import {requestMeterConsumptions} from '@src/api/backend/meterConsumptions';
import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IMeterConsumptionsListQueryKey, meterConsumptionsQueryKeys} from '../keys';

export const useMeterConsumptionsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(meterConsumptionsQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IMeterConsumptionsListQueryKey>) => {
			const [, , meterId] = queryKey;
			const result = await requestMeterConsumptions(meterId);
			return result;
		},
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
