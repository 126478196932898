import {queryClient} from '@src/api/QueryClient';
import {
	IUnbindSpaceFromOwnerResponse,
	makeUnbindSpaceFromOwnerRequest
} from '@src/api/backend/spaceOwner';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

/**
 * Отвязывает собственника от помещения
 *
 * @param ownerId id собственника
 * @param spaceId id помещения
 * @param removeIfLast удалять собственника, если у него не останется помещений
 */
interface IUnbindSpaceOwnerParams {
	ownerId: string;
	spaceId: string;
	removeIfLast?: boolean;
}

export const useUnbindSpaceOwner = () =>
	useMutation<IUnbindSpaceFromOwnerResponse, IError, IUnbindSpaceOwnerParams>({
		mutationKey: spaceOwnersQueryKeys.unbind(),
		mutationFn: params => {
			const {ownerId, spaceId, removeIfLast} = params;
			return makeUnbindSpaceFromOwnerRequest(ownerId, spaceId, removeIfLast);
		},
		onError: () => {
			addErrorToast('Ошибка', 'не удалось отвязать собственника от помещения');
		},
		onSuccess: (_, variables) => {
			// не факт что нужно
			void queryClient.invalidateQueries({
				queryKey: spaceOwnersQueryKeys.detail(variables.ownerId)
			});
			void queryClient.invalidateQueries({queryKey: spaceOwnersQueryKeys.list()});
		}
	});
