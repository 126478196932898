import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

/**
 * Формирует заголовок текущего помещения (в store)
 */
export function useSpaceTitle(
	space: {name: string; altName?: string; type: SpaceTypeId} | undefined
) {
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const translatedSpaceTypes = useTranslatedDictionaryMap(
		dictionaryKeys.spaceTypesSingular,
		spaceTypes
	);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(
		() =>
			space
				? formSpaceTitle(
						space.name,
						space.altName,
						translatedSpaceTypes?.[space.type],
						altNamesVisible,
						true
				  )
				: '',
		[space, spaceTypes, translatedSpaceTypes, altNamesVisible]
	);
}
