import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStructuresSchemaTypes} from '@src/core/hooks/queries/structureTypes/hooks';
import {memo, useCallback} from 'react';
import {SchemaTabs, TabButton} from '@tehzor/ui-components';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';
import {structuresActions} from '@/entities/Structures';
import {useActiveSchemaStructuresType} from '@/widgets/StructuresSchema/model/hooks/useActiveSchemaStructuresType';
import {Skeleton} from './Skeleton/Skeleton';

interface ITabsProps {
	objectId: string;
}

export const Tabs = memo(({objectId}: ITabsProps) => {
	useActiveSchemaStructuresType(objectId);
	const dispatch = useAppDispatch();
	const {changeSchemaStructureType} = structuresActions;
	const {schemaStructureTypeId} = useAppSelector(state =>
		extractStructuresSchemaPageSettings(state, objectId)
	);
	const {data: schemaStructuresTypes, isLoading} = useStructuresSchemaTypes(objectId);
	const changeViewType = useCallback(
		(value: string) => {
			dispatch(changeSchemaStructureType({objectId, structureTypeId: value}));
		},
		[dispatch, changeSchemaStructureType, objectId]
	);

	return (
		<SchemaTabs>
			{isLoading ? (
				<Skeleton />
			) : (
				schemaStructuresTypes &&
				schemaStructuresTypes.map(type => (
					<TabButton
						label={`${type.name} ${type.structuresQuantity}`}
						value={type.id}
						onClick={changeViewType}
						key={type.id}
						active={schemaStructureTypeId === type.id}
					/>
				))
			)}
		</SchemaTabs>
	);
});
