import './CheckPage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import {getCheckProblemReplies} from '@src/store/modules/entities/check/actions/problemReplies';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useCheck} from '@src/core/hooks/queries/check/hook';

const CheckPage = () => {
	useScrollRestoration();

	const {objectId, checkId} = useStrictParams<{objectId: string; checkId: string}>();
	const dispatch = useAppDispatch();
	const {data: check} = useCheck(checkId, objectId);

	useAsync(async () => {
		await dispatch(getCheckProblemReplies(objectId, checkId));
	});

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				checkId={checkId}
				check={check}
			/>
		);
	}
	return (
		<Mobile
			objectId={objectId}
			checkId={checkId}
			check={check}
		/>
	);
};

export default CheckPage;
