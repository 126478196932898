import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {offlineJournalActionType} from '../enums/actionsTypes';
import {MenuItem} from '@tehzor/ui-components';
import {formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';
import {useTranslation} from 'react-i18next';

export const EntityLink = (props: {item: IOfflineJournalEntity; goTo: (url: string) => void}) => {
	const {t} = useTranslation();
	const {item, goTo} = props;
	let menuItem = <></>;
	let url: string;
	if (
		item.object?.objectId &&
		(item.entity.entityType === offlineJournalActionType.PROBLEM_ADD ||
			item.entity.entityType === offlineJournalActionType.PROBLEM_SPACE_ADD ||
			item.entity.entityType === offlineJournalActionType.PROBLEM_CHECKLIST_ADD)
	) {
		url = formProblemLink(item.object?.objectId, item.entity.entityId);
		menuItem = (
			<MenuItem
				key="edit"
				icon={<i className="tz-problem-24" />}
				onClick={() => goTo(url)}
			>
				{t('offlineJournalPage.entityLink.menuItem.toDefect.text')}
			</MenuItem>
		);
	}
	if (
		item.object?.objectId &&
		(item.entity.entityType === offlineJournalActionType.INSPECTION_ADD ||
			item.entity.entityType === offlineJournalActionType.INSPECTION_CHECKLIST_ADD ||
			item.entity.entityType === offlineJournalActionType.INSPECTION_SPACE_ADD)
	) {
		url = formInspectionLink(item.object?.objectId, item.entity.entityId);
		menuItem = (
			<MenuItem
				key="edit"
				icon={<i className="tz-inspection-24" />}
				onClick={() => goTo(url)}
			>
				{t('offlineJournalPage.entityLink.menuItem.toInspection.text')}
			</MenuItem>
		);
	}
	return menuItem;
};
