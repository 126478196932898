export const spaceStatusesQueryKeys = {
	all: () => ['space-statuses'],
	one: () => ['space-status'],
	list: () => [...spaceStatusesQueryKeys.all(), 'list'],
	add: () => [...spaceStatusesQueryKeys.one(), 'add'],
	edit: () => [...spaceStatusesQueryKeys.one(), 'edit'],
	delete: () => [...spaceStatusesQueryKeys.one(), 'delete'],
	deleteMany: () => [...spaceStatusesQueryKeys.all(), 'delete'],
	editOrder: () => [...spaceStatusesQueryKeys.all(), 'edit-order']
};
