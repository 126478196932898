import React, {useCallback, useState} from 'react';
import {AddingMeterDialog} from '../AddingMeterDialog';

export function useAddingMeterDialog(
	companyId?: string,
	objectId?: string,
	spaceId?: string
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = isOpen ? (
		<AddingMeterDialog
			companyId={companyId}
			objectId={objectId}
			spaceId={spaceId}
			isOpen={isOpen}
			onClose={close}
		/>
	) : null;

	return [dialog, open];
}
