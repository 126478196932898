import {useEffect, useMemo} from 'react';
import './WorkAcceptancePage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {Desktop} from './components/Desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Mobile} from './components/Mobile';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {
	getCheckRecords,
	getInspections,
	getProblems
} from '@src/store/modules/pages/checkLists/actions';
import {
	getWorkAcceptanceInspections,
	getWorkAcceptanceProblems
} from '@src/store/modules/entities/workAcceptance/actions';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {setInitialState} from '@src/store/modules/settings/pages/workAcceptances/actions';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import {useWorkAcceptanceSettings} from '@src/core/hooks/settings/useWorkAcceptanceSettings';

export const WorkAcceptancePage = () => {
	useScrollRestoration();
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId: string;
	}>();
	const {data: workAcceptance} = useWorkAcceptance(workAcceptanceId, objectId);
	const settings = useWorkAcceptanceSettings(objectId);
	const permissions = useWorkAcceptancesPermissions(objectId, workAcceptance);
	const dispatch = useAppDispatch();

	// TODO зачем это? без него вроде как работает нужно разобраться
	useEffect(() => {
		dispatch(setInitialState(objectId));
	}, [objectId, dispatch]);

	useAsync(async () => {
		await Promise.all<unknown>([
			dispatch(getWorkAcceptanceProblems(objectId, workAcceptanceId)),
			dispatch(getWorkAcceptanceInspections(objectId, workAcceptanceId))
		]);
	});

	const isDesktop = useIsDesktop();
	const structureTypes = useMemo(
		() =>
			workAcceptance?.structures
				? Array.from(new Set(workAcceptance.structures.map(str => str.type)))
				: undefined,
		[workAcceptance?.structures]
	);
	const spaceTypes = useMemo(
		() =>
			workAcceptance?.spaces
				? Array.from(new Set(workAcceptance.spaces.map(space => space.type)))
				: undefined,
		[workAcceptance?.spaces]
	);
	const checkLists = useAvailableCheckLists(
		objectId,
		undefined,
		workAcceptance?.stage,
		CheckListTypeId.ACCEPTANCES,
		structureTypes,
		workAcceptance?.categoryId,
		spaceTypes
	);

	const checkListsIds = useMemo(() => checkLists.map(cl => cl.id), [checkLists]);

	useAsync(async () => {
		if (checkListsIds.length) {
			await Promise.allSettled([
				dispatch(getProblems(checkListsIds, undefined, [workAcceptanceId])),
				dispatch(getCheckRecords(checkListsIds, [objectId], undefined, [workAcceptanceId])),
				dispatch(getInspections(checkListsIds, undefined, [workAcceptanceId]))
			]);
		}
	}, [objectId, workAcceptanceId, checkListsIds]);

	if (!workAcceptance) {
		return null;
	}
	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				checkLists={checkLists}
				workAcceptance={workAcceptance}
				permissions={permissions}
				settings={settings}
			/>
		);
	}

	return (
		<Mobile
			objectId={objectId}
			checkLists={checkLists}
			workAcceptance={workAcceptance}
			permissions={permissions}
			settings={settings}
		/>
	);
};
