import {createReducer} from '@reduxjs/toolkit';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import * as types from './constants';

export interface ICategoriesSetsFiltersState extends Record<string, unknown> {
	objects?: string[];
	createdBy?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
	stages?: ObjectStageIds[];
}

export type ICategoriesSetsSortState = Record<string, boolean>;

export interface ICategoriesSetsPageSettingsState {
	filters: ICategoriesSetsFiltersState;
	sort: ICategoriesSetsSortState;
	pageSize: number;
	offset: number;
}

export type ICategoriesSetsPagesSettingsState = Record<string, ICategoriesSetsPageSettingsState>;

export const getInitialStateForPage = (objectId: string): ICategoriesSetsPageSettingsState => ({
	filters: {
		objects: objectId !== 'all' ? [objectId] : undefined
	},
	sort: {
		modifiedAt: false
	},
	pageSize: 20,
	offset: 0
});

export default createReducer<ICategoriesSetsPagesSettingsState>(
	{},
	{
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<ICategoriesSetsFiltersState>}
		) => {
			const {filters, objectId} = payload;
			if (!(objectId in state)) {
				state[objectId] = getInitialStateForPage(objectId);
			}

			state[objectId].filters = filters;
		},
		[types.CHANGE_SORT]: (
			state,
			{payload}: {payload: IChangeSortPayload<ICategoriesSetsSortState>}
		) => {
			const {sort, objectId} = payload;
			if (!(objectId in state)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].sort = sort;
		},
		[types.CLEAR_FILTERS]: (state, {payload}: {payload: IClearFiltersPayload}) => {
			const {objectId} = payload;

			const filters = {} as ICategoriesSetsFiltersState;
			if (objectId !== 'all') {
				filters.objects = [objectId];
			}
			state[objectId].filters = filters;
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string; offset: number}}
		) => {
			const {objectId, offset} = payload;
			if (!(objectId in state)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].offset = offset;
		},
		[types.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: IChangePageSizePayload}) => {
			const {objectId, pageSize} = payload;
			if (!(objectId in state)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].pageSize = pageSize;
		}
	}
);
