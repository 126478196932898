import {Tree} from 'array-to-tree';
import {findResponsibles} from './findResponsibles';
import {IWorkingGroup} from '../../interfaces/workingGroups/IWorkingGroup';
import {IResponsibilityRule} from '../../interfaces/responsibilityRules/IResponsibilityRule';
import {IRuleParams} from './IRuleParams';
import {ISuggestions} from './ISuggestions';

/**
 * Возвращает предложения по отобранным с помощью правил группам и пользователям
 *
 * @param groups рабочие группы, предварительно отфильтрованные по текущему объекту, стадии, области
 * @param rules правила ответственности
 * @param ruleParams параметры правил ответственности
 * @return возвращает:
 * 		отобранную по правилам мапу активной группы и пользователей
 * 		выбранную активную группу;
 * 		выбранных пользователей.
 */
export const getSuggestionsByRules = (
	groups: Array<Tree<IWorkingGroup>>,
	rules: IResponsibilityRule[],
	ruleParams: IRuleParams = {}
): [ISuggestions, string | undefined, string[]] => {
	const [suggestions, selectedGroups, selectedUsers] = findResponsibles(groups, rules, ruleParams);

	if (selectedGroups.size === 1) {
		return [
			suggestions,
			selectedGroups.values().next().value as string,
			Array.from(selectedUsers.values())
		];
	}

	return [suggestions, undefined, []];
};
