import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractWorkingGroupsPageFilters} from '@src/store/modules/settings/pages/manage/workingGroups/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	IWorkingGroupsFiltersState,
	workingGroupsActions
} from '@src/store/modules/settings/pages/manage/workingGroups/slice';
import {useCallback} from 'react';

export const useWorkingGroupsFilters = () => {
	const dispatch = useAppDispatch();
	const {setFilter, clearFilters} = workingGroupsActions;
	const filters = useAppSelector(extractWorkingGroupsPageFilters);

	const applyFilters = useCallback(
		(value: IWorkingGroupsFiltersState) => {
			dispatch(setFilter(value));
		},
		[dispatch, setFilter]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters());
	}, [clearFilters, dispatch]);

	return {filters, applyFilters, onClear};
};
