import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ILinkedLegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILinkedLegalEntity';

export type IGetLegalEntityResponse = ILinkedLegalEntity;

export const requestLegalEntity = async (legalId: string) => {
	const response = await httpRequests.withToken.get<IGetLegalEntityResponse>(
		`legals-entity/${legalId}`
	);
	return response.data;
};
