import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useAddingCheckListDialog} from '@src/components/AddingCheckListDialog';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';

export const DesktopAddButton = () => {
	const [dialog, open] = useAddingCheckListDialog();
	return (
		<>
			<Desktop>
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-plus-20" />}
					label="Добавить чек-лист"
					onClick={open}
				/>
			</Desktop>

			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16" />}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={open}
				/>
			</BeforeDesktop>
			{dialog}
		</>
	);
};
