import {EntityTypeId} from '@tehzor/tools/interfaces/fields';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntitySettings, useEntitySettings} from './useEntitySettings';

const objectFields: Array<keyof IObjectFieldsSettings> = [
	'buildingProblem',
	'acceptanceProblem',
	'acceptanceClaimProblem',
	'warrantyClaimProblem'
];

const objectFieldsStageMap = new Map<ObjectStageIds, keyof IObjectFieldsSettings>([
	[ObjectStageIds.BUILDING, 'buildingProblem'],
	[ObjectStageIds.ACCEPTANCE, 'acceptanceProblem'],
	[ObjectStageIds.TRANSFER, 'acceptanceClaimProblem'],
	[ObjectStageIds.WARRANTY, 'warrantyClaimProblem']
]);


export const useProblemSettings = (objectId?: string, stage?: ObjectStageIds): IEntitySettings => {
	const fields: Array<keyof IObjectFieldsSettings> = [];
	if (!stage) {
		fields.push(...objectFields);
	}

	const stageField = stage && objectFieldsStageMap.get(stage);

	if (stageField) {
		fields.push(stageField);
	}

	return useEntitySettings(objectId || 'all', fields, EntityTypeId.PROBLEM, stage);
};
