import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

export type InternalAcceptanceEntitiesVisibility = 'problems' | 'inspections';

export const getInitialState = (): InternalAcceptanceEntitiesVisibility[] => ['problems', 'inspections'];

export default createReducer<InternalAcceptanceEntitiesVisibility[]>(getInitialState(), {
	[types.CHANGE_ENTITIES_VISIBILITY]: (state, {payload}: {payload: InternalAcceptanceEntitiesVisibility[]}) =>
		payload
});
