const domain = 'settings/pages/statistics/building';

export const CHANGE_TAB_INDEX = `${domain}/tab-index/change`;
export const CHANGE_FILTERS = `${domain}/filters/change`;
export const CLEAR_FILTERS = `${domain}/filters/clear`;
export const DELETE_FILTERS_ITEM = `${domain}/filters/item/delete`;
export const DELETE_FILTERS_ITEMS = `${domain}/filters/items/delete`;

export const CHANGE_FILTERS_DIALOG_TAB_INDEX = `${domain}/filters-dialog/tab-index/change`;
export const CHANGE_GROUPING = `${domain}/grouping/change`;
