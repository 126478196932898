import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';
import {requestTaskPriorities} from '@src/api/backend/taskPriorities';

export const useTaskPrioritiesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.taskPriorities(), {
		queryFn: requestTaskPriorities,
		staleTime: 1000 * 60 * 60 * 24, // 1 день
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
