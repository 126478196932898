import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import {
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import EditableWarrantyClaimAttachments from '../EditableWarrantyClaimAttachments';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {
	useEditableWarrantyClaimState,
	convertToSave,
	hasAttachmentsError,
	isEdited,
	errorsFns
} from '@src/core/hooks/states/useEditableWarrantyClaimState';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	phone: {fieldId: 'phone', isRequired: true},
	email: {fieldId: 'email', isRequired: true}
};

/**
 * Логика редактирования вложений
 */
export const useEditableWarrantyClaimAttachments = (
	objectId: string,
	claim: IWarrantyClaim,
	saving: boolean
): [React.ReactNode, () => Promise<ISavingWarrantyClaim | undefined>, (
) => void, boolean] => {
	// TODO Определение типа настроек
	// const fieldsSettings = useFieldsSettings(objectId, 'warrantyClaim');

	const [editingState, editingDispatch] = useEditableWarrantyClaimState(claim);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, claim) || isFilesExist(uploadingFilesState.value));
	}, [editingState, uploadingFilesState.value, claim]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (
			hasErrors(editingState, errorsFns, fieldsSettings)
			|| (hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
		) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (!isEdited(editingState, claim) && (!isFilesExist(files) || someFilesHaveError(files))) {
			return undefined;
		}
		const savingData = convertToSave(editingState, claim, true);
		savingData.newAttachments = convertFilesToSave(files);
		return savingData;
	}, [editingState, objectId, claim, fieldsSettings]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: claim
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [claim]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [claim]);

	const fields = (
		<EditableWarrantyClaimAttachments
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
