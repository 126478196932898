import {useMutation} from '@tanstack/react-query';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';
import {problemTagsSetsQueryKeys} from '@src/api/cache/problemTagsSets/keys';
import {makeProblemTagsSetDeleteRequest} from '@src/api/backend/problemTagsSet';

export const useDeleteProblemTagsSet = () =>
	useMutation({
		mutationKey: problemTagsSetsQueryKeys.delete(),
		mutationFn: (setId: string) => makeProblemTagsSetDeleteRequest(setId),
		onError: () => {
			addErrorToast('Ошибка', 'при удалении набора меток');
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: problemTagsSetsQueryKeys.list()});
			addSuccessToast('Удалено', 'Набор меток успешно удален');
		}
	});
