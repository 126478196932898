import {useMemo} from 'react';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {useUsersAsMap} from './queries/users/hooks';
import {useWorkingGroupsAsMap} from './queries/workingGroups/hooks';

export function formResponsibleDisplayValue(
	respUsers: string[] | undefined,
	activeGroup: string | undefined,
	groups?: Record<string, IWorkingGroup>,
	users?: Record<string, IBriefUser>
) {
	if (respUsers?.length && users) {
		return respUsers
			.reduce<string[]>((prev, id) => {
				if (users[id]) {
					prev.push(users[id].displayName);
				}
				return prev;
			}, [])
			.join(', ');
	}
	if (activeGroup && groups) {
		return groups[activeGroup]?.name ?? '';
	}
	return '';
}

/**
 * Формирует отображаемое значение для ответственных нарушений (пользователи или группа)
 *
 * @param respUsers пользователи
 * @param activeGroup группа
 */
export function useResponsibleDisplayValue(respUsers?: string[], activeGroup?: string) {
	const {data: usersMap} = useUsersAsMap();
	const {data: groupsMap} = useWorkingGroupsAsMap();

	return useMemo(
		() => formResponsibleDisplayValue(respUsers, activeGroup, groupsMap, usersMap),
		[respUsers, activeGroup, usersMap, groupsMap]
	);
}
