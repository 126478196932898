import * as types from '../constants';
import {SpaceEntitiesTab} from '../reducers';

/**
 * Изменяет вкладку отображения сущностей помещения
 *
 * @param value значение фильтра
 */
export const changeEntitiesTab = (value: SpaceEntitiesTab | undefined) => ({
	type: types.CHANGE_ENTITIES_TAB,
	payload: value
});
