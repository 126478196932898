import {useCallback} from 'react';
import {SwitchMenu, SwitchMenuOption} from '@tehzor/ui-components';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractStructuresPageSettings, structuresActions} from '@/entities/Structures';

interface IDesktopDisplayModeSwitchProps {
	objectId: string;
}

export const DisplayModeSwitchDesktop = ({objectId}: IDesktopDisplayModeSwitchProps) => {
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const {changeDisplayMode} = structuresActions;

	const handleChange = useCallback(
		(value: StructuresDisplayMode) => {
			dispatch(changeDisplayMode({objectId, displayMode: value}));
		},
		[dispatch, changeDisplayMode, objectId]
	);

	return (
		<SwitchMenu
			value={displayMode}
			onChange={handleChange}
		>
			<SwitchMenuOption value={StructuresDisplayMode.SCHEMA}>
				<i className="tz-schema-24" />
			</SwitchMenuOption>
			<SwitchMenuOption value={StructuresDisplayMode.LIST}>
				<i className="tz-list-24" />
			</SwitchMenuOption>
		</SwitchMenu>
	);
};
