import useAppSelector from '@src/core/hooks/useAppSelector';
import {StatisticsGrouping} from '@src/interfaces/StatisticsGrouping';
import ByCompaniesStats from './ByCompaniesStats';
import ByObjectsStats from './ByObjectsStats';
import ByUsersStats from './ByUsersStats';
import ByContractorsStats from './ByContractorsStats';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsync from 'react-use/lib/useAsync';
import {
	getChecksCreationStats,
	getProblemsCreationStats,
	getProblemRepliesCreationStats,
	getInspectionsCreationStats,
	getWarrantyClaimsCreationStats
} from '@src/store/modules/statistics/warranty/actions';
import NoData from '../../NoData';
import DisplayControls from '../../DisplayControls';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';

function renderStatsComponent(grouping: StatisticsGrouping) {
	switch (grouping) {
		case StatisticsGrouping.COMPANIES:
			return <ByCompaniesStats/>;
		case StatisticsGrouping.OBJECTS:
			return <ByObjectsStats/>;
		case StatisticsGrouping.USERS:
			return <ByUsersStats/>;
		case StatisticsGrouping.CONTRACTORS:
			return <ByContractorsStats/>;
		default:
			return null;
	}
}

const CreationStats = () => {
	useScrollRestoration();

	const filters = useAppSelector(s => s.settings.pages.statistics.warranty.filters);
	const grouping = useAppSelector(s => s.settings.pages.statistics.warranty.grouping);

	const dispatch = useAppDispatch();

	useAsync(async () => {
		await Promise.all([
			dispatch(getChecksCreationStats()),
			dispatch(getProblemsCreationStats()),
			dispatch(getProblemRepliesCreationStats()),
			dispatch(getInspectionsCreationStats()),
			dispatch(getWarrantyClaimsCreationStats())
		]);
	}, [filters]);

	return (
		<div>
			<DisplayControls/>

			{renderStatsComponent(grouping)}

			<NoData asTabContent/>
		</div>
	);
};

export default CreationStats;
