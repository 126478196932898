import {IGetProblemStatusesResponse} from '@src/api/backend/problemStatuses';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

export const extractProblemStatusesAsArray = (data: IGetProblemStatusesResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

export const extractProblemStatusesMap = (data: IGetProblemStatusesResponse) => data.byId;

export const extractProblemStatusesWithExpired = (data: IGetProblemStatusesResponse) => {
	const processedData = extractProblemStatusesAsArray(data);
	return processedData.length
		? processedData.concat([
				{
					id: ProblemStatusId.EXPIRED,
					name: 'Просрочено',
					color: '#FF6565',
					textColor: '#FF5A47',
					transitions: []
				} as IProblemStatus
		  ])
		: [];
};
