import './CheckListPopupContent.less';
import declination from '@tehzor/tools/utils/declination';
import {CheckListInfo} from './components/CheckListInfo';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';

interface ICheckListPopupContentProps {
	space: IConvertedSpace;
	spaceNameTitle: string | undefined;
	checkListTranslationArr?: string[];
}

const translationArr = ['чеклист', 'чеклиста', 'чеклистов'];

export const CheckListPopupContent = ({
	space,
	spaceNameTitle = 'Помещение №',
	checkListTranslationArr = translationArr
}: ICheckListPopupContentProps) => (
	<div className="check-list-popup-content">
		<div className="check-list-popup-content__header">
			<div className="check-list-popup-content__space-name">
				{spaceNameTitle}
				{space.name}
			</div>
			<div className="check-list-popup-content__checkList-number">
				{space.checkLists &&
					`${space.checkLists.length} ${declination(
						space.checkLists.length,
						checkListTranslationArr
					)}`}
			</div>
		</div>
		{space.checkLists &&
			space.checkLists.map(checkList => (
				<CheckListInfo
					key={checkList.id}
					checkList={checkList}
					openByDefault={space.checkLists?.length === 1}
				/>
			))}
	</div>
);
