import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import classNames from 'classnames';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

export const TaskDescriptionCell = ({row}: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const task = row.original;

	const translatedTypeName = useTranslatedDictionaryById(dictionaryKeys.taskTypes, task.taskType?.id);

	return (
		<span className={classNames('tasks-page__task-description', {
			'tasks-page__task-description--attachment': !!task?.attachments?.[0]
		})}
		>
			<div>
				<div className="tasks-page__task-description-name">
					{task.name}
				</div>

				{task?.description && (
					<div className="tasks-page__task-description-long">
						{task?.description}
					</div>
				)}

				{task?.taskType && (
					<div className="tasks-page__task-description-type">
						{translatedTypeName}
					</div>
				)}
			</div>

			{task?.attachments?.[0] && (
				<div className="tasks-page__task-description-preview">
					<i className="tz-document-20"/>
				</div>
			)}
		</span>
	);
};
