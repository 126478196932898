import {createMutation} from 'react-query-kit';
import {legalsQueryKeys} from '../keys';
import {
	IAddLegalParams,
	IEditLegalParams
} from '@src/api/cache/legals/defaults/useLegalsMutationDefaults';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export const useAddLegalMutation = createMutation<ILegalEntity, IAddLegalParams>({
	mutationKey: legalsQueryKeys.add(),
	meta: {
		entity: 'legal',
		type: 'creation'
	}
});

export const useEditLegalMutation = createMutation<ILegalEntity, IEditLegalParams>({
	mutationKey: legalsQueryKeys.edit(),
	meta: {
		entity: 'legal',
		type: 'editing'
	}
});
