import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {ISavingSpace} from '@src/interfaces/saving/ISavingSpace';

export type IAddSpaceResponse = ISpace;

/**
 * Добавляет помещение
 *
 * @param objectId id объекта
 * @param fields данные помещения
 */
export const makeSpaceAddRequest = async (objectId: string, fields: ISavingSpace) => {
	const response = await httpRequests.withToken.post<IAddSpaceResponse>('spaces', {
		objectId,
		...fields
	});
	return response.data;
};
