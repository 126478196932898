import {ISpaceLocation} from '@tehzor/tools/interfaces/spaces/ISpaceLocation';

export const transformLocations = (
	locations?: ISpaceLocation[]
): [planId?: string, sectorId?: string] => {
	if (locations === undefined || locations?.length < 1) {
		return [undefined, undefined];
	}
	return [locations[0].planId, locations[0].sectorId];
};
