import * as React from 'react';
import './EmptyPlate.less';
import classNames from 'classnames';

interface IEmptyPlateProps {
	text?: string;
	icon?: React.ReactNode;
	mode?: 'detail' | 'main';
}
export const EmptyPlate = (props: IEmptyPlateProps) => {
	const {text, icon, mode = 'detail'} = props;

	const mods = classNames('empty-plate__no-content', {
		'empty-plate__no-content-detail': mode === 'detail',
		'empty-plate__no-content-main': mode === 'main'
	});

	return (
		<div className={mods}>
			<div className="empty-plate__no-content-container">
				{icon !== undefined && <div className="empty-plate__no-content-icon">{icon}</div>}
				{text !== undefined && <div className="empty-plate__no-content-text">{text}</div>}
			</div>
		</div>
	);
};
