import {useQuery} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetOwnerAcceptanceStatusesResponse} from '@src/api/backend/ownerAcceptanceStatuses';
import {extractOwnerAcceptanceStatusesAsArray} from '@src/core/hooks/queries/ownerAcceptanceStatuses/selectors';

export const useOwnerAcceptanceStatuses = <T = IGetOwnerAcceptanceStatusesResponse>(
	select?: (data: IGetOwnerAcceptanceStatusesResponse) => T
) =>
	useQuery<IGetOwnerAcceptanceStatusesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.ownerAcceptanceStatuses(),
		meta: {
			error: 'при загрузке статусов передач собственникам'
		},
		select
	});

export const useOwnerAcceptanceStatusesAsArray = () =>
	useOwnerAcceptanceStatuses(extractOwnerAcceptanceStatusesAsArray);
