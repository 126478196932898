import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetInternalAcceptancesResponse extends INormalizedData<IListInternalAcceptance> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список проверок
 */
export const requestInternalAcceptances = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const response = await httpRequests.withToken.post<IGetInternalAcceptancesResponse>(
		'internal-acceptances/get',
		{
			filters,
			sort: convertSortParam(sort),
			offset,
			limit
		}
	);
	return response.data;
};
