import * as React from 'react';
import {InlineButton} from '@tehzor/ui-components';
import classNames from 'classnames';

interface ISpaceTypeMenuButtonProps {
	label?: React.ReactNode;
	isOpen?: boolean;
	disabled?: boolean;

	onClick?: () => void;
}

export const SpaceTypeMenuButton = (props: ISpaceTypeMenuButtonProps) => {
	const {label, isOpen, disabled, onClick} = props;

	return (
		<InlineButton
			className="spaces-page__space-type-menu-btn"
			labelClassName="spaces-page__space-type-menu-btn-label"
			rightIconClassName={classNames('spaces-page__space-type-menu-btn-icon', {
				'spaces-page__space-type-menu-btn-icon_open': isOpen
			})}
			label={label}
			rightIcon={<i className="tz-simple-arrow-16" />}
			disabled={disabled}
			onClick={onClick}
		/>
	);
};
