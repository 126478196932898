import {IAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import {Dispatch} from 'react';
import {
	IEditableAuthorizedPersonState,
	IEditableAuthorizedPersonAction,
	convertToSave
} from '@src/core/hooks/states/useEditableAuthorizedPersonState';
import {useAddAuthorizedPerson} from '@src/core/hooks/mutations/authorizedPersons/useAddAuthorizedPerson';
import {useEditAuthorizedPerson} from '@src/core/hooks/mutations/authorizedPersons/useEditAuthorizedPerson';
import {useDeleteAuthorizedPerson} from '@src/core/hooks/mutations/authorizedPersons/useDeleteAuthorizedPerson';

interface IUseUpdateContentProps {
	editingState?: IEditableAuthorizedPersonState;
	editingDispatch?: Dispatch<IEditableAuthorizedPersonAction>;
	authorizedPerson?: IAuthorizedPerson;
	legalEntityId?: string;
	companyId?: string;
}

interface IUseUpdateContent {
	updateAuthorizedPerson: () => Promise<IAuthorizedPerson | undefined> | undefined;
	removeAuthorizedPerson: (id: string) => void;
}

export const useUpdateContent = (props: IUseUpdateContentProps): IUseUpdateContent => {
	const {editingState, editingDispatch, authorizedPerson, legalEntityId, companyId} = props;
	const addAuthorizedPerson = useAddAuthorizedPerson();
	const editAuthorizedPerson = useEditAuthorizedPerson();
	const deleteAuthorizedPerson = useDeleteAuthorizedPerson();

	const checkErrors = (
		state: IEditableAuthorizedPersonState,
		dispatch: Dispatch<IEditableAuthorizedPersonAction>
	) => {
		const {name, errors} = state;
		let result = false;

		if (!name) {
			dispatch({type: 'update-error', field: 'name'});
			result = true;
		}

		if (errors) {
			for (const error of Object.values(errors)) {
				result = error ? true : result;
			}
		}

		return result;
	};

	const updateAuthorizedPerson = () => {
		if (!editingState || !editingDispatch || checkErrors(editingState, editingDispatch)) {
			return undefined;
		}
		const fields = convertToSave(editingState, authorizedPerson);

		if (!authorizedPerson) {
			return addAuthorizedPerson({fields: {...fields, legalEntityId, companyId}});
		}

		return editAuthorizedPerson({
			authorizedPersonId: authorizedPerson.id,
			fields: {
				...fields,
				legalEntityId: authorizedPerson.legalEntityId,
				companyId: authorizedPerson.companyId
			}
		});
	};

	const removeAuthorizedPerson = (id: string) => deleteAuthorizedPerson(id);

	return {updateAuthorizedPerson, removeAuthorizedPerson};
};
