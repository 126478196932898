import styles from './SchemaTabs.module.less';
import {ReactNode} from 'react';

interface ISchemaTabsProps {
	children: ReactNode;
}

export const SchemaTabs = ({children}: ISchemaTabsProps) => (
	<div className={styles.wrapper}>{children}</div>
);
