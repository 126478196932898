import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ActionButtons, Button, Dialog, Select2 as Select, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import { useCallback, useMemo, useState } from 'react';
import {useUpdateEffect} from 'react-use';
import {IExtendedSpaceStatus} from '../../interfaces/IExtendedSpaceStatus';

interface IStatusAutoTransitionFieldProps {
	status: IExtendedSpaceStatus;
	statuses?: ISpaceStatus[];
	disabled?: boolean;
	isMobile?: boolean;

	onChange: (value?: string | null) => void;
}

const getTitle = (statuses?: ISpaceStatus[], value?: string) => {
	if (value === undefined || statuses === undefined) {
		return '';
	}

	return statuses.find(status => status.id === value)?.name || '';
};

const arrowIcon = <i className="tz-simple-arrow-20"/>;

export const StatusAutoTransitionField = ({
	status, statuses, disabled, isMobile, onChange
}: IStatusAutoTransitionFieldProps) => {
	const [checked, setChecked] = useState(status?.autoTransitionTo);
	const [isOpen, setOpen] = useState(false);

	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	useUpdateEffect(() =>
		setChecked(status?.autoTransitionTo),
	[status?.autoTransitionTo]);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const count = checked ? 1 : 0;
	const title = useMemo(() =>
		getTitle(statuses, status?.autoTransitionTo || undefined),
	[statuses, status?.autoTransitionTo]);

	const isSearchShown = useMemo(() => (statuses?.length || 0) >= 8, [statuses]);

	const filteredStatuses = useMemo(() => {
		if (!statuses) { return undefined; }

		return flatFilter(
			statuses?.filter(val => val.id !== status.id),
			'name',
			search
		);
	}, [status.id, statuses, search]);

	const handleCancel = useCallback(() => {
		setChecked(status?.autoTransitionTo);
		clearSearch();
	}, [status?.autoTransitionTo, clearSearch]);

	const handleConfirm = useCallback(() => {
		onChange(checked);
		clearSearch();
	}, [onChange, checked, clearSearch]);

	const handleChange = useCallback((val: string) => setChecked(val), []);

	const handleClearButton = useCallback(() => {
		onChange(null);
		clearSearch();
	}, [onChange, clearSearch]);

	const handleClear = useCallback(() => {
		setChecked(null);
		clearSearch();
	}, [clearSearch]);

	const trigger = (
		<TextFieldWithForwardedRef
			elementType="div"
			icon={arrowIcon}
			value={title}
			disabled={disabled}
			onClick={isMobile ? open : undefined}
			cleanable={!!status.autoTransitionTo}
			onClearClick={handleClearButton}
		/>
	);

	const content = (
		<Select
			value={checked || undefined}
			onChange={handleChange}
		>
			{filteredStatuses?.map(item => (
				<SelectOption
					inputType="radio"
					key={item.id}
					itemKey={item.id}
					content={item.name}
				/>
			))}
		</Select>
	);

	const selectSearch = isSearchShown ? (
		<SelectSearch
			value={search}
			onChange={setSearch}
			onClear={clearSearch}
		/>
	) : null;

	return (
		<div className="editable-space-statuses__status-fields__auto">
			<div className="editable-space-statuses__status-fields__auto-title">
				Автоматическая смена на:
			</div>
			{!isMobile ? (
				<SelectPopup
					onCancel={handleCancel}
					onApply={handleConfirm}
					onClear={handleClear}
					clearButton={!!checked}
					count={count}
					footer
					trigger={trigger}
					search={selectSearch}
				>
					{content}
				</SelectPopup>
			) : trigger}
			{isMobile && (
				<Dialog
					isOpen={isOpen}
					className="editable-space-statuses__status-fields__auto-dialog"
					title="Автоматическая смена статуса на:"
					onRequestClose={close}
					footer={(
						<div className="editable-space-statuses__status-fields__auto-footer">
							<ActionButtons
								className="editable-space-statuses__status-fields__auto-footer-btns"
							>
								<Button
									type="cancel"
									label="Отменить"
									onClick={handleCancel}
								/>
								<Button
									type="accent-blue"
									label="Сохранить"
									onClick={handleConfirm}
								/>
							</ActionButtons>
						</div>
					)}
				>
					{selectSearch}
					{content}
				</Dialog>
			)}
		</div>
	);
};