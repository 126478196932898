import {useQueryClient} from '@tanstack/react-query';
import {authorizedPersonsQueryKeys} from '../keys';
import {requestAuthorizedPersons} from '@src/api/backend/authorizedPersons';

export const useAuthorizedPersonsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(authorizedPersonsQueryKeys.list(), {
		queryFn: requestAuthorizedPersons,
		meta: {
			error: 'при загрузке уполномоченных лиц'
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
