import * as types from '@src/constants/local-entities/problems';
import {ILocalInspection} from '@src/interfaces/ILocalInspection';

/**
 * Добавляет отчёт в локальное хранилище
 *
 * @param inspection
 * @returns {{type, payload}} action
 */
export const addLocalInspection = (inspection: ILocalInspection) => ({
	type: types.ADD_LOCAL_INSPECTION,
	payload: {inspection}
});

/**
 * Удаляет отчёт из локального хранилища
 *
 * @param {string} key id локального отчёта
 * @returns {{type, payload}} action
 */
export const deleteLocalInspection = (key: string) => ({
	type: types.DELETE_LOCAL_INSPECTION,
	payload: {key}
});
