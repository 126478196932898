import {queryClient} from '@src/api/QueryClient';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {ISavingNewAttachment} from '@src/interfaces/attachments/ISavingAttachment';

export const convertLocalAttachments = <
	T extends {fields: Record<string, any> & {newAttachments?: ISavingNewAttachment[]}}
>(
	key: string,
	queryKey: {
		savingData: (id: string) => string[];
	}
): IAttachment[] => {
	const savedProblemData = queryClient.getQueryData<T>(queryKey.savingData(key));
	if (savedProblemData) {
		const newAttachments = savedProblemData.fields.newAttachments;
		return newAttachments?.map(att => ({
			id: att.key,
			full: att.file ? {url: URL.createObjectURL(att.file), type: att.file?.type} : undefined,
			preview: att.file
				? {url: URL.createObjectURL(att.file), type: att.file?.type}
				: undefined,
			canvas: att.drawData,
			original: att.originalFile
				? {url: URL.createObjectURL(att.originalFile), type: att.file?.type}
				: undefined
		})) as IAttachment[];
	}
	return [];
};
