import {useMutation} from '@tanstack/react-query';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';
import {makeAddRespUserRequest} from '@src/api/backend/users';
import {respUsersQueryKeys} from '@src/api/cache/users/keys';

// Добавляет нового ответственного

export const useAddRespUser = () =>
	useMutation({
		mutationKey: respUsersQueryKeys.addRespUser(),
		mutationFn: (fields: IAddingRespUser) => makeAddRespUserRequest(fields),
		onError: () => addErrorToast('Ошибка', 'при добавлении ответственного'),
		onSuccess: () => {
			addSuccessToast(
				'Успех',
				`Заявка принята и отправлена на модерацию.
		 		Новый пользователь будет добавлен в систему в течение 10 минут (в период с 8.00 до 22.00 по московскому времени) и автоматически назначен ответственным по данному нарушению.
		 		Для выбора данного пользователя в качестве ответственного в следующем нарушении просим дождаться внесения изменений в систему`
			);
		}
	});
