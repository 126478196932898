import {IContract} from '@tehzor/tools/interfaces/contracts';

export const makeTitle = (contract?: IContract) => {
	if (contract?.number) {
		return `Договор №${contract.number}`;
	}
	if (contract) {
		return 'Договор';
	}
	return 'Добавить договор';
};