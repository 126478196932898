import {IGetSpaceTypeDecorationResponse} from '@src/api/backend/spaceTypeDecoration';
import {spaceTypesDecorationsQueryKeys} from '@src/api/cache/spaceTypeDecorations/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractSpaceDecorationTypesAllIds,
	extractSpaceTypeDecorationsAsArray,
	extractSpaceTypeDecorationsAsMap,
	extractSpaceTypeDecorationsBySetIdAsArray,
	extractSpaceTypeDecorationsForSetsAsArray
} from './selectors';
import {useCallback} from 'react';

export const useSpaceTypeDecorations = <T = IGetSpaceTypeDecorationResponse>(
	select?: (data: IGetSpaceTypeDecorationResponse) => T
) =>
	useQuery<IGetSpaceTypeDecorationResponse, IError, T>({
		queryKey: spaceTypesDecorationsQueryKeys.list(),
		meta: {
			error: 'при загрузке типов отделки'
		},
		select
	});

export const useSpaceDecorationTypesAllIds = () =>
	useSpaceTypeDecorations(extractSpaceDecorationTypesAllIds);

export const useSpaceTypeDecorationsAsMap = () =>
	useSpaceTypeDecorations(extractSpaceTypeDecorationsAsMap);

export const useSpaceTypeDecorationsAsArray = () =>
	useSpaceTypeDecorations(extractSpaceTypeDecorationsAsArray);

export const useSpaceTypeDecorationsForSetsAsArray = (decorationSetIds: string[]) => {
	const selector = useCallback(
		(data: IGetSpaceTypeDecorationResponse) =>
			extractSpaceTypeDecorationsForSetsAsArray(data, decorationSetIds),
		[decorationSetIds]
	);
	return useSpaceTypeDecorations(selector);
};

export const useSpaceTypeDecorationsBySetId = <T = IGetSpaceTypeDecorationResponse>(
	id: string,
	select?: (data: IGetSpaceTypeDecorationResponse) => T
) =>
	useQuery<IGetSpaceTypeDecorationResponse, IError, T>({
		queryKey: spaceTypesDecorationsQueryKeys.listById(id),
		meta: {
			error: 'Ошибка при загрузке декораций по ID'
		},
		select
	});

export const useSpaceTypeDecorationsBySetIdAsArray = (id: string) =>
	useSpaceTypeDecorationsBySetId(id, extractSpaceTypeDecorationsBySetIdAsArray);
