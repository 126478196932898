import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useEffect} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import ObjectPageBreadcrumbs from '../ProblemsPage/components/ObjectPageBreadcrumbs';
import {AddButton} from './components/AddButton';
import {PlansTable} from './components/table/PlansTable';
import './PlansPage.less';
import {addErrorToast} from '@src/utils/toasts';

export const PlansPage = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();

	const sectionsMenu = useObjectSectionsMenu(objectId || '');
	useObjectAppHeader(objectId || '', {sectionsMenu});

	useEffect(() => {
		if (!objectId) {
			addErrorToast('Ошибка на странице', 'отсутствует объект');
		}
	}, [objectId]);

	if (!objectId) {
		return null;
	}

	return (
		<div className="page-cont plans-page">
			<ObjectPageBreadcrumbs objectId={objectId}>
				<AddButton />
			</ObjectPageBreadcrumbs>
			<PlansTable />
		</div>
	);
};
