import './InspectionInfoDesktopCell.less';
import {PhotoPreview, TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import classNames from 'classnames';
import {CellProps} from 'react-table';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useInspectionViewer} from '@src/core/hooks/imagesViewers/desktop/useInspectionViewer';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import InspectionInfo from '@src/components/tableCells/InspectionInfo';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';

const InspectionInfoDesktopCell = (props: CellProps<{data: IInspection}>) => {
	const {row} = props;
	const inspection = row.original.data;

	const [imagesAttachments] = useSplitAttachments(inspection);
	const images = useAttachmentsImages(imagesAttachments);
	const imagesData = useAttachmentsImagesObjects(imagesAttachments);
	const [imagesViewer, openImage] = useInspectionViewer(images, inspection.id, imagesData);

	return (
		<>
			<div
				className={classNames('d-inspection-info-cell', {
					'd-inspection-info-cell_with-padding': row.depth > 0
				})}
			>
				<ClickPreventWrap>
					<TableRowSelect
						{...row.getToggleRowSelectedProps()}
						style={{marginTop: row.depth > 0 ? '6px' : '12px'}}
					/>
				</ClickPreventWrap>

				<div className="d-inspection-info-cell__info">
					<div className="d-inspection-info-cell__info-text">
						<InspectionInfo
							style={{marginBottom: row.depth > 0 ? '12px' : '16px'}}
							data={inspection}
							depth={row.depth}
						/>
						{inspection.description !== undefined && (
							<div className="d-inspection-info-cell__desc">
								{inspection.description}
							</div>
						)}
					</div>

					{images.length > 0 ? (
						<>
							<ClickPreventWrap>
								<PhotoPreview
									style={{width: '76px'}}
									url={images.length ? images[0] : undefined}
									data={0}
									onClick={openImage}
								/>
							</ClickPreventWrap>
							{imagesViewer}
						</>
					) : null}
				</div>
			</div>

			{images.length ? <ClickPreventWrap>{imagesViewer}</ClickPreventWrap> : null}
		</>
	);
};

export default InspectionInfoDesktopCell;
