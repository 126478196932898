import {IGetOwnerAcceptancesResponse} from '@src/api/backend/ownerAcceptances';
import {useQuery} from '@tanstack/react-query';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import IError from '@tehzor/tools/interfaces/IError';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOwnerAcceptancesPageSettings} from '@src/store/modules/settings/pages/ownerAcceptances/selectors';
import {extractOwnerAcceptancesAsArray} from '@src/core/hooks/queries/ownerAcceptances/selectors';

export const useOwnerAcceptances = <T = IGetOwnerAcceptancesResponse>(
	objectId = 'all',
	select?: (data: IGetOwnerAcceptancesResponse) => T
) => {
	const {filters, sort, offset, pageSize} = useAppSelector(s =>
		extractOwnerAcceptancesPageSettings(s, objectId)
	);

	return useQuery<IGetOwnerAcceptancesResponse, IError, T>({
		queryKey: [
			...ownerAcceptancesQueryKeys.listWithObjectId(objectId),
			filters,
			sort,
			offset,
			pageSize
		],
		meta: {
			error: 'при загрузке списка передач собственнику'
		},
		select
	});
};

export const useOwnerAcceptancesAsArray = (objectId = 'all') =>
	useOwnerAcceptances(objectId, extractOwnerAcceptancesAsArray);
