import { forwardRef, Ref, UIEvent, useMemo } from 'react';
import * as React from 'react';
import SimpleBar from 'simplebar-react';
import MobileDetect from 'mobile-detect';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';
import './Scrollbar.less';

export interface IScrollbarsProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	simpleBarProps?: React.ComponentProps<typeof SimpleBar>;

	onScroll?: (event: UIEvent<HTMLDivElement>) => void;
}

const Scrollbar = forwardRef((
	{simpleBarProps, onScroll, ...props}: IScrollbarsProps,
	ref?: Ref<HTMLDivElement>
) => {
	const isMobile = useMemo(() => {
		const md = new MobileDetect(window.navigator.userAgent);
		return !!md.mobile();
	}, [window.navigator.userAgent]);

	return (isMobile ? (
		<div
			{...props}
			onScroll={onScroll}
			ref={ref}
		/>
	) : (
		<SimpleBar
			scrollableNodeProps={{onScroll, ref}}
			{...props}
			{...simpleBarProps}
		/>
	));
});

Scrollbar.displayName = 'Scrollbar';
export {Scrollbar};