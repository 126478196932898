import {ReactNode} from 'react';
import {ValueTypeId} from '@tehzor/tools/interfaces/fields';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';

export const getValue = (value?: unknown, valueType?: ValueTypeId): ReactNode | null => {
	if (!value) {
		return null;
	}

	switch (valueType) {
		case ValueTypeId.DATE: {
			if (typeof value === 'number') {
				return format(value, dateFormat);
			}
			return null;
		}

		case ValueTypeId.SELECT: {
			if (Array.isArray(value)) {
				const stringArray = value.map(v => getValue(v));
				return stringArray.filter(val => val !== undefined).join(', ');
			}
			if (typeof value === 'string') {
				return value;
			}
			return null;
		}

		case ValueTypeId.LINK: {
			if (typeof value === 'string') {
				return (
					<a
						className="custom-field__link"
						href={value}
					>
						{value}
					</a>
				);
			}
			return null;
		}

		default: {
			if (typeof value === 'string') {
				return value;
			}
			return null;
		}
	}
};
