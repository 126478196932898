import {useMutation} from '@tanstack/react-query';
import {addErrorToast} from '@src/utils/toasts';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {ISavingCheck} from '@src/interfaces/saving/ISavingCheck';
import {makeCheckEditRequest} from '@src/api/backend/check/edit';
import {queryClient} from '@src/api/QueryClient';

export interface IMakeTaskEditRequestData {
	objectId: string;
	checkId: string;
	fields: ISavingCheck;
}

export const useEditCheck = () =>
	useMutation({
		mutationKey: checksQueryKeys.edit(),
		mutationFn: ({objectId, checkId, fields}: IMakeTaskEditRequestData) =>
			makeCheckEditRequest(objectId, checkId, fields),
		onError: () => addErrorToast('Ошибка', 'при обновлении проверки'),
		onSuccess: data => {
			queryClient.setQueryData(
				[...checksQueryKeys.details(), data.id, data.objectId],
				check => (check ? {...check, ...data} : data)
			);
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.latest()});
		}
	});
