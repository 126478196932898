import {ResponsiblesFilter} from '@src/components/EntitiesFilters/components/ResponsiblesFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useAcceptorsGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';
import {memo, useCallback} from 'react';

interface IAcceptorsFilterProps {
	objectId: string;
	acceptors?: string[];
	label?: string;
}

export const AcceptorsFilter = memo(({objectId, acceptors, label}: IAcceptorsFilterProps) => {
	const {data: workingGroups} = useAcceptorsGroupsAsArray(objectId);
	const {dispatch} = useEntitiesFiltersCtx();

	const handleChange = useCallback(
		(value?: string[]) => {
			dispatch({acceptors: value});
		},
		[dispatch]
	);

	return (
		<ResponsiblesFilter
			respUsers={acceptors}
			workingGroups={workingGroups}
			onChange={handleChange}
			label={label}
		/>
	);
});
