import {memo} from 'react';
import {useTranslation} from 'react-i18next';

interface IAreaProps {
	planned?: number;
	actual?: number;
}

export const Area = memo(({planned, actual}: IAreaProps) => {
	const {t} = useTranslation();
	return (
		<div className="space-page__area">
			{planned ? (
				<>
					<div className="space-page__area-title">
						{t('spacePage.info.plannedArea.planned')}
					</div>
					<div className="space-page__area-value">
						{planned}
						&nbsp;м
						<sup>2</sup>
					</div>
				</>
			) : null}
			{actual ? (
				<>
					<div className="space-page__area-title">
						{t('spacePage.info.plannedArea.actual')}
					</div>
					<div className="space-page__area-value">
						{actual}
						&nbsp;м
						<sup>2</sup>
					</div>
				</>
			) : null}
		</div>
	);
});
