import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';

export interface IOfflineJournalFiltersState extends Record<string, unknown> {
	actions?: string[];
	statuses?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
}

export default createReducer<IOfflineJournalFiltersState>(
	{},
	{
		[types.CHANGE_FILTERS]: (state, {payload}: {payload: IOfflineJournalFiltersState}) => {
			state = payload;
			return state;
		},
		[types.CLEAR_FILTERS]: state => {
			const filters = {} as IOfflineJournalFiltersState;
			state = filters;
			return state;
		}
	}
);
