import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {ISavingOwnerAcceptance} from '@src/interfaces/saving/ISavingOwnerAcceptance';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {MutableRefObject, RefObject} from 'react';
import {AddingOwnerAcceptanceView} from '../AddingOwnerAcptDialog';
import {IAddingAcceptanceRefProps} from '../components/AddingAcceptance';

interface ISaveOwnerAcceptanceProps {
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingAcceptanceRefProps>;
	localOwnerAcceptancesIds: string[];
	view: AddingOwnerAcceptanceView;
	online: boolean;

	updateLatest: () => Promise<void>;
	selectedOwnerAcceptanceId?: string;

	success: MutableRefObject<boolean>;
	onClose: () => void;
	setSaving?: (value: boolean) => void
}

export const saveOwnerAcceptance = async ({
	addingEntityRef,
	localOwnerAcceptancesIds,
	view,
	online,
	updateLatest,
	selectedOwnerAcceptanceId,
	success,
	onClose,
	setSaving
}: ISaveOwnerAcceptanceProps) => {
	let isAccepted = false;
	if (addingEntityRef.current) {
		let ownerAcceptanceId = selectedOwnerAcceptanceId;

		const extraLinks: IProblem['links'] = {ownerAcceptanceId};

		const savingData = await addingEntityRef.current.getSavingData(true);
		if (!savingData) {
			return;
		}

		if (view === 'acceptance') {
			ownerAcceptanceId = (await addingEntityRef.current.save(savingData)) as string;
			if (
				(savingData as ISavingOwnerAcceptance).resolution
				=== OwnerAcceptanceResolutionId.ACCEPTED
			) {
				isAccepted = true;
			}
		}
		if (view === 'problem') {
			if (addingEntityRef.current.saveCache && savingData) {
				if (ownerAcceptanceId && localOwnerAcceptancesIds.includes(ownerAcceptanceId)) {
					await addingEntityRef.current.saveCache(savingData, extraLinks);
				} else {
					const cachedData = await addingEntityRef.current.saveCache(
						savingData,
						extraLinks
					);
					if (cachedData) {
						await addingEntityRef.current.save(
							savingData,
							extraLinks,
							cachedData.cacheKey
						);
					}
				}
			}
			if (ownerAcceptanceId && online) {
				void updateLatest();
			}
		}
	}
	if (!addingEntityRef.current || view === 'problem' || (view === 'acceptance' && isAccepted)) {
		onClose();
	}
	success.current = true;
	if (success.current && view === "problem") {
		setSaving?.(false)
	}
};
