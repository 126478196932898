import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import CellWrap from '@src/components/tableCells/CellWrap';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemFixCell} from '@src/components/tableCells/ProblemFixCell';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {ProblemStatusCell} from '@src/components/tableCells/ProblemStatusCell';
import {useOwnerAcceptanceProblemCommentsByProblem} from '@src/core/hooks/queries/problems/hooks';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';
import {useMemo} from 'react';
import {Column} from 'react-table';
import {useTranslation} from 'react-i18next';

export const useDesktopColumns = (objectId: string, acceptanceId: string) => {
	const {t} = useTranslation();
	const {data: ownerAcceptanceProblemCommentsByProblem} =
		useOwnerAcceptanceProblemCommentsByProblem(objectId, acceptanceId);

	return useMemo<Array<Column<IOwnerAcceptanceEntity>>>(
		() => [
			{
				id: 'number',
				Header: HeaderCellWithRowSelectWrap(
					t('ownerAcceptancePage.table.columns.number.header')
				),
				Cell: CellWrap({
					problem: ProblemInfoCellWrap(
						ProblemInfoDesktopCell,
						undefined,
						ownerAcceptanceProblemCommentsByProblem
					)
				}),
				width: 200,
				minWidth: 200
			},
			{
				id: 'status',
				Header: t('ownerAcceptancePage.table.columns.status.header'),
				Cell: CellWrap({
					problem: ProblemStatusCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'category',
				Header: t('ownerAcceptancePage.table.columns.category.header'),
				Cell: CellWrap({
					problem: CategoryCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'fix',
				Header: t('ownerAcceptancePage.table.columns.fix.header'),
				Cell: CellWrap({problem: ProblemFixCell}),
				width: 80,
				minWidth: 80
			}
		],
		[ownerAcceptanceProblemCommentsByProblem]
	);
};
