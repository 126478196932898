import {useReducer} from 'react';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {errorsFns, init} from '@src/core/hooks/states/useEditableProblemTagsSetState/state';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';

export const useEditableProblemTagsSetState = (data: {
	problemTagsSet?: IProblemTagsSet;
	problemTags?: IProblemTag[];
}) => useReducer(createReducer(init, errorsFns), data, init);
