import {EntityInnerTable} from '@tehzor/ui-components';
import { ReactElement, useCallback } from 'react';
import {Column} from 'react-table';
import SelectionRow from './SelectionRow';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import {formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useInternalAcceptanceEntities} from '@src/core/hooks/queries/internalAcceptance/hook';

interface ITableProps {
	objectId: string;
	internalAcceptanceId: string;
	columns: Array<Column<IInternalAcceptanceEntity>>;
	hideHead?: boolean;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

const Table = ({
	objectId,
	internalAcceptanceId,
	columns,
	hideHead,
	selectedRows,
	onSelectedRowsChange
}: ITableProps): ReactElement => {
	const {pushPath} = useChangePath();
	const entities = useInternalAcceptanceEntities(objectId, internalAcceptanceId);

	const handleRowClick = useCallback((entity: IInternalAcceptanceEntity) => {
		switch (entity.type) {
			case 'problem':
				return pushPath(formProblemLink(objectId, entity.data.id));
			case 'inspection':
				return pushPath(formInspectionLink(objectId, entity.data.id));
			default:
				return undefined;
		}
	}, []);

	return (
		<EntityInnerTable
			className="internal-acceptance-page__d-entities"
			columns={columns}
			data={entities}
			hideHead={hideHead}
			selectable
			selectedRows={selectedRows}
			onRowClick={handleRowClick}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={props => (
				<SelectionRow
					{...props}
					objectId={objectId}
					internalAcceptanceId={internalAcceptanceId}
				/>
			)}
		/>
	);
};

export default Table;
