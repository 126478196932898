import {useCallback, useRef, useState, memo} from 'react';
import './AddingMeterDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {AddingMeter, IAddingMeterRefProps} from '@src/components/AddingMeter/AddingMeter';
import {useTranslation} from "react-i18next";

interface IAddingMeterDialogProps {
	companyId?: string;
	objectId?: string;
	spaceId?: string;
	isOpen: boolean;

	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

/**
 * Окно добавления прибора учёта
 */
export const AddingMeterDialog = memo((props: IAddingMeterDialogProps) => {
	const {t} = useTranslation();
	const {companyId, objectId, spaceId, isOpen, onClose, onSuccess} = props;

	const viewRef = useRef<IAddingMeterRefProps>(null);
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const handleSave = useCallback(() => {
		if (viewRef.current) {
			viewRef.current.save();
		} else {
			onClose();
		}
		success.current = true;
	}, [onClose]);

	const handleCancel = useCallback(() => {
		if (viewRef.current) {
			void viewRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onSuccess]);

	return (
		<Dialog
			className={{
				root: 'adding-meter-dialog',
				title: 'adding-meter-dialog__title',
				body: 'adding-meter-dialog__body'
			}}
			isOpen={isOpen}
			title={t('components.addingMeterDialog.dialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('components.addingMeterDialog.dialog.button.cancel')}
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label={t('components.addingMeterDialog.dialog.button.accent')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			<AddingMeter
				companyId={companyId}
				objectId={objectId}
				spaceId={spaceId}
				saving={saving}
				onClose={onClose}
				setSaving={setSaving}
				ref={viewRef}
			/>
		</Dialog>
	);
});
