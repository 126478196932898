import {IContract} from '@tehzor/tools/interfaces/contracts';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import { memo, useCallback, useState } from 'react';
import {useEditableContract} from '../EditableContract/hooks/useEditableContract';
import './EditableContractDialog.less';
import {makeTitle} from './utils/makeTitle';
import {useAddContract} from '@src/core/hooks/mutations/contracts/useAddContract';
import {useEditContract} from '@src/core/hooks/mutations/contracts/useEditContract';

interface IEditableContractDialogProps {
	contract?: IContract;
	isOpen: boolean;
	onClose: () => void;
}

export const EditableContractDialog = memo(({
	contract,
	isOpen,
	onClose
}: IEditableContractDialogProps) => {
	const [saving, setSaving] = useState(false);
	const {fields, reset, getSavingData, isBlocking} = useEditableContract(contract);

	const addContract = useAddContract();
	const editContract = useEditContract();

	const save = useCallback(async () => {
		setSaving(true);
		const fields = await getSavingData();
		if (!fields) {
			setSaving(false);
			return;
		}

		try {
			if (contract) {
				await editContract({contractId: contract.id, fields});
			} else {
				await addContract({fields});
			}

			onClose();
			setSaving(false);
		} catch (error) {
			setSaving(false);
		}
	}, [getSavingData, contract]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
			setSaving(false);
		}
	}, [isBlocking, onClose]);

	const footer = (
		<ActionButtons className="editable-contract-dialog__action-buttons">
			<Button
				type="cancel"
				label="Отменить"
				disabled={saving}
				onClick={handleClose}
			/>
			<Button
				type="accent-blue"
				label="Сохранить"
				disabled={saving}
				onClick={save}
			/>
		</ActionButtons>
	);

	return (
		<Dialog
			className="editable-contract-dialog"
			isOpen={isOpen}
			title={makeTitle(contract)}
			footer={footer}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
});
