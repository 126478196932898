import MobileMenu from './Menu.mobile';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import ClaimExportDialog from './ClaimExportDialog';

interface IMobileActionsProps {
	objectId: string;
	warrantyClaim: ILinkedWarrantyClaim;
}

const ActionsMobile = ({objectId, warrantyClaim}: IMobileActionsProps) => {
	const permissions = useWarrantyClaimsPermissions(objectId);

	return (
		<>
			{permissions.canExport && (
				<ClaimExportDialog
					objectId={objectId}
					warrantyClaimId={warrantyClaim.id}
				/>
			)}

			<MobileMenu
				objectId={objectId}
				// check={check}
				warrantyClaim={warrantyClaim}
				canDeleteWarrantyClaim={permissions.canDelete}
				canMoveWarrantyClaim={permissions.canMove}
				canEditWarrantyClaim={permissions.canEdit}
			/>
		</>
	);
};

export default ActionsMobile;
