export const markerProblemPath = (x: number, y: number, s: number) =>
    `M ${x - 21.8718 * s} ${y - 22.9678 * s} C ${x - 29.2966 * s} ${y - 29.2264 * s} ${
        x - 34 * s
    } ${y - 38.565 * s} ${x - 34 * s} ${y - 49 * s} C ${x - 34 * s} ${y - 67.7776 * s} ${
        x - 18.7776 * s
    } ${y - 83 * s} ${x} ${y - 83 * s} C ${x + 18.7776 * s} ${y - 83 * s} ${x + 34 * s} ${
        y - 67.7776 * s
    } ${x + 34 * s} ${y - 49 * s} C ${x + 34 * s} ${y - 38.4034 * s} ${x + 29.15 * s} ${
        y - 28.938 * s
    } ${x + 21.5562 * s} ${y - 22.7056 * s} L ${x + 21.5366 * s} ${y - 22.6896 * s} L ${
        x + 21.5164 * s
    } ${y - 22.6738 * s} L ${x + 11.8618 * s} ${y - 15.1538 * s} C ${x + 7.3028 * s} ${
        y - 11.9784 * s
    } ${x + 4.3628 * s} ${y - 6.8332 * s} ${x + 4.2888 * s} ${y - 0.9986 * s} C ${
        x + 4.2888 * s
    } ${y - 0.9982 * s} ${x + 4.268 * s} ${y + 1.008 * s} ${x + 2.958 * s} ${y + 1.072 * s} H ${
        x - 2.2662 * s
    } C ${x - 2.886 * s} ${y + 1.042 * s} ${x - 4.016 * s} ${y + 0.836 * s} ${x - 4.222 * s} ${
        y - 1.012 * s
    } C ${x - 4.564 * s} ${y - 7.07 * s} ${x - 6.86 * s} ${y - 11.712 * s} ${x - 14 * s} ${
        y - 17 * s
    } Z`;

    export const markerStructurePath = (x: number, y: number, s: number) =>
    `M ${x + 30 * s} ${y} C ${x} ${y} ${x} ${y} ${x} ${y - 30 * s} C ${x} ${y - 60 * s} ${x} ${
        y - 60 * s
    } ${x + 30 * s} ${y - 60 * s} C ${x + 60 * s} ${y - 60 * s} ${x + 60 * s} ${y - 60 * s} ${
        x + 60 * s
    } ${y - 30 * s} C ${x + 60 * s} ${y} ${x + 60 * s} ${y} ${x + 30 * s} ${y} Z`;