import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {TFunction} from 'i18next';

export const createProblemTagsDifference = (
	prev: string[] | undefined,
	next: string[] | undefined,
	problemTags: IProblemTag[] | undefined,
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev: prev
		? prev.map(item => {
				const tag = problemTags?.find(el => el.id === item);
				return {
					name: tag?.name ?? '',
					color: tag?.color ?? '#FFFFFF'
				};
		  })
		: undefined,
	next: next
		? next.map(item => {
				const tag = problemTags?.find(el => el.id === item);
				return {
					name: tag?.name ?? '',
					color: tag?.color ?? '#FFFFFF'
				};
		  })
		: undefined,
	type: HistoryTypeId.TAG,
	name: t('createDifference.createProblemTagsDifference')
});
