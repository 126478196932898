import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import {IDeleteFiltersItemPayload, IDeleteFiltersItemsPayload} from '../actions';

export interface IBuildingStatisticsFiltersState {
	companies?: string[];
	objects?: string[];
	users?: string[];
	contractors?: Record<string, true | string[]>;
}

export const getInitialState = () => ({});

export default createReducer<IBuildingStatisticsFiltersState>(getInitialState(), {
	[types.CHANGE_FILTERS]: (state, {payload}: {payload?: IBuildingStatisticsFiltersState}) =>
		payload,
	[types.CLEAR_FILTERS]: state => {
		if (!state.companies && !state.objects && !state.users && !state.contractors) {
			return state;
		}
		return getInitialState();
	},
	[types.DELETE_FILTERS_ITEM]: (state, {payload}: {payload: IDeleteFiltersItemPayload}) => {
		if (payload.field === 'contractors') {
			if (state.contractors) {
				delete state.contractors[payload.value];
			}
		} else if (payload.field === 'contractorsUsers') {
			if (state.contractors) {
				for (const id in state.contractors) {
					if (
						state.contractors.hasOwnProperty(id)
						&& Array.isArray(state.contractors[id])
					) {
						state.contractors[id] = (state.contractors[id] as string[]).filter(
							userId => userId !== payload.value
						);
						if ((state.contractors[id] as string[]).length === 0) {
							delete state.contractors[id];
						}
					}
				}
			}
		} else if (state[payload.field]) {
			state[payload.field] = state[payload.field].filter(
				(id: string) => id !== payload.value
			);
		}
	},
	[types.DELETE_FILTERS_ITEMS]: (state, {payload}: {payload: IDeleteFiltersItemsPayload}) => {
		if (state[payload.field]) {
			state[payload.field] = state[payload.field].filter(
				(id: string) => !payload.value.includes(id)
			);
		}
	}
});
