import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import {makeWarrantyClaimDeleteRequest} from '@src/api/backend/warrantyClaim';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {deleteWarrantyClaimActions} from '@src/store/modules/entities/warrantyClaim/actions';

interface IDeleteWarrantyClaimParams {
	objectId: string;
	warrantyClaimId: string;
}

export const useDeleteWarrantyClaim = () => {
	const dispatch = useAppDispatch();
	return useMutation({
		mutationKey: warrantyClaimsQueryKeys.delete(),
		mutationFn: (params: IDeleteWarrantyClaimParams) => {
			const {objectId, warrantyClaimId} = params;
			return makeWarrantyClaimDeleteRequest(objectId, warrantyClaimId);
		},
		onSuccess: (_, params: IDeleteWarrantyClaimParams) => {
			const {objectId, warrantyClaimId} = params;
			void Promise.all([
				queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.lists()}),
				queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.latest()}),
				queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()}),
				queryClient.invalidateQueries({
					queryKey: [...spacesQueryKeys.warrantyClaims(), objectId]
				})
			]);
			dispatch(deleteWarrantyClaimActions.success(objectId, warrantyClaimId, true));
			addInfoToast('Удалено', 'Обращение успешно удалено');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при удалении обращения');
		}
	});
};
