import {
	useState,
	useEffect,
	useRef,
	memo,
	CSSProperties,
	MouseEvent,
	KeyboardEvent,
	DragEvent
} from 'react';

import './VisualContentCarousel.less';
import classNames from 'classnames';

import anime, {AnimeInstance} from 'animejs';
import debounce from 'lodash/debounce';

import TouchRecognizer, {
	ITouchRecognizerProps,
	PanEvent,
	SwipeDirection
} from '../various/TouchRecognizer/TouchRecognizer2';
import {convertClassNames} from '../../utils/convertClassNames';
import Content from './components/Content';
import {ContentArrowButton} from './components/ContentArrowButton';

const recognizerDragOptions: ITouchRecognizerProps['dragOptions'] = {startAxis: 'x', lockAxis: 'x'};

interface IPlanCarouselProps {
	className?:
		| string
		| {
				root?: string;
				touchRoot?: string;
				image?: string;
				arrowButton?: string;
		  };
	style?: CSSProperties;
	data: string[];
	titles?: string[];
	backgroundSize?: string;
	heightRatio?: number;
	animationDuration?: number;
	onClick?: (index: number) => void;
}

export const VisualContentCarousel = memo(
	({
		className,
		style,
		data = [],
		titles,
		backgroundSize = 'contain',
		heightRatio = 1,
		animationDuration = 250,
		onClick
	}: IPlanCarouselProps) => {
		const [currentValue, setCurrentValue] = useState<number>(0);
		/**
		 * Корневой элемент
		 */
		const frameRef = useRef<HTMLDivElement | null>(null);

		/**
		 * Обёртка изображений
		 */
		const wrapperRef = useRef<HTMLDivElement | null>(null);

		/**
		 * Объект анимации wrapper'а
		 */
		const wrapperAnimationRef = useRef<AnimeInstance>();

		/**
		 * Ширина корневого элемента
		 */
		const [frameWidth, setFrameWidth] = useState(0);

		/**
		 * Текущее смещение wrapper'а
		 */
		const wrapperXRef = useRef(0);

		const isArrowNavigation = !!(data.length > 1);
		/**
		 * Сохраняет ширину корневого элемента
		 */
		const saveFrameWidth = () => {
			if (frameRef.current) {
				setFrameWidth(frameRef.current.offsetWidth);
			} else {
				setFrameWidth(0);
			}
		};

		/**
		 * Устанавливает высоту корневого элемента при наличии параметра heightRatio
		 */
		const setFrameHeight = () => {
			if (heightRatio !== undefined && frameRef.current) {
				frameRef.current.style.height = `${frameWidth * heightRatio}px`;
			}
		};

		/**
		 * Устанавливает ширину wrapper'а
		 */
		const setWrapperWidth = () => {
			if (wrapperRef.current) {
				wrapperRef.current.style.width = `${frameWidth * data.length}px`;
			}
		};

		/**
		 * Устанавливает позицию wrapper'а
		 *
		 * @param offset смещение, если не задано, то берётся this.wrapperX
		 */
		const setWrapperPosition = (offset?: number) => {
			if (wrapperRef.current) {
				wrapperRef.current.style.transform = `translateX(${
					offset ?? wrapperXRef.current
				}px)`;
			}
		};
		const setWrapperX = (newX: number) => {
			wrapperXRef.current = newX;
			setWrapperPosition(newX);
		};

		/**
		 * Останавливает анимацию движения wrapper'а
		 */
		const stopWrapperAnimation = () => {
			if (wrapperAnimationRef.current) {
				wrapperAnimationRef.current.pause();
				wrapperAnimationRef.current = undefined;
			}
		};

		/**
		 * Запускает анимацию движения wrapper'а для отображения текущего элемента
		 */
		const startWrapperAnimation = () => {
			stopWrapperAnimation();
			if (wrapperRef.current) {
				const targets = {offset: +wrapperRef.current.style.transform.slice(11, -3)};
				wrapperAnimationRef.current = anime({
					targets,
					offset: -currentValue * frameWidth,
					easing: 'cubicBezier(0.32, 0.72, 0.37, 0.95)',
					duration: animationDuration,
					update: () => {
						setWrapperX(targets.offset + currentValue);
						setWrapperPosition();
					}
				});
			}
		};

		/**
		 * Обрабатывает событие изменения размера элемента
		 */

		const handleResize = debounce(() => {
			if (frameRef.current) {
				setFrameWidth(frameRef.current.offsetWidth);
			}
			saveFrameWidth();
			setWrapperX(-currentValue * frameWidth);
			setFrameHeight();
			setWrapperWidth();
			setWrapperPosition();
		}, 150);

		useEffect(() => {
			startWrapperAnimation();
		}, [currentValue]);

		useEffect(() => {
			handleResize();
			window.addEventListener('resize', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, [handleResize]);

		/**
		 * Инициирует изменение значения
		 *
		 * @param value новое значение
		 */
		const changeValue = (newValue: number) => {
			setCurrentValue(newValue);
		};

		/**
		 * Переводит к следующему изображению
		 */

		const next = () => {
			if (currentValue < data.length - 1) {
				changeValue(currentValue + 1);
			}
		};

		/**
		 * Переводит к предыдущему изображению
		 */

		const prev = () => {
			if (currentValue > 0) {
				changeValue(currentValue - 1);
			}
		};

		/**
		 * Обрабывает событие по кнопке перехода к следующему изображению
		 *
		 * @param event событие
		 */

		const handleNextBtnClick = (event: MouseEvent) => {
			event.stopPropagation();
			next();
		};

		/**
		 * Обрабывает событие по кнопке перехода к предыдущему изображению
		 *
		 * @param event событие
		 */
		const handlePrevBtnClick = (event: MouseEvent) => {
			event.stopPropagation();
			prev();
		};

		/**
		 * Обрабатывает событие начала движения
		 */
		const handlePanStart = () => {
			stopWrapperAnimation();
		};

		/**
		 * Обрабатывает событие движения
		 *
		 * @param event событие
		 */
		const handlePanMove = (event: PanEvent) => {
			if (data.length === 1) {
				return;
			}
			let newWrapperX = wrapperXRef.current + event.deltaX;
			if (newWrapperX > 0) {
				// Натянутое движение за границей слева
				newWrapperX = newWrapperX ** 0.5 * 2;
			} else if (newWrapperX < -frameWidth * (data.length - 1)) {
				const a = -frameWidth * (data.length - 1);
				// Натянутое движение за границей справа

				newWrapperX = a - (a - newWrapperX) ** 0.5 * 2;
			} else {
				// Проверка нахождения смещения в пределах диапазона от предыдущего изображения до следующего
				newWrapperX = Math.max(
					Math.min(newWrapperX, -frameWidth * (currentValue - 1)),
					-frameWidth * (currentValue + 1)
				);
				setWrapperX(newWrapperX);
			}
			setWrapperPosition(newWrapperX);
		};

		/**
		 * Обрабатывает событие завершения движения
		 *
		 * @param event событие
		 */
		const handlePanEnd = (event: PanEvent) => {
			const distance = event.initialClientX - event.clientX;
			if (Math.abs(distance) > frameWidth * 0.25) {
				if (distance < 0 && currentValue > 0) {
					changeValue(currentValue - 1);
				}
				if (distance > 0 && currentValue < data.length - 1) {
					changeValue(currentValue + 1);
				}
			}
			return startWrapperAnimation();
		};

		/**
		 * Обрабатывает событие swipe
		 *
		 * @param event событие
		 * @param direction направление жеста
		 */
		const handleSwipe = (event: PanEvent, direction?: SwipeDirection) => {
			if (direction) {
				if (direction === 'right' && currentValue > 0) {
					changeValue(currentValue - 1);
				}
				if (direction === 'left' && currentValue < data.length - 1) {
					changeValue(currentValue + 1);
				}
			}
			return startWrapperAnimation();
		};

		/**
		 * Обрабатывает событие click
		 */
		const handleClick = () => {
			if (onClick) {
				onClick(currentValue);
			}
		};

		/**
		 * Обрабатывает событие keyDown для перелистывания стрелками
		 *
		 * @param event событие
		 */
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft') {
				prev();
			}
			if (event.key === 'ArrowRight') {
				next();
			}
		};

		/**
		 * Предотвращает перетаскивание
		 *
		 * @param event событие
		 */
		const preventDrag = (event: DragEvent) => {
			event.preventDefault();
			return false;
		};

		/**
		 * Сохраняет ссылку на крневой элемент
		 *
		 * @param element dom-элемент
		 */
		const saveRef = (element: HTMLDivElement | null) => {
			frameRef.current = element;
			saveFrameWidth();
			setFrameHeight();
			setWrapperWidth();
		};

		/**
		 * Сохраняет ссылку на wrapper
		 *
		 * @param element dom-элемент
		 */
		const saveWrapperRef = (element: HTMLDivElement | null) => {
			wrapperRef.current = element;
		};

		const classes = convertClassNames(className);
		return (
			<>
				<div
					className={classNames(
						'content-carousel',
						{'content-carousel_clickable': !!onClick},
						classes.root
					)}
					style={style}
				>
					<TouchRecognizer
						className={classNames('content-carousel__touch-root', classes.touchRoot)}
						dragOptions={recognizerDragOptions}
						tabIndex={-1}
						role="list"
						onPanStart={handlePanStart}
						onPanMove={handlePanMove}
						onPanEnd={handlePanEnd}
						onSwipe={handleSwipe}
						onResize={handleResize}
						onTap={handleClick}
						onKeyDown={handleKeyDown}
						elementRef={saveRef}
					>
						<div
							className="content-carousel__wrapper"
							onDragStart={preventDrag}
							ref={saveWrapperRef}
						>
							{data.map((url, index) => (
								<Content
									key={url}
									className={classNames('content-carousel__img', classes.image)}
									style={{backgroundSize}}
									url={url}
									suspend={Math.abs(currentValue - index) > 2}
								/>
							))}
						</div>
					</TouchRecognizer>
				</div>
				<div className="content-carousel__arrow-wrapper">
					{isArrowNavigation && (
						<ContentArrowButton
							className={classNames(
								'content-carousel__arrow-button content-carousel__arrow-button--left',
								currentValue === 0
									? 'content-carousel__arrow-button--disabled'
									: '',
								classes.arrowButton
							)}
							onClick={handlePrevBtnClick}
						/>
					)}
					{!!titles?.length && (
						<p className="content-carousel__img-title">{titles[currentValue]}</p>
					)}
					{isArrowNavigation && (
						<ContentArrowButton
							className={classNames(
								'content-carousel__arrow-button content-carousel__arrow-button--right',
								currentValue + 1 === data.length
									? 'content-carousel__arrow-button--disabled'
									: '',
								classes.arrowButton
							)}
							onClick={handleNextBtnClick}
						/>
					)}
				</div>
			</>
		);
	}
);
