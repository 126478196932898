import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IMarkers} from '@tehzor/tools/interfaces/markers/IMarkers';

export type IGetMarkersResponse = IMarkers;

/**
 * Возвращает маркеры нарушений
 * @param planId id плана
 * @param problemIds ids нарушений
 * @param taskIds ids задач
 * @param inspectionIds ids проверок
 * @param ownerAcceptanceIds ids передач собственникам
 */
export const requestMarkers = async (
	planId?: string,
	problemIds?: string[],
	inspectionIds?: string[],
	ownerAcceptanceIds?: string[],
	taskIds?: string[]
): Promise<IMarkers> => {
	const params = {
		planId,
		problemIds,
		taskIds,
		inspectionIds,
		ownerAcceptanceIds
	};
	const res = await httpRequests.withToken.get<IGetMarkersResponse>('markers', {params});
	return res.data;
};
