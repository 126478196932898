import {wsConnector} from '@src/api/backend/wsConnector';
import ISavingPlan from '@tehzor/tools/interfaces/plans/ISavingPlan';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';

export type IEditPlanResponse = IPlan;

/**
 * Изменяет плана
 *
 * @param objectId id объекта
 * @param plan данные плана
 */
export const makePlanEditRequest = (objectId: string, plan: ISavingPlan) =>
	wsConnector.sendAuthorizedRequest<IEditPlanResponse>('editPlan', {
		objectId,
		...plan
	});
