import {Responsibles} from '@src/components/editableFields/Responsibles';
import {
	IEditableInspectionAction,
	IEditableInspectionState
} from '@src/core/hooks/states/useEditableInspectionState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {isEqual} from 'lodash';
import {Dispatch, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface IEditableInspectionPerformersProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;

	editingState: IEditableInspectionState;
	editingDispatch: Dispatch<IEditableInspectionAction>;

	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	exception?: boolean;
}

export const EditableInspectionPerformers = ({
	objectId,
	stage,
	scope,
	editingState,
	editingDispatch,
	required,
	disabled,
	hasError,
	exception,
	...restProps
}: IEditableInspectionPerformersProps) => {
	const {t} = useTranslation();

	const {categoryId, planId, performers, performersActiveGroup, initialRuleParams} = editingState;
	const ruleParams: IRuleParams = useMemo(
		() => ({categoryId: categoryId || undefined, planId}),
		[categoryId, planId]
	);

	const handleChange = useCallback(
		(users: string[], group?: string) => {
			editingDispatch({type: 'update', field: 'performers', value: users});
			if (required) {
				editingDispatch({type: 'update-error', field: 'performers'});
			}

			if (!group) {
				return;
			}

			editingDispatch({type: 'update', field: 'performersActiveGroup', value: group});
			if (required) {
				editingDispatch({type: 'update-error', field: 'performersActiveGroup'});
			}
		},
		[required]
	);

	const handleUpdateException = useCallback(
		(value: boolean) => {
			if (exception) {
				return;
			}

			editingDispatch({type: 'update-exception', field: 'performers', value});
		},
		[exception]
	);

	const checkDoSuggestions = useCallback(() => {
		if (isEqual(initialRuleParams, ruleParams)) {
			return false;
		}
		editingDispatch({type: 'update', field: 'initialRuleParams', value: undefined});
		return true;
	}, [initialRuleParams, ruleParams]);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			scope={scope}
			workingGroupType={WorkingGroupTypeId.PERFORMERS}
			respUsers={performers}
			activeGroup={performersActiveGroup}
			ruleParams={ruleParams}
			label={t('components.editableInspection.performers.label')}
			errorLabel={t('components.editableInspection.performers.errorLabel')}
			dialogTitle={t('components.delegationDialog.performers.title')}
			required={required}
			disabled={disabled}
			hasError={hasError}
			onResponsiblesChange={handleChange}
			onUpdateException={handleUpdateException}
			checkDoSuggestions={checkDoSuggestions}
			{...restProps}
		/>
	);
};
