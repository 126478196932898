import useAppSelector from '@src/core/hooks/useAppSelector';
import {useEnrichedStructures} from './useEnrichedStructures';
import {getFilteredSchemaStructures} from '../utils/filterStructures';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';

interface IUseEnrichedFilteredSchemaStructuresProps {
	objectId: string;
	spaceId?: string;
}

export const useEnrichedFilteredSchemaStructures = ({
	objectId,
	spaceId
}: IUseEnrichedFilteredSchemaStructuresProps) => {
	const {filters} = useAppSelector(s => extractStructuresSchemaPageSettings(s, objectId));
	const {data: enrichedStructures, isLoading} = useEnrichedStructures({objectId, spaceId});
	const enrichedFilteredSchemaStructures = getFilteredSchemaStructures(
		filters,
		enrichedStructures
	);
	return {data: enrichedFilteredSchemaStructures, isLoading};
};
