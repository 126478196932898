import { memo } from 'react';
import {useSpaceStage} from '@src/core/hooks/spaces/useSpaceStage';
import {useSpaceLocationForEntities} from '@src/core/hooks/spaces/useSpaceLocationForEntities';
import {GlobalEntityAdding} from './GlobalEntityAdding';
import {useSpace} from '@src/core/hooks/queries/space';

interface IEntityAddingProps {
	objectId: string;
	spaceId: string;
}

export const EntityAdding = memo(({objectId, spaceId}: IEntityAddingProps) => {
	const {data: space} = useSpace(spaceId, objectId);
	const stage = useSpaceStage(objectId, space);

	const defaultData = useSpaceLocationForEntities(objectId, spaceId);

	if (!space || !stage) return null;
	return (
		<GlobalEntityAdding
			objectId={objectId}
			spaceId={spaceId}
			stage={stage}
			defaultData={defaultData}
			space={space}
		/>
	);
});
