import {MutableRefObject, useCallback, useEffect} from 'react';
import IUploadingFile from '../../interfaces/IUploadingFile';

/**
 * Хук. Возвращает функцию обновления state uploadingFiles
 * @param {MutableRefObject<IUploadingFile[]>} uploadsRef
 * @param {IUploadingFile[]} uploadingFiles
 * @param {(value: IUploadingFile[]) => void} onUploadingFilesChange
 */

export const useUpdateFile = (
	uploadsRef: MutableRefObject<IUploadingFile[]>,
	uploadingFiles: IUploadingFile[],
	onUploadingFilesChange: (value: IUploadingFile[]) => void
) => {
	// Тут мы добавляем в uploadsRef новые файлы
	useEffect(() => {
		const keys = uploadsRef.current.map(file => file.key);
		uploadingFiles.forEach(item => {
			if (!keys.includes(item.key)) {
				uploadsRef.current.push(item);
			}
		});
	}, [uploadingFiles]);

	return useCallback(
		(key: string, data: Partial<IUploadingFile>) => {
			uploadsRef.current = uploadsRef.current.map(item =>
				item.key === key ? {...item, ...data} : item
			);
			/**
			 * TODO
			 * по хорошему должно работать без ref и синхронизации состояния
			 * ниже закоментирован код который должен выполнять задачу обновления файлов
			 * но из-за неверного состояния uploadingFiles, не работает
			 * тому кто это прочитает предстоит разобраться с этим в задаче: TZ-3447
			 *
			 * onUploadingFilesChange(
			 *	uploadingFiles.map(item => (item.key === key ? {...item, ...data} : item))
			 * );
			 *
			 */

			onUploadingFilesChange(uploadsRef.current);
		},
		[onUploadingFilesChange]
	);
};
