import {combineReducers} from 'redux';
import checkLists from './checkLists/reducers';
import {workingGroupsReducer} from './workingGroups/slice';
import categoriesSets from './categoriesSets/reducers';
import {spaceStatusesSets} from './spaceStatusesSets/reducers';
import {fieldsSettingsSets} from '@/entities/FieldSettingsSets';

export default combineReducers({
	checkLists,
	workingGroups: workingGroupsReducer,
	categoriesSets,
	spaceStatusesSets,
	fieldsSettingsSets
});
