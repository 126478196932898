import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetExportTemplatesResponse} from '@src/api/backend/exportTemplates/get';
import {exportTemplatesQueryKeys} from '@src/api/cache/exportTemplates/keys';
import {
	extractExportTemplateById,
	getExportTemplatesAsArray,
	getExportTemplatesAsArrayWithoutPdf
} from './selectors';

import {useCallback} from 'react';

export const useFetchExportTemplates = <T = IGetExportTemplatesResponse>(
	select?: (data: IGetExportTemplatesResponse) => T,
	enabled?: boolean
) =>
	useQuery<IGetExportTemplatesResponse, IError, T>({
		queryKey: exportTemplatesQueryKeys.list(),
		meta: {error: 'при загрузке шаблонов экспорта'},
		enabled,
		select
	});

export const useGetExportTemplatesAsArray = (enabled?: boolean) =>
	useFetchExportTemplates(getExportTemplatesAsArray, enabled);

export const useGetExportTemplatesAsArrayWithoutPdf = (enabled?: boolean) =>
	useFetchExportTemplates(getExportTemplatesAsArrayWithoutPdf, enabled);

export const useExtractExportTemplateById = (templateId?: string, enabled?: boolean) => {
	const selector = useCallback(
		(data: IGetExportTemplatesResponse) => extractExportTemplateById(data, templateId),
		[templateId]
	);
	return useFetchExportTemplates(selector, enabled);
};
