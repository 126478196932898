import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TasksDisplayMode} from "@src/interfaces/TasksDisplayMode";
import {ITasksPageSettingsState} from "@src/store/modules/settings/pages/tasks/interfaces";

const initialState: ITasksPageSettingsState = {
	displayMode: TasksDisplayMode.LIST
}
export const tasksGeneralSlice = createSlice({
	name: 'tasksGeneral',
	initialState,
	reducers: {
		changeDisplayMode: (state, {payload}: PayloadAction<{displayMode: TasksDisplayMode}>) => {
			state.displayMode = payload.displayMode
		}
	}
})

export const {actions: tasksGeneralActions, reducer: tasksGeneralReducer} = tasksGeneralSlice;