import {IState} from '@src/store/modules';
import {createSelector} from 'reselect';
import {getInitialStateForPage} from './reducers';

export const extractCategoriesSetsPageSettings = createSelector(
	[
		(state: IState) => state.settings.pages.manage.categoriesSets,
		(state: IState, objectId: string) => objectId
	],
	(data, objectId) => data[objectId] || getInitialStateForPage(objectId)
);
