import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {CellProps} from 'react-table';

interface ICreatedCellProps extends CellProps<IPreparedContract> {
	isMobile?: boolean;
}

export const CreatedCell = ({row, isMobile}: ICreatedCellProps) => {
	const {createdAt, createdBy} = row.original;

	return (
		<div className="contracts__cell-created">
			{createdAt && !isMobile && (
				<div className="contracts__cell-created-date">
					{format(createdAt, dateTimeCommaSeparatedFormat)}
				</div>
			)}
			{isMobile && (
				<div className="contracts__cell-created-header">Автор: </div>
			)}
			{createdBy && (
				<div className="contracts__cell-created-name">
					{createdBy.displayName}
				</div>
			)}
		</div>
	);
};