import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import EditableInspection from '../EditableInspection';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {
	convertToLocalSave as convertFilesToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	someFilesHaveSizeError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {
	IEditableInspectionState,
	convertToSave,
	errorsFns,
	hasAttachmentsError,
	isEdited,
	useEditableInspectionState
} from '@src/core/hooks/states/useEditableInspectionState';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useInspectionSettings} from '@src/core/hooks/settings/useInspectionSettings';
import {useIsFetching} from '@tanstack/react-query';

export const useEditableInspection = (
	objectId: string,
	stage: ObjectStageIds,
	defaultData: ISavingInspection | undefined,
	saving: boolean,
	creating?: boolean,
	isLoading?: boolean,
	links?: IInspection['links']
): [
	React.ReactNode,
	(useLocalFiles?: boolean) => Promise<ISavingInspection | undefined>,
	() => void,
	boolean
] => {
	const fieldsSettings = useInspectionSettings(objectId);
	const {builtin, custom} = fieldsSettings;

	const [editingState, editingDispatch] = useEditableInspectionState(defaultData);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	const {data: workAcceptance} = useWorkAcceptance(links?.workAcceptanceId, objectId);

	let entityLocation: ILocation | undefined;
	let entityPlanId: string | undefined;

	if (links?.structureId && links?.workAcceptanceId) {
		const selectedStructure = workAcceptance?.structures?.find(
			str => str.id === links?.structureId
		);
		entityLocation = selectedStructure?.location;
		entityPlanId = selectedStructure?.planId;
	}

	if (links?.spaceId && links?.workAcceptanceId) {
		const selectedSpaces = workAcceptance?.spaces?.find(space => space.id === links?.spaceId);
		entityLocation = selectedSpaces?.location;
		entityPlanId = selectedSpaces?.planId;
	}

	useEffect(() => {
		setIsBlocking(
			isEdited(editingState, defaultData) || isFilesExist(uploadingFilesState.value)
		);
	}, [editingState, uploadingFilesState.value, defaultData]);

	const getSavingData = useCallback(
		async (useLocalFiles?: boolean) => {
			const files = await waitUploading();
			// Так как в editingState в attachments нет новых данных пока они не будут загружены на сервер,
			// добавляем данные из uploadingFilesState
			const editingStateWithUploadingFiles: IEditableInspectionState = {
				...editingState,
				attachments: [
					...editingState.attachments,
					...uploadingFilesState.value.map(item => ({id: item.key}))
				]
			};
			// Проверка наличия ошибок в состояниях
			if (
				hasErrors(editingStateWithUploadingFiles, errorsFns, builtin) ||
				hasErrors(editingStateWithUploadingFiles, errorsFns, custom, true) ||
				(hasAttachmentsError(editingState, builtin) && !isFilesExist(files))
			) {
				editingDispatch({type: 'update-errors'});
				uploadingFilesDispatch({type: 'update-error'});
				return undefined;
			}
			// Проверка, есть ли файлы с превышением размера.
			// не даст сохранить нарушение в автономном режиме
			if (someFilesHaveSizeError(files)) {
				return undefined;
			}
			// Проверка, были ли отредактированы поля
			if (
				!isEdited(editingState, defaultData) &&
				(!isFilesExist(files) || someFilesHaveError(files))
			) {
				return undefined;
			}
			const savingData = convertToSave(editingState, defaultData, !creating);
			savingData.newAttachments = useLocalFiles
				? convertFilesToLocalSave(files)
				: convertFilesToSave(files);
			return savingData;
		},
		[
			waitUploading,
			editingState,
			uploadingFilesState.value,
			builtin,
			custom,
			defaultData,
			creating,
			editingDispatch,
			uploadingFilesDispatch
		]
	);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: defaultData
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [defaultData, editingDispatch, uploadingFilesDispatch]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [reset]);

	// TO-DO: Избавиться от этого безобразия, после переработки форм
	const isAllLoading = !!useIsFetching({});

	const fields = (
		<EditableInspection
			objectId={objectId}
			stage={stage}
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving || isAllLoading}
			isLoading={isLoading}
			entityLocation={entityLocation}
			entityPlanId={entityPlanId}
			exceptions={editingState.exceptions}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
