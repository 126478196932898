import {Dispatch, forwardRef, Ref, useCallback, useRef} from 'react';
import {CommentInput, FilesPicker, IconButton, UserAvatar} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import {Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {IUploadingFilesAction} from '@src/core/hooks/states/useUploadingFilesState';
import {IEditableCommentAction} from '@src/core/hooks/states/useEditableCommentState';
import {convertRawFiles} from '@src/components/editableFields/Attachments/utils/convertRawFiles';
import {useTranslation} from 'react-i18next';

interface ITextInputProps {
	description: string;
	editingDispatch: Dispatch<IEditableCommentAction>;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	isSaving: boolean;
	waitForUploading?: boolean;

	onSave: () => void;
}

const TextInput = (
	{
		description,
		editingDispatch,
		uploadingFilesDispatch,
		isSaving,
		onSave,
		waitForUploading = true
	}: ITextInputProps,
	ref?: Ref<CommentInput>
) => {
	const {t} = useTranslation();
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const profile = useAppSelector(s => s.auth.profile);
	const filesPickerRef = useRef<FilesPicker>(null);

	const handleTextChange = useCallback((value: string) => {
		editingDispatch({type: 'update', field: 'description', value});
	}, []);

	const openFilesPicker = useCallback(() => {
		if (filesPickerRef.current) {
			filesPickerRef.current.open();
		}
	}, []);

	const handleFilesPick = useCallback(
		(files: IRawFile[]) => {
			uploadingFilesDispatch({
				type: 'add',
				value: convertRawFiles(files, networkStatus, waitForUploading)
			});
		},
		[networkStatus]
	);

	return (
		<div className="editable-comment__text-block">
			<Desktop>
				<UserAvatar
					className="editable-comment__avatar"
					user={profile}
					size="40"
					coloring="text"
				/>
			</Desktop>

			<div className="editable-comment__input-cont">
				<CommentInput
					className={{
						root: 'editable-comment__input',
						textarea: 'editable-comment__input-textarea',
						placeholder: 'editable-comment__input-placeholder'
					}}
					value={description}
					placeholder={t('components.editableProblemComment.commentInput.placeholder')}
					disabled={isSaving}
					onChange={handleTextChange}
					onSave={onSave}
					ref={ref}
				/>

				<IconButton
					className="editable-comment__attach-btn"
					type="inline-blue-accent"
					disabled={isSaving}
					onClick={openFilesPicker}
				>
					<i className="tz-attach-24" />
				</IconButton>
			</div>

			<IconButton
				className="editable-comment__send-btn"
				disabled={isSaving}
				onClick={onSave}
			>
				<i className="tz-send" />
			</IconButton>

			<FilesPicker
				multiple
				onChange={handleFilesPick}
				ref={filesPickerRef}
			/>
		</div>
	);
};

export default forwardRef(TextInput);
