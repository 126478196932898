import {ReactNode} from 'react';
import styles from './CentalBarMobile.module.less';

interface ICentralBarMobileProps {
	children: ReactNode;
}

export const CentralBarMobile = ({children}: ICentralBarMobileProps) => (
	<div className={styles.wrapper}>{children}</div>
);
