import { useCallback } from 'react';
import * as React from 'react';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ItemButton} from './ItemButton';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import {useExtractTreeCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';

interface IItemFooterProps {
	active?: boolean;
	record?: ICheckRecord;
	item: ICheckItem;
	stage: ObjectStageIds;
	setChangingState: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
	availableStatuses?: string[];
}

const closeIcon = <i className="tz-close-16" />;
const percentIcon = <i className="tz-percent" />;
const markIcon = <i className="tz-mark-16" />;

const getStatus = (record: ICheckRecord | undefined, nextStatus: CheckRecordStatusId) =>
	record?.status === nextStatus ? CheckRecordStatusId.NOT_CHECKED : nextStatus;

export const ItemActions = ({
	active,
	record,
	item,
	stage,
	setChangingState,
	disabled,
	availableStatuses
}: IItemFooterProps) => {
	const {objectId, workAcceptanceId, spaceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId?: string;
		spaceId: string;
	}>();
	const {data: treeItem} = useExtractTreeCheckItemById(item.checkListId, item.id);
	const {changeCheckItemRecord} = useChangeCheckRecord(objectId, spaceId, item);
	const buttonProps = {record, item};

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите принять категорию?',
		'Все дочерние категории будут переведены в статус "Принято", а связанные с ними нарушения - в статус "Устранено"'
	);

	const accept = useCallback(async () => {
		let confirmationResult = true;

		if (record?.status !== CheckRecordStatusId.ACCEPTED && treeItem?.children?.length) {
			confirmationResult = await getClosingConfirmation();
		}

		if (!confirmationResult) {
			return;
		}

		setChangingState(true);
		if (record) {
			changeCheckItemRecord({
				item,
				objectId: objectId || '',
				spaceId: undefined,
				workAcceptanceId,
				stage,
				status: getStatus(record, CheckRecordStatusId.ACCEPTED),
				record
			});
		}
	}, [
		record,
		treeItem?.children?.length,
		setChangingState,
		getClosingConfirmation,
		changeCheckItemRecord,
		item,
		objectId,
		workAcceptanceId,
		stage
	]);

	const reject = useCallback(() => {
		setChangingState(true);
		if (record) {
			changeCheckItemRecord({
				item,
				objectId: objectId || '',
				spaceId: undefined,
				workAcceptanceId,
				stage,
				status: getStatus(record, CheckRecordStatusId.REJECTED),
				record
			});
		}
	}, [setChangingState, record, changeCheckItemRecord, item, objectId, workAcceptanceId, stage]);

	const partiallyAccept = useCallback(() => {
		setChangingState(true);
		if (record) {
			changeCheckItemRecord({
				item,
				objectId,
				spaceId: undefined,
				workAcceptanceId,
				stage,
				status: getStatus(record, CheckRecordStatusId.PARTIALLY_ACCEPTED),
				record
			});
		}
	}, [setChangingState, record, changeCheckItemRecord, item, objectId, workAcceptanceId, stage]);

	return active || record?.status !== CheckRecordStatusId.ACCEPTED ? (
		<ClickPreventWrap className="check-list-items__item-actions">
			{/* TODO: переверстать кнопку */}
			{(!availableStatuses || availableStatuses.includes(CheckRecordStatusId.REJECTED)) && (
				<ItemButton
					onClick={reject}
					{...buttonProps}
					status={CheckRecordStatusId.REJECTED}
					icon={closeIcon}
					disabled={disabled}
				/>
			)}

			{(!availableStatuses ||
				availableStatuses.includes(CheckRecordStatusId.PARTIALLY_ACCEPTED)) && (
				<ItemButton
					onClick={partiallyAccept}
					{...buttonProps}
					status={CheckRecordStatusId.PARTIALLY_ACCEPTED}
					icon={percentIcon}
					disabled={disabled}
				/>
			)}

			{(!availableStatuses || availableStatuses.includes(CheckRecordStatusId.ACCEPTED)) && (
				<ItemButton
					onClick={accept}
					{...buttonProps}
					status={CheckRecordStatusId.ACCEPTED}
					icon={markIcon}
					disabled={disabled}
				/>
			)}

			{closingDialog}
		</ClickPreventWrap>
	) : null;
};
