import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {parse, stringify} from 'query-string';

interface IFiltersQuery<S extends Record<string, unknown>> {
	params?: S;
	clear: (...names: string[]) => void;
	allClear: () => void;
	change: (state: S) => void;
}

const useFiltersQuery = <S extends Record<string, unknown>>(): IFiltersQuery<S> => {
	const {pathname, search} = useLocation();
	const {replacePath} = useChangePath();
	const params = parse(search, {arrayFormat: 'index'}) as S;
	const preparedParams = {
		...params,
		critical:
			params.critical && typeof params.critical === 'string'
				? params.critical === 'true'
					? true
					: params.critical === 'false'
					? false
					: undefined
				: undefined,
		createdAtFrom:
			params.createdAtFrom && typeof params.createdAtFrom === 'string'
				? new Date(params.createdAtFrom)
				: undefined,
		createdAtTo:
			params.createdAtTo && typeof params.createdAtTo === 'string'
				? new Date(params.createdAtTo)
				: undefined,
		plannedFixDateFrom:
			params.plannedFixDateFrom && typeof params.plannedFixDateFrom === 'string'
				? new Date(params.plannedFixDateFrom)
				: undefined,
		plannedFixDateTo:
			params.plannedFixDateTo && typeof params.plannedFixDateTo === 'string'
				? new Date(params.plannedFixDateTo)
				: undefined
	};
	const clear = (...names: string[]) => {
		names.forEach(name => {
			delete params[name];
		});

		replacePath(`${pathname}?${stringify(params, {arrayFormat: 'index'})}`, {canGoBack: true});
	};

	const allClear = () => {
		replacePath(`${pathname}`, {canGoBack: true});
	};

	const change = (state: S) => {
		replacePath(`${pathname}?${stringify(state, {arrayFormat: 'index'})}`, {canGoBack: true});
	};

	return useMemo(
		() => ({
			params: preparedParams,
			clear,
			allClear,
			change
		}),
		[search, params]
	);
};

export default useFiltersQuery;
