import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import arrayToTree from 'array-to-tree';
// проверяет является ли массив одним объектом но с большой вложенностью
function getLastChild(treeItem: arrayToTree.Tree<IObject>): arrayToTree.Tree<IObject> {
	if (treeItem) {
		if (!treeItem.children) {
			return treeItem;
		}
		return getLastChild(treeItem.children[0]);
	}
	return {} as arrayToTree.Tree<IObject>;
}

export default getLastChild;
