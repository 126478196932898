import * as types from '../constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IObjectsFiltersState} from '../reducers';

export const changeFilters = (
	objectId = 'all', filters: IObjectsFiltersState
) => ({
	type: types.CHANGE_FILTERS,
	payload: {
		objectId, filters
	} as IChangeFiltersPayload<IObjectsFiltersState>
});