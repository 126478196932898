import {ReactNode} from 'react';
import './FilterPage.less';
import classNames from 'classnames';
import {Button} from '../../buttons';
import {ErrorBoundary} from '../../containers';
import {SelectOption} from '../../inputs';

export interface IFilterPageProps {
	className?: string;
	children?: ReactNode;
	applyDisabled?: boolean;
	search?: ReactNode;
	tabs?: ReactNode;
	acceptBtnLabel?: string;
	noOptions?: boolean;
	noOptionsOnChange?: () => void;
	noOptionsChecked?: boolean;
	noOptionsContent?: ReactNode;
	onApplyClick?: () => void;
}

const FilterPage = ({
	className,
	children,
	applyDisabled,
	onApplyClick,
	search,
	tabs,
	acceptBtnLabel = 'Применить',
	noOptions,
	noOptionsOnChange,
	noOptionsChecked,
	noOptionsContent
}: IFilterPageProps) => (
	<ErrorBoundary label="FilterPage" className="filter-page error">
		<div className={classNames('filter-page', className)}>
			<div className="filter-page__container">
				{tabs && <div className="filter-page__container-tabs">{tabs}</div>}
				{search && <div className="filter-page__container-search">{search}</div>}
			</div>
			<div className="filter-page__content">
				{noOptions ? (
					<SelectOption
						onChange={noOptionsOnChange}
						checked={noOptionsChecked}
						content={noOptionsContent}
						className={{root: 'filter-page__no-option-select'}}
					/>
				) : null}
				{children}
			</div>
			<div className="filter-page__buttons">
				<Button
					type="accent-blue"
					disabled={applyDisabled}
					style={{width: '100%'}}
					label={acceptBtnLabel}
					onClick={onApplyClick}
				/>
			</div>
		</div>
	</ErrorBoundary>
);

FilterPage.displayName = 'FilterPage';

export default FilterPage;
