import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {Button} from '@tehzor/ui-components';

interface IDesktopChecksExportProps {
	task: IEnrichedTask;
}

const checkIcon = <i className="tz-check-24" />;

export const DesktopCreateCheck = ({task}: IDesktopChecksExportProps) => {
	const {t} = useTranslation();
	const {data: object} = useObject(task.objectId?.id);

	const [dialog, openDialog] = useAddingCheckDialog({
		objectId: task?.objectId?.id || 'all',
		stage: object?.stage,
		links: {taskId: task.id}
	});

	return (
		<>
			<Button
				type="accent-blue"
				label={t('taskViewPage.createCheck.title')}
				leftIcon={checkIcon}
				onClick={openDialog}
			/>
			{dialog}
		</>
	);
};
