import PlansPage from '@src/pages/PlansPage';
import {PlanPageWrapper} from '@src/pages/PlanPage/PlanPageWrapper';
import {PrivateRoute} from '@src/containers/PrivateRoute';

export const objectPlansRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<PlansPage />} />
	},
	{
		path: 'add',
		element: <PrivateRoute element={<PlanPageWrapper />} />
	},
	{
		path: ':planId',
		element: <PrivateRoute element={<PlanPageWrapper />} />
	}
];
