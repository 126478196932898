import { memo } from 'react';
import {EntityGrid, EntityGridItem, LinkButton} from '@tehzor/ui-components';
import {useEntitiesLinks} from '../../hooks/useEntitiesLinks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ExpandableMobileInfo} from '@src/components/ExpandableMobileInfo';
import {useTranslation} from "react-i18next";

interface IMobileLinksProps {
	spaceId: string | undefined;
	objectId: string;
}
const linkIcon = <i className="tz-link-20" />;
const spaceIcon = <i className="tz-space-16" />;

export const MobileLinks = memo((props: IMobileLinksProps) => {
	const {t} = useTranslation();
	const {spaceId, objectId} = props;
	const {pushPath} = useChangePath();
	const links = useEntitiesLinks(spaceId, objectId);

	return (
		<ExpandableMobileInfo>
			<EntityGrid>
				<EntityGridItem
					label={t('checkListPage.links.mobileLinks.label')}
					icon={linkIcon}
					fullRow
				>
					{links.map(item => (
						<LinkButton
							key={item.key}
							type="filled"
							leftIcon={spaceIcon}
							label={item.label}
							onClick={() => pushPath(item.url ?? '')}
							title={item.title}
						/>
					))}
				</EntityGridItem>
			</EntityGrid>
		</ExpandableMobileInfo>
	);
});
