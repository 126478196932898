import {Reducer, useReducer} from 'react';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {
	errorsFns,
	init,
	IEditableRespUserState,
	IEditableRespUserAction
} from '@src/core/hooks/states/useEditableRespUserState/state';
import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';

export const useEditableRespUserState = (defaultData?: IAddingRespUser) =>
	useReducer<
		Reducer<IEditableRespUserState, IEditableRespUserAction>,
		IAddingRespUser | undefined
	>(createReducer(init, errorsFns), defaultData, init);
