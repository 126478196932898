import {useCallback} from 'react';
import {IGetWorkAcceptancesResponse} from '@src/api/backend/workAcceptances';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractWorkAcceptancesAsArray,
	extractWorkAcceptancesForStructureAsArray
} from '../selectors';

interface IUseWorkAcceptancesListParams<T> {
	filters?: Record<string, unknown>;
	select?: (data: IGetWorkAcceptancesResponse) => T;
	options?: Partial<UseQueryOptions<IGetWorkAcceptancesResponse, IError, T>>;
}

export const useWorkAcceptancesList = <TResult = IGetWorkAcceptancesResponse>({
	filters,
	select,
	options
}: IUseWorkAcceptancesListParams<TResult>) =>
	useQuery<IGetWorkAcceptancesResponse, IError, TResult>({
		queryKey: [...workAcceptancesQueryKeys.list(), filters],
		meta: {
			error: 'при загрузке списка приёмок'
		},
		select,
		...options
	});

export const useWorkAcceptancesForSpaceAsArray = (objectId?: string, spaceId?: string) => {
	const filters = {objects: [objectId], spaceIds: [spaceId]};
	return useWorkAcceptancesList({filters, select: extractWorkAcceptancesAsArray});
};

export const useWorkAcceptancesForStructureAsArray = (objectId?: string, structureId?: string) => {
	const filters = {objects: [objectId]};

	const select = useCallback(
		(data: IGetWorkAcceptancesResponse) =>
			extractWorkAcceptancesForStructureAsArray(data, structureId),
		[structureId]
	);

	return useWorkAcceptancesList({filters, select});
};
