import {Tree} from 'array-to-tree';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';

/**
 * Проверяет выбран ли в фильтрах переданный объект или любой из его предков или потомков
 *
 * @param object объект
 * @param filters выбранные id объектов в фильтрах
 */
export const isFiltersIncludeObject = (
	object: Tree<IStatsObject>,
	filters: {
		companies?: string[];
		objects?: string[];
	}
): boolean =>
	!!(
		filters.objects?.includes(object.id)
		|| filters.companies?.includes(object.companyId)
		|| filters.objects?.some(id => object.ancestors?.includes(id))
		|| object.children?.some(o => isFiltersIncludeObject(o, filters))
	);
