import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export type IGetListProblemsResponse = INormalizedData<IListProblem>;

/**
 * Возвращает список нарушений
 */
export const requestListProblems = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>
) => {
	const response = await httpRequests.withToken.post<IGetListProblemsResponse>(
		'problems/get-list-problems',
		{filters, sort: convertSortParam(sort)}
	);

	return response.data;
};
