import './WorkingGroupsPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
import {Table} from './components/table/Table.desktop';
import {MobileTable} from './components/table/Table.mobile';
import {DesktopAddButton} from './components/DesktopAddButton';
import {useWorkingGroupPermissions} from '@src/core/hooks/permissions/useWorkingGroupPermissions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {WorkingGroupsFilters} from '@src/pages/manage/WorkingGroupsPage/components/WorkingGroupsFilters/WorkingGroupsFilters';
import {useWorkingGroupsFilters} from './hooks/useWorkingGroupsFilters';
import classNames from 'classnames';

export const WorkingGroupsPage = () => {
	useAppHeader({title: 'Рабочие группы', mobileRightButtons: <AppUpdateMenu />});
	const permissions = useWorkingGroupPermissions();
	const isDesktop = useIsDesktop();
	const {filters, applyFilters, onClear} = useWorkingGroupsFilters();

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<div
				className={classNames('page-cont', {
					'manage-working-groups-page': isDesktop,
					'manage-working-groups-page_mobile': !isDesktop
				})}
			>
				<PageBreadcrumbs />
				<WorkingGroupsFilters />
				{isDesktop ? <Table /> : <MobileTable />}
				{permissions.canAdd && !isDesktop && <DesktopAddButton />}
			</div>
		</EntitiesFiltersProvider>
	);
};
