import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface IFieldsSettingsSetsPageSettingsState {
	pageSize: number;
	offset: number;
	selectedRows: string[];
}

export const getInitialStateFieldsSettingsSetsPageSettings =
	(): IFieldsSettingsSetsPageSettingsState => ({
		pageSize: 20,
		offset: 0,
		selectedRows: []
	});

const fieldsSettingsSetsSlice = createSlice({
	name: 'fieldsSettingsSets',
	initialState: getInitialStateFieldsSettingsSetsPageSettings,
	reducers: {
		changeOffset(state, action: PayloadAction<number>) {
			state.offset = action.payload;
		},
		changePageSize(state, action: PayloadAction<number>) {
			state.pageSize = action.payload;
		},
		changeSelectedRows(state, action: PayloadAction<string[]>) {
			state.selectedRows = action.payload;
		}
	}
});

export const fieldsSettingsSetsAction = fieldsSettingsSetsSlice.actions;
export const fieldsSettingsSets = fieldsSettingsSetsSlice.reducer;
