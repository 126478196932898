import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';

export type IAddCategoriesSetResponse = ICategoriesSet;

export const requestAddCategoriesSet = async (categoriesSet: ISavingCategoriesSet) => {
	const response = await  httpRequests.withToken.post<IAddCategoriesSetResponse>('categories-sets/add', categoriesSet);

	return response.data;
};
