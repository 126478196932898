import { useCallback } from 'react';
import {EntityInnerTable} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formProblemLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import SelectionRow from './SelectionRow';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {useWarrantyClaimEntities} from '@src/core/hooks/queries/warrantyClaim/problems/hooks';

interface ITableProps {
	objectId: string;
	warrantyClaimId: string;
	warrantyClaim?: ILinkedWarrantyClaim;
	columns: Array<Column<IWarrantyClaimEntity>>;
	hideHead?: boolean;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

const Table = ({
	objectId,
	warrantyClaimId,
	columns,
	hideHead,
	selectedRows,
	onSelectedRowsChange
}: ITableProps) => {
	const {pushPath} = useChangePath();
	const {data: entities} = useWarrantyClaimEntities(objectId, warrantyClaimId);

	const handleRowClick = useCallback((entity: IWarrantyClaimEntity) => {
		switch (entity.type) {
			case 'problem':
				return pushPath(formProblemLink(objectId, entity.data.id));
			default:
				return undefined;
		}
	}, []);

	return (
		<EntityInnerTable
			// className="space-page__d-entities"
			columns={columns}
			data={entities || []}
			hideHead={hideHead}
			selectable
			selectedRows={selectedRows}
			onRowClick={handleRowClick}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={props => (
				<SelectionRow
					{...props}
					objectId={objectId}
					warrantyClaimId={warrantyClaimId}
				/>
			)}
		/>
	);
};

export default Table;
