import { useMemo } from 'react';
import {CellProps} from 'react-table';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {Tag, TagsContainer} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordsAsArray} from '@src/store/modules/pages/checkLists/selectors/records';
import {getLatestModified} from '@src/utils/selectorUtils';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {useExtractCheckRecordStatusesById} from '@src/core/hooks/queries/checkRecordStatuses/hooks';

const checkListIcon = <i className="tz-check-list-16 " />;
const classes = {
	root: 'check-list-cell',
	label: 'check-list-cell__label',
	icon: 'check-list-cell__icon'
};
export const CheckListsCell = ({row}: CellProps<IPreparedWorkAcceptance>) => {
	const {stage, structures, spaces, object, categoryId, id} = row.original;

	const structureTypes = useMemo(
		() => (structures ? Array.from(new Set(structures.map(str => str?.type))) : undefined),
		[structures]
	);

	const spaceTypes = useMemo(
		() => (spaces ? Array.from(new Set(spaces.map(space => space?.type))) : undefined),
		[spaces]
	);
	const checkListsRecords = useAppSelector(extractCheckRecordsAsArray);
	const {data: checkRecordStatuses} = useExtractCheckRecordStatusesById();

	const checkLists = useAvailableCheckLists(
		object?.id || 'all',
		undefined,
		stage,
		CheckListTypeId.ACCEPTANCES,
		structureTypes,
		categoryId,
		spaceTypes
	);
	const checkListsEnriched = useMemo(
		() =>
			checkLists.map(checkList => {
				const records = checkListsRecords.filter(
					record =>
						record.links.workAcceptanceId &&
						id === record.links.workAcceptanceId &&
						record.links.checkListId === checkList.id &&
						!record.links.checkItemId
				);
				const record = getLatestModified<ICheckRecord>(records);
				const resp = {
					key: checkList.id,
					name: checkList.name,
					status:
						checkRecordStatuses &&
						checkRecordStatuses[record.status || CheckRecordStatusId.NOT_CHECKED]
				};
				return resp;
			}),
		[checkRecordStatuses, checkListsRecords, checkLists, id]
	);

	return (
		<>
			<TagsContainer>
				{checkListsEnriched &&
					checkListsEnriched.slice(0, 2).map(checkList => (
						<Tag
							key={checkList.key}
							className={classes}
							color={checkList.status?.color}
							icon={checkListIcon}
							label={checkList.name}
							size="large"
						/>
					))}
			</TagsContainer>
			{!!checkListsEnriched?.length && checkListsEnriched?.length > 2 && (
				<div className="work-acceptances-page__d-table__cell-rest">
					+{checkListsEnriched.length - 2}
				</div>
			)}
		</>
	);
};
