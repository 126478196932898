import './AppSidebar.less';
import {useIsDesktop, useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import DesktopAppSidebar from './components/DesktopAppSidebar';
import TabletAppSidebar from './components/TabletAppSidebar';
import MobileAppSidebar from './components/MobileAppSidebar';
import {ErrorBoundary} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import classNames from 'classnames';

export const AppSidebar = () => {
	const isTablet = useIsTablet();
	const isDesktop = useIsDesktop();

	const active = useAppSelector(s => {
		if (isDesktop) {
			return s.settings.sidebar.expanded;
		}
		if (isTablet) {
			return s.app.sidebar.tablet.visible;
		}
		return s.app.sidebar.mobile.visible;
	});

	const getSidebar = () => {
		if (isDesktop) {
			return <DesktopAppSidebar/>;
		}
		if (isTablet) {
			return <TabletAppSidebar/>;
		}
		return <MobileAppSidebar/>;
	};

	return (
		<ErrorBoundary
			label="AppSidebar"
			className={classNames(
				'app-sidebar',
				'error',
				{
					active,
					isDesktop,
					isTablet: !isDesktop && isTablet,
					isMobile: !isDesktop && !isTablet
				}
			)}
		>
			{getSidebar()}
		</ErrorBoundary>
	);
};
