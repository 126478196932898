import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

const extractSelected = (state: IState, selected: ProblemStatusId[] | undefined) => selected;
export const extractFilteredProblemStatuses = createSelector(
	[
		extractSelected,
		(s: IState, selected: ProblemStatusId[], statuses: IProblemStatus[] | undefined) => statuses
	],
	(selected, statuses) =>
		selected?.length ? statuses?.filter(status => selected.includes(status.id)) : statuses
);
