import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {IGetMeterTypesResponse} from '@src/api/backend/meterTypes';
import {
	extractMeterTypesAllIds,
	extractMeterTypesAsArray,
	extractMeterTypesById
} from './selectors';

export const useMeterTypes = <T = IGetMeterTypesResponse>(
	select?: (data: IGetMeterTypesResponse) => T
) =>
	useQuery<IGetMeterTypesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.meterTypes(),
		meta: {
			error: 'Ошибка при загрузке типов приборов учёта'
		},
		select,
	});

export const useExtractMeterTypesAsArray = () => useMeterTypes(extractMeterTypesAsArray);
export const useExtractMeterTypesById = () => useMeterTypes(extractMeterTypesById);
export const useExtractMeterTypesAllIds = () => useMeterTypes(extractMeterTypesAllIds);
