import ChecksFiltersPage from '@src/pages/filters/ChecksFiltersPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';

export const checksFilterRoutes = [
	{path: 'objects', element: <FiltersPageObjects/>},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage/>
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage/>
	},
	{
		path: '',
		element: <ChecksFiltersPage/>
	}
];
