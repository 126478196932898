import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';

/**
 * Возвращает полномочия для конкретного осмотра
 *
 * @param inspection осмотр
 * @param networkStatus статус соединения
 * @param objectId id объекта
 * @param user текущий пользователь
 */
export const getPermissionsForInspection = (
	inspection: IListInspection,
	networkStatus: boolean,
	objectId?: string,
	user?: IUser
) => {
	let canDelete = false;
	if (user) {
		const deletePermission = findPermission(
			user.roles || [],
			'inspectionsDelete',
			UserRoleScopes.OBJECT,
			objectId
		);
		if (deletePermission) {
			canDelete = deletePermission.restrictions
				? !!deletePermission.restrictions.inspectionsCreatedByUser
				  && !!inspection.createdBy
				  && user.id === inspection.createdBy.id
				: true;
		}
	}
	return {canDelete};
};
