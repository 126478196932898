import {ReactNode} from 'react';
import classNames from 'classnames';
import './EntityLinks.less';
import {SpaceEntityLink} from '@src/components/EntityLinks/components/SpaceEntityLink';
import {CheckEntityLink} from '@src/components/EntityLinks/components/CheckEntityLink';
import {CheckListEntityLink} from '@src/components/EntityLinks/components/CheckListEntityLink';
import {InternalAcceptanceEntityLink} from './components/InternalAcceptanceEntityLink';
import {TaskLink} from './components/TaskLink';
import {StructureEntityLink} from './components/StructureEntityLink';
import {CheckItemEntityLink} from '@src/components/EntityLinks/components/CheckItemEntityLink';
import {ProblemLink} from '@src/components/EntityLinks/components/ProblemLink';
import {useTranslation} from 'react-i18next';
import {WorkAcceptanceLink} from './components/WorkAcceptanceLink';

const renderList = (links: Record<string, string | undefined>) =>
	Object.entries(links).map(([key]) => {
		switch (key) {
			case 'spaceId':
				if (!links.spaceId) {
					return null;
				}
				return (
					<SpaceEntityLink
						key={links.spaceId}
						{...links}
					/>
				);

			case 'structureId':
				if (!links.structureId) {
					return null;
				}

				return (
					<StructureEntityLink
						key={links.structureId}
						{...links}
					/>
				);

			// case 'objectId':
			// 	return <ObjectEntityLink key={links.objectId!} {...links} />;
			case 'checkId':
				if (!links.checkId) {
					return null;
				}

				return (
					<CheckEntityLink
						key={links.checkId}
						{...links}
					/>
				);
			case 'checkListId':
				// Пропускаем этот кейс, если есть ссылка на категорию внутри чек-листа
				if (!links.checkListId || links.checkItemId) {
					return null;
				}

				return (
					<CheckListEntityLink
						key={links.checkListId}
						{...links}
					/>
				);

			case 'checkItemId':
				// Пропускаем этот кейс, если нет ссылки на категориию или чек-лист
				if (!links.checkItemId || !links.checkListId) {
					return null;
				}

				return (
					<CheckItemEntityLink
						key={links.checkItemId}
						{...links}
					/>
				);

			case 'internalAcceptanceId':
				if (!links.internalAcceptanceId) {
					return null;
				}

				return (
					<InternalAcceptanceEntityLink
						key={links.internalAcceptanceId}
						{...links}
					/>
				);

			case 'taskId':
				if (!links.taskId) {
					return null;
				}

				return (
					<TaskLink
						key={links.taskId}
						{...links}
					/>
				);

			case 'problemId':
				if (!links.problemId) {
					return null;
				}

				return (
					<ProblemLink
						key={links.problemId}
						problemId={links.problemId}
						objectId={links.objectId || 'all'}
					/>
				);
			case 'workAcceptanceId':
				if (!links.workAcceptanceId) {
					return null;
				}
				return (
					<WorkAcceptanceLink
						key={links.workAcceptanceId}
						workAcceptanceId={links.workAcceptanceId}
					/>
				);
			default:
				return null;
		}
	});

interface LinksProps {
	className?: string;
	links?: Record<string, string | undefined>;
	isMobile?: boolean;
	children?: ReactNode;
}

export const EntityLinks = ({className, links, isMobile, children}: LinksProps) => {
	const {t} = useTranslation();

	return (
		<div className={classNames('entity-links', className)}>
			{!isMobile && (
				<div className="entity-links__header">{t('components.entityLinks.header')}</div>
			)}

			<div className={classNames('entity-links__content', {isMobile})}>
				<div className="entity-links__container">
					{links ? renderList(links) : null}
					{children}
				</div>
			</div>
		</div>
	);
};
