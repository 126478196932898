import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import AxesHandler from '../core/planEditor/AxesHandler';
import CustomShapesHandler from '../core/planEditor/CustomShapesHandler';
import GenShapesHandler from '../core/planEditor/GenShapesHandler';
import {rafThrottle} from '../utils/throttle';
import generateKey from '@tehzor/tools/utils/generateKey';
import PlanBase from './PlanBase/PlanBase';

require('svg.js');
require('../../vendor/svg.draw.js');
require('svg.draggable.js');
require('svg.resize.js');
require('svg.select.js');
require('svg.intersections.js');
require('../../vendor/svg.topath');
require('../core/planEditor/axis');
require('../core/planEditor/polyline');

/**
 * Графический редактор планов
 */
class PlanEditor extends PlanBase {
	// Слои с фигурами
	_layers = [];

	// Текущий активный слой
	_activeLayer = -1;

	// Перетаскивание ладошкой активно
	_isMovingActive = false;

	// Начальная позиция по x при перетаскивании
	_movingStartPositionX = 0;

	// Начальная позиция по y при перетаскивании
	_movingStartPositionY = 0;

	// Режим редактирования
	_drawingMode = null;

	/**
	 * Обработчики включения и выключения режимов редактирования
	 * @type {{[name]: {on: (()), off: (())}}}
	 */
	_drawing = {
		select: {
			on: () => {
				if (
					this._activeLayer >= 0 &&
					this._layers[this._activeLayer].handler.checkDrawingModeSupport('select')
				) {
					this._layers[this._activeLayer].handler.enableSelection();
				}
			},
			off: () => {
				if (
					this._activeLayer >= 0 &&
					this._layers[this._activeLayer].handler.checkDrawingModeSupport('select')
				) {
					this._layers[this._activeLayer].handler.disableSelection();
				}
			}
		},
		palm: {
			on: () => {
				this._hammer.on('panstart', this._handleTouchPanStart);
				this._hammer.on('panend', this._handleTouchPanEnd);
				this._hammer.on('panmove', this._handleTouchPanMove);
				this._svgElement.style.cursor = 'pointer';
			},
			off: () => {
				this._hammer.off('panstart', this._handleTouchPanStart);
				this._hammer.off('panend', this._handleTouchPanEnd);
				this._hammer.off('panmove', this._handleTouchPanMove);
				this._svgElement.style.cursor = 'auto';
			}
		},
		ellipse: {
			on: () => {
				if (this._activeLayer >= 0 && this._layers[this._activeLayer].type === 'custom') {
					this._layers[this._activeLayer].handler.addEllipse();
					return;
				}

				let customShapesLayer = this._layers.findIndex(layer => layer.type === 'custom');

				if (customShapesLayer >= 0) {
					this._activeLayer = customShapesLayer;
					this._emitCurrentLayerChanged();
					this._layers[customShapesLayer].handler.addEllipse();
					return;
				}

				this.addLayer('Пользовательские фигуры', 'custom');
				customShapesLayer = this._layers.length - 1;
				this._activeLayer = customShapesLayer;
				this._emitCurrentLayerChanged();
				this._layers[customShapesLayer].handler.addEllipse();
			},
			off: () => {
				if (this._layers[this._activeLayer].type === 'custom') {
					this._layers[this._activeLayer].handler.cancelShapeAdding();
				}
			}
		},
		rect: {
			on: () => {
				if (this._activeLayer >= 0 && this._layers[this._activeLayer].type === 'custom') {
					this._layers[this._activeLayer].handler.addRect();
					return;
				}

				let customShapesLayer = this._layers.findIndex(layer => layer.type === 'custom');

				if (customShapesLayer >= 0) {
					this._activeLayer = customShapesLayer;
					this._emitCurrentLayerChanged();
					this._layers[customShapesLayer].handler.addRect();
					return;
				}

				this.addLayer('Пользовательские фигуры', 'custom');
				customShapesLayer = this._layers.length - 1;
				this._activeLayer = customShapesLayer;
				this._emitCurrentLayerChanged();
				this._layers[customShapesLayer].handler.addRect();
			},
			off: () => {
				if (this._layers[this._activeLayer].type === 'custom') {
					this._layers[this._activeLayer].handler.cancelShapeAdding();
				}
			}
		},
		polygon: {
			on: () => {
				if (this._activeLayer >= 0 && this._layers[this._activeLayer].type === 'custom') {
					this._layers[this._activeLayer].handler.addPolygon();
					return;
				}

				let customShapesLayer = this._layers.findIndex(layer => layer.type === 'custom');

				if (customShapesLayer >= 0) {
					this._activeLayer = customShapesLayer;
					this._emitCurrentLayerChanged();
					this._layers[customShapesLayer].handler.addPolygon();
					return;
				}

				this.addLayer('Пользовательские фигуры', 'custom');
				customShapesLayer = this._layers.length - 1;
				this._activeLayer = customShapesLayer;
				this._emitCurrentLayerChanged();
				this._layers[customShapesLayer].handler.addPolygon();
			},
			off: () => {
				if (this._layers[this._activeLayer].type === 'custom') {
					this._layers[this._activeLayer].handler.cancelShapeAdding();
				}
			}
		},
		axis: {
			on: () => {
				if (this._activeLayer >= 0 && this._layers[this._activeLayer].type === 'axes') {
					this._layers[this._activeLayer].handler.addShape();
					return;
				}

				let axesLayer = this._layers.findIndex(layer => layer.type === 'axes');

				if (axesLayer >= 0) {
					this._activeLayer = axesLayer;
					this._emitCurrentLayerChanged();
					this._layers[axesLayer].handler.addShape();
					return;
				}

				this.addLayer('Оси', 'axes');
				axesLayer = this._layers.length - 1;
				this._activeLayer = axesLayer;
				this._emitCurrentLayerChanged();
				this._layers[axesLayer].handler.addShape();
			},
			off: () => {
				if (this._layers[this._activeLayer].type === 'axes') {
					this._layers[this._activeLayer].handler.cancelShapeAdding();
				}
			}
		}
	};

	componentDidMount() {
		super.componentDidMount();
		if (this._frameElement.current) {
			this._frameElement.current.addEventListener('keydown', this._handleKeyDown);
		}
	}

	componentWillUnmount() {
		this.setDrawingMode(null);
		super.componentWillUnmount();
		if (this._frameElement.current) {
			this._frameElement.current.removeEventListener('keydown', this._handleKeyDown);
		}
		this._destroyLayers();
	}

	/**
	 * Инициализирует редактор
	 *
	 * @param {String} imagePath путь к изображению пдана
	 * @param {Array} layers слои с фигурами
	 */
	initialize(imagePath, layers = []) {
		if (this._svg) {
			this.setDrawingMode(null);
		}

		this.initDOMElements(imagePath, () => {
			this.props.toggleBusy(false);
		});

		this._layers = [];
		this._activeLayer = -1;

		for (let i = 0, layer, handler; i < layers.length; i++) {
			layer = layers[i];

			switch (layer.type) {
				case 'axes':
					handler = new AxesHandler(
						this._svg,
						this._layersGroup,
						layer.id,
						layer.visible,
						layer.shapes,
						shapes => this._emitShapesChanged(i, shapes)
					);
					break;
				case 'generated':
					handler = new GenShapesHandler(
						this._svg,
						this._layersGroup,
						layer.id,
						layer.visible,
						layer.shapes,
						shapes => this._emitShapesChanged(i, shapes)
					);
					break;
				case 'custom':
				default:
					handler = new CustomShapesHandler(
						this._svg,
						this._layersGroup,
						layer.id,
						layer.visible,
						layer.shapes,
						shapes => this._emitShapesChanged(i, shapes)
					);
					break;
			}

			this._layers.push({
				id: layer.id,
				name: layer.name,
				type: layer.type,
				visible: layer.visible,
				handler,
				new: false
			});
		}

		this.setActiveLayer(0);
	}

	/**
	 * Очищает редактор
	 */
	clear() {
		if (this._svg) {
			this.setDrawingMode(null);
		}

		this.clearDOMElements();
	}

	/**
	 * Возвращает слои в формате, пригодном для сохранения
	 *
	 * @returns {Array} слои
	 */
	getLayersForSaving() {
		return this._layers.map(layer => {
			const result = {
				name: layer.name,
				type: layer.type,
				visible: layer.visible,
				shapes: layer.handler.getShapesForSaving(layer.new)
			};
			if (!layer.new) {
				result._id = layer.id;
			}
			return result;
		});
	}

	/**
	 * Добавляет слой
	 *
	 * @param {String} name название
	 * @param {String} type тип
	 */
	addLayer(name, type) {
		const layerIndex = this._layers.length;
		const layer = {
			id: generateKey('layer'),
			name,
			type,
			visible: true,
			new: true
		};

		switch (type) {
			case 'axes':
				layer.handler = new AxesHandler(
					this._svg,
					this._layersGroup,
					generateKey('layer'),
					true,
					[],
					shapes => this._emitShapesChanged(layerIndex, shapes)
				);
				break;
			case 'generated':
				layer.handler = new GenShapesHandler(
					this._svg,
					this._layersGroup,
					generateKey('layer'),
					true,
					[],
					shapes => this._emitShapesChanged(layerIndex, shapes)
				);
				break;
			case 'custom':
			default:
				layer.handler = new CustomShapesHandler(
					this._svg,
					this._layersGroup,
					generateKey('layer'),
					true,
					[],
					shapes => this._emitShapesChanged(layerIndex, shapes)
				);
				break;
		}

		this._layers.push(layer);
		this._emitLayersChanged();
	}

	/**
	 * Переключает видимость слоёв
	 *
	 * @param {Number} index индекс слоя
	 */
	toggleLayerVisible(index = -1) {
		if (index < 0 || index >= this._layers.length) {
			return;
		}
		const visible = !this._layers[index].visible;
		this._layers[index].visible = visible;
		this._layers[index].handler.toggleVisible(visible);
		if (!visible && this._activeLayer === index) {
			const visibleLayer = this._layers.findIndex(layer => layer.visible);
			this.setActiveLayer(visibleLayer);
		}
		this._emitLayersChanged();
	}

	/**
	 * Устанавливает активность слоя
	 *
	 * @param {Number} index индекс слоя
	 */
	setActiveLayer(index = -1) {
		if (index < 0 || index >= this._layers.length) {
			return;
		}
		const drawingMode = this._drawingMode;
		this.setDrawingMode(null);
		this._activeLayer = index;
		if (this._layers[index].handler.checkDrawingModeSupport(drawingMode)) {
			this.setDrawingMode(drawingMode);
		}
		this._emitCurrentLayerChanged();
	}

	/**
	 * Удаляет фигуры из слоя
	 * @param {Number} index индекс слоя
	 */
	clearLayer(index = -1) {
		if (index < 0 || index >= this._layers.length) {
			return;
		}
		this._layers[index].handler.deleteAllShapes();
	}

	/**
	 * Удаляет слой
	 *
	 * @param {Number} index индекс слоя
	 */
	deleteLayer(index = -1) {
		if (index < 0 || index >= this._layers.length) {
			return;
		}
		if (this._activeLayer === index) {
			this.setDrawingMode(null);
			this._activeLayer = -1;
			this._emitCurrentLayerChanged();
		}
		this._layers[index].handler.destroy();
		this._layers.splice(index, 1);
		for (let i = 0; i < this._layers.length; i++) {
			this._layers[i].handler._setEmitFunction(shapes => this._emitShapesChanged(i, shapes));
		}
		this._emitLayersChanged();
		if (this._activeLayer > index) {
			this._activeLayer -= 1;
			this._emitCurrentLayerChanged();
		}
	}

	/**
	 * Изменяет порядок слоёв
	 *
	 * @param {Array} order новый порядок слоёв
	 */
	changeLayersOrder(order) {
		const count = this._layers.length;
		if (count !== order.length) {
			return;
		}
		const layers = new Array(count);

		for (let i = count - 1; i >= 0; i--) {
			layers[i] = this._layers[order[i]];
			layers[i].handler.toFront();
		}

		this._layers = layers;
	}

	/**
	 * Очищает ресурсы, связанные со слоями
	 *
	 * @protected
	 */
	_destroyLayers = () => {
		for (const layer of this._layers) {
			layer.handler.destroy();
		}
		this._layers = [];
	};

	/**
	 * Изменяет название фигуры
	 *
	 * @param {Number} layerIndex индекс слоя
	 * @param {Number} shapeIndex индекс фигуры
	 * @param {String} name название
	 */
	editShapeName(layerIndex = -1, shapeIndex, name) {
		if (layerIndex < 0 || layerIndex >= this._layers.length) {
			return;
		}
		this._layers[layerIndex].handler.editShapeName(shapeIndex, name);
	}

	/**
	 * Изменяет тип помещения фигуры
	 *
	 * @param {Number} layerIndex индекс слоя
	 * @param {Number} shapeIndex индекс фигуры
	 * @param {String} spaceType тип помещения
	 */
	editShapeSpaceType(layerIndex = -1, shapeIndex, spaceType) {
		if (layerIndex < 0 || layerIndex >= this._layers.length) {
			return;
		}
		this._layers[layerIndex].handler.editShapeSpaceType(shapeIndex, spaceType);
	}

	/**
	 * Переключает выделение фигуры
	 *
	 * @param {Number} layerIndex индекс слоя
	 * @param {Number} shapeIndex индекс фигуры
	 * @param {Boolean} shiftKey нажата ли клавиша shift
	 */
	toggleShapeSelection(layerIndex = -1, shapeIndex, shiftKey = false) {
		if (layerIndex < 0 || layerIndex >= this._layers.length) {
			return;
		}
		if (this._drawingMode !== 'select') {
			this.setDrawingMode('select');
		}
		if (this._activeLayer !== layerIndex) {
			this.setActiveLayer(layerIndex);
		}
		this._layers[layerIndex].handler.toggleShapeSelection(shapeIndex, shiftKey);
	}

	/**
	 * Удаляет фигуру
	 *
	 * @param {Number} layerIndex индекс слоя
	 * @param {Number} shapeIndex индекс фигуры
	 */
	deleteShape(layerIndex = -1, shapeIndex) {
		if (layerIndex < 0 || layerIndex >= this._layers.length) {
			return;
		}
		this._layers[layerIndex].handler.deleteShape(shapeIndex);
	}

	/**
	 * Удаляет выделенные фигуры
	 *
	 * @param {Number} layerIndex индекс слоя
	 */
	deleteSelectedShapes(layerIndex = -1) {
		if (layerIndex < 0 || layerIndex >= this._layers.length) {
			return;
		}
		this._layers[layerIndex].handler.deleteSelectedShapes();
	}

	/**
	 * Устанавливает новый режим рисования
	 *
	 * @param {String} mode режим рисования
	 */
	setDrawingMode(mode) {
		if (!this._svg) {
			return;
		}
		const prevMode = this._drawingMode;
		const drawingElem = this._drawing[mode];
		const prevDrawingElem = this._drawing[prevMode];

		if (!mode) {
			if (prevMode && prevDrawingElem) {
				prevDrawingElem.off.bind(this)();
				this._drawingMode = null;
				this._emitDrawingModeChanged();
			}
			return;
		}
		if (!prevMode) {
			drawingElem && drawingElem.on.bind(this)();
			this._drawingMode = mode;
			this._emitDrawingModeChanged();
			return;
		}
		if (prevMode === mode) {
			drawingElem && drawingElem.off.bind(this)();
			this._drawingMode = null;
			this._emitDrawingModeChanged();
			return;
		}
		this._drawingMode = mode;
		this._emitDrawingModeChanged();
		prevDrawingElem && prevDrawingElem.off.bind(this)();
		drawingElem && drawingElem.on.bind(this)();
	}

	/**
	 * Обрабатывает оси и находит секторы
	 */
	generateShapes() {
		if (!this._svg) {
			return;
		}
		const axesLayerIndex = this._layers.findIndex(layer => layer.type === 'axes');
		let genShapesLayerIndex = this._layers.findIndex(layer => layer.type === 'generated');

		if (axesLayerIndex >= 0) {
			const shapes = this._layers[axesLayerIndex].handler.generateShapes();

			if (genShapesLayerIndex < 0) {
				this.addLayer('Сгенерированные фигуры', 'generated');
				genShapesLayerIndex = this._layers.length - 1;
			}
			this._layers[genShapesLayerIndex].handler.addShapes(shapes);
		}
	}

	generateAxisLayer(generatedLayer) {
		if (!this._svg) {
			return;
		}
		let axesLayerIndex = this._layers.findIndex(layer => layer.type === 'axes');

		if (axesLayerIndex < 0) {
			this.addLayer('Оси', 'axes');
			axesLayerIndex = this._layers.length - 1;
		}

		this._layers[axesLayerIndex].handler.addGeneratedShapes(generatedLayer.shapes);
	}

	generateCustomLayer(generatedLayer) {
		if (!this._svg) {
			return;
		}
		let customLayerIndex = this._layers.findIndex(layer => layer.type === 'custom');

		if (customLayerIndex < 0) {
			this.addLayer('Пользовательские фигуры', 'custom');
			customLayerIndex = this._layers.length - 1;
		}

		this._layers[customLayerIndex].handler.addGeneratedShapes(generatedLayer.shapes);
	}

	/**
	 * Оповещает об изменении слоёв
	 *
	 * @private
	 */
	_emitLayersChanged() {
		const {onLayersChanged} = this.props;

		if (onLayersChanged && typeof onLayersChanged === 'function') {
			onLayersChanged(
				this._layers.map(layer => ({
					name: layer.name,
					type: layer.type,
					visible: layer.visible,
					shapes: layer.handler.getShapes()
				}))
			);
		}
	}

	/**
	 * Оповещает об изменении слоя
	 *
	 * @param {Number} layerIndex индекс слоя
	 * @private
	 */
	_emitLayerChanged(layerIndex = -1) {
		if (layerIndex < 0 || layerIndex >= this._layers.length) {
			return;
		}
		const {onLayerChanged} = this.props;

		if (onLayerChanged && typeof onLayerChanged === 'function') {
			const {name, type, visible} = this._layers[layerIndex];
			onLayerChanged(layerIndex, {name, type, visible});
		}
	}

	/**
	 * Оповещает об изменении фигур в конкретном слое
	 *
	 * @param {Number} layerIndex индекс слоя
	 * @param {Array} shapes фигуры
	 * @private
	 */
	_emitShapesChanged(layerIndex = -1, shapes) {
		if (layerIndex < 0 || layerIndex >= this._layers.length) {
			return;
		}
		const {onShapesChanged} = this.props;

		if (onShapesChanged && typeof onShapesChanged === 'function') {
			onShapesChanged(layerIndex, shapes);
		}
	}

	/**
	 * Оповещает об изменении активного слоя
	 *
	 * @private
	 */
	_emitCurrentLayerChanged() {
		const {onCurrentLayerChanged} = this.props;

		if (onCurrentLayerChanged && typeof onCurrentLayerChanged === 'function') {
			onCurrentLayerChanged(this._activeLayer);
		}
	}

	/**
	 * Оповещает об изменении режима редактирования
	 *
	 * @private
	 */
	_emitDrawingModeChanged() {
		const {onDrawingModeChanged} = this.props;

		if (onDrawingModeChanged && typeof onDrawingModeChanged === 'function') {
			onDrawingModeChanged(this._drawingMode);
		}
	}

	/**
	 * Обрабатывает нажатие клавиши в области рисования
	 *
	 * @param {Event} e событие типа React.KeyboardEvent
	 */
	_handleKeyDown = debounce(e => {
		if (e.code === 'Delete') {
			this._layers[this._activeLayer].handler.deleteSelectedShapes();
		}
	}, 100);

	/**
	 * Включает возможность перетаскивания области рисования мышью
	 *
	 * @param {Event} e событие
	 * @private
	 */
	_handleTouchPanStart = e => {
		this._isMovingActive = true;
		this._movingStartPositionX = e.center.x;
		this._movingStartPositionY = e.center.y;
	};

	/**
	 * Выключает возможность перетаскивания области рисования мышью
	 *
	 * @private
	 */
	_handleTouchPanEnd = () => {
		this._isMovingActive = false;
	};

	_handleTouchPanMove = rafThrottle(e => {
		if (this._isMovingActive) {
			if (this._frameElement.current) {
				this._frameElement.current.scrollLeft -= e.center.x - this._movingStartPositionX;
				this._frameElement.current.scrollTop -= e.center.y - this._movingStartPositionY;
			}
			this._movingStartPositionX = e.center.x;
			this._movingStartPositionY = e.center.y;
		}
	});
}

PlanEditor.propTypes = {
	...PlanBase.propTypes,
	onLayersChanged: PropTypes.func,
	onLayerChanged: PropTypes.func,
	onShapesChanged: PropTypes.func,
	onCurrentLayerChanged: PropTypes.func,
	onDrawingModeChanged: PropTypes.func,
	toggleBusy: PropTypes.func
};

export default PlanEditor;
