import {useAddAuthorizedPersonMutation} from '@src/api/cache/authorizedPersons/mutations';
import {IAddAuthorizedPersonParams} from '@src/api/cache/authorizedPersons/defaults/useAuthorizedPersonsMutationDefaults';
import {IAddAuthorizedPersonResponse} from '@src/api/backend/authorizedPerson/add';

export const useAddAuthorizedPerson = (): ((
	params: IAddAuthorizedPersonParams
) => Promise<IAddAuthorizedPersonResponse | undefined>) => {
	const mutation = useAddAuthorizedPersonMutation();
	return (params: IAddAuthorizedPersonParams) => mutation.mutateAsync(params);
};
