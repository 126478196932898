import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as replyTypes from '@src/store/modules/entities/workAcceptanceReply/constants';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IGetLastWorkAcceptanceReplyPayload} from './actions';
import {IAddWorkAcceptanceCommentPayload, IDeleteWorkAcceptanceCommentPayload, IEditWorkAcceptanceCommentPayload} from '../workAcceptanceComment/actions';

export interface IWorkAcceptancesLastRepliesState {
	[workAcceptanceId: string]: IComment | undefined;
}

export default createReducer<IWorkAcceptancesLastRepliesState>(
	{},
	{
		[types.GET_SUCCESS]: (state, {payload}: {payload: IGetLastWorkAcceptanceReplyPayload}) => {
			state[payload.workAcceptanceId] = payload.reply || undefined;
		},
		[replyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddWorkAcceptanceCommentPayload}) => {
			if (payload.links?.workAcceptanceId && state.hasOwnProperty(payload.links?.workAcceptanceId)) {
				state[payload.links.workAcceptanceId] = payload;
			}
		},
		[replyTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditWorkAcceptanceCommentPayload}) => {
			if (
				payload.links?.workAcceptanceId
				&& state.hasOwnProperty(payload.links.workAcceptanceId)
				&& state[payload.links.workAcceptanceId]?.id === payload.id
			) {
				state[payload.links.workAcceptanceId] = payload;
			}
		},
		[replyTypes.DELETE_SUCCESS]: (
			state,
			{payload}: {payload: IDeleteWorkAcceptanceCommentPayload}
		) => {
			if (
				state.hasOwnProperty(payload.workAcceptanceId)
				&& state[payload.workAcceptanceId]?.id === payload.commentId
			) {
				delete state[payload.workAcceptanceId];
			}
		}
	}
);
