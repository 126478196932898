import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export interface ILegalsFiltersState extends Record<string, unknown> {
	objects?: string[];
	companies?: string[];
	createdBy?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
}

export type ILegalsSortFields = Partial<Record<keyof ILegalEntity, boolean>>;
export type ILegalsSortState = Partial<Record<keyof ILegalsSortFields, boolean>>;

export interface ILegalsPageSettingsState {
	filters: ILegalsFiltersState;
	sort: ILegalsSortState;
	selectedRows: string[];
	pageSize: number;
	offset: number;
}

export const getInitialStateForPage = (): ILegalsPageSettingsState => ({
	filters: {},
	sort: {
		modifiedAt: false
	},
	selectedRows: [],
	pageSize: 20,
	offset: 0
});

const legalsSlice = createSlice({
	name: 'legals',
	initialState: getInitialStateForPage,
	reducers: {
		changeFilters(state, action: PayloadAction<ILegalsFiltersState>) {
			state.filters = action.payload;
		},
		clearFilters(state) {
			state.filters = {};
		},
		changeSort(state, action: PayloadAction<ILegalsSortState>) {
			state.sort = action.payload;
		},
		changeOffset(state, action: PayloadAction<number>) {
			state.offset = action.payload;
		},
		changePageSize(state, action: PayloadAction<number>) {
			state.pageSize = action.payload;
		},
		changeSelectedRows(state, action: PayloadAction<string[]>) {
			state.selectedRows = action.payload;
		}
	}
});

export const legalsActions = legalsSlice.actions;
export const legals = legalsSlice.reducer;
