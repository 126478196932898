import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {TFunction} from 'i18next';

export const createDescriptionDifference = (
	prev: string | undefined,
	next: string | undefined,
	t: TFunction<'translation', undefined>,
	label?: string
): IHistoryData => ({
	prev,
	next,
	type: HistoryTypeId.DESCRIPTION,
	name: label ? t('createDifference.createDescriptionDifference') : ''
});
