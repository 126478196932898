import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {Dialog, LinkButton} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';

interface IDescriptionDialogProps {
	item: ICheckItem;
}

export const DescriptionDialog = ({item}: IDescriptionDialogProps) => {
	const [isOpen, toggle] = useToggle(false);
	return (
		<ClickPreventWrap>
			<LinkButton
				label={item.name}
				onClick={toggle}
				className="structure-check-list-items__item-header-title structure-check-list-items__item-header-title_clickable"
			/>

			<Dialog
				className="structure-check-list-items__dialog"
				isOpen={isOpen}
				onRequestClose={toggle}
			>
				<div className="structure-check-list-items__description">{item.description}</div>
			</Dialog>
		</ClickPreventWrap>
	);
};
