import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useManageCheckListBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageCheckListBreadcrumbs';

interface IPageBreadcrumbsProps {
	checkListId: string;
}

export const PageBreadcrumbs = ({checkListId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useManageCheckListBreadcrumbs(checkListId);

	return (
		<AppBreadcrumbs
			className="manage-check-lists-edit-page__breadcrumbs"
			items={breadcrumbs}
		/>
	);
};
