import {FixDateTag} from '@src/components/FixDateTag';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {useTranslation} from 'react-i18next';

interface IMobilePlannedFixDateProps {
	plannedFixDate: string;
	actualFixDateOriginal: number | undefined;
	plannedFixDateOriginal: number | undefined;
}
export const MobilePlannedFixDate = ({
	plannedFixDate,
	actualFixDateOriginal,
	plannedFixDateOriginal
}: IMobilePlannedFixDateProps) => {
	const {t} = useTranslation();

	return !!plannedFixDate ? (
		<div className="problems-page__m-table-plannedFixDate">
			<div>
				{t('problemsPage.mobileProblemCell.plannedFixDate')}: &nbsp;
				{plannedFixDate}
			</div>
			{compareFixDate(actualFixDateOriginal, plannedFixDateOriginal) && (
				<FixDateTag
					plannedFixDate={plannedFixDateOriginal!}
					actualFixDate={actualFixDateOriginal}
				/>
			)}
		</div>
	) : null;
};
