import {IDiagramSector, IEntityStat} from '../interfaces';

export const convertStatsToDiagramSectors = (stats?: IEntityStat[]): IDiagramSector[] => {
	if (!stats) return [];
	return stats.map(stat => ({
		id: stat.key,
		color: stat.color,
		quantity: stat.value
	}));
};
