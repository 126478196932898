import {memo} from 'react';
import {EntityGrid, EntityGridItem, Plate} from '@tehzor/ui-components';
import {ExpandableMobileInfo} from '@src/components/ExpandableMobileInfo';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {ContractFormId} from '@tehzor/tools/interfaces/spaces/IContractForm';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Dates} from '@src/components/viewFields/Dates';
import {formSpaceDates} from '@src/utils/formSpaceDates';
import {useContractForms} from '@src/core/hooks/queries/contractForms/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionary} from "@src/core/hooks/translations/useTranslatedDictionary";
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";

interface IDesktopAdditionalInfoProps {
	space: ISpace;
}

const areaIcon = <i className="tz-area-20" />;
const locationIcon = <i className="tz-location-20" />;
const documentIcon = <i className="tz-document-20" />;
const percentIcon = <i className="tz-percent" />;

export const AdditionalInfo = memo(({space}: IDesktopAdditionalInfoProps) => {
	const {t} = useTranslation();

	const isDesktop = useIsDesktop();
	const {data: contractForms} = useContractForms();
	const translatedContractForms = useTranslatedDictionary(dictionaryKeys.contractForms, contractForms);
	const makeName = (contractId?: ContractFormId) => {
		if (
			translatedContractForms?.byId === undefined ||
			translatedContractForms?.allIds.length === 0 ||
			contractId === undefined
		)
			return undefined;
		return translatedContractForms?.byId?.[contractId]?.name;
	};
	const dates = formSpaceDates(space, t);

	const AdditionalInfoGrid = (
		<EntityGrid withBorders>
			{space.areaBTI && (
				<EntityGridItem
					label={t('spacePage.additionalInfo.areaBTI.label')}
					icon={areaIcon}
					fullRow
				>
					{space.areaBTI} м<sup>2</sup>
				</EntityGridItem>
			)}

			{space.numberBTI && (
				<EntityGridItem
					label={t('spacePage.additionalInfo.numberBTI.label')}
					fullRow
					icon={areaIcon}
				>
					{space.numberBTI}
				</EntityGridItem>
			)}
			{space.floorBTI && (
				<EntityGridItem
					label={t('spacePage.additionalInfo.floorBTI.label')}
					fullRow
					icon={locationIcon}
				>
					{space.floorBTI}
				</EntityGridItem>
			)}

			{space.contractForm && (
				<EntityGridItem
					label={t('spacePage.additionalInfo.contractForm.label')}
					fullRow
					icon={documentIcon}
				>
					{makeName(space.contractForm)}
				</EntityGridItem>
			)}

			{space.markupForRegistration !== undefined && (
				<EntityGridItem
					label={t('spacePage.additionalInfo.markupForRegistration.label')}
					fullRow
					icon={percentIcon}
				>
					{
						space.markupForRegistration ?
						t('spacePage.additionalInfo.markupForRegistration.text.yes') :
						t('spacePage.additionalInfo.markupForRegistration.text.no')
					}
				</EntityGridItem>
			)}
			{(space.decorationWarrantyExpiredDate ||
				space.constructiveWarrantyExpiredDate ||
				space.technicalEquipmentWarrantyExpiredDate) && <Dates dates={dates} />}
		</EntityGrid>
	);

	return (
		<Plate
			className={{content: 'space-page__info-plate'}}
			style={!isDesktop ? {marginTop: '16px'} : undefined}
		>
			<div className="space-page__info-title">{t('spacePage.additionalInfo.title')}</div>
			{!isDesktop ? (
				<ExpandableMobileInfo>{AdditionalInfoGrid}</ExpandableMobileInfo>
			) : (
				AdditionalInfoGrid
			)}
		</Plate>
	);
});
