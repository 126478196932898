import {QueryClient} from '@tanstack/react-query';
import {plansQueryKeys} from '../../keys';
import IPlanOrder from '@tehzor/tools/interfaces/plans/IPlanOrder';
import {updateCachedState} from '@src/utils/updateCachedState';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {makePlansEditOrderRequest, IEditPlansOrderResponse} from '@src/api/backend/plans';
import {addErrorToast} from '@src/utils/toasts';

export interface IEditOrderPlansParams {
	objectId: string;
	order: IPlanOrder[];
}

const getObjectIds = ({allIds, byId}: IEditPlansOrderResponse) => {
	const objectIds = new Set<string>();

	for (const planId of allIds) {
		const plan = byId[planId];
		objectIds.add(plan.objectId);
	}

	return Array.from(objectIds);
}

export const useEditOrderPlansMutationDefaults = (
	queryClient: QueryClient
) => {
	const key = Date.now().toString(10);

	queryClient.setMutationDefaults(plansQueryKeys.editOrder(), {
		onMutate: (params: IEditOrderPlansParams) => ({variables: params}),
		mutationFn: async (params: IEditOrderPlansParams) => {
			await updateCachedState<IPlan>(
				plansQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);

			const {objectId, order} = params;
			return makePlansEditOrderRequest(objectId, order);
		},
		onSuccess: async (data: IEditPlansOrderResponse) => {
			await updateCachedState<IPlan>(
				plansQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);

			const objectIds = getObjectIds(data);

			await queryClient.invalidateQueries({
				queryKey: plansQueryKeys.list(objectIds)
			});

			return data;
		},
		onError: async () => {
			addErrorToast('Ошибка', 'не удалось изменить порядок планов');
			await updateCachedState<IPlan>(
				plansQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
			return undefined;
		}
	});
}