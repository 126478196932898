import {IconButton, PopupPlate} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import {Settings} from './Settings';
import {useTranslation} from 'react-i18next';

const classNames = {
	root: 'spaces-page__settings-popup',
	content: 'spaces-page__settings-popup-content'
};

const settingsIcon = <i className="tz-settings-24" />;

const SettingsPopup = () => {
	const {t} = useTranslation();
	const [isOpen, toggle] = useToggle(false);

	return (
		<PopupPlate
			className={classNames}
			trigger={({ref}) => (
				<IconButton
					className="spaces-page__settings-btn"
					type="common"
					active={isOpen}
					onClick={toggle}
					ref={ref}
				>
					{settingsIcon}
				</IconButton>
			)}
			arrowVisible
			isOpen={isOpen}
			placement="bottom-end"
			onOutsideClick={toggle}
			onDisappear={toggle}
		>
			<div className="spaces-page__settings-popup-title">
				{t('spacesPage.settingsPopup.popupPlate.popupTitle')}
			</div>

			<Settings />
		</PopupPlate>
	);
};

export default SettingsPopup;
