import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useDocumentsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useDocumentsBreadcrumbs';
import {IDocument} from '@tehzor/types-and-subjects/ts/documents';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Actions} from '@src/pages/DocumentPage/components/desktop/Actions';

interface IPageBreadcrumbsProps {
	document?: IDocument;
}

export const PageBreadcrumbs = ({document}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useDocumentsBreadcrumbs(document);
	const isDesktop = useIsDesktop();

	return (
		<AppBreadcrumbs
			className="document-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && document !== undefined && <Actions document={document} />}
		</AppBreadcrumbs>
	);
};
