import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {IState} from '@src/store/modules';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {
	IGetSpacesInspectionStatsResponse,
	requestSpacesInspectionStats
} from '@src/api/backend/statistics/creation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

const request = () => ({type: types.GET_SPACES_INSPECTION_REQUEST});

const success = (response: IGetSpacesInspectionStatsResponse) => ({
	type: types.GET_SPACES_INSPECTION_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке статистики');
	}
	return {
		type: types.GET_SPACES_INSPECTION_FAILURE,
		payload: error
	};
};

/**
 * Получает статистику по прохождению помещений для стадии приёмки
 */
export const getSpacesInspectionStats
	= (): AppThunkAction<Promise<IGetSpacesInspectionStatsResponse>> => (dispatch, getState) =>
		dispatch(
			checkExpiration<IState, IGetSpacesInspectionStatsResponse, ApiAction>(
				state => state.statistics.acceptance.spacesInspection,
				createApiAction<IGetSpacesInspectionStatsResponse>(
					request,
					success,
					failure,
					() => {
						const s = getState();
						const {objects} = s.settings.pages.statistics.acceptance.filters;
						return requestSpacesInspectionStats(objects, ObjectStageIds.ACCEPTANCE);
					}
				)
			)
		);
