import { memo } from 'react';
import {getCheckItemsLabel} from '../utils/getCheckItemsLabel';

interface ICategoryCountProps {
	count?: number;
}
export const CategoryCount = memo((props: ICategoryCountProps) => {
	const {count} = props;
	const categoryCount = getCheckItemsLabel(count);
	return <div className="check-list-page__category-count">{categoryCount}</div>;
});
