import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {
	CheckRecordStatusId,
	ICheckRecordStatus
} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ISpaceCheckListsData} from '@tehzor/tools/interfaces/entititesComputedData/ISpaceCheckListsData';
import {
	IConvertedCheckItemData,
	IConvertedCheckListData
} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';

const convertCheckItemsData = (
	items: ICheckItem[] | undefined,
	itemsData: Record<string, CheckRecordStatusId> | undefined,
	checkRecordStatusesMap?: Record<string, ICheckRecordStatus> | undefined,
	selectedCheckListCategory?: string[]
) =>
	items
		? items.reduce<IConvertedCheckItemData[]>((prev, item) => {
				if (!item.parentId) {
					const statusId = itemsData?.[item.id] ?? CheckRecordStatusId.NOT_CHECKED;
					const status = checkRecordStatusesMap?.[statusId];
					if (
						selectedCheckListCategory === undefined ||
						selectedCheckListCategory?.length === 0 ||
						selectedCheckListCategory?.includes(item.id)
					) {
						prev.push({
							id: item.id,
							name: item.name,
							status: statusId,
							statusName: status?.name ?? '',
							color: status?.color ?? ''
						});
					}
				}
				return prev;
		  }, [])
		: [];

export const convertCheckListsData = (
	checkLists: ICheckList[] | undefined,
	checkItemsByLists: Record<string, ICheckItem[]> | undefined,
	checkRecordStatuses?: INormalizedData<ICheckRecordStatus>,
	selectedCheckListCategory?: string[],
	checkListsData: ISpaceCheckListsData | undefined = {}
): IConvertedCheckListData[] => {
	let total = 0;
	const result: IConvertedCheckListData[] = [];

	if (!checkLists) {
		return [];
	}
	for (const checkList of checkLists) {
		// Если будет множественная привязка чек-листа к стадиям, то здесь надо будет брать
		// данные по чек-листу в каждой его стадии (в тех стадиях, которые выбраны в фильтре).
		const checkListData = checkListsData[checkList.stage]?.[checkList.id];
		// В случае множественной привязки чек-листа к стадиям,
		// надо будет определиться какой статус брать из нескольких,
		// либо дублировать чек-листы с разными статусами.
		const statusId = checkListData?.status ?? CheckRecordStatusId.NOT_CHECKED;
		const status = checkRecordStatuses?.byId[statusId];
		const problems: IConvertedCheckListData['problems'] = checkListData?.problems ?? [];

		const checkListsItems = checkItemsByLists?.[checkList.id];

		const value = checkListsItems
			? checkListsItems.filter(el => el.parentId === undefined).length
			: 0;
		total += value;

		result.push({
			id: checkList.id,
			key: checkList.id,
			name: checkList.name,
			value,
			percent: 0,
			startPoint: 0,
			status: statusId,
			statusName: status?.name ?? '',
			color: status?.color,
			items: convertCheckItemsData(
				checkItemsByLists?.[checkList.id],
				checkListData?.items,
				checkRecordStatuses?.byId,
				selectedCheckListCategory
			),
			problems
		});
	}
	let offset = 0;
	for (const stat of result) {
		stat.percent = ((stat?.value || 0) / total) * 100;
		stat.startPoint = -offset;
		offset += stat.percent;
	}

	return result;
};
