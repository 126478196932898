import {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IPaginateSettings} from '@/shared/types/IPaginate';
import {Action} from "redux";

interface IChangePageProps {
	settings: IPaginateSettings;
	changeOffsetAction: (offset: number) => Action;
	changePageSizeAction: (value: number) => Action;
}

export const useChangePage = ({ settings, changeOffsetAction, changePageSizeAction }: IChangePageProps) => {
	const dispatch = useAppDispatch();

	const handlePageChange = useCallback(
		({ selected }: { selected: number }) => {
			const newOffset = selected * settings.pageSize;
			if (settings.offset !== newOffset) {
				dispatch(changeOffsetAction(newOffset));
			}
		},
		[settings, dispatch, changeOffsetAction]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			const newOffset = Math.floor(settings.offset / value);
			dispatch(changePageSizeAction(value));
			dispatch(changeOffsetAction(newOffset));
		},
		[settings, dispatch, changePageSizeAction, changeOffsetAction]
	);

	return { handlePageChange, handlePageSizeChange };
};
