import {StatusSelect} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {editWarrantyClaimStatus} from '@src/store/modules/entities/warrantyClaim/actions';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import ClickPreventWrap from './ClickPreventWrap';
import {useWarrantyClaimsStatuses} from '@src/core/hooks/queries/warrantyClaimsStatuses/hooks';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';

const WarrantyClaimStatusCell = ({row}: CellProps<{data: IWarrantyClaim}>) => {
	const claim = row.original.data;
	const {id, objectId, status} = claim;

	const {data: statuses} = useWarrantyClaimsStatuses();
	const translatedWarrantyClaimStatuses = useTranslatedDictionary(
		'warrantyClaimStatuses',
		statuses
	);
	const dispatch = useAppDispatch();
	const permissions = useWarrantyClaimsPermissions(objectId);

	const [, handleChange] = useAsyncFn(
		async (v: WarrantyClaimStatusId) => {
			await dispatch(editWarrantyClaimStatus(objectId, id, v));
		},
		[objectId, id]
	);

	return (
		<ClickPreventWrap>
			<StatusSelect
				style={{maxWidth: '100%'}}
				statuses={translatedWarrantyClaimStatuses}
				disabled={!permissions.canEditStatus}
				value={status}
				onChange={handleChange}
			/>
		</ClickPreventWrap>
	);
};

export default WarrantyClaimStatusCell;
