import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {EntityInfo, EntityInfoDateWithCountFiles} from '@tehzor/ui-components';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from "react-i18next";

interface IWarrantyClaimInfoProps {
	className?: string;
	data: IWarrantyClaim;
	title?: string;
}

const claimIcon = <i className="tz-warranty-claim-28" />;

const WarrantyClaimInfo = ({
	className,
	data,
	title = 'Гарантийное обращение'
}: IWarrantyClaimInfoProps) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();

	return (
		<EntityInfo
			className={className}
			title={`${title} №${data.number ?? data.localNumber}`}
			subTitle={
				<EntityInfoDateWithCountFiles
					labelFrom={t('components.tableCell.info.entityInfoDateWithCountFiles.labelFrom')}
					locale={dateFnsLocale}
					data={data}
				/>
			}
			icon={claimIcon}
			iconColor="#656BEA"
			iconSize={44}
		/>
	);
};

export default WarrantyClaimInfo;
