import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteCategoryResponse {
	success: boolean;
}

/**
 * Удаляет вид работ по id
 *
 * @param categoryId id вида работа
 */
export const requestDeleteCategory = async (categoryId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteCategoryResponse>(`categories/delete/${categoryId}`);

	return response.data;
};
