import IComment from '@tehzor/tools/interfaces/comments/IComment';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetCommentsResponse extends INormalizedData<IComment> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список комментариев
 */
export async function requestComments(
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};
	return wsConnector.sendAuthorizedRequest<IGetCommentsResponse>(
		'getComments',
		params
	);
}
