import * as React from 'react';
import './DelegationSelectOption.less';
import classNames from 'classnames';
import {ReadMore} from '../../various/ReadMore';

interface IDelegationSelectOptionProps {
	rules?: string[];
	level: number;
	selectable?: boolean;
	selected: boolean;
	selectInput: React.ReactNode;
	infoComponent: React.ReactNode;
	readMoreHideLabel?: string;
	readMoreShowLabel?: string;
	onClick: () => void;
}

export const DelegationSelectOption = ({
	rules,
	level,
	selectable,
	selected,
	selectInput,
	infoComponent,
	readMoreHideLabel,
	readMoreShowLabel,
	onClick
}: IDelegationSelectOptionProps) => (
	<div
		data-testid={`TreeItem_${level}`}
		className={classNames(
			'delegation-select-option',
			`delegation-select-option_level_${level}`,
			{
				'delegation-select-option_selectable': selectable,
				'delegation-select-option_selected': selected
			}
		)}
		onClick={selectable ? onClick : undefined}
	>
		<div className="delegation-select-option__header">
			<div className="delegation-select-option__select-wrap">
				{selectable ? selectInput : null}
			</div>
			{infoComponent}
		</div>

		{rules?.length ? (
			<div className="delegation-select-option__rules">
				{rules.map((rule, index) => (
					<ReadMore
						text={rule}
						key={index}
						maxLength={100}
						hideLabel={readMoreHideLabel}
						showLabel={readMoreShowLabel}
					/>
				))}
			</div>
		) : null}
	</div>
);

DelegationSelectOption.displayName = 'DelegationSelectOption';
