import {CSSProperties, memo, ReactElement} from 'react';
import './BaseStatusSelect.less';
import StatusButton from '../../../buttons/StatusButton';
import classNames from 'classnames';
import MenuLayer from '../../../menu/MenuLayer';
import {IPopupBasicProps} from '../../../containers/Popup';
import {IBaseStatus} from './utils/IBaseStatus';
import {convertClassNames} from '../../../../utils/convertClassNames';

const defaultPopupProps: IPopupBasicProps = {placement: 'bottom-start', preferX: 'right'};

export interface IBaseStatusSelectProps {
	className?:
		| string
		| {
				root?: string;
				btn?: string;
		  };
	style?: CSSProperties;
	disabled?: boolean;
	popupProps?: IPopupBasicProps;
	hideArrow?: boolean;
	label?: string;
	color?: string;

	menu?: ReactElement;
	selectedStatus?: IBaseStatus;
	actionInProgress?: boolean;
	multiline?: boolean;
}

const BaseStatusSelect = ({
	className,
	style,
	disabled,
	popupProps = defaultPopupProps,
	hideArrow,
	label = 'статус',
	menu,
	color,
	selectedStatus,
	actionInProgress,
	multiline
}: IBaseStatusSelectProps) => {
	const classes = convertClassNames(className);

	return (
		<MenuLayer
			className={classNames('base-status-select', classes.root)}
			trigger={({isOpen, toggle, ref, disabled: triggerDisabled}) => (
				<StatusButton
					className={classNames('base-status-select__btn', classes?.btn)}
					style={style}
					label={selectedStatus ? selectedStatus.name : `Выберите ${label}`}
					color={color}
					arrowDirection={!hideArrow ? !!isOpen : undefined}
					disabled={disabled || !menu || triggerDisabled}
					onClick={toggle}
					ref={ref}
					actionInProgress={actionInProgress}
					multiline={multiline}
				/>
			)}
			popupProps={popupProps}
		>
			{menu}
		</MenuLayer>
	);
};

export default memo(BaseStatusSelect);
