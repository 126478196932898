import { PureComponent } from 'react';
import * as React from 'react';
import RCSelect, {Option} from '../../../vendor/rc-select';
import classNames from 'classnames';
import './Select.less';
import './rc-select.less';
import FieldError from '../inputs/FieldError';

const arrowDownIcon = <i className="tz-simple-arrow-16"/>;
const clearIcon = <i className="tz-close-16"/>;

const placements = {
	bottomLeft: {
		points: ['tl', 'bl'],
		offset: [0, 0],
		overflow: {
			adjustX: 0,
			adjustY: 1
		}
	},
	topLeft: {
		points: ['bl', 'tl'],
		offset: [0, 0],
		overflow: {
			adjustX: 0,
			adjustY: 1
		}
	}
};

const menuItemCheckboxIcon = (props: Record<string, unknown>) => {
	const classes = classNames(
		'rc-select-dropdown-menu-item-checkbox',
		{'rc-select-dropdown-menu-item-checkbox__selected': props.isSelected}
	);
	return (
		<div className={classes}/>
	);
};

type ValueType = string | string[];

interface ISelectProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	elementClassName?: string;
	error?: string;
	multiple?: boolean;
	wrapTags?: boolean;
	allowClear?: boolean;
	placeholder?: string;
	disabled?: boolean;
	menuMaxHeight?: string;
	maxTagCount?: number;
	maxTagTextLength?: number;
	maxTagPlaceholder?: (omittedValues: number) => void | React.ReactNode;
	value?: ValueType;
	defaultValue?: ValueType;
	controlRender?: (value: Array<{ label: string, value: string }>) => void;

	onChange?(value: string | string[] | undefined, label: string): void;

	getPopupContainer?(node: HTMLElement): HTMLElement;
}

class Select extends PureComponent<ISelectProps> {
	static displayName = 'Select';

	static defaultProps: Partial<ISelectProps> = {
		wrapTags: false
	};

	render() {
		const {
			className, style, children, error, multiple, wrapTags, allowClear, placeholder, disabled,
			menuMaxHeight, maxTagCount, maxTagTextLength, maxTagPlaceholder,
			value, defaultValue, onChange, controlRender, getPopupContainer
		} = this.props;
		const classes = classNames('select', className);
		const elementClasses = classNames(
			'select__element',
			{select__element_error: !!error},
			{'rc-select_wrap-tags': wrapTags}
		);

		const selectProps = {
			className: elementClasses,
			multiple,
			allowClear,
			placeholder,
			disabled,
			animation: 'slide-up',
			showSearch: false,
			builtinPlacements: placements,
			inputIcon: arrowDownIcon,
			clearIcon,
			removeIcon: clearIcon,
			menuItemSelectedIcon: menuItemCheckboxIcon,
			dropdownMenuStyle: {maxHeight: menuMaxHeight || '100vh'},
			optionLabelProp: 'children',
			optionFilterProp: 'children',
			notFoundContent: 'Не найдено',
			maxTagCount,
			maxTagTextLength,
			maxTagPlaceholder,
			onChange,
			controlRender,
			getPopupContainer: getPopupContainer || this._getPopupContainer
		} as Record<string, unknown>;
		// if (value) {
		selectProps.value = value;
		// }
		// if (defaultValue) {
		selectProps.defaultValue = defaultValue;
		// }

		return (
			<div
				className={classes}
				style={style}
			>
				<RCSelect {...selectProps}>
					{children}
				</RCSelect>

				{error && <FieldError text={error}/>}
			</div>
		);
	}

	private _getPopupContainer = (node: HTMLElement) => node.parentElement || node;
}

export {Option};
export default Select;
