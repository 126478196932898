import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';

export function useProblemTitle(problem: IPreparedProblem): string {
	const {t} = useTranslation();

	return useMemo(() => {
		if (problem?.number !== undefined) {
			return t('editableProblemDialog.dialog.title.makeTitle.problemWithPercent', {
				number: problem.number
			});
		}

		return t('editableProblemDialog.dialog.title.makeTitle.problem');
	}, [problem, t]);
}
