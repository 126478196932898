import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {CellProps} from 'react-table';

export const AcceptorsCell = ({
	row: {
		original: {acceptors, acceptorsActiveGroup, company}
	}
}: CellProps<IPreparedWorkAcceptance>) => (
	<div className="work-acceptances-page__d-table-acceptors">
		{acceptorsActiveGroup && (
			<div className="work-acceptances-page__d-table__cell-header">{acceptorsActiveGroup}</div>
		)}
		{acceptors?.[0] && (
			<div className="work-acceptances-page__d-table-acceptors-user">{acceptors[0]}</div>
		)}
		{company && (
			<div className="work-acceptances-page__d-table-acceptors-company">{company}</div>
		)}
		{!!acceptors?.length && acceptors?.length > 1 && (
			<div className="work-acceptances-page__d-table__cell-rest">+{acceptors.length - 1}</div>
		)}
	</div>
);
