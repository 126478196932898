import { useState, useMemo, memo } from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import {useLegalsPermissions} from '@src/core/hooks/permissions/useLegalsPermissions';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import classNames from 'classnames';
import {useAuthorizedPersonDelete} from '@src/pages/LegalPage/hooks/useAuthorizedPersonDelete';
import {useEditableAuthorizedPersonDialog} from '@src/components/EditableAuthorizedPersonDialog/hooks/useEditableAuthorizedPersonDialog';

interface IAuthorizedPersonItemProps {
	authorizedPerson: IAuthorizedPerson;
}

const deleteIcon = <i className="tz-delete" />;
const editIcon = <i className="tz-edit" />;
const simpleArrowIcon = <i className="tz-simple-arrow-20" />;
const userIcon = <i className="tz-user-28" />;

export const AuthorizedPersonItem = memo((props: IAuthorizedPersonItemProps) => {
	const {authorizedPerson} = props;
	const [expanded, setExpanded] = useState(false);

	const permissions = useLegalsPermissions();
	const [deleteDialog, handleDeleteClick] = useAuthorizedPersonDelete(authorizedPerson.id);
	const {authorizedPersonDialog, handleOpenAuthorizedPersonDialog} =
		useEditableAuthorizedPersonDialog({authorizedPerson});

	const items = useMemo(() => {
		const items = [];

		if (permissions.canEdit) {
			items.push(
				<MenuItem
					key="edit"
					icon={editIcon}
					onClick={handleOpenAuthorizedPersonDialog}
				>
					Редактировать
				</MenuItem>
			);
		}

		if (permissions.canDelete) {
			items.push(
				<MenuItem
					key="delete"
					icon={deleteIcon}
					onClick={handleDeleteClick}
				>
					Удалить
				</MenuItem>
			);
		}

		return items;
	}, [permissions, handleDeleteClick, handleOpenAuthorizedPersonDialog]);

	const authorityExpiry = format(authorizedPerson.authorityExpiry || 0, dateFormat, {
		locale: ru
	});
	const createdAt = format(authorizedPerson.createdAt || 0, dateFormat, {locale: ru});
	const modifiedAt = format(authorizedPerson?.modifiedAt || 0, dateFormat, {locale: ru});

	const body = (
		<div className="legal-page__authorized-person-info">
			<span className="legal-page__authorized-person-info-title">
				Срок действия полномочий:
			</span>
			<span className="legal-page__authorized-person-info-content">{authorityExpiry}</span>
			<span className="legal-page__authorized-person-info-title">Комментарий:</span>
			<span className="legal-page__authorized-person-info-content">
				{authorizedPerson.comment}
			</span>
			<span className="legal-page__authorized-person-info-title">Создано:</span>
			<span className="legal-page__authorized-person-info-content">
				{`${createdAt}, ${authorizedPerson?.createdBy?.displayName}`}
			</span>
			{authorizedPerson?.modifiedAt && (
				<>
					<span className="legal-page__authorized-person-info-title">Изменено:</span>
					<span className="legal-page__authorized-person-info-content">
						{`${modifiedAt}, ${authorizedPerson?.modifiedBy?.displayName}`}
					</span>
				</>
			)}
		</div>
	);

	const handleClick = () => setExpanded(prev => !prev);

	return (
		<div
			key={authorizedPerson.id}
			className="legal-page__authorized-person-item-wrapper"
		>
			<div className="legal-page__authorized-person-item">
				<div className="legal-page__authorized-person-item-icon">{userIcon}</div>

				<div className="legal-page__authorized-person-item-body">
					<span className="legal-page__authorized-person-item-body-number">
						{authorizedPerson.name}
					</span>
					<span className="legal-page__authorized-person-item-body-name">
						{authorizedPerson.position}
					</span>
					<div
						onClick={handleClick}
						className="legal-page__authorized-person-item-body-button"
					>
						{expanded ? <span>Скрыть</span> : <span>Подробнее</span>}
						<span
							className={classNames(
								'legal-page__authorized-person-item-body-button-icon',
								{
									'legal-page__authorized-person-item-body-button-icon_expanded':
										expanded
								}
							)}
						>
							{simpleArrowIcon}
						</span>
					</div>
				</div>

				<IconMenu className="legal-page__authorized-person-item-icon-menu">
					{items}
				</IconMenu>
				{permissions.canDelete && deleteDialog}
				{permissions.canAdd && authorizedPersonDialog}
			</div>
			{expanded && body}
		</div>
	);
});
