import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {usePerformersGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ResponsiblesFilterPage} from '../ResponsiblesFilterPage';

export const PerformersFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity?: string}>();
	const {state, change} = useEntitiesFiltersCtx<{performers?: string[]}>();
	const {data: groups} = usePerformersGroupsAsArray(objectId);

	const handleChange = useCallback(
		(value?: string[]) => {
			change({performers: value});
		},
		[change]
	);

	return (
		<ResponsiblesFilterPage
			objectId={objectId}
			entity={entity}
			respUsers={state.performers}
			label={t('entitiesFilters.performers.label')}
			workingGroups={groups}
			onChange={handleChange}
		/>
	);
};
