import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {TFunction} from 'i18next';

export const createPercentageDifference = (
	prev: number | undefined,
	next: number | undefined,
	t: TFunction<'translation', undefined>,
	label?: string
): IHistoryData => ({
	prev: prev ? `${prev}%` : undefined,
	next: next ? `${next}%` : undefined,
	type: HistoryTypeId.DESCRIPTION,
	name: label ? t('createDifference.createPercentageDifference') : ''
});
