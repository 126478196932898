export const GET_WEB_PUSH_PUBLIC_KEY_REQUEST = 'GET_WEB_PUSH_PUBLIC_KEY_REQUEST';
export const GET_WEB_PUSH_PUBLIC_KEY_SUCCESS = 'GET_WEB_PUSH_PUBLIC_KEY_SUCCESS';
export const GET_WEB_PUSH_PUBLIC_KEY_FAILURE = 'GET_WEB_PUSH_PUBLIC_KEY_FAILURE';

export const UPDATE_WEB_PUSH_SUBSCRIPTION_REQUEST = 'UPDATE_WEB_PUSH_SUBSCRIPTION_REQUEST';
export const UPDATE_WEB_PUSH_SUBSCRIPTION_SUCCESS = 'UPDATE_WEB_PUSH_SUBSCRIPTION_SUCCESS';
export const UPDATE_WEB_PUSH_SUBSCRIPTION_FAILURE = 'UPDATE_WEB_PUSH_SUBSCRIPTION_FAILURE';

export const DELETE_WEB_PUSH_SUBSCRIPTION_REQUEST = 'DELETE_WEB_PUSH_SUBSCRIPTION_REQUEST';
export const DELETE_WEB_PUSH_SUBSCRIPTION_SUCCESS = 'DELETE_WEB_PUSH_SUBSCRIPTION_SUCCESS';
export const DELETE_WEB_PUSH_SUBSCRIPTION_FAILURE = 'DELETE_WEB_PUSH_SUBSCRIPTION_FAILURE';
