import MobileActions from './Actions.mobile';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';

interface IMobileRightButtonsProps {
	taskId: string;
	objectId?: string;
	taskType?: TaskTypeId;
}

const MobileRightButtons = (props: IMobileRightButtonsProps) => {
	const {taskId,objectId,taskType} = props
	if (!taskId) return null;

	return <MobileActions taskId={taskId} objectId={objectId} taskType={taskType}/>;
};

export default MobileRightButtons;
