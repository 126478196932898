import {Column} from 'react-table';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {WorkAcceptanceDateCell} from '@src/components/tableCells/WorkAcceptanceDateCell/WorkAcceptanceDateCell';
import {WorkAcceptanceStatusCell} from '@src/components/tableCells/WorkAcceptanceStatusCell';
import {WorkAcceptanceStructuresCell} from '@src/components/tableCells/WorkAcceptanceStructuresCell';
import {TFunction} from 'i18next';

export const getWorkAcceptanceDesktopColumns = (
	tfunc: TFunction
): Array<Column<IListWorkAcceptance>> => [
	{
		id: 'number',
		Header: tfunc('structurePage.workAcceptanceTableColumns.number.header'),
		accessor: row => `${row.description ? `${row.number}, ${row.description}` : row.number}`,
		width: 140,
		minWidth: 140
	},
	{
		id: 'status',
		Header: tfunc('structurePage.workAcceptanceTableColumns.status.header'),
		Cell: WorkAcceptanceStatusCell,
		width: 140,
		minWidth: 140
	},
	{
		id: 'category',
		Header: tfunc('structurePage.workAcceptanceTableColumns.category.header'),
		Cell: WorkAcceptanceStructuresCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'date',
		Header: tfunc('structurePage.workAcceptanceTableColumns.date.header'),
		Cell: WorkAcceptanceDateCell
	}
];
