export enum ValueTypeId {
	SELECT = 'select',
	TEXT = 'text',
	DATE = 'date',
	LINK = 'link'
}

export interface IValueType {
	id: ValueTypeId;
	name: string;
}
