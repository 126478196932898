import {CellProps} from 'react-table';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {useExtractCategoryById} from '@src/core/hooks/queries/categories/hook';

export const WorkAcceptanceDescriptionCell = ({row}: CellProps<IPreparedWorkAcceptance>) => {
	const {categoryId, description, percent, physicalWorkScope} = row.original;
	const {data: category} = useExtractCategoryById(categoryId);

	return (
		<div className="work-acceptances-page__d-table-description">
			<div className="work-acceptances-page__d-table-description__number">
				{`№${row?.original?.number}` || ''}
			</div>
			{description && (
				<div className="work-acceptances-page__d-table-description__name">
					{description}
				</div>
			)}
			<div className="work-acceptances-page__d-table-description__category">
				{category?.name || ''}
			</div>
			<div className="work-acceptances-page__d-table-description__progress">
				{percent ? `${percent}%` : '0%'}
				{physicalWorkScope?.value && physicalWorkScope?.unitName
					? `, ${physicalWorkScope.value + physicalWorkScope.unitName}`
					: ''}
			</div>
		</div>
	);
};
