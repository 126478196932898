import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import ILocationMapPoint from '@tehzor/tools/interfaces/ILocationMapPoint';
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {InputType, ViewerType} from '../LocationSelect';

export const useLocSelectState = (
	location?: ILocation,
	defaultViewerType?: ViewerType,
	defaultInputType?: InputType,
	entityLocation?: ILocation
): [
		ILocationMarker[],
		ILocationMarker[],
		ILocationMapPoint[],
		Dispatch<SetStateAction<ILocationMarker[]>>,
		Dispatch<SetStateAction<ILocationMarker[]>>,
		Dispatch<SetStateAction<ILocationMapPoint[]>>,
		ViewerType,
		Dispatch<SetStateAction<ViewerType>>,
		InputType,
		Dispatch<SetStateAction<InputType>>,
		() => void,
		(
		) => void,
		ILocationMarker[],
		ILocationMarker[]
	] => {
	const [planSectors, setPlanSectors] = useState<ILocationMarker[]>([]);
	const [planPoints, setPlanPoints] = useState<ILocationMarker[]>([]);
	const [mapPoints, setMapPoints] = useState<ILocationMapPoint[]>([]);
	const [viewerType, setViewerType] = useState<ViewerType>(defaultViewerType || 'plan');
	const [inputType, setInputType] = useState<InputType>(defaultInputType || 'points');

	const [entitySectors, setEntitySectors] = useState<ILocationMarker[]>([]);
	const [entityPoints, setEntityPoints] = useState<ILocationMarker[]>([]);

	const clear = useCallback(() => {
		setPlanSectors([]);
		setPlanPoints([]);
		setMapPoints([]);

		setEntitySectors([]);
		setEntityPoints([]);
	}, []);

	const reset = useCallback(() => {
		let pSectors = [] as ILocationMarker[];
		let pPoints = [] as ILocationMarker[];
		let mPoints = [] as ILocationMapPoint[];

		let eSectors = [] as ILocationMarker[];
		let ePoints = [] as ILocationMarker[];

		if (location) {
			if (location.sectors) {
				pSectors = location.sectors;
			}
			if (location.points) {
				pPoints = location.points;
			}
			if (location.mapPoints) {
				mPoints = location.mapPoints;
			}
		}

		if (entityLocation) {
			if (entityLocation.sectors) {
				eSectors = entityLocation.sectors;
			}
			if (entityLocation.points) {
				ePoints = entityLocation.points;
			}
		}
		setPlanSectors(pSectors);
		setPlanPoints(pPoints);
		setMapPoints(mPoints);

		setEntitySectors(eSectors);
		setEntityPoints(ePoints);

		if (pSectors.length || pPoints.length) {
			setViewerType('plan');
		} else if (mPoints.length) {
			setViewerType('map');
		}

		if (pSectors.length) {
			setInputType('sectors');
		} else if (pPoints.length || mPoints.length) {
			setInputType('points');
		}
	}, [location, entityLocation]);

	useEffect(reset, [location, entityLocation]);

	return [
		planSectors,
		planPoints,
		mapPoints,
		setPlanSectors,
		setPlanPoints,
		setMapPoints,
		viewerType,
		setViewerType,
		inputType,
		setInputType,
		clear,
		reset,
		entitySectors,
		entityPoints
	];
};
