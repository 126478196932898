import {
	IGetDelegationHistoriesByInspectionResponse,
	requestDelegationHistoriesByInspection
} from '@src/api/backend/delegationHistories';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';
import {addErrorToast} from '@src/utils/toasts';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../../constants';

export type IGetPerformersDelegationHistoriesByInspectionPayload =
	IGetDelegationHistoriesByInspectionResponse;

const request = () => ({type: types.GET_PERFORMERS_DELEGATION_HISTORIES_REQUEST});

const success = (response: IGetDelegationHistoriesByInspectionResponse) => ({
	type: types.GET_PERFORMERS_DELEGATION_HISTORIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке истории делегирования исполнителей');
	return {
		type: types.GET_PERFORMERS_DELEGATION_HISTORIES_FAILURE,
		payload: error
	};
};

/**
 * Получает записи из истории делегирования нарушения
 *
 * @param objectId id объекта
 * @param inspectionId id нарушения
 */
export const getPerformersDelegationHistoriesByInspection = (
	objectId: string,
	inspectionId: string
) =>
	checkLoaded<IState, IGetDelegationHistoriesByInspectionResponse, ApiAction>(
		s => s.entities.inspection.performersDelegationHistories,
		createApiAction<IGetDelegationHistoriesByInspectionResponse>(
			request,
			success,
			failure,
			() => requestDelegationHistoriesByInspection(objectId, inspectionId)
		)
	);
