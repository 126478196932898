import {Column} from 'react-table';
import {IPreparedExportTemplate} from '../../interfaces/IPreparedExportTemplate';
import {CompanyCell} from './CompanyCell';
import {MenuCell} from './MenuCell';
import {ObjectsCell} from './ObjectsCell';

export const desktopColumns: Array<Column<IPreparedExportTemplate>> = [

	{
		id: 'name',
		accessor: 'name',
		Header: 'Наименование',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},
	{
		id: 'fileName',
		accessor: 'fileName',
		Header: 'fileName',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},

	{
		id: 'destination',
		accessor: 'destination',
		Header: 'destination',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},
	{
		id: 'outputFileType',
		Header: 'outputFileType',
		accessor: 'outputFileType',
		width: 80,
		minWidth: 80
	},
	{
		id: 'companyId',
		Header: 'companyId',
		accessor: 'companyId',
		Cell: CompanyCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'objects',
		Header: 'objects',
		accessor: 'objects',
		Cell: ObjectsCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'fileNameTemplate',
		Header: 'fileNameTemplate',
		accessor: 'fileNameTemplate',
		width: 80,
		minWidth: 80
	},
	{
		id: 'createdAt',
		accessor: row => row.createdAt,
		Header: 'Создание',
		width: 80,
		minWidth: 80
	},
	{
		id: 'modifiedAt',
		accessor: row => row.modifiedAt,
		Header: 'Изменение',
		width: 80,
		minWidth: 80
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];
