import MenuItem from '../../../../menu/MenuItem';
import classNames from 'classnames';
import {getSpaceStatusColor} from './getSpaceStatusColor';
import {Tag} from '../../../../tags';
import {ISpaceStatus} from './ISpaceStatus';

export const getSpaceMenuItem = <S extends ISpaceStatus>(
	status: S, currentStatus?: S, disabled?: boolean
) => {
	const color = getSpaceStatusColor(status);
	const isDisabled = disabled && currentStatus?.id !== status.id;

	return (
		<MenuItem
			key={status.id}
			itemKey={status.id}
			selected={currentStatus?.id === status.id}
			disabled={disabled}
			className={classNames(
				'base-status-select__menu-item',
				'space-status-select__menu-item',
				{current: currentStatus?.id === status.id}
			)}
		>
			<Tag
				className={{
					root: classNames(
						'space-status-select__menu-item-tag',
						{'space-status-select__menu-item-tag_disabled': isDisabled}
					),
					label: 'space-status-select__menu-item-tag_label'
				}}
				label={status.name}
				color={color}
				size="large"
				threshold={0.5}
				multiline
			/>
		</MenuItem>
	);
};