import {Dispatch, memo} from 'react';
import './EditableOwnerAcceptance.less';
import {
	IEditableOwnerAcceptanceAction,
	IEditableOwnerAcceptanceState
} from '@src/core/hooks/states/useEditableOwnerAcceptanceState';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import Resolution from './components/Resolution';
import Attachments from '@src/components/editableFields/Attachments';
import SpaceOwner from '@src/components/editableFields/SpaceOwner';
import {useTranslation} from 'react-i18next';

interface IEditableOwnerAcceptanceProps {
	spaceId?: string;
	editingState: IEditableOwnerAcceptanceState;
	editingDispatch: Dispatch<IEditableOwnerAcceptanceAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableOwnerAcceptance = (props: IEditableOwnerAcceptanceProps) => {
	const {
		spaceId,
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		saving
	} = props;
	const {t} = useTranslation();
	return (
		<div className="editable-owner-acceptance">
			<div>
				<div className="editable-owner-acceptance__info-grid">
					{spaceId ? (
						<SpaceOwner
							data-testid="EditableOwnerAcceptanceSpaceOwner"
							spaceId={spaceId}
							value={editingState.spaceOwnerId}
							editingDispatch={editingDispatch}
							required
							disabled={saving}
							hasError={editingState.errors.spaceOwnerId}
						/>
					) : null}

					<Resolution
						data-testid="EditableEntityGridCell"
						value={editingState.resolution}
						label={t('editableOwnerAcceptance.resolution.label')}
						editingDispatch={editingDispatch}
						required
						disabled={saving}
						hasError={editingState.errors.resolution}
					/>
				</div>
			</div>

			<div>
				<Attachments
					data-testid="EditableOwnerAcceptanceAttachments"
					className={{
						root: 'editable-owner-acceptance__attachments',
						scrollArea: 'editable-owner-acceptance__attachments-scroll-area',
						files: 'editable-owner-acceptance__attachments-files',
						file: 'editable-owner-acceptance__attachments-file'
					}}
					attachments={editingState.attachments}
					uploadingFiles={uploadingFilesState.value}
					editingDispatch={editingDispatch}
					uploadingFilesDispatch={uploadingFilesDispatch}
					label={t('editableOwnerAcceptance.attachments.label')}
					disabled={saving}
					canDraw={false}
					waitForUploading={false}
					hasError={editingState.errors.attachments && uploadingFilesState.error}
					showAttachBtn
				/>
			</div>
		</div>
	);
};

export default memo(EditableOwnerAcceptance);
