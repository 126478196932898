import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {CacheStatus} from '@tehzor/tools/enums/CacheStatus';
import {OfflineModeStatus} from '@tehzor/tools/enums/OfflineModeStatus';
import {offlineMode} from '@src/store/modules/offlineMode/reducers';

export const extractOfflineStatus = createSelector(
	(s: IState) => s.offlineMode,
	({networkStatus, cacheStatus}: ReturnType<typeof offlineMode>): OfflineModeStatus => {
		if (networkStatus) {
			// if (передача данных) {
			// 	return OfflineModeStatus.SAVING;
			// }
			// if (ошибка при передаче данных) {
			// 	return OfflineModeStatus.SAVING_ERROR;
			// }
			if (cacheStatus === CacheStatus.CACHING) {
				return OfflineModeStatus.CACHING;
			}
			if (cacheStatus === CacheStatus.ERROR) {
				return OfflineModeStatus.CACHING_ERROR;
			}
			if (cacheStatus === CacheStatus.READY) {
				return OfflineModeStatus.ONLINE_READY;
			}
			if (cacheStatus === CacheStatus.MISSING) {
				return OfflineModeStatus.ONLINE_CACHE_MISSING;
			}
			return OfflineModeStatus.UNKNOWN;
		}
		if (cacheStatus === CacheStatus.READY) {
			return OfflineModeStatus.OFFLINE_READY;
		}
		if (cacheStatus === CacheStatus.MISSING) {
			return OfflineModeStatus.OFFLINE_CACHE_MISSING;
		}
		if (cacheStatus === CacheStatus.ERROR) {
			return OfflineModeStatus.OFFLINE_ERROR;
		}
		return OfflineModeStatus.UNKNOWN;
	}
);

export const extractNetworkStatus = createSelector(
	(s: IState) => s.offlineMode.networkStatus,
	(networkStatus: boolean): boolean => networkStatus
);
