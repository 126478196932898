import {useMemo} from 'react';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import ICanvasData from '@tehzor/tools/interfaces/ICanvasData';

/**
 * Извлекает из вложений ссылки на изображения
 *
 * @param uploads вложения загруженные в temp
 */
export const useUploadsImagesObjects = (uploads: IUploadingFile[]) =>
	useMemo(
		() =>
			uploads.reduce<
				Array<{
					id: string;
					key: string;
					url: string;
					canvas?: ICanvasData;
					original?: string;
				}>
			>((prev, item) => {
				if (item && item.tempFile && item.tempFile.full) {
					prev.push({
						id: item.tempFile.id,
						key: item.key,
						url: item.url,
						canvas: item.tempFile.canvas,
						original: item.tempFile.original?.url
					});
				}
				if (!item.tempFile) {
					prev.push({
						id: item.key,
						key: item.key,
						url: item.url,
						canvas: item.canvas?.drawedData,
						original: item.canvas?.originalFile
							? URL.createObjectURL(item.canvas.originalFile)
							: undefined
					});
				}
				return prev;
			}, []),
		[uploads]
	);
