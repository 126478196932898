import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	CreatedByFilterMobile,
	CreationDateFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {CompaniesFilterMobile} from '@src/components/MobileEntitiesFilters/components/CompaniesFilterMobile';
import {ContractorsFilterMobile} from '@src/components/MobileEntitiesFilters/components/ContractorsFilterMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useAppLocation} from '@src/core/hooks/useAppLocation';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IDocumentsFiltersState} from '@src/store/modules/settings/pages/documents/reducers';
import {LinkButton} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {useParams} from 'react-router';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {useTranslation} from 'react-i18next';

export const DocumentsFiltersPage = () => {
	const {t} = useTranslation();
	const {entity = 'documents'} = useParams<{entity?: string}>();
	const location = useAppLocation();

	const {goBack} = useChangePath();

	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IDocumentsFiltersState>();

	const handleApply = useCallback(() => {
		if (location.state.canGoBack) {
			apply();
			goBack();
		}
	}, [location, apply]);

	useAppHeader(
		{
			title: t('filtersPage.title"'),
			showBackBtn: true,
			mobileRightButtons: isCleanable(state) ? (
				<LinkButton
					label={t('filterPage.resetFiltersBtn.label')}
					onClick={mobileClear}
				/>
			) : null
		},
		[state]
	);

	useRouting(!location.state.canGoBack, 'all', entity);

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			<CompaniesFilterMobile
				value={state.companyIds}
				onChange={change}
				entity={entity}
				filterName="companyIds"
			/>

			<ContractorsFilterMobile
				onChange={change}
				entity={entity}
				value={state.contractorIds}
			/>

			<CreatedByFilterMobile
				entity={entity}
				onChange={change}
				value={state.createdBy}
			/>

			<CreationDateFilterMobile
				entity={entity}
				from={state.createdAtFrom}
				to={state.createdAtTo}
			/>
		</TranslatedFilterPage>
	);
};
