import {CellProps} from 'react-table';
import {IReportsTableRow} from '../../hooks/useReportsTable';

export const SelectedCell = ({row, column}: CellProps<IReportsTableRow>) => {
	const id = column.id;
	const data = row?.original.data;

	const count = data ? data?.[id] || 0 : 0;

	return (
		<div>
			{data ? count : ''}
		</div>
	);
};