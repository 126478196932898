import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	IContractsFiltersState,
	contractsActions
} from '@src/store/modules/settings/pages/contracts/reducers';
import {extractContractsPageSettings} from '@src/store/modules/settings/pages/contracts/selectors';
import { useCallback } from 'react';
import {Outlet} from 'react-router-dom';

export const ContractsFiltersRoutingPage = () => {
	const {filters} = useAppSelector(extractContractsPageSettings);
	const dispatch = useAppDispatch();
	const {changeFilters} = contractsActions;

	const applyFilters = useCallback(
		(value: IContractsFiltersState) => {
			dispatch(changeFilters(value));
		},
		[dispatch, changeFilters]
	);

	return (
		<MobileEntitiesFilters
			filters={filters}
			onApply={applyFilters}
			fullClear
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};
