import {IGetMeterConsumptionsResponse} from '@src/api/backend/meterConsumptions';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractMeterConsumptionsAsArrayForMeter} from '@src/core/hooks/queries/meterConsumptions/selectors';
import {meterConsumptionsQueryKeys} from '@src/api/cache/meterConsumptions/keys';
import {useCallback} from 'react';

export const useMeterConsumptions = <T = IGetMeterConsumptionsResponse>(
	meterId: string,
	select?: (data: IGetMeterConsumptionsResponse) => T
) =>
	useQuery<IGetMeterConsumptionsResponse, IError, T>({
		queryKey: meterConsumptionsQueryKeys.list(meterId),
		meta: {
			error: 'Ошибка получения показаний приборов учета'
		},
		select,
		enabled: !!meterId
	});

export const useMeterConsumptionsAsArrayForMeter = (meterId: string) => {
	const select = useCallback(
		(data: IGetMeterConsumptionsResponse) =>
			extractMeterConsumptionsAsArrayForMeter(data, meterId),
		[meterId]
	);

	return useMeterConsumptions(meterId, select);
};
