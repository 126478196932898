import {DndContextRef} from '../DndContext';
import {IDndDragState, IDndItem, IDragPositionEvent, IElementData} from '../interfaces';

/**
 * Проверяет находятся ли указанные координаты в элементе
 *
 * @param element элемент
 * @param x позиция по x
 * @param y позиция по y
 */
const isPointerInElement = (element: HTMLElement, x?: number, y?: number): boolean => {
	if (!x || !y) {
		return false;
	}

	const {top, bottom, left, right} = element.getBoundingClientRect();

	if (y < top || y > bottom) {
		return false;
	}
	if (x < left || x > right) {
		return false;
	}

	return true;
};

/**
 * Проверяет совпадает ли контейнер компонента с тем, в котором находится курсор
 *
 * @param container контейнер компонента
 * @param overContainer контейнер над которым находится курсор
 */
const isOverContainer = (container?: string, overContainer?: string): boolean => {
	if (container && overContainer !== container) {
		return false;
	}
	return true;
};

/**
 * Проверяет нужно ли начинать событие dragStart в компоненте
 *
 * @param dragData данные перемещаемого элемента
 * @param container контейнер компонента
 */
export const isDragStartEvent = <S extends IDndItem>(
	dragData: IElementData<S>,
	container?: string
): boolean => {
	if (!dragData.item || !isOverContainer(container, dragData.container)) {
		return false;
	}
	return true;
};

/**
 * Проверяет нужно ли начинать событие dragOver в компоненте
 *
 * @param event данные события
 * @param overData данные элемента над которым находится курсор
 * @param container контейнер компонента
 */
export const isDragOverEvent = <S extends IDndItem>(
	event: IDragPositionEvent,
	overData?: IElementData<S>,
	container?: string
): boolean => {
	const contextRef = DndContextRef.current;
	if (!contextRef || !isPointerInElement(contextRef, event.x, event.y)) {
		return false;
	}
	if (!isOverContainer(container, overData?.container)) {
		return false;
	}

	return true;
};

/**
 * Проверяет нужно ли начинать событие dragEnd в компоненте
 *
 * @param dragState drag состояние
 * @param container контейнер компонента
 * @param overContainer контейнер над которым находится курсор
 */
export const isDragEndEvent = <S extends IDndItem>(
	dragState: IDndDragState<S>,
	container?: string,
	overContainer?: string
): boolean => {
	if (!dragState.isDragging) {
		return false;
	}
	if (isOverContainer(container, dragState.initContainer)) {
		return true;
	}
	if (isOverContainer(container, overContainer)) {
		return true;
	}
	return false;
};