import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';

export type IGetListFieldSettingsSetsResponse = INormalizedData<IFieldSettingsSet>;

/**
 * Возвращает список наборов настроек полей
 */
export const requestGetListFieldSettingsSets = async () => {
	const res = await httpRequests.withToken.get<IGetListFieldSettingsSetsResponse>(
		'field-settings-sets/get-list'
	);

	return res.data;
};
