export const plansQueryKeys = {
	all: () => ['plans'],
	one: () => ['plan'],
	lists: () => [...plansQueryKeys.all(), 'list'],
	list: (objectIds?: string[]) => [...plansQueryKeys.lists(), objectIds],
	localLists: () => [...plansQueryKeys.all(), 'local-list'],
	localList: () => plansQueryKeys.localLists(),
	details: () => [...plansQueryKeys.one(), 'detail'],
	detail: (id: string) => [...plansQueryKeys.details(), id],
	editOrder: () => [...plansQueryKeys.all(), 'edit-order'],
};
