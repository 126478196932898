import {memo} from 'react';
import {BaseListFilterMobile} from './BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useTranslation} from 'react-i18next';
import {useTranslatedProblemStatuses} from '@src/core/hooks/translations/useProblemStatuses';

interface IProblemStatusesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	filterName?: string;
	onChange: (changes: IChanges) => void;
}

export const ProblemStatusesFilterMobile = memo((props: IProblemStatusesFilterMobileProps) => {
	const {t} = useTranslation();
	const statuses = useTranslatedProblemStatuses();

	if (!statuses) {
		return null;
	}

	return (
		<BaseListFilterMobile
			{...props}
			options={statuses}
			label={props.label ?? t('problemsPage.problemStatusesFilterMobile.label')}
			filterName={props.filterName ?? 'statuses'}
		/>
	);
});
