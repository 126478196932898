import {ProblemIndicator} from '@tehzor/ui-components';
import {useProblemsCountByStatus} from '../hooks/useProblemsCountByStatus';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';

interface ProblemIndicatorsProps {
	workAcceptanceId: string;
}

export const ProblemIndicators = ({workAcceptanceId}: ProblemIndicatorsProps) => {
	const {data: problemStatuses} = useProblemStatuses();
	const problemCounts = useProblemsCountByStatus(workAcceptanceId);

	return (
		<div className="check-list-page__problem-indicators">
			{problemStatuses &&
				problemCounts.map(({status, count}) =>
					count > 0 ? (
						<ProblemIndicator
							key={status}
							statuses={problemStatuses}
							status={status}
							count={count}
						/>
					) : null
				)}
		</div>
	);
};
