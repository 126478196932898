import {IContractsFiltersState, IContractsSortState} from '@src/store/modules/settings/pages/contracts/reducers';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IContract} from '@tehzor/tools/interfaces/contracts';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export type IGetListContractsResponse = INormalizedData<IContract>;

/**
 * Возвращает список договоров 
 */
export const requestGetListContracts = async (
	filters?: IContractsFiltersState,
	sort?: IContractsSortState
) => {
	const params = {filters, sort: convertSortParam(sort)};
	const res = await httpRequests.withToken.post<IGetListContractsResponse>(
		'contracts/get-list', params
	);

	return res.data;
};
