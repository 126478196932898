import {CellProps} from "react-table";
import {IEnrichedTask} from "@tehzor/tools/interfaces/tasks/IEnrichedTask";
import {useTaskStatuses} from "@src/core/hooks/queries/taskStatuses/hook";
import {useEditTaskStatus} from "@src/core/hooks/mutations/task/useEditTaskStatus";
import {useTranslatedDictionary} from "@src/core/hooks/translations/useTranslatedDictionary";
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";
import {useTasksPermissions} from "@src/core/hooks/permissions/useTasksPermissions";
import useAsyncFn from "react-use/lib/useAsyncFn";
import {TaskStatusId} from "@tehzor/tools/interfaces/tasks/ITaskStatus";
import ClickPreventWrap from "@src/components/tableCells/ClickPreventWrap";
import {StatusSelect} from "@tehzor/ui-components";


export const TaskStatusChangeableCell = (
	props: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'value'>
) => {
	const {value} = props;
	const task = value;
	const {data: statuses} = useTaskStatuses();
	const {mutateAsync: editTaskStatus} = useEditTaskStatus();
	const translatedStatuses = useTranslatedDictionary(dictionaryKeys.taskStatuses, statuses);

	const permissions = useTasksPermissions({
		objectId: task.objectId?.id,
		createdBy: task.createdBy?.id,
		respUsers: task.respUsers?.map(user => user.id),
		watchers: task.watchers,
		activeGroupLeader: task.activeGroup
	});

	const [, handleChange] = useAsyncFn(
		async (statusId: TaskStatusId) => {
			await editTaskStatus({taskId: task.id, statusId});
		},
		[task]
	);

	const availableStatuses = Object.values(TaskStatusId);

	return (
		<ClickPreventWrap>
			<StatusSelect
				className="tasks-page_status"
				statuses={translatedStatuses}
				availableStatuses={availableStatuses}
				disabled={!permissions.canEditStatus}
				value={task?.status?.id}
				onChange={handleChange}
				style={{maxWidth: '100%'}}
			/>
		</ClickPreventWrap>
	);
};
