import {createBrowserRouter} from 'react-router-dom';
import {ErrorBoundary} from '@tehzor/ui-components';
import {rootRoutes} from './rootRoutes';
import {App} from '@src/components/App';

export const router = createBrowserRouter([
	{
		element: <App />,
		errorElement: <ErrorBoundary label="router" />,
		children: rootRoutes
	}
]);
