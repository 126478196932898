import {structuresQueryKeys} from '../../../keys/index';
import {IStructuresStatsDataRequest} from '@src/interfaces/IStructuresStatsData';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetStructuresProblemsResponse} from '@/shared/api/structuresProblemData/getStructuresProblemdata';

export const useStructuresProblemsStats = ({
	objectId,
	structuresIds,
	options
}: IStructuresStatsDataRequest & {
	options?: Partial<UseQueryOptions<IGetStructuresProblemsResponse, IError>>;
}) =>
	useQuery<IGetStructuresProblemsResponse, IError>({
		queryKey: structuresQueryKeys.problemsStats(objectId, structuresIds),
		meta: {
			error: 'при загрузке статистики по нарушениям структур'
		},
		...options
	});
