import {getStatusColor} from './getStatusColor';
import {IStatus} from './IStatus';
import MenuItem from '../../../../menu/MenuItem';
import classNames from 'classnames';

export const getMenuItem = <S extends IStatus>(
	status: S,
	currentStatus?: S,
	disabled?: boolean
) => {
	const color = getStatusColor(status, true);

	return (
		<MenuItem
			dataTestId="MenuItem"
			key={status.id}
			itemKey={status.id}
			selected={currentStatus?.id === status.id}
			disabled={disabled}
			className={classNames('status-select__menu-item', 'base-status-select__menu-item', {
				current: currentStatus?.id === status.id
			})}
		>
			<span style={!disabled || currentStatus?.id === status.id ? {color} : undefined}>
				{status.name}
			</span>
		</MenuItem>
	);
};
