import {queryClient} from '@src/api/QueryClient';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IMigrateCategoriesSetResponse {
	success: boolean;
}

export interface IMigrateCategoriesSetData {
	fromSetId: string;
	toSetId: string;
	matches: Array<{fromId: string; toId: string}>;
}

export const requestMigrateCategoriesSet = async (
	migrateCategoriesSetData: IMigrateCategoriesSetData
) => {
	const response = await httpRequests.withToken.post<IMigrateCategoriesSetResponse>(
		'categories-sets/migrate',
		migrateCategoriesSetData
	);
	await queryClient.invalidateQueries({queryKey: categoriesSetsQueryKeys.list()});
	return response.data;
};
