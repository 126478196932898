import {createContext, useCallback, useMemo} from 'react';
import {Plate, EntitiesTable, PaginationAndSize, Pagination} from '@tehzor/ui-components';
import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {getMobileColumns, getMobileColumnsWithoutObjects} from './columns.mobile';
import {getDesktopColumns, getDesktopColumnsWithoutObjects} from './columns.desktop';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeSort,
	changePageSize,
	changeOffset
} from '@src/store/modules/settings/pages/manage/categoriesSets/actions';
import {extractCategoriesSetsPageSettings} from '@src/store/modules/settings/pages/manage/categoriesSets/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {useAsyncFn} from 'react-use';
import useUpdateContent from '@src/components/AddingCategoriesSet/hooks/useUpdateContent';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {useTranslation} from 'react-i18next';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<ICategoriesSet>) => void
>(() => ({}));

const pageSizes = [10, 20, 50, 100];

interface ICategoriesSetsTableProps {
	objectId?: string;
	data: IFullCategoriesSet[];
	currentPage: number;
	pageCount: number;
}

const CategoriesSetsTable = ({
	objectId = 'all',
	data,
	currentPage,
	pageCount
}: ICategoriesSetsTableProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();

	const {removeCategoriesSet} = useUpdateContent();

	const settings = useAppSelector(s => extractCategoriesSetsPageSettings(s, objectId));
	const {offset, pageSize} = settings;

	const isLargeTablet = useIsLargeTablet();
	const columns = useMemo(
		() =>
			determineTableColumns(
				objectId === 'all',
				isLargeTablet,
				getMobileColumns(t),
				getMobileColumnsWithoutObjects(t),
				getDesktopColumns(t),
				getDesktopColumnsWithoutObjects(t)
			),
		[objectId, isLargeTablet]
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<ICategoriesSet>) => {
			if (action.type === 'delete' && action.payload) {
				const {id} = action.payload;
				await removeCategoriesSet(id);
			}
		},
		[]
	);

	const handleRowClick = useCallback(
		(row: IFullCategoriesSet) => {
			if (objectId !== 'all') {
				pushPath(`/objects/${objectId}/categories-sets/${row.id}`);
			} else {
				pushPath(`/manage/categories-sets/${row.id}`);
			}
		},
		[objectId]
	);

	const handleSortChange = useCallback(
		(value: Record<string, boolean>) => {
			dispatch(changeSort(objectId || 'all', value));
		},
		[objectId]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				dispatch(changeOffset(objectId, newOffset));
			}
		},
		[objectId, offset, pageSize]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			const newOffset = Math.floor(offset / value);

			dispatch(changePageSize(objectId, value));
			dispatch(changeOffset(objectId, newOffset));
		},
		[objectId, offset]
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={data}
						responsive={!isLargeTablet}
						onRowClick={e => handleRowClick(e)}
						onSortChange={e => handleSortChange(e)}
					/>
				</Plate>
			</DispatchActionCtx.Provider>
			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pageCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
};
export default CategoriesSetsTable;
