export type ISpaceTypesDecorationsSetsQueryKey = [] & {
	0: string; //space-type-decoration-set,
	1: string; //id
};
export const spaceTypeDecorationSetsQueryKeys = {
	all: () => ['space-type-decoration-sets'],
	one: () => ['space-type-decoration-set'],
	lists: () => [...spaceTypeDecorationSetsQueryKeys.all(), 'list'],
	detail: (id?: string) => [...spaceTypeDecorationSetsQueryKeys.one(), id],
	add: () => [...spaceTypeDecorationSetsQueryKeys.one(), 'add'],
	edit: () => [...spaceTypeDecorationSetsQueryKeys.one(), 'edit'],
	delete: () => [...spaceTypeDecorationSetsQueryKeys.one(), 'delete']
};
