import SearchableProblemDescription from '@src/components/EditableProblem/components/SearchableProblemDescription';
import Attachments from '@src/components/editableFields/Attachments';
import {EditableDate} from '@src/components/editableFields/EditableDate';
import Floors from '@src/components/editableFields/Floors';
import {Location} from '@src/components/editableFields/Location';
import {useContractsPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {
	IEditableProblemAction,
	IEditableProblemState
} from '@src/core/hooks/states/useEditableProblemState';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useIsDesktopHeight, useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Dispatch, memo} from 'react';
import {useTranslation} from 'react-i18next';
import Category from '../editableFields/Category';
import {Contracts} from '../editableFields/Contracts';
import {CustomField} from '../editableFields/CustomField';
import Description from '../editableFields/Description';
import Prescription from '../editableFields/Prescription';
import {ProblemTags} from '../editableFields/ProblemTags';
import './EditableProblem.less';
import {SearchableProblemReason} from './components/SearchableProblemReason';
import {EditableProblemInspectors} from './fields/EditableProblemInspectors';
import {EditableProblemPerformers} from './fields/EditableProblemPerformers';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useProblemTemplatesAsArray} from '@src/core/hooks/queries/problemTemplatesSets/hooks';

const desktopDescProps = {
	minRows: 3,
	maxRows: 7
};
const mobileDescProps = {
	minRows: 3,
	maxRows: 5
};

interface IEditableProblemProps {
	objectId: string;
	stage: ObjectStageIds;
	scope?: string;
	createdBy?: string;
	editingState: IEditableProblemState;
	editingDispatch: Dispatch<IEditableProblemAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	fieldsSettings: IEntitySettings;
	saving: boolean;
	isLoading?: boolean;
	fieldsAvailability?: Record<string, boolean>;
	entityLocation?: ILocation;
	entityPlanId?: string;
	exceptions?: IEditableProblemState['exceptions'];
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableProblem = (props: IEditableProblemProps) => {
	const {
		objectId,
		stage,
		scope,
		createdBy,
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		fieldsSettings,
		saving,
		fieldsAvailability,
		entityLocation,
		entityPlanId,
		isLoading,
		exceptions
	} = props;
	const {t} = useTranslation();

	const builtinNumber = Object.keys(fieldsSettings.builtin).length;
	const hasCustomFields = !!Object.keys(fieldsSettings.custom).length;

	const isDesktop = useIsLargeTablet();
	const isDesktopHeight = useIsDesktopHeight();
	const isFieldsDisabled = saving || isLoading;

	const problem = useAppSelector(s => s.pages.problem.data);
	const {canView} = useContractsPermissions();
	const attachmentsClassNames = {
		root: 'editable-problem__attachments',
		scrollArea: 'editable-problem__attachments-scroll-area',
		files: 'editable-problem__attachments-files',
		file: 'editable-problem__attachments-file'
	};

	const {data: object} = useObject(objectId);
	const {data: templates} = useProblemTemplatesAsArray(object);

	return (
		<div className="editable-problem">
			<div>
				{fieldsSettings.builtin.description !== undefined &&
					(templates && templates.length > 0 ? (
						<SearchableProblemDescription
							data-testid="EditableEntityGridCell"
							className="editable-problem__description"
							value={editingState.description}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.description.required}
							disabled={isFieldsDisabled}
							hasError={editingState.errors.description}
							textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
							objectId={objectId}
							stage={stage}
							label={t('components.editableProblem.description.label')}
						/>
					) : (
						<Description
							data-testid="EditableEntityGridCell"
							className="editable-problem__description"
							value={editingState.description}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.description.required}
							disabled={
								(fieldsAvailability && !fieldsAvailability.description) ||
								isFieldsDisabled
							}
							hasError={editingState.errors.description}
							textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
							label={t('components.editableProblem.description.label')}
						/>
					))}

				<div className="editable-problem__info-grid">
					{fieldsSettings.builtin.categoryId !== undefined && (
						<Category
							data-testid="EditableEntityGridCell"
							value={editingState.categoryId}
							objectId={objectId}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.categoryId.required}
							disabled={
								(fieldsAvailability && !fieldsAvailability.categoryId) ||
								isFieldsDisabled
							}
							hasError={editingState.errors.categoryId}
							stage={stage}
							exception={exceptions?.categoryId}
							label={t('components.editableProblem.category.label')}
						/>
					)}

					{fieldsSettings.builtin.plannedFixDate !== undefined && (
						<EditableDate
							data-testid="EditableEntityGridCell"
							value={editingState.plannedFixDate}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.plannedFixDate.required}
							disabled={
								(fieldsAvailability && !fieldsAvailability.plannedFixDate) ||
								isFieldsDisabled
							}
							hasError={editingState.errors.plannedFixDate}
							criticalValue={editingState.critical}
							criticalFieldsSettings={fieldsSettings.builtin.critical}
							criticalDisabled={fieldsAvailability && !fieldsAvailability.critical}
							createdAt={editingState.createdAt}
							showTimeSelect
							label={t('components.editableProblem.plannedFixDate.label')}
						/>
					)}

					{fieldsSettings.builtin.reason !== undefined && (
						<SearchableProblemReason
							data-testid="EditableEntityGridCell"
							value={editingState.reason}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.reason.required}
							disabled={
								(fieldsAvailability && !fieldsAvailability.reason) ||
								isFieldsDisabled
							}
							hasError={editingState.errors.reason}
							label={t('components.editableProblem.reason.label')}
						/>
					)}

					{fieldsSettings.builtin.problemTags !== undefined && (
						<ProblemTags
							data-testid="EditableEntityGridCell"
							objectId={objectId}
							stage={stage}
							tags={editingState.problemTags}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.problemTags.required}
							exception={exceptions?.problemTags}
							disabled={
								(fieldsAvailability && !fieldsAvailability.problemTags) ||
								isFieldsDisabled
							}
							hasError={editingState.errors.problemTags}
							label={t('components.editableProblem.tages.label')}
						/>
					)}

					{fieldsSettings.builtin.location !== undefined && (
						<Location
							data-testid="EditableEntityGridCell"
							planId={editingState.planId || entityPlanId}
							location={editingState.location}
							objectId={objectId}
							disabled={
								(fieldsAvailability && !fieldsAvailability.location) ||
								isFieldsDisabled
							}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.location.required}
							hasError={editingState.errors.location}
							entityLocation={entityLocation}
							label={t('components.editableProblem.location.label')}
						/>
					)}

					{fieldsSettings.builtin.floor !== undefined && (
						<Floors
							data-testid="EditableEntityGridCell"
							value={editingState.floor}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.floor.required}
							disabled={
								(fieldsAvailability && !fieldsAvailability.floor) ||
								isFieldsDisabled
							}
							hasError={editingState.errors.floor}
							label={t('components.editableProblem.floor.label')}
						/>
					)}

					{fieldsSettings.builtin.performers !== undefined && (
						<EditableProblemPerformers
							data-testid="EditableEntityGridCell"
							objectId={objectId}
							stage={stage}
							scope={scope}
							editingState={editingState}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.performers.required}
							disabled={
								(fieldsAvailability && !fieldsAvailability.performers) ||
								isFieldsDisabled
							}
							hasError={
								editingState.errors.performers &&
								editingState.errors.performersActiveGroup
							}
							exception={exceptions?.performers}
						/>
					)}
					{fieldsSettings.builtin.inspectors !== undefined && (
						<EditableProblemInspectors
							data-testid="EditableEntityGridCell"
							objectId={objectId}
							stage={stage}
							scope={scope}
							createdBy={createdBy}
							editingState={editingState}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.inspectors.required}
							disabled={
								(fieldsAvailability && !fieldsAvailability.inspectors) ||
								isFieldsDisabled
							}
							hasError={editingState.errors.inspectors}
						/>
					)}

					{fieldsSettings.builtin.contractId !== undefined && canView && (
						<Contracts
							data-testid="EditableEntityGridCell"
							value={editingState.contractId}
							objectId={objectId}
							stage={stage}
							planId={editingState.planId}
							categoryId={editingState.categoryId}
							editingDispatch={editingDispatch}
							required={fieldsSettings.builtin.contractId.required}
							disabled={
								(fieldsAvailability && !fieldsAvailability.contractId) ||
								isFieldsDisabled
							}
							hasError={editingState.errors.contractId}
							exception={exceptions?.contractId}
							label={t('components.editableProblem.contract.label')}
						/>
					)}
					{hasCustomFields &&
						Object.values(fieldsSettings.custom).map(customSetting => (
							<CustomField
								data-testid="EditableEntityGridCell"
								key={customSetting.id}
								custom={customSetting}
								isMobile={!isDesktop}
								editingDispatch={editingDispatch}
								editingState={editingState}
								disabled={isFieldsDisabled}
							/>
						))}
				</div>
				{fieldsSettings.builtin.prescription !== undefined && (
					<Prescription
						data-testid="EditableEntityGridCell"
						className="editable-problem__prescription"
						value={editingState.prescription}
						editingDispatch={editingDispatch}
						required={fieldsSettings.builtin.prescription.required}
						disabled={
							(fieldsAvailability && !fieldsAvailability.prescription) ||
							isFieldsDisabled
						}
						hasError={editingState.errors.prescription}
						textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
						label={t('components.editableProblem.prescription.label')}
					/>
				)}
			</div>

			<div>
				{fieldsSettings.builtin.attachments !== undefined && (
					<Attachments
						data-testid="EditableProblemAttachments"
						className={attachmentsClassNames}
						imagesTitle={t('components.editableProblem.attachments.label')}
						attachmentsDestination={AttachmentFileDestination.PROBLEM}
						entityId={problem?.id}
						attachments={editingState.attachments}
						uploadingFiles={uploadingFilesState.value}
						editingDispatch={editingDispatch}
						uploadingFilesDispatch={uploadingFilesDispatch}
						required={fieldsSettings.builtin.attachments.required}
						disabled={
							(fieldsAvailability && !fieldsAvailability.attachments) ||
							isFieldsDisabled
						}
						canDraw={fieldsAvailability && fieldsAvailability.attachments}
						waitForUploading={false}
						hasError={editingState.errors.attachments && uploadingFilesState.error}
						showAttachBtn
						label={t('components.editableProblem.attachments.imagesTitle')}
					/>
				)}
			</div>

			{!builtinNumber && !hasCustomFields && (
				<div className="editable-problem__empty-fields-msg">
					{t('components.editableProblem.emptyFieldsMsg')}
				</div>
			)}
		</div>
	);
};

export default memo(EditableProblem);
