import {Context, createContext, useContext} from 'react';
import {IChanges} from './EntitiesFiltersProvider';

export interface IEntitiesFiltersCtx<S extends Record<string, unknown>> {
	state: S;
	isStateChanged: boolean;
	isLocal: boolean;
	change: (changes: IChanges<S>) => void;
	clear: () => void;
	mobileClear: () => void;
	apply: () => void;
	dispatch: (changes: IChanges<S>) => void;
}

/**
 * Создает контекст для всех фильтров
 */
export const EntitiesFiltersCtx = createContext<IEntitiesFiltersCtx<Record<string, unknown>>>({
	state: {},
	isLocal: false,
	isStateChanged: false,
	change: () => null,
	clear: () => null,
	mobileClear: () => null,
	apply: () => null,
	dispatch: () => null
});

export const useEntitiesFiltersCtx = <S extends Record<string, unknown>>() =>
	useContext<IEntitiesFiltersCtx<S>>(EntitiesFiltersCtx as Context<IEntitiesFiltersCtx<S>>);
