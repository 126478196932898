import {IGetSpacesTypesCountResponse} from '@src/api/backend/spaces/spacesTypesCount';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useSuspenseQuery} from '@tanstack/react-query';
import {useCallback} from 'react';
import {
	extractFilteredSpacesTypes,
	extractObjectsSpacesFilteredByType,
	extractSpacesSchemaTypes,
	extractTypesDataByObjects
} from './selectors';
import {getSpacesTypesCount} from '@src/store/persistentStorage/offlineActions/spacesTypesCount';
import {useExtractSpaceTypesAsArray} from '../../spaceTypes/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';

export const useSpacesTypesCount = <T = IGetSpacesTypesCountResponse>(
	objectIds: string[],
	select?: (data: IGetSpacesTypesCountResponse) => T
) =>
	useSuspenseQuery({
		queryKey: spacesQueryKeys.typesCount(objectIds),
		initialData: getSpacesTypesCount(objectIds),
		staleTime: Infinity,
		select
	});

export const useSpacesTypesCountDataByObjects = (objectIds: string[]) => {
	const selector = useCallback(
		(data: IGetSpacesTypesCountResponse) => extractTypesDataByObjects(data, objectIds),
		[objectIds]
	);
	return useSpacesTypesCount(objectIds, selector);
};

export const useFilteredSpacesTypes = (objectIds: string[]) => {
	const {data: spaceTypes} = useExtractSpaceTypesAsArray();
	const translatedSpaceTypes = useTranslatedDictionaryArray('spaceTypes', spaceTypes);

	const selector = useCallback(
		(data: IGetSpacesTypesCountResponse) =>
			extractFilteredSpacesTypes(data, translatedSpaceTypes, objectIds),
		[translatedSpaceTypes, objectIds]
	);
	return useSpacesTypesCount(objectIds, selector);
};

export const useObjectsSpacesFilteredByType = (objectIds: string[], type?: string) => {
	const selector = useCallback(
		(data: IGetSpacesTypesCountResponse) =>
			extractObjectsSpacesFilteredByType(data, objectIds, type),
		[objectIds, type]
	);
	return useSpacesTypesCount(objectIds, selector);
};

export const useSpacesSchemaTypes = (objectIds: string[]) => {
	const {data: spaceTypes} = useExtractSpaceTypesAsArray();
	const translatedSpaceTypes = useTranslatedDictionaryArray('spaceTypes', spaceTypes);

	const selector = useCallback(
		(data: IGetSpacesTypesCountResponse) =>
			extractSpacesSchemaTypes(data, translatedSpaceTypes, objectIds),
		[objectIds, translatedSpaceTypes]
	);
	return useSpacesTypesCount(objectIds, selector);
};
