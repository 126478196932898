import {formWarrantyClaimLink} from '@tehzor/tools/utils/links';
import {useMemo} from 'react';
import {useWarrantyClaimsBreadcrumbs} from './useWarrantyClaimsBreadcrumbs';
import {useTranslation} from "react-i18next";

/**
 * Хук для хлебных крошек от "всех объектов" до гарантийного обращения
 *
 * @param objectId id конечного объекта
 * @param warrantyClaimId id помещения
 * @param number номер помещения
 */
export function useWarrantyClaimBreadcrumbs(
	objectId: string,
	warrantyClaimId: string,
	number: number | undefined
) {
	const {t} = useTranslation();
	const res = useWarrantyClaimsBreadcrumbs(objectId);
	return useMemo(
		() =>
			res?.concat({
				label: number ? `${t('warrantyClaimPage.title')} №${number}` : t('warrantyClaimPage.title'),
				url: formWarrantyClaimLink(objectId, warrantyClaimId),
				inactive: true
			}),
		[objectId, warrantyClaimId, res]
	);
}
