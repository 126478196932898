import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {ITasksFiltersState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useUpdateEffect} from 'react-use';

export const TasksSearch = () => {
	const {t} = useTranslation();
	const {state, dispatch} = useEntitiesFiltersCtx<ITasksFiltersState>();
	const [searchValue, setSearchValue] = useState<string | undefined>(state.searchString);
	const handleChange = useCallback((v: string | undefined) => {
		setSearchValue(v);
	}, []);

	const handleApplySearch = useCallback(() => {
		dispatch({searchString: searchValue});
	}, [dispatch, searchValue]);

	const handleClear = useCallback(() => {
		dispatch({searchString: undefined});
	}, [dispatch]);

	useUpdateEffect(() => {
		setSearchValue(state.searchString);
	}, [state.searchString]);

	return (
		<TranslatedSelectSearch
			placeholder={t('tasksPage.filters.tasksSearch.placeholder')}
			value={searchValue}
			type="filter"
			onClear={handleClear}
			onChange={handleChange}
			onSearch={searchValue ? handleApplySearch : undefined}
		/>
	);
};
