import {CellProps} from 'react-table';
import {Tag} from '@tehzor/ui-components';
import {IPreparedExportTemplate} from '../../interfaces/IPreparedExportTemplate';
import {useObjectsMap} from '@src/core/hooks/queries/objects/hooks';

export const ObjectsCell = ({row}: CellProps<IPreparedExportTemplate>) => {
	const rowObjects = row.original.objects || [];
	const objectsById = useObjectsMap();

	return (
		<>
			{rowObjects.map((object, index, arr) => {
				if (objectsById?.[object]) {
					if (index < 2) {
						return (
							<Tag
								className="objectTag"
								key={objectsById?.[object].id}
								label={objectsById?.[object].name}
							/>
						);
					}
					if (index === 2) {
						return (
							<Tag
								className="objectTag"
								key={objectsById?.[object].id}
								label={`+${arr.length - 2}`}
							/>
						);
					}
				}
				return null;
			})}
		</>
	);
};
