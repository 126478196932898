import {EntityInfo, EntityInfoDateWithCountFiles} from '@tehzor/ui-components';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IInternalAcceptanceInfoProps {
	data: IInternalAcceptance;
}

const acceptanceIcon = <i className="tz-internal-acceptance-28" />;

const InternalAcceptanceInfo = ({data}: IInternalAcceptanceInfoProps) => {
	const dateFnsLocale = useDateFnsLocale();
	const {t} = useTranslation();
	return (
		<EntityInfo
			title={`${t('internalAcceptanceInfoCell.title')} №${data.number ?? data.localNumber}`}
			subTitle={
				<EntityInfoDateWithCountFiles
					labelFrom={t('components.tableCell.info.entityInfoDateWithCountFiles.labelFrom')}
					locale={dateFnsLocale}
					data={data}
				/>
			}
			icon={acceptanceIcon}
			iconColor="#57A4FF"
			iconSize={44}
		/>
	);
};

export default InternalAcceptanceInfo;
