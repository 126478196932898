import {Plate} from '@tehzor/ui-components';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {WorkAcptAttachments} from '@src/pages/WorkAcceptancePage/components/workAcptAttachments/WorkAcptAttachments';
import {WorkAcceptanceResult} from '@src/pages/WorkAcceptancePage/components/WorkAcceptanceResult/WorkAcceptanceResult';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {WorkAcceptanceTask} from '../tasks/WorkAcceptanceTask';

interface IDesktopInfoProps {
	openDialog: () => void;
	unitString?: string;
	unitPlanString?: string;
	workAcceptance: IWorkAcceptance;
	permissions?: IPermissions;
}

export const DesktopInfo = ({
	openDialog,
	unitString,
	unitPlanString,
	workAcceptance,
	permissions
}: IDesktopInfoProps) => (
	<div className="work-acceptance-page__d-column work-acceptance-page__d-column_info">
		<WorkAcceptanceResult
			openDialog={openDialog}
			unitString={unitString}
			unitPlanString={unitPlanString}
			workAcceptance={workAcceptance}
			permissions={permissions}
		/>
		<Plate withoutPadding>
			<WorkAcptAttachments
				workAcceptanceId={workAcceptance.id}
				objectId={workAcceptance.objectId}
				attachments={workAcceptance.attachments}
			/>
		</Plate>
		<Plate withoutPadding>
			<WorkAcceptanceTask
				objectId={workAcceptance.objectId}
				workAcceptance={workAcceptance}
				isDesktop
			/>
		</Plate>
	</div>
);
