import * as React from 'react';
import './TasksSchedulePopup.less';
import {TasksListPopup} from './components/TasksListPopup';
import {Dialog} from '@tehzor/ui-components';
import {IEnrichedTaskForScheduler} from '@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler';
import {useTranslation} from 'react-i18next';

interface ITasksSchedulePopupProps {
	tasks: IEnrichedTaskForScheduler[];
	isOpen: boolean;
	title?: string;
	objectId?: string;
	startTime?: number;
	endTime?: number;
	onClose: (e: React.SyntheticEvent) => void;
}

export const TasksSchedulePopup = (props: ITasksSchedulePopupProps) => {
	const {tasks, isOpen, onClose, title, objectId, startTime, endTime} = props;
	const {t} = useTranslation();
	const popupTitle = title || t('components.tasksSchedulePopup.title');

	return (
		<Dialog
			className={{
				root: 'tasks-schedule-popup',
				content: 'tasks-schedule-popup__content'
			}}
			isOpen={isOpen}
			title={popupTitle}
			fullScreenOnMobile
			onRequestClose={onClose}
		>
			<TasksListPopup
				data={tasks}
				objectId={objectId}
				startTime={startTime}
				endTime={endTime}
			/>
		</Dialog>
	);
};
