import {CHANGE_NEW_VERSION} from '../constants';

/**
 * Изменяет версию приложения, на которую следует обновиться
 *
 * @param version новая версия
 */
export const changeAppUpdateNewVersion = (version: string | undefined) => ({
	type: CHANGE_NEW_VERSION,
	payload: version
});
