import { memo, useState } from 'react';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import DesktopInfo from './info/Info.desktop';
import {Plate} from '@tehzor/ui-components';
import DesktopActions from './actions/Actions.desktop';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useLocation} from 'react-router-dom';
import {FromSpacePageBreadcrumbs} from './FromSpacePageBreadcrumbs';
import Table from './table/Table';
import {useDesktopColumns} from './table/columns.desktop';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {useTranslation} from "react-i18next";

interface IDesktopProps {
	objectId: string;
	ownerAcceptanceId: string;
	ownerAcceptance?: ILinkedOwnerAcceptance;
}

export const Desktop = memo(({objectId, ownerAcceptanceId, ownerAcceptance}: IDesktopProps) => {
	const {t} = useTranslation();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const spaceId = (useLocation().state as {spaceId?: string} | null)?.spaceId;

	useAppHeader(
		{
			title: ownerAcceptance
				? `${t('ownerAcceptancePage.useAppHeader.title')} №${ownerAcceptance.number}`
				: t('ownerAcceptancePage.useAppHeader.title'),
			showBackBtn: true
		},
		[ownerAcceptance]
	);

	const desktopColumns = useDesktopColumns(objectId, ownerAcceptanceId);

	return ownerAcceptance ? (
		<div className="page-cont owner-acceptance-page">
			{spaceId ? (
				<FromSpacePageBreadcrumbs
					objectId={objectId}
					ownerAcceptance={ownerAcceptance}
				/>
			) : (
				<PageBreadcrumbs
					objectId={objectId}
					ownerAcceptance={ownerAcceptance}
				/>
			)}
			<div className="owner-acceptance-page__d-columns">
				<div className="owner-acceptance-page__d-column owner-acceptance-page__d-column_info">
					<DesktopInfo
						objectId={objectId}
						ownerAcceptance={ownerAcceptance}
					/>
				</div>

				<div className="owner-acceptance-page__d-column owner-acceptance-page__d-column_entities">
					{ownerAcceptance && <DesktopActions ownerAcceptance={ownerAcceptance} />}

					<Plate withoutPadding>
						<Table
							objectId={objectId}
							ownerAcceptanceId={ownerAcceptanceId}
							ownerAcceptance={ownerAcceptance}
							columns={desktopColumns}
							selectedRows={selectedRows}
							onSelectedRowsChange={setSelectedRows}
						/>
					</Plate>
				</div>
			</div>
		</div>
	) : null;
});
