import {IBaseStatus} from './IBaseStatus';

export const isDisabled = <S extends IBaseStatus>(
	status: S, currentStatus?: S, availableStatuses?: string[]
) => {
	if (currentStatus?.transitions?.length && availableStatuses?.length) {
		return !(currentStatus.transitions.includes(status.id) && availableStatuses?.includes(status.id));
	}
	if (currentStatus?.transitions?.length) {
		return !currentStatus.transitions.includes(status.id);
	}
	if (availableStatuses?.length) {
		return !availableStatuses.includes(status.id);
	}

	return false;
};