import {Column} from 'react-table';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import StagesCell from './StagesCell';
import UserCell from './UserCell';
import DeleteCell from './DeleteCell';
import {ObjectsCell} from './ObjectsCell';
import InfoCell from './InfoCell';

export const desktopColumns: Array<Column<IEnrichedProblemTagsSet>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Название',
		Cell: InfoCell,
		width: 80,
		sortDescFirst: true
	},
	{
		id: 'companyId',
		Header: 'Компания',
		accessor: item => item.company?.name,
		width: 90
	},
	{
		id: 'objects',
		Header: 'Объекты',
		accessor: 'objects',
		Cell: ObjectsCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'stages',
		Header: 'Стадии',
		Cell: StagesCell,
		width: 60
	},
	{
		Header: 'Автор',
		id: 'createdBy.displayName',
		accessor: 'createdBy',
		Cell: UserCell,
		width: 90
	},
	{
		id: 'createdAt',
		accessor: 'createdAt',
		Header: 'Добавлено',
		width: 40
	},
	{
		id: 'modifiedAt',
		accessor: 'modifiedAt',
		Header: 'Изменено',
		width: 40
	},
	{
		id: '_delete',
		Cell: DeleteCell,
		width: 80,
		minWidth: 68,
		disableResizing: true,
		isNonClickable: true
	}
];
