import {GlobalEntityAdding} from './GlobalEntityAdding';

interface IEntityAddingProps {
	objectId: string;
	structureId: string;
}

export const EntityAdding = ({objectId, structureId}: IEntityAddingProps) => (
	<GlobalEntityAdding
		objectId={objectId}
		structureId={structureId}
	/>
	);
