import {EntityGridItem} from '@tehzor/ui-components';
import {useTranslation} from "react-i18next";

interface SpaceOwnerProps {
	name: string;
	phone?: string;
	email?: string;
}

const SpaceOwners = ({name, phone, email}: SpaceOwnerProps) => {
	const {t} = useTranslation();
	return (
		<EntityGridItem
			label={t('ownerAcceptancePage.info.spaceOwners.label')}
			icon={<i className="tz-space-owner-20"/>}
			fullRow
		>
			<div className="owner-acceptance-page__owner">
				<div className="owner-acceptance-page__owner-name">{name}</div>

				{phone !== undefined && (
					<div className="owner-acceptance-page__owner-contact">
						<a href={`tel:${phone}`}>{phone}</a>
					</div>
				)}

				{email !== undefined && (
					<div className="owner-acceptance-page__owner-contact">
						<a href={`mailto:${email}`}>{email}</a>
					</div>
				)}
			</div>
		</EntityGridItem>
	)
};
export default SpaceOwners;
