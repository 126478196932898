import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';
import WClaimStatusesFilterPage from '@src/pages/filters/WClaimStatusesFilterPage';
import WarrantyClaimsFiltersPage from '@src/pages/filters/WarrantyClaimsFiltersPage';
import {RegistrationDateFilterPage} from '@src/pages/filters/RegistrationDateFilterPage';

export const warrantyClaimsFilterRoutes = [
	{
		path: 'objects',
		element: <FiltersPageObjects />
	},
	{
		path: 'statuses',
		element: <WClaimStatusesFilterPage />
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: 'registrationDate',
		element: <RegistrationDateFilterPage />
	},
	{
		path: '',
		element: <WarrantyClaimsFiltersPage />
	}
];
