import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import {DateFilter} from '@src/components/EntitiesFilters';
import {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

interface IAcceptancePeriodFilterProps {
	from?: Date;
	to?: Date;
}
export const AcceptancePeriodFilter = memo(({from, to}: IAcceptancePeriodFilterProps) => {
	const {t} = useTranslation();
	const {dispatch} = useEntitiesFiltersCtx<IWorkAcceptancesFiltersState>();

	const handleApply = useCallback(
		(dateFrom?: Date | null, dateTo?: Date | null) => {
			dispatch({acceptanceDateFrom: dateFrom, acceptanceDateTo: dateTo});
		},
		[dispatch]
	);

	return (
		<DateFilter
			from={from}
			to={to}
			label={t('entitiesFilters.acceptancePeriodFilter.label')}
			onChange={handleApply}
		/>
	);
});
