import * as React from 'react';
import './TableHeadSelectionRow.less';
import classNames from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import {Row} from 'react-table';
import {convertSelectedRowsFromInternal} from '../shared/utils/convertSelectedRowsFromInternal';
import {ISelectionRowProps} from '../shared/interfaces';

const animationVariants = {
	opened: {opacity: 1},
	collapsed: {opacity: 0}
};

const animationTransition = {type: 'tween', duration: 0.1};

interface ITableSelectionHeadProps<D extends { id: string }> {
	className?: string;
	style?: React.CSSProperties;
	role?: string;
	visible?: boolean;
	selectedRows?: string[];
	selectedRowIds: Record<string, boolean>;
	selectedFlatRows: Array<Row<D>>;

	renderSelectionRow: (props: ISelectionRowProps<D>) => React.ReactNode;
	toggleAllRowsSelected: (value?: boolean) => void;
}

const TableHeadSelectionRow = <D extends { id: string }>(props: ITableSelectionHeadProps<D>) => {
	const {
		className,
		style,
		role,
		visible,
		selectedRows,
		selectedRowIds,
		selectedFlatRows,
		renderSelectionRow,
		toggleAllRowsSelected
	} = props;

	return (
		<AnimatePresence initial={false}>
			{visible && (
				<motion.div
					className={classNames('table-head-selection-row', className)}
					style={style}
					role={role}
					initial="collapsed"
					animate="opened"
					exit="collapsed"
					variants={animationVariants}
					transition={animationTransition}
				>
					{renderSelectionRow({
						selectedRows: selectedRows ?? convertSelectedRowsFromInternal(selectedRowIds),
						selectedFlatRows,
						toggleAllRowsSelected
					})}
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default TableHeadSelectionRow;