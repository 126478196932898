import createReducer from '@tehzor/tools/utils/createReducer';
import {
	ADD_LOCAL_WARRANTY_CLAIM,
	CHANGE_LOCAL_WARRANTY_CLAIM_MODIFIED_AT,
	DELETE_LOCAL_WARRANTY_CLAIM
} from '@src/constants/local-entities/warrantyClaim';
import {ILocalWarrantyClaim} from '@src/interfaces/ILocalWarrantyClaim';

type ILocalWarrantyClaimsState = ILocalWarrantyClaim[];

const warrantyClaims = createReducer<ILocalWarrantyClaimsState>([], {
	[ADD_LOCAL_WARRANTY_CLAIM]: (state, payload: {warrantyClaim: ILocalWarrantyClaim}) => [
		...state,
		payload.warrantyClaim
	],
	[CHANGE_LOCAL_WARRANTY_CLAIM_MODIFIED_AT]: (
		state,
		payload: {key: string, modifiedAt: number}
	) =>
		state.map(item => {
			if (item.key === payload.key) {
				item.modifiedAt = payload.modifiedAt;
				return item;
			}
			return item;
		}),
	[DELETE_LOCAL_WARRANTY_CLAIM]: (state, payload: {key: string}) =>
		state.filter(item => item.key !== payload.key)
});

export {ILocalWarrantyClaimsState};
export default warrantyClaims;
