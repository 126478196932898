import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import {TempFileDestination} from '@tehzor/tools/enums/TempFileDestination';
import {convertRawToUploading} from '@src/utils/attachments/filesConverters';

export function convertRawFiles(
	files: IRawFile[],
	networkStatus: boolean,
	waitForUploading?: boolean
) {
	/**
	 * Если отсутствует/false флаг waitForUploading не дожидаемся загрузки и показываем,
	 * что файл загружен иначе проверяем статус сети и показываем нормальные статусы
	 */
	const status = !waitForUploading
		? UploadingFileStatus.FINISHED
		: networkStatus
		? UploadingFileStatus.WAITING
		: UploadingFileStatus.LOCAL;
	return files.map(file => {
		/**
		 * Если размер файла больше 50мб, то ставим ему sizeError
		 */
		const sizeError = file.size > 50 * 1024 * 1024;
		return convertRawToUploading(
			file,
			TempFileDestination.ATTACHMENT,
			sizeError ? UploadingFileStatus.ERROR : status,
			sizeError
		);
	});
}
