import { useState } from 'react';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import PageBreadcrumbs from './PageBreadcrumbs';
import Info from './info/Info';
import DesktopActions from './actions/Actions.desktop';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {Plate} from '@tehzor/ui-components';
import Table from './table/Table';
import {DesktopEntityDocs} from '@src/components/EntityDocs';
import {useTranslation} from 'react-i18next';
import {useCheckColumns} from '../hooks/useCheckColumns';

interface IDesktopProps {
	objectId: string;
	checkId: string;
	check?: ILinkedCheck;
}

const Desktop = ({objectId, checkId, check}: IDesktopProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const {t} = useTranslation();

	useAppHeader(
		{
			title: check ? t('checksPage.header.title', {number: check.number}) : '',
			showBackBtn: true
		},
		[check]
	);

	const columns = useCheckColumns({isDesktop: true});

	return check !== undefined ? (
		<div className="page-cont check-page">
			<PageBreadcrumbs
				objectId={objectId}
				check={check}
			/>

			<div className="check-page__d-columns">
				<div className="check-page__d-column check-page__d-column_info">
					<Info
						objectId={objectId}
						check={check}
					/>
					<DesktopEntityDocs id={check.id} />
				</div>
				<div className="check-page__d-column check-page__d-column_entities">
					{check && (
						<DesktopActions
							objectId={objectId}
							check={check}
						/>
					)}

					<Plate withoutPadding>
						<Table
							columns={columns}
							objectId={objectId}
							checkId={checkId}
							selectedRows={selectedRows}
							onSelectedRowsChange={setSelectedRows}
						/>
					</Plate>
				</div>
			</div>
		</div>
	) : null;
};

export default Desktop;
