import {useSpaceTypeDecorationsAsMap} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {memo} from 'react';

interface ITypeDecorationProps {
	name?: string;
}

export const TypeDecor = memo(({name}: ITypeDecorationProps) => {
	const {data: typeDecorations} = useSpaceTypeDecorationsAsMap();
	const createLabel = (
		id: string | undefined,
		objectMap: Record<string, ISpaceTypeDecoration> | undefined
	) => {
		if (!id || !objectMap) return undefined;
		return objectMap[id]?.name;
	};
	const label = createLabel(name, typeDecorations);

	return (
		<div className="space-page__area">
			{label ? <div className="space-page__area-title">{label}</div> : null}
		</div>
	);
});
