import {IGetObjectStagesResponse} from '@src/api/backend/objectStages';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {
	extractObjectsStagesMap,
	extractObjectStagesAsArray
} from '@src/core/hooks/queries/objectStages/selectors';

export const useObjectStages = <T = IGetObjectStagesResponse>(
	select?: (data: IGetObjectStagesResponse) => T
) =>
	useQuery<IGetObjectStagesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.objectStages(),
		meta: {
			error: 'ошибка в получении стадии объектов'
		},
		select
	});

export const useObjectStagesAsArray = () => useObjectStages(extractObjectStagesAsArray);

export const useObjectStagesMap = () => useObjectStages(extractObjectsStagesMap);
