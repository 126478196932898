import { useCallback } from 'react';
import * as React from 'react';
import './SidebarMenuItem.less';
import ButtonBase from '../../buttons/ButtonBase';
import classNames from 'classnames';

export interface ISidebarMenuItemProps<D = unknown> {
	className?: string;
	style?: React.CSSProperties;
	icon?: React.ReactNode;
	label?: React.ReactNode;
	link?: string;
	type?: 'mobile' | 'tablet' | 'desktop';
	active?: boolean;
	data?: D;
	dataTestId?: string;

	onClick?(data?: D): void;
}

const SidebarMenuItem = <D extends unknown>(props: ISidebarMenuItemProps<D>) => {
	const {className, style, icon, label, link, type, active, data, dataTestId, onClick} = props;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(data);
		}
	}, [data, onClick]);

	return (
		<ButtonBase
			className={classNames(`sidebar-menu-item_${type}`, {'sidebar-menu-item_active': active}, className)}
			style={style}
			leftIcon={icon}
			label={label}
			classNamePrefix="sidebar-menu-item"
			outerTagType="a"
			outerTagProps={{href: link}}
			onClick={handleClick}
			dataTestId={dataTestId}
		/>
	);
};

export default SidebarMenuItem;
