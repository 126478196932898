import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetCheckListsResponse = INormalizedData<ICheckList>;

/**
 * Получает список чек-листов
 */
export const requestCheckLists = async () => {
	const response = await httpRequests.withToken.get<IGetCheckListsResponse>('check-lists');
	return response.data;
};
