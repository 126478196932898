import {useEffect} from 'react';
import {NetworkStatusTracker} from '../trackers/NetworkStatusTracker';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

/**
 * Отслеживание статуса соединения с websocket api
 */
export function useNetworkStatusTracker() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const networkTracker = new NetworkStatusTracker(dispatch);
		networkTracker.start();

		return () => {
			networkTracker.stop();
		};
	}, []);
}
