import {useMemo} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useResponsibilityRulesAsArray} from '@src/core/hooks/queries/responsibilityRules/hooks';

interface IUseDelegationRulesProps {
	objectId: string;
	stage?: ObjectStageIds;
	groupIds?: string[];
	scope?: string;
}

export const useDelegationRules = (props: IUseDelegationRulesProps) => {
	const {scope, groupIds, objectId, stage} = props;
	const {data: rulesArray} = useResponsibilityRulesAsArray();

	return useMemo(() => {
		if (!rulesArray?.length) {
			return [];
		}

		return rulesArray?.filter(
			rule =>
				(!rule.objects?.length || rule.objects.includes(objectId)) &&
				(!rule.stage || rule.stage === stage) &&
				(!rule.scope || rule.scope === scope) &&
				(!groupIds || groupIds.includes(rule.groupId))
		);
	}, [groupIds, objectId, stage, scope, rulesArray]);
};
