import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import { useCallback, useState } from 'react';
import * as React from 'react';
import {EditableCheckList} from '@src/components/EditableCheckList/EditableCheckList';
import {useEditableCheckListState} from '@src/core/hooks/states/useEditableCheckListState/hook';
import {
	convertCheckListToSave,
	errorsFns,
	isEdited
} from '@src/core/hooks/states/useEditableCheckListState/state';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {EditableCheckItems} from '@src/components/EditableCheckList/components';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {Button} from '@tehzor/ui-components';
import {useEditCheckList} from '@src/core/hooks/mutations/checkLists/useEditCheckList';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	companyId: {fieldId: 'companyId', isRequired: true},
	spaceTypes: {fieldId: 'spaceTypes', isRequired: false},
	structureType: {fieldId: 'structureType', isRequired: false},
	type: {fieldId: 'type', isRequired: true},
	frontType: {fieldId: 'frontType', isRequired: false},
	stage: {fieldId: 'stage', isRequired: true},
	objects: {fieldId: 'objects', isRequired: false},
	typeDecoration: {fieldId: 'typeDecoration', isRequired: false},
	categories: {fieldId: 'categories', isRequired: false}
};

interface IHookArgs {
	checkList?: ICheckList;
	saving?: boolean;
}

export const useEditableCheckList = ({
	checkList,
	saving
}: IHookArgs): [
	React.ReactNode,
	React.ReactNode,
	() => {checkList?: ISavingCheckList; checkItems?: ISavingCheckItem[]} | undefined,
	() => void,
	boolean
] => {
	const [editingState, editingDispatch] = useEditableCheckListState({checkList});
	const [isBlocking, setIsBlocking] = useState(false);
	const perms = useCheckListPermissions();
	const editCheckList = useEditCheckList();

	const getSavingData = useCallback(() => {
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}

		if (!isEdited(editingState, checkList)) {
			return undefined;
		}

		const savingCheckList = convertCheckListToSave(editingState, checkList, true);

		return {
			checkList: savingCheckList
		};
	}, [editingState, checkList, editingDispatch]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: {checkList}
		});
	}, [editingDispatch, checkList]);

	const handleSave = useCallback(() => {
		setIsBlocking(true);

		const savingData = getSavingData();

		try {
			if (checkList && savingData && savingData.checkList) {
				editCheckList({id: checkList.id, checkListData: savingData.checkList});
			}
		} finally {
			setIsBlocking(false);
		}
	}, [checkList, editCheckList, getSavingData]);

	const checkListFields = (
		<>
			<EditableCheckList
				editingState={editingState}
				editingDispatch={editingDispatch}
				fieldsSettings={fieldsSettings}
				saving={saving}
			/>
			{perms.canEdit && checkList && (
				<div className="editable-check-list__buttons">
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={
							isBlocking ||
							(checkList
								? !isEdited(editingState, checkList) ||
								  hasErrors(editingState, errorsFns, fieldsSettings)
								: hasErrors(editingState, errorsFns, fieldsSettings))
						}
						onClick={handleSave}
					/>

					<Button
						type="cancel"
						label="Отменить "
						onClick={reset}
						disabled={!isEdited(editingState, checkList)}
					/>
				</div>
			)}
		</>
	);

	const checkItemsFields = (
		<EditableCheckItems/>
	);

	return [checkListFields, checkItemsFields, getSavingData, reset, isBlocking];
};
