import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ISavingCheck} from '@src/interfaces/saving/ISavingCheck';

export type IEditableCheckState = IEditableEntityState<{
	description?: string | null;
	attachments: IAttachment[];
}>;

export type IEditableCheckAction = IEditableEntityAction<IEditableCheckState, ICheck>;

const makeEmptyState = (): IEditableCheckState => ({
	description: undefined,
	attachments: [],
	errors: {
		description: false,
		attachments: false
	}
});

export const init = (check?: ICheck): IEditableCheckState => {
	const empty = makeEmptyState();
	return check
		? {
				description: check.description || undefined,
				attachments: check.attachments || empty.attachments,
				errors: empty.errors
		  }
		: empty;
};

const isDescriptionEdited = (state: IEditableCheckState, original?: ICheck) =>
	(original?.description ? original.description !== state.description : !!state.description);

const areAttachmentsEdited = (state: IEditableCheckState, original?: ICheck) =>
	(original?.attachments ? original.attachments.length !== state.attachments.length : false);

/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableCheckState, original?: ICheck): boolean =>
	isEntityEdited(state, original, isDescriptionEdited, areAttachmentsEdited);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	description: (state: IEditableCheckState) => !state.description,
	attachments: (state: IEditableCheckState) => !state.attachments.length
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableCheckState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableCheckState,
	original?: ICheck,
	onlyEdited?: boolean
): ISavingCheck => {
	if (!onlyEdited) {
		return {
			description: state.description,
			attachments: state.attachments
		};
	}
	const check: ISavingCheck = {};
	if (isDescriptionEdited(state, original)) {
		check.description = state.description;
	}
	if (areAttachmentsEdited(state, original)) {
		check.attachments = state.attachments.map(item => ({id: item.id}));
	}
	return check;
};
