import {memo, useMemo} from 'react';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useEditableResultWorkAcceptanceDialog} from '@src/components/EditableResultWorkAcceptanceDialog/hooks/useEditableResultWorkAcceptanceDialog';
import {makeUnitStr} from '@src/core/hooks/queries/units/hook';
import {DesktopRoutes} from './routes/Routes.desktop';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {DesktopControls} from './controls/Controls.desktop';
import {useTranslation} from 'react-i18next';
import {DesktopInfo} from '@src/pages/WorkAcceptancePage/components/info/Info.desktop';
import {useTranslatedUnitsArray} from '@src/core/hooks/translations/useTranslatedUnitsArray';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';

interface IDesktopProps {
	objectId: string;
	checkLists: ICheckList[];
	workAcceptance: IWorkAcceptance;
	permissions?: IPermissions;
	settings?: IEntitySettings;
}

export const Desktop = memo(
	({objectId, workAcceptance, checkLists, permissions, settings}: IDesktopProps) => {
		const {t} = useTranslation();
		// Диалог редактирования приёмки работ
		const [resultWorkAcceptanceDialog, openEditResultDialog] =
			useEditableResultWorkAcceptanceDialog(
				workAcceptance.objectId,
				workAcceptance.stage,
				workAcceptance
			);

		const units = useTranslatedUnitsArray();
		const unitValue = workAcceptance?.physicalWorkScope?.value;
		const unitId = workAcceptance?.physicalWorkScope?.unitId;

		const unitPlanValue = workAcceptance?.planPhysicalWorkScope?.value;
		const unitPlanId = workAcceptance?.planPhysicalWorkScope?.unitId;

		const finalUnitStr = useMemo(
			() => makeUnitStr(units, unitValue, unitId),
			[units, unitValue, unitId]
		);

		const finalUnitPlanStr = useMemo(
			() => makeUnitStr(units, unitPlanValue, unitPlanId),
			[units, unitPlanValue, unitPlanId]
		);

		const sectionsMenu = [
			{
				id: 'Description',
				url: `/objects/${objectId}/work-acceptances/${workAcceptance.id}`,
				label: t('workAcceptancePage.pageTabs.description.label')
			},
			{
				id: 'ProblemsAndChecks',
				url: `/objects/${objectId}/work-acceptances/${workAcceptance.id}/entities`,
				label: t('workAcceptancePage.pageTabs.problemsAndChecks.label')
			},
			{
				id: 'Checklists',
				url: `/objects/${objectId}/work-acceptances/${workAcceptance.id}/check-lists`,
				label: t('workAcceptancePage.pageTabs.checklists.label')
			}
		];

		useAppHeader(
			{
				title: `${t('workAcceptancePage.title')} №${workAcceptance.number}`,
				showBackBtn: true,
				sectionsMenu
			},
			[workAcceptance, t]
		);

		return workAcceptance ? (
			<div className="page-cont work-acceptance-page">
				<div className="work-acceptance-page__d-controls">
					<div className="work-acceptance-page__d-controls-left">
						<PageBreadcrumbs
							objectId={objectId}
							workAcceptance={workAcceptance}
						/>
					</div>
					<div className="work-acceptance-page__d-controls-right">
						<DesktopControls
							workAcceptance={workAcceptance}
							checkLists={checkLists}
							objectId={objectId}
							permissions={permissions}
						/>
					</div>
				</div>

				<div className="work-acceptance-page__d-columns">
					<DesktopInfo
						openDialog={openEditResultDialog}
						workAcceptance={workAcceptance}
						unitString={finalUnitStr}
						unitPlanString={finalUnitPlanStr}
						permissions={permissions}
					/>
					<div className="work-acceptance-page__d-column work-acceptance-page__d-column_entities">
						<DesktopRoutes
							workAcceptance={workAcceptance}
							objectId={objectId}
							checkLists={checkLists}
							settings={settings}
						/>
					</div>
				</div>
				{permissions?.canEditResult && resultWorkAcceptanceDialog}
			</div>
		) : null;
	}
);
