import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IObjectSpacesTypesCount} from '@tehzor/tools/interfaces/spacesTypesCount/ISpacesTypesCount';

export type IGetSpacesTypesCountResponse = INormalizedData<IObjectSpacesTypesCount>;
/**
 * Возвращает счетчики типов помещений для списка объектов
 */
export const requestSpacesTypesCount = async (objectIds: string[]) => {
	const params = {
		objectIds
	};
	const response = await httpRequests.withToken.post<IGetSpacesTypesCountResponse>(
		'spaces/types-count/get',
		params
	);
	return response.data;
};
