import {CellContext} from '@tanstack/react-table';
import classNames from 'classnames';
import {IScheduleData} from '@src/pages/TasksPage/utils/convertTasksToScheduleFormat';
import {useTranslation} from 'react-i18next';

export const ObjectCell = ({getValue, row}: CellContext<IScheduleData, IScheduleData>) => {
	const {t} = useTranslation();
	const objectsCount = Number(getValue().subRows?.length ?? 0);
	const objectsCountStr = objectsCount
		? t('taskSchedulePage.objectCell.count', {count: objectsCount})
		: '';

	return (
		// eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
		<div
			className={classNames('manage-task-schedule-page__object-cell', {
				'manage-task-schedule-page__object-cell-pointer': row.getCanExpand()
			})}
			onClick={row.getToggleExpandedHandler()}
			style={{
				marginLeft: row.depth ? `${56 * row.depth}px` : '24px'
			}}
			role="button"
		>
			<div
				className="manage-task-schedule-page__object-cell-expander"
				style={{
					transform: row.getIsExpanded() ? 'rotate(0deg)' : 'rotate(-90deg)'
				}}
			>
				{row.getCanExpand() && <i className="tz-simple-arrow-24" />}
			</div>
			<div className="manage-task-schedule-page__object-cell-title">
				<span
					className={classNames('manage-task-schedule-page__object-cell-title-name', {
						'manage-task-schedule-page__object-cell-title-name-bold': !row.depth
					})}
				>
					{getValue().name}
				</span>
				<span className="manage-task-schedule-page__object-cell-objects">
					{objectsCountStr}
				</span>
			</div>
		</div>
	);
};
