import {useMemo} from 'react';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';

export const useManageCheckListsBreadcrumbs = (): IBreadcrumb[] =>
	useMemo(
		() => [
			{
				label: 'Чек-листы',
				url: '/manage/check-lists'
			}
		],
		[]
	);
