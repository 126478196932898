import { Dispatch, useCallback } from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {Select2, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {ISpaceType, SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {
	useExtractSpaceTypesAsArray,
	useExtractSpaceTypesById
} from '@src/core/hooks/queries/spaceTypes/hooks';

interface ISpaceTypeSelectProps<S, E> {
	field?: keyof S;
	value?: SpaceTypeId | SpaceTypeId[];
	index: number;
	arrayItem?: unknown;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: SpaceTypeId | SpaceTypeId[] | undefined,
	types: ISpaceType[],
	typesMap: Record<string, ISpaceType>
) => {
	if (!value) {
		return undefined;
	}

	if (Array.isArray(value)) {
		return value.length ? makeFilterLabel('Выбрано', value, types) : undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

/**
 * Селект типов помещений для поля которое находится внутри массива в редактируемом объекте {data: [ { spaceTypes:[] }, {}, {}]}
 */
const SpaceTypeSelect2 = <S extends {data: Record<string, unknown>}, E>({
	className,
	style,
	// field,
	// label = 'Тип помещения',
	value,
	index,
	arrayItem,
	editingDispatch,
	required,
	disabled,
	hasError
}: ISpaceTypeSelectProps<S, E>) => {
	const {data: types} = useExtractSpaceTypesAsArray();
	const {data: typesMap} = useExtractSpaceTypesById();
	const originalValue = arrayItem as [];

	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({
				type: 'update-array-item',
				field: 'data',
				index,
				value: {...originalValue, spaceTypes: v}
			});
			if (required) {
				editingDispatch({type: 'update-error', field: 'data'});
			}
		},
		[required, arrayItem]
	);

	if (!types || !typesMap) return null;

	return (
		<div
			className={className}
			style={style}
		>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, types, typesMap) || 'Выберите тип помещения'}
						icon={<i className="tz-simple-arrow-20" />}
						error={required && hasError ? 'Выберите тип помещения' : undefined}
						disabled={disabled}
					/>
				}
			>
				{Array.isArray(value) ? (
					<Select2
						multiple
						value={value}
						onChange={handleChange}
					>
						{types.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="checkbox"
							/>
						))}
					</Select2>
				) : (
					<Select2
						value={value}
						onChange={handleChange}
					>
						{types.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="radio"
							/>
						))}
					</Select2>
				)}
			</SelectPopup>
		</div>
	);
};

export default SpaceTypeSelect2;
