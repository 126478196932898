import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IBankAccount, ISavingBankAccount} from '@tehzor/tools/interfaces/bankAccounts';

export type IEditBankAccountResponse = IBankAccount;

export const makeBankAccountEditRequest = async (
	bankAccountId: string,
	fields: ISavingBankAccount
) => {
	const response = await httpRequests.withToken.post<IEditBankAccountResponse>(
		`bank-accounts/${bankAccountId}`,
		{
			bankAccountId,
			...fields
		}
	);
	return response.data;
};
