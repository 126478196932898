import { Dispatch, useCallback } from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {
	IWorkingGroupType,
	WorkingGroupTypeId
} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {
	useWorkingGroupsTypesAsArray,
	useWorkingGroupsTypesById
} from '@src/core/hooks/queries/workingGroupTypes/hooks';

interface IWorkingGroupTypeSelectProps<S, E> {
	field?: keyof S;
	value?: WorkingGroupTypeId;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: WorkingGroupTypeId | undefined,
	typesMap: Record<string, IWorkingGroupType>
) => {
	if (!value) {
		return undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

export const WorkingGroupTypeSelect = <
	S extends {
		type?: WorkingGroupTypeId;
		spaceTypes?: SpaceTypeId[];
	},
	E
>({
	className,
	style,
	field = 'type',
	label = 'Тип рабочей группы',
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: IWorkingGroupTypeSelectProps<S, E>) => {
	const {data: types} = useWorkingGroupsTypesAsArray();
	const {data: typesMap} = useWorkingGroupsTypesById();
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required, editingDispatch, field]
	);

	if (!types || !typesMap) return null;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, typesMap)}
						icon={<i className="tz-simple-arrow-20" />}
						error={required && hasError ? 'Выберите тип рабочей группы' : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{types.map(type => (
						<SelectOption
							key={type.id}
							itemKey={type.id}
							content={type.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
