import {ComponentType, useMemo} from 'react';
import * as React from 'react';
import './SpacesBoard.less';
import classNames from 'classnames';
import {groupSpacesByFloor} from './utils/groupSpacesByFloor';
import {ISpaceComponent} from '../interfaces/ISpaceComponent';
import InlineButton from '../../buttons/LinkButton';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';

export interface ISpacesBoardProps {
	className?: string;
	style?: React.CSSProperties;
	data: IConvertedSpace[];
	title?: string;
	reverseFloorSort?: boolean;
	altNamesVisible?: boolean;
	SpaceComponent: ComponentType<ISpaceComponent>;
	checkListPopupContentSpaceNameTitle?: string;
	checkListPopupContentTranslationArr?: string[];
	onTitleClick?: () => void;
	onSpaceClick?: (space: IConvertedSpace) => void;
}

export const SpacesBoard = (props: ISpacesBoardProps) => {
	const {
		className,
		style,
		data,
		title,
		onTitleClick,
		reverseFloorSort,
		altNamesVisible,
		SpaceComponent,
		checkListPopupContentSpaceNameTitle,
		checkListPopupContentTranslationArr,
		onSpaceClick
	} = props;
	const [floors, groups] = useMemo(
		() => groupSpacesByFloor(data, reverseFloorSort),
		[data, reverseFloorSort]
	);

	return (
		<div className={classNames(className, 'spaces-board')} style={style}>
			<div className="spaces-board__building">
				{title !== undefined && (
					<div className="spaces-board__header">
						<InlineButton
							onClick={onTitleClick}
							label={title}
							labelClassName="spaces-board__title"
							disabled={!onTitleClick}
						/>
					</div>
				)}

				{floors.map(floor => (
					<div key={floor} className="spaces-board__floor">
						<div className="spaces-board__floor-num-wrap">
							<div className="spaces-board__floor-num">{floor}</div>
						</div>

						<div className="spaces-board__spaces">
							{groups[floor].map(space => (
								<SpaceComponent
									key={space.id}
									space={space}
									disabled={!space.filtered}
									altNamesVisible={altNamesVisible}
									checkListPopupContentSpaceNameTitle={
										checkListPopupContentSpaceNameTitle
									}
									checkListPopupContentTranslationArr={
										checkListPopupContentTranslationArr
									}
									onClick={onSpaceClick}
								/>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
