import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ITaskStatus} from '@tehzor/tools/interfaces/tasks/ITaskStatus';

export type IGetTaskStatusesResponse = INormalizedData<ITaskStatus>;

/**
 * Возвращает список статусов задач
 */
export const requestTaskStatuses = async () => {
	const response = await httpRequests.withToken.get<IGetTaskStatusesResponse>('task-statuses');
	return response.data;
};
