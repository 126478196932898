import {useCallback} from 'react';
import './AppBottomMenu.less';
import {
	ErrorBoundary,
	MobileBottomMenu,
	MobileBottomMenuItem,
	NotificationsIcon
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {toggleNotificationsVisible} from '@src/actions/settings/notifications';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useNavPermissions} from '@src/core/hooks/useNavPermissions';
import {usePageKey} from './utils/usePageKey';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useTranslation} from 'react-i18next';

const AppBottomMenu = () => {
	const {replacePath} = useChangePath();
	const {t} = useTranslation();
	const user = useAppSelector(s => s.auth.profile);
	const notificationsCount = useAppSelector(s => s.notifications.messages.total);
	const dispatch = useAppDispatch();
	const permissions = useNavPermissions();
	const pageKey = usePageKey();

	const navigate = useCallback((path?: string) => {
		if (path) {
			replacePath(`/${path}`);
		}
	}, []);

	const toggleNotifications = useCallback(() => {
		dispatch(toggleNotificationsVisible());
	}, []);

	return pageKey ? (
		<ErrorBoundary
			label="AppBottomMenu"
			className="app-bottom-menu error"
		>
			<MobileBottomMenu className="app-bottom-menu">
				<MobileBottomMenuItem
					icon={<i className="tz-object-28" />}
					label={t('appBottomMenu.objects.label')}
					active={pageKey === 'objects'}
					data="objects"
					onClick={navigate}
				/>
				{permissions.problemsView && (
					<MobileBottomMenuItem
						icon={<i className="tz-problem-28" />}
						label={t('appBottomMenu.problems.label')}
						active={pageKey === 'problems'}
						data="problems"
						onClick={navigate}
					/>
				)}
				{permissions.inspectionsView && (
					<MobileBottomMenuItem
						icon={<i className="tz-inspection-28" />}
						label={t('appBottomMenu.inspections.label')}
						active={pageKey === 'inspections'}
						data="inspections"
						onClick={navigate}
					/>
				)}
				{user.activated && (
					<MobileBottomMenuItem
						icon={<NotificationsIcon count={notificationsCount} />}
						label={t('appBottomMenu.events.label')}
						onClick={toggleNotifications}
					/>
				)}
			</MobileBottomMenu>
		</ErrorBoundary>
	) : null;
};

export default AppBottomMenu;
