import {CheckListsDiagram} from '@tehzor/ui-components';
import {useDiagramData} from '@src/core/hooks/useDiagramData';

interface IDiagramProps {
	listId: string;
}

export const DiagramIcon = ({listId}: IDiagramProps) => {
	const data = useDiagramData(listId);

	if (!data) return null;

	return (
		<svg
			width={50}
			height={50}
			className="work-acceptance-page__check-list__m-list-bullet"
		>
			<CheckListsDiagram
				checkLists={data}
				width={54}
				height={54}
				radius={20}
				spaceId={listId}
			/>
		</svg>
	);
};
