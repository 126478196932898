import {EntityDesktopTasks} from '@src/components/EntityTasks';
import {EntityMobileTasks} from '@src/components/EntityTasks/EntityTasks.mobile';
import {memo} from 'react';
import {usePermissions} from '../../hooks/usePermissions';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {useAddWorkAcceptanceTaskDialog} from '../../hooks/useAddWorkAcceptaceTaskDialog';
import {useWorkAcceptanceTasksArray} from '@src/core/hooks/queries/tasks/work-acceptance/hooks';

interface IWorkAcceptanceTaskProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
	isDesktop?: boolean;
}

export const WorkAcceptanceTask = memo((props: IWorkAcceptanceTaskProps) => {
	const {objectId, workAcceptance, isDesktop = false} = props;
	const problemPermissions = usePermissions(objectId, workAcceptance);
	const [addingTaskDialog, openAddingTaskDialog] = useAddWorkAcceptanceTaskDialog(workAcceptance);
	const {data: tasks} = useWorkAcceptanceTasksArray(workAcceptance.id);

	return (
		<>
			{isDesktop && (
				<EntityDesktopTasks
					tasks={tasks}
					openAddingTaskDialog={openAddingTaskDialog}
					className={{
						title: 'work-acceptance-page__tasks-title',
						icon: 'work-acceptance-page__add-task-icon'
					}}
				/>
			)}
			{!isDesktop && (
				<EntityMobileTasks
					tasks={tasks}
					openAddingTaskDialog={openAddingTaskDialog}
					className={{icon: 'work-acceptance-page__add-task-icon'}}
				/>
			)}

			{problemPermissions.canViewWorkAcceptances && addingTaskDialog}
		</>
	);
});
