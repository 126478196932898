import { cloneElement, createElement, ReactNode } from 'react';
import * as React from 'react';

export function makeElement<P extends {}>(
	component: React.ReactElement<P> | ((props: P) => JSX.Element),
	props: P,
	...children: ReactNode[]
) {
	if (typeof component === 'function') {
		return createElement(component, props, children);
	}
	return cloneElement(component, props, children);
}
