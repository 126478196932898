import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

export type CheckEntitiesVisibility = 'problems' | 'inspections';

export const getInitialState = (): CheckEntitiesVisibility[] => ['problems', 'inspections'];

export default createReducer<CheckEntitiesVisibility[]>(getInitialState(), {
	[types.CHANGE_ENTITIES_VISIBILITY]: (state, {payload}: {payload: CheckEntitiesVisibility[]}) =>
		payload
});
