import {TFunction} from 'i18next';

export const getMenuSections = (objectId: string, structureId: string, tFunc: TFunction) => [
	{
		id: 'Problems',
		url: `/objects/${objectId}/structures/${structureId}`,
		label: tFunc('structurePage.menuLinks.problems.label')
	},
	{
		id: 'Checklists',
		url: `/objects/${objectId}/structures/${structureId}/check-lists`,
		label: tFunc('structurePage.menuLinks.checklists.label')
	},
	{
		id: 'WorkAcceptances',
		url: `/objects/${objectId}/structures/${structureId}/work-acceptances`,
		label: tFunc('structurePage.menuLinks.workAcceptances.label')
	}
];
