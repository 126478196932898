import {useCallback} from 'react';
import {EntityInnerTable} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import {extractWorkAcceptanceEntities} from '@src/store/modules/entities/workAcceptance/selectors/workAcceptance';
import {SelectionRow} from './SelectionRow';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {MobileControls} from '../controls/Controls.mobile';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useTranslation} from 'react-i18next';

interface ITableProps {
	objectId: string;
	columns: Array<Column<IWorkAcceptanceEntity>>;
	hideHead?: boolean;
	selectedRows?: string[];
	workAcceptance: IWorkAcceptance;
	checkLists: ICheckList[];
	onSelectedRowsChange?: (value: string[]) => void;
}

export const Table = ({
	objectId,
	columns,
	hideHead,
	selectedRows,
	workAcceptance,
	checkLists,
	onSelectedRowsChange
}: ITableProps) => {
	const {pushPath} = useChangePath();
	const {t} = useTranslation();
	const entities = useAppSelector(extractWorkAcceptanceEntities);
	const permissions = useWorkAcceptancesPermissions(objectId, workAcceptance);

	useAppHeader(
		{
			title: workAcceptance
				? `${t('workAcceptancePage.title')} №${workAcceptance.number}`
				: t('workAcceptancePage.title'),
			mobileRightButtons: (
				<MobileControls
					objectId={objectId}
					workAcceptance={workAcceptance}
					checkLists={checkLists}
					permissions={permissions}
				/>
			),
			showBackBtn: true
		},
		[workAcceptance, objectId, checkLists, permissions]
	);
	const handleRowClick = useCallback(
		(entity: IWorkAcceptanceEntity) => {
			switch (entity.type) {
				case 'problem':
					return pushPath(formProblemLink(objectId, entity.data.id));
				case 'inspection':
					return pushPath(formInspectionLink(objectId, entity.data.id));
				default:
					return undefined;
			}
		},
		[objectId]
	);

	return (
		<EntityInnerTable
			columns={columns}
			data={entities}
			hideHead={hideHead}
			selectable
			selectedRows={selectedRows}
			onRowClick={handleRowClick}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={props => (
				<SelectionRow
					{...props}
					objectId={objectId}
					workAcceptanceId={workAcceptance.id}
					checkLists={checkLists}
				/>
			)}
		/>
	);
};
