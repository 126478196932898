import {requestListInspections} from '@src/api/backend/inspections';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';

export type IGetStructureInspectionsPayload = INormalizedData<IListInspection>;

const request = () => ({type: types.GET_INSPECTIONS_REQUEST});

const success = (response: IGetStructureInspectionsPayload) => ({
	type: types.GET_INSPECTIONS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке осмотров');
	return {
		type: types.GET_INSPECTIONS_FAILURE,
		payload: error
	};
};

/**
 * Получает список осмотров для конкретной структуры
 *
 * @param objectId id объекта
 * @param structureId id структуры
 */
export const getStructureInspections = (objectId: string, structureId: string) =>
	checkExpiration<IState, IGetStructureInspectionsPayload, ApiAction>(
		s => s.entities.structure.inspections,
		createApiAction<IGetStructureInspectionsPayload>(request, success, failure, () =>
			requestListInspections(
				{
					objects: [objectId],
					structures: [structureId]
				},
				{createdAt: false}
			).then(({byId, allIds}) => ({
				byId,
				allIds
			}))
		)
	);
