import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';

/**
 * Возвращает внутренние приемки
 */
export const extractInternalAcceptancesData = (data: IGetInternalAcceptancesResponse) => data;

/**
 * Возвращает внутренние приемки в виде массива
 */
export const extractInternalAcceptancesAsArray = (data: IGetInternalAcceptancesResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractLocalSpaceInternalAcceptances = (
	data: IListInternalAcceptance[],
	spaceId: string
) => data.filter(check => check.links?.spaceId === spaceId);
