import {IDndRenderPreviewProps, TextField} from '@tehzor/ui-components';
import {ISavingCheckItem} from "@tehzor/tools/interfaces/checkItems/ISavingCheckItem";

const dragIcon = <i className="tz-drag-and-drop-24 editable-check-items__preview-icon"/>

const classes = {
	wrap: 'editable-check-items__preview-text',
};

export const renderPreview = ({
	item, children
}: IDndRenderPreviewProps<ISavingCheckItem>) => {
	let hiddenItemsCount = 0;
	if (item) {
		hiddenItemsCount++;
	}
	if (children?.length) {
		hiddenItemsCount += children.length;
	}

	return item ? (
		<div className="editable-check-items__preview">
			{dragIcon}
			<div className="editable-check-items__preview-count">
				{hiddenItemsCount || null}
			</div>
			<TextField
				className={classes}
				value={item?.name}
				elementType="div"
			/>
		</div>
	) : null;
};