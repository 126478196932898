import {wsConnector} from '../wsConnector';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';

export type IEditOwnerAcceptanceStatusResponse = IOwnerAcceptance;

/**
 * Изменяет статус передачи собственнику
 */
export const makeOwnerAcceptanceStatusEditRequest = (
	objectId: string,
	acceptanceId: string,
	statusId: OwnerAcceptanceStatusId
) =>
	wsConnector.sendAuthorizedRequest<IEditOwnerAcceptanceStatusResponse>(
		'editOwnerAcceptanceStatus',
		{objectId, acceptanceId, statusId}
	);
