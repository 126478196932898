import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {IEditInspectionResponse, makeInspectionEditRequest} from '@src/api/backend/inspection';
import {addErrorToast} from '@src/utils/toasts';

export type IEditInspectionPayload = IEditInspectionResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditInspectionResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении осмотра');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

export const editInspectionsActions = {request, success, failure};

/**
 * Изменяет осмотр
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 * @param fields измененные поля
 */
export const editInspection = (
	objectId: string,
	inspectionId: string,
	fields: ISavingInspection = {}
) =>
	createApiAction<IEditInspectionResponse>(request, success, failure, () =>
		makeInspectionEditRequest(objectId, inspectionId, fields));
