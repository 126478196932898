import {memo, useMemo, useState} from 'react';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {BaseListFilterMobile} from './BaseListFilterMobile';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useTranslation} from 'react-i18next';

interface ICompaniesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	isUUID?: boolean;
	label?: string;
	filterName?: string;

	onChange: (changes: IChanges) => void;
}

export const CompaniesFilterMobile = memo((props: ICompaniesFilterMobileProps) => {
	const {t} = useTranslation();
	const {isUUID, label, filterName, ...restProps} = props;

	const [search, setSearch] = useState('');
	const {data: companies} = useCompaniesAsArray();

	const filteredData = useMemo(() => {
		let finalCompanies = companies;

		if (isUUID) {
			const companiesWithUUID = companies?.map(el => {
				if (el?.uuid) {
					const newCompany = {...el};
					newCompany.id = el.uuid;
					return newCompany;
				}
				return el;
			});

			finalCompanies = companiesWithUUID;
		}

		return finalCompanies ? flatFilter(finalCompanies, 'name', search) : [];
	}, [companies, isUUID, search]);

	return (
		<BaseListFilterMobile
			{...restProps}
			options={filteredData}
			label={label || t('entitiesFilters.companiesFilter.label')}
			filterName={filterName || 'companies'}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
		/>
	);
});
