import {MobileMenu} from './Menu.mobile';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {useStructuresPermissions} from '@src/core/hooks/permissions/useStructuresPermissions';

interface IMobileActionsProps {
	objectId: string;
	structure: IStructure;
}

export const ActionsMobile = ({objectId, structure}: IMobileActionsProps) => {
	const permissions = useStructuresPermissions(objectId);

	return (
		<MobileMenu
			structure={structure}
			canDeleteStructure={permissions.canDelete}
			canEditStructure={permissions.canEdit}
		/>
	);
};
