import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceStatus} from './ISpaceStatus';
import {Scrollbar} from '../../../../containers';
import {Menu} from '../../../../menu';
import classNames from 'classnames';
import {getSpaceMenuItems} from './getSpaceMenuItems';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IGetSpaceRenderMenu <S extends ISpaceStatus> {
	selectedStatus?: S;
	statuses?: INormalizedData<S>;
	availableStatuses?: string[];
	stagesMap: Record<ObjectStageIds, IObjectStage>;

	onChange?: (value: string) => void;
}

export const getSpaceRenderMenu = <S extends ISpaceStatus>({
	selectedStatus, statuses, availableStatuses, stagesMap, onChange
}: IGetSpaceRenderMenu<S>) => {
	const statusesArray = statuses ? statuses.allIds.map(id => statuses.byId[id]) : undefined;

	const menuItems = getSpaceMenuItems({
		statuses: statusesArray, selectedStatus, availableStatuses, stagesMap
	});

	return menuItems ? (
		<Scrollbar
			className={classNames(
				'space-status-select__scrollbar', 'base-status-select__scrollbar'
			)}
		>
			<Menu
				value={selectedStatus?.id}
				onChange={onChange}
			>
				{menuItems}
			</Menu>
		</Scrollbar>
	) : undefined;
};
