import {useQuery} from '@tanstack/react-query';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import useAppSelector from '../../useAppSelector';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import IError from '@tehzor/tools/interfaces/IError';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {useCallback, useMemo} from 'react';
import {useLatestOwnerAcceptancesAsArray} from './latest/hooks';
import {extractLocalSpaceOwnerAcceptance} from './selectors';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';

export const useLocalOwnerAcceptancesQuery = <T = IListOwnerAcceptance[]>(
	objectId = 'all',
	select?: (data: IListOwnerAcceptance[]) => T
) => {
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	return useQuery<IListOwnerAcceptance[], IError, T>({
		queryKey: [...ownerAcceptancesQueryKeys.localList(), objectId],
		staleTime: 0,
		gcTime: Infinity,
		networkMode: 'offlineFirst',
		enabled: isOfflineModeAvailable,
		select
	});
};

export const useLocalOwnerAcceptances = (objectId = 'all') => {
	const {data} = useLocalOwnerAcceptancesQuery(objectId);
	return data || [];
};

export const useExtractLocalSpaceOwnerAcceptances = (objectId: string, spaceId: string) => {
	const selector = useCallback(
		(data: IListOwnerAcceptance[]) => extractLocalSpaceOwnerAcceptance(data, spaceId),
		[spaceId]
	);
	return useLocalOwnerAcceptancesQuery(objectId, selector);
};

export const useMergedSpaceLocalAndLatestOwnerAcceptances = (objectId: string, spaceId: string) => {
	const offlineMode = useAppSelector(extractOfflineModeAvailability);
	const isOnline = useAppSelector(extractNetworkStatus);
	const appIsOffline = offlineMode && !isOnline;
	const {data: latestOwnerAcceptances} = useLatestOwnerAcceptancesAsArray(objectId, spaceId);
	const {data: localOwnerAcceptances} = useExtractLocalSpaceOwnerAcceptances(objectId, spaceId);

	return useMemo(
		() =>
			appIsOffline
				? [...(latestOwnerAcceptances || []), ...(localOwnerAcceptances || [])]
				: localOwnerAcceptances,
		[latestOwnerAcceptances, localOwnerAcceptances, appIsOffline]
	);
};
