import {forwardRef, Ref, useCallback, useImperativeHandle, useState} from 'react';
import {SearchSelect, useLocalSearchProvider} from '@tehzor/ui-components';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {renderItem} from '../utils/renderItem';
import {useExtractSpaceOwnersForCompany} from '@src/core/hooks/queries/spaceOwners/hooks';
import {useBindSpaceOwners} from '@src/core/hooks/mutations/spaceOwners/useBindSpaceOwners';

interface IOwnersBindingProps {
	companyId: string;
	spaceId: string;
	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IOwnersBindingRefProps {
	save: () => void;
	cancel: () => void;
}

const OwnersBinding = (props: IOwnersBindingProps, ref?: Ref<IOwnersBindingRefProps>) => {
	const {companyId, spaceId, onClose, setSaving} = props;
	const {data: owners} = useExtractSpaceOwnersForCompany(companyId);
	const {mutateAsync: bindSpaceToOwners} = useBindSpaceOwners();
	const [selected, setSelected] = useState<ISpaceOwner[]>();

	const save = useCallback(async () => {
		if (selected) {
			setSaving(true);
			try {
				await bindSpaceToOwners({owners: selected.map(owner => owner.id), spaceId});
				onClose();
			} finally {
				setSaving(false);
			}
		}
	}, [bindSpaceToOwners, onClose, selected, setSaving, spaceId]);

	useImperativeHandle(ref, () => ({save, cancel: onClose}), [onClose, save]);

	const provider = useLocalSearchProvider({
		initialList: owners || [],
		keys: ['name']
	});

	return (
		<SearchSelect
			className={{
				root: 'adding-space-owner-dialog__owners-container',
				select: 'adding-space-owner-dialog__owners-container-select'
			}}
			provider={provider}
			multiple
			renderItem={renderItem}
			onSelect={setSelected}
		/>
	);
};

export default forwardRef<IOwnersBindingRefProps, IOwnersBindingProps>(OwnersBinding);
