import { useMemo } from 'react';
import declination from '@tehzor/tools/utils/declination';
import {useTranslation} from "react-i18next";

interface ICategoriesSetsPageHeaderProps {
	total: number;
}

const CategoriesSetsPageHeader = ({total}: ICategoriesSetsPageHeaderProps) => {
	const {t} = useTranslation();
	const amountOfSets = useMemo(() => (
		`${total} ${declination(total, t('categoriesSetsPage.categoriesSetsPageHeader.declination', {returnObjects: true}))}`
		), [total]);
	return (
		<div className="categories-sets__header">
			<p>{amountOfSets}</p>
		</div>
	);
};

export default CategoriesSetsPageHeader;
