import {wsConnector} from '../wsConnector';
import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export type IEditStructureResponse = IStructure;

/**
 * Изменяет структуру
 *
 * @param objectId id объекта
 * @param structureId id структуры
 * @param fields данные структуры
 */
export const makeStructureEditRequest = (objectId: string, structureId: string, fields: ISavingStructure) =>
	wsConnector.sendAuthorizedRequest<IEditStructureResponse>('editStructure', {
		objectId,
		structureId,
		...fields
	});
