import {BaseSkeleton} from '@tehzor/ui-components';

export const Skeleton = () => (
	<>
		<BaseSkeleton
			width={120}
			height={34}
		/>
		<BaseSkeleton
			width={120}
			height={34}
		/>
		<BaseSkeleton
			width={120}
			height={34}
		/>
	</>
);
