const domain = 'settings/pages/work-acceptances';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;

export const CHANGE_FILTERS = `${domain}/filters/change`;
export const CLEAR_FILTERS = `${domain}/filters/clear`;
export const CHANGE_SORT = `${domain}/sort/change`;
export const CHANGE_PAGE_SIZE = `${domain}/pageSize/change`;
export const CHANGE_DISPLAY_MODE = `${domain}/display-mode/change`;
export const SET_INITIAL_STATE = `${domain}/set-initial-state`;
export const CHANGE_OFFSET = `${domain}/offset/change`;
export const CHANGE_SELECTED_ROWS = `${domain}/selectedRows/change`;
export const TOGGLE_ALL_ROWS_SELECTED = `${domain}/allRowsSelected/toggle`;
