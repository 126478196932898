import {memo, useMemo} from 'react';
import {IconMenu, LoadingPanel, MenuItem, Plate} from '@tehzor/ui-components';
import {useCheckListBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCheckListBreadcrumbs';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {DesktopInfoBlock} from '@src/pages/SpacePage/components/info/DesktopInfoBlock';
import {getCheckListsWithStages} from '../utils/getCheckListsWithStages';
import {StageGroup} from './StageGroup/StageGroup';
import {DesktopDetailColumn} from './DesktopDetailColumn';
import {useHasUnfinished} from '@src/pages/SpacePage/hooks/useHasUnfinished';
import {useSpace} from '@src/core/hooks/queries/space';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';
import {useCheckListHistoryDialog} from '@src/components/CheckListHistoryDialog/hooks/useCheckListHistoryDialog';
import {useSpaceCheckListsHeader} from '../hooks/useSpaceCheckListsHeader';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';
import {CheckListExportSwitcher} from './CheckListExportSwitcher/CheckListExportSwitcher';
import {Outlet} from 'react-router';

interface IDesktopProps {
	loading: boolean;
}

const historyIcon = <i className="tz-history-24" />;

export const Desktop = memo(({loading}: IDesktopProps) => {
	const {t} = useTranslation();
	const {objectId, spaceId, stage, listId} = useStrictParams<{
		objectId: string;
		spaceId: string;
		listId: string;
		stage: ObjectStageIds | 'all';
	}>();
	useSpaceCheckListsHeader(spaceId, objectId, stage, listId);
	const breadcrumbs = useCheckListBreadcrumbs();
	const {data: checkList} = useExtractCheckListById(listId);
	const checkLists = useAvailableCheckLists(objectId, spaceId, stage);
	const checkListsIds = useMemo(() => checkLists?.map(cl => cl.id), [checkLists]);

	const [hasUnfixedProblems, hasUnacceptedCheckItem] = useHasUnfinished(
		checkListsIds,
		objectId,
		spaceId
	);

	const [historyDialog, openHistoryDialog] = useCheckListHistoryDialog(
		objectId,
		checkList?.stage,
		checkList?.id,
		spaceId
	);
	const permissions = useCheckListPermissions();
	const showHistory = !!spaceId && checkList?.stage && permissions.canViewHistory;

	const checkListsWithStages = useMemo(() => getCheckListsWithStages(checkLists), [checkLists]);
	const {data: stagesArray} = useObjectStagesAsArray();
	const stages = stagesArray?.filter(s => checkListsWithStages[s.id]);
	const translatedStages = useTranslatedDictionaryArray(dictionaryKeys.objectStages, stages);

	const {data: space} = useSpace(spaceId, objectId);

	if (!checkLists.length && loading)
		return (
			<LoadingPanel
				className="check-list-page__loading-panel"
				active
				delay={0}
			/>
		);
	const items = [];

	if (showHistory) {
		items.push(
			<MenuItem
				key="history"
				icon={historyIcon}
				onClick={openHistoryDialog}
			>
				{t('checkListPage.menuItem.history.text')}
			</MenuItem>
		);
	}

	return (
		<div className="page-cont check-list-page">
			<div className="check-list-page__d-container">
				<AppBreadcrumbs items={breadcrumbs}>
					<CheckListExportSwitcher
						spaceId={spaceId}
						objectId={objectId}
						permissions={permissions}
						checkList={checkList}
						checkLists={checkLists}
					/>
					{showHistory && <IconMenu type="accent-blue">{items}</IconMenu>}
				</AppBreadcrumbs>
				<div className="check-list-page__d-grid">
					<div className="check-list-page__d-info">
						<div className="check-list-page__d-info__sticky">
							{space && (
								<DesktopInfoBlock
									objectId={objectId}
									space={space}
									hasUnfixedProblems={hasUnfixedProblems}
									hasUnacceptedCheckItem={hasUnacceptedCheckItem}
								/>
							)}
						</div>
					</div>
					<div className="check-list-page-list">
						{!listId && (
							<Plate
								className={{content: 'check-list-page-list__container'}}
								withoutPadding
							>
								{translatedStages?.map((st, i, arr) => {
									const isLastStage = i === arr.length - 1;
									return (
										<StageGroup
											key={st.id}
											stage={st}
											checkLists={checkListsWithStages[st.id]}
											addDivider={!isLastStage}
										/>
									);
								})}
							</Plate>
						)}
						{listId && (
							<Plate withoutPadding>
								<Outlet />
							</Plate>
						)}
					</div>
					<div className="check-list-page__d-detail">
						<div className="check-list-page__d-detail__sticky">
							<DesktopDetailColumn />
						</div>
					</div>
				</div>
			</div>
			{showHistory && historyDialog}
		</div>
	);
});
