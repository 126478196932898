import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useDeleteContract} from '@src/core/hooks/mutations/contracts/useDeleteContract';
import {useAuthorizedPersonsMap} from '@src/core/hooks/queries/authorizedPersons';
import {useBankAccountsMap} from '@src/core/hooks/queries/bankAccounts';
import {useContractsPaginateArray} from '@src/core/hooks/queries/contracts/hooks';
import {useLegalsMap} from '@src/core/hooks/queries/legals';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {
	IContractsSortState,
	contractsActions
} from '@src/store/modules/settings/pages/contracts/reducers';
import {extractContractsPageSettings} from '@src/store/modules/settings/pages/contracts/selectors';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {createContext, memo, useCallback, useMemo} from 'react';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {convertContracts} from '../../utils/convertContracts';
import {Menu} from '../actions/Menu';
import {SelectionClearing} from '../selection/SelectionClearing';
import {SelectionRow} from '../selection/SelectionRow';
import {getDesktopColumns} from './desktop.columns';
import {getMobileColumns} from './mobile.columns';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedContract>) => void
>(() => ({}));

const pageSizes = [10, 20, 50, 100];

interface IContractsTableProps {
	offset: number;
	total: number;
	selectedRows: string[];
	sort: IContractsSortState;
}

export const ContractsTable = memo(({offset, total, selectedRows, sort}: IContractsTableProps) => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();

	const {changeSort, changePageSize, changeOffset, changeSelectedRows} = contractsActions;

	const {pageSize} = useAppSelector(extractContractsPageSettings);

	const pageCount = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);
	const currentPage = useMemo(() => Math.floor(offset / pageSize), [offset, pageSize]);

	const {data: contracts} = useContractsPaginateArray();
	const roles = useAppSelector(extractUserRoles);
	const legalsMap = useLegalsMap();
	const bankAccountsMap = useBankAccountsMap();
	const authorizedPersonsMap = useAuthorizedPersonsMap();
	const preparedContracts = useMemo(
		() =>
			contracts
				? convertContracts(
						contracts,
						legalsMap,
						bankAccountsMap,
						authorizedPersonsMap,
						roles
				  )
				: undefined,
		[contracts, legalsMap, bankAccountsMap, authorizedPersonsMap, roles]
	);

	const selectedEntities = useMemo(
		() => preparedContracts?.filter(item => selectedRows?.includes(item.id)),
		[preparedContracts, selectedRows]
	);

	const deleteContract = useDeleteContract();

	const isLargeTablet = useIsLargeTablet();
	const columns = useMemo(() => {
		if (isLargeTablet) {
			return getDesktopColumns();
		}
		return getMobileColumns();
	}, [isLargeTablet]);

	const handleContextMenuAction = useCallback(
		(action: ITableContextMenuAction<IPreparedContract>) => {
			if (action.type === 'delete' && action.payload) {
				const {id} = action.payload;
				deleteContract({contractId: id});
			}
		},
		[]
	);

	const handleRowClick = useCallback((row: IPreparedContract) => {
		pushPath(`/contracts/${row.id}`);
	}, []);

	const handleSortChange = useCallback((value: IContractsSortState) => {
		dispatch(changeSort(value));
	}, []);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				dispatch(changeOffset(newOffset));
			}
		},
		[offset, pageSize]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			const newOffset = Math.floor(offset / value);

			dispatch(changePageSize(value));
			dispatch(changeOffset(newOffset));
		},
		[offset]
	);

	const handleSelectedRowsChange = useCallback((value: string[]) => {
		dispatch(changeSelectedRows(value));
	}, []);

	const handleSelectedRowsClear = useCallback(() => {
		handleSelectedRowsChange([]);
	}, [handleSelectedRowsChange]);

	useAppHeader(
		{
			title: 'Договоры',
			mobileLeftButton:
				selectedEntities && selectedEntities?.length > 0 ? (
					<SelectionClearing onClear={handleSelectedRowsClear} />
				) : undefined,
			mobileRightButtons: selectedEntities ? (
				<Menu
					contracts={selectedEntities}
					deleteContract={deleteContract}
					handleSelectedRowsClear={handleSelectedRowsClear}
				/>
			) : null
		},
		[selectedEntities]
	);

	return (
		<div className="contracts__container">
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate
					className="contracts__container-table"
					withoutPadding
				>
					{preparedContracts && (
						<EntitiesTable
							sort={sort}
							columns={columns}
							data={preparedContracts}
							selectable={isLargeTablet}
							selectedRows={selectedRows}
							headVisible={isLargeTablet}
							onRowClick={handleRowClick}
							onSortChange={handleSortChange}
							onSelectedRowsChange={handleSelectedRowsChange}
							renderSelectionRow={props => <SelectionRow {...props} />}
						/>
					)}
				</Plate>
			</DispatchActionCtx.Provider>
			<PaginationAndSize
				pagination={
					<Pagination
						className="contracts__container-pagination"
						pageCount={pageCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						className="contracts__container-page-size"
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</div>
	);
});
