import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {TFunction} from 'i18next';

export const createCommentDifference = (
	prev: {
		description: string | undefined;
		attachments: IAttachment[] | undefined;
	},
	next: {
		description: string | undefined;
		attachments: IAttachment[] | undefined;
	},
	t: TFunction<'translation', undefined>,
	official?: boolean
): IHistoryData => ({
	prev,
	next,
	type: HistoryTypeId.COMMENT,
	name: official
		? t('createDifference.createCommentDifference.officialResponse')
		: t('createDifference.createCommentDifference.comment')
});

export const createCommentDeletion = (
	prev: {
		description: string | undefined;
		attachments: IAttachment[] | undefined;
	},
	t: TFunction<'translation', undefined>,
	official?: boolean
): IHistoryData => ({
	prev,
	next: undefined,
	type: HistoryTypeId.COMMENT,
	name: official
		? t('createDifference.createCommentDifference.officialResponse')
		: t('createDifference.createCommentDifference.comment')
});

export const createCommentCreation = (
	next: {
		description: string | undefined;
		attachments: IAttachment[] | undefined;
	},
	t: TFunction<'translation', undefined>,
	official?: boolean
): IHistoryData => ({
	prev: undefined,
	next,
	type: HistoryTypeId.COMMENT,
	name: official
		? t('createDifference.createCommentDifference.officialResponse')
		: t('createDifference.createCommentDifference.comment')
});
