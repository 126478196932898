import {useState} from 'react';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import Info from './info/Info';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import MobileSelectionClearing from './selection/SelectionClearing.mobile';
import MobileRightButtons from './actions/RightButtons.mobile';
import {Plate} from '@tehzor/ui-components';
import Table from './table/Table';
import EntitiesVisibility from './actions/EntitiesVisibility';
import EntityAdding from '@src/pages/CheckPage/components/actions/EntityAdding';
import PageBreadcrumbs from './PageBreadcrumbs';
import {useTranslation} from 'react-i18next';
import {useCheckColumns} from '../hooks/useCheckColumns';

interface IMobileProps {
	objectId: string;
	checkId: string;
	check?: ILinkedCheck;
}

const Mobile = ({objectId, checkId, check}: IMobileProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const {t} = useTranslation();
	const columns = useCheckColumns({isDesktop: false});

	useAppHeader(
		{
			title: check ? t('checksPage.header.title', {number: check.number}) : '',
			showBackBtn: true,
			mobileLeftButton:
				Object.keys(selectedRows).length > 0 ? (
					<MobileSelectionClearing onSelectedRowsChange={setSelectedRows} />
				) : undefined,
			mobileRightButtons: (
				<MobileRightButtons
					objectId={objectId}
					checkId={checkId}
					check={check}
					selectedRows={selectedRows}
				/>
			)
		},
		[check, selectedRows]
	);

	return check !== undefined ? (
		<div className="page-cont check-page">
			<PageBreadcrumbs
				objectId={objectId}
				check={check}
			/>
			<Info
				objectId={objectId}
				check={check}
			/>

			<EntitiesVisibility className="check-page__m-visibility-filter" />

			<Plate withoutPadding>
				<Table
					objectId={objectId}
					columns={columns}
					hideHead
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
					checkId={checkId}
				/>
			</Plate>

			<EntityAdding
				objectId={objectId}
				check={check}
			/>
		</div>
	) : null;
};

export default Mobile;
