import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeCommentDeleteRequest} from '@src/api/backend/comment';

export interface IDeleteWorkAcceptanceCommentPayload {
	objectId: string;
	workAcceptanceId: string;
	commentId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, workAcceptanceId: string, commentId: string) => {
	addInfoToast('Удалено', 'Комментарий успешно удалён');
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			workAcceptanceId,
			commentId
		} as IDeleteWorkAcceptanceCommentPayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении комментария');
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

/**
 * Удаляет комментарий приемки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id нарушения
 * @param commentId id комментария
 */
export const deleteWorkAcceptanceComment = (objectId: string, workAcceptanceId: string, commentId: string) =>
	createApiAction(
		request,
		() => success(objectId, workAcceptanceId, commentId),
		failure,
		() => makeCommentDeleteRequest(objectId, commentId)
	);
