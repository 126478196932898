import {useQuery} from '@tanstack/react-query';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback} from 'react';
import {extractLocalWarrantyClaimsJournal} from './selector';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';

export const useLocalWarrantyClaimsJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: warrantyClaimsQueryKeys.add()});
	const selector = useCallback(
		(warrantyClaims: IListWarrantyClaim[]) =>
			extractLocalWarrantyClaimsJournal(warrantyClaims, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IListWarrantyClaim[], unknown, IOfflineJournalEntity[]>({
		queryKey: warrantyClaimsQueryKeys.localList(),
		select: selector
	});
	return data || [];
};
