import {IGetSpaceResponse} from '@src/api/backend/space';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {getCachedSpace} from '@src/store/persistentStorage/offlineActions/spaces';
import {useIsFetching, useQuery} from '@tanstack/react-query';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';

export const useSpace = <T = IGetSpaceResponse>(
	spaceId?: string,
	objectId?: string,
	select?: (data: IGetSpaceResponse) => T
) => {
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	return useQuery({
		queryKey: [...spacesQueryKeys.detail(spaceId), objectId],
		meta: {
			error: 'при загрузке помещения',
			persist: isOfflineModeAvailable
		},
		select,
		initialData: getCachedSpace(spaceId),
		enabled: !!spaceId && !!objectId
	});
};

export const useSpaceIsLoading = () => !!useIsFetching({queryKey: spacesQueryKeys.details()});
