import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '@src/constants/local-entities/problems';
import * as entitiesTypes from '@src/constants/local-entities/entities';
import {ILocalProblem} from '@src/interfaces/ILocalProblem';
import {IOfflineDataTransferStatuses} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {IOfflineDataCheckAdded} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataCheckAdded';
import {IOfflineDataInternalAcceptanceAdded} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataInternalAcceptanceAdded';

type ILocalProblemsState = ILocalProblem[];

const problems = createReducer<ILocalProblemsState>([], {
	[types.ADD_LOCAL_PROBLEM]: (state, payload: {problem: ILocalProblem}) => [
		...state,
		payload.problem
	],
	[types.DELETE_LOCAL_PROBLEM]: (state, payload: {key: string}) =>
		state.filter(item => item.key !== payload.key),
	[entitiesTypes.CHANGE_LOCAL_ENTITY_STATUS]: (state, payload: IOfflineDataTransferStatuses) =>
		state.map(problem => {
			if (payload.problems[problem.key]) {
				problem.transferStatus = payload.problems[problem.key];
			}
			return problem;
		}),
	[entitiesTypes.CHANGE_LOCAL_CHECK_LINK]: (state, payload: IOfflineDataCheckAdded) =>
		state.map(problem => {
			if (problem.localLinks?.checkId === payload.key) {
				problem.links = {...problem.links, checkId: payload.id};
			}
			return problem;
		}),
	[entitiesTypes.CHANGE_LOCAL_INTERNAL_ACCEPTANCE_LINK]: (
		state,
		payload: IOfflineDataInternalAcceptanceAdded
	) =>
		state.map(problem => {
			if (problem.localLinks?.internalAcceptanceId === payload.key) {
				problem.links = {...problem.links, internalAcceptanceId: payload.id};
			}
			return problem;
		})
});

export {ILocalProblemsState};
export default problems;
