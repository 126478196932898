import {IHistoryDifference} from '@tehzor/tools/interfaces/history/IHistoryDifference';
import {memo, useMemo} from 'react';
import {UserAvatar} from '../../../various';
import {format} from 'date-fns';
import {TranslationsHeader} from '../types/translationsHeader';

interface IHeaderProps {
	entityName: string;
	change: IHistoryDifference;
	isLast: boolean;
	dateOptions: Parameters<typeof format>[2];
	noCreation?: boolean;
	translations: TranslationsHeader;
}

export const Header = memo(
	({entityName, change, isLast, dateOptions, noCreation, translations}: IHeaderProps) => {
		const str = useMemo(
			() =>
				change.data
					? Object.entries(change.data)
							.filter(([, value]) => value)
							.map(([key]) => (change.data ? change.data[key].name : ''))
							.join(', ')
					: '',
			[change.data]
		);

		return (
			<div className="history-list__header">
				{change.createdBy && (
					<UserAvatar
						user={change.createdBy}
						size="34"
						coloring="text"
						className="history-list__user"
					/>
				)}
				<div className="history-list__info">
					<span className="history-list__strong-text">
						{change.createdBy ? change.createdBy.displayName : translations.defaultUser}
					</span>
					{(isLast || change.isCreated) && !noCreation ? (
						<span>
							&nbsp;
							{translations.create}{' '}
							<span className="history-list__strong-text">
								{change.isCreated
									? change.data
										? change.data[0].name
										: translations.defaultParam
									: entityName}
							</span>
						</span>
					) : change.isDeleted ? (
						<span>
							&nbsp;
							{translations.delete}{' '}
							<span className="history-list__strong-text">
								{change.data ? change.data[0].name : translations.defaultParam}
							</span>
						</span>
					) : (
						<span>
							&nbsp;
							{translations.modify}
							&nbsp;
							<span className="history-list__strong-text">{str}</span>
						</span>
					)}
					<span>
						{change.createdAt
							? ` ${translations.prefix} ${format(
									change.createdAt,
									'HH:mm',
									dateOptions
							  )}`
							: null}
					</span>
				</div>
			</div>
		);
	}
);
