import {useDeleteFieldSettingsSet} from '@/features/FieldsSettingsSets/mutations/useDeleteFieldSettingsSet';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import styles from './DeleteRowButton.module.less';

const deleteIcon = <i className="tz-delete" />;

interface IDeleteRowButtonProps {
	rowId: string;
}

export const DeleteFieldsSettingsSetButton = ({rowId}: IDeleteRowButtonProps) => {
	const deleteFieldsSettingsSet = useDeleteFieldSettingsSet();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить набор',
		'Вы уверены, что хотите удалить набор?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			deleteFieldsSettingsSet.mutate(rowId);
		}
	}, [deleteFieldsSettingsSet, getDeleteConfirmation, rowId]);

	return (
		<>
			<IconButton
				className={styles.deleteCell}
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</>
	);
};
