import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';

export interface IDeleteWarrantyClaimPayload {
	objectId: string;
	claimId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, claimId: string, quietly?: boolean) => {
	if (!quietly) {
		addInfoToast('Удалено', 'Обращение успешно удалено');
	}
	return {
		type: types.DELETE_SUCCESS,
		payload: {objectId, claimId} as IDeleteWarrantyClaimPayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении обращения');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

export const deleteWarrantyClaimActions = {request, success, failure};
