import {Dispatch, useCallback} from 'react';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {InputType, ViewerType} from '@tehzor/ui-components/src/components/LocationSelect';
import {
	changeLocDefaultInputType,
	changeLocDefaultViewerType
} from '@src/store/modules/settings/locationSelect/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {usePlans} from '@src/core/hooks/queries/plans/hooks';
import {TranslatedLocationSelect} from '@src/components/TranslatedLocationSelect';
import {useStructureById} from '@/entities/Structures';

const locationIcon = <i className="tz-location-20" />;

interface IStructureLocationSelectProps<S, E> {
	structureEl: {
		structureId: string;
		location:
			| {
					planId: string;
					location: ILocation;
			  }
			| undefined;
	};
	objectId: string;
	locations: Record<
		string,
		{
			planId: string;
			location: ILocation;
		}
	>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
}

export const StructureLocationSelect = <S extends {locations?: ILocation | null}, E>({
	structureEl,
	objectId,
	locations,
	editingDispatch
}: IStructureLocationSelectProps<S, E>) => {
	const dispatch = useAppDispatch();

	const handleLocatoinChange = useCallback(
		(structureId: string, planId?: string, location?: ILocation | null) => {
			const value = locations || {};
			if (planId && location) {
				value[structureId] = {planId, location};
			}
			editingDispatch({type: 'update', field: 'locations', value});
		},
		[locations]
	);

	const handleViewerTypeChange = useCallback(
		(v: ViewerType) => {
			dispatch(changeLocDefaultViewerType(objectId, v));
		},
		[objectId]
	);

	const handleInputTypeChange = useCallback(
		(v: InputType) => {
			dispatch(changeLocDefaultInputType(objectId, v));
		},
		[objectId]
	);

	const {data: plansMap} = usePlans(objectId);
	const {data: structure} = useStructureById(objectId, structureEl.structureId);

	if (!structure) {
		return null;
	}
	const location = structureEl.location?.location;
	const plans =
		structure.plans && plansMap ? structure.plans.map(plan => plansMap?.byId[plan.id]) : [];
	const planId = structureEl.location?.planId;

	return (
		<div>
			<EditableFieldLabel>{structure.name}</EditableFieldLabel>
			<TranslatedLocationSelect
				key={structureEl.structureId}
				mode="edit"
				location={location}
				planId={planId}
				plans={planId && plansMap ? [plansMap?.byId[planId]] : plans}
				multiplePoints
				path="entity"
				onLocationChange={(structureLocation: ILocation, planId?: string) =>
					handleLocatoinChange(structureEl.structureId, planId, structureLocation)
				}
				onViewerTypeChange={handleViewerTypeChange}
				onInputTypeChange={handleInputTypeChange}
			>
				{(displayValue, open: () => void) => (
					<TextField
						className="text-field_interactive"
						disabled={!structure.plans?.length}
						elementType="div"
						value={displayValue}
						icon={locationIcon}
						onClick={open}
					/>
				)}
			</TranslatedLocationSelect>
		</div>
	);
};
