import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const getAllIds = (state: IState) => state.pages.problem.stories.allIds || [];
const getById = (state: IState) => state.pages.problem.stories.byId || {};

/**
 * Извлекает текущую историю нарушения
 */
export const extractProblemStoriesAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map(id => byId[id]));
