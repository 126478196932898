import {CellProps} from 'react-table';
import {DocumentsCount} from '@src/components/DocumentsCount';
import {IPreparedCheck} from '../../interfaces/IPreparedCheck';
import classNames from 'classnames';

interface IDescriptionCellProps extends CellProps<IPreparedCheck> {
	className?: string;
}
export const DescriptionCell = ({row, className}: IDescriptionCellProps) => {
	const documentsCount = row.original.documentsCount;
	const numberEl = (
		<div className="checks-page__d-table-description-number">
			№{row.original.number || row.original.localNumber?.toUpperCase()}
		</div>
	);

	const documentsCountEl = documentsCount && (
		<div className="checks-page__d-table-description-documents-count">
			<DocumentsCount count={documentsCount} />
		</div>
	);

	return (
		<div className={classNames('checks-page__m-table-description', className)}>
			<div className="checks-page__m-table-description-header">{numberEl}</div>

			{documentsCountEl}
		</div>
	);
};
