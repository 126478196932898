import {IMutationErrorsMap} from '@src/interfaces/IMutationErrorsMap';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {offlineJournalActionType} from '../../enums/actionsTypes';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';

export const extractLocalInspectionsJournal = (
	inspections: IListInspection[],
	errorsMap: IMutationErrorsMap
) =>
	inspections.map(inspection => {
		let entityType = offlineJournalActionType.INSPECTION_ADD;
		if (inspection.links?.spaceId) {
			entityType = offlineJournalActionType.INSPECTION_SPACE_ADD;
		}
		if (inspection.links?.checkItemId || inspection.links?.checkListId) {
			entityType = offlineJournalActionType.INSPECTION_CHECKLIST_ADD;
		}
		return {
			entity: {
				entityId: inspection.id,
				entityType,
				entityLocalNumber: inspection.localNumber,
				description: inspection.description
			},
			location: {
				names: inspection.location?.points?.map(point => point.name)
			},
			object: {
				objectId: inspection.object?.id,
				objectName: inspection.object?.name
			},
			transfer: {
				status: inspection.transferStatus,
				statusMessage: inspection.transferStatus
					? offlineModeTransferTitles[inspection.transferStatus]
					: ''
			},
			createdAt: inspection.createdAt,
			error: errorsMap[inspection.id]
		} as IOfflineJournalEntity;
	});
