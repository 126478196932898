import {useCustomFieldById} from '@src/core/hooks/queries/fields/hooks';
import {
	IEditableEntityAction,
	IEditableEntityState
} from '@tehzor/tools/core/states/editableEntityState';
import {IFieldSetting, ValueTypeId} from '@tehzor/tools/interfaces/fields';
import {Dispatch, memo} from 'react';
import {CustomSelectField} from './components/CustomSelectField';
import {CustomDateField} from './components/CustomDateField';
import {CustomTextField} from './components/CustomTextField';
import {CustomLinkField} from './components/CustomLinkField';
import './CustomFields.less';

interface ICustomFieldProps<S, E> {
	custom: IFieldSetting;
	isMobile?: boolean;
	disabled?: boolean;

	editingState: IEditableEntityState<S>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
}

export interface ICustomState {
	customFields?: Record<string, unknown>;
	errors?: {
		customFields?: Record<string, boolean | undefined>;
	};
	exceptions?: {
		customFields?: Record<string, boolean | undefined>;
	};
}

const CustomFieldComponent = <S extends ICustomState, E>({
	custom,
	isMobile,
	editingState,
	editingDispatch,
	disabled
}: ICustomFieldProps<S, E>) => {
	const {data: field} = useCustomFieldById(custom.fieldId);

	switch (field?.valueType.id) {
		case ValueTypeId.SELECT: {
			return (
				<CustomSelectField
					setting={custom}
					field={field}
					isMobile={isMobile}
					hasError={editingState?.errors?.customFields?.[field.key]}
					exception={editingState?.exceptions?.customFields?.[field.key]}
					editingState={editingState}
					editingDispatch={editingDispatch}
					disabled={disabled}
				/>
			);
		}
		case ValueTypeId.TEXT: {
			return (
				<CustomTextField
					setting={custom}
					field={field}
					hasError={editingState?.errors?.customFields?.[field.key]}
					exception={editingState?.exceptions?.customFields?.[field.key]}
					editingState={editingState}
					editingDispatch={editingDispatch}
					disabled={disabled}
				/>
			);
		}
		case ValueTypeId.DATE: {
			return (
				<CustomDateField
					setting={custom}
					field={field}
					hasError={editingState?.errors?.customFields?.[field.key]}
					exception={editingState?.exceptions?.customFields?.[field.key]}
					editingState={editingState}
					editingDispatch={editingDispatch}
					disabled={disabled}
				/>
			);
		}
		case ValueTypeId.LINK: {
			return (
				<CustomLinkField
					setting={custom}
					field={field}
					hasError={editingState?.errors?.customFields?.[field.key]}
					exception={editingState?.exceptions?.customFields?.[field.key]}
					editingState={editingState}
					editingDispatch={editingDispatch}
					disabled={disabled}
				/>
			);
		}
		default: {
			return null;
		}
	}
};

export const CustomField = memo(CustomFieldComponent) as typeof CustomFieldComponent;
