import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectsDisplayMode} from '@src/interfaces/ObjectsDisplayMode';
import {changeDisplayMode} from '@src/store/modules/settings/pages/objects/actions';
import {extractObjectsPageSettings} from '@src/store/modules/settings/pages/objects/selectors';
import {SwitchMenu, SwitchMenuButton, SwitchMenuOption} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useCallback} from 'react';
import {LegendButton} from '../legend/LegendButton';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useMatch} from 'react-router-dom';
import {useObjectsControlOptions} from '@src/pages/ObjectsPage/hooks/useObjectsControlOptions';

interface IObjectsControlsProps {
	text: string;
}

export const ObjectsControls = ({text}: IObjectsControlsProps) => {
	const routeObjectMatch = useMatch('/objects/:objectId');
	const routeSpaceSelectionMatch = useMatch('/objects/:objectId/spaces/selection');
	const objectId =
		routeObjectMatch?.params?.objectId || routeSpaceSelectionMatch?.params?.objectId;
	const {pushPath} = useChangePath();
	const {displayMode} = useAppSelector(extractObjectsPageSettings);
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const {options} = useObjectsControlOptions(isDesktop);

	const handleChange = useCallback(
		(value: ObjectsDisplayMode) => {
			if (value === ObjectsDisplayMode.SPACES && objectId) {
				return pushPath(`/objects/${objectId}/spaces`);
			}
			return dispatch(changeDisplayMode(value));
		},
		[objectId]
	);

	return (
		<div className="objects-page__controls">
			<div className="objects-page__controls-amount">{text}</div>

			<div className="objects-page__controls-actions">
				<LegendButton />
				{isDesktop ? (
					<SwitchMenu
						value={displayMode}
						onChange={handleChange}
						className="objects-page__controls-actions__mode-switch"
					>
						{options.map(option => (
							<SwitchMenuOption
								value={option.key}
								key={option.key}
							>
								{option.content}
							</SwitchMenuOption>
						))}
					</SwitchMenu>
				) : (
					<SwitchMenuButton
						className="objects-page__controls-actions__mode-switch"
						options={options}
						value={displayMode}
						onChange={handleChange}
					/>
				)}
			</div>
		</div>
	);
};
