import {httpRequests} from '@tehzor/tools/api/httpRequests';

interface IDeleteWorkingGroupResponse {
	success: boolean;
}
/**
 * Удаляет рабочую группу
 *
 * @param groupId id рабочей группы
 */
export const requestDeleteWorkingGroup = async (groupId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteWorkingGroupResponse>(
		`working-groups/${groupId}`
	);
	return response.data;
};
