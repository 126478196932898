import {Column} from 'react-table';
import {IPreparedInternalAcceptance} from '../../interfaces/IPreparedInternalAcceptance';
import {MobileInternalAcceptanceCell} from './MobileInternalAcceptanceCell';
import {MobileInternalAcceptanceCellWithoutObject} from './MobileInternalAcceptanceCellWithoutObject';

export const mobileColumns: Array<Column<IPreparedInternalAcceptance>> = [
	{
		id: 'object',
		Cell: MobileInternalAcceptanceCell,
		className: 'internal-acceptances-page__mobile-cell'
	}
];

export const mobileColumnsWithoutObject: Array<Column<IPreparedInternalAcceptance>> = [
	{
		id: 'object',
		Cell: MobileInternalAcceptanceCellWithoutObject,
		className: 'internal-acceptances-page__mobile-cell'
	}
];
