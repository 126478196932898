import {useMemo} from 'react';
import {IEnrichedDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IEnrichedDelegationHistory';

export const useGroupedHistories = (histories: IEnrichedDelegationHistory[]) =>
	useMemo(() => {
		const byDate: Record<number, IEnrichedDelegationHistory[]> = {};

		for (const history of histories) {
			const date = history.createdAt ? history.createdAt - (history.createdAt % 86400000) : -1;
			if (!byDate[date]) {
				byDate[date] = [];
			}
			byDate[date].push(history);
		}

		return Object.entries(byDate)
			.map<[number, IEnrichedDelegationHistory[]]>(item => [
				+item[0],
				item[1].sort((a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0))
			])
			.sort((a, b) => b[0] - a[0]);
	}, [histories]);