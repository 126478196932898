import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {IDndRenderPreviewProps, TextField} from '@tehzor/ui-components';

const dragIcon = <i className="tz-drag-and-drop-24 plans-page__preview-icon"/>

const classes = {
	wrap: 'plans-page__preview-text',
};

export const renderPreview = ({
	item
}: IDndRenderPreviewProps<IPlan>) => (item ? (
	<div className="plans-page__preview">
		{dragIcon}
		<TextField
			className={classes}
			value={item?.name}
			elementType="div"
		/>
	</div>
) : null);