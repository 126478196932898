import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {GET_REQUEST} from '@src/store/modules/entities/warrantyClaims/constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import * as warranityClaimsTypes from '@src/store/modules/entities/warrantyClaims/constants';
import {IGetWarrantyClaimsPayload} from '@src/store/modules/entities/warrantyClaims/actions';

export interface IWarrantyClaimsFiltersState extends Record<string, unknown> {
	objects?: string[];
	spaces?: string[];
	number?: string;
	publicId?: string;
	statuses?: string[];
	spaceOwners?: string[];
	createdBy?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
	registrationDateFrom?: Date;
	registrationDateTo?: Date;
}

export interface IWarrantyClaimsSortState {
	[column: string]: boolean;
}

export interface IWarrantyClaimsPageSettingsState {
	filters: IWarrantyClaimsFiltersState;
	sort: IWarrantyClaimsSortState;
	pageSize: number;
	offset: number;
	total: number;
	selectedRows: string[];
	isAllRowsSelected: boolean;
}

export type IWarrantyClaimsPagesSettingsState = Record<string, IWarrantyClaimsPageSettingsState>;

export const getInitialStateForPage = (objectId: string): IWarrantyClaimsPageSettingsState => ({
	filters: {
		objects: objectId !== 'all' ? [objectId] : undefined
	},
	sort: {
		createdAt: false
	},
	pageSize: 20,
	offset: 0,
	total: 0,
	selectedRows: [],
	isAllRowsSelected: false
});

export default createReducer<IWarrantyClaimsPagesSettingsState>(
	{},
	{
		[GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage(payload.objectId);
			}
		},
		[warranityClaimsTypes.GET_SUCCESS]: (
			state,
			{payload}: {payload: IGetWarrantyClaimsPayload}
		) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage(payload.objectId);
			}
			const page = state[payload.objectId];
			page.offset = payload.offset;
			page.total = payload.total;
		},
		[warranityClaimsTypes.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string; offset: number}}
		) => {
			const page = state[payload.objectId];
			if (page) {
				page.offset = payload.offset;
			}
		},
		[warranityClaimsTypes.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: {payload: {objectId: string; selectedRows: string[]}}
		) => {
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		[warranityClaimsTypes.TOGGLE_ALL_ROWS_SELECTED]: (
			state,
			{payload}: {payload: {objectId: string; isAllRowsSelected?: boolean}}
		) => {
			const page = state[payload.objectId];
			page.isAllRowsSelected =
				payload.isAllRowsSelected !== undefined
					? payload.isAllRowsSelected
					: !page.isAllRowsSelected;
		},
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<IWarrantyClaimsFiltersState>}
		) => {
			const {filters} = payload;
			if (payload.objectId !== 'all') {
				filters.objects = [payload.objectId];
			}
			state[payload.objectId].filters = filters;
		},
		[types.CLEAR_FILTERS]: (state, {payload}: {payload: IClearFiltersPayload}) => {
			const filters = {} as IWarrantyClaimsFiltersState;
			if (payload.objectId !== 'all') {
				filters.objects = [payload.objectId];
			}
			state[payload.objectId].filters = filters;
		},
		[types.CHANGE_SORT]: (
			state,
			{payload}: {payload: IChangeSortPayload<IWarrantyClaimsSortState>}
		) => {
			state[payload.objectId].sort = payload.sort;
		},
		[types.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: IChangePageSizePayload}) => {
			state[payload.objectId].pageSize = payload.pageSize;
		}
	}
);
