import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

/**
 * Формирует дерево компаний и объектов
 *
 * @param companies компании
 * @param objects объекты
 */
export function makeObjectsTreeData(objects: IObject[], companies?: ICompany[]): ITreeDataItem[] {
	let result: ITreeDataItem[] = [];
	if (companies && companies.length > 1) {
		result = companies.map(c => ({
			id: c.id,
			content: c.name
		}));
	}
	result = result.concat(
		objects.map(o => ({
			id: o.id,
			content: o.name,
			parentId: o.parentId || o.companyId
		}))
	);
	return result;
}
