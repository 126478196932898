import * as React from 'react';
import classNames from 'classnames';

interface ITableHeadProps {
	className?: string;
	style?: React.CSSProperties;
	role?: string;
	children?: React.ReactNode;
}

const TableHead = ({className, style, role, children}: ITableHeadProps) => (
	<div
		className={classNames('table2__head', className)}
		style={style}
		role={role}
	>
		{children}
	</div>
);

export default TableHead;