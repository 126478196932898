import {BaseSkeleton} from '@tehzor/ui-components';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import styles from './Skeletom.module.less';

export const Skeleton = () => {
	const isDesktop = useIsDesktop();
	return (
		<div className={styles.wrapper}>
			<BaseSkeleton
				width={isDesktop ? 350 : '100%'}
				height={700}
			/>
		</div>
	);
};
