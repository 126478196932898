import {memo} from 'react';
import {useTranslation} from 'react-i18next';

const TotalTasks = ({number}: {number: number}) => {
	const {t} = useTranslation();

	return (
		<div>
			{`${t('components.tasksSchedulePopup.totalTasks.text')}: ${number}`}
		</div>
	);
};

export default memo(TotalTasks);
