import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';

export function useEntityType(objectId: string, types: IInternalAcceptanceAddingEntityType[]) {
	const type = useAppSelector(s => s.settings.internalAcceptanceAdding.entityType);
	return useMemo<IInternalAcceptanceAddingEntityType | undefined>(() => {
		if (types.includes(type)) {
			return type;
		}
		return types.length ? types[0] : undefined;
	}, [type, types]);
}
