import {useMemo} from 'react';
import {IDatesOptions} from '../Calendar';
import {add, format, startOfWeek} from 'date-fns';

export const useWeekNames = (datesOptions?: IDatesOptions) => useMemo(() => {
		const startWeek = startOfWeek(new Date(), datesOptions);
		const names: string[] = [];
		for (let i = 0; i < 7; i++) {
			const d = add(startWeek, {days: i});
			names.push(format(d, 'EEEEEE', datesOptions));
		}
		return names;
	}, [datesOptions]);
