import {useEditableCheckList} from '@src/components/EditableCheckList/hooks/useEditableCheckList';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useCallback, useState} from 'react';
import './AddingCheckListDialog.less';
import {useAddCheckList} from '@src/core/hooks/mutations/checkLists/useAddCheckList';

interface IAddingCheckListDialogProps {
	isOpen: boolean;
	close(): void;
}

export const AddingCheckListDialog = ({isOpen, close}: IAddingCheckListDialogProps) => {
	const [saving, setSaving] = useState(false);
	const [checkListFields, , getSavingData, reset, isBlocking] = useEditableCheckList({saving});

	const addCheckList = useAddCheckList();

	const handleSave = useCallback(() => {
		setSaving(true);

		const savingData = getSavingData();

		try {
			if (savingData && savingData.checkList) {
				const {checkList} = savingData;
				addCheckList(checkList);
			}
		} finally {
			setSaving(false);
			reset();
			close();
		}
	}, [addCheckList, close, getSavingData, reset]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			close();
		}
	}, [isBlocking, close, getClosingConfirmation]);

	return (
		<ModalDialog
			className={{root: 'adding-check-list', content: 'adding-check-list__container'}}
			open={isOpen}
			title="Создать чек-лист"
			footer={
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={cancel}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={cancel}
			onAfterClose={reset}
		>
			{checkListFields}
			{closingDialog}
		</ModalDialog>
	);
};
