import classNames from 'classnames';
import { ReactNode, memo, useMemo, useRef } from 'react';
import {convertClassNames} from '../../../utils/convertClassNames';
import {useDragEvents} from '../hooks/useDragEvents';
import {IDndEvent, IDndItem} from '../interfaces';
import './DndList.less';
import {DndListEmptyItem} from './components/DndListEmptyItem';
import {DndListItem} from './components/DndListItem';
import {IDndRenderItemProps} from './interfaces/IDndRenderItemProps';

interface IDndListProps<S> {
	className?:
		| string
		| {
				root?: string;
				item?: string;
				itemContainer?: string;
				emptyItem?: string;
				handle?: string;
				itemWithoutHandle?: string;
		  };
	items: S[];
	draggableIds?: string[];
	container: string;
	emptyMessage?: string;

	onDragStart?: (event: IDndEvent<S>) => void;
	onDragOver?: (event: IDndEvent<S>) => void;
	onDragEnd?: (event: IDndEvent<S>) => void;
	onDragCancel?: (event: IDndEvent<S>) => void;

	renderItem: (props: IDndRenderItemProps<S>) => ReactNode;
}

export const DndList = memo(
	<S extends IDndItem>({
		className,
		items,
		draggableIds,
		container,
		emptyMessage,
		onDragStart,
		onDragOver,
		onDragEnd,
		onDragCancel,
		renderItem
	}: IDndListProps<S>) => {
		const containerRef = useRef<HTMLDivElement>(null);
		const classes = useMemo(() => convertClassNames(className), [className]);
		const {draggableItems, overState} = useDragEvents(
			items,
			container,
			containerRef,
			onDragStart,
			onDragOver,
			onDragEnd,
			onDragCancel
		);
		const {item: overItem, container: overContainer, status} = overState;

		return (
			<div
				ref={containerRef}
				className={classNames('dnd-list', classes.root)}
			>
				{!draggableItems.length && (
					<DndListEmptyItem
						className={classes.emptyItem}
						container={container}
						overContainer={overContainer}
						emptyMessage={emptyMessage}
					/>
				)}
				{draggableItems.map((item, index) => (
					<DndListItem
						className={classes}
						key={item.id}
						item={item}
						index={index}
						isOverItem={item.id === overItem?.id}
						overStatus={item.id === overItem?.id ? status : undefined}
						draggableIds={draggableIds}
						container={container}
						renderItem={renderItem}
					/>
				))}
			</div>
		);
	}
);
