import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

export type IGetSpaceResponse = ISpace;

/**
 * Получает помещение по id
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 */
export const requestSpace = async (spaceId: string, objectId: string) => {
	const response = await httpRequests.withToken.get<IGetSpaceResponse>(`spaces/${spaceId}`, {
		params: {objectId}
	});
	return response.data;
};
