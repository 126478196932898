import { useCallback } from 'react';
import * as React from 'react';
import './MobileBottomMenuItem.less';
import classNames from 'classnames';
import ButtonBase from '../../../../buttons/ButtonBase';

interface IMobileHeaderMenuItemProps<D = unknown> {
	className?: string;
	style?: React.CSSProperties;
	icon?: React.ReactNode;
	label?: React.ReactNode;
	active?: boolean;
	data?: D;

	onClick?(data?: D): void;
}

const MobileBottomMenuItem = <D extends unknown>(props: IMobileHeaderMenuItemProps<D>) => {
	const {className, style, icon, label, active, data, onClick} = props;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(data);
		}
	}, [data, onClick]);

	return (
		<ButtonBase
			className={classNames({'m-bottom-menu-item_active': active}, className)}
			style={style}
			classNamePrefix="m-bottom-menu-item"
			outerTagType="button"
			leftIcon={icon}
			label={label}
			onClick={handleClick}
		/>
	);
};

export default MobileBottomMenuItem;
