import * as React from 'react';
import './NotificationsDialog.less';
import Modal from 'react-modal';
import classNames from 'classnames';
// import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
// import MobileDetect from 'mobile-detect';
// import useUnmount from 'react-use/lib/useUnmount';

// const md = new MobileDetect(window.navigator.userAgent);
// const isMobile = !!md.mobile();

interface INotificationsDialogProps {
	className?: string;
	children?: React.ReactNode;
	isOpen: boolean;

	onRequestClose?(): void;
}

const NotificationsDialog = (props: INotificationsDialogProps) => {
	const {className, children, isOpen, onRequestClose} = props;

	// const contentRef = useRef<HTMLDivElement | undefined>();

	const classes = {
		base: classNames('notifications-dialog', className),
		afterOpen: 'notifications-dialog_after-open',
		beforeClose: 'notifications-dialog_before-close'
	};

	const overlayClasses = {
		base: 'notifications-dialog-overlay',
		afterOpen: 'notifications-dialog-overlay_after-open',
		beforeClose: 'notifications-dialog-overlay_before-close'
	};

	// const saveContentRef = useCallback((element: HTMLDivElement) => {
	// 	contentRef.current = element;
	// }, []);

	// const enableScroll = useCallback(() => {
	// 	if (isMobile && contentRef.current) {
	// 		enableBodyScroll(contentRef.current);
	// 	}
	// }, []);
	//
	// const disableScroll = useCallback(() => {
	// 	if (isMobile && contentRef.current) {
	// 		disableBodyScroll(contentRef.current);
	// 	}
	// }, []);

	// useEffect(() => {
	// 	if (!isOpen) {
	// 		enableScroll();
	// 	}
	// }, [isOpen]);

	// useUnmount(() => {
	// 	enableScroll();
	// });

	return (
		<Modal
			isOpen={isOpen}
			className={classes}
			overlayClassName={overlayClasses}
			onRequestClose={onRequestClose}
			ariaHideApp={false}
			closeTimeoutMS={300}
			// onAfterOpen={disableScroll}
			// contentRef={saveContentRef}
		>
			{children}
		</Modal>
	);
};

export default NotificationsDialog;
