import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IGetCheckListStoriesQueryKeyList, checkListStoriesQueryKeys} from '../keys';
import {requestCheckListStories} from '@src/api/backend/checkListStories';

export const useCheckListStoriesQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(checkListStoriesQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetCheckListStoriesQueryKeyList>) => {
			const [, , objectId, stage, links] = queryKey;
			return requestCheckListStories(objectId, stage, links);
		},
		meta: {
			error: 'при загрузке истории изменений по чек-листу'
		},
		staleTime: 0, // 0 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});
};
