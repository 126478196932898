import {Locale} from 'date-fns';

import {ru, enUS} from 'date-fns/locale';
import {useTranslation} from 'react-i18next';

interface ILocales {
	[key: string]: Locale;
}

export const useDateFnsLocale = () => {
	const {i18n} = useTranslation();
	const locales: ILocales = {
		ru,
		en: enUS
	};
	return locales[i18n.language];
};
