import { useCallback } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {IEditableSpaceTypeDecorationItemProps} from './EditableTypeDecorationItems';

export const DeleteCell = ({
	elemIndex,
	editingDispatch,
	className
}: Omit<IEditableSpaceTypeDecorationItemProps, 'editingState'> & {
	elemIndex: number;
	className: string;
}) => {
	const handleClick = useCallback(() => {
		editingDispatch({type: 'delete-array-item', field: 'data', index: [elemIndex]});
	}, [elemIndex]);

	return (
		<IconButton
			className={className}
			type="inline-blue-accent"
			onClick={handleClick}
		>
			<i className="tz-delete"/>
		</IconButton>
	);
};
