import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {convertEmployeesToTreeItems} from './convertEmployeesToTreeItems';

type IUsersTreeData = Array<{
	key: string;
	data: ITreeDataItem[];
}>;

/**
 * Формирует дерево сотрудников компаний
 *
 * @param companies компании
 * @param users пользователи
 */
export const makeUsersTreeData = (
	users: INormalizedData<IBriefUser>,
	companies?: ICompany[],
): IUsersTreeData => {
	if (companies?.length === 1) {
		if (companies[0].employees) {
			const data = convertEmployeesToTreeItems(users, companies[0].employees);
			return [{key: companies[0].id, data}];
		}
		return [];
	}
	if (!companies) {
		return []
	}
	return companies.reduce<IUsersTreeData>((prev, item) => {
		if (item.employees) {
			const current = convertEmployeesToTreeItems(users, item.employees, item.id);
			if (current.length) {
				current.push({id: item.id, content: item.name, nonCheckable: true});
				prev.push({key: item.id, data: current});
			}
		}
		return prev;
	}, []);
};
