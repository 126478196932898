import {EntityLinkButton, TouchablePanel} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {makeCommentTitle} from '../../../../components/Comments/utils/makeCommentTitle';
import {useTranslation} from 'react-i18next';
import {useCommentsPermissions} from '@src/core/hooks/permissions/useCommentsPermissions';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {formWorkAcceptanceCommentsLink} from '@tehzor/tools/utils/links';

interface IMobileLinksProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
}

const MobileLinks = (props: IMobileLinksProps) => {
	const {pushPath} = useChangePath();
	const {objectId, workAcceptance} = props;
	const {t} = useTranslation(); // в том числе makeCommentTitle
	const commentsPermissions = useCommentsPermissions({
		objectId,
		createdBy: workAcceptance.createdBy,
		performers: workAcceptance.acceptors,
		watchers: workAcceptance.watchers
	});
	return (
		<TouchablePanel className="problem-page__m-links">
			{(commentsPermissions.canViewComments || commentsPermissions.canViewReplies) && (
				<EntityLinkButton
					key="comments"
					className="problem-page__m-link"
					type="accent"
					leftIcon={<i className="tz-comment" />}
					label={makeCommentTitle(
						commentsPermissions.canViewComments,
						commentsPermissions.canViewReplies,
						t
					)}
					url={formWorkAcceptanceCommentsLink(objectId, workAcceptance.id)}
					title={t('problemPage.links.toReplyAndComments.title')}
					onClick={pushPath}
				/>
			)}
		</TouchablePanel>
	);
};

export default MobileLinks;
