import {Column} from 'react-table';
import CellWrap from '@src/components/tableCells/CellWrap';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell';
import {extractWorkAcceptanceProblemCommentsByProblem} from '@src/store/modules/entities/workAcceptance/selectors/workAcceptance';

export const mobileColumns: Array<Column<IWorkAcceptanceEntity>> = [
	{
		id: 'info',
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(
				ProblemInfoMobileCell,
				extractWorkAcceptanceProblemCommentsByProblem
			),
			inspection: InspectionInfoMobileCell
		})
	}
];
