import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useSpaceStatusesSetPermissions} from '@src/core/hooks/permissions/useSpaceStatusesSetsPermissions';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {addErrorToast} from '@src/utils/toasts';
import {LoadingPanel} from '@tehzor/ui-components';
import {useEffect} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {SpaceStatusesSetContent} from './components/SpaceStatusesSetContent';
import './SpaceStatusesSetPage.less';
import {useExtractSpaceStatusesSetById} from '@src/core/hooks/queries/spaceStatusesSets/hooks';
import {useSpaceStatusesAsArrayBySetId} from '@src/core/hooks/queries/spaceStatuses/hooks';

export const SpaceStatusesSetPage = () => {
	useScrollRestoration();

	const {spaceStatusesSetId} = useStrictParams<{spaceStatusesSetId: string}>();
	const {data: spaceStatusesSet, isLoading: spaceStatusesSetLoading} =
		useExtractSpaceStatusesSetById(spaceStatusesSetId);
	const {canView} = useSpaceStatusesSetPermissions();
	useEffect(() => {
		if (!canView) {
			addErrorToast('Отсутствие доступа', 'к просмотру страницы');
		}
	}, [canView]);

	const {data: spaceStatuses, isLoading: spaceStatusesLoading} =
		useSpaceStatusesAsArrayBySetId(spaceStatusesSetId);

	useAppHeader(
		{
			title: spaceStatusesSet?.name || 'Новый набор статусов помещений',
			showBackBtn: true
		},
		[spaceStatusesSet]
	);

	if (!canView) {
		return null;
	}

	if (spaceStatusesSetLoading || spaceStatusesLoading) {
		return (
			<LoadingPanel
				className="space-statuses-set__loading-panel"
				active={spaceStatusesSetLoading || spaceStatusesLoading}
				delay={0}
			/>
		);
	}

	return (
		<div className="space-statuses-set">
			<SpaceStatusesSetContent
				spaceStatuses={spaceStatuses}
				spaceStatusesSet={spaceStatusesSet}
			/>
		</div>
	);
};
