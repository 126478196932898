import { memo, useCallback } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formTaskLink} from '@tehzor/tools/utils/links';
import {EntityInnerTable} from '@tehzor/ui-components';
import {mobileColumns} from '@src/pages/CheckListPage/components/columns.mobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckDetailsEntity} from '@src/interfaces/ICheckDetailsEntity';
import {EmptyPlate} from '@src/pages/CheckListPage/components/detail/EmptyPlate/EmptyPlate';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useTasksByLinksAsDetailsEntity} from "@src/core/hooks/queries/tasks/hooks";
import {useTranslation} from "react-i18next";

interface IItem extends ICheckItem {
	children?: any;
}

interface ITableProps {
	item?: IItem;
	list?: ICheckList;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

const taskIcon = <i className="tz-task-28" />;

export const TasksTable = memo(({item, list, selectedRows, onSelectedRowsChange}: ITableProps) => {
	const {t} = useTranslation();

	const {pushPath} = useChangePath();
	const params = useStrictParams<{
		itemId: string;
		objectId: string;
		spaceId?: string;
		workAcceptanceId?: string;
	}>();

	const {data: tasks} = useTasksByLinksAsDetailsEntity(
		{
			checkListIds: list?.id ? [list.id] : undefined,
			checkItemIds: item?.id ? [item?.id] : undefined
		},
		{
			spaceId: params.spaceId,
			workAcceptanceId: params.workAcceptanceId
		});

	const handleRowClick = useCallback(
		(entity: ICheckDetailsEntity) => {
			pushPath(formTaskLink(entity.id));
		},
		[pushPath]
	);

	return (
		<div className="check-item-detail__list">
			{tasks && tasks?.length > 0 ? (
				<div className="check-item-detail__list-body">
					<EntityInnerTable
						columns={mobileColumns}
						hideHead
						selectedRows={selectedRows}
						onSelectedRowsChange={onSelectedRowsChange}
						data={tasks}
						selectable
						onRowClick={handleRowClick}
					/>
				</div>
			) : (
				<EmptyPlate
					text={t('checkListPage.tasksTable.emptyPlate.text')}
					icon={taskIcon}
				/>
			)}
		</div>
	);
});
