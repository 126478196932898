import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IProblemStory} from '@tehzor/tools/interfaces/problemStories/IProblemStory';
import {wsConnector} from '../wsConnector';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

export type IGetProblemStoriesResponse = INormalizedData<IProblemStory>;

/**
 * Получает историю нарушения по id
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const requestProblemStories = (objectId: string, problemId: string) => {
	const params = {objectId, problemId, commentTypeId: ICommentTypeId.PROBLEM_COMMENT};
	return wsConnector.sendAuthorizedRequest<IGetProblemStoriesResponse>(
		'getProblemStories',
		params
	);
};
