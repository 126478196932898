import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {ModifiedDateFilterPage} from '@src/pages/filters/ModifiedDateFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';
import {OwnerAcceptancesFiltersPage} from '@src/pages/filters/OwnerAcceptancesFiltersPage/OwnerAcceptancesFiltersPage';
import {SpaceOwnersFilterPage} from '@src/pages/filters/SpaceOwnersFilterPage';

export const ownerAcceptancesFilterRoutes = [
	{
		path: 'objects',
		element: <FiltersPageObjects/>
	},
	{
		path: 'spaceowners',
		element: <SpaceOwnersFilterPage/>
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage/>
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage/>
	},
	{
		path: 'modifieddate',
		element: <ModifiedDateFilterPage/>
	},
	{
		path: '',
		element: <OwnerAcceptancesFiltersPage/>
	}
];
