import {ReactNode, useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {errorsFns, isEdited} from '@src/core/hooks/states/useEditableExportTemplateState/state';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {EditableExportTemplate} from '../EditableExportTemplate';
import {
	convertExportTemplateToSave,
	useEditableExportTemplateState
} from '@src/core/hooks/states/useEditableExportTemplateState';
import {
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {Button} from '@tehzor/ui-components';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import {fileNameError} from '../utils/fileNameError';
import ExportTemplatesInstruction from '@tehzor/ui-components/src/components/various/ExportTemplatesInstruction/ExportTemplatesInstruction';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ISavingExportTemplate} from '@src/interfaces/saving/ISavingExportTemplate';
import {useDownloadExportTemplate} from '@src/core/hooks/mutations/exportTemplates/useDownloadExportTemplate';
import {useAddExportTemplate} from '@src/core/hooks/mutations/exportTemplates/useAddExportTemplate';
import {useEditExportTemplate} from '@src/core/hooks/mutations/exportTemplates/useEditExportTemplate';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	fileNameTemplate: {fieldId: 'fileNameTemplate', isRequired: false},
	companyId: {fieldId: 'companyId', isRequired: false},
	objects: {fieldId: 'objects', isRequired: false},
	attachments: {fieldId: 'attachments', isRequired: true},
	destination: {fieldId: 'destination', isRequired: true},
	documentTypeId: {fieldId: 'documentTypeId', isRequired: false}
};

interface IHookArgs {
	exportTemplate?: IExportTemplate;
	saving?: boolean;
}

export const useEditableExportTemplate = ({
	exportTemplate,
	saving
}: IHookArgs): [
	ReactNode,
	ReactNode,
	ReactNode,
	() => Promise<ISavingExportTemplate | undefined>,
	() => void,
	boolean
] => {
	const {pushPath} = useChangePath();
	const [editingState, editingDispatch] = useEditableExportTemplateState({
		exportTemplate
	});

	const [isBlocking, setIsBlocking] = useState(true);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();

	const {mutateAsync: downloadExportTemplate} = useDownloadExportTemplate();
	const {mutateAsync: addExportTemplate} = useAddExportTemplate();
	const {mutateAsync: editExportTemplate} = useEditExportTemplate();

	const fileNameIsNotCorrect = fileNameError(
		uploadingFilesState?.value[0]?.name,
		exportTemplate?.file?.s3Name
	);

	useUpdateEffect(() => {
		if (isEdited(editingState, exportTemplate)) {
			if (hasErrors(editingState, errorsFns, fieldsSettings)) {
				setIsBlocking(true);
			} else if (exportTemplate) {
				setIsBlocking(false);
			} else if (
				isFilesExist(uploadingFilesState.value) &&
				uploadingFilesState.value.length === 1 &&
				!fileNameIsNotCorrect
			) {
				setIsBlocking(false);
			} else {
				setIsBlocking(true);
			}
		} else if (
			isFilesExist(uploadingFilesState.value) &&
			uploadingFilesState.value.length === 1 &&
			!fileNameIsNotCorrect
		) {
			setIsBlocking(false);
		} else {
			setIsBlocking(true);
		}
	}, [editingState, exportTemplate, uploadingFilesState.value, isEdited]);

	useUpdateEffect(() => {
		editingDispatch({
			type: 'update',
			field: 'objects',
			value: undefined
		});
	}, [editingState.companyId]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}

		if (
			!isEdited(editingState, exportTemplate) &&
			(!isFilesExist(files) || someFilesHaveError(files))
		) {
			return undefined;
		}

		const savingExportTemplate = convertExportTemplateToSave(
			editingState,
			exportTemplate,
			true
		);

		return {
			...savingExportTemplate,
			newAttachments: convertFilesToSave(files)
		};
	}, [editingDispatch, editingState, exportTemplate, uploadingFilesDispatch, waitUploading]);
	const handleDownload = async () => {
		if (exportTemplate?.file?.s3Name) {
			await downloadExportTemplate(exportTemplate.id);
		}
	};

	const handleSave = useCallback(async () => {
		setIsBlocking(true);
		const savingData = await getSavingData();
		if (savingData) {
			if (exportTemplate?.id) {
				await editExportTemplate({templateId: exportTemplate.id, fields: savingData});
			} else {
				const result = await addExportTemplate(savingData);
				pushPath(`/manage/export-templates/${result.allIds[0]}`);
			}
		}
	}, [getSavingData, exportTemplate?.id, editExportTemplate, addExportTemplate, pushPath]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: {exportTemplate}
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [editingDispatch, exportTemplate, uploadingFilesDispatch]);

	const exportTemplateFields = (
		<>
			<EditableExportTemplate
				editingState={editingState}
				editingDispatch={editingDispatch}
				fieldsSettings={fieldsSettings}
				saving={saving}
				fileNameError={fileNameIsNotCorrect}
				uploadingFilesState={uploadingFilesState}
				uploadingFilesDispatch={uploadingFilesDispatch}
				isEditing={!!exportTemplate}
			/>
			{!isBlocking && (
				<div className="editable-export-template__buttons">
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={isBlocking}
						onClick={handleSave}
					/>

					<Button
						type="cancel"
						label="Отменить"
						onClick={reset}
						disabled={
							!(
								isEdited(editingState, exportTemplate) ||
								isFilesExist(uploadingFilesState.value)
							)
						}
					/>
				</div>
			)}
			{exportTemplate ? (
				<div className="editable-export-template__buttons-download">
					<Button
						type="common-bordered"
						label="Скачать шаблон"
						onClick={handleDownload}
					/>
				</div>
			) : null}
		</>
	);

	const availableFields = <ExportTemplatesInstruction destination={editingState.destination} />;

	const commonInstruction = (
		<ExportTemplatesInstruction
			destination="fixed"
			hideSubtitle
		/>
	);

	return [
		exportTemplateFields,
		availableFields,
		commonInstruction,
		getSavingData,
		reset,
		isBlocking
	];
};
