import {Column} from 'react-table';
import {MobileWorkAcceptanceCell} from './MobileWorkAcceptanceCell';
import {MobileWorkAcceptanceCellWithoutObject} from './MobileWorkAcceptanceCellWithoutObject';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';

export const mobileColumns: Array<Column<IPreparedWorkAcceptance>> = [
	{
		id: 'object',
		Cell: MobileWorkAcceptanceCell,
		className: 'work-acceptances-page__m-table__cell'
	}
];

export const mobileColumnsWithoutObject: Array<Column<IPreparedWorkAcceptance>> = [
	{
		id: 'object',
		Cell: MobileWorkAcceptanceCellWithoutObject,
		className: 'work-acceptances-page__m-table__cell'
	}
];
