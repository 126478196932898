import { memo } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import DesktopMainActions from './actions/MainActions.desktop';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import {useInternalAcceptanceFromSpaceBreadcrumbs} from '@src/core/hooks/breadcrumbs/useInternalAcceptanceFromSpaceBreadcrumbs';

interface IPageBreadcrumbsProps {
	objectId: string;
	internalAcceptance: ILinkedInternalAcceptance;
}

const FromSpacePageBreadcrumbs = ({objectId, internalAcceptance}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useInternalAcceptanceFromSpaceBreadcrumbs(
		objectId,
		internalAcceptance.id,
		internalAcceptance
	);

	return (
		<AppBreadcrumbs
			className="internal-acceptance-page__breadcrumbs"
			items={breadcrumbs}
		>
			<DesktopMainActions
				objectId={objectId}
				internalAcceptance={internalAcceptance}
			/>
		</AppBreadcrumbs>
	);
};

export default memo(FromSpacePageBreadcrumbs);
