import {ComponentPropsWithoutRef, memo, MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface GoToSpacesProps extends ComponentPropsWithoutRef<'button'> {
	objectId: string;
}

const spaceIcon = <i className="tz-space-16" />;

export const GoToSpacesButton = memo((props: GoToSpacesProps) => {
	const {objectId, onClick, ...restProps} = props;
	const {t} = useTranslation();
	const {pushPath} = useChangePath();

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		onClick?.(e);
		pushPath(`/objects/${objectId}/spaces`);
	};

	return (
		<Button
			{...restProps}
			dataTestId="go-to-spaces-button"
			className="object-item__go-to-spaces-button"
			leftIcon={spaceIcon}
			type="secondary"
			label={t('objectsPage.objectItem.goToSpaces')}
			onClick={handleClick}
		/>
	);
});
