import {IWarrantyClaimsFiltersState, IWarrantyClaimsSortState} from './reducers';
import * as types from './constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import * as warranityClaimsTypes from '@src/store/modules/entities/warrantyClaims/constants';

/**
 * Изменяет фильтр
 *
 * @param objectId id объекта
 * @param filters свойства фильтра
 */
export const changeFilters = (objectId = 'all', filters: IWarrantyClaimsFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: {
		objectId,
		filters
	} as IChangeFiltersPayload<IWarrantyClaimsFiltersState>
});

/**
 * Очищает фильтр
 *
 * @param objectId id объекта
 */
export const clearFilters = (objectId = 'all') => ({
	type: types.CLEAR_FILTERS,
	payload: {objectId} as IClearFiltersPayload
});

/**
 * Изменяет сортировку
 *
 * @param objectId id объекта
 * @param sort параметры сортировки
 */
export const changeSort = (objectId = 'all', sort: IWarrantyClaimsSortState) => ({
	type: types.CHANGE_SORT,
	payload: {
		objectId,
		sort
	} as IChangeSortPayload<IWarrantyClaimsSortState>
});

/**
 * Изменяет количество элементов на странице
 *
 * @param objectId id объекта
 * @param pageSize количество элементов на странице
 */
export const changePageSize = (objectId = 'all', pageSize: number) => ({
	type: types.CHANGE_PAGE_SIZE,
	payload: {
		objectId,
		pageSize
	} as IChangePageSizePayload
});

/**
 * Изменяет смещение списка
 *
 * @param objectId id объекта
 * @param offset смещение
 */
export const changeOffset = (objectId = 'all', offset: number) => ({
	type: warranityClaimsTypes.CHANGE_OFFSET,
	payload: {
		objectId,
		offset
	}
});

/**
 * Изменяет выбранные строки в таблице
 *
 * @param objectId id объекта
 * @param selectedRows массив id выбранных строк
 */
export const changeSelectedRows = (objectId = 'all', selectedRows: string[]) => ({
	type: warranityClaimsTypes.CHANGE_SELECTED_ROWS,
	payload: {
		objectId,
		selectedRows
	}
});

/**
 * Переключает флаг выбора всех строк в таблице
 *
 * @param objectId id объекта
 * @param isAllRowsSelected true/false - включить/выключить, undefined - поменять на противоположное значение
 */
export const toggleAllRowsSelected = (objectId = 'all', isAllRowsSelected?: boolean) => ({
	type: warranityClaimsTypes.TOGGLE_ALL_ROWS_SELECTED,
	payload: {
		objectId,
		isAllRowsSelected
	}
});
