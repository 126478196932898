import {addErrorToast} from '@src/utils/toasts';
import {makeOwnerAcptDeleteRequest} from '@src/api/backend/ownerAcceptance';
import {queryClient} from '@src/api/QueryClient';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export const useDeleteOwnerAcceptance = () => async (objectId: string, acceptanceId: string) => {
	try {
		await makeOwnerAcptDeleteRequest(objectId, acceptanceId);

		void queryClient.invalidateQueries({queryKey: ownerAcceptancesQueryKeys.list()});
		void queryClient.invalidateQueries({queryKey: ownerAcceptancesQueryKeys.latest()});
		void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
		void queryClient.invalidateQueries({
			queryKey: [...spacesQueryKeys.ownerAcceptances(), objectId]
		});

		void queryClient.removeQueries({
			queryKey: [...ownerAcceptancesQueryKeys.details(), objectId, acceptanceId]
		});
	} catch (e) {
		addErrorToast('Передача собственнику', 'не удалось удалить сущность');
	}
};
