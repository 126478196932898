import _ from 'lodash';

export const handleSort = <I, S extends Record<string, boolean>>(
	array: I[],
	sort?: S
): I[] => {
	if (!sort) {
		return array;
	}

	const sortKeys = Object.keys(sort || {});
	const sortItem = sort[sortKeys[0]];

	const order: Array<'asc' | 'desc'> = sortItem ? ['asc'] : ['desc'];
	return _.orderBy(array, sortKeys, order);
};
