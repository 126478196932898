import {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {getStructure} from '@src/store/modules/app/links/actions/getStructure';
import {LinkButton} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureLink} from '@tehzor/tools/utils/links';
import {useExtractStructureTypesById} from '@src/core/hooks/queries/structureTypes/hooks';
import {useTranslation} from 'react-i18next';

export const StructureEntityLink = ({structureId, objectId}: Record<string, string>) => {
	const {t} = useTranslation();

	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const structure = useAppSelector(s => s.app.links.structureId?.[structureId]);
	const {data: structureTypes} = useExtractStructureTypesById();

	const handleClick = useCallback(() => {
		pushPath(formStructureLink(objectId, structureId));
	}, [objectId, structureId]);

	useAsync(async () => {
		await Promise.all([dispatch(getStructure(objectId, structureId))]);
	}, [structureId, objectId]);

	return structure && structureTypes && structureTypes[structure.type] ? (
		<LinkButton
			className="entity-links__link"
			label={`${t('components.entityLinks.structureEntityLink.label')}: ${structure.name}`}
			leftIcon={<i className="tz-external-link-16" />}
			onClick={handleClick}
			type="filled"
		/>
	) : null;
};
