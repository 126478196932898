import {IState} from '@src/store/modules';

export const extractLinksStructureId = (state: IState, structureId: string) =>
	state.app.links.structureId?.[structureId];
export const extractLinksCheckListId = (state: IState, checkListId: string) =>
	state.app.links.checkListId?.[checkListId];
export const extractLinksCheckId = (state: IState, checkId: string) =>
	state.app.links.checkId?.[checkId];
export const extractInternalAcceptanceId = (state: IState, internalAcceptanceId: string) =>
	state.app.links.internalAcceptanceId?.[internalAcceptanceId];
export const extractObjectId = (state: IState, objectId: string) =>
	state.app.links.objectId?.[objectId];
export const extractSpaceId = (state: IState, spaceId: string) =>
	state.app.links.spaceId?.[spaceId];
