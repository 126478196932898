import {formCacheOfflineDataAction} from '@tehzor/tools/contracts/dataCachingWebWorker/actions';
import {swRegistration} from './swRegistration';
import {isServiceWorkerSupported} from './utils/isServiceWorkerSupported';

/**
 * Запускает кеширования данных в worker'е
 *
 * @param forceUpdate игнорировать ли срок действия кеша
 */
export async function startOfflineDataCaching(forceUpdate?: boolean) {
	if (isServiceWorkerSupported()) {
		const registration = await swRegistration.waitRegistration();

		console.log('startOfflineDataCaching ', registration);

		if (registration) {
			registration.active?.postMessage(formCacheOfflineDataAction(forceUpdate));
		}
	}
}
