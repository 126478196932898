import CellWrap from '@src/components/tableCells/CellWrap';
import LocalProblemStatusCell from '@src/components/tableCells/LocalProblemStatusCell';
import {ProblemStatusCellWrap} from '@src/components/tableCells/ProblemStatusCell';
import {ColumnWithSettings} from '@src/core/hooks/settings/useColumnsWithSettings/interfaces';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {TFunction} from 'i18next';
import {CellProps} from 'react-table';
import CreatedCell from '../components/table/CreatedCell';
import DescriptionCell from '../components/table/DescriptionCell';
import {PlannedFixDateCell} from '../components/table/PlannedFixDateCell';
import LocationCell from '../components/table/LocationCell';
import LocationCellWithoutObject from '../components/table/LocationCellWithoutObject';
import MenuCell from '../components/table/MenuCell';
import ModifiedCell from '../components/table/ModifiedCell';
import {ProblemsInspectorsCell} from '../components/table/ProblemsInspectorsCell';
import {ProblemsPerformersCell} from '../components/table/ProblemsPerformersCell';
import {getCustomColumns} from '@src/utils/getCustomColumns';
import {ActualFixDateCell} from '../components/table/ActualFixDateCell';

export const getDesktopColumns = (
	objectId: string,
	tFunc: TFunction,
	settings?: IEntitySettings
): Array<ColumnWithSettings<IProblemEntity>> => {
	let columns: Array<ColumnWithSettings<IProblemEntity>> = [
		{
			id: 'number',
			Header: '№',
			accessor: row => row.data.number || row.data.localNumber,
			Cell: (props: CellProps<IProblemEntity>) => DescriptionCell({...props, settings}),
			width: 160,
			minWidth: 160,
			className: 'problems-page__d-table-description-cell',
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'status',
			Header: tFunc('problemsPage.problemsTable.header.status'),
			Cell: CellWrap({
				problem: ProblemStatusCellWrap,
				local_problem: LocalProblemStatusCell
			}),
			width: 130,
			minWidth: 130,
			className: 'problems-page__d-table-status-cell',
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'plannedFixDate',
			Header: tFunc('problemsPage.problemsTable.header.plannedFixDate'),
			accessor: row => row.data.plannedFixDate,
			width: 130,
			minWidth: 130,
			Cell: PlannedFixDateCell,
			sortDescFirst: true,
			customizeSettings: {
				isBuiltin: true,
				controlled: true
			}
		},
		{
			id: 'actualFixDate',
			Header: tFunc('problemsPage.problemsTable.header.actualFixDate'),
			accessor: row => row.data.actualFixDate,
			width: 130,
			minWidth: 130,
			Cell: ActualFixDateCell,
			sortDescFirst: true,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'performers',
			Header: tFunc('problemsPage.problemsTable.header.performers'),
			Cell: ProblemsPerformersCell,
			width: 130,
			minWidth: 130,
			customizeSettings: {
				isBuiltin: true,
				controlled: true
			}
		},
		{
			id: 'inspectors',
			Header: tFunc('problemsPage.problemsTable.header.inspectors'),
			Cell: ProblemsInspectorsCell,
			width: 130,
			minWidth: 130,
			customizeSettings: {
				isBuiltin: true,
				controlled: true
			}
		},
		{
			id: 'location',
			Header: tFunc('problemsPage.problemsTable.header.location'),
			Cell: objectId === 'all' ? LocationCell : LocationCellWithoutObject,
			width: 130,
			minWidth: 130,
			defaultCanSort: false,
			customizeSettings: {
				isBuiltin: true,
				controlled: true
			}
		},
		{
			id: 'createdAt',
			Header: tFunc('problemsPage.problemsTable.header.createdAt'),
			accessor: row => row.data.createdAt,
			Cell: CreatedCell,
			width: 130,
			minWidth: 130,
			sortDescFirst: true,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'modifiedAt',
			Header: tFunc('problemsPage.problemsTable.header.modifiedAt'),
			accessor: row => row.data.modifiedAt,
			Cell: ModifiedCell,
			width: 130,
			minWidth: 130,
			sortDescFirst: true,
			customizeSettings: {
				controlled: true
			}
		}
	];

	if (settings?.custom) {
		const customColumns = getCustomColumns<IProblemEntity>(settings.custom);
		columns = [...columns, ...customColumns];
	}

	columns.push({
		id: '_menu',
		Cell: MenuCell,
		className: 'problems-page__d-table-menu',
		width: 130,
		minWidth: 130,
		defaultCanSort: false,
		disableResizing: true,
		customizeSettings: {
			controlled: false
		}
	});

	return columns;
};
