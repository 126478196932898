export const imageTypes = ['image/jpeg', 'image/png', 'image/gif'];
export const wordType = 'application/msword';
export const wordXmlType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const excelType = 'application/vnd.ms-excel';
export const excelXmlType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const officeTypes = [wordType, wordXmlType, excelType, excelXmlType];
export const pdfType = 'application/pdf';

export const isImage = (type: string) => imageTypes.includes(type);

export const isOfficeFile = (type: string) => officeTypes.includes(type);

export const isDocx = (type: string) => type === wordXmlType;

export const isDoc = (type: string) => type === wordType;

export const isXlsx = (type: string) => type === excelXmlType;

export const isXls = (type: string) => type === excelType;

export const isPdf = (type: string) => type === pdfType;
