import {requestGetFieldEntityTypes, requestGetFieldValueTypes} from '@src/api/backend/fieldTypes';
import {requestGetBuiltinFields, requestGetCustomFields} from '@src/api/backend/fields';
import {useQueryClient} from '@tanstack/react-query';
import {fieldsQueryKeys} from '../keys';

export const useFieldsQueryDefaults = () => {
	const queryClient = useQueryClient();

	// Получение списка пользовательских полей
	queryClient.setQueryDefaults(fieldsQueryKeys.customLists(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetCustomFields,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});

	// Получение списка встроенных полей
	queryClient.setQueryDefaults(fieldsQueryKeys.builtinLists(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetBuiltinFields,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});

	// Получение списка типов сущностей для полей
	queryClient.setQueryDefaults(fieldsQueryKeys.types.entityTypes(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetFieldEntityTypes,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});

	// Получение списка типов значений для полей
	queryClient.setQueryDefaults(fieldsQueryKeys.types.valueTypes(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetFieldValueTypes,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
