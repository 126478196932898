import { memo } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useSpaceBreadcrumbs} from '@src/core/hooks/breadcrumbs/useSpaceBreadcrumbs';
import {MainActionsDesktop} from './actions/MainActions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IPageBreadcrumbsProps {
	objectId: string;
	spaceId: string;
	showActions?: boolean;
}

export const PageBreadcrumbs = memo(({objectId, spaceId, showActions}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useSpaceBreadcrumbs(objectId, spaceId);
	const isDesktop = useIsDesktop();
	return (
		<AppBreadcrumbs
			className="space-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && showActions && (
				<MainActionsDesktop
					objectId={objectId}
					spaceId={spaceId}
				/>
			)}
		</AppBreadcrumbs>
	);
});
