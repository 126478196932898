import chroma from 'chroma-js';

/**
 * Определяет цвета на основое базового
 *
 * @param baseColor базовый цвет
 */
export function calculateColors(baseColor?: string): [string | undefined, string | undefined, string | undefined] {
	if (!baseColor) {
		return [undefined, undefined, undefined];
	}
	const color = chroma(baseColor);
	const lightness = color.get('hsl.l');

	const bgLightness = Math.pow(lightness, 1 / 20);
	const background = color.set('hsl.l', bgLightness > 0.97 ? 0.97 : bgLightness);

	const valueBgLightness = Math.pow(lightness, 1 / 40);
	const valueBackground = color.set('hsl.l', valueBgLightness > 0.99 ? 0.99 : valueBgLightness);

	const valueShadow = color.desaturate(1.2).alpha(0.1);

	return [background.css('hsl'), valueBackground.css('hsl'), valueShadow.css('hsl')];
}
