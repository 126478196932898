import {IDragPositionEvent, OverStatus} from '../interfaces';

/**
 * Вычисляет overStatus
 * 
 * @param event событие перемещения drag элемента
 * @param overElement элемент над которым находится курсор
 * @param isTree древовидный ли список
 */
export const calculateOverStatus = (
	event: IDragPositionEvent,
	overElement?: HTMLElement,
	isTree?: boolean
): OverStatus | undefined => {
	const {x, y} = event;
	if (!x || !y || !overElement) { return undefined; }

	const {height, y: rectY} = overElement.getBoundingClientRect();

	const halfHeight = height / 2;
	const quarterHeight = height / 4;

	const topPosition = rectY;
	const centerPosition = rectY + halfHeight;
	const bottomPosition = rectY + height;

	const halfTopPosition = topPosition + quarterHeight;
	const halfBottomPosition = bottomPosition - quarterHeight;

	if (y > centerPosition) {
		return y < halfBottomPosition && isTree
			? OverStatus.CENTER : OverStatus.BOTTOM;
	}
	return y > halfTopPosition && isTree
		? OverStatus.CENTER : OverStatus.TOP;
};