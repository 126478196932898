import { forwardRef, memo, Ref, useCallback } from 'react';
import * as React from 'react';
import './LinkButton.less';
import InlineButton, {IInlineButtonProps} from '../InlineButton';
import classNames from 'classnames';

export interface ILinkButtonProps
	extends Omit<IInlineButtonProps, 'outerTagType' | 'outerTagProps'> {
	url?: string;
	title?: string;
	target?: string;
	rel?: string;
}

const LinkButton = (props: ILinkButtonProps, ref?: Ref<HTMLAnchorElement>) => {
	const {
		className,
		labelClassName,
		type = 'accent',
		title,
		url,
		target,
		rel,
		onClick,
		...rest
	} = props;

	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			if (onClick) {
				event.stopPropagation();
				event.preventDefault();
				onClick(event);
			}
		},
		[onClick]
	);

	const classes = classNames(
		'link-button',
		{
			'link-button_disabled': type !== 'filled' && rest.disabled,
			'link-button_filled-disabled': type === 'filled' && rest.disabled
		},
		className
	);

	return (
		<InlineButton
			{...rest}
			className={classes}
			labelClassName={classNames(
				{
					'link-button__label': type !== 'filled',
					'link-button__filled-label': type === 'filled'
				},
				labelClassName
			)}
			type={type}
			outerTagType="a"
			outerTagProps={{href: url, title, target, rel}}
			onClick={handleClick}
			ref={ref}
		/>
	);
};

LinkButton.displayName = 'LinkButton';

export default memo(forwardRef(LinkButton));
