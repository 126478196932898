import { memo } from 'react';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {useCurrentCheckItemIndex} from '@src/core/hooks/queries/checkItems/hooks';

const getBackgroundColor = (record?: ICheckRecord) => {
	switch (record?.status) {
		case CheckRecordStatusId.WAITING:
			return '#FFF3B3';
		case CheckRecordStatusId.PARTIALLY_ACCEPTED:
			return '#CCE3FF';
		case CheckRecordStatusId.REJECTED:
			return '#FFCCD2';
		case CheckRecordStatusId.ACCEPTED:
			return '#EFF9E6';
		default:
			return '#EDF2F6';
	}
};

interface IItemBulletProps {
	item: ICheckItem;
	record?: ICheckRecord;
}

export const ItemBullet = memo(({item, record}: IItemBulletProps) => {
	const {data: currentIndex} = useCurrentCheckItemIndex(item);

	return (
		<div className="check-list-items__item-header-bullet">
			{record ? (
				<div
					className="check-list-items__item-header-bullet-circle"
					style={{backgroundColor: getBackgroundColor(record)}}
				>
					{currentIndex}
				</div>
			) : (
				<div
					title={`${currentIndex}.`}
					className="check-list-items__item-header-bullet-text-with-ellipsis"
				>
					{currentIndex}.
				</div>
			)}
		</div>
	);
});
