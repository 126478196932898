import {queryClient} from '@src/api/QueryClient';
import {IGetMetersResponse} from '@src/api/backend/meters';
import {IDeleteMeterResponse, makeMeterDeleteRequest} from '@src/api/backend/meters/delete';
import {metersQueryKeys} from '@src/api/cache/meters/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useCallback} from 'react';

interface IMeterDeleteParams {
	id: string;
}

export const useDeleteMeter = () => {
	const mutation = useMutation<IDeleteMeterResponse, IError, IMeterDeleteParams>({
		mutationFn: (params: IMeterDeleteParams) => makeMeterDeleteRequest(params.id),

		onSuccess: data => {
			queryClient.setQueryData<IGetMetersResponse>(
				metersQueryKeys.list(data.spaceId),
				oldData => {
					if (oldData) {
						const newAllIds = oldData.allIds.filter(id => data.id !== id);
						const newById = newAllIds.reduce((prev, current) => {
							prev[current] = oldData.byId[current];
							return prev;
						}, {});

						return {
							allIds: newAllIds,
							byId: newById
						};
					}
					return oldData;
				}
			);
			addSuccessToast('Успех', 'Прибор учёта удален');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при удалении прибора учёта');
		}
	});
	/**
	 * Возвращает функцию для удаления прибора учёта
	 *
	 * @param meterId id прибора учёта
	 */
	return useCallback(
		(params: IMeterDeleteParams) => {
			mutation.mutate(params);
		},
		[mutation]
	);
};
