import {useQueryClient} from '@tanstack/react-query';
import {problemTagsSetsQueryKeys} from '../keys';
import {requestProblemTagsSets} from '@src/api/backend/problemTagsSets';

export const useProblemTagsSetsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(problemTagsSetsQueryKeys.list(), {
		queryFn: requestProblemTagsSets,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
