import {memo} from 'react';
import {useExtractMeterTariffsById} from '@src/core/hooks/queries/meterTariffs/hooks';
import {useMeterConsumptionsAsArrayForMeter} from '@src/core/hooks/queries/meterConsumptions/hooks';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {useExtractMeterTypesById} from '@src/core/hooks/queries/meterTypes/hooks';
import {format} from 'date-fns';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import './MeterConsumptions.less';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {getTranslatedMeasureUnit} from '@src/utils/translation/getTranslatedMeasureUnit';

interface MeterConsumptionsProps {
	meter: IMeter;
}

export const MeterConsumptions = memo(({meter}: MeterConsumptionsProps) => {
	const {t} = useTranslation();
	const meterId = meter.id;
	const {data: meterConsumptions} = useMeterConsumptionsAsArrayForMeter(meterId);
	const {data: meterTariffs} = useExtractMeterTariffsById();
	const translatedMeterTariffs = useTranslatedDictionaryMap(
		dictionaryKeys.meterTariffs,
		meterTariffs
	);
	const {data: meterTypes} = useExtractMeterTypesById();
	const translatedMeterTypes = useTranslatedDictionaryMap(dictionaryKeys.meterTypes, meterTypes);
	const measureUnit = translatedMeterTypes && translatedMeterTypes[meter.typeId].measureUnit;
	const translatedMeasureUnit = getTranslatedMeasureUnit(measureUnit, t);

	if (!meterConsumptions || !meterConsumptions.length) {
		return null;
	}

	return (
		<>
			{meterConsumptions.map(meterConsumption => (
				<div
					key={meterConsumption.id}
					className="meter-consumption"
				>
					<div className="meter-consumption-value">
						{translatedMeterTariffs &&
							meterConsumption.tariffId &&
							`${translatedMeterTariffs[meterConsumption.tariffId]?.name}: `}
						{meterConsumption.value !== undefined &&
							`${meterConsumption.value} ${translatedMeasureUnit}`}
					</div>
					<div className="meter-consumption-date">
						{`${t('components.meterConsumptions.datePrefix')} ${
							meterConsumption.modifiedAt
								? format(meterConsumption.modifiedAt, dateTimeFormat)
								: meterConsumption.createdAt &&
								  format(meterConsumption.createdAt, dateTimeFormat)
						}`}
					</div>
				</div>
			))}
		</>
	);
});
