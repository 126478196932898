import './InternalAcceptancePage.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/selectors';
import {useAsync} from 'react-use';
import {getInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/actions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import {getInternalAcceptanceProblemReplies} from '@src/store/modules/entities/internalAcceptance/actions/problemReplies';

const InternalAcceptancePage = () => {
	useScrollRestoration();

	const {objectId, internalAcceptanceId} = useStrictParams<{
		objectId: string;
		internalAcceptanceId: string;
	}>();
	const internalAcceptance = useAppSelector(extractInternalAcceptance);
	const dispatch = useAppDispatch();

	useAsync(async () => {
		await Promise.all([
			dispatch(getInternalAcceptance(objectId, internalAcceptanceId)),
			dispatch(getInternalAcceptanceProblemReplies(objectId, internalAcceptanceId))
		]);
	});

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				internalAcceptanceId={internalAcceptanceId}
				internalAcceptance={internalAcceptance}
			/>
		);
	}
	return (
		<Mobile
			objectId={objectId}
			internalAcceptanceId={internalAcceptanceId}
			internalAcceptance={internalAcceptance}
		/>
	);
};

export default InternalAcceptancePage;
