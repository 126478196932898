import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

export interface IResponsibilityRulesPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
}

export const useResponsibilityRulesPermissions = (): IResponsibilityRulesPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		return {
			canView: hasPermission(user.roles, 'responsibility-rules-view'),
			canAdd: hasPermission(user.roles, 'responsibility-rules-add'),
			canEdit: hasPermission(user.roles, 'responsibility-rules-edit'),
			canDelete: hasPermission(user.roles, 'responsibility-rules-delete')
		};
	}, [user]);
};
