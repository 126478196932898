import {Reducer, useReducer} from 'react';
import {
	errorsFns,
	IEditableInternalAcceptanceState,
	IEditableInternalAcceptanceAction,
	init
} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';

export const useEditableInternalAcceptanceState = (internalAcceptance?: IInternalAcceptance) =>
	useReducer<
		Reducer<IEditableInternalAcceptanceState, IEditableInternalAcceptanceAction>,
		IInternalAcceptance | undefined
	>(createReducer(init, errorsFns), internalAcceptance, init);
