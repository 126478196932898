import {PriorityView} from '@src/components/PriorityView/PriorityView';
import {CellProps} from 'react-table';
import {useTaskPrioritiesMap} from '@src/core/hooks/queries/task-priorities/hooks';
import {IEnrichedTaskForScheduler} from "@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler";

export const Priority = ({row}: Pick<CellProps<IEnrichedTaskForScheduler>, 'row'>) => {
	const task = row.original;
	const {data: prioritiesMap} = useTaskPrioritiesMap();
	if (!prioritiesMap) return null;
	return (
		<PriorityView
			value={task?.taskPriority?.id || ''}
			prioritiesMap={prioritiesMap}
		/>
	);
};
