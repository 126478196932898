import {IconButton} from '@tehzor/ui-components';
import { useCallback, useContext } from 'react';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from '../DispatchActionCtx';
import {IPreparedCheckList} from '@src/pages/manage/CheckListsPage/interfaces/IPreparedCheckList';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';

const deleteIcon = <i className="tz-delete" />;
const copyIcon = <i className="tz-copy" />;

export const DesktopMenuCell = ({row}: CellProps<IPreparedCheckList>) => {
	const menuActions = [];
	const perms = useCheckListPermissions();
	const dispatchAction = useContext(DispatchActionCtx);
	const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [row.original.id]);

	const handleCopy = useCallback(() => {
		void dispatchAction({type: 'copy', payload: row.original});
	}, [row.original.id]);

	if (perms.canAdd) {
		menuActions.push(
			<IconButton
				key="copy"
				type="inline-blue-accent"
				onClick={handleCopy}
			>
				{copyIcon}
			</IconButton>
		);
	}

	if (perms.canDelete) {
		menuActions.push(
			<IconButton
				key="delete"
				type="inline-blue-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
		);
	}

	return menuActions.length ? (
		<ClickPreventWrap className="manage-check-lists-page__d-table-menu-wrap">
			{menuActions}
		</ClickPreventWrap>
	) : null;
};
