import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {useCallback} from 'react';
import {useDeleteFieldsSettingsSets} from '@/features/FieldsSettingsSets/hooks/useDeleteFieldsSettingsSets';

interface IUseHandleDeleteFieldsSettingsSetsProps {
	selectedEntities: IPreparedFieldSettingsSet[];
	onClear: () => void;
}

export const useHandleDeleteFieldsSettingsSets = ({
	selectedEntities,
	onClear
}: IUseHandleDeleteFieldsSettingsSetsProps) => {
	const deleteFieldsSettingsSets = useDeleteFieldsSettingsSets();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить наборы',
		'Вы действительно хотите удалить выбранные наборы?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			deleteFieldsSettingsSets(selectedEntities);
		}
		onClear();
	}, [deleteFieldsSettingsSets, getDeleteConfirmation, onClear, selectedEntities]);

	return {deleteDialog, handleDelete};
};
