import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {createContext, useCallback, useMemo} from 'react';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {desktopColumns} from './columns.desktop';
import {useAsyncFn} from 'react-use';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceStatusesSetsPageSettings} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/selectors';
import {
	changeOffset,
	changeSort,
	changePageSize,
	changeSelectedRows
} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/actions';
import {SelectionClearing} from '../selection/SelectionClearing';
import {convertSpaceStatusesSets} from '../../utils/convertSpaceStatusesSets';
import {IPreparedSpaceStatusesSet} from '../../interfaces/IPreparedSpaceStatusesSet';
import {IFullSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/IFullSpaceStatusesSet';
import {SelectionActions} from '../selection/SelectionActions';
import {SelectionRow} from '../selection/SelectionRow';
import {mobileColumns} from './columns.mobile';
import {useUpdateContent} from '@src/components/EditableSpaceStatusesSet/hooks/useUpdateContent';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedSpaceStatusesSet>) => void
>(() => ({}));

const pageSizes = [10, 20, 50, 100];

interface ISpaceStatusesSetsTableProps {
	data: IFullSpaceStatusesSet[];
	currentPage: number;
	pageCount: number;
}

export const SpaceStatusesSetsTable = ({
	data,
	currentPage,
	pageCount
}: ISpaceStatusesSetsTableProps) => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();

	const settings = useAppSelector(extractSpaceStatusesSetsPageSettings);
	const {offset, pageSize, selectedRows} = settings;

	const user = useAppSelector(s => s.auth.profile);
	const preparedSets = useMemo(() => convertSpaceStatusesSets(data, user), [data, user]);

	const selectedEntities = useMemo(
		() => preparedSets.filter(set => selectedRows.includes(set.id)),
		[selectedRows, preparedSets]
	);

	const {removeSpaceStatusesSet} = useUpdateContent();

	const isTablet = useIsTablet();
	const columns = useMemo(
		() =>
			determineTableColumns(
				true,
				isTablet,
				mobileColumns,
				mobileColumns,
				desktopColumns,
				desktopColumns
			),
		[isTablet]
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedSpaceStatusesSet>) => {
			if (action.type === 'delete' && action.payload) {
				const {id} = action.payload;
				await removeSpaceStatusesSet(id);
			}
		},
		[]
	);

	const handleRowClick = useCallback(
		(row: IPreparedSpaceStatusesSet) => pushPath(`/manage/space-statuses-sets/${row.id}`),
		[]
	);

	const handleSortChange = useCallback((value: Record<string, boolean>) => {
		dispatch(changeSort(value));
	}, []);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				dispatch(changeOffset(newOffset));
			}
		},
		[offset, pageSize]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			const newOffset = Math.floor(offset / value);

			dispatch(changePageSize(value));
			dispatch(changeOffset(newOffset));
		},
		[offset]
	);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows(value)),
		[]
	);

	useAppHeader(
		{
			title: 'Наборы статусов помещений',
			mobileLeftButton:
				selectedEntities.length > 0 ? (
					<SelectionClearing onSelectedRowsChange={() => handleSelectedRowsChange([])} />
				) : undefined,
			mobileRightButtons:
				selectedEntities.length > 0 ? (
					<SelectionActions
						selectedEntities={selectedEntities}
						onClear={() => handleSelectedRowsChange([])}
					/>
				) : undefined
		},
		[selectedEntities]
	);

	return (
		<div className="space-statuses-sets__container">
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate
					className="space-statuses-sets__container-table"
					withoutPadding
				>
					<EntitiesTable
						columns={columns}
						data={preparedSets}
						selectable={isTablet}
						selectedRows={selectedRows}
						headVisible={isTablet}
						onRowClick={handleRowClick}
						onSortChange={handleSortChange}
						onSelectedRowsChange={handleSelectedRowsChange}
						renderSelectionRow={props => <SelectionRow {...props} />}
					/>
				</Plate>
			</DispatchActionCtx.Provider>
			<PaginationAndSize
				pagination={
					<Pagination
						className="space-statuses-sets__container-pagination"
						pageCount={pageCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						className="space-statuses-sets__container-page-size"
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</div>
	);
};
