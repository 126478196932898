import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IInspectionsAddingPermissions {
	canAdd?: boolean;
}

/**
 * Возвращает полномочие на создание осмотра
 *
 * @param objectId id объекта
 */
export const useInspectionsAddingPermissions = (
	objectId?: string
): IInspectionsAddingPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles || !objectId) {
			return {};
		}
		return {
			canAdd: hasPermission(user.roles, 'inspections-add', UserRoleScopes.OBJECT, objectId)
		};
	}, [objectId, user]);
};
