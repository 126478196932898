import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

export const convertStructures = (
	structures: IListStructure[],
	problemsStats: Record<string, IEntityProblemsData> | undefined,
	workAcceptancesRecord?: Record<string, IListWorkAcceptance[]>
): IListStructure[] =>
	structures.map(structure => ({
		...structure,
		problems: problemsStats?.[structure.id],
		workAcceptances: workAcceptancesRecord ? workAcceptancesRecord[structure.id] : []
	}));
