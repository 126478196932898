import {ICheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import CheckListExportDialog from '../actions/CheckListExportDialog';
import CheckListsExportDialog from '../actions/CheckListsExportDialog';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useParams} from 'react-router';

interface ICheckListExportSwitcherProps {
	spaceId: string;
	objectId: string;
	permissions: ICheckListPermissions;
	checkList?: ICheckList;
	checkLists: ICheckList[];
}

export const CheckListExportSwitcher = (props: ICheckListExportSwitcherProps) => {
	const params = useParams();
	const {spaceId, objectId, permissions, checkList, checkLists} = props;

	const shouldRenderExportDialog = !spaceId || !objectId || !permissions.canExport;
	const isSingleCheckListExport = params.listId && checkList;

	if (shouldRenderExportDialog) return null;

	if (isSingleCheckListExport) {
		return (
			<CheckListExportDialog
				objectId={objectId}
				spaceId={spaceId}
				checkListId={checkList.id}
			/>
		);
	}

	return (
		<CheckListsExportDialog
			objectId={objectId}
			spaceId={spaceId}
			checkListsIds={checkLists.map(item => item.id)}
		/>
	);
};
