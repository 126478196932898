export enum TaskPriorityId {
	HIGHEST = 'highest',
	HIGH = 'high',
	MEDIUM = 'medium',
	LOW = 'low',
	LOWEST = 'lowest'
}

export interface ITaskPriority {
	id: TaskPriorityId;
	name: string;
	color: string;
}
