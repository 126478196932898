import Description from '@src/components/editableFields/Description';
import {IDescriptionProps} from '@src/components/editableFields/Description/IDescriptionProps';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useLocalSearchProvider, useSearchSelectPopup} from '@tehzor/ui-components';
import { memo, useMemo, useRef } from 'react';
import {useTemplateSelectHandler} from '../hooks/useTemplateSelectHandler';
import {templateRenderItem} from '../utils/templateRenderItem';
import {TemplateSelectDialog} from './TemplateSelectDialog';
import {useProblemTemplatesAsArray} from '@src/core/hooks/queries/problemTemplatesSets/hooks';

interface ISearchableProblemDescriptionProps
	extends Omit<IDescriptionProps<{description: string}, IProblem>, 'onSelect' | 'addon'> {
	stage: ObjectStageIds;
	objectId: string;
}

const SearchableProblemDescription = ({
	objectId,
	editingDispatch,
	stage,
	...props
}: ISearchableProblemDescriptionProps) => {
	const inputRef = useRef<HTMLTextAreaElement>(null);
	const onSelect = useTemplateSelectHandler({objectId, editingDispatch, stage});
	const {data: object} = useObject(objectId);
	const {data: templates} = useProblemTemplatesAsArray(object);
	const filteredTemplates = useMemo(
		() => templates?.filter(el => el.stageId === stage) || [],
		[templates, stage]
	);
	const provider = useLocalSearchProvider({
		initialList: filteredTemplates,
		keys: ['description']
	});

	const [popup, handleSearchChange] = useSearchSelectPopup<IProblemTemplate>({
		inputRef,
		onSelect,
		provider,
		renderItem: templateRenderItem,
		noCheckbox: true
	});

	return (
		<>
			<Description
				ref={inputRef}
				editingDispatch={editingDispatch}
				onChange={handleSearchChange}
				addon={
					<TemplateSelectDialog
						objectId={objectId}
						editingDispatch={editingDispatch}
						stage={stage}
					/>
				}
				{...props}
			/>
			{popup}
		</>
	);
};

export default memo(SearchableProblemDescription);
