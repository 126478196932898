import {useMemo} from 'react';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';

export function formObjectsChain(
	objects: {[id: string]: IObject},
	objectId?: string,
	defaultPage: 'problems' | 'inspections' = 'problems',
	t?: TFunction
) {
	let result = [
		{
			label: t
				? t('hooks.useObjectBreadcrumbs.formObjectsChain.firstLink.label')
				: 'Все объекты',
			url: '/objects',
			inactive: false
		}
	];
	if (!objectId) {
		return result;
	}

	const object = objects[objectId];
	const hasChildren = Object.values(objects)?.some(obj => obj.ancestors?.includes(objectId));
	if (object) {
		if (object.ancestors) {
			result = result.concat(
				object.ancestors
					.filter(id => objects[id])
					.map(id => ({
						label: objects[id]?.name || '',
						url: `/objects/${id}`,
						inactive: false
					}))
			);
		}
		result.push({
			label: object?.name,
			url: hasChildren ? `/objects/${object.id}` : `/objects/${object.id}/${defaultPage}`,
			inactive: false
		});
	}
	return result;
}

/**
 * Хук для хлебных крошек от "всех объектов" до конкретного
 *
 * @param objectId id конечного объекта
 */
export function useObjectBreadcrumbs(objectId?: string) {
	const {t} = useTranslation();
	const objects = useObjectsData();
	return useMemo(
		() => (objects ? formObjectsChain(objects.byId, objectId, 'problems', t) : []),
		[objectId, objects, t]
	);
}
