import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {useCallback} from 'react';
import {useMutationErrors} from '../useMutationErrors';
import {extractLocalInternalAcceptancesJournal} from './selector';

export const useLocalInternalAcceptancesJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: internalAcceptancesQueryKeys.add()});
	const selector = useCallback(
		(internalAcceptances: IListInternalAcceptance[]) =>
			extractLocalInternalAcceptancesJournal(internalAcceptances, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IListInternalAcceptance[], unknown, IOfflineJournalEntity[]>({
		queryKey: internalAcceptancesQueryKeys.localList(),
		select: selector
	});
	return data || [];
};
