import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {extractCategoriesSetsPageSettings} from '@src/store/modules/settings/pages/manage/categoriesSets/selectors';
import { useCallback } from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {CategoriesSetsPage} from './CategoriesSetsPage';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ICategoriesSetsFiltersState} from '@src/store/modules/settings/pages/manage/categoriesSets/reducers';
import {
	changeFilters,
	changeOffset,
	clearFilters
} from '@src/store/modules/settings/pages/manage/categoriesSets/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

const CategoriesSetsPageWrap = () => {
	const dispatch = useAppDispatch();
	const {objectId} = useStrictParams<{objectId: string}>();

	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {
		sectionsMenu,
		mobileRightButtons: <AppUpdateMenu/>
	});

	const {filters} = useAppSelector(s => extractCategoriesSetsPageSettings(s, objectId));

	const applyFilters = useCallback(
		(value: ICategoriesSetsFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId, 0));
		},
		[objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [objectId]);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			objectId={objectId}
			onClear={onClear}
		>
			<CategoriesSetsPage key={objectId}/>
		</EntitiesFiltersProvider>
	);
};

export default CategoriesSetsPageWrap;
