import {EntityGridItem} from '@tehzor/ui-components';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {PriorityView} from '../PriorityView/PriorityView';
import {useTaskPrioritiesMap} from '@src/core/hooks/queries/task-priorities/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface IDescriptionProps {
	className?: string;
	task: IEnrichedTask;
}

const priorityIcon = <i className="tz-priority-highest-16" />;

export const TaskPriority = ({className, task}: IDescriptionProps) => {
	const {t} = useTranslation();
	const {data: prioritiesMap} = useTaskPrioritiesMap();
	const translatedPrioritiesMap = useTranslatedDictionaryMap(
		dictionaryKeys.taskPriorities,
		prioritiesMap
	);

	return (
		<EntityGridItem
			className={className}
			icon={priorityIcon}
			label={t('taskViewPage.taskPriority.title')}
			fullRow
		>
			{translatedPrioritiesMap && (
				<PriorityView
					value={task.taskPriority?.id || ''}
					prioritiesMap={translatedPrioritiesMap}
				/>
			)}
		</EntityGridItem>
	);
};
