export enum ProblemStatusId {
	EXPIRED = 'expired',
	WAITING = 'waiting',
	HANDLING = 'handling',
	VERIFICATION = 'verification',
	FIXED = 'fixed'
}

export interface IProblemStatus {
	id: ProblemStatusId;
	name: string;
	transitions?: ProblemStatusId[];
	color: string;
	textColor?: string;
	order?: number;
}
