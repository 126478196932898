import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {Desktop} from './components/Desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Mobile} from './components/Mobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {
	useOwnerAcceptance,
	useOwnerAcceptanceIsLoading
} from '@src/core/hooks/queries/ownerAcceptance/hooks';
import {LoadingPanel} from '@tehzor/ui-components';
import './OwnerAcceptancePage.less';

export const OwnerAcceptancePage = () => {
	useScrollRestoration();
	const {objectId, ownerAcceptanceId} = useStrictParams<{
		objectId: string;
		ownerAcceptanceId: string;
	}>();
	const isDesktop = useIsDesktop();

	const {data: ownerAcceptance} = useOwnerAcceptance(objectId, ownerAcceptanceId);
	const loading = useOwnerAcceptanceIsLoading();

	if (!ownerAcceptance) return <LoadingPanel active={loading} />;

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				ownerAcceptanceId={ownerAcceptanceId}
				ownerAcceptance={ownerAcceptance}
			/>
		);
	}

	return (
		<Mobile
			objectId={objectId}
			ownerAcceptanceId={ownerAcceptanceId}
			ownerAcceptance={ownerAcceptance}
		/>
	);
};
