import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {
	IAddWarrantyClaimResponse,
	makeWarrantyClaimAddRequest
} from '@src/api/backend/warrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {queryClient} from '@src/api/QueryClient';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';

export type IAddWarrantyClaimPayload = IAddWarrantyClaimResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddWarrantyClaimResponse) => {
	void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.lists()});

	return {
		type: types.ADD_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении обращения');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

export const addWarrantyClaimActions = {request, success, failure};

/**
 * Добавляет гарантийное обращение
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные
 */
export const addWarrantyClaim = (
	objectId: string,
	links: IOwnerAcceptance['links'] | undefined,
	fields: ISavingWarrantyClaim
) =>
	createApiAction<IAddWarrantyClaimResponse>(request, success, failure, () =>
		makeWarrantyClaimAddRequest(objectId, links, fields)
	);
