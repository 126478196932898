import {IDndItem} from '../interfaces';
import {IElementData} from '../interfaces/IElementData';
import {OverStatus} from '../interfaces/OverStatus';

/**
 * Возвращает parentId и order для элемента, который нужно переместить
 *
 * @param dragItem перемещаемый элемент
 * @param dropData данные drop элемента
 * @param sameContainer флаг указывающий совпадают ли начальный и конечный контейнеры
 * @param overStatus over статус
 */
export const getDropItem = <S extends IDndItem>(
	dragItem: S,
	dropData: IElementData<S>,
	sameContainer: boolean,
	overStatus?: OverStatus
): S => {
	const dropItem = {...dragItem};
	if (!overStatus) {
		dropItem.parentId = undefined;
		return dropItem;
	}

	if (overStatus === OverStatus.CENTER) {
		dropItem.parentId = dragItem.id !== dropData.id ? dropData.id : dragItem.parentId;
		dropItem.order = dragItem.id !== dropData.id ? 1 : dragItem.order;
		return dropItem;
	}
	if (overStatus === OverStatus.TOP && dropData.item?.order !== undefined) {
		if (dragItem.parentId === dropData.item?.parentId && sameContainer) {
			if (dragItem.order > dropData.item?.order) {
				dropItem.order =
					dragItem.id !== dropData.id ? dropData.item?.order : dragItem.order;
				return dropItem;
			}
			if (dragItem.order < dropData.item?.order) {
				dropItem.order =
					dragItem.id !== dropData.id ? dropData.item?.order - 1 : dragItem.order;
				return dropItem;
			}
		} else {
			dropItem.parentId = dropData.item.parentId;
			dropItem.order = dragItem.id !== dropData.id ? dropData.item?.order : dragItem.order;
			return dropItem;
		}
	} else if (overStatus === OverStatus.BOTTOM && dropData.item?.order !== undefined) {
		if (dragItem.parentId === dropData.item?.parentId && sameContainer) {
			if (dragItem.order > dropData.item?.order) {
				dropItem.order =
					dragItem.id !== dropData.id ? dropData.item?.order + 1 : dragItem.order;
				return dropItem;
			}
			if (dragItem.order < dropData.item?.order) {
				dropItem.order =
					dragItem.id !== dropData.id ? dropData.item?.order : dragItem.order;
				return dropItem;
			}
		} else {
			dropItem.parentId = dropData.item.parentId;
			dropItem.order =
				dragItem.id !== dropData.id ? dropData.item?.order + 1 : dragItem.order;
			return dropItem;
		}
	}
	return dropItem;
};
