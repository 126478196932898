import { memo, useCallback, useMemo, useState } from 'react';
import {useUpdateEffect} from 'react-use';
import {
	SelectPopup,
	FilterButton,
	SelectOption,
	Select2,
	SelectSearch
} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useTranslation} from "react-i18next";

interface SelectOptionItem {
	id: string;
	name: string;
}

interface IActionsFilterProps<T extends SelectOptionItem> {
	label?: string;
	actions?: string[];
	allActions: T[];
	filterName?: string;
	resetOnApply?: string[];
}

export const ActionsFilter = memo(<T extends SelectOptionItem>(props: IActionsFilterProps<T>) => {
	const {label , filterName = 'actions', actions, allActions, resetOnApply} = props;
	const {t} = useTranslation();
	const filterLabel = label || t('offlineJournalPage.actionsFilter.label');
	const [selectedActions, setSelectedActions] = useState(actions);
	const [search, setSearch] = useState('');
	const {dispatch} = useEntitiesFiltersCtx();
	const clearSearch = useCallback(() => setSearch(''), []);

	const isSearchShown = useMemo(() => allActions.length >= 8, [allActions]);
	const filteredData = useMemo(
		() => flatFilter(allActions, 'name', search),
		[allActions, search]
	);

	const handleApply = useCallback(() => {
		const changes = {[filterName]: selectedActions};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		dispatch(changes);
		clearSearch();
	}, [filterName, selectedActions, resetOnApply, dispatch, clearSearch]);

	const handleChange = useCallback(
		(v?: string[]) => {
			if (v && v.length === 0) {
				setSelectedActions(undefined);
			} else {
				setSelectedActions(v);
			}
			clearSearch();
		},
		[clearSearch]
	);

	const handleClear = useCallback(() => {
		setSelectedActions(undefined);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedActions(undefined);
		const changes = {[filterName]: undefined};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		dispatch(changes);
		clearSearch();
	}, [filterName, resetOnApply, dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedActions(actions);
		clearSearch();
	}, [clearSearch, actions]);

	useUpdateEffect(() => {
		setSelectedActions(actions);
	}, [actions]);

	return (
		<SelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedActions?.length}
			footer
			count={selectedActions?.length}
			noHeader={!isSearchShown}
			trigger={(
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel<T>(filterLabel, actions, allActions)}
					active={!!(actions && actions.length != 0)}
					onClear={handleFullClear}
				/>
			)}
			search={isSearchShown && (
				<SelectSearch
					placeholder={t('offlineJournalPage.actionsFilter.SelectSearch.placeholder')}
					value={search}
					onChange={setSearch}
				/>
			)}
			translationsProps={{
				selectClearAllBtnLabel: t('selectPopup.selectClearAllBtn.label'),
				selectApplyBtnLabel: t('selectPopup.selectApplyBtnLabel.label'),
				selectCancelBtnLabel: t('selectPopup.selectCancelBtnLabel.label'),
				selectClearBtnLabel: t('selectPopup.selectClearBtn.label'),
				selectSelectAllBtnLabel: t('selectPopup.selectSelectAllBtn.label'),
				selectSelectedCountLabel: t('selectPopup.selectedCount.label')
			}}
		>
			<Select2
				multiple
				value={selectedActions}
				onChange={handleChange}
			>
				{filteredData.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</SelectPopup>
	);
});
