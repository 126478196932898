import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';

export const getAcceptedItems = (records: ICheckRecord[], checkListId: string) => {
	const acceptedItemsIds = new Set<string>();

	for (const record of records) {
		if (
			record.links.checkItemId &&
			record.links.checkListId === checkListId &&
			record.status === CheckRecordStatusId.ACCEPTED
		) {
			acceptedItemsIds.add(record.links.checkItemId);
		}
	}

	return Array.from(acceptedItemsIds);
};
