import {useExportOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useExportOwnerAcceptance';
import {useSendExportedOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useSendExportedOwnerAcceptance';

/**
 * Скачивает файл или отправляет на email передачу собственнику
 *
 * @param email email
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param acceptanceId id передачи
 * @param problems нарушения
 * @param createDocument флаг создания документа
 */
export const useExportUnifiedOwnerAcceptance =
	() =>
	async (
		email: string | undefined,
		templateId: string,
		objectId: string,
		acceptanceId: string,
		problems?: string[],
		createDocument?: boolean
	) => {
		const sendExportedOwnerAcceptance = useSendExportedOwnerAcceptance();
		const exportOwnerAcceptance = useExportOwnerAcceptance();

		if (email) {
			await sendExportedOwnerAcceptance(
				email,
				templateId,
				objectId,
				acceptanceId,
				problems,
				createDocument
			);
		} else {
			await exportOwnerAcceptance(
				templateId,
				objectId,
				acceptanceId,
				problems,
				createDocument
			);
		}
	};
