import {CellProps} from 'react-table';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';
import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';

export const CheckListSpaceCompanyCell = ({row}: CellProps<IPreparedCheckList>) => {
	const {data: companies} = useCompaniesAsArray();
	const company = companies?.find(({id}) => id === row.original.companyId);

	if (!company) {
		return null;
	}

	return <div>{company.name}</div>;
};
