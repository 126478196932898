import {useQuery} from '@tanstack/react-query';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {
	extractWarrantyClaimProblemCommentsByProblem,
	extractWarrantyClaimProblemRepliesAsArray
} from './selectors';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

export type IGetWarrantyClaimProblemRepliesPayload = INormalizedData<IComment>;

export const useWarrantyClaimProblemsReplies = <T = IGetWarrantyClaimProblemRepliesPayload>(
	claimId?: string,
	objectId?: string,
	select?: (data: IGetWarrantyClaimProblemRepliesPayload) => T
) =>
	useQuery({
		queryKey: warrantyClaimsQueryKeys.claimProblemsReplies(
			claimId as string,
			objectId as string
		),
		select,
		enabled: !!(claimId && objectId)
	});

export const useWarrantyClaimProblemsRepliesAsArray = (claimId?: string, objectId?: string) =>
	useWarrantyClaimProblemsReplies(claimId, objectId, extractWarrantyClaimProblemRepliesAsArray);

export const useWarrantyClaimProblemCommentsByProblem = (claimId?: string, objectId?: string) =>
	useWarrantyClaimProblemsReplies(
		claimId,
		objectId,
		extractWarrantyClaimProblemCommentsByProblem
	);
