import {ReactNode} from 'react';
import {IStatus} from '../interfaces';

export const getLegendGroupItems = (
	statuses?: IStatus[],
	renderIcon?: (color?: string) => ReactNode
) => {
	if (!statuses || !renderIcon) return [];
	return statuses.map(status => ({
		id: status.id,
		title: status.name,
		icon: renderIcon(status.color)
	}));
};
