import {httpRequests} from '@tehzor/tools/api/httpRequests';
import IUser from '@tehzor/tools/interfaces/IUser';
import ISavingUserProfile from '@tehzor/tools/interfaces/users/ISavingUserProfile';

export type IEditProfileResponse = IUser;

/**
 * Изменяет данные текущего пользователя
 *
 * @param fields измененные поля
 */
export const makeProfileEditRequest = async (fields: ISavingUserProfile) =>
	(await httpRequests.withToken.post<IUser>('/profile', fields)).data;
