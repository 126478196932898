import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	CompaniesFilterMobile,
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	ObjectsFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useAppLocation} from '@src/core/hooks/useAppLocation';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {SigningDateFilterMobile} from '@src/pages/ContractsPage/components/filters/SigningDateFilterMobile';
import {IContractsFiltersState} from '@src/store/modules/settings/pages/contracts/reducers';
import {LinkButton} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {useTranslation} from 'react-i18next';
import {ContractorsFilterMobile} from '@src/components/MobileEntitiesFilters/components/ContractorsFilterMobile';

export const ContractsFiltersPage = () => {
	const {t} = useTranslation();
	const {entity = 'contracts'} = useParams<{entity?: string}>();
	const location = useAppLocation();

	const {goBack} = useChangePath();

	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IContractsFiltersState>();

	const handleApply = useCallback(() => {
		if (location.state.canGoBack) {
			apply();
			goBack();
		}
	}, [location, apply]);

	useAppHeader(
		{
			title: t('filtersPage.title'),
			showBackBtn: true,
			mobileRightButtons: isCleanable<IContractsFiltersState>(state) ? (
				<LinkButton
					label={t('filterPage.resetFiltersBtn.label')}
					onClick={mobileClear}
				/>
			) : null
		},
		[state]
	);

	useRouting(!location.state.canGoBack, 'all', entity);

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			<SigningDateFilterMobile
				from={state.signingDateFrom}
				to={state.signingDateTo}
			/>

			<CompaniesFilterMobile
				label={t('entitiesFilters.contractsFiltersPage.companiesFilter.label')}
				value={state.companyIds}
				onChange={change}
				entity={entity}
				filterName="companyIds"
			/>

			<ContractorsFilterMobile
				onChange={change}
				entity={entity}
				value={state.contractorIds}
			/>

			<ObjectsFilterMobile
				entity={entity}
				filterName="objectIds"
				value={state.objectIds}
			/>

			<CreatedByFilterMobile
				entity={entity}
				onChange={change}
				value={state.createdBy}
			/>

			<CreationDateFilterMobile
				entity={entity}
				from={state.createdAtFrom}
				to={state.createdAtTo}
			/>
		</TranslatedFilterPage>
	);
};
