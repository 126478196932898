import { useCallback, useState } from 'react';
import * as React from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import EditableSpaceAttachmentsDialog from '../EditableSpaceAttachmentsDialog';

export const useEditableSpaceAttachmentsDialog = (
	objectId: string,
	spaceId: string,
	space: ISpace
): [React.ReactNode, () => void] => {
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const dialog = isOpen ? (
		<EditableSpaceAttachmentsDialog
			objectId={objectId}
			spaceId={spaceId}
			space={space}
			isOpen={isOpen}
			onClose={close}
		/>
	) : null;

	return [dialog, open];
};
