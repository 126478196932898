import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IProblemEntity[];
	selectedClearing: () => void;
}

const SelectedEntitiesDeletion = ({
	selectedEntities,
	selectedClearing
}: ISelectedEntitiesDeletionProps) => {
	const dispatch = useAppDispatch();
	const {t} = useTranslation();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('problemsPage.selectedEntitiesDeletion.deleteDialog.title'),
		message: t('problemsPage.selectedEntitiesDeletion.deleteDialog.message')
	});
	const [, handleClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			const objectsIds: string[] = [];
			for (const problem of selectedEntities) {
				await dispatch(deleteProblem(problem.data.objectId, problem.data.id));
				objectsIds.push(problem.data.objectId);
			}
			selectedClearing();
			await queryClient.refetchQueries({queryKey: [...objectsQueryKeys.stats(), objectsIds]});
		}
	}, [selectedEntities, selectedClearing]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete" />
			</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
