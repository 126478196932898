import {queryClient} from '@src/api/QueryClient';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';

const detailsCache = [
	checksQueryKeys.details().toString(),
	internalAcceptancesQueryKeys.details().toString(),
	ownerAcceptancesQueryKeys.details().toString(),
	warrantyClaimsQueryKeys.details().toString()
];

export const removeCache = () => {
	queryClient.removeQueries({
		predicate: data =>
			!data.queryKey.includes('saving-data') &&
			!detailsCache.includes(data.queryKey.slice(0, 2).toString())
	});
};
