import {formWorkAcceptanceCheckListsLink} from './formWorkAcceptanceCheckListsLink';

interface Args {
	objectId: string;
	workAcceptanceId: string;
	listId: string;
}
/**
 * Формирует ссылку на чек-лист
 *
 * @param params
 * @param listId id чек-листа
 */
export const formWorkAcceptanceCheckListLink = ({listId, ...params}: Args) => `${formWorkAcceptanceCheckListsLink(params)}/${listId}`;
