import {memo} from 'react';
import {BaseListFilterMobile} from './BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface ITypesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	filterName: string;
	types: Array<{
		id: string;
		name: string;
	}>;
	onChange: (changes: IChanges) => void;
}

export const TypesFilterMobile = memo((props: ITypesFilterMobileProps) => {
	const {t} = useTranslation();
	const {types} = props;
	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.workAcceptanceTypes,
		types
	);

	if (!translatedOptions) return null;

	return (
		<BaseListFilterMobile
			{...props}
			options={translatedOptions}
			label={props.label ?? t('entitiesFilters.statusesFilter.workAcceptanceTypes.label')}
			filterName={props.filterName}
		/>
	);
});
