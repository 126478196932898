import './ProblemHistoryDialog.less';
import {ModalDialog} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {useEnrichedHistories} from './hooks/useEnrichedHistories';
import {getProblemStories} from '@src/store/modules/pages/problem/actions/stories';
import {getProblemCommentsStories} from '@src/store/modules/pages/problem/actions/commentsStories';
import {getProblemMarkersStories} from '@src/store/modules/pages/problem/actions/markersStories';
import {useTranslation} from 'react-i18next';
import {TranslatedHistoryList} from '@src/components/TranslatedHistoryList';

interface IProblemHistoryDialogProps {
	objectId: string;
	problemId: string;
	isOpen: boolean;
	onClose: () => void;
}

export const ProblemHistoryDialog = (props: IProblemHistoryDialogProps) => {
	const {t} = useTranslation();
	const {objectId, problemId, isOpen, onClose} = props;
	const dispatch = useAppDispatch();
	const stories = useEnrichedHistories(objectId);

	useAsync(async () => {
		if (isOpen) {
			await dispatch(getProblemStories(objectId, problemId));
			await dispatch(getProblemCommentsStories(objectId, problemId));
			await dispatch(getProblemMarkersStories(objectId, problemId));
		}
	}, [isOpen, objectId, problemId]);

	return (
		<ModalDialog
			className={{
				root: 'problem-history-dialog',
				content: 'problem-history-dialog__content'
			}}
			open={isOpen}
			title={t('components.problemHistoryDialog.title')}
			fullScreenOnMobile
			onRequestClose={onClose}
		>
			<TranslatedHistoryList
				entityName={t('components.problemHistoryDialog.historyList.entityName')}
				data={stories}
			/>
		</ModalDialog>
	);
};
