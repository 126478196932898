import {TFunction} from "i18next";
import {offlineJournalActionType} from "@src/pages/OfflineJournalPage/enums/actionsTypes";

export const getEntityActionsType = (entityType: string | undefined, tFunc: TFunction): string => {
	if(!entityType) return ""
	for (const key in offlineJournalActionType) {
		if(offlineJournalActionType[key] === entityType) {
			return tFunc(`offlineJournalActionType.${key}`)
		}
	}
	return entityType
}