import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

export const extractUserProfile = (state: IState) => state.auth.profile;

export const extractUserId = (state: IState) => state.auth.profile.id;

/**
 * Возвращает полномочия пользователя
 */
export const extractUserRoles = createSelector([extractUserProfile], user => user.roles || []);

/**
 * Возвращает массив компаний
 */
export const extractUserCompanies = createSelector(
	[extractUserProfile],
	user => user.companies?.map(company => company.id) || []
);
