import {PopupColorPicker} from '@tehzor/ui-components';

interface IColorFieldProps {
	value?: string;
	disabled?: boolean;

	onChange: (value: string) => void;
}

export const ColorField = ({
	value, disabled, onChange
}: IColorFieldProps) => (
	<div className="editable-space-statuses__status-fields__color">
		<PopupColorPicker
			className="editable-space-statuses__status-fields__color-picker"
			value={value}
			disabled={disabled}
			onChange={onChange}
			presetColors
		/>
	</div>
);