import {memo} from 'react';
import {CellProps} from 'react-table';
import styles from './FieldObjectsCell.module.less';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';

export const FieldObjectsCell = memo(({row}: Pick<CellProps<IPreparedFieldSettingsSet>, 'row'>) => {
	const objects = row.original.objects;

	return (
		<div className={styles.objects}>
			{objects.slice(0, 3).map(object => (
				<div
					key={object.id}
					className={styles.objectItem}
				>
					{object.name}
				</div>
			))}
			{objects.length > 3 && (
				<div className={styles.moreObjectsText}>+{objects.length - 3}</div>
			)}
		</div>
	);
});
