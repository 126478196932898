import classNames from 'classnames';
import { CSSProperties, useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import {SelectDialog, TextField, TreeSelectTagOption} from '../inputs';
import Tree, {ITreeDataItem} from '../Tree';
import Select from '../inputs/select/Select';
import {useUpdateEffect} from 'react-use';
import SelectSearch, {treeFilter} from '../inputs/select/SelectSearch';

export interface ITreeProblemTagsSetDataItem extends ITreeDataItem {
	color?: string;
	problemTagsSetId?: string;
	total?: number;
}

interface IProblemTagsSelectProps {
	className?: string;
	style?: CSSProperties;
	value?: string[];
	treeData: ITreeProblemTagsSetDataItem[];
	disabled?: boolean;
	multiple?: boolean;
	dialogTitle?: string;
	placeholder?: string;
	error?: string;
	icon?: React.ReactNode;
	searchPlaceholder?: string;
	dialogAcceptBtnLabel?: string;
	dialogRejectBtnLabel?: string;
	onChange?(value: string[]): void;
	onConfirm?(value: string[]): void;
}

const tagIcon = <i className="tz-tag-20"/>;

const getTitle = (treeData: ITreeProblemTagsSetDataItem[], value: string[]) => {
	if (!value.length || treeData === undefined) {
		return '';
	}
	const selected = value.map(id => treeData.find(item => item.id === id));
	const sets = treeData.filter(item => !item?.problemTagsSetId);

	let title = '';
	let titleTags = selected.filter(item => item?.problemTagsSetId);
	const titleSets: ITreeProblemTagsSetDataItem[] = [];

	for (const set of sets) {
		if (!set?.total || set?.disabled) {
			continue;
		}

		const tags = selected.filter(item => item?.problemTagsSetId === set.id);

		if (!tags.length) {
			continue;
		}

		if (tags.length >= set?.total) {
			titleTags = titleTags.filter(item => item?.problemTagsSetId !== set.id);
			titleSets.push(set);
		}
	}

	title += titleSets.map(item => item.content).join(', ');
	if (titleSets.length > 0 && titleTags.length > 0) {
		title += ', ';
	}
	title += titleTags.map(item => item?.content).join(', ');

	return title;
};

const getAllParents = (id: string, treeData: ITreeProblemTagsSetDataItem[]): string[] => {
	const item = treeData.find(item => item.id === id);

	if (!item?.parentId) {
		return [];
	}
	return [item.parentId].concat(getAllParents(item.parentId, treeData));
};

const getInitialExpanded = (
	checked: string[],
	treeData: ITreeProblemTagsSetDataItem[]
): string[] => {
	const keys: string[] = [];
	for (const id of checked) {
		if (!treeData.find(item => item.id === id)) continue;
		const parentIds = getAllParents(id, treeData);

		for (const parentId of parentIds) {
			if (!keys.includes(parentId)) {
				keys.push(parentId);
			}
		}
	}
	return keys;
};

export const ProblemTagsSelect = ({
	className,
	style,
	value = [],
	treeData,
	disabled = false,
	multiple = false,
	dialogTitle = 'Метки нарушений',
	placeholder,
	error,
	icon,
	dialogAcceptBtnLabel,
	dialogRejectBtnLabel,
	searchPlaceholder,
	onChange,
	onConfirm
}: IProblemTagsSelectProps) => {
	const [checked, setChecked] = useState<string[]>(value);
	const [expanded, setExpanded] = useState<string[] | undefined>(
		getInitialExpanded(value, treeData)
	);

	const [search, setSearch] = useState('');
	const clearSearch = useCallback(() => setSearch(''), []);

	const {filteredData, expanded: expandedValue} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpanded(expandedValue?.map(item => item.id));
	}, [expandedValue]);

	const classes = classNames('categories-set-select', className);
	const triggerClasses = classNames(
		'categories-set-select__field',
		{'categories-set-select__field-disabled': disabled},
		'text-field_interactive'
	);

	useUpdateEffect(() => {
		setChecked(value);
	}, [JSON.stringify(value)]);

	const title = useMemo(() => getTitle(treeData, checked), [treeData, checked]);

	const Trigger = (
		<TextField
			className={triggerClasses}
			elementType="div"
			value={title}
			disabled={disabled}
			placeholder={placeholder}
			error={error}
			icon={icon !== undefined ? icon : tagIcon}
		/>
	);

	const handleCheck = useCallback(
		(value: string[]) => {
			setChecked(value);
			const arr = treeData
				.filter(item => value.includes(item.id) && item.parentId)
				.map(item => item.id);

			if (onChange) {
				onChange(arr);
			}
		},
		[onChange, treeData]
	);

	const handleExpanded = useCallback((value: string[]) => {
		setExpanded(value);
	}, []);

	const handleConfirm = useCallback(() => {
		clearSearch();

		if (!onConfirm) {
			return;
		}
		onConfirm(checked);
	}, [checked, clearSearch, onConfirm]);

	return (
		<div
			className={classes}
			style={style}
		>
			{!disabled ? (
				<SelectDialog
					dataTestId="ProblemTagsSelectDialog"
					title={dialogTitle}
					trigger={Trigger}
					onChange={handleConfirm}
					onClose={clearSearch}
					acceptBtnLabel={dialogAcceptBtnLabel}
					rejectBtnLabel={dialogRejectBtnLabel}
				>
					<Select className="problem-tags-set-select__dialog">
						<SelectSearch
							value={search}
							onChange={setSearch}
							placeholder={searchPlaceholder}
						/>
						<Tree
							data={filteredData}
							checkedValue={checked}
							onCheck={handleCheck}
							expandedValue={expanded}
							onExpand={handleExpanded}
							multiple={multiple}
							isCheckable
							TreeItem={TreeSelectTagOption}
						/>
					</Select>
				</SelectDialog>
			) : (
				Trigger
			)}
		</div>
	);
};
