import styles from './LagendGroup.module.less';
import {ILegendItem} from '../interfaces';

interface ILegendGroupProps {
	title: string;
	items: ILegendItem[];
}

export const LegendGroup = ({title, items}: ILegendGroupProps) => (
	<div className={styles.wrapper}>
		<p className={styles.title}>{title}</p>
		<div className={styles.list}>
			{items.map(item => (
				<div className={styles.item} key={item.id}>
					<div className={styles.itemIconContainer}>{item.icon}</div>
					<p className={styles.itemText}>{item.title}</p>
				</div>
			))}
		</div>
	</div>
);
