import * as React from 'react';
import './DelegationHistoryItem.less';
import {IEnrichedDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IEnrichedDelegationHistory';
import {UsersOrGroup} from './components/UsersOrGroup';
import classNames from 'classnames';
import {UserActionInfo} from './components/UserActionInfo';
import {AutomaticActionInfo} from './components/AutomaticActionInfo';
import {format} from 'date-fns';

interface IDelegationHistoryItemProps {
	className?: string;
	style?: React.CSSProperties;
	data: IEnrichedDelegationHistory;
	dateFormat?: string;
	dateOptions?: Parameters<typeof format>[2];
	automaticActionLabelTitle?: string;
	automaticActionLabelIn?: string;
	userActionLabelTitle?: string;
	userActionLabelIn?: string;
}

export const DelegationHistoryItem = ({
	className,
	style,
	data,
	dateFormat,
	dateOptions,
	automaticActionLabelTitle,
	automaticActionLabelIn,
	userActionLabelTitle,
	userActionLabelIn
}: IDelegationHistoryItemProps) => {
	const {fromGroup, toGroup, toUsers, ruleId, createdBy, createdAt} = data;

	return (
		<div className={classNames('delegation-history-item', className)} style={style}>
			{ruleId !== undefined ? (
				<AutomaticActionInfo
					createdAt={createdAt}
					labelTitle={automaticActionLabelTitle}
					labelIn={automaticActionLabelIn}
					dateFormat={dateFormat}
					dateOptions={dateOptions}
				/>
			) : createdBy !== undefined ? (
				<UserActionInfo
					createdBy={createdBy}
					createdAt={createdAt}
					labelTitle={userActionLabelTitle}
					labelIn={userActionLabelIn}
					dateFormat={dateFormat}
					dateOptions={dateOptions}
				/>
			) : null}

			<div className="delegation-history-item__transition">
				<UsersOrGroup group={fromGroup} />

				{fromGroup && (toGroup || toUsers?.length) ? (
					<i className="tz-arrow-20 delegation-history-item__transition-arrow" />
				) : null}

				<UsersOrGroup users={toUsers} group={toGroup} />
			</div>
		</div>
	);
};

DelegationHistoryItem.displayName = 'DelegationHistoryItem';
