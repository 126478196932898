import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {ElementPropsType} from '@tehzor/ui-components/src/components/inputs/TextField/TextField';
import classNames from 'classnames';
import { memo, useCallback } from 'react';

const defaultTextAreaProps: ElementPropsType = {minRows: 2, maxRows: 6};

interface ITextContractFieldProps {
	className?: string;
	label?: string;
	errorMessage?: string;
	hasError?: boolean;
	required?: boolean;
	value?: string | null;
	disabled?: boolean;

	elementType?: 'input' | 'textarea';
	elementProps?: ElementPropsType;

	onChange: (value: string) => void;
	onError: () => void;
}

export const TextContractField = memo(({
	className,
	label,
	errorMessage,
	hasError,
	required,
	value,
	disabled,
	elementType = 'input',
	elementProps = defaultTextAreaProps,
	onChange,
	onError
}: ITextContractFieldProps) => {
	const handleChange = useCallback((value: string) => {
		onChange(value);
		if (required) {
			onError();
		}
	}, [onChange, required, onError]);

	return (
		<div
			className={classNames(
				'editable-contract__fields-text', className
			)}
		>
			<EditableFieldLabel
				className="editable-contract__fields-text__label"
			>
				{required ? `${label} *` : label}
			</EditableFieldLabel>

			<TextField
				value={value || undefined}
				onChange={handleChange}
				error={hasError ? errorMessage : undefined}
				elementType={disabled ? 'div' : elementType}
				elementProps={elementProps}
				disabled={disabled}
			/>
		</div>
	);
});