import {makeCheckListCopyRequest} from '@src/api/backend/checkList';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import IError from '@tehzor/tools/interfaces/IError';

const mutationFn = (id: string) => makeCheckListCopyRequest(id);

const onSuccess = () => {
	void queryClient.invalidateQueries({queryKey: checkListsQueryKeys.list()});
	void queryClient.invalidateQueries({queryKey: checkItemsQueryKeys.list()});
};

const onError = () => addErrorToast('Ошибка', 'при копировании чек-листа');

export const useCopyCheckList = () =>
	useMutation<ICheckList, IError, string>({
		mutationFn,
		mutationKey: checkListsQueryKeys.copy(),
		onSuccess,
		onError
	});
