import {useMutation} from '@tanstack/react-query';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';
import {exportTemplatesQueryKeys} from '@src/api/cache/exportTemplates/keys';
import {makeExportTemplateDeleteRequest} from '@src/api/backend/exportTemplates/delete';

export const useDeleteExportTemplate = () =>
	useMutation({
		mutationKey: exportTemplatesQueryKeys.delete(),
		mutationFn: (templateId: string) => makeExportTemplateDeleteRequest(templateId),
		onError: () => {
			addErrorToast('Ошибка', 'при удалении шаблона экспорта');
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: exportTemplatesQueryKeys.list()});
			addSuccessToast('Удалено', 'шаблон экспорта успешно удален');
		}
	});
