import useAppSelector from '@src/core/hooks/useAppSelector';
import {UserStats} from './UserStats';
import StatsTreePlaceholder from '../../StatsTreePlaceholder';
import {useFilteredInspectors} from '@src/pages/StatisticsPage/hooks/useFilteredInspectors';
import ProblemsStatsChart from './ProblemsStatsChart';
import {
	aggregateByUsers,
	extractStatsData
} from '@src/store/modules/statistics/warranty/selectors/problemsByStatuses';
import {byObjects} from '@src/utils/statsAggregators/problemsByStatuses';
import {areProblemsByStatusesStatsLoading} from '@src/store/modules/statistics/ui/selectors/warranty';
import {isWarrantyFiltersSetUp} from '@src/store/modules/settings/pages/statistics/warranty/selectors/filters';

const ByUsersStats = () => {
	const stats = useAppSelector(extractStatsData);
	const usersStats = useAppSelector(aggregateByUsers);
	const filters = useAppSelector(s => s.settings.pages.statistics.warranty.filters);
	const isFiltersSetUp = useAppSelector(isWarrantyFiltersSetUp);
	const users = useFilteredInspectors(
		usersStats,
		userId => byObjects(stats.filter(item => item.userId === userId)),
		filters,
		isFiltersSetUp
	);
	const loading = useAppSelector(areProblemsByStatusesStatsLoading);

	return (
		<>
			{users.map(({user, userStats, objects, objectsStats}) => (
				<UserStats
					key={user.id}
					user={user}
					userStats={userStats}
					objects={objects}
					objectsStats={objectsStats}
				/>
			))}

			{loading && users.length === 0 && (
				<StatsTreePlaceholder
					includeSubTitle
					includeChildren
					permanentContent={<ProblemsStatsChart/>}
				/>
			)}
		</>
	);
};

export default ByUsersStats;
