import React, {useCallback, useState} from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {useCommentsCtx} from '../utils/CommentsCtx';

export function useCommentDeleting(): [React.ReactNode, (comment: IComment) => Promise<void>] {
	const {deleteReply, deleteComment} = useCommentsCtx();
	const [commentToDelete, setDeletingComment] = useState<IComment>();

	const [dialog, getConfirmation] = useConfirmDialog(
		commentToDelete?.official ? 'Удалить ответ' : 'Удалить комментарий',
		commentToDelete?.official
			? `Вы действительно хотите удалить ответ${
					commentToDelete.number ? ` №${commentToDelete.number}` : ''
			  }?`
			: 'Вы действительно хотите удалить комментарий?',
		{
			acceptBtnProps: {type: 'accent-red'}
		}
	);

	const deletion = useCallback(
		async (comment: IComment) => {
			setDeletingComment(comment);
			if (await getConfirmation()) {
				comment.official ? deleteReply(comment.id) : deleteComment(comment.id);
			}
		},
		[deleteComment, deleteReply, getConfirmation]
	);

	return [dialog, deletion];
}
