import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddProblemTagsSetResponse = IProblemTagsSet;

/**
 * Добавляет набор меток нарушений
 *
 * @param fields данные набора
 */
export const makeProblemTagsSetAddRequest = async (fields: ISavingProblemTagsSet) => {
	const response = await httpRequests.withToken.post<IAddProblemTagsSetResponse>(
		'problem-tags-sets',
		fields
	);
	return response.data;
};
