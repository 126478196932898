import {memo} from 'react';
import {MeterConsumptions} from '@src/components/MeterConsumptions';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {MeterActions} from '../actions/MeterActions';
import {useTranslation} from "react-i18next";

interface IMeterProps {
	meter: IMeter;
}

export const Meter = memo((props: IMeterProps) => {
	const {t} = useTranslation();
	const {meter} = props;

	return (
		<div className="space-page__meter">
			<div className="space-page__meter-serial-number">
				{meter.serialNumber ? meter.serialNumber : t('spacePage.info.meter.withoutNumber')}
				{meter.description && ` (${meter.description})`}
				<MeterConsumptions meter={meter} />
			</div>
			<MeterActions meter={meter} />
		</div>
	);
});
