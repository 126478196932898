import {isServiceWorkerSupported} from '../utils/isServiceWorkerSupported';
import {useNetworkStatusTracker} from './useNetworkStatusTracker';
import {useCachingStateTracker} from './useCachingStateTracker';

console.log('sw support', isServiceWorkerSupported());

/**
 * Хук для регистрации service worker'а и механизмов отслеживания статусов для автономной работы
 */
export function useOfflineMode() {
	useNetworkStatusTracker();
	useCachingStateTracker();
}
