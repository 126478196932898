import classNames from 'classnames';
import { KeyboardEvent, memo, useCallback } from 'react';
import {Row} from 'react-table';
import {useDndCtx} from '../../DndContext';
import {useDndHandle} from '../../hooks/useDndHandle';
import {useDndItem} from '../../hooks/useDndItem';
import {IDndItem, OverStatus} from '../../interfaces';

interface IDndTableRowProps<S extends IDndItem> {
	className?: {
		bodyRow?: string;
		bodyCell?: string;
		bodyDragCell?: string;
	};
	container?: string;
	row: Row<S>;
	isOverItem?: boolean;
	overStatus?: OverStatus;
	draggableIds?: string[];

	onRowClick?: (data: Row<S>) => void;
	prepareRow: (row: Row<S>) => void;
}

type IDndTableRowType = <S extends IDndItem>(
	props: IDndTableRowProps<S>
) => JSX.Element | null;

export const DndTableRow = memo(<S extends IDndItem>({
	className,
	container,
	row,
	isOverItem,
	overStatus,
	draggableIds,
	onRowClick,
	prepareRow
}: IDndTableRowProps<S>) => {
	prepareRow(row);

	const {dragState} = useDndCtx<S>();
	const {item: dragItem, isDragging} = dragState;
	const {id} = row.original;

	const isDraggable = draggableIds ? draggableIds.includes(id) : true;

	const itemRef = useDndItem<HTMLTableRowElement>(id, container, isDragging);
	const handleRef = useDndHandle<HTMLTableCellElement>(isDragging);

	const handleRowClick = useCallback(() => {
		if (onRowClick) {
			onRowClick(row);
		}
	}, [onRowClick, row]);

	const handleKeyPress = useCallback((e: KeyboardEvent) => {
		if (e.code === 'Space' || e.code === 'Enter') {
			handleRowClick();
		}
	}, [handleRowClick]);

	return (
		<>
			{isOverItem && overStatus === OverStatus.TOP && (
				<tr className="dnd-table__body-row-top"/>
			)}
			<tr
				{...row.getRowProps()}
				className={classNames(
					{isDragging: isDragging && dragItem?.id === id},
					className?.bodyRow,
					'dnd-table__body-row'
				)}
				ref={itemRef}
			>
				<td
					className={classNames(
						className?.bodyCell,
						className?.bodyDragCell,
						'dnd-table__body-row__cell',
						'dnd-table__body-row__cell-drag'
					)}
				>
					{isDraggable && (
						<i
							className={classNames(
								'tz-drag-and-drop-24',
								'dnd-table__body-row__cell-drag-handle'
							)}
							ref={handleRef}
						/>
					)}
				</td>
				{row.cells.map(cell => (
					<td
						{...cell.getCellProps()}
						className={classNames(
							'dnd-table__body-row__cell',
							className?.bodyCell,
							cell.column.className
						)}
						onClick={handleRowClick}
						onKeyDown={handleKeyPress}
						role="gridcell"
					>
						{cell.render('Cell')}
					</td>
				))}
			</tr>
			{isOverItem && overStatus === OverStatus.BOTTOM && (
				<tr className="dnd-table__body-row-bottom"/>
			)}
		</>
	);
}) as IDndTableRowType;
