export enum MeterTypeId {
	WATER_COLD = 'water-cold',
	WATER_HOT = 'water-hot',
	ELECTRICITY = 'electricity',
	HEAT = 'heat',
	COOLING_SYSTEM = 'cooling-system',
	GAS = 'gas'
}

export interface IMeterType {
	id: MeterTypeId;
	name: string;
	measureUnit: string;
}
