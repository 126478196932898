import {unitsQueryKeys} from '@src/api/cache/units/keys';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {legalsQueryKeys} from '@src/api/cache/legals/keys';
import {bankAccountsQueryKeys} from '@src/api/cache/bankAccounts/keys';
import {authorizedPersonsQueryKeys} from '@src/api/cache/authorizedPersons/keys';
import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {queryClient} from '@src/api/QueryClient';
import {fieldsQueryKeys} from '@src/api/cache/fields/keys';
import {fieldSettingsQueryKeys} from '@src/api/cache/fieldSettings/keys';
import {fieldSettingsSetsQueryKeys} from '@src/api/cache/fieldSettingsSets/keys';
import {regulatoryStandardsQueryKeys} from '@src/api/cache/regulatoryStandards/keys';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import {contractFormsQueryKeys} from '@src/api/cache/сontractForms/keys';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {exportTemplatesQueryKeys} from '@src/api/cache/exportTemplates/keys';
import {problemTagsSetsQueryKeys} from '@src/api/cache/problemTagsSets/keys';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {spaceTypesDecorationsQueryKeys} from '@src/api/cache/spaceTypeDecorations/keys';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';

interface IResourceMap extends Record<string, string[][]> {
	all: string[][];
}

const resourceMap: IResourceMap = {
	all: [
		unitsQueryKeys.list(),
		regulatoryStandardsQueryKeys.list(),
		contractFormsQueryKeys.lists(),
		restDictionariesQueryKeys.users(),
		documentsQueryKeys.types(),
		exportTemplatesQueryKeys.list()
	],
	'fields-view': [
		fieldsQueryKeys.builtinLists(),
		fieldsQueryKeys.customLists(),
		fieldsQueryKeys.types.entityTypes(),
		fieldsQueryKeys.types.valueTypes()
	],
	'working-groups-view': [
		restDictionariesQueryKeys.workingGroupsTypes(),
		workingGroupsQueryKeys.list()
	],
	'field-settings-view': [fieldSettingsQueryKeys.lists()],
	'field-settings-sets-view': [fieldSettingsSetsQueryKeys.lists()],
	'owner-acceptances-view': [
		restDictionariesQueryKeys.ownerAcceptanceResolutions(),
		restDictionariesQueryKeys.ownerAcceptanceStatuses()
	],
	'legals-view': [
		legalsQueryKeys.list(),
		bankAccountsQueryKeys.list(),
		authorizedPersonsQueryKeys.list()
	],
	'warranty-claims-view': [restDictionariesQueryKeys.warrantyClaimStatuses()],
	objectsView: [restDictionariesQueryKeys.objectStages()],
	'spaces-view': [restDictionariesQueryKeys.spaceTypes()],
	'check-records-view': [restDictionariesQueryKeys.checkRecordStatuses()],
	problemsView: [restDictionariesQueryKeys.problemStatuses()],
	'problem-tags-sets-view': [problemTagsSetsQueryKeys.list()],
	'problem-templates-sets-view': [restDictionariesQueryKeys.problemTemplatesSets()],
	'export-templates-edit': [restDictionariesQueryKeys.exportTemplatesDestination()],
	'tasks-view': [
		restDictionariesQueryKeys.taskTypes(),
		restDictionariesQueryKeys.taskStatuses(),
		restDictionariesQueryKeys.taskPriorities()
	],
	'work-acceptances-view': [
		restDictionariesQueryKeys.workAcceptanceFrontTypes(),
		restDictionariesQueryKeys.workAcceptanceStatuses(),
		restDictionariesQueryKeys.workAcceptanceTypes()
	],
	'meters-view': [
		restDictionariesQueryKeys.meterTariffs(),
		restDictionariesQueryKeys.meterTypes()
	],
	'structures-view': [restDictionariesQueryKeys.structureTypes()],
	spaceIndicatorsSetsView: [restDictionariesQueryKeys.spaceIndicatorsSets()],
	spaceStatusesSetsView: [spaceStatusesSetsQueryKeys.list(), spaceStatusesQueryKeys.list()],
	'responsibility-rules-view': [responsibilityRulesQueryKeys.list()],
	'space-type-decoration-view': [spaceTypesDecorationsQueryKeys.list()],
	'space-type-decoration-sets-view': [spaceTypeDecorationSetsQueryKeys.lists()]
};

export const prefetchInitialResources = async (roles: IUserRole[]) => {
	const promisesGroup = [];
	const prefetchPromises = [];
	for (const permission of Object.keys(resourceMap)) {
		if (!permission) continue;

		const resourceKeys = resourceMap[permission];

		if (hasPermission(roles, permission) || permission === 'all') {
			// Запросы для прав которые содержат всего один ключ собираем в один массив
			if (resourceKeys.length === 1) {
				promisesGroup.push(async () =>
					queryClient.prefetchQuery({queryKey: resourceKeys[0]})
				);
				continue;
			}
			for (const key of resourceKeys) {
				prefetchPromises.push(async () => queryClient.prefetchQuery({queryKey: key}));
			}
			await Promise.allSettled(prefetchPromises.map(async query => query()));
			prefetchPromises.length = 0;
		}
	}
	await Promise.allSettled(promisesGroup.map(async query => query()));
};
