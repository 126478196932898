import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '../StatusesFilterPage';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

const StagesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const {data: objectStages} = useObjectStagesAsArray();
	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.objectStages,
		objectStages
	);

	if (!objectStages) {
		return null;
	}
	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={translatedOptions}
			label={t('entitiesFilters.stagesFilter.label')}
			filterName="stages"
		/>
	);
};

export default StagesFilterPage;
