import { useCallback } from 'react';
import ButtonBase from '../../../buttons/ButtonBase';

interface IPresetButtonProps {
	presetColor: string;
	onChange: (value: string) => void;
	onClose: () => void;
}

export const PresetButton = ({presetColor, onChange, onClose}: IPresetButtonProps) => {
	const handleChange = useCallback(() => {
		onChange(presetColor);
		onClose();
	}, [presetColor, onChange, onClose]);

	return (
		<ButtonBase
			className="colorpicker__preset-button"
			style={{backgroundColor: presetColor}}
			classNamePrefix="color-pciker-button"
			outerTagType="button"
			onClick={handleChange}
		/>
	);
};