import {Dialog, IconButton, PopupPlate} from '@tehzor/ui-components';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import { useCallback, useState } from 'react';
import {LegendContent} from './LegendContent';

export const LegendButton = () => {
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const isDesktop = useIsTablet();

	return isDesktop ? (
		<PopupPlate
			className="objects-page__legend-popup"
			trigger={({ref}) => (
				<IconButton
					ref={ref}
					className="objects-page__legend-button"
					type="common"
					active={isOpen}
					onClick={open}
				>
					<i className="tz-info-24"/>
				</IconButton>
			)}
			arrowVisible
			isOpen={isOpen}
			placement="bottom-end"
			onOutsideClick={close}
			onDisappear={close}
		>
			<LegendContent/>
		</PopupPlate>
	) : (
		<>
			<IconButton
				className="objects-page__legend-button"
				active={isOpen}
				type="transparent"
				onClick={open}
			>
				<i className="tz-info-24"/>
			</IconButton>

			<Dialog
				className={{root: 'objects-page__legend-dialog'}}
				isOpen={isOpen}
				onRequestClose={close}
				hideCloseButton
			>
				<LegendContent/>
			</Dialog>
		</>
	);
};