import './WarrantyClaimInfoMobileCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import WarrantyClaimStatusCell from '../WarrantyClaimStatusCell';
import WarrantyClaimInfo from '../WarrantyClaimInfo';
import {useTranslation} from 'react-i18next';

const WarrantyClaimInfoMobileCell = (props: CellProps<{data: IWarrantyClaim}>) => {
	const {row} = props;
	const {data} = row.original;
	const {t} = useTranslation();

	return (
		<div className="m-wclaim-info-cell">
			<div className="m-wclaim-info-cell__header">
				<WarrantyClaimInfo
					data={data}
					title={t('warrantyClaimInfoCell.mobile.title')}
				/>

				<ClickPreventWrap style={{marginLeft: '8px'}}>
					<TableRowSelect
						{...row.getToggleRowSelectedProps()}
						style={{marginTop: '12px'}}
					/>
				</ClickPreventWrap>
			</div>

			{data.spaceOwner !== undefined && (
				<div className="m-wclaim-info-cell__extra-info">{data.spaceOwner.name}</div>
			)}

			{data.replyEmail !== undefined && (
				<ClickPreventWrap className="m-wclaim-info-cell__extra-info">
					<a href={`mailto:${data.replyEmail}`}>{data.replyEmail}</a>
				</ClickPreventWrap>
			)}

			{data.replyPhone !== undefined && (
				<ClickPreventWrap className="m-wclaim-info-cell__extra-info">
					<a href={`tel:${data.replyPhone}`}>{data.replyPhone}</a>
				</ClickPreventWrap>
			)}
			{data.claimerName !== undefined && (
				<ClickPreventWrap className="m-wclaim-info-cell__extra-info">
					<a href={`tel:${data.claimerName}`}>{data.claimerName}</a>
				</ClickPreventWrap>
			)}

			<div style={{marginTop: '16px'}}>
				<WarrantyClaimStatusCell {...props} />
			</div>
		</div>
	);
};

export default WarrantyClaimInfoMobileCell;
