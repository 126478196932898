import {IState} from '@src/store/modules';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetStructureResponse, requestStructure} from '@src/api/backend/structure/get';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {IStructureState} from '@src/store/modules/entities/structure/reducers';

export type IGetStructurePayload = IGetStructureResponse;

const request = (objectId: string, structureId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId, structureId}
});

const success = (response: IGetStructureResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке структуры');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Возвращает структуру по id
 */
export const getStructure
	= (objectId: string, structureId: string): AppThunkAction<Promise<IGetStructureResponse | undefined>> =>
	(dispatch, getState) => {
		const state = getState();

		const apiAction = createApiAction<IGetStructureResponse>(
			() => request(objectId, structureId),
			success,
			failure,
			async () => requestStructure(objectId, structureId)
		);

		return state.entities.structure.data?.id === structureId
			? dispatch(
					checkLoaded<IState, IGetStructureResponse, ApiAction, IStructureState>(
						s => s.entities.structure,
						apiAction,
						s => s.data
					)
			  )
			: dispatch(apiAction);
	};
