import {AppDispatch} from '@src/store/appStore';
import {wsConnector} from '@src/api/backend/wsConnector';
import {changeNetworkStatus} from '@src/store/modules/offlineMode/actions';
import {onlineManager} from '@tanstack/react-query';

export class NetworkStatusTracker {
	private dispatch: AppDispatch;

	constructor(dispatch: AppDispatch) {
		this.dispatch = dispatch;

		if (wsConnector.isOnline() && wsConnector.isConnected()) {
			onlineManager.setOnline(true);
			this.dispatch(changeNetworkStatus(true));
		}
	}

	start = () => {
		wsConnector.addEventListener('connect', this.handleWsConnect);
		wsConnector.addEventListener('disconnect', this.handleWsDisconnect);
	};

	stop = () => {
		wsConnector.removeEventListener('connect', this.handleWsConnect);
		wsConnector.removeEventListener('disconnect', this.handleWsDisconnect);
	};

	private handleWsConnect = () => {
		console.log('connect to api');
		onlineManager.setOnline(true);
		this.dispatch(changeNetworkStatus(true));
	};

	private handleWsDisconnect = () => {
		console.log('disconnect from api');
		onlineManager.setOnline(false);
		this.dispatch(changeNetworkStatus(false));
	};
}
