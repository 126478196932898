import {Category} from '@src/components/viewFields/Category';
import Description from '@src/components/viewFields/Description';
import Location from '@src/components/viewFields/Location';
import MobileImages from '@src/pages/InspectionPage/components/attachments/Images.mobile';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomField} from '@src/components/CustomField';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {InspectionPerformers} from './InspectionPerformers';

interface IMobileInfoProps {
	objectId: string;
	inspection: ILinkedInspection;
	images?: string[];
	settings?: IEntitySettings;

	onImageClick?: (index: number) => void;
}

const MobileInfo = ({objectId, inspection, images, settings, onImageClick}: IMobileInfoProps) => {
	const {t} = useTranslation();
	const custom = settings?.custom;

	return (
		<EntityGrid withBorders>
			{inspection.description && <Description value={inspection.description} />}
			{images !== undefined && images.length > 0 && (
				<MobileImages
					images={images}
					onClick={onImageClick}
				/>
			)}
			{(!!inspection.plan || !!inspection.floor || !!inspection.location) && (
				<Location
					objectId={objectId}
					planId={inspection.plan?.id}
					floor={inspection.floor}
					location={inspection.location}
				/>
			)}
			{inspection.categoryId && <Category categoryId={inspection.categoryId} />}
			{inspection.performers?.length || inspection.performersActiveGroup ? (
				<InspectionPerformers inspection={inspection} />
			) : null}
			{custom &&
				Object.values(custom).map(customSetting => (
					<CustomField
						fields={inspection.customFields}
						setting={customSetting}
					/>
				))}
			{inspection.createdAt !== inspection.modifiedAt && (
				<EditorInfo
					icon={<i className="tz-edit-20" />}
					label={t('inspectionPage.info.modifiedAt.label')}
					date={inspection.modifiedAt}
					user={inspection.modifiedBy}
				/>
			)}
		</EntityGrid>
	);
};

export default memo(MobileInfo);
