import {OfflineDataItem} from '@tehzor/ui-components';
import {IDictionaryCacheState} from '@src/store/modules/offlineMode/reducers/dictionariesCacheState';
import {OfflineDataCachingStatus} from '@tehzor/tools/contracts/dataCachingWebWorker/interfaces/IOfflineDataCachingStatuses';
import {someInProgress} from '../utils/someInProgress';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useCacheStates} from '../hooks/useCacheStates';
import {useTranslation} from "react-i18next";

interface IObjectsCacheStatusProps {
	filesCacheState?: IDictionaryCacheState;
}

export const ObjectsCacheStatus = ({filesCacheState}: IObjectsCacheStatusProps) => {
	const {t} = useTranslation();

	const [isFetching, isError, isStale, isPaused, dataUpdatedAt] = useCacheStates(
		objectsQueryKeys.list()
	);
	const error
		= isError && filesCacheState?.status === OfflineDataCachingStatus.ERROR
			? t('cacheStatusErrors.error')
			: isStale
			? t('cacheStatusErrors.stale')
			: isError
			? t('cacheStatusErrors.data')
			: filesCacheState?.status === OfflineDataCachingStatus.ERROR
			? t('cacheStatusErrors.files')
			: undefined;

	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.title')}
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={error}
			inProgress={!!isFetching || isPaused || someInProgress([filesCacheState])}
			progressValue={filesCacheState?.progress}
			progressTitle={
				!!isFetching && filesCacheState?.status === OfflineDataCachingStatus.LOADING
					? t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle')
					: t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default')
			}
		/>
	);
};
