import {isServiceWorkerSupported} from './utils/isServiceWorkerSupported';

export class ServiceWorkerRegistration {
	constructor() {
		void this.register();
	}

	/**
	 * Возвращает promise для ожидания завершения регистрации service worker'а (или ошибки)
	 */
	waitRegistration = async () => {
		if (isServiceWorkerSupported()) {
			return navigator.serviceWorker.ready;
		}
		return undefined;
	};

	/**
	 * Регистрирует service worker
	 */
	private register = async () => {
		if (isServiceWorkerSupported()) {
			try {
				await navigator.serviceWorker.register('/service-worker.js', {scope: '/'});
				return true;
			} catch (error) {
				console.log('Service worker registration failed:', error);
				return false;
			}
		} else {
			console.log('Service workers are not supported. Update your browser or device.');
			return false;
		}
	};
}

export const swRegistration = new ServiceWorkerRegistration();
