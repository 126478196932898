import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LoadingPanel} from '@tehzor/ui-components';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import './CategoriesSetPage.less';
import {useCategoriesSetBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCategoriesSetBreadcrumbs';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import classNames from 'classnames';
import CategoriesSetContent from './components/CategoriesSetContent';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useExtractCategoriesSetById} from '@src/core/hooks/queries/categorySets/hook';
import {useExtractCategoriesAsArrayBySetId} from '@src/core/hooks/queries/categories/hook';

const CategoriesSetPage = () => {
	useScrollRestoration();

	const {objectId, categoriesSetId} = useStrictParams<{
		objectId: string;
		categoriesSetId: string;
	}>();
	const breadcrumbs: IBreadcrumb[] = useCategoriesSetBreadcrumbs(objectId, categoriesSetId);

	const {data: categoriesSet, isLoading: categoriesSetAreLoading} =
		useExtractCategoriesSetById(categoriesSetId);
	const {data: categories, isLoading: categoriesAreLoading} =
		useExtractCategoriesAsArrayBySetId(categoriesSetId);

	useAppHeader(
		{
			title: categoriesSet?.name || 'Новый набор видов работ',
			showBackBtn: true
		},
		[categoriesSet]
	);

	return (
		<LoadingPanel
			className={classNames('categories-set__loading-panel', 'page-cont')}
			active={categoriesSetAreLoading || categoriesAreLoading}
		>
			{categoriesSet && categories && (
				<div className="categories-set">
					<AppBreadcrumbs
						className="categories-set__breadcrumbs"
						items={breadcrumbs}
					/>
					<CategoriesSetContent
						categories={categories}
						categoriesSet={categoriesSet}
					/>
				</div>
			)}
		</LoadingPanel>
	);
};

export default CategoriesSetPage;
