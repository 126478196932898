import { useState } from 'react';
import {PhotoCarousel, PhotoNavigation, Plate} from '@tehzor/ui-components';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';

interface IDesktopImagesProps {
	attachments: IAttachment[];

	onImageClick?: (index: number) => void;
}

const DesktopImages = ({attachments, onImageClick}: IDesktopImagesProps) => {
	const images = useAttachmentsImages(attachments);

	const [active, setActive] = useState(0);

	return images.length > 0 ? (
		<Plate className="problem-page__d-images">
			<PhotoCarousel
				className={{
					root: 'problem-page__d-images-carousel',
					image: 'problem-page__d-images-carousel-item'
				}}
				data={images}
				value={active}
				useArrowsNavigation
				heightRatio={1}
				onChange={setActive}
				onClick={onImageClick}
			/>

			{images.length > 1 && (
				<PhotoNavigation
					className="problem-page__d-images-nav"
					data={images}
					value={active}
					onChange={setActive}
				/>
			)}
		</Plate>
	) : null;
};

export default DesktopImages;
