import React, {useEffect, useRef, useState} from 'react';
import './EditableComment.less';
import classNames from 'classnames';
import TextInput from './components/TextInput';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {
	convertToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {
	convertToSave,
	isEdited,
	useEditableCommentState
} from '@src/core/hooks/states/useEditableCommentState';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import Attachments from '../editableFields/Attachments';
import {CommentInput} from '@tehzor/ui-components';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useCommentsCtx} from '../Comments/utils/CommentsCtx';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {useTranslation} from 'react-i18next';

interface IEditableCommentProps {
	className?: string;
	style?: React.CSSProperties;
	objectId: string;
	comment?: IComment;
	links?: IComment['links'];
	parentId?: string;

	onClearReference: () => void;
}

export const EditableComment = ({
	className,
	style,
	objectId,
	comment,
	links,
	parentId,
	onClearReference
}: IEditableCommentProps) => {

	const {t} = useTranslation();
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const [editingState, editingDispatch] = useEditableCommentState(comment);
	const [uploadingFiles, upFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);
	const textInputRef = useRef<CommentInput>(null);
	const {addComment, editComment} = useCommentsCtx();
	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, comment) || isFilesExist(uploadingFiles.value));
	}, [editingState, uploadingFiles.value, comment]);

	// Установка фокуса на поле ввода при создании компонента,
	// если изначально были переданы id комментария (для редактирования)
	// или его родителя (для добавления вложенного)
	useEffect(() => {
		if (comment || parentId) {
			if (textInputRef.current) {
				textInputRef.current.focus();
			}
		}
	}, []);

	// Установка фокуса на поле ввода при изменении ссылок на добавляемый/редактируемый комментарий
	useUpdateEffect(() => {
		if (textInputRef.current) {
			textInputRef.current.focus();
		}
	}, [comment, parentId]);

	useUpdateEffect(() => {
		editingDispatch({type: 'reset', entity: comment});
		upFilesDispatch({type: 'reset'});
	}, [comment]);

	const [savingState, save] = useAsyncFn(async () => {
		const files = await waitUploading();
		// Проверка, были ли отредактированы поля
		if (
			!isEdited(editingState, comment) &&
			(!isFilesExist(files) || someFilesHaveError(files))
		) {
			return;
		}

		if (!networkStatus) {
			// Локальное сохранение
			const savingData = convertToSave(editingState, comment, true);
			savingData.newAttachments = convertToLocalSave(files);
			// TODO Локальное сохранение
		} else {
			const savingData = convertToSave(editingState, comment, true);
			savingData.newAttachments = convertFilesToSave(files);
			savingData.parentId = parentId;

			try {
				if (comment === undefined) {
					await addComment(savingData);
				} else {
					await editComment(comment.id, savingData);
				}

				editingDispatch({type: 'reset'});
				upFilesDispatch({type: 'reset'});
				onClearReference();
			} catch (error) {
				console.log(error);
				// TODO Локальное сохранение
			}
		}
	}, [editingState, comment, parentId, networkStatus, objectId, links, onClearReference]);

	return (
		<div
			className={classNames('editable-comment', className)}
			style={style}
		>
			<TextInput
				description={editingState.description}
				editingDispatch={editingDispatch}
				uploadingFilesDispatch={upFilesDispatch}
				isSaving={savingState.loading}
				onSave={save}
				ref={textInputRef}
			/>

			{(editingState.attachments.length > 0 || uploadingFiles.value.length > 0) && (
				<Attachments
					className={{
						root: 'editable-comment__attachments',
						scrollArea: 'editable-comment__attachments-scroll-area'
					}}
					imagesTitle={t('components.editableProblemComment.attachments.imagesTitle')}
					attachmentsDestination={AttachmentFileDestination.COMMENT_OR_REPLY}
					entityId={comment?.id}
					attachments={editingState.attachments}
					uploadingFiles={uploadingFiles.value}
					editingDispatch={editingDispatch}
					canDraw
					uploadingFilesDispatch={upFilesDispatch}
					disabled={savingState.loading}
				/>
			)}
		</div>
	);
};