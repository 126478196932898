import {memo} from 'react';
import {CellProps} from 'react-table';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';

const pdfIcon = <i className="tz-file-type-pdf" />;
const docIcon = <i className="tz-file-type-doc" />;
const xlsIcon = <i className="tz-file-type-xls" />;

export const MobileDocumentCell = memo(({row}: CellProps<IDocument>) => {
	let icon: JSX.Element | null = null;

	if (row.original.fileType === 'xls' || row.original.fileType === 'xlsx') {
		icon = xlsIcon;
	}

	if (row.original.fileType === 'doc' || row.original.fileType === 'docx') {
		icon = docIcon;
	}

	if (row.original.fileType === 'pdf') {
		icon = pdfIcon;
	}

	const exportTemplate =
		!!(row.original.exportTemplate && row.original.exportTemplateId) &&
		row.original.exportTemplate[row.original.exportTemplateId]?.name;

	const company =
		!!(row.original.company && row.original.companyId) &&
		row.original.company[row.original.companyId]?.name;

	const contractor =
		!!(row.original.contractor && row.original.contractorId) &&
		row.original.contractor[row.original.contractorId]?.name;

	return (
		<div>
			<div className="documents-page__mobile_cell_name">
				{`${row.original.type?.name || 'Документ'} №${row.original.number}`}
				{icon}
			</div>
			<div>{exportTemplate}</div>
			<div className="documents-page__mobile_cell_company">Организация: {company}</div>
			<div className="documents-page__mobile_cell_company">Подрядчик: {contractor}</div>
			<div className="documents-page__mobile_cell_created">
				<div>Сформировано:</div>

				<div>
					{format(parseInt(row.original.createdAt), dateTimeCommaSeparatedFormat)}{' '}
					{row.original.createdBy && row.original.createdByUser
						? row.original.createdByUser[row.original.createdBy]?.name
						: ''}
				</div>
			</div>
		</div>
	);
});
