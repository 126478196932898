import './EntityLoadingStatus.less';

interface IEntityLoadingStatusProps {
	icon?: string;
	color?: string;
	title?: string;
}

const EntityLoadingStatus = (props: IEntityLoadingStatusProps) => {
	const {title, icon, color} = props;

	return (
		<div
			className="entity-loading-status"
			style={{backgroundColor: color}}
		>
			<i className={`${icon}`}/>
			<div className="entity-loading-status__text">{title}</div>
		</div>
	);
};

EntityLoadingStatus.displayName = 'EntityLoadingStatus';

export default EntityLoadingStatus;
