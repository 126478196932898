import {ITask} from "@tehzor/tools/interfaces/tasks/ITask";
import {IObject} from "@tehzor/tools/interfaces/objects/IObject";
import {ITaskStatus} from "@tehzor/tools/interfaces/tasks/ITaskStatus";
import {ITaskPriority} from "@tehzor/tools/interfaces/tasks/ITaskPriority";
import {ITaskType} from "@tehzor/tools/interfaces/tasks/ITaskType";
import {IBriefUser} from "@tehzor/tools/interfaces/users/IBriefUser";
import {dateTimeCommaSeparatedFormat} from "@tehzor/tools/utils/dateFormats";
import {format} from 'date-fns';

interface IUseEnrichTaskProps {
	task: ITask;
	objectsMap?:  Record<string, IObject>;
	taskStatusesMap?:  Record<string, ITaskStatus>;
	taskPrioritiesMap?:  Record<string, ITaskPriority>;
	taskTypesMap?:  Record<string, ITaskType>;
	usersMap?:  Record<string, IBriefUser>;
}
export const enrichTask = (props: IUseEnrichTaskProps) => {
	const {
		task,
		objectsMap,
		taskStatusesMap,
		taskPrioritiesMap,
		taskTypesMap,
		usersMap
	} = props;

	return {
		...task,
		objectId: objectsMap ? objectsMap[task.objectId] : undefined,
		status: task.status ? taskStatusesMap?.[task.status] : undefined,
		taskPriority: task.taskPriority
			? taskPrioritiesMap?.[task.taskPriority]
			: undefined,
		taskType: task.taskType ? taskTypesMap?.[task.taskType] : undefined,
		respUsers: usersMap
			? task.respUsers?.map(userId => usersMap[userId]).filter(user => user)
			: undefined,
		createdAt: task.createdAt
			? format(task.createdAt, dateTimeCommaSeparatedFormat)
			: '',
		modifiedAt: task.modifiedAt
			? format(task.modifiedAt, dateTimeCommaSeparatedFormat)
			: ''
	};
};