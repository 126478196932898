import {useMemo} from 'react';
import {Column} from 'react-table';
import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';
import {NameCell} from './cells/NameCell';
import {CompanyCell} from './cells/CompanyCell';
import {CreatedCell} from './cells/CreatedCell';
import {ModifiedCell} from './cells/ModifiedCell';
import {MobileCell} from './cells/MobileCell';
import {DeleteCell} from './cells/DeleteCell';

const getDesktopColumns = (): Array<Column<IPreparedLegal>> => {
	const nameColumn: Column<IPreparedLegal> = {
		id: 'name',
		Header: 'Название',
		Cell: NameCell,
		width: 90,
		className: 'legals-page__d-table-name-cell',
		accessor: row => row.name
	};

	const companyColumn: Column<IPreparedLegal> = {
		id: 'company',
		Header: 'Компания',
		Cell: CompanyCell,
		width: 70,
		className: 'legals-page__d-table-company-cell',
		accessor: row => row?.company?.name
	};

	const createdAtColumn: Column<IPreparedLegal> = {
		id: 'createdAt',
		Header: 'Добавлено',
		Cell: CreatedCell,
		width: 60,
		accessor: row => row.createdAt
	};

	const modifiedAtColumn: Column<IPreparedLegal> = {
		id: 'modifiedAt',
		Header: 'Изменено',
		Cell: ModifiedCell,
		width: 60,
		accessor: row => row?.modifiedAt
	};

	const deleteColumn: Column<IPreparedLegal> = {
		id: 'delete',
		Cell: DeleteCell,
		Header: '',
		width: 70,
		disableResizing: true,
		isNonClickable: true
	};

	return [nameColumn, companyColumn, createdAtColumn, modifiedAtColumn, deleteColumn];
};

const getMobileColumns = (): Array<Column<IPreparedLegal>> => {
	const mobileColumn = {
		id: 'object',
		Cell: MobileCell,
		className: 'legals-page__mobile-cell'
	};

	return [mobileColumn];
};

export const useColumns = (isTablet: boolean) =>
	useMemo(() => (!isTablet ? getMobileColumns() : getDesktopColumns()), [isTablet]);
