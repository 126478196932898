import INormalizedData from '../interfaces/INormalizedData';

export interface IEntityState<E> {
	byId: {
		[id: string]: E;
	};
	allIds: string[];
	expires?: number;
}

export interface ILoadedEntityState<E> {
	byId: {
		[id: string]: E;
	};
	allIds: string[];
	loaded: boolean;
	loadedAt?: number;
}

interface IEntity {
	id: string;
}

export interface IManageableEntityState<E> {
	data?: E;
	expires?: number;
}

export const getNormalizedEntitiesSuccess = <E extends IEntity>(
	state: IEntityState<E>,
	{payload}: {payload: INormalizedData<E>}
) => ({
	byId: payload.byId,
	allIds: payload.allIds
});

export const alterEntitySuccess = <E extends IEntity>(state: IEntityState<E>, {payload}: {payload: E}) => ({
	byId: {
		...state.byId,
		[payload.id]: {...payload}
	},
	allIds: Array.from(new Set(state.allIds.concat(payload.id)))
});

export const removeEntitySuccess = <E extends IEntity>(state: IEntityState<E>, {payload}: {payload: Pick<E, 'id'>}) => {
	if (!payload.id) {
		return state;
	}
	const byId = {...state.byId};
	delete byId[payload.id];
	return {
		byId,
		allIds: state.allIds.filter(id => id !== payload.id)
	};
};

export const getEntitiesSuccess
	= <E extends IEntity>(cacheTime: number) =>
	(state: IEntityState<E>, {payload}: {payload: INormalizedData<E>}) => ({
		...getNormalizedEntitiesSuccess(state, {payload}),
		expires: Date.now() + cacheTime * 1000
	});

export const addEntitySuccess
	= <E extends IEntity>() =>
	(state: IEntityState<E>, {payload}: {payload: E}) => ({
		...alterEntitySuccess(state, {payload}),
		expires: state.expires
	});

export const editEntitySuccess
	= <E extends IEntity>() =>
	(state: IEntityState<E>, {payload}: {payload: E}) => ({
		...alterEntitySuccess(state, {payload}),
		expires: state.expires
	});

export const deleteEntitySuccess
	= <E extends IEntity>() =>
	(state: IEntityState<E>, {payload}: {payload: Pick<E, 'id'>}) => ({
		...removeEntitySuccess(state, {payload}),
		expires: state.expires
	});

export const getLoadedEntitiesSuccess
	= <E extends IEntity>() =>
	(state: ILoadedEntityState<E>, {payload}: {payload: INormalizedData<E>}) => ({
		...getNormalizedEntitiesSuccess(state, {payload}),
		loaded: true,
		loadedAt: Date.now()
	});

export const addLoadedEntitySuccess
	= <E extends IEntity>() =>
	(state: ILoadedEntityState<E>, {payload}: {payload: E}) => ({
		...alterEntitySuccess(state, {payload}),
		loaded: state.loaded,
		loadedAt: state.loadedAt
	});

export const editLoadedEntitySuccess
	= <E extends IEntity>() =>
	(state: ILoadedEntityState<E>, {payload}: {payload: E}) => ({
		...alterEntitySuccess(state, {payload}),
		loaded: state.loaded,
		loadedAt: state.loadedAt
	});

export const deleteLoadedEntitySuccess
	= <E extends IEntity>() =>
	(state: ILoadedEntityState<E>, {payload}: {payload: Pick<E, 'id'>}) => ({
		...removeEntitySuccess(state, {payload}),
		loaded: state.loaded,
		loadedAt: state.loadedAt
	});

export const alterManageableEntitySuccess
	= <E extends IEntity>(cacheTime: number) =>
	(state: IManageableEntityState<E>, {payload}: {payload: E}) => {
		state.data = payload;
		state.expires = state.expires || cacheTime * 1000;
	};

export const getManageableEntityInitialState = <E extends IEntity>(): IManageableEntityState<E> => ({
	data: undefined,
	expires: undefined
});

export const getEntitiesInitialState = <E extends IEntity>() =>
	({
		byId: {},
		allIds: [],
		expires: undefined
	} as IEntityState<E>);

export const getLoadedEntitiesInitialState = <E extends IEntity>() =>
	({
		byId: {},
		allIds: [],
		loaded: false
	} as ILoadedEntityState<E>);
