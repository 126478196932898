import {IEditableCheckListState} from '@src/core/hooks/states/useEditableCheckListState';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export const filterTypeDecorationsForCheckList = (
	editingState: IEditableCheckListState,
	typeDecorationsArray: ISpaceTypeDecoration[],
	typeDecorationSets: ISpaceTypeDecorationSet[]
) => {
	let fileteredTypeDecorations = [] as ISpaceTypeDecoration[];

	// Фильтруем наборы типов отделки по компании и объектам
	const filteredSets = typeDecorationSets.filter(set => {
		if (editingState.companyId === set.companyId) {
			return editingState?.objects?.some(object => set.objects.includes(object));
		}
		return false;
	});

	// Фильтруем типы отделки по Id наборов полученных на предыдущем шаге
	fileteredTypeDecorations = typeDecorationsArray.filter(t =>
		filteredSets.some(set => set.id === t.typeDecorationSetId));

	// Фильтруем типы отделки по типу помещений
	if (editingState.spaceTypes !== undefined && editingState?.spaceTypes?.length) {
		fileteredTypeDecorations = fileteredTypeDecorations.filter(t =>
			t.spaceTypes.some(space => editingState.spaceTypes?.includes(space)));
	}
	return fileteredTypeDecorations;
};
