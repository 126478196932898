import {useQueryClient} from '@tanstack/react-query';
import {useMemo} from 'react';

export const useFindMutation = (entityId: string) => {
	const queryClient = useQueryClient();
	const mutationCache = queryClient.getMutationCache();
	return useMemo(() => {
		const currentMutation = mutationCache.find<
			unknown,
			unknown,
			Record<string, unknown> & {key: string},
			unknown
		>({
			predicate: mut => {
				const key = (mut.state.variables as Record<string, unknown> & {key: string}).key;
				return entityId === key;
			}
		});
		return currentMutation;
	}, [queryClient, entityId]);
};
