import {wsConnector} from '../wsConnector';
import {ISavingProblemComment} from '@src/interfaces/saving/ISavingProblemComment';
import {IEditCommentResponse} from '../comment';

/**
 * Изменяет ответ на нарушение
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const makeReplyEditRequest = (
	objectId: string,
	commentId: string,
	fields: ISavingProblemComment
) =>
	wsConnector.sendAuthorizedRequest<IEditCommentResponse>('editReply', {
		objectId,
		commentId,
		...fields
	});
