import { useCallback } from 'react';
import {useIsBeforeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {toggleMobileSidebarVisibility} from '@src/store/modules/app/sidebar/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton, MenuArrowButton} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IMobileLeftButtonProps {
	backBtnVisible?: boolean;
}

const MobileLeftButton = ({backBtnVisible}: IMobileLeftButtonProps) => {
	const dispatch = useAppDispatch();
	const isMobile = useIsBeforeTablet();
	const {goBack} = useChangePath();
	const openSidebar = useCallback(() => {
		dispatch(toggleMobileSidebarVisibility(true));
	}, []);

	if (backBtnVisible) {
		return (
			<IconButton onClick={goBack}>
				<i className="tz-long-arrow-24 rotate-180"/>
			</IconButton>
		);
	}
	if (isMobile) {
		return <MenuArrowButton onClick={openSidebar}/>;
	}
	return null;
};

export default MobileLeftButton;
