import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import {ActionsMenu} from '../../components/ActionsMenu/ActionsMenu';
import {TopBar} from '@/shared/ui/EntitiesPage/TopBar/TopBar';

interface ITopBarProps {
	objectId: string;
}

export const StructuresTopBar = ({objectId}: ITopBarProps) => {
	const breadcrumbs = useObjectBreadcrumbs(objectId);

	return (
		<TopBar breadcrumbs={breadcrumbs}>
			<ActionsMenu objectId={objectId} />
		</TopBar>
	);
};
