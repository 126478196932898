import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditSpaceOwnerResponse = ISpaceOwner;

/**
 * Изменяет собственника помещения
 *
 * @param ownerId id собственника
 * @param data данные собственника
 */
export const makeSpaceOwnerEditRequest = async (ownerId: string, data: ISavingSpaceOwner) => {
	const res = await httpRequests.withToken.put<IEditSpaceOwnerResponse>(
		`space-owners/${ownerId}`,
		data
	);
	return res.data;
};
