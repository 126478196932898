import { memo, useCallback } from 'react';
import * as React from 'react';
import './UserMenu.less';
import classNames from 'classnames';
import PopupMenu, {IPopupMenuTriggerFnProps} from '../PopupMenu';
import {IMenuProps} from '../Menu';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IMenuItemProps} from '../MenuItem';
import UserButton from '../../buttons/UserButton';
import {UserAvatarSize} from '../../various/UserAvatar';
import {RefCallback} from 'react-laag/dist/types.d';

interface IHeaderUserMenuProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactElement<IMenuProps> | Array<React.ReactElement<IMenuItemProps>>;
	user?: IUser;
	loading?: boolean;
	avatarSize?: UserAvatarSize;
}

const UserMenu = ({
	className,
	style,
	children,
	user,
	loading,
	avatarSize
}: IHeaderUserMenuProps) => {
	const rightIcon = useCallback(
		(ref?: RefCallback) => (
			<div className="user-menu__button-icon-wrap" ref={ref}>
				<i className="tz-simple-arrow-16" />
			</div>
		),
		[]
	);
	const triggerFunction = useCallback(
		({isOpen, toggle, ref, disabled: triggerDisabled}: IPopupMenuTriggerFnProps) => {
			const userButtonClasses = {
				root: classNames('user-menu__button', className),
				rightIcon: classNames('user-menu__button-icon', {
					'user-menu__button-icon_open': isOpen
				})
			};
			return (
				<UserButton
					className={userButtonClasses}
					style={style}
					rightIcon={rightIcon(ref)}
					disabled={loading || triggerDisabled || !children}
					user={user}
					avatarSize={avatarSize}
					onClick={toggle}
				/>
			);
		},
		[className, style, children, loading, user, avatarSize, rightIcon]
	);

	return (
		<PopupMenu
			trigger={triggerFunction}
			arrowVisible
			popupProps={{placement: 'bottom-end', preferX: 'right'}}
		>
			{children}
		</PopupMenu>
	);
};

UserMenu.displayName = 'UserMenu';

export default memo(UserMenu);
