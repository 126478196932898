import {memo} from 'react';
import {SelectedEntitiesDeletion} from './SelectedEntitiesDeletion';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';

interface ISelectionsActionsProps {
	selectedEntities: IPreparedWorkAcceptance[];
	selectedClearing: () => void;
	objectId: string;
}

export const SelectionActions = memo(
	({selectedEntities, selectedClearing, objectId}: ISelectionsActionsProps) => {
		const perms = useWorkAcceptancesPermissions(objectId);
		return perms.canDelete ? (
			<SelectedEntitiesDeletion
				selectedEntities={selectedEntities}
				selectedClearing={selectedClearing}
			/>
		) : null;
	}
);
