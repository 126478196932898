export enum OfflineDataTransferStatus {
	/**
	 * Сущность сохранена только локально
	 */
	SAVED_LOCAL = 'saved-local',
	/**
	 * Началась передача данных
	 */
	TRANSFER = 'transfer',
	/**
	 * Сущность успешно сохранена
	 */
	TRANSFER_COMPLETE = 'transfer-complete',
	/**
	 * Сущность не сохранена
	 */
	TRANSFER_ERROR = 'transfer-error',
}
interface ILocalKeys {
	checks: string;
	problems: string;
	inspections: string;
	internalAcceptances: string;
	ownerAcceptances: string;
	warrantyClaims: string;
}
export type IOfflineDataTransferStatuses = {
	[K in keyof ILocalKeys]: {
		[key: string]: OfflineDataTransferStatus;
	};
};

export interface IOfflineDataTransferSuccess {
	entityType: keyof ILocalKeys;
	newItem: {
		[key: string]: any;
	};
	key: string;
	localFilesKeys?: string[];
}