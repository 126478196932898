import classNames from 'classnames';
import './ProblemTagsMarkers.less';

interface IProblemTagsMarkerProps {
	colors: string[];
	className?: string;
}
export const ProblemTagsMarker = ({colors, className}: IProblemTagsMarkerProps) => {
	const limit = 2;
	const visibleColors = colors.length > limit ? colors.slice(0, limit) : colors;

	const dots = visibleColors.map((item, index) => (
		<div
			className="problem-tags-markers__tag"
			key={index}
			style={{backgroundColor: item}}
		/>
	));
	if (!colors.length) {
		return null;
	}

	return (
		<div className={classNames('problem-tags-markers', className)}>
			{dots}
			{colors.length > limit && (
				<div className="problem-tags-markers__rest">
					+
					{colors.length - limit}
				</div>
			)}
		</div>
	);
};
