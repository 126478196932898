import {useMemo} from 'react';

export interface IDecadeData {
	value: number;
	name: string;
	differentPeriod: boolean;
}

export const useDecadesGrid = (century: number) => useMemo(() => {
		const decades: IDecadeData[] = [];
		for (let i = -10; i < 110; i += 10) {
			decades.push({
				value: century + i,
				name: `${century + i}-${century + i + 9}`,
				differentPeriod: i < 0 || i >= 100
			});
		}
		return decades;
	}, [century]);
