import {useMemo} from 'react';
import {Column} from 'react-table';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemStatusCell} from '@src/components/tableCells/ProblemStatusCell';
import {ProblemFixCell} from '@src/components/tableCells/ProblemFixCell';
import InspectionInfoDesktopCell from '@src/components/tableCells/InspectionInfoDesktopCell/InspectionInfoDesktopCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {extractInternalAcceptanceProblemCommentsByProblem} from '@src/store/modules/entities/internalAcceptance/selectors';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import {useTranslation} from 'react-i18next';
import {InspectionFixCell} from '@src/components/tableCells/InspectionFixCell';

export const useDesktopColumns = () => {
	const {t} = useTranslation();

	return useMemo<Array<Column<IInternalAcceptanceEntity>>>(
		() => [
			{
				id: 'number',
				Header: HeaderCellWithRowSelectWrap(t('internalAcceptancePage.columns.number')),
				Cell: CellWrap({
					problem: ProblemInfoCellWrap(
						ProblemInfoDesktopCell,
						extractInternalAcceptanceProblemCommentsByProblem
					),
					inspection: InspectionInfoDesktopCell
				}),
				width: 200,
				minWidth: 200
			},
			{
				id: 'status',
				Header: t('internalAcceptancePage.columns.status'),
				Cell: CellWrap({
					problem: ProblemStatusCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'category',
				Header: t('internalAcceptancePage.columns.category'),
				Cell: CellWrap({
					problem: CategoryCell,
					inspection: CategoryCell
				}),
				width: 120,
				minWidth: 120
			},
			{
				id: 'fix',
				Header: t('internalAcceptancePage.columns.fix'),
				Cell: CellWrap({problem: ProblemFixCell, inspection: InspectionFixCell}),
				width: 150,
				minWidth: 150
			}
		],
		[t]
	);
};
