import React from 'react';
import './CommentsVisibilityFilter.less';
import {ButtonMenu, IconMenu} from '@tehzor/ui-components';
import classNames from 'classnames';
import {IPopupBasicProps} from '@tehzor/ui-components/src/components/containers/Popup';
import {useVisibilityFilterMenu} from './hooks/useVisibilityFilterMenu';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {ICommentVariants} from '@tehzor/tools/interfaces/comments/ICommentType';

interface ICommentsVFilterProps {
	className?: string;
	style?: React.CSSProperties;
	popupProps?: IPopupBasicProps;
	visibility: ICommentVariants[];
	changeCommentsVisibility: (value: ICommentVariants[]) => {
		type: string;
		payload: ICommentVariants[];
	};
}

export const CommentsVisibilityFilter = ({
	className,
	style,
	popupProps,
	visibility,
	changeCommentsVisibility
}: ICommentsVFilterProps) => {
	const [menu, label] = useVisibilityFilterMenu(visibility, changeCommentsVisibility );
	const isTablet = useIsTablet();

	return isTablet ? (
		<ButtonMenu
			className={{
				root: classNames('comments-v-filter', className),
				label: 'comments-v-filter__label'
			}}
			style={style}
			type="common-bordered"
			label={label}
			popupProps={popupProps}
		>
			{menu}
		</ButtonMenu>
	) : (
		<IconMenu
			icon={<i className="tz-filters-24"/>}
			popupProps={popupProps}
		>
			{menu}
		</IconMenu>
	);
};
