import * as types from './constants';
import {ViewerType, InputType} from '@tehzor/ui-components/src/components/LocationSelect';

export interface IChangeLocDefaultViewerTypePayload {
	objectId: string;
	value: ViewerType;
}

/**
 * Изменяет дефолтное значение варианта отображения
 *
 * @param objectId id объекта
 * @param value значение
 */
export const changeLocDefaultViewerType = (objectId: string, value: ViewerType) => ({
	type: types.CHANGE_DEFAULT_VIEWER_TYPE,
	payload: {
		objectId,
		value
	} as IChangeLocDefaultViewerTypePayload
});

export interface IChangeLocDefaultInputTypePayload {
	objectId: string;
	value: InputType;
}

/**
 * Изменяет дефолтное значение варианта выбора местоположения
 *
 * @param objectId id объекта
 * @param value значение
 */
export const changeLocDefaultInputType = (objectId: string, value: InputType) => ({
	type: types.CHANGE_DEFAULT_INPUT_TYPE,
	payload: {
		objectId,
		value
	} as IChangeLocDefaultInputTypePayload
});
