import {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useExtractCheckRecordStatusesByEntityTypeAsArray} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface ICheckListsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const CheckListCategoryStatusesFilterMobile = memo((props: ICheckListsFilterMobileProps) => {
	const {t} = useTranslation();
	const {data: checkItemsRecordStatuses} =
		useExtractCheckRecordStatusesByEntityTypeAsArray('check-item');

	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.checkListStatuses,
		checkItemsRecordStatuses
	);

	if (!translatedOptions) {
		return null;
	}

	return (
		<BaseListFilterMobile
			{...props}
			options={translatedOptions}
			label={t('spacesFiltersPage.checkListCategoryStatusesFilterMobile.label')}
			filterName="checkListCategoryStatuses"
		/>
	);
});
