import {EntityGridItem} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import './ActualFixDate.less';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IFixDateProps {
	value: number;
}

const calendarIcon = <i className="tz-calendar-done-20" />;

export const ActualFixDate = ({value}: IFixDateProps) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();
	const formatted = format(value, longDateTimeFormat, {locale: dateFnsLocale});

	return (
		<EntityGridItem
			icon={calendarIcon}
			label={t('problemPage.info.actualFixDate.label')}
		>
			<div className="actual-fix-date-view-field-value">{formatted}</div>
		</EntityGridItem>
	);
};
