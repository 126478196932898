import {useCallback, CSSProperties, ReactNode} from 'react';
import {ChangeSelectionCtx} from '../utils/ChangeSelectionCtx';
import {CheckSelectionCtx} from '../utils/CheckSelectionCtx';

export type ISelectProps<V extends string = string> = {
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
} & (
	| {
			multiple?: false;
			value?: V;

			onChange?: (value: V) => void;
	  }
	| {
			multiple: true;
			value?: V[];

			onChange?: (value: V[]) => void;
	  }
);

const Select = <V extends string = string>(props: ISelectProps<V>) => {
	const {className, style, children, multiple, value, onChange} = props;

	const change = useCallback(
		(key: V) => {
			if (multiple) {
				let newValue: V[];
				if (value && Array.isArray(value)) {
					if (value.includes(key)) {
						newValue = value.filter(v => v !== key);
					} else {
						newValue = value.concat(key);
					}
				} else {
					newValue = [key];
				}
				if (onChange) {
					(onChange as (v: V[]) => void)(newValue);
				}
			} else if (onChange) {
					(onChange as (v: V) => void)(key);
				}
		},
		[value, onChange, multiple]
	);

	const check = useCallback(
		(key: V) => value !== undefined && (Array.isArray(value) ? value.includes(key) : value === key),
		[value]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<ChangeSelectionCtx.Provider value={change}>
				<CheckSelectionCtx.Provider value={check}>{children}</CheckSelectionCtx.Provider>
			</ChangeSelectionCtx.Provider>
		</div>
	);
};

Select.displayName = 'Select';

export default Select;