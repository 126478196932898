import {IEditableCategory} from '@src/core/hooks/states/useCategoriesSetState';
import {Button, IconButton} from '@tehzor/ui-components';
import { memo } from 'react';

interface ICategoryDesktopOptionsProps {
	item: IEditableCategory;
	isEdited?: boolean;
	isEditable?: boolean;
	onCreate: (parent?: IEditableCategory) => void;
	onRemove: (id: string) => void;
	onConfirm: (id: string) => void;
	onCancel: (id: string) => void;
}

const addIcon = <i className="tz-plus-20"/>;
const removeIcon = <i className="tz-delete"/>;

export const CategoryDesktopOptions = memo(({
	item,
	isEdited,
	isEditable,
	onCreate,
	onRemove,
	onConfirm,
	onCancel
}: ICategoryDesktopOptionsProps) => isEditable ? (
	<div className="editable-categories__item-options">
		{isEdited ? (
			<div className="editable-categories__item-options__edit">
				<Button
					label="Сохранить"
					type="accent-blue"
					onClick={() => onConfirm(item.id)}
				/>
				<Button
					label="Отменить"
					type="cancel"
					onClick={() => onCancel(item.id)}
				/>
			</div>
		) : (
			<div className="editable-categories__item-options__view">
				{item.id[0] !== '_' && (
					<IconButton
						className="editable-categories__item-options__view-add"
						type="inline-blue-accent"
						onClick={() => onCreate(item)}
					>
						{addIcon}
					</IconButton>
				)}
				<IconButton
					className="editable-categories__item-options__view-delete"
					type="inline-red-accent"
					onClick={() => onRemove(item.id)}
				>
					{removeIcon}
				</IconButton>
			</div>
		)}
	</div>
) : null);