import {PrivateRoute} from '@src/containers/PrivateRoute';
import {AllProblemsPage} from '@src/pages/ProblemsPage';
import ProblemsFiltersRoutingPage from '@src/pages/filters/ProblemsFiltersRoutingPage';
import {problemsFilterRoutes} from './problemsFilterRoutes';

export const problemsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllProblemsPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<ProblemsFiltersRoutingPage/>}/>,
		children: problemsFilterRoutes
	}
];
