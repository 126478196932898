import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IEditSpaceStatusResponse} from '@src/api/backend/space/editStatus';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IEditSpaceStatusPayload = IEditSpaceStatusResponse;

const success = (response: IEditSpaceStatusResponse, pageObjectId: string) => {
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.detail(response.id)});

	return {
		type: types.EDIT_STATUS_SUCCESS,
		payload: {...response, pageObjectId}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении статуса помещения');
	return {
		type: types.EDIT_STATUS_FAILURE,
		payload: error
	};
};

export const editSpaceStatusActions = {failure, success};
