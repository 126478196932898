import { useCallback } from 'react';
import * as React from 'react';
import './UploadingFile.less';
import classNames from 'classnames';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import DownloadableFile from '../DownloadableFile';
import {IconButton} from '../../buttons';
import {makeIcon} from './utils/makeIcon';

export interface IUploadingFileProps<D> {
	className?: string;
	style?: React.CSSProperties;
	name: string;
	url: string;
	type: string;
	size?: number;
	progress: number;
	status: UploadingFileStatus;
	disabled?: boolean;
	data: D;

	onDelete?: (data: D) => void;
	onReload?: (data: D) => void;
}

const UploadingFile = <D extends unknown>(props: IUploadingFileProps<D>) => {
	const {className, style, name, url, type, size, progress, status, disabled, data, onDelete, onReload} = props;

	const handleDelete = useCallback(() => {
		if (onDelete) {
			onDelete(data);
		}
	}, [data, onDelete]);

	const handleReload = useCallback(() => {
		if (onReload) {
			onReload(data);
		}
	}, [data, onReload]);

	return (
		<div
			className={classNames('uploading-file', className)}
			style={style}
		>
			<div className="uploading-file__info-wrapper">
				<DownloadableFile
					className={{
						root: classNames('uploading-file__info', `uploading-file__info_${status}`),
						name: 'uploading-file__info-name',
						size: 'uploading-file__info-size'
					}}
					name={name}
					url={url}
					type={type}
					size={size}
					icon={makeIcon(progress, status)}
				/>
			</div>

			{status === UploadingFileStatus.ERROR && (
				<IconButton
					className="uploading-file__reload-btn"
					type="inline-blue-accent"
					disabled={disabled}
					onClick={handleReload}
				>
					<i className="tz-reload-24"/>
				</IconButton>
			)}

			<IconButton
				className="uploading-file__delete-btn"
				type="inline"
				disabled={disabled}
				onClick={handleDelete}
			>
				<i className="tz-close-20"/>
			</IconButton>
		</div>
	);
};

export default UploadingFile;