import { useCallback } from 'react';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {Block} from './Block';

interface ISpaceBlockProps {
	space: IConvertedSpace;
	width: number;
	height: number;
	name: string;
	onClick?: (space: IConvertedSpace) => void;
}

export const SpaceBlock = ({space, onClick, width, height, name}: ISpaceBlockProps) => {
	const {checkLists} = space;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(space);
		}
	}, [space]);

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			onClick={handleClick}
			className="check-lists-space__status"
		>
			{/* тень */}
			<rect
				x="14px"
				y="14px"
				width={width - 12 - 12 - 4}
				rx="9"
				height={height - 12 - 12 - 4}
				className="check-lists-space__status-shadow"
			/>
			{/* прямоугольная диаграмма */}
			<Block
				// key={item.key}
				width={width}
				checkLists={checkLists}
				spaceId={space.id}
			/>

			<text
				className="check-lists-space__number"
				x="50%"
				y="50%"
				dominantBaseline="middle"
				textAnchor="middle"
			>
				{name}
			</text>
		</svg>
	);
};
