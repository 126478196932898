import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {extractSourcesLoading} from './sources';

export const extractProblemsByStatusesStatsLoading = (state: IState) =>
	state.statistics.ui.warranty.problemsByStatusesLoading;
export const extractProblemsByCategoriesStatsLoading = (state: IState) =>
	state.statistics.ui.warranty.problemsByCategoriesLoading;
export const extractChecksCreationStatsLoading = (state: IState) =>
	state.statistics.ui.warranty.checksCreationLoading;
export const extractProblemsCreationStatsLoading = (state: IState) =>
	state.statistics.ui.warranty.problemsCreationLoading;
export const extractProblemRepliesCreationStatsLoading = (state: IState) =>
	state.statistics.ui.warranty.problemRepliesCreationLoading;
export const extractInspectionsCreationStatsLoading = (state: IState) =>
	state.statistics.ui.warranty.inspectionsCreationLoading;
	export const extractWarrantyClaimsCreationStatsLoading = (state: IState) =>
	state.statistics.ui.warranty.warrantyClaimsCreationLoading;


export const areProblemsByStatusesStatsLoading = createSelector(
	[extractSourcesLoading, extractProblemsByStatusesStatsLoading],
	(sourcesLoading, dataLoading) => sourcesLoading || dataLoading
);

export const areProblemsByCategoriesStatsLoading = createSelector(
	[extractSourcesLoading, extractProblemsByCategoriesStatsLoading],
	(sourcesLoading, dataLoading) => sourcesLoading || dataLoading
);

export const areCreationStatsLoading = createSelector(
	[
		extractSourcesLoading,
		extractChecksCreationStatsLoading,
		extractProblemsCreationStatsLoading,
		extractProblemRepliesCreationStatsLoading,
		extractInspectionsCreationStatsLoading,
		extractWarrantyClaimsCreationStatsLoading
	],
	(...loadings) => loadings.some(v => v)
);
