import {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useObjectsCitiesAsArray} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

interface ICitiesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const CitiesFilterMobile = memo((props: ICitiesFilterMobileProps) => {
	const cities = useObjectsCitiesAsArray();
	const {t} = useTranslation();

	if (!cities) return null;

	return (
		<BaseListFilterMobile
			{...props}
			options={cities}
			label={t('objectsPage.filters.city')}
			filterName="cities"
		/>
	);
});
