import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {TFunction} from 'i18next';

export const createAttachmentsDifference = (
	prev: IAttachment[] | undefined,
	next: IAttachment[] | undefined,
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev,
	next,
	type: HistoryTypeId.ATTACHMENTS,
	name: t('createDifference.createAttachmentsDifference')
});
