import {
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	InlineButton,
	Plate,
	StatusSelect
} from '@tehzor/ui-components';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import {
	editWarrantyClaim,
	editWarrantyClaimStatus
} from '@src/store/modules/entities/warrantyClaim/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import SpaceOwners from './SpaceOwners';
import Documents from './Documents';
import {useEditableWarrantyClaimAttachmentsDialog} from '@src/components/EditableWarrantyClaimAttachmentsDialog/hooks/useEditableWarrantyClaimAttachmentsDialog';
import {RegistrationDateDesktop} from './registrationDate.desktop';
import {useWarrantyClaimsStatuses} from '@src/core/hooks/queries/warrantyClaimsStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface IDesktopInfoProps {
	objectId: string;
	warrantyClaim: IWarrantyClaim;
}

const calendarIcon = <i className="tz-calendar-20" />;
const editIcon = <i className="tz-edit-16" />;
const statusIcon = <i className="tz-status-20" />;
const documentsIcon = <i className="tz-document-20" />;

const DesktopInfo = ({objectId, warrantyClaim}: IDesktopInfoProps) => {
	const {t} = useTranslation();
	const {data: statuses} = useWarrantyClaimsStatuses();
	const translatedStatuses = useTranslatedDictionary(
		dictionaryKeys.warrantyClaimStatuses,
		statuses
	);
	const dispatch = useAppDispatch();
	const permissions = useWarrantyClaimsPermissions(objectId);
	// Диалог редактирования обращения
	const [editingAttachmentsDialog, openEditAttachmentsDialog] =
		useEditableWarrantyClaimAttachmentsDialog(objectId, warrantyClaim.id, warrantyClaim);
	const [, handleChange] = useAsyncFn(
		async (v: WarrantyClaimStatusId) => {
			if (warrantyClaim) {
				await dispatch(editWarrantyClaimStatus(objectId, warrantyClaim.id, v));
			}
		},
		[objectId, warrantyClaim]
	);

	const [, handleRegDateChange] = useAsyncFn(
		async (value: Date) => {
			if (warrantyClaim) {
				await dispatch(
					editWarrantyClaim(objectId, warrantyClaim.id, {
						registrationDate: value.getTime()
					})
				);
			}
		},
		[objectId, warrantyClaim]
	);

	return (
		<Plate className={{content: 'warranty-claim-page__info-plate'}}>
			<EntityGrid withBorders>
				<EntityGridItem
					label={t('warrantyClaimPage.info.entityGridItem.status.label')}
					icon={statusIcon}
					fullRow
				>
					<StatusSelect
						className="warranty-claim-page__d-entities-status"
						statuses={translatedStatuses}
						disabled={!permissions.canEditStatus}
						value={warrantyClaim?.status}
						onChange={handleChange}
					/>
				</EntityGridItem>

				<SpaceOwners
					name={warrantyClaim.spaceOwner?.name}
					phone={warrantyClaim?.replyPhone}
					email={warrantyClaim?.replyEmail}
					claimer={warrantyClaim?.claimerName}
				/>

				<EntityGridItem
					label={t('warrantyClaimPage.info.entityGridItem.formalNote.label')}
					icon={documentsIcon}
					fullRow
					buttons={
						<InlineButton
							className="warranty-claim-page__documents-edit-icon"
							type="accent"
							leftIcon={editIcon}
							onClick={openEditAttachmentsDialog}
						/>
					}
				>
					<Documents
						warrantyClaim={warrantyClaim}
						// objectId={objectId}
					/>
				</EntityGridItem>
				<RegistrationDateDesktop
					value={warrantyClaim.registrationDate}
					onChange={handleRegDateChange}
					canEdit={permissions.canEdit}
				/>
				<EditorInfo
					icon={calendarIcon}
					label={t('warrantyClaimPage.info.editorInfo.created.label')}
					date={warrantyClaim?.createdAt}
					user={warrantyClaim?.createdBy}
					fullRow
				/>
				{warrantyClaim?.createdAt !== warrantyClaim?.modifiedAt && (
					<EditorInfo
						icon={calendarIcon}
						label={t('warrantyClaimPage.info.editorInfo.updated.label')}
						date={warrantyClaim?.modifiedAt}
						user={warrantyClaim?.modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default DesktopInfo;
