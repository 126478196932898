import {EntityGridItem} from '@tehzor/ui-components';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTranslation} from "react-i18next";

interface IDescriptionProps {
	className?: string;
	task: IEnrichedTask;
}

export const TaskType = ({className, task}: IDescriptionProps) => {
	const {t} = useTranslation();

	return (
		<EntityGridItem
			className={className}
			label={t('checkListPage.detail.taskInfoMobileCell.taskType.label')}
			inline
		>
			<div>{task.taskType?.name}</div>
		</EntityGridItem>
	)
};
