import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditSpaceStatusesSetResponse = ISpaceStatusesSet;

/**
 * Изменяет набор статусов помещений
 *
 * @param spaceStatusesSetId id набора статусов помещения
 * @param fields поля для набора статусов помещений
 */
export const requestEditSpaceStatusesSet = async (
	spaceStatusesSetId: string,
	fields: ISavingSpaceStatusesSet
) => {
	const res = await httpRequests.withToken.put<IEditSpaceStatusesSetResponse>(
		`space-statuses-sets/${spaceStatusesSetId}`,
		fields
	);
	return res.data;
};
