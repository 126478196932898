import {Responsibles} from '@src/components/viewFields/Responsibles';
import {useWorkAcceptanceResponsiblePermissions} from '@src/core/hooks/permissions/useWorkAcceptanceResponsiblePermissions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getDelegationHistoriesByWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions/delegationHistories';
import {editWorkAcceptanceAcceptors} from '@src/store/modules/entities/workAcceptance/actions/editAcceptors';
import {extractWorkAcceptanceDelegationHistoriesAsArray} from '@src/store/modules/entities/workAcceptance/selectors/workAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface IWorkAcceptanceAcceptorsProps {
	workAcceptance: IWorkAcceptance;
}

export const WorkAcceptanceAcceptors = ({workAcceptance}: IWorkAcceptanceAcceptorsProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const histories = useAppSelector(extractWorkAcceptanceDelegationHistoriesAsArray);

	const {id, objectId, stage, acceptorsActiveGroup, acceptorsInitialGroup, acceptors} =
		workAcceptance;

	const {canEditAcceptors, onlyActiveGroup} = useWorkAcceptanceResponsiblePermissions(objectId);

	const ruleParams: IRuleParams = useMemo(
		() => ({
			categoryId: workAcceptance.categoryId,
			spaceIds: workAcceptance.spaces?.map(space => space.id),
			structureIds: workAcceptance.structures?.map(structure => structure.id)
		}),
		[workAcceptance]
	);

	const getDelegationHistories = useCallback(
		() => dispatch(getDelegationHistoriesByWorkAcceptance(objectId, id)),
		[objectId, id]
	);

	const handleChange = useCallback(
		async (users: string[], group?: string) => {
			await dispatch(editWorkAcceptanceAcceptors(objectId, id, users, group));
		},
		[objectId, id]
	);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			workAcceptanceType={workAcceptance.type}
			workAcceptanceFrontType={workAcceptance.frontType}
			workingGroupType={WorkingGroupTypeId.ACCEPTORS}
			respUsers={acceptors}
			activeGroup={acceptorsActiveGroup}
			initialGroup={acceptorsInitialGroup}
			canEditResponsibles={canEditAcceptors}
			onlyActiveGroup={onlyActiveGroup}
			ruleParams={ruleParams}
			histories={histories}
			label={t('workAcceptancePage.description.acceptors.label')}
			delegationDialogLabel={t('components.delegationDialog.acceptors.title')}
			historyAutomaticActionLabelIn={t(
				'workAcceptancePage.description.acceptors.historyAutomaticActionLabelIn'
			)}
			historyAutomaticActionLabelTitle={t(
				'workAcceptancePage.description.acceptors.historyAutomaticActionLabelTitle'
			)}
			historyUserActionLabelIn={t(
				'workAcceptancePage.description.acceptors.historyUserActionLabelIn'
			)}
			historyUserActionLabelTitle={t(
				'workAcceptancePage.description.acceptors.historyUserActionLabelTitle'
			)}
			onResponsiblesChange={handleChange}
			getDelegationHistories={getDelegationHistories}
		/>
	);
};
