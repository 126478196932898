import { memo } from 'react';
import DesktopImages from '@src/pages/ProblemPage/components/attachments/Images.desktop';
import Documents from '@src/pages/ProblemPage/components/attachments/Documents';
import EmptyAttachments from './EmptyAttachments';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {Plate} from '@tehzor/ui-components';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';

interface IAttachmentsProps {
	problem: IPreparedProblem;

	onImageClick?: (index: number) => void;
}

const DesktopAttachments = ({problem, onImageClick}: IAttachmentsProps) => {
	const [images, docs] = useSplitAttachments(problem);

	if (images.length === 0 && docs.length === 0) {
		return <EmptyAttachments/>;
	}
	return (
		<>
			<DesktopImages
				attachments={images}
				onImageClick={onImageClick}
			/>
			{docs.length > 0 && (
				<Plate>
					<Documents files={docs}/>
				</Plate>
			)}
		</>
	);
};

export default memo(DesktopAttachments);
