import * as types from '../constants';
import {IGetWarrantyClaimResponse} from '@src/api/backend/warrantyClaim';

export type IGetWarrantyClaimPayload = IGetWarrantyClaimResponse;

const request = (warrantyClaimId: string, objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {warrantyClaimId, objectId}
});

const success = (response: IGetWarrantyClaimResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

export const getWarrantyClaimActions = {request, success};
