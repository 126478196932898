import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {IState} from '@src/store/modules';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {
	IGetWarrantyClaimsCreationStatsResponse,
	requestWarrantyClaimsCreationStats
} from '@src/api/backend/statistics/creation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

const request = () => ({type: types.GET_WARRANTY_CLAIMS_CREATION_REQUEST});

const success = (response: IGetWarrantyClaimsCreationStatsResponse) => ({
	type: types.GET_WARRANTY_CLAIMS_CREATION_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке статистики');
	}
	return {
		type: types.GET_WARRANTY_CLAIMS_CREATION_FAILURE,
		payload: error
	};
};

/**
 * Получает статистику по созданию гарантийных обращений для стадии гарантии
 */
export const getWarrantyClaimsCreationStats
	= (): AppThunkAction<Promise<IGetWarrantyClaimsCreationStatsResponse>> => (dispatch, getState) =>
		dispatch(
			checkExpiration<IState, IGetWarrantyClaimsCreationStatsResponse, ApiAction>(
				state => state.statistics.warranty.warrantyClaimsCreation,
				createApiAction<IGetWarrantyClaimsCreationStatsResponse>(
					request,
					success,
					failure,
					() => {
						const s = getState();
						const {objects, users, contractors}
							= s.settings.pages.statistics.warranty.filters;
						return requestWarrantyClaimsCreationStats(
							objects,
							ObjectStageIds.WARRANTY,
							users,
							contractors
						);
					}
				)
			)
		);
