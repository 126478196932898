import {IProblemView} from '@tehzor/tools/interfaces/problemView/IProblemView';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetProblemViewsResponse = INormalizedData<IProblemView>;

export const requestProblemViews = async (objectId: string, problemId: string) => {
	const response = await httpRequests.withToken.get<IGetProblemViewsResponse>(
		`problems/get-problem-view/${problemId}`, {params: {objectId}}
	);

	return response.data;
};
