import * as React from 'react';
import classNames from 'classnames';

interface ITableRowProps {
	className?: string;
	style?: React.CSSProperties;
	role?: string;
	children?: React.ReactNode;
}

const TableHeadRow = ({className, style, role, children}: ITableRowProps) => (
	<div
		className={classNames('table2__row', className)}
		style={style}
		role={role}
	>
		{children}
	</div>
);

export default TableHeadRow;