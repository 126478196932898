import IconButton from '../buttons/IconButton';

const icon = <i className="tz-reason-external-link" />;

interface IReasonSelectLink {
	externalLink?: string;
	shortName: string;
	link?: string;
}

const ReasonSelectLink = (props: IReasonSelectLink) => {
	const {externalLink, link} = props;

	return externalLink && link ? (
		<a
			className="reason-select__standard-item-link"
			href={`${link}`}
			target="_blank"
			rel="noreferrer"
			title="Подробнее"
		>
			<IconButton className="reason-select__standard-item-link-btn">{icon}</IconButton>
		</a>
	) : null;
};

export default ReasonSelectLink;
