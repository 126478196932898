import {queryClient} from '@src/api/QueryClient';
import {IGetChecksResponse} from '@src/api/backend/checks';
import {IGetPaginateInspectionsResponse} from '@src/api/backend/inspections';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {IGetOwnerAcceptancesResponse} from '@src/api/backend/ownerAcceptances';
import {IGetListProblemsResponse, IGetPaginateProblemsResponse} from '@src/api/backend/problems';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';

type ConditionalResponseType<T> = T extends
	| IGetListProblemsResponse
	| IGetPaginateProblemsResponse
	| IGetChecksResponse
	| IGetInternalAcceptancesResponse
	| IGetOwnerAcceptancesResponse
	| IGetWarrantyClaimsResponse
	| IGetPaginateInspectionsResponse
	? T
	: never;

export function removeStaleOfflineEntities<R>(
	res: ConditionalResponseType<R>,
	queryKeyFn: (key: string) => string[],
	localListQueryKeyFn: () => string[],
	findWithObjectId?: boolean
) {
	let shouldRefetch = false;
	res.allIds.forEach(id => {
		const localId = res.byId[id].localId;
		const objectId = res.byId[id].objectId;
		if (!localId) return;

		const queryKey = findWithObjectId
			? [...queryKeyFn(localId), objectId]
			: queryKeyFn(localId);
		if (localId && queryClient.getQueryData(queryKey)) {
			queryClient.removeQueries({queryKey});
			shouldRefetch = true;
		}
	});
	if (shouldRefetch) {
		void queryClient.refetchQueries({queryKey: localListQueryKeyFn()});
	}
}
