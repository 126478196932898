import {getTitle} from '@src/components/AddingCategoriesSet/components/EditableRelations/getTitle';
import {useCompaniesById} from '@src/core/hooks/queries/companies/hooks';
import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import { useMemo } from 'react';
import {CellProps} from 'react-table';
import {useObjectsMap} from '@src/core/hooks/queries/objects/hooks';

const RelationsCell = ({row}: CellProps<IFullCategoriesSet>) => {
	const shared = row.original.shared;

	const companies = row.original.companies;
	const objects = row.original.objects;
	const {data: companiesMap} = useCompaniesById();
	const objectsMap = useObjectsMap();

	const title = useMemo(
		() =>
			objectsMap && companiesMap ? getTitle([...companies, ...objects], companiesMap, objectsMap) : undefined,
		[companies, objects, companiesMap, objectsMap]
	);

	return shared ? (
		<div className="categories-sets__cell-relations">
			<p>Общий набор</p>
		</div>
	) : (
		<div className="categories-sets__cell-relations">{title || 'Нет компаний/объектов'}</div>
	);
};

export default RelationsCell;
