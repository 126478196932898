import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Удаляет набор меток нарушений
 *
 * @param setId id набора
 */
export const makeProblemTagsSetDeleteRequest = async (setId: string) => {
	const response = await httpRequests.withToken.delete<void>(`problem-tags-sets/${setId}`);
	return response.data;
};
