import { memo } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useDocumentsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useDocumentsBreadcrumbs';

export const PageBreadcrumbs = memo(() => {
	const breadcrumbs = useDocumentsBreadcrumbs();

	return (
		<AppBreadcrumbs
			className="documents-page__breadcrumbs"
			items={breadcrumbs}
		/>
	);
});
