import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import {getPermissions} from './getPermissions';
import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export const convertLegals = (legals: ILegalEntity[], roles: IUserRole[]): IPreparedLegal[] =>
	legals.map(legal => ({
		...legal,
		...getPermissions(roles)
	}));
