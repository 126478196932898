import {IUserRole, UserRoleScopes} from '../interfaces/IUser';
import IPermission from '../interfaces/IPermission';

/**
 * Проверяет есть ли право доступа хотя бы в одной роли
 *
 * @param roles роли пользователя
 * @param permissionId id полномочия
 * @param scope тип области видимости
 * @param scopeId id сущности области видимости
 */
export function hasPermission(
	roles: IUserRole[],
	permissionId: string,
	scope?: UserRoleScopes,
	scopeId?: string
): boolean {
	if (scope) {
		return roles.some(
			role =>
				scope === role.scope
				&& (scopeId ? role.scopeIds.includes(scopeId) : true)
				&& !!role.permissions
				&& role.permissions.some(item => item.permissionId === permissionId)
		);
	}
	return roles.some(role => !!role.permissions && role.permissions.some(item => item.permissionId === permissionId));
}

/**
 * Проверяет есть ли право доступа хотя бы в одной роли
 *
 * @param roles роли пользователя
 * @param permissionId id полномочия
 * @param scope тип области видимости
 * @param scopeId id сущности области видимости
 */
export function findPermission(
	roles: IUserRole[],
	permissionId: string,
	scope?: UserRoleScopes,
	scopeId?: string
): IPermission | undefined {
	for (const role of roles) {
		if (
			!role.permissions
			|| (scope && (scope !== role.scope || (scopeId ? !role.scopeIds.includes(scopeId) : false)))
		) {
			continue;
		}
		const perm = role.permissions.find(item => item.permissionId === permissionId);
		if (perm) {
			return perm;
		}
	}
	return undefined;
}

/**
 * Возвращает список id сущностей для заданного scope и permissionId
 *
 * @param roles роли пользователя
 * @param scope тип области видимости
 * @param permissionId id полномочия
 */
export function getScopeIds(roles: IUserRole[], scope: UserRoleScopes, permissionId: string): string[] {
	let companies: string[] = [];
	for (const role of roles) {
		if (role.scope !== scope || !role.permissions) {
			continue;
		}
		if (role.permissions.some(item => item.permissionId === permissionId)) {
			companies = companies.concat(role.scopeIds);
		}
	}
	return companies;
}
