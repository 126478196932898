import {ISavingSpace} from '@src/interfaces/saving/ISavingSpace';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IEditSpaceResponse, makeSpaceEditRequest} from '@src/api/backend/space/edit';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IEditSpacePayload = IEditSpaceResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditSpaceResponse, pageObjectId: string) => {
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.detail(response.id)});

	return {
		type: types.EDIT_SUCCESS,
		payload: {...response, pageObjectId}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении помещения');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет помещение
 *
 * @param objectId id объекта
 * @param pageObjectId id страницы объекта
 * @param spaceId id помещения
 * @param fields измененные поля
 */
export const editSpace = (
	objectId: string,
	pageObjectId: string,
	spaceId: string,
	fields: ISavingSpace = {}
) =>
	createApiAction<IEditSpaceResponse>(
		request,
		response => success(response, pageObjectId),
		failure,
		() => makeSpaceEditRequest(objectId, spaceId, fields)
	);
