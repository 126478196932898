import createReducer from '@tehzor/tools/utils/createReducer';
import {
	ADD_LOCAL_INTERNAL_ACCEPTANCE,
	CHANGE_LOCAL_INTERNAL_ACCEPTANCE_MODIFIED_AT,
	DELETE_LOCAL_INTERNAL_ACCEPTANCE
} from '@src/constants/local-entities/internalAcceptance';
import {ILocalInternalAcceptance} from '@src/interfaces/ILocalInternalAcceptance';

type ILocalInternalAcceptancesState = ILocalInternalAcceptance[];

const internalAcceptances = createReducer<ILocalInternalAcceptancesState>([], {
	[ADD_LOCAL_INTERNAL_ACCEPTANCE]: (
		state,
		payload: {internalAcceptance: ILocalInternalAcceptance}
	) => [...state, payload.internalAcceptance],
	[CHANGE_LOCAL_INTERNAL_ACCEPTANCE_MODIFIED_AT]: (
		state,
		payload: {key: string, modifiedAt: number}
	) =>
		state.map(item => {
			if (item.key === payload.key) {
				item.modifiedAt = payload.modifiedAt;
				return item;
			}
			return item;
		}),
	[DELETE_LOCAL_INTERNAL_ACCEPTANCE]: (state, payload: {key: string}) =>
		state.filter(item => item.key !== payload.key)
});

export {ILocalInternalAcceptancesState};
export default internalAcceptances;
