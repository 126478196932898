import { memo } from 'react';
import * as React from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';

interface IPageBreadcrumbsProps {
	objectId: string;
	children?: React.ReactNode;
}

const ObjectPageBreadcrumbs = ({objectId, children}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useObjectBreadcrumbs(objectId);

	return (
		<AppBreadcrumbs
			className="inspections-page__breadcrumbs"
			items={breadcrumbs}
		>
			{children}
		</AppBreadcrumbs>
);
};

export default memo(ObjectPageBreadcrumbs);
