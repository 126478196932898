import {useEffect, useState} from 'react';
import {Dialog} from '../dialogs';
import './MarkerCommentDialog.less';
import {TextField} from '../inputs';
import {ActionButtons, Button} from '../buttons';

export interface IPointCommentInputProps {
	show: boolean;
	onClose: () => void;
	onApply: (pointDescription?: string) => void;
	title: string;
	deleteComment: () => void;
	description: string;
	translation?: {
		save?: string;
		reject?: string;
		addCommentPrompt?: string;
	};
	canEdit: boolean;
}

export const MarkerCommentDialog = ({
	show,
	onClose,
	onApply,
	title,
	deleteComment,
	description,
	translation = {
		addCommentPrompt: 'Добавьте комментарий',
		reject: 'Отменить',
		save: 'Сохранить'
	},
	canEdit
}: IPointCommentInputProps) => {
	const [pointDescription, setPointDescription] = useState<string>('');
	const {addCommentPrompt, reject, save} = translation;
	useEffect(() => {
		if (show) {
			setPointDescription(description);
		}
	}, [show, description]);

	const textFieldProps = {
		maxRows: 4
	};

	return (
		<Dialog
			isOpen={show}
			className={{
				root: 'marker-comment-dialog',
				body: 'marker-comment-dialog__body',
				footer: canEdit ? 'marker-comment-dialog__footer' : undefined,
				layer: 'marker-comment-dialog__layer'
			}}
			onRequestClose={onClose}
			hideCloseButton
			footer={
				canEdit ? (
					<ActionButtons>
						<Button
							type="accent-blue"
							label={save}
							onClick={() => onApply(pointDescription)}
						/>
						<Button
							type="cancel"
							label={reject}
							onClick={onClose}
						/>
					</ActionButtons>
				) : undefined
			}
		>
			<header className="marker-comment-dialog__header">
				<span className="marker-comment-dialog__title">{title}</span>
				<Button
					className="marker-comment-dialog__close"
					leftIcon={<i className="tz-close-20"/>}
					outerTagType="button"
					onClick={onClose}
				/>
				{canEdit && (
					<Button
						className="marker-comment-dialog__delete"
						leftIcon={<i className="tz-delete"/>}
						outerTagType="button"
						onClick={deleteComment}
					/>
				)}
			</header>
			<main>
				{canEdit ? (
					<TextField
						elementType="textarea"
						placeholder={addCommentPrompt}
						value={pointDescription}
						onChange={setPointDescription}
						elementProps={textFieldProps}
						className="marker-comment-dialog__text-input"
					/>
				) : (
					<div className="marker-comment-dialog__text">{pointDescription}</div>
				)}
			</main>
		</Dialog>
	);
};
