import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	ActionButtons,
	Button,
	Dialog,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useAsync, useAsyncFn, useUpdateEffect} from 'react-use';
import {getSpacesSchema} from '@src/store/modules/entities/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {moveWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {useSpacesAsArray} from '@src/core/hooks/queries/spaces/hooks';
import {useSpace} from '@src/core/hooks/queries/space';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from "react-i18next";
import './MovingWarrantyClaimDialog.less';

const arrowIcon = <i className="tz-simple-arrow-20" />;

interface IMovingWarrantyClaimDialogProps {
	objectId: string;
	claim: IWarrantyClaim;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно перемещения гарантийного обращения помещения
 */
export const MovingWarrantyClaimDialog = ({
	objectId,
	claim,
	isOpen,
	onClose
}: IMovingWarrantyClaimDialogProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const objects = useObjectsData();
	const objectsMap = objects?.byId;
	const {data: spaceTypesMap} = useExtractSpaceTypesById();
	const [objectSearch, setObjectSearch] = useState('');
	const [spaceSearch, setSpaceSearch] = useState('');
	const clearObjectSearch = useCallback(() => setObjectSearch(''), []);
	const clearSpaceSearch = useCallback(() => setSpaceSearch(''), []);

	const {filteredData, expanded} = useMemo(() => {
		const objectIds = objects?.allIds || [];
		const treeData = makeObjectsTreeData(objectsMap ? objectIds.map(id => objectsMap[id]) : []);
		return treeFilter(treeData, 'content', objectSearch);
	}, [objectsMap, objectSearch, objects?.allIds]);

	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);

	useUpdateEffect(() => {
		setExpandedObjects(expanded?.map(item => item.id));
	}, [expanded]);

	const [toObjectId, setToObjectId] = useState(objectId);
	const {data: spaces} = useSpacesAsArray(toObjectId);
	const spaceTypeIds = useMemo(
		() => Array.from(new Set(spaces.map(space => space.type))),
		[spaces]
	);
	const [toSpaceType, setToSpaceType] = useState<string | undefined>(undefined);

	const handleObjectChange = useCallback((value: string) => {
		setToObjectId(value);
	}, []);

	const handleSpaceTypeChange = useCallback((value: string) => {
		setToSpaceType(value);
	}, []);

	const [toSpaceId, setToSpaceId] = useState(claim.links?.spaceId);

	const handleSpaceChange = useCallback((value: string) => {
		setToSpaceId(value);
	}, []);

	useAsync(async () => {
		await dispatch(getSpacesSchema(toObjectId));
	}, [toObjectId]);

	const {data: space} = useSpace(claim.links?.spaceId, objectId);

	const filteredSpace = useMemo(() => (
		spaces.filter(
				el =>
					toSpaceType &&
					el.type === toSpaceType &&
					el.name.toLowerCase().indexOf(spaceSearch.toLowerCase()) > -1
			)
			.sort((a: IListSpace, b: IListSpace) => parseInt(a.name) - parseInt(b.name))
	),[spaces, spaceSearch, toSpaceType])


	useEffect(() => {
		if (spaceTypeIds.length) {
			setToSpaceType(spaceTypeIds[0]);
		} else {
			setToSpaceType(undefined);
		}
	}, [spaceTypeIds]);

	useEffect(() => {
		const newSpace = filteredSpace.find(item => item.name === space?.name);
		setToSpaceId(newSpace?.id ?? (filteredSpace.length ? filteredSpace[0].id : undefined));
	}, [spaces, space, toSpaceType, filteredSpace]);

	const [, handleSave] = useAsyncFn(async () => {
		if (toSpaceId) {
			await dispatch(moveWarrantyClaim(objectId, claim.id, toObjectId, toSpaceId));
			pushPath(`/objects/${toObjectId}/warranty-claims/${claim.id}`);
			onClose();
		}
	}, [objectId, claim, toObjectId, toSpaceId]);

	return (
		<Dialog
			isOpen={isOpen}
			title={t('components.movingWarrantyClaimDialog.dialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="accent-blue"
						label={t('components.movingWarrantyClaimDialog.button.accept')}
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label={t('components.movingWarrantyClaimDialog.button.cancel')}
						onClick={onClose}
					/>
				</ActionButtons>
			}
		>
			<div className="moving-warranty-claim-dialog">
				<h3 className="moving-warranty-claim-dialog__subtitle">{t('components.movingWarrantyClaimDialog.dialog.subtitle')} {claim.number}</h3>
				<div className="moving-warranty-claim-dialog__container">
					<div className="moving-warranty-claim-dialog__item">
						<p className="moving-warranty-claim-dialog__item__title">{t('components.movingWarrantyClaimDialog.newObject')}</p>
						{objectsMap && (
							<SelectPopup
								search={
									<SelectSearch
										value={objectSearch}
										onChange={setObjectSearch}
										onClear={clearObjectSearch}
									/>
								}
								trigger={
									<TextFieldWithForwardedRef
										elementType="div"
										value={objectsMap[toObjectId]?.name}
										icon={arrowIcon}
									/>
								}
							>
								<TreeSelect
									data={filteredData}
									value={toObjectId || undefined}
									onChange={handleObjectChange}
									latestOnly
									expandedValue={expandedObjects}
									onExpand={setExpandedObjects}
								/>
							</SelectPopup>
						)}
					</div>
					<div className="moving-warranty-claim-dialog__item">
						<p className="moving-warranty-claim-dialog__item__title">{t('components.movingWarrantyClaimDialog.spaceType')}</p>
						<SelectPopup
							noHeader
							trigger={
								<TextFieldWithForwardedRef
									elementType="div"
									value={toSpaceType && spaceTypesMap?.[toSpaceType]?.name}
									icon={arrowIcon}
								/>
							}
						>
							<Select2
								value={toSpaceType}
								onChange={handleSpaceTypeChange}
							>
								{spaceTypeIds.map(id => (
									<SelectOption
										inputType="radio"
										key={id}
										itemKey={id}
										content={spaceTypesMap?.[id]?.name}
									/>
								))}
							</Select2>
						</SelectPopup>
					</div>
					<div className="moving-warranty-claim-dialog__item">
						<p className="moving-warranty-claim-dialog__item__title">{t('components.movingWarrantyClaimDialog.newSpace')}</p>
						<SelectPopup
							search={
								<SelectSearch
									value={spaceSearch}
									onChange={setSpaceSearch}
									onClear={clearSpaceSearch}
								/>
							}
							trigger={
								<TextFieldWithForwardedRef
									elementType="div"
									value={spaces.find(s => s.id === toSpaceId)?.name}
									icon={arrowIcon}
								/>
							}
						>
							<Select2
								value={toSpaceId}
								onChange={handleSpaceChange}
							>
								{filteredSpace.map(item => (
									<SelectOption
										key={item.id}
										itemKey={item.id}
										content={item.name}
										inputType="radio"
									/>
								))}
							</Select2>
						</SelectPopup>
					</div>
				</div>

			</div>
		</Dialog>
	);
};
