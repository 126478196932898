import {useAppHeader} from './useAppHeader';
import {AppHeaderCtxOptions} from '../utils/AppHeaderCtx';
import {DependencyList} from 'react';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';

export const useObjectAppHeader = (
	objectId: string,
	options?: AppHeaderCtxOptions,
	deps?: DependencyList
) => {
	const object = useCurrentTreeObject(objectId);

	useAppHeader(
		{title: object?.name, showBackBtn: true, ...options},
		deps ? deps.concat([object]) : [object]
	);
};
