import {
	ADD_LOCAL_CHECK,
	CHANGE_LOCAL_CHECK_MODIFIED_AT,
	DELETE_LOCAL_CHECK
} from '@src/constants/local-entities/checks';
import {ILocalCheck} from '@src/interfaces/ILocalCheck';

/**
 * Добавляет проверку в локальное хранилище
 *
 * @param {ILocalCheck} check проверка, пригодная для сохранения локально
 * @returns {{type, payload}} action
 */
export const addLocalCheck = (check: ILocalCheck) => ({
	type: ADD_LOCAL_CHECK,
	payload: {check}
});

export const changeLocalCheckModifiedAt = (key: string, modifiedAt: number) => ({
	type: CHANGE_LOCAL_CHECK_MODIFIED_AT,
	payload: {key, modifiedAt}
});

/**
 * Удаляет проверку из локального хранилища
 *
 * @param {string} key id локальной проверки
 * @returns {{type, payload}} action
 */
export const deleteLocalCheck = (key: string) => ({
	type: DELETE_LOCAL_CHECK,
	payload: {key}
});
