import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export type IUnbindSpaceFromOwnerResponse = ISpaceOwner | undefined;

/**
 * Отвязывает собственника от помещения
 *
 * @param ownerId id собственника
 * @param spaceId id помещения
 * @param removeIfLast удалять собственника, если у него не останется помещений
 */
export const makeUnbindSpaceFromOwnerRequest = async (
	ownerId: string,
	spaceId: string,
	removeIfLast?: boolean
) => {
	const res = await httpRequests.withToken.patch<IUnbindSpaceFromOwnerResponse>(
		'space-owners/unbind-space-from-owner',
		{
			ownerId,
			spaceId,
			removeIfLast
		}
	);
	return res.data;
};
