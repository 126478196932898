export type IMetersListQueryKey = [] & {
	0: string; // meters
	1: string; // list
	2: string; // spaceId
};

export const metersQueryKeys = {
	all: () => ['meters'],
	one: () => ['meter'],
	lists: () => [...metersQueryKeys.all(), 'list'],
	list: (spaceId: string) => [...metersQueryKeys.lists(), spaceId]
};
