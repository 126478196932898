import { useMemo } from 'react';
import {StatusSelect} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import ClickPreventWrap from './ClickPreventWrap';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {useEditOwnerAcceptanceStatus} from '@src/core/hooks/mutations/ownerAcceptances/useEditOwnerAcceptanceStatus';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useOwnerAcceptancesStatuses} from '@src/core/hooks/queries/ownerAcceptancesStatuses/hooks';

const OwnerAcceptanceStatusCell = ({row}: CellProps<{data: IOwnerAcceptance}>) => {
	const acceptance = row.original.data;
	const {id, objectId, status, resolution} = acceptance;
	const {data: statuses} = useOwnerAcceptancesStatuses();
	const translatedOwnerAcceptance = useTranslatedDictionary('ownerAcceptanceStatuses', statuses);
	const permissions = useOwnerAcceptancesPermissions(objectId);

	const isStatusHidden = useMemo(
		() => resolution === OwnerAcceptanceResolutionId.ACCEPTED,
		[resolution]
	);

	const editOwnerAcceptanceStatus = useEditOwnerAcceptanceStatus();

	const [, handleChange] = useAsyncFn(
		async (v: OwnerAcceptanceStatusId) => {
			await editOwnerAcceptanceStatus({objectId, acceptanceId: id, statusId: v});
		},
		[objectId, id]
	);

	return !isStatusHidden ? (
		<ClickPreventWrap>
			<StatusSelect
				style={{maxWidth: '100%'}}
				statuses={translatedOwnerAcceptance}
				disabled={!permissions.canEditStatus}
				value={status}
				onChange={handleChange}
			/>
		</ClickPreventWrap>
	) : null;
};

export default OwnerAcceptanceStatusCell;
