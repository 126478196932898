/**
 * Возвращает ближайший элемент со скроллом среди предков
 *
 * @param node элемент, от которого начинается поиск, или id элемента-контейнера
 */
export function getScrollContainer(node: HTMLElement | string) {
	if (typeof node === 'string') {
		const element = document.getElementById(node);
		if (element) {
			return element;
		}
	} else {
		let parent: HTMLElement | null = node;
		do {
			if (parent === document.body) {
				return window;
			}
			const overflowYVal = getComputedStyle(parent, null).getPropertyValue('overflow-y');
			if (overflowYVal === 'auto' || overflowYVal === 'scroll') {
				return parent;
			}
			parent = parent.parentElement;
		} while (parent);
	}
	return window;
}
