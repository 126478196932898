import {IPreparedSpaceStatusesSet} from '@src/pages/manage/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import declination from '@tehzor/tools/utils/declination';
import { useMemo } from 'react';
import {CellProps} from 'react-table';

const words = ['статус', 'статуса', 'статусов'];

export const NameCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) => {
	const {name, statuses} = row.original;

	const amount = useMemo(() => {
		const total = row.original.statuses.length ?? 0;
		return `${total} ${declination(total, words)}`;
	}, [statuses, row.original.statuses]);

	return (
		<div className="space-statuses-sets__cell-name">
			<div className="space-statuses-sets__cell-name-text">{name}</div>
			<div className="space-statuses-sets__cell-name-amount">{amount}</div>
		</div>
	);
};
