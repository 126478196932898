import {createRoot} from 'react-dom/client';
import {store, persistor} from './store/appStore';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SocketConnector from './api/SocketConnector';
import LocalEntitiesManager from './core/LocalEntitiesManager';
import '@tehzor/ui-components/src/index.less';
import './styles/main.less';
import Root, {IRootProps} from './containers/Root';
import {storeNotificationMessage} from '@src/actions/notifications/storeNotificationMessage';
import {wsConnector} from '@src/api/backend/wsConnector';
import config from '@src/core/config';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {tokenUpdater} from '@tehzor/tools/api/updateTokens';
import {queryClient} from './api/QueryClient';
import {ReactElement} from 'react';
import './core/i18n';

require('./utils/polyfills');
require('./utils/mathAddons');

const {apiUrl, newApiUrl, newWsApiUrl, appId} = config;

const socketConnector = SocketConnector.instance;

const localEntitiesManager = LocalEntitiesManager.instance;

const customLogout = async () => {
	store.dispatch({type: 'auth/logout'});
	store.dispatch({type: 'auth/clear-store'});
	await queryClient.cancelQueries();
};
const render = (Component: (props: IRootProps) => ReactElement) => {
	tokenUpdater.initialize(appId);
	// Инициализация модуля http-запросов
	if (newApiUrl) {
		httpRequests.initialize(newApiUrl, customLogout);
	}
	// Подключение по websocket к api
	if (newWsApiUrl) {
		wsConnector.connect(newWsApiUrl, undefined, undefined, customLogout);
	}
	socketConnector.initialize(store, storeNotificationMessage);
	socketConnector.connect(apiUrl);

	localEntitiesManager.initialize(store);

	const container = document.getElementById('app');
	const root = createRoot(container!);

	root.render(
		<Component
			store={store}
			persistor={persistor}
		/>
	);
};

render(Root);
