import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {DesktopAddButton} from './DesktopAddButton';
import {useWorkingGroupPermissions} from '@src/core/hooks/permissions/useWorkingGroupPermissions';
import {useManageWorkingGroupBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageWorkingGroupBreadcrumbs';

export const PageBreadcrumbs = () => {
	const breadcrumbs = useManageWorkingGroupBreadcrumbs();
	const permissions = useWorkingGroupPermissions();

	return (
		<AppBreadcrumbs
			className="manage-working-groups-page__breadcrumbs"
			items={breadcrumbs}
		>
			{permissions.canAdd ? <DesktopAddButton/> : null}
		</AppBreadcrumbs>
	);
};
