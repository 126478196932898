import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeInternalAcceptanceExportRequest} from '@src/api/backend/internalAcceptance';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {addExportToast} from '@src/components/ExportToast/addExportToast';

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse) => {
	if (response.link) {
		addExportToast(
			'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при экспорте');
	return {
		type: types.EXPORT_FAILURE,
		payload: {...error}
	};
};

/**
 * Экспортирует внутреннюю приёмку
 *
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 * @param problems выбранные нарушения
 * @param inspections выбранные осмотры
 * @param createDocument флаг создания документа
 *
 */
export const exportInternalAcceptance = (
	templateId: string,
	objectId: string,
	internalAcceptanceId: string,
	problems?: string[],
	inspections?: string[],
	createDocument?: boolean
) =>
	createApiAction<IExportResponse>(request, success, failure, () =>
		makeInternalAcceptanceExportRequest(
			templateId,
			objectId,
			internalAcceptanceId,
			problems,
			inspections,
			createDocument
		)
	);
