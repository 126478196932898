import {PageHeader} from '@tehzor/ui-components';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useWorkAcceptances} from '@src/core/hooks/queries/workAcceptances';
import {useTranslation} from 'react-i18next';

interface IWorkAcceptancesPageHeaderProps {
	objectId?: string;
}

export const WorkAcceptancesPageCounter = ({objectId = 'all'}: IWorkAcceptancesPageHeaderProps) => {
	const {t} = useTranslation();
	const {data: workAcceptancesData} = useWorkAcceptances({objectId});
	const {state} = useEntitiesFiltersCtx<IWorkAcceptancesFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);
	const total = workAcceptancesData?.total || 0;
	const text = `${
		isFiltersActive
			? t('workAcceptancesPage.workAcceptancesPageCounter.find')
			: t('workAcceptancesPage.workAcceptancesPageCounter.total')
	}: ${total}`;

	return (
		<PageHeader
			className="work-acceptances-page__header"
			title=""
			subTitle={text}
			wrapClassName="work-acceptances-page__header-wrap"
			leftSideClassName="work-acceptances-page__header-left"
			rightSideClassName="work-acceptances-page__header-right"
		/>
	);
};
