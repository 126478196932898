import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '@src/constants/notifications/subscriptions';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import INotificationSubscription from '@tehzor/tools/interfaces/INotificationSubscription';
import {
	getLoadedEntitiesSuccess,
	ILoadedEntityState,
	getLoadedEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';

type INotificationsSubscriptionsState = ILoadedEntityState<INotificationSubscription>;

const initialState = {
	byId: {},
	allIds: [],
	loaded: false
} as INotificationsSubscriptionsState;

const subscriptions = createReducer(initialState, {
	[types.GET_NOTIFICATION_SUBSCRIPTIONS_SUCCESS]:
		getLoadedEntitiesSuccess<INotificationSubscription>(),
	[types.EDIT_NOTIFICATION_SUBSCRIPTIONS_SUCCESS]: (state, payload) => ({
		allIds: payload.allIds,
		byId: payload.byId,
		loaded: true
	}),
	[CLEAR_STORE]: getLoadedEntitiesInitialState
});

export {INotificationsSubscriptionsState};
export default subscriptions;
