import * as React from 'react';
import './CircleButton.less';
import classNames from 'classnames';
import ButtonBase from '../ButtonBase';

export type ButtonType = 'common' | 'dark';

interface ICloseButtonProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	type?: ButtonType;
	disabled?: boolean;

	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const CircleButton = ({className, style, children, type = 'common', disabled, onClick}: ICloseButtonProps) => (
	<ButtonBase
		className={classNames(`circle-button_${type}`, className)}
		style={style}
		leftIcon={children}
		disabled={disabled}
		classNamePrefix="circle-button"
		outerTagType="button"
		onClick={onClick}
	/>
);

export default CircleButton;