import * as React from 'react';
import './NewImage.less';
import classNames from 'classnames';
import AttachmentPreview from '../AttachmentPreview';
import InlineButton from '../buttons/InlineButton';
import Progress, {ProgressType} from '../Progress';
import filesize from 'filesize';

const bucketIcon = <i className="tz-delete new-attachment__btn-icon"/>;
const reloadIcon = <i className="tz-reload new-attachment__btn-icon"/>;
const cancelIcon = <i className="tz-close-16 new-attachment__btn-icon"/>;

type NewAttachmentStatus = 'waiting' | 'started' | 'finished' | 'error';

interface INewAttachmentProps {
	className?: string;
	style?: {};
	id: string;
	name: string;
	url: string;
	type: string;
	size?: number;
	progress: number;
	status: NewAttachmentStatus;
	disabled?: boolean;

	onDeleteClick?(id: string): void;

	onReloadClick?(id: string): void;
}

class NewImage extends React.PureComponent<INewAttachmentProps> {
	render() {
		const {className, style, id, name, url, progress, status} = this.props;
		const classes = classNames('new-image', className);

		const size = this._getFormattedFileSize();
		const progressTitle = this._getProgressTitle();
		const progressType = this._getProgressType();
		const buttons = this._getButtons();

		return (
			<div
				className={classes}
				style={style}
			>
				<AttachmentPreview
					className="new-image__preview"
					id={id}
					url={url}
					error={status === 'error'}
				/>

				<div className="new-image-title-block">
					<div className="new-image__title">{name}</div>
					<div className="new-image__file-size">{size}</div>
				</div>

				<div className="new-image__progress-block">
					{progressTitle}

					<Progress
						className="new-image__progress"
						percent={progress}
						type={progressType}
					/>
				</div>

				<div className="new-image__controls-block">{buttons}</div>
			</div>
		);
	}

	private _getFormattedFileSize = () => {
		const {size} = this.props;
		if (size === undefined) {
			return '';
		}
		return filesize(size, {round: 0});
	};

	private _getProgressTitle = (): React.ReactNode => {
		const {progress, status} = this.props;
		if (status === 'finished') {
			return (
				<div className="new-image__progress-title">
					Загружено:
					{' '}
					<span className="new-image__progress-title-success">
						{progress}
						%
					</span>
				</div>
			);
		}
		if (status === 'error') {
			return (
				<div className="new-image__progress-title">
					<span className="new-attachment__progress-title-error">Ошибка при загрузке</span>
				</div>
			);
		}
		return (
			<div className="new-image__progress-title">
				Загружено:
				{' '}
				<span className="new-image__progress-title-started">
					{progress}
					%
				</span>
			</div>
		);
	};

	private _getProgressType = (): ProgressType => {
		const {status} = this.props;
		if (status === 'finished') {
			return 'success';
		}
		if (status === 'error') {
			return 'error';
		}
		return 'started';
	};

	private _getButtons = (): React.ReactNode[] => {
		const {status, disabled} = this.props;
		const buttons = [];

		if (status === 'error') {
			buttons.push(
				<InlineButton
					type="accent"
					key="reload"
					className="new-image__btn"
					label="Повторить"
					labelClassName="new-image__btn-label"
					leftIcon={reloadIcon}
					disabled={disabled}
					onClick={this._handleReloadClick}
				/>
			);
		}
		if (status === 'finished' || status === 'error') {
			buttons.push(
				<InlineButton
					key="delete"
					className="new-image__btn"
					type="cancel"
					label="Удалить"
					labelClassName="new-image__btn-label"
					leftIcon={bucketIcon}
					disabled={disabled}
					onClick={this._handleDeleteClick}
				/>
			);
		}
		if (status === 'waiting' || status === 'started') {
			buttons.push(
				<InlineButton
					key="cancel"
					className="new-image__btn"
					type="cancel"
					label="Отменить"
					labelClassName="new-image__btn-label"
					leftIcon={cancelIcon}
					disabled={disabled}
					onClick={this._handleDeleteClick}
				/>
			);
		}
		return buttons;
	};

	private _handleDeleteClick = () => {
		const {id, onDeleteClick} = this.props;
		if (onDeleteClick) {
			onDeleteClick(id);
		}
	};

	private _handleReloadClick = () => {
		const {id, onReloadClick} = this.props;
		if (onReloadClick) {
			onReloadClick(id);
		}
	};
}

export default NewImage;
