import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {IGetCheckRecordStatusesResponse} from '@src/api/backend/checkRecordStatuses';
import {
	extractCheckRecordStatusesByEntityType,
	extractCheckRecordStatusesByEntityTypeAsArray,
	extractCheckRecordStatusesById
} from './selectors';
import {useCallback} from 'react';
import {CheckRecordsStatusesType} from './interfaces';

export const useCheckRecordsStatuses = <T = IGetCheckRecordStatusesResponse>(
	select?: (data: IGetCheckRecordStatusesResponse) => T
) =>
	useQuery<IGetCheckRecordStatusesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.checkRecordStatuses(),
		meta: {
			error: 'при загрузке статусов категорий чек-листа'
		},
		select
	});

export const useExtractCheckRecordStatusesById = () =>
	useCheckRecordsStatuses(extractCheckRecordStatusesById);

export const useExtractCheckRecordStatusesByEntityType = (type: CheckRecordsStatusesType) => {
	const selector = useCallback(
		(data: IGetCheckRecordStatusesResponse) =>
			extractCheckRecordStatusesByEntityType(data, type),
		[type]
	);

	return useCheckRecordsStatuses(selector);
};

export const useExtractCheckRecordStatusesByEntityTypeAsArray = (
	type: CheckRecordsStatusesType
) => {
	const selector = useCallback(
		(data: IGetCheckRecordStatusesResponse) =>
			extractCheckRecordStatusesByEntityTypeAsArray(data, type),
		[type]
	);

	return useCheckRecordsStatuses(selector);
};
