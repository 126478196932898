import {IGetDevicesResponse, requestGetDevices} from '@src/api/backend/devices/get';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';
import {addErrorToast} from '@src/utils/toasts';
import preventExtraLoading from '@tehzor/tools/core/preventExtraLoading';
import IError from '@tehzor/tools/interfaces/IError';
import {checkExpiration} from '@tehzor/tools/core/storeHelpers/actions';
import * as types from '../constants';
import {StoreError} from '@tehzor/tools/errors/StoreError';

export type IGetDevicesPayload = IGetDevicesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetDevicesResponse) => ({
	type: types.GET_SUCCESS,
	payload: {...response} as IGetDevicesPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка устройств');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

export const getDevices = (): AppThunkAction<Promise<IGetDevicesResponse>> =>
	(dispatch, getState) =>
		dispatch(
			preventExtraLoading(
				'devices',
				checkExpiration<IState, IGetDevicesResponse, ApiAction>(
					s => s.entities.devices,
					createApiAction<IGetDevicesResponse>(
						request, success, failure, () => {
							const s = getState();
							if (!s.entities.devices) {
								throw new StoreError('Empty store before getting devices');
							}
							return requestGetDevices();
						}
					)
				)
			)
		);
