import {useMemo} from 'react';
import './InspectionPage.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useAsync} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {extractInspection} from '@src/store/modules/entities/inspection/selectors/inspection';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getInspection} from '@src/store/modules/entities/inspection/actions';
import {useIsDesktop, useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Tablet from './components/Tablet';
import Mobile from './components/Mobile';
import MobileRightButtons from './components/actions/RightButtons.mobile';
import {convertLocalAttachments} from '@src/utils/convertLocalAttachments';
import {ISavingInspectionQuery} from '@src/core/hooks/mutations/inspection/useAddInspection';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useTranslation} from 'react-i18next';
import {useInspectionSettings} from '@src/core/hooks/settings/useInspectionSettings';

const InspectionPage = () => {
	const {objectId, inspectionId} = useStrictParams<{objectId: string; inspectionId: string}>();
	const {t} = useTranslation();

	useScrollRestoration();
	const dispatch = useAppDispatch();
	const settings = useInspectionSettings(objectId);

	useAsync(async () => {
		await dispatch(getInspection(objectId, inspectionId));
	}, [objectId]);

	const extractedInspection = useAppSelector(extractInspection);
	const inspection = useMemo(
		() =>
			extractedInspection?.localNumber
				? {
						...extractedInspection,
						attachments: convertLocalAttachments<ISavingInspectionQuery>(
							extractedInspection.id,
							inspectionsQueryKeys
						)
				  }
				: extractedInspection,
		[extractedInspection]
	);

	useAppHeader(
		{
			title: inspection
				? `${t('inspectionPage.header')} №${inspection.number || inspection.localNumber}`
				: '',
			showBackBtn: true,
			mobileRightButtons: inspection ? (
				<MobileRightButtons
					objectId={objectId}
					inspection={inspection}
				/>
			) : null
		},
		[inspection]
	);
	const isTablet = useIsTablet();
	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				inspectionId={inspectionId}
				inspection={inspection}
				settings={settings}
			/>
		);
	}
	if (isTablet) {
		return (
			<Tablet
				objectId={objectId}
				inspection={inspection}
				settings={settings}
			/>
		);
	}

	return (
		<Mobile
			objectId={objectId}
			inspection={inspection}
			settings={settings}
		/>
	);
};

export default InspectionPage;
