import {AddTempFileFn, DeleteTempFileFn} from '@tehzor/tools/components/withFileUploader';
import IFile from '@tehzor/tools/interfaces/IFile';
import {useCallback, useRef, useState} from 'react';
import './PlanImage.less';
import {AddingDocuments, Button, FilesPicker} from '@tehzor/ui-components';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';
import {convertRawToUploading} from '@src/utils/attachments/filesConverters';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import {TempFileDestination} from '@tehzor/tools/enums/TempFileDestination';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import ITempFile from '@tehzor/tools/interfaces/ITempFile';

interface IPlanImageProps {
	fullImage?: IFile;
	onChange?: (file: ITempFile | null) => void;
	addTempFile: AddTempFileFn;
	deleteTempFile: DeleteTempFileFn;
}

export const PlanImage = ({fullImage, onChange, addTempFile, deleteTempFile}: IPlanImageProps) => {
	const [files, setFiles] = useState<IUploadingFile[]>([]);
	const pickerRef = useRef<FilesPicker>(null);

	const online = useAppSelector(extractNetworkStatus);

	const handleFileChange = useCallback(
		(value: IUploadingFile[]) => {
			const newFile = value[value.length - 1];
			setFiles(newFile ? [newFile] : []);

			if (onChange) {
				onChange(newFile && newFile.tempFile ? newFile.tempFile : null);
			}
		},
		[onChange]
	);

	const handleOpenPicker = useCallback(() => {
		if (pickerRef.current) {
			pickerRef.current.open();
		}
	}, []);

	const handleFilesPick = useCallback((value: IRawFile[]) => {
		const newFile = convertRawToUploading(
			value[value.length - 1],
			TempFileDestination.PLAN_IMAGE,
			UploadingFileStatus.WAITING,
			false
		);
		setFiles(newFile ? [newFile] : []);
	}, []);

	return (
		<div className="plan-image">
			<AddingDocuments
				onAddTempFile={addTempFile}
				onDeleteTempFile={deleteTempFile}
				uploadingFiles={files}
				onUploadingFilesChange={handleFileChange}
				online={online}
			/>

			{!files.length && <div>{fullImage ? fullImage.name : ''}</div>}

			<Button
				className="plan-image__btn"
				label="Выбрать файл"
				onClick={handleOpenPicker}
				disabled={files.length >= 1}
			/>

			<FilesPicker
				accept="image/jpeg,image/png,image/gif"
				multiple={false}
				onChange={handleFilesPick}
				ref={pickerRef}
			/>
		</div>
	);
};
