import {useMutation} from "@tanstack/react-query";
import {tasksQueryKeys} from "@src/api/cache/tasks/keys";
import {IMakeTaskEditRequestData, makeTaskEditRequest} from "@src/api/backend/task";
import {addErrorToast} from "@src/utils/toasts";
import {queryClient} from "@src/api/QueryClient";

export const useEditTask = () => useMutation({
	mutationKey: tasksQueryKeys.edit(),
	mutationFn: (data: IMakeTaskEditRequestData) => makeTaskEditRequest(data),
	onError: () => addErrorToast('Ошибка', 'при обновлении задачи'),
	onSuccess: (data) => {
		queryClient.setQueryData(tasksQueryKeys.detail(data.id), (task) => (task ? ({...task, ...data}) : data))
		void queryClient.invalidateQueries({queryKey: tasksQueryKeys.all()})
	}
})