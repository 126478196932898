import { useCallback, useState } from 'react';
import * as React from 'react';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {TasksDialog} from '@src/components/TasksDialog/TasksDialog';

interface IUseTasksDialog {
	tasksDialog: React.ReactElement | null;
	openTasksDialog: () => void;
}

export const useTasksDialog = (tasks?: ITask[]): IUseTasksDialog => {
	const [isOpen, setIsOpen] = useState(false);

	const totalCount = tasks?.length || 0;

	const onOpen = useCallback(() => {
		setIsOpen(true);
	}, [setIsOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	return {
		tasksDialog: isOpen ? (
			<TasksDialog
				tasks={tasks}
				totalCount={totalCount}
				title="Задачи"
				isOpen={isOpen}
				onClose={onClose}
			/>
		) : null,
		openTasksDialog: onOpen
	};
};
