import { useCallback, useState } from 'react';
import * as React from 'react';
import EditableInspectionDialog from '../EditableInspectionDialog';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export function useEditableInspectionDialog(
	objectId: string,
	inspection: IInspection
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<EditableInspectionDialog
			objectId={objectId}
			inspection={inspection}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
}
