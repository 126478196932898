import {Column} from 'react-table';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemStatusCell} from '@src/components/tableCells/ProblemStatusCell';
import {ProblemFixCell} from '@src/components/tableCells/ProblemFixCell';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {extractWorkAcceptanceProblemCommentsByProblem} from '@src/store/modules/entities/workAcceptance/selectors/workAcceptance';
import InspectionInfoDesktopCell from '@src/components/tableCells/InspectionInfoDesktopCell';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import {InspectionFixCell} from '@src/components/tableCells/InspectionFixCell';

export const useWorkAcceptanceDesktopColumns = (): Array<Column<IWorkAcceptanceEntity>> => {
	const {t} = useTranslation();

	return useMemo(
		() => [
			{
				id: 'number',
				Header: HeaderCellWithRowSelectWrap(
					`№, ${t('workAcceptancePage.workAcceptanceDesktopColumns.number.header')}`
				),
				Cell: CellWrap({
					problem: ProblemInfoCellWrap(
						ProblemInfoDesktopCell,
						extractWorkAcceptanceProblemCommentsByProblem
					),
					inspection: InspectionInfoDesktopCell
				}),
				width: 200,
				minWidth: 200
			},
			{
				id: 'status',
				Header: t('workAcceptancePage.workAcceptanceDesktopColumns.status.header'),
				Cell: CellWrap({
					problem: ProblemStatusCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'category',
				Header: t('workAcceptancePage.workAcceptanceDesktopColumns.category.header'),
				Cell: CellWrap({
					problem: CategoryCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'fix',
				Header: t('workAcceptancePage.workAcceptanceDesktopColumns.fix.header'),
				Cell: CellWrap({problem: ProblemFixCell, inspection: InspectionFixCell}),
				width: 80,
				minWidth: 80
			}
		],
		[t]
	);
};
