import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useEditingTaskDialog} from '@src/components/EditingTaskDialog';
import {useTask} from '@src/core/hooks/queries/task/hooks';
import {useDeleteTask} from '@src/core/hooks/mutations/task/useDeleteTask';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';

interface IMenuProps {
	taskId: string;
}

const Menu = ({taskId}: IMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {mutateAsync: deleteTask} = useDeleteTask();

	const {data: task} = useTask(taskId);
	const permissions = useTasksPermissions({
		objectId: task?.objectId,
		activeGroupLeader: task?.activeGroupLeader?.id,
		respUsers: task?.respUsers,
		createdBy: task?.createdBy?.id,
		watchers: task?.watchers
	});
	const [taskEditDialog, handleOpenEditTaskDialog] = useEditingTaskDialog({taskId: task?.id});

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.task.deleteTitle'),
		message: t('useConfirmDialog.task.deleteMessage'),
		props: {acceptBtnProps: {type: 'accent-red'}}
	});

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const handleDeleteClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			const deleteTaskParams = {
				taskId,
				objectId: task?.objectId,
				workAcceptanceId: task?.links?.workAcceptanceId
			};
			await deleteTask(deleteTaskParams);
			goBack();
		}
	}, [taskId, task, getDeleteConfirmation, deleteTask, goBack]);

	const items = [];
	items.push(updateData);
	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit" />}
				onClick={handleOpenEditTaskDialog}
			>
				{t('taskViewPage.menu.edit')}
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={handleDeleteClick}
			>
				{t('taskViewPage.menu.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
			{permissions.canEdit && taskEditDialog}
		</>
	) : null;
};

export default Menu;
