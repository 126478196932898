import {memo, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useSpaceOwnersAsArray} from '@src/core/hooks/queries/spaceOwners/hooks';

interface ISpaceOwnersFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpaceOwnersFilterMobile = memo(
	({objectId, value, entity, onChange}: ISpaceOwnersFilterMobileProps) => {
		const {t} = useTranslation();
		const [selected, setSelected] = useState(value);
		const [search, setSearch] = useState('');

		const {data: spaceOwners} = useSpaceOwnersAsArray();
		const elements = useMemo(
			() =>
				spaceOwners?.map(spaceOwner => ({id: spaceOwner.id, name: spaceOwner?.name})) || [],
			[spaceOwners]
		);
		const filteredElements = useMemo(
			() => flatFilter(elements, 'name', search),
			[search, spaceOwners]
		);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, 'spaceowners');

		const handleChange = (v: string[] | undefined) => {
			onChange({spaceOwners: v});
		};

		return (
			<MobileFilter
				label={t('entitiesFilters.spaceOwnersFilter.label')}
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
			/>
		);
	}
);
