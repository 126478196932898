import {PageHeader} from '@tehzor/ui-components';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/interfaces';
import {useInternalAcceptancesQuery} from '@src/core/hooks/queries/internalAcceptances/hooks';
import {useTranslation} from "react-i18next";

interface IInternalAcceptancesPageHeaderProps {
	objectId?: string;
}

export const InternalAcceptancesPageCounter = ({
	objectId = 'all'
}: IInternalAcceptancesPageHeaderProps) => {
	const {t} = useTranslation();
	const {data: internalAcceptancesData} = useInternalAcceptancesQuery(objectId);
	const {state} = useEntitiesFiltersCtx<IChecksFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);
	const text = `${isFiltersActive ?
		t('internalAcceptancesPage.internalAcceptancesPageCounter.find') :
		t('internalAcceptancesPage.internalAcceptancesPageCounter.total')}:
		${internalAcceptancesData?.total || 0
	}`;
	return (
		<PageHeader
			className="internal-acceptances-page__header"
			title=""
			subTitle={text}
			wrapClassName="internal-acceptances-page__header-wrap"
			leftSideClassName="internal-acceptances-page__header-left"
			rightSideClassName="internal-acceptances-page__header-right"
		/>
	);
};
