import {toast, ToastOptions} from 'react-toastify';
import './ExportToast.less';
import {IconButton} from '@tehzor/ui-components';
import {ExportToast} from './ExportToast';
import {IDocument} from '@tehzor/types-and-subjects/ts/documents';

const exportIcon = <i className="tz-notifications-export-28" />;
const sendIcon = <i className="tz-notifications-send-28" />;

export const addExportToast = (
	title: string,
	url: string,
	fileName: string,
	description?: string,
	document?: IDocument,
	options?: ToastOptions,
	isSend?: boolean
) => {
	const toastId = toast(
		() => (
			<ExportToast
				title={title}
				description={description}
				documentRecord={document}
				onClose={() =>
					toast.dismiss({id: toastId, containerId: 'AppNotificationsContainer'})
				}
				url={url}
				fileName={fileName}
				icon={isSend ? sendIcon : exportIcon}
			/>
		),
		{
			closeOnClick: false,
			autoClose: false,
			className: 'export-toast',
			position: 'bottom-right',
			containerId: 'AppNotificationsContainer',
			closeButton: (
				<IconButton
					className="export-toast__close"
					onClick={() =>
						toast.dismiss({id: toastId, containerId: 'AppNotificationsContainer'})
					}
				>
					<i className="tz-close-24" />
				</IconButton>
			),
			...options
		}
	);
};
