import {useCallback, useMemo, useState} from 'react';
import * as React from 'react';
import './EntitiesDesktopPhotoViewer.less';
import DesktopPhotoViewer, {IDesktopPhotoViewerPropsTranslations} from '../DesktopPhotoViewer';
import {useCachedIndexes} from '../EntitiesMobilePhotoViewer/utils/useCachedIndexes';
import {getCommonIndex} from '../EntitiesMobilePhotoViewer/utils/getCommonIndex';
import classNames from 'classnames';
import {InfiniteScroller} from '../../containers';
import Entity from './components/Entity';
import {format} from 'date-fns';
import IconButton from '../../buttons/IconButton';
import {getActiveEntityTitle} from '../EntitiesMobilePhotoViewer/utils/getActiveEntityTitle';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import ICanvasData from '@tehzor/tools/interfaces/ICanvasData';
import {getCanvasData} from '../EntitiesMobilePhotoViewer/utils/genCanvasData';
import {ICanvasRefObject} from '../../Canvas/Canvas';
// import {getActiveEntityEditRule} from '../EntitiesMobilePhotoViewer/utils/getActiveEntityEditRule';

export interface IEntity {
	id: string;
	title: string;
	files: string[];
	date?: number;
	type?: AttachmentFileDestination;
	attachments?: Array<{
		id: string;
		url: string;
		canvas?: ICanvasData;
		original?: string;
	}>;
}

export interface IEntitiesDesktopPhotoViewerTranslations {
	desktopPhotoViewer: IDesktopPhotoViewerPropsTranslations;
}

interface IEntitiesDesktopPhotoViewerProps {
	className?: string;
	style?: React.CSSProperties;
	entities: IEntity[];
	activeId?: string;
	activeIndex?: number;
	isOpen: boolean;
	loadingEnabled?: boolean;
	dateOptions?: Parameters<typeof format>[2];
	canDraw?: boolean;
	translations: IEntitiesDesktopPhotoViewerTranslations;

	saveEditedImage?: (editedImageData: object) => void;
	onChange: (id: string, index: number) => void;
	onClose?: () => void;
	onLoad?: () => void;
}

const EntitiesDesktopPhotoViewer = (props: IEntitiesDesktopPhotoViewerProps) => {
	const {
		className,
		style,
		entities,
		activeId,
		activeIndex,
		isOpen,
		dateOptions,
		loadingEnabled,
		saveEditedImage,
		onChange,
		onClose,
		onLoad,
		canDraw,
		translations
	} = props;

	const [drawData, setDrawData] = useState<ICanvasRefObject>();
	const images = useMemo(
		() => entities.reduce<string[]>((prev, item) => prev.concat(item.files), []),
		[entities]
	);
	const indexes = useCachedIndexes(entities);
	const title = getActiveEntityTitle(entities, activeId);
	// const editable = getActiveEntityEditRule(entities, activeId);
	const canvasData = getCanvasData(entities, activeId, activeIndex);
	const value = getCommonIndex(indexes, activeId, activeIndex);

	const handleChange = useCallback(
		(index: number) => {
			if (!onChange) {
				return;
			}

			for (const id in indexes) {
				if (
					indexes.hasOwnProperty(id) &&
					indexes[id].from <= index &&
					indexes[id].to >= index
				) {
					onChange(id, index - indexes[id].from);
					return;
				}
			}
		},
		[indexes, onChange]
	);

	// Блокировка подгрузки, пока окно не будет открыто
	const [loadingBlocked, setLoadingBlocked] = useState(true);
	const handleAfterOpen = useCallback(() => {
		setLoadingBlocked(false);
	}, []);

	const handleClose = useCallback(() => {
		setLoadingBlocked(true);
		if (onClose) {
			onClose();
		}
	}, [onClose]);

	const handleCanvasChange = useCallback((data: ICanvasRefObject) => {
		setDrawData(data);
	}, []);
	const handleClear = useCallback(() => {
		if (drawData) {
			drawData.clear();
		}
	}, [drawData]);
	const handleSave = useCallback(async () => {
		if (!saveEditedImage || !drawData || !drawData.canvas) {
			return;
		}
		const entity = entities.find(ent => ent.id === activeId);
		const attachmentId = entity?.attachments?.find(att => att.url === images[value])?.id;
		const blobs = await drawData.getBlobUrl(drawData.canvas);
		if (blobs) {
			const editedImageData = {
				drawData: drawData.drawed,
				attachmentId,
				entityId: activeId,
				entityType: entity?.type,
				editedBlob: blobs.editedBlob,
				originalBlob: blobs.originalBlob
			};
			saveEditedImage(editedImageData);
		}
	}, [saveEditedImage, drawData, entities, activeId]);

	return (
		<DesktopPhotoViewer
			className={{
				root: classNames('ed-photo-viewer', className),
				body: 'ed-photo-viewer__body',
				frame: 'ed-photo-viewer__frame'
			}}
			style={style}
			title={title}
			data={images}
			value={value}
			editable={canDraw}
			isOpen={isOpen}
			closeButton={
				<IconButton className="ed-photo-viewer__close-btn">
					<i className="tz-close-24 dialog__close-btn-icon" />
				</IconButton>
			}
			onChange={handleChange}
			onClose={handleClose}
			onAfterOpen={handleAfterOpen}
			onCanvasChange={handleCanvasChange}
			canvasBaseData={canvasData}
			onCanvasClear={handleClear}
			onSaveEdited={handleSave}
			translations={translations.desktopPhotoViewer}
		>
			<InfiniteScroller
				className={{
					root: 'ed-photo-viewer__previews',
					content: 'ed-photo-viewer__previews-content'
				}}
				enabled={loadingEnabled && !loadingBlocked}
				onLoad={onLoad}
			>
				{entities.map(item => (
					<React.Fragment key={item.id}>
						<Entity
							key={item.id}
							data={item}
							active={item.id === activeId}
							activeIndex={item.id === activeId ? activeIndex : undefined}
							dateOptions={dateOptions}
							onChange={onChange}
						/>
						<div className="ed-photo-viewer__entity-separator" />
					</React.Fragment>
				))}
			</InfiniteScroller>
		</DesktopPhotoViewer>
	);
};

export default EntitiesDesktopPhotoViewer;
