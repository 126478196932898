import {wsConnector} from '@src/api/backend/wsConnector';
import ICreationStatsItem from '@tehzor/tools/interfaces/statistics/ICreationStatsItem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IGetChecksCreationStatsResponse = ICreationStatsItem[];

/**
 * Отправляет запрос на получение статистики по созданию проверок
 *
 * @param objects фильтр по объектам
 * @param stage стадия объектов
 * @param users фильтр по порверяющим
 * @param contractors фильтр по подрядчикам
 */
export const requestChecksCreationStats = (
	objects: string[] | undefined,
	stage: ObjectStageIds,
	users: string[] | undefined,
	contractors: Record<string, true | string[]> | undefined
) =>
	wsConnector.sendAuthorizedRequest<IGetChecksCreationStatsResponse>('getChecksCreationStats', {
		objects,
		stage,
		users,
		contractors
	});
