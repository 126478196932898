import {createReducer} from '@reduxjs/toolkit';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import * as types from '../constants/inspections';
import {RESET} from '@src/store/modules/pages/checkLists/constants/page';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IGetInspectionsPayload} from '../actions/inspections';
import * as inspectionTypes from '@src/store/modules/entities/inspection/constants';
import {
	IDeleteInspectionPayload,
	IEditInspectionPayload
} from '@src/store/modules/entities/inspection/actions';

export interface IInspectionsState {
	byId: {
		[id: string]: IInspection;
	};
	allIds: string[];
}

export const getInitialState = (): IInspectionsState => ({
	byId: {},
	allIds: []
});

export default createReducer<IInspectionsState>(getInitialState(), {
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetInspectionsPayload}) => {
		state.byId = payload.byId;
		state.allIds = payload.allIds;
	},
	[inspectionTypes.ADD_SUCCESS]: (state, {payload}: {payload: IInspection}) => {
		// без условия вообще все осмотры при создании сюда пихались бы, костыль жоский
		if (payload.links?.checkListId) {
			state.byId[payload.id] = payload;
			state.allIds.push(payload.id);
		}
	},
	[inspectionTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditInspectionPayload}) => {
		if (state.allIds.includes(payload.id)) {
			state.byId[payload.id] = payload;
		}
	},
	[inspectionTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteInspectionPayload}) => {
		if (state.allIds.includes(payload.inspectionId)) {
			state.allIds = state.allIds.filter(id => id !== payload.inspectionId);
			delete state.byId[payload.inspectionId];
		}
	},
	[RESET]: getInitialState,
	[CLEAR_STORE]: getInitialState
});
