import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetWarrantyClaimStatusesResponse} from '@src/api/backend/warrantyClaimStatuses';
import {
	extractWarrantyClaimsStatusesAsArray,
	extractWarrantyClaimsStatusesAsMap
} from '@src/core/hooks/queries/warrantyClaimsStatuses/selectors';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';

export const useWarrantyClaimsStatuses = <T = IGetWarrantyClaimStatusesResponse>(
	select?: (data: IGetWarrantyClaimStatusesResponse) => T
) =>
	useQuery<IGetWarrantyClaimStatusesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.warrantyClaimStatuses(),
		meta: {
			error: 'ошибка в получении статусов'
		},
		select
	});

export const useWarrantyClaimStatusesArray = () =>
	useWarrantyClaimsStatuses(extractWarrantyClaimsStatusesAsArray);

export const useWarrantyClaimStatusesMap = () =>
	useWarrantyClaimsStatuses(extractWarrantyClaimsStatusesAsMap);
