import {CriticalMarker} from '@src/components/CriticalMarker';
import {DocumentsCount} from '@src/components/DocumentsCount';
import {EntityTransfer} from '@src/components/EntityTransfer/EntityTransfer';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {useProblemStatusPermissions} from '@src/core/hooks/permissions/useProblemStatusPermissions';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useInspectorsGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {editProblemStatus} from '@src/store/modules/pages/problem/actions';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {PhotoPreview, StatusSelect, TableRowSelect} from '@tehzor/ui-components';
import {useCallback, useMemo} from 'react';
import {CellProps} from 'react-table';
import {MobileLocationDetails} from './MobileLocationDetails';
import {MobilePerfomers} from './MobilePerfomers';
import {MobileInspectors} from './MobileInspectors';
import {MobilePlannedFixDate} from './MobilePlannedFixDate';
import {MobileActualFixDate} from './MobileActualFixDate';
import {ProblemReopenedTimes} from '@src/components/ProblemReopenedTimes';

interface IMobileProblemCell extends CellProps<IProblemEntity> {
	settings?: IEntitySettings;
	withObject?: boolean;
}

const locationIcon = <i className="tz-comment-on-plan-16" />;

const MobileProblemCell = ({row, settings, withObject}: IMobileProblemCell) => {
	const {
		objectId,
		stage,
		id,
		status,
		reopenedTimes,
		plannedFixDate,
		actualFixDate,
		number,
		localNumber,
		transferStatus,
		critical,
		description,
		plannedFixDateOriginal,
		inspectors,
		performers,
		performersActiveGroup,
		object,
		attachment,
		problemTags,
		actualFixDateOriginal,
		floor,
		location,
		plan
	} = row.original.data;

	const {data: statuses} = useProblemStatuses();
	const translatedStatuses = useTranslatedDictionary('problemStatuses', statuses);
	const {data: inspectorsGroups} = useInspectorsGroupsAsArray(objectId, stage);
	const dispatch = useAppDispatch();
	const permissions = useProblemStatusPermissions(objectId, row.original.data.data);
	const handleChange = useCallback(
		async (v: ProblemStatusId) => {
			await dispatch(editProblemStatus(objectId, id, v));
		},
		[objectId, id, editProblemStatus, dispatch]
	);
	const documentsCount = row.original.data.documentsCount;
	const firstInspector = inspectors?.[0];
	const userGroup = useMemo(() => {
		if (!firstInspector || !inspectorsGroups) {
			return undefined;
		}

		const groups: IWorkingGroup[] = [];
		for (const group of inspectorsGroups) {
			if (group.performers?.includes(firstInspector.id)) {
				groups.push(group);
			} else if (group.leader === firstInspector.id) {
				groups.push(group);
			}
		}

		return groups.length === 1 ? groups[0] : undefined;
	}, [inspectorsGroups, firstInspector]);

	const numberEl = (
		<div className="problems-page__m-table-description-number">№{number ?? localNumber}</div>
	);

	const criticalEl = !!critical && <CriticalMarker />;

	const hasLocationComments = !!row?.original?.data?.hasLocationComments;

	const problemTagsEl = !!problemTags?.length && (
		<ProblemTagsMarker
			className="problems-page__m-table-tags"
			colors={problemTags}
		/>
	);

	const descriptionEl = (
		<div className="problems-page__m-table-description-text">{description}</div>
	);

	const statusEl = (
		<StatusSelect
			className="problems-page__m-table-status-select"
			statuses={translatedStatuses}
			availableStatuses={permissions.availableStatuses}
			disabled={!permissions.canEditStatus}
			value={status.id}
			onChange={handleChange}
		/>
	);

	const reopenedTimesEl = !!reopenedTimes && (
		<ProblemReopenedTimes reopenedTimes={reopenedTimes} />
	);

	const transferEl = transferStatus && (
		<div className="problems-page__m-table-transfer-status">
			<EntityTransfer
				transferStatus={transferStatus}
				itemKey={id}
			/>
		</div>
	);

	const objectEl = <div className="problems-page__m-table-object">{object?.name}</div>;

	const attachmentsEl = (
		<div className="problems-page__m-table-description-image">
			{attachment?.preview && (
				<PhotoPreview
					url={attachment.preview.url}
					data={0}
				/>
			)}
		</div>
	);

	const documentsCountEl = documentsCount && (
		<div className="problems-page__m-table-description-documents-count">
			<DocumentsCount count={documentsCount} />
		</div>
	);

	return (
		<div className="problems-page__mobile-cell-info">
			<ClickPreventWrap className="problems-page__mobile-cell-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()} />
			</ClickPreventWrap>
			<div>
				<div className="problems-page__m-table-description-header">
					{numberEl}
					{settings?.builtin.critical && criticalEl}
					{settings?.builtin.location && hasLocationComments && locationIcon}
					{settings?.builtin.problemTags && problemTagsEl}
				</div>

				{settings?.builtin.description && descriptionEl}

				{settings?.builtin.plannedFixDate && status.id !== ProblemStatusId.FIXED && (
					<MobilePlannedFixDate
						plannedFixDate={plannedFixDate}
						actualFixDateOriginal={actualFixDateOriginal}
						plannedFixDateOriginal={plannedFixDateOriginal}
					/>
				)}

				{status.id === ProblemStatusId.FIXED && (
					<MobileActualFixDate
						actualFixDate={actualFixDate}
						actualFixDateOriginal={actualFixDateOriginal}
						plannedFixDateOriginal={plannedFixDateOriginal}
					/>
				)}

				{settings?.builtin.performers && (
					<MobilePerfomers
						performersActiveGroup={performersActiveGroup}
						performers={performers}
					/>
				)}
				{settings?.builtin.inspectors && (
					<MobileInspectors
						inspectors={inspectors}
						userGroup={userGroup}
					/>
				)}
				{(plan || floor || location) && (
					<MobileLocationDetails
						plan={plan}
						floor={floor}
						location={location}
					/>
				)}

				<ClickPreventWrap className="problems-page__m-table-status-select">
					{statusEl}
					{reopenedTimesEl}
				</ClickPreventWrap>

				{documentsCountEl}

				{transferEl}
				{!!withObject && objectEl}
			</div>
			{settings?.builtin.attachments && attachmentsEl}
		</div>
	);
};

export default MobileProblemCell;
