import { forwardRef, memo, Ref, useCallback } from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {IPrescriptionProps} from './IPrescriptionProps';
import {useTranslation} from 'react-i18next';

const defaultTextAreaProps = {
	minRows: 2,
	maxRows: 6
};

const Prescription = <S extends {prescription: string}, E>(
	{
		className,
		style,
		label,
		addon,
		value,
		editingDispatch,
		required,
		disabled,
		hasError,
		onChange,
		textAreaProps = defaultTextAreaProps,
		...restProps
	}: IPrescriptionProps<S, E>,
	ref?: Ref<HTMLTextAreaElement>
) => {
	const {t} = useTranslation();
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'prescription', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'prescription'});
			}
			if (onChange) {
				onChange(v);
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
			{...restProps}
		>
			<EditableFieldLabel addon={addon}>
				{label ?? t('editableProblem.prescription.label')}
			</EditableFieldLabel>

			<TextField
				value={value}
				elementRef={ref}
				elementType="textarea"
				elementProps={textAreaProps}
				error={required && hasError ? t('components.editableFields.prescription.error') : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default memo(
	forwardRef<
		HTMLTextAreaElement,
		IPrescriptionProps<Record<string, unknown> & {prescription: string}, unknown>
	>(Prescription)
) as typeof Prescription;
