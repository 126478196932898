import {useCallback} from 'react';
import {CellProps} from 'react-table';
import {StatusSelect} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {editWorkAcceptanceStatus} from '@src/store/modules/entities/workAcceptance/actions';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";
import {useTranslatedDictionary} from "@src/core/hooks/translations/useTranslatedDictionary";

export const StatusChangeableCell = ({row}: CellProps<IPreparedWorkAcceptance>) => {
	const workAcceptance = row.original;
	const objectId = workAcceptance?.object?.id || 'all';
	const permissions = useWorkAcceptancesPermissions(objectId);

	const {data: statuses} = useExtractWorkAcceptanceStatuses();
	const translatedStatuses = useTranslatedDictionary(dictionaryKeys.workAcceptanceStatuses, statuses);

	const dispatch = useAppDispatch();

	const handleChange = useCallback(
		async (statusId: WorkAcceptanceStatusIds) => {
			await dispatch(editWorkAcceptanceStatus(objectId, workAcceptance.id, statusId));
		},
		[workAcceptance, objectId, dispatch]
	);
	return statuses ? (
		<ClickPreventWrap>
			<div className="work-acceptances-page__d-table-status">
				<StatusSelect
					statuses={translatedStatuses}
					availableStatuses={statuses.allIds}
					disabled={!permissions.canEditStatus}
					value={workAcceptance?.status}
					onChange={handleChange}
				/>
			</div>
		</ClickPreventWrap>
	) : null;
};
