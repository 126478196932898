import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export type IEditWorkingGroupResponse = IWorkingGroup;

/**
 * Изменяет рабочую группу
 *
 * @param groupId id рабочей группы
 * @param fields данные рабочей группы
 */
export const requestEditWorkingGroup = async (groupId: string, fields: ISavingWorkingGroup) => {
	const response = await httpRequests.withToken.put<IEditWorkingGroupResponse>(
		`working-groups/${groupId}`,
		{groupId, ...fields}
	);
	return response.data;
};
