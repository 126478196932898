import arrayToTree from 'array-to-tree';
import ICategory from '../../interfaces/categories/ICategory';

export type ITreeStructureElement = number[];

export type ITreeStructure = ITreeStructureElement[];

export const getTreeStructure = (
	data: Array<arrayToTree.Tree<ICategory>>,
	preIndex: ITreeStructureElement
	): ITreeStructure => data.reduce(
	(acc: ITreeStructure, val: arrayToTree.Tree<ICategory>, structureIndex: number) => {
		acc.push([...preIndex, structureIndex]);

		if (val.children) {
			getTreeStructure(val.children, [...preIndex, structureIndex])
				.forEach(childrenVal => acc.push(childrenVal));
		}
		return acc;
	}, []
);

export const joinStuctures = (str: ITreeStructure, joinedStr: ITreeStructure) => str.reduce((
	acc: ITreeStructure,
	comparedStrEl: ITreeStructureElement
	) => {
	if (!joinedStr.some(treeStrEl => JSON.stringify(treeStrEl) === JSON.stringify(comparedStrEl))) {
		acc.push(comparedStrEl);
	}
	return acc;
}, joinedStr);