import {memo} from 'react';
import * as React from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useTranslation} from 'react-i18next';

interface IPageBreadcrumbsProps {
	children?: React.ReactNode;
}

const AllObjectPageBreadcrumbs = ({children}: IPageBreadcrumbsProps) => {
	const {t} = useTranslation();
	return (
		<AppBreadcrumbs
			className="w-claims-page__breadcrumbs"
			items={[{label: t('header.warrantyClaims'), url: ''}]}
		>
			{children}
		</AppBreadcrumbs>
	);
};

export default memo(AllObjectPageBreadcrumbs);
