import { memo } from 'react';
import {MobileFilter, TextField} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '../../EntitiesFilters/utils/makeFilterLabel';
import {useFilterPageTransition} from '../utils/pagesRouting';

interface ICreationDateFilterMobileProps {
	from?: Date;
	to?: Date;
	type?: string;
	label?: string;
	entity: string;
}

export const IntervalFilterMobile = memo(
	(props: ICreationDateFilterMobileProps) => {
		const {
			from,
			to,
			entity,
			label = 'Дата добавления',
			type = 'intervalstart'
		} = props;
		const handleExpand = useFilterPageTransition(undefined, entity, type);

		return (
			<MobileFilter label={label}>
				<TextField
					className="mobile-entities-filters__textfield"
					elementType="div"
					value={from && to ? makeDateFilterLabel('', from, to) : undefined}
					icon={<i className="tz-calendar-20"/>}
					onClick={handleExpand}
				/>
			</MobileFilter>
		);
	}
);
