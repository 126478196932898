import {AddingOwnerAcceptanceView} from '../AddingOwnerAcptDialog';
import {useTranslation} from 'react-i18next';

interface IDialogTitleProps {
	view?: AddingOwnerAcceptanceView;
}

const DialogTitle = ({view}: IDialogTitleProps) => {
	const {t} = useTranslation();
	return (
		<div className="adding-owner-acpt-dialog__dialog-title">
			{view === 'acceptance'
				? t('addingOwnerAcptDialog.dialogTitle.acceptance')
				: view === 'problem'
				? 'Нарушение'
				: ''}
		</div>
	);
};

export default DialogTitle;
