const SVG = require('svg.js');
require('../../../vendor/svg.draw.js');

SVG.Element.prototype.draw.extend('polyline', {
	init() {
		const p = this.startPoint;
		const arr = [
			[p.x, p.y],
			[p.x, p.y]
		];
		this.el.plot(arr);
	},
	calc(e) {
		const arr = this.el.array().valueOf();
		arr.pop();
		if (e) {
			const p = this.transformPoint(e.clientX, e.clientY);
			arr.push(this.snapToGrid([p.x, p.y]));
		}
		this.el.plot(arr);
	},
	point(e) {
		const p = this.transformPoint(e.clientX, e.clientY);
		if (Math.abs(p.x - this.startPoint.x) < 30 && Math.abs(p.y - this.startPoint.y) < 30) {
			this.stop(e);
			return;
		}
		const arr = this.el.array().valueOf();
		arr.push(this.snapToGrid([p.x, p.y]));
		this.el.plot(arr);
		this.el.fire('drawpoint', {event: e, p: {x: p.x, y: p.y}, m: this.m});
	}
});

SVG.extend(SVG.Array, {
	getPointsWithoutLast() {
		const value = [...this.value];
		value.length && value.pop();
		return value;
	}
});