import {ICheckListHistoryLinks} from '@src/components/CheckListHistoryDialog/hooks/useEnrichedHistories';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ICheckRecordStory} from '@tehzor/tools/interfaces/checkRecordStories/ICheckRecordStory';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetCheckListStoriesResponse = INormalizedData<ICheckRecordStory>;

/**
 * Возвращает историю изменений по чеклисту
 */
export const requestCheckListStories = async (
	objectId: string,
	stage: string,
	links: ICheckListHistoryLinks
) => {
	const params = {
		objectId,
		stage,
		...links
	};
	const response = await httpRequests.withToken.get<IGetCheckListStoriesResponse>(
		'check-records-stories',
		{params}
	);
	return response.data;
};
