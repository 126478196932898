import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IRegulatoryStandard from '@tehzor/tools/interfaces/IRegulatoryStandard';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetRegulatoryStandardsResponse = INormalizedData<IRegulatoryStandard>;

/**
 * Возвращает список стандартов
 */
export const requestRegulatoryStandards = async () => {
	const response = await httpRequests.withToken.get<IGetRegulatoryStandardsResponse>(
		'regulatory-standards'
	);
	return response.data;
};
