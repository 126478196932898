import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IProblemState} from '../reducers';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {hasLocationComments} from '../utils/hasLocationComments';

const extractProblemData = (state: IState) => state.pages.problem || {};

/**
 * Извлекает текущее нарушение
 */
export const extractProblem = createSelector(
	[extractProblemData],
	(problemState: IProblemState) => problemState.data
);

/**
 * Возвращает есть ли комментарии к точкам/секторам нарушения
 */
export const extractHasLocationComments = createSelector(
	[extractProblem],
	(problemData: ILinkedProblem): boolean => hasLocationComments(problemData?.location)
);