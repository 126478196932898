import {IGetContractResponse} from '@src/api/backend/contract';
import {IGetPaginateContractsResponse} from '@src/api/backend/contracts';
import {IGetListContractsResponse} from '@src/api/backend/contracts/getList';
import {contractsQueryKeys} from '@src/api/cache/contracts/keys';
import {
	IContractsFiltersState,
	IContractsSortState
} from '@src/store/modules/settings/pages/contracts/reducers';
import {extractContractsPageSettings} from '@src/store/modules/settings/pages/contracts/selectors';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useContractsPermissions} from '../../permissions/useContractsPermissions';
import useAppSelector from '../../useAppSelector';
import {
	extractContractsListArray,
	extractContractsListMap,
	extractContractsPaginateArray,
	extractContractsPaginateMap
} from './selectors';

export const useContractsPaginate = <T = IGetPaginateContractsResponse>(
	select?: (data: IGetPaginateContractsResponse) => T
) => {
	const permissions = useContractsPermissions();
	const {offset, filters, sort, pageSize} = useAppSelector(extractContractsPageSettings);

	return useQuery<IGetPaginateContractsResponse, IError, T>({
		queryKey: contractsQueryKeys.paginate(filters, sort, offset, pageSize),
		meta: {
			error: 'при загрузке списка договоров'
		},
		enabled: permissions.canAdministrate,
		select
	});
};

export const useContractsList = <T = IGetListContractsResponse>(
	filters?: IContractsFiltersState,
	sort?: IContractsSortState,
	select?: (data: IGetListContractsResponse) => T
) =>
	useQuery<IGetListContractsResponse, IError, T>({
		queryKey: contractsQueryKeys.list(filters, sort),
		meta: {
			error: 'при загрузке списка договоров'
		},
		select
	});

export const useContract = <T = IGetContractResponse>(
	contractId?: string,
	select?: (data: IGetContractResponse) => T
) =>
	useQuery<IGetContractResponse, IError, T>({
		queryKey: [...contractsQueryKeys.details(), contractId],
		meta: {
			error: 'при загрузке договора'
		},
		enabled: !!contractId,
		select
	});

export const useContractsPaginateMap = () => useContractsPaginate(extractContractsPaginateMap);

export const useContractsPaginateArray = () => useContractsPaginate(extractContractsPaginateArray);

export const useContractsListMap = (filters?: IContractsFiltersState, sort?: IContractsSortState) =>
	useContractsList(filters, sort, extractContractsListMap);

export const useContractsListArray = (
	filters?: IContractsFiltersState,
	sort?: IContractsSortState
) => useContractsList(filters, sort, extractContractsListArray);
