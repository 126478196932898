import {queryClient} from '@src/api/QueryClient';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {findMutation} from './findMutation';
import IError from '@tehzor/tools/interfaces/IError';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {IListOwnerAcceptanceSavingData} from '@src/core/hooks/mutations/ownerAcceptances/utils/convertToLocalSave';
import {IAddOwnerAcceptanceParams} from '@src/api/cache/ownerAcceptances/defaults/useOwnerAcceptancesMutationDefaults';

export const transferOwnerAcceptance = (entity: IOfflineJournalEntity['entity']) => {
	const {entityId} = entity;
	const mutationCache = queryClient.getMutationCache();
	const [[, cachedData]] = queryClient.getQueriesData<IListOwnerAcceptanceSavingData>({
		queryKey: ownerAcceptancesQueryKeys.savingData(entityId)
	});

	if (cachedData) {
		if (cachedData) {
			const {objectId, fields, links, id} = cachedData;
			if (!fields) return;
			const currentMutation = findMutation(id);

			if (currentMutation) {
				void currentMutation.execute({objectId, links, fields, key: id});
				return;
			}
			const mutation = mutationCache.build<
				unknown,
				IError,
				IAddOwnerAcceptanceParams,
				unknown
			>(queryClient, {
				mutationKey: ownerAcceptancesQueryKeys.add()
			});

			void mutation.execute({objectId, links, fields, key: id});
		}
	}
};
