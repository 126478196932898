import {ResponsiblesFilter} from '@src/components/EntitiesFilters/components/ResponsiblesFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useWorkingGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';
import {memo, useCallback} from 'react';

interface ITaskResponsiblesFilterProps {
	respUsers?: string[];
	label?: string;
}

export const TaskResponsiblesFilter = memo(
	({respUsers, label}: ITaskResponsiblesFilterProps) => {
		const {data: workingGroups} = useWorkingGroupsAsArray();
		const {dispatch} = useEntitiesFiltersCtx();

		const handleChange = useCallback(
			(value?: string[]) => {
				dispatch({respUsers: value});
			},
			[dispatch]
		);

		return (
			<ResponsiblesFilter
				respUsers={respUsers}
				workingGroups={workingGroups}
				onChange={handleChange}
				label={label}
			/>
		);
	}
);
