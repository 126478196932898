import IUser from '@tehzor/tools/interfaces/IUser';
import {testCommentRestrictions} from '@src/utils/testRestrictions';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

export interface ICommentPermissions {
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param objectId id объекта
 * @param user текущий пользователь
 * @param comment комментарий
 */
export const getCommentPermissions = (
	objectId: string,
	user: IUser | undefined,
	comment: IComment
): ICommentPermissions => {
	if (!user || !user.roles) {
		return {};
	}
	if (comment.official) {
		return {
			canEdit: testCommentRestrictions(
				user.id,
				user.roles,
				'repliesEdit',
				objectId,
				comment.createdBy
			),
			canDelete: testCommentRestrictions(
				user.id,
				user.roles,
				'repliesDelete',
				objectId,
				comment.createdBy
			),
			canExport: testCommentRestrictions(
				user.id,
				user.roles,
				'repliesExport',
				objectId,
				comment.createdBy
			)
		};
	}
	return {
		canEdit: testCommentRestrictions(
			user.id,
			user.roles,
			'commentsEdit',
			objectId,
			comment.createdBy
		),
		canDelete: testCommentRestrictions(
			user.id,
			user.roles,
			'commentsDelete',
			objectId,
			comment.createdBy
		)
	};
};
