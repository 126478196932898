import {createMutation} from 'react-query-kit';
import {bankAccountsQueryKeys} from '../keys';
import {IBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {
	IAddBankAccountParams,
	IEditBankAccountParams
} from '../defaults/useBankAccountsMutationDefaults';

export const useAddBankAccountMutation = createMutation<IBankAccount, IAddBankAccountParams>({
	mutationKey: bankAccountsQueryKeys.add(),
	meta: {
		entity: 'bankAccount',
		type: 'creation'
	}
});

export const useEditBankAccountMutation = createMutation<IBankAccount, IEditBankAccountParams>({
	mutationKey: bankAccountsQueryKeys.edit(),
	meta: {
		entity: 'bankAccount',
		type: 'editing'
	}
});