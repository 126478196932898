import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	ObjectsFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IntervalFilterMobile} from '@src/components/MobileEntitiesFilters/components/IntervalFilterMobile';
import {TaskResponsiblesFilterMobile} from '@src/components/MobileEntitiesFilters/components/TaskResponsiblesMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTaskPrioritiesAsArray} from '@src/core/hooks/queries/task-priorities/hooks';
import {useTaskStatusesAsArray} from '@src/core/hooks/queries/taskStatuses/hook';
import {useTaskTypesAsArray} from '@src/core/hooks/queries/taskTypes';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ITasksFiltersState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {LinkButton} from '@tehzor/ui-components';
import {stringify} from 'query-string';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

export const TasksFiltersPage = () => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {entity} = useStrictParams<{entity: string}>();
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};
	const {state, isLocal, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<ITasksFiltersState>();

	const {data: allTypes} = useTaskTypesAsArray();
	const {data: allStatuses} = useTaskStatusesAsArray();
	const {data: allPriorities} = useTaskPrioritiesAsArray();

	const search = stringify(state, {arrayFormat: 'index'});

	const handleApply = useCallback(() => {
		if (canGoBack) {
			if (isLocal) {
				apply();
				pushPath(`/tasks/?${search}`);
			} else {
				apply();
				pushPath('/tasks');
			}
		}
	}, [canGoBack, apply, isLocal, search]);

	useAppHeader(
		{
			title: t('filtersPage.title'),
			showBackBtn: true,
			mobileRightButtons: isCleanable<ITasksFiltersState>(state) ? (
				<LinkButton
					label={t('filterPage.resetFiltersBtn.label')}
					onClick={mobileClear}
				/>
			) : null
		},
		[state]
	);

	useRouting(!canGoBack, 'all', entity);

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			<ObjectsFilterMobile
				entity={entity}
				value={state?.objects}
			/>

			{allTypes && (
				<BaseListFilterMobile
					value={state?.types}
					entity={entity}
					onChange={change}
					options={allTypes}
					filterName="types"
					label={t('entitiesFilters.tasksTypesFilter.label')}
				/>
			)}

			{allStatuses && (
				<BaseListFilterMobile
					value={state?.statuses}
					entity={entity}
					onChange={change}
					options={allStatuses}
					filterName="statuses"
					label={t('entitiesFilters.tasksStatusesFilter.label')}
					translationDictionaryKey={dictionaryKeys.taskStatuses}
				/>
			)}

			{allPriorities && (
				<BaseListFilterMobile
					value={state?.priorities}
					entity={entity}
					onChange={change}
					options={allPriorities}
					filterName="priorities"
					label={t('entitiesFilters.baseListFilterMobile.priority.label')}
				/>
			)}

			<IntervalFilterMobile
				from={state?.taskIntervalStartFrom}
				to={state?.taskIntervalStartTo}
				label={t('entitiesFilters.dateFilter.startDate.label')}
				entity={entity}
				type="intervalstart"
			/>

			<IntervalFilterMobile
				from={state?.taskIntervalEndFrom}
				to={state?.taskIntervalEndTo}
				label={t('entitiesFilters.dateFilter.completionDate.label')}
				entity={entity}
				type="intervalend"
			/>

			<CreationDateFilterMobile
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity}
			/>

			<TaskResponsiblesFilterMobile
				objectId="all"
				value={state?.respUsers}
				entity={entity}
				onChange={change}
			/>

			<CreatedByFilterMobile
				value={state?.createdBy}
				entity={entity}
				onChange={change}
			/>
		</TranslatedFilterPage>
	);
};
