import { useMemo } from 'react';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import SelectionActions from '../selection/SelectionActions';
import ActionsMobile from './Actions.mobile';
import {useCheckEntities} from '@src/core/hooks/queries/check/hook';

interface IMobileRightButtonsProps {
	objectId: string;
	checkId: string;
	check?: ILinkedCheck;
	selectedRows: string[];
}

const MobileRightButtons = ({objectId, checkId, check, selectedRows}: IMobileRightButtonsProps) => {
	const entities = useCheckEntities(objectId, checkId);

	const selectedEntities = useMemo(
		() => entities.filter(item => selectedRows?.includes(item.id)),
		[selectedRows, entities]
	);

	if (!check) {
		return null;
	}
	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				checkId={checkId}
				check={check}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			check={check}
		/>
	);
};

export default MobileRightButtons;
