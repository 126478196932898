import {wsConnector} from '../wsConnector';

export type IDeleteOwnerAcptResponse = void;

/**
 * Удаляет передачу собственнику
 *
 * @param objectId id объекта
 * @param acceptanceId id передачи
 */
export const makeOwnerAcptDeleteRequest = (objectId: string, acceptanceId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteOwnerAcptResponse>('deleteOwnerAcceptance', {
		objectId,
		acceptanceId
	});
