import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {PriorityView} from '@src/components/PriorityView/PriorityView';
import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useTaskPrioritiesMap} from '@src/core/hooks/queries/task-priorities/hooks';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';

export const PriorityCell = (data: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const row = data.row;
	const task = row.original;
	const isDesktop = useIsDesktop();
	const {data: prioritiesMap} = useTaskPrioritiesMap();
	const translatedPrioritiesMap = useTranslatedDictionaryMap('taskPriorities', prioritiesMap);

	const ref = useRef<HTMLDivElement>(null);
	const [priorityWidth, setPriorityWidth] = useState(0);

	useLayoutEffect(() => {
		if (ref?.current) {
			setPriorityWidth(ref.current.clientWidth);
		}
	}, []);

	useEffect(() => {
		function handleWindowResize() {
			if (ref?.current) {
				setPriorityWidth(ref.current.clientWidth);
			}
		}
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	if (!translatedPrioritiesMap || !task?.taskPriority?.id) {
		return null;
	}

	return (
		<div
			className="tasks-page_priority"
			ref={ref}
		>
			<PriorityView
				value={task?.taskPriority?.id}
				prioritiesMap={translatedPrioritiesMap}
				short={isDesktop ? priorityWidth < 120 : false}
			/>
		</div>
	);
};
