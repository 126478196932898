import {memo, useCallback, useEffect, useRef, useState} from 'react';
import './AddingOwnerAcptDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IAddingAcceptanceRefProps} from './components/AddingAcceptance';
import {IAddingProblemRefProps} from '../AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {useOwnerAcceptanceMaps} from './hooks/useOwnerAcceptanceMaps';
import {saveOwnerAcceptance} from './utils/saveOwnerAcceptance';
import {useLocalOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances/useGetOwnerAcceptances';
import {queryClient} from '@src/api/QueryClient';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';

export type AddingOwnerAcceptanceView = 'acceptance' | 'problem' | undefined;

interface IAddingOwnerAcceptanceDialogProps {
	objectId: string;
	spaceId?: string;
	acceptanceId?: string;
	links?: IOwnerAcceptance['links'];
	defaultProblemData?: ISavingProblem;
	isOpen: boolean;
	title: string;

	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const addingOwnerAcptDialogClassNames = {
	root: 'adding-owner-acpt-dialog',
	header: 'adding-owner-acpt-dialog__header',
	body: 'adding-owner-acpt-dialog__body'
};

const AddingOwnerAcptDialog = (props: IAddingOwnerAcceptanceDialogProps) => {
	const {
		objectId,
		spaceId,
		acceptanceId,
		links,
		defaultProblemData,
		isOpen,
		onClose,
		onSuccess,
		title
	} = props;
	const {t} = useTranslation();
	const [selectedOwnerAcceptanceId, setSelectedOwnerAcceptanceId] = useState<string | undefined>(
		acceptanceId
	);
	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingAcceptanceRefProps>(null);

	const [view, setView] = useState<AddingOwnerAcceptanceView>();
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const localOwnerAcceptances = useLocalOwnerAcceptances();
	const localOwnerAcceptancesIds = localOwnerAcceptances.map(acp => acp.id);

	useEffect(() => {
		setView(!selectedOwnerAcceptanceId ? 'acceptance' : 'problem');
	}, [selectedOwnerAcceptanceId]);

	const stage = ObjectStageIds.TRANSFER;
	const stages = useTranslatedObjectStagesArray();
	const [contentMap, entitiesSelectMapProps] = useOwnerAcceptanceMaps({
		objectId,
		acceptanceId,
		spaceId,
		stage,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedOwnerAcceptanceId,
		setSelectedOwnerAcceptanceId,
		defaultProblemData,
		onClose
	});

	const handleSave = useCallback(async () => {
		const updateLatest = async () => {
			if (
				selectedOwnerAcceptanceId &&
				!localOwnerAcceptancesIds.includes(selectedOwnerAcceptanceId)
			) {
				await queryClient.invalidateQueries({
					queryKey: ownerAcceptancesQueryKeys.detail(selectedOwnerAcceptanceId)
				});
			}
		};

		await saveOwnerAcceptance({
			addingEntityRef,
			localOwnerAcceptancesIds,
			view,
			online,
			updateLatest,
			selectedOwnerAcceptanceId,
			success,
			onClose,
			setSaving
		});
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [localOwnerAcceptancesIds, onClose, onSuccess, online, selectedOwnerAcceptanceId, view]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const getEntitySelectContainerSubTitle = useCallback(
		(objectStageName: string): string =>
			t('globalAddingEntityDialog.entitySelectContainer.getEntitySelectContainerSubTitle', {
				name: objectStageName
			}),
		[t]
	);

	return (
		<Dialog
			className={addingOwnerAcptDialogClassNames}
			isOpen={isOpen}
			title={title}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					selectedStage={stage}
					stagesSelectDialogTitle={t('addingOwnerAcptDialog.stagesSelectDialog.title')}
					stagesSelectDialogSaveLabel={t('actionButtons.button.save')}
					stagesSelectDialogCancelLabel={t('actionButtons.button.cancel')}
					stages={stages}
					getEntitySelectContainerSubTitle={getEntitySelectContainerSubTitle}
				/>
			)}
		</Dialog>
	);
};

export default memo(AddingOwnerAcptDialog);
