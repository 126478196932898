import {
	ADD_LOCAL_INTERNAL_ACCEPTANCE,
	CHANGE_LOCAL_INTERNAL_ACCEPTANCE_MODIFIED_AT,
	DELETE_LOCAL_INTERNAL_ACCEPTANCE
} from '@src/constants/local-entities/internalAcceptance';
import {ILocalInternalAcceptance} from '@src/interfaces/ILocalInternalAcceptance';

/**
 * Добавляет внутреннюю приемку в локальное хранилище
 *
 * @param {ILocalInternalAcceptance} internalAcceptance проверка, пригодная для сохранения локально
 * @returns {{type, payload}} action
 */
export const addLocalInternalAcceptance = (internalAcceptance: ILocalInternalAcceptance) => ({
	type: ADD_LOCAL_INTERNAL_ACCEPTANCE,
	payload: {internalAcceptance}
});

export const changeLocalInternalAcceptanceModifiedAt = (key: string, modifiedAt: number) => ({
	type: CHANGE_LOCAL_INTERNAL_ACCEPTANCE_MODIFIED_AT,
	payload: {key, modifiedAt}
});

/**
 * Удаляет внутреннюю приемку из локального хранилища
 *
 * @param {string} key id локальной внутренней приемки
 * @returns {{type, payload}} action
 */
export const deleteLocalInternalAcceptance = (key: string) => ({
	type: DELETE_LOCAL_INTERNAL_ACCEPTANCE,
	payload: {key}
});
