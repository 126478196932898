import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetOwnerAcceptanceResolutionsResponse} from '@src/api/backend/ownerAcceptanceResolutions';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {
	extractOwnerAcceptancesResolutionsAsArray,
	extractOwnerAcceptancesResolutionsAsMap
} from './selectors';

export const useOwnerAcceptancesResolutions = <T = IGetOwnerAcceptanceResolutionsResponse>(
	select?: (data: IGetOwnerAcceptanceResolutionsResponse) => T
) =>
	useQuery<IGetOwnerAcceptanceResolutionsResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.ownerAcceptanceResolutions(),
		meta: {
			error: 'при получении решений приёмок'
		},
		select
	});

export const useOwnerAcceptancesResolutionsMap = () =>
	useOwnerAcceptancesResolutions(extractOwnerAcceptancesResolutionsAsMap);

export const useOwnerAcceptancesResolutionsArray = () =>
	useOwnerAcceptancesResolutions(extractOwnerAcceptancesResolutionsAsArray);
