import {ITwoWayTreeItem} from '../../interfaces/ITwoWayTreeItem';
import {getAllTreeKeys} from './getAllTreeKeys';

/**
 * Формирует массив из всех нод дерева, даже если в checked не приходят родительсике элементы
 *
 * @param data данные в виде дерева
 * @param checked отмеченные элементы
 */
export function formAllCheckedOnlyChildren<
	T extends {
		id: string;
		onlySemiCheckable?: boolean;
	}
>(data: Array<ITwoWayTreeItem<T>>, checked: string[]): string[] {
	if (!checked.length) {
		return [];
	}

	const uniqueIds = new Set<string>(checked);

	data.forEach(treeItem => {
		if (treeItem.children) {
			const ids = getAllTreeKeys(treeItem.children);

			if (checked.includes(treeItem.id)) {
				ids.forEach(id => uniqueIds.add(id));
			} else if (ids.every(id => checked.includes(id))) {
				uniqueIds.add(treeItem.id);
			}
		}

		if (treeItem.children) {
			const childrenIds = formAllCheckedOnlyChildren(treeItem.children, checked);
			childrenIds.forEach(id => uniqueIds.add(id));
		}
	});

	return Array.from(uniqueIds);
}
