import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import { useCallback, useEffect } from 'react';
import {ExpandablePanel, LoadingPanel, Plate} from '@tehzor/ui-components';
import classNames from 'classnames';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureCheckListLink, formStructureCheckListsLink} from '@tehzor/tools/utils/links';
import {RecordStatusSelect} from '@src/components/CheckRecordStatusSelect/RecordStatusSelect'
import {useToggle} from 'react-use';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {DiagramIcon} from './DiagramIcon/DiagramIcon';
import {CheckListItems} from './list';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';

interface ICheckListPlateProps {
	checkList: ICheckList;
	defaultOpen: boolean;
}

export const CheckListPlate = ({checkList, defaultOpen}: ICheckListPlateProps) => {
	const {replacePath} = useChangePath();
	const params = useStrictParams<{
		objectId: string;
		structureId: string;
		listId: string;
		spaceId: string;
	}>();
	const {structureId, objectId, listId} = params;
	const [isOpen, toggle] = useToggle(checkList.id === listId);
	const listsLoading = useCheckListsIsLoading();

	const {changeCheckListRecord} = useChangeCheckRecord(
		params.objectId,
		params.spaceId,
		checkList
	);
	const handleOpen = useCallback(() => {
		if (!isOpen) {
			replacePath(formStructureCheckListLink({...params, listId: checkList.id}));
		} else {
			replacePath(formStructureCheckListsLink({objectId, structureId}));
		}

		toggle();
	}, [isOpen]);

	useEffect(() => {
		if (listId === undefined && defaultOpen) {
			toggle(true);
			replacePath(formStructureCheckListLink({...params, listId: checkList.id}));
		}
	}, []);

	return (
		<Plate
			id={checkList.id}
			className={{
				root: classNames('structure-page__check-list__d-plate', {
					'structure-page__check-list__d-plate_expanded': isOpen
				}),
				content: 'structure-page__check-list__d-plate-content'
			}}
			header={
				<div
					className="structure-page__check-list__d-plate-header"
					onClick={handleOpen}
					role="presentation"
				>
					<div className="structure-page__check-list__d-plate-header-diagram">
						<DiagramIcon listId={checkList.id} />
					</div>
					<div className="structure-page__check-list__d-plate-header-title">
						<ClickPreventWrap className="structure-page__check-list__float-actions">
							<RecordStatusSelect
								item={checkList}
								mutation={changeCheckListRecord}
								disabled
							/>
						</ClickPreventWrap>
						{checkList.name}{' '}
						<i
							className={classNames(
								'tz-simple-arrow-24',
								'structure-page__check-list__d-plate-header-title-arrow',
								{
									'structure-page__check-list__d-plate-header-title-arrow_reversed':
										isOpen
								}
							)}
						/>
					</div>
					<div className="structure-page__check-list__d-plate-header-indicators">
						<ProblemIndicators checkListId={checkList.id} />
					</div>
				</div>
			}
		>
			<ExpandablePanel expanded={isOpen}>
				<LoadingPanel active={listsLoading}>
					<CheckListItems checkList={checkList} />
				</LoadingPanel>
			</ExpandablePanel>
		</Plate>
	);
};
