import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {useQuery} from '@tanstack/react-query';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {extractWarrantyClaimEntities, extractWarrantyClaimProblemsArray} from './selectors';

export const useWarrantyClaimProblems = <T = INormalizedData<IListProblem>>(
	objectId: string,
	claimId: string,
	select?: (data: INormalizedData<IListProblem>) => T
) =>
	useQuery({
		queryKey: warrantyClaimsQueryKeys.claimProblems(objectId, claimId),
		select
	});

export const useWarrantyClaimProblemsArray = (objectId: string, claimId: string) =>
	useWarrantyClaimProblems(objectId, claimId, extractWarrantyClaimProblemsArray);

export const useWarrantyClaimEntities = (objectId: string, claimId: string) =>
	useWarrantyClaimProblems(objectId, claimId, extractWarrantyClaimEntities);
