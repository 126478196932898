import {t} from 'i18next';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

export function makeTitle(commentId?: string, comment?: IComment) {
	if (commentId) {
		if (comment?.number !== undefined) {
			return `${t('component.editableProblemReply.dialog.reply.title')} №${comment.number}`;
		}
		return t('component.editableProblemReply.dialog.reply.title');
	}
	return t('component.editableProblemReply.dialog.newReply.title');
}
