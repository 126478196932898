import EntitiesFilters, {
	CreatedByFilter,
	ObjectsFilter,
	CreationDateFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/interfaces';
import {InternalAcceptancesSearch} from './InternalAcceptancesSearch';
import {useTranslation} from 'react-i18next';

interface IInternalAcceptancesFiltersProps {
	objectId?: string;
}

export const InternalAcceptancesFilters = ({
	objectId = 'all'
}: IInternalAcceptancesFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IInternalAcceptancesFiltersState>();
	const {t} = useTranslation();

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="internal-acceptances"
		>
			<div className="internal-acceptances-page__search">
				<InternalAcceptancesSearch />
			</div>
			{objectId === 'all' && (
				<ObjectsFilter
					value={state?.objects}
					label={t('entitiesFilters.objectsFilter.label')}
				/>
			)}

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
				label={t('entitiesFilters.CreatedByFilter.label')}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
				label={t('entitiesFilters.CreationDateFilter.label')}
			/>
		</EntitiesFilters>
	);
};
