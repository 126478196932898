import { useEffect, useRef } from 'react';
import './AppContent.less';
import classNames from 'classnames';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useIsDesktop, useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {OfflineModeBar} from '@src/components/OfflineModeBar';
import {ErrorBoundary} from '@tehzor/ui-components';
import {Outlet} from 'react-router-dom';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';

const AppContent = () => {
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const isActivated = useAppSelector(s => s.auth.profile.activated);
	const isExpanded = useAppSelector(s => s.settings.sidebar.expanded);
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);

	const isTablet = useIsTablet();
	const isDesktop = useIsDesktop();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			// Добавление/удаление transition руками необходимо для избежания ненужных анимаций,
			// связанных с отступом из-за sidebar'а (при mount'е, logout'е и др.),
			// а также для предотвращения избыточного рендеринга
			// в случае использования локального state для хранения класса с transition.
			ref.current.style.transition =
				isDesktop && isAuthenticated && isActivated
					? 'margin 0.2s cubic-bezier(0.32, 0.72, 0.37, 0.95)'
					: '';
		}
	}, [isDesktop, isAuthenticated, isActivated]);

	const classes = classNames(
		'app-content',
		{'app-content_padding_56': isAuthenticated && isTablet && !isDesktop},
		{'app-content_padding_72': isAuthenticated && isDesktop && !isExpanded},
		{'app-content_padding_288': isAuthenticated && isDesktop && isExpanded}
	);

	return (
		<ErrorBoundary label="AppContent">
			<div
				className={classes}
				ref={ref}
			>
				{isAuthenticated && isOfflineModeAvailable && !isDesktop ? (
					<OfflineModeBar />
				) : null}

				<Outlet />
			</div>
		</ErrorBoundary>
	);
};

export default AppContent;
