import {useCallback, useState} from 'react';
import * as React from 'react';
import {EditableStructureDialog} from '../EditableStructureDialog';

export function useEditableStructureDialog(
	objectId: string,
	structureId?: string
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<EditableStructureDialog
			objectId={objectId}
			structureId={structureId}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
}
