import {IGetListStructuresResponse} from '@/shared/api/structures/getStructuresList';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useQuery} from '@tanstack/react-query';
import {extractCurrentStructuresPageSettings} from '../../../../slice/selectors';
import {structuresQueryKeys} from '../../../keys/index';

export const useStructuresList = <T = IGetListStructuresResponse>(
	objectId: string,
	select?: (data: IGetListStructuresResponse) => T
) => {
	const filters = {objects: objectId !== 'all' && [objectId]};
	const {sort} = useAppSelector(state => extractCurrentStructuresPageSettings(state, objectId));
	return useQuery({
		queryKey: [...structuresQueryKeys.list(), filters, sort],
		select,
		meta: {
			error: 'при загрузке списка структур'
		}
	});
};
