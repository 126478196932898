import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {FilterButton, Select2, SelectOption} from '@tehzor/ui-components';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import SelectSearch, {
	flatFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {useTranslation} from 'react-i18next';

interface IPlansFilterProps {
	label?: string;
	objectId?: string;
	plans?: string[];
}

export const PlansFilter = memo((props: IPlansFilterProps) => {
	const {t} = useTranslation();

	const {label = 'План', objectId = 'all', plans} = props;

	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedPlans, setSelectedPlans] = useState(plans);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const {data: allPlans} = usePlansAsArray(objectId);
	const filteredData = useMemo(
		() => (allPlans ? flatFilter(allPlans, 'name', search) : []),
		[allPlans, search]
	);

	const handleApply = useCallback(() => {
		dispatch({plans: selectedPlans});
		clearSearch();
	}, [selectedPlans, dispatch, clearSearch]);

	const handleClear = useCallback(() => {
		setSelectedPlans([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedPlans([]);
		dispatch({plans: undefined});
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedPlans(plans);
		clearSearch();
	}, [plans, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedPlans(allPlans?.map(val => val.id));
	}, [allPlans, clearSearch]);

	useUpdateEffect(() => {
		setSelectedPlans(plans);
	}, [plans]);

	return (
		<div>
			{allPlans && (
				<TranslatedSelectPopup
					onCancel={handleCancel}
					onApply={handleApply}
					onClear={handleClear}
					clearButton={!!selectedPlans?.length}
					onSelectAll={handleSelectAll}
					count={selectedPlans?.length}
					footer
					trigger={
						<FilterButton
							className="entities-filters__item"
							label={makeFilterLabel<IPlan>(label, plans, allPlans)}
							active={!!(plans && plans.length != 0)}
							onClear={handleFullClear}
						/>
					}
					search={
						<SelectSearch
							type="popup"
							value={search}
							placeholder={t('selectSearch.placeholder')}
							onChange={setSearch}
						/>
					}
				>
					<Select2
						multiple
						value={selectedPlans}
						onChange={setSelectedPlans}
					>
						{filteredData.map(item => (
							<SelectOption
								dataTestId="MenuItem"
								key={item.id}
								itemKey={item.id}
								content={item.name}
							/>
						))}
					</Select2>
				</TranslatedSelectPopup>
			)}
		</div>
	);
});
