import { memo } from 'react';
import {IconButton, Dialog, Breadcrumbs} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {EntityLinks} from '@src/components/EntityLinks/EntityLinks';
import {useWorkAcceptanceBreadcrumbs} from '@src/core/hooks/breadcrumbs/useWorkAcceptanceBreadcrumbs';

interface ITitleButtonsProps {
	objectId: string;
	workAcceptanceId: string;
}

const simpleArrowIcon = <i className="tz-simple-arrow-16" />;

export const TitleWorkAcceptanceButtons = memo(
	({objectId, workAcceptanceId}: ITitleButtonsProps) => {
		const {pushPath} = useChangePath();
		const breadcrumbs = useWorkAcceptanceBreadcrumbs(objectId, workAcceptanceId, undefined);
		const [isOpen, toggle] = useToggle(false);

		return (
			<>
				<IconButton
					type="inline"
					onClick={toggle}
				>
					{simpleArrowIcon}
				</IconButton>
				<Dialog
					onRequestClose={toggle}
					isOpen={isOpen}
				>
					{breadcrumbs && (
						<div className="check-list-page__links-dialog-breadcrumbs">
							<Breadcrumbs
								items={breadcrumbs}
								onNavigate={pushPath}
							/>
						</div>
					)}
					<EntityLinks links={{workAcceptanceId, objectId}} />
				</Dialog>
			</>
		);
	}
);
