import {useTranslation} from 'react-i18next';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {useProblemStatusesExpired} from '@src/core/hooks/queries/problemStatuses/hooks';

export const useTranslateProblemStatusesWithExpired = (): IProblemStatus[] => {
	const {t} = useTranslation();
	const {data: statuses} = useProblemStatusesExpired();

	return statuses
		? statuses.map(status => ({...status, name: t(`problemStatuses.${status.id}`)}))
		: [];
};
