import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IEntity} from '@tehzor/tools/core/storeHelpers/reducers/common';

export const addNewEntityToNormalizedData = <T extends INormalizedData<unknown>, D extends IEntity>(
	prevData: T,
	newLocalEntity: D
) => ({
	...prevData,
	allIds: [newLocalEntity.id, ...prevData.allIds],
	byId: {[newLocalEntity.id]: newLocalEntity, ...prevData.byId}
});
