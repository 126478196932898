import {memo, useEffect, useMemo, useState} from 'react';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {MobileFilter} from '@tehzor/ui-components';
import {filterEmptyTypeDecorationSets} from '@src/components/editableFields/SpaceTypeDecorationTreeSelect/utils/makeTreeData';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {
	useSpaceTypeDecorationsAsArray,
	useSpaceTypeDecorationsAsMap
} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {useExtractSpaceTypeDecorationSetsAsArray} from '@src/core/hooks/queries/SpaceTypeDecorationSets/hooks';

interface ISpaceTypesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpaceTypeDecorationFilterMobile = memo(
	({objectId, value, entity, onChange}: ISpaceTypesFilterMobileProps) => {
		const {t} = useTranslation();
		const [selected, setSelected] = useState([] as string[]);
		const [search, setSearch] = useState('');

		const {data: typeDecorationsSets} = useExtractSpaceTypeDecorationSetsAsArray();
		const {data: typeDecorationsAsArray} = useSpaceTypeDecorationsAsArray();
		const {data: typeDecorationsById} = useSpaceTypeDecorationsAsMap();

		const notEmptyTypeDecorationsSets = useMemo(() => {
			if (!typeDecorationsSets) return null;
			return filterEmptyTypeDecorationSets(typeDecorationsAsArray, typeDecorationsSets);
		}, [typeDecorationsAsArray, typeDecorationsSets]);

		const filteredElements = useMemo(() => {
			if (!notEmptyTypeDecorationsSets) return null;
			return flatFilter(notEmptyTypeDecorationsSets, 'name', search);
		}, [notEmptyTypeDecorationsSets, search]);

		useEffect(() => {
			const selectedSets = value?.reduce((prev, current) => {
				if (!typeDecorationsById) {
					return prev;
				}
				const setId = typeDecorationsById[current]?.typeDecorationSetId;

				return prev.add(setId);
			}, new Set<string>());

			setSelected(Array.from(selectedSets || []));
		}, [typeDecorationsById, value]);

		const handleChange = (v: string[] | undefined) => {
			let typeDecoration = [] as string[];
			setSelected(v || []);

			if (v && v?.length > 0 && typeDecorationsAsArray) {
				typeDecoration = typeDecorationsAsArray.reduce((prev, current) => {
					if (v?.includes(current.typeDecorationSetId)) {
						prev.push(current.id);
					}
					return prev;
				}, [] as string[]);
			}

			onChange({typeDecoration});
		};

		const handleExpand = useFilterPageTransition(objectId, entity, 'typeDecoration');

		if (!filteredElements) return null;

		return (
			<MobileFilter
				label={t('spacesFiltersPage.spaceTypeDecorationFilterMobile.mobileFilter.label')}
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
						placeholder={t(
							'spacesFiltersPage.spaceTypeDecorationFilterMobile.mobileFilter.selectSearch.placeholder'
						)}
					/>
				}
			/>
		);
	}
);
