import TasksPageCounter from '@src/pages/TasksPage/components/TasksPageCounter';
import {DesktopSwitchMode} from '@src/pages/TasksPage/components/DesktopSwitchMode';
import {useTasksPageDisplayMode} from '@src/core/hooks/queries/tasks/hooks';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';
import {TasksScheduleCollectTabs} from '@src/pages/TasksPage/components/tabs/TasksSchedulePage/components/TasksScheduleCollectTabs';

export const TasksPageControlBar = () => {
	const displayMode = useTasksPageDisplayMode();

	return (
		<div className="tasks-page__control-bar">
			{displayMode === TasksDisplayMode.LIST && <TasksPageCounter />}
			{displayMode === TasksDisplayMode.SCHEDULE && <TasksScheduleCollectTabs />}
			<DesktopSwitchMode />
		</div>
	);
};
