import {useMutation} from "@tanstack/react-query";
import {tasksQueryKeys} from "@src/api/cache/tasks/keys";
import {IMakeTaskResponsibleRequestData, makeTaskResponsible} from "@src/api/backend/task/editResponsible";
import {addErrorToast} from "@src/utils/toasts";
import {queryClient} from "@src/api/QueryClient";

export const useEditTaskResponsible = () => useMutation({
	mutationKey: tasksQueryKeys.edit(),
	mutationFn: (data: IMakeTaskResponsibleRequestData) => makeTaskResponsible(data),
	onError: () => addErrorToast('Ошибка', 'при изменении ответственных'),
	onSuccess: (data) => {
		queryClient.setQueryData(tasksQueryKeys.detail(data.id), (task) => (task ? ({...task, ...data}) : data))
		void queryClient.invalidateQueries({queryKey: tasksQueryKeys.all()})
	}
})