import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {useParams} from 'react-router-dom';
import {useCompaniesContractorsAsArray} from "@src/core/hooks/queries/companies/hooks";

export const ContractorsFilterPage = () => {
	const {entity = 'contracts'} = useParams<{entity?: string}>();
	const {data: contractors} = useCompaniesContractorsAsArray();

	return (
		<StatusesFilterPage
			entity={entity}
			statuses={contractors}
			label="Подрядчики"
			filterName="contractorIds"
		/>
	);
};