import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {IGetSpaceStatusesSetsResponse} from '@src/api/backend/spaceStatusesSets';
import {
	extractSpaceStatusesAsArrayByObjectId,
	extractSpaceStatusesAsArrayByStage,
	extractSpaceStatusesDataByObjectId,
	extractSpaceStatusesSetById,
	extractSpaceStatusesSetsForPage
} from './selectors';
import {useCallback} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceStatusesSetsPageSettings} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/selectors';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useAllSpaceStatusesAsArray} from '../spaceStatuses/hooks';

export const useGetSpaceStatusesSets = <T = IGetSpaceStatusesSetsResponse>(
	select?: (data: IGetSpaceStatusesSetsResponse) => T
) =>
	useQuery<IGetSpaceStatusesSetsResponse, IError, T>({
		queryKey: spaceStatusesSetsQueryKeys.list(),
		meta: {
			error: 'при загрузке статусов помещений'
		},
		select
	});

export const useExtractSpaceStatusesSetById = (id: string | undefined) => {
	const selector = useCallback(
		(data: IGetSpaceStatusesSetsResponse) => extractSpaceStatusesSetById(data, id),
		[id]
	);
	return useGetSpaceStatusesSets(selector);
};

export const useExtractSpaceStatusesSetsForPage = () => {
	const {data: allStatuses} = useAllSpaceStatusesAsArray();
	const pageSettings = useAppSelector(extractSpaceStatusesSetsPageSettings);
	const selector = useCallback(
		(data: IGetSpaceStatusesSetsResponse) =>
			extractSpaceStatusesSetsForPage(data, allStatuses, pageSettings),
		[allStatuses, pageSettings]
	);
	return useGetSpaceStatusesSets(selector);
};

export const useExtractSpaceStatusesAsArrayByObjectId = (objectId: string) => {
	const {data: allStatuses} = useAllSpaceStatusesAsArray();
	const {data: stages} = useObjectStagesMap();
	const {data: object} = useObject(objectId);
	const selector = useCallback(
		(data: IGetSpaceStatusesSetsResponse) =>
			extractSpaceStatusesAsArrayByObjectId(data, allStatuses, stages, object),
		[allStatuses, stages, object]
	);
	return useGetSpaceStatusesSets(selector);
};

export const useExtractSpaceStatusesDataByObjectId = (objectId: string) => {
	const {data: statuses} = useExtractSpaceStatusesAsArrayByObjectId(objectId);
	const selector = useCallback(() => extractSpaceStatusesDataByObjectId(statuses), [statuses]);
	return useGetSpaceStatusesSets(selector);
};

export const useExtractSpaceStatusesAsArrayByStage = (
	objectId: string,
	stage: ObjectStageIds | undefined
) => {
	const {data: statuses} = useExtractSpaceStatusesAsArrayByObjectId(objectId);
	const selector = useCallback(
		() => extractSpaceStatusesAsArrayByStage(statuses, stage),
		[statuses, stage]
	);
	return useGetSpaceStatusesSets(selector);
};
