import {CSSProperties, forwardRef, MouseEvent, ReactNode, Ref} from 'react';
import {OuterTagProps, OuterTagType} from './ButtonBase';

interface IButtonBaseOuterProps {
	className: string;
	style?: CSSProperties;
	tagType: OuterTagType;
	tagProps: OuterTagProps;
	children: ReactNode;
	dataTestId?: string;

	onClick?: (event: MouseEvent) => void;
	onMouseEnter?: (event: MouseEvent) => void;
	onMouseLeave?: (event: MouseEvent) => void;
}

const ButtonBaseOuter = (
	{tagType, tagProps, dataTestId, children, ...rest}: IButtonBaseOuterProps,
	ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>
) => {
	const props = {
		...rest,
		...tagProps,
		tabIndex: 0,
		ref
	} as Record<string, unknown>;

	const Component = tagType;

	return (
		<Component data-testid={dataTestId} {...props}>
			{children}
		</Component>
	);
};

export default forwardRef(ButtonBaseOuter);
