import { useCallback, useState } from 'react';
import * as React from 'react';
import {TasksSchedulePopup} from '../TasksSchedulePopup';
import {IEnrichedTaskForScheduler} from "@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler";

export function useTasksSchedulePopup(
	tasks: IEnrichedTaskForScheduler[],
	title?: string,
	objectId?: string,
	startTime?: number,
	endTime?: number
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const popup = (
		<TasksSchedulePopup
			tasks={tasks}
			isOpen={isOpen}
			onClose={close}
			title={title}
			objectId={objectId}
			startTime={startTime}
			endTime={endTime}
		/>
	) ;

	return [popup, open];
}
