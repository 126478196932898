import {Column} from 'react-table';
import {IPreparedClaim} from '../../interfaces/IPreparedClaim';
import OwnerCell from './OwnerCell';
import {MenuCell} from './MenuCell';
import {TFunction} from 'i18next';
import {NumberAndStatsCell} from './NumberAndStatsCell';
import {StatusCell} from './StatusCell';
import {CreatedCell} from './CreatedCell';
import {ModifiedCell} from './ModifiedCell';
import {SpaceCell} from './SpaceCell';

export const getDesktopColumns = (tFunc: TFunction): Array<Column<IPreparedClaim>> => [
	{
		id: 'number',
		Header: '№',
		Cell: NumberAndStatsCell,
		accessor: claim => claim.number ?? claim.localNumber,
		minWidth: 60,
		width: 90
	},
	{
		id: 'status',
		Header: tFunc('warrantyClaimsPage.table.columns.status.header'),
		Cell: StatusCell,
		accessor: claim => claim.status,
		width: 166,
		minWidth: 166
	},
	{
		Header: tFunc('warrantyClaimsPage.table.columns.object.header'),
		id: 'object',
		accessor: row => row.object?.name,
		width: 100
	},
	{
		id: 'space',
		Header: tFunc('warrantyClaimsPage.table.columns.spaceCell.header'),
		Cell: SpaceCell,
		width: 80
	},
	{
		Header: tFunc('warrantyClaimsPage.table.columns.registrationDate.header'),
		id: 'registrationDate',
		accessor: row => row.registrationDate,
		width: 100,
		sortDescFirst: true
	},
	{
		Header: tFunc('warrantyClaimsPage.table.columns.spaceOwner.header'),
		id: 'spaceOwner',
		accessor: row => row.spaceOwner?.name,
		Cell: OwnerCell,
		width: 100
	},
	{
		id: 'createdAt',
		Header: tFunc('warrantyClaimsPage.table.columns.createdAt.header'),
		Cell: CreatedCell,
		accessor: claim => claim.createdAt,
		width: 80,
		sortDescFirst: true
	},
	{
		id: 'modifiedAt',
		Header: tFunc('warrantyClaimsPage.table.columns.modifiedAt.header'),
		Cell: ModifiedCell,
		accessor: claim => claim.modifiedAt,
		width: 80,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'warranty-claims-table__menu-cell',
		width: 60,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];

export const getDesktopColumnsWithoutObject = (tFunc: TFunction) =>
	getDesktopColumns(tFunc).filter(item => item.id !== 'object');
