const domain = 'auth';

export const LOGIN_REQUEST = `${domain}/login/request`;
export const LOGIN_SUCCESS = `${domain}/login/success`;
export const LOGIN_FAILURE = `${domain}/login/failure`;

export const LOGOUT = `${domain}/logout`;

export const UPDATE_TOKENS_REQUEST = `${domain}/tokens/update/request`;
export const UPDATE_TOKENS_SUCCESS = `${domain}/tokens/update/success`;
export const UPDATE_TOKENS_FAILURE = `${domain}/tokens/update/failure`;

export const CLEAR_STORE = `${domain}/clear-store`;