import {Suspense, useCallback, useMemo} from 'react';
import {SingleSchema} from './SingleSchema';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {LoadingPanel, MobilePagination, Plate} from '@tehzor/ui-components';
import {changeCurrentObject} from '@src/store/modules/entities/spaces/actions';
import {useVisibleObjects} from '../../hooks/useVisibleObjects';
import {extractSpacesSchemaData} from '@src/store/modules/entities/spaces/selectors/schemas';
import {DesktopSchemaViewTabs} from '@src/pages/SpacesPage/components/SchemaViewTabs.desktop';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {Table} from '../reportsTable/Table';
import {useObjectsMap} from '@src/core/hooks/queries/objects/hooks';
import {MobileSchemaLoader} from '@tehzor/ui-components/src/components/loaders/skeletons/MobileSchemaLoader';

interface IMobileSchemasProps {
	objectId: string;
	targetObjects: string[];
}

const MobileSchemas = ({objectId, targetObjects}: IMobileSchemasProps) => {
	const {currentObject} = useAppSelector(s => extractSpacesSchemaData(s, objectId));
	const objectsMap = useObjectsMap();
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const objects = useVisibleObjects(objectId, targetObjects);

	const currentIndex = useMemo(() => {
		if (currentObject) {
			const index = objects.indexOf(currentObject);
			return index === -1 ? 0 : index;
		}
		return 0;
	}, [currentObject, objects]);

	const getObjectTitle = useCallback(
		() => (objectsMap ? objectsMap[currentObject ?? objects[0]]?.name ?? '' : null),
		[currentObject, objects, objectsMap]
	);

	const handlePageChange = useCallback(
		(value: number) => {
			dispatch(changeCurrentObject(objectId, objects[value]));
		},
		[objectId, objects, dispatch]
	);

	const isReportsView = pageSettings.schemaView === SpacesSchemaVariants.REPORTS;

	return (
		<Plate
			withoutPadding
			className="spaces-page__plate"
		>
			<div className="spaces-page__plate-header">
				<DesktopSchemaViewTabs objectId={objectId} />
			</div>

			{!isReportsView && (
				<>
					<MobilePagination
						className="spaces-page__m-pagination"
						page={currentIndex}
						pageCount={objects.length}
						renderTitle={getObjectTitle}
						onPageChange={handlePageChange}
					/>
					<Suspense fallback={<MobileSchemaLoader />}>
						<SingleSchema
							objectId={objects[currentIndex]}
							hideTitle
						/>
					</Suspense>
				</>
			)}
			{isReportsView && (
				<Suspense fallback={<LoadingPanel />}>
					<Table objectId={objectId} />
				</Suspense>
			)}
		</Plate>
	);
};

export default MobileSchemas;
