import {wsConnector} from '@src/api/backend/wsConnector';
import {IInternalAcceptancesStatsDataRequest} from '@src/interfaces/IInternalAcceptancesStatsData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

export type IGetInternalAcceptanceProblemsResponse = IEntityProblemsData;

/**
 * Возвращает статистику нарушений по внутренним приемкам
 */
export const requestInternalAcceptancesProblemsData = (requestData: IInternalAcceptancesStatsDataRequest) =>
	wsConnector.sendAuthorizedRequest<IGetInternalAcceptanceProblemsResponse>('getInternalAcceptancesProblemsData', {...requestData});
