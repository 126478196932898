import { useMemo } from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ActionsMobile} from './Actions.mobile';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {extractStructureEntities} from '@src/store/modules/entities/structure/selectors';
import {SelectionActions} from '../selection/SelectionActions';

interface IMobileRightButtonsProps {
	objectId: string;
	structure: IStructure;
	selectedRows?: string[];
}

export const MobileRightButtons = ({objectId, structure, selectedRows}: IMobileRightButtonsProps) => {
	const entities = useAppSelector(extractStructureEntities);

	const selectedEntities = useMemo(
		() => entities.filter(item => selectedRows?.includes(item.id)),
		[selectedRows, entities]
	);

	if (!structure) {
		return null;
	}

	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			structure={structure}
		/>
);
};