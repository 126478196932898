import { useCallback } from 'react';
import * as React from 'react';
import './UploadingImage.less';
import classNames from 'classnames';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import {PhotoPreview} from '../../photos';
import CircleButton from '../../buttons/CircleButton';
import Icon from './components/Icon';

export interface IUploadingImageProps<D> {
	className?: string;
	style?: React.CSSProperties;
	name: string;
	url: string;
	type: string;
	size?: number;
	progress: number;
	status: UploadingFileStatus;
	disabled?: boolean;
	data: D;

	onClick?: (data: D, url: string) => void;
	onDelete?: (data: D) => void;
	onReload?: (data: D) => void;
}

const UploadingImage = <D extends unknown>(props: IUploadingImageProps<D>) => {
	const {className, style, url, progress, status, disabled, data, onClick, onDelete, onReload} = props;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(data, url);
		}
	}, [data, onClick]);

	const handleDelete = useCallback(() => {
		if (onDelete) {
			onDelete(data);
		}
	}, [data, onDelete]);

	const handleReload = useCallback(() => {
		if (onReload) {
			onReload(data);
		}
	}, [data, onReload]);

	return (
		<div
			className={classNames('uploading-image', className)}
			style={style}
		>
			<PhotoPreview
				className={{
					root: 'uploading-image__photo',
					wrap: status === UploadingFileStatus.ERROR ? 'uploading-image__photo-wrap_error' : undefined
				}}
				url={url}
				data={data}
				active={status === UploadingFileStatus.ERROR}
				onClick={handleClick}
			/>

			<Icon
				progress={progress}
				status={status}
				disabled={disabled}
				onReloadClick={handleReload}
			/>

			<CircleButton
				className="uploading-image__delete-btn"
				type="dark"
				disabled={disabled}
				onClick={handleDelete}
			>
				<i className="tz-close-16"/>
			</CircleButton>
		</div>
	);
};

export default UploadingImage;