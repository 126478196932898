import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeInspectionExportRequest} from '@src/api/backend/inspection';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {addExportToast} from '@src/components/ExportToast/addExportToast';

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse, sending: boolean) => {
	if (response.link) {
		addExportToast(
			sending ? 'Документ отправлен' : 'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document,
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при экспорте');
	return {
		type: types.EXPORT_FAILURE,
		payload: error
	};
};

/**
 * Экспортирует осмотр
 *
 * @param templateId id шаблона экспорта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param objectId id объекта
 * @param inspectionId id осмотра
 */
export const exportInspection = (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	inspectionId: string
) =>
	createApiAction<IExportResponse>(
		request,
		(r: IExportResponse) => success(r, !!email),
		failure,
		() => makeInspectionExportRequest(templateId, createDocument, email, objectId, inspectionId)
	);
