import * as React from 'react';
import './LinearLoader.less';
import classNames from 'classnames';

interface ICircleLoaderProps {
	className?: string;
	style?: React.CSSProperties;
	percent?: number;
	trackColor?: string;
	progressColor?: string;
}

export const LinearLoader = (props: ICircleLoaderProps) => {
	const {className, style, percent, trackColor = '#EDEFF2', progressColor = '#59A5FF'} = props;

	return (
		<div
			className={classNames(
				'linear-loader',
				percent === undefined ? 'linear-loader_looped' : 'linear-loader_definite',
				className
			)}
			style={{...style, backgroundColor: trackColor}}
		>
			<div
				className="linear-loader__progress"
				style={{backgroundColor: progressColor, width: percent !== undefined ? `${percent}%` : undefined}}
			/>
		</div>
	);
};