import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractStoriesAsArray} from './selectors';
import {useWorkAcceptancesPermissions} from '../../permissions/useWorkAcceptancesPermissions';
import {IGetWorkAcceptanceStoriesResponse} from '@src/api/backend/workAcceptanceStories';
import {workAcceptanceStoriesQueryKeys} from '@src/api/cache/workAcceptanceStories/keys';
import {IWorkAcceptanceStory} from '@tehzor/tools/interfaces/workAcceptancesStories/IWorkAcceptanceStory';

export const useWorkAcceptanceStoriesHook = <T = IGetWorkAcceptanceStoriesResponse>(
	objectId: string,
	workAcceptanceId: string,
	select?: (data: IGetWorkAcceptanceStoriesResponse) => T
) => {
	const permissions = useWorkAcceptancesPermissions(objectId);
	return useQuery<IGetWorkAcceptanceStoriesResponse, IError, T>({
		queryKey: [...workAcceptanceStoriesQueryKeys.list(objectId, workAcceptanceId)],
		meta: {
			error: 'при загрузке истории изменений приёмки работ'
		},
		select,
		enabled: !!permissions.canViewHistory && !!workAcceptanceId
	});
};

export const useWorkAcceptanceStoriesAsArray = (objectId: string, workAcceptanceId: string) =>
	useWorkAcceptanceStoriesHook<IWorkAcceptanceStory[]>(
		objectId,
		workAcceptanceId,
		extractStoriesAsArray
	);
