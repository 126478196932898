import {useCallback, useMemo, useState} from 'react';
import {ViewerType, InputType} from '../LocationSelect';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import Select, {Option} from '../../Select';
import {Select2, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '../../inputs';
import SelectSearch, {flatFilter} from '../../inputs/select/SelectSearch';

export interface IControlsDisplayValue {
	name: string;
	description?: string;
}

interface Translated {
	result: string;
	markedPoints: string;
	selectedSectors: string;
	display: string;
	plan: string;
	map: string;
	selectionMethod: string;
	pointsT: string;
	sectors: string;
	layersT: string;
}

interface IControlsProps {
	viewerType: ViewerType;
	inputType: InputType;
	planId?: string;
	plans: IPlan[];
	layers: ILayer[];
	visibleLayers: string[];
	displayValue: IControlsDisplayValue[];
	availableViewerTypes?: ViewerType[];
	availableInputTypes?: InputType[];
	onViewerTypeChange: (value: ViewerType) => void;
	translated?: Translated;

	onInputTypeChange: (value: InputType) => void;

	onVisibleLayersChange: (value: string[]) => void;

	onPlanChange: (value: string) => void;

	onValueSelect: (index: number) => void;
}

const Controls = ({
	viewerType,
	inputType,
	planId,
	plans,
	layers,
	visibleLayers,
	translated = {
		result: 'Результат',
		markedPoints: 'Отмеченные точки',
		selectedSectors: 'Выбранные секторы',
		display: 'Отображение',
		plan: 'План',
		map: 'Карта',
		selectionMethod: 'Способ выбора',
		pointsT: 'Точки',
		sectors: 'Секторы',
		layersT: 'Слои'
	},
	displayValue,
	availableViewerTypes,
	availableInputTypes,
	onViewerTypeChange,
	onInputTypeChange,
	onVisibleLayersChange,
	onPlanChange,
	onValueSelect
}: IControlsProps) => {
	const handleVisibleLayersChange = useCallback(
		(value?: string[]) => {
			onVisibleLayersChange(value || []);
		},
		[onVisibleLayersChange]
	);
	const {
		display,
		layersT,
		map,
		markedPoints,
		pointsT,
		result,
		plan,
		selectedSectors,
		selectionMethod,
		sectors
	} = translated;
	const [search, setSearch] = useState<string>('');
	const clearSearch = useCallback(() => setSearch(''), []);
	const filteredData = useMemo(() => flatFilter(plans, 'name', search), [plans, search]);

	let title = `${result}:`;
	if (inputType === 'points') {
		title = `${markedPoints}:`;
	}
	if (inputType === 'sectors') {
		title = `${selectedSectors}:`;
	}

	const points = displayValue.map((value, index) => (
		<div
			className="loc-select__controls-points"
			onClick={() => {
				onValueSelect(index);
			}}
		>
			<div className="loc-select__controls-points-name">{value.name}</div>
			<div className="loc-select__controls-points-description">{value.description}</div>
		</div>
	));

	return (
		<div className="loc-select__controls">
			{availableViewerTypes && availableViewerTypes.length > 1 && (
				<div className="loc-select__controls-col">
					<div className="loc-select__controls-title">{display}:</div>
					<Select
						className="loc-select__controls-select"
						value={viewerType}
						onChange={onViewerTypeChange}
					>
						{availableViewerTypes.includes('plan') && (
							<Option key="plan" value="plan">
								{plan}
							</Option>
						)}
						{availableViewerTypes.includes('map') && (
							<Option key="map" value="map">
								{map}
							</Option>
						)}
					</Select>
				</div>
			)}

			{viewerType === 'plan' && (
				<div className="loc-select__controls-col">
					<div className="loc-select__controls-title">{plan}:</div>
					<SelectPopup
						trigger={
							<TextFieldWithForwardedRef
								value={plans.find(plan => plan.id === planId)?.name}
								elementType="div"
								className={{element: 'loc-select__controls-plan-select'}}
							/>
						}
						onCancel={clearSearch}
						search={<SelectSearch value={search} onChange={setSearch} />}
					>
						<Select2
							className="loc-select__controls-select"
							value={planId}
							onChange={onPlanChange}
						>
							{filteredData.map(item => (
								<SelectOption
									key={item.id}
									itemKey={item.id}
									inputType="radio"
									content={item.name}
								/>
							))}
						</Select2>
					</SelectPopup>
				</div>
			)}

			{viewerType === 'plan' && availableInputTypes && availableInputTypes.length > 1 && (
				<div className="loc-select__controls-col">
					<div className="loc-select__controls-title">{selectionMethod}:</div>
					<Select
						className="loc-select__controls-select"
						value={inputType}
						onChange={onInputTypeChange}
					>
						{availableInputTypes.includes('points') && (
							<Option key="points" value="points">
								{pointsT}
							</Option>
						)}
						{availableInputTypes.includes('sectors') && (
							<Option key="sectors" value="sectors">
								{sectors}
							</Option>
						)}
					</Select>
				</div>
			)}

			{viewerType === 'plan' && inputType === 'sectors' && (
				<div className="loc-select__controls-col">
					<div className="loc-select__controls-title">{layersT}:</div>
					<Select
						className="loc-select__controls-select"
						allowClear={false}
						multiple
						maxTagCount={1}
						menuMaxHeight="300px"
						value={visibleLayers}
						onChange={handleVisibleLayersChange}
						controlRender={(items: Array<{label: string}>) => {
							const label = items.map(item => item.label).join(', ');
							return (
								<div className="loc-select__controls-layer-label" title={label}>
									{label}
								</div>
							);
						}}
					>
						{layers &&
							layers.map(item => (
								<Option key={item.id} value={item.id}>
									{item.name}
								</Option>
							))}
					</Select>
				</div>
			)}

			<div className="loc-select__controls-wide-col">
				<div className="loc-select__controls-title">{title}</div>

				{points}
			</div>
		</div>
	);
};

export default Controls;
