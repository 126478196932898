import { useMemo } from 'react';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import SelectionActions from '../selection/SelectionActions';
import ActionsMobile from './Actions.mobile';
import {useInternalAcceptanceEntities} from '@src/core/hooks/queries/internalAcceptance/hook';

interface IMobileRightButtonsProps {
	objectId: string;
	internalAcceptanceId: string;
	internalAcceptance?: ILinkedInternalAcceptance;
	selectedRows: string[];
}

const MobileRightButtons = ({
	objectId,
	internalAcceptanceId,
	internalAcceptance,
	selectedRows
}: IMobileRightButtonsProps) => {
	const entities = useInternalAcceptanceEntities(objectId, internalAcceptanceId);

	const selectedEntities = useMemo(
		() => entities.filter(item => selectedRows?.includes(item.id)),
		[selectedRows, entities]
	);

	if (!internalAcceptance) {
		return null;
	}
	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				internalAcceptanceId={internalAcceptanceId}
				internalAcceptance={internalAcceptance}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			internalAcceptance={internalAcceptance}
		/>
	);
};

export default MobileRightButtons;
