import {useQueries, useQuery, UseQueryResult} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {IGetStructureTypesResponse} from '@src/api/backend/structureTypes';
import {
	extractStructureTypesAllIds,
	extractStructureTypesAsArray,
	extractStructureTypesById
} from './selectors';
import {useCallback} from 'react';
import {
	ISchemaStructureType,
	IStructureType
} from '@tehzor/tools/interfaces/structures/IStructureType';
import {structuresQueryKeys} from '@/entities/Structures';
import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';
import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {extractStructuresListAsArray} from '@/entities/Structures/model/cache/queries/Structures/selectors/extractStructuresListAsArray';

export const useStructureTypes = <T = IGetStructureTypesResponse>(
	select?: (data: IGetStructureTypesResponse) => T
) =>
	useQuery<IGetStructureTypesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.structureTypes(),
		meta: {
			error: 'при загрузке типов структур'
		},
		select
	});

export const useExtractStructureTypesAsArray = () =>
	useStructureTypes(extractStructureTypesAsArray);
export const useExtractStructureTypesById = () => useStructureTypes(extractStructureTypesById);
export const useExtractStructureTypesAllIds = () => useStructureTypes(extractStructureTypesAllIds);

export const useStructuresSchemaTypes = (objectId: string) => {
	const filters = {objects: objectId !== 'all' && [objectId]};
	const combine = useCallback(
		(result: [UseQueryResult<IListStructure[]>, UseQueryResult<IStructureType[]>]) => {
			const [structuresResult, typesResult] = result;
			const {data: structures, isLoading: isStructuresLoading} = structuresResult;
			const {data: types, isLoading: isTypesLoading} = typesResult;
			const reducedTypes = types?.reduce<ISchemaStructureType[]>((acc, type) => {
				const typeStructures = structures?.filter(structure => structure.type === type.id);
				if (typeStructures?.length) {
					acc.push({...type, structuresQuantity: typeStructures.length});
				}
				return acc;
			}, []);
			return {
				data: reducedTypes,
				isLoading: oneArgIsTrue(isTypesLoading, isStructuresLoading)
			};
		},
		[]
	);

	return useQueries({
		queries: [
			{
				queryKey: [...structuresQueryKeys.list(), filters],
				select: extractStructuresListAsArray
			},
			{
				queryKey: restDictionariesQueryKeys.structureTypes(),
				select: extractStructureTypesAsArray
			}
		],
		combine
	});
};
