import { useCallback, useContext } from 'react';
import * as React from 'react';
import './SwitchMenuOption.less';
import classNames from 'classnames';
import ButtonBase from '../../../../buttons/ButtonBase';
import {SwitchMenuContext} from '../../SwitchMenuContext';

interface IToggleButton {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	value?: string;
	checked?: boolean;
	disabled?: boolean;

	onClick?: (event: React.MouseEvent) => void;
}

export const SwitchMenuOption = (props: IToggleButton) => {
	const {className, style, children, value, disabled, onClick} = props;
	let {checked} = props;

	const group = useContext(SwitchMenuContext);
	if (group && checked === undefined && value !== undefined) {
		checked = group.isChecked(value);
	}

	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			if (onClick) {
				onClick(event);
			}
			if (value !== undefined && group?.onChange) {
				group.onChange(value);
			}
		},
		[value, onClick, group?.onChange]
	);

	return (
		<ButtonBase
			className={classNames(
				{'switch-menu-option_checked': checked},
				className
			)}
			style={style}
			classNamePrefix="switch-menu-option"
			outerTagType="button"
			label={children}
			disabled={disabled}
			onClick={handleClick}
		/>
	);
};