import {memo, useCallback} from 'react';
import MobileInfo from './info/Info.mobile';
import {Plate} from '@tehzor/ui-components';
import MobileLinks from './links/Links.mobile';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import Documents from './attachments/Documents';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useEntityAndReplyViewerMobile} from '@src/core/hooks/imagesViewers/mobile/useEntityAndReplyViewerMobile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import PageBreadcrumbs from './PageBreadcrumbs';
import {extractHasLocationComments} from '@src/store/modules/pages/problem/selectors/problem';
import {IPermissions} from '../hooks/usePermissions';
import {formProblemCommentsLink} from '@tehzor/tools/utils/links/formProblemCommentsLink';
import {ICommentReferenceState} from '@src/components/Comments/hooks/useCommentReference';
import MobileLastReply from '@src/components/Comments/components/LastReply.mobile';
import {useCommentsCtx} from '@src/components/Comments/utils/CommentsCtx';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useTranslation} from 'react-i18next';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

interface IMobileProps {
	objectId: string;
	problemId: string;
	problem?: IPreparedProblem;
	permissions: IPermissions;
	settings?: IEntitySettings;
}

const Mobile = memo(({objectId, problemId, problem, permissions, settings}: IMobileProps) => {
	const {t} = useTranslation();
	const {lastReply} = useCommentsCtx();
	const {pushPath} = useChangePath();
	const [problemImagesAttachments, docs] = useSplitAttachments(problem);
	const problemImages = useAttachmentsImages(problemImagesAttachments);
	const problemImagesData = useAttachmentsImagesObjects(problemImagesAttachments);
	const [replyImagesAttachments] = useSplitAttachments(lastReply);
	const replyImages = useAttachmentsImages(replyImagesAttachments);
	const replyImagesData = useAttachmentsImagesObjects(replyImagesAttachments);
	const hasLocationComments = useAppSelector(extractHasLocationComments);
	const goToComments = useCallback(() => {
		pushPath(formProblemCommentsLink(objectId, problemId));
	}, [objectId, problemId]);
	const goToCommentsWithAdding = useCallback(
		(comment: IComment) => {
			pushPath(formProblemCommentsLink(objectId, problemId), {
				reference: {
					type: 'add',
					comment
				} as ICommentReferenceState
			});
		},
		[objectId, problemId]
	);

	const [imagesViewer, openProblemImage, openReplyImage] = useEntityAndReplyViewerMobile(
		problemImages,
		replyImages,
		AttachmentFileDestination.PROBLEM,
		AttachmentFileDestination.COMMENT_OR_REPLY,
		problemImagesData,
		replyImagesData,
		'problem',
		'Фото нарушения',
		lastReply?.number,
		problemId,
		lastReply?.id
	);

	return (
		<div className="page-cont problem-page">
			<PageBreadcrumbs objectId={objectId} />
			{problem !== undefined && (
				<MobileLinks
					objectId={objectId}
					problem={problem}
					permissions={permissions}
				/>
			)}

			{problem !== undefined && (
				<Plate className="problem-page__m-plate problem-page__m-info">
					<MobileInfo
						objectId={objectId}
						problem={problem}
						images={problemImages}
						onImageClick={openProblemImage}
						hasLocationComments={hasLocationComments}
						permissions={permissions}
						settings={settings}
					/>
				</Plate>
			)}

			{docs.length > 0 && (
				<Plate
					className="problem-page__m-plate"
					title={t('problemPage.attachment.title')}
				>
					<Documents files={docs} />
				</Plate>
			)}

			<MobileLastReply
				onImageClick={openReplyImage}
				goToComments={goToComments}
				goToCommentsWithAdding={goToCommentsWithAdding}
			/>

			{imagesViewer}
		</div>
	);
});

export default Mobile;
