import { useCallback } from 'react';
import {CheckListItem} from './components/CheckListItem';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {AcceptedItems} from './components/AcceptedItems';
import './CheckListItems.less';
import {formStructureCheckItemLink} from '@tehzor/tools/utils/links';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useSeparatedCheckItems} from '@src/pages/CheckListPage/hooks/useSeparatedCheckItems';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface ICheckListItemsProps {
	checkList: ICheckList;
}

export const CheckListItems = ({checkList}: ICheckListItemsProps) => {
	const {replacePath, pushPath} = useChangePath();
	const params = useStrictParams<{
		objectId: string;
		stage: string;
		listId: string;
		structureId: string;
	}>();
	const isDesktop = useIsDesktop();
	const {accepted, current, next} = useSeparatedCheckItems(checkList.id);
	const handleClick = useCallback(
		(item: ICheckItem) => {
			const navigate = isDesktop ? replacePath : pushPath;
			navigate(
				formStructureCheckItemLink({
					...params,
					listId: item.checkListId,
					itemId: item.id,
					structureId: params.structureId
				})
			);
		},
		[isDesktop, params]
	);

	return (
		<div className="structure-check-list-items">
			{accepted.length > 0 && (
				<>
					<AcceptedItems
						items={accepted}
						stage={checkList.stage}
						onItemClick={handleClick}
					/>
					<hr className="structure-page__check-list__divider structure-page__check-list__divider_hr" />
				</>
			)}

			{current.map(item => (
				<CheckListItem
					mode="main"
					key={item.id}
					item={item}
					onClick={handleClick}
				/>
			))}

			{next.length > 0 && (
				<>
					<hr className="structure-page__check-list__divider check-list-page__divider_hr" />

					<div className="structure-check-list-items__next">
						<div className="structure-check-list-items__next_disabled" />
						{next.map(item => (
							<CheckListItem
								mode="main"
								key={item.id}
								item={item}
								onClick={handleClick}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
};
