import {IGetSpaceStatusesSetsResponse} from '@src/api/backend/spaceStatusesSets';
import {
	ISpaceStatusesSetsFiltersState,
	ISpaceStatusesSetsPageSettingsState
} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/reducers';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {IFullSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/IFullSpaceStatusesSet';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {handleSort} from './utils/handleSort';
import {handleFilter} from './utils/handleFilter';
import {handlePagination} from './utils/handlePagination';

export const extractSpaceStatusesSetsAsArray = (data: IGetSpaceStatusesSetsResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

export const extractSpaceStatusesSetById = (
	data: IGetSpaceStatusesSetsResponse,
	id: string | undefined
) => (id ? data.byId[id] : undefined);

export const extractFullSpaceStatusesSetsData = (
	data: IGetSpaceStatusesSetsResponse,
	allStatusesArr: Record<string, ISpaceStatus[]> | undefined
) => {
	const total = data.allIds.length;
	const fullById: {[id: string]: IFullSpaceStatusesSet} = {};

	data.allIds.forEach(id => {
		fullById[id] = {
			...data.byId[id],
			statuses: allStatusesArr?.[id] ?? []
		};
	});

	return {allIds: data.allIds, byId: fullById, total};
};

export const extractFullSpaceStatusesSetsAsArray = (
	data: IGetSpaceStatusesSetsResponse,
	allStatuses: Record<string, ISpaceStatus[]> | undefined
) => {
	const {allIds, byId} = extractFullSpaceStatusesSetsData(data, allStatuses);
	return allIds.map(id => byId[id]);
};

export const extractSpaceStatusesSetsForPage = (
	data: IGetSpaceStatusesSetsResponse,
	allStatuses: Record<string, ISpaceStatus[]> | undefined,
	pageSettings: ISpaceStatusesSetsPageSettingsState
) => {
	const fullSpaceStatusesSetsAsArray = extractFullSpaceStatusesSetsAsArray(data, allStatuses);
	const {offset, pageSize, sort, filters} = pageSettings;

	const scopeFilters: ISpaceStatusesSetsFiltersState = {};

	const filteredArr = handleSort(fullSpaceStatusesSetsAsArray, sort)
		.filter(item => handleFilter(item, scopeFilters))
		.filter(item => handleFilter(item, filters));

	const paginatedArr = filteredArr.filter((item, i) => handlePagination(i, offset, pageSize));

	return {
		data: paginatedArr,
		pageCount: Math.ceil(filteredArr.length / pageSize),
		currentPage: Math.floor(offset / pageSize),
		total: filteredArr.length
	};
};

export const extractSpaceStatusesAsArrayByObjectId = (
	data: IGetSpaceStatusesSetsResponse,
	allStatuses: Record<string, ISpaceStatus[]> | undefined,
	stagesMap: Record<string, IObjectStage> | undefined,
	object: IObject | undefined
) => {
	const sets = extractSpaceStatusesSetsAsArray(data);
	const companyId = object?.companyId;
	const objectId = object?.id || 'all';

	let set = sets.find(item => item.objects?.includes(objectId));
	if (!set) {
		set = sets.find(item =>
			item.companyId && !item.objects?.length ? item.companyId === companyId : false
		);
	}
	if (!set) {
		set = sets.find(item => item.default);
	}

	const id = set?.id;

	const statuses: ISpaceStatus[] = id && allStatuses && allStatuses[id] ? allStatuses[id] : [];

	return statuses.sort((prev, next) => {
		const prevStage = stagesMap?.[prev.stage];
		const nextStage = stagesMap?.[next.stage];

		if (prevStage && nextStage) {
			if (prevStage?.name < nextStage?.name) {
				return 1;
			}
			if (prevStage?.name > nextStage?.name) {
				return -1;
			}
		}
		return 0;
	});
};

export const extractSpaceStatusesDataByObjectId = (statuses: ISpaceStatus[] | undefined) => {
	if (!statuses) return null;

	const allIds: string[] = [];
	const byId: Record<string, ISpaceStatus> = {};

	for (const status of statuses) {
		allIds.push(status.id);
		byId[status.id] = status;
	}

	return {allIds, byId};
};

export const extractSpaceStatusesAsArrayByStage = (
	statuses: ISpaceStatus[] | undefined,
	stage: ObjectStageIds | undefined
) => (!stage ? statuses : statuses?.filter(item => item.stage === stage));
