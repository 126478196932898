import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	makeWarrantyClaimStatusEditRequest,
	IEditWarrantyClaimStatusResponse
} from '@src/api/backend/warrantyClaim';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import {queryClient} from '@src/api/QueryClient';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IEditWarrantyClaimStatusPayload = IEditWarrantyClaimStatusResponse;

const request = () => ({
	type: types.EDIT_STATUS_REQUEST
});

const success = (response: IEditWarrantyClaimStatusResponse) => {
	void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.lists()});
	void queryClient.invalidateQueries({
		queryKey: [...warrantyClaimsQueryKeys.detail(response.id), response.objectId]
	});
	if (response.links?.spaceId) {
		void queryClient.invalidateQueries({
			queryKey: spacesQueryKeys.spaceWarrantyClaims(response.objectId, response.links.spaceId)
		});
	}

	return {
		type: types.EDIT_STATUS_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении статуса обращения');
	return {
		type: types.EDIT_STATUS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет статус гарантийного обращения
 */
export const editWarrantyClaimStatus = (
	objectId: string,
	claimId: string,
	statusId: WarrantyClaimStatusId
) =>
	createApiAction<IEditWarrantyClaimStatusResponse>(request, success, failure, () =>
		makeWarrantyClaimStatusEditRequest(objectId, claimId, statusId)
	);
