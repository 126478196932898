import StatusesFilterPage from '../StatusesFilterPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractSpaceStatusesAsArrayByObjectId} from '@src/core/hooks/queries/spaceStatusesSets/hooks';
import {useTranslation} from 'react-i18next';

const SpacesStatusesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const {data: statuses} = useExtractSpaceStatusesAsArrayByObjectId(objectId);

	if (!statuses) return null;

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={statuses}
			label={t('spacesFiltersPage.spacesStatusesFilterMobile.baseListFilterMobile.label')}
			filterName="statuses"
		/>
	);
};

export default SpacesStatusesFilterPage;
