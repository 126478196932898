import {EntityGridItem} from '@tehzor/ui-components';
import {useTranslation} from "react-i18next";

interface SpaceOwnerProps {
	name?: string;
	phone?: string;
	email?: string;
	claimer?: string;
}

const SpaceOwners = ({name, phone, email, claimer}: SpaceOwnerProps) => {
	const {t} = useTranslation();

	return (
		<EntityGridItem
			label={t('warrantyClaimPage.info.entityGridItem.spaceOwners.label')}
			icon={<i className="tz-space-owner-20"/>}
			fullRow
		>
			<div className="warranty-claim-page__owner">
				{(claimer === undefined || claimer === '') && (
					<div className="warranty-claim-page__owner-name">{name}</div>
				)}
				{claimer !== undefined && (
					<div className="warranty-claim-page__owner-name">{claimer}</div>
				)}

				{phone !== undefined && (
					<div className="warranty-claim-page__owner-contact">
						<a href={`tel:${phone}`}>{phone}</a>
					</div>
				)}

				{email !== undefined && (
					<div className="warranty-claim-page__owner-contact">
						<a href={`mailto:${email}`}>{email}</a>
					</div>
				)}
			</div>
		</EntityGridItem>
	)
};
export default SpaceOwners;
