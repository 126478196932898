import {IconButton} from '@tehzor/ui-components';
import {useAddingInternalAcceptanceDialog} from '@src/components/AddingInternalAcceptanceDialog/hooks/useAddingInternalAcceptanceDialog';
import {useObject} from '@src/core/hooks/queries/objects/hooks';

interface IMobileEntityAddingProps {
	objectId: string;
}

const MobileEntityAdding = ({objectId}: IMobileEntityAddingProps) => {
	const {data: object} = useObject(objectId);

	const [dialog, open] = useAddingInternalAcceptanceDialog({
		objectId,
		stage: object?.stage
	});

	return (
		<>
			<IconButton onClick={open}>
				<i className="tz-plus-24" />
			</IconButton>
			{dialog}
		</>
	);
};

export default MobileEntityAdding;
