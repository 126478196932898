import {Suspense} from 'react';
import {usePermissions} from '../../utils/usePermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ProblemsExportDialog from '../ProblemsExportDialog';
import ProblemAddingDesktop from './ProblemAdding.desktop';
import {useCanProblemAdd} from '@src/pages/ProblemsPage/hooks/useCanProblemAdd';

interface IDesktopActionsProps {
	objectId?: string;
}

export const ActionsDesktop = ({objectId}: IDesktopActionsProps) => {
	const user = useAppSelector(s => s.auth.profile);
	const permissions = usePermissions(user, objectId);
	const canAdd = useCanProblemAdd({objectId});

	return (
		<>
			{canAdd && objectId && (
				<Suspense>
					<ProblemAddingDesktop objectId={objectId} />
				</Suspense>
			)}
			{permissions.canExport && <ProblemsExportDialog objectId={objectId} />}
		</>
	);
};
