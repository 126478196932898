import {ObjectsDisplayMode} from '@src/interfaces/ObjectsDisplayMode';
import * as types from '../constants';

export interface IChangeDisplayModePayload {
	displayMode: ObjectsDisplayMode;
}

export const changeDisplayMode = (displayMode: ObjectsDisplayMode) => ({
	type: types.CHANGE_DISPLAY_MODE,
	payload: {displayMode} as IChangeDisplayModePayload
});