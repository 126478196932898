import {IEditableCategory} from '@src/core/hooks/states/useCategoriesSetState';
import {TextField} from '@tehzor/ui-components';
import classNames from 'classnames';
import { memo, useCallback, useMemo } from 'react';

interface ICategoryNameProps {
	item: IEditableCategory;
	isEdited?: boolean;
	openCategoryDialog: (item: IEditableCategory) => void;
	onChangeEdit: (id: string, isEdited?: boolean) => void;
}

export const CategoryName = memo(
	({item, isEdited, openCategoryDialog, onChangeEdit}: ICategoryNameProps) => {
		const handleClick = useCallback(() => {
			openCategoryDialog(item);
			onChangeEdit(item.id, isEdited);
		}, [openCategoryDialog, item]);

		const classes = useMemo(
			() => ({
				root: 'editable-categories__item-name',
				wrap: classNames({'editable-categories__item-name__view-wrap': !isEdited}),
				element: 'editable-categories__item-name__element'
			}),
			[isEdited]
		);

		return (
			<TextField
				placeholder="Название вида работ"
				className={classes}
				value={item.name}
				onClick={handleClick}
				elementType="div"
			/>
		);
	}
);
