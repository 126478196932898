import './ProblemsStatsChart.less';
import {
	BlockPlaceholder,
	PieChartPlaceholder,
	ProblemsBarChart,
	ProblemsPieChart
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StatisticsChartType} from '@src/interfaces/StatisticsChartType';
import classNames from 'classnames';
import {determineCardsColumnsCount} from './utils/determineCardsColumnsCount';
import NoData from '@src/pages/StatisticsPage/components/NoData';
import {StatsByStatus} from '@src/utils/statsAggregators/problemsByStatuses';
import {extractFilteredProblemStatuses} from '@src/store/modules/settings/pages/statistics/selectors';
import {areProblemsByStatusesStatsLoading} from '@src/store/modules/statistics/ui/selectors/warranty';
import ProblemsStatsCards from '../ProblemsStatsCards';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {useProblemStatusesExpired} from '@src/core/hooks/queries/problemStatuses/hooks';

interface IProblemsStatsChartProps {
	stats?: StatsByStatus;
	totalCount?: number;
	level?: number;
	onClick?: (status?: string) => void;
}

const ProblemsStatsChart = ({stats, totalCount, level = 0, onClick}: IProblemsStatsChartProps) => {
	const {chartType, displayFilters} = useAppSelector(
		s => s.settings.pages.statistics.warranty.problems
	);
	const {data: expiredStatuses} = useProblemStatusesExpired();
	const statuses = useAppSelector(s =>
		extractFilteredProblemStatuses(s, displayFilters, expiredStatuses)
	);
	const loading = useAppSelector(areProblemsByStatusesStatsLoading);

	const cardsColumnsCount = determineCardsColumnsCount(chartType);
	const classes = classNames('problems-stats-chart', `problems-stats-chart_type_${chartType}`);

	if (loading) {
		return (
			<div className={classes}>
				{chartType === StatisticsChartType.BAR && (
					<BlockPlaceholder className="problems-stats-chart__bar-chart-placeholder" />
				)}

				{chartType === StatisticsChartType.PIE && (
					<PieChartPlaceholder
						className={classNames(
							'problems-stats-chart__pie-chart',
							`problems-stats-chart__pie-chart_level_${level}`
						)}
					/>
				)}

				<ProblemsStatsCards
					className="problems-stats-chart__cards"
					columnsCount={cardsColumnsCount}
					loading
				/>
			</div>
		);
	}
	if (stats && statuses) {
		return (
			<div className={classes}>
				{chartType === StatisticsChartType.BAR && (
					<ProblemsBarChart
						statuses={statuses.filter(status => status.id !== ProblemStatusId.EXPIRED)}
						counts={stats}
						totalCount={totalCount}
					/>
				)}

				{chartType === StatisticsChartType.PIE && (
					<ProblemsPieChart
						className={classNames(
							'problems-stats-chart__pie-chart',
							`problems-stats-chart__pie-chart_level_${level}`
						)}
						statuses={statuses.filter(status => status.id !== ProblemStatusId.EXPIRED)}
						counts={stats}
						totalCount={totalCount}
					/>
				)}

				<ProblemsStatsCards
					className="problems-stats-chart__cards"
					stats={stats}
					columnsCount={cardsColumnsCount}
					onClick={onClick}
				/>
			</div>
		);
	}
	return <NoData />;
};

export default ProblemsStatsChart;
