import {useMemo} from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useExtractSpaceStatusesDataByObjectId} from '../queries/spaceStatusesSets/hooks';

/**
 * Возвращает стадию помещения исходя из его статуса
 *
 * @param objectId id объекта
 * @param space помещение
 */
export function useSpaceStage(objectId: string, space?: ISpace) {
	const {data: statuses} = useExtractSpaceStatusesDataByObjectId(objectId);
	return useMemo(
		() => (space ? statuses?.byId[space.status]?.stage : ObjectStageIds.BUILDING),
		[space, statuses]
	);
}
