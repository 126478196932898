import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IWorkAcceptanceEntity[];
}

export const SelectedEntitiesDeletion = ({selectedEntities}: ISelectedEntitiesDeletionProps) => {
	const dispatch = useAppDispatch();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить нарушения',
		'Вы действительно хотите удалить выбранные нарушения?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			const objectsIds: string[] = [];
			for (const {type, data} of selectedEntities) {
				switch (type) {
					case 'problem':
						await dispatch(deleteProblem(data.objectId, data.id));
						objectsIds.push(data.objectId);
				}
			}
			if (objectsIds.length > 0) {
				await queryClient.refetchQueries({
					queryKey: [...objectsQueryKeys.stats(), objectsIds]
				});
			}
		}
	}, [selectedEntities]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete" />
			</IconButton>

			{deleteDialog}
		</>
	);
};
