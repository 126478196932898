import {DBSchema, openDB, IDBPDatabase} from 'idb';

const dbKey = 'tehzor' as const;
const storeKey = 'store' as const;

interface TehzorDB extends DBSchema {
	[storeKey]: {
		key: string;
		value: string;
	};
}

export class IDBStorage {
	private db: Promise<IDBPDatabase<TehzorDB>>;

	constructor() {
		this.db = openDB<TehzorDB>(dbKey, 10, {
			upgrade(db) {
				if (!db.objectStoreNames.contains(storeKey)) {
					db.createObjectStore(storeKey);
				}
			}
		});
	}

	/**
	 * Возвращает значение по ключу из локального хранилища
	 *
	 * @param key ключ
	 */
	get = async (key: string): Promise<string | null> => {
		const result = await (await this.db).get(storeKey, key);
		return result ?? null;
	};

	/**
	 * Сохраняет значение в локальном хранилище
	 *
	 * @param key ключ
	 * @param value значение
	 */
	set = async (key: string, value: string): Promise<void> => {
		await (await this.db).put(storeKey, value, key);
	};

	/**
	 * Удаляет из локального хранилища
	 */
	delete = async (key: string): Promise<void> => (await this.db).delete(storeKey, key);
}
