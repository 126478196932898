import {IOwnerAcceptanceStatus} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetOwnerAcceptanceStatusesResponse = INormalizedData<IOwnerAcceptanceStatus>;

/**
 * Возвращает список статусов приёмок собственниками
 */
export const requestOwnerAcceptanceStatuses = async () => {
	const response = await httpRequests.withToken.get<IGetOwnerAcceptanceStatusesResponse>(
		'owner-acceptances/statuses'
	);
	return response.data;
};
