import {Column} from 'react-table';
import {MenuCell} from './MenuCell';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {ResponsibleUsersCell} from './ResponsibleUsersCell';
import {TaskStatusChangeableCell} from './TaskStatusChangeableCell';
import {PriorityCell} from './PriorityCell';
import {DeadLineCell} from './DeadLineCell';
import {TaskDescriptionCell} from './TaskDescriptionCell';
import LocationCell from './LocationCell';
import {TFunction} from 'i18next';

export const getColumns = (tFunc: TFunction): Array<Column<IEnrichedTask>> => [
		{
			id: 'name',
			Header: tFunc('tasksTablePage.columns.taskDescription.header'),
			Cell: TaskDescriptionCell,
			accessor: item => item,
			width: 150,
			minWidth: 150,
			sortDescFirst: true
		},
		{
			id: 'objectId',
			Header: tFunc('tasksTablePage.columns.location.header'),
			Cell: LocationCell,
			accessor: item => item,
			width: 120,
			minWidth: 80,
			disableSortBy: true
		},
		{
			id: 'status',
			Header: tFunc('tasksTablePage.columns.taskStatusChangeable.header'),
			Cell: TaskStatusChangeableCell,
			accessor: item => item,
			width: 110,
			disableSortBy: true
		},
		{
			id: 'priority',
			Header: tFunc('tasksTablePage.columns.priority.header'),
			Cell: PriorityCell,
			accessor: item => item,
			width: 110,
			minWidth: 30,
			disableSortBy: true
		},
		{
			id: 'taskIntervalStart',
			Header: tFunc('tasksTablePage.columns.deadLine.header'),
			Cell: DeadLineCell,
			accessor: (item: IEnrichedTask) => item,
			width: 80,
			minWidth: 120,
			disableSortBy: true
		},
		{
			id: 'respUsers',
			Header: tFunc('tasksTablePage.columns.responsibleUsers.header'),
			Cell: ResponsibleUsersCell,
			accessor: (item: IEnrichedTask) => item,
			width: 80,
			minWidth: 80,
			disableSortBy: true
		},
		{
			id: 'createdAt',
			accessor: 'createdAt',
			Header: tFunc('tasksTablePage.columns.createdAt.header'),
			width: 100,
			disableSortBy: false
		},
		{
			id: 'modifiedAt',
			Header: tFunc('tasksTablePage.columns.modifiedAt.header'),
			accessor: 'modifiedAt',
			width: 100,
			sortDescFirst: true
		},
		{
			id: '_menu',
			Cell: MenuCell,
			className: 'tasks-page__d-table-menu',
			width: 120,
			defaultCanSort: false,
			disableResizing: true
		}
	];
