import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {useInternalAcceptanceDeleteConfirmDialog} from '../../hooks/useInternalAcceptanceDeleteConfirmDialog';
import {useTranslation} from 'react-i18next';

interface IDesktopMenuProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
	canDeleteInternalAcceptance?: boolean;
}

const DesktopMenu = ({
	objectId,
	internalAcceptance,
	canDeleteInternalAcceptance
}: IDesktopMenuProps) => {
	const [deleteDialog, handleDeleteClick] = useInternalAcceptanceDeleteConfirmDialog(
		objectId,
		internalAcceptance
	);

	const {t} = useTranslation();

	const items = [];
	if (canDeleteInternalAcceptance) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={handleDeleteClick}
			>
				{t('useConfirmDialog.internalAcceptance.deleteTitle')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDeleteInternalAcceptance && deleteDialog}
		</>
	) : null;
};

export default DesktopMenu;
