import {useEffect, useMemo} from 'react';
import './StatisticsPage.less';
import classNames from 'classnames';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {changeSection} from '@src/store/modules/settings/pages/statistics/actions';
import {Navigate, Outlet, useLocation, useResolvedPath} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import {useTranslation} from 'react-i18next';

const StatisticsPage = () => {
	const url = useResolvedPath('').pathname;
	const location = useLocation();
	const path = location.pathname;
	const {t} = useTranslation();

	const sections = [
		{id: "Building", path: '/building', label: t('statisticsPage.sections.building')},
		{id: "Acceptance", path: '/acceptance', label: t('statisticsPage.sections.acceptance')},
		{id: "Warranty", path: '/warranty', label: t('statisticsPage.sections.warranty')}
	];

	const sectionsMenu = useMemo(
		() =>
			sections.map(item => ({
				id: item.id,
				url: url + item.path,
				label: item.label,
				serveSubPath: true
			})),
		[url]
	);
	useAppHeader({
		title: t('statisticsPage.title'),
		sectionsMenu,
		mobileRightButtons: <AppUpdateMenu/>
	});

	const section = useAppSelector(s => s.settings.pages.statistics.section);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (url !== path) {
			const currentPath = location.pathname.replace(url, '');
			const currentSection = sections.find(
				item => currentPath === item.path || currentPath.includes(item.path)
			);
			if (currentSection && currentSection.path !== section) {
				dispatch(changeSection(currentSection.path));
			}
		}
	}, [location.pathname, path, section, url]);

	return (
		<div
			className={classNames(
				'page-cont',
				'statistics-page',
				{'page-cont_extra-top-margin': sectionsMenu.length},
				'page-cont_extra-bottom-margin'
			)}
		>
			{path === '/statistics' && <Navigate to={path + (section || sections[0].path)} />}
			<Outlet />
		</div>
	);
};

export default StatisticsPage;
