import {ISpaceStatusesSetsSortState} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/reducers';
import {IFullSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/IFullSpaceStatusesSet';
import {orderBy} from 'lodash';

export const handleSort = (
	array: IFullSpaceStatusesSet[], sort?: ISpaceStatusesSetsSortState
) => {
	if (!sort) {
		return array;
	}

	const sortKeys = Object.keys(sort || {}) as Array<keyof ISpaceStatusesSetsSortState>;
	const sortItem = sort[sortKeys[0]];
	const order: Array<'asc' | 'desc'> = sortItem ? ['asc'] : ['desc'];

	const orderedArr = orderBy(array, sortKeys, order);

	return orderedArr;
};