import { createContext } from 'react';
import * as React from 'react';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';

export interface AppHeaderCtxOptions {
	title?: string;
	subTitle?: string;
	showBackBtn?: boolean;
	sectionsMenu?: IHeaderSectionsMenuItem[];
	mobileLeftButton?: React.ReactNode;
	mobileRightButtons?: React.ReactNode;
	titleButtons?: React.ReactNode;
}

export type AppHeaderCtxType = (options?: AppHeaderCtxOptions) => void;

export const AppHeaderCtx = createContext<AppHeaderCtxType>(() => null);
