import {useProblemSettings} from '@src/core/hooks/settings/useProblemSettings';
import {
	IEditableProblemState,
	convertToSave,
	errorsFns,
	hasAttachmentsError,
	isEdited,
	useEditableProblemState
} from '@src/core/hooks/states/useEditableProblemState';
import {
	convertToLocalSave as convertFilesToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	someFilesHaveSizeError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {testProblemFieldsRestrictions} from '@src/utils/testRestrictions';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import {useUpdateEffect} from 'react-use';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import EditableProblem from '../EditableProblem';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useIsFetching} from '@tanstack/react-query';

/**
 * Логика редактирования и сохранения нарушения (нового или существующего)
 */
export const useEditableProblem = (
	objectId: string,
	stage: ObjectStageIds,
	scope: string | undefined,
	defaultData: ISavingProblem | undefined,
	saving: boolean,
	creating?: boolean,
	isCopying?: boolean,
	createdBy?: IBriefUser,
	performers?: string[],
	performersActiveGroupLeader?: string,
	inspectors?: string[],
	inspectorsActiveGroupLeader?: string,
	watchers?: string[],
	links?: IProblem['links'],
	isLoading?: boolean
): [
	React.ReactNode,
	(useLocalFiles?: boolean) => Promise<(ISavingProblem & {templateId?: string}) | undefined>,
	// eslint-disable-next-line function-paren-newline
	() => void,
	boolean
] => {
	const fieldsSettings = useProblemSettings(objectId, stage);
	const {builtin, custom} = fieldsSettings;
	const user = useAppSelector(s => s.auth.profile);
	const [editingState, editingDispatch] = useEditableProblemState(defaultData);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);
	const {data: workAcceptance} = useWorkAcceptance(links?.workAcceptanceId, objectId);

	let entityLocation: ILocation | undefined;
	let entityPlanId: string | undefined;

	if (links?.structureId && links?.workAcceptanceId) {
		const selectedStructure = workAcceptance?.structures?.find(
			str => str.id === links?.structureId
		);
		entityLocation = selectedStructure?.location;
		entityPlanId = selectedStructure?.planId;
	}

	if (links?.spaceId && links?.workAcceptanceId) {
		const selectedSpaces = workAcceptance?.spaces?.find(space => space.id === links?.spaceId);
		entityLocation = selectedSpaces?.location;
		entityPlanId = selectedSpaces?.planId;
	}

	useNavigationPrevent(isBlocking);

	// это очень важный костыль, при поочередном редактировании нескольких нарушений дежит editingState в соответствии
	// с defaultData, если убрать то editingState будет отставать на 1 шаг от defaultData
	useUpdateEffect(() => {
		editingDispatch({
			type: 'reset',
			entity: defaultData
		});
	}, [defaultData]);

	useEffect(() => {
		setIsBlocking(
			isEdited(editingState, defaultData) || isFilesExist(uploadingFilesState.value)
		);
	}, [editingState, uploadingFilesState.value, defaultData]);

	const getSavingData = useCallback(
		async (useLocalFiles?: boolean) => {
			const files = await waitUploading();
			// Так как в editingState в attachments нет новых данных пока они не будут загружены на сервер,
			// добавляем данные из uploadingFilesState
			const editingStateWithUploadingFiles: IEditableProblemState = {
				...editingState,
				attachments: [
					...editingState.attachments,
					...uploadingFilesState.value.map(item => ({id: item.key}))
				]
			};
			// Проверка наличия ошибок в состояниях
			if (
				hasErrors(editingStateWithUploadingFiles, errorsFns, builtin) ||
				hasErrors(editingStateWithUploadingFiles, errorsFns, custom, true) ||
				(hasAttachmentsError(editingState, builtin) && !isFilesExist(files))
			) {
				editingDispatch({type: 'update-errors'});
				uploadingFilesDispatch({type: 'update-error'});
				return undefined;
			}
			// Проверка, есть ли файлы с превышением размера.
			// не даст сохранить нарушение в автономном режиме
			if (someFilesHaveSizeError(files)) {
				return undefined;
			}
			// Проверка, были ли отредактированы поля
			if (
				!isEdited(editingState, defaultData, true) &&
				!isCopying &&
				(!isFilesExist(files) || someFilesHaveError(files))
			) {
				return undefined;
			}
			const savingData = convertToSave(editingState, defaultData, !creating);

			return {
				...savingData,
				newAttachments: useLocalFiles
					? convertFilesToLocalSave(files)
					: convertFilesToSave(files),
				templateId: editingState.templateId
			};
		},
		[
			waitUploading,
			editingState,
			uploadingFilesState.value,
			builtin,
			custom,
			defaultData,
			isCopying,
			creating,
			editingDispatch,
			uploadingFilesDispatch
		]
	);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: defaultData
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [defaultData, editingDispatch, uploadingFilesDispatch]);

	const fieldsAvailability =
		user.roles &&
		testProblemFieldsRestrictions(
			user.id,
			user.roles,
			'problemsEdit',
			objectId,
			createdBy?.id,
			performers,
			performersActiveGroupLeader,
			inspectors,
			inspectorsActiveGroupLeader,
			watchers,
			saving,
			creating
		);

	// TO-DO: Избавиться от этого безобразия, после переработки форм
	const isAllLoading = !!useIsFetching({});

	const fields = (
		<EditableProblem
			objectId={objectId}
			stage={stage}
			scope={scope}
			createdBy={createdBy?.id}
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving || isAllLoading}
			fieldsAvailability={fieldsAvailability}
			entityLocation={entityLocation}
			entityPlanId={entityPlanId}
			isLoading={isLoading}
			exceptions={editingState.exceptions}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
