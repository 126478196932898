import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';

export type IEditableRespUserState = IEditableEntityState<{
	fullName?: string;
	email?: string;
	area?: string;
	categories?: string[];
	planId?: string;
	stage?: ObjectStageIds;
	location?: ILocation;
}>;

export type IEditableRespUserAction = IEditableEntityAction<
	IEditableRespUserState,
	IAddingRespUser
>;

const makeEmptyState = (): IEditableRespUserState => ({
	fullName: undefined,
	email: undefined,
	area: undefined,
	stage: undefined,
	categories: undefined,
	planId: undefined,
	location: undefined,
	errors: {
		fullName: false,
		email: false,
		area: false,
		categories: false,
		planId: false,
		stage: false,
		location: false
	}
});

export const init = (original?: IAddingRespUser): IEditableRespUserState => {
	const empty = makeEmptyState();
	return original
		? {
				fullName: original.fullName,
				email: original.email,
				area: original.area,
				categories: original.categories,
				planId: original.planId,
				stage: original.stage,
				location: original.location,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (state: IEditableRespUserState, original?: IAddingRespUser): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'fullName'),
		isPropEdited.bind(null, 'email'),
		isPropEdited.bind(null, 'area'),
		isPropEdited.bind(null, 'categories'),
		isPropEdited.bind(null, 'planId'),
		isPropEdited.bind(null, 'stage'),
		isPropEdited.bind(null, 'location')
	);

export const errorsFns = {
	fullName: (state: IEditableRespUserState) => !state.fullName,
	email: (state: IEditableRespUserState) => !state.email,
	area: (state: IEditableRespUserState) => !state.area,
	categories: (state: IEditableRespUserState) => !state.categories,
	planId: (state: IEditableRespUserState) => !state.planId,
	stage: (state: IEditableRespUserState) => !state.stage,
	location: (state: IEditableRespUserState) => !state.location
};

export const convertRespUserToSave = (
	state: IEditableRespUserState,
	original?: IAddingRespUser,
	onlyEdited?: boolean
): IAddingRespUser => {
	if (!onlyEdited) {
		return state;
	}

	const problemRespUser: IAddingRespUser = {};

	if (isPropEdited('fullName', state, original)) {
		problemRespUser.fullName = state.fullName;
	}

	if (isPropEdited('email', state, original)) {
		problemRespUser.email = state.email;
	}

	if (isPropEdited('area', state, original)) {
		problemRespUser.area = state.area;
	}

	if (isPropEdited('categories', state, original)) {
		problemRespUser.categories = state.categories;
	}

	if (isPropEdited('planId', state, original)) {
		problemRespUser.planId = state.planId;
	}
	if (isPropEdited('location', state, original)) {
		problemRespUser.location = state.location;
	}
	if (isPropEdited('stage', state, original)) {
		problemRespUser.stage = state.stage;
	}

	return problemRespUser;
};
