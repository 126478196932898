import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {useProblemStatusesAsArray} from '@src/core/hooks/queries/problemStatuses/hooks';

export const useTranslatedProblemStatuses = () => {
	const {t} = useTranslation();
	const {data: problemStatuses} = useProblemStatusesAsArray();

	return useMemo<IProblemStatus[]>(
		() =>
			problemStatuses
				? problemStatuses.map(status => ({
						...status,
						name: t(`problemStatuses.${status.id}`)
				  }))
				: [],
		[problemStatuses, t]
	);
};
