import { useCallback, useState } from 'react';
import {FloatingActionButton, IconButton} from '@tehzor/ui-components';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useStructureSelectDialog} from '../StructureSelectDialog/useStructureSelectDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useSpaceSelectDialog} from '../SpaceSelectDialog/useSpaceSelectDialog';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';

interface IEntityAddingProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
}

const mobileAddIcon = <i className="tz-plus-16" />;
const desktopAddIcon = <i className="tz-plus-20" />;

export const EntityAdding = ({objectId, workAcceptance}: IEntityAddingProps) => {
	const [links, setLinks] = useState<IProblem['links'] | IInspection['links']>({
		workAcceptanceId: workAcceptance.id
	});

	const checksPermissions = useChecksPermissions(objectId, 'work-acceptance');

	const permissions = {
		checks: checksPermissions.canAdd
	};
	const [dialog, openDialog] = useGlobalAddingEntityDialog(
		objectId,
		undefined,
		links,
		ObjectStageIds.BUILDING,
		permissions,
		undefined
	);

	const onSuccess = ({structureId, spaceId}: {structureId?: string; spaceId?: string}) => {
		setLinks({...links, structureId, spaceId});
		openDialog();
	};

	const structures = workAcceptance.structures ?? [];
	const [structureSelectDialog, openStructureSelectDialog] = useStructureSelectDialog(
		structures,
		onSuccess,
		dialog ?? null
	);
	const spaces = workAcceptance.spaces ?? [];
	const [spaceSelectDialog, openSpaceSelectDialog] = useSpaceSelectDialog(
		spaces,
		onSuccess,
		dialog ?? null
	);

	const noSelectStructure = useCallback(() => {
		onSuccess(structures.length ? {structureId: structures[0].id} : {});
	}, [structures]);

	const noSelectSpace = useCallback(() => {
		onSuccess(spaces.length ? {spaceId: spaces[0].id} : {});
	}, [spaces]);

	const onClick = () => {
		if (workAcceptance.frontType === WorkAcceptanceFrontTypeId.STRUCTURES) {
			if (structures.length > 1) {
				void openStructureSelectDialog();
			} else {
				noSelectStructure();
			}
		}
		if (workAcceptance.frontType === WorkAcceptanceFrontTypeId.SPACES) {
			if (spaces.length > 1) {
				void openSpaceSelectDialog();
			} else {
				noSelectSpace();
			}
		}
	};

	return permissions.checks ? (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={mobileAddIcon}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={onClick}
				/>
			</BeforeDesktop>
			<Desktop>
				<IconButton
					type="accent-blue"
					onClick={onClick}
				>
					{desktopAddIcon}
				</IconButton>
			</Desktop>
			{structureSelectDialog}
			{spaceSelectDialog}
		</>
	) : null;
};
