import {Dispatch, useCallback} from 'react';
import './EditableMeter.less';
import Text from '../editableFields/Text';
import {
	IEditableMeterAction,
	IEditableMeterState
} from '@src/core/hooks/states/useEditableMeterState';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {MeterTypeSelect} from '../editableFields/MeterTypeSelect';
import {MeterAddingMeterTariffSelect} from '../editableFields/MeterAddingMeterTariffSelect';
import {MeterAddingMeterValueInput} from '../editableFields/MeterAddingMeterValueInput';
import {EditableFieldLabel, InlineButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useExtractMeterTypesById} from '@src/core/hooks/queries/meterTypes/hooks';
import {useTranslation} from "react-i18next";

const plusIcon = <i className="tz-plus-20" />;
const deleteIcon = <i className="tz-delete" />;

interface IEditableMeterProps {
	editingState: IEditableMeterState;
	editingDispatch: Dispatch<IEditableMeterAction>;
	saving: boolean;
	fieldsSettings: Record<string, IObjectFieldSetting>;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableMeter = (props: IEditableMeterProps) => {
	const {t} = useTranslation();
	const {editingState, editingDispatch, saving, fieldsSettings} = props;
	const meterConsumptions = editingState.meterConsumptions;

	const addMeterConsumption = useCallback(() => {
		editingDispatch({
			type: 'update',
			field: 'meterConsumptions',
			value: meterConsumptions
				? [...meterConsumptions, {tariffId: undefined, value: undefined}]
				: [{tariffId: undefined, value: undefined}]
		});
	}, [editingDispatch, meterConsumptions]);

	const deleteMeterConsumption = useCallback(
		(index: number) => {
			editingDispatch({
				type: 'update',
				field: 'meterConsumptions',
				value: meterConsumptions?.filter((mc, i) => i !== index)
			});
		},
		[editingDispatch, meterConsumptions]
	);

	const roles = useAppSelector(extractUserRoles);
	const canDelete = hasPermission(roles, 'meter-consumptions-delete');

	const {data: meterTypeMap} = useExtractMeterTypesById();

	return (
		<div className="editable-meter">
			<div className="editable-meter__info-grid">
				<MeterTypeSelect
					value={editingState.typeId}
					editingDispatch={editingDispatch}
					required={fieldsSettings.typeId.isRequired}
					disabled={saving}
					hasError={editingState.errors.typeId}
					className="editable-meter__field"
				/>
				<Text
					field="serialNumber"
					label={t('components.editableMeter.serialNumberFiled.label')}
					value={editingState.serialNumber}
					editingDispatch={editingDispatch}
					disabled={saving}
					required={fieldsSettings.serialNumber.isRequired}
					hasError={editingState.errors.serialNumber}
					className="editable-meter__field"
					errorMessage={t('components.editableMeter.serialNumberFiled.error')}
				/>
			</div>
			<Text
				field="description"
				label={t('components.editableMeter.descriptionFiled.label')}
				value={editingState.description}
				editingDispatch={editingDispatch}
				disabled={saving}
				required={fieldsSettings.description.isRequired}
				hasError={editingState.errors.description}
				className="editable-meter__field"
				errorMessage={t('components.editableMeter.descriptionFiled.error')}
			/>

			<EditableFieldLabel className="editable-meter_label">{t('components.editableMeter.editableFieldLabel')}</EditableFieldLabel>
			<div>
				{meterConsumptions &&
					meterConsumptions.map((mc, index) => (
						<div
							className="editable-meter__consumptions-grid"
							key={index}
						>
							<MeterAddingMeterValueInput
								meterConsumptions={meterConsumptions}
								index={index}
								editingDispatch={editingDispatch}
								disabled={saving}
								className="editable-meter-consumption__field"
								measureUnit={
									meterTypeMap && editingState.typeId
										? meterTypeMap[editingState.typeId]?.measureUnit
										: undefined
								}
							/>
							<MeterAddingMeterTariffSelect
								meterConsumptions={meterConsumptions}
								index={index}
								editingDispatch={editingDispatch}
								disabled={saving}
								className="editable-meter-consumption__field"
							/>
							{canDelete && (
								<InlineButton
									label={t('components.editableMeter.button.delete.label')}
									className="editable-meter-dialog__delete-button"
									onClick={() => deleteMeterConsumption(index)}
									type="cancel"
									leftIcon={deleteIcon}
									disabled={saving}
								/>
							)}
						</div>
					))}
			</div>
			<InlineButton
				type="accent"
				leftIcon={plusIcon}
				label={t('components.editableMeter.button.add.label')}
				disabled={saving}
				onClick={addMeterConsumption}
			/>
		</div>
	);
};
