import {localAuthStorage} from '@tehzor/tools/api/localAuthStorage';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import config from '@src/core/config';

const {appId} = config;

/**
 * Сбрасывает аутентификацию
 */
export async function logout(deviceId?: string) {
	try {
		await httpRequests.withToken.post('/auth/logout', {deviceId, appId});
	} finally {
		await localAuthStorage.clear();
	}
}
