import {useMemo} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useExtractCheckListsAsArray} from '@src/core/hooks/queries/checkLists/hooks';
import {useSpace} from '../queries/space';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';

/**
 * Возвращает доступные чек-листы для определённых условий (объект, помещение, стадия)
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param stage стадия
 * @param type тип чеклиста
 * @param structureTypes типы структур
 * @param categoryId id вида работ
 * @param spaceTypes типы помещений
 */
export function useAvailableCheckLists(
	objectId: string,
	spaceId: string | undefined,
	stage: ObjectStageIds | 'all' = 'all',
	type: CheckListTypeId = CheckListTypeId.OPERATIONS,
	structureTypes?: string[],
	categoryId?: string,
	spaceTypes?: string[]
) {
	const {data: object} = useObject(objectId);
	const {data: space} = useSpace(spaceId, objectId);
	const {data: lists} = useExtractCheckListsAsArray();

	return useMemo(() => {
		if (!object || !lists) {
			return [];
		}

		return lists.filter(checklist => {
			// У чек-листа должна совпадать компания
			if (object.companyId !== checklist.companyId) {
				return false;
			}
			// Должны совпадать объекты (если указаны)
			if (checklist.objects?.length && !checklist.objects.includes(object.id)) {
				return false;
			}
			// Должна совпадать выбранная стадия
			if (stage !== 'all' && checklist.stage !== stage) {
				return false;
			}

			// Проверка дополнительных условий для чек-листов помещений
			if (
				type === CheckListTypeId.OPERATIONS &&
				(checklist.type === CheckListTypeId.OPERATIONS ||
					checklist.frontType === WorkAcceptanceFrontTypeId.SPACES)
			) {
				if (!space) {
					return false;
				}
				// Должен совпадать тип помещения (если указан)
				if (checklist.spaceTypes?.length && !checklist.spaceTypes.includes(space.type)) {
					return false;
				}
				// Если указан тип отделки у помещения
				if (space.typeDecoration) {
					return (
						// Возвращаем чек-листы без типов отделки
						!checklist.typeDecoration?.length ||
						// и чек-листы с типом отделки помещения
						checklist.typeDecoration.includes(space.typeDecoration)
					);
				}
				// Если не указан тип отделки у помещения, то у чек-листа также не должно быть типа отделки
				return !checklist.typeDecoration?.length;
			}

			if (
				type === CheckListTypeId.ACCEPTANCES &&
				checklist.type === CheckListTypeId.ACCEPTANCES
			) {
				if (
					categoryId &&
					!(checklist.categories?.length && checklist.categories.includes(categoryId))
				) {
					return false;
				}
				if (checklist.frontType === WorkAcceptanceFrontTypeId.STRUCTURES) {
					return (
						checklist.structureType && structureTypes?.includes(checklist.structureType)
					);
				}
				if (checklist.frontType === WorkAcceptanceFrontTypeId.SPACES) {
					return checklist.spaceTypes?.some(spaceType => spaceTypes?.includes(spaceType));
				}
			}
			return false;
		});
	}, [object, space, lists, stage, structureTypes, categoryId, type, spaceTypes]);
}
