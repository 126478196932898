import {logout} from '@src/store/modules/auth/actions';
import useAsync from 'react-use/lib/useAsync';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';

/**
 * Страница нужна для того, чтобы отрабатывали хуки жизненного цикла предыдущей страницы
 */
export const LogoutPage = () => {
	const {replacePath} = useChangePath();
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const dispatch = useAppDispatch();
	useAsync(async () => {
		if (isAuthenticated) {
			await dispatch(logout());
		}
		replacePath('/login');
	}, [isAuthenticated]);

	return null;
};
