// import {useLockBodyScroll} from 'react-use';
import FocusLock, {AutoFocusInside} from 'react-focus-lock';
import {Portal} from '../containers';
import Overlay from '../containers/Overlay';
import {AnimatePresence, motion} from 'framer-motion';
import {useIsBeforeDesktop, useIsBeforeTablet} from '../../utils/mediaQueries';
import {makeAnimations} from '../dialogs/Dialog/utils/makeAnimations';
import {CSSProperties, KeyboardEvent, ReactNode} from 'react';
import classNames from 'classnames';
import cls from './Modal.module.less';

export interface ModalProps {
	className?: string;
	contentClassName?: string;
	open?: boolean;
	onClose?: () => void;
	style?: CSSProperties;
	// disableBodyScrollLock?: boolean;
	focusTrap?: boolean;
	autoFocus?: boolean;
	children?: ReactNode;
	container?: HTMLElement;
	disableEscapeKeyDown?: boolean;
	useContentOpenAnimation?: boolean;
	useContentCloseAnimation?: boolean;
	fullScreen?: boolean;
	fullScreenOnMobile?: boolean;
	onAfterClose?: () => void;
	handleEscapeKeyDown?: () => void;
	fullScreenOnTablet?: boolean;
	qa?: string;
}

export function Modal({
	className,
	container,
	// disableBodyScrollLock,
	open,
	style,
	children,
	fullScreen,
	fullScreenOnMobile,
	disableEscapeKeyDown,
	handleEscapeKeyDown,
	fullScreenOnTablet,
	onClose,
	useContentCloseAnimation,
	useContentOpenAnimation,
	contentClassName,
	onAfterClose,
	focusTrap,
	qa,
	autoFocus
}: ModalProps) {
	/* TODO: удален хук useLockBodyScroll из-за возникновения бага в мобильном Safari (TZ-3939)
	при обнаружении других багов со скроллом в модальных окнах, возможно потребуется вернуть данных хук:
	useLockBodyScroll(!disableBodyScrollLock && open); */

	const isMobile = useIsBeforeTablet();
	const isTablet = useIsBeforeDesktop();
	const animations = makeAnimations(
		useContentOpenAnimation,
		useContentCloseAnimation,
		fullScreen || (fullScreenOnMobile && isMobile) || (fullScreenOnTablet && isTablet)
	);

	const onEscapeKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (!disableEscapeKeyDown && e.key === 'Escape') {
			handleEscapeKeyDown?.();
			onClose?.();
		}
	};

	const mods = classNames(
		cls.modal,
		{
			[cls.modalFs]: fullScreen,
			[cls.modalFsMobile]: fullScreenOnMobile && isMobile,
			[cls.modalFsTablet]: fullScreenOnTablet && isTablet
		},
		className
	);

	const handleAfterClose = () => {
		if (!open) {
			onAfterClose?.();
		}
	};

	return (
		<Portal root={container}>
			<AnimatePresence
				initial={false}
				onExitComplete={handleAfterClose}
			>
				{open && (
					<Overlay
						className={classNames({
							[cls.modalOverlayTransparent]:
								fullScreen ||
								(fullScreenOnMobile && isMobile) ||
								(fullScreenOnTablet && isTablet)
						})}
						variants={animations?.overlay}
						onClick={onClose}
					>
						<FocusLock disabled={!focusTrap}>
							<div
								className={mods}
								onKeyDown={onEscapeKeyDown}
							>
								<motion.div
									className={cls.modalLayer}
									variants={animations?.dialog}
								>
									<AutoFocusInside disabled={!autoFocus}>
										<motion.div
											data-testid={qa}
											className={classNames(
												cls.modalContent,
												contentClassName
											)}
											style={style}
											variants={animations?.content}
										>
											{children}
										</motion.div>
									</AutoFocusInside>
								</motion.div>
							</div>
						</FocusLock>
					</Overlay>
				)}
			</AnimatePresence>
		</Portal>
	);
}
