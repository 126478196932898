import {
	IConvertedCheckItemData,
	IConvertedCheckListData
} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractCheckRecordByListId,
	extractCheckRecordsAsArrayByListId
} from '@src/store/modules/pages/checkLists/selectors/records';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {useMemo} from 'react';
import {getLatestModified} from '@src/utils/selectorUtils';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractRootCheckItems} from '@src/core/hooks/queries/checkItems/hooks';
import {useExtractCheckRecordStatusesById} from '@src/core/hooks/queries/checkRecordStatuses/hooks';

export const useDiagramData = (listId: string): IConvertedCheckListData[] | null => {
	// все статусы
	const {data: statusesMap} = useExtractCheckRecordStatusesById();
	// все записи по данному чеклисту
	const records = useAppSelector(s => extractCheckRecordsAsArrayByListId(s, listId));
	// все категории чеклиста
	const {data: items} = useExtractRootCheckItems(listId);

	const {data: checkList} = useExtractCheckListById(listId);
	const listRecord = useAppSelector(s => extractCheckRecordByListId(s, listId));
	const listStatus = statusesMap?.[listRecord?.status || CheckRecordStatusId.NOT_CHECKED];

	return useMemo(() => {
		if (!listRecord || !listStatus) return null;

		const processedItems = items
			? items.reduce<IConvertedCheckItemData[]>((acc, item) => {
					if (item.parentId) {
						return acc;
					}

					const record = getLatestModified<ICheckRecord>(
						records.filter(el => el.links.checkItemId === item.id)
					);
					const status = statusesMap?.[record?.status || CheckRecordStatusId.NOT_CHECKED];

					acc.push({
						id: item.id,
						name: item.name,
						status: status?.id,
						statusName: status?.name,
						color: status?.color
					});

					return acc;
			  }, [])
			: [];
		return [
			{
				id: listId,
				name: checkList?.name ?? '',
				value: items ? items.length : 0,
				status: listStatus?.id,
				statusName: listStatus?.name,
				color: listStatus?.color,
				items: processedItems
			}
		];
	}, [listRecord, records, items, statusesMap]);
};
