import {useCallback, useRef, useState} from 'react';
import './AddingInternalAcceptanceDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {IAddingProblemRefProps} from '../AddingProblem';
import {IAddingInspectionRefProps} from '../AddingInspection';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {useEntityType} from './hooks/useEntityType';
import {useAvailableTypes} from './hooks/useAvailableTypes';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {useInternalAcceptanceMaps} from './hooks/useInternalAcceptanceMaps';
import {saveInternalAcceptance} from './utils/saveInternalAcceptance';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {useAddInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useAddInternalAcceptance';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useLocalInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/hooks';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {useQueryClient} from '@tanstack/react-query';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';

interface IAddingInternalAcceptanceDialogProps {
	objectId: string;
	internalAcceptanceId?: string;
	links?: IInternalAcceptance['links'];
	types?: IInternalAcceptanceAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	isOpen: boolean;
	problemToCopyId?: string;
	createdBy?: string;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const addingInternalAcceptanceDialogClassNames = {
	root: 'adding-internal-acceptance-dialog',
	header: 'adding-internal-acceptance-dialog__header',
	body: 'adding-internal-acceptance-dialog__body'
};

const AddingInternalAcceptanceDialog = (props: IAddingInternalAcceptanceDialogProps) => {
	const {
		objectId,
		internalAcceptanceId,
		links,
		types,
		isOpen,
		problemToCopyId,
		createdBy,
		onSuccess,
		defaultProblemData,
		defaultInspectionData,
		onClose
	} = props;
	const {t} = useTranslation();
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const {data: object} = useObject(objectId);
	const [selectedInternalAcceptanceId, setSelectedInternalAcceptanceId] = useState<
		string | undefined
	>(internalAcceptanceId);

	const addInternalAcceptance = useAddInternalAcceptance(object);
	const {data: localInternalAcceptances} = useLocalInternalAcceptances();

	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingInspectionRefProps>(null);

	const availableTypes = useAvailableTypes(objectId, types, {createdBy});
	const type = useEntityType(objectId, availableTypes);

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);
	const queryClient = useQueryClient();

	const stage = ObjectStageIds.ACCEPTANCE;
	const stages = useTranslatedObjectStagesArray();
	const [contentMap, entitiesSelectMapProps] = useInternalAcceptanceMaps({
		objectId,
		internalAcceptanceId,
		stage,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedInternalAcceptanceId,
		setSelectedInternalAcceptanceId,
		defaultProblemData,
		defaultInspectionData,
		onClose
	});

	const handleSave = useCallback(async () => {
		const localInternalAcceptancesIds = localInternalAcceptances?.map(acpt => acpt.id) || [];
		const addNewInternalAcceptance = () =>
			addInternalAcceptance({
				objectId,
				links,
				stage
			});
		const updateLatest = async () => {
			if (
				selectedInternalAcceptanceId &&
				!localInternalAcceptancesIds.includes(selectedInternalAcceptanceId)
			) {
				await queryClient.invalidateQueries({
					queryKey: [
						...internalAcceptancesQueryKeys.detail(selectedInternalAcceptanceId),
						objectId
					]
				});
			}
		};
		await saveInternalAcceptance({
			addingEntityRef,
			selectedInternalAcceptanceId,
			localInternalAcceptancesIds,
			online,
			updateLatest,
			addNewInternalAcceptance,
			setSaving,
			success,
			onClose
		});
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [
		addInternalAcceptance,
		links,
		localInternalAcceptances,
		objectId,
		onClose,
		onSuccess,
		online,
		queryClient,
		selectedInternalAcceptanceId,
		stage
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const getEntitySelectContainerSubTitle = useCallback(
		(objectStageName: string): string =>
			t('globalAddingEntityDialog.entitySelectContainer.getEntitySelectContainerSubTitle', {
				name: objectStageName
			}),
		[t]
	);

	return (
		<Dialog
			className={addingInternalAcceptanceDialogClassNames}
			isOpen={isOpen}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('cancelBtn.label')}
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label={t('applyBtn.label')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					stagesSelectDialogTitle={t(
						'addingInternalAcceptanceDialog.stagesSelectDialog.title'
					)}
					stagesSelectDialogSaveLabel={t('actionButtons.button.save')}
					stagesSelectDialogCancelLabel={t('actionButtons.button.cancel')}
					selectedStage={stage}
					stages={stages}
					getEntitySelectContainerSubTitle={getEntitySelectContainerSubTitle}
				/>
			)}
		</Dialog>
	);
};

export default AddingInternalAcceptanceDialog;
