import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {useCallback} from 'react';
import SelectionActions from './SelectionActions';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTranslation} from 'react-i18next';

const SelectionRow = (props: ISelectionRowProps<IEnrichedTask>) => {
	const {selectedFlatRows, toggleAllRowsSelected} = props;
	const {t} = useTranslation();

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				selectedEntities={selectedFlatRows.map(item => item.original)}
				selectedClearing={clearSelection}
			/>
			<Button
				label={t('selectionRow.cancelButton.label')}
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};

export default SelectionRow;
