import {useCallback} from 'react';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import {mobileColumns} from './columns.mobile';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {useEnrichedProblemTagsSets} from '../../hooks/useEnrichedProblemTagsSets';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {problemTagsQueryKeys} from '@src/api/cache/problemTags/keys';
import {useQueryClient} from '@tanstack/react-query';
import {useExtractProblemTagsSetsAsArray} from '@src/core/hooks/queries/problemTagsSets/hooks';

interface ITableProps {
	loading?: boolean;
}

export const MobileTable = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const queryClient = useQueryClient();
	const {data} = useExtractProblemTagsSetsAsArray();
	const reloadProblemTags = useCallback(async () => {
		await queryClient.invalidateQueries({
			queryKey: problemTagsQueryKeys.list()
		});
	}, [queryClient]);
	const preparedData = useEnrichedProblemTagsSets(data);

	const handleRowClick = useCallback(
		(item: IEnrichedProblemTagsSet) => {
			pushPath(`/manage/problem-tags-sets/${item.id}`);
		},
		[pushPath]
	);

	if (!preparedData) return null;

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={reloadProblemTags}>
				<LoadingPanel active={loading}>
					<EntitiesTable
						headVisible={false}
						columns={mobileColumns}
						data={preparedData}
						onRowClick={handleRowClick}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
