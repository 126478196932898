import {createContext, PropsWithChildren, useCallback} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {useDeleteWorkingGroup} from '@src/core/hooks/mutations/workingGroups/useDeleteWorkingGroup';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IEnrichedWorkingGroup>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({
	children,
	reloadList
}: PropsWithChildren<{reloadList: () => Promise<void>}>) => {
	const {mutateAsync: deleteWorkingGroup} = useDeleteWorkingGroup();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить рабочую группу?',
		'Все связанные данные будут безвозвратно удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IEnrichedWorkingGroup>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await deleteWorkingGroup(action.payload.id);
				await reloadList();
			}
		},
		[reloadList]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
