import * as React from 'react';
import {EntityInfo, EntityInfoDateWithCountFiles} from '@tehzor/ui-components';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IInspectionInfoProps {
	className?: string;
	style?: React.CSSProperties;
	data: IInspection;
	depth?: number;
}

const inspectionIcon = <i className="tz-inspection-28" />;

const InspectionInfo = ({className, style, data, depth = 0}: IInspectionInfoProps) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();
	return (
		<EntityInfo
			className={className}
			style={style}
			title={`${t('inspectionInfoCell.title')} №${data.number ?? data.localNumber}`}
			subTitle={
				<EntityInfoDateWithCountFiles
					data={data}
					locale={dateFnsLocale}
					labelFrom={t('components.entityInfoDate')}
					depth={depth}
				/>
			}
			icon={inspectionIcon}
			iconColor="#8695A6"
			iconSize={depth > 0 ? 32 : 44}
		/>
	);
};

export default InspectionInfo;
