import {queryClient} from '@src/api/QueryClient';
import {
	IEditWorkAcceptanceAcceptorsResponse,
	makeWorkAcceptanceAcceptorsEditRequest
} from '@src/api/backend/workAcceptance';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';

export type IEditWorkAcceptanceResponsiblePayload = IEditWorkAcceptanceAcceptorsResponse;

const request = () => ({type: types.EDIT_ACCEPTORS_REQUEST});

const success = (response: IEditWorkAcceptanceAcceptorsResponse) => {
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.lists()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.paginate()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.details()});

	return {
		type: types.EDIT_ACCEPTORS_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении ответственных');
	return {
		type: types.EDIT_ACCEPTORS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет ответственных приемки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приемки работ
 * @param acceptors пользователи
 * @param activeGroup текущая группа
 */
export const editWorkAcceptanceAcceptors = (
	objectId: string,
	workAcceptanceId: string,
	acceptors?: string[],
	activeGroup?: string
) =>
	createApiAction<IEditWorkAcceptanceAcceptorsResponse>(request, success, failure, () =>
		makeWorkAcceptanceAcceptorsEditRequest(objectId, workAcceptanceId, acceptors, activeGroup)
	);
