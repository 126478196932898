import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export type IBindSpaceToOwnersResponse = ISpaceOwner[];

/**
 * Привязывает собственников к помещению
 *
 * @param owners id собственников
 * @param spaceId id помещения
 */
export const makeBindSpaceToOwnersRequest = async (owners: string[], spaceId: string) => {
	const res = await httpRequests.withToken.patch<IBindSpaceToOwnersResponse>(
		'space-owners/bind-space-to-owners',
		{
			owners,
			spaceId
		}
	);
	return res.data;
};
