import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IStructureState, getInitialState} from './reducers';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';

const extractStructureData = (state: IState): IStructureState => state.entities.structure || getInitialState();

/**
 * Возвращает данные структуры
 */
export const extractStructure = createSelector(
	[extractStructureData],
	structure => structure.data
);

/**
 * Извлекает нарушения структуры
 */
export const extractStructureProblems = createSelector(
	[extractStructureData], data =>
	data.problems.allIds.map(id => data.problems.byId[id])
);

/**
 * Извлекает осмотры структуры
 */
export const extractStructureInspections = createSelector(
	[extractStructureData],
	data => data.inspections.allIds.map(id => data.inspections.byId[id])
);

const sortByCreation = (a: IStructureEntity, b: IStructureEntity) =>
	(b.data.createdAt ?? 0) - (a.data.createdAt ?? 0);

const convert = <T extends {id: string}, E extends string, S>(
	data: T,
	entityName: E,
	subRows?: S[]
) => ({
	id: `${entityName}_${data.id}`,
	type: entityName,
	data,
	expanded: !!subRows?.length,
	subRows
});

/**
 * Извлекает нарушения и осмотры единым списком с учётом фильтра
 */
export const extractStructureEntities = createSelector(
	[

		extractStructureProblems,
		extractStructureInspections,
		state => state.settings.pages.structure.entitiesVisibility
	],
	(
		problems,
		inspections,
		visibility
	) => {
		const problemsVisible = visibility.includes('problems');
		const inspectionsVisible = visibility.includes('inspections');

		const result: IStructureEntity[] = [];

		if (problemsVisible) {
			for (const problem of problems) {
				if (problem.links) {
					result.push(convert(problem, 'problem'));
				}
			}
		}

		if (inspectionsVisible) {
			for (const inspection of inspections) {
				if (inspection.links) {
					result.push(convert(inspection, 'inspection'));
				}
			}
		}

		return result.sort(sortByCreation);
	}
);