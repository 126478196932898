import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {Column} from 'react-table';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {PlanCell} from '../components/table/PlanCell';
import {MenuCell} from '../components/table/MenuCell';

export const useColumns = () => {
	const {t} = useTranslation();

	return useMemo<Array<Column<IPlan>>>(
		() => [
			{
				id: 'plan',
				Header: t('plansPage.useColumns.plan.header'),
				Cell: PlanCell,
				accessor: item => item
			},
			{
				id: '_menu',
				Header: '',
				Cell: MenuCell,
				className: 'plans-page__menu-cell'
			}
		],
		[t]
	);
};
