import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {IState} from '@src/store/modules';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {
	IGetChecksCreationStatsResponse,
	requestChecksCreationStats
} from '@src/api/backend/statistics/creation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

const request = () => ({type: types.GET_CHECKS_CREATION_REQUEST});

const success = (response: IGetChecksCreationStatsResponse) => ({
	type: types.GET_CHECKS_CREATION_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке статистики');
	}
	return {
		type: types.GET_CHECKS_CREATION_FAILURE,
		payload: error
	};
};

/**
 * Получает статистику по созданию проверок для стадии приёмки
 */
export const getChecksCreationStats
	= (): AppThunkAction<Promise<IGetChecksCreationStatsResponse>> => (dispatch, getState) =>
		dispatch(
			checkExpiration<IState, IGetChecksCreationStatsResponse, ApiAction>(
				state => state.statistics.acceptance.checksCreation,
				createApiAction<IGetChecksCreationStatsResponse>(request, success, failure, () => {
					const s = getState();
					const {objects, users, contractors}
						= s.settings.pages.statistics.acceptance.filters;
					return requestChecksCreationStats(
						objects,
						ObjectStageIds.ACCEPTANCE,
						users,
						contractors
					);
				})
			)
		);
