import { memo, useCallback, useEffect, useState } from 'react';
import {AddingDocuments, FilesDropArea} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import {convertRawFiles} from '@src/components/editableFields/Attachments/utils/convertRawFiles';
import {addTempFile, deleteTempFile} from '@src/store/modules/entities/tempFile/actions';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import {
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {editSpace} from '@src/store/modules/entities/space/actions';
import {useTranslation} from 'react-i18next';

interface INewDocumentsProps {
	objectId: string;
	spaceId: string;
	waitForUploading?: boolean;
}

export const NewDocuments = memo((props: INewDocumentsProps) => {
	const {objectId, spaceId, waitForUploading = true} = props;
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const {t} = useTranslation();

	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [saving, setSaving] = useState(false);

	const handleFilesPick = useCallback(
		(files: IRawFile[]) => {
			uploadingFilesDispatch({
				type: 'add',
				value: convertRawFiles(files, networkStatus, waitForUploading)
			});
		},
		[networkStatus]
	);

	const addTemporaryFile = useCallback(
		(...args: Parameters<typeof addTempFile>) => dispatch(addTempFile(...args)),
		[]
	);

	const deleteTemporaryFile = useCallback(async (fileId: string) => {
		await dispatch(deleteTempFile(fileId));
	}, []);

	const handleUploadingFilesChange = useCallback((value: IUploadingFile[]) => {
		uploadingFilesDispatch({type: 'update', value});
	}, []);

	const save = useCallback(async () => {
		setSaving(true);

		const files = await waitUploading();
		// Проверка, были ли успешно добавлены файлы
		if (!isFilesExist(files) || someFilesHaveError(files)) {
			setSaving(false);
			return;
		}
		const savingData = {
			objectId,
			spaceId,
			newAttachments: convertFilesToSave(files)
		};

		if (!networkStatus) {
			// TODO Локальное сохранение
		} else {
			try {
				await dispatch(editSpace(objectId, objectId, spaceId, savingData));
				uploadingFilesDispatch({type: 'reset'});
			} catch (error) {
				console.log(error);
				// TODO Локальное сохранение
			}
		}

		setSaving(false);
	}, [networkStatus, objectId, spaceId, waitUploading]);

	useEffect(() => {
		if (uploadingFilesState.value.length) {
			void save();
		}
	}, [uploadingFilesState.value.length]);

	return (
		<>
			{uploadingFilesState.value.length > 0 ? (
				<AddingDocuments
					online={networkStatus}
					uploadingFiles={uploadingFilesState.value}
					disabled={saving}
					onUploadingFilesChange={handleUploadingFilesChange}
					onAddTempFile={addTemporaryFile}
					onDeleteTempFile={deleteTemporaryFile}
				/>
			) : null}

			<FilesDropArea
				className="space-page__documents-adding-btn"
				label={t('spacePage.documents.addButton')}
				icon={false}
				multiple
				disabled={saving}
				onPick={handleFilesPick}
			/>
		</>
	);
});
