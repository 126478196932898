import './ExportTemplateEditingPage.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {EditPageBreadcrumbs} from './components/EditPageBreadcrumbs';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import {EditingExportTemplate} from '@src/components/EditingExportTemplate/EditingExportTemplate';
import {useParams} from 'react-router-dom';

export interface IExportTemplateEditingPageProps {
	exportTemplateId?: string;
}

export const ExportTemplateEditingPage = () => {
	const {exportTemplateId} = useParams<{exportTemplateId?: string}>();
	useAppHeader(
		{
			title: exportTemplateId
				? 'Редактирование шаблона экспорта'
				: 'Создание шаблона экспорта',
			mobileRightButtons: <AppUpdateMenu/>,
			showBackBtn: true
		},
		[exportTemplateId]
	);

	return (
		<div className="page-cont manage-export-template-edit-page">
			<EditPageBreadcrumbs/>
			<EditingExportTemplate exportTemplateId={exportTemplateId}/>
		</div>
	);
};
