import {useDocumentsListFilteredArray} from '@src/core/hooks/queries/documents/hooks';
import {EntityGridItem, InlineButton} from '@tehzor/ui-components';
import { memo } from 'react';
import {useDocumentsDialog} from '../DocumentsDialog';
import './EntityDocs.less';
import {DOCUMENTS_COUNT, EntityDocsItems} from './components/EntityDocsItems';
import {useTranslation} from 'react-i18next';

interface IMobileEntityDocsProps {
	id: string;
}

const documentIcon = <i className="tz-document-20" />;

export const MobileEntityDocs = memo((props: IMobileEntityDocsProps) => {
	const {id} = props;

	const {t} = useTranslation();

	const {data: documents} = useDocumentsListFilteredArray(id);
	const totalCount = documents?.length;
	const {documentsDialog, openDocumentsDialog} = useDocumentsDialog(documents);

	if (!totalCount || !documents) {
		return null;
	}

	const showButton = totalCount > DOCUMENTS_COUNT;

	return (
		<EntityGridItem
			label={t('entityDocs.header')}
			icon={documentIcon}
			fullRow
		>
			<EntityDocsItems documents={documents} />

			{showButton && (
				<div className="entity-docs__more">
					<InlineButton
						type="accent"
						label={`${t('entityDocs.showAll')} (${totalCount})`}
						onClick={openDocumentsDialog}
					/>
				</div>
			)}

			{documentsDialog}
		</EntityGridItem>
	);
});
