import {IPreparedContract} from '../interfaces/IPreparedContract';

const hasValue = (
	contract: IPreparedContract, field: keyof IPreparedContract
) =>
	(contract[field] !== undefined && contract[field] !== null)

export const isBlockVisible = (
	contract: IPreparedContract,
	fields: Array<keyof IPreparedContract>
): boolean => {
	for (const field of fields) {
		if (field in contract && hasValue(contract, field)) {
			return true;
		}
	}
	return false;
}