import { memo } from 'react';
import * as React from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';

interface IAllPageBreadcrumbsProps {
	children?: React.ReactNode;
}

export const AllObjectPageBreadcrumbs = memo(({children}: IAllPageBreadcrumbsProps) => (
	<AppBreadcrumbs
		className="owner-acceptances-page__breadcrumbs"
		items={[]}
	>
		{children}
	</AppBreadcrumbs>
));