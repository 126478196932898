import { memo } from 'react';
import {CalendarView, CalendarViewResult} from '../hooks/useCalendarView';
import IconButton from '../../../buttons/IconButton';
import InlineButton from '../../../buttons/InlineButton';

interface IMonthsNavProps {
	viewData: CalendarViewResult;
}

const MonthsNav = (props: IMonthsNavProps) => {
	const {viewData} = props;
	const {year, changeView} = viewData;

	const handleYearClick = () => {
		changeView(CalendarView.DECADE);
	};

	return (
		<div className="calendar__nav">
			<IconButton
				className="calendar__nav-arrow-btn"
				onClick={viewData.prevYear}
			>
				<i className="tz-simple-arrow-24 calendar__nav-prev-icon"/>
			</IconButton>

			<InlineButton
				className="calendar__nav-link"
				labelClassName="calendar__nav-link-label"
				type="accent"
				label={year}
				onClick={handleYearClick}
			/>

			<IconButton
				className="calendar__nav-arrow-btn"
				onClick={viewData.nextYear}
			>
				<i className="tz-simple-arrow-24 calendar__nav-next-icon"/>
			</IconButton>
		</div>
	);
};

export default memo(MonthsNav);
