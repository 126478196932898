import classNames from 'classnames';
import { memo } from 'react';
import {useDndCtx} from '../../DndContext';
import {useDndEmptyItem} from '../../hooks/useDndEmptyItem';

interface IDndListEmptyItemProps {
	className?: string;
	emptyMessage?: string;
	container?: string;
	overContainer?: string;
}

export const DndListEmptyItem = memo(({
	className, emptyMessage, container, overContainer
}: IDndListEmptyItemProps) => {
	const emptyItemRef = useDndEmptyItem<HTMLDivElement>(container);
	const {dragState: {isDragging}} = useDndCtx();

	const isOverItem = overContainer && container
		? isDragging && overContainer === container
		: false;

	return (
		<div
			className={classNames(
				'dnd-list__empty-item',
				{isOver: isOverItem},
				className
			)}
			ref={emptyItemRef}
		>
			{emptyMessage || 'Нет элементов в списке'}
		</div>
	);
});