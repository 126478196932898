import {useCallback, useEffect} from 'react';
import {DialogMenu, Menu, MenuItem} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeType} from '@src/store/modules/settings/pages/spaces/actions';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {SpaceTypeMenuButton} from '@src/pages/SpacesPage/components/typeFilter/SpaceTypeMenuButton';
import {useFilteredSpacesTypes} from '@src/core/hooks/queries/spaces/typesCount/hooks';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';

interface ISpaceTypeMenuProps {
	objectId: string;
	targetObjects: string[];
}

export const SpaceTypeMenu = ({objectId, targetObjects}: ISpaceTypeMenuProps) => {
	const {data: types} = useFilteredSpacesTypes(targetObjects);
	const selectedType = useAppSelector(s => extractSpacesPageSettings(s, objectId).type);
	const {data: typesMap} = useExtractSpaceTypesById();
	const translatedTypesMap = useTranslatedDictionaryMap('spaceTypes', typesMap);

	const dispatch = useAppDispatch();

	// При переходе на страницу в первый раз (то есть нет сохраненного выбранного типа),
	// необходимо установить тип по умолчанию
	useEffect(() => {
		if (selectedType === undefined && types?.length) {
			dispatch(changeType(objectId, types[0][0].id));
		}
	}, [types]);

	const handleTypeChange = useCallback(
		(type: string) => {
			dispatch(changeType(objectId, type));
		},
		[objectId]
	);

	const selectedTypeCount = types?.find(
		type => selectedType && selectedType === (type[0].id as string)
	)?.[1];

	const btnLabel =
		selectedType && selectedTypeCount
			? `${translatedTypesMap[selectedType]?.name} (${
					selectedTypeCount.filtred !== selectedTypeCount.spaces
						? `${selectedTypeCount.filtred}/${selectedTypeCount.spaces}`
						: selectedTypeCount.filtred
			  })`
			: 'Выбрать тип';

	return types?.length ? (
		<DialogMenu
			className="spaces-page__space-type-menu-dialog"
			trigger={<SpaceTypeMenuButton label={btnLabel} />}
		>
			<Menu
				value={selectedType}
				onChange={handleTypeChange}
			>
				{types.map(([type, count]) => (
					<MenuItem
						key={type.id}
						itemKey={type.id}
					>
						{`${type.name} (${
							count.filtred !== count.spaces
								? `${count.filtred}/${count.spaces}`
								: count.filtred
						})`}
					</MenuItem>
				))}
			</Menu>
		</DialogMenu>
	) : null;
};
