import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ITaskType} from '@tehzor/tools/interfaces/tasks/ITaskType';

export type IGetTaskTypesResponse = INormalizedData<ITaskType>;

/**
 * Возвращает список типов задач
 */
export const requestTaskTypes = async () => {
	const response = await httpRequests.withToken.get<IGetTaskTypesResponse>('task-types');
	return response.data;
};
