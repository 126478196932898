import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableSpaceAction, IEditableSpaceState, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

export const useEditableSpaceState = (space?: ISpace) =>
	useReducer<Reducer<IEditableSpaceState, IEditableSpaceAction>, ISpace | undefined>(
		createReducer(init, errorsFns),
		space,
		init
	);
