import {Column} from 'react-table';
import CellWrap from '@src/components/tableCells/CellWrap';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import {useMemo} from 'react';
import {useWarrantyClaimProblemCommentsByProblem} from '@src/core/hooks/queries/warrantyClaim/problemsReplies/hooks';

export const useMobileColumns = (
	claimId?: string,
	objectId?: string
): Array<Column<IWarrantyClaimEntity>> => {
	const {data: replies} = useWarrantyClaimProblemCommentsByProblem(claimId, objectId);

	return useMemo(
		() => [
			{
				id: 'info',
				Cell: CellWrap({
					problem: ProblemInfoCellWrap(ProblemInfoMobileCell, undefined, replies)
				})
			}
		],
		[replies]
	);
};
