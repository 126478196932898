import {useCallback, useEffect, useState} from 'react';
import './EditableReply.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	convertToSave,
	isEdited,
	useEditableCommentState,
	hasErrors,
	hasAttachmentsError
} from '@src/core/hooks/states/useEditableCommentState';
import {
	convertToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import Description from '@src/components/editableFields/Description';
import Attachments from '@src/components/editableFields/Attachments';
import {useIsDesktopHeight} from '@tehzor/ui-components/src/utils/mediaQueries';
import {makeTitle} from './utils/makeTitle';
import StatusTransition from './components/StatusTransition';
import {useFieldsSettings} from '@src/core/hooks/useFieldsSettings';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useCommentsCtx} from '../Comments/utils/CommentsCtx';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditableReplyProps {
	objectId: string;
	comment?: IComment;
	links?: IComment['links'];
	isOpen: boolean;
	onClose: () => void;
	onAfterClose: () => void;
}

/**
 * Окно для добавления/редактирования ответа
 */
export const EditableReply = (props: IEditableReplyProps) => {
	const {objectId, comment, links, isOpen, onClose, onAfterClose} = props;
	const {t} = useTranslation();
	const {addReply, getEntity, editReply} = useCommentsCtx();
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const fieldsSettings = useFieldsSettings(objectId, 'problemReply');

	const [editingState, editingDispatch] = useEditableCommentState(comment);
	const [uploadingFiles, upFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, comment) || isFilesExist(uploadingFiles.value));
	}, [editingState, uploadingFiles.value, comment]);

	useUpdateEffect(() => {
		editingDispatch({type: 'reset', entity: comment});
		upFilesDispatch({type: 'reset'});
	}, [comment]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
		}
	}, [isBlocking, onClose]);

	const handleAfterClose = useCallback(() => {
		editingDispatch({type: 'reset'});
		upFilesDispatch({type: 'reset'});
		if (onAfterClose) {
			onAfterClose();
		}
	}, [onAfterClose]);

	const [savingState, save] = useAsyncFn(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (
			hasErrors(editingState, fieldsSettings) ||
			(hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
		) {
			editingDispatch({type: 'update-errors'});
			upFilesDispatch({type: 'update-error'});
			return;
		}
		// Проверка, были ли отредактированы поля
		if (
			!isEdited(editingState, comment) &&
			(!isFilesExist(files) || someFilesHaveError(files))
		) {
			return;
		}

		if (!networkStatus) {
			// Локальное сохранение
			const savingData = convertToSave(editingState, comment, true);
			savingData.newAttachments = convertToLocalSave(files);
			// TODO Локальное сохранение
		} else {
			const savingData = convertToSave(editingState, comment, true);
			savingData.newAttachments = convertFilesToSave(files);

			try {
				if (comment === undefined) {
					await addReply(savingData);
					if (getEntity) {
						getEntity();
					}
				} else {
					await editReply(comment.id, savingData);
				}

				onClose();
			} catch (error) {
				console.log(error);
				// TODO Локальное сохранение
			}
		}
	}, [editingState, comment, networkStatus, objectId, links, fieldsSettings]);

	const isDesktopHeight = useIsDesktopHeight();

	return (
		<Dialog
			className="editable-reply"
			isOpen={isOpen}
			title={makeTitle(comment?.id, comment)}
			footer={
				<ActionButtons>
					<Button
						type="accent-blue"
						label={t('component.editableProblemReply.dialog.button.save')}
						disabled={savingState.loading}
						onClick={save}
					/>
					<Button
						type="cancel"
						label={t('component.editableProblemReply.dialog.button.cancel')}
						disabled={savingState.loading}
						onClick={handleClose}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={handleAfterClose}
		>
			<StatusTransition
				objectId={objectId}
				commentId={comment?.id}
			/>

			{fieldsSettings.description !== undefined && (
				<Description
					className="editable-reply__description"
					label={t('component.editableProblemReply.dialog.description.title')}
					value={editingState.description}
					editingDispatch={editingDispatch}
					required={fieldsSettings.description.isRequired}
					disabled={savingState.loading}
					hasError={editingState.errors.description}
					textAreaProps={
						isDesktopHeight ? {minRows: 3, maxRows: 7} : {minRows: 3, maxRows: 5}
					}
				/>
			)}

			{fieldsSettings.attachments !== undefined && (
				<Attachments
					className={{
						root: 'editable-reply__attachments',
						scrollArea: 'editable-reply__attachments-scroll-area',
						files: 'editable-reply__attachments-files',
						file: 'editable-reply__attachments-file'
					}}
					imagesTitle={t('component.editableProblemReply.dialog.attachments.label')}
					attachmentsDestination={AttachmentFileDestination.COMMENT_OR_REPLY}
					entityId={comment?.id}
					attachments={editingState.attachments}
					uploadingFiles={uploadingFiles.value}
					editingDispatch={editingDispatch}
					uploadingFilesDispatch={upFilesDispatch}
					required={fieldsSettings.attachments.isRequired}
					disabled={savingState.loading}
					canDraw
					hasError={editingState.errors.attachments && uploadingFiles.error}
					showAttachBtn
				/>
			)}

			{Object.keys(fieldsSettings).length === 0 && (
				<div className="editable-comment__empty-fields-msg">
					{t('component.editableProblemReply.dialog.emptyFieldsMsg')}
				</div>
			)}

			{closingDialog}
		</Dialog>
	);
};
