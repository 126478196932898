import {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {getTranslatedMeasureUnit} from '@src/utils/translation/getTranslatedMeasureUnit';

interface IMeterTariffSelectProps<S, E> {
	meterConsumptions: Array<{value?: string}>;
	index: number;
	className?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	disabled?: boolean;
	measureUnit?: string;
}

export const MeterAddingMeterValueInput = <
	S extends {meterConsumptions?: Array<{value?: string}> | null},
	E
>({
	meterConsumptions,
	index,
	className,
	editingDispatch,
	disabled,
	measureUnit
}: IMeterTariffSelectProps<S, E>) => {
	const {t} = useTranslation();
	const translatedMeasureUnit = measureUnit
		? getTranslatedMeasureUnit(measureUnit, t)
		: undefined;
	const handleChange = useCallback(
		(v: string) => {
			const value = [...meterConsumptions];
			value[index].value = v;
			editingDispatch({
				type: 'update',
				field: 'meterConsumptions',
				value
			});
		},
		[editingDispatch, index, meterConsumptions]
	);

	return (
		<div className={className}>
			<EditableFieldLabel>
				{translatedMeasureUnit
					? `${t(
							'components.editableFields.meterAddingMeterValueInput.editableFieldLabel'
					  )}, ${translatedMeasureUnit}`
					: t('components.editableFields.meterAddingMeterValueInput.editableFieldLabel')}
			</EditableFieldLabel>

			<TextField
				value={meterConsumptions[index].value}
				elementType="input"
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};
