import {Category} from '@src/components/viewFields/Category';
import Description from '@src/components/viewFields/Description';
import Location from '@src/components/viewFields/Location';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {CustomField} from '@src/components/CustomField';
import {InspectionPerformers} from './InspectionPerformers';

interface IDesktopInfoProps {
	objectId: string;
	inspection: ILinkedInspection;
	settings?: IEntitySettings;
}

const DesktopInfo = ({objectId, inspection, settings}: IDesktopInfoProps) => {
	const {t} = useTranslation();
	const custom = settings?.custom;
	return (
		<EntityGrid
			className={{wrap: 'inspection-page__d-entities-grid'}}
			withBorders
		>
			{inspection.description && <Description value={inspection.description} />}
			{(!!inspection.plan || !!inspection.floor || !!inspection.location) && (
				<Location
					objectId={objectId}
					planId={inspection.plan?.id}
					floor={inspection.floor}
					location={inspection.location}
				/>
			)}
			{inspection.categoryId && <Category categoryId={inspection.categoryId} />}
			{inspection.performers?.length || inspection.performersActiveGroup ? (
				<InspectionPerformers inspection={inspection} />
			) : null}
			{custom &&
				Object.values(custom).map(customSetting => (
					<CustomField
						fields={inspection.customFields}
						setting={customSetting}
					/>
				))}
			<EditorInfo
				icon={<i className="tz-document-20" />}
				label={t('inspectionPage.info.createdAt.label')}
				date={inspection.createdAt}
				user={inspection.createdBy}
			/>
			{inspection.createdAt !== inspection.modifiedAt && (
				<EditorInfo
					icon={<i className="tz-edit-20" />}
					label={t('inspectionPage.info.modifiedAt.label')}
					date={inspection.modifiedAt}
					user={inspection.modifiedBy}
				/>
			)}
		</EntityGrid>
	);
};

export default memo(DesktopInfo);
