import {CSSProperties, ReactNode} from 'react';
import './ButtonContainer.less';
import classNames from 'classnames';

export interface IButtonContainerProps {
	children: ReactNode[];
	className?: string;
	style?: CSSProperties;
}

const ButtonContainer = (props: IButtonContainerProps) => {
	const {children, className, style} = props;

	const useSeparation = children.length > 1;
	return (
		<div className={classNames('button-container', className)} style={style}>
			{children.map((item, index) => (
				<div className="button-container__element" key={index}>
					{item}
					{useSeparation && index !== children.length - 1 && (
						<div className="button-container__separator" />
					)}
				</div>
			))}
		</div>
	);
};

ButtonContainer.displayName = 'ButtonContainer';

export default ButtonContainer;
