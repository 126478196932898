import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingMeter} from '@tehzor/tools/interfaces/meters/ISavingMeter';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';

export type IAddMeterResponse = IMeter;

/**
 * Добавляет прибор учёта
 *
 * @param meter данные для добавления
 */
export const makeMeterAddRequest = async (meter: ISavingMeter) => {
	const response = await httpRequests.withToken.post<IAddMeterResponse>('/meters/add', meter);
	return response.data;
};
