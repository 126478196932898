import {ReactNode, useEffect} from 'react';
import './Popup.less';
import ResizeObserver from 'resize-observer-polyfill';
import {DisappearType, Placement, useLayer, UseLayerOptions} from 'react-laag';
import PopupAnimation from './components/PopupAnimation';
import classNames from 'classnames';
import {LayerDimensions, Options} from 'react-laag/dist/types.d';
import {PlacementType} from 'react-laag/dist/PlacementType.d';
import {TriggerProps} from 'react-laag/dist/useLayer.d';
import Arrow from './components/Arrow';
import {convertClassNames} from '../../../utils/convertClassNames';
import {Variants} from 'framer-motion';
import {useScrollBlockContext} from '../../../context/ScrollBlockContext';

export interface IPopupBasicProps {
	container?: string | HTMLElement | (() => HTMLElement);
	placement?: Placement;
	possiblePlacements?: PlacementType[];
	preferX?: Options['preferX'];
	preferY?: Options['preferY'];
	auto?: boolean;
	snap?: boolean;
	triggerOffset?: number;
	containerOffset?: number;
	arrowOffset?: number;
	layerDimensions?: LayerDimensions | null;
	overflowContainer?: boolean;
}

export interface IPopupProps extends IPopupBasicProps {
	className?:
		| string
		| {
				root?: string;
				content?: string;
		  };
	children?: ReactNode;
	isOpen: boolean;
	trigger?: (props: TriggerProps) => ReactNode;
	triggerObject?: UseLayerOptions['trigger'];
	arrowVisible?: boolean;
	animationVariants?: Variants;

	onParentClose?: () => void;
	onOutsideClick?: () => void;
	onDisappear?: (type: DisappearType) => void;
	onAnimationComplete?: () => void;
	dataTestId?: string;
}

const Popup = (props: IPopupProps) => {
	const {
		className,
		children,
		isOpen,
		trigger,
		triggerObject,
		arrowVisible,
		animationVariants,
		container,
		placement = 'bottom-start',
		possiblePlacements,
		preferX = 'right',
		preferY,
		auto = true,
		snap = false,
		triggerOffset = arrowVisible ? 18 : 8,
		containerOffset = 16,
		arrowOffset = 26,
		layerDimensions,
		overflowContainer = true,
		onOutsideClick,
		onDisappear,
		onAnimationComplete,
		onParentClose,
		dataTestId
	} = props;

	const {layerProps, layerSide, triggerProps, arrowProps, renderLayer} = useLayer({
		isOpen,
		placement,
		possiblePlacements,
		preferX,
		preferY,
		auto,
		snap,
		container,
		triggerOffset,
		containerOffset,
		arrowOffset,
		layerDimensions,
		overflowContainer,
		onOutsideClick,
		onDisappear,
		trigger: triggerObject,
		ResizeObserver,
		onParentClose
	});
	const setScrollBlock = useScrollBlockContext();
	const classes = convertClassNames(className);

	useEffect(() => {
		setScrollBlock(isOpen);
	}, [isOpen]);

	return (
		<>
			{trigger && trigger(triggerProps)}
			{renderLayer(
				<PopupAnimation
					className={classNames('popup', classes.root)}
					style={layerProps.style}
					layerSide={layerSide}
					arrowStyle={arrowProps.style}
					animationVariants={animationVariants}
					isOpen={isOpen}
					onAnimationComplete={onAnimationComplete}
					ref={layerProps.ref}
				>
					{arrowVisible && children !== undefined && (
						<Arrow style={arrowProps.style} layerSide={arrowProps.layerSide} />
					)}

					{children !== undefined && (
						<div
							data-testid={dataTestId}
							className={classNames('popup__content', classes.content)}
						>
							{children}
						</div>
					)}
				</PopupAnimation>
			)}
		</>
	);
};

Popup.displayName = 'Popup';

export default Popup;
