import {useMemo} from 'react';
import {IEnrichedDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IEnrichedDelegationHistory';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';

function enrichGroup(
	groupId: string | undefined,
	groupsMap: Record<string, IWorkingGroup> | undefined,
	usersMap: Record<string, IBriefUser>
) {
	if (groupId && groupsMap && groupsMap[groupId]) {
		return {
			id: groupsMap[groupId].id,
			name: groupsMap[groupId].name,
			leader: usersMap[groupsMap[groupId].leader]
		};
	}
	return undefined;
}

export function useEnrichedHistories(
	histories: IDelegationHistory[]
): IEnrichedDelegationHistory[] {
	const {data: groupsMap} = useWorkingGroupsAsMap();
	const {data: usersMap} = useUsersAsMap();

	return useMemo(
		() =>
			histories.map<IEnrichedDelegationHistory>(history => {
				const fromGroup = usersMap
					? enrichGroup(history.fromGroup, groupsMap, usersMap)
					: undefined;
				const toGroup = usersMap
					? enrichGroup(history.toGroup, groupsMap, usersMap)
					: undefined;
				const toUsers = usersMap
					? history.toUsers?.reduce<IBriefUser[]>((prev, id) => {
							if (usersMap[id]) {
								prev.push(usersMap[id]);
							}
							return prev;
					  }, [])
					: undefined;
				const createdBy =
					history.createdBy && usersMap ? usersMap[history.createdBy] : undefined;
				return {
					id: history.id,
					objectId: history.id,
					links: {
						problemId: history.links?.problemId,
						workAcceptanceId: history.links?.workAcceptanceId
					},
					fromGroup,
					toGroup,
					toUsers,
					ruleId: history.ruleId,
					createdBy,
					createdAt: history.createdAt
				};
			}),
		[histories, groupsMap, usersMap]
	);
}
