import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';

export const ResponsibleUsersMobileCell = (
	props: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'value'>
) => {
	const {value} = props;
	const task = value;
	const {data: usersMap} = useUsersAsMap();
	const {data: groupsMap} = useWorkingGroupsAsMap();

	const getLeaderWorkGroupName = (workGroupId?: string): string | undefined => {
		if (!workGroupId || !usersMap || !groupsMap) return undefined;
		const leaderId = groupsMap[workGroupId]?.leader;
		return usersMap[leaderId]?.displayName;
	};

	if (!((task?.respUsers && task?.respUsers?.length > 0) || task?.activeGroup)) {
		return null;
	}
	return (
		<div className="tasks-page__m-table-cell-responsible">
			<div className="tasks-page__m-table-cell-responsible--label">Ответственный:</div>
			<div className="tasks-page__m-table-cell-responsible--text">
				{task?.respUsers && task?.respUsers?.length > 0
					? task.respUsers.map(user => user?.displayName)
					: getLeaderWorkGroupName(task.activeGroup)}
			</div>
		</div>
	);
};
