import {memo} from 'react';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';

import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from 'react-i18next';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {makeDateFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';

interface IFixDateFilterProps {
	label?: string;
	from?: Date;
	to?: Date;
}

export const ActualFixDateFilter = memo((props: IFixDateFilterProps) => {
	const {label = 'Фактическая дата устранения', from, to} = props;
	const {dispatch} = useEntitiesFiltersCtx();

	const handleChange = (f: Date | null, t: Date | null) => {
		const actualFixDateFrom = f
			? new Date(f.getFullYear(), f.getMonth(), f.getDate())
			: undefined;
		const actualFixDateTo = t
			? new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999)
			: undefined;

		dispatch({actualFixDateFrom, actualFixDateTo});
	};

	const {t} = useTranslation();
	const handleClear = () => {
		dispatch({actualFixDateFrom: undefined, actualFixDateTo: undefined});
	};

	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	return (
		<DateRangePicker
			trigger={triggerProps => (
				<FilterButton
					className="entities-filters__item"
					label={makeDateFilterLabel(label, from, to)}
					active={from !== undefined || to !== undefined}
					onClick={triggerProps.toggle}
					onClear={handleClear}
					ref={triggerProps.ref}
				/>
			)}
			valueFrom={from}
			valueTo={to}
			dateFormat="dd MMMM yyyy"
			datesOptions={datesOptions}
			useApplyButton
			onChange={handleChange}
			selectApplyBtnLabel={t('applyBtn.label')}
			selectCancelBtnLabel={t('cancelBtn.label')}
		/>
	);
});
