import { useMemo } from 'react';
import * as React from 'react';
import {IPermissions} from './usePermissions';
import {
	formCheckLink,
	formInternalAcceptanceLink,
	formOwnerAcceptanceLink,
	formSpaceLink,
	formTaskLink,
	formWarrantyClaimLink,
	formWorkAcceptanceLink
} from '@tehzor/tools/utils/links';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTask} from "@src/core/hooks/queries/task/hooks";
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryMap} from "@src/core/hooks/translations/useTranslatedDictionaryMap";
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";

interface IEntityLink {
	key: string;
	icon: React.ReactNode;
	label: string;
	url?: string;
	title: string;
}

export function useEntitiesLinks(
	permissions: IPermissions,
	objectId: string,
	problem?: IPreparedProblem
): IEntityLink[] {
	const {t} = useTranslation();

	const {data: spaceTypes} = useExtractSpaceTypesById();
	const translatedSpaceTypes = useTranslatedDictionaryMap(dictionaryKeys.spaceTypes, spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const {data: task} = useTask(problem?.links?.taskId)

	return useMemo((): IEntityLink[] => {
		if (!problem?.links) {
			return [];
		}
		const result: IEntityLink[] = [];
		if (problem.space && permissions.canViewSpace) {
			result.push({
				key: 'space',
				icon: <i className="tz-space-24"/>,
				label: formSpaceTitle(
					problem.space.name,
					problem.space.altName,
					translatedSpaceTypes?.[problem.space.type],
					altNamesVisible,
					true
				),
				url: formSpaceLink(objectId, problem.space.id),
				title: t('problemPage.links.toSpace.title')
			});
		}
		if (problem.check && permissions.canViewCheck) {
			result.push({
				key: 'check',
				icon: <i className="tz-check-24"/>,
				label: `${t('problemPage.links.toCheck.label')} №${problem.check.number}`,
				url: formCheckLink(objectId, problem.check.id),
				title: t('problemPage.links.toCheck.title')
			});
		}
		if (problem.internalAcceptance && permissions.canViewInternalAcceptance) {
			result.push({
				key: 'internalAcceptance',
				icon: <i className="tz-internal-acceptance-24"/>,
				label: `${t('problemPage.links.toInternalAcceptance.label')} №${problem.internalAcceptance.number}`,
				url: formInternalAcceptanceLink(objectId, problem.internalAcceptance.id),
				title: t('problemPage.links.toInternalAcceptance.title')
			});
		}
		if (problem.ownerAcceptance && permissions.canViewOwnerAcceptance) {
			result.push({
				key: 'ownerAcceptance',
				icon: <i className="tz-owner-acceptance-24"/>,
				label: `${t('problemPage.links.toOwnerAcceptance.label')} №${problem.ownerAcceptance.number}`,
				url: formOwnerAcceptanceLink(objectId, problem.ownerAcceptance.id),
				title: t('problemPage.links.toOwnerAcceptance.title')
			});
		}
		if (problem.warrantyClaim && permissions.canViewWClaim) {
			result.push({
				key: 'w-claim',
				icon: <i className="tz-warranty-claim-24"/>,
				label: `${t('problemPage.links.toWarrantyClaim.label')} №${problem.warrantyClaim.number}`,
				url: problem.warrantyClaim
					? formWarrantyClaimLink(objectId, problem.warrantyClaim.id)
					: undefined,
				title: t('problemPage.links.toWarrantyClaim.title')
			});
		}

		if (problem?.links?.taskId !== undefined && permissions.canViewTask) {
			result.push({
				key: 'task',
				icon: <i className="tz-internal-acceptance-24"/>,
				label: `${t('problemPage.links.toTask.label')} ${task?.name?.slice(0, 9)}`,
				url: formTaskLink(problem.links.taskId),
				title: t('problemPage.links.toTask.title')
			});
		}
		if (problem.workAcceptance !== undefined && permissions.canViewWorkAcceptance) {
			result.push({
				key: 'work-acceptance',
				icon: <i className="tz-internal-acceptance-24"/>,
				label: `${t('problemPage.links.toWorkAcceptance.label')} №${problem.workAcceptance.number}`,
				url: formWorkAcceptanceLink(objectId, problem.workAcceptance.id),
				title: t('problemPage.links.toWorkAcceptance.title')
			});
		}
		return result;
	}, [objectId, problem, permissions, spaceTypes, task, altNamesVisible]);
}
