/**
 * Открывает файл в новой вкладке
 *
 * @param url адрес файла
 * @param fileName название файла при скачивании
 */
export default function openFileInNewTab(url: string, fileName?: string) {
	const link = document.createElement('a');
	link.href = url;
	link.target = '_blank';
	if (fileName) {
		link.download = fileName;
	}
	link.style.display = 'none';
	document.body.appendChild(link);
	link.click();
	link.remove();
}
