import {
	IEditableEntityAction,
	IEditableEntityState
} from '@tehzor/tools/core/states/editableEntityState';
import {ICustomField, IFieldSetting} from '@tehzor/tools/interfaces/fields';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {Dispatch, memo, useCallback, useEffect} from 'react';
import {ICustomState} from '../CustomFields';
import {useTranslation} from 'react-i18next';

interface ICustomTextFieldProps<S, E> {
	field: ICustomField;
	setting: IFieldSetting;
	hasError?: boolean;
	exception?: boolean;
	disabled?: boolean;

	editingState: IEditableEntityState<S>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
}

const textAreaProps = {
	minRows: 2,
	maxRows: 6
};

const getValue = (value: unknown): string | undefined => {
	if (typeof value === 'string') {
		return value;
	}
	return undefined;
};

const CustomTextFieldComponent = <S extends ICustomState, E>({
	field,
	setting,
	hasError,
	disabled,
	exception,
	editingState,
	editingDispatch,
}: ICustomTextFieldProps<S, E>) => {
	const value = getValue(editingState.customFields?.[field.key]);
	const {t} = useTranslation();

	useEffect(() => {
		editingDispatch({type: 'create-custom-error', field: field.key});
	}, [field.key]);

	// убираем обязательность поля при отсутствии вариантов для выбора
	useEffect(() => {
		if (setting.required && !exception) {
			const isException = !field.values || field.values.length === 0;

			editingDispatch({
				type: 'update-custom-exception',
				field: field.key,
				value: isException
			});
		}
	}, [field, exception, setting.required]);

	const handleChange = useCallback(
		(val?: string | null) => {
			editingDispatch({
				field: 'customFields',
				type: 'update',
				value: {...editingState.customFields, [field.key]: val}
			});

			if (setting.required) {
				editingDispatch({type: 'update-custom-error', field: field.key});
			}
		},
		[editingState.customFields, field, setting.required]
	);

	return (
		<div className="custom-fields__text-custom-field">
			<EditableFieldLabel>
				{setting.required ? `${field.name} *` : field.name}
			</EditableFieldLabel>

			<TextField
				value={value}
				elementType="textarea"
				elementProps={textAreaProps}
				disabled={disabled}
				error={
					setting.required && hasError
						? t('components.editableFields.text.error')
						: undefined
				}
				onChange={handleChange}
			/>
		</div>
	);
};

export const CustomTextField = memo(CustomTextFieldComponent) as typeof CustomTextFieldComponent;
