import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {IState} from '../..';
import * as types from '../constants';
import {multipleLogout as apiMultipleLogout} from '@src/api/backend/auth';
import {deleteWebPushSubscription} from '@src/actions/notifications/webPushSubscriptions';
import {localAuthStorage} from '@tehzor/tools/api/localAuthStorage';
import {removeCache} from '@src/cache/actions/logout/clearCache';

export const multipleLogout =
	(): ThunkAction<Promise<void>, IState, null, Action> => async dispatch => {
		const deviceId = await localAuthStorage.getDeviceId();
		if (deviceId) {
			await dispatch(deleteWebPushSubscription(deviceId));
		}
		await apiMultipleLogout();
		removeCache();
		dispatch({type: types.LOGOUT});
		dispatch({type: types.CLEAR_STORE});
	};
