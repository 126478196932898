import useAppSelector from '@src/core/hooks/useAppSelector';
import {ContractorStats} from './ContractorStats';
import {useFilteredContractors} from '@src/pages/StatisticsPage/hooks/useFilteredContractors';
import StatsTreePlaceholder from '../../StatsTreePlaceholder';
import ProblemsStatsChart from './ProblemsStatsChart';
import {aggregateByContractors} from '@src/store/modules/statistics/warranty/selectors/problemsByStatuses';
import {areProblemsByStatusesStatsLoading} from '@src/store/modules/statistics/ui/selectors/warranty';
import {isWarrantyFiltersSetUp} from '@src/store/modules/settings/pages/statistics/warranty/selectors/filters';

const ByContractorsStats = () => {
	const statsByContractors = useAppSelector(aggregateByContractors);
	const filters = useAppSelector(s => s.settings.pages.statistics.warranty.filters);
	const isFiltersSetUp = useAppSelector(isWarrantyFiltersSetUp);
	const contractors = useFilteredContractors(statsByContractors, filters, isFiltersSetUp);
	const loading = useAppSelector(areProblemsByStatusesStatsLoading);

	return (
		<>
			{contractors.map(({contractor, users, stats, deletedUsers}) => (
				<ContractorStats
					key={contractor.id}
					contractor={contractor}
					contractorStats={stats}
					users={users}
					deletedUsers={deletedUsers}
				/>
			))}

			{loading && contractors.length === 0 && (
				<StatsTreePlaceholder
					includeChildren
					permanentContent={<ProblemsStatsChart/>}
				/>
			)}
		</>
	);
};

export default ByContractorsStats;
