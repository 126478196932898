import {openDB} from 'idb';
import {PersistedClient, Persister} from '@tanstack/react-query-persist-client';

const openQueryCacheDB = async () =>
	openDB('query-cache', 1, {
		upgrade(db) {
			if (!db.objectStoreNames.contains('cache')) {
				db.createObjectStore('cache');
			}
		}
	});

export const createIDBPersister = (idbValidKey: IDBValidKey = 'reactQuery') =>
	({
		persistClient: async (client: PersistedClient) => {
			const db = await openQueryCacheDB();
			// Этот код нужен для того, чтобы вычещать из персиста promise
			// В библиотеке react-query что то изменилось и когда мы используем опцию shouldDehydrateQuery,
			// персистор перестает дожидаться исполнения промиса
			const promiseExcludedClient = {
				...client,
				clientState: {
					...client.clientState,
					queries: await Promise.all(
						client.clientState.queries.map(async query => {
							const result = {...query, promise: await query.promise};
							delete result.promise;
							return result;
						})
					)
				}
			} as PersistedClient;
			const tx = db.transaction('cache', 'readwrite');
			await tx.objectStore('cache').put(promiseExcludedClient, idbValidKey);
			await tx.done;
		},
		restoreClient: async () => {
			const db = await openQueryCacheDB();
			const tx = db.transaction('cache', 'readonly');
			const client = await tx.objectStore('cache').get(idbValidKey);
			await tx.done;
			return client;
		},
		removeClient: async () => {
			const db = await openQueryCacheDB();
			const tx = db.transaction('cache', 'readwrite');
			await tx.objectStore('cache').delete(idbValidKey);
			await tx.done;
		}
	}) as Persister;
