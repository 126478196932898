import { memo } from 'react';
import './FilesCount.less';

const fixedIcon = <i className="tz-attach-16" />;

export const FilesCount = memo((props: {count?: number}) => {
	const {count} = props;

	if (!count) return null;

	return (
		<div className="files-counter">
			<div className="files-counter-icon">{fixedIcon}</div>
			<div className="files-counter-title">{count}</div>
		</div>
	);
});
