import {
	IEnrichedSchemaStructure,
	IEnrichedStructure
} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {IConvertedProblemsData} from '@tehzor/tools/interfaces/structures/IConvertedProblemsData';
import {IConvertedWorkAcceptancesData} from '@tehzor/tools/interfaces/structures/IConvertedWorkAcceptancesData';
import {ICheckRecordStatus} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {IStructuresFiltersState} from '@/entities/Structures';

export const structureFilter = (
	structure: IEnrichedStructure,
	filters: IStructuresFiltersState
) => {
	if (filters.types && filters.types.length) {
		if (!filters.types.includes(structure.type)) {
			return false;
		}
	}

	if (filters.problemStatuses && filters.problemStatuses.length) {
		if (
			!structure.problemsStats ||
			!structure.problemsStats.length ||
			!structure.problemsStats.some(
				(statusStat: IConvertedProblemsData) =>
					filters.problemStatuses?.includes(statusStat.key)
			)
		) {
			return false;
		}
	}

	if (filters.workAcceptanceStatuses && filters.workAcceptanceStatuses.length) {
		if (
			!structure.workAcceptancesStats ||
			!structure.workAcceptancesStats.length ||
			!structure.workAcceptancesStats.some(
				(statusStat: IConvertedWorkAcceptancesData) =>
					filters.workAcceptanceStatuses?.includes(statusStat.key)
			)
		) {
			return false;
		}
	}

	if (filters.categories && filters.categories.length) {
		if (!structure.categories) {
			return false;
		}

		const categories = structure.categories;
		if (
			!categories.length ||
			!categories.some((category: string) => filters.categories?.includes(category))
		) {
			return false;
		}
	}

	if (filters.checkListStatuses && filters.checkListStatuses.length) {
		if (
			!structure.checkLists ||
			!structure.checkLists.length ||
			!structure.checkLists.some(
				(checkList: {status: ICheckRecordStatus}) =>
					filters.checkListStatuses?.includes(checkList.status.id)
			)
		) {
			return false;
		}
	}

	if (filters.checkListIds && filters.checkListIds.length) {
		if (
			!structure.checkLists ||
			!structure.checkLists.length ||
			!structure.checkLists.some(
				(checkList: {key: string}) => filters.checkListIds?.includes(checkList.key)
			)
		) {
			return false;
		}
	}

	return true;
};

export const getFilteredStructures = (
	filters: IStructuresFiltersState,
	structures?: IEnrichedStructure[]
): IEnrichedStructure[] | undefined =>
	structures?.filter(structure => structureFilter(structure, filters));

export const getFilteredSchemaStructures = (
	filters: IStructuresFiltersState,
	structures?: IEnrichedStructure[]
): IEnrichedSchemaStructure[] | undefined =>
	structures?.map(structure => ({
		...structure,
		filtered: structureFilter(structure, filters)
	}));
