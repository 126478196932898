import {useMemo} from 'react';

function getRandomString() {
	const length = Math.max(10, Math.floor(Math.random() * 30));
	return '0'.repeat(length);
}

/**
 * Возвращает фиктивный список сущностей, для отображения во время загрузки
 *
 * @param includeImage включать плейсхолдер изображения
 * @param includeSubTitle включать плейсхолдер подзаголовка
 * @param includeChildren включать плейсхолдер кнопки раскрытия
 * @param count количество элементов
 */
export const useFakeItems = (
	includeImage?: boolean,
	includeSubTitle?: boolean,
	includeChildren?: boolean,
	count = 2
) =>
	useMemo(() => {
		const result = [];
		for (let i = 0; i < count; i++) {
			result.push({
				id: i.toString(),
				title: getRandomString(),
				subTitle: includeSubTitle ? getRandomString() : undefined,
				image: includeImage ? '0' : undefined
			});
		}
		return result;
	}, [includeSubTitle, includeImage, includeChildren, count]);
