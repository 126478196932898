interface IItem {
	parentId?: string;
	order: number;
}

/**
 * Получает order для нового элемента в списке
 * 
 * @param items массив элементов
 * @param parentId id родительского элемента
 */
export const getNewItemOrder = <S extends IItem> (
	items: S[],
	parentId?: string
): number => {
	const filteredItems = items.filter(item => item.parentId === parentId);
	const filteredOrders = filteredItems.map(item => item.order);

	const maxOrder = filteredOrders.length ? Math.max(...filteredOrders) : 0;
	return !isNaN(maxOrder) ? maxOrder + 1 : 0;
};