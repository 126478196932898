import {ExpiredFilterValues} from '@tehzor/tools/interfaces/expiration/IExpirationFilter';

export const expiredFilterOptions = [
	{
		id: ExpiredFilterValues.EXPIRED,
		name: 'Просрочено'
	},
	{
		id: ExpiredFilterValues.NOT_EXPIRED,
		name: 'Не просрочено'
	},
	{
		id: ExpiredFilterValues.UNSET,
		name: 'Без срока приёмки'
	}
];
