import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetCategoriesSetsResponse} from '@src/api/backend/categoriesSets';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {
	ICategoriesSetsFilters,
	ICategoriesSetsPageSettings,
	extractCategoriesSetById,
	extractCategoriesSetsAsArray,
	extractCategoriesSetsForPage,
	extractCommonCategoriesSetsAsArray,
	extractFilteredCategoriesSetsAsArray,
	extractFullCategoriesSetsAsArray,
	extractFullCategoriesSetsAsArrayByObjectId,
	extractFullCategoriesSetsData
} from './selectors';
import {useCallback} from 'react';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useExtractAllCategoriesAsArray} from '../categories/hook';

export const useCategoriesSets = <T = IGetCategoriesSetsResponse>(
	select?: (data: IGetCategoriesSetsResponse) => T
) =>
	useQuery<IGetCategoriesSetsResponse, IError, T>({
		queryKey: [...categoriesSetsQueryKeys.list()],
		meta: {
			error: 'при загрузке наборов видов работ'
		},
		select
	});

export const useExtractCategoriesSetById = (categoriesSetId?: string) => {
	const selector = useCallback(
		(data: IGetCategoriesSetsResponse) => extractCategoriesSetById(data, categoriesSetId),
		[categoriesSetId]
	);
	return useCategoriesSets(selector);
};

export const useExtractFullCategoriesSetsData = (allCategories?: Record<string, ICategory[]>) => {
	const selector = useCallback(
		(data: IGetCategoriesSetsResponse) => extractFullCategoriesSetsData(data, allCategories),
		[allCategories]
	);
	return useCategoriesSets(selector);
};

export const useExtractFullCategoriesSetsAsArray = () => {
	const {data: allCategories} = useExtractAllCategoriesAsArray();
	const selector = useCallback(
		(data: IGetCategoriesSetsResponse) => extractFullCategoriesSetsAsArray(data, allCategories),
		[allCategories]
	);
	return useCategoriesSets(selector);
};

export const useExtractFullCategoriesSetsAsArrayByObjectId = (
	objectId: string,
	allCategories: Record<string, ICategory[]> | undefined
) => {
	const selector = useCallback(
		(data: IGetCategoriesSetsResponse) =>
			extractFullCategoriesSetsAsArrayByObjectId(data, objectId, allCategories),
		[objectId, allCategories]
	);
	return useCategoriesSets(selector);
};

export const useExtractCategoriesSetsForPage = (
	objectId: string,
	settings: ICategoriesSetsPageSettings,
	allCategories?: Record<string, ICategory[]>
) => {
	const selector = useCallback(
		(data: IGetCategoriesSetsResponse) =>
			extractCategoriesSetsForPage(data, objectId, settings, allCategories),
		[objectId, settings, allCategories]
	);
	return useCategoriesSets(selector);
};

export const useExtractFilteredCategoriesSetsAsArray = (
	filters: ICategoriesSetsFilters,
	allCategories?: Record<string, ICategory[]>
) => {
	const selector = useCallback(
		(data: IGetCategoriesSetsResponse) =>
			extractFilteredCategoriesSetsAsArray(data, filters, allCategories),
		[filters, allCategories]
	);
	return useCategoriesSets(selector);
};

export const useExtractCategoriesSetsAsArray = (
	objectId: string,
	stage?: ObjectStageIds,
	allCategories?: Record<string, ICategory[]>
) => {
	const selector = useCallback(
		(data: IGetCategoriesSetsResponse) =>
			extractCategoriesSetsAsArray(data, objectId, stage, allCategories),
		[objectId, stage, allCategories]
	);
	return useCategoriesSets(selector);
};

export const useExtractCommonCategoriesSetsAsArray = (objectIds?: string[]) => {
	const selector = useCallback(
		(data: IGetCategoriesSetsResponse) => extractCommonCategoriesSetsAsArray(data, objectIds),
		[objectIds]
	);
	return useCategoriesSets(selector);
};
