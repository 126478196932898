export function getTextSelection(element: HTMLTextAreaElement): { start: number, end: number } {
	if (element.selectionStart !== undefined && element.selectionEnd !== undefined) {
		const start = element.selectionStart;
		const end = element.selectionEnd;
		return {start, end};
	}
	return {start: 0, end: 0};
}

export function setTextSelection(element: HTMLTextAreaElement, start: number, end: number) {
	if (element.selectionStart !== undefined && element.selectionEnd !== undefined) {
		element.focus();
		element.setSelectionRange(start, end);
	}
}
