import {queryClient} from '@src/api/QueryClient';
import {IGetCategoriesSetsResponse, requestAddCategoriesSet} from '@src/api/backend/categoriesSets';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';

export const useAddCategoriesSet = () =>
	useMutation<ICategoriesSet, IError, ISavingCategoriesSet>({
		mutationFn: (params: ISavingCategoriesSet) => requestAddCategoriesSet(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetCategoriesSetsResponse>(
				categoriesSetsQueryKeys.list(),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return {
						byId: {[data.id]: data},
						allIds: [data.id]
					};
				}
			);
			addSuccessToast('Успех', 'Набор видов работ добавлен');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении набора видов работ');
		}
	});
