import './renderItem.less';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import declination from '@tehzor/tools/utils/declination';
import {SelectOption} from '@tehzor/ui-components';

export const templateRenderItem = (item: IProblemTemplate) => {
	const dateText = `${item.fixDate} ${declination(item.fixDate || 0, ['день', 'дня', 'дней'])}`;
	return (
		<SelectOption
			dataTestId="MenuItem"
			key={item.id}
			itemKey={item.id}
			inputType="radio"
			content={
				<div className="template-item">
					<div className="template-item__description">{item.description}</div>
					<div className="template-item__wrapper">
						{item.categoryId !== undefined && (
							<div className="template-item__category">{item.categoryId}</div>
						)}
						<div className="template-item__info">
							{item.fixDate !== undefined && (
								<div className="template-item__date">{dateText}</div>
							)}
							{item.fixDate !== undefined && item.reason !== undefined && (
								<div className="template-item__separator" />
							)}
							{item.reason !== undefined && (
								<div className="template-item__reason">{item.reason?.value}</div>
							)}
						</div>
					</div>
				</div>
			}
		/>
	);
};
