import {EntityGrid, EntityGridItem, InlineButton, Plate} from '@tehzor/ui-components';
import {ProblemIndicators} from '../ProblemIndicators';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {TaskIndicators} from '@src/components/TaskIndicators/TaskIndicators';
import {useTranslation} from 'react-i18next';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';

const editIcon = <i className="tz-edit-20" />;
const percentIcon = <i className="tz-percent-20" />;
const unitIcon = <i className="tz-unit-20" />;
const problemIcon = <i className="tz-problem-20" />;
const taskIcon = <i className="tz-task-20" />;
const commentIcon = <i className="tz-comment-20" />;

interface IDesktopInfoProps {
	openDialog: () => void;
	unitString?: string;
	unitPlanString?: string;
	workAcceptance: IWorkAcceptance;
	permissions?: IPermissions;
}

export const WorkAcceptanceResult = ({
	openDialog,
	unitString,
	unitPlanString,
	workAcceptance,
	permissions
}: IDesktopInfoProps) => {
	const {t} = useTranslation();

	return (
		<Plate
			className={{
				root: 'work-acceptance-page__plate-card',
				content: 'work-acceptance-page__info-plate'
			}}
		>
			<EntityGrid withBorders>
				<EntityGridItem
					label={t('workAcceptancePage.info.result.label')}
					className="work-acceptance-page__d-column_result-title"
					fullRow
					buttons={
						permissions?.canEditResult ? (
							<InlineButton
								type="accent"
								leftIcon={editIcon}
								onClick={openDialog}
							/>
						) : null
					}
				/>
				<EntityGridItem
					icon={percentIcon}
					label={t('workAcceptancePage.info.volume.label')}
					fullRow
				>
					<div className="entity-grid-item__d-flex">
						<div
							className="entity-grid-item__fd-column semi-bold"
							style={{whiteSpace: 'pre-wrap'}}
						>
							<div className="entity-grid-item__label-param">
								{t('workAcceptancePage.info.plan')}
							</div>
							{workAcceptance.workScope || 0} %
						</div>
						<div
							className="entity-grid-item__fd-column entity-grid-item__fd-column-right semi-bold"
							style={{whiteSpace: 'pre-wrap'}}
						>
							<div className="entity-grid-item__label-param">
								{t('workAcceptancePage.info.physic')}
							</div>
							{workAcceptance.percent || 0} %
						</div>
					</div>
				</EntityGridItem>
				<EntityGridItem
					icon={unitIcon}
					label={t('workAcceptancePage.info.physicalVolume.label')}
					fullRow
				>
					<div className="entity-grid-item__d-flex">
						<div
							className="entity-grid-item__fd-column semi-bold"
							style={{whiteSpace: 'pre-wrap'}}
						>
							<div className="entity-grid-item__label-param">
								{t('workAcceptancePage.info.plan')}
							</div>
							{unitPlanString || 0}
						</div>
						<div
							className="entity-grid-item__fd-column entity-grid-item__fd-column-right semi-bold"
							style={{whiteSpace: 'pre-wrap'}}
						>
							<div className="entity-grid-item__label-param">
								{t('workAcceptancePage.info.physic')}
							</div>
							{unitString || 0}
						</div>
					</div>
				</EntityGridItem>

				<EntityGridItem
					icon={problemIcon}
					label={t('workAcceptancePage.info.defectsIdentified.label')}
					fullRow
				>
					<ProblemIndicators workAcceptanceId={workAcceptance.id} />
				</EntityGridItem>

				<EntityGridItem
					icon={taskIcon}
					label={t('workAcceptancePage.info.tasksCreated.label')}
					fullRow
				>
					<TaskIndicators workAcceptanceId={workAcceptance.id} />
				</EntityGridItem>

				<EntityGridItem
					icon={commentIcon}
					label={t('workAcceptancePage.info.comment.label')}
					fullRow
				>
					<div style={{whiteSpace: 'pre-wrap'}}>{workAcceptance.comment}</div>
				</EntityGridItem>
			</EntityGrid>
		</Plate>
	);
};
