export type IProblemViewsListQueryKey = [] & {
	0: string; //problemViews
	1: string; //list
	2: string; //objectId
	3: string; //problemId
};

export const problemViewsQueryKeys = {
	all: () => ['problemViews'],
	one: () => ['problemView'],
	list: () => [...problemViewsQueryKeys.all(), 'list'],
	detail: (objectId: string, problemId: string) => [
		...problemViewsQueryKeys.list(),
		objectId,
		problemId
	],
	add: () => [...problemViewsQueryKeys.one(), 'add']
};
