import {memo, useCallback, useEffect, useTransition} from 'react';
import {TabButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeType, togglePending} from '@src/store/modules/settings/pages/spaces/actions';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {useUpdateEffect} from 'react-use';
import {useSpacesSchemaTypes} from '@src/core/hooks/queries/spaces/typesCount/hooks';

interface ISpaceTypeButtonsProps {
	objectId: string;
	targetObjects: string[];
}

export const SpaceTypeButtons = memo(({objectId, targetObjects}: ISpaceTypeButtonsProps) => {
	const {data: types} = useSpacesSchemaTypes(targetObjects);
	const selectedType = useAppSelector(s => extractSpacesPageSettings(s, objectId).type);
	const dispatch = useAppDispatch();
	const [isPending, startTransition] = useTransition();

	// При переходе на страницу в первый раз (то есть нет сохраненного выбранного типа),
	// необходимо установить тип по умолчанию
	useEffect(() => {
		if (selectedType === undefined && types?.length) {
			dispatch(changeType(objectId, types[0][0].id));
		}
	}, [types, objectId]);

	useUpdateEffect(() => {
		dispatch(togglePending(isPending));
	}, [isPending]);

	const handleTypeSelection = useCallback(
		(type: string) => {
			startTransition(() => {
				dispatch(changeType(objectId, type));
			});
		},
		[objectId]
	);

	return types?.length ? (
		<div className="spaces-page__space-type-list">
			{types.map(([type, count]) => (
				<TabButton
					key={type.id}
					value={type.id}
					active={selectedType === type.id}
					className="spaces-page__space-type-list-item"
					label={`${type.name} ${count}`}
					onClick={handleTypeSelection}
				/>
			))}
		</div>
	) : null;
});
