import {forwardRef, Ref, useCallback, useImperativeHandle} from 'react';
import {useEditableMeterConsumption} from '@src/components/EditableMeterConsumption/hooks/useEditableMeterConsumption';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useAddMeterConsumption} from '@src/core/hooks/mutations/meterConsumptions/useAddMeterConsumption';
import {useTranslation} from "react-i18next";

interface IAddingMeterConsumptionProps {
	companyId?: string;
	meterId?: string;
	saving: boolean;

	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IAddingMeterConsumptionRefProps {
	save: () => void;
	cancel: () => void;
}

export const AddingMeterConsumption = forwardRef<
	IAddingMeterConsumptionRefProps,
	IAddingMeterConsumptionProps
>((props: IAddingMeterConsumptionProps, ref?: Ref<IAddingMeterConsumptionRefProps>) => {
	const {t} = useTranslation();
	const {companyId, meterId, saving, onClose, setSaving} = props;
	const addMeterConsumption = useAddMeterConsumption();

	const [fields, getSavingData, , isBlocking] = useEditableMeterConsumption(undefined, saving);

	const save = useCallback(() => {
		setSaving(true);
		if (companyId && meterId) {
			const savingData = getSavingData();
			if (savingData) {
				try {
					savingData.meterId = meterId;
					savingData.companyId = companyId;
					addMeterConsumption(savingData);
					onClose();
				} catch (error) {
					setSaving(false);
					throw error;
				}
			}
		}
		setSaving(false);
	}, [companyId, meterId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		t('components.addingMeterConsumption.useConfirm.title'),
		t('components.addingMeterConsumption.useConfirm.message'),
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('components.addingMeterConsumption.useConfirm.acceptBtnLabel'),
			rejectBtnLabel: t('components.addingMeterConsumption.useConfirm.rejectBtnLabel')
		}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	useImperativeHandle(ref, () => ({save, cancel}), [save, cancel]);

	return (
		<>
			{fields}
			{closingDialog}
		</>
	);
});
