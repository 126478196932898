import { useCallback } from 'react';
import {InternalAcceptancesPage} from './InternalAcceptancesPage';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/interfaces';
import ActionsMobile from './components/actions/Actions.mobile';
import {internalAcceptancesActions} from '@src/store/modules/settings/pages/internalAcceptances/slice';
import {useTranslation} from "react-i18next";

export const AllInternalAcceptancesPageWrap = (props: Record<string, unknown>) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {clearFilters, changeFilters, changeOffset} = internalAcceptancesActions;

	useAppHeader({title: t('internalAcceptancesPage.title'), mobileRightButtons: <ActionsMobile />});

	const {filters} = useAppSelector(s => extractInternalAcceptancesPageSettings(s, 'all'));

	const applyFilters = useCallback(
		(value: IInternalAcceptancesFiltersState) => {
			dispatch(changeFilters({objectId: 'all', filters: value}));
			dispatch(changeOffset({objectId: 'all', offset: 0}));
		},
		[changeFilters, changeOffset, dispatch]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: 'all'}));
	}, [clearFilters, dispatch]);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			onApply={applyFilters}
			filters={filters}
			onClear={onClear}
		>
			<InternalAcceptancesPage
				key="all-internalAcceptances"
				className="page-cont_extra-bottom-margin"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};
