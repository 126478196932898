import {wsConnector} from '../wsConnector';

/**
 * Удаляет структуру
 *
 * @param objectId id объекта
 * @param structureId id структуры
 */
export const makeStructureDeleteRequest = (objectId: string, structureId: string) =>
	wsConnector.sendAuthorizedRequest<void>('deleteStructure', {
		objectId,
		structureId
	});
