import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {IGetMeterTariffsResponse} from '@src/api/backend/meterTariff';
import {
	extractMeterTariffsAllIds,
	extractMeterTariffsAsArray,
	extractMeterTariffsById
} from './selectors';

export const useMeterTariffs = <T = IGetMeterTariffsResponse>(
	select?: (data: IGetMeterTariffsResponse) => T
) =>
	useQuery<IGetMeterTariffsResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.meterTariffs(),
		meta: {
			error: 'Ошибка при загрузке тарифов приборов учёта'
		},
		select
	});

export const useExtractMeterTariffsAsArray = () => useMeterTariffs(extractMeterTariffsAsArray);
export const useExtractMeterTariffsById = () => useMeterTariffs(extractMeterTariffsById);
export const useExtractMeterTariffsAllIds = () => useMeterTariffs(extractMeterTariffsAllIds);
