import {
	unstable_BlockerFunction as BlockerFunction,
	unstable_useBlocker as useBlocker,
	useNavigate
} from 'react-router-dom';
import {useEffect, useCallback} from 'react';

export const useNavigationPrevent = (isBlocking: boolean) => {
	const shouldBlock = useCallback<BlockerFunction>(
		({currentLocation, nextLocation}) =>
			isBlocking && currentLocation.pathname !== nextLocation.pathname,
		[isBlocking]
	);
	const blocker = useBlocker(shouldBlock);
	const navigate = useNavigate();
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (isBlocking) {
				event.preventDefault();
				event.returnValue = '';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isBlocking, blocker]);

	useEffect(() => {
		if (isBlocking && blocker.state === 'blocked') {
			const handleNavigation = () => {
				if (
					window.confirm(
						'Вы действительно хотите уйти? Все введённые данные будут потеряны.'
					)
				) {
					blocker.proceed();
					navigate(blocker.location.pathname);
				} else {
					blocker.reset();
				}
			};

			window.addEventListener('popstate', handleNavigation);

			return () => {
				window.removeEventListener('popstate', handleNavigation);
			};
		}
		return undefined;
	}, [isBlocking, blocker, navigate]);
};
