import {combineReducers} from 'redux';
import {tasksGeneralReducer} from './general';
import {tasksListReducer} from './list';
import {tasksScheduleReducer} from './schedule';

export const tasks = combineReducers({
	general: tasksGeneralReducer,
	list: tasksListReducer,
	schedule: tasksScheduleReducer
});
