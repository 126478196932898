import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableObjectAction, IEditableObjectState, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingObject} from '@src/interfaces/saving/ISavingObject';

export const useEditableObjectState = (defaultData?: ISavingObject) =>
	useReducer<Reducer<IEditableObjectState, IEditableObjectAction>, ISavingObject | undefined>(
		createReducer(init, errorsFns),
		defaultData,
		init
	);
