import {useDelegationDialog} from '@src/components/DelegationDialog/hooks/useDelegationDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {WorkAcceptanceTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {InlineButton} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IDelegationButtonProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	workAcceptanceType?: WorkAcceptanceTypeId;
	workAcceptanceFrontType?: WorkAcceptanceFrontTypeId;
	ruleParams?: IRuleParams;
	workingGroupType?: WorkingGroupTypeId;
	respUsers?: string[];
	author?: IBriefUser;
	activeGroup?: string;
	onlyActiveGroup?: boolean;
	inProgress?: boolean;
	required?: boolean;
	title?: string
	onChange?: (users: string[], group?: string) => void;
}

const delegateIcon = <i className="tz-delegate-20"/>;

export const DelegationButton = ({
	objectId,
	stage,
	scope,
	ruleParams,
	workAcceptanceType,
	workAcceptanceFrontType,
	respUsers,
	author,
	workingGroupType,
	activeGroup,
	onlyActiveGroup,
	inProgress,
	required,
	title,
	onChange
}: IDelegationButtonProps) => {
	const {t} = useTranslation();

	const label = inProgress
		? t('responsibles.delegationBtn.delegate.label')
		: t('responsibles.delegationBtn.redelegate.label');

	const [dialog, handleOpen] = useDelegationDialog({
		objectId,
		workingGroupType,
		stage,
		scope,
		workAcceptanceType,
		workAcceptanceFrontType,
		author,
		ruleParams,
		activeGroup,
		selectedUsers: respUsers,
		selectedGroup: activeGroup,
		onlyActiveGroup,
		required,
		title,
		onChange
	});

	return (
		<>
			<InlineButton
				className="responsibles-view-field__btn"
				type="accent"
				label={label}
				leftIcon={delegateIcon}
				onClick={handleOpen}
			/>
			{dialog}
		</>
	);
};
