import {forwardRef, Ref, CSSProperties, ReactNode} from 'react';
import './PLate.less';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';

export interface IPlateProps {
	className?:
		| string
		| {
				root?: string;
				header?: string;
				content?: string;
		  };
	style?: CSSProperties;
	children?: ReactNode;
	header?: ReactNode;
	title?: ReactNode;
	headerExtra?: ReactNode;
	withoutPadding?: boolean;
	id?: string;
}

const Plate = (props: IPlateProps, ref?: Ref<HTMLDivElement>) => {
	const {className, style, children, header, title, headerExtra, withoutPadding, id} = props;
	const classes = convertClassNames(className);

	return (
		<div
			className={classNames('plate', classes.root)}
			style={style}
			ref={ref}
			id={id}
		>
			{header ??
				((title !== undefined || headerExtra !== undefined) && (
					<div className={classNames('plate__header', classes.header)}>
						<div className="plate__title">{title}</div>
						<div className="plate__header-extra">{headerExtra}</div>
					</div>
				))}

			<div
				className={classNames(
					'plate__content',
					{'plate__content_no-padding': withoutPadding},
					classes.content
				)}
			>
				{children}
			</div>
		</div>
	);
};

Plate.displayName = 'Plate';

export default forwardRef(Plate);
