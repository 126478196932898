import {IGetMetersResponse} from '@src/api/backend/meters';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';

export const extractMetersData = (data: IGetMetersResponse): INormalizedData<IMeter> => data;

export const extractMetersAsArray = (data: IGetMetersResponse): IMeter[] => {
	const metersData = extractMetersData(data);
	return metersData.allIds.map((id: string) => metersData.byId[id]);
};

export const extractMetersAsArrayForSpace = (
	data: IGetMetersResponse,
	spaceId: string
): IMeter[] => {
	const meters = extractMetersAsArray(data);
	return meters.filter((meter: IMeter) => meter.spaceId === spaceId);
};

export const extractMetersById = (data: IGetMetersResponse): Record<string, IMeter> => {
	const metersData = extractMetersData(data);
	return metersData.byId;
};
