import {createContext, useContext} from 'react';

export interface TreeCtxType {
	isUserSelected: (userId: string, groupId: string | undefined) => boolean;
	isGroupSelected: (groupId: string) => boolean;
	isGroupExpanded: (groupId: string) => boolean;
	changeUserSelected: (userId: string, groupId: string | undefined, value: boolean) => void;
	changeGroupSelected: (groupId: string, value: boolean) => void;
	changeGroupExpanded: (groupId: string, value: boolean) => void;
}

export const TreeCtx = createContext<TreeCtxType>({
	isUserSelected: () => false,
	isGroupSelected: () => false,
	isGroupExpanded: () => false,
	changeUserSelected: () => null,
	changeGroupSelected: () => null,
	changeGroupExpanded: () => null
});

export const useTreeCtx = () => useContext(TreeCtx);
