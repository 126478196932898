import {DateRangePicker, FilterButton} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useCallback, useMemo} from 'react';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from 'react-i18next';
import {
	ICustomFilterDateValue,
	ICustomFiltersData
} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';
import {IConvertCustomFieldDate} from '@tehzor/tools/interfaces/fields';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';

interface ICustomFilterDateProps {
	fieldData: IConvertCustomFieldDate;
	filterValue: ICustomFilterDateValue;
	onChange: (data: ICustomFiltersData) => void;
	onClear: (key: string) => void;
}

export const CustomFilterDate = ({
	fieldData,
	filterValue = {from: undefined, to: undefined},
	onChange,
	onClear
}: ICustomFilterDateProps) => {
	const {t} = useTranslation();
	const locale = useDateFnsLocale();
	const {name, key, valueTypeId} = fieldData;
	const {from, to} = filterValue;

	const {fromAsDate, toAsDate} = useMemo(
		() => ({
			fromAsDate: from ? new Date(from) : undefined,
			toAsDate: to ? new Date(to) : undefined
		}),
		[from, to]
	);

	const handleChange = (dateFrom: Date | null, dateTo: Date | null) => {
		const dateFromValue = dateFrom ? dateFrom.getTime() : undefined;
		const dataToValue = dateTo ? dateTo.getTime() : undefined;
		onChange({
			[key]: {
				valueTypeId,
				value: {from: dateFromValue, to: dataToValue}
			}
		});
	};

	const handleClear = useCallback(() => {
		onClear(key);
	}, [onClear, key]);

	const trigger = (triggerProps: IDatePickerTriggerProps) => (
		<FilterButton
			className="entities-filters__item"
			label={makeDateFilterLabel(name, fromAsDate, toAsDate)}
			active={from !== undefined || to !== undefined}
			onClick={triggerProps.toggle}
			onClear={handleClear}
			ref={triggerProps.ref}
		/>
	);

	return (
		<DateRangePicker
			trigger={trigger}
			valueFrom={fromAsDate}
			valueTo={toAsDate}
			dateFormat="dd MMMM yyyy"
			datesOptions={{locale}}
			useApplyButton
			onChange={handleChange}
			selectApplyBtnLabel={t('applyBtn.label')}
			selectCancelBtnLabel={t('cancelBtn.label')}
		/>
	);
};
