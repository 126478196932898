const domain = 'pages/check-lists/records';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const CHECK_REQUEST = `${domain}/check/request`;
export const CHECK_SUCCESS = `${domain}/check/success`;
export const CHECK_FAILURE = `${domain}/check/failure`;

export const CHANGE_PERCENT_REQUEST = `${domain}/change-percent/request`;
export const CHANGE_PERCENT_SUCCESS = `${domain}/change-percent/success`;
export const CHANGE_PERCENT_FAILURE = `${domain}/change-percent/failure`;

export const CHANGE_STATUS_REQUEST = `${domain}/change-status/request`;
export const CHANGE_STATUS_SUCCESS = `${domain}/change-status/success`;
export const CHANGE_STATUS_FAILURE = `${domain}/change-status/failure`;
