import {OfflineDataItem} from '@tehzor/ui-components';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {useCacheStates} from '../hooks/useCacheStates';
import {useTranslation} from "react-i18next";

export const CategoriesCacheStatus = () => {
	const {t} = useTranslation();
	const [isFetching, isError, isStale, isPaused, dataUpdatedAt] = useCacheStates(
		categoriesQueryKeys.list()
	);
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t('components.offlineModeDialog.categoriesCacheStatus.offlineDataItem.title')}
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={isError ? t('cacheStatusErrors.error') : isStale ? t('cacheStatusErrors.stale') : undefined}
			inProgress={!!isFetching || isPaused}
			progressTitle={t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default')}
		/>
	);
};
