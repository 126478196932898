import {EntityGridItem, InlineButton} from '@tehzor/ui-components';
import classNames from 'classnames';
import { memo, useState } from 'react';
import {useObjectsMap} from '@src/core/hooks/queries/objects/hooks';

interface IObjectsCellProps {
	objectIds?: string[];
}

const NUMBER_OF_SHOWN = 2;

const objectsIcon = <i className="tz-outline-object-20" />;

export const ObjectsCell = memo(({objectIds}: IObjectsCellProps) => {
	const [isShown, setShown] = useState(false);
	const objectsMap = useObjectsMap();

	const handleClick = () => setShown(s => !s);

	if (!objectIds?.length || !objectsMap) {
		return null;
	}

	const objects = objectIds.map(id => objectsMap[id]?.name);

	const shownObjects = objects
		.filter((object, i) => (!isShown ? i + 1 <= NUMBER_OF_SHOWN : true))
		.join(', ');
	const numberOfRemainingObjects = objects.length > NUMBER_OF_SHOWN ? objects.length : 0;

	return (
		<EntityGridItem
			className={{
				root: 'contract__cells-item',
				header: 'contract__cells-item-header',
				content: classNames('contract__cells-objects', 'contract__cells-item-content')
			}}
			label="Объекты"
			icon={objectsIcon}
			inline
		>
			<div>{shownObjects}</div>
			{!!numberOfRemainingObjects && (
				<InlineButton
					label={!isShown ? `Показать все (${numberOfRemainingObjects})` : 'Скрыть'}
					type="accent"
					onClick={handleClick}
				/>
			)}
		</EntityGridItem>
	);
});
