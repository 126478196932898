import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {useCallback, useState} from 'react';
import * as React from 'react';
import {EditableResultWorkAcceptanceDialog} from '../EditableResultWorkAcceptanceDialog';
import {useQueryClient} from '@tanstack/react-query';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';

export function useEditableResultWorkAcceptanceDialog(
	objectId: string,
	stage: ObjectStageIds,
	workAcceptance: IWorkAcceptance
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);
	const queryClient = useQueryClient();

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const onSuccess = useCallback(() => {
		void queryClient.invalidateQueries({
			queryKey: workAcceptancesQueryKeys.listWithObjectId(objectId)
		});
	}, [queryClient, objectId]);

	const dialog = (
		<EditableResultWorkAcceptanceDialog
			objectId={objectId}
			stage={stage}
			workAcceptance={workAcceptance}
			isOpen={isOpen}
			onClose={close}
			onSuccess={onSuccess}
		/>
	);

	return [dialog, open];
}
