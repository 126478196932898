import useAppSelector from '@src/core/hooks/useAppSelector';
import {getFilteredStructures} from '@/features/Structures/useEnrichedStructures/utils/filterStructures';
import {useEnrichedStructures} from '@/features/Structures/useEnrichedStructures/hooks/useEnrichedStructures';
import {extractStructuresListPageSettings} from '@/entities/Structures/model/slice/selectors';

interface IUsePreparedPaginateStructuresProps {
	objectId: string;
	spaceId?: string;
}

export const usePreparedPaginateStructures = ({
	objectId,
	spaceId
}: IUsePreparedPaginateStructuresProps) => {
	const {filters, pageSize, offset} = useAppSelector(s =>
		extractStructuresListPageSettings(s, objectId)
	);
	const {data: enrichedStructures} = useEnrichedStructures({objectId, spaceId});
	const filteredStructures = getFilteredStructures(filters, enrichedStructures);

	const currentPage = Math.floor((offset ?? 0) / pageSize);

	const structures = filteredStructures?.slice(
		currentPage * pageSize,
		currentPage * pageSize + pageSize
	);
	return {structures, total: enrichedStructures ? enrichedStructures.length : 0, offset};
};
