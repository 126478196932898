import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IContractForm} from '@tehzor/tools/interfaces/spaces/IContractForm';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetContractFormsResponse = INormalizedData<IContractForm>;

/**
 * Возвращает список форм договоров
 */
export const requestContractForms = async () => {
	const response = await httpRequests.withToken.get<IGetContractFormsResponse>('contract-forms');
	return response.data;
};
