export enum CheckListTypeId {
	OPERATIONS = 'operations',
	OBJECTS = 'objects',
	ACCEPTANCES = 'acceptances'
}

export interface ICheckListType {
	id: CheckListTypeId;
	name: string;
	color?: string;
}
