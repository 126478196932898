export const exportTemplatesQueryKeys = {
	all: () => ['exports-templates'],
	one: () => ['exports-templates'],
	list: () => [...exportTemplatesQueryKeys.all(), 'list'],
	add: () => [...exportTemplatesQueryKeys.one(), 'add'],
	edit: () => [...exportTemplatesQueryKeys.one(), 'edit'],
	delete: () => [...exportTemplatesQueryKeys.one(), 'delete'],
	details: () => [...exportTemplatesQueryKeys.one(), 'detail'],
	detail: (templateId: string) => [...exportTemplatesQueryKeys.one(), 'detail', templateId]
};
