import {ActionButtons, Button, Dialog, EditableFieldLabel, Select2 as Select, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import classNames from 'classnames';
import { ReactNode, memo, useCallback, useMemo, useState } from 'react';
import {useUpdateEffect} from 'react-use';

interface IItem {
	id: string;
}

interface ISingleSelectContractFieldProps<S> {
	items?: S[];
	itemsMap?: Record<string, S>;
	fieldName?: string;

	className?: string;
	label?: string;
	dialogTitle?: string;
	errorMessage?: string;
	hasError?: boolean;
	required?: boolean;
	disabled?: boolean;
	isMobile?: boolean;
	value?: string | null;

	icon?: ReactNode;

	onConfirm: (value?: string | null) => void;
	onError: () => void;
}

const getTitle = <S extends IItem> (
	fieldName: string,
	value?: string | null,
	itemsMap?: Record<string, S>,
): string => {
	if (
		!value
		|| !itemsMap
		|| !itemsMap[value]
		|| !(fieldName in itemsMap[value])
	) { return ''; }

	const title = itemsMap[value][fieldName] as unknown;
	return typeof title === 'string' ? title : '';
};

const arrow = <i className="tz-simple-arrow-20"/>;

export const SingleSelectContractField = memo(<S extends IItem> ({
	items,
	itemsMap,
	fieldName = 'name',
	className,
	label,
	dialogTitle,
	errorMessage,
	hasError,
	required,
	disabled,
	isMobile,
	value,
	icon = arrow,
	onConfirm,
	onError
}: ISingleSelectContractFieldProps<S>) => {
	const [checked, setChecked] = useState(value);
	const [isOpen, setOpen] = useState(false);

	const [search, setSearch] = useState('');

	const clearSearch = () => setSearch('');

	useUpdateEffect(() => setChecked(value), [value]);

	const open = () => setOpen(true);
	const close = () => setOpen(false);

	const isSearchShown = (items?.length || 0) >= 8;

	const filteredItems = useMemo(() =>
		flatFilter(items || [], fieldName, search), [items, search]);

	const handleCancel = () => {
		setChecked(value);
		if (isMobile) {
			close();
		}
		clearSearch();
	};

	const handleConfirm = useCallback(() => {
		onConfirm(checked);
		if (required) {
			onError();
		}
		if (isMobile) {
			close();
		}

		clearSearch();
	}, [onConfirm, required, onError, clearSearch, isMobile]);

	const handleClearButton = useCallback(() => {
		onConfirm(null);
		if (required) {
			onError();
		}
		clearSearch();
	}, [onConfirm, required, onError, isMobile]);

	const trigger = (
		<TextFieldWithForwardedRef
			className="editable-contract__fields-single-select__text-field"
			elementType="div"
			value={getTitle(fieldName, value, itemsMap)}
			icon={icon}
			error={hasError ? errorMessage : undefined}
			disabled={disabled || !items?.length}
			onClearClick={handleClearButton}
			onClick={isMobile ? open : undefined}
			cleanable={!!value}
		/>
	);

	const content = (
		<Select
			value={checked || undefined}
			onChange={setChecked}
		>
			{filteredItems?.map(item => (
				<SelectOption
					className={{
						content: 'editable-contract__fields-single-select__content'
					}}
					key={item.id}
					itemKey={item.id}
					content={typeof item[fieldName] === 'string'
						? item[fieldName] as string : ''}
					inputType="radio"
				/>
			))}
		</Select>
	);

	const selectSearch = isSearchShown ? (
		<SelectSearch
			value={search}
			onChange={setSearch}
			onClear={clearSearch}
		/>
	) : null;

	const footer = (
		<div className="editable-contract__fields-single-select__dialog-footer">
			<ActionButtons
				className="editable-contract__fields-single-select__dialog-footer-btns"
			>
				<Button
					type="cancel"
					label="Отменить"
					onClick={handleCancel}
				/>
				<Button
					type="accent-blue"
					label="Сохранить"
					onClick={handleConfirm}
				/>
			</ActionButtons>
		</div>
	);

	return (
		<div
			className={classNames(
				'editable-contract__fields-single-select', className
			)}
		>
			<EditableFieldLabel
				className="editable-contract__fields-single-select__label"
			>
				{required ? `${label} *` : label}
			</EditableFieldLabel>
			{!isMobile ? (
				<SelectPopup
					noHeader={!isSearchShown}
					search={selectSearch}
					footer
					onCancel={handleCancel}
					onApply={handleConfirm}
					trigger={trigger}
				>
					{content}
				</SelectPopup>
			) : trigger}
			{isMobile && (
				<Dialog
					isOpen={isOpen}
					className="editable-contract__fields-single-select__dialog"
					title={required ? `${dialogTitle} *` : dialogTitle}
					onRequestClose={close}
					footer={footer}
				>
					<div className="editable-contract__fields-single-select__dialog-search">
						{selectSearch}
					</div>
					{content}
				</Dialog>
			)}
		</div>
	);
});