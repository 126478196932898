import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {makeDateFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {IContractsFiltersState} from '@src/store/modules/settings/pages/contracts/reducers';
import {longDateFormat} from '@tehzor/tools/utils/dateFormats';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';
import { memo } from 'react';

interface ISigningDateFilterProps {
	from?: Date;
	to?: Date;
}

export const SigningDateFilter = memo(({
	from, to
}: ISigningDateFilterProps) => {
	const {dispatch} = useEntitiesFiltersCtx<IContractsFiltersState>();

	const handleChange = (f: Date | null, t: Date | null) => {
		const signingDateFrom = f
			? new Date(f.getFullYear(), f.getMonth(), f.getDate())
			: undefined;
		const signingDateTo = t
			? new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999)
			: undefined;

		dispatch({signingDateFrom, signingDateTo});
	};

	const handleClear = () => {
		dispatch({signingDateFrom: undefined, signingDateTo: undefined});
	};

	return (
		<DateRangePicker
			trigger={props => (
				<FilterButton
					className="entities-filters__item"
					label={makeDateFilterLabel('Дата заключения', from, to)}
					active={from !== undefined || to !== undefined}
					onClick={props.toggle}
					onClear={handleClear}
					ref={props.ref}
				/>
			)}
			valueFrom={from}
			valueTo={to}
			dateFormat={longDateFormat}
			useApplyButton
			onChange={handleChange}
		/>
	);
});