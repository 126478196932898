import {PrivateRoute} from '@src/containers/PrivateRoute';
import {ContractsFiltersRoutingPage} from '@src/pages/filters/ContractsFiltersRoutingPage';
import {ContractsPageWrap} from '@src/pages/ContractsPage/wraps/ContractsPageWrap';
import {contractsFiltersRoutes} from './contractsFiltersRoutes';
import {ContractPageWrap} from '@src/pages/ContractPage/wraps/ContractPageWrap';

export const contractsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<ContractsPageWrap/>}/>
	},
	{
		path: ':contractId',
		element: <PrivateRoute element={<ContractPageWrap/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<ContractsFiltersRoutingPage/>}/>,
		children: contractsFiltersRoutes
	}
];
