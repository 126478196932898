import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';

export const ResponsibleUsersCell = ({
	value: task
}: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'value'>) => {
	const {data: usersMap} = useUsersAsMap();
	const {data: groupsMap} = useWorkingGroupsAsMap();

	const getLeaderWorkGroupName = (workGroupId?: string): string | undefined => {
		if (!workGroupId || !usersMap || !groupsMap) return undefined;
		const leaderId = groupsMap[workGroupId]?.leader;
		return usersMap[leaderId]?.displayName;
	};

	return (
		<div>
			{task?.respUsers && task?.respUsers?.length > 0 ? (
				task.respUsers.map(user => <div key={user?.id}>{user?.displayName}</div>)
			) : task?.activeGroup ? (
				<div>{getLeaderWorkGroupName(task.activeGroup)}</div>
			) : null}
		</div>
	);
};
