import { cloneElement, useCallback, useState } from 'react';
import * as React from 'react';
import {SelectMenuItemCtx} from '../Menu/utils/SelectMenuItemCtx';
import {IMenuItemProps} from '../MenuItem';

export interface IMultipleMenuProps {
	className?: string;
	style?: React.CSSProperties;
	value?: string[];
	defaultValue?: string[];
	children?: React.ReactElement<IMenuItemProps> | Array<React.ReactElement<IMenuItemProps>>;

	onChange?: (keys: string[]) => void;
}

const MultipleMenu = (props: IMultipleMenuProps) => {
	const {className, style, value, defaultValue, children, onChange} = props;
	const [localValue, setLocalValue] = useState<string[]>(defaultValue ?? []);

	const currentValue = value || localValue;

	const handleSelect = useCallback(
		(key: string) => {
			let newValue = [];
			if (currentValue.includes(key)) {
				newValue = currentValue.filter(v => v !== key);
			} else {
				newValue = currentValue.concat(key);
			}
			if (value === undefined) {
				setLocalValue(newValue);
			}
			if (onChange) {
				onChange(newValue);
			}
		},
		[value, currentValue, onChange]
	);

	let items = null;
	if (children) {
		if (Array.isArray(children)) {
			items = children.map((item, index) => cloneElement(item, {
					key: index,
					selectable: true,
					selected: item.props.itemKey !== undefined && currentValue.includes(item.props.itemKey)
				}));
		} else {
			items = cloneElement(children, {
				key: 0,
				selectable: true,
				selected: children.props.itemKey !== undefined && currentValue.includes(children.props.itemKey)
			});
		}
	}

	return (
		<div
			className={className}
			style={style}
		>
			<SelectMenuItemCtx.Provider value={handleSelect}>{items}</SelectMenuItemCtx.Provider>
		</div>
	);
};

MultipleMenu.displayName = 'MultipleMenu';

export default MultipleMenu;
