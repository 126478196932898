import {wsConnector} from '../wsConnector';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ISavingCheckRecord} from '@tehzor/tools/interfaces/checkRecords/ISavingCheckRecord';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IEditCheckRecordResponse = ICheckRecord;

/**
 * Обновляет запись по категории
 *
 * @param objectId id объекта
 * @param recordId id записи
 * @param stage стадия
 * @param fields данные для обновления
 */
export const makeCheckRecordEditRequest = (
	objectId: string,
	recordId: string,
	stage: ObjectStageIds,
	fields: ISavingCheckRecord
) =>
	wsConnector.sendAuthorizedRequest<IEditCheckRecordResponse>('editCheckRecord', {
		objectId,
		recordId,
		stage,
		...fields
	});
