import {CellProps} from 'react-table';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import ProblemsCount from './ProblemsCount';

const ObjectStatsCell = ({row}: CellProps<IParentObject>) => {
	const object = row.original;

	return <ProblemsCount object={object}/>;
};

export default ObjectStatsCell;
