import {useCheckRecordStatusesQueryDefaults} from './useCheckRecordStatusesQueryDefaults';
import {useObjectStagesQueryDefaults} from './useObjectStagesQueryDefaults';
import {useOwnerAcceptanceResolutionsQueryDefaults} from './useOwnerAcceptanceResolutionsQueryDefaults';
import {useOwnerAcceptanceStatusesQueryDefaults} from './useOwnerAcceptanceStatusesQueryDefaults';
import {useProblemStatusesQueryDefaults} from './useProblemStatusesQueryDefaults';
import {useProblemTemplatesSetsQueryDefaults} from './useProblemTemplatesSetsQueryDefaults';
import {useSpaceIndicatorsSetsQueryDefaults} from './useSpaceIndicatorsSetsQueryDefaults';
import {useSpaceTypesQueryDefaults} from './useSpaceTypesQueryDefaults';
import {useUsersQueryDefaults} from './useUsersQueryDefaults';
import {useWarrantyClaimStatusesQueryDefaults} from './useWarrantyClaimStatusesQueryDefaults';
import {useTaskStatusesQueryDefaults} from './useTaskStatusesQueryDefaults';
import {useTaskTypesQueryDefaults} from './useTaskTypesQueryDefaults';
import {useExportTemplatesDestinationsQueryDefaults} from './useExportTemplatesDestination';
import {useTaskPrioritiesQueryDefaults} from './useTaskPrioritiesQueryDefault';
import {useStructureTypesQueryDefaults} from './useStructureTypesQueryDefaults';
import {useWorkAcceptanceTypesQueryDefaults} from './useWorkAcceptanceTypesQueryDefaults';
import {useWorkAcceptanceFrontTypesQueryDefaults} from './useWorkAcceptanceFrontTypesQueryDefaults';
import {useWorkAcceptanceStatusesQueryDefaults} from './useWorkAcceptanceStatusesQueryDefaults';
import {useWorkingGroupsTypesQueryDefaults} from './useWorkingGroupsTypesQueryDefaults';
import {useMeterTypesQueryDefaults} from './useMeterTypesQueryDefaults';
import {useMeterTariffsQueryDefaults} from './useMeterTariffsQueryDefaults';

export const useRestDictionariesCacheDefaults = () => {
	useObjectStagesQueryDefaults();
	useOwnerAcceptanceResolutionsQueryDefaults();
	useOwnerAcceptanceStatusesQueryDefaults();
	useProblemStatusesQueryDefaults();
	useProblemTemplatesSetsQueryDefaults();
	useSpaceIndicatorsSetsQueryDefaults();
	useSpaceTypesQueryDefaults();
	useUsersQueryDefaults();
	useWarrantyClaimStatusesQueryDefaults();
	useCheckRecordStatusesQueryDefaults();
	useTaskPrioritiesQueryDefaults();
	useTaskStatusesQueryDefaults();
	useTaskTypesQueryDefaults();
	useExportTemplatesDestinationsQueryDefaults();
	useStructureTypesQueryDefaults();
	useWorkAcceptanceTypesQueryDefaults();
	useWorkAcceptanceFrontTypesQueryDefaults();
	useWorkAcceptanceStatusesQueryDefaults();
	useWorkingGroupsTypesQueryDefaults();
	useMeterTypesQueryDefaults();
	useMeterTariffsQueryDefaults();
};
