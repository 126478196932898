import {makeCheckListExportRequest} from '@src/api/backend/checkList/export';
import {addExportToast} from '@src/components/ExportToast/addExportToast';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';

interface IExportCheckParams {
	templateId: string;
	objectId: string;
	spaceId: string;
	checkListId: string;
	problems?: string[];
	createDocument?: boolean;
	email?: string;
	type: 'export' | 'send';
}

const mutationFn = (params: IExportCheckParams) => {
	const {templateId, objectId, spaceId, checkListId, problems, createDocument, email} = params;

	return makeCheckListExportRequest(
		templateId,
		objectId,
		spaceId,
		checkListId,
		problems,
		createDocument,
		email
	);
};

const onSuccess = (response: IExportResponse, params: IExportCheckParams) => {
	if (params.type === 'export' && response.link) {
		addExportToast(
			'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document
		);
	} else if (params.type === 'send') {
		addInfoToast('Отправлено', 'Документ успешно отправлен');
	}
};

const onError = (_: IError, params: IExportCheckParams) => {
	const errorMessage = params.type === 'export' ? 'при экспорте' : 'при отправке';
	addErrorToast('Ошибка', errorMessage);
};

export const useExportCheckList = () =>
	useMutation<IExportResponse, IError, IExportCheckParams>({
		mutationFn,
		onSuccess,
		onError
	});
