const domain = 'check';

export const GET_REQUEST = `${domain}/get/request`;

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const GET_PROBLEM_REPLIES_REQUEST = `${domain}/problem-replies/get/request`;
export const GET_PROBLEM_REPLIES_SUCCESS = `${domain}/problem-replies/get/success`;
export const GET_PROBLEM_REPLIES_FAILURE = `${domain}/problem-replies/get/failure`;
