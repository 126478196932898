import * as React from 'react';
import './TableHeadCell.less';
import classNames from 'classnames';

interface ITableCellProps {
	className?: string;
	style?: React.CSSProperties;
	role?: string;
	children?: React.ReactNode;
	canSort?: boolean;
	isSorted?: boolean;
	isSortedDesc?: boolean;
	showBorders?: boolean;

	onClick?: (event: React.MouseEvent) => void;
}

const TableHeadCell = (props: ITableCellProps) => {
	const {className, style, role, children, canSort, isSorted, isSortedDesc, showBorders, onClick} = props;

	return (
		<div
			className={classNames('table-head-cell', {'table-cell_with-border': showBorders}, className)}
			style={style}
			role={role}
			onClick={canSort ? onClick : undefined}
		>
			<div className="table-head-cell__children">{children}</div>
			{isSorted && (
				<i
					className={classNames(
						'tz-table-sort-20',
						'table-head-cell__sort-icon',
						`table-head-cell__sort-icon_${isSortedDesc ? 'desc' : 'asc'}`
					)}
				/>
			)}
		</div>
	);
};

export default TableHeadCell;