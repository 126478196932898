import {CellProps} from 'react-table';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";
import {useTranslatedDictionaryMap} from "@src/core/hooks/translations/useTranslatedDictionaryMap";

const SpaceNameCell = ({row}: CellProps<IConvertedSpace>) => {
	const space = row.original;
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const translatedSpaceTypes = useTranslatedDictionaryMap(dictionaryKeys.spaceTypes, spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return (
		<>{formSpaceTitle(space.name, space.altName, translatedSpaceTypes?.[space.type], altNamesVisible, true)}</>
	);
};

export default SpaceNameCell;
