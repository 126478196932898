import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';

export interface IEditableTypeDecoration {
	id?: string;
	name: string;
	typeDecorationSetId: string;
	spaceTypes: SpaceTypeId[];
}

export type IEditableTypeDecorationSetState = IEditableEntityState<{
	name?: string;
	companyId?: string;
	objects?: string[];
	data?: IEditableTypeDecoration[];
}>;

export type IEditableTypeDecorationAction = IEditableEntityAction<
	IEditableTypeDecorationSetState,
	{typeDecorationSet?: ISpaceTypeDecorationSet; typeDecoration?: ISpaceTypeDecoration[]}
>;

const makeEmptyState = (): IEditableTypeDecorationSetState => ({
	name: '',
	companyId: '',
	objects: [],
	data: [],
	errors: {
		name: false,
		companyId: false,
		objects: false,
		data: false
	}
});

export const init = ({
	typeDecorationSet,
	typeDecoration
}: {
	typeDecorationSet?: ISpaceTypeDecorationSet;
	typeDecoration?: ISpaceTypeDecoration[];
}): IEditableTypeDecorationSetState => {
	const empty = makeEmptyState();

	if (!typeDecorationSet) {
		return empty;
	}

	// Создает массивы типов отделки используется такой подход т.к. добавляемые типы отделки без Id, а изменяемые с Id
	const mapTypeDecoration = (typeDec: ISpaceTypeDecoration[]) =>
		typeDec.map(el => {
			const obj = {} as ISpaceTypeDecoration;
			Object.keys(el).forEach(key => {
				obj[key] = el[key];
			});
			return obj;
		});

	return {
		name: typeDecorationSet.name,
		companyId: typeDecorationSet.companyId,
		objects: typeDecorationSet.objects,
		data: typeDecoration?.length ? mapTypeDecoration(typeDecoration) : [],
		errors: empty.errors
	};
};

export const isEdited = (
	state: IEditableTypeDecorationSetState,
	original?: ISpaceTypeDecorationSet | {data: ISpaceTypeDecoration[]}
): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'companyId'),
		isPropEdited.bind(null, 'objects'),
		isPropEdited.bind(null, 'data')
	);

export const errorsFns = {
	name: (state: IEditableTypeDecorationSetState) => !state.name,
	companyId: (state: IEditableTypeDecorationSetState) => !state.companyId,
	objects: (state: IEditableTypeDecorationSetState) => !state.objects?.length,
	data: (state: IEditableTypeDecorationSetState) => !state.data?.length
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertSpaceTypeDecorationSetToSave = (
	state: IEditableTypeDecorationSetState,
	original?: ISpaceTypeDecorationSet,
	onlyEdited?: boolean
): ISavingSpaceTypeDecorationSet => {
	if (!onlyEdited) {
		return state as ISavingSpaceTypeDecorationSet;
	}

	const typeDecorationSet: ISavingSpaceTypeDecorationSet = {};

	if (isPropEdited('name', state, original)) {
		typeDecorationSet.name = state.name;
	}

	if (isPropEdited('companyId', state, original)) {
		typeDecorationSet.companyId = state.companyId;
	}

	if (isPropEdited('objects', state, original)) {
		typeDecorationSet.objects = state.objects;
	}

	return typeDecorationSet;
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertSpaceTypeDecorationToSave = (
	state: IEditableTypeDecorationSetState,
	original?: ISpaceTypeDecoration[],
	onlyEdited?: boolean
): IEditableTypeDecoration[] => {
	if (!onlyEdited) {
		return (state?.data as IEditableTypeDecoration[]) || [];
	}

	const typeDecoration: IEditableTypeDecoration[] = [];

	if (isPropEdited('data', state, {data: original})) {
		typeDecoration.push(...((state.data as IEditableTypeDecoration[]) || []));
	}

	return typeDecoration;
};
