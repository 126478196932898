import {ButtonType} from '../Button';

export const createButtonTestId = (type: ButtonType, dataTestId?: string): string | undefined => {
	if (dataTestId) return dataTestId;

	switch (type) {
		case 'accent-blue':
			return 'BaseButton';
		case 'accent-red':
			return 'AccentRedButton';
		case 'cancel':
			return 'BaseButton';
		case 'common':
			return 'BaseButton';
		case 'common-bordered':
			return 'CommonBordered';
		default:
			return undefined;
	}
};
