import {Column} from 'react-table';
import {FieldDescriptionCell} from '../../ui/cells/FieldDescriptionCell/FieldDescriptionCell';
import {FieldAddedCell} from '../../ui/cells/FieldAddedCell/FieldAddedCell';
import {FieldModifiedCell} from '../../ui/cells/FieldModifiedCell/FieldModifiedCell';
import {FieldObjectsCell} from '../../ui/cells/FieldObjectsCell/FieldObjectsCell';
import {FieldStagesCell} from '../../ui/cells/FieldStagesCell/FieldStagesCell';
import {DeleteCell} from '../../ui/cells/DeleteCell/DeleteCell';
import {useMemo} from 'react';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {FieldCompanyCell} from '../../ui/cells/FieldCompanyCell/FieldCompanyCell';
import {MobileCell} from '../../ui/cells/MobileCell/MobileCell';

export const getDesktopColumns = (): Array<Column<IPreparedFieldSettingsSet>> => {
	const nameColumn: Column<IPreparedFieldSettingsSet> = {
		id: 'name',
		Header: 'Набор',
		Cell: FieldDescriptionCell,
		accessor: item => item.name,
		width: 150,
		minWidth: 150
	};

	const companyColumn: Column<IPreparedFieldSettingsSet> = {
		id: 'company',
		Header: 'Компания',
		Cell: FieldCompanyCell,
		accessor: item => item.company?.name,
		width: 120,
		minWidth: 80
	};

	const objectsColumn: Column<IPreparedFieldSettingsSet> = {
		id: 'objectIds',
		Header: 'Объекты',
		accessor: item => item,
		Cell: FieldObjectsCell,
		width: 120,
		minWidth: 80
	};

	const stagesColumn: Column<IPreparedFieldSettingsSet> = {
		id: 'stages',
		Header: 'Стадии',
		accessor: item => item.stageIds,
		Cell: FieldStagesCell,
		width: 120,
		minWidth: 80
	};

	const entityTypeColumn: Column<IPreparedFieldSettingsSet> = {
		id: 'entityType',
		Header: 'Тип сущности',
		accessor: item => item.entityType.name,
		width: 120,
		minWidth: 80
	};

	const createdColumn: Column<IPreparedFieldSettingsSet> = {
		id: 'createdAt',
		accessor: item => item,
		Header: 'Добавлено',
		Cell: FieldAddedCell,
		width: 100
	};

	const modifiedColumn: Column<IPreparedFieldSettingsSet> = {
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: item => item,
		Cell: FieldModifiedCell,
		width: 100
	};

	const deleteColumn: Column<IPreparedFieldSettingsSet> = {
		id: 'delete',
		Cell: DeleteCell,
		Header: '',
		width: 70,
		disableResizing: true,
		isNonClickable: true
	};

	return [
		nameColumn,
		companyColumn,
		objectsColumn,
		stagesColumn,
		entityTypeColumn,
		createdColumn,
		modifiedColumn,
		deleteColumn
	];
};

const getMobileColumns = (): Array<Column<IPreparedFieldSettingsSet>> => {
	const mobileColumns = {
		id: 'object',
		Cell: MobileCell
	};
	return [mobileColumns];
};

export const useColumns = (isTablet: boolean) =>
	useMemo(() => (!isTablet ? getMobileColumns() : getDesktopColumns()), [isTablet]);
