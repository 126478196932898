import IUser from '@tehzor/tools/interfaces/IUser';
import {IFullSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/IFullSpaceStatusesSet';
import {findPermission} from '@tehzor/tools/utils/findPermission';

export const getPermissions = (
	spaceStatuseSet: IFullSpaceStatusesSet,
	user?: IUser
) => {
	const deletePermission = findPermission(
		user?.roles || [],
		'spaceStatusesSetsDelete'
	);
	const canDelete = (deletePermission && !spaceStatuseSet.default) || false;

	return {canDelete};
};