import * as types from '../constants';

/**
 * Изменяет путь активного раздела статистики
 *
 * @param value путь активного раздела
 */
export const changeSection = (value: string) => ({
	type: types.CHANGE_SECTION,
	payload: value
});
