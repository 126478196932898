import {IGetBuiltinFieldsResponse} from '@src/api/backend/fields';
import {fieldsQueryKeys} from '@src/api/cache/fields/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractBuiltinFieldById,
	extractBuiltinFieldsArray,
	extractBuiltinFieldsMap
} from '../selectors';
import {useFieldsPermissions} from '@/entities/FieldSettingsSets/model/hooks/useFieldsPermissions';

export const useBuiltinFields = <T = IGetBuiltinFieldsResponse>(
	select?: (data: IGetBuiltinFieldsResponse) => T
) => {
	const permissions = useFieldsPermissions();

	return useQuery<IGetBuiltinFieldsResponse, IError, T>({
		queryKey: fieldsQueryKeys.builtinLists(),
		meta: {
			error: 'при загрузке списка пользовательских полей'
		},
		select,
		enabled: permissions.canViewFields
	});
};

export const useBuiltinFieldsMap = () => useBuiltinFields(extractBuiltinFieldsMap);

export const useBuiltinFieldsArray = () => useBuiltinFields(extractBuiltinFieldsArray);

export const useBuiltinFieldById = (builtinFieldId: string) =>
	useBuiltinFields(data => extractBuiltinFieldById(data, builtinFieldId));
