import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {IGetCheckRecordStatusesResponse} from '@src/api/backend/checkRecordStatuses';
import {TFunction} from 'i18next';

const textColor = '#23354B';
const notCheckedColor = '#D9DDE3';

export const createCheckRecordStatusDifference = (
	prev: string | undefined,
	next: string | undefined,
	label: string | undefined,
	statuses: IGetCheckRecordStatusesResponse,
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev: prev
		? {
				name: statuses.byId[prev].name,
				color: statuses.byId[prev].color,
				textColor
		  }
		: {
				name: 'Не осмотрено',
				color: notCheckedColor,
				textColor
		  },
	next: next
		? {
				name: statuses.byId[next].name,
				color: statuses.byId[next].color,
				textColor
		  }
		: undefined,
	type: HistoryTypeId.STATUS,
	name: t('createDifference.createCheckRecordStatusDifference', {label})
});
