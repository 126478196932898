import {wsConnector} from '../wsConnector';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ISavingWorkAcceptance} from '@src/interfaces/saving/ISavingWorkAcceptance';

export type IEditWorkAcceptanceResultResponse = IWorkAcceptance;

/**
 * Изменяет результат приёмки работ
 */
export const makeWorkAcceptanceResultEditRequest = (
	objectId: string,
	workAcceptanceId: string,
	data: ISavingWorkAcceptance
) =>
	wsConnector.sendAuthorizedRequest<IEditWorkAcceptanceResultResponse>(
		'editWorkAcceptanceResult',
		{
			objectId,
			workAcceptanceId,
			...data
		}
	);
