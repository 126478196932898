import { Fragment } from 'react';
import './ProblemStatusesTooltip.less';

interface IStatusesTooltipProps {
	active: boolean;
	payload?: Array<{name: string, value: number}>;
}

const sortFn = (a: {value: number}, b: {value: number}) => b.value - a.value;

const ProblemStatusesTooltip = ({active, payload}: IStatusesTooltipProps) =>
	(active ? (
		<div className="problem-statuses-tooltip">
			{payload?.sort(sortFn).map((item, index) => (
				<Fragment key={index}>
					<div className="problem-statuses-tooltip__value">{item.value}</div>
					<div className="problem-statuses-tooltip__name">{item.name}</div>
				</Fragment>
			))}
		</div>
	) : null);

export default ProblemStatusesTooltip;
