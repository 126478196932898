import {Button, IconButton} from '@tehzor/ui-components';
import {useInternalAcceptancesExport} from '@src/core/hooks/export/useInternalAcceptancesExport';

interface IDesktopInternalAcceptancesExportProps {
	objectId?: string;
}

const DesktopInternalAcceptancesExport = ({objectId}: IDesktopInternalAcceptancesExportProps) => {
	const [exportDialog, openExportDialog] = useInternalAcceptancesExport(objectId);

	return (
		<>
			{objectId ? (
				<IconButton
					type="accent-blue"
					onClick={openExportDialog}
				>
					<i className="tz-export-20"/>
				</IconButton>
			) : (
				<Button
					type="accent-blue"
					label="Экспорт"
					leftIcon={<i className="tz-export-20"/>}
					onClick={openExportDialog}
				/>
			)}
			{exportDialog}
		</>
	);
};

export default DesktopInternalAcceptancesExport;
