import {getDesktopColumns} from '@src/pages/ProblemsPage/utils/getDesktopColumns';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {getMobileColumns} from '@src/pages/ProblemsPage/utils/getMobileColumns';
import {useColumnsWithSettings} from '@src/core/hooks/settings/useColumnsWithSettings/useColumnsWithSettings';
import {TFunction} from 'i18next';
import {Column} from 'react-table';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {useCallback, useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ITableSettings} from '@src/interfaces/ITableSettings';

interface IUseProblemTableColumnsProps {
	objectId: string;
	settings?: IEntitySettings;
	isDesktop: boolean;
	t: TFunction;
}
export const useProblemsTableColumns = ({
	objectId,
	settings,
	isDesktop,
	t
}: IUseProblemTableColumnsProps): [Array<Column<IProblemEntity>>, number] => {
	const dispatch = useAppDispatch();

	const {tableSettings} = useAppSelector(s => extractProblemsPageSettings(s, objectId));
	const {changeTableSettings} = problemsActions;

	const onChangeTableSettings = useCallback(
		(newTableSettings: ITableSettings) => {
			dispatch(changeTableSettings({objectId, tableSettings: newTableSettings}));
		},
		[dispatch, changeTableSettings, objectId]
	);

	const customizedColumns = useMemo(
		() =>
			isDesktop
				? getDesktopColumns(objectId, t, settings)
				: getMobileColumns(objectId, settings),
		[isDesktop, objectId, settings, t]
	);

	return useColumnsWithSettings({
		customizedColumns,
		entitySettings: settings,
		tableSettings,
		onChangeTableSettings
	});
};
