import fingerprint from 'fingerprintjs2';

/**
 * Генерирует fingerprint для текущего устройства
 */
const generateFingerprint = async () => {
	const options = {
		excludes: {
			canvas: true,
			webgl: true,
			webglVendorAndRenderer: true,
			fonts: true,
			audio: true
		}
	};
	const components: any[] = await new Promise(resolve => {
		// @ts-ignore
		if (window.requestIdleCallback) {
			// @ts-ignore
			window.requestIdleCallback(() => {
				fingerprint.get(options, resolve);
			});
		} else {
			setTimeout(() => {
				fingerprint.get(options, resolve);
			}, 500);
		}
	});
	const values = components.map(component => component.value);
	return fingerprint.x64hash128(values.join(''), 31);
};

export default generateFingerprint;
