import {IWorkAcceptanceType} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import { httpRequests } from '@tehzor/tools/api/httpRequests';

export type IGetWorkAcceptanceTypesResponse = INormalizedData<IWorkAcceptanceType>;

/**
 * Возвращает список типов приёмок работ
 */
export const requestWorkAcceptanceTypes = async () => {
	const response = await httpRequests.withToken.get<IGetWorkAcceptanceTypesResponse>(
		'work-acceptance-types'
	);
	return response.data;
};


