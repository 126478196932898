import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {useTranslation} from 'react-i18next';
import {CellProps} from 'react-table';

export const AcceptorsMobileCell = ({
	row: {
		original: {acceptors, acceptorsActiveGroup, company}
	}
}: CellProps<IPreparedWorkAcceptance>) => {
	const {t} = useTranslation();
	return (
		<div className="work-acceptances-page__m-table__cell-acceptors">
			<div className="work-acceptances-page__m-table__cell-acceptors-title">
				{t('workAcceptancesPage.table.columns.acceptors.header')}:
			</div>
			{acceptorsActiveGroup && (
				<div className="work-acceptances-page__m-table__cell-acceptors-header">
					{acceptorsActiveGroup}
				</div>
			)}
			<div className="work-acceptances-page__m-table__cell-acceptors-info">
				{acceptors?.[0] && (
					<div className="work-acceptances-page__m-table__cell-acceptors-user">
						{acceptors[0]}
					</div>
				)}
				{company && (
					<div className="work-acceptances-page__m-table__cell-acceptors-company">
						{company}
					</div>
				)}
				{!!acceptors?.length && acceptors?.length > 1 && (
					<div className="work-acceptances-page__m-table__cell-acceptors-rest">
						+{acceptors.length - 1}
					</div>
				)}
			</div>
		</div>
	);
};
