import {Dispatch, memo, useCallback, useEffect} from 'react';
import * as React from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {useTranslation} from 'react-i18next';

interface IReplyEmailProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value: string;
	defaultValue?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const ReplyEmail = <S extends {replyEmail: string}, E>({
	className,
	style,
	label = 'Email',
	value,
	defaultValue,
	editingDispatch,
	required,
	disabled,
	hasError,
	...restProps
}: IReplyEmailProps<S, E>) => {
	const {t} = useTranslation();
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'replyEmail', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'replyEmail'});
			}
		},
		[required]
	);

	useEffect(() => {
		if (defaultValue) {
			editingDispatch({
				type: 'update',
				field: 'replyEmail',
				value: defaultValue
			});
		}
	}, [defaultValue]);

	return (
		<div
			className={className}
			style={style}
			{...restProps}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<TextField
				value={value || defaultValue}
				elementType="input"
				error={
					required && hasError ? t('components.editableFields.email.error') : undefined
				}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default memo(ReplyEmail) as typeof ReplyEmail;
