import {queryClient} from '@src/api/QueryClient';
import {makeSpaceTypeDecorationEditRequest} from '@src/api/backend/spaceTypeDecoration';
import {spaceTypesDecorationsQueryKeys} from '@src/api/cache/spaceTypeDecorations/keys';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ISavingSpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecoration';

interface ISpaceTypeDecorationParams {
	typeDecorationSetId: string;
	items: ISavingSpaceTypeDecoration[];
}
export const useEditSpaceTypeDecoration = () =>
	useMutation({
		mutationFn: (params: ISpaceTypeDecorationParams) =>
			makeSpaceTypeDecorationEditRequest(params.typeDecorationSetId, params.items),
		mutationKey: spaceTypesDecorationsQueryKeys.edit(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceTypesDecorationsQueryKeys.list()});
			void queryClient.invalidateQueries({
				queryKey: spaceTypesDecorationsQueryKeys.allById()
			});
		},
		onError: () => {
			addErrorToast('Ошибка', 'при изменении типов отделки');
		}
	});
