import {createReducer} from '@reduxjs/toolkit';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ICheckChangedProblemsPayload, IGetProblemsPayload} from '../actions';
import * as types from '../constants/problems';
import {IDeleteProblemPayload, IEditProblemPayload} from '@src/store/modules/pages/problem/actions';
import {RESET} from '@src/store/modules/pages/checkLists/constants/page';

export interface IProblemsState {
	byId: {
		[id: string]: IProblem;
	};
	allIds: string[];
}

export const getInitialState = (): IProblemsState => ({
	byId: {},
	allIds: []
});

export default createReducer<IProblemsState>(getInitialState(), {
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetProblemsPayload}) => {
		state.byId = payload.byId;
		state.allIds = payload.allIds;
	},
	[types.CHECK_SUCCESS]: (state, {payload}: {payload: ICheckChangedProblemsPayload}) => {
		for (const [key, value] of Object.entries(payload.byId)) {
			state.byId[key] = value;

			if (!state.allIds.includes(key)) {
				state.allIds.push(key);
			}
		}
	},
	[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IProblem}) => {
		// без условия вообще все нарушения при создании сюда пихались, костыль жоский
		if (payload.links?.checkListId) {
			state.byId[payload.id] = payload;
			state.allIds.push(payload.id);
		}
	},
	[problemTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditProblemPayload}) => {
		if (state.allIds.includes(payload.id)) {
			state.byId[payload.id] = payload;
		}
	},
	[problemTypes.EDIT_STATUS_SUCCESS]: (state, {payload}: {payload: IProblem}) => {
		if (state.allIds.includes(payload.id)) {
			state.byId[payload.id] = payload;
		}
	},
	[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (state.allIds.includes(payload.problemId)) {
			state.allIds = state.allIds.filter(id => id !== payload.problemId);
			delete state.byId[payload.problemId];
		}
	},
	[RESET]: getInitialState,
	[CLEAR_STORE]: getInitialState
});
