import { useState, useMemo, memo } from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {useLegalsPermissions} from '@src/core/hooks/permissions/useLegalsPermissions';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {useBankAccountDelete} from '@src/pages/LegalPage/hooks/useBankAccountDelete';
import classNames from 'classnames';
import {useEditableBankAccountDialog} from '@src/components/EditableBankAccountDialog/hooks/useEditableBankAccountDialog';

interface IBankAccountItemProps {
	bankAccount: IBankAccount;
}

const deleteIcon = <i className="tz-delete" />;
const editIcon = <i className="tz-edit" />;
const simpleArrowIcon = <i className="tz-simple-arrow-20" />;
const bankAccountIcon = <i className="tz-bank-account-28" />;

export const BankAccountItem = memo((props: IBankAccountItemProps) => {
	const {bankAccount} = props;
	const [expanded, setExpanded] = useState(false);

	const permissions = useLegalsPermissions();
	const [deleteDialog, handleDeleteClick] = useBankAccountDelete(bankAccount.id);
	const {bankAccountDialog, handleOpenBankAccountDialog} = useEditableBankAccountDialog({
		bankAccount
	});

	const items = useMemo(() => {
		const items = [];

		if (permissions.canEdit) {
			items.push(
				<MenuItem
					key="delete"
					icon={editIcon}
					onClick={handleOpenBankAccountDialog}
				>
					Редактировать
				</MenuItem>
			);
		}

		if (permissions.canDelete) {
			items.push(
				<MenuItem
					key="delete"
					icon={deleteIcon}
					onClick={handleDeleteClick}
				>
					Удалить
				</MenuItem>
			);
		}

		return items;
	}, [permissions, handleDeleteClick, handleOpenBankAccountDialog]);

	const createdAt = format(bankAccount.createdAt || 0, dateTimeFormat, {locale: ru});
	const modifiedAt = format(bankAccount?.modifiedAt || 0, dateTimeFormat, {locale: ru});

	const body = (
		<div className="legal-page__bank-account-info">
			<span className="legal-page__bank-account-info-title">Комментарий:</span>
			<span className="legal-page__bank-account-info-content">{bankAccount.comment}</span>
			<span className="legal-page__bank-account-info-title">Создано:</span>
			<span className="legal-page__bank-account-info-content">{`${createdAt}, ${bankAccount?.createdBy?.displayName}`}</span>
			{bankAccount?.modifiedAt && (
				<>
					<span className="legal-page__bank-account-info-title">Изменено:</span>
					<span className="legal-page__bank-account-info-content">{`${modifiedAt}, ${bankAccount?.modifiedBy?.displayName}`}</span>
				</>
			)}
		</div>
	);

	const handleClick = () => setExpanded(prev => !prev);

	return (
		<div
			key={bankAccount.id}
			className="legal-page__bank-account-item-wrapper"
		>
			<div className="legal-page__bank-account-item">
				<div className="legal-page__bank-account-item-icon">{bankAccountIcon}</div>

				<div className="legal-page__bank-account-item-body">
					<span className="legal-page__bank-account-item-body-number">
						{`Р/c ${bankAccount.accountNumber}`}
					</span>
					<span className="legal-page__bank-account-item-body-bank-name">
						{bankAccount.bankName}
					</span>
					<div
						onClick={handleClick}
						className="legal-page__bank-account-item-body-button"
					>
						{expanded ? <span>Скрыть</span> : <span>Подробнее</span>}
						<span
							className={classNames(
								'legal-page__bank-account-item-body-button-icon',
								{
									'legal-page__bank-account-item-body-button-icon_expanded':
										expanded
								}
							)}
						>
							{simpleArrowIcon}
						</span>
					</div>
				</div>

				<IconMenu className="legal-page__bank-account-item-icon-menu">{items}</IconMenu>
				{permissions.canDelete && deleteDialog}
				{permissions.canEdit && bankAccountDialog}
			</div>
			{expanded && body}
		</div>
	);
});
