import {combineReducers} from 'redux';
import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '../../constants/uiConstants';
import {EDIT_SUCCESS} from '../../store/modules/auth/profile/constants';

const isBusy = createReducer(false, {
	[types.TOGGLE_PROFILE_BUSY]: (state, payload) => (payload !== undefined ? payload : !state)
});

const editMode = createReducer(false, {
	[types.TOGGLE_PROFILE_EDIT_MODE]: (state, payload) =>
		(payload !== undefined ? payload : !state),
	[EDIT_SUCCESS]: () => false
});

export default combineReducers({
	isBusy,
	editMode
});
