import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetWorkAcceptanceStatusesResponse = INormalizedData<IWorkAcceptanceStatus>;

/**
 * Возвращает список статусов приёмок работ
 */
export const requestWorkAcceptanceStatuses = async () => {
	const response = await httpRequests.withToken.get<IGetWorkAcceptanceStatusesResponse>(
		'work-acceptance-statuses'
	);
	return response.data;
};
