import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton} from '@tehzor/ui-components';
import {ObjectsFilterMobile} from '@src/components/MobileEntitiesFilters/components/ObjectsFilterMobile';
import {CreationDateFilterMobile} from '@src/components/MobileEntitiesFilters/components/CreationDateFilterMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {IWarrantyClaimsFiltersState} from '@src/store/modules/settings/pages/warrantyClaims/reducers';
import {
	CreatedByFilterMobile,
	WClaimStatusesFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {RegistrationDateFilterMobile} from '@src/components/MobileEntitiesFilters/components/RegistrationDateFilterMobile';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {useTranslation} from 'react-i18next';

const WarrantyClaimsFilterPage = () => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();
	const location = useLocation();
	const {canGoBack} = location.state as {canGoBack: boolean};
	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IWarrantyClaimsFiltersState>();

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			goBack();
		}
	}, [location, apply]);

	useAppHeader(
		{
			title: t('filtersPage.title'),
			showBackBtn: true,
			mobileRightButtons: isCleanable<IWarrantyClaimsFiltersState>(state, objectId) ? (
				<LinkButton
					label={t('filterPage.resetFiltersBtn.label')}
					onClick={mobileClear}
				/>
			) : null
		},
		[state, objectId]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			{objectId === undefined && (
				<ObjectsFilterMobile
					entity={entity || ''}
					value={state?.objects}
				/>
			)}

			<WClaimStatusesFilterMobile
				objectId={objectId}
				value={state?.statuses}
				entity={entity || ''}
				onChange={change}
			/>

			<CreatedByFilterMobile
				objectId={objectId}
				value={state?.createdBy}
				entity={entity || ''}
				onChange={change}
			/>

			<RegistrationDateFilterMobile
				objectId={objectId}
				from={state.registrationDateFrom}
				to={state.registrationDateTo}
				entity={entity || ''}
			/>

			<CreationDateFilterMobile
				objectId={objectId}
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity || ''}
			/>
		</TranslatedFilterPage>
	);
};

export default WarrantyClaimsFilterPage;
