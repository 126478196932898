import {memo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {FilterButton, Select2, SelectOption} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';

interface IBooleanFilterProps {
	label: string;
	filterName: string;
	options: Array<{id: string; name: string}>;

	value?: boolean;
}

export const BooleanFilter = memo(({label, value, filterName, options}: IBooleanFilterProps) => {
	const valueAsArray = value === undefined ? undefined : value ? ['true'] : ['false'];

	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.problemsCritical,
		options
	);

	const [selectedOptions, setSelectedOptions] = useState(valueAsArray);

	const {dispatch} = useEntitiesFiltersCtx();

	const handleApply = () => {
		const newValue = selectedOptions
			? selectedOptions.length === 2
				? undefined
				: selectedOptions[0] === 'true'
			: undefined;
		dispatch({[filterName]: newValue});
	};

	const handleChange = (v?: string[]) => {
		if (v && v.length === 0) {
			setSelectedOptions(undefined);
		} else {
			setSelectedOptions(v);
		}
	};

	const handleClear = () => {
		setSelectedOptions(undefined);
	};

	const handleFullClear = () => {
		setSelectedOptions(undefined);
		dispatch({[filterName]: undefined});
	};

	const handleCancel = () => {
		setSelectedOptions(valueAsArray);
	};

	useUpdateEffect(() => {
		setSelectedOptions(valueAsArray);
	}, []);

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedOptions?.length}
			noHeader
			footer
			count={selectedOptions?.length}
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel(label, valueAsArray, translatedOptions)}
					active={!!valueAsArray?.length}
					onClear={handleFullClear}
				/>
			}
		>
			<Select2
				multiple
				value={selectedOptions}
				onChange={handleChange}
			>
				{translatedOptions?.map(item => (
					<SelectOption
						dataTestId="MenuItem"
						key={item.id.toString()}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</TranslatedSelectPopup>
	);
});
