import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableMeterAction, IEditableMeterState, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';

export interface IMeterState extends IMeter {
	meterConsumptions?: IMeterConsumption[];
}

export const useEditableMeterState = (meter?: IMeterState) =>
	useReducer<Reducer<IEditableMeterState, IEditableMeterAction>, IMeterState | undefined>(
		createReducer(init, errorsFns),
		meter,
		init
	);
