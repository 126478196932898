import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {IMeterType} from '@tehzor/tools/interfaces/meters/IMeterType';
import {
	useExtractMeterTypesAsArray,
	useExtractMeterTypesById
} from '@src/core/hooks/queries/meterTypes/hooks';
import {useTranslatedDictionaryArray} from "@src/core/hooks/translations/useTranslatedDictionaryArray";
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";
import {useTranslatedDictionaryMap} from "@src/core/hooks/translations/useTranslatedDictionaryMap";
import {useTranslation} from "react-i18next";

const arrowIcon = <i className="tz-simple-arrow-20" />;

interface IMeterTypeSelectProps<S, E> {
	field?: keyof S;
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (value: string | undefined, typesMap: Record<string, IMeterType>) => {
	if (!value) {
		return undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

export const MeterTypeSelect = <
	S extends {typeId?: string; meterType?: string; meterTypes?: string[]},
	E
>({
	className,
	style,
	field = 'typeId',
	label,
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: IMeterTypeSelectProps<S, E>) => {
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.meterTypeSelect.label');
	const {data: types} = useExtractMeterTypesAsArray();
	const translatedTypes = useTranslatedDictionaryArray(dictionaryKeys.meterTypes, types)
	const {data: typesMap} = useExtractMeterTypesById();
	const translatedTypesMat = useTranslatedDictionaryMap(dictionaryKeys.meterTypes, typesMap)
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required, editingDispatch, field]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>
			{translatedTypes && translatedTypesMat && (
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={getInputLabel(value, translatedTypesMat)}
							icon={arrowIcon}
							error={required && hasError ? t('components.editableFields.meterTypeSelect.error') : undefined}
							disabled={disabled}
						/>
					}
				>
					<Select2
						value={value}
						onChange={handleChange}
					>
						{translatedTypes.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			)}
		</div>
	);
};
