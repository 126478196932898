import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditExportTemplateResponse = INormalizedData<IExportTemplate>;

/**
 * Редактирует шаблон
 * @param exportTemplateId id рабочей группы
 * @param fields данные для добавления
 */
export const makeExportTemplateEditRequest = async (
	exportTemplateId: string,
	fields: ISavingSpaceTypeDecorationSet
) => {
	const response = await httpRequests.withToken.put<IEditExportTemplateResponse>(
		'export-templates',
		{exportTemplateId, ...fields}
	);
	return response.data;
};
