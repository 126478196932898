import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {WorkAcceptanceViewTabs} from './WorkAcceptanceViewTabs.mobile';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {MobileRoutes} from './routes/Routes.mobile';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {MobileControls} from './controls/Controls.mobile';
import {useTranslation} from 'react-i18next';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';

interface IMobileProps {
	objectId: string;
	checkLists: ICheckList[];
	workAcceptance: IWorkAcceptance;
	permissions: IPermissions;
	settings?: IEntitySettings;
}

export const Mobile = ({
	objectId,
	checkLists,
	workAcceptance,
	permissions,
	settings
}: IMobileProps) => {
	const {t} = useTranslation();
	useAppHeader(
		{
			title: `${t('workAcceptancePage.title')} №${workAcceptance.number}`,
			mobileRightButtons: (
				<MobileControls
					objectId={objectId}
					workAcceptance={workAcceptance}
					checkLists={checkLists}
					permissions={permissions}
				/>
			),
			showBackBtn: true
		},
		[workAcceptance, objectId, checkLists, permissions, t]
	);

	return (
		<div className="page-cont work-acceptance-page">
			<div className="work-acceptance-page__plate-header">
				<WorkAcceptanceViewTabs objectId={objectId} />
			</div>
			<PageBreadcrumbs
				objectId={objectId}
				workAcceptance={workAcceptance}
			/>
			<div className="work-acceptance-page__d-column work-acceptance-page__d-column_entities">
				<MobileRoutes
					workAcceptance={workAcceptance}
					objectId={objectId}
					checkLists={checkLists}
					settings={settings}
				/>
			</div>
		</div>
	);
};
