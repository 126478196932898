import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {structuresActions} from '@/entities/Structures';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStructuresSchemaTypes} from '@src/core/hooks/queries/structureTypes/hooks';
import {useEffect} from 'react';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';

export const useActiveSchemaStructuresType = (objectId: string) => {
	const dispatch = useAppDispatch();
	const {changeSchemaStructureType} = structuresActions;
	const {schemaStructureTypeId} = useAppSelector(state =>
		extractStructuresSchemaPageSettings(state, objectId)
	);
	const {data: schemaStructuresTypes} = useStructuresSchemaTypes(objectId);

	useEffect(() => {
		if (!schemaStructuresTypes) return;
		if (
			!schemaStructureTypeId ||
			!schemaStructuresTypes.some(({id}) => id === schemaStructureTypeId)
		) {
			if (!schemaStructuresTypes[0]) {
				return;
			}
			dispatch(
				changeSchemaStructureType({objectId, structureTypeId: schemaStructuresTypes[0].id})
			);
		}
	}, [
		objectId,
		dispatch,
		schemaStructuresTypes,
		schemaStructureTypeId,
		changeSchemaStructureType
	]);
};
