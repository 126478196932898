import {ISpaceStatusesSetsFiltersState} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/reducers';
import {IFullSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/IFullSpaceStatusesSet';

export const handleFilter = (
	set: IFullSpaceStatusesSet,
	filters: ISpaceStatusesSetsFiltersState = {}
) => {
	const filtersArray = Object.keys(filters || {});
	let result = true;

	for (const filterKey of filtersArray) {
		const {search, createdAtFrom, createdAtTo, createdBy} = filters;
		const objects = filters?.objects || [];
		const companies = filters?.companies || [];

		switch (filterKey) {
			case 'search': {
				if (!search) {
					break;
				}

				const upperSearch = search.toUpperCase();
				const upperName = set.name.toUpperCase();

				result = upperName.indexOf(upperSearch) > -1;
				break;
			}
			case 'objects': {
				if (!objects?.length) {
					break;
				}

				result = !!set.objects?.some(item => objects.includes(item));
				break;
			}
			case 'companies': {
				if (!companies?.length) {
					break;
				}

				result = companies.includes(set?.companyId || '');
				break;
			}
			case 'createdBy': {
				const id = set.createdBy?.id || '';
				if (!createdBy?.length) {
					break;
				}

				result = createdBy.includes(id);
				break;
			}
			case 'createdAtTo':
			case 'createdAtFrom': {
				const createdAt = new Date(set.createdAt || 0);
				createdAt.setHours(0, 0, 0, 0);

				if (!createdAtFrom || !createdAtTo) {
					break;
				}

				result = createdAtFrom <= createdAt && createdAt <= createdAtTo;
				break;
			}
		}
	}

	return result;
};