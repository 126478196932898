import {QueryState} from '@tanstack/react-query';

export function getMaxDate(states: Array<QueryState | undefined>): number | undefined {
	const maxDate = states.reduce<number>((prev, state) => {
		if (state?.dataUpdatedAt) {
			return Math.max(prev, state.dataUpdatedAt);
		}
		return prev;
	}, 0);
	if (maxDate !== 0) {
		return maxDate;
	}
	return undefined;
}
