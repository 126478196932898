import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptanceVisibility} from '@src/store/modules/settings/pages/internalAcceptance/selectors';
import {useQuery} from '@tanstack/react-query';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {useCallback} from 'react';
import {useLocalProblems} from '../../problems/hooks';
import {
	extractInternalAcceptanceLocalProblemsArray,
	extractInternalAcceptanceLocalProblemsEntities,
	extractInternalAcceptanceProblemsArray,
	extractInternalAcceptanceProblemsEntities
} from './selectors';
import {IGetListProblemsResponse} from '@src/api/backend/problems';

export const useInternalAcceptanceProblems = <T = IGetListProblemsResponse>(
	objectId: string,
	acpId: string,
	select?: (data: IGetListProblemsResponse) => T
) =>
	useQuery({
		queryKey: internalAcceptancesQueryKeys.internalAcceptanceProblems(objectId, acpId),
		select
	});

export const useInternalAcceptanceProblemsArray = (objectId: string, acpId: string) =>
	useInternalAcceptanceProblems(objectId, acpId, extractInternalAcceptanceProblemsArray);

export const useInternalAcceptanceLocalProblems = (objectId: string, acpId: string) => {
	const selector = useCallback(
		(data: IListProblem[]) => extractInternalAcceptanceLocalProblemsArray(data, acpId),
		[acpId]
	);
	return useLocalProblems(objectId, selector);
};

export const useInternalAcceptanceProblemsEntities = (objectId: string, acpId: string) => {
	const visibility = useAppSelector(extractInternalAcceptanceVisibility);
	const selector = useCallback(
		(data: IGetListProblemsResponse) =>
			extractInternalAcceptanceProblemsEntities(data, visibility),
		[visibility]
	);
	return useInternalAcceptanceProblems(objectId, acpId, selector);
};

export const useInternalAcceptanceLocalProblemsEntities = (objectId: string, acpId: string) => {
	const visibility = useAppSelector(extractInternalAcceptanceVisibility);
	const selector = useCallback(
		(data: IListProblem[]) =>
			extractInternalAcceptanceLocalProblemsEntities(data, acpId, visibility),
		[acpId, visibility]
	);
	return useLocalProblems(objectId, selector);
};
