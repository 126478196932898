import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {ViewerType, InputType} from '@tehzor/ui-components/src/components/LocationSelect';
import {IChangeLocDefaultInputTypePayload, IChangeLocDefaultViewerTypePayload} from './actions';

export interface ILocSelectSettingsForObjectState {
	defaultViewerType?: ViewerType;
	defaultInputType?: InputType;
}

export interface ILocationSelectSettingsState {
	[objectId: string]: ILocSelectSettingsForObjectState;
}

export default createReducer<ILocationSelectSettingsState>(
	{},
	{
		[types.CHANGE_DEFAULT_VIEWER_TYPE]: (
			state,
			{payload}: {payload: IChangeLocDefaultViewerTypePayload}
		) => {
			state[payload.objectId].defaultViewerType = payload.value;
		},
		[types.CHANGE_DEFAULT_INPUT_TYPE]: (
			state,
			{payload}: {payload: IChangeLocDefaultInputTypePayload}
		) => {
			state[payload.objectId].defaultInputType = payload.value;
		}
	}
);
