import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import ILocationMapPoint from '@tehzor/tools/interfaces/ILocationMapPoint';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import {useEffect, useState} from 'react';
import {getSectorsDisplayValue} from '../utils/getSectorsDisplayValue';
import {InputType, ViewerType} from '../LocationSelect';
import {getPointsDisplayValue} from '../utils/getPointsDisplayValue';

export const useDisplayValueState = (
	viewerType: ViewerType,
	inputType: InputType,
	planSectors: ILocationMarker[],
	planPoints: ILocationMarker[],
	mapPoints: ILocationMapPoint[],
	layers: ILayer[]
) => {
	const [value, setValue] = useState<string[]>([]);

	useEffect(() => {
		let newValue = [] as string[];
		if (viewerType === 'plan') {
			newValue =
				inputType === 'sectors'
					? getSectorsDisplayValue(planSectors, layers)
					: getPointsDisplayValue(planPoints);
		} else if (viewerType === 'map' && inputType === 'points') {
			newValue = getPointsDisplayValue(mapPoints);
		}

		if (JSON.stringify(value) !== JSON.stringify(newValue)) {
			setValue(newValue);
		}
	}, [viewerType, inputType, planSectors, planPoints, mapPoints, layers, value]);
	return value;
};
