import StatusesFilterPage from '../StatusesFilterPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {
	useExtractCheckListsAsArrayFilteredBySelectedSpaceType,
	useExtractCheckListsAsArrayForWorkAcceptances
} from '@src/core/hooks/queries/checkLists/hooks';
import {useTranslation} from 'react-i18next';

export const CheckListIdsFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const {data: spacesCheckLists} =
		useExtractCheckListsAsArrayFilteredBySelectedSpaceType(objectId);
	const {data: structuresCheckLists} = useExtractCheckListsAsArrayForWorkAcceptances(objectId);

	const checkLists = entity === 'spaces' ? spacesCheckLists : structuresCheckLists;

	if (!checkLists) return null;

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={checkLists}
			label={t('spacesPage.spacesFilters.entitiesFilters.statusesFilter.checkListIds.label')}
			filterName="checkListIds"
			resetOnApply={['checkListCategory']}
		/>
	);
};
