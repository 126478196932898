import { Dispatch } from 'react';
import './EditableOwnerAcceptanceAttachments.less';
import Attachments from '@src/components/editableFields/Attachments';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {
	IEditableOwnerAcceptanceAction,
	IEditableOwnerAcceptanceState
} from '@src/core/hooks/states/useEditableOwnerAcceptanceState';

interface IEditableProblemProps {
	editingState: IEditableOwnerAcceptanceState;
	editingDispatch: Dispatch<IEditableOwnerAcceptanceAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	// fieldsSettings: Record<string, IObjectFieldSetting>;

	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableOwnerAcceptanceAttachments = (props: IEditableProblemProps) => {
	const {
		// objectId,
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		// fieldsSettings,
		saving
	} = props;

	// const isDesktopHeight = useIsDesktopHeight();

	return (
		<div className="editable-owner-acceptance-attachments">
			<div>
				{/* {fieldsSettings.attachments !== undefined && ( */}
				<Attachments
					className={{
						root: 'editable-owner-acceptance-attachments__attachments',
						scrollArea:
							'editable-owner-acceptance-attachments__attachments-scroll-area',
						files: 'editable-owner-acceptance-attachments__attachments-files',
						file: 'editable-owner-acceptance-attachments__attachments-file',
						attachBtn: 'editable-owner-acceptance-attachments__attach-btn'
					}}
					attachments={editingState.attachments}
					uploadingFiles={uploadingFilesState.value}
					editingDispatch={editingDispatch}
					uploadingFilesDispatch={uploadingFilesDispatch}
					// required={fieldsSettings.attachments.isRequired}
					disabled={saving}
					canDraw={false}
					hasError={editingState.errors.attachments && uploadingFilesState.error}
					showAttachBtn
				/>
				{/* )} */}
			</div>
		</div>
	);
};

export default EditableOwnerAcceptanceAttachments;
