import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback} from 'react';
import {extractLocalChecksJournal} from './selector';

export const useLocalChecksJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: checksQueryKeys.add()});
	const selector = useCallback(
		(checks: IListCheck[]) => extractLocalChecksJournal(checks, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IListCheck[], unknown, IOfflineJournalEntity[]>({
		queryKey: checksQueryKeys.localList(),
		select: selector
	});
	return data || [];
};
