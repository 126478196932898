import {IPreparedClaim} from '@src/pages/WarrantyClaimsPage/interfaces/IPreparedClaim';
import {SelectedEntitiesDeletion} from './SelectedEntitiesDeletion';

interface ISelectionsActionsProps {
	selectedEntities: IPreparedClaim[];
	selectedClearing: () => void;
}

export const SelectionActions = (props: ISelectionsActionsProps) => {
	const {selectedEntities, selectedClearing} = props;
	const canDelete = selectedEntities?.every(item => item.canDelete);

	return (
		<>
			{canDelete && (
				<SelectedEntitiesDeletion
					selectedEntities={selectedEntities}
					selectedClearing={selectedClearing}
				/>
			)}
		</>
	);
};
