import {Reducer, useReducer} from 'react';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {
	IEditableCategoriesSetAction,
	IEditableCategoriesSetState,
	IEditableCategoriesSetOriginal,
	init,
	errorsFns
} from './state';

export const useEditableCategoriesSetState = (defaultData?: IEditableCategoriesSetOriginal) =>
	useReducer<
		Reducer<IEditableCategoriesSetState, IEditableCategoriesSetAction>,
		IEditableCategoriesSetOriginal | undefined
	>(createReducer(init, errorsFns), defaultData, init);
