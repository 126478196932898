import {useMutation} from '@tanstack/react-query';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {ISavingExportTemplate} from '@src/interfaces/saving/ISavingExportTemplate';
import {queryClient} from '@src/api/QueryClient';
import {exportTemplatesQueryKeys} from '@src/api/cache/exportTemplates/keys';
import {makeExportTemplateEditRequest} from '@src/api/backend/exportTemplates/edit';

interface IUseEditExportTemplateMutationParams {
	templateId: string;
	fields: ISavingExportTemplate;
}

export const useEditExportTemplate = () =>
	useMutation({
		mutationKey: exportTemplatesQueryKeys.edit(),
		mutationFn: (params: IUseEditExportTemplateMutationParams) => {
			const {templateId, fields} = params;
			return makeExportTemplateEditRequest(templateId, fields);
		},
		onError: () => {
			addErrorToast('Ошибка', 'при редактировании шаблона');
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: exportTemplatesQueryKeys.list()});
			addSuccessToast('Успешно', 'шаблон был обновлен');
		}
	});
