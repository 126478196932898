import classNames from 'classnames';
import { memo, useMemo, useRef } from 'react';
import {Column, Row, useTable} from 'react-table';
import {convertClassNames} from '../../../utils/convertClassNames';
import {useDragEvents} from '../hooks/useDragEvents';
import {IDndEvent, IDndItem} from '../interfaces';
import './DndTable.less';
import {DndTableEmptyRow} from './components/DndTableEmptyRow';
import {DndTableRow} from './components/DndTableRow';

export interface IDndTableProps<S extends IDndItem> {
	className?:
		| string
		| {
				root?: string;

				header?: string;
				headerRow?: string;
				headerCell?: string;
				headerDragCell?: string;

				body?: string;
				bodyRow?: string;
				bodyCell?: string;
				bodyDragCell?: string;

				emptyRow?: string;
		  };
	columns: Array<Column<S>>;
	items: S[];
	draggableIds?: string[];
	container: string;
	emptyMessage?: string;

	onDragStart?: (event: IDndEvent<S>) => void;
	onDragOver?: (event: IDndEvent<S>) => void;
	onDragEnd?: (event: IDndEvent<S>) => void;
	onDragCancel?: (event: IDndEvent<S>) => void;
	onRowClick?: (data: Row<S>) => void;
}

type IDndTableType = <S extends IDndItem>(props: IDndTableProps<S>) => JSX.Element | null;

export const DndTable = memo(
	<S extends IDndItem>({
		className,
		columns,
		items,
		container,
		draggableIds,
		emptyMessage,
		onDragStart,
		onDragOver,
		onDragEnd,
		onDragCancel,
		onRowClick
	}: IDndTableProps<S>) => {
		const classes = useMemo(() => convertClassNames(className), [className]);
		const containerRef = useRef<HTMLTableElement>(null);
		const {draggableItems, overState} = useDragEvents<S>(
			items,
			container,
			containerRef,
			onDragStart,
			onDragOver,
			onDragEnd,
			onDragCancel
		);
		const {item: overItem, status, container: overContainer} = overState;

		const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
			columns,
			data: draggableItems
		});

		return (
			<table
				ref={containerRef}
				{...getTableProps()}
				className={classNames('dnd-table', classes.root)}
				role="grid"
			>
				<thead className={classNames('dnd-table__header', classes.header)}>
					{headerGroups.map(headerGroup => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							className={classNames('dnd-table__header-row', classes.headerRow)}
						>
							<th
								aria-label="drag"
								className={classNames(
									'dnd-table__header-row__cell-drag',
									'dnd-table__header-row__cell',
									classes.headerDragCell,
									classes.headerCell
								)}
							/>
							{headerGroup.headers.map(column => (
								<th
									{...column.getHeaderProps()}
									className={classNames(
										'dnd-table__header-row__cell',
										classes.headerCell
									)}
								>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody
					{...getTableBodyProps()}
					className={classNames('dnd-table__body', classes.body)}
				>
					{!rows.length && (
						<DndTableEmptyRow
							className={classes.emptyRow}
							container={container}
							overContainer={overContainer}
							emptyMessage={emptyMessage}
						/>
					)}
					{rows.map(row => (
						<DndTableRow
							row={row}
							prepareRow={prepareRow}
							onRowClick={onRowClick}
							key={row.id}
							container={container}
							draggableIds={draggableIds}
							isOverItem={row.original.id === overItem?.id}
							overStatus={row.original.id === overItem?.id ? status : undefined}
						/>
					))}
				</tbody>
			</table>
		);
	}
) as IDndTableType;
