import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {spacesQueryKeys} from '../keys';
import {useQueryClient} from '@tanstack/react-query';
import {makeSpaceIndicatorsEditRequest, makeSpaceStatusEditRequest} from '@src/api/backend/space';
import {
	IEditSpaceIndicatorsPayload,
	IEditSpaceStatusPayload,
	editSpaceIndicatorsActions,
	editSpaceStatusActions
} from '@src/store/modules/entities/space/actions';
import IError from '@tehzor/tools/interfaces/IError';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {updateCachedState} from '@src/utils/updateCachedState';
import {IEditSpaceIndicatorsJournalData} from '@src/core/hooks/mutations/spaces/useEditSpaceIndicators';
import {IEditSpaceStatusJournalData} from '@src/core/hooks/mutations/spaces/useEditSpaceStatus';

export interface IEditSpaceIndicatorsParams {
	readonly objectId: string;
	readonly pageObjectId: string;
	readonly spaceId: string;
	readonly indicators: string[];
	readonly online: boolean;
	readonly key: string;
}

export interface IEditSpaceStatusParams {
	readonly objectId: string;
	readonly pageObjectId: string;
	readonly spaceId: string;
	readonly status: string;
	readonly online: boolean;
	readonly key: string;
}

export interface IEditSpaceIndicatorsMutationParams extends IEditSpaceIndicatorsParams {
	journalData: IEditSpaceIndicatorsJournalData;
}

export interface IEditSpaceStatusMutationParams extends Omit<IEditSpaceStatusParams, 'online'> {
	journalData: IEditSpaceStatusJournalData;
}

export interface IChangedIndicators extends Omit<IEditSpaceIndicatorsMutationParams, 'online'> {
	transferStatus: OfflineDataTransferStatus;
}

export interface IChangedStatus extends IEditSpaceStatusMutationParams {
	transferStatus: OfflineDataTransferStatus;
}

export const useSpacesMutationDefaults = () => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();

	queryClient.setMutationDefaults(spacesQueryKeys.indicators.edit(), {
		onMutate: ({
			objectId,
			spaceId,
			pageObjectId,
			indicators,
			key,
			journalData
		}: IEditSpaceIndicatorsMutationParams) => {
			queryClient.setQueryData<IChangedIndicators>(spacesQueryKeys.indicators.detail(key), {
				key,
				objectId,
				spaceId,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
				pageObjectId,
				indicators,
				journalData
			});
		},
		mutationFn: async ({
			objectId,
			spaceId,
			indicators,
			key
		}: IEditSpaceIndicatorsMutationParams) => {
			await updateCachedState<IChangedIndicators>(
				spacesQueryKeys.indicators,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			await queryClient.cancelQueries({queryKey: spacesQueryKeys.detail(spaceId)});
			return makeSpaceIndicatorsEditRequest(objectId, spaceId, indicators);
		},
		onError: async (err, {key}: IEditSpaceIndicatorsMutationParams) => {
			await updateCachedState<IChangedIndicators>(
				spacesQueryKeys.indicators,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
			dispatch(editSpaceIndicatorsActions.failure(err as IError));
		},
		onSuccess: async (
			data: IEditSpaceIndicatorsPayload,
			{pageObjectId, key, spaceId, objectId}: IEditSpaceIndicatorsMutationParams
		) => {
			await updateCachedState<IChangedIndicators>(
				spacesQueryKeys.indicators,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			queryClient.removeQueries({queryKey: spacesQueryKeys.indicators.detail(key)});
			queryClient.setQueryData([...spacesQueryKeys.detail(spaceId), objectId], () => data);
			dispatch(editSpaceIndicatorsActions.success(data, pageObjectId));
			void queryClient.invalidateQueries({
				queryKey: [...spacesQueryKeys.list(), {objects: [objectId]}]
			});
		}
	});

	queryClient.setMutationDefaults(spacesQueryKeys.status.edit(), {
		onMutate: ({
			objectId,
			spaceId,
			pageObjectId,
			status,
			key,
			journalData
		}: IEditSpaceStatusMutationParams) => {
			queryClient.setQueryData<IChangedStatus>(spacesQueryKeys.status.detail(key), {
				key,
				objectId,
				spaceId,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
				pageObjectId,
				status,
				journalData
			});
		},
		mutationFn: async ({objectId, spaceId, status, key}: IEditSpaceStatusMutationParams) => {
			await updateCachedState<IChangedStatus>(
				spacesQueryKeys.status,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			await queryClient.cancelQueries({queryKey: spacesQueryKeys.detail(spaceId)});
			return makeSpaceStatusEditRequest(objectId, spaceId, status);
		},
		onError: async (err, {key}: IEditSpaceStatusMutationParams) => {
			await updateCachedState<IChangedStatus>(
				spacesQueryKeys.status,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
			dispatch(editSpaceStatusActions.failure(err as IError));
		},
		onSuccess: async (
			data: IEditSpaceStatusPayload,
			{pageObjectId, spaceId, objectId, key}: IEditSpaceIndicatorsMutationParams
		) => {
			await updateCachedState<IChangedStatus>(
				spacesQueryKeys.status,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			queryClient.removeQueries({queryKey: spacesQueryKeys.status.detail(key)});
			dispatch(editSpaceStatusActions.success(data, pageObjectId));

			await queryClient.refetchQueries({
				queryKey: [...spacesQueryKeys.detail(spaceId), objectId]
			});
			await queryClient.invalidateQueries({
				queryKey: [...spacesQueryKeys.detail(spaceId), objectId]
			});
			await queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
		}
	});
};
