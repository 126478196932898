import {useWorkingGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {WorkAcceptanceTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';
import arrayToTree from 'array-to-tree';
import {useMemo} from 'react';
import {IWorkingGroupsFilters} from '../types/IWorkingGroupsFilters';
import {ViewButtonsTypeId} from '../types/ViewButtonsTypeId';
import {filterWorkingGroups} from '../utils/filterWorkingGroups';

interface IUseFilteredWorkingGroupsProps {
	objectId: string;
	activeGroup?: string;
	workingGroupType?: WorkingGroupTypeId;
	viewButtonsType?: ViewButtonsTypeId;
	stage?: ObjectStageIds;
	scope?: string;
	workAcceptanceType?: WorkAcceptanceTypeId;
	workAcceptanceFrontType?: WorkAcceptanceFrontTypeId;
}

const getWorkingGroupType = (
	workingGroupType?: WorkingGroupTypeId,
	viewButtonsType?: ViewButtonsTypeId
): WorkingGroupTypeId | undefined => {
	if (workingGroupType) {
		return workingGroupType;
	}

	switch (viewButtonsType) {
		case ViewButtonsTypeId.INSPECTORS: {
			return workingGroupType ?? WorkingGroupTypeId.INSPECTORS;
		}
		case ViewButtonsTypeId.PERFORMERS: {
			return workingGroupType ?? WorkingGroupTypeId.PERFORMERS;
		}
		case ViewButtonsTypeId.ACCEPTORS: {
			return workingGroupType ?? WorkingGroupTypeId.ACCEPTORS;
		}
		default: {
			return workingGroupType;
		}
	}
};

export const useFilteredWorkingGroups = ({
	objectId,
	activeGroup,
	workingGroupType,
	viewButtonsType,
	stage,
	scope,
	workAcceptanceType,
	workAcceptanceFrontType
}: IUseFilteredWorkingGroupsProps) => {
	const {data: workingGroups} = useWorkingGroupsAsArray();

	const type = getWorkingGroupType(workingGroupType, viewButtonsType);

	return useMemo(() => {
		const filters: IWorkingGroupsFilters = {
			type,
			objectId,
			scope,
			stage,
			workAcceptanceFrontType,
			workAcceptanceType
		};
		const filteredWorkingGroups = filterWorkingGroups(workingGroups || [], filters);

		const tree = arrayToTree(filteredWorkingGroups, {
			parentProperty: 'parentId',
			customID: 'id'
		});
		const activeGroupNode = activeGroup ? findTreeNode(tree, activeGroup) : undefined;

		return activeGroupNode ? [activeGroupNode] : tree;
	}, [workingGroups, type, objectId, activeGroup, scope, stage, workAcceptanceType, workAcceptanceFrontType]);
};
