import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export type IEditInspectionResponse = IInspection;

/**
 * Изменяет осмотр
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 * @param fields данные осмотра
 */
export const makeInspectionEditRequest = async (
	objectId: string,
	inspectionId: string,
	fields: ISavingInspection
) => {
	const response = await httpRequests.withToken.put<IEditInspectionResponse>(
		`inspections/${inspectionId}`,
		{
			objectId,
			inspectionId,
			...fields
		}
	);

	return response.data;
};
