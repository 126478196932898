import { Dispatch, useCallback } from 'react';
import {LinkButton} from '@tehzor/ui-components';
import './EditableTypeDecorationItems.less';
import {
	IEditableTypeDecoration,
	IEditableTypeDecorationAction,
	IEditableTypeDecorationSetState
} from '@src/core/hooks/states/useEditableSpaceTypeDecorationState';
import {DeleteCell} from './DeleteCell';
import {TextInputCell} from './TextInputCell';
import SpaceTypeSelect2 from '@src/components/editableFields/SpaceTypeSelect2';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export interface IEditableSpaceTypeDecorationItemProps {
	editingState: IEditableTypeDecorationSetState;
	editingDispatch: Dispatch<IEditableTypeDecorationAction>;
}

export const EditableSpaceTypeDecorationItems = ({
	editingState,
	editingDispatch
}: IEditableSpaceTypeDecorationItemProps) => {
	const {typeDecorationSetId} = useStrictParams<{typeDecorationSetId: string}>();
	const handleAdd = useCallback(() => {
		editingDispatch({
			type: 'add-in-array',
			field: 'data',
			value: [
				{
					name: '',
					typeDecorationSetId,
					spaceTypes: []
				}
			]
		});
	}, []);

	return (
		<div className="editable-space-type-decoration">
			<div className="editable-space-type-decoration__header">
				<h3>Типы отделки помещений</h3>
				<div className="editable-space-type-decoration__header-actions">
					<LinkButton
						label="Добавить тип отделки"
						onClick={handleAdd}
						leftIcon={<i className="tz-plus-20"/>}
					/>
				</div>
			</div>
			<div className="editable-space-type-decoration__body">
				{editingState.data?.map((typeDecItem: IEditableTypeDecoration, index) => (
					<div
						className="editable-space-type-decoration__item"
						key={index}
					>
						<TextInputCell
							className="editable-space-type-decoration__item-input-name"
							elemIndex={index}
							rootField="data"
							fieldName="name"
							editingState={editingState}
							editingDispatch={editingDispatch}
							placeholder="Тип отделки"
							elementType="input"
							value={typeDecItem.name}
						/>

						<SpaceTypeSelect2
							className="editable-space-type-decoration__item-input-space"
							field="data"
							index={index}
							arrayItem={typeDecItem}
							label="Типы помещений"
							value={typeDecItem.spaceTypes || []}
							editingDispatch={editingDispatch}
							required
							hasError={editingState.errors.data}
						/>

						<DeleteCell
							className="editable-space-type-decoration__item-remove-button"
							elemIndex={index}
							editingDispatch={editingDispatch}
						/>
					</div>
				))}
			</div>
		</div>
	);
};
