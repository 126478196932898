import IRawFile from '../interfaces/IRawFile';
import generateKey from './generateKey';

export function extendRawFiles(files: File[]): IRawFile[] {
	const result = [] as IRawFile[];
	for (const file of files) {
		const extendedFile = extendRawFile(file);
		if (extendedFile) {
			result.push(extendedFile);
		}
	}
	return result;
}

export default function extendRawFile(file: File): IRawFile | undefined {
	try {
		return {
			key: generateKey('file'),
			name: file.name,
			size: file.size,
			type: file.type,
			url: window.URL.createObjectURL(file),
			original: file
		} as IRawFile;
	} catch (error) {
		if (process.env.NODE_ENV !== 'production') {
			console.error('Failed to generate preview for a file', file, error);
		}
		return;
	}
	return undefined;
}
