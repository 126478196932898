import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {
	useExtractWorkAcceptanceTypesAsArray,
	useExtractWorkAcceptanceTypesById
} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	IWorkAcceptanceType,
	WorkAcceptanceTypeId
} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import * as React from 'react';
import {Dispatch, useCallback} from 'react';

interface IWorkAcceptanceTypeSelectProps<S, E> {
	field?: keyof S;
	value?: WorkAcceptanceTypeId;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: string;
}

const getInputLabel = (
	value: WorkAcceptanceTypeId | undefined,
	typesMap: Record<string, IWorkAcceptanceType>
) => {
	if (!value) {
		return undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

export const WorkAcceptanceTypeSelect = <
	S extends {
		type?: WorkAcceptanceTypeId;
		workAcceptanceTypes?: WorkAcceptanceTypeId[];
	},
	E
>({
	className,
	style,
	field = 'type',
	label = 'Тип приёмки работ',
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	errorMessage = 'Выберите тип приёмки работ'
}: IWorkAcceptanceTypeSelectProps<S, E>) => {
	const {data: types} = useExtractWorkAcceptanceTypesAsArray();
	const translatedTypes = useTranslatedDictionaryArray(dictionaryKeys.workAcceptanceTypes, types);

	const {data: typesMap} = useExtractWorkAcceptanceTypesById();
	const translatedTypesMap = useTranslatedDictionaryMap(
		dictionaryKeys.workAcceptanceTypes,
		typesMap
	);

	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required, field]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			{translatedTypes && translatedTypesMap && (
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={getInputLabel(value, translatedTypesMap)}
							icon={<i className="tz-simple-arrow-20" />}
							error={required && hasError ? errorMessage : undefined}
							disabled={disabled}
						/>
					}
				>
					<Select2
						value={value}
						onChange={handleChange}
					>
						{translatedTypes.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			)}
		</div>
	);
};
