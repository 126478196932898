import * as types from './constants';
import {IOfflineJournalFiltersState} from './reducers';

/**
 * Изменяет фильтр
 *
 * @param filters свойства фильтра
 */
export const changeFilters = (filters: IOfflineJournalFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: filters
});

/**
 * Очищает фильтр
 *
 */
export const clearFilters = () => ({
	type: types.CLEAR_FILTERS
});
