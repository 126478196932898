import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';
import {EntityInfo, UserInfo} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {CellProps} from 'react-table';
import './InspectionFixCell.less';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

const userIcon = <i className="tz-users-24" />;

export const InspectionFixCell = ({row}: CellProps<{data: IInspection}>) => {
	const {performers, performersActiveGroup} = row.original.data;
	const {data: usersMap} = useUsersAsMap();
	const {data: groupsMap} = useWorkingGroupsAsMap();
	const isDesktop = useIsDesktop();

	return (
		<>
			{performers?.length && usersMap ? (
				performers.map(userId => (
					<UserInfo
						key={userId}
						className="inspection-fix-cell-user"
						user={usersMap[userId]}
						avatarSize={isDesktop ? '40' : '34'}
						avatarColoring="text"
					/>
				))
			) : performersActiveGroup && groupsMap ? (
				<EntityInfo
					className="inspection-fix-cell-user"
					title={groupsMap[performersActiveGroup]?.name}
					icon={userIcon}
					iconColor="#718198"
					backgroundColor="#FFDEB8"
				/>
			) : null}
		</>
	);
};
