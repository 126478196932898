import {deleteSpaceTypeDecorationSetRequest} from '@src/api/backend/spaceTypeDecorationSets';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';

export const useDeleteSpaceTypeDecorationSet = () =>
	useMutation({
		mutationFn: (id: string) => deleteSpaceTypeDecorationSetRequest(id),
		mutationKey: spaceTypeDecorationSetsQueryKeys.delete(),
		onSuccess: () => {
			addSuccessToast('Удалено', 'Набор типов отделки успешно удален');
			void queryClient.invalidateQueries({
				queryKey: spaceTypeDecorationSetsQueryKeys.lists()
			});
		},
		onError: () => addErrorToast('Ошибка', 'при удалении набора типов отделки')
	});
