import {exportInternalAcceptance} from './export';
import {sendExportedInternalAcceptance} from './send';
import {ApiAction} from '@src/store/middlewares/api';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {ISendResponse} from '@src/interfaces/ISendResponse';

/**
 * Скачивает файл или отправляет на email внутреннюю приёмку
 *
 * @param email email
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 * @param problems выбранные нарушения
 * @param inspections выбранные осмотры
 * @param createDocument флаг создания документа
 */
export const exportInternalAcceptanceUnified = (
	email: string | undefined,
	templateId: string,
	objectId: string,
	internalAcceptanceId: string,
	problems?: string[],
	inspections?: string[],
	createDocument?: boolean
): ApiAction<IExportResponse | ISendResponse> =>
	(email !== undefined
		? sendExportedInternalAcceptance(
			email, templateId, objectId, internalAcceptanceId, problems, inspections, createDocument
			)
		: exportInternalAcceptance(templateId, objectId, internalAcceptanceId, problems, inspections, createDocument));
