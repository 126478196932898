import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {useSpaceEntitiesIsLoading, useSpaceIsLoading} from '@src/core/hooks/queries/space';
import {useIsFetching} from '@tanstack/react-query';

export const useSpacePageIsLoading = (isLoading = false) => {
	const spaceIsLoading = useSpaceIsLoading();
	const checkListsIsLoading = useCheckListsIsLoading();
	const entitiesIsLoading = useSpaceEntitiesIsLoading();
	const checkRecordsIsLoading = !!useIsFetching({queryKey: checkRecordsQueryKeys.list()});
	const spaceOwnersIsLoading = !!useIsFetching({queryKey: spaceOwnersQueryKeys.list()});

	return (
		isLoading ||
		spaceIsLoading ||
		checkListsIsLoading ||
		entitiesIsLoading ||
		checkRecordsIsLoading ||
		spaceOwnersIsLoading
	);
};
