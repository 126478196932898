import {Dispatch, useCallback} from 'react';
import * as React from 'react';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	useExtractSpaceStatusesAsArrayByObjectId,
	useExtractSpaceStatusesDataByObjectId
} from '@src/core/hooks/queries/spaceStatusesSets/hooks';

interface ISpaceStatusProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	objectId: string;
}

const icon = <i className="tz-simple-arrow-20" />;
export const SpaceStatus = <S extends {status?: string}, E>({
	className,
	style,
	label = 'Статус помещения',
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	objectId
}: ISpaceStatusProps<S, E>) => {
	const {data: statusesArr} = useExtractSpaceStatusesAsArrayByObjectId(objectId);
	const {data: statuses} = useExtractSpaceStatusesDataByObjectId(objectId);

	const status = value ? statuses?.byId[value]?.name : '';
	const error = required && hasError ? 'Выберите статус' : undefined;

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'status', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'status'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={status}
						icon={icon}
						error={error}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{statusesArr?.map(type => (
						<SelectOption
							key={type.id}
							itemKey={type.id}
							content={type.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
