import {LegendGroup} from './LegendGroup';
import {useTranslation} from "react-i18next";
import {useTranslatedProblemStatuses} from "@src/core/hooks/translations/useProblemStatuses";

export const LegendContent = () => {
	const {t} = useTranslation();
	const problemStatuses = useTranslatedProblemStatuses();

	if (!problemStatuses) {
		return null;
	}
	return (
		<div className="objects-page__legend-content">
			<LegendGroup
				title={t('objectsPage.legend.problemStatuses')}
				items={problemStatuses}
			/>
		</div>
	);
};