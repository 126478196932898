import * as types from '../constants';

/**
 * Переключает видимость окна автономной работы
 *
 * @param value true/false - показать/скрыть, undefined - поменять на противоположное значение
 */
export const toggleOfflineDialogVisibility = (value?: boolean) => ({
	type: types.TOGGLE_VISIBILITY,
	payload: value
});
