import {Dispatch} from 'react';
import './EditableStructure.less';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {
	IEditableStructureAction,
	IEditableStructureState
} from '@src/core/hooks/states/useEditableStructureState';
import Text from '../editableFields/Text';
import {StructureTypeSelect} from '@src/components/editableFields/StructureTypeSelect';
import {PlanSelect} from '../editableFields/PlanSelect';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {useTranslation} from 'react-i18next';

interface IEditableStructureProps {
	objectId: string;
	editingState: IEditableStructureState;
	editingDispatch: Dispatch<IEditableStructureAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableStructure = (props: IEditableStructureProps) => {
	const {objectId, editingState, editingDispatch, fieldsSettings, saving} = props;
	const {data: plans} = usePlansAsArray(objectId);
	const {t} = useTranslation();

	return (
		<div className="editable-structure">
			<div className="editable-structure__info-grid">
				{fieldsSettings.name !== undefined && (
					<Text
						field="name"
						label={t('editableStructureDialog.nameField.label')}
						value={editingState.name}
						editingDispatch={editingDispatch}
						required={fieldsSettings.name.isRequired}
						disabled={saving}
						hasError={editingState.errors.name}
					/>
				)}

				{fieldsSettings.type !== undefined && (
					<StructureTypeSelect
						value={editingState.type}
						editingDispatch={editingDispatch}
						required={fieldsSettings.type.isRequired}
						disabled={saving}
						hasError={editingState.errors.type}
					/>
				)}

				{fieldsSettings.planIds !== undefined && plans && (
					<PlanSelect
						data={plans}
						value={editingState.planIds}
						editingDispatch={editingDispatch}
						required={fieldsSettings.planIds.isRequired}
						disabled={saving}
						hasError={editingState.errors.planIds}
					/>
				)}
				<Text
					field="externalId"
					label={t('editableStructureDialog.nameExternalId.label')}
					value={editingState.externalId}
					editingDispatch={editingDispatch}
					required={fieldsSettings.externalId.isRequired}
					disabled={saving}
					hasError={editingState.errors.externalId}
				/>
				<Text
					field="floor"
					label={t('editableStructureDialog.floorField.label')}
					value={editingState.floor}
					editingDispatch={editingDispatch}
					required={fieldsSettings.floor.isRequired}
					disabled={saving}
					hasError={editingState.errors.floor}
				/>
			</div>
		</div>
	);
};
