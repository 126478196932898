import {IEditBankAccountParams} from '@src/api/cache/bankAccounts/defaults/useBankAccountsMutationDefaults';
import {useEditBankAccountMutation} from '@src/api/cache/bankAccounts/mutations';
import {IEditBankAccountResponse} from '@src/api/backend/bankAccount/edit';

export const useEditBankAccount = (): ((
	params: IEditBankAccountParams
) => Promise<IEditBankAccountResponse | undefined>) => {
	const mutation = useEditBankAccountMutation();
	return (params: IEditBankAccountParams) => mutation.mutateAsync(params);
};
