import './Comments.less';
import List from './components/List';
import CommentReference from './components/CommentReference';
import {Button, LoadingPanel} from '@tehzor/ui-components';
import {EditableComment} from '@src/components/EditableComment';
import useAsync from 'react-use/lib/useAsync';
import {ICommentReferenceState, useCommentReference} from './hooks/useCommentReference';
import {useReplyDialog} from './hooks/useReplyDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {useCommentsCtx} from './utils/CommentsCtx';
import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';

interface ICommentsProps {
	className?:
		| string
		| {
				root?: string;
				list?: string;
				content?: string;
				item?: string;
				footer?: string;
				replyBtn?: string;
				reference?: string;
				input?: string;
		  };
	objectId: string;
	commentTypeId: ICommentTypeId;
	onImageClick?: (id: string, index: number) => void;
}

export const Comments = ({
	className,
	objectId,
	commentTypeId,
	onImageClick
}: ICommentsProps) => {
	const {t} = useTranslation();
	const {
		entityPermissions,
		resetComments,
		transferStatus,
		getComments,
		visibility,
		links
	} = useCommentsCtx();
	const location = useLocation();
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const refer = (location.state as {reference?: ICommentReferenceState} | null)?.reference;

	const loadingState = useAsync(async () => {
		resetComments();
		if (online && !transferStatus) {
			getComments();
		}
	}, [visibility, online]);

	const loadComments = useCallback(async () => {
		if (online && !transferStatus) {
			getComments();
		}
	}, [getComments, online, transferStatus]);

	// Состояние для ссылки на добавляемый/редактируемый комментарий
	const [reference, clearReference, setAddReference, setEditReference] =
		useCommentReference(refer);

	// Диалог добавления/редактирования ответа
	const [replyDialog, handleReplyAdd, handleReplyEdit] = useReplyDialog(
		objectId,
		links
	);

	const classes = convertClassNames(className);

	const commentInputVisible
		= entityPermissions.canAddComments || (reference !== undefined && reference.type === 'edit');

	return (
		<div className={classNames('comments', classes.root)}>
			{loadingState.loading ? (
				<LoadingPanel
					className="comments__loading-panel"
					active={loadingState.loading}
				/>
			) : (
				<List
					className={{root: classes.list, content: classes.content, item: classes.item}}
					objectId={objectId}
					selectedId={reference?.type === 'edit' ? reference.comment.id : undefined}
					onLoad={loadComments}
					onSubCommentAdd={entityPermissions.canAddComments ? setAddReference : undefined}
					onCommentEdit={setEditReference}
					onReplyEdit={handleReplyEdit}
					onImageClick={onImageClick}
				/>
			)}
			{(entityPermissions.canAddReplies || commentInputVisible) && (
				<div className={classNames('comments__footer', classes.footer)}>
					{entityPermissions.canAddReplies && (
						<Button
							className={classNames('comments__reply-btn', classes.replyBtn)}
							type="accent-blue"
							label={t('components.problemComments.addReplyButton.label')}
							outerTagType="button"
							onClick={handleReplyAdd}
						/>
					)}

					{reference !== undefined
						&& (reference.type === 'add' ? entityPermissions.canAddComments : true) && (
							<CommentReference
								className={classes.reference}
								commentTypeId={commentTypeId}
								commentId={reference.comment.id}
								type={reference.type}
								onClearReference={clearReference}
							/>
						)}

					{commentInputVisible && (
						<EditableComment
							className={classes.input}
							objectId={objectId}
							comment={reference?.type === 'edit'? reference.comment : undefined}
							links={links}
							parentId={reference?.type === 'add' ? reference.comment.id : undefined}
							onClearReference={clearReference}
						/>
					)}
				</div>
			)}
			{replyDialog}
		</div>
	);
};
