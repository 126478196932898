import {IGetTaskStatusesResponse} from '@src/api/backend/taskStatuses';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractTaskStatusesAsArray, extractTaskStatusesMap} from './selectors';

export const useTaskStatuses = <T = IGetTaskStatusesResponse>(
	select?: (data: IGetTaskStatusesResponse) => T
) =>
	useQuery<IGetTaskStatusesResponse, IError, T>({
		queryKey: [...restDictionariesQueryKeys.taskStatuses()],
		meta: {
			error: 'при загрузке статусов задач'
		},
		select
	});

export const useTaskStatusesAsArray = () => useTaskStatuses(extractTaskStatusesAsArray);

export const useTaskStatusesMap = () => useTaskStatuses(extractTaskStatusesMap);
