import EntitiesFilters, {
	CreatedByFilter,
	CreationDateFilter,
	ObjectsFilter,
	StatusesFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTaskPrioritiesAsArray} from '@src/core/hooks/queries/task-priorities/hooks';
import {useTaskStatusesAsArray} from '@src/core/hooks/queries/taskStatuses/hook';
import {useTaskTypesAsArray} from '@src/core/hooks/queries/taskTypes';
import {TasksIntervalFilter} from '@src/pages/TasksPage/components/filters/TasksIntervalFilter';
import {TasksSearch} from '@src/pages/TasksPage/components/filters/TasksSearch';
import {useTranslation} from 'react-i18next';
import {TaskResponsiblesFilter} from './TaskResponsiblesFilter';
import {ITasksFiltersState} from "@src/store/modules/settings/pages/tasks/interfaces";
import {useTasksPageDisplayMode} from "@src/core/hooks/queries/tasks/hooks";
import {TasksDisplayMode} from "@src/interfaces/TasksDisplayMode";

export const TasksFilters = () => {
	const {t} = useTranslation();
	const {state} = useEntitiesFiltersCtx<ITasksFiltersState>();
	const {data: allStatuses} = useTaskStatusesAsArray();
	const {data: allTypes} = useTaskTypesAsArray();
	const {data: allPriorities} = useTaskPrioritiesAsArray();
	const displayMode = useTasksPageDisplayMode();
	const isList = displayMode === TasksDisplayMode.LIST;

	return (
		<EntitiesFilters entity="tasks">
			{isList && (
				<div className="problems-page__search">
					<TasksSearch />
				</div>
			)}

			<ObjectsFilter
				value={state?.objects}
				label={t('entitiesFilters.objectsFilter.label')}
			/>

			{allTypes && (
				<StatusesFilter
					allStatuses={allTypes}
					statuses={state?.types}
					filterName="types"
					label={t('entitiesFilters.tasksTypesFilter.label')}
					translationDictionaryKey={dictionaryKeys.taskTypes}
				/>
			)}

			{allStatuses && (
				<StatusesFilter
					allStatuses={allStatuses}
					statuses={state?.statuses}
					label={t('entitiesFilters.statusesFilter.tasksStatuses.label')}
					translationDictionaryKey={dictionaryKeys.taskStatuses}
				/>
			)}

			{allPriorities && (
				<StatusesFilter
					allStatuses={allPriorities}
					statuses={state?.priorities}
					filterName="priorities"
					label={t('entitiesFilters.baseListFilterMobile.priority.label')}
					translationDictionaryKey={dictionaryKeys.taskPriorities}
				/>
			)}

			{isList && (
				<>
					<TasksIntervalFilter
						from={state?.taskIntervalStartFrom}
						to={state?.taskIntervalStartTo}
						label={t('tasksPage.filters.tasksIntervalFilter.from.label')}
						filterNameFrom="taskIntervalStartFrom"
						filterNameTo="taskIntervalStartTo"
					/>

					<TasksIntervalFilter
						from={state?.taskIntervalEndFrom}
						to={state?.taskIntervalEndTo}
						label={t('tasksPage.filters.tasksIntervalFilter.to.label')}
						filterNameFrom="taskIntervalEndFrom"
						filterNameTo="taskIntervalEndTo"
					/>

					<CreationDateFilter
						from={state?.createdAtFrom}
						to={state?.createdAtTo}
					/>
				</>
			)}

			<TaskResponsiblesFilter
				respUsers={state?.respUsers}
			/>

			<CreatedByFilter
				objectId="all"
				createdBy={state?.createdBy}
				label={t('entitiesFilters.CreatedByFilter.label')}
			/>
		</EntitiesFilters>
	);
};
