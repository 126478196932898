import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteSpaceStatusesSetResponse {
	success: boolean;
}

/**
 * Удаляет набор статусов помещений по id
 *
 * @param spaceStatusesSetId id набора статусов помещений
 */
export const requestDeleteSpaceStatusesSet = async (spaceStatusesSetId: string) => {
	const res = await httpRequests.withToken.delete<IDeleteSpaceStatusesSetResponse>(
		`space-statuses-sets/${spaceStatusesSetId}`
	);
	return res.data;
};
