import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export type IEditSpaceTypeDecorationSetResponse = ISpaceTypeDecorationSet;

/**
 * Изменяет набор типов отделки помещения
 *
 * @param fields данные для добавления
 */
export const editSpaceTypeDecorationSetRequest = async (fields: ISpaceTypeDecorationSet) => {
	const response = await httpRequests.withToken.post<IEditSpaceTypeDecorationSetResponse>(
		`space-type-decoration-sets/edit`,
		{...fields}
	);
	return response.data;
};
