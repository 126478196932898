import { useCallback } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useInternalAcceptanceExport} from '@src/core/hooks/export/useInternalAcceptanceExport';

interface IInternalAcceptanceExportProps {
	objectId: string;
	internalAcceptanceId: string;
}

const MobileInternalAcceptanceExport = ({objectId, internalAcceptanceId}: IInternalAcceptanceExportProps) => {
	const [exportDialog, openExportDialog] = useInternalAcceptanceExport(objectId);

	const handleExport = useCallback(() => {
		openExportDialog(internalAcceptanceId);
	}, [internalAcceptanceId, openExportDialog]);

	return (
		<>
			<IconButton onClick={handleExport}>
				<i className="tz-export-24"/>
			</IconButton>
			{exportDialog}
		</>
	);
};

export default MobileInternalAcceptanceExport;
