import {IDndRenderPreviewProps, TextField} from '@tehzor/ui-components';
import {IExtendedSpaceStatus} from '../interfaces/IExtendedSpaceStatus';

const dragIcon = <i className="tz-drag-and-drop-24 editable-space-statuses__preview-icon"/>

const classes = {
	wrap: 'editable-space-statuses__preview-text',
};

export const renderPreview = ({
	item
}: IDndRenderPreviewProps<IExtendedSpaceStatus>) => (item ? (
	<div className="editable-space-statuses__preview">
		{dragIcon}
		<TextField
			className={classes}
			value={item?.name}
			elementType="div"
		/>
	</div>
) : null);