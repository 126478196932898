import {ChangeEvent, useCallback, useEffect} from 'react';
import {
	ActionButtons,
	Button,
	Checkbox,
	Dialog,
	ExportTemplateSelect,
	LoadingPanel,
	TextField
} from '@tehzor/ui-components';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import './ExportDialog.less';
import {
	changeEmailAddress,
	changeSelectedTemplate,
	toggleEmailEnabled,
	toggleMakeDocument
} from '@src/actions/settings/export';
import {getSelectedTemplate} from '@src/selectors/settings/export';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useDocumentsPermissions} from '@src/core/hooks/permissions/useDocumentsPermissions';
import {
	useFetchExportTemplates,
	useGetExportTemplatesAsArray
} from '@src/core/hooks/queries/exportTemplates/hooks';
import {useTranslation} from 'react-i18next';

interface IExportDialogProps {
	// Необходим для сохранения выбранного шаблона в локальном хранилище
	pageKey: string;
	destinations: ExportTemplatesDestinationId[];
	isOpen: boolean;

	onExport(
		template: IExportTemplate,
		email?: string,
		createDocument?: boolean
	): Promise<void> | void;

	onClose(): void;
}

export const ExportDialog = ({
	pageKey,
	destinations,
	isOpen,
	onExport,
	onClose
}: IExportDialogProps) => {
	const {t} = useTranslation();

	const dispatch = useAppDispatch();

	const {data: templates} = useGetExportTemplatesAsArray();
	const {data: exportTemplates} = useFetchExportTemplates();
	const selectedTemplate = useAppSelector(s => getSelectedTemplate(s, pageKey));
	const isEmailEnabled = useAppSelector(s => s.settings.export.isEmailEnabled);
	const createDocument = useAppSelector(s => s.settings.export.createDocument);
	const emailAddress = useAppSelector(s => s.settings.export.emailAddress);
	const documentTypeId = exportTemplates?.byId?.[selectedTemplate]?.documentTypeId;

	const {canAdd: canAddDocuments} = useDocumentsPermissions();

	const changeTemplate = useCallback(
		(value: string) => {
			dispatch(changeSelectedTemplate(pageKey, value));
		},
		[pageKey]
	);

	useEffect(() => {
		if (!selectedTemplate) {
			const first = templates?.find(item => destinations.includes(item.destination));
			if (first) {
				changeTemplate(first.id);
			}
		}
	}, [selectedTemplate, destinations, templates]);

	const handleEmailEnabledChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		dispatch(toggleEmailEnabled(event.target.checked));
	}, []);

	const handleMakeDocumentChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		dispatch(toggleMakeDocument(event.target.checked));
	}, []);

	const [exportingState, handleOk] = useAsyncFn(async () => {
		const template = templates?.find(item => item.id === selectedTemplate);
		if (template) {
			await onExport(
				template,
				isEmailEnabled ? emailAddress : undefined,
				documentTypeId && canAddDocuments ? createDocument : undefined
			);
		}
	}, [
		selectedTemplate,
		isEmailEnabled,
		emailAddress,
		templates,
		createDocument,
		documentTypeId,
		canAddDocuments,
		onExport
	]);

	const handleChangeEmailAddress = useCallback((value: string) => {
		dispatch(changeEmailAddress(value));
	}, []);

	const isBusy = exportingState.loading;

	return (
		<Dialog
			className={{root: 'export-dialog', body: 'export-dialog__dialog-body'}}
			isOpen={isOpen}
			title={t('exportDialog.dialog.title')}
			footer={
				<ActionButtons className="export-dialog__action-buttons">
					<Button
						type="accent-blue"
						label={
							isEmailEnabled
								? t('exportDialog.dialog.actionButtons.button.send.label')
								: t('exportDialog.dialog.actionButtons.button.download.label')
						}
						disabled={isBusy || !selectedTemplate || (isEmailEnabled && !emailAddress)}
						onClick={handleOk}
					/>
					<Button
						type="cancel"
						label={t('exportDialog.dialog.actionButtons.button.cancel.label')}
						onClick={onClose}
					/>
				</ActionButtons>
			}
			onRequestClose={onClose}
		>
			<LoadingPanel
				className="export-dialog__content"
				overlayClassName="export-dialog__loading-panel"
				active={isBusy}
			>
				<>
					<div className="export-dialog__template-title">
						{t('exportDialog.dialog.loadingPanel.title')}
					</div>

					<ExportTemplateSelect
						className="export-dialog__template-select"
						value={selectedTemplate}
						data={templates}
						availableDestinations={destinations}
						onChange={changeTemplate}
					/>

					{documentTypeId && canAddDocuments && (
						<Checkbox
							className="export-dialog__make-document-cb"
							checked={createDocument}
							onChange={handleMakeDocumentChange}
						>
							{t(
								'exportDialog.dialog.loadingPanel.exportTemplateSelect.checkbox.makeDocument.children'
							)}
						</Checkbox>
					)}

					<Checkbox
						className="export-dialog__email-enabled-cb"
						checked={isEmailEnabled}
						onChange={handleEmailEnabledChange}
					>
						{t(
							'exportDialog.dialog.loadingPanel.exportTemplateSelect.checkbox.sendOnEmail.children'
						)}
					</Checkbox>

					{isEmailEnabled && (
						<>
							<div className="export-dialog__email-title">
								{t(
									'exportDialog.dialog.loadingPanel.exportTemplateSelect.emailTitle'
								)}
							</div>

							<TextField
								className="export-dialog__email-address"
								elementType="input"
								value={emailAddress}
								onChange={handleChangeEmailAddress}
							/>
						</>
					)}
				</>
			</LoadingPanel>
		</Dialog>
	);
};
