import {QueryClient} from '@tanstack/react-query';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {checkRecordsQueryKeys} from '../../keys';

export const updateCheckRecordsCache = (
	record: ICheckRecord,
	queryClient: QueryClient,
	objects?: string[]
) => {
	queryClient.setQueriesData<INormalizedData<ICheckRecord>>(
		{
			queryKey: [...checkRecordsQueryKeys.list(), undefined, objects]
		},
		data => {
			if (data) {
				if (!data?.byId[record.id]) {
					return {
						byId: {
							...data.byId,
							[record.id]: record
						},
						allIds: [...data.allIds, record.id]
					};
				}
				return {
					byId: {
						...data.byId,
						[record.id]: record
					},
					allIds: [...data.allIds]
				};
			}
			return data;
		}
	);
};
