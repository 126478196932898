import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery} from '@tanstack/react-query';
import {IGetSpaceChecksResponse} from '../interfaces';
import {extractCheckAllIds, extractCheckAsArray} from './selectors';

export const useSpaceChecks = <T = IGetSpaceChecksResponse>(
	objectId: string,
	spaceId: string,
	permission = true,
	select?: (data: IGetSpaceChecksResponse) => T
) =>
	useQuery({
		queryKey: spacesQueryKeys.spaceChecks(objectId, spaceId),
		meta: {
			error: 'при загрузке проверок'
		},
		select,
		enabled: permission
	});

export const useSpaceChecksAsArray = (objectId: string, spaceId: string, permission = true) =>
	useSpaceChecks(objectId, spaceId, permission, extractCheckAsArray);

export const useExtractSpaceChecksAllIds = (objectId: string, spaceId: string, permission = true) =>
	useSpaceChecks(objectId, spaceId, permission, extractCheckAllIds);
