import {useTranslation} from 'react-i18next';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useMemo} from 'react';
import {getDesktopColumns} from '../utils/getDesktopColumns';
import {getMobileColumns} from '../utils/getMobileColumns';

export const useTableColumns = () => {
	const {t} = useTranslation();
	const isDesktop = useIsDesktop();

	return useMemo(() => (isDesktop ? getDesktopColumns(t) : getMobileColumns()), [isDesktop, t]);
};
