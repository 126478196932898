import {flatFilter} from './flatFilter';

interface ITreeFilterReturn<T> {
	filteredData: T[];
	expanded?: T[];
}

export const treeFilter = <T extends {id: string; parentId?: string}>(
	treeData: T[],
	field: string,
	search: string
): ITreeFilterReturn<T> => {
	if (search === '') {
		return {
			filteredData: treeData,
			expanded: []
		};
	}

	const filteredTreeData = [...new Set(flatFilter(treeData, field, search))];

	const findDescendants = (treeItem: T, tree: T[], visited = new Set<string>()): T[] => {
		// Без этой проверки происходит переполнение стека вызовов
		// TODO: Возможно надо более глубоко понять связи в дереве и обрабатывать такую ситуацию иначе
		if (visited.has(treeItem.id)) {
			return [];
		}
		visited.add(treeItem.id);

		const descendant = tree.find(item => item.id === treeItem.parentId);
		if (descendant) {
			return [descendant, ...findDescendants(descendant, tree, visited)];
		}
		return [];
	};

	const findAncestors = (treeItems: T[], tree: T[], visited = new Set<string>()): T[] => {
		// Без этой фильтрации происходит переполнение стека вызовов
		// TODO: Возможно надо более глубоко понять связи в дереве и обрабатывать такую ситуацию иначе
		const filteredTreeItems = treeItems.filter(item => !visited.has(item.id));

		const ancestors = tree.filter(item =>
			filteredTreeItems.find(treeItem => treeItem.id === item.parentId)
		);
		filteredTreeItems.forEach(item => visited.add(item.id));

		if (ancestors.length) {
			return [...ancestors, ...findAncestors(ancestors, tree, visited)];
		}
		return [];
	};

	const returnData: ITreeFilterReturn<T> = {filteredData: []};
	for (const treeItem of filteredTreeData) {
		const descendants = findDescendants(treeItem, treeData);
		const ancestors = findAncestors([treeItem], treeData);
		if (descendants.length) {
			returnData.expanded = descendants;
		}
		returnData.filteredData.push(treeItem, ...ancestors, ...descendants);
	}

	return {
		filteredData: [...new Set(returnData.filteredData)],
		expanded: [...new Set(returnData.expanded)]
	};
};
