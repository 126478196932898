import {OmitKeyof, QueryObserverOptions, QueryState} from '@tanstack/react-query';

export const getStates = (
	state?: QueryState,
	settings?: OmitKeyof<QueryObserverOptions, 'queryKey', 'strictly'>
): [boolean, boolean, boolean, number | undefined] => {
	const isStale =
		((state?.dataUpdatedAt as number) || 0) + ((settings?.staleTime as number) || 0) <
		Date.now();
	return [!!state?.error, isStale, state?.fetchStatus === 'paused', state?.dataUpdatedAt];
};
