import {createMutation} from 'react-query-kit';
import {
	IAddSpaceOwnerParams,
	IEditSpaceOwnerParams
} from '../defaults/useSpaceOwnersMutationDefaults';
import {spaceOwnersQueryKeys} from '../keys';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export const useAddSpaceOwnerMutation = createMutation<ISpaceOwner, IAddSpaceOwnerParams>({
	mutationKey: spaceOwnersQueryKeys.add(),
	meta: {
		entity: 'space-owner',
		type: 'creation'
	}
});

export const useEditSpaceOwnerMutation = createMutation<ISpaceOwner, IEditSpaceOwnerParams>({
	mutationKey: spaceOwnersQueryKeys.edit(),
	meta: {
		entity: 'space-owner',
		type: 'editing'
	}
});
