import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IMarkerStory} from '@tehzor/tools/interfaces/markers/IMarkerStory';
import {wsConnector} from '../wsConnector';

export type IGetProblemMarkersStoriesResponse = INormalizedData<IMarkerStory>;

/**
 * Получает историю маркеров нарушения по id объекта и id нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const requestProblemMarkersStories = (objectId: string, problemId: string) => {
	const params = {objectId, problemId};
	return wsConnector.sendAuthorizedRequest<IGetProblemMarkersStoriesResponse>(
		'getProblemMarkersStories',
		params
	);
};
