import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetObjectsResponse = INormalizedData<IObject>;

/**
 * Получает список объектов
 */
export const requestObjects = async () => {
	const response = await httpRequests.withToken.get<IGetObjectsResponse>('objects');
	return response.data;
};
