import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {extractLocalSpaceOwnersJournal} from './selector';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback} from 'react';

export const useLocalSpaceOwnersJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: spaceOwnersQueryKeys.add()});
	const selector = useCallback(
		(spaceOwners: ISpaceOwner[]) => extractLocalSpaceOwnersJournal(spaceOwners, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<ISpaceOwner[], unknown, IOfflineJournalEntity[]>({
		queryKey: spaceOwnersQueryKeys.localList(),
		select: selector
	});
	return data || [];
};
