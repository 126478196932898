import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import IReason from '@tehzor/tools/interfaces/IReason';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import isEqual from 'lodash/isEqual';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';

export type IEditableProblemState = IEditableEntityState<{
	templateId?: string;
	categoryId?: string | null;
	plannedFixDate?: number;
	reason?: IReason | null;
	planId?: string;
	location?: ILocation;
	floor: string;
	firstAssignedInspector?: string;
	inspectors: string[];
	inspectorsActiveGroup?: string;
	performers: string[];
	watchers: string[];
	performersActiveGroup?: string;
	description: string;
	prescription: string;
	attachments: IAttachment[];
	critical: boolean;
	createdAt?: number;
	problemTags: string[];
	contractId?: string | null;
	initialRuleParams?: IRuleParams;
}>;

export type IEditableProblemAction = IEditableEntityAction<IEditableProblemState, ISavingProblem>;

const makeEmptyState = (): IEditableProblemState => ({
	categoryId: undefined,
	plannedFixDate: undefined,
	reason: undefined,
	planId: undefined,
	location: undefined,
	floor: '',
	firstAssignedInspector: undefined,
	inspectors: [],
	inspectorsActiveGroup: undefined,
	performers: [],
	watchers: [],
	performersActiveGroup: undefined,
	description: '',
	prescription: '',
	attachments: [],
	problemTags: [],
	critical: false,
	createdAt: undefined,
	contractId: undefined,
	initialRuleParams: undefined,
	customFields: {},
	errors: {
		categoryId: false,
		plannedFixDate: false,
		reason: false,
		planId: false,
		location: false,
		floor: false,
		inspectors: false,
		inspectorsActiveGroup: false,
		performers: false,
		performersActiveGroup: false,
		watchers: false,
		description: false,
		prescription: false,
		attachments: false,
		critical: false,
		problemTags: false,
		contractId: false,
		initialRuleParams: false,
		customFields: {}
	},
	exceptions: {}
});

export const makeDefaultData = (
	problem?: Omit<
		IProblem,
		'createdBy' | 'modifiedBy' | 'initialGroupLeader' | 'activeGroupLeader'
	>
): ISavingProblem | undefined => {
	if (!problem) {
		return undefined;
	}
	return {
		categoryId: problem.categoryId,
		plannedFixDate: problem.plannedFixDate,
		reason: problem.reason,
		planId: problem.plan?.id,
		location: problem.location,
		floor: problem.floor,
		inspectors: problem.inspectors,
		inspectorsActiveGroup: problem.inspectorsActiveGroup,
		performers: problem.performers,
		watchers: problem.watchers,
		performersActiveGroup: problem.performersActiveGroup,
		description: problem.description,
		prescription: problem.prescription,
		attachments: problem.attachments,
		critical: problem.critical,
		createdAt: problem.createdAt,
		problemTags: problem.problemTags,
		contractId: problem.contractId,
		customFields: problem.customFields
	};
};

export const init = (original?: ISavingProblem): IEditableProblemState => {
	const empty = makeEmptyState();
	if (!original) {
		return empty;
	}

	return {
		categoryId: original.categoryId ?? undefined,
		plannedFixDate: original.plannedFixDate ?? undefined,
		reason: original.reason ?? undefined,
		planId: original.planId ?? undefined,
		location: original.location ?? undefined,
		floor: original.floor ?? '',
		inspectors: original.inspectors ?? [],
		inspectorsActiveGroup: original.inspectorsActiveGroup ?? undefined,
		performers: original.performers ?? [],
		watchers: original.watchers ?? [],
		performersActiveGroup: original.performersActiveGroup ?? undefined,
		description: original.description ?? '',
		prescription: original.prescription ?? '',
		attachments: original.attachments ?? [],
		critical: original.critical ?? false,
		createdAt: original.createdAt ?? undefined,
		problemTags: original.problemTags ?? [],
		contractId: original.contractId ?? undefined,
		customFields: original.customFields,
		initialRuleParams: {
			categoryId: original.categoryId ?? undefined,
			planId: original.planId ?? undefined
		},
		errors: empty.errors,
		exceptions: empty.exceptions
	};
};

const isCategoryEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.categoryId ? original.categoryId !== state.categoryId : !!state.categoryId;

const isFixDateEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.plannedFixDate
		? original.plannedFixDate !== state.plannedFixDate
		: !!state.plannedFixDate;

const isReasonEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.reason ? !isEqual(original.reason, state.reason) : !!state.reason;

const isPlanEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.planId ? original.planId !== state.planId : !!state.planId;

const isLocationEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.location ? !isEqual(original.location, state.location) : !!state.location;

const isFloorEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.floor ? original.floor !== state.floor : !!state.floor;

const areInspectorsEdited = (
	state: IEditableProblemState,
	original?: ISavingProblem,
	checkToSave?: boolean
) => {
	if (original?.inspectors?.length) {
		return (
			original.inspectors.length !== state.inspectors.length ||
			original.inspectors.some(id => !state.inspectors.includes(id))
		);
	}

	if (state.inspectors.length && state.firstAssignedInspector && !checkToSave) {
		return !state.inspectors.every(inspector => inspector === state.firstAssignedInspector);
	}

	return !!state.inspectors.length;
};

const isInspectorsActiveGroupEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.inspectorsActiveGroup
		? original.inspectorsActiveGroup !== state.inspectorsActiveGroup
		: !!state.inspectorsActiveGroup;

const arePerformersEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.performers
		? original.performers.length !== state.performers.length ||
		  original.performers.some(id => !state.performers.includes(id))
		: !!state.performers.length;

const isPerformersActiveGroupEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.performersActiveGroup
		? original.performersActiveGroup !== state.performersActiveGroup
		: !!state.performersActiveGroup;

const areWatchersEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.watchers
		? original.watchers.length !== state.watchers.length ||
		  original.watchers.some(id => !state.watchers.includes(id))
		: !!state.watchers.length;

const isDescriptionEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.description ? original.description !== state.description : !!state.description;

const isPrescriptionEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.prescription ? original.prescription !== state.prescription : !!state.prescription;

const areAttachmentsEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.attachments ? original.attachments.length !== state.attachments.length : false;

const isCriticalEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.critical ? original.critical !== state.critical : state.critical;

const areProblemTagsEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.problemTags
		? original.problemTags.length !== state.problemTags.length ||
		  original.problemTags.some(id => !state.problemTags.includes(id))
		: !!state.problemTags.length;

const isContractEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.contractId ? original.contractId !== state.contractId : !!state.contractId;

const areCustomFieldsEdited = (state: IEditableProblemState, original?: ISavingProblem) => {
	if (original?.customFields && state.customFields) {
		return !isEqual(state.customFields, original?.customFields);
	}
	return state.customFields ? !!Object.keys(state.customFields).length : false;
};

/**
 * Возвращает значение, показывающее были ли отредактированы поля нарушения
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (
	state: IEditableProblemState,
	original?: ISavingProblem,
	checkToSave?: boolean
): boolean =>
	isEntityEdited(
		state,
		original,
		isCategoryEdited,
		isFixDateEdited,
		isReasonEdited,
		isPlanEdited,
		isLocationEdited,
		isFloorEdited,
		(state, original) => areInspectorsEdited(state, original, checkToSave),
		isInspectorsActiveGroupEdited,
		arePerformersEdited,
		areWatchersEdited,
		isPerformersActiveGroupEdited,
		isDescriptionEdited,
		isPrescriptionEdited,
		isCriticalEdited,
		areAttachmentsEdited,
		areProblemTagsEdited,
		isContractEdited,
		areCustomFieldsEdited
	);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	categoryId: (state: IEditableProblemState) =>
		state.exceptions?.categoryId ? false : !state.categoryId,
	plannedFixDate: (state: IEditableProblemState) => !state.plannedFixDate,
	reason: (state: IEditableProblemState) => !state.reason,
	planId: (state: IEditableProblemState) => !state.planId,
	location: (state: IEditableProblemState) => !state.location,
	floor: (state: IEditableProblemState) => !state.floor,
	inspectors: (state: IEditableProblemState) =>
		state.exceptions?.inspectors ? false : !state.inspectors.length,
	inspectorsActiveGroup: (state: IEditableProblemState) => !state.inspectorsActiveGroup,
	performers: (state: IEditableProblemState) =>
		state.exceptions?.performers
			? false
			: !state.performers.length && !state.performersActiveGroup,
	performersActiveGroup: (state: IEditableProblemState) =>
		state.exceptions?.performers ? false : !state.performersActiveGroup,
	watchers: (state: IEditableProblemState) => !state.watchers.length,
	description: (state: IEditableProblemState) => !state.description,
	prescription: (state: IEditableProblemState) => !state.prescription,
	critical: () => false,
	problemTags: (state: IEditableProblemState) =>
		state.exceptions?.problemTags ? false : !(state.problemTags && state.problemTags.length),
	attachments: (state: IEditableProblemState) => !state.attachments.length,
	contractId: (state: IEditableProblemState) =>
		state.exceptions?.contractId ? false : !state.contractId,
	customFields: (state: IEditableProblemState, key?: string) => {
		if (!key) {
			return false;
		}

		const value = state.customFields?.[key];
		if (Array.isArray(value)) {
			return !value.length;
		}

		return !value;
	}
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableProblemState,
	settings: {[k: string]: IObjectFieldSetting}
) =>
	(settings.attachments?.isRequired || settings.attachments?.required) &&
	errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableProblemState,
	original?: ISavingProblem,
	onlyEdited?: boolean
): ISavingProblem => {
	if (!onlyEdited) {
		return {
			categoryId: state.categoryId,
			plannedFixDate: state.plannedFixDate,
			reason: state.reason,
			planId: state.planId,
			location: state.location || null,
			floor: state.floor,
			inspectors: state.inspectors,
			inspectorsActiveGroup: state.inspectorsActiveGroup,
			performers: state.performers,
			watchers: state.watchers,
			performersActiveGroup: state.performersActiveGroup,
			description: state.description,
			prescription: state.prescription,
			attachments: state.attachments,
			critical: state.critical,
			createdAt: state.createdAt,
			problemTags: state.problemTags,
			contractId: state.contractId,
			customFields: state.customFields
		};
	}
	const problem: ISavingProblem = {};
	if (isCategoryEdited(state, original)) {
		problem.categoryId = state.categoryId || null;
	}
	if (isFixDateEdited(state, original)) {
		problem.plannedFixDate = state.plannedFixDate || null;
	}
	if (isReasonEdited(state, original)) {
		problem.reason = state.reason || null;
	}
	if (isPlanEdited(state, original)) {
		problem.planId = state.planId || null;
	}
	if (isLocationEdited(state, original)) {
		problem.location = state.location || null;
	}
	if (isFloorEdited(state, original)) {
		problem.floor = state.floor || null;
	}
	if (
		isInspectorsActiveGroupEdited(state, original) ||
		areInspectorsEdited(state, original, true)
	) {
		problem.inspectorsActiveGroup = state.inspectorsActiveGroup;
		problem.inspectors = state.inspectors;
	}
	if (arePerformersEdited(state, original) || isPerformersActiveGroupEdited(state, original)) {
		problem.performers = state.performers;
		problem.performersActiveGroup = state.performersActiveGroup;
	}
	if (areWatchersEdited(state, original)) {
		problem.watchers = state.watchers;
	}
	if (isDescriptionEdited(state, original)) {
		problem.description = state.description;
	}
	if (isPrescriptionEdited(state, original)) {
		problem.prescription = state.prescription;
	}
	if (isCriticalEdited(state, original)) {
		problem.critical = state.critical;
	}
	if (areProblemTagsEdited(state, original)) {
		problem.problemTags = state.problemTags;
	}
	if (areAttachmentsEdited(state, original)) {
		problem.attachments = state.attachments.map(item => ({id: item.id}));
	}
	if (isContractEdited(state, original)) {
		problem.contractId = state.contractId || null;
	}
	if (areCustomFieldsEdited(state, original)) {
		problem.customFields = state.customFields;
	}
	return problem;
};
