import {Column} from 'react-table';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {WorkingGroupInfoMobileCell} from '../WorkingGroupInfoMobileCell';

export const mobileColumns: Array<Column<IEnrichedWorkingGroup>> = [
	{
		id: 'info',
		Cell: WorkingGroupInfoMobileCell,
		className: 'manage-working-groups-page__mobile-cell'
	}
];
