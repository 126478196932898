import {ReactNode} from 'react';
import styles from './CentralBarDesctop.module.less';

interface ICentralBarProps {
	children: ReactNode;
}

export const CentralBarDesktop = ({children}: ICentralBarProps) => (
	<div className={styles.wrapper}>{children}</div>
);
