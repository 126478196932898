import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery} from '@tanstack/react-query';
import {IGetSpaceProblemsResponse} from '../interfaces';
import {extractProblemsAsArray} from './selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

export const useSpaceProblems = <T = IGetSpaceProblemsResponse>(
	objectId: string,
	spaceId: string,
	permission?: boolean,
	select?: (data: IGetSpaceProblemsResponse) => T
) => {
	const roles = useAppSelector(extractUserRoles);
	const canView = useMemo(() => hasPermission(roles, 'problemsView'), [roles]);

	return useQuery({
		queryKey: spacesQueryKeys.spaceProblems(objectId, spaceId),
		meta: {
			error: 'при загрузке нарушений помещения'
		},
		select,
		enabled: permission || canView
	});
};

export const useSpaceProblemsAsArray = (objectId: string, spaceId: string, permission?: boolean) =>
	useSpaceProblems(objectId, spaceId, permission, extractProblemsAsArray);
