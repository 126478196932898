import {IGetProblemTemplatesSetsResponse} from '@src/api/backend/problemTemplatesSets';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {
	extractProblemTemplatesAsArray,
	extractProblemTemplatesSetsAsArray
} from '@src/core/hooks/queries/problemTemplatesSets/selectors';
import {useCallback} from 'react';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export const useProblemTemplatesSets = <T = IGetProblemTemplatesSetsResponse>(
	select?: (data: IGetProblemTemplatesSetsResponse) => T
) =>
	useQuery<IGetProblemTemplatesSetsResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.problemTemplatesSets(),
		meta: {
			error: 'при загрузке наборов шаблонов нарушений'
		},
		select
	});

export const useProblemTemplatesSetsAsArray = () =>
	useProblemTemplatesSets(extractProblemTemplatesSetsAsArray);

export const useProblemTemplatesAsArray = (object?: IObject) => {
	const selector = useCallback(
		(data: IGetProblemTemplatesSetsResponse) => extractProblemTemplatesAsArray(data, object),
		[object]
	);

	return useProblemTemplatesSets(selector);
};
