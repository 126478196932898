import {IGetResponsibilityRulesResponse} from '@src/api/backend/responsibilityRules';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {
	extractResponsibilityRulesAsArray,
	extractResponsibilityRulesForWorkingGroupAsArray
} from '@src/core/hooks/queries/responsibilityRules/selectors';
import {useCallback} from 'react';

export const useResponsibilityRules = <T = IGetResponsibilityRulesResponse>(
	select?: (data: IGetResponsibilityRulesResponse) => T
) =>
	useQuery<IGetResponsibilityRulesResponse, IError, T>({
		queryKey: responsibilityRulesQueryKeys.list(),
		select,
		meta: {
			error: 'при загрузке правил делегирования'
		}
	});

export const useResponsibilityRulesAsArray = () =>
	useResponsibilityRules(extractResponsibilityRulesAsArray);

export const useResponsibilityRulesForWorkingGroupAsArray = (workingGroupId?: string) => {
	const select = useCallback(
		(data: IGetResponsibilityRulesResponse) =>
			extractResponsibilityRulesForWorkingGroupAsArray(data, workingGroupId),
		[workingGroupId]
	);

	return useResponsibilityRules(select);
};
