import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {ISavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/ISavingResponsibilityRule';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditResponsibilityRuleResponse = IResponsibilityRule;

/**
 * Обновляет одно правило ответственности
 *
 * @param ruleId id правила
 * @param groupId id группы
 * @param rule данные для изменения
 */
export const makeResponsibilityRuleEditRequest = async (
	ruleId: string,
	groupId: string,
	rule: ISavingResponsibilityRule
) => {
	const response = await httpRequests.withToken.patch<IEditResponsibilityRuleResponse>(
		'responsibility-rules',
		{
			ruleId,
			groupId,
			...rule
		}
	);
	return response.data;
};
