import {useTranslation} from 'react-i18next';

interface IMobileLocationDetailsProps {
	plan: string;
	floor: string;
	location: string;
}
export const MobileLocationDetails = ({plan, floor, location}: IMobileLocationDetailsProps) => {
	const {t} = useTranslation();
	return (
		<>
			{plan && (
				<div className="problems-page__m-table-location-line">
					<span className="problems-page__m-table-location-title">
						{t('problemsPage.locationCell.plan')}:
					</span>
					<span className="problems-page__m-table-location-subtitle">{plan}</span>
				</div>
			)}
			{floor && (
				<div className="problems-page__m-table-location-line">
					<span className="problems-page__m-table-location-title">
						{t('problemsPage.locationCell.floor')}:
					</span>
					<span className="problems-page__m-table-location-subtitle">{floor}</span>
				</div>
			)}
			{location && (
				<div className="problems-page__m-table-location-line">
					<span className="problems-page__m-table-location-title">
						{t('problemsPage.locationCell.location')}:
					</span>
					<span className="problems-page__m-table-location-subtitle">{location}</span>
				</div>
			)}
		</>
	);
};
