import {createReducer} from '@reduxjs/toolkit';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import * as types from '../constants/problemsData';
import {RESET} from '../constants/page';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {ADD_SUCCESS, DELETE_SUCCESS, EDIT_STATUS_SUCCESS} from '../../problem/constants';

export interface IWorkAcceptancesProblemsStatsState {
	data: Record<string, IEntityProblemsData>;
	loading: boolean;
	loaded: boolean;
}

export const getInitialState = (): IWorkAcceptancesProblemsStatsState => ({
	data: {},
	loading: false,
	loaded: false
});

export default createReducer<IWorkAcceptancesProblemsStatsState>(getInitialState(), {
	[types.GET_REQUEST]: state => {
		state.data = {};
		state.loaded = false;
		state.loading = true;
	},
	[types.GET_SUCCESS]: (state, {payload}: {payload: Record<string, IEntityProblemsData>}) => {
		state.data = payload;
		state.loaded = true;
		state.loading = false;
	},
	[types.GET_FAILURE]: state => {
		state.data = {};
		state.loaded = false;
		state.loading = false;
	},
	[EDIT_STATUS_SUCCESS]: getInitialState,
	[ADD_SUCCESS]: getInitialState,
	[DELETE_SUCCESS]: getInitialState,
	[RESET]: getInitialState,
	[CLEAR_STORE]: getInitialState
});
