import {createMutation} from 'react-query-kit';
import {
	IEditSpaceIndicatorsMutationParams,
	IEditSpaceStatusMutationParams
} from '../defaults/useSpacesMutationDefaults';
import {
	IEditSpaceIndicatorsPayload,
	IEditSpaceStatusPayload
} from '@src/store/modules/entities/space/actions';
import {spacesQueryKeys} from '../keys';

export const useEditSpaceIndicatorsMutation = createMutation<
	IEditSpaceIndicatorsPayload,
	IEditSpaceIndicatorsMutationParams
>({
	mutationKey: spacesQueryKeys.indicators.edit(),
	meta: {
		entity: 'space',
		type: 'editing-indicators'
	}
});

export const useEditSpaceStatusMutation = createMutation<
	IEditSpaceStatusPayload,
	IEditSpaceStatusMutationParams
>({
	mutationKey: spacesQueryKeys.status.edit(),
	meta: {
		entity: 'space',
		type: 'editing-status'
	}
});
