import {useCallback} from 'react';
import {IGetListStructuresResponse} from '@/shared/api/structures/getStructuresList';
import {extractFilteredStructuresListAsArray} from '../selectors/extractFilteredStructuresListAsArray';
import {useStructuresList} from './useStructuresList';

export const useFilteredStructuresListAsArray = (objectId: string, structureIds?: string[]) => {
	const selector = useCallback(
		(data: IGetListStructuresResponse) =>
			extractFilteredStructuresListAsArray(data, structureIds),
		[structureIds]
	);
	return useStructuresList(objectId, selector);
};
