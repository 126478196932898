import {DownloadableFile} from '@tehzor/ui-components';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';

interface IDocumentsProps {
	internalAcceptance?: IInternalAcceptance;
}

const Documents = ({internalAcceptance}: IDocumentsProps) => (
	<div className="internal-acceptance-page__documents">
		{internalAcceptance?.attachments?.map(item =>
			(item.full ? (
				<div
					key={item.id}
					className="internal-acceptance-page__documents-file"
				>
					<DownloadableFile
						name={item.full.name}
						url={item.full.url}
						type={item.full.type}
						size={item.full.size}
					/>
				</div>
			) : null))}
	</div>
);
export default Documents;
