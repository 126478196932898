import DesktopMenu from './Menu.desktop';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCheckPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import {changeEntitiesVisibility} from '@src/store/modules/settings/pages/check/actions';
import VisibilityFilter from '@src/components/VisibilityFilter';
import {useCheckProblemsArray} from '@src/core/hooks/queries/check/problems/hooks';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';

interface IDesktopActionsProps {
	objectId: string;
	check: ILinkedCheck;
}

const DesktopActions = ({objectId, check}: IDesktopActionsProps) => {
	const {t} = useTranslation();

	const {data: problems} = useCheckProblemsArray(objectId, check.id);
	const permissions = useCheckPermissions(objectId, check, problems);
	const visibility = useAppSelector(s => s.settings.pages.check.entitiesVisibility);

	const visibilityTitles = useMemo(
		() => ({
			problems: t('checksPage.actions.visibility.problems'),
			inspections: t('checksPage.actions.visibility.inspections')
		}),
		[t]
	);

	return (
		<div className="check-page__d-controls">
			<div className="check-page__d-controls-left">
				<VisibilityFilter
					value={visibility}
					titlesMap={visibilityTitles}
					onChange={changeEntitiesVisibility}
				/>
			</div>

			<div className="check-page__d-controls-right">
				<DesktopMenu
					objectId={objectId}
					check={check}
					permissions={permissions}
				/>
			</div>
		</div>
	);
};

export default DesktopActions;
