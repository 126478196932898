import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICustomField} from '@tehzor/tools/interfaces/fields';

export type IGetCustomFieldsResponse = INormalizedData<ICustomField>;

/**
 * Возвращает пользовательские поля
 */
export const requestGetCustomFields = async () => {
	const res = await httpRequests.withToken.get<IGetCustomFieldsResponse>('fields/get-custom');

	return res.data;
};
