import {useEditableContractDialog} from '@src/components/EditableContractDialog/hooks/useEditableContractDialog';
import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import { memo, useCallback } from 'react';

interface IAddButtonProps {
	canAdd?: boolean;
}

const plusIcon = <i className="tz-plus-20" />;

export const AddButton = memo(({canAdd = false}: IAddButtonProps) => {
	const isLargeTablet = useIsLargeTablet();
	const [contractDialog, openContractDialog] = useEditableContractDialog();

	const handleClick = useCallback(() => {
		openContractDialog();
	}, [openContractDialog]);

	return canAdd ? (
		<>
			{isLargeTablet ? (
				<Button
					type="accent-blue"
					leftIcon={plusIcon}
					label="Добавить договор"
					onClick={handleClick}
				/>
			) : (
				<FloatingActionButton
					icon={plusIcon}
					label="Договор"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleClick}
					className="contracts__add-floating-button"
				/>
			)}
			{contractDialog}
		</>
	) : null;
});
