import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import '../ProblemHistoryDialog.less';
import {IProblemStory} from '@tehzor/tools/interfaces/problemStories/IProblemStory';
import {IHistoryDifference} from '@tehzor/tools/interfaces/history/IHistoryDifference';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {IEntityState} from '@tehzor/tools/utils/reducersHandlers';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {extractProblemStoriesAsArray} from '@src/store/modules/pages/problem/selectors/stories';
import {
	createAttachmentsDifference,
	createCategoryDifference,
	createCriticalDifference,
	createDateDifference,
	createDescriptionDifference,
	createLocationDifference,
	createMarkerCommentDifference,
	createPrescriptionDifference,
	createProblemTagsDifference,
	createReasonDifference,
	createRespUsersDifference,
	createStatusDifference
} from '@src/utils/createHistoryData';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {extractProblemCommentsStoriesAsArray} from '@src/store/modules/pages/problem/selectors/commentsStories';
import {ICommentStory} from '@tehzor/tools/interfaces/comments/ICommentStory';
import {
	createCommentCreation,
	createCommentDeletion,
	createCommentDifference
} from '@src/utils/createHistoryData/createCommentDifference';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {extractProblemMarkersStoriesAsArray} from '@src/store/modules/pages/problem/selectors/markerStories';
import {IMarkerStory} from '@tehzor/tools/interfaces/markers/IMarkerStory';
import {usePlans} from '@src/core/hooks/queries/plans/hooks';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {IGetProblemStatusesResponse} from '@src/api/backend/problemStatuses';
import {isArrayEqual} from '@src/utils/createHistoryData/isArrayEqual';
import {isAttachmentsEqual} from '@src/utils/createHistoryData/isAttachmentsEqual';
import {useUsers} from '@src/core/hooks/queries/users/hooks';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useCategories} from '@src/core/hooks/queries/categories/hook';
import {useExtractProblemTagsAsArray} from '@src/core/hooks/queries/problemTags/hooks';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useWorkingGroups} from '@src/core/hooks/queries/workingGroups/hooks';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

function compareMarkersStories(
	markersHistories: IMarkerStory[],
	t: TFunction<'translation', undefined>,
	users?: INormalizedData<IBriefUser>
) {
	const result: IHistoryDifference[] = [];
	markersHistories.forEach(story => {
		const isFinished =
			story.current && !!story?.dateEnd && story?.dateEnd < new Date().getTime();
		const latestItem = !isFinished
			? markersHistories.reduce((prev, current) => {
					if (
						current.markerId === story.markerId &&
						current.id !== story.id &&
						current.createdAt &&
						story.createdAt &&
						current.createdAt < story.createdAt &&
						!prev
					) {
						return current;
					}
					return prev;
			  }, undefined)
			: story;

		// запись об изменении комментария к точке ТОЛЬКО в том случае если менялся текст комментария
		if (story.data.description || latestItem?.data.description) {
			result.push({
				data: [
					createMarkerCommentDifference(
						latestItem
							? {
									name: latestItem?.data.name,
									description: latestItem?.data.description
							  }
							: undefined,
						isFinished
							? undefined
							: {
									name: story.data.name,
									description: story.data.description
							  },
						t
					)
				],
				isCreated: !latestItem && !isFinished,
				isDeleted: !story.data.description || isFinished,
				createdAt: story.createdAt,
				createdBy: story.createdBy && users ? users.byId[story.createdBy] : undefined
			});
		}
	});
	return result;
}

function compareCommentsStories(
	commentsHistories: ICommentStory[],
	t: TFunction<'translation', undefined>,
	users?: INormalizedData<IBriefUser>
) {
	const result: IHistoryDifference[] = [];

	commentsHistories.forEach(story => {
		if (result.some(item => item.id === story.data.commentId)) {
			const latestItem = commentsHistories.reduce((prev, current) => {
				if (
					current.data.commentId === story.data.commentId &&
					current.id !== story.id &&
					current.createdAt &&
					story.createdAt &&
					current.createdAt < story.createdAt &&
					(!prev.createdAt || current.createdAt > prev.createdAt)
				) {
					return current;
				}
				return prev;
			});

			// запись об удалении комментария
			if (story.data.deleted) {
				result.push({
					data: [
						createCommentDeletion(
							{
								description: latestItem.data.description,
								attachments: latestItem.data.attachments
							},
							t,
							story.data.official
						)
					],
					id: story.data.commentId,
					createdAt: story.createdAt,
					createdBy: story.createdBy && users ? users.byId[story.createdBy] : undefined,
					isDeleted: true
				});
			} else {
				// запись об изменении комментарий
				result.push({
					data: [
						createCommentDifference(
							{
								description: latestItem.data.description,
								attachments: latestItem.data.attachments
							},
							{
								description: story.data.description,
								attachments: story.data.attachments
							},
							t,
							story.data.official
						)
					],
					id: story.data.commentId,
					createdAt: story.createdAt,
					createdBy: story.createdBy && users ? users.byId[story.createdBy] : undefined
				});
			}
		} else {
			// запись о создании комментария
			result.push({
				data: [
					createCommentCreation(
						{
							description: story.data.description,
							attachments: story.data.attachments
						},
						t,
						story.data.official
					)
				],
				id: story.data.commentId,
				createdAt: story.createdAt,
				createdBy: story.createdBy && users ? users.byId[story.createdBy] : undefined,
				isCreated: true
			});
		}
	});
	return result;
}
function compare(
	next: IProblemStory,
	prev: IProblemStory,
	users: INormalizedData<IBriefUser> | undefined,
	workingGroups: INormalizedData<IWorkingGroup> | undefined,
	categories: IEntityState<ICategory> | undefined,
	plans: IEntityState<IPlan> | undefined,
	problemStatuses: IGetProblemStatusesResponse | undefined,
	problemTags: IProblemTag[] | undefined,
	t: TFunction<'translation', undefined>,
	locale: Locale
): IHistoryDifference {
	const data: IHistoryData[] = [];
	if (next.data.critical !== prev.data.critical) {
		data.push(createCriticalDifference(prev.data.critical, next.data.critical, t));
	}
	if (next.data.description !== prev.data.description) {
		data.push(createDescriptionDifference(prev.data.description, next.data.description, t));
	}
	if (next.data.prescription !== prev.data.prescription) {
		data.push(createPrescriptionDifference(prev.data.prescription, next.data.prescription, t));
	}
	if (next.data.status !== prev.data.status && problemStatuses) {
		data.push(createStatusDifference(prev.data.status, next.data.status, problemStatuses, t));
	}
	if (next.data.categoryId !== prev.data.categoryId && categories) {
		data.push(
			createCategoryDifference(prev.data.categoryId, next.data.categoryId, categories, t)
		);
	}
	if (next.data.plannedFixDate !== prev.data.plannedFixDate) {
		data.push(
			createDateDifference(prev.data.plannedFixDate, next.data.plannedFixDate, t, locale)
		);
	}
	if (next.data.reason !== prev.data.reason) {
		data.push(createReasonDifference(prev.data.reason, next.data.reason, t));
	}
	if (
		plans &&
		(next.data.displayLocation !== prev.data.displayLocation ||
			next.data.floor !== prev.data.floor ||
			next.data.planId !== prev.data.planId)
	) {
		data.push(
			createLocationDifference(
				prev.data.displayLocation,
				prev.data.floor,
				prev.data.planId,
				next.data.displayLocation,
				next.data.floor,
				next.data.planId,
				plans,
				t
			)
		);
	}
	if (
		users &&
		workingGroups &&
		(next.data.performersActiveGroup !== prev.data.performersActiveGroup ||
			next.data.performers?.length !== prev.data.performers?.length ||
			(next.data.performers?.length === prev.data.performers?.length &&
				!isArrayEqual(next.data.performers, prev.data.performers)))
	) {
		data.push(
			createRespUsersDifference(
				prev.data.performersActiveGroup,
				prev.data.performers,
				next.data.performersActiveGroup,
				next.data.performers,
				users,
				workingGroups,
				t('createDifference.createPerformersDifference')
			)
		);
	}
	if (
		users &&
		workingGroups &&
		(next.data.inspectorsActiveGroup !== prev.data.inspectorsActiveGroup ||
			next.data.inspectors?.length !== prev.data.inspectors?.length ||
			(next.data.inspectors?.length === prev.data.inspectors?.length &&
				!isArrayEqual(next.data.inspectors, prev.data.inspectors)))
	) {
		data.push(
			createRespUsersDifference(
				prev.data.inspectorsActiveGroup,
				prev.data.inspectors,
				next.data.inspectorsActiveGroup,
				next.data.inspectors,
				users,
				workingGroups,
				t('createDifference.createInspectorsDifference')
			)
		);
	}
	if (
		next.data.attachments?.length !== prev.data.attachments?.length ||
		(next.data.attachments &&
			prev.data.attachments &&
			next.data.attachments.length === prev.data.attachments.length &&
			!isAttachmentsEqual(next.data.attachments, prev.data.attachments))
	) {
		data.push(createAttachmentsDifference(prev.data.attachments, next.data.attachments, t));
	}

	if (
		next.data.problemTags?.length !== prev.data.problemTags?.length ||
		(next.data.problemTags?.length === prev.data.problemTags?.length &&
			!isArrayEqual(next.data.problemTags, prev.data.problemTags))
	) {
		data.push(
			createProblemTagsDifference(
				prev.data.problemTags,
				next.data.problemTags,
				problemTags,
				t
			)
		);
	}

	return {
		data,
		createdAt: next.createdAt,
		createdBy: next.createdBy && users ? users.byId[next.createdBy] : undefined
	};
}

export function useEnrichedHistories(objectId: string): IHistoryDifference[] {
	const {t} = useTranslation();
	const histories = useAppSelector(extractProblemStoriesAsArray);
	const commentsHistories = useAppSelector(extractProblemCommentsStoriesAsArray);
	const {data: problemTags} = useExtractProblemTagsAsArray();
	const markersHistories = useAppSelector(extractProblemMarkersStoriesAsArray);
	const {data: problemStatuses} = useProblemStatuses();
	const {data: categories} = useCategories();

	const translatedProblemStatuses = useTranslatedDictionary(
		dictionaryKeys.problemStatuses,
		problemStatuses
	);
	const {data: users} = useUsers();
	const {data: workingGroups} = useWorkingGroups();
	const {data: plans} = usePlans(objectId);
	const locale = useDateFnsLocale();

	return useMemo(() => {
		const result: IHistoryDifference[] = [];

		for (let i = 0; i < histories.length - 1; i++) {
			result.push(
				compare(
					histories[i + 1],
					histories[i],
					users,
					workingGroups,
					categories,
					plans,
					translatedProblemStatuses,
					problemTags,
					t,
					locale
				)
			);
		}
		// убираем из истории изменений нарушения пустые записи появившиеся из-за изменений комменатриев
		const filteredProblemStories = result.filter(item => item.data && item.data?.length > 0);

		// добавляем запись о создании сущности
		if (histories[0]) {
			filteredProblemStories.push({
				createdAt: histories[0].createdAt,
				createdBy:
					histories[0].createdBy && users ? users.byId[histories[0].createdBy] : undefined
			});
		}

		const commentsStoriesResult = compareCommentsStories(commentsHistories, t, users);
		const markersStoriesResult = compareMarkersStories(markersHistories, t, users);

		const sum = [...filteredProblemStories, ...commentsStoriesResult, ...markersStoriesResult];
		return sum;
	}, [
		histories,
		commentsHistories,
		t,
		users,
		markersHistories,
		workingGroups,
		categories,
		plans,
		translatedProblemStatuses,
		problemTags,
		locale
	]);
}
