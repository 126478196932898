import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '@src/constants/notifications/channels';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import INotificationChannel from '@tehzor/tools/interfaces/INotificationChannel';
import {
	getLoadedEntitiesSuccess,
	ILoadedEntityState,
	getLoadedEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';

type INotificationsChannelsState = ILoadedEntityState<INotificationChannel>;

const channels = createReducer<INotificationsChannelsState>(getLoadedEntitiesInitialState(), {
	[types.GET_NOTIFICATION_CHANNELS_SUCCESS]: getLoadedEntitiesSuccess<INotificationChannel>(),
	[CLEAR_STORE]: getLoadedEntitiesInitialState
});

export {INotificationsChannelsState};
export default channels;
