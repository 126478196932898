import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type ICopyProblemResponse = IProblem;

/**
 * Копирует нарушение с измененными полями
 *
 * @param objectId id объекта
 * @param problemId id нарушения которое хотим копировать
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 * @param fields данные нарушения
 */
export const makeProblemCopyRequest = async (
	objectId: string,
	problemId: string,
	links: IProblem['links'] | undefined,
	fields: ISavingProblem,
	stage: ObjectStageIds = ObjectStageIds.BUILDING
) => {
	const response = await httpRequests.withToken.post<ICopyProblemResponse>(`/problems/copy-problem/${problemId}`, {
		objectId,
		links,
		stage,
		...fields
	});

	return response.data;
};
