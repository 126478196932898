import { useCallback, useState } from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableLegal} from '@src/components/EditableLegal/hooks/useEditableLegal';
import './AddingLegalDialog.less';
import {useAddLegal} from '@src/core/hooks/mutations/legals/useAddLegal';
import {useEditLegal} from '@src/core/hooks/mutations/legals/useEditLegal';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

interface IAddingLegalDialogProps {
	legal?: ILegalEntity;
	onSuccess?: () => void | Promise<void>;
	isOpen: boolean;
	close(): void;
}

export const AddingLegalDialog = ({legal, isOpen, close, onSuccess}: IAddingLegalDialogProps) => {
	const [saving, setSaving] = useState(false);
	const {legalFields, getSavingData, isBlocking, reset} = useEditableLegal(legal);
	const addLegal = useAddLegal();
	const editLegal = useEditLegal();

	const handleSave = useCallback(() => {
		const savingData = getSavingData();
		if (!savingData) {
			return;
		}
		setSaving(true);

		try {
			if (!legal?.id) {
				addLegal({fields: savingData});
			} else {
				editLegal({fields: savingData, legalId: String(legal.id)});
			}

			if (onSuccess) {
				void onSuccess();
			}
		} finally {
			setSaving(false);
			reset();
			close();
		}
	}, [close, getSavingData, reset, onSuccess, legal?.id, addLegal, editLegal]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (close && (!isBlocking || (await getClosingConfirmation()))) {
			close();
		}
	}, [getClosingConfirmation, close, isBlocking]);

	const dialogTitle = legal?.name ? 'Редактировать юрлицо' : 'Добавить юрлицо';

	return (
		<Dialog
			className={{
				root: 'adding-legal',
				content: 'adding-legal__container'
			}}
			isOpen={isOpen}
			title={dialogTitle}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{legalFields}
			{closingDialog}
		</Dialog>
	);
};
