import {IGetMarkersResponse} from '@src/api/backend/markers';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IMarker} from '@tehzor/tools/interfaces/markers/IMarker';

export const extractMarkersData = (
	data: IGetMarkersResponse,
	entityId: string
): INormalizedData<IMarker> => data[entityId] || {byId: {}, allIds: []};

export const extractMarkersAsArray = (
	data: IGetMarkersResponse,
	entityIds: string[]
): IMarker[] => {
	const result: IMarker[] = [];
	entityIds.forEach(entityId => {
		const markersData = extractMarkersData(data, entityId);
		markersData.allIds.forEach((id: string) => {
			const marker = markersData.byId[id];
			result.push(marker);
		});
	});
	return result;
};
