import {ISpaceType, SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';

interface ISpaceData {
	id: string;
	name: string;
	type: SpaceTypeId;
	objectId: string;
}
export const filterEmptySpaceTypes = (spaces: ISpaceData[], spaceTypes: ISpaceType[] | undefined) =>
	spaceTypes?.filter(type => spaces.some(space => space.type === type.id));

export const mapSpaceToTreeData = (data: Array<ISpaceData | ISpaceType>) => {
	const result = data.map(el => {
		if ('type' in el) {
			return {
				id: el.id,
				content: el.name,
				parentId: el?.type
			};
		}
		return {
			id: el.id,
			content: el.name,
			onlySemiCheckable: true,
			nonCheckable: true
		};
	});
	return result as ITreeDataItem[];
};

export const makeTreeDataSpace = (spaces: ISpaceData[], spaceTypes?: ISpaceType[]) => {
	const filteredSets = filterEmptySpaceTypes(spaces, spaceTypes);
	const arr = [] as Array<ISpaceData | ISpaceType>;
	const dataForTree = filteredSets ? arr.concat(spaces, filteredSets) : spaces;

	return mapSpaceToTreeData(dataForTree);
};
