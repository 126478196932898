import {IconButton} from '@tehzor/ui-components';
import {memo} from 'react';

interface ISelectionClearingProps {
	onSelectedRowsChange: () => void;
}

const closeIcon = <i className="tz-close-24" />;

export const SelectionClearing = memo(({onSelectedRowsChange}: ISelectionClearingProps) => (
	<IconButton onClick={onSelectedRowsChange}>{closeIcon}</IconButton>
));
