import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IDocumentsFiltersState, documentsActions} from '@src/store/modules/settings/pages/documents/reducers';
import {extractDocumentsPageSettings} from '@src/store/modules/settings/pages/documents/selectors';
import { useCallback } from 'react';
import {Outlet} from 'react-router';

export const DocumentsFiltersRoutingPage = () => {
	const {filters} = useAppSelector(extractDocumentsPageSettings);
	const dispatch = useAppDispatch();
	const {changeFilters} = documentsActions;

	const applyFilters = useCallback((value: IDocumentsFiltersState) => {
		dispatch(changeFilters(value));
	}, [dispatch]);

	return (
		<MobileEntitiesFilters
			filters={filters}
			onApply={applyFilters}
			fullClear
		>
			<Outlet/>
		</MobileEntitiesFilters>
	)
}