import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

/**
 * Возвращает количества нарушений по статусам для текущей стадии, если стадия не указана, то по всем стадиям
 *
 * @param problems данные по нарушениям из структуры
 * @param stage текущая стадия
 */
export const getProblemsDataByStage = (
	problems: IEntityProblemsData['problems'],
	stage?: ObjectStageIds
): Record<string, {count: number; critical: boolean}> | undefined => {
	if (stage) {
		return problems[stage];
	}

	return Object.values(problems).reduce<Record<string, {count: number; critical: boolean}>>(
		(prev, item) => {
			for (const status in item) {
				if (item.hasOwnProperty(status)) {
					if (prev[status]) {
						prev[status].count += item[status].count;
						prev[status].critical = prev[status].critical || item[status].critical;
					} else {
						prev = {
							...prev,
							[status]: {count: item[status].count, critical: item[status].critical}
						};
					}
				}
			}
			return prev;
		},
		{}
	);
};
