/* eslint-disable @typescript-eslint/ban-ts-comment */
import {AnyAction, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import {IState, rootReducer} from './modules';
// @ts-ignore
import backendMiddleware from '@src/middlewares/backendMiddleware';
import apiMiddleware from './middlewares/api';
import offlineModeMiddleware from './middlewares/offlineMode';
import thunk from 'redux-thunk';
import {formPersistConfig} from './persistentStorage/formPersistConfig';

const middleware = [thunk, apiMiddleware(), backendMiddleware(), offlineModeMiddleware()];

if (process.env.NODE_ENV === 'development') {
	// eslint-disable-next-line max-len
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-var-requires,global-require,import/no-extraneous-dependencies
	const {createLogger} = require('redux-logger');
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	middleware.push(createLogger({collapsed: true}));
}

const persistedReducer = persistReducer(
	formPersistConfig<IState>('root', {whitelist: []}),
	rootReducer
);

export const store = configureStore<IState, AnyAction, typeof middleware>({
	reducer: persistedReducer,
	middleware,
	devTools: process.env.NODE_ENV === 'development',
	preloadedState: {}
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type AppStore = typeof store;
