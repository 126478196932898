import './UploadingLoader.less';

export const UploadingLoader = () => (
	<svg
		className="uploading-loader"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#A8BDDC"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			fill="#A8BDDC"
			d="M5 2C4.44772 2 4 2.44772 4 3C4 3.55228 4.44772 4 5 4H19C19.5523 4 20 3.55228 20 3C20 2.44772 19.5523 2 19 2H5Z"
		/>
		<path
			className="uploading-loader__arrow"
			fillRule="evenodd"
			clipRule="evenodd"
			fill="#A8BDDC"
			d="M12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L5.79289 12.7929C5.40237 13.1834 5.40237 13.8166 5.79289 14.2071C6.18342 14.5976 6.81658 14.5976 7.20711 14.2071L11 10.4142V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V10.4142L16.7929 14.2071C17.1834 14.5976 17.8166 14.5976 18.2071 14.2071C18.5976 13.8166 18.5976 13.1834 18.2071 12.7929L12.7071 7.29289Z"
		/>
	</svg>
);
