import {Responsibles} from '@src/components/editableFields/Responsibles';
import {
	IEditableWorkAcceptanceAction,
	IEditableWorkAcceptanceState
} from '@src/core/hooks/states/useEditableWorkAcceptanceState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {isEqual} from 'lodash';
import {Dispatch, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface IEditableAcceptorsProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;

	editingState: IEditableWorkAcceptanceState;
	editingDispatch: Dispatch<IEditableWorkAcceptanceAction>;

	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	exception?: boolean;
}

export const EditableAcceptors = ({
	objectId,
	stage,
	scope,
	editingState,
	editingDispatch,
	required,
	disabled,
	hasError,
	exception
}: IEditableAcceptorsProps) => {
	const {t} = useTranslation();

	const {
		categoryId,
		structureIds,
		spaceIds,
		acceptors,
		acceptorsActiveGroup,
		type,
		frontType,
		initialRuleParams
	} = editingState;

	const ruleParams: IRuleParams = useMemo(
		() => ({categoryId: categoryId || undefined, structureIds, spaceIds}),
		[categoryId, structureIds, spaceIds]
	);

	const handleChange = useCallback(
		(users: string[], group?: string) => {
			editingDispatch({type: 'update', field: 'acceptors', value: users});
			if (required) {
				editingDispatch({type: 'update-error', field: 'acceptors'});
			}

			editingDispatch({type: 'update', field: 'acceptorsActiveGroup', value: group});
			if (required) {
				editingDispatch({type: 'update-error', field: 'acceptorsActiveGroup'});
			}
		},
		[required]
	);

	const handleUpdateException = useCallback(
		(value: boolean) => {
			if (exception) {
				return;
			}

			editingDispatch({type: 'update-exception', field: 'acceptors', value});
		},
		[exception]
	);

	const checkDoSuggestions = useCallback(() => {
		if (isEqual(initialRuleParams, ruleParams)) {
			return false;
		}
		editingDispatch({type: 'update', field: 'initialRuleParams', value: undefined});
		return true;
	}, [initialRuleParams, ruleParams]);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			scope={scope}
			workAcceptanceType={type}
			workAcceptanceFrontType={frontType}
			workingGroupType={WorkingGroupTypeId.ACCEPTORS}
			respUsers={acceptors}
			activeGroup={acceptorsActiveGroup}
			ruleParams={ruleParams}
			label={t('editableWorkAcceptance.acceptors.label')}
			errorLabel={t('editableWorkAcceptance.acceptors.errorLabel')}
			dialogTitle={t('components.delegationDialog.acceptors.title')}
			required={required}
			disabled={disabled}
			hasError={hasError}
			onResponsiblesChange={handleChange}
			onUpdateException={handleUpdateException}
			checkDoSuggestions={checkDoSuggestions}
		/>
	);
};
