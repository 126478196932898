import {useMemo} from 'react';
import {IEntity} from '../EntitiesMobilePhotoViewer';

export const useCachedIndexes = (entities: IEntity[]) =>
	useMemo(() => {
		const byId: { [key: string]: {from: number, to: number} } = {};
		let index = 0;
		for (const entity of entities) {
			byId[entity.id] = {from: index, to: index + entity.files.length - 1};
			index += entity.files.length;
		}
		return byId;
	}, [entities]);