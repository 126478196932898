import ISavingComment from '@src/interfaces/ISavingComment';
import {wsConnector} from '../wsConnector';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

export type IAddCommentResponse = IComment;

/**
 * Добавляет комментарий
 *
 * @param objectId id объекта
 * @param commentTypeId тип комментария
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const makeCommentAddRequest = (
	objectId: string,
	commentTypeId: ICommentTypeId,
	links: IComment['links'] | undefined,
	fields: ISavingComment
) =>
	wsConnector.sendAuthorizedRequest<IAddCommentResponse>('addComment', {
		objectId,
		commentTypeId,
		links,
		...fields
	});
