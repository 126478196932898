import {queryClient} from '@src/api/QueryClient';
import {legalsQueryKeys} from '@src/api/cache/legals/keys';
import {makeLegalEntityDeleteRequest} from '@src/api/backend/legal-entity/delete';
import {addErrorToast} from '@src/utils/toasts';

export const useDeleteLegal = () => async (legalId: string) => {
	try {
		await makeLegalEntityDeleteRequest(legalId);
		void queryClient.invalidateQueries({queryKey: legalsQueryKeys.list()});
	} catch (e) {
		addErrorToast('Юрлицо', 'не удалось удалить сущность');
	}
};
