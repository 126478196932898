import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {IProfileWithSubscription} from '@tehzor/tools/interfaces/profile/IProfile';

export type IEditableProfileState = IEditableEntityState<IProfileWithSubscription>;

export type IEditableProfileAction = IEditableEntityAction<IEditableProfileState, IProfileWithSubscription>;

const makeEmptyState = (): IEditableProfileState => ({
	email: undefined,
	firstName: undefined,
	lastName: undefined,
	middleName: undefined,
	position: undefined,
	password: undefined,
	subscribed: undefined,
	errors: {
		email: false,
		firstName: false,
		lastName: false,
		middleName: false,
		position: false,
		password: false,
		subscribed: false
	}
});

export const init = (user: IProfileWithSubscription): IEditableProfileState => {
	const empty = makeEmptyState();
	return user
		? {
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
				middleName: user.middleName,
				position: user.position,
				password: user.password,
				subscribed: user.subscribed,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (state: IEditableProfileState, original?: IProfileWithSubscription) =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'email'),
		isPropEdited.bind(null, 'firstName'),
		isPropEdited.bind(null, 'lastName'),
		isPropEdited.bind(null, 'middleName'),
		isPropEdited.bind(null, 'position'),
		isPropEdited.bind(null, 'password'),
		isPropEdited.bind(null, 'subscribed')
	);

const validateEmail = (value: string | undefined) => {
	if (!value) return false;

	const regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

	return regex.test(value);
};

const validatePassword = (value: string | undefined) => {
	if (!value) return false;

	return value?.length > 3;
};

export const errorsFns = {
	email: (state: IEditableProfileState) => !state.email || !validateEmail(state.email),
	firstName: (state: IEditableProfileState) => !state.firstName,
	lastName: (state: IEditableProfileState) => !state.lastName,
	middleName: (state: IEditableProfileState) => !state.middleName,
	position: (state: IEditableProfileState) => !state.position,
	password: (state: IEditableProfileState) => !state.password || !validatePassword(state.password),
	subscribed: (state: IEditableProfileState) => !state.subscribed
};

export const convertToSave = (
	state: IEditableProfileState,
	original?: IProfileWithSubscription,
	onlyEdited?: boolean
) => {
	if (!onlyEdited) {
		return {
			email: state.email,
			firstName: state.firstName,
			lastName: state.lastName,
			middleName: state.middleName,
			position: state.position,
			password: state.password,
			subcribed: state.subscribed
		};
	}

	const user: IProfileWithSubscription = {};

	if (isPropEdited('email', state, original)) {
		user.email = state.email;
	}
	if (isPropEdited('firstName', state, original)) {
		user.firstName = state.firstName;
	}
	if (isPropEdited('lastName', state, original)) {
		user.lastName = state.lastName;
	}
	if (isPropEdited('middleName', state, original)) {
		user.middleName = state.middleName;
	}
	if (isPropEdited('position', state, original)) {
		user.position = state.position;
	}
	if (isPropEdited('password', state, original)) {
		if (state.password !== '') {
			user.password = state.password;
		}
	}
	if (isPropEdited('subscribed', state, original)) {
		user.subscribed = state.subscribed;
	}
	return user;
};
