import {useDocumentsPermissions} from '@src/core/hooks/permissions/useDocumentsPermissions';
import {useDocumentsListFilteredArray} from '@src/core/hooks/queries/documents/hooks';
import {InlineButton, Plate} from '@tehzor/ui-components';
import { memo } from 'react';
import {useDocumentsDialog} from '../DocumentsDialog';
import './EntityDocs.less';
import {DOCUMENTS_COUNT, EntityDocsItems} from './components/EntityDocsItems';
import {useTranslation} from 'react-i18next';

interface IDesktopEntityDocsProps {
	id: string;
}

export const DesktopEntityDocs = memo((props: IDesktopEntityDocsProps) => {
	const {id} = props;

	const {t} = useTranslation();

	const {canView} = useDocumentsPermissions();
	const {data: documents} = useDocumentsListFilteredArray(id);
	const totalCount = documents?.length;
	const {documentsDialog, openDocumentsDialog} = useDocumentsDialog(documents);

	if (!totalCount || !canView || !documents) {
		return null;
	}

	const showButton = totalCount > DOCUMENTS_COUNT;

	return (
		<Plate
			className={{root: 'entity-docs-wrapper'}}
			withoutPadding
		>
			<div className="entity-docs__header">{t('entityDocs.header')}</div>

			<div className="entity-docs__content">
				<EntityDocsItems documents={documents} />

				{showButton && (
					<div className="entity-docs__more">
						<InlineButton
							type="accent"
							label={`${t('entityDocs.showAll')} (${totalCount})`}
							onClick={openDocumentsDialog}
						/>
					</div>
				)}

				{documentsDialog}
			</div>
		</Plate>
	);
});
