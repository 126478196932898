import {Column} from 'react-table';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemFixCell} from '@src/components/tableCells/ProblemFixCell';
import InspectionInfoDesktopCell from '@src/components/tableCells/InspectionInfoDesktopCell/InspectionInfoDesktopCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {extractCheckProblemCommentsByProblem} from '@src/store/modules/entities/check/selectors';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell';
import {useTranslation} from 'react-i18next';
import {InspectionFixCell} from '@src/components/tableCells/InspectionFixCell';
import {ProblemStatusCell} from '@src/components/tableCells/ProblemStatusCell';

interface IUseCheckColumnsProps {
	isDesktop: boolean;
}

export const useCheckColumns = ({
	isDesktop
}: IUseCheckColumnsProps): Array<Column<ICheckEntity>> => {
	const {t} = useTranslation();

	if (!isDesktop) {
		return [
			{
				id: 'info',
				Cell: CellWrap({
					problem: ProblemInfoCellWrap(
						ProblemInfoMobileCell,
						extractCheckProblemCommentsByProblem
					),
					inspection: InspectionInfoMobileCell
				})
			}
		];
	}

	return [
		{
			id: 'number',
			Header: HeaderCellWithRowSelectWrap(`№ ${t('checksPage.table.columns.number.header')}`),
			Cell: CellWrap({
				problem: ProblemInfoCellWrap(
					ProblemInfoDesktopCell,
					extractCheckProblemCommentsByProblem
				),
				inspection: InspectionInfoDesktopCell
			}),
			width: 200,
			minWidth: 200
		},
		{
			id: 'status',
			Header: t('checksPage.table.columns.status.header'),
			Cell: CellWrap({
				problem: ProblemStatusCell
			}),
			width: 80,
			minWidth: 80
		},
		{
			id: 'category',
			Header: t('checksPage.table.columns.category.header'),
			Cell: CellWrap({
				problem: CategoryCell,
				inspection: CategoryCell
			}),
			width: 120,
			minWidth: 120
		},
		{
			id: 'fix',
			Header: t('checksPage.table.columns.fix.header'),
			Cell: CellWrap({problem: ProblemFixCell, inspection: InspectionFixCell}),
			width: 150,
			minWidth: 150
		}
	];
};
