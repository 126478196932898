import IUser from '@tehzor/tools/interfaces/IUser';
import {IFullSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/IFullSpaceStatusesSet';
import {IPreparedSpaceStatusesSet} from '../interfaces/IPreparedSpaceStatusesSet';
import {getPermissions} from './getPermissions';

export const convertSpaceStatusesSets = (
	spaceStatusesSets: IFullSpaceStatusesSet[],
	user?: IUser
): IPreparedSpaceStatusesSet[] => spaceStatusesSets.map(set => ({
	...set,
	...getPermissions(set, user)
}));