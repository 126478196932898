import {useEffect, useState} from 'react';
import {LinkButton} from '@tehzor/ui-components';
import './EditableTags.less';
import {Table} from '@src/pages/manage/ProblemTagsSetEditingPage/components/table/Table.desktop';
import {IExtendedSavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IExtendedSavingProblemTag';
import {generateId} from '@src/components/EditableCheckList/components';
import {useExtractProblemTagsForSetAsArray} from '@src/core/hooks/queries/problemTags/hooks';

interface IEditableProblemTagsProps {
	problemTagsSetId?: string;
	forbiddenAdding?: boolean;
}

export const EditableTags = ({problemTagsSetId, forbiddenAdding}: IEditableProblemTagsProps) => {
	const {data: tags} = useExtractProblemTagsForSetAsArray(problemTagsSetId);

	const [array, setArray] = useState<IExtendedSavingProblemTag[]>(tags ?? []);
	useEffect(() => {
		if (tags) {
			setArray(tags);
		}
	}, [tags]);

	const addNewTag = () => {
		if (problemTagsSetId)
			setArray(
				array.concat({
					id: generateId(),
					name: undefined,
					color: undefined,
					isDraft: true,
					problemTagsSetId
				})
			);
	};

	return (
		<div className="editable-tags">
			<div className="editable-tags__header">
				<h3>Метки нарушений</h3>
			</div>
			<div className="editable-tags__body">
				<Table
					tags={array}
					setArray={setArray}
					problemTagsSetId={problemTagsSetId}
					disabledActions={forbiddenAdding}
				/>
			</div>
			<div className="editable-tags__actions">
				<LinkButton
					label="Добавить метку"
					disabled={forbiddenAdding}
					onClick={addNewTag}
					leftIcon={<i className="tz-plus-20" />}
				/>
			</div>
		</div>
	);
};
