import { useMemo } from 'react';
import SelectionActions from '../selection/SelectionActions';
import ActionsMobile from './Actions.mobile';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {useWarrantyClaimEntities} from '@src/core/hooks/queries/warrantyClaim/problems/hooks';

interface IMobileRightButtonsProps {
	objectId: string;
	warrantyClaimId: string;
	warrantyClaim?: ILinkedWarrantyClaim;
	selectedRows?: string[];
}

const MobileRightButtons = ({
	objectId,
	warrantyClaimId,
	warrantyClaim,
	selectedRows
}: IMobileRightButtonsProps) => {
	const {data: entities} = useWarrantyClaimEntities(objectId, warrantyClaimId);

	const selectedEntities = useMemo(
		() => entities?.filter(item => selectedRows?.includes(item.id)) || [],
		[selectedRows, entities]
	);

	if (!warrantyClaim) {
		return null;
	}
	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				warrantyClaimId={warrantyClaimId}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			warrantyClaim={warrantyClaim}
		/>
	);
};

export default MobileRightButtons;
