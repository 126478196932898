import ICanvasData from '@tehzor/tools/interfaces/ICanvasData';
import { memo, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import {useCanvasDrawing} from './useCanvasDrawing';

interface ICanvasProps {
	imageUrl: string;
	style?: React.CSSProperties;
	brushRadius: number;
	brushColor?: string;
	canvasWidth: number;
	canvasHeight: number;
	onChange?: (data: ICanvasRefObject | undefined) => void;
	savedDrawData?: ICanvasData;
}

export interface ICanvasRefObject {
	canvas: HTMLCanvasElement | null;
	clear: () => void;
	drawed: ICanvasData;
	getBlobUrl: (canvas: HTMLCanvasElement) => Promise<{editedBlob: Blob, originalBlob: Blob} | undefined>;
}

export const Canvas = memo((props: ICanvasProps) => {
	const {
		style,
		imageUrl,
		brushRadius,
		brushColor = 'red',
		canvasWidth,
		canvasHeight,
		onChange,
		savedDrawData = {} as ICanvasData
	} = props;
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(canvasRef.current);
	const {drawLines} = useCanvasDrawing(
		canvas,
		canvasWidth,
		canvasHeight,
		brushColor,
		brushRadius,
		imageUrl,
		savedDrawData,
		onChange
	);
	useEffect(() => {
		setCanvas(canvasRef.current);
	}, []);
	useEffect(() => {
		drawLines(canvas);
	}, [brushColor, drawLines, canvas]);

	return (
		<div
			style={{
				display: 'block',
				touchAction: 'none',
				width: canvasWidth,
				height: canvasHeight,
				background: `url(${imageUrl}) no-repeat`,
				backgroundSize: 'contain',
				backgroundPosition: 'center',
				...style
			}}
		>
			<canvas ref={canvasRef}/>
		</div>
	);
});
