import {useMemo} from 'react';
import {IPaginate, IPaginateSettings} from '@/shared/types/IPaginate';
import {handlePagination} from '@/shared/utils/handlePaginate';

export const usePaginate = <T>(settings: IPaginateSettings, arr?: T[]): IPaginate<T> =>
	useMemo(() => {
		const paginatedArr =
			arr?.filter((item, i) => handlePagination(i, settings.offset, settings.pageSize)) || [];

		const pagesCount = Math.ceil(paginatedArr.length / settings.pageSize);
		const currentPage = Math.floor(settings.offset / settings.pageSize);
		const total = paginatedArr.length;

		return {
			data: paginatedArr,
			pagesCount,
			currentPage,
			total
		};
	}, [arr, settings]);
