import {makeCheckListDeleteRequest} from '@src/api/backend/checkList';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import IError from '@tehzor/tools/interfaces/IError';

const mutationFn = (id: string) => makeCheckListDeleteRequest(id);

const onSuccess = () => {
	addSuccessToast('Удалено', 'Чек-лист успешно удален');
	void queryClient.invalidateQueries({queryKey: checkListsQueryKeys.list()});
};

const onError = () => addErrorToast('Ошибка', 'при удалении чек-листа');

export const useDeleteCheckList = () =>
	useMutation<ICheckList, IError, string>({
		mutationFn,
		mutationKey: checkListsQueryKeys.delete(),
		onSuccess,
		onError
	});
