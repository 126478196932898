import {useCallback, useState} from 'react';
import {AuthForm, ErrorBoundary, Logo} from '@tehzor/ui-components';
import {login} from '@src/store/modules/auth/actions/login';
import './LoginPage.less';
import {CSSTransition} from 'react-transition-group';
import useMount from 'react-use/lib/useMount';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useLocation} from 'react-use';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {Location} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {AppLanguageSelect} from '@src/components/AppLanguageSelect';

const errorMap = {
	InvalidCredentials: 'Неверный логин или пароль',
	NoActiveConnection: 'Отсутствует соединение с сервером',
	TimeoutError: 'Превышено ожидание ответа от сервера',
	unknown: 'Ошибка при входе'
};

/**
 * Компонент формы ввода логина и пароля
 */
export const LoginPage = () => {
	const {t, i18n} = useTranslation();
	const location = useLocation();
	const {pushPath} = useChangePath();
	const locationState = location.state as {usr: {redirect: string} | null};
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const dispatch = useAppDispatch();

	const [error, setError] = useState<string>();

	useMount(() => {
		if (isAuthenticated) {
			pushPath('/');
		}
	});

	const authenticateUser = useCallback(
		async (email: string, password: string) => {
			try {
				const result = await dispatch(login(email, password));

				if (
					typeof result === 'object' &&
					result !== null &&
					!Array.isArray(result) &&
					result.hasOwnProperty('error')
				) {
					const error = (result as unknown as {error: string}).error;
					const isRegisteredError = !!errorMap[error];
					setError(
						isRegisteredError
							? t(`loginPage.errors.${error}`)
							: t('loginPage.errors.unknown')
					);
				}

				let redirect = '/';
				if (location.state && locationState.usr?.redirect) {
					const stateRedirect = locationState.usr.redirect;
					delete (locationState as Partial<Location>).key;
					if (stateRedirect !== '/login' && stateRedirect !== '/logout') {
						redirect = stateRedirect;
					}
				}
				pushPath(redirect);
			} catch (err) {
				const isRegisteredError = !!errorMap[err.error];
				setError(
					isRegisteredError
						? t(`loginPage.errors.${err.error}`)
						: t('loginPage.errors.unknown')
				);
			}
		},
		[location]
	);

	return (
		<CSSTransition
			classNames="login-page"
			in
			appear
			timeout={300}
		>
			<div className="login-page">
				<Logo
					className="login-page__logo"
					language={i18n.language}
				/>
				<ErrorBoundary
					label="LoginPage"
					className="login-page error"
				>
					<div className="login-page__container">
						<AuthForm
							className="login-page__container-auth-form"
							lngSelect={<AppLanguageSelect />}
							error={error}
							title={t('loginPage.title')}
							emailLabel={t('loginPage.email')}
							passwordLabel={t('loginPage.password')}
							recoveryLabel={t('loginPage.recovery')}
							rememberLabel={t('loginPage.remember')}
							submitLabel={t('loginPage.submit')}
							registerLabel={t('loginPage.register')}
							orText={t('loginPage.or')}
							onLogin={authenticateUser}
						/>
						<div className="login-page__container-background">
							<div className="login-page__container-background__left" />
							<div className="login-page__container-background__right" />
						</div>
					</div>
				</ErrorBoundary>
			</div>
		</CSSTransition>
	);
};
