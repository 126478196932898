import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetCategoriesResponse} from '@src/api/backend/categories';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {
	extractAllCategories,
	extractAllCategoriesAsArray,
	extractCategoriesAsArray,
	extractCategoriesAsArrayBySetId,
	extractCategoriesAsMap,
	extractCategoriesBySetId,
	extractCategoryById
} from './selectors';
import {useCallback} from 'react';

export const useCategories = <T = IGetCategoriesResponse>(
	select?: (data: IGetCategoriesResponse) => T
) =>
	useQuery<IGetCategoriesResponse, IError, T>({
		queryKey: [...categoriesQueryKeys.list()],
		meta: {
			error: 'при загрузке видов работ'
		},
		select
	});

export const useExtractCategoriesAsMap = () => useCategories(extractCategoriesAsMap);

export const useExtractCategoriesAsArray = () => useCategories(extractCategoriesAsArray);

export const useExtractAllCategories = () => useCategories(extractAllCategories);

export const useExtractAllCategoriesAsArray = () => useCategories(extractAllCategoriesAsArray);

export const useExtractCategoriesBySetId = (categoriesSetId: string) => {
	const selector = useCallback(
		(data: IGetCategoriesResponse) => extractCategoriesBySetId(data, categoriesSetId),
		[categoriesSetId]
	);
	return useCategories(selector);
};

export const useExtractCategoriesAsArrayBySetId = (categoriesSetId?: string) => {
	const selector = useCallback(
		(data: IGetCategoriesResponse) => extractCategoriesAsArrayBySetId(data, categoriesSetId),
		[categoriesSetId]
	);
	return useCategories(selector);
};

export const useExtractCategoryById = (categoryId?: string) => {
	const selector = useCallback(
		(data: IGetCategoriesResponse) => extractCategoryById(data, categoryId),
		[categoryId]
	);
	return useCategories(selector);
};
