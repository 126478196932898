import { Dispatch } from 'react';
import './EditableCheckAttachments.less';
import Attachments from '@src/components/editableFields/Attachments';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {IEditableCheckAction, IEditableCheckState} from '@src/core/hooks/states/useEditableCheckState';

interface IEditableCheckAttachmentsProps {
	editingState: IEditableCheckState;
	editingDispatch: Dispatch<IEditableCheckAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableCheckAttachments = ({
	editingState,
	editingDispatch,
	uploadingFilesState,
	uploadingFilesDispatch,
	saving
}: IEditableCheckAttachmentsProps) =>
	 (
		<div className="editable-check-attachments">
			<div>
				<Attachments
					className={{
						attachBtn: 'editable-check-attachments__attach-btn'
					}}
					attachments={editingState.attachments}
					uploadingFiles={uploadingFilesState.value}
					editingDispatch={editingDispatch}
					uploadingFilesDispatch={uploadingFilesDispatch}
					disabled={saving}
					canDraw={false}
					hasError={editingState.errors.attachments && uploadingFilesState.error}
					showAttachBtn
				/>
			</div>
		</div>
	);
