import {memo, useCallback, useDeferredValue, useMemo} from 'react';
import {
	CheckListsSpace,
	ProblemsSpace,
	SpacesBoard,
	WorkAcceptancesSpace
} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {formCheckListsLink, formSpaceLink} from '@tehzor/tools/utils/links';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeSchemaView} from '@src/store/modules/settings/pages/spaces/actions';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {convertProblemsData} from '../../utils/convertProblems';
import {convertCheckListsData} from '../../utils/convertCheckListsData.';
import {filterCheckLists, filterSpace} from '../../utils/filterSpaces';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useSpacesAsArrayWithFilteredByObject} from '@src/core/hooks/queries/spaces/hooks';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {useCheckLists} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractCheckItemsGroupedByLists} from '@src/core/hooks/queries/checkItems/hooks';
import {useCheckRecordsStatuses} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {convertWorkAcceptancesData} from '../../utils/convertWorkAcceptances';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';

const getSpaceComponent = (schemaView: SpacesSchemaVariants) => {
	switch (schemaView) {
		case SpacesSchemaVariants.CHECK_LISTS:
			return CheckListsSpace;
		case SpacesSchemaVariants.WORK_ACCEPTANCES:
			return WorkAcceptancesSpace;
		case SpacesSchemaVariants.PROBLEMS:
		default:
			return ProblemsSpace;
	}
};

interface ISingleSchemaProps {
	objectId: string;
	hideTitle?: boolean;
	isTitleClickable?: boolean;
}

export const SingleSchema = memo(({objectId, hideTitle, isTitleClickable}: ISingleSchemaProps) => {
	const {t} = useTranslation();

	const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
	const {pushPath} = useChangePath();
	const pageSetting = useAppSelector(s => extractSpacesPageSettings(s, pageObjectId));
	const pageSettings = useDeferredValue(pageSetting);
	const {data: object} = useObject(objectId);
	const {data: problemStatuses} = useProblemStatuses();
	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatuses();
	const {data: checkListsData} = useCheckLists();
	const {data: checkRecordStatuses} = useCheckRecordsStatuses();
	const translatedCheckRecordStatuses = useTranslatedDictionary(
		'checkRecordStatus',
		checkRecordStatuses
	);
	const {data: checkItemsByLists} = useExtractCheckItemsGroupedByLists();
	const sharedSettings = useAppSelector(s => s.settings.pages.spaces.shared);
	const dispatch = useAppDispatch();
	const {data: spaces} = useSpacesAsArrayWithFilteredByObject(objectId, pageSetting.type);
	const goToObject = useCallback(() => {
		if (pageSettings.schemaView === SpacesSchemaVariants.PROBLEMS) {
			dispatch(changeSchemaView(objectId, SpacesSchemaVariants.PROBLEMS));
		} else if (pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS) {
			dispatch(changeSchemaView(objectId, SpacesSchemaVariants.CHECK_LISTS));
		}
		pushPath(`/objects/${objectId}/spaces`);
	}, [objectId, pageSettings, dispatch]);

	const goToSpace = useCallback(
		(space: IConvertedSpace) => {
			if (pageSettings.schemaView === SpacesSchemaVariants.PROBLEMS) {
				pushPath(formSpaceLink(space.objectId, space.id));
			} else if (pageSettings.schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES) {
				pushPath(formSpaceLink(space.objectId, space.id, 'work-acceptances'));
			} else if (pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS) {
				const checkListsLink = formCheckListsLink({
					objectId,
					spaceId: space.id,
					stage: pageSettings.stage ?? 'all'
				});
				if (checkListsLink) {
					pushPath(checkListsLink);
				}
			}
		},
		[objectId, pageSettings]
	);

	const filteredSpaces = useMemo(
		() => spaces?.filter((space: IEnrichedSpace) => space.type === pageSettings.type),
		[pageSettings.type, spaces]
	);

	const convertedSpaces: IConvertedSpace[] | undefined = useMemo(
		() =>
			filteredSpaces?.map(space => {
				const convertedSpace: IConvertedSpace = {
					...space,
					problems: undefined,
					checkLists: undefined,
					workAcceptances: undefined,
					sortBase: space.name
				};

				convertedSpace.problems = convertProblemsData(
					space.problems,
					problemStatuses,
					pageSettings.stage
				);

				convertedSpace.workAcceptances = convertWorkAcceptancesData(
					space.workAcceptances,
					workAcceptanceStatuses
				);

				const filteredCheckLists = checkListsData
					? filterCheckLists(
							checkListsData,
							object?.companyId || '',
							space.objectId,
							space.type,
							pageSettings.stage,
							pageSettings.filters.checkListIds,
							space.typeDecoration
					  )
					: [];
				convertedSpace.checkLists = convertCheckListsData(
					filteredCheckLists,
					checkItemsByLists,
					translatedCheckRecordStatuses,
					pageSettings.filters.checkListCategory,
					space.checkLists
				);

				return convertedSpace;
			}),

		[
			checkItemsByLists,
			checkListsData,
			translatedCheckRecordStatuses,
			workAcceptanceStatuses,
			filteredSpaces,
			object?.companyId,
			pageSettings.filters.checkListIds,
			pageSettings.filters.checkListCategory,
			pageSettings.stage,
			problemStatuses
		]
	);

	const filteredConvertedSpaces: IConvertedSpace[] | undefined = useMemo(
		() =>
			convertedSpaces?.map(space => {
				const filtered = filterSpace(
					space,
					space.indicators?.map(indicator => indicator.id) || [],
					space.status?.id,
					space.problems?.map(el => el.key) || [],
					pageSettings.filters,
					checkListsData,
					space.workAcceptances?.map(el => el.key) || []
				);
				space.filtered = filtered;
				return space;
			}),
		[convertedSpaces, pageSettings.filters, checkListsData]
	);

	if (!filteredConvertedSpaces || !filteredConvertedSpaces.length) {
		return null;
	}

	return (
		<SpacesBoard
			className="spaces-page__schema-list-item"
			data={filteredConvertedSpaces}
			title={hideTitle ? undefined : object?.name}
			reverseFloorSort={pageSettings.reverseFloorSort}
			SpaceComponent={getSpaceComponent(pageSettings.schemaView)}
			checkListPopupContentSpaceNameTitle={t(
				'spacesPage.schema.singleSchema.spacesBoard.checkListPopupContentSpaceNameTitle'
			)}
			onSpaceClick={goToSpace}
			altNamesVisible={sharedSettings.altNamesVisible}
			onTitleClick={isTitleClickable ? goToObject : undefined}
		/>
	);
});
