import {IGetCheckRecordStatusesResponse} from '@src/api/backend/checkRecordStatuses';
import {ILoadableEntitiesState} from '@tehzor/tools/core/storeHelpers/reducers';
import {ICheckRecordStatus} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {CheckRecordsStatusesType} from './interfaces';

export const extractCheckRecordStatusesById = (data: IGetCheckRecordStatusesResponse) => data.byId;

export const extractCheckRecordStatusesByEntityType = (
	data: IGetCheckRecordStatusesResponse,
	type: CheckRecordsStatusesType
) => {
	const checkRecordStatusesById = extractCheckRecordStatusesById(data);
	return Object.values(checkRecordStatusesById).reduce<
		ILoadableEntitiesState<ICheckRecordStatus>
	>(
		(acc, status) => {
			if (status.for.includes(type)) {
				acc.byId[status.id] = status;
				acc.allIds.push(status.id);
			}

			return acc;
		},
		{allIds: [], byId: {}, loaded: true}
	);
};

export const extractCheckRecordStatusesByEntityTypeAsArray = (
	data: IGetCheckRecordStatusesResponse,
	type: CheckRecordsStatusesType
) => {
	const checkRecordStatusesByEntityType = extractCheckRecordStatusesByEntityType(data, type);
	return checkRecordStatusesByEntityType.allIds.map(
		(id: string) => checkRecordStatusesByEntityType.byId[id]
	);
};
