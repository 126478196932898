import {ISavingAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import {IEditableAuthorizedPersonAction} from '@src/core/hooks/states/useEditableAuthorizedPersonState';
import {Dispatch} from 'react';

export const reset = (
	dispatch: Dispatch<IEditableAuthorizedPersonAction>,
	data?: ISavingAuthorizedPerson
) => {
	dispatch({
		field: 'name',
		type: 'update',
		value: data?.name || undefined
	});
	dispatch({
		field: 'position',
		type: 'update',
		value: data?.position || undefined
	});
	dispatch({
		field: 'authorityBasis',
		type: 'update',
		value: data?.authorityBasis || undefined
	});
	dispatch({
		field: 'authorityExpiry',
		type: 'update',
		value: data?.authorityExpiry || undefined
	});
	dispatch({
		field: 'comment',
		type: 'update',
		value: data?.comment || undefined
	});
};