import {useCategoriesSetPermissions} from '@src/core/hooks/permissions/useCategoriesSetPermissions';
import useAddingCategoriesSet from '@src/components/AddingCategoriesSet/hooks/useAddingCategoriesSet';
import {Menu} from '../components/Menu';

export const ActionsDesktop = () => {
	const {canAdd, canMigrate} = useCategoriesSetPermissions();
	const addButton = useAddingCategoriesSet(canAdd);

	return (
		<>
			{addButton}
			{canMigrate && <Menu/>}
		</>
	);
};
