import useAppSelector from '@src/core/hooks/useAppSelector';
import {structuresActions} from '@/entities/Structures';
import {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {SortChanger} from '@tehzor/ui-components';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';

interface ISchemaSortChangerProps {
	objectId: string;
}

export const SchemaSortChanger = ({objectId}: ISchemaSortChangerProps) => {
	const dispatch = useAppDispatch();
	const {changeSchemaSortRevers} = structuresActions;
	const {schemaSortRevers} = useAppSelector(s =>
		extractStructuresSchemaPageSettings(s, objectId)
	);

	const changeSortReversHandler = useCallback(
		(value: boolean) => {
			dispatch(changeSchemaSortRevers({objectId, revers: value}));
		},
		[objectId, dispatch, changeSchemaSortRevers]
	);

	return (
		<SortChanger
			isRevers={schemaSortRevers}
			onChange={changeSortReversHandler}
		/>
	);
};
