import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {getInternalAcceptance} from '@src/store/modules/app/links/actions/getInternalAcceptance';
import {LinkButton} from '@tehzor/ui-components';
import { useCallback } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formInternalAcceptanceLink} from '@tehzor/tools/utils/links';
import { useTranslation } from 'react-i18next';

export const InternalAcceptanceEntityLink = ({
	internalAcceptanceId,
	objectId
}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const internalAcceptance = useAppSelector(
		s => s.app.links.internalAcceptanceId?.[internalAcceptanceId]
	);
	const {t} = useTranslation();

	useAsync(async () => {
		await Promise.all([dispatch(getInternalAcceptance(objectId, internalAcceptanceId))]);
	}, [internalAcceptanceId, objectId]);

	const handleClick = useCallback(() => {
		pushPath(formInternalAcceptanceLink(objectId, internalAcceptanceId));
	}, [internalAcceptanceId, objectId]);

	if (!internalAcceptance) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${t('components.entityLinks.internalAcceptanceEntityLink.label')} №${internalAcceptance.number}`}
			leftIcon={<i className="tz-external-link-16"/>}
			onClick={handleClick}
			type="filled"
		/>
	);
};
