import {Column} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';
import {MobileOwnerAcceptanceCell} from '../cells/MobileOwnerAcceptanceCell';
import {MobileOwnerAcceptanceCellWithoutObject} from '../cells/MobileInternalAcceptanceCellWithoutObject';

export const mobileColumns: Array<Column<IPreparedOwnerAcceptance>> = [
	{
		id: 'object',
		Cell: MobileOwnerAcceptanceCell,
		className: 'owner-acceptances-page__mobile-cell'
	}
];

export const mobileColumnsWithoutObject: Array<Column<IPreparedOwnerAcceptance>> = [
	{
		id: 'object',
		Cell: MobileOwnerAcceptanceCellWithoutObject,
		className: 'owner-acceptances-page__mobile-cell'
	}
];
