import {DndAttribute, IDndItem, IElementData} from '../interfaces';

/**
 * Получает данные dom элемента
 * 
 * @param items массив элементов
 * @param element dom элемент
 */
export const getElementData = <S extends IDndItem>(
	items: S[], element?: HTMLElement
): IElementData<S> | undefined => {
	if (!element) { return undefined; }

	const container = element.getAttribute(DndAttribute.CONTAINER) || undefined;

	const id = element.getAttribute(DndAttribute.ID) || undefined;
	if (!id) {
		const isEmpty = !!element.getAttribute(DndAttribute.EMPTY);
		return {isEmpty, container, index: 0};
	}

	const index = items.findIndex(val => val.id === id);
	const item = index !== -1 ? items[index] : undefined;

	return {id, container, index, item};
};