import {useMemo} from 'react';

export interface IYearData {
	value: number;
	differentPeriod: boolean;
}

export const useYearsGrid = (decade: number) => useMemo(() => {
		const years: IYearData[] = [];
		for (let i = -1; i < 11; i++) {
			years.push({
				value: decade + i,
				differentPeriod: i < 0 || i >= 10
			});
		}
		return years;
	}, [decade]);
