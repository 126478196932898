import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetTasksResponse extends INormalizedData<ITask> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список задач
 */
export async function requestTasks(
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};
	const response = await httpRequests.withToken.post<IGetTasksResponse>('tasks/get', params);
	return response.data;
}


