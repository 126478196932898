import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export const toInspection = (
	object: IObject,
	links: IInspection['links'] | undefined,
	stage: ObjectStageIds | undefined,
	fields: ISavingInspection,
	key: string,
	nextNumber?: number
): IInspection => {
	const newInspection: IInspection = {
		...(fields as Omit<ISavingInspection, 'newAttachments'>),
		objectId: object.id,
		links,
		stage: stage || ObjectStageIds.BUILDING,
		id: key,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		floor: fields.floor || undefined,
		location: fields.location || undefined,
		categoryId: fields.categoryId || undefined,
		createdAt: Number(key)
	};
	if (nextNumber) {
		newInspection.localNumber = `АВТ-${nextNumber}`;
	}
	return newInspection;
};

export const toListInspection = (
	object: IObject,
	links: IInspection['links'] | undefined,
	stage: ObjectStageIds | undefined,
	fields: ISavingInspection,
	key: string,
	nextNumber?: number
): IInspection => {
	const newInspection: IListInspection = {
		...(fields as Omit<ISavingInspection, 'newAttachments'>),
		objectId: object.id,
		links,
		stage: stage || ObjectStageIds.BUILDING,
		id: key,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		floor: fields.floor || undefined,
		categoryId: fields.categoryId || undefined,
		location: fields.location || undefined,
		object: {id: object.id, name: object.name},
		createdAt: Number(key)
	};
	if (nextNumber) {
		newInspection.localNumber = `АВТ-${nextNumber}`;
	}
	return newInspection;
};
