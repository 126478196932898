import './ProblemCommentsPage.less';
import {Navigate} from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {getProblem} from '@src/store/modules/pages/problem/actions';
import {makeCommentTitle} from '@src/components/Comments/utils/makeCommentTitle';
import {CommentsVisibilityFilter} from '@src/components/CommentsVisibilityFilter/CommentsVisibilityFilter';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {formProblemLink} from '@tehzor/tools/utils/links/formProblemLink';
import {Comments} from '@src/components/Comments';
import {useEntityAndCommentsViewerMobile} from '@src/core/hooks/imagesViewers/mobile/useEntityAndCommentsViewerMobile';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {useCommentsCtx} from '@src/components/Comments/utils/CommentsCtx';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useTranslation} from 'react-i18next';

const ProblemCommentsPage = () => {
	const {t} = useTranslation();

	useScrollRestoration();

	const {objectId, problemId} = useStrictParams<{objectId: string, problemId: string}>();
	const dispatch = useAppDispatch();
	const {entityPermissions, comments, visibility, changeCommentsVisibility} = useCommentsCtx();

	const filter
		= entityPermissions.canViewComments && entityPermissions.canViewReplies ? (
			<CommentsVisibilityFilter
				visibility={visibility}
				changeCommentsVisibility={changeCommentsVisibility}
				popupProps={{placement: 'bottom-end', preferX: 'right'}}
			/>
		) : null;

	useAppHeader(
		{
			title: makeCommentTitle(
				entityPermissions.canViewComments,
				entityPermissions.canViewReplies,
				t
			),
			showBackBtn: true,
			mobileRightButtons: filter
		},
		[entityPermissions, filter]
	);

	useAsync(async () => {
		await dispatch(getProblem(objectId, problemId));
	});

	const [imagesViewer, openImage] = useEntityAndCommentsViewerMobile(
		comments,
		AttachmentFileDestination.COMMENT_OR_REPLY
	);

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return <Navigate to={formProblemLink(objectId, problemId)} />;
	}
	return (
		<div className="page-cont problem-comments-page">
			<Comments
				className={{
					root: 'problem-comments-page__comments',
					content: 'problem-comments-page__comments-content',
					item: 'problem-comments-page__comment',
					footer: 'problem-comments-page__comments-footer',
					replyBtn: 'problem-comments-page__comments-reply-btn',
					reference: 'problem-comments-page__comments-reference',
					input: 'problem-comments-page__comments-input'
				}}
				objectId={objectId}
				onImageClick={openImage}
				commentTypeId={ICommentTypeId.PROBLEM_COMMENT}
			/>
			{imagesViewer}
		</div>
	);
};

export default ProblemCommentsPage;
