import {Dispatch} from 'react';
import './EditableResultWorkAcceptance.less';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {
	IEditableResultWorkAcceptanceAction,
	IEditableResultWorkAcceptanceState
} from '@src/core/hooks/states/useEditableResultWorkAcceptanceState';
import Text from '../editableFields/Text';
import {UnitSelect} from '@src/components/editableFields/UnitSelect';
import {useTranslation} from 'react-i18next';
import {useTranslatedUnitsArray} from '@src/core/hooks/translations/useTranslatedUnitsArray';

interface IEditableResultWorkAcceptanceProps {
	editingState: IEditableResultWorkAcceptanceState;
	editingDispatch: Dispatch<IEditableResultWorkAcceptanceAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableResultWorkAcceptance = (props: IEditableResultWorkAcceptanceProps) => {
	const {editingState, editingDispatch, fieldsSettings, saving} = props;
	const {t} = useTranslation();

	const units = useTranslatedUnitsArray();

	return (
		<div className="editable-result-work-acceptance">
			<div className="editable-result-work-acceptance__info-grid">
				<Text
					field="percent"
					label={t('editableResultWorkAcceptance.percent.label')}
					value={editingState.percent?.toString()}
					editingDispatch={editingDispatch}
					required={fieldsSettings.percent.isRequired}
					disabled={saving}
					hasError={editingState.errors.percent}
					errorMessage={t('editableResultWorkAcceptance.percent.error')}
				/>

				<div className="editable-result-work-acceptance__info-grid-units">
					<Text
						field="unitValue"
						label={t('editableResultWorkAcceptance.unitValue.label')}
						value={editingState.unitValue?.toString()}
						editingDispatch={editingDispatch}
						required={fieldsSettings.unitValue.isRequired}
						disabled={saving}
						hasError={editingState.errors.unitValue}
						errorMessage={t('editableResultWorkAcceptance.unitValue.error')}
						className="editable-result-work-acceptance__info-grid-units-text-input"
					/>

					<UnitSelect
						field="unit"
						label={t('editableResultWorkAcceptance.unit.label')}
						value={editingState.unit?.toString()}
						editingDispatch={editingDispatch}
						required={fieldsSettings.unit.isRequired}
						disabled={saving}
						hasError={editingState.errors.unit}
						unitsListArr={units || []}
						className="editable-result-work-acceptance__info-grid-units-text-input"
						classNameSelectSearch="editable-result-work-acceptance__info-grid-units-select-search"
					/>
				</div>
				<Text
					field="comment"
					elementType="textarea"
					label={t('editableResultWorkAcceptance.comment.label')}
					value={editingState.comment}
					editingDispatch={editingDispatch}
					required={fieldsSettings.comment.isRequired}
					disabled={saving}
					hasError={editingState.errors.comment}
				/>
			</div>
		</div>
	);
};
