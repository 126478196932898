import {MobileEntityDocs} from '@src/components/EntityDocs';
import {ContractLink} from '@src/components/EntityLinks/components/ContractLink';
import {ActualFixDate} from '@src/components/viewFields/ActualFixDate/ActualFixDate';
import {Category} from '@src/components/viewFields/Category';
import Description from '@src/components/viewFields/Description';
import {FixDate} from '@src/components/viewFields/FixDate/FixDate';
import {Links} from '@src/components/viewFields/Links';
import Location from '@src/components/viewFields/Location';
import Prescription from '@src/components/viewFields/Prescription';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import MobileImages from '@src/pages/ProblemPage/components/attachments/Images.mobile';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {IPermissions} from '../../hooks/usePermissions';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';
import {EntityProblemTasks} from '../tasks/EntityProblemTasks';
import {CustomField} from '@src/components/CustomField';
import {ProblemInspectors} from './ProblemInspectors';
import {ProblemPerformers} from './ProblemPerformers';
import ProblemTags from './ProblemTags';
import {ProblemViews} from './ProblemViews';
import Reason from './Reason';
import Status from './Status';

interface IMobileInfoProps {
	objectId: string;
	problem: IPreparedProblem;
	images?: string[];
	hasLocationComments?: boolean;
	permissions: IPermissions;
	settings?: IEntitySettings;

	onImageClick?: (index: number) => void;
}

const MobileInfo = ({
	objectId,
	problem,
	images,
	hasLocationComments,
	onImageClick,
	settings,
	permissions
}: IMobileInfoProps) => {
	const {t} = useTranslation();

	const builtin = settings?.builtin;
	const custom = settings?.custom;

	return (
		<EntityGrid withBorders>
			<Status
				objectId={objectId}
				problem={problem}
			/>
			{problem.description && builtin?.description && (
				<Description value={problem.description} />
			)}
			{images !== undefined && images.length > 0 && builtin?.attachments && (
				<MobileImages
					images={images}
					onClick={onImageClick}
				/>
			)}
			{problem.plannedFixDate && builtin?.plannedFixDate && (
				<FixDate
					plannedFixDate={problem.plannedFixDate}
					status={problem.status}
					critical={problem.critical}
					actualFixDate={problem.actualFixDate}
				/>
			)}
			{problem.actualFixDate && <ActualFixDate value={problem.actualFixDate} />}
			{(problem.performers?.length || problem.performersActiveGroup) &&
			builtin?.performers ? (
				<ProblemPerformers
					problem={problem}
					settings={settings}
				/>
			) : null}

			<MobileEntityDocs id={problem.id} />

			{(problem.inspectors?.length || problem.inspectorsActiveGroup) &&
			builtin?.inspectors ? (
				<ProblemInspectors
					problem={problem}
					settings={settings}
				/>
			) : null}

			{problem.problemTags && problem.problemTags?.length > 0 && builtin?.problemTags ? (
				<ProblemTags
					tags={problem.problemTags}
					fullRow
				/>
			) : null}

			<EntityProblemTasks
				objectId={objectId}
				problem={problem}
			/>

			<Links
				links={problem.links ? {objectId, ...problem.links} : {}}
				fullRow
			>
				{problem.contractId && permissions.canViewContract && (
					<ContractLink contractId={problem.contractId} />
				)}
			</Links>
			{(!!problem.plan || !!problem.floor || !!problem.location) && builtin?.location && (
				<Location
					objectId={objectId}
					planId={problem.plan?.id}
					floor={problem.floor}
					location={problem.location}
					hasComments={hasLocationComments}
				/>
			)}
			{problem.categoryId && builtin?.categoryId && (
				<Category categoryId={problem.categoryId} />
			)}
			{problem.reason && builtin?.reason && <Reason value={problem.reason} />}
			{custom &&
				Object.values(custom).map(customSetting => (
					<CustomField
						fields={problem.customFields}
						setting={customSetting}
					/>
				))}
			<EditorInfo
				icon={<i className="tz-document-20" />}
				label={t('problemPage.info.createdAt.label')}
				date={problem.createdAt}
				user={problem.createdBy}
				localDate={problem.localCreatedAt}
				localLabel={t('problemPage.info.createdAt.localLabel')}
			/>
			{problem.createdAt !== problem.modifiedAt && (
				<EditorInfo
					icon={<i className="tz-edit-20" />}
					label={t('problemPage.info.updatedAt.label')}
					date={problem.modifiedAt}
					user={problem.modifiedBy}
				/>
			)}
			{permissions.canSeeViews && (
				<ProblemViews
					problem={problem}
					objectId={objectId}
					canSeeViews={permissions.canSeeViews}
				/>
			)}
			{problem.prescription && builtin?.prescription && (
				<Prescription value={problem.prescription} />
			)}
		</EntityGrid>
	);
};

export default memo(MobileInfo);
