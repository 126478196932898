import {PersistQueryClientOptions} from '@tanstack/react-query-persist-client';
import {createIDBPersister} from './queryClientPersister';

const persistableQueryKeys = [
	'saving-data',
	'latest-internal-acceptances',
	'latest-checks',
	'latest-warranty-claims',
	'latest'
];

export const queryClientPersister = createIDBPersister();

export const persistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
	persister: queryClientPersister,
	dehydrateOptions: {
		shouldDehydrateMutation: mutation =>
			mutation.state.isPaused || mutation.state.status === 'error',
		/**
		 * Это нужно для фильтрации query. Чтобы указать какие из них сложим в indexedDB
		 * */
		shouldDehydrateQuery: query =>
			!!query.meta?.persist ||
			persistableQueryKeys.some(key => query.options.queryKey?.includes(key))
	}
};
