interface IItemWithId {
	id: string;
}

type Tree<T> = T & {
	children?: Array<Tree<T>>;
};

/**
 * Находит элемент в дереве по id
 *
 * @param {Array<Tree>} tree дерево элементов
 * @param {string} id id элемента
 */
const findTreeNode = <T extends IItemWithId>(tree: Array<Tree<T>>, id: string): Tree<T> | undefined => {
	for (const object of tree) {
		if (object.id === id) {
			return object;
		}
		if (object.children) {
			const sub = findTreeNode(object.children, id);
			if (sub) {
				return sub;
			}
		}
	}
	return undefined;
};

export default findTreeNode;
