import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useTranslation} from 'react-i18next';

const plusIcon20 = <i className="tz-plus-20" />;
const plusIcon16 = <i className="tz-plus-16" />;

export const AddButton = () => {
	const {t} = useTranslation();
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {pushPath} = useChangePath();

	const add = useCallback(() => {
		pushPath(`/objects/${objectId}/plans/add`);
	}, [objectId]);

	return (
		<>
			<Desktop>
				<div className="plans-page_add-button">
					<Button
						type="accent-blue"
						leftIcon={plusIcon20}
						label={t('plansPage.addButton')}
						onClick={add}
					/>
				</div>
			</Desktop>

			<BeforeDesktop>
				<FloatingActionButton
					icon={plusIcon16}
					label={t('plansPage.addButton')}
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={add}
				/>
			</BeforeDesktop>
		</>
	);
};
