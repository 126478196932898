import React, {useCallback, useEffect, useState} from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {EditableMeterConsumption} from '../EditableMeterConsumption';
import {
	convertToSave,
	errorsFns,
	isEdited,
	useEditableMeterConsumptionState
} from '@src/core/hooks/states/useEditableMeterConsumptionState';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';
import {ISavingMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/ISavingMeterConsumption';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

const fieldsSettings = {
	tariffId: {fieldId: 'tariffId', isRequired: false},
	value: {fieldId: 'value', isRequired: false}
};

/**
 * Логика редактирования и сохранения показания прибора учёта (нового или существующего)
 */
export const useEditableMeterConsumption = (
	meterConsumption: IMeterConsumption | undefined,
	saving: boolean
): [React.ReactNode, () => ISavingMeterConsumption | undefined, () => void, boolean] => {
	const [editingState, editingDispatch] = useEditableMeterConsumptionState(meterConsumption);
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, meterConsumption));
	}, [editingState, meterConsumption]);

	const getSavingData = useCallback(() => {
		// Проверка наличия ошибок в состоянии
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (!isEdited(editingState, meterConsumption)) {
			return undefined;
		}
		return convertToSave(editingState, meterConsumption, true);
	}, [editingState, meterConsumption]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: meterConsumption
		});
	}, [meterConsumption]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [meterConsumption]);

	const fields = (
		<EditableMeterConsumption
			editingState={editingState}
			editingDispatch={editingDispatch}
			saving={saving}
			fieldsSettings={fieldsSettings}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
