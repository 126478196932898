import {useTransition, useCallback, useEffect, useMemo} from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {changeSchemaView, togglePending} from '@src/store/modules/settings/pages/spaces/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {useUpdateEffect} from 'react-use';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useTranslation} from 'react-i18next';

const tabsIndexes = {
	[SpacesSchemaVariants.PROBLEMS]: 0,
	[SpacesSchemaVariants.CHECK_LISTS]: 1,
	[SpacesSchemaVariants.WORK_ACCEPTANCES]: 2,
	[SpacesSchemaVariants.REPORTS]: 3
};
const tabsKeys = [
	SpacesSchemaVariants.PROBLEMS,
	SpacesSchemaVariants.CHECK_LISTS,
	SpacesSchemaVariants.WORK_ACCEPTANCES,
	SpacesSchemaVariants.REPORTS
];

interface IDesktopSchemaViewTabsProps {
	objectId: string;
}

const classNames = {
	root: 'spaces-page__plate-header-tabs',
	links: 'spaces-page__plate-header-tabs-links'
};

export const DesktopSchemaViewTabs = ({objectId}: IDesktopSchemaViewTabsProps) => {
	const {t} = useTranslation();

	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const [isPending, startTransition] = useTransition();
	const permissions = useCheckListPermissions(objectId);
	// Если в настройках сохранён недоступный вариант, то сбрасываем на нарушения
	useEffect(() => {
		if (!permissions.canView && pageSettings.schemaView !== SpacesSchemaVariants.PROBLEMS) {
			dispatch(changeSchemaView(objectId, SpacesSchemaVariants.PROBLEMS));
		}
	}, [permissions.canView]);

	useUpdateEffect(() => {
		dispatch(togglePending(isPending));
	}, [isPending]);

	const handleChange = useCallback(
		(index: number) => {
			startTransition(() => {
				dispatch(changeSchemaView(objectId, tabsKeys[index]));
			});
		},
		[objectId]
	);

	const tabs = useMemo(
		() => [
			<TabLink label={t('spacesPage.schemaViewTabs.tabLink.label.defects')} />,
			<TabLink label={t('spacesPage.schemaViewTabs.tabLink.label.checkLists')} />,
			<TabLink label={t('spacesPage.schemaViewTabs.tabLink.label.workAcceptances')} />,
			<TabLink label={t('spacesPage.schemaViewTabs.tabLink.label.reports')} />
		],
		[t]
	);

	return permissions.canView ? (
		<Tabs
			className={classNames}
			links={tabs}
			activeTab={tabsIndexes[pageSettings.schemaView]}
			onActiveTabChange={handleChange}
		/>
	) : null;
};
