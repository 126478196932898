import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {offlineJournalActionStatus} from '@src/pages/filters/OfflineJournalFilterPage/data/entityFilters';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';

export const filterEntities = (
	entities: IOfflineJournalEntity[],
	filters: IOfflineJournalFiltersState
) =>
	entities.filter((entity: IOfflineJournalEntity) => {
		const createdAt = new Date(entity.createdAt ?? 0);
		const createdAtTimestamp = createdAt.getTime();
		const filterFromTimestamp = filters.createdAtFrom?.getTime() ?? 0;
		const filterToTimestamp = filters.createdAtTo?.getTime() ?? Number.MAX_VALUE;
		const actions = filters.actions ?? [];
		const statuses = filters.statuses ?? [];

		return (
			createdAtTimestamp >= filterFromTimestamp &&
			createdAtTimestamp <= filterToTimestamp &&
			(actions.length === 0 ||
				(entity.entity.entityType && actions.includes(entity.entity.entityType))) &&
			(statuses.length === 0 ||
				statuses.length === 2 ||
				(statuses.includes(offlineJournalActionStatus.WITH_ERRORS) &&
					entity.transfer.status === OfflineDataTransferStatus.TRANSFER_ERROR) ||
				(statuses.includes(offlineJournalActionStatus.WITHOUT_ERRORS) &&
					entity.transfer.status !== OfflineDataTransferStatus.TRANSFER_ERROR))
		);
	});
