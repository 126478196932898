import * as React from 'react';
import './AppBreadcrumbs.less';
import {Breadcrumbs} from '@tehzor/ui-components';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useChangePath} from '@src/core/hooks/useChangePath';
import classNames from 'classnames';

interface IAppBreadcrumbsProps {
	items: IBreadcrumb[];
	children?: React.ReactNode;
	className?: string;
}

const AppBreadcrumbs = ({items, children, className}: IAppBreadcrumbsProps) => {
	const {pushPath} = useChangePath();
	return (
		<div className={classNames('app-breadcrumbs', className)}>
			<Breadcrumbs
				className="app-breadcrumbs__links"
				items={items}
				onNavigate={pushPath}
			/>

			{children !== undefined && <div className="app-breadcrumbs__controls">{children}</div>}
		</div>
	);
};

export default AppBreadcrumbs;
