import {IPreparedSpaceStatusesSet} from '@src/pages/manage/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import {UserInfo} from '@tehzor/ui-components';
import {CellProps} from 'react-table';

export const UserCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) =>
	row.original.createdBy ? (
		<div>
			<UserInfo user={row.original.createdBy} />
		</div>
	) : null;
