import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useMemo} from 'react';
import {useExtractSpaceStatusesSetById} from '../queries/spaceStatusesSets/hooks';

export const useSpaceStatusesSetBreadcrumbs = (spaceStatusesSetId?: string) => {
	const {data: spaceStatusesSet} = useExtractSpaceStatusesSetById(spaceStatusesSetId);

	return useMemo(() => {
		const items: IBreadcrumb[] = [];

		items.push({
			label: 'Наборы статусов помещений',
			url: '/manage/space-statuses-sets'
		});

		if (spaceStatusesSet) {
			items.push({
				label: spaceStatusesSet.name,
				url: `/manage/space-statuses-sets/${spaceStatusesSetId}`
			});
		}

		return items;
	}, [spaceStatusesSet, spaceStatusesSetId]);
};
