import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editProfile} from '@src/store/modules/auth/profile/actions';
import {Toggle} from '@tehzor/ui-components';
import {ChangeEvent, useCallback} from 'react';
import {useTranslation} from "react-i18next";

interface ISubscribeSettingsProps {
	value?: boolean;
}

export const SubscribeSettings = ({value}: ISubscribeSettingsProps) => {
	const {t} = useTranslation();

	const dispatch = useAppDispatch();
	const handleChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
		await dispatch(editProfile({subscribed: event.target?.checked}));
	}, []);

	return (
		<div>
			<Toggle
				checked={value}
				onChange={handleChange}
			>
				{t('profilePage.settings.emailSubscription')}
			</Toggle>
		</div>
	);
};