import {
	IConvertCustomField,
	ICustomField,
	ICustomFieldSetting
} from '@tehzor/tools/interfaces/fields';
import {filteredValueTypes} from '@src/components/CustomFilters/constants/filteredValueTypes';

export const convertCustomFields = (
	settings: Record<string, ICustomFieldSetting>,
	customFields?: Record<string, ICustomField>
) => {
	if (!customFields) return [];
	const result: IConvertCustomField[] = [];
	for (const key in settings) {
		if (settings.hasOwnProperty(key)) {
			const field = customFields[settings[key].fieldId];
			if (filteredValueTypes.includes(field.valueType.id)) {
				result.push({
					id: field.id,
					values: field.values,
					name: field.name,
					key: field.key,
					valueTypeId: field.valueType.id
				});
			}
		}
	}
	return result;
};
