import {useMemo} from 'react';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {IParamAggregateEntityPermissions, useAggregateEntityPermissions} from '@src/core/hooks/permissions/useAggregateEntityPermissions';

const defaultTypes: ICheckAddingEntityType[] = ['problem', 'inspection'];

export function useAvailableTypes(
	objectId: string,
	types: ICheckAddingEntityType[] = defaultTypes,
	param?: IParamAggregateEntityPermissions
) {
	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const checkProblemPermissions = useAggregateEntityPermissions(objectId, 'checkProblemsAdd', param);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);
	const checkInspectionPermissions = useAggregateEntityPermissions(objectId, 'checkInspectionsAdd', param);

	return useMemo<ICheckAddingEntityType[]>(() => {
		const permissions = {
			problem: problemsPermissions.canAdd && checkProblemPermissions.canAdd,
			inspection: inspectionsPermissions.canAdd && checkInspectionPermissions.canAdd
		};
		return types.filter(t => permissions[t]);
	}, [types, problemsPermissions, inspectionsPermissions]);
}
