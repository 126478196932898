import {IconMenu, MenuItem} from '@tehzor/ui-components';
import { useCallback, useContext } from 'react';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from '../DispatchActionCtx';
import {IPreparedCheckList} from '@src/pages/manage/CheckListsPage/interfaces/IPreparedCheckList';

const deleteIcon = <i className="tz-delete" />;
const copyIcon = <i className="tz-copy" />;

export const MobileMenuCell = ({row}: CellProps<IPreparedCheckList>) => {
	const menuActions = [];
	const perms = useCheckListPermissions();
	const dispatchAction = useContext(DispatchActionCtx);
	const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [row.original.id]);

	const handleCopy = useCallback(() => {
		void dispatchAction({type: 'copy', payload: row.original});
	}, [row.original.id]);

	if (perms.canAdd) {
		menuActions.push(
			<MenuItem
				key="copy"
				className="menu-item_red"
				icon={copyIcon}
				onClick={handleCopy}
			>
				Копировать
			</MenuItem>
		);
	}

	if (perms.canDelete) {
		menuActions.push(
			<MenuItem
				key="edit"
				className="menu-item_red"
				icon={deleteIcon}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}

	return menuActions.length ? <IconMenu>{menuActions}</IconMenu> : null;
};
