import * as React from 'react';
import SwitchBase, {ISwitchIconProps} from '../../SwitchBase';
import CheckboxIcon from './components/CheckboxIcon';
import {CheckboxGroupContext} from '../CheckboxGroup';

export interface ICheckboxIconProps extends ISwitchIconProps {
	semiChecked?: boolean;
}

export interface ICheckboxProps {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				icon?: string;
				content?: string;
		  };
	style?: React.CSSProperties;
	children?: React.ReactNode;
	id?: string;
	name?: string;
	value?: string;
	checked?: boolean;
	semiChecked?: boolean;
	disabled?: boolean;
	icon?: React.ReactElement<ICheckboxIconProps>;
	dataTestId?: string;

	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Checkbox = (props: ICheckboxProps) => {
	const {icon, semiChecked, dataTestId, ...rest} = props;

	const switchIcon = icon ? React.cloneElement(icon, {semiChecked}) : <CheckboxIcon semiChecked={semiChecked}/>;

	return (
		<SwitchBase
			{...rest}
			dataTestId={dataTestId}
			type="checkbox"
			switchIcon={switchIcon}
			GroupContext={CheckboxGroupContext}
		/>
	);
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
