import './TasksPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {AddButton} from './components/AddButton';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import classNames from 'classnames';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';
import {TasksTablePage} from '@src/pages/TasksPage/components/tabs/TasksTablePage/TasksTablePage';
import {TaskSchedulePage} from '@src/pages/TasksPage/components/tabs/TasksSchedulePage/TasksSchedulePage';
import {useTranslation} from 'react-i18next';
import {useTasksPageDisplayMode} from '@src/core/hooks/queries/tasks/hooks';

export const TasksPage = () => {
	const {t} = useTranslation();

	useAppHeader({
		title: t('components.entityTasks.header'),
		mobileRightButtons: <AppUpdateMenu />
	});

	const {canAdd} = useTasksPermissions();
	const isDesktop = useIsDesktop();
	const displayMode = useTasksPageDisplayMode();

	return (
		<div className={classNames('page-cont', isDesktop ? 'tasks-page' : 'tasks-page__mobile')}>
			{canAdd && <AddButton />}
			{displayMode === TasksDisplayMode.LIST && <TasksTablePage />}
			{displayMode === TasksDisplayMode.SCHEDULE && <TaskSchedulePage />}
		</div>
	);
};
