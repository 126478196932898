import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {DesktopWorkAcceptanceAddBtn} from './WorkAcceptanceAddBtn.desktop';

interface IDesktopActionsProps {
	objectId: string;
}

export const DesktopActions = ({objectId}: IDesktopActionsProps) => {
	const permissions = useWorkAcceptancesPermissions(objectId);

	return permissions.canAdd ? <DesktopWorkAcceptanceAddBtn objectId={objectId} /> : null;
};
