import {PrivateRoute} from '@src/containers/PrivateRoute';
import CategoriesSetsFiltersRoutingPage from '@src/pages/filters/CategoriesSetsFiltersRoutingPage/CategoriesSetsFiltersRoutingPage';
import {CategoriesSetMigrationPage} from '@src/pages/manage/CategoriesSetMigrationPage';
import CategoriesSetPage from '@src/pages/manage/CategoriesSetPage/CategoriesSetPage';
import {AllCategoriesSetsPage} from '@src/pages/manage/CategoriesSetsPage';
import {categoriesSetsFilterRoutes} from './categoriesSetsFilterRoutes';
import CategoriesSetAddingPage from '@src/pages/manage/CategoriesSetPage/CategoriesSetAddingPage';

export const manageCategoriesSetsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllCategoriesSetsPage />} />
	},
	{
		path: 'add',
		element: <PrivateRoute element={<CategoriesSetAddingPage />} />
	},
	{
		path: ':categoriesSetId',
		element: <PrivateRoute element={<CategoriesSetPage />} />
	},
	{
		path: 'migration',
		element: <PrivateRoute element={<CategoriesSetMigrationPage />} />
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<CategoriesSetsFiltersRoutingPage />} />,
		children: categoriesSetsFilterRoutes
	}
];
