export enum WorkAcceptanceStatusIds {
	WAITING = 'waiting',
	IN_PROGRESS = 'in-progress',
	PROBLEMS_IDENTIFIED = 'problems-identified',
	ACCEPTED = 'accepted',
	REJECTED = 'rejected'
}

export interface IWorkAcceptanceStatus {
	id: WorkAcceptanceStatusIds;
	name: string;
	color?: string;
	textColor?: string;
	order?: number;
}
