import './ProviderButton.less';
import {Button} from '../../../../buttons';
import {IProvider} from '../../interfaces/IProvider';

interface IProviderButtonProps extends IProvider {
	onClick?: (key: string) => void;
}

export const ProviderButton = ({key, name, icon, onClick}: IProviderButtonProps) => {
	const handleClick = () => {
		if (onClick) {
			onClick(key);
		}
	};

	return (
		<Button
			className={{
				root: 'auth-provider-button',
				leftIcon: 'auth-provider-button__icon',
				label: 'auth-provider-button__label'
			}}
			label={name}
			leftIcon={icon}
			onClick={handleClick}
		/>
	);
};
