import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IMeterTariff} from '@tehzor/tools/interfaces/meterConsumptions/IMeterTariff';

export type IGetMeterTariffsResponse = INormalizedData<IMeterTariff>;

/**
 * Возвращает список типов тарифов приборов учёта
 */
export const requestMeterTariffs = async () => {
	const response = await httpRequests.withToken.get<IGetMeterTariffsResponse>('meter-tariffs');
	return response.data;
};
