import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {findPermission} from '@tehzor/tools/utils/findPermission';

/**
 * Возвращает полномочия для конкретной проверки
 *
 * @param check проверка
 * @param networkStatus статус соединения
 * @param objectId id объекта
 * @param user текущий пользователь
 */
export const getPermissionsForCheck = (
	check: ICheck,
	networkStatus: boolean,
	objectId?: string,
	user?: IUser
) => {
	let canDelete = false;
	if (networkStatus && user) {
		const deletePermission = findPermission(
			user.roles || [],
			'checksDelete',
			UserRoleScopes.OBJECT,
			objectId
		);
		if (deletePermission) {
			canDelete = deletePermission.restrictions
				? !!deletePermission.restrictions.checksCreatedByUser
				  && !!check.createdBy
				  && user.id === check.createdBy.id
				: true;
		}
	}
	return {canDelete};
};
