import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

export const WorkAcceptancesSearch = () => {
	const {state, dispatch} = useEntitiesFiltersCtx<IWorkAcceptancesFiltersState>();
	const [searchValue, setSearchValue] = useState<string | undefined>(state.searchString);
	const handleChange = useCallback((v: string | undefined) => {
		setSearchValue(v);
	}, []);
	const handleApplySearch = useCallback(() => {
		dispatch({searchString: searchValue});
	}, [dispatch, searchValue]);

	const handleClear = useCallback(() => {
		dispatch({searchString: undefined});
	}, [dispatch]);

	useUpdateEffect(() => {
		setSearchValue(state.searchString);
	}, [state.searchString]);

	return (
		<TranslatedSelectSearch
			className="work-acceptances-page__search"
			value={searchValue}
			type="filter"
			onClear={handleClear}
			onChange={handleChange}
			onSearch={searchValue ? handleApplySearch : undefined}
		/>
	);
};
