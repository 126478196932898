import {
	IProblemsFiltersState,
	IProblemsSortState
} from '@src/store/modules/settings/pages/problems/interfaces';

export type IProblemsListQueryKey = [] & {
	0: string;
	1: string;
	2: IProblemsFiltersState;
	3: IProblemsSortState;
	4: number;
	5: number;
};
export const problemsQueryKeys = {
	all: () => ['problems'],
	one: () => ['problem'],
	list: () => [...problemsQueryKeys.all(), 'list'],
	localList: () => [...problemsQueryKeys.all(), 'local-list'],
	add: () => [...problemsQueryKeys.one(), 'add'],
	savingDatas: () => [...problemsQueryKeys.one(), 'saving-data'],
	savingData: (key: string) => [...problemsQueryKeys.one(), 'saving-data', key],
	details: () => [...problemsQueryKeys.one(), 'detail'],
	detail: (problemId: string) => [...problemsQueryKeys.one(), 'detail', problemId],
	edit: () => [...problemsQueryKeys.one(), 'edit'],
	export: () => [...problemsQueryKeys.all(), 'export'],
	comments: () => [...problemsQueryKeys.all(), 'comments']
};
