import {ActualFixDate} from '@src/components/viewFields/ActualFixDate/ActualFixDate';
import {Category} from '@src/components/viewFields/Category';
import Description from '@src/components/viewFields/Description';
import {FixDate} from '@src/components/viewFields/FixDate/FixDate';
import Location from '@src/components/viewFields/Location';
import Prescription from '@src/components/viewFields/Prescription';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {IPermissions} from '../../hooks/usePermissions';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';
import {CustomField} from '@src/components/CustomField';
import {ProblemInspectors} from './ProblemInspectors';
import {ProblemPerformers} from './ProblemPerformers';
import ProblemTags from './ProblemTags';
import {ProblemViews} from './ProblemViews';
import Reason from './Reason';
import Status from './Status';

interface IDesktopInfoProps {
	objectId: string;
	problem: IPreparedProblem;
	hasLocationComments: boolean;
	permissions: IPermissions;
	settings?: IEntitySettings;
}

const DesktopInfo = ({
	objectId,
	problem,
	hasLocationComments,
	permissions,
	settings
}: IDesktopInfoProps) => {
	const {t} = useTranslation();

	const builtin = settings?.builtin;
	const custom = settings?.custom;

	return (
		<EntityGrid
			className={{wrap: 'problem-page__d-entities-grid'}}
			withBorders
		>
			{problem && (
				<Status
					objectId={objectId}
					problem={problem}
				/>
			)}
			{problem.description && builtin?.description && (
				<Description value={problem.description} />
			)}
			{problem.plannedFixDate && builtin?.plannedFixDate && (
				<FixDate
					plannedFixDate={problem.plannedFixDate}
					status={problem.status}
					critical={problem.critical}
					actualFixDate={problem.actualFixDate}
				/>
			)}
			{problem.actualFixDate && <ActualFixDate value={problem.actualFixDate} />}
			{(problem.performers?.length || problem.performersActiveGroup) &&
			builtin?.performers ? (
				<ProblemPerformers
					problem={problem}
					settings={settings}
				/>
			) : null}

			{(problem.inspectors?.length || problem.inspectorsActiveGroup) &&
			builtin?.inspectors ? (
				<ProblemInspectors
					problem={problem}
					settings={settings}
				/>
			) : null}

			{problem.problemTags && problem.problemTags?.length > 0 && builtin?.problemTags ? (
				<ProblemTags
					tags={problem.problemTags}
					fullRow
				/>
			) : null}

			{(!!problem.plan || !!problem.floor || !!problem.location) && builtin?.location && (
				<Location
					objectId={objectId}
					planId={problem.plan?.id}
					floor={problem.floor}
					location={problem.location}
					status={problem.status}
					hasComments={hasLocationComments}
				/>
			)}
			{problem.categoryId && builtin?.categoryId && (
				<Category categoryId={problem.categoryId} />
			)}
			{problem.reason && builtin?.reason && <Reason value={problem.reason} />}
			{custom &&
				Object.values(custom).map(customSetting => (
					<CustomField
						key={customSetting.id}
						fields={problem.customFields}
						setting={customSetting}
					/>
				))}
			<EditorInfo
				icon={<i className="tz-document-20" />}
				label={t('problemPage.info.createdAt.label')}
				date={problem.createdAt}
				user={problem.createdBy}
				localDate={problem.localCreatedAt}
				localLabel={t('problemPage.info.createdAt.localLabel')}
			/>
			{problem.createdAt !== problem.modifiedAt && (
				<EditorInfo
					icon={<i className="tz-edit-20" />}
					label={t('problemPage.info.updatedAt.label')}
					date={problem.modifiedAt}
					user={problem.modifiedBy}
				/>
			)}
			{permissions.canSeeViews && (
				<ProblemViews
					problem={problem}
					objectId={objectId}
					canSeeViews={permissions.canSeeViews}
				/>
			)}
			{problem.prescription && builtin?.prescription && (
				<Prescription value={problem.prescription} />
			)}
		</EntityGrid>
	);
};

export default memo(DesktopInfo);
