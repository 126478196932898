import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useEditableStructureDialog} from '@src/components/EditableStructureDialog/hooks/useEditableStructureDialog';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {deleteStructure} from '@src/store/modules/entities/structure/actions/delete';
import {useStructuresPermissions} from '@src/core/hooks/permissions/useStructuresPermissions';
import {useTranslation} from 'react-i18next';

interface IDesktopMenuProps {
	structure: IStructure;
}

export const DesktopMenu = ({structure}: IDesktopMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const permissions = useStructuresPermissions(structure.objectId);

	// Диалог редактирования структуры
	const [editDialog, openEditDialog] = useEditableStructureDialog(
		structure.objectId,
		structure.id
	);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		t('structurePage.actions.menu.confirmDialog.delete.title'),
		`${t('structurePage.actions.menu.confirmDialog.delete.message')} "${structure.name}"?`,
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('useConfirmDialog.rejectBtnLabel')
		},
	);

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteStructure(structure.objectId, structure.id));
			goBack();
		}
	}, [structure.objectId, structure.id]);

	const items = [];
	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit" />}
				onClick={openEditDialog}
			>
				{t('structurePage.actions.menuItems.edit')}
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={handleDeleteClick}
			>
				{t('structurePage.actions.menuItems.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canEdit && editDialog}
			{permissions.canDelete && deleteDialog}
		</>
	) : null;
};
