import {useMemo} from 'react';
import {TabButton} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

// Пока они просто для красоты
// TO-DO Нужно связать их со стором и таблицей
export const FieldButtons = () => {
	const {t} = useTranslation();

	const fields = useMemo(
		() => [{id: 'status', name: t('spacesPage.schema.table.fieldButtons.fields.status.name')}],
		[t]
	);

	return fields.length ? (
		<div className="spaces-page__reports-table-field-buttons">
			{fields.map(field => (
				<TabButton
					key={field.id}
					value={field.id}
					active
					className="spaces-page__reports-table-field-buttons-item"
					label={field.name}
				/>
			))}
		</div>
	) : null;
};
