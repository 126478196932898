import {useQueryClient} from '@tanstack/react-query';
import {plansQueryKeys} from '../keys';
import {requestPlans} from '@src/api/backend/plans';

export const usePlansQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(plansQueryKeys.lists(), {
		queryFn: async ({queryKey}) => requestPlans(queryKey[2] as string[]),
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
