import {MobileEntityAdding} from './EntityAdding.mobile';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';

interface IMobileActionsProps {
	objectId?: string;
}

export const ActionsMobile = ({objectId}: IMobileActionsProps) => {
	const permissions = useOwnerAcceptancesPermissions(objectId);
	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return (
		<>
			{objectId !== undefined
				&& permissions.canAdd
				&& (problemsPermissions.canAdd || inspectionsPermissions.canAdd) && (
					<MobileEntityAdding objectId={objectId}/>
				)}
		</>
	);
};
