import { memo } from 'react';
import './InspectionIndicator.less';

const inspectionIcon = <i className="tz-inspection-16" style={{color: '#9AA6B6'}} />;

interface InspectionIndicatorProps {
	count: number;
}

export const InspectionIndicator = memo(({count}: InspectionIndicatorProps) => (
	<div className="inspection-indicator">
		{inspectionIcon}
		<div className="inspection-indicator__counter">{count}</div>
	</div>
));
