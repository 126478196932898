export const fieldsQueryKeys = {
	all: () => ['fields'],
	one: () => ['field'],
	customLists: () => [...fieldsQueryKeys.all(), 'custom-list'],
	builtinLists: () => [...fieldsQueryKeys.all(), 'builtin-list'],
	types: {
		entityTypes: () => [...fieldsQueryKeys.all(), 'entity-types'],
		valueTypes: () => [...fieldsQueryKeys.all(), 'value-types']
	}
};
