import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableWarrantyClaimState, IEditableWarrantyClaimAction, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';

export const useEditableWarrantyClaimState = (claim?: IWarrantyClaim) =>
	useReducer<
		Reducer<IEditableWarrantyClaimState, IEditableWarrantyClaimAction>,
		IWarrantyClaim | undefined
	>(createReducer(init, errorsFns), claim, init);
