import { useMemo } from 'react';
import * as React from 'react';
import {Tree} from 'array-to-tree';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import splitAttachments from '@tehzor/tools/utils/splitAttachments';
import {flattenTree} from '@src/utils/tree';
import {useEntitiesViewer} from './useEntitiesViewer';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import IAttachmentsImageData from '@tehzor/tools/interfaces/IAttachmentsImageData';
import {IEntity} from '@tehzor/ui-components/src/components/photos/EntitiesMobilePhotoViewer/EntitiesMobilePhotoViewer';

/**
 * Хук создания просмотрщика изображений комментариев к сущности
 *
 * @param comments комментарии
 * @param commentsAttachmentFileDestination
 */
export function useEntityAndCommentsViewerMobile(
	comments: Array<Tree<IComment>>,
	commentsAttachmentFileDestination: AttachmentFileDestination
): [React.ReactNode, (id: string, i: number) => void] {
	const data = useMemo(() => {
		const items = flattenTree(comments);
		const result: IEntity[] = [];

		for (let i = items.length - 1; i >= 0; i--) {
			const item = items[i];
			if (item.attachments) {
				const [imagesAttachments] = splitAttachments(item.attachments);
				const files: string[] = [];
				const attachmentsData: IAttachmentsImageData[] = [];
				for (const attachment of imagesAttachments) {
					if (attachment.full) {
						files.push(attachment.full.url);
						attachmentsData.push({
							id: attachment.id,
							url: attachment.full.url,
							canvas: attachment.canvas,
							original: attachment.original?.url
						});
					}
				}
				if (files.length) {
					result.push({
						id: item.id,
						title: item.official
							? item.number
								? `Фото ответа №${item.number}`
								: 'Фото ответа'
							: 'Фото комментария',
						files,
						type: commentsAttachmentFileDestination,
						attachments: attachmentsData
					});
				}
			}
		}
		return result;
	}, [comments, commentsAttachmentFileDestination]);

	return useEntitiesViewer(data, false);
}
