import {wsConnector} from '@src/api/backend/wsConnector';
import ICreationStatsItem from '@tehzor/tools/interfaces/statistics/ICreationStatsItem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IGetSpacesInspectionStatsResponse = ICreationStatsItem[];

/**
 * Отправляет запрос на получение статистики по прохождению помещений
 *
 * @param objects фильтр по объектам
 * @param stage стадия объектов
 */
export const requestSpacesInspectionStats = (
	objects: string[] | undefined,
	stage: ObjectStageIds
) =>
	wsConnector.sendAuthorizedRequest<IGetSpacesInspectionStatsResponse>(
		'getSpacesInspectionStats',
		{
			objects,
			stage
		}
	);
