import {queryClient} from '@src/api/QueryClient';
import {authorizedPersonsQueryKeys} from '@src/api/cache/authorizedPersons/keys';
import {addErrorToast} from '@src/utils/toasts';
import {makeAuthorizedPersonDeleteRequest} from '@src/api/backend/authorizedPerson';

export const useDeleteAuthorizedPerson = () => async (authorizedPersonId: string) => {
	try {
		await makeAuthorizedPersonDeleteRequest(authorizedPersonId);
		void queryClient.invalidateQueries({queryKey: authorizedPersonsQueryKeys.list()});
		void queryClient.refetchQueries({queryKey: authorizedPersonsQueryKeys.list()});
	} catch (e) {
		addErrorToast('Уполномоченное лицо', 'не удалось удалить сущность');
	}
};
