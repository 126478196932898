import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {ISavingSpace} from '@src/interfaces/saving/ISavingSpace';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {ContractFormId} from '@tehzor/tools/interfaces/spaces/IContractForm';

export type IEditableSpaceState = IEditableEntityState<{
	name?: string;
	altName?: string;
	type?: SpaceTypeId;
	status?: string;
	floor?: string;
	plannedArea?: string;
	actualArea?: string;
	typeDecoration?: string;
	attachments: IAttachment[];
	areaBTI?: string;
	numberBTI?: string;
	floorBTI?: string;
	externalId?: string;
	contractForm?: ContractFormId;
	markupForRegistration?: boolean;
	decorationWarrantyExpiredDate?: number;
	constructiveWarrantyExpiredDate?: number;
	technicalEquipmentWarrantyExpiredDate?: number;
}>;

export type IEditableSpaceAction = IEditableEntityAction<IEditableSpaceState, ISpace>;

const makeEmptyState = (): IEditableSpaceState => ({
	name: '',
	altName: '',
	type: undefined,
	floor: '',
	plannedArea: undefined,
	actualArea: undefined,
	typeDecoration: undefined,
	attachments: [],
	areaBTI: undefined,
	numberBTI: undefined,
	floorBTI: undefined,
	externalId: undefined,
	contractForm: undefined,
	markupForRegistration: undefined,
	decorationWarrantyExpiredDate: undefined,
	constructiveWarrantyExpiredDate: undefined,
	technicalEquipmentWarrantyExpiredDate: undefined,
	errors: {
		name: false,
		altName: false,
		type: false,
		status: false,
		floor: false,
		plannedArea: false,
		actualArea: false,
		typeDecoration: false,
		attachments: false,
		areaBTI: false,
		numberBTI: false,
		floorBTI: false,
		externalId: false,
		contractForm: false,
		markupForRegistration: false,
		decorationWarrantyExpiredDate: false,
		constructiveWarrantyExpiredDate: false,
		technicalEquipmentWarrantyExpiredDate: false
	}
});

const replaceCommaToDot = (str: string | undefined) => {
	if (str) {
		return str.replace(/,/g, '.');
	}
	return undefined;
};

export const init = (space?: ISpace): IEditableSpaceState => {
	const empty = makeEmptyState();
	return space
		? {
				name: space.name || '',
				altName: space.altName || '',
				type: space.type || undefined,
				status: space.status || undefined,
				floor: space.floor || '',
				plannedArea: space.plannedArea?.toString() || undefined,
				actualArea: space.actualArea?.toString() || undefined,
				typeDecoration: space.typeDecoration || undefined,
				attachments: space.attachments || empty.attachments,
				areaBTI: space.areaBTI?.toString() || undefined,
				numberBTI: (space.numberBTI as string) || undefined,
				floorBTI: (space.floorBTI as string) || undefined,
				externalId: (space.externalId as string) || undefined,
				contractForm: space.contractForm || undefined,
				markupForRegistration:
					space?.markupForRegistration !== undefined
						? space.markupForRegistration
						: undefined,
				decorationWarrantyExpiredDate: space.decorationWarrantyExpiredDate || undefined,
				constructiveWarrantyExpiredDate: space.constructiveWarrantyExpiredDate || undefined,
				technicalEquipmentWarrantyExpiredDate:
					space.technicalEquipmentWarrantyExpiredDate || undefined,
				errors: empty.errors
		  }
		: empty;
};

const isNameEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.name ? original.name !== state.name : !!state.name);

const isAltNameEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.altName ? original.altName !== state.altName : !!state.altName);

const isTypeEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.type ? original.type !== state.type : !!state.type);

const isStatusEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.status ? original.status !== state.status : !!state.status);

const isFloorEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.floor ? original.floor !== state.floor : !!state.floor);

const isPlannedAreaEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.plannedArea
		? original.plannedArea !== Number(replaceCommaToDot(state.plannedArea))
		: !!state.plannedArea);

const isActualAreaEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.actualArea
		? original.actualArea !== Number(replaceCommaToDot(state.actualArea))
		: !!state.actualArea);

const isTypeDecorationEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.typeDecoration
		? original.typeDecoration !== state.typeDecoration
		: !!state.typeDecoration);

const areAttachmentsEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.attachments ? original.attachments.length !== state.attachments.length : false);

const isAreaBTIEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.areaBTI
		? original.areaBTI !== Number(replaceCommaToDot(state.areaBTI))
		: !!state.areaBTI);

const isNumberBTIEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.numberBTI ? original.numberBTI !== state.numberBTI : !!state.numberBTI);

const isFloorBTIEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.floorBTI ? original.floorBTI !== state.floorBTI : !!state.floorBTI);
	
const isExternalIdEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.externalId ? original.externalId !== state.externalId : !!state.externalId);

const isContractFormEdited = (state: IEditableSpaceState, original?: ISpace) =>
	original?.contractForm !== state?.contractForm;

const isMarkupForRegistrationEdited = (state: IEditableSpaceState, original?: ISpace) =>
	original?.markupForRegistration !== state?.markupForRegistration;

const isDecorationWarrantyExpiredDateEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.decorationWarrantyExpiredDate
		? original.decorationWarrantyExpiredDate !== state.decorationWarrantyExpiredDate
		: !!state.decorationWarrantyExpiredDate);
const isConstructiveWarrantyExpiredDateEdited = (state: IEditableSpaceState, original?: ISpace) =>
	(original?.constructiveWarrantyExpiredDate
		? original.constructiveWarrantyExpiredDate !== state.constructiveWarrantyExpiredDate
		: !!state.constructiveWarrantyExpiredDate);
const isTechnicalEquipmentWarrantyExpiredDateEdited = (
	state: IEditableSpaceState,
	original?: ISpace
) =>
	(original?.technicalEquipmentWarrantyExpiredDate
		? original.technicalEquipmentWarrantyExpiredDate
		  !== state.technicalEquipmentWarrantyExpiredDate
		: !!state.technicalEquipmentWarrantyExpiredDate);

/**
 * Возвращает значение, показывающее были ли отредактированы поля нарушения
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableSpaceState, original?: ISpace): boolean =>
	isEntityEdited(
		state,
		original,
		isNameEdited,
		isAltNameEdited,
		isTypeEdited,
		isStatusEdited,
		isFloorEdited,
		isPlannedAreaEdited,
		isActualAreaEdited,
		isTypeDecorationEdited,
		areAttachmentsEdited,
		isAreaBTIEdited,
		isNumberBTIEdited,
		isFloorBTIEdited,
		isExternalIdEdited,
		isContractFormEdited,
		isMarkupForRegistrationEdited,
		isDecorationWarrantyExpiredDateEdited,
		isConstructiveWarrantyExpiredDateEdited,
		isTechnicalEquipmentWarrantyExpiredDateEdited
	);

const regArea = /^\d+([.,]\d+)?$/;

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	name: (state: IEditableSpaceState) => !state.name,
	altName: (state: IEditableSpaceState) => !state.altName,
	type: (state: IEditableSpaceState) => !state.type,
	status: (state: IEditableSpaceState) => !state.status,
	floor: (state: IEditableSpaceState) => !state.floor,
	plannedArea: (state: IEditableSpaceState) =>
		!!state.plannedArea && !(state.plannedArea && regArea.test(state.plannedArea)),
	actualArea: (state: IEditableSpaceState) =>
		!!state.actualArea && !(state.actualArea && regArea.test(state.actualArea)),
	typeDecoration: (state: IEditableSpaceState) => !state.typeDecoration,
	attachments: (state: IEditableSpaceState) => !state.attachments.length,
	areaBTI: (state: IEditableSpaceState) =>
		!!state.areaBTI && !(state.areaBTI && regArea.test(state.areaBTI)),
	numberBTI: (state: IEditableSpaceState) => !state.numberBTI,
	floorBTI: (state: IEditableSpaceState) => !state.floorBTI,
	externalId: (state: IEditableSpaceState) => !state.externalId,
	contractForm: (state: IEditableSpaceState) => !state.contractForm,
	markupForRegistration: (state: IEditableSpaceState) =>
		state.markupForRegistration === undefined,
	decorationWarrantyExpiredDate: (state: IEditableSpaceState) =>
		!state.decorationWarrantyExpiredDate,
	constructiveWarrantyExpiredDate: (state: IEditableSpaceState) =>
		!state.constructiveWarrantyExpiredDate,
	technicalEquipmentWarrantyExpiredDate: (state: IEditableSpaceState) =>
		!state.technicalEquipmentWarrantyExpiredDate
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableSpaceState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableSpaceState,
	original?: ISpace,
	onlyEdited?: boolean
): ISavingSpace => {
	if (!onlyEdited) {
		return {
			name: state.name,
			altName: state.altName,
			type: state.type,
			status: state.status,
			floor: state.floor,
			plannedArea: Number(replaceCommaToDot(state.plannedArea)),
			actualArea: Number(replaceCommaToDot(state.actualArea)),
			typeDecoration: state.typeDecoration,
			attachments: state.attachments,
			areaBTI: Number(replaceCommaToDot(state.areaBTI)),
			numberBTI: state.numberBTI,
			floorBTI: state.floorBTI,
			externalId: state.externalId,
			contractForm: state.contractForm,
			markupForRegistration: state.markupForRegistration,
			decorationWarrantyExpiredDate: state.decorationWarrantyExpiredDate,
			constructiveWarrantyExpiredDate: state.constructiveWarrantyExpiredDate,
			technicalEquipmentWarrantyExpiredDate: state.technicalEquipmentWarrantyExpiredDate
		};
	}
	const space: ISavingSpace = {};
	if (isNameEdited(state, original)) {
		space.name = state.name;
	}
	if (isAltNameEdited(state, original)) {
		space.altName = state.altName;
	}
	if (isTypeEdited(state, original)) {
		space.type = state.type;
	}
	if (isStatusEdited(state, original)) {
		space.status = state.status;
	}
	if (isFloorEdited(state, original)) {
		space.floor = state.floor;
	}
	if (isPlannedAreaEdited(state, original)) {
		space.plannedArea = Number(replaceCommaToDot(state.plannedArea));
	}
	if (isActualAreaEdited(state, original)) {
		space.actualArea = Number(replaceCommaToDot(state.actualArea));
	}
	if (isTypeDecorationEdited(state, original)) {
		space.typeDecoration = state.typeDecoration || null;
	}
	if (areAttachmentsEdited(state, original)) {
		space.attachments = state.attachments.map(item => ({id: item.id}));
	}
	// Дополнительная информация
	if (isAreaBTIEdited(state, original)) {
		space.areaBTI = Number(replaceCommaToDot(state.areaBTI));
	}
	if (isNumberBTIEdited(state, original)) {
		space.numberBTI = state.numberBTI;
	}
	if (isFloorBTIEdited(state, original)) {
		space.floorBTI = state.floorBTI;
	}
	if (isExternalIdEdited(state, original)) {
		space.externalId = state.externalId;
	}
	if (isContractFormEdited(state, original)) {
		space.contractForm = state.contractForm || null;
	}

	if (isMarkupForRegistrationEdited(state, original)) {
		space.markupForRegistration = state.markupForRegistration !== undefined
			? state.markupForRegistration : null;
	}
	if (isDecorationWarrantyExpiredDateEdited(state, original)) {
		space.decorationWarrantyExpiredDate
			= state.decorationWarrantyExpiredDate || null;
	}
	if (isConstructiveWarrantyExpiredDateEdited(state, original)) {
		space.constructiveWarrantyExpiredDate
			= state.constructiveWarrantyExpiredDate || null;
	}
	if (isTechnicalEquipmentWarrantyExpiredDateEdited(state, original)) {
		space.technicalEquipmentWarrantyExpiredDate
			= state.technicalEquipmentWarrantyExpiredDate || null;
	}

	return space;
};
