import {memo} from 'react';
import {useEditableWarrantyClaim} from '@src/components/EditableWarrantyClaim/hooks/useEditableWarrantyClaim';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import './EditWarrantyClaimDialog.less';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditWarrantyClaimProps {
	isOpen?: boolean;
	warrantyClaim?: IWarrantyClaim;
	onClose?: () => void;
	onChange?: (value: ISavingWarrantyClaim) => void;
	loading?: boolean;
}

export const EditWarrantyClaimDialog = memo((props: IEditWarrantyClaimProps) => {
	const {t} = useTranslation();
	const {warrantyClaim, onClose, onChange, loading = false, isOpen = false} = props;
	const [fields, getSavingData, reset, isBlocking] = useEditableWarrantyClaim(
		warrantyClaim?.links?.spaceId,
		warrantyClaim,
		loading
	);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const onClickSave = async () => {
		if (warrantyClaim) {
			const savingData = await getSavingData();
			if (savingData) {
				onChange?.(savingData);
				onClose?.();
			}
		}
	};

	const onClickCancel = async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose?.();
			reset();
		}
	};

	return (
		<Dialog
			className={{
				root: 'edit-warranty-claim-dialog',
				header: 'edit-warranty-claim-dialog__header',
				body: 'edit-warranty-claim-dialog__body'
			}}
			isOpen={isOpen}
			footer={
				<ActionButtons>
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						onClick={onClickSave}
					/>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						onClick={onClickCancel}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={onClickCancel}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
});
