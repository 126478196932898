import {useCallback, useRef, ReactNode, useState} from 'react';
import {
	ConfirmDialog,
	IConfirmDialogProps,
	IConfirmDialogRefProps
} from '../components/dialogs/ConfirmDialog';

/**
 * Хук для запроса подтверждения действия пользователя
 */

export interface IDialogData {
	title: string
	message?: string
}
const useConfirmDialog = (
	title: string,
	message?: string,
	{
		acceptBtnLabel = 'Да',
		rejectBtnLabel = 'Нет',
		...rest
	}: Omit<IConfirmDialogProps, 'title' | 'message'> = {}
): [ReactNode, () => Promise<boolean>, (data: IDialogData) => void] => {
	const ref = useRef<IConfirmDialogRefProps | null>(null);
	const [dialogData, setDialogData] = useState<IDialogData>({
		title,
		message
	});

	const dialog = (
		<ConfirmDialog
			{...rest}
			title={dialogData.title}
			message={dialogData.message}
			acceptBtnLabel={acceptBtnLabel}
			rejectBtnLabel={rejectBtnLabel}
			ref={ref}
		/>
	);

	const getConfirmation = useCallback(
		async (): Promise<boolean> => !!(ref.current && (await ref.current.open())),
		[]
	);

	const changeDialogData = useCallback((data: IDialogData) => setDialogData(data),
		[]);

	return [dialog, getConfirmation, changeDialogData];
};

export default useConfirmDialog;
