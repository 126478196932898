import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IChangeOffsetPayload} from '@src/store/interfaces/IChangeOffsetPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeSelectedRowsPayload} from '@src/store/interfaces/IChangeSelectedRowsPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import * as types from './constants';
import {ISpaceStatusesSetsFiltersState, ISpaceStatusesSetsSortState} from './reducers';

export const changeFilters = (filters: ISpaceStatusesSetsFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: {filters} as IChangeFiltersPayload<ISpaceStatusesSetsFiltersState>
});

export const clearFilters = () => ({type: types.CLEAR_FILTERS});

export const changeSort = (sort: ISpaceStatusesSetsSortState) => ({
	type: types.CHANGE_SORT,
	payload: {sort} as IChangeSortPayload<ISpaceStatusesSetsFiltersState>
});

export const changeOffset = (offset: number) => ({
	type: types.CHANGE_OFFSET,
	payload: {offset} as IChangeOffsetPayload
});

export const changePageSize = (pageSize: number) => ({
	type: types.CHANGE_PAGE_SIZE,
	payload: {pageSize} as IChangePageSizePayload
});

export const changeSelectedRows = (selectedRows: string[]) => ({
	type: types.CHANGE_SELECTED_ROWS,
	payload: {selectedRows} as IChangeSelectedRowsPayload
});