import {FixDateTag} from '@src/components/FixDateTag';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {useTranslation} from 'react-i18next';

interface IMobileActualFixDateProps {
	actualFixDate: string;
	actualFixDateOriginal: number | undefined;
	plannedFixDateOriginal: number | undefined;
}

export const MobileActualFixDate = ({
	actualFixDate,
	actualFixDateOriginal,
	plannedFixDateOriginal
}: IMobileActualFixDateProps) => {
	const {t} = useTranslation();

	return !!actualFixDate ? (
		<div className="problems-page__m-table-plannedFixDate">
			<div>
				{t('problemsPage.mobileProblemCell.actualFixDate')}: &nbsp;
				{actualFixDate}
			</div>
			{compareFixDate(actualFixDateOriginal, plannedFixDateOriginal) && (
				<FixDateTag
					plannedFixDate={plannedFixDateOriginal!}
					actualFixDate={actualFixDateOriginal}
				/>
			)}
		</div>
	) : null;
};
