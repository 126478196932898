import {memo, RefObject} from 'react';
import {
	AddingDocuments,
	DownloadableFile,
	EntityGridItem,
	FilesPicker,
	IconButton
} from '@tehzor/ui-components';
import IFile from '@tehzor/tools/interfaces/IFile';
import './FilesUploaderBlock.less';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import {addTempFile} from '@src/store/modules/entities/tempFile/actions';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import ITempFile from '@tehzor/tools/interfaces/ITempFile';

interface IAttachmentsBlockUIProps {
	onFilesPick: (files: IRawFile[]) => void;
	onAddClick: () => void;
	onUploadingFilesChange: (value: IUploadingFile[]) => void;
	onTemporaryFileAdd: (...args: Parameters<typeof addTempFile>) => Promise<ITempFile>;
	onTemporaryFileDelete: (fileId: string) => Promise<void>;
	uploadingFiles: IUploadingFile[];
	filesList: IFile[];
	pickerRef: RefObject<FilesPicker>;
	label?: string;
	networkStatus: boolean;
	isLoading: boolean;
}

const attachmentIcon = <i className="tz-attach-20" />;
const plusIcon = <i className="tz-plus-20" />;
const EntityGridItemCls = {
	root: 'files-uploader-block__entity-grid-item',
	title: 'files-uploader-block__entity-grid-title',
	icon: 'files-uploader-block__entity-grid-icon',
	content: 'files-uploader-block__entity-grid-content'
};

export const FilesUploaderBlock = memo((props: IAttachmentsBlockUIProps) => {
	const {
		label,
		pickerRef,
		filesList,
		networkStatus,
		isLoading,
		uploadingFiles,
		onFilesPick,
		onAddClick,
		onUploadingFilesChange,
		onTemporaryFileAdd,
		onTemporaryFileDelete
	} = props;

	const addBtn = (
		<IconButton
			type="inline-blue-accent"
			onClick={onAddClick}
			disabled={isLoading}
			className="files-uploader-block__add-icon"
		>
			{plusIcon}
		</IconButton>
	);

	return (
		<>
			<EntityGridItem
				label={label}
				icon={attachmentIcon}
				fullRow
				className={EntityGridItemCls}
				buttons={addBtn}
			>
				{filesList.map(item => (
					<div
						className="files-uploader-block__item"
						key={item.id}
					>
						<DownloadableFile {...item} />
					</div>
				))}
				{uploadingFiles.length ? (
					<AddingDocuments
						online={networkStatus}
						uploadingFiles={uploadingFiles}
						disabled={isLoading}
						onUploadingFilesChange={onUploadingFilesChange}
						onAddTempFile={onTemporaryFileAdd}
						onDeleteTempFile={onTemporaryFileDelete}
					/>
				) : null}
			</EntityGridItem>
			<FilesPicker
				multiple
				onChange={onFilesPick}
				ref={pickerRef}
			/>
		</>
	);
});

FilesUploaderBlock.displayName = 'filesUploaderBlock';
