import {IDndItem} from '../interfaces';

/**
 * Получает всех детей элемента по его идентификатору
 * 
 * @param id идентификатор элемента
 * @param items массив всех элементов
 */
export const getAllChildren = <S extends IDndItem>(
	id: string, items: S[]
): S[] => {
	const children: S[] = [];
	for (const item of items) {
		if (item.parentId !== id) { continue; }
		children.push(item);
		children.push(...getAllChildren(item.id, items));
	}

	return children;
};