import {httpRequests} from '@tehzor/tools/api/httpRequests';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';

export type IEditCategoryResponse = ICategory;

/**
 * Изменяет вид работ
 *
 * @param category данные вида работ
 */
export const requestEditCategory = async (category: ISavingCategory) => {
	const response = await httpRequests.withToken.post<IEditCategoryResponse>(
		`categories/edit/${category.id}`, category
	);

	return response.data;
};
