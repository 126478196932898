import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useTranslation} from 'react-i18next';

interface IMobilePerfomersProps {
	performersActiveGroup: string | undefined;
	performers: IBriefUser[] | undefined;
}

export const MobilePerfomers = ({performersActiveGroup, performers}: IMobilePerfomersProps) => {
	const {t} = useTranslation();
	return !!(performersActiveGroup || performers?.length) ? (
		<div className="problems-page__m-table-performers">
			<div className="problems-page__m-table-performers-title">
				{t('problemsPage.mobileProblemCell.performers')}:
			</div>
			<div className="problems-page__m-table-performers-text">
				{performersActiveGroup && (
					<div className="problems-page__m-table-performers-group">
						{performersActiveGroup}
					</div>
				)}
				{performers?.[0] && (
					<div className="problems-page__m-table-performers-user">
						<span className="problems-page__m-table-performers-user-name">
							{performers[0]?.displayName}
						</span>
						<span className="problems-page__m-table-performers-user-company">
							{performers[0].companies?.[0]?.name}
						</span>
						{!!performers?.length && performers?.length > 1 && (
							<span className="problems-page__m-table-performers-rest">
								+{performers.length - 1}
							</span>
						)}
					</div>
				)}
			</div>
		</div>
	) : null;
};
