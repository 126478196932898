import {httpRequests} from '@tehzor/tools/api/httpRequests';

interface IDeleteProblemResponse {
	success: boolean;
}

/**
 * Удаляет нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const makeProblemDeleteRequest = async (objectId: string, problemId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteProblemResponse>(
		`problems/${problemId}`,
		{params: {objectId}}
	);

	return response.data;
};
