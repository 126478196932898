import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {generateWeek} from "@src/store/modules/settings/pages/tasks/utils/scheduleUtils";
import {
	ChangeScheduleValueMode,
	IChangeTasksFiltersPayload, ITasksFiltersState,
	ITasksPageSettingsScheduleState, ITasksSettingsMode,
	TasksScheduleCollectMode
} from "@src/store/modules/settings/pages/tasks/interfaces";

const initialState: ITasksPageSettingsScheduleState = {
	filters: {},
	settings: ({
		currentMode: 'year',
		year: new Date().getFullYear(),
		month: new Date().getMonth(),
		week: generateWeek(),
		day: new Date().getDate()
	}),
	collectBy: TasksScheduleCollectMode.OBJECT
}
export const tasksScheduleSlice = createSlice({
	name: 'tasksSchedule',
	initialState,
	reducers: {
		changeFilters: (state, {payload}: PayloadAction<IChangeTasksFiltersPayload<ITasksFiltersState>>) => ({
			...state,
			filters: payload.filters
		}),
		clearFilters: (state) => ({
			...state,
			filters: {}
		}),
		changeScheduleMode: (state, {payload}: PayloadAction<{mode: ITasksSettingsMode}>) => ({
			...state,
			settings: {...state.settings, currentMode: payload.mode}
		}),
		changeScheduleValue: (state, {payload}: PayloadAction<{mode: ChangeScheduleValueMode}>) => {
			const {currentMode, month} = state.settings;

			if (currentMode === 'year') {
				if (payload.mode === 'increment') {
					state.settings.year += 1;
				}

				if (payload.mode === 'decrement') {
					state.settings.year -= 1;
				}
			}

			if (currentMode === 'month') {
				if (payload.mode === 'increment') {
					if (month === 11) {
						state.settings.month = 0;
						state.settings.year += 1;
					} else {
						state.settings.month += 1;
					}
				}

				if (payload.mode === 'decrement') {
					if (month === 0) {
						state.settings.month = 11;
						state.settings.year -= 1;
					} else {
						state.settings.month -= 1;
					}
				}
			}
		},
		changeCollectMode: (state, {payload}: PayloadAction<{collectMode: TasksScheduleCollectMode}>) => ({
			...state,
			collectBy: payload.collectMode
		})
	}
})

export const {actions: tasksScheduleActions, reducer: tasksScheduleReducer} = tasksScheduleSlice;