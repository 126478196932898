let count = 0;

/**
 * Формирует уникальный идентификатор
 *
 * @param {String} prefix префикс (по умолчанию "id")
 * @return {string}
 */
export default function generateKey(prefix = 'id'): string {
	return prefix + ++count;
}
