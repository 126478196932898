import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';

export interface IEditableProblemTag {
	id: string;
	name: string;
	color: string;
	problemTagsSetId: string;
	isDraft?: boolean;
}

export type IEditableProblemTagsSetState = IEditableEntityState<{
	name?: string;
	companyId?: string;
	stages?: ObjectStageIds[];
	objects?: string[];
}>;

export type IEditableProblemTagsSetAction = IEditableEntityAction<
IEditableProblemTagsSetState,
	{
		problemTagsSet?: IProblemTagsSet;
	}
>;

const makeEmptyState = (): IEditableProblemTagsSetState => ({
	name: undefined,
	companyId: undefined,
	stages: undefined,
	objects: [],
	errors: {
		name: false,
		companyId: false,
		objects: false,
		stages: false
	}
});

export const init = ({
	problemTagsSet
}: {
	problemTagsSet?: IProblemTagsSet;
}): IEditableProblemTagsSetState => {
	const empty = makeEmptyState();
	return problemTagsSet
		? {
				name: problemTagsSet.name,
				objects: problemTagsSet.objects,
				companyId: problemTagsSet.companyId,
				stages: problemTagsSet.stages,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (state: IEditableProblemTagsSetState, original?: IProblemTagsSet): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'companyId'),
		isPropEdited.bind(null, 'objects'),
		isPropEdited.bind(null, 'stages')
	);

export const errorsFns = {
	name: (state: IEditableProblemTagsSetState) => !state.name,
	companyId: (state: IEditableProblemTagsSetState) => !state.companyId,
	objects: (state: IEditableProblemTagsSetState) => !state.objects,
	stages: (state: IEditableProblemTagsSetState) => !state.stages || state.stages.length === 0
};

export const convertProblemTagsSetToSave = (
	state: IEditableProblemTagsSetState,
	original?: IProblemTagsSet,
	onlyEdited?: boolean
): ISavingProblemTagsSet => {
	if (!onlyEdited) {
		return state;
	}

	const problemTagsSet: ISavingProblemTagsSet = {};

	if (isPropEdited('name', state, original)) {
		problemTagsSet.name = state.name;
	}
	if (isPropEdited('objects', state, original)) {
		problemTagsSet.objects = state.objects;
	}
	if (isPropEdited('companyId', state, original)) {
		problemTagsSet.companyId = state.companyId;
	}
	if (isPropEdited('stages', state, original)) {
		problemTagsSet.stages = state.stages;
	}

	return problemTagsSet;
};
