import {useCallback} from 'react';
import {SwitchMenuButton} from '@tehzor/ui-components';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractStructuresPageSettings} from '@/entities/Structures/model/slice/selectors';
import {structuresActions} from '@/entities/Structures';

const options = [
	{key: StructuresDisplayMode.SCHEMA, content: <i className="tz-schema-24" />},
	{key: StructuresDisplayMode.LIST, content: <i className="tz-list-24" />}
];

interface IMobileDisplayModeSwitchProps {
	objectId: string;
}

export const DisplayModeSwitchMobile = ({objectId}: IMobileDisplayModeSwitchProps) => {
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const {changeDisplayMode} = structuresActions;

	const handleChange = useCallback(
		(value: StructuresDisplayMode) => {
			dispatch(changeDisplayMode({objectId, displayMode: value}));
		},
		[dispatch, changeDisplayMode, objectId]
	);

	return (
		<SwitchMenuButton
			className="structures-page__m-display-mode-switch"
			options={options}
			value={displayMode}
			onChange={handleChange}
		/>
	);
};
