import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {useTranslation} from 'react-i18next';

interface IMobileInspectorsProps {
	inspectors: IBriefUser[] | undefined;
	userGroup: IWorkingGroup | undefined;
}
export const MobileInspectors = ({inspectors, userGroup}: IMobileInspectorsProps) => {
	const {t} = useTranslation();
	return !!inspectors?.length ? (
		<div className="problems-page__m-table-inspectors">
			<div className="problems-page__m-table-inspectors-title">
				{t('problemsPage.mobileProblemCell.inspectors')}:
			</div>
			<div className="problems-page__m-table-inspectors-text">
				{userGroup && (
					<div className="problems-page__m-table-inspectors-group">{userGroup.name}</div>
				)}
				{inspectors?.[0] && (
					<div className="problems-page__m-table-inspectors-user">
						<span className="problems-page__m-table-inspectors-user-name">
							{inspectors[0]?.displayName}
						</span>
						<span className="problems-page__m-table-inspectors-user-company">
							{inspectors[0].companies?.[0]?.name}
						</span>
						{!!inspectors?.length && inspectors?.length > 1 && (
							<span className="problems-page__m-table-inspectors-rest">
								+{inspectors.length - 1}
							</span>
						)}
					</div>
				)}
			</div>
		</div>
	) : null;
};
