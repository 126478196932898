import { createContext, PropsWithChildren, useState } from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deletePlan} from '@src/store/modules/entities/plan/actions';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {usePlanMovingDialog} from '../hooks/usePlanMovingDialog';

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPlan>) => void>(
	() => ({})
);

export const MenuActionCtxProvider = ({children}: PropsWithChildren) => {
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить этот план?',
		'Все данные будут безвозвратно удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [plan, setPlan] = useState<IPlan>();
	const [copyDialog, setCopyDialogOpen] = usePlanMovingDialog(plan);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPlan>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await dispatch(deletePlan(action.payload.objectId, action.payload.id));
			}
			if (action.type === 'copy') {
				setPlan(action.payload);
				setCopyDialogOpen(true);
			}
		}
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
			{plan && copyDialog}
		</DispatchActionCtx.Provider>
	);
};
