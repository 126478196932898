import {IGetTaskResponse} from '@src/api/backend/task';
import {useQuery} from '@tanstack/react-query';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';

export const useTask = <T = IGetTaskResponse>(
	taskId?: string,
	select?: (data: IGetTaskResponse) => T
) =>
	useQuery({
		queryKey: [...tasksQueryKeys.details(), taskId],
		enabled: !!taskId,
		meta: {
			error: 'при загрузке задачи'
		},
		select
	});
