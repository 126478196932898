import {queryClient} from '@src/api/QueryClient';
import {
	IEditProblemPerformersResponse,
	makeProblemPerformersEditRequest
} from '@src/api/backend/problem/editPerformers';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';

export type IEditProblemPerformersPayload = IEditProblemPerformersResponse;

const request = () => ({type: types.EDIT_PERFORMERS_REQUEST});

const success = (response: IEditProblemPerformersResponse) => ({
	type: types.EDIT_PERFORMERS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении исполнителей');
	return {
		type: types.EDIT_PERFORMERS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет исполнителей нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */
export const editProblemPerformers = (
	objectId: string,
	problemId: string,
	respUsers?: string[],
	activeGroup?: string
) =>
	createApiAction<IEditProblemPerformersResponse>(
		request,
		res => {
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void queryClient.invalidateQueries({
				queryKey: [...problemsQueryKeys.detail(problemId), objectId]
			});
			return success(res);
		},
		failure,
		() => makeProblemPerformersEditRequest(objectId, problemId, respUsers, activeGroup)
	);
