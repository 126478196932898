import './CheckInfoDesktopCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import CheckInfo from '@src/components/tableCells/CheckInfo';

const CheckInfoDesktopCell = ({row}: CellProps<{data: ICheck}>) => (
	<div className="d-check-info-cell">
		<ClickPreventWrap>
			<TableRowSelect
				{...row.getToggleRowSelectedProps()}
				style={{marginTop: '12px'}}
			/>
		</ClickPreventWrap>

		<div className="d-check-info-cell__info">
			<CheckInfo data={row.original.data}/>
		</div>
	</div>
);

export default CheckInfoDesktopCell;
