import {Button, IconButton} from '@tehzor/ui-components';
import {useChecksExport} from '@src/core/hooks/export/useChecksExport';
import {useTranslation} from 'react-i18next';

interface IDesktopChecksExportProps {
	objectId?: string;
}

const exportIcon = <i className="tz-export-20" />;

const DesktopChecksExport = ({objectId}: IDesktopChecksExportProps) => {
	const {t} = useTranslation();
	const [exportDialog, openExportDialog] = useChecksExport(objectId);

	return (
		<>
			{objectId ? (
				<IconButton
					type="accent-blue"
					onClick={openExportDialog}
				>
					{exportIcon}
				</IconButton>
			) : (
				<Button
					type="accent-blue"
					label={t('checksPage.checksExport.exportButton')}
					leftIcon={exportIcon}
					onClick={openExportDialog}
				/>
			)}
			{exportDialog}
		</>
	);
};

export default DesktopChecksExport;
