import {useChangePath} from '@src/core/hooks/useChangePath';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import * as React from 'react';
import {useCallback} from 'react';
import {useDeleteInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useDeleteInternalAcceptance';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export function useInternalAcceptanceDeleteConfirmDialog(
	objectId: string,
	internalAcceptance: IInternalAcceptance
): [React.ReactNode, () => void] {
	const {goBack} = useChangePath();
	const {t} = useTranslation();

	const {mutate: deleteInternalAcceptance} = useDeleteInternalAcceptance();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.internalAcceptances.delete'),
		message: t('useConfirmDialog.internalAcceptances.deleteTitleWithId', {
			id: internalAcceptance.number
		})
	});

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			deleteInternalAcceptance({objectId, internalAcceptanceId: internalAcceptance.id});
			goBack();
		}
	}, [getDeleteConfirmation, deleteInternalAcceptance, objectId, internalAcceptance.id, goBack]);

	return [deleteDialog, handleDelete];
}
