import { useCallback } from 'react';
import * as React from 'react';
import './MobileHeaderMenu.less';
import DraggableMenu from '../../navigation/DraggableMenu';
import classNames from 'classnames';
import MobileHeaderMenuItem from './components/MobileHeaderMenuItem';

export interface IMobileHeaderMenuItem {
	url: string;
	label: string;
	serveSubPath?: boolean;
}

interface IMobileHeaderMenuProps {
	className?: string;
	style?: React.CSSProperties;
	items?: IMobileHeaderMenuItem[];
	value?: string;

	onChange?(value: string): void;
}

const MobileHeaderMenu = (props: IMobileHeaderMenuProps) => {
	const {className, style, items, value, onChange} = props;

	const activeIndex = items?.findIndex(item =>
		(item.serveSubPath ? value?.startsWith(item.url) : item.url === value));

	const handleChange = useCallback(
		(index: number) => {
			if (items && onChange && index >= 0 && index < items.length) {
				onChange(items[index].url);
			}
		},
		[items, onChange]
	);

	return (
		<DraggableMenu
			className={classNames('m-header-menu', className)}
			style={style}
			itemClassName="m-header-menu__item"
			value={activeIndex}
			items={items?.map(item => (
				<MobileHeaderMenuItem
					key={item.url}
					label={item.label}
				/>
			))}
			disableFakeItem
			onSelect={handleChange}
		/>
	);
};

export default MobileHeaderMenu;
