import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ISavingCheckRecord} from '@tehzor/tools/interfaces/checkRecords/ISavingCheckRecord';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddCheckRecordResponse = ICheckRecord;

/**
 * Создает запись по категории
 *
 * @param objectId id объекта
 * @param links связанные сущности
 * @param stage стадия
 * @param fields данные для добавления
 */
export const makeCheckRecordAddRequest = async (
	objectId: string,
	links: ICheckRecord['links'],
	stage: ObjectStageIds,
	fields: ISavingCheckRecord
) => {
	const res = await httpRequests.withToken.post<IAddCheckRecordResponse>('check-records/add', {
		objectId,
		links,
		stage,
		...fields
	});
	return res.data;
};
