import {ISavingLegalEntity} from '@tehzor/tools/interfaces/legals-entity/ISavingLegalEntity';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export type IAddLegalResponse = ILegalEntity;

/**
 * Добавляет юр лицо
 */
export const makeLegalEntityAddRequest = async (fields: ISavingLegalEntity) => {
	const response = await httpRequests.withToken.post<IAddLegalResponse>('legals-entity', fields);
	return response.data;
};
