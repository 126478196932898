import {CellProps} from 'react-table';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';

export const FixDateCell = ({row}: CellProps<IPreparedWorkAcceptance>) => (
	<div className="work-acceptances-page__d-table-plannedFixDate">
		{row.original.acceptanceDate}

		{row.original.acceptanceDateOriginal && (
			<FixDateTag
				className="fix-date-view-field__tag"
				plannedFixDate={row.original.acceptanceDateOriginal}
			/>
		)}
	</div>
);
