import {wsConnector} from '../wsConnector';

/**
 * Удаляет ответ
 *
 * @param objectId id объекта
 * @param commentId id комментария
 */
export const makeReplyDeleteRequest = (objectId: string, commentId: string) =>
	wsConnector.sendAuthorizedRequest<void>('deleteReply', {
		objectId,
		commentId
	});
