import {findPermission} from '@tehzor/tools/utils/findPermission';
import {IUserRole} from '@tehzor/tools/interfaces/IUser';

export const getPermissions = (
	roles: IUserRole[] = []
) => {
	const canDelete = !!findPermission(
		roles, 'legals-delete'
	);

	return {canDelete};
};