import './PieChartLabel.less';
import classNames from 'classnames';
import {isBrightColor} from '../../../utils/isBrightColor';

const RADIAN = Math.PI / 180;

interface IPieChartLabelProps {
	cx: number;
	cy: number;
	innerRadius: number;
	outerRadius: number;
	midAngle: number;
	percent: number;
	id?: string;
	value?: string;
	fill?: string;
}

const PieChartLabel = (props: IPieChartLabelProps) => {
	const {cx, cy, midAngle, innerRadius, outerRadius, percent, id, value = 0, fill = '#000'} = props;
	if (id === 'total') {
		return null;
	}

	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	// Условно на каждый символ отводится по 3%, если процент меньше, то не показывается
	if (value.toString().length * 0.03 > percent) {
		return null;
	}

	const fontSize = outerRadius - innerRadius > 21 ? '14px' : '11px';

	return (
		<text
			x={x}
			y={y}
			textAnchor="middle"
			dominantBaseline="central"
			className={classNames('pie-chart-label', {'pie-chart-label_dark': isBrightColor(fill)})}
			fontSize={fontSize}
		>
			{value}
		</text>
	);
};

export default PieChartLabel;
