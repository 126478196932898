import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IExportTemplatesDestination} from '@tehzor/tools/interfaces/IExportTemplate';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetExportTemplatesDestinationsResponse = INormalizedData<IExportTemplatesDestination>;

/**
 * Возвращает список типов шаблонов экспорта
 */
export const requestExportTemplatesDestinations = async () => {
	const response = await httpRequests.withToken.get<IGetExportTemplatesDestinationsResponse>(
		'export-templates-destinations'
	);
	return response.data;
};
