import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

interface IPlansFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;
	label?: string;

	onChange: (changes: IChanges) => void;
}

export const PlansFilterMobile = memo(
	({objectId, value, entity, label, onChange}: IPlansFilterMobileProps) => {
		const {t} = useTranslation();

		const [selected, setSelected] = useState(value);
		const [search, setSearch] = useState('');

		const {data: plans} = usePlansAsArray(objectId);
		const filteredData = useMemo(
			() => (plans ? flatFilter(plans, 'name', search) : []),
			[plans, search]
		);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, 'plans');

		const handleChange = useCallback((v: string[] | undefined) => {
			onChange({plans: v});
		}, []);

		return (
			<MobileFilter
				label={label}
				elements={filteredData}
				linkBtnLabel={t('problemsFiltersPage.allBtn.label')}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
			/>
		);
	}
);
