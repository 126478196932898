import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {findPermission, hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IPermissions {
	canAdd?: boolean;
	canEdit?: boolean;
	canEditStatus?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canMove?: boolean;
	canProblemsAdd?: boolean;
}

/**
 * Возвращает полномочия для гарантийных обращений
 *
 * @param objectId id объекта
 * @param targetComponent таргет
 */
export const useWarrantyClaimsPermissions = (
	objectId: string,
	targetComponent?: 'problems' | 'spaces' | 'space' | 'check-list'
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		const addPermission = findPermission(
			user.roles,
			'warranty-claims-add',
			UserRoleScopes.OBJECT,
			objectId
		);
		return {
			canAdd:
				addPermission !== undefined
				&& !(
					targetComponent === 'problems'
					&& addPermission.restrictions
					&& addPermission.restrictions['warranty-claims-add-from-problems']
				)
				&& !(
					targetComponent === 'space'
					&& addPermission.restrictions
					&& addPermission.restrictions['warranty-claims-add-from-space']
				)
				&& !(
					targetComponent === 'check-list'
					&& addPermission.restrictions
					&& addPermission.restrictions['warranty-claims-add-from-check-list']
				),
			canEdit: hasPermission(
				user.roles,
				'warranty-claims-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canEditStatus: hasPermission(
				user.roles,
				'warranty-claims-status-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canDelete: hasPermission(
				user.roles,
				'warranty-claims-delete',
				UserRoleScopes.OBJECT,
				objectId
			),
			canExport: hasPermission(
				user.roles,
				'warranty-claims-export',
				UserRoleScopes.OBJECT,
				objectId
			),
			canMove: hasPermission(
				user.roles,
				'warranty-claims-moving',
				UserRoleScopes.OBJECT,
				objectId
			),
			canProblemsAdd: hasPermission(
				user.roles,
				'problems-add',
				UserRoleScopes.OBJECT,
				objectId
			)
		};
	}, [objectId, user]);
};
