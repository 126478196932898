import {combineReducers} from 'redux';
import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '../../constants/uiConstants';

const isBusy = createReducer(false, {
	[types.TOGGLE_PLAN_BUSY]: (state, payload) => (
		payload !== undefined ? payload : !state
	)
});

export default combineReducers({
	isBusy
});