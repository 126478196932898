import {CellProps} from 'react-table';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {IEnrichedTaskForScheduler} from "@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler";

export const ResponsibleUsers = ({row}: Pick<CellProps<IEnrichedTaskForScheduler>, 'row'>) => {
	const task = row.original;

	const {data: usersMap} = useUsersAsMap();

	const getLeaderWorkGroupName = (workGroup?: IWorkingGroup): string | undefined => {
		if (!workGroup || !usersMap) return undefined;
		const leaderId = workGroup?.leader;
		return usersMap[leaderId]?.displayName;
	};

	return (
		<span>
			{task?.respUsers && task?.respUsers?.length > 0 ? (
				task?.respUsers?.map(user => (
					<div
						className="tasks-schedule-popup__list-content_user"
						key={user.id}
					>
						{user.displayName}
					</div>
				))
			) : task?.activeGroup ? (
				<div className="tasks-schedule-popup__list-content_user">
					{getLeaderWorkGroupName(task.activeGroup)}
				</div>
			) : (
				<div className="tasks-schedule-popup__list-content_nouser">
					Ответственные не назначены
				</div>
			)}
		</span>
	);
};
