import {createMutation} from 'react-query-kit';
import {checkRecordsQueryKeys} from '../keys';
import {IChangeCheckRecordStatusMutationParams} from '../defaults/useCheckRecordsMutationDefaults';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';

export const useCheckRecordStatusChangeListMutation = createMutation<
	ICheckRecord[],
	IChangeCheckRecordStatusMutationParams
>({
	mutationKey: checkRecordsQueryKeys.changeList()
});

export const useCheckRecordStatusChangeItemMutation = createMutation<
	ICheckRecord[],
	IChangeCheckRecordStatusMutationParams
>({
	mutationKey: checkRecordsQueryKeys.changeItem()
});
