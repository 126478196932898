import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import arrayToTree, {Tree} from 'array-to-tree';
// проверяет является ли массив одним объектом но с большой вложенностью
function isOneObject(tree: Array<arrayToTree.Tree<IObject>>) {
	let flag = false;

	function isOneChild(children?: Array<Tree<IObject>>) {
		if (children) {
			if (children.length > 1) {
				flag = false;
			} else if (children.length === 1) {
				isOneChild(children[0].children);
			}
		}
	}

	if (tree.length === 1) {
		flag = true;
		isOneChild(tree[0].children);
	}

	return flag;
}
export default isOneObject;
