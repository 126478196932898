import React, {createContext, PureComponent} from 'react';
import {Action, BrowserHistory, Listener, Location} from 'history';
import {getScrollContainer} from '../../utils/getScrollContainer';

interface IScrollRestorationProps {
	children?: React.ReactNode;
	history: BrowserHistory;
	scrollContainerId?: string;
}

export const ScrollRestorationCtx = createContext<{
	addPosition(key: string): void;
	scrollTo(position?: number): void;
}>({
	addPosition: () => null,
	scrollTo: () => null
});

const elementStyle = {display: 'none'};

/**
 * Компонент для сохранения и восстановления позиции скролла при переходе между страницами
 */
class ScrollRestoration extends PureComponent<IScrollRestorationProps> {
	private _scrollContainer?: Window | HTMLElement;

	private _stopListenHistory?: Listener;

	constructor(props: IScrollRestorationProps) {
		super(props);

		if ('scrollRestoration' in window.history) {
			window.history.scrollRestoration = 'manual';
		}
	}

	componentDidMount() {
		const {scrollContainerId, history} = this.props;
		if (scrollContainerId) {
			this._scrollContainer = getScrollContainer(scrollContainerId);
		}
		this._stopListenHistory = history.listen(() => this._handleLocationChange);
	}

	componentWillUnmount() {
		if (this._stopListenHistory) {
			return this._stopListenHistory;
		}
		return undefined;
	}

	render() {
		return (
			<ScrollRestorationCtx.Provider value={this._ctxValue}>
				{this.props.scrollContainerId === undefined && (
					<div
						style={elementStyle}
						ref={this._saveRef}
					/>
)}
				{this.props.children}
			</ScrollRestorationCtx.Provider>
		);
	}

	private _addPosition = (key: string) => {
		if (this._scrollContainer) {
			sessionStorage.setItem(key, this._getCurrentPosition().toString());
		}
	};

	private _scrollTo = (position = 0) => {
		if (this._scrollContainer) {
			this._scrollContainer.scrollTo(0, position);
		}
	};

	private _handleLocationChange = (location: Location, action: Action) => {
		if (action === 'POP' && location.key && this._scrollContainer) {
			const pos = sessionStorage.getItem(location.key);
			if (pos !== null) {
				this._scrollContainer.scrollTo(0, +pos);
			}
		}
	};

	private _getCurrentPosition = () => {
		if (this._scrollContainer) {
			return this._scrollContainer === window
				? this._scrollContainer.pageYOffset
				: (this._scrollContainer as HTMLElement).scrollTop;
		}
		return 0;
	};

	private _saveRef = (element: HTMLDivElement) => {
		this._scrollContainer = getScrollContainer(element);
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	private _ctxValue = {
		addPosition: this._addPosition,
		scrollTo: this._scrollTo
	};
}

export default ScrollRestoration;
