import {Plate} from '@tehzor/ui-components';
import {MobileInfo as Info} from './info/Info.mobile';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {PageBreadcrumbs} from './PageBreadcrumbs';

interface IMobileProps {
	object: IObject;
	stages: IObjectStage[];
}

export const Mobile = ({
	object, stages
}: IMobileProps) => (
	<div className="page-cont object-page">
		<PageBreadcrumbs objectId={object.id}/>
		<div className="object-page__d-columns">
			<Plate className="object-page__d-column">
				<Info
					object={object}
					stages={stages}
				/>
			</Plate>
		</div>
	</div>
);