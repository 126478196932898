import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IEditSpaceIndicatorsResponse} from '@src/api/backend/space/editIndicators';

export type IEditSpaceIndicatorsPayload = IEditSpaceIndicatorsResponse;

const request = () => ({type: types.EDIT_INDICATORS_REQUEST});

const success = (response: IEditSpaceIndicatorsResponse, pageObjectId: string) => ({
	type: types.EDIT_INDICATORS_SUCCESS,
	payload: {...response, pageObjectId}
});
const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении индикаторов помещения');
	return {
		type: types.EDIT_INDICATORS_FAILURE,
		payload: error
	};
};

export const editSpaceIndicatorsActions = {request, failure, success};
