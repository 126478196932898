import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';

export type IEditCheckItemResponse = ICheckItem;

/**
 * Редактирование категории
 *
 * @param item данные для добавления
 */
export const makeCheckItemEditRequest = async (item: ISavingCheckItem) => {
	const {checkListId, id, name, description, dependencies} = item;
	const response = await httpRequests.withToken.put<IEditCheckItemResponse>('/check-items/edit', {
		checkListId,
		id,
		name,
		description,
		dependencies
	});
	return response.data;
};
