import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '@src/constants/notifications/types';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	getLoadedEntitiesSuccess,
	ILoadedEntityState,
	getLoadedEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';

type INotificationsTypesState = ILoadedEntityState<INotificationType>;

const nTypes = createReducer<INotificationsTypesState>(getLoadedEntitiesInitialState(), {
	[types.GET_NOTIFICATION_TYPES_SUCCESS]: getLoadedEntitiesSuccess<INotificationType>(),
	[CLEAR_STORE]: getLoadedEntitiesInitialState
});

export {INotificationsTypesState};
export default nTypes;
