import {useCallback, useMemo} from 'react';
import {
	EntitiesTable,
	LoadingPanel,
	Pagination,
	PaginationAndSize,
	Plate
} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {getColumns} from './columns';
import {MenuActionCtxProvider} from '../../../DispatchActionCtx';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useTasks} from '@src/core/hooks/queries/tasks/hooks';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {columnsMobile} from '@src/pages/TasksPage/components/tabs/TasksTablePage/components/mobile/columnsMobile';
import SelectionRow from '@src/pages/TasksPage/components/selection/SelectionRow';
import MobileSelectionClearing from '@src/pages/ProblemsPage/components/selection/SelectionClearing.mobile';
import MobileRightButtons from '@src/pages/TasksPage/components/MobileRightsButtons';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useTranslation} from 'react-i18next';
import {useEnrichedTasks} from '@src/core/hooks/queries/tasks/enrichedTasks/hooks';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import useAppSelector from "@src/core/hooks/useAppSelector";
import {extractTasksListSettings} from "@src/store/modules/settings/pages/tasks/selectors";
import {tasksListActions} from "@src/store/modules/settings/pages/tasks/slices/list";

const pageSizes = [10, 20, 50, 100];

export const TasksTable = () => {
	const {t} = useTranslation();
	const {changePageSize, changeOffset, changeSort, changeSelectedRows} = tasksListActions;
	const dispatch = useAppDispatch();
	const columns = getColumns(t);
	const {pushPath} = useChangePath();
	const isDesktop = useIsDesktop();
	const {data: tasksData} = useTasks();
	const pageSettings = useAppSelector(extractTasksListSettings);
	const {data: enrichedTasks, isFetching} = useEnrichedTasks();

	const pagesCount = Math.ceil((tasksData?.total ?? 0) / pageSettings.pageSize);
	const currentPage = Math.floor((tasksData?.offset ?? 0) / pageSettings.pageSize);

	const handleRowClick = useCallback((item: IEnrichedTask) => {
		pushPath(`/tasks/${item.id}`);
	}, [pushPath]);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows({selectedRows: value})),
		[dispatch, changeSelectedRows]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize({pageSize: value}));
			const offset = Math.floor((tasksData?.offset ?? 0) / value)
			dispatch(changeOffset({offset}));
		},
		[dispatch, tasksData?.offset, changePageSize, changeOffset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (tasksData?.offset !== offset) {
				dispatch(changeOffset({offset}));
			}
		},
		[dispatch, tasksData?.offset, pageSettings.pageSize, changeOffset]
	);

	const handleSortChange = useCallback((value: {[key: string]: boolean}) => {
		dispatch(changeSort({sort: value}));
	}, [dispatch, changeSort]);

	const selectedEntities = useMemo(
		() => enrichedTasks ? enrichedTasks.filter(item => pageSettings.selectedRows?.includes(item.id)) : [],
		[pageSettings.selectedRows, enrichedTasks]
	);

	useAppHeader(
		{
			title: t('tasksTablePage.title'),
			mobileLeftButton:
				selectedEntities.length > 0 ? (
					<MobileSelectionClearing
						onSelectedRowsChange={() => handleSelectedRowsChange([])}
					/>
				) : undefined,
			mobileRightButtons: !isDesktop ? (
				<MenuActionCtxProvider>
					<MobileRightButtons
						selectedEntities={selectedEntities}
						selectedClearing={() => {
							handleSelectedRowsChange([]);
						}}
					/>
				</MenuActionCtxProvider>
			) : null
		},
		[selectedEntities, pageSettings.selectedRows, isDesktop]
	);

	return (
		<>
			<MenuActionCtxProvider>
				<Plate withoutPadding>
					<LoadingPanel active={isFetching}>
						<EntitiesTable
							columns={isDesktop ? columns : columnsMobile}
							data={enrichedTasks || []}
							selectedRows={pageSettings.selectedRows}
							sort={pageSettings.sort}
							selectable
							onRowClick={handleRowClick}
							onSelectedRowsChange={handleSelectedRowsChange}
							onSortChange={handleSortChange}
							hideLastHeaderCell={isDesktop}
							headVisible={isDesktop}
							renderSelectionRow={props => <SelectionRow {...props} />}
						/>
					</LoadingPanel>
				</Plate>
			</MenuActionCtxProvider>
			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
};
