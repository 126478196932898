import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export const toSpaceOwner = (
	fields: ISavingSpaceOwner,
	companyId: string,
	key: string
): ISpaceOwner => {
	const newSpaceOwner: ISpaceOwner = {
		...fields,
		email: fields.email || undefined,
		phone: fields.phone || undefined,
		additionalPhone: fields.additionalPhone || undefined,
		additionalEmail: fields.additionalEmail || undefined,
		comment: fields.comment || undefined,
		name: fields.name || '',
		companyId,
		id: key,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Date.now()
	};

	return newSpaceOwner;
};