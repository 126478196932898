import * as React from 'react';
import './AcceptanceDateDisplayValue.less';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {FixDateIcon} from './components/FixDateIcon';
import classNames from 'classnames';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';

interface IAcceptanceDateDisplayValueProps {
	className?: string;
	style?: React.CSSProperties;
	value: number;
	status: WorkAcceptanceStatusIds;
}

export const AcceptanceDateDisplayValue = ({
	className,
	style,
	value,
	status
}: IAcceptanceDateDisplayValueProps) => {
	const formatted = format(value, dateTimeFormat, {locale: ru});

	return (
		<span
			className={classNames('acceptance-date-display-value', className)}
			style={style}
		>
			<span className="acceptance-date-display-value__text">{formatted}</span>

			{status !== WorkAcceptanceStatusIds.ACCEPTED && (
				<>
					{'  '}
					<FixDateIcon value={value}/>
				</>
			)}
		</span>
	);
};
