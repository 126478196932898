import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import {useCheckPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import MobileCheckExport from './CheckExport.mobile';
import MobileMenu from './Menu.mobile';
import {useCheckProblemsArray} from '@src/core/hooks/queries/check/problems/hooks';

interface IMobileActionsProps {
	objectId: string;
	check: ILinkedCheck;
}

const ActionsMobile = ({objectId, check}: IMobileActionsProps) => {
	const {data: problems} = useCheckProblemsArray(objectId, check.id);
	const permissions = useCheckPermissions(objectId, check, problems);

	return (
		<>
			{permissions.canExport && (
				<MobileCheckExport
					objectId={objectId}
					checkId={check.id}
				/>
			)}

			<MobileMenu
				objectId={objectId}
				check={check}
				permissions={permissions}
			/>
		</>
	);
};

export default ActionsMobile;
