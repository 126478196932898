import CitiesFilterPage from '@src/pages/filters/CitiesFilterPage';
import ObjectsFiltersPage from '@src/pages/filters/ObjectsFiltersPage/ObjectsFiltersPage';
import StagesFilterPage from '@src/pages/filters/StagesFilterPage';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import MainCompaniesFilterPage from '@src/pages/filters/MainCompaniesFilterPage';

export const objectsFilterRoutes = [
	{
		path: 'stages',
		element: <PrivateRoute element={<StagesFilterPage/>}/>
	},
	{
		path: 'companies',
		element: <PrivateRoute element={<MainCompaniesFilterPage/>}/>
	},
	{
		path: 'cities',
		element: <PrivateRoute element={<CitiesFilterPage/>}/>
	},
	{
		path: '',
		element: <PrivateRoute element={<ObjectsFiltersPage/>}/>
	}
];
