export interface IBuiltinField {
	id: string;
	key: string;
	name: string;
	entityTypeId: string;
}

export enum BuiltinProblemField {
	CATEGORY_ID = 'categoryId',
	PLANNED_FIX_DATE = 'plannedFixDate',
	REASON = 'reason',
	PLAN_ID = 'planId',
	FLOOR = 'floor',
	LOCATION = 'location',
	PERFORMERS = 'performers',
	INSPECTORS = 'inspectors',
	PRESCRIPTION = 'prescription',
	PROBLEM_TAGS = 'problemTags',
	DESCRIPTION = 'description',
	ATTACHMENTS = 'attachments',
	CRITICAL = 'critical',
	CONTRACT_ID = 'contractId'
}

export enum BuiltinInspectionField {
	PLAN_ID = 'planId',
	FLOOR = 'floor',
	LOCATION = 'location',
	DESCRIPTION = 'description',
	ATTACHMENTS = 'attachments'
}

export enum BuiltinTaskField {
	PLAN_ID = 'planId',
	FLOOR = 'floor',
	LOCATION = 'location',
	RESP_USERS = 'respUsers',
	DESCRIPTION = 'description',
	ATTACHMENTS = 'attachments',
	TASK_INTERVAL_START = 'taskIntervalStart'
}

export enum BuiltinProblemReplyField {
	DESCRIPTION = 'description',
	ATTACHMENTS = 'attachments'
}
