import { memo, useMemo } from 'react';
import {Tree} from '@tehzor/ui-components';
import {makeObjectsTreeData} from '../utils/makeObjectsTreeData';
import {useFiltersDialogDispatch} from '../utils/FiltersDialogDispatchCtx';
import Controls from './Controls';
import {useObjectsAsArray} from '@src/core/hooks/queries/objects/hooks';
import {useMainAndSubCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';

interface IObjectsTabProps {
	checked: string[];
	expanded?: string[];
}

const ObjectsTab = ({checked, expanded}: IObjectsTabProps) => {
	const {data} = useMainAndSubCompaniesAsArray();
	const companies = data?.mainCompanies;
	const objects = useObjectsAsArray();

	const treeData = useMemo(() => {
		if (!objects) return [];
		return makeObjectsTreeData(objects, companies);
	}, [companies, objects]);

	const dispatch = useFiltersDialogDispatch();

	const handleCheck = (value: string[]) => {
		dispatch({type: 'update', field: 'checkedObjects', value});
	};

	const handleExpand = (value: string[]) => {
		dispatch({type: 'update', field: 'expandedObjects', value});
	};

	return (
		<>
			<Controls checkedField="checkedObjects" />

			<div className="stats-filters-dialog__tree-wrap">
				<Tree
					className="stats-filters-dialog__tree"
					isCheckable
					checkedValue={checked}
					expandedValue={expanded}
					isAllCheckedExpanded
					multiple
					data={treeData}
					onCheck={handleCheck}
					onExpand={handleExpand}
				/>
			</div>
		</>
	);
};

export default memo(ObjectsTab);
