import EntityAdding from './EntityAdding';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';

interface IDesktopMainActionsProps {
	objectId: string;
	ownerAcceptance: IOwnerAcceptance;
}

export const DesktopMainActions = ({objectId, ownerAcceptance}: IDesktopMainActionsProps) => {
	const permissions = useOwnerAcceptancesPermissions(objectId);

	return (
		<>
			{permissions.canProblemsAdd && (
				<EntityAdding
					objectId={objectId}
					ownerAcceptance={ownerAcceptance}
				/>
			)}
		</>
	);
};