import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetStructuresResponse extends INormalizedData<IListStructure> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список структур
 */
export const getStructuresPaginateRequest = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const response = await httpRequests.withToken.post<IGetStructuresResponse>(
		'structures/get-paginate',
		{
			filters,
			sort: convertSortParam(sort),
			offset,
			limit
		}
	);

	return response.data;
};
