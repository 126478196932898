import {createContext} from 'react';
import {IDndDragState} from '../../interfaces/IDndDragState';
import {IDndCtx, IDragHandlers} from '../interfaces/IDndCtx';

export const defaultState: IDndDragState<unknown> = {
	isDragging: false,
	containers: {}
};

export const defaultHandlers: IDragHandlers = {
	dragStartHandlers: {},
	dragMoveHandlers: {},
	dragPreviewMoveHandlers: {},
	dragEndHandlers: {},
	dragCancelHandlers: {}
};

export const DndCtx = createContext<IDndCtx<unknown>>({
	dragState: defaultState,
	changeDragState: () => null,
	resetDragState: () => null,
	changeInitPosition: () => null,
	handlers: defaultHandlers,
	addHandler: () => null,
	changeDragContainers: () => null
});