import {useMemo} from 'react';
import {useStructuresBreadcrumbs} from './useStructuresBreadcrumbs';
import {formStructureLink} from '@tehzor/tools/utils/links';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractStructure} from '@src/store/modules/entities/structure/selectors';

/**
 * Хук для хлебных крошек от "всех объектов" до структуры
 *
 * @param objectId id конечного объекта
 * @param structureId id структуры
 */
export function useStructureBreadcrumbs(objectId: string, structureId: string) {
	const res = useStructuresBreadcrumbs(objectId);
	const structure = useAppSelector(extractStructure);

	return useMemo(
		() =>
			res.concat({
				label: structure ? structure.name : '',
				url: formStructureLink(objectId, structureId),
				inactive: false
			}),
		[objectId, res]
	);
}
