import {Plate} from '@tehzor/ui-components';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {Content} from './Content';

export const Desktop = () => (
	<>
		<PageBreadcrumbs/>

		<Plate className="categories-migration-page__columns">
			<Content/>
		</Plate>
	</>
	);