import {ISpaceLocation} from '@tehzor/tools/interfaces/spaces/ISpaceLocation';

export const getPlansIds = (locations?: ISpaceLocation[]) => {
	if (locations) {
		return locations.reduce<string[]>((acc, current) => {
			if (current.planId) acc.push(current.planId);
			return acc;
		}, []);
	}
	return [];
};
