import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {SelectedEntitiesDeletionFieldsSettingsSets} from './ui/SelectedEntitiesDeletionFieldsSettingsSets';

interface ISelectionActionsFieldsSettingsSetsProps {
	selectedEntities: IPreparedFieldSettingsSet[];
	selectedClearing: () => void;
}

export const SelectionActionsFieldsSettingsSets = ({
	selectedEntities,
	selectedClearing
}: ISelectionActionsFieldsSettingsSetsProps) => {
	const canDelete = selectedEntities.every(item => item.canDelete);
	return canDelete ? (
		<SelectedEntitiesDeletionFieldsSettingsSets
			selectedEntities={selectedEntities}
			selectedClearing={selectedClearing}
		/>
	) : null;
};
