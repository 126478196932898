import {queryClient} from '@src/api/QueryClient';
import {IGetProblemTagsResponse, makeProblemTagAddRequest} from '@src/api/backend/problemTags';
import {problemTagsQueryKeys} from '@src/api/cache/problemTags/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';

export const useAddProblemTag = () =>
	useMutation<IProblemTag, IError, ISavingProblemTag>({
		mutationFn: (params: ISavingProblemTag) => makeProblemTagAddRequest(params),
		mutationKey: problemTagsQueryKeys.add(),
		onSuccess: data => {
			queryClient.setQueryData<IGetProblemTagsResponse>(
				problemTagsQueryKeys.list(),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return oldData;
				}
			);
			addSuccessToast('Успех', 'Метка нарушений добавлена');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении метки нарушений');
		}
	});
