import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт чеклиста
 */
export const makeCheckListExportRequest = async (
	templateId: string,
	objectId: string,
	spaceId: string,
	checkListId: string,
	problems?: string[],
	createDocument?: boolean,
	email?: string
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		objectId,
		spaceId,
		problems,
		checkListId,
		...(email ? {email: getEmailsArray(email)} : {})
	};

	const response = await httpRequests.exportWithToken.post<IExportResponse>(
		`check-lists/${checkListId}/export`,
		params
	);
	return response.data;
};
