import {useCallback} from 'react';
import IUploadingFile from '../../interfaces/IUploadingFile';

/**
 * Хук. Возвращает функцию прерывания загрузки файла
 * @param {IUploadingFile[]} uploadingFiles
 */
export const useAbortFile = (uploadingFiles: IUploadingFile[]) =>
	useCallback(() => {
		for (const file of uploadingFiles) {
			if (file.abort) {
				file.abort();
			}
		}
	}, [uploadingFiles]);
