import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';

/**
 * Проверяет данные на актуальность
 *
 * @param getData функция, принимающая состояние и возвращающая данные, содержащие expires
 * @param action действие, выполняющееся если данные неактуальны
 */
export default function checkExpiration<S, R, A extends Action>(
	getData: (state: S) => { expires?: number },
	action: A
): ThunkAction<Promise<R>, S, never, A> {
	return (dispatch, getState): Promise<R> => {
		const state = getState();
		const data = getData(state);
		const expires = typeof data === 'object' ? data.expires : undefined;
		const expired = !expires || Date.now() >= expires;
		return (expired ? dispatch(action) : Promise.resolve(data)) as Promise<R>;
	};
}
