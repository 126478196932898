/**
 * Функция для склонения русских слов
 * Пример использования: declination(5,['комментарий', 'комментария', 'комментариев'])
 *
 * @param {Number} num число, для которого будет расчитано окончание
 * @param {Array} words варианты слов (1 комментарий, 2 комментария, 100 комментариев)
 * @return {String} слово с правильным окончанием
 */
export default function declination(num: number, words: string[]): string {
	const n = Math.abs(num);
	return n % 10 === 1 && n % 100 !== 11
		? words[0]
		: n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
		? words[1]
		: words[2];
}
