import {queryClient} from '@src/api/QueryClient';
import {
	IEditCategoriesOrderResponse,
	IGetCategoriesResponse,
	requestEditOrderCategories
} from '@src/api/backend/categories';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ICategoryOrder} from '@tehzor/tools/interfaces/categories/ICategoryOrder';

export interface IEditCategoriesOrderParams {
	categoriesSetId: string;
	order: ICategoryOrder[];
}
export const useEditOrderCategories = () =>
	useMutation<IEditCategoriesOrderResponse, IError, IEditCategoriesOrderParams>({
		mutationFn: (params: IEditCategoriesOrderParams) =>
			requestEditOrderCategories(params.categoriesSetId, params.order),

		onSuccess: data => {
			queryClient.setQueryData<IGetCategoriesResponse>(
				categoriesQueryKeys.list(),
				oldData => {
					if (oldData) {
						data.allIds.forEach(categoryId => {
							oldData[categoryId] = data.byId[categoryId];
						});
					}
					return data;
				}
			);
			addSuccessToast('Успех', 'Порядок видов работ изменен');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при редактировании порядка видов работ');
		}
	});
