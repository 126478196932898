import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery} from '@tanstack/react-query';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {extractWarrantyClaimsAllIds, extractWarrantyClaimsAsArray} from './selectors';

type IGetSpaceWarrantyClaimsResponse = INormalizedData<IListWarrantyClaim>;

export const useSpaceWarrantyClaims = <T = IGetSpaceWarrantyClaimsResponse>(
	objectId: string,
	spaceId: string,
	permission = true,
	select?: (data: IGetSpaceWarrantyClaimsResponse) => T
) =>
	useQuery({
		queryKey: spacesQueryKeys.spaceWarrantyClaims(objectId, spaceId),
		meta: {
			error: 'при загрузке гарантийных обращений помещения'
		},
		select,
		enabled: permission
	});

export const useSpaceWarrantyClaimsAsArray = (
	objectId: string,
	spaceId: string,
	permission = true
) => useSpaceWarrantyClaims(objectId, spaceId, permission, extractWarrantyClaimsAsArray);

export const useExtractSpaceWarrantyClaimsAllIds = (
	objectId: string,
	spaceId: string,
	permission = true
) => useSpaceWarrantyClaims(objectId, spaceId, permission, extractWarrantyClaimsAllIds);
