import {IPreparedClaim} from '@src/pages/WarrantyClaimsPage/interfaces/IPreparedClaim';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {IconButton} from '@tehzor/ui-components';
import {useDeleteWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useDeleteWarrantyClaim';
import {useCallback} from 'react';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IPreparedClaim[];
	selectedClearing: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const SelectedEntitiesDeletion = (props: ISelectedEntitiesDeletionProps) => {
	const {selectedEntities, selectedClearing} = props;
	const {mutate: deleteWarrantyClaim} = useDeleteWarrantyClaim();
	const {t} = useTranslation();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.warrantyClaims.deleteManyTitle'),
		message: t('useConfirmDialog.warrantyClaims.deleteManyMessage')
	});

	const handleClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			for (const claim of selectedEntities) {
				deleteWarrantyClaim({
					objectId: claim.object?.id ?? 'all',
					warrantyClaimId: claim.id
				});
			}
			selectedClearing();
		}
	}, [getDeleteConfirmation, selectedClearing, selectedEntities, deleteWarrantyClaim]);

	return (
		<>
			<IconButton onClick={handleClick}>{deleteIcon}</IconButton>

			{deleteDialog}
		</>
	);
};
