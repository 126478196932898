import {memo} from 'react';
import EntitiesFilters, {
	CreatedByFilter,
	ObjectsFilter,
	CreationDateFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {StatusesFilter} from './filters/StatusesFilter';
import {ResolutionsFilter} from './filters/ResolutionsFilter';
import {ModifiedDateFilter} from '@src/components/EntitiesFilters/components/ModifedDateFilter';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/interfaces';
import {useTranslation} from 'react-i18next';
import {SpaceOwnersFilter} from '@src/components/EntitiesFilters/components/SpaceOwnersFilter';

interface IOwnerAcceptancesFiltersProps {
	objectId?: string;
}

export const OwnerAcceptancesFilters = memo(({objectId = 'all'}: IOwnerAcceptancesFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IOwnerAcceptancesFiltersState>();
	const {t} = useTranslation();

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="owner-acceptances"
		>
			{objectId === 'all' && (
				<ObjectsFilter
					value={state?.objects}
					label={t('entitiesFilters.objectsFilter.label')}
				/>
			)}

			<StatusesFilter statuses={state?.statuses} />

			<ResolutionsFilter resolutions={state?.resolutions} />

			<SpaceOwnersFilter owners={state?.spaceOwners} />

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
				label={t('entitiesFilters.CreatedByFilter.label')}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
				label={t('entitiesFilters.CreationDateFilter.label')}
			/>

			<ModifiedDateFilter
				from={state?.modifiedAtFrom}
				to={state?.modifiedAtTo}
				label={t('entitiesFilters.ModifiedDateFilter.label')}
			/>
		</EntitiesFilters>
	);
});
