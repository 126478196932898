import * as types from './constants';
import {StatisticsChartType} from '@src/interfaces/StatisticsChartType';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

/**
 * Изменяет вариант графика для отображения статистики
 *
 * @param value новое значение
 */
export const changeChartType = (value: StatisticsChartType) => ({
	type: types.CHANGE_CHART_TYPE,
	payload: value
});

/**
 * Изменяет выбранные статусы нарушений для отображения на графике
 *
 * @param value новое значение
 */
export const changeStatuses = (value: ProblemStatusId[]) => ({
	type: types.CHANGE_DISPLAY_FILTERS,
	payload: value
});
