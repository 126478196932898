import {EntityInfo, EntityInfoDateWithCountFiles} from '@tehzor/ui-components';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from "react-i18next";

interface IOwnerAcceptanceInfoProps {
	className?: string;
	data: IOwnerAcceptance;
	title?: string;
}

const ownerAcceptanceIcon = <i className="tz-owner-acceptance-28" />;

const OwnerAcceptanceInfo = ({
	className,
	data,
	title = 'Передача собственнику'
}: IOwnerAcceptanceInfoProps) => {
	const {t} = useTranslation();
	const locale = useDateFnsLocale();
	return (
		<EntityInfo
			className={className}
			title={`${title} №${data.number ?? data.localNumber}`}
			subTitle={
				<EntityInfoDateWithCountFiles
					labelFrom={t('components.tableCell.info.entityInfoDateWithCountFiles.labelFrom')}
					locale={locale}
					data={data}
				/>
			}
			icon={ownerAcceptanceIcon}
			iconColor="#2BC697"
			iconSize={44}
		/>
	);
};

export default OwnerAcceptanceInfo;
