import {Column} from 'react-table';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {MobileDocumentCell} from './cells/mobileDocumentCell';

export const mobileColumns: Array<Column<IDocument>> = [
	{
		id: 'number',
		width: 65,
		Cell: MobileDocumentCell
	}
];
