import {StatisticsChartType} from '@src/interfaces/StatisticsChartType';

export function determineCardsColumnsCount(type: StatisticsChartType) {
	switch (type) {
		case StatisticsChartType.BAR:
			return 4;
		case StatisticsChartType.PIE:
			return 3;
		default:
			return 0;
	}
}
