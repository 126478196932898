import {Plate} from '@tehzor/ui-components';
import './EditingExportTemplate.less';
import {useEditableExportTemplate} from '../EditableExportTemplate/hooks/useEditableExportTemplate';
import {useExtractExportTemplateById} from '@src/core/hooks/queries/exportTemplates/hooks';

interface IEditingExportTemplateProps {
	exportTemplateId?: string;
}

export const EditingExportTemplate = ({exportTemplateId}: IEditingExportTemplateProps) => {
	const {data: exportTemplate} = useExtractExportTemplateById(
		exportTemplateId,
		!!exportTemplateId
	);
	const [exportTemplateFields, availableFilds, commonInstruction] = useEditableExportTemplate({
		exportTemplate
	});

	return (
		<div className="editing-export-template__container">
			<Plate className="editing-export-template__info-grid">{exportTemplateFields}</Plate>
			<Plate className="editing-export-template__items-grid">
				{commonInstruction}
				{availableFilds}
			</Plate>
		</div>
	);
};
