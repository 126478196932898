import * as React from 'react';
import './DesktopPhotoEditorFooter.less';
import classNames from 'classnames';
import {ColorSelectButton, InlineButton, Button} from '../../../../buttons';

/**
 * IDesktopPhotoEditorFooterTranslations нужен для перевода
 * @param applyBtn defaultValue[RU] = Готово
 * @param cancelBtn defaultValue[RU] = Отменить
 * @param editBtn defaultValue[RU] = Редактировать
 */
export interface IDesktopPhotoEditorFooterTranslations {
	applyBtn: string;
	cancelBtn: string;
	editBtn: string;
}

interface IPhotoEditorFooterProps {
	className?: string;
	style?: React.CSSProperties;
	type: 'edit' | 'view';
	editable?: boolean;
	hEdit(): void;
	title?: string;
	hChangeColor(color: string): void;
	hCancel(): void;
	hSave(): void;
	hClear?(): void;
	colors: string[];
	selectedColor?: string;
	translations: IDesktopPhotoEditorFooterTranslations;
}

const editIcon = <i className="tz-brush-24" />;
const eraseIcon = <i className="tz-eraser-24" />;

const DesktopPhotoEditorFooter = ({
	className,
	editable,
	type,
	hEdit,
	hChangeColor,
	hCancel,
	hSave,
	hClear,
	title,
	colors,
	selectedColor,
	translations
}: IPhotoEditorFooterProps) => (
	<div className={classNames('desktop-photo-viewer__footer', className)}>
		<div>{title || 'Фото нарушения'}</div>
		{editable && type === 'edit' && <InlineButton leftIcon={eraseIcon} onClick={hClear} />}
		{editable && type === 'edit' && (
			<div className="desktop-photo-viewer__footer_edit-color">
				{colors.map(color => (
					<ColorSelectButton
						key={color}
						onClick={() => hChangeColor(color)}
						color={`${color}`}
						selected={color === selectedColor}
					/>
				))}
			</div>
		)}
		{editable && type === 'edit' && (
			<div className="desktop-photo-viewer__footer_edit-actions">
				<Button type="accent-blue" label={translations.applyBtn} onClick={hSave} />
				<Button type="cancel" label={translations.cancelBtn} onClick={hCancel} />
			</div>
		)}
		{editable && type === 'view' && (
			<InlineButton
				leftIcon={editIcon}
				onClick={hEdit}
				labelClassName="desktop-photo-viewer__footer_edit-label"
				label={translations.editBtn}
			/>
		)}
	</div>
);

export default DesktopPhotoEditorFooter;
