import {makeObjectDeleteRequest} from '@src/api/backend/object';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {queryClient} from '@src/api/QueryClient';
import { addErrorToast } from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';

const mutationFn = (id: string) => makeObjectDeleteRequest(id);

const onSuccess = () => {
	void queryClient.invalidateQueries({queryKey: objectsQueryKeys.list()});
};

const onError = () => addErrorToast('Ошибка', 'при удалении объекта');

export const useDeleteObject = () =>
	useMutation({
		mutationFn,
		mutationKey: objectsQueryKeys.delete(),
		onSuccess,
		onError
	});
