import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {useQuery} from '@tanstack/react-query';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback} from 'react';
import {extractLocalProblemsJournal} from './selector';

export const useLocalProblemsJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: problemsQueryKeys.add()});
	const selector = useCallback(
		(problems: IListProblem[]) => extractLocalProblemsJournal(problems, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IListProblem[], unknown, IOfflineJournalEntity[]>({
		queryKey: problemsQueryKeys.localList(),
		select: selector
	});
	return data || [];
};
