import {memo} from 'react';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import './OfflineJournalItem.less';
import {useTranslation} from 'react-i18next';
import {OfflineJournalTitle} from '@src/pages/OfflineJournalPage/components/OfflineJournalTitle/OfflineJournalTitle';
import {ExpandablePlate} from '@tehzor/ui-components';

interface IOfflineJournalItemProps {
	offlineJournalEntity: IOfflineJournalEntity;
}

export const OfflineJournalItem = memo(({offlineJournalEntity}: IOfflineJournalItemProps) => {
	const {t} = useTranslation();
	const {user, object, entity, location, error} = offlineJournalEntity;

	return (
		<ExpandablePlate
			title={<OfflineJournalTitle offlineJournalEntity={offlineJournalEntity} />}
			className={{
				root: 'offline-journal-page__list-item',
				content: 'offline-journal-page__list-content',
				expandButton: 'offline-journal-page__list-expand-button'
			}}
			initiallyClosed
		>
			<div className="offline-journal-item">
				{error && (
					<div className="offline-journal-item__error">
						<span>{t('offlineJournalPage.offlineJournalItem.error')}</span>
						{error.message}
					</div>
				)}
				{object?.objectName && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.objectName')}</span>
						{object.objectName}
					</div>
				)}
				{object?.locationName && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.locationName')}</span>
						{object.locationName}
					</div>
				)}
				{entity?.entityId && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.localNumber')}</span>
						{entity.entityLocalNumber}
					</div>
				)}
				{entity?.checkListName && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.checkListName')}</span>
						{entity.checkListName}
					</div>
				)}
				{entity?.checkItemName && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.checkItemName')}</span>
						{entity.checkItemName}
					</div>
				)}
				{entity?.newRecordStatus && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.newRecordStatus')}</span>
						{entity.newRecordStatus}
					</div>
				)}
				{entity?.newIndicators && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.newIndicators')}</span>
						{entity.newIndicators.join(', ')}
					</div>
				)}
				{entity?.description && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.description')}</span>
						{entity.description}
					</div>
				)}
				{location?.names && location?.names.length && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.location')}</span>
						{location?.names.join(', ')}
					</div>
				)}
				{user?.userName && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.userName')}</span>
						{user.userName}
					</div>
				)}
				{user?.phone && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.phone')}</span>
						{user.phone}
					</div>
				)}
				{user?.email && (
					<div className="offline-journal-item__info">
						<span>{t('offlineJournalPage.offlineJournalItem.email')}</span>
						{user.email}
					</div>
				)}
			</div>
		</ExpandablePlate>
	);
});
