import {useExtractChecksAsArray, useLocalChecks} from '@src/core/hooks/queries/checks/hooks';
import {
	useExtractAllLatestChecksAsArray,
	useExtractLatestCheckId
} from '@src/core/hooks/queries/checks/latest/hooks';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {useFetchLatestUserCheck} from '@src/core/hooks/queries/check/latest/hooks';

export const useLatestChecks = (
	objectId: string,
	setSelectedCheckId: Dispatch<SetStateAction<string | undefined>>,
	selectedCheckId?: string,
	links?: ICheck['links']
) => {
	const {data: allChecks} = useExtractChecksAsArray(objectId);
	const {data: allLatestChecks} = useExtractAllLatestChecksAsArray();
	const {data: localChecks} = useLocalChecks();
	const isCheck = !!allChecks?.find(item => item.id === selectedCheckId);
	const isLatestCheck = !!allLatestChecks?.find(item => item.id === selectedCheckId);
	const isLocalCheck = !!localChecks?.find(item => item.id === selectedCheckId);
	const {data: latestCheckId} = useExtractLatestCheckId(objectId, links?.spaceId);

	// Запрос нужен при создании нарушения/осмотра из уже созданной другим
	// пользователем проверки. В этом случае проверка добавляется в
	// "последние" проверки пользователя и автоматически выбирается, как основная
	const {data: latestUserCheckData} = useFetchLatestUserCheck(
		selectedCheckId as string,
		objectId,
		{
			enabled: !!(selectedCheckId && objectId && isCheck && !isLatestCheck && !isLocalCheck)
		}
	);

	// "Синхронизация состояния" - это антипаттерн, стоит переписать.
	useEffect(() => {
		if (latestCheckId) {
			setSelectedCheckId(latestCheckId);
		}
	}, [latestCheckId]);

	useEffect(() => {
		if (latestUserCheckData) {
			setSelectedCheckId(latestUserCheckData.id);
		}
	}, [latestUserCheckData]);
};
