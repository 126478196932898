import { memo } from 'react';
import {CalendarViewResult} from '../hooks/useCalendarView';
import IconButton from '../../../buttons/IconButton';

interface IDecadesNavProps {
	viewData: CalendarViewResult;
}

const DecadesNav = (props: IDecadesNavProps) => {
	const {viewData} = props;
	const {century} = viewData;

	return (
		<div className="calendar__nav">
			<IconButton
				className="calendar__nav-arrow-btn"
				onClick={viewData.prevCentury}
			>
				<i className="tz-simple-arrow-24 calendar__nav-prev-icon"/>
			</IconButton>

			<div className="calendar__nav-link-label calendar__nav-decades-label">{`${century}-${century + 99}`}</div>

			<IconButton
				className="calendar__nav-arrow-btn"
				onClick={viewData.nextCentury}
			>
				<i className="tz-simple-arrow-24 calendar__nav-next-icon"/>
			</IconButton>
		</div>
	);
};

export default memo(DecadesNav);
