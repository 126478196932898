const domain = 'owner-acceptance';

export const ADD_SUCCESS = `${domain}/add/success`;

export const EDIT_SUCCESS = `${domain}/edit/success`;

export const EDIT_STATUS_SUCCESS = `${domain}/status/edit/success`;

export const DELETE_SUCCESS = `${domain}/delete/success`;

export const GET_PROBLEM_REPLIES_REQUEST = `${domain}/problem-replies/get/request`;
export const GET_PROBLEM_REPLIES_SUCCESS = `${domain}/problem-replies/get/success`;
export const GET_PROBLEM_REPLIES_FAILURE = `${domain}/problem-replies/get/failure`;