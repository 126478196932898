import {useCallback} from 'react';
import * as React from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {
	useTasksPageDisplayMode,
} from '@src/core/hooks/queries/tasks/hooks';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';
import useAppSelector from "@src/core/hooks/useAppSelector";
import {
	extractTasksListSettings,
	extractTasksScheduleSettings
} from "@src/store/modules/settings/pages/tasks/selectors";
import {tasksListActions} from "@src/store/modules/settings/pages/tasks/slices/list";
import {tasksScheduleActions} from "@src/store/modules/settings/pages/tasks/slices/schedule";

export const FiltersContextWrap = ({children}: {children: React.ReactNode}) => {
	const dispatch = useAppDispatch();
	const {changeFilters, changeOffset, clearFilters} = tasksListActions;

	const {filters} = useAppSelector(extractTasksListSettings);
	const {filters: scheduleFilters} = useAppSelector(extractTasksScheduleSettings);
	const displayMode = useTasksPageDisplayMode();
	const {changeFilters: changeScheduleFilters,clearFilters: clearScheduleFilters } = tasksScheduleActions;

	const applyFilters = useCallback(
		(value: IProblemsFiltersState) => {
			if (displayMode === TasksDisplayMode.LIST) {
				dispatch(changeFilters({filters: value}));
				dispatch(changeOffset({offset: 0}));
			}

			if (displayMode === TasksDisplayMode.SCHEDULE) {
				dispatch(changeScheduleFilters({filters: value}));
			}
		},
		[dispatch, displayMode, changeFilters, changeOffset, changeScheduleFilters]
	);

	const onClear = useCallback(() => {
		if (displayMode === TasksDisplayMode.LIST) {
			dispatch(clearFilters());
		}
		if (displayMode === TasksDisplayMode.SCHEDULE) {
			dispatch(clearScheduleFilters());
		}
	}, [dispatch, displayMode, clearFilters, clearScheduleFilters]);

	return (
		<EntitiesFiltersProvider
			filters={displayMode === TasksDisplayMode.LIST ? filters : scheduleFilters}
			onApply={applyFilters}
			onClear={onClear}
		>
			{children}
		</EntitiesFiltersProvider>
	);
};
