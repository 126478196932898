import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IEntityAddingBtnProps {
	onClick: () => void;
}

export const EntityAddingBtn = ({onClick}: IEntityAddingBtnProps) => {
	const {t} = useTranslation();

	return (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16" />}
					label={t('structurePage.buttons.add')}
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={onClick}
				/>
			</BeforeDesktop>
			<Desktop>
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-plus-20" />}
					label={t('structurePage.buttons.add')}
					onClick={onClick}
				/>
			</Desktop>
		</>
	);
};
