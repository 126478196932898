interface IItem {
	id: string;
	order: number;
	parentId?: string;
}

/**
 * Рекурсивно создает индекс в предварительно сортированном по order дереве
 *
 * @param item элемент индекс которого надо посчитать
 * @param sortedTree плоское дерево сортированное по order
 */
export const createRecursiveIndex = (item: IItem, sortedTree: IItem[]): string => {
	const siblings = sortedTree.filter(val => val.parentId === item.parentId);
	const index = siblings.findIndex(val => val.id === item.id);

	if (index === -1) {
		return '1.';
	}

	const parent = sortedTree.find(val => val.id === item.parentId);

	const recursiveIndex = `${index + 1}.`;
	if (!parent) {
		return recursiveIndex;
	}

	const parentRecursiveIndex = createRecursiveIndex(parent, sortedTree);
	return `${parentRecursiveIndex}${recursiveIndex}`;
};
