import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import {
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {
	useEditableCheckState,
	convertToSave,
	hasAttachmentsError,
	isEdited,
	errorsFns
} from '@src/core/hooks/states/useEditableCheckState';
import {EditableCheckAttachments} from '../EditableCheckAttachments';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ISavingCheck} from '@src/interfaces/saving/ISavingCheck';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

const fieldsSettings = {
	description: {fieldId: 'description', isRequired: false},
	attachments: {fieldId: 'attachments', isRequired: false}
};

/**
 * Логика редактирования вложений
 */
export const useEditableCheckAttachments = (
	objectId: string,
	check: ICheck,
	saving: boolean
): [React.ReactNode, () => Promise<ISavingCheck | undefined>, (
) => void, boolean] => {
	const [editingState, editingDispatch] = useEditableCheckState(check);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, check) || isFilesExist(uploadingFilesState.value));
	}, [editingState, uploadingFilesState.value, check]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (
			hasErrors(editingState, errorsFns, fieldsSettings)
			|| (hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
		) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (!isEdited(editingState, check) && (!isFilesExist(files) || someFilesHaveError(files))) {
			return undefined;
		}
		const savingData = convertToSave(editingState, check, true);
		savingData.newAttachments = convertFilesToSave(files);
		return savingData;
	}, [editingState, objectId, check, fieldsSettings]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: check
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [check]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [check]);

	const fields = (
		<EditableCheckAttachments
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
