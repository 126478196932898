import {PageHeader} from '@tehzor/ui-components';
import {useWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/hooks';
import {useTranslation} from 'react-i18next';

interface IClaimsPageHeaderProps {
	objectId?: string;
}

export const ClaimsPageCounter = ({objectId = 'all'}: IClaimsPageHeaderProps) => {
	const {data: warrantyClaims} = useWarrantyClaims(objectId);
	const {t} = useTranslation();
	const total = warrantyClaims?.total || 0;
	const text = `${t('warrantyClaimsPage.counter.claimsDeclination.prefix.total')}: ${total}`;

	return (
		<PageHeader
			className="w-claims-page__header"
			title=""
			subTitle={text}
			wrapClassName="w-claims-page__header-wrap"
			leftSideClassName="w-claims-page__header-left"
			rightSideClassName="w-claims-page__header-right"
		/>
	);
};
