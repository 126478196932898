import {memo, useCallback} from 'react';
import {Dialog} from '@tehzor/ui-components';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {EntityTasksVirtualizeItems} from '@src/components/TasksDialog/components/EntityTasksVirtualizeItems';
import './TasksDialog.less';

interface ITasksDialogProps {
	tasks?: ITask[];
	totalCount?: number;
	title?: string;
	isOpen: boolean;
	onClose: () => void;
}

const tasksDialogClassnames = {
	root: 'tasks-dialog',
	content: 'tasks-dialog__content',
	body: 'tasks-dialog__body'
};

export const TasksDialog = memo((props: ITasksDialogProps) => {
	const {tasks, totalCount, title, isOpen, onClose} = props;

	const renderModalTitle = useCallback(
		() => (
			<div className="tasks-dialog__title">
				{title}
				{totalCount && (
					<span className="tasks-dialog__title--counter">{`(${totalCount})`}</span>
				)}
			</div>
		),
		[title, totalCount]
	);

	return (
		<Dialog
			className={tasksDialogClassnames}
			isOpen={isOpen}
			title={renderModalTitle()}
			onRequestClose={onClose}
			fullScreenOnMobile
		>
			<EntityTasksVirtualizeItems tasks={tasks} />
		</Dialog>
	);
});
