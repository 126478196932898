import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {useCallback} from 'react';
import {CheckListsDiagram} from './CheckListsDiagram';

interface ISpaceDiagramProps {
	space: IConvertedSpace;
	width: number;
	height: number;
	name: string;
	onClick?: (space: IConvertedSpace) => void;
}

export const SpaceDiagram = ({space, width, height, name, onClick}: ISpaceDiagramProps) => {
	const {checkLists} = space;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(space);
		}
	}, [space]);

	return (
		<svg
			width={width}
			height={height}
			className="check-lists-space__status"
			onClick={handleClick}
		>
			{/* тень */}
			<circle
				cx={width / 2}
				cy={height / 2}
				r={(width - 20) / 2}
				className="check-lists-space__status-shadow"
			/>
			{/* круговая диаграмма */}
			<CheckListsDiagram
				width={width}
				height={height}
				radius={(width - 20) / 2}
				checkLists={checkLists}
				spaceId={space.id}
			/>

			<text
				className="check-lists-space__number"
				x="50%"
				y="50%"
				dominantBaseline="middle"
				textAnchor="middle"
			>
				{name}
			</text>
		</svg>
	);
};
