import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

export type IEditProblemStatusResponse = ILinkedProblem;

/**
 * Изменяет статус нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param status статус
 */
export const makeProblemStatusEditRequest = async (
	objectId: string,
	problemId: string,
	status: ProblemStatusId
) => {
	const response = await httpRequests.withToken.patch<IEditProblemStatusResponse>(`problems/edit-status/${problemId}`, {
		objectId,
		status
	});

	return response.data;
};
