import {queryClient} from '@src/api/QueryClient';
import {IGetMetersResponse, makeMeterAddRequest} from '@src/api/backend/meters';
import {metersQueryKeys} from '@src/api/cache/meters/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {ISavingMeter} from '@tehzor/tools/interfaces/meters/ISavingMeter';
import {useCallback} from 'react';

export const useAddMeter = () => {
	const mutation = useMutation<IMeter, IError, ISavingMeter>({
		mutationFn: (params: ISavingMeter) => makeMeterAddRequest(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetMetersResponse>(
				metersQueryKeys.list(data.spaceId),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return {
						byId: {[data.id]: data},
						allIds: [data.id]
					};
				}
			);
			addSuccessToast('Успех', 'Прибор учёта добавлен');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении прибора учёта');
		}
	});
	/**
	 * Возвращает функцию для создания прибора учёта
	 *
	 * @param item данные нового прибора учёта
	 */
	return useCallback(
		(item: ISavingMeter) => {
			mutation.mutate(item);
		},
		[mutation]
	);
};
