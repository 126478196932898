import {CellProps} from 'react-table';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';

export const ModifiedCell = ({row}: CellProps<IPreparedWorkAcceptance>) => (
	<div className="work-acceptances-page__d-table-created">
		<div className="work-acceptances-page__d-table-created--date">
			{row.original.modifiedAt}
		</div>
		<div className="work-acceptances-page__d-table-created--name">
			{row.original.modifiedBy}
		</div>
	</div>
);
