import {Responsibles} from '@src/components/viewFields/Responsibles/Responsibles';
import {useInspectionResponsiblePermissions} from '@src/core/hooks/permissions/useInspectionResponsiblePermissions';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getPerformersDelegationHistoriesByInspection} from '@src/store/modules/entities/inspection/actions';
import {editInspectionPerformers} from '@src/store/modules/entities/inspection/actions/editPerformers';
import {extractInspectionPerformersDelegationHistoriesAsArray} from '@src/store/modules/entities/inspection/selectors/delegationHistories';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface IInspectionPerformersProps {
	inspection: ILinkedInspection;
	settings?: IEntitySettings;
}

export const InspectionPerformers = ({inspection, settings}: IInspectionPerformersProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const histories = useAppSelector(extractInspectionPerformersDelegationHistoriesAsArray);

	const {id, objectId, stage, performersActiveGroup, performersInitialGroup, performers} =
		inspection;

	const {canEditPerformers, onlyActiveGroup} = useInspectionResponsiblePermissions(
		objectId,
		inspection
	);

	const ruleParams: IRuleParams = useMemo(
		() => ({
			categoryId: inspection.categoryId,
			planId: inspection.plan?.id
		}),
		[inspection.categoryId, inspection.plan?.id]
	);

	const getDelegationHistories = useCallback(
		() => dispatch(getPerformersDelegationHistoriesByInspection(objectId, id)),
		[objectId, id]
	);

	const handleChange = useCallback(
		async (users: string[], group?: string) => {
			await dispatch(editInspectionPerformers(objectId, id, users, group));
		},
		[objectId, id]
	);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			workingGroupType={WorkingGroupTypeId.PERFORMERS}
			respUsers={performers}
			activeGroup={performersActiveGroup}
			initialGroup={performersInitialGroup}
			canEditResponsibles={canEditPerformers}
			onlyActiveGroup={onlyActiveGroup}
			ruleParams={ruleParams}
			histories={histories}
			required={settings?.builtin.performers.required}
			label={t('inspectionPage.info.performers.label')}
			delegationDialogLabel={t('components.delegationDialog.performers.title')}
			historyAutomaticActionLabelIn={t('inspectionPage.info.performers.historyAutomaticActionLabelIn')}
			historyAutomaticActionLabelTitle={t('inspectionPage.info.performers.historyAutomaticActionLabelTitle')}
			historyUserActionLabelIn={t('inspectionPage.info.performers.historyUserActionLabelIn')}
			historyUserActionLabelTitle={t('inspectionPage.info.performers.historyUserActionLabelTitle')}
			onResponsiblesChange={handleChange}
			getDelegationHistories={getDelegationHistories}
		/>
	);
};
