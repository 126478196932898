import {useEditAuthorizedPersonMutation} from '@src/api/cache/authorizedPersons/mutations';
import {IEditAuthorizedPersonParams} from '@src/api/cache/authorizedPersons/defaults/useAuthorizedPersonsMutationDefaults';
import {IEditAuthorizedPersonResponse} from '@src/api/backend/authorizedPerson/edit';

export const useEditAuthorizedPerson = (): ((
	params: IEditAuthorizedPersonParams
) => Promise<IEditAuthorizedPersonResponse | undefined>) => {
	const mutation = useEditAuthorizedPersonMutation();
	return (params: IEditAuthorizedPersonParams) => mutation.mutateAsync(params);
};
