export type Tree<T> = T & {
	children?: Array<Tree<T>>;
};

/**
 * Выполняет callback функцию для каждого элемента дерева
 *
 * @param tree дерево элементов
 * @param callback функция вызываемая для каждого элемента дерева
 */
export function traverseTree<P>(
	tree: Array<Tree<P>>,
	callback: (treeElement: Tree<P>) => void
): void {
	function recursTraverse(treeElementArray: Array<Tree<P>>) {
		treeElementArray.forEach((treeElement: Tree<P>) => {
			callback(treeElement);
			if (treeElement.children) {
				recursTraverse(treeElement.children);
			}
		});
	}

	recursTraverse(tree);
}

/**
 * Создаёт новое дерево с результатом вызова указанной функции для каждого элемента дерева
 *
 * @param tree дерево элементов
 * @param callback функция вызываемая для каждого элемента дерева
 * @param args данные для функции
 */
export function mapTree<
	P, // тип элемента дерева
	R, // тип значения возвращаемого callback функцией
	U // тип args
>(
	tree: Array<Tree<P>>,
	callback: (treeElement: Tree<P>, callbackPayload?: U) => R,
	args?: U
): Array<Tree<R>> {
	const recursMap = (treeElementArray: Array<Tree<P>>): Array<Tree<R>> =>
		treeElementArray.reduce((responceTree: Array<Tree<R>>, treeElement: Tree<P>) => {
			responceTree.push({
				...callback(treeElement, args),
				children: treeElement.children ? recursMap(treeElement.children) : undefined
			});
			return responceTree;
		}, [] as Array<Tree<R>>);
	return recursMap(tree);
}

/**
 * Преобразует дерево элементов в плоский массив, с сохранением визуального порядка элементов
 *
 * @param items дерево элементов
 */
export function flattenTree<P>(items: Array<Tree<P>>): P[] {
	const resp = [] as Array<Tree<P>>;
	traverseTree<P>(items, item => {
		resp.push({...item, children: undefined});
	});
	return resp;
}

/**
 * Создаёт новое дерево со всеми элементами, прошедшими проверку, задаваемую в передаваемой функции
 *
 * @param tree дерево элементов
 * @param callbackFn функция которая будет вызвана для проверки каждого элемента дерева.
 * Если функция возвращает true, то элемент остаётся в дереве, если false, то удаляется
 * @param args Значение, передаваемое в колбэк-функцию
 */
export function filterTree<
	P, // тип элемента дерева
	U // тип args
>(
	tree: Array<Tree<P>>,
	callbackFn: (treeElement: Tree<P>, callbackArgs?: U) => boolean,
	args?: U
): Array<Tree<P>> {
	const recursiveFilter = (treeElementsArray: Array<Tree<P>>): Array<Tree<P>> =>
		treeElementsArray.reduce<Array<Tree<P>>>(
			(filteredTree: Array<Tree<P>>, currentTreeElement: Tree<P>): Array<Tree<P>> => {
				let check = false;
				traverseTree<P>([currentTreeElement], checksElement => {
					if (callbackFn(checksElement, args)) {
						check = true;
					}
				});
				if (check) {
					filteredTree.push({
						...currentTreeElement,
						children: currentTreeElement.children
							? recursiveFilter(currentTreeElement.children)
							: undefined
					});
				}
				return filteredTree;
			},
			[]
		);
	return recursiveFilter(tree);
}
