import IShape from '@tehzor/tools/interfaces/plans/IShape';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

/**
 * Формирует данные для нарушения и осмотра, содержащие местоположение из помещения
 *
 * @param space помещение
 */
export function formSpaceLocationForEntities(space: ISpace, sector?: IShape) {
	const data: Record<string, unknown> = {floor: space.floor};
	if (space.locations?.length) {
		const location = space.locations[0];
		data.planId = location.planId;

		if (location && sector) {
			data.location = {sectors: [
				{
					sector: sector?.id,
					name: sector.name
				}
			]};
		}
	}
	return data;
}
