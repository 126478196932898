import EntityAdding from './EntityAdding';
import ClaimExportDialog from './ClaimExportDialog';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';

interface IDesktopMainActionsProps {
	objectId: string;
	warrantyClaim: IWarrantyClaim;
}

export const DesktopMainActions = ({objectId, warrantyClaim}: IDesktopMainActionsProps) => {
	const permissions = useWarrantyClaimsPermissions(objectId);

	return (
		<>
			{permissions.canExport && (
				<ClaimExportDialog
					objectId={objectId}
					warrantyClaimId={warrantyClaim.id}
				/>
			)}
			{permissions.canProblemsAdd && (
				<EntityAdding
					objectId={objectId}
					warrantyClaim={warrantyClaim}
				/>
			)}
		</>
	);
};