import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useMemo} from 'react';

export interface IPermissions {
	canViewObject?: boolean;
	canAddObject?: boolean;
	canEditObject?: boolean;
	canDeleteObject?: boolean;
	canViewApartments?: boolean;
	canGenerate?: boolean;
}

/**
 * Возвращает полномочия для конкретного объекта
 *
 * @param {string} objectId id объекта
 * @param {IUser?} user текущий пользователь
 * @return {IPermissions}
 */
export const getPermissions = (objectId: string, user?: IUser): IPermissions => {
	if (!user || !user.roles) {
		return {};
	}
	return {
		canViewObject: hasPermission(user.roles, 'objectsPageView', UserRoleScopes.OBJECT, objectId),
		canAddObject: hasPermission(user.roles, 'objectsAdd', UserRoleScopes.OBJECT, objectId),
		canEditObject: hasPermission(user.roles, 'objectsEdit', UserRoleScopes.OBJECT, objectId),
		canDeleteObject: hasPermission(
			user.roles,
			'objectsDelete',
			UserRoleScopes.OBJECT,
			objectId
		),
		canViewApartments: hasPermission(user.roles, 'spaces-view', UserRoleScopes.OBJECT, objectId),
		canGenerate: hasPermission(
			user.roles,
			'spaces-generate',
			UserRoleScopes.OBJECT,
			objectId
		)
	};
};

/**
 * Хук для возврата кешированных полномочий текущего пользователя
 *
 * @param {string} objectId id объекта
 * @param {IUser|undefined} user текущий пользователь
 * @private
 */
export default (objectId: string, user?: IUser): IPermissions =>
	useMemo(() => getPermissions(objectId, user), [objectId, user]);
