import {ITwoWayTreeItem} from '../../interfaces/ITwoWayTreeItem';
import {getAllTreeKeys} from './getAllTreeKeys';

/**
 * Обновляет родительский элемент исходя из его дочерних
 *
 * @param item элемент
 * @param checked выбранные элементы
 */
function updateAncestors<T extends {
	id: string;
	parentId?: string;
	nonCheckable?: boolean;
	onlySemiCheckable?: boolean;
}>(
	item: ITwoWayTreeItem<T>,
	checked: string[]
) {
	let result = [...checked];
	// Если у элемента есть родитель, у которого есть дочерние элементы
	if (item.parentId && item.parent?.children && !item.parent.nonCheckable) {
		// Если все дочерние элементы родителя выбраны
		if (item.parent.children.every(d => (
			d.nonCheckable || checked.includes(d.id)
		)) && !item.parent.onlySemiCheckable) {
			// То родитель становится выбранным
			result.push(item.parentId);
		} else {
			// Иначе удаляется из выбранных
			result = result.filter(id => item.parentId !== id);
		}
		// Рекурсивное обновление родителя
		result = updateAncestors(item.parent, result);
	}
	return result;
}

/**
 * Обновляет список выбранных элементов
 *
 * @param item элемент, с которым связаны манипуляции
 * @param value true - добавление отметки выбора, false - снятие отметки
 * @param checked список ранее выбранных элементов
 * @param strictly без обновления предков и потомков
 * @param multiple множественный выбор при strictly === true
 */
export function updateCheckedKeys<T extends {
	id: string;
	parentId?: string;
	nonCheckable?: boolean;
	onlySemiCheckable?: boolean;
}>(
	item: ITwoWayTreeItem<T>,
	value: boolean,
	checked: string[],
	strictly?: boolean,
	multiple?: boolean
) {
	let result = [...checked];
	if (strictly) {
		if (multiple) {
			if (value) {
				result.push(item.id);
			} else {
				result = result.filter(id => id !== item.id);
			}
		} else {
			result = value ? [item.id] : [];
		}
	} else if (value) {
		if (!item.onlySemiCheckable) {
			result.push(item.id);
		}

		// Добавляем/Удаляем потомков
		if (item.children) {
			const childrens = getAllTreeKeys(item.children);
			// Если все потомки уже добавлены, то удаляем их
			if (item.children.every(d => d.nonCheckable || checked.includes(d.id))) {
				result = result.filter(id => !childrens.includes(id));
				// Иначе добавляем потомков
			} else {
				result = result.concat(childrens);
			}
		}
		// Обновляем всех предков
		result = updateAncestors(item, result);
	} else {
		let deletingIds = [item.id];
		// Снимаем со всех потомков
		if (item.children) {
			deletingIds = deletingIds.concat(getAllTreeKeys(item.children));
		}
		result = result.filter(id => !deletingIds.includes(id));
		// Обновляем всех предков
		result = updateAncestors(item, result);
	}
	return result;
}
