import {createContext, PropsWithChildren} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IPreparedExportTemplate} from '../interfaces/IPreparedExportTemplate';
import {useDeleteExportTemplate} from '@src/core/hooks/mutations/exportTemplates/useDeleteExportTemplate';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedExportTemplate>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({children}: PropsWithChildren) => {
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить шаблон?',
		'',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const {mutateAsync: deleteExportTemplate} = useDeleteExportTemplate();

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedExportTemplate>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await deleteExportTemplate(action.payload.id);
			}
		}
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
