import {useCallback} from 'react';
import {AppSidebarMenu} from './AppSidebarMenu';
import AppSidebarInfo from './AppSidebarInfo';
import {DesktopSidebar} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleSidebarExpanded} from '@src/store/modules/settings/sidebar/actions';

const DesktopAppSidebar = () => {
	const expanded = useAppSelector(s => s.settings.sidebar.expanded);
	const dispatch = useAppDispatch();

	const toggleExpanded = useCallback(() => dispatch(toggleSidebarExpanded()), []);

	return (
		<DesktopSidebar
			className="app-sidebar app-sidebar_desktop"
			menu={<AppSidebarMenu type="desktop"/>}
			appInfo={<AppSidebarInfo/>}
			expanded={expanded}
			onExpand={toggleExpanded}
		/>
	);
};

export default DesktopAppSidebar;
