import {IProblemTarget} from '@src/api/backend/problem';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import { useCallback, useState } from 'react';
import * as React from 'react';
import MovingProblemsDialog from '../MovingProblemsDialog';

export function useMovingProblemsDialog(
	objectId: string,
	target: IProblemTarget,
	selectedEntities: ICheckEntity[] | IInternalAcceptanceEntity[],
	restrictions?: {[key: string]: boolean},
	onSuccess?: () => void
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<MovingProblemsDialog
			selectedEntities={selectedEntities}
			objectId={objectId}
			target={target}
			restrictions={restrictions}
			isOpen={isOpen}
			onClose={close}
			onSuccess={onSuccess}
		/>
	);

	return [dialog, open];
}
