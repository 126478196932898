import { useCallback, useState } from 'react';
import * as React from 'react';
import {AddingRespUserDialog} from '../AddingRespUserDialog';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const useAddingRespUserDialog = (): [React.ReactNode, () => void] => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const {objectId} = useStrictParams<{objectId: string}>();
	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<AddingRespUserDialog
			isOpen={isOpen}
			close={close}
			objectId={objectId}
		/>
	);

	return [dialog, open];
};
