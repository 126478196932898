import {AddingWarrantyClaimView} from '../AddingWarrantyClaimDialog';
import {useTranslation} from 'react-i18next';

interface IDialogTitleProps {
	view?: AddingWarrantyClaimView;
}

const DialogTitle = ({view}: IDialogTitleProps) => {
	const {t} = useTranslation();
	let label = '';

	switch (view) {
		case 'claim':
			label = t('addingWarrantyClaimGlobal.dialogTitle.claim');
			break;
		case 'problem':
			label = t('addingWarrantyClaimGlobal.dialogTitle.problem');
	}

	return <div className="adding-warranty-claim-dialog__dialog-title">{label}</div>;
};

export default DialogTitle;
