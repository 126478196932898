import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {LinkButton} from '@tehzor/ui-components';
import { useCallback, useEffect, useMemo } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckItemLink} from '@tehzor/tools/utils/links';
import {getCheckList} from '@src/store/modules/app/links/actions/getCheckList';
import {useExtractCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import { useTranslation } from 'react-i18next';

const externalLinkIcon = <i className="tz-external-link-16" />;

export const CheckItemEntityLink = ({
	checkItemId,
	objectId,
	spaceId,
	checkListId,
	workAcceptanceId
}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const {data: checkItem} = useExtractCheckItemById(checkItemId);
	const listId = checkListId || checkItem!.checkListId;
	const checkList = useAppSelector(s => s.app.links.checkListId?.[listId]);
	const {t} = useTranslation();

	useEffect(() => {
		dispatch(getCheckList(listId));
	}, [listId]);

	const checkItemLink = useMemo(() => {
		if (!checkList?.stage) { return undefined; }

		return formCheckItemLink({
			listId,
			itemId: checkItemId,
			objectId,
			spaceId,
			workAcceptanceId,
			stage: checkList.stage
		});
	}, [listId, checkItemId, objectId, spaceId, checkList?.stage]);

	const handleClick = useCallback(() => {
		if (checkItemLink) {
			pushPath(checkItemLink);
		}
	}, [checkItemLink]);

	if (!checkList || !checkItem) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${t('components.entityLinks.checkListEntityLink.label')} "${checkList.name}"`}
			leftIcon={externalLinkIcon}
			onClick={handleClick}
			disabled={!checkItemLink}
			type="filled"
		/>
	);
};
