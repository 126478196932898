import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from "@tehzor/tools/interfaces/IExportResponse";
import getEmailsArray from "@src/utils/getEmailsArray";

/**
 * Отправляет запрос на экспорт приемки работ
 */
export const makeWorkAcceptanceExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	workAcceptanceId: string,
	checkLists?: string[],
	problems?: string[]
) => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId,
		workAcceptanceId,
		problems,
		checkLists
	};
	const response = await httpRequests.exportWithToken.get<IExportResponse>(
		`work-acceptances/${workAcceptanceId}/export`, {params}
	);
	return response.data
};