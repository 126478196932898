import {queryClient} from '@src/api/QueryClient';
import {
	IBindSpaceToOwnersResponse,
	makeBindSpaceToOwnersRequest
} from '@src/api/backend/spaceOwners';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

/**
 * Привязывает собственников к помещению
 *
 * @param owners id собственников
 * @param spaceId id помещения
 */
interface IBindSpaceOwnersParams {
	owners: string[];
	spaceId: string;
}

export const useBindSpaceOwners = () =>
	useMutation<IBindSpaceToOwnersResponse, IError, IBindSpaceOwnersParams>({
		mutationKey: spaceOwnersQueryKeys.bind(),
		mutationFn: params => {
			const {owners, spaceId} = params;
			return makeBindSpaceToOwnersRequest(owners, spaceId);
		},
		onError: () => {
			addErrorToast('Ошибка', 'при привязке собственников');
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceOwnersQueryKeys.list()});
		}
	});
