import {memo, useCallback, useRef, useState} from 'react';
import './AddingMeterConsumptionDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {
	AddingMeterConsumption,
	IAddingMeterConsumptionRefProps
} from '@src/components/AddingMeterConsumption/AddingMeterConsumption';
import {useTranslation} from "react-i18next";

interface IAddingMeterConsumptionDialogProps {
	companyId?: string;
	meterId?: string;
	isOpen: boolean;

	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

/**
 * Окно добавления показания прибора учёта
 */
export const AddingMeterConsumptionDialog = memo((props: IAddingMeterConsumptionDialogProps) => {
	const {t} = useTranslation();
	const {companyId, meterId, isOpen, onClose, onSuccess} = props;

	const viewRef = useRef<IAddingMeterConsumptionRefProps>(null);
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const handleSave = useCallback(() => {
		if (viewRef.current) {
			viewRef.current.save();
		} else {
			onClose();
		}
		success.current = true;
	}, [onClose]);

	const handleCancel = useCallback(() => {
		if (viewRef.current) {
			void viewRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onSuccess]);

	return (
		<Dialog
			className={{
				root: 'adding-meter-consumption-dialog',
				title: 'adding-meter-consumption-dialog__title',
				body: 'adding-meter-consumption-dialog__body'
			}}
			isOpen={isOpen}
			title={t('components.addingMeterConsumptionDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('components.addingMeterConsumptionDialog.button.cancel.label')}
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label={t('components.addingMeterConsumptionDialog.button.accent.label')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			<AddingMeterConsumption
				companyId={companyId}
				meterId={meterId}
				saving={saving}
				onClose={onClose}
				setSaving={setSaving}
				ref={viewRef}
			/>
		</Dialog>
	);
});
