import { memo } from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useExtractRootCheckItems} from '@src/core/hooks/queries/checkItems/hooks';
import {useTranslation} from 'react-i18next';

interface ICheckListsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;
	checkList: string;
	onChange: (changes: IChanges) => void;
}

export const CheckListCategoryFilterMobile = memo((props: ICheckListsFilterMobileProps) => {
	const {t} = useTranslation();
	const {checkList} = props;
	const {data: checkListCategory} = useExtractRootCheckItems(checkList);

	if (!checkListCategory) return null;

	return (
		<BaseListFilterMobile
			{...props}
			options={checkListCategory}
			label={t('spacesFiltersPage.checkListCategoryFilterMobile.label')}
			filterName="checkListCategory"
		/>
	);
});
