import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {TFunction} from 'i18next';

export const createPrescriptionDifference = (
	prev: string | undefined,
	next: string | undefined,
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev,
	next,
	type: HistoryTypeId.PRESCRIPTION,
	name: t('createDifference.createPrescriptionDifference')
});
