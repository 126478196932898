import {OfflineDataItem} from '@tehzor/ui-components';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IGetSpacesTypesCountResponse} from '@src/api/backend/spaces/spacesTypesCount';
import {useTranslation} from "react-i18next";

const acpIcon = <i className="tz-internal-acceptance-24" />;
export const SpacesTypesCountCacheStatus = () => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const settings = queryClient.getQueryDefaults(spacesQueryKeys.typesCounts());
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	const {isError, isStale, isFetching, dataUpdatedAt, isPaused} =
		useQuery<IGetSpacesTypesCountResponse>({
			queryKey: spacesQueryKeys.typesCount(objects),
			networkMode: 'offlineFirst',
			staleTime: settings?.staleTime,
			gcTime: settings?.gcTime,
			enabled: !!objects && !!objects.length
		});

	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t('components.offlineModeDialog.spacesTypesCountCacheStatus.offlineDataItem.title')}
			icon={acpIcon}
			date={dataUpdatedAt}
			error={isError ? t('cacheStatusErrors.error') : isStale ? t('cacheStatusErrors.stale') : undefined}
			inProgress={isPaused || isFetching}
			progressTitle={t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default')}
		/>
	);
};
