import {IGetSpaceIndicatorsSetsResponse} from '@src/api/backend/spaceIndicatorsSets';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import {normalizeData} from '@tehzor/tools/utils/normalizeData';

/**
 * Возвращает индикаторы для конкретного объекта
 */
export const extractSpaceIndicatorsAsMap = (
	data: IGetSpaceIndicatorsSetsResponse,
	object?: IObject
) => {
	const s = object?.spaceIndicatorsSet ? data.byId[object?.spaceIndicatorsSet] : undefined;
	return s ? normalizeData<ISpaceIndicator>(s.data) : undefined;
};

/**
 * Возвращает индикаторы для конкретного объекта в виде массива
 */
export const extractSpaceIndicatorsAsArray = (
	data: IGetSpaceIndicatorsSetsResponse,
	object?: IObject
) => {
	const s = object?.spaceIndicatorsSet ? data.byId[object?.spaceIndicatorsSet] : undefined;
	return s?.data;
};
