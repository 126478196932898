import {combineReducers} from 'redux';
import checks, {ILocalChecksState} from './checks';
import internalAcceptances, {ILocalInternalAcceptancesState} from './internalAcceptances';
import problems, {ILocalProblemsState} from './problems';
import inspections, {ILocalInspectionsState} from './inspections';
import {persistReducer} from 'redux-persist';
import {formPersistConfig} from '@src/store/persistentStorage/formPersistConfig';
import ownerAcceptances, {ILocalOwnerAcceptancesState} from './ownerAcceptances';
import warrantyClaims, {ILocalWarrantyClaimsState} from './warrantyClaims';

export interface ILocalEntitiesState {
	checks: ILocalChecksState;
	internalAcceptances: ILocalInternalAcceptancesState;
	ownerAcceptances: ILocalOwnerAcceptancesState;
	warrantyClaims: ILocalWarrantyClaimsState;
	problems: ILocalProblemsState;
	inspections: ILocalInspectionsState;
}

const localEntitiesReducer = combineReducers({
	checks,
	internalAcceptances,
	ownerAcceptances,
	warrantyClaims,
	problems,
	inspections
});

export default persistReducer(
	formPersistConfig<ReturnType<typeof localEntitiesReducer>>('localEntities'),
	localEntitiesReducer
);
