import {
	EntitiesTable,
	Pagination,
	PaginationAndSize,
	PaginationPageSize,
	Plate
} from '@tehzor/ui-components';
import {useColumns} from '../model/hooks/useTableColumns';
import useAppSelector from '../../../../../core/hooks/useAppSelector';
import {memo, useMemo} from 'react';
import {pageSizes} from '@/shared/constants/pageSizes';
import styles from './FieldsSettingsSetsTable.module.less';
import cellStyles from '@/features/FieldsSettingsSets/components/DeleteRowButton/DeleteRowButton.module.less';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {convertFieldsSettingsSets} from '@/entities/FieldSettingsSets/model/utils/convertFieldSettingsSets';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {SelectionClearing} from '@/shared/ui/selection/SelectionClearing';
import {SelectionRow} from '@/features/FieldsSettingsSets/components/SelectionRow';
import {SelectionActionsFieldsSettingsSets} from '@/features/FieldsSettingsSets/components/selectionActionFieldsSettingsSets';
import {useFieldSettingsSetsArray} from '@src/core/hooks/queries/fieldSettingsSets/hooks';
import {usePaginate} from '@/shared/hooks/usePaginate';
import {extractFieldsSettingsSetsPageSettings} from '@/entities/FieldSettingsSets/model/slice/selectors';
import {useChangePage} from '@/shared/hooks/useChangePage';
import {useSelected} from '../model/hooks/useSelected';
import {fieldsSettingsSetsAction} from '@/entities/FieldSettingsSets';

export const FieldsSettingsSetsTable = memo(() => {
	const settings = useAppSelector(extractFieldsSettingsSetsPageSettings);
	const {data: fieldsArray} = useFieldSettingsSetsArray();
	const {data: fieldsSetsData, currentPage, pagesCount} = usePaginate(settings, fieldsArray);
	const roles = useAppSelector(extractUserRoles);
	const preparedFieldSettingsSets = useMemo(
		() => convertFieldsSettingsSets(fieldsSetsData, roles),
		[fieldsSetsData, roles]
	);
	const {selectedEntities, handleSelectedRowsChange, clearSelectedRowsChange} = useSelected({
		preparedFieldSettingsSets,
		settings
	});
	const isTablet = useIsTablet();
	const columns = useColumns(isTablet);
	const {handlePageChange, handlePageSizeChange} = useChangePage({
		settings,
		changeOffsetAction: fieldsSettingsSetsAction.changeOffset,
		changePageSizeAction: fieldsSettingsSetsAction.changePageSize
	});

	useAppHeader(
		{
			title: 'Наборы настроек пользовательских полей',
			mobileLeftButton:
				selectedEntities?.length > 0 ? (
					<SelectionClearing onSelectedRowsChange={clearSelectedRowsChange} />
				) : undefined,
			mobileRightButtons: (
				<SelectionActionsFieldsSettingsSets
					selectedEntities={selectedEntities}
					selectedClearing={clearSelectedRowsChange}
				/>
			)
		},
		[selectedEntities]
	);

	return (
		<div>
			<Plate withoutPadding>
				<EntitiesTable
					columns={columns}
					data={preparedFieldSettingsSets}
					selectable={isTablet}
					selectedRows={settings.selectedRows}
					headVisible={isTablet}
					onSelectedRowsChange={handleSelectedRowsChange}
					renderSelectionRow={props => <SelectionRow {...props} />}
					rowProps={() => ({
						className: cellStyles.deleteCellHover
					})}
				/>
			</Plate>
			<PaginationAndSize
				pagination={
					<Pagination
						className={styles.containerPagination}
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<PaginationPageSize
						className={styles.containerPageSize}
						pageSize={settings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</div>
	);
});
