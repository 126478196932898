import classNames from 'classnames';
import {MapContainer, TileLayer, Marker} from 'react-leaflet';
import './MapViewer.less';
// import './leaflet.css';
import ILocationMapPoint from '@tehzor/tools/interfaces/ILocationMapPoint';
// import {Icon} from 'leaflet';
import {Mode} from '../../LocationSelect';

// const markerIcon = new Icon({
// 	iconUrl: marker,
// 	iconSize: [42, 60],
// 	iconAnchor: [21, 53]
// });

interface IMapViewerProps {
	className?: string;
	mode: Mode;
	points: ILocationMapPoint[];

	onPointsChange?(points: ILocationMapPoint[]): void;
}

const MapViewer = (props: IMapViewerProps) => {
	const {className, /* mode, */ points /* , onPointsChange */} = props;

	const classes = classNames('map-viewer', className);

	// const handleMapClick = useCallback(
	// 	(event: LeafletMouseEvent) => {
	// 		if (onPointsChange) {
	// 			onPointsChange([
	// 				...points,
	// 				{
	// 					name: `${event.latlng.lat}, ${event.latlng.lng}`,
	// 					lat: event.latlng.lat,
	// 					lng: event.latlng.lng
	// 				}
	// 			]);
	// 		}
	// 	},
	// 	[points]
	// );

	return (
		<div className={classes}>
			<MapContainer
				className="map-viewer__map"
				center={[58.0105, 56.2502]}
				zoom={12}
				attributionControl={false}
				// onClick={mode === 'edit' ? handleMapClick : undefined}
			>
				<TileLayer
					// attribution={'&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{points.map((point, index) => (
					<Marker
						key={index}
						// icon={markerIcon}
						position={[point.lat, point.lng]}
						title={point.name}
					/>
				))}
			</MapContainer>
		</div>
	);
};

export default MapViewer;
