import { forwardRef, Ref } from 'react';
import * as React from 'react';
import {LayerSide} from 'react-laag';
import {AnimatePresence, motion, Variants} from 'framer-motion';

const outTransform = {
	top: {x: 0, y: 18},
	left: {x: 18, y: 0},
	bottom: {x: 0, y: -18},
	right: {x: -18, y: 0}
};

const defaultVariants: Variants = {
	initial: (layerSide: LayerSide) => ({
		opacity: 0,
		scale: 0.85,
		...(outTransform[layerSide] as Record<string, unknown>)
	}),
	visible: {
		opacity: 1,
		scale: 1,
		x: 0,
		y: 0,
		transition: {
			type: 'tween',
			ease: 'easeOut',
			duration: 0.15
		},
		transitionEnd: {
			willChange: 'initial'
		}
	},
	hidden: (layerSide: LayerSide) => ({
		opacity: 0,
		scale: 0.85,
		...(outTransform[layerSide] as Record<string, unknown>),
		pointerEvents: 'none',
		transition: {
			type: 'tween',
			ease: 'easeIn',
			duration: 0.12
		}
	})
};

function getTransformOrigin(layerSide: LayerSide, arrowStyle: React.CSSProperties) {
	const result: Record<string, string> = {};

	// Определение выравнивания по главной оси через layerSide
	switch (layerSide) {
		case 'top':
			result.originY = 'bottom';
			break;
		case 'bottom':
			result.originY = 'top';
			break;
		case 'left':
			result.originX = 'right';
			break;
		case 'right':
			result.originX = 'left';
			break;
	}

	// Определение выравнивания по второстепенной оси через стили стрелки
	const x = arrowStyle.left || arrowStyle.right;
	if (x && typeof x === 'number') {
		result.originX = `${x}px`;
	}
	const y = arrowStyle.top || arrowStyle.bottom;
	if (y && typeof y === 'number') {
		result.originY = `${y}px`;
	}

	return result;
}

interface IPopupAnimationProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	isOpen?: boolean;
	layerSide: LayerSide;
	arrowStyle: React.CSSProperties;
	animationVariants?: Variants;

	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
	onAnimationComplete?: () => void;
}

const PopupAnimation = (props: IPopupAnimationProps, ref?: Ref<HTMLDivElement>) => {
	const {
		className,
		style,
		children,
		isOpen,
		layerSide,
		arrowStyle,
		animationVariants = defaultVariants,
		onClick,
		onAnimationComplete
	} = props;

	return (
		<AnimatePresence
			initial={false}
			onExitComplete={onAnimationComplete}
		>
			{isOpen && (
				<motion.div
					className={className}
					style={{...style, ...getTransformOrigin(layerSide, arrowStyle)}}
					onClick={onClick}
					initial="initial"
					animate="visible"
					exit="hidden"
					variants={animationVariants}
					custom={layerSide}
					ref={ref}
				>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default forwardRef(PopupAnimation);
