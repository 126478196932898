import createReducer from '@tehzor/tools/utils/createReducer';
import {combineReducers} from 'redux';
import {
	TOGGLE_WEB_PUSH_ENABLED,
	TOGGLE_NOTIFICATIONS_VISIBLE
} from '@src/constants/settings/notifications';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

interface INotificationsSettingsState {
	isVisible: boolean;
	isWebPushEnabled: boolean;
}

const isVisible = createReducer<boolean>(false, {
	[TOGGLE_NOTIFICATIONS_VISIBLE]: (state, payload) =>
		(payload !== undefined && typeof payload === 'boolean' ? payload : !state)
});

const isWebPushEnabled = createReducer<boolean>(false, {
	[TOGGLE_WEB_PUSH_ENABLED]: (state, payload) =>
		(payload !== undefined && typeof payload === 'boolean' ? payload : !state),
	[CLEAR_STORE]: () => false
});

const notifications = combineReducers({
	isVisible,
	isWebPushEnabled
});

export {INotificationsSettingsState};
export default notifications;
