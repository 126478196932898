import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useMovingWarrantyClaimDialog} from '@src/components/MovingWarrantyClaimDialog/hooks/useMovingWarrantyClaimDialog';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useEditWarrantyClaimDialog} from '../../hooks/useEditWarrantyClaimDialog';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {useDeleteWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useDeleteWarrantyClaim';
import {useCallback} from 'react';

interface IDesktopMenuProps {
	warrantyClaim: IWarrantyClaim;
}

const DesktopMenu = ({warrantyClaim}: IDesktopMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const permissions = useWarrantyClaimsPermissions(warrantyClaim.objectId);
	const {mutate: deleteWarrantyClaim} = useDeleteWarrantyClaim();
	// Диалог переноса обращения
	const [moveDialog, openMoveDialog] = useMovingWarrantyClaimDialog(
		warrantyClaim.objectId,
		warrantyClaim
	);

	const [warrantyClaimDialog, openWarrantyClaimDialog] =
		useEditWarrantyClaimDialog(warrantyClaim);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.warrantyClaim.deleteTitle'),
		message: t('useConfirmDialog.warrantyClaim.deleteMessage', {id: warrantyClaim.number})
	});

	const handleDeleteClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			deleteWarrantyClaim({
				objectId: warrantyClaim.objectId,
				warrantyClaimId: warrantyClaim.id
			});
			goBack();
		}
	}, [
		getDeleteConfirmation,
		deleteWarrantyClaim,
		warrantyClaim.objectId,
		warrantyClaim.id,
		goBack
	]);

	const items = [];
	if (permissions.canMove) {
		items.push(
			<MenuItem
				key="move"
				icon={<i className="tz-long-arrow-24" />}
				onClick={openMoveDialog}
			>
				{t('warrantyClaimPage.actions.menu.menuItem.move')}
			</MenuItem>
		);
	}

	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit-20" />}
				onClick={openWarrantyClaimDialog}
			>
				{t('warrantyClaimPage.actions.menu.menuItem.edit')}
			</MenuItem>
		);
	}

	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={handleDeleteClick}
			>
				{t('warrantyClaimPage.actions.menu.menuItem.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
			{permissions.canMove && moveDialog}
			{permissions.canEdit && warrantyClaimDialog}
		</>
	) : null;
};

export default DesktopMenu;
