import * as types from '../constants';

/**
 * Изменяет смещение списка
 *
 * @param objectId id объекта
 * @param offset смещение
 */
export const changeOffset = (objectId: string, offset: number) => ({
	type: types.CHANGE_OFFSET,
	payload: {
		objectId,
		offset
	}
});

/**
 * Изменяет выбранные строки в таблице
 *
 * @param objectId id объекта
 * @param value массив id выбранных строк
 */
export const changeSelected = (objectId: string, value: string[]) => ({
	type: types.CHANGE_SELECTED,
	payload: {
		objectId,
		value
	}
});

/**
 * Изменяет текущий отображаемый объект в мобильной шахматке
 *
 * @param objectId id объекта для страницы
 * @param id id отображаемого объекта
 */
export const changeCurrentObject = (objectId: string, id: string) => ({
	type: types.CHANGE_CURRENT_OBJECT,
	payload: {objectId, id}
});

export * from './getList';
export * from './getSchema';
export * from './generate';
