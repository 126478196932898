import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import { useCallback, useState } from 'react';
import {PlanMovingDialog} from '../components/PlanMovingDialog';

export const usePlanMovingDialog = (plan?: IPlan) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClose = useCallback(() =>
		setIsOpen(false),
	[]);

	const dialog = plan ? (
		<PlanMovingDialog
			objectId={plan.objectId}
			planId={plan.id}
			open={isOpen}
			onClose={handleClose}
		/>
	)
	: null;

	return [
		dialog,
		setIsOpen
	] as [typeof dialog, typeof setIsOpen];
};
