import {makeWarrantyClaimAddRequest} from '@src/api/backend/warrantyClaim';
import {
	addWarrantyClaimActions,
	deleteWarrantyClaimActions
} from '@src/store/modules/entities/warrantyClaim/actions';
import {useQueryClient} from '@tanstack/react-query';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useAddChildren} from '../../hooks/useAddChildren';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {addTempFiles} from '../../utils/addTempFiles';
import {warrantyClaimsQueryKeys} from '../keys';
import {updateCachedState} from '@src/utils/updateCachedState';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {spacesQueryKeys} from '../../spaces/keys';

export interface IAddWarrantyClaimParams {
	key: string;
	objectId: string;
	links: IWarrantyClaim['links'] | undefined;
	fields: ISavingWarrantyClaim;
}
/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useWarrantyClaimsMutationDefaults = () => {
	const queryClient = useQueryClient();
	const [addProblems] = useAddChildren();
	const {updateEntity} = useUpdateEntity(warrantyClaimsQueryKeys);
	queryClient.setMutationDefaults(warrantyClaimsQueryKeys.add(), {
		mutationFn: async (params: IAddWarrantyClaimParams) => {
			const {objectId, links, fields, key} = params;
			await updateCachedState<IListWarrantyClaim>(
				warrantyClaimsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			return makeWarrantyClaimAddRequest(
				objectId,
				links,
				{
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments)
				},
				key
			);
		},
		onSuccess: async (newWarrantyClaim: IWarrantyClaim, {key}: IAddWarrantyClaimParams) => {
			await updateCachedState<IListWarrantyClaim>(
				warrantyClaimsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			addProblems(key, newWarrantyClaim.id, 'warrantyClaimId');
			await queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.list()});
			updateEntity<IWarrantyClaim>(
				newWarrantyClaim,
				deleteWarrantyClaimActions.success,
				addWarrantyClaimActions.success,
				key
			);
			await queryClient.refetchQueries({
				queryKey: warrantyClaimsQueryKeys.localList()
			});
			await queryClient.invalidateQueries({
				queryKey: warrantyClaimsQueryKeys.latest()
			});
			await queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.warrantyClaims()
			});
		},
		onError: async (_, {key}: IAddWarrantyClaimParams) => {
			await updateCachedState<IListWarrantyClaim>(
				warrantyClaimsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
		}
	});
};
