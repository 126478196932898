import { memo, useCallback } from 'react';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useContractsPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IDeleteContractParams} from '@src/api/cache/contracts/defaults/mutations/delete';

interface IMenuProps {
	contracts: IPreparedContract[];
	deleteContract: (params: IDeleteContractParams) => void;
	handleSelectedRowsClear: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const Menu = memo(({contracts, deleteContract, handleSelectedRowsClear}: IMenuProps) => {
	const {pushPath} = useChangePath();
	const {canDelete} = useContractsPermissions();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить договор',
		'Вы действительно хотите удалить договор?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const updateData = useAppUpdateMenuItem();

	const handleDeleteClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			for (const contract of contracts) {
				deleteContract({contractId: contract.id});
			}
			handleSelectedRowsClear();
			pushPath('/contracts/');
		}
	}, [contracts, deleteContract]);

	const items = [];

	items.push(updateData);

	if (canDelete && contracts.length) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDelete && deleteDialog}
		</>
	) : null;
});
