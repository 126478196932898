import {IGetWarrantyClaimStatusesResponse} from '@src/api/backend/warrantyClaimStatuses';
/*
 *Возвращает статусы для гарантийных обращений в виде массива, объекты с ключами индексов
 */
export const extractWarrantyClaimsStatusesAsArray = (data: IGetWarrantyClaimStatusesResponse) => {
	if (!data) return undefined;
	return data.allIds.map((id: string) => data.byId[id]);
};

/*
 *Возвращает статусы для гарантийных обращений в виде объекта
 */
export const extractWarrantyClaimsStatusesAsMap = (data: IGetWarrantyClaimStatusesResponse) =>
	data?.byId;
