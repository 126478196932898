import {memo} from 'react';
import {MobileFilter, TextField} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '../../EntitiesFilters/utils/makeFilterLabel';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {useTranslation} from 'react-i18next';

const calendarIcon = <i className="tz-calendar-20" />;

interface ICreationDateFilterMobileProps {
	objectId?: string;
	from?: Date;
	to?: Date;
	entity: string;
}

export const WorkAcceptancesAcceptDateFilterMobile = memo(
	({objectId, from, to, entity}: ICreationDateFilterMobileProps) => {
		const {t} = useTranslation();
		const handleExpand = useFilterPageTransition(objectId, entity, 'acceptancedate');

		return (
			<MobileFilter label={t('entitiesFilters.acceptancePeriodFilter.label')}>
				<TextField
					className="mobile-entities-filters__textfield"
					elementType="div"
					value={from && to ? makeDateFilterLabel('', from, to) : undefined}
					icon={calendarIcon}
					onClick={handleExpand}
				/>
			</MobileFilter>
		);
	}
);
