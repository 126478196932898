import declination from '@tehzor/tools/utils/declination';
import { memo } from 'react';

interface IContractsPageHeaderProps {
	total: number;
}

const words = ['договор', 'договора', 'договоров'];

export const ContractsPageHeader = memo(({
	total
}: IContractsPageHeaderProps) => (
	<div className="contracts__page-header">
		<p className="contracts__page-header-amount">
			{`Всего: ${total} ${declination(total, words)}`}
		</p>
	</div>
));