import {useMemo} from 'react';
import {formOwnerAcceptanceLink} from '@tehzor/tools/utils/links/formOwnerAcceptanceLink';
import {useOwnerAcceptancesBreadcrumbs} from './useOwnerAcceptancesBreadcrumbs';
import {useTranslation} from "react-i18next";
/**
 * Хук для хлебных крошек от "всех объектов" до передачи собственнику
 *
 * @param objectId id конечного объекта
 * @param ownerAcceptanceId id передачки
 * @param number номер помещения
 */
export function useOwnerAcceptanceBreadcrumbs(
	objectId: string,
	ownerAcceptanceId: string,
	number: number | undefined
) {
	const {t} = useTranslation();
	const res = useOwnerAcceptancesBreadcrumbs(objectId);
	return useMemo(
		() =>
			res.concat({
				label: number ? `${t('ownerAcceptancePage.useOwnerAcceptanceBreadcrumbs.label')} №${number}` :
					t('ownerAcceptancePage.useOwnerAcceptanceBreadcrumbs.label'),
				url: formOwnerAcceptanceLink(objectId, ownerAcceptanceId),
				inactive: true
			}),
		[objectId, ownerAcceptanceId, res]
	);
}
