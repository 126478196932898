import {queryClient} from '@src/api/QueryClient';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {makeResponsibilityRuleDeleteRequest} from '@src/api/backend/responsibilityRules';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {useMutation} from '@tanstack/react-query';

export interface IDeleteResponsibilityRulesParams {
	ruleId: string;
}

export const useDeleteResponsibilityRules = () =>
	useMutation({
		mutationFn: (params: IDeleteResponsibilityRulesParams) =>
			makeResponsibilityRuleDeleteRequest(params.ruleId),
		mutationKey: responsibilityRulesQueryKeys.delete(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
			addSuccessToast('Удалено', 'Правило успешно удалено');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при удалении правила');
		}
	});
