import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '@src/constants/settings/export';

interface IExportSettingsState {
	selectedTemplates: {
		[key: string]: string;
	};
	isEmailEnabled: boolean;
	createDocument: boolean;
	emailAddress: string;
}

const initialState = {
	selectedTemplates: {},
	isEmailEnabled: false,
	createDocument: false,
	emailAddress: ''
} as IExportSettingsState;

const exportReducer = createReducer<IExportSettingsState>(initialState, {
	[types.CHANGE_EXPORT_SELECTED_TEMPLATE]: (state, payload: {key: string, value: string}) => ({
		...state,
		selectedTemplates: {
			...state.selectedTemplates,
			[payload.key]: payload.value
		}
	}),
	[types.TOGGLE_EXPORT_EMAIL_ENABLED]: (state, payload?: boolean) => ({
		...state,
		isEmailEnabled: payload !== undefined ? payload : !state.isEmailEnabled
	}),
	[types.TOGGLE_EXPORT_MAKE_DOKUMENT]: (state, payload?: boolean) => ({
		...state,
		createDocument: payload !== undefined ? payload : !state.createDocument
	}),
	[types.CHANGE_EXPORT_EMAIL_ADDRESS]: (state, payload: string) => ({
		...state,
		emailAddress: payload
	})
});

export {IExportSettingsState};
export default exportReducer;
