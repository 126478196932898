import {addExportToast} from '@src/components/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {makeCheckExportRequest} from '@src/api/backend/check';
import {queryClient} from '@src/api/QueryClient';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import {checksDocumentsStatsQueryKeys} from '@src/api/cache/checksDocumentsStats/keys';

interface IExportCheckParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId: string;
	checkId: string;
	problems?: string[];
	inspections?: string[];
}

export const useExportCheck = () =>
	useMutation({
		mutationFn: (params: IExportCheckParams) => {
			const {templateId, createDocument, email, objectId, checkId, problems, inspections} =
				params;
			return makeCheckExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				checkId,
				problems,
				inspections
			);
		},
		onSuccess: (data, params) => {
			if (data.link) {
				addExportToast(
					params.email ? 'Документ отправлен' : 'Экспорт документа завершен',
					data.link,
					data.file.name,
					'',
					data.document
				);
			}
			if (data.warnings?.itemsLimit !== undefined) {
				addWarningToast(
					'Внимание',
					`превышен лимит записей (${String(data.warnings.itemsLimit)})`
				);
			}
			void queryClient.invalidateQueries({queryKey: documentsQueryKeys.lists()});
			void queryClient.invalidateQueries({queryKey: checksDocumentsStatsQueryKeys.lists()});
		},
		onError: () => {
			addErrorToast('Ошибка', 'не удалось выполнить экспорт');
		}
	});
