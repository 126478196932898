import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState} from './reducers';

/**
 * Возвращает настройки страницы для конкретного объекта
 */
export const extractCheckListsPageSettings = createSelector(
	(state: IState) => state.settings.pages.manage.checkLists,
	data => data || getInitialState()
);
