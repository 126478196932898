import { useMemo } from 'react';
import {LoadingPanel} from '@tehzor/ui-components';
import './ObjectsPage.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import classNames from 'classnames';
import {ObjectsDisplayMode} from '@src/interfaces/ObjectsDisplayMode';
import {extractObjectsPageSettings} from '@src/store/modules/settings/pages/objects/selectors';
import {ListPage} from './components/ListPage';
import {PlatesPage} from './components/PlatesPage';
import {flattenTree} from '@src/utils/tree';
import {ObjectsBreadcrumbs} from './components/breadcrumbs/ObjectsBreadcrumbs';
import {ObjectsMenu} from './components/menu/ObjectsMenu';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';
import {useIsFetching} from '@tanstack/react-query';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useTranslation} from "react-i18next";

/**
 * Страница списка объектов
 */
export const SubObjectsPage = () => {
	const {t} = useTranslation();

	const {objectId} = useStrictParams<{objectId: string}>();
	const sectionsMenu = useObjectSectionsMenu(objectId);

	useScrollRestoration();
	useObjectAppHeader(objectId, {sectionsMenu, mobileRightButtons: <ObjectsMenu />}, []);

	const treeObjects = useCurrentTreeObject(objectId)?.children || [];

	const text = useMemo(() => {
		const count = flattenTree(treeObjects).length;
		return `${t('objectsPage.count.label')}: ${count}`;
	}, [treeObjects]);

	const {displayMode = ObjectsDisplayMode.SCHEMA} = useAppSelector(extractObjectsPageSettings);
	const isLoading = !!useIsFetching({queryKey: objectsQueryKeys.list()});

	return (
		<LoadingPanel
			className={classNames('objects-page__loading-panel page-cont', {
				'sub-objects-page': sectionsMenu.length
			})}
			active={isLoading}
		>
			<>
				<ObjectsBreadcrumbs objectId={objectId} />
				{displayMode === ObjectsDisplayMode.SCHEMA && (
					<PlatesPage
						text={text}
						treeObjects={treeObjects}
						isSubPlates
					/>
				)}
				{displayMode === ObjectsDisplayMode.LIST && (
					<ListPage
						treeObjects={treeObjects}
						text={text}
					/>
				)}
			</>
		</LoadingPanel>
	);
};
