import {queryClient} from '@src/api/QueryClient';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type ICopyCheckListResponse = ICheckList;

/**
 * Копирует чек-лист
 *
 * @param id id копируемого Чек Листа
 */
export const makeCheckListCopyRequest = async (id: string) => {
	void queryClient.invalidateQueries({queryKey: checkListsQueryKeys.list()});
	void queryClient.invalidateQueries({queryKey: checkItemsQueryKeys.list()});

	const response = await httpRequests.withToken.post<ICopyCheckListResponse>('check-lists/copy', {
		id
	});
	return response.data;
};
