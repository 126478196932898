import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBaseStatus} from './IBaseStatus';

export function getSelectedStatus<S extends IBaseStatus>(
	value?: string,
	statuses?: INormalizedData<S>
) {
	if (!value || !statuses || !statuses.byId.hasOwnProperty(value)) {
		return undefined;
	}
	return statuses.byId[value];
}
