import {Tree} from 'array-to-tree';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

interface IResult {
	item: IComment;
	parent: IComment;
	showAppeal: boolean;
}

/**
 * Преобразует всех потомков в одномерный массив
 *
 * @param comment комментарий
 * @param showAppeal показывать ли обращение, необходимо для >1 уровней
 */
export function flattenDescendants(comment: Tree<IComment>, showAppeal = false): IResult[] {
	if (comment.children?.length) {
		return comment.children.reduce<IResult[]>((prev, item) => {
			prev.push({item, parent: comment, showAppeal});
			if (item.children?.length) {
				prev = prev.concat(flattenDescendants(item, true));
			}
			return prev;
		}, []);
	}
	return [];
}
