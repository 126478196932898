import {CellProps} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

export const StatusCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => {
	const {status} = row.original;
	const translatedStatus = useTranslatedDictionaryById(
		dictionaryKeys.ownerAcceptanceStatuses,
		status?.id
	);

	return (
		<div
			style={{color: status?.textColor}}
			className="owner-acceptances-page__d-table-status"
		>
			{translatedStatus}
		</div>
	);
};
