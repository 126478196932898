import {memo} from 'react';
import {EntityDesktopTasks} from '@src/components/EntityTasks';
import {useAddingProblemTaskDialog} from '../../hooks/useAddingProblemTaskDialog';
import {usePermissions} from '../../hooks/usePermissions';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';
import {useProblemTasksArray} from '@src/core/hooks/queries/tasks/problem/hooks';
import {EntityMobileTasks} from '@src/components/EntityTasks/EntityTasks.mobile';

interface EntityDesktopProblemTasksProps {
	objectId: string;
	problem: IPreparedProblem;
	isDesktop?: boolean;
}

export const EntityProblemTasks = memo((props: EntityDesktopProblemTasksProps) => {
	const {objectId, problem, isDesktop = false} = props;
	const problemPermissions = usePermissions(objectId, problem);
	const [addingTaskDialog, openAddingTaskDialog] = useAddingProblemTaskDialog(problem);
	const {data: tasks} = useProblemTasksArray(problem.id, {enabled: !problem.localNumber});

	return (
		<>
			{isDesktop && (
				<EntityDesktopTasks
					tasks={tasks}
					openAddingTaskDialog={openAddingTaskDialog}
				/>
			)}
			{!isDesktop && (
				<EntityMobileTasks
					tasks={tasks}
					openAddingTaskDialog={openAddingTaskDialog}
				/>
			)}
			{problemPermissions.canAddTask && addingTaskDialog}
		</>
	);
});
