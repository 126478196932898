import {queryClient} from '@src/api/QueryClient';
import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {store} from '@src/store/appStore';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';

export const getCachedCheckRecords = (
	checkListIds?: string[],
	objectsIds?: string[],
	spaceId?: string,
	workAcceptanceIds?: string[]
) => {
	const state = store.getState();
	const selectedObjects = state.settings.offlineMode.cache.objects;
	const checkRecordsCache = queryClient.getQueryData<INormalizedData<ICheckRecord>>([
		...checkRecordsQueryKeys.list(),
		undefined,
		selectedObjects
	]);
	if (checkRecordsCache) {
		const asArray = Object.entries(checkRecordsCache.byId);

		// Фильтруем по checkListIds
		const filteredByIds = asArray.filter(
			([, value]) =>
				checkListIds?.includes(value.links.checkListId) ||
				(value.links.checkItemId && checkListIds?.includes(value.links.checkItemId))
		);

		// Фильтруем по objectId
		const filteredByObjectId = objectsIds
			? filteredByIds.filter(([, value]) => objectsIds.includes(value.objectId))
			: filteredByIds;

		// Фильтруем по spaceId
		const filteredBySpaceId = spaceId
			? filteredByObjectId.filter(([, value]) => value.links.spaceId === spaceId)
			: filteredByObjectId;

		// Фильтруем по workAcceptanceIds
		const filterByWorkAcceptanceIds =
			workAcceptanceIds && workAcceptanceIds.length
				? filteredBySpaceId.filter(
						([, value]) =>
							value.links.workAcceptanceId &&
							workAcceptanceIds.includes(value.links.workAcceptanceId)
				  )
				: filteredBySpaceId;
		if (filterByWorkAcceptanceIds.length) {
			const byId = Object.fromEntries(filterByWorkAcceptanceIds);
			const allIds = checkRecordsCache.allIds.filter(id => Object.keys(byId).includes(id));
			return {
				byId,
				allIds
			} as INormalizedData<ICheckRecord>;
		}
		return undefined;
	}
	return undefined;
};
