import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetProblemsDocumentsStatsResponse} from '@src/api/backend/problemsDocumentsStats/get';
import {problemsDocumentsStatsQueryKeys} from '@src/api/cache/problemsDocumentsStats/keys';
import {IGetChecksDocumentsStatsResponse} from '@src/api/backend/checksDocumentsStats/get';
import {checksDocumentsStatsQueryKeys} from '@src/api/cache/checksDocumentsStats/keys';
import {useDocumentsPermissions} from '../../permissions/useDocumentsPermissions';

export const useProblemsDocumentsStats = (problemIds?: string[]) => {
	const permissions = useDocumentsPermissions();
	return useQuery<IGetProblemsDocumentsStatsResponse, IError>({
		queryKey: problemsDocumentsStatsQueryKeys.list(problemIds || []),
		meta: {
			error: 'при загрузке статистики по документам у нарушений'
		},
		enabled: permissions.canView && !!problemIds?.length
	});
};

export const useChecksDocumentsStats = (checkIds?: string[]) => {
	const permissions = useDocumentsPermissions();
	return useQuery<IGetChecksDocumentsStatsResponse, IError>({
		queryKey: checksDocumentsStatsQueryKeys.list(checkIds || []),
		meta: {
			error: 'при загрузке статистики по документам у проверок'
		},
		enabled: permissions.canView && !!checkIds?.length
	});
};
