import {StatsTreePanel} from '@tehzor/ui-components';
import {Tree} from 'array-to-tree';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import ProblemsStatsChart from './ProblemsStatsChart';
import {StatsMap} from '@src/utils/statsAggregators/problemsByStatuses';
import {
	useNavigateProblems,
	IUseNavigateProblemsProps
} from '@src/pages/StatisticsPage/hooks/useNavigateProblems';
import {sumStatsValues} from '@src/pages/StatisticsPage/utils/sumStatsValues';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';

interface ISubObjectStatsProps {
	data: Tree<IStatsObject>;
	stats: StatsMap;
	level?: number;
	navigateProps?: Array<{
		type: string;
		prop: string | string[];
	}>;
}

export const SubObjectStats = (props: ISubObjectStatsProps) => {
	const {data, stats, level = 0, navigateProps} = props;
	const objectStats = stats[data.id];

	const totalObjectCount = objectStats?.expired
		? sumStatsValues(objectStats) - objectStats?.expired
		: sumStatsValues(objectStats);

	const navigationSettings: IUseNavigateProblemsProps = {
		objectId: data.children ? undefined : data.id,
		page: 'warranty',
		navProps: navigateProps
			? [{type: 'objects', prop: data.id}, ...navigateProps]
			: [{type: 'objects', prop: data.id}]
	};
	if (data.children) {
		navigationSettings.navProps = navigateProps
			? [{type: 'objects', prop: data.id}, ...navigateProps]
			: [{type: 'objects', prop: data.id}];
		navigationSettings.page = 'warranty';
	}
	const {data: children} = useTargetObjects(data.id);
	const navigate = useNavigateProblems(navigationSettings, children);

	const handleClick = (status: string) => {
		navigate(status);
	};

	return (
		<StatsTreePanel
			id={data.id}
			title={data.name}
			image={data.image?.url}
			level={level}
			permanentContent={
				<ProblemsStatsChart
					onClick={handleClick}
					stats={objectStats}
					totalCount={totalObjectCount}
					level={level}
				/>
			}
			hiddenContent={data.children?.map(item => (
				<SubObjectStats
					key={item.id}
					data={item}
					stats={stats}
					level={level + 1}
					navigateProps={navigateProps}
				/>
			))}
		/>
	);
};
