import {RefObject, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {useDndCtx} from '../DndContext';
import {IDndDragState, IDndEvent, IDndItem, IDragPositionEvent} from '../interfaces';
import {IDndOverState} from '../interfaces/IDndOverState';
import {IPosition} from '../interfaces/IPositionRef';
import {
	calculateOverStatus,
	filterVisibleItems,
	getAllChildren,
	getDragEndItems,
	getElementData,
	getOverData,
	getOverElementByIdAttribute,
	isDragEndEvent,
	isDragOverEvent,
	isDragStartEvent,
	isPositionChanged,
	sortItems,
	startEventHandler
} from '../utils';
import {getDropContainer} from '../utils/getDropContainer';

export interface IDragEventsReturn<S> {
	draggableItems: S[];
	overState: IDndOverState<S>;
}

/**
 * Хук реализующий сортировку при помощи dnd
 *
 * @param items массив элементов
 * @param container название контейнера
 * @param containerRef ссылка на контейнер
 * @param onDragStart обработчик события dragStart
 * @param onDragOver обработчик события dragOver
 * @param onDragEnd обработчик события dragEnd
 * @param onDragCancel обработчик события dragCancel
 */
export const useDragEvents = <S extends IDndItem>(
	items: S[],
	container: string,
	containerRef: RefObject<HTMLElement>,
	onDragStart?: (event: IDndEvent<S>) => void,
	onDragOver?: (event: IDndEvent<S>) => void,
	onDragEnd?: (event: IDndEvent<S>) => void,
	onDragCancel?: (event: IDndEvent<S>) => void
): IDragEventsReturn<S> => {
	const {
		dragState,
		changeDragState,
		resetDragState,
		changeInitPosition,
		addHandler,
		treeOffset,
		changeDragContainers
	} = useDndCtx<S>();

	const [draggableItems, setDraggableItems] = useState(sortItems(items));
	const [overState, setOverState] = useState<IDndOverState<S>>({});
	const isTree = treeOffset !== undefined;

	// Обновляет и заново сортирует draggableItems с изменением items из props
	useUpdateEffect(() => {
		setDraggableItems(sortItems(items));
	}, [items]);

	const positionRef = useRef<IPosition>({});
	const overElement = useRef<HTMLElement>();

	// сохраняем ссылки на контейнеры для отслеживания места куда перемещаем
	useEffect(() => {
		if (!containerRef.current) {
			return;
		}
		changeDragContainers(container, containerRef.current);
	}, [container]);

	useLayoutEffect(() => {
		const changeOverState = (newState: IDndOverState<S>) => {
			setOverState(s => ({...s, ...newState}));
		};

		const resetOverState = () => {
			setOverState({});
		};

		const reset = () => {
			resetDragState();
			resetOverState();
			positionRef.current = {};
			overElement.current = undefined;
		};

		const handleDragCancel = () => {
			startEventHandler(onDragCancel, dragState, overState, draggableItems, container);
			reset();
		};

		const handleDragStart = (event: IDragPositionEvent) => {
			const {x, y} = event;
			if (!x || !y) {
				return;
			}

			const dragEl = getOverElementByIdAttribute(x, y, true);

			positionRef.current = {x, y};
			changeInitPosition(x, y);

			const dragData = getElementData(draggableItems, dragEl);
			if (!dragData?.id || !isDragStartEvent(dragData, container)) {
				return;
			}

			const dragChildren = getAllChildren(dragData.id, draggableItems);

			const newDragState: IDndDragState<S> = {
				initContainer: container,
				item: dragData.item,
				children: dragChildren,
				isDragging: true
			};

			const newOverState: IDndOverState<S> = {
				container,
				item: dragData.item,
				status: calculateOverStatus(event, dragEl, isTree)
			};

			changeDragState(newDragState);
			changeOverState(newOverState);
			startEventHandler(
				onDragStart,
				newDragState,
				newOverState,
				draggableItems,
				container,
				dragData.container
			);
		};

		const handleDragMove = (event: IDragPositionEvent) => {
			if (!isPositionChanged(event, dragState, positionRef)) {
				return;
			}

			const {x, y} = event;
			if (!x || !y) {
				handleDragCancel();
				return;
			}
			const {item: dragItem, children: dragChildren} = dragState;
			const {container: overContainer, item: overItem} = overState;
			const overTargetEl = getOverElementByIdAttribute(x, y);
			if (overTargetEl) {
				overElement.current = overTargetEl;
			}
			const filteredItems = filterVisibleItems(draggableItems, dragItem, dragChildren);
			const overData = getElementData(filteredItems, overElement.current);
			if (!isDragOverEvent(event, overData, container)) {
				resetOverState();
				return;
			}

			const newOverState: IDndOverState<S> = {
				status: calculateOverStatus(event, overElement.current, isTree),
				item: overData?.id === dragItem?.id ? dragItem : overData?.item
			};

			if (overContainer !== container) {
				newOverState.container = container;
			}

			changeOverState(newOverState);
			if (newOverState.item && newOverState.item.id !== overItem?.id) {
				startEventHandler(
					onDragOver,
					dragState,
					newOverState,
					draggableItems,
					container,
					overData?.container
				);
			}
		};

		const handleDragEnd = (event: IDragPositionEvent) => {
			const {x, y} = event;
			if (!x || !y) {
				handleDragCancel();
				return;
			}
			const {item: dragItem, children: dragChildren, containers} = dragState;
			const {item: overItem, container: overContainer, status} = overState;

			const dropTargetEl = getOverElementByIdAttribute(x, y);
			const dropContainer = getDropContainer(containers, x, y);
			const filteredItems = filterVisibleItems(draggableItems, dragItem, dragChildren);
			const dropData = getElementData(filteredItems, dropTargetEl);
			const overData = getOverData(filteredItems, overItem, overContainer);

			if (
				(!dropData && !overData && !dropContainer)
				|| !isDragEndEvent(dragState, container, overContainer)
			) {
				handleDragCancel();
				return;
			}

			const newItems = getDragEndItems(
				draggableItems,
				dragState,
				dropData || overData,
				status,
				container,
				dropContainer
			);

			startEventHandler(
				onDragEnd,
				dragState,
				overState,
				newItems,
				container,
				dragState.initContainer,
				dropData?.container || overData?.container
			);

			setDraggableItems(newItems);
			reset();
		};

		addHandler(handleDragCancel, 'dragCancelHandlers', container);
		addHandler(handleDragStart, 'dragStartHandlers', container);
		addHandler(handleDragMove, 'dragMoveHandlers', container);
		addHandler(handleDragEnd, 'dragEndHandlers', container);
	}, [
		dragState,
		overState,
		draggableItems,
		container,
		isTree,
		resetDragState,
		changeInitPosition,
		onDragStart,
		onDragOver,
		onDragEnd,
		onDragCancel,
		addHandler
	]);

	return {draggableItems, overState};
};