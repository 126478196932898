import {ITableSettings} from '@src/interfaces/ITableSettings';

export const getVisibleControlledColumnsLength = (tableSettings: ITableSettings) => {
	let counter = 0;
	for (const setting in tableSettings) {
		if (tableSettings[setting].isVisible) {
			counter += 1;
		}
	}
	return counter;
};
