import { memo } from 'react';
import * as React from 'react';
import './NotificationsButton.less';
import classNames from 'classnames';
import Button from '../Button';
import NotificationsIcon from '../../notifs/NotificationsIcon';

interface INotificationsButtonProps {
	className?: string;
	style?: React.CSSProperties;
	count?: number;
	label?: React.ReactNode;

	onClick?(): void;
}

const NotificationsButton = ({className, style, count = 0, label, onClick}: INotificationsButtonProps) => (
	<Button
		className={{
			root: classNames('notifications-button', className),
			wrap: 'notifications-button__wrap',
			label: 'notifications-button__label',
			leftIcon: 'notifications-button__icon'
		}}
		style={style}
		leftIcon={<NotificationsIcon count={count} />}
		label={label}
		onClick={onClick}
	/>
);

export default memo(NotificationsButton);

