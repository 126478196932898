import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export const useTranslatedDictionaryMap = <T extends {id: string}>(
	tKey: string,
	map?: Record<string, T>
): Record<string, T> => {
	const {t} = useTranslation();

	return useMemo<Record<string, T>>(() => {
		if (!map) {
			return {};
		}

		const translated: Record<string, T> = {};

		for (const [key, value] of Object.entries(map)) {
			translated[key] = {...value, name: t(`${tKey}.${value.id}`)};
		}

		return translated;
	}, [tKey, map, t]);
};
