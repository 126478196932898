import {wsConnector} from '../wsConnector';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export type IGetStructureResponse = IStructure;

/**
 * Получает структуру по id
 *
 * @param objectId id объекта
 * @param structureId id структуры
 */
export const requestStructure = (objectId: string, structureId: string) =>
	wsConnector.sendAuthorizedRequest<IGetStructureResponse>('getStructure', {
		objectId,
		structureId
	});
