import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetExportTemplatesResponse extends INormalizedData<IExportTemplate> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список шаблонов экспорта
 */
export const requestExportTemplates = async () => {
	const response = await httpRequests.withToken.get<IGetExportTemplatesResponse>(
		'export-templates'
	);
	return response.data;
};
