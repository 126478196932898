import {customAlphabet} from 'nanoid/async';

export class UniqueIdUtil {
	length?: number;

	generateShort: (size?: number | undefined) => Promise<string>;

	generateLong: (size?: number | undefined) => Promise<string>;

	constructor(length?: number) {
		this.length = length;
		this.generateShort = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 6);
		this.generateLong = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 21);
	}

	/**
	 * Генерирует 6 символьный id, состоящий из цифр и букв в нижнем регистре
	 */
	generateShortId(): Promise<string> {
		return this.generateShort(this.length);
	}

	/**
	 * Генерирует 21 символьный id, состоящий из цифр и букв в верхнем и нижнем регистре
	 */
	generateLongId(): Promise<string> {
		return this.generateLong(this.length);
	}
}
