import {matchPath, useLocation} from 'react-router-dom';

/**
 * Возвращает ключ текущей страницы
 */
export const usePageKey = () => {
	const location = useLocation();
	const path = location.pathname;
	if (matchPath({path: '/'}, path)) {
		return 'objects';
	}
	if (matchPath({path: '/objects/*'}, path)) {
		return 'objects';
	}
	if (matchPath({path: '/checks/*'}, path)) {
		return 'checks';
	}
	if (matchPath({path: '/work-acceptances/*'}, path)) {
		return 'workAcceptances';
	}
	if (matchPath({path: '/internal-acceptances/*'}, path)) {
		return 'internalAcceptances';
	}
	if (matchPath({path: '/owner-acceptances/*'}, path)) {
		return 'ownerAcceptances';
	}
	if (matchPath({path: '/problems/*'}, path)) {
		return 'problems';
	}
	if (matchPath({path: '/tasks/*'}, path)) {
		return 'tasks';
	}
	if (matchPath({path: '/inspections/*'}, path)) {
		return 'inspections';
	}
	if (matchPath({path: '/warranty-claims/*'}, path)) {
		return 'warrantyClaims';
	}
	if (matchPath({path: '/legals/*'}, path)) {
		return 'legals';
	}
	if (matchPath({path: '/contracts/*'}, path)) {
		return 'contracts';
	}
	if (matchPath({path: '/statistics/*'}, path)) {
		return 'statistics';
	}
	if (matchPath({path: '/documents/*'}, path)) {
		return 'documents';
	}
	if (matchPath({path: '/manage/categories-sets/*'}, path)) {
		return 'categoriesSets';
	}
	if (matchPath({path: '/manage/check-lists/*'}, path)) {
		return 'manage/check-lists';
	}
	if (matchPath({path: '/manage/working-groups/*'}, path)) {
		return 'manage/working-groups';
	}
	if (matchPath({path: '/manage/problem-tags-sets/*'}, path)) {
		return 'manage/problem-tags-sets';
	}
	if (matchPath({path: '/manage/space-type-decoration/*'}, path)) {
		return 'manage/space-type-decoration';
	}
	if (matchPath({path: '/manage/export-templates/*'}, path)) {
		return 'manage/export-templates';
	}
	if (matchPath({path: '/manage/space-statuses-sets/*'}, path)) {
		return 'manage/space-statuses-sets';
	}
	if (matchPath({path: '/manage/fields'}, path)) {
		return 'manage/fields';
	}
	return undefined;
};
