import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';

export type IGetListStructuresResponse = INormalizedData<IListStructure>;

/**
 * Возвращает список структур
 */
export const getStructuresListRequest = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>
) => {
	const response = await httpRequests.withToken.post<IGetListStructuresResponse>(
		'structures/get-list',
		{filters, sort: convertSortParam(sort)}
	);

	return response.data;
};
