import {IGetListProblemsResponse} from '@src/api/backend/problems';
import {CheckEntitiesVisibility} from '@src/store/modules/settings/pages/check/reducers/entitiesVisibility';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';

/**
 * Извлекает нарушения проверки
 */
export const extractCheckProblemsArray = (data: IGetListProblemsResponse) =>
	data.allIds.map(id => data.byId[id]);

/**
 * Извлекает локальные нарушения проверки
 */
export const extractCheckLocalProblemsArray = (data: IListProblem[], checkId: string) =>
	data.filter(problem => problem.links?.checkId === checkId);

export const extractCheckProblemsEntities = (
	data: IGetListProblemsResponse,
	visibility: CheckEntitiesVisibility[]
) => {
	const problems = extractCheckProblemsArray(data);
	if (visibility.includes('problems') && problems?.length) {
		return problems.map(item => ({
			id: `p_${item.id}`,
			type: 'problem',
			data: item
		}));
	}
	return [];
};

export const extractCheckLocalProblemsEntities = (
	data: IListProblem[],
	checkId: string,
	visibility: CheckEntitiesVisibility[]
) => {
	const problems = extractCheckLocalProblemsArray(data, checkId);
	if (visibility.includes('problems') && problems?.length) {
		return problems.map(item => ({
			id: `p_${item.id}`,
			type: 'problem',
			data: item
		}));
	}
	return [];
};
