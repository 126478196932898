import {memo} from 'react';
import {IPermissions} from '@src/pages/ObjectsPage/utils/usePermissionsForObject';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {Button} from '@tehzor/ui-components';
import {Menu} from './Menu';
import {useEditableObjectDialog} from '@src/components/EditableObjectDialog/hooks/useEditableObjectDialog';
import {useTranslation} from 'react-i18next';

interface IDesktopControlsProps {
	object: IObject;
	permissions: IPermissions;
}

const editIcon = <i className="tz-edit-16" />;

export const DesktopControls = memo(({object, permissions}: IDesktopControlsProps) => {
	const [objectEditDialog, openObjectEditDialog] = useEditableObjectDialog(object);
	const {t} = useTranslation();
	return (
		<div className="object-page__d-controls">
			{permissions.canEditObject && (
				<>
					<Button
						label={t('objectPage.controls.editButton.label')}
						leftIcon={editIcon}
						type="accent-blue"
						onClick={openObjectEditDialog}
					/>
					{objectEditDialog}
				</>
			)}
			<Menu
				object={object}
				permissions={permissions}
			/>
		</div>
	);
});
