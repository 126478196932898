import {EntityGridItem} from '@tehzor/ui-components';
import {ReactNode} from 'react';
import {EntityLinks} from '../EntityLinks/EntityLinks';
import {useTranslation} from 'react-i18next';

interface ILinksProps {
	links?: Record<string, string | undefined>;
	fullRow?: boolean;
	children?: ReactNode;
}

const linksIcon = <i className="tz-link-20" />;

export const Links = ({links, fullRow, children}: ILinksProps) => {
	const {t} = useTranslation();

	return (
		<EntityGridItem
			icon={linksIcon}
			fullRow={fullRow}
			label={t('components.entityLinks.header')}
		>
			<EntityLinks
				links={links}
				isMobile
			>
				{children}
			</EntityLinks>
		</EntityGridItem>
	);
};
