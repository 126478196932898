import {IGetDocumentResponse} from '@src/api/backend/document';
import {IGetListDocumentsResponse, IGetPaginateDocumentsResponse} from '@src/api/backend/documents';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import {
	IDocumentsFiltersState,
	IDocumentsSortState
} from '@src/store/modules/settings/pages/documents/reducers';
import {extractDocumentsPageSettings} from '@src/store/modules/settings/pages/documents/selectors';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useDocumentsPermissions} from '../../permissions/useDocumentsPermissions';
import useAppSelector from '../../useAppSelector';
import {
	extractDocumentsListArray,
	extractDocumentsListFilteredArray,
	extractDocumentsListMap,
	extractDocumentsPaginateArray,
	extractDocumentsPaginateMap
} from './selectors';

export const useDocumentsPaginate = <T = IGetPaginateDocumentsResponse>(
	select?: (data: IGetPaginateDocumentsResponse) => T
) => {
	const permissions = useDocumentsPermissions();
	const {filters, sort, offset, pageSize} = useAppSelector(extractDocumentsPageSettings);

	return useQuery<IGetPaginateDocumentsResponse, IError, T>({
		queryKey: documentsQueryKeys.paginate(filters, sort, offset, pageSize),
		meta: {
			error: 'при загрузке документов'
		},
		enabled: permissions.canView,
		select
	});
};

export const useDocumentsList = <T = IGetListDocumentsResponse>(
	filters?: IDocumentsFiltersState,
	sort?: IDocumentsSortState,
	select?: (data: IGetListDocumentsResponse) => T
) => {
	const permissions = useDocumentsPermissions();
	return useQuery<IGetListDocumentsResponse, IError, T>({
		queryKey: documentsQueryKeys.list(filters, sort),
		meta: {
			error: 'при загрузке списка документов'
		},
		enabled: permissions.canView,
		select
	});
};

export const useDocument = <T = IGetDocumentResponse>(
	documentId?: string,
	select?: (data: IGetDocumentResponse) => T
) => {
	const permissions = useDocumentsPermissions();

	return useQuery<IGetDocumentResponse, IError, T>({
		queryKey: [...documentsQueryKeys.details(), documentId],
		meta: {
			error: 'при загрузке документа'
		},
		enabled: permissions.canView && !!documentId,
		select
	});
};

export const useDocumentsPaginateArray = () => useDocumentsPaginate(extractDocumentsPaginateArray);

export const useDocumentsPaginateMap = () => useDocumentsPaginate(extractDocumentsPaginateMap);

export const useDocumentsListArray = (
	filters?: IDocumentsFiltersState,
	sort?: IDocumentsSortState
) => useDocumentsList(filters, sort, extractDocumentsListArray);

export const useDocumentsListMap = (filters?: IDocumentsFiltersState, sort?: IDocumentsSortState) =>
	useDocumentsList(filters, sort, extractDocumentsListMap);

/**
 * Функция фильтрует документы по ids и возвращает результат
 *
 * @param id Id сущности к которой привязан документ
 */
export const useDocumentsListFilteredArray = (id?: string) =>
	useDocumentsList(undefined, undefined, data => extractDocumentsListFilteredArray(data, id));
