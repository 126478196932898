import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {
	IConvertedCheckItemData,
	IConvertedCheckListData
} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';

const isEveryCheckListIncludesTypeDecoration = (
	checkListIds: string[],
	checkLists?: INormalizedData<ICheckList>
): boolean =>
	checkListIds.every(checkListId => {
		const checkList = checkLists?.byId?.[checkListId];

		return !!(checkList && checkList.typeDecoration && checkList.typeDecoration?.length > 0);
	});

const isAnyCheckListIncludesTypeDecorationAsInSpace = (
	checkListIds: string[],
	space: IEnrichedSpace | IConvertedSpace | IListSpace,
	checkLists?: INormalizedData<ICheckList>
): boolean =>
	checkListIds?.some(checkListId => {
		const checkList = checkLists?.byId?.[checkListId];

		return !!(
			checkList?.typeDecoration &&
			space?.typeDecoration &&
			checkList.typeDecoration?.includes(space.typeDecoration)
		);
	});

export const filterCheckLists = (
	checkLists: INormalizedData<ICheckList>,
	companyId: string,
	objectId: string,
	spaceType: SpaceTypeId,
	selectedStage: ObjectStageIds | undefined,
	selectedCheckLists: string[] | undefined,
	spaceTypeDecoration: string | undefined = undefined
): ICheckList[] =>
	checkLists.allIds.reduce<ICheckList[]>((prev, id) => {
		if (!selectedCheckLists || selectedCheckLists.includes(id)) {
			const checkList = checkLists.byId[id];
			if (checkList) {
				if (
					checkList.companyId === companyId &&
					checkList.objects?.includes(objectId) &&
					checkList.stage === selectedStage &&
					checkList.type === CheckListTypeId.OPERATIONS &&
					((checkList.spaceTypes && checkList.spaceTypes.length === 0) ||
						checkList.spaceTypes?.includes(spaceType)) &&
					(checkList.typeDecoration?.length === 0 ||
						(spaceTypeDecoration &&
							checkList.typeDecoration?.includes(spaceTypeDecoration)))
				) {
					prev.push(checkList);
					return prev;
				}
				if (
					checkList.companyId === companyId &&
					checkList.type === CheckListTypeId.OPERATIONS &&
					(!checkList.objects ||
						checkList.objects.length === 0 ||
						checkList.objects.includes(objectId)) &&
					(!selectedStage || checkList.stage === selectedStage) &&
					(!checkList.spaceTypes ||
						checkList.spaceTypes.length === 0 ||
						checkList.spaceTypes.includes(spaceType)) &&
					(checkList.typeDecoration?.length === 0 ||
						(spaceTypeDecoration &&
							checkList.typeDecoration?.includes(spaceTypeDecoration)))
				) {
					prev.push(checkList);
				}
			}
		}
		return prev;
	}, []);

export const filterSpace = (
	space: IConvertedSpace | IListSpace,
	spaceIndicators: string[],
	spaceStatus: string | undefined,
	spaceProblems: string[],
	filters: ISpacesFiltersState,
	checkLists?: INormalizedData<ICheckList>,
	spaceWorkAcceptances?: string[]
): boolean => {
	const {
		statuses,
		indicators,
		problemStatuses,
		types,
		objects,
		checkListStatuses,
		checkListCategoryStatuses,
		checkListIds,
		typeDecoration,
		workAcceptanceStatuses
	} = filters;

	if (statuses && spaceStatus) {
		if (!statuses.includes(spaceStatus)) {
			return false;
		}
	}
	if (indicators) {
		if (!spaceIndicators.some(indicator => indicators.includes(indicator))) {
			return false;
		}
	}
	if (problemStatuses && space.problems) {
		if (!spaceProblems.some(problemData => problemStatuses.includes(problemData))) {
			return false;
		}
	}
	if (types) {
		if (!types.includes(space.type)) {
			return false;
		}
	}

	if (objects && objects.length > 0) {
		if (!objects.includes(space.objectId)) {
			return false;
		}
	}
	if (typeDecoration) {
		if (!(space.typeDecoration && typeDecoration.includes(space.typeDecoration))) {
			return false;
		}
	}
	// Если выбраны чек-листы, и все из них имеют тип отделки, то оставить
	// только те помещения у которых совпадают типы отделки с чек-листами
	if (checkListIds && checkListIds?.length > 0) {
		const isEvery = isEveryCheckListIncludesTypeDecoration(checkListIds, checkLists);

		if (isEvery) {
			const isAny = isAnyCheckListIncludesTypeDecorationAsInSpace(
				checkListIds,
				space,
				checkLists
			);

			if (!isAny) {
				return false;
			}
		}
	}
	if (checkListStatuses && checkListStatuses.length > 0 && checkLists) {
		if (Array.isArray(space.checkLists)) {
			if (
				!space.checkLists?.some((spaceCheeckList: IConvertedCheckListData) =>
					checkListStatuses.includes(spaceCheeckList.status)
				)
			) {
				return false;
			}
		}
	}
	if (checkListCategoryStatuses && checkListCategoryStatuses.length > 0 && checkLists) {
		if (Array.isArray(space.checkLists)) {
			if (
				space.checkLists[0] &&
				!space.checkLists[0].items.some((spaceCheeckItem: IConvertedCheckItemData) =>
					checkListCategoryStatuses.includes(spaceCheeckItem.status)
				)
			) {
				return false;
			}
		}
	}
	if (workAcceptanceStatuses && workAcceptanceStatuses.length > 0 && spaceWorkAcceptances) {
		if (
			!spaceWorkAcceptances.some(workAcceptanceData =>
				workAcceptanceStatuses.includes(workAcceptanceData)
			)
		) {
			return false;
		}
	}

	return true;
};
