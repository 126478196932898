import {AppInfo} from '@tehzor/ui-components';
import config from '@src/core/config';
import {useTranslation} from "react-i18next";

const {appName, appVersion} = config;

const AppSidebarInfo = () => {
	const {t} = useTranslation()

	return (
		<AppInfo
			name={appName}
			version={appVersion}
			company={t('sidebar.info.company')}
			startYear={2017}
		/>
	);
};

export default AppSidebarInfo;
