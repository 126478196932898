import * as types from './constants';
import {IInternalAcceptanceAddingEntityType} from './reducers/entityType';

/**
 * Меняет выбранную сущность для добавления в internalAcceptance
 *
 * @param value новое значение
 */
export const changeInternalAcceptanceAddingEntityType = (value: IInternalAcceptanceAddingEntityType) => ({
	type: types.CHANGE_ENTITY_TYPE,
	payload: value
});
