import {EntityGridItem} from '@tehzor/ui-components';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {useTranslation} from "react-i18next";

interface IDescriptionProps {
	className?: string;
	start?: number;
	stop?: number;
}

export const IntervalView = ({className, start, stop}: IDescriptionProps) => {
	const {t} = useTranslation();

	return (
		<EntityGridItem
			className={className}
			label={t('checkListPage.detail.taskInfoMobileCell.intervalView.label')}
			inline
		>
			<div>
				<span>{start && format(start, dateTimeFormat)}</span>
				<span>{start && stop && ' – '}</span>
				<span>{stop && format(stop, dateTimeFormat)}</span>
			</div>
		</EntityGridItem>
	)
};
