import {updateCachedState} from '@src/utils/updateCachedState';
import {useQueryClient} from '@tanstack/react-query';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {ISavingBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {addErrorToast} from '@src/utils/toasts';
import {bankAccountsQueryKeys} from '../keys';
import {IAddBankAccountResponse, makeBankAccountAddRequest} from '@src/api/backend/bankAccount/add';
import {
	IEditBankAccountResponse,
	makeBankAccountEditRequest
} from '@src/api/backend/bankAccount/edit';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export interface IAddBankAccountParams {
	fields: ISavingBankAccount;
}

export interface IEditBankAccountParams {
	bankAccountId: string;
	fields: ISavingBankAccount;
}

export const useBankAccountsMutationDefaults = () => {
	const queryClient = useQueryClient();
	const key = Date.now().toString(10);

	queryClient.setMutationDefaults(bankAccountsQueryKeys.add(), {
		onMutate: (variables: IAddBankAccountParams) => ({variables}),
		mutationFn: async (params: IAddBankAccountParams) => {
			await updateCachedState<ILegalEntity>(
				bankAccountsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			const {fields} = params;
			return makeBankAccountAddRequest(fields);
		},
		onSuccess: async (data: IAddBankAccountResponse) => {
			await updateCachedState<ILegalEntity>(
				bankAccountsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			// Удаляем кешированные данные локального юрлица
			queryClient.removeQueries({queryKey: bankAccountsQueryKeys.savingData(key)});

			await queryClient.refetchQueries({
				queryKey: bankAccountsQueryKeys.localList()
			});
			await queryClient.invalidateQueries({queryKey: bankAccountsQueryKeys.list()});

			return data;
		},
		onError: async () => {
			addErrorToast('Ошибка', 'не удалось добавить реквизиты');
			await updateCachedState<ILegalEntity>(
				bankAccountsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);

			return undefined;
		},
		retry: 1,
		retryDelay: 10 * 1000
	});

	queryClient.setMutationDefaults(bankAccountsQueryKeys.edit(), {
		mutationFn: async ({bankAccountId, fields}: IEditBankAccountParams) => {
			await queryClient.cancelQueries({
				queryKey: bankAccountsQueryKeys.detail(bankAccountId)
			});
			return makeBankAccountEditRequest(bankAccountId, fields);
		},
		onSuccess: async (
			data: IEditBankAccountResponse,
			{bankAccountId}: IEditBankAccountParams
		) => {
			await queryClient.invalidateQueries({
				queryKey: bankAccountsQueryKeys.detail(bankAccountId)
			});
			await queryClient.invalidateQueries({queryKey: bankAccountsQueryKeys.list()});

			return data;
		},
		onError: async () => {
			addErrorToast('Ошибка', 'не удалось изменить реквизиты');
			await updateCachedState<ILegalEntity>(
				bankAccountsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);

			return undefined;
		}
	});
};
