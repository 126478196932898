import * as React from 'react';
import Portal from '../../containers/Portal';
import {AnimatePresence, motion, Variants} from 'framer-motion';
import Overlay from '../../containers/Overlay';

import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

interface IDialogBaseState {
	isInDom: boolean;
}
interface IDialogBaseProps {
	className?:
		| string
		| {
				portal?: string;
				overlay?: string;
				dialog?: string;
				layer?: string;
				content?: string;
		  };
	style?: React.CSSProperties;
	contentStyle?: React.CSSProperties;
	children?: React.ReactNode;
	isOpen: boolean;
	appRoot?: HTMLElement;
	animations?: {
		overlay?: Variants;
		dialog?: Variants;
		content?: Variants;
	};
	shouldFocusAfterRender?: boolean;
	shouldCloseOnOverlayClick?: boolean;
	shouldCloseOnEsc?: boolean;
	// shouldReturnFocusAfterClose?: boolean;
	shouldDisableScroll?: boolean;
	role?: string;
	noBodyScrollLock?: boolean;
	onRequestClose?: (event?: React.SyntheticEvent) => void;
	onBeforeOpen?: () => void;
	onAfterOpen?: () => void;
	onBeforeClose?: () => void;
	onAfterClose?: () => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
	getScrollableElement?: () => HTMLElement | null;
	dataTestId?: string;
}

class DialogBase extends React.Component<IDialogBaseProps, IDialogBaseState> {
	static defaultProps = {
		shouldFocusAfterRender: true,
		shouldCloseOnOverlayClick: true,
		shouldCloseOnEsc: true,
		// shouldReturnFocusAfterClose: true,
		shouldDisableScroll: true,
		role: 'dialog'
	};

	constructor(props: IDialogBaseProps | Readonly<IDialogBaseProps>) {
		super(props);
		// этот флаг нужен чтобы убирать диалог из DOM после того как выполнятся все методы жизненного цикла,
		this.state = {
			isInDom: false
		};
	}

	private _dialog = React.createRef<HTMLDivElement>();

	componentDidUpdate(prevProps: IDialogBaseProps) {
		if (this.props.isOpen && !prevProps.isOpen) {
			this.setState({isInDom: true});
			if (this.props.shouldDisableScroll && !prevProps.noBodyScrollLock) {
				this._disableScroll();
			}
			if (this.props.shouldFocusAfterRender) {
				this._focusDialog();
			}
		}

		if (!this.props.isOpen && prevProps.isOpen) {
			if (this.props.shouldDisableScroll && !prevProps.noBodyScrollLock) {
				this._enableScroll();
			}
		}
	}

	componentWillUnmount() {
		if (this.props.shouldDisableScroll && !this.props.noBodyScrollLock) {
			this._enableScroll();
		}
		this.setState({isInDom: false});
	}

	render() {
		const {
			style,
			children,
			isOpen,
			appRoot,
			animations,
			shouldCloseOnOverlayClick,
			role,
			contentStyle,
			dataTestId
		} = this.props;

		const classes = this._getClasses();
		const {isInDom} = this.state;
		return isOpen || isInDom ? (
			<Portal
				className={classes.portal}
				root={appRoot}
			>
				<AnimatePresence initial={false}>
					{isOpen && (
						<Overlay
							className={classes.overlay}
							variants={animations?.overlay}
							onClick={shouldCloseOnOverlayClick ? this._close : undefined}
							onAnimationStart={this._handleAnimationStart}
							onAnimationComplete={this._handleAnimationComplete}
						>
							<div
								key="dialog"
								className={classes.dialog}
								style={style}
								tabIndex={-1}
								role={role}
								onKeyDown={this._handleKeyDown}
								ref={this._dialog}
							>
								<motion.div
									className={classes.layer}
									variants={animations?.dialog}
								>
									<motion.div
										data-testid={dataTestId}
										key="content"
										className={classes.content}
										style={contentStyle}
										variants={animations?.content}
									>
										{children}
									</motion.div>
								</motion.div>
							</div>
						</Overlay>
					)}
				</AnimatePresence>
			</Portal>
		) : null;
	}

	private _close = (event?: React.SyntheticEvent) => {
		if (this.props.onRequestClose) {
			this.props.onRequestClose(event);
		}
	};

	private _handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (this.props.shouldCloseOnEsc && event.key === 'Escape') {
			event.stopPropagation();
			this._close();
		}
		if (this.props.onKeyDown) {
			this.props.onKeyDown(event);
		}
	};

	private _focusDialog = () => {
		if (this._dialog.current && !this._dialogHasFocus()) {
			this._dialog.current.focus({preventScroll: true});
		}
	};

	private _dialogHasFocus = () =>
		document.activeElement === this._dialog.current ||
		(!!this._dialog.current && this._dialog.current.contains(document.activeElement));

	/**
	 * Блокирует скролл body
	 */
	private _disableScroll = () => {
		const element = this._getScrollableElement();
		if (element) {
			disableBodyScroll(element);
		}
	};

	/**
	 * Разблокировывает скролл body
	 */
	private _enableScroll = () => {
		const element = this._getScrollableElement();
		if (element) {
			enableBodyScroll(element);
		}
	};

	private _getScrollableElement = () => {
		if (this.props.getScrollableElement) {
			const element = this.props.getScrollableElement();
			if (element) {
				return element;
			}
		}
		return this._dialog.current;
	};

	private _handleAnimationStart = () => {
		if (this.props.isOpen) {
			if (this.props.onBeforeOpen) {
				setTimeout(this.props.onBeforeOpen, 0);
			}
		} else if (this.props.onBeforeClose) {
			setTimeout(this.props.onBeforeClose, 0);
		}
	};

	private _handleAnimationComplete = () => {
		if (this.props.isOpen) {
			if (this.props.onAfterOpen) {
				setTimeout(this.props.onAfterOpen, 0);
			}
		} else if (this.props.onAfterClose) {
			setTimeout(this.props.onAfterClose, 0);
		}
		if (!this.props.isOpen) {
			this.setState({isInDom: false});
		}
	};

	private _getClasses = () => {
		const {className} = this.props;
		if (!className) {
			return {};
		}
		if (typeof className === 'string') {
			return {dialog: className};
		}
		return className;
	};
}

export default DialogBase;
