import {IWorkAcceptancesStatsDataRequest} from '@src/interfaces/IWorkAcceptancesStatsData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

export type IGetWorkAcceptanceProblemsResponse = IEntityProblemsData;

/**
 * Возвращает статистику нарушений по приемкам работ
 */
export const requestWorkAcceptancesProblemsData = async (
	requestData: IWorkAcceptancesStatsDataRequest
) => {
	const response = await httpRequests.withToken.post<IGetWorkAcceptanceProblemsResponse>(
		'/work-acceptances/problems-stats',
		{...requestData}
	);
	return response.data;
};
