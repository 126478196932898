import {EntityGridItem, StatusSelect} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editProblemStatus} from '@src/store/modules/pages/problem/actions';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {useProblemStatusPermissions} from '@src/core/hooks/permissions/useProblemStatusPermissions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IPopupBasicProps} from '@tehzor/ui-components/src/components/containers/Popup';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useTranslation} from 'react-i18next';
import {useTranslateProblemStatuses} from '@src/core/hooks/useTranslateProblemStatuses';
import {ProblemReopenedTimes} from '@src/components/ProblemReopenedTimes';

const popupProps: IPopupBasicProps = {placement: 'bottom-end', preferX: 'left'};

interface IStatusProps {
	objectId: string;
	problem: IPreparedProblem;
}

const Status = ({objectId, problem}: IStatusProps) => {
	const {t} = useTranslation();

	const statuses = useTranslateProblemStatuses();
	const dispatch = useAppDispatch();

	const permissions = useProblemStatusPermissions(objectId, problem);

	const [, handleChange] = useAsyncFn(
		async (v: ProblemStatusId) => {
			await dispatch(editProblemStatus(objectId, problem.id, v));
			await queryClient.refetchQueries({queryKey: [...objectsQueryKeys.stats(), [objectId]]});
		},
		[objectId, problem.id]
	);

	return (
		<EntityGridItem
			className="problem-page__status"
			icon={<i className="tz-status-20" />}
			label={t('problemPage.info.status.label')}
			inline
			fullRow
		>
			<StatusSelect
				statuses={statuses}
				availableStatuses={permissions.availableStatuses}
				disabled={!permissions.canEditStatus}
				value={problem.status}
				popupProps={popupProps}
				onChange={handleChange}
			/>
			{!!problem.reopenedTimes && (
				<ProblemReopenedTimes reopenedTimes={problem.reopenedTimes} />
			)}
		</EntityGridItem>
	);
};

export default Status;
