import {AxiosResponse} from 'axios';
import {WrongHttpHeaderError} from '@tehzor/tools/errors/WrongHttpHeaderError';

/**
 * Извлекает имя, файл и другие данные из ответа
 *
 * @param response ответ
 * @deprecated
 */
export function formExportResponse(response: AxiosResponse<Blob>): {
	fileName: string;
	fileContent: Blob;
	warnings?: Record<string, unknown>;
} {
	// Получение имени файла из http-заголовка Content-Disposition
	const dispositionHeader = (response.headers as Record<string, string>)['content-disposition'];
	if (dispositionHeader) {
		const match = /filename="(.*)"/.exec(dispositionHeader);
		if (match && match.length > 1) {
			const {warnings} = response.headers as Record<string, string>;
			return {
				fileName: decodeURIComponent(match[1]),
				fileContent: response.data,
				warnings: warnings ? (JSON.parse(warnings) as Record<string, unknown>) : undefined
			};
		}
	}
	throw new WrongHttpHeaderError('Wrong http header "Content-Disposition"');
}
