import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {ISuggestions} from '@tehzor/tools/utils/responsibilityRules/ISuggestions';
import {getSuggestionsByRules} from '@tehzor/tools/utils/responsibilityRules/getSuggestionsByRules';
import {Tree} from 'array-to-tree';
import {useMemo} from 'react';

export const useSuggestionsByRules = (
	groups: Array<Tree<IWorkingGroup>>,
	rules: IResponsibilityRule[],
	ruleParams: IRuleParams = {}
): [ISuggestions, string | undefined, string[]] =>
	useMemo(() => getSuggestionsByRules(groups, rules, ruleParams), [groups, rules, ruleParams]);
