import {IBuildingStatisticsFiltersState} from '@src/store/modules/settings/pages/statistics/building/reducers/filters';
import {IStatsSourcesState} from '@src/store/modules/statistics/sources/reducers';
import {ITagDataItem} from '@tehzor/ui-components/src/components/tags';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

function handleContractors(
	value: IBuildingStatisticsFiltersState['contractors'],
	sources: IStatsSourcesState,
	usersSource: INormalizedData<IBriefUser>,
	contractorsSource?: INormalizedData<ICompany>
) {
	const contractors: ITagDataItem[] = [];
	const users: Map<string, ITagDataItem> = new Map();

	for (const id in value) {
		if (value.hasOwnProperty(id)) {
			if (typeof value[id] === 'boolean' && value[id]) {
				const contractor = contractorsSource?.byId[id];
				contractors.push({id, label: contractor?.name || id});
			} else if (Array.isArray(value[id])) {
				for (const userId of value[id] as string[]) {
					const user = usersSource.byId[userId];
					if (user && !users.has(userId)) {
						users.set(userId, {id: userId, label: user?.fullName || userId});
					}
				}
			}
		}
	}
	return {
		contractors,
		contractorsUsers: Array.from(users.values())
	};
}

/**
 * Формирует данные для отображения в строке выбранных фильтров
 *
 * @param filters выбранные фильтры
 * @param sources источники данных
 * @param storeObjects объекты
 * @param usersSource пользователи
 * @param storeCompanies компании
 * @param contractorsSource подрядчики
 */
export const makeTagsData = (
	filters: IBuildingStatisticsFiltersState,
	sources: IStatsSourcesState,
	storeObjects: INormalizedData<IObject>,
	usersSource: INormalizedData<IBriefUser>,
	storeCompanies?: INormalizedData<ICompany>,
	contractorsSource?: INormalizedData<ICompany>,
) => ({
	companies: filters.companies?.map(id => {
		const item = storeCompanies?.byId[id];
		if (item) {
			return {id, label: item.name};
		}
		return {id, label: id};
	}),
	objects: filters.objects
		// Фильтрация объектов, которые являются вложенными у выбранных родительских объектов или компаний
		?.filter(id => {
			const object = storeObjects.byId[id];
			if (object) {
				if (object.parentId) {
					if (filters.objects?.includes(object.parentId)) {
						return false;
					}
				} else if (filters.companies?.includes(object.companyId)) {
					return false;
				}
				return true;
			}
			return false;
		})
		.map(id => ({id, label: storeObjects.byId[id].name})),
	users: filters.users?.map(id => {
		const item = usersSource.byId[id];
		if (item) {
			return {id, label: item.fullName};
		}
		return {id, label: id};
	}),
	...handleContractors(filters.contractors, sources, usersSource, contractorsSource)
});
