import {TempFileDestination} from '@tehzor/tools/enums/TempFileDestination';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import ICanvasData from '@tehzor/tools/interfaces/ICanvasData';
import ITempFile from '@tehzor/tools/interfaces/ITempFile';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import generateKey from '@tehzor/tools/utils/generateKey';

export const convertToUpload = (tempFile: ITempFile, blobData: Blob) => {
	if (tempFile.full?.url) {
		const file = new File([blobData], tempFile.full.name, {type: 'image/jpeg'});
		const upl: IUploadingFile = {
			status: UploadingFileStatus.FINISHED,
			destination: TempFileDestination.ATTACHMENT,
			progress: 100,
			key: generateKey('file'),
			tempFile,
			type: file.type,
			size: file.size,
			name: tempFile.full.name,
			original: file,
			url: tempFile.full.url
		};
		return upl;
	}
	return undefined;
};

export const convertFileToUpload = (
	editedBlob: Blob,
	drawedData?: ICanvasData,
	originalBlob?: Blob
) => {
	const file = new File([editedBlob], `anime${Date.now()}.jpg$`, {type: 'image/jpeg'});
	const upl: IUploadingFile = {
		status: UploadingFileStatus.FINISHED,
		destination: TempFileDestination.ATTACHMENT,
		progress: 100,
		key: generateKey('file'),
		type: file.type,
		size: file.size,
		name: 'anyname',
		original: file,
		url: URL.createObjectURL(editedBlob)
	};
	if (drawedData && originalBlob) {
		upl.canvas = {
			drawedData,
			originalFile: new File([originalBlob], `anime${Date.now() + 1}.jpg$`, {
				type: 'image/jpeg'
			})
		};
	}
	return upl;
};
