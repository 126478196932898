import {queryClient} from '@src/api/QueryClient';
import {
	IAddSpaceOwnerParams,
	IEditSpaceOwnerParams
} from '@src/api/cache/spaceOwners/defaults/useSpaceOwnersMutationDefaults';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import IError from '@tehzor/tools/interfaces/IError';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

// Функция обновляет мутацию в зависимости от ключа edit или add
export const updateSpaceOwnerMutationState = (
	mutationKey: () => string[],
	spaceOwnerId: string,
	savingData?: ISpaceOwner
) => {
	const mutationCache = queryClient.getMutationCache();
	const currentMutation = mutationCache.find<
		ISpaceOwner,
		IError,
		IAddSpaceOwnerParams | IEditSpaceOwnerParams,
		unknown
	>({
		mutationKey: mutationKey(),
		predicate: mut => {
			let cacheId;
			if (mutationKey().join() === spaceOwnersQueryKeys.add().join()) {
				cacheId = (mut.state.variables as IAddSpaceOwnerParams).key;
			}
			if (mutationKey().join() === spaceOwnersQueryKeys.edit().join()) {
				cacheId = (mut.state.variables as IEditSpaceOwnerParams).spaceOwnerId;
			}
			return spaceOwnerId === cacheId && mut.state.status !== 'success';
		}
	});

	let mutationExist = false;
	if (currentMutation && savingData && currentMutation.state.variables) {
		const newVariables = {
			...currentMutation.state.variables,
			fields: {
				...currentMutation.state.variables.fields,
				...savingData
			}
		};
		mutationExist = true;
		currentMutation.state.isPaused = true;
		currentMutation.state.status = 'pending';
		currentMutation.state.failureCount = 0;
		currentMutation.state.error = null;
		currentMutation.state.failureReason = null;
		currentMutation.state.variables = newVariables;
		void currentMutation.execute(newVariables);
	}
	return mutationExist;
};
