import {Plate} from '@tehzor/ui-components';
import './EditingProblemTagsSet.less';
import {useEditableProblemTagsSet} from '../EditableProblemTagsSet/hooks/useEditableProblemTagsSet';
import {useExtractProblemTagsSetById} from '@src/core/hooks/queries/problemTagsSets/hooks';

interface IEditingProblemTagsSetProps {
	problemTagsSetId?: string;
}

export const EditingProblemTagsSet = ({problemTagsSetId}: IEditingProblemTagsSetProps) => {
	const {data: problemTagsSet} = useExtractProblemTagsSetById(problemTagsSetId);

	const [setFields, tagFields] = useEditableProblemTagsSet({
		problemTagsSet
	});

	return (
		<div className="editing-problem-tags-set__container">
			<Plate className="editing-problem-tags-set__info-grid">{setFields}</Plate>
			<Plate className="editing-problem-tags-set__items-grid">{tagFields}</Plate>
		</div>
	);
};
