import {useCallback, useState} from 'react';
import * as React from 'react';
import AddingSpaceOwnerDialog from '../AddingSpaceOwnerDialog';

export function useAddingSpaceOwnerDialog(
	companyId?: string,
	spaceId?: string
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<AddingSpaceOwnerDialog
			companyId={companyId}
			spaceId={spaceId}
			isOpen={isOpen}
			onClose={close}
		/>
	);
	return [dialog, open];
}
