import {IGetTasksResponse} from '@src/api/backend/tasks';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useCallback} from 'react';
import {extractTasksByAcceptanceId} from './selectors';

export const useWorkAcceptanceTasks = <T = IGetTasksResponse>(
	workAcceptanceId?: string,
	select?: (data: IGetTasksResponse) => T,
	options?: Partial<UseQueryOptions<IGetTasksResponse, IError>>
) =>
	useQuery<IGetTasksResponse, IError, T>({
		queryKey: tasksQueryKeys.list(
			{workAcceptance: [workAcceptanceId]},
			{createdAt: false},
			0,
			1000
		),
		meta: {
			error: 'при загрузке списка задач'
		},
		...options,
		select
	});

export const useWorkAcceptanceTasksArray = (workAcceptanceId: string) => {
	const selector = useCallback(
		(data: IGetTasksResponse) => extractTasksByAcceptanceId(data, workAcceptanceId),
		[workAcceptanceId]
	);
	return useWorkAcceptanceTasks(workAcceptanceId, selector);
};
