const domain = 'statistics/sources';

export const GET_COMPANIES_REQUEST = `${domain}/companies/get/request`;
export const GET_COMPANIES_SUCCESS = `${domain}/companies/get/success`;
export const GET_COMPANIES_FAILURE = `${domain}/companies/get/failure`;

export const GET_OBJECTS_REQUEST = `${domain}/objects/get/request`;
export const GET_OBJECTS_SUCCESS = `${domain}/objects/get/success`;
export const GET_OBJECTS_FAILURE = `${domain}/objects/get/failure`;

export const GET_CATEGORIES_REQUEST = `${domain}/categories/get/request`;
export const GET_CATEGORIES_SUCCESS = `${domain}/categories/get/success`;
export const GET_CATEGORIES_FAILURE = `${domain}/categories/get/failure`;
