import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetPlansResponse = INormalizedData<IPlan>;

/**
 * Возвращает список планов
 *
 * @param objectIds id объектов
 * @param planIds id планов
 */
export const requestPlans = async (objectIds?: string[], planIds?: string[]) => {
	const params = {objectIds, planIds};
	const response = await httpRequests.withToken.post<IGetPlansResponse>(
		'plans/get', params
	);
	return response.data;
};
