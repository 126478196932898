import {memo} from 'react';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import IExpiredFilter, {
	expiredOptions
} from '@tehzor/tools/interfaces/expiration/IExpirationFilter';
import {MobileFilter} from '@tehzor/ui-components';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

interface IExpiredFilterMobileProps {
	value?: string[];
	label?: string;
	filterName?: string;
	customExpiredOptions?: IExpiredFilter[];
	onChange: (changes: IChanges) => void;
}

export const ExpiredFilterMobile = memo(
	({
		value,
		label,
		filterName = 'expired',
		customExpiredOptions,
		onChange
	}: IExpiredFilterMobileProps) => {
		const {t} = useTranslation();
		const options = customExpiredOptions || expiredOptions;
		const translatedOptions = useTranslatedDictionaryArray(
			dictionaryKeys.expiredFilterValues,
			options
		);

		const translatedLabel = label ?? t('expiredFilterValues.expired');

		const handleChange = (v: string[] | undefined) => {
			const changes = {[filterName]: v?.length ? v : undefined};
			onChange(changes);
		};

		return (
			<MobileFilter
				label={translatedLabel}
				elements={translatedOptions}
				selected={value}
				onChange={handleChange}
			/>
		);
	}
);
