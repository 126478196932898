import {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import './GlobalAddingEntityDialog.less';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IAddingProblemRefProps} from '../AddingProblem';
import {IAddingInspectionRefProps} from '../AddingInspection';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {useEntitiesMaps} from './hooks/useEntitiesMaps';
import {IAddingAcceptanceRefProps} from '../AddingOwnerAcptDialog/components/AddingAcceptance';
import {IAddingWarrantyClaimRefProps} from '../AddingWarrantyClaimDialog/components/AddingWarrantyClaim';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {saveCheck} from '../AddingCheckDialog/utils/saveCheck';
import {saveInternalAcceptance} from '../AddingInternalAcceptanceDialog/utils/saveInternalAcceptance';
import {saveOwnerAcceptance} from '../AddingOwnerAcptDialog/utils/saveOwnerAcceptance';
import {saveClaim} from '../AddingWarrantyClaimDialog/utils/saveClaim';
import {AddingOwnerAcceptanceView} from '../AddingOwnerAcptDialog/AddingOwnerAcptDialog';
import {AddingWarrantyClaimView} from '../AddingWarrantyClaimDialog/AddingWarrantyClaimDialog';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {useAddCheck} from '@src/core/hooks/mutations/checks/useAddCheck';
import {useLocalChecks} from '@src/core/hooks/queries/checks/hooks';
import {useAddInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useAddInternalAcceptance';
import {useLocalOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances/useGetOwnerAcceptances';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useLocalInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/hooks';
import {useLocalWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/hooks';
import {useQueryClient} from '@tanstack/react-query';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';

export type IGlobalAddingRef = RefObject<IGlobalAddingRefProps>;

export type IGlobalAddingRefProps =
	| IAddingProblemRefProps
	| IAddingInspectionRefProps
	| IAddingAcceptanceRefProps
	| IAddingWarrantyClaimRefProps;

export type IGlobalAddingType = ICheckAddingEntityType | IInternalAcceptanceAddingEntityType;

export interface IGlobalAddingPermissions {
	checks?: boolean;
	internalAcceptances?: boolean;
	acceptances?: boolean;
	claims?: boolean;
}

export interface IGlobalAddingLinks {
	spaceId?: string;
	structureId?: string;
	workAcceptanceId?: string;
	checkListId?: string;
	checkItemId?: string;
	checkRecordId?: string;
}

interface IGlobalAddingEntityDialogProps {
	objectId: string;
	spaceId?: string;
	links?: IGlobalAddingLinks;
	stage: ObjectStageIds;
	permissions: IGlobalAddingPermissions;
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	isOpen: boolean;
	types?: IGlobalAddingType[];
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const getDefaultSelectedStage = (
	defaultStage: ObjectStageIds,
	entitiesSelectMapProps: Record<ObjectStageIds, IEntitySelectProps>
): ObjectStageIds => {
	if (entitiesSelectMapProps?.[defaultStage]) {
		return defaultStage;
	}

	const mapKeys = Object.keys(entitiesSelectMapProps) as ObjectStageIds[];
	return mapKeys[0];
};

const GlobalAddingEntityDialog = (props: IGlobalAddingEntityDialogProps) => {
	const {
		objectId,
		spaceId,
		links,
		stage,
		permissions,
		defaultProblemData,
		defaultInspectionData,
		isOpen,
		types,
		onClose,
		onSuccess
	} = props;
	const {t} = useTranslation();

	const stages = useTranslatedObjectStagesArray();
	const {data: object} = useObject(objectId);
	const addingEntityRef = useRef<IGlobalAddingRefProps>(null);
	const [selectedStage, setSelectedStage] = useState(stage);

	const queryClient = useQueryClient();

	const [selectedCheckId, setSelectedCheckId] = useState<string | undefined>();
	const [selectedInternalAcceptanceId, setSelectedInternalAcceptanceId] = useState<
		string | undefined
	>();
	const [selectedOwnerAcceptanceId, setSelectedOwnerAcceptanceId] = useState<
		string | undefined
	>();
	const [selectedWarrantyClaimId, setSelectedWarrantyClaimId] = useState<string | undefined>();

	const [ownerAcceptanceView, setOwnerAcceptanceView] = useState<AddingOwnerAcceptanceView>();
	const [warrantyClaimView, setWarrantyClaimView] = useState<AddingWarrantyClaimView>();

	const localOwnerAcceptances = useLocalOwnerAcceptances();
	const localOwnerAcceptancesIds = localOwnerAcceptances.map(acp => acp.id);
	const {data: localWarrantyClaims} = useLocalWarrantyClaims();
	const localWarrantyClaimsIds = localWarrantyClaims?.map(claim => claim.id);
	const {data: localChecks} = useLocalChecks();
	const {data: localInternalAcceptances} = useLocalInternalAcceptances();
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const [contentMap, entitiesSelectMapProps] = useEntitiesMaps({
		objectId,
		spaceId,
		links,
		permissions,
		addingEntityRef,
		defaultProblemData,
		defaultInspectionData,
		types,
		saving,
		setSaving,
		selectedCheckId,
		selectedInternalAcceptanceId,
		selectedOwnerAcceptanceId,
		selectedWarrantyClaimId,
		setSelectedCheckId,
		setSelectedInternalAcceptanceId,
		setSelectedOwnerAcceptanceId,
		setSelectedWarrantyClaimId,
		ownerAcceptanceView,
		setOwnerAcceptanceView,
		warrantyClaimView,
		setWarrantyClaimView,
		onClose
	});

	useEffect(() => {
		setSelectedStage(getDefaultSelectedStage(stage, entitiesSelectMapProps));
	}, [stage]);

	const reset = useCallback(
		() => setSelectedStage(getDefaultSelectedStage(stage, entitiesSelectMapProps)),
		[stage]
	);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
			reset();
		}
	}, [onSuccess, reset]);

	const addCheck = useAddCheck(object);
	const addInternalAcceptance = useAddInternalAcceptance(object);

	const handleSave = useCallback(async () => {
		const localChecksIds = localChecks?.map(check => check.id) || [];
		if (permissions.checks && selectedStage === ObjectStageIds.BUILDING) {
			const addNewCheck = () =>
				addCheck({
					objectId,
					links,
					stage
				});
			const updateLatest = async () => {
				if (online && selectedCheckId && !localChecksIds.includes(selectedCheckId)) {
					await queryClient.invalidateQueries({
						queryKey: [...checksQueryKeys.detail(selectedCheckId), objectId]
					});
				}
			};

			await saveCheck({
				addingEntityRef,
				selectedCheckId,
				localChecksIds,
				online,
				updateLatest,
				addNewCheck,
				setSaving,
				success,
				onClose
			});
			return;
		}

		if (
			(permissions.internalAcceptances && selectedStage === ObjectStageIds.ACCEPTANCE) ||
			(!permissions.checks &&
				permissions.internalAcceptances &&
				selectedStage === ObjectStageIds.BUILDING)
		) {
			const localInternalAcceptancesIds =
				localInternalAcceptances?.map(acpt => acpt.id) || [];
			const addNewInternalAcceptance = () =>
				addInternalAcceptance({
					objectId,
					links,
					stage
				});
			const updateLatest = async () => {
				if (
					online &&
					selectedInternalAcceptanceId &&
					!localInternalAcceptancesIds.includes(selectedInternalAcceptanceId)
				) {
					await queryClient.invalidateQueries({
						queryKey: [
							...internalAcceptancesQueryKeys.detail(selectedInternalAcceptanceId),
							objectId
						]
					});
				}
			};

			await saveInternalAcceptance({
				addingEntityRef,
				selectedInternalAcceptanceId,
				localInternalAcceptancesIds,
				online,
				updateLatest,
				addNewInternalAcceptance,
				setSaving,
				success,
				onClose
			});
			return;
		}

		if (permissions.acceptances && selectedStage === ObjectStageIds.TRANSFER) {
			const updateLatest = async () => {
				if (
					online &&
					selectedOwnerAcceptanceId &&
					!localOwnerAcceptancesIds.includes(selectedOwnerAcceptanceId)
				) {
					await queryClient.invalidateQueries({
						queryKey: ownerAcceptancesQueryKeys.detail(selectedOwnerAcceptanceId)
					});
				}
			};
			await saveOwnerAcceptance({
				addingEntityRef,
				localOwnerAcceptancesIds,
				view: ownerAcceptanceView,
				online,
				updateLatest,
				selectedOwnerAcceptanceId,
				success,
				onClose,
				setSaving
			});
			return;
		}

		if (permissions.claims && selectedStage === ObjectStageIds.WARRANTY) {
			const updateLatest = async () => {
				if (
					online &&
					selectedWarrantyClaimId &&
					localWarrantyClaimsIds?.includes(selectedWarrantyClaimId)
				) {
					await queryClient.invalidateQueries({
						queryKey: [
							...warrantyClaimsQueryKeys.detail(selectedWarrantyClaimId),
							objectId
						]
					});
				}
			};

			await saveClaim({
				addingEntityRef,
				localWarrantyClaimsIds,
				view: warrantyClaimView,
				online,
				updateLatest,
				selectedWarrantyClaimId,
				success,
				onClose,
				setSaving
			});
		}
	}, [
		onClose,
		selectedCheckId,
		selectedInternalAcceptanceId,
		selectedOwnerAcceptanceId,
		selectedWarrantyClaimId,
		objectId,
		links,
		stage,
		localChecks,
		localInternalAcceptances,
		localOwnerAcceptancesIds,
		localWarrantyClaimsIds,
		ownerAcceptanceView,
		warrantyClaimView,
		permissions,
		selectedStage,
		addingEntityRef,
		selectedStage,
		success
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			close();
		}
	}, []);

	const getEntitySelectContainerSubTitle = useCallback(
		(objectStageName: string): string =>
			t('globalAddingEntityDialog.entitySelectContainer.getEntitySelectContainerSubTitle', {
				name: objectStageName
			}),
		[t]
	);

	return (
		<ModalDialog
			dataTestId="GlobalAddingEntityDialog"
			open={isOpen}
			className={{
				root: 'adding-entity-dialog',
				header: 'adding-entity-dialog__header',
				body: 'adding-entity-dialog__body'
			}}
			fullScreenOnTablet
			onRequestClose={onClose}
			onAfterClose={handleAfterClose}
			title={t('globalAddingEntityDialog.title')}
			disableScrollBlock
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('globalAddingEntityDialog.dialog.footer.actionButtons.cancel')}
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label={t('globalAddingEntityDialog.dialog.footer.actionButtons.save')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					stages={stages}
					selectedStage={selectedStage}
					onSelectStage={setSelectedStage}
					getEntitySelectContainerSubTitle={getEntitySelectContainerSubTitle}
					stagesSelectDialogTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.stagesSelectDialogTitle'
					)}
					stagesSelectDialogSaveLabel={t(
						'globalAddingEntityDialog.entitySelectContainer.stagesSelectDialogSaveLabel'
					)}
					stagesSelectDialogCancelLabel={t(
						'globalAddingEntityDialog.entitySelectContainer.stagesSelectDialogCancelLabel'
					)}
				/>
			)}
		</ModalDialog>
	);
};

export default GlobalAddingEntityDialog;
