import {CellProps} from 'react-table';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {useExtractCategoryById} from '@src/core/hooks/queries/categories/hook';
import {useFilteredStructuresListAsArray} from '@/entities/Structures';

export const WorkAcceptanceStructuresCell = ({row}: CellProps<IListWorkAcceptance>) => {
	const workAcceptance: IListWorkAcceptance = row.original;

	const {data: category} = useExtractCategoryById(workAcceptance.categoryId);
	const {data: structures} = useFilteredStructuresListAsArray(
		workAcceptance.objectId,
		workAcceptance.structureIds
	);

	return (
		<div>
			<div>{category?.name}</div>
			{structures && <div>{structures.map(str => str.name).join(', ')}</div>}
		</div>
	);
};
