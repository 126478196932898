import {useUpdateEffect} from 'react-use';
import {memo, useMemo, useState} from 'react';
import * as React from 'react';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch/helpers/flatFilter';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectSearch,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';

interface IMultiplePlansSelectProps<D extends {name: string; id: string}> {
	label: string;
	className?: string;
	popupClassName?: string;
	style?: React.CSSProperties;
	options?: D[];
	optionsIds?: string[];
	onChangeFilter: (value: string[]) => void;
	disabled?: boolean;
}

const simpleArrowIcon = <i className="tz-simple-arrow-20" />;
export const MultiplePlansSelect = memo(
	<D extends {name: string; id: string}>(props: IMultiplePlansSelectProps<D>) => {
		const {
			label,
			options = [],
			optionsIds,
			onChangeFilter,
			disabled,
			style,
			className,
			popupClassName
		} = props;
		const [search, setSearch] = useState('');
		const [selectedValue, setSelectedValue] = useState(optionsIds);
		const {t} = useTranslation();

		useUpdateEffect(() => {
			setSelectedValue(optionsIds);
		}, [optionsIds]);

		const handleApply = () => {
			if (selectedValue) {
				onChangeFilter(selectedValue);
				setSearch('');
			}
		};

		const handleClear = () => {
			setSelectedValue([]);
			setSearch('');
		};

		const handleCancel = () => {
			setSearch('');
		};

		const filteredData = useMemo(() => flatFilter(options, 'name', search), [options, search]);
		const hasSelectedValue = Boolean(selectedValue?.length);
		const selectedLabel = optionsIds?.length
			? makeFilterLabel(
					t('objectPage.generateSpacesDialog.planSelect.selected'),
					optionsIds,
					options
			  )
			: undefined;

		return (
			<div
				className={className}
				style={style}
			>
				<EditableFieldLabel>{label}</EditableFieldLabel>
				<TranslatedSelectPopup
					footer
					onCancel={handleCancel}
					onApply={handleApply}
					onClear={handleClear}
					clearButton={hasSelectedValue}
					count={selectedValue?.length}
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={selectedLabel}
							icon={simpleArrowIcon}
							disabled={disabled}
						/>
					}
					search={
						<SelectSearch
							value={search}
							onChange={setSearch}
							type="popup"
							placeholder={t('objectPage.generateSpacesDialog.planSelect.search')}
						/>
					}
				>
					<Select2
						className={popupClassName}
						multiple
						onChange={setSelectedValue}
						value={selectedValue}
					>
						{filteredData?.map(option => (
							<SelectOption
								content={option.name}
								inputType="checkbox"
								itemKey={option.id}
								key={option.id}
							/>
						))}
					</Select2>
				</TranslatedSelectPopup>
			</div>
		);
	}
);
