import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IStatsCategory from '@tehzor/tools/interfaces/statistics/IStatsCategory';
import {IGetCategoriesStatsSourcePayload} from '@src/store/modules/statistics/sources/actions';

export interface IStatsSourcesState {
	categories: {
		data: INormalizedData<IStatsCategory>;
		loaded?: boolean;
	};
}

export const getInitialState = (): IStatsSourcesState => ({
	categories: {data: {byId: {}, allIds: []}}
});

export default createReducer<IStatsSourcesState>(getInitialState(), {
	[types.GET_CATEGORIES_SUCCESS]: (
		state,
		{payload}: {payload: IGetCategoriesStatsSourcePayload}
	) => {
		state.categories.data = payload;
		state.categories.loaded = true;
	}
});
