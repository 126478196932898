import { useCallback } from 'react';
import {Button} from '@tehzor/ui-components';
import {useSpacesExport} from '@src/core/hooks/export/useSpacesExport';
import {useTranslation} from 'react-i18next';

interface ISpacesExportBProps {
	objectId: string;
}

const exportIcon = <i className="tz-export-20" />;

const DesktopSpacesExportBtn = ({objectId}: ISpacesExportBProps) => {
	const {t} = useTranslation();
	const [exportDialog, openExportDialog] = useSpacesExport(objectId);
	const handleExport = useCallback(() => {
		openExportDialog(objectId);
	}, [objectId, openExportDialog]);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={exportIcon}
				label={t('spacesPage.pageBreadcrumbs.desktopSpacesExportBtn.button.label')}
				onClick={handleExport}
			/>
			{exportDialog}
		</>
	);
};

export default DesktopSpacesExportBtn;
