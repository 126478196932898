import {useMemo} from 'react';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTranslation} from "react-i18next";

export const useManageTaskBreadcrumbs = (task?: IEnrichedTask) => {
	const {t} = useTranslation();

	return useMemo(() => {
		const items = [
			{
				label: t('taskViewPage.useManageTaskBreadcrumbs.tasks'),
				url: '/tasks',
				nonClickable: !task
			}
		];

		if (task) {
			items.push({
				label: t('taskViewPage.useManageTaskBreadcrumbs.task', {name: task?.name}),
				url: `/tasks/${task.id}`,
				nonClickable: false
			});
		}

		return items;
	}, [task, t]);
};
