import {Mutation, useMutationState} from '@tanstack/react-query';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {useMemo} from 'react';

export const useEntityTransferState = (entityId: string) => {
	const [state = 'idle'] = useMutationState({
		filters: {
			predicate: mut => {
				const key = (mut.state.variables as Record<string, unknown> & {key: string})?.key;
				return entityId === key;
			}
		},
		select: (mutation: Mutation<unknown, Error>) =>
			!mutation.state.isPaused ? mutation.state.status : 'idle'
	});
	return useMemo(() => {
		let status: OfflineDataTransferStatus;
		switch (state) {
			case 'idle':
				status = OfflineDataTransferStatus.SAVED_LOCAL;
				break;
			case 'pending':
				status = OfflineDataTransferStatus.TRANSFER;
				break;
			case 'success':
				status = OfflineDataTransferStatus.TRANSFER_COMPLETE;
				break;
			case 'error':
				status = OfflineDataTransferStatus.TRANSFER_ERROR;
				break;
			default:
				status = OfflineDataTransferStatus.SAVED_LOCAL;
		}
		return status;
	}, [state]);
};
