import {useTasks} from '@src/core/hooks/queries/tasks/hooks';
import classNames from 'classnames';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useTranslation} from 'react-i18next';

const TasksPageCounter = () => {
	const {t} = useTranslation();
	const {data: tasksData} = useTasks();
	const isDesktop = useIsDesktop();

	return (
		<div
			className={classNames('tasks-page__counter', {
				'tasks-page__m-control-bar-counter': !isDesktop
			})}
		>
			{`${t('tasksPage.tasksPageCounter.text')}: ${tasksData?.total || 0}`}
		</div>
	);
};

export default TasksPageCounter;
