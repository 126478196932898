import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IListUnit, useUnitsAsArray} from '../queries/units/hook';

export const useTranslatedUnitsArray = (): IListUnit[] => {
	const {t} = useTranslation();
	const {data: units} = useUnitsAsArray();

	return useMemo(() => {
		if (!units) {
			return [];
		}

		const translatedUnits: IListUnit[] = [];
		for (const unit of units) {
			const id = unit.id;
			const fullName = t(`units.fullName.${id}`);
			const name = t(`units.name.${id}`);

			translatedUnits.push({
				id,
				fullName,
				name,
				fullListName: `${fullName} (${name})`
			});
		}

		return translatedUnits;
	}, [t]);
};
