import {MessageReference} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

interface ICommentReferenceProps {
	className?: string;
	commentId: string;
	type: 'add' | 'edit';
	commentTypeId: ICommentTypeId;
	onClearReference?: () => void;
}

const CommentReference = ({
	className,
	commentId,
	type,
	commentTypeId,
	onClearReference
}: ICommentReferenceProps) => {
	const {t} = useTranslation();
	let comment = null;

	const problemComment = useAppSelector(s => s.pages.problem.comments.byId[commentId]);
	const workAcceptanceComment = useAppSelector(
		s => s.entities.workAcceptance.comments.byId[commentId]
	);

	if (commentTypeId === ICommentTypeId.PROBLEM_COMMENT) {
		comment = problemComment;
	} else if (commentTypeId === ICommentTypeId.WORK_ACCEPTANCE_COMMENT) {
		comment = workAcceptanceComment;
	}
	return comment ? (
		<MessageReference
			className={classNames('problem-comments__reference', className)}
			title={
				type === 'add'
					? t('components.problemComments.commentReference.add')
					: t('components.problemComments.commentReference.edit')
			}
			description={comment.description}
			onDelete={onClearReference}
		/>
	) : null;
};

export default CommentReference;
