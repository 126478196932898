import {convertRawToUploading} from '@src/utils/attachments/filesConverters';
import {TempFileDestination} from '@tehzor/tools/enums/TempFileDestination';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';

export const convertRawFiles = (file: IRawFile, online: boolean) => {
	const status = online ? UploadingFileStatus.WAITING : UploadingFileStatus.LOCAL;

	const sizeError = file.size > 50 * 1024 * 1024;
	return convertRawToUploading(
		file,
		TempFileDestination.OBJECT_IMAGE,
		sizeError ? UploadingFileStatus.ERROR : status,
		sizeError
	);
};
