import {IGetPaginateProblemsResponse, requestPaginateProblems} from '@src/api/backend/problems';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../../constants/problems';

export type IGetProblemsPayload = IGetPaginateProblemsResponse;

const request = () => ({
	type: types.GET_REQUEST
});

const success = (response: IGetPaginateProblemsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response
	} as IGetProblemsPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка нарушений');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список нарушений для чек-листов
 */
export const getProblems
	= (
		checkListIds: string[],
		spaceId?: string,
		workAcceptanceIds?: string[]
	): AppThunkAction<Promise<IGetPaginateProblemsResponse>> =>
		dispatch =>
			dispatch(
				createApiAction<IGetPaginateProblemsResponse>(request, success, failure, () => {
					const filters: Record<string, unknown> = {checkListIds};
					if (spaceId) {
						filters.spaces = [spaceId];
					}
					if (workAcceptanceIds?.length) {
						filters.workAcceptanceIds = workAcceptanceIds;
					}
					return requestPaginateProblems(filters, {createdAt: false}, 0, 1000);
				})
			);
