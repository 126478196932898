import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IHardDeleteCheckItemResponse {
	deletedIds: string[];
	editedItems: INormalizedData<ICheckItem>;
}

/**
 * Удаляет (hard) категорию чеклиста вместе со всеми вложениями
 *
 * @param checkListId id чек-листа
 * @param id  идентификатор категориии которую удаляем
 */
export const makeCheckItemHardDeleteRequest = async (checkListId: string, id: string) => {
	const response = await httpRequests.withToken.post<IHardDeleteCheckItemResponse>(
		'/check-items/hard-delete',
		{
			checkListId,
			id
		}
	);
	return response.data;
};
