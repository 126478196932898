import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export const createRespUsersDifference = (
	prevGroup: string | undefined,
	prevUsers: string[] | undefined,
	nextGroup: string | undefined,
	nextUsers: string[] | undefined,
	users: INormalizedData<IBriefUser>,
	groups: INormalizedData<IWorkingGroup>,
	label: string
): IHistoryData => ({
	prev: prevGroup
		? {
				respUsers: prevUsers
					? prevUsers.map((item: string) => ({
							name: users.byId[item]?.fullName,
							position: users.byId[item]?.position
					  }))
					: undefined,
				activeGroup: groups.byId[prevGroup] ? groups.byId[prevGroup]?.name : ''
		  }
		: undefined,
	next: nextGroup
		? {
				respUsers: nextUsers
					? nextUsers.map((item: string) => ({
							name: users.byId[item]?.fullName,
							position: users.byId[item]?.position
					  }))
					: undefined,
				activeGroup: groups.byId[nextGroup] ? groups.byId[nextGroup]?.name : ''
		  }
		: undefined,
	type: HistoryTypeId.RESP_USERS,
	name: label
});
