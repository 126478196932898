import {createContext, PropsWithChildren, useCallback} from 'react';

import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IPreparedSpaceTypeDecoration} from '../interfaces/IPreparedSpaceTypeDecoration';
import {useDeleteSpaceTypeDecorationSet} from '@src/core/hooks/mutations/spaceTypeDecorationSets/useDeleteSpaceTypeDecorationSet';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedSpaceTypeDecoration>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({children}: PropsWithChildren) => {
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить тип отделки помещения?',
		'Все связанные данные будут безвозвратно удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const {mutateAsync: deleteSpaceTypeDecorationSet} = useDeleteSpaceTypeDecorationSet();
	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IPreparedSpaceTypeDecoration>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await deleteSpaceTypeDecorationSet(action.payload.id);
			}
		},
		[getDeleteConfirmation, deleteSpaceTypeDecorationSet]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
