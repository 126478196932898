import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {ILocationSelectSettingsState} from '@src/store/modules/settings/locationSelect/reducers';

const getAll = (state: IState) => state.settings.locationSelect || {};
const getObjectId = (state: IState, objectId: string) => objectId;

/**
 * Извлекает дефолтные настройки для компонента выбора местоположения
 */
export const extractLocSelectDefaultSettings = createSelector(
	[getAll, getObjectId],
	(all: ILocationSelectSettingsState, objectId: string) => (all[objectId] ? all[objectId] : {})
);
