import {wsConnector} from '../wsConnector';
import {ISavingProblemComment} from '@src/interfaces/saving/ISavingProblemComment';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

export type IEditCommentResponse = IComment;

/**
 * Изменяет комментарий
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const makeCommentEditRequest = (
	objectId: string,
	commentId: string,
	fields: ISavingProblemComment
) =>
	wsConnector.sendAuthorizedRequest<IEditCommentResponse>('editComment', {
		objectId,
		commentId,
		...fields
	});
