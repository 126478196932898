import cls from './ProblemReopenedTimes.module.less';
import classNames from "classnames";

interface IProblemReopenedTimesProps {
	reopenedTimes: number;
	className?: string;
}

export const ProblemReopenedTimes = ({reopenedTimes, className}: IProblemReopenedTimesProps) => (
	<div className={classNames(cls.wrapper, className)}>
		<i className={classNames('tz-repeat-16', cls.icon)} />
		<p className={cls.count}>
			{reopenedTimes > 9 ? '9+' : reopenedTimes}
		</p>
	</div>
);
