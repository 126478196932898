import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {DocumentsFiltersPage} from '@src/pages/filters/DocumentsFiltersPage';
import {CompaniesFilterPage} from '@src/pages/DocumentsPage/components/filters/CompaniesFilterPage';
import {ContractorsFilterPage} from '@src/pages/DocumentsPage/components/filters/ContractorsFilterPage';

export const documentsFiltersRoutes = [
	{
		path: 'companyIds',
		element: <CompaniesFilterPage />
	},
	{
		path: 'contractorIds',
		element: <ContractorsFilterPage />
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: '',
		element: <DocumentsFiltersPage />
	}
]