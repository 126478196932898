import * as React from 'react';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

interface IScrollLockProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
}

/**
 * Компонент для блокировки body-скролла при mount'е
 */
class ScrollLock extends React.PureComponent<IScrollLockProps> {
	private ref: HTMLElement;

	componentWillUnmount() {
		if (this.ref) {
			enableBodyScroll(this.ref);
		}
	}

	render() {
		const {className, style, children} = this.props;

		return (
			<div
				className={className}
				style={style}
				ref={this.saveRef}
			>
				{children}
			</div>
		);
	}

	private saveRef = (element: HTMLDivElement) => {
		this.ref = element;
		if (this.ref) {
			disableBodyScroll(this.ref);
		}
	};
}

export default ScrollLock;
