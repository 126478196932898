import {queryClient} from '@src/api/QueryClient';
import {makeSpaceTypeDecorationAddRequest} from '@src/api/backend/spaceTypeDecoration';
import {spaceTypesDecorationsQueryKeys} from '@src/api/cache/spaceTypeDecorations/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ISavingSpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecoration';

interface ISpaceTypeDecorationParams {
	typeDecorationSetId: string;
	items: ISavingSpaceTypeDecoration[];
}
export const useAddSpaceTypeDecoration = () =>
	useMutation({
		mutationFn: (params: ISpaceTypeDecorationParams) =>
			makeSpaceTypeDecorationAddRequest(params.typeDecorationSetId, params.items),
		mutationKey: spaceTypesDecorationsQueryKeys.add(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceTypesDecorationsQueryKeys.list()});
			void queryClient.invalidateQueries({
				queryKey: spaceTypesDecorationsQueryKeys.allById()
			});
			addSuccessToast('Успешно', 'Набор типов отделки создан');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении типов отделки');
		}
	});
