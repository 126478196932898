import {useCallback, useContext} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from './InspectionsTable';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';
import {useTranslation} from 'react-i18next';

const deleteIcon = <i className="tz-delete" />;

const MenuCell = ({row}: CellProps<IInspectionEntity>) => {
	const {t} = useTranslation();
	const {canDelete} = row.original.data;

	const dispatchAction = useContext(DispatchActionCtx);

	const deleteCheck = useCallback(() => {
		dispatchAction({type: 'delete', payload: row.original});
	}, [row.original, dispatchAction]);

	const menuActions = [];
	if (canDelete) {
		menuActions.push(
			<MenuItem
				key="delete"
				className="menu-item_red"
				icon={deleteIcon}
				onClick={deleteCheck}
			>
				{t('inspectionsPage.table.menuCell.button.delete')}
			</MenuItem>
		);
	}

	return menuActions.length ? <IconMenu>{menuActions}</IconMenu> : null;
};

export default MenuCell;
