
export type IGetWorkAcceptanceStoriesQueryKeyList = [] & {
	0: string; // work-acceptance-stories
	1: string; // list
	2: string; // objectId
	3: string; // workAcceptanceId
};

export const workAcceptanceStoriesQueryKeys = {
	all: () => ['work-acceptance-stories'],
	lists: () => [...workAcceptanceStoriesQueryKeys.all(), 'list'],
	list: (
		objectId: string,
		workAcceptanceId: string
	) => [
		...workAcceptanceStoriesQueryKeys.lists(),
		objectId,
		workAcceptanceId
	],
};
