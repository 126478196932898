import createReducer from '@tehzor/tools/utils/createReducer';
import {ILocalCheck} from '@src/interfaces/ILocalCheck';
import {
	ADD_LOCAL_CHECK,
	CHANGE_LOCAL_CHECK_MODIFIED_AT,
	DELETE_LOCAL_CHECK
} from '@src/constants/local-entities/checks';

type ILocalChecksState = ILocalCheck[];

const checks = createReducer<ILocalChecksState>([], {
	[ADD_LOCAL_CHECK]: (state, payload: {check: ILocalCheck}) => [...state, payload.check],
	[CHANGE_LOCAL_CHECK_MODIFIED_AT]: (state, payload: {key: string, modifiedAt: number}) =>
		state.map(item => {
			if (item.key === payload.key) {
				item.modifiedAt = payload.modifiedAt;
				return item;
			}
			return item;
		}),
	[DELETE_LOCAL_CHECK]: (state, payload: {key: string}) =>
		state.filter(item => item.key !== payload.key)
});

export {ILocalChecksState};
export default checks;
