import generateKey from '@tehzor/tools/utils/generateKey';

/**
 * Общий класс для работы со слоями
 */
class AbstractHandler {
	// SVG.js объект
	_svg = null;

	// SVG группа слоя
	_group = null;

	// Функция для оповещения при изменении состояния
	_emitFunc = null;

	// Массив фигур
	_shapes = [];

	/**
	 * Создаёт слой
	 * @param {Object} svg SVG.js объект
	 * @param {Object} group SVG.js группа
	 * @param {string} id id слоя
	 * @param visible
	 * @param {Array} shapes фигуры для первоначальной отрисовки
	 * @param {Function} emit функция для оповещения при изменении состояния
	 */
	constructor(svg, group, id, visible, shapes = [], emit) {
		if (!svg || !group || !emit || typeof emit !== 'function') {
			throw new Error('Svg element, svg group and emit function not passed');
		}

		this._svg = svg;
		this.setEmitFunction(emit);
		this._group = group.group().id(id).back();

		this._group.style({display: visible ? 'block' : 'none'});

		for (let i = 0, shape; i < shapes.length; i++) {
			shape = this._group.svg(shapes[i].svg).last().id(generateKey('shape'));

			this._shapes.push({
				id: shapes[i].id,
				element: this._enhanceShape(shape),
				name: shapes[i].name,
				spaceType: shapes[i].spaceType,
				selected: false
			});
		}
	}

	/**
	 * Проверяет поддержку режима редактирования
	 * @param {String} mode режим редактирования
	 * @return {boolean}
	 */
	checkDrawingModeSupport(mode) {
		return this.hasOwnProperty('_availableModes')
			? this._availableModes.includes(mode)
			: false;
	}

	/**
	 * Помещает слой впереди всех
	 */
	toFront() {
		this._group.front();
	}

	toggleVisible(visible) {
		this._group.style({display: visible ? 'block' : 'none'});
		this.deselectAllShapes();
	}

	/**
	 * Возвращает фигуры в сокращенном виде (только название и выбран/не выбран)
	 * @return {Array} фигуры
	 */
	getShapes() {
		return this._shapes.map(shape => ({
			name: shape.name,
			spaceType: shape.spaceType,
			selected: shape.selected
		}));
	}

	/**
	 * Возвращает фигуры в расширенном виде (для сохранения)
	 * @return {Array} фигуры
	 */
	getShapesForSaving(isNew) {
		return this._shapes.map(shape => {
			let svg = shape.element.svg();
			svg = svg.replace(/\s(id|opacity|fill|stroke|stroke-width)=".*?"/gi, '');
			const responce = {
				name: shape.name,
				spaceType: shape.spaceType,
				type: shape.element.node.tagName,
				svg
			};
			if (!isNew) {
				responce._id = shape.id;
			}
			return responce;
		});
	}

	/**
	 * Включает режим выбора фигур
	 */
	enableSelection() {
		this._group.on('mousedown', this._selectShape);
		this._svg.on('mousedown', this.deselectAllShapes);
	}

	/**
	 * Выключает режим выбора фигур
	 */
	disableSelection() {
		this.deselectAllShapes();
		this._group.off('mousedown', this._selectShape);
		this._svg.off('mousedown', this.deselectAllShapes);
	}

	/**
	 * Изменяет название фигуры
	 * @param {Number} index индекс фигуры в массиве
	 * @param {String} name новое название
	 */
	editShapeName(index = -1, name) {
		if (index < 0 || index >= this._shapes.length) {
			return;
		}
		this._shapes[index].name = name;
		this._emit();
	}

	/**
	 * Изменяет тип помещения фигуры
	 * @param {Number} index индекс фигуры в массиве
	 * @param {String} spaceType новый тип помещения
	 */
	editShapeSpaceType(index = -1, spaceType) {
		if (index < 0 || index >= this._shapes.length) {
			return;
		}
		this._shapes[index].spaceType = spaceType;
		this._emit();
	}

	/**
	 * Переключает выделение фигуры
	 * @param {Number} index индекс фигуры в массиве
	 * @param {Boolean} shiftKey нажата ли клавиша shift
	 */
	toggleShapeSelection(index = -1, shiftKey = false) {
		if (index < 0 || index >= this._shapes.length) {
			return;
		}

		!shiftKey && this.deselectAllShapes();

		this._shapes[index].element
			.selectize(shiftKey ? !this._shapes[index].selected : true)
			.selectize(shiftKey ? !this._shapes[index].selected : true, {
				deepSelect: true
			})
			.resize();
		this._shapes[index].selected = shiftKey ? !this._shapes[index].selected : true;
		this._emit();
	}

	/**
	 * Снимает выделение со всех фигур
	 */
	deselectAllShapes = () => {
		for (let i = 0; i < this._shapes.length; i++) {
			if (this._shapes[i].selected) {
				this._shapes[i].element.selectize(false).selectize(false, {
					deepSelect: true
				});
				this._shapes[i].selected = false;
			}
		}
		this._emit();
	};

	/**
	 * Удаляет фигуру
	 * @param {Number} index индекс фигуры в массиве
	 */
	deleteShape(index = -1) {
		if (index < 0 || index >= this._shapes.length) {
			return;
		}

		this._shapes[index].element
			.selectize(false)
			.selectize(false, {
				deepSelect: true
			})
			.resize(false)
			.remove();

		this._shapes = this._shapes.filter((item, i) => i !== index);
		this._emit();
	}

	/**
	 * Удалет все выбранные фигуры
	 */
	deleteSelectedShapes() {
		for (let i = 0; i < this._shapes.length; i++) {
			if (this._shapes[i].selected) {
				this._shapes[i].element
					.selectize(false)
					.selectize(false, {
						deepSelect: true
					})
					.resize(false)
					.remove();
			}
		}
		this._shapes = this._shapes.filter(item => !item.selected);
		this._emit();
	}

	/**
	 * Удалет все фигуры
	 */
	deleteAllShapes() {
		for (let i = 0; i < this._shapes.length; i++) {
			if (this._shapes[i].selected) {
				this._shapes[i].element
					.selectize(false)
					.selectize(false, {
						deepSelect: true
					})
					.resize(false);
			}
			this._shapes[i].element.remove();
		}
		this._shapes = [];
		this._emit();
	}

	/**
	 * Устанавливает функцию для оповещения при изменении состояния
	 * @param {Function} emit функция
	 */
	setEmitFunction(emit) {
		if (emit && typeof emit === 'function') {
			this._emitFunc = emit;
		}
	}

	/**
	 * Очищает ресурсы, удаляет слой
	 */
	destroy() {
		this.deleteAllShapes();
		this.disableSelection();
		this._group.remove();
	}

	/**
	 * Добавляет необходимые атрибуты фигуре
	 * @param {Object} shape SVG.js фигура
	 * @return {Object} SVG.js фигура
	 */
	_enhanceShape(shape) {
		return shape;
	}

	/**
	 * Выделяет фигуру при клике на неё
	 * @param {Event} e событие
	 * @private
	 */
	_selectShape = e => {
		if (this._availableShapes.includes(e.target.tagName)) {
			e.stopPropagation();
			!e.shiftKey && this.deselectAllShapes();

			for (let i = 0; i < this._shapes.length; i++) {
				if (this._shapes[i].element.attr('id') === e.target.id) {
					this._shapes[i].element
						.selectize(e.shiftKey ? !this._shapes[i].selected : true)
						.selectize(e.shiftKey ? !this._shapes[i].selected : true, {
							deepSelect: true,
							radius: 12
						})
						.resize();
					this._shapes[i].selected = e.shiftKey ? !this._shapes[i].selected : true;
					break;
				}
			}
			this._emit();
		}
	};

	/**
	 * Оповещает об изменении состояния
	 * @protected
	 */
	_emit() {
		this._emitFunc(
			this._shapes.map(shape => ({
				name: shape.name,
				spaceType: shape.spaceType,
				selected: shape.selected
			}))
		);
	}
}

export default AbstractHandler;
