import {createReducer} from '@reduxjs/toolkit';
import config from '@src/core/config';
import {ILoadableEntitiesState} from '@tehzor/tools/core/storeHelpers/reducers';
import {IListDevice} from '@tehzor/tools/interfaces/IDevice';
import {CLEAR_STORE} from '../../auth/constants';
import {IDeleteDevicePayload} from './actions/delete';
import {IGetDevicesPayload} from './actions/get';
import * as types from './constants';

const {entitiesCacheTime} = config;

export interface IDevicesState extends ILoadableEntitiesState<IListDevice> {
	selectedRows: string[];
	isAllRowsSelected: boolean;
}

export const getInitialState = (): IDevicesState => ({
	byId: {},
	allIds: [],
	selectedRows: [],
	isAllRowsSelected: false,
	loadedAt: undefined,
	loaded: false
});

export default createReducer<IDevicesState>(getInitialState, {
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetDevicesPayload}) => {
		state.allIds = payload.allIds;
		state.byId = payload.byId;
		state.loaded = true;
		state.loadedAt = Date.now() + entitiesCacheTime * 1000;
	},
	[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteDevicePayload}) => {
		state.allIds = state.allIds.filter(id => id !== payload.id);
		delete state.byId[payload.id];
		state.loaded = false;
		state.loadedAt = undefined;
	},
	[CLEAR_STORE]: getInitialState
});