import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {TableRowSelect} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {AcceptorsMobileCell} from './AcceptorsMobileCell';
import {StatusChangeableCell} from './StatusChangableCell';
import {WorkAcceptanceDescriptionMobileCell} from './WorkAcceptanceDescriptionMobileCell';

export const MobileWorkAcceptanceCellWithoutObject = (
	props: CellProps<IPreparedWorkAcceptance>
) => {
	const {row} = props;
	const {acceptors} = row.original;

	return (
		<div className="work-acceptances-page__m-table__cell__info">
			<ClickPreventWrap className="work-acceptances-page__m-table__cell__info-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()} />
			</ClickPreventWrap>
			<div className="work-acceptances-page__m-table__cell__info-description">
				<WorkAcceptanceDescriptionMobileCell {...props} />
				{acceptors && <AcceptorsMobileCell {...props} />}

				<div className="work-acceptances-page__m-table__cell-status">
					<StatusChangeableCell {...props} />
				</div>
			</div>
		</div>
	);
};
