const domain = 'space';

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const EDIT_REQUEST = `${domain}/edit/request`;
export const EDIT_SUCCESS = `${domain}/edit/success`;
export const EDIT_FAILURE = `${domain}/edit/failure`;

export const EDIT_STATUS_REQUEST = `${domain}/status/edit/request`;
export const EDIT_STATUS_SUCCESS = `${domain}/status/edit/success`;
export const EDIT_STATUS_FAILURE = `${domain}/status/edit/failure`;

export const EDIT_INDICATORS_REQUEST = `${domain}/indicators/edit/request`;
export const EDIT_INDICATORS_SUCCESS = `${domain}/indicators/edit/success`;
export const EDIT_INDICATORS_FAILURE = `${domain}/indicators/edit/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const EXPORT_REQUEST = `${domain}/export/request`;
export const EXPORT_SUCCESS = `${domain}/export/success`;
export const EXPORT_FAILURE = `${domain}/export/failure`;

export const GET_CHECKS_REQUEST = `${domain}/checks/get/request`;
export const GET_CHECKS_SUCCESS = `${domain}/checks/get/success`;
export const GET_CHECKS_FAILURE = `${domain}/checks/get/failure`;

export const GET_OWNER_ACCEPTANCES_REQUEST = `${domain}/owner-acceptances/get/request`;
export const GET_OWNER_ACCEPTANCES_SUCCESS = `${domain}/owner-acceptances/get/success`;
export const GET_OWNER_ACCEPTANCES_FAILURE = `${domain}/owner-acceptances/get/failure`;

export const GET_WARRANTY_CLAIMS_REQUEST = `${domain}/warranty-claims/get/request`;
export const GET_WARRANTY_CLAIMS_SUCCESS = `${domain}/warranty-claims/get/success`;
export const GET_WARRANTY_CLAIMS_FAILURE = `${domain}/warranty-claims/get/failure`;

export const CHANGE_ENTITIES_TAB = `${domain}/entities-tab/change`;
