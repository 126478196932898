import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {IStructureType} from '@tehzor/tools/interfaces/structures/IStructureType';
import {TFunction} from 'i18next';
import {IGetListStructuresResponse} from '@/shared/api/structures/getStructuresList';

const getData = (
	structuresMap: IGetListStructuresResponse,
	structuresTypesMap: Record<string, IStructureType> | undefined,
	items: string[] | undefined
): Record<string, string[]> | undefined =>
	items?.reduce(
		(prev, current) => {
			const type =
				structuresTypesMap && structuresTypesMap[structuresMap.byId[current]?.type].name;
			const name = structuresMap.byId[current]?.name;
			if (type && name) {
				if (prev[type]) {
					prev[type].push(name);
				} else {
					prev[type] = [name];
				}
			}
			return prev;
		},
		{} as Record<string, string[]>
	);

export const createStructuresDifference = (
	prev: string[] | undefined,
	next: string[] | undefined,
	structuresMap: IGetListStructuresResponse,
	structuresTypesMap: Record<string, IStructureType> | undefined,
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev: getData(structuresMap, structuresTypesMap, prev),
	next: getData(structuresMap, structuresTypesMap, next),
	type: HistoryTypeId.SPACES,
	name: t('createDifference.createStructuresDifference')
});
