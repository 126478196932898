import {SelectOption} from '@tehzor/ui-components';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export const renderItem = (item: ISpaceOwner) => (
	<SelectOption
		key={item.id}
		itemKey={item.id}
		inputType="checkbox"
		content={`${item.name}`}
	/>
);
