import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {flatFilterMultiple} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

const checkFilterByName = (item: string, searchValue: string) => {
	const upperContent = item.toUpperCase();
	const upperSearch = searchValue.toUpperCase();

	return searchValue === '' || upperContent.includes(upperSearch);
};

/**
 * Фильтрует рабочие группы ответственных по поисковому запросу
 *
 * @param data рабочие группы ответственных (обогащенные данные)
 * @param searchValue поисковой запрос
 */
export const filterBySearch = (
	data: IEnrichedWorkingGroup[],
	searchValue: string
): IEnrichedWorkingGroup[] => {
	if (searchValue === '') {
		return data;
	}

	const filteredData: IEnrichedWorkingGroup[] = [];
	for (const item of data) {
		const newItem = {...item};
		// Поиск по названию рабочей группы
		const isSearchedByName = newItem.name
			? checkFilterByName(newItem.name, searchValue)
			: false;

		// Поиск по руководителю рабочей группы
		const isSearchedByLeaderName = newItem.leader?.displayName
			? checkFilterByName(newItem.leader?.displayName, searchValue)
			: false;

		// Поиск по дочерним рабочим группам
		if (newItem.children?.length) {
			newItem.children = filterBySearch(newItem.children, searchValue);
		}

		// Поиск по ответственным лицам рабочей группы
		if (newItem.performers?.length) {
			newItem.performers = flatFilterMultiple(
				newItem.performers,
				['displayName', 'fullName'],
				searchValue
			);
		}

		if (
			isSearchedByName ||
			isSearchedByLeaderName ||
			newItem.children?.length ||
			newItem.performers?.length
		) {
			filteredData.push(newItem);
		}
	}
	return filteredData;
};
