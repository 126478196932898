import { useCallback, useContext } from 'react';
import './WorkingGroupInfoMobileCell.less';
import {CellProps} from 'react-table';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {DispatchActionCtx} from '../DispatchActionCtx';
import {useWorkingGroupPermissions} from '@src/core/hooks/permissions/useWorkingGroupPermissions';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IconButton} from '@tehzor/ui-components/src/components/buttons';

export const WorkingGroupInfoMobileCell = ({row}: CellProps<IEnrichedWorkingGroup>) => {
	const workingGroup = row.original;
	const perms = useWorkingGroupPermissions();
	const dispatchAction = useContext(DispatchActionCtx);

	const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [row.original.id]);

	return (
		<div className="m-working-group-info-cell">
			<div className="m-working-group-info-cell__wrapper">
				<div className="m-working-group-info-cell__text">
					<div className="m-working-group-info-cell__title">{workingGroup.name}</div>
					<div className="m-working-group-info-cell__subtitle">
						<strong>Компания:</strong>
						&nbsp;
						{workingGroup.company?.name}
					</div>
					<div className="m-working-group-info-cell__subtitle">
						<strong>Тип:</strong>
						&nbsp;
						{workingGroup.type?.name}
					</div>
					<div className="m-working-group-info-cell__subtitle">
						<strong>Руководитель группы:</strong>
						&nbsp;
						{workingGroup.leader?.fullName}
					</div>
				</div>
			</div>
			{perms.canDelete && (
				<ClickPreventWrap style={{marginLeft: 'auto', padding: '0 25px'}}>
					<IconButton
						type="transparent"
						onClick={handleDelete}
					>
						<i className="tz-delete"/>
					</IconButton>
				</ClickPreventWrap>
			)}
		</div>
	);
};
