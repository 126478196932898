import {EntityTransfer} from '@src/components/EntityTransfer/EntityTransfer';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';
import {CellProps} from 'react-table';

const TransferStatusCell = ({row}: CellProps<IInspectionEntity>) => {
	const {transferStatus, id} = row.original.data;
	return (
		<>
			{transferStatus && (
				<EntityTransfer
					itemKey={id}
					transferStatus={transferStatus}
				/>
)}
		</>
);
};

export default TransferStatusCell;
