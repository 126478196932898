import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {IGetSpaceTypesResponse} from '@src/api/backend/spaceTypes';
import {
	extractSpaceTypesAllIds,
	extractSpaceTypesAsArray,
	extractSpaceTypesById
} from './selectors';

export const useSpaceTypes = <T = IGetSpaceTypesResponse>(
	select?: (data: IGetSpaceTypesResponse) => T
) =>
	useQuery<IGetSpaceTypesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.spaceTypes(),
		meta: {
			error: 'при загрузке типов помещений'
		},
		select
	});

export const useExtractSpaceTypesAsArray = () => useSpaceTypes(extractSpaceTypesAsArray);
export const useExtractSpaceTypesById = () => useSpaceTypes(extractSpaceTypesById);
export const useExtractSpaceTypesAllIds = () => useSpaceTypes(extractSpaceTypesAllIds);
