import {queryClient} from '@src/api/QueryClient';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {useCompanies} from '@src/core/hooks/queries/companies/hooks';
import {
	useWorkAcceptances,
	useWorkAcceptancesAsArray
} from '@src/core/hooks/queries/workAcceptances';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {extractSpacesListData} from '@src/store/modules/entities/spaces/selectors/lists';
import {deleteWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions/delete';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';
import {
	changeOffset,
	changePageSize,
	changeSelectedRows,
	changeSort
} from '@src/store/modules/settings/pages/workAcceptances/actions';
import {extractWorkAcceptancesPageSettings} from '@src/store/modules/settings/pages/workAcceptances/selectors';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {useIsDesktop, useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {createContext, useCallback, useMemo} from 'react';
import {convertWorkAcceptances} from '../../utils/convertWorkAcceptances';
import {getDesktopColumns, getDesktopColumnsWithoutObject} from './desktopColumns';
import {SelectionRow} from './selection/SelectionRow';
import {mobileColumns, mobileColumnsWithoutObject} from './mobileColumns';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useExtractWorkAcceptanceTypesById} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useExtractWorkAcceptanceFrontTypesById} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';
import {useStructuresList} from '@/entities/Structures';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';

const pageSizes = [10, 20, 50, 100];

interface IWorkAcceptancesTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedWorkAcceptance>) => void
>(() => ({}));

export const WorkAcceptancesTable = ({objectId = 'all'}: IWorkAcceptancesTableProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {data: companies} = useCompanies();
	const {data: usersMap} = useUsersAsMap();
	const {data: groupsMap} = useWorkingGroupsAsMap();
	const {data: structuresMap} = useStructuresList(objectId);
	const spacesMap = useAppSelector(s => extractSpacesListData(s, objectId));
	const {data: workAcceptances} = useWorkAcceptancesAsArray(objectId);
	const {data: workAcceptancesData} = useWorkAcceptances({objectId});
	const pageSettings = useAppSelector(s => extractWorkAcceptancesPageSettings(s, objectId));
	const {data: typesMap} = useExtractWorkAcceptanceTypesById();
	const translatedTypesMap = useTranslatedDictionaryMap(
		dictionaryKeys.workAcceptanceTypes,
		typesMap
	);
	const {data: frontTypesMap} = useExtractWorkAcceptanceFrontTypesById();
	const translatedFrontTypesMap = useTranslatedDictionaryMap(
		dictionaryKeys.workAcceptanceFrontTypes,
		frontTypesMap
	);
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(extractNetworkStatus);
	const dispatch = useAppDispatch();
	const workAcceptanceTotal = workAcceptancesData?.total || 0;
	const workAcceptanceOffset = workAcceptancesData?.offset || 0;
	const pagesCount = Math.ceil(workAcceptanceTotal / pageSettings.pageSize);
	const currentPage = Math.floor(workAcceptanceOffset / pageSettings.pageSize);
	const isDesktop = useIsDesktop();

	const preparedWorkAcceptances = useMemo(() => {
		if (!workAcceptances || !usersMap || !groupsMap || !structuresMap) return [];

		return convertWorkAcceptances(
			workAcceptances,
			translatedTypesMap,
			translatedFrontTypesMap,
			networkStatus,
			usersMap,
			groupsMap,
			companies?.byId,
			structuresMap.byId,
			spacesMap.byId,
			user
		);
	}, [
		workAcceptances,
		networkStatus,
		usersMap,
		groupsMap,
		companies,
		structuresMap,
		spacesMap,
		user,
		translatedTypesMap,
		translatedFrontTypesMap
	]);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.workAcceptances.deleteTitle')
	});

	const handleRowClick = useCallback(
		(workAcceptance: IPreparedWorkAcceptance) => {
			if (workAcceptance.object?.id) {
				pushPath(
					`/objects/${workAcceptance.object.id}/work-acceptances/${workAcceptance.id}`
				);
			}
		},
		[pushPath]
	);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => {
			dispatch(changeSelectedRows(objectId, value));
		},
		[objectId, dispatch]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort(objectId, value));
			void queryClient.invalidateQueries({
				queryKey: workAcceptancesQueryKeys.listWithObjectId(objectId)
			});
		},
		[objectId, dispatch]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize(objectId, value));
			dispatch(changeOffset(objectId, Math.floor(workAcceptanceOffset / value)));
			void queryClient.invalidateQueries({
				queryKey: workAcceptancesQueryKeys.listWithObjectId(objectId)
			});
		},
		[objectId, workAcceptanceOffset, dispatch]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (workAcceptanceOffset !== offset) {
				dispatch(changeOffset(objectId, offset));
				void queryClient.invalidateQueries({
					queryKey: workAcceptancesQueryKeys.listWithObjectId(objectId)
				});
			}
		},
		[objectId, workAcceptanceOffset, pageSettings.pageSize, dispatch]
	);

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IPreparedWorkAcceptance>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					await dispatch(
						deleteWorkAcceptance(action.payload.object?.id, action.payload.id)
					);
					void queryClient.invalidateQueries({
						queryKey: workAcceptancesQueryKeys.listWithObjectId(objectId)
					});
				}
			}
		},
		[objectId, dispatch, deleteWorkAcceptance]
	);

	const isLargeTablet = useIsLargeTablet();

	const columns = determineTableColumns(
		objectId === 'all',
		isLargeTablet,
		mobileColumns,
		mobileColumnsWithoutObject,
		getDesktopColumns(t),
		getDesktopColumnsWithoutObject(t)
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={preparedWorkAcceptances}
						selectedRows={pageSettings.selectedRows}
						sort={pageSettings.sort}
						selectable={isDesktop}
						onRowClick={handleRowClick}
						hideLastHeaderCell
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
						headVisible={isDesktop}
						renderSelectionRow={props => (
							<SelectionRow
								{...props}
								objectId={objectId}
							/>
						)}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>

			{deleteDialog}
		</>
	);
};
