import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {IPreparedSpaceTypeDecoration} from '../interfaces/IPreparedSpaceTypeDecoration';

/**
 * Преобразовывает массив наборов типов отделки помещения в необходимый для вывода формат
 *
 * @param data массив наборов типов отделки помещения
 */
export const convertSpaceTypeDecorationSet = (
	data: ISpaceTypeDecorationSet[]
): IPreparedSpaceTypeDecoration[] =>
	data.map(item => ({
		...item,
		createdBy: item.createdBy?.displayName || '',
		createdAt: item.createdAt ? format(item.createdAt, dateTimeLetterSeparatedFormat) : '',
		modifiedBy: item.modifiedBy?.displayName || '',
		modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeLetterSeparatedFormat) : ''
	}));
