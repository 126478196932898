import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IProblemsLastRepliesState} from '@src/store/modules/entities/problemsLastReplies/reducers';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

/**
 * Извлекает последний ответ на нарушение
 */
export const extractProblemLastReply = createSelector(
	(state: IState) => state.entities.problemsLastReplies,
	(state: IState, problemId: string) => problemId,
	(data: IProblemsLastRepliesState, problemId: string): IComment | undefined => data[problemId]
);
