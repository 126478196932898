import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useDeleteOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useDeleteOwnerAcceptance';
import {useTranslation} from "react-i18next";

interface IDesktopMenuProps {
	ownerAcceptance: IOwnerAcceptance;
}

const DesktopMenu = ({ownerAcceptance}: IDesktopMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const permissions = useOwnerAcceptancesPermissions(ownerAcceptance.objectId);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		t('ownerAcceptancePage.actions.menu.useConfirmDialog.title'),
		`${t('ownerAcceptancePage.actions.menu.useConfirmDialog.message')} №${ownerAcceptance.number}?`,
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('ownerAcceptancePage.actions.menu.useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('ownerAcceptancePage.actions.menu.useConfirmDialog.rejectBtnLabel')
		}
	);

	const deleteOwnerAcceptance = useDeleteOwnerAcceptance();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await deleteOwnerAcceptance(ownerAcceptance.objectId, ownerAcceptance.id);
			goBack();
		}
	}, [ownerAcceptance.objectId, ownerAcceptance.id]);

	const items = [];

	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={handleDeleteClick}
			>
				{t('ownerAcceptancePage.actions.menu.menuItem.delete.text')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
		</>
	) : null;
};

export default DesktopMenu;
