import {useCallback, useMemo, useState} from 'react';
import {IAnyEntity} from '@tehzor/tools/interfaces/IAnyEntity';
import {ITextSearchProvider} from '../interfaces';

/**
 * Общие обработчики для селекта с поиском
 *
 * @param provider провайдер поиска
 * @param onSelect callback при совершении выбора
 */
export const useSearchSelectHandlers = <T extends IAnyEntity>(
	provider: ITextSearchProvider<T>,
	onSelect: (value: T | undefined) => void
): [string | undefined, (value: string) => void] => {
	const [selected, setSelected] = useState<string>();

	const handleSelection = useCallback(
		(value: string) => {
			setSelected(value);
			onSelect(provider.filteredList.find(({id}) => id === value));
		},
		[provider.filteredList]
	);

	return useMemo(() => [selected, handleSelection], [selected, handleSelection]);
};
