import { memo, useState } from 'react';
import {format} from 'date-fns';
import {ActionButtons, Button, Dialog, EntityGridItem, InlineButton} from '@tehzor/ui-components';
import {useBoolean, useUpdateEffect} from 'react-use';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {EditableDateField} from '@src/components/EditableDateField';
import {useTranslation} from "react-i18next";

interface IRegistrationDateMobileProps {
	value?: number;
	onChange?: (date: Date) => void;
	canEdit?: boolean;
}

const calendarIcon = <i className="tz-calendar-20" />;
const editIcon = <i className="tz-edit-16" />;

export const RegistrationDateMobile = memo((props: IRegistrationDateMobileProps) => {
	const {t} = useTranslation();
	const {value, canEdit, onChange} = props;

	const initialState = value ? new Date(value) : undefined;

	const [registrationDate, setRegistrationDate] = useState<Date | undefined>(initialState);
	const [isEditable, setIsEditable] = useBoolean(false);

	const handleDateChange = (date: Date) => {
		setRegistrationDate(date);
	};

	const onClickSave = () => {
		if (registrationDate) {
			onChange?.(registrationDate);
			setIsEditable(false);
		}
	};
	const onClickCancel = () => {
		setIsEditable(false);
		setRegistrationDate(initialState);
	};

	useUpdateEffect(() => {
		setRegistrationDate(initialState);
	}, [value]);

	return (
		<EntityGridItem
			label={t('warrantyClaimPage.info.entityGridItem.registrationDate.label')}
			icon={calendarIcon}
			fullRow
			buttons={
				canEdit &&
				!isEditable && (
					<InlineButton
						type="accent"
						leftIcon={editIcon}
						onClick={() => setIsEditable(true)}
					/>
				)
			}
		>
			{!isEditable && registrationDate && (
				<span className="semi-bold">{format(registrationDate, dateFormat)}</span>
			)}
			{isEditable && (

				<Dialog
					fullScreenOnTablet
					title={t('warrantyClaimPage.info.registrationDateMobile.dialog.title')}
					isOpen={isEditable}
					onAfterClose={() => setRegistrationDate(initialState)}
					onRequestClose={() => setIsEditable(false)}
					footer={
						<ActionButtons>
							<Button
								type="accent-blue"
								label={t('warrantyClaimPage.info.registrationDateMobile.dialog.button.accept')}
								onClick={onClickSave}
							/>
							<Button
								type="cancel"
								label={t('warrantyClaimPage.info.registrationDateMobile.dialog.button.cancel')}
								onClick={onClickCancel}
							/>
						</ActionButtons>
					}
				>
					<EditableDateField
						className="warranty-claim-page__registration-date__field"
						value={registrationDate}
						onChange={handleDateChange}
					/>
				</Dialog>
			)}
		</EntityGridItem>
	);
});
