import {IGetTasksResponse} from '@src/api/backend/tasks';
import {extractTasksAsArray} from '../selectors';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';

export const extractTasksByAcceptanceId = (data: IGetTasksResponse, workAcceptanceId: string) =>
	extractTasksAsArray(data).filter(
		task =>
			task.links?.workAcceptanceId === workAcceptanceId &&
			task.taskType === TaskTypeId.ACCEPTANCE_CALL
	);
