import {useCallback} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/interfaces';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {internalAcceptancesActions} from '@src/store/modules/settings/pages/internalAcceptances/slice';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';

export const InternalAcceptancesFiltersRoutingPage = () => {
	const {objectId} = useParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractInternalAcceptancesPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const {clearFilters, changeFilters, changeOffset} = internalAcceptancesActions;

	const applyFilters = useCallback(
		(value: IInternalAcceptancesFiltersState) => {
			dispatch(changeFilters({objectId: objectId || 'all', filters: value}));
			dispatch(changeOffset({objectId: objectId || 'all', offset: 0}));
		},
		[dispatch, changeFilters, objectId, changeOffset]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: 'all'}));
	}, [clearFilters, dispatch]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};
