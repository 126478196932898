import {StatusSelect} from '@tehzor/ui-components';
// import {CellProps} from 'react-table';
// import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import ClickPreventWrap from './ClickPreventWrap';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
// import {ILocalProblem} from '@src/interfaces/ILocalProblem';

// interface ILocalProblemStatusProps {
// 	problem: ILocalProblem;
// }

export const LocalProblemStatus = () => {
	const {data: statuses} = useProblemStatuses();
	return (
		<ClickPreventWrap>
			<StatusSelect
				style={{maxWidth: '100%'}}
				statuses={statuses}
				disabled
				value={ProblemStatusId.WAITING}
			/>
		</ClickPreventWrap>
	);
};

const LocalProblemStatusCell = () => <LocalProblemStatus />;

export default LocalProblemStatusCell;
