import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {testTaskRestrictions} from '@src/utils/testRestrictions';

interface ITasksPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
	canEditResponsible?: boolean;
	canEditStatus?: boolean;
}

interface IUseTasksPermissionsProps {
	objectId?: string,
	createdBy?: string,
	respUsers?: string[],
	watchers?: string[],
	activeGroupLeader?: string
}

export const useTasksPermissions = (data?: IUseTasksPermissionsProps): ITasksPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		return {
			canView: testTaskRestrictions({...data, userId: user.id, userRoles: user.roles, permissionId: 'tasks-view'}),
			canAdd: testTaskRestrictions({...data, userId: user.id, userRoles: user.roles, permissionId: 'tasks-add'}),
			canEdit: testTaskRestrictions({...data, userId: user.id, userRoles: user.roles, permissionId: 'tasks-edit'}),
			canDelete: testTaskRestrictions({...data, userId: user.id, userRoles: user.roles, permissionId: 'tasks-delete'}),
			canEditResponsible: testTaskRestrictions({...data, userId: user.id, userRoles: user.roles, permissionId: 'tasks-edit-responsible'}),
			canEditStatus:
				testTaskRestrictions({...data, userId: user.id, userRoles: user.roles, permissionId: 'tasks-edit-status'})
		};
	}, [data, user]);
};
