import {useMutation} from '@tanstack/react-query';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {addErrorToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {requestDeleteSpaceStatusesSet} from '@src/api/backend/spaceStatusesSets/delete';

interface IUseDeleteSpaceStatusesSetsParams {
	id: string;
}

export const useDeleteSpaceStatusesSets = () =>
	useMutation({
		mutationKey: spaceStatusesSetsQueryKeys.delete(),
		mutationFn: (params: IUseDeleteSpaceStatusesSetsParams) => {
			const {id} = params;
			return requestDeleteSpaceStatusesSet(id);
		},
		onError: () => {
			addErrorToast(
				'Наборы статусов помещений',
				'Не удалось удалить набор статусов помещений'
			);
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesSetsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
		}
	});
