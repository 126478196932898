import {createSelector} from 'reselect';
import {getInitialStateForPage} from '../reducers/lists';
import {IState} from '@src/store/modules';

/**
 * Извлекает данные для списка помещений конкретного объекта
 */
export const extractSpacesListData = createSelector(
	(state: IState) => state.entities.spaces.lists,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(data, objectId) => data[objectId] || getInitialStateForPage()
);

/**
 * Извлекает помещения в виде массива
 */
export const extractSpacesListAsArray = createSelector([extractSpacesListData], data =>
	data.allIds.map(id => data.byId[id]));

/**
 * Извлекает статус загрузки
 */
export const extractSpacesListLoadingState = createSelector(
	[extractSpacesListData],
	data => !data.loaded
);
