import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetSpaceStatusesResponse = INormalizedData<ISpaceStatus>;

/**
 * Возвращает статусы помещений
 */
export const requestGetSpaceStatuses = async () => {
	const response = await httpRequests.withToken.get<IGetSpaceStatusesResponse>('space-statuses');
	return response.data;
};
