import {Dispatch, useCallback} from 'react';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {InputType, ViewerType} from '@tehzor/ui-components/src/components/LocationSelect';
import {
	changeLocDefaultInputType,
	changeLocDefaultViewerType
} from '@src/store/modules/settings/locationSelect/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {usePlans} from '@src/core/hooks/queries/plans/hooks';
import {useSpacesData} from '@src/core/hooks/queries/spaces/hooks';
import {useSpaceTypes} from '@src/core/hooks/queries/spaceTypes/hooks';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {TranslatedLocationSelect} from '@src/components/TranslatedLocationSelect';

const locationIcon = <i className="tz-location-20" />;

interface ISpaceLocationSelectProps<S, E> {
	spaceEl: {
		spaceId: string;
		location:
			| {
					planId: string;
					location: ILocation;
			  }
			| undefined;
	};
	objectId: string;
	locations: Record<
		string,
		{
			planId: string;
			location: ILocation;
		}
	>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
}

export const SpaceLocationSelect = <S extends {locations?: ILocation | null}, E>({
	spaceEl,
	objectId,
	locations,
	editingDispatch
}: ISpaceLocationSelectProps<S, E>) => {
	const dispatch = useAppDispatch();
	const {data: spaceTypes} = useSpaceTypes();

	const handleLocatoinChange = useCallback(
		(spaceId: string, planId?: string, location?: ILocation | null) => {
			const value = locations;
			if (planId && location) {
				value[spaceId] = {planId, location};
			}
			editingDispatch({type: 'update', field: 'locations', value});
		},
		[locations]
	);

	const handleViewerTypeChange = useCallback(
		(v: ViewerType) => {
			dispatch(changeLocDefaultViewerType(objectId, v));
		},
		[objectId]
	);

	const handleInputTypeChange = useCallback(
		(v: InputType) => {
			dispatch(changeLocDefaultInputType(objectId, v));
		},
		[objectId]
	);

	const {data: plansMap} = usePlans(objectId);
	const {data: spacesMap} = useSpacesData(objectId);
	const space = spacesMap[spaceEl.spaceId];

	if (!space) {
		return null;
	}
	const location = spaceEl.location?.location;
	const plans =
		space.locations && plansMap
			? space.locations.map(spaceLocation => plansMap?.byId[spaceLocation.planId])
			: [];
	const planId = spaceEl.location?.planId;

	return (
		<div>
			<EditableFieldLabel>
				{spaceTypes
					? formSpaceTitle(space.name, undefined, spaceTypes.byId[space.type])
					: space.name}
			</EditableFieldLabel>
			<TranslatedLocationSelect
				key={spaceEl.spaceId}
				mode="edit"
				location={location}
				planId={planId}
				plans={planId && plansMap ? [plansMap?.byId[planId]] : plans}
				multiplePoints
				path="entity"
				onLocationChange={(spaceLocation: ILocation, planId?: string) =>
					handleLocatoinChange(spaceEl.spaceId, planId, spaceLocation)
				}
				onViewerTypeChange={handleViewerTypeChange}
				onInputTypeChange={handleInputTypeChange}
			>
				{(displayValue, open: () => void) => (
					<TextField
						className="text-field_interactive"
						disabled={!space.locations?.length}
						elementType="div"
						value={displayValue}
						icon={locationIcon}
						onClick={open}
					/>
				)}
			</TranslatedLocationSelect>
		</div>
	);
};
