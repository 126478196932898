import {memo} from 'react';
import {Button} from '@tehzor/ui-components';
import {useAddingOwnerAcptDialog} from '@src/components/AddingOwnerAcptDialog/hooks/useAddingOwnerAcptDialog';
import {useTranslation} from 'react-i18next';

interface IDesktopEntityAddingProps {
	objectId?: string;
}

const plusIcon = <i className="tz-plus-20" />;

export const DesktopEntityAdding = memo(({objectId = 'all'}: IDesktopEntityAddingProps) => {
	const {t} = useTranslation();
	const [dialog, open] = useAddingOwnerAcptDialog({
		title: t('ownerAcceptancesPage.addingDialog.title'),
		objectId
	});

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={plusIcon}
				label={t('addButton.label')}
				onClick={open}
			/>
			{dialog}
		</>
	);
});
