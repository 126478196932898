import {CellProps} from 'react-table';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {useTranslation} from 'react-i18next';

const LocationCell = ({row}: CellProps<IProblemEntity>) => {
	const {plan, floor, location, object, hasLocationComments} = row.original.data;
	const {t} = useTranslation();

	return (
		<div className="problems-page__d-table-location">
			{!!object && (
				<div className="problems-page__d-table-location-object">{object.name}</div>
			)}

			{(plan || floor || location) && (
				<div className="problems-page__d-table-location-block">
					{plan && (
						<div className="problems-page__d-table-location-line">
							<span className="problems-page__d-table-location-title">
								{t('problemsPage.locationCell.plan')}:
							</span>
							<div className="problems-page__d-table-location-subtitle">{plan}</div>
						</div>
					)}
					{floor && (
						<div className="problems-page__d-table-location-line">
							<span className="problems-page__d-table-location-title">
								{t('problemsPage.locationCell.floor')}:
							</span>
							<div className="problems-page__d-table-location-subtitle">{floor}</div>
						</div>
					)}
					{location && (
						<div className="problems-page__d-table-location-line">
							<span className="problems-page__d-table-location-title">
								{t('problemsPage.locationCell.location')}:
							</span>
							<div className="problems-page__d-table-location-subtitle">
								{location}
							</div>
						</div>
					)}
					{hasLocationComments && <i className="tz-comment-on-plan-16" />}
				</div>
			)}
		</div>
	);
};

export default LocationCell;
