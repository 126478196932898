import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '../StatusesFilterPage';
import {useExtractWorkAcceptanceTypesAsArray} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

export const WorkAcceptanceTypeFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity?: string}>();

	const {data: allTypes} = useExtractWorkAcceptanceTypesAsArray();
	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.workAcceptanceTypes,
		allTypes
	);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={translatedOptions}
			label={t('entitiesFilters.statusesFilter.workAcceptanceTypes.label')}
			filterName="type"
		/>
	);
};
