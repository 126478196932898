import { PureComponent } from 'react';
import * as React from 'react';
import './InfiniteScroller.less';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import ResizeObserver from 'resize-observer-polyfill';
import {Scrollbar} from '../Scrollbar';
import {convertClassNames} from '../../../utils/convertClassNames';

interface IInfiniteScrollerProps {
	className?: string | {
		root?: string;
		content?: string;
	};
	style?: React.CSSProperties;
	children?: React.ReactNodeArray;
	loader?: React.ReactNode;
	enabled?: boolean;
	reversed?: boolean;
	rootRef?: React.MutableRefObject<HTMLDivElement | null> | React.RefCallback<HTMLDivElement>;

	onLoad?: () => void;
}

class InfiniteScroller extends PureComponent<IInfiniteScrollerProps> {
	/**
	 * Корневой элемент, ограничивающий видимую область
	 */
	private root?: HTMLDivElement | null;

	/**
	 * Элемент, пересечение которого будет отслеживаться
	 */
	private track?: HTMLDivElement | null;

	/**
	 * IntersectionObserver
	 */
	private observer?: IntersectionObserver;

	/**
	 * Высота корневого элемента
	 */
	private height = 0;

	/**
	 * Функция удаления слушателя изменения размера элемента
	 */
	private removeResizeEventListener?: () => void;

	/**
	 * Обрабатывает событие изменения размера контейнера
	 */
	private handleResize = debounce(() => {
		this.saveHeight();
		this.updateTrackHeight();
	}, 150);

	getSnapshotBeforeUpdate(prevProps: IInfiniteScrollerProps) {
		// Запоминание положения скролла перед обновлением контента
		if (this.props.reversed && this.props.children !== prevProps.children && this.root) {
			return this.root.scrollHeight - this.root.scrollTop;
		}
		return null;
	}

	componentDidUpdate(prevProps: IInfiniteScrollerProps, prevState: unknown, snapshot: number | null) {
		// Установка положения скролла в ту же позицию, что была до обновления
		if (this.props.reversed && this.props.children !== prevProps.children && snapshot !== null && this.root) {
			this.root.scrollTop = React.Children.count(prevProps.children) === 0
				? this.root.scrollHeight - this.root.offsetHeight
				: this.root.scrollHeight - snapshot;
		}
		// Дозагрузка элементов, чтобы заполнилась вся область просмотра
		if (this.props.children?.length !== prevProps.children?.length) {
			this.checkIntersection();
		}
	}

	componentWillUnmount() {
		this.stopObservation();
		if (this.removeResizeEventListener) {
			this.removeResizeEventListener();
		}
	}

	render() {
		const {className, style, children, loader, enabled, reversed} = this.props;

		const classes = convertClassNames(className);

		return (
			<Scrollbar
				className={classNames('infinite-scroller', {'infinite-scroller_reversed': reversed}, classes.root)}
				style={style}
				simpleBarProps={{forceVisible: 'y'}}
				ref={this.saveRootRef}
			>
				<div className="infinite-scroller__wrapper">
					<div className={classNames('infinite-scroller__content', classes.content)}>
						{children}
					</div>

					{enabled && (
						<div className="infinite-scroller__loader">
							<div
								className="infinite-scroller__track"
								ref={this.saveTrackRef}
							/>
							{loader}
						</div>
					)}
				</div>
			</Scrollbar>
		);
	}

	/**
	 * Обрабатывает событие пересечения
	 *
	 * @param entries entries
	 */
	private handleIntersection = (entries: IntersectionObserverEntry[]) => {
		if (this.props.enabled && this.props.onLoad && entries.some(entry => entry.isIntersecting)) {
			this.props.onLoad();
		}
	};

	/**
	 * Проверяет пересечение track-элемента с областью просмотра,
	 * инициирует загрузку в случае вхождения
	 */
	private checkIntersection = () => {
		if (this.props.enabled && this.props.onLoad && this.track && this.root) {
			const trackRect = this.track.getBoundingClientRect();
			const rootRect = this.root.getBoundingClientRect();
			if (
				(trackRect.top >= rootRect.top && trackRect.top <= rootRect.bottom)
				|| (trackRect.bottom >= rootRect.top && trackRect.bottom <= rootRect.bottom)
			) {
				this.props.onLoad();
			}
		}
	};

	/**
	 * Инициирует отслеживание пересечения
	 */
	private startObservation = () => {
		if (this.root && this.track) {
			this.stopObservation();
			this.observer = new IntersectionObserver(this.handleIntersection, {root: this.root, threshold: 0});
			this.observer.observe(this.track);
		}
	};

	/**
	 * Завершает отслеживание пересечения
	 */
	private stopObservation = () => {
		if (this.observer) {
			this.observer.disconnect();
		}
	};

	/**
	 * Сохраняет высоту контейнера
	 */
	private saveHeight = () => {
		this.height = this.root ? this.root.offsetHeight : 0;
	};

	/**
	 * Обновляет высоту элемента для отслеживания пересечения
	 */
	private updateTrackHeight = () => {
		if (this.track) {
			// Для своевременной подзагрузки во время скролла устанавливается высота
			this.track.style.height = `${this.height}px`;
		}
	};

	/**
	 * Сохраняет ссылку на корневой элемент
	 *
	 * @param element html-элемент
	 */
	private saveRootRef = (element: HTMLDivElement | null) => {
		this.root = element;
		// Обновление размеров
		this.saveHeight();
		this.updateTrackHeight();
		// Добавление слушателя изменения размера
		if (this.removeResizeEventListener) {
			this.removeResizeEventListener();
		}
		if (this.root) {
			this.addResizeEventListener(this.root);
		}
		// Добавление слушателя пересечения
		this.startObservation();
		// Прокидывание ссылки родительскому компоненту
		if (this.props.rootRef) {
			if (typeof this.props.rootRef === 'function') {
				this.props.rootRef(this.root);
			} else {
				this.props.rootRef.current = this.root;
			}
		}
	};

	/**
	 * Сохраняет ссылку на элемент для отслеживания пересечения
	 *
	 * @param element html-элемент
	 */
	private saveTrackRef = (element: HTMLDivElement | null) => {
		this.track = element;
		this.updateTrackHeight();
		this.startObservation();
	};

	/**
	 * Добавляет слушатель изменения размера элемента
	 *
	 * @param element html-элемент
	 */
	private addResizeEventListener = (element: HTMLElement) => {
		const observer = new ResizeObserver(this.handleResize);
		observer.observe(element);
		this.removeResizeEventListener = () => observer.disconnect();
	};
}

export default InfiniteScroller;