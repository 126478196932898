import {IPaginationPageSizeProps, PaginationPageSize} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

type TranslatedPaginationPageSizeProps = IPaginationPageSizeProps;
export const TranslatedPaginationPageSize = (props: TranslatedPaginationPageSizeProps) => {
	const {t} = useTranslation();

	const translations = {
		paginationShowSizeLabel: t('paginationPageSize.showSize.label'),
		paginationShowPerPageLabel: t('paginationPageSize.perPage.label')
	};
	return (
		<PaginationPageSize
			translationsProps={translations}
			{...props}
		/>
	);
};
