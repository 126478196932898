import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import {CellProps} from 'react-table';

interface ICompanyCellProps extends CellProps<IPreparedContract> {
	isMobile?: boolean;
}

export const CompanyCell = ({row, isMobile}: ICompanyCellProps) => (
	<div className="contracts__cell-company">
		{isMobile && <div className="contracts__cell-company-header">Заказчик:</div>}
		<div className="contracts__cell-company-name">
			<span className="contracts__cell-company-name-text">{row.original.company?.name}</span>{' '}
			<span className="contracts__cell-company-name-add-text">
				{row.original.companyLegalEntity?.name}
			</span>
		</div>
	</div>
);
