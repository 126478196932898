import {
	TextFieldWithForwardedRef,
	Checkbox,
	TreeSelect
} from '@tehzor/ui-components/src/components/inputs';
import {
	IEditableCategoriesSetAction,
	IEditableCategoriesSetState
} from '@src/core/hooks/states/useCategoriesSetState';
import { Dispatch, useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import './EditableRelations.less';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {getTitle} from './getTitle';
import {Button, Dialog} from '@tehzor/ui-components';
import classNames from 'classnames';
import {useObjectsAsArray, useObjectsMap} from '@src/core/hooks/queries/objects/hooks';
import {useCompaniesAsArray, useCompaniesById} from '@src/core/hooks/queries/companies/hooks';

interface IEditableRelationsProps {
	isSharable?: boolean;

	required?: boolean;
	disabled?: boolean;

	setEdited: (value: React.SetStateAction<boolean>) => void;
	editingState: IEditableCategoriesSetState;
	editingDispatch: Dispatch<IEditableCategoriesSetAction>;
}

const editIcon = <i className="tz-edit-20" />;

const EditableRelations = ({
	isSharable,
	required,
	disabled,
	setEdited,
	editingDispatch,
	editingState
}: IEditableRelationsProps) => {
	const {data: allCompanies}  = useCompaniesAsArray();
	const {data: companiesMap} = useCompaniesById();

	const allObjects = useObjectsAsArray();
	const objectsMap = useObjectsMap();

	const [isOpen, setOpen] = useState(false);
	const [expanded, setExpanded] = useState<string[]>();
	const [treeData, setTreeData] = useState<string[]>([
		...editingState.companies,
		...editingState.objects
	]);

	const hasError = useMemo(() => {
		const {errors, shared} = editingState;

		if (!shared) {
			return errors.companies && errors.objects;
		}
		return false;
	}, [editingState.errors, editingState.shared]);

	const title = useMemo(() => {
		if (!objectsMap || !companiesMap)  return '';
		return getTitle(treeData, companiesMap, objectsMap);
	}, [treeData, companiesMap, objectsMap]);

	const objectsTree = useMemo(() => {
		if (!allObjects) return [];
		return makeObjectsTreeData(allObjects, allCompanies);
	}, [allCompanies, allObjects]);

	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => setOpen(false), []);

	const handleConfirm = useCallback(() => {
		const companies: string[] = [];
		const objects: string[] = [];

		for (const id of treeData) {
			const companyFound = allCompanies?.find(company => company.id === id);

			if (companyFound) {
				companies.push(id);
			} else {
				objects.push(id);
			}
		}

		setEdited(true);
		editingDispatch({type: 'update', field: 'companies', value: companies});
		editingDispatch({type: 'update', field: 'objects', value: objects});
		handleClose();
	}, [treeData, allCompanies]);

	const handleCancel = useCallback(() => {
		const companies = editingState?.companies ? editingState.companies : [];
		const objects = editingState?.objects ? editingState.objects : [];

		editingDispatch({type: 'update', field: 'companies', value: companies});
		editingDispatch({type: 'update', field: 'objects', value: objects});
		setTreeData([...companies, ...objects]);
		handleClose();
	}, [editingState]);

	const handleCheckbox = useCallback(() => {
		const {shared} = editingState;
		const value = !shared;

		if (value === shared) {
			return;
		}
		if (treeData.length && !shared) {
			setTreeData([]);
			editingDispatch({type: 'update', field: 'companies', value: []});
			editingDispatch({type: 'update', field: 'objects', value: []});
		}

		setEdited(true);
		editingDispatch({type: 'update', field: 'shared', value});
	}, [editingState, treeData.length]);

	const handleChange = useCallback(
		(ids: string[]) => {
			setTreeData(ids);
		},
		[editingState, allCompanies]
	);

	return (
		<div className="editable-relations">
			<TextFieldWithForwardedRef
				className={classNames('editable-relations__text-field', {
					disabled: disabled || editingState.shared
				})}
				elementType="div"
				value={title}
				icon={editIcon}
				error={required && hasError ? 'Выберите компанию/объект' : undefined}
				disabled={disabled || editingState.shared}
				onClick={handleOpen}
			/>
			<Dialog
				shouldCloseOnEsc
				isOpen={isOpen}
				fullScreenOnMobile
				onRequestClose={handleCancel}
				title="Компании/Объекты"
				footer={
					<div className="editable-stages__actions">
						<Button
							label="Применить"
							type="accent-blue"
							onClick={handleConfirm}
						/>
						<Button
							label="Сбросить"
							type="cancel"
							onClick={handleCancel}
						/>
					</div>
				}
			>
				<TreeSelect
					data={objectsTree}
					value={treeData}
					onChange={handleChange}
					expandedValue={expanded}
					onExpand={setExpanded}
					multiple
				/>
			</Dialog>

			<Checkbox
				className="editable-relations__checkbox"
				checked={editingState.shared}
				onClick={!disabled && isSharable ? handleCheckbox : undefined}
				disabled={disabled || !isSharable}
			>
				Общий
			</Checkbox>
		</div>
	);
};
export default EditableRelations;
