import React, {useCallback, useState} from 'react';
import {AddingMeterConsumptionDialog} from '../AddingMeterConsumptionDialog';

export function useAddingMeterConsumptionDialog(
	companyId?: string,
	meterId?: string
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = isOpen ? (
		<AddingMeterConsumptionDialog
			companyId={companyId}
			meterId={meterId}
			isOpen={isOpen}
			onClose={close}
		/>
	) : null;

	return [dialog, open];
}
