import {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import {StructuresTable} from '@/widgets/StructuresTable';
import {StructuresSchema} from '@/widgets/StructuresSchema';
import {extractStructuresPageSettings} from '@/entities/Structures';

interface IMainProps {
	objectId: string;
	spaceId: string | undefined;
}

export const Main = memo(({objectId, spaceId}: IMainProps) => {
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));

	if (displayMode === StructuresDisplayMode.LIST) {
		return (
			<StructuresTable
				objectId={objectId}
				spaceId={spaceId}
			/>
		);
	}

	if (displayMode === StructuresDisplayMode.SCHEMA) {
		return <StructuresSchema objectId={objectId} />;
	}

	return null;
});
