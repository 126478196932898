import styles from './DiagramIcon.module.less';
import classNames from 'classnames';

interface IDiagramIconProps {
	color?: string;
	className?: string;
	isExternal?: boolean;
}

export const DiagramIcon = ({color, className, isExternal}: IDiagramIconProps) => {
	const iconStyles = {
		backgroundColor: isExternal ? 'transparent' : color,
		borderColor: isExternal ? color : 'transparent'
	};

	return <div className={classNames(styles.wrapper, className)} style={iconStyles} />;
};
