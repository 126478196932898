import MobileMenu from './Menu.mobile';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';

interface IMobileActionsProps {
	objectId: string;
	ownerAcceptance: ILinkedOwnerAcceptance;
}

const ActionsMobile = ({objectId, ownerAcceptance}: IMobileActionsProps) => {
	const permissions = useWarrantyClaimsPermissions(objectId);

	return (
		<MobileMenu
			ownerAcceptance={ownerAcceptance}
			canDeleteOwnerAcceptance={permissions.canDelete}
		/>
	);
};

export default ActionsMobile;
