import useEditableCategoriesSet from '@src/components/AddingCategoriesSet/hooks/useEditableCategoriesSet';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import declination from '@tehzor/tools/utils/declination';
import {Plate} from '@tehzor/ui-components';
import {useMemo} from 'react';
import CategoriesSetInfo from './CategoriesSetInfo';
import CategoriesSetMenu from './CategoriesSetMenu';

interface ICategoriesSetContentProps {
	categoriesSet?: ICategoriesSet;
	categories?: ICategory[];
	inDialog?: boolean;
}

const CategoriesSetContent = ({
	categories,
	categoriesSet,
	inDialog
}: ICategoriesSetContentProps) => {
	const {nameEl, relationsEl, addButtonEl, saveButtonsEl, categoriesEl, stagesEl, externalIdEl} =
		useEditableCategoriesSet(categoriesSet, categories, inDialog);
	const total = useMemo(() => categories?.length ?? 0, [categories]);
	const words = ['вид работ', 'вида работ', 'видов работ'];
	const amountOfCategories = useMemo(() => `${total} ${declination(total, words)}`, [total]);

	return (
		<>
			<div className="categories-set__controls">
				<p>{amountOfCategories}</p>
				<div className="categories-set__controls-options">
					{addButtonEl}
					{!!categoriesSet && <CategoriesSetMenu set={categoriesSet} />}
				</div>
			</div>
			<div className="categories-set__wrap">
				<CategoriesSetInfo
					set={categoriesSet}
					stagesEl={stagesEl}
					externalIdEl={externalIdEl}
					nameEl={nameEl}
					relationsEl={relationsEl}
					saveButtonsEl={saveButtonsEl}
					className="categories-set__wrap-info"
				/>
				<Plate className={{content: 'categories-set__wrap-table'}}>
					<div className="categories-set__wrap-table__title">Виды работ</div>
					<div className="categories-set__wrap-table__text">{categoriesEl}</div>
				</Plate>
			</div>
		</>
	);
};

export default CategoriesSetContent;
