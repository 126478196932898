import {RefObject, useEffect, useRef} from 'react';
import {DndAttribute} from '../interfaces';

export const useDndHandle = <S extends HTMLElement>(
	isDragging?: boolean, isDraggable?: boolean
): RefObject<S> => {
	const ref = useRef<S>(null);

	// Добавляет аттрибуты в ref handle'a
	useEffect(() => {
		if (ref.current === null) { return; }

		ref.current.setAttribute(DndAttribute.HANDLE, String(true));
	}, [isDragging, isDraggable]);

	return ref;
};