import {isEqual} from 'lodash';
// import pickBy from 'lodash/pickBy';
// import identity from 'lodash/identity';

type InputObject = Record<string, unknown>;

/**
 * Проверят идентичность фильтров и локального состояния
 *
 * @param obj1 локальное состояние
 * @param obj2 фильтры

 */
export function isStateEqual<T extends InputObject>(obj1: T, obj2: T): boolean {
	const state: T = {...obj1};
	const o1: T = {} as T;
	const filters: T = {...obj2};
	const o2: T = {} as T;

	function create(o: T = {} as T, sta: T) {
		for (const key in sta) {
			if (sta.hasOwnProperty(key)) {
				if (Array.isArray(sta[key])) {
					(o as InputObject)[key] = [...(sta[key] as unknown[])].sort();
				} else if (sta[key] !== undefined) {
					o[key] = sta[key];
				}
			}
		}
		return o;
	}
	return isEqual(create(o1, state), create(o2, filters));
}
