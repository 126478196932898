import {IState} from '@src/store/modules';
import {createSelector} from 'reselect';

const getPerformersAllIds = (state: IState) => state.pages.problem.performersDelegationHistories.allIds || [];
const getPerformersById = (state: IState) => state.pages.problem.performersDelegationHistories.byId || {};

const getInspectorsAllIds = (state: IState) => state.pages.problem.inspectorsDelegationHistories.allIds || [];
const getInspectorsById = (state: IState) => state.pages.problem.inspectorsDelegationHistories.byId || {};


/**
 * Возвращает записи истории делегирования исполнителей в виде массива
 */
export const extractProblemPerformersDelegationHistoriesAsArray = createSelector(
	[getPerformersAllIds, getPerformersById],
	(allIds, byId) => allIds.map(id => byId[id])
);

/**
 * Возвращает записи истории делегирования проверяющих в виде массива
 */
export const extractProblemInspectorsDelegationHistoriesAsArray = createSelector(
	[getInspectorsAllIds, getInspectorsById],
	(allIds, byId) => allIds.map(id => byId[id])
);
