import {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
	DateTimeRangePicker,
	IDateTimePickerProps,
	IDateTimePickerTranslations
} from '@tehzor/ui-components';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface TranslatedDateTimeRangePicker extends Omit<IDateTimePickerProps, 'translations'> {
	translations?: IDateTimePickerTranslations;
}

export const TranslatedDateTimeRangePicker = memo((props: TranslatedDateTimeRangePicker) => {
	const {t} = useTranslation();

	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	const translations = useMemo<IDateTimePickerTranslations>(
		() => ({
			selectApplyBtnLabel: t('applyBtn.label'),
			selectCancelBtnLabel: t('cancelBtn.label'),
			start: t('tasksPage.dateTimeSelect.start'),
			fullStart: t('tasksPage.dateTimeSelect.fullStart'),
			end: t('tasksPage.dateTimeSelect.end'),
			fullEnd: t('tasksPage.dateTimeSelect.fullEnd')
		}),
		[t]
	);

	const dialogProps = useMemo(() => ({title: t('tasksPage.dateTimeSelect.dialogProps')}), [t]);

	return (
		<DateTimeRangePicker
			{...props}
			datesOptions={datesOptions}
			translations={translations}
			dialogProps={dialogProps}
		/>
	);
});
