import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Удаляет метку *
 * @param id id метки
 * @param problemTagsSetId id набора
 */
export const makeProblemTagDeleteRequest = async (id: string, problemTagsSetId: string) => {
	const response = await httpRequests.withToken.post<{id: string}>('/problem-tags/delete', {
		id,
		problemTagsSetId
	});
	return response.data;
};
