import {forwardRef, Ref, useCallback, useImperativeHandle} from 'react';
import {useEditableSpaceOwner} from '@src/components/EditableSpaceOwner/hooks/useEditableSpaceOwner';
import {useAddNewSpaceOwner} from '@src/core/hooks/mutations/spaceOwners/useAddSpaceOwner';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IAddingSpaceOwnerProps {
	companyId?: string;
	spaceId?: string;
	saving: boolean;

	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IAddingSpaceOwnerRefProps {
	save: () => void;
	cancel: () => void;
}

const AddingSpaceOwner = (props: IAddingSpaceOwnerProps, ref?: Ref<IAddingSpaceOwnerRefProps>) => {
	const {companyId, spaceId, saving, onClose, setSaving} = props;
	const addSpaceOwner = useAddNewSpaceOwner();

	const [fields, getSavingData, , isBlocking] = useEditableSpaceOwner(undefined, saving);

	const save = useCallback(() => {
		setSaving(true);
		if (companyId && spaceId) {
			const savingData = getSavingData();
			if (savingData) {
				try {
					savingData.spaces = [spaceId];
					addSpaceOwner(companyId, savingData);
					onClose();
				} catch (error) {
					setSaving(false);
					throw error;
				}
			}
		}
		setSaving(false);
	}, [companyId, spaceId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	useImperativeHandle(ref, () => ({save, cancel}), [save, cancel]);

	return (
		<>
			{fields}
			{closingDialog}
		</>
	);
};

export default forwardRef<IAddingSpaceOwnerRefProps, IAddingSpaceOwnerProps>(AddingSpaceOwner);
