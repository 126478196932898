import {useMemo} from 'react';
import {CheckListPlate} from '../CheckListPlate';
import {DesktopDetailColumn} from '@src/pages/CheckListPage/components/DesktopDetailColumn';
import {LoadingPanel} from '@tehzor/ui-components';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

export const CheckListsDesktopRoutes = () => {
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId: string;
	}>();
	const {data: workAcceptance} = useWorkAcceptance(workAcceptanceId, objectId);
	const structureTypes = useMemo(
		() =>
			workAcceptance?.structures
				? Array.from(new Set(workAcceptance.structures.map(str => str.type)))
				: undefined,
		[workAcceptance?.structures]
	);
	const spaceTypes = useMemo(
		() =>
			workAcceptance?.spaces
				? Array.from(new Set(workAcceptance.spaces.map(space => space.type)))
				: undefined,
		[workAcceptance?.spaces]
	);
	const checkLists = useAvailableCheckLists(
		objectId,
		undefined,
		workAcceptance?.stage,
		CheckListTypeId.ACCEPTANCES,
		structureTypes,
		workAcceptance?.categoryId,
		spaceTypes
	);
	const isDesktop = useIsDesktop();
	const listsLoading = useCheckListsIsLoading();

	const checkListsRoute = isDesktop ? (
		<div className="work-acceptance-page__check-list__d-main">
			<div className="work-acceptance-page__check-list__d-main-list">
				<LoadingPanel active={listsLoading}>
					<>
						{checkLists.map(checkList => (
							<CheckListPlate
								key={checkList.id}
								defaultOpen={checkLists.length === 1}
								checkList={checkList}
							/>
						))}
					</>
				</LoadingPanel>
			</div>

			<div className="work-acceptance-page__check-list__d-main-detail">
				<DesktopDetailColumn />
			</div>
		</div>
	) : null;

	return checkListsRoute;
};
