import {CSSProperties, Dispatch, useCallback, useMemo} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import './SpaceTypeDecorationSelect.less';
import {makeTreeDataSpaceTypeDecoration} from '../SpaceTypeDecorationTreeSelect/utils/makeTreeData';
import {
	useSpaceTypeDecorationsAsArray,
	useSpaceTypeDecorationsAsMap
} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {useExtractSpaceTypeDecorationSetsAsArray} from '@src/core/hooks/queries/SpaceTypeDecorationSets/hooks';

interface ISpaceTypeDecorationSelectProps<S, E> {
	field?: keyof S;
	value?: string;
	className?: string;
	style?: CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	externalTypeDecorations?: ISpaceTypeDecoration[];
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: string | undefined,
	typeDecorationsById: Record<string, ISpaceTypeDecoration> | undefined
) => {
	if (value !== undefined && typeDecorationsById?.[value]) {
		return typeDecorationsById[value]?.name;
	}

	return undefined;
};

const arrowIcon = <i className="tz-simple-arrow-20" />;

/**
 * Возврашает поле одиночного выбора типа отделки помещения сгруппированные по наборам,
 * типы отделки по умолчанию получаются селектором из state.entities.spaceTypeDecorations
 * или пердается снаружи через пропс externalTypeDecorations
 */
export const SpaceTypeDecorationSelect = <S extends {typeDecoration?: 'typeDecoration'}, E>({
	className,
	style,
	field = 'typeDecoration',
	label = 'Тип отделки помещения',
	value,
	externalTypeDecorations,
	editingDispatch,
	required,
	disabled,
	hasError
}: ISpaceTypeDecorationSelectProps<S, E>) => {
	const {data: typeDecorationsById} = useSpaceTypeDecorationsAsMap();
	const {data: internalTypeDecorations} = useSpaceTypeDecorationsAsArray();
	const {data: typeDecorationSets} = useExtractSpaceTypeDecorationSetsAsArray();

	const treeData = useMemo(() => {
		const typeDecorations = externalTypeDecorations || internalTypeDecorations || [];
		if (!typeDecorationSets) return null;
		return makeTreeDataSpaceTypeDecoration(typeDecorations, typeDecorationSets);
	}, [externalTypeDecorations, internalTypeDecorations, typeDecorationSets]);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[editingDispatch, field, required]
	);

	const onClear = useCallback(() => {
		editingDispatch({type: 'update', field, value: undefined});
	}, [editingDispatch, field]);

	if (!treeData) return null;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, typeDecorationsById)}
						cleanable={!!value}
						onClearClick={onClear}
						icon={arrowIcon}
						error={required && hasError ? 'Выберите тип отделки помещения' : undefined}
						disabled={disabled}
					/>
				}
			>
				<TreeSelect
					data={treeData}
					value={value}
					onChange={handleChange}
				/>
			</SelectPopup>
		</div>
	);
};
