import {ICustomFieldSetting} from '@tehzor/tools/interfaces/fields';
import {CellProps} from 'react-table';
import {CustomCell} from '@src/components/CustomCell/CustomCell';
import {ColumnWithSettings} from '@src/core/hooks/settings/useColumnsWithSettings/interfaces';

export const getCustomColumns = <T extends {data: {customFields?: Record<string, unknown>}}>(
	customSettings: Record<string, ICustomFieldSetting>
): Array<ColumnWithSettings<T>> =>
	Object.entries(customSettings).map(([id, {fieldKey, fieldName, valueTypeId}]) => ({
		id,
		Header: fieldName,
		Cell: ({row}: CellProps<T>) =>
			CustomCell({
				valueType: valueTypeId,
				value: row.original.data.customFields
					? row.original.data.customFields[fieldKey]
					: undefined
			}),
		width: 130,
		minWidth: 130,
		sortDescFirst: true,
		customizeSettings: {
			controlled: true,
			isCustom: true
		}
	}));
