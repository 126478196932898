import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery} from '@tanstack/react-query';
import {IGetSpaceOwnerAcceptancesResponse} from '../interfaces';
import {extractOwnerAcceptanceAllIds, extractOwnerAcceptancesAsArray} from './selectors';

export const useSpaceOwnerAcceptances = <T = IGetSpaceOwnerAcceptancesResponse>(
	objectId: string,
	spaceId: string,
	permission = true,
	select?: (data: IGetSpaceOwnerAcceptancesResponse) => T
) =>
	useQuery({
		queryKey: spacesQueryKeys.spaceOwnerAcceptances(objectId, spaceId),
		meta: {
			error: 'при загрузке передач собственникам'
		},
		select,
		enabled: permission
	});

export const useSpaceOwnerAcceptancesAsArray = (
	objectId: string,
	spaceId: string,
	permission = true
) => useSpaceOwnerAcceptances(objectId, spaceId, permission, extractOwnerAcceptancesAsArray);

export const useExtractSpaceOwnerAcceptanceAllIds = (
	objectId: string,
	spaceId: string,
	permission = true
) => useSpaceOwnerAcceptances(objectId, spaceId, permission, extractOwnerAcceptanceAllIds);
