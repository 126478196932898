import {IEditableWorkingGroupState} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {useCallback, useState} from 'react';
import * as React from 'react';
import {EditableResponsibilityRulesDialog} from '../EditableResponsibilityRulesDialog';
import {useCompanyWithContractorsUsersAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useResponsibilityRulesForWorkingGroupAsArray} from '@src/core/hooks/queries/responsibilityRules/hooks';
import {useWorkingGroupsByParentIdAsArray} from '@src/core/hooks/queries/workingGroups/hooks';

interface IUserTarget {
	userId: string;
	groupId?: undefined;
}

interface IGroupTarget {
	groupId: string;
	userId?: undefined;
}
export function useEditableResponsibilityRulesDialog(
	editingState: IEditableWorkingGroupState,
	workingGroupId?: string
): [React.ReactNode, (target: IUserTarget | IGroupTarget) => void] {
	const {data: rules} = useResponsibilityRulesForWorkingGroupAsArray(workingGroupId);
	const {data: allUsers} = useUsersAsMap();
	const {data: users} = useCompanyWithContractorsUsersAsArray(allUsers, editingState.companyId);
	const {data: groups} = useWorkingGroupsByParentIdAsArray(workingGroupId);

	const [isOpen, setOpen] = useState<boolean>(false);
	const [target, setTarget] = useState<IUserTarget | IGroupTarget | undefined>(undefined);

	const open = useCallback((target: IUserTarget | IGroupTarget) => {
		setTarget(target);
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = target ? (
		<EditableResponsibilityRulesDialog
			target={target}
			rules={rules}
			groups={groups}
			users={users}
			isOpen={isOpen}
			onClose={close}
			editingState={editingState}
		/>
	) : null;

	return [dialog, open];
}
