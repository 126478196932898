import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export const CompaniesFilterPage = () => {
	const {t} = useTranslation();
	const {entity = 'contracts'} = useParams<{entity?: string}>();
	const {data: companies} = useCompaniesAsArray();

	return (
		<StatusesFilterPage
			entity={entity}
			statuses={companies}
			label={t('entitiesFilters.contractsFiltersPage.companiesFilter.label')}
			filterName="companyIds"
		/>
	);
};
