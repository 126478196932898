import {ResponsiblesFilter} from '@src/components/EntitiesFilters/components/ResponsiblesFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useInspectorsGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';
import {memo, useCallback} from 'react';

interface IInspectorsFilterProps {
	objectId: string;
	inspectors?: string[];
	label?: string;
}

export const InspectorsFilter = memo(({objectId, inspectors, label}: IInspectorsFilterProps) => {
	const {data: workingGroups} = useInspectorsGroupsAsArray(objectId);
	const {dispatch} = useEntitiesFiltersCtx();

	const handleChange = useCallback(
		(value?: string[]) => {
			dispatch({inspectors: value});
		},
		[dispatch]
	);

	return (
		<ResponsiblesFilter
			respUsers={inspectors}
			workingGroups={workingGroups}
			onChange={handleChange}
			label={label}
		/>
	);
});
