import {format} from 'date-fns';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {ValueTypeId} from '@tehzor/tools/interfaces/fields';
import './CustomCell.less';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface ICustomCellProps {
	valueType: ValueTypeId;
	value: unknown;
}

export const CustomCell = ({valueType, value}: ICustomCellProps) => {
	const locale = useDateFnsLocale();

	if (!value) {
		return null;
	}

	switch (valueType) {
		case ValueTypeId.SELECT:
			return <p className="custom-cell__text">{String(value)}</p>;
		case ValueTypeId.DATE:
			return (
				<p className="custom-cell__text">{format(Number(value), dateFormat, {locale})}</p>
			);
		case ValueTypeId.TEXT:
			return <p className="custom-cell__text">{String(value)}</p>;
		default:
			return null;
	}
};
