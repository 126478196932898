import {Responsibles} from '@src/components/editableFields/Responsibles';
import {useUser} from '@src/core/hooks/queries/users/hooks';
import {
	IEditableProblemAction,
	IEditableProblemState
} from '@src/core/hooks/states/useEditableProblemState';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {Dispatch, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import isEqual from 'lodash/isEqual';

interface IEditableProblemInspectorsProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	createdBy?: string;

	editingState: IEditableProblemState;
	editingDispatch: Dispatch<IEditableProblemAction>;

	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	exception?: boolean;
}

const getFirstInspector = (
	inspectors: string[],
	activeGroup: string | undefined,
	currentUser: IBriefUser
): string | undefined => {
	if (inspectors.length || (activeGroup && !inspectors.length)) {
		return undefined;
	}

	return currentUser.id;
};

const inspectorIcon = <i className="tz-inspector-20" />;

export const EditableProblemInspectors = ({
	objectId,
	stage,
	scope,
	createdBy,
	editingState,
	editingDispatch,
	required,
	disabled,
	hasError,
	exception
}: IEditableProblemInspectorsProps) => {
	const {t} = useTranslation();

	const {categoryId, planId, inspectors, inspectorsActiveGroup, initialRuleParams} = editingState;
	const ruleParams: IRuleParams = useMemo(
		() => ({
			categoryId: categoryId || undefined,
			planId
		}),
		[categoryId, planId]
	);

	const user = useAppSelector(extractUserProfile);
	const {data: author} = useUser(createdBy);

	useEffect(() => {
		const newInspector = getFirstInspector(inspectors, inspectorsActiveGroup, author ?? user);

		if (newInspector) {
			editingDispatch({type: 'update', field: 'inspectors', value: [newInspector]});
			editingDispatch({type: 'update', field: 'firstAssignedInspector', value: newInspector});
		}
	}, [inspectors, inspectorsActiveGroup, author, user]);

	const handleChange = useCallback(
		(users: string[], group?: string) => {
			editingDispatch({type: 'update', field: 'inspectors', value: users});
			if (required) {
				editingDispatch({type: 'update-error', field: 'inspectors'});
			}

			editingDispatch({type: 'update', field: 'inspectorsActiveGroup', value: group});
			if (required) {
				editingDispatch({type: 'update-error', field: 'inspectorsActiveGroup'});
			}
		},
		[required]
	);

	const handleUpdateException = useCallback(
		(value: boolean) => {
			if (exception) {
				return;
			}

			editingDispatch({type: 'update-exception', field: 'performers', value});
		},
		[exception]
	);

	const checkDoSuggestions = useCallback(() => {
		if (isEqual(initialRuleParams, ruleParams)) {
			return false;
		}
		editingDispatch({type: 'update', field: 'initialRuleParams', value: undefined});
		return true;
	}, [initialRuleParams, ruleParams]);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			scope={scope}
			workingGroupType={WorkingGroupTypeId.INSPECTORS}
			respUsers={inspectors}
			activeGroup={inspectorsActiveGroup}
			ruleParams={ruleParams}
			author={author ?? user}
			label={t('components.editableProblem.inspectors.label')}
			errorLabel={t('components.editableProblem.inspectors.errorLabel')}
			dialogTitle={t('components.delegationDialog.inspectors.title')}
			icon={inspectorIcon}
			required={required}
			disabled={disabled}
			hasError={hasError}
			onResponsiblesChange={handleChange}
			onUpdateException={handleUpdateException}
			checkDoSuggestions={checkDoSuggestions}
		/>
	);
};
