import {IEditableStatus} from '@src/core/hooks/states/useSpaceStatusesSetState';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IExtendedSpaceStatus} from '../interfaces/IExtendedSpaceStatus';
import {orderBy} from 'lodash';

export const getExtendedStatusesByStage = (
	statuses: IEditableStatus[],
	stagesMap: Record<ObjectStageIds, IObjectStage>,
	stage: IObjectStage,
	editedStatuses: string[]
): {statusesByStage: IExtendedSpaceStatus[], draggable: string[]} => {
	const draggable: string[] = [];
	const statusesWithStages = statuses?.map((status, index) => ({
		...status, index, stage: stagesMap[status.stage]
	}));

	const sortedStatuses = orderBy(
		statusesWithStages, ['stage.name', 'order'], ['desc', 'asc']
	);

	const statusesByStage: IExtendedSpaceStatus[] = [];
	sortedStatuses.forEach((status, i) => {
		if (status.stage.id !== stage.id) { return; }
		if (!status.isLocal && !editedStatuses.includes(status.id)) {
			draggable.push(status.id);
		}

		const extendedStatus = {...status, number: i + 1, stage: stage.id};
		statusesByStage.push(extendedStatus);
	})

	return {statusesByStage, draggable};
}