import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {IState} from '@src/store/modules';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {ILoginResponse, login as apiLogin} from '@src/api/backend/auth';

export const loginRequest = () => ({type: types.LOGIN_REQUEST});

export const loginSuccess
	= (response: ILoginResponse): ThunkAction<void, IState, null, Action> =>
	(dispatch, getState: () => IState) => {
		const state = getState();
		// Если до этого был залогинен другой пользователь, то все его локальные данные необходимо удалить
		if (
			state.auth.authSession.previousUserId
			&& state.auth.authSession.previousUserId !== response.user.id
		) {
			dispatch({type: types.CLEAR_STORE});
		}
		dispatch({
			type: types.LOGIN_SUCCESS,
			payload: response.user
		});
	};

export const loginFailure = (error: IError) => ({
	type: types.LOGIN_FAILURE,
	payload: error
});

/**
 * Аутентифицирует по email и паролю
 *
 * @param email email пользователя
 * @param password пароль пользователя
 */
export const login
	= (email: string, password: string): ThunkAction<Promise<ILoginResponse>, IState, null, Action> =>
	async dispatch => {
		dispatch(loginRequest());
		try {
			const response = await apiLogin(email, password);
			dispatch(loginSuccess(response));
			return response;
		} catch (error) {
			dispatch(loginFailure(error));
			return Promise.reject(error);
		}
	};
