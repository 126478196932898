import { Dispatch, useCallback } from 'react';
import EntitiesFilters, {StatusesFilter} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IObjectsFiltersState} from '@src/store/modules/settings/pages/objects/reducers';
import {SelectSearch} from '@tehzor/ui-components';
import {useObjectsCitiesAsArray} from '@src/core/hooks/queries/objects/hooks';
import {useMainAndSubCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from "react-i18next";

interface IObjectsFiltersProps {
	search?: string;
	setSearch: Dispatch<string | undefined>;
}

const ObjectsFilters = ({search, setSearch}: IObjectsFiltersProps) => {
	const {t} = useTranslation();

	const handleChange = useCallback((val: string) => setSearch(val), []);
	const handleClear = useCallback(() => setSearch(undefined), []);

	const {state} = useEntitiesFiltersCtx<IObjectsFiltersState>();
	// TZ-2070 Разкомментить после исправления фильтров по стадии объектов
	// const {data: stages} = useObjectStagesAsArray();
	const cities = useObjectsCitiesAsArray();
	const {data} = useMainAndSubCompaniesAsArray();
	const companies = data?.mainCompanies;

	return (
		<div className="objects-page__filters">
			<SelectSearch
				className="objects-page__filters-search"
				type="filter"
				placeholder={t('objectsPage.filters.search')}
				value={search}
				onChange={handleChange}
				onClear={handleClear}
			/>
			<EntitiesFilters
				className="objects-page__filters-entities"
				entity="objects"
			>
				{companies && (
					<StatusesFilter
						label={t('objectsPage.filters.company')}
						filterName="companies"
						statuses={state?.companies}
						allStatuses={companies}
					/>
				)}

				{cities && (
					<StatusesFilter
						label={t('objectsPage.filters.city')}
						filterName="cities"
						statuses={state?.cities}
						allStatuses={cities}
					/>
				)}
				{/*  // TZ-2070  Разкомментить после исправления фильтров по стадии объектов
					<StatusesFilter
					label="Стадия объекта"
					filterName="stages"
					statuses={state?.stages}
					allStatuses={stages}
				/> */}
			</EntitiesFilters>
		</div>
	);
};

export default ObjectsFilters;
