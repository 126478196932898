import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import {IContract} from '@tehzor/tools/interfaces/contracts';
import {getPermissions} from './getPermissions';
import {IPreparedContract} from '../interfaces/IPreparedContract';
import {IBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {IAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export const convertContracts = (
	contracts: IContract[],
	legalsMap: Record<string, ILegalEntity>,
	bankAccountsMap: Record<string, IBankAccount>,
	authorizedPersonsMap: Record<string, IAuthorizedPerson>,
	roles?: IUserRole[]
): IPreparedContract[] =>
	contracts.map(contract => ({
		...contract,
		...getPermissions(roles),
		companyLegalEntity: contract.companyLegalEntityId
			? legalsMap[contract.companyLegalEntityId]
			: undefined,
		contractorLegalEntity: contract.contractorLegalEntityId
			? legalsMap[contract.contractorLegalEntityId]
			: undefined,
		companyBankAccount: contract.companyBankAccountId
			? bankAccountsMap[contract.companyBankAccountId]
			: undefined,
		contractorBankAccount: contract.contractorBankAccountId
			? bankAccountsMap[contract.contractorBankAccountId]
			: undefined,
		companyAuthorizedPerson: contract.companyAuthorizedPersonId
			? authorizedPersonsMap[contract.companyAuthorizedPersonId]
			: undefined,
		contractorAuthorizedPerson: contract.contractorAuthorizedPersonId
			? authorizedPersonsMap[contract.contractorAuthorizedPersonId]
			: undefined
	}));
