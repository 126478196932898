import {IGetChecksResponse} from '@src/api/backend/checks';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {IProblemTarget} from '@src/api/backend/problem';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import {addInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/actions';
import {moveProblem} from '@src/store/modules/pages/problem/actions/move';
import {useQueryClient} from '@tanstack/react-query';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {
	ActionButtons,
	Button,
	Checkbox,
	Dialog,
	LoadableOptionsList,
	Select2,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import {useAsyncFn} from 'react-use';
import './MovingProblemsDialog.less';
import {useTranslation} from 'react-i18next';
import {useAddCheckOnline} from '@src/core/hooks/mutations/checks/useAddCheckOnline';

interface IMovingProblemsDialogProps {
	selectedEntities: ICheckEntity[];
	objectId: string;
	target: IProblemTarget;
	isOpen: boolean;
	restrictions?: {[key: string]: boolean};
	onClose: () => void;
	onSuccess?: () => void;
}

const arrowIcon = <i className="tz-simple-arrow-20" />;

/**
 * Окно перемещения нарушения
 */
const MovingProblemsDialog = ({
	selectedEntities,
	objectId,
	target,
	isOpen,
	restrictions,
	onClose,
	onSuccess
}: IMovingProblemsDialogProps) => {
	const {t} = useTranslation();

	const isCheck = !!target.checkId;
	const isInternalAcceptance = !!target.internalAcceptanceId;
	const dispatch = useAppDispatch();
	const check = useAppSelector(s => s.entities.check.data);
	const internalAcceptance = useAppSelector(s => s.entities.internalAcceptance.data);
	const user = useAppSelector(s => s.auth.profile);
	const [disabled, setDisabled] = useState(false);
	const [toEntityId, setToEntityId] = useState<string>('');
	const [toNewEntity, setToNewEntity] = useState<boolean>(false);
	const [entities, setEntities] = useState<Array<{id: string; content: string}>>([]);
	const [offset, setOffset] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);
	const handleEntityChange = useCallback((value: string) => {
		setToEntityId(value);
	}, []);

	const queryClient = useQueryClient();
	const {mutateAsync: addCheckOnline} = useAddCheckOnline();

	const formList = useCallback(
		(value: IListCheck[] | IListInternalAcceptance[]) => {
			const arr: Array<{id: string; content: string}> = [];
			for (const item of value) {
				const text = isCheck
					? `${t('movingProblemsDialog.title.inspection')} №`
					: isInternalAcceptance
					? `${t('movingProblemsDialog.title.internalAcceptance')} №`
					: `${t('movingProblemsDialog.title.unknown')} №`;
				arr.push({id: item.id, content: `${text}${item.number}`});
			}
			setEntities([...entities, ...arr]);
		},
		[entities, isCheck, isInternalAcceptance, t]
	);

	const [, getEntities] = useAsyncFn(async () => {
		let items = {} as IGetInternalAcceptancesResponse | IGetChecksResponse;
		if (isCheck) {
			const filters = {
				objects: [objectId],
				createdBy: restrictions && restrictions.checksCreatedByUser ? [user.id] : undefined
			};
			items = await queryClient.fetchQuery({
				queryKey: [...checksQueryKeys.list(), filters, undefined, offset, 100]
			});
		} else if (isInternalAcceptance) {
			const filters = {
				objects: [objectId],
				createdBy:
					restrictions && restrictions.internalAcceptancesCreatedByUser
						? [user.id]
						: undefined
			};
			items = await queryClient.fetchQuery({
				queryKey: internalAcceptancesQueryKeys.list(filters, undefined, offset, 100)
			});
		}
		formList([...Object.values(items.byId)]);
		setOffset(offset + 100);
		setTotal(items.total);
	}, [
		objectId,
		offset,
		check,
		entities,
		internalAcceptance,
		isCheck,
		isInternalAcceptance,
		restrictions
	]);

	useEffect(() => {
		const entityId = isCheck
			? check?.id
			: isInternalAcceptance
			? internalAcceptance?.id
			: undefined;
		setToEntityId(`${entityId}`);
	}, [check, internalAcceptance, isCheck, isInternalAcceptance]);

	useEffect(() => {
		if (entities.length === 0) {
			void getEntities();
		}
	}, [entities.length, getEntities]);

	const handleNewEntity = (event: React.ChangeEvent<HTMLInputElement>) => {
		setToNewEntity(event.target.checked);
	};

	const [, handleSave] = useAsyncFn(async () => {
		setDisabled(true);
		const problemsIds = selectedEntities
			.filter(item => item.type === 'problem')
			.map(({data}) => {
				const {id} = data as IProblem;
				return id;
			});

		if (isCheck) {
			if (toNewEntity && problemsIds.length) {
				const newCheck = await addCheckOnline({
					objectId,
					links: undefined,
					stage: check?.stage
				});
				for (const problemId of problemsIds) {
					await dispatch(moveProblem(objectId, {checkId: newCheck?.id}, problemId));
				}
			}
			if (toEntityId && !toNewEntity && problemsIds.length) {
				for (const problemId of problemsIds) {
					await dispatch(moveProblem(objectId, {checkId: toEntityId}, problemId));
				}
			}
		}
		if (isInternalAcceptance) {
			if (toNewEntity && problemsIds.length) {
				const newInternalAcceptance = await dispatch(
					addInternalAcceptance(objectId, undefined, internalAcceptance?.stage)
				);
				for (const problemId of problemsIds) {
					await dispatch(
						moveProblem(
							objectId,
							{internalAcceptanceId: newInternalAcceptance?.id},
							problemId
						)
					);
				}
			}
			if (toEntityId && !toNewEntity && problemsIds.length) {
				for (const problemId of problemsIds) {
					await dispatch(
						moveProblem(objectId, {internalAcceptanceId: toEntityId}, problemId)
					);
				}
			}
		}
		if (onSuccess) {
			onSuccess();
		}
		onClose();
	}, [objectId, toEntityId, toNewEntity, isCheck, isInternalAcceptance]);

	return (
		<Dialog
			className="check-page__problems-moving"
			isOpen={isOpen}
			footer={
				<ActionButtons>
					<Button
						disabled={
							disabled ||
							((target.checkId === toEntityId ||
								target.internalAcceptanceId === toEntityId) &&
								!toNewEntity)
						}
						type="accent-blue"
						label={t('movingProblemsDialog.save')}
						onClick={handleSave}
					/>
					<Button
						disabled={disabled}
						type="cancel"
						label={t('movingProblemsDialog.cancel')}
						onClick={onClose}
					/>
				</ActionButtons>
			}
		>
			<div className="check-page__problems-moving__select">
				<div>
					{isCheck
						? t('movingProblemsDialog.choose.inspection')
						: isInternalAcceptance
						? t('movingProblemsDialog.choose.internalAcceptance')
						: t('movingProblemsDialog.choose.unknown')}
				</div>
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							disabled={toNewEntity}
							elementType="div"
							value={entities.find(e => e.id === toEntityId)?.content}
							icon={arrowIcon}
						/>
					}
				>
					<Select2
						value={toEntityId}
						onChange={handleEntityChange}
					>
						<LoadableOptionsList
							items={entities}
							total={total}
							handleLoad={getEntities}
							maxListHeight={400}
							rowHeight={48}
						/>
					</Select2>
				</SelectPopup>
				<Checkbox
					className="check-page__problems-moving-cb"
					checked={toNewEntity}
					onChange={handleNewEntity}
				>
					{isCheck
						? t('movingProblemsDialog.moveTo.inspection')
						: isInternalAcceptance
						? t('movingProblemsDialog.moveTo.internalAcceptance')
						: t('movingProblemsDialog.moveTo.unknown')}
				</Checkbox>
			</div>
		</Dialog>
	);
};

export default MovingProblemsDialog;
