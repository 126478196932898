import {queryClient} from '@src/api/QueryClient';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteCategoriesSetResponse {
	success: boolean;
}
/**
 * Удаляет набор видов работ по id
 *
 * @param categoriesSetId id набора видов работ
 */
export const requestDeleteCategoriesSet = async (categoriesSetId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteCategoriesSetResponse>(
		`categories-sets/delete/${categoriesSetId}`
	);
	await queryClient.invalidateQueries({queryKey: categoriesSetsQueryKeys.list()});

	return response.data;
};
