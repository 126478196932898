import {Cell, Pie, PieChart, ResponsiveContainer} from 'recharts';
import {defaultItem} from './constants/defaultItem';
import {IDiagramSector} from '../interfaces';
import {memo} from 'react';
import classNames from 'classnames';
import styles from './Diagram.module.less';

interface IDiagramProps {
	innerSectors: IDiagramSector[];
	outerSectors?: IDiagramSector[];
	className?: {
		innerCell?: string;
		outerCell?: string;
	};
	disabled?: boolean;
	size?: number;
}

export const Diagram = memo(
	({innerSectors, outerSectors, className, disabled, size = 56}: IDiagramProps) => {
		const hideInnerStroke = innerSectors.length <= 1;
		const outerRadius = size / 2;
		return (
			<div className={classNames(styles.wrapper, disabled && styles.wrapperDisabled)}>
				<ResponsiveContainer>
					<PieChart style={{cursor: disabled ? 'initial' : 'pointer'}}>
						<circle
							cx="50%"
							cy="50%"
							r={hideInnerStroke ? outerRadius : outerRadius - 1}
							fill="white"
							className={styles.shadow}
						/>
						<Pie
							data={innerSectors.length ? innerSectors : [defaultItem]}
							dataKey="quantity"
							outerRadius={outerRadius}
							legendType="none"
							blendStroke={hideInnerStroke}
							startAngle={-270}
							isAnimationActive={false}
						>
							{innerSectors.length ? (
								innerSectors.map(innerSector => (
									<Cell
										key={innerSector.id}
										fill={innerSector.color}
										className={className?.innerCell}
									/>
								))
							) : (
								<Cell fill={defaultItem.color} />
							)}
						</Pie>
						{outerSectors && outerSectors.length && (
							<Pie
								data={outerSectors}
								dataKey="quantity"
								outerRadius={outerRadius}
								innerRadius={outerRadius - 3}
								legendType="none"
								blendStroke
								startAngle={-270}
								isAnimationActive={false}
							>
								{outerSectors.map(outerSector => (
									<Cell
										key={outerSector.id}
										fill={outerSector.color}
										className={className?.outerCell}
									/>
								))}
							</Pie>
						)}
					</PieChart>
				</ResponsiveContainer>
			</div>
		);
	}
);
