import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {editSpace} from '@src/store/modules/entities/space/actions';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useCallback, useState} from 'react';
import {useEditableSpaceAttachments} from '../EditableSpaceAttachments/hooks/useEditableSpaceAttachments';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

interface IEditableSpaceAttachmentsDialogProps {
	objectId: string;
	spaceId: string;
	space: ISpace;
	isOpen: boolean;
	onClose: () => void;
}

const EditableSpaceAttachmentsDialog = ({
	objectId,
	spaceId,
	space,
	isOpen,
	onClose
}: IEditableSpaceAttachmentsDialogProps) => {
	const {t} = useTranslation();
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableSpaceAttachments(
		objectId,
		space,
		saving
	);

	const save = useCallback(async () => {
		setSaving(true);
		if (spaceId) {
			const savingData = await getSavingData();
			if (savingData) {
				if (!networkStatus) {
					//
				} else {
					try {
						await dispatch(editSpace(objectId, objectId, spaceId, savingData));
						onClose();
					} catch (error) {
						console.log(error);
					}
				}
			}
		}
		setSaving(false);
	}, [networkStatus, objectId, spaceId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-attachments-dialog"
			isOpen={isOpen}
			title={t('components.editableSpaceAttachmentsDialog.dialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('components.editableSpaceAttachmentsDialog.button.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('components.editableSpaceAttachmentsDialog.button.save')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};

export default EditableSpaceAttachmentsDialog;
