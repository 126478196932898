import { memo } from 'react';
import {DownloadableFile, Plate} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {NewDocuments} from './NewDocuments';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {useTranslation} from 'react-i18next';

interface IDesktopDocumentsProps {
	objectId: string;
	space: ISpace;
}

export const DesktopDocuments = memo(({objectId, space}: IDesktopDocumentsProps) => {
	const {canEdit} = useSpacesPermissions(objectId);
	const {t} = useTranslation();

	return (
		<Plate className={{content: 'space-page__info-plate'}}>
			<div className="space-page__info-title">{t('spacePage.documents.title')}</div>
			{space.attachments?.map(item =>
				item.full ? (
					<div
						key={item.id}
						className="space-page__documents-file"
					>
						<DownloadableFile
							name={item.full.name}
							url={item.full.url}
							type={item.full.type}
							size={item.full.size}
						/>
					</div>
				) : null
			)}

			{canEdit && (
				<NewDocuments
					objectId={objectId}
					spaceId={space.id}
				/>
			)}
		</Plate>
	);
});
