import {RefObject, useEffect, useRef} from 'react';
import {DndAttribute} from '../interfaces';

export const useDndItem = <S extends HTMLElement>(
	id: string, container?: string, isDragging?: boolean
): RefObject<S> => {
	const ref = useRef<S>(null);

	// Добавляет аттрибуты item'a в ref
	useEffect(() => {
		if (ref.current === null) { return; }

		if (container) {
			ref.current.setAttribute(DndAttribute.CONTAINER, container);
		}
		if (id) {
			ref.current.setAttribute(DndAttribute.ID, id);
		}
		// isDragging нужен в зависимость, чтоб каждый раз при его изменении
		// добавлялись аттрибуты. Ведь после перемещения список дерево меняется
	}, [container, id, isDragging]);

	return ref;
};