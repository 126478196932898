import {useMemo} from 'react';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';
import {checkIsCurrent} from '@src/pages/ObjectPage/utils/checkIsCurrent';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {Tag} from '@tehzor/ui-components';
import {useTranslation} from "react-i18next";

interface IObjectStagesProps {
	object: IObject;
}

const colorsMap = new Map([
	[ObjectStageIds.ACCEPTANCE, '#FFEEDB'],
	[ObjectStageIds.BUILDING, '#D6F5FF'],
	[ObjectStageIds.TRANSFER, '#E2FDF3'],
	[ObjectStageIds.WARRANTY, '#DFE1FC']
]);

export const ObjectStages = ({object}: IObjectStagesProps) => {
	const {t} = useTranslation();

	const {data: stagesMap} = useObjectStagesMap();

	const stages = useMemo(() => {
		if (!stagesMap) return null;
		if (!object.stages) {
			return [stagesMap[object.stage]];
		}
		const array = [];

		const keys = Object.keys(object.stages);
		keys.sort((a, b) => {
			const stagesMaps = new Map([
				[ObjectStageIds.BUILDING, 1],
				[ObjectStageIds.ACCEPTANCE, 2],
				[ObjectStageIds.TRANSFER, 3],
				[ObjectStageIds.WARRANTY, 4]
			]);
			const a1 = stagesMaps.get(a as ObjectStageIds) ?? 0;
			const b1 = stagesMaps.get(b as ObjectStageIds) ?? 0;
			return a1 - b1;
		});

		for (const key of keys) {
			if (
				checkIsCurrent(
					object.stages[key as ObjectStageIds]?.from,
					object.stages[key as ObjectStageIds]?.to
				)
			) {
				array.push({...stagesMap[key], color: colorsMap.get(key as ObjectStageIds)});
			}
		}

		return array.length
			? array
			: [{...stagesMap[object.stage], color: colorsMap.get(object.stage)}];
	}, [object, stagesMap]);

	return (
		<div className="object-item__footer-stages">
			{stages &&
				stages.map(stage => (
					<Tag
						className={{
							root: 'object-item__footer-stages__item',
							label: 'object-item__footer-stages__label'
						}}
						key={stage.id}
						label={t(`objectStages.${stage.id}`)}
						color={stage.color}
					/>
				))}
		</div>
	);
};