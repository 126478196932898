import {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useExtractCheckRecordStatusesByEntityTypeAsArray} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';

interface ICheckListsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const CheckListStatusesFilterMobile = memo((props: ICheckListsFilterMobileProps) => {
	const {t} = useTranslation();
	const {data: checkListsRecordStatuses} =
		useExtractCheckRecordStatusesByEntityTypeAsArray('check-list');

	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.checkListStatuses,
		checkListsRecordStatuses
	);

	if (!translatedOptions) {
		return null;
	}
	return (
		<BaseListFilterMobile
			{...props}
			options={translatedOptions}
			label={t('spacesFiltersPage.checkListStatusesFilterMobile.label')}
			filterName="checkListStatuses"
		/>
	);
});
