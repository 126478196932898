import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import {CellProps} from 'react-table';

interface IContractorCellProps extends CellProps<IPreparedContract> {
	isMobile?: boolean;
}

export const ContractorCell = ({row, isMobile}: IContractorCellProps) => (
	<div className="contracts__cell-contractor">
		{isMobile && <div className="contracts__cell-contractor-header">Подрядчик:</div>}
		<div className="contracts__cell-contractor-name">
			<span className="contracts__cell-contractor-name-text">
				{row.original.contractor?.name} ,
			</span>
			<span className="contracts__cell-contractor-name-add-text">
				{row.original.contractorLegalEntity?.name}
			</span>
		</div>
	</div>
);
