import {ModalDialog} from '@tehzor/ui-components';
import {useEnrichedHistories} from './hooks/useEnrichedHistories';
import {TranslatedHistoryList} from '@src/components/TranslatedHistoryList';
import {useTranslation} from 'react-i18next';
import './InspectionHistoryDialog.less';

interface IInspectionHistoryDialogProps {
	objectId: string;
	inspectionId: string;
	isOpen: boolean;
	onClose: () => void;
}

export const InspectionHistoryDialog = (props: IInspectionHistoryDialogProps) => {
	const {t} = useTranslation();
	const {objectId, inspectionId, isOpen, onClose} = props;
	const stories = useEnrichedHistories(objectId, inspectionId, isOpen);

	return (
		<ModalDialog
			className={{
				root: 'inspection-history-dialog',
				content: 'inspection-history-dialog__content'
			}}
			open={isOpen}
			title={t('inspectionHistoryDialog.title')}
			fullScreenOnMobile
			onRequestClose={onClose}
		>
			<TranslatedHistoryList
				entityName={t('inspectionHistoryDialog.translatedHistoryList.entityName')}
				data={stories}
			/>
		</ModalDialog>
	);
};
