import {memo} from 'react';
import * as React from 'react';
import './ExpandableMobileInfo.less';
import {ExpandablePanel, InlineButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

interface IExpandableMobileInfoProps {
	label?: string;
	children?: React.ReactNode;
	noBorder?: boolean;
}

const expandIcon = <i className="tz-simple-arrow-28" />;
const infoIcon = <i className="tz-info-20" />;
const arrowIcon = <i className="tz-simple-arrow-20" />;

export const ExpandableMobileInfo = memo((props: IExpandableMobileInfoProps) => {
	const {t} = useTranslation();
	const {children, noBorder} = props;

	const [expanded, toggleExpanded] = useToggle(false);

	return (
		<>
			<ExpandablePanel
				className="expandable-mobile-info-panel"
				expanded={expanded}
			>
				<div className="expandable-mobile-info-panel__content">{children}</div>
			</ExpandablePanel>

			<div
				className={classNames('expandable-mobile-info', {
					'expandable-mobile-info_expanded': expanded,
					'expandable-mobile-info_no-border': expanded && noBorder
				})}
			>
				{expanded ? (
					<InlineButton
						className="expandable-mobile-info__hide-btn"
						rightIconClassName="expandable-mobile-info__hide-btn-icon"
						type="accent"
						rightIcon={expandIcon}
						onClick={toggleExpanded}
					/>
				) : (
					<InlineButton
						className="expandable-mobile-info__expand-btn"
						rightIconClassName="expandable-mobile-info__expand-btn-icon"
						type="accent"
						label={t('expandableMobileInfo.label')}
						leftIcon={infoIcon}
						rightIcon={arrowIcon}
						onClick={toggleExpanded}
					/>
				)}
			</div>
		</>
	);
});
