import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {IAddCheckResponse} from '@src/api/backend/check';
import {addErrorToast} from '@src/utils/toasts';

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddCheckResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении проверки');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

export const addCheckActions = {request, success, failure};
