import {Column} from 'react-table';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';
import {useMemo} from 'react';
import {useOwnerAcceptanceProblemCommentsByProblem} from '@src/core/hooks/queries/problems/hooks';

export const useMobileColumns = (
	acceptanceId: string,
	objectId: string
): Array<Column<IOwnerAcceptanceEntity>> => {
	const {data: replies} = useOwnerAcceptanceProblemCommentsByProblem(
		objectId || 'all',
		acceptanceId
	);

	return useMemo(
		() => [
			{
				id: 'info',
				Cell: CellWrap({
					problem: ProblemInfoCellWrap(ProblemInfoMobileCell, undefined, replies)
				})
			}
		],
		[replies]
	);
};
