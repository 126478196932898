import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import ICreationStatsItem from '@tehzor/tools/interfaces/statistics/ICreationStatsItem';
import {byCompanies, byContractors, byObjects, byUsers} from '@src/utils/statsAggregators/creation';

export const extractChecksStatsData = (state: IState): ICreationStatsItem[] =>
	state.statistics.warranty.checksCreation.data || [];

export const extractProblemsStatsData = (state: IState): ICreationStatsItem[] =>
	state.statistics.warranty.problemsCreation.data || [];

export const extractRepliesStatsData = (state: IState): ICreationStatsItem[] =>
	state.statistics.warranty.repliesCreation.data || [];

export const extractInspectionsStatsData = (state: IState): ICreationStatsItem[] =>
	state.statistics.warranty.inspectionsCreation.data || [];

export const extractWarrantyClaimsStatsData = (state: IState): ICreationStatsItem[] =>
	state.statistics.warranty.warrantyClaimsCreation.data || [];

const extractStatsData = (state: IState) => ({
	checks: extractChecksStatsData(state),
	problems: extractProblemsStatsData(state),
	replies: extractRepliesStatsData(state),
	inspections: extractInspectionsStatsData(state),
	warrantyClaims: extractWarrantyClaimsStatsData(state)
});

/**
 * Агрегирует по компаниям общую статистику
 */
export const aggregateByCompanies = createSelector(extractStatsData, byCompanies);

/**
 * Агрегирует по объектам общую статистику
 */
export const aggregateByObjects = createSelector(extractStatsData, byObjects);

/**
 * Агрегирует по инженерам общую статистику
 */
export const aggregateByUsers = createSelector(extractStatsData, byUsers);

/**
 * Агрегирует по подрядчикам общую статистику
 */
export const aggregateByContractors = createSelector(extractStatsData, byContractors);
