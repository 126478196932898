import {CellProps} from 'react-table';
import { useMemo } from 'react';
import {Tag} from '@tehzor/ui-components';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';
import {useExtractSpaceTypesAsArray} from '@src/core/hooks/queries/spaceTypes/hooks';

export const CheckListSpaceTypesCell = ({row}: CellProps<IPreparedCheckList>) => {
	const rowSpaceTypes = row.original.spaceTypes || [];
	const {data: spaceTypes} = useExtractSpaceTypesAsArray();

	const selectedTypes = useMemo(
		() => spaceTypes?.filter(spaceType => rowSpaceTypes.includes(spaceType.id)),
		[spaceTypes, row.original.spaceTypes]
	);
	if (!selectedTypes) return null;
	return (
		<div>
			{selectedTypes.length < 4 ? (
				<div>
					{selectedTypes.map(selectedType => (
						<Tag
							className="listTag"
							key={selectedType.id}
							label={selectedType.name}
						/>
					))}
				</div>
			) : (
				<div>
					{selectedTypes.slice(0, 3).map(selectedType => (
						<Tag
							className="listTag"
							key={selectedType.id}
							label={selectedType.name}
						/>
					))}
					<Tag
						color="#EEF5FB"
						label={`+${selectedTypes.length - 3}`}
					/>
				</div>
			)}
		</div>
	);
};
