import classNames from 'classnames';
import * as React from 'react';
import './VerticalTabContent.less';

export interface IVerticalTabContentProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
}

const VerticalTabContent = ({className, style, children}: IVerticalTabContentProps) => (
	<div
		className={classNames('vertical-tab-content', className)}
		style={style}
	>
		{children}
	</div>
);

export default VerticalTabContent;
