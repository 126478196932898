import {useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import SelectionActions from '../selection/SelectionActions';
import {useTranslation} from 'react-i18next';
import {useCheck} from '@src/core/hooks/queries/check/hook';

interface ICustomSelectionRowProps extends ISelectionRowProps<ICheckEntity> {
	objectId: string;
	checkId: string;
}

const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {t} = useTranslation();

	const {objectId, checkId, selectedFlatRows, toggleAllRowsSelected} = props;
	const {data: check} = useCheck(checkId, objectId);

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				objectId={objectId}
				checkId={checkId}
				check={check}
				selectedEntities={selectedFlatRows.map(item => item.original)}
				clearSelection={clearSelection}
			/>
			<Button
				label={t('checksPage.table.selectionRow.cancel')}
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};

export default SelectionRow;
