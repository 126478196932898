import {useCallback, useMemo} from 'react';
import {update} from '@src/store/modules/app/updateData/actions/update';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {MenuItem} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAppUpdateMenuItem = (): React.ReactElement => {
	const {i18n, t} = useTranslation();

	const dispatch = useAppDispatch();
	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const updateAppData = useCallback(async () => {
		await dispatch(update());
	}, []);

	return useMemo(
		() => (
			<MenuItem
				dataTestId="MenuItem"
				key="update"
				disabled={!online}
				icon={<i className="tz-reload-24" />}
				onClick={updateAppData}
			>
				{t('header.update')}
			</MenuItem>
		),
		[online, i18n.language]
	);
};
