import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetResponsibilityRulesResponse = INormalizedData<IResponsibilityRule>;

/**
 * Возвращает список правил ответственности
 */
export const requestResponsibilityRules = async () => {
	const response = await httpRequests.withToken.get<IGetResponsibilityRulesResponse>(
		'responsibility-rules'
	);
	return response.data;
};
