import {createContext, PropsWithChildren, useCallback} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {useDeleteProblemTagsSet} from '@src/core/hooks/mutations/problemTagsSets/useDeleteProblemTagsSet';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IEnrichedProblemTagsSet>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({
	children,
	reloadList
}: PropsWithChildren<{reloadList: () => Promise<any>}>) => {
	const {mutate: deleteProblemTagsSet} = useDeleteProblemTagsSet();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить набор меток?',
		'Все связанные данные будут безвозвратно удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IEnrichedProblemTagsSet>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				deleteProblemTagsSet(action.payload.id);
				await reloadList();
			}
		},
		[deleteProblemTagsSet, getDeleteConfirmation, reloadList]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
