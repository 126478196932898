import SelectedEntitiesExport from './SelectedEntitiesExport';
import SelectedEntitiesDeletion from './SelectedEntitiesDeletion';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';

interface ISelectionsActionsProps {
	objectId: string;
	spaceId: string;
	selectedEntities: ISpaceEntity[];
}

const SelectionActions = ({objectId, spaceId, selectedEntities}: ISelectionsActionsProps) => {
	const permissions = useSpacesPermissions(objectId);

	return (
		<>
			{permissions.canExport && (
				<SelectedEntitiesExport
					objectId={objectId}
					spaceId={spaceId}
					selectedEntities={selectedEntities}
				/>
			)}
			{permissions.canDelete && (
				<SelectedEntitiesDeletion selectedEntities={selectedEntities}/>
			)}
		</>
	);
};

export default SelectionActions;
