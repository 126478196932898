import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IWorkingGroupType} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetWorkingGroupTypesResponse = INormalizedData<IWorkingGroupType>;

/**
 * Возвращает список типов рабочих групп
 */
export const requestWorkingGroupTypes = async () => {
	const response = await httpRequests.withToken.get<IGetWorkingGroupTypesResponse>(
		'working-groups/types'
	);
	return response.data;
};
