import {wsConnector} from '../wsConnector';
import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export type IAddStructureResponse = IStructure;

/**
 * Добавляет структуру
 *
 * @param objectId id объекта
 * @param fields данные структуры
 */
export const makeStructureAddRequest = (objectId: string, fields: ISavingStructure) =>
	wsConnector.sendAuthorizedRequest<IAddStructureResponse>('addStructure', {
		objectId,
		...fields
	});
