import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddSpaceStatusesSetResponse = ISpaceStatusesSet;

/**
 * Добавляет набор статусов помещений
 *
 * @param spaceStatusesSet наборы статусов помещения
 */
export const requestAddSpaceStatusesSet = async (spaceStatusesSet: ISavingSpaceStatusesSet) => {
	const response = await httpRequests.withToken.post<IAddSpaceStatusesSetResponse>(
		'space-statuses-sets',
		{...spaceStatusesSet}
	);
	return response.data;
};
