import {EntityGridItem, Tag} from '@tehzor/ui-components';
import {useExtractProblemTagsById} from '@src/core/hooks/queries/problemTags/hooks';
import {useTranslation} from 'react-i18next';

interface IProblemTagsProps {
	tags: string[];
	fullRow?: boolean;
}

const ProblemTags = ({tags, fullRow}: IProblemTagsProps) => {
	const {data: allTagsById} = useExtractProblemTagsById();
	const {t} = useTranslation();
	const problemTags = tags.map(id => {
		const data = allTagsById && allTagsById[id];
		if (data) {
			return (
				<Tag
					className="problem-page__tag"
					key={data.id}
					label={data.name}
					dotColor={data.color}
					size="small"
				/>
			);
		}
		return null;
	});

	return (
		<EntityGridItem
			icon={<i className="tz-tag-20" />}
			label={t('problemPage.info.tags.label')}
			fullRow={fullRow}
		>
			<div className="problem-page__tags">{problemTags}</div>
		</EntityGridItem>
	);
};

export default ProblemTags;
