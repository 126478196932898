import {InvalidRestrictionError} from '../../../../errors/InvalidRestrictionError';
import {IWorkingGroup} from '../../../../interfaces/workingGroups/IWorkingGroup';
import {IBriefUser} from '../../../../interfaces/users/IBriefUser';
import IInspection from '../../../../interfaces/inspections/IInspection';

type InspectionModified = Omit<IInspection, 'createdBy'> & {createdBy: IBriefUser};

export function inspectionsCreatedByUser(
	restriction: unknown,
	{userId, inspection}: {userId?: string, inspection?: InspectionModified}
) {
	if (typeof restriction !== 'boolean' || !userId || !inspection) {
		throw new InvalidRestrictionError();
	}
	if (restriction && inspection.createdBy) {
		return userId === inspection.createdBy.id;
	}
	return false;
}

export function inspectionsHavePerformerUser(
	restriction: unknown,
	{userId, inspection}: {userId?: string, inspection?: IInspection}
) {
	if (typeof restriction !== 'boolean' || !userId || !inspection) {
		throw new InvalidRestrictionError();
	}
	if (restriction && inspection.performers) {
		return inspection.performers.includes(userId);
	}
	return false;
}

export function inspectionsHaveWatcherUser(
	restriction: unknown,
	{userId, inspection}: {userId?: string, inspection?: IInspection}
) {
	if (typeof restriction !== 'boolean' || !userId || !inspection) {
		throw new InvalidRestrictionError();
	}
	if (restriction && inspection.watchers) {
		return inspection.watchers.includes(userId);
	}
	return false;
}

export function inspectionsHaveLeaderInPerformersActiveGroup(
	restriction: unknown,
	{userId, inspection}: {userId?: string, inspection?: IInspection}
) {
	if (typeof restriction !== 'boolean' || !userId || !inspection) {
		throw new InvalidRestrictionError();
	}
	if (restriction) {
		return inspection.performersActiveGroupLeader === userId;
	}
	return false;
}

export function onlyPerformersActiveGroup(
	restriction: unknown,
	{
		inspection,
		workingGroup,
		performers,
		activeGroup
	}: {inspection?: IInspection, workingGroup?: IWorkingGroup, performers?: string[], activeGroup?: IWorkingGroup}
) {
	if (typeof restriction !== 'boolean' || !inspection) {
		throw new InvalidRestrictionError();
	}
	if (!restriction) {
		return false;
	}
	// Если уже имеется предыдущая активная рабочая группа
	if (workingGroup) {
		if (performers?.length) {
			return performers.every(id => workingGroup.performers?.includes(id));
		}
		if (activeGroup) {
			return activeGroup.parentId === workingGroup.id;
		}
		// Если была уже установлена группа и пришли данные о пустых новых ответственных,
		// то разрешаем такое действие по сбросу ответственных
		return true;
	}
	// Если не были ранее выбраны ответственные, то должна быть возможность выбрать только из групп самого верхнего,
	// доступного уровня, то есть у кого нет родительских групп.
	// Для такой проверки необходимо всё дерево групп для конкретной ситуации, так как могут быть группы с parentId
	// и в то же время являться верхнеуровневой при определенных параметрах.
	// Поэтому пропускаем за неимением такой проверки.
	return true;
}

onlyPerformersActiveGroup.logicalOperator = 'and';
