import {useMemo} from 'react';
import './ReadMore.less';
import {useToggle} from 'react-use';
import LinkButton from '../../buttons/LinkButton';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';

export interface IReadMoreProps {
	text: string;
	maxLength: number;
	className?:
		| string
		| {
				root?: string;
				button?: string;
		  };
	hideLabel?: string;
	showLabel?: string;
}

export const ReadMore = ({text, maxLength, className, hideLabel = 'Свернуть', showLabel = 'Показать полностью'}: IReadMoreProps) => {
	const [visibleText, setVisibleText] = useToggle(false);
	const isLong = useMemo(() => text.length > maxLength, [text, maxLength]);
	const classes = convertClassNames(className);
	return (
		<div className={classNames('read-more', classes.root)}>
			{visibleText || !isLong ? `${text} ` : `${text.slice(0, maxLength)}... `}
			{isLong && (
				<LinkButton
					className={classNames('read-more-button', classes.button)}
					label={visibleText ? hideLabel : showLabel}
					onClick={setVisibleText}
				/>
			)}
		</div>
	);
};
ReadMore.displayName = 'ReadMore';
