import { ReactElement } from 'react';
import {useSearchSelectHandlers} from '../../hooks';
import {ITextSearchProvider} from '../../interfaces';
import {IAnyEntity} from '@tehzor/tools/interfaces/IAnyEntity';
import classNames from 'classnames';
import {Select2} from '../../../inputs';

interface ISingleSearchSelectProps<T extends IAnyEntity> {
	className?: string;
	provider: ITextSearchProvider<T>;
	onSelect: (value: T | undefined) => void;
	renderItem: (item: T) => ReactElement;
}

export const SingleSearchSelect = <T extends IAnyEntity>(props: ISingleSearchSelectProps<T>) => {
	const {className, provider, onSelect, renderItem} = props;
	const [selected, handleSelection] = useSearchSelectHandlers(provider, onSelect);

	return (
		<Select2
			className={classNames('search-select__select', className)}
			value={selected}
			onChange={handleSelection}
		>
			{provider.filteredList.map(renderItem)}
		</Select2>
	);
};