import { useCallback, useContext } from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useExportTemplatePermissions} from '@src/core/hooks/permissions/useExportTemplatePermissions';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from '../DispatchActionCtx';
import {IPreparedExportTemplate} from '../../interfaces/IPreparedExportTemplate';

export const MenuCell = ({row}: CellProps<IPreparedExportTemplate>) => {
	const menuActions = [];
    const perms = useExportTemplatePermissions();
    const dispatchAction = useContext(DispatchActionCtx);

    const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [row.original]);

    if (perms.canEdit) {
		menuActions.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-delete"/>}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}

    return menuActions.length ? <IconMenu>{menuActions}</IconMenu> : null;
};
