import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {requestProblemsDocumentsStats} from '@src/api/backend/problemsDocumentsStats';
import {IGetProblemsDocumentsStatsQueryKeyList, problemsDocumentsStatsQueryKeys} from '../keys';

export const useProblemsDocumentsStatsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(problemsDocumentsStatsQueryKeys.lists(), {
		queryFn: async ({
			queryKey
		}: QueryFunctionContext<IGetProblemsDocumentsStatsQueryKeyList>) => {
			const [, , problemIds] = queryKey;
			return requestProblemsDocumentsStats(problemIds);
		},
		meta: {
			error: 'при загрузке статистики по документам y нарушений'
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
