import {MouseEvent, TouchEvent} from 'react';
import {transferEntity} from '../../utils/transferEntity';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {CircleButton} from '@tehzor/ui-components';
import {useNeedTransferButton} from '../../hooks/useNeedTransferButton';

interface ITransferButtonProps {
	entity: IOfflineJournalEntity['entity'];
}

export const TransferButton = ({entity}: ITransferButtonProps) => {
	const needButton = useNeedTransferButton(entity);
	if (!needButton) return null;
	return (
		<CircleButton
			className="offline-journal-status__circle-button"
			onClick={(event: MouseEvent | TouchEvent) => {
				event.stopPropagation();
				transferEntity(entity);
			}}
		>
			<i className="tz-reload-24" />
		</CircleButton>
	);
};
