import {queryClient} from '@src/api/QueryClient';
import {IGetMetersResponse, makeMeterEditRequest} from '@src/api/backend/meters';
import {metersQueryKeys} from '@src/api/cache/meters/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {ISavingMeter} from '@tehzor/tools/interfaces/meters/ISavingMeter';
import {useCallback} from 'react';

export const useEditMeter = () => {
	const mutation = useMutation<IMeter, IError, ISavingMeter>({
		mutationFn: (params: ISavingMeter) => makeMeterEditRequest(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetMetersResponse>(
				metersQueryKeys.list(data.spaceId),
				oldData => {
					if (oldData && oldData.allIds.includes(data.id)) {
							return {
								allIds: oldData.allIds,
								byId: {...oldData.byId, [data.id]: data}
							};
					}
					return oldData;
				}
			);
			addSuccessToast('Успех', 'Прибор учёта изменен');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при редактировании прибора учёта');
		}
	});
	/**
	 * Возвращает функцию для редактирования прибора учёта
	 *
	 * @param item данные прибора учёта
	 */
	return useCallback(
		(item: ISavingMeter) => {
			mutation.mutate(item);
		},
		[mutation]
	);
};
