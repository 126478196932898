import {Column} from 'react-table';
import {MobileWorkAcceptanceCell} from '@src/components/tableCells/MobileWorkAcceptanceCell';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

export const workAcceptanceMobileColumns: Array<Column<IListWorkAcceptance>> = [
	{
		id: 'category',
		Cell: MobileWorkAcceptanceCell,
		width: 80,
		minWidth: 80
	}
];
