import {memo, useCallback, useMemo} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {FieldsRowActionButton} from '../FieldsRowActionButton/FieldsRowActionButton';

export const SelectionRow = memo(
	({selectedFlatRows, toggleAllRowsSelected}: ISelectionRowProps<IPreparedFieldSettingsSet>) => {
		const handleClear = useCallback(() => {
			toggleAllRowsSelected(false);
		}, [toggleAllRowsSelected]);

		const selectedEntities = useMemo(
			() => selectedFlatRows.map(item => item.original),
			[selectedFlatRows]
		);

		return (
			<>
				<FieldsRowActionButton
					onClear={handleClear}
					selectedEntities={selectedEntities}
				/>
				<Button
					label="Сбросить"
					type="cancel"
					onClick={handleClear}
				/>
			</>
		);
	}
);
