import {CheckItem, generateId} from '@src/components/EditableCheckList/components/CheckItem';
import {
	DndList,
	DndContext,
	IDndEvent,
	IDndRenderItemProps,
	LinkButton,
	Toggle
} from '@tehzor/ui-components';
import { useCallback, useMemo, useState } from 'react';
import './EditableCheckItems.less';
import {renderPreview} from '../utils/renderPreview';
import {useParams} from 'react-router';
import {getNewItemOrder} from '@tehzor/tools/utils/getNewItemOrder';
import {useExtractCheckItemsIncludingDeleted} from '@src/core/hooks/queries/checkItems/hooks';
import {
	ISavingCheckItem,
	ISavingCheckItemDraftType
} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {useToggle, useUpdateEffect} from 'react-use';
import {getAllTreeKeysByParentId} from '@tehzor/tools/utils/tree/getAllTreeKeysByParentId';
import {isEqual} from 'lodash';
import {useItemDraftHandler} from '../hooks/useItemDraftHandler';
import {useItemArchivationHadler} from '../hooks/useItemArchivationHandler';
import {useCheckItemsPermissions} from '@src/core/hooks/permissions/useCheckItemsPermissions';

const addIcon = <i className="tz-plus-20" />;

const classes = {
	item: 'editable-check-items__item-dnd',
	handle: 'editable-check-items__item-dnd-handle',
	itemWithoutHandle: 'editable-check-items__item-dnd-without-handle'
};

export const EditableCheckItems = () => {
	const {checkListId} = useParams<{checkListId?: string}>();
	const {data: items} = useExtractCheckItemsIncludingDeleted(checkListId);
	const [movingItemId, setMovingItemId] = useState<string>();
	const [archivedVisible, toggleArchiveVisible] = useToggle(false);

	const areArchivedItems = items?.some(item => item.deleted);
	const preparedItems = useMemo(() => {
		if (archivedVisible) {
			return (
				items?.sort((a, b) => a.order - b.order).map(item => ({...item, isDraft: false})) ||
				[]
			);
		}
		return (
			items
				?.filter(item => !item.deleted)
				.sort((a, b) => a.order - b.order)
				.map(item => ({...item, isDraft: false})) || []
		);
	}, [items, archivedVisible]);
	const [array, setArray] = useState<ISavingCheckItem[]>(preparedItems);

	const hasDraft = useCallback(() => array.some(item => item.isDraft), [array]);
	const permissions = useCheckItemsPermissions();

	// обновляем весь массив при изменении данных в сторе
	useUpdateEffect(() => {
		setArray(preparedItems);
	}, [preparedItems]);

	// убираем преключатель если нет архивных айтемов
	useUpdateEffect(() => {
		if (!areArchivedItems) {
			toggleArchiveVisible(false);
		}
	}, [areArchivedItems]);

	const onReset = useCallback(() => {
		setArray(preparedItems);
		setMovingItemId(undefined);
	}, [preparedItems]);

	const handleAddCategory = useCallback(() => {
		if (checkListId && !hasDraft()) {
			const id = generateId();
			const order = getNewItemOrder(items || []);
			const item: ISavingCheckItem = {
				id,
				name: '',
				checkListId,
				description: '',
				parentId: undefined,
				order,
				dependencies: [],
				isDraft: true,
				draftType: ISavingCheckItemDraftType.ADDING_DRAFT
			};
			// сортировка не нужна потому что категория добавляется ниже всех
			setArray(array.concat(item));
		}
	}, [array, checkListId, hasDraft, items]);

	const handleAddSubCategory = useCallback(
		(mainItemId: string) => {
			if (checkListId && !hasDraft()) {
				const order = getNewItemOrder(array, mainItemId);
				const id = generateId();

				const item: ISavingCheckItem = {
					id,
					name: '',
					checkListId,
					description: '',
					parentId: mainItemId,
					order,
					dependencies: [],
					isDraft: true,
					draftType: ISavingCheckItemDraftType.ADDING_DRAFT
				};
				// сортировка не нужна потому что категория добавляется ниже всех
				setArray(array.concat(item));
			}
		},
		[array, checkListId, hasDraft]
	);

	// технически это заглушка до тех пор пока пользователь не нажмет на сохранение и бэк не пришлет ответ,
	// dependencies не копирую, их все равно визуально не видно, их обработкой занимается бэк
	const handleCopyCategory = useCallback(
		(item: ISavingCheckItem) => {
			if (items?.length && checkListId && !hasDraft()) {
				const ids = getAllTreeKeysByParentId(item.id, array, false);

				const idsMap: Record<string, string> = {[item.id]: generateId()};

				ids.forEach((el: string) => {
					idsMap[el] = generateId();
				});

				const mainCopy: ISavingCheckItem = {
					...item,
					id: idsMap[item.id],
					name: `(копия) ${item.name}`,
					isDraft: true,
					draftType: ISavingCheckItemDraftType.COPYING_DRAFT,
					sourceItemId: item.id,
					sourceSubItemsIds: ids
				};
				const subCopies: ISavingCheckItem[] = [];

				ids.forEach(id => {
					const index = items?.findIndex(i => i.id === id);
					if (index !== -1) {
						const oldParentId = items[index].parentId;
						subCopies.push({
							checkListId: item.checkListId,
							id: idsMap[id],
							name: items[index].name,
							parentId: oldParentId ? idsMap[oldParentId] : undefined,
							order: items[index].order,
							isDraft: true
						});
					}
				});

				// тут уже нужна сорировака
				// без нее копии попадают в конец массива и получают неправильные индексы при отображении
				setArray(array.concat(mainCopy, ...subCopies).sort((a, b) => a.order - b.order));
			}
		},
		[array, checkListId, hasDraft, items]
	);

	const handleEditCategory = useCallback(
		(index: number, itemData: ISavingCheckItem) => {
			if (itemData.draftType === ISavingCheckItemDraftType.EDITING_DRAFT) {
				const item = preparedItems.find(el => el.id === itemData.id);
				if (
					!item ||
					(item.name === itemData.name &&
						item.description === itemData.description &&
						isEqual(item.dependencies, itemData.dependencies))
				) {
					onReset();
					return;
				}
			}

			const newArray = [...array];
			newArray[index] = itemData;
			setArray(newArray);
		},
		[array, onReset, preparedItems]
	);

	const handleDragEnd = useCallback(
		({items: changedItems, dragState}: IDndEvent<ISavingCheckItem>) => {
			const movedItem = dragState.item;
			const finalItem = changedItems.find(item => item.id === movedItem?.id);

			if (!checkListId || !movedItem) {
				return;
			}

			const startItem = preparedItems.find(item => item.id === movedItem.id);

			if (!startItem || !finalItem) {
				return;
			}
			if (finalItem.order === startItem.order && finalItem.parentId === startItem.parentId) {
				onReset();
				return;
			}
			const subIds = getAllTreeKeysByParentId(movedItem.id, changedItems, false);
			const newItems = changedItems.map(item => {
				if (item.id === movedItem.id) {
					return {
						...item,
						isDraft: true,
						draftType: ISavingCheckItemDraftType.MOVING_DRAFT
					};
				}
				if (subIds.includes(item.id)) {
					return {...item, isDraft: true};
				}
				return item;
			});

			setMovingItemId(movedItem.id);
			setArray(newItems);
		},
		[checkListId, preparedItems, onReset]
	);

	const handleDraft = useItemDraftHandler();

	const [
		softDeleteDialog,
		hardDeleteDialog,
		restoreDialog,
		handleSoftDelete,
		handleHardDelete,
		handleRestore
	] = useItemArchivationHadler();

	const renderItem = useCallback(
		({item}: IDndRenderItemProps<ISavingCheckItem>) => (
			<CheckItem
				item={item}
				items={array}
				permissions={permissions}
				onReset={onReset}
				onChange={handleEditCategory}
				hasDraft={hasDraft()}
				handleAddSubCategory={handleAddSubCategory}
				handleCopyCategory={handleCopyCategory}
				handleDraft={handleDraft}
				handleSoftDelete={handleSoftDelete}
				handleHardDelete={handleHardDelete}
				handleRestore={handleRestore}
				archivedVisible={archivedVisible}
			/>
		),
		[
			array,
			permissions,
			onReset,
			handleEditCategory,
			hasDraft,
			handleAddSubCategory,
			handleCopyCategory,
			handleDraft,
			handleSoftDelete,
			handleHardDelete,
			handleRestore,
			archivedVisible
		]
	);

	return (
		<div className="editable-check-items">
			<div className="editable-check-items__header">
				<h3>Категории</h3>
				<div className="editable-check-items__header-actions">
					{(permissions.canHardDelete || permissions.canRestore) && areArchivedItems && (
						<Toggle
							checked={archivedVisible}
							onClick={toggleArchiveVisible}
							disabled={hasDraft()}
						>
							Показывать архивные
						</Toggle>
					)}
				</div>
			</div>
			<div className="editable-check-items__body">
				{items && checkListId ? (
					<DndContext
						renderPreview={renderPreview}
						treeOffset={50}
					>
						<DndList
							className={classes}
							draggableIds={
								hasDraft()
									? movingItemId
										? [movingItemId]
										: []
									: archivedVisible
									? []
									: undefined
							}
							items={array}
							renderItem={renderItem}
							onDragEnd={handleDragEnd}
							emptyMessage="Нет категорий чек-листа"
							container="check-items-list"
						/>
					</DndContext>
				) : (
					'Нет категорий чек-листа'
				)}
				{permissions.canAdd && (
					<LinkButton
					className='editable-check-items__add-button'
						label="Категория"
						onClick={handleAddCategory}
						leftIcon={addIcon}
						disabled={hasDraft() || archivedVisible}
					/>
				)}
			</div>
			{permissions.canSoftDelete && softDeleteDialog}
			{permissions.canHardDelete && hardDeleteDialog}
			{permissions.canRestore && restoreDialog}
		</div>
	);
};
