import {IGetSpaceTypeDecorationSetResponse} from '@src/api/backend/spaceTypeDecorationSets';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';
import {useQuery} from '@tanstack/react-query';

export const useSpaceTypeDecorationSet = <TResult = IGetSpaceTypeDecorationSetResponse>(
	id?: string,
	select?: (data: IGetSpaceTypeDecorationSetResponse) => TResult
) =>
	useQuery({
		queryKey: spaceTypeDecorationSetsQueryKeys.detail(id),
		meta: {
			error: 'при загрузке набора типов отделки'
		},
		select,
		enabled: !!id
	});
