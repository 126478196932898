import {useMemo} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';
import {useTranslation} from 'react-i18next';

export function useStagesTitlesMap() {
	const {t} = useTranslation();
	const {data: stagesMap} = useObjectStagesMap();

	return useMemo(
		() =>
			Object.entries({
				all: {id: 'all', name: t('spacesPage.hooks.useStagesTitlesMap.allStages')},
				...stagesMap
			}).reduce((acc, [stageId, stage]) => {
				if (stageId === 'all') {
					acc[stageId] = stage.name;
				} else {
					acc[stageId] = t(`objectStages.${stageId}`);
				}

				return acc;
			}, {}) as Record<ObjectStageIds, string>,
		[stagesMap, t]
	);
}
