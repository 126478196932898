import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetTaskResponse = ITask;

/**
 * Получает рабочую группу по id
 *
 * @param taskId id рабочей группы
 */
export const requestTask = async (taskId: string) => {
	const response = await httpRequests.withToken.get<IGetTaskResponse>(`tasks/${taskId}`);
	return response.data;
};
