import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';

/**
 * Проверяет загружены ли данные
 *
 * @param getData функция, принимающая состояние и возвращающая данные, содержащие loaded
 * @param action действие, выполняющееся если данные не загружены
 * @param responseFromStateExtractor функция для извлечения из области state данных, которые приходят в запросе
 * @param todayOnly параметр указывает, что данные актуальны в течении дня относительно loadedAt
 */
export default function checkLoaded<
	S,
	R,
	A extends Action,
	LS extends {loaded?: boolean, loadedAt?: number} = {loaded?: boolean, loadedAt?: number}
>(
	getData: (state: S) => LS,
	action: A,
	responseFromStateExtractor?: (s: LS) => R | undefined,
	todayOnly = false
): ThunkAction<Promise<R | undefined>, S, never, A> {
	return (dispatch, getState): Promise<R> => {
		const state = getState();
		const data = getData(state);
		const loaded = typeof data === 'object' ? data.loaded : false;
		const today = todayOnly && data.loadedAt && Date.now() > new Date(data.loadedAt).setUTCHours(23, 59, 59, 999);
		return (
			!loaded || today
				? dispatch(action)
				: Promise.resolve(responseFromStateExtractor ? responseFromStateExtractor(data) : data)
		) as Promise<R>;
	};
}
