import { memo, useCallback } from 'react';
import {Dialog} from '@tehzor/ui-components';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {EntityDocsVirtualizeItems} from '@src/components/EntityDocs/components/EntityDocsVirtualizeItems';
import './DocumentsDialog.less';

interface IDocumentsDialogProps {
	documents: IDocument[];
	totalCount?: number;
	title?: string;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Модальное окно вывода документов
 */
export const DocumentsDialog = memo((props: IDocumentsDialogProps) => {
	const {documents, totalCount, title, isOpen, onClose} = props;

	const renderModalTitle = useCallback(
		() => (
			<div className="documents-dialog__title">
				{title}
				{totalCount && (
					<span className="documents-dialog__title--counter">{`(${totalCount})`}</span>
				)}
			</div>
		),
		[title, totalCount]
	);

	return (
		<Dialog
			className={{
				root: 'documents-dialog',
				content: 'documents-dialog__content',
				body: 'documents-dialog__body'
			}}
			isOpen={isOpen}
			title={renderModalTitle()}
			onRequestClose={onClose}
		>
			<EntityDocsVirtualizeItems documents={documents} />
		</Dialog>
	);
});
