import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableSpaceOwnerAction, IEditableSpaceOwnerState, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export const useEditableSpaceOwnerState = (owner?: ISpaceOwner) =>
	useReducer<
		Reducer<IEditableSpaceOwnerState, IEditableSpaceOwnerAction>,
		ISpaceOwner | undefined
	>(createReducer(init, errorsFns), owner, init);
