import {Plate} from '@tehzor/ui-components';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {Common} from './Common';
import {useTranslation} from 'react-i18next';

interface IDesktopInfoProps {
	structure: IStructure;
}

export const DesktopInfo = ({structure}: IDesktopInfoProps) => {
	const {t} = useTranslation();

	return (
		<Plate className={{content: 'structure-page__info-plate'}}>
			<div className="structure-page__info-title">{t('structurePage.infoDesktop.title')}</div>
			<Common structure={structure} />
		</Plate>
	);
};
