import useAppSelector from '@src/core/hooks/useAppSelector';
import {aggregateByContractors} from '@src/store/modules/statistics/building/selectors/creation';
import ContractorStats from './ContractorStats';
import {useFilteredContractors} from '@src/pages/StatisticsPage/hooks/useFilteredContractors';
import {areCreationStatsLoading} from '@src/store/modules/statistics/ui/selectors/building';
import StatsTreePlaceholder from '../../StatsTreePlaceholder';
import CommonStatsCards from './CommonStatsCards';
import {isBuildingFiltersSetUp} from '@src/store/modules/settings/pages/statistics/building/selectors/filters';

const ByContractorsStats = () => {
	const statsByContractors = useAppSelector(aggregateByContractors);
	const filters = useAppSelector(s => s.settings.pages.statistics.building.filters);
	const isFiltersSetUp = useAppSelector(isBuildingFiltersSetUp);
	const contractors = useFilteredContractors(statsByContractors, filters, isFiltersSetUp);
	const loading = useAppSelector(areCreationStatsLoading);

	return (
		<>
			{contractors.map(({contractor, users, stats}) => (
				<ContractorStats
					key={contractor.id}
					contractor={contractor}
					contractorStats={stats}
					users={users}
				/>
			))}

			{loading && contractors.length === 0 && (
				<StatsTreePlaceholder
					includeChildren
					permanentContent={(
						<CommonStatsCards
							hideChecks
							hideInspections
						/>
					)}
				/>
			)}
		</>
	);
};

export default ByContractorsStats;
