import {memo, ReactElement, useCallback, useMemo} from 'react';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {changeCheckAddingEntityType} from '@src/store/modules/settings/checkAdding/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {MenuItem} from '@tehzor/ui-components';
import {IMenuItemProps} from '@tehzor/ui-components/src/components/menu/MenuItem';
import classNames from 'classnames';
import MenuLayer from '@tehzor/ui-components/src/components/menu/MenuLayer';
import {useTranslation} from 'react-i18next';

interface IEntityTypeSelectProps {
	type: ICheckAddingEntityType;
	types: ICheckAddingEntityType[];
	disabled: boolean;
}

const EntityTypeSelect = ({type, types, disabled}: IEntityTypeSelectProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const changeType = useCallback(
		(value: ICheckAddingEntityType) => {
			if (!disabled) {
				dispatch(changeCheckAddingEntityType(value));
			}
		},
		[disabled]
	);

	const entityTypeMap = useMemo<Record<ICheckAddingEntityType, string>>(
		() => ({
			problem: t('addingCheckGlobal.entityTypeSelect.entityTypeMap.problem'),
			inspection: t('addingCheckGlobal.entityTypeSelect.entityTypeMap.inspection')
		}),
		[t]
	);

	const menuContent: Array<ReactElement<IMenuItemProps>> = [];
	for (const item of types) {
		menuContent.push(
			<MenuItem
				key={item}
				onClick={() => changeType(item)}
				selected={type === item}
			>
				{entityTypeMap[item]}
			</MenuItem>
		);
	}

	return menuContent.length > 1 ? (
		<MenuLayer
			trigger={({isOpen, toggle, ref}) => (
				<div
					data-testid="EntityTypeSelect"
					className={classNames('adding-check-dialog__entity-type-select', {disabled})}
					onClick={!disabled ? toggle : undefined}
					ref={ref}
				>
					{entityTypeMap[type]}{' '}
					{!disabled && <i className={classNames('tz-simple-arrow-20', {isOpen})} />}
				</div>
			)}
		>
			{menuContent}
		</MenuLayer>
	) : (
		<div
			data-testid="EntityTypeSelect"
			className={classNames('adding-check-dialog__entity-type-select', 'disabled')}
		>
			{entityTypeMap[type]}
		</div>
	);
};

export default memo(EntityTypeSelect);
