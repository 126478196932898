import {
	IConvertCustomFieldDate,
	IConvertCustomFieldSelect,
	ICustomFieldSetting,
	ValueTypeId
} from '@tehzor/tools/interfaces/fields';
import {memo} from 'react';
import {useCustomFieldsMap} from '@src/core/hooks/queries/fields/hooks';
import {CustomFilterSelect} from '@src/components/CustomFilters/Components/desktop/CustomFilterSelect';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ICustomFiltersData} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';
import {CustomFilterDate} from '@src/components/CustomFilters/Components/desktop/CustomFilterDate';
import {convertCustomFields} from '@src/components/CustomFilters/helpers/convertCustomFields';
import {useCustomFiltersHandlers} from '@src/components/CustomFilters/hooks/useCustomFiltersHandlers';

interface ICustomFiltersProps {
	settings: Record<string, ICustomFieldSetting>;
	filtersData?: ICustomFiltersData;
}

export const CustomFilters = memo(({settings, filtersData}: ICustomFiltersProps) => {
	const {dispatch} = useEntitiesFiltersCtx();
	const {data: customFields} = useCustomFieldsMap();
	const convertedFields = convertCustomFields(settings, customFields);

	const [onChange, onClear] = useCustomFiltersHandlers(dispatch, filtersData);

	const renderFilters = () =>
		convertedFields.map(fieldData => {
			const filterData = filtersData?.[fieldData.key];
			switch (fieldData.valueTypeId) {
				case ValueTypeId.SELECT:
					return (
						<CustomFilterSelect
							fieldData={fieldData as IConvertCustomFieldSelect}
							onChange={onChange}
							onClear={onClear}
							filterValue={
								filterData?.valueTypeId === ValueTypeId.SELECT
									? filterData.value
									: undefined
							}
							key={fieldData.id}
						/>
					);
				case ValueTypeId.DATE:
					return (
						<CustomFilterDate
							fieldData={fieldData as IConvertCustomFieldDate}
							onChange={onChange}
							onClear={onClear}
							filterValue={
								filterData?.valueTypeId === ValueTypeId.DATE
									? filterData.value
									: undefined
							}
							key={fieldData.id}
						/>
					);
				default:
					return null;
			}
		});

	return <>{renderFilters().map(filter => filter)}</>;
});
