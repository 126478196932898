import * as React from 'react';
import {Dispatch, memo, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {InputType, ViewerType} from '@tehzor/ui-components/src/components/LocationSelect';
import {extractLocSelectDefaultSettings} from '@src/store/modules/settings/locationSelect/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeLocDefaultInputType,
	changeLocDefaultViewerType
} from '@src/store/modules/settings/locationSelect/actions';
import {usePlans, usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedLocationSelect} from '@src/components/TranslatedLocationSelect';

interface ILocationProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	planId?: string;
	location?: ILocation;
	objectId: string;
	entityLocation?: ILocation;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const LocationMemo = <
	S extends {location?: ILocation | null; planId?: string | null; floor: string},
	E
>({
	className,
	style,
	label,
	planId,
	location,
	objectId,
	entityLocation,
	editingDispatch,
	required,
	disabled,
	hasError,
	...restProps
}: ILocationProps<S, E>) => {
	const {data: plans} = usePlansAsArray(objectId);
	const {data: plansMap} = usePlans(objectId);
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.location.label');
	const settings = useAppSelector(s => extractLocSelectDefaultSettings(s, objectId));
	const dispatch = useAppDispatch();

	const handleChange = useCallback(
		(v: ILocation | null) => {
			editingDispatch({type: 'update', field: 'location', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'location'});
			}
		},
		[required]
	);

	const handleViewerTypeChange = useCallback(
		(v: ViewerType) => {
			dispatch(changeLocDefaultViewerType(objectId, v));
		},
		[objectId]
	);

	const handleInputTypeChange = useCallback(
		(v: InputType) => {
			dispatch(changeLocDefaultInputType(objectId, v));
		},
		[objectId]
	);

	const handlePlanChange = useCallback(
		(v?: string) => {
			editingDispatch({type: 'update', field: 'planId', value: v});
			// Автоматическая установка этажа из плана
			if (v && plansMap) {
				const plan = plansMap.byId[v];
				editingDispatch({
					type: 'update',
					field: 'floor',
					value: plan?.floor ? String(plan.floor) : ''
				});
			} else {
				editingDispatch({type: 'update', field: 'floor', value: ''});
			}
			if (required) {
				editingDispatch({type: 'update-error', field: 'planId'});
				editingDispatch({type: 'update-error', field: 'floor'});
			}
		},
		[required, plansMap]
	);

	const locationPlans =
		(entityLocation?.points?.length || entityLocation?.sectors?.length) && planId && plansMap
			? [plansMap.byId[planId]]
			: plans;

	return (
		<div
			className={className}
			style={style}
			{...restProps}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>

			<TranslatedLocationSelect
				mode="edit"
				defaultViewerType={settings.defaultViewerType}
				defaultInputType={settings.defaultInputType}
				planId={planId}
				location={location}
				entityLocation={entityLocation}
				plans={locationPlans}
				multiplePoints
				onLocationChange={handleChange}
				onViewerTypeChange={handleViewerTypeChange}
				onInputTypeChange={handleInputTypeChange}
				onPlanChange={handlePlanChange}
			>
				{(displayValue, open: () => void) => (
					<TextField
						className="text-field_interactive"
						disabled={disabled}
						elementType="div"
						value={displayValue}
						icon={<i className="tz-location-20" />}
						error={
							required && hasError
								? t('components.editableFields.location.error')
								: undefined
						}
						onClick={open}
					/>
				)}
			</TranslatedLocationSelect>
		</div>
	);
};
export const Location = memo(LocationMemo) as typeof LocationMemo;
