import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';

/**
 * Извлекает нарушения передачи собственнику
 */
export const extractOwnerAcceptanceProblemsArray = (data: INormalizedData<IListProblem>) =>
	data.allIds.map(id => data.byId[id]);

/**
 * Извлекает сущности передачи собственнику
 */
export const extractOwnerAcceptanceEntities = (data: INormalizedData<IListProblem>) => {
	const problems = extractOwnerAcceptanceProblemsArray(data);
	return problems
		.map<IOwnerAcceptanceEntity>(item => ({
			id: `p_${item.id}`,
			type: 'problem',
			data: item
		}))
		.sort((a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0));
};
