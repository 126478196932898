import IUser from '@tehzor/tools/interfaces/IUser';
import {getPermissionsForInspection} from './getPermissionsForInspection';
import {IPreparedInspection} from '../interfaces/IPreparedInspection';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {format} from 'date-fns';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

/**
 * Преобразовывает осмотры в необходимый для вывода формат
 *
 * @param inspections массив осмотров
 * @param networkStatus статус соединения
 * @param user текущий пользователь
 */
export const convertInspections = (
	inspections: IListInspection[],
	networkStatus: boolean,
	groupsMap?: Record<string, IWorkingGroup>,
	usersMap?: Record<string, IBriefUser>,
	user?: IUser
): IPreparedInspection[] =>
	inspections.map(item => ({
		id: item.id,
		object: item.object,
		links: item.links,
		number: item.number,
		localNumber: item.localNumber,
		transferStatus: item.transferStatus,
		plan: item.plan?.name || '',
		floor: item.floor || '',
		categoryId: item.categoryId,
		location: item.displayLocation || '',
		performers:
			item.performers?.length && usersMap
				? item.performers?.map(id => usersMap[id])
				: undefined,
		performersActiveGroup: item.performersActiveGroup && groupsMap
			? groupsMap[item.performersActiveGroup]
			: undefined,
		watchers: item.watchers,
		createdBy: item.createdBy?.fullName || '',
		createdAt: item.createdAt ? format(item.createdAt, dateTimeCommaSeparatedFormat) : '',
		modifiedBy: item.modifiedBy?.fullName || '',
		modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeCommaSeparatedFormat) : '',
		customFields: item.customFields,
		description: item.description,
		attachment: item.attachments ? item.attachments.find(att => att.preview) : undefined,
		...getPermissionsForInspection(item, networkStatus, item.object?.id, user)
	}));
