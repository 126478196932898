import {memo} from 'react';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import Description from '@src/components/viewFields/Description';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import ObjectInfo from '@src/components/viewFields/ObjectInfo';
import Location from '@src/components/viewFields/Location';
import TaskType from '@src/components/viewFields/TaskType';
import {TaskStatusGrid} from './TaskStatusGrid';
import {TaskPriority} from '@src/components/viewFields/TaskPriority';
import {useTranslation} from 'react-i18next';
import {IntervalView} from '@src/components/viewFields/InternalView';
import {TaskResponsibles} from './TaskResponsibles';

interface IDesktopInfoProps {
	task: IEnrichedTask;
}

const DesktopInfo = ({task}: IDesktopInfoProps) => {
	const {t} = useTranslation();

	return (
		<EntityGrid
			className={{wrap: 'task-page__d-entities-grid'}}
			withBorders
		>
			<TaskStatusGrid task={task} />
			{!!task.taskPriority && <TaskPriority task={task} />}
			{task.taskType && <TaskType task={task} />}
			{task.description && <Description value={task.description} />}
			{task.objectId && task.objectId.id && (
				<ObjectInfo objectId={task.objectId.id.toString()} />
			)}
			{(task.taskIntervalStart || task.taskIntervalEnd) && (
				<IntervalView
					start={task.taskIntervalStart}
					stop={task.taskIntervalEnd}
				/>
			)}
			{!!task.location && (
				<Location
					objectId={task?.objectId?.id}
					location={task.location}
					floor={task.floor}
					planId={task.planId}
				/>
			)}
			{task.respUsers?.length || task.activeGroup ? <TaskResponsibles task={task} /> : null}
			{task.createdAtNumber && (
				<EditorInfo
					icon={<i className="tz-document-20" />}
					label={t('taskViewPage.info.created.label')}
					date={task?.createdAtNumber}
					user={task.createdBy}
				/>
			)}
			{task.createdAt !== task.modifiedAt && (
				<EditorInfo
					icon={<i className="tz-edit-20" />}
					label={t('taskViewPage.info.updated.label')}
					date={task?.modifiedAtNumber}
					user={task.modifiedBy}
				/>
			)}
		</EntityGrid>
	);
};

export default memo(DesktopInfo);
