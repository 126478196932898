import {StatsTreePanel, Tag} from '@tehzor/ui-components';
import ProblemsStatsChart from './ProblemsStatsChart';
import {StatsByStatus} from '@src/utils/statsAggregators/problemsByStatuses';
import {useNavigateProblems} from '@src/pages/StatisticsPage/hooks/useNavigateProblems';
import {sumStatsValues} from '@src/pages/StatisticsPage/utils/sumStatsValues';
import {useUser} from '@src/core/hooks/queries/users/hooks';

interface IWorkerStatsProps {
	userId: string;
	stats?: StatsByStatus;
	level?: number;
	isDeleted?: boolean;
}

const attentionIcon = <i className="tz-warning-20 statistics-page__deleted-tag-icon" />;
export const WorkerStats = ({userId, stats, level, isDeleted}: IWorkerStatsProps) => {
	const totalCount = stats?.expired
		? sumStatsValues(stats) - (stats?.expired || 0)
		: sumStatsValues(stats);

	const {data: user} = useUser(userId);

	const navigate = useNavigateProblems(
		{
			navProps: [
				{
					type: 'respUsers',
					prop: user?.id
				}
			],
			page: 'acceptance'
		},
		undefined
	);

	const deletedTag = (
		<Tag
			className={{
				root: 'statistics-page__deleted-tag',
				label: 'statistics-page__deleted-tag-label'
			}}
			label="Пользователь удален из подрядчика"
			icon={attentionIcon}
		/>
	);
	const handleClick = (status: string) => {
		navigate(status);
	};
	if (!user) {
		return null;
	}
	return (
		<StatsTreePanel
			id={user.id}
			title={user.fullName}
			subTitle={user.position}
			level={level}
			tag={isDeleted ? deletedTag : undefined}
			permanentContent={
				<ProblemsStatsChart
					onClick={handleClick}
					stats={stats}
					totalCount={totalCount}
					level={level}
				/>
			}
		/>
	);
};
