import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useCallback} from 'react';
import {AnyAction} from 'redux';
import {useQueryClient} from '@tanstack/react-query';

/**
 * Хук обновления кэша и стора сущностей
 * @param queryKey - queryKey используемый для работы с кэшем сущности
 */
export const useUpdateEntity = (queryKey: {
	detail: (key?: string) => Array<string | undefined>;
}) => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();

	/**
	 * Функция обновления кэша и стора сущности
	 * @param newItem - созданная сущность из ответа сервера
	 * @param deleteAction - redux action для удаления сущности (удалит локально сохраненную сущность)
	 * @param addAction - redux action для добавления сущности в store
	 * @param key - key локальной сущности
	 */
	const updateEntity = useCallback(
		<E extends {id: string; objectId: string}>(
			newItem: E,
			deleteAction: (objectId: string, id: string, quietly: boolean) => AnyAction,
			addAction: (item: E) => AnyAction,
			key: string
		) => {
			dispatch(deleteAction(newItem.objectId, key, true));

			dispatch(addAction(newItem));
			queryClient.removeQueries({queryKey: queryKey.detail(key)});
		},
		[dispatch, queryClient]
	);

	/**
	 * Функция обновления кэша и стора сущности
	 * @param newItem - созданная сущность из ответа сервера
	 * @param deleteAction - redux action для удаления сущности (удалит локально сохраненную сущность)
	 * @param addAction - redux action для добавления сущности в store
	 * @param key - key локальной сущности
	 */
	const updateCompanyBindEntity = useCallback(
		<E extends {id: string; companyId: string}>(data: {
			newItem: E;
			deleteAction?: (companyId: string, id: string, quietly: boolean) => AnyAction;
			addAction?: (item: E) => AnyAction;
			key: string;
		}) => {
			const {newItem, deleteAction, addAction, key} = data;
			if (deleteAction) {
				dispatch(deleteAction(newItem.companyId, key, true));
			}
			if (addAction) {
				dispatch(addAction(newItem));
			}
			queryClient.removeQueries({queryKey: queryKey.detail(key)});
		},
		[dispatch, queryClient]
	);

	return {updateEntity, updateCompanyBindEntity};
};
