import {IEditContractResponse} from '@src/api/backend/contract';
import {IEditContractParams} from '@src/api/cache/contracts/defaults/mutations/edit';
import {contractsQueryKeys} from '@src/api/cache/contracts/keys';
import {useEditContractMutation} from '@src/api/cache/contracts/mutations';
import {useQueryClient} from '@tanstack/react-query';

export const useEditContract = (): ((
	params: IEditContractParams
) => Promise<IEditContractResponse | undefined>) => {
	const queryClient = useQueryClient();
	const mutation = useEditContractMutation();

	return async (params: IEditContractParams) => {
		const response = await mutation.mutateAsync(params);
		await queryClient.invalidateQueries({queryKey: contractsQueryKeys.all()});
		return response;
	};
};
