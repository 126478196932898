import { memo } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {DesktopMainActions} from './actions/MainActions.desktop';
import {useWarrantyClaimFromSpaceBreadcrumbs} from '@src/core/hooks/breadcrumbs/useWarantyClaimFromSpaceBreadcrumbs';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';

interface IPageBreadcrumbsProps {
	objectId: string;
	warrantyClaim: IWarrantyClaim;
}

export const FromSpacePageBreadcrumbs = memo(({objectId, warrantyClaim}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useWarrantyClaimFromSpaceBreadcrumbs(
		objectId,
		warrantyClaim.id,
		warrantyClaim
	);

	return (
		<AppBreadcrumbs items={breadcrumbs}>
			<DesktopMainActions
				objectId={objectId}
				warrantyClaim={warrantyClaim}
			/>
		</AppBreadcrumbs>
	);
});
