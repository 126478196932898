import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppSelector from '../../useAppSelector';
import {toCheck, toListCheck} from './utils/convertToLocalSave';
import {useLocalChecks} from '../../queries/checks/hooks';
import {useAddCheckMutation} from '@src/api/cache/checks/mutations';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {IAddCheckParams} from '@src/api/cache/checks/defaults/useChecksMutationDefaults';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {addNewEntityToNormalizedData} from '@src/utils/addNewEntityToNormalizedData';
import {IGetChecksResponse} from '@src/api/backend/checks';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';

// Добавление проверки, с учётом офлайн режима
export const useAddCheck = (object?: IObject) => {
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const user = useAppSelector(s => s.auth.profile);
	const {data: localChecks} = useLocalChecks();
	const nextNumber = useNextLocalNumber<IListCheck>(localChecks, 'createdAt');
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	const mutation = useAddCheckMutation();

	return ({objectId, links, stage}: Omit<IAddCheckParams, 'key'>) => {
		const key = Date.now().toString(10);
		if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
			links.checkRecordId = undefined;
		}
		const converted = toCheck(user, objectId, links, stage, key, nextNumber);
		const convertedtoList = object
			? toListCheck(object, user, links, stage, key, nextNumber)
			: undefined;
		queryClient.setQueryData<IListCheck | undefined>(
			[...checksQueryKeys.detail(key), objectId],
			convertedtoList
		);

		if (!online && isOfflineModeAvailable) {
			addWarningToast('Локальное сохранение', 'проверка добавлена локально');
			void queryClient.refetchQueries({queryKey: checksQueryKeys.localList()});
			queryClient.setQueryData<IGetChecksResponse>(checksQueryKeys.latest(), oldData => {
				if (!oldData) return undefined;
				return addNewEntityToNormalizedData<IGetChecksResponse, ICheck>(oldData, converted);
			});
		}
		mutation.mutate({
			objectId,
			links,
			stage,
			key
		});
		return converted;
	};
};
