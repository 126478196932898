import {useAddingObjectDialog} from '@src/components/AddingObjectDialog/hooks/useAddingObjectDialog';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import {useObjectsPermissions} from '@src/core/hooks/permissions/useObjectsPermissions';
import {IconButton} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useObject} from '@src/core/hooks/queries/objects/hooks';

interface IObjectsBreadcrumbsProps {
	objectId?: string;
}

export const ObjectsBreadcrumbs = ({objectId}: IObjectsBreadcrumbsProps) => {
	const {data: object} = useObject(objectId);

	const breadcrumbs = useObjectBreadcrumbs(objectId);

	const isDesktop = useIsDesktop();

	const [addingDialog, openAddingDialog] = useAddingObjectDialog(object?.id, object?.companyId);
	const {canAdd} = useObjectsPermissions();

	return (
		<div className="objects-page__breadcrumbs-container">
			<AppBreadcrumbs
				className="objects-page__breadcrumbs"
				items={breadcrumbs}
			/>

			{isDesktop && canAdd && (
				<>
					<IconButton
						onClick={openAddingDialog}
						type="accent-blue"
					>
						<i className="tz-plus-24" />
					</IconButton>
					{addingDialog}
				</>
			)}
		</div>
	);
};
