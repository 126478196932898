import { useState } from 'react';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import DesktopInfo from './info/Info.desktop';
import {Plate} from '@tehzor/ui-components';
import DesktopActions from './actions/Actions.desktop';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {useLocation} from 'react-router-dom';
import {FromSpacePageBreadcrumbs} from './FromSpacePageBreadcrumbs';
import Table from './table/Table';
import {useDesktopColumns} from './table/columns.desktop';
import {useTranslation} from "react-i18next";

interface IDesktopProps {
	objectId: string;
	warrantyClaimId: string;
	warrantyClaim?: ILinkedWarrantyClaim;
}

export const Desktop = ({objectId, warrantyClaimId, warrantyClaim}: IDesktopProps) => {
	const {t} = useTranslation();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const spaceId = (useLocation().state as {spaceId?: string} | null)?.spaceId;

	const desktopColumns = useDesktopColumns(warrantyClaimId, objectId);

	useAppHeader(
		{
			title: warrantyClaim
				? `${t('warrantyClaimPage.title')} №${warrantyClaim.number}`
				: t('warrantyClaimPage.title'),
			showBackBtn: true
		},
		[warrantyClaim]
	);

	return warrantyClaim ? (
		<div className="page-cont warranty-claim-page">
			{spaceId ? (
				<FromSpacePageBreadcrumbs
					objectId={objectId}
					warrantyClaim={warrantyClaim}
				/>
			) : (
				<PageBreadcrumbs
					objectId={objectId}
					warrantyClaim={warrantyClaim}
				/>
			)}
			<div className="warranty-claim-page__d-columns">
				<div className="warranty-claim-page__d-column warranty-claim-page__d-column_info">
					<DesktopInfo
						objectId={objectId}
						warrantyClaim={warrantyClaim}
					/>
				</div>

				<div className="warranty-claim-page__d-column warranty-claim-page__d-column_entities">
					{warrantyClaim && <DesktopActions warrantyClaim={warrantyClaim} />}

					<Plate withoutPadding>
						<Table
							objectId={objectId}
							warrantyClaimId={warrantyClaimId}
							warrantyClaim={warrantyClaim}
							columns={desktopColumns}
							selectedRows={selectedRows}
							onSelectedRowsChange={setSelectedRows}
						/>
					</Plate>
				</div>
			</div>
		</div>
	) : null;
};
