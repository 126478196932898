import SelectedEntitiesExport from './SelectedEntitiesExport';
import SelectedEntitiesDeletion from './SelectedEntitiesDeletion';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';

interface ISelectionsActionsProps {
	objectId: string;
	ownerAcceptanceId: string;
	selectedEntities: IOwnerAcceptanceEntity[];
}

const SelectionActions = ({
	objectId,
	ownerAcceptanceId,
	selectedEntities
}: ISelectionsActionsProps) => {
	const permissions = useOwnerAcceptancesPermissions(objectId);

	return (
		<>
			{permissions.canExport && (
				<SelectedEntitiesExport
					objectId={objectId}
					ownerAcceptanceId={ownerAcceptanceId}
					selectedEntities={selectedEntities}
				/>
			)}
			{permissions.canDelete && (
				<SelectedEntitiesDeletion selectedEntities={selectedEntities}/>
			)}
		</>
	);
};

export default SelectionActions;
