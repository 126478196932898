import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import {Tag} from '@tehzor/ui-components';
import './ProblemTagsCount.less';
import {useExtractProblemTagsById} from '@src/core/hooks/queries/problemTags/hooks';

interface IProblemTagsCountProps {
	object: IParentObject;
}

const getProblemTags = (obj: IParentObject, result: Record<string, number>) => {
	if (obj.stats?.problemTags) {
		for (const [key, value] of Object.entries(obj.stats.problemTags)) {
			result[key] = value.count;
		}
	}
	if (obj.children) {
		for (const child of obj.children) {
			getProblemTags(child, result);
		}
	}
};

export const ProblemTagsCount = ({object}: IProblemTagsCountProps) => {
	const {data: tagMap} = useExtractProblemTagsById();

	const result: Record<string, number> = {};
	getProblemTags(object, result);

	return (
		<div className="problem-tags-count">
			{Object.entries(result).map(([tagId, count]) =>
				count && tagMap && tagMap[tagId] ? (
					<div
						className="problem-tags-count__container"
						key={tagId}
					>
						<Tag
							className="problem-tags-count__container-tag"
							label={tagMap[tagId].name}
							dotColor={tagMap[tagId].color}
							size="small"
						/>
						<div className="problem-tags-count__container-count">{count}</div>
					</div>
				) : null
			)}
		</div>
	);
};
