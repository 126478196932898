import {Action, ActionCreator, AnyAction, Middleware} from 'redux';
import {IState} from '@src/store/modules';
import {AppThunkAction, AppThunkDispatch} from '@src/store/interfaces/thunk';
import {MissingTokenError} from '@tehzor/tools/errors/MissingTokenError';
import {logout} from '../modules/auth/actions';
import {MissingDeviceError} from '@tehzor/tools/errors/MissingDeviceError';

const API_ACTION_TYPE = 'call-api';

export interface ApiAction<R = unknown> extends Action<string> {
	type: typeof API_ACTION_TYPE;
	request: ActionCreator<AnyAction | AppThunkAction>;
	success: ActionCreator<AnyAction | AppThunkAction>;
	failure: ActionCreator<AnyAction | AppThunkAction>;
	call: () => Promise<R>;
}

export type ApiDispatch<R = unknown> = (action: ApiAction<R>) => Promise<R>;

// type MergedAction = Action | ThunkAction<unknown, IState, never, Action>;

// type MergedDispatch = Dispatch | ThunkDispatch<IState, never, AnyAction>;

type ApiMiddleware = Middleware<ApiDispatch, IState, AppThunkDispatch>;

/**
 * Middleware для обработки вызовов к backend api
 */
export default (): ApiMiddleware =>
	({dispatch}) =>
	next =>
	async (action: Action | ApiAction) => {
		if (action.type !== API_ACTION_TYPE) {
			return next(action);
		}

		const {request, success, failure, call} = action as ApiAction;
			dispatch(request());

		try {
			const response = await call();
			dispatch(success(response));
			return response;
		} catch (error: unknown) {
			if (error instanceof MissingTokenError || error instanceof MissingDeviceError) {
				return dispatch(logout());
			}
				dispatch(failure(error));
			throw error;
		}
	};

export const createApiAction = <R>(
	request: ActionCreator<Action | AppThunkAction>,
	success: ActionCreator<Action | AppThunkAction>,
	failure: ActionCreator<Action | AppThunkAction>,
	call: () => Promise<R>
): ApiAction<R> => ({
	type: API_ACTION_TYPE,
	request,
	success,
	failure,
	call
});
