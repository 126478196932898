import './CheckInfoMobileCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import CheckInfo from '@src/components/tableCells/CheckInfo';

const CheckInfoMobileCell = ({row}: CellProps<{data: ICheck}>) => (
	<div className="m-check-info-cell">
		<div className="m-check-info-cell__header">
			<CheckInfo data={row.original.data}/>

			<ClickPreventWrap style={{marginLeft: '8px'}}>
				<TableRowSelect
					{...row.getToggleRowSelectedProps()}
					style={{marginTop: '12px'}}
				/>
			</ClickPreventWrap>
		</div>
	</div>
);

export default CheckInfoMobileCell;
