import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import IProblemsByStatusesStatsItem from '@tehzor/tools/interfaces/statistics/IProblemsByStatusesStatsItem';
import {
	byCompanies,
	byContractors,
	byObjects,
	byUsers
} from '@src/utils/statsAggregators/problemsByStatuses';

export const extractStatsData = (state: IState): IProblemsByStatusesStatsItem[] =>
	state.statistics.warranty.problemsByStatuses.data || [];

/**
 * Агрегирует по компаниям статистику нарушений
 */
export const aggregateByCompanies = createSelector([extractStatsData], byCompanies);

/**
 * Агрегирует по объектам статистику нарушений
 */
export const aggregateByObjects = createSelector([extractStatsData], byObjects);

/**
 * Агрегирует по инженерам статистику нарушений
 */
export const aggregateByUsers = createSelector([extractStatsData], byUsers);

/**
 * Агрегирует по подрядчикам статистику нарушений
 */
export const aggregateByContractors = createSelector([extractStatsData], byContractors);
