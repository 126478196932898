import {createMutation} from 'react-query-kit';
import {IAddCheckParams} from '../defaults/useChecksMutationDefaults';
import {checksQueryKeys} from '../keys';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';

export const useAddCheckMutation = createMutation<ICheck, IAddCheckParams>(
		{
			mutationKey: checksQueryKeys.add()
		}
);
