import {memo} from 'react';
import {useEditableCheckList} from '@src/components/EditableCheckList/hooks/useEditableCheckList';
import {Plate} from '@tehzor/ui-components';
import './EditingCheckList.less';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';

interface IEditingCheckListProps {
	checkListId: string;
}

export const EditingCheckList = memo(({checkListId}: IEditingCheckListProps) => {
	const {data: checkList} = useExtractCheckListById(checkListId);
	const [checkListFields, checkItemsFields] = useEditableCheckList({
		checkList
	});

	return (
		<div className="editing-check-list__container">
			<Plate className="editing-check-list__info-grid">{checkListFields}</Plate>
			<Plate className="editing-check-list__items-grid">{checkItemsFields}</Plate>
		</div>
	);
});
