import {CellProps} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';

export const NumberCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => {
	const {number, localNumber} = row.original;

	return (
		<div className="owner-acceptances-page__d-table-number">
			№
			{number ?? localNumber}
		</div>
);
};
