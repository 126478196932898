import {createContext, PropsWithChildren} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IPreparedCheckList} from '../interfaces/IPreparedCheckList';
import {useCopyCheckList} from '@src/core/hooks/mutations/checkLists/useCopyCheckList';
import {useDeleteCheckList} from '@src/core/hooks/mutations/checkLists/useDeleteCheckList';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedCheckList>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({
	children,
	reloadList
}: PropsWithChildren<{reloadList: () => void}>) => {
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить чек-лист?',
		'Все связанные данные будут безвозвратно удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const {mutateAsync: copyCheckList} = useCopyCheckList();
	const {mutateAsync: deleteCheckList} = useDeleteCheckList();
	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedCheckList>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await deleteCheckList(action.payload.id);
				reloadList();
			}

			if (action.type === 'copy') {
				await copyCheckList(action.payload.id);
				reloadList();
			}
		}
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
