import {determinePolarAngle} from './determineAangle';

const PI_90 = Math.PI * 0.5;
const PI_270 = Math.PI * 1.5;

/**
 * Создаёт граф, образованный пересечениями всех линий
 *
 * @param lines линии
 * @returns {{vertices: Array, connections: Array}} vertices - массив вершин, connections - массив смежностей
 */
function createGraph(lines) {
	const n = lines.length;
	const vertices = [];
	const connections = [];

	// Пересечения всех линий
	const inters = new Array(n);
	for (let i = 0; i < n; i++) {
		inters[i] = [];
	}

	// Находим пересечения
	const count2 = n;
	const count1 = count2 - 1;
	for (let i = 0; i < count1; i++) {
		for (let j = i + 1; j < count2; j++) {
			const interPoint = lines[i].el.intersectsLine(lines[j].el);
			if (interPoint) {
				interPoint.x = Math.round(interPoint.x);
				interPoint.y = Math.round(interPoint.y);
				inters[i].push(interPoint);
				inters[j].push(interPoint);
			}
		}
	}

	// Добавляет вершину в массив
	function addVertex(vertex) {
		let index = vertices.findIndex(v => v.x === vertex.x && v.y === vertex.y);
		if (index === -1) {
			vertices.push({
				x: vertex.x,
				y: vertex.y
			});
			connections.push([]);
			index = vertices.length - 1;
		}
		return index;
	}

	// Разбиваем отрезки на ребра
	for (let i = 0, line, lineInters; i < n; i++) {
		line = lines[i];
		lineInters = inters[i];

		if (lineInters.length > 1) {
			// Находим угол между отрезком и горизонталью
			let angle = determinePolarAngle({x: line.x1, y: line.y1}, {x: line.x2, y: line.y2});

			// В зависимости от угла сортируем точки на отрезке
			if (angle >= 0 && angle < PI_90 || angle >= Math.PI && angle < PI_270) {
				lineInters.sort((p1, p2) => ((p1.x <= p2.x) && (p1.y >= p2.y) ? -1 : 1));
			} else {
				lineInters.sort((p1, p2) => ((p1.x >= p2.x) && (p1.y >= p2.y) ? -1 : 1));
			}

			if (angle >= Math.PI) angle -= Math.PI;

			// Разбиваем отрезок на ребра
			for (let j = 1, v1, v2; j < lineInters.length; j++) {
				v1 = addVertex(lineInters[j - 1]);
				v2 = addVertex(lineInters[j]);

				if (v1 !== v2) {
					connections[v1].push({
						index: v2,
						angle,
						line: lines[i].name
					});
					connections[v2].push({
						index: v1,
						angle: angle + Math.PI,
						line: lines[i].name
					});
				}
			}
		}
	}

	// Сортируем ребра по углу
	for (let i = 0; i < connections.length; i++) {
		connections[i].sort((a, b) => a.angle - b.angle);
	}

	return {
		vertices,
		connections
	};
}

/**
 * Возвращает индекс элемента, идущего следующим в массиве
 *
 * @param {Array} array массив
 * @param index индекс элемента
 * @returns {Number} индекс следующего элемента
 */
function findNextItemIndex(array, index) {
	const nextIndex = array.findIndex(el => el.index === index);
	if (nextIndex === -1) {
		throw new Error(`В массиве ${array} нет элемента ${index}`);
	}
	return nextIndex === array.length - 1 ? 0 : nextIndex + 1;
}

/**
 * Находит секторы, образованные пересечениями всех линий
 *
 * @param {Array} lines линии
 * @returns {Array} секторы
 */
export default function findSectors(lines) {
	const {vertices, connections} = createGraph(lines);

	const n = vertices.length;
	const used = new Array(n);

	for (let i = 0; i < n; i++) {
		used[i] = new Array(connections[i].length);
	}

	const sectors = [];

	for (let i = 0; i < n; i++) {
		for (let j = 0; j < connections[i].length; j++) {
			if (!used[i][j]) {
				used[i][j] = true;

				let cur = connections[i][j].index;
				let prev = i;
				const sector = [prev, cur];
				let k;
				let l = 0;
				let error = false;

				while (cur !== i) {
					k = findNextItemIndex(connections[cur], prev);
					used[cur][k] = true;
					prev = cur;
					cur = connections[cur][k].index;
					sector.push(cur);

					if (++l > 100) {
						error = true;
						console.log(error);
						break;
					}
				}

				if (!error) {
					let square = 0;
					const points = [];
					const lines = [];

					for (let k = 0; k + 1 < sector.length; k++) {
						square += (vertices[sector[k]].x - vertices[sector[k + 1]].x) * (vertices[sector[k]].y + vertices[sector[k + 1]].y);
						points.push([vertices[sector[k]].x, vertices[sector[k]].y]);

						const connection = connections[sector[k]].find(item => item.index === sector[k + 1]);
						if (connection && (!lines.length || lines[lines.length - 1] !== connection.line)) {
							lines.push(connection.line);
						}
					}

					if (square / 2 > 1e-9) {
						sectors.push({
							points,
							lines
						});
					}
				}
			}
		}
	}
	return sectors;
}
