import {IGetSpaceOwnersResponse} from '@src/api/backend/spaceOwners';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

/**
 * Извлекает собственников помещения в виде массива
 */
export const extractSpaceOwnersAsArray = (data: IGetSpaceOwnersResponse) =>
	data.allIds.map((spaceOwnerId: string) => data.byId[spaceOwnerId]);

/**
 * Извлекает собственников помещения в виде объекта
 */
export const extractSpaceOwnersAsMap = (data: IGetSpaceOwnersResponse) => data.byId;

/**
 * Извлекает собственников для конкретной компании
 */
export const extractSpaceOwnersForCompany = (data: IGetSpaceOwnersResponse, companyId: string) => {
	const owners = extractSpaceOwnersAsArray(data);
	return owners.filter(owner => owner.companyId === companyId);
};

/**
 * Извлекает собственников для конкретного помещения
 */
export const extractSpaceOwnersForSpace = (data: IGetSpaceOwnersResponse, spaceId: string) => {
	const owners = extractSpaceOwnersAsArray(data);
	return owners.filter(owner => owner.spaces?.includes(spaceId));
};

/**
 * Извлекает локальных собственников для конкретного помещения
 */
export const extractLocalSpaceOwnersForSpace = (data: ISpaceOwner[], spaceId: string) =>
	data.filter(owner => owner.spaces?.includes(spaceId));

/**
 * Извлекает локальных собственников помещения в виде объекта
 */
export const extractLocalSpaceOwnersAsMap = (data: ISpaceOwner[]) =>
	data.reduce<Record<string, ISpaceOwner>>((acc, val) => {
		acc[val.id] = val;
		return acc;
	}, {});
