import {PrivateRoute} from '@src/containers/PrivateRoute';
import {SpacePage} from '@src/pages/SpacePage';
import SpacesPage from '@src/pages/SpacesPage';
import SpacesFiltersRoutingPage from '@src/pages/filters/SpacesFiltersRoutingPage';
import {spacesFilterRoutes} from './spacesFilterRoutes';
import {SubObjectsPage} from '@src/pages/ObjectsPage';
import {CheckListItems} from '@src/pages/CheckListPage/components/list';
import {SpacesPageVariants} from '@src/interfaces/SpacesPageVariants';
import {CheckListPage} from '@src/pages/CheckListPage/CheckListPage';

export const objectSpacesRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<SpacesPage />} />
	},
	{
		path: 'selection',
		element: <PrivateRoute element={<SubObjectsPage />} />
	},
	{
		path: ':spaceId',
		children: [
			{
				index: true,
				element: (
					<PrivateRoute
						element={<SpacePage spacesPageVariants={SpacesPageVariants.PROBLEMS} />}
					/>
				)
			},
			{
				path: 'work-acceptances',
				element: (
					<PrivateRoute
						element={
							<SpacePage spacesPageVariants={SpacesPageVariants.WORK_ACCEPTANCES} />
						}
					/>
				)
			}
		]
	},
	{
		path: ':spaceId/check-lists/:stage/*',
		element: <PrivateRoute element={<CheckListPage/>} />,
		children: [
			{
				path: ':listId?/:pathParam?/:itemId?',
				element: <PrivateRoute element={<CheckListItems mode="main" />} />
			}
		]
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<SpacesFiltersRoutingPage />} />,
		children: spacesFilterRoutes
	}
];
