import * as types from '../../constants/records';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IEditCheckRecordResponse, makeCheckRecordEditRequest} from '@src/api/backend/checkRecord';
import {addErrorToast} from '@src/utils/toasts';
import {AppThunkDispatch} from '@src/store/interfaces/thunk';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IEditCheckRecordPayload = IEditCheckRecordResponse;

const request = () => ({type: types.CHANGE_PERCENT_REQUEST});

const success = (response: IEditCheckRecordResponse) => (dispatch: AppThunkDispatch) => {
	dispatch({
		type: types.CHANGE_PERCENT_SUCCESS,
		payload: response
	});
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении записи по категории');
	return {
		type: types.CHANGE_PERCENT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет проценты в записи по категории
 *
 * @param objectId id объекта
 * @param recordId id записи
 * @param stage стадия
 * @param percent проценты
 */
export const changePercents = (
	objectId: string,
	recordId: string,
	stage: ObjectStageIds,
	percent: number | undefined
) =>
	createApiAction<IEditCheckRecordResponse>(request, success, failure, () =>
		makeCheckRecordEditRequest(objectId, recordId, stage, {percent}));
