import {queryClient} from '@src/api/QueryClient';
import {ICachedSpaceOwner} from '../useEditSpaceOwner';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {toSpaceOwner} from './convertToLocalSave';
import {fieldFill} from './fieldFill';

export const updateCachedOwner = (spaceOwnerId: string, fields: ISavingSpaceOwner) =>
	// получаем данные локального собственника и добавляем данные из fields
	queryClient.setQueryData<ICachedSpaceOwner>(spaceOwnersQueryKeys.detail(spaceOwnerId), data => {
		if (!data) {
			return undefined;
		}
		return {
			...data,
			...toSpaceOwner({...data.savingData, ...fields}, data.companyId, data.key),
			savingData: {
				...data.savingData,
				id: data.key,
				name: fields.name || data.savingData.name,
				email: fieldFill(fields.email, data.savingData.email),
				additionalEmail: fieldFill(fields.additionalEmail, data.savingData.additionalEmail),
				phone: fieldFill(fields.phone, data.savingData.phone),
				additionalPhone: fieldFill(fields.additionalPhone, data.savingData.additionalPhone),
				comment: fieldFill(fields.comment, data.savingData.comment)
			}
		};
	});
