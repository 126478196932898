import {memo, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useCompanyInspectors} from '@src/core/hooks/queries/companies/hooks';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

interface ICreatedByFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	onChange: (changes: IChanges) => void;
}

export const CreatedByFilterMobile = memo(
	({objectId = 'all', value, entity, onChange, label}: ICreatedByFilterMobileProps) => {
		const {t} = useTranslation();

		const [selected, setSelected] = useState(value);
		const [search, setSearch] = useState('');
		const {data: usersMap} = useUsersAsMap();
		const {data: users} = useCompanyInspectors(usersMap, objectId);
		const elements = useMemo(
			() => users?.map(user => ({id: user.id, name: user.fullName})),
			[users]
		);
		const filteredElements = useMemo(
			() => (elements ? flatFilter(elements, 'name', search) : []),
			[search, elements]
		);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, 'createdBy');

		const handleChange = (v: string[] | undefined) => {
			onChange({createdBy: v});
		};

		return (
			<MobileFilter
				label={label ?? t('entitiesFilters.CreatedByFilter.label')}
				elements={filteredElements}
				selected={selected}
				linkBtnLabel={t('problemsFiltersPage.allBtn.label')}
				onChange={handleChange}
				onExpand={handleExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
			/>
		);
	}
);
