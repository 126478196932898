import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {findPermission, hasPermission} from '@tehzor/tools/utils/findPermission';
import {useMemo} from 'react';

export interface IPermissions {
	canAddCheck?: boolean;
	canAddCheckFromProblems?: boolean;
	canAddOwnerAcceptance?: boolean;
	canAddOwnerAcceptanceFromProblems?: boolean;
	canAddInternalAcceptance?: boolean;
	canAddInternalAcceptanceFromProblems?: boolean;
	canAddWarrantyClaim?: boolean;
	canAddWarrantyClaimFromProblems?: boolean;
	canAddProblem?: boolean;
	canExport?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param user текущий пользователь
 * @param objectId id объекта
 */
const getPermissions = (user?: IUser, objectId?: string): IPermissions => {
	if (!user || !user.roles) {
		return {};
	}

	const checksAddPermission = findPermission(
		user.roles,
		'checksAdd',
		UserRoleScopes.OBJECT,
		objectId
	);
	const ownerAcceptancesAddPermission = findPermission(
		user.roles,
		'owner-acceptances-add',
		UserRoleScopes.OBJECT,
		objectId
	);
	const internalAcceptancesAddPermission = findPermission(
		user.roles,
		'internalAcceptancesAdd',
		UserRoleScopes.OBJECT,
		objectId
	);
	const warrantyClaimsAddPermission = findPermission(
		user.roles,
		'warranty-claims-add',
		UserRoleScopes.OBJECT,
		objectId
	);

	return objectId
		? {
			canAddCheck: checksAddPermission !== undefined,
			canAddCheckFromProblems: checksAddPermission !== undefined
				&& !(
					checksAddPermission.restrictions
					&& checksAddPermission.restrictions['checks-add-from-problems']
				),
			canAddOwnerAcceptance: ownerAcceptancesAddPermission !== undefined,
			canAddOwnerAcceptanceFromProblems: ownerAcceptancesAddPermission !== undefined
			&& !(
				ownerAcceptancesAddPermission.restrictions
				&& ownerAcceptancesAddPermission.restrictions['owner-acceptances-add-from-problems']
			),
			canAddInternalAcceptance: internalAcceptancesAddPermission !== undefined,
			canAddInternalAcceptanceFromProblems: internalAcceptancesAddPermission !== undefined
			&& !(
				internalAcceptancesAddPermission.restrictions
				&& internalAcceptancesAddPermission.restrictions['internal-acceptances-add-from-problems']
			),
			canAddWarrantyClaim: warrantyClaimsAddPermission !== undefined,
			canAddWarrantyClaimFromProblems: warrantyClaimsAddPermission !== undefined
			&& !(
				warrantyClaimsAddPermission.restrictions
				&& warrantyClaimsAddPermission.restrictions['warranty-claims-add-from-problems']
			),
			canAddProblem: hasPermission(
				user.roles,
				'problems-add',
				UserRoleScopes.OBJECT,
				objectId
			),
			canExport: hasPermission(
				user.roles,
				'problemsExport',
				UserRoleScopes.OBJECT,
				objectId
			)
		}
		: {
			canAddCheck: false,
			canAddProblem: false,
			canExport: hasPermission(user.roles, 'problemsExport')
		};
};

/**
 * Хук для возврата кешированных полномочий текущего пользователя
 *
 * @param user текущий пользователь
 * @param objectId id объекта
 */
export const usePermissions = (user?: IUser, objectId?: string): IPermissions =>
	useMemo(() => getPermissions(user, objectId), [user, objectId]);
