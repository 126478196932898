import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton} from '@tehzor/ui-components';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
// TZ-2070 Разкомментить после исправления фильтров по стадии объектов
// import {StagesFilterMobile} from '@src/components/MobileEntitiesFilters/components/StagesFilterMobile';
import {CitiesFilterMobile} from '@src/components/MobileEntitiesFilters/components/CitiesFilterMobile';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IObjectsFiltersState} from '@src/store/modules/settings/pages/objects/reducers';
import {MainCompaniesFilterMobile} from '@src/components/MobileEntitiesFilters';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';

const ObjectsFiltersPage = () => {
	const {t} = useTranslation();
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};
	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IObjectsFiltersState>();
	const navigate = useNavigate();

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			navigate(-1);
		}
	}, [canGoBack, apply]);

	useAppHeader(
		{
			title: t('filtersPage.title'),
			showBackBtn: true,
			mobileRightButtons: isCleanable<IObjectsFiltersState & {objects?: []}>(state) ? (
				<LinkButton
					label={t('filterPage.resetFiltersBtn.label')}
					onClick={mobileClear}
				/>
			) : null
		},
		[state]
	);

	useRouting(!canGoBack, 'all', 'objects');

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			<MainCompaniesFilterMobile
				value={state?.companies}
				onChange={change}
				entity="objects"
				label={t('entitiesFilters.companiesFilter.label')}
			/>

			<CitiesFilterMobile
				value={state?.cities}
				onChange={change}
				entity="objects"
			/>

			{/* // TZ-2070 Разкомментить после исправления фильтров по стадии объектов
			<StagesFilterMobile
				value={state?.stages}
				onChange={change}
				entity="objects"
			/> */}
		</TranslatedFilterPage>
	);
};

export default ObjectsFiltersPage;
