import {CellProps} from 'react-table';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';
import {useTranslation} from 'react-i18next';

const LocationCell = ({row}: CellProps<IInspectionEntity>) => {
	const {t} = useTranslation();
	const {plan, floor, location} = row.original.data;

	return (
		<>
			{!!plan && (
				<div>
					<span className="inspections-page__d-table-location-title">
						{t('locationCell.plan')}:{' '}
					</span>
					{plan}
				</div>
			)}
			{!!floor && (
				<div>
					<span className="inspections-page__d-table-location-title">
						{t('locationCell.floor')}:{' '}
					</span>
					{floor}
				</div>
			)}
			{!!location && (
				<div>
					<span className="inspections-page__d-table-location-title">
						{t('locationCell.location')}:{' '}
					</span>
					{location}
				</div>
			)}
		</>
	);
};

export default LocationCell;
