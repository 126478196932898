import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import { memo } from 'react';
import SelectedEntitiesDeletion from './SelectedEntitiesDeletion';
import {usePermissions} from '@src/pages/ProblemPage/hooks/usePermissions';

interface ISelectionsActionsProps {
	objectId: string;
	selectedEntities: IProblem[];
}

const SelectionActions = ({objectId, selectedEntities}: ISelectionsActionsProps) => {
	const permissions = usePermissions(objectId);

	return (
		<div>
			{permissions.canDelete && (
				<SelectedEntitiesDeletion selectedEntities={selectedEntities} />
			)}
		</div>
	);
};

export default memo(SelectionActions);
