import { memo, useCallback } from 'react';
import {Button, IconButton} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	clearFilters,
	changeFilters,
	changeFiltersDialogTabIndex
} from '@src/store/modules/settings/pages/statistics/building/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {isBuildingFiltersSetUp} from '@src/store/modules/settings/pages/statistics/building/selectors/filters';
import StatsFiltersDialog from '../../../../components/StatsFiltersDialog';
import useToggle from 'react-use/lib/useToggle';
import {useTranslation} from 'react-i18next';


const BuildingControls = () => {
	const {t} = useTranslation();

	const {filtersDialogTabIndex, filters} = useAppSelector(
		s => s.settings.pages.statistics.building
	);
	const isFiltersSetUp = useAppSelector(isBuildingFiltersSetUp);
	const dispatch = useAppDispatch();

	const [isFiltersOpen, toggleFiltersOpen] = useToggle(false);

	const handleFiltersReset = useCallback(() => {
		dispatch(clearFilters());
	}, []);

	return (
		<div className="statistics-page__controls">
			<div className="statistics-page__controls-left">
				<IconButton
					className="statistics-page__filters-btn"
					type="accent-blue"
					onClick={toggleFiltersOpen}
				>
					<i className="tz-filters-24 statistics-page__filters-btn-icon"/>
				</IconButton>

				{isFiltersSetUp && (
					<Button
						className="statistics-page__reset-btn"
						type="cancel"
						label={t('component.statsFilters.clearFilters')}
						onClick={handleFiltersReset}
					/>
				)}
			</div>

			<StatsFiltersDialog
				filters={filters}
				filtersDialogTabIndex={filtersDialogTabIndex}
				isOpen={isFiltersOpen}
				changeFilters={changeFilters}
				changeFiltersDialogTabIndex={changeFiltersDialogTabIndex}
				onRequestClose={toggleFiltersOpen}
			/>
		</div>
	);
};

export default memo(BuildingControls);
