import getEmailsArray from '@src/utils/getEmailsArray';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import getTimezone from '@tehzor/tools/utils/getTimezone';

/**
 * Отправляет запрос на экспорт проверки
 */
export const makeCheckExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	checkId: string,
	problems?: string[],
	inspections?: string[]
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId,
		problems,
		inspections
	};
	try {
		const response = await httpRequests.exportWithToken.post<IExportResponse>(
			`checks/${checkId}/export`,
			params
		);
		return response.data;
	} catch (error) {
		console.error('export error:', error);
		throw error;
	}
};
