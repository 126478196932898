export type IObjectsQueryKey = [] & {
	0: string;
	1: string;
	2: string[];
};

export const objectsQueryKeys = {
	all: () => ['objects'],
	one: () => ['object'],
	list: () => [...objectsQueryKeys.all(), 'list'],
	stats: () => [...objectsQueryKeys.all(), 'stats'],
	add: () => [...objectsQueryKeys.one(), 'add'],
	delete: () => [...objectsQueryKeys.one(), 'delete'],
	edit: () => [...objectsQueryKeys.one(), 'edit'],
};
