import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {WorkAcceptanceResult} from '@src/pages/WorkAcceptancePage/components/WorkAcceptanceResult/WorkAcceptanceResult';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';

interface IMobileInfoProps {
	openDialog: () => void;
	unitString?: string;
	unitPlanString?: string;
	workAcceptance: IWorkAcceptance;
	permissions?: IPermissions;
}

export const MobileInfo = ({
	openDialog,
	unitString,
	unitPlanString,
	workAcceptance,
	permissions
}: IMobileInfoProps) => (
	<div className="work-acceptance-page__cards-container">
		<WorkAcceptanceResult
			openDialog={openDialog}
			workAcceptance={workAcceptance}
			unitString={unitString}
			unitPlanString={unitPlanString}
			permissions={permissions}
		/>
	</div>
);
