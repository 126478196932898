import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {TFunction} from 'i18next';

export const createMarkerCommentDifference = (
	prev:
		| {
				name: string | undefined;
				description: string | undefined;
		  }
		| undefined,
	next:
		| {
				name: string | undefined;
				description: string | undefined;
		  }
		| undefined,
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev,
	next,
	type: HistoryTypeId.MARKER_COMMENT,
	name: t('createDifference.createMarkerCommentDifference')
});
