import * as React from 'react';
import {useCallback, useState} from 'react';
import Calendar, {IDatesOptions} from '../Calendar';
import {ru} from 'date-fns/locale';
import SelectableDay from './component/SelectableDay';
import TimeSelect from '../TimeSelect';
import './InlineDatePicker.less';

interface IDatePickerProps {
	className?: string;
	style?: React.CSSProperties;
	datesOptions?: IDatesOptions;
	value?: Date;
	showTimeSelect?: boolean;
	label?: string;
	onChange?: (value: Date) => void;
	disabledDate?: (value: Date) => boolean;
}

const InlineDatePicker = (props: IDatePickerProps) => {
	const {
		className,
		style,
		datesOptions = {locale: ru},
		value,
		label = 'Время:',
		showTimeSelect,
		onChange,
		disabledDate
	} = props;

	const [selectedDate, setSelectedDate] = useState(value);

	const handleDayClick = useCallback(
		(v: Date) => {
			setSelectedDate(v);

			if (onChange) {
				onChange(v);
			}
		},
		[onChange]
	);

	return (
		<>
			<div className="inline-date-picker__calendar">
				<Calendar
					className={className}
					style={style}
					initialDate={value}
					datesOptions={datesOptions}
					dayComponent={(
						<SelectableDay
							value={value}
							onClick={handleDayClick}
							disabledDate={disabledDate}
						/>
					)}
				/>
			</div>
			{showTimeSelect && (
				<div className="inline-date-picker__time-select">
					<div className="inline-date-picker__time-select-label">{label}</div>
					<TimeSelect
						value={selectedDate}
						onChangeTime={handleDayClick}
					/>
				</div>
			)}
		</>
	);
};

export default InlineDatePicker;
