/* eslint-disable no-undef */
import {Dispatch, memo, useCallback, useMemo, useState} from 'react';
import {EntitiesTable} from '@tehzor/ui-components';
import {desktopColumns} from './columns.desktop';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {convertPerformers} from '../../utils/convertPerformers';
import {useEditableResponsibilityRulesDialog} from '@src/components/EditableResponsibilityRulesDialog/hooks/useEditableResponsibilityRulesDialog';
import {IPreparedPerformer} from '../../interfaces/IPreparedPerformer';
import {useUsersAsArray} from '@src/core/hooks/queries/users/hooks';
import {useResponsibilityRulesForWorkingGroupAsArray} from '@src/core/hooks/queries/responsibilityRules/hooks';

interface ITableProps {
	editingState: IEditableWorkingGroupState;
	editingDispatch: Dispatch<IEditableWorkingGroupAction>;
	workingGroupId?: string;
	forbiddenEditing?: boolean;
}

export const Table = memo(
	({editingState, editingDispatch, workingGroupId, forbiddenEditing}: ITableProps) => {
		const [editDialog, openEditDialog] = useEditableResponsibilityRulesDialog(
			editingState,
			workingGroupId
		);
		const {data: users} = useUsersAsArray();
		const {data: responsibilityRules} =
			useResponsibilityRulesForWorkingGroupAsArray(workingGroupId);
		const preparedData = useMemo(
			() => convertPerformers(editingState.performers, users, responsibilityRules),
			[editingState, users, responsibilityRules]
		);
		const [selectedRows, setSelectedRows] = useState<string[]>([]);

		const handleRowClick = useCallback(
			(item: IPreparedPerformer) => {
				openEditDialog({userId: item.id, groupId: undefined});
			},
			[openEditDialog]
		);
		return (
			<>
				<MenuActionCtxProvider
					editingState={editingState}
					editingDispatch={editingDispatch}
					workingGroupId={workingGroupId}
				>
					<EntitiesTable
						columns={desktopColumns}
						data={preparedData}
						selectedRows={selectedRows}
						selectable
						onRowClick={
							workingGroupId && !forbiddenEditing ? handleRowClick : undefined
						}
						onSelectedRowsChange={setSelectedRows}
					/>
				</MenuActionCtxProvider>
				{editDialog}
			</>
		);
	}
);
