import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditInspectionPerformersResponse = ILinkedInspection;

/**
 * Изменяет ответственных осмотра
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 * @param performers пользователи
 * @param performersActiveGroup текущая группа
 */
export const makeInspectionPerformersEditRequest = async (
	objectId: string,
	inspectionId: string,
	performers?: string[],
	performersActiveGroup?: string
) => {
	const response = await httpRequests.withToken.patch<IEditInspectionPerformersResponse>(
		`inspections/edit-performers/${inspectionId}`,
		{
			objectId,
			performers,
			performersActiveGroup
		}
	);

	return response.data;
};
