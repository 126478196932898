import {memo} from 'react';
import {EntityGridItem, InlineButton} from '@tehzor/ui-components';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {DOCUMENTS_COUNT} from '@src/components/EntityDocs/components/EntityDocsItems';
import {useTasksDialog} from '@src/components/TasksDialog/hooks/useTasksDialog';
import {EntityTasksItems} from '@src/components/EntityTasks/components/EntityTasksItems';
import './EntityTasks.less';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {useTranslation} from 'react-i18next';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import classNames from 'classnames';

interface EntityDesktopTasksProps {
	tasks?: ITask[];
	openAddingTaskDialog: () => void;
	className?:
		| string
		| {
				root?: string;
				title?: string;
				subTitle?: string;
				icon?: string;
		  };
}

const plusIcon = <i className="tz-plus-20" />;
const taskIcon = <i className="tz-task-16" />;

const entityGridItemCls = {
	header: 'entity-tasks__header-entity-grid-item-header'
};

export const EntityMobileTasks = memo((props: EntityDesktopTasksProps) => {
	const {tasks, openAddingTaskDialog, className} = props;
	const {t} = useTranslation();
	const {canAdd} = useTasksPermissions();
	const totalCount = tasks?.length || 0;
	const showButton = totalCount > DOCUMENTS_COUNT;
	const classes = convertClassNames(className);

	const {tasksDialog, openTasksDialog} = useTasksDialog(tasks);

	return (
		<EntityGridItem
			label={t('components.entityTasks.header')}
			icon={taskIcon}
			fullRow
			className={entityGridItemCls}
			buttons={
				canAdd && (
					<InlineButton
						type="accent"
						leftIcon={plusIcon}
						onClick={openAddingTaskDialog}
						className={classNames('entity-tasks__header-add-button', classes.icon)}
					/>
				)
			}
		>
			<EntityTasksItems tasks={tasks} />

			{showButton && (
				<div className="entity-tasks__more-button">
					<InlineButton
						type="accent"
						label={`${t('components.entityTasks.button.showAll')} (${totalCount})`}
						onClick={openTasksDialog}
					/>
				</div>
			)}

			{tasksDialog}
		</EntityGridItem>
	);
});
