import {requestProblemTags} from '@src/api/backend/problemTags';
import {useQueryClient} from '@tanstack/react-query';
import {problemTagsQueryKeys} from '../keys';

export const useProblemTagsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(problemTagsQueryKeys.list(), {
		networkMode: 'offlineFirst',
		queryFn: requestProblemTags,
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
