import {mutations as mutationFns} from '@src/api/mutations';
import {ISavingInspectionQuery} from '@src/core/hooks/mutations/inspection/useAddInspection';
import {ISavingProblemQuery} from '@src/core/hooks/mutations/problem/useAddProblem';
import {checkPropertyValue} from '@src/utils/checkPropertyValue';
import {Query, useQueryClient} from '@tanstack/react-query';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useCallback} from 'react';
import {inspectionsQueryKeys} from '../inspections/keys';
import {problemsQueryKeys} from '../problems/keys';

export const useAddChildren = () => {
	const mutations = mutationFns();
	const queryClient = useQueryClient();

	const addProblems = useCallback(
		(
			linkCacheValue: string,
			newLinkId: string,
			linkKey: keyof NonNullable<IProblem['links']>
		) => {
			const savingProblems = queryClient
				.setQueriesData<ISavingProblemQuery>(
					{
						queryKey: problemsQueryKeys.savingDatas(),
						predicate: (query: Query<ISavingProblemQuery>) => {
							const data = query.state.data;
							if (
								data &&
								data.links &&
								checkPropertyValue(data.links, linkCacheValue)
							) {
								return true;
							}
							return false;
						}
					},
					problemData => {
						if (problemData) {
							return {
								...problemData,
								links: {...problemData.links, [linkKey]: newLinkId}
							};
						}
						return problemData;
					}
				)
				.filter(([, i]) => !!i)
				.map(([, data]) => data) as ISavingProblemQuery[];
			if (savingProblems.length) {
				savingProblems.forEach(query => {
					mutations.problem.mutate({
						objectId: query.object.id,
						links: query.links,
						stage: query.stage,
						fields: query.fields,
						key: query.key
					});
				});
			}
		},
		[queryClient]
	);

	const addInspections = useCallback(
		(
			linkCacheValue: string,
			newLinkId: string,
			linkKey: keyof NonNullable<IInspection['links']>
		) => {
			const savingInspections = queryClient
				.setQueriesData<ISavingInspectionQuery>(
					{
						queryKey: inspectionsQueryKeys.savingDatas(),
						predicate: (query: Query<ISavingInspectionQuery>) => {
							const data = query.state.data;
							if (
								data &&
								data.links &&
								checkPropertyValue(data.links, linkCacheValue)
							) {
								return true;
							}
							return false;
						}
					},
					inspectionData => {
						if (inspectionData) {
							return {
								...inspectionData,
								links: {...inspectionData.links, [linkKey]: newLinkId}
							};
						}
						return inspectionData;
					}
				)
				.filter(([, i]) => !!i)
				.map(([, data]) => data) as ISavingInspectionQuery[];
			if (savingInspections.length) {
				savingInspections.forEach(query => {
					mutations.inspection.mutate({
						objectId: query.object.id,
						links: query.links,
						stage: query.stage,
						fields: query.fields,
						key: query.key
					});
				});
			}
		},
		[queryClient]
	);

	return [addProblems, addInspections];
};
