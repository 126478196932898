import { useCallback, useState } from 'react';
import * as React from 'react';
import './ExportToast.less';
import {CircleLoader, LinkButton} from '@tehzor/ui-components';
import {IDocument} from '@tehzor/types-and-subjects/ts/documents';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formDocumentLink} from '@tehzor/tools/utils/links';
import {downloadExportedFile} from './utils/downloadExportedFile';

interface IExportToastProps {
	title?: string;
	description?: string;
	documentRecord?: IDocument;
	url: string;
	fileName: string;
	icon?: React.ReactNode;
	onClose: () => void;
}

const successIcon = <i className="tz-mark-24 export-toast__icon export-toast__success-icon" />;

export const ExportToast = ({
	title,
	url,
	fileName,
	icon,
	description,
	documentRecord,
	onClose
}: IExportToastProps) => {
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [isDownloading, setIsDownloading] = useState(false);
	const {pushPath} = useChangePath();

	const handleDocument = useCallback(() => {
		if (documentRecord) {
			pushPath(formDocumentLink(documentRecord.id));
			onClose();
		}
	}, [documentRecord, onClose]);

	const handleDownload = useCallback(async () => {
		await downloadExportedFile(url, fileName, setIsDownloading, setDownloadProgress);
	}, [fileName, url]);

	return (
		<div className="export-toast__content">
			{downloadProgress === 100 ? (
				successIcon
			) : icon ? (
				<div className="export-toast__icon">{icon}</div>
			) : null}
			<div className="export-toast__text">
				<div className="export-toast__title">
					{downloadProgress === 100 ? 'Скачивание завершено' : title}
				</div>
				<div className="export-toast__description">{description}</div>
				{isDownloading ? (
					<CircleLoader percent={downloadProgress} />
				) : (
					downloadProgress !== 100 && (
						<div className="export-toast__links">
							{documentRecord ? (
								<LinkButton
									onClick={handleDocument}
									label="Перейти в документ"
								/>
							) : null}

							<LinkButton
								onClick={handleDownload}
								label="Скачать"
							/>
						</div>
					)
				)}
			</div>
		</div>
	);
};
