import { memo } from 'react';
import {IConvertedProblemsData} from '@tehzor/tools/interfaces/spaces/IConvertedProblemsData';
import {IConvertedCheckListData} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';

interface IStatsBorderProps {
	item: IConvertedProblemsData | IConvertedCheckListData;
	width: number;
	height: number;
	radius: number;
	color?: string;
}

export const StatsBorderCircle = memo(({item, width, height, radius, color}: IStatsBorderProps) => {
	const totalLength = Math.PI * 2 * radius;
	const strokeLength = totalLength / 100 * (item.percent ?? 100);
	const strokeDashoffset = totalLength / 100 * ((item.startPoint ?? 0) + 25);

return (
	<circle
		key={item.key}
		cx={width - 10 - radius}
		cy={height - 10 - radius}
		r={radius}
		fill="transparent"
		stroke={color ?? item.color}
		strokeWidth="3"
		strokeDasharray={`${strokeLength} ${totalLength - strokeLength}`}
		strokeDashoffset={strokeDashoffset}
	/>
	);
});
