import {useMemo} from 'react';
import {formSpaceLocationForEntities} from '@src/utils/formSpaceLocationForEntities';
import {transformLocations} from '@src/pages/CheckListPage/utils/transformLocations';
import {useShape} from '../queries/plans/hooks';
import {useSpace} from '../queries/space';

/**
 * Берёт из помещения местоположение и этаж и формирует исходные данные для создания нарушений и осмотров
 *
 * @param spaceId id помещения, чтобы понять, нужное ли помещение находится в store
 */
export function useSpaceLocationForEntities(objectId: string, spaceId?: string) {
	const {data: space} = useSpace(spaceId, objectId);
	const [planId, sectorId] = transformLocations(space?.locations);
	const {data: sector} = useShape(objectId, planId, sectorId);
	return useMemo(() => {
		if (space && space.id === spaceId) {
			return formSpaceLocationForEntities(space, sector);
		}
		return undefined;
	}, [space, spaceId, sector]);
}
