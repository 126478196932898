import { useCallback } from 'react';
import * as React from 'react';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {useSoftDeleteCheckItem} from '@src/core/hooks/mutations/checkItems/useSoftDeleteCheckItem';
import {useHardDeleteCheckItem} from '@src/core/hooks/mutations/checkItems/useHardDeleteCheckItem';
import {useRestoreCheckItem} from '@src/core/hooks/mutations/checkItems/useRestoreCheckItem';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';

export const useItemArchivationHadler = (): [
	React.ReactNode,
	React.ReactNode,
	React.ReactNode,
	(item: ISavingCheckItem) => Promise<void>,
	(item: ISavingCheckItem) => Promise<void>,
	(item: ISavingCheckItem) => Promise<void>
] => {
	const softDeleteItem = useSoftDeleteCheckItem();
	const hardDeleteItem = useHardDeleteCheckItem();
	const restoreItem = useRestoreCheckItem();

	const [softDeleteDialog, getSoftDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить категорию?',
		'Вместе с ней будут удалены все дочерние категории и зависимости других категорий',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const [hardDeleteDialog, getHardDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить категорию?',
		'Категория и все дочерние категории будут безвозвратно удалены, все связи с другими сущностями (нарушения, проверки и тд) будут также удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const [restoreDialog, getRestoreConfirmation] = useConfirmDialog(
		'Вы действительно хотите восстановить категорию?',
		'Категория и все дочерние категории будут восстановлены',
		{acceptBtnProps: {type: 'accent-blue'}}
	);
	const handleSoftDelete = useCallback(
		async (item: ISavingCheckItem) => {
			if (!item.deleted && (await getSoftDeleteConfirmation())) {
				softDeleteItem({checkListId: item.checkListId, id: item.id});
			}
		},
		[getSoftDeleteConfirmation, softDeleteItem]
	);

	const handleHardDelete = useCallback(
		async (item: ISavingCheckItem) => {
			if (item.deleted && (await getHardDeleteConfirmation())) {
				hardDeleteItem({checkListId: item.checkListId, id: item.id});
			}
		},
		[getHardDeleteConfirmation, hardDeleteItem]
	);

	const handleRestore = useCallback(
		async (item: ISavingCheckItem) => {
			if (item.deleted && (await getRestoreConfirmation())) {
				restoreItem({checkListId: item.checkListId, id: item.id});
			}
		},
		[getRestoreConfirmation, restoreItem]
	);
	return [
		softDeleteDialog,
		hardDeleteDialog,
		restoreDialog,
		handleSoftDelete,
		handleHardDelete,
		handleRestore
	];
};
