import {
	IEditableCategoriesSetAction,
	IEditableCategoriesSetState,
	IEditableCategory
} from '@src/core/hooks/states/useCategoriesSetState';
import {ActionButtons, Button, Dialog, TextField} from '@tehzor/ui-components';
import { Dispatch, useCallback, useState } from 'react';
import * as React from 'react';

export function useEditableCategoriesDialog(
	editingState: IEditableCategoriesSetState,
	editingDispatch: Dispatch<IEditableCategoriesSetAction>
): [React.ReactNode, (item?: IEditableCategory) => void] {
	const [isOpen, setOpen] = useState<boolean>(false);
	const [editedItem, setEditedItem] = useState<IEditableCategory>();
	const open = useCallback((item: IEditableCategory) => {
		setOpen(true);
		setEditedItem(item);
	}, []);

	const confirm = useCallback(() => {
		const i = editingState.categories.findIndex(category => category.id === editedItem?.id);
		const changedCategory = editingState.categories[i];
		editingDispatch({
			type: 'update-array-item',
			field: 'categories',
			value: {...changedCategory, ...editedItem},
			index: i
		});
		setOpen(false);
	}, [editedItem, editingDispatch, editingState.categories]);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const handleChangeName = useCallback((value: string) => {
		setEditedItem(prevState => {
			if (!prevState) return prevState;
			return {
				...prevState,
				name: value
			};
		});
	}, []);

	const handleChangeExternalId = useCallback((value: string) => {
		setEditedItem(prevState => {
			if (!prevState) return prevState;
			return {
				...prevState,
				externalId: value
			};
		});
	}, []);

	const dialog = (
		<Dialog
			className="editable-categories__dialog"
			isOpen={isOpen}
			onRequestClose={close}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={close}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={confirm}
					/>
				</ActionButtons>
			}
		>
			<div className="editable-categories__dialog-title">Редактирование вида работ</div>
			<div className="editable-categories__dialog-field-wrapper">
				<div className="editable-categories__dialog-field-label">Название</div>
				<TextField
					className="editable-categories__dialog-field"
					value={editedItem?.name}
					onChange={handleChangeName}
					elementType="input"
				/>
			</div>
			<div className="editable-categories__dialog-field-wrapper">
				<div className="editable-categories__dialog-field-label">Внешний id</div>
				<TextField
					className="editable-categories__dialog-field"
					value={editedItem?.externalId}
					onChange={handleChangeExternalId}
					elementType="input"
				/>
			</div>
		</Dialog>
	);

	return [dialog, open];
}
