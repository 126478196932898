import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import './ShadowBox.less';
import classNames from 'classnames';

interface IShadowBoxProps {
	children?: React.ReactNode;
	className?: string;
}

const ShadowBox = (props: IShadowBoxProps) => {
	const {children, className} = props;
	const [shadow, setShadow] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current && ref.current.scrollHeight > ref.current.clientHeight) {
			setShadow(true);
		} else {
			setShadow(false);
		}
	});

	return (
		<div
			ref={ref}
			className={classNames(className, 'shadowbox', {shadowbox_active: shadow})}
		>
			{children}
		</div>
	);
};
ShadowBox.displayName = 'ShadowBox';

export default ShadowBox;

// import React, {useCallback, useEffect, useRef, useState} from 'react';
// import './ShadowBox.less';
// import classNames from 'classnames';

// interface IShadowBoxProps {
// 	children?: React.ReactNode;
// 	className?: string;
// }

// const ShadowBox = (props: IShadowBoxProps) => {
// 	const {children, className} = props;
// 	const [isScrollable, setScrollable] = useState(false);

// 	const observer = useRef();
// 	const getSize = (elements: React.ReactNode)=> {
// 		let el;
// 		if (elements) {
// 			el = elements[0].target;
// 		}
// 		if (!el) return;
// 		setScrollable(el.scrollHeight > el.clientHeight);
// 	};

// 	const target = useCallback(element => {
// 		if (element) {
// 			observer.current = new ResizeObserver(getSize).observe(element);
// 		}
// 	}, []);

// 	useEffect(
// 		() => () => {
// 			if (observer) {
// 					observer?.current?.disconnect();
// 			}
// 		},
// 		[]
// 	);

// 	return (
// 		<div ref={target} className={classNames('shadowbox', className, {shadowbox_active: isScrollable})}>
// 			{children}
// 		</div>
// 	);
// };
// ShadowBox.displayName = 'ShadowBox';

// export default ShadowBox;
