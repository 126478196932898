import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ISpaceStatusesSetsFiltersState} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/reducers';
import {useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

export const SpaceStatusesSetsSearch = () => {
	const {state, dispatch} = useEntitiesFiltersCtx<ISpaceStatusesSetsFiltersState>();

	const [search, setSearch] = useState(state?.search);

	const handleClear = useCallback(() => {
		dispatch({search: undefined});
	}, []);

	const handleSearch = useCallback(() => {
		dispatch({search});
	}, [search]);

	useUpdateEffect(() => {
		setSearch(state.search);
	}, [state.search]);

	return (
		<TranslatedSelectSearch
			className="space-statuses-sets__filters-search"
			value={search}
			type="filter"
			onChange={setSearch}
			onClear={handleClear}
			onSearch={handleSearch}
		/>
	);
};
