import './ChecksPage.less';
import {LoadingPanel, TabContent, Tabs} from '@tehzor/ui-components';
import classNames from 'classnames';
import ChecksTable from './components/table/ChecksTable';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import ObjectPageBreadcrumbs from './components/ObjectPageBreadcrumbs';
import AllPageBreadcrumbs from './components/AllPageBreadcrumbs';
import ActionsDesktop from './components/actions/Actions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import MobileEntityAdding from './components/actions/EntityAdding.mobile';
import {useParams} from 'react-router-dom';
import {useChecksQuery} from '@src/core/hooks/queries/checks/hooks';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';

interface IChecksPageProps {
	className?: string;
}

const ChecksPage = ({className}: IChecksPageProps) => {
	useScrollRestoration();
	const {objectId} = useParams<{objectId?: string}>();
	const {state} = useEntitiesFiltersCtx();
	const {isLoading} = useChecksQuery(objectId || 'all', state);
	const isDesktop = useIsDesktop();
	const actions = <ActionsDesktop objectId={objectId} />;

	return (
		<LoadingPanel
			className="checks-page__loading-panel"
			active={isLoading}
		>
			<div
				className={classNames(
					'page-cont',
					'checks-page',
					{'checks-page_small-margin': !objectId},
					className
				)}
			>
				{objectId !== undefined ? (
					<ObjectPageBreadcrumbs objectId={objectId}>
						{isDesktop && actions}
					</ObjectPageBreadcrumbs>
				) : (
					isDesktop && <AllPageBreadcrumbs>{actions}</AllPageBreadcrumbs>
				)}

				<Tabs className={{links: 'checks-page__tabs-links'}}>
					<TabContent>
						<ChecksTable objectId={objectId} />
					</TabContent>
				</Tabs>
				{objectId && !isDesktop && <MobileEntityAdding objectId={objectId} />}
			</div>
		</LoadingPanel>
	);
};

export default ChecksPage;
