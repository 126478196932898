import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ReactElement, useCallback, useState} from 'react';
import {addRecord} from '@src/store/modules/pages/checkLists/actions/records/add';
import {checkChangedRecords} from '@src/store/modules/pages/checkLists/actions/records/checkChangedRecords';
import {extractCheckRecordByItemId} from '@src/store/modules/pages/checkLists/selectors/records';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {useStructureSelectDialog} from '../components/StructureSelectDialog/useStructureSelectDialog';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useSpaceSelectDialog} from '../components/SpaceSelectDialog/useSpaceSelectDialog';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {checkListsActions} from '@src/store/modules/settings/pages/checkLists/slice';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {LastEntityAddedFromCheckList} from '@src/interfaces/LastEntityAddedFromCheckList';

export const useAddingWorkAcceptanceCheckListProblemDialog = (
	listId: string | undefined,
	itemId: string | undefined
): [ReactElement, () => void] => {
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId?: string;
	}>();

	const {data: workAcceptance} = useWorkAcceptance(workAcceptanceId, objectId);
	const {data: checkList} = useExtractCheckListById(listId);
	const {data: checkItem} = useExtractCheckItemById(itemId);
	const checkRecord = useAppSelector(s => extractCheckRecordByItemId(s, itemId));
	const dispatch = useAppDispatch();
	const {changeLastAddedEntity} = checkListsActions;
	const [links, setLinks] = useState<IProblem['links'] | IInspection['links']>({
		workAcceptanceId,
		checkItemId: itemId,
		checkListId: listId
	});

	const addCheckRecord = useCallback(() => {
		if (!checkRecord.id) {
			if (checkItem && checkList) {
				void dispatch(
					addRecord(
						objectId,
						{
							workAcceptanceId,
							checkItemId: checkItem.id,
							checkListId: checkItem.checkListId
						},
						checkList.stage,
						{status: CheckRecordStatusId.NOT_CHECKED}
					)
				);
			}
		}
	}, [checkItem, checkRecord, workAcceptanceId, objectId]);

	const [addingCheckDialog, openCheckDialog] = useAddingCheckDialog({
		objectId: objectId || '',
		links: {
			...links,
			checkRecordId: checkRecord?.id
		},
		stage: checkList?.stage,
		types: ['problem'],
		onSuccess: () => {
			void dispatch(
				changeLastAddedEntity({lastAddedEntity: LastEntityAddedFromCheckList.PROBLEM})
			);
			if (checkItem) {
				void dispatch(
					checkChangedRecords([checkItem.checkListId], objectId, workAcceptanceId)
				);
			}
		}
	});

	const handleOpenCheckDialog = useCallback(() => {
		addCheckRecord();
		openCheckDialog();
	}, [checkItem, checkRecord, openCheckDialog, workAcceptanceId, objectId, links]);

	const onSuccess = ({structureId, spaceId}: {structureId?: string; spaceId?: string}) => {
		setLinks({...links, structureId, spaceId});
		handleOpenCheckDialog();
	};
	const structures = workAcceptance?.structures ?? [];

	const [structureSelectDialog, openStructureSelectDialog] = useStructureSelectDialog(
		structures,
		onSuccess,
		addingCheckDialog ?? null
	);

	const spaces = workAcceptance?.spaces ?? [];
	const [spaceSelectDialog, openSpaceSelectDialog] = useSpaceSelectDialog(
		spaces,
		onSuccess,
		addingCheckDialog ?? null
	);

	const noSelectStructure = useCallback(() => {
		onSuccess(structures.length ? {structureId: structures[0].id} : {});
	}, [structures]);

	const noSelectSpace = useCallback(() => {
		onSuccess(spaces.length ? {spaceId: spaces[0].id} : {});
	}, [spaces]);

	const onClick =
		workAcceptance?.frontType === WorkAcceptanceFrontTypeId.STRUCTURES // Если Тип фронта - структуры, тогда, если если структур несколько то открываем окно выбора, если одна то используем её
			? structures.length > 1
				? openStructureSelectDialog
				: noSelectStructure
			: WorkAcceptanceFrontTypeId.SPACES // Если Тип фронта - помещения, тогда, если если помещений несколько то открываем окно выбора, если одно то используем его
			? spaces.length > 1
				? openSpaceSelectDialog
				: noSelectSpace
			: undefined;

	const dialog =
		workAcceptance?.frontType === WorkAcceptanceFrontTypeId.STRUCTURES
			? structureSelectDialog
			: WorkAcceptanceFrontTypeId.SPACES
			? spaceSelectDialog
			: null;

	return [dialog as ReactElement, onClick as () => void];
};
