import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {merge} from 'lodash';
import {useMemo} from 'react';

type TempRule = Record<
	string,
	{
		categories?: string[];
		plans?: string[];
		structureIds?: string[];
		spaceIds?: string[];
	}
>;

type ResultRule = Record<string, string[]>;

const addRuleParamInArray = (array: string[], param?: string[]) => {
	if (param?.length) {
		array.push(param.join(', '));
	}
};

export const usePreparedRules = (rules?: IEnrichedWorkingGroup['rules']): [ResultRule, ResultRule] =>
	useMemo(() => {
		if (!rules) {
			return [{}, {}];
		}
		const byUsers: TempRule = {};
		const byGroups: TempRule = {};

		for (const rule of rules) {
			const {target, ...rest} = rule;

			if (target.userId) {
				byUsers[target.userId] = merge(byUsers[target.userId], rest);
			}
			if (target.groupId) {
				byGroups[target.groupId] = merge(byUsers[target.groupId], rest);
			}
		}

		const byUsersResult: ResultRule = {};
		const byGroupsResult: ResultRule = {};
		for (const userId in byUsers) {
			if (byUsers.hasOwnProperty(userId)) {
				byUsersResult[userId] = [];
				addRuleParamInArray(byUsersResult[userId], byUsers[userId].categories);
				addRuleParamInArray(byUsersResult[userId], byUsers[userId].plans);
				addRuleParamInArray(byUsersResult[userId], byUsers[userId].structureIds);
				addRuleParamInArray(byUsersResult[userId], byUsers[userId].spaceIds);
			}
		}
		for (const groupId in byGroups) {
			if (byGroups.hasOwnProperty(groupId)) {
				byGroupsResult[groupId] = [];
				addRuleParamInArray(byGroupsResult[groupId], byGroups[groupId].categories);
				addRuleParamInArray(byGroupsResult[groupId], byGroups[groupId].plans);
				addRuleParamInArray(byGroupsResult[groupId], byGroups[groupId].structureIds);
				addRuleParamInArray(byGroupsResult[groupId], byGroups[groupId].spaceIds);
			}
		}

		return [byUsersResult, byGroupsResult];
	}, [rules]);