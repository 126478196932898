export enum CacheStatus {
	// Неизвестно состояние кеша
	UNKNOWN = 'unknown',
	// Кеш отсутствует
	MISSING = 'missing',
	// Кеширование в процессе
	CACHING = 'caching',
	// Кеш готов
	READY = 'ready',
	// Ошибка при кешировании
	ERROR = 'error'
}
