import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IExtendedSavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IExtendedSavingResponsibilityRule';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';

export type IEditableResponsibilityRuleState = IEditableEntityState<{
	id: string;
	groupId: string;
	target?:
		| {
				userId: string;
				groupId?: undefined;
		  }
		| {
				groupId: string;
				userId?: undefined;
		  };
	objects?: string[];
	scope?: string;
	stage?: ObjectStageIds;
	categories?: string[];
	plans?: string[];
	autoSelect?: boolean;
	structureIds?: string[];
	spaceIds?: string[];
	isDraft?: boolean;
}>;

export type IEditableResponsibilityRuleAction = IEditableEntityAction<
	IEditableResponsibilityRuleState,
	IExtendedSavingResponsibilityRule
>;

const makeEmptyState = (): IEditableResponsibilityRuleState => ({
	id: '',
	groupId: '',
	scope: undefined,
	target: undefined,
	objects: [],
	stage: undefined,
	categories: undefined,
	plans: undefined,
	autoSelect: undefined,
	structureIds: [],
	spaceIds: [],
	isDraft: undefined,
	errors: {
		target: false,
		scope: false,
		objects: false,
		stage: false,
		categories: false,
		plans: false,
		autoSelect: false,
		structureIds: false,
		spaceIds: false
	}
});

export const init = (
	original?: IExtendedSavingResponsibilityRule
): IEditableResponsibilityRuleState => {
	const empty = makeEmptyState();
	return original
		? {
				id: original.id,
				groupId: original.groupId,
				target: original.target,
				objects: original.objects,
				scope: original.scope,
				stage: original.stage,
				categories: original.categories,
				plans: original.plans,
				autoSelect: original.autoSelect,
				structureIds: original.structureIds,
				spaceIds: original.spaceIds,
				isDraft: original.isDraft,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (
	state: IEditableResponsibilityRuleState,
	original?: IExtendedSavingResponsibilityRule
): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'objects'),
		isPropEdited.bind(null, 'scope'),
		isPropEdited.bind(null, 'stage'),
		isPropEdited.bind(null, 'categories'),
		isPropEdited.bind(null, 'plans'),
		isPropEdited.bind(null, 'autoSelect'),
		isPropEdited.bind(null, 'structureType'),
		isPropEdited.bind(null, 'structureIds'),
		isPropEdited.bind(null, 'spaceIds')
	);

export const errorsFns = {
	target: (state: IEditableResponsibilityRuleState) => !state.target,
	objects: (state: IEditableResponsibilityRuleState) => !state.objects,
	scope: (state: IEditableResponsibilityRuleState) => !state.scope,
	stage: (state: IEditableResponsibilityRuleState) => !state.stage,
	categories: (state: IEditableResponsibilityRuleState) => !state.categories,
	plans: (state: IEditableResponsibilityRuleState) => !state.plans,
	autoSelect: (state: IEditableResponsibilityRuleState) => !state.autoSelect,
	structureIds: (state: IEditableResponsibilityRuleState) => !state.structureIds,
	spaceIds: (state: IEditableResponsibilityRuleState) => !state.spaceIds,
};

export const convertToSave = (
	state: IEditableResponsibilityRuleState,
	original?: IResponsibilityRule,
	onlyEdited?: boolean
): IExtendedSavingResponsibilityRule => {
	if (!onlyEdited) {
		return state;
	}

	const rule: IExtendedSavingResponsibilityRule = {id: state.id, groupId: state.groupId};

	if (isPropEdited('target', state, original)) {
		rule.target = state.target;
	}
	if (isPropEdited('objects', state, original)) {
		rule.objects = state.objects;
	}
	if (isPropEdited('scope', state, original)) {
		rule.scope = state.scope;
	}
	if (isPropEdited('stage', state, original)) {
		rule.stage = state.stage;
	}
	if (isPropEdited('categories', state, original)) {
		rule.categories = state.categories;
	}
	if (isPropEdited('plans', state, original)) {
		rule.plans = state.plans;
	}
	if (isPropEdited('autoSelect', state, original)) {
		rule.autoSelect = state.autoSelect;
	}
	if (isPropEdited('structureIds', state, original)) {
		rule.structureIds = state.structureIds;
	}
	if (isPropEdited('spaceIds', state, original)) {
		rule.spaceIds = state.spaceIds;
	}
	return rule;
};
