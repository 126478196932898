import * as types from '../constants';

/**
 * Изменяет доступность автономного режима
 *
 * @param value доступен/недоступен
 */
export const changeOfflineModeAvailability = (value: boolean) => ({
	type: types.CHANGE_OFFLINE_MODE_AVAILABILITY,
	payload: value
});
