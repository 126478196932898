import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {ISavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/ISavingResponsibilityRule';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddResponsibilityRuleResponse = IResponsibilityRule;

/**
 * Добавляет правило ответственности
 *
 * @param groupId id группы
 * @param rule данные для добавления
 */
export const makeResponsibilityRuleAddRequest = async (
	groupId: string,
	rule: ISavingResponsibilityRule
) => {
	const response = await httpRequests.withToken.post<IAddResponsibilityRuleResponse>(
		'responsibility-rules',
		rule
	);
	return response.data;
};
