import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemsDataByWorkAcceptanceId} from '@src/store/modules/pages/workAcceptances/selectors/problemsData';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {useMemo} from 'react';

export const useProblemsCountsByStatus = (workAcceptanceId: string) => {
	const problemsData = useAppSelector(s =>
		extractProblemsDataByWorkAcceptanceId(s, workAcceptanceId)
	);
	return useMemo(() => {
		const statusesCounts: Record<ProblemStatusId, number> = {
			[ProblemStatusId.EXPIRED]: 0,
			[ProblemStatusId.WAITING]: 0,
			[ProblemStatusId.HANDLING]: 0,
			[ProblemStatusId.VERIFICATION]: 0,
			[ProblemStatusId.FIXED]: 0
		};
		if (problemsData?.problems) {
			Object.values(problemsData.problems).forEach(value => {
				Object.entries(value).forEach(([status, data]) => {
					statusesCounts[status] += data.count;
				});
			});
			return Object.entries(statusesCounts).map(([key, val]) => ({
				status: key as ProblemStatusId,
				count: val
			}));
		}
		return undefined;
	}, [problemsData]);
};
