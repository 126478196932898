import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetCheckRecordsResponse = INormalizedData<ICheckRecord>;

export interface IGetCheckRecordsRequest {
	checkListId?: string[];
	checkItemId?: string[];
	spaceId?: string[];
	workAcceptanceId?: string[];
	problemId?: string[];
	objectId?: string[];
}

/**
 * Возвращает нормализованный список записей по категориям
 *
 * @param params запрос
 */
export const requestCheckRecords = async (params: IGetCheckRecordsRequest) => {
	const response = await httpRequests.withToken.post<IGetCheckRecordsResponse>(
		'check-records/get',
		params
	);
	return response.data;
};
