import {EntityGridItem} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IDescriptionProps {
	className?: string;
	value: string;
}

const Description = ({className, value}: IDescriptionProps) => {
	const {t} = useTranslation();
	return (
		<EntityGridItem
			className={className}
			icon={<i className="tz-description-20" />}
			label={t('components.viewFields.description.label')}
			fullRow
		>
			<div style={{whiteSpace: 'pre-wrap'}}>{value}</div>
		</EntityGridItem>
	);
};

export default Description;
