import {IGetListDocumentsResponse, IGetPaginateDocumentsResponse} from '@src/api/backend/documents';

export const extractDocumentsPaginateMap = (data: IGetPaginateDocumentsResponse) => data.byId;

export const extractDocumentsPaginateArray = (data: IGetPaginateDocumentsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractDocumentsListMap = (data: IGetListDocumentsResponse) => data.byId;

export const extractDocumentsListArray = (data: IGetListDocumentsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractDocumentsListFilteredArray = (data: IGetListDocumentsResponse, id?: string) => {
	const array = extractDocumentsListArray(data);
	return array.filter(document => document.links?.some(link => link.entityObjectId === id));
}