import {EditorInfo, EntityGrid, EntityGridItem, InlineButton, Plate} from '@tehzor/ui-components';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import Documents from './Documents';
import {useEditableInternalAcceptanceAttachmentsDialog} from '@src/components/EditableInternalAcceptanceAttachmentsDialog/hooks/useEditableInternalAcceptanceAttachmentsDialog';
import {useInternalAcceptancePermissions} from '@src/core/hooks/permissions/useInternalAcceptancePermissions';
import DescriptionDesktop from './Description.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import DescriptionMobile from './Description.mobile';
import {useTranslation} from 'react-i18next';

interface IDesktopInfoProps {
	objectId: string;
	internalAcceptance: ILinkedInternalAcceptance;
}

const documentIcon = <i className="tz-document-20" />;
const editIcon16 = <i className="tz-edit-16" />;
const editIcon20 = <i className="tz-edit-20" />;

const DesktopInfo = ({objectId, internalAcceptance}: IDesktopInfoProps) => {
	const {t} = useTranslation();

	// Диалог редактирования вложений
	const [editingAttachmentsDialog, openEditAttachmentsDialog] =
		useEditableInternalAcceptanceAttachmentsDialog(
			objectId,
			internalAcceptance.id,
			internalAcceptance
		);
	const permissions = useInternalAcceptancePermissions(objectId, internalAcceptance);
	const isDesktop = useIsDesktop();
	return (
		<Plate>
			<EntityGrid>
				{isDesktop ? (
					<DescriptionDesktop
						objectId={objectId}
						internalAcceptanceId={internalAcceptance.id}
						value={internalAcceptance.description}
						canEdit={permissions.canEdit}
					/>
				) : (
					<DescriptionMobile
						objectId={objectId}
						internalAcceptanceId={internalAcceptance.id}
						value={internalAcceptance.description}
						canEdit={permissions.canEdit}
					/>
				)}

				<EntityGridItem
					label={t('internalAcceptancePage.info.documents.label')}
					icon={documentIcon}
					fullRow
					buttons={
						permissions.canEdit ? (
							<InlineButton
								className="internal-acceptance-page__documents-edit-icon"
								type="accent"
								leftIcon={editIcon16}
								onClick={openEditAttachmentsDialog}
							/>
						) : null
					}
				>
					<Documents internalAcceptance={internalAcceptance} />
				</EntityGridItem>
				<EditorInfo
					icon={documentIcon}
					label={t('internalAcceptancePage.info.created.label')}
					date={internalAcceptance.createdAt}
					user={internalAcceptance.createdBy}
					fullRow
				/>
				{internalAcceptance.createdAt !== internalAcceptance.modifiedAt && (
					<EditorInfo
						icon={editIcon20}
						label={t('internalAcceptancePage.info.modified.label')}
						date={internalAcceptance.modifiedAt}
						user={internalAcceptance.modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default DesktopInfo;
