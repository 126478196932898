import * as React from 'react';
import './TabLink.less';
import classNames from 'classnames';
import ButtonBase from '../../../../buttons/ButtonBase';

interface ITabLinkProps {
	className?: string;
	style?: React.CSSProperties;
	label?: React.ReactNode;
	active?: boolean;
	disabled?: boolean;
	count?: number;

	onClick?(event: React.MouseEvent): void;
}

const TabLink = (props: ITabLinkProps) => {
	const {className, style, label, active, onClick, disabled, count} = props;

	return (
		<ButtonBase
			className={classNames(
				{'tab-link_active': active, 'tab-link_disabled': disabled},
				className
			)}
			style={style}
			disabled={disabled}
			classNamePrefix="tab-link"
			outerTagType="button"
			label={label}
			onClick={disabled ? undefined : onClick}
			rightIcon={count}
		/>
	);
};

export default TabLink;
