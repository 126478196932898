import {httpRequests} from '@tehzor/tools/api/httpRequests';

interface IDeleteInspectionResponse {
	success: true;
}

/**
 * Удаляет осмотр
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 */
export const makeInspectionDeleteRequest = async (objectId: string, inspectionId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteInspectionResponse>(
		`inspections/${inspectionId}`,
		{params: {objectId}}
	);

	return response.data;
};
