import {PropsWithChildren, useCallback, useRef, useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getProfile} from '@src/store/modules/auth/profile/actions';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {startCachingResources} from '@src/core/offlineMode/startCachingResources';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {companiesQueryKeys} from '@src/api/cache/companies/keys';
import {prefetchInitialResources} from '@src/components/ResourcesLoader/utils/prefetchInitialResources';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {LoadingPanel} from '@tehzor/ui-components';
import useAsync from 'react-use/lib/useAsync';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {resumeMutations} from '@src/store/persistentStorage/queryClientResumeMutations';

/**
 * Компонент для первоначальной загрузки ресурсов при старте приложения
 */
export const ResourcesLoader = ({children}: PropsWithChildren) => {
	const dispatch = useAppDispatch();
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const isActivated = useAppSelector(s => s.auth.profile.activated);
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	const isOnline = useAppSelector(s => s.offlineMode.networkStatus);
	const roles = useAppSelector(extractUserRoles);
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	const [caching, setCaching] = useState<boolean>(false);
	const loadingState = useRef(false);
	const queryClient = useQueryClient();

	const loadResources = useCallback(async () => {
		if (isOnline) {
			if (isAuthenticated) {
				await dispatch(getProfile());
			}

			if (!roles) {
				return;
			}

			// Запросы разделены на несколько блоков, чтобы не прилетали на сервер все одновременно
			await queryClient.prefetchQuery({queryKey: companiesQueryKeys.list()});
			await queryClient.prefetchQuery({queryKey: objectsQueryKeys.list()});

			await prefetchInitialResources(roles);
			resumeMutations();
		}
		// Отправка команды на обновление данных
		if (isOfflineModeAvailable && objects && objects.length) {
			try {
				setCaching(true);
				await startCachingResources(roles, false, objects);
			} finally {
				setCaching(false);
			}
		}
	}, [isAuthenticated, isOfflineModeAvailable, isOnline, objects, queryClient, roles]);

	// Загрузка ресурсов для авторизованного и активированного пользователя
	useAsync(async () => {
		if (!loadingState.current && isAuthenticated && isActivated) {
			await loadResources();
			if (isOnline) {
				loadingState.current = true;
			}
		}
	}, [isOnline, isAuthenticated, isActivated]);

	// При логауте необходимо сбросить флаг, чтобы затем была возможность снова загрузить ресурсы
	useUpdateEffect(() => {
		if (!isAuthenticated) {
			loadingState.current = false;
		}
	}, [isAuthenticated]);

	if (caching && (!onlineManager.isOnline() || !isOnline)) {
		return (
			<LoadingPanel
				style={{width: '100%', height: '100vh'}}
				delay={0}
				active
				loaderContent={<div>Восстановление данных для автономной работы</div>}
			/>
		);
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
};
