import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {findMutation} from './findMutation';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export const findParentMutation = (links?: IProblem['links'] | IInspection['links']) => {
	let mutation;
	if (links?.checkId) {
		mutation = findMutation(links.checkId);
	}
	if (links && 'ownerAcceptanceId' in links && links?.ownerAcceptanceId) {
		mutation = findMutation(links.ownerAcceptanceId);
	}
	if (links && 'warrantyClaimId' in links && links?.warrantyClaimId) {
		mutation = findMutation(links.warrantyClaimId);
	}
	if (links?.internalAcceptanceId) {
		mutation = findMutation(links.internalAcceptanceId);
	}
	return mutation;
};
