import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';

export function determineSubtitle(
	date: number | undefined,
	error: string | undefined,
	inProgress: boolean | undefined,
	progressTitle: string
) {
	if (inProgress) {
		return progressTitle;
	}
	if (error) {
		return error;
	}
	if (date) {
		return format(date, dateTimeFormat, {locale: ru});
	}
	return undefined;
}