import {useMemo} from 'react';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/interfaces';
import {useTranslation} from "react-i18next";

export const useOwnerAcceptancesPageCounterTitle = (objectId = 'all', count: number) => {
	const {t} = useTranslation();
	const {state} = useEntitiesFiltersCtx<IOwnerAcceptancesFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);

	return useMemo(
		() =>
			`${isFiltersActive ? t('ownerAcceptancesPage.useOwnerAcceptancesPageCounterTitle.searched') :
				t('ownerAcceptancesPage.useOwnerAcceptancesPageCounterTitle.total')}: ${count}`,
		[count]
	);
};
