import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordByItemId} from '@src/store/modules/pages/checkLists/selectors/records';
import {ReactNode, useCallback, useMemo} from 'react';
import {addRecord} from '@src/store/modules/pages/checkLists/actions/records/add';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {checkChangedRecords} from '@src/store/modules/pages/checkLists/actions/records/checkChangedRecords';
import {formSpaceLocationForEntities} from '@src/utils/formSpaceLocationForEntities';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useAddingInternalAcceptanceDialog} from '@src/components/AddingInternalAcceptanceDialog/hooks/useAddingInternalAcceptanceDialog';
import {useAddingWarrantyClaimDialog} from '@src/components/AddingWarrantyClaimDialog/hooks/useAddingWarrantyClaimDialog';
import {useAddingOwnerAcptDialog} from '@src/components/AddingOwnerAcptDialog/hooks/useAddingOwnerAcptDialog';
import {transformLocations} from '../utils/transformLocations';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {useShape} from '@src/core/hooks/queries/plans/hooks';
import {useSpace} from '@src/core/hooks/queries/space';
import {LastEntityAddedFromCheckList} from '@src/interfaces/LastEntityAddedFromCheckList';
import {checkListsActions} from '@src/store/modules/settings/pages/checkLists/slice';
import {onlineManager} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';

export const useAddingCheckListProblemDialog = (itemId: string): [ReactNode, () => void] => {
	const {objectId, workAcceptanceId, spaceId, stage, listId} = useStrictParams<{
		objectId: string;
		spaceId?: string;
		workAcceptanceId?: string;
		stage: ObjectStageIds;
		listId: string;
		itemId: string;
	}>();

	const {t} = useTranslation();

	const {data: space} = useSpace(spaceId, objectId);
	const [planId, sectorId] = transformLocations(space?.locations);
	const {data: sector} = useShape(objectId, planId, sectorId);
	const {data: checkList} = useExtractCheckListById(listId);
	const {data: checkItem} = useExtractCheckItemById(itemId);
	const checkRecord = useAppSelector(s => extractCheckRecordByItemId(s, itemId));
	const dispatch = useAppDispatch();
	const {changeLastAddedEntity} = checkListsActions;
	const addCheckRecord = useCallback(() => {
		if (!checkRecord.id) {
			// item здесь всегда определен
			// если запись не создана - создаем
			// после получения новой записи обновится links аргумент хука useAddingProblemDialog
			if (checkItem) {
				void dispatch(
					addRecord(
						objectId,
						{
							spaceId,
							checkItemId: checkItem.id,
							checkListId: checkItem.checkListId
						},
						stage,
						{status: CheckRecordStatusId.NOT_CHECKED}
					)
				);
			}
		}
	}, [checkItem, checkRecord, spaceId, objectId, stage]);

	const [addingCheckDialog, openCheckDialog] = useAddingCheckDialog({
		objectId,
		links: {
			spaceId,
			checkItemId: itemId,
			checkListId: listId,
			checkRecordId: checkRecord?.id,
			workAcceptanceId
		},
		stage: checkList?.stage,
		types: ['problem'],
		defaultProblemData: space ? formSpaceLocationForEntities(space, sector) : undefined,
		onSuccess: () => {
			void dispatch(
				changeLastAddedEntity({lastAddedEntity: LastEntityAddedFromCheckList.PROBLEM})
			);
			if (checkItem && onlineManager.isOnline()) {
				void dispatch(checkChangedRecords([checkItem.checkListId], objectId, spaceId));
			}
		}
	});

	const handleOpenCheckDialog = useCallback(() => {
		addCheckRecord();
		openCheckDialog();
	}, [addCheckRecord, openCheckDialog]);

	const [addingInternalAcceptanceDialog, openInternalAcceptanceDialog] =
		useAddingInternalAcceptanceDialog({
			objectId,
			links: {
				spaceId,
				checkItemId: itemId,
				checkListId: listId,
				checkRecordId: checkRecord?.id
			},
			stage: checkList?.stage,
			types: ['problem'],
			defaultProblemData: space ? formSpaceLocationForEntities(space, sector) : undefined,
			onSuccess: () => {
				void dispatch(
					changeLastAddedEntity({lastAddedEntity: LastEntityAddedFromCheckList.PROBLEM})
				);
				if (checkItem && onlineManager.isOnline()) {
					void dispatch(checkChangedRecords([checkItem.checkListId], objectId, spaceId));
				}
			}
		});

	const handleOpenInternalAcceptanceDialog = useCallback(() => {
		addCheckRecord();
		openInternalAcceptanceDialog();
	}, [addCheckRecord, openInternalAcceptanceDialog]);

	const [addingWarrantyClaimDialog, openWarrantyClaimDialog] = useAddingWarrantyClaimDialog({
		objectId,
		spaceId,
		links: {
			spaceId,
			checkItemId: itemId,
			checkListId: listId,
			checkRecordId: checkRecord?.id
		},
		defaultProblemData: space ? formSpaceLocationForEntities(space, sector) : undefined,
		onSuccess: () => {
			void dispatch(
				changeLastAddedEntity({lastAddedEntity: LastEntityAddedFromCheckList.PROBLEM})
			);
		}
	});

	const handleOpenWarrantyClaimDialog = useCallback(() => {
		addCheckRecord();
		openWarrantyClaimDialog();
	}, [addCheckRecord, openWarrantyClaimDialog]);

	const [addingOwnerAcptDialog, openOwnerAcptDialog] = useAddingOwnerAcptDialog({
		objectId,
		spaceId,
		title: t('ownerAcceptancesPage.addingDialog.title'),
		links: {
			spaceId,
			checkItemId: itemId,
			checkListId: listId,
			checkRecordId: checkRecord?.id
		},
		defaultProblemData: space ? formSpaceLocationForEntities(space, sector) : undefined,
		onSuccess: () => {
			void dispatch(
				changeLastAddedEntity({lastAddedEntity: LastEntityAddedFromCheckList.PROBLEM})
			);
		}
	});

	const handleOpenOwnerAcptDialog = useCallback(() => {
		addCheckRecord();
		openOwnerAcptDialog();
	}, [addCheckRecord, openOwnerAcptDialog]);

	const [dialog, handleOpenDialog] = useMemo(() => {
		switch (checkList?.stage) {
			case ObjectStageIds.ACCEPTANCE:
				return [addingInternalAcceptanceDialog, handleOpenInternalAcceptanceDialog];
			case ObjectStageIds.TRANSFER:
				return [addingOwnerAcptDialog, handleOpenOwnerAcptDialog];
			case ObjectStageIds.WARRANTY:
				return [addingWarrantyClaimDialog, handleOpenWarrantyClaimDialog];
			default:
				return [addingCheckDialog, handleOpenCheckDialog];
		}
	}, [
		checkList?.stage,
		addingInternalAcceptanceDialog,
		handleOpenInternalAcceptanceDialog,
		addingOwnerAcptDialog,
		handleOpenOwnerAcptDialog,
		addingWarrantyClaimDialog,
		handleOpenWarrantyClaimDialog,
		addingCheckDialog,
		handleOpenCheckDialog
	]);
	return [dialog, handleOpenDialog];
};
