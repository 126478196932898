import {forwardRef, Ref, useCallback} from 'react';
import * as React from 'react';
import './EntityLinkButton.less';
import Button, {IButtonProps} from '../Button';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';

export type ButtonType = 'common' | 'accent' | 'inline';

export interface IEntityLinkButtonProps extends Omit<IButtonProps, 'type' | 'outerTagType' | 'outerTagProps' | 'onClick'> {
	type?: ButtonType;
	url?: string;
	title?: string;
	target?: string;
	rel?: string;
	onClick?: (url?: string) => void;
}

const EntityLinkButton = (props: IEntityLinkButtonProps, ref?: Ref<HTMLAnchorElement>) => {
	const {
		className,
		type = 'common',
		title,
		url,
		target,
		rel,
		onClick,
		...rest
	} = props;

	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			if (onClick) {
				event.stopPropagation();
				event.preventDefault();
				onClick(url);
			}
		},
		[onClick, url]
	);

	const classes = convertClassNames(className);

	return (
		<Button
			{...rest}
			className={{
				root: classNames(
					'entity-link-button',
					`entity-link-button_${type}`,
					{'entity-link-button_disabled': rest.disabled},
					classes.root
				),
				wrap: classNames('entity-link-button__wrap', classes.wrap),
				label: classNames('entity-link-button__label', classes.label),
				leftIcon: classNames('entity-link-button__icon_left', classes.leftIcon),
				rightIcon: classes.rightIcon
			}}
			type="common"
			outerTagType="a"
			outerTagProps={{href: url, title, target, rel, draggable: 'false'}}
			onClick={handleClick}
			ref={ref}
			dataTestId="ParentEntityLink"
		/>
	);
};

EntityLinkButton.displayName = 'EntityLinkButton';

export default forwardRef(EntityLinkButton);
