import * as React from 'react';
import './MobileHeader.less';
import classNames from 'classnames';
// import {useScrollingReveal} from '../../../hooks/useScrollingReveal';

interface IMobileHeaderProps {
	className?: string;
	style?: React.CSSProperties;
	title?: string;
	subTitle?: string;
	left?: React.ReactNode;
	right?: React.ReactNode;
	sectionsMenu?: React.ReactNode;
	titleButtons?: React.ReactNode;
	// hideOnScroll?: boolean;
	// scrollContainerId?: string;
}

const MobileHeader = (props: IMobileHeaderProps) => {
	const {
		className,
		style,
		title,
		subTitle,
		left,
		right,
		sectionsMenu,
		titleButtons /* , hideOnScroll, scrollContainerId */
	} = props;

	// const [{offset}, ref] = useScrollingReveal<HTMLDivElement>({scrollContainerId});
	return (
		<div
			className={classNames('m-header', className)}
			style={style}
			// style={{...style, transform: hideOnScroll ? `translate(0, -${offset}px)` : undefined}}
			// ref={ref}
		>
			<div className="m-header__main-row">
				<div className="m-header__left">{left}</div>
				<div className="m-header__title-section">
					<div className="m-header__title-wrap">
						<div
							className={classNames('m-header__title', {
								'm-header__title_small': !!subTitle
							})}
						>
							{title}
						</div>

						{subTitle && <div className="m-header__sub-title">{subTitle}</div>}
					</div>
					{titleButtons && <div className="m-header__title-buttons">{titleButtons}</div>}
				</div>
				<div className="m-header__right">{right}</div>
			</div>

			{!!sectionsMenu && <div className="m-header__sections-menu">{sectionsMenu}</div>}
		</div>
	);
};

export default MobileHeader;
