import {httpRequests} from '@tehzor/tools/api/httpRequests';
import IUser from '@tehzor/tools/interfaces/IUser';

export type IRequestProfileResponse = IUser;

/**
 * Получает информацию о текущем пользователе
 */
export const requestProfile = async () =>
	(await httpRequests.withToken.get<IUser>('/profile')).data;
