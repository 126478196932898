import {usePlan, usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {useParams} from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PlanPage from '@src/pages/PlanPage';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';

export const PlanPageWrapper = () => {
	const {planId, objectId} = useParams();
	const {data: plan} = usePlan(objectId, planId);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	
	const {data: plans} = usePlansAsArray(objectId);
	const amountOfPlans = plans?.length || 0;

	return (
		<PlanPage
			plan={plan}
			spaceTypesMap={spaceTypes}
			amountOfPlans={amountOfPlans}
		/>
	);
};
