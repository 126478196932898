import {IconButton} from '@tehzor/ui-components';
import { memo, useMemo } from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {transformLocations} from '../../utils/transformLocations';
import {useEntityAddingPermission} from '../../hooks/useEntityAddingPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useAddingCheckListTaskDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListTaskDialog';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useShape} from '@src/core/hooks/queries/plans/hooks';
import {useSpace} from '@src/core/hooks/queries/space';

interface ICheckListHeaderActionsProps {
	listId: string;
}

const taskAddIcon = <i className="tz-task-add-24" />;

export const CheckListHeaderActions = memo((props: ICheckListHeaderActionsProps) => {
	const {listId: checkListId} = props;

	const {objectId, spaceId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		spaceId?: string;
		workAcceptanceId?: string;
	}>();
	const {data: space} = useSpace(spaceId, objectId);

	const [planId, sectorId] = transformLocations(space?.locations);
	const roles = useAppSelector(extractUserRoles);

	const canAddEntity = useEntityAddingPermission(objectId, checkListId);
	const permissions = {
		canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
		canAddEntity
	};

	const {data: sector} = useShape(objectId, planId, sectorId);

	const location = useMemo(
		() =>
			sector
				? {
						sectors: [
							{
								sector: sector?.id,
								name: sector.name
							}
						]
				  }
				: undefined,
		[sector]
	);
	const [taskDialog, handleOpenAddTaskDialog] = useAddingCheckListTaskDialog({
		objectId,
		floor: space?.floor,
		planId,
		location,
		spaceId,
		workAcceptanceId,
		checkListId
	});

	return permissions.canAddTask ? (
		<div>
			<IconButton
				className="check-list-page__d-plate-header-title__menu-btn"
				type='common'
				onClick={handleOpenAddTaskDialog}
			>
				{taskAddIcon}
			</IconButton>
			{taskDialog}
		</div>
	) : null;
});
