import * as React from 'react';
import './AddingDocuments.less';
import classNames from 'classnames';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {convertClassNames} from '../../../utils/convertClassNames';
import {UploadingFile} from '../../files';
import {IFilesUploaderProps, withFileUploader} from '@tehzor/tools/components/withFileUploader';

interface IAddingDocumentsProps {
	className?:
		| string
		| {
				root?: string;
				file?: string;
		  };
	style?: React.CSSProperties;
	savedAttachments?: IAttachment[];
	disabled?: boolean;
}

const AddingDocuments = withFileUploader<
	IAddingDocumentsProps &
		IFilesUploaderProps & {
			reloadFile?: (key: string) => void;
			deleteFile?: (key: string) => void;
		}
>(props => {
	const {className, style, uploadingFiles: files, disabled, deleteFile, reloadFile} = props;

	const classes = convertClassNames(className);

	return (
		<div
			className={classNames('adding-documents', classes.root)}
			style={style}
		>
			{files.map(file => (
				<UploadingFile
					{...file}
					className={classNames('adding-documents__file', classes.file)}
					data={file.key}
					disabled={disabled}
					onDelete={deleteFile}
					onReload={reloadFile}
				/>
			))}
		</div>
	);
});
export default AddingDocuments;
