import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IWarrantyClaimsStatsDataRequest} from '@src/interfaces/warrantyClaimsStatsData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

export type IGetWarrantyClaimsProblemsResponse = Record<string, IEntityProblemsData>;

/**
 * Получает статстику нарушений для гарантийных обращений
 *
 * @param data
 */
export const requestWarrantyClaimsStatsData = async (data: IWarrantyClaimsStatsDataRequest) => {
	const response = await httpRequests.withToken.post<IGetWarrantyClaimsProblemsResponse>(
		`warranty-claims/problems-data-warranty-claims`,
		data
	);
	return response.data;
};
