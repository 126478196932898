import {IGetProblemViewsResponse} from '@src/api/backend/problemViews';
import {problemViewsQueryKeys} from '@src/api/cache/problemViews/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractProblemViewsAsArray, extractSortedProblemViewsAsArray} from './selectors';

interface IProblemViewsParams {
	objectId: string;
	problemId: string;
	canSeeViews?: boolean;
}

export const useProblemViews = <T = IGetProblemViewsResponse>(
	params: IProblemViewsParams,
	select?: (data: IGetProblemViewsResponse) => T
) => {
	const {objectId, problemId, canSeeViews} = params;
	return useQuery<IGetProblemViewsResponse, IError, T>({
		queryKey: problemViewsQueryKeys.detail(objectId, problemId),
		select,
		meta: {
			error: 'при загрузке просмотров нарушения'
		},
		enabled: !!canSeeViews
	});
};

export const useProblemViewsAsArray = (params: IProblemViewsParams) =>
	useProblemViews(params, extractProblemViewsAsArray);

export const useSortedProblemViewsAsArray = (params: IProblemViewsParams) =>
	useProblemViews(params, extractSortedProblemViewsAsArray);
