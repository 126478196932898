import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetSpaceTypeDecorationSetsResponse
	extends INormalizedData<ISpaceTypeDecorationSet> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает наборы типов отделки помещений
 */
export const requestSpaceTypeDecorationSets = async () => {
	const response = await httpRequests.withToken.get<IGetSpaceTypeDecorationSetsResponse>(
		'space-type-decoration-sets'
	);
	return response.data;
};
