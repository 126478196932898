import {memo} from 'react';
import {Boards, Schema} from '@tehzor/ui-components';
import {ObjectBoard} from '@/widgets/StructuresSchema/ui/ObjectBoard/ObjectBoard';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {Tabs} from '@/widgets/StructuresSchema/ui/Tabs/Tabs';

interface ISchemaProps {
	objectId: string;
}

export const StructuresSchema = memo(({objectId}: ISchemaProps) => {
	const isDesktop = useIsDesktop();

	return (
		<Schema>
			{isDesktop && <Tabs objectId={objectId} />}
			<Boards>
				<ObjectBoard objectId={objectId} />
			</Boards>
		</Schema>
	);
});
