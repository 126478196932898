import {useExtractCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';
import {useCheckItemIndex} from '@src/pages/CheckListPage/hooks/useCheckItemIndex';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {EntityInfoLink} from '@tehzor/ui-components';
import {ChecklistProblemInfoMobileCell} from '@src/components/tableCells/ChecklstProblemInfoMobileCell';

export const CheckListProblemCellWrap = (props: IProblemCellProps<{data: IProblem}>) => {
	const {itemId} = useStrictParams<{itemId: string}>();

	const {row, replies, allProblemTags} = props;
	const {data} = row.original;
	const {links} = data;

	const {data: item} = useExtractCheckItemById(links?.checkItemId);
	const currentIndex = useCheckItemIndex(item);

	return (
		<ChecklistProblemInfoMobileCell
			problem={data}
			depth={row.depth}
			subTitle={
				links?.checkItemId !== itemId ? (
					<EntityInfoLink label={`${currentIndex} ${item?.name}`} />
				) : undefined
			}
			replies={replies}
			allProblemTags={allProblemTags}
			getToggleRowSelectedProps={row.getToggleRowSelectedProps}
			positionFilesCount="after"
		/>
	);
};
