import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableResultWorkAcceptanceState, IEditableResultWorkAcceptanceAction, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

export const useEditableResultWorkAcceptanceState = (workAcceptance?: IWorkAcceptance) =>
	useReducer<
		Reducer<IEditableResultWorkAcceptanceState, IEditableResultWorkAcceptanceAction>,
		IWorkAcceptance | undefined
	>(createReducer(init, errorsFns), workAcceptance, init);
