import {IGetProblemStatusesResponse} from '@src/api/backend/problemStatuses';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractProblemStatusesAsArray,
	extractProblemStatusesMap,
	extractProblemStatusesWithExpired
} from './selectors';

export const useProblemStatuses = <T = IGetProblemStatusesResponse>(
	select?: (data: IGetProblemStatusesResponse) => T
) =>
	useQuery<IGetProblemStatusesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.problemStatuses(),
		meta: {
			error: 'ошибка при получении статусов нарушений'
		},
		select
	});

export const useProblemStatusesAsArray = () => useProblemStatuses(extractProblemStatusesAsArray);
export const useProblemStatusesMap = () => useProblemStatuses(extractProblemStatusesMap);
export const useProblemStatusesExpired = () =>
	useProblemStatuses(extractProblemStatusesWithExpired);
