export type IMeterConsumptionsListQueryKey = [] & {
	0: string; // meter-consumptions
	1: string; // list
	2: string; // meterId
};

export const meterConsumptionsQueryKeys = {
	all: () => ['meter-consumptions'],
	one: () => ['meter-consumption'],
	lists: () => [...meterConsumptionsQueryKeys.all(), 'list'],
	list: (meterId: string) => [...meterConsumptionsQueryKeys.lists(), meterId]
};
