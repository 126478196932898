import { Component } from 'react';
import {IWeekRowProps} from '../../Calendar/components/WeekRow';
import {IDayData} from '../../Calendar/hooks/useDaysGrid';
import {isBefore, isWithinInterval} from 'date-fns';
import ResizeObserver from 'resize-observer-polyfill';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';

export interface IRangeWeekRowProps extends Partial<IWeekRowProps> {
	valueFrom?: Date;
	valueTo?: Date;
}

interface IRangeWeekRowState {
	days?: IDayData[];
	valueFrom?: Date;
	valueTo?: Date;
	fromIndex?: number;
	toIndex?: number;
}

class RangeWeekRow extends Component<IRangeWeekRowProps, IRangeWeekRowState> {
	static displayName = 'RangeWeekRow';

	private _row: HTMLDivElement | null | undefined;

	private _selection: HTMLDivElement | null | undefined;

	private _unsubscribe?: () => void;

	private _handleRowResize = rafThrottle(() => {
		this._updateSelection();
	});

	constructor(props: IRangeWeekRowProps) {
		super(props);

		this.state = {};
	}

	static getDerivedStateFromProps(nextProps: IRangeWeekRowProps, prevState: IRangeWeekRowState) {
		const {days, valueFrom, valueTo} = nextProps;
		if (days !== prevState.days || valueFrom !== prevState.valueFrom || valueTo !== prevState.valueTo) {
			let fromIndex;
			let toIndex;
			if (valueFrom && valueTo && days?.length) {
				const selectedInterval = isBefore(valueFrom, valueTo) ? {start: valueFrom, end: valueTo} : {start: valueTo, end: valueFrom};
				for (let i = 0; i < days.length; i++) {
					if (isWithinInterval(days[i].date, selectedInterval)) {
						fromIndex = i;
						break;
					}
				}
				for (let i = days.length - 1; i >= 0; i--) {
					if (isWithinInterval(days[i].date, selectedInterval)) {
						toIndex = i;
						break;
					}
				}
			}
			return {
				days: nextProps.days,
				valueFrom: nextProps.valueFrom,
				valueTo: nextProps.valueTo,
				fromIndex,
				toIndex
			};
		}
		return null;
	}

	componentDidMount() {
		this._updateSelection();
	}

	componentDidUpdate(prevProps: IRangeWeekRowProps) {
		const {days, valueFrom, valueTo} = this.props;
		if (days !== prevProps.days || valueFrom !== prevProps.valueFrom || valueTo !== prevProps.valueTo) {
			this._updateSelection();
		}
	}

	componentWillUnmount() {
		this._stopResizeObserving();
	}

	render() {
		const {children} = this.props;

		return (
			<div className="inline-date-range-picker__week-row-wrap">
				<div
					className="inline-date-range-picker__week-row-selection"
					ref={this._saveSelectionRef}
				/>
				<div
					className="calendar__week-row inline-date-range-picker__week-row"
					ref={this._saveRowRef}
				>
					{children}
				</div>
			</div>
		);
	}

	/**
	 * Сохраняет ссылку на активный пункт меню
	 *
	 * @param element html-элемент
	 */
	private _saveRowRef = (element: HTMLDivElement | null) => {
		this._row = element;
		this._stopResizeObserving();
		if (this._row) {
			const observer = new ResizeObserver(this._handleRowResize);
			observer.observe(this._row);
			this._unsubscribe = () => observer.disconnect();
		}
	};

	/**
	 * Сохраняет ссылку на активный пункт меню
	 *
	 * @param element html-элемент
	 */
	private _saveSelectionRef = (element: HTMLDivElement | null) => {
		this._selection = element;
	};

	private _updateSelection = () => {
		if (this._row && this._selection) {
			const {fromIndex, toIndex} = this.state;
			let left = '';
			let width = '';
			if (fromIndex !== undefined && toIndex !== undefined && this._row) {
				const fromElement = this._row.children[fromIndex] as HTMLElement;
				const toElement = this._row.children[toIndex] as HTMLElement;

				left = `${fromElement.offsetLeft}px`;
				width = `${toElement.offsetLeft + toElement.offsetWidth - fromElement.offsetLeft}px`;
			}
			this._selection.style.left = left;
			this._selection.style.width = width;
		}
	};

	private _stopResizeObserving = () => {
		if (this._unsubscribe) {
			this._unsubscribe();
			this._unsubscribe = undefined;
		}
	};
}

export default RangeWeekRow;
