import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetWorkingGroupsResponse extends INormalizedData<IWorkingGroup> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список рабочих групп
 */
export const requestWorkingGroups = async () => {
	const response = await httpRequests.withToken.get<IGetWorkingGroupsResponse>('working-groups');
	return response.data;
};
