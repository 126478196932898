import {createContext, useContext} from 'react';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {Tree} from 'array-to-tree';
import {ICommentVariants} from '@tehzor/tools/interfaces/comments/ICommentType';
import {IEntityCommentsPermissions} from '@src/core/hooks/permissions/useProblemCommentsPermissions';
import {ICommentPermissions} from '../hooks/useProblemCommentPermissions';
import ISavingComment from '@src/interfaces/ISavingComment';

export interface ICommentsCtx {
	hasMore: boolean;
	visibility: ICommentVariants[];
	changeCommentsVisibility: (value: ICommentVariants[]) => {
		type: string;
		payload: ICommentVariants[];
	};
	transferStatus?: OfflineDataTransferStatus;
	entityPermissions: IEntityCommentsPermissions;
	commentPermissions: (comment: IComment) => ICommentPermissions;
	links?: IComment['links'];
	comments: Array<Tree<IComment>>;
	getComments: () => void;
	addReply: (savingData: ISavingComment) => Promise<IComment>;
	editReply: (commentId: string, savingData: ISavingComment) => Promise<IComment>;
	addComment: (savingData: ISavingComment) => Promise<IComment>;
	editComment: (commentId: string, savingData: ISavingComment) => Promise<IComment>;
	getEntity?: () => void;
	resetComments: () => void;
	deleteComment: (commentId: string) => void;
	deleteReply: (commentId: unknown) => void;
	lastReply?: IComment;
	getLastReply: () => void;
}

const commentPermissionsPlaceholder: (comment: IComment) => ICommentPermissions = comment => ({});

export const CommentsCtx = createContext<ICommentsCtx>({
	hasMore: false,
	visibility: [],
	changeCommentsVisibility: () => ({type: '', payload: []}),
	transferStatus: undefined,
	entityPermissions: {},
	commentPermissions: commentPermissionsPlaceholder,
	links: {},
	comments: [],
	getComments: () => null,
	addReply: () => new Promise<IComment>(resolve => resolve({} as IComment)),
	editReply: () => new Promise<IComment>(resolve => resolve({} as IComment)),
	addComment: () => new Promise<IComment>(resolve => resolve({} as IComment)),
	editComment: () => new Promise<IComment>(resolve => resolve({} as IComment)),
	getEntity: () => null,
	resetComments: () => null,
	deleteComment: () => null,
	deleteReply: () => null,
	lastReply: undefined,
	getLastReply: () => null
});

export const useCommentsCtx = () =>
	useContext<ICommentsCtx>(CommentsCtx);
