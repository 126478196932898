import {IChangeFiltersPayload} from "@src/store/interfaces/IChangeFiltersPayload";
import {IChangePageSizePayload} from "@src/store/interfaces/IChangePageSizePayload";
import {IChangeSortPayload} from "@src/store/interfaces/IChangeSortPayload";
import {TasksDisplayMode} from "@src/interfaces/TasksDisplayMode";

export interface ITasksPageSettingsState {
	displayMode: TasksDisplayMode;
}
export interface ITasksFiltersState extends Record<string, unknown> {
	objects?: string[];
	types?: string[];
	statuses?: string[];
	priorities?: string[];
	taskIntervalStartFrom?: Date;
	taskIntervalStartTo?: Date;
	taskIntervalEndFrom?: Date;
	taskIntervalEndTo?: Date;
	createdAtFrom?: Date;
	createdAtTo?: Date;
	respUsers?: string[];
	createdBy?: string[];
	searchString?: string;
	checkListIds?: string[];
	checkItemIds?: string[];
}

export type ITasksSortState = Record<string, boolean>;

export type ITasksSettingsMode = keyof Omit<ITasksSettingsState, 'currentMode' | 'heights'>;

export type ChangeScheduleValueMode = 'increment' | 'decrement';

export type IChangeTasksFiltersPayload<T> = Omit<IChangeFiltersPayload<T>, "objectId">

export type IChangeTasksPageSizePayload = Omit<IChangePageSizePayload, "objectId">

export type IChangeTasksSortPayload<T> = Omit<IChangeSortPayload<T>, "objectId">

export interface ITasksPageSettingsScheduleState {
	filters: ITasksFiltersState;
	settings: ITasksSettingsState;
	collectBy: TasksScheduleCollectMode;
}
export interface ITasksSettingsState {
	currentMode: ITasksSettingsMode;
	year: number;
	month: number;
	week: [number, number];
	day: number;
}
export interface ITasksPageSettingsListState {
	filters: ITasksFiltersState;
	sort: ITasksSortState;
	pageSize: number;
	offset: number;
	selectedRows: string[];
}
export enum TasksScheduleCollectMode {
	OBJECT = 'object',
	GROUP = 'group'
}