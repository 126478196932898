import {FilterPage} from '@tehzor/ui-components';
import {IFilterPageProps} from '@tehzor/ui-components/src/components/filters/FilterPage';
import {useTranslation} from 'react-i18next';

type ITranslatedFilterPageProps = IFilterPageProps;

export const TranslatedFilterPage = (props: ITranslatedFilterPageProps) => {
	const {t} = useTranslation();

	return (
		<FilterPage
			acceptBtnLabel={t('filterPage.acceptBtn.label')}
			{...props}
		/>
	);
};
