export const timeFormat = 'HH:mm';

export const dateFormat = 'dd.MM.yyyy';

export const longDateFormat = 'dd MMMM yyyy';

export const longDateTimeFormat = 'dd MMMM yyyy HH:mm';

export const dateTimeFormat = 'dd.MM.yyyy HH:mm';

export const dateTimeCommaSeparatedFormat = 'dd.MM.yyyy, HH:mm';

export const dateTimeLetterSeparatedFormat = 'dd.MM.yyyy в HH:mm';

export const getDateTimeLetterSeparatedFormat = (separator: string): string => `dd.MM.yyyy '${separator}' HH:mm`;
export const dateMonthStringFormat = 'DD MMMM';
