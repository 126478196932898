import {IAnyEntity} from '../interfaces/IAnyEntity';

interface IGraphableEntity extends IAnyEntity {
	dependencies?: string[];
}

interface SortResult<T> {
	sorted: T[];
	// [nodeId, dependencyId]
	edges: Array<[string, string]>;
}

/**
 * Сортировка сущностей, у которых есть зависимости
 * Порядок не определен (может быть несколько "правильных" решений)
 * Возвращает отсортированные элементы и грани
 * Если грани не пустые - обнаружена циклическая зависимость
 *
 * @param data
 */
export const topologicalSort = <T extends IGraphableEntity>(data: T[]): SortResult<T> => {
	const sorted: T[] = [];
	const heads: T[] = [];
	const edges: Array<[string, string]> = [];

	for (const item of data) {
		// элементы без зависимостей закидываем в heads
		if (!item.dependencies || item.dependencies.length === 0) {
			heads.push(item);
			continue;
		}

		// из элементов с зависимостями строим edges
		for (const depId of item.dependencies || []) {
			edges.push([item.id, depId]);
		}
	}

	while (heads.length !== 0) {
		// достаем первый элемент без зависимостей
		// не может быть undefined из-за условия while-loop
		const item = heads.pop()!;
		sorted.push(item);

		// находим edges, относящиеся к текущему item
		const itemEdges = edges.filter(([, dep]) => dep === item.id);

		// убираем из граней те, что относятся к текущему элементу
		for (const edge of itemEdges) {
			const [nodeId] = edge;
			const node = data.find(dataNode => dataNode.id === nodeId)!;

			// удаляем из edges текущую грань
			const index = edges.indexOf(edge);
			edges.splice(index, 1);

			const nodeEdges = edges.filter(([id]) => id === node.id);

			// если у ноды больше нет граней - зависимости разрешены
			if (nodeEdges.length === 0) {
				heads.push(node);
			}
		}
	}

	return {sorted, edges};
};
