import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {editProfile} from '@src/store/modules/auth/profile/actions';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {useCallback, useEffect, useRef} from 'react';
import {Id} from 'react-toastify';
import {addToast} from './addToast';

export const EmailNotificationsToast = () => {
	const dispatch = useAppDispatch();
	const {subscribed} = useAppSelector(extractUserProfile);
	const toastId = useRef<Id>();

	const handleSubscribe = useCallback(async () => {
		await dispatch(editProfile({subscribed: true}));
	}, []);

	const handleUnsubscribe = useCallback(async () => {
		await dispatch(editProfile({subscribed: false}));
	}, []);

	useEffect(() => {
		if (subscribed === undefined) {
			toastId.current = addToast(
				'Подписка на уведомления',
				'Получать уведомления на электронную почту?',
				handleSubscribe,
				handleUnsubscribe
			);
		}
	}, [subscribed]);

	return null;
};