import {IGetListInspectionsResponse} from '@src/api/backend/inspections';
import {CheckEntitiesVisibility} from '@src/store/modules/settings/pages/check/reducers/entitiesVisibility';

/**
 * Извлекает осмотры проверки
 */
export const extractCheckInspectionsArray = (data: IGetListInspectionsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractCheckInspectionsEntity = (
	data: IGetListInspectionsResponse,
	visibility: CheckEntitiesVisibility[]
) => {
	const inspections = extractCheckInspectionsArray(data);
	if (visibility.includes('inspections') && inspections?.length) {
		return inspections.map(item => ({
			id: `i_${item.id}`,
			type: 'inspection',
			data: item
		}));
	}
	return [];
};
