import {queryClient} from '@src/api/QueryClient';
import {IGetProblemTagsResponse, makeProblemTagEditRequest} from '@src/api/backend/problemTags';
import {problemTagsQueryKeys} from '@src/api/cache/problemTags/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';

export const useEditProblemTag = () =>
	useMutation<IProblemTag, IError, ISavingProblemTag>({
		mutationFn: (params: ISavingProblemTag) => makeProblemTagEditRequest(params),
		mutationKey: problemTagsQueryKeys.edit(),
		onSuccess: data => {
			queryClient.setQueryData<IGetProblemTagsResponse>(
				problemTagsQueryKeys.list(),
				oldData => {
					if (oldData) {
						if (oldData.allIds.includes(data.id)) {
							return {
								allIds: oldData.allIds,
								byId: {...oldData.byId, [data.id]: data}
							};
						}
					}
					return oldData;
				}
			);
			addSuccessToast('Успех', 'Метка изменена');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при редактировании метки');
		}
	});
