import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '../StatusesFilterPage';
import {useExtractWorkAcceptanceFrontTypesAsArray} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

export const WorkAcceptanceFrontTypeFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity?: string}>();

	const {data: allFrontTypes} = useExtractWorkAcceptanceFrontTypesAsArray();
	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.workAcceptanceFrontTypes,
		allFrontTypes
	);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={translatedOptions}
			label={t('entitiesFilters.statusesFilter.workAcceptanceFrontTypes.label')}
			filterName="frontType"
		/>
	);
};
