import {combineReducers} from 'redux';
import building from './building/reducers';
import acceptance from './acceptance/reducers';
import warranty from './warranty/reducers';
import sources from './sources/reducers';
import ui from './ui/reducers';

export default combineReducers({
	building,
	acceptance,
	warranty,
	sources,
	ui
});
