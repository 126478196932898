import {IGetSpaceTypeDecorationResponse} from '@src/api/backend/spaceTypeDecoration';

export const extractSpaceDecorationTypesAllIds = (data: IGetSpaceTypeDecorationResponse) =>
	data.allIds;

export const extractSpaceTypeDecorationsAsMap = (data: IGetSpaceTypeDecorationResponse) =>
	data.byId;

/**
 * Возвращает типы отделки помещений в виде массива
 */

export const extractSpaceTypeDecorationsAsArray = (data: IGetSpaceTypeDecorationResponse) =>
	data.allIds.map((id: string) => data.byId[id]);
/**
 * Возвращает типы отделки помещений для наборов в виде массива
 */

export const extractSpaceTypeDecorationsForSetsAsArray = (
	data: IGetSpaceTypeDecorationResponse,
	decorationSetIds: string[]
) => {
	const types = extractSpaceTypeDecorationsAsArray(data);
	return types.filter(type => decorationSetIds.includes(type.typeDecorationSetId));
};

/**
 * Возвращает типы отделки помещений по id набора
 */
export const extractSpaceTypeDecorationsBySetIdAsArray = (data: IGetSpaceTypeDecorationResponse) =>
	data.allIds.map((id: string) => data.byId[id]);
