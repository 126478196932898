import classNames from 'classnames';
import { CSSProperties, ReactNode, useCallback, useState } from 'react';
import {useMeasure} from 'react-use';
import {useIsDesktop} from '../../../../../utils/mediaQueries';
import {Button, IconButton} from '../../../../buttons';
import './ErrorMessage.less';
import {Dialog} from '../../../../dialogs';

const warningIcon = <i className="tz-warning-20"/>;
const infoIcon = <i className="tz-info-24"/>;
const reloadIcon = <i className="tz-reload-24"/>;

interface IErrorMessageProps {
	className?: string;
	style?: CSSProperties;
	message?: ReactNode;
}

export const ErrorMessage = ({className, style, message}: IErrorMessageProps) => {
	const [isOpen, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleReload = useCallback(() => {
		window.location.reload();
	}, []);

	const [ref, {width, height}] = useMeasure<HTMLDivElement>();

	const isDesktop = useIsDesktop();
	const minWidth = isDesktop ? 380 : 360;
	const extendedWidth = isDesktop ? 220 : 200;
	const minHeight = 80;

	const isExtended
		= (width >= extendedWidth && height >= minHeight)
		|| (width >= minWidth);
	const isColumns = !isExtended
		? height >= minHeight && width <= (extendedWidth / 2)
		: height >= minHeight;

	const dialog = (
		<Dialog
			className="error-message__dialog"
			isOpen={isOpen}
			onRequestClose={handleClose}
			fullScreenOnMobile
			title="Информация об ошибке"
		>
			{message}
		</Dialog>
	);

	return (
		<div
			className={classNames('error-message__container', className)}
			style={style}
			ref={ref}
		>
			<div className={classNames('error-message', {isColumns})}>
				<div
					className={classNames(
						'error-message__error-block',
						{isSlim: isColumns && !isExtended}
					)}
				>
					{warningIcon}
					{isExtended && (
						<div className="error-message__error-block-text">
							Произошла ошибка
						</div>
					)}
					<IconButton
						onClick={handleOpen}
					>
						{infoIcon}
					</IconButton>
				</div>
				<div className="error-message__reload-block">
					<Button
						className="error-message__reload-block-button"
						leftIcon={reloadIcon}
						label={isExtended && 'Обновить'}
						onClick={handleReload}
					/>
				</div>
			</div>
			{dialog}
		</div>
	);
};