import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Возвращает ссылку для скачивания документа
 *
 * @param documentId id документа
 */
export const requestDocumentLink = async (documentId: string) => {
	const response = await httpRequests.withToken.get<string>(`documents/link/${documentId}`);
	return response.data;
};
