import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import {exportWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';
import {useExportProblems} from '@src/core/hooks/mutations/problems/useExportProblems';

function useDestinations(selectedEntities: IWarrantyClaimEntity[]) {
	const [destinations, setDestinations] = useState<ExportTemplatesDestinationId[]>([
		ExportTemplatesDestinationId.WARRANTY_CLAIM
	]);

	useEffect(() => {
		let hasProblems = false;
		for (const item of selectedEntities) {
			if (item.type === 'problem') {
				hasProblems = true;
				break;
			}
		}

		// Экспорт обращения и списка нарушений
		if (hasProblems) {
			return setDestinations([
				ExportTemplatesDestinationId.WARRANTY_CLAIM,
				ExportTemplatesDestinationId.PROBLEMS
			]);
		}
		return undefined;
	}, [selectedEntities]);

	return destinations;
}

const splitSelectedEntities = (selectedEntities: IWarrantyClaimEntity[]) =>
	selectedEntities.reduce<{
		problems: string[];
	}>(
		(prev, item) => {
			switch (item.type) {
				case 'problem':
					prev.problems.push(item.data.id);
					break;
			}
			return prev;
		},
		{problems: []}
	);

/**
 * Хук для экспорта обращения  или его нарушений в зависимости от того, что выбрано
 *
 * @param objectId id объекта
 * @param selectedEntities выбранные нарушения/осмотры
 */
export function useWarrantyClaimCombinedExport(
	objectId: string,
	selectedEntities: IWarrantyClaimEntity[]
): [React.ReactNode, (id: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const destinations = useDestinations(selectedEntities);
	const {mutateAsync: exportProblems} = useExportProblems();
	const [isOpen, setOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (template.destination === ExportTemplatesDestinationId.WARRANTY_CLAIM) {
				if (exportingId) {
					const {problems} = splitSelectedEntities(selectedEntities);
					await dispatch(
						exportWarrantyClaim(
							template.id,
							createDocument,
							email,
							objectId,
							exportingId,
							problems
						)
					);
				}
			} else if (template.destination === ExportTemplatesDestinationId.PROBLEMS) {
				const selected = selectedEntities.map(item => item.data.id);
				await exportProblems({
					templateId: template.id,
					objectId,
					createDocument,
					email,
					filters: undefined,
					sort: undefined,
					selected
				});
			}
			setOpen(false);
		},
		[exportingId, selectedEntities, dispatch, objectId, exportProblems]
	);

	const dialog = (
		<ExportDialog
			pageKey="warranty-claim"
			destinations={destinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
