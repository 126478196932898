import {memo, useCallback} from 'react';
import {InlineButton, Select2, SelectDialog, SelectOption, Tag} from '@tehzor/ui-components';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useSpaceIndicatorsAsArray} from '@src/core/hooks/queries/spaceIndicatorsSets/hooks';
import {useEditSpaceIndicators} from '@src/core/hooks/mutations/spaces/useEditSpaceIndicators';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

interface IIndicatorsSelectProps {
	objectId: string;
	spaceId: string;
	value?: string[];
}

const editIcon = <i className="tz-edit-20" />;

export const IndicatorsSelect = memo(({objectId, spaceId, value}: IIndicatorsSelectProps) => {
	const {t} = useTranslation();

	const {data: object} = useObject(objectId);
	const {data: indicators} = useSpaceIndicatorsAsArray(object);
	const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
	const online = useAppSelector(s => s.offlineMode.networkStatus);


	const editSpaceIndicators = useEditSpaceIndicators(objectId);

	const handleChange = useCallback(
		(v: string[]) => {
			editSpaceIndicators({objectId, pageObjectId, spaceId, indicators: v, online});
		},
		[objectId, pageObjectId, spaceId, online, editSpaceIndicators]
	);

	return (
		<SelectDialog
			title={t('spacePage.info.indicatorsSelect.title')}
			trigger={
				<InlineButton
					type="accent"
					leftIcon={editIcon}
					className="space-page__info-edit-icon"
				/>
			}
			onChange={handleChange}
			value={value}
			acceptBtnLabel={t('spacePage.info.indicatorsSelect.acceptBtnLabel')}
			rejectBtnLabel={t('spacePage.info.indicatorsSelect.rejectBtnLabel')}
		>
			<Select2 multiple>
				{indicators?.map(indicator => (
					<SelectOption
						key={indicator.id}
						itemKey={indicator.id}
						content={
							<Tag
								color={`${indicator.color}33`}
								icon={
									<div
										className="space-page__indicator-icon"
										style={{backgroundColor: indicator.color}}
									/>
								}
								label={indicator.name}
							/>
						}
					/>
				))}
			</Select2>
		</SelectDialog>
	);
});
