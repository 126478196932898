import {IconButton} from '@tehzor/ui-components';
import { memo } from 'react';

interface ISelectionClearingProps {
	onClear: () => void;
}

const closeIcon = <i className="tz-close-24"/>;

export const SelectionClearing = memo(({
	onClear
}: ISelectionClearingProps) => (
	<IconButton onClick={onClear}>
		{closeIcon}
	</IconButton>
));