import openFileInNewTab from './open-file-in-new-tab';

/**
 * Даёт пользователю возможность сохранить или открыть файл
 *
 * @param file файл
 * @param fileName название файла
 */
export default function saveOrOpenBlob(file: Blob, fileName: string) {
	const url = URL.createObjectURL(file);
	openFileInNewTab(url, fileName);
}
