import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
	IProblemsFiltersState,
	IProblemsPageSettingsState,
	IProblemsPagesSettingsState,
	IProblemsSortState
} from './interfaces';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {ITableSettings} from '@src/interfaces/ITableSettings';

const initialState: IProblemsPagesSettingsState = {};

export const getInitialStateForPage = (objectId: string): IProblemsPageSettingsState => ({
	filters: {
		objects: objectId !== 'all' ? [objectId] : undefined
	},
	sort: {
		modifiedAt: false
	},
	pageSize: 20,
	offset: 0,
	selectedRows: [],
	tableSettings: {}
});

export const problemsSlice = createSlice({
	name: 'problems',
	initialState,
	reducers: {
		changeFilters(state, action: PayloadAction<IChangeFiltersPayload<IProblemsFiltersState>>) {
			const {objectId, filters} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].filters = filters;
		},
		clearFilters(state, action: PayloadAction<IClearFiltersPayload>) {
			const {objectId} = action.payload;
			state[objectId].filters = {};
			if (objectId !== 'all') {
				state[objectId].filters = {objects: [objectId]};
			}
		},
		changeSort(state, action: PayloadAction<IChangeSortPayload<IProblemsSortState>>) {
			const {objectId, sort} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].sort = sort;
		},
		changePageSize(state, action: PayloadAction<IChangePageSizePayload>) {
			const {objectId, pageSize} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].pageSize = pageSize;
		},
		changeOffset(state, action: PayloadAction<{objectId: string; offset: number}>) {
			const {objectId, offset} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].offset = offset;
		},
		changeSelectedRows(
			state,
			action: PayloadAction<{objectId: string; selectedRows: string[]}>
		) {
			const {objectId, selectedRows} = action.payload;

			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].selectedRows = selectedRows;
		},
		changeTableSettings(
			state,
			action: PayloadAction<{objectId: string; tableSettings: ITableSettings}>
		) {
			const {objectId, tableSettings} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].tableSettings = tableSettings;
		},
		changeColumnVisible(state, action: PayloadAction<{objectId: string; columnKey: string}>) {
			const {objectId, columnKey} = action.payload;
			state[objectId].tableSettings[columnKey].isVisible =
				!state[objectId].tableSettings[columnKey].isVisible;
		},
		showAllColumn(state, action: PayloadAction<{objectId: string}>) {
			const {objectId} = action.payload;
			for (const key in state[objectId].tableSettings) {
				if (state[objectId].tableSettings.hasOwnProperty(key)) {
					state[objectId].tableSettings[key].isVisible = true;
				}
			}
		}
	}
});
export const {actions: problemsActions, reducer: problemsReducer} = problemsSlice;
