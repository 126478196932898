import {Dispatch, useCallback, useMemo} from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {CategorySelect, EditableFieldLabel} from '@tehzor/ui-components';
import {useExtractCommonCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {makeTreeData} from '@src/core/hooks/queries/categorySets/utils/makeTreeData';

interface ICategoriesSetProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: string[] | null;
	stages?: string[];
	objectIds: string[];
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	treeCheckOnlyChildren?: boolean;
}

export const CategoriesSet = <S extends {categories?: string[] | null}, E>({
	className,
	style,
	label = 'Виды работ',
	value,
	objectIds,
	stages,
	editingDispatch,
	required,
	disabled,
	hasError,
	treeCheckOnlyChildren = false
}: ICategoriesSetProps<S, E>) => {
	const {data: categoriesSets} = useExtractCommonCategoriesSetsAsArray(objectIds);
	const filteredCategoriesSets = useMemo(
		() =>
			categoriesSets?.filter(cs =>
				stages?.length && cs.stages?.length
					? cs.stages?.some(st => stages.includes(st))
					: cs
			),
		[categoriesSets, stages]
	);

	const {data: allCategories} = useExtractAllCategoriesAsArray();
	const treeData = useMemo(
		() => makeTreeData(filteredCategoriesSets, allCategories),
		[allCategories, filteredCategoriesSets]
	);

	const categories = useMemo(() => value || [], [JSON.stringify(value)]);

	const handleChange = useCallback(
		(v: string[]) => {
			editingDispatch({type: 'update', field: 'categories', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'categories'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<CategorySelect
				multiple
				treeData={treeData}
				value={categories}
				error={required && hasError ? 'Выберите вид работ' : undefined}
				disabled={disabled}
				onChange={handleChange}
				treeCheckOnlyChildren={treeCheckOnlyChildren}
			/>
		</div>
	);
};
