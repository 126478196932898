import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectOption, Select2, FilterButton} from '@tehzor/ui-components';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useCompanyInspectors} from '@src/core/hooks/queries/companies/hooks';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useTranslation} from 'react-i18next';

interface ICreatedByFilterProps {
	objectId: string;
	label?: string;
	createdBy?: string[];
}

export const CreatedByFilter = memo((props: ICreatedByFilterProps) => {
	const {label, objectId, createdBy} = props;
	const {t} = useTranslation();
	const translatedLabel = label ?? t('entitiesFilters.CreatedByFilter.label');

	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedInspectors, setSelectedInspectors] = useState(createdBy);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);
	const {data: usersMap} = useUsersAsMap();
	const {data: inspectors} = useCompanyInspectors(usersMap, objectId);
	const filteredData = useMemo(
		() => (inspectors ? flatFilter(inspectors, 'fullName', search) : []),
		[inspectors, search]
	);

	const handleApply = useCallback(() => {
		dispatch({createdBy: selectedInspectors});
		clearSearch();
	}, [selectedInspectors, dispatch, clearSearch]);

	const handleClear = useCallback(() => {
		setSelectedInspectors([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedInspectors([]);
		dispatch({createdBy: undefined});
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedInspectors(createdBy);
		clearSearch();
	}, [createdBy, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedInspectors(inspectors?.map(val => val.id));
	}, [inspectors, clearSearch]);

	useUpdateEffect(() => {
		setSelectedInspectors(createdBy);
	}, [createdBy]);

	return (
		<div>
			<TranslatedSelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				onSelectAll={handleSelectAll}
				clearButton={!!selectedInspectors?.length}
				count={selectedInspectors?.length}
				footer
				trigger={
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel(translatedLabel, createdBy, inspectors)}
						active={!!createdBy?.length}
						onClear={handleFullClear}
					/>
				}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
						type="popup"
					/>
				}
			>
				<Select2
					multiple
					value={selectedInspectors}
					onChange={setSelectedInspectors}
				>
					{filteredData.map(item => (
						<SelectOption
							dataTestId="MenuItem"
							key={item.id}
							itemKey={item.id}
							content={item.fullName}
						/>
					))}
				</Select2>
			</TranslatedSelectPopup>
		</div>
	);
});
