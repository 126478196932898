import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import { memo } from 'react';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {isBlockVisible} from '../../utils/isBlockVisible';
import classNames from 'classnames';

interface IContractorBlockProps {
	contract: IPreparedContract;
}

const contractorIcon = <i className="tz-contractor-20"/>;
const bankAccountIcon = <i className="tz-bank-account-20"/>;
const legalEntityIcon = <i className="tz-legal-entity-20"/>;
const authorizedPersonIcon = <i className="tz-user-20"/>;

const visibilityFields: Array<keyof IPreparedContract> = [
	'contractor',
	'contractorAuthorizedPerson',
	'contractorBankAccount',
	'contractorLegalEntity'
];

export const ContractorBlock = memo(({
	contract
}: IContractorBlockProps) => isBlockVisible(contract, visibilityFields) ? (
	<div className="contract__blocks-contractor">
		<div className="contract__blocks-contractor__header">
			Подрядчик
		</div>

		<EntityGrid
			className={{
				wrap: classNames(
					'contract__blocks-contractor__container', 'contract__cells'
				)
			}}
			withBorders
		>
			<EntityGridItem
				className={{
					root: 'contract__cells-item',
					header: 'contract__cells-item-header',
					content: 'contract__cells-item-content'
				}}
				label="Подрядчик"
				icon={contractorIcon}
				inline
			>
				{contract.contractor?.name}
			</EntityGridItem>

			{contract.contractorLegalEntity && (
				<EntityGridItem
					className={{
						root: 'contract__cells-item',
						header: 'contract__cells-item-header',
						content: 'contract__cells-item-content'
					}}
					label="Юр. лицо"
					icon={legalEntityIcon}
					inline
				>
					{contract.contractorLegalEntity.name}
				</EntityGridItem>
			)}

			{contract.contractorBankAccount && (
				<EntityGridItem
					className={{
						root: 'contract__cells-item',
						header: 'contract__cells-item-header',
						content: 'contract__cells-item-content'
					}}
					label="Реквизиты"
					icon={bankAccountIcon}
					inline
				>
					{contract.contractorBankAccount.accountNumber}
				</EntityGridItem>
			)}

			{contract.contractorAuthorizedPerson && (
				<EntityGridItem
					className={{
						root: 'contract__cells-item',
						header: 'contract__cells-item-header',
						content: 'contract__cells-item-content'
					}}
					label="Уполномоченное лицо"
					icon={authorizedPersonIcon}
					inline
				>
					{contract.contractorAuthorizedPerson.name}
				</EntityGridItem>
			)}
		</EntityGrid>
	</div>
) : null);