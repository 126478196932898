import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {IGetWorkingGroupTypesResponse} from '@src/api/backend/workingGroupTypes';
import {extractWorkingGroupsTypesAsArray, extractWorkingGroupsTypesById} from './selectors';

export const useWorkingGroupsTypes = <T = IGetWorkingGroupTypesResponse>(
	select?: (data: IGetWorkingGroupTypesResponse) => T
) =>
	useQuery<IGetWorkingGroupTypesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.workingGroupsTypes(),
		meta: {
			error: 'при загрузке типов рабочих групп'
		},
		select
	});

export const useWorkingGroupsTypesAsArray = () =>
	useWorkingGroupsTypes(extractWorkingGroupsTypesAsArray);

export const useWorkingGroupsTypesById = () => useWorkingGroupsTypes(extractWorkingGroupsTypesById);
