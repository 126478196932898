import {useCheckLocalProblemsEntities, useCheckProblemsEntities} from './problems/hooks';
import {useCheckInspectionsEntities} from './inspections/hooks';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {useQuery} from '@tanstack/react-query';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import IError from '@tehzor/tools/interfaces/IError';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';

export const useCheckEntities = (objectId: string, checkId: string) => {
	const {data: problems} = useCheckProblemsEntities(objectId, checkId);
	const {data: localProblems} = useCheckLocalProblemsEntities(objectId, checkId);
	const {data: inspections} = useCheckInspectionsEntities(objectId, checkId);

	return useMemo(
		() =>
			[...(localProblems || []), ...(problems || []), ...(inspections || [])].sort(
				(a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0)
			) as ICheckEntity[],
		[problems, inspections, localProblems]
	);
};

// Хук для получения одной проверки:
export const useCheck = (checkId?: string, objectId?: string) => {
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);

	return useQuery<ILinkedCheck, IError>({
		queryKey: [...checksQueryKeys.details(), checkId, objectId],
		meta: {
			error: 'при загрузке проверки',
			persist: isOfflineModeAvailable
		},
		enabled: !!checkId && !!objectId
	});
};
