import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {CellProps, Column} from 'react-table';
import {IPreparedCheck} from '@src/pages/ChecksPage/interfaces/IPreparedCheck';
import {DescriptionCell} from '@src/pages/ChecksPage/components/table/DescriptionCell';
import StatsCell from '@src/pages/ChecksPage/components/table/StatsCell';
import MenuCell from '@src/pages/ChecksPage/components/table/MenuCell';

interface IUseColumnsProps {
	isDesktop: boolean;
	objectId: string;
}

export const useChecksColumns = (props: IUseColumnsProps) => {
	const {isDesktop, objectId} = props;
	const {t} = useTranslation();

	return useMemo<Array<Column<IPreparedCheck>>>(() => {
		const withObjectCell = objectId === 'all';

		if (isDesktop) {
			const desktopColumns: Array<Column<IPreparedCheck> | undefined> = [
				{
					id: 'number',
					Header: '№',
					accessor: row => `№${row.number ?? row.localNumber}`,
					Cell: (props: CellProps<IPreparedCheck>) => DescriptionCell({...props}),
					width: 60
				},
				withObjectCell
					? {
							id: 'object',
							Header: t('columns.object'),
							accessor: row => row.object?.name,
							width: 100
					  }
					: undefined,
				{
					id: 'createdBy',
					Header: t('columns.createdBy'),
					accessor: row => row.createdBy?.fullName,
					width: 100
				},
				{
					id: 'createdAt',
					Header: t('columns.createdAt'),
					accessor: 'createdAt',
					width: 80,
					sortDescFirst: true
				},
				{
					id: 'modifiedAt',
					Header: t('columns.modifiedAt'),
					accessor: 'modifiedAt',
					width: 80,
					sortDescFirst: true
				},
				{
					id: 'stats',
					Cell: StatsCell,
					width: 120,
					defaultCanSort: false
				},
				{
					id: '_menu',
					Cell: MenuCell,
					className: 'checks-page__d-table-menu',
					width: 68,
					minWidth: 68,
					defaultCanSort: false,
					disableResizing: true,
					isNonClickable: true
				}
			];

			return desktopColumns.filter(Boolean) as Array<Column<IPreparedCheck>>;
		}

		const mobileColumns: Array<Column<IPreparedCheck> | undefined> = [
			{
				id: 'number',
				Header: '',
				accessor: row => `№${row.number ?? row.localNumber}`,
				width: 65,
				Cell: (props: CellProps<IPreparedCheck>) =>
					DescriptionCell({...props, className: 'checks-page__m-table-number_mobile'}),
				className: 'checks-page__m-table-number'
			},
			withObjectCell
				? {
						id: 'object',
						Header: t('columns.object'),
						accessor: row => row.object?.name,
						width: 100
				  }
				: undefined,
			{
				id: 'createdBy',
				Header: t('columns.createdBy'),
				accessor: row => row.createdBy?.fullName,
				width: 100
			},
			{
				Header: t('columns.createdAt'),
				accessor: 'createdAt',
				width: 100,
				sortDescFirst: true
			},
			{
				Header: t('columns.modifiedAt'),
				accessor: 'modifiedAt',
				width: 100,
				sortDescFirst: true
			},
			{
				id: 'stats',
				Cell: StatsCell,
				width: 100,
				defaultCanSort: false
			},
			{
				id: '_menu',
				Header: '',
				Cell: MenuCell,
				defaultCanSort: false,
				disableResizing: true,
				isNonClickable: true,
				className: 'checks-page__m-table-menu'
			}
		];

		return mobileColumns.filter(Boolean) as Array<Column<IPreparedCheck>>;
	}, [t, isDesktop, objectId]);
};
