import {IGetPaginateProblemsResponse} from '@src/api/backend/problems';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	extractLocalSpaceProblems,
	extractOwnerAcceptanceProblemCommentsByProblem,
	extractProblemCommentsAsArray,
	extractProblemsList,
	problemsListWithAttachments
} from '@src/core/hooks/queries/problems/selectors';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {useCallback} from 'react';
import useAppSelector from '../../useAppSelector';
import {IGetCommentsResponse} from '@src/api/backend/comments';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

export const useProblems = <T = IGetPaginateProblemsResponse>(
	objectId: string,
	select?: (data: IGetPaginateProblemsResponse) => T
) => {
	const {offset, filters, sort, pageSize} = useAppSelector(s =>
		extractProblemsPageSettings(s, objectId)
	);
	const {state} = useEntitiesFiltersCtx<IProblemsFiltersState>();
	return useQuery<IGetPaginateProblemsResponse, IError, T>({
		queryKey: [...problemsQueryKeys.list(), state || filters, sort, offset, pageSize],
		select,
		meta: {
			error: 'при загрузке списка нарушений'
		}
	});
};

export const useProblemsList = (objectId: string) => useProblems(objectId, extractProblemsList);

export const useLocalProblems = <T = IListProblem[]>(
	objectId?: string,
	select?: (data: IListProblem[]) => T
) => {
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	return useQuery({
		queryKey: [...problemsQueryKeys.localList(), objectId || 'all'],
		staleTime: 0,
		gcTime: 0,
		select,
		networkMode: 'offlineFirst',
		enabled: isOfflineModeAvailable
	});
};

export const useLocalProblemsWithAttachments = (objectId?: string) =>
	useLocalProblems(objectId, problemsListWithAttachments);

export const useExtractLocalSpaceProblems = (objectId: string, spaceId: string) => {
	const selector = useCallback(
		(data: IListProblem[]) => extractLocalSpaceProblems(data, spaceId),
		[spaceId]
	);
	return useLocalProblems(objectId, selector);
};

export const useOwnerAcceptanceProblemsReplies = <T = IGetCommentsResponse>(
	objectId: string,
	acceptanceId: string,
	select: (data: IGetCommentsResponse) => T
) => {
	const filters = {
		commentTypeId: ICommentTypeId.PROBLEM_COMMENT,
		objects: [objectId],
		ownerAcceptances: [acceptanceId],
		official: true
	};
	const sort = {createdAt: false};

	return useQuery<IGetCommentsResponse, IError, T>({
		queryKey: [...problemsQueryKeys.comments(), filters, sort, 0, 1000],
		meta: {
			error: 'при загрузке списка нарушений'
		},
		select
	});
};

export const useOwnerAcceptanceProblemsRepliesArray = (objectId: string, acceptanceId: string) =>
	useOwnerAcceptanceProblemsReplies(objectId, acceptanceId, extractProblemCommentsAsArray);

export const useOwnerAcceptanceProblemCommentsByProblem = (
	objectId: string,
	acceptanceId: string
) =>
	useOwnerAcceptanceProblemsReplies(
		objectId,
		acceptanceId,
		extractOwnerAcceptanceProblemCommentsByProblem
	);
