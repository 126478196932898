import {TOGGLE_DIALOG_VISIBILITY} from '../constants';

/**
 * Переключает видимость окна обновления приложения
 *
 * @param value true/false - показать/скрыть, undefined - поменять на противоположное значение
 */
export const toggleAppUpdateDialogVisibility = (value?: boolean) => ({
	type: TOGGLE_DIALOG_VISIBILITY,
	payload: value
});
