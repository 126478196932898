import { memo, useCallback, useMemo, useState } from 'react';
import {useUpdateEffect} from 'react-use';
import {
	SelectOption,
	Select2,
	FilterButton,
	SelectPopup,
	SelectSearch
} from '@tehzor/ui-components';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';

interface ICompaniesFilterProps {
	label?: string;
	companies?: string[];
	isUUID?: boolean;
}

export const CompaniesFilter = memo((props: ICompaniesFilterProps) => {
	const {label = 'Компания', companies, isUUID = false} = props;

	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedCompanies, setSelectedCompanies] = useState(companies);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const {data: companiesData}  = useCompaniesAsArray();

	const filteredData = useMemo(
		() => companiesData ? flatFilter(companiesData, 'name', search) : [],
		[search, companiesData]
	);

	const handleApply = useCallback(() => {
		dispatch({companies: selectedCompanies});
		clearSearch();
	}, [selectedCompanies, dispatch, clearSearch]);

	const handleClear = useCallback(() => {
		setSelectedCompanies([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedCompanies([]);
		dispatch({companies: undefined});
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedCompanies(companies);
		clearSearch();
	}, [companies, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedCompanies(companiesData?.map(val => val.id));
	}, [companiesData, clearSearch]);

	useUpdateEffect(() => {
		setSelectedCompanies(companies);
	}, [companies]);

	const btnLabel = makeFilterLabel(label, companies, companiesData, false, true);

	return (
		<div>
			<SelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				onSelectAll={handleSelectAll}
				clearButton={!!selectedCompanies?.length}
				count={selectedCompanies?.length}
				footer
				trigger={
					<FilterButton
						className="entities-filters__item"
						label={btnLabel}
						active={!!companies?.length}
						onClear={handleFullClear}
					/>
				}
				search={
					<SelectSearch
						value={search}
						onChange={setSearch}
						type="popup"
					/>
				}
			>
				<Select2
					multiple
					value={selectedCompanies}
					onChange={setSelectedCompanies}
				>
					{filteredData?.map(item => {
						const itemKey = isUUID && item?.uuid ? item.uuid : item.id;
						return (
							<SelectOption
								key={itemKey}
								itemKey={itemKey}
								content={item.name}
							/>
						);
					})}
				</Select2>
			</SelectPopup>
		</div>
	);
});
