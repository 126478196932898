import * as types from './constants';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {ICheckListsSortState} from './reducers';

/**
 * Изменяет сортировку
 *
 * @param objectId id объекта
 * @param sort параметры сортировки
 */
export const changeSort = (sort: ICheckListsSortState) => ({
	type: types.CHANGE_SORT,
	payload: {
		sort
	} as IChangeSortPayload<ICheckListsSortState>
});
