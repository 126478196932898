import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {IGetUnitsResponse} from '@src/api/backend/units';
import {TFunction} from 'i18next';

interface IWorkAmount {
	value?: string | undefined;
	unitId?: string | undefined;
	unitName?: string | undefined;
}
export const createWorkAmountDifference = (
	prev: IWorkAmount | undefined,
	next: IWorkAmount | undefined,
	units: IGetUnitsResponse | undefined,
	t: TFunction<'translation', undefined>
): IHistoryData => {
	const prevMeasurementUnit = prev?.unitId ? units?.byId[prev.unitId].name : '';
	const nextMeasurementUnit = next?.unitId ? units?.byId[next.unitId].name : '';
	return {
		prev: prev ? `${prev.value || ''} ${prevMeasurementUnit}` : undefined,
		next: next ? `${next.value || ''} ${nextMeasurementUnit}` : undefined,
		type: HistoryTypeId.DESCRIPTION,
		name: t('createDifference.createWorkAmountDifference')
	};
};
