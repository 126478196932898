import {Responsibles} from '@src/components/viewFields/Responsibles/Responsibles';
import {useProblemResponsiblePermissions} from '@src/core/hooks/permissions/useProblemResponsiblePermissions';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';
import {getPerformersDelegationHistoriesByProblem} from '@src/store/modules/pages/problem/actions/delegationHistories';
import {editProblemPerformers} from '@src/store/modules/pages/problem/actions/editPerformers';
import {extractProblemPerformersDelegationHistoriesAsArray} from '@src/store/modules/pages/problem/selectors/delegationHistories';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface IProblemPerformersProps {
	problem: IPreparedProblem;
	settings?: IEntitySettings;
}

export const ProblemPerformers = ({problem, settings}: IProblemPerformersProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const histories = useAppSelector(extractProblemPerformersDelegationHistoriesAsArray);

	const {id, objectId, stage, performersActiveGroup, performersInitialGroup, performers} =
		problem;

	const {canEditPerformers, onlyPerformersActiveGroup} = useProblemResponsiblePermissions(
		objectId,
		problem
	);

	const ruleParams: IRuleParams = useMemo(
		() => ({
			categoryId: problem.categoryId,
			planId: problem.plan?.id
		}),
		[problem.categoryId, problem.plan?.id]
	);

	const getDelegationHistories = useCallback(
		() => dispatch(getPerformersDelegationHistoriesByProblem(objectId, id)),
		[objectId, id]
	);

	const handleChange = useCallback(
		async (users: string[], group?: string) => {
			await dispatch(editProblemPerformers(objectId, id, users, group));
		},
		[objectId, id]
	);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			workingGroupType={WorkingGroupTypeId.PERFORMERS}
			respUsers={performers}
			activeGroup={performersActiveGroup}
			initialGroup={performersInitialGroup}
			canEditResponsibles={canEditPerformers}
			onlyActiveGroup={onlyPerformersActiveGroup}
			ruleParams={ruleParams}
			histories={histories}
			required={settings?.builtin.performers.required}
			label={t('problemPage.info.performers.label')}
			delegationDialogLabel={t('components.delegationDialog.performers.title')}
			historyAutomaticActionLabelIn={t('problemPage.info.performers.historyAutomaticActionLabelIn')}
			historyAutomaticActionLabelTitle={t('problemPage.info.performers.historyAutomaticActionLabelTitle')}
			historyUserActionLabelIn={t('problemPage.info.performers.historyUserActionLabelIn')}
			historyUserActionLabelTitle={t('problemPage.info.performers.historyUserActionLabelTitle')}
			onResponsiblesChange={handleChange}
			getDelegationHistories={getDelegationHistories}
		/>
	);
};
