import { useCallback } from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';

type ICustomSelectionRowProps = ISelectionRowProps<IConvertedSpace>;

export const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {toggleAllRowsSelected} = props;

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<Button
			label="Сбросить"
			type="cancel"
			onClick={clearSelection}
		/>
	);
};
