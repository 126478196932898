import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Button, EntityGrid, EntityGridItem, ErrorBoundary, Plate} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import { useCallback, useState } from 'react';
import DevicesHistoryDialog from './components/DevicesHistoryDialog/DevicesHistoryDialog';
import {getDevices} from '../../store/modules/entities/devices/actions/get';
import {OfflineModeSettings} from './components/OfflineModeSettings';
import './ProfilePage.less';
import {useMount} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ProfileMenu} from './components/ProfileMenu';
import {SubscribeSettings} from './components/SubscribeSettings';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {useTranslation} from "react-i18next";

const editIcon = <i className="tz-edit-16" />;
const mailIcon = <i className="tz-mail-20" />;
const userIcon = <i className="tz-user-20" />;
const nameIcon = <i className="tz-name-20" />;
const offlineModeIcon = <i className="tz-offline-mode-20" />;
const infoIcon = <i className="tz-info-20" />;
export const ProfilePage = () => {
	const {pushPath} = useChangePath();
	const {t} = useTranslation()
	const user = useAppSelector(s => s.auth.profile);
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	const isDesktop = useIsDesktop();
	const dispatch = useAppDispatch();

	const [isOpenDevicesHistoryDialog, setIsOpenDevicesHistoryDialog] = useState(false);

	const handleCloseDevicesHistoryDialog = useCallback(
		() => setIsOpenDevicesHistoryDialog(false),
		[]
	);

	const handleOpenDevicesHistoryDialog = useCallback(
		() => setIsOpenDevicesHistoryDialog(true),
		[]
	);

	const handleEdit = useCallback(() => {
		pushPath('/profile/edit');
	}, []);

	const goToOfflineJournal = useCallback(() => {
		pushPath('/profile/offline-journal');
	}, []);

	useMount(() => {
		void dispatch(getDevices());
	});

	useAppHeader(
		{
			title: t("profilePage.title"),
			showBackBtn: true,
			mobileRightButtons: <ProfileMenu onEdit={handleEdit} />
		},
		[isDesktop,t]
	);

	return (
		<div className="page-cont profile-page">
			<ErrorBoundary
				label="ProfilePageEdit"
				className="profile-page__action error"
			>
				{isDesktop && (
					<div className="profile-page__action">
						<Button
							className="profile-page__action-button"
							type="accent-blue"
							label={t("profilePage.edit")}
							leftIcon={editIcon}
							onClick={handleEdit}
						/>
					</div>
				)}
			</ErrorBoundary>

			<Plate>
				<ErrorBoundary label="ProfilePagePlate">
					<EntityGrid
						className={{wrap: 'profile-page__entities-grid'}}
						withBorders
					>
						{user?.email && (
							<EntityGridItem
								icon={mailIcon}
								label="Email"
								inline
							>
								{user.email}
							</EntityGridItem>
						)}

						{user?.lastName && (
							<EntityGridItem
								icon={userIcon}
								label={t("profilePage.info.lastName")}
								inline
							>
								{user.lastName}
							</EntityGridItem>
						)}

						{user?.firstName && (
							<EntityGridItem
								icon={nameIcon}
								label={t("profilePage.info.firstName")}
								inline
							>
								{user.firstName}
							</EntityGridItem>
						)}

						{user?.middleName && (
							<EntityGridItem
								icon={nameIcon}
								label={t("profilePage.info.middleName")}
								inline
							>
								{user.middleName}
							</EntityGridItem>
						)}

						{user?.position && (
							<EntityGridItem
								icon={nameIcon}
								label={t("profilePage.info.position")}
								inline
							>
								{user.position}
							</EntityGridItem>
						)}

						<EntityGridItem className="profile-page__entities-grid-item__settings">
							<OfflineModeSettings />
							<SubscribeSettings value={user.subscribed} />
						</EntityGridItem>

						<EntityGridItem
							icon={offlineModeIcon}
							label={t("profilePage.settings.offlineActions")}
						>
							<Button
								label={t("profilePage.settings.openOfflineActions")}
								type="accent-blue"
								disabled={!isOfflineModeAvailable}
								onClick={goToOfflineJournal}
							/>
						</EntityGridItem>

						<EntityGridItem
							icon={infoIcon}
							label={t("profilePage.settings.devices")}
						>
							<Button
								label={t("profilePage.settings.openDevices")}
								type="accent-blue"
								onClick={handleOpenDevicesHistoryDialog}
							/>
							<DevicesHistoryDialog
								isOpen={isOpenDevicesHistoryDialog}
								onClose={handleCloseDevicesHistoryDialog}
							/>
						</EntityGridItem>
					</EntityGrid>
				</ErrorBoundary>
			</Plate>
		</div>
	);
};

// import React from 'react';
// import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
// import {getDevices} from '@src/store/modules/entities/devices/actions/get';
// import './ProfilePage.less';
// import {useMount} from 'react-use';
// import useAppDispatch from '@src/core/hooks/useAppDispatch';
// import {Desktop} from './components/Desktop';
// import {Mobile} from './components/Mobile';
//
// export const ProfilePage = () => {
// 	const dispatch = useAppDispatch();
//
// 	useMount(() => {
// 		void dispatch(getDevices());
// 	});
//
// 	const isDesktop = useIsDesktop();
//
// 	if (isDesktop) {
// 		return <Desktop />;
// 	}
// 	return <Mobile />;
// };