import { useCallback, useState } from 'react';
import * as React from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import {exportSpace} from '@src/store/modules/entities/space/actions';

const availableDestinations = [ExportTemplatesDestinationId.SPACE];

export function useSpaceExport(
	objectId: string,
	spaceId: string
): [React.ReactNode, (id: string, sid: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const [exportingSpaceId, setExportingSpaceId] = useState<string>();
	const [isOpen, setOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const openDialog = useCallback((id: string, sid: string) => {
		setExportingId(id);
		setExportingSpaceId(sid);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setExportingSpaceId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (exportingSpaceId && exportingId) {
				await dispatch(
					exportSpace(
						template.id,
						createDocument,
						email,
						exportingId,
						exportingSpaceId,
						undefined,
						undefined,
						undefined,
						undefined,
						undefined
					)
				);
			}
			setOpen(false);
		},
		[objectId, spaceId, exportingSpaceId]
	);

	const dialog = isOpen ? (
		<ExportDialog
			pageKey="space"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	) : null;

	return [dialog, openDialog];
}
