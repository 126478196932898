import {useStructuresList} from './useStructuresList';
import {useCallback} from 'react';
import {IGetListStructuresResponse} from '@/shared/api/structures/getStructuresList';

export const useStructureById = (objectId: string, structureId: string) => {
	const selector = useCallback(
		(data: IGetListStructuresResponse) => data.byId[structureId],
		[structureId]
	);
	return useStructuresList(objectId, selector);
};
