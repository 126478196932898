import CategoriesSetFilterPage from '@src/pages/filters/CategoriesSetFilterPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {PlannedFixDateFilterPage} from '@src/pages/filters/PlannedFixDateFilterPage';
import {InspectorsFilterPage} from '@src/pages/filters/InspectorsFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';
import {PerformersFilterPage} from '@src/pages/filters/PerformersFilterPage';
import PlansFilterPage from '@src/pages/filters/PlansFilterPage/PlansFilterPage';
import ProblemStatusesFilterPage from '@src/pages/filters/ProblemStatusesFilterPage';
import ProblemTagsSetFilterPage from '@src/pages/filters/ProblemTagSetFilterPage';
import ProblemsFiltersPage from '@src/pages/filters/ProblemsFiltersPage';
import StagesFilterPage from '@src/pages/filters/StagesFilterPage';
import {CustomFiltersPage} from '@src/pages/filters/CustomFiltersPages/CustoFiltersPage';
import {ActualFixDateFilterPage} from '@src/pages/filters/ActualFixDateFilterPage';
import {ReopenedTimesFilterPage} from "@src/pages/filters/ReopenedTimesFilterPage";

export const problemsFilterRoutes = [
	{
		path: 'plans',
		element: <PlansFilterPage />
	},
	{
		path: 'problemTags',
		element: <ProblemTagsSetFilterPage />
	},
	{
		path: 'performers',
		element: <PerformersFilterPage />
	},
	{
		path: 'categories',
		element: <CategoriesSetFilterPage />
	},
	{
		path: 'objects',
		element: <FiltersPageObjects />
	},
	{
		path: 'statuses',
		element: <ProblemStatusesFilterPage />
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},
	{
		path: 'inspectors',
		element: <InspectorsFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: 'planFixDate',
		element: <PlannedFixDateFilterPage />
	},
	{
		path: 'actualFixDate',
		element: <ActualFixDateFilterPage />
	},
	{
		path: 'stages',
		element: <StagesFilterPage />
	},
	{
		path: 'reopenedTimes',
		element: <ReopenedTimesFilterPage/>
	},
	{
		path: 'custom/:fieldKey',
		element: <CustomFiltersPage />
	},
	{
		path: '',
		element: <ProblemsFiltersPage />
	}
];
