import {IEditableContractAction, makeEmptyState} from '@src/core/hooks/states/useEditableContractState';
import {IUploadingFilesAction} from '@src/core/hooks/states/useUploadingFilesState';
import {ISavingContract} from '@src/interfaces/saving/ISavingContract';
import {Dispatch} from 'react';

export const reset = (
	dispatch: Dispatch<IEditableContractAction>,
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>,
	data?: ISavingContract
) => {
	const empty = makeEmptyState();

	dispatch({
		field: 'companyId',
		type: 'update',
		value: data?.companyId || empty.companyId
	});
	dispatch({
		field: 'contractorId',
		type: 'update',
		value: data?.contractorId || empty.contractorId
	});
	dispatch({
		field: 'companyLegalEntityId',
		type: 'update',
		value: data?.companyLegalEntityId || empty.companyLegalEntityId
	});
	dispatch({
		field: 'contractorLegalEntityId',
		type: 'update',
		value: data?.contractorLegalEntityId || empty.contractorLegalEntityId
	});
	dispatch({
		field: 'companyBankAccountId',
		type: 'update',
		value: data?.companyBankAccountId || empty.companyBankAccountId
	});
	dispatch({
		field: 'contractorBankAccountId',
		type: 'update',
		value: data?.contractorBankAccountId || empty.contractorBankAccountId
	});
	dispatch({
		field: 'companyAuthorizedPersonId',
		type: 'update',
		value: data?.companyAuthorizedPersonId || empty.companyAuthorizedPersonId
	});
	dispatch({
		field: 'contractorAuthorizedPersonId',
		type: 'update',
		value: data?.contractorAuthorizedPersonId || empty.contractorAuthorizedPersonId
	});
	dispatch({
		field: 'name', type: 'update', value: data?.name || empty.name
	});
	dispatch({
		field: 'number',
		type: 'update',
		value: data?.number || empty.number
	});
	dispatch({
		field: 'stageId',
		type: 'update',
		value: data?.stageId || empty.stageId
	});
	dispatch({
		field: 'objectIds',
		type: 'update',
		value: data?.objectIds || empty.objectIds
	});
	dispatch({
		field: 'categoryIds',
		type: 'update',
		value: data?.categoryIds || empty.categoryIds
	});
	dispatch({
		field: 'planIds',
		type: 'update',
		value: data?.planIds || empty.planIds
	});
	dispatch({
		field: 'signingDate',
		type: 'update',
		value: data?.signingDate || empty.signingDate
	});
	dispatch({
		field: 'startDate',
		type: 'update',
		value: data?.startDate || empty.startDate
	});
	dispatch({
		field: 'endDate',
		type: 'update',
		value: data?.endDate || empty.endDate
	});
	dispatch({
		field: 'priceNumber',
		type: 'update',
		value: data?.priceNumber || empty.priceNumber
	});
	dispatch({
		field: 'priceText',
		type: 'update',
		value: data?.priceText || empty.priceText
	});
	dispatch({
		field: 'fine',
		type: 'update',
		value: data?.fine || empty.fine
	});
	dispatch({
		field: 'errors', type: 'update', value: empty.errors
	});

	uploadingFilesDispatch({type: 'reset'});
};