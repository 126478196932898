import { memo } from 'react';
import * as React from 'react';
import './MenuCloseButton.less';
import classNames from 'classnames';
import IconButton from '../IconButton';

interface IMenuCloseButtonProps {
	className?: string;
	style?: React.CSSProperties;
	active?: boolean;

	onClick?(): void;
}

const MenuCloseButton = ({className, style, active, onClick}: IMenuCloseButtonProps) => (
	<IconButton
		className={classNames('menu-close-button', {'menu-close-button_active': active}, className)}
		style={style}
		onClick={onClick}
	>
		<div className="menu-close-button__cont">
			<div className="menu-close-button__line-1"/>
			<div className="menu-close-button__line-2"/>
			<div className="menu-close-button__line-3"/>
		</div>
	</IconButton>
);

MenuCloseButton.displayName = 'MenuCloseButton';

export default memo(MenuCloseButton);
