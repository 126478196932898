import ContentLoader from 'react-content-loader';

export const MobileSchemaLoader = () => (
	<ContentLoader
		style={{width: '100%', height: '100%'}}
		speed={2}
		width={600}
		height={800}
		viewBox="0 0 600 800"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="10" y="10" rx="10" ry="10" width="580" height="780" />
	</ContentLoader>
);
