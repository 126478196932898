import {IGetTaskTypesResponse} from '@src/api/backend/taskTypes';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractTaskTypesAsArray, extractTaskTypesMap} from './selectors';

export const useTaskTypes = <T = IGetTaskTypesResponse>(
	select?: (data: IGetTaskTypesResponse) => T
) =>
	useQuery<IGetTaskTypesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.taskTypes(),
		meta: {
			error: 'при загрузке типов задач'
		},
		select
	});

export const useTaskTypesAsArray = () => useTaskTypes(extractTaskTypesAsArray);
export const useTaskTypesMap = () => useTaskTypes(extractTaskTypesMap);
