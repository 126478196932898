import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

interface IObjectsPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
}

export const useObjectsPermissions = (): IObjectsPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		return {
			canView: hasPermission(user.roles, 'objectsPageView', UserRoleScopes.OBJECT),
			canAdd: hasPermission(user.roles, 'objectsAdd', UserRoleScopes.OBJECT),
			canEdit: hasPermission(user.roles, 'objectsEdit', UserRoleScopes.OBJECT),
			canDelete: hasPermission(user.roles, 'objectsDelete', UserRoleScopes.OBJECT)
		};
	}, [user]);
};
