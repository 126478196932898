import {queryClient} from '@src/api/QueryClient';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteManyCategoriesResponse {
	success: boolean;
}

/**
 * Удаляет все виды работ по id набора
 *
 * @param categoriesSetId id набора видов работ
 */
export const requestDeleteManyCategories = async (categoriesSetId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteManyCategoriesResponse>(
		`categories/deleteBySet/${categoriesSetId}`
	);
	await queryClient.invalidateQueries({queryKey: categoriesQueryKeys.list()});
	return response.data;
};
