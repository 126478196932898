import * as React from 'react';
import {EntityInfo, EntityInfoDate} from '@tehzor/ui-components';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {useDateFnsLocale} from "@src/core/hooks/translations/useDateFnsLocales";

interface ITaskInfoProps {
	className?: string;
	style?: React.CSSProperties;
	data: ITask;
	depth?: number;
	subTitle?: React.ReactNode;
}

export const TaskInfo = ({className, style, data, depth = 0, subTitle}: ITaskInfoProps) => {
	const dateFnsLocale = useDateFnsLocale();
	return (
		<EntityInfo
			className={className}
			style={style}
			title={data.name}
			subTitle={
				subTitle ?? (depth === 0 ? <EntityInfoDate value={data.createdAt} locale={dateFnsLocale}/> : undefined)
			}
		/>
	);
};
