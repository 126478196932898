import arrayToTree, {Tree} from 'array-to-tree';
import {IDndItem} from '../interfaces';

/**
 * Формирует плоский массив с заполненной глубиной и порядком элементов из дерева
 * 
 * @param items дерево элементов
 * @param parentId id родителя
 * @param depth глубина
 */
const formFlatArrayWithDepthAndOrder = <S extends IDndItem>(
	items: Array<Tree<S>>,
	parentId?: string,
	depth = 0
): S[] => {
	const flatArray: S[] = [];

	items.forEach((item, index) => {
		const {children, ...rest} = item;
		const newItem = {
			...rest,
			parentId: rest.parentId === null ? null : parentId,
			order: index + 1,
			depth
		} as S;
		flatArray.push(newItem);

		if (children) {
			flatArray.push(
				...formFlatArrayWithDepthAndOrder(children, item.id, depth + 1)
			);
		}
	});

	return flatArray;
};

/**
 * Вычисляет глубину каждого элемента в массиве
 * 
 * @param items массив элементов
 */
export const calculateDepth = <S extends IDndItem>(
	items: S[]
): S[] => {
	const treeItems = arrayToTree(items, {parentProperty: 'parentId'});
	const flatArray = formFlatArrayWithDepthAndOrder(treeItems);

	return flatArray;
};
