import {OfflineDataItem} from '@tehzor/ui-components';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {useCacheStates} from '../hooks/useCacheStates';
import {useTranslation} from "react-i18next";

export const LastUserInternalAcceptancesCacheStatus = () => {
	const {t} = useTranslation();
	const [isFetching, isError, isStale, isPaused, dataUpdatedAt] = useCacheStates(
		internalAcceptancesQueryKeys.latest()
	);
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t('components.offlineModeDialog.lastUserInternalAcceptancesCacheStatus.offlineDataItem.title')}
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={isError ? t('cacheStatusErrors.error') : isStale ? t('cacheStatusErrors.stale') : undefined}
			inProgress={isPaused || isFetching}
			progressTitle={t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default')}
		/>
	);
};
