import './CheckListsPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {Table} from '@src/pages/manage/CheckListsPage/components/table/Table.desktop';
import {PageBreadcrumbs} from '@src/pages/manage/CheckListsPage/components/PageBreadcrumbs';
import {MobileTable} from './components/table/Table.mobile';
import {DesktopAddButton} from './components/DesktopAddButton';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {useCheckListTypesAsArray} from '@src/core/hooks/queries/checkListTypes/hooks';

export const CheckListsPage = () => {
	useAppHeader({
		title: 'Чек-листы',
		mobileRightButtons: <AppUpdateMenu />
	});
	const permissions = useCheckListPermissions();

	const isDesktop = useIsDesktop();

	const {isLoading: checkListTypesIsLoading} = useCheckListTypesAsArray();

	const checkListsIsLoading = useCheckListsIsLoading();

	const loading = checkListTypesIsLoading || checkListsIsLoading;
	if (isDesktop) {
		return (
			<div className="page-cont manage-check-lists-page">
				<PageBreadcrumbs permissions={permissions} />
				<Table loading={loading} />
			</div>
		);
	}
	return (
		<div className="page-cont manage-check-lists-page_mobile">
			<PageBreadcrumbs permissions={permissions} />
			<MobileTable loading={loading} />
			{permissions.canAdd ? <DesktopAddButton /> : null}
		</div>
	);
};
