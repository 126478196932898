import { useCallback, useState } from 'react';
import * as React from 'react';
import EditableSpaceDialog from '../EditableSpaceDialog';

export function useEditableSpaceDialog(
	objectId: string,
	spaceId?: string
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = isOpen ? (
		<EditableSpaceDialog
			objectId={objectId}
			spaceId={spaceId}
			isOpen={isOpen}
			onClose={close}
		/>
	) : null;

	return [dialog, open];
}
