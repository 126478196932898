import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {makeFirstLetterLowercase} from '@tehzor/tools/utils/firstLetter';

interface IDescriptionProps {
	data: IComment;
	parent?: IComment;
	showAppeal?: boolean;
}

const Description = ({data, parent, showAppeal}: IDescriptionProps) => {
	if (!showAppeal || parent?.createdBy === undefined) {
		return <span className="problem-comment__desc-text">{data.description}</span>;
	}
	return (
		<>
			<span className="problem-comment__desc-appeal">{parent.createdBy.displayName}, </span>
			<span className="problem-comment__desc-text">
				{makeFirstLetterLowercase(data.description)}
			</span>
		</>
	);
};

export default Description;
