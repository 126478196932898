import {Reducer, useReducer} from 'react';
import {
	errorsFns,
	IEditableCommentAction,
	IEditableCommentState,
	init
} from './state';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';

export const useEditableCommentState = (comment?: IComment) =>
	useReducer<
		Reducer<IEditableCommentState, IEditableCommentAction>,
		IComment | undefined
	>(createReducer(init, errorsFns), comment, init);
