import styles from './Controls.module.less';
import {ReactNode} from 'react';

interface IControlsProps {
	children: ReactNode;
}

export const Controls = ({children}: IControlsProps) => (
	<div className={styles.wrapper}>{children}</div>
);
