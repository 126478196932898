import {EntityLinkButton, TouchablePanel} from '@tehzor/ui-components';
import {useEntitiesLinks} from '../../hooks/useEntitiesLinks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {makeCommentTitle} from '../../../../components/Comments/utils/makeCommentTitle';
import {formProblemCommentsLink} from '@tehzor/tools/utils/links/formProblemCommentsLink';
import {IPermissions} from '../../hooks/usePermissions';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';
import {useTranslation} from 'react-i18next';
import {useCommentsPermissions} from '@src/core/hooks/permissions/useCommentsPermissions';

interface IMobileLinksProps {
	objectId: string;
	problem: IPreparedProblem;
	permissions: IPermissions;
}

const MobileLinks = (props: IMobileLinksProps) => {
	const {pushPath} = useChangePath();
	const {objectId, problem, permissions} = props;
	const {t} = useTranslation(); // в том числе makeCommentTitle
	const commentsPermissions = useCommentsPermissions({
		objectId,
		createdBy: problem.createdBy,
		performers: problem.performers,
		inspectors: problem.inspectors,
		watchers: problem.watchers
	});
	const links = useEntitiesLinks(permissions, objectId, problem);
	return (
		<TouchablePanel className="problem-page__m-links">
			{(commentsPermissions.canViewComments || commentsPermissions.canViewReplies) && (
				<EntityLinkButton
					key="comments"
					className="problem-page__m-link"
					type="accent"
					leftIcon={<i className="tz-comment" />}
					label={makeCommentTitle(
						commentsPermissions.canViewComments,
						commentsPermissions.canViewReplies,
						t
					)}
					url={formProblemCommentsLink(objectId, problem.id)}
					title={t('problemPage.links.toReplyAndComments.title')}
					onClick={pushPath}
				/>
			)}

			{links.map(item => (
				<EntityLinkButton
					key={item.key}
					className="problem-page__m-link"
					leftIcon={item.icon}
					label={item.label}
					url={item.url}
					title={item.title}
					onClick={pushPath}
				/>
			))}
		</TouchablePanel>
	);
};

export default MobileLinks;
