import {IGetCustomFieldsResponse} from '@src/api/backend/fields';
import {fieldsQueryKeys} from '@src/api/cache/fields/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractCustomFieldById,
	extractCustomFieldsArray,
	extractCustomFieldsMap
} from '../selectors';
import {useFieldsPermissions} from '@/entities/FieldSettingsSets/model/hooks/useFieldsPermissions';

export const useCustomFields = <T = IGetCustomFieldsResponse>(
	select?: (data: IGetCustomFieldsResponse) => T
) => {
	const permissions = useFieldsPermissions();

	return useQuery<IGetCustomFieldsResponse, IError, T>({
		queryKey: fieldsQueryKeys.customLists(),
		meta: {
			error: 'при загрузке списка пользовательских полей'
		},
		select,
		enabled: permissions.canViewFields
	});
};

export const useCustomFieldsMap = () => useCustomFields(extractCustomFieldsMap);

export const useCustomFieldsArray = () => useCustomFields(extractCustomFieldsArray);

export const useCustomFieldById = (customFieldId: string) =>
	useCustomFields(data => extractCustomFieldById(data, customFieldId));
