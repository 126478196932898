import {CellProps} from 'react-table';
import {IPreparedExportTemplate} from '../../interfaces/IPreparedExportTemplate';
import {useCompaniesAsMap} from '@src/core/hooks/queries/companies/hooks';

export const CompanyCell = ({row}: CellProps<IPreparedExportTemplate>) => {
	const {data: companiesMap} = useCompaniesAsMap();
	if (!row.original.companyId) return null;

	return <div>{companiesMap?.get(row.original.companyId)?.name || null}</div>;
};
