import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useMutation} from '@tanstack/react-query';
import {makeCheckMoveRequest} from '@src/api/backend/check';
import {addErrorToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';

export interface IMakeTaskMoveRequestData {
	objectId: string;
	checkId: string;
	toObjectId: string;
	toSpaceId?: string;
}

export const useMoveCheck = () =>
	useMutation({
		mutationKey: checksQueryKeys.move(),
		mutationFn: ({objectId, checkId, toObjectId, toSpaceId}: IMakeTaskMoveRequestData) =>
			makeCheckMoveRequest(objectId, checkId, toObjectId, toSpaceId),
		onError: () => addErrorToast('Ошибка', 'при перемещении проверки'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.latest()});
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.details()});
			void queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.details()});
		}
	});
