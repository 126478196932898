import {useCallback} from 'react';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {DesktopMenu} from '../actions/Menu.desktop';
import {useWorkAcceptanceExport} from '@src/core/hooks/export/useWorkAcceptanceExport';
import {Button} from '@tehzor/ui-components';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useTranslation} from 'react-i18next';

interface IDesktopControlsProps {
	workAcceptance: IWorkAcceptance;
	checkLists: ICheckList[];
	objectId: string;
	permissions?: IPermissions;
}

const exportIcon = <i className="tz-export-20" />;

export const DesktopControls = ({
	workAcceptance,
	checkLists,
	objectId,
	permissions
}: IDesktopControlsProps) => {
	const checkListsIds = checkLists.map(({id}) => id);
	const [exportDialog, openExportDialog] = useWorkAcceptanceExport(objectId, checkListsIds);

	const {t} = useTranslation();

	const handleExport = useCallback(() => {
		openExportDialog(workAcceptance.id);
	}, [workAcceptance.id, openExportDialog]);

	return (
		<div className="work-acceptance-page__d-controls-right-container">
			{permissions?.canExport && (
				<Button
					type="accent-blue"
					leftIcon={exportIcon}
					label={t('workAcceptancePage.controls.exportBtn.label')}
					onClick={handleExport}
				/>
			)}
			{permissions?.canExport && exportDialog}
			<DesktopMenu
				workAcceptance={workAcceptance}
				permissions={permissions}
			/>
		</div>
	);
};
