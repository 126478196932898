import {wsConnector} from '@src/api/backend/wsConnector';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

export type IGetWorkAcceptanceResponse = IWorkAcceptance;

/**
 * Отправляет запрос на получение приёмки работ
 */
export const requestWorkAcceptance = (objectId: string, workAcceptanceId: string) => {
	const params = {objectId, workAcceptanceId};
	return wsConnector.sendAuthorizedRequest<IGetWorkAcceptanceResponse>('getWorkAcceptance', params);
};
