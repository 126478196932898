import {useQuery} from '@tanstack/react-query';
import {
	extractInternalAcceptanceInspectionsArray,
	extractInternalAcceptanceInspectionsEntities
} from './selectors';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCallback} from 'react';
import {extractInternalAcceptanceVisibility} from '@src/store/modules/settings/pages/internalAcceptance/selectors';
import {IGetListInspectionsResponse} from '@src/api/backend/inspections';

export const useInternalAcceptanceInspections = <T = IGetListInspectionsResponse>(
	objectId: string,
	acpId: string,
	select?: (data: IGetListInspectionsResponse) => T
) =>
	useQuery({
		queryKey: internalAcceptancesQueryKeys.internalAcceptanceInspections(objectId, acpId),
		select
	});

export const useInternalAcceptanceInspectionsArray = (objectId: string, acpId: string) =>
	useInternalAcceptanceInspections(objectId, acpId, extractInternalAcceptanceInspectionsArray);

export const useInternalAcceptanceInspectionsEntities = (objectId: string, acpId: string) => {
	const visibility = useAppSelector(extractInternalAcceptanceVisibility);
	const selector = useCallback(
		(data: IGetListInspectionsResponse) =>
			extractInternalAcceptanceInspectionsEntities(data, visibility),
		[visibility]
	);
	return useInternalAcceptanceInspections(objectId, acpId, selector);
};
