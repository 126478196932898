import {isElementOutViewport} from '@src/utils/isElementOutViewport';
import {useCallback, useEffect, useState} from 'react';

interface IUseScrollToOptions {
	delay?: number;
	behavior?: 'auto' | 'smooth';
}

/**
 * Хук `useScrollToId` служит для автоматического скроллинга к элементу
 *
 * @return Функцию для инициации скроллинга.
 *
 * Функция принимает два параметра:
 *   - `val` - значение, указывающее, нужно ли выполнить скроллинг.
 *   - `options` - объект с опциями скроллинга.
 */

export const useScrollToId = (id?: string) => {
	const [shouldScroll, setShouldScroll] = useState(false);
	const [options, setOptions] = useState<IUseScrollToOptions | undefined>(undefined);

	useEffect(() => {
		let timerId: ReturnType<typeof setTimeout>;
		if (shouldScroll && id) {
			const {delay = 0, behavior} = options || {};
			timerId = setTimeout(() => {
				const el = document.getElementById(id);
				if (el && isElementOutViewport(el)) {
					el.scrollIntoView({behavior});
				}
				setShouldScroll(false);
			}, delay);
		}
		return () => clearTimeout(timerId);
	}, [shouldScroll, options, id]);

	const triggerScrollToId = useCallback((val: boolean, scrollOptions?: IUseScrollToOptions) => {
		setShouldScroll(val);
		setOptions(scrollOptions);
	}, []);

	return {triggerScrollToId};
};
