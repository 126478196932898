import {useQuery} from '@tanstack/react-query';
import {IGetSpaceTypeDecorationSetsResponse} from '@src/api/backend/spaceTypeDecorationSets';
import {
	extractSpaceTypeDecorationSetsAsArray,
	extractSpaceTypeDecorationSetsForObjectAsArray
} from './selectors';
import {useCallback} from 'react';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';

export const useSpaceTypeDecorationSets = <T = IGetSpaceTypeDecorationSetsResponse>(
	select: (data: IGetSpaceTypeDecorationSetsResponse) => T,
	enabled?: boolean
) =>
	useQuery({
		queryKey: spaceTypeDecorationSetsQueryKeys.lists(),
		meta: {
			error: 'при загрузке наборов типов отделки помещений'
		},
		select,
		enabled
	});

export const useExtractSpaceTypeDecorationSetsAsArray = () =>
	useSpaceTypeDecorationSets(extractSpaceTypeDecorationSetsAsArray);

export const useExtractSpaceTypeDecorationSetsForObjectAsArray = (objectId?: string) => {
	const selector = useCallback(
		(data: IGetSpaceTypeDecorationSetsResponse) =>
			extractSpaceTypeDecorationSetsForObjectAsArray(data, objectId),
		[objectId]
	);
	return useSpaceTypeDecorationSets(selector, !!objectId);
};
