import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {TempFileLocation} from '@tehzor/tools/enums/TempFileLocation';

/**
 * Удаляет временный файл
 */
export const makeTempFilesDeleteRequest = async (
	fileId: string, location?: TempFileLocation
) => {
	const res = await httpRequests.withToken.delete<void>(
		`/temp-files/${fileId}`,
		{params: {location}}
	);

	return res.data;
};
