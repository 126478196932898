import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {extractInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/selectors';
import {formInternalAcceptanceLink, formSpaceLink} from '@tehzor/tools/utils/links';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from 'react-i18next';

/**
 * Хук для хлебных крошек от "всех объектов" до internalAcceptance
 *
 * @param objectId id конечного объекта
 */
export function useInternalAcceptanceBreadcrumbs(objectId: string) {
	const {t} = useTranslation();

	const objects = useObjectsData();
	const internalAcceptance = useAppSelector(extractInternalAcceptance);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(() => {
		const items: IBreadcrumb[] = objects
			? formObjectsChain(objects.byId, objectId, 'problems', t)
			: [];

		if (internalAcceptance) {
			if (internalAcceptance.space) {
				items.push({
					label: formSpaceTitle(
						internalAcceptance.space.name,
						internalAcceptance.space.altName,
						spaceTypes?.[internalAcceptance.space.type],
						altNamesVisible
					),
					url: formSpaceLink(objectId, internalAcceptance.space.id),
					inactive: false
				});
			} else {
				items.push({
					label: t('internalAcceptancePage.useInternalAcceptanceBreadcrumbs.label'),
					url: `/objects/${objectId}/internal-acceptances`,
					inactive: false
				});
			}
			items.push({
				label: t('internalAcceptancePage.useInternalAcceptanceBreadcrumbs.labelWithId', {
					id: internalAcceptance.number
				}),
				url: formInternalAcceptanceLink(objectId, internalAcceptance.id),
				inactive: false
			});
		}
		return items;
	}, [objectId, internalAcceptance, objects, spaceTypes]);
}
