import {IEditableContractAction, IEditableContractState} from '@src/core/hooks/states/useEditableContractState';
import {Dispatch} from 'react';

export const checkErrors = (
	state: IEditableContractState,
	dispatch: Dispatch<IEditableContractAction>
) => {
	const {
		companyId, contractorId, companyLegalEntityId, contractorLegalEntityId, name, stageId, errors
	} = state;
	let result = false;

	if (!companyId) {
		dispatch({type: 'update-error', field: 'companyId'});
		result = true;
	}
	if (!contractorId) {
		dispatch({type: 'update-error', field: 'contractorId'});
		result = true;
	}
	if (!companyLegalEntityId) {
		dispatch({type: 'update-error', field: 'companyLegalEntityId'})
		result = true;
	}
	if (!contractorLegalEntityId) {
		dispatch({type: 'update-error', field: 'contractorLegalEntityId'});
	}
	if (!name) {
		dispatch({type: 'update-error', field: 'name'});
		result = true;
	}
	if (!stageId) {
		dispatch({type: 'update-error', field: 'stageId'});
		result = true;
	}

	if (errors) {
		for (const error of Object.values(errors)) {
			result = error ? true : result;
		}
	}
	return result;
};