import {DialogMenu, Menu, MenuItem} from '@tehzor/ui-components';
import {Trigger} from './Triger/Triger';
import {Skeleton} from '@/shared/ui/MobileDropDownMenu/Skeleton/Skeleton';
import styles from './MobileDropDownMenu.module.less';

interface IMenuItem {
	id: string;
	name: string;
}

interface IMobileDropDownMenuProps {
	value?: string;
	label?: string;
	onChange?: (key: string) => void;
	items?: IMenuItem[];
	isLoading?: boolean;
}

export const MobileDropDownMenu = ({
	value,
	label,
	onChange,
	items,
	isLoading = false
}: IMobileDropDownMenuProps) => {
	if (isLoading) {
		return <Skeleton />;
	}
	return (
		<DialogMenu
			className={styles.wrapper}
			trigger={<Trigger label={label} />}
		>
			<Menu
				value={value}
				onChange={onChange}
			>
				{items &&
					items.map(item => (
						<MenuItem
							key={item.id}
							itemKey={item.id}
						>
							{item.name}
						</MenuItem>
					))}
			</Menu>
		</DialogMenu>
	);
};
