import './OwnerAcceptanceInfoDesktopCell.less';
import {StatusSelect, TableRowSelect} from '@tehzor/ui-components';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import OwnerAcceptanceInfo from '../OwnerAcceptanceInfo';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useAsyncFn} from 'react-use';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {useEditOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useEditOwnerAcceptance';
import {
	useOwnerAcceptancesResolutions,
	useOwnerAcceptancesResolutionsArray
} from '@src/core/hooks/queries/ownerAcceptancesResolutions/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useSpaceOwnersAsMap} from '@src/core/hooks/queries/spaceOwners/hooks';

const OwnerAcceptanceInfoDesktopCell = ({row}: CellProps<{data: IOwnerAcceptance}>) => {
	const acceptance = row.original.data;
	const {id: acceptanceId, objectId, resolution} = acceptance;

	const {data: ownersMap} = useSpaceOwnersAsMap();
	const {data: resolutions} = useOwnerAcceptancesResolutionsArray();
	const {data: resolutionsMap} = useOwnerAcceptancesResolutions();
	const translatedResolutions = useTranslatedDictionary(
		'ownerAcceptanceResolutions',
		resolutionsMap
	);
	const {t} = useTranslation();
	const permissions = useOwnerAcceptancesPermissions(objectId);

	const owner = acceptance.spaceOwner && ownersMap && ownersMap[acceptance.spaceOwner.id];
	const editOwnerAcceptance = useEditOwnerAcceptance();

	const [, handleResolutionChange] = useAsyncFn(
		async (v: OwnerAcceptanceResolutionId) => {
			await editOwnerAcceptance({
				objectId,
				acceptanceId,
				fields: {resolution: v}
			});
		},
		[objectId, acceptanceId]
	);
	return (
		<div className="d-owner-acpt-info-cell">
			<ClickPreventWrap>
				<TableRowSelect
					{...row.getToggleRowSelectedProps()}
					style={{marginTop: '12px'}}
				/>
			</ClickPreventWrap>

			<div className="d-owner-acpt-info-cell__info">
				<OwnerAcceptanceInfo
					className="d-owner-acpt-info-cell__title"
					data={acceptance}
					title={t('ownerAcceptanceInfoCell.title')}
				/>

				<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
					<div style={{marginRight: '16px'}}>
						{t('ownerAcceptanceInfoCell.resolutionStatusSelect.label')}:
					</div>
					{permissions.canEdit ? (
						<ClickPreventWrap>
							<StatusSelect
								style={{maxWidth: '100%'}}
								statuses={translatedResolutions}
								disabled={!permissions.canEditStatus}
								value={resolution !== 'undefined' ? resolution : undefined}
								onChange={handleResolutionChange}
								hideArrow
								label={t('ownerAcceptanceInfoCell.resolutionStatusSelect.label')}
							/>
						</ClickPreventWrap>
					) : (
						resolutions && (
							<div className="owner-acceptance-page__resolution">
								{resolutions.find(item => item.id === resolution)?.name}
							</div>
						)
					)}
				</div>

				{acceptance.spaceOwner !== undefined && (
					<div style={{marginTop: '8px'}}>
						<div className="d-owner-acpt-info-cell__extra-info">
							{/* <div> */}
							{t('ownerAcceptanceInfoCell.owner')}: &nbsp;
							{acceptance.spaceOwner.name}
							{/* </div> */}
						</div>
						{owner?.phone !== undefined && (
							<ClickPreventWrap className="d-owner-acpt-info-cell__extra-info">
								<a href={`tel:${owner.phone}`}>{owner.phone}</a>
							</ClickPreventWrap>
						)}
						{owner?.email !== undefined && (
							<ClickPreventWrap className="d-owner-acpt-info-cell__extra-info">
								<a href={`mailto:${owner.email}`}>{owner.email}</a>
							</ClickPreventWrap>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default OwnerAcceptanceInfoDesktopCell;
