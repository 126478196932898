import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

export type StructureEntitiesVisibility = 'problems' | 'inspections';

export const getInitialState = (): StructureEntitiesVisibility[] => ['problems', 'inspections'];

export const entitiesVisibility = createReducer<StructureEntitiesVisibility[]>(getInitialState(), {
	[types.CHANGE_ENTITIES_VISIBILITY]: (state, {payload}: {payload: StructureEntitiesVisibility[]}) =>
		payload
});
