import './CheckListHistoryDialog.less';
import {ModalDialog} from '@tehzor/ui-components';
import {useEnrichedHistories} from './hooks/useEnrichedHistories';
import {TranslatedHistoryList} from '@src/components/TranslatedHistoryList';
import {useTranslation} from 'react-i18next';

interface ICheckListHistoryDialogProps {
	objectId: string;
	checkListId: string;
	stage: string;
	spaceId?: string;
	workAcceptanceId?: string;
	isOpen: boolean;
	onClose: () => void;
}

const checkListHistoryDialogClassnames = {
	root: 'check-list-history-dialog',
	content: 'check-list-history-dialog__content'
};
export const CheckListHistoryDialog = (props: ICheckListHistoryDialogProps) => {
	const {t} = useTranslation();
	const {objectId, checkListId, stage, spaceId, workAcceptanceId, isOpen, onClose} = props;
	const links = {
		checkListId,
		spaceId,
		workAcceptanceId
	};
	const stories = useEnrichedHistories(objectId, stage, links);

	return (
		<ModalDialog
			className={checkListHistoryDialogClassnames}
			open={isOpen}
			title={t('checkListHistoryDialog.title')}
			fullScreenOnMobile
			onRequestClose={onClose}
		>
			<TranslatedHistoryList
				entityName={t('checkListHistoryDialog.translatedHistoryList.entityName')}
				data={stories}
				noCreation
			/>
		</ModalDialog>
	);
};
