/**
 * Находит среди предков элемент пункта меню и берёт его data-index свойство
 *
 * @param element html-элемент
 */
export const findMenuItemIndex = (element: HTMLElement): number | undefined => {
	if (element.classList.contains('draggable-menu__item') && element.dataset.index !== undefined) {
		return +element.dataset.index;
	}
	if (element.parentElement) {
		return findMenuItemIndex(element.parentElement);
	}
	return undefined;
};
