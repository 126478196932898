import {useCallback} from 'react';
import {InspectionsPage} from './InspectionsPage';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import ActionsMobile from './components/actions/Actions.mobile';
import {useTranslation} from 'react-i18next';
import {inspectionsActions} from "@src/store/modules/settings/pages/inspections/slice";
import {IInspectionsFiltersState} from "@src/store/modules/settings/pages/inspections/interfaces";

const AllInspectionsPageWrap = (props: Record<string, unknown>) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	useAppHeader({
		title: t('header.inspections'),
		mobileRightButtons: <ActionsMobile />
	});
	const {filters} = useAppSelector(s => extractInspectionsPageSettings(s, 'all'));
	const {changeFilters, clearFilters, changeOffset} = inspectionsActions;

	const applyFilters = useCallback(
		(value: IInspectionsFiltersState) => {
			dispatch(changeFilters({objectId: 'all', filters: value}));
			dispatch(changeOffset({objectId: 'all', offset: 0}));
		},
		[dispatch, changeFilters, changeOffset]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: 'all'}));
	}, [dispatch, clearFilters]);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<InspectionsPage
				key="all-inspections"
				className="page-cont_extra-bottom-margin"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default AllInspectionsPageWrap;
