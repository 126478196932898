import {Suspense, useCallback, useState} from 'react';
import * as React from 'react';
import {MovingCheckDialog} from '../MovingCheckDialog';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';

export function useMovingCheckDialog(
	objectId: string,
	check: ILinkedCheck
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<Suspense>
			<MovingCheckDialog
				objectId={objectId}
				check={check}
				isOpen={isOpen}
				onClose={close}
			/>
		</Suspense>
	);

	return [dialog, open];
}
