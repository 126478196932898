import {memo, useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useTranslation} from 'react-i18next';

interface IAction {
	id: string;
	name: string;
}

interface IActionsFilterMobileProps {
	value?: string[];
	onChange: (selectedIdArray: IChanges) => void;
	filteredElements: IAction[];
}

export const ActionsFilterMobile = memo(
	({value, onChange, filteredElements}: IActionsFilterMobileProps) => {
		const {t} = useTranslation();
		const [selected, setSelected] = useState(value);
		const [search, setSearch] = useState('');

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleChange = useCallback(
			(selectedIdArray: string[] | undefined) => {
				onChange({actions: selectedIdArray});
			},
			[onChange]
		);

		return (
			<MobileFilter
				label={t('entitiesFilters.actionsFilterMobile.label')}
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
			/>
		);
	}
);
