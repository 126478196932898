import {PrivateRoute} from '@src/containers/PrivateRoute';
import ProfileEditPage from '@src/pages/ProfileEditPage';
import ProfilePage from '@src/pages/ProfilePage';
import {OfflineJournalPage} from '@src/pages/OfflineJournalPage';
import {offlineJournalFilterRoutes} from './offlineJournalFilterRoutes';

export const profileRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<ProfilePage/>}/>
	},
	{
		path: 'edit',
		element: <PrivateRoute element={<ProfileEditPage/>}/>
	},
	{
		path: 'offline-journal',
		element: <PrivateRoute element={<OfflineJournalPage/>}/>
	},
	{
		path: 'offline-journal/filters',
		children: offlineJournalFilterRoutes
	}
];
