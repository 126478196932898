import {useCallback} from 'react';
import {DottedLinkButton, EntityGridItem} from '@tehzor/ui-components';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {getSelectedStatus} from '@tehzor/ui-components/src/components/inputs/statuses/BaseStatusSelect/utils/getSelectedStatus';
import {getStatusColor} from '@tehzor/ui-components/src/components/inputs/statuses/StatusSelect/utils/getStatusColor';
import {usePlan, usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedLocationSelect} from '@src/components/TranslatedLocationSelect';

interface ILocationProps {
	objectId?: string;
	planId?: string;
	floor?: string;
	location?: ILocation;
	fullRow?: boolean;
	status?: string;
	hasComments?: boolean;
}

const Location = (props: ILocationProps) => {
	const {objectId, planId, floor, location, fullRow, status, hasComments} = props;

	const {t} = useTranslation();

	const {data: plans} = usePlansAsArray(objectId);
	const {data: plan} = usePlan(objectId, planId);

	const {data: statuses} = useProblemStatuses();
	const selectedStatus = getSelectedStatus(status, statuses);
	const pointsColor = getStatusColor(selectedStatus);

	const locationSelectCallback = useCallback(
		(displayLocation: string, open: () => void) => (
			<div className="problem-page__d-entities-grid-display-place">
				<DottedLinkButton
					label={displayLocation}
					onClick={open}
				/>
				{hasComments && <i className="tz-comment-on-plan-16" />}
			</div>
		),
		[hasComments]
	);

	return (
		<EntityGridItem
			icon={<i className="tz-location-20" />}
			label={t('components.viewFields.location.label')}
			fullRow={fullRow}
		>
			<div className="semi-bold">
				{t('components.viewFields.location.children.plan')}: {plan?.name}
				{plan?.name && floor ? ', ' : undefined}
				<br />
				{t('components.viewFields.location.children.floor')}: {floor || undefined}
			</div>

			<TranslatedLocationSelect
				mode="view"
				planId={planId}
				location={location}
				plans={plans}
				multiplePoints
				pointsColor={pointsColor}
			>
				{locationSelectCallback}
			</TranslatedLocationSelect>
		</EntityGridItem>
	);
};

export default Location;
