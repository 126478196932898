import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import getEmailsArray from '@src/utils/getEmailsArray';

interface ISelected {
	spaceId?: string;
	workAcceptanceId?: string;
	objectId: string;
	checkListsIds: string[];
}

/**
 * Отправляет запрос на экспорт чеклистов
 */
export const makeCheckListsExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	selected: ISelected[]
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		selected
	};
	const response = await httpRequests.exportWithToken.post<IExportResponse>(
		'check-lists/export', params
	);
	return response.data;
};
