import {useCallback, useEffect, useRef} from 'react';
import {Id, toast, ToastContainer} from 'react-toastify';
import {UploadingLoader} from '@tehzor/ui-components';
import {useIsMutating} from '@tanstack/react-query';
import './SaveDataToast.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

const SAVING_DATA_CONTAINER_ID = 'saving-data-notification-container';

export const SaveDataToast = () => {
	const toastId = useRef<Id>('');

	// Позже можно будет расширить на любые мутации
	const isMutating = useIsMutating({
		mutationKey: problemsQueryKeys.add()
	});

	const isOnline = useOnlineManager();

	const showToast = useCallback(() => {
		if (!toast.isActive(toastId.current, SAVING_DATA_CONTAINER_ID)) {
			toastId.current = toast('Сохранение данных....', {
				autoClose: false,
				closeOnClick: false,
				draggable: false,
				className: 'save-data-toast',
				containerId: SAVING_DATA_CONTAINER_ID,
				icon: <UploadingLoader />,
				hideProgressBar: true,
				closeButton: false
			});
		}
	}, []);

	const hideToast = useCallback(() => {
		if (toast.isActive(toastId.current, SAVING_DATA_CONTAINER_ID)) {
			toast.dismiss({
				id: toastId.current,
				containerId: SAVING_DATA_CONTAINER_ID
			});
		}
	}, []);

	useEffect(() => {
		if (isMutating && isOnline) {
			showToast();
		} else {
			hideToast();
		}
	}, [isMutating, showToast, hideToast, isOnline]);

	const isDesktop = useIsDesktop();

	if (!isDesktop) return null;

	return (
		<ToastContainer
			limit={1}
			position="bottom-center"
			className="save-data-toast-container"
			containerId={SAVING_DATA_CONTAINER_ID}
		/>
	);
};
