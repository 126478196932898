import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {
	IGetProblemMarkersStoriesResponse,
	requestProblemMarkersStories
} from '@src/api/backend/problemMarkersStories';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {IState} from '@src/store/modules';

export type IGetProblemMarkersStoriesPayload = IGetProblemMarkersStoriesResponse;

const request = () => ({type: types.GET_MARKERS_STORIES_REQUEST});

const success = (response: IGetProblemMarkersStoriesResponse) => ({
	type: types.GET_MARKERS_STORIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке истории маркеров');
	return {
		type: types.GET_MARKERS_STORIES_FAILURE,
		payload: error
	};
};

/**
 * Получает записи истории изменения маркеров на плане
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const getProblemMarkersStories = (objectId: string, problemId: string) =>
	checkLoaded<IState, IGetProblemMarkersStoriesResponse, ApiAction>(
		s => s.pages.problem.markersStories,
		createApiAction<IGetProblemMarkersStoriesResponse>(request, success, failure, () =>
			requestProblemMarkersStories(objectId, problemId))
	);
