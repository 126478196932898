import {useMemo} from 'react';
import {useParams} from 'react-router';
import {useSpaceTypeDecorationSet} from '../queries/SpaceTypeDecorationSet';

export const useManageSpaceTypeDecorationBreadcrumbs = () => {
	const {typeDecorationSetId} = useParams<{typeDecorationSetId?: string}>();
	const {data: typeDecoration} = useSpaceTypeDecorationSet(typeDecorationSetId);

	return useMemo(() => {
		const items = [
			{
				label: 'Наборы типов отделки',
				url: '/manage/space-type-decoration',
				nonClickable: !typeDecoration
			}
		];

		if (typeDecoration && typeDecorationSetId) {
			items.push({
				label: `Набор "${typeDecoration.name}"`,
				url: `/manage/space-type-decoration/${typeDecoration.id}`,
				nonClickable: false
			});
		}

		return items;
	}, [typeDecoration, typeDecorationSetId]);
};
