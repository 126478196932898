import {Responsibles} from '@src/components/viewFields/Responsibles/Responsibles';
import {useEditTaskResponsible} from '@src/core/hooks/mutations/task/useEditTaskResponsible';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useCallback, useMemo} from 'react';

interface ITaskResponsiblesProps {
	task: IEnrichedTask;
	settings?: IEntitySettings;
}

export const TaskResponsibles = ({task, settings}: ITaskResponsiblesProps) => {
	const {id, activeGroupLeader, activeGroup, initialGroup, createdBy, watchers, planId} = task;
	const {mutateAsync: editTaskResponsible} = useEditTaskResponsible();

	const ruleParams = useMemo(() => ({planId}), [planId]);

	const objectId = task.objectId?.id;
	const respUsers = task.respUsers?.map(user => user.id);

	const {canEditResponsible} = useTasksPermissions({
		objectId,
		activeGroupLeader: activeGroupLeader?.id,
		respUsers,
		createdBy: createdBy?.id,
		watchers
	});

	const handleChange = useCallback(
		async (users: string[], group: string | undefined) => {
			await editTaskResponsible({
				objectId: objectId || 'all',
				taskId: id,
				respUsers: users,
				activeGroup: group
			});
		},
		[objectId]
	);

	return (
		<Responsibles
			objectId={objectId || 'all'}
			ruleParams={ruleParams}
			respUsers={respUsers}
			activeGroup={activeGroup}
			initialGroup={initialGroup}
			canEditResponsibles={canEditResponsible}
			required={settings?.builtin.respUsers.required}
			onResponsiblesChange={handleChange}
		/>
	);
};
