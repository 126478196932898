import {useParams} from 'react-router-dom';

export const useStrictParams = <T extends Record<string, string | undefined> & Partial<T>>() => {
	const params = useParams<T>();
	/**
	 * TODO
	 * Проверка необязательных параметров
	 */
	return Object.fromEntries(
		Object.entries(params).map(([key, value]) => [key, value as string])
	) as T;
};
