import { useCallback, useState, memo } from 'react';
import classNames from 'classnames';
import './OfflineJournalSort.less';

export const OfflineJournalSort = memo(() => {
	const [isAscending, setAscending] = useState(false);

	const toggleReverseFloorSort = useCallback(() => {
		setAscending(prev => !prev);
	}, []);

	return (
		<i
			onClick={toggleReverseFloorSort}
			className={classNames(
					'offline-journal-floor-sort',
					'tz-table-sort-20',
					'spaces-sort-icon',
					`spaces-sort-icon_${!isAscending ? '' : 'asc'}`
			)}
		/>
	);
});
