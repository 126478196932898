/* eslint-disable @typescript-eslint/ban-types */
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import {ITwoWayTreeItem} from '../../interfaces/ITwoWayTreeItem';

interface IOptions {
	idProperty: string;
	parentIdProperty: string;
	parentProperty: string;
	childrenProperty: string;
	rootId: string;
}

function convert<T extends object, R extends T = ITwoWayTreeItem<T>>(
	groups: Record<string, T[]>,
	rootNodes: T[] | undefined,
	options: IOptions,
	parent?: T
) {
	const tree: R[] = [];
	if (rootNodes) {
		for (const node of rootNodes) {
			if (parent) {
				(node as Record<string, unknown>)[options.parentProperty] = parent;
			}
			const children = groups[node[options.idProperty] as string];
			if (children) {
				(node as Record<string, unknown>)[options.childrenProperty] = convert(groups, children, options, node);
			}
			tree.push(node as R);
		}
	}

	return tree;
}

function groupByParents<T extends object>(array: T[], options: IOptions) {
	const arrayById = keyBy(array, options.idProperty) as Record<string, Record<string, unknown>>;

	return array.reduce<Record<string, T[]>>((prev, item) => {
		let parentId = item[options.parentIdProperty] as string | undefined;
		if (!parentId || !arrayById.hasOwnProperty(parentId)) {
			parentId = options.rootId;
		}
		if (parentId && prev.hasOwnProperty(parentId)) {
			prev[parentId].push(item);
			return prev;
		}
		prev[parentId] = [item];
		return prev;
	}, {});
}

/**
 * Преобразует массив элементов в дерево
 *
 * @param data массив
 * @param opts параметры
 */
export function makeTree<T extends object, R extends T = ITwoWayTreeItem<T>>(data: T[], opts?: Partial<IOptions>) {
	const options = {
		idProperty: 'id',
		parentIdProperty: 'parentId',
		parentProperty: 'parent',
		childrenProperty: 'children',
		rootId: '0',
		...opts
	};
	if (!Array.isArray(data)) {
		throw new TypeError('Expected an array but got an invalid argument');
	}
	const grouped = groupByParents<T>(cloneDeep(data), options);
	return convert<T, R>(grouped, grouped[options.rootId], options);
}
