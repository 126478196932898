import {useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import './RegistrationDateFilterPage.less';
import {DatePickerResult, InlineDateRangePicker, LinkButton} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

export const RegistrationDateFilterPage = () => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{
		registrationDateFrom?: Date;
		registrationDateTo?: Date;
	}>();
	const [from, setFrom] = useState(state.registrationDateFrom);
	const [to, setTo] = useState(state.registrationDateTo);
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({registrationDateFrom: from, registrationDateTo: to});
		goBack();
	}, [change, from, to, goBack]);

	const handleChange = useCallback((dateFrom: Date | null, dateTo: Date | null) => {
		if (dateFrom && dateTo) {
			setFrom(dateFrom);
			setTo(dateTo);
		}
	}, []);

	const handleClear = useCallback(() => {
		setTo(undefined);
		setFrom(undefined);
	}, []);

	useAppHeader(
		{
			title: t('entitiesFilters.registrationDateFilter.label'),
			showBackBtn: true,
			mobileRightButtons:
				from && to ? (
					<LinkButton
						label={t('entitiesFilters.cancelButton.label')}
						onClick={handleClear}
					/>
				) : null
		},
		[from, to]
	);

	useRouting(!canGoBack, objectId, entity);

	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	return (
		<TranslatedFilterPage
			applyDisabled={
				isLocalStateEqual(state.registrationDateFrom, from) &&
				isLocalStateEqual(state.registrationDateTo, to)
			}
			onApplyClick={handleApply}
		>
			<DatePickerResult
				className="registration-Date-filter-page__date-picker-result"
				value1={from}
				value2={to}
				placeholder1={t('entitiesFilters.dateFilter.startDate.label')}
				placeholder2={t('entitiesFilters.dateFilter.completionDate.label')}
				isRange
				dateFormat={dateFormat}
			/>
			<InlineDateRangePicker
				valueFrom={from}
				valueTo={to}
				onChange={handleChange}
				datesOptions={datesOptions}
			/>
		</TranslatedFilterPage>
	);
};
