import {httpRequests} from '@tehzor/tools/api/httpRequests';

interface IDeleteCheckResponse {
	success: boolean;
}

/**
 * Удаляет проверку
 *
 * @param objectId id объекта
 * @param checkId id проверки
 */
export const makeCheckDeleteRequest = async (objectId: string, checkId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteCheckResponse>(
		`checks/${checkId}`,
		{params: {objectId}}
	);

	return response.data;
};
