import {makeSpaceTypeDecorationSetRequest} from '@src/api/backend/spaceTypeDecorationSets';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';

export const useAddSpaceTypeDecorationSet = () =>
	useMutation({
		mutationFn: (params: ISavingSpaceTypeDecorationSet) =>
			makeSpaceTypeDecorationSetRequest(params),
		mutationKey: spaceTypeDecorationSetsQueryKeys.add(),
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: spaceTypeDecorationSetsQueryKeys.lists()
			}),
		onError: () => addErrorToast('Ошибка', 'при добавлении набора типов отделки')
	});
