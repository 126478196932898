import { useCallback } from 'react';
import classNames from 'classnames';
import {getMonth, isSameDay, isToday} from 'date-fns';
import CalendarButton from '../../../buttons/CalendarButton';
import {IDayProps} from '../../Calendar/components/Day';

export interface ISelectableDayProps extends Partial<IDayProps> {
	valueFrom?: Date;
	valueTo?: Date;
	inProgress?: boolean;

	onClick(date: Date): void;

	onHover(isActive: boolean, date: Date): void;
}

const SelectableDay = (props: ISelectableDayProps) => {
	const {date, day, activeMonth, valueFrom, valueTo, inProgress, onClick, onHover} = props;

	const handleClick = useCallback(() => {
		if (date) {
			onClick(date);
		}
	}, [date, onClick]);

	const handleMouseEnter = useCallback(() => {
		if (date) {
			onHover(true, date);
		}
	}, [date, onHover]);

	const handleMouseLeave = useCallback(() => {
		if (date) {
			onHover(false, date);
		}
	}, [date, onHover]);

	if (!date) {
		return null;
	}

	const isActive = (valueFrom !== undefined && isSameDay(valueFrom, date)) || (valueTo !== undefined && !inProgress && isSameDay(valueTo, date));

	return (
		<CalendarButton
			key={day}
			className={classNames(
				'calendar__day',
				'inline-date-range-picker__day',
				// eslint-disable-next-line @typescript-eslint/camelcase
				{calendar__day_today: isToday(date)}
			)}
			active={isActive}
			inactive={getMonth(date) !== activeMonth}
			onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{day}
		</CalendarButton>
	);
};

export default SelectableDay;
