interface ICriticalMarkerProps {
	x: number;
	y: number;
}
export const CriticalMarker = ({x, y}: ICriticalMarkerProps) => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		x={x}
		y={y}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_12000_151890)">
			<circle
				cx="11"
				cy="11"
				r="11"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 12.4596L9.64922 12.4917C9.64922 12.4917 8.72856 19.1748 8.73282 19.2029C8.73704 19.2309 16.802 9.35364 16.802 9.35364L13.3407 9.33683C13.3407 9.33683 14.3528 3.03628 14.3456 3.00007C14.3406 2.97503 6.06823 12.4602 6 12.4596Z"
				fill="#FF0F31"
			/>
		</g>
		<defs>
			<clipPath id="clip0_12000_151890">
				<rect
					x="22"
					y="22"
					width="22"
					height="22"
					rx="11"
					transform="rotate(180 22 22)"
					fill="white"
				/>
			</clipPath>
		</defs>
	</svg>
	);
