import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IStatus} from '@tehzor/ui-components/src/components/inputs/statuses/StatusSelect/utils/IStatus';
import {useTranslation} from 'react-i18next';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';

export const useTranslateProblemStatuses = (): INormalizedData<IStatus> => {
	const {t} = useTranslation();
	const {data: statuses} = useProblemStatuses();

	return statuses
		? {
				byId: statuses.allIds.reduce((acc: Record<string, IStatus>, id: string) => {
					acc[id] = {...statuses.byId[id], name: t(`problemStatuses.${id}`)};
					return acc;
				}, {}),
				allIds: statuses.allIds
		  }
		: {
				byId: {},
				allIds: []
		  };
};
