import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';

export type IEditProblemTagResponse = IProblemTag;

/**
 * Обновляет одну метку
 *
 * @param tag данные для изменения
 */
export const makeProblemTagEditRequest = async (tag: ISavingProblemTag) => {
	const response = await httpRequests.withToken.post<IEditProblemTagResponse>(
		'/problem-tags/edit',
		tag
	);
	return response.data;
};
