import {EntityGridItem, ExternalLinkButton} from '@tehzor/ui-components';
import IReason from '@tehzor/tools/interfaces/IReason';
import {useRegulatoryStandardsMap} from '@src/core/hooks/queries/regulatoryStandards/hooks';
import {useTranslation} from 'react-i18next';

interface IReasonProps {
	value: IReason;
}

const Reason = ({value}: IReasonProps) => {
	const {t} = useTranslation();

	const {data: byId} = useRegulatoryStandardsMap();

	let link;
	if (value.regulatoryStandard) {
		const item = byId?.[value.regulatoryStandard];
		if (item) {
			link = item.link;
		}
	}

	return (
		<EntityGridItem
			icon={<i className="tz-external-link-20"/>}
			label={t('problemPage.info.reason.label')}
		>
			{link ? (
				<ExternalLinkButton
					label={value.value}
					url={link}
					icon={false}
					target="_blank"
					rel="noreferrer nofollow noopener"
				/>
			) : (
				<span className="problem-page__semi-bold">{value.value}</span>
			)}
		</EntityGridItem>
	);
};

export default Reason;
