import { memo } from 'react';
import {PageHeader} from '@tehzor/ui-components';
import {useOwnerAcceptancesPageCounterTitle} from '@src/pages/OwnerAcceptancesPage/hooks/useOwnerAcceptancesPageCounterTitle';

interface IOwnerAcceptancesPageHeaderProps {
	objectId?: string;
	count?: number;
}

export const OwnerAcceptancesPageCounter = memo(
	({objectId = 'all', count = 0}: IOwnerAcceptancesPageHeaderProps) => {
		const title = useOwnerAcceptancesPageCounterTitle(objectId, count);

		return (
			<PageHeader
				className="owner-acceptances-page__header"
				title=""
				subTitle={title}
				wrapClassName="owner-acceptances-page__header-wrap"
				leftSideClassName="owner-acceptances-page__header-left"
				rightSideClassName="owner-acceptances-page__header-right"
			/>
		);
	}
);
