import {Tree} from 'array-to-tree';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';

export const lookupParents = (
	tree: Array<Tree<ICheckItem>>,
	currentId: string,
	parentId: string
): string | undefined => {
	const parent = findTreeNode(tree, parentId);

	if (parent) {
		const currentIndex = parent.children?.findIndex(item => item.id === currentId);
		let parentIndex;

		if (parent.parentId) {
			parentIndex = lookupParents(tree, parent.id, parent.parentId);
		} else {
			parentIndex = tree.findIndex(item => item.id === parent.id);

			if (parentIndex !== undefined && parentIndex >= 0) {
				parentIndex += 1;
			}
		}

		if (currentIndex !== undefined && currentIndex >= 0) {
			return parentIndex !== undefined ||
				(typeof parentIndex === 'number' && parentIndex >= 0)
				? `${parentIndex}.${currentIndex + 1}`
				: (currentIndex + 1).toString();
		}
	}

	return undefined;
};
