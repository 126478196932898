import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export const toInternalAcceptance = (
	user: IUser,
	objectId: string,
	links: IInternalAcceptance['links'] | undefined,
	stage: ObjectStageIds | undefined,
	key: string,
	nextNumber: number
): IInternalAcceptance => {
	const briefUser: IBriefUser = {
		id: user.id,
		fullName: user.fullName,
		displayName: user.displayName
	};
	const newAcceptance: IInternalAcceptance = {
		id: key,
		localNumber: `АВТ-${nextNumber}`,
		objectId,
		links,
		stage: ObjectStageIds.ACCEPTANCE,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Number(key),
		createdBy: briefUser,
		modifiedAt: Number(key),
		modifiedBy: briefUser
	};
	return newAcceptance;
};

export const toListInternalAcceptance = (
	object: IObject,
	user: IUser,
	links: IInternalAcceptance['links'] | undefined,
	stage: ObjectStageIds | undefined,
	key: string,
	nextNumber: number
): IListInternalAcceptance => {
	const briefUser: IBriefUser = {
		id: user.id,
		fullName: user.fullName,
		displayName: user.displayName
	};
	const newAcceptance: IListInternalAcceptance = {
		id: key,
		localNumber: `АВТ-${nextNumber}`,
		objectId: object.id,
		links,
		object: {id: object.id, name: object.name},
		stage: ObjectStageIds.ACCEPTANCE,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Number(key),
		createdBy: briefUser,
		modifiedAt: Number(key),
		modifiedBy: briefUser
	};
	return newAcceptance;
};
