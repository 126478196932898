import {IGetExportTemplatesResponse} from '@src/api/backend/exportTemplates/get';
import {OutputFileType} from '@tehzor/tools/interfaces/IExportTemplate';

export const getExportTemplatesAsArray = (data: IGetExportTemplatesResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

export const getExportTemplatesAsArrayWithoutPdf = (data: IGetExportTemplatesResponse) =>
	getExportTemplatesAsArray(data).filter(item => item.outputFileType !== OutputFileType.PDF);

export const extractExportTemplateById = (
	data: IGetExportTemplatesResponse,
	exportTemplateId: string | undefined
) => {
	if (exportTemplateId) {
		return data.byId[exportTemplateId];
	}
	return undefined;
};
