import * as React from 'react';
import './EditableAttachments.less';
import classNames from 'classnames';
import {IFilesUploaderProps, withFileUploader} from '@tehzor/tools/components/withFileUploader';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {splitSavedAttachments} from './utils/splitSavedAttachments';
import {splitUploadingFiles} from './utils/splitUploadingFiles';
import {convertClassNames} from '../../../utils/convertClassNames';

interface IEditableAttachmentsProps {
	className?:
		| string
		| {
				root?: string;
				images?: string;
				files?: string;
				image?: string;
				file?: string;
		  };
	style?: React.CSSProperties;
	savedAttachments?: IAttachment[];
	disabled?: boolean;

	onSavedAttachmentsChange?: (value: IAttachment[]) => void;
	onSavedAttachmentImageClick?: (id: string) => void;
	onUploadingImageClick?: (id: string, url: string) => void;
}

export const EditableAttachments = withFileUploader<
	IEditableAttachmentsProps &
		IFilesUploaderProps & {
			reloadFile?: (key: string) => void;
			deleteFile?: (key: string) => void;
		}
>(props => {
	const {
		className,
		style,
		uploadingFiles: files,
		savedAttachments,
		disabled,
		onSavedAttachmentImageClick,
		onUploadingImageClick,
		deleteFile,
		reloadFile,
		onSavedAttachmentsChange
	} = props;

	/**
	 * Удаляет сохранённое вложение из списка
	 *
	 * @param id id вложения
	 */
	const handleSavedAttachmentDelete = (id: string) => {
		if (onSavedAttachmentsChange && savedAttachments) {
			onSavedAttachmentsChange(savedAttachments.filter(item => item.id !== id));
		}
	};

	const classes = convertClassNames(className);

	const [savedImages, savedFiles] = splitSavedAttachments(
		savedAttachments,
		disabled,
		classes.image,
		classes.file,
		handleSavedAttachmentDelete,
		onSavedAttachmentImageClick
	);

	const [uploadingImages, uploadingFiles] = splitUploadingFiles(
		files,
		disabled,
		classes.image,
		classes.file,
		deleteFile,
		reloadFile,
		onUploadingImageClick
	);

	return (
		<div
			className={classNames('editable-attachments', classes.root)}
			style={style}
		>
			{(savedImages.length > 0 || uploadingImages.length > 0) && (
				<div className={classNames('editable-attachments__images', classes.images)}>
					{savedImages}
					{uploadingImages}
				</div>
			)}

			{(savedFiles.length > 0 || uploadingFiles.length > 0) && (
				<div className={classNames('editable-attachments__files', classes.files)}>
					{savedFiles}
					{uploadingFiles}
				</div>
			)}
		</div>
	);
});
