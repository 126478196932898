import { memo } from 'react';
import {InspectionIndicator, ProblemIndicator, TaskIndicator} from '@tehzor/ui-components';
import {useProblemsCountByStatus} from '@src/pages/CheckListPage/hooks/useProblemsCountByStatus';
import {indicatorsIsEmpty} from '../../utils/indicatorsIsEmpty';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useInspectionsCount} from '../../hooks/useInspectionsCount';
import {useTaskStatuses} from '@src/core/hooks/queries/taskStatuses/hook';
import {useTasksCountByStatus} from "@src/core/hooks/tasks/useTasksCountByStatus";

interface IIndicatorsProps {
	checkListId: string;
	checkItemId?: string;
	spaceId?: string;
	workAcceptanceId?: string;
	className?: string;
}

export const Indicators = memo((props: IIndicatorsProps) => {
	const {className, spaceId, workAcceptanceId, checkListId, checkItemId} = props;

	const {data: taskStatuses} = useTaskStatuses();
	const taskCounts = useTasksCountByStatus({listId: checkListId, itemId: checkItemId, spaceId, workAcceptanceId});
	const {data: problemStatuses} = useProblemStatuses();
	const problemCounts = useProblemsCountByStatus(checkListId, checkItemId);
	const inspectionCount = useInspectionsCount(checkListId, checkItemId);
	const problemsIsEmpty = indicatorsIsEmpty(problemCounts);

	const indicatorsEmpty =
		problemsIsEmpty && indicatorsIsEmpty(taskCounts) && inspectionCount === 0;

	if (indicatorsEmpty) return null;

	return (
		<div className={className}>
			<div className="check-list-page__indicators">
				{taskCounts.map(({status, count}) =>
					count > 0 && taskStatuses ? (
						<TaskIndicator
							key={status}
							statuses={taskStatuses}
							status={status}
							count={count}
						/>
					) : null
				)}
			</div>
			{problemStatuses && !problemsIsEmpty && (
				<div className="check-list-page__indicators">
					{problemCounts.map(({status, count}) =>
						count > 0 ? (
							<ProblemIndicator
								key={status}
								statuses={problemStatuses}
								status={status}
								count={count}
							/>
						) : null
					)}
				</div>
			)}

			{inspectionCount !== 0 && (
				<div className="check-list-page__indicators">
					<InspectionIndicator count={inspectionCount} />
				</div>
			)}
		</div>
	);
});
