import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {IConvertedWorkAcceptancesData} from '@tehzor/tools/interfaces/structures/IConvertedWorkAcceptancesData';

export const convertWorkAcceptancesData = (
	workAcceptances: IListWorkAcceptance[] | undefined,
	workAcceptanceStatuses: INormalizedData<IWorkAcceptanceStatus>
): IConvertedWorkAcceptancesData[] => {
	if (!workAcceptances) {
		return [];
	}

	const result = [];

	for (const statusId of workAcceptanceStatuses.allIds) {
		const workAcceptancesCount = workAcceptances.filter(
			(wa: IListWorkAcceptance) => wa.status === statusId
		).length;

		if (workAcceptancesCount) {
			result.push({
				key: statusId,
				value: workAcceptancesCount,
				color: workAcceptanceStatuses.byId[statusId]?.color || '#00000000'
			});
		}
	}
	return result;
};
