import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';

/**
 * Отправляет запрос на экспорт гарантийных обращений
 */
export const makeWarrantyClaimsExportRequest = async (
	templateId: string,
	filters?: {},
	sort?: {[key: string]: boolean},
	selected?: string[],
	isAllSelected?: boolean,
	createDocument?: boolean
) => {
	const preparedSort = {};
	if (sort) {
		for (const key in sort) {
			if (sort.hasOwnProperty(key)) {
				preparedSort[key] = sort[key] ? 1 : -1;
			}
		}
	}
	const params = {
		templateId,
		filters,
		sort: preparedSort,
		selected,
		isAllSelected,
		timezone: getTimezone(),
		createDocument
	};
	return (await httpRequests.withToken.get<Blob>('warranty-claims/export', {params})).data;
};
