import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import IUser from '@tehzor/tools/interfaces/IUser';

export interface IAuthSessionState {
	currentUserId?: string;
	previousUserId?: string;
}

export const authSession = createReducer<IAuthSessionState>(
	{},
	{
		[types.LOGIN_REQUEST]: state => ({
			currentUserId: undefined,
			previousUserId: state.previousUserId
		}),
		[types.LOGIN_SUCCESS]: (state, {payload}: {payload: IUser}) => ({
			currentUserId: payload.id,
			previousUserId: undefined
		}),
		[types.LOGIN_FAILURE]: state => ({
			currentUserId: undefined,
			previousUserId: state.previousUserId
		}),
		[types.UPDATE_TOKENS_FAILURE]: state => ({
			currentUserId: undefined,
			previousUserId: state.currentUserId
		}),
		[types.LOGOUT]: state => ({
			currentUserId: undefined,
			previousUserId: state.currentUserId
		})
	}
);
