import {requestTaskTypes} from '@src/api/backend/taskTypes';
import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';

export const useTaskTypesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.taskTypes(), {
		queryFn: requestTaskTypes,
		staleTime: 1000 * 60 * 60 * 24, // 1 день
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
