import {IMutationErrorsMap} from '@src/interfaces/IMutationErrorsMap';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {offlineJournalActionType} from '../../enums/actionsTypes';

export const extractLocalSpaceOwnersJournal = (
	spaceOwners: ISpaceOwner[],
	errorsMap: IMutationErrorsMap
) =>
	spaceOwners.map(
		spaceOwner =>
			({
				entity: {
					entityId: spaceOwner.id,
					entityLocalNumber: spaceOwner.id,
					entityType: offlineJournalActionType.SPACE_OWNER_ADD
				},
				user: {
					userName: spaceOwner.name
				},
				transfer: {
					status: spaceOwner.transferStatus,
					statusMessage: spaceOwner.transferStatus
						? offlineModeTransferTitles[spaceOwner.transferStatus]
						: ''
				},
				error: errorsMap[spaceOwner.id]
			}) as IOfflineJournalEntity
	);
