import {convertProblem} from '@src/pages/ProblemPage/utils/convertProblem';
import {convertDocumentsStats} from '@src/utils/convertDocumentsStats';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IDocumentsStatisticsData} from '@tehzor/tools/interfaces/documentsStatistics/IDocumentsStatisticsData';
import {IMarker} from '@tehzor/tools/interfaces/markers/IMarker';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {
	dateTimeCommaSeparatedFormat,
	dateTimeLetterSeparatedFormat
} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';
import {getPermissionsForProblem} from './getPermissionsForProblem';

/**
 * Преобразовывает нарушения в необходимый для вывода формат
 *
 * @param problems массив нарушений
 * @param problemTagsMap метки нарушений
 * @param statusesMap статусы
 * @param usersMap пользователи
 * @param groupsMap группы
 * @param markers маркеры
 * @param networkStatus статус соединения
 * @param documentsStats статистика по документам
 * @param user текущий пользователь
 */
export const convertProblems = (
	problems: IListProblem[],
	problemTagsMap: Record<string, IProblemTag> | undefined,
	statusesMap: Record<string, IProblemStatus> | undefined,
	usersMap: Record<string, IBriefUser> | undefined,
	groupsMap: Record<string, IWorkingGroup> | undefined,
	markers: IMarker[] | undefined,
	networkStatus: boolean,
	documentsStats?: INormalizedData<IDocumentsStatisticsData>,
	user?: IUser
): IPreparedProblem[] =>
	problems.map(item => ({
		data: convertProblem(item, usersMap),
		id: item.id,
		objectId: item.objectId,
		object: item.object,
		links: item.links,
		number: item.number,
		localNumber: item.localNumber,
		description: item.description,
		status: {
			id: item.status,
			name: statusesMap && statusesMap[item.status]?.name
		},
		reopenedTimes: item.reopenedTimes || 0,
		documentsCount: documentsStats ? convertDocumentsStats(item.id, documentsStats) : undefined,
		transferStatus: item.transferStatus,
		plannedFixDate: item.plannedFixDate
			? format(item.plannedFixDate, dateTimeCommaSeparatedFormat)
			: '',
		plannedFixDateOriginal: item.plannedFixDate,
		actualFixDate: item.actualFixDate
			? format(item.actualFixDate, dateTimeCommaSeparatedFormat)
			: '',
		actualFixDateOriginal: item.actualFixDate,
		categoryId: item.categoryId,
		plan: item.plan?.name || '',
		floor: item.floor || '',
		location: item.displayLocation || '',
		hasLocationComments: markers?.some(
			({links, description}) => links?.problemId === item.id && description !== undefined
		),
		inspectors:
			item.inspectors?.length && usersMap
				? item.inspectors?.map(id => usersMap[id])
				: undefined,
		inspectorsActiveGroup:
			item.inspectorsActiveGroup && groupsMap
				? groupsMap[item.inspectorsActiveGroup]?.name
				: undefined,
		performers:
			item.performers?.length && usersMap
				? item.performers?.map(id => usersMap[id])
				: undefined,
		performersActiveGroup:
			item.performersActiveGroup && groupsMap
				? groupsMap[item.performersActiveGroup]?.name
				: undefined,
		stage: item.stage,
		attachment: item.attachments ? item.attachments.find(att => att.preview) : undefined,
		watchers:
			item.watchers && usersMap
				? item.watchers.map(id => usersMap[id]?.displayName).join(', ')
				: '',
		critical: item.critical,
		problemTags:
			problemTagsMap && item.problemTags?.length
				? item.problemTags?.map(id => problemTagsMap[id]?.color)
				: undefined,
		localCreatedAt: item.localCreatedAt
			? format(item.localCreatedAt, dateTimeLetterSeparatedFormat)
			: '',
		createdBy:
			item.createdBy && usersMap && usersMap[item.createdBy]
				? usersMap[item.createdBy]?.displayName || ''
				: '',
		createdAt: item.createdAt ? format(item.createdAt, dateTimeCommaSeparatedFormat) : '',
		modifiedBy:
			item.modifiedBy && usersMap && usersMap[item.modifiedBy]
				? usersMap[item.modifiedBy].displayName || ''
				: '',
		modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeCommaSeparatedFormat) : '',
		...getPermissionsForProblem(item, networkStatus, item.object?.id, user),
		customFields: item.customFields
	}));
