import {IPreparedCheckList} from '../interfaces/IPreparedCheckList';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

/**
 * Преобразовывает чеклист в необходимый для вывода формат
 *
 * @param data массив чеклистов
 */
export const convertChecklists = (data?: ICheckList[]): IPreparedCheckList[] | undefined =>
	data?.map(item => ({
		...item,
		createdBy: item.createdBy?.displayName || '',
		createdAt: item.createdAt ? format(item.createdAt, dateTimeLetterSeparatedFormat) : '',
		modifiedBy: item.modifiedBy?.displayName || '',
		modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeLetterSeparatedFormat) : ''
	}));
