import {ISavingCheck} from '@src/interfaces/saving/ISavingCheck';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditCheckResponse = ICheck;

/**
 * Изменяет проверку
 *
 * @param objectId id объекта
 * @param checkId id проверки
 * @param fields данные проверки
 */
export const makeCheckEditRequest = async (
	objectId: string,
	checkId: string,
	fields: ISavingCheck
) => {
	const response = await httpRequests.withToken.put<IEditCheckResponse>(`checks/${checkId}`, {
		objectId,
		...fields
	});

	return response.data;
};
