import { useCallback } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useCheckCombinedExport} from '../../hooks/useCheckCombinedExport';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';

interface IExportSelectedProps {
	objectId: string;
	checkId: string;
	selectedEntities: ICheckEntity[];
}

const SelectedEntitiesExport = ({objectId, checkId, selectedEntities}: IExportSelectedProps) => {
	const [exportDialog, openExportDialog] = useCheckCombinedExport(objectId, selectedEntities);

	const handleClick = useCallback(() => {
		openExportDialog(checkId);
	}, [checkId, openExportDialog]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-export-24"/>
			</IconButton>

			{exportDialog}
		</>
	);
};

export default SelectedEntitiesExport;
