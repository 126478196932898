import { Dispatch, memo } from 'react';
import {LinkButton} from '@tehzor/ui-components';
import './EditableGroupItems.less';
import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {useAddingWorkingGroupPerformerDialog} from '@src/components/AddingWorkingGroupPerformerDialog/hooks/useAddingWorkingGroupPerformerDialog';
import {Table} from '@src/pages/manage/WorkingGroupEditingPage/components/table/Table.desktop';

interface IEditablePerformersProps {
	editingState: IEditableWorkingGroupState;
	editingDispatch: Dispatch<IEditableWorkingGroupAction>;
	workingGroupId?: string;
	forbiddenAdding?: boolean;
}

export const EditablePerformers = memo(
	({
		editingState,
		editingDispatch,
		workingGroupId,
		forbiddenAdding
	}: IEditablePerformersProps) => {
		const [addingDialog, openAddingDialog] = useAddingWorkingGroupPerformerDialog(
			editingState,
			editingDispatch,
			workingGroupId
		);

		return (
			<div className="editable-group-items">
				<div className="editable-group-items__header">
					<h3>Состав членов рабочей группы</h3>
					<div className="editable-group-items__header-actions">
						{editingState.type && editingState.companyId && (
							<LinkButton
								label="Добавить"
								disabled={forbiddenAdding}
								onClick={openAddingDialog}
								leftIcon={<i className="tz-plus-20" />}
							/>
						)}
					</div>
				</div>
				<div className="editable-group-items__body">
					<Table
						editingDispatch={editingDispatch}
						editingState={editingState}
						workingGroupId={workingGroupId}
						forbiddenEditing={forbiddenAdding}
					/>
				</div>
				{addingDialog}
			</div>
		);
	}
);
