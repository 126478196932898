import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {formOwnerAcceptanceLink} from '@tehzor/tools/utils/links/formOwnerAcceptanceLink';
import {useMemo} from 'react';
import {useSpaceForWarrantyClaimBreadcrumbs} from './useSpaceForWarrantyClaimBreadcrumbs';

/**
 * Хук для хлебных крошек от "всех объектов" до передачи собственнику
 *
 * @param objectId id конечного объекта
 * @param ownerAcceptanceId id передачи
 * @param ownerAcceptance примемка
 */
export function useOwnerAcceptanceFromSpaceBreadcrumbs(
	objectId: string,
	ownerAcceptanceId: string,
	ownerAcceptance: ILinkedOwnerAcceptance
) {
	const res = useSpaceForWarrantyClaimBreadcrumbs(objectId, ownerAcceptance.space);
	return useMemo(
		() =>
			res.concat({
				label: ownerAcceptance.number
					? `Передача собственнику №${ownerAcceptance.number}`
					: 'Передача собственнику',
				url: formOwnerAcceptanceLink(objectId, ownerAcceptanceId),
				inactive: false
			}),
		[objectId, res, ownerAcceptanceId]
	);
}
