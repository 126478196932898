import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IMoveCheckItemResponse = INormalizedData<ICheckItem>;

/**
 * Перемещеает категорию чеклиста вместе со всеми вложениями
 *
 * @param checkListId id чек-листа
 * @param id  идентификатор категориии которую перемещаем
 * @param order новый порядок категории
 * @param parentId новая родительская категория
 */
export const makeCheckItemMoveRequest = async (
	checkListId: string,
	id: string,
	order: number,
	parentId: string | undefined
) => {
	const response = await httpRequests.withToken.put<IMoveCheckItemResponse>('/check-items/move', {
		checkListId,
		id,
		order,
		parentId
	});
	return response.data;
};