import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useMemo} from 'react';

export const useLegalsBreadcrumbs = (legal?: ILegalEntity) =>
	useMemo(() => {
		const items: IBreadcrumb[] = [];

		items.push({
			label: 'Юрлица',
			url: '/legals'
		});

		if (legal) {
			items.push({
				label: legal.shortName || '',
				url: `/legals/${legal.id}`
			});
		}

		return items;
	}, [legal]);
