import StatusesFilterPage from '../StatusesFilterPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractStructureTypesAsArray} from '@src/core/hooks/queries/structureTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

export const StructureTypesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const {data: structureTypes} = useExtractStructureTypesAsArray();
	const translatedStructureTypes = useTranslatedDictionaryArray(
		dictionaryKeys.structureTypes,
		structureTypes
	);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={translatedStructureTypes}
			label={t('entitiesFilters.statusesFilter.structureTypes.label')}
			filterName="types"
		/>
	);
};
