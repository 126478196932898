import {requestSpaceOwners} from '@src/api/backend/spaceOwners';
import {useQueryClient} from '@tanstack/react-query';
import {spaceOwnersQueryKeys} from '../keys';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export const useSpaceOwnersQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(spaceOwnersQueryKeys.list(), {
		queryFn: requestSpaceOwners,
		networkMode: 'offlineFirst',
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});

	queryClient.setQueryDefaults(spaceOwnersQueryKeys.localList(), {
		queryFn: () => {
			const localSpaceOwners = queryClient.getQueriesData<ISpaceOwner>({
				queryKey: spaceOwnersQueryKeys.details()
			});
			return localSpaceOwners.map(([, spaceOwner]) => spaceOwner) as ISpaceOwner[];
		},

		staleTime: 0,
		gcTime: Infinity,
		networkMode: 'offlineFirst'
	});
};
