import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../../../MobileEntitiesFilters/utils/pagesRouting';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {IConvertCustomFieldSelect} from '@tehzor/tools/interfaces/fields';
import {
	ICustomFiltersData,
	ICustomFilterSelectValue
} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';
import {useTranslation} from 'react-i18next';
import {isEmpty} from 'lodash';

interface ICustomFilterSelectMobileProps {
	entity: string;
	objectId?: string;
	fieldData: IConvertCustomFieldSelect;
	filterValue: ICustomFilterSelectValue;
	onChange: (data: ICustomFiltersData | Record<string, undefined>) => void;
	onClear: (key: string) => void;
}

export const CustomFilterSelectMobile = memo(
	({
		fieldData,
		filterValue,
		onChange,
		objectId,
		onClear,
		entity
	}: ICustomFilterSelectMobileProps) => {
		const {t} = useTranslation();
		const {values, name, key, valueTypeId} = fieldData;
		const handleExpand = useFilterPageTransition(objectId, entity, `custom/${key}`);

		const [selectedValues, setSelectedValues] = useState(filterValue);
		const [search, setSearch] = useState('');

		const filteredData = useMemo(() => {
			if (!values) return [];
			const filterItems = values.map((value, i) => ({id: value, name: value}));
			if (search === '') return filterItems;
			return filterItems.filter(value =>
				value.id.toUpperCase().includes(search.toUpperCase())
			);
		}, [values, search]);

		useUpdateEffect(() => {
			setSelectedValues(filterValue);
		}, [filterValue]);

		const handleChange = useCallback(
			(v?: string[]) => {
				if (isEmpty(v)) {
					onClear(key);
				} else {
					onChange({
						[key]: {
							valueTypeId,
							value: v
						}
					});
				}
			},
			[setSelectedValues]
		);

		return (
			<MobileFilter
				label={name}
				elements={filteredData}
				selected={selectedValues}
				linkBtnLabel={t('components.customFilterMobile.button.all.label')}
				onChange={handleChange}
				onExpand={handleExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
			/>
		);
	}
);
