import {IDeleteSpaceStatusResponse} from '@src/api/backend/spaceStatuses/delete';
import {IDeleteSpaceStatusesSetResponse} from '@src/api/backend/spaceStatusesSets/delete';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {
	convertToSave,
	IEditableSpaceStatusesSetAction,
	IEditableSpaceStatusesSetOriginal,
	IEditableSpaceStatusesSetState
} from '@src/core/hooks/states/useSpaceStatusesSetState';
import {addErrorToast} from '@src/utils/toasts';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISpaceStatusOrder} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatusOrder';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {Dispatch, useCallback} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {useAddSpaceStatusesSets} from '@src/core/hooks/mutations/spaceStatusesSets/useAddSpaceStatusesSets';
import {useEditSpaceStatusesSets} from '@src/core/hooks/mutations/spaceStatusesSets/useEditSpaceStatusesSets';
import {useDeleteSpaceStatusesSets} from '@src/core/hooks/mutations/spaceStatusesSets/useDeleteSpaceStatusesSets';
import {useAddSpaceStatus} from '@src/core/hooks/mutations/spaceStatuses/useAddSpaceStatus';
import {useDeleteSpaceStatus} from '@src/core/hooks/mutations/spaceStatuses/useDeleteSpaceStatus';
import {useEditSpaceStatus} from '@src/core/hooks/mutations/spaceStatuses/useEditSpaceStatus';
import {useDeleteManySpaceStatuses} from '@src/core/hooks/mutations/spaceStatuses/useDeleteManySpaceStatuses';
import {useEditSpaceStatusesOrder} from '@src/core/hooks/mutations/spaceStatuses/useEditSpaceStatusesOrder';

interface IUseUpdateContent {
	updateSpaceStatusesSet: () => Promise<ISpaceStatusesSet | undefined>;
	removeSpaceStatusesSet: (id: string) => Promise<IDeleteSpaceStatusesSetResponse | undefined>;
	updateSpaceStatus: (index: number) => Promise<ISpaceStatus | undefined>;
	updateSpaceStatusesOrder: (
		order: ISpaceStatusOrder[]
	) => Promise<INormalizedData<ISpaceStatus> | undefined>;
	removeSpaceStatus: (id: string) => Promise<IDeleteSpaceStatusResponse | undefined>;
}

export const useUpdateContent = (
	editingState?: IEditableSpaceStatusesSetState,
	editingDispatch?: Dispatch<IEditableSpaceStatusesSetAction>,
	defaultData?: IEditableSpaceStatusesSetOriginal
): IUseUpdateContent => {
	const {replacePath} = useChangePath();
	const {spaceStatusesSetId} = useStrictParams<{spaceStatusesSetId: string}>();
	const {mutateAsync: addSpaceStatusesSet} = useAddSpaceStatusesSets();
	const {mutateAsync: editSpaceStatusesSet} = useEditSpaceStatusesSets();
	const {mutateAsync: deleteSpaceStatusesSet} = useDeleteSpaceStatusesSets();
	const {mutateAsync: addSpaceStatus} = useAddSpaceStatus();
	const {mutateAsync: deleteSpaceStatus} = useDeleteSpaceStatus();
	const {mutateAsync: editSpaceStatus} = useEditSpaceStatus();
	const {mutateAsync: deleteManySpaceStatuses} = useDeleteManySpaceStatuses();
	const {mutateAsync: editSpaceStatusesOrder} = useEditSpaceStatusesOrder();

	const checkErrors = useCallback(
		(
			state: IEditableSpaceStatusesSetState,
			dispatch: Dispatch<IEditableSpaceStatusesSetAction>
		) => {
			const {name, companyId, objects, isLocal, defaultStatus, errors} = state;
			let result = false;

			if (!name) {
				dispatch({type: 'update-error', field: 'name'});
				result = true;
			}
			if (!companyId && !objects?.length) {
				dispatch({type: 'update-error', field: 'companyId'});
				dispatch({type: 'update-error', field: 'objects'});
				result = true;
			}
			if (isLocal && !defaultStatus) {
				dispatch({type: 'update-error', field: 'defaultStatus'});
				result = true;
			}
			if (errors) {
				for (const error of Object.values(errors)) {
					result = error ? true : result;
				}
			}

			return result;
		},
		[]
	);

	const updateSpaceStatusesSet = useCallback(async () => {
		if (!editingState || !editingDispatch || checkErrors(editingState, editingDispatch)) {
			return undefined;
		}

		const [fields] = convertToSave(editingState, defaultData);

		if (editingState.isLocal) {
			const data = await addSpaceStatusesSet({spaceStatusesSet: fields});
			replacePath(`/manage/space-statuses-sets/${data?.id}`);
			return data;
		}

		const data = await editSpaceStatusesSet({spaceStatusesSetId, fields});
		return data;
	}, [editingState, defaultData, spaceStatusesSetId]);

	const removeSpaceStatusesSet = useCallback(async (id: string) => {
		const res = await deleteSpaceStatusesSet({id});
		await deleteManySpaceStatuses({spaceStatusesSetId: id});

		return res;
	}, []);

	const updateSpaceStatus = useCallback(
		async (index: number) => {
			if (
				!editingState ||
				!editingDispatch ||
				spaceStatusesSetId === 'empty' ||
				index === -1
			) {
				return undefined;
			}

			const {statuses: editingStatuses} = editingState;
			const fields = editingStatuses[index];

			if (fields.isLocal) {
				try {
					const data = await addSpaceStatus(fields);

					return data;
				} catch (e) {
					addErrorToast(
						'Наборы статусов помещений',
						'Не удалось создать статус помещения'
					);

					return undefined;
				}
			}

			try {
				const data = await editSpaceStatus({spaceStatusId: fields.id, fields});

				return data;
			} catch (e) {
				addErrorToast('Наборы статусов помещений', 'Не удалось изменить статус помещения');

				return undefined;
			}
		},
		[editingState, spaceStatusesSetId]
	);

	const updateSpaceStatusesOrder = useCallback(
		async (order: ISpaceStatusOrder[]) => {
			if (!spaceStatusesSetId || spaceStatusesSetId === 'empty') {
				return undefined;
			}

			try {
				return editSpaceStatusesOrder({spaceStatusesSetId, order});
			} catch (e) {
				addErrorToast(
					'Наборы статусов помещений',
					'Не удалось изменить порядок статусов помещений'
				);
				return undefined;
			}
		},
		[spaceStatusesSetId]
	);

	const removeSpaceStatus = useCallback(
		async (id: string) => {
			if (spaceStatusesSetId === 'empty' || id[0] === '_') {
				return undefined;
			}

			try {
				const res = await deleteSpaceStatus({id, spaceStatusesSetId});

				return res;
			} catch (e) {
				addErrorToast('Наборы статусов помещений', 'Не удалось удалить статус помещения');

				return undefined;
			}
		},
		[spaceStatusesSetId]
	);

	return {
		updateSpaceStatusesSet,
		removeSpaceStatusesSet,
		updateSpaceStatus,
		updateSpaceStatusesOrder,
		removeSpaceStatus
	};
};
