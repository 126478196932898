import {useToggle} from 'react-use';
import {PopupPlate, IconButton} from '@tehzor/ui-components';
import {LegendContent} from './LegendContent';

const infoIcon = <i className="tz-info-24" />;
const classNames = {
	root: 'spaces-page__legend-popup',
	content: 'spaces-page__legend-popup-content'
};

export const LegendPopup = () => {
	const [isOpen, toggle] = useToggle(false);

	return (
		<PopupPlate
			className={classNames}
			trigger={({ref}) => (
				<IconButton
					ref={ref}
					type="transparent"
					active={isOpen}
					onClick={toggle}
				>
					{infoIcon}
				</IconButton>
			)}
			arrowVisible
			isOpen={isOpen}
			placement="bottom-end"
			onOutsideClick={toggle}
			onDisappear={toggle}
		>
			<LegendContent />
		</PopupPlate>
	);
};
