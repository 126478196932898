import {memo} from 'react';
import {CellProps} from 'react-table';
import styles from './FieldDescriptionCell.module.less';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';

export const FieldDescriptionCell = memo(
	({row}: Pick<CellProps<IPreparedFieldSettingsSet>, 'row'>) => {
		const field = row.original;
		return (
			<div className={styles.description}>
				<div>
					<div className={styles.descriptionName}>{field.name}</div>

					{field?.description && (
						<div className={styles.descriptionLong}>{field.description}</div>
					)}

					{field?.count && <div className={styles.descriptionType}>{field.count}</div>}
				</div>
			</div>
		);
	}
);
