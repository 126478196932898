import React from 'react';
import './InlineButtonMenu.less';
import {IMenuComponentProps, IMenuItemProps} from '../MenuItem';
import {IPopupBasicProps} from '../../containers/Popup';
import MenuLayer from '../MenuLayer';
import {InlineButton} from "../../buttons";
import {ButtonType} from "../../buttons/InlineButton/InlineButton";
import classNames from "classnames";

const icon = <i className="tz-simple-arrow-20"/>;

interface IInlineButtonMenuProps<V, M extends IMenuComponentProps<V>> {
	className?: string;
	wrapClassName?: string;
	labelClassName?: string;
	leftIconClassName?: string;
	rightIconClassName?: string;
	style?: React.CSSProperties;
	children?: React.ReactElement<M> | Array<React.ReactElement<IMenuItemProps>>;
	type?: ButtonType;
	leftIcon?: React.ReactNode;
	label?: React.ReactNode;
	disabled?: boolean;
	popupArrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
}

export const InlineButtonMenu = <V, M extends IMenuComponentProps<V>>(props: IInlineButtonMenuProps<V, M>) => {
	const {
		className,
		wrapClassName,
		labelClassName,
		leftIconClassName,
		rightIconClassName,
		style,
		children,
		type,
		leftIcon,
		label,
		disabled,
		popupArrowVisible,
		popupProps
	} = props;

	return (
		<MenuLayer
			trigger={({isOpen, toggle, ref, disabled: triggerDisabled}) => (
				<InlineButton
					className={classNames('inline-button-menu__button', className)}
					wrapClassName={classNames('inline-button-menu__button-wrap', wrapClassName)}
					labelClassName={labelClassName}
					leftIconClassName={leftIconClassName}
					rightIconClassName={classNames('inline-button-menu__button-icon', 'inline-button-menu__button-icon_right', {
						'inline-button-menu__button-icon_open': isOpen
					}, rightIconClassName)}
					style={style}
					type={type}
					leftIcon={leftIcon}
					label={label}
					rightIcon={icon}
					disabled={disabled || triggerDisabled || !children}
					onClick={toggle}
					ref={ref}
				/>
			)}
			popupArrowVisible={popupArrowVisible}
			popupProps={{placement: 'bottom-start', preferX: 'left', ...popupProps}}
		>
			{children}
		</MenuLayer>
	);
};

InlineButtonMenu.displayName = 'InlineButtonMenu';
