import {useOwnerAcceptancesMutationDefaults} from './useOwnerAcceptancesMutationDefaults';
import {useOwnerAcceptancesQueryDefaults} from './useOwnerAcceptancesQueryDefaults';

/**
 * Хук для передачи дефолтных функций в QueryClient
 */
export function useOwnerAcceptancesCacheDefaults() {
	useOwnerAcceptancesQueryDefaults();
	useOwnerAcceptancesMutationDefaults();
}
