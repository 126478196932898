import {Column} from 'react-table';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {NameCell} from './cells/NameCell';
import {SigningDateCell} from './cells/SigningDateCell';
import {CompanyCell} from './cells/CompanyCell';
import {ContractorCell} from './cells/ContractorCell';
import {ObjectsCell} from './cells/ObjectsCell';
import {CreatedCell} from './cells/CreatedCell';
import {ModifiedCell} from './cells/ModifiedCell';
import {DeleteCell} from './cells/DeleteCell';

export const getDesktopColumns = () => {
	const nameColumn: Column<IPreparedContract> = {
		id: 'name',
		Header: 'Название',
		accessor: row => row.name,
		Cell: NameCell,
		width: 160
	};

	const signingDateColumn: Column<IPreparedContract> = {
		id: 'signingDate',
		Header: 'Дата заключения',
		accessor: row => row.signingDate,
		Cell: SigningDateCell,
		width: 100
	};

	const companyColumn: Column<IPreparedContract> = {
		id: 'company',
		Header: 'Заказчик',
		accessor: row => row.company?.name,
		Cell: CompanyCell,
		width: 120
	};

	const contractorColumn: Column<IPreparedContract> = {
		id: 'contractor',
		Header: 'Подрядчик',
		accessor: row => row.contractor?.name,
		Cell: ContractorCell,
		width: 120
	};

	const objectsColumn: Column<IPreparedContract> = {
		id: 'objects',
		Header: 'Объекты',
		Cell: ObjectsCell,
		width: 100
	};

	const createdAtColumn: Column<IPreparedContract> = {
		id: 'createdAt',
		Header: 'Добавлено',
		accessor: row => row.createdAt,
		Cell: CreatedCell,
		width: 100
	};

	const modifiedAtColumn: Column<IPreparedContract> = {
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: row => row.modifiedAt,
		Cell: ModifiedCell,
		width: 100
	};

	const deleteColumn: Column<IPreparedContract> = {
		id: '_delete',
		Cell: DeleteCell,
		width: 60,
		disableResizing: true,
		isNonClickable: true
	}

	return [
		nameColumn,
		signingDateColumn,
		companyColumn,
		contractorColumn,
		objectsColumn,
		createdAtColumn,
		modifiedAtColumn,
		deleteColumn
	];
};