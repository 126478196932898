import {useMemo} from 'react';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {getSpaceRenderMenu} from '../utils/getSpaceRenderMenu';
import {ISpaceStatus} from '../utils/ISpaceStatus';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IUseSpaceStatusMenu <S extends ISpaceStatus> {
	selectedStatus?: S;
	statuses?: INormalizedData<S>;
	availableStatuses?: string[];
	stagesMap: Record<ObjectStageIds, IObjectStage>;

	onChange?: (value: string) => void;
}

export const useSpaceStatusMenu = <S extends ISpaceStatus>({
	selectedStatus, statuses, availableStatuses, stagesMap, onChange
}: IUseSpaceStatusMenu<S>) =>
	useMemo(
		() => getSpaceRenderMenu<S>(
			{selectedStatus, statuses, availableStatuses, stagesMap, onChange}
		),
		[selectedStatus, statuses, availableStatuses, stagesMap, onChange]
	);
