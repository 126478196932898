import EntitiesFilters, {CreatedByFilter, CreationDateFilter, StatusesFilter} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useCompaniesAsArray, useCompaniesContractorsAsArray} from '@src/core/hooks/queries/companies/hooks';
import {IDocumentsFiltersState} from '@src/store/modules/settings/pages/documents/reducers';

export const DocumentsFilters = () => {
	const {state} = useEntitiesFiltersCtx<IDocumentsFiltersState>();

	const {data: companies} = useCompaniesAsArray();
	const {data: contractors} = useCompaniesContractorsAsArray();

	return (
		<div className="documents__filters">
			<EntitiesFilters
				objectId="all"
				entity="documents"
			>
				{companies && companies?.length > 1 && (
					<StatusesFilter
						label="Компании"
						filterName="companyIds"
						statuses={state?.companyIds}
						allStatuses={companies}
					/>
				)}

				{contractors && contractors?.length > 1 && (
					<StatusesFilter
						label="Подрядчики"
						filterName="contractorIds"
						statuses={state?.contractorIds}
						allStatuses={contractors}
					/>
				)}

				<CreatedByFilter
					objectId="all"
					createdBy={state?.createdBy}
				/>

				<CreationDateFilter
					from={state?.createdAtFrom}
					to={state?.createdAtTo}
				/>
			</EntitiesFilters>
		</div>
	)
}