import {useEffect} from 'react';

/**
 * Предотвращает перезагрузку страницы без предупреждения
 *
 * @param isBlocking boolean-значение, при котором блокируется перезагрузка
 * @param message сообщение диалога
 */
export function usePageReloadPrevent(
	isBlocking: boolean,
	message = 'Имеются несохраненные данные, всё равно перезагрузить?'
) {
	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			const preventPageReload = (event: BeforeUnloadEvent): string | void => {
				if (isBlocking) {
					event.returnValue = message;
					return message;
				}
				return undefined;
			};

			window.addEventListener('beforeunload', preventPageReload);

			return () => {
				window.removeEventListener('beforeunload', preventPageReload);
			};
		}
		return undefined;
	}, [isBlocking]);
}
