import {EntityGridItem} from '@tehzor/ui-components';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface IDescriptionProps {
	className?: string;
	task: IEnrichedTask;
}

const infoIcon = <i className="tz-info-20" />;

const TaskType = ({className, task}: IDescriptionProps) => {
	const {t} = useTranslation();
	const translatedTaskType = useTranslatedDictionaryById(
		dictionaryKeys.taskTypes,
		task.taskType?.id
	);

	return (
		<EntityGridItem
			className={className}
			icon={infoIcon}
			label={t('taskViewPage.taskType.title')}
			fullRow
		>
			<div>{translatedTaskType}</div>
		</EntityGridItem>
	);
};

export default TaskType;
