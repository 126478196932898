import {useMemo} from 'react';
import {IInspectionStory} from '@tehzor/tools/interfaces/inspectionStories/IInspectionStory';
import {IHistoryDifference} from '@tehzor/tools/interfaces/history/IHistoryDifference';
import {IEntityState} from '@tehzor/tools/utils/reducersHandlers';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {
	createAttachmentsDifference,
	createLocationDifference,
	createDescriptionDifference,
	createCategoryDifference
} from '@src/utils/createHistoryData';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {usePlans} from '@src/core/hooks/queries/plans/hooks';
import {isAttachmentsEqual} from '@src/utils/createHistoryData/isAttachmentsEqual';
import {useUsers} from '@src/core/hooks/queries/users/hooks';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useInspectionStoriesAsArray} from '@src/core/hooks/queries/inspections/stories/hooks';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {useCategories} from '@src/core/hooks/queries/categories/hook';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';

interface ICompareProps {
	next: IInspectionStory;
	prev: IInspectionStory;
	users?: INormalizedData<IBriefUser>;
	plans?: IEntityState<IPlan>;
	categories?: IEntityState<ICategory>;
	t: TFunction<'translations', undefined>;
}

function compare(props: ICompareProps): IHistoryDifference {
	const {next, prev, users, plans, categories, t} = props;

	const data: IHistoryData[] = [];
	if (next.data.description !== prev.data.description) {
		data.push(createDescriptionDifference(prev.data.description, next.data.description, t));
	}
	if (
		plans &&
		(next.data.displayLocation !== prev.data.displayLocation ||
			next.data.floor !== prev.data.floor ||
			next.data.planId !== prev.data.planId)
	) {
		data.push(
			createLocationDifference(
				prev.data.displayLocation,
				prev.data.floor,
				prev.data.planId,
				next.data.displayLocation,
				next.data.floor,
				next.data.planId,
				plans,
				t
			)
		);
	}
	if (next.data.categoryId !== prev.data.categoryId && categories) {
		data.push(
			createCategoryDifference(prev.data.categoryId, next.data.categoryId, categories, t)
		);
	}
	if (
		next.data.attachments?.length !== prev.data.attachments?.length ||
		(next.data.attachments &&
			prev.data.attachments &&
			next.data.attachments.length === prev.data.attachments.length &&
			!isAttachmentsEqual(next.data.attachments, prev.data.attachments))
	) {
		data.push(createAttachmentsDifference(prev.data.attachments, next.data.attachments, t));
	}

	return {
		createdAt: next.createdAt,
		createdBy: next.createdBy && users ? users.byId[next.createdBy] : undefined,
		data
	};
}

export function useEnrichedHistories(
	objectId: string,
	inspectionId: string,
	isOpen: boolean
): IHistoryDifference[] {
	const {t} = useTranslation();
	const {data: histories} = useInspectionStoriesAsArray(objectId, inspectionId, {
		enabled: isOpen
	});
	const {data: users} = useUsers();
	const {data: plans} = usePlans(objectId);
	const {data: categories} = useCategories();

	return useMemo(() => {
		const result: IHistoryDifference[] = [];
		if (!histories) return result;
		if (histories[0]) {
			result.push({
				createdAt: histories[0].createdAt,
				createdBy:
					histories[0].createdBy && users ? users.byId[histories[0].createdBy] : undefined
			});
		}
		for (let i = 0; i < histories.length - 1; i++) {
			result.push(
				compare({next: histories[i + 1], prev: histories[i], users, plans, categories, t})
			);
		}
		return result;
	}, [histories, users, plans]);
}
