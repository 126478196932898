import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IInspectionStory} from '@tehzor/tools/interfaces/inspectionStories/IInspectionStory';

export type IGetInspectionStoriesResponse = INormalizedData<IInspectionStory>;

/**
 * Получает историю осмотра по id
 *
 * @param objectId id объекта
 * @param inspectionId id нарушения
 */

export const requestInspectionStories = async (objectId: string, inspectionId: string) => {
	const params = {objectId, inspectionId};
	const response = await httpRequests.withToken.get<IGetInspectionStoriesResponse>(
		'inspection-stories',
		{params}
	);
	return response.data;
};
