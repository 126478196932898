import {MutableRefObject, useCallback} from 'react';
import IUploadingFile from '../../interfaces/IUploadingFile';
import {UploadingFileStatus} from '../../enums/UploadingFileStatus';
import {DeleteTempFileFn} from '../withFileUploader';
import {QueueObject} from 'async';

/**
 * Хук. Удаляет файлы из очереди и из state
 * @param {MutableRefObject<IUploadingFile[]>} uploadsRef
 * @param {(key: string) => IUploadingFile | undefined} findFile
 * @param {DeleteTempFileFn} onDeleteTempFile
 * @param {(value: IUploadingFile[]) => void} onUploadingFilesChange
 * @param {MutableRefObject<QueueObject<string>>} queueHandler
 * @param {MutableRefObject<string[]>} processingKeys
 */
export const useDeleteFile = (
	uploadsRef: MutableRefObject<IUploadingFile[]>,
	findFile: (key: string) => IUploadingFile | undefined,
	onDeleteTempFile: DeleteTempFileFn,
	onUploadingFilesChange: (value: IUploadingFile[]) => void,
	queueHandler: MutableRefObject<QueueObject<string>>,
	processingKeys: MutableRefObject<string[]>
) =>
	useCallback(
		(key: string) => {
			const file = findFile(key);
			if (file === undefined) {
				return;
			}
			if (
				file.status === UploadingFileStatus.WAITING ||
				file.status === UploadingFileStatus.STARTED
			) {
				queueHandler.current.remove(({data}) => data === key);
				if (file.abort) {
					file.abort();
				}
			}
			if (file.status === UploadingFileStatus.FINISHED && file.tempFile !== undefined) {
				onDeleteTempFile(file.tempFile.id).catch(() => undefined);
			}
			const index = processingKeys.current.indexOf(key);
			if (index !== -1) {
				processingKeys.current.splice(index, 1);
			}
			/**
			 * TODO
			 * Синхронизация состояния.
			 * В нормальном виде должно работать так:
			 * onUploadingFilesChange(uploadingFiles.filter(item => item.key !== key));
			 * Решить в задаче: TZ-3447
			 */
			uploadsRef.current = uploadsRef.current.filter(item => item.key !== key);
			onUploadingFilesChange(uploadsRef.current);
		},
		[findFile, onUploadingFilesChange, onDeleteTempFile]
	);
