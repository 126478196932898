import {queryClient} from '@src/api/QueryClient';
import {checksDocumentsStatsQueryKeys} from '@src/api/cache/checksDocumentsStats/keys';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import getEmailsArray from '@src/utils/getEmailsArray';
import {addErrorToast} from '@src/utils/toasts';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import getTimezone from '@tehzor/tools/utils/getTimezone';

/**
 * Отправляет запрос на экспорт проверок
 */
export const makeChecksExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId?: string,
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	selected?: string[]
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId,
		filters: {
			...filters,
			selected
		},
		sort: convertSortParam(sort)
	};
	try {
		const response = await httpRequests.exportWithToken.post<IExportResponse>(
			'checks/export',
			params
		);
		await queryClient.invalidateQueries({queryKey: documentsQueryKeys.lists()});
		await queryClient.invalidateQueries({queryKey: checksDocumentsStatsQueryKeys.lists()});
		return response.data;
	} catch (error) {
		addErrorToast('Ошибка', 'не удалось выполнить экспорт');
		console.error('export error:', error);
		throw error;
	}
};
