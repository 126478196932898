import {queryClient} from '@src/api/QueryClient';
import {requestEditWorkingGroup} from '@src/api/backend/workingGroup';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';

interface IEditWorkingGroup {
	groupId: string;
	fields: ISavingWorkingGroup;
	toastMessage: messageTypes;
}

export enum messageTypes {
	editValues = 'Группа была обновлена',
	addPerformer = 'Добавлен новый исполнитель',
	deletePerformer = 'Исполнитель удален'
}

export const useEditWorkingGroup = () =>
	useMutation({
		mutationFn: (params: IEditWorkingGroup) => {
			const {groupId, fields} = params;
			return requestEditWorkingGroup(groupId, fields);
		},
		mutationKey: workingGroupsQueryKeys.edit(),
		onSuccess: (data, variables) => {
			void queryClient.invalidateQueries({queryKey: workingGroupsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});

			if (variables?.toastMessage) {
				addSuccessToast('Успешно', `${variables.toastMessage}`);
			} else {
				addSuccessToast('Успешно', messageTypes.editValues);
			}
		},
		onError: () => {
			addErrorToast('Ошибка', 'при изменении рабочей группы');
		}
	});
