import {IState} from '@src/store/modules';
import * as types from '../constants';
import {getProfile} from '@src/store/modules/auth/profile/actions';
import {AppThunkAction, AppThunkDispatch} from '@src/store/interfaces/thunk';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {invalidateDictionariesCache} from './invalidateDictionariesCache';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {companiesQueryKeys} from '@src/api/cache/companies/keys';
import {prefetchInitialResources} from '@src/components/ResourcesLoader/utils/prefetchInitialResources';

/**
 * Сбрасывает стор и вызывает методы на получение данных
 */
export const update =
	(): AppThunkAction<unknown> => async (dispatch: AppThunkDispatch, getState: () => IState) => {
		dispatch({
			type: types.TOGGLE_UPDATE_STATUS,
			payload: true
		});
		await invalidateDictionariesCache();
		dispatch({type: types.CLEAR_BEFORE_UPDATE});
		await dispatch(getProfile());

		const state = getState();
		const roles = extractUserRoles(state);

		if (!roles) {
			return;
		}

		await queryClient.invalidateQueries({queryKey: companiesQueryKeys.list()});
		await queryClient.refetchQueries({queryKey: objectsQueryKeys.list()});

		await prefetchInitialResources(roles);

		dispatch({
			type: types.TOGGLE_UPDATE_STATUS,
			payload: false
		});
	};
