import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteSpaceStatusResponse {
	success: boolean;
}

/**
 * Удаляет статус помещения по id
 *
 * @param spaceStatusId id статуса помещений
 * @param spaceStatusesSetId id набора статусов помещений
 */
export const requestDeleteSpaceStatus = async (
	spaceStatusId: string,
	spaceStatusesSetId: string
) => {
	const response = await httpRequests.withToken.delete<IDeleteSpaceStatusResponse>(
		`space-statuses/${spaceStatusId}`,
		{data: {spaceStatusesSetId}}
	);

	return response.data;
};
