import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	changeOffset,
	changeFilters
} from '@src/store/modules/settings/pages/manage/categoriesSets/actions';
import {ICategoriesSetsFiltersState} from '@src/store/modules/settings/pages/manage/categoriesSets/reducers';
import {extractCategoriesSetsPageSettings} from '@src/store/modules/settings/pages/manage/categoriesSets/selectors';
import { useCallback } from 'react';
import {Outlet} from 'react-router-dom';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const CategoriesSetsFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractCategoriesSetsPageSettings(s, objectId || 'all'));

	const dispatch = useAppDispatch();

	const handleApply = useCallback(
		(value: ICategoriesSetsFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId, 0));
		},
		[objectId]
	);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={handleApply}
		>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};

export default CategoriesSetsFiltersRoutingPage;
