import CheckPage from '@src/pages/CheckPage';
import {ChecksPage} from '@src/pages/ChecksPage';
import ChecksFiltersRoutingPage from '@src/pages/filters/ChecksFiltersRoutingPage';
import {checksFilterRoutes} from './checksFilterRoutes';
import {PrivateRoute} from '@src/containers/PrivateRoute';

export const objectChecksRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<ChecksPage/>}/>
	},
	{
		path: ':checkId',
		element: <PrivateRoute element={<CheckPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<ChecksFiltersRoutingPage/>}/>,
		children: checksFilterRoutes
	}
];
