import {wsConnector} from '@src/api/backend/wsConnector';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import ISavingPlan from '@tehzor/tools/interfaces/plans/ISavingPlan';

export type IAddPlanResponse = IPlan;

/**
 * Добавляет план
 * @param objectId id объекта
 * @param plan данные нового плана
 */
export const makePlanAddRequest = (objectId: string, plan: ISavingPlan) =>
	wsConnector.sendAuthorizedRequest<IAddPlanResponse>('addPlan', {
		objectId,
		...plan
	});
