import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ISpaceStatusesSetsFiltersState} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/reducers';
import {extractSpaceStatusesSetsPageSettings} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/selectors';
import {
	changeFilters,
	changeOffset
} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/actions';
import { useCallback } from 'react';
import {Outlet} from 'react-router-dom';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';

export const SpaceStatusesSetsFiltersRoutingPage = () => {
	const {filters} = useAppSelector(extractSpaceStatusesSetsPageSettings);

	const dispatch = useAppDispatch();

	const handleApply = useCallback((value: ISpaceStatusesSetsFiltersState) => {
		dispatch(changeFilters(value));
		dispatch(changeOffset(0));
	}, []);

	return (
		<MobileEntitiesFilters
			filters={filters}
			onApply={handleApply}
		>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};
