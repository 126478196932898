import {useTaskTypes} from '@src/core/hooks/queries/taskTypes';
import {useTaskStatuses} from '@src/core/hooks/queries/taskStatuses/hook';
import {IconButton, PopupPlate} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import {useTranslation} from "react-i18next";
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';

export const LegendTasks = () => {
	const {t} = useTranslation();
	const [isOpen, toggle] = useToggle(false);

	const {data: taskStatuses} = useTaskStatuses();
	const translatedStatuses = useTranslatedDictionary('taskStatuses', taskStatuses);

	const {data: taskTypes} = useTaskTypes();
	const translatedTypes = useTranslatedDictionary('taskTypes', taskTypes);

	return (
		<PopupPlate
			className={{
				root: 'manage-task-schedule-page__legend-popup',
				content: 'manage-task-schedule-page__legend-popup-content'
			}}
			trigger={({ref}) => (
				<IconButton
					ref={ref}
					type="transparent"
					active={isOpen}
					onClick={toggle}
				>
					<i className="tz-info-24" />
				</IconButton>
			)}
			arrowVisible
			isOpen={isOpen}
			placement="bottom-end"
			onOutsideClick={toggle}
			onDisappear={toggle}
		>
			<div className="manage-task-schedule-page__legend-content">
				<div>
					<div className="manage-task-schedule-page__legend-content-title">
						{t('taskSchedulePage.legendTasks.tasksStatus.title')}
					</div>
					{translatedStatuses?.allIds.map(id => (
						<div
							key={id}
							className="manage-task-schedule-page__legend-content-item"
						>
							<span
								className="manage-task-schedule-page__legend-content-item-status"
								style={{
									backgroundColor: translatedStatuses.byId?.[id]?.scheduleTableColor
										? translatedStatuses.byId[id]?.scheduleTableColor
										: '#C4CAD4'
								}}
							>
								{' '}
							</span>
							<span className="manage-task-schedule-page__legend-content-item-status-title">
								{translatedStatuses.byId?.[id]?.name}
							</span>
						</div>
					))}
				</div>

				<div>
					<div className="manage-task-schedule-page__legend-content-title">
						{t('taskSchedulePage.legendTasks.tasksTypes.title')}
					</div>
					<div className="manage-task-schedule-page__legend-content-items">
						{translatedTypes?.allIds.map(id => (
							<div
								key={id}
								className="manage-task-schedule-page__legend-content-item"
							>
								<span
									className="manage-task-schedule-page__legend-content-item-type"
									style={{
										backgroundColor: translatedTypes.byId[id]
											? translatedTypes.byId[id].color
											: '#C4CAD4'
									}}
								>
									{' '}
								</span>
								<span className="manage-task-schedule-page__legend-content-item-type-title">
									{translatedTypes.byId[id]?.name}
								</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</PopupPlate>
	);
};
