import {IconMenu} from '@tehzor/ui-components';
import {useAppUpdateMenuItem} from './useAppUpdateMenuItem';

const AppUpdateMenu = () => {
	const items = [];

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	items.push(
		updateData
	);

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
		</>
	) : null;
};

export default AppUpdateMenu;
