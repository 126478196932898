import * as React from 'react';
import './CircleLoader.less';
import classNames from 'classnames';

interface ICircleLoaderProps {
	className?: string;
	style?: React.CSSProperties;
	percent?: number;
	trackColor?: string;
	progressColor?: string;
}

export const CircleLoader = (props: ICircleLoaderProps) => {
	const {className, style, percent, trackColor = '#E8EAEC', progressColor = '#A7D0FF'} = props;

	return (
		<svg
			className={classNames(
				'circle-loader',
				percent === undefined ? 'circle-loader_looped' : 'circle-loader_definite',
				className
			)}
			style={style}
			viewBox="0 0 36.4 36.4"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				opacity="0.6"
				cx="18.2"
				cy="18.2"
				r="15.91549430918954"
				stroke={trackColor}
				strokeWidth="4.4"
			/>
			<circle
				className="circle-loader__progress"
				cx="18.2"
				cy="18.2"
				r="15.91549430918954"
				stroke={progressColor}
				strokeWidth="4.4"
				strokeDasharray={percent !== undefined ? `${percent} ${100 - percent}` : undefined}
				strokeDashoffset="25"
				strokeLinecap="round"
			/>
		</svg>
	);
};