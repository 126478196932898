import {Dispatch, memo, useMemo} from 'react';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import Text from '@src/components/editableFields/Text';
import CompanySelect from '@src/components/editableFields/CompanySelect';
import SpaceTypeSelect from '@src/components/editableFields/SpaceTypeSelect';
import {
	IEditableCheckListAction,
	IEditableCheckListState
} from '@src/core/hooks/states/useEditableCheckListState/state';
import './EditableCheckList.less';
import CheckListTypeSelect from '../editableFields/CheckListTypeSelect';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {ObjectStageSelect} from '@src/components/editableFields/ObjectStageSelect';
import {ObjectsSelect} from '../editableFields/ObjectsSelect';
import {SpaceTypeDecorationTreeSelect} from '../editableFields/SpaceTypeDecorationTreeSelect';
import {filterTypeDecorationsForCheckList} from './utils/filterTypeDecorations';
import {CategoryTreeSelect} from '../editableFields/CategoryTreeSelect';
import {StructureTypeSelect} from '../editableFields/StructureTypeSelect';
import {WorkAcceptanceFrontTypeSelect} from '../editableFields/WorkAcceptanceFrontTypeSelect';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {useUpdateEffect} from 'react-use';
import {useSpaceTypeDecorationsAsArray} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {useExtractSpaceTypeDecorationSetsAsArray} from '@src/core/hooks/queries/SpaceTypeDecorationSets/hooks';

interface IEditableCheckListProps {
	editingState: IEditableCheckListState;
	editingDispatch: Dispatch<IEditableCheckListAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving?: boolean;
}

export const EditableCheckList = memo((props: IEditableCheckListProps) => {
	const {editingState, editingDispatch, fieldsSettings, saving} = props;

	const {data: typeDecorationsArray} = useSpaceTypeDecorationsAsArray();
	const {data: typeDecorationSets} = useExtractSpaceTypeDecorationSetsAsArray();

	const externalTypeDecorations = useMemo(() => {
		if (!typeDecorationsArray || !typeDecorationSets) {
			return [];
		}
		return filterTypeDecorationsForCheckList(
			editingState,
			typeDecorationsArray,
			typeDecorationSets
		);
	}, [editingState, typeDecorationsArray, typeDecorationSets]);

	// Сброс типа отделки если изменились поля
	useUpdateEffect(() => {
		editingDispatch({
			type: 'update',
			field: 'typeDecoration',
			value: undefined
		});
	}, [
		editingState.companyId,
		editingState.objects,
		editingState.spaceTypes,
		editingState.structureType,
		editingState.stage,
		editingState.type
	]);

	useUpdateEffect(() => {
		editingDispatch({
			type: 'update',
			field: 'objects',
			value: []
		});
	}, [editingState.companyId]);

	return (
		<div className="editable-check-list">
			{fieldsSettings.name !== undefined && (
				<Text
					field="name"
					label="Название"
					elementType="textarea"
					textAreaProps={{minRows: 2}}
					value={editingState.name}
					editingDispatch={editingDispatch}
					required={fieldsSettings.name.isRequired}
					disabled={saving}
					hasError={editingState.errors.name}
				/>
			)}

			{fieldsSettings.companyId !== undefined && (
				<CompanySelect
					field="companyId"
					label="Компания"
					value={editingState.companyId}
					editingDispatch={editingDispatch}
					required={fieldsSettings.companyId.isRequired}
					disabled={saving}
					hasError={editingState.errors.companyId}
				/>
			)}

			<ObjectsSelect
				field="objects"
				label="Объекты"
				value={editingState.objects}
				companyId={editingState.companyId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.objects.isRequired}
				disabled={saving}
				hasError={editingState.errors.objects}
			/>

			{fieldsSettings.type !== undefined && (
				<CheckListTypeSelect
					field="type"
					label="Тип чеклиста"
					value={editingState.type}
					editingDispatch={editingDispatch}
					required={fieldsSettings.type.isRequired}
					disabled={saving}
					hasError={editingState.errors.type}
				/>
			)}

			{fieldsSettings.frontType !== undefined &&
				editingState.type === CheckListTypeId.ACCEPTANCES && (
					<WorkAcceptanceFrontTypeSelect
						field="frontType"
						label="Тип фронта приёмок работ"
						value={editingState.frontType}
						editingDispatch={editingDispatch}
						required={fieldsSettings.frontType.isRequired}
						disabled={saving}
						hasError={editingState.errors.frontType}
					/>
				)}

			{fieldsSettings.spaceTypes !== undefined &&
				(editingState.type === CheckListTypeId.OPERATIONS ||
					editingState.frontType === WorkAcceptanceFrontTypeId.SPACES) && (
					<SpaceTypeSelect
						field="spaceTypes"
						label="Типы помещений"
						value={editingState.spaceTypes || []}
						editingDispatch={editingDispatch}
						required={fieldsSettings.spaceTypes.isRequired}
						disabled={saving}
						hasError={editingState.errors.spaceTypes}
					/>
				)}

			{fieldsSettings.categories !== undefined &&
				editingState.type === CheckListTypeId.ACCEPTANCES && (
					<CategoryTreeSelect
						field="categories"
						label="Вид работ"
						value={editingState.categories || []}
						editingDispatch={editingDispatch}
						required={fieldsSettings.categories.isRequired}
						disabled={saving}
						hasError={editingState.errors.categories}
					/>
				)}

			{fieldsSettings.structureType !== undefined &&
				editingState.type === CheckListTypeId.ACCEPTANCES &&
				editingState.frontType === WorkAcceptanceFrontTypeId.STRUCTURES && (
					<StructureTypeSelect
						field="structureType"
						label="Тип Структур"
						value={editingState.structureType}
						editingDispatch={editingDispatch}
						required={fieldsSettings.structureType.isRequired}
						disabled={saving}
						hasError={editingState.errors.structureType}
					/>
				)}

			{fieldsSettings.stage !== undefined && (
				<ObjectStageSelect
					field="stage"
					label={
						editingState.type === CheckListTypeId.OPERATIONS
							? 'Стадия помещений'
							: 'Стадия объекта'
					}
					errorMessage={
						editingState.type === CheckListTypeId.OPERATIONS
							? 'Выберите стадию помещений'
							: 'Выберите стадию объекта'
					}
					value={editingState.stage}
					editingDispatch={editingDispatch}
					required={fieldsSettings.stage.isRequired}
					disabled={saving}
					hasError={editingState.errors.stage}
				/>
			)}

			{/* Показывать если выбрана компания и стадия помещения = "приёмка" или "передача" */}
			{editingState.companyId !== undefined &&
				(editingState.type === CheckListTypeId.OPERATIONS ||
					editingState.frontType === WorkAcceptanceFrontTypeId.SPACES) &&
				externalTypeDecorations && (
					<SpaceTypeDecorationTreeSelect
						field="typeDecoration"
						label="Типы отделки"
						value={editingState.typeDecoration || []}
						externalTypeDecorations={externalTypeDecorations}
						editingDispatch={editingDispatch}
						required={fieldsSettings.typeDecoration.isRequired}
						disabled={saving}
						hasError={editingState.errors.typeDecoration}
					/>
				)}
		</div>
	);
});
