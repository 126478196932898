import { useMemo } from 'react';
import {SelectPopup, TextFieldWithForwardedRef, TreeSelect} from '@tehzor/ui-components';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {useObjectsAsArray, useObjectsMap} from '@src/core/hooks/queries/objects/hooks';

interface IObjectSelectProps {
	selectObjectsIds: string[];
	onSelect: (ObjectsIds: string[]) => void;
}

export const ObjectSelect = (props: IObjectSelectProps) => {
	const objectsMap = useObjectsMap();
	const objects = useObjectsAsArray();

	const treeData = useMemo(() => {
		if (!objects) return undefined;
		return makeObjectsTreeData(objects);
	}, [objects]);

	const {selectObjectsIds, onSelect} = props;

	if (!treeData || !objectsMap) return null;

	return (
		<SelectPopup
			trigger={
				<TextFieldWithForwardedRef
					className="categories-set-migration-page__select"
					elementType="div"
					value={
						selectObjectsIds && selectObjectsIds.length > 0
							? selectObjectsIds.length > 1
								? `${objectsMap[selectObjectsIds[0]]?.name} + ${
										selectObjectsIds.length - 1
								  }`
								: `${objectsMap[selectObjectsIds[0]]?.name}`
							: undefined
					}
					icon={<i className="tz-simple-arrow-20" />}
				/>
			}
			clearButton={!!selectObjectsIds?.length}
			footer
			noHeader
			count={selectObjectsIds?.length}
			onClear={() => onSelect([])}
		>
			<TreeSelect
				data={treeData}
				multiple
				value={selectObjectsIds}
				onChange={onSelect}
			/>
		</SelectPopup>
	);
};
