const domain = 'problem';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const EDIT_REQUEST = `${domain}/edit/request`;
export const EDIT_SUCCESS = `${domain}/edit/success`;
export const EDIT_FAILURE = `${domain}/edit/failure`;

export const MOVE_REQUEST = `${domain}/move/request`;
export const MOVE_SUCCESS = `${domain}/move/success`;
export const MOVE_FAILURE = `${domain}/move/failure`;

export const EDIT_STATUS_REQUEST = `${domain}/status/edit/request`;
export const EDIT_STATUS_SUCCESS = `${domain}/status/edit/success`;
export const EDIT_STATUS_FAILURE = `${domain}/status/edit/failure`;

export const EDIT_PERFORMERS_REQUEST = `${domain}/performers/edit/request`;
export const EDIT_PERFORMERS_SUCCESS = `${domain}/performers/edit/success`;
export const EDIT_PERFORMERS_FAILURE = `${domain}/performers/edit/failure`;

export const EDIT_INSPECTORS_REQUEST = `${domain}/inspectors/edit/request`;
export const EDIT_INSPECTORS_SUCCESS = `${domain}/inspectors/edit/success`;
export const EDIT_INSPECTORS_FAILURE = `${domain}/inspectors/edit/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const GET_COMMENTS_REQUEST = `${domain}/comments/get/request`;
export const GET_COMMENTS_SUCCESS = `${domain}/comments/get/success`;
export const GET_COMMENTS_FAILURE = `${domain}/comments/get/failure`;

export const RESET_COMMENTS = `${domain}/comments/reset`;

export const GET_STORIES_REQUEST = `${domain}/stories/get/request`;
export const GET_STORIES_SUCCESS = `${domain}/stories/get/success`;
export const GET_STORIES_FAILURE = `${domain}/stories/get/failure`;

export const GET_COMMENTS_STORIES_REQUEST = `${domain}/comments-stories/get/request`;
export const GET_COMMENTS_STORIES_SUCCESS = `${domain}/comments-stories/get/success`;
export const GET_COMMENTS_STORIES_FAILURE = `${domain}/comments-stories/get/failure`;

export const GET_MARKERS_STORIES_REQUEST = `${domain}/markers-stories/get/request`;
export const GET_MARKERS_STORIES_SUCCESS = `${domain}/markers-stories/get/success`;
export const GET_MARKERS_STORIES_FAILURE = `${domain}/markers-stories/get/failure`;

export const GET_PERFORMERS_DELEGATION_HISTORIES_REQUEST = `${domain}/performers-delegation-histories/get/request`;
export const GET_PERFORMERS_DELEGATION_HISTORIES_SUCCESS = `${domain}/performers-delegation-histories/get/success`;
export const GET_PERFORMERS_DELEGATION_HISTORIES_FAILURE = `${domain}/performers-delegation-histories/get/failure`;

export const GET_INSPECTORS_DELEGATION_HISTORIES_REQUEST = `${domain}/inspectors-delegation-histories/get/request`;
export const GET_INSPECTORS_DELEGATION_HISTORIES_SUCCESS = `${domain}/inspectors-delegation-histories/get/success`;
export const GET_INSPECTORS_DELEGATION_HISTORIES_FAILURE = `${domain}/inspectors-delegation-histories/get/failure`;
