import {format} from 'date-fns';
import {IPreparedExportTemplate} from '../interfaces/IPreparedExportTemplate';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';

/**
 * Преобразовывает массив шаблонов в необходимый для вывода формат
 *
 * @param data массив шаблонов экспорта
 */
export const convertExportTemplates = (
	data: IExportTemplate[]
): IPreparedExportTemplate[] =>
	data.map(item => ({
		...item,
		fileName: item.file?.s3Name || '',
		createdBy: item.createdBy?.id,
		createdAt: item.createdAt ? format(item.createdAt, dateTimeLetterSeparatedFormat) : '',
		modifiedBy: item.modifiedBy?.id,
		modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeLetterSeparatedFormat) : ''
	}));
