import {wsConnector} from '../wsConnector';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';

export type IEditWorkAcceptanceStatusResponse = IWorkAcceptance;

/**
 * Изменяет статус приёмки работ
 */
export const makeWorkAcceptanceStatusEditRequest = (
	objectId: string,
	workAcceptanceId: string,
	statusId: WorkAcceptanceStatusIds
) =>
	wsConnector.sendAuthorizedRequest<IEditWorkAcceptanceStatusResponse>('editWorkAcceptanceStatus', {
		objectId,
		workAcceptanceId,
		statusId
	});
