import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '../constants';

export interface IOfflineModeSettingsState extends Record<string, unknown> {
	objects: string[];
}

export const getInitialState = (): IOfflineModeSettingsState => ({
	objects: []
});
export const cache = createReducer<IOfflineModeSettingsState>(getInitialState(), {
	[types.CHANGE_CACHING_OBJECTS]: (state, payload: IOfflineModeSettingsState) => payload,
	[types.CHANGE_OFFLINE_MODE_AVAILABILITY]: (state, payload: boolean) => {
		if (!payload) {
			return getInitialState();
		}
		return state;
	}
});
