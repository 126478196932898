import {useMemo} from 'react';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useLocalChecksJournal} from './localChecksJournal';
import {useLocalInternalAcceptancesJournal} from './localInternalAcceptancesJournal';
import {useLocalOwnerAcceptancesJournal} from './localOwnerAcceptances';
import {useLocalWarrantyClaimsJournal} from './localWarrantyClaimsJournal';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';
import {useLocalCheckRecordsChangesJournal} from './localCheckRecordsChangesJournal';
import {useLocalSpaceIndicatorsChangesJournal} from './localSpaceIndicatorsChangesJournal';
import {useLocalSpaceStatusChangesJournal} from './localSpaceStatusChangesJournal';
import {useLocalSpaceOwnersJournal} from './localSpaceOwnersJournal';
import {filterEntities} from '../utils/filterEntities';
import {useLocalProblemsJournal} from './localProblemsJournal';
import {useLocalInspectionsJournal} from './localInspectionsJournal';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';

export const useJournalData = (): IOfflineJournalEntity[] => {
	const {state: filters} = useEntitiesFiltersCtx<IOfflineJournalFiltersState>();
	const problems = useLocalProblemsJournal();
	const inspections = useLocalInspectionsJournal();
	const checks = useLocalChecksJournal();
	const internalAcceptances = useLocalInternalAcceptancesJournal();
	const ownerAcceptances = useLocalOwnerAcceptancesJournal();
	const spaceOwners = useLocalSpaceOwnersJournal();
	const warrantyClaims = useLocalWarrantyClaimsJournal();
	const checkRecordsChanges = useLocalCheckRecordsChangesJournal();
	const spaceIndicatorsChanges = useLocalSpaceIndicatorsChangesJournal();
	const spaceStatusChanges = useLocalSpaceStatusChangesJournal();
	const entities = useMemo(
		() => [
			...inspections,
			...problems,
			...internalAcceptances,
			...ownerAcceptances,
			...spaceOwners,
			...checks,
			...warrantyClaims,
			...checkRecordsChanges,
			...spaceIndicatorsChanges,
			...spaceStatusChanges
		],
		[
			inspections,
			problems,
			internalAcceptances,
			ownerAcceptances,
			spaceOwners,
			checks,
			warrantyClaims,
			checkRecordsChanges,
			spaceIndicatorsChanges,
			spaceStatusChanges
		]
	);
	return useMemo(() => filterEntities(entities, filters), [entities, filters]);
};
