import * as types from '@src/constants/local-entities/problems';
import {ILocalProblem} from '@src/interfaces/ILocalProblem';

/**
 * Добавляет отчёт в локальное хранилище
 *
 * @param problem
 * @returns {{type, payload}} action
 */
export const addLocalProblem = (problem: ILocalProblem) => ({
	type: types.ADD_LOCAL_PROBLEM,
	payload: {problem}
});

/**
 * Удаляет отчёт из локального хранилища
 *
 * @param {string} key id локального отчёта
 * @returns {{type, payload}} action
 */
export const deleteLocalProblem = (key: string) => ({
	type: types.DELETE_LOCAL_PROBLEM,
	payload: {key}
});
