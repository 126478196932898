import {ITwoWayTreeItem} from '../../interfaces/ITwoWayTreeItem';

/**
 * Формирует список частично отмеченных элементов
 *
 * @param data данные в виде дерева
 * @param checked отмеченные элементы
 */
export function formSemiChecked<T extends {
	id: string;
	onlySemiCheckable?: boolean;
}>(data: Array<ITwoWayTreeItem<T>>, checked?: string[]): string[] {
	if (!checked?.length) {
		return [];
	}
	let semiChecked: string[] = [];
	for (const item of data) {
		if (!checked.includes(item.id) && item.children) {
			const childrenSemiChecked = formSemiChecked(item.children, checked);
			const childrenCheckedCount = item.children.filter(child => checked.includes(child.id)).length;

			if (
				childrenSemiChecked.length
				|| (childrenCheckedCount > 0 && childrenCheckedCount < item.children.length)
			) {
				semiChecked.push(item.id);
			}
			if (childrenCheckedCount === item.children.length && item.onlySemiCheckable) {
				semiChecked.push(item.id);
			}
			semiChecked = semiChecked.concat(childrenSemiChecked);
		}
	}
	return semiChecked;
}
