import {queryClient} from '@src/api/QueryClient';
import {makeResponsibilityRuleAddRequest} from '@src/api/backend/responsibilityRules';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ISavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/ISavingResponsibilityRule';

export interface IAddResponsibilityRulesParams {
	groupId: string;
	rule: ISavingResponsibilityRule;
}

export const useAddResponsibilityRules = () =>
	useMutation({
		mutationFn: async (params: IAddResponsibilityRulesParams) => {
			const {groupId, rule} = params;
			return makeResponsibilityRuleAddRequest(groupId, rule);
		},
		mutationKey: responsibilityRulesQueryKeys.add(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
			addSuccessToast('Успешно', 'Добавлено новое правило');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении правила');
		}
	});
