import {IGetMeterConsumptionsResponse} from '@src/api/backend/meterConsumptions';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';

export const extractMeterConsumptionsData = (
	data: IGetMeterConsumptionsResponse
): INormalizedData<IMeterConsumption> => data;

export const extractMeterConsumptionsAsArray = (
	data: IGetMeterConsumptionsResponse
): IMeterConsumption[] => {
	const meterConsumptionsData = extractMeterConsumptionsData(data);
	return meterConsumptionsData.allIds.map((id: string) => meterConsumptionsData.byId[id]);
};

export const extractMeterConsumptionsAsArrayForMeter = (
	data: IGetMeterConsumptionsResponse,
	meterId: string
): IMeterConsumption[] => {
	const meterConsumptions = extractMeterConsumptionsAsArray(data);
	return meterConsumptions.filter((meter: IMeterConsumption) => meter.meterId === meterId);
};
