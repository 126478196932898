import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IRequestProfileResponse, requestProfile} from '@src/api/backend/profile';

export type IGetProfilePayload = IRequestProfileResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IRequestProfileResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => ({
	type: types.GET_FAILURE,
	payload: error
});

/**
 * Получает информацию о текущем пользователе
 */
export const getProfile = () =>
	createApiAction<IGetProfilePayload>(request, success, failure, () => requestProfile());
