import {memo} from 'react';
import {MobileFilter, TextField} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '../../EntitiesFilters/utils/makeFilterLabel';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {useTranslation} from 'react-i18next';

interface IFixDateFilterMobileProps {
	objectId?: string;
	from?: Date;
	to?: Date;
	entity: string;
}

const calendarIcon = <i className="tz-calendar-20" />;

export const PlannedFixDateFilterMobile = memo(
	({objectId, from, to, entity}: IFixDateFilterMobileProps) => {
		const handleExpand = useFilterPageTransition(objectId, entity, 'planFixDate');
		const {t} = useTranslation();

		return (
			<MobileFilter label={t('problemsPage.fixDateFilter.label')}>
				<TextField
					className="mobile-entities-filters__textfield"
					elementType="div"
					value={from && to ? makeDateFilterLabel('', from, to) : undefined}
					icon={calendarIcon}
					onClick={handleExpand}
				/>
			</MobileFilter>
		);
	}
);
