import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IConvertedProblemsData} from '@tehzor/tools/interfaces/structures/IConvertedProblemsData';
import {getProblemsDataByStage} from './getProblemsDataByStage';

export const convertProblemsData = (
	problems: IEntityProblemsData['problems'] | undefined,
	problemStatuses: INormalizedData<IProblemStatus>,
	selectedStage?: ObjectStageIds
): IConvertedProblemsData[] => {
	if (!problems) {
		return [];
	}

	let total = 0;
	const result = [];

	const stageProblems = getProblemsDataByStage(problems, selectedStage);

	if (stageProblems) {
		for (const statusId of problemStatuses.allIds) {
			if (stageProblems.hasOwnProperty(statusId) && stageProblems[statusId].count > 0) {
				result.push({
					key: statusId,
					value: stageProblems[statusId].count,
					percent: 0,
					color: problemStatuses.byId[statusId]?.color || '#00000000',
					startPoint: 0,
					critical:
						statusId !== ProblemStatusId.FIXED
							? stageProblems[statusId].critical
							: undefined
				});
				total += stageProblems[statusId].count;
			}
		}
		let offset = 0;
		for (const stat of result) {
			stat.percent = (stat.value / total) * 100;
			stat.startPoint = -offset;
			offset += stat.percent;
		}
	}
	return result;
};
