import { useCallback } from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {extractOwnerAcceptancesPageSettings} from '@src/store/modules/settings/pages/ownerAcceptances/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ownerAcceptancesPageSettingsActions} from '@src/store/modules/settings/pages/ownerAcceptances/slice';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/interfaces';

export const OwnerAcceptancesFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractOwnerAcceptancesPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const {changeFilters, changeOffset, clearFilters} = ownerAcceptancesPageSettingsActions;

	const applyFilters = useCallback(
		(filters: IOwnerAcceptancesFiltersState) => {
			dispatch(changeFilters({objectId: objectId || 'all', filters}));
			dispatch(changeOffset({objectId: objectId || 'all', offset: 0}));
		},
		[objectId, dispatch, changeFilters, changeOffset]
	);

	const mobileClearFilters = useCallback(() => {
		clearFilters({objectId: objectId || 'all'});
	}, [objectId, clearFilters]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={mobileClearFilters}
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};
