import {useMemo} from 'react';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import splitAttachments from '@tehzor/tools/utils/splitAttachments';
import IFile from '@tehzor/tools/interfaces/IFile';

/**
 * Разделяет вложения на изображения и остальные файлы
 *
 * @param entity сущность
 */
export const useSplitAttachments = (entity?: {
	attachments?: IAttachment[];
}): [IAttachment[], IFile[]] =>
	useMemo(() => {
		if (entity?.attachments) {
			return splitAttachments(entity.attachments);
		}
		return [[], []];
	}, [entity]);
