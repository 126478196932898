import {memo} from 'react';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface ICreationDateFilterProps {
	label?: string;
	from?: Date;
	to?: Date;
}

export const CreationDateFilter = memo((props: ICreationDateFilterProps) => {
	const {label, from, to} = props;
	const {t: translate} = useTranslation();
	const filterLabel = label || translate('components.entitiesFilters.CreationDateFilter.label');

	const dateFrom = from ? new Date(from) : undefined;
	const dateTo = to ? new Date(to) : undefined;

	const {dispatch} = useEntitiesFiltersCtx();

	const handleChange = (f: Date | null, t: Date | null) => {
		const createdAtFrom = f ? new Date(f.getFullYear(), f.getMonth(), f.getDate()) : undefined;
		const createdAtTo = t
			? new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999)
			: undefined;
		dispatch({createdAtFrom, createdAtTo});
	};

	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	const handleClear = () => {
		dispatch({createdAtFrom: undefined, createdAtTo: undefined});
	};

	return (
		<DateRangePicker
			trigger={triggerProps => (
				<FilterButton
					className="entities-filters__item"
					label={makeDateFilterLabel(filterLabel, dateFrom, dateTo)}
					active={dateFrom !== undefined || dateTo !== undefined}
					onClick={triggerProps.toggle}
					onClear={handleClear}
					ref={triggerProps.ref}
				/>
			)}
			valueFrom={dateFrom}
			valueTo={dateTo}
			dateFormat="dd MMMM yyyy"
			useApplyButton
			datesOptions={datesOptions}
			onChange={handleChange}
			selectApplyBtnLabel={translate('applyBtn.label')}
			selectCancelBtnLabel={translate('cancelBtn.label')}
		/>
	);
});
