import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditCategoriesSetResponse = ICategoriesSet;

export const requestEditCategoriesSet = async (categoriesSet: ISavingCategoriesSet) => {
	const response = await httpRequests.withToken.post<IEditCategoriesSetResponse>(`categories-sets/edit/${categoriesSet.id}`, categoriesSet);

	return response.data;
};
