import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';

export function useEntityType(objectId: string, types: ICheckAddingEntityType[]) {
	const type = useAppSelector(s => s.settings.checkAdding.entityType);
	return useMemo<ICheckAddingEntityType | undefined>(() => {
		if (types.includes(type)) {
			return type;
		}
		return types.length ? types[0] : undefined;
	}, [type, types]);
}
