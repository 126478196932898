import {TabLink, Tabs} from '@tehzor/ui-components';
import {TasksScheduleCollectMode} from '@src/store/modules/settings/pages/tasks/interfaces';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useTranslation} from "react-i18next";
import useAppSelector from "@src/core/hooks/useAppSelector";
import {extractTasksScheduleSettings} from "@src/store/modules/settings/pages/tasks/selectors";
import {tasksScheduleActions} from "@src/store/modules/settings/pages/tasks/slices/schedule";

export const TasksScheduleCollectTabs = () => {
	const {t} = useTranslation();
	const {collectBy} = useAppSelector(extractTasksScheduleSettings);
	const dispatch = useAppDispatch();
	const {changeCollectMode} = tasksScheduleActions;
	const setActiveTab = (tab: number) => {
		const collectMode = tab === 0 ? TasksScheduleCollectMode.OBJECT : TasksScheduleCollectMode.GROUP;
		dispatch(changeCollectMode({collectMode}));
	};

	return (
		<div className="manage-task-schedule-page__tabs">
			<Tabs
				className={{
					fakeLink: 'manage-task-schedule-page__tabs-fake-link'
				}}
				activeTab={collectBy === TasksScheduleCollectMode.OBJECT ? 0 : 1}
				links={[
					<TabLink
						key="object"
						label={t('taskSchedulePage.tabLink.object.label')}
					/>,
					<TabLink
						key="group"
						label={t('taskSchedulePage.tabLink.group.label')}
					/>
				]}
				onActiveTabChange={setActiveTab}
			/>
		</div>
	);
};
