import {Variants} from 'framer-motion';

export const animations = {
	overlay: {
		initial: {
			opacity: 0
		},
		visible: {
			opacity: 1,
			transition: {
				ease: [0.34, 0.18, 0.61, 0.81],
				duration: 0.2,
				delayChildren: 0
			}
		},
		hidden: {
			opacity: 0,
			transition: {
				ease: [0.34, 0.18, 0.61, 0.81],
				duration: 0.2,
				delay: 0.1,
				when: false
			}
		}
	},
	dialog: {
		initial: {
			scale: 0.6,
			opacity: 0
		},
		visible: {
			scale: 1,
			opacity: 1,
			transition: {
				type: 'tween',
				ease: [0.34, 0.18, 0.61, 0.81],
				duration: 0.2,
				delay: 0.1,
				delayChildren: 0.05,
				when: 'beforeChildren'
			}
		},
		hidden: {
			scale: 0.8,
			opacity: 0,
			transition: {
				type: 'tween',
				ease: [0.34, 0.18, 0.61, 0.81],
				duration: 0.25,
				when: 'afterChildren'
			}
		}
	}
} as {[key: string]: Variants};
