import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';

export const makePlansTreeData = (
	plans: IPlan[],
	objectsMap: Record<string, IObject>,
	objectIds?: string[]
) => {
	const treeData: ITreeDataItem[] = [];
	const displayedObjectIds = new Set<string>();

	for (const plan of plans) {
		displayedObjectIds.add(plan.objectId);
		const treeItem: ITreeDataItem = {
			id: plan.id,
			content: plan.name,
			parentId: objectIds?.length ? plan.objectId : undefined
		};
		treeData.push(treeItem);
	}

	if (!objectIds?.length) {
		return treeData;
	}

	for (const objectId of objectIds) {
		const object = objectsMap[objectId];
		if (!object || !displayedObjectIds.has(objectId)) { continue; }

		const treeItem: ITreeDataItem = {
			id: object.id,
			content: object.name,
			onlySemiCheckable: true
		};
		treeData.push(treeItem);
	}

	return treeData;
}