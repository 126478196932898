import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {ITreeProblemTagsSetDataItem} from '@tehzor/ui-components';

const makeTagsTreeData = (
	treeData: ITreeProblemTagsSetDataItem[],
	tags?: IProblemTag[],
	problemTagsSetId?: string
) => {
	if (!tags) {
		return treeData;
	}
	tags.forEach(tag => {
		treeData.push({
			id: tag.id,
			content: tag.name,
			color: tag.color,
			parentId: tag.problemTagsSetId || problemTagsSetId,
			problemTagsSetId
		});
	});

	return treeData;
};

export const makeTreeData = (
	sets: IProblemTagsSet[] = [],
	allTags: IProblemTag[] | undefined,
	disabled = false
): ITreeProblemTagsSetDataItem[] => {
	let treeData: ITreeProblemTagsSetDataItem[] = [];

	if (sets?.length === 1) {
		const set = sets[0];
		const tags = allTags?.filter(item => item.problemTagsSetId === set.id);
		treeData = makeTagsTreeData(treeData, tags, set.id);
		return treeData;
	}

	for (const set of sets) {
		const tags = allTags?.filter(item => item.problemTagsSetId === set.id);
		const treeSetItem: ITreeProblemTagsSetDataItem = {
			id: set.id,
			content: set.name,
			parentId: undefined,
			nonCheckable: disabled,
			total: tags?.length || 0,
			onlySemiCheckable: true
		};
		treeData.push(treeSetItem);
		treeData = makeTagsTreeData(treeData, tags, set.id);
	}
	return treeData;
};
