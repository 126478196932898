import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {
	IWarrantyClaimsPageSettingsState,
	IWarrantyClaimsPagesSettingsState,
	getInitialStateForPage
} from './reducers';

/**
 * Возвращает настройки страницы для конкретного объекта
 */
export const extractWarrantyClaimsPageSettings = createSelector(
	(state: IState) => state.settings.pages.warrantyClaims,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(
		rootData: IWarrantyClaimsPagesSettingsState,
		objectId: string
	): IWarrantyClaimsPageSettingsState => rootData[objectId] || getInitialStateForPage(objectId)
);
