import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IWorkAcceptanceStory} from '@tehzor/tools/interfaces/workAcceptancesStories/IWorkAcceptanceStory';

export type IGetWorkAcceptanceStoriesResponse = INormalizedData<IWorkAcceptanceStory>;

/**
 * Возвращает историю изменений приемки работ
 */
export const requestWorkAcceptanceStories = async (objectId: string, workAcceptanceId: string) => {
	const params = {
		objectId,
		workAcceptanceId
	};
	const response = await httpRequests.withToken.get<IGetWorkAcceptanceStoriesResponse>(
		'work-acceptances-stories',
		{params}
	);
	return response.data;
};
