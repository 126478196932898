import './OwnerAcceptanceInfoMobileCell.less';
import {StatusSelect, TableRowSelect} from '@tehzor/ui-components';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import OwnerAcceptanceInfo from '../OwnerAcceptanceInfo';
import OwnerAcceptanceStatusCell from '../OwnerAcceptanceStatusCell';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {useAsyncFn} from 'react-use';
import {useEditOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useEditOwnerAcceptance';
import {
	useOwnerAcceptancesResolutions,
	useOwnerAcceptancesResolutionsArray
} from '@src/core/hooks/queries/ownerAcceptancesResolutions/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';

const OwnerAcceptanceInfoMobileCell = (props: CellProps<{data: IOwnerAcceptance}>) => {
	const {row} = props;
	const {data} = row.original;
	const permissions = useOwnerAcceptancesPermissions(data.objectId);
	const {data: resolutions} = useOwnerAcceptancesResolutionsArray();
	const {data: resolutionsMap} = useOwnerAcceptancesResolutions();
	const translatedResolutions = useTranslatedDictionary(
		'ownerAcceptanceResolutions',
		resolutionsMap
	);
	const {t} = useTranslation();
	const editOwnerAcceptance = useEditOwnerAcceptance();

	const [, handleResolutionChange] = useAsyncFn(
		async (v: OwnerAcceptanceResolutionId) => {
			await editOwnerAcceptance({
				objectId: data.objectId,
				acceptanceId: data.id,
				fields: {resolution: v}
			});
		},
		[data.objectId, data.id]
	);

	return (
		<div className="m-owner-acpt-info-cell">
			<div className="m-owner-acpt-info-cell__header">
				<OwnerAcceptanceInfo
					data={data}
					title={t('ownerAcceptanceInfoCell.mobile.title')}
				/>

				<ClickPreventWrap style={{marginLeft: '8px'}}>
					<TableRowSelect
						{...row.getToggleRowSelectedProps()}
						style={{marginTop: '12px'}}
					/>
				</ClickPreventWrap>
			</div>

			<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
				<div style={{marginRight: '16px'}}>
					{t('ownerAcceptanceInfoCell.resolutionStatusSelect.label')}:
				</div>
				{permissions.canEdit ? (
					<ClickPreventWrap>
						<StatusSelect
							style={{maxWidth: '100%'}}
							statuses={translatedResolutions}
							disabled={!permissions.canEditStatus}
							value={data.resolution !== 'undefined' ? data.resolution : undefined}
							onChange={handleResolutionChange}
							hideArrow
							label={t('ownerAcceptanceInfoCell.resolutionStatusSelect.label')}
						/>
					</ClickPreventWrap>
				) : (
					resolutions && (
						<div className="owner-acceptance-page__resolution">
							{resolutions.find(item => item.id === data.resolution)?.name}
						</div>
					)
				)}
			</div>

			{data.spaceOwner !== undefined && (
				<div className="m-owner-acpt-info-cell__extra-info">{data.spaceOwner.name}</div>
			)}

			<div style={{marginTop: '16px'}}>
				<OwnerAcceptanceStatusCell {...props} />
			</div>
		</div>
	);
};

export default OwnerAcceptanceInfoMobileCell;
