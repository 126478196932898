import {
	IEditOwnerAcceptanceStatusResponse,
	IEditOwnerAcptResponse,
	makeOwnerAcceptanceStatusEditRequest,
	makeOwnerAcptAddRequest,
	makeOwnerAcptEditRequest
} from '@src/api/backend/ownerAcceptance';
import {useQueryClient} from '@tanstack/react-query';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {ISavingOwnerAcceptance} from '@src/interfaces/saving/ISavingOwnerAcceptance';
import {useAddChildren} from '../../hooks/useAddChildren';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {addTempFiles} from '../../utils/addTempFiles';
import {ownerAcceptancesQueryKeys} from '../keys';
import {updateCachedState} from '@src/utils/updateCachedState';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import * as acceptanceTypes from '@src/store/modules/entities/ownerAcceptance/constants';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {spacesQueryKeys} from '../../spaces/keys';

export interface IAddOwnerAcceptanceParams {
	key: string;
	objectId: string;
	links: IOwnerAcceptance['links'] | undefined;
	fields: ISavingOwnerAcceptance;
}

export interface IEditOwnerAcceptanceParams {
	objectId: string;
	acceptanceId: string;
	fields: ISavingOwnerAcceptance;
}

export interface IEditOwnerAcceptanceStatusParams {
	objectId: string;
	acceptanceId: string;
	statusId: OwnerAcceptanceStatusId;
}

/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useOwnerAcceptancesMutationDefaults = () => {
	const queryClient = useQueryClient();
	const [addProblems] = useAddChildren();
	const {updateEntity} = useUpdateEntity(ownerAcceptancesQueryKeys);
	const dispatch = useAppDispatch();

	queryClient.setMutationDefaults(ownerAcceptancesQueryKeys.add(), {
		mutationFn: async (params: IAddOwnerAcceptanceParams) => {
			const {objectId, links, fields, key} = params;
			await updateCachedState<IOwnerAcceptance>(
				ownerAcceptancesQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			return makeOwnerAcptAddRequest(
				objectId,
				links,
				{
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments)
				},
				key
			);
		},
		onSuccess: async (
			newOwnerAcceptance: IOwnerAcceptance,
			{key}: IAddOwnerAcceptanceParams
		) => {
			await updateCachedState<IOwnerAcceptance>(
				ownerAcceptancesQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			addProblems(key, newOwnerAcceptance.id, 'ownerAcceptanceId');
			await queryClient.invalidateQueries({queryKey: ownerAcceptancesQueryKeys.list()});
			updateEntity<IOwnerAcceptance>(
				newOwnerAcceptance,
				(objectId: string, id: string) => ({
					type: acceptanceTypes.DELETE_SUCCESS,
					payload: {objectId, acceptanceId: id}
				}),
				data => ({type: acceptanceTypes.ADD_SUCCESS, payload: data}),
				key
			);
			await queryClient.refetchQueries({
				queryKey: ownerAcceptancesQueryKeys.localList()
			});
			await queryClient.invalidateQueries({
				queryKey: ownerAcceptancesQueryKeys.latest()
			});
			await queryClient.invalidateQueries({queryKey: spacesQueryKeys.ownerAcceptances()});
			queryClient.removeQueries({queryKey: ownerAcceptancesQueryKeys.savingData(key)});
		},
		onError: async (_, {key}: IAddOwnerAcceptanceParams) => {
			await updateCachedState<IOwnerAcceptance>(
				ownerAcceptancesQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
		}
	});

	queryClient.setMutationDefaults(ownerAcceptancesQueryKeys.edit(), {
		mutationFn: async (params: IEditOwnerAcceptanceParams) => {
			const {objectId, acceptanceId, fields} = params;
			if (!objectId || !acceptanceId || objectId === 'all') return undefined;

			return makeOwnerAcptEditRequest(objectId, acceptanceId, fields);
		},
		onSuccess: async (data: IEditOwnerAcptResponse) => {
			await queryClient.invalidateQueries({queryKey: ownerAcceptancesQueryKeys.list()});
			await queryClient.invalidateQueries({
				queryKey: ownerAcceptancesQueryKeys.detail(data.id, data.objectId)
			});
			if (data.links?.spaceId) {
				await queryClient.invalidateQueries({
					queryKey: spacesQueryKeys.spaceOwnerAcceptances(
						data.objectId,
						data.links.spaceId
					)
				});
			}
			dispatch({type: acceptanceTypes.EDIT_SUCCESS, payload: data});
		}
	});

	queryClient.setMutationDefaults(ownerAcceptancesQueryKeys.editStatus(), {
		mutationFn: async (params: IEditOwnerAcceptanceStatusParams) => {
			const {objectId, acceptanceId, statusId} = params;
			if (!objectId || !acceptanceId || !statusId || objectId === 'all') return undefined;

			return makeOwnerAcceptanceStatusEditRequest(objectId, acceptanceId, statusId);
		},
		onSuccess: async (data: IEditOwnerAcceptanceStatusResponse) => {
			await queryClient.invalidateQueries({queryKey: ownerAcceptancesQueryKeys.list()});
			await queryClient.invalidateQueries({
				queryKey: ownerAcceptancesQueryKeys.detail(data.id, data.objectId)
			});
			if (data.links?.spaceId) {
				await queryClient.invalidateQueries({
					queryKey: spacesQueryKeys.spaceOwnerAcceptances(
						data.objectId,
						data.links.spaceId
					)
				});
			}
			dispatch({type: acceptanceTypes.EDIT_STATUS_SUCCESS, payload: data});
		}
	});
};
