import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {Tree} from 'array-to-tree';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';
import {useExtractCheckItemsAsTree} from '@src/core/hooks/queries/checkItems/hooks';

const lookupParents = (
	tree: Array<Tree<ICheckItem>>,
	currentId: string,
	parentId: string
): string | undefined => {
	const parent = findTreeNode(tree, parentId);

	if (parent) {
		const currentIndex = parent.children?.findIndex(item => item.id === currentId);
		let parentIndex: string | undefined;

		if (parent.parentId) {
			parentIndex = lookupParents(tree, parent.id, parent.parentId);
		} else {
			const i = tree.findIndex(item => item.id === parent.id);
			if (i >= 0) {
				parentIndex = (i + 1).toString();
			}
		}

		if (currentIndex !== undefined && currentIndex >= 0) {
			return parentIndex !== undefined
				? `${parentIndex}.${currentIndex + 1}`
				: (currentIndex + 1).toString();
		}
	}

	return undefined;
};

/**
 * Формирует индекс категории с учётом вложенности (1.1, 3.3.1 и тп)
 *
 * @param item категория чек-листа
 */
export function useCheckItemIndex(item: ICheckItem | undefined) {
	const {data: itemsTree} = useExtractCheckItemsAsTree(item?.checkListId);

	let index;

	if (item?.parentId) {
		if (itemsTree) {
			index = lookupParents(itemsTree, item.id, item.parentId);
		}
	} else if (itemsTree) {
		index = itemsTree.findIndex(item => item.id === item?.id);
		index = index === -1 ? undefined : index + 1;
	}

	return index?.toString();
}
