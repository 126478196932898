import { useState } from 'react';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import MobileInfo from './info/Info.mobile';
import {Plate} from '@tehzor/ui-components';
import Table from './table/Table';
import EntityAdding from './actions/EntityAdding';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import MobileSelectionClearing from './selection/SelectionClearing.mobile';
import MobileRightButtons from './actions/RightButtons.mobile';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useMobileColumns} from './table/columns.mobile';
import {useTranslation} from "react-i18next";

interface IMobileProps {
	objectId: string;
	warrantyClaimId: string;
	warrantyClaim?: ILinkedWarrantyClaim;
}

export const Mobile = ({objectId, warrantyClaimId, warrantyClaim}: IMobileProps) => {
	const {t} = useTranslation();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const permissions = useWarrantyClaimsPermissions(objectId);

	const mobileColumns = useMobileColumns(warrantyClaimId, objectId);

	useAppHeader(
		{
			title: warrantyClaim
				? `${t('warrantyClaimPage.title')} №${warrantyClaim.number}`
				: t('warrantyClaimPage.title'),
			showBackBtn: true,
			mobileLeftButton:
				Object.keys(selectedRows).length > 0 ? (
					<MobileSelectionClearing onSelectedRowsChange={setSelectedRows} />
				) : undefined,
			mobileRightButtons: (
				<MobileRightButtons
					objectId={objectId}
					warrantyClaimId={warrantyClaimId}
					warrantyClaim={warrantyClaim}
					selectedRows={selectedRows}
				/>
			)
		},
		[warrantyClaim, selectedRows]
	);

	return warrantyClaim ? (
		<div className="page-cont warranty-claim-page">
			<PageBreadcrumbs
				objectId={objectId}
				warrantyClaim={warrantyClaim}
			/>
			<MobileInfo
				objectId={objectId}
				warrantyClaim={warrantyClaim}
			/>
			<Plate
				style={{marginTop: '16px'}}
				withoutPadding
			>
				<Table
					objectId={objectId}
					columns={mobileColumns}
					hideHead
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
					warrantyClaimId={warrantyClaimId}
				/>
			</Plate>

			{permissions.canProblemsAdd && (
				<EntityAdding
					objectId={objectId}
					warrantyClaim={warrantyClaim}
				/>
			)}
		</div>
	) : null;
};
