import {memo, useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {FilterButton, Select2, SelectOption} from '@tehzor/ui-components';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import IExpiredFilter, {
	expiredOptions
} from '@tehzor/tools/interfaces/expiration/IExpirationFilter';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface IExpiredFilterProps {
	label?: string;
	value?: string[];
	customExpiredOptions?: IExpiredFilter[];
}

export const ExpiredFilter = memo((props: IExpiredFilterProps) => {
	const {label = 'Просрочено', value, customExpiredOptions} = props;
	const options = customExpiredOptions || expiredOptions;
	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedValues, setSelectedValues] = useState(value);

	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.expiredFilterValues,
		options
	);

	const handleApply = useCallback(() => {
		dispatch({expired: selectedValues});
	}, [selectedValues, dispatch]);

	const handleClear = useCallback(() => {
		setSelectedValues([]);
	}, []);

	const handleFullClear = useCallback(() => {
		setSelectedValues([]);
		dispatch({expired: undefined});
	}, [dispatch]);

	const handleCancel = useCallback(() => {
		setSelectedValues(value);
	}, [value]);

	useUpdateEffect(() => {
		setSelectedValues(value);
	}, [value]);

	return (
		<div>
			<TranslatedSelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedValues?.length}
				count={selectedValues?.length}
				footer
				noHeader
				trigger={
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel<(typeof options)[0]>(
							label,
							value,
							translatedOptions
						)}
						active={!!(value && value.length > 0)}
						onClear={handleFullClear}
					/>
				}
			>
				<Select2
					multiple
					value={selectedValues}
					onChange={setSelectedValues}
				>
					{translatedOptions?.map(item => (
						<SelectOption
							dataTestId="MenuItem"
							key={item.id}
							itemKey={item.id}
							content={item.name}
						/>
					))}
				</Select2>
			</TranslatedSelectPopup>
		</div>
	);
});
