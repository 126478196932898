import {Column} from 'react-table';
import {IReportsTableRow} from '../../hooks/useReportsTable';
import {ExpanderCell} from './ExpanderCell';
import {FieldCell} from './FieldCell';
import {NameCell} from './NameCell';
import {SelectedCell} from './SelectedCell';
import {SelectedPercentCell} from './SelectedPercentCell';
import {useTranslation} from 'react-i18next';

export const buildColumns = (
	fieldArr: string[],
	fieldMap: Record<string, {id: string; name: string}>
) => {
	const {t} = useTranslation();
	const columns: Array<Column<IReportsTableRow>> = [];

	columns.push(
		{
			id: 'expander',
			Cell: ExpanderCell
		},
		{
			id: 'spaces',
			Header: t('spacesPage.schema.table.buildColumns.spaces.header'),
			Cell: NameCell
		},
		{
			id: 'total',
			Header: t('spacesPage.schema.table.buildColumns.total.header'),
			Cell: SelectedCell
		}
	);

	for (const fieldKey of fieldArr) {
		const item = fieldMap?.[fieldKey];
		if (!item) {
			continue;
		}

		columns.push({
			id: item.id,
			Header: item.name,
			Cell: FieldCell
		});
	}

	columns.push(
		{
			id: 'selected',
			Header: t('spacesPage.schema.table.buildColumns.selected.header'),
			Cell: SelectedCell
		},
		{
			id: 'selectedPercent',
			Header: t('spacesPage.schema.table.buildColumns.selectedPercent.header'),
			Cell: SelectedPercentCell
		}
	);

	return columns;
};
