import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetProblemStatusesResponse = INormalizedData<IProblemStatus>;

/**
 * Возвращает список статусов нарушений
 */
export const requestProblemStatuses = async () => {
	const response = await httpRequests.withToken.get<IGetProblemStatusesResponse>(
		'problem-statuses'
	);
	return response.data;
};
