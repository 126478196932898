import {queryClient} from '@src/api/QueryClient';

import {
	IDeleteCategoriesSetResponse,
	IGetCategoriesSetsResponse,
	requestDeleteCategoriesSet
} from '@src/api/backend/categoriesSets';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

export const useDeleteCategoriesSet = () =>
	useMutation<IDeleteCategoriesSetResponse, IError, string>({
		mutationFn: (categoriesSetId: string) => requestDeleteCategoriesSet(categoriesSetId),

		onSuccess: (_, categoriesSetId) => {
			queryClient.setQueryData<IGetCategoriesSetsResponse>(
				categoriesQueryKeys.list(),
				oldData => {
					if (oldData) {
						const newAllIds = oldData.allIds.filter(id => categoriesSetId !== id);
						const newById = newAllIds.reduce((prev, current) => {
							prev[current] = oldData.byId[current];
							return prev;
						}, {});

						return {
							allIds: newAllIds,
							byId: newById
						};
					}
					return oldData;
				}
			);
			addSuccessToast('Успех', 'Набор видов работ удален');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при удалении набора видов работ');
		}
	});
