import {IChangedIndicators} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {IMutationErrorsMap} from '@src/interfaces/IMutationErrorsMap';
import {offlineJournalActionType} from '../../enums/actionsTypes';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';

export const extractLocalSpaceIndicatorsChangesJournal = (
	records: IChangedIndicators[],
	errorsMap: IMutationErrorsMap
) =>
	records.map(
		record =>
			({
				entity: {
					entityType: offlineJournalActionType.SPACE_INDICATORS_CHANGE,
					newIndicators: record.journalData.newIndicators
				},
				object: {
					objectId: record.objectId,
					objectName: record.journalData.objectName,
					locationName: record.journalData.locationName
				},
				transfer: {
					status: record.transferStatus,
					statusMessage: record.transferStatus
						? offlineModeTransferTitles[record.transferStatus]
						: ''
				},
				createdAt: Number(record.key),
				error: errorsMap[record.key]
			}) as IOfflineJournalEntity
	);
