import {forwardRef, memo, Ref, useCallback, useImperativeHandle} from 'react';
import {AddingEntities} from '@src/api/mutations';
import {useEditableProblem} from '@src/components/EditableProblem/hooks/useEditableProblem';
import {useAddNewProblem} from '@src/core/hooks/mutations/problem/useAddProblem';
import {useCopyProblem} from '@src/core/hooks/mutations/problem/useCopyProblem';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {onlineManager} from '@tanstack/react-query';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useUpdateEffect} from 'react-use';
import {useTranslation} from 'react-i18next';

interface IAddingProblemProps {
	objectId: string;
	links?: IProblem['links'];
	stage: ObjectStageIds;
	scope?: string;
	defaultData?: ISavingProblem;
	saving: boolean;
	problemToCopyId?: string;
	onClose: () => void;
	setSaving: (s: boolean) => void;
	isLoading?: boolean;
}

export interface IAddingProblemRefProps {
	getSavingData: (
		useLocalFiles?: boolean
	) => Promise<(ISavingProblem & {templateId?: string}) | undefined>;
	save: (
		savingData?: ISavingProblem & {templateId?: string},
		extraLinks?: IProblem['links'],
		cacheKey?: string
	) => Promise<string | undefined | void> | string | void;
	cancel: () => void;
	saveCache?: (
		savingData?: ISavingProblem & {templateId?: string},
		extraLinks?: IProblem['links']
	) => Promise<
		| {
				cacheKey: string;
				type: AddingEntities;
				links: IProblem['links'];
		  }
		| undefined
	>;
}

const AddingProblem = (
	{
		objectId,
		links,
		stage,
		scope,
		defaultData,
		saving,
		problemToCopyId,
		onClose,
		isLoading,
		setSaving
	}: IAddingProblemProps,
	ref?: Ref<IAddingProblemRefProps>
) => {
	const {t} = useTranslation();
	const networkStatus = onlineManager.isOnline();
	const {data: object} = useObject(objectId);
	const {createNewProblemCache, saveProblem} = useAddNewProblem(object);
	const copyProblem = useCopyProblem();
	const [fields, getSavingData, , isBlocking] = useEditableProblem(
		objectId,
		stage,
		scope,
		defaultData,
		saving,
		true,
		!!problemToCopyId,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		links,
		isLoading
	);

	useUpdateEffect(() => {
		if (!networkStatus) {
			setSaving(false);
		}
	}, [networkStatus]);
	const saveCache = useCallback(
		async (
			savingData?: ISavingProblem & {templateId?: string},
			extraLinks?: IProblem['links']
		) => {
			setSaving(true);
			if (savingData) {
				const {templateId, ...data} = savingData;
				const cache = await createNewProblemCache({
					objectId,
					links: {...links, ...extraLinks, templateId},
					stage,
					fields: data
				});
				return {
					cacheKey: cache.id,
					links: cache.links,
					type: AddingEntities.PROBLEM
				};
			}
			return undefined;
		},
		[setSaving, createNewProblemCache, objectId, links, stage]
	);
	const save = useCallback(
		(
			savingData?: ISavingProblem & {templateId?: string},
			extraLinks?: IProblem['links'],
			cacheKey?: string
		) => {
			if (savingData) {
				const {templateId, ...data} = savingData;
				if (problemToCopyId) {
					copyProblem(
						objectId,
						problemToCopyId,
						{...links, ...extraLinks, templateId},
						stage,
						data
					);
				} else {
					const key = cacheKey || '';
					data.localCreatedAt = Number(key);
					saveProblem({
						objectId,
						links: {...links, ...extraLinks, templateId},
						stage,
						fields: data,
						key
					});
				}
			}
		},
		[objectId, links, stage, saveProblem, problemToCopyId, copyProblem]
	);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		t('addingProblem.useConfirmDialog.title'),
		t('addingProblem.useConfirmDialog.message'),
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('useConfirmDialog.rejectBtnLabel')
		}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [getClosingConfirmation, isBlocking, onClose]);

	useImperativeHandle(ref, () => ({save, saveCache, cancel, getSavingData}), [
		save,
		saveCache,
		cancel,
		getSavingData
	]);

	return (
		<>
			{fields}
			{closingDialog}
		</>
	);
};

export default memo(forwardRef<IAddingProblemRefProps, IAddingProblemProps>(AddingProblem));
