import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	IChangeTasksFiltersPayload, IChangeTasksPageSizePayload, IChangeTasksSortPayload,
	ITasksFiltersState,
	ITasksPageSettingsListState,
	ITasksSortState
} from "@src/store/modules/settings/pages/tasks/interfaces";

const initialState: ITasksPageSettingsListState = {
	filters: {},
	sort: {
		createdAt: false
	},
	pageSize: 20,
	offset: 0,
	selectedRows: []
}

export const tasksListSlice = createSlice({
	name: 'tasksList',
	initialState,
	reducers: {
		changeFilters: (state, {payload}: PayloadAction<IChangeTasksFiltersPayload<ITasksFiltersState>>) => ({
			...state,
			filters: payload.filters
		}),
		clearFilters: (state) => ({
			...state,
			filters: {}
		}),
		changeSort: (state, {payload}: PayloadAction<IChangeTasksSortPayload<ITasksSortState>>) => ({
			...state,
			sort: payload.sort
		}),
		changePageSize: (state, {payload}: PayloadAction<IChangeTasksPageSizePayload>) => ({
			...state,
			pageSize: payload.pageSize
		}),
		changeOffset: (state, {payload}: PayloadAction<{ offset: number}>) => ({
			...state,
			offset: payload.offset
		}),
		changeSelectedRows: (state, {payload}: PayloadAction<{selectedRows: string[]}>) => ({
			...state,
			selectedRows: payload.selectedRows
		})
	}
})

export const {actions: tasksListActions, reducer: tasksListReducer} = tasksListSlice;