import styles from './BottomBar.module.less';
import {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import {extractStructuresPageSettings} from '@/entities/Structures';
import {SchemaSortChanger} from '../../components/SchemaSortChanger/SchemaSortChanger';
import {ProcessFilter} from '../../components/ProcessFilter/ProcesFilter';
import {StructuresLegend} from '../../components/StructuresLegend/StructuresLegend';
import {BottomBar} from '@/shared/ui/EntitiesPage/BottomBar/BootomBar';

interface IBottomBarProps {
	objectId: string;
}

export const StructuresBottomBar = memo(({objectId}: IBottomBarProps) => {
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));

	return (
		<BottomBar>
			<div className={styles.controls}>
				<ProcessFilter />
				{displayMode === StructuresDisplayMode.SCHEMA && (
					<SchemaSortChanger objectId={objectId} />
				)}
			</div>
			<StructuresLegend objectId={objectId} />
		</BottomBar>
	);
});
