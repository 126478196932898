import {useCallback} from 'react';
import {useAsyncFn} from 'react-use';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import {useEnrichedGroups} from '../../hooks/useEnrichedGroups';
import {mobileColumns} from './columns.mobile';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {queryClient} from '@src/api/QueryClient';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {useFilteredWorkingGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';

interface ITableProps {
	loading?: boolean;
}

export const MobileTable = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const {data: filteredGroups} = useFilteredWorkingGroupsAsArray();

	const [, loadResponsibilityRules] = useAsyncFn(() =>
		queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()})
	);

	const preparedData = useEnrichedGroups(filteredGroups);

	const handleRowClick = useCallback((item: IEnrichedWorkingGroup) => {
		pushPath(`/manage/working-groups/${item.id}`);
	}, []);

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={loadResponsibilityRules}>
				<LoadingPanel active={loading}>
					<EntitiesTable
						headVisible={false}
						columns={mobileColumns}
						data={preparedData}
						onRowClick={handleRowClick}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
