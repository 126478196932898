import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingProblemComment} from '@src/interfaces/saving/ISavingProblemComment';
import {IEditCommentResponse, makeCommentEditRequest} from '@src/api/backend/comment';

export type IEditProblemCommentPayload = IEditCommentResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditCommentResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении комментария');
	return {
		type: types.EDIT_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет комментарий нарушения
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const editProblemComment = (
	objectId: string,
	commentId: string,
	fields: ISavingProblemComment
) =>
	createApiAction<IEditCommentResponse>(request, success, failure, () =>
		makeCommentEditRequest(objectId, commentId, fields));
