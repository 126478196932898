import INormalizedData from '../interfaces/INormalizedData';

/**
 * Нормализует данные в формат {byId, allIds}
 *
 * @param items элементы
 * @returns {{byId, allIds}}
 */
export const normalizeData = <T extends {id: string}>(items: T[]): INormalizedData<T> => ({
	byId: items.reduce((acc, item) => {
		acc[item.id] = item;
		return acc;
	}, {}),
	allIds: items.map(item => item.id)
});
