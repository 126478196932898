import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from "@tehzor/tools/interfaces/IExportResponse";
import getEmailsArray from "@src/utils/getEmailsArray";

/**
 * Отправляет запрос на экспорт ответа на нарушение
 *
 * @param templateId id шаблона экспорта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param objectId id объекта
 * @param commentId id комментария
 */
export const makeProblemReplyExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	commentId: string
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId,
	};
	const response = await httpRequests.exportWithToken.get<IExportResponse>(
		`problem-replies/${commentId}/export`,
		{
			params
		}
	);
	return response.data;
};
