import {makeProblemsExportRequest} from '@src/api/backend/problems';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {addExportToast} from '@src/components/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';

interface IExportProblemsParams {
	templateId: string;
	objectId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	filters: Record<string, unknown> | undefined;
	sort: Record<string, boolean> | undefined;
	selected?: string[] | undefined;
}

/**
 * Экспортирует список нарушений
 * {mutate, mutateAsync}
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param filters фильтры
 * @param sort сортировка
 * @param selected выбранные элементы
 */

export const useExportProblems = () =>
	useMutation<IExportResponse, Error, IExportProblemsParams>({
		mutationKey: problemsQueryKeys.export(),
		mutationFn: params => {
			const {templateId, objectId, createDocument, email, filters, sort, selected} = params;
			return makeProblemsExportRequest(
				templateId,
				objectId,
				createDocument,
				email,
				filters,
				sort,
				selected
			);
		},
		onSuccess: (response, params) => {
			const {email} = params;
			if (response.link) {
				addExportToast(
					email ? 'Документ отправлен' : 'Экспорт документа завершен',
					response.link,
					response.file.name,
					'',
					response.document
				);
			}
			if (response.warnings?.itemsLimit !== undefined) {
				addWarningToast(
					'Внимание',
					`превышен лимит записей (${String(response.warnings.itemsLimit)})`
				);
			}
		},
		onError: () => {
			addErrorToast('Ошибка', 'при экспорте');
		}
	});
