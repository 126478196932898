import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import combineSectors from '@tehzor/tools/utils/combineSectors';
import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';

/**
 * Возвращает выбранные секторы в виде массива имён,
 * имена сгенерированных слоёв обрабатываются таким образом,
 * чтобы схлопнуть смежные секторы, если это возможно
 *
 * @param sectors выбранные секторы
 * @param layers слои плана
 */
export const getSectorsDisplayValue = (sectors: ILocationMarker[], layers: ILayer[]): string[] => {
	if (!layers.length) {
		return [];
	}
	let allNames: string[] = [];
	for (const layer of layers) {
		if (layer.shapes) {
			let names: string[] = layer.shapes.reduce((acc, shape) => {
				if (sectors.some(sector => sector.sector === shape.id)) {
					acc.push(shape.name);
				}
				return acc;
			}, [] as string[]);

			if (layer.type === 'generated') {
				names = combineSectors(names);
			}
			if (names.length) {
				allNames = allNames.concat(names);
			}
		}
	}
	return allNames;
};
