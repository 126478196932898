import {EntityGridItem} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IPrescriptionProps {
	className?: string;
	value: string;
}

const Prescription = ({className, value}: IPrescriptionProps) => {
	const {t} = useTranslation();

	return (
		<EntityGridItem
			className={className}
			icon={<i className="tz-prescription-20" />}
			label={t('components.viewFields.prescription.label')}
			fullRow
		>
			<div style={{whiteSpace: 'pre-wrap'}}>{value}</div>
		</EntityGridItem>
	);
};

export default Prescription;
