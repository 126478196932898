import useAppSelector from '@src/core/hooks/useAppSelector';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useMemo} from 'react';

export interface IPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canEditDefault?: boolean;
	canDelete?: boolean;
}

export const useSpaceStatusesSetPermissions = (): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);
	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canView: hasPermission(user.roles, 'spaceStatusesSetsAdministration'),
			canAdd: hasPermission(user.roles, 'spaceStatusesSetsAdd'),
			canEdit: hasPermission(user.roles, 'spaceStatusesSetsEdit'),
			canEditDefault: hasPermission(user.roles, 'spaceStatusesSetsEdit', UserRoleScopes.ALL),
			canDelete: hasPermission(user.roles, 'spaceStatusesSetsDelete')
		};
	}, [user.roles]);
};
