import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

export const useTranslatedDictionaryByIds = (key: dictionaryKeys, ids: string[]) => {
	const {t} = useTranslation();

	if (!ids || !ids.length) return [];

	return ids.map(id => t(`${key}.${id}`));
};
