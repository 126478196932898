import {
	makeSpaceTypeDecorationGetRequest,
	makeSpaceTypeDecorationsGetAllRequest
} from '@src/api/backend/spaceTypeDecoration';
import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {ISpaceTypesDecorationsQueryKey, spaceTypesDecorationsQueryKeys} from '../keys';

export const useSpaceTypeDecorationsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(spaceTypesDecorationsQueryKeys.list(), {
		networkMode: 'offlineFirst',
		queryFn: makeSpaceTypeDecorationsGetAllRequest,
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24 * 7
	});
	queryClient.setQueryDefaults(spaceTypesDecorationsQueryKeys.allById(), {
		networkMode: 'offlineFirst',
		queryFn: ({queryKey}: QueryFunctionContext<ISpaceTypesDecorationsQueryKey>) => {
			const [, id] = queryKey;
			return makeSpaceTypeDecorationGetRequest(id);
		},
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24 * 7
	});
};
