import classNames from 'classnames';
import {LoadingPanel} from '@tehzor/ui-components';
import {ClaimsPageCounter} from './components/ClaimsPageHeader';
import ClaimsFilters from './components/filters/ClaimsFilters';
import ClaimsTable from './components/table/ClaimsTable';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import ObjectPageBreadcrumbs from './components/ObjectPageBreadcrumbs';
import AllPageBreadcrumbs from './components/AllPageBreadcrumbs';
import {useParams} from 'react-router-dom';
import {useWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/hooks';
import './WarrantyClaimsPage.less';

const WarrantyClaimsPage = ({className}: {className?: string}) => {
	useScrollRestoration();

	const {objectId} = useParams<{objectId?: string}>();
	const {data: claimsData, isLoading} = useWarrantyClaims(objectId);

	return (
		<LoadingPanel
			className="w-claims-page__loading-panel"
			active={isLoading}
		>
			<div
				className={classNames(
					'page-cont',
					'w-claims-page',
					'warranty-claims-table',
					{'w-claims-page_small-margin': !objectId},
					className
				)}
			>
				<div className="w-claims-page__head-wrap">
					{objectId ? (
						<ObjectPageBreadcrumbs objectId={objectId} />
					) : (
						<AllPageBreadcrumbs />
					)}

					<div className="w-claims-page__filters-bar">
						<ClaimsFilters objectId={objectId} />
						<ClaimsPageCounter objectId={objectId} />
					</div>
				</div>
				<ClaimsTable
					objectId={objectId}
					claimsData={claimsData}
				/>
			</div>
		</LoadingPanel>
	);
};

export default WarrantyClaimsPage;
