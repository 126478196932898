import * as types from '../constants';
import {IWarrantyStatisticsFiltersState} from '../reducers/filters';

/**
 * Изменяет фильтры
 *
 * @param value новые фильтры
 */
export const changeFilters = (value: IWarrantyStatisticsFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: value
});

/**
 * Сбрасывает фильтры
 */
export const clearFilters = () => ({type: types.CLEAR_FILTERS});

export interface IDeleteFiltersItemPayload {
	field: string;
	value: string;
}

/**
 * Удаляет элемент фильтра
 *
 * @param field поле в фильтрах
 * @param value id удаляемого элемента
 */
export const deleteFiltersItem = (field: string, value: string) => ({
	type: types.DELETE_FILTERS_ITEM,
	payload: {field, value} as IDeleteFiltersItemPayload
});

export interface IDeleteFiltersItemsPayload {
	field: string;
	value: string[];
}

/**
 * Удаляет элементы фильтра
 *
 * @param field поле в фильтрах
 * @param value id удаляемых элементов
 */
export const deleteFiltersItems = (field: string, value: string[]) => ({
	type: types.DELETE_FILTERS_ITEMS,
	payload: {field, value} as IDeleteFiltersItemsPayload
});
