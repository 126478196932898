import * as types from '@src/constants/settings/export';

/**
 * Изменяет выбранный
 *
 * @returns {{type, payload}} action
 * @param {string} key ключ
 * @param {string} value значение
 */
export const changeSelectedTemplate = (key: string, value: string) => ({
	type: types.CHANGE_EXPORT_SELECTED_TEMPLATE,
	payload: {key, value}
});

/**
 * Изменяет адрес, используемый при отправке на email
 *
 * @param {string} value email адрес
 * @returns {{type, payload}} action
 */
export const toggleEmailEnabled = (value?: boolean) => ({
	type: types.TOGGLE_EXPORT_EMAIL_ENABLED,
	payload: value
});

/**
 * Переключает флаг формирования документа
 *
 * @param {string} value email адрес
 * @returns {{type, payload}} action
 */
export const toggleMakeDocument = (value?: boolean) => ({
	type: types.TOGGLE_EXPORT_MAKE_DOKUMENT,
	payload: value
});

/**
 * Изменяет адрес, используемый при отправке на email
 *
 * @param {string} emailAddress email адрес
 * @returns {{type, payload}} action
 */
export const changeEmailAddress = (emailAddress: string) => ({
	type: types.CHANGE_EXPORT_EMAIL_ADDRESS,
	payload: emailAddress
});
