import { memo } from 'react';
import {TagsContainer, SpaceIndicatorTag} from '@tehzor/ui-components';
import {useSpaceIndicatorsAsMap} from '@src/core/hooks/queries/spaceIndicatorsSets/hooks';
import {useObject} from '@src/core/hooks/queries/objects/hooks';

interface IIndicatorsProps {
	objectId: string;
	value: string[];
}

export const Indicators = memo(({objectId, value}: IIndicatorsProps) => {
	const {data: object} = useObject(objectId);
	const {data: indicators} = useSpaceIndicatorsAsMap(object);

	return (
		<TagsContainer>
			{value.map(id => {
				const indicator = indicators?.byId[id];
				return indicator ? (
					<SpaceIndicatorTag
						key={id}
						color={indicator.color}
						label={indicator.name}
					/>
				) : null;
			})}
		</TagsContainer>
	);
});
