import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {parse} from 'query-string';
import {isEmpty} from 'lodash';

const isNotEmpty = (item: unknown) => {
	if (item === null) {
		return true;
	}
	if (typeof item === 'object') {
		return !isEmpty(item);
	}
	return item !== undefined;
};
/**
 * Проверяет заданы ли фильтры
 *
 * @param objectId id объекта
 * @param filters фильтры
 */
export const useFiltersActive = <T>(objectId: string, filters: T) => {
	const {search} = useLocation();
	const params = parse(search, {arrayFormat: 'index'});

	return useMemo(() => {
		if (!filters) return false;

		const array = Object.values(filters);
		if (
			search &&
			Object.keys(params).length === 1 &&
			params.objects?.length === 1 &&
			params.objects[0] === objectId
		) {
			return false;
		}
		if (objectId === 'all') {
			return array.some(isNotEmpty);
		}
		// Убирает значение фильтра объекта если страница одного конкретного объекта
		if (array.length > 1 && array[Object.keys(filters).indexOf('objects')] !== objectId) {
			array.splice(Object.keys(filters).indexOf('objects'), 1);
			return array.some(isNotEmpty);
		}

		if (array.length === 1 && Array.isArray(array[0])) {
			if (array[0].length === 0 || array[0][0] === objectId) {
				return false;
			}
			if ((array[0].length === 1 && array[0][0] !== objectId) || array[0].length > 1) {
				return true;
			}
		}
		return false;
	}, [objectId, filters, search]);
};
