import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetSpaceOwnersResponse = INormalizedData<ISpaceOwner>;

/**
 * Возвращает список собственников помещений
 */
export const requestSpaceOwners = async () => {
	const response = await httpRequests.withToken.get<IGetSpaceOwnersResponse>('space-owners');
	return response.data;
};
