import {
	useInternalAcceptanceLocalProblemsEntities,
	useInternalAcceptanceProblemsEntities
} from './problems/hooks';
import {useInternalAcceptanceInspectionsEntities} from './inspections/hooks';
import {useMemo} from 'react';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';

export const useInternalAcceptanceEntities = (objectId: string, checkId: string) => {
	const {data: problems} = useInternalAcceptanceProblemsEntities(objectId, checkId);
	const {data: localProblems} = useInternalAcceptanceLocalProblemsEntities(objectId, checkId);
	const {data: inspections} = useInternalAcceptanceInspectionsEntities(objectId, checkId);

	return useMemo(
		() =>
			[...(localProblems || []), ...(problems || []), ...(inspections || [])].sort(
				(a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0)
			) as ICheckEntity[],
		[problems, inspections, localProblems]
	);
};
