import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const getAllIds = (state: IState) => state.pages.problem.commentsStories.allIds || [];
const getById = (state: IState) => state.pages.problem.commentsStories.byId || {};

/**
 * Извлекает текущую историю комментариев нарушения
 */
export const extractProblemCommentsStoriesAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map(id => byId[id])
);
