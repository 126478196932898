import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {
	IGetInspectionPayload,
	IEditInspectionPayload,
	IGetInspectionRequestPayload,
	IDeleteInspectionPayload,
	IGetPerformersDelegationHistoriesByInspectionPayload
} from './actions';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import config from '@src/core/config';
import {
	ILoadedEntityState,
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess
} from '@tehzor/tools/utils/reducersHandlers';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {IEditInspectionPerformersPayload} from './actions/editPerformers';

const {entitiesCacheTime} = config;

export interface IInspectionState {
	data?: ILinkedInspection;
	expires?: number;
	performersDelegationHistories: ILoadedEntityState<IDelegationHistory>;
}

const getInitialState = (): IInspectionState => ({
	expires: undefined,
	performersDelegationHistories: {
		byId: {},
		allIds: [],
		loaded: false
	}
});

const isAnotherInspection = (state: IInspectionState, payload: IEditInspectionPayload) =>
	!(state.data?.id === payload.id);

const updateInspection = (state: IInspectionState, {payload}: {payload: IEditInspectionPayload}) =>
	isAnotherInspection(state, payload)
		? state
		: {
				...state,
				data: {...state.data, ...payload},
				expires: Date.now() + entitiesCacheTime * 1000,
				performersDelegationHistories: getInitialState().performersDelegationHistories
		  };

export default createReducer<IInspectionState>(getInitialState, {
	[types.GET_REQUEST]: (state, {payload}: {payload: IGetInspectionRequestPayload}) => {
		if (state.data?.id === payload.inspectionId) {
			return {...state, data: undefined};
		}
		return getInitialState();
	},
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetInspectionPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_FAILURE]: getInitialState,
	[types.EDIT_SUCCESS]: updateInspection,
	[types.EDIT_PERFORMERS_SUCCESS]: (
		state,
		{payload}: {payload: IEditInspectionPerformersPayload}
	) => {
		const newState = updateInspection(state, {payload});
		newState.performersDelegationHistories = getLoadedEntitiesInitialState();
		return newState;
	},
	[types.GET_PERFORMERS_DELEGATION_HISTORIES_REQUEST]: state => {
		state.performersDelegationHistories = getLoadedEntitiesInitialState();
	},
	[types.GET_PERFORMERS_DELEGATION_HISTORIES_SUCCESS]: (
		state,
		params: {payload: IGetPerformersDelegationHistoriesByInspectionPayload}
	) => {
		state.performersDelegationHistories = getLoadedEntitiesSuccess<IDelegationHistory>()(
			state.performersDelegationHistories,
			params
		);
	},
	[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteInspectionPayload}) => {
		if (!(state.data?.id === payload.inspectionId)) {
			return state;
		}
		return getInitialState();
	},
	[CLEAR_STORE]: getInitialState
});
