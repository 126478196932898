import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISpaceStatusOrder} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatusOrder';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditSpaceStatusesOrderResponse = INormalizedData<ISpaceStatus>;

/**
 * Изменяет порядок статусов помещений по id набора
 *
 * @param spaceStatusesSetId id набора
 * @param order порядок статусов помещений
 */
export const requestEditOrderSpaceStatuses = async (
	spaceStatusesSetId: string,
	order: ISpaceStatusOrder[]
) => {
	const response = await httpRequests.withToken.patch<IEditSpaceStatusesOrderResponse>(
		'space-statuses/edit-order-space-statuses',
		{spaceStatusesSetId, order}
	);
	return response.data;
};
