import {Column} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {TaskMobileCell} from './TaskMobileCell';

export const columnsMobile: Array<Column<IEnrichedTask>> = [
	{
		id: 'info',
		Cell: TaskMobileCell,
		accessor: item => item,
		className: 'tasks-page__m-table'
	}
];
