import { useCallback } from 'react';
import * as React from 'react';
import IExportTemplate, {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';
import useToggle from 'react-use/lib/useToggle';
import {useExportInternalAcceptances} from '../mutations/InternalAcceptances/useExportInternalAcceptances';

const availableDestinations = [ExportTemplatesDestinationId.CHECKS, ExportTemplatesDestinationId.CHECKS_PROBLEMS];

export function useInternalAcceptancesExport(objectId = 'all'): [React.ReactNode, () => void] {
	const {filters, sort, selectedRows} = useAppSelector(s => extractInternalAcceptancesPageSettings(s, objectId));
	const [isOpen, toggleOpen] = useToggle(false);
	const exportInternalAcceptances = useExportInternalAcceptances();
	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			exportInternalAcceptances(
				template.id,
				email,
				createDocument,
				objectId !== 'all' ? objectId : undefined,
				filters,
				sort,
				selectedRows
			)
			toggleOpen(false);
		},
		[exportInternalAcceptances, objectId, filters, sort, selectedRows]
	);

	const dialog = (
		<ExportDialog
			pageKey="internalAcceptances"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={toggleOpen}
		/>
	);

	return [dialog, toggleOpen];
}
