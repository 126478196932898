import useAppSelector from '@src/core/hooks/useAppSelector';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {CheckListHeaderActions} from '@src/pages/CheckListPage/components/actions/CheckListHeaderActions';
import {extractCheckRecordsIsChanging} from '@src/store/modules/pages/checkLists/selectors/records';
import { memo } from 'react';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {RecordStatusSelect} from '@src/components/CheckRecordStatusSelect/RecordStatusSelect';
import {useExtractCheckItemsAsTree} from '@src/core/hooks/queries/checkItems/hooks';
import {useCheckListStatusPermission} from '@src/core/hooks/permissions/useCheckListStatusPermission';
import classNames from 'classnames';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {CategoryCount} from '../СategoryCount';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {Indicators} from '@src/pages/CheckListPage/components/Indicators/Indicators';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {CheckListType} from '../CheckListType/CheckListType';

interface ICheckListHeader {
	checkList: ICheckList;
	onClick?: (id: string) => void;
}

export const CheckListDesktop = memo((props: ICheckListHeader) => {
	useScrollRestoration();
	const {onClick, checkList} = props;
	const {objectId, spaceId, workAcceptanceId, listId, itemId} = useStrictParams<{
		objectId: string;
		stage: string;
		listId: string;
		spaceId: string;
		itemId: string;
		workAcceptanceId?: string;
	}>();
	const {canStatusEdit, availableStatuses} = useCheckListStatusPermission(
		'check-lists-space-status-edit',
		objectId
	);
	const {data: checkItemsTree} = useExtractCheckItemsAsTree(checkList.id);
	const isChanging = useAppSelector(extractCheckRecordsIsChanging);
	const {changeCheckListRecord, isLoading} = useChangeCheckRecord(objectId, spaceId, checkList);

	const handleClick = () => {
		onClick?.(checkList.id);
	};

	const disabled = isChanging || !canStatusEdit || checkList.type !== CheckListTypeId.OPERATIONS;

	return (
		<div
			role="presentation"
			className={classNames('check-list-page__d-plate-container', {
				'check-list-page__d-plate-container_active': listId === checkList.id && !itemId
			})}
			onClick={handleClick}
		>
			<div className="check-list-page__d-plate-header">
				<div className="check-list-page__d-plate-header-title">
					{checkList.name}
					{checkList.type === CheckListTypeId.ACCEPTANCES && (
						<CheckListType typeId={checkList.type} />
					)}
				</div>
				<Indicators
					checkListId={checkList.id}
					spaceId={spaceId}
					workAcceptanceId={workAcceptanceId}
					className="check-list-page__d-plate-header-indicators"
				/>
				<div className="check-list-page__d-plate-header-status">
					<ClickPreventWrap className="check-list-page__d-plate-header-status-wrap">
						<RecordStatusSelect
							item={checkList}
							mutation={changeCheckListRecord}
							showLoader={isLoading}
							disabled={disabled}
							availableStatuses={availableStatuses}
						/>
					</ClickPreventWrap>
				</div>
				<CategoryCount count={checkItemsTree?.length} />
			</div>
			{checkList.type === CheckListTypeId.OPERATIONS && (
				<div className="check-list-page__d-plate-container-actions">
					<ClickPreventWrap>
						<CheckListHeaderActions listId={checkList.id} />
					</ClickPreventWrap>
				</div>
			)}
		</div>
	);
});
