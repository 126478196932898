import { memo } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {formDocumentLink} from '@tehzor/tools/utils/links';
import {LinkButton} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IEntityDocsItemsProps {
	documents: IDocument[];
}

const documentIcon = <i className="tz-document-16" />;

export const DOCUMENTS_COUNT = 4;

export const EntityDocsItems = memo((props: IEntityDocsItemsProps) => {
	const {t} = useTranslation();

	const {pushPath} = useChangePath();
	const {documents} = props;

	const renderedDocuments = documents.slice(0, DOCUMENTS_COUNT).map(document => {
		const title = document?.number ? `${t('entityDocs.pluralName')} №${document.number.toString()}` : t('entityDocs.emptyName');

		const handleClick = () => {
			pushPath(formDocumentLink(document.id));
		};

		return (
			<LinkButton
				onClick={handleClick}
				type="filled"
				leftIcon={documentIcon}
				label={title}
			/>
		);
	});

	return <div className="entity-docs__list">{renderedDocuments}</div>;
});
