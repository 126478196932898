import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export type IGetSpaceTypeDecorationSetResponse = ISpaceTypeDecorationSet;

/**
 * Возвращает один набор типов отделки помещений по Id
 *  *
 * @param id id набора
 */
export const requestSpaceTypeDecorationSet = async (id: string) => {
	const response = await httpRequests.withToken.get<IGetSpaceTypeDecorationSetResponse>(
		`space-type-decoration-sets/${id}`
	);

	return response.data;
};
