import {IProblemView} from '@tehzor/tools/interfaces/problemView/IProblemView';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddProblemViewResponse = IProblemView;

export const makeProblemViewAddRequest = async (objectId: string, problemId: string) => {
	const response = await httpRequests.withToken.post<IAddProblemViewResponse>(
		`problems/add-problem-view/${problemId}`,
		{
			objectId
		}
	);
	return response.data;
};
