import {IconMenu, MenuItem} from '@tehzor/ui-components';
import { useCallback, useContext } from 'react';
import {CellProps} from 'react-table';
import {useWorkingGroupPermissions} from '@src/core/hooks/permissions/useWorkingGroupPermissions';
import {IPreparedPerformer} from '../../interfaces/IPreparedPerformer';
import {DispatchActionCtx} from '../DispatchActionCtx';

export const MenuCell = ({row}: CellProps<IPreparedPerformer>) => {
	const menuActions = [];
	const perms = useWorkingGroupPermissions();
	const dispatchAction = useContext(DispatchActionCtx);
	const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [row]);

	if (perms.canDelete) {
		menuActions.push(
			<MenuItem
				key="edit"
				className="menu-item_red"
				icon={<i className="tz-delete"/>}
				onClick={handleDelete}
			>
				Удалить исполнителя
			</MenuItem>
		);
	}

	return menuActions.length ? <IconMenu>{menuActions}</IconMenu> : null;
};
