import * as types from '../../constants/uiConstants';

/**
 * Переключает индикатор выполнения фоновых задач
 * @param {Boolean} value фоновые задачи активны/не активны
 * @returns {{type, payload: *}} action
 */
export const togglePlanBusy = value => ({
		type: types.TOGGLE_PLAN_BUSY,
		payload: value
	});