import {IGetListFieldSettingsResponse} from '@src/api/backend/fieldSettings';
import {fieldSettingsQueryKeys} from '@src/api/cache/fieldSettings/keys';
import {useQuery} from '@tanstack/react-query';
import {
	extractFieldSettingsListArray,
	extractFieldSettingsListById,
	extractFieldSettingsListBySetIds,
	extractFieldSettingsListMap
} from './selectors';
import {useFieldsPermissions} from '@/entities/FieldSettingsSets/model/hooks/useFieldsPermissions';

export const useFieldSettingsList = <T = IGetListFieldSettingsResponse>(
	select?: (data: IGetListFieldSettingsResponse) => T
) => {
	const permissions = useFieldsPermissions();

	return useQuery({
		queryKey: fieldSettingsQueryKeys.list(),
		meta: {
			error: 'при загрузке списка настроек полей'
		},
		select,
		enabled: permissions.canViewSettings
	});
};

export const useFieldSettingsArray = () => useFieldSettingsList(extractFieldSettingsListArray);

export const useFieldSettingsMap = () => useFieldSettingsList(extractFieldSettingsListMap);

export const useFieldSettingsBySetIds = (setIds?: string[]) =>
	useFieldSettingsList(data => extractFieldSettingsListBySetIds(data, setIds));

export const useFieldSettingsById = (id: string) =>
	useFieldSettingsList(data => extractFieldSettingsListById(data, id));
