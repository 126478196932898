import {CellProps} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {Tag, TagsContainer} from '@tehzor/ui-components';
import styles from './StructureNameCell.module.less';

export const StructureNameCell = ({row}: CellProps<IEnrichedStructure>) => {
	const structure = row.original;

	return (
		<>
			{structure.name}
			{structure.problemsStats?.length ? (
				<TagsContainer className={styles.container}>
					{structure.problemsStats.map(item => (
						<Tag
							key={item.key}
							className={{
								label: styles.cellLabel,
								icon: styles.cellIcon
							}}
							icon={
								<i
									className="tz-problem-16"
									style={{color: item.color}}
								/>
							}
							label={item.value.toString()}
							size="large"
						/>
					))}
				</TagsContainer>
			) : null}
		</>
	);
};
