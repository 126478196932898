import AcceptanceStats from '@src/pages/StatisticsPage/components/acceptance/AcceptanceStats';
import {BuildingStats} from '@src/pages/StatisticsPage/components/building/BuildingStats';
import WarrantyStats from '@src/pages/StatisticsPage/components/warranty/WarrantyStats';

const sections = [
	{path: 'building', label: 'Строительство'},
	{path: 'acceptance', label: 'Приёмка'},
	{path: 'warranty', label: 'Гарантия'}
];

export const statisticsRoutes = [
	{
		path: `${sections[0].path}/*`,
		element: <BuildingStats/>
	},
	{
		path: `${sections[1].path}/*`,
		element: <AcceptanceStats/>
	},
	{
		path: `${sections[2].path}/*`,
		element: <WarrantyStats/>
	}
];
