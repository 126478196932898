import { useState } from 'react';
import * as React from 'react';
import {IExtendedSavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IExtendedSavingProblemTag';
import {IProblemTagsSetPermissions} from '@src/core/hooks/permissions/useProblemTagsSetPermissions';
import {EditableProblemTagWrap} from '../EditableProblemTagWrap';

export const useEditableProblemTags = (
	tags: IExtendedSavingProblemTag[],
	setArray: React.Dispatch<React.SetStateAction<IExtendedSavingProblemTag[]>>,
	permissions: IProblemTagsSetPermissions,
	disabledActions?: boolean,
	problemTagsSetId?: string
): React.ReactNode[] | null => {
	const [activeTagId, setActiveTagId] = useState<string| undefined>();
	if (!tags || !problemTagsSetId) {
		return null;
	}

	return tags.map((tag, index) => (
		<EditableProblemTagWrap
			key={tag.id}
			index={index}
			tag={tag}
			problemTagsSetId={problemTagsSetId}
			tags={tags}
			setArray={setArray}
			setActiveTagId={setActiveTagId}
			activeTagId={activeTagId}
			permissions={permissions}
			disabledActions={disabledActions}
		/>
	));
};
