import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {ColumnsCustomizer} from '@src/components/ColumnsCustomizer/ColumnsCustomizer';
import {useInspectionSettings} from '@src/core/hooks/settings/useInspectionSettings';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import {inspectionsActions} from '@src/store/modules/settings/pages/inspections/slice';
import {useIsFetching} from '@tanstack/react-query';
import {LoadingPanel} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import classNames from 'classnames';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import './InspectionsPage.less';
import AllPageBreadcrumbs from './components/AllPageBreadcrumbs';
import InspectionsPageCounter from './components/InspectionsPageCounter';
import ObjectPageBreadcrumbs from './components/ObjectPageBreadcrumbs';
import ActionsDesktop from './components/actions/Actions.desktop';
import MobileEntityAdding from './components/actions/InspectionAdding.mobile';
import {InspectionsFilters} from './components/filters/InspectionsFilters';
import InspectionsTable from './components/table/InspectionsTable';

interface IInspectionsPageProps {
	className?: string;
}

export const InspectionsPage = ({className}: IInspectionsPageProps) => {
	const dispatch = useAppDispatch();
	useScrollRestoration();
	const {objectId} = useParams<{objectId?: string}>();
	const fieldsSettings = useInspectionSettings(objectId);
	const isFetching = useIsFetching({queryKey: inspectionsQueryKeys.list()});
	const isDesktop = useIsDesktop();
	const {tableSettings} = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const {changeColumnVisible, showAllColumn} = inspectionsActions;

	const onChangeColumnVisible = useCallback(
		(columnKey: string) => {
			dispatch(changeColumnVisible({objectId: objectId || 'all', columnKey}));
		},
		[dispatch, changeColumnVisible, objectId]
	);

	const onShowAllColumns = useCallback(() => {
		dispatch(showAllColumn({objectId: objectId || 'all'}));
	}, [dispatch, showAllColumn, objectId]);

	const actions = <ActionsDesktop objectId={objectId} />;

	return (
		<LoadingPanel
			className="inspections-page__loading-panel"
			active={!!isFetching}
		>
			<div
				className={classNames(
					'page-cont',
					'inspections-page',
					{'inspections-page_small-margin': !objectId},
					className
				)}
			>
				{objectId !== undefined ? (
					<ObjectPageBreadcrumbs objectId={objectId}>
						{isDesktop && actions}
					</ObjectPageBreadcrumbs>
				) : (
					isDesktop && <AllPageBreadcrumbs>{actions}</AllPageBreadcrumbs>
				)}
				<div className="inspections-page__filters-bar">
					<InspectionsFilters
						objectId={objectId}
						settings={fieldsSettings}
					/>
				</div>
				<div className="inspections-page__counter-bar">
					<InspectionsPageCounter objectId={objectId} />
					{isDesktop && (
						<ColumnsCustomizer
							tableSettings={tableSettings}
							onChangeColumnsVisible={onChangeColumnVisible}
							onShowAllColumns={onShowAllColumns}
						/>
					)}
				</div>
				<InspectionsTable objectId={objectId} />
				{objectId && !isDesktop && <MobileEntityAdding objectId={objectId} />}
			</div>
		</LoadingPanel>
	);
};
