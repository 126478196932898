import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {
	ActionButtons,
	Button,
	Dialog,
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextField,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import { useCallback, useEffect, useState } from 'react';
import {
	useExtractSpaceTypesAsArray,
	useExtractSpaceTypesById
} from '@src/core/hooks/queries/spaceTypes/hooks';

export interface ICurrentShape {
	layerIndex: number;
	shapeIndex: number;
	spaceType: SpaceTypeId;
	name: string;
}

interface IEditableShapeDialogProps extends ICurrentShape {
	editing: boolean;
	layerType: string;
	onClose: () => void;
	onSave: (shape: ICurrentShape) => void;
}

export const EditableShapeDialog = ({
	editing,
	onClose,
	onSave,
	name,
	spaceType,
	layerType,
	...indexes
}: IEditableShapeDialogProps) => {
	const [editedName, setEditedName] = useState(name);
	const [editedSpaceType, setEditedSpaceType] = useState(spaceType);
	const {data: spaceTypes} = useExtractSpaceTypesAsArray();
	const {data: spaceTypesMap} = useExtractSpaceTypesById();

	const handleSave = useCallback(() => {
		onSave({...indexes, name: editedName, spaceType: editedSpaceType});
	}, [editedName, editedSpaceType]);

	useEffect(() => {
		setEditedName(name);
		setEditedSpaceType(spaceType);
	}, [name, spaceType]);

	return (
		<Dialog
			title="Изменить название"
			className={{root: 'plan-page__dialog', body: 'plan-page__dialog-content'}}
			isOpen={editing}
			onRequestClose={onClose}
			footer={
				<ActionButtons>
					<Button
						label="Сохранить"
						type="accent-blue"
						onClick={handleSave}
					/>
					<Button
						label="Отмена"
						type="cancel"
						onClick={onClose}
					/>
				</ActionButtons>
			}
		>
			<div>
				<EditableFieldLabel>Название</EditableFieldLabel>

				<TextField
					value={editedName}
					elementType="input"
					onChange={setEditedName}
				/>
			</div>

			{layerType === 'custom' && (
				<div>
					<EditableFieldLabel>Тип помещения</EditableFieldLabel>

					<SelectPopup
						trigger={
							<TextFieldWithForwardedRef
								value={spaceTypesMap?.[editedSpaceType]?.name}
								elementType="div"
							/>
						}
					>
						<Select2
							value={editedSpaceType}
							onChange={(value: SpaceTypeId) => setEditedSpaceType(value)}
						>
							{spaceTypes?.map(item => (
								<SelectOption
									key={item.id}
									itemKey={item.id}
									content={item.name}
								/>
							))}
						</Select2>
					</SelectPopup>
				</div>
			)}
		</Dialog>
	);
};
