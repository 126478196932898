import { useCallback } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useInternalAcceptanceCombinedExport} from '../../hooks/useInternalAcceptanceCombinedExport';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';

interface IExportSelectedProps {
	objectId: string;
	internalAcceptanceId: string;
	selectedEntities: IInternalAcceptanceEntity[];
}

const SelectedEntitiesExport = ({objectId, internalAcceptanceId, selectedEntities}: IExportSelectedProps) => {
	const [exportDialog, openExportDialog] = useInternalAcceptanceCombinedExport(objectId, selectedEntities);

	const handleClick = useCallback(() => {
		openExportDialog(internalAcceptanceId);
	}, [internalAcceptanceId, openExportDialog]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-export-24"/>
			</IconButton>

			{exportDialog}
		</>
	);
};

export default SelectedEntitiesExport;
