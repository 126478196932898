import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {
	extractWorkAcceptanceStatuses,
	extractWorkAcceptanceStatusesAllIds,
	extractWorkAcceptanceStatusesAsArray,
	extractWorkAcceptanceStatusesById
} from './selectors';
import {IGetWorkAcceptanceStatusesResponse} from '@src/api/backend/workAcceptanceStatuses';

export const useWorkAcceptanceStatuses = <T = IGetWorkAcceptanceStatusesResponse>(
	select?: (data: IGetWorkAcceptanceStatusesResponse) => T
) => useQuery<IGetWorkAcceptanceStatusesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.workAcceptanceStatuses(),
		meta: {
			error: 'при загрузке статусов приёмок работ'
		},
		select
	});

export const useExtractWorkAcceptanceStatusesAsArray = () =>
	useWorkAcceptanceStatuses(extractWorkAcceptanceStatusesAsArray);
export const useExtractWorkAcceptanceStatuses = () =>
	useWorkAcceptanceStatuses(extractWorkAcceptanceStatuses);
export const useExtractWorkAcceptanceStatusesById = () =>
	useWorkAcceptanceStatuses(extractWorkAcceptanceStatusesById);
export const useExtractWorkAcceptanceStatusesAllIds = () =>
	useWorkAcceptanceStatuses(extractWorkAcceptanceStatusesAllIds);
