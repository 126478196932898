import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

export type IGetDelegationHistoriesByWorkAcceptanceResponse = INormalizedData<IDelegationHistory>;

/**
 * Получает записи из истории делегирования приемки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id нарушения
 */
export const requestDelegationHistoriesByWorkAcceptance = async (
	objectId: string,
	workAcceptanceId: string
) => {
	const response =
		await httpRequests.withToken.get<IGetDelegationHistoriesByWorkAcceptanceResponse>(
			'delegation-histories',
			{
				params: {objectId, workAcceptanceId, workingGroupType: WorkingGroupTypeId.ACCEPTORS}
			}
		);

	return response.data;
};
