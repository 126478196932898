import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IPreparedWorkingGroup} from '../interfaces/IPreparedWorkingGroup';

/**
 * Преобразовывает исполнителей в необходимый для вывода формат
 *
 * @param data массив id исполнителей
 * @param rules массив правил
 */
export const convertGroups = (
	data?: IWorkingGroup[],
	rules?: IResponsibilityRule[]
): IPreparedWorkingGroup[] => {
	if (data) {
		return data.map((el, index) => ({
			id: el.id,
			index,
			name: el.name,
			count: rules?.filter(rule => rule.target.groupId === el.id).length || 0
		}));
	}
	return [];
};
