import { memo } from 'react';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {Info} from './info/Info';
import {MainContent} from './MainContent';
import {SideBarMenu} from './SideBarMenu';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

interface IDesktopProps {
	legal?: ILegalEntity;
}

export const Desktop = memo(({legal}: IDesktopProps) => {
	if (!legal) {
		return null;
	}

	return (
		<div className="page-cont legal-page">
			<PageBreadcrumbs legal={legal} />

			<div className="legal-page__main">
				<Info legal={legal} />
				<MainContent legal={legal} />
				<SideBarMenu legal={legal} />
			</div>
		</div>
	);
});
