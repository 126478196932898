import {CellProps} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';
import {useTranslation} from "react-i18next";

export const LocationCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => {
	const {t} = useTranslation();
	const {plan, markers, object} = row.original;

	return (
		<div className="owner-acceptances-page__d-table-location">
			{!!object && (
				<div className="owner-acceptances-page__d-table-location-object">{object.name}</div>
			)}

			{(plan?.name || plan?.floor || markers) && (
				<div className="owner-acceptances-page__d-table-location-block">
					{plan?.name && (
						<div className="owner-acceptances-page__d-table-location-line">
							<span className="owner-acceptances-page__d-table-location-title">
								{t('ownerAcceptancesPage.table.cells.locationCell.plan.title')}
							</span>
							<div className="owner-acceptances-page__d-table-location-subtitle">{plan?.name}</div>
						</div>
					)}
					{plan?.floor && (
						<div className="owner-acceptances-page__d-table-location-line">
							<span className="owner-acceptances-page__d-table-location-title">
								{t('ownerAcceptancesPage.table.cells.locationCell.floor.title')}
							</span>
							<div className="owner-acceptances-page__d-table-location-subtitle">{plan?.floor}</div>
						</div>
					)}
					{!!markers?.length && (
						<div className="owner-acceptances-page__d-table-location-line">
							<span className="owner-acceptances-page__d-table-location-title">
								{t('ownerAcceptancesPage.table.cells.locationCell.location.title')}
							</span>
							<div className="owner-acceptances-page__d-table-location-subtitle">
								{markers[0].name}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};