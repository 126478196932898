const domain = 'plan';

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const EDIT_REQUEST = `${domain}/edit/request`;
export const EDIT_SUCCESS = `${domain}/edit/success`;
export const EDIT_FAILURE = `${domain}/edit/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const COPY_REQUEST = `${domain}/copy/request`;
export const COPY_SUCCESS = `${domain}/copy/success`;
export const COPY_FAILURE = `${domain}/copy/failure`;
