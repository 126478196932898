import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

export const getCheckListsWithStages = (checkLists: ICheckList[]): Record<string, ICheckList[]> =>
	checkLists.reduce(
		(obj, ch) => {
			if (!obj[ch.stage]) obj[ch.stage] = [];
			obj[ch.stage].push(ch);
			return obj;
		},
		{} as Record<string, ICheckList[]>
	);
