import {ICommentVariants} from '@tehzor/tools/interfaces/comments/ICommentType';
import * as types from '../constants';

/**
 * Изменяет фильтр отображения ответов и комментариев
 *
 * @param value значение фильтра
 */
export const changeCommentsVisibility = (value: ICommentVariants[]) => ({
	type: types.CHANGE_COMMENTS_VISIBILITY,
	payload: value
});
