import {combineReducers} from 'redux';
import {cacheStatus} from './cacheStatus';
import {networkStatus} from './networkStatus';
import {dictionariesCacheState} from './dictionariesCacheState';

export const offlineMode = combineReducers({
	networkStatus,
	cacheStatus,
	dictionariesCacheState
});
