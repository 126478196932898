import {queryClient} from '@src/api/QueryClient';
import {requestDeleteWorkingGroup} from '@src/api/backend/workingGroup';
import { responsibilityRulesQueryKeys } from '@src/api/cache/responsibilityRules/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';

export const useDeleteWorkingGroup = () =>
	useMutation({
		mutationFn: (groupId: string) => requestDeleteWorkingGroup(groupId),
		mutationKey: workingGroupsQueryKeys.delete(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: workingGroupsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
			addSuccessToast('Удалено', 'Рабочая группа успешно удалена');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при удалении рабочей группы');
		}
	});
