import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {useParams} from 'react-router-dom';
import {useTaskPrioritiesAsArray} from '@src/core/hooks/queries/task-priorities/hooks';
import {useTranslation} from 'react-i18next';

export const TasksPrioritiesFilterPage = () => {
	const {t} = useTranslation();
	const {entity} = useParams<{entity?: string}>();
	const {data: priorities} = useTaskPrioritiesAsArray();
	if (!priorities) return null;
	return (
		<StatusesFilterPage
			entity={entity}
			statuses={priorities}
			filterName="priorities"
			label={t('entitiesFilters.tasksStatusesFilter.label')}
		/>
	);
};
