import {formStructureCheckListsLink} from './formStructureCheckListsLink';

interface Args {
	objectId: string;
	structureId: string;
	listId: string;
}
/**
 * Формирует ссылку на чек-лист
 *
 * @param params
 * @param listId id чек-листа
 */
export const formStructureCheckListLink = ({listId, ...params}: Args) =>
	`${formStructureCheckListsLink(params)}/${listId}`;
