import {Dispatch, useCallback, useState} from 'react';
import './AddingWorkingGroupPerformerDialog.less';
import {ActionButtons, Button, Dialog, Select2, SelectOption} from '@tehzor/ui-components';
import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {useCompanyWithContractorsUsersAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {
	messageTypes,
	useEditWorkingGroup
} from '@src/core/hooks/mutations/workingGroups/useEditWorkingGroup';

interface IAddingWorkingGroupPerformerDialogProps {
	editingState: IEditableWorkingGroupState;
	editingDispatch: Dispatch<IEditableWorkingGroupAction>;
	isOpen: boolean;
	onClose: () => void;
	workingGroupId?: string;
}

/**
 * Окно добавления нового исполнителя
 */
export const AddingWorkingGroupPerformerDialog = (
	props: IAddingWorkingGroupPerformerDialogProps
) => {
	const {editingState, editingDispatch, isOpen, onClose, workingGroupId} = props;
	const [selected, setSelected] = useState<string[] | undefined>(editingState.performers);
	const {data: allUsers} = useUsersAsMap();
	const {data: users} = useCompanyWithContractorsUsersAsArray(allUsers, editingState.companyId);
	const {mutateAsync: editWorkingGroup} = useEditWorkingGroup();
	const handleSave = useCallback(async () => {
		if (selected) {
			editingDispatch({
				type: 'update',
				field: 'performers',
				value: [...selected]
			});
			if (workingGroupId) {
				const editParams = {
					groupId: workingGroupId,
					fields: {performers: selected},
					toastMessage: messageTypes.addPerformer
				};
				await editWorkingGroup(editParams);
			}
		}

		onClose();
	}, [onClose, selected, editingState, workingGroupId]);

	const handleCancel = useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Dialog
			className={{
				root: 'adding-performer-dialog',
				title: 'adding-performer-dialog__title',
				body: 'adding-performer-dialog__body'
			}}
			isOpen={isOpen}
			title="Выберите нового участника группы"
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			<Select2
				className="adding-performer-dialog__performer-select"
				multiple
				value={selected}
				onChange={setSelected}
			>
				{users
					?.filter(user => !editingState.performers?.includes(user.id))
					.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.fullName}
						/>
					))}
			</Select2>
		</Dialog>
	);
};
