import { memo } from 'react';
import Documents from '@src/pages/InspectionPage/components/attachments/Documents';
import EmptyAttachments from './EmptyAttachments';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {EntityGridItem} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IAttachmentsProps {
	task: IEnrichedTask;
}
const linksListIcon = <i className="tz-document-20" />;
const MobileAttachments = ({task}: IAttachmentsProps) => {
	const [, docs] = useSplitAttachments(task);
	const {t} = useTranslation();

	if (docs.length === 0) {
		return <EmptyAttachments />;
	}
	return docs.length > 0 ? (
		<EntityGridItem
			label={t('taskViewPage.info.attachments')}
			icon={linksListIcon}
		>
			<Documents files={docs} />
		</EntityGridItem>
	) : null;
};

export default memo(MobileAttachments);
