import {Dispatch, memo, useCallback, useState} from 'react';
import './EditableWarrantyClaim.less';
import {
	IEditableWarrantyClaimAction,
	IEditableWarrantyClaimState
} from '@src/core/hooks/states/useEditableWarrantyClaimState';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import Attachments from '@src/components/editableFields/Attachments';
import SpaceOwner from '@src/components/editableFields/SpaceOwner';
import ReplyEmail from './components/ReplyEmail';
import ReplyPhone from './components/ReplyPhone';
import ClaimerName from './components/ClaimerName';
import {EditableDateField} from '@src/components/EditableDateField';
import {useSpace} from '@src/core/hooks/queries/space';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useSpaceOwnersAsMap} from '@src/core/hooks/queries/spaceOwners/hooks';

interface IEditableWarrantyClaimProps {
	spaceId?: string;
	objectId?: string;
	editingState: IEditableWarrantyClaimState;
	editingDispatch: Dispatch<IEditableWarrantyClaimAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	// fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */

const EditableWarrantyClaim = (props: IEditableWarrantyClaimProps) => {
	const {
		spaceId,
		objectId,
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		saving
	} = props;

	const {t} = useTranslation();
	const {data: ownersMap} = useSpaceOwnersAsMap();
	const {data: space} = useSpace(spaceId, objectId);
	const {data: spacesTypes} = useExtractSpaceTypesById();
	const notBeOwned = space ? spacesTypes?.[space.type]?.notBeOwned : undefined;
	const [ownerIsNotClaimer, setOwnerIsNotClaimer] = useState(false);
	const selectedOwner =
		editingState.spaceOwnerId && ownersMap ? ownersMap[editingState.spaceOwnerId] : undefined;

	const handleOwnerIsNotClaimer = useCallback(() => {
		setOwnerIsNotClaimer(!ownerIsNotClaimer);
	}, [ownerIsNotClaimer]);

	const handleDateChange = useCallback(
		(date: Date) => {
			editingDispatch({type: 'update', field: 'registrationDate', value: date.getTime()});
		},
		[editingDispatch]
	);

	const registrationDate = editingState.registrationDate
		? new Date(editingState.registrationDate)
		: undefined;

	return (
		<div className="editable-warranty-claim">
			<div>
				<div className="editable-warranty-claim__info-grid">
					{spaceId && !notBeOwned ? (
						<SpaceOwner
							data-testid="EditableWarrantyClaimSpaceOwner"
							spaceId={spaceId}
							value={editingState.spaceOwnerId}
							editingDispatch={editingDispatch}
							required
							// required={fieldsSettings.resolution.isRequired}
							disabled={saving}
							hasError={editingState.errors.spaceOwnerId}
							ownerIsNotClaimer={ownerIsNotClaimer}
							handleOwnerIsNotClaimer={handleOwnerIsNotClaimer}
							label={
								ownerIsNotClaimer
									? t('components.editableWarrantyClaim.spaceOwner.client.label')
									: t(
											'components.editableWarrantyClaim.spaceOwner.clientApplicant.label'
									  )
							}
						/>
					) : null}

					{!spaceId || notBeOwned || ownerIsNotClaimer ? (
						<ClaimerName
							data-testid="EditableEntityGridCell"
							label={t('editableWarrantyClaim.claimerName.label')}
							value={editingState.claimerName}
							editingDispatch={editingDispatch}
							required
							disabled={saving}
							hasError={editingState.errors.claimerName}
							defaultValue={selectedOwner?.name}
						/>
					) : null}

					<ReplyEmail
						data-testid="EditableEntityGridCell"
						value={editingState.replyEmail}
						editingDispatch={editingDispatch}
						required
						// required={fieldsSettings.resolution.isRequired}
						disabled={saving}
						hasError={editingState.errors.replyEmail}
						defaultValue={selectedOwner?.email}
					/>

					<ReplyPhone
						data-testid="EditableEntityGridCell"
						label={t('editableWarrantyClaim.replyPhone.label')}
						value={editingState.replyPhone}
						editingDispatch={editingDispatch}
						required
						// required={fieldsSettings.resolution.isRequired}
						disabled={saving}
						hasError={editingState.errors.replyPhone}
						defaultValue={selectedOwner?.phone}
					/>

					<EditableDateField
						data-testid="EditableEntityGridCell"
						label={t('editableWarrantyClaim.editableDateField.label')}
						value={registrationDate}
						disabled={saving}
						onChange={handleDateChange}
					/>
				</div>
			</div>

			<Attachments
				data-testid="EditableWarrantyClaimAttachments"
				className={{
					root: 'editable-warranty-claim__attachments',
					scrollArea: 'editable-warranty-claim__attachments-scroll-area',
					files: 'editable-warranty-claim__attachments-files',
					file: 'editable-warranty-claim__attachments-file'
				}}
				attachments={editingState.attachments}
				uploadingFiles={uploadingFilesState.value}
				editingDispatch={editingDispatch}
				uploadingFilesDispatch={uploadingFilesDispatch}
				// required={fieldsSettings.attachments.isRequired}
				waitForUploading={false}
				label={t('editableWarrantyClaim.attachments.label')}
				disabled={saving}
				canDraw={false}
				hasError={editingState.errors.attachments && uploadingFilesState.error}
				showAttachBtn
			/>
		</div>
	);
};

export default memo(EditableWarrantyClaim);
