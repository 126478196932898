import AppBreadcrumbs from '@src/components/AppBreadcrumbs';

import {useManageExportTemplateBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageExportTemplateBreadcrumbs';

export const EditPageBreadcrumbs = () => {
	const breadcrumbs = useManageExportTemplateBreadcrumbs();

	return (
		<AppBreadcrumbs
			className="manage-export-template-edit-page__breadcrumbs"
			items={breadcrumbs}
		/>
	);
};
