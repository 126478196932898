import {combineReducers, createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

const visible = createReducer<boolean>(false, {
	[types.TOGGLE_MOBILE_SIDEBAR_VISIBILITY]: (state, {payload}: {payload?: boolean}) =>
		(payload === undefined ? !state : payload)
});

export default combineReducers({
	visible
});
