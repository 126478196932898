import {Suspense, startTransition, memo, useCallback} from 'react';
import {LegendPopup} from '../legend';
import {SpaceTypeButtons} from '../typeFilter/SpaceTypeButtons';
import {LoadingPanel, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {DesktopSchemaViewTabs} from '../SchemaViewTabs.desktop';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {Table} from '../reportsTable/Table';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {TypesCountLoader} from '@tehzor/ui-components/src/components/loaders/skeletons/TypesCountLoader';
import {Schema} from './Schema';
import {SchemaLoader} from '@tehzor/ui-components/src/components/loaders/skeletons/SchemaLoader';
import classNames from 'classnames';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useVisibleObjects} from '../../hooks/useVisibleObjects';
import {
	changeSchemaOffset,
	changeSchemaPageSize
} from '@src/store/modules/settings/pages/spaces/actions';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';

const pageSizes = [5, 10, 20];

interface IDesktopSchemaProps {
	objectId: string;
}

const DesktopSchema = memo(({objectId}: IDesktopSchemaProps) => {
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const {data: targetObjects} = useTargetObjects(objectId);
	const isReportsView = pageSettings.schemaView === SpacesSchemaVariants.REPORTS;
	const dispatch = useAppDispatch();
	const objects = useVisibleObjects(objectId, targetObjects || []);
	const schemaPageSize = pageSettings.schemaPageSize ? pageSettings.schemaPageSize : 5;
	const schemaOffset = pageSettings.schemaOffset ? pageSettings.schemaOffset : 0;
	const pagesCount = Math.ceil(objects.length / schemaPageSize);
	const currentPage = Math.floor(schemaOffset / schemaPageSize);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			if (objects) {
				startTransition(() => {
					dispatch(changeSchemaPageSize(objectId, value));
					dispatch(changeSchemaOffset(objectId, Math.floor(schemaOffset / value)));
				});
			}
		},
		[dispatch, objectId, objects, schemaOffset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * schemaPageSize;
			if (schemaOffset !== offset) {
				startTransition(() => {
					dispatch(changeSchemaOffset(objectId, offset));
				});
			}
		},
		[schemaPageSize, schemaOffset, dispatch, objectId]
	);
	const slicedObjects = objects.slice(schemaOffset, schemaOffset + schemaPageSize);

	return (
		<div>
			<Plate
				className="spaces-page__plate"
				withoutPadding
			>
				<div className="spaces-page__plate-header">
					<DesktopSchemaViewTabs objectId={objectId} />

					{!isReportsView && (
						<div className="spaces-page__plate-header-actions">
							<LegendPopup />
						</div>
					)}
				</div>

				{!isReportsView && targetObjects && (
					<div className="spaces-page__plate-content">
						{targetObjects && (
							<Suspense fallback={<TypesCountLoader />}>
								<SpaceTypeButtons
									objectId={objectId}
									targetObjects={targetObjects}
								/>
							</Suspense>
						)}

						<Suspense
							fallback={
								<div className={classNames('spaces-page__schema-list')}>
									<SchemaLoader />
									<SchemaLoader />
									<SchemaLoader />
									<SchemaLoader />
								</div>
							}
						>
							<Schema
								objectId={objectId}
								slicedObjects={slicedObjects}
							/>
						</Suspense>
					</div>
				)}
				{isReportsView && (
					<Suspense fallback={<LoadingPanel />}>
						<Table objectId={objectId} />
					</Suspense>
				)}
			</Plate>

			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={schemaPageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</div>
	);
});

export default DesktopSchema;
