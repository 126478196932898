import { Fragment } from 'react';
import ClickablePlate from '../../../ClickablePlate/ClickablePlate';
import {EntityInfo, IEntityInfoProps} from '../../../../various';
import './EntityButton.less';
import classNames from 'classnames';

interface IEntityButtonProps extends IEntityInfoProps {
	onClick: () => void;
	withoutShadow?: boolean;
	disable?: boolean;
	className?: string;
}

const editIcon = <i className={classNames('tz-edit-20', 'entity-btn__content-edit-icon')}/>;

export const EntityButton = ({
	onClick, withoutShadow, className, disable, ...props
}: IEntityButtonProps) => {
	const content = (
		<Fragment key="Content">
			<EntityInfo
				{...props}
				className={{
					title: 'entity-btn__content-title'
				}}
			/>
			{editIcon}
		</Fragment>
	);

	return withoutShadow ? (
		<div
			className={classNames(className, 'entity-btn', {withoutShadow})}
			onClick={!disable ? onClick : undefined}
		>
			<div className={classNames('entity-btn__content', {disable, withoutShadow})}>
				{content}
			</div>
		</div>
	) : (
		<ClickablePlate
			onClick={!disable ? onClick : undefined}
			className={{
				root: classNames(className, 'entity-btn'),
				content: classNames('entity-btn__content', {disable, withoutShadow})
			}}
			withoutPadding
		>
			{content}
		</ClickablePlate>
	);
};