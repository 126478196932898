import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetObjectStagesResponse = INormalizedData<IObjectStage>;

/**
 * Получает стадии объектов
 */
export const requestObjectStages = async () => {
	const response = await httpRequests.withToken.get<IGetObjectStagesResponse>('object-stages');
	return response.data;
};
