import { PropsWithChildren, ReactNode, useTransition } from 'react';
import {TabContent, TabLink, Tabs} from '@tehzor/ui-components';

export interface ITabItem {
	value: string;
	content: ReactNode;
}

interface ITabClassNames {
	root?: string;
	links?: string;
	link?: string;
	fakeLink?: string;
	content?: string;
}

interface ITabProps {
	tabs: ITabItem[];
	onTabClick: (tab: number) => void;
	activeTab: number;
	tabsClassNames?: string | ITabClassNames;
	itemsClassName?: string;
}

export const CheckDetailTabs = (props: PropsWithChildren<ITabProps>) => {
	const {onTabClick, tabs, activeTab, tabsClassNames, itemsClassName, children} = props;
	const [, startTransition] = useTransition();

	const handleTabClick = (index: number) => {
		startTransition(() => {
			onTabClick(index);
		});
	};

	const TabLinks = tabs.map(t => (
		<TabLink
			key={t.value}
			label={t.value}
		/>
	));

	return (
		<div>
			<Tabs
				className={tabsClassNames}
				activeTab={activeTab}
				links={TabLinks}
				onActiveTabChange={handleTabClick}
			>
				{tabs.map(t => (
					<TabContent key={t.value}>
						<div className={itemsClassName}>{t.content}</div>
					</TabContent>
				))}
			</Tabs>
			{children}
		</div>
	);
};
