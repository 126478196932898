import {CellProps} from 'react-table';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

// TODO Заменить на ISpace после внесения стадии в помещение
const SpaceStageCell = ({row}: CellProps<IConvertedSpace>) => {
	const space = row.original;
	const {data: stages} = useObjectStagesMap();
	const stageId = stages && space.status?.stage && stages[space.status.stage]?.id;
	const stageName = useTranslatedDictionaryById(dictionaryKeys.objectStages, stageId);

	return <div>{stageName}</div>;
};

export default SpaceStageCell;
