import {memo} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useAddingOwnerAcptDialog} from '@src/components/AddingOwnerAcptDialog/hooks/useAddingOwnerAcptDialog';
import {useTranslation} from 'react-i18next';

interface IMobileEntityAddingProps {
	objectId: string;
}

export const MobileEntityAdding = memo(({objectId}: IMobileEntityAddingProps) => {
	const {t} = useTranslation();
	const [dialog, open] = useAddingOwnerAcptDialog({
		title: t('ownerAcceptancesPage.addingDialog.title'),
		objectId
	});

	return (
		<>
			<IconButton onClick={open}>
				<i className="tz-plus-24" />
			</IconButton>
			{dialog}
		</>
	);
});
