import {createReducer} from '@reduxjs/toolkit';

/**
 * Создаёт reducer - индикатор асинхронного действия
 *
 * @param request action начала асинхронного действия
 * @param success action завершения асинхронного действия
 * @param failure action завершения с ошибкой асинхронного действия
 */
export const createAsyncUIReducer = (request: string, success: string, failure: string) =>
	createReducer<boolean>(false, {
		[request]: () => true,
		[success]: () => false,
		[failure]: () => false
	});
