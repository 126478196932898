import {useCallback, useEffect} from 'react';
import {LinkButton, Plate, Comment} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCommentsCtx} from '../utils/CommentsCtx';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

interface IMobileLastCommentProps {
	onImageClick?: (i: number) => void;
	goToComments: () => void;
	goToCommentsWithAdding: (comment: IComment) => void;
}

const MobileLastReply = ({
	onImageClick,
	goToComments,
	goToCommentsWithAdding
}: IMobileLastCommentProps) => {
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const {lastReply, getLastReply, transferStatus} = useCommentsCtx();

	useEffect(() => {
		if (online && !transferStatus) {
			getLastReply();
		}
	}, [getLastReply, online, transferStatus]);

	const handleImageClick = useCallback(
		(id: string, index: number) => {
			if (onImageClick) {
				onImageClick(index);
			}
		},
		[onImageClick]
	);

	return lastReply ? (
		<Plate
			className="comments__m-plate"
			title={lastReply.number !== undefined ? `Ответ №${lastReply.number}` : undefined}
			headerExtra={
				<LinkButton
					label="Все ответы"
					onClick={goToComments}
				/>
			}
		>
			<Comment
				className={{
					root: 'comments__last-reply',
					plate: 'comments__last-reply-plate'
				}}
				data={lastReply}
				hideTitle
				onSubCommentAdd={goToCommentsWithAdding}
				onImageClick={handleImageClick}
			/>
		</Plate>
	) : null;
};

export default MobileLastReply;
