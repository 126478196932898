import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
// import {testProblemRestrictions} from '@src/utils/testRestrictions';
import {useMemo} from 'react';
// import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import useAppSelector from '@src/core/hooks/useAppSelector';

export interface IPermissions {
	canViewWorkAcceptances?: boolean;
	canViewSpace?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param objectId id объекта
 */
export const usePermissions = (objectId: string): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canViewSpace: hasPermission(user.roles, 'spaces-view', UserRoleScopes.OBJECT, objectId),
			canViewWorkAcceptances: hasPermission(user.roles, 'work-acceptances-view', UserRoleScopes.OBJECT, objectId)

		};
	}, [objectId, user]);
};
