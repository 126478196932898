import {IState} from '@src/store/modules';
import {createSelector} from 'reselect';

export const extractObjectsPageSettings = createSelector(
	(state: IState) => state.settings.pages.objects,
	data => data
);

export const getObjectsFilters = (state: IState) =>
	state.settings?.pages.objects?.filters || {
		cities: [],
		stages: [],
		companies: []
	};
