import { memo } from 'react';
import {DesktopEditButton} from './DesktopEditButton';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

interface IDesktopMainActionsProps {
	legal: ILegalEntity;
}

export const DesktopMainActions = memo(({legal}: IDesktopMainActionsProps) => (
	<DesktopEditButton legal={legal} />
));
