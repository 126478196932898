import {IOwnerAcceptanceResolution} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetOwnerAcceptanceResolutionsResponse = INormalizedData<IOwnerAcceptanceResolution>;

/**
 * Возвращает список решений приёмок собственниками
 */
export const requestOwnerAcceptanceResolutions = async () => {
	const response = await httpRequests.withToken.get<IGetOwnerAcceptanceResolutionsResponse>(
		'owner-acceptances/resolutions'
	);
	return response.data;
};
