import {IEntityProblemsData} from "@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData";
import {ObjectStageIds} from "@tehzor/tools/interfaces/objects/IObjectStage";

export const getProblemsDataByStage = (
	problemsStats: IEntityProblemsData,
	stage?: ObjectStageIds
): Record<string, {count: number, critical: boolean}> | undefined => {
	if (stage) {
		return problemsStats.problems?.[stage];
	}
	const problems = problemsStats.problems;
	return problems
		? Object.values(problems).reduce<Record<string, {count: number, critical: boolean}>>(
				(prev, item) => {
					for (const status in item) {
						if (item.hasOwnProperty(status)) {
							if (prev[status]) {
								prev[status].count += item[status].count;
								prev[status].critical
									= prev[status].critical || item[status].critical;
							} else {
								prev = {
									...prev,
									[status]: {
										count: item[status].count,
										critical: item[status].critical
									}
								};
							}
						}
					}
					return prev;
				},
				{}
		  )
		: undefined;
};