import * as types from '../constants';
import {SpaceEntitiesVisibility} from '../reducers/entitiesVisibility';

/**
 * Изменяет фильтр отображения сущностей помещения
 *
 * @param value значение фильтра
 */
export const changeEntitiesVisibility = (value: SpaceEntitiesVisibility[]) => ({
	type: types.CHANGE_ENTITIES_VISIBILITY,
	payload: value
});
