import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import './CategoriesSetPage.less';
import {useCategoriesSetBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCategoriesSetBreadcrumbs';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import CategoriesSetContent from './components/CategoriesSetContent';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';

const CategoriesSetAddingPage = () => {
	useScrollRestoration();
	const breadcrumbs: IBreadcrumb[] = useCategoriesSetBreadcrumbs();

	useAppHeader(
		{
			title: 'Новый набор видов работ',
			showBackBtn: true
		},
		[]
	);

	return (
		<div className="page-cont">
			<div className="categories-set">
				<AppBreadcrumbs
					className="categories-set__breadcrumbs"
					items={breadcrumbs}
				/>
				<CategoriesSetContent />
			</div>
		</div>
	);
};

export default CategoriesSetAddingPage;
