import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';

export type IEditCheckListResponse = ICheckList;

/**
 * Возвращает чек-лист
 *
 * @param id идентификатор чек-листа
 * @param fields данные для обновления
 */
export const makeCheckListEditRequest = async (id: string, fields: ISavingCheckList) => {
	const response = await httpRequests.withToken.put<IEditCheckListResponse>(`check-lists/${id}`, {
		id,
		...fields
	});
	return response.data;
};
