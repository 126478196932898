import {MutableRefObject, useCallback, useEffect} from 'react';
import IUploadingFile from '../../interfaces/IUploadingFile';
import {UploadingFileStatus} from '../../enums/UploadingFileStatus';
import {QueueObject} from 'async';

/**
 * Хук. Принимает файлы и ставит их в очередь.
 * @param {IUploadingFile[]} uploadingFiles
 * @param {MutableRefObject<QueueObject<string>>} queueHandler
 * @param {MutableRefObject<string[]>} processingKeys
 * @param {(key: string, data: Partial<IUploadingFile>) => void} updateFile
 */
export const useFileHandler = (
	uploadingFiles: IUploadingFile[],
	queueHandler: MutableRefObject<QueueObject<string>>,
	processingKeys: MutableRefObject<string[]>,
	updateFile: (key: string, data: Partial<IUploadingFile>) => void
) => {
	const checkAndAddTask = useCallback(
		(file: IUploadingFile) => {
			if (
				file.status === UploadingFileStatus.WAITING &&
				!file.tempFile &&
				!processingKeys.current.includes(file.key)
			) {
				processingKeys.current.push(file.key);
				void queueHandler.current.push(file.key);
			}
		},
		[updateFile]
	);

	useEffect(() => {
		uploadingFiles.forEach(checkAndAddTask);
	}, [uploadingFiles, checkAndAddTask]);
};
