import {httpRequests} from '@tehzor/tools/api/httpRequests';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export type IGetInspectionResponse = IInspection;

/**
 * Возвращает осмотр
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 */
export const requestInspection = async (objectId: string, inspectionId: string) => {
	const response = await httpRequests.withToken.get<IGetInspectionResponse>(
		`inspections/${inspectionId}`,
		{params: {objectId}}
	);

	return response.data;
};
