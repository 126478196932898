import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntitySelectProps} from '../components/EntitySelect/EntitySelect';

export const getTitle = (
	selectedStage: ObjectStageIds,
	entitiesSelectMapProps: Record<ObjectStageIds, IEntitySelectProps>
) => {
	const {name, newName, selected, entities, localEntities} = entitiesSelectMapProps[selectedStage];

	if (!selected) {
		return newName;
	}
	const entity = entities && entities.find(entity => selected === entity.id);
	if (entity) {
		return `${name} №${entity.number ?? entity.localNumber}`;
	}

	const localEntity = localEntities && localEntities.find(localEntity => selected === localEntity.id);
	if (localEntity) {
		return `${name} №${localEntity.number}`;
	}
	return newName;
};