import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {
	CheckRecordStatusId,
	ICheckRecordStatus
} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {getLatestModified} from '@src/utils/selectorUtils';

export const convertCheckLists = (
	structure: IListStructure,
	checkLists: Record<string, ICheckList[]>,
	checkListsRecords: ICheckRecord[],
	checkRecordStatuses: Record<string, ICheckRecordStatus>
): Array<{key: string; name: string; status: ICheckRecordStatus}> => {
	const structureCheckLists = checkLists[structure.type];

	if (!structure.workAcceptances) {
		return [];
	}
	const workAcceptanceIds = structure.workAcceptances.map(wa => wa.id);

	return structureCheckLists.map(checkList => {
		const records = checkListsRecords.filter(
			record =>
				record.links.workAcceptanceId &&
				workAcceptanceIds.includes(record.links.workAcceptanceId) &&
				record.links.checkListId === checkList.id &&
				!record.links.checkItemId
		);
		const record = getLatestModified<ICheckRecord>(records);
		const resp = {
			key: checkList.id,
			name: checkList.name,
			status: checkRecordStatuses[record.status || CheckRecordStatusId.NOT_CHECKED]
		};
		return resp;
	});
};
