import {useState} from 'react';
import {Button, InlineButton, Checkbox} from '@tehzor/ui-components';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import arrayToTree from 'array-to-tree';
import {useEditableCategoriesSetDialog} from '../hooks/useEditableCategoriesSetDialog';
import {getTreeStructure, joinStuctures} from '@tehzor/tools/utils/tree/treeStuctures';
import {getStructuredCategoriesData, StructuredCategories} from './StructuredCategories';
import {ObjectSelect} from './ObjectSelect';
import {orderBy} from 'lodash';
import {useExtractCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useExtractFullCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {useDeleteCategoriesSet} from '@src/core/hooks/mutations/categoriesSets/useDeleteCategoriesSet';
import {useMigrateCategoriesSets} from '@src/core/hooks/mutations/categoriesSets/useMigrateCategoriesSets';

export const Content = () => {
	const {mutate: deleteCategoriesSet} = useDeleteCategoriesSet();
	const {mutateAsync: migrateCategoriesSet} = useMigrateCategoriesSets();

	const [selectObjectsIds, setSelectObjectsIds] = useState<string[]>([]);
	const [deleteFromCategoriesSet, setDeleteFromCategoriesSet] = useState<boolean>(false);
	const {data: categoriesSetsDataAsArray} = useExtractFullCategoriesSetsAsArray();

	const categoriesSets = categoriesSetsDataAsArray?.filter(cs =>
		cs.objects.some(c => selectObjectsIds?.includes(c))
	);

	const {data: categories} = useExtractCategoriesAsArray();
	const [index, setIndex] = useState<number>(0);
	const [comparedIndex, setComparedIndex] = useState<number>(0);

	const previousSet = () => {
		setIndex(index > 0 ? index - 1 : 0);
	};
	const nextSet = () => {
		if (categoriesSets && index < categoriesSets.length - 1) {
			setIndex(index + 1);
		}
	};
	const previousComparedSet = () => {
		if (comparedIndex > 0) {
			setComparedIndex(comparedIndex - 1);
		}
	};
	const nextComparedSet = () => {
		if (categoriesSets && comparedIndex < categoriesSets.length - 1) {
			setComparedIndex(comparedIndex + 1);
		}
	};

	if (categoriesSets && index >= categoriesSets.length) {
		setIndex(categoriesSets.length - 1);
	}
	if (categoriesSets && comparedIndex >= categoriesSets.length) {
		setComparedIndex(categoriesSets.length - 1);
	}

	const onSelect = (objectsIds: string[]) => {
		setSelectObjectsIds(objectsIds);
		setIndex(0);
		setComparedIndex(0);
	};

	const set: ICategoriesSet | undefined = categoriesSets && categoriesSets[index];
	const comparedSet: ICategoriesSet | undefined = categoriesSets && categoriesSets[comparedIndex];
	const setsCategories = orderBy(
		categories?.filter(cat => cat.categoriesSetId === set?.id),
		['order'],
		['asc']
	);
	const tree = arrayToTree<ICategory>(setsCategories, {parentProperty: 'parentId'});
	const comparedSetsCategories = orderBy(
		categories?.filter(cat => cat.categoriesSetId === comparedSet?.id),
		['order'],
		'asc'
	);
	const comparedTree = arrayToTree<ICategory>(comparedSetsCategories, {
		parentProperty: 'parentId'
	});

	const commonStructure = joinStuctures(
		getTreeStructure(tree, []),
		getTreeStructure(comparedTree, [])
	);

	const [editCategoriesDialog, editCategory] = useEditableCategoriesSetDialog(
		comparedSetsCategories,
		comparedSet
	);

	let err = false;
	const setErr = () => {
		err = true;
	};
	const matches: Array<{fromId: string; toId: string}> = [];

	const addMatch = (match: {fromId: string; toId: string}) => {
		matches.push(match);
	};

	const migrate = async () => {
		if (set?.id && comparedSet?.id) {
			const response = await migrateCategoriesSet({
				fromSetId: set.id,
				toSetId: comparedSet.id,
				matches
			});

			if (response?.success && deleteFromCategoriesSet) {
				deleteCategoriesSet(set?.id);
			}
		}
	};

	const setStructuredCategoriesData = getStructuredCategoriesData(
		commonStructure,
		tree,
		comparedTree,
		'',
		true,
		setErr,
		addMatch
	);
	const comparedSetStructuredCategoriesData = getStructuredCategoriesData(
		commonStructure,
		comparedTree,
		tree,
		'',
		false,
		setErr,
		addMatch
	);

	return (
		<div>
			<ObjectSelect
				selectObjectsIds={selectObjectsIds}
				onSelect={onSelect}
			/>
			<div className="categories-set-migration-page__counter">{`Итого наборов: ${
				categoriesSets?.length || 0
			}`}</div>
			<div className="categories-set-migration-page__content">
				{set && (
					<div className="categories-set-migration-page__column">
						<div className="categories-set-migration-page__header">
							{`Старый набор ${set.name} (номер: ${index})`}
						</div>
						<div>
							<Button
								label="Предыдущий"
								className="categories-set-migration-page__button"
								type="common-bordered"
								onClick={previousSet}
							/>
							<Button
								label="Следующий"
								className="categories-set-migration-page__button"
								type="common-bordered"
								onClick={nextSet}
							/>
						</div>
						<div>
							<StructuredCategories
								structuredCategoriesData={setStructuredCategoriesData}
							/>
						</div>
					</div>
				)}
				{comparedSet && (
					<div className="categories-set-migration-page__column">
						<div className="categories-set-migration-page__header">
							{`Новый набор ${comparedSet.name} (номер: ${comparedIndex})`}
							<InlineButton
								className="categories-set-migration-page__inline-button"
								type="accent"
								leftIcon={<i className="tz-edit-16" />}
								onClick={() => editCategory()}
							/>
						</div>
						<div>
							<Button
								label="Предыдущий"
								className="categories-set-migration-page__button"
								type="common-bordered"
								onClick={previousComparedSet}
							/>
							<Button
								label="Следующий"
								className="categories-set-migration-page__button"
								type="common-bordered"
								onClick={nextComparedSet}
							/>
						</div>
						<div>
							<StructuredCategories
								structuredCategoriesData={comparedSetStructuredCategoriesData}
							/>
						</div>
					</div>
				)}
			</div>
			{editCategoriesDialog}

			{set && comparedSet && (
				<div className="categories-set-migration-page__header">
					{`Миграция из ${set.name} в ${comparedSet.name}`}
				</div>
			)}
			<div>
				<Checkbox
					className="categories-set-migration-page__checkbox"
					checked={deleteFromCategoriesSet}
					onChange={() => setDeleteFromCategoriesSet(!deleteFromCategoriesSet)}
					disabled={err || set === undefined || set?.id === comparedSet?.id}
				>
					<span className="categories-set-migration-page__checkbox__label">
						Удалить старый набор
					</span>
				</Checkbox>
			</div>
			<Button
				label="Выполнить миграцию"
				className="categories-set-migration-page__button"
				type="accent-red"
				disabled={err || set === undefined || set?.id === comparedSet?.id}
				onClick={migrate}
			/>
		</div>
	);
};
