// import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {ILinksState} from '@src/store/modules/app/links/reducers';
import {AppThunkAction, AppThunkDispatch} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';

export const createEntityAction = <R extends unknown, Fn extends (...args: any) => any>(
	field: keyof ILinksState,
	apiFn: Fn,
	{
		loadAdditionalData,
		getDataFromLocalStore
	}: {
		loadAdditionalData?: (...args: Parameters<Fn>) => AppThunkAction;
		getDataFromLocalStore?: (s: IState) => R;
	} = {}
) => {
	const request = () => ({
		type: types.GET_REQUEST
	});

	const success = (response: R, args: Parameters<Fn>) => (dispatch: AppThunkDispatch) => {
		if (loadAdditionalData && args) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(loadAdditionalData(...args));
		}

		dispatch({
			type: types.GET_SUCCESS,
			payload: {field, data: response}
		});
	};

	const failure = (error: IError) =>
	// addErrorToast('Ошибка', 'при загрузке ссылки на сущность');
	({
		type: types.GET_FAILURE,
		payload: error
	});

	return (...args: Parameters<Fn>): AppThunkAction =>
		async (dispatch, getState) => {
			const data = getDataFromLocalStore?.(getState());

			if (!data) {
				// @ts-ignore
				void dispatch(createApiAction<R>(request, success, failure, () => apiFn(...args)));
			} else {
				success(data, args);
			}
		};
};
