import {useEditableSpaceDialog} from '@src/components/EditableSpaceDialog/hooks/useEditableSpaceDialog';
import {memo} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IDesktopMenuProps {
	objectId: string;
	canAddSpace?: boolean;
}

const addIcon = <i className="tz-plus-24" />;
export const DesktopMenu = memo((props: IDesktopMenuProps) => {
	const {t} = useTranslation();
	const {objectId, canAddSpace} = props;
	// Диалог добавления помещения
	const [addDialog, openAddDialog] = useEditableSpaceDialog(objectId);
	// Диалог генерации помещений

	const items = [];
	if (canAddSpace) {
		items.push(
			<MenuItem
				key="add"
				icon={addIcon}
				onClick={openAddDialog}
			>
				{t('spacesPage.pageBreadcrumbs.desktopMenu.iconMenu.menuItem.children')}
			</MenuItem>
		);
	}
	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canAddSpace && addDialog}
		</>
	) : null;
});
