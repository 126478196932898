import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '@src/constants/local-entities/problems';
import {ILocalInspection} from '@src/interfaces/ILocalInspection';
import * as entitiesTypes from '@src/constants/local-entities/entities';
import {IOfflineDataTransferStatuses} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {IOfflineDataCheckAdded} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataCheckAdded';
import {IOfflineDataInternalAcceptanceAdded} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataInternalAcceptanceAdded';

type ILocalInspectionsState = ILocalInspection[];

const problems = createReducer<ILocalInspectionsState>([], {
	[types.ADD_LOCAL_INSPECTION]: (state, payload: {inspection: ILocalInspection}) => [
		...state,
		payload.inspection
	],
	[types.DELETE_LOCAL_INSPECTION]: (state, payload: {key: string}) =>
		state.filter(item => item.key !== payload.key),
	[entitiesTypes.CHANGE_LOCAL_ENTITY_STATUS]: (state, payload: IOfflineDataTransferStatuses) =>
		state.map(inspection => {
			if (payload.inspections[inspection.key]) {
				inspection.transferStatus = payload.inspections[inspection.key];
			}
			return inspection;
		}),
	[entitiesTypes.CHANGE_LOCAL_CHECK_LINK]: (state, payload: IOfflineDataCheckAdded) =>
		state.map(inspection => {
			if (inspection.localLinks?.checkId === payload.key) {
				inspection.links = {...inspection.links, checkId: payload.id};
			}
			return inspection;
		}),
	[entitiesTypes.CHANGE_LOCAL_INTERNAL_ACCEPTANCE_LINK]: (
		state,
		payload: IOfflineDataInternalAcceptanceAdded
	) =>
		state.map(inspection => {
			if (inspection.localLinks?.internalAcceptanceId === payload.key) {
				inspection.links = {...inspection.links, internalAcceptanceId: payload.id};
			}
			return inspection;
		})
});

export {ILocalInspectionsState};
export default problems;
