import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {useTranslation} from 'react-i18next';
import {CellProps} from 'react-table';

export const AcceptorsMobileCell = ({
	row: {
		original: {acceptors, acceptorsActiveGroup, company}
	}
}: CellProps<IPreparedWorkAcceptance>) => {
	const {t} = useTranslation();
	return (
		<div className="work-acceptances-page__m-table__cell-responsible">
			<div className="work-acceptances-page__m-table__cell-responsible-title">
				{t('spacePage.workAcceptanceTable.columns.acceptorsMobileCell.title')}:
			</div>
			{acceptorsActiveGroup && (
				<div className="work-acceptances-page__m-table__cell-responsible-header">
					{acceptorsActiveGroup}
				</div>
			)}
			<div className="work-acceptances-page__m-table__cell-responsible-info">
				{acceptors?.[0] && (
					<div className="work-acceptances-page__m-table__cell-responsible-user">
						{acceptors[0]}
					</div>
				)}
				{company && (
					<div className="work-acceptances-page__m-table__cell-responsible-company">
						{company}
					</div>
				)}
				{!!acceptors?.length && acceptors?.length > 1 && (
					<div className="work-acceptances-page__m-table__cell-responsible-rest">
						+{acceptors.length - 1}
					</div>
				)}
			</div>
		</div>
	);
};
