import {createContext, memo, useCallback} from 'react';
import {useIsDesktop, useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {IPreparedOwnerAcceptance} from '../../interfaces/IPreparedOwnerAcceptance';
import {extractOwnerAcceptancesPageSettings} from '@src/store/modules/settings/pages/ownerAcceptances/selectors';
import {mobileColumns, mobileColumnsWithoutObject} from './columns/mobileColumns';
import {getDesktopColumns, getDesktopColumnsWithoutObject} from './columns/desktopColumns';
import {useOwnerAcceptanceStatusesAsArray} from '@src/core/hooks/queries/ownerAcceptanceStatuses/hooks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useLocalOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances/useGetOwnerAcceptances';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {
	useOwnerAcceptances,
	useOwnerAcceptancesAsArray
} from '@src/core/hooks/queries/ownerAcceptances/hooks';
import {calculateCurrentPage} from '@src/pages/OwnerAcceptancesPage/utils/calculateCurrentPage';
import {calculatePagesCount} from '@src/pages/OwnerAcceptancesPage/utils/calculatePagesCount';
import {useConvertOwnerAcceptances} from '@src/pages/OwnerAcceptancesPage/hooks/useConvertOwnerAcceptances';
import {ownerAcceptancesPageSettingsActions} from '@src/store/modules/settings/pages/ownerAcceptances/slice';
import {useDeleteOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useDeleteOwnerAcceptance';
import {useOwnerAcceptancesResolutionsArray} from '@src/core/hooks/queries/ownerAcceptancesResolutions/hooks';
import {useMarkersAsArray} from '@src/core/hooks/queries/markers/hooks';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';

const pageSizes = [10, 20, 50, 100];

interface IOwnerAcceptancesTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedOwnerAcceptance>) => void
>(() => ({}));

export const OwnerAcceptancesTable = memo(({objectId = 'all'}: IOwnerAcceptancesTableProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const isLargeTablet = useIsLargeTablet();

	const {changeSelectedRows, changeSort, changePageSize, changeOffset} =
		ownerAcceptancesPageSettingsActions;
	const {data: ownerAcceptancesMap} = useOwnerAcceptances(objectId);
	const {data: ownerAcceptances} = useOwnerAcceptancesAsArray(objectId);
	const {data: ownerAcceptanceStatuses} = useOwnerAcceptanceStatusesAsArray();
	const localOwnerAcceptances = useLocalOwnerAcceptances(objectId);

	const {data: plans} = usePlansAsArray(objectId);
	const pageSettings = useAppSelector(s => extractOwnerAcceptancesPageSettings(s, objectId));
	const {data: resolutions} = useOwnerAcceptancesResolutionsArray();
	const ownerAcceptanceIds = ownerAcceptances?.map(ownerAcceptance => ownerAcceptance.id);
	const {data: markers} = useMarkersAsArray({ownerAcceptanceIds});
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);

	const pagesCount = calculatePagesCount(ownerAcceptancesMap?.total, pageSettings.pageSize);
	const currentPage = calculateCurrentPage(ownerAcceptancesMap?.offset, pageSettings.pageSize);

	const deleteOwnerAcceptance = useDeleteOwnerAcceptance();

	const preparedOwnerAcceptances = useConvertOwnerAcceptances({
		ownerAcceptances,
		localOwnerAcceptances,
		networkStatus,
		user,
		markers,
		plans,
		resolutions,
		statuses: ownerAcceptanceStatuses
	});

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.ownerAcceptances.deleteTitle')
	});

	const handleRowClick = useCallback(
		(ownerAcceptance: IPreparedOwnerAcceptance) => {
			if (ownerAcceptance.object) {
				pushPath(
					`/objects/${ownerAcceptance.object.id}/owner-acceptances/${ownerAcceptance.id}`
				);
			}
		},
		[pushPath]
	);

	const handleSelectedRowsChange = useCallback(
		(selectedRows: string[]) => dispatch(changeSelectedRows({objectId, selectedRows})),
		[dispatch, objectId, changeSelectedRows]
	);

	const handleSortChange = useCallback(
		(sort: {[key: string]: boolean}) => {
			dispatch(changeSort({objectId, sort}));
		},
		[dispatch, objectId, changeSort]
	);

	const handlePageSizeChange = useCallback(
		(pageSize: number) => {
			const offset = Math.floor(pageSettings.offset / pageSize);
			dispatch(changePageSize({objectId, pageSize}));
			dispatch(changeOffset({objectId, offset}));
		},
		[objectId, pageSettings.offset, dispatch, changePageSize, changeOffset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (ownerAcceptancesMap?.offset !== offset) {
				dispatch(changeOffset({objectId, offset}));
			}
		},
		[pageSettings.pageSize, ownerAcceptancesMap?.offset, dispatch, objectId, changeOffset]
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedOwnerAcceptance>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					await deleteOwnerAcceptance(action.payload.object.id, action.payload.id);
				}
			}
		},
		[objectId, dispatch]
	);

	const columns = determineTableColumns(
		objectId === 'all',
		isLargeTablet,
		mobileColumns,
		mobileColumnsWithoutObject,
		getDesktopColumns(t),
		getDesktopColumnsWithoutObject(t)
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					{preparedOwnerAcceptances && (
						<EntitiesTable
							columns={columns}
							data={preparedOwnerAcceptances}
							selectedRows={pageSettings.selectedRows}
							sort={pageSettings.sort}
							selectable={isDesktop}
							onRowClick={handleRowClick}
							onSelectedRowsChange={handleSelectedRowsChange}
							onSortChange={handleSortChange}
							hideLastHeaderCell={isDesktop}
							headVisible={isDesktop}
						/>
					)}
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>

			{deleteDialog}
		</>
	);
});
