import {useMemo} from 'react';
import {ObjectsDisplayMode} from '@src/interfaces/ObjectsDisplayMode';

const options = [
	{key: ObjectsDisplayMode.SCHEMA, content: <i className="tz-tile-24" />},
	{key: ObjectsDisplayMode.LIST, content: <i className="tz-list-24" />}
];

export const useObjectsControlOptions = (isDesktop?: boolean) => {
	const filteredOptions = useMemo(
		() =>
			options.filter(({key}) => {
				if (key === ObjectsDisplayMode.SPACES) {
					return isDesktop;
				}

				return true;
			}),
		[isDesktop]
	);
	return {options: filteredOptions};
};
