import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';

export const useTitle = (isEdit: boolean) => {
	const {t} = useTranslation();

	return useMemo(() => {
		if (isEdit) {
			return t('editableObjectDialog.title.edit');
		}

		return t('editableObjectDialog.title.create');
	}, [isEdit]);
};
