import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {IChangedIndicators} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {extractLocalSpaceIndicatorsChangesJournal} from './selector';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback} from 'react';

export const useLocalSpaceIndicatorsChangesJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: spacesQueryKeys.indicators.edit()});
	const selector = useCallback(
		(records: IChangedIndicators[]) =>
			extractLocalSpaceIndicatorsChangesJournal(records, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IChangedIndicators[], unknown, IOfflineJournalEntity[]>({
		queryKey: spacesQueryKeys.indicators.localList(),
		select: selector
	});
	return data || [];
};
