import {requestCheckRecordStatuses} from '@src/api/backend/checkRecordStatuses';
import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';

export const useCheckRecordStatusesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.checkRecordStatuses(), {
		networkMode: 'offlineFirst',
		queryFn: requestCheckRecordStatuses,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
