import {queryClient} from '@src/api/QueryClient';
import {IGetSpaceOwnersResponse} from '@src/api/backend/spaceOwners';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {fieldFill} from './fieldFill';

export const updateCachedOwnersList = (spaceOwnerId: string, fields: ISavingSpaceOwner) => {
	queryClient.setQueriesData<IGetSpaceOwnersResponse>(
		{queryKey: spaceOwnersQueryKeys.list()},
		ownersList => {
			if (!ownersList) {
				return undefined;
			}
			return {
				...ownersList,
				...{
					byId: {
						...ownersList.byId,
						[spaceOwnerId]: {
							...ownersList.byId[spaceOwnerId],
							name: fields.name || ownersList.byId[spaceOwnerId].name,
							email: fieldFill(fields.email, ownersList.byId[spaceOwnerId].email),
							additionalEmail: fieldFill(
								fields.additionalEmail,
								ownersList.byId[spaceOwnerId].additionalEmail
							),
							phone: fieldFill(fields.phone, ownersList.byId[spaceOwnerId].phone),
							additionalPhone: fieldFill(
								fields.additionalPhone,
								ownersList.byId[spaceOwnerId].additionalPhone
							),
							comment: fieldFill(
								fields.comment,
								ownersList.byId[spaceOwnerId].comment
							)
						}
					}
				}
			};
		}
	);
};
