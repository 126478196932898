import { memo } from 'react';
import * as React from 'react';
import {IDatesOptions} from '../Calendar';
import {useMonthsGrid} from '../hooks/useMonthsGrid';
import {makeElement} from '../../../../utils/makeElement';
import Month, {IMonthProps} from './Month';

interface IMonthsGridProps {
	month?: number;
	datesOptions?: IDatesOptions;
	component?: React.ReactElement<IMonthProps> | ((props: IMonthProps) => JSX.Element);

	changeMonth(value: number): void;
}

const MonthsGrid = (props: IMonthsGridProps) => {
	const {month, datesOptions, component = Month, changeMonth} = props;

	const months = useMonthsGrid(datesOptions);

	return (
		<div className="calendar__months-grid">
			{months.map((item, i) => {
				const monthProps = {
					...item,
					key: i,
					active: item.value === month,
					onClick: changeMonth
				};
				return makeElement(component, monthProps);
			})}
		</div>
	);
};

export default memo(MonthsGrid);
