import {requestListProblems} from '@src/api/backend/problems';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';

export type IGetStructureProblemsPayload = INormalizedData<IListProblem>;

const request = () => ({type: types.GET_PROBLEMS_REQUEST});

const success = (response: IGetStructureProblemsPayload) => ({
	type: types.GET_PROBLEMS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке нарушений');
	return {
		type: types.GET_PROBLEMS_FAILURE,
		payload: error
	};
};

/**
 * Получает список нарушений для конкретной структуры
 *
 * @param objectId id объекта
 * @param structureId id структуры
 */
export const getStructureProblems = (objectId: string, structureId: string) =>
	checkExpiration<IState, IGetStructureProblemsPayload, ApiAction>(
		s => s.entities.structure.problems,
		createApiAction<IGetStructureProblemsPayload>(request, success, failure, () =>
			requestListProblems(
				{
					objects: [objectId],
					structures: [structureId]
				},
				{createdAt: false}
			).then(({byId, allIds}) => ({
				byId,
				allIds
			})))
	);
