import { RefCallback } from 'react';
import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import interact from 'interactjs';
import {
	DragEvent,
	DraggableOptions,
	Element,
	GestureEvent,
	Interactable,
	InteractEvent,
	Point,
	PointerEvent
} from '@interactjs/types';
import {ActionMap} from '@interactjs/core/scope';
import isEqual from 'lodash/isEqual';

export interface PanEvent {
	clientX: number;
	clientY: number;
	initialClientX: number;
	initialClientY: number;
	deltaX: number;
	deltaY: number;
	velocityX: number;
	velocityY: number;
}

export interface PinchEvent extends PanEvent {
	angle: number;
	deltaAngle: number;
	scale: number;
	deltaScale: number;
}

export interface TapEvent {
	target: EventTarget;
	clientX: number;
	clientY: number;
}

export type SwipeDirection = 'left' | 'right' | 'up' | 'down';

export interface ITouchRecognizerProps extends React.HTMLAttributes<HTMLDivElement> {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	dragOptions?: {
		origin?: Point | string | Element;
		allowFrom?: string | Element;
		ignoreFrom?: string | Element;
		startAxis?: DraggableOptions['startAxis'];
		lockAxis?: DraggableOptions['lockAxis'];
	};
	elementRef?: RefCallback<HTMLDivElement>;

	onPanStart?: (event: PanEvent) => void;
	onPanMove?: (event: PanEvent) => void;
	onPanEnd?: (event: PanEvent) => void;
	onPinchStart?: (event: PanEvent) => void;
	onPinchMove?: (event: PanEvent) => void;
	onPinchEnd?: (event: PanEvent) => void;
	onSwipe?: (event: PanEvent, direction?: SwipeDirection) => void;
	onTap?: (event: TapEvent) => void;
	onDoubleTap?: (event: TapEvent) => void;
	onLongTap?: (event: TapEvent) => void;
	onResize?: () => void;
}

/**
 * Базовый класс для распознавания жестов move, swipe, tap, pinch
 */
class TouchRecognizer extends React.PureComponent<ITouchRecognizerProps> {
	static defaultDragOptions: ITouchRecognizerProps['dragOptions'] = {
		origin: 'self',
		startAxis: 'xy',
		lockAxis: 'xy'
	};

	/**
	 * Объект interact.js
	 */
	private handler?: Interactable;

	/**
	 * Функция отписки от изменений размера корневого элемента
	 */
	private removeResizeEventListener?: () => void;

	componentDidUpdate(prevProps: ITouchRecognizerProps) {
		// Обновление interact.js объекта при изменении параметров
		if (
			this.props.dragOptions !== prevProps.dragOptions
			&& !isEqual(this.props.dragOptions, prevProps.dragOptions)
		) {
			this.applyPanInteraction();
		}
	}

	componentWillUnmount() {
		if (this.removeResizeEventListener) {
			this.removeResizeEventListener();
		}
		this.stopInteraction();
	}

	render() {
		/* eslint-disable @typescript-eslint/no-unused-vars */
		const {
			style,
			dragOptions,
			elementRef,
			onPanStart,
			onPanMove,
			onPanEnd,
			onPinchStart,
			onPinchMove,
			onPinchEnd,
			onSwipe,
			onTap,
			onDoubleTap,
			onLongTap,
			onResize,
			...rest
		} = this.props;

		return (
			<div
				{...rest}
				style={{...style, touchAction: 'none'}}
				ref={this.saveRef}
			/>
		);
	}

	/**
	 * Обрабатывает событие начало движения
	 *
	 * @param event событие
	 */
	private handlePanStart = (event: DragEvent) => {
		if (this.props.onPanStart) {
			this.props.onPanStart(this.convertInteractEvent(event));
		}
	};

	/**
	 * Обрабатывает событие движения
	 *
	 * @param event событие
	 */
	private handlePanMove = (event: DragEvent) => {
		if (this.props.onPanMove) {
			this.props.onPanMove(this.convertInteractEvent(event));
		}
	};

	/**
	 * Обрабатывает событие завершения движения
	 *
	 * @param event событие
	 */
	private handlePanEnd = (event: DragEvent) => {
		// Если был распознан swipe, то вызываем только его callback
		if (event.swipe && this.props.onSwipe) {
			this.props.onSwipe(this.convertInteractEvent(event), this.getSwipeDirection(event.swipe));
		} else if (this.props.onPanEnd) {
			this.props.onPanEnd(this.convertInteractEvent(event));
		}
	};

	/**
	 * Обрабатывает событие начало жеста увеличения
	 *
	 * @param event событие
	 */
	private handlePinchStart = (event: GestureEvent) => {
		if (this.props.onPinchStart) {
			this.props.onPinchStart(this.convertGestureEvent(event));
		}
	};

	/**
	 * Обрабатывает событие движения жеста увеличения
	 *
	 * @param event событие
	 */
	private handlePinchMove = (event: GestureEvent) => {
		if (this.props.onPinchMove) {
			this.props.onPinchMove(this.convertGestureEvent(event));
		}
	};

	/**
	 * Обрабатывает событие завершения жеста увеличения
	 *
	 * @param event событие
	 */
	private handlePinchEnd = (event: GestureEvent) => {
		if (this.props.onPinchEnd) {
			this.props.onPinchEnd(this.convertGestureEvent(event));
		}
	};

	/**
	 * Обрабатывает событие касания
	 *
	 * @param event событие
	 */
	private handleTap = (event: PointerEvent) => {
		if (this.props.onTap) {
			this.props.onTap(this.convertPointerEvent(event));
		}
	};

	/**
	 * Обрабатывает событие двойного касания
	 *
	 * @param event событие
	 */
	private handleDoubleTap = (event: PointerEvent) => {
		if (this.props.onDoubleTap) {
			this.props.onDoubleTap(this.convertPointerEvent(event));
		}
	};

	/**
	 * Обрабатывает событие удерживания при касании
	 *
	 * @param event событие
	 */
	private handleLongTap = (event: PointerEvent) => {
		if (this.props.onLongTap) {
			this.props.onLongTap(this.convertPointerEvent(event));
		}
	};

	/**
	 * Преобразует interact.js событие перемещения в PanEvent
	 *
	 * @param event событие
	 */
	private convertInteractEvent = <T extends keyof ActionMap = never>(event: InteractEvent<T>): PanEvent => ({
		clientX: event.client.x,
		clientY: event.client.y,
		initialClientX: event.clientX0,
		initialClientY: event.clientY0,
		deltaX: event.delta.x,
		deltaY: event.delta.y,
		velocityX: event.velocity.x,
		velocityY: event.velocity.y
	});

	/**
	 * Преобразует interact.js событие увеличения в PinchEvent
	 *
	 * @param event событие
	 */
	private convertGestureEvent = (event: GestureEvent): PinchEvent => ({
		...this.convertInteractEvent(event),
		angle: event.angle,
		deltaAngle: event.da,
		scale: event.scale,
		deltaScale: event.ds
	});

	/**
	 * Преобразует interact.js событие касания в TapEvent
	 *
	 * @param event событие
	 */
	private convertPointerEvent = (event: PointerEvent): TapEvent => ({
		target: event.target,
		clientX: event.clientX,
		clientY: event.clientY
	});

	/**
	 * Определяет направления свайпа
	 *
	 * @param swipe направление из события
	 */
	private getSwipeDirection = (swipe: DragEvent['swipe']): SwipeDirection | undefined => {
		if (swipe.left) {
			return 'left';
		}
		if (swipe.right) {
			return 'right';
		}
		if (swipe.up) {
			return 'up';
		}
		if (swipe.down) {
			return 'down';
		}
		return undefined;
	};

	/**
	 * Запускает отслеживание событий и жестов
	 *
	 * @param element dom-элемент
	 */
	private startInteraction = (element: HTMLDivElement | null) => {
		if (element) {
			this.stopInteraction();
			this.handler = interact(element).styleCursor(false);
			this.applyPanInteraction(true);
			this.applyPinchInteraction();
			this.applyTapInteractions();
		}
	};

	/**
	 * Привязывает события движения
	 *
	 * @param initial первый запуск
	 */
	private applyPanInteraction = (initial?: boolean) => {
		if ((this.props.onPanStart || this.props.onPanMove || this.props.onPanEnd) && this.handler) {
			const options: DraggableOptions = {
				...TouchRecognizer.defaultDragOptions,
				...this.props.dragOptions
			};
			if (initial) {
				options.onstart = this.handlePanStart;
				options.onmove = this.handlePanMove;
				options.onend = this.handlePanEnd;
			}
			this.handler.draggable(options);
		}
	};

	/**
	 * Привязывает события увеличения
	 */
	private applyPinchInteraction = () => {
		if ((this.props.onPinchStart || this.props.onPinchMove || this.props.onPinchEnd) && this.handler) {
			this.handler.gesturable({
				onstart: this.handlePinchStart,
				onmove: this.handlePinchMove,
				onend: this.handlePinchEnd
			});
		}
	};

	/**
	 * Привязывает события касания
	 */
	private applyTapInteractions = () => {
		if (this.handler) {
			if (this.props.onTap) {
				this.handler.on('tap', this.handleTap);
			}
			if (this.props.onDoubleTap) {
				this.handler.on('doubletap', this.handleDoubleTap);
			}
			if (this.props.onLongTap) {
				this.handler.on('hold', this.handleLongTap);
			}
		}
	};

	/**
	 * Останавливает отслеживание событий и жестов
	 */
	private stopInteraction = () => {
		if (this.handler) {
			this.handler.unset();
		}
	};

	/**
	 * Обрабатывает событие изменения размера элемента
	 */
	private handleResize = () => {
		if (this.props.onResize) {
			this.props.onResize();
		}
	};

	/**
	 * Сохраняет ссылку на dom-элемент
	 *
	 * @param element dom-элемент
	 */
	private saveRef = (element: HTMLDivElement | null) => {
		if (this.removeResizeEventListener) {
			this.removeResizeEventListener();
		}
		if (element) {
			this.addResizeEventListener(element);
		}
		this.startInteraction(element);
		if (this.props.elementRef) {
			this.props.elementRef(element);
		}
	};

	/**
	 * Создаёт наблюдатель за изменением размера элемента
	 *
	 * @param element dom-элемент
	 */
	private addResizeEventListener = (element: HTMLElement) => {
		const observer = new ResizeObserver(this.handleResize);
		observer.observe(element);
		this.removeResizeEventListener = () => observer.disconnect();
	};
}

export default TouchRecognizer;
