import {OfflineDataItem} from '@tehzor/ui-components';
import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {queryClient} from '@src/api/QueryClient';
import {useQuery} from '@tanstack/react-query';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useTranslation} from "react-i18next";

const acpIcon = <i className="tz-internal-acceptance-24" />;
export const CheckListsRecordsCacheStatus = () => {
	const {t} = useTranslation();
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	const settingsStatuses = queryClient.getQueryDefaults(checkRecordsQueryKeys.list());
	const {dataUpdatedAt, isError, isFetching, isPaused, isStale} = useQuery({
		queryKey: [...checkRecordsQueryKeys.list(), undefined, objects],
		networkMode: 'offlineFirst',
		staleTime: settingsStatuses?.staleTime
	});
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t('components.offlineModeDialog.checkListsRecordsCacheStatus.offlineDataItem.title')}
			icon={acpIcon}
			date={dataUpdatedAt}
			error={isError ? t('cacheStatusErrors.error') : isStale ? t('cacheStatusErrors.stale') : undefined}
			inProgress={!!isFetching || isPaused}
			progressTitle={t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default')}
		/>
	);
};
