import {makeObjectAddRequest} from '@src/api/backend/object';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {queryClient} from '@src/api/QueryClient';
import {ISavingObject} from '@src/interfaces/saving/ISavingObject';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';

const mutationFn = (object: ISavingObject) => makeObjectAddRequest(object);

const onSuccess = () => {
	void queryClient.invalidateQueries({queryKey: objectsQueryKeys.list()})
};

const onError = () => addErrorToast('Ошибка', 'при добавлении объекта');

export const useAddObject = () =>
	useMutation({
		mutationFn,
		mutationKey: objectsQueryKeys.add(),
		onSuccess,
		onError
	});
