import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ICheckRecordStatus} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {useExtractCheckRecordStatusesByEntityTypeAsArray} from '@src/core/hooks/queries/checkRecordStatuses/hooks';

export const useCheckRecordStatusesArray = (type: 'check-list' | 'check-item') => {
	const {t} = useTranslation();
	const {data: statuses} = useExtractCheckRecordStatusesByEntityTypeAsArray(type);

	return useMemo<ICheckRecordStatus[]>(
		() =>
			statuses
				? statuses.map(status => ({
						...status,
						name: t(`checkRecordStatus.${status.id}`)
				  }))
				: [],
		[statuses, t]
	);
};
