import './InternalAcceptancesPage.less';
import {LoadingPanel} from '@tehzor/ui-components';
import classNames from 'classnames';
import {InternalAcceptancesTable} from './components/table/InternalAcceptancesTable';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {ObjectPageBreadcrumbs} from './components/ObjectPageBreadcrumbs';
import {AllObjectPageBreadcrumbs} from './components/AllPageBreadcrumbs';
import ActionsDesktop from './components/actions/Actions.desktop';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {InternalAcceptancesSearch} from './components/InternalAcceptancesSearch';
import {InternalAcceptancesFilters} from './components/InternalAcceptancesFilters';
import {InternalAcceptancesPageCounter} from './components/InternalAcceptancesPageCounter';
import {useParams} from 'react-router-dom';
import {useInternalAcceptancesQuery} from '@src/core/hooks/queries/internalAcceptances/hooks';

interface IInternalAcceptancesPageProps {
	className?: string;
}

export const InternalAcceptancesPage = ({className}: IInternalAcceptancesPageProps) => {
	useScrollRestoration();
	const {state} = useEntitiesFiltersCtx();
	const {objectId} = useParams<{objectId?: string}>();
	const {isLoading} = useInternalAcceptancesQuery(objectId || 'all', state);
	const isDesktop = useIsDesktop();
	const actions = isDesktop ? <ActionsDesktop objectId={objectId}/> : null;

	return (
		<LoadingPanel
			className="internal-acceptances-page__loading-panel"
			active={isLoading}
		>
			<div className={classNames('page-cont', 'internal-acceptances-page', className)}>
				{objectId !== undefined ? (
					<ObjectPageBreadcrumbs objectId={objectId}>{actions}</ObjectPageBreadcrumbs>
				) : (
					isDesktop && <AllObjectPageBreadcrumbs>{actions}</AllObjectPageBreadcrumbs>
				)}
				<div className="internal-acceptances-page__filters-bar">
					{!isDesktop && (
						<div className="internal-acceptances-page__search">
							<InternalAcceptancesSearch/>
						</div>
					)}
					<InternalAcceptancesFilters objectId={objectId}/>
				</div>
				<InternalAcceptancesPageCounter objectId={objectId}/>
				<InternalAcceptancesTable objectId={objectId}/>
			</div>
		</LoadingPanel>
	);
};
