import {IGetCompaniesResponse} from '@src/api/backend/companies';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

/**
 * Возвращает компании
 */
export const extractCompanies = (data: IGetCompaniesResponse) => data;

/**
 * Возвращает компании в виде массива
 */
export const extractCompaniesAsArray = (data: IGetCompaniesResponse) =>
	data.allIds.map(id => data.byId[id]);

/**
 * Возвращает массив id всех компаний
 */
export const extractCompaniesAllIds = (data: IGetCompaniesResponse) => data.allIds;

/**
 * Возвращает компании в виде record string
 */
export const extractCompaniesAllById = (data: IGetCompaniesResponse) => data.byId;

/**
 * Возвращает компании в виде мапы
 */
export const extractCompaniesAsMap = (data: IGetCompaniesResponse) => {
	const allIds = extractCompaniesAllIds(data);
	const byId = extractCompaniesAllById(data);
	const companiesMapById = new Map() as Map<string, ICompany>;
	allIds.forEach(id => companiesMapById.set(id, byId[id]));
	return companiesMapById;
};

/**
 * Возвращает главные компании и компании подрядчики как два массива
 */
export const extractMainAndSubCompaniesAsArray = (data: IGetCompaniesResponse) => {
	const companies = extractCompaniesAsArray(data);
	const contractorIds = new Set(
		companies?.flatMap(item => item.contractors?.map(el => el.subCompanyId))
	);
	return {
		mainCompanies: companies?.filter(item => !contractorIds.has(item.id)) || [],
		subCompanies: companies?.filter(item => contractorIds.has(item.id)) || []
	};
};

/**
 * Возвращает подрядчиков в виде массива
 */
export const extractCompaniesContractorsAsArray = (data: IGetCompaniesResponse) => {
	const companies = extractCompaniesAsArray(data);
	const contractorsSet = new Set<ICompany>();
	companies?.forEach(company => {
		if (company.contractors) {
			company.contractors.forEach(el => {
				const contractor = companies?.find(item => item.id === el.subCompanyId);
				if (contractor) {
					contractorsSet.add(contractor);
				}
			});
		}
	});
	const contractors = [...contractorsSet];
	return contractors;
};

/**
 * Возвращает подрядчиков в виде объекта {byId, allIds}
 */
export const extractCompaniesContractors = (data: IGetCompaniesResponse) => {
	const contractors = extractCompaniesContractorsAsArray(data);
	return contractors
		.sort((a, b) => (a.name > b.name ? 1 : -1))
		.reduce<INormalizedData<ICompany>>(
			(prev, item) => {
				prev.allIds.push(item.id);
				prev.byId[item.id] = item;
				return prev;
			},
			{
				byId: {},
				allIds: []
			}
		);
};

export const extractCompanyById = (data: IGetCompaniesResponse, id?: string) => {
	const companies = extractCompaniesAllById(data);
	return companies && id && companies[id] ? companies[id] : undefined;
};
/**
 * Возвращает инспекторов/сотрудников компании или компаний
 */
export const extractCompanyInspectors = (
	data: IGetCompaniesResponse,
	users?: Record<string, IBriefUser>,
	objectId?: string,
	object?: IObject
) => {
	if (!users) return undefined;
	const companies = extractCompanies(data);
	const uniqueUsers = new Set<string>();

	if (objectId !== 'all') {
		const company = object && data?.byId[object.companyId];
		if (company?.employees) {
			company.employees.forEach(e => uniqueUsers.add(e.userId));
		}
	} else {
		companies?.allIds.forEach(companyId => {
			const company = data?.byId[companyId];
			if (company?.employees) {
				company.employees.forEach(e => uniqueUsers.add(e.userId));
			}
		});
	}

	const result: IBriefUser[] = [];
	uniqueUsers.forEach(userId => {
		const user = users[userId];
		if (user) {
			result.push(user);
		}
	});
	return result.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
};

/**
 * Возвращает пользователей компании включая подрядчиков
 */
export const extractCompanyWithContractorsUsersAsArray = (
	data: IGetCompaniesResponse,
	usersMap?: Record<string, IBriefUser>,
	companyId?: string
) => {
	const companiesMap = extractCompaniesAllById(data);

	if (!companyId || !companiesMap[companyId] || !usersMap) {
		return [];
	}
	const company = companiesMap[companyId];
	const contractors = company?.contractors;
	const contractorsIds = contractors?.map(item => item.subCompanyId);
	const contractorCompanies: ICompany[] = [];
	if (contractorsIds) {
		contractorsIds.forEach(id => {
			if (companiesMap[id]) {
				contractorCompanies.push(companiesMap[id]);
			}
		});
	}
	const employees = company?.employees;

	const usersSet = new Set<string>();

	contractorCompanies?.forEach(c => {
		c.employees?.forEach(e => {
			usersSet.add(e.userId);
		});
	});
	employees?.forEach(i => {
		usersSet.add(i.userId);
	});

	const result: IBriefUser[] = [];
	usersSet.forEach(id => {
		if (usersMap[id]) {
			result.push(usersMap[id]);
		}
	});
	return result.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
};
