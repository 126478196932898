import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as problemReplyTypes from '@src/store/modules/entities/problemReply/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {IGetWarrantyClaimPayload} from './actions/get';
import {
	IEditWarrantyClaimStatusPayload,
	IMoveWarrantyClaimPayload,
	IEditWarrantyClaimPayload
} from './actions';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IAddProblemCommentPayload} from '@src/store/modules/entities/problemComment/actions';

const {entitiesCacheTime} = config;

export interface IWarrantyClaimState {
	data?: ILinkedWarrantyClaim;
	expires?: number;
	problemReplies: {
		byId: Record<string, IComment>;
		allIds: string[];
		expires?: number;
	};
}

export const getInitialState = (): IWarrantyClaimState => ({
	problemReplies: {
		byId: {},
		allIds: []
	}
});

export default createReducer<IWarrantyClaimState>(getInitialState(), {
	[types.GET_REQUEST]: getInitialState,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetWarrantyClaimPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditWarrantyClaimPayload}) => {
		state.data = {...state.data, ...payload};
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_STATUS_SUCCESS]: (state, {payload}: {payload: IEditWarrantyClaimStatusPayload}) => {
		state.data = {...state.data, ...payload};
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.MOVE_SUCCESS]: (state, {payload}: {payload: IMoveWarrantyClaimPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Ответы на нарушения
	[problemReplyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemCommentPayload}) => {
		if (state.data?.id === payload.links?.warrantyClaimId) {
			state.problemReplies.byId[payload.id] = payload;
			state.problemReplies.allIds.unshift(payload.id);
		}
	},
	[CLEAR_STORE]: getInitialState
});
