import {EditorInfo, EntityGrid, EntityGridItem, InlineButton, Plate} from '@tehzor/ui-components';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import Documents from './Documents';
import {useEditableCheckAttachmentsDialog} from '@src/components/EditableCheckAttachmentsDialog/hooks/useEditableCheckAttachmentsDialog';
import {useCheckPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import DescriptionDesktop from './Description.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import DescriptionMobile from './Description.mobile';
import {MobileEntityDocs} from '@src/components/EntityDocs';
import {useTranslation} from 'react-i18next';

interface IDesktopInfoProps {
	objectId: string;
	check: ILinkedCheck;
}

const documentsIcon = <i className="tz-document-20" />;
const editIcon = <i className="tz-edit-16" />;
const calendarIcon = <i className="tz-calendar-20" />;

const Info = ({objectId, check}: IDesktopInfoProps) => {
	const {t} = useTranslation();

	// Диалог редактирования вложений
	const [editingAttachmentsDialog, openEditAttachmentsDialog] = useEditableCheckAttachmentsDialog(
		objectId,
		check.id,
		check
	);
	const permissions = useCheckPermissions(objectId, check);
	const isDesktop = useIsDesktop();
	return (
		<Plate>
			<EntityGrid withBorders>
				{isDesktop ? (
					<DescriptionDesktop
						objectId={objectId}
						checkId={check.id}
						value={check.description}
						canEdit={permissions.canEdit}
					/>
				) : (
					<DescriptionMobile
						objectId={objectId}
						checkId={check.id}
						value={check.description}
						canEdit={permissions.canEdit}
					/>
				)}

				<EntityGridItem
					label={t('checksPage.info.documents')}
					icon={documentsIcon}
					fullRow
					buttons={
						permissions.canEdit ? (
							<InlineButton
								className="check-page__documents-edit-icon"
								type="accent"
								leftIcon={editIcon}
								onClick={openEditAttachmentsDialog}
							/>
						) : null
					}
				>
					<Documents check={check} />
				</EntityGridItem>
				<EditorInfo
					icon={calendarIcon}
					label={t('checksPage.info.created')}
					date={check.createdAt}
					user={check.createdBy}
					fullRow
				/>
				{check.createdAt !== check.modifiedAt && (
					<EditorInfo
						icon={calendarIcon}
						label={t('checksPage.info.modified')}
						date={check.modifiedAt}
						user={check.modifiedBy}
						fullRow
					/>
				)}
				{isDesktop ? null : <MobileEntityDocs id={check.id} />}
			</EntityGrid>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default Info;
