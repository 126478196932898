import {IMutationErrorsMap} from '@src/interfaces/IMutationErrorsMap';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {offlineJournalActionType} from '../../enums/actionsTypes';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';

export const extractLocalWarrantyClaimsJournal = (
	warrantyClaims: IListWarrantyClaim[],
	errorsMap: IMutationErrorsMap
) =>
	warrantyClaims.map(
		claim =>
			({
				entity: {
					entityId: claim.id,
					entityLocalNumber: claim.localNumber,
					entityType: offlineJournalActionType.WARRANTY_CLAIM_ADD
				},
				object: {
					objectId: claim.object?.id,
					objectName: claim.object?.name
				},
				transfer: {
					status: claim.transferStatus,
					statusMessage: claim.transferStatus
						? offlineModeTransferTitles[claim.transferStatus]
						: ''
				},
				createdAt: claim.createdAt,
				error: errorsMap[claim.id]
			}) as IOfflineJournalEntity
	);
