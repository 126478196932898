import {memo} from 'react';
import {CellProps} from 'react-table';
import {IPreparedClaim} from '@src/pages/WarrantyClaimsPage/interfaces/IPreparedClaim';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

export const SpaceCell = memo(({row}: CellProps<IPreparedClaim>) => {
	const claim = row.original;
	const spaceType = useTranslatedDictionaryById(
		dictionaryKeys.spaceTypesSingular,
		claim.space?.type
	);
	const {t} = useTranslation();

	return (
		<div className="warranty-claims-table__space-cell">
			{claim.space?.name && claim.space.type && (
				<div>{`${claim.space?.name} ${spaceType}${claim.space?.floor ? ',' : ''}`}</div>
			)}
			{claim.space?.floor && (
				<div>
					{`${claim.space?.floor} ${t(
						'warrantyClaimsPage.table.columns.spaceCell.floor'
					)}`}
				</div>
			)}
		</div>
	);
});
