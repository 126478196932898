import {AcceptorsFilterPage} from '@src/pages/filters/AcceptorsFilterPage';
import CategoriesSetFilterPage from '@src/pages/filters/CategoriesSetFilterPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';
import {WorkAcceptanceFrontTypeFilterPage} from '@src/pages/filters/WorkAcceptanceFrontTypeFilterPage';
import {WorkAcceptanceStatusesFilterPage} from '@src/pages/filters/WorkAcceptanceStatusesFilterPage';
import {WorkAcceptanceTypeFilterPage} from '@src/pages/filters/WorkAcceptanceTypeFilterPage';
import {WorkAcceptancesAcceptDateFilterPage} from '@src/pages/filters/WorkAcceptancesAcceptDateFilterPage';
import {WorkAcceptancesFiltersPage} from '@src/pages/filters/WorkAcceptancesFiltersPage/WorkAcceptancesFiltersPage';

export const workAcceptancesFilterRoutes = [
	{path: 'objects', element: <FiltersPageObjects />},
	{
		path: 'statuses',
		element: <WorkAcceptanceStatusesFilterPage />
	},
	{
		path: 'fronttypes',
		element: <WorkAcceptanceFrontTypeFilterPage />
	},
	{
		path: 'types',
		element: <WorkAcceptanceTypeFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: 'acceptancedate',
		element: <WorkAcceptancesAcceptDateFilterPage />
	},
	{
		path: 'acceptors',
		element: <AcceptorsFilterPage />
	},
	{
		path: 'createdby',
		element: <CreatedByFilterPage />
	},
	{
		path: 'categories',
		element: <CategoriesSetFilterPage />
	},
	{
		path: '',
		element: <WorkAcceptancesFiltersPage />
	}
];
