import { memo, useMemo } from 'react';
import {Tree} from '@tehzor/ui-components';
import {useFiltersDialogDispatch} from '../utils/FiltersDialogDispatchCtx';
import Controls from './Controls';
import {makeContractorsTreeData} from '../utils/makeContractorsTreeData';
import {useMainAndSubCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useUsers} from '@src/core/hooks/queries/users/hooks';

interface IContractorsTabProps {
	checked: {[key: string]: string[]};
	expanded: {[key: string]: string[]};
}

const ContractorsTab = ({checked, expanded}: IContractorsTabProps) => {
	const {data} = useMainAndSubCompaniesAsArray();
	const {data: users} = useUsers();

	const treeData = useMemo(() => {
		const mainCompanies = data?.mainCompanies || [];
		const subCompanies = data?.subCompanies || [];
		return users ? makeContractorsTreeData(mainCompanies, subCompanies, users) : undefined;
	}, [data?.mainCompanies, data?.subCompanies, users]);

	const dispatch = useFiltersDialogDispatch();

	const handleCheck = (key: string, value: string[]) => {
		dispatch({
			type: 'update',
			field: 'checkedContractors',
			value: {
				...checked,
				[key]: value
			}
		});
	};

	const handleExpand = (key: string, value: string[]) => {
		dispatch({
			type: 'update',
			field: 'expandedContractors',
			value: {
				...expanded,
				[key]: value
			}
		});
	};

	return (
		<>
			<Controls checkedField="checkedContractors" />

			<div className="stats-filters-dialog__tree-wrap">
				<div className="stats-filters-dialog__tree">
					{treeData?.map(item => (
						<Tree
							key={item.key}
							isCheckable
							checkOnlyParents
							multiple
							checkedValue={checked[item.key]}
							expandedValue={expanded[item.key]}
							isAllCheckedExpanded
							data={item.data}
							onCheck={(value: string[]) => handleCheck(item.key, value)}
							onExpand={(value: string[]) => handleExpand(item.key, value)}
						/>
					))}
				</div>
			</div>
		</>
	);
};

export default memo(ContractorsTab);
