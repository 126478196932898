import {ITasksSettingsState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {getMonthName} from './timeMonthUtils';

export const createScheduleHeaderValue = (settings: ITasksSettingsState): string => {
	const {currentMode, year, month} = settings;

	if (currentMode === 'year') {
		return year.toString();
	}

	if (currentMode === 'month') {
		return `${getMonthName(month).toLowerCase()} ${year}`;
	}

	return year.toString();
};
