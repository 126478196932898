import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type ICopyCheckItemResponse = INormalizedData<ICheckItem>;

/**
 * Копирует категорию вместе со всеми вложенными
 *
 * @param checkListId id чек-листа
 * @param id  идентификатор категориии которую копируем
 * @param subIds массив id тех внутренних категорий любой вложенности которые тоже хотим копировать
 */

export const makeCheckItemCopyRequest = async (
	checkListId: string,
	id: string,
	subIds?: string[]
) => {
	const response = await httpRequests.withToken.post<ICopyCheckItemResponse>(
		'/check-items/copy',
		{
			checkListId,
			id,
			subIds
		}
	);
	return response.data;
};
