import {useMemo} from 'react';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

export type IStatsData = Record<string, {count: number}>;

/**
 * Хук нужен для того чтобы преобразовать данные для одной сущности
 *
 * @param statsData данные типа {fixed: { count: 10 }}
 */
export const useProblemsCounts = (statsData?: IStatsData) =>
	useMemo(() => {
		if (!statsData) return undefined;

		const statusesCounts: Record<ProblemStatusId, number> = {
			[ProblemStatusId.EXPIRED]: 0,
			[ProblemStatusId.WAITING]: 0,
			[ProblemStatusId.HANDLING]: 0,
			[ProblemStatusId.VERIFICATION]: 0,
			[ProblemStatusId.FIXED]: 0
		};

		Object.entries(statsData).forEach(([status, data]) => {
			statusesCounts[status] += data.count;
		});

		return Object.entries(statusesCounts).map(([key, val]) => ({
			status: key as ProblemStatusId,
			count: val
		}));
	}, [statsData]);
