import {IAddContractResponse} from '@src/api/backend/contract';
import {IAddContractParams} from '@src/api/cache/contracts/defaults/mutations/add';
import {contractsQueryKeys} from '@src/api/cache/contracts/keys';
import {useAddContractMutation} from '@src/api/cache/contracts/mutations';
import {useQueryClient} from '@tanstack/react-query';

export const useAddContract = (): ((
	params: IAddContractParams
) => Promise<IAddContractResponse | undefined>) => {
	const mutation = useAddContractMutation();
	const queryClient = useQueryClient();

	return async (params: IAddContractParams) => {
		const response = await mutation.mutateAsync(params);
		await queryClient.invalidateQueries({queryKey: contractsQueryKeys.all()});
		return response;
	};
};
