import {useCallback, useMemo} from 'react';
import * as React from 'react';
import {useEntitiesViewer} from './useEntitiesViewer';
import {IEntity} from '@tehzor/ui-components/src/components/photos/EntitiesMobilePhotoViewer/EntitiesMobilePhotoViewer';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import IAttachmentsImageData from '@tehzor/tools/interfaces/IAttachmentsImageData';

/**
 * Хук для создания просмотрщика изображений сущности и ответа
 *
 * @param entityImages изображения сущности
 * @param replyImages изображения ответа
 * @param entityAttachmentFileDestination
 * @param commentsAttachmentFileDestination
 * @param entityImagesData данные изображений нарушения
 * @param replyImagesData данные изображений ответа
 * @param entityType тип сущности
 * @param entityTitle заголовок сущности
 * @param replyNumber номер ответа
 * @param entityId id сущности
 * @param replyId id ответа
 */
export function useEntityAndReplyViewerMobile(
	entityImages: string[],
	replyImages: string[],
	entityAttachmentFileDestination: AttachmentFileDestination,
	commentsAttachmentFileDestination: AttachmentFileDestination,
	entityImagesData: IAttachmentsImageData[],
	replyImagesData: IAttachmentsImageData[],
	entityType: string,
	entityTitle: string,
	replyNumber?: number,
	entityId?: string,
	replyId?: string
): [React.ReactNode, (i: number) => void, (i: number) => void] {

	// Формирование общего списка изображений
	const data = useMemo(() => {
		const result: IEntity[] = [];
		if (entityImages.length) {
			result.push({
				id: entityId || entityType,
				title: entityTitle,
				files: entityImages,
				type: entityAttachmentFileDestination,
				attachments: entityImagesData
			});
		}
		if (replyImages.length) {
			result.push({
				id: replyId || 'reply',
				title: replyNumber ? `Фото ответа №${replyNumber}` : 'Фото ответа',
				files: replyImages,
				type: commentsAttachmentFileDestination,
				attachments: replyImagesData
			});
		}
		return result;
	}, [
		entityImages,
		replyImages,
		entityId,
		entityType,
		entityTitle,
		entityAttachmentFileDestination,
		entityImagesData,
		replyId,
		replyNumber,
		commentsAttachmentFileDestination,
		replyImagesData
	]);

	const [viewer, openImage] = useEntitiesViewer(data, false);

	const openEntityImage = useCallback(
		(index: number) => {
			openImage(entityId || entityType, index);
		},
		[entityId, entityType, openImage]
	);
	const openReplyImage = useCallback(
		(index: number) => {
			openImage(replyId || 'reply', index);
		},
		[openImage, replyId]
	);

	return [viewer, openEntityImage, openReplyImage];
}
