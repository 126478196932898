import {memo, useCallback, useMemo, useState} from 'react';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useUpdateEffect} from 'react-use';
import {FilterButton, Select2, SelectOption} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useSpaceOwnersAsArray} from '@src/core/hooks/queries/spaceOwners/hooks';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useTranslation} from 'react-i18next';

interface IOwnersFilterProps {
	owners?: string[];
	label?: string;
}

export const SpaceOwnersFilter = memo((props: IOwnersFilterProps) => {
	const {owners, label} = props;
	const {t} = useTranslation();
	const [selectedOwners, setSelectedOwners] = useState(owners);
	const [search, setSearch] = useState('');

	const {data: allOwners} = useSpaceOwnersAsArray();

	const {dispatch} = useEntitiesFiltersCtx();

	const clearSearch = useCallback(() => setSearch(''), []);
	const filteredData = useMemo(
		() => (allOwners ? flatFilter(allOwners, 'name', search) : undefined),
		[allOwners, search]
	);

	const handleApply = useCallback(() => {
		dispatch({spaceOwners: selectedOwners});
		clearSearch();
	}, [selectedOwners, clearSearch, dispatch]);

	const handleClear = useCallback(() => {
		setSelectedOwners([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedOwners([]);
		dispatch({spaceOwners: undefined});
		clearSearch();
	}, [clearSearch, dispatch]);

	const handleCancel = useCallback(() => {
		setSelectedOwners(owners);
		clearSearch();
	}, [owners, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedOwners(allOwners?.map(val => val.id));
	}, [allOwners, clearSearch]);

	useUpdateEffect(() => {
		setSelectedOwners(owners);
	}, [owners]);

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			onSelectAll={handleSelectAll}
			clearButton={!!selectedOwners?.length}
			count={selectedOwners?.length}
			footer
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel(
						label ?? t('entitiesFilters.spaceOwnersFilter.label'),
						owners,
						allOwners
					)}
					active={!!owners?.length}
					onClear={handleFullClear}
				/>
			}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
					type="popup"
				/>
			}
		>
			<Select2
				multiple
				value={selectedOwners}
				onChange={setSelectedOwners}
			>
				{filteredData?.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</TranslatedSelectPopup>
	);
});
