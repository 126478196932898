import {useCallback} from 'react';
import './EditableCheckAttachmentsDialog.less';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {useEditableCheckAttachments} from '../EditableCheckAttachments/hooks/useEditableCheckAttachments';
import {useTranslation} from 'react-i18next';
import {useEditCheck} from '@src/core/hooks/mutations/checks/useEditCheck';

interface IEditableOwnerAcceptanceAttachmentsDialogProps {
	objectId: string;
	checkId: string;
	check: ICheck;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования вложений проверки
 */
export const EditableCheckAttachmentsDialog = ({
	objectId,
	checkId,
	check,
	isOpen,
	onClose
}: IEditableOwnerAcceptanceAttachmentsDialogProps) => {
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const {t} = useTranslation();
	const {mutateAsync: editCheck, isPending: saving} = useEditCheck();

	const [fields, getSavingData, reset, isBlocking] = useEditableCheckAttachments(
		objectId,
		check,
		saving
	);

	const save = useCallback(async () => {
		if (checkId) {
			const savingData = await getSavingData();
			if (savingData) {
				if (!networkStatus) {
					// Локальное сохранение
					// const savingData = convertToSave(editingState, problem, true);
					// savingData.newAttachments = convertToLocalSave(files);
					// TODO Локальное сохранение
				} else {
					try {
						await editCheck({
							objectId,
							checkId,
							fields: savingData
						});
						onClose();
					} catch (error) {
						console.log(error);
						// TODO Локальное сохранение
					}
				}
			}
		}
	}, [checkId, getSavingData, networkStatus, objectId, onClose]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		t('useConfirmDialog.title'),
		t('useConfirmDialog.message'),
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose, getClosingConfirmation]);

	return (
		<ModalDialog
			className={{root: 'editable-check-attachments-dialog'}}
			open={isOpen}
			title={t('editableCheckAttachmentsDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('editableCheckAttachmentsDialog.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('editableCheckAttachmentsDialog.save')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</ModalDialog>
	);
};
