import { memo } from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useTranslation} from 'react-i18next';

interface ICheckListsFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	checkLists: ICheckList[];
	onChange: (changes: IChanges) => void;
}

export const CheckListsFilterMobile = memo((props: ICheckListsFilterMobileProps) => {
	const {t} = useTranslation();

	return (
		<BaseListFilterMobile
			{...props}
			options={props.checkLists}
			label={t('spacesFiltersPage.checkListsFilterMobile.label')}
			filterName="checkListIds"
			resetOnApply={['checkListCategory']}
		/>
	);
});
