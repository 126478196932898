import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {IGetOwnerAcceptanceStatusesResponse} from '@src/api/backend/ownerAcceptanceStatuses';
import {
	extractOwnerAcceptancesStatusesAsArray,
	extractOwnerAcceptancesStatusesAsMap
} from './selectors';

export const useOwnerAcceptancesStatuses = <T = IGetOwnerAcceptanceStatusesResponse>(
	select?: (data: IGetOwnerAcceptanceStatusesResponse) => T
) =>
	useQuery<IGetOwnerAcceptanceStatusesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.ownerAcceptanceStatuses(),
		meta: {
			error: 'при получении статусов приёмок'
		},
		select
	});

export const useOwnerAcceptancesStatusesMap = () =>
	useOwnerAcceptancesStatuses(extractOwnerAcceptancesStatusesAsMap);

export const useOwnerAcceptancesStatusesArray = () =>
	useOwnerAcceptancesStatuses(extractOwnerAcceptancesStatusesAsArray);
