import { memo, useState } from 'react';
import * as React from 'react';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {useUpdateEffect} from 'react-use';
import {
	useObjectStagesAsArray,
	useObjectStagesMap
} from '@src/core/hooks/queries/objectStages/hooks';

interface IObjectStagesSelectProps {
	value?: string[];
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	errorMessage?: string;
	onChange?: (value?: string[], required?: boolean) => void;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const simpleArrowIcon = <i className="tz-simple-arrow-20" />;

export const ObjectStagesMultipleSelect = memo((props: IObjectStagesSelectProps) => {
	const {
		className,
		style,
		label = 'Стадия объекта',
		errorMessage = 'Выберите стадию объекта',
		value,
		onChange,
		required,
		disabled,
		hasError
	} = props;
	const {data: types} = useObjectStagesAsArray();
	const {data: typesMap} = useObjectStagesMap();

	const [selectedValue, setSelectedValue] = useState(value);

	useUpdateEffect(() => {
		setSelectedValue(value);
	}, [value]);

	const handleApply = () => {
		onChange?.(selectedValue, required);
	};

	const handleClear = () => {
		setSelectedValue(undefined);
	};

	const handleCancel = () => {
		setSelectedValue(value);
	};

	if (!typesMap) return null;

	const labelName = selectedValue?.map(v => typesMap[v]?.name).join(', ');
	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				footer
				onApply={handleApply}
				onClear={handleClear}
				onCancel={handleCancel}
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={labelName}
						icon={simpleArrowIcon}
						error={required && hasError ? errorMessage : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					multiple
					onChange={setSelectedValue}
					value={selectedValue}
				>
					{types &&
						types.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="checkbox"
							/>
						))}
				</Select2>
			</SelectPopup>
		</div>
	);
});
