import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ITaskPriority} from '@tehzor/tools/interfaces/tasks/ITaskPriority';

export type IGetTaskPrioritiesResponse = INormalizedData<ITaskPriority>;

/**
 * Возвращает список приритетов задач
 */
export const requestTaskPriorities = async () => {
	const response = await httpRequests.withToken.get<IGetTaskPrioritiesResponse>(
		'task-priorities'
	);
	return response.data;
};
