import {useCallback, useMemo, useState} from 'react';
import * as React from 'react';
import {DesktopPhotoViewer} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import {useTranslation} from 'react-i18next';
import {IDesktopPhotoViewerPropsTranslations} from '@tehzor/ui-components/src/components/photos/DesktopPhotoViewer';

/**
 * Хук для базового просмотрщика изображений
 *
 * @param images изображения
 */
export function useViewer(images: string[]): [React.ReactNode, (i: number) => void] {
	const {t} = useTranslation();

	// Состояния для активного фото и видимости просмотрщика
	const [isOpen, toggleOpen] = useToggle(false);
	const [activeIndex, setActiveIndex] = useState<number>();

	const openImage = useCallback((index: number) => {
		setActiveIndex(index);
		toggleOpen(true);
	}, []);

	const translations = useMemo<IDesktopPhotoViewerPropsTranslations>(
		() => ({
			desktopPhotoEditorFooter: {
				applyBtn: t('readyBtn.label'),
				cancelBtn: t('cancelBtn.label'),
				editBtn: t('editBtn.label')
			}
		}),
		[t]
	);

	const viewer = (
		<DesktopPhotoViewer
			data={images}
			value={activeIndex}
			isOpen={isOpen}
			onChange={setActiveIndex}
			onClose={toggleOpen}
			translations={translations}
		/>
	);

	return [viewer, openImage];
}
