import { forwardRef, Ref } from 'react';
import * as React from 'react';
import './ColorPickerButton.less';
import ButtonBase from '../ButtonBase';

interface IColorPickerButtonProps {
	className?: string;
	style?: React.CSSProperties;
	color?: string;
	disabled?: boolean;

	onClick?(event: React.MouseEvent): void;
}

const ColorPickerButton = (
	{className, style, color, disabled, onClick}: IColorPickerButtonProps,
	ref?: Ref<HTMLButtonElement>
) => (
	<ButtonBase
		className={className}
		style={{...style, backgroundColor: color}}
		classNamePrefix="color-picker-button"
		disabled={disabled}
		outerTagType="button"
		onClick={onClick}
		ref={ref}
	/>
);

ColorPickerButton.displayName = 'ColorPickerButton';

export default forwardRef(ColorPickerButton);
