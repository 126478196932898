import {wsConnector} from '../wsConnector';

export type IDeleteInternalAcceptanceResponse = void;

/**
 * Удаляет внутреннюю приёмку
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 */
export const makeInternalAcceptanceDeleteRequest = (objectId: string, internalAcceptanceId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteInternalAcceptanceResponse>('deleteInternalAcceptance', {objectId, internalAcceptanceId});
