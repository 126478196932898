import {createElement} from 'react';
import IObjectStage, {IObjectStageWithIcon} from '@tehzor/tools/interfaces/objects/IObjectStage';

export const convertStages = (stages: IObjectStage[]): IObjectStageWithIcon[] =>
	stages.map(stage => ({
		id: stage.id,
		name: stage.name,
		color: stage.color,
		icon: createElement('i', {
			className: `tz-${stage.iconName}-28`,
			style: {color: stage.color}
		}),
		order: stage.order
	}));
