/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import './MobileSidebar.less';
import classNames from 'classnames';
import {Scrollbar} from '../../containers/Scrollbar';
import Portal from '../../containers/Portal';
import anime, {AnimeInstance} from 'animejs';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = !!md.mobile();

const minVelocity = 1;
const minDistanceMultiplier = 0.3;

const defaultEasing = 'cubicBezier(0.32, 0.72, 0.37, 0.95)';
const formSpringEasing = (velocity: number) => `spring(0, 100, 100, ${velocity})`;

const hiddenSidebarClass = 'm-sidebar_hidden';

interface IMobileSidebarProps {
	className?: string;
	style?: React.CSSProperties;
	menu?: React.ReactNode;
	appInfo?: React.ReactNode;
	userInfo?: React.ReactNode;
	appRoot?: HTMLElement;
	visible: boolean;

	onVisibilityChange(visible: boolean): void;
}

class MobileSidebar extends React.PureComponent<IMobileSidebarProps> {
	static displayName = 'MobileSidebar';

	/**
	 * Dom-элемент sidebar
	 */
	private sidebar?: HTMLDivElement | null | undefined;

	/**
	 * Dom-элемент container
	 */
	private container?: HTMLDivElement;

	/**
	 * Dom-элемент overlay
	 */
	private overlay?: HTMLDivElement | null | undefined;

	/**
	 * Идентификатор тач-события
	 */
	private touchIdentifier?: number;

	/**
	 * Флаг необходимости распознать направление движения жеста
	 */
	private needRecognition = false;

	/**
	 * Текущая позиция по x
	 */
	private touchCurrentX = 0;

	/**
	 * Текущая позиция по y
	 */
	private touchCurrentY = 0;

	/**
	 * Текущее смещение sidebar'а
	 */
	private currentOffset = 0;

	/**
	 * Последнее время тач-события
	 */
	private startTouchTime = 0;

	/**
	 * Ширина sidebar
	 */
	private sidebarWidth = 0;

	/**
	 * Объект анимации
	 */
	private sidebarAnimation?: AnimeInstance;

	/**
	 * Объект анимации
	 */
	private overlayAnimation?: AnimeInstance;

	/**
	 * Resolve-функция promise'ов при открытии/закрытии
	 */
	private animResolveFn?: () => void;

	componentDidUpdate(prevProps: IMobileSidebarProps) {
		if (prevProps.visible !== this.props.visible) {
			if (this.props.visible) {
				this.disableScroll();
				this.startOpenAnimations();
			} else {
				this.enableScroll();
				this.startCloseAnimations();
			}
		}
	}

	componentWillUnmount() {
		this.enableScroll();
	}

	render() {
		const {className, style, menu, appInfo, userInfo, appRoot} = this.props;

		return (
			<>
				<div
					className="m-sidebar-toggle-area"
					onTouchStart={this.onTouchStart}
					onTouchMove={this.onTouchMove}
					onTouchEnd={this.onTouchEnd}
					onTouchCancel={this.onTouchEnd}
				/>

				<div
					className={classNames('m-sidebar', className)}
					style={style}
					onTouchStart={this.onTouchStart}
					onTouchMove={this.onTouchMove}
					onTouchEnd={this.onTouchEnd}
					onTouchCancel={this.onTouchEnd}
					ref={this.saveSidebarRef}
				>
					<Scrollbar
						className="m-sidebar__cont"
						ref={this.saveContRef}
					>
						<div className="m-sidebar__user-info-wrap">{userInfo}</div>

						<div className="m-sidebar__menu-wrap">{menu}</div>

						<div className="m-sidebar__app-info-wrap">{appInfo}</div>
					</Scrollbar>
				</div>

				<Portal
					className="m-sidebar-portal"
					root={appRoot}
				>
					<div
						className="overlay m-sidebar-overlay"
						onClick={this.close}
						ref={this.saveOverlayRef}
					/>
				</Portal>
			</>
		);
	}

	/**
	 * Открывает sidebar
	 */
	open = () => {
		const {visible, onVisibilityChange} = this.props;
		if (!visible) {
			onVisibilityChange(true);
			return new Promise<void>(resolve => {
				if (this.animResolveFn) {
					this.animResolveFn();
				}
				this.animResolveFn = resolve;
			}).then(() => {
				this.animResolveFn = undefined;
			});
		}
		return Promise.resolve();
	};

	/**
	 * Закрывает sidebar
	 */
	close = () => {
		const {visible, onVisibilityChange} = this.props;
		if (visible) {
			onVisibilityChange(false);
			return new Promise<void>(resolve => {
				if (this.animResolveFn) {
					this.animResolveFn();
				}
				this.animResolveFn = resolve;
			}).then(() => {
				this.animResolveFn = undefined;
			});
		}
		return Promise.resolve();
	};

	/**
	 * Запускает анимации открытия
	 */
	private startOpenAnimations = () => {
		this.setVisible();
		this.stopAnimations();
		this.animate(0, 1);
	};

	/**
	 * Запускает анимации закрытия
	 */
	private startCloseAnimations = () => {
		this.stopAnimations();
		this.animate(-this.sidebarWidth, 0);
	};

	/**
	 * Обработка события touchStart
	 *
	 * @param event событие
	 */
	private onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
		// Пропускаем жесты более чем одним пальцем и если ранее уже был начат другой жест
		if (this.touchIdentifier === undefined && event.touches.length === 1) {
			const touch = event.touches[0];
			this.touchIdentifier = touch.identifier;
			this.needRecognition = true;
			this.touchCurrentX = touch.clientX;
			this.touchCurrentY = touch.clientY;
			this.startTouchTime = Date.now();

			this.stopAnimations();
		}
	};

	/**
	 * Обработка события touchMove
	 *
	 * @param event событие
	 */
	private onTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
		const touch = this.getTouchFromEvent(event);
		if (touch) {
			// Если это первый вызов touchMove и необходимо определить направление движения
			if (this.needRecognition) {
				this.needRecognition = false;
				// Если изменение по вертикали больше изменения по горизонтали, от не обрабатываем последующие события
				if (
					Math.abs(this.touchCurrentX - touch.clientX)
					<= Math.abs(this.touchCurrentY - touch.clientY) * (this.props.visible ? 1.4 : 1)
				) {
					this.touchIdentifier = undefined;
					this.touchCurrentX = 0;
					this.touchCurrentY = 0;
					this.startTouchTime = 0;
					return;
				}
			}

			// Изменение смещения с предыдущего вызова
			const delta = touch.clientX - this.touchCurrentX;
			this.touchCurrentX = touch.clientX;
			// Проверка нахождения смещения в пределах диапазона [-this.sidebarWidth; 0]
			this.currentOffset = Math.max(Math.min(this.currentOffset + delta, 0), -this.sidebarWidth);

			if (this.sidebar) {
				this.sidebar.style.transform = `translateX(${this.currentOffset}px)`;
			}
			if (this.overlay) {
				this.overlay.style.opacity = (
					Math.round(((this.sidebarWidth + this.currentOffset) / this.sidebarWidth) * 100) / 100
				).toString();
			}
			this.setVisible();
		}
	};

	/**
	 * Обработка события touchEnd
	 *
	 * @param event событие
	 */
	private onTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
		const {visible, onVisibilityChange} = this.props;
		const touch = this.getTouchFromEvent(event);
		if (touch && onVisibilityChange) {
			// Расстояние, на которое сдвинут sidebar
			const distance = visible ? Math.abs(this.currentOffset) : this.sidebarWidth - Math.abs(this.currentOffset);
			// Скорость преодоления дистанции, используется как начальная для завершения анимации
			const velocity = distance / (Date.now() - this.startTouchTime);

			if (distance !== 0) {
				// Если ускорение больше необходимого минимума или дистанция больше минимально необходимой,
				// то нужно сменить состояние sidebar'а
				const needChangeState = velocity > minVelocity || distance > this.sidebarWidth * minDistanceMultiplier;

				let offset;
				let opacity;
				let easing;

				// При закрытии
				if (visible) {
					// Если движение было в нужном направлении и необходимо сменить состояние
					if (needChangeState) {
						onVisibilityChange(false);
						offset = -this.sidebarWidth;
						opacity = 0;
						easing = formSpringEasing(velocity);
					} else {
						offset = 0;
						opacity = 1;
					}
				} else if (needChangeState) {
					// При открытии и движении в нужном направлении
					// и необходимости сменить состояние
					onVisibilityChange(true);
					offset = 0;
					opacity = 1;
					easing = formSpringEasing(velocity);
				} else {
					offset = -this.sidebarWidth;
					opacity = 0;
				}

				this.animate(offset, opacity, easing);
			} else if (!visible) {
				this.setInvisible();
			}

			this.touchIdentifier = undefined;
			this.touchCurrentX = 0;
			this.touchCurrentY = 0;
			this.startTouchTime = 0;
		}
	};

	/**
	 * Анимирует sidebar по translateX и overlay по opacity
	 *
	 * @param sidebarOffset смещение
	 * @param overlayOpacity прозрачность
	 * @param easing функция плавности
	 */
	private animate = (sidebarOffset: number, overlayOpacity: number, easing = defaultEasing) => {
		let completedCount = 0;
		const complete = () => {
			if (++completedCount === 2) {
				if (this.animResolveFn) {
					this.animResolveFn();
				}
			}
		};

		if (this.sidebar) {
			this.sidebarAnimation = anime({
				targets: this.sidebar,
				translateX: sidebarOffset,
				easing,
				duration: 250,
				update: () => {
					if (this.sidebar) {
						// Извлечение из стилей реального смещения
						this.currentOffset = +this.sidebar.style.transform.slice(11, -3);
					}
				},
				complete
			});
		}
		if (this.overlay) {
			this.overlayAnimation = anime({
				targets: this.overlay,
				opacity: overlayOpacity,
				easing,
				duration: 250,
				complete: () => {
					if (overlayOpacity === 0) {
						this.setInvisible();
					}
					complete();
				}
			});
		}
	};

	/**
	 * Останавливает анимации
	 */
	private stopAnimations = () => {
		if (this.sidebar && this.sidebarAnimation) {
			this.sidebarAnimation.pause();
			anime.remove(this.sidebar);
			this.sidebarAnimation = undefined;
		}
		if (this.overlay && this.overlayAnimation) {
			this.overlayAnimation.pause();
			anime.remove(this.overlay);
			this.overlayAnimation = undefined;
		}
	};

	/**
	 * Делает видимыми элементы, необходимые для анимаций
	 */
	private setVisible = () => {
		if (this.overlay && this.overlay.style.visibility !== 'visible') {
			this.overlay.style.visibility = 'visible';
		}
		if (this.sidebar) {
			this.sidebar.classList.remove(hiddenSidebarClass);
		}
	};

	/**
	 * Делает невидимыми элементы, после завершения анимаций
	 */
	private setInvisible = () => {
		if (this.overlay && this.overlay.style.visibility !== 'hidden') {
			this.overlay.style.visibility = 'hidden';
		}
		if (this.sidebar) {
			this.sidebar.classList.add(hiddenSidebarClass);
		}
	};

	/**
	 * Находит необходимый touch-элемент в событии
	 *
	 * @param event событие
	 */
	private getTouchFromEvent = (event: React.TouchEvent<HTMLDivElement>): React.Touch | undefined => {
		if (this.touchIdentifier === undefined) {
			return;
		}
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < event.changedTouches.length; i++) {
			if (event.changedTouches[i].identifier === this.touchIdentifier) {
				return event.changedTouches[i];
			}
		}
		return undefined;
	};

	/**
	 * Блокирует скролл body
	 *
	 * @private
	 */
	private disableScroll = () => {
		if (isMobile && this.container) {
			disableBodyScroll(this.container);
		}
	};

	/**
	 * Разлокировывает скролл body
	 *
	 * @private
	 */
	private enableScroll = () => {
		if (isMobile && this.container) {
			enableBodyScroll(this.container);
		}
	};

	private saveSidebarRef = (node: HTMLDivElement): void => {
		this.sidebar = node;
		this.sidebarWidth = node ? node.offsetWidth : 0;
		if (this.sidebar) {
			const offset = this.props.visible ? 0 : -this.sidebarWidth;
			this.sidebar.style.transform = `translateX(${offset}px)`;
			this.currentOffset = offset;
		}
	};

	private saveContRef = (element: HTMLDivElement) => {
		this.container = element;
	};

	private saveOverlayRef = (node: HTMLDivElement): void => {
		this.overlay = node;
		if (this.overlay) {
			if (this.props.visible) {
				this.overlay.style.opacity = '1';
				this.setVisible();
			} else {
				this.overlay.style.opacity = '0';
				this.setInvisible();
			}
		}
	};
}

export default MobileSidebar;
