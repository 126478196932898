import {ReactNode} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {TaskPriorityId} from '@tehzor/tools/interfaces/tasks/ITaskPriority';
import {LastEntityAddedFromCheckList} from '@src/interfaces/LastEntityAddedFromCheckList';
import {checkListsActions} from '@src/store/modules/settings/pages/checkLists/slice';

interface AddingCheckListTaskDialogProps {
	objectId?: string;
	floor?: string;
	planId?: string;
	location?: ILocation;
	status?: TaskStatusId;
	taskType?: TaskTypeId;
	taskPriority?: TaskPriorityId;
	spaceId?: string;
	workAcceptanceId?: string;
	checkListId?: string;
	checkItemId?: string;
}
export const useAddingCheckListTaskDialog = (
	props?: AddingCheckListTaskDialogProps
): [ReactNode, () => void] => {
	const {objectId, spaceId, listId, itemId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		spaceId?: string;
		listId: string;
		itemId?: string;
		workAcceptanceId?: string;
	}>();
	const dispatch = useAppDispatch();
	const {changeLastAddedEntity} = checkListsActions;
	const data = {
		objectId: props?.objectId || objectId,
		taskType: props?.taskType || TaskTypeId.ACCEPTANCE_CONTROL,
		planId: props?.planId,
		location: props?.location,
		floor: props?.floor,
		status: props?.status,
		links: {
			spaceId: props?.spaceId || spaceId,
			workAcceptanceId: props?.workAcceptanceId || workAcceptanceId,
			checkListId: props?.checkListId || listId,
			checkItemId: props?.checkItemId || itemId
		}
	};

	const [taskDialog, handleOpenTaskDialog] = useAddingTaskDialog({
		...data,
		onSuccess: () => {
			void dispatch(
				changeLastAddedEntity({lastAddedEntity: LastEntityAddedFromCheckList.TASK})
			);
		}
	});

	return [taskDialog, handleOpenTaskDialog];
};
