export enum ExpiredFilterValues {
	EXPIRED = 'expired',
	NOT_EXPIRED = 'not-expired',
	UNSET = 'unset'
}

export default interface IExpiredFilter {
	id: ExpiredFilterValues;
	name: string;
}

export const expiredOptions = [
	{
		id: ExpiredFilterValues.EXPIRED,
		name: 'Просрочено'
	},
	{
		id: ExpiredFilterValues.NOT_EXPIRED,
		name: 'Не просрочено'
	},
	{
		id: ExpiredFilterValues.UNSET,
		name: 'Без срока устранения'
	}
];