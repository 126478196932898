import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {getSpace} from '@src/store/modules/app/links/actions/getSpace';
import {LinkButton} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {onlineManager} from '@tanstack/react-query';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

export const SpaceEntityLink = ({spaceId, objectId}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const space = useAppSelector(s => s.app.links.spaceId?.[spaceId]);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const translatedSpaceTypes = useTranslatedDictionaryMap(dictionaryKeys.spaceTypes, spaceTypes);

	const handleClick = useCallback(() => {
		pushPath(formSpaceLink(objectId, spaceId));
	}, [objectId, spaceId]);

	useAsync(async () => {
		if (onlineManager.isOnline()) {
			await Promise.all([dispatch(getSpace(spaceId, objectId))]);
		}
	}, [spaceId, objectId]);

	if (!space || !translatedSpaceTypes?.[space.type]) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${translatedSpaceTypes[space.type]?.name} №${space.name}`}
			leftIcon={<i className="tz-external-link-16" />}
			onClick={handleClick}
			type="filled"
		/>
	);
};
