import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {memo} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useHandleDeleteFieldsSettingsSets} from '@/features/FieldsSettingsSets/hooks/useHandleDeleteFieldsSettingsSets';

interface ISelectionActionsProps {
	selectedEntities: IPreparedFieldSettingsSet[];
	onClear: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const FieldsRowActionButton = memo(({selectedEntities, onClear}: ISelectionActionsProps) => {
	const canDelete = selectedEntities.every(item => item.canDelete);
	const {deleteDialog, handleDelete} = useHandleDeleteFieldsSettingsSets({
		selectedEntities,
		onClear
	});
	if (!canDelete) return null;
	return (
		<>
			<IconButton onClick={handleDelete}>{deleteIcon}</IconButton>
			{deleteDialog}
		</>
	);
});
