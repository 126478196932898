import {Dispatch, useCallback} from 'react';
import * as React from 'react';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {
	useTaskPrioritiesAsArray,
	useTaskPrioritiesMap
} from '@src/core/hooks/queries/task-priorities/hooks';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';

interface ITaskPrioritySelectProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const priorityIcon = <i className="tz-star-priority-20" />;

export const TaskPrioritySelect = <S extends {taskPriority?: string}, E>({
	className,
	style,
	label,
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: ITaskPrioritySelectProps<S, E>) => {
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.taskPrioritySelect.label');
	const {data: priorities} = useTaskPrioritiesAsArray();
	const {data: prioritiesMap} = useTaskPrioritiesMap();

	const translatedTasksPrioritiesMap = useTranslatedDictionaryMap(
		dictionaryKeys.taskPriorities,
		prioritiesMap
	);
	const translatedTaskPrioritiesArray = useTranslatedDictionaryArray(
		dictionaryKeys.taskPriorities,
		priorities
	);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'taskPriority', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'taskPriority'});
			}
		},
		[editingDispatch, required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={value ? translatedTasksPrioritiesMap?.[value]?.name : ''}
						icon={priorityIcon}
						error={
							required && hasError
								? t('components.editableFields.taskPrioritySelect.error')
								: undefined
						}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{translatedTaskPrioritiesArray?.map(type => (
						<SelectOption
							key={type.id}
							itemKey={type.id}
							content={type.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
