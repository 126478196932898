import {useRef, memo, useCallback, useMemo, useState} from 'react';
import './EditableResponsibilityRulesDialog.less';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {Dialog, LinkButton, LoadingPanel} from '@tehzor/ui-components';
import {IEditableWorkingGroupState} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {useResponsibilityRulesPermissions} from '@src/core/hooks/permissions/useResponsibilityRulesPermissions';
import {EditableResponsibilityRule} from '@src/components/EditableResponsibilityRule/EditableResponsibilityRules';
import {useVirtualizer} from '@tanstack/react-virtual';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {generateId} from '../EditableCheckList/components';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface IUserTarget {
	userId: string;
	groupId?: undefined;
}

interface IGroupTarget {
	groupId: string;
	userId?: undefined;
}
interface IEditableResponsibilityDialogProps {
	target: IUserTarget | IGroupTarget;
	isOpen: boolean;
	rules: IResponsibilityRule[] | undefined;
	users?: IBriefUser[];
	groups?: IWorkingGroup[];
	editingState?: IEditableWorkingGroupState;
	onClose: () => void;
}

/**
 * Окно редактирования правил ответственности
 */

const plusIcon = <i className="tz-plus-20" />;

export const EditableResponsibilityRulesDialog = memo(
	({
		target,
		editingState,
		rules,
		groups,
		users,
		isOpen,
		onClose
	}: IEditableResponsibilityDialogProps) => {
		const {workingGroupId} = useStrictParams<{workingGroupId: string}>();
		const permissions = useResponsibilityRulesPermissions();
		const [hasDraft, setHasDraft] = useState(false);

		const filteredRules = useMemo(
			() =>
				rules
					?.filter(rule =>
						target?.userId
							? rule.target.userId === target.userId
							: rule.target.groupId === target.groupId
					)
					.map(rule => ({...rule, isDraft: false})),
			[rules, target.groupId, target.userId]
		);

		const [closingDialog, getClosingConfirmation] = useConfirmDialog(
			'Вы действительно хотите закрыть?',
			'Все несохраненные изменения будут потеряны',
			{acceptBtnProps: {type: 'accent-red'}}
		);

		const handleClose = useCallback(async () => {
			const isSaved = !hasDraft;

			if (isSaved || (!isSaved && (await getClosingConfirmation()))) {
				onClose();
			}
		}, [hasDraft, getClosingConfirmation, onClose]);

		const handleAddRule = useCallback(() => {
			setHasDraft(true);
		}, [setHasDraft]);

		const emptyRule = useMemo(
			() => ({
				id: generateId(),
				groupId: workingGroupId,
				objects: undefined,
				target,
				stage: undefined,
				scope: undefined,
				plans: undefined,
				autoSelect: undefined,
				categories: undefined,
				isDraft: true
			}),
			[workingGroupId, target]
		);

		const parentRef = useRef<HTMLDivElement>(null);

		const count = useMemo(() => {
			if (hasDraft) {
				return (filteredRules?.length ?? 0) + 1;
			}
			return filteredRules?.length ?? 0;
		}, [filteredRules?.length, hasDraft]);

		const virtualizer = useVirtualizer({
			count,
			getScrollElement: () => parentRef.current,
			estimateSize: () => 200
		});

		const items = virtualizer.getVirtualItems();

		const targetUserFullName = users?.find(user => user.id === target.userId)?.fullName;
		const targetGroupName = groups?.find(group => group.id === target.groupId)?.name;

		return (
			<LoadingPanel>
				<Dialog
					className="editable-rule-dialog"
					isOpen={isOpen}
					title="Редактирование набора правил"
					fullScreenOnTablet
					onRequestClose={handleClose}
				>
					{closingDialog}
					<div
						ref={parentRef}
						className="editable-rule-dialog__content"
						style={{
							height: virtualizer.getTotalSize() + 25
						}}
					>
						<div
							style={{
								height: virtualizer.getTotalSize(),
								width: '100%',
								position: 'relative'
							}}
						>
							<div
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									transform: `translateY(${items[0]?.start}px)`
								}}
							>
								{targetUserFullName}
								{targetGroupName}
								{items.map(virtualRow => {
									const rule = filteredRules?.[virtualRow.index];

									if (!rule) return null;

									return (
										<div
											className="editable-rule-dialog__content__rule"
											key={virtualRow.key}
											data-index={virtualRow.index}
											ref={virtualizer.measureElement}
										>
											<EditableResponsibilityRule
												key={rule.id}
												rule={rule}
												groupState={editingState}
												permissions={permissions}
												setHasDraft={setHasDraft}
											/>
										</div>
									);
								})}

								{hasDraft && (
									<div className="editable-rule-dialog__content__rule">
										<EditableResponsibilityRule
											rule={emptyRule}
											groupState={editingState}
											permissions={permissions}
											setHasDraft={setHasDraft}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
					{!hasDraft && permissions.canAdd && (
						<div>
							<LinkButton
								label="Добавить правило"
								onClick={handleAddRule}
								leftIcon={plusIcon}
							/>
						</div>
					)}
				</Dialog>
			</LoadingPanel>
		);
	}
);
