import {IPreparedPerformer} from '../interfaces/IPreparedPerformer';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';

/**
 * Преобразовывает исполнителей в необходимый для вывода формат
 *
 * @param data массив id исполнителей
 * @param users массив пользователей
 * @param rules массив правил
 */
export const convertPerformers = (
	data?: string[],
	users?: IBriefUser[],
	rules?: IResponsibilityRule[]
): IPreparedPerformer[] => {
	if (data && users) {
		return data.map((id, index) => ({
			id,
			index,
			name: users.find(user => user.id === id)?.fullName || '',
			count: rules?.filter(rule => rule.target.userId === id).length || 0
		}));
	}
	return [];
};
