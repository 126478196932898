import {useMemo} from 'react';
import {useManageCheckListsBreadcrumbs} from './useManageCheckListsBreadcrumbs';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';

export const useManageCheckListBreadcrumbs = (checkListId: string): IBreadcrumb[] => {
	const breadcrumbs = useManageCheckListsBreadcrumbs();
	const {data: checkList} = useExtractCheckListById(checkListId);

	return useMemo(() => {
		if (checkList) {
			return breadcrumbs.concat({
				label: checkList.name,
				url: `/manage/check-lists/${checkList.id}`
			});
		}
		return breadcrumbs;
	}, [checkList, breadcrumbs]);
};
