import * as types from '../constants';
import {StatisticsGrouping} from '@src/interfaces/StatisticsGrouping';

/**
 * Изменяет индекс активной вкладки
 *
 * @param value индекс активной вкладки
 */
export const changeTabIndex = (value: number) => ({
	type: types.CHANGE_TAB_INDEX,
	payload: value
});

/**
 * Изменяет индекс активной вкладки в окне фильтров
 *
 * @param value индекс активной вкладки
 */
export const changeFiltersDialogTabIndex = (value: number) => ({
	type: types.CHANGE_FILTERS_DIALOG_TAB_INDEX,
	payload: value
});

/**
 * Изменяет группировку статистики
 *
 * @param value группировка
 */
export const changeGrouping = (value: StatisticsGrouping) => ({
	type: types.CHANGE_GROUPING,
	payload: value
});

export * from './filters';
