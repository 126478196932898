import useAppSelector from '@src/core/hooks/useAppSelector';
import {useExtractTreeCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {
	extractInspectionsAndSubInspectionsByItemIdAsArray,
	extractInspectionsByCheckListIdAsArray
} from '@src/store/modules/pages/checkLists/selectors/inspections';

/**
 * Вычисляет количество осмотров для чек-листа или категории
 *
 * @param listId id чек-листа
 * @param itemId id категории
 */
export function useInspectionsCount(listId: string, itemId?: string): number {
	const {data: treeCheckItem} = useExtractTreeCheckItemById(listId, itemId);

	const inspections = useAppSelector(state =>
		itemId
			? extractInspectionsAndSubInspectionsByItemIdAsArray(state, treeCheckItem)
			: extractInspectionsByCheckListIdAsArray(state, listId)
	);

	return inspections.length;
}
