import {Column} from 'react-table';
import {IPreparedSpaceTypeDecoration} from '../../interfaces/IPreparedSpaceTypeDecoration';
import {SpaceTypeDecorationMobileCell} from '@src/components/tableCells/SpaceTypeDecorationMobileCell/SpaceTypeDecorationMobileCell';

export const mobileColumns: Array<Column<IPreparedSpaceTypeDecoration>> = [
	{
		id: 'info',
		Cell: SpaceTypeDecorationMobileCell,
		className: 'spaces-page__mobile-cell'
	}
];
