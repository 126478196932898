import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetProblemTagsResponse = INormalizedData<IProblemTag>;

/**
 * Возвращает список меток нарушений
 */
export const requestProblemTags = async () => {
	const response = await httpRequests.withToken.get<IGetProblemTagsResponse>('problem-tags');
	return response.data;
};
