import * as React from 'react';
import {useCallback, useState} from 'react';
import {
	convertProblemTagsSetToSave,
	errorsFns,
	isEdited
} from '@src/core/hooks/states/useEditableProblemTagsSetState/state';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {EditableProblemTagsSet} from '../EditableProblemTagsSet';
import {EditableTags} from '../components';
import {Button} from '@tehzor/ui-components';
import {addSuccessToast} from '@src/utils/toasts';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useUpdateEffect} from 'react-use';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';
import {useProblemTagsSetPermissions} from '@src/core/hooks/permissions/useProblemTagsSetPermissions';
import {useEditableProblemTagsSetState} from '@src/core/hooks/states/useEditableProblemTagsSetState';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useEditProblemTagsSet} from '@src/core/hooks/mutations/problemTagsSets/useEditProblemTagsSet';
import {useAddProblemTagsSet} from '@src/core/hooks/mutations/problemTagsSets/useAddProblemTagsSet';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	companyId: {fieldId: 'companyId', isRequired: true},
	objects: {fieldId: 'objects', isRequired: true},
	stages: {fieldId: 'stages', isRequired: true}
};

interface IHookArgs {
	problemTagsSet?: IProblemTagsSet;
	saving?: boolean;
}

export const useEditableProblemTagsSet = ({
	problemTagsSet,
	saving
}: IHookArgs): [
	React.ReactNode,
	React.ReactNode,
	() =>
		| {
				problemTagsSet?: ISavingProblemTagsSet;
		  }
		| undefined,
	() => void,
	boolean
] => {
	const {pushPath} = useChangePath();
	const [editingState, editingDispatch] = useEditableProblemTagsSetState({
		problemTagsSet
	});

	const perms = useProblemTagsSetPermissions();
	const [isBlocking, setIsBlocking] = useState(false);
	const dispatch = useAppDispatch();
	const {mutate: editProblemTagsSet} = useEditProblemTagsSet();
	const {mutateAsync: addProblemTagsSet} = useAddProblemTagsSet();

	const getSavingData = useCallback(() => {
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}

		if (!isEdited(editingState, problemTagsSet)) {
			return undefined;
		}
		const savingProblemTagsSet = convertProblemTagsSetToSave(
			editingState,
			problemTagsSet,
			true
		);

		return {
			problemTagsSet: savingProblemTagsSet
		};
	}, [editingState, problemTagsSet, errorsFns, fieldsSettings, isEdited, editingDispatch]);

	useUpdateEffect(() => {
		editingDispatch({
			type: 'update',
			field: 'objects',
			value: undefined
		});
	}, [editingState.companyId]);

	const handleSave = useCallback(async () => {
		setIsBlocking(true);
		const savingData = getSavingData();
		try {
			if (savingData && savingData.problemTagsSet) {
				if (problemTagsSet?.id) {
					editProblemTagsSet({
						setId: problemTagsSet.id,
						fields: savingData.problemTagsSet
					});
				} else {
					const result = await addProblemTagsSet(savingData.problemTagsSet);
					pushPath(`/manage/problem-tags-sets/${result.id}`);
				}

				addSuccessToast(
					'Успешно',
					problemTagsSet?.id ? 'Набор меток был обновлен' : 'Набор меток создан'
				);
			}
		} finally {
			setIsBlocking(false);
		}
	}, [getSavingData, problemTagsSet?.id, setIsBlocking, dispatch]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: {
				problemTagsSet
			}
		});
	}, [problemTagsSet, editingDispatch]);
	const setFields = (
		<>
			<EditableProblemTagsSet
				// problemTagsSetId={problemTagsSet?.id}
				editingState={editingState}
				editingDispatch={editingDispatch}
				fieldsSettings={fieldsSettings}
				disabled={
					saving ||
					!perms.canEdit ||
					(problemTagsSet && !problemTagsSet.allObjectsAvailable)
				}
				problemTagsSet={problemTagsSet}
			/>
			{perms.canEdit && (!problemTagsSet || problemTagsSet.allObjectsAvailable) && (
				<div className="editable-problem-tags-set__buttons">
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={
							isBlocking ||
							(problemTagsSet
								? !isEdited(editingState, problemTagsSet) ||
								  hasErrors(editingState, errorsFns, fieldsSettings)
								: hasErrors(editingState, errorsFns, fieldsSettings))
						}
						onClick={handleSave}
					/>

					<Button
						type="cancel"
						label="Отменить "
						onClick={reset}
						disabled={!isEdited(editingState, problemTagsSet)}
					/>
				</div>
			)}
		</>
	);

	const tagFields = (
		<EditableTags
			forbiddenAdding={
				!problemTagsSet ||
				(problemTagsSet && !problemTagsSet.allObjectsAvailable) ||
				!perms.canEdit
			}
			problemTagsSetId={problemTagsSet?.id}
		/>
	);

	return [setFields, tagFields, getSavingData, reset, isBlocking];
};
