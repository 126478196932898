import IShape from '@tehzor/tools/interfaces/plans/IShape';
import classNames from 'classnames';

interface ISectorProps {
	shape: IShape;
	sectorIds: (string | undefined)[];
	commentedSectors: (string | undefined)[];
	entitySectorIds?: (string | undefined)[];
	handleSectorMouseDown: (
		event: React.MouseEvent<SVGElement, MouseEvent>,
		sectorName: string,
		sectorId: string
	) => void;
}

export const Sector = ({
	shape,
	sectorIds,
	commentedSectors,
	entitySectorIds,
	handleSectorMouseDown
}: ISectorProps) => {
	const layerClassNames = ['plan-viewer__sector', 'plan-viewer__sector_hoverable'];
	if (sectorIds.includes(shape.id)) {
		layerClassNames.push('plan-viewer__sector_selected');
	}
	if (commentedSectors.includes(shape.id)) {
		layerClassNames.push('plan-viewer__sector_commented');
	}
	if (entitySectorIds?.includes(shape.id)) {
		layerClassNames.push('plan-viewer__sector_entity');
	}
	return (
		<g
			key={shape.id}
			dangerouslySetInnerHTML={{__html: shape.svg}}
			className={classNames(layerClassNames)}
			id={shape.id}
			name={shape.name}
			onMouseDown={event => handleSectorMouseDown(event, shape.name, shape.id)}
		/>
	);
};
