import {memo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {FilterButton, SelectOption, Select2} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useOwnerAcceptanceStatusesAsArray} from '@src/core/hooks/queries/ownerAcceptanceStatuses/hooks';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

interface IStatusesFilterProps {
	statuses?: string[];
}

export const StatusesFilter = memo(({statuses}: IStatusesFilterProps) => {
	const [selectedStatuses, setSelectedStatuses] = useState(statuses);
	const {data: allStatuses} = useOwnerAcceptanceStatusesAsArray();
	const {t} = useTranslation();
	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.ownerAcceptanceStatuses,
		allStatuses
	);
	const {dispatch} = useEntitiesFiltersCtx();

	const handleApply = () => {
		dispatch({statuses: selectedStatuses});
	};

	const handleClear = () => {
		setSelectedStatuses([]);
	};

	const handleFullClear = () => {
		setSelectedStatuses([]);
		dispatch({statuses: undefined});
	};

	const handleCancel = () => {
		setSelectedStatuses(statuses);
	};

	useUpdateEffect(() => {
		setSelectedStatuses(statuses);
	}, [statuses]);

	if (!translatedOptions) return null;

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedStatuses?.length}
			count={selectedStatuses?.length}
			footer
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel(
						t('entitiesFilters.statusesFilter.ownerAcceptanceStatuses.label'),
						statuses,
						translatedOptions
					)}
					active={!!statuses?.length}
					onClear={handleFullClear}
				/>
			}
		>
			<Select2
				multiple
				value={selectedStatuses}
				onChange={setSelectedStatuses}
			>
				{translatedOptions.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</TranslatedSelectPopup>
	);
});
