/* eslint-disable @typescript-eslint/ban-types */
import { createElement, ComponentType } from 'react';
import {CellProps} from 'react-table';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IState} from '@src/store/modules';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';
import {useExtractProblemTagsById} from '@src/core/hooks/queries/problemTags/hooks';

/**
 * Конструктор для ячеек с информацией о нарушении. Добавляет replies в props компонента.
 *
 * @param component компонент ячейки
 * @param selector селектор для извлечения ответов
 * @param replies нужно там где нет редакса
 * @constructor
 */
export const ProblemInfoCellWrap =
	<D extends object>(
		component: ComponentType<IProblemCellProps<D>>,
		selector?: (s: IState) => Record<string, IComment>,
		replies?: Record<string, IComment>
	) =>
	(props: CellProps<D>) => {
		const finalSelector = selector || (() => undefined);
		const storeReplies = useAppSelector(finalSelector);
		const {data: allProblemTags} = useExtractProblemTagsById();

		const finalReplies = replies || storeReplies;
		return createElement(component, {...props, replies: finalReplies, allProblemTags});
	};
