/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {IScheduleData} from '../../../../utils/convertTasksToScheduleFormat';
import {createHeightForMonthCell, getMonthIndex, isCurrentMonth} from '../../../../utils/timeMonthUtils';
import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import {CellContext} from '@tanstack/react-table';
import classNames from 'classnames';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractTasksScheduleSettings} from '@src/store/modules/settings/pages/tasks/selectors';
import {useMonthTasks} from '@src/pages/TasksPage/hooks/useMonthTasks';

const plusIcon = <i className="tz-plus-20"/>;

export const MonthCell = ({
	column,
	row,
	getValue
}: CellContext<IScheduleData, IScheduleData>) => {
	const {settings} = useAppSelector(extractTasksScheduleSettings);
	const permissions = useTasksPermissions();
	const currentYear = settings.year;
	const monthId = column?.id;
	const monthIndex = getMonthIndex(monthId);
	const value = getValue();
	const groupedTasks = value.tasks?.tasksForMonthCell[monthIndex];

	const startTime = new Date(currentYear, monthIndex, 1, 9).getTime();
	const endTime = new Date(currentYear, monthIndex + 1, 0, 18).getTime();

	const [taskDialog, handleOpenTaskDialog] = useAddingTaskDialog({
		objectId: value?.objectId,
		startTime: startTime.toString(),
		endTime: endTime.toString(),
		activeGroup: value?.activeGroupId,
		respUsers: value?.respUsers
	});

	const isCurrentMonthFlag = isCurrentMonth(monthIndex, currentYear);

	const {tasks} = useMonthTasks({
		groupedTasks,
		hiddenNumber: 8,
		objectId: value?.objectId,
		startTime,
		endTime
	});

	const maxTasksCountInRow = value.tasks?.maxTasksCountInRow;
	const height = createHeightForMonthCell(maxTasksCountInRow);

	return (
		<div
			className={classNames('manage-task-schedule-page__month-cell-wrap',
				{'manage-task-schedule-page__month-cell-wrap_active': isCurrentMonthFlag})}
			style={{height}}
		>
			{tasks && (
				<div
					className="manage-task-schedule-page__month-cell"
					role="cell"
				>
					<div className="manage-task-schedule-page__month-cell-tasks">
						{tasks}
					</div>
				</div>
			)}
			{permissions.canAdd && (
				<div
					className="manage-task-schedule-page__month-cell-add"
					onClick={handleOpenTaskDialog}
					role="cell"
				>
					{plusIcon}
					{taskDialog}
				</div>
			)}
		</div>
	);
};