import IUploadingFile from '../interfaces/IUploadingFile';
import {TempFileDestination} from '../enums/TempFileDestination';
import {ComponentType, memo, useCallback, useRef} from 'react';
import ITempFile from '../interfaces/ITempFile';
import {useQueueHandler} from './hooks/useQueueHandler';
import {useFindFile} from './hooks/useFindFile';
import {useUpdateFile} from './hooks/useUpdateFile';
import {useAbortFile} from './hooks/useAbortFile';
import {useDeleteFile} from './hooks/useDeleteFile';
import {useConnectionHandler} from './hooks/useConnectionHandler';
import {useFileHandler} from './hooks/useFileHandler';

export type AddTempFileFn = (
	file: File,
	destination: TempFileDestination,
	onInitialized?: (abortRequest: () => void) => void,
	onProgress?: (progress: number) => void,
	original?: File,
	drawData?: string
) => Promise<ITempFile>;

export type DeleteTempFileFn = (id: string) => Promise<void>;

export interface IFilesUploaderProps {
	online?: boolean;
	uploadingFiles: IUploadingFile[];

	onUploadingFilesChange: (value: IUploadingFile[]) => void;
	onAddTempFile: AddTempFileFn;
	onDeleteTempFile: DeleteTempFileFn;
}

/**
 * HOC. Нужен для того, чтобы на любой компонент навешивать обработчики загрузки файла
 * Принимается в себя компонент, возвращает компонент обогащенный props
 */
export const withFileUploader = <P extends IFilesUploaderProps>(Component: ComponentType<P>) => {
	const ComponentWithFileUploaderProps = (props: P) => {
		const {uploadingFiles, onUploadingFilesChange, online, onAddTempFile, onDeleteTempFile} =
			props;
		const processingKeysRef = useRef<string[]>([]);
		const uploadsRef = useRef<IUploadingFile[]>(uploadingFiles);

		const findFile = useFindFile(uploadingFiles);

		const updateFile = useUpdateFile(uploadsRef, uploadingFiles, onUploadingFilesChange);

		const abortFilesUploading = useAbortFile(uploadingFiles);

		const queueHandler = useQueueHandler(
			updateFile,
			findFile,
			onAddTempFile,
			abortFilesUploading,
			processingKeysRef
		);

		const reloadFile = useCallback((key: string) => {
			void queueHandler.current.push(key);
		}, []);

		const deleteFile = useDeleteFile(
			uploadsRef,
			findFile,
			onDeleteTempFile,
			onUploadingFilesChange,
			queueHandler,
			processingKeysRef
		);

		useConnectionHandler(
			online,
			uploadingFiles,
			abortFilesUploading,
			onUploadingFilesChange,
			queueHandler,
			processingKeysRef
		);

		useFileHandler(uploadingFiles, queueHandler, processingKeysRef, updateFile);

		return <Component {...props} reloadFile={reloadFile} deleteFile={deleteFile} />;
	};
	return memo(ComponentWithFileUploaderProps);
};
