import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableProblemAction, IEditableProblemState, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';

export const useEditableProblemState = (defaultData?: ISavingProblem) =>
	useReducer<Reducer<IEditableProblemState, IEditableProblemAction>, ISavingProblem | undefined>(
		createReducer(init, errorsFns),
		defaultData,
		init
	);
