import { useCallback } from 'react';
import * as React from 'react';
import CheckboxGroupContext from './CheckboxGroupContext';

interface ICheckboxGroupProps {
	children?: React.ReactNode;
	name?: string;
	value: string[];

	onChange?: (value: string[]) => void;
}

const CheckboxGroup = ({children, name, value, onChange}: ICheckboxGroupProps) => {
	const isChecked = useCallback((itemValue: string) => value.includes(itemValue), [value]);

	const handleChange = useCallback((itemValue: string) => {
		if (onChange) {
			onChange(value.includes(itemValue) ? value.filter(v => v !== itemValue) : value.concat([itemValue]));
		}
	}, [value, onChange]);

	return (
		<CheckboxGroupContext.Provider value={{name, value, isChecked, onChange: handleChange}}>
			{children}
		</CheckboxGroupContext.Provider>
	);
};

export default CheckboxGroup;
