import {
	IGetDelegationHistoriesByProblemResponse,
	requestDelegationHistoriesByProblem
} from '@src/api/backend/delegationHistories';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';
import {addErrorToast} from '@src/utils/toasts';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import IError from '@tehzor/tools/interfaces/IError';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import * as types from '../../constants';

export type IGetPerformersDelegationHistoriesByProblemPayload = IGetDelegationHistoriesByProblemResponse;

const request = () => ({type: types.GET_PERFORMERS_DELEGATION_HISTORIES_REQUEST});

const success = (response: IGetDelegationHistoriesByProblemResponse) => ({
	type: types.GET_PERFORMERS_DELEGATION_HISTORIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке истории делегирования исполнителей');
	return {
		type: types.GET_PERFORMERS_DELEGATION_HISTORIES_FAILURE,
		payload: error
	};
};

/**
 * Получает записи из истории делегирования нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const getPerformersDelegationHistoriesByProblem = (
	objectId: string,
	problemId: string
) =>
	checkLoaded<IState, IGetDelegationHistoriesByProblemResponse, ApiAction>(
		s => s.pages.problem.performersDelegationHistories,
		createApiAction<IGetDelegationHistoriesByProblemResponse>(request, success, failure, () =>
			requestDelegationHistoriesByProblem(objectId, problemId, WorkingGroupTypeId.PERFORMERS)
		)
	);
