import {IResponsibilityRule} from '../../interfaces/responsibilityRules/IResponsibilityRule';
import {IRuleParams} from './IRuleParams';

const isEmptyRule = (array: unknown[] | undefined) => !array?.length;

const checkSingleValue = (
	rule: IResponsibilityRule,
	key: 'categories' | 'plans',
	value?: string
) => {
	if (isEmptyRule(rule[key])) {
		return true;
	}
	if (!value) {
		return false;
	}
	return rule[key]?.includes(value);
};

export const isMatch = (
	rule: IResponsibilityRule,
	{planId, categoryId, structureIds, spaceIds}: IRuleParams
) => {
	const isEmptyCategories = isEmptyRule(rule.categories);
	const isEmptyPlans = isEmptyRule(rule.plans);
	const isEmptyStructureIds = isEmptyRule(rule.structureIds);
	const isEmptySpaceIds = isEmptyRule(rule.spaceIds);

	if (isEmptyPlans && isEmptyCategories && isEmptyStructureIds && isEmptySpaceIds) {
		return true;
	}

	const hasCategories = checkSingleValue(rule, 'categories', categoryId);
	const hasPlans = checkSingleValue(rule, 'plans', planId);
	const hasStructureIds = !isEmptyStructureIds
		? structureIds?.every(id => rule.structureIds?.includes(id))
		: true;
	const hasSpaceIds = !isEmptySpaceIds
		? spaceIds?.every(id => rule.spaceIds?.includes(id))
		: true;

	if (hasCategories && hasPlans && hasStructureIds && hasSpaceIds) {
		return true;
	}
	return false;
};
