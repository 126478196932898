import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IWorkAcceptancesTasksDataQueryKey, workAcceptancesTasksDataQueryKeys} from '../keys';
import {requestWorkAcceptancesTasksData} from '@src/api/backend/workAcceptances/tasksData';

export const useWorkAcceptancesTasksDataQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(workAcceptancesTasksDataQueryKeys.all(), {
		queryFn: ({queryKey}: QueryFunctionContext<IWorkAcceptancesTasksDataQueryKey>) => {
			const [, params] = queryKey;
			return requestWorkAcceptancesTasksData(params);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});
};
