import {
	requestComments,
	IGetCommentsResponse
} from '@src/api/backend/comments';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {ICommentTypeId, ICommentVariants} from '@tehzor/tools/interfaces/comments/ICommentType';

export type IGetProblemCommentsPayload = IGetCommentsResponse;

const request = () => ({type: types.GET_COMMENTS_REQUEST});

const success = (response: IGetCommentsResponse) => ({
	type: types.GET_COMMENTS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка комментариев и ответов');
	return {
		type: types.GET_COMMENTS_FAILURE,
		payload: error
	};
};

/**
 * Получает список комментариев и ответов для конкретного нарушения
 *
 * @param objectId id объекта
 * @param commentTypeId тип комментария
 * @param problemId id нарушения
 */
export const getCommentsForProblem = (
	objectId: string,
	commentTypeId: ICommentTypeId,
	problemId: string
): AppThunkAction<Promise<IGetCommentsResponse>> => (dispatch, getState) =>
		dispatch(
			createApiAction<IGetCommentsResponse>(request, success, failure, () => {
				const s = getState();
				const {offset, limit} = s.pages.problem.comments;
				const {visibility} = s.settings.pages.problem.comments;
				const official
					= visibility.length === 1
						? visibility[0] === ICommentVariants.REPLIES
						: undefined;
				return requestComments(
					{
						commentTypeId,
						objects: [objectId],
						problems: [problemId],
						official
					},
					{createdAt: false},
					offset,
					limit
				);
			})
		);
