import {PrivateRoute} from '@src/containers/PrivateRoute';
import {DocumentPage} from '@src/pages/DocumentPage';
import {DocumentsPageWrap} from '@src/pages/DocumentsPage/wraps/DocumentsPageWrap';
import {DocumentsFiltersRoutingPage} from '@src/pages/filters/DocumentsFiltersRoutingPage';
import {documentsFiltersRoutes} from './documentsFiltersRoutes';

export const documentsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<DocumentsPageWrap/>}/>
	},
	{
		path: ':documentId',
		element: <PrivateRoute element={<DocumentPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<DocumentsFiltersRoutingPage/>}/>,
		children: documentsFiltersRoutes
	}
];