import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import { memo } from 'react';
import {CellProps} from 'react-table';
import {NameCell} from './NameCell';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {TableRowSelect} from '@tehzor/ui-components';
import {CompanyCell} from './CompanyCell';
import {ContractorCell} from './ContractorCell';
import {ObjectsCell} from './ObjectsCell';
import {SigningDateCell} from './SigningDateCell';
import {CreatedCell} from './CreatedCell';

export const MobileCell = memo((props: CellProps<IPreparedContract>) => {
	const {row} = props;
	const {signingDate, objectIds} = row.original;

	return (
		<div className="contracts__cell-mobile">
			<ClickPreventWrap className="contracts__cell-mobile-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()} />
			</ClickPreventWrap>
			<div className="contracts__cell-mobile-container">
				<NameCell {...props} />
				{signingDate && (
					<SigningDateCell
						isMobile
						{...props}
					/>
				)}
				<CompanyCell
					isMobile
					{...props}
				/>
				<ContractorCell
					isMobile
					{...props}
				/>

				<CreatedCell
					isMobile
					{...props}
				/>

				{objectIds && (
					<ObjectsCell
						isMobile
						{...props}
					/>
				)}
			</div>
		</div>
	);
});
