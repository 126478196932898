import {queryClient} from '@src/api/QueryClient';
import {deleteMutation} from '@src/cache/utils/deleteMutation';
import {deleteLinkedLocalProblems} from '../problem/deleteLinkedLocalProblems';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {store} from '@src/store/appStore';
import * as acceptanceTypes from '@src/store/modules/entities/ownerAcceptance/constants';

export const deleteLocalOwnerAcceptance = async (acpId: string, objectId?: string) => {
	queryClient.removeQueries({queryKey: ownerAcceptancesQueryKeys.savingData(acpId)});
	await queryClient.refetchQueries({
		queryKey: ownerAcceptancesQueryKeys.localList()
	});
	if (objectId) {
		store.dispatch({type: acceptanceTypes.DELETE_SUCCESS, payload: {objectId, acpId}});
	}
	await deleteLinkedLocalProblems('ownerAcceptanceId', acpId);
	deleteMutation(acpId);
};
