import {CellProps} from 'react-table';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {Tag} from '@tehzor/ui-components';

const offlineIcon = <i className="tz-offline-mode-20 problems-page__offline_icon"/>;

const CreatedCell = ({row}: CellProps<IProblemEntity>) => (
	<div className="problems-page__d-table-created">
		{row.original.data.localCreatedAt && (
			<Tag
				className="problems-page__offline_tag"
				label={row.original.data.localCreatedAt}
				icon={offlineIcon}
				multiline
			/>
		)}
		<div className="problems-page__d-table-created-date">{row.original.data.createdAt}</div>
		<div className="problems-page__d-table-created-name">{row.original.data.createdBy}</div>
	</div>
);

export default CreatedCell;
