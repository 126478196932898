import {OfflineDataItem} from '@tehzor/ui-components';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {useCacheStates} from '../hooks/useCacheStates';
import {useTranslation} from "react-i18next";

export const CheckListsCacheStatus = () => {
	const {t} = useTranslation();
	const [isListsFetching, isListsError, isListsStale, isListsPaused, listsDataUpdatedAt] =
		useCacheStates(checkListsQueryKeys.list());
	const [isItemsFetching, isItemsError, isItemsStale, isItemsPaused, itemsDataUpdatedAt] =
		useCacheStates(checkItemsQueryKeys.list());
	const [isTypesFetching, isTypesError, isTypesStale, isTypesPaused, typesDataUpdatedAt] =
		useCacheStates(checkListsQueryKeys.types());
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t('components.offlineModeDialog.checkListsCacheStatus.offlineDataItem.title')}
			icon={<i className="tz-internal-acceptance-24" />}
			date={Math.max(
				listsDataUpdatedAt ?? 0,
				itemsDataUpdatedAt ?? 0,
				typesDataUpdatedAt ?? 0
			)}
			error={
				isListsError || isItemsError || isTypesError
					? t('cacheStatusErrors.error')
					: isListsStale || isItemsStale || isTypesStale
					? t('cacheStatusErrors.stale')
					: undefined
			}
			inProgress={
				isListsPaused ||
				isListsFetching ||
				isItemsPaused ||
				isItemsFetching ||
				isTypesFetching ||
				isTypesPaused
			}
			progressTitle={t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default')}
		/>
	);
};
