import {useCallback, useRef, useState} from 'react';
import './AddingSpaceOwnerDialog.less';
import {ActionButtons, Button, IconButton, InlineButton, ModalDialog} from '@tehzor/ui-components';
import OwnersBinding, {IOwnersBindingRefProps} from './components/OwnersBinding';
import AddingSpaceOwner, {
	IAddingSpaceOwnerRefProps
} from '@src/components/AddingSpaceOwner/AddingSpaceOwner';
import {useTranslation} from 'react-i18next';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';

interface IAddingSpaceOwnerDialogProps {
	companyId?: string;
	spaceId?: string;
	isOpen: boolean;

	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

/**
 * Окно добавления собственника помещения
 */
const AddingSpaceOwnerDialog = (props: IAddingSpaceOwnerDialogProps) => {
	const {companyId, spaceId, isOpen, onClose, onSuccess} = props;

	const viewRef = useRef<IAddingSpaceOwnerRefProps | IOwnersBindingRefProps>(null);
	const online = useOnlineManager();
	const [view, setView] = useState<'adding' | 'binding'>('adding');
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);
	const {t} = useTranslation();

	const handleSave = useCallback(() => {
		if (viewRef.current) {
			viewRef.current.save();
		} else {
			onClose();
		}
		success.current = true;
	}, [onClose]);

	const handleCancel = useCallback(() => {
		if (viewRef.current) {
			void viewRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const handleAfterClose = useCallback(() => {
		setView('adding');
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onSuccess]);

	return (
		<ModalDialog
			className={{
				root: 'adding-space-owner-dialog',
				title: 'adding-space-owner-dialog__title',
				body: 'adding-space-owner-dialog__body'
			}}
			open={isOpen}
			title={
				view === 'adding' ? (
					t('spacePage.info.spaceOwnersDialog.newClient')
				) : (
					<>
						<IconButton
							className="adding-space-owner-dialog__back-btn"
							onClick={() => setView('adding')}
						>
							<i className="tz-long-arrow-24" />
						</IconButton>
						{t('spacePage.info.spaceOwnersDialog.selectClient')}
					</>
				)
			}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('spacePage.info.spaceOwnersDialog.cancelButton')}
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label={t('spacePage.info.spaceOwnersDialog.saveButton')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			{view === 'adding' || !online ? (
				<>
					<AddingSpaceOwner
						companyId={companyId}
						spaceId={spaceId}
						saving={saving}
						onClose={onClose}
						setSaving={setSaving}
						ref={viewRef}
					/>
					{online && (
						<div className="adding-space-owner-dialog__bind-btn-wrap">
							<InlineButton
								label={t('spacePage.info.spaceOwnersDialog.selectFromTheList')}
								type="accent"
								rightIcon={<i className="tz-arrow-24" />}
								onClick={() => setView('binding')}
							/>
						</div>
					)}
				</>
			) : (
				companyId !== undefined &&
				spaceId !== undefined && (
					<OwnersBinding
						companyId={companyId}
						spaceId={spaceId}
						onClose={onClose}
						setSaving={setSaving}
						ref={viewRef}
					/>
				)
			)}
		</ModalDialog>
	);
};

export default AddingSpaceOwnerDialog;
