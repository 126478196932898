import { useCallback, useMemo, useState } from 'react';
import {ActionButtons, Button, Dialog, EditableFieldLabel, TextField} from '@tehzor/ui-components';
import DependencySelect from '@src/components/EditableCheckList/components/DependencySelect';
import arrayToTree, {Tree} from 'array-to-tree';
import {topologicalSort} from '@tehzor/tools/utils/topologicalSort';
import {addErrorToast} from '@src/utils/toasts';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';
import {
	ISavingCheckItem,
	ISavingCheckItemDraftType
} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {isEqual} from 'lodash';

interface IEditableCheckItemDialogProps {
	isOpen: boolean;
	items: ISavingCheckItem[];
	itemsLeaf: Tree<ISavingCheckItem>;
	onChange: (index: number, itemData: ISavingCheckItem) => void;
	close(): void;
}

const checkIsCircular = (
	allItems: ISavingCheckItem[],
	currentItem: Tree<ISavingCheckItem>,
	value: string[]
) => {
	let parentItems: Array<Tree<ISavingCheckItem>>;

	const tree = arrayToTree(allItems || [], {
		parentProperty: 'parentId',
		customID: 'id'
	});

	if (currentItem.parentId) {
		const found = findTreeNode(tree, currentItem.parentId);
		parentItems = found ? found.children! : [];
	} else {
		parentItems = tree;
	}

	return topologicalSort([
		...parentItems.filter(item => item.id !== currentItem.id),
		{...currentItem, dependencies: value}
	]);
};

export const EditableCheckItemDialog = ({
	items,
	itemsLeaf,
	isOpen,
	onChange,
	close
}: IEditableCheckItemDialogProps) => {
	const currentIndex = useMemo(
		() => items?.map(item => item.id).indexOf(itemsLeaf.id),
		[items, itemsLeaf.id]
	);

	const availableDependencies = useMemo(
		() =>
			items?.filter(
				item =>
					item.name !== '' &&
					item.id !== itemsLeaf.id &&
					item.parentId === itemsLeaf.parentId
			) || [],
		[items, itemsLeaf.id, itemsLeaf.parentId]
	);

	const [text, setText] = useState(itemsLeaf.name);
	const [description, setDescription] = useState(itemsLeaf.description);
	const [deps, setDeps] = useState<string[]>(itemsLeaf.dependencies!);
	const siblingsNames = items
		.filter(el => el.name && el.id !== itemsLeaf.id && el.parentId === itemsLeaf.parentId)
		.map(i => i.name);
	const nameIsForbidden = siblingsNames.includes(text);
	const handleClose = useCallback(() => {
		setText(itemsLeaf.name);
		setDescription(itemsLeaf.description);
		setDeps(itemsLeaf.dependencies!);
		close();
	}, [close, itemsLeaf.dependencies, itemsLeaf.description, itemsLeaf.name]);

	const isEdited =
		itemsLeaf.name !== text ||
		itemsLeaf.description !== description ||
		!isEqual(itemsLeaf.dependencies, deps);

	const handleSelectChange = useCallback(
		(value: string[]) => {
			const {edges} = checkIsCircular(items || [], itemsLeaf, value);

			if (edges.length) {
				const dependencies = availableDependencies.filter(dep =>
					edges.find(([, depId]) => depId === dep.id)
				);

				addErrorToast(
					'Ошибка!',
					`Обнаружена циклическая зависимость категорий. Категория "${
						itemsLeaf.name
					}" не может быть зависима от ${dependencies
						.map(({name}) => `"${name}"`)
						.join(', ')}`
				);
			} else {
				setDeps(value);
			}
		},
		[availableDependencies, items, itemsLeaf]
	);

	const handleSave = useCallback(() => {
		if (currentIndex !== undefined) {
			const {children, ...data} = itemsLeaf;
			onChange(currentIndex, {
				...data,
				name: text,
				description,
				dependencies: deps,
				isDraft: true,
				draftType: data.draftType || ISavingCheckItemDraftType.EDITING_DRAFT
			});
		}

		close();
	}, [currentIndex, close, itemsLeaf, onChange, text, description, deps]);

	return (
		<Dialog
			isOpen={isOpen}
			className="editable-check-items__item-editing-dialog"
			title="Редактирование категории"
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleSave}
						disabled={!isEdited || nameIsForbidden || !text}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
		>
			<div>
				<EditableFieldLabel>Название</EditableFieldLabel>

				<TextField
					value={text}
					className="editable-check-items__item-editing-dialog-input"
					elementType="textarea"
					elementProps={{autoFocus: true, minRows: 2}}
					onChange={setText}
					error={nameIsForbidden? 'Название совпадает с названием одной из соседних категорий' : undefined}
				/>
				<EditableFieldLabel>Описание</EditableFieldLabel>

				<TextField
					value={description}
					className="editable-check-items__item-editing-dialog-input"
					elementType="textarea"
					elementProps={{minRows: 2}}
					onChange={setDescription}
				/>
			</div>

			<DependencySelect
				label="Зависимость на категории"
				className="editable-check-items__item-editing-dialog-select"
				value={deps}
				dependencies={availableDependencies}
				onChange={handleSelectChange}
				disabled={availableDependencies.length === 0}
			/>
		</Dialog>
	);
};
