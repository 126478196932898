import {Column} from 'react-table';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemStatusCell} from '@src/components/tableCells/ProblemStatusCell';
import {ProblemFixCell} from '@src/components/tableCells/ProblemFixCell';
import InspectionInfoDesktopCell from '@src/components/tableCells/InspectionInfoDesktopCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import {TFunction} from 'i18next';
import {InspectionFixCell} from '@src/components/tableCells/InspectionFixCell';

export const getDesktopColumns = (tFunc: TFunction): Array<Column<IStructureEntity>> => [
	{
		id: 'number',
		Header: HeaderCellWithRowSelectWrap(
			tFunc('structurePage.problemsTableColumns.number.header')
		),
		Cell: CellWrap({
			problem: ProblemInfoDesktopCell,
			inspection: InspectionInfoDesktopCell
		}),
		width: 200,
		minWidth: 200
	},
	{
		id: 'status',
		Header: tFunc('structurePage.problemsTableColumns.status.header'),
		Cell: CellWrap({
			problem: ProblemStatusCell
		}),
		width: 80,
		minWidth: 80
	},
	{
		id: 'category',
		Header: tFunc('structurePage.problemsTableColumns.category.header'),
		Cell: CellWrap({
			problem: CategoryCell
		}),
		width: 80,
		minWidth: 80
	},
	{
		id: 'fix',
		Header: tFunc('structurePage.problemsTableColumns.fix.header'),
		Cell: CellWrap({problem: ProblemFixCell, inspection: InspectionFixCell}),
		width: 80,
		minWidth: 80
	}
];
