import { memo } from 'react';
import {useDndEmptyItem} from '../../hooks/useDndEmptyItem';
import {useDndCtx} from '../../DndContext';
import classNames from 'classnames';

interface IDndTableEmptyRowProps {
	className?: string;
	emptyMessage?: string;
	container?: string;
	overContainer?: string;
}

export const DndTableEmptyRow = memo(({
	className, emptyMessage, container, overContainer
}: IDndTableEmptyRowProps) => {
	const emptyItemRef = useDndEmptyItem<HTMLTableRowElement>(container);
	const {dragState: {isDragging}} = useDndCtx();

	const isOverItem = overContainer && container
		? isDragging && overContainer === container
		: false;

	return (
		<tr
			className={classNames(
				'dnd-table__body-row-empty',
				{isOver: isOverItem},
				className
			)}
			ref={emptyItemRef}
		>
			<td
				className="dnd-table__body-row-empty__cell"
				colSpan={256}
			>
				{emptyMessage || 'Нет элементов в таблице'}
			</td>
		</tr>
	);
});