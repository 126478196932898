import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import arrayToTree from 'array-to-tree';

export const getGroupsIds = (groupsTree: Array<arrayToTree.Tree<IWorkingGroup>>) => {
	const ids: string[] = [];
	groupsTree.forEach(group => {
		ids.push(group.id);
		if (group.children) {
			ids.push(...getGroupsIds(group.children));
		}
	});
	return ids;
};
