import {AllObjectPageBreadcrumbs} from '@src/pages/OwnerAcceptancesPage/components/AllObjectPageBreadcrumbs';
import {ObjectPageBreadcrumbs} from '@src/pages/OwnerAcceptancesPage/components/ObjectPageBreadcrumbs';
import {ActionsDesktop} from '@src/pages/OwnerAcceptancesPage/components/actions/Actions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

export const useOwnerAcceptancesPageBreadcrumbs = (objectId = 'all') => {
	const isDesktop = useIsDesktop();
	const actions = isDesktop ? <ActionsDesktop objectId={objectId} /> : null;

	let breadcrumbs = null;

	if (objectId && isDesktop) {
		breadcrumbs = <AllObjectPageBreadcrumbs>{actions}</AllObjectPageBreadcrumbs>;
	} else if (!objectId) {
		breadcrumbs = <ObjectPageBreadcrumbs objectId={objectId}>{actions}</ObjectPageBreadcrumbs>;
	}

	return breadcrumbs;
};
