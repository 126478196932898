import {ITableSettings} from '@src/interfaces/ITableSettings';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {ColumnWithSettings} from '@src/core/hooks/settings/useColumnsWithSettings/interfaces';
import {isEmpty} from 'lodash';

export const getNewTableSettings = <T extends object>(
	customizedColumns: Array<ColumnWithSettings<T>>,
	tableSettings: ITableSettings,
	entitySettings?: IEntitySettings
) =>
	customizedColumns.reduce((newableSettings: ITableSettings, column) => {
		const {id, customizeSettings, Header} = column;
		if (customizeSettings.controlled) {
			if (customizeSettings.isBuiltin && !isEmpty(entitySettings?.builtin)) {
				const columnIsAvailable = !!entitySettings?.builtin[id];
				newableSettings[id] = {
					header: String(Header),
					isVisible: columnIsAvailable
						? tableSettings[id]
							? tableSettings[id].isVisible
							: true
						: false,
					showToggle: columnIsAvailable
				};
			} else {
				newableSettings[id] = {
					header: String(Header),
					isVisible: tableSettings[id]
						? tableSettings[id].isVisible
						: !customizeSettings.isCustom,
					showToggle: true
				};
			}
		}
		return newableSettings;
	}, {});
