interface Args {
	objectId: string;
	workAcceptanceId: string;
}
/**
 * Формирует ссылку на список чек-листов
 *
 * @param objectId id объекта
 * @param workAcceptanceId id помещения
 */
export const formWorkAcceptanceCheckListsLink = ({objectId, workAcceptanceId}: Args) =>
	(`/objects/${objectId}/work-acceptances/${workAcceptanceId}/check-lists`);
