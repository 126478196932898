import './ProblemTagsSetEditingPage.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {EditPageBreadcrumbs} from './components/EditPageBreadcrumbs';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import {EditingProblemTagsSet} from '@src/components/EditingProblemTagsSet/EditingProblemTagsSet';

export const ProblemTagsSetEditingPage = () => {
	const {problemTagsSetId} = useStrictParams<{problemTagsSetId?: string}>();

	useAppHeader(
		{
			title: problemTagsSetId
				? 'Редактирование набора меток нарушений'
				: 'Создание набора меток нарушений',
			mobileRightButtons: <AppUpdateMenu/>,
			showBackBtn: true
		},
		[problemTagsSetId]
	);

	return (
		<div className="page-cont manage-problem-tags-set-edit-page">
			<EditPageBreadcrumbs/>
			<EditingProblemTagsSet problemTagsSetId={problemTagsSetId}/>
		</div>
	);
};
