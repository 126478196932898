import { useMemo } from 'react';
import SelectionActions from '../selection/SelectionActions';
import ActionsMobile from './Actions.mobile';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {useOwnerAcceptanceEntities} from '@src/core/hooks/queries/ownerAcceptance/problems/hooks';

interface IMobileRightButtonsProps {
	objectId: string;
	ownerAcceptanceId: string;
	ownerAcceptance?: ILinkedOwnerAcceptance;
	selectedRows?: string[];
}

const MobileRightButtons = ({
	objectId,
	ownerAcceptanceId,
	ownerAcceptance,
	selectedRows
}: IMobileRightButtonsProps) => {
	const {data: entities} = useOwnerAcceptanceEntities(objectId, ownerAcceptanceId);

	const selectedEntities = useMemo(
		() => entities?.filter(item => selectedRows?.includes(item.id)) || [],
		[selectedRows, entities]
	);

	if (!ownerAcceptance) {
		return null;
	}

	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				ownerAcceptanceId={ownerAcceptanceId}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			ownerAcceptance={ownerAcceptance}
		/>
	);
};

export default MobileRightButtons;
