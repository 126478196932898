import { createContext } from 'react';
import {IBlock} from '../PageLeavingBlock';

export const PageLeavingBlockCtx = createContext<{
	add(block: IBlock): void;
	delete(key: string): void;
}>({
	add: () => null,
	delete: () => null
});
