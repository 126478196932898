import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export type IGetLegalsEntityResponse = INormalizedData<ILegalEntity>;

/**
 * Возвращает юридические лица
 */
export const requestLegalsEntity = async () => {
	const response = await httpRequests.withToken.get<IGetLegalsEntityResponse>('legals-entity');
	return response.data;
};
