import {useIsFetching, useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetCheckListsResponse} from '@src/api/backend/checkLists';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {
	extractCheckListsForPage,
	extractCheckListById,
	extractCheckListsAsArray,
	extractCheckListsAsArrayByObjectId,
	extractCheckListsAsArrayFilteredBySelectedSpaceType,
	extractCheckListsAsArrayFilteredByStructureType,
	extractCheckListsAsArrayForWorkAcceptances,
	extractCheckListsMap
} from './selectors';
import {useCallback} from 'react';
import {useObjects} from '@src/core/hooks/queries/objects/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {ICheckListsPageSettingsState} from "@src/store/modules/settings/pages/manage/checkLists/reducers";

export const useCheckLists = <T = IGetCheckListsResponse>(
	select?: (data: IGetCheckListsResponse) => T
) =>
	useQuery<IGetCheckListsResponse, IError, T>({
		queryKey: checkListsQueryKeys.list(),
		meta: {
			error: 'при загрузке списка чек-листов'
		},
		select
	});

export const useCheckListsIsLoading = () => !!useIsFetching({queryKey: checkListsQueryKeys.list()});

export const useExtractCheckListsMap = () => useCheckLists(extractCheckListsMap);

export const useExtractCheckListsForPage = (
	settings: ICheckListsPageSettingsState,
) => {
	const selector = useCallback(
		(data: IGetCheckListsResponse) =>
			extractCheckListsForPage(data, settings),
		[settings]
	);
	return useCheckLists(selector);
};

export const useExtractCheckListsAsArray = () => useCheckLists(extractCheckListsAsArray);

export const useExtractCheckListById = (checkListId?: string) => {
	const selector = useCallback(
		(data: IGetCheckListsResponse) => extractCheckListById(data, checkListId),
		[checkListId]
	);
	return useCheckLists(selector);
};

/**
 * Возвращаем чек листы в виде массива для объекта
 */
export const useExtractCheckListsAsArrayByObjectId = (objectId?: string) => {
	const {data: objectsData} = useObjects();
	const selector = useCallback(
		(data: IGetCheckListsResponse) =>
			extractCheckListsAsArrayByObjectId(data, objectId, objectsData),
		[objectId]
	);
	return useCheckLists(selector);
};

export const useExtractCheckListsAsArrayFilteredBySelectedSpaceType = (objectId?: string) => {
	const {data: objectsData} = useObjects();
	const spacesPageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const selector = useCallback(
		(data: IGetCheckListsResponse) =>
			extractCheckListsAsArrayFilteredBySelectedSpaceType(
				data,
				objectId,
				objectsData,
				spacesPageSettings
			),
		[objectId, objectsData, spacesPageSettings]
	);
	return useCheckLists(selector);
};

/**
 * Возвращаем чек листы в виде массива для объекта для приёмок работ
 */
export const useExtractCheckListsAsArrayForWorkAcceptances = (objectId?: string) => {
	const {data: objectsData} = useObjects();
	const selector = useCallback(
		(data: IGetCheckListsResponse) =>
			extractCheckListsAsArrayForWorkAcceptances(data, objectId, objectsData),
		[objectId, objectsData]
	);
	return useCheckLists(selector);
};

/**
 * Возвращаем чек листы в виде массива для объекта для конкретного типа структур
 */
export const useExtractCheckListsAsArrayFilteredByStructureType = (
	objectId?: string,
	type?: string
) => {
	const {data: objectsData} = useObjects();
	const selector = useCallback(
		(data: IGetCheckListsResponse) =>
			extractCheckListsAsArrayFilteredByStructureType(data, objectId, objectsData, type),
		[objectId, objectsData, type]
	);
	return useCheckLists(selector);
};
