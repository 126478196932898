import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export type IGetUsersResponse = INormalizedData<IBriefUser>;

/**
 * Отправляет запрос на получение списка пользователей
 */
export const requestUsers = async () => {
	const response = await httpRequests.withToken.get<IGetUsersResponse>('users');
	return response.data;
};
