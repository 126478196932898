import {WorkAcceptanceFrontItem} from '@src/components/WorkAcceptanceFrontItem/WorkAcceptanceFrontItem';
import {Category} from '@src/components/viewFields/Category';
import Description from '@src/components/viewFields/Description';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useExtractWorkAcceptanceFrontTypesById} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useExtractWorkAcceptanceTypesById} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {CustomField} from '@src/components/CustomField';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editWorkAcceptanceStatus} from '@src/store/modules/entities/workAcceptance/actions';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {EditorInfo, EntityGrid, EntityGridItem, Plate, StatusSelect} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {WorkAcceptanceAcceptors} from './WorkAcceptanceAcceptors';

const locationIcon = <i className="tz-location-20" />;
const calendarIcon = <i className="tz-calendar-20" />;
const typeIcon = <i className="tz-category-20" />;
const statusIcon = <i className="tz-status-20" />;
const contractorIcon = <i className="tz-contractor-20" />;

interface IDesktopInfoProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
	settings?: IEntitySettings;
}

export const DesktopDescription = ({objectId, workAcceptance, settings}: IDesktopInfoProps) => {
	const {data: statuses} = useExtractWorkAcceptanceStatuses();
	const translatedStatuses = useTranslatedDictionary('workAcceptanceStatuses', statuses);
	const {t} = useTranslation();
	const custom = settings?.custom;
	const dispatch = useAppDispatch();
	const handleChange = useCallback(
		async (v: WorkAcceptanceStatusIds) => {
			if (workAcceptance) {
				await dispatch(editWorkAcceptanceStatus(objectId, workAcceptance.id, v));
			}
		},
		[objectId, workAcceptance, dispatch]
	);

	const formattedDate = workAcceptance?.acceptanceDate
		? format(workAcceptance.acceptanceDate, dateTimeFormat, {locale: ru})
		: undefined;

	const permissions = useWorkAcceptancesPermissions(objectId);
	const availableStatuses = permissions.canEditStatusRestrictions?.availableStatuses;

	const {data: typesMap} = useExtractWorkAcceptanceTypesById();

	const workAcceptanceType = workAcceptance.type && typesMap && typesMap[workAcceptance.type];
	const translatedAcceptanceType = useTranslatedDictionaryById(
		dictionaryKeys.workAcceptanceTypes,
		workAcceptanceType?.id
	);

	const {data: frontTypesMap} = useExtractWorkAcceptanceFrontTypesById();
	const workAcceptanceFrontType =
		workAcceptance.frontType && frontTypesMap && frontTypesMap[workAcceptance.frontType];
	const translatedAcceptanceFrontType = useTranslatedDictionaryById(
		dictionaryKeys.workAcceptanceFrontTypes,
		workAcceptanceFrontType?.id
	);
	const workAcceptanceFront =
		workAcceptance.frontType === WorkAcceptanceFrontTypeId.STRUCTURES
			? workAcceptance.structures
			: workAcceptance.frontType === WorkAcceptanceFrontTypeId.SPACES
			? workAcceptance.spaces
			: undefined;

	return (
		<Plate
			className={{
				content: 'work-acceptance-page__info-plate',
				root: 'work-acceptance-page__info-plate-container'
			}}
		>
			<EntityGrid withBorders>
				<EntityGridItem
					label={t('workAcceptancePage.description.status.label')}
					icon={statusIcon}
					fullRow
				>
					{translatedStatuses && (
						<StatusSelect
							className="work-acceptance-page__d-entities-status"
							statuses={translatedStatuses}
							availableStatuses={availableStatuses || translatedStatuses.allIds}
							disabled={!permissions.canEditStatus}
							value={workAcceptance?.status}
							onChange={handleChange}
						/>
					)}
				</EntityGridItem>

				{translatedAcceptanceType && (
					<EntityGridItem
						icon={typeIcon}
						label={t('workAcceptancePage.description.acceptanceType.label')}
						fullRow
					>
						<div>{translatedAcceptanceType}</div>
					</EntityGridItem>
				)}

				{workAcceptance.description && <Description value={workAcceptance.description} />}

				<EntityGridItem
					className="fix-date-view-field"
					icon={calendarIcon}
					label={t('workAcceptancePage.description.acceptanceDeadline.label')}
					fullRow
				>
					<div className="fix-date-view-field__value">{formattedDate}</div>
				</EntityGridItem>

				{workAcceptance.categoryId && (
					<Category
						categoryId={workAcceptance.categoryId}
						fullRow
						label={t('workAcceptancePage.description.category.label')}
					/>
				)}

				<EntityGridItem
					icon={contractorIcon}
					label={t('workAcceptancePage.description.contractor.label')}
					fullRow
				>
					{workAcceptance.contractorLegalEntity?.shortName}
				</EntityGridItem>

				{(workAcceptance.acceptorsActiveGroup || workAcceptance.acceptors?.length) && (
					<WorkAcceptanceAcceptors workAcceptance={workAcceptance} />
				)}

				{translatedAcceptanceFrontType && (
					<EntityGridItem
						icon={locationIcon}
						label={t('workAcceptancePage.description.worksFrontType.label')}
						fullRow
					>
						<div>{translatedAcceptanceFrontType}</div>
					</EntityGridItem>
				)}

				<EntityGridItem
					icon={locationIcon}
					label={t('workAcceptancePage.description.workScope.label')}
					fullRow
				>
					{workAcceptanceFront?.map(item => (
						<WorkAcceptanceFrontItem
							item={item}
							objectId={objectId}
							frontType={workAcceptance.frontType}
							key={item.id}
						/>
					))}
				</EntityGridItem>

				{custom &&
					Object.values(custom).map(customSetting => (
						<CustomField
							fields={workAcceptance.customFields}
							setting={customSetting}
						/>
					))}

				<EditorInfo
					icon={calendarIcon}
					label={t('workAcceptancePage.description.created.label')}
					date={workAcceptance?.createdAt}
					user={workAcceptance?.createdBy}
					fullRow
				/>

				{workAcceptance?.createdAt !== workAcceptance?.modifiedAt && (
					<EditorInfo
						icon={calendarIcon}
						label={t('workAcceptancePage.description.updated.label')}
						date={workAcceptance?.modifiedAt}
						user={workAcceptance?.modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
		</Plate>
	);
};
