import * as React from 'react';
import './DesktopSidebar.less';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import SidebarToggleBtn from '../SidebarToggleBtn';
import {Scrollbar} from '../../containers/Scrollbar';

interface IDesktopSidebarProps {
	className?: string;
	style?: React.CSSProperties;
	menu?: React.ReactNode;
	appInfo?: React.ReactNode;
	expanded?: boolean;

	onExpand?(): void;
}

const appInfoVariants = {
	hidden: {opacity: 0},
	visible: {opacity: 1}
};

const appInfoTransition = {type: 'tween', duration: 0.2};

const DesktopSidebar = (
	{className, style, menu, appInfo, expanded, onExpand}: IDesktopSidebarProps
) => (
	<div
		className={classNames('d-sidebar', {'d-sidebar_expanded': expanded}, className)}
		style={style}
	>
		<div className={classNames('d-sidebar__anim-area', {'d-sidebar__anim-area_expanded': expanded})}>
			<Scrollbar className="d-sidebar__cont">
				<div className="d-sidebar__menu-wrap">{menu}</div>

				{expanded && (
					<motion.div
						className="d-sidebar__info-wrap"
						initial="hidden"
						animate="visible"
						variants={appInfoVariants}
						transition={appInfoTransition}
					>
						{appInfo}
					</motion.div>
						)}
			</Scrollbar>
		</div>

		<div className="d-sidebar__toggle-area"/>
		<SidebarToggleBtn
			className="d-sidebar__toggle-btn"
			reversed={expanded}
			onClick={onExpand}
		/>
	</div>
);

export default DesktopSidebar;
