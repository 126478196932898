import {useCallback, useMemo} from 'react';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import {desktopColumns} from './columns.desktop';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {IPreparedExportTemplate} from '../../interfaces/IPreparedExportTemplate';
import {convertExportTemplates} from '../../utils/convertExportTemplates';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useGetExportTemplatesAsArrayWithoutPdf} from '@src/core/hooks/queries/exportTemplates/hooks';

interface ITableProps {
	loading?: boolean;
}

export const Table = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const {data} = useGetExportTemplatesAsArrayWithoutPdf();
	const preparedData = useMemo(() => (data ? convertExportTemplates(data) : null), [data]);

	const handleRowClick = useCallback(
		(item: IPreparedExportTemplate) => {
			pushPath(`/manage/export-templates/${item.id}`);
		},
		[pushPath]
	);

	if (!preparedData) return null;
	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider>
				<LoadingPanel active={loading}>
					<EntitiesTable
						columns={desktopColumns}
						data={preparedData}
						onRowClick={handleRowClick}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
