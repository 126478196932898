import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import { useCallback, useMemo } from 'react';
import {IPreparedSpaceStatusesSet} from '../../interfaces/IPreparedSpaceStatusesSet';
import {SelectionActions} from './SelectionActions';

export const SelectionRow = ({
	selectedFlatRows, toggleAllRowsSelected
}: ISelectionRowProps<IPreparedSpaceStatusesSet>) => {
	const handleClear = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	const selectedEntities = useMemo(() =>
		selectedFlatRows.map(item => item.original), [selectedFlatRows]);

	return (
		<>
			<SelectionActions
				onClear={handleClear}
				selectedEntities={selectedEntities}
			/>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={handleClear}
			/>
		</>
	);
};