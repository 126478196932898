import {useChangePath} from '@src/core/hooks/useChangePath';
import {toast, ToastContainer, ToastOptions} from 'react-toastify';
import {IconButton} from '@tehzor/ui-components';
import {AddingSuccessToast} from '@src/components/AddingSuccessToast/AddingSuccessToast';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

const ADDING_SUCCESS_CONTAINER_ID = 'adding-success-notification-container';
const closeIcon = <i className="tz-close-24" />;

interface IShowAddInfoToastParams {
	title: string;
	link: string;
	iconName?: string;
}

export const useAddingSuccessToast = () => {
	const isDesktop = useIsDesktop();
	const {pushPath} = useChangePath();

	const toastConfigurations: ToastOptions = {
		draggable: !isDesktop,
		closeOnClick: false,
		autoClose: 5_000,
		progressClassName: 'adding-success-toast__progress-bar',
		type: 'success',
		className: 'adding-success-toast',
		bodyClassName: 'adding-success-toast__wrapper',
		containerId: ADDING_SUCCESS_CONTAINER_ID,
		closeButton: isDesktop
			? ({closeToast}) => (
					<IconButton
						onClick={closeToast}
						type="inline-blue-accent"
						className="adding-success-toast__close"
					>
						{closeIcon}
					</IconButton>
			  )
			: false
	};

	const showAddingSuccessToast = (params: IShowAddInfoToastParams) => {
		const {title, link, iconName} = params;

		return toast(
			({closeToast}) => (
				<AddingSuccessToast
					title={title}
					onLinkClick={() => {
						pushPath(link);
						closeToast?.();
					}}
				/>
			),
			{
				...toastConfigurations,
				icon: iconName ? (
					<i className={`adding-success-toast--icon ${iconName}`} />
				) : undefined
			}
		);
	};

	const addingSuccessToastContainer = (
		<ToastContainer
			className="adding-success-toast-container"
			stacked
			position={isDesktop ? 'bottom-right' : 'top-center'}
			containerId={ADDING_SUCCESS_CONTAINER_ID}
		/>
	);

	return {showAddingSuccessToast, addingSuccessToastContainer};
};
