import {Column} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {StructureInfoMobileCell} from '@/widgets/StructuresTable/ui/Cells/StructureInfoMobileCell/StructureInfoMobileCell';

export const getMobileColumns = (): Array<Column<IEnrichedStructure>> => [
	{
		id: 'info',
		Cell: StructureInfoMobileCell,
		className: 'structures-page__mobile-cell'
	}
];
