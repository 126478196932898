import {
	ActionButtons,
	Button,
	Dialog,
	EditableFieldLabel,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import classNames from 'classnames';
import { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import {useUpdateEffect} from 'react-use';

interface ITreeItem {
	id: string;
	name: string;
	parentId?: string;
}

interface ITreeSelectContractFieldProps {
	items: ITreeDataItem[];
	itemsMap: Record<string, ITreeItem>;

	className?: string;
	label?: string;
	dialogTitle?: string;
	errorMessage?: string;
	hasError?: boolean;
	required?: boolean;
	disabled?: boolean;
	isMobile?: boolean;
	value?: string[] | null;

	icon?: ReactNode;

	onConfirm: (value?: string[] | null) => void;
	onError: () => void;
}

const getTitle = (itemsMap: Record<string, ITreeItem>, value?: string[] | null) => {
	if (!value?.length) {
		return '';
	}

	return value
		?.map(id => itemsMap[id]?.name)
		.filter(value => !!value)
		.join(', ');
};

const arrow = <i className="tz-simple-arrow-20" />;

export const TreeSelectContractField = memo(
	({
		items,
		itemsMap,
		className,
		label,
		dialogTitle,
		errorMessage,
		hasError,
		required,
		disabled,
		isMobile,
		value,
		icon = arrow,
		onConfirm,
		onError
	}: ITreeSelectContractFieldProps) => {
		const [checked, setChecked] = useState(value);
		const [expandedItems, setExpandedItems] = useState<string[] | undefined>([]);
		const [isOpen, setOpen] = useState(false);

		const [search, setSearch] = useState('');

		const clearSearch = useCallback(() => {
			setSearch('');
		}, []);

		useUpdateEffect(() => setChecked(value), [value]);

		const open = () => setOpen(true);
		const close = () => setOpen(false);

		const isSearchShown = (items?.length || 0) >= 8;

		const {filteredData, expanded} = useMemo(
			() => treeFilter(items || [], 'content', search),
			[items, search]
		);

		useUpdateEffect(() => {
			setExpandedItems(expanded?.map(item => item.id));
		}, [expanded]);

		const handleCancel = () => {
			setChecked(value);
			clearSearch();
		};

		const handleConfirm = useCallback(() => {
			onConfirm(checked);
			if (required) {
				onError();
			}
			clearSearch();
		}, [onConfirm, checked, required, clearSearch, onError]);

		const handleClearButton = useCallback(() => {
			onConfirm(null);
			if (required) {
				onError();
			}
			clearSearch();
		}, [onConfirm, required, clearSearch, onError]);

		const trigger = (
			<TextFieldWithForwardedRef
				elementType="div"
				value={getTitle(itemsMap, value)}
				icon={icon}
				error={hasError ? errorMessage : undefined}
				disabled={disabled || !items?.length}
				onClearClick={handleClearButton}
				onClick={isMobile ? open : undefined}
				cleanable={!!value}
			/>
		);

		const content = (
			<TreeSelect
				data={filteredData}
				multiple
				value={checked || undefined}
				onChange={setChecked}
				expandedValue={expandedItems}
				onExpand={setExpandedItems}
			/>
		);

		const selectSearch = isSearchShown ? (
			<SelectSearch
				value={search}
				onChange={setSearch}
				onClear={clearSearch}
			/>
		) : null;

		const footer = (
			<div className="editable-contract__fields-tree-select__dialog-footer">
				<ActionButtons className="editable-contract__fields-tree-select__dialog-footer-btns">
					<Button
						type="cancel"
						label="Отменить"
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleConfirm}
					/>
				</ActionButtons>
			</div>
		);

		return (
			<div className={classNames('editable-contract__fields-tree-select', className)}>
				<EditableFieldLabel className="editable-contract__fields-tree-select__label">
					{required ? `${label} *` : label}
				</EditableFieldLabel>
				{!isMobile ? (
					<SelectPopup
						noHeader={!isSearchShown}
						search={selectSearch}
						footer
						onCancel={handleCancel}
						onApply={handleConfirm}
						trigger={trigger}
					>
						{content}
					</SelectPopup>
				) : (
					trigger
				)}
				{isMobile && (
					<Dialog
						isOpen={isOpen}
						className="editable-contract__fields-tree-select__dialog"
						title={required ? `${dialogTitle} *` : dialogTitle}
						onRequestClose={close}
						footer={footer}
					>
						<div className="editable-contract__fields-tree-select__dialog-search">
							{selectSearch}
						</div>
						{content}
					</Dialog>
				)}
			</div>
		);
	}
);
