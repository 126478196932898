import {Dispatch, memo, useCallback, useEffect, useState} from "react";
import {EditableFieldLabel, TextField} from "@tehzor/ui-components";
import useToggle from "react-use/lib/useToggle";
import {
	IEditableSpaceStatusesSetAction
} from "@src/core/hooks/states/useSpaceStatusesSetState";
import {IExtendedSpaceStatus} from "@src/components/EditableSpaceStatuses/interfaces/IExtendedSpaceStatus";
import {ISpaceStatusNotificationMessage} from "@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus";
import {isEqual} from "lodash";
import {NotificationMessageDialog} from "@src/components/EditableSpaceStatuses/components/fields/NotificationMessageField/NotificationMessageDialog";
import {useTranslatedConfirmDialog} from "@src/core/hooks/translations/useTranslatedConfirmDialog";
import {
	defaultMessageData
} from "@src/components/EditableSpaceStatuses/components/fields/NotificationMessageField/constants/defaultMessageData";

interface INotificationMessageFieldProps {
	status: IExtendedSpaceStatus;
	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;
	handleSetEdit?: () => void;
}

export const NotificationMessageField = memo(({status, editingDispatch, handleSetEdit}: INotificationMessageFieldProps) => {
	const {notificationMessage} = status;
	const [isOpen, toggleOpen] = useToggle(false);

	const [messageData, setMessageData] = useState<ISpaceStatusNotificationMessage>(() => notificationMessage || defaultMessageData);
	const receivedData = notificationMessage || defaultMessageData;
	const [hasTitleError, setHasTitleError] = useState<boolean>(false);

	const [dialog, getConfirmation] = useTranslatedConfirmDialog();

	useEffect(() => {
		if(notificationMessage) {
			setMessageData(notificationMessage);
			return;
		}
		setMessageData(defaultMessageData);
	}, [notificationMessage]);

	const rejectHandler = useCallback(async () => {
		if(!isEqual(receivedData, messageData)) {
			if(await getConfirmation()) {
				setMessageData(receivedData)
			} else {
				return;
			}
		}
		toggleOpen(false);
		setHasTitleError(false);
	},[getConfirmation, receivedData, messageData, toggleOpen]);

	const handleChangeMessageTitle = useCallback ((title: string) => {
		if(hasTitleError && title) {
			setHasTitleError(false);
		}
		setMessageData({...messageData, title});
	}, [hasTitleError, messageData]);

	const handleChangeMessageBody = useCallback ((body: string) => {
		setMessageData({...messageData, body})
	}, [messageData]);

	const handleSaveNotificationMessage = useCallback(
		() => {
			if(!messageData.title) {
				setHasTitleError(true)
				return;
			}
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: {...status, notificationMessage: messageData}
			});
			if(handleSetEdit) {
				handleSetEdit();
			}
			toggleOpen(false);
		},
		[editingDispatch, status, handleSetEdit, toggleOpen,  messageData]
	);

	const handleDeleteNotificationMessage = useCallback(
		() => {
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: {...status, notificationMessage: null}
			});
			if(handleSetEdit) {
				handleSetEdit();
			}
			toggleOpen(false);
		},
		[editingDispatch, status, handleSetEdit, toggleOpen]
	);

	return (
		<div>
			{dialog}
			<div className="editable-space-statuses__status-fields__notification-message">
				<EditableFieldLabel>Текст оповещения при установке</EditableFieldLabel>
				<TextField
					elementType="div"
					value={notificationMessage?.title ? `Заголовок (${notificationMessage.title})` : ''}
					icon={<i className="tz-edit-16"/>}
					onClick={toggleOpen}
				/>
			</div>
			<NotificationMessageDialog
				isOpen={isOpen}
				rejectHandler={rejectHandler}
				handleDeleteNotificationMessage={handleDeleteNotificationMessage}
				deleteIsDisable={!notificationMessage}
			   	handleSaveNotificationMessage={handleSaveNotificationMessage}
			>
				<div>
					<EditableFieldLabel>Заголовок оповещения</EditableFieldLabel>
					<TextField
						value={messageData.title}
						onChange={handleChangeMessageTitle}
						elementType="textarea"
						className={{
							wrap: "editable-space-statuses__status-fields__notification-message__title"
						}}
						error={hasTitleError ? "Error" : undefined}
					/>
				</div>
				<div>
					<EditableFieldLabel>Текст оповещения</EditableFieldLabel>
					<TextField
						value={messageData.body}
						onChange={handleChangeMessageBody}
						elementType="textarea"
						className={{
							wrap: "editable-space-statuses__status-fields__notification-message__body"
						}}
					/>
				</div>
			</NotificationMessageDialog>
		</div>
	)
})