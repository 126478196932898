import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface IWorkingGroupsFiltersState extends Record<string, unknown> {
	objects?: string[];
	stages?: string[];
	name?: string;
}

interface IWorkingGroupsPageSettingsState {
	filters: IWorkingGroupsFiltersState;
}

const initialState: IWorkingGroupsPageSettingsState = {
	filters: {}
};

const workingGroupsSlice = createSlice({
	name: 'workingGroups',
	initialState,
	reducers: {
		setFilter(state, action: PayloadAction<IWorkingGroupsFiltersState>) {
			state.filters = action.payload;
		},
		clearFilters(state) {
			state.filters = {};
		}
	}
});

export const {actions: workingGroupsActions, reducer: workingGroupsReducer} = workingGroupsSlice;
