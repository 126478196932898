import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import ProblemsTable from '@src/pages/ProblemsPage/components/table/ProblemsTable';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import classNames from 'classnames';
import {useParams} from 'react-router-dom';
import {useProblemSettings} from '../../core/hooks/settings/useProblemSettings';
import './ProblemsPage.less';
import AllPageBreadcrumbs from './components/AllPageBreadcrumbs';
import ObjectPageBreadcrumbs from './components/ObjectPageBreadcrumbs';
import ProblemsPageCounter from './components/ProblemsPageCounter';
import {ProblemsSearch} from './components/ProblemsSearch';
import {ActionsDesktop} from './components/actions/Actions.desktop';
import ProblemsFilters from './components/filters/ProblemsFilters';
import {ColumnsCustomizer} from '@src/components/ColumnsCustomizer/ColumnsCustomizer';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

interface IProblemsPageProps {
	className?: string;
}

const ProblemsPage = ({className}: IProblemsPageProps) => {
	const dispatch = useAppDispatch();
	useScrollRestoration();
	const {objectId} = useParams<{objectId?: string}>();
	const isDesktop = useIsDesktop();

	const problemSettings = useProblemSettings(objectId);
	const {tableSettings} = useAppSelector(s => extractProblemsPageSettings(s, objectId));
	const {changeColumnVisible, showAllColumn} = problemsActions;

	const onChangeColumnVisible = useCallback(
		(columnKey: string) => {
			dispatch(changeColumnVisible({objectId: objectId || 'all', columnKey}));
		},
		[dispatch, changeColumnVisible, objectId]
	);

	const onShowAllColumns = useCallback(() => {
		dispatch(showAllColumn({objectId: objectId || 'all'}));
	}, [dispatch, showAllColumn, objectId]);

	const actions = <ActionsDesktop objectId={objectId} />;

	return (
		<div className={classNames('page-cont', 'problems-page', className)}>
			{objectId !== undefined ? (
				<ObjectPageBreadcrumbs objectId={objectId}>
					{isDesktop && actions}
				</ObjectPageBreadcrumbs>
			) : (
				isDesktop && <AllPageBreadcrumbs>{actions}</AllPageBreadcrumbs>
			)}

			<div className="problems-page__filters-bar">
				{!isDesktop && (
					<div className="problems-page__search">
						<ProblemsSearch />
					</div>
				)}
				<ProblemsFilters
					objectId={objectId}
					settings={problemSettings}
				/>
			</div>
			<div className="problems-page__counter-bar">
				<ProblemsPageCounter objectId={objectId} />
				{isDesktop && (
					<ColumnsCustomizer
						tableSettings={tableSettings}
						onChangeColumnsVisible={onChangeColumnVisible}
						onShowAllColumns={onShowAllColumns}
					/>
				)}
			</div>
			<ProblemsTable
				objectId={objectId}
				settings={problemSettings}
			/>
		</div>
	);
};

export default ProblemsPage;
