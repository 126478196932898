import {useMemo} from 'react';
import {CriticalMarker} from '@src/components/CriticalMarker';
import {DocumentsCount} from '@src/components/DocumentsCount';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {LocalProblemStatus} from '@src/components/tableCells/LocalProblemStatusCell';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {offlineModeTransferColors} from '@src/core/offlineMode/utils/offlineModeTransferColors';
import {offlineModeTransferIcons} from '@src/core/offlineMode/utils/offlineModeTransferIcons';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {EntityLoadingStatus, PhotoPreview, TableRowSelect} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {useTranslation} from 'react-i18next';
import {useEntityTransferState} from '@src/core/hooks/useEntityTransferState';
import {useInspectorsGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';

interface ILocalMobileProblemCellProps extends CellProps<IProblemEntity> {
	settings?: IEntitySettings;
	withObject?: boolean;
}

const LocalMobileProblemCell = ({row, settings, withObject}: ILocalMobileProblemCellProps) => {
	const {
		status,
		plannedFixDate,
		localNumber,
		critical,
		description,
		plannedFixDateOriginal,
		inspectors,
		performers,
		performersActiveGroup,
		object,
		attachment,
		id
	} = row.original.data;

	const {t} = useTranslation();

	const transferStatus = useEntityTransferState(id);
	const {data: inspectorsGroups} = useInspectorsGroupsAsArray(object?.id);

	const statusTitle =
		offlineModeTransferTitles[transferStatus || OfflineDataTransferStatus.SAVED_LOCAL];
	const statusIcon =
		offlineModeTransferIcons[transferStatus || OfflineDataTransferStatus.SAVED_LOCAL];
	const statusColor =
		offlineModeTransferColors[transferStatus || OfflineDataTransferStatus.SAVED_LOCAL];

	const documentsCount = row.original.data.documentsCount;

	const firstInspector = inspectors?.[0];
	const userGroup = useMemo(() => {
		if (!firstInspector || !inspectorsGroups?.length) {
			return undefined;
		}

		const groups: IWorkingGroup[] = [];
		for (const group of inspectorsGroups) {
			if (group.performers?.includes(firstInspector.id)) {
				groups.push(group);
			} else if (group.leader === firstInspector.id) {
				groups.push(group);
			}
		}

		return groups.length === 1 ? groups[0] : undefined;
	}, [inspectorsGroups, firstInspector]);

	const numberEl = (
		<div className="problems-page__m-table-description-number">
			№{localNumber?.toUpperCase()}
		</div>
	);

	const criticalEl = !!critical && <CriticalMarker />;

	const problemTagsEl = row.original.data.problemTags?.length && (
		<ProblemTagsMarker
			className="problems-page__m-table-tags"
			colors={row.original.data.problemTags}
		/>
	);

	const descriptionEl = (
		<div className="problems-page__m-table-description-text">{description}</div>
	);

	const plannedFixDateEl = plannedFixDate && (
		<div className="problems-page__m-table-plannedFixDate">
			<div>
				{t('problemsPage.mobileProblemCell.plannedFixDate')}:{plannedFixDate}
			</div>
			{status.id !== ProblemStatusId.FIXED && plannedFixDateOriginal && (
				<FixDateTag plannedFixDate={plannedFixDateOriginal} />
			)}
		</div>
	);

	const performersEl = !!(performersActiveGroup || performers?.length) && (
		<div className="problems-page__m-table-performers">
			<div className="problems-page__m-table-performers-title">
				{t('problemsPage.mobileProblemCell.performers')}:
			</div>
			<div className="problems-page__m-table-performers-text">
				{performersActiveGroup && (
					<div className="problems-page__m-table-performers-group">
						{performersActiveGroup}
					</div>
				)}
				{performers?.[0] && (
					<div className="problems-page__m-table-performers-user">
						<span className="problems-page__m-table-performers-user-name">
							{performers[0]?.displayName}
						</span>
						<span className="problems-page__m-table-performers-user-company">
							{performers[0].companies?.[0]?.name}
						</span>
						{!!performers?.length && performers?.length > 1 && (
							<span className="problems-page__m-table-performers-rest">
								+{performers.length - 1}
							</span>
						)}
					</div>
				)}
			</div>
		</div>
	);

	const inspectorsEl = !!inspectors?.length && (
		<div className="problems-page__m-table-inspectors">
			<div className="problems-page__m-table-inspectors-title">
				{t('problemsPage.mobileProblemCell.inspectors')}:
			</div>
			<div className="prbolems-page__m-table-inspectors-text">
				{userGroup && (
					<div className="problems-page__m-table-inspectors-group">{userGroup.name}</div>
				)}
				{inspectors?.[0] && (
					<div className="problems-page__m-table-inspectors-user">
						<span className="problems-page__m-table-inspectors-user-name">
							{inspectors[0]?.displayName}
						</span>
						<span className="problems-page__m-table-inspectors-user-company">
							{inspectors[0].companies?.[0]?.name}
						</span>
						{!!inspectors?.length && inspectors?.length > 1 && (
							<span className="problems-page__m-table-inspectors-rest">
								+{inspectors.length - 1}
							</span>
						)}
					</div>
				)}
			</div>
		</div>
	);

	const localStatusEl = (
		<div className="problems-page__m-table-local-status">
			<LocalProblemStatus />
		</div>
	);

	const transferEl = (
		<div className="problems-page__m-table-transfer-status">
			<EntityLoadingStatus
				title={statusTitle}
				icon={statusIcon}
				color={statusColor}
			/>
		</div>
	);

	const objectEl = <div className="problems-page__m-table-object">{object?.name}</div>;

	const attachmentsEl = (
		<div className="problems-page__m-table-description-image">
			{attachment?.preview && (
				<PhotoPreview
					url={attachment.preview.url}
					data={0}
				/>
			)}
		</div>
	);

	const documentsCountEl = documentsCount && (
		<div className="problems-page__m-table-description-documents-count">
			<DocumentsCount count={documentsCount} />
		</div>
	);

	return (
		<div className="problems-page__mobile-cell-info">
			<ClickPreventWrap className="problems-page__mobile-cell-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()} />
			</ClickPreventWrap>
			<div>
				<div className="problems-page__m-table-description-header">
					{numberEl}
					{settings?.builtin.critical && criticalEl}
					{settings?.builtin.problemTags && problemTagsEl}
				</div>

				{settings?.builtin.description && descriptionEl}
				{settings?.builtin.plannedFixDate && plannedFixDateEl}
				{settings?.builtin.performers && performersEl}
				{settings?.builtin.inspectors && inspectorsEl}

				{localStatusEl}

				{documentsCountEl}

				{transferEl}
				{!!withObject && objectEl}
			</div>
			{settings?.builtin.attachments && attachmentsEl}
		</div>
	);
};

export default LocalMobileProblemCell;
