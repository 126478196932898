import React from 'react';
import {EntityInfo, EntityInfoDate} from '@tehzor/ui-components';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useProblemStatusesMap} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from 'react-i18next';

interface IProblemInfoProps {
	className?:
		| string
		| {
				root?: string;
				title?: string;
				subTitle?: string;
				icon?: string;
		  };
	style?: React.CSSProperties;
	data: IProblem;
	depth?: number;
	subTitle?: React.ReactNode;
	title?: string;
	withoutStatusIcon?: boolean;
	markers?: React.ReactElement;
}

const problemIcon = <i className="tz-problem-28"/>;

export const ProblemInfo = ({
	className,
	style,
	data,
	depth = 0,
	subTitle,
	markers,
	withoutStatusIcon
}: IProblemInfoProps) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();

	const {data: statusesMap} = useProblemStatusesMap();
	let iconProps = {};

	if (!withoutStatusIcon) {
		iconProps = {
			icon: problemIcon,
			iconColor: statusesMap && statusesMap[data.status]?.color,
			iconSize: depth > 0 ? 32 : 44
		};
	}

	return (
		<EntityInfo
			className={className}
			style={style}
			title={`${t('problemInfoCell.title')} №${data.number ?? data.localNumber}`}
			markers={markers}
			subTitle={
				subTitle ??
				(depth === 0 ? (
					<EntityInfoDate
						labelFrom={t('components.tableCell.info.entityInfoDateWithCountFiles.labelFrom')}
						locale={dateFnsLocale}
						value={data.createdAt}
					/>
				) : undefined)
			}
			{...iconProps}
		/>
	);
};
