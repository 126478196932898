import {io} from 'socket.io-client';
import {localAuthStorage} from './localAuthStorage';
import detectSocketTimeout from '../utils/detectSocketTimeout';
import EventEmitter from 'events';
import INotificationMessage from '../interfaces/INotificationMessage';
import {MissingTokenError} from '../errors/MissingTokenError';
import {MissingDeviceError} from '../errors/MissingDeviceError';
import {Socket} from 'socket.io-client/build/esm/socket';

type StoreNotificationMsgAction = (message: INotificationMessage) => void;

interface IParams {
	// eslint-disable-next-line @typescript-eslint/ban-types
	[key: string]: string | number | boolean | object | Blob | null | undefined;
}

/**
 * Базовый singleton класс для взаимодействия с backend'ом
 */
class BaseSocketConnector {
	// eslint-disable-next-line no-undef
	private _socket?: Socket;

	// private _stream?: any;

	private _store: any;

	private _storeNotificationMsgAction: StoreNotificationMsgAction;

	private _eventEmitter = new EventEmitter();

	/**
	 * Создает подключение к api
	 *
	 * @param {string} url адрес api
	 */
	connect = (url: string) => {
		this._socket = io(url, {
			path: '/api/main',
			transports: ['websocket']
		});
		// this._stream = ss(this._socket, {});
		this._socket.on('connect', this._onConnect);
		this._socket.on('disconnect', this._onDisconnect);
	};

	/**
	 * Инициализирует экземпляр класса
	 *
	 * @param store Redux store
	 * @param storeNotificationMsgAction
	 */
	initialize = (store: any, storeNotificationMsgAction: StoreNotificationMsgAction) => {
		if (!store || !storeNotificationMsgAction) {
			throw new Error("Params ('store', 'connStatusAction', 'storeNotificationMsgAction') are not found");
		}
		this._store = store;
		this._storeNotificationMsgAction = storeNotificationMsgAction;
	};

	/**
	 * Пожписывает на обновления о изменении статуса соединения
	 *
	 * @param {Listener} listener функция-обработчик
	 */
	// subscribeOnConnectionStatusChange = (listener: Listener) => {
	// 	this._eventEmitter.addListener('connectionStatusChange', listener);
	// };

	/**
	 * Отписывает от обновлений о изменении статуса соединения
	 *
	 * @param {Listener} listener функция-обработчик
	 */
	// unsubscribeOnConnectionStatusChange = (listener: Listener) => {
	// 	this._eventEmitter.removeListener('connectionStatusChange', listener);
	// };

	/**
	 * Авторизовывает пользователя
	 *
	 * @param {string} appId id приложения
	 * @param {string} appSecret ключ приложения
	 * @param {string} email email учётной записи
	 * @param {string} password пароль
	 * @returns {Promise}
	 */
	// getTokens = async (appId: string, appSecret: string, email: string, password: string) => {
	// 	const params = {
	// 		appId,
	// 		appSecret,
	// 		email,
	// 		password
	// 	};
	// 	try {
	// 		const response = await this._sendRequest('getTokens', params);
	// 		await localAuthStorage.setAccessToken(response.accessToken);
	// 		await localAuthStorage.setRefreshToken(response.refreshToken);
	// 		return response;
	// 	} catch (error) {
	// 		await localAuthStorage.deleteTokens();
	// 		return Promise.reject(error);
	// 	}
	// };

	/**
	 * Обновляет токены доступа
	 *
	 * @param {string} appId id приложения
	 * @param {string} appSecret ключ приложения
	 * @return {Promise}
	 */
	// updateTokens = async (appId: string, appSecret: string) => {
	// 	const refreshToken = await localAuthStorage.getRefreshToken();
	// 	if (!refreshToken) {
	// 		return Promise.reject({
	// 			code: 650,
	// 			error: 'EmptyRefreshToken',
	// 			message: 'refreshToken is empty'
	// 		});
	// 	}
	//
	// 	const params = {appId, appSecret, refreshToken};
	// 	try {
	// 		const response = await this._sendRequest('updateTokens', params);
	// 		await localAuthStorage.setAccessToken(response.accessToken);
	// 		await localAuthStorage.setRefreshToken(response.refreshToken);
	// 		return response;
	// 	} catch (error) {
	// 		await localAuthStorage.deleteTokens();
	// 		return Promise.reject(error);
	// 	}
	// };

	/**
	 * Удаляет выданные ранее токены
	 *
	 * @return {Promise}
	 */
	// deleteTokens = async () => {
	// 	await this._sendAuthorizedRequest('deleteTokens');
	// 	await localAuthStorage.deleteTokens();
	// };

	/**
	 * Получает данные текущего пользователя
	 *
	 * @return {Promise}
	 */
	// getCurrentUser = () => this._sendAuthorizedRequest('getCurrentUser');

	/**
	 * Изменяет информацию о текущем пользователе
	 *
	 * @param {string} email email пользователя
	 * @param {string} fullName ФИО
	 * @param {string} position должность
	 * @param {string} password новый пароль
	 * @return {Promise}
	 */
	// updateCurrentUser = (email: string, fullName: string, position: string, password: string) => {
	// 	const params: IParams = {};
	// 	if (email) {
	// 		params.email = email;
	// 	}
	// 	if (fullName) {
	// 		params.fullName = fullName;
	// 	}
	// 	if (position) {
	// 		params.position = position;
	// 	}
	// 	if (password) {
	// 		params.password = password;
	// 	}
	// 	return this._sendAuthorizedRequest('updateCurrentUser', params);
	// };

	/**
	 * Получает список объектов
	 *
	 * @returns {Promise}
	 */
	// getObjects = () => this._sendAuthorizedRequest('getObjects');

	/**
	 * Получает объект
	 *
	 * @param {string} objectId id объекта
	 * @returns {Promise}
	 */
	// getObject = (objectId: string) => {
	// 	const params = {objectId};
	// 	return this._sendAuthorizedRequest('getObject', params);
	// };

	/**
	 * Добавляет объект
	 *
	 * @param {ISavingObject} fields поля объекта
	 * @returns {Promise}
	 */
	// addObject = (fields: ISavingObject) => this._sendAuthorizedRequest('addObject', fields as IParams);

	/**
	 * Изменяет объект
	 *
	 * @param {string} objectId id объекта
	 * @param {ISavingObject} fields поля объекта
	 * @returns {Promise}
	 */
	// editObject = (objectId: string, fields: ISavingObject) => {
	// 	const params = {
	// 		...fields,
	// 		objectId
	// 	};
	// 	return this._sendAuthorizedRequest('editObject', params);
	// };

	/**
	 * Удаляет объект
	 *
	 * @param {string} objectId id объекта
	 * @returns {Promise}
	 */
	// deleteObject = (objectId: string) => {
	// 	const params = {objectId};
	// 	return this._sendAuthorizedRequest('deleteObject', params);
	// };

	/**
	 * Получает список категорий
	 *
	 * @param {string} objectId id объекта
	 * @return {Promise}
	 */
	// getCategories = (objectId: string) => {
	// 	const params = {objectId};
	// 	return this._sendAuthorizedRequest('getCategories', params);
	// };

	/**
	 * Добавляет категорию
	 *
	 * @param {string} objectId id объекта
	 * @param {string} name название
	 * @param {string|null|undefined} parentId id родительской категории
	 * @returns {Promise}
	 */
	// addCategory = (objectId: string, name: string, parentId?: string | null) => {
	// 	const params = {
	// 		objectId,
	// 		name,
	// 		parentId
	// 	};
	// 	return this._sendAuthorizedRequest('addCategory', params);
	// };

	/**
	 * Изменяет категорию
	 *
	 * @param {string} objectId id объекта
	 * @param {string} categoryId id категории
	 * @param {string|undefined} name название
	 * @param {string|null|undefined} parentId id родительской категории
	 * @returns {Promise}
	 */
	// editCategory = (objectId: string, categoryId: string, name?: string, parentId?: string | null) => {
	// 	const params = {
	// 		objectId,
	// 		categoryId
	// 	} as IParams;
	// 	if (name) {
	// 		params.name = name;
	// 	}
	// 	if (parentId) {
	// 		params.parentId = parentId;
	// 	}
	// 	return this._sendAuthorizedRequest('editCategory', params);
	// };

	/**
	 * Изменяет порядок расположения элементов
	 *
	 * @param {string} objectId id объекта
	 * @param {string[]} orders массив порядков сортировки
	 * @returns {Promise}
	 */
	// editCategoriesOrder = (objectId: string, orders: string[]) => {
	// 	const params = {
	// 		objectId,
	// 		orders
	// 	};
	// 	return this._sendAuthorizedRequest('editCategoriesOrder', params);
	// };

	/**
	 * Удаляет категорию
	 *
	 * @param {string} objectId id объекта
	 * @param {string} categoryId id категории
	 * @returns {Promise}
	 */
	// deleteCategory = (objectId: string, categoryId: string) => {
	// 	const params = {
	// 		objectId,
	// 		categoryId
	// 	};
	// 	return this._sendAuthorizedRequest('deleteCategory', params);
	// };

	/**
	 * Получает список планов
	 *
	 * @param {String} objectId id объекта
	 * @return {Promise}
	 */
	// getPlans = (objectId: string) => {
	// 	const params = {objectId};
	// 	return this._sendAuthorizedRequest('getPlans', params);
	// };

	/**
	 * Добавляет план
	 *
	 * @param {String} objectId id объекта
	 * @param {String} name название
	 * @param {File} image изображение
	 * @param {number[]} floors этажи
	 * @param {ILayer[]} layers слои с фигурами
	 * @returns {Promise}
	 */
	// addPlan = (objectId: string, name: string, image: {id: string}, floors: number[], layers: ILayer[]) => {
	// 	const params = {
	// 		objectId,
	// 		name,
	// 		image
	// 	} as IParams;
	// 	if (floors) {
	// 		params.floors = floors;
	// 	}
	// 	if (layers) {
	// 		params.layers = layers;
	// 	}
	// 	return this._sendAuthorizedRequest('addPlan', params);
	// };

	/**
	 * Изменяет план
	 *
	 * @param {String} objectId id объекта
	 * @param {String} planId id плана
	 * @param {String} name название
	 * @param {File} image изображение
	 * @param {Array} floors этажи
	 * @param {Array} layers слои с фигурами
	 * @returns {Promise}
	 */
	// editPlan = (
	// 	objectId: string,
	// 	planId: string,
	// 	name: string,
	// 	image: {id: string},
	// 	floors: number[],
	// 	layers: ILayer[]
	// ) => {
	// 	const params = {
	// 		objectId,
	// 		planId
	// 	} as IParams;
	// 	if (name) {
	// 		params.name = name;
	// 	}
	// 	if (image) {
	// 		params.image = image;
	// 	}
	// 	if (floors) {
	// 		params.floors = floors;
	// 	}
	// 	if (layers) {
	// 		params.layers = layers;
	// 	}
	// 	return this._sendAuthorizedRequest('editPlan', params);
	// };

	/**
	 * Изменяет порядок расположения элементов
	 *
	 * @param {String} objectId id объекта
	 * @param {Array} orders массив порядков сортировки
	 * @returns {Promise}
	 */
	// editPlansOrder = (objectId: string, orders: string[]) => {
	// 	const params = {
	// 		objectId,
	// 		orders
	// 	};
	// 	return this._sendAuthorizedRequest('editPlansOrder', params);
	// };

	/**
	 * Удаляет план
	 *
	 * @param {String} objectId id объекта
	 * @param {String} planId id плана
	 * @returns {Promise}
	 */
	// deletePlan = (objectId: string, planId: string) => {
	// 	const params = {
	// 		objectId,
	// 		planId
	// 	};
	// 	return this._sendAuthorizedRequest('deletePlan', params);
	// };

	/**
	 * Подписывается на получение сообщений-уведомлений
	 *
	 * @return {Promise}
	 */
	subscribeOnNotifications = async () => {
		await this._sendAuthorizedRequest('subscribeOnNotifications');
		this._subscribe('notificationMessageAdded', this._storeNotificationMessage, true);
	};

	/**
	 * Отписывается от получения сообщений-уведомлений
	 *
	 * @return {Promise}
	 */
	unsubscribeFromNotifications = async () => {
		this._unsubscribe('notificationMessageAdded', this._storeNotificationMessage);
		await this._sendSimpleRequest('unsubscribeFromNotifications');
	};

	/**
	 * Получает список непрочитанных сообщений
	 *
	 * @param {number} offset индекс начального элемента
	 * @param {number} limit количество элементов
	 * @returns {Promise}
	 */
	getNotificationMessages = (offset: number, limit: number) => {
		const params: {offset?: number, limit?: number} = {};
		if (offset) {
			params.offset = offset;
		}
		if (limit) {
			params.limit = limit;
		}
		return this._sendAuthorizedRequest('getNotificationMessages', params);
	};

	/**
	 * Помечает сообщение как прочитанное
	 *
	 * @param {string} id id сообщения
	 * @returns {*}
	 */
	deleteNotificationMessage = (id: string) => {
		const params = {id};
		return this._sendAuthorizedRequest('deleteNotificationMessage', params);
	};

	/**
	 * Помечает все сообщения как прочитанные
	 *
	 * @returns {*}
	 */
	deleteAllNotificationMessages = () => this._sendAuthorizedRequest('deleteAllNotificationMessages');

	/**
	 * Получает типы уведомлений
	 *
	 * @returns {*}
	 */
	getNotificationTypes = () => this._sendAuthorizedRequest('getNotificationTypes');

	/**
	 * Получает каналы рассылки уведомлений
	 *
	 * @returns {*}
	 */
	getNotificationChannels = () => this._sendAuthorizedRequest('getNotificationChannels');

	/**
	 * Получает подписки на уведомления
	 *
	 * @returns {*}
	 */
	getNotificationSubscriptions = () => this._sendAuthorizedRequest('getNotificationSubscriptions');

	/**
	 * Измененяет подписки на уведомления
	 *
	 * @param {Array} subscriptions подписки
	 * @returns {*}
	 */
	editNotificationSubscriptions = (subscriptions: any) => {
		const params = {
			subscriptions
		};
		return this._sendAuthorizedRequest('editNotificationSubscriptions', params);
	};

	/**
	 * Получает интервалы уведомлений
	 *
	 * @returns {*}
	 */
	getNotificationIntervals = () => this._sendAuthorizedRequest('getNotificationIntervals');

	/**
	 * Получает периоды уведомлений
	 *
	 * @returns {*}
	 */
	getNotificationPeriods = () => this._sendAuthorizedRequest('getNotificationPeriods');

	/**
	 * Получает публичный ключ для шифрования push-уведомлений
	 *
	 * @returns {*}
	 */
	getWebPushPublicKey = () => this._sendAuthorizedRequest('getWebPushPublicKey');

	/**
	 * Добавляет или изменяет подписку на web push-уведомления
	 *
	 * @param {PushSubscription} subscription объект подписки
	 * @param {string} fingerprint fingerprint подписываемого устройства
	 */
	updateWebPushSubscription = async (subscription: PushSubscription, fingerprint: string) => {
		const deviceId = await localAuthStorage.getDeviceId();

		const params = {subscription, fingerprint, deviceId};
		return this._sendAuthorizedRequest('updateWebPushSubscription', params);
	};

	/**
	 * Удаляет подписку на web push-уведомления
	 *
	 * @param {string} deviceId id подписываемого устройства
	 * @returns {*}
	 */
	deleteWebPushSubscription = (deviceId: string) => {
		const params = {deviceId};
		return this._sendAuthorizedRequest('deleteWebPushSubscription', params);
	};

	/**
	 * Отправляет запрос через WebSocket и ожидает данные в ответ
	 *
	 * @param {string} methodName название метода
	 * @param {Object} params параметры запроса
	 * @return {Promise}
	 * @protected
	 */
	protected _sendRequest = <R = any>(methodName: string, params: IParams = {}): Promise<R> =>
		new Promise((resolve, reject) => {
			if (!this._socket) {
				reject({
					code: 600,
					error: 'NoActiveConnection',
					message: 'No active connection'
				});
			} else {
				this._socket.emit(
					methodName,
					params,
					detectSocketTimeout(data => {
						if (data && data.error) {
							reject(data);
						}
						resolve(data);
					})
				);
			}
		});

	/**
	 * Отправляет запрос, прикрепляя к нему токен авторизации
	 *
	 * @param {string} methodName название метода
	 * @param {IParams} params параметры запроса
	 * @return {Promise}
	 * @protected
	 */
	protected _sendAuthorizedRequest = async <R>(methodName: string, params: IParams = {}): Promise<R> => {
		const accessToken = await localAuthStorage.getAccessToken();
		const deviceId = await localAuthStorage.getDeviceId();

		if (!accessToken) { throw new MissingTokenError(); }
		if (!deviceId) { throw new MissingDeviceError(); }

		params.accessToken = accessToken;
		params.deviceId = deviceId;

		return this._sendRequest<R>(methodName, params);
	};

	/**
	 * Отправляет простой запрос через WebSocket без ожидания данных в ответ
	 *
	 * @param {string} methodName название метода
	 * @param {IParams} params параметры запроса
	 * @protected
	 */
	protected _sendSimpleRequest = async (methodName: string, params: IParams = {}): Promise<void> => {
		if (!this._socket) {
			return Promise.reject({
				code: 600,
				error: 'NoActiveConnection',
				message: 'No active connection'
			});
		}
		this._socket.emit(methodName, params);
	};

	/**
	 * Отправляет простой запрос, прикрепляя к нему токен авторизации
	 *
	 * @param {String} methodName название метода
	 * @param {Object} params параметры запроса
	 * @return {Promise}
	 * @protected
	 */
	protected _sendAuthorizedSimpleRequest = async (methodName: string, params: IParams = {}): Promise<void> => {
		const accessToken = await localAuthStorage.getAccessToken();
		if (!accessToken) {
			throw new MissingTokenError();
		}
		params.accessToken = accessToken;
		await this._sendSimpleRequest(methodName, params);
	};

	/**
	 * Отправляет файл
	 *
	 * @param {string} methodName название метода
	 * @param {File} file файл
	 * @param {Object} params параметры запроса
	 * @param {Function} onInitialized функция, вызываемая после инициализации запроса,
	 * первым аргументом передаётся abort-функция
	 * @param {Function} onProgress функция, вызываемая при обновлении процесса выгрузки
	 * @return {Promise}
	 * @protected
	 */
	// protected _sendFileRequest = (
	// 	methodName: string,
	// 	file: File,
	// 	params: IParams = {},
	// 	onInitialized?: (abort: () => void) => void,
	// 	onProgress: (value: number) => void = () => null
	// ) => {
	// 	if (!this._socket) {
	// 		return Promise.reject({
	// 			code: 600,
	// 			error: 'NoActiveConnection',
	// 			message: 'No active connection'
	// 		});
	// 	}
	//
	// 	return new Promise((resolve, reject) => {
	// 		let ended = false;
	// 		const totalSize = file.size;
	// 		let uploadedSize = 0;
	//
	// 		const handleStreamError = (error: Error) => {
	// 			if (ended) {
	// 				return;
	// 			}
	// 			ended = true;
	// 			reject({
	// 				code: 254,
	// 				error: 'StreamError',
	// 				message: error.message
	// 			});
	// 		};
	//
	// 		const socketStream = ss.createStream().on('error', handleStreamError);
	// 		const blobStream = ss
	// 			.createBlobReadStream(file, {highWaterMark: 524288}) // 512 kb
	// 			.on('data', (chunk: any) => {
	// 				uploadedSize += chunk.length;
	// 				onProgress(((uploadedSize / totalSize) * 100) | 0);
	// 			})
	// 			.on('error', handleStreamError);
	//
	// 		this._stream.emit(
	// 			methodName,
	// 			socketStream,
	// 			params,
	// 			detectSocketTimeout(data => {
	// 				if (data && data.error && !ended) {
	// 					ended = true;
	// 					reject(data);
	// 				}
	// 				resolve(data);
	// 			}, 90)
	// 		);
	//
	// 		blobStream.pipe(socketStream);
	//
	// 		if (onInitialized && typeof onInitialized === 'function') {
	// 			onInitialized(() => {
	// 				const error = {
	// 					code: 920,
	// 					error: 'RequestAborted',
	// 					message: 'Request has been aborted by user'
	// 				};
	// 				blobStream.destroy(error);
	// 				socketStream.destroy(error);
	// 				blobStream.unpipe(socketStream);
	//
	// 				if (ended) {
	// 					return;
	// 				}
	// 				ended = true;
	// 				reject(error);
	// 			});
	// 		}
	// 	});
	// };

	/**
	 * Отправляет файл вместе с токенами авторизации
	 *
	 * @param {string} methodName название метода
	 * @param {File} file файл
	 * @param {IParams} params параметры запроса
	 * @param {Function} onInitialized функция, вызываемая после инициализации запроса,
	 * первым аргументом передаётся abort-функция
	 * @param {Function} onProgress функция, вызываемая при обновлении процесса выгрузки
	 * @return {Promise}
	 * @protected
	 */
	// protected _sendAuthorizedFileRequest = (
	// 	methodName: string,
	// 	file: File,
	// 	params: IParams = {},
	// 	onInitialized?: (abort: () => void) => void,
	// 	onProgress: (value: number) => void = () => null
	// ) =>
	// 	storage.getProp('accessToken').then(accessToken => {
	// 		if (!accessToken) {
	// 			return Promise.reject({
	// 				code: 650,
	// 				error: 'EmptyAccessToken',
	// 				message: 'accessToken is empty'
	// 			});
	// 		}
	// 		params.accessToken = accessToken;
	// 		return this._sendFileRequest(methodName, file, params, onInitialized, onProgress);
	// 	});

	/**
	 * Отправляет запрос на приём файла
	 *
	 * @param {string} methodName имя метода сокета
	 * @param {IParams} params параметры
	 * @return {Promise}
	 * @protected
	 */
	// protected _getFileRequest = (methodName: string, params: IParams = {}) => {
	// 	if (!this._stream) {
	// 		return Promise.reject({
	// 			code: 600,
	// 			error: 'NoActiveConnection',
	// 			message: 'No active connection'
	// 		});
	// 	}
	// 	return new Promise((resolve, reject) => {
	// 		const stream = ss.createStream();
	// 		this._stream.emit(
	// 			methodName,
	// 			stream,
	// 			params,
	// 			detectSocketTimeout(data => {
	// 				if (data && data.error) {
	// 					reject(data);
	// 				}
	// 				const fileBuffer: any[] = [];
	// 				stream
	// 					.on('data', (chunk: any) => {
	// 						fileBuffer.push(chunk);
	// 					})
	// 					.on('end', () => {
	// 						resolve(fileBuffer);
	// 					});
	// 			}, 90)
	// 		);
	// 	});
	// };

	/**
	 * Отправляет авторизованный запрос на приём файла
	 *
	 * @param {string} methodName имя метода сокета
	 * @param {IParams} params параметры
	 * @return {Promise}
	 * @protected
	 */
	// protected _getAuthorizedFileRequest = (methodName: string, params: IParams = {}) =>
	// 	storage.getProp('accessToken').then(accessToken => {
	// 		if (!accessToken) {
	// 			return Promise.reject({
	// 				code: 650,
	// 				error: 'EmptyAccessToken',
	// 				message: 'accessToken is empty'
	// 			});
	// 		}
	// 		params.accessToken = accessToken;
	// 		return this._getFileRequest(methodName, params);
	// 	});

	/**
	 * Подписывается на WebSocket событие
	 *
	 * @param {string} eventName название события
	 * @param {Function} callback callback
	 * @param unique
	 * @protected
	 */
	protected _subscribe = (eventName: string, callback: (...args: any[]) => void, unique = false) => {
		if (this._socket && (!unique || (unique && !this._socket.hasListeners(eventName)))) {
			this._socket.on(eventName, callback);
		}
	};

	/**
	 * Отписывается от WebSocket события
	 *
	 * @param {string} eventName название события
	 * @param {Function} callback callback
	 * @protected
	 */
	protected _unsubscribe = (eventName: string, callback: (...args: any[]) => void) => {
		if (this._socket) {
			this._socket.off(eventName, callback);
		}
	};

	/**
	 * Обработывает событие при соединении с api
	 *
	 * @private
	 */
	private _onConnect = () => {
		this._eventEmitter.emit('connectionStatusChange', true);
	};

	/**
	 * Обработывает событие при отключении от api
	 *
	 * @param {string} reason причина отключения
	 * @private
	 */
	private _onDisconnect = (reason: string) => {
		this._eventEmitter.emit('connectionStatusChange', false);

		if (reason === 'io server disconnect' && this._socket) {
			this._socket.connect();
		}
	};

	/**
	 * Сохраняет в store сообщение-уведомление
	 *
	 * @param {INotificationMessage} message сообщение
	 * @private
	 */
	private _storeNotificationMessage = (message: INotificationMessage) => {
		if (message && typeof message === 'object') {
			this._store.dispatch(this._storeNotificationMsgAction(message));
		}
	};
}

export default BaseSocketConnector;
