import { useCallback } from 'react';
import {InternalAcceptancesPage} from './InternalAcceptancesPage';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import ActionsMobile from './components/actions/Actions.mobile';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/interfaces';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {internalAcceptancesActions} from '@src/store/modules/settings/pages/internalAcceptances/slice';

const InternalAcceptancesPageWrap = () => {
	const dispatch = useAppDispatch();
	const {clearFilters, changeFilters, changeOffset} = internalAcceptancesActions;
	const {objectId} = useStrictParams<{objectId: string}>();
	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {
		sectionsMenu,
		mobileRightButtons: <ActionsMobile objectId={objectId} />
	});

	const {filters} = useAppSelector(s => extractInternalAcceptancesPageSettings(s, objectId));

	const applyFilters = useCallback(
		(value: IInternalAcceptancesFiltersState) => {
			dispatch(changeFilters({objectId, filters: value}));
			dispatch(changeOffset({objectId: objectId || 'all', offset: 0}));
		},
		[dispatch, changeFilters, objectId, changeOffset]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId}));
	}, [clearFilters, dispatch, objectId]);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			objectId={objectId}
			onClear={onClear}
		>
			<InternalAcceptancesPage key={objectId} />
		</EntitiesFiltersProvider>
	);
};

export default InternalAcceptancesPageWrap;
