import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';

export type IGetSpaceTypeDecorationResponse = INormalizedData<ISpaceTypeDecoration>;

/**
 * Возвращает нормализованный список типов отделки помещений
 *
 * @param typeDecorationSetId id набора типов отделки помещений
 */
export const makeSpaceTypeDecorationGetRequest = async (typeDecorationSetId: string) => {
	const response = await httpRequests.withToken.get<IGetSpaceTypeDecorationResponse>(
		`space-type-decoration/${typeDecorationSetId}`
	);
	return response.data;
};
/**
 * Возвращает нормализованный список всех типов отделки помещений
 */
export const makeSpaceTypeDecorationsGetAllRequest = async () => {
	const response = await httpRequests.withToken.get<IGetSpaceTypeDecorationResponse>(
		'space-type-decoration'
	);
	return response.data;
};
