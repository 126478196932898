import * as React from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useUpdateContent} from '@src/components/EditableAuthorizedPerson/hooks/useUpdateContent';

export function useAuthorizedPersonDelete(
	authorizedPersonId: string
): [React.ReactNode, () => void] {
	const {removeAuthorizedPerson} = useUpdateContent({});

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить уполномоченное лицо',
		'Вы действительно хотите удалить уполномоченное лицо?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await removeAuthorizedPerson(authorizedPersonId);
		}
	}, [authorizedPersonId]);

	return [deleteDialog, handleDelete];
}
