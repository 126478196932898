import {IGetCheckItemsResponse} from '@src/api/backend/checkItems';
import arrayToTree from 'array-to-tree';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';
import {lookupParents} from '../checkLists/utils/lookupParents';

/**
 * Возвращает категории чек-листов в виде массива включая архивные (soft-deleted)
 */
const extractAllCheckItems = (data: IGetCheckItemsResponse) => data.allIds.map(id => data.byId[id]);

/**
 * Возвращает категории чек-листов в виде массива, за исключением архивных (soft-deleted)
 */
const extractNonDeletedCheckItems = (data: IGetCheckItemsResponse) =>
	extractAllCheckItems(data).filter(item => !item.deleted);

/**
 * Возвращает категории виде массива для чеклиста, за исключением архивных (soft-deleted)
 */
export const extractCheckItems = (data: IGetCheckItemsResponse, checkListId?: string) =>
	extractNonDeletedCheckItems(data).filter(item => item.checkListId === checkListId);

/**
 * Возвращает категории в виде массива для чеклиста, включая архивных (soft-deleted)
 */
export const extractCheckItemsIncludingDeleted = (
	data: IGetCheckItemsResponse,
	checkListId?: string
) => extractAllCheckItems(data).filter(item => item.checkListId === checkListId);

export const extractCheckItemsByListIdsAsArray = (
	data: IGetCheckItemsResponse,
	checkListIds?: string[]
) => extractNonDeletedCheckItems(data).filter(item => checkListIds?.includes(item.checkListId));

/**
 * Возвращает категории верхнего уровня чек-листа в виде массива
 */
export const extractRootCheckItems = (data: IGetCheckItemsResponse, checkListId?: string) =>
	extractCheckItems(data, checkListId).filter(item => item.parentId === undefined);

/**
 * Возвращает категории чек-листа в виде дерева
 */
export const extractCheckItemsAsTree = (data: IGetCheckItemsResponse, checkListId?: string) => {
	const checkItems = extractCheckItems(data, checkListId);
	return arrayToTree<ICheckItem>(checkItems, {
		parentProperty: 'parentId',
		customID: 'id'
	});
};

/**
 * Возвращает категорию чек-листа по id
 */
export const extractCheckItemById = (data: IGetCheckItemsResponse, checkItemId?: string) =>
	checkItemId ? data.byId[checkItemId] : undefined;

/**
 * Возвращает категорию чек-листа по id в виде элемента дерева
 */
export const extractTreeCheckItemById = (
	data: IGetCheckItemsResponse,
	checkListId?: string,
	checkItemId?: string
) => {
	const checkItemsTree = extractCheckItemsAsTree(data, checkListId);
	return checkItemId ? findTreeNode<ICheckItem>(checkItemsTree, checkItemId) : undefined;
};

/**
 * Возвращает категории, сгруппированные по id чек-листов
 */
export const extractCheckItemsGroupedByLists = (data: IGetCheckItemsResponse) => {
	const checkItems = extractNonDeletedCheckItems(data);
	return checkItems.reduce<Record<string, ICheckItem[]>>((prev, item) => {
		if (!prev[item.checkListId]) {
			prev[item.checkListId] = [];
		}
		prev[item.checkListId].push(item);
		return prev;
	}, {});
};

export const getCurrentCheckItemIndex = (data: IGetCheckItemsResponse, current?: ICheckItem) => {
	const checkItemsTree = extractCheckItemsAsTree(data, current?.checkListId);
	let index;

	if (current?.parentId) {
		index = lookupParents(checkItemsTree, current.id, current.parentId);
	} else {
		index = checkItemsTree.findIndex(item => item.id === current?.id);

		if (index >= 0) {
			index += 1;
		}
	}

	return index !== undefined ? index.toString() : undefined;
};

export const extractIndexedCheckItems = (data: IGetCheckItemsResponse, checkListId?: string) => {
	const items = extractCheckItems(data, checkListId);
	const indexedItems = items.map(item => {
		const index = getCurrentCheckItemIndex(data, item);
		return {...item, currentIndex: index};
	});
	return indexedItems;
};

export const getSiblings = (data: IGetCheckItemsResponse, checkItem?: ICheckItem) => {
	if (!checkItem) {
		return [];
	}

	if (!checkItem.parentId) {
		return extractCheckItemsAsTree(data, checkItem?.checkListId);
	}

	const parent = extractTreeCheckItemById(data, checkItem.checkListId, checkItem.parentId);

	return parent!.children || [];
};
