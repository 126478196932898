import {queryClient} from '@src/api/QueryClient';
import {bankAccountsQueryKeys} from '@src/api/cache/bankAccounts/keys';
import {makeBankAccountDeleteRequest} from '@src/api/backend/bankAccount';
import {addErrorToast} from '@src/utils/toasts';

export const useDeleteBankAccount = () => async (bankAccountId: string) => {
	try {
		await makeBankAccountDeleteRequest(bankAccountId);
		void queryClient.invalidateQueries({queryKey: bankAccountsQueryKeys.list()});
		void queryClient.refetchQueries({queryKey: bankAccountsQueryKeys.list()});
	} catch (e) {
		addErrorToast('Реквизиты', 'не удалось удалить сущность');
	}
};
