import {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useWarrantyClaimStatusesArray} from '@src/core/hooks/queries/warrantyClaimsStatuses/hooks';
import {useTranslation} from 'react-i18next';

interface IWClaimStatusesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const WClaimStatusesFilterMobile = memo((props: IWClaimStatusesFilterMobileProps) => {
	const {t} = useTranslation();
	const {data: statuses} = useWarrantyClaimStatusesArray();

	if (!statuses) return null;

	return (
		<BaseListFilterMobile
			{...props}
			options={statuses}
			label={t('entitiesFilters.statusesFilter.label')}
			filterName="statuses"
		/>
	);
});
