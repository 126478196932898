import { useCallback, useState } from 'react';
import * as React from 'react';
import {AddingSpaceTypeDecorationDialog} from '../AddingSpaceTypeDecorationDialog';

export const useAddingSpaceTypeDecorationDialog = (): [React.ReactNode, () => void] => {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<AddingSpaceTypeDecorationDialog
			isOpen={isOpen}
			close={close}
		/>
);

	return [dialog, open];
};
