import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {AddButton} from './AddButton';
import {useSpaceTypeDecorationPermissions} from '@src/core/hooks/permissions/useSpaceTypeDecorationPermissions';
import {useManageSpaceTypeDecorationBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageSpaceTypeDecorationBreadcrumbs';

interface IPageBreadrumbsProps {
	isDesktop?: boolean;
}

export const PageBreadcrumbs = ({isDesktop}: IPageBreadrumbsProps) => {
	const breadcrumbs = useManageSpaceTypeDecorationBreadcrumbs();
	const permissions = useSpaceTypeDecorationPermissions();

	return (
		<AppBreadcrumbs
			className="manage-space-type-decoration-page__breadcrumbs"
			items={breadcrumbs}
		>
			{permissions.canAdd && isDesktop ? <AddButton/> : null}
		</AppBreadcrumbs>
	);
};
