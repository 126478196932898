import {useObjectsMap} from '@src/core/hooks/queries/objects/hooks';
import {IPreparedSpaceStatusesSet} from '@src/pages/manage/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {CellProps} from 'react-table';

export const ObjectsCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) => {
	const objectsMap = useObjectsMap();

	const isTablet = useIsTablet();
	const numberOfShownObjects = isTablet ? 3 : 2;

	const objects = row.original.objects || [];
	const shownObjects = objects.filter((objectId, i) => i + 1 <= numberOfShownObjects);
	const numberOfRemainingObjects =
		objects.length > numberOfShownObjects ? objects.length - numberOfShownObjects : undefined;

	if (!objectsMap) return null;

	return (
		<div className="space-statuses-sets__cell-objects">
			{shownObjects.map(objectId => (
				<div
					className="space-statuses-sets__cell-objects-text"
					key={objectId}
				>
					{objectsMap[objectId]?.name}
					<br />
				</div>
			))}
			{numberOfRemainingObjects && (
				<div className="space-statuses-sets__cell-objects-number">
					+{numberOfRemainingObjects}
				</div>
			)}
		</div>
	);
};
