import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {LinkButton} from '@tehzor/ui-components';
import { useCallback } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {getProblem} from '@src/store/modules/pages/problem/actions';
import {formProblemLink} from '@tehzor/tools/utils/links';
import {useTranslation} from 'react-i18next';

export const ProblemLink = ({problemId, objectId}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const problem = useAppSelector(s => s.pages.problem.data);
	const {t} = useTranslation();

	useAsync(async () => {
		await Promise.all([dispatch(getProblem(objectId, problemId))]);
	}, [problemId, objectId]);

	const handleClick = useCallback(() => {
		pushPath(formProblemLink(objectId, problemId));
	}, [problemId, objectId]);

	if (!problem) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${t('components.entityLinks.problemLink.label')} №${problem.number}`}
			leftIcon={<i className="tz-external-link-16" />}
			onClick={handleClick}
			type="filled"
		/>
	);
};
