import './CategoriesSetMigrationPage.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {Desktop} from './components/Desktop';

export const CategoriesSetMigrationPage = () => {
	useScrollRestoration();

	useAppHeader(
		{
			title: 'Миграция видов работ',
			showBackBtn: true
		},
		[]
	);

	return (
		<div className="page-cont categories-set-migration-page">
			<Desktop/>
		</div>
	);
};
