import {IGetProblemViewsResponse} from '@src/api/backend/problemViews';
import {orderBy} from 'lodash';

export const extractProblemViewsAsArray = (data: IGetProblemViewsResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

export const extractSortedProblemViewsAsArray = (data: IGetProblemViewsResponse) => {
	const views = extractProblemViewsAsArray(data);
	return orderBy(views, ['createdAt'], ['desc']);
};
