import {queryClient} from '@src/api/QueryClient';
import {IGetCategoriesResponse, requestAddCategories} from '@src/api/backend/categories';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';

export const useAddCategory = () =>
	useMutation<ICategory, IError, ISavingCategory>({
		mutationFn: (params: ISavingCategory) => requestAddCategories(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetCategoriesResponse>(
				categoriesQueryKeys.list(),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return {
						byId: {[data.id]: data},
						allIds: [data.id]
					};
				}
			);
			addSuccessToast('Успех', 'Вид работ добавлен');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении вида работ');
		}
	});
