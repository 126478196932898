import {useCallback, useState} from 'react';
import * as React from 'react';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import {useExportCheck} from '@src/core/hooks/mutations/checks/useExportCheck';

const availableDestinations = [ExportTemplatesDestinationId.CHECK];

export function useCheckExport(objectId: string): [React.ReactNode, (id: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const [isOpen, setOpen] = useState<boolean>(false);
	const {mutateAsync: exportCheck} = useExportCheck();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (exportingId) {
				await exportCheck({
					templateId: template.id,
					createDocument,
					email,
					objectId,
					checkId: exportingId,
					problems: undefined,
					inspections: undefined
				});
			}
			setOpen(false);
		},
		[objectId, exportingId, exportCheck]
	);

	const dialog = (
		<ExportDialog
			pageKey="report"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
