import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

export type SpaceEntitiesVisibility = 'problems' | 'inspections';

export const getInitialState = (): SpaceEntitiesVisibility[] => ['problems', 'inspections'];

export default createReducer<SpaceEntitiesVisibility[]>(getInitialState(), {
	[types.CHANGE_ENTITIES_VISIBILITY]: (state, {payload}: {payload: SpaceEntitiesVisibility[]}) =>
		payload
});
