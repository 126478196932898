import {useQueries, useQuery, UseQueryResult} from '@tanstack/react-query';
import {IGetSpaceOwnersResponse} from '@src/api/backend/spaceOwners';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractLocalSpaceOwnersAsMap,
	extractLocalSpaceOwnersForSpace,
	extractSpaceOwnersAsArray,
	extractSpaceOwnersAsMap,
	extractSpaceOwnersForCompany,
	extractSpaceOwnersForSpace
} from './selectors';
import {useCallback} from 'react';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import useAppSelector from '../../useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';

export const useSpaceOwners = <T = IGetSpaceOwnersResponse>(
	select?: (data: IGetSpaceOwnersResponse) => T
) =>
	useQuery<IGetSpaceOwnersResponse, IError, T>({
		queryKey: spaceOwnersQueryKeys.list(),
		meta: {
			error: 'при загрузке собственников'
		},
		select
	});

export const useLocalSpaceOwners = <T = ISpaceOwner[]>(select?: (data: ISpaceOwner[]) => T) => {
	const offlineModeIsAvailable = useAppSelector(extractOfflineModeAvailability);
	return useQuery({
		queryKey: spaceOwnersQueryKeys.localList(),
		networkMode: 'offlineFirst',
		staleTime: 0,
		gcTime: Infinity,
		enabled: offlineModeIsAvailable,
		select
	});
};

export const useSpaceOwnersAsArray = () => useSpaceOwners(extractSpaceOwnersAsArray);
export const useSpaceOwnersAsMap = () => useSpaceOwners(extractSpaceOwnersAsMap);

export const useExtractSpaceOwnersForCompany = (companyId: string) => {
	const selector = useCallback(
		(data: IGetSpaceOwnersResponse) => extractSpaceOwnersForCompany(data, companyId),
		[companyId]
	);
	return useSpaceOwners(selector);
};

export const useExtractSpaceOwnersForSpace = (spaceId: string) => {
	const selector = useCallback(
		(data: IGetSpaceOwnersResponse) => extractSpaceOwnersForSpace(data, spaceId),
		[spaceId]
	);
	return useSpaceOwners(selector);
};

export const useLocalSpaceOwnersForSpace = (spaceId: string) => {
	const selector = useCallback(
		(data: ISpaceOwner[]) => extractLocalSpaceOwnersForSpace(data, spaceId),
		[spaceId]
	);
	return useLocalSpaceOwners(selector);
};

export const useLocalSpaceOwnersAsMap = () => useLocalSpaceOwners(extractLocalSpaceOwnersAsMap);

export const useAllSpaceOwnersForSpaceAsArray = (spaceId: string) => {
	const listSelector = useCallback(
		(data: IGetSpaceOwnersResponse) => extractSpaceOwnersForSpace(data, spaceId),
		[spaceId]
	);
	const localListSelector = useCallback(
		(data: ISpaceOwner[]) => extractLocalSpaceOwnersForSpace(data, spaceId),
		[spaceId]
	);
	const combine = useCallback(
		(results: Array<UseQueryResult<ISpaceOwner[]>>) =>
			results.reduce<ISpaceOwner[]>((acc, cur) => [...acc, ...(cur?.data || [])], []),
		[]
	);
	return useQueries({
		queries: [
			{queryKey: spaceOwnersQueryKeys.list(), select: listSelector},
			{queryKey: spaceOwnersQueryKeys.localList(), select: localListSelector}
		],
		combine
	});
};

export const useAllSpaceOwnersAsMap = () => {
	const combine = useCallback(
		(results: Array<UseQueryResult<Record<string, ISpaceOwner>>>) =>
			results.reduce<Record<string, ISpaceOwner>>((acc, cur) => ({...acc, ...cur.data}), {}),
		[]
	);
	return useQueries({
		queries: [
			{queryKey: spaceOwnersQueryKeys.list(), select: extractSpaceOwnersAsMap},
			{queryKey: spaceOwnersQueryKeys.localList(), select: extractLocalSpaceOwnersAsMap}
		],
		combine
	});
};
