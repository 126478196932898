import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {IGetProblemStatusesResponse} from '@src/api/backend/problemStatuses';
import {IGetWorkAcceptanceStatusesResponse} from '@src/api/backend/workAcceptanceStatuses';
import {TFunction} from 'i18next';

export const createStatusDifference = (
	prev: string | undefined,
	next: string | undefined,
	statuses: IGetProblemStatusesResponse | IGetWorkAcceptanceStatusesResponse,
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev: prev
		? {
				name: statuses.byId[prev].name,
				color: statuses.byId[prev].color || '#ffffff'
		  }
		: undefined,
	next: next
		? {
				name: statuses.byId[next].name,
				color: statuses.byId[next].color || ' #ffffff'
		  }
		: undefined,
	type: HistoryTypeId.STATUS,
	name: t('createDifference.createStatusDifference')
});
