import {IDndDragState, IDndEvent, IDndOverState} from '../interfaces';

/**
 * Запуск функции если контейнеры совпадают
 * 
 * @param eventHandler функция
 * @param dragState drag состояние
 * @param overState over состояние
 * @param items элементы
 * @param container 
 * @param restContainers 
 */
export const startEventHandler = <S>(
	eventHandler: ((event: IDndEvent<S>) => void) | undefined,
	dragState: IDndDragState<S>,
	overState: IDndOverState<S>,
	items: S[],
	container?: string,
	...restContainers: Array<string | undefined>
) => {
	if (!eventHandler) { return; }
	const event: IDndEvent<S> = {dragState, overState, items, container};

	if (!container || !restContainers.length) {
		eventHandler(event);
		return;
	}

	for (const restContainer of restContainers) {
		if (restContainer === container) {
			eventHandler(event);
			break;
		}
	}
};