import {useCallback} from 'react';
import * as React from 'react';
import classNames from 'classnames';
import {ObjectImage} from './components/ObjectImage';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useObjectsPermissions} from '@src/core/hooks/permissions/useObjectsPermissions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAddingObjectDialog} from '@src/components/AddingObjectDialog/hooks/useAddingObjectDialog';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import {ObjectStages} from './components/ObjectStages';
import ProblemsCount from '@src/components/tableCells/ProblemsCount';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {GoToSpacesButton} from './components/GoToSpacesButton';
import './ObjectItem.less';
import { useDeleteObject } from '@src/core/hooks/mutations/object/useDeleteObject';

const openIcon = <i className="tz-object-24" />;
const addIcon = <i className="tz-plus-20" />;
const deleteIcon = <i className="tz-delete" />;

interface IObjectItemProps {
	className?: string;
	object: IParentObject;
}

export const ObjectItem = ({className, object}: IObjectItemProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {previewImage, fullImage} = object;
	const image = previewImage || fullImage;

	const permissions = useObjectsPermissions();
	const {mutateAsync: deleteObject} = useDeleteObject();

	const classes = classNames('object-item', className);
	const imgStyles = {} as React.CSSProperties;
	if (image) {
		imgStyles.backgroundImage = `url(${image.url})`;
	}

	const [addingDialog, openAddingDialog] = useAddingObjectDialog(object.id, object.companyId);
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.objects.deleteTitle'),
		message: ''
	});

	const handleClick = useCallback(() => {
		if (object.children && object.children.length) {
			return pushPath(`/objects/${object.id}`);
		}
		return pushPath(`/objects/${object.id}/problems`);
	}, [object]);

	const handleView = useCallback(() => {
		pushPath(`/objects/${object.id}/view`);
	}, [object.id]);

	const handleAdd = useCallback(() => {
		openAddingDialog();
	}, [openAddingDialog]);

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			await deleteObject(object.id);
		}
	}, [object.id]);

	const menuActions = [];
	if (permissions.canView) {
		menuActions.push(
			<MenuItem
				key="view"
				icon={openIcon}
				onClick={handleView}
			>
				{t('objectsPage.objectItem.view')}
			</MenuItem>
		);
	}
	if (permissions.canAdd) {
		menuActions.push(
			<MenuItem
				key="add"
				icon={addIcon}
				onClick={handleAdd}
			>
				{t('objectsPage.objectItem.add')}
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		menuActions.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDelete}
			>
				{t('objectsPage.objectItem.delete')}
			</MenuItem>
		);
	}
	let menuBtn;
	if (menuActions.length) {
		menuBtn = <IconMenu className="object-item__footer-menu">{menuActions}</IconMenu>;
	}

	return (
		<div className={classes}>
			<ObjectImage
				image={image?.url}
				title={object.name}
				onClick={handleClick}
			/>

			<div className="object-item__footer">
				<div
					className="object-item__footer-title"
					title={object.name}
				>
					{object.name}
				</div>

				{menuBtn}
				<ObjectStages object={object} />

				<div className="object-item__footer-problems">
					<ProblemsCount object={object} />
				</div>

				<GoToSpacesButton
					objectId={object.id}
					disabled={!object.hasSpaces}
				/>
			</div>
			{permissions.canAdd && addingDialog}
			{permissions.canDelete && deleteDialog}
		</div>
	);
};
