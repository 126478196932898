import {ViewerType} from '../LocationSelect';

/**
 * Возвращает название вкладки в мобиьной версии
 *
 * @param translatedViewerType тип отображения и перевод согласно типу
 */
export const getViewerTabTitle = (translatedViewerType: {
	viewerType: ViewerType;
	plan: string;
	map: string;
}) => {
	const {viewerType, ...viewerTypes} = translatedViewerType;

	return viewerTypes[viewerType] || '';
};
