import {RefCallback} from 'react';
import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';

interface ITouchRecognizerProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	useMouseEvents?: boolean;
	scrollRatio: number;
	minVelocity: number;
	minDistanceMultiplier: number;
	elementRef?: RefCallback<HTMLDivElement>;

	onBeforeRecognition?: () => void;

	onAfterRecognition?: (success: boolean) => void;

	onMove?: (position: number, delta: number) => void;

	onMoveEnd?: (position: number, delta: number, velocity: number) => void;

	onSwipe?: (direction: -1 | 0 | 1) => void;

	onClick?: (event: MouseEvent | TouchEvent) => void;

	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;

	onResize?: () => void;
}

/**
 * Базовый класс для использования перемещения пальцем/мышью и для распознавания жеста swipe
 */
class TouchRecognizer extends React.PureComponent<ITouchRecognizerProps> {
	static defaultProps = {
		scrollRatio: 1,
		minVelocity: 1,
		minDistanceMultiplier: 0.3
	};

	/**
	 * Корневой элемент
	 */
	private ref?: HTMLDivElement | null;

	/**
	 * Идентификатор события
	 */
	private eventId?: number;

	/**
	 * Флаг необходимости распознать направление движения
	 */
	private needRecognition = false;

	/**
	 * Текущая позиция по x
	 */
	private currentX = 0;

	/**
	 * Текущая позиция по y
	 */
	private currentY = 0;

	/**
	 * Начальная позиция по x
	 */
	private startX = 0;

	/**
	 * Начальная позиция по y
	 */
	// private startY = 0;

	/**
	 * Последнее время тач-события
	 */
	private startTouchTime = 0;

	/**
	 * Ширина dom-элемента
	 */
	private width = 0;

	private removeResizeEventListener?: () => void;

	/**
	 * Обрабатывает событие touchMove
	 *
	 * @param event событие
	 */
	private handleTouchMove = rafThrottle((event: React.TouchEvent<HTMLDivElement>) => {
		const touch = this.getTouchFromEvent(event);
		if (touch) {
			this.move(touch.clientX, touch.clientY);
		}
	});

	/**
	 * Обрабатывает событие mouseMove
	 *
	 * @param event событие
	 */
	private handleMouseMove = rafThrottle((event: React.MouseEvent<HTMLDivElement>) => {
		if (this.eventId !== undefined) {
			if (this.currentX !== event.clientX || this.currentY !== event.clientY) {
				this.move(event.clientX, event.clientY);
			}
		}
	});

	componentWillUnmount() {
		if (this.removeResizeEventListener) {
			this.removeResizeEventListener();
		}
	}

	render() {
		const {className, style, children, useMouseEvents, onKeyDown} = this.props;

		return (
			// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
			<div
				className={className}
				style={style}
				tabIndex={-1}
				onTouchStart={this.handleTouchStart}
				onTouchMove={this.handleTouchMove}
				onTouchEnd={this.handleTouchEnd}
				onTouchCancel={this.handleTouchEnd}
				onMouseDown={useMouseEvents ? this.handleMouseDown : undefined}
				onMouseMove={useMouseEvents ? this.handleMouseMove : undefined}
				onMouseUp={useMouseEvents ? this.handleMouseUp : undefined}
				onMouseLeave={useMouseEvents ? this.handleMouseUp : undefined}
				onKeyDown={onKeyDown}
				ref={this.saveRef}
				role="list"
			>
				{children}
			</div>
		);
	}

	/**
	 * Начинает отслеживание движения
	 *
	 * @param x координата по x
	 * @param y координата по y
	 */
	private start = (x: number, y: number) => {
		this.needRecognition = true;
		this.currentX = x;
		this.currentY = y;
		this.startX = x;
		this.startTouchTime = Date.now();

		if (this.props.onBeforeRecognition) {
			this.props.onBeforeRecognition();
		}
	};

	/**
	 * Распознаёт направление движения
	 *
	 * @param x координата по x
	 * @param y координата по y
	 */
	private recognize = (x: number, y: number) => {
		this.needRecognition = false;
		const {scrollRatio, onAfterRecognition} = this.props;

		const horizontal = Math.abs(this.currentX - x) > Math.abs(this.currentY - y) * scrollRatio;
		// Если изменение по вертикали больше изменения по горизонтали, то не обрабатываем последующие события
		if (!horizontal) {
			this.eventId = undefined;
			this.currentX = 0;
			this.currentY = 0;
			this.startX = 0;
			this.startTouchTime = 0;
		}
		if (onAfterRecognition) {
			onAfterRecognition(horizontal);
		}
		return horizontal;
	};

	/**
	 * Обрабатывает событие движения
	 *
	 * @param x координата по x
	 * @param y координата по y
	 */
	private move = (x: number, y: number) => {
		// Если это первый вызов, в котором необходимо определить направление движения
		if (this.needRecognition && !this.recognize(x, y)) {
			return;
		}
		if (this.props.onMove) {
			this.props.onMove(x, x - this.currentX);
		}
		this.currentX = x;
		this.currentY = y;
	};

	/**
	 * Завершает отслеживание движения
	 */
	private end = (x: number, event: MouseEvent | TouchEvent) => {
		const distance = this.currentX - this.startX;
		if (distance !== 0) {
			const {minVelocity, minDistanceMultiplier, onMoveEnd, onSwipe} = this.props;
			// Скорость преодоления дистанции, используется как начальная для завершения анимации
			const velocity = distance / (Date.now() - this.startTouchTime);
			if (onMoveEnd) {
				onMoveEnd(x, x - this.currentX, velocity);
			}
			// Если ускорение больше необходимого минимума или дистанция больше минимально необходимой,
			// то значит произошёл swipe
			const needChangeState =
				Math.abs(velocity) > minVelocity ||
				Math.abs(distance) > this.width * minDistanceMultiplier;
			if (onSwipe) {
				if (needChangeState) {
					onSwipe(this.startX > this.currentX ? 1 : -1);
				} else {
					onSwipe(0);
				}
			}
		} else if (this.props.onClick) {
			this.props.onClick(event);
		}

		this.eventId = undefined;
		this.currentX = 0;
		this.currentY = 0;
		this.startX = 0;
		this.startTouchTime = 0;
	};

	/**
	 * Обрабатывает событие touchStart
	 *
	 * @param event событие
	 */
	private handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
		// Пропускаем жесты более чем одним пальцем и если ранее уже был начат другой жест
		if (this.eventId === undefined && event.touches.length === 1) {
			const touch = event.touches[0];
			this.eventId = touch.identifier;
			this.start(touch.clientX, touch.clientY);
		}
	};

	/**
	 * Обрабатывает событие touchEnd
	 *
	 * @param event событие
	 */
	private handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
		const touch = this.getTouchFromEvent(event);
		if (touch) {
			this.end(touch.clientX, event.nativeEvent);
		}
	};

	/**
	 * Обрабатывает событие mouseDown
	 *
	 * @param event событие
	 */
	private handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
		this.eventId = -1;
		this.start(event.clientX, event.clientY);
	};

	/**
	 * Обрабатывает событие mouseUp
	 */
	private handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
		if (this.eventId !== undefined) {
			this.end(event.clientX, event.nativeEvent);
		}
	};

	/**
	 * Сохраняет ширину элемента
	 */
	private saveWidth = () => {
		this.width = this.ref ? this.ref.offsetWidth : 0;
	};

	/**
	 * Обрабатывает событие изменения размера элемента
	 */
	private handleResize = () => {
		this.saveWidth();
		if (this.props.onResize) {
			this.props.onResize();
		}
	};

	/**
	 * Находит необходимый touch-элемент в событии
	 *
	 * @param event событие
	 */
	private getTouchFromEvent = (
		event: React.TouchEvent<HTMLDivElement>
	): React.Touch | undefined => {
		if (this.eventId === undefined) {
			return;
		}
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < event.changedTouches.length; i++) {
			if (event.changedTouches[i].identifier === this.eventId) {
				return event.changedTouches[i];
			}
		}
		return undefined;
	};

	/**
	 * Сохраняет ссылку на dom-элемент
	 *
	 * @param node html-элемент
	 */
	private saveRef = (node: HTMLDivElement | null) => {
		this.ref = node;
		this.saveWidth();
		if (this.removeResizeEventListener) {
			this.removeResizeEventListener();
		}
		if (this.ref) {
			this.addResizeEventListener(this.ref);
		}
		if (this.props.elementRef) {
			this.props.elementRef(this.ref);
		}
	};

	private addResizeEventListener = (element: HTMLElement) => {
		const observer = new ResizeObserver(this.handleResize);
		observer.observe(element);
		this.removeResizeEventListener = () => observer.disconnect();
	};
}

export default TouchRecognizer;
