import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertToStatsObject} from '../utils/convertToStatsObject';
import {statsObjectsSortFn} from '../utils/statsObjectsSortFn';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';
import {filterObjects} from '@src/pages/StatisticsPage/utils/filterObjects';
import {useObjectsAsTree} from '@src/core/hooks/queries/objects/hooks';
import {useMainAndSubCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';

export function useFilteredCompanies<T>(
	statsByCompanies: {[id: string]: T},
	statsByObjects: {[id: string]: T},
	filters: {
		companies?: string[];
		objects?: string[];
	},
	isFiltersSetUp: boolean
) {
	const {data} = useMainAndSubCompaniesAsArray();
	const companies = data?.mainCompanies;
	const objectsTree = useObjectsAsTree();
	const loading = useAppSelector(extractSourcesLoading);

	// Во время загрузки справочников используется основные источники компаний и объектов,
	// чтобы отображать корректное количество элементов с анимацией загрузки.
	// Это необходимо, когда в фильтре выбраны компании/объекты.
	return useMemo(() => {
		const source = companies;

		const result = [];
		if (source) {
			for (const company of source) {
				const companyStats = statsByCompanies[company.id];

				const objectsSource = objectsTree
					? objectsTree
						.map(convertToStatsObject)
						.sort(statsObjectsSortFn)
						.filter(item => item.companyId === company.id)
					: undefined;

				const companyObjects = isFiltersSetUp
					? filterObjects(objectsSource, statsByObjects, filters)
					: objectsSource;

				// Компания отображается, если не установлены фильтры, или есть статистика,
				// или компания выбрана в фильтре или есть дочерние объекты со статистикой
				if (
					!isFiltersSetUp ||
					companyStats ||
					filters.companies?.includes(company.id) ||
					companyObjects?.length
				) {
					result.push({company, companyStats, objects: companyObjects});
				}
			}
		}
		return result;
	}, [
		companies,
		objectsTree,
		statsByCompanies,
		statsByObjects,
		isFiltersSetUp,
		filters,
		loading
	]);
}
