import { memo } from 'react';
import {ProblemIndicator} from '@tehzor/ui-components';
import {useProblemsCountByStatus} from '../hooks/useProblemsCountByStatus';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';

interface ProblemIndicatorsProps {
	checkListId: string;
	checkItemId?: string;
}

export const ProblemIndicators = memo(({checkListId, checkItemId}: ProblemIndicatorsProps) => {
	const {data: problemStatuses} = useProblemStatuses();
	const problemCounts = useProblemsCountByStatus(checkListId, checkItemId);

	return (
		<div className="check-list-page__indicators">
			{problemStatuses &&
				problemCounts.map(({status, count}) =>
					count > 0 ? (
						<ProblemIndicator
							key={status}
							statuses={problemStatuses}
							status={status}
							count={count}
						/>
					) : null
				)}
		</div>
	);
});
