/* eslint-disable no-shadow */
import BaseSocketConnector from '@tehzor/tools/api/BaseSocketConnector';
// eslint-disable-next-line import/no-unresolved
// import {storeReportMessage} from '@src/actions/entities/storeReportChatMessage';
// import getTimezone from '@tehzor/tools/utils/getTimezone';

const singleton = Symbol('singleton');
const singletonEnforcer = Symbol('singletonEnforcer');

/**
 * Singleton класс для взаимодействия с backend'ом
 */
class SocketConnector extends BaseSocketConnector {
	/**
	 * Возвращает единственный экземпляр класса SocketConnector
	 *
	 * @returns {SocketConnector}
	 */
	static get instance() {
		if (!this[singleton]) {
			this[singleton] = new SocketConnector(singletonEnforcer);
		}
		return this[singleton];
	}

	constructor(enforcer) {
		super();
		if (enforcer !== singletonEnforcer) {
			throw new Error("Cannot construct 'SocketConnector' class");
		}
	}

	/**
	 * Получает список СНиП и СП
	 *
	 * @return {Promise}
	 */
	// getRegulatoryStandards = () => this._sendAuthorizedRequest('getRegulatoryStandards');

	/**
	 * Получает список проверок
	 *
	 * @param {Object} filter фильтры
	 * @param {Object} sort параметры сортировки
	 * @param {Number} offset индекс начального элемента
	 * @param {Number} limit количество элементов
	 * @returns {Promise}
	 */
	// getReports = (filter, sort, offset, limit) => {
	// 	const params = {timezone: getTimezone()};
	// 	if (filter) {
	// 		params.filter = filter;
	// 	}
	// 	if (sort) {
	// 		params.sort = sort;
	// 	}
	// 	if (offset) {
	// 		params.offset = offset;
	// 	}
	// 	if (limit) {
	// 		params.limit = limit;
	// 	}
	// 	return this._sendAuthorizedRequest('getReports', params);
	// };

	/**
	 * Получает отчёт
	 *
	 * @param {String} objectId id объекта
	 * @param {String} reportId id отчёта
	 * @return {Promise}
	 */
	// getReport = (objectId, reportId) => {
	// 	const params = {
	// 		objectId,
	// 		reportId
	// 	};
	// 	return this._sendAuthorizedRequest('getReport', params);
	// };

	/**
	 * Добавляет проверку
	 *
	 * @param {string} objectId id объекта
	 * @param {ISavingProblem[]} problems список нарушений
	 * @param {ISavingInspection[]} inspections список осмотров
	 * @return {Promise}
	 */
	// addReport = (objectId, problems = undefined, inspections = undefined) => {
	// 	const params = {objectId};
	//
	// 	const filterAttachments = items => items.map(item => {
	// 			let newAttachments;
	// 			if (item.newAttachments) {
	// 				newAttachments = item.newAttachments.map(attach => ({id: attach.id}));
	// 			}
	// 			return {...item, newAttachments};
	// 		});
	// 	if (problems) {
	// 		params.problems = filterAttachments(problems);
	// 	}
	// 	if (inspections) {
	// 		params.inspections = filterAttachments(inspections);
	// 	}
	// 	return this._sendAuthorizedRequest('addReport', params);
	// };

	/**
	 * Удаляет проверку
	 *
	 * @param {String} objectId id объекта
	 * @param {String} reportId id отчета
	 * @return {Promise}
	 */
	// deleteReport = (objectId, reportId) => {
	// 	const params = {
	// 		objectId,
	// 		reportId
	// 	};
	// 	return this._sendAuthorizedRequest('deleteReport', params);
	// };

	/**
	 * Экспортирует список проверок
	 *
	 * @param {string} templateId id шаблона экспорта
	 * @param {IReportsPageSettingsState['filters']} filter
	 * @param {IReportsPageSettingsState['sort']} sort
	 * @param {string[]} selected
	 * @param {boolean} allSelected
	 * @return {Promise}
	 */
	// exportReports = (templateId, filter, sort, selected, allSelected) => {
	// 	const params = {
	// 		templateId,
	// 		filter,
	// 		sort,
	// 		selected,
	// 		allSelected,
	// 		timezone: getTimezone()
	// 	};
	// 	return this._getAuthorizedFileRequest('exportReports', params);
	// };

	/**
	 * Отправляет список проверок на email
	 *
	 * @param {string} templateId id шаблона экспорта
	 * @param {string} templateId id шаблона экспорта
	 * @param {IReportsPageSettingsState['filters']} filter
	 * @param {IReportsPageSettingsState['sort']} sort
	 * @param {string[]} selected
	 * @param {boolean} allSelected
	 * @param {string} email адрес электронной почты
	 * @return {Promise}
	 */
	// sendReports = (templateId, filter, sort, selected, allSelected, email) => {
	// 	const params = {
	// 		templateId,
	// 		filter,
	// 		sort,
	// 		selected,
	// 		allSelected,
	// 		email,
	// 		timezone: getTimezone()
	// 	};
	// 	return this._sendAuthorizedRequest('sendReports', params);
	// };

	/**
	 * Экспортирует проверку
	 *
	 * @param {string} objectId id объекта
	 * @param {string} reportId id проверки
	 * @param {string} templateId id шаблона экспорта
	 * @return {Promise}
	 */
	// exportReport = (objectId, reportId, templateId) => {
	// 	const params = {
	// 		objectId,
	// 		reportId,
	// 		templateId,
	// 		timezone: getTimezone()
	// 	};
	// 	return this._getAuthorizedFileRequest('exportReport', params);
	// };

	/**
	 * Отправляет проверку на email
	 *
	 * @param {string} objectId id объекта
	 * @param {string} reportId id проверки
	 * @param {string} templateId id шаблона экспорта
	 * @param {string} email адрес электронной почты
	 * @return {Promise}
	 */
	// sendReport = (objectId, reportId, templateId, email) => {
	// 	const params = {
	// 		objectId,
	// 		reportId,
	// 		templateId,
	// 		email,
	// 		timezone: getTimezone()
	// 	};
	// 	return this._sendAuthorizedRequest('sendReport', params);
	// };

	/**
	 * Получает список авторов проверок
	 *
	 * @returns {Promise}
	 */
	// getReportsUsers = () => this._sendAuthorizedRequest('getReportsUsers');

	/**
	 * Получает список нарушений
	 *
	 * @param {Object} filter фильтры
	 * @param {Object} sort параметры сортировки
	 * @param {Number} offset индекс начального элемента
	 * @param {Number} limit количество элементов
	 * @returns {Promise}
	 */
	// getProblems = (filter, sort, offset, limit) => {
	// 	const params = {timezone: getTimezone()};
	// 	if (filter) {
	// 		params.filter = filter;
	// 	}
	// 	if (sort) {
	// 		params.sort = sort;
	// 	}
	// 	if (offset) {
	// 		params.offset = offset;
	// 	}
	// 	if (limit) {
	// 		params.limit = limit;
	// 	}
	// 	return this._sendAuthorizedRequest('getProblems', params);
	// };

	/**
	 * Экспортирует список нарушений
	 *
	 * @param {string} templateId id шаблона экспорта
	 * @param {IReportsPageSettingsState['filters']} filter
	 * @param {IReportsPageSettingsState['sort']} sort
	 * @param {string[]} selected
	 * @param {boolean} allSelected
	 * @return {Promise}
	 */
	// exportProblems = (templateId, filter, sort, selected, allSelected) => {
	// 	const params = {
	// 		templateId,
	// 		filter,
	// 		sort,
	// 		selected,
	// 		allSelected,
	// 		timezone: getTimezone()
	// 	};
	// 	return this._getAuthorizedFileRequest('exportProblems', params);
	// };

	/**
	 * Отправляет список нарушений на email
	 *
	 * @param {string} templateId id шаблона экспорта
	 * @param {string} templateId id шаблона экспорта
	 * @param {IReportsPageSettingsState['filters']} filter
	 * @param {IReportsPageSettingsState['sort']} sort
	 * @param {string[]} selected
	 * @param {boolean} allSelected
	 * @param {string} email адрес электронной почты
	 * @return {Promise}
	 */
	// sendProblems = (templateId, filter, sort, selected, allSelected, email) => {
	// 	const params = {
	// 		templateId,
	// 		filter,
	// 		sort,
	// 		selected,
	// 		allSelected,
	// 		email,
	// 		timezone: getTimezone()
	// 	};
	// 	return this._sendAuthorizedRequest('sendProblems', params);
	// };

	/* Добавляет список нарушений
	 *
	 * @param {string} objectId id объекта
	 * @param {IProblem['links']} links связи каждого нарушения с другими сущностями
	 * @param {ISavingProblem[]} problems нарушения
	 * @return {Promise}
	 */
	// addProblems = (objectId, links, problems) => {
	// 	const params = {
	// 		objectId,
	// 		links,
	// 		problems: problems.map(problem => ({
	// 			...problem,
	// 			newAttachments: problem.newAttachments && problem.newAttachments.map(item => ({id: item.id}))
	// 		}))
	// 	};
	// 	return this._sendAuthorizedRequest('addProblems', params);
	// };

	/**
	 * Добавляет нарушение
	 *
	 * @param {string} objectId id объекта
	 * @param {string} IProblem['links'] id проверки
	 * @param {ISavingProblem} fields поля нарушения
	 * @return {Promise}
	 */
	// addProblem = (objectId, links, fields = {}) => {
	// 	const params = {
	// 		objectId,
	// 		links,
	// 		...fields
	// 	};
	// 	if (params.newAttachments) {
	// 		params.newAttachments = params.newAttachments.map(item => ({id: item.id}));
	// 	}
	// 	return this._sendAuthorizedRequest('addProblem', params);
	// };

	/**
	 * Изменяет статус нарушения
	 *
	 * @param {string} objectId id объекта
	 * @param {string} problemId id нарушения
	 * @param {String} status статус
	 * @return {Promise}
	 */
	// editProblemStatus = (objectId, problemId, status) => {
	// 	const params = {
	// 		objectId,
	// 		problemId,
	// 		status
	// 	};
	// 	return this._sendAuthorizedRequest('editProblemStatus', params);
	// };

	/**
	 * Изменяет нарушение
	 *
	 * @param {string} objectId id объекта
	 * @param {string} problemId id нарушения
	 * @param {ISavingProblem} fields измененные поля
	 * @return {Promise}
	 */
	// editProblem = (objectId, problemId, fields = {}) => {
	// 	const params = {
	// 		objectId,
	// 		problemId,
	// 		...fields
	// 	};
	// 	if (params.newAttachments) {
	// 		params.newAttachments = params.newAttachments.map(item => ({id: item.id}));
	// 	}
	// 	return this._sendAuthorizedRequest('editProblem', params);
	// };

	/**
	 * Удаляет нарушение
	 *
	 * @param {string} objectId id объекта
	 * @param {string} problemId id нарушения
	 * @return {Promise}
	 */
	// deleteProblem = (objectId, problemId) => {
	// 	const params = {
	// 		objectId,
	// 		problemId
	// 	};
	// 	return this._sendAuthorizedRequest('deleteProblem', params);
	// };

	/**
	 * Добавляет ответ на нарушение
	 *
	 * @param {string} objectId id объекта
	 * @param {string} problemId id нарушения
	 * @param {IProblemReply} fields поля ответа
	 * @return {Promise}
	 */
	// addProblemReply = (objectId, problemId, fields = {}) => {
	// 	const params = {
	// 		objectId,
	// 		problemId,
	// 		...fields
	// 	};
	// 	if (params.newAttachments) {
	// 		params.newAttachments = params.newAttachments.map(item => ({id: item.id}));
	// 	}
	// 	return this._sendAuthorizedRequest('addProblemReply', params);
	// };

	/**
	 * Изменяет ответ на нарушение
	 *
	 * @param {string} objectId id объекта
	 * @param {string} problemId id нарушения
	 * @param {ISavingProblemReply} fields поля ответа
	 * @return {Promise}
	 */
	// editProblemReply = (objectId, problemId, fields = {}) => {
	// 	const params = {
	// 		objectId,
	// 		problemId,
	// 		...fields
	// 	};
	// 	if (params.newAttachments) {
	// 		params.newAttachments = params.newAttachments.map(item => ({id: item.id}));
	// 	}
	// 	return this._sendAuthorizedRequest('editProblemReply', params);
	// };

	/**
	 * Удаляет ответ на нарушение
	 *
	 * @param {string} objectId id объекта
	 * @param {string} problemId id нарушения
	 * @return {Promise}
	 */
	// deleteProblemReply = (objectId, problemId) => {
	// 	const params = {
	// 		objectId,
	// 		problemId
	// 	};
	// 	return this._sendAuthorizedRequest('deleteProblemReply', params);
	// };

	/**
	 * Экспортирует ответ на нарушение
	 *
	 * @param {string} objectId id объекта
	 * @param {string} problemId id нарушения
	 * @param {string} templateId id шаблона экспорта
	 * @return {Promise}
	 */
	// exportProblemReply = (objectId, problemId, templateId) => {
	// 	const params = {
	// 		objectId,
	// 		problemId,
	// 		templateId,
	// 		timezone: getTimezone()
	// 	};
	// 	return this._getAuthorizedFileRequest('exportProblemReply', params);
	// };

	/**
	 * Отправляет экспортированный ответ на email
	 *
	 * @param {string} objectId id объекта
	 * @param {string} problemId id нарушения
	 * @param {string} templateId id шаблона экспорта
	 * @param {string} email адрес электронной почты
	 * @return {Promise}
	 */
	// sendProblemReply = (objectId, problemId, templateId, email) => {
	// 	const params = {
	// 		objectId,
	// 		problemId,
	// 		templateId,
	// 		timezone: getTimezone(),
	// 		email
	// 	};
	// 	return this._sendAuthorizedRequest('sendProblemReply', params);
	// };

	/**
	 * Получает список осмотров
	 *
	 * @param {Object} filter фильтры
	 * @param {Object} sort параметры сортировки
	 * @param {Number} offset индекс начального элемента
	 * @param {Number} limit количество элементов
	 * @returns {Promise}
	 */
	// getInspections = (filter, sort, offset, limit) => {
	// 	const params = {timezone: getTimezone()};
	// 	if (filter) {
	// 		params.filter = filter;
	// 	}
	// 	if (sort) {
	// 		params.sort = sort;
	// 	}
	// 	if (offset) {
	// 		params.offset = offset;
	// 	}
	// 	if (limit) {
	// 		params.limit = limit;
	// 	}
	// 	return this._sendAuthorizedRequest('getInspections', params);
	// };

	/**
	 * Добавляет список нарушений
	 *
	 * @param {string} objectId id объекта
	 * @param {IProblem['links']} reportId связи каждого нарушения с другими сущностями
	 * @param {ISavingInspection[]} inspections нарушения
	 * @return {Promise}
	 */
	// addInspections = (objectId, reportId, inspections) => {
	// 	const params = {
	// 		objectId,
	// 		reportId,
	// 		inspections: inspections.map(insp => ({
	// 			...insp,
	// 			newAttachments: insp.newAttachments && insp.newAttachments.map(item => ({id: item.id}))
	// 		}))
	// 	};
	// 	return this._sendAuthorizedRequest('addInspections', params);
	// };

	/**
	 * Добавляет осмотр
	 *
	 * @param {string} objectId id объекта
	 * @param {string} reportId id проверки
	 * @param {ISavingInspection} fields поля осмотра
	 * @return {Promise}
	 */
	// addInspection = (objectId, reportId, fields = {}) => {
	// 	const params = {
	// 		objectId,
	// 		reportId,
	// 		...fields
	// 	};
	// 	if (params.newAttachments) {
	// 		params.newAttachments = params.newAttachments.map(item => ({id: item.id}));
	// 	}
	// 	return this._sendAuthorizedRequest('addInspection', params);
	// };

	/**
	 * Изменяет осмотр
	 *
	 * @param {string} objectId id объекта
	 * @param {string} inspectionId id осмотра
	 * @param {Object} fields измененные поля
	 * @return {Promise}
	 */
	// editInspection = (objectId, inspectionId, fields = {}) => {
	// 	const params = {
	// 		objectId,
	// 		inspectionId,
	// 		...fields
	// 	};
	// 	if (params.newAttachments) {
	// 		params.newAttachments = params.newAttachments.map(item => ({id: item.id}));
	// 	}
	// 	return this._sendAuthorizedRequest('editInspection', params);
	// };

	/**
	 * Удаляет осмотр
	 *
	 * @param {string} objectId id объекта
	 * @param {string} reportId id проверки
	 * @param {string} inspectionId id осмотра
	 * @return {Promise}
	 */
	// deleteInspection = (objectId, reportId, inspectionId) => {
	// 	const params = {
	// 		objectId,
	// 		reportId,
	// 		inspectionId
	// 	};
	// 	return this._sendAuthorizedRequest('deleteInspection', params);
	// };

	/**
	 * Получает данные по квартире
	 *
	 * @param {string} objectId id объекта
	 * @param {string} spaceId id отчёта
	 * @return {Promise}
	 */
	// getApartment = (objectId, spaceId) => {
	// 	const params = {
	// 		objectId,
	// 		spaceId
	// 	};
	// 	return this._sendAuthorizedRequest('getApartment', params);
	// };

	/**
	 * Изменяет поле acceptedByOwner (принята собственником) у квартиры
	 *
	 * @param {string} objectId id объекта
	 * @param {string} spaceId id квартиры
	 * @param {boolean} acceptedByOwner флаг принятия собственником
	 * @returns {Promise}
	 */
	// editApartmentAcceptance = (objectId, spaceId, acceptedByOwner) => this._sendAuthorizedRequest('editApartmentAcceptance', {objectId, spaceId, acceptedByOwner});

	/**
	 * Получает сводную статистику по компаниям
	 *
	 * @param {Date} dateFrom дата от
	 * @param {Date} dateTo дата до
	 * @returns {Promise}
	 */
	// getCompaniesSummary = (dateFrom, dateTo) => {
	// 	const params = {
	// 		dateFrom,
	// 		dateTo,
	// 		timezone: getTimezone()
	// 	};
	// 	return this._sendAuthorizedRequest('getCompaniesSummary', params);
	// };

	/**
	 * Экспортирует сводную статистику по компаниям
	 *
	 * @param {String} templateId id шаблона экспорта
	 * @param {Date} dateFrom дата от
	 * @param {Date} dateTo дата до
	 * @returns {Promise}
	 */
	// exportCompaniesSummary = (templateId, dateFrom, dateTo) => {
	// 	const params = {
	// 		templateId,
	// 		dateFrom: new Date(dateFrom).toISOString(),
	// 		dateTo: new Date(dateTo).toISOString(),
	// 		timezone: getTimezone()
	// 	};
	// 	return this._getAuthorizedFileRequest('exportCompaniesSummary', params);
	// };

	/**
	 * Подписывает на получение сообщений в чате отчёта
	 *
	 * @param {string} objectId id объекта
	 * @param {string} reportId id отчёта
	 * @return {Promise}
	 */
	// subscribeOnReportMessages = async (objectId, reportId) => {
	// 	const params = {
	// 		objectId,
	// 		reportId
	// 	};
	// 	const result = await this._sendAuthorizedRequest('subscribeOnReportMessages', params);
	// 	this._subscribe('reportMessageAdded', this._storeReportMessage, true);
	// 	return result;
	// };

	/**
	 * Отдписывается от получения сообщений в чате отчёта
	 *
	 * @param {string} reportId id отчёта
	 * @return {Promise}
	 */
	// unsubscribeFromReportMessages = async reportId => {
	// 	this._unsubscribe('reportMessageAdded', this._storeReportMessage);
	// 	return this._sendAuthorizedRequest('unsubscribeFromReportMessages', {reportId});
	// };

	/**
	 * Получает сообщения из истории чата отчёта
	 *
	 * @param {string} objectId id объекта
	 * @param {string} reportId id отчёта
	 * @param {number} offset индекс начального элемента
	 * @param {number} limit количество элементов
	 * @return {Promise}
	 */
	// getReportMessages = (objectId, reportId, offset, limit) => {
	// 	const params = {
	// 		objectId,
	// 		reportId
	// 	};
	// 	if (offset) {
	// 		params.offset = offset;
	// 	}
	// 	if (limit) {
	// 		params.limit = limit;
	// 	}
	// 	return this._sendAuthorizedRequest('getReportMessages', params);
	// };

	/**
	 * Добавляет новое сообщение в чат
	 *
	 * @param {string} objectId id объекта
	 * @param {string} reportId id отчёта
	 * @param {string} text текст сообщения
	 * @param {string|undefined} replyTo id отвечаемого сообщения
	 * @return {Promise}
	 */
	// addReportMessage = (objectId, reportId, text, replyTo) => {
	// 	const params = {
	// 		objectId,
	// 		reportId,
	// 		text
	// 	};
	// 	if (replyTo) {
	// 		params.replyTo = replyTo;
	// 	}
	// 	return this._sendAuthorizedRequest('addReportMessage', params);
	// };

	/**
	 * Получает список шаблонов экспорта
	 *
	 * @returns {Promise}
	 */
	getExportTemplates = () => this._sendAuthorizedRequest('getExportTemplates');

	/**
	 * Получает список компаний
	 *
	 * @return {Promise}
	 */
	// getCompanies = () => this._sendAuthorizedRequest('getCompanies');

	/**
	 * Получает список пользователей
	 *
	 * @return {Promise}
	 */
	// getUsers = () => this._sendAuthorizedRequest('getUsers');

	/**
	 * Сохраняет в store сообщение чата отчёта
	 *
	 * @param {IReportChatMessage} message сообщение
	 * @private
	 */
	// _storeReportMessage = message => {
	// 	if (message && typeof message === 'object') {
	// 		this._store.dispatch(storeReportMessage(message));
	// 	}
	// };
}

export default SocketConnector;
