import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import { memo, useCallback, useMemo } from 'react';
import {IPreparedLegal} from '../../interfaces/IPreparedLegal';
import {LegalsRowActions} from './LegalsRowActions';

export const SelectionRow = memo(
	({selectedFlatRows, toggleAllRowsSelected}: ISelectionRowProps<IPreparedLegal>) => {
		const handleClear = useCallback(() => {
			toggleAllRowsSelected(false);
		}, [toggleAllRowsSelected]);

		const selectedEntities = useMemo(
			() => selectedFlatRows.map(item => item.original),
			[selectedFlatRows]
		);

		return (
			<>
				<LegalsRowActions
					onClear={handleClear}
					selectedEntities={selectedEntities}
				/>
				<Button
					label="Сбросить"
					type="cancel"
					onClick={handleClear}
				/>
			</>
		);
	}
);
