import {IContractsFiltersState, IContractsSortState} from '@src/store/modules/settings/pages/contracts/reducers';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IContract} from '@tehzor/tools/interfaces/contracts';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetPaginateContractsResponse extends INormalizedData<IContract> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает пагинированный список договоров 
 */
export const requestGetPaginateContracts = async (
	filters?: IContractsFiltersState,
	sort?: IContractsSortState,
	offset?: number,
	limit?: number
) => {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};
	const res = await httpRequests.withToken.post<IGetPaginateContractsResponse>(
		'contracts/get-paginate', params
	);

	return res.data;
};
