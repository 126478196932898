import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {AcceptorsMobileCell} from '@src/pages/WorkAcceptancesPage/components/table/AcceptorsMobileCell';
import {CheckListsCell} from '@src/pages/WorkAcceptancesPage/components/table/CheckListsCell';
import {StatusChangeableCell} from '@src/pages/WorkAcceptancesPage/components/table/StatusChangableCell';
import {WorkAcceptanceDescriptionMobileCell} from '@src/pages/WorkAcceptancesPage/components/table/WorkAcceptanceDescriptionMobileCell';
import {TableRowSelect} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {WorkAcceptanceIndicators} from './WorkAcceptanceIndicators';
import {WorkAcceptanceScopeMobileCell} from './WorkAcceptanceScopeMobileCell';

export const MobileWorkAcceptanceCellWithoutObject = (
	props: CellProps<IPreparedWorkAcceptance>
) => {
	const {row} = props;
	const {id, acceptorsActiveGroup} = row.original;

	return (
		<div className="work-acceptances-page__m-table__cell__info">
			<ClickPreventWrap className="work-acceptances-page__m-table__cell__info-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()} />
			</ClickPreventWrap>
			<div className="work-acceptances-page__m-table__cell__info-description">
				<WorkAcceptanceDescriptionMobileCell {...props} />
				{acceptorsActiveGroup && <AcceptorsMobileCell {...props} />}
				<div className="work-acceptances-page__m-table__cell-check-lists">
					<CheckListsCell {...props} />
				</div>
				<div className="work-acceptances-page__m-table__cell-status">
					<StatusChangeableCell {...props} />
				</div>
				<WorkAcceptanceScopeMobileCell {...props} />
				<div className="work-acceptances-page__m-table__cell-stats">
					<WorkAcceptanceIndicators id={id} />
				</div>
			</div>
		</div>
	);
};
