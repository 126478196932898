import {makeOwnerAcptExportRequest} from '@src/api/backend/ownerAcceptance';
import {addExportToast} from '@src/components/ExportToast/addExportToast';
import {addErrorToast} from '@src/utils/toasts';

/**
 * Экспортирует передачу собственнику
 *
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param acceptanceId id передачи
 * @param problems нарушения
 * @param createDocument флаг создания документа
 *
 */
export const useExportOwnerAcceptance = () => async (
		templateId: string,
		objectId: string,
		acceptanceId: string,
		problems?: string[],
		createDocument?: boolean
	) => {
		try {
			const response = await makeOwnerAcptExportRequest(
				templateId,
				objectId,
				acceptanceId,
				problems,
				createDocument
			);

			if (response.link) {
				addExportToast(
					'Экспорт документа завершен',
					response.link,
					response.file.name,
					'',
					response.document,
				);
			}
		} catch (e) {
			addErrorToast('Ошибка', 'при экспорте документа');
		}
	};
