import {useCallback, useMemo, useState} from 'react';
import Fuse from 'fuse.js';
import {useUpdateEffect} from 'react-use';
import {IAnyEntity} from '@tehzor/tools/interfaces/IAnyEntity';
import {ITextSearchProvider} from '../interfaces';

export interface IUseLocalSearchProviderOptions<T extends IAnyEntity> {
	initialList: T[];
	keys: Fuse.FuseOptionKey[];
}

/**
 * Fuse-search на клиенте
 *
 * @param initialList список, по которому будет идти поиск. Качество сильно падает на тысячах вхождений
 * @param keys массив ключей, по которым будет вестись поиск. Поддерживает dot notation
 */
export const useLocalSearchProvider = <T extends IAnyEntity>({
	initialList,
	keys
}: IUseLocalSearchProviderOptions<T>): ITextSearchProvider<T> => {
	const [text, setText] = useState('');
	const [filteredList, setList] = useState<T[]>(initialList);
	const fuse = useMemo(
		() =>
			new Fuse(initialList, {
				findAllMatches: true,
				threshold: 0.1,
				ignoreLocation: true,
				keys
			} as Fuse.IFuseOptions<T>),
		[initialList]
	);

	const handleSearch = useCallback(
		(value: string) => {
			setText(value);
			if (value === '') {
				setList(initialList);
				return;
			}
			const result = fuse.search(value);
			setList(result.map(data => data.item));
		},
		[fuse]
	);

	useUpdateEffect(() => {
		handleSearch(text);
	}, [fuse]);

	return useMemo(() => ({text, handleSearch, filteredList}), [text, filteredList, handleSearch]);
};
