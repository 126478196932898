import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IStructureType} from '@tehzor/tools/interfaces/structures/IStructureType';

export type IGetStructureTypesResponse = INormalizedData<IStructureType>;

/**
 * Возвращает список типов структур
 */
export const requestStructureTypes = async () => {
	const response = await httpRequests.withToken.get<IGetStructureTypesResponse>(
		'structure-types'
	);
	return response.data;
};
