import { memo, useState } from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import MobileInfo from './info/Info.mobile';
import {Plate} from '@tehzor/ui-components';
import Table from './table/Table';
import {useMobileColumns} from './table/columns.mobile';
import EntityAdding from './actions/EntityAdding';
import MobileSelectionClearing from './selection/SelectionClearing.mobile';
import MobileRightButtons from './actions/RightButtons.mobile';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {FromSpacePageBreadcrumbs} from './FromSpacePageBreadcrumbs';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useTranslation} from "react-i18next";

interface IMobileProps {
	objectId: string;
	ownerAcceptanceId: string;
	ownerAcceptance?: ILinkedOwnerAcceptance;
}

export const Mobile = memo(({objectId, ownerAcceptanceId, ownerAcceptance}: IMobileProps) => {
	const {t} = useTranslation();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const location = useLocation();
	const spaceId = (location.state as {spaceId?: string} | null)?.spaceId;
	const permissions = useOwnerAcceptancesPermissions(objectId);

	const mobileColumns = useMobileColumns(ownerAcceptanceId, objectId);

	useAppHeader(
		{
			title: ownerAcceptance
				? `${t('ownerAcceptancePage.useAppHeader.title')} №${ownerAcceptance.number}`
				: t('ownerAcceptancePage.useAppHeader.title'),
			showBackBtn: true,
			mobileLeftButton:
				Object.keys(selectedRows).length > 0 ? (
					<MobileSelectionClearing onSelectedRowsChange={setSelectedRows} />
				) : undefined,
			mobileRightButtons: (
				<MobileRightButtons
					objectId={objectId}
					ownerAcceptanceId={ownerAcceptanceId}
					ownerAcceptance={ownerAcceptance}
					selectedRows={selectedRows}
				/>
			)
		},
		[ownerAcceptance, selectedRows]
	);

	return ownerAcceptance ? (
		<div className="page-cont owner-acceptance-page">
			{spaceId ? (
				<FromSpacePageBreadcrumbs
					objectId={objectId}
					ownerAcceptance={ownerAcceptance}
				/>
			) : (
				<PageBreadcrumbs
					objectId={objectId}
					ownerAcceptance={ownerAcceptance}
				/>
			)}
			<MobileInfo
				objectId={objectId}
				ownerAcceptance={ownerAcceptance}
			/>
			<Plate
				style={{marginTop: '16px'}}
				withoutPadding
			>
				<Table
					objectId={objectId}
					columns={mobileColumns}
					hideHead
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
					ownerAcceptanceId={ownerAcceptanceId}
				/>
			</Plate>

			{permissions.canProblemsAdd && (
				<EntityAdding
					objectId={objectId}
					ownerAcceptance={ownerAcceptance}
				/>
			)}
		</div>
	) : null;
});
