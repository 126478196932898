import {queryClient} from '@src/api/QueryClient';
import {
	IDeleteCategoryResponse,
	IGetCategoriesResponse,
	requestDeleteCategory
} from '@src/api/backend/categories';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

export const useDeleteCategory = () =>
	useMutation<IDeleteCategoryResponse, IError, string>({
		mutationFn: (categoryId: string) => requestDeleteCategory(categoryId),

		onSuccess: (_, categoryId) => {
			queryClient.setQueryData<IGetCategoriesResponse>(
				categoriesQueryKeys.list(),
				oldData => {
					if (oldData) {
						const newAllIds = oldData.allIds.filter(id => categoryId !== id);
						const newById = newAllIds.reduce((prev, current) => {
							prev[current] = oldData.byId[current];
							return prev;
						}, {});

						return {
							allIds: newAllIds,
							byId: newById
						};
					}
					return oldData;
				}
			);
			addSuccessToast('Успех', 'Вид работ удален');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при удалении вида работ');
		}
	});
