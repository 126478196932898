import {IGetDocumentTypesResponse} from '@src/api/backend/documentTypes/get';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractDocumentTypesAsArray, extractDocumentTypesById} from './selectors';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';

export const useDocumentTypes = <T = IGetDocumentTypesResponse>(
	select?: (data: IGetDocumentTypesResponse) => T
) =>
	useQuery<IGetDocumentTypesResponse, IError, T>({
		queryKey: documentsQueryKeys.types(),
		meta: {
			error: 'при загрузке типов документов'
		},
		select
	});

export const useExtractDocumentTypesAsArray = () => useDocumentTypes(extractDocumentTypesAsArray);
export const useExtractDocumentTypesById = () => useDocumentTypes(extractDocumentTypesById);
