import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useProblemSettings} from '@src/core/hooks/settings/useProblemSettings';
import {useCustomFieldById} from '@src/core/hooks/queries/fields/hooks';
import {ValueTypeId} from '@tehzor/tools/interfaces/fields';
import {CustomFilterSelectPage} from '@src/pages/filters/CustomFiltersPages/CustomFilterSelectPage';
import {CustomFilterDatePage} from '@src/pages/filters/CustomFiltersPages/CustomFilterDatePage';
import {ICustomFiltersData} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useCustomFiltersHandlers} from '@src/components/CustomFilters/hooks/useCustomFiltersHandlers';

export const CustomFiltersPage = () => {
	const {
		objectId = 'all',
		entity,
		fieldKey
	} = useStrictParams<{objectId?: string; entity: string; fieldKey: string}>();
	const {custom} = useProblemSettings(objectId);
	const {data: field} = useCustomFieldById(custom[fieldKey].fieldId);
	const {
		state: {custom: filtersData},
		change
	} = useEntitiesFiltersCtx<{custom?: ICustomFiltersData}>();

	const [onChange, onClear] = useCustomFiltersHandlers(change, filtersData);

	if (!field) return null;
	const filterData = filtersData?.[fieldKey];
	switch (field.valueType.id) {
		case ValueTypeId.SELECT:
			return (
				<CustomFilterSelectPage
					objectId={objectId}
					entity={entity}
					fieldData={field}
					filterValue={
						filterData && filterData.valueTypeId === ValueTypeId.SELECT
							? filterData.value
							: undefined
					}
					onChange={onChange}
					onClear={onClear}
					key={field.id}
				/>
			);
		case ValueTypeId.DATE:
			return (
				<CustomFilterDatePage
					objectId={objectId}
					entity={entity}
					fieldData={field}
					filterValue={
						filterData && filterData.valueTypeId === ValueTypeId.DATE
							? filterData.value
							: undefined
					}
					onChange={onChange}
					onClear={onClear}
					key={field.id}
				/>
			);
		default:
			return null;
	}
};
