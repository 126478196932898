import {queryClient} from '@src/api/QueryClient';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {findMutation} from './findMutation';
import IError from '@tehzor/tools/interfaces/IError';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {IAddInternalAcceptanceParams} from '@src/api/cache/internalAcceptances/defaults/useInternalAcceptancesMutationDefaults';

export const transferInternalAcceptance = (entity: IOfflineJournalEntity['entity']) => {
	const {entityId} = entity;
	const mutationCache = queryClient.getMutationCache();
	const [[, cachedData]] = queryClient.getQueriesData<IInternalAcceptance>({
		queryKey: internalAcceptancesQueryKeys.detail(entityId)
	});

	if (cachedData) {
		const {objectId, stage, links, id} = cachedData;
		const currentMutation = findMutation(id);
		if (currentMutation) {
			void currentMutation.execute({objectId, stage, links, key: id});
			return;
		}
		const mutation = mutationCache.build<
			unknown,
			IError,
			IAddInternalAcceptanceParams,
			unknown
		>(queryClient, {
			mutationKey: internalAcceptancesQueryKeys.add()
		});
		void mutation.execute({objectId, stage, links, key: id});
	}
};
