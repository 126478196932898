import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {IEditProblemResponse} from '@src/api/backend/problem';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';

export type IEditProblemPayload = IEditProblemResponse;

const request = () => ({type: types.EDIT_REQUEST});

const optimistic = (response: IEditProblemResponse) => {
	addWarningToast('Локальное изменение', 'нарушение изменнено локально');
	return {
		type: types.EDIT_SUCCESS,
		payload: response
	};
};

const success = (response: IEditProblemResponse) => ({
		type: types.EDIT_SUCCESS,
		payload: response
	});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении нарушения');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

export const editProblemActions = {request, optimistic, success, failure};
