import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';

/**
 * Проверяет данные на актуальность
 *
 * @param getData функция, принимающая состояние и возвращающая данные, содержащие expires
 * @param action действие, выполняющееся если данные неактуальны
 * @param expireAfter срок актуальности данных (1 день по умолчанию)
 */
export const checkExpiration
	= <S, R, A extends Action>(
		getData: (state: S) => {loaded?: boolean, loadedAt?: number},
		action: A,
		expireAfter = 86400000 // 1 день
	): ThunkAction<Promise<R>, S, never, A> =>
	(dispatch, getState): Promise<R> => {
		const state = getState();
		const data = getData(state);
		const expired
			= typeof data !== 'object'
			|| !data.loaded
			|| data.loadedAt === undefined
			|| Date.now() >= data.loadedAt + expireAfter;
		return (expired ? dispatch(action) : Promise.resolve(data)) as Promise<R>;
	};
