import {isServiceWorkerSupported} from '@src/core/offlineMode/utils/isServiceWorkerSupported';
import urlBase64ToUint8Array from '@tehzor/tools/utils/urlBase64ToUint8Array';

/**
 * Подписывается на получение push уведомлений
 *
 * @param {string} publicKey публичный ключ для шифрования
 */
async function subscribeOnWebPush(publicKey: string) {
	const registration = await navigator.serviceWorker.ready;
	const sub = await registration.pushManager.getSubscription();
	if (sub) {
		return sub;
	}
	const convertedKey = urlBase64ToUint8Array(publicKey);
	return registration.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: convertedKey
	});
}

/**
 * Отписывается от получения push уведомлений
 */
async function unsubscribeFromWebPush() {
	if (!isServiceWorkerSupported()) {
		return;
	}

	const registration = await navigator.serviceWorker.ready;
	if ('pushManager' in registration) {
		const sub = await registration.pushManager.getSubscription();
		if (sub) {
			await sub.unsubscribe();
		}
	}
}

/**
 * Запрашивает разрешение на показ уведомлений
 */
const requestPermission = () =>
	new Promise((resolve, reject) => {
		const permissionResult = Notification.requestPermission(result => {
			// Поддержка устаревшей версии с функцией обратного вызова
			resolve(result);
		});
		if (permissionResult) {
			permissionResult.then(resolve, reject);
		}
	});

export {subscribeOnWebPush, unsubscribeFromWebPush, requestPermission};
