import {CellProps} from 'react-table';
import {Tag} from '@tehzor/ui-components';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {useObjectsAsArrayByIds} from '@src/core/hooks/queries/objects/hooks';

export const ObjectsCell = ({row}: CellProps<IEnrichedProblemTagsSet>) => {
	const rowObjects = row.original.objects;
	const objectsById = useObjectsAsArrayByIds(rowObjects);

	if (!objectsById) return null;

	return (
		<>
			{objectsById.map((object, index, arr) => {
				if (index < 2) {
					return (
						<Tag
							className="objectTag"
							key={object.id}
							label={object.name}
						/>
					);
				}
				if (index === 2) {
					return (
						<Tag
							className="objectTag"
							key={object.id}
							label={`+${arr.length - 2}`}
						/>
					);
				}
				return null;
			})}
		</>
	);
};
