import { useMemo } from 'react';
import * as React from 'react';
import {format} from 'date-fns';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

const addressIcon = <i className="tz-address-20" />;
const numberIcon = <i className="tz-number-20" />;
const bookClassifierIcon = <i className="tz-classifier-20" />;
const commentIcon = <i className="tz-comment-20" />;
const phoneIcon = <i className="tz-phone-20" />;
const mailIcon = <i className="tz-mail-20" />;
const calendarIcon = <i className="tz-calendar-20" />;

type ILegalMainContentItemId = keyof Omit<
	ILegalEntity,
	'createdAt' | 'createdBy' | 'modifiedAt' | 'modifiedBy' | 'company'
>;

interface ILegalMainContentItem {
	id: ILegalMainContentItemId;
	icon: React.ReactNode;
	name: string;
	isDate?: boolean;
}

const data: Record<string, ILegalMainContentItem[]> = {
	Основное: [
		{
			id: 'legalAddress',
			icon: addressIcon,
			name: 'Юридический адрес'
		},
		{
			id: 'mailingAddress',
			icon: addressIcon,
			name: 'Почтовый адрес'
		},
		{
			id: 'inn',
			icon: numberIcon,
			name: 'ИНН'
		},
		{
			id: 'kpp',
			icon: numberIcon,
			name: 'КПП'
		},
		{
			id: 'ogrn',
			icon: numberIcon,
			name: 'ОГРН'
		},
		{
			id: 'okpo',
			icon: bookClassifierIcon,
			name: 'ОКПО'
		},
		{
			id: 'okved',
			icon: bookClassifierIcon,
			name: 'ОКВЭД'
		},
		{
			id: 'comment',
			icon: commentIcon,
			name: 'Комментарий'
		}
	],
	Контакты: [
		{
			id: 'phone',
			icon: phoneIcon,
			name: 'Телефон'
		},
		{
			id: 'email',
			icon: mailIcon,
			name: 'E-mail'
		}
	],
	'Свидетельство о государственной регистрации': [
		{
			id: 'registrationCertificateDate',
			icon: calendarIcon,
			name: 'Дата',
			isDate: true
		},
		{
			id: 'registrationCertificateNumber',
			icon: numberIcon,
			name: 'Номер'
		}
	],
	'СРО строительства': [
		{
			id: 'buildingSroDate',
			icon: calendarIcon,
			name: 'Дата',
			isDate: true
		},
		{
			id: 'buildingSroNumber',
			icon: numberIcon,
			name: 'Номер'
		}
	],
	'СРО проектирования': [
		{
			id: 'designSroDate',
			icon: calendarIcon,
			name: 'Дата',
			isDate: true
		},
		{
			id: 'designSroNumber',
			icon: numberIcon,
			name: 'Номер'
		}
	]
};

export const useLegalMainContent = (legal?: ILegalEntity) =>
	useMemo(() => {
		if (!legal) return [];

		const entriesData = Object.entries(data);
		const finalElements: React.ReactNode[] = [];

		for (const entriesItem of entriesData) {
			const [title, items] = entriesItem;
			let countElements = 0;

			const finalItem = (
				<div
					key={title}
					className="legal-page__main-content-section"
				>
					<span className="legal-page__main-content-section-title">{title}</span>

					{items.map(item => {
						const itemId = item.id;
						const field = legal[itemId];
						let date: string | null = null;

						if (item.isDate && field && typeof field === 'number') {
							date = format(field, dateFormat);
						}

						const itemName = date || field;

						if (!itemName) {
							return null;
						}

						countElements++;

						return (
							<div
								key={itemId}
								className="legal-page__main-content-section-item"
							>
								<div className="legal-page__main-content-section-item-icon">
									{item.icon}
								</div>
								<div className="legal-page__main-content-section-item-icon-name">
									{item.name}
								</div>
								<div className="legal-page__main-content-section-item-name">
									{itemName}
								</div>
							</div>
						);
					})}
				</div>
			);

			if (!countElements) {
				continue;
			}

			finalElements.push(finalItem);
		}

		return finalElements;
	}, [legal]);
