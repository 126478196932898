import {TFunction} from "i18next";

export enum offlineJournalActionStatus {
	WITH_ERRORS = 'C ошибками',
	WITHOUT_ERRORS = 'Без ошибок'
}

export const getEntityStatuses = (tFunc: TFunction) => (
	Object.entries(offlineJournalActionStatus).map(([key, value]) => ({
		id: value,
		name: tFunc(`offlineJournalActionStatus.${key}`)
	}))
)