import * as React from 'react';
import './FloatingActionButton.less';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import {motion, Variants} from 'framer-motion';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';
import {getScrollContainer} from '@tehzor/tools/utils/getScrollContainer';

const btnAnimation: Variants = {
	normal: {
		width: 'auto',
		transition: {
			duration: 0.15,
			delay: 0.2
		}
	},
	minified: {
		width: 52,
		transition: {
			duration: 0.15,
			delay: 0.3
		}
	}
};

const labelAnimation: Variants = {
	visible: {
		visibility: 'visible',
		opacity: 1,
		transition: {
			duration: 0.2,
			delay: 0.3
		}
	},
	hidden: {
		opacity: 0,
		transition: {
			duration: 0.2,
			delay: 0.2
		},
		transitionEnd: {visibility: 'hidden'}
	}
};

interface IFloatingActionButtonProps {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				icon?: string;
				label?: string;
		  };
	style?: React.CSSProperties;
	icon?: React.ReactNode;
	label?: React.ReactNode;
	minifyOnScroll?: boolean;
	scrollContainer?: HTMLElement | string;
	threshold?: number;
	disabled?: boolean;

	onClick?: (event: React.MouseEvent) => void;
}

interface IFloatingActionButtonState {
	minified: boolean;
}

class FloatingActionButton extends React.PureComponent<
	IFloatingActionButtonProps,
	IFloatingActionButtonState
> {
	private scrollContainer?: HTMLElement | Window;

	private handleScroll = rafThrottle(() => {
		if (this.scrollContainer) {
			const threshold = this.props.threshold ?? 10;
			const y =
				this.scrollContainer === window
					? this.scrollContainer.pageYOffset
					: (this.scrollContainer as HTMLElement).scrollTop;
			if (this.state.minified !== y > threshold) {
				this.setState(s => ({minified: !s.minified}));
			}
		}
	});

	constructor(props: IFloatingActionButtonProps) {
		super(props);

		this.state = {minified: false};
	}

	componentDidMount() {
		const {minifyOnScroll, scrollContainer} = this.props;
		if (minifyOnScroll && scrollContainer) {
			this.scrollContainer = getScrollContainer(scrollContainer);
			this.scrollContainer.addEventListener('scroll', this.handleScroll);
		}
	}

	componentWillUnmount() {
		if (this.scrollContainer) {
			this.scrollContainer.removeEventListener('scroll', this.handleScroll);
			this.scrollContainer = undefined;
		}
	}

	render() {
		const {className, style, icon, label, onClick, disabled} = this.props;
		const {minified} = this.state;

		const classes = convertClassNames(className);

		return (
			<motion.button
				className={classNames('fab', classes.root)}
				style={style}
				disabled={disabled}
				animate={minified ? 'minified' : 'normal'}
				variants={btnAnimation}
				onClick={onClick}
			>
				<div className="fab__wrap">
					{icon && <div className={classNames('fab__icon', classes.icon)}>{icon}</div>}

					{label && (
						<motion.div
							className={classNames('fab__label', classes.label)}
							animate={minified ? 'hidden' : 'visible'}
							variants={labelAnimation}
						>
							{label}
						</motion.div>
					)}
				</div>
			</motion.button>
		);
	}
}

export default FloatingActionButton;
