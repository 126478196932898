import INormalizedData from '../../../interfaces/INormalizedData';

export interface IEntity {
	id: string;
}

export type IEntitiesState<E> = INormalizedData<E>;

export const getNormalizedEntities = <E extends IEntity>(
	state: IEntitiesState<E>,
	{payload}: {payload: INormalizedData<E>}
) => ({
	byId: payload.byId,
	allIds: payload.allIds
});

export const alterEntity = <E extends IEntity>(state: IEntitiesState<E>, {payload}: {payload: E}) => ({
	byId: {
		...state.byId,
		[payload.id]: {...payload}
	},
	allIds: Array.from(new Set(state.allIds.concat(payload.id)))
});

export const removeEntity = <E extends IEntity>(state: IEntitiesState<E>, {payload}: {payload: Pick<E, 'id'>}) => {
	if (!payload.id) {
		return state;
	}
	const byId = {...state.byId};
	delete byId[payload.id];
	return {
		byId,
		allIds: state.allIds.filter(id => id !== payload.id)
	};
};
