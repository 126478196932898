import {extractTasksAsArray} from '@src/core/hooks/queries/tasks/selectors';
import {IGetTasksResponse} from '@src/api/backend/tasks';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';

export const useProblemTasks = <T = IGetTasksResponse>(
	problemId?: string,
	select?: (data: IGetTasksResponse) => T,
	options?: Partial<UseQueryOptions<IGetTasksResponse, IError>>
) =>
	useQuery<IGetTasksResponse, IError, T>({
		queryKey: tasksQueryKeys.list({problems: [problemId]}, {createdAt: false}, 0, 1000),
		meta: {
			error: 'при загрузке списка задач'
		},
		...options,
		select
	});

export const useProblemTasksArray = (
	problemId: string,
	options?: Partial<UseQueryOptions<IGetTasksResponse, IError>>
) => useProblemTasks(problemId, extractTasksAsArray, options);
