import * as types from './constants';
import {ICheckAddingEntityType} from './reducers/entityType';

/**
 * Меняет выбранную сущность для добавления в проверку
 *
 * @param value новое значение
 */
export const changeCheckAddingEntityType = (value: ICheckAddingEntityType) => ({
	type: types.CHANGE_ENTITY_TYPE,
	payload: value
});
