import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IExportSettingsState} from '@src/reducers/settings/export';

const getAll = (state: IState) => state.settings.export.selectedTemplates || {};
const getPageKey = (state: IState, pageKey: string) => pageKey;

/**
 * Возвращает дефолтное значение варианта выбора местоположения для объекта
 */
export const getSelectedTemplate = createSelector(
	[getAll, getPageKey],
	(all: IExportSettingsState['selectedTemplates'], key: string) => all[key]
);
