import { memo, useState } from 'react';
import './ListDetail.desktop.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {CheckDetailTabs, ITabItem} from '../../Tabs/CheckDetailTabs';
import {ProblemsTable} from '../../ProblemsTable';
import {TasksTable} from '../../TasksTable';
import {InspectionsTable} from '../../InspectionsTable';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from "react-i18next";

const tabsClassNames = {
	links: 'check-list-detail__tabs-links',
	link: 'check-list-detail__tabs-link'
};

export const DesktopListDetail = memo(() => {
	const {t} = useTranslation();
	const {listId} = useStrictParams<{listId: string}>();
	const {data: list} = useExtractCheckListById(listId);
	const [activeTab, setActiveTab] = useState(0);

	const hideInspections =
		list?.stage === ObjectStageIds.WARRANTY || list?.stage === ObjectStageIds.TRANSFER;

	const tabsContent: ITabItem[] = [{
		value: t('checkListPage.detail.listDetail.tabsContent.problems.value'),
		content: <ProblemsTable list={list} />
	}];
	if (!hideInspections) {
		tabsContent.push({
			value: t('checkListPage.detail.listDetail.tabsContent.inspections.value'),
			content: <InspectionsTable list={list} />
		});
	}
	tabsContent.push({
		value: t('checkListPage.detail.listDetail.tabsContent.tasks.value'),
		content: <TasksTable list={list} />
	});

	if (!list) {
		return null;
	}

	return (
		<div className="check-list-detail__container">
			<div className="check-list-detail__scrollable">
				<CheckDetailTabs
					tabs={tabsContent}
					tabsClassNames={tabsClassNames}
					onTabClick={setActiveTab}
					activeTab={activeTab}
					itemsClassName="check-list-detail__items"
				/>
			</div>
		</div>
	);
});
