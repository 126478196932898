import {useQueryClient} from '@tanstack/react-query';
import {spacesQueryKeys} from '../keys';
import {IChangedIndicators} from './useSpacesMutationDefaults';

export const useSpacesIndicatorsChangesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(spacesQueryKeys.indicators.localList(), {
		queryFn: () => {
			const localChanges = queryClient.getQueriesData<IChangedIndicators>({
				queryKey: spacesQueryKeys.indicators.details()
			});
			return localChanges.map(([, change]) => change) as IChangedIndicators[];
		},
		staleTime: 0,
		gcTime: Infinity,
		networkMode: 'offlineFirst'
	});
};
