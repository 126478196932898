import {InlineButton} from '@tehzor/ui-components';
import './AddingSuccessToast.less';

interface IAddingSuccessToast {
	title: string;
	onLinkClick?: () => void;
}

export const AddingSuccessToast = (props: IAddingSuccessToast) => {
	const {title, onLinkClick} = props;

	return (
		<div className="adding-success-toast__body">
			<div className="adding-success-toast__body-text">
				<div className="adding-success-toast__body-text__title">{title}</div>
			</div>
			<div className="adding-success-toast__body-actions">
				<InlineButton
					labelClassName="adding-success-toast__body-actions__button"
					onClick={onLinkClick}
					type="accent"
					label="Открыть"
				/>
			</div>
		</div>
	);
};
