import {Column} from 'react-table';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';

export const mobileColumns: Array<Column<IStructureEntity>> = [
	{
		id: 'info',
		Cell: CellWrap({
			problem: ProblemInfoMobileCell,
			inspection: InspectionInfoMobileCell
		})
	}
];
