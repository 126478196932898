import {Column} from 'react-table';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import ProblemTagsSetInfoMobileCell from '../ProblemTagsSetInfoMobileCell';

export const mobileColumns: Array<Column<IEnrichedProblemTagsSet>> = [
	{
		id: 'info',
		Cell: ProblemTagsSetInfoMobileCell,
		className: 'manage-problem-tags-sets-page__mobile-cell'
	}
];
