import { useCallback, useState } from 'react';
import * as React from 'react';
import AddingWarrantyClaimDialog from '../AddingWarrantyClaimDialog';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';

interface IAddingWarrantyClaimDialogProps {
	objectId: string;
	spaceId?: string;
	claimId?: string;
	links?: IWarrantyClaim['links'];
	defaultProblemData?: ISavingProblem;
	onSuccess?: () => void | Promise<void>;
}

export function useAddingWarrantyClaimDialog({
	objectId,
	spaceId,
	claimId,
	links,
	defaultProblemData,
	onSuccess
}: IAddingWarrantyClaimDialogProps): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = isOpen ? (
		<AddingWarrantyClaimDialog
			objectId={objectId}
			spaceId={spaceId}
			claimId={claimId}
			links={links}
			defaultProblemData={defaultProblemData}
			isOpen={isOpen}
			onClose={close}
			onSuccess={onSuccess}
		/>
	) : null;

	return [dialog, open];
}
