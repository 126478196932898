import {Column} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {TFunction} from 'i18next';
import {StructureNameCell} from '../../ui/Cells/StructureNameCell/StructureNameCell';
import {StructureTypeCell} from '../../ui/Cells/StructureTypeCell/StructureTypeCell';
import {CategoriesCell} from '../../ui/Cells/CategoriesCell/CategoriesCell';
import {CheckListsCell} from '../../ui/Cells/CheckListsCell/CheckListsCell';
import {WorkAcceptancesStatsCell} from '../../ui/Cells/WorkAcceptancesStatsCell/WorkAcceptancesStatsCell';

export const getDesktopColumns = (tFunc: TFunction): Array<Column<IEnrichedStructure>> => [
	{
		id: 'name',
		accessor: 'name',
		Header: tFunc('structuresPage.table.columns.name.header'),
		Cell: StructureNameCell,
		width: 110,
		minWidth: 80
	},
	{
		id: 'type',
		accessor: 'type',
		Header: tFunc('structuresPage.table.columns.type.header'),
		Cell: StructureTypeCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'categories',
		accessor: 'categories',
		Header: tFunc('structuresPage.table.columns.categories.header'),
		Cell: CategoriesCell,
		width: 110,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'checkLists',
		accessor: 'checkLists',
		Header: tFunc('structuresPage.table.columns.checkLists.header'),
		Cell: CheckListsCell,
		width: 110,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'workAcceptances',
		accessor: 'workAcceptances',
		Header: tFunc('structuresPage.table.columns.workAcceptances.header'),
		Cell: WorkAcceptancesStatsCell,
		width: 110,
		minWidth: 80,
		disableSortBy: true
	}
];
