import {forwardRef, Ref, MouseEvent} from 'react';
import './InlineButton.less';
import ButtonBase, {IButtonBaseProps, OuterTagProps, OuterTagType} from '../ButtonBase';
import classNames from 'classnames';

export type ButtonType = 'common' | 'accent' | 'cancel' | 'filled';

export interface IInlineButtonProps extends Omit<IButtonBaseProps, 'classNamePrefix'> {
	type?: ButtonType;
	outerTagType?: OuterTagType;
	outerTagProps?: OuterTagProps;
	dataTestId?: string;

	onClick?(event: MouseEvent): void;
}

const InlineButton = (props: IInlineButtonProps, ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>) => {
	const {className, type = 'common', dataTestId, ...rest} = props;
	const typedClass = type && !rest.disabled ? `inline-button_${type}` : undefined;

	return (
		<ButtonBase
			{...rest}
			dataTestId={dataTestId}
			className={classNames(typedClass, className)}
			classNamePrefix="inline-button"
			ref={ref}
		/>
	);
};

InlineButton.displayName = 'InlineButton';

export default forwardRef(InlineButton);
