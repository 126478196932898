interface ClassNameType {
	[key: string]: string | undefined;
	root?: string;
}

/**
 * Преобразует классы из формата строки или объекта в объект
 *
 * @param className
 */
export function convertClassNames<T extends ClassNameType>(className?: string | T): T {
	if (typeof className === 'string') {
		return {root: className} as T;
	}
	return className ?? ({} as T);
}
