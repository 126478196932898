import {Navigate, useLocation} from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import NotFoundPage from '../../pages/NotFoundPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useCurrentObjectByExternalId} from '@src/core/hooks/queries/objects/hooks';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';

export const ExternalObjectRoot = () => {
	const {externalId} = useStrictParams<{externalId: string}>();

	useAsync(async () => {
		await queryClient.refetchQueries({queryKey: objectsQueryKeys.list()});
	}, []);

	const {pathname} = useLocation();
	const object = useCurrentObjectByExternalId(externalId);
	if (object) {
		return <Navigate to={pathname.replace(`external/${externalId}`, object.id)} />;
	}
	return <NotFoundPage />;
};
