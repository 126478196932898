import { useCallback, useState } from 'react';
import * as React from 'react';
import {SpaceSelectDialog} from './SpaceSelectDialog';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';

export const useSpaceSelectDialog = (
	spaces: Array<{
		id: string;
		name: string;
		type: SpaceTypeId;
	}>,
	onSuccess: ({
		structureId,
		spaceId
	}: {
		structureId?: string;
		spaceId?: string;
	}) => void | Promise<void>,
	entityAddingDialog?: React.ReactNode | Record<string, never> | null,
	value?: string
): [JSX.Element, () => void] => {
	const [spaceSelectDialogIsOpen, setSpaceSelectDialogOpen] = useState<boolean>(false);

	const openSpaceSelectDialog: () => void = useCallback(() => {
		setSpaceSelectDialogOpen(true);
	}, []);

	const closeSpaceSelectDialog = useCallback(() => {
		setSpaceSelectDialogOpen(false);
	}, []);

	const handleSuccess = (val: string) => {
		closeSpaceSelectDialog();
		void onSuccess({spaceId: val});
	};

	const spaceSelectDialog = (
		<>
			{spaceSelectDialogIsOpen && (
				<SpaceSelectDialog
					spaces={spaces}
					isOpen={spaceSelectDialogIsOpen}
					value={value}
					onClose={closeSpaceSelectDialog}
					onSuccess={handleSuccess}
				/>
			)}
			{entityAddingDialog}
		</>
	);

	return [spaceSelectDialog, openSpaceSelectDialog];
};
