import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableWorkAcceptanceState, IEditableWorkAcceptanceAction, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

export const useEditableWorkAcceptanceState = (workAcceptance?: IWorkAcceptance) =>
	useReducer<
		Reducer<IEditableWorkAcceptanceState, IEditableWorkAcceptanceAction>,
		IWorkAcceptance | undefined
	>(createReducer(init, errorsFns), workAcceptance, init);
