import {useMemo} from 'react';
import {FloatingActionButton} from '@tehzor/ui-components';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from 'react-i18next';

const types: ICheckAddingEntityType[] = ['inspection'];
interface IMobileEntityAddingProps {
	objectId: string;
}

const MobileEntityAdding = ({objectId}: IMobileEntityAddingProps) => {
	const {t} = useTranslation();
	const {data: object} = useObject(objectId);

	const checksPermissions = useChecksPermissions(objectId);
	const internalAcceptancePermissions = useInternalAcceptancesPermissions(objectId);

	const permissions = useMemo(
		() => ({
			checks: checksPermissions.canAdd,
			internalAcceptances: internalAcceptancePermissions.canAdd
		}),
		[checksPermissions, internalAcceptancePermissions]
	);

	const [globalDialog, openGlobalDialog] = useGlobalAddingEntityDialog(
		objectId,
		undefined,
		undefined,
		object?.stage || ObjectStageIds.BUILDING,
		permissions,
		undefined,
		undefined,
		types
	);

	if (permissions.checks || permissions.internalAcceptances) {
		return (
			<>
				<FloatingActionButton
					icon={<i className="tz-plus-16" />}
					label={t('inspectionsPage.mobileEntityAdding.label')}
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={openGlobalDialog}
				/>

				{globalDialog}
			</>
		);
	}
	return null;
};

export default MobileEntityAdding;
