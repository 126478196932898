import {CSSProperties, Dispatch, useCallback} from 'react';
import {IEditableObjectState} from '@src/core/hooks/states/useEditableObjectState';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {StageField} from './StageField';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';

interface IStageFieldsProps<S, E> {
	className?:
		| string
		| {
				root?: string;
				title?: string;
				wrap?: string;
				stage?: string;
		  };
	style?: CSSProperties;
	value?: IEditableObjectState['stages'];
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	title?: string;
	disabled?: boolean;
	required?: boolean;
	hasError?: boolean;
	errorMessage?: string;
}

export const StageFields = <S, E>({
	className,
	style,
	value,
	editingDispatch,
	title,
	disabled,
	required,
	hasError,
	errorMessage
}: IStageFieldsProps<S, E>) => {
	const classes = convertClassNames(className);
	const translatedStages = useTranslatedObjectStagesArray();

	const handleChange = useCallback(
		(dateFrom: Date, dateTo: Date, stageId: ObjectStageIds) => {
			editingDispatch({
				field: 'stages' as keyof S,
				type: 'update',
				value: {...value, [stageId]: {from: dateFrom.getTime(), to: dateTo.getTime()}}
			});
		},
		[value]
	);

	const handleClear = useCallback(
		(stageId: ObjectStageIds) => {
			editingDispatch({
				field: 'stages' as keyof S,
				type: 'update',
				value: {...value, [stageId]: null}
			});
		},
		[value]
	);

	return (
		<div
			className={classes.root}
			style={style}
		>
			<div className={classes.title}>{title}</div>
			<div className={classes.wrap}>
				{translatedStages &&
					translatedStages.map(stage => (
						<StageField
							className={classes.stage}
							onChange={handleChange}
							onClear={handleClear}
							stage={stage}
							from={value?.[stage.id]?.from}
							to={value?.[stage.id]?.to}
							error={required && hasError}
							errorMessage={errorMessage}
							disabled={disabled}
							key={stage.id}
						/>
					))}
			</div>
		</div>
	);
};
