import {ISavingBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {IEditableBankAccountAction} from '@src/core/hooks/states/useEditableBankAccountState';
import {Dispatch} from 'react';

export const reset = (
	dispatch: Dispatch<IEditableBankAccountAction>,
	data?: ISavingBankAccount
) => {
	dispatch({
		field: 'bankName',
		type: 'update',
		value: data?.bankName || undefined
	});
	dispatch({
		field: 'accountNumber',
		type: 'update',
		value: data?.accountNumber || undefined
	});
	dispatch({
		field: 'bik',
		type: 'update',
		value: data?.bik || undefined
	});
	dispatch({
		field: 'correspondentAccountNumber',
		type: 'update',
		value: data?.correspondentAccountNumber || undefined
	});
	dispatch({
		field: 'comment',
		type: 'update',
		value: data?.comment || undefined
	});
};