import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Удаляет шаблон экспорта
 *
 * @param exportTemplateId id рабочей группы
 */

interface IDeleteExportTemplateResponse {
	success: boolean;
}

export const makeExportTemplateDeleteRequest = async (exportTemplateId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteExportTemplateResponse>(
		`export-templates/${exportTemplateId}`
	);
	return response.data;
};
