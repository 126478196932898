import { useMemo, memo } from 'react';
import {PageHeader} from '@tehzor/ui-components';
import './OfflineJournalPageCounter.less';
import {useTranslation} from "react-i18next";

interface IOfflineJournalPageCounterProps {
	length: number;
	isFiltersActive?: boolean;
}

export const OfflineJournalPageCounter = memo((props: IOfflineJournalPageCounterProps) => {
	const {t} = useTranslation();
	const {length, isFiltersActive = false} = props;

	// isFilterActive нужен при фильтрации (Найдено, Всего)
	const text = useMemo(
		() =>
			`${isFiltersActive ?
				t('offlineJournalPage.offlineJournalPageCounter.found'):
				t('offlineJournalPage.offlineJournalPageCounter.total')
			} ${length}`,
		[length, isFiltersActive]
	);

	return (
		<PageHeader
			className="offline-journal-page-counter"
			title=""
			subTitle={text}
			wrapClassName="offline-journal-page-counter__header-wrap"
		/>
	);
});
