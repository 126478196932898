import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {CellProps} from 'react-table';

export const ProblemsInspectorsCell = ({row}: CellProps<IProblemEntity>) => {
	const {inspectors, inspectorsActiveGroup} = row.original.data;

	return inspectors ? (
		<div className="problems-page__d-table-inspectors">
			{inspectorsActiveGroup && (
				<div className="problems-page__d-table-inspectors-group">
					{inspectorsActiveGroup}
				</div>
			)}
			{inspectors?.[0] && (
				<div className="problems-page__d-table-inspectors-user">
					<div className="problems-page__d-table-inspectors-user-name">
						{inspectors[0]?.displayName}
					</div>
					<div className="problems-page__d-table-inspectors-user-company">
						{inspectors[0]?.companies?.[0]?.name}
					</div>
				</div>
			)}
			{inspectors.length > 1 && (
				<div className="problems-page__d-table-inspectors-rest">
					+{inspectors.length - 1}
				</div>
			)}
		</div>
	) : null;
};
