import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as problemReplyTypes from '@src/store/modules/entities/problemReply/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import {IGetInternalAcceptancePayload} from '@src/store/modules/entities/internalAcceptance/actions';
import {IGetInternalAcceptanceProblemRepliesPayload} from './actions/problemReplies';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IAddProblemCommentPayload} from '@src/store/modules/entities/problemComment/actions';;
import {IEditInternalAcceptancePayload} from './actions/edit';

const {entitiesCacheTime} = config;

export interface IInternalAcceptanceState {
	data?: ILinkedInternalAcceptance;
	expires?: number;
	problemReplies: {
		byId: Record<string, IComment>;
		allIds: string[];
		expires?: number;
	};
}

export const getInitialState = (): IInternalAcceptanceState => ({
	problemReplies: {
		byId: {},
		allIds: []
	}
});

const updateInternalAcceptance = (
	state: IInternalAcceptanceState,
	{payload}: {payload: IEditInternalAcceptancePayload}
) =>
	state.data?.id !== payload.id
		? state
		: {
				...state,
				data: payload,
				expires: Date.now() + entitiesCacheTime * 1000
		  };

export const internalAcceptance = createReducer<IInternalAcceptanceState>(getInitialState(), {
	[types.GET_REQUEST]: getInitialState,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetInternalAcceptancePayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_SUCCESS]: updateInternalAcceptance,

	[types.GET_PROBLEM_REPLIES_SUCCESS]: (
		state,
		{payload}: {payload: IGetInternalAcceptanceProblemRepliesPayload}
	) => {
		state.problemReplies.byId = payload.byId;
		state.problemReplies.allIds = payload.allIds;
		state.problemReplies.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Ответы на нарушения
	[problemReplyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemCommentPayload}) => {
		if (state.data?.id === payload.links?.internalAcceptanceId) {
			state.problemReplies.byId[payload.id] = payload;
			state.problemReplies.allIds.unshift(payload.id);
		}
	},
	[CLEAR_STORE]: getInitialState
});
