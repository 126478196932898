import {CSSProperties, Dispatch, ReactNode, useCallback} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {useTranslation} from 'react-i18next';

interface INameFieldProps<S, E> {
	className?: string;
	style?: CSSProperties;
	label?: ReactNode;
	field?: keyof S;
	addon?: ReactNode;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: string;
	onChange?: (value: string) => void;
}

export const NameField = <S, E>({
	className,
	style,
	label = 'Название',
	field = 'name' as keyof S,
	addon,
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	errorMessage,
	onChange
}: INameFieldProps<S, E>) => {
	const {t} = useTranslation();
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
			if (onChange) {
				onChange(v);
			}
		},
		[required, onChange, field]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel addon={addon}>{label}</EditableFieldLabel>

			<TextField
				value={value}
				elementType="input"
				error={
					required && hasError
						? errorMessage || t('components.editableFields.name.error')
						: undefined
				}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};
