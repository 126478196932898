import { memo, useCallback } from 'react';
import * as React from 'react';
import './DesktopHeaderMenuItem.less';
import classNames from 'classnames';
import ButtonBase from '../../../../buttons/ButtonBase';

interface IDesktopHeaderMenuItemProps {
	className?: string;
	style?: React.CSSProperties;
	url: string;
	label: React.ReactNode;
	active?: boolean;
	dataTestId?: string;

	onClick?(url: string): void;
}

const DesktopHeaderMenuItem = (props: IDesktopHeaderMenuItemProps) => {
	const {className, style, label, url, active, dataTestId, onClick} = props;

	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			event.preventDefault();
			if (onClick) {
				onClick(url);
			}
		},
		[url, onClick]
	);

	return (
		<ButtonBase
			className={classNames({'d-header-menu-item_active': active}, className)}
			style={style}
			classNamePrefix="d-header-menu-item"
			outerTagType="a"
			outerTagProps={{href: url}}
			label={label}
			onClick={handleClick}
			dataTestId={dataTestId}
		/>
	);
};

export default memo(DesktopHeaderMenuItem);
