import {LinkButton, Plate} from '@tehzor/ui-components';
import { memo } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useSpaceTasksAsArray} from '@src/core/hooks/queries/space';
import {useTranslation} from 'react-i18next';

const externalLinkIcon = <i className="tz-external-link-16" />;

interface ISpaceTasksLinksDesktopProps {
	spaceId: string;
}

export const SpaceTasksLinksDesktop = memo((props: ISpaceTasksLinksDesktopProps) => {
	const {t} = useTranslation();

	const {spaceId} = props;
	const {data: tasks} = useSpaceTasksAsArray(spaceId);
	const {pushPath} = useChangePath();

	if (!tasks?.length) return null;

	return (
		<Plate className={{content: 'space-page__info-plate'}}>
			<div className="space-page__info-title">{t('spacePage.tasksLinks.title')}</div>
			<div className="space-page__tasks">
				{tasks.map(task => (
					<LinkButton
						type="filled"
						className="space-page__tasks-link"
						leftIcon={externalLinkIcon}
						onClick={() => pushPath(`/tasks/${task.id}`)}
						label={task.name}
					/>
				))}
			</div>
		</Plate>
	);
});
