import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useProblemStatusesAsArray} from '@src/core/hooks/queries/problemStatuses/hooks';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

const SpacesProblemStatusesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();
	const {data: statuses} = useProblemStatusesAsArray();
	const {t} = useTranslation();

	if (!statuses) return null;
	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={statuses}
			translationDictionaryKey={dictionaryKeys.problemStatuses}
			label={t('problemsPage.statusesFilter.label')}
		/>
	);
};

export default SpacesProblemStatusesFilterPage;
