import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useTranslation} from "react-i18next";

interface IMobileCellInfoProps {
	number?: number;
	localNumber?: string;
	description?: string;
	createdBy?: IBriefUser;
	createdAt: string;
	modifiedAt: string;
	modifiedBy?: IBriefUser;
}

export const MobileCellInfo = ({
	number,
	localNumber,
	description,
	createdAt,
	modifiedAt,
	createdBy,
	modifiedBy
}: IMobileCellInfoProps) => {
	const {t} = useTranslation();

	return (
		<>
			<div className="internal-acceptances-page__mobile-cell-header">
				<div className="internal-acceptances-page__mobile-cell-number">
					№
					{number ?? localNumber}
				</div>
			</div>
			<div className="internal-acceptances-page__mobile-cell-description">{description}</div>
			<div className="internal-acceptances-page__mobile-cell-created">
				<div className="internal-acceptances-page__mobile-cell-created-label">
					{t('internalAcceptancesPage.table.columns.createdAt.header')}:
				</div>
				<div className="internal-acceptances-page__mobile-cell-created-container">
					<div className="internal-acceptances-page__mobile-cell-created-date">
						{createdAt}
					</div>
					<div className="internal-acceptances-page__mobile-cell-created-name">
						{createdBy?.fullName}
					</div>
				</div>
			</div>
			{modifiedBy ? (
				<div className="internal-acceptances-page__mobile-cell-created">
					<div className="internal-acceptances-page__mobile-cell-created-label">
						{t('internalAcceptancesPage.table.columns.modifiedAt.header')}:
					</div>
					<div className="internal-acceptances-page__mobile-cell-created-container">
						<div className="internal-acceptances-page__mobile-cell-created-date">
							{modifiedAt}
						</div>
						<div className="internal-acceptances-page__mobile-cell-created-name">
							{modifiedBy?.fullName}
						</div>
					</div>
				</div>
			) : null}
		</>
	)
};
