import {requestRegulatoryStandards} from '@src/api/backend/regulatoryStandards';
import {useQueryClient} from '@tanstack/react-query';
import {regulatoryStandardsQueryKeys} from '../keys';

export const useRegulatoryStandardsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(regulatoryStandardsQueryKeys.list(), {
		networkMode: 'offlineFirst',
		queryFn: requestRegulatoryStandards,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
