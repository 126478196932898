import { forwardRef, memo, Ref, useCallback, useEffect, useImperativeHandle } from 'react';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useEditableOwnerAcceptance} from '@src/components/EditableOwnerAcceptance/hooks/useEditableOwnerAcceptance';
import {ISavingOwnerAcceptance} from '@src/interfaces/saving/ISavingOwnerAcceptance';
import {useAddOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useAddOwnerAcceptance';
import {AddingEntities} from '@src/api/mutations';
import {useObject} from '@src/core/hooks/queries/objects/hooks';

interface IAddingAcceptanceProps {
	objectId: string;
	spaceId?: string;
	links?: IOwnerAcceptance['links'];
	saving: boolean;

	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IAddingAcceptanceRefProps {
	getSavingData: (useLocalFiles?: boolean) => Promise<ISavingOwnerAcceptance | undefined>;
	save: (
		savingData?: ISavingOwnerAcceptance
	) => Promise<string | undefined | void> | string | void;
	saveCache?: (
		savingData?: ISavingOwnerAcceptance,
		extraLinks?: IOwnerAcceptance['links']
	) => Promise<
		| {
				cacheKey: string;
				type: AddingEntities;
				links: IOwnerAcceptance['links'];
		  }
		| undefined
	>;
	cancel: () => void;
}

const AddingAcceptance = (
	{objectId, spaceId, links, saving, onClose, setSaving}: IAddingAcceptanceProps,
	ref?: Ref<IAddingAcceptanceRefProps>
) => {
	const [fields, getSavingData, , isBlocking] = useEditableOwnerAcceptance(
		spaceId,
		undefined,
		saving
	);

	useEffect(() => () => setSaving(false), [setSaving]);

	const {data: object} = useObject(objectId);
	const addOwnerAcceptance = useAddOwnerAcceptance(object);
	const save = useCallback(
		async (savingData?: ISavingOwnerAcceptance) => {
			let id: string | undefined;

			setSaving(true);
			if (savingData) {
				try {
					const ownerAcceptance = await addOwnerAcceptance({
						objectId,
						links,
						fields: savingData
					});
					id = ownerAcceptance.id;
				} catch (error) {
					setSaving(false);
					throw error;
				}
			}

			return id;
		},
		[objectId, links, getSavingData]
	);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	useImperativeHandle(ref, () => ({save, cancel, getSavingData}), [save, cancel]);

	return (
		<>
			{fields}
			{closingDialog}
		</>
	);
};

export default memo(
	forwardRef<IAddingAcceptanceRefProps, IAddingAcceptanceProps>(AddingAcceptance)
);
