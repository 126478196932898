import {MobileFiltersLinkBtn} from '@src/components/MobileFiltersLinkBtn';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {DisplayModeSwitchMobile} from '../../components/DisplayModeSwitchMobile/DisplayModeSwitchMobile';
import {CentralBarMobile} from '@/shared/ui/EntitiesPage/CentralBarMobile/CentralBarMobile';
import {SchemaStructuresTypesMenu} from '@/pages/StructuresPage/components/SchemaStructuresTypesMenu/SchemaStructuresTypesMenu';

interface ICentralBarMobileProps {
	objectId: string;
}

export const StructuresCentralBarMobile = ({objectId}: ICentralBarMobileProps) => {
	const {state} = useEntitiesFiltersCtx();

	return (
		<CentralBarMobile>
			<DisplayModeSwitchMobile objectId={objectId} />
			<SchemaStructuresTypesMenu objectId={objectId} />
			<MobileFiltersLinkBtn
				state={state}
				objectId={objectId}
				entity="structures"
			/>
		</CentralBarMobile>
	);
};
