import { memo } from 'react';
import {LinkButton} from '@tehzor/ui-components';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface EntityTasksItemsProps {
	tasks?: ITask[];
}

const TASKS_COUNT = 4;
const taskIcon = <i className="tz-task-16" />;

export const EntityTasksItems = memo((props: EntityTasksItemsProps) => {
	const {tasks} = props;
	const {pushPath} = useChangePath();

	if (!tasks?.length) return null;

	return (
		<div className="entity-tasks__content">
			<div className="entity-tasks__content-items">
				{tasks.slice(0, TASKS_COUNT).map(task => (
					<LinkButton
						key={task.id}
						type="filled"
						className="entity-tasks__content-link"
						leftIcon={taskIcon}
						onClick={() => pushPath(`/tasks/${task.id}`)}
						label={task.name}
					/>
				))}
			</div>
		</div>
	);
});
