export type ISpaceTypesDecorationsQueryKey = [] & {
	0: string; //space-type-decorations-by-id,
	1: string; //typeDecorationSetId
};

export const spaceTypesDecorationsQueryKeys = {
	all: () => ['space-type-decorations'],
	one: () => ['space-type-decoration'],
	allById: () => ['space-type-decorations-by-id'],
	list: () => ['space-type-decorations', 'list'],
	listById: (id?: string) => [...spaceTypesDecorationsQueryKeys.allById(), id],
	add: () => [...spaceTypesDecorationsQueryKeys.one(), 'add'],
	edit: () => [...spaceTypesDecorationsQueryKeys.one(), 'edit']
};
