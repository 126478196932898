import {queryClient} from '@src/api/QueryClient';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {findMutation} from './findMutation';
import IError from '@tehzor/tools/interfaces/IError';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {IAddWarrantyClaimParams} from '@src/api/cache/warrantyClaims/defaults/useWarrantyClaimsMutationDefaults';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';

export const transferWarrantyClaim = (entity: IOfflineJournalEntity['entity']) => {
	const {entityId} = entity;
	const mutationCache = queryClient.getMutationCache();
	const [[, cachedData]] = queryClient.getQueriesData<IListWarrantyClaim>({
		queryKey: warrantyClaimsQueryKeys.detail(entityId)
	});
	if (cachedData) {
		const {
			objectId,
			links,
			id,
			spaceOwner,
			replyEmail,
			replyPhone,
			claimerName,
			registrationDate,
			attachments
		} = cachedData;
		const fields: ISavingWarrantyClaim = {
			spaceOwnerId: spaceOwner?.id,
			replyEmail,
			replyPhone,
			claimerName,
			registrationDate,
			newAttachments: attachments
		};
		const currentMutation = findMutation(id);
		if (currentMutation) {
			void currentMutation.execute({objectId, fields, links, key: id});
			return;
		}
		const mutation = mutationCache.build<unknown, IError, IAddWarrantyClaimParams, unknown>(
			queryClient,
			{
				mutationKey: warrantyClaimsQueryKeys.add()
			}
		);
		void mutation.execute({objectId, links, fields, key: id});
	}
};
