/**
 * Определяет угол между векторами {p2; p1} и {p2; p3}
 * @param {Object} p1 точка с координатами x и y
 * @param {Object} p2 точка с координатами x и y
 * @param {Object} p3 точка с координатами x и y
 * #return {Number} угол в радианах [0; PI*2)
 */
export function determineAngle(p1, p2, p3) {
	// Определяем координаты векторов
	const v1 = [p1.x - p2.x, p1.y - p2.y];
	const v2 = [p3.x - p2.x, p3.y - p2.y];
	// Находим векторное произведение
	const det = v1[0] * v2[1] - v2[0] * v1[1];
	// Находим угол
	const cos = (v1[0] * v2[0] + v1[1] * v2[1]) / Math.sqrt(Math.pow(v1[0], 2) + Math.pow(v1[1], 2)) / Math.sqrt(Math.pow(v2[0], 2) + Math.pow(v2[1], 2));
	// console.log(cos, Math.round10(cos, -15));
	// if (cos > 1) cos = 1;
	// if (cos < -1) cos = -1;
	let angle = Math.acos(Math.round10(cos, -15));
	// Если векторное произведение < 0
	if (det < 0) {
		// Меняем знак у угла
		angle = 2 * Math.PI - angle;
	}
	return angle;
}

/**
 * Определяет угол между векторами {p2; p1} и {1; 0}
 * @param {Object} p1 точка с координатами x и y
 * @param {Object} p2 точка с координатами x и y
 * #return {Number} угол в радианах [0; PI*2)
 */
export function determinePolarAngle(p1, p2) {
	// Определяем координаты векторов
	const v1 = [p2.x - p1.x, p2.y - p1.y];
	const v2 = [1, 0];
	// Находим векторное произведение
	const det = v1[0] * v2[1] - v2[0] * v1[1];
	// Находим угол
	const cos = (v1[0] * v2[0] + v1[1] * v2[1]) / Math.sqrt(Math.pow(v1[0], 2) + Math.pow(v1[1], 2));
	// if (cos > 1) cos = 1;
	// if (cos < -1) cos = -1;
	let angle = Math.acos(Math.round10(cos, -15));
	// Если векторное произведение < 0
	if (det < 0) {
		// Меняем знак у угла
		angle = 2 * Math.PI - angle;
	}
	return angle;
}