import {findPermission} from '@tehzor/tools/utils/findPermission';
import useAppSelector from '../useAppSelector';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export const useCheckListStatusPermission = (
	permissionId: string,
	objectId: string
): {canStatusEdit: boolean; availableStatuses: string[] | undefined} => {
	const user = useAppSelector(s => s.auth.profile);
	const statusEditPermission =
		user &&
		user.roles &&
		findPermission(user.roles, permissionId, UserRoleScopes.OBJECT, objectId);
	const canStatusEdit = !!statusEditPermission;
	const availableStatuses: string[] | undefined = statusEditPermission?.restrictions
		?.availableStatuses as string[] | undefined;
	return {canStatusEdit, availableStatuses};
};
