import {createContext, ReactNode, useCallback} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useDeleteTask} from '@src/core/hooks/mutations/task/useDeleteTask';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IEnrichedTask>) => void
>(() => ({}));

interface IMenuActionCtxProviderProps {
	children: ReactNode;
}
export const MenuActionCtxProvider = ({children}: IMenuActionCtxProviderProps) => {
	const {mutateAsync: deleteTask} = useDeleteTask();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить задачу?',
		'Все связанные данные будут безвозвратно удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IEnrichedTask>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				const deleteTaskParams = {
					taskId: action.payload.id,
					objectId: action.payload.objectId?.id,
					workAcceptanceId: action.payload?.links?.workAcceptanceId
				};
				await deleteTask(deleteTaskParams);
			}
		},
		[deleteTask, getDeleteConfirmation]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
