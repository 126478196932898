import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {CellProps} from 'react-table';
import {useEditingTaskDialog} from '@src/components/EditingTaskDialog';
import {LinkButton} from '@tehzor/ui-components';
import {IEnrichedTaskForScheduler} from "@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler";

export const EditButton = ({row}: Pick<CellProps<IEnrichedTaskForScheduler>, 'row'>) => {
	const task = row.original;
	const permissions = useTasksPermissions({
		objectId: task?.objectId?.id,
		createdBy: task?.createdBy?.id,
		respUsers: task?.respUsers?.map(user => (user.id)),
		watchers: task?.watchers,
		activeGroupLeader: task?.activeGroupLeader?.id
	});
	const [taskEditDialog, handleOpenEditTaskDialog] = useEditingTaskDialog({taskId: task.id});

	return (
		<>
			{permissions.canEdit && (
				<>
					<LinkButton
						onClick={handleOpenEditTaskDialog}
						leftIcon={<i className="tz-edit"/>}
					/>
					{taskEditDialog}
				</>
			)}
		</>
	);
};
