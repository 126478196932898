import { useCallback, useState } from 'react';
import {Plate} from '@tehzor/ui-components';
import {MobileLinks} from '../../../links/Links.mobile';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import TitleButtons from '../../../TitleButtons.mobile';
import TitleObjectButtons from '../../../TitleObjectButtons.mobile';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {
	useExtractCheckListById,
	useExtractCheckListsAsArrayByObjectId
} from '@src/core/hooks/queries/checkLists/hooks';
import {TitleWorkAcceptanceButtons} from '@src/pages/WorkAcceptancePage/components/TitleButtons.mobile';
import {CheckDetailTabs, ITabItem} from '../../Tabs/CheckDetailTabs';
import {CheckListItems} from '../../../list';
import {ProblemsTable} from '../../ProblemsTable';
import {TasksTable} from '../../TasksTable';
import {CheckListMobile} from '../../../CheckListMobile/CheckListMobile';
import './ListDetail.mobile.less';
import {InspectionsTable} from '../../InspectionsTable';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {ListDetailHeaderMenu} from '@src/pages/CheckListPage/components/ListDetailHeaderMenu/ListDetailHeaderMenu';
import {useTranslation} from "react-i18next";

const tabsClassNames = {
	links: 'check-list-detail__tabs-links',
	link: 'check-list-detail__tabs-link'
};

export const ListDetailMobile = () => {
	const params = useStrictParams<{
		objectId: string;
		listId: string;
		spaceId?: string;
		workAcceptanceId?: string;
	}>();
	const {t} = useTranslation();
	const {objectId, listId, spaceId, workAcceptanceId} = params;
	const {data: checkList} = useExtractCheckListById(listId);
	const {data: checkLists} = useExtractCheckListsAsArrayByObjectId(objectId);
	const checkListPermissions = useCheckListPermissions();

	const hideInspections =
		checkList?.stage === ObjectStageIds.WARRANTY ||
		checkList?.stage === ObjectStageIds.TRANSFER;
	const [activeTab, setActiveTab] = useState(0);

	const tabsContent: ITabItem[] = [
		{
			value: t('checkListPage.detail.listDetail.tabsContent.categories.value'),
			content: (
				<CheckListItems
					withoutHeader
					mode="main"
				/>
			)
		},
		{
			value: t('checkListPage.detail.listDetail.tabsContent.problems.value'),
			content: <ProblemsTable list={checkList} />
		}
	];
	if (!hideInspections) {
		tabsContent.push({
			value: t('checkListPage.detail.listDetail.tabsContent.inspections.value'),
			content: <InspectionsTable list={checkList} />
		});
	}
	tabsContent.push({
		value: t('checkListPage.detail.listDetail.tabsContent.tasks.value'),
		content: <TasksTable list={checkList} />
	});

	const getTitleButtons = useCallback(() => {
		if (workAcceptanceId) {
			return (
				<TitleWorkAcceptanceButtons
					objectId={objectId}
					workAcceptanceId={workAcceptanceId}
				/>
			);
		}

		if (spaceId) {
			return (
				<TitleButtons
					objectId={objectId}
					spaceId={spaceId}
				/>
			);
		}

		return <TitleObjectButtons objectId={objectId} />;
	}, [objectId, spaceId, workAcceptanceId]);

	useAppHeader(
		{
			title: `${t('checkListPage.detail.listDetailMobile.useAppHeader.title')} ${checkList?.name ?? ''}`,
			showBackBtn: true,
			titleButtons: getTitleButtons(),
			mobileRightButtons: (
				<ListDetailHeaderMenu
					checkList={checkList}
					checkListPermissions={checkListPermissions}
					spaceId={spaceId}
					workAcceptanceId={workAcceptanceId}
					objectId={objectId}
				/>
			)
		},
		[objectId, spaceId, getTitleButtons, listId, checkList, checkListPermissions]
	);

	if (!checkList || !checkLists) {
		return null;
	}

	const type = spaceId ? CheckListTypeId.OPERATIONS : CheckListTypeId.ACCEPTANCES;
	return (
		<div className="check-list-mobile-detail">
			<Plate
				withoutPadding
				className="check-list-mobile-detail__info"
			>
				<CheckListMobile
					type={type}
					checkList={checkList}
				/>
				{(!spaceId || !workAcceptanceId) && (
					<MobileLinks
						objectId={objectId}
						spaceId={spaceId}
					/>
				)}
			</Plate>
			<Plate
				withoutPadding
				className="check-list-mobile-detail__tabs"
			>
				<CheckDetailTabs
					tabs={tabsContent}
					onTabClick={setActiveTab}
					activeTab={activeTab}
					tabsClassNames={tabsClassNames}
				/>
			</Plate>
		</div>
	);
};
