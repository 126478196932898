import { useCallback } from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {Button, IconButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {exportWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useTranslation} from "react-i18next";

const availableDestinations = [ExportTemplatesDestinationId.WARRANTY_CLAIM];

interface IClaimExportDialog {
	objectId: string;
	warrantyClaimId: string;
}

function ClaimExportDialog(props: IClaimExportDialog) {
	const {t} = useTranslation();
	const {objectId, warrantyClaimId} = props;
	const [isOpen, toggleOpen] = useToggle(false);

	const dispatch = useAppDispatch();

	const exportClaim = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await dispatch(
				exportWarrantyClaim(
					template.id,
					createDocument,
					email,
					objectId,
					warrantyClaimId,
					undefined
				)
			);
			toggleOpen(false);
		},
		[objectId, warrantyClaimId]
	);

	const isDesktop = useIsDesktop();

	return (
		<>
			{isDesktop ? (
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-export-20" />}
					label={t('warrantyClaimPage.actions.claimExportDialog.buttonLabel')}
					onClick={toggleOpen}
				/>
			) : (
				<IconButton onClick={toggleOpen}>
					<i className="tz-export-24" />
				</IconButton>
			)}
			<ExportDialog
				pageKey="claim"
				destinations={availableDestinations}
				isOpen={isOpen}
				onExport={exportClaim}
				onClose={toggleOpen}
			/>
		</>
	);
}

export default ClaimExportDialog;
