import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import useAppSelector from '../useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

export interface IPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
	canAdministrate?: boolean;
}

export const useContractsPermissions = (): IPermissions => {
	const roles = useAppSelector(extractUserRoles);

	return useMemo(() => {
		if (!roles?.length) {
			return {};
		}
		return {
			canView: hasPermission(roles, 'contracts-view'),
			canAdd: hasPermission(roles, 'contracts-add'),
			canEdit: hasPermission(roles, 'contracts-edit'),
			canDelete: hasPermission(roles, 'contracts-delete'),
			canAdministrate: hasPermission(roles, 'contracts-administration')
		};
	}, [roles]);
};
