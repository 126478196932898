import {IGetWorkAcceptancesResponse} from '@src/api/backend/workAcceptances';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

/**
 * Возвращает приёмки работ в виде массива
 */
export const extractWorkAcceptancesAsArray = (data: IGetWorkAcceptancesResponse) => {
	if (!data) return undefined;
	return data.allIds.map((id: string) => data.byId[id]);
};

/**
 * Возвращает данные приёмки работ по id
 */
export const extractWorkAcceptanceById = (
	data: IGetWorkAcceptancesResponse,
	workAcceptanceId?: string
) => {
	if (!data || !workAcceptanceId) return undefined;
	return data.byId[workAcceptanceId];
};

/**
 * Возвращает приёмки работ для структуры в виде массива
 */
export const extractWorkAcceptancesForStructureAsArray = (
	data: IGetWorkAcceptancesResponse,
	structureId?: string
) => {
	const array = extractWorkAcceptancesAsArray(data);

	if (!array) return undefined;

	return array.filter(
		(workAcceptance: IListWorkAcceptance) =>
			structureId && workAcceptance.structureIds?.includes(structureId)
	);
};

/**
 * Возвращает приёмки работ для помещения в виде массива
 */
export const extractWorkAcceptancesForSpaceAsArray = (
	data: IGetWorkAcceptancesResponse,
	spaceId?: string
) => {
	const array = extractWorkAcceptancesAsArray(data);

	if (!array) return undefined;

	return array.filter(
		(workAcceptance: IListWorkAcceptance) =>
			spaceId && workAcceptance.spaceIds?.includes(spaceId)
	);
};

/**
 * Возвращает приёмки работ по структурам в виде массива
 */
export const extractWorkAcceptancesByStructuresAsRecord = (
	data: IGetWorkAcceptancesResponse,
	structureIds?: string[]
) => {
	const array = extractWorkAcceptancesAsArray(data);

	if (!array) return undefined;

	const response: Record<string, IListWorkAcceptance[]> = {};

	structureIds?.forEach((structureId: string) => {
		response[structureId] = array.filter(
			(workAcceptance: IListWorkAcceptance) =>
				workAcceptance.structureIds?.includes(structureId)
		);
	});

	return response;
};
