import {MobileDropDownMenu} from '@/shared/ui/MobileDropDownMenu/MobileDropDownMenu';
import {useActiveSchemaStructuresType} from '@/widgets/StructuresSchema/model/hooks/useActiveSchemaStructuresType';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {structuresActions} from '@/entities/Structures';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStructuresSchemaTypes} from '@src/core/hooks/queries/structureTypes/hooks';
import {memo, useCallback, useMemo} from 'react';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';

interface ISchemaStructuresTypesMenuProps {
	objectId: string;
}

export const SchemaStructuresTypesMenu = memo(({objectId}: ISchemaStructuresTypesMenuProps) => {
	useActiveSchemaStructuresType(objectId);
	const dispatch = useAppDispatch();
	const {changeSchemaStructureType} = structuresActions;
	const {schemaStructureTypeId} = useAppSelector(state =>
		extractStructuresSchemaPageSettings(state, objectId)
	);
	const {data: schemaStructuresTypes, isLoading} = useStructuresSchemaTypes(objectId);

	const preparedSchemaStructuresTypes = useMemo(
		() =>
			schemaStructuresTypes?.map(type => ({
				...type,
				name: `${type.name} (${type.structuresQuantity})`
			})),
		[schemaStructuresTypes]
	);

	const changeViewType = useCallback(
		(value: string) => {
			dispatch(changeSchemaStructureType({objectId, structureTypeId: value}));
		},
		[dispatch, changeSchemaStructureType, objectId]
	);

	const label = preparedSchemaStructuresTypes?.find(({id}) => id === schemaStructureTypeId)?.name;

	return (
		<MobileDropDownMenu
			value={schemaStructureTypeId}
			label={label}
			onChange={changeViewType}
			items={preparedSchemaStructuresTypes}
			isLoading={isLoading}
		/>
	);
});
