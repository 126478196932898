import {IEditableContractState} from '@src/core/hooks/states/useEditableContractState';
import {memo, useCallback, useMemo} from 'react';
import {SingleSelectContractField} from '../fields/SingleSelectContractField';
import {TreeSelectContractField} from '../fields/TreeSelectContractField';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {makePlansTreeData} from '@src/utils/makePlansTreeData';
import {useObjectIdsForCompanyOrAllIds, useObjectsMap} from '@src/core/hooks/queries/objects/hooks';
import {usePlansByObjectIds, usePlansByObjectIdsAsArray} from '@src/core/hooks/queries/plans/hooks';
import {
	useObjectStagesAsArray,
	useObjectStagesMap
} from '@src/core/hooks/queries/objectStages/hooks';
import {useExtractFilteredCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {
	useExtractAllCategoriesAsArray,
	useExtractCategoriesAsMap
} from '@src/core/hooks/queries/categories/hook';
import {makeTreeData} from '@src/core/hooks/queries/categorySets/utils/makeTreeData';

interface IMainBlockProps {
	editingState: IEditableContractState;
	isMobile?: boolean;

	onChange: (
		value: string | number | string[] | null | undefined,
		field: keyof IEditableContractState
	) => void;
	onError: (field: keyof IEditableContractState) => void;
}

export const MainBlock = memo(({editingState, isMobile, onChange, onError}: IMainBlockProps) => {
	const {companyId, stageId} = editingState;

	const {data: stages} = useObjectStagesAsArray();
	const {data: stagesMap} = useObjectStagesMap();

	const objectsMap = useObjectsMap();
	const objectIds = useObjectIdsForCompanyOrAllIds(companyId);
	const treeObjects = useMemo(() => {
		if (!objectIds || !objectsMap) return [];
		const objects = objectIds.map(id => objectsMap[id]);
		return makeObjectsTreeData(objects);
	}, [objectIds, objectsMap]);

	const handleChangeObjects = useCallback(
		(value?: string[] | null | undefined) => {
			onChange(value, 'objectIds');

			// Сброс связанных сущностей
			onChange(null, 'planIds');
			onChange(null, 'categoryIds');
		},
		[onChange]
	);
	const {data: categoriesMap} = useExtractCategoriesAsMap();
	const setsFilters = {
		objects: editingState.objectIds ? editingState.objectIds : objectIds,
		stages: stageId ? [stageId] : undefined
	};
	const {data: categoriesSets} = useExtractFilteredCategoriesSetsAsArray(setsFilters);
	const {data: allCategories} = useExtractAllCategoriesAsArray();
	const treeCategories = makeTreeData(categoriesSets, allCategories);

	const {data: plansData} = usePlansByObjectIds(editingState.objectIds || undefined);
	const plansMap = useMemo(() => plansData?.byId || {}, [plansData?.byId]);
	const {data: plans} = usePlansByObjectIdsAsArray(editingState.objectIds || undefined);

	const treePlans = useMemo(
		() =>
			objectsMap && plans
				? makePlansTreeData(plans, objectsMap, editingState.objectIds || undefined)
				: undefined,
		[editingState.objectIds, objectsMap, plans]
	);

	return (
		<div className="editable-contract__blocks-main">
			<div className="editable-contract__blocks-main-title">Основное</div>
			<div className="editable-contract__blocks-main-container">
				<SingleSelectContractField
					items={stages}
					itemsMap={stagesMap}
					label="Стадия"
					dialogTitle="Стадия"
					errorMessage="Укажите стадию"
					hasError={editingState.errors.stageId}
					required
					value={editingState.stageId}
					isMobile={isMobile}
					onConfirm={value => onChange(value, 'stageId')}
					onError={() => onError('stageId')}
				/>

				{objectsMap && (
					<TreeSelectContractField
						items={treeObjects}
						itemsMap={objectsMap}
						label="Объекты"
						dialogTitle="Объекты"
						errorMessage="Укажите объекты"
						hasError={editingState.errors.objectIds}
						value={editingState.objectIds}
						isMobile={isMobile}
						onConfirm={handleChangeObjects}
						onError={() => onError('objectIds')}
					/>
				)}

				{categoriesMap && (
					<TreeSelectContractField
						items={treeCategories}
						itemsMap={categoriesMap}
						label="Виды работы"
						dialogTitle="Виды работы"
						errorMessage="Укажите виды работ"
						hasError={editingState.errors.categoryIds}
						value={editingState.categoryIds}
						isMobile={isMobile}
						onConfirm={value => onChange(value, 'categoryIds')}
						onError={() => onError('categoryIds')}
					/>
				)}

				{treePlans && (
					<TreeSelectContractField
						items={treePlans}
						itemsMap={plansMap}
						label="Планы"
						dialogTitle="Планы"
						errorMessage="Укажите планы"
						hasError={editingState.errors.planIds}
						value={editingState.planIds}
						isMobile={isMobile}
						onConfirm={value => onChange(value, 'planIds')}
						onError={() => onError('planIds')}
					/>
				)}
			</div>
		</div>
	);
});
