import {IContract} from '@tehzor/tools/interfaces/contracts';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetContractResponse = IContract;

/**
 * Получает договор
 * @param contractId id договора
 */
export const requestGetContract = async (contractId: string) => {
	const res = await httpRequests.withToken.get<IGetContractResponse>(
		`contracts/get/${contractId}`
	);

	return res.data;
};