import {IEditableEntityState, IEditableEntityAction, isEntityEdited, isPropEdited} from '@tehzor/tools/core/states/editableEntityState';
import {IBankAccount, ISavingBankAccount} from '@tehzor/tools/interfaces/bankAccounts';

export type IEditableBankAccountState = IEditableEntityState<{
	bankName?: string;
	accountNumber?: string;
	bik?: string;
	correspondentAccountNumber?: string;
	comment?: string;
	isLocal?: boolean;
}>;

export type IEditableBankAccountAction = IEditableEntityAction<IEditableBankAccountState, ISavingBankAccount>;

const makeEmptyState = (): IEditableBankAccountState => ({
	bankName: '',
	accountNumber: '',
	bik: '',
	correspondentAccountNumber: '',
	comment: '',
	isLocal: false,
	errors: {
		bankName: false,
		accountNumber: false,
		bik: false,
		correspondentAccountNumber: false,
		comment: false,
		isLocal: false
	}
});

export const makeDefaultData = (
	bankAccount?: IBankAccount
): ISavingBankAccount | undefined => {
	if (!bankAccount) { return undefined; }

	return {
		bankName: bankAccount.bankName,
		accountNumber: bankAccount.accountNumber,
		bik: bankAccount.bik,
		correspondentAccountNumber: bankAccount.correspondentAccountNumber,
		comment: bankAccount.comment
	};
};

export const init = (
	original?: ISavingBankAccount
): IEditableBankAccountState => {
	const empty = makeEmptyState();
	if (!original) { return empty; }

	const state: IEditableBankAccountState = {
		...original, errors: empty.errors
	};
	return state;
};

export const isEdited = (
	state: IEditableBankAccountState, original?: IBankAccount
): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'bankName'),
		isPropEdited.bind(null, 'accountNumber'),
		isPropEdited.bind(null, 'bik'),
		isPropEdited.bind(null, 'correspondentAccountNumber'),
		isPropEdited.bind(null, 'comment')
	);

export const errorsFns = {
	bankName: (state: IEditableBankAccountState) =>
		!state.bankName,
	accountNumber: (state: IEditableBankAccountState) =>
		!state.accountNumber,
	bik: (state: IEditableBankAccountState) =>
		!state.bik,
	correspondentAccountNumber: (state: IEditableBankAccountState) =>
		!state.correspondentAccountNumber,
	comment: (state: IEditableBankAccountState) =>
		!state.comment
};

export const convertToSave = (
	state: IEditableBankAccountState,
	original?: IBankAccount
): ISavingBankAccount => {
	const defaultData = makeDefaultData(original);
	const bankAccount: ISavingBankAccount = {};

	if (isPropEdited('bankName', state, defaultData)) {
		bankAccount.bankName = state.bankName;
	}
	if (isPropEdited('accountNumber', state, defaultData)) {
		bankAccount.accountNumber = state.accountNumber;
	}
	if (isPropEdited('bik', state, defaultData)) {
		bankAccount.bik = state.bik;
	}
	if (isPropEdited('correspondentAccountNumber', state, defaultData)) {
		bankAccount.correspondentAccountNumber = state.correspondentAccountNumber;
	}
	if (isPropEdited('comment', state, defaultData)) {
		bankAccount.comment = state.comment;
	}

	return bankAccount;
};