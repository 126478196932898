import classNames from 'classnames';
import './Logo.less';

interface ILogoProps {
	className?: string;
	language?: string;
}

const Logo = ({className, language = 'ru'}: ILogoProps) => (
	<div className={className}>
		<i
			className={classNames(
				language === 'ru' ? 'tz-logo-cyrillic' : 'tz-logo-latin',
				'logo__icon'
			)}
		/>
	</div>
);

export default Logo;
