import { useCallback, useState } from 'react';
import classNames from 'classnames';
import './CheckListInfo.less';
import {IconButton, StatusButton} from '../../../buttons';
import {ExpandablePanel} from '../../../containers';
import {
	IConvertedCheckItemData,
	IConvertedCheckListData
} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';

interface IChecklistInfoProps {
	checkList: IConvertedCheckListData;
	openByDefault?: boolean;
}

export const CheckListInfo = ({openByDefault, checkList}: IChecklistInfoProps) => {
	const [isOpen, setIsOpen] = useState(openByDefault ?? true);
	const problems = checkList.problems || [];
	const onClick = useCallback(() => setIsOpen(s => !s), []);

	return (
		<div className="check-list-info">
			<div className="check-list-info__header">
				<div className="check-list-info__header-name">{checkList.name}</div>
				<IconButton
					onClick={onClick}
					className="check-list-info__header-arrow"
				>
					<i
						className={classNames(
							'tz-simple-arrow-24',
							'check-list-info__header-arrow-icon',
							{'check-list-info__header-arrow-icon--reversed': isOpen}
						)}
					/>
				</IconButton>
			</div>
			<div className="check-list-info__statuses">
				<StatusButton
					className="check-list-info__status"
					label={checkList.statusName}
					color={checkList.color}
					disabled
				/>
				{problems.map(({status, count}) => {
					if (count > 0) {
						return (
							<div
								key={status.name}
								className="check-list-info__statuses-problems"
							>
								<i
									className="tz-problem-16"
									style={{color: `${status.color}`}}
								/>
								<div className="check-list-info__statuses-problems__number">
									{count}
								</div>
							</div>
						);
					}
					return null;
				})}
			</div>
			<ExpandablePanel expanded={isOpen}>
				{checkList.items.map((item: IConvertedCheckItemData, index: number) => (
					<div
						key={item.id}
						className="check-list-info__item"
					>
						<div className="check-list-info__item-index">{`${index + 1}.`}</div>
						<div className="check-list-info__item-info">
							<div className="check-list-info__item-info__name">
								{item.name || ''}
							</div>
							<StatusButton
								className="check-list-info__item-info__status"
								label={item.statusName}
								color={item.color}
								disabled
							/>
						</div>
					</div>
				))}
			</ExpandablePanel>
		</div>
	);
};
