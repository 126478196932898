import * as React from 'react';
import './MobileHeaderMenuItem.less';
import classNames from 'classnames';
import ButtonBase from '../../../../buttons/ButtonBase';

interface IMobileHeaderMenuItemProps {
	className?: string;
	style?: React.CSSProperties;
	label?: React.ReactNode;
	active?: boolean;

	onClick?(event: React.MouseEvent): void;
}

const MobileHeaderMenuItem = (props: IMobileHeaderMenuItemProps) => {
	const {className, style, label, active, onClick} = props;

	return (
		<ButtonBase
			className={classNames({'m-header-menu-item_active': active}, className)}
			style={style}
			classNamePrefix="m-header-menu-item"
			outerTagType="button"
			label={label}
			onClick={onClick}
		/>
	);
};

export default MobileHeaderMenuItem;
