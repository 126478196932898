import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddProblemRespUserResponse = IAddingRespUser;

/**
 * Отправка письма с данными нового ответственного пользователя
 *
 * @param fields данные ответственного пользователя
 */

export const makeAddRespUserRequest = async (fields: IAddingRespUser) => {
	const response = await httpRequests.withToken.post<IAddProblemRespUserResponse>(
		'users/add-resp-user',
		fields
	);
	return response.data;
};
