import {StatusCode} from '../enums/StatusCode';

export class NoActiveConnectionError extends Error {
	code = StatusCode.OFFLINE;

	constructor(message: string) {
		super(message);
		this.name = 'NoActiveConnectionError';
	}
}
