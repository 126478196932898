import {useAsyncFn} from 'react-use';
import {
	EntityGridItem,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {
	IOwnerAcceptanceResolution,
	OwnerAcceptanceResolutionId
} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {useEditOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useEditOwnerAcceptance';
import {useTranslation} from "react-i18next";

interface ResolutionProps {
	objectId: string;
	ownerAcceptanceId: string;
	resolutions?: IOwnerAcceptanceResolution[];
	value: string | undefined;
	canEditResolution?: boolean;
}

const Resolution = ({
	objectId,
	ownerAcceptanceId,
	resolutions,
	value,
	canEditResolution
}: ResolutionProps) => {
	const {t} = useTranslation();
	const editOwnerAcceptance = useEditOwnerAcceptance();

	const [, handleResolutionChange] = useAsyncFn(
		async (v: OwnerAcceptanceResolutionId) => {
			await editOwnerAcceptance({
				objectId,
				acceptanceId: ownerAcceptanceId,
				fields: {resolution: v}
			});
		},
		[objectId, ownerAcceptanceId]
	);

	if (!resolutions) return null;

	return (
		<EntityGridItem
			label={t('ownerAcceptancePage.info.resolution.label')}
			icon={<i className="tz-description-20" />}
			fullRow
		>
			{canEditResolution ? (
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={
								resolutions.find(({id}) => id === value && id !== 'undefined')
									?.name
							}
							icon={<i className="tz-simple-arrow-20" />}
						/>
					}
				>
					<Select2
						value={value}
						onChange={handleResolutionChange}
					>
						{resolutions.map(item => (
							<SelectOption
								key={item.id}
								itemKey={item.id}
								content={item.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			) : (
				<div className="owner-acceptance-page__resolution">
					{resolutions.find(item => item.id === value)?.name}
				</div>
			)}
		</EntityGridItem>
	);
};
export default Resolution;
