import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {IWorkAcceptanceFrontType} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {TFunction} from 'i18next';

export const createFrontTypeDifference = (
	prev: string | undefined,
	next: string | undefined,
	typesMap: {
		[id: string]: IWorkAcceptanceFrontType;
	},
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev: prev ? typesMap[prev]?.name : undefined,
	next: next ? typesMap[next]?.name : undefined,
	type: HistoryTypeId.DESCRIPTION,
	name: t('createDifference.createFrontTypeDifference')
});
