import {forwardRef, memo, Ref, useCallback} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {IDescriptionProps} from './IDescriptionProps';
import {useTranslation} from 'react-i18next';

const defaultTextAreaProps = {
	minRows: 2,
	maxRows: 6
};

const Description = <S extends {description: string}, E>(
	{
		className,
		style,
		label = 'Описание',
		addon,
		value,
		editingDispatch,
		required,
		disabled,
		hasError,
		onChange,
		textAreaProps = defaultTextAreaProps,
		...restProps
	}: IDescriptionProps<S, E>,
	ref?: Ref<HTMLTextAreaElement>
) => {
	const {t} = useTranslation();
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'description', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'description'});
			}
			if (onChange) {
				onChange(v);
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
			{...restProps}
		>
			<EditableFieldLabel addon={addon}>{label}</EditableFieldLabel>

			<TextField
				value={value}
				elementRef={ref}
				elementType="textarea"
				elementProps={textAreaProps}
				error={
					required && hasError
						? t('components.editableFields.description.error')
						: undefined
				}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default memo(
	forwardRef<
		HTMLTextAreaElement,
		IDescriptionProps<Record<string, unknown> & {description: string}, unknown>
	>(Description)
);
