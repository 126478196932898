import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';

export type IEditableCheckListState = IEditableEntityState<{
	name?: string;
	companyId?: string;
	objects?: string[];
	stage?: ObjectStageIds;
	spaceTypes?: SpaceTypeId[];
	structureType?: string;
	type?: CheckListTypeId;
	typeDecoration?: string[];
	categories?: string[];
	frontType?: WorkAcceptanceFrontTypeId;
}>;

export type IEditableCheckListAction = IEditableEntityAction<
	IEditableCheckListState,
	{checkList?: ICheckList}
>;

const makeEmptyState = (): IEditableCheckListState => ({
	name: undefined,
	companyId: undefined,
	objects: [],
	stage: undefined,
	spaceTypes: [],
	structureType: undefined,
	type: undefined,
	typeDecoration: [],
	categories: [],
	frontType: undefined,
	errors: {
		name: false,
		companyId: false,
		type: false,
		typeDecoration: false,
		spaceTypes: false,
		structureType: false,
		frontType: false
	}
});

export const init = ({checkList}: {checkList?: ICheckList}): IEditableCheckListState => {
	const empty = makeEmptyState();

	return checkList
		? {
				name: checkList.name,
				companyId: checkList.companyId,
				objects: checkList.objects,
				stage: checkList.stage,
				spaceTypes: checkList.spaceTypes,
				structureType: checkList.structureType,
				type: checkList.type,
				typeDecoration: checkList.typeDecoration,
				categories: checkList.categories,
				frontType: checkList.frontType,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (
	state: IEditableCheckListState,
	original?: ICheckList & {items?: ICheckItem[]}
): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'companyId'),
		isPropEdited.bind(null, 'objects'),
		isPropEdited.bind(null, 'spaceTypes'),
		isPropEdited.bind(null, 'structureType'),
		isPropEdited.bind(null, 'type'),
		isPropEdited.bind(null, 'stage'),
		isPropEdited.bind(null, 'typeDecoration'),
		isPropEdited.bind(null, 'categories'),
		isPropEdited.bind(null, 'frontType')
	);

export const errorsFns = {
	name: (state: IEditableCheckListState) => !state.name,
	companyId: (state: IEditableCheckListState) => !state.companyId,
	objects: (state: IEditableCheckListState) => !state.objects,
	stage: (state: IEditableCheckListState) => !state.stage,
	spaceTypes: (state: IEditableCheckListState) => !state.spaceTypes?.length,
	structureType: (state: IEditableCheckListState) => !state.structureType,
	type: (state: IEditableCheckListState) => !state.type,
	typeDecoration: (state: IEditableCheckListState) => !state.typeDecoration?.length,
	categories: (state: IEditableCheckListState) => !state.categories?.length,
	frontType: (state: IEditableCheckListState) => !state.frontType
};

export const convertCheckListToSave = (
	state: IEditableCheckListState,
	original?: ICheckList,
	onlyEdited?: boolean
): ISavingCheckList => {
	if (!onlyEdited) {
		return state;
	}

	const checkList: ISavingCheckList = {};

	if (isPropEdited('name', state, original)) {
		checkList.name = state.name;
	}
	if (isPropEdited('companyId', state, original)) {
		checkList.companyId = state.companyId;
	}
	if (isPropEdited('objects', state, original)) {
		checkList.objects = state.objects;
	}
	if (isPropEdited('stage', state, original)) {
		checkList.stage = state.stage;
	}
	if (isPropEdited('spaceTypes', state, original)) {
		checkList.spaceTypes = state.spaceTypes;
	}
	if (isPropEdited('structureType', state, original)) {
		checkList.structureType = state.structureType;
	}
	if (isPropEdited('type', state, original)) {
		checkList.type = state.type;
	}
	if (isPropEdited('typeDecoration', state, original)) {
		checkList.typeDecoration = state.typeDecoration;
	}
	if (isPropEdited('categories', state, original)) {
		checkList.categories = state.categories;
	}
	if (isPropEdited('frontType', state, original)) {
		checkList.frontType = state.frontType;
	}

	return checkList;
};
