import INormalizedData from '../../../interfaces/INormalizedData';
import {alterEntity, getNormalizedEntities, IEntity, IEntitiesState, removeEntity} from './common';

export interface ILoadableEntitiesState<E> extends IEntitiesState<E> {
	loaded: boolean;
	loadedAt?: number;
	loading?: boolean;
}

export const getLoadableEntitiesInitialState = <E extends IEntity>(): ILoadableEntitiesState<E> => ({
	byId: {},
	allIds: [],
	loaded: false,
	loading: false
});

export const handleLoadableEntitiesGetting
	= <E extends IEntity>() =>
	(state: ILoadableEntitiesState<E>, {payload}: {payload: INormalizedData<E>}) => ({
		...getNormalizedEntities(state, {payload}),
		loaded: true,
		loadedAt: Date.now(),
		loading: false
	});

export const handleLoadableEntityAdding
	= <E extends IEntity>() =>
	(state: ILoadableEntitiesState<E>, {payload}: {payload: E}) => ({
		...alterEntity(state, {payload}),
		loaded: state.loaded,
		loadedAt: state.loadedAt
	});

export const handleLoadableEntityEditing
	= <E extends IEntity>() =>
	(state: ILoadableEntitiesState<E>, {payload}: {payload: E}) => ({
		...alterEntity(state, {payload}),
		loaded: state.loaded,
		loadedAt: state.loadedAt
	});

export const handleLoadableEntityDeletion
	= <E extends IEntity>() =>
	(state: ILoadableEntitiesState<E>, {payload}: {payload: Pick<E, 'id'>}) => ({
		...removeEntity(state, {payload}),
		loaded: state.loaded,
		loadedAt: state.loadedAt
	});

export const handleLoadableEntityReset
	= <E extends IEntity>() =>
	(state: ILoadableEntitiesState<E>) => ({
		...state,
		loaded: false
	});

export const handleLoadableEntitiesStartLoading
	= <E extends IEntity>() =>
	(state: ILoadableEntitiesState<E>) => ({
		...state,
		loading: true
	});

export const handleLoadableEntitiesStopLoading
	= <E extends IEntity>() =>
	(state: ILoadableEntitiesState<E>) => ({
		...state,
		loading: false
	});