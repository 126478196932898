import {memo, useCallback} from 'react';
import classNames from 'classnames';
import {IStructureDiagramData} from '../../model/interfaces';
import styles from './BoardItem.module.less';
import {Diagram} from '@tehzor/ui-components';

interface IBoardItemProps {
	title?: string;
	diagrams: IStructureDiagramData[];
	onClick: (structureId: string) => void;
}

export const BoardItem = memo(({title, diagrams, onClick}: IBoardItemProps) => {
	const clickHandler = useCallback(
		(filtered: boolean, id: string) => {
			if (filtered) {
				onClick(id);
			}
		},
		[onClick]
	);

	return (
		<div className={styles.wrapper}>
			<p className={styles.title}>{title}</p>
			<div className={styles.list}>
				{diagrams.map(({id, name, filtered, innerSectors, outerSectors}) => (
					<div
						className={classNames(
							styles.listItem,
							!filtered && styles.listItem_disabled
						)}
						key={id}
						onClick={() => clickHandler(filtered, id)}
					>
						<Diagram
							innerSectors={innerSectors}
							outerSectors={outerSectors}
							className={{
								innerCell: styles.innerCell
							}}
							disabled={!filtered}
						/>
						<p className={styles.name}>{name}</p>
					</div>
				))}
			</div>
		</div>
	);
});
