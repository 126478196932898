import { useCallback, useContext } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from './WorkAcceptancesTable';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';

const deleteIcon = <i className="tz-delete" />;

export const MenuCell = ({row}: CellProps<IPreparedWorkAcceptance>) => {
	const {canDelete} = row.original;

	const dispatchAction = useContext(DispatchActionCtx);

	const deleteCheck = useCallback(() => {
		dispatchAction({type: 'delete', payload: row.original});
	}, [row.original, dispatchAction]);

	const menuActions = [];
	if (canDelete) {
		menuActions.push(
			<IconButton
				key="delete"
				className="menu-item_red"
				onClick={deleteCheck}
			>
				{deleteIcon}
			</IconButton>
		);
	}
	return menuActions.length ? (
		<ClickPreventWrap className="work-acceptances-page__d-table-menu-wrap">
			{menuActions}
		</ClickPreventWrap>
	) : null;
};
