import {IGetPaginateInspectionsResponse} from '@src/api/backend/inspections';
import {IGetOwnerAcceptancesResponse} from '@src/api/backend/ownerAcceptances';
import {IGetPaginateProblemsResponse} from '@src/api/backend/problems';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {isEveryKeyIncluded} from '@src/utils/isEveryKeyIncluded';
import {removeStaleOfflineEntities} from '@src/utils/removeStaleOfflineEntities';
import {addErrorToast} from '@src/utils/toasts';
import {QueryCache, QueryClient} from '@tanstack/react-query';
import {IGetChecksResponse} from './backend/checks';

export const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (_, query) => {
			if (query.meta?.error) {
				addErrorToast('Ошибка', query.meta.error);
			}
		},
		onSuccess: (response, query) => {
			// Временное решение, от которого нужно избавится в максимально короткие сроки.
			if (isEveryKeyIncluded(problemsQueryKeys.list(), query.queryKey)) {
				removeStaleOfflineEntities(
					response as IGetPaginateProblemsResponse,
					problemsQueryKeys.savingData,
					problemsQueryKeys.localList
				);
			}
			if (isEveryKeyIncluded(checksQueryKeys.list(), query.queryKey)) {
				removeStaleOfflineEntities(
					response as IGetChecksResponse,
					checksQueryKeys.detail,
					checksQueryKeys.localList,
					true
				);
			}
			if (isEveryKeyIncluded(internalAcceptancesQueryKeys.lists(), query.queryKey)) {
				removeStaleOfflineEntities<IGetChecksResponse>(
					response as IGetChecksResponse,
					internalAcceptancesQueryKeys.detail,
					internalAcceptancesQueryKeys.localList,
					true
				);
			}
			if (isEveryKeyIncluded(ownerAcceptancesQueryKeys.list(), query.queryKey)) {
				removeStaleOfflineEntities(
					response as IGetOwnerAcceptancesResponse,
					ownerAcceptancesQueryKeys.savingData,
					ownerAcceptancesQueryKeys.localList,
					true
				);
			}
			if (isEveryKeyIncluded(warrantyClaimsQueryKeys.lists(), query.queryKey)) {
				removeStaleOfflineEntities(
					response as IGetWarrantyClaimsResponse,
					warrantyClaimsQueryKeys.detail,
					warrantyClaimsQueryKeys.localList,
					true
				);
			}
			if (isEveryKeyIncluded(inspectionsQueryKeys.list(), query.queryKey)) {
				removeStaleOfflineEntities(
					response as IGetPaginateInspectionsResponse,
					inspectionsQueryKeys.savingData,
					inspectionsQueryKeys.localList
				);
			}
		}
	}),
	defaultOptions: {
		queries: {
			gcTime: 1000 * 60 * 60 * 24 * 7,
			staleTime: 1000 * 60 * 60 * 24,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			// TODO Обработка ошибок 404 500 403 401???
			retry: false
		},
		mutations: {
			gcTime: Infinity
		}
	}
});
