import * as types from '@src/constants/notifications/messages';
import INotificationMessage from '@tehzor/tools/interfaces/INotificationMessage';

/**
 * Сохраняет в store сообщение-уведомление
 *
 * @param {INotificationMessage} message сообщение
 * @return {{}}
 */
export const storeNotificationMessage = (message: INotificationMessage) => ({
	type: types.STORE_NOTIFICATION_MESSAGE,
	payload: {...message}
});
