import {Plate} from '@tehzor/ui-components';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {Common} from '@src/pages/StructurePage/components/info/Common';

interface IMobileInfoProps {
	structure: IStructure;
}

export const MobileInfo = ({structure}: IMobileInfoProps) => (
	<Plate className="structure-page__info-content">
		<Common
			structure={structure}
			withBorders
		/>
	</Plate>
);
