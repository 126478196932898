import {wsConnector} from '../wsConnector';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';

export type IEditWarrantyClaimStatusResponse = IWarrantyClaim;

/**
 * Изменяет статус гарантийного обращения
 */
export const makeWarrantyClaimStatusEditRequest = (
	objectId: string,
	claimId: string,
	statusId: WarrantyClaimStatusId
) =>
	wsConnector.sendAuthorizedRequest<IEditWarrantyClaimStatusResponse>('editWarrantyClaimStatus', {
		objectId,
		claimId,
		statusId
	});
