import {useMemo} from 'react';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {IContract} from '@tehzor/tools/interfaces/contracts';

export const useContractsBreadcrumbs = (contract?: IContract) => {
	return useMemo(() => {
		const items: IBreadcrumb[] = [
			{
				label: 'Договоры',
				url: '/contracts'
			}
		];

		if (contract) {
			items.push({
				label: contract.name,
				url: `/contracts/${contract.id}`
			});
		}

		return items;
	}, [contract]);
};
