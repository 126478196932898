import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDocumentsStatisticsData} from '@tehzor/tools/interfaces/documentsStatistics/IDocumentsStatisticsData';

export const convertDocumentsStats = (
	entityId: string,
	documentsStats?: INormalizedData<IDocumentsStatisticsData>
) => {
	const entityStats = documentsStats?.byId?.[entityId]?.documentsCount || {};
	return Object.values(entityStats).reduce((total, item) => total + item.count, 0) || undefined;
};
