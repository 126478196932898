import {useCallback} from 'react';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import './OfflineJournalPage.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {
	changeFilters,
	clearFilters
} from '@src/store/modules/settings/pages/offlineJournal/actions';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';
import {OfflineJournalPage} from './OfflineJournalPage';
import {useTranslation} from "react-i18next";

export const OfflineJournalPageWrap = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const filters = useAppSelector(s => s.settings.pages.offlineJournal);
	const applyFilters = useCallback(
		(value: IOfflineJournalFiltersState) => {
			dispatch(changeFilters(value));
		},
		[dispatch]
	);

	useAppHeader(
		{
			title: t('offlineJournalPage.useAppHeader.title'),
			showBackBtn: true
		},
		[isDesktop]
	);
	const handleClear = useCallback(() => {
		dispatch(clearFilters());
	}, [dispatch]);
	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			onClear={handleClear}
		>
			<OfflineJournalPage />
		</EntitiesFiltersProvider>
	);
};
