import {IEditableSpaceState} from '@src/core/hooks/states/useEditableSpaceState';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export const filterTypeDecorationsForEditingspace = (
	object: IObject,
	editingState: IEditableSpaceState,
	typeDecorationsArray: ISpaceTypeDecoration[],
	typeDecorationSets: ISpaceTypeDecorationSet[]
) => {
	let fileteredTypeDecorations = [] as ISpaceTypeDecoration[];

	// Фильтруем наборы типов отделки по компании и объектам
	const filteredSets = typeDecorationSets.filter(set => {
		if (object.companyId === set.companyId) {
			return set.objects.includes(object.id);
		}
		return false;
	});

	// Фильтруем типы отделки по Id наборов полученных на предыдущем шаге
	fileteredTypeDecorations = typeDecorationsArray.filter(t =>
		filteredSets.some(set => set.id === t.typeDecorationSetId));

	// Фильтруем типы отделки по типу помещений
	if (editingState.type !== undefined) {
		fileteredTypeDecorations = fileteredTypeDecorations.filter(t =>
			t.spaceTypes.some(space => editingState.type?.includes(space)));
	}
	return fileteredTypeDecorations;
};
