import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ISpaceIndicatorsSet from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicatorsSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetSpaceIndicatorsSetsResponse = INormalizedData<ISpaceIndicatorsSet>;

/**
 * Возвращает наборы индикаторов помещений
 */
export const requestSpaceIndicatorsSets = async () => {
	const response = await httpRequests.withToken.get<IGetSpaceIndicatorsSetsResponse>(
		'space-indicators-sets'
	);
	return response.data;
};
