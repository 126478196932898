import IFile from '@tehzor/tools/interfaces/IFile';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';

export const getPreparedPlans = (plans: IPlan[]) => {
	const fullImagesData: IFile[] = [];
	const fullUrls: string[] = [];
	const previewUrls: string[] = [];
	const planNames: string[] = [];
	if (plans) {
		plans.forEach(item => {
			planNames.push(item.name);
			if (item.fullImage) {
				fullUrls.push(item.fullImage.url);
				fullImagesData.push(item.fullImage);
			}
			if (item.previewImage) {
				previewUrls.push(item.previewImage.url);
			}
		});
	}

	return {fullImagesData, fullUrls, previewUrls, planNames};
};
