import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {IObjectsFiltersState} from '@src/store/modules/settings/pages/objects/reducers';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export interface IObjectsGroup {
	companyId: string;
	companyName: string;
	objects: IParentObject[];
}

/**
 * Возвращает полномочия для конкретной компании
 *
 * @param {string} networkStatus статус соединения
 * @param {string?} companyId id компании
 * @param {IUser?} user текущий пользователь
 * @return {IPermissions}
 */
const getPermissionsForCompany = (networkStatus: boolean, companyId?: string, user?: IUser) => {
	if (!networkStatus || !user || !user.roles || !companyId) {
		return {};
	}
	return {
		canAddObject: hasPermission(user.roles, 'objectsAdd', UserRoleScopes.COMPANY, companyId)
	};
};

/**
 * Группирует объекты по компаниям
 *
 * @param filter
 * @param user текущий пользователь
 */
export const useObjectsGroups = (
	objects: IObject[],
	filters: IObjectsFiltersState,
	user?: IUser
): IObjectsGroup[] => {
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);

	return useMemo(() => {
		if (!user || !user.companies) {
			return [];
		}
		const groups: {[companyId: string]: IObjectsGroup} = user.companies.reduce(
			(prev, company) => {
				if (filters.companies?.length && !filters.companies.includes(company.id)) {
					return prev;
				}
				prev[company.id] = {
					companyId: company.id,
					companyName: company.name,
					objects: [],
					...getPermissionsForCompany(networkStatus, company.id, user)
				};
				return prev;
			},
			{}
		);

		for (const object of objects) {
			if (groups.hasOwnProperty(object.companyId)) {
				groups[object.companyId].objects.push(object);
			}
		}
		return Object.values(groups).reduce((acc, group) => {
			if (group.objects.length) {
				acc.push(group);
			}
			return acc;
		}, [] as IObjectsGroup[]);
	}, [objects, networkStatus, filters, user]);
};
