import {useQueryClient} from '@tanstack/react-query';
import {fieldSettingsSetsQueryKeys} from '../keys';
import {requestGetListFieldSettingsSets} from '@src/api/backend/fieldSettingsSets';

export const useFieldSettingsSetsQueryDefaults = () => {
	const queryClient = useQueryClient();

	// Получение списка наборов настроек полей
	queryClient.setQueryDefaults(fieldSettingsSetsQueryKeys.lists(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetListFieldSettingsSets,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
