import {wsConnector} from '@src/api/backend/wsConnector';
import {IGetWorkAcceptancesResponse} from './getPaginated';

/**
 * Возвращает непагинированный список приёмок работ с фильтрацией по сущности
 */

export const requestWorkAcceptancesList = (filters?: Record<string, unknown>) => {
	return wsConnector.sendAuthorizedRequest<IGetWorkAcceptancesResponse>(
		'getWorkAcceptancesList',
		{
			filters
		}
	);
};
