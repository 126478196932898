import { memo } from 'react';
import {DesktopSpaceExport} from './SpaceExport.desktop';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {EntityAdding} from './EntityAdding';

interface IDesktopTopActionsProps {
	objectId: string;
	spaceId: string;
}

export const MainActionsDesktop = memo(({objectId, spaceId}: IDesktopTopActionsProps) => {
	const permissions = useSpacesPermissions(objectId);

	return (
		<>
			<EntityAdding
				objectId={objectId}
				spaceId={spaceId}
			/>
			{permissions.canExport && (
				<DesktopSpaceExport
					objectId={objectId}
					spaceId={spaceId}
				/>
			)}
		</>
	);
});
