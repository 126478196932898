import { forwardRef, Ref } from 'react';
import * as React from 'react';

interface IPhotoProps {
	className?: string;
	style?: React.CSSProperties;
	url: string;
}

const Photo = (props: IPhotoProps, ref?: Ref<HTMLImageElement>) => {
	const {className, style, url} = props;

	return (
		<img
			className={className}
			style={style}
			src={url}
			alt=""
			draggable="false"
			ref={ref}
		/>
	);
};

export default forwardRef(Photo);