import {combineReducers} from 'redux';
import messages, {INotificationMessagesState} from './messages';
import subscriptions, {INotificationsSubscriptionsState} from './subscriptions';
import types, {INotificationsTypesState} from './types';
import channels, {INotificationsChannelsState} from './channels';

export interface INotificationsState {
	messages: INotificationMessagesState;
	subscriptions: INotificationsSubscriptionsState;
	types: INotificationsTypesState;
	channels: INotificationsChannelsState;
}

export default combineReducers({
	messages,
	subscriptions,
	types,
	channels
});
