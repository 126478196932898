import {useCallback} from 'react';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	DateRangePicker,
	EditableFieldLabel,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {makeDateRangeLabel} from '@tehzor/ui-components/src/components/dates/DateRangePicker';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';

interface IStageFieldProps {
	className?: string;
	onChange: (from: Date, to: Date, stageId: ObjectStageIds) => void;
	onClear: (stageId: string) => void;
	stage: IObjectStage;
	from?: number;
	to?: number;
	error?: boolean;
	errorMessage?: string;
	disabled?: boolean;
}

const icon = <i className="tz-calendar-20" />;

export const StageField = ({
	className,
	onChange,
	onClear,
	stage,
	from,
	to,
	error,
	errorMessage = 'Укажите срок устранения',
	disabled
}: IStageFieldProps) => {
	const dateFrom = from ? new Date(from) : undefined;
	const dateTo = to ? new Date(to) : undefined;

	const handleChange = useCallback(
		(from: Date, to: Date) => {
			onChange(from, to, stage.id);
		},
		[stage.id, onChange]
	);

	const handleClear = useCallback(() => {
		onClear(stage.id);
	}, [stage.id, onClear]);

	return (
		<div className={className}>
			<EditableFieldLabel>{stage.name}</EditableFieldLabel>
			<DateRangePicker
				trigger={props => (
					<TextFieldWithForwardedRef
						elementType="div"
						value={makeDateRangeLabel(undefined, dateFrom, dateTo)}
						cleanable={!!(from || to)}
						onClearClick={handleClear}
						disabled={disabled}
						error={error ? errorMessage : undefined}
						icon={icon}
						onClick={props?.toggle}
						ref={props?.ref}
					/>
				)}
				valueFrom={dateFrom}
				valueTo={dateTo}
				dateFormat={dateFormat}
				useApplyButton
				onChange={handleChange}
			/>
		</div>
	);
};
