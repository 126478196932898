import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetSpaceStatusesSetsResponse = INormalizedData<ISpaceStatusesSet>;

/**
 * Возвращает наборы статусов помещений
 */
export const requestGetSpaceStatusesSets = async () => {
	const response = await httpRequests.withToken.get<IGetSpaceStatusesSetsResponse>(
		'space-statuses-sets'
	);
	return response.data;
};
