import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {
	IChangeStructuresDisplayModePayload,
	IStructuresFiltersState,
	IStructuresPageSettingsState,
	IStructuresPagesSettingsState,
	IStructuresSortState
} from './interfaces';

export const getInitialStateForPage = (): IStructuresPageSettingsState => ({
	displayMode: StructuresDisplayMode.SCHEMA,
	[StructuresDisplayMode.SCHEMA]: {
		schemaStructureTypeId: undefined,
		schemaSortRevers: false,
		type: undefined,
		filters: {},
		sort: {name: true},
		pageSize: 20,
		offset: 0
	},
	[StructuresDisplayMode.LIST]: {
		type: undefined,
		filters: {},
		sort: {name: true},
		pageSize: 20,
		offset: 0,
		selectedRows: []
	}
});

const initialState: IStructuresPagesSettingsState = {};
export const structuresSlice = createSlice({
	name: 'structures',
	initialState,
	reducers: {
		changeDisplayMode(state, action: PayloadAction<IChangeStructuresDisplayModePayload>) {
			const {objectId, displayMode} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId].displayMode = displayMode;
		},
		changeType(state, action: PayloadAction<{objectId: string; type: string}>) {
			const {objectId, type} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			const displayMode = state[objectId].displayMode;

			state[objectId][displayMode].type = type;
		},
		changeSchemaStructureType(
			state,
			action: PayloadAction<{objectId: string; structureTypeId: string}>
		) {
			const {objectId, structureTypeId} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId][StructuresDisplayMode.SCHEMA].schemaStructureTypeId = structureTypeId;
		},
		changeSchemaSortRevers(state, action: PayloadAction<{objectId: string; revers: boolean}>) {
			const {objectId, revers} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId][StructuresDisplayMode.SCHEMA].schemaSortRevers = revers;
		},
		changeFilters(
			state,
			action: PayloadAction<IChangeFiltersPayload<IStructuresFiltersState>>
		) {
			const {objectId, filters} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			const displayMode = state[objectId].displayMode;

			state[objectId][displayMode].filters = filters;
		},
		clearFilters(state, action: PayloadAction<IClearFiltersPayload>) {
			const {objectId} = action.payload;
			const displayMode = state[objectId].displayMode;

			state[objectId][displayMode].filters = {};
			if (objectId !== 'all') {
				state[objectId][displayMode].filters = {objects: [objectId]};
			}
		},
		changeSort(state, action: PayloadAction<IChangeSortPayload<IStructuresSortState>>) {
			const {objectId, sort} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			const displayMode = state[objectId].displayMode;
			state[objectId][displayMode].sort = sort;
		},
		changePageSize(state, action: PayloadAction<IChangePageSizePayload>) {
			const {objectId, pageSize} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			const displayMode = state[objectId].displayMode;
			state[objectId][displayMode].pageSize = pageSize;
		},
		changeOffset(state, action: PayloadAction<{objectId: string; offset: number}>) {
			const {objectId, offset} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			const displayMode = state[objectId].displayMode;
			state[objectId][displayMode].offset = offset;
		},
		changeSelectedRows(
			state,
			action: PayloadAction<{objectId: string; selectedRows: string[]}>
		) {
			const {objectId, selectedRows} = action.payload;

			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}

			state[objectId][StructuresDisplayMode.LIST].selectedRows = selectedRows;
		}
	}
});

export const {actions: structuresActions, reducer: structuresReducer} = structuresSlice;
