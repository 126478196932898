import {wsConnector} from '@src/api/backend/wsConnector';
import {IInternalAcceptancesStatsDataRequest} from '@src/interfaces/IInternalAcceptancesStatsData';
import {IEntityInspectionsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityInspectionsData';

export type IGetInternalAcceptanceInspectionsResponse = IEntityInspectionsData;

/**
 * Возвращает статистику осмотров по внутренним приемкам
 */
export const requestInternalAcceptancesInspectionsData = (
	requestData: IInternalAcceptancesStatsDataRequest
) =>
	wsConnector.sendAuthorizedRequest<IGetInternalAcceptanceInspectionsResponse>(
		'getInternalAcceptancesInspectionsData',
		{...requestData}
	);
