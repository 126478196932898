import {memo, useCallback, useMemo, useState, useTransition} from 'react';
import {useUpdateEffect} from 'react-use';
import {FilterButton, Select2, SelectOption} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {togglePending} from '@src/store/modules/settings/pages/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

interface SelectOptionItem {
	id: string;
	name: string;
}

interface IStatusesFilterProps<T extends SelectOptionItem> {
	label?: string;
	statuses?: string[];
	allStatuses: T[];
	filterName?: string;
	resetOnApply?: string[];
	translationDictionaryKey?: dictionaryKeys;
}

export const StatusesFilter = memo(<T extends SelectOptionItem>(props: IStatusesFilterProps<T>) => {
	const {
		label = 'Статус',
		filterName = 'statuses',
		statuses,
		allStatuses,
		resetOnApply,
		translationDictionaryKey
	} = props;

	const {t} = useTranslation();

	const [selectedStatuses, setSelectedStatuses] = useState(statuses);
	const [search, setSearch] = useState('');
	const [isPending, startTransition] = useTransition();
	const clearSearch = useCallback(() => setSearch(''), []);
	const isSearchShown = useMemo(() => allStatuses.length >= 8, [allStatuses]);

	const translatedOptions = useTranslatedDictionaryArray(translationDictionaryKey, allStatuses);
	const filteredData = useMemo(
		() =>
			!translatedOptions
				? flatFilter(allStatuses, 'name', search)
				: flatFilter(translatedOptions, 'name', search),
		[translatedOptions, search, allStatuses]
	);

	const {dispatch} = useEntitiesFiltersCtx();

	const dispatcher = useAppDispatch();

	useUpdateEffect(() => {
		dispatcher(togglePending(isPending));
	}, [isPending]);

	const handleApply = useCallback(() => {
		const changes = {[filterName]: selectedStatuses};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		startTransition(() => {
			dispatch(changes);
		});
		clearSearch();
	}, [filterName, selectedStatuses, resetOnApply, dispatch, clearSearch]);

	const handleChange = useCallback(
		(v?: string[]) => {
			if (v && v.length === 0) {
				setSelectedStatuses(undefined);
			} else {
				setSelectedStatuses(v);
			}
		},
		[clearSearch]
	);

	const handleClear = useCallback(() => {
		setSelectedStatuses(undefined);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedStatuses(undefined);
		const changes = {[filterName]: undefined};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		dispatch(changes);
		clearSearch();
	}, [filterName, resetOnApply, dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedStatuses(statuses);
		clearSearch();
	}, [clearSearch, statuses]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedStatuses(allStatuses.map(item => item.id));
	}, [allStatuses, clearSearch]);

	useUpdateEffect(() => {
		setSelectedStatuses(statuses);
	}, [statuses]);

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedStatuses?.length}
			footer
			count={selectedStatuses?.length}
			noHeader={!isSearchShown}
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel<T>(label, statuses, translatedOptions || allStatuses)}
					active={!!(statuses && statuses.length != 0)}
					onClear={handleFullClear}
				/>
			}
			search={
				isSearchShown && (
					<TranslatedSelectSearch
						value={search}
						placeholder={t('selectSearch.placeholder')}
						onChange={setSearch}
					/>
				)
			}
			onSelectAll={handleSelectAll}
		>
			<Select2
				multiple
				value={selectedStatuses}
				onChange={handleChange}
			>
				{filteredData.map(item => (
					<SelectOption
						dataTestId="MenuItem"
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</TranslatedSelectPopup>
	);
});
