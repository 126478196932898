import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordByListId} from '@src/store/modules/pages/checkLists/selectors/records';
import {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureCheckListLink} from '@tehzor/tools/utils/links';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {DiagramIcon} from '../DiagramIcon/DiagramIcon';
import {extractProblemsByCheckListIdAsArray} from '@src/store/modules/pages/checkLists/selectors/problems';
import {format} from 'date-fns';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {useTranslation} from 'react-i18next';

interface ICheckListProps {
	checkList: ICheckList;
	showDivider?: boolean;
}

// TODO Разнести компоненты по разным файлам
const CheckList = ({checkList, showDivider}: ICheckListProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {objectId, structureId} = useStrictParams<{objectId: string; structureId: string}>();
	const record = useAppSelector(s => extractCheckRecordByListId(s, checkList.id));
	const problems = useAppSelector(s => extractProblemsByCheckListIdAsArray(s, checkList.id));

	const handleClick = useCallback(() => {
		pushPath(
			formStructureCheckListLink({
				objectId,
				structureId,
				listId: checkList.id
			})
		);
	}, [checkList.id]);

	return (
		<div className="structure-page__check-lists">
			<div
				className="structure-page__check-list__m-list"
				onClick={handleClick}
				role="presentation"
			>
				<DiagramIcon listId={checkList.id} />
				<div className="structure-page__check-list__m-list-text">
					<div className="structure-page__check-list__m-list-text-title">
						{checkList.name}
					</div>
					<div className="structure-page__check-list__m-list-text-subtitle">
						{record?.status === CheckRecordStatusId.ACCEPTED && record.modifiedAt
							? `принял ${format(
									record.modifiedAt,
									dateTimeLetterSeparatedFormat
							  )} ${record.modifiedBy?.displayName}`
							: problems.length > 0
							? t('structuresPage.checkList.problems', {
									count: problems.length
							  })
							: null}
					</div>
				</div>
			</div>
			<ProblemIndicators checkListId={checkList.id} />

			{showDivider && <hr className="structure-page__check-list__divider" />}
		</div>
	);
};

interface ICheckListsProps {
	checkLists: ICheckList[];
}

export const CheckLists = ({checkLists}: ICheckListsProps) => {
	const listsLoading = useCheckListsIsLoading();

	return (
		<LoadingPanel active={listsLoading}>
			<Plate
				withoutPadding
				className="structure-page__check-list__m-lists"
			>
				{checkLists.map((checkList, index) => (
					<CheckList
						key={checkList.id}
						checkList={checkList}
						showDivider={index + 1 !== checkLists.length}
					/>
				))}
			</Plate>
		</LoadingPanel>
	);
};
