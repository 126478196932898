import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {useDeleteFieldSettingsSet} from '../../mutations/useDeleteFieldSettingsSet/useDeleteFieldSettingsSet';

export const useDeleteFieldsSettingsSets = () => {
	const {mutate} = useDeleteFieldSettingsSet();

	return (fieldSettingsSets: IPreparedFieldSettingsSet[]) => {
		fieldSettingsSets.forEach(fieldSettingsSet => {
			mutate(fieldSettingsSet.id);
		});
	};
};
