import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {TFunction} from "i18next";

/**
 * Формирует массив дат помещения
 */
export const formSpaceDates = (space: ISpace, tFunc: TFunction) => {
	const dates = [];
	if (space.decorationWarrantyExpiredDate) {
		dates.push({
			value: space.decorationWarrantyExpiredDate,
			label: tFunc('utils.formSpaceDates.dates.decorationWarrantyExpiredDate.label')
		});
	}
	if (space.technicalEquipmentWarrantyExpiredDate) {
		dates.push({
			value: space.technicalEquipmentWarrantyExpiredDate,
			label: tFunc('utils.formSpaceDates.dates.technicalEquipmentWarrantyExpiredDate.label')
		});
	}
	if (space.constructiveWarrantyExpiredDate) {
		dates.push({
			value: space.constructiveWarrantyExpiredDate,
			label: tFunc('utils.formSpaceDates.dates.constructiveWarrantyExpiredDate.label')
		});
	}
	return dates.length ? dates : undefined;
};
