import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';
import {MeterTariffId} from '@tehzor/tools/interfaces/meterConsumptions/IMeterTariff';
import {ISavingMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/ISavingMeterConsumption';

export type IEditableMeterConsumptionState = IEditableEntityState<{
	tariffId?: MeterTariffId;
	value?: string;
}>;

export type IEditableMeterConsumptionAction = IEditableEntityAction<
	IEditableMeterConsumptionState,
	IMeterConsumption
>;

const makeEmptyState = (): IEditableMeterConsumptionState => ({
	tariffId: undefined,
	value: undefined,
	errors: {
		tariffId: false,
		value: false
	}
});

export const init = (meterConsumption?: IMeterConsumption): IEditableMeterConsumptionState => {
	const empty = makeEmptyState();
	return meterConsumption
		? {
				tariffId: meterConsumption.tariffId,
				value: meterConsumption.value,
				errors: empty.errors
		  }
		: empty;
};

const isTariffIdEdited = (state: IEditableMeterConsumptionState, original?: IMeterConsumption) =>
	original?.tariffId ? original.tariffId !== state.tariffId : !!state.tariffId;

const isValueEdited = (state: IEditableMeterConsumptionState, original?: IMeterConsumption) =>
	original?.value ? original.value !== state.value : !!state.value;

/**
 * Возвращает значение, показывающее были ли отредактированы поля прибора учёта
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (
	state: IEditableMeterConsumptionState,
	original?: IMeterConsumption
): boolean => isEntityEdited(state, original, isTariffIdEdited, isValueEdited);

export const errorsFns = {
	tariffId: (state: IEditableMeterConsumptionState) => !state.tariffId,
	value: (state: IEditableMeterConsumptionState) => !state.value
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableMeterConsumptionState,
	original?: IMeterConsumption,
	onlyEdited?: boolean
): ISavingMeterConsumption => {
	if (!onlyEdited) {
		return {
			tariffId: state.tariffId,
			value: state.value
		};
	}
	const meterConsumption: ISavingMeterConsumption = {};

	if (isTariffIdEdited(state, original)) {
		meterConsumption.tariffId = state.tariffId;
	}
	if (isValueEdited(state, original)) {
		meterConsumption.value = state.value;
	}

	return meterConsumption;
};
