import { useMemo } from 'react';
import ActionsMobile from './Actions.mobile';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import SelectionActions from '../selection/SelectionActions';
import {useSpaceEntities} from '@src/core/hooks/queries/space';

interface IMobileRightButtonsProps {
	objectId: string;
	spaceId: string;
	space: ISpace;
	selectedRows?: string[];
}

const MobileRightButtons = ({objectId, space, spaceId, selectedRows}: IMobileRightButtonsProps) => {
	const entities = useSpaceEntities(objectId, spaceId);

	const flatEntities = useMemo(
		() =>
			entities.reduce(
				(prev, current) => {
					prev.push(current);

					if (current.subRows !== undefined && current.subRows?.length > 0) {
						prev.push(...current.subRows);
					}
					return prev;
				},
				[] as typeof entities
			),
		[entities]
	);

	const selectedEntities = useMemo(
		() =>
			flatEntities.filter(
				item =>
					selectedRows?.includes(item.id) ||
					item.subRows?.every(el => selectedRows?.includes(el.id))
			),
		[flatEntities, selectedRows]
	);

	if (!space) {
		return null;
	}
	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				spaceId={spaceId}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			space={space}
		/>
	);
};

export default MobileRightButtons;
