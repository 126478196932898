import {useChangePath} from '@src/core/hooks/useChangePath';
import {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

/**
 * Перенаправляет на страницу единичного фильтра
 *
 * @param objectId id объекта
 * @param entity сущность
 * @param name название фильтра
 */
export const useFilterPageTransition = (
	objectId: string | undefined,
	entity: string,
	name: string
) => {
	const {search} = useLocation();
	const {pushPath} = useChangePath();

	return useCallback(() => {
		pushPath(`${name}?${search}`, {state: {canGoBack: true}});
	}, [objectId, entity, name, search]);
};
/**
 * Перенаправляет на страницу сущности
 *
 * @param flag флаг разрешающий роутинг
 * @param objectId id объекта
 * @param entity сущность
 */
export const useRouting = (flag: boolean, objectId = 'all', entity?: string) => {
	const {search} = useLocation();
	const {replacePath} = useChangePath();

	useEffect(() => {
		if (flag) {
			replacePath(`${entity}?${search}`);
		}
	}, [flag, objectId, entity, search]);
};
