import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export const toCheck = (
	user: IUser,
	objectId: string,
	links: ICheck['links'] | undefined,
	stage: ObjectStageIds | undefined,
	key: string,
	nextNumber: number
): ICheck => {
	const briefUser: IBriefUser = {
		id: user.id,
		fullName: user.fullName,
		displayName: user.displayName
	};
	const newCheck: ICheck = {
		id: key,
		localNumber: `АВТ-${nextNumber}`,
		objectId,
		links,
		stage: ObjectStageIds.BUILDING,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Number(key),
		createdBy: briefUser,
		modifiedAt: Number(key),
		modifiedBy: briefUser
	};
	return newCheck;
};

export const toListCheck = (
	object: IObject,
	user: IUser,
	links: ICheck['links'] | undefined,
	stage: ObjectStageIds | undefined,
	key: string,
	nextNumber: number
): IListCheck => {
	const briefUser: IBriefUser = {
		id: user.id,
		fullName: user.fullName,
		displayName: user.displayName
	};
	const newCheck: IListCheck = {
		id: key,
		localNumber: `АВТ-${nextNumber}`,
		objectId: object.id,
		links,
		object: {id: object.id, name: object.name},
		stage: ObjectStageIds.BUILDING,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Number(key),
		createdBy: briefUser,
		modifiedAt: Number(key),
		modifiedBy: briefUser
	};
	return newCheck;
};
