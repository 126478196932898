import {TextField} from '@tehzor/ui-components';
import { useCallback } from 'react';

interface INameFieldProps {
	value?: string;
	disabled?: boolean;
	required?: boolean;
	hasError?: boolean;

	onChange: (value: string) => void;
	onError: () => void;
}

export const NameField = ({
	disabled, required, value, hasError, onChange, onError
}: INameFieldProps) => {
	const handleChange = useCallback((value: string) => {
		onChange(value);
		if (required) {
			onError();
		}
	}, [onChange, onError, required]);

	return (
		<div className="editable-space-statuses-set__name">
			<TextField
				className="editable-space-statuses-set__name-input"
				placeholder="Название набора"
				elementType={disabled ? 'div' : 'input'}
				value={value}
				onChange={handleChange}
				error={hasError ? 'Укажите наименование' : undefined}
			/>
		</div>
	);
};