/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {IGroupTask} from '../utils/groupTasksByStatusAndType';
import {useTasksSchedulePopup} from '@src/components/TasksSchedulePopup/hooks/useTasksSchedulePopup';
import declination from '@tehzor/tools/utils/declination';
import {
	ScheduleMonthVisibleItem
} from "@src/pages/TasksPage/components/tabs/TasksSchedulePage/components/ScheduleMonthVisibleItem";

interface IUseMonthTasksProps {
	groupedTasks?: IGroupTask[];
	hiddenNumber: number;
	objectId?: string;
	startTime?: number;
	endTime?: number;
	popupTitle?: string;
}

export const useMonthTasks = (props: IUseMonthTasksProps) => {
	const {groupedTasks = [], hiddenNumber, objectId, startTime, endTime, popupTitle} = props;

	const visibleGroupedTasks = groupedTasks.slice(0, hiddenNumber);
	const notVisibleTasks = groupedTasks.slice(hiddenNumber)
		.reduce((acc, groupTasks) => [...acc, ...groupTasks.tasks], []);

	const [TasksSchedulePopup, openTasksSchedulePopup] = useTasksSchedulePopup(
		notVisibleTasks,
		popupTitle,
		objectId,
		startTime,
		endTime
	);

	const tasksCountStr = groupedTasks.length - hiddenNumber > 0
		&& `+ ${notVisibleTasks.length} ${declination(notVisibleTasks.length, ['задача', 'задачи', 'задач'])}`;

	return {
		tasks: (
			<>
				{visibleGroupedTasks.map((task, i) => (
					<ScheduleMonthVisibleItem
						task={task}
						objectId={objectId}
						startTime={startTime}
						endTime={endTime}
						popupTitle={popupTitle}
						key={i}
					/>
				))}
				{tasksCountStr && (
					<span
						onClick={openTasksSchedulePopup}
						className="manage-task-schedule-page__grouped-tasks_item-task"
					>
						{tasksCountStr}
					</span>
				)}
				{TasksSchedulePopup}
			</>
		)
	};
};