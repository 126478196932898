import {
	ITasksFiltersState,
	ITasksSortState
} from '@src/store/modules/settings/pages/tasks/interfaces';

export type ITasksListQueryKey = [] & {
	0: string;
	1: string;
	2: ITasksFiltersState;
	3: ITasksSortState;
	4: number;
	5: number;
};

export type ITasksScheduleQueryKey = [] & {
	0: string;
	1: string;
	2: ITasksFiltersState;
	3: ITasksSortState;
};
export const tasksQueryKeys = {
	all: () => ['tasks'],
	one: () => ['task'],
	lists: () => [...tasksQueryKeys.all(), 'list'],
	list: (
		filters: ITasksFiltersState,
		sort: ITasksSortState,
		offset: number,
		pageSize: number
	) => [...tasksQueryKeys.lists(), filters, sort, offset, pageSize],
	schedule: () => [...tasksQueryKeys.all(), 'schedule'],
	localList: () => [...tasksQueryKeys.all(), 'local-list'],
	details: () => [...tasksQueryKeys.one(), 'detail'],
	detail: (taskId: string) => [...tasksQueryKeys.one(), 'detail', taskId],
	edit: () => [...tasksQueryKeys.one(), 'edit'],
	add: () => [...tasksQueryKeys.one(), 'add'],
	delete: () => [...tasksQueryKeys.one(), 'delete']
};
