import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ItemBullet} from '@src/pages/CheckListPage/components/list/CheckListItems/components/ItemBullet';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {format} from 'date-fns';
import {DescriptionPopUp} from './DescriptionPopUp';
import {DescriptionDialog} from './DescriptionDialog';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {EntityInfoLink} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordsByItemIAndWorkAcceptanceIds} from '@src/store/modules/pages/checkLists/selectors/records';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import {useWorkAcceptancesAsArray} from '@src/core/hooks/queries/workAcceptances';
import {useTranslation} from "react-i18next";

interface IItemHeaderProps {
	item: ICheckItem;
	record?: ICheckRecord;
}

export const ItemHeader = ({item, record}: IItemHeaderProps) => {
	const {t} = useTranslation();
	const isDesktop = useIsDesktop();
	const {pushPath} = useChangePath();
	const {data: workAcceptances} = useWorkAcceptancesAsArray(record?.objectId);

	const workAcceptanceIds = workAcceptances?.map(wa => wa.id);
	const records = useAppSelector(state =>
		extractCheckRecordsByItemIAndWorkAcceptanceIds(state, item.id, workAcceptanceIds)
	);
	const itemWorkAcceptances = workAcceptances?.filter(workAcceptance =>
		records.some(rec => rec.links.workAcceptanceId === workAcceptance.id)
	);

	const navigate = (workAcceptanceId: string) => {
		if (record?.objectId) {
			pushPath(formWorkAcceptanceLink(record.objectId, workAcceptanceId));
		}
	};

	return (
		<div className="structure-check-list-items__item-header">
			<ItemBullet
				item={item}
				record={record}
			/>

			{item.description ? (
				isDesktop ? (
					<DescriptionPopUp item={item} />
				) : (
					<DescriptionDialog item={item} />
				)
			) : (
				<div className="structure-check-list-items__item-header-title">{item.name}</div>
			)}

			{record && record.modifiedAt ? (
				<div className="structure-check-list-items__item-header-subtitle">
					<div className="structure-check-list-items__item-header-subtitle_accepted">
						{record.status === CheckRecordStatusId.ACCEPTED ?
							t('structurePage.checklistItem.itemHeader.recordStatus.accepted') :
							t('structurePage.checklistItem.itemHeader.recordStatus.updated')}{' '}
						&nbsp;
						<div>{format(record.modifiedAt, dateTimeLetterSeparatedFormat)}</div>
						&nbsp;
						<div className="structure-check-list-items__item-header-subtitle-name">
							{record?.modifiedBy?.displayName}
						</div>
					</div>
					{itemWorkAcceptances?.map(workAcceptance => (
						<EntityInfoLink
							key={workAcceptance.id}
							className="structure-check-list-items__item-header-links"
							label={`${t('structurePage.checklistItem.itemHeader.entityInfoLink.label')} ${workAcceptance.number}`}
							onClick={() => {
								navigate(workAcceptance.id);
							}}
						/>
					))}
				</div>
			) : null}
		</div>
	);
};
