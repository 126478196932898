import {IconButton} from '@tehzor/ui-components';
import {useInternalAcceptancesExport} from '@src/core/hooks/export/useInternalAcceptancesExport';

interface IMobileInternalAcceptancesExportProps {
	objectId?: string;
}

const MobileInternalAcceptancesExport = ({objectId}: IMobileInternalAcceptancesExportProps) => {
	const [exportDialog, openExportDialog] = useInternalAcceptancesExport(objectId);

	return (
		<>
			<IconButton onClick={openExportDialog}>
				<i className="tz-export-24"/>
			</IconButton>
			{exportDialog}
		</>
	);
};

export default MobileInternalAcceptancesExport;
