import {SelectedEntitiesDeletion} from './SelectedEntitiesDeletion';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import {useStructuresPermissions} from '@src/core/hooks/permissions/useStructuresPermissions';

interface ISelectionsActionsProps {
	objectId: string;
	selectedEntities: IStructureEntity[];
}

export const SelectionActions = ({objectId, selectedEntities}: ISelectionsActionsProps) => {
	const permissions = useStructuresPermissions(objectId);

	return (
		<>
			{permissions.canDelete && (
				<SelectedEntitiesDeletion selectedEntities={selectedEntities}/>
			)}
		</>
	);
};
