import {IObjectTree} from '@src/interfaces/IObjectTree';
import {FiltersTreeObjectsFilterArgs} from '@src/core/hooks/queries/objects/interfaces';

export const filterFun = (el: IObjectTree, filterArgs: FiltersTreeObjectsFilterArgs) => {
	const filterByCities =
		!(filterArgs.cities && Array.isArray(filterArgs.cities) && filterArgs.cities.length > 0) ||
		(el.city && filterArgs.cities.includes(el.city));
	const filterByStages =
		!(filterArgs.stages && Array.isArray(filterArgs.stages) && filterArgs.stages.length > 0) ||
		(el.stage && filterArgs.stages.includes(el.stage));
	const filterByCompanies =
		!(
			filterArgs.companies &&
			Array.isArray(filterArgs.companies) &&
			filterArgs.companies.length > 0
		) ||
		(el.companyId && filterArgs.companies.includes(el.companyId));
	const filterByObject =
		!(filterArgs.object && filterArgs.object.length > 0) ||
		el.name.toUpperCase().includes(filterArgs.object.toUpperCase());

	return !!filterByCities && filterByStages && !!filterByCompanies && filterByObject;
};
