import {IGetListContractsResponse, IGetPaginateContractsResponse} from '@src/api/backend/contracts';

export const extractContractsPaginateMap = (data: IGetPaginateContractsResponse) => data.byId;

export const extractContractsPaginateArray = (data: IGetPaginateContractsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractContractsListMap = (data: IGetListContractsResponse) => data.byId;

export const extractContractsListArray = (data: IGetListContractsResponse) =>
	data.allIds.map(id => data.byId[id]);
