import {IEditableSpaceStatusesSetAction, IEditableStatus} from '@src/core/hooks/states/useSpaceStatusesSetState';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import { Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from 'react';
import {EditableSpaceStatusDialog} from '../components/mobile/EditableSpaceStatusDialog';
import {IExtendedSpaceStatus} from '../interfaces/IExtendedSpaceStatus';

interface IUseAddingSpaceStatusDialogProps {
	statusesByStage: IExtendedSpaceStatus[];
	statuses?: ISpaceStatus[];

	setEdited: Dispatch<SetStateAction<string[]>>;

	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;

	createStatus: () => IEditableStatus | undefined;
	updateStatus: (index: number) => Promise<ISpaceStatus | undefined>;
}

export const useAddingSpaceStatusDialog = ({
	statusesByStage, statuses, setEdited, editingDispatch, createStatus, updateStatus
}: IUseAddingSpaceStatusDialogProps): [() => void, ReactNode] => {
	const [isOpen, setOpen] = useState(false);
	const [statusId, setStatusId] = useState<string | undefined>(undefined);

	const status = useMemo(() =>
		statusesByStage.find(val => val.id === statusId),
	[statusId, statusesByStage]);

	const open = useCallback(() => {
		setOpen(true);

		const status = createStatus();
		setStatusId(status?.id);
	}, [createStatus]);

	const close = useCallback(() => setOpen(false), []);

	const dialog = status ? (
		<EditableSpaceStatusDialog
			isOpen={isOpen}
			onClose={close}
			status={status}
			statuses={statuses}
			setEdited={setEdited}
			editingDispatch={editingDispatch}
			updateStatus={updateStatus}
		/>
	) : undefined;

	return [open, dialog];
};