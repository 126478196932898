import {IDndDragState, IDndItem} from '../interfaces';
import {IElementData} from '../interfaces/IElementData';
import {OverStatus} from '../interfaces/OverStatus';
import {calculateDepth} from './calculateDepth';
import {filterDragItems} from './filterItems';
import {getDropItem} from './getDropItem';

/**
 * Возвращает индекс, куда элемент нужно переместить
 *
 * @param items массив элементов
 * @param dragItem перемещаемый элемент
 * @param dropData данные drop элемента
 * @param overStatus over статус
 */
export const getDropIndex = <S extends IDndItem>(
	items: S[],
	dragItem: S,
	dropData: IElementData<S>,
	overStatus?: OverStatus
): number | undefined => {
	if (!overStatus) {
		return undefined;
	}

	const {id, index: dropIndex} = dropData;

	if (dragItem.id === id) {
		return dropIndex;
	}

	const dragIndex = items.findIndex(item => item.id === dragItem.id);
	if (dragIndex === -1) {
		return overStatus === OverStatus.BOTTOM ? dropIndex + 1 : dropIndex;
	}

	if (dragIndex > dropIndex) {
		return overStatus === OverStatus.TOP ? dropIndex : dropIndex + 1;
	}

	return overStatus === OverStatus.TOP ? dropIndex - 1 : dropIndex;
};

/**
 * Возвращает массив элементов для события dragEnd и добавляет к каждому depth
 *
 * @param items массив элементов
 * @param dragState drag состояние
 * @param dropData данные drop элемента
 * @param overStatus over статус
 * @param container контейнер компонента
 * @param dropContainer контейнер куда кидаем
 */
export const getDragEndItems = <S extends IDndItem>(
	items: S[],
	dragState: IDndDragState<S>,
	dropData?: IElementData<S>,
	overStatus?: OverStatus,
	container?: string,
	dropContainer?: string,
): S[] => {
	const {item: dragItem, children: dragChildren, initContainer} = dragState;

	if (!dropContainer || !dragItem) {
		return items;
	}

	const isInitContainer = initContainer === container;
	const isDropContainer = dropContainer === container;

	if (container && !isInitContainer && !isDropContainer) {
		// контейнер не участвовал в перетаскивании
		return items;
	}

	const filteredItems = filterDragItems(items, dragItem, dragChildren);

	if (container && isInitContainer && !isDropContainer) {
		// если это начальный контейнер, но не конечный - то в нем нужно сдвинуть индексы siblings на -1 у тех что ниже расположены
		return filteredItems.map(item => {
			if (item.parentId === dragItem?.parentId && item.order > dragItem.order) {
				return {...item, order: item.order - 1};
			}
			return item;
		});
	}

	if (!dropData) {
		// если почему то непонятно куда кинули - ничего не меняем
		return items;
	}
	// если начальный и конечный контейнеры совпадают или уже только конечный контейнер
	const dropIndex = getDropIndex(items, dragItem, dropData, overStatus);
	// финальный родитель и порядок
	const dropItem = getDropItem(
		dragItem,
		dropData,
		initContainer === dropContainer,
		overStatus
	);

	const newItems = filteredItems.slice(0, dropIndex);

	newItems.push(dropItem);

	if (dragChildren?.length) {
		newItems.push(...dragChildren);
	}

	newItems.push(...filteredItems.slice(dropIndex));

	return calculateDepth(newItems);
};
