import {combineReducers} from 'redux';
import update from 'immutability-helper';
import createReducer from '@tehzor/tools/utils/createReducer';
import {TOGGLE_LOCAL_REPORT_SAVING} from '@src/constants/uiConstants';

interface IUILocalReportState {
	reportId: string;
	isSaving: boolean;
}

interface IToggleSavingPayload {
	reportId: string;
	isSaving?: boolean;
}

const localReports = createReducer<IUILocalReportState[]>([], {
	[TOGGLE_LOCAL_REPORT_SAVING]: (state, payload: IToggleSavingPayload) => {
		const index = state.findIndex(item => item.reportId === payload.reportId);
		if (index >= 0) {
			return update(state, {
				[index]: {
					isSaving: (isSaving: boolean) =>
						(payload.isSaving === undefined ? !isSaving : payload.isSaving)
				}
			});
		}
		return [
			...state,
			{
				reportId: payload.reportId,
				isSaving: payload.isSaving === undefined ? false : payload.isSaving
			}
		];
	}
});

export default combineReducers({
	localReports
});
