import {IContract} from '@tehzor/tools/interfaces/contracts';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingContract} from '@src/interfaces/saving/ISavingContract';

export type IAddContractResponse = IContract;

/**
 * Добавляет договор
 *
 * @param fields данные договора
 */
export const requestAddContract = async (fields: ISavingContract) => {
	const res = await httpRequests.withToken.post<IAddContractResponse>('contracts/add', fields);

	return res.data;
};
