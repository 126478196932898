import {memo} from 'react';
import {CellProps} from 'react-table';
import styles from './FieldModifiedCell.module.less';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';

export const FieldModifiedCell = memo(
	({row}: Pick<CellProps<IPreparedFieldSettingsSet>, 'row'>) => {
		const field = row.original;
		return (
			<div className={styles.modified}>
				<div>
					<div className={styles.modifiedAt}>{field.modifiedAt}</div>

					{field?.modifiedBy && (
						<div className={styles.modifiedBy}>{field.modifiedBy.fullName}</div>
					)}
				</div>
			</div>
		);
	}
);
