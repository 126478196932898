import {useInternalAcceptancePermissions} from '@src/core/hooks/permissions/useInternalAcceptancePermissions';
import DesktopInternalAcceptanceExportBtn from './InternalAcceptanceExport.desktop';
import EntityAdding from './EntityAdding';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {useInternalAcceptanceProblemsArray} from '@src/core/hooks/queries/internalAcceptance/problems/hooks';

interface IDesktopMainActionsProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
}

const DesktopMainActions = ({objectId, internalAcceptance}: IDesktopMainActionsProps) => {
	const {data: problems} = useInternalAcceptanceProblemsArray(objectId, internalAcceptance.id);
	const permissions = useInternalAcceptancePermissions(objectId, internalAcceptance, problems);

	return (
		<>
			{permissions.canExport && (
				<DesktopInternalAcceptanceExportBtn
					objectId={objectId}
					internalAcceptanceId={internalAcceptance.id}
				/>
			)}
			<EntityAdding
				objectId={objectId}
				internalAcceptance={internalAcceptance}
			/>
		</>
	);
};

export default DesktopMainActions;
