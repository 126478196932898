import _ from 'lodash';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';

export const extractAllLatestInternalAcceptancesAsArray = (data: IGetInternalAcceptancesResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractLatestInternalAcceptancesAsArray = (
	data: IGetInternalAcceptancesResponse,
	userId: string,
	objectId: string,
	spaceId?: string
) => {
	const arr = extractAllLatestInternalAcceptancesAsArray(data);
	return arr.filter(
		item =>
			item.objectId === objectId &&
			item.createdBy?.id === userId &&
			(!spaceId || spaceId === item.links?.spaceId)
	);
};

export const extractLatestInternalAcceptanceId = (
	data: IGetInternalAcceptancesResponse,
	userId: string,
	objectId: string,
	spaceId?: string,
	localInternalAcceptance?: IListInternalAcceptance[]
) => {
	const latestInternalAcceptance = extractLatestInternalAcceptancesAsArray(
		data,
		userId,
		objectId,
		spaceId
	);
	const filteredLocalInternalAcceptances = localInternalAcceptance?.filter(
		item => item.objectId === objectId && (!spaceId || spaceId === item.links?.spaceId)
	);
	if (filteredLocalInternalAcceptances?.length) {
		return _.maxBy(filteredLocalInternalAcceptances, 'modifiedAt')?.id;
	}
	if (latestInternalAcceptance.length) {
		return _.maxBy(latestInternalAcceptance, 'modifiedAt')?.id;
	}
	return undefined;
};
