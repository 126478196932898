import {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {useTranslation} from 'react-i18next';

interface IStagesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	onChange: (changes: IChanges) => void;
}

export const StagesFilterMobile = memo((props: IStagesFilterMobileProps) => {
	const {label = 'Стадии', ...restProps} = props;
	const {t} = useTranslation();
	const translatedLabel = label ?? t('entitiesFilters.stagesFilter.label');
	const {data: objectStages} = useObjectStagesAsArray();
	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.objectStages,
		objectStages
	);

	if (!translatedOptions) {
		return null;
	}

	return (
		<BaseListFilterMobile
			options={translatedOptions}
			label={translatedLabel}
			filterName="stages"
			{...restProps}
		/>
	);
});
