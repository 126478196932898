import {IGetOwnerAcceptanceResolutionsResponse} from '@src/api/backend/ownerAcceptanceResolutions';

export const extractOwnerAcceptancesResolutionsAsMap = (
	data?: IGetOwnerAcceptanceResolutionsResponse
) => {
	if (!data) return undefined;
	return data.byId;
};

export const extractOwnerAcceptancesResolutionsAsArray = (
	data?: IGetOwnerAcceptanceResolutionsResponse
) => {
	if (!data) return undefined;
	return data.allIds.map(id => data.byId[id]);
};
