import { Dispatch } from 'react';
import './EditableGroupItems.less';
import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {GroupsTable} from '@src/pages/manage/WorkingGroupEditingPage/components/table/GroupsTable.desktop';

interface IEditablePerformersProps {
	editingState: IEditableWorkingGroupState;
	editingDispatch: Dispatch<IEditableWorkingGroupAction>;
	workingGroupId?: string;
}

export const EditableSubGroups = ({
	editingState,
	editingDispatch,
	workingGroupId
}: IEditablePerformersProps) => (
	<div className="editable-group-items">
		<div className="editable-group-items__header">
			<h3>Дочерние рабочие группы</h3>
			<div className="editable-group-items__header-actions"/>
		</div>
		<div className="editable-group-items__body">
			<GroupsTable
				editingDispatch={editingDispatch}
				editingState={editingState}
				workingGroupId={workingGroupId}
			/>
		</div>
	</div>
);
