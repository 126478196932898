import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export type IDeleteSpaceTypeDecorationSetResponse = ISpaceTypeDecorationSet;

/**
 * Удаляет набор типов отделки помещения
 *
 * @param id id набора типов отделки помещения
 */
export const deleteSpaceTypeDecorationSetRequest = async (id: string) => {
	const response = await httpRequests.withToken.delete<IDeleteSpaceTypeDecorationSetResponse>(
		`space-type-decoration-sets/${id}`
	);
	return response.data;
};
