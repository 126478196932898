import EntitiesFilters, {
	ObjectsFilter,
	CreationDateFilter,
	StatusesFilter,
	CreatedByFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import {useExtractWorkAcceptanceFrontTypesAsArray} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {useExtractWorkAcceptanceTypesAsArray} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useExtractWorkAcceptanceStatusesAsArray} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {WorkAcceptancesSearch} from '@src/pages/WorkAcceptancesPage/components/WorkAcceptancesSearch';
import {ExpiredFilter} from '@src/components/EntitiesFilters/components/ExpiredFilter';
import {CategoriesSetFilter} from '@src/pages/ProblemsPage/components/filters/CategoriesSetFilter';
import {AcceptancePeriodFilter} from '@src/pages/WorkAcceptancesPage/components/filters/AcceptancePeriodFilter';
import {expiredFilterOptions} from '@src/pages/WorkAcceptancesPage/components/filters/constants/expiredFilterOptions';
import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {AcceptorsFilter} from './AcceptorsFilter';

interface IWorkAcceptancesFiltersProps {
	objectId?: string;
}

export const WorkAcceptancesFilters = ({objectId = 'all'}: IWorkAcceptancesFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IWorkAcceptancesFiltersState>();
	const {data: allFrontTypes} = useExtractWorkAcceptanceFrontTypesAsArray();
	const {data: allTypes} = useExtractWorkAcceptanceTypesAsArray();
	const {data: allStatuses} = useExtractWorkAcceptanceStatusesAsArray();
	const {t} = useTranslation();

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="work-acceptances"
		>
			<WorkAcceptancesSearch />

			{objectId === 'all' && (
				<ObjectsFilter
					value={state?.objects}
					label={t('entitiesFilters.objectsFilter.label')}
				/>
			)}

			{allStatuses && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.workAcceptanceStatuses.label')}
					filterName="statuses"
					statuses={state?.statuses}
					allStatuses={allStatuses}
					translationDictionaryKey={dictionaryKeys.workAcceptanceStatuses}
				/>
			)}

			{allTypes && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.workAcceptanceTypes.label')}
					filterName="types"
					statuses={state?.types}
					allStatuses={allTypes}
					translationDictionaryKey={dictionaryKeys.workAcceptanceTypes}
				/>
			)}

			{allFrontTypes && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.workAcceptanceFrontTypes.label')}
					filterName="frontTypes"
					statuses={state?.frontTypes}
					allStatuses={allFrontTypes}
					translationDictionaryKey={dictionaryKeys.workAcceptanceFrontTypes}
				/>
			)}

			<AcceptancePeriodFilter
				from={state?.acceptanceDateFrom}
				to={state?.acceptanceDateTo}
			/>

			<ExpiredFilter
				value={state.expired}
				customExpiredOptions={expiredFilterOptions}
				label={t('expiredFilterValues.expired')}
			/>

			<AcceptorsFilter
				acceptors={state?.acceptors}
				objectId={objectId}
				label={t('entitiesFilters.acceptors.label')}
			/>

			<CategoriesSetFilter
				objectId={objectId}
				categories={state.categories}
			/>

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
				label={t('entitiesFilters.CreatedByFilter.label')}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
				label={t('entitiesFilters.CreationDateFilter.label')}
			/>
		</EntitiesFilters>
	);
};
