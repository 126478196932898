import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {Tree} from 'array-to-tree';

/**
 * Рекурсивно обходит каждый уровень дерева для поиска нужного ID.
 *
 * @param {Tree<ICheckItem>} targetItem - Объект, представляющий узел дерева, в котором выполняется поиск.
 * @param {string} id  который требуется найти в дереве.
 * @return {boolean} - Возвращает true, если ID найден в дереве, и false в противном случае.
 */
export const findIdInTreeItems = (targetItem: Tree<ICheckItem>, id: string): boolean => {
	if (targetItem.id === id) {
		return true;
	}
	if (targetItem.children) {
		return targetItem.children.some(child => findIdInTreeItems(child, id));
	}
	return false;
};
