import MobileActions from './Actions.mobile';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';

interface IMobileRightButtonsProps {
	objectId: string;
	inspection: ILinkedInspection;
}

const MobileRightButtons = ({objectId, inspection}: IMobileRightButtonsProps) => {
	if (!inspection) {
		return null;
	}
	return (
		<MobileActions
			objectId={objectId}
			inspection={inspection}
		/>
);
};

export default MobileRightButtons;
