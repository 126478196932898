import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {Checkbox, IconMenu, MenuItem} from '@tehzor/ui-components';
import classNames from 'classnames';

interface IMenuProps {
	className?: string;
	statuses?: ISpaceStatus[];

	disabled?: boolean;
	isMobile?: boolean;
	isDefault?: boolean;

	onEdit?: () => void;
	onDelete?: () => void;
	onDefault?: () => void;
}

const editIcon = (
	<i className={classNames('tz-edit-20', 'editable-space-statuses__status-icon')}/>
);
const deleteIcon = (
	<i className={classNames('tz-delete', 'editable-space-statuses__status-icon')}/>
);

export const Menu = ({
	className, statuses, disabled, isMobile, isDefault, onEdit, onDelete, onDefault
}: IMenuProps) => {
	const menuItems = [];
	if (!disabled && isMobile) {
		menuItems.push(
			<MenuItem
				icon={editIcon}
				onClick={onEdit}
				key="edit"
			>
				Редактировать
			</MenuItem>
		);
	}

	if (!disabled && !isMobile) {
		menuItems.push(
			<MenuItem
				icon={<Checkbox checked={isDefault}/>}
				onClick={onDefault}
				key="default"
			>
				По умолчанию
			</MenuItem>
		);
	}
	if (!disabled && statuses?.length && statuses.length > 1) {
		menuItems.push(
			<MenuItem
				icon={deleteIcon}
				onClick={onDelete}
				key="delete"
			>
				Удалить
			</MenuItem>
		);
	}

	return (
		<IconMenu className={className}>
			{menuItems}
		</IconMenu>
	);
};