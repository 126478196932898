import {useEditOwnerAcceptanceMutation} from '@src/api/cache/ownerAcceptances/mutations';
import {IEditOwnerAcceptanceParams} from '@src/api/cache/ownerAcceptances/defaults/useOwnerAcceptancesMutationDefaults';
import {addTempFiles} from '@src/api/cache/utils/addTempFiles';

export const useEditOwnerAcceptance = () => {
	const mutation = useEditOwnerAcceptanceMutation();
	return async (args: IEditOwnerAcceptanceParams) => {
		const {objectId, acceptanceId, fields} = args;

		let data;

		if (fields.newAttachments) {
			data = await mutation.mutateAsync({
				objectId,
				acceptanceId,
				fields: {
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments)
				}
			});
		} else {
			data = await mutation.mutateAsync({
				objectId,
				acceptanceId,
				fields
			});
		}

		return data;
	};
};
