import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {findPermission} from '@tehzor/tools/utils/findPermission';

/**
 * Возвращает полномочия для конкретной передачи собственнику
 *
 * @param ownerAcceptance внутренняя приёмка
 * @param networkStatus статус соединения
 * @param objectId id объекта
 * @param user текущий пользователь
 */
export const getPermissionsForOwnerAcceptance = (
	ownerAcceptance: IOwnerAcceptance,
	networkStatus: boolean,
	objectId?: string,
	user?: IUser
) => {
	let canDelete = false;
	if (networkStatus && user) {
		const deletePermission = findPermission(
			user.roles || [],
			'owner-acceptances-delete',
			UserRoleScopes.OBJECT,
			objectId
		);

		if (deletePermission) {
			canDelete = deletePermission.restrictions
				? !!deletePermission.restrictions.ownerAcceptancesCreatedByUser
				&& !!ownerAcceptance.createdBy
				&& user.id === ownerAcceptance.createdBy.id
				: true;
		}
	}
	return {canDelete};
};
