import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useIsFetching} from '@tanstack/react-query';
import {CacheStatus} from '@tehzor/tools/enums/CacheStatus';
import {useMemo} from 'react';

export const cacheStatus = () => {
	const {objects: objectsSettings} = useAppSelector(s => s.settings.offlineMode.cache);
	const companies = !!useIsFetching({queryKey: restDictionariesQueryKeys.all()});
	const objects = !!useIsFetching({queryKey: objectsQueryKeys.list()});
	const checkLists = !!useIsFetching({queryKey: checkListsQueryKeys.list()});
	const checkItems = !!useIsFetching({queryKey: checkItemsQueryKeys.list()});
	const plans = !!useIsFetching({queryKey: plansQueryKeys.list(objectsSettings)});
	const workingGroups = !!useIsFetching({queryKey: workingGroupsQueryKeys.list()});
	const responsibilityRules = !!useIsFetching({queryKey: responsibilityRulesQueryKeys.list()});
	const checkRecords = !!useIsFetching({
		queryKey: [...checkRecordsQueryKeys.list(), undefined, objectsSettings]
	});
	const categories = !!useIsFetching({queryKey: categoriesQueryKeys.list()});
	const spaces = !!useIsFetching({
		queryKey: [...spacesQueryKeys.list(), {objects: objectsSettings}, null, null, 1000000]
	});
	return useMemo(
		() =>
			[
				companies,
				objects,
				checkLists,
				checkItems,
				plans,
				workingGroups,
				responsibilityRules,
				checkRecords,
				categories,
				spaces
			].some(status => status)
				? CacheStatus.CACHING
				: CacheStatus.READY,
		[
			companies,
			objects,
			checkLists,
			checkItems,
			plans,
			workingGroups,
			responsibilityRules,
			checkRecords,
			categories,
			spaces
		]
	);
};
