import {Column} from 'react-table';
import {MenuCell} from './MenuCell';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';

export const desktopColumns: Array<Column<IEnrichedWorkingGroup>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Название',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},
	{
		id: 'companyId',
		Header: 'Компания',
		accessor: item => item.company?.name,
		width: 80,
		minWidth: 80
	},
	{
		id: 'leader',
		Header: 'Руководитель',
		accessor: item => item.leader?.fullName,
		width: 80,
		minWidth: 80
	},
	{
		id: 'stage',
		Header: 'Стадия',
		accessor: item => item?.stage?.map(stage => stage?.name).join(', '),
		width: 80,
		minWidth: 80
	},
	{
		id: 'type',
		Header: 'Тип',
		accessor: item => item.type?.name,
		width: 80,
		minWidth: 80
	},
	{
		id: 'createdAt',
		accessor: 'createdAt',
		Header: 'Создание',
		width: 80,
		minWidth: 80
	},
	{
		id: 'modifiedAt',
		accessor: 'modifiedAt',
		Header: 'Изменение',
		width: 80,
		minWidth: 80
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];
