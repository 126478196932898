import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteManySpaceStatusesResponse {
	success: boolean;
}

/**
 * Удаляет статусы помещений по id набора
 *
 * @param spaceStatusesSetId id набора статусов помещений
 */
export const requestDeleteManySpaceStatuses = async (spaceStatusesSetId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteManySpaceStatusesResponse>(
		'space-statuses',
		{data: {spaceStatusesSetId}}
	);
	return response.data;
};
