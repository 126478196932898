import TasksPageCounter from '@src/pages/TasksPage/components/TasksPageCounter';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';
import {MobileSwitchMode} from '@src/pages/TasksPage/components/MobileSwitchMode';
import {TasksFilters} from '@src/pages/TasksPage/components/TasksFilters';
import {useTasksPageDisplayMode} from '@src/core/hooks/queries/tasks/hooks';

export const TasksPageControlBarMobile = () => {
	const displayMode = useTasksPageDisplayMode();

	return (
		<div className="tasks-page__m-control-bar">
			<MobileSwitchMode />
			{displayMode === TasksDisplayMode.LIST && <TasksPageCounter />}
			{displayMode === TasksDisplayMode.SCHEDULE && (
				<div className="tasks-page__m-control-bar-selector" />
			)}

			<div className="tasks-page__m-control-bar-filter">
				<TasksFilters />
			</div>
		</div>
	);
};
