import {IDndDragState, IDndItem, IDragPositionEvent, IPositionRef} from '../interfaces';

/**
 * Проверяет изменилась ли позиция курсора
 * 
 * @param event данные события перемещения
 * @param dragState drag состояние
 * @param positionRef ref с предыдущей позицией
 */
export const isPositionChanged = <S extends IDndItem>(
	event: IDragPositionEvent,
	dragState: IDndDragState<S>,
	positionRef: IPositionRef
): boolean => {
	const {x, y} = event;
	const {isDragging} = dragState;

	const refX = positionRef.current.x;
	const refY = positionRef.current.y;

	if (!isDragging || (x === refX && y === refY) || !x || !y) {
		return false;
	}
	positionRef.current = {x, y};

	return true;
};