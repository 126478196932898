import {EmptyTableMessage} from '@src/components/EmptyTableMessage/EmptyTableMessage';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {useInspections} from '@src/core/hooks/queries/inspections/hooks';
import {useInspectionSettings} from '@src/core/hooks/settings/useInspectionSettings';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';
import {useInspectionsTableColumns} from '@src/pages/InspectionsPage/hooks/useInspectionsTableColumns';
import {deleteInspection} from '@src/store/modules/entities/inspection/actions';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import {inspectionsActions} from '@src/store/modules/settings/pages/inspections/slice';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {createContext, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {usePreparedInspections} from '../../hooks/usePreparedInspections';

const pageSizes = [10, 20, 50, 100];

interface IInspectionsTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IInspectionEntity>) => void
>(() => ({}));

const InspectionsTable = ({objectId = 'all'}: IInspectionsTableProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {data: inspectionsCacheData} = useInspections(objectId);

	const {changeSelectedRows, changeSort, changePageSize, changeOffset} = inspectionsActions;

	const pageSettings = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const pagesCount = Math.ceil((inspectionsCacheData?.total ?? 0) / pageSettings.pageSize);
	const currentPage = Math.floor((inspectionsCacheData?.offset ?? 0) / pageSettings.pageSize);
	const settings = useInspectionSettings(objectId);

	const preparedInspections = usePreparedInspections({objectId, cacheData: inspectionsCacheData});

	const inspectionsEntities = useMemo(
		() => [
			...(preparedInspections.map(item => ({
				id: item.id,
				type: 'inspection',
				data: item
			})) as IInspectionEntity[])
		],
		[preparedInspections]
	);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.inspections.deleteTitle'),
		message: ''
	});

	const handleRowClick = useCallback(
		(inspection: IInspectionEntity) => {
			if (
				!inspection.data.object ||
				(!inspection.data.links?.checkId && !inspection.data.links?.internalAcceptanceId)
			) {
				return;
			}
			pushPath(`/objects/${inspection.data.object.id}/inspections/${inspection.id}`);
		},
		[pushPath]
	);

	const handleSelectedRowsChange = useCallback(
		(selectedRows: string[]) => dispatch(changeSelectedRows({objectId, selectedRows})),
		[dispatch, objectId, changeSelectedRows]
	);

	const handleSortChange = useCallback(
		(sort: {[key: string]: boolean}) => {
			dispatch(changeSort({objectId, sort}));
		},
		[objectId, dispatch, changeSort]
	);

	const handlePageSizeChange = useCallback(
		(pageSize: number) => {
			dispatch(changePageSize({objectId, pageSize}));
			const offset = Math.floor((inspectionsCacheData?.offset ?? 0) / pageSize);
			dispatch(changeOffset({objectId, offset}));
		},
		[objectId, inspectionsCacheData?.offset, changeOffset, changePageSize, dispatch]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (inspectionsCacheData?.offset !== offset) {
				dispatch(changeOffset({objectId, offset}));
			}
		},
		[dispatch, changeOffset, inspectionsCacheData?.offset, pageSettings.pageSize]
	);

	const handleContextMenuAction = useCallback(
		async ({type, payload}: ITableContextMenuAction<IInspectionEntity>) => {
			if (type === 'delete') {
				if (
					payload.data.object &&
					(await getDeleteConfirmation()) &&
					payload.type === 'inspection'
				) {
					await dispatch(deleteInspection(payload.data.object.id, payload.id));
				}
			}
		},
		[objectId]
	);

	const isLargeTablet = useIsLargeTablet();

	const [columns, activeColumnsLength] = useInspectionsTableColumns({
		objectId,
		isDesktop: isLargeTablet,
		settings,
		t
	});

	if (isLargeTablet && !activeColumnsLength) {
		return <EmptyTableMessage />;
	}

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={inspectionsEntities}
						selectedRows={pageSettings.selectedRows}
						sort={pageSettings.sort}
						selectable
						responsive={!isLargeTablet}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>

			{deleteDialog}
		</>
	);
};

export default InspectionsTable;
