import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import * as inspectionsTypes from '@src/store/modules/entities/inspection/constants';
import * as commentTypes from '@src/store/modules/entities/workAcceptanceComment/constants';
import * as replyTypes from '@src/store/modules/entities/workAcceptanceReply/constants';
import * as problemReplyTypes from '@src/store/modules/entities/problemReply/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	IGetWorkAcceptanceProblemsPayload,
	IGetWorkAcceptanceInspectionsPayload
} from './actions';
import {
	IAddProblemPayload,
	IDeleteProblemPayload,
	IEditProblemStatusPayload
} from '@src/store/modules/pages/problem/actions';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IAddProblemCommentPayload} from '@src/store/modules/entities/problemComment/actions';
import {
	ILoadedEntityState,
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess
} from '@tehzor/tools/utils/reducersHandlers';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {IGetDelegationHistoriesByWorkAcceptancePayload} from './actions/delegationHistories';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ILoadableEntitiesState} from '@tehzor/tools/core/storeHelpers/reducers';
import {ICommentStory} from '@tehzor/tools/interfaces/comments/ICommentStory';
import {IGetWorkAcceptanceCommentsPayload} from './actions/comments';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {IAddInspectionPayload} from '../inspection/actions';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IAddWorkAcceptanceCommentPayload, IDeleteWorkAcceptanceCommentPayload} from '../workAcceptanceComment/actions';

const {entitiesCacheTime} = config;

export interface IWorkAcceptanceState {
	problems: {
		byId: Record<string, IListProblem>;
		allIds: string[];
		expires?: number;
	};
	inspections: {
		byId: Record<string, IListInspection>;
		allIds: string[];
		expires?: number;
	};
	problemReplies: {
		byId: Record<string, IComment>;
		allIds: string[];
		expires?: number;
	};
	delegationHistories: ILoadedEntityState<IDelegationHistory>;
	comments: INormalizedData<IComment> & {
		offset: number;
		limit: number;
		total: number;
		hasMore: boolean;
	};
	commentsStories: ILoadableEntitiesState<ICommentStory>;
}

export const getInitialState = (): IWorkAcceptanceState => ({
	problems: {
		byId: {},
		allIds: []
	},
	inspections: {
		byId: {},
		allIds: []
	},
	problemReplies: {
		byId: {},
		allIds: []
	},
	delegationHistories: {
		byId: {},
		allIds: [],
		loaded: false
	},
	comments: {
		byId: {},
		allIds: [],
		offset: 0,
		limit: 20,
		total: 0,
		hasMore: false
	},
	commentsStories: {
		byId: {},
		allIds: [],
		loaded: false
	}
});
/**
 * Находит позицию для вставки нового комментария,
 * с учётом того, что они должны быть отсортированы по дате добавления
 */
const findCommentPlace = (state: IWorkAcceptanceState, comment: IComment) => {
	for (let i = state.comments.allIds.length - 1; i >= 0; i--) {
		const item = state.comments.byId[state.comments.allIds[i]];
		if (item.createdAt && comment.createdAt && item.createdAt <= comment.createdAt) {
			return i;
		}
	}
	return 0;
};

const handleCommentAdd = (
	state: IWorkAcceptanceState,
	{payload}: {payload: IAddWorkAcceptanceCommentPayload}
) => {
	state.comments.byId[payload.id] = payload;
	const index = findCommentPlace(state, payload);
	if (index === state.comments.allIds.length - 1 || index === state.comments.allIds.length) {
		state.comments.allIds.push(payload.id);
	} else {
		state.comments.allIds.splice(index, 0, payload.id);
	}
	state.comments.offset += 1;
	state.comments.total += 1;
};

const handleCommentChange = (state: IWorkAcceptanceState, {payload}: {payload: IComment}) => {
	if (state.comments.allIds.includes(payload.id)) {
		state.comments.byId[payload.id] = payload;
	}
};

const handleCommentDelete = (
	state: IWorkAcceptanceState,
	{payload}: {payload: IDeleteWorkAcceptanceCommentPayload}
) => {
	if (state.comments.allIds.includes(payload.commentId)) {
		state.comments.allIds = state.comments.allIds.filter(id => id !== payload.commentId);
		delete state.comments.byId[payload.commentId];
	}
};
export const workAcceptance = createReducer<IWorkAcceptanceState>(getInitialState(), {
	[types.GET_REQUEST]: getInitialState,
	// Загрузка сущностей
	[types.GET_PROBLEMS_SUCCESS]: (
		state,
		{payload}: {payload: IGetWorkAcceptanceProblemsPayload}
	) => {
		state.problems.byId = payload.byId;
		state.problems.allIds = payload.allIds;
		state.problems.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_INSPECTIONS_SUCCESS]: (
		state,
		{payload}: {payload: IGetWorkAcceptanceInspectionsPayload}
	) => {
		state.inspections.byId = payload.byId;
		state.inspections.allIds = payload.allIds;
		state.inspections.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Нарушения
	[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
		state.problems.byId[payload.id] = payload;
		state.problems.allIds.unshift(payload.id);
	},
	[problemTypes.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditProblemStatusPayload}
	) => {
		if (state.problems.allIds.includes(payload.id)) {
			state.problems.byId[payload.id] = payload;
		}
	},
	[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (state.problems.allIds.includes(payload.problemId)) {
			state.problems.allIds = state.problems.allIds.filter(id => id !== payload.problemId);
			delete state.problems.byId[payload.problemId];
		}
	},
	// Осмотры
	[inspectionsTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddInspectionPayload}) => {
		state.inspections.byId[payload.id] = payload;
		state.inspections.allIds.unshift(payload.id);
	},
	// Ответы на нарушения
	[problemReplyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemCommentPayload}) => {
		state.problemReplies.byId[payload.id] = payload;
		state.problemReplies.allIds.unshift(payload.id);
	},
	[types.GET_DELEGATION_HISTORIES_REQUEST]: state => {
		state.delegationHistories = getLoadedEntitiesInitialState();
	},
	[types.GET_DELEGATION_HISTORIES_SUCCESS]: (
		state,
		params: {payload: IGetDelegationHistoriesByWorkAcceptancePayload}
	) => {
		state.delegationHistories = getLoadedEntitiesSuccess<IDelegationHistory>()(
			state.delegationHistories,
			params
		);
	},

	// комментарии
	[types.GET_COMMENTS_REQUEST]: state => {
		state.comments.hasMore = false;
	},

	[types.GET_COMMENTS_SUCCESS]: (state, {payload}: {payload: IGetWorkAcceptanceCommentsPayload}) => {
		state.comments.byId = {...state.comments.byId, ...payload.byId};
		state.comments.allIds = Array.from(
			new Set([...payload.allIds.reverse(), ...state.comments.allIds])
		);
		state.comments.offset = payload.offset + payload.limit;
		state.comments.limit = payload.limit;
		state.comments.total = payload.total;
		state.comments.hasMore = payload.total > payload.offset + payload.limit;
	},

	[types.RESET_COMMENTS]: state => {
		state.comments = getInitialState().comments;
	},
	[replyTypes.ADD_SUCCESS]: handleCommentAdd,
	[replyTypes.EDIT_SUCCESS]: handleCommentChange,
	[replyTypes.DELETE_SUCCESS]: handleCommentDelete,
	[commentTypes.ADD_SUCCESS]: handleCommentAdd,
	[commentTypes.EDIT_SUCCESS]: handleCommentChange,
	[commentTypes.DELETE_SUCCESS]: handleCommentDelete,

	[CLEAR_STORE]: getInitialState
});
