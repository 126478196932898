import {Button} from '@tehzor/ui-components';
import { memo, ReactNode } from 'react';

interface IDetailAction {
	label?: string;
	leftIcon?: ReactNode;
	onClick?: () => void;
}
export const DetailAction = memo((props: IDetailAction) => {
	const {label, leftIcon, onClick} = props;
	return (
		<div className="check-item-detail__action">
			<Button
				className="check-item-detail__action-btn"
				label={label}
				type="accent-blue"
				leftIcon={leftIcon}
				onClick={onClick}
			/>
		</div>
	);
});
