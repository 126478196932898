import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {formWarrantyClaimLink} from '@tehzor/tools/utils/links';
import {useMemo} from 'react';
import {useSpaceForWarrantyClaimBreadcrumbs} from './useSpaceForWarrantyClaimBreadcrumbs';
import {useTranslation} from "react-i18next";

/**
 * Хук для хлебных крошек от "всех объектов" до гарантийного обращения
 *
 * @param objectId id конечного объекта
 * @param warrantyClaimId id обращения
 * @param warrantyClaim обращение
 */
export function useWarrantyClaimFromSpaceBreadcrumbs(
	objectId: string,
	warrantyClaimId: string,
	warrantyClaim: ILinkedWarrantyClaim
) {
	const {t} = useTranslation();
	const res = useSpaceForWarrantyClaimBreadcrumbs(objectId, warrantyClaim.space);
	return useMemo(
		() =>
			res.concat({
				label: warrantyClaim.number
					? `${t('warrantyClaimPage.title')} №${warrantyClaim.number}`
					: t('warrantyClaimPage.title'),
				url: formWarrantyClaimLink(objectId, warrantyClaimId),
				inactive: true
			}),
		[objectId, res, warrantyClaimId]
	);
}
