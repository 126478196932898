/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import './PhotoCarousel.less';
import classNames from 'classnames';
import TouchRecognizer, {ITouchRecognizerProps, PanEvent, SwipeDirection} from '../../various/TouchRecognizer/TouchRecognizer2';
import anime, {AnimeInstance} from 'animejs';
import debounce from 'lodash/debounce';
import {convertClassNames} from '../../../utils/convertClassNames';
import Photo from './components/Photo';
import PhotoArrowButton from '../PhotoArrowButton';

const recognizerDragOptions: ITouchRecognizerProps['dragOptions'] = {startAxis: 'x', lockAxis: 'x'};

interface IPhotoCarouselProps {
	className?: string | {
		root?: string;
		touchRoot?: string;
		image?: string;
		arrowButton?: string;
	};
	style?: React.CSSProperties;
	data: string[];
	value?: number;
	backgroundSize?: string;
	useArrowsNavigation?: boolean;
	heightRatio?: number;
	animationDuration: number;

	onChange?: (index: number) => void;
	onClick?: (index: number) => void;
}

interface IPhotoCarouselState {
	value: number;
}

class PhotoCarousel extends React.PureComponent<IPhotoCarouselProps, IPhotoCarouselState> {
	static defaultProps = {
		data: [],
		backgroundSize: 'cover',
		animationDuration: 250
	};

	/**
	 * Корневой элемент
	 */
	private frameRef?: HTMLDivElement | null;

	/**
	 * Обёртка изображений
	 */
	private wrapperRef?: HTMLDivElement | null;

	/**
	 * Объект анимации wrapper'а
	 */
	private wrapperAnimation?: AnimeInstance;

	/**
	 * Ширина корневого элемента
	 */
	private frameWidth = 0;

	/**
	 * Текущее смещение wrapper'а
	 */
	private wrapperX = 0;

	/**
	 * Обрабатывает событие изменения размера элемента
	 */
	private handleResize = debounce(() => {
		this.saveFrameWidth();
		this.wrapperX = -this.state.value * this.frameWidth;
		this.setFrameHeight();
		this.setWrapperWidth();
		this.setWrapperPosition();
	}, 150);

	constructor(props: IPhotoCarouselProps) {
		super(props);

		this.state = {value: props.value ?? 0};
	}

	static getDerivedStateFromProps(nextProps: IPhotoCarouselProps, prevState: IPhotoCarouselState) {
		if (nextProps.value !== undefined && nextProps.value !== prevState.value) {
			return {value: nextProps.value};
		}
		return null;
	}

	/**
	 * Переводит к следующему изображению
	 */
	next = () => {
		const {value} = this.state;
		if (value < this.props.data.length - 1) {
			this.changeValue(value + 1);
		}
	};

	/**
	 * Переводит к предыдущему изображению
	 */
	prev = () => {
		const {value} = this.state;
		if (value > 0) {
			this.changeValue(value - 1);
		}
	};

	componentDidUpdate(prevProps: IPhotoCarouselProps, prevState: IPhotoCarouselState) {
		const {value} = this.state;

		if (value !== prevState.value) {
			this.startWrapperAnimation();
		}
		if (this.props.data.length !== prevProps.data.length) {
			this.setWrapperWidth();
		}
	}

	render() {
		const {className, style, data, backgroundSize, useArrowsNavigation, onClick} = this.props;
		const {value} = this.state;
		const classes = convertClassNames(className);

		return (
			<div
				className={classNames(
					'photo-carousel',
					{'photo-carousel_clickable': !!onClick},
					classes.root
				)}
				style={style}
			>
				<TouchRecognizer
					className={classNames('photo-carousel__touch-root',	classes.touchRoot)}
					dragOptions={recognizerDragOptions}
					tabIndex={-1}
					role="list"
					onPanStart={this.handlePanStart}
					onPanMove={this.handlePanMove}
					onPanEnd={this.handlePanEnd}
					onSwipe={this.handleSwipe}
					onResize={this.handleResize}
					onTap={this.handleClick}
					onKeyDown={this.handleKeyDown}
					elementRef={this.saveRef}
				>
					<div
						className="photo-carousel__wrapper"
						onDragStart={this.preventDrag}
						ref={this.saveWrapperRef}
					>
						{data.map((url, index) => (
							<Photo
								key={index}
								className={classNames('photo-carousel__img', classes.image)}
								style={{backgroundSize}}
								url={url}
								suspend={Math.abs(value - index) > 2}
							/>
						))}
					</div>
				</TouchRecognizer>

				{useArrowsNavigation && (
					<div className="photo-carousel__hover-area">
						{value > 0 && (
							<PhotoArrowButton
								className={classNames('photo-carousel__arrow-button', classes.arrowButton)}
								type="left"
								onClick={this.handlePrevBtnClick}
							/>
						)}

						{value < data.length - 1 && (
							<PhotoArrowButton
								className={classNames('photo-carousel__arrow-button', classes.arrowButton)}
								type="right"
								onClick={this.handleNextBtnClick}
							/>
						)}
					</div>
				)}
			</div>
		);
	}

	/**
	 * Обрабывает событие по кнопке перехода к следующему изображению
	 *
	 * @param event событие
	 */
	private handleNextBtnClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.next();
	};

	/**
	 * Обрабывает событие по кнопке перехода к предыдущему изображению
	 *
	 * @param event событие
	 */
	private handlePrevBtnClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.prev();
	};

	/**
	 * Обрабатывает событие начала движения
	 */
	private handlePanStart = () => {
		this.stopWrapperAnimation();
	};

	/**
	 * Обрабатывает событие движения
	 *
	 * @param event событие
	 */
	private handlePanMove = (event: PanEvent) => {
		const {data} = this.props;
		const {value} = this.state;

		this.wrapperX += event.deltaX;
		let x: number;

		if (this.wrapperX > 0) {
			// Натянутое движение за границей слева
			x = this.wrapperX ** 0.5 * 2;
		} else if (this.wrapperX < -this.frameWidth * (data.length - 1)) {
			// Натянутое движение за границей справа
			const a = -this.frameWidth * (data.length - 1);
			x = a - (a - this.wrapperX) ** 0.5 * 2;
		} else {
			// Проверка нахождения смещения в пределах диапазона от предыдущего изображения до следующего
			x = Math.max(
				Math.min(this.wrapperX, -this.frameWidth * (value - 1)),
				-this.frameWidth * (value + 1)
			);
			this.wrapperX = x;
		}
		this.setWrapperPosition(x);
	};

	/**
	 * Обрабатывает событие завершения движения
	 *
	 * @param event событие
	 */
	private handlePanEnd = (event: PanEvent) => {
		const {value} = this.state;
		const distance = event.initialClientX - event.clientX;

		if (Math.abs(distance) > this.frameWidth * 0.25) {
			if (distance < 0 && value > 0) {
				return this.changeValue(value - 1);
			}
			if (distance > 0 && value < this.props.data.length - 1) {
				return this.changeValue(value + 1);
			}
		}
		this.startWrapperAnimation();
	};

	/**
	 * Обрабатывает событие swipe
	 *
	 * @param event событие
	 * @param direction направление жеста
	 */
	private handleSwipe = (event: PanEvent, direction?: SwipeDirection) => {
		const {value} = this.state;

		if (direction) {
			if (direction === 'right' && value > 0) {
				return this.changeValue(value - 1);
			}
			if (direction === 'left' && value < this.props.data.length - 1) {
				return this.changeValue(value + 1);
			}
		}
		this.startWrapperAnimation();
	};

	/**
	 * Обрабатывает событие click
	 */
	private handleClick = () => {
		if (this.props.onClick) {
			this.props.onClick(this.state.value);
		}
	};

	/**
	 * Обрабатывает событие keyDown для перелистывания стрелками
	 *
	 * @param event событие
	 */
	private handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'ArrowLeft') {
			this.prev();
		}
		if (event.key === 'ArrowRight') {
			this.next();
		}
	};

	/**
	 * Сохраняет ширину корневого элемента
	 */
	private saveFrameWidth = () => {
		this.frameWidth = this.frameRef ? this.frameRef.offsetWidth : 0;
	};

	/**
	 * Устанавливает высоту корневого элемента при наличии параметра heightRatio
	 */
	private setFrameHeight = () => {
		if (this.props.heightRatio !== undefined && this.frameRef) {
			this.frameRef.style.height = `${this.frameWidth * this.props.heightRatio}px`;
		}
	};

	/**
	 * Устанавливает ширину wrapper'а
	 */
	private setWrapperWidth = () => {
		if (this.wrapperRef) {
			this.wrapperRef.style.width = `${this.frameWidth * this.props.data.length}px`;
		}
	};

	/**
	 * Устанавливает позицию wrapper'а
	 *
	 * @param offset смещение, если не задано, то берётся this.wrapperX
	 */
	private setWrapperPosition = (offset?: number) => {
		if (this.wrapperRef) {
			this.wrapperRef.style.transform = `translateX(${offset ?? this.wrapperX}px)`;
		}
	};

	/**
	 * Запускает анимацию движения wrapper'а для отображения текущего элемента
	 */
	private startWrapperAnimation = () => {
		this.stopWrapperAnimation();
		if (this.wrapperRef) {
			const targets = {offset: +this.wrapperRef.style.transform.slice(11, -3)};
			this.wrapperAnimation = anime({
				targets,
				offset: -this.state.value * this.frameWidth,
				easing: 'cubicBezier(0.32, 0.72, 0.37, 0.95)',
				duration: this.props.animationDuration,
				update: () => {
					this.wrapperX = targets.offset;
					this.setWrapperPosition();
				}
			});
		}
	};

	/**
	 * Останавливает анимацию движения wrapper'а
	 */
	private stopWrapperAnimation = () => {
		if (this.wrapperAnimation) {
			this.wrapperAnimation.pause();
			this.wrapperAnimation = undefined;
		}
	};

	/**
	 * Инициирует изменение значения
	 *
	 * @param value новое значение
	 */
	private changeValue = (value: number) => {
		if (this.props.onChange) {
			this.props.onChange(value);
		} else {
			this.setState({value});
		}
	};

	/**
	 * Предотвращает перетаскивание
	 *
	 * @param event событие
	 */
	private preventDrag = (event: React.DragEvent) => {
		event.preventDefault();
		return false;
	};

	/**
	 * Сохраняет ссылку на крневой элемент
	 *
	 * @param element dom-элемент
	 */
	private saveRef = (element: HTMLDivElement | null) => {
		this.frameRef = element;
		this.saveFrameWidth();
		this.setFrameHeight();
		this.setWrapperWidth();
	};

	/**
	 * Сохраняет ссылку на wrapper
	 *
	 * @param element dom-элемент
	 */
	private saveWrapperRef = (element: HTMLDivElement | null) => {
		this.wrapperRef = element;
	};
}

export default PhotoCarousel;
