import { forwardRef, Ref, useCallback, useImperativeHandle } from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useEditableInspection} from '@src/components/EditableInspection/hooks/useEditableInspection';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {useAddNewInspection} from '@src/core/hooks/mutations/inspection/useAddInspection';
import {AddingEntities} from '@src/api/mutations';
import {useObject} from '@src/core/hooks/queries/objects/hooks';

interface IAddingInspectionProps {
	objectId: string;
	links?: IInspection['links'];
	stage?: ObjectStageIds;
	defaultData?: ISavingInspection;
	saving: boolean;
	isLoading?: boolean;
	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IAddingInspectionRefProps {
	getSavingData: (useLocalFiles?: boolean) => Promise<ISavingInspection | undefined>;
	save: (
		savingData?: ISavingInspection,
		extraLinks?: IInspection['links'],
		cacheKey?: string
	) => Promise<string | undefined | void> | string | void;
	cancel: () => void;
	saveCache?: (
		savingData?: ISavingInspection,
		extraLinks?: IInspection['links']
	) => Promise<
		| {
				cacheKey: string;
				type: AddingEntities;
				links: IInspection['links'];
		  }
		| undefined
	>;
}

const AddingInspection = (
	{
		objectId,
		links,
		stage = ObjectStageIds.BUILDING,
		defaultData,
		saving,
		onClose,
		setSaving,
		isLoading
	}: IAddingInspectionProps,
	ref?: Ref<IAddingInspectionRefProps>
) => {
	const {data: object} = useObject(objectId);
	const [fields, getSavingData, , isBlocking] = useEditableInspection(
		objectId,
		stage,
		defaultData,
		saving,
		true,
		isLoading,
		links
	);
	const {createNewInspectionCache, saveInspection} = useAddNewInspection(object);
	const saveCache = useCallback(
		async (
			savingData?: ISavingInspection & {templateId?: string},
			extraLinks?: IInspection['links']
		) => {
			setSaving(true);
			if (savingData) {
				const {templateId, ...data} = savingData;
				const cache = await createNewInspectionCache({
					objectId,
					links: {...links, ...extraLinks},
					stage,
					fields: data
				});
				return {
					cacheKey: cache.id,
					links: cache.links,
					type: AddingEntities.INSPECTION
				};
			}
			return undefined;
		},
		[setSaving, onClose, createNewInspectionCache, objectId, links, stage]
	);
	const save = useCallback(
		(savingData?: ISavingInspection, extraLinks?: IInspection['links'], cacheKey?: string) => {
			if (savingData) {
				const {...data} = savingData;
				saveInspection({
					objectId,
					links: {...links, ...extraLinks},
					stage,
					fields: data,
					key: cacheKey || ''
				});
			}
		},
		[objectId, stage, links, saveInspection]
	);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	useImperativeHandle(ref, () => ({save, saveCache, cancel, getSavingData}), [
		save,
		saveCache,
		cancel,
		getSavingData
	]);

	return (
		<>
			{fields}
			{closingDialog}
		</>
	);
};

export default forwardRef<IAddingInspectionRefProps, IAddingInspectionProps>(AddingInspection);
