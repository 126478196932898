interface TreeItem {
	id: string;
	parentId?: string;
}

/**
 * Формирует список идентификаторов всего вложенного дерева по parentId
 *
 * @param mainItemId Идентификатор главного элемента
 * @param data Массив данных
 * @param includingMainItem Флаг для включения/исключения главного элемента из результата
 */
export const getAllTreeKeysByParentId = <T extends TreeItem>(
	mainItemId: string,
	data: T[],
	includingMainItem = true
): string[] => {
	const result: string[] = [];

	function findChildren(currentId: string) {
		if (includingMainItem || currentId !== mainItemId) {
			result.push(currentId);
		}
		const children = data.filter(item => item.parentId === currentId);
		for (const child of children) {
			findChildren(child.id);
		}
	}

	findChildren(mainItemId);
	return result;
};
