import {memo, useCallback, useMemo, useRef, useState} from 'react';
import IFile from '@tehzor/tools/interfaces/IFile';
import {onlineManager} from '@tanstack/react-query';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import {convertRawFiles} from '@src/components/editableFields/Attachments/utils/convertRawFiles';
import {addTempFile, deleteTempFile} from '@src/store/modules/entities/tempFile/actions';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import {editWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions';
import {FilesPicker} from '@tehzor/ui-components';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {useUpdateEffect} from 'react-use';
import {FilesUploaderBlock} from '@src/components/FilesUploaderBlock';
import {useTranslation} from 'react-i18next';

interface IWorkAcptAttachmentsProps {
	attachments?: IAttachment[];
	objectId: string;
	workAcceptanceId: string;
}

export const WorkAcptAttachments = memo((props: IWorkAcptAttachmentsProps) => {
	const {attachments, objectId, workAcceptanceId} = props;
	const {t} = useTranslation();
	const [filesList, setFilesList] = useState<IAttachment[] | undefined>(attachments);
	const networkStatus = onlineManager.isOnline();
	const dispatch = useAppDispatch();
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [saving, setSaving] = useState(false);
	const pickerRef = useRef<FilesPicker>(null);

	const handleFilesPick = useCallback(
		(files: IRawFile[]) => {
			uploadingFilesDispatch({
				type: 'add',
				value: convertRawFiles(files, networkStatus, true)
			});
		},
		[networkStatus, uploadingFilesDispatch]
	);

	const saveAttachments = useCallback(async () => {
		setSaving(true);

		const uploadingFiles = await waitUploading();
		if (!isFilesExist(uploadingFiles) || someFilesHaveError(uploadingFiles)) {
			setSaving(false);
			return;
		}
		const data = await dispatch(
			editWorkAcceptance(objectId, workAcceptanceId, {
				newAttachments: convertFilesToSave(uploadingFiles)
			})
		);
		setFilesList(data.attachments);
		uploadingFilesDispatch({type: 'reset'});
		setSaving(false);
	}, [dispatch, objectId, uploadingFilesDispatch, waitUploading, workAcceptanceId]);

	const handleTemporaryFileAdd = useCallback(
		(...args: Parameters<typeof addTempFile>) => {
			void saveAttachments();
			return dispatch(addTempFile(...args));
		},
		[dispatch, saveAttachments]
	);

	const handleTemporaryFileDelete = useCallback(
		async (fileId: string) => {
			await dispatch(deleteTempFile(fileId));
		},
		[dispatch]
	);

	const handleUploadingFilesChange = useCallback(
		(value: IUploadingFile[]) => {
			uploadingFilesDispatch({type: 'update', value});
			void saveAttachments();
		},
		[saveAttachments, uploadingFilesDispatch]
	);

	const handleClickAdd = useCallback(() => {
		if (pickerRef.current) {
			pickerRef.current.open();
		}
	}, []);

	useUpdateEffect(() => {
		setFilesList(attachments);
	}, [attachments]);

	const filteredAttachments = useMemo(
		() =>
			filesList?.reduce<IFile[]>((acc, item) => {
				if (item.full) {
					acc.push(item.full);
				}
				return acc;
			}, []),
		[filesList]
	);

	if (!filteredAttachments) {
		return null;
	}

	return (
		<FilesUploaderBlock
			filesList={filteredAttachments}
			onTemporaryFileAdd={handleTemporaryFileAdd}
			onTemporaryFileDelete={handleTemporaryFileDelete}
			onFilesPick={handleFilesPick}
			onAddClick={handleClickAdd}
			networkStatus={networkStatus}
			uploadingFiles={uploadingFilesState.value}
			onUploadingFilesChange={handleUploadingFilesChange}
			pickerRef={pickerRef}
			isLoading={saving}
			label={t('workAcceptancePage.workAcptAttachments.label')}
		/>
	);
});

WorkAcptAttachments.displayName = 'WorkAcptAttachments';
