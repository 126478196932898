import {PrivateRoute} from '@src/containers/PrivateRoute';
import {InternalAcceptancesPage} from '@src/pages/InternalAcceptancesPage';
import InternalAcceptancePage from '@src/pages/InternalAcceptancePage';
import {InternalAcceptancesFiltersRoutingPage} from '@src/pages/filters/InternalAcceptancesFiltersRoutingPage/InternalAcceptancesFiltersRoutingPage';
import {internalAcceptancesFilterRoutes} from './internalAcceptancesFilterRoutes';

export const objectInternalAcceptancesRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<InternalAcceptancesPage />} />
	},
	{
		path: ':internalAcceptanceId',
		element: <PrivateRoute element={<InternalAcceptancePage />} />
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<InternalAcceptancesFiltersRoutingPage />} />,
		children: internalAcceptancesFilterRoutes
	}
];
