import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';

export const filterEmptyCategoriesSets = (
	categories: ICategory[],
	categorySets: ICategoriesSet[]
) => categorySets.filter(set => categories.some(category => category.categoriesSetId === set.id));

export const mapCategoryToTreeData = (
	data: Array<ICategory | ICategoriesSet>,
	multipleSelect = false
) => {
	const result = data.map(el => {
		if ('categoriesSetId' in el) {
			return {
				id: el.id,
				content: el.name,
				parentId: el?.categoriesSetId
			};
		}
		return {
			id: el.id,
			content: el.name,
			onlySemiCheckable: true,
			nonCheckable: !multipleSelect
		};
	});
	return result as ITreeDataItem[];
};

export const makeTreeDataCategory = (
	categories: ICategory[],
	categoriesSets: ICategoriesSet[],
	multipleSelect = false
) => {
	const filteredSets = filterEmptyCategoriesSets(categories, categoriesSets);
	const arr = [] as Array<ICategory | ICategoriesSet>;
	const dataForTree = arr.concat(categories, filteredSets);

	return mapCategoryToTreeData(dataForTree, multipleSelect);
};
