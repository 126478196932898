import {useQueryClient} from '@tanstack/react-query';
import {requestWorkingGroupTypes} from '@src/api/backend/workingGroupTypes';
import {restDictionariesQueryKeys} from '../keys';

export const useWorkingGroupsTypesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.workingGroupsTypes(), {
		queryFn: requestWorkingGroupTypes,
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
