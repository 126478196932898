import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {StatusSelect} from '@tehzor/ui-components';
import {useAsyncFn} from 'react-use';
import {CellProps} from 'react-table';
import {useTaskStatuses} from '@src/core/hooks/queries/taskStatuses/hook';
import {IEnrichedTaskForScheduler} from "@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler";
import {useEditTaskStatus} from "@src/core/hooks/mutations/task/useEditTaskStatus";

export const TaskStatusChangeable = ({row}: Pick<CellProps<IEnrichedTaskForScheduler>, 'row'>) => {
	const task = row.original;
	const {data: statuses} = useTaskStatuses();
	const {mutateAsync: editTaskStatus} = useEditTaskStatus();
	const permissions = useTasksPermissions({
		objectId: row.original?.objectId?.id,
		respUsers: row.original?.respUsers?.map(user => (user.id)),
		createdBy: row.original?.createdBy?.id,
		watchers: row.original?.watchers
	});

	const [, handleChange] = useAsyncFn(
		async (statusId: TaskStatusId) => {
			await editTaskStatus({taskId: task.id, statusId});
		},
		[task]
	);

	const availableStatuses = Object.values(TaskStatusId);

	return (
		<ClickPreventWrap>
			<StatusSelect
				className="tasks-schedule-popup__status"
				statuses={statuses}
				availableStatuses={availableStatuses}
				disabled={!permissions.canEditStatus}
				value={task?.status?.id}
				onChange={handleChange}
			/>
		</ClickPreventWrap>
	);
};
