export function isArrayEqual(arr1: string[] | undefined, arr2: string[] | undefined) {
	let flag = true;
	if (arr1 && arr2) {
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				flag = false;
			}
		}
	}

	return flag;
}
