import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordByListId} from '@src/store/modules/pages/checkLists/selectors/records';
import {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formWorkAcceptanceCheckListLink} from '@tehzor/tools/utils/links';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {DiagramIcon} from '../DiagramIcon/DiagramIcon';
import {RecordStatusSelect} from '@src/components/CheckRecordStatusSelect/RecordStatusSelect';
import {extractProblemsByCheckListIdAsArray} from '@src/store/modules/pages/checkLists/selectors/problems';
import declination from '@tehzor/tools/utils/declination';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {TitleWorkAcceptanceButtons} from '../TitleButtons.mobile';
import {TitleObjectButtons} from '../TitleObjectButtons.mobile';
import {format} from 'date-fns';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {useCheckListStatusPermission} from '@src/core/hooks/permissions/useCheckListStatusPermission';
import {TaskIndicators} from "@src/components/TaskIndicators/TaskIndicators";
import {useTranslation} from 'react-i18next';

interface ICheckListProps {
	checkList: ICheckList;
	showDivider?: boolean;
}

// TODO Разнести компоненты по разным файлам
const CheckList = ({checkList, showDivider}: ICheckListProps) => {
	const {pushPath} = useChangePath();
	const routeParams = useStrictParams<{
		objectId: string;
		workAcceptanceId: string;
		spaceId: string;
	}>();

	const record = useAppSelector(s => extractCheckRecordByListId(s, checkList.id));
	const problems = useAppSelector(s => extractProblemsByCheckListIdAsArray(s, checkList.id));

	const {changeCheckListRecord} = useChangeCheckRecord(
		routeParams.objectId,
		routeParams.spaceId,
		checkList
	);
	const handleClick = useCallback(() => {
		pushPath(formWorkAcceptanceCheckListLink({...routeParams, listId: checkList.id}));
	}, [pushPath, checkList.id, routeParams]);

	const {objectId} = routeParams;
	const {canStatusEdit, availableStatuses} = useCheckListStatusPermission(
		'check-lists-work-acceptance-status-edit',
		objectId
	);

	return (
		<div
			className="work-acceptance-page__check-lists"
			onClick={handleClick}
		>
			<div
				className="work-acceptance-page__check-list__m-list"
				role="presentation"
			>
				<DiagramIcon listId={checkList.id} />
				<div className="work-acceptance-page__check-list__m-list-text">
					<div className="work-acceptance-page__check-list__m-list-text-title">
						{checkList.name}
					</div>
					<div className="work-acceptance-page__check-list__m-list-text-subtitle">
						{record?.status === CheckRecordStatusId.ACCEPTED && record.modifiedAt
							? `принял ${format(
									record.modifiedAt,
									dateTimeLetterSeparatedFormat
							  )} ${record.modifiedBy?.displayName}`
							: problems.length > 0
							? `${problems.length} ${declination(problems.length, [
									'нарушение',
									'нарушения',
									'нарушений'
							  ])}`
							: null}
					</div>
				</div>
			</div>
			<div className="work-acceptance-page__check-list__m-list-indicators">
				<ProblemIndicators checkListId={checkList.id} />
				<TaskIndicators
					checkListId={checkList.id}
					workAcceptanceId={routeParams.workAcceptanceId}
				/>
			</div>
			<div className="work-acceptance-page__check-list__m-list-status-select">
				<ClickPreventWrap className="work-acceptance-page__check-list__d-plate-header-status-wrap">
					<RecordStatusSelect
						item={checkList}
						mutation={changeCheckListRecord}
						disabled={!canStatusEdit}
						availableStatuses={availableStatuses}
					/>
				</ClickPreventWrap>
			</div>

			{showDivider && <hr className="work-acceptance-page__check-list__divider" />}
		</div>
	);
};

interface ICheckListsProps {
	checkLists: ICheckList[];
}

export const CheckLists = ({checkLists}: ICheckListsProps) => {
	const {t} = useTranslation();
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId: string;
	}>();
	const listsLoading = useCheckListsIsLoading();

	useAppHeader(
		{
			title: t('workAcceptancePage.pageTabs.checklists.label'),
			showBackBtn: true,
			titleButtons: workAcceptanceId ? (
				<TitleWorkAcceptanceButtons
					objectId={objectId}
					workAcceptanceId={workAcceptanceId}
				/>
			) : (
				<TitleObjectButtons objectId={objectId} />
			)
		},
		[objectId, workAcceptanceId, checkLists]
	);

	return (
		<LoadingPanel active={listsLoading}>
			<Plate
				withoutPadding
				className="work-acceptance-page__check-list__m-lists"
			>
				{checkLists.map((checkList, index) => (
					<CheckList
						key={checkList.id}
						checkList={checkList}
						showDivider={index + 1 !== checkLists.length}
					/>
				))}
			</Plate>
		</LoadingPanel>
	);
};
