import {memo, useMemo} from 'react';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {format} from 'date-fns';
import {DescriptionPopUp} from './DescriptionPopUp';
import {DescriptionDialog} from './DescriptionDialog';
import {getDateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {ItemHeaderActions} from '../../../actions';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordsByItemIAndWorkAcceptanceIds} from '@src/store/modules/pages/checkLists/selectors/records';
import {EntityInfoLink} from '@tehzor/ui-components';
import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useTranslation} from 'react-i18next';
import {useWorkAcceptancesForSpaceAsArray} from '@src/core/hooks/queries/workAcceptances';

interface IItemHeaderProps {
	item: ICheckItem;
	stage: ObjectStageIds;
	record?: ICheckRecord;
	canAddEntity?: boolean;
	disabled?: boolean;
}

export const ItemHeader = memo(
	({item, stage, record, canAddEntity, disabled}: IItemHeaderProps) => {
		const {t} = useTranslation();
		const isDesktop = useIsDesktop();
		const {pushPath} = useChangePath();
		const {objectId, spaceId} = useStrictParams<{objectId: string; spaceId: string}>();
		const {data: workAcceptances} = useWorkAcceptancesForSpaceAsArray(objectId, spaceId);
		const workAcceptanceIds = workAcceptances?.map(wa => wa.id);

		const records = useAppSelector(state =>
			extractCheckRecordsByItemIAndWorkAcceptanceIds(state, item.id, workAcceptanceIds)
		);
		const itemWorkAcceptances = useMemo(
			() =>
				workAcceptances &&
				workAcceptances.filter(workAcceptance =>
					records.some(rec => rec.links.workAcceptanceId === workAcceptance.id)
				),
			[workAcceptances, records]
		);

		const navigate = (workAcceptanceId: string) => {
			if (record?.objectId) {
				pushPath(formWorkAcceptanceLink(record.objectId, workAcceptanceId));
			}
		};

		const toWorkAcceptance = (workAcceptanceId: string) => {
			navigate(workAcceptanceId);
		};

		return (
			<div className="check-list-items__item-header">
				{item.description ? (
					isDesktop ? (
						<DescriptionPopUp item={item} />
					) : (
						<DescriptionDialog item={item} />
					)
				) : (
					<div className="check-list-items__item-header-title">{item.name}</div>
				)}

				{record && record.modifiedAt ? (
					<div className="check-list-items__item-header-subtitle">
						<div className="check-list-items__item-header-subtitle_accepted">
							<p>
								{record.status === CheckRecordStatusId.ACCEPTED
									? t(
											'checkListPage.checklistItem.itemHeader.recordStatus.accepted'
									  )
									: t(
											'checkListPage.checklistItem.itemHeader.recordStatus.updated'
									  )}
								{format(
									record.modifiedAt,
									getDateTimeLetterSeparatedFormat(
										t(
											'checkListPage.checklistItem.itemHeader.recordStatus.separator'
										)
									)
								)}{' '}
								{record?.modifiedBy?.displayName}
							</p>
						</div>
						{itemWorkAcceptances?.map(workAcceptance => (
							<EntityInfoLink
								key={workAcceptance.id}
								className="structure-check-list-items__item-header-links"
								label={`${t(
									'checkListPage.checklistItem.itemHeader.entityInfoLink.label'
								)} ${workAcceptance.number}`}
								onClick={() => toWorkAcceptance(workAcceptance.id)}
							/>
						))}
					</div>
				) : null}
				<div className="check-list-items__item-header-actions">
					<ClickPreventWrap>
						<ItemHeaderActions
							canAddEntity={canAddEntity}
							stage={stage}
							checkItemId={item.id}
							checkListId={item.checkListId}
							disabled={disabled}
						/>
					</ClickPreventWrap>
				</div>
			</div>
		);
	}
);
