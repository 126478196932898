import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {TFunction} from 'i18next';

export const createDateDifference = (
	prev: number | undefined,
	next: number | undefined,
	t: TFunction<'translation', undefined>,
	locale: Locale,
	label?: string
): IHistoryData => ({
	prev: prev ? format(prev, longDateTimeFormat, {locale: locale || ru}) : '',
	next: next ? format(next, longDateTimeFormat, {locale: locale || ru}) : '',
	type: HistoryTypeId.PLANNED_FIX_DATE,
	name: label ? t('createDifference.createDateDifference') : ''
});
