import {wsConnector} from '../wsConnector';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';

export type IMoveWarrantyClaimResponse = IWarrantyClaim;

/**
 * Перемещает гарантийное обращение
 */
export const makeWarrantyClaimMoveRequest = (
	objectId: string,
	claimId: string,
	toObjectId: string,
	toSpaceId: string
) =>
	wsConnector.sendAuthorizedRequest<IMoveWarrantyClaimResponse>('moveWarrantyClaim', {
		objectId,
		claimId,
		toObjectId,
		toSpaceId
	});
