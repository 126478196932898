import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddExportTemplateResponse = INormalizedData<IExportTemplate>;

/**
 * Создает шаблон
 *
 * @param fields данные для добавления
 */
export const makeExportTemplateAddRequest = async (fields: ISavingSpaceTypeDecorationSet) => {
	const response = await httpRequests.withToken.post<IAddExportTemplateResponse>(
		'export-templates',
		fields
	);
	return response.data;
};
