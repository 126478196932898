import {combineReducers} from 'redux';
import localReports from '../../../reducers/ui/localReports';
// @ts-ignore
import plan from '../../../reducers/ui/planUIReducer';
// @ts-ignore
import profile from '../../../reducers/ui/profileUIReducer';

export default combineReducers({
	localReports,
	plan,
	profile
});
