import {IBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {Dispatch} from 'react';
import {
	convertToSave,
	IEditableBankAccountAction,
	IEditableBankAccountState
} from '@src/core/hooks/states/useEditableBankAccountState';
import {useAddBankAccount} from '@src/core/hooks/mutations/bankAccounts/useAddBankAccount';
import {useDeleteBankAccount} from '@src/core/hooks/mutations/bankAccounts/useDeleteBankAccount';
import {useEditBankAccount} from '@src/core/hooks/mutations/bankAccounts/useEditBankAccount';

interface IUseUpdateContentProps {
	editingState?: IEditableBankAccountState;
	editingDispatch?: Dispatch<IEditableBankAccountAction>;
	bankAccount?: IBankAccount;
	legalEntityId?: string;
	companyId?: string;
}

interface IUseUpdateContent {
	updateBankAccount: () => Promise<IBankAccount | undefined> | undefined;
	removeBankAccount: (id: string) => void;
}

export const useUpdateContent = (props: IUseUpdateContentProps): IUseUpdateContent => {
	const {editingState, editingDispatch, bankAccount, legalEntityId, companyId} = props;
	const addBankAccount = useAddBankAccount();
	const editBankAccount = useEditBankAccount();
	const deleteBankAccount = useDeleteBankAccount();

	const checkErrors = (
		state: IEditableBankAccountState,
		dispatch: Dispatch<IEditableBankAccountAction>
	) => {
		const {bankName, accountNumber, bik, correspondentAccountNumber, errors} = state;
		let result = false;

		if (!bankName) {
			dispatch({type: 'update-error', field: 'bankName'});
			result = true;
		}
		if (!accountNumber) {
			dispatch({type: 'update-error', field: 'accountNumber'});
			result = true;
		}
		if (!bik) {
			dispatch({type: 'update-error', field: 'bik'});
			result = true;
		}
		if (!correspondentAccountNumber) {
			dispatch({type: 'update-error', field: 'correspondentAccountNumber'});
			result = true;
		}

		if (errors) {
			for (const error of Object.values(errors)) {
				result = error ? true : result;
			}
		}

		return result;
	};

	const updateBankAccount = () => {
		if (!editingState || !editingDispatch || checkErrors(editingState, editingDispatch)) {
			return undefined;
		}
		const fields = convertToSave(editingState, bankAccount);

		if (bankAccount) {
			return editBankAccount({
				bankAccountId: bankAccount.id,
				fields: {
					...fields,
					legalEntityId: bankAccount.legalEntityId,
					companyId: bankAccount.companyId
				}
			});
		}

		return addBankAccount({
			fields: {
				...fields,
				legalEntityId,
				companyId
			}
		});
	};

	const removeBankAccount = (id: string) => deleteBankAccount(id);

	return {updateBankAccount, removeBankAccount};
};
