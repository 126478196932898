import {Column} from 'react-table';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';
import CheckListInfoMobileCell from '@src/components/tableCells/CheckListInfoMobileCell';

export const mobileColumns: Array<Column<IPreparedCheckList>> = [
	{
		id: 'info',
		Cell: CheckListInfoMobileCell,
		className: 'spaces-page__mobile-cell'
	}
];
