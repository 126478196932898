import {format, Locale} from 'date-fns';
import {ru} from 'date-fns/locale';

interface IEntityInfoDateProps {
	value?: Date | number;
	locale?: Locale;
	labelFrom?: string;
}

export const EntityInfoDate = ({value, locale = ru, labelFrom = 'от'}: IEntityInfoDateProps) =>
	value ? <>{format(value, `'${labelFrom}' dd MMMM yyyy`, {locale})}</> : null;
