import {memo} from 'react';
import DesktopCommentsHeader from './CommentsHeader.desktop';
import {Comments} from '@src/components/Comments';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

interface IDesktopCommentsProps {
	objectId: string;
	commentTypeId: ICommentTypeId;
	onImageClick?: (id: string, index: number) => void;
}

const DesktopComments = ({
	objectId,
	commentTypeId,
	onImageClick
}: IDesktopCommentsProps) => (
	<div className="comments__d-comments">
		<DesktopCommentsHeader/>

		<Comments
			className={{
				root: 'comments__d-comments-body',
				content: 'comments__d-comments-content',
				item: 'comments__d-comment',
				footer: 'comments__d-comments-footer',
				replyBtn: 'comments__d-comments-reply-btn',
				reference: 'comments__d-comments-reference',
				input: 'comments__d-comments-input'
			}}
			objectId={objectId}
			commentTypeId={commentTypeId}
			onImageClick={onImageClick}
		/>
	</div>
);
export default memo(DesktopComments);
