import {useMemo} from 'react';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {getRenderMenu} from '../utils/getRenderMenu';
import {IStatus} from '../utils/IStatus';

interface IUseStatusMenu<S extends IStatus> {
	selectedStatus?: S;
	statuses?: INormalizedData<S>;
	availableStatuses?: string[];
	onChange?: (value: string) => void;
}

export const useStatusMenu = <S extends IStatus>({
	selectedStatus,
	statuses,
	availableStatuses,
	onChange
}: IUseStatusMenu<S>) =>
	useMemo(
		() =>
			getRenderMenu<S>({
				selectedStatus,
				statuses,
				availableStatuses,
				onChange
			}),
		[selectedStatus, statuses, availableStatuses, onChange]
	);
