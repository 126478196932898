import './TagsChange.less';
import classNames from 'classnames';
import {IChangeComponentProps} from '../interfaces/IChangeComponent';
import {Tag} from '../../tags/Tag';

interface ITagChangeItem {
	name: string;
	color: string;
}

interface ITagsChangeProps extends IChangeComponentProps {
	value: ITagChangeItem[];
}

export const TagsChange = ({className, style, value}: ITagsChangeProps) => (
	<div
		className={classNames('history-tags-change', className)}
		style={style}
	>
		{value.map((item, index) => (
			<Tag
				key={index}
				label={item.name}
				dotColor={item.color}
				size="small"
			/>
))}
	</div>
);

TagsChange.displayName = 'TagsChange';
