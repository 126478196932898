import {IAddingProblemRefProps} from '@src/components/AddingProblem/AddingProblem';
import {RefObject, MutableRefObject} from 'react';

interface ISaveProblemProps {
	addingEntityRef: RefObject<IAddingProblemRefProps>;
	success: MutableRefObject<boolean>;
	onClose: () => void;
}

export const saveProblem = async ({addingEntityRef, success, onClose}: ISaveProblemProps) => {
	if (addingEntityRef.current) {
		const savingData = await addingEntityRef.current.getSavingData(true);
		if (!savingData) {
			return;
		}
		if (addingEntityRef.current.saveCache) {
			const cachedData = await addingEntityRef.current.saveCache(savingData);
			if (cachedData) {
				await addingEntityRef.current.save(savingData, undefined, cachedData.cacheKey);
			}
		}
	}
	success.current = true;
	onClose();
};
