import {cloneElement, useCallback, useState, CSSProperties, ReactElement, ReactNode} from 'react';
import './SelectDialog.less';
import classNames from 'classnames';
import {RefCallback} from 'react-laag/dist/types.d';
import useToggle from 'react-use/lib/useToggle';
import {convertClassNames} from '../../../../utils/convertClassNames';
import {ActionButtons} from '../../../buttons';
import Button from '../../../buttons/Button';
import {useUpdateEffect} from 'react-use';
import {IDialogProps, ModalDialog} from '../../../dialogs/ModalDialog';

export interface ISelectDialogTriggerElProps {
	isOpen?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	ref?: RefCallback;
}

export interface ISelectDialogTriggerFnProps {
	isOpen?: boolean;
	disabled?: boolean;
	open?: () => void;
	close?: () => void;
	toggle?: () => void;
	ref?: RefCallback;
}

export interface ISelectDialogProps {
	className?: IDialogProps['className'];
	style?: CSSProperties;
	trigger:
		| ReactElement<ISelectDialogTriggerElProps>
		| ((props: ISelectDialogTriggerFnProps) => ReactNode);
	children?: ReactElement;
	title?: string;
	acceptBtnLabel?: string;
	rejectBtnLabel?: string;
	dialogProps?: IDialogProps;
	value?: string | string[];
	dataTestId?: string;

	onChange?: (value: string | string[] | undefined) => void;
	onClose?: () => void;
}

const SelectDialog = ({
	className,
	style,
	trigger,
	children,
	title,
	acceptBtnLabel = 'Подтвердить',
	rejectBtnLabel = 'Отменить',
	dialogProps,
	value: propsValue,
	dataTestId,
	onChange,
	onClose
}: ISelectDialogProps) => {
	const [value, setValue] = useState(propsValue);
	useUpdateEffect(() => {
		setValue(propsValue);
	}, [propsValue]);
	const handleChange = useCallback((v: string | string[] | undefined) => {
		setValue(v);
	}, []);

	const [isOpen, toggleOpen] = useToggle(false);

	const open = useCallback(() => {
		toggleOpen(true);
	}, [toggleOpen]);

	const close = useCallback(() => {
		toggleOpen(false);

		if (onClose) {
			onClose();
		}
	}, [toggleOpen, onClose]);

	const handleOkClick = useCallback(() => {
		close();
		if (onChange) {
			setTimeout(() => onChange(value), 300);
		}
	}, [value, onChange, close]);

	const handleCancelClick = useCallback(() => {
		close();
		setTimeout(() => setValue(propsValue), 300);
	}, [propsValue, close]);

	const classes = convertClassNames(className);
	classes.root = classNames('select-dialog', classes.root);
	classes.layer = classNames('select-dialog__layer', classes.layer);
	classes.body = classNames('select-dialog__body', classes.body);
	classes.content = 'select-dialog__content';

	return children ? (
		<>
			{typeof trigger === 'function'
				? trigger({
						isOpen,
						open,
						close,
						toggle: toggleOpen
				  })
				: cloneElement(trigger, {
						isOpen,
						onClick: toggleOpen
				  })}

			<ModalDialog
				dataTestId={dataTestId}
				fullScreenOnMobile
				{...dialogProps}
				className={classes}
				style={style}
				open={isOpen}
				title={title}
				footer={
					<ActionButtons>
						<Button type="cancel" label={rejectBtnLabel} onClick={handleCancelClick} />
						<Button type="accent-blue" label={acceptBtnLabel} onClick={handleOkClick} />
					</ActionButtons>
				}
				onRequestClose={handleCancelClick}
			>
				{cloneElement(children, {value, onChange: handleChange})}
			</ModalDialog>
		</>
	) : (
		<>
			{typeof trigger === 'function'
				? trigger({disabled: true})
				: cloneElement(trigger, {disabled: true})}
		</>
	);
};

SelectDialog.displayName = 'SelectDialog';

export default SelectDialog;
