import ContentLoader from 'react-content-loader';

export const SchemaLoader = () => (
	<ContentLoader
		speed={2}
		width={350}
		height={700}
		viewBox="0 0 350 700"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="24" y="12" rx="10" ry="10" width="326" height="683" />
	</ContentLoader>
);
