import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import useAppSelector from '@src/core/hooks/useAppSelector';

export interface IPermissions {
	canViewCheck?: boolean;
	canViewInspection?: boolean;
	canViewProblem?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param objectId id объекта
 */
export const usePermissions = (objectId: string): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canViewCheck: hasPermission(
				user.roles,
				'checksView',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewInspection: hasPermission(
				user.roles,
				'inspectionsView',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewProblem: hasPermission(
				user.roles,
				'problemsView',
				UserRoleScopes.OBJECT,
				objectId
			)
		};
	}, [objectId, user]);
};
