import {ReactElement, cloneElement, useCallback, ReactNode} from 'react';
import Popup, {IPopupBasicProps} from '../../containers/Popup';
import './PopupMenu.less';
import {IMenuItemProps, IMenuComponentProps} from '../MenuItem';
import classNames from 'classnames';
import useToggle from 'react-use/lib/useToggle';
import {RefCallback} from 'react-laag/dist/types.d';
import {useClonedMenu} from '../../../hooks/useClonedMenu';
import {TriggerProps} from 'react-laag/dist/useLayer.d';
import {DisappearType} from 'react-laag';

export interface IPopupMenuTriggerElProps {
	[key: string]: unknown;

	isOpen?: boolean;
	disabled?: boolean;

	onClick?: () => void;
}

export interface IPopupMenuTriggerFnProps {
	isOpen?: boolean;
	open?: () => void;
	close?: () => void;
	toggle?: () => void;
	ref?: RefCallback;
	disabled?: boolean;
}

export interface IPopupMenuProps<V, M extends IMenuComponentProps<V>> {
	className?: string;
	trigger:
		| ReactElement<IPopupMenuTriggerElProps>
		| ((props: IPopupMenuTriggerFnProps) => ReactNode);
	children?: ReactElement<M> | Array<ReactElement<IMenuItemProps>>;
	arrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
}

const PopupMenu = <V, M extends IMenuComponentProps<V>>(props: IPopupMenuProps<V, M>) => {
	const {className, trigger, children, arrowVisible, popupProps} = props;

	const [isOpen, toggleOpen] = useToggle(false);
	const open = useCallback(() => {
		toggleOpen(true);
	}, [toggleOpen]);

	const close = useCallback(() => {
		toggleOpen(false);
	}, [toggleOpen]);

	const onDisappear = useCallback(
		(type: DisappearType) => {
			if (type === 'full') {
				toggleOpen(false);
			}
		},
		[toggleOpen]
	);

	// Клонирование children для отложенного запуска обработчиков onClick и onChange у MenuItem и Menu.
	// Без данного действия анимация закрытия Popup'а происходит с подлагиванием
	const clonedChildren = useClonedMenu(children, close, 160);

	const triggerElement = (triggerProps: TriggerProps) =>
		typeof trigger === 'function'
			? trigger({
					...triggerProps,
					isOpen,
					open,
					close,
					toggle: toggleOpen
			  })
			: cloneElement(trigger, {
					isOpen,
					onClick: toggleOpen,
					ref: triggerProps.ref
			  });

	return clonedChildren ? (
		<Popup
			{...popupProps}
			className={classNames('popup-menu', className)}
			trigger={triggerElement}
			isOpen={isOpen}
			arrowVisible={arrowVisible}
			onOutsideClick={close}
			onDisappear={onDisappear}
		>
			{clonedChildren}
		</Popup>
	) : (
		<>
			{typeof trigger === 'function'
				? trigger({disabled: true})
				: cloneElement(trigger, {disabled: true})}
		</>
	);
};

PopupMenu.displayName = 'PopupMenu';

export default PopupMenu;
