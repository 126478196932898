import {useMutation} from '@tanstack/react-query';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {makeCheckDeleteRequest} from '@src/api/backend/check';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteCheckActions} from '@src/store/modules/entities/check/actions';
import {addInfoToast} from '@src/utils/toasts';

interface IDeleteCheckParams {
	objectId: string;
	checkId: string;
}

export const useDeleteCheck = () => {
	const dispatch = useAppDispatch();
	return useMutation({
		mutationKey: checksQueryKeys.delete(),
		mutationFn: (params: IDeleteCheckParams) => {
			const {objectId, checkId} = params;
			return makeCheckDeleteRequest(objectId, checkId);
		},
		onSuccess: (_, params: IDeleteCheckParams) => {
			const {objectId, checkId} = params;
			void Promise.all([
				queryClient.invalidateQueries({queryKey: checksQueryKeys.list()}),
				queryClient.invalidateQueries({queryKey: checksQueryKeys.latest()}),
				queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()}),
				queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.list()}),
				queryClient.invalidateQueries({
					queryKey: [...spacesQueryKeys.checks(), objectId]
				})
			]);
			dispatch(deleteCheckActions.success(objectId, checkId, true));
			addInfoToast('Удалено', 'Проверка успешно удалена');
		},
		onError: () => {
			addInfoToast('Ошибка', 'при удалении проверки');
		}
	});
};
