import {IGetCompaniesResponse} from '@src/api/backend/companies';
import {companiesQueryKeys} from '@src/api/cache/companies/keys';
import {useQuery} from '@tanstack/react-query';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractCompaniesAsMap,
	extractCompaniesAsArray,
	extractCompaniesAllById,
	extractCompanies,
	extractMainAndSubCompaniesAsArray,
	extractCompaniesContractorsAsArray,
	extractCompaniesContractors,
	extractCompanyInspectors,
	extractCompanyWithContractorsUsersAsArray,
	extractCompanyById
} from '@src/core/hooks/queries/companies/selectors';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useCallback} from 'react';

export const useCompaniesHook = <T = IGetCompaniesResponse>(
	select?: (data: IGetCompaniesResponse) => T
) =>
	useQuery<IGetCompaniesResponse, IError, T>({
		queryKey: [...companiesQueryKeys.list()],
		meta: {
			error: 'при загрузке списка компаний'
		},
		select
	});

/**
 * Возвращает компании в нормализованном виде
 */
export const useCompanies = () => useCompaniesHook<INormalizedData<ICompany>>(extractCompanies);

/**
 * Возвращает компании ByID
 */
export const useCompaniesById = () => useCompaniesHook(extractCompaniesAllById);

/**
 * Возвращает компании как массив
 */
export const useCompaniesAsArray = () => useCompaniesHook<ICompany[]>(extractCompaniesAsArray);

/**
 * Возвращает главные компании как массив
 */
export const useMainCompaniesAsArray = () =>
	useCompaniesHook<ICompany[]>(data => {
		const {mainCompanies} = extractMainAndSubCompaniesAsArray(data);
		return mainCompanies;
	});

/**
 * Возвращает главные компании и компании подрядчики как два массива
 */
export const useMainAndSubCompaniesAsArray = () =>
	useCompaniesHook(extractMainAndSubCompaniesAsArray);

/**
 * Возвращает подрядчиков в виде массива
 */

export const useCompaniesContractorsAsArray = () =>
	useCompaniesHook(extractCompaniesContractorsAsArray);

/**
 * Возвращает подрядчиков в виде объекта {byId, allIds}
 */
export const useCompaniesContractors = () => useCompaniesHook(extractCompaniesContractors);

/**
 * Возвращает компанию по id
 */
export const useCompany = (id?: string) => {
	const selector = useCallback(
		(data: IGetCompaniesResponse) => extractCompanyById(data, id),
		[id]
	);
	return useCompaniesHook(selector);
};

/**
 * Возвращает компании в виде мапы: new Map(companyId: company)
 */
export const useCompaniesAsMap = () =>
	useCompaniesHook<Map<string, ICompany>>(extractCompaniesAsMap);

/**
 * Возвращает инспекторов/сотрудников компании или компаний
 */
export const useCompanyInspectors = (users?: Record<string, IBriefUser>, objectId?: string) => {
	const {data: object} = useObject(objectId);
	const selector = useCallback(
		(data: IGetCompaniesResponse) => extractCompanyInspectors(data, users, objectId, object),
		[users, objectId, object]
	);
	return useCompaniesHook(selector);
};

/**
 * Возвращает пользователей компании включая подрядчиков
 */
export const useCompanyWithContractorsUsersAsArray = (
	usersMap?: Record<string, IBriefUser>,
	companyId?: string
) => {
	const selector = useCallback(
		(data: IGetCompaniesResponse) =>
			extractCompanyWithContractorsUsersAsArray(data, usersMap, companyId),
		[usersMap, companyId]
	);
	return useCompaniesHook(selector);
};
