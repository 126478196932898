import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {CellProps} from 'react-table';

export const ProblemsPerformersCell = ({
	row: {
		original: {
			data: {performers, performersActiveGroup}
		}
	}
}: CellProps<IProblemEntity>) => (
	<div className="problems-page__d-table-performers">
		{performersActiveGroup && (
			<div className="problems-page__d-table-performers-group">{performersActiveGroup}</div>
		)}
		{performers?.[0] && (
			<div className="problems-page__d-table-performers-user">
				<div className="problems-page__d-table-performers-user-name">
					{performers[0]?.displayName}
				</div>
				<div className="problems-page__d-table-performers-user-company">
					{performers[0].companies?.[0]?.name}
				</div>
			</div>
		)}
		{!!performers?.length && performers?.length > 1 && (
			<div className="problems-page__d-table-performers-rest">+{performers.length - 1}</div>
		)}
	</div>
);