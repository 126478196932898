import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import {
	IEditableSpaceStatusesSetAction
} from '@src/core/hooks/states/useSpaceStatusesSetState';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ActionButtons, Button} from '@tehzor/ui-components';
import classNames from 'classnames';
import {useMeasure} from 'react-use';
import {IExtendedSpaceStatus} from '../../interfaces/IExtendedSpaceStatus';
import {StatusAutoTransitionField} from '../fields/StatusAutoTransitionField';
import {NameField} from '../fields/NameField';
import {ColorField} from '../fields/ColorField';
import {StatusTransitionsField} from '../fields/StatusTransitionsField';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IDeleteSpaceStatusResponse} from '@src/api/backend/spaceStatuses/delete';
import {Menu} from '../menu/Menu';
import {addWarningToast} from '@src/utils/toasts';
import {OptionsField} from '../fields/OptionsField';
import {
	NotificationMessageField
} from "@src/components/EditableSpaceStatuses/components/fields/NotificationMessageField/NotificationMessageField";

interface IDesktopEditableSpaceStatusProps {
	status: IExtendedSpaceStatus;
	statuses?: ISpaceStatus[];

	disabled?: boolean;
	isEdited?: boolean;
	setEdited: Dispatch<SetStateAction<string[]>>;

	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;

	updateStatus: (index: number) => Promise<ISpaceStatus | undefined>;
	removeStatus: (id: string) => Promise<IDeleteSpaceStatusResponse | undefined>;
}

export const DesktopEditableSpaceStatus = ({
	status,
	statuses,
	disabled,
	isEdited,
	setEdited,
	editingDispatch,
	updateStatus,
	removeStatus
}: IDesktopEditableSpaceStatusProps) => {
	const [ref, {width}] = useMeasure<HTMLDivElement>();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить статус',
		'Удаление может привести к сбросу статусов помещений у объектов, Вы уверены, что хотите сохранить удалить статус?'
	);

	const isTransfered = useMemo(
		() => (isEdited ? width < 1103 && width !== 0 : true),
		[width, isEdited]
	);

	const handleSetEdit = useCallback(() => {
		if (!isEdited) {
			setEdited(s => [...s, status.id]);
		}
	}, [status.id, isEdited]);

	const handleChangeName = useCallback(
		(name: string) => {
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: {...status, name}
			});
			handleSetEdit();
		},
		[status, handleSetEdit]
	);

	const handleChangeColor = useCallback(
		(color: string) => {
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: {...status, color}
			});
			handleSetEdit();
		},
		[status, handleSetEdit]
	);

	const handleChangeTransitions = useCallback(
		(transitions: string[]) => {
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: {...status, transitions}
			});
			handleSetEdit();
		},
		[status, handleSetEdit]
	);

	const handleChangeAutoTransition = useCallback(
		(autoTransitionTo: string) => {
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: {...status, autoTransitionTo}
			});
			handleSetEdit();
		},
		[status, handleSetEdit]
	);

	const handleChangeDefault = useCallback(() => {
		const hasDefault = !!statuses?.find(val => val.default);

		if (!hasDefault || status.default) {
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: {...status, default: !status.default}
			});
			handleSetEdit();
		} else {
			addWarningToast('Статус по умолчанию может быть только один');
		}
	}, [status, statuses, handleSetEdit]);

	const handleSave = useCallback(async () => {
		const res = await updateStatus(status.index);
		if (res) {
			setEdited(s => s.filter(item => item !== status.id));
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: res
			});
		}
	}, [status, status.id, updateStatus]);

	const handleCancel = useCallback(() => {
		if (status.isLocal) {
			editingDispatch({
				type: 'delete-array-item',
				field: 'statuses',
				index: [status.index]
			});
		} else if (statuses?.[status.index]) {
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: statuses[status.index]
			});
		}
		setEdited(s => s.filter(item => item !== status.id));
	}, [status.isLocal, status.index, status.id, statuses]);

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			const res = await removeStatus(status.id);

			if (res) {
				editingDispatch({
					type: 'delete-array-item',
					field: 'statuses',
					index: [status.index]
				});
			}
		}
	}, [removeStatus, status.id, status.index]);

	const handleChangeUnavailableWithUnfixedProblems = useCallback(() => {
		editingDispatch({
			type: 'update-array-item',
			field: 'statuses',
			index: status.index,
			value: {
				...status,
				unavailableWithUnfixedProblems: !status.unavailableWithUnfixedProblems
			}
		});
		handleSetEdit();
	}, [status, handleSetEdit]);

	const handleChangeUnavailableWithUnacceptedCheckItem = useCallback(() => {
		editingDispatch({
			type: 'update-array-item',
			field: 'statuses',
			index: status.index,
			value: {
				...status,
				unavailableWithUnacceptedCheckItem: !status.unavailableWithUnacceptedCheckItem
			}
		});
		handleSetEdit();
	}, [status, handleSetEdit]);

	return (
		<div
			className={classNames('editable-space-statuses__status-desktop', {
				transfered: isTransfered
			})}
			ref={ref}
		>
			<div className="editable-space-statuses__status-desktop__number">{status.number}.</div>
			<div>
				<div className="editable-space-statuses__status-desktop__container">
					<NameField
						value={status.name}
						disabled={disabled}
						onChange={handleChangeName}
					/>
					<ColorField
						value={status.color}
						disabled={disabled}
						onChange={handleChangeColor}
					/>
					<StatusTransitionsField
						status={status}
						statuses={statuses}
						disabled={disabled}
						onChange={handleChangeTransitions}
					/>
					<StatusAutoTransitionField
						status={status}
						statuses={statuses}
						disabled={disabled}
						onChange={handleChangeAutoTransition}
					/>
					<NotificationMessageField
						status={status}
						editingDispatch={editingDispatch}
						handleSetEdit={handleSetEdit}
					/>

					<div className="editable-space-statuses__status-desktop__actions">
						{!isEdited && !disabled && (
							<>
								<div className="editable-space-statuses__status-desktop__actions-menu">
									<Menu
										statuses={statuses}
										className="editable-space-statuses__status-desktop__menu-btn"
										disabled={disabled}
										isDefault={status.default}
										onDelete={handleDelete}
										onDefault={handleChangeDefault}
									/>
								</div>
								{deleteDialog}
							</>
						)}
					</div>
				</div>
				<div className="editable-space-statuses__status-desktop__options">
					<OptionsField
						checked={status.unavailableWithUnfixedProblems}
						title="Запретить при наличии неустраненных нарушений"
						disabled={disabled}
						onChange={handleChangeUnavailableWithUnfixedProblems}
					/>
					<OptionsField
						title="Запретить при наличии невыполненных категорий в чек-листе"
						checked={status.unavailableWithUnacceptedCheckItem}
						disabled={disabled}
						onChange={handleChangeUnavailableWithUnacceptedCheckItem}
					/>
				</div>
				<div>
					{isEdited && !disabled && (
						<ActionButtons className="editable-space-statuses__status-desktop__actions-btns">
							<Button
								label="Сохранить"
								type="accent-blue"
								onClick={handleSave}
							/>
							<Button
								label="Отменить"
								type="cancel"
								onClick={handleCancel}
							/>
						</ActionButtons>
					)}
				</div>
			</div>
		</div>
	);
};
