import {ILegalsFiltersState} from '@src/store/modules/settings/pages/legals/reducers';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export const handleFilter = (legal: ILegalEntity, filters: ILegalsFiltersState) => {
	const filtersArray = Object.keys(filters || {});
	let result = true;

	for (const filterKey of filtersArray) {
		const {companies, createdAtFrom, createdAtTo, createdBy} = filters;

		switch (filterKey) {
			case 'companies': {
				if (!companies?.length) {
					break;
				}

				result = companies.includes(legal?.companyId || '');
				break;
			}
			case 'createdBy': {
				const id = legal.createdBy?.id || '';
				if (!createdBy?.length) {
					break;
				}

				result = createdBy.includes(id);
				break;
			}
			case 'createdAtTo':
			case 'createdAtFrom': {
				const createdAt = new Date(legal.createdAt || 0);
				createdAt.setHours(0, 0, 0, 0);

				if (!createdAtFrom || !createdAtTo) {
					break;
				}

				result = createdAtFrom <= createdAt && createdAt <= createdAtTo;
				break;
			}
		}
	}

	return result;
};
