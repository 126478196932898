import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useCallback} from 'react';
import {Checkbox} from '../../inputs';
import {UserInfo} from '../../various';
import {DelegationSelectOption} from '../DelegationSelectOption';
import {useTreeCtx} from '../DelegationTree/utils/TreeCtx';

interface IDelegationTreeUserProps {
	user: IBriefUser;
	groupId?: string;
	rules?: string[];
	level: number;
	onlyFirstLevelSelectable?: boolean;
	readMoreHideLabel?: string;
	readMoreShowLabel?: string;
}

export const DelegationTreeUser = ({
	user,
	groupId,
	rules,
	level,
	onlyFirstLevelSelectable,
	readMoreHideLabel,
	readMoreShowLabel
}: IDelegationTreeUserProps) => {
	const {isUserSelected, changeUserSelected} = useTreeCtx();

	const selectable = !onlyFirstLevelSelectable || level === 0;
	const selected = isUserSelected(user.id, groupId);

	const handleSelect = useCallback(() => {
		changeUserSelected(user.id, groupId, !selected);
	}, [selected, user, groupId, changeUserSelected]);

	return (
		<DelegationSelectOption
			rules={rules}
			level={level}
			selectable={selectable}
			selected={selected}
			selectInput={<Checkbox checked={selected} />}
			infoComponent={
				<UserInfo
					className="delegation-select-option__info"
					user={user}
					avatarSize="40"
					avatarColoring="text"
				/>
			}
			readMoreHideLabel={readMoreHideLabel}
			readMoreShowLabel={readMoreShowLabel}
			onClick={handleSelect}
		/>
	);
};

DelegationTreeUser.displayName = 'DelegationTreeUser';
