import {useIsFetching, useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {IGetOwnerAcceptanceResponse} from '@src/api/backend/ownerAcceptance';

export const useOwnerAcceptance = <T = IGetOwnerAcceptanceResponse>(
	objectId?: string,
	acceptanceId?: string,
	select?: (data: IGetOwnerAcceptanceResponse) => T
) =>
	useQuery<IGetOwnerAcceptanceResponse, IError, T>({
		queryKey: ownerAcceptancesQueryKeys.detail(acceptanceId, objectId),
		meta: {
			error: 'при загрузке передачи собственнику'
		},
		select,
		enabled: !!acceptanceId
	});

export const useOwnerAcceptanceIsLoading = () =>
	!!useIsFetching({queryKey: ownerAcceptancesQueryKeys.details()});
