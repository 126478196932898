import {useMemo} from 'react';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';

/**
 * Извлекает из вложений ссылки на изображения
 *
 * @param attachments вложения
 */
export const useAttachmentsImages = (attachments: IAttachment[]) =>
	useMemo(
		() =>
			attachments.reduce<string[]>((prev, item) => {
				if (item.full) {
					prev.push(item.full.url);
				}
				return prev;
			}, []),
		[attachments]
	);
