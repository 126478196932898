import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import { memo } from 'react';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {isBlockVisible} from '../../utils/isBlockVisible';
import classNames from 'classnames';

interface ICompanyBlockProps {
	contract: IPreparedContract;
}

const visibilityFields: Array<keyof IPreparedContract> = [
	'company',
	'companyAuthorizedPerson',
	'companyBankAccount',
	'companyLegalEntity'
];

const companyIcon = <i className="tz-customer-20"/>;
const bankAccountIcon = <i className="tz-bank-account-20"/>;
const legalEntityIcon = <i className="tz-legal-entity-20"/>;
const authorizedPersonIcon = <i className="tz-user-20"/>;

export const CompanyBlock = memo(({
	contract
}: ICompanyBlockProps) => isBlockVisible(contract, visibilityFields) ? (
	<div className="contract__blocks-company">
		<div className="contract__blocks-company__header">
			Заказчик
		</div>

		<EntityGrid
			className={{
				wrap: classNames(
					'contract__blocks-company__container', 'contract__cells'
				)
			}}
			withBorders
		>
			<EntityGridItem
				className={{
					root: 'contract__cells-item',
					header: 'contract__cells-item-header',
					content: 'contract__cells-item-content'
				}}
				label="Заказчик"
				icon={companyIcon}
				inline
			>
				{contract.company?.name}
			</EntityGridItem>

			{contract.companyLegalEntity && (
				<EntityGridItem
					className={{
						root: 'contract__cells-item',
						header: 'contract__cells-item-header',
						content: 'contract__cells-item-content'
					}}
					label="Юр. лицо"
					icon={legalEntityIcon}
					inline
				>
					{contract.companyLegalEntity.name}
				</EntityGridItem>
			)}

			{contract.companyBankAccount && (
				<EntityGridItem
					className={{
						root: 'contract__cells-item',
						header: 'contract__cells-item-header',
						content: 'contract__cells-item-content'
					}}
					label="Реквизиты"
					icon={bankAccountIcon}
					inline
				>
					{contract.companyBankAccount.accountNumber}
				</EntityGridItem>
			)}

			{contract.companyAuthorizedPerson && (
				<EntityGridItem
					className={{
						root: 'contract__cells-item',
						header: 'contract__cells-item-header',
						content: 'contract__cells-item-content'
					}}
					label="Уполномоченное лицо"
					icon={authorizedPersonIcon}
					inline
				>
					{contract.companyAuthorizedPerson.name}
				</EntityGridItem>
			)}
		</EntityGrid>
	</div>
) : null);