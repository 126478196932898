/**
 * Throttle функция, основанная на requestAnimationFrame
 *
 * @param {function} func тормозимая функция
 * @returns {wrapper} функция-обёртка для мноогократного вызова
 */
export function rafThrottle(func: (...args: any[]) => any) {
	let isThrottled = false;
	let savedArgs: any[] | null;
	let savedThis: any | null;

	function wrapper(...args: any[]) {
		if (isThrottled) {
			savedArgs = args;
			// @ts-ignore
			savedThis = this;
			return;
		}

		// @ts-ignore
		func.apply(this, args);
		isThrottled = true;

		requestAnimationFrame(() => {
			isThrottled = false;
			if (savedArgs) {
				wrapper.apply(savedThis, savedArgs);
				savedThis = savedArgs = null;
			}
		});
	}

	return wrapper;
}
