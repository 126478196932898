import { Dispatch, useCallback } from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {useCompanyWithContractorsUsersAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';

interface ICompanySelectProps<S, E> {
	field: keyof S;
	value?: string;
	companyId?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const LeaderSelect = <S, E>({
	className,
	style,
	field,
	label = 'Руководитель',
	value,
	companyId,
	editingDispatch,
	required,
	disabled,
	hasError
}: ICompanySelectProps<S, E>) => {
	const {data: usersMap} = useUsersAsMap();
	const {data: users} = useCompanyWithContractorsUsersAsArray(usersMap, companyId);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[field, required]
	);
	const textFieldValue = value && usersMap ? usersMap[value]?.fullName : undefined;
	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={textFieldValue}
						icon={<i className="tz-simple-arrow-20" />}
						error={required && hasError ? 'Выберите руководителя' : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{users?.map(user => (
						<SelectOption
							key={user.id}
							itemKey={user.id}
							content={user.fullName}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
