import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {requestComments} from '@src/api/backend/comments';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

export type IGetCheckProblemRepliesPayload = INormalizedData<IComment>;

const request = () => ({type: types.GET_PROBLEM_REPLIES_REQUEST});

const success = (response: IGetCheckProblemRepliesPayload) => ({
	type: types.GET_PROBLEM_REPLIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке ответов на нарушения');
	return {
		type: types.GET_PROBLEM_REPLIES_FAILURE,
		payload: error
	};
};

/**
 * Получает список ответов на нарушения для конкретной проверки
 *
 * @param objectId id объекта
 * @param checkId id проверки
 */
export const getCheckProblemReplies = (objectId: string, checkId: string) => checkExpiration<IState, IGetCheckProblemRepliesPayload, ApiAction>(
		s => s.entities.check.problemReplies,
		createApiAction<IGetCheckProblemRepliesPayload>(request, success, failure, () =>
		requestComments(
				{
					commentTypeId: ICommentTypeId.PROBLEM_COMMENT,
					objects: [objectId],
					checks: [checkId],
					official: true
				},
				{createdAt: false},
				0,
				1000
			).then(({byId, allIds}) => ({
				byId,
				allIds
			})))
	);