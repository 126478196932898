import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery} from '@tanstack/react-query';
import {IGetSpaceInternalAcceptancesResponse} from '../interfaces';
import {extractInternalAcceptancesAllIds, extractInternalAcceptancesAsArray} from './selectors';

export const useSpaceInternalAcceptances = <T = IGetSpaceInternalAcceptancesResponse>(
	objectId: string,
	spaceId: string,
	permission = true,
	select?: (data: IGetSpaceInternalAcceptancesResponse) => T
) =>
	useQuery({
		queryKey: spacesQueryKeys.spaceInternalAcceptances(objectId, spaceId),
		meta: {
			error: 'при загрузке внутренних приёмок'
		},
		select,
		enabled: permission
	});

export const useSpaceInternalAcceptancesAsArray = (
	objectId: string,
	spaceId: string,
	permission = true
) => useSpaceInternalAcceptances(objectId, spaceId, permission, extractInternalAcceptancesAsArray);

export const useExtractSpaceInternalAcceptancesAllIds = (
	objectId: string,
	spaceId: string,
	permission = true
) => useSpaceInternalAcceptances(objectId, spaceId, permission, extractInternalAcceptancesAllIds);
