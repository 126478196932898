import {WarrantyClaimPage} from './WarrantyClaimPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

/**
 * Обёртка над страницей гарантийного обращения,
 * необходимо для того чтобы при переходе от одного зявления к другому отрабатывали все методы жизненного цикла
 *
 * @param props свойства компонента
 * @constructor
 */
export const WarrantyClaimPageWrap = () => {
	const {warrantyClaimId} = useStrictParams<{warrantyClaimId: string}>();
	return <WarrantyClaimPage key={warrantyClaimId}/>;
};
