import {useMutation} from '@tanstack/react-query';
import {makeGetExportTemplateRequest} from '@src/api/backend/exportTemplates/getOne';
import saveOrOpenBlob from '@tehzor/tools/utils/saveOrOpenBlob';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';

export const useDownloadExportTemplate = () =>
	useMutation({
		mutationFn: (templateId: string) => makeGetExportTemplateRequest(templateId),
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке шаблона');
		},
		onSuccess: response => {
			saveOrOpenBlob(response.fileContent, response.fileName);
			if (response.warnings?.itemsLimit !== undefined) {
				addWarningToast(
					'Внимание',
					`превышен лимит записей (${String(response.warnings.itemsLimit)})`
				);
			}
		}
	});
