export enum WarrantyClaimStatusId {
	WAITING = 'waiting',
	IN_PROGRESS = 'in-progress',
	DONE = 'done'
}

export interface IWarrantyClaimStatus {
	id: WarrantyClaimStatusId;
	name: string;
	transitions?: WarrantyClaimStatusId[];
	color?: string;
	textColor?: string;
}
