import {memo} from 'react';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from 'react-i18next';

interface IFixDateFilterProps {
	label?: string;
	from?: Date;
	to?: Date;
}

export const PlannedFixDateFilter = memo((props: IFixDateFilterProps) => {
	const {label = 'Срок устранения', from, to} = props;
	const {dispatch} = useEntitiesFiltersCtx();

	const handleChange = (f: Date | null, t: Date | null) => {
		const plannedFixDateFrom = f
			? new Date(f.getFullYear(), f.getMonth(), f.getDate())
			: undefined;
		const plannedFixDateTo = t
			? new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999)
			: undefined;

		dispatch({plannedFixDateFrom, plannedFixDateTo});
	};

	const {t} = useTranslation();

	const handleClear = () => {
		dispatch({plannedFixDateFrom: undefined, plannedFixDateTo: undefined});
	};

	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	return (
		<DateRangePicker
			trigger={triggerProps => (
				<FilterButton
					className="entities-filters__item"
					label={makeDateFilterLabel(label, from, to)}
					active={from !== undefined || to !== undefined}
					onClick={triggerProps.toggle}
					onClear={handleClear}
					ref={triggerProps.ref}
				/>
			)}
			valueFrom={from}
			valueTo={to}
			dateFormat="dd MMMM yyyy"
			datesOptions={datesOptions}
			useApplyButton
			onChange={handleChange}
			selectApplyBtnLabel={t('applyBtn.label')}
			selectCancelBtnLabel={t('cancelBtn.label')}
		/>
	);
});
