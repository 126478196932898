import {wsConnector} from '@src/api/backend/wsConnector';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';

export type IGetWarrantyClaimResponse = ILinkedWarrantyClaim;

/**
 * Отправляет запрос на получение гарантийного обращения
 */
export const requestWarrantyClaim = (warrantyClaimId: string, objectId: string) => {
	const params = {warrantyClaimId, objectId};
	return wsConnector.sendAuthorizedRequest<IGetWarrantyClaimResponse>('getWarrantyClaim', params);
};
