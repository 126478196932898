import {memo} from 'react';
import {format} from 'date-fns';
import {DatePicker, EditableFieldLabel, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

const calendarIcon = <i className="tz-calendar-20" />;

interface IEditableDateFieldProps {
	label?: string;
	value?: Date;
	onChange?: (date: Date) => void;
	changeDateFormat?: string;
	disabled?: boolean;
	className?: string;
}

export const EditableDateField = memo((props: IEditableDateFieldProps) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	const {
		value,
		className,
		label,
		disabled,
		changeDateFormat = dateFormat,
		onChange,
		...restProps
	} = props;
	return (
		<div {...restProps}>
			{label && <EditableFieldLabel>{label}</EditableFieldLabel>}
			<DatePicker
				datesOptions={datesOptions}
				useApplyButton
				value={value}
				onChange={onChange}
				selectCancelBtnLabel={t('cancelBtn.label')}
				selectApplyBtnLabel={t('applyBtn.label')}
				trigger={(triggerProps: IDatePickerTriggerProps) => (
					<TextFieldWithForwardedRef
						elementType="div"
						className={className}
						value={
							triggerProps?.value !== undefined
								? format(triggerProps.value, changeDateFormat)
								: ''
						}
						icon={calendarIcon}
						onClick={triggerProps?.toggle}
						ref={triggerProps?.ref}
						disabled={disabled}
					/>
				)}
			/>
		</div>
	);
});
