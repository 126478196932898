import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface IDocumentsFiltersState extends Record<string, unknown> {
	companyIds?: string[];
	contractorIds?: string[];
	createdBy?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
}

export type IDocumentsSortState = Record<string, boolean>;

export interface IDocumentsPageSettingsState {
	filters: IDocumentsFiltersState;
	sort: IDocumentsSortState;
	pageSize: number;
	offset: number;
	selectedRows: string[];
}

export const getInitialStateForPage = (): IDocumentsPageSettingsState => ({
	filters: {},
	sort: {
		modifiedAt: false
	},
	pageSize: 20,
	offset: 0,
	selectedRows: []
});

export const documentsSlice = createSlice({
	name: 'documents',
	initialState: getInitialStateForPage,
	reducers: {
		changeFilters: (
			state,
			{payload}: PayloadAction<IDocumentsFiltersState>
		) => {
			state.filters = payload;
		},
		clearFilters: (state) => {
			state.filters = {};
		},
		changeSort: (
			state,
			{payload}: PayloadAction<IDocumentsSortState>
		) => {
			state.sort = payload;
		},
		changePageSize: (
			state,
			{payload}: PayloadAction<IDocumentsPageSettingsState['pageSize']>
		) => {
			state.pageSize = payload;
		},
		changeOffset: (
			state,
			{payload}: PayloadAction<IDocumentsPageSettingsState['offset']>
		) => {
			state.offset = payload;
		},
		changeSelectedRows: (
			state,
			{payload}: PayloadAction<IDocumentsPageSettingsState['selectedRows']>
		) => {
			state.selectedRows = payload;
		}
	}
});

export const documentsActions = documentsSlice.actions;
export const documents = documentsSlice.reducer;