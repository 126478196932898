import {useAddingRespUserDialog} from '@src/components/AddingRespUserDialog';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {
	ActionButtons,
	Button,
	DelegationTree,
	ModalDialog,
	IconButton
} from '@tehzor/ui-components';
import {ReactNode, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import './DelegationDialog.less';
import {useDelegationSearch} from './hooks/useDelegationSearch';

interface IDelegationDialogProps {
	activeGroup?: string;
	users?: string[];
	group?: string;
	author?: IBriefUser;

	workingGroups: IEnrichedWorkingGroup[];

	isOpen: boolean;
	onlyInitialLevelSelectable?: boolean;

	expandedGroups: string[];
	onGroupsExpand: (groups: string[]) => void;

	onChange?: (users: string[], group: string | undefined) => void;
	onClose: () => void;

	title?: string;
	viewButtons?: ReactNode;
}

const addUserIcon = <i className="tz-add-user-24" />;

/**
 * Модальное окно делегирования / выбора ответственных
 */
export const DelegationDialog = (props: IDelegationDialogProps) => {
	const {
		activeGroup,
		users,
		group,
		workingGroups,
		author,
		isOpen,
		onlyInitialLevelSelectable,
		expandedGroups,
		onGroupsExpand,
		onChange,
		onClose,
		title,
		viewButtons
	} = props;
	const {t} = useTranslation();

	// Локальные переменные, которые выводятся в Tree
	const [selectedUsers, setSelectedUsers] = useState<string[]>(users || []);
	const [selectedGroup, setSelectedGroup] = useState<string | undefined>(group);

	// Синхронизация локальных переменных с пропсами part1
	useUpdateEffect(() => setSelectedUsers(users || []), [users]);
	useUpdateEffect(() => setSelectedGroup(group), [group]);

	// Поиск по ответственным
	const [filteredWorkingGroups, searchComponent, clearSearchValue] = useDelegationSearch({
		workingGroups,
		selectedUsers: users,
		selectedGroup: group,
		placeholder: t('components.delegationDialog.selectSearch.placeholder'),
		changeExpandedGroups: onGroupsExpand
	});

	// Флаг сброса локального state при отмене/закрытии окна
	const needToReset = useRef<boolean>(false);

	const save = useCallback(() => {
		needToReset.current = false;

		if (onChange) {
			onChange(selectedUsers, selectedGroup);
		}
		clearSearchValue();
		onClose();
	}, [onChange, selectedUsers, selectedGroup, clearSearchValue, onClose]);

	const close = useCallback(() => {
		needToReset.current = true;
		clearSearchValue();
		onClose();
	}, [clearSearchValue, onClose]);

	const reset = useCallback(() => {
		if (needToReset.current) {
			setSelectedUsers(users || []);
			setSelectedGroup(group);
		}
	}, [users, group]);

	const [addingUserDialog, openAddingUserDialog] = useAddingRespUserDialog();

	return (
		<ModalDialog
			dataTestId="DelegationDialog"
			className={{
				root: 'delegation-dialog',
				content: 'delegation-dialog__content',
				body: 'delegation-dialog__body'
			}}
			open={isOpen}
			title={title ?? t('components.delegationDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						onClick={close}
					/>
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={selectedUsers.length === 0 && !selectedGroup}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnMobile
			onRequestClose={close}
			onAfterClose={reset}
		>
			<div className="delegation-dialog__controls">
				{viewButtons}

				<IconButton
					className="delegation-dialog__add-user-button"
					onClick={openAddingUserDialog}
				>
					{addUserIcon}
				</IconButton>
			</div>

			<div className="delegation-dialog__search-controls">{searchComponent}</div>

			<div className="delegation-dialog__tree">
				<DelegationTree
					author={author}
					groups={filteredWorkingGroups}
					activeGroup={activeGroup}
					selectedUsers={selectedUsers}
					selectedGroup={selectedGroup}
					expandedGroups={expandedGroups}
					onlyInitialLevelSelectable={onlyInitialLevelSelectable}
					onUsersSelect={setSelectedUsers}
					onGroupSelect={setSelectedGroup}
					onGroupsExpand={onGroupsExpand}
					readMoreHideLabel={t('components.readmore.hideLabel')}
					readMoreShowLabel={t('components.readmore.showLabel')}
					hideChildrenLabel={t(
						'components.delegationDialog.delegationTree.hideChildrenLabel'
					)}
					showChildrenLabel={t(
						'components.delegationDialog.delegationTree.showChildrenLabel'
					)}
				/>
			</div>

			{addingUserDialog}
		</ModalDialog>
	);
};
