import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {createMutation} from 'react-query-kit';
import {IAddProblemParams, IEditProblemParams} from '../defaults/useProblemsMutationDefaults';
import {problemsQueryKeys} from '../keys';

export const useAddProblemMutation = createMutation<IProblem, IAddProblemParams>({
	mutationKey: problemsQueryKeys.add(),
	meta: {
		entity: 'problem',
		type: 'creation'
	}
});

export const useEditProblemMutation = createMutation<IProblem, IEditProblemParams>({
	mutationKey: problemsQueryKeys.edit(),
	meta: {
		entity: 'problem',
		type: 'editing'
	}
});
