import {editSpaceTypeDecorationSetRequest} from '@src/api/backend/spaceTypeDecorationSets';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export const useEditSpaceTypeDecorationSet = () =>
	useMutation({
		mutationFn: (params: ISpaceTypeDecorationSet) => editSpaceTypeDecorationSetRequest(params),
		mutationKey: spaceTypeDecorationSetsQueryKeys.edit(),
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: spaceTypeDecorationSetsQueryKeys.lists()
			});
			void queryClient.invalidateQueries({
				queryKey: spaceTypeDecorationSetsQueryKeys.one()
			});
		},
		onError: () => addErrorToast('Ошибка', 'при обновлении набора типов отделки')
	});
