import classNames from 'classnames';
import { memo, useCallback } from 'react';
import * as React from 'react';
import './PhotoFeed.less';
import {convertClassNames} from '../../../utils/convertClassNames';
import TouchablePanel from '../../containers/TouchablePanel';
import PhotoPreview from '../PhotoPreview';

interface IPhotoFeedProps {
	className?: string | {
		root?: string;
		photo?: string;
	};
	style?: React.CSSProperties;
	data: string[];

	onClick?: (index: number) => void;
}

const PhotoFeed = ({className, style, data, onClick}: IPhotoFeedProps) => {
	const classes = convertClassNames(className);

	const preventDrag = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		return false;
	}, []);

	return (
		<TouchablePanel
			className={classNames('photo-feed', classes.root)}
			style={style}
		>
			<div
				className="photo-feed__wrapper"
				onDragStart={preventDrag}
			>
				{data.map((url, index) => (
					<PhotoPreview
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className={classNames('photo-feed__photo', classes.photo)}
						url={url}
						data={index}
						onClick={onClick}
					/>
				))}
			</div>
		</TouchablePanel>
	);
};

export default memo(PhotoFeed);