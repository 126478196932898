import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetMetersResponse = INormalizedData<IMeter>;

/**
 * Возвращает список приборов учёта по id помещения
 *
 * @param spaceId id помещения
 */
export const requestMeters = async (spaceId: string) => {
	const params = {
		spaceId
	};
	const response = await httpRequests.withToken.get<IGetMetersResponse>('meters', {params});
	return response.data;
};
