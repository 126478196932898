import {useMutation} from '@tanstack/react-query';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {makeTaskDeleteRequest} from '@src/api/backend/task';
import {addErrorToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';
import {workAcceptancesTasksDataQueryKeys} from '@src/api/cache/workAcceptancesTasksData/keys';

interface IDeleteTaskParams {
	taskId: string;
	objectId?: string;
	workAcceptanceId?: string;
}
export const useDeleteTask = () =>
	useMutation({
		mutationKey: tasksQueryKeys.delete(),
		mutationFn: (params: IDeleteTaskParams) => {
			const {taskId} = params;
			return makeTaskDeleteRequest(taskId);
		},
		onError: () => addErrorToast('Ошибка', 'при удалении задачи'),
		onSuccess: (_, variables) => {
			const {objectId, workAcceptanceId} = variables;
			if (objectId && workAcceptanceId) {
				void queryClient.refetchQueries({
					queryKey: workAcceptancesTasksDataQueryKeys.data({
						workAcceptances: [
							{
								objectId,
								workAcceptanceId
							}
						]
					})
				});
			}
			void queryClient.invalidateQueries({queryKey: tasksQueryKeys.all()});
		}
	});
