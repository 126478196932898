import {useMemo} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import {CellProps} from 'react-table';
import {checkIsCurrent} from '@src/pages/ObjectPage/utils/checkIsCurrent';
import {useTranslatedObjectStagesMap} from '@src/core/hooks/translations/useTranslatedObjectStagesMap';

export const ObjectStagesCell = ({row}: CellProps<IParentObject>) => {
	const stages = row.original.stages;
	const stagesMap = useTranslatedObjectStagesMap();

	const mainStage = stagesMap && stagesMap[row.original.stage]?.name;

	const text = useMemo(() => {
		if (!stages) {
			return undefined;
		}

		const array = [];

		for (const key of Object.keys(stages)) {
			if (
				checkIsCurrent(
					stages[key as ObjectStageIds]?.from,
					stages[key as ObjectStageIds]?.to
				)
			) {
				array.push(stagesMap && stagesMap[key]?.name);
			}
		}
		return array.length ? array.join(', ') : undefined;
	}, [stages, stagesMap]);

	return <div>{text || mainStage}</div>;
};
