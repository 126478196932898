import {ISavingTask} from '@src/interfaces/saving/ISavingTask';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditTaskResponse = ITask;

/**
 * Изменяет рабочую группу
 *
 * @param taskId id рабочей группы
 * @param fields данные рабочей группы
 */

export interface IMakeTaskEditRequestData {
	taskId: string;
	fields: ISavingTask
}
export const makeTaskEditRequest = async (data: IMakeTaskEditRequestData) => {
	const response = await httpRequests.withToken.patch<IEditTaskResponse>('tasks', {
		taskId: data.taskId,
		...data.fields
	});
	return response.data;
};
