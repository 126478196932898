import {combineReducers} from '@reduxjs/toolkit';
import sources from './sources';
import building from './building';
import acceptance from './acceptance';
import warranty from './warranty';

export default combineReducers({
	sources,
	building,
	acceptance,
	warranty
});
