import {ReactNode} from 'react';

/**
 * Возвращает контент в зависимости от активной вкладки
 *
 * @param index индекс активной вкладки
 * @param children контент всех вкладок
 */
export function getContent(index: number, children?: ReactNode) {
	if (children) {
		if (Array.isArray(children) && index >= 0 && index < children.length) {
			return children[index];
		}
		if (index === 0) {
			return children;
		}
	}
	return null;
}
