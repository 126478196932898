import {MobileWorkAcceptanceCellWithoutObject} from './MobileWorkAcceptanceCellWithoutObject';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {Column} from 'react-table';

export const mobileColumns: Array<Column<IPreparedWorkAcceptance>> = [
	{
		id: 'object',
		Cell: MobileWorkAcceptanceCellWithoutObject,
		className: 'work-acceptances-page__m-table__cell'
	}
];
