import {useCallback} from 'react';
import {FloatingActionButton, IconButton} from '@tehzor/ui-components';
import {useAddingInternalAcceptanceDialog} from '@src/components/AddingInternalAcceptanceDialog/hooks/useAddingInternalAcceptanceDialog';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {useSpaceLocationForEntities} from '@src/core/hooks/spaces/useSpaceLocationForEntities';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useTranslation} from 'react-i18next';

interface IEntityAddingProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
}

const mobileAddIcon = <i className="tz-plus-16" />;
const desktopAddIcon = <i className="tz-plus-20" />;

const EntityAdding = ({objectId, internalAcceptance}: IEntityAddingProps) => {
	const {data: object} = useObject(objectId);
	const {t} = useTranslation();

	const defaultData = useSpaceLocationForEntities(objectId, internalAcceptance.links?.spaceId);
	const [dialog, open] = useAddingInternalAcceptanceDialog({
		objectId,
		internalAcceptanceId: internalAcceptance.id,
		links: internalAcceptance.links,
		stage: object?.stage,
		defaultProblemData: defaultData,
		defaultInspectionData: defaultData,
		createdBy: internalAcceptance.createdBy?.id
	});

	const handleClick = useCallback(() => {
		if (internalAcceptance.links?.spaceId) {
			void queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.detail(internalAcceptance.links.spaceId)
			});
		}
		open();
	}, [internalAcceptance, open]);

	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return problemsPermissions.canAdd || inspectionsPermissions.canAdd ? (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={mobileAddIcon}
					label={t('addButton.label')}
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleClick}
				/>
			</BeforeDesktop>
			<Desktop>
				<IconButton
					type="accent-blue"
					onClick={handleClick}
				>
					{desktopAddIcon}
				</IconButton>
			</Desktop>
			{dialog}
		</>
	) : null;
};

export default EntityAdding;
