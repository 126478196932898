import {makeObjectEditRequest} from '@src/api/backend/object';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {queryClient} from '@src/api/QueryClient';
import { ISavingObject } from '@src/interfaces/saving/ISavingObject';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';

interface IEditObjectRequestData {
	objectId: string;
	object: ISavingObject;
}

const mutationFn = ({objectId, object}: IEditObjectRequestData) =>
	makeObjectEditRequest(objectId, object);

const onSuccess = () => {
	void queryClient.invalidateQueries({queryKey: objectsQueryKeys.list()});
};

const onError = () => addErrorToast('Ошибка', 'при изменении объекта');

export const useEditObject = () =>
	useMutation({
		mutationFn,
		mutationKey: objectsQueryKeys.edit(),
		onSuccess,
		onError
	});
