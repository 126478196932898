import {IDeleteSpaceStatusResponse} from '@src/api/backend/spaceStatuses/delete';
import {
	IEditableSpaceStatusesSetAction,
	IEditableSpaceStatusesSetState
} from '@src/core/hooks/states/useSpaceStatusesSetState';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISpaceStatusOrder} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatusOrder';
import {DndContext} from '@tehzor/ui-components';
import { Dispatch } from 'react';
import './EditableSpaceStatuses.less';
import {StageStatuses} from './components/StageStatuses';
import {renderPreview} from './utils/renderPreview';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';

interface IEditableSpaceStatusesProps {
	statuses?: ISpaceStatus[];
	canModify?: boolean;
	spaceStatusesSetId: string;

	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;
	editingState: IEditableSpaceStatusesSetState;

	updateStatus: (index: number) => Promise<ISpaceStatus | undefined>;
	updateStatusesOrder: (
		order: ISpaceStatusOrder[]
	) => Promise<INormalizedData<ISpaceStatus> | undefined>;
	removeStatus: (id: string) => Promise<IDeleteSpaceStatusResponse | undefined>;
}

export const EditableSpaceStatuses = ({
	statuses,
	canModify,
	spaceStatusesSetId,
	editingDispatch,
	editingState,
	updateStatus,
	updateStatusesOrder,
	removeStatus
}: IEditableSpaceStatusesProps) => {
	const {data: stages} = useObjectStagesAsArray();
	if (!stages) return null;
	return (
		<DndContext renderPreview={renderPreview}>
			<div className="editable-space-statuses">
				{!editingState.isLocal && statuses?.length
					? stages.map(stage => (
							<StageStatuses
								key={stage.id}
								statuses={statuses}
								spaceStatusesSetId={spaceStatusesSetId}
								stage={stage}
								editingDispatch={editingDispatch}
								editingState={editingState}
								disabled={!canModify}
								updateStatus={updateStatus}
								updateStatusesOrder={updateStatusesOrder}
								removeStatus={removeStatus}
							/>
					  ))
					: 'Нет статусов'}
			</div>
		</DndContext>
	);
};
