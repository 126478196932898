import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useLegalsPermissions} from '@src/core/hooks/permissions/useLegalsPermissions';
import {useLegalDelete} from '@src/pages/LegalPage/hooks/useLegalDelete';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useAddingLegalDialog} from '@src/components/AddingLegalDialog';

interface IDesktopMenuProps {
	legal?: ILegalEntity;
}

const deleteIcon = <i className="tz-delete" />;
const editIcon = <i className="tz-edit" />;

export const LegalActions = ({legal}: IDesktopMenuProps) => {
	const permissions = useLegalsPermissions();
	const updateAppData = useAppUpdateMenuItem();
	const [deleteDialog, handleDeleteClick] = useLegalDelete(legal?.id);
	const [legalDialog, handleOpenLegalDialog] = useAddingLegalDialog({legal});
	const items = [];

	if (updateAppData) {
		items.push(updateAppData);
	}

	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={handleOpenLegalDialog}
			>
				Редактировать
			</MenuItem>
		);
	}

	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
			{permissions.canEdit && legalDialog}
		</>
	) : null;
};
