import { useCallback } from 'react';
import './OfflineModeBar.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineStatus} from '@src/store/modules/offlineMode/selectors';
import {getOfflineModeTitle} from '@src/core/offlineMode/utils/offlineModeTitles';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleOfflineDialogVisibility} from '@src/store/modules/app/offlineDialog/actions';
import {cacheStatus} from './utils/cacheStatus';
import {useTranslation} from "react-i18next";

export const OfflineModeBar = () => {
	const {t} = useTranslation();
	const status = useAppSelector(extractOfflineStatus);
	const queriesFetchStatus = cacheStatus();
	const dispatch = useAppDispatch();

	const openDialog = useCallback(() => {
		dispatch(toggleOfflineDialogVisibility(true));
	}, []);

	return (
		<>
			<div
				className="offline-bar"
				onClick={openDialog}
			>
				{getOfflineModeTitle(queriesFetchStatus, t) || getOfflineModeTitle(status, t)}
			</div>
		</>
	);
};
