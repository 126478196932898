import {requestProblem} from '@src/api/backend/problem';
import {requestPaginateProblems} from '@src/api/backend/problems';
import {ISavingProblemQuery} from '@src/core/hooks/mutations/problem/useAddProblem';
import {toListProblem} from '@src/core/hooks/mutations/problem/utils/convertToLocalSave';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {Query, QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IProblemsListQueryKey, problemsQueryKeys} from '../keys';
import {requestComments} from '@src/api/backend/comments';

export const useProblemsQueryDefaults = () => {
	const queryClient = useQueryClient();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	queryClient.setQueryDefaults(problemsQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IProblemsListQueryKey>) => {
			const [, , filters, sort, offset, pageSize] = queryKey;
			return requestPaginateProblems(filters, sort, offset, pageSize);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(problemsQueryKeys.comments(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IProblemsListQueryKey>) => {
			const [, , filters, sort, offset, pageSize] = queryKey;
			return requestComments(filters, sort, offset, pageSize);
		}
	});
	queryClient.setQueryDefaults(problemsQueryKeys.details(), {
		queryFn: async ({queryKey}) => requestProblem(queryKey[3] as string, queryKey[2] as string),
		meta: {
			persist: isOfflineModeAvailable
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 5 // 5 Дней
	});

	queryClient.setQueryDefaults(problemsQueryKeys.localList(), {
		queryFn: ({queryKey}) => {
			const [, , objectId] = queryKey;
			const queryMatch = queryClient.getQueriesData<ISavingProblemQuery>({
				queryKey: problemsQueryKeys.savingDatas(),
				predicate: (query: Query<ISavingProblemQuery>) => {
					const {data} = query.state;
					if (objectId === data?.object.id || !objectId || objectId === 'all') {
						return true;
					}
					return false;
				}
			});
			const savingDatas = queryMatch.map(([, problem]) => problem);
			const problems: IListProblem[] = [];
			for (const savingData of savingDatas) {
				if (savingData) {
					const {object, links, stage, key, fields, transferStatus, number} = savingData;
					problems.push({
						...toListProblem(object, links, stage, fields, key, number),
						transferStatus
					});
				}
			}
			return problems;
		},
		staleTime: 0,
		gcTime: 0,
		networkMode: 'offlineFirst'
	});
};
