import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export const getInspectors = (users?: IBriefUser[], companies?: ICompany[]) => {
	if (!users || !companies) return [];
	const inspectors = companies.reduce<string[]>((prev, item) => {
		if (item.employees) {
			return prev.concat(item.employees.map(e => e.userId));
		}
		return prev;
	}, []);
	const inspectorsSet = new Set(inspectors);
	return users.filter(user => inspectorsSet.has(user.id));
};
