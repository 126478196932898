import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

interface IExportTemplatePermissions {
	canEdit?: boolean;
}

export const useExportTemplatePermissions = (): IExportTemplatePermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canEdit: hasPermission(
				user.roles,
				'export-templates-edit',
				UserRoleScopes.ALL
			)
		};
	}, [user]);
};
