import {memo} from 'react';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {Image} from '../Image';
import {ObjectStages} from './ObjectStages';
import {ObjectManager} from './ObjectManager';
import {ObjectProblems} from './ObjectProblems';
import {useTranslation} from 'react-i18next';

interface IMobileInfoProps {
	object: IObject;
	stages: IObjectStage[];
}

const nameIcon = <i className="tz-name-20" />;
const photoIcon = <i className="tz-photo-20" />;
const cityIcon = <i className="tz-location-20" />;
const contractorIcon = <i className="tz-company-20" />;

export const MobileInfo = memo(({object, stages}: IMobileInfoProps) => {
	const {t} = useTranslation();
	return (
		<>
			<EntityGrid className={{wrap: 'object-page__d-entities-grid'}}>
				<EntityGridItem
					label={t('objectPage.info.name.label')}
					icon={nameIcon}
				>
					{object.name}
				</EntityGridItem>

				<EntityGridItem
					className={{content: 'object-page__d-entities-grid-image'}}
					label={t('objectPage.info.photo.label')}
					icon={photoIcon}
				>
					<Image
						title={object.name}
						fullImage={object.fullImage?.url}
						previewImage={object.previewImage?.url}
					/>
				</EntityGridItem>

				{object.city && (
					<EntityGridItem
						label={t('objectPage.info.city.label')}
						icon={cityIcon}
					>
						{object.city}
					</EntityGridItem>
				)}

				{object.generalContractor && (
					<EntityGridItem
						label={t('objectPage.info.generalContractor.label')}
						icon={contractorIcon}
					>
						{object.generalContractor}
					</EntityGridItem>
				)}

				<ObjectManager
					label={t('objectPage.info.projectManager.label')}
					value={object.projectManager}
				/>

				<ObjectManager
					label={t('objectPage.info.constructionManager.label')}
					value={object.constructionManager}
				/>
			</EntityGrid>

			<ObjectStages
				object={object}
				stages={stages}
			/>

			<ObjectProblems object={object} />
		</>
	);
});
