import {useCallback} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Toggle} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/actions';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from "react-i18next";

export const OfflineModeSettings = () => {
	const {t} = useTranslation();

	const {available} = useAppSelector(s => s.settings.offlineMode);
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const handleChange = useCallback(() => {
		dispatch(changeOfflineModeAvailability(!available));
		if (available) {
			queryClient.removeQueries({predicate: data => data.meta?.persist === true});
		}
		if (!available) {
			queryClient.removeQueries({predicate: data => data.meta?.persist === false});
		}
	}, [available, queryClient, dispatch]);

	return (
		<div>
			<Toggle
				checked={available}
				onChange={handleChange}
			>
				{t('profilePage.settings.offline')}
			</Toggle>
		</div>
	);
};
