import {ISavingTask} from '@src/interfaces/saving/ISavingTask';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddTaskResponse = ITask;

/**
 * Добавляет задачу
 *
 * @param fields данные задачи
 */
export const makeTaskAddRequest = async (fields: ISavingTask) => {
	const response = await httpRequests.withToken.post<IAddTaskResponse>('tasks', fields);
	return response.data;
};
