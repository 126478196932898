import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {IGetInternalAcceptanceResponse} from '@src/api/backend/internalAcceptance';
import IError from '@tehzor/tools/interfaces/IError';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';

export const useFetchLatestInternalAcceptance = (
	internalAcceptanceId: string,
	objectId: string,
	options?: Partial<UseQueryOptions<IGetInternalAcceptanceResponse, IError>>
) =>
	useQuery<IGetInternalAcceptanceResponse, IError>({
		queryKey: [...internalAcceptancesQueryKeys.detail(internalAcceptanceId), objectId],
		staleTime: Infinity,
		meta: {
			error: 'при загрузке последней внутренней приёмки'
		},
		...options
	});
