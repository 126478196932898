import * as React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IOnlyActivatedProps {
	children: React.ReactNode;
}

export const OnlyActivated = ({children}: IOnlyActivatedProps) => {
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const isActivated = useAppSelector(s => s.auth.profile.activated);

	return isAuthenticated && isActivated ? <>{children}</> : null;
};
