import {differenceInDays, isBefore} from 'date-fns';
import classNames from 'classnames';

export const FixDateIcon = ({value}: {value: number}) => {
	const nowDate = new Date();
	if (differenceInDays(value, nowDate) > 5) {
		return null;
	}
	const isExpired = isBefore(value, nowDate);

	return (
		<i
			className={classNames(
				'fix-date-display-value__icon',
				isExpired
					? 'tz-alarm-clock fix-date-display-value__icon_expired'
					: 'tz-clock fix-date-display-value__icon_warning'
			)}
		/>
	);
};
