import {httpRequests} from '@tehzor/tools/api/httpRequests';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';

/**
 * Генерирует слои помещений (пользовательских фигур) для плана
 *
 * @param objectId id объекта
 * @param planId id плана
 */
export const getFlatMarkupRequest = async (objectId: string, planId: string) => {
	const params = {objectId, planId};
	const response = await httpRequests.withToken.post<{layers: ILayer[]}>(
		'plans/flat-markup', params
	);
	return response.data;
};
