import {ReactNode} from 'react';
import styles from './Board.module.less';

interface IBoardProps {
	title?: string;
	counter?: string | number;
	children: ReactNode;
	showHeader?: boolean;
}

export const Board = ({title, counter, children, showHeader = true}: IBoardProps) => (
	<div className={styles.wrapper}>
		{showHeader && (
			<div className={styles.header}>
				<p className={styles.title}>
					{title} {!!counter && <span className={styles.counter}>({counter})</span>}
				</p>
			</div>
		)}
		<div className={styles.container}>{children}</div>
	</div>
);
