import {useCallback} from 'react';
import {useObjectsMap} from '@src/core/hooks/queries/objects/hooks';
import {useTaskPrioritiesMap} from '@src/core/hooks/queries/task-priorities/hooks';
import {useTaskTypesMap} from '@src/core/hooks/queries/taskTypes';
import {useTaskStatusesMap} from '@src/core/hooks/queries/taskStatuses/hook';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {enrichTask} from '@src/utils/tasks/enrichTask';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTasks, useTasksForSchedule} from '@src/core/hooks/queries/tasks/hooks';
import {IGetTasksResponse} from '@src/api/backend/tasks';
import {ITasksFiltersState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {IEnrichedTaskForScheduler} from '@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler';
import {useWorkingGroupsAsMap} from '../../workingGroups/hooks';

export const useEnrichedTasks = (state?: ITasksFiltersState) => {
	const {data: usersMap} = useUsersAsMap();
	const objectsMap = useObjectsMap();
	const {data: taskStatusesMap} = useTaskStatusesMap();
	const {data: taskTypesMap} = useTaskTypesMap();
	const {data: taskPrioritiesMap} = useTaskPrioritiesMap();

	const select = useCallback(
		(data: IGetTasksResponse): IEnrichedTask[] =>
			data.allIds.map(id => {
				const task = data.byId[id];
				return {
					...enrichTask({
						task,
						objectsMap,
						taskStatusesMap,
						taskPrioritiesMap,
						taskTypesMap,
						usersMap
					}),
					createdAtNumber: task.createdAt,
					modifiedAtNumber: task.modifiedAt
				};
			}),
		[usersMap, objectsMap, taskStatusesMap, taskTypesMap, taskPrioritiesMap]
	);

	return useTasks(state, select);
};

export const useEnrichedTasksForScheduler = (state?: ITasksFiltersState) => {
	const {data: usersMap} = useUsersAsMap();
	const objectsMap = useObjectsMap();
	const {data: workingGroupsMap} = useWorkingGroupsAsMap();
	const {data: taskPrioritiesMap} = useTaskPrioritiesMap();
	const {data: taskStatusesMap} = useTaskStatusesMap();
	const {data: taskTypesMap} = useTaskTypesMap();

	const select = useCallback(
		(data: IGetTasksResponse): IEnrichedTaskForScheduler[] =>
			data.allIds.map(id => {
				const task = data.byId[id];
				return {
					...enrichTask({
						task,
						objectsMap,
						taskStatusesMap,
						taskPrioritiesMap,
						taskTypesMap,
						usersMap
					}),
					activeGroup:
						task.activeGroup && workingGroupsMap
							? workingGroupsMap[task.activeGroup]
							: undefined,
					attachment: task.attachments
						? task.attachments.find(att => att.preview)
						: undefined
				};
			}),
		[usersMap, objectsMap, taskStatusesMap, taskTypesMap, taskPrioritiesMap]
	);

	return useTasksForSchedule(state, select);
};
