import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {useMemo} from 'react';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {useCompaniesById} from '@src/core/hooks/queries/companies/hooks';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';
import {useExtractProblemTagsAsArray} from '@src/core/hooks/queries/problemTags/hooks';

/**
 * Преобразовывает набор меток в необходимый для вывода формат
 *
 * @param data массив наборов меток
 */
export const useEnrichedProblemTagsSets = (
	data?: IProblemTagsSet[]
): IEnrichedProblemTagsSet[] | undefined => {
	const {data: companiesMap} = useCompaniesById();
	const {data: stagesMap} = useObjectStagesMap();
	const {data: tagsArray} = useExtractProblemTagsAsArray();
	return useMemo(
		() =>
			data?.map(item => ({
				...item,
				company: companiesMap ? companiesMap[item.companyId] : undefined,
				objects: item.objects,
				allObjectsAvailable: item.allObjectsAvailable,
				stages: item.stages,
				tagsCount: tagsArray
					? tagsArray.filter(tag => tag.problemTagsSetId === item.id).length
					: undefined,
				createdBy: item.createdBy,
				createdAt: item.createdAt
					? format(item.createdAt, dateTimeCommaSeparatedFormat)
					: '',
				modifiedBy: item.modifiedBy,
				modifiedAt: item.modifiedAt
					? format(item.modifiedAt, dateTimeCommaSeparatedFormat)
					: ''
			})),
		[data, companiesMap, stagesMap]
	);
};
