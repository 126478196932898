import { Dispatch } from 'react';
import {
	IEditableAuthorizedPersonAction,
	IEditableAuthorizedPersonState
} from '@src/core/hooks/states/useEditableAuthorizedPersonState';
import Text from '@src/components/editableFields/Text';
import {EditableDate} from '@src/components/editableFields/EditableDate';
import './EditableAuthorizedPerson.less';

interface IEditableAuthorizedPersonProps {
	editingDispatch: Dispatch<IEditableAuthorizedPersonAction>;
	editingState: IEditableAuthorizedPersonState;
}

export const EditableAuthorizedPerson = (props: IEditableAuthorizedPersonProps) => {
	const {editingState, editingDispatch} = props;

	return (
		<div className="editable-authorized-person">
			<Text
				field="name"
				label="ФИО*"
				value={editingState.name}
				editingDispatch={editingDispatch}
				required
				hasError={editingState.errors.name}
			/>

			<Text
				field="position"
				label="Должность"
				value={editingState.position}
				editingDispatch={editingDispatch}
				hasError={editingState.errors.position}
			/>

			<Text
				field="authorityBasis"
				label="Основание полномочий"
				value={editingState.authorityBasis}
				editingDispatch={editingDispatch}
				hasError={editingState.errors.authorityBasis}
			/>

			<EditableDate
				field="authorityExpiry"
				label="Срок действия полномочий"
				value={editingState.authorityExpiry}
				editingDispatch={editingDispatch}
				hasError={editingState.errors.authorityExpiry}
				createdAt={0}
				errorMessage="Укажите срок"
			/>

			<Text
				field="comment"
				label="Комментарий"
				value={editingState.comment}
				editingDispatch={editingDispatch}
				hasError={editingState.errors.comment}
			/>
		</div>
	);
};
