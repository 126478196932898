import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

interface IPhotoViewerPreviewsState {
	expanded: boolean;
}

export default createReducer<IPhotoViewerPreviewsState>(
	{expanded: true},
	{
		[types.TOGGLE_PREVIEWS_EXPANDED]: (state, {payload}: {payload?: boolean}) => {
			state.expanded = payload !== undefined ? payload : !state;
		}
	}
);
