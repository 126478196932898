const domain = 'statistics/warranty';

export const GET_PROBLEMS_BY_STATUSES_REQUEST = `${domain}/problems-by-statuses/get/request`;
export const GET_PROBLEMS_BY_STATUSES_SUCCESS = `${domain}/problems-by-statuses/get/success`;
export const GET_PROBLEMS_BY_STATUSES_FAILURE = `${domain}/problems-by-statuses/get/failure`;

export const GET_PROBLEMS_BY_CATEGORIES_REQUEST = `${domain}/problems-by-categories/get/request`;
export const GET_PROBLEMS_BY_CATEGORIES_SUCCESS = `${domain}/problems-by-categories/get/success`;
export const GET_PROBLEMS_BY_CATEGORIES_FAILURE = `${domain}/problems-by-categories/get/failure`;

export const GET_CHECKS_CREATION_REQUEST = `${domain}/checks-creation/get/request`;
export const GET_CHECKS_CREATION_SUCCESS = `${domain}/checks-creation/get/success`;
export const GET_CHECKS_CREATION_FAILURE = `${domain}/checks-creation/get/failure`;

export const GET_PROBLEMS_CREATION_REQUEST = `${domain}/problems-creation/get/request`;
export const GET_PROBLEMS_CREATION_SUCCESS = `${domain}/problems-creation/get/success`;
export const GET_PROBLEMS_CREATION_FAILURE = `${domain}/problems-creation/get/failure`;

export const GET_PROBLEM_REPLIES_CREATION_REQUEST = `${domain}/problem-replies-creation/get/request`;
export const GET_PROBLEM_REPLIES_CREATION_SUCCESS = `${domain}/problem-replies-creation/get/success`;
export const GET_PROBLEM_REPLIES_CREATION_FAILURE = `${domain}/problem-replies-creation/get/failure`;

export const GET_INSPECTIONS_CREATION_REQUEST = `${domain}/inspections-creation/get/request`;
export const GET_INSPECTIONS_CREATION_SUCCESS = `${domain}/inspections-creation/get/success`;
export const GET_INSPECTIONS_CREATION_FAILURE = `${domain}/inspections-creation/get/failure`;

export const GET_WARRANTY_CLAIMS_CREATION_REQUEST = `${domain}/warranty-claims-creation/get/request`;
export const GET_WARRANTY_CLAIMS_CREATION_SUCCESS = `${domain}/warranty-claims-creation/get/success`;
export const GET_WARRANTY_CLAIMS_CREATION_FAILURE = `${domain}/warranty-claims-creation/get/failure`;