import {
	IDeleteManyCategoriesResponse,
	requestDeleteManyCategories
} from '@src/api/backend/categories';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

export const useDeleteCategories = () =>
	useMutation<IDeleteManyCategoriesResponse, IError, string>({
		mutationFn: (categoriesSetId: string) => requestDeleteManyCategories(categoriesSetId),
		onError: () => {
			addErrorToast('Ошибка', 'при удалении видов работ');
		}
	});
