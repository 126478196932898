export type ICheckRecordsListQueryKey = [] & {
	0: string;
	1: string;
	2: string[];
	3?: string[];
	4?: string;
	5?: string[];
};
export const checkRecordsQueryKeys = {
	all: () => ['check-records'],
	one: () => ['check-record'],
	changeList: () => [...checkRecordsQueryKeys.one(), 'change-list'],
	changeItem: () => [...checkRecordsQueryKeys.one(), 'change-item'],
	localList: () => [...checkRecordsQueryKeys.all(), 'local-list'],
	details: () => [...checkRecordsQueryKeys.one(), 'detail'],
	detail: (id: string) => [...checkRecordsQueryKeys.one(), 'detail', id],
	list: () => [...checkRecordsQueryKeys.all(), 'list']
};
