import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast} from '@src/utils/toasts';
import {problemTagsSetsQueryKeys} from '@src/api/cache/problemTagsSets/keys';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';
import {makeProblemTagsSetAddRequest} from '@src/api/backend/problemTagsSet';

export const useAddProblemTagsSet = () =>
	useMutation({
		mutationKey: problemTagsSetsQueryKeys.add(),
		mutationFn: (fields: ISavingProblemTagsSet) => makeProblemTagsSetAddRequest(fields),
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке наборов меток нарушений');
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: problemTagsSetsQueryKeys.list()});
		}
	});
