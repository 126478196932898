import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {findMutation} from './findMutation';

export const needResetLinks = (links?: IProblem['links'] | IInspection['links']) => {
	if (links?.checkId) {
		const checkMutation = findMutation(links?.checkId);
		return checkMutation ? false : links.checkId.length < 24;
	}
	if (links?.internalAcceptanceId) {
		const internalAcceptanceMutation = findMutation(links?.internalAcceptanceId);
		return internalAcceptanceMutation ? false : links.internalAcceptanceId.length < 24;
	}
	if (links && 'ownerAcceptanceId' in links && links.ownerAcceptanceId) {
		const ownerAcceptanceMutation = findMutation(links.ownerAcceptanceId);
		return ownerAcceptanceMutation ? false : links.ownerAcceptanceId.length < 24;
	}
	if (links && 'warrantyClaimId' in links && links.warrantyClaimId) {
		const warrantyClaimMutation = findMutation(links.warrantyClaimId);
		return warrantyClaimMutation ? false : links.warrantyClaimId.length < 24;
	}
	return false;
};
