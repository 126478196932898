import config from '@src/core/config';
import IUser from '@tehzor/tools/interfaces/IUser';
import {localAuthStorage} from '@tehzor/tools/api/localAuthStorage';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {getDevice} from '@tehzor/tools/utils/getDevice';
import {IDevice} from '@tehzor/tools/interfaces/IDevice';

const {appId} = config;

export interface ILoginResponse {
	accessToken: string;
	refreshToken: string;
	expires: number;
	device: IDevice;
	user: IUser;
}

/**
 * Аутентифицирует пользователя по email и паролю
 *
 * @param email email
 * @param password пароль
 * @param offlineMode включен ли оффлайн режим
 */
export async function login(email: string, password: string) {
	const device = await getDevice();
	try {
		const response = await httpRequests.default.post<ILoginResponse>('/auth/login', {
			appId,
			email,
			password,
			device
		});

		await localAuthStorage.setTokens(response.data.accessToken, response.data.refreshToken);
		await localAuthStorage.setDeviceId(response.data.device.deviceId);

		return response.data;
	} catch (error) {
		await localAuthStorage.clear();
		throw error;
	}
}
