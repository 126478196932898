import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {formCheckLink, formSpaceLink} from '@tehzor/tools/utils/links';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useCheck} from '@src/core/hooks/queries/check/hook';

/**
 * Хук для хлебных крошек от "всех объектов" до проверки
 *
 * @param objectId id конечного объекта
 * @param checkId id проверки
 */
export function useCheckBreadcrumbs(objectId: string, checkId: string) {
	const {t} = useTranslation();

	const objects = useObjectsData();
	const {data: check} = useCheck(checkId, objectId);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(() => {
		const items: IBreadcrumb[] = objects
			? formObjectsChain(objects.byId, objectId, 'problems', t)
			: [];
		if (check) {
			if (check.space) {
				items.push({
					label: formSpaceTitle(
						check.space.name,
						check.space.altName,
						spaceTypes?.[check.space.type],
						altNamesVisible
					),
					url: formSpaceLink(objectId, check.space.id),
					inactive: false
				});
			} else {
				items.push({
					label: `${t('useCheckBreadcrumbs.pluralName')}`,
					url: `/objects/${objectId}/checks`,
					inactive: false
				});
			}
			items.push({
				label: `${t('useCheckBreadcrumbs.singularName')} №${check.number}`,
				url: formCheckLink(objectId, check.id),
				inactive: false
			});
		}
		return items;
	}, [objectId, check, objects, spaceTypes, altNamesVisible, t]);
}
