import {useCallback, useState} from 'react';
import {LinkButton} from '@tehzor/ui-components';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {CreationDateFilter} from '@src/pages/OfflineJournalPage';
import './OfflineJournalFilterPage.less';
import {ActionsFilterMobile} from './filters/ActionsFilterMobile';
import {StatusesFilterMobile} from './filters/StatusesFilterMobile';
import {getEntityStatuses} from './data/entityFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {useTranslation} from 'react-i18next';
import {getEntityActions} from '@src/pages/OfflineJournalPage/utils/getEntityActions';

export const OfflineJournalFilterPage = () => {
	const {t} = useTranslation();
	const [isCleanable, setIsCleanable] = useState(true);
	const {pushPath} = useChangePath();
	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IOfflineJournalFiltersState>();
	const handleApply = useCallback(() => {
		apply();
		setIsCleanable(false);
		pushPath('/profile/offline-journal');
	}, [apply]);

	useAppHeader(
		{
			title: t('filtersPage.title'),
			showBackBtn: true,
			mobileRightButtons: isCleanable ? (
				<LinkButton
					label={t('filterPage.resetFiltersBtn.label')}
					onClick={mobileClear}
				/>
			) : null
		},
		[]
	);

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
			className="offline-journal-filter-page"
		>
			<ActionsFilterMobile
				value={state.actions}
				onChange={change}
				filteredElements={getEntityActions(t)}
			/>
			<StatusesFilterMobile
				value={state.statuses}
				onChange={change}
				filteredElements={getEntityStatuses(t)}
			/>
			<CreationDateFilter
				label={t('offlineJournalPage.offlineJournalFilters.creationDateFilter.label')}
				from={state.createdAtFrom}
				to={state.createdAtTo}
				className="offline-journal-filter-page__date-button"
			/>
		</TranslatedFilterPage>
	);
};
