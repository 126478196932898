import {queryClient} from '@src/api/QueryClient';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {Query} from '@tanstack/react-query';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {deleteLocalInspection} from './deleteLocalInspection';

export const deleteLinkedLocalInspections = async (
	linkName: keyof NonNullable<IInspection['links']>,
	parentId: string
) => {
	const localInspectionsQueries = queryClient.getQueriesData<IInspection>({
		queryKey: inspectionsQueryKeys.details(),
		predicate: (current: Query<IInspection>) =>
			!!current.state.data?.links && current.state.data?.links[linkName] === parentId
	});
	const localInspections = localInspectionsQueries.map(
		([, inspection]) => inspection
	) as IInspection[];
	if (localInspections.length) {
		for (const i of localInspections) {
			await deleteLocalInspection(i.id);
		}
	}
};
