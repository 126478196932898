import CategoriesSetContent from '@src/pages/manage/CategoriesSetPage/components/CategoriesSetContent';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {Dialog} from '@tehzor/ui-components';
import {useCallback, useState} from 'react';
import * as React from 'react';

export function useEditableCategoriesSetDialog(
	categories: ICategory[],
	categoriesSet: ICategoriesSet | undefined
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<Dialog
			isOpen={isOpen}
			onRequestClose={close}
		>
			<CategoriesSetContent
				categories={categories}
				categoriesSet={categoriesSet}
				inDialog
			/>
		</Dialog>
	);

	return [dialog, open];
}
