import {StatsTreePanel} from '@tehzor/ui-components';
import CommonStatsCards from './CommonStatsCards';
import {StatsByFields} from '@src/utils/statsAggregators/creation';
import {useUser} from '@src/core/hooks/queries/users/hooks';

interface IWorkerStatsProps {
	userId: string;
	stats?: StatsByFields;
	level?: number;
}

const WorkerStats = ({userId, stats, level}: IWorkerStatsProps) => {
	const {data: user} = useUser(userId);
	if (!user) {
		return null;
	}

	return (
		<StatsTreePanel
			id={user.id}
			title={user.fullName}
			subTitle={user.position}
			level={level}
			permanentContent={
				<CommonStatsCards
					stats={stats}
					hideChecks
					hideInspections
				/>
			}
		/>
	);
};

export default WorkerStats;
