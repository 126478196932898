import classNames from 'classnames';
import { memo, useCallback } from 'react';
import * as React from 'react';
import './PhotoPreview.less';
import ButtonBase from '../../buttons/ButtonBase';
import {convertClassNames} from '../../../utils/convertClassNames';

interface IPhotoProps<D> {
	className?: string | {
		root?: string;
		wrap?: string;
	};
	style?: React.CSSProperties;
	children?: React.ReactNode;
	url?: string;
	active?: boolean;
	data?: D;

	onClick?: (data: D) => void;
}

const PhotoPreview = <D extends unknown>({className, style, children, url, active, data, onClick}: IPhotoProps<D>) => {
	const classes = convertClassNames(className);

	const handleClick = useCallback(() => {
		if (data !== undefined && onClick !== undefined) {
			onClick(data);
		}
	}, [data, onClick]);

	return (
		<ButtonBase
			className={classNames('photo-preview', {'photo-preview_active': active}, classes.root)}
			wrapClassName={classes.wrap}
			style={{...style, backgroundImage: url ? `url(${url})` : undefined}}
			classNamePrefix="photo-preview"
			label={children}
			outerTagType="button"
			onClick={handleClick}
		/>
	);
};

export default memo(PhotoPreview);