type SectorType = [string, string, string, string];

/**
 * Находит сектор, с которым можно схлопнуться
 *
 * @param {number} index index сектора, который необходимо схлопнуть
 * @param {SectorType[]} sectors секторы
 */
function findNearSector(index: number, sectors: SectorType[]): number | undefined {
	for (let i = 0;i < sectors.length;i++) {
		if (i === index) {
			continue;
		}
		const intersection = sectors[index].filter(item => sectors[i].includes(item));
		if (intersection.length === 3) {
			return i;
		}
	}
	return
}

/**
 * Объединяет два сектора
 *
 * @param {SectorType} first первый сектор
 * @param {SectorType} second второй сектор
 */
function combineTwoSectors(first: SectorType, second: SectorType): SectorType | undefined {
	const i = first.findIndex(item => !second.includes(item));
	const j = second.findIndex(item => !first.includes(item));
	if (i < 0 || j < 0) {
		return;
	}
	// Если разные элементы в первой группе
	if (i < 2 && j < 2) {
		return [first[i], second[j], first[2], first[3]];
	}
	// Если разные элементы во второй группе
	if (i >= 2 && j >= 2) {
		return [first[0], first[1], first[i], second[j]];
	}
	return
}

/**
 * Схлапывает сектора по возможности
 *
 * @param {string[]} names массив названий
 */
function combineSectors(names: string[]): string[] {
	// Регулярное выражение для разбора сектора
	const sectorRegex = /^([A-ZА-Яа-яa-z0-9]+)\s?-\s?([A-ZА-Яа-яa-z0-9]+)\s?\/\s?([A-ZА-Яа-яa-z0-9]+)\s?-\s?([A-ZА-Яа-яa-z0-9]+)$/g;
	// Разобранные секторы
	const sectors: SectorType[] = [];
	// Секторы с неправильными именами
	const unknown: string[] = [];

	// Разбираем секторы по осям
	for (const name of names) {
		const matches = new RegExp(sectorRegex).exec(name);
		if (matches && matches.length === 5) {
			// @ts-ignore
			sectors.push(matches.slice(1));
		} else {
			unknown.push(name);
		}
	}

	let index = 0;
	while (index < sectors.length) {
		// Находим элемент с которым можно схлопнуть текущий
		const nearSectorIndex = findNearSector(index, sectors);
		if (nearSectorIndex !== undefined) {
			// Схлапываем сектора
			const combined = combineTwoSectors(sectors[index], sectors[nearSectorIndex]);
			if (!combined) {
				index++;
				continue;
			}
			// Обновляем массив секторов
			sectors.splice(Math.max(index, nearSectorIndex), 1);
			sectors.splice(Math.min(index, nearSectorIndex), 1);
			sectors.push(combined);

			// Сбрасываем index
			index = 0;
		} else {
			index++;
		}
	}

	// Формируем обратно названия
	return sectors
		.map(item => {
			const i = item[0] < item[1] ? 0 : 1;
			const j = item[2] < item[3] ? 2 : 3;
			return `${item[i]}-${item[1 - i]} / ${item[j]}-${item[5 - j]}`;
		})
		.concat(unknown);
}

export default combineSectors;
