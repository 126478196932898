import {useCallback} from 'react';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureLink} from '@tehzor/tools/utils/links';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {pageSizes} from '@/shared/constants/pageSizes';
import {SelectionRow} from './SelectionRow/SelectionRow';
import {useTableColumns} from '../model/hooks/useTableColumns';
import styles from './StructuresTable.module.less';
import {extractStructuresListPageSettings} from '@/entities/Structures/model/slice/selectors';
import {structuresActions} from '@/entities/Structures';
import {usePreparedPaginateStructures} from '@/features/Structures/useEnrichedStructures';

interface IDesktopTableProps {
	objectId: string;
	spaceId: string | undefined;
}

export const StructuresTable = (props: IDesktopTableProps) => {
	const {objectId, spaceId} = props;
	const isDesktop = useIsDesktop();
	const {pushPath} = useChangePath();
	const {sort, pageSize, selectedRows} = useAppSelector(s =>
		extractStructuresListPageSettings(s, objectId)
	);

	const dispatch = useAppDispatch();
	const {changeSort, changePageSize, changeOffset, changeSelectedRows} = structuresActions;

	const {structures, total, offset} = usePreparedPaginateStructures({objectId, spaceId});

	const pagesCount = Math.ceil((total ?? 0) / pageSize);
	const currentPage = Math.floor((offset ?? 0) / pageSize);

	const handleRowClick = useCallback(
		(structure: IEnrichedStructure) =>
			pushPath(formStructureLink(structure.objectId, structure.id)),
		[pushPath]
	);

	const handleSelection = useCallback(
		(value: string[]) => dispatch(changeSelectedRows({objectId, selectedRows: value})),
		[objectId, dispatch, changeSelectedRows]
	);

	const handleSortChange = useCallback(
		(value: Record<string, boolean>) => dispatch(changeSort({objectId, sort: value})),
		[objectId, dispatch, changeSort]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				dispatch(changeOffset({objectId, offset: newOffset}));
			}
		},
		[objectId, offset, pageSize, dispatch, changeOffset]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize({objectId, pageSize: value}));
			const newOffset = Math.floor((offset ?? 0) / value);
			dispatch(changeOffset({objectId, offset: newOffset}));
		},
		[dispatch, objectId, offset, changePageSize, changeOffset]
	);

	const columns = useTableColumns();

	return (
		<>
			<Plate withoutPadding>
				<EntitiesTable<IEnrichedStructure>
					columns={columns}
					data={structures || []}
					sort={sort}
					selectedRows={selectedRows}
					selectable={isDesktop}
					noRowBorder={!isDesktop}
					headVisible={isDesktop}
					onRowClick={handleRowClick}
					onSelectedRowsChange={handleSelection}
					onSortChange={handleSortChange}
					renderSelectionRow={SelectionRow}
				/>
			</Plate>
			<PaginationAndSize
				className={styles.pagination}
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
};
