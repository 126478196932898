import {IDeleteDevicesResponse, requestDeleteDevice} from '@src/api/backend/devices/delete';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';

export interface IDeleteDevicePayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => ({
	type: types.DELETE_SUCCESS,
	payload: {id} as IDeleteDevicePayload
});

const failure = (err: IError) => ({
	type: types.DELETE_FAILURE,
	payload: {...err}
});

export const deleteDevice = (id: string) =>
	createApiAction<IDeleteDevicesResponse>(
		request,
		() => success(id),
		failure,
		() => requestDeleteDevice(id)
	);