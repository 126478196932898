import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ActionButtons, Button, Dialog, LinkButton, Select2 as Select, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import { useCallback, useMemo, useState } from 'react';
import {useUpdateEffect} from 'react-use';
import {IExtendedSpaceStatus} from '../../interfaces/IExtendedSpaceStatus';

interface IStatusTransitionsFieldProps {
	status: IExtendedSpaceStatus;
	statuses?: ISpaceStatus[];
	disabled?: boolean;
	isMobile?: boolean;

	onChange: (value?: string[] | null) => void;
}

const getTitle = (statuses?: ISpaceStatus[], value?: string[]) => {
	if (!value?.length || statuses === undefined) {
		return '';
	}
	const filteredStatuses = statuses.filter(status => value.includes(status.id));
	const names = filteredStatuses.map(status => status.name);

	return names.join(', ');
};

const arrowIcon = <i className="tz-simple-arrow-20"/>;
const closeIcon = <i className="tz-close-16"/>;

export const StatusTransitionsField = ({
	status, statuses, disabled, isMobile, onChange
}: IStatusTransitionsFieldProps) => {
	const [checked, setChecked] = useState(status?.transitions);
	const [isOpen, setOpen] = useState(false);

	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	useUpdateEffect(() => setChecked(status?.transitions), [status?.transitions]);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const count = useMemo(() => checked?.length, [checked]);
	const title = useMemo(() =>
		getTitle(statuses, status?.transitions || undefined),
	[statuses, status?.transitions]);

	const isSearchShown = useMemo(() => (statuses?.length || 0) >= 8, [statuses]);

	const filteredStatuses = useMemo(() => {
		if (!statuses) { return undefined; }

		return flatFilter(
			statuses?.filter(val => val.id !== status.id),
			'name',
			search
		);
	}, [status.id, statuses, search]);

	const handleCancel = useCallback(() => {
		setChecked(status?.transitions || undefined);
		clearSearch();
	}, [status?.transitions, clearSearch]);

	const handleConfirm = useCallback(() => {
		onChange(checked);
		clearSearch();
	}, [onChange, checked, clearSearch]);

	const handleChange = useCallback((val: string[]) => setChecked(val), []);

	const handleClearButton = useCallback(() => {
		onChange(null);
		clearSearch();
	}, [clearSearch]);

	const handleClear = useCallback(() => {
		setChecked(null);
		clearSearch();
	}, [clearSearch]);

	const trigger = (
		<TextFieldWithForwardedRef
			elementType="div"
			icon={arrowIcon}
			value={title}
			disabled={disabled}
			onClick={isMobile ? open : undefined}
			cleanable={!!status?.transitions?.length}
			onClearClick={handleClearButton}
		/>
	);

	const content = (
		<Select
			multiple
			value={checked || undefined}
			onChange={handleChange}
		>
			{filteredStatuses?.map(item => (
				<SelectOption
					key={item.id}
					itemKey={item.id}
					content={item.name}
				/>
			))}
		</Select>
	);

	const selectSearch = isSearchShown ? (
		<SelectSearch
			value={search}
			onChange={setSearch}
			onClear={clearSearch}
		/>
	) : null;

	return (
		<div className="editable-space-statuses__status-fields__trns">
			<div className="editable-space-statuses__status-fields__trns-title">
				Смена доступна на:
			</div>
			{!isMobile ? (
				<SelectPopup
					onCancel={handleCancel}
					onApply={handleConfirm}
					onClear={handleClear}
					clearButton={!!checked?.length}
					footer
					count={checked?.length}
					trigger={trigger}
					search={selectSearch}
				>
					{content}
				</SelectPopup>
			) : trigger}
			{isMobile && (
				<Dialog
					isOpen={isOpen}
					title="Смена статуса доступна на:"
					onRequestClose={close}
					className="editable-space-statuses__status-fields__trns-dialog"
					footer={(
						<div
							className="editable-space-statuses__status-fields__trns-footer"
						>
							<div
								className="editable-space-statuses__status-fields__trns-footer-info"
							>
								<div className="d-regular-12">{`Выбрано ${count || 0}`}</div>
								<LinkButton
									label="Сбросить все"
									className="select-popup__clear"
									onClick={handleClear}
									rightIcon={closeIcon}
								/>
							</div>

							<ActionButtons
								className="editable-space-statuses__status-fields__trns-footer-btns"
							>
								<Button
									type="cancel"
									label="Отменить"
									onClick={handleCancel}
								/>
								<Button
									type="accent-blue"
									label="Сохранить"
									onClick={handleConfirm}
								/>
							</ActionButtons>
						</div>
					)}
				>
					{selectSearch}
					{content}
				</Dialog>
			)}
		</div>
	);
};