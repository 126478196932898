import {IGetOwnerAcceptancesResponse} from '@src/api/backend/ownerAcceptances';
import {maxBy} from 'lodash';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';

export const extractOwnerAcceptancesAsArray = (data?: IGetOwnerAcceptancesResponse) => {
	if (!data) return undefined;
	return data.allIds.map(id => data.byId[id]);
};

/**
 * Возвращает последние ownerAcceptances для конкретного объекта в виде массива
 */
export const extractLatestOwnerAcceptancesAsArray = (
	data?: IGetOwnerAcceptancesResponse,
	userId?: string,
	objectId?: string,
	spaceId?: string
) => {
	const array = extractOwnerAcceptancesAsArray(data);

	if (!array) return undefined;

	return array.filter(
		item =>
			item.objectId === objectId &&
			item.createdBy?.id === userId &&
			(!spaceId || spaceId === item.links?.spaceId)
	);
};

/**
 * Возвращает последнюю передачу собственнику для конкретного помещения
 */
export const extractLatestOwnerAcceptanceId = (
	data?: IGetOwnerAcceptancesResponse,
	userId?: string,
	objectId?: string,
	spaceId?: string
) => {
	const array = extractLatestOwnerAcceptancesAsArray(data, userId, objectId, spaceId);

	if (!array) return undefined;

	return maxBy(array, 'modifiedAt')?.id;
};

export const extractLocalSpaceOwnerAcceptance = (data: IListOwnerAcceptance[], spaceId: string) =>
	data.filter(check => check.links?.spaceId === spaceId);
