import {HtmlHTMLAttributes, memo} from 'react';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import classNames from 'classnames';
import {CircleLoader} from '@tehzor/ui-components/src/components/loaders/CircleLoader';
import CircleButton from '@tehzor/ui-components/src/components/buttons/CircleButton';
import './OfflineJournalStatus.less';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {getOfflineModeTransferTitle} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {useTranslation} from 'react-i18next';
import {TransferButton} from '../OfflineJournalTransferButton/TransferButton';
import {useEntityTransferState} from '@src/core/hooks/useEntityTransferState';

interface IOfflineJournalStatusProps extends HtmlHTMLAttributes<HTMLDivElement> {
	offlineJournalEntity: IOfflineJournalEntity;
}

export const OfflineJournalStatus = memo((props: IOfflineJournalStatusProps) => {
	const {t} = useTranslation();
	const {offlineJournalEntity, className, ...restProps} = props;
	const {entity} = offlineJournalEntity;
	const status = useEntityTransferState(entity.entityId);
	const title = getOfflineModeTransferTitle(status, t);

	let statusModeClassName = null;
	let iconClassName = null;
	let currentStatusIcon = null;

	switch (status) {
		case OfflineDataTransferStatus.SAVED_LOCAL:
			statusModeClassName = 'offline-journal-status__content_status_saved';
			iconClassName = 'tz-offline-mode-20';
			currentStatusIcon = <TransferButton entity={entity} />;
			break;
		case OfflineDataTransferStatus.TRANSFER:
			statusModeClassName = 'offline-journal-status__content_status_progress';
			iconClassName = 'tz-data-transfer-20';
			currentStatusIcon = <CircleLoader />;
			break;
		case OfflineDataTransferStatus.TRANSFER_COMPLETE:
			statusModeClassName = 'offline-journal-status__content_status_transferred';
			iconClassName = 'tz-toast-success';
			currentStatusIcon = null;
			break;
		case OfflineDataTransferStatus.TRANSFER_ERROR:
			statusModeClassName = 'offline-journal-status__content_status_error';
			iconClassName = 'tz-data-transfer-error-20';
			currentStatusIcon = <TransferButton entity={entity} />;
			break;
		default:
			statusModeClassName = 'offline-journal-status__content_status_error';
			iconClassName = 'tz-data-transfer-error-20';
			currentStatusIcon = (
				<CircleButton className="offline-journal-status__circle-button">
					<i className="tz-reload-24" />
				</CircleButton>
			);
	}

	return (
		<div
			className={classNames('offline-journal-status', className)}
			{...restProps}
		>
			<div className={classNames('offline-journal-status__content', statusModeClassName)}>
				<i className={iconClassName} />
				{title}
			</div>
			{currentStatusIcon}
		</div>
	);
});
