import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import * as types from './constants';
import {ICategoriesSetsFiltersState, ICategoriesSetsSortState} from './reducers';

export const changeFilters = (objectId = 'all', filters: ICategoriesSetsFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: {
		objectId,
		filters
	} as IChangeFiltersPayload<ICategoriesSetsFiltersState>
});

export const clearFilters = (objectId = 'all') => ({
	type: types.CLEAR_FILTERS,
	payload: {objectId} as ICategoriesSetsFiltersState
});

export const changeSort = (objectId = 'all', sort: ICategoriesSetsSortState) => ({
	type: types.CHANGE_SORT,
	payload: {
		objectId,
		sort
	} as IChangeSortPayload<ICategoriesSetsSortState>
});

export const changeOffset = (objectId = 'all', offset: number) => ({
	type: types.CHANGE_OFFSET,
	payload: {
		objectId,
		offset
	}
});

export const changePageSize = (objectId = 'all', pageSize: number) => ({
	type: types.CHANGE_PAGE_SIZE,
	payload: {
		objectId,
		pageSize
	} as IChangePageSizePayload
});
