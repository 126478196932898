import {memo} from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import {useMetersAsArrayForSpace} from '@src/core/hooks/queries/meters/hooks';
import {useExtractMeterTypesById} from '@src/core/hooks/queries/meterTypes/hooks';
import {MeterTypeId} from '@tehzor/tools/interfaces/meters/IMeterType';
import {Meter} from './Meter';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface MetersProps {
	typeId: MeterTypeId;
	spaceId: string;
}

const meterIcons: Record<MeterTypeId, JSX.Element> = {
	[MeterTypeId.ELECTRICITY]: <i className="tz-electricity-20" />,
	[MeterTypeId.WATER_COLD]: <i className="tz-water-cold-20" />,
	[MeterTypeId.WATER_HOT]: <i className="tz-water-hot-20" />,
	[MeterTypeId.HEAT]: <i className="tz-heat-20" />,
	[MeterTypeId.GAS]: <i className="tz-gas-20" />,
	[MeterTypeId.COOLING_SYSTEM]: <i className="tz-air-conditioner-20" />
};

export const Meters = memo(({typeId, spaceId}: MetersProps) => {
	const {data: meters} = useMetersAsArrayForSpace(spaceId);
	const {data: meterTypes} = useExtractMeterTypesById();
	const translatedMeterTypes = useTranslatedDictionaryMap(dictionaryKeys.meterTypes, meterTypes);

	// Диалог редактирования прибора учёта

	const filteredMeters = meters?.filter(m => m.typeId === typeId);

	if (!filteredMeters || !filteredMeters.length || !translatedMeterTypes) {
		return null;
	}

	return (
		<EntityGridItem
			fullRow
			icon={meterIcons[typeId]}
			label={translatedMeterTypes[typeId]?.name}
			className="space-page__meter-type"
		>
			<div key={typeId}>
				{filteredMeters.map((meter: IMeter) => (
					<Meter meter={meter} />
				))}
			</div>
		</EntityGridItem>
	);
});
