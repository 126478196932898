import {memo, useCallback} from 'react';
import {useSchemaItemsByWorkAcceptancesAndProblems} from '@/widgets/StructuresSchema/model/hooks/useSchemaItemsByWorkAcceptancesAndProblems';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {Board} from '@tehzor/ui-components';
import {BoardItem} from '@/widgets/StructuresSchema/ui/BoardItem/BoardItem';
import {formStructureLink} from '@tehzor/tools/utils/links';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {Skeleton} from '@/widgets/StructuresSchema/ui/ObjectBoard/Skeleton/Skeleton';

interface IObjectBoardProps {
	objectId: string;
}

export const ObjectBoard = memo(({objectId}: IObjectBoardProps) => {
	const isDesktop = useIsDesktop();
	const {pushPath} = useChangePath();
	const {data: object} = useObject(objectId);

	const {
		data: boardItems,
		isLoading,
		total
	} = useSchemaItemsByWorkAcceptancesAndProblems(objectId);

	const goToStructure = useCallback(
		(structureId: string) => pushPath(formStructureLink(objectId, structureId)),
		[objectId, pushPath]
	);

	return (
		<Board
			title={object?.name}
			counter={total}
			showHeader={isDesktop}
		>
			{isLoading ? (
				<Skeleton />
			) : (
				boardItems &&
				boardItems.map(([key, value], i) => (
					<BoardItem
						title={key}
						diagrams={value}
						onClick={goToStructure}
						key={i}
					/>
				))
			)}
		</Board>
	);
});
