import {memo} from 'react';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '../utils/makeFilterLabel';
import {IDateRangePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DateRangePicker/DateRangePicker';
import {longDateFormat} from '@tehzor/tools/utils/dateFormats';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IDateFilterFilterProps {
	label?: string;
	from?: Date;
	to?: Date;
	onChange?: (dateFrom?: Date | null, dateTo?: Date | null) => void;
}

export const DateFilter = memo((props: IDateFilterFilterProps) => {
	const {label = 'label', from, to, onChange} = props;

	const handleChange = (dateFrom?: Date | null, dateTo?: Date | null) => {
		onChange?.(dateFrom, dateTo);
	};
	const handleClear = () => onChange?.(undefined, undefined);

	const getTrigger = (triggerProps: IDateRangePickerTriggerProps) => (
		<FilterButton
			className="entities-filters__item"
			label={makeDateFilterLabel(label, from, to)}
			active={Boolean(from || to)}
			onClick={triggerProps.toggle}
			onClear={handleClear}
			ref={triggerProps.ref}
		/>
	);

	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	return (
		<DateRangePicker
			trigger={getTrigger}
			valueFrom={from}
			valueTo={to}
			dateFormat={longDateFormat}
			useApplyButton
			onChange={handleChange}
			datesOptions={datesOptions}
			selectApplyBtnLabel={t('applyBtn.label')}
			selectCancelBtnLabel={t('cancelBtn.label')}
		/>
	);
});
