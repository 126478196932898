import {useCallback, useRef, useState} from 'react';
import * as React from 'react';
import './AddingProblemDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import AddingProblem, {IAddingProblemRefProps} from '../AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {saveProblem} from './utils/saveProblem';
import {useFetchLatestUserChecks} from '@src/core/hooks/queries/checks/latest/hooks';
import {useTranslation} from 'react-i18next';

interface IAddingProblemDialogProps {
	objectId: string;
	links?: IProblem['links'];
	stage: ObjectStageIds;
	scope?: string;
	defaultData?: ISavingProblem;
	isOpen: boolean;
	header?: React.ReactElement;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const AddingProblemDialog = (props: IAddingProblemDialogProps) => {
	const {t} = useTranslation();
	const {objectId, links, stage, scope, defaultData, isOpen, header, onSuccess, onClose} = props;
	const addingEntityRef = useRef<IAddingProblemRefProps>(null);
	useFetchLatestUserChecks();

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const handleSave = useCallback(async () => {
		await saveProblem({addingEntityRef, success, onClose});
		setSaving(false);
	}, [onClose]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onSuccess]);

	return (
		<Dialog
			className="adding-check-dialog"
			isOpen={isOpen}
			title={t('components.addingProblemDialog.dialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('components.addingProblemDialog.button.cancel.label')}
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label={t('components.addingProblemDialog.button.accent.label')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			{header}
			<AddingProblem
				objectId={objectId}
				links={links}
				stage={stage}
				scope={scope}
				defaultData={defaultData}
				saving={saving}
				onClose={onClose}
				setSaving={setSaving}
				ref={addingEntityRef}
			/>
		</Dialog>
	);
};

export default AddingProblemDialog;
