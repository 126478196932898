import { createElement, ComponentType } from 'react';
import {CellProps} from 'react-table';

/**
 * Исходя из поля type в данных отрисовывает соответствующий компонент
 *
 * @param components объект с ключами - типами и значениями - компонентами
 * @constructor
 */
const CellWrap
	= <E extends {type: string}>(
		components: Partial<Record<E['type'], ComponentType<CellProps<any>>>> // TODO Разобраться с типами, заменить any
	) =>
	(props: CellProps<E>) => {
		const component = components[props.row.original.type] as ComponentType<CellProps<E>>;
		if (component !== undefined) {
			return createElement(component, props);
		}
		return null;
	};

export default CellWrap;
