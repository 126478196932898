import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import {
	convertLegalToSave,
	errorsFns,
	isEdited,
	useEditableLegalState
} from '@src/core/hooks/states/useEditableLegalState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {EditableLegal} from '@src/components/EditableLegal/EditableLegal';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingLegalEntity} from '@tehzor/tools/interfaces/legals-entity/ISavingLegalEntity';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

const defaultFieldsSettings: Record<string, IObjectFieldSetting> = {
	name: {fieldId: 'name', isRequired: true},
	shortName: {fieldId: 'shortName', isRequired: true},
	companyId: {fieldId: 'companyId', isRequired: true},
	inn: {fieldId: 'inn', isRequired: true},
	kpp: {fieldId: 'kpp', isRequired: false},
	externalId: {fieldId: 'externalId', isRequired: false},
	ogrn: {fieldId: 'ogrn', isRequired: true},
	legalAddress: {fieldId: 'legalAddress', isRequired: true},
	mailingAddress: {fieldId: 'mailingAddress', isRequired: false},
	phone: {fieldId: 'phone', isRequired: false},
	email: {fieldId: 'email', isRequired: false},
	registrationCertificateDate: {fieldId: 'registrationCertificateDate', isRequired: true},
	registrationCertificateNumber: {fieldId: 'registrationCertificateNumber', isRequired: true},
	okpo: {fieldId: 'okpo', isRequired: false},
	okved: {fieldId: 'okved', isRequired: true},
	buildingSroNumber: {fieldId: 'buildingSroNumber', isRequired: false},
	buildingSroDate: {fieldId: 'buildingSroDate', isRequired: false},
	designSroNumber: {fieldId: 'designSroNumber', isRequired: false},
	designSroDate: {fieldId: 'designSroDate', isRequired: false},
	comment: {fieldId: 'comment', isRequired: false}
};

interface IEditableLegal {
	legalFields: React.ReactNode;
	getSavingData: (useLocalFiles?: boolean) => ISavingLegalEntity | undefined;
	isBlocking: boolean;
	reset: () => void;
}

interface IPredefinedLegal {
	name?: string;
	shortName?: string;
	companyId?: string;
	inn?: string;
	kpp?: string;
	ogrn?: string;
	externalId?: string;
	legalAddress?: string;
	mailingAddress?: string;
	phone?: string;
	email?: string;
	registrationCertificateDate?: number;
	registrationCertificateNumber?: string;
	okpo?: string;
	okved?: string[];
	buildingSroNumber?: string;
	buildingSroDate?: number;
	designSroNumber?: string;
	designSroDate?: number;
	comment?: string;
}

export const useEditableLegal = (legal?: ILegalEntity): IEditableLegal => {
	const createPredefinedLegal = useCallback(
		(predefinedLegal: IPredefinedLegal) =>
			({
				name: predefinedLegal.name,
				shortName: predefinedLegal.shortName,
				companyId: predefinedLegal.companyId,
				inn: predefinedLegal.inn,
				kpp: predefinedLegal.kpp,
				ogrn: predefinedLegal.ogrn,
				externalId: predefinedLegal.externalId,
				legalAddress: predefinedLegal.legalAddress,
				mailingAddress: predefinedLegal.mailingAddress,
				phone: predefinedLegal.phone,
				email: predefinedLegal.email,
				registrationCertificateDate: predefinedLegal.registrationCertificateDate,
				registrationCertificateNumber: predefinedLegal.registrationCertificateNumber,
				okpo: predefinedLegal.okpo,
				okved: predefinedLegal.okved,
				buildingSroNumber: predefinedLegal.buildingSroNumber,
				buildingSroDate: predefinedLegal.buildingSroDate,
				designSroNumber: predefinedLegal.designSroNumber,
				designSroDate: predefinedLegal.designSroDate,
				comment: predefinedLegal.comment
			} as ILegalEntity),
		[]
	);

	const [localLegal, setLocalLegal] = useState(() => legal || createPredefinedLegal(legal || {}));

	useEffect(() => {
		if (legal) {
			setLocalLegal(legal);
		}
	}, [legal]);

	const [editingState, editingDispatch] = useEditableLegalState(localLegal);

	const [isBlocking, setIsBlocking] = useState(false);
	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, localLegal));
	}, [editingState, localLegal]);

	const getSavingData = useCallback(() => {
		if (hasErrors(editingState, errorsFns, defaultFieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}

		if (!isEdited(editingState, localLegal)) {
			return undefined;
		}
		// Здесь используется legal, а не localLegal, для того чтобы обнаруживать изменение поля
		// если были предзаполнены поля при помощи createPredefinedLegal
		return convertLegalToSave(editingState, legal, true);
	}, [editingState, legal, localLegal, editingDispatch]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: localLegal
		});
	}, [editingDispatch, localLegal]);

	useUpdateEffect(reset, [localLegal]);

	const legalFields = (
		<EditableLegal
			editingState={editingState}
			editingDispatch={editingDispatch}
			fieldsSettings={defaultFieldsSettings}
		/>
	);

	return {legalFields, getSavingData, isBlocking, reset};
};
