import {useCallback, useMemo, memo, useTransition} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	changeStage,
	clearFilters,
	togglePending
} from '@src/store/modules/settings/pages/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {FilterMenu, Menu, MenuItem} from '@tehzor/ui-components';
import './StageVisibilityFilter.less';
import classNames from 'classnames';
import {useStagesTitlesMap} from '../../../hooks/useStagesTitlesMap';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useUpdateEffect} from 'react-use';
import {isEmpty} from 'lodash';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {useTranslation} from 'react-i18next';

interface StageVisibilityFilterProps {
	className?: string;
	objectId: string;
}

export const StageVisibilityFilter = memo(({className, objectId}: StageVisibilityFilterProps) => {
	const {t} = useTranslation();

	const dispatch = useAppDispatch();
	const titlesMap = useStagesTitlesMap();
	const {data: object} = useObject(objectId);
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	const [isPending, startTransition] = useTransition();

	const stage = useMemo(
		() =>
			pageSettings.schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES
				? ObjectStageIds.BUILDING
				: pageSettings.stage,
		[pageSettings.schemaView, pageSettings.stage]
	);

	const label = useMemo(
		() =>
			stage && titlesMap[stage]
				? `${t('spacesPage.stageVisibilityFilter.filterMenu.show.label')}: ${
					titlesMap[stage]
				}`
				: `${t('spacesPage.stageVisibilityFilter.filterMenu.show.label')}: ${t(
					'spacesPage.stageVisibilityFilter.filterMenu.allStages.label'
				)}`,
		[stage, titlesMap]
	);

	const handleChange = useCallback(
		(v: ObjectStageIds) => {
			startTransition(() => {
				dispatch(changeStage(objectId, v));
			});
			if (!isEmpty(pageSettings.filters)) {
				dispatch(clearFilters(objectId));
			}
		},
		[objectId, pageSettings.filters]
	);

	useUpdateEffect(() => {
		dispatch(togglePending(isPending));
	}, [isPending]);

	const finalClassNames = useMemo(
		() => ({
			root: classNames('stage-visibility-filter', className),
			label: 'stage-visibility-filter__label'
		}),
		[className]
	);

	return (
		<FilterMenu
			className={finalClassNames}
			label={label}
			active
			disabled={pageSettings.schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES}
		>
			<Menu
				value={stage || 'all'}
				onChange={handleChange}
			>
				{Object.entries<string>(titlesMap).map(([key, title]) => (
					<MenuItem
						key={key}
						itemKey={key}
					>
						{title}
						{object?.stage === key && (
							<div className="stage-visibility-filter__current">
								{t(
									'spacesPage.stageVisibilityFilter.filterMenu.menu.menuItem.current.children'
								)}
							</div>
						)}
					</MenuItem>
				))}
			</Menu>
		</FilterMenu>
	);
});
