import {wsConnector} from '@src/api/backend/wsConnector';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {ISendResponse} from '@src/interfaces/ISendResponse';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на отправку экспортированной внутренней приёмки
 */
export const makeExportedInternalAcceptanceSendRequest = (
	email: string,
	templateId: string,
	objectId: string,
	internalAcceptanceId: string,
	problems?: string[],
	inspections?: string[],
	createDocument?: boolean
) => {
	const params = {
		email: getEmailsArray(email),
		templateId,
		objectId,
		internalAcceptanceId,
		problems,
		inspections,
		timezone: getTimezone(),
		createDocument
	};
	return wsConnector.sendAuthorizedRequest<ISendResponse>('sendExportedInternalAcceptance', params);
};
