import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useWorkAcceptanceBreadcrumbs} from '@src/core/hooks/breadcrumbs/useWorkAcceptanceBreadcrumbs';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

interface IPageBreadcrumbsProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
}

export const PageBreadcrumbs = ({objectId, workAcceptance}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useWorkAcceptanceBreadcrumbs(
		objectId,
		workAcceptance.id,
		workAcceptance.number
	);

	if (!breadcrumbs) return null;

	return (
		<AppBreadcrumbs
			className="work-acceptance-page__breadcrumbs"
			items={breadcrumbs}
		/>
	);
};
