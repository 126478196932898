import {ITwoWayTreeItem} from '../../interfaces/ITwoWayTreeItem';
import {getAllTreeKeys} from './getAllTreeKeys';

/**
 * Оставляет только выбранные родительские элементы
 *
 * @param data данные в виде дерева
 * @param checked отмеченные элементы
 */
export const filterOnlyParents = <T extends {id: string}>(
	data: Array<ITwoWayTreeItem<T>>,
	checked: string[]
): string[] =>
	data.reduce<string[]>((prev, treeItem) => {
		if (checked.includes(treeItem.id)) {
			if (treeItem.children) {
				const descendants = getAllTreeKeys(treeItem.children);
				prev = prev.filter(id => !descendants.includes(id));
			}
		} else if (treeItem.children) {
			prev = filterOnlyParents(treeItem.children, prev);
		}
		return prev;
	}, checked);
