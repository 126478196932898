import {Reducer, useReducer} from 'react';
import {
	errorsFns,
	IEditableCheckAction,
	IEditableCheckState,
	init
} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';

export const useEditableCheckState = (check?: ICheck) =>
	useReducer<
		Reducer<IEditableCheckState, IEditableCheckAction>,
		ICheck | undefined
	>(createReducer(init, errorsFns), check, init);
