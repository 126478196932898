import {useCallback} from 'react';
import {SwitchMenu, SwitchMenuOption} from '@tehzor/ui-components';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {changeDisplayMode} from '@src/store/modules/settings/pages/spaces/actions';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

interface IDesktopDisplayModeSwitchProps {
	objectId: string;
}

export const DesktopDisplayModeSwitch = ({objectId}: IDesktopDisplayModeSwitchProps) => {
	const {displayMode} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const handleChange = useCallback(
		(value: SpacesDisplayMode) => {
			dispatch(changeDisplayMode(objectId, value));
		},
		[objectId]
	);

	return (
		<SwitchMenu
			value={displayMode}
			onChange={handleChange}
		>
			<SwitchMenuOption value={SpacesDisplayMode.SCHEMA}>
				<i className="tz-schema-24" />
			</SwitchMenuOption>
			<SwitchMenuOption value={SpacesDisplayMode.LIST}>
				<i className="tz-list-24" />
			</SwitchMenuOption>
		</SwitchMenu>
	);
};
