import {Checkbox} from '@tehzor/ui-components';
import { memo } from 'react';

interface IUnavailableWithUnacceptedCheckItemFieldProps {
	title: string
	checked?: boolean;
	disabled?: boolean;

	onChange: () => void;
}

export const OptionsField = memo(({ title, checked, disabled, onChange }: IUnavailableWithUnacceptedCheckItemFieldProps) => (
	<div className="editable-space-statuses__status-fields__options">
		<Checkbox
			className="editable-space-statuses__status-fields__options-checkbox"
			checked={checked}
			disabled={disabled}
			onClick={onChange}
		/>
		<div className="editable-space-statuses__status-fields__options-title">
			{title}
		</div>
	</div>
));