import {IState} from '@src/store/modules';
import * as types from '../constants/inspectionsData';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {IEntityInspectionsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityInspectionsData';
import {requestInternalAcceptancesInspectionsData} from '@src/api/backend/internalAcceptances/inspectionsData';
import {IInternalAcceptancesStatsDataRequest} from '@src/interfaces/IInternalAcceptancesStatsData';

const request = (requestData: IInternalAcceptancesStatsDataRequest) => ({
	type: types.GET_REQUEST,
	payload: requestData
});

const success = (response: Record<string, IEntityInspectionsData>) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке осмотров внутренних приемок');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает статстику осмотров для внутренних приемок
 *
 * @param requestData данные запроса
 */
export const getInternalAcceptancesInspectionsData = (
	requestData: IInternalAcceptancesStatsDataRequest
) => (
		checkLoaded<IState, Record<string, IEntityInspectionsData>, ApiAction>(
		s => s.pages.internalAcceptances.inspectionsData,
		createApiAction(
			() => request(requestData),
			result => success(result),
			error => failure(error),
			() => requestInternalAcceptancesInspectionsData(requestData)
		)
	)
	);
