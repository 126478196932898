import {memo, useCallback, useState} from 'react';
import * as React from 'react';
import './AppHeader.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import DesktopAppHeader from './components/DesktopAppHeader';
import MobileAppHeader from './components/MobileAppHeader';
import {AppHeaderCtx, AppHeaderCtxOptions} from './utils/AppHeaderCtx';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';
import {ErrorBoundary} from '@tehzor/ui-components';

interface IAppHeaderProps {
	children?: React.ReactNode;
}

/**
 * Шапка приложения
 */
const AppHeader = ({children}: IAppHeaderProps) => {
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);

	const [title, setTitle] = useState<string | undefined>();
	const [subTitle, setSubTitle] = useState<string | undefined>();
	const [backBtnVisible, setBackBtnVisible] = useState<boolean | undefined>(false);
	const [sectionsMenu, setSectionsMenu] = useState<IHeaderSectionsMenuItem[] | undefined>();
	const [mobileLeftButton, setMobileLeftButton] = useState<React.ReactNode | undefined>();
	const [mobileRightButtons, setMobileRightButtons] = useState<React.ReactNode | undefined>();
	const [titleButtons, setTitleButtons] = useState<React.ReactNode | undefined>();

	const ctxValue = useCallback((options?: AppHeaderCtxOptions) => {
		setTitle(options?.title);
		setSubTitle(options?.subTitle);
		setBackBtnVisible(options?.showBackBtn);
		setSectionsMenu(options?.sectionsMenu);
		setMobileLeftButton(options?.mobileLeftButton);
		setMobileRightButtons(options?.mobileRightButtons);
		setTitleButtons(options?.titleButtons);
	}, []);

	const isDesktop = useIsDesktop();
	return (
		<>
			<ErrorBoundary
				label="AppHeader"
				className="app-header error"
			>
				{isAuthenticated &&
					(isDesktop ? (
						<DesktopAppHeader
							title={title}
							backBtnVisible={backBtnVisible}
							sectionsMenu={sectionsMenu}
						/>
					) : (
						<MobileAppHeader
							title={title}
							subTitle={subTitle}
							backBtnVisible={backBtnVisible}
							sectionsMenu={sectionsMenu}
							mobileLeftButton={mobileLeftButton}
							mobileRightButtons={mobileRightButtons}
							titleButtons={titleButtons}
						/>
					))}
			</ErrorBoundary>

			<AppHeaderCtx.Provider value={ctxValue}>{children}</AppHeaderCtx.Provider>
		</>
	);
};

export default memo(AppHeader);
