import {CellProps} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {useExtractCategoriesAsMap} from '@src/core/hooks/queries/categories/hook';
import styles from './CategoriesCell.module.less';

export const CategoriesCell = ({row}: CellProps<IEnrichedStructure>) => {
	const {data: categoriesMap} = useExtractCategoriesAsMap();

	if (!row.original.categories) {
		return null;
	}
	const categories = Object.values(row.original.categories);
	if (!categoriesMap || !categories || categories?.length === 0) {
		return null;
	}
	return (
		<div>
			<div>{categoriesMap[categories[0]]?.name}</div>
			<div className={styles.cell}>
				{categories.length > 1 && categoriesMap[categories[1]]?.name}
			</div>
			{categories.length > 2 && (
				<div className={styles.cellRest}>+{categories.length - 2}</div>
			)}
		</div>
	);
};
