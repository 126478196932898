const SvgFilter = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		className="vertical-tabs__filter"
	>
		<defs>
			<filter id="sticky-filter">
				<feGaussianBlur
					in="SourceGraphic"
					stdDeviation="9"
					result="blur"
				/>
				<feColorMatrix
					in="blur"
					type="matrix"
					values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"
					result="goo"
				/>
				<feComposite
					in="SourceGraphic"
					in2="goo"
					operator="atop"
				/>
			</filter>
		</defs>
	</svg>
);

export default SvgFilter;
