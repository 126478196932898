import {CriticalMarker} from '@src/components/CriticalMarker';
import {DocumentsCount} from '@src/components/DocumentsCount';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import {useExtractCategoryById} from '@src/core/hooks/queries/categories/hook';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {PhotoPreview} from '@tehzor/ui-components';
import {CellProps} from 'react-table';

interface IDescriptionCellProps extends CellProps<IProblemEntity> {
	settings?: IEntitySettings;
}

const DescriptionCell = ({row, settings}: IDescriptionCellProps) => {
	const {data: category} = useExtractCategoryById(row.original.data.categoryId);
	const documentsCount = row.original.data.documentsCount;
	const numberEl = (
		<div className="problems-page__d-table-description-number">
			№{row.original.data.number || row.original.data.localNumber?.toUpperCase()}
		</div>
	);

	const criticalEl = row.original.data.critical && <CriticalMarker />;

	const problemTagsEl = row.original.data.problemTags?.length && (
		<ProblemTagsMarker
			className="problems-page__d-table-tags"
			colors={row.original.data.problemTags}
		/>
	);

	const descriptionEl = (
		<div className="problems-page__d-table-description-text">
			{row.original.data.description}
		</div>
	);

	const categoryEl = category && (
		<div className="problems-page__d-table-description-category">{category?.name}</div>
	);
	const documentsCountEl = documentsCount && (
		<div className="problems-page__d-table-description-documents-count">
			<DocumentsCount count={documentsCount} />
		</div>
	);

	const attachmentsEl = (
		<div className="problems-page__d-table-description-image">
			{row.original.data.attachment?.preview && (
				<PhotoPreview
					url={row.original.data.attachment.preview.url}
					data={0}
				/>
			)}
		</div>
	);

	return (
		<div className="problems-page__d-table-description">
			<div>
				<div className="problems-page__d-table-description-header">
					{numberEl}
					{settings?.builtin.critical && criticalEl}
					{settings?.builtin.problemTags && problemTagsEl}
				</div>

				{settings?.builtin.description && descriptionEl}
				{settings?.builtin.categoryId && categoryEl}
				{documentsCountEl}
			</div>
			{settings?.builtin.attachments && attachmentsEl}
		</div>
	);
};

export default DescriptionCell;
