import {t} from 'i18next';
import {IEnrichedTaskForScheduler} from '@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler';

interface IInYearProps {
	tasks: IEnrichedTaskForScheduler[];
	year: number;
}

interface IInMonthProps {
	tasks: IEnrichedTaskForScheduler[];
	month: number;
}
export const getMonthNames = (): string[] => t('tasksPage.monthsNames', {returnObjects: true});

export const getMonthIndex = (monthId: string) => getMonthNames().indexOf(monthId);

export const getMonthName = (monthIndex: number) => getMonthNames()[monthIndex];

export function filterTasksInYear(props: IInYearProps) {
	const {tasks, year} = props;

	return tasks.filter(task => {
		const start = Number(task.taskIntervalStart);
		const end = Number(task.taskIntervalEnd);

		if (!start || !end) return false;

		const startYear = new Date(start).getFullYear();
		const endYear = new Date(end).getFullYear();

		return year >= startYear && year <= endYear;
	});
}

export function filterTasksInMonth(props: IInMonthProps) {
	const {tasks, month} = props;

	return tasks.filter(task => {
		const start = Number(task.taskIntervalStart);
		const end = Number(task.taskIntervalEnd);

		if (!start || !end) return false;

		const startMonth = new Date(start).getMonth();
		const endMonth = new Date(end).getMonth();

		return month >= startMonth && month <= endMonth;
	});
}

export const inUnAssigned = (task: IEnrichedTaskForScheduler) =>
	task.taskIntervalStart === undefined ||
	task.taskIntervalEnd === undefined ||
	(task.respUsers === undefined && task.activeGroup === undefined) ||
	(!task.respUsers?.length && task.activeGroup === undefined);

export const isCurrentMonth = (monthIndex: number, currentYear?: number): boolean => {
	const currentRealYear = new Date().getFullYear();
	const currentRealMonth = new Date().getMonth();

	return currentRealMonth === monthIndex && currentRealYear === currentYear;
};

export const getMaxDaysInMonth = (year: number, month: number) => {
	const date1 = new Date(year, month, 1);
	const date2 = new Date(year, month + 1, 1);
	return Math.round((Number(date2) - Number(date1)) / 1000 / 3600 / 24);
};

export const createHeightForMonthCell = (maxTasksCountInRow: number) =>
	(Math.ceil(maxTasksCountInRow / 4) + 1) * 28;
