import {useMemo} from 'react';
import {IScheduleData} from '../../../../utils/convertTasksToScheduleFormat';
import {MonthCell} from './MonthCell';
import {ObjectCell} from './ObjectCell';
import {TasksUnAssignedCell} from './TasksUnAssignedCell';
import {ColumnDef} from '@tanstack/react-table';
import {MonthHeader} from './MonthHeader';
import {ITasksSettingsState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {DayHeader} from './DayHeader';
import {DayCell} from './DayCell';
import {createHeaderStr} from '@src/pages/TasksPage/utils/timeDayUtils';
import {getMonthNames} from '@src/pages/TasksPage/utils/timeMonthUtils';
import {WorkingGroupCell} from './WorkingGroupCell';
import {useTranslation} from 'react-i18next';

export const useInfoColumns = () => {
	const {t} = useTranslation();

	return useMemo<Array<ColumnDef<IScheduleData>>>(
		() => [
			{
				id: 'name',
				header: t('taskSchedulePage.columns.object.header'),
				accessorFn: (item: IScheduleData) => item,
				cell: ObjectCell
			},
			{
				id: 'tasks',
				header: t('taskSchedulePage.columns.tasksUnAssigned.header'),
				accessorFn: (item: IScheduleData) => item,
				cell: TasksUnAssignedCell
			}
		],
		[]
	);
};

export const useInfoColumnsForGroupMode = () => {
	const {t} = useTranslation();
	return useMemo<Array<ColumnDef<IScheduleData>>>(
		() => [
			{
				id: 'name',
				header: t('taskSchedulePage.columns.workingGroup.header'),
				accessorFn: (item: IScheduleData) => item,
				cell: WorkingGroupCell
			},
			{
				id: 'tasks',
				header: t('taskSchedulePage.columns.tasksUnAssigned.header'),
				accessorFn: (item: IScheduleData) => item,
				cell: TasksUnAssignedCell
			}
		],
		[]
	);
};

export const useBodyColumns = (settings: ITasksSettingsState) => {
	const {t} = useTranslation();

	return useMemo<Array<ColumnDef<IScheduleData>>>(() => {
		const yearColumns: Array<ColumnDef<IScheduleData>> = [];
		if (settings.currentMode === 'year') {
			for (const monthName of getMonthNames()) {
				yearColumns.push({
					id: monthName,
					header: MonthHeader,
					accessorFn: (item: IScheduleData) => item,
					cell: MonthCell
				});
			}

			return yearColumns;
		}

		if (settings.currentMode === 'month') {
			const maxDaysInMonth = new Date(settings.year, settings.month + 1, 0).getDate();
			const monthColumns: Array<ColumnDef<IScheduleData>> = [];

			for (let i = 1; i <= maxDaysInMonth; i++) {
				monthColumns.push({
					id: createHeaderStr(settings.year, settings.month, i, t),
					header: DayHeader,
					accessorFn: (item: IScheduleData) => item,
					cell: DayCell
				});
			}

			return monthColumns;
		}

		return yearColumns;
	}, [settings, t]);
};
