import {IStructureDiagramData} from '../interfaces';

export const convertDiagramMapToSortArray = (
	map: Map<string | undefined, IStructureDiagramData[]>
) =>
	Array.from(map).sort((a, b) => {
		if (a[0] && b[0]) {
			return Number(a[0]) > Number(b[0]) ? 1 : -1;
		}
		if (a[0] && !b[0]) {
			return -1;
		}
		if (!a[0] && b[0]) {
			return 1;
		}
		return -1;
	});
