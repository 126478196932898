import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICheckListsPageSettingsState} from './interfaces';
import {LastEntityAddedFromCheckList} from '@src/interfaces/LastEntityAddedFromCheckList';
import {IChangeLastAddedEntityFromCheckListsPayload} from '@src/store/interfaces/IChangeLastAddedEntityFromCheckListsPayload';

const initialState: ICheckListsPageSettingsState = {
	lastAddedEntity: LastEntityAddedFromCheckList.PROBLEM
};

export const checkListsSlice = createSlice({
	name: 'checkLists',
	initialState,
	reducers: {
		changeLastAddedEntity(
			state,
			action: PayloadAction<IChangeLastAddedEntityFromCheckListsPayload>
		) {
			const {lastAddedEntity} = action.payload;
			state.lastAddedEntity = lastAddedEntity;
		}
	}
});

export const {actions: checkListsActions, reducer: checkListsReducer} = checkListsSlice;
