import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';

export type IAddCheckListResponse = ICheckList;

/**
 * Создает чек-лист
 *
 * @param fields данные для добавления
 */
export const makeCheckListAddRequest = async (fields: ISavingCheckList) => {
	const response = await httpRequests.withToken.post<IAddCheckListResponse>(
		'check-lists',
		fields
	);
	return response.data;
};
