import {queryClient} from '@src/api/QueryClient';
import {requestEditOrderSpaceStatuses} from '@src/api/backend/spaceStatuses';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {useMutation} from '@tanstack/react-query';
import {ISpaceStatusOrder} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatusOrder';

interface IEditSpaceStatusesOrderParams {
	spaceStatusesSetId: string;
	order: ISpaceStatusOrder[];
}

export const useEditSpaceStatusesOrder = () =>
	useMutation({
		mutationFn: (params: IEditSpaceStatusesOrderParams) => {
			const {spaceStatusesSetId, order} = params;
			return requestEditOrderSpaceStatuses(spaceStatusesSetId, order);
		},
		mutationKey: spaceStatusesQueryKeys.editOrder(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesQueryKeys.list()});
		}
	});
