import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {
	IAuthorizedPerson,
	ISavingAuthorizedPerson
} from '@tehzor/tools/interfaces/authorizedPersons';

export type IEditAuthorizedPersonResponse = IAuthorizedPerson;

export const makeAuthorizedPersonEditRequest = async (
	authorizedPersonId: string,
	fields: ISavingAuthorizedPerson
) => {
	const response = await httpRequests.withToken.post<IEditAuthorizedPersonResponse>(
		`authorized-persons/${authorizedPersonId}`,
		{
			authorizedPersonId,
			...fields
		}
	);
	return response.data;
};
