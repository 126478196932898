import ProblemsCount from '@src/components/tableCells/ProblemsCount';
import ProblemTagsCount from '@src/components/tableCells/ProblemTagsCount';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface IObjectProblemProps {
	object: IParentObject;
	isDesktop?: boolean;
}

const problemIcon = <i className="tz-problem-hexagon" />;
const problemTagIcon = <i className="tz-tag-20" />;

const hasStats = (object: IParentObject, statsKey: 'problems' | 'problemTags') => {
	let result = false;

	if (object.stats?.[statsKey] && Object.keys(object.stats[statsKey]).length) {
		result = true;
	}
	if (object.children) {
		for (const child of object.children) {
			if (hasStats(child, statsKey)) {
				result = true;
			}
		}
	}
	return result;
};

export const ObjectProblems = memo(({object, isDesktop}: IObjectProblemProps) => {
	const hasProblems = useMemo(() => hasStats(object, 'problems'), [object]);
	const hasProblemTags = useMemo(() => hasStats(object, 'problemTags'), [object]);
	const {t} = useTranslation();

	return hasProblems || hasProblemTags ? (
		<EntityGrid
			withBorders
			className={{
				wrap: 'object-page__d-entities-grid',
				root: 'object-page__d-entities-grid-problems'
			}}
		>
			<EntityGridItem className={{content: 'object-page__d-entities-grid-title'}}>
				{t('objectPage.info.problems.title')}
			</EntityGridItem>
			{hasProblems && (
				<EntityGridItem
					label={t('objectPage.info.problems.title')}
					icon={problemIcon}
					inline={isDesktop}
				>
					<ProblemsCount object={object} />
				</EntityGridItem>
			)}

			{hasProblemTags && (
				<EntityGridItem
					label={t('objectPage.info.problems.tags.label')}
					icon={problemTagIcon}
					inline={isDesktop}
				>
					<ProblemTagsCount object={object} />
				</EntityGridItem>
			)}
		</EntityGrid>
	) : null;
});
