import {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {extractCurrentStructuresPageSettings} from '@/entities/Structures/model/slice/selectors';
import {IStructuresFiltersState, structuresActions} from '@/entities/Structures';

export const StructuresFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId: string}>();
	const {filters} = useAppSelector(s => extractCurrentStructuresPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const {changeFilters, clearFilters} = structuresActions;

	const applyFilters = useCallback(
		(value: IStructuresFiltersState) => {
			if (objectId) {
				dispatch(changeFilters({objectId, filters: value}));
			}
		},
		[objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId}));
	}, [objectId]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
			fullClear
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};
