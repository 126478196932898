import {MutableRefObject, useLayoutEffect} from 'react';
import {IDragPositionHandler} from '../../interfaces';
import {IDragHandlers} from '../interfaces/IDndCtx';

const triggerEventHandlers = (
	handlers: Record<string, IDragPositionHandler>,
	x?: number,
	y?: number
) => {
	for (const handler of Object.values(handlers)) {
		handler({x, y});
	}
};

export const useSetEventHandlers = (
	handlers: MutableRefObject<IDragHandlers>,
	selector?: string
) => {
	// Добавляет функции обработки события dnd на body страницы
	useLayoutEffect(() => {
		const {
			dragStartHandlers,
			dragMoveHandlers,
			dragPreviewMoveHandlers,
			dragEndHandlers,
			dragCancelHandlers
		} = handlers.current;

		const handleDragCancel = () => {
			triggerEventHandlers(dragCancelHandlers);
		};

		const handleTouchStart = (e: TouchEvent) => {
			const touch = e.touches[0];
			triggerEventHandlers(dragStartHandlers, touch.clientX, touch.clientY);
		};

		const handleTouchMove = (e: TouchEvent) => {
			const touch = e.touches[0];
			triggerEventHandlers(dragMoveHandlers, touch.clientX, touch.clientY);
			triggerEventHandlers(dragPreviewMoveHandlers, touch.clientX, touch.clientY);
		};

		const handleTouchEnd = (e: TouchEvent) => {
			const touch = e.changedTouches[0];
			triggerEventHandlers(dragEndHandlers, touch.clientX, touch.clientY);
		};

		const handleMouseDown = (e: MouseEvent) => {
			triggerEventHandlers(dragStartHandlers, e.clientX, e.clientY);
		};

		const handleMouseMove = (e: MouseEvent) => {
			triggerEventHandlers(dragMoveHandlers, e.clientX, e.clientY);
			triggerEventHandlers(dragPreviewMoveHandlers, e.clientX, e.clientY);
		};

		const handleMouseUp = (e: MouseEvent) => {
			triggerEventHandlers(dragEndHandlers, e.clientX, e.clientY);
		};

		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.code === 'Escape') {
				handleDragCancel();
			}
		};

		const element = selector ? document.querySelector(selector) : document;

		element?.addEventListener('mousedown', handleMouseDown);
		element?.addEventListener('mousemove', handleMouseMove);
		element?.addEventListener('mouseup', handleMouseUp);

		element?.addEventListener('touchstart', handleTouchStart);
		element?.addEventListener('touchmove', handleTouchMove);
		element?.addEventListener('touchend', handleTouchEnd);

		element?.addEventListener('blur', handleDragCancel);
		element?.addEventListener('keydown', handleKeyDown);

		return () => {
			element?.removeEventListener('mousedown', handleMouseDown);
			element?.removeEventListener('mousemove', handleMouseMove);
			element?.removeEventListener('mouseup', handleMouseUp);

			element?.removeEventListener('touchstart', handleTouchStart);
			element?.removeEventListener('touchmove', handleTouchMove);
			element?.removeEventListener('touchend', handleTouchEnd);

			element?.removeEventListener('blur', handleDragCancel);
			element?.removeEventListener('keydown', handleKeyDown);
		};
	}, [selector]);
};
