import {useMemo} from 'react';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {IParamAggregateEntityPermissions, useAggregateEntityPermissions} from '@src/core/hooks/permissions/useAggregateEntityPermissions';

const defaultTypes: IInternalAcceptanceAddingEntityType[] = ['problem', 'inspection'];

export function useAvailableTypes(
	objectId: string,
	types: IInternalAcceptanceAddingEntityType[] = defaultTypes,
	param?: IParamAggregateEntityPermissions
) {
	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const internalAcceptanceProblemPermissions = useAggregateEntityPermissions(objectId, 'internalAcceptanceProblemsAdd', param);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);
	const internalAcceptanceInspectionPermissions = useAggregateEntityPermissions(objectId, 'internalAcceptanceInspectionsAdd', param);

	return useMemo<IInternalAcceptanceAddingEntityType[]>(() => {
		const permissions = {
			problem: problemsPermissions.canAdd && internalAcceptanceProblemPermissions.canAdd,
			inspection: inspectionsPermissions.canAdd && internalAcceptanceInspectionPermissions.canAdd
		};
		return types.filter(t => permissions[t]);
	}, [types, problemsPermissions, inspectionsPermissions]);
}
