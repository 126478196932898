import {IGetSpaceStatusesResponse} from '@src/api/backend/spaceStatuses';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractAllSpaceStatusesAsArray,
	extractSpaceStatusesAsArray,
	extractSpaceStatusesAsArrayBySetId
} from './selectors';
import {useObjectStagesMap} from '../objectStages/hooks';
import {useCallback} from 'react';

export const useSpaceStatuses = <T = IGetSpaceStatusesResponse>(
	select?: (data: IGetSpaceStatusesResponse) => T
) =>
	useQuery<IGetSpaceStatusesResponse, IError, T>({
		queryKey: spaceStatusesQueryKeys.list(),
		meta: {error: 'при загрузке статусов помещений'},
		select
	});

export const useSpaceStatusesAsArray = () => useSpaceStatuses(extractSpaceStatusesAsArray);

export const useSpaceStatusesAsArrayBySetId = (spaceStatusesSetId: string) => {
	const {data: stages} = useObjectStagesMap();
	const selector = useCallback(
		(data: IGetSpaceStatusesResponse) =>
			extractSpaceStatusesAsArrayBySetId(data, spaceStatusesSetId, stages),
		[spaceStatusesSetId, stages]
	);
	return useSpaceStatuses(selector);
};

export const useAllSpaceStatusesAsArray = () => useSpaceStatuses(extractAllSpaceStatusesAsArray);
