import { useCallback } from 'react';
import {SwitchMenu, SwitchMenuOption} from '@tehzor/ui-components';
import {StatisticsChartType} from '@src/interfaces/StatisticsChartType';
import {useDispatch} from 'react-redux';
import {AnyAction} from 'redux';

const icons = {
	[StatisticsChartType.BAR]: <i className="tz-bar-chart"/>,
	[StatisticsChartType.PIE]: <i className="tz-pie-chart"/>,
	[StatisticsChartType.LINE]: <i className="tz-line-chart"/>
};

interface IChartTypeMenuProps {
	availableTypes: StatisticsChartType[];
	value: StatisticsChartType;

	changeAction(value: StatisticsChartType): AnyAction;
}

const ChartTypeMenu = ({availableTypes, value, changeAction}: IChartTypeMenuProps) => {
	const dispatch = useDispatch();

	const handleChange = useCallback((v: StatisticsChartType) => {
		dispatch(changeAction(v));
	}, []);

	return (
		<SwitchMenu
			className="stats-display-controls__chart-type-menu"
			value={value}
			onChange={handleChange}
		>
			{availableTypes.map(type => (
				<SwitchMenuOption
					key={type}
					value={type}
				>
					{icons[type]}
				</SwitchMenuOption>
			))}
		</SwitchMenu>
	);
};

export default ChartTypeMenu;
