import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {LinkButton, Popup, Scrollbar} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import {IconButton} from '@tehzor/ui-components/src/components/buttons';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';

interface IDescriptionPopUpProps {
	item: ICheckItem;
}

export const DescriptionPopUp = ({item}: IDescriptionPopUpProps) => {
	const [isOpen, toggle] = useToggle(false);
	return (
		<ClickPreventWrap>
			<Popup
				trigger={({ref}) => (
					<LinkButton
						label={item.name}
						onClick={toggle}
						ref={ref}
						className="structure-check-list-items__item-header-title structure-check-list-items__item-header-title_clickable"
					/>
				)}
				isOpen={isOpen}
				arrowVisible
				onOutsideClick={toggle}
				onDisappear={toggle}
				placement="bottom-start"
				className="structure-check-list-items__popup"
			>
				<div>
					<IconButton
						type="inline-blue-accent"
						className="structure-check-list-items__popup-close"
						onClick={toggle}
					>
						<i className="tz-close-20"/>
					</IconButton>
					<Scrollbar className="structure-check-list-items__description-scrollbar">
						<div className="structure-check-list-items__description">
							{item.description}
						</div>
					</Scrollbar>
				</div>
			</Popup>
		</ClickPreventWrap>
	);
};
