import { useCallback } from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/selectors';
import SelectionActions from '../selection/SelectionActions';

interface ICustomSelectionRowProps extends ISelectionRowProps<IInternalAcceptanceEntity> {
	objectId: string;
	internalAcceptanceId: string;
}

const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {objectId, internalAcceptanceId, selectedFlatRows, toggleAllRowsSelected} = props;
	const internalAcceptance = useAppSelector(extractInternalAcceptance);

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				objectId={objectId}
				internalAcceptanceId={internalAcceptanceId}
				internalAcceptance={internalAcceptance}
				selectedEntities={selectedFlatRows.map(item => item.original)}
			/>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};

export default SelectionRow;
