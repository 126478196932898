import {memo, useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {t} from 'i18next';

interface IStatus {
	id: string;
	name: string;
}

interface IStatusesFilterMobileProps {
	value?: string[];
	onChange: (selectedIdArray: IChanges) => void;
	filteredElements: IStatus[];
}

export const StatusesFilterMobile = memo(
	({value, onChange, filteredElements}: IStatusesFilterMobileProps) => {
		const [selected, setSelected] = useState(value);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleChange = useCallback(
			(selectedIdArray: string[] | undefined) => {
				onChange({statuses: selectedIdArray});
			},
			[onChange]
		);

		return (
			<MobileFilter
				label={t('entitiesFilters.statusesFilter.label')}
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
			/>
		);
	}
);
