import {usePermissions} from '../../hooks/usePermissions';
import MobileInspectionExport from './InspectionExport.mobile';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import Menu from '../Menu';

interface IMobileActionsProps {
	objectId: string;
	inspection: ILinkedInspection;
}

const MobileActions = ({objectId, inspection}: IMobileActionsProps) => {
	const permissions = usePermissions(objectId, inspection);

	return (
		<>
			{permissions.canExport ? (
				<MobileInspectionExport
					objectId={objectId}
					inspectionId={inspection.id}
				/>
			) : null}

			<Menu
				objectId={objectId}
				inspection={inspection}
			/>
		</>
	);
};

export default MobileActions;
