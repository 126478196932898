import {legalsQueryKeys} from '@src/api/cache/legals/keys';
import {
	ILegalsFiltersState,
	ILegalsPageSettingsState
} from '@src/store/modules/settings/pages/legals/reducers';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useMemo} from 'react';
import {handleFilter} from './utils/handleFilter';
import {handlePagination} from './utils/handlePagination';
import {handleSort} from './utils/handleSort';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {IGetLegalsEntityResponse} from '@src/api/backend/legals-entity/get';
import {IGetLegalEntityResponse} from '@src/api/backend/legal-entity/get';
import {useLegalsPermissions} from "@src/core/hooks/permissions/useLegalsPermissions";

interface ILegalsData {
	data: ILegalEntity[];
	pageCount: number;
	currentPage: number;
	total: number;
}

export const useLegals = () => {
	const permission = useLegalsPermissions();
	const {data} = useQuery<IGetLegalsEntityResponse, IError>({
		queryKey: [...legalsQueryKeys.list()],
		meta: {
			error: 'при загрузке юридических лиц'
		},
		enabled: permission.canView
	});

	return data;
};

export const useLegalsArray = (legals?: IGetLegalsEntityResponse) =>
	useMemo(() => legals?.allIds?.map(id => legals.byId[id]) || [], [legals]);

export const useLegalsMap = () => {
	const legals = useLegals();
	return useMemo(() => legals?.byId || {}, [legals]);
};

export const useFilteredLegalsArray = (companyIds: string[] | undefined) => {
	const legals = useLegals();
	const legalsArray = useLegalsArray(legals);
	return useMemo(() => {
		if (!companyIds?.length) {
			return [];
		}
		return legalsArray.filter(item => companyIds.includes(item.companyId));
	}, [legalsArray, companyIds]);
};

export const useLegalsData = (
	settings: ILegalsPageSettingsState,
	legals?: IGetLegalsEntityResponse
): ILegalsData => {
	const array = useLegalsArray(legals);

	return useMemo(() => {
		const {offset, pageSize, sort, filters} = settings;
		const scopeFilters: ILegalsFiltersState = {};

		const filteredArr = handleSort(array, sort)
			.filter(item => handleFilter(item, scopeFilters))
			.filter(item => handleFilter(item, filters));

		const paginatedArr = filteredArr.filter((item, i) => handlePagination(i, offset, pageSize));

		return {
			data: paginatedArr,
			pageCount: Math.ceil(filteredArr.length / pageSize),
			currentPage: Math.floor(offset / pageSize),
			total: filteredArr?.length
		};
	}, [array, settings]);
};

export const useLegal = (legalId: string) => {
	const permission = useLegalsPermissions();
	const {data} = useQuery<IGetLegalEntityResponse, IError>({
		queryKey: [...legalsQueryKeys.details(), legalId],
		meta: {
			error: 'при загрузке юрлица'
		},
		enabled: permission.canView
	});

	return data;
};
