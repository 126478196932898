import { CSSProperties, PureComponent, ReactElement, ReactNode } from 'react';
import * as React from 'react';
import {ErrorMessage} from './components';
import {formErrorMessage} from './utils/formErrorMessage';

interface IErrorBoundaryProps {
	label: string;
	children?: ReactNode;
	component?: ReactNode;

	className?: string;
	style?: CSSProperties;
}

interface IErrorBoundaryState {
	hasError: boolean;
	errorMessage?: ReactNode;
}

const extractPropsFromArray = (arr: ReactElement[]): unknown[] => {
	const props: Array<Record<string, unknown>> = [];
	for (const child of arr) {
		props.push(child?.props);
	}
	return props;
};

const extractPropsFromChildren = (children: ReactNode): unknown[] => {
	if (Array.isArray(children)) {
		return extractPropsFromArray(children as ReactElement[]);
	}
	return [(children as ReactElement)?.props];
};

export class ErrorBoundary extends PureComponent<IErrorBoundaryProps, IErrorBoundaryState> {
	constructor(props: IErrorBoundaryProps) {
		super(props);

		this.state = {
			hasError: false,
			errorMessage: undefined
		};
	}

	static getDerivedStateFromError(): IErrorBoundaryState {
		return {hasError: true};
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		this.setState({errorMessage: formErrorMessage(error, errorInfo)});
		console.log(`Произошла ошибка в компоненте: "${this.props.label}"`, {
			error, errorInfo, props: extractPropsFromChildren(this.props.children)
		});
	}

	render() {
		if (this.state.hasError) {
			return !this.props.component ? (
				<ErrorMessage
					className={this.props.className}
					style={this.props.style}
					message={this.state.errorMessage}
				/>
			) : (
				this.props.component
			);
		}

		return this.props.children || null;
	}
}