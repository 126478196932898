/**
 * Возвращает диапазон выделения текста в элементе
 *
 * @param element элемент
 */
export function getTextSelection(element: HTMLTextAreaElement): {start: number, end: number} {
	if (element.selectionStart !== undefined && element.selectionEnd !== undefined) {
		const start = element.selectionStart;
		const end = element.selectionEnd;
		return {start, end};
	}
	return {start: 0, end: 0};
}

/**
 * Устанавливает выделение в элементе
 *
 * @param element элемент
 * @param start начало выделения
 * @param end конец выделения
 */
export function setTextSelection(element: HTMLTextAreaElement, start: number, end: number) {
	if (element.selectionStart !== undefined && element.selectionEnd !== undefined) {
		element.focus();
		element.setSelectionRange(start, end);
	}
}
