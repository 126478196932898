import {DesktopMenu} from './Menu.desktop';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {EntitiesVisibility} from './EntitiesVisibility';

interface IDesktopActionsProps {
	space: ISpace;
}

export const DesktopActions = ({space}: IDesktopActionsProps) => (
	<div className="space-page__d-controls">
		<div className="space-page__d-controls-left">
			<EntitiesVisibility />
		</div>

		<div className="check-page__d-controls-right">
			<DesktopMenu space={space} />
		</div>
	</div>
);
