import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingSpace} from '@src/interfaces/saving/ISavingSpace';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

export type IEditSpaceResponse = ISpace;

/**
 * Изменяет помещение
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param fields данные помещения
 */
export const makeSpaceEditRequest = async (
	objectId: string,
	spaceId: string,
	fields: ISavingSpace
) => {
	const response = await httpRequests.withToken.put<IEditSpaceResponse>(`/spaces/${spaceId}`, {
		objectId,
		spaceId,
		...fields
	});
	return response.data;
};
