import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';

export const extractWarrantyClaimsAsArray = (data: IGetWarrantyClaimsResponse) => {
	if (!data) return undefined;
	return data.allIds.map((id: string) => data.byId[id]);
};

export const extractLocalSpaceWarrantyClaims = (data: IListWarrantyClaim[], spaceId: string) =>
	data.filter(check => check.links?.spaceId === spaceId);
