import {IMobileFilterItem} from '../MobileFilter';
import {useMemo} from 'react';
import {isActive} from './isActive';

export const useSortedElements = (items: IMobileFilterItem[] | undefined, selected?: string[]) =>
	useMemo(() => {
		if (items === undefined) {
			return [];
		}
		if (selected === undefined) {
			return items;
		}

		function compare(a: IMobileFilterItem, b: IMobileFilterItem) {
			if (isActive(a, selected) && !isActive(b, selected)) {
				return -1;
			}
			if (isActive(a, selected) && isActive(b, selected)) {
				return 0;
			}
			if (!isActive(a, selected) && isActive(b, selected)) {
				return 1;
			}
			return 0;
		}

		return [...items].sort(compare);
	}, [items, selected]);