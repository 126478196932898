import {useCallback} from 'react';
import ChecksPage from './ChecksPage';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/interfaces';
import ActionsMobile from './components/actions/Actions.mobile';
import {checksActions} from '@src/store/modules/settings/pages/checks/slice';
import {useTranslation} from 'react-i18next';

const AllChecksPageWrap = (props: object) => {
	const dispatch = useAppDispatch();
	const {t} = useTranslation();
	const {clearFilters, changeFilters, changeOffset} = checksActions;
	useAppHeader({title: t('header.checks'), mobileRightButtons: <ActionsMobile />});

	const {filters} = useAppSelector(s => extractChecksPageSettings(s, 'all'));

	const applyFilters = useCallback(
		(value: IChecksFiltersState) => {
			dispatch(changeFilters({objectId: 'all', filters: value}));
			dispatch(changeOffset({objectId: 'all', offset: 0}));
		},
		[changeFilters, changeOffset, dispatch]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: 'all'}));
	}, [clearFilters, dispatch]);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			onApply={applyFilters}
			filters={filters}
			onClear={onClear}
		>
			<ChecksPage
				key="all-checks"
				className="page-cont_extra-bottom-margin"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default AllChecksPageWrap;
