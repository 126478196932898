import {useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {DatePickerResult, InlineDateRangePicker, LinkButton} from '@tehzor/ui-components';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';

export const ActualFixDateFilterPage = () => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{
		actualFixDateFrom?: Date;
		actualFixDateTo?: Date;
	}>();
	const [from, setFrom] = useState(state.actualFixDateFrom);
	const [to, setTo] = useState(state.actualFixDateTo);
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({actualFixDateFrom: from, actualFixDateTo: to});
		goBack();
	}, [from, to, change]);

	const handleChange = useCallback((f: Date | null, t: Date | null) => {
		if (f) {
			setFrom(new Date(f.getFullYear(), f.getMonth(), f.getDate()));
		}
		if (t) {
			setTo(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999));
		}
	}, []);

	const handleClear = useCallback(() => {
		setTo(undefined);
		setFrom(undefined);
	}, []);

	useAppHeader(
		{
			title: t('problemsPage.mobileProblemCell.actualFixDate'),
			showBackBtn: true,
			mobileRightButtons:
				from && to ? (
					<LinkButton
						label={t('entitiesFilters.cancelButton.label')}
						onClick={handleClear}
					/>
				) : null
		},
		[from, to]
	);

	useRouting(!canGoBack, objectId, entity);

	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	return (
		<TranslatedFilterPage
			applyDisabled={
				isLocalStateEqual(state.actualFixDateFrom, from) &&
				isLocalStateEqual(state.actualFixDateTo, to)
			}
			onApplyClick={handleApply}
		>
			<DatePickerResult
				style={{marginTop: '20px'}}
				value1={from}
				value2={to}
				placeholder1={t('entitiesFilters.dateFilter.startDate.label')}
				placeholder2={t('entitiesFilters.dateFilter.completionDate.label')}
				isRange
				dateFormat="dd.MM.yyyy"
			/>
			<InlineDateRangePicker
				style={{marginTop: '18px'}}
				valueFrom={from}
				valueTo={to}
				onChange={handleChange}
				datesOptions={datesOptions}
			/>
		</TranslatedFilterPage>
	);
};
