import {wsConnector} from '../wsConnector';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';

export type IGetInternalAcceptanceResponse = ILinkedInternalAcceptance;

/**
 * Возвращает внутреннюю приёмку
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 */
export const requestInternalAcceptance = (objectId: string, internalAcceptanceId: string) =>
	wsConnector.sendAuthorizedRequest<IGetInternalAcceptanceResponse>('getInternalAcceptance', {objectId, internalAcceptanceId});
