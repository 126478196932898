import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISavingSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISavingSpaceStatus';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditSpaceStatusResponse = ISpaceStatus;

/**
 * Изменяет статус помещения
 *
 * @param spaceStatusId id статуса помещения
 * @param fields поля для статуса помещения
 */
export const requestEditSpaceStatus = async (spaceStatusId: string, fields: ISavingSpaceStatus) => {
	const response = await httpRequests.withToken.put<IEditSpaceStatusResponse>(
		`space-statuses/${spaceStatusId}`,
		fields
	);
	return response.data;
};
