import {createContext, useCallback, useMemo} from 'react';
import {useIsDesktop, useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {getDesktopColumns, getDesktopColumnsWithoutObject} from './desktopColumns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertInternalAcceptances} from '../../utils/convertInternalAcceptance';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IPreparedInternalAcceptance} from '../../interfaces/IPreparedInternalAcceptance';
import {mobileColumns, mobileColumnsWithoutObject} from './mobileColumns';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {getInternalAcceptancesProblemsData} from '@src/store/modules/pages/internalAcceptances/actions/getInternalAcceptancesProblemsData';
import {getInternalAcceptancesInspectionsData} from '@src/store/modules/pages/internalAcceptances/actions/getInternalAcceptancesInspectionsData';
import {
	useInternalAcceptancesAsArray,
	useInternalAcceptancesQuery,
	useLocalInternalAcceptances
} from '@src/core/hooks/queries/internalAcceptances/hooks';
import {internalAcceptancesActions} from '@src/store/modules/settings/pages/internalAcceptances/slice';
import {useUpdateEffect} from 'react-use';
import {resetInternalAcceptancesProblemsAndInspectionsStats} from '@src/store/modules/pages/internalAcceptances/reducers/reset';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {useDeleteInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useDeleteInternalAcceptance';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';

const pageSizes = [10, 20, 50, 100];

interface IInternalAcceptancesTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedInternalAcceptance>) => void
>(() => ({}));

export const InternalAcceptancesTable = ({objectId = 'all'}: IInternalAcceptancesTableProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {data: localInternalAcceptances} = useLocalInternalAcceptances(objectId);
	const {data: internalAcceptances} = useInternalAcceptancesAsArray(objectId);
	const {data: internalAcceptancesData} = useInternalAcceptancesQuery(objectId);
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const problemStats = useAppSelector(s => s.pages.internalAcceptances.problemsData.data);
	const inspectionStats = useAppSelector(s => s.pages.internalAcceptances.inspectionsData.data);
	const {data: problemStatuses} = useProblemStatuses();
	const pageSettings = useAppSelector(s => extractInternalAcceptancesPageSettings(s, objectId));
	const {mutate: deleteInternalAcceptance} = useDeleteInternalAcceptance();
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const {changePageSize, changeOffset, changeSort, changeSelectedRows} =
		internalAcceptancesActions;
	const isDesktop = useIsDesktop();
	const pagesCount = internalAcceptancesData?.total
		? Math.ceil(internalAcceptancesData.total / pageSettings.pageSize)
		: 0;
	const currentPage = internalAcceptancesData?.offset
		? Math.floor(internalAcceptancesData.offset / pageSettings.pageSize)
		: 0;
	const preparedInternalAcceptances = useMemo(() => {
		const acceptances = [...(localInternalAcceptances || []), ...(internalAcceptances || [])];
		return convertInternalAcceptances(
			acceptances,
			problemStats,
			problemStatuses,
			inspectionStats,
			networkStatus,
			user
		);
	}, [
		internalAcceptances,
		networkStatus,
		user,
		problemStats,
		problemStatuses,
		inspectionStats,
		localInternalAcceptances
	]);

	// здесь мы запрашиваем с сервера данные по количеству нарушений и осмотров для отображаемых внутренних приемок
	// каждый раз когда отображаемые приемки меняются мы запрашиваем данные, при наличии интернета конечно же
	useUpdateEffect(() => {
		if (online) {
			void dispatch(resetInternalAcceptancesProblemsAndInspectionsStats());
			const internalAcp = internalAcceptancesData?.allIds.map(id => ({
				objectId: internalAcceptancesData.byId[id].objectId,
				internalAcceptanceId: internalAcceptancesData.byId[id].id
			}));
			if (internalAcp) {
				void dispatch(
					getInternalAcceptancesProblemsData({internalAcceptances: internalAcp})
				);
				void dispatch(
					getInternalAcceptancesInspectionsData({internalAcceptances: internalAcp})
				);
			}
		}
	}, [internalAcceptancesData]);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.internalAcceptances.deleteTitle')
	});

	const handleRowClick = useCallback(
		(internalAcceptance: IPreparedInternalAcceptance) => {
			if (internalAcceptance.object) {
				pushPath(
					`/objects/${internalAcceptance.object.id}/internal-acceptances/${internalAcceptance.id}`
				);
			}
		},
		[pushPath]
	);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows({objectId, selectedRows: value})),
		[changeSelectedRows, dispatch, objectId]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort({objectId, sort: value}));
		},
		[dispatch, changeSort, objectId]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			if (internalAcceptancesData) {
				dispatch(changePageSize({objectId, pageSize: value}));
				dispatch(
					changeOffset({
						objectId,
						offset: Math.floor(internalAcceptancesData.offset / value)
					})
				);
			}
		},
		[internalAcceptancesData, dispatch, changePageSize, objectId, changeOffset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (internalAcceptancesData?.offset !== offset) {
				dispatch(changeOffset({offset, objectId}));
			}
		},
		[pageSettings.pageSize, internalAcceptancesData?.offset, dispatch, changeOffset, objectId]
	);

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IPreparedInternalAcceptance>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					deleteInternalAcceptance({
						objectId: action.payload.object.id,
						internalAcceptanceId: action.payload.id
					});
				}
			}
		},
		[getDeleteConfirmation, deleteInternalAcceptance]
	);

	const isLargeTablet = useIsLargeTablet();
	const columns = determineTableColumns(
		objectId === 'all',
		isLargeTablet,
		mobileColumns,
		mobileColumnsWithoutObject,
		getDesktopColumns(t),
		getDesktopColumnsWithoutObject(t)
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={preparedInternalAcceptances}
						selectedRows={pageSettings.selectedRows}
						sort={pageSettings.sort}
						selectable={isDesktop}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
						headVisible={isDesktop}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>

			{deleteDialog}
		</>
	);
};
