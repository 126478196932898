/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/member-ordering */
import * as React from 'react';
import './DesktopPhotoViewer.less';
import classNames from 'classnames';
import CloseButton from '../MobilePhotoViewer/components/CloseButton';
import {Dialog} from '../../dialogs';
import PhotoArrowButton from '../PhotoArrowButton';
import {AnimatePresence, motion} from 'framer-motion';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';
import debounce from 'lodash/debounce';
import ResizeObserver from 'resize-observer-polyfill';
import {convertClassNames} from '../../../utils/convertClassNames';
import {IDialogProps} from '../../dialogs/Dialog';
import DesktopPhotoEditorFooter, {
	IDesktopPhotoEditorFooterTranslations
} from '../EntitiesDesktopPhotoViewer/components/DesktopPhotoEditorFooter';
import {Canvas, ICanvasRefObject} from '../../Canvas/Canvas';
import ICanvasData from '@tehzor/tools/interfaces/ICanvasData';

const animVariants = {
	enter: (direction: boolean) => ({
		zIndex: 1,
		x: direction ? '100%' : '-100%',
		opacity: 0
	}),
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1
	},
	exit: (direction: boolean) => ({
		zIndex: 0,
		x: direction ? '-100%' : '100%',
		opacity: 0
	})
};

const animDuration = {duration: 0.35, ease: 'easeInOut'};

const defaultCloseBtn = <CloseButton />;

export interface IDesktopPhotoViewerPropsTranslations {
	desktopPhotoEditorFooter: IDesktopPhotoEditorFooterTranslations;
}

interface IDesktopPhotoViewerProps {
	className?:
		| string
		| {
				root?: string;
				layer?: string;
				body?: string;
				frame?: string;
		  };
	editable?: boolean;
	editing?: boolean;
	title?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	data: string[];
	value?: number;
	isOpen: boolean;
	closeButton?: IDialogProps['closeButton'];
	canvasBaseData?: {canvas: string; original?: string};
	translations: IDesktopPhotoViewerPropsTranslations;

	onCanvasClear?: () => void;
	onCanvasChange?: (data: ICanvasRefObject) => void;
	onSaveEdited?: () => void;
	onChange?: (index: number) => void;
	onClose?: () => void;
	onAfterOpen?: () => void;
}

interface IDesktopPhotoViewerState {
	value: number;
	direction: boolean;
	height?: number;
	width?: number;
	editing?: boolean;
	brushColor?: string;
}

class DesktopPhotoViewer extends React.PureComponent<
	IDesktopPhotoViewerProps,
	IDesktopPhotoViewerState
> {
	static defaultProps = {
		data: []
	};

	/**
	 * Корневой элемент
	 */
	private frameRef?: HTMLDivElement | null;

	/**
	 * Активное изображение
	 */
	private imageRef?: HTMLImageElement | null;

	/**
	 * Ширина корневого элемента
	 */
	private frameWidth = 0;

	/**
	 * Высота корневого элемента
	 */
	private frameHeight = 0;

	/**
	 * Позиция сверху корневого элемента
	 */
	private frameTop = 0;

	/**
	 * Позиция слева корневого элемента
	 */
	private frameLeft = 0;

	/**
	 * Текущая ширина изображения
	 */
	private imageWidth = 0;

	/**
	 * Текущая высота изображения
	 */
	private imageHeight = 0;

	private imagePrevX = 0;

	private imagePrevY = 0;

	/**
	 * Положение изображения по y
	 */
	private imageX = 0;

	/**
	 * Положение изображения по x
	 */
	private imageY = 0;

	/**
	 * Текущее увеличение активного изображения
	 */
	private imageScale = 1;

	/**
	 * Значение зума (от 1 до 20)
	 */
	private zoomValue = 1;

	/**
	 * Удаляет слушатель изменения размера элемента
	 */
	private removeResizeEventListener?: () => void;

	/**
	 * Обрабатывает событие перемещения изображения
	 */
	private handleMouseMove = rafThrottle((event: MouseEvent) => {
		const {x, y} = this.ensureImagePosition(
			this.imageX + event.clientX - this.imagePrevX,
			this.imageY + event.clientY - this.imagePrevY,
			this.imageScale
		);
		this.imageX = x;
		this.imageY = y;
		this.imagePrevX = event.clientX;
		this.imagePrevY = event.clientY;

		this.setImageTransform();
	});

	/**
	 * Обрабатывает событие изменения размера элемента
	 */
	private handleResize = debounce(() => {
		this.saveFrameSize();
		this.saveImageSize();
	}, 150);

	constructor(props: IDesktopPhotoViewerProps) {
		super(props);

		this.state = {value: props.value ?? 0, direction: false, brushColor: '#3392FF'};
	}

	static getDerivedStateFromProps(
		nextProps: IDesktopPhotoViewerProps,
		prevState: IDesktopPhotoViewerState
	) {
		if (nextProps.value !== undefined && nextProps.value !== prevState.value) {
			return {value: nextProps.value, direction: nextProps.value > prevState.value};
		}
		return null;
	}

	componentDidUpdate(prevProps: IDesktopPhotoViewerProps, prevState: IDesktopPhotoViewerState) {
		if (this.state.value !== prevState.value) {
			this.imageScale = 1;
			this.zoomValue = 1;
			this.setState({editing: false});
		}
	}

	componentWillUnmount() {
		this.removeFrameEventsListeners();
		this.imageRef = undefined;
	}

	/**
	 * Переход к следующему изображению
	 */
	next = () => {
		const {value} = this.state;
		if (value < this.props.data.length - 1) {
			this.changeValue(value + 1);
		}
	};

	/**
	 * Переход к предыдущему изображению
	 */
	prev = () => {
		const {value} = this.state;
		if (value > 0) {
			this.changeValue(value - 1);
		}
	};

	hEdit = () => {
		this.setState({editing: true});
	};

	hChangeColor = (color: string) => {
		this.setState({brushColor: color});
	};

	hCancel = () => {
		this.setState({editing: false});
	};

	hSave = () => {
		if (this.props.onSaveEdited) {
			this.props.onSaveEdited();
			this.setState({editing: false});
		}
	};

	render() {
		const {
			className,
			children,
			data,
			isOpen,
			closeButton,
			editable,
			title,
			canvasBaseData,
			translations
		} = this.props;
		const {value, direction, editing, brushColor} = this.state;

		const mode_type = !editing ? 'view' : 'edit';
		const classes = convertClassNames(className);

		const imageUrl = value >= 0 && value < data.length ? data[value] : undefined;

		return (
			<Dialog
				className={{
					root: classNames('desktop-photo-viewer', classes.root),
					layer: classNames('desktop-photo-viewer__layer', classes.layer),
					body: classNames('desktop-photo-viewer__body', classes.body),
					overlay: 'desktop-photo-viewer-overlay'
				}}
				closeButton={closeButton ?? defaultCloseBtn}
				isOpen={isOpen}
				useContentOpenAnimation
				onRequestClose={this.handleClose}
				onKeyDown={this.handleKeyDown}
				onAfterOpen={this.handleAfterOpen}
			>
				<div
					className={classNames('desktop-photo-viewer__frame', classes.frame)}
					onMouseDown={this.handleMouseDown}
					onMouseUp={this.handleMouseUp}
					ref={this.saveFrameRef}
				>
					<AnimatePresence initial={false} custom={direction}>
						{imageUrl !== undefined && (
							<motion.div
								key={value}
								className="desktop-photo-viewer__image-wrap"
								custom={direction}
								variants={animVariants}
								initial="enter"
								animate="center"
								exit="exit"
								transition={animDuration}
							>
								{editing ? (
									<Canvas
										canvasWidth={this.imageWidth}
										canvasHeight={this.imageHeight}
										brushRadius={6}
										imageUrl={canvasBaseData?.original || imageUrl}
										savedDrawData={
											canvasBaseData?.canvas
												? (JSON.parse(canvasBaseData.canvas) as ICanvasData)
												: undefined
										}
										brushColor={brushColor}
										onChange={this.props.onCanvasChange}
									/>
								) : (
									<img
										className="desktop-photo-viewer__image"
										src={imageUrl}
										alt={imageUrl}
										draggable="false"
										ref={this.saveImageRef}
									/>
								)}
							</motion.div>
						)}
					</AnimatePresence>
					<DesktopPhotoEditorFooter
						type={mode_type}
						editable={editable}
						hEdit={this.hEdit}
						title={title}
						hCancel={this.hCancel}
						hSave={this.hSave}
						hClear={this.props.onCanvasClear}
						selectedColor={brushColor}
						colors={['#3392FF', '#718198', '#FF0F31', '#FFFFFF']}
						hChangeColor={this.hChangeColor}
						translations={translations.desktopPhotoEditorFooter}
					/>
					{value > 0 && !editing && (
						<PhotoArrowButton
							className="desktop-photo-viewer__arrow-btn"
							type="left"
							onClick={this.prev}
						/>
					)}

					{value < data.length - 1 && !editing && (
						<PhotoArrowButton
							className="desktop-photo-viewer__arrow-btn"
							type="right"
							onClick={this.next}
						/>
					)}
				</div>

				{children}
			</Dialog>
		);
	}

	/**
	 * Обрабатывает закрытие диалога
	 */
	private handleClose = () => {
		this.imageX = 0;
		this.imageY = 0;
		this.imagePrevX = 0;
		this.imagePrevY = 0;
		this.imageScale = 1;
		this.zoomValue = 1;
		this.setState({editing: false});
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	/**
	 * Обрабатывает событие после открытия диалога
	 */
	private handleAfterOpen = () => {
		this.saveFrameSize();
		this.saveImageSize();
		if (this.props.onAfterOpen) {
			this.props.onAfterOpen();
		}
	};

	/**
	 * Обрабатывает событие keyDown для перелистывания стрелками
	 *
	 * @param event событие
	 */
	private handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'ArrowLeft') {
			this.prev();
		}
		if (event.key === 'ArrowRight') {
			this.next();
		}
	};

	/**
	 * Обрабатывает событие mouseDown
	 *
	 * @param event событие
	 */
	private handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
		this.imagePrevX = event.clientX;
		this.imagePrevY = event.clientY;
		window.addEventListener('mousemove', this.handleMouseMove);
		window.addEventListener('mouseup', this.handleMouseUp);
	};

	/**
	 * Обрабатывает событие mouseUp
	 */
	private handleMouseUp = () => {
		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('mouseup', this.handleMouseUp);
	};

	/**
	 * Обрабатывает событие вращения колеса мыши
	 *
	 * @param event событие
	 */
	private handleWheel = (event: WheelEvent) => {
		event.preventDefault();

		this.zoomValue += event.deltaY < 0 ? 1 : -1;
		if (this.zoomValue < 1) {
			this.zoomValue = 1;
		}
		if (this.zoomValue > 20) {
			this.zoomValue = 20;
		}

		// Вычисление точки увеличения, относительно центра фрейма
		const scalePointX = event.clientX - this.frameWidth / 2 - this.frameLeft;
		const scalePointY = event.clientY - this.frameHeight / 2 - this.frameTop;
		// Вычисление точки увеличения, относительно центра изоражения до увеличения
		const imageXBefore = (this.imageX - scalePointX) / this.imageScale;
		const imageYBefore = (this.imageY - scalePointY) / this.imageScale;
		// Вычисление нового масштаба
		this.imageScale = this.computeZoomValue(this.zoomValue);
		// Вычисление точки увеличения, относительно центра изоражения после увеличения
		const imageXAfter = imageXBefore * this.imageScale + scalePointX;
		const imageYAfter = imageYBefore * this.imageScale + scalePointY;

		const {x, y} = this.ensureImagePosition(imageXAfter, imageYAfter, this.imageScale);
		this.imageX = x;
		this.imageY = y;
		this.setImageTransform();
	};

	/**
	 * Устанавливает transform активному изображению
	 */
	private setImageTransform = () => {
		if (this.imageRef) {
			this.imageRef.style.transform = `translate(${this.imageX}px, ${this.imageY}px) scale(${this.imageScale})`;
		}
	};

	/**
	 * Вычисляет значение зума
	 *
	 * @param value позиция зума
	 */
	private computeZoomValue = (value: number): number =>
		Math.round(Math.E ** (0.1196 * value - 0.1182) * 100) / 100;

	/**
	 * Инициирует изменение значения
	 *
	 * @param value новое значение
	 */
	private changeValue = (value: number) => {
		if (this.props.onChange) {
			this.props.onChange(value);
		} else {
			this.setState(s => ({value, direction: value > s.value}));
		}
	};

	/**
	 * Проверяет позицию активного изображению, чтобы оно не выходило за рамки
	 *
	 * @param x координата по x
	 * @param y координата по y
	 * @param scale масштаб
	 */
	private ensureImagePosition = (x: number, y: number, scale: number) => {
		const result = {x, y};

		const currentImageWidth = this.imageWidth * scale;
		if (currentImageWidth > this.frameWidth) {
			const maxXOffset = Math.abs(this.frameWidth - currentImageWidth) / 2;
			if (x > maxXOffset) {
				result.x = maxXOffset;
			}
			if (x < -maxXOffset) {
				result.x = -maxXOffset;
			}
		} else {
			result.x = 0;
		}

		const currentImageHeight = this.imageHeight * scale;
		if (currentImageHeight > this.frameHeight) {
			const maxYOffset = Math.abs(this.frameHeight - currentImageHeight) / 2;
			if (y > maxYOffset) {
				result.y = maxYOffset;
			}
			if (y < -maxYOffset) {
				result.y = -maxYOffset;
			}
		} else {
			result.y = 0;
		}
		return result;
	};

	/**
	 * Сохраняет ширину корневого элемента
	 */
	private saveFrameSize = () => {
		if (this.frameRef) {
			const rect = this.frameRef.getBoundingClientRect();
			this.frameWidth = this.frameRef.offsetWidth;
			this.frameHeight = this.frameRef.offsetHeight;
			this.frameTop = rect.top;
			this.frameLeft = rect.left;
		}
	};

	/**
	 * Сохраняет размер активного изображения
	 */
	private saveImageSize = () => {
		if (this.imageRef) {
			this.imageWidth = this.imageRef.offsetWidth;
			this.imageHeight = this.imageRef.offsetHeight;
		}
	};

	/**
	 * Сохраняет ссылку на крневой элемент
	 *
	 * @param element dom-элемент
	 */
	private saveFrameRef = (element: HTMLDivElement | null) => {
		this.frameRef = element;
		this.removeFrameEventsListeners();
		this.addFrameEventsListeners();
		this.saveFrameSize();
		this.saveImageSize();
	};

	/**
	 * Сохраняет ссылку на активное изображение
	 *
	 * @param element dom-элемент
	 */
	private saveImageRef = (element: HTMLImageElement | null) => {
		if (element) {
			this.imageRef = element;
		}
		this.saveImageSize();
	};

	private addFrameEventsListeners = () => {
		if (this.frameRef) {
			this.frameRef.addEventListener('wheel', this.handleWheel, {passive: false});
			const observer = new ResizeObserver(this.handleResize);
			observer.observe(this.frameRef);
			this.removeResizeEventListener = () => observer.disconnect();
		}
	};

	private removeFrameEventsListeners = () => {
		if (this.frameRef) {
			this.frameRef.removeEventListener('wheel', this.handleWheel);
		}
		if (this.removeResizeEventListener) {
			this.removeResizeEventListener();
		}
	};
}

export default DesktopPhotoViewer;
