import {useQueryClient} from '@tanstack/react-query';
import {useAddContractsMutationDefaults} from './mutations/add';
import {useEditContractsMutationDefaults} from './mutations/edit';
import {useDeleteContractsMutationDefaults} from './mutations/delete';

export const useContractsMutationDefaults = () => {
	const queryClient = useQueryClient();

	useAddContractsMutationDefaults(queryClient);
	useEditContractsMutationDefaults(queryClient);
	useDeleteContractsMutationDefaults(queryClient);
}

