import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import { memo } from 'react';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {useExtractCheckItemsAsTree} from '@src/core/hooks/queries/checkItems/hooks';
import {CheckListHeaderActions} from '../actions/CheckListHeaderActions';
import classNames from 'classnames';
import {CategoryCount} from '../СategoryCount';
import {RecordStatusSelect} from '@src/components/CheckRecordStatusSelect/RecordStatusSelect';
import {useCheckListStatusPermission} from '@src/core/hooks/permissions/useCheckListStatusPermission';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {Indicators} from '../Indicators/Indicators';
import {CheckListType} from '../CheckListType/CheckListType';

interface ICheckListProps {
	checkList: ICheckList;
	type: CheckListTypeId;
	className?: string;
	onClick?: (id: string) => void;
}

export const CheckListMobile = memo((props: ICheckListProps) => {
	const {className, checkList, type, onClick} = props;
	const {objectId, workAcceptanceId, spaceId} = useStrictParams<{
		objectId: string;
		spaceId?: string;
		workAcceptanceId?: string;
	}>();

	const {data: checkItemsTree} = useExtractCheckItemsAsTree(checkList.id);

	const {changeCheckListRecord, isLoading} = useChangeCheckRecord(objectId, spaceId, checkList);

	const handleClick = () => {
		onClick?.(checkList.id);
	};

	const {canStatusEdit, availableStatuses} = useCheckListStatusPermission(
		type === CheckListTypeId.OPERATIONS
			? 'check-lists-space-status-edit'
			: 'check-lists-work-acceptance-status-edit',
		objectId
	);

	const disabled = !canStatusEdit || checkList.type !== type;
	return (
		<div
			className={classNames(
				'check-list-page__m-list__container',
				{
					'check-list-page__m-list__container__clickable': !!onClick
				},
				[className]
			)}
			onClick={handleClick}
			role="presentation"
		>
			<div className="check-list-page__m-check-list">
				<div
					className="check-list-page__m-list__header"
					role="presentation"
				>
					<div className="check-list-page__m-list__header-title">{checkList.name}</div>
					{checkList.type === CheckListTypeId.ACCEPTANCES && (
						<CheckListType typeId={checkList.type} />
					)}
				</div>
				<Indicators
					checkListId={checkList.id}
					workAcceptanceId={workAcceptanceId}
					spaceId={spaceId}
					className="check-list-page__m-list-indicators"
				/>
				<div className="check-list-page__m-list-status-select">
					<ClickPreventWrap className="check-list-page__d-plate-header-status-wrap">
						<RecordStatusSelect
							item={checkList}
							mutation={changeCheckListRecord}
							showLoader={isLoading}
							disabled={disabled}
							availableStatuses={availableStatuses}
						/>
					</ClickPreventWrap>
				</div>
				<CategoryCount count={checkItemsTree?.length} />
			</div>
			{checkList.type === type && (
				<ClickPreventWrap>
					<CheckListHeaderActions listId={checkList.id} />
				</ClickPreventWrap>
			)}
		</div>
	);
});
