import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import {ISavingNewAttachment} from '@src/interfaces/attachments/ISavingAttachment';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import {
	convertUploadingToLocalSaving,
	convertUploadingToSaving
} from '@src/utils/attachments/filesConverters';

export interface IUploadingFilesState {
	value: IUploadingFile[];
	error: boolean;
}

export type IUploadingFilesAction =
	| {
			type: 'update';
			value: IUploadingFile[];
	  }
	| {
			type: 'add';
			value: IUploadingFile[] | IUploadingFile;
	  }
	| {
			type: 'update-error';
	  }
	| {
			type: 'reset';
	  };

/**
 * Инициализирует состояние
 */
export const init = (): IUploadingFilesState => ({value: [], error: false});

export const reducer = (
	state: IUploadingFilesState,
	action: IUploadingFilesAction
): IUploadingFilesState => {
	switch (action.type) {
		case 'update':
			return {
				...state,
				value: action.value
			};
		case 'add':
			return {
				...state,
				value: state.value.concat(action.value)
			};
		case 'update-error':
			return {
				...state,
				error: state.value.length === 0
			};
		case 'reset':
			return init();
		default:
			return state;
	}
};

/**
 * Возвращает значение, показывающее были ли добавлены новые вложения
 *
 * @param files состояние
 */
export const isEdited = (files: IUploadingFile[]): boolean => files.length > 0;

/**
 * Проверяет есть ли файлы с состоянием error
 *
 * @param files состояние
 */
export const someFilesHaveError = (files: IUploadingFile[]) =>
	files.some(file => file.status === UploadingFileStatus.ERROR);

/**
 * Проверяет есть ли файлы с превышением размера
 *
 * @param files состояние
 */
export const someFilesHaveSizeError = (files: IUploadingFile[]) =>
	files.some(file => file.sizeError);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param files состояние
 */
export const convertToSave = (files: IUploadingFile[]): ISavingNewAttachment[] | undefined => {
	if (isEdited(files)) {
		return files.map(convertUploadingToSaving);
	}
	return undefined;
};

/**
 * Конвертирует данные в формат, пригодный для сохранения локально
 *
 * @param files состояние
 */
export const convertToLocalSave = (files: IUploadingFile[]): ISavingNewAttachment[] | undefined => {
	if (isEdited(files)) {
		return files.map(convertUploadingToLocalSaving);
	}
	return undefined;
};
