import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {DesktopAddButton} from '@src/pages/manage/CheckListsPage/components/DesktopAddButton';
import {ICheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useManageCheckListsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageCheckListsBreadcrumbs';

export const PageBreadcrumbs = ({permissions}: {permissions: ICheckListPermissions}) => {
	const breadcrumbs = useManageCheckListsBreadcrumbs();

	return (
		<AppBreadcrumbs items={breadcrumbs}>
			{permissions.canAdd ? <DesktopAddButton /> : null}
		</AppBreadcrumbs>
	);
};
