import {EntityGridItem} from '@tehzor/ui-components';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

interface IDescriptionProps {
	className?: string;
	objectId?: string;
}

const locationIcon = <i className="tz-location-20" />;

const ObjectInfo = ({className, objectId}: IDescriptionProps) => {
	const {t} = useTranslation();
	const {data: object} = useObject(objectId);

	return (
		<EntityGridItem
			className={className}
			icon={locationIcon}
			label={t('taskViewPage.objectInfo.title')}
			fullRow
		>
			<div style={{whiteSpace: 'pre-wrap'}}>{object?.name}</div>
		</EntityGridItem>
	);
};

export default ObjectInfo;
