import {IGetProblemTagsSetsResponse} from '@src/api/backend/problemTagsSets';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export const extractProblemTagsSetsAsArray = (data: IGetProblemTagsSetsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractProblemTagsSetsAsArrayForObject = (
	data: IGetProblemTagsSetsResponse,
	objectId: string
) => {
	const setsAsArr = extractProblemTagsSetsAsArray(data);
	return objectId !== 'all'
		? setsAsArr?.filter(item => item.objects.includes(objectId))
		: setsAsArr;
};

export const extractProblemTagsSetsAsFilteredArray = (
	data: IGetProblemTagsSetsResponse,
	objectId: string,
	stage: ObjectStageIds
) => {
	const setsAsArr = extractProblemTagsSetsAsArray(data);
	return setsAsArr.filter(item => item.objects.includes(objectId) && item.stages.includes(stage));
};

export const extractProblemTagsSetById = (data: IGetProblemTagsSetsResponse, setId?: string) =>
	setId ? data.byId?.[setId] : undefined;
