import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetProblemTagsSetsResponse extends INormalizedData<IProblemTagsSet> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список наборов меток нарушений
 */
export const requestProblemTagsSets = async () => {
	const response = await httpRequests.withToken.get<IGetProblemTagsSetsResponse>(
		'problem-tags-sets'
	);
	return response.data;
};
