import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IFieldSetting} from '@tehzor/tools/interfaces/fields';

export type IGetListFieldSettingsResponse = INormalizedData<IFieldSetting>;

/**
 * Возвращает список настроек полей
 */
export const requestGetListFieldSettings = async () => {
	const res = await httpRequests.withToken.get<IGetListFieldSettingsResponse>(
		'field-settings/get-list'
	);

	return res.data;
};
