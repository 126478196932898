import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckListType} from '@tehzor/tools/interfaces/checkLists/ICheckListType';

export type IGetCheckListTypesResponse = INormalizedData<ICheckListType>;

/**
 * Возвращает список типов чеклистов
 */
export const requestCheckListTypes = async () => {
	const res = await httpRequests.withToken.get<IGetCheckListTypesResponse>('check-list-types');
	return res.data;
};
