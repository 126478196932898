import {Reducer, useReducer} from 'react';
import {
	errorsFns,
	IEditableOwnerAcceptanceState,
	IEditableOwnerAcceptanceAction,
	init
} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';

export const useEditableOwnerAcceptanceState = (acceptance?: IOwnerAcceptance) =>
	useReducer<
		Reducer<IEditableOwnerAcceptanceState, IEditableOwnerAcceptanceAction>,
		IOwnerAcceptance | undefined
	>(createReducer(init, errorsFns), acceptance, init);
