import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as problemReplyTypes from '@src/store/modules/entities/problemReply/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import {IGetCheckProblemRepliesPayload} from './actions/problemReplies';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IAddProblemCommentPayload} from '@src/store/modules/entities/problemComment/actions';

const {entitiesCacheTime} = config;

export interface ICheckState {
	data?: ILinkedCheck;
	expires?: number;
	problemReplies: {
		byId: Record<string, IComment>;
		allIds: string[];
		expires?: number;
	};
}

export const getInitialState = (): ICheckState => ({
	problemReplies: {
		byId: {},
		allIds: []
	}
});

export default createReducer<ICheckState>(getInitialState(), {
	[types.GET_REQUEST]: getInitialState,
	[types.GET_PROBLEM_REPLIES_SUCCESS]: (
		state,
		{payload}: {payload: IGetCheckProblemRepliesPayload}
	) => {
		state.problemReplies.byId = payload.byId;
		state.problemReplies.allIds = payload.allIds;
		state.problemReplies.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Ответы на нарушения
	[problemReplyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemCommentPayload}) => {
		if (state.data?.id === payload.links?.checkId) {
			state.problemReplies.byId[payload.id] = payload;
			state.problemReplies.allIds.unshift(payload.id);
		}
	},
	[CLEAR_STORE]: getInitialState
});
