import {IChangedRecord} from '@src/api/cache/checkRecords/defaults/useCheckRecordsMutationDefaults';
import {IMutationErrorsMap} from '@src/interfaces/IMutationErrorsMap';
import {offlineJournalActionType} from '../../enums/actionsTypes';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';

export const exportLocalCheckRecordsChangesJournal = (
	records: IChangedRecord[],
	errorsMap: IMutationErrorsMap
) =>
	records.map(record => {
		let entityType = offlineJournalActionType.CHECK_LIST_STATUS_CHANGE;
		if (record.type === 'checkitem') {
			entityType = offlineJournalActionType.CHECK_ITEM_STATUS_CHANGE;
		}
		return {
			entity: {
				entityType,
				newRecordStatus: record.journalData.newStatus,
				checkListName: record.journalData.checkListName,
				checkItemName: record.journalData.checkItemName
			},
			object: {
				objectId: record.objectId,
				objectName: record.journalData.objectName,
				locationName: record.journalData.locationName
			},
			transfer: {
				status: record.transferStatus,
				statusMessage: record.transferStatus
					? offlineModeTransferTitles[record.transferStatus]
					: ''
			},
			createdAt: Number(record.key),
			error: errorsMap[record.key]
		} as IOfflineJournalEntity;
	});
