import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback} from 'react';
import {extractLocalInspectionsJournal} from './selector';

export const useLocalInspectionsJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: inspectionsQueryKeys.add()});
	const selector = useCallback(
		(inspections: IListInspection[]) => extractLocalInspectionsJournal(inspections, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IListInspection[], unknown, IOfflineJournalEntity[]>({
		queryKey: inspectionsQueryKeys.localList(),
		select: selector
	});
	return data || [];
};
