import {useMemo} from 'react';
import keyBy from 'lodash/keyBy';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {useObjectFieldsSettings} from '@src/core/hooks/queries/objects/hooks';

/**
 * Хук для извлечения и преобразования в объект настроек полей
 *
 * @param objectId id объекта
 * @param entity сущность, для которой нужны настройки
 */
export function useFieldsSettings(objectId: string, entity: keyof IObjectFieldsSettings) {
	const settings = useObjectFieldsSettings(objectId);

	return useMemo(() => {
		if (!settings) return {};
		return keyBy<IObjectFieldSetting>(settings[entity], 'fieldId');
	}, [settings, entity]);
}
