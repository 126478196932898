import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {TFunction} from 'i18next';

export const createCriticalDifference = (
	prev: boolean | undefined,
	next: boolean | undefined,
	t: TFunction<'translation', undefined>
): IHistoryData => {
	const criticalMessage = (
		<>
			<i
				className="tz-critical-20"
				style={{marginRight: '8px', verticalAlign: 'middle', color: '#FF0F31'}}
			/>
			Критично
		</>
	);
	const nonCriticalMessage = 'Не критично';

	return {
		prev: prev ? criticalMessage : nonCriticalMessage,
		next: next ? criticalMessage : nonCriticalMessage,
		type: HistoryTypeId.DESCRIPTION,
		name: t('createDifference.createCriticalDifference')
	};
};
