import {PrivateRoute} from '@src/containers/PrivateRoute';
import {SpaceStatusesSetsFiltersRoutingPage} from '@src/pages/filters/SpaceStatusesSetsFiltersRoutingPage';
import {SpaceStatusesSetPage} from '@src/pages/manage/SpaceStatusesSetPage/SpaceStatusesSetPage';
import {AllSpaceStatusesSetsPage} from '@src/pages/manage/SpaceStatusesSetsPage';
import {spaceStatusesSetsFilterRoutes} from './spaceStatusesSetsFilterRoutes';

export const manageSpaceStatusesSetsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllSpaceStatusesSetsPage />} />
	},
	{
		path: ':spaceStatusesSetId',
		element: <PrivateRoute element={<SpaceStatusesSetPage />} />
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<SpaceStatusesSetsFiltersRoutingPage />} />,
		children: spaceStatusesSetsFilterRoutes
	}
];
