import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {Table} from './components/Table';
import './ItemDetail.desktop.less';
import {useExtractTreeCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';

export const DesktopItemDetail = () => {
	const {listId, itemId} = useStrictParams<{
		listId: string;
		itemId: string;
	}>();

	const {data: item} = useExtractTreeCheckItemById(listId, itemId);

	if (!item) {
		return null;
	}

	return (
		<div className="check-item-detail__container">
			<div className="check-item-detail__header">
				<div className="check-item-detail__header-text">{item.name}</div>
				<div className="check-item-detail__header-actions" />
			</div>

			<div className="check-item-detail__scrollable">
				<div className="check-item-detail__problems">
					<Table item={item} />
				</div>
			</div>
		</div>
	);
};
