import {AnyAction} from 'redux';
import {IBuildingStatisticsFiltersState} from '@src/store/modules/settings/pages/statistics/building/reducers/filters';
import {IStatsSourcesState} from '@src/store/modules/statistics/sources/reducers';

export interface IStatsFiltersDialogState {
	checkedObjects: string[];
	expandedObjects?: string[];
	checkedUsers: string[];
	expandedUsers: {[key: string]: string[]};
	checkedContractors: {[key: string]: string[]};
	expandedContractors: {[key: string]: string[]};
}

const convertContractorsToState = (contractors: {[key: string]: true | string[]} = {}) => {
	const result: {[key: string]: string[]} = {};
	for (const id in contractors) {
		if (contractors.hasOwnProperty(id)) {
			result[id]
				= typeof contractors[id] === 'boolean' && contractors[id]
					? [id]
					: (contractors[id] as string[]);
		}
	}
	return result;
};

export const init = ({
	filters
}: {
	filters: IBuildingStatisticsFiltersState;
	sources: IStatsSourcesState;
}): IStatsFiltersDialogState => ({
	checkedObjects: (filters.companies || []).concat(filters.objects || []),
	expandedObjects: undefined,
	checkedUsers: filters.users || [],
	expandedUsers: {},
	checkedContractors: convertContractorsToState(filters.contractors),
	expandedContractors: {}
});

const handleDeleteAction = (state: IStatsFiltersDialogState, action: AnyAction) => {
	if (action.field === 'checkedContractors') {
		const contractors = {...state.checkedContractors};
		delete contractors[action.value];
		return {
			...state,
			checkedContractors: contractors
		};
	}
	if (action.field === 'checkedContractorsUsers') {
		const contractors = {...state.checkedContractors};
		for (const contractorId in contractors) {
			if (contractors.hasOwnProperty(contractorId)) {
				contractors[contractorId] = contractors[contractorId].filter(
					userId => userId !== action.value
				);
				if (contractors[contractorId].length === 0) {
					delete contractors[contractorId];
				}
			}
		}
		return {
			...state,
			checkedContractors: contractors
		};
	}
	return {
		...state,
		[action.field]: (state[action.field] as string[]).filter(id => id !== action.value)
	};
};

export const reducer = (
	state: IStatsFiltersDialogState,
	action: AnyAction
): IStatsFiltersDialogState => {
	switch (action.type) {
		case 'update':
			return {
				...state,
				[action.field]: action.value
			};
		case 'delete':
			return handleDeleteAction(state, action);
		case 'reset-checked':
			return {
				...init({filters: action.filters, sources: action.sources}),
				expandedObjects: state.expandedObjects,
				expandedUsers: state.expandedUsers,
				expandedContractors: state.expandedContractors
			};
		default:
			return state;
	}
};
