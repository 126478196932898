import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetPaginateInspectionsResponse extends INormalizedData<IListInspection> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список осмотров
 */
export const requestPaginateInspections = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const response = await httpRequests.withToken.post<IGetPaginateInspectionsResponse>(
		'inspections/get-paginate-inspections',
		{
			filters,
			sort: convertSortParam(sort),
			offset,
			limit
		}
	);

	return response.data;
};
