import {Breadcrumbs} from "@tehzor/ui-components";
import {IBreadcrumb} from "@tehzor/ui-components/src/components/navigation/Breadcrumbs";
import {useChangePath} from "@src/core/hooks/useChangePath";
import {useTranslation} from "react-i18next";
import {memo} from "react";

export const OfflineJournalPageBreadcrumbs = memo(() => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();

	const offlineJournalBreadcrumbs: IBreadcrumb[] = [
		{
			label: t('offlineJournalPage.offlineJournalBreadcrumbs.profile.label'),
			url: '/profile'
		},
		{
			label: t('offlineJournalPage.offlineJournalBreadcrumbs.journal.label'),
			url: '/profile/offline-journal'
		}
	];

	return (
		<Breadcrumbs
			className="offline-journal-page__header-breadcrumbs"
			items={offlineJournalBreadcrumbs}
			onNavigate={pushPath}
		/>
	)
})