import {useMemo} from 'react';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {useExtractCategoriesSetById} from '../queries/categorySets/hook';
import {useTranslation} from 'react-i18next';

export const useCategoriesSetBreadcrumbs = (objectId?: string, categoriesSetId?: string) => {
	const {t} = useTranslation();
	const {data: categoriesSet} = useExtractCategoriesSetById(categoriesSetId);
	const objects = useObjectsData();

	return useMemo(() => {
		let items: IBreadcrumb[];

		if (objectId) {
			items = objects ? formObjectsChain(objects.byId, objectId, 'problems', t) : [];
			items.push({
				label: t('categoriesSetsPage.title'),
				url: `/objects/${objectId}/categories-sets`
			});
		} else {
			items = [
				{
					label: t('categoriesSetsPage.title'),
					url: '/manage/categories-sets'
				}
			];
		}

		if (categoriesSet) {
			items.push({
				label: categoriesSet.name,
				url: objectId
					? `/objects/${objectId}/categories-sets/${categoriesSetId}`
					: `/manage/categories-sets/${categoriesSetId}`
			});
		}

		return items;
	}, [objects, categoriesSet, objectId]);
};
