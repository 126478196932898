import './SpaceTypeDecorationEditPage.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {LoadingPanel} from '@tehzor/ui-components';
import {EditPageBreadcrumbs} from '../SpaceTypeDecorationPage/components/EditPageBreadcrumbs';
import {EditingSpaceTypeDecoration} from '@src/components/EditingSpaceTypedecorationSet/EditingSpaceTypeDecoration';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import {useIsFetching} from '@tanstack/react-query';
import {useSpaceTypeDecorationsBySetIdAsArray} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';

export const SpaceTypeDecorationEditPage = () => {
	useAppHeader({
		title: 'Редактирование типов отделки помещений',
		mobileRightButtons: <AppUpdateMenu />,
		showBackBtn: true
	});
	const {typeDecorationSetId} = useStrictParams<{typeDecorationSetId: string}>();
	const {data: typeDecoration, isLoading} =
		useSpaceTypeDecorationsBySetIdAsArray(typeDecorationSetId);
	const isTypeDecorationSetsLoading = !!useIsFetching({
		queryKey: spaceTypeDecorationSetsQueryKeys.detail(typeDecorationSetId)
	});

	return (
		<div className="page-cont manage-space-type-decoration-edit-page">
			<EditPageBreadcrumbs />
			<LoadingPanel active={isTypeDecorationSetsLoading && isLoading}>
				<>
					{!isLoading && !isTypeDecorationSetsLoading && typeDecorationSetId && (
						<EditingSpaceTypeDecoration
							typeDecorationSetId={typeDecorationSetId}
							typeDecoration={typeDecoration}
						/>
					)}
				</>
			</LoadingPanel>
		</div>
	);
};
