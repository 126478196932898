import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeExportedInternalAcceptanceSendRequest} from '@src/api/backend/internalAcceptance';
import {ISendResponse} from '@src/interfaces/ISendResponse';

const request = () => ({type: types.SEND_REQUEST});

const success = () => {
	addInfoToast('Отправлено', 'Документ успешно отправлен');
	return {type: types.SEND_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при отправке');
	return {
		type: types.SEND_FAILURE,
		payload: {...error}
	};
};

/**
 * Отправляет экспортированную внутреннюю приёмку на email
 *
 * @param email email
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 * @param problems выбранные нарушения
 * @param inspections выбранные осмотры
 * @param createDocument флаг создания документа
 */
export const sendExportedInternalAcceptance = (
	email: string,
	templateId: string,
	objectId: string,
	internalAcceptanceId: string,
	problems?: string[],
	inspections?: string[],
	createDocument?: boolean
) =>
	createApiAction<ISendResponse>(request, success, failure, () =>
		makeExportedInternalAcceptanceSendRequest(
			email, templateId, objectId, internalAcceptanceId, problems, inspections, createDocument
));
