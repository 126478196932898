import {memo} from 'react';
import {ProblemIndicator, TaskIndicator} from '@tehzor/ui-components';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useProblemsCountsByStatus} from '../../hooks/useProblemsCountsByStatus';
import {useTaskStatuses} from '@src/core/hooks/queries/taskStatuses/hook';
import {useTasksCountByStatus} from '@src/core/hooks/tasks/useTasksCountByStatus';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IIndicatorsProps {
	id: string;
}
export const WorkAcceptanceIndicators = memo((props: IIndicatorsProps) => {
	const {id} = props;
	const {data: taskStatuses} = useTaskStatuses();
	const {data: problemStatuses} = useProblemStatuses();
	const problemsCount = useProblemsCountsByStatus(id);
	const tasksCounts = useTasksCountByStatus({
		workAcceptanceId: id
	});
	const isLargeTable = useIsLargeTablet();
	const className = isLargeTable
		? 'work-acceptances-page__d-table-description'
		: 'work-acceptances-page__m-table-description';

	return (
		<div className={`${className}-indicators`}>
			{problemStatuses && problemsCount && (
				<div className={`${className}__indicators`}>
					{problemsCount.map(({status, count}) =>
						count > 0 ? (
							<ProblemIndicator
								key={status}
								statuses={problemStatuses}
								status={status}
								count={count}
							/>
						) : null
					)}
				</div>
			)}
			{taskStatuses && tasksCounts && (
				<div className={`${className}__indicators`}>
					{tasksCounts.map(({status, count}) =>
						count > 0 ? (
							<TaskIndicator
								key={status}
								statuses={taskStatuses}
								status={status}
								count={count}
							/>
						) : null
					)}
				</div>
			)}
		</div>
	);
});
