import {Dispatch, useMemo} from 'react';
import './EditableSpace.less';
import Attachments from '@src/components/editableFields/Attachments';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {
	IEditableSpaceAction,
	IEditableSpaceState
} from '@src/core/hooks/states/useEditableSpaceState';
import Text from '../editableFields/Text';
import {SpaceStatus} from '../editableFields/SpaceStatus';
import SpaceTypeSelect from '@src/components/editableFields/SpaceTypeSelect';
import {SpaceTypeDecorationSelect} from '../editableFields/SpaceTypeDecorationSelect/SpaceTypeDecorationSelect';
import YesNoSelect from '../editableFields/YesNoSelect';
import ContractFormSelect from '../editableFields/ContractFormSelect';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {filterTypeDecorationsForEditingspace} from './utils/filterTypeDecoration';
import {useUpdateEffect} from 'react-use';
import {EditableDate} from '../editableFields/EditableDate';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useSpaceTypeDecorationsAsArray} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {useExtractSpaceTypeDecorationSetsAsArray} from '@src/core/hooks/queries/SpaceTypeDecorationSets/hooks';

interface IEditableSpaceProps {
	objectId: string;
	editingState: IEditableSpaceState;
	editingDispatch: Dispatch<IEditableSpaceAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableSpace = (props: IEditableSpaceProps) => {
	const {
		objectId,
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		fieldsSettings,
		saving
	} = props;

	const {t} = useTranslation();
	const {data: object} = useObject(objectId);
	const user = useAppSelector(s => s.auth.profile);

	const {data: typeDecorationsArray} = useSpaceTypeDecorationsAsArray();
	const {data: typeDecorationSets} = useExtractSpaceTypeDecorationSetsAsArray();

	const externalTypeDecorations = useMemo(() => {
		if (!object || !typeDecorationsArray || !typeDecorationSets) return undefined;
		return filterTypeDecorationsForEditingspace(
			object,
			editingState,
			typeDecorationsArray,
			typeDecorationSets
		);
	}, [editingState, object, typeDecorationSets, typeDecorationsArray]);

	// Сброс типа отделки при реактировании типа помещения
	useUpdateEffect(() => {
		editingDispatch({
			type: 'update',
			field: 'typeDecoration',
			value: undefined
		});
	}, [editingState.type]);

	const canEditExternalId =
		!!user && !!user.roles
			? hasPermission(user.roles, 'spaces-external-id-edit', UserRoleScopes.OBJECT, objectId)
			: false;

	return (
		<div className="editable-space">
			<div>
				<div className="editable-space__title">
					{t('spacePage.editableSpaceDialog.editableSpace.title.main')}
				</div>
				<div className="editable-space__info-grid">
					{fieldsSettings.name !== undefined && (
						<Text
							field="name"
							label={t('spacePage.editableSpaceDialog.editableSpace.text.name')}
							value={editingState.name}
							editingDispatch={editingDispatch}
							required={fieldsSettings.name.isRequired}
							disabled={saving}
							hasError={editingState.errors.name}
						/>
					)}

					{fieldsSettings.type !== undefined && (
						<SpaceTypeSelect
							label={t(
								'spacePage.editableSpaceDialog.editableSpace.spaceTypeSelect.label'
							)}
							value={editingState.type}
							editingDispatch={editingDispatch}
							required={fieldsSettings.type.isRequired}
							disabled={saving}
							hasError={editingState.errors.type}
							translationDictionaryKey={dictionaryKeys.spaceTypes}
						/>
					)}

					{fieldsSettings.status !== undefined && (
						<SpaceStatus
							label={t(
								'spacePage.editableSpaceDialog.editableSpace.spaceStatus.label'
							)}
							value={editingState.status}
							editingDispatch={editingDispatch}
							required={fieldsSettings.status.isRequired}
							disabled={saving}
							hasError={editingState.errors.status}
							objectId={objectId}
						/>
					)}

					{fieldsSettings.altName !== undefined && (
						<Text
							field="altName"
							label={t('spacePage.editableSpaceDialog.editableSpace.text.altName')}
							value={editingState.altName}
							editingDispatch={editingDispatch}
							required={fieldsSettings.altName.isRequired}
							disabled={saving}
							hasError={editingState.errors.altName}
						/>
					)}

					{fieldsSettings.floor !== undefined && (
						<Text
							field="floor"
							label={t('spacePage.editableSpaceDialog.editableSpace.text.floor')}
							value={editingState.floor}
							editingDispatch={editingDispatch}
							required={fieldsSettings.floor.isRequired}
							disabled={saving}
							hasError={editingState.errors.floor}
						/>
					)}

					{fieldsSettings.plannedArea !== undefined && (
						<Text
							field="plannedArea"
							label={t(
								'spacePage.editableSpaceDialog.editableSpace.text.plannedArea'
							)}
							value={editingState.plannedArea}
							editingDispatch={editingDispatch}
							required={fieldsSettings.plannedArea.isRequired}
							disabled={saving}
							hasError={editingState.errors.plannedArea}
							errorMessage="Введите число"
						/>
					)}

					{fieldsSettings.actualArea !== undefined && (
						<Text
							field="actualArea"
							label={t('spacePage.editableSpaceDialog.editableSpace.text.actualArea')}
							value={editingState.actualArea}
							editingDispatch={editingDispatch}
							required={fieldsSettings.actualArea.isRequired}
							disabled={saving}
							hasError={editingState.errors.actualArea}
							errorMessage="Введите число"
						/>
					)}

					{fieldsSettings.typeDecoration !== undefined && (
						<SpaceTypeDecorationSelect
							label={t(
								'spacePage.editableSpaceDialog.editableSpace.spaceTypeDecorationSelect.label'
							)}
							value={editingState.typeDecoration}
							editingDispatch={editingDispatch}
							externalTypeDecorations={externalTypeDecorations}
							required={fieldsSettings.typeDecoration.isRequired}
							disabled={saving}
							hasError={editingState.errors.typeDecoration}
						/>
					)}
				</div>
				{/* Дополнительная информация */}
				<div className="editable-space__title">
					{t('spacePage.editableSpaceDialog.editableSpace.title.additional')}
				</div>
				<div className="editable-space__info-grid">
					<Text
						field="areaBTI"
						label={t('spacePage.editableSpaceDialog.editableSpace.text.areaBTI')}
						value={editingState.areaBTI}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>
					<Text
						field="numberBTI"
						label={t('spacePage.editableSpaceDialog.editableSpace.text.numberBTI')}
						value={editingState.numberBTI}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>
					<Text
						field="floorBTI"
						label={t('spacePage.editableSpaceDialog.editableSpace.text.floorBTI')}
						value={editingState.floorBTI}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>
					{canEditExternalId && (
						<Text
							field="externalId"
							label={t('spacePage.editableSpaceDialog.editableSpace.text.externalId')}
							value={editingState.externalId}
							editingDispatch={editingDispatch}
							disabled={saving}
						/>
					)}
					<ContractFormSelect
						label={t(
							'spacePage.editableSpaceDialog.editableSpace.contractFormSelect.label'
						)}
						field="contractForm"
						value={editingState.contractForm}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>

					<YesNoSelect
						field="markupForRegistration"
						label={t(
							'spacePage.editableSpaceDialog.editableSpace.yesNoSelect.markupForRegistration.label'
						)}
						value={editingState.markupForRegistration}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>
					{fieldsSettings.decorationWarrantyExpiredDate !== undefined && (
						<EditableDate
							value={editingState.decorationWarrantyExpiredDate}
							editingDispatch={editingDispatch}
							required={fieldsSettings.decorationWarrantyExpiredDate.isRequired}
							hasError={editingState.errors.decorationWarrantyExpiredDate}
							createdAt={0}
							label={t(
								'spacePage.editableSpaceDialog.editableSpace.editableDate.decorationWarrantyExpiredDate.label'
							)}
							field="decorationWarrantyExpiredDate"
						/>
					)}
					{fieldsSettings.technicalEquipmentWarrantyExpiredDate !== undefined && (
						<EditableDate
							value={editingState.technicalEquipmentWarrantyExpiredDate}
							editingDispatch={editingDispatch}
							required={
								fieldsSettings.technicalEquipmentWarrantyExpiredDate.isRequired
							}
							hasError={editingState.errors.technicalEquipmentWarrantyExpiredDate}
							createdAt={0}
							label={t(
								'spacePage.editableSpaceDialog.editableSpace.editableDate.technicalEquipmentWarrantyExpiredDate.label'
							)}
							field="technicalEquipmentWarrantyExpiredDate"
						/>
					)}

					{fieldsSettings.constructiveWarrantyExpiredDate !== undefined && (
						<EditableDate
							value={editingState.constructiveWarrantyExpiredDate}
							editingDispatch={editingDispatch}
							required={fieldsSettings.constructiveWarrantyExpiredDate.isRequired}
							hasError={editingState.errors.constructiveWarrantyExpiredDate}
							createdAt={0}
							label={t(
								'spacePage.editableSpaceDialog.editableSpace.editableDate.constructiveWarrantyExpiredDate.label'
							)}
							field="constructiveWarrantyExpiredDate"
						/>
					)}
				</div>
			</div>

			<div>
				{fieldsSettings.attachments !== undefined && (
					<Attachments
						className={{
							root: 'editable-space__attachments',
							scrollArea: 'editable-space__attachments-scroll-area',
							files: 'editable-space__attachments-files',
							file: 'editable-space__attachments-file'
						}}
						attachments={editingState.attachments}
						uploadingFiles={uploadingFilesState.value}
						editingDispatch={editingDispatch}
						uploadingFilesDispatch={uploadingFilesDispatch}
						required={fieldsSettings.attachments.isRequired}
						disabled={saving}
						canDraw={false}
						hasError={editingState.errors.attachments && uploadingFilesState.error}
						showAttachBtn
					/>
				)}
			</div>
		</div>
	);
};

export default EditableSpace;
