import {
	IMigrateCategoriesSetData,
	IMigrateCategoriesSetResponse,
	requestMigrateCategoriesSet
} from '@src/api/backend/categoriesSets/migrate';
import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

export const useMigrateCategoriesSets = () =>
	useMutation<IMigrateCategoriesSetResponse, IError, IMigrateCategoriesSetData>({
		mutationFn: (params: IMigrateCategoriesSetData) => requestMigrateCategoriesSet(params),
		onError: () => {
			addErrorToast('Ошибка', 'при миграции наборов видов работ');
		}
	});
