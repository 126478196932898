import {ReactNode, useCallback} from 'react';
import * as React from 'react';
import './TreeSelectOption.less';
import SelectOption, {ISelectOptionProps} from '../SelectOption';
import classNames from 'classnames';
import IconButton from '../../../buttons/IconButton';
import {AnimatePresence, motion} from 'framer-motion';
import {ITwoWayTreeItem} from '@tehzor/tools/interfaces/ITwoWayTreeItem';
import {ITreeDataItem} from '../../../Tree';
import {useTreeCtx} from '../../../Tree/utils/TreeCtx';

export interface ITreeSelectOptionProps<T extends ITreeDataItem = ITreeDataItem>
	extends ISelectOptionProps {
	data: ITwoWayTreeItem<T>;
	getContent?: (data: ITwoWayTreeItem<T>) => ReactNode;
	level: number;
	multiple?: boolean;
	disabled?: boolean;
	latestOnly?: boolean;
}

const animationVariants = {
	opened: {opacity: 1, height: 'auto'},
	collapsed: {
		opacity: 0,
		height: 0,
		transition: {
			duration: 0.25,
			// Анимация до своих потомков необходима для избежания бага с резким
			// сворачиванием всех потомков при сворачивании родителя
			when: 'beforeChildren',
			staggerChildren: 0.5
		}
	}
};

const animationTransition = {type: 'tween', duration: 0.25};

const TreeSelectOption = ({
	data,
	getContent,
	level,
	multiple,
	disabled: treeDisabled,
	latestOnly
}: ITreeSelectOptionProps) => {
	const {id, content, children, disabled, nonCheckable} = data;

	const {isExpanded, isChecked, isSemiChecked, changeExpanded, changeChecked} = useTreeCtx();

	const expanded = isExpanded(id);
	const checked = isChecked(id);
	const semiChecked = isSemiChecked(id);
	const inputType = !nonCheckable ? (multiple ? 'checkbox' : 'radio') : null;

	const handleExpand = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			changeExpanded(id, !expanded);
		},
		[id, expanded, changeExpanded]
	);

	const handleCheck = useCallback(() => {
		changeChecked(data, !checked);
	}, [data, checked, changeChecked]);

	return (
		<li
			data-testid={`TreeItem_${level}`}
			className="tree-select-option-wrap"
		>
			<SelectOption
				dataTestId={`TreeItem_${level}`}
				className={{
					root: classNames('tree-select-option', `tree-select-option_level_${level}`),
					content: classNames('tree-select-option__content')
				}}
				content={
					<>
						{children && (
							<IconButton
								dataTestId="TreeItemExpandBtn"
								className="tree-select-option__expand-btn"
								type="transparent"
								onClick={handleExpand}
							>
								<i
									className={classNames(
										'tz-simple-arrow-20',
										'tree-select-option__expand-btn-icon',
										{
											'tree-select-option__expand-btn-icon_expanded': expanded
										}
									)}
								/>
							</IconButton>
						)}
						{getContent ? getContent(data) : content}
					</>
				}
				inputType={children && latestOnly ? null : inputType}
				checked={checked}
				semiChecked={semiChecked}
				disabled={treeDisabled || disabled}
				onClick={handleCheck}
			/>

			{children !== undefined && (
				<AnimatePresence initial={false}>
					{expanded && (
						<motion.ul
							className="tree-select-option__children"
							initial="collapsed"
							animate="opened"
							exit="collapsed"
							variants={animationVariants}
							transition={animationTransition}
						>
							{children.map(item => (
								<TreeSelectOption
									key={item.id}
									data={item}
									getContent={getContent}
									multiple={multiple}
									disabled={treeDisabled}
									level={level + 1}
								/>
							))}
						</motion.ul>
					)}
				</AnimatePresence>
			)}
		</li>
	);
};

TreeSelectOption.displayName = 'TreeSelectOption';

export default TreeSelectOption;
