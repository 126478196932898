import { useCallback, useState } from 'react';
import * as React from 'react';
import './PopupColorPicker.less';
import Popup from '../../containers/Popup';
import ColorPicker from '../ColorPicker';
import {ColorPickerButton} from '../../buttons';

interface IColorPickerProps {
	className?: string;
	style?: React.CSSProperties;
	value?: string;
	disabled?: boolean;
	presetColors?: boolean;
	onChange: (value: string) => void;
}
const array = [
	'#006DEB',
	'#7AB8FF',
	'#C2DEFF',
	'#559523',
	'#89D64D',
	'#C3EAA4',
	'#E07800',
	'#FFAC4D',
	'#FFCD94',
	'#EB0021',
	'#FF7A8D',
	'#FF9EAC',
	'#E5CD00',
	'#FFEC52',
	'#FFF499',
	'#303438',
	'#9AA6B6',
	'#D9DDE3',
	'#0A9460',
	'#3BF2AD',
	'#9FF9D7',
	'#00A5DB',
	'#47D2FF',
	'#8FE4FF',
	'#C030A8',
	'#E699D9',
	'#F4D2EE',
	'#8409E1',
	'#C580FA',
	'#E4C4FD',
	'#1320CD',
	'#7C85F3',
	'#BEC2F9',
	'#A73820',
	'#E48672'
	// '#EFB9AE'
];
const PopupColorPicker = ({
	className, style, value, disabled, onChange, presetColors
}: IColorPickerProps) => {
	const [isOpen, setOpen] = useState(false);

	const close = useCallback(() => setOpen(false), []);
	const open = useCallback(() => setOpen(true), []);

	return (
		<Popup
			trigger={({ref}) => (
				<ColorPickerButton
					className={className}
					style={style}
					color={value}
					disabled={disabled}
					onClick={open}
					ref={ref}
				/>
			)}
			isOpen={isOpen}
			onOutsideClick={close}
			onDisappear={close}
		>
			<ColorPicker
				value={value}
				onChange={onChange}
				onClose={close}
				presetColors={presetColors ? array : undefined}
			/>
		</Popup>
	);
};

export default PopupColorPicker;
