import EntitiesFilters, {
	CreatedByFilter,
	ObjectsFilter,
	CreationDateFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/interfaces';
import {useTranslation} from 'react-i18next';

interface IChecksFiltersProps {
	objectId?: string;
}

const ChecksFilters = ({objectId = 'all'}: IChecksFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IChecksFiltersState>();
	const {t} = useTranslation();

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="checks"
		>
			{objectId === 'all' && (
				<ObjectsFilter
					value={state?.objects}
					label={t('entitiesFilters.objectsFilter.label')}
				/>
			)}

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
				label={t('entitiesFilters.CreatedByFilter.label')}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
				label={t('entitiesFilters.CreationDateFilter.label')}
			/>
		</EntitiesFilters>
	);
};

export default ChecksFilters;
