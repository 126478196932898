import {IMutationErrorsMap} from '@src/interfaces/IMutationErrorsMap';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {offlineJournalActionType} from '../../enums/actionsTypes';

export const extractLocalChecksJournal = (checks: IListCheck[], errorsMap: IMutationErrorsMap) =>
	checks.map(
		check =>
			({
				entity: {
					entityId: check.id,
					entityLocalNumber: check.localNumber,
					entityType: offlineJournalActionType.CHECK_ADD
				},
				object: {
					objectId: check.object?.id,
					objectName: check.object?.name
				},
				transfer: {
					status: check.transferStatus,
					statusMessage: check.transferStatus
						? offlineModeTransferTitles[check.transferStatus]
						: ''
				},
				createdAt: check.createdAt,
				error: errorsMap[check.id]
			}) as IOfflineJournalEntity
	);
