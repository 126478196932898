import React, {useCallback, useMemo} from 'react';
import splitAttachments from '@tehzor/tools/utils/splitAttachments';
import {Tree} from 'array-to-tree';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {flattenTree} from '@src/utils/tree';
import {useEntitiesViewer} from './useEntitiesViewer';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import IAttachmentsImageData from '@tehzor/tools/interfaces/IAttachmentsImageData';
import {IEntity} from '@tehzor/ui-components/src/components/photos/EntitiesDesktopPhotoViewer/EntitiesDesktopPhotoViewer';
import {useCommentsCtx} from '@src/components/Comments/utils/CommentsCtx';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';

interface ILinkedEntity<T extends {attachments?: IAttachment[]; createdAt?: number}> {
	attachments?: T['attachments'];
	createdAt?: T['createdAt'];
}

/**
 * Хук для создания просмотрщика изображений сущности и комментариев к ней
 *
 * @param objectId id объекта
 * @param entityId id сущности
 * @param entity сущность
 * @param entityAttachmentFileDestination
 * @param commentsAttachmentFileDestination
 * @param entityType тип сушнеости
 * @param entityTitle заголовок сущности
 * @param comments комментарии
 */
export function useEntityAndCommentsViewerDesktop<
	T extends {attachments?: IAttachment[]; createdAt?: number}
>(
	objectId: string,
	entityId: string,
	entity: ILinkedEntity<T> | undefined,
	entityAttachmentFileDestination: AttachmentFileDestination,
	commentsAttachmentFileDestination: AttachmentFileDestination,
	comments: Array<Tree<IComment>>,
	entityType: string,
	entityTitle: string
): [React.ReactNode, (i: number) => void, (id: string, i: number) => void] {
	const {hasMore, getComments} = useCommentsCtx();
	// Формирование общего списка изображений
	const data = useMemo(() => {
		const result: IEntity[] = [];
		if (entity?.attachments?.length) {
			const [entityImagesAttachments] = splitAttachments(entity.attachments);
			const entityImages = entityImagesAttachments.reduce<string[]>((prev, item) => {
				if (item.full) {
					prev.push(item.full.url);
				}
				return prev;
			}, []);
			const entityImagesData: IAttachmentsImageData[] = [];
			for (const attachment of entityImagesAttachments) {
				if (attachment.full) {
					entityImagesData.push({
						id: attachment.id,
						url: attachment.full.url,
						canvas: attachment.canvas,
						original: attachment.original?.url
					});
				}
			}
			result.push({
				id: entityId || entityType,
				title: entityTitle,
				files: entityImages,
				type: entityAttachmentFileDestination,
				attachments: entityImagesData,
				date: entity.createdAt ? new Date(entity.createdAt).getTime() : undefined
			});
		}
		if (comments.length) {
			const items = flattenTree(comments);
			for (let i = items.length - 1; i >= 0; i--) {
				const item = items[i];
				if (item.attachments) {
					const [imagesAttachments] = splitAttachments(item.attachments);
					const files: string[] = [];
					const attachmentsData: IAttachmentsImageData[] = [];
					for (const attachment of imagesAttachments) {
						if (attachment.full) {
							files.push(attachment.full.url);
							attachmentsData.push({
								id: attachment.id,
								url: attachment.full.url,
								canvas: attachment.canvas,
								original: attachment.original?.url
							});
						}
					}
					if (files.length) {
						result.push({
							id: item.id,
							title: item.official
								? item.number
									? `Фото ответа №${item.number}`
									: 'Фото ответа'
								: 'Фото комментария',
							files,
							type: commentsAttachmentFileDestination,
							attachments: attachmentsData,
							date: item.createdAt
						});
					}
				}
			}
		}
		return result;
	}, [
		entity?.attachments,
		entity?.createdAt,
		comments,
		entityId,
		entityType,
		entityTitle,
		entityAttachmentFileDestination,
		commentsAttachmentFileDestination
	]);

	// Подгрузка комментариев
	const loadComments = useCallback(async () => {
		getComments();
	}, [getComments]);

	const [viewer, openImage] = useEntitiesViewer(data, false, hasMore, loadComments);

	const openEntityImage = useCallback(
		(index: number) => {
			openImage(entityId, index);
		},
		[openImage, entityId]
	);

	return [viewer, openEntityImage, openImage];
}
