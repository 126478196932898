import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import {useEffect, useMemo, useState} from 'react';

export const usePlanState = (plans: IPlan[], planId?: string): [string | undefined, string | undefined, ILayer[], (value: string) => void] => {
	const [currentPlanId, setCurrentPlanId] = useState<string | undefined>();

	useEffect(() => {
		setCurrentPlanId(planId);
	}, [planId]);

	const [image, layers] = useMemo(() => {
		let img;
		let ls: ILayer[] = [];
		if (currentPlanId && plans) {
			const plan = plans.find(item => item.id === currentPlanId);
			if (plan) {
				img = plan.fullImage?.url;
				ls = plan.layers || [];
			}
		}
		return [img, ls];
	}, [currentPlanId, plans]);

	return [currentPlanId, image, layers, setCurrentPlanId];
};
