import {IScheduleData} from '../../../../utils/convertTasksToScheduleFormat';
import {CellContext} from '@tanstack/react-table';
import classNames from 'classnames';

export const WorkingGroupCell = ({getValue, row}: CellContext<IScheduleData, IScheduleData>) => {
	const leaderName = getValue()?.leaderName;
	const position = getValue()?.respPosition;
	const isExpanded = row.getIsExpanded();
	const canExpand = row.getCanExpand();
	const name = getValue().name;
	const depth = row.depth;
	const toggleExpandedHandler = row.getToggleExpandedHandler();

	return (
		// eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
		<div
			className={classNames('manage-task-schedule-page__object-cell',
			{'manage-task-schedule-page__object-cell-pointer': canExpand})}
			onClick={toggleExpandedHandler}
			style={{
					marginLeft: depth ? `${56 * depth}px` : '24px'
			}}
			role="button"
		>
			<div
				className="manage-task-schedule-page__object-cell-expander"
				style={{
						transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'
				}}
			>
				{canExpand && <i className="tz-simple-arrow-24"/>}
			</div>
			<div
				className={
					classNames('manage-task-schedule-page__object-cell-title',
					{'manage-task-schedule-page__object-cell-title_active': !!leaderName})
				}
			>
				<span
					className={
						classNames('manage-task-schedule-page__object-cell-title-name',
						{'manage-task-schedule-page__object-cell-title-name-bold': !depth})
					}
				>
					{name}
				</span>
				<span className="manage-task-schedule-page__object-cell-objects">
					{leaderName}
					{position}
				</span>
			</div>
		</div>
	);
};
