import './SpaceTypeDecorationPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {Table as DesktopTable} from './components/table/Table.desktop';
import {MobileTable} from './components/table/Table.mobile';
import {AddButton} from './components/AddButton';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';

import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import classNames from 'classnames';

export const SpaceTypeDecorationPage = () => {
	useAppHeader({
		title: 'Наборы типов отделки помещений',
		mobileRightButtons: <AppUpdateMenu />
	});

	const isDesktop = useIsDesktop();

	return (
		<div
			className={classNames(
				'page-cont',
				isDesktop
					? 'manage-space-type-decoration-page'
					: 'manage-space-type-decoration-page_mobile'
			)}
		>
			<PageBreadcrumbs isDesktop={isDesktop} />
			{isDesktop ? (
				<DesktopTable />
			) : (
				<>
					<MobileTable />
					<AddButton />
				</>
			)}
		</div>
	);
};
