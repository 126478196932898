import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';

/**
 * Извлекает нарушения гарантийного обращения
 */
export const extractWarrantyClaimProblemsArray = (data: INormalizedData<IListProblem>) =>
	data.allIds.map(id => data.byId[id]);

/**
 * Извлекает сущности гарантийного обращения
 */
export const extractWarrantyClaimEntities = (data: INormalizedData<IListProblem>) => {
	const problems = extractWarrantyClaimProblemsArray(data);
	return problems
		.map<IWarrantyClaimEntity>(item => ({
			id: `p_${item.id}`,
			type: 'problem',
			data: item
		}))
		.sort((a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0));
};
