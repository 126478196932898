import {useMemo} from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {useIsFetching} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {getMaxDate} from '../utils/getMaxDate';
import {someHasError} from '../utils/someHasError';
import {someQueryInProgress} from '../utils/someQueryInProgress';
import {keysPermissionMapping} from '@src/core/offlineMode/cachingResorces/utils/cacheKeysPermissionMapping';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useTranslation} from 'react-i18next';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {queryClient} from '@src/api/QueryClient';

export const RestDictionariesCacheStatus = () => {
	const {t} = useTranslation();
	const isFetching = useIsFetching({queryKey: restDictionariesQueryKeys.all()});
	const roles = useAppSelector(s => s.auth.profile.roles);

	const restDictionriesCacheStates = useMemo(() => {
		const restKeys = {...restDictionariesQueryKeys, spaceOwners: spaceOwnersQueryKeys.list};
		return Object.values(restKeys)
			.slice(1)
			.filter(key => keysPermissionMapping(roles).some(item => item.join() === key().join()))
			.map(key => queryClient.getQueryState(key()));
	}, [isFetching, roles]);
	const [withError, dataUpdatedAt, inProgress] = useMemo(
		() => [
			someHasError(restDictionriesCacheStates),
			getMaxDate(restDictionriesCacheStates),
			someQueryInProgress(restDictionriesCacheStates)
		],
		[restDictionriesCacheStates]
	);

	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t(
				'components.offlineModeDialog.restDictionariesCacheStatus.offlineDataItem.title'
			)}
			icon={<i className="tz-internal-acceptance-24" />}
			date={dataUpdatedAt}
			error={withError ? t('cacheStatusErrors.error') : undefined}
			inProgress={inProgress}
			progressTitle={t(
				'components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default'
			)}
		/>
	);
};
