import './WorkAcceptanceCommentsPage.less';
import {Navigate} from 'react-router-dom';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {makeCommentTitle} from '@src/components/Comments/utils/makeCommentTitle';
import {CommentsVisibilityFilter} from '@src/components/CommentsVisibilityFilter/CommentsVisibilityFilter';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Comments} from '@src/components/Comments';
import {useEntityAndCommentsViewerMobile} from '@src/core/hooks/imagesViewers/mobile/useEntityAndCommentsViewerMobile';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {useCommentsCtx} from '@src/components/Comments/utils/CommentsCtx';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import { useTranslation } from 'react-i18next';

const WorkAcceptanceCommentsPage = () => {
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId: string;
	}>();
	useScrollRestoration();
	const {t} = useTranslation();
	const {entityPermissions, comments, visibility, changeCommentsVisibility} = useCommentsCtx();
	const filter =
		entityPermissions.canViewComments && entityPermissions.canViewReplies ? (
			<CommentsVisibilityFilter
				visibility={visibility}
				changeCommentsVisibility={changeCommentsVisibility}
				popupProps={{placement: 'bottom-end', preferX: 'right'}}
			/>
		) : null;

	useAppHeader(
		{
			title: makeCommentTitle(
				entityPermissions.canViewComments,
				entityPermissions.canViewReplies,
				t
			),
			showBackBtn: true,
			mobileRightButtons: filter
		},
		[entityPermissions, filter]
	);

	const [imagesViewer, openImage] = useEntityAndCommentsViewerMobile(
		comments,
		AttachmentFileDestination.WORK_ACCEPTANCE_COMMENT_OR_REPLY
	);

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return <Navigate to={formWorkAcceptanceLink(objectId, workAcceptanceId)} />;
	}
	return (
		<div className="page-cont problem-comments-page">
			<Comments
				className={{
					root: 'work-acceptance-comments-page__comments',
					content: 'work-acceptance-comments-page__comments-content',
					item: 'work-acceptance-comments-page__comment',
					footer: 'work-acceptance-comments-page__comments-footer',
					replyBtn: 'work-acceptance-comments-page__comments-reply-btn',
					reference: 'work-acceptance-comments-page__comments-reference',
					input: 'work-acceptance-comments-page__comments-input'
				}}
				objectId={objectId}
				onImageClick={openImage}
				commentTypeId={ICommentTypeId.WORK_ACCEPTANCE_COMMENT}
			/>

			{imagesViewer}
		</div>
	);
};

export default WorkAcceptanceCommentsPage;
