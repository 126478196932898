import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IPermissions {
	canAdd?: boolean;
	canAddTask?: boolean;
	canEdit?: boolean;
	canEditStatus?: boolean;
	canEditIndicators?: boolean;
	canDelete?: boolean;
	canGenerate?: boolean;
	canExport?: boolean;
}

/**
 * Возвращает полномочия для помещений
 *
 * @param objectId id объекта
 */
export const useSpacesPermissions = (objectId: string): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canAdd: hasPermission(user.roles, 'spaces-add', UserRoleScopes.OBJECT, objectId),
			canAddTask: hasPermission(user.roles, 'tasks-add', UserRoleScopes.OBJECT, objectId),
			canEdit: hasPermission(user.roles, 'spaces-edit', UserRoleScopes.OBJECT, objectId),
			canEditStatus: hasPermission(
				user.roles,
				'spaces-status-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canEditIndicators: hasPermission(
				user.roles,
				'spaces-indicators-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canDelete: hasPermission(user.roles, 'spaces-delete', UserRoleScopes.OBJECT, objectId),
			canGenerate: hasPermission(
				user.roles,
				'spaces-generate',
				UserRoleScopes.OBJECT,
				objectId
			),
			canExport: hasPermission(user.roles, 'spaces-export', UserRoleScopes.OBJECT, objectId)
		};
	}, [objectId, user]);
};
