import MobileDetect from 'mobile-detect';

let isMobile: boolean | undefined;

/**
 * Проверяет мобильное ли устройство используется в данный момент
 */
export function isMobileDevice() {
	if (isMobile === undefined) {
		const md = new MobileDetect(window.navigator.userAgent);
		isMobile = !!md.mobile();
	}
	return isMobile;
}
