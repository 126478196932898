import { memo, useState } from 'react';
import {EditorInfo, EntityGrid, EntityGridItem, Plate} from '@tehzor/ui-components';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import classNames from 'classnames';

interface IInfoProps {
	legal: ILegalEntity;
}

const nameIcon = <i className="tz-name-20" />;
const numberIcon = <i className="tz-number-20" />;
const createdIcon = <i className="tz-created-20" />;
const modifiedIcon = <i className="tz-modified-20" />;
const arrowIcon = <i className="tz-simple-arrow-28" />;

const fullNameTitleStyle = {content: 'legal-page__main-info__full-name-title'};
const shortNameTitleStyle = {content: 'legal-page__main-info__short-name-title'};

const mobileOpenEditorStyle = {
	content: 'legal-page__main-info__mobile-open-editor',
	root: 'legal-page__main-info__mobile-open-editor-item',
	header: 'legal-page__main-info__mobile-open-editor-header'
};

export const Info = memo(({legal}: IInfoProps) => {
	const isTablet = useIsTablet();

	const [openEditorInfo, setOpenEditorInfo] = useState(false);

	const handleClick = () => {
		setOpenEditorInfo(prev => !prev);
	};

	return (
		<div className="legal-page__main-info">
			<Plate>
				<EntityGrid withBorders>
					<EntityGridItem
						icon={nameIcon}
						label="Полное название юрлица"
						fullRow
						className={fullNameTitleStyle}
					>
						<div>{legal.name}</div>
					</EntityGridItem>

					<EntityGridItem
						icon={nameIcon}
						label="Краткое название юрлица"
						fullRow
						className={shortNameTitleStyle}
					>
						<div>{legal.shortName}</div>
					</EntityGridItem>

					{(isTablet || openEditorInfo) && (
						<>
							<EntityGridItem
								icon={numberIcon}
								label="Внешний id"
								fullRow
								className={shortNameTitleStyle}
							>
								<div>{legal.externalId}</div>
							</EntityGridItem>
							<EditorInfo
								date={legal.createdAt}
								user={legal.createdBy}
								label="Создано"
								icon={createdIcon}
								fullRow
								className="legal-page__main-info__createdAt"
							/>

							{(legal.modifiedAt || legal.modifiedBy) && (
								<EditorInfo
									date={legal.modifiedAt}
									user={legal.modifiedBy}
									label="Изменено"
									icon={modifiedIcon}
									fullRow
									className="legal-page__main-info__modifiedAt"
								/>
							)}
						</>
					)}

					{!isTablet && (
						<EntityGridItem
							className={mobileOpenEditorStyle}
							onClick={handleClick}
						>
							<button
								type="button"
								className={classNames(
									'legal-page__main-info__mobile-open-editor-btn',
									{
										'legal-page__main-info__mobile-open-editor-btn-active':
											openEditorInfo
									}
								)}
							>
								{arrowIcon}
							</button>
						</EntityGridItem>
					)}
				</EntityGrid>
			</Plate>
		</div>
	);
});
