import { memo } from 'react';
import {TextContractField} from '../fields/TextContractField';
import {IEditableContractState} from '@src/core/hooks/states/useEditableContractState';

interface INameBlockProps {
	editingState: IEditableContractState;

	onChange: (
		value: string | number | string[] | null | undefined,
		field: keyof IEditableContractState
	) => void;
	onError: (field: keyof IEditableContractState) => void;
}

export const NameBlock = memo(({
	editingState, onChange, onError
}: INameBlockProps) => (
	<div className="editable-contract__blocks-name">
		<TextContractField
			label="Название договора"
			errorMessage="Укажите название договора"
			hasError={editingState.errors.name}
			required
			value={editingState.name}
			onChange={value => onChange(value, 'name')}
			onError={() => onError('name')}
		/>
		<TextContractField
			label="Номер договора"
			errorMessage="Укажите номер договора"
			hasError={editingState.errors.number}
			value={editingState.number}
			onChange={value => onChange(value, 'number')}
			onError={() => onError('number')}
		/>
	</div>
));