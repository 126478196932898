import {useCallback} from 'react';
import {
	ISavingCheckItem,
	ISavingCheckItemDraftType
} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {useAddCheckItem} from '@src/core/hooks/mutations/checkItems/useAddCheckItem';
import {useEditCheckItem} from '@src/core/hooks/mutations/checkItems/useEditCheckItem';
import {useCopyCheckItem} from '@src/core/hooks/mutations/checkItems/useCopyCheckItem';
import {useMoveCheckItem} from '@src/core/hooks/mutations/checkItems/useMoveCheckItem';

export const useItemDraftHandler = (): ((item: ISavingCheckItem) => void) => {
	const addCheckItem = useAddCheckItem();
	const editCheckItem = useEditCheckItem();
	const copyCheckItem = useCopyCheckItem();
	const moveCheckItem = useMoveCheckItem();

	const handleSave = useCallback(
		(item: ISavingCheckItem) => {
			switch (item.draftType) {
				case ISavingCheckItemDraftType.ADDING_DRAFT:
					addCheckItem(item);
					break;

				case ISavingCheckItemDraftType.EDITING_DRAFT:
					editCheckItem(item);
					break;

				case ISavingCheckItemDraftType.COPYING_DRAFT:
					if (item.sourceItemId) {
						copyCheckItem({
							checkListId: item.checkListId,
							id: item.sourceItemId,
							subIds: item.sourceSubItemsIds
						});
					}
					break;

				case ISavingCheckItemDraftType.MOVING_DRAFT:
					moveCheckItem({
						checkListId: item.checkListId,
						id: item.id,
						parentId: item.parentId,
						order: item.order
					});
					break;

				default:
					break;
			}
		},
		[addCheckItem, copyCheckItem, editCheckItem, moveCheckItem]
	);
	return handleSave;
};
