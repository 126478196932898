/**
 * Формирует ссылку на помещение
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 */
export const formSpaceLink = (objectId?: string, spaceId?: string, subComponent?: 'work-acceptances') => {
	if (!objectId || !spaceId) return undefined;
	return `/objects/${objectId}/spaces/${spaceId}${subComponent ? `/${subComponent}` : ''}`;
};
