export function getLatestModified<P extends {modifiedAt?: number}>(data: P[]): P {
	const lasted = data.reduce(
		(last, current) =>
			((current.modifiedAt && last.modifiedAt && current.modifiedAt > last.modifiedAt)
			|| !last.modifiedAt
				? current
				: last),
		{} as P
	);
	return lasted;
}
