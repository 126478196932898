import {Dispatch, useCallback} from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useObjectsAsArray, useObjectsMap} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

interface IObjectSelectProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	field: keyof S;
	label?: string;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: string | undefined,
	types: IObject[],
	typesMap: Record<string, IObject>
) => {
	if (!value) {
		return undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

export const ObjectSelect = <S, E>({
	className,
	style,
	field,
	label,
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: IObjectSelectProps<S, E>) => {
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.objectSelect.label');
	const objects = useObjectsAsArray();
	const objectsMap = useObjectsMap();
	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	if (!objects || !objectsMap) return null;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, objects, objectsMap)}
						icon={<i className="tz-outline-object-20" />}
						error={
							required && hasError
								? t('components.editableFields.objectSelect.error')
								: undefined
						}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{objects.map(type => (
						<SelectOption
							key={type.id}
							itemKey={type.id}
							content={type.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
