import * as types from '../constants';

export interface IChangeGroupVisiblePayload {
	companyId: string;
	value?: boolean;
}

export const changeGroupVisible = (
	companyId: string, value?: boolean
) => ({
	type: types.CHANGE_GROUP_VISIBLE,
	payload: {companyId, value} as IChangeGroupVisiblePayload
});