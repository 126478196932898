import {tasksQueryKeys} from "@src/api/cache/tasks/keys";
import {ISavingTask} from "@src/interfaces/saving/ISavingTask";
import {makeTaskAddRequest} from "@src/api/backend/task";
import {addErrorToast} from "@src/utils/toasts";
import {useMutation} from "@tanstack/react-query";
import {queryClient} from "@src/api/QueryClient";

export const useAddTask = () => useMutation({
	mutationKey: tasksQueryKeys.add(),
	mutationFn: (fields: ISavingTask) => makeTaskAddRequest(fields),
	onError: () => addErrorToast('Ошибка', 'при добавлении задачи'),
	onSuccess: () => {
		void queryClient.invalidateQueries({queryKey: tasksQueryKeys.all()})
	}
})