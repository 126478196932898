import classNames from 'classnames';
import { ReactNode, RefObject, forwardRef, memo } from 'react';
import {Portal} from '../../../containers';
import {IDndDragState, IDndItem} from '../../interfaces';
import {IDndRenderPreviewProps} from '../interfaces/IDndRenderPreviewProps';

interface IDndPreviewProps<S> {
	className?: string;
	state: IDndDragState<S>;
	renderPreview?: (props: IDndRenderPreviewProps<S>) => ReactNode;
}

const forwardRefDndPreview = forwardRef(<S extends IDndItem>(
	{className, state, renderPreview}: IDndPreviewProps<S>,
	ref: RefObject<HTMLDivElement>
) =>
	(state.isDragging && renderPreview ? (
		<Portal>
			<div
				className={classNames(className, 'dnd-context__preview')}
				ref={ref}
			>
				{renderPreview(state)}
			</div>
		</Portal>
	) : null));

export const DndPreview = memo(forwardRefDndPreview);