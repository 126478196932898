import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IGetPaginateProblemsResponse} from '@src/api/backend/problems';
import {convertLocalAttachments} from '@src/utils/convertLocalAttachments';
import {ISavingProblemQuery} from '../../mutations/problem/useAddProblem';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {IGetCommentsResponse} from '@src/api/backend/comments';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

export const extractProblemCommentsAsArray = (data: IGetCommentsResponse) => {
	if (!data) return undefined;
	return data.allIds.map(id => data.byId[id]);
};

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractOwnerAcceptanceProblemCommentsByProblem = (
	data: IGetCommentsResponse
) => {
	const problemsAsArray = extractProblemCommentsAsArray(data);

	if (!problemsAsArray) return undefined;

	return problemsAsArray.reduce<Record<string, IComment>>((prev, item) => {
		if (item.links?.problemId && !prev[item.links.problemId]) {
			prev[item.links.problemId] = item;
		}
		return prev;
	}, {});
};

export const extractLocalSpaceProblems = (data: IListProblem[], spaceId: string) =>
	data.filter(check => check.links?.spaceId === spaceId);

export const extractProblemsList = (data: IGetPaginateProblemsResponse) => ({
	problems: data?.allIds.map(id => data.byId[id]),
	total: data.total,
	allIds: data.allIds
});

export const problemsListWithAttachments = (data: IListProblem[]) =>
	data.map(localProblem => ({
		...localProblem,
		attachments: convertLocalAttachments<ISavingProblemQuery>(
			localProblem.id,
			problemsQueryKeys
		)
	}));
