import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import IProblemsByStatusesStatsItem from '@tehzor/tools/interfaces/statistics/IProblemsByStatusesStatsItem';

export type StatsByStatus = Record<ProblemStatusId, number | undefined>;

export interface StatsMap {
	[key: string]: StatsByStatus;
}

export interface ContractorsStatsMap {
	[contractorId: string]: {
		stats: StatsByStatus;
		usersStats: StatsMap;
	};
}

export const byCompanies = (data: IProblemsByStatusesStatsItem[]) =>
	data.reduce<StatsMap>((prev, item) => {
		if (!prev[item.companyId]) {
			prev[item.companyId] = {} as StatsByStatus;
		}
		const statusId = item.status;
		const expired = 'expired';
		prev[item.companyId][statusId] = (prev[item.companyId][statusId] || 0) + item.count;
		if (item.isExpired) {
			prev[item.companyId][expired] = (prev[item.companyId][expired] || 0) + item.count;
		}
		return prev;
	}, {});

export const byObjects = (data: IProblemsByStatusesStatsItem[]) =>
	data.reduce<StatsMap>((prev, item) => {
		const statusId = item.status;
		const expired = 'expired';
		for (const objectId of item.objects) {
			if (!prev[objectId]) {
				prev[objectId] = {} as StatsByStatus;
			}
			prev[objectId][statusId] = (prev[objectId][statusId] || 0) + item.count;
			if (item.isExpired) {
				prev[objectId][expired] = (prev[objectId][expired] || 0) + item.count;
			}
		}
		return prev;
	}, {});

export const byUsers = (data: IProblemsByStatusesStatsItem[]) =>
	data.reduce<StatsMap>((prev, item) => {
		if (item.userId) {
			if (!prev[item.userId]) {
				prev[item.userId] = {} as StatsByStatus;
			}
			const statusId = item.status;
			const expired = 'expired';
			prev[item.userId][statusId] = (prev[item.userId][statusId] || 0) + item.count;
			if (item.isExpired) {
				prev[item.userId][expired] = (prev[item.userId][expired] || 0) + item.count;
			}
		}
		return prev;
	}, {});

export const byContractors = (data: IProblemsByStatusesStatsItem[]) =>
	data.reduce<ContractorsStatsMap>((prev, item) => {
		if (!item.contractors) {
			return prev;
		}
		const statusId = item.status;
		const expired = 'expired';
		// массив компаний подрядчиков в item чтобы убрать сложение count в рамках одного item у подрядчиков с одинаковым companyId
		const itemContractorsCompanies: string[] = [];

		for (const c of item.contractors) {
			if (!prev[c.companyId]) {
				prev[c.companyId] = {
					stats: {} as StatsByStatus,
					usersStats: {}
				};
			}

			if (!prev[c.companyId].usersStats[c.userId]) {
				prev[c.companyId].usersStats[c.userId] = {} as StatsByStatus;
			}

			prev[c.companyId].stats[statusId]
				= (prev[c.companyId].stats[statusId] || 0)
				+ (itemContractorsCompanies.includes(c.companyId) ? 0 : item.count);

			prev[c.companyId].usersStats[c.userId][statusId]
				= (prev[c.companyId].usersStats[c.userId][statusId] || 0) + item.count;

			if (item.isExpired) {
				prev[c.companyId].stats[expired]
					= (prev[c.companyId].stats[expired] || 0)
					+ (itemContractorsCompanies.includes(c.companyId) ? 0 : item.count);

				prev[c.companyId].usersStats[c.userId][expired]
					= (prev[c.companyId].usersStats[c.userId][expired] || 0) + item.count;
			}
			itemContractorsCompanies.push(c.companyId);
		}
		return prev;
	}, {});
