import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';

export const hasLocationComments = (location: ILocation | undefined) => {
	let finalElements: ILocationMarker[] = [];

		if (location?.sectors?.length) {
			finalElements = location.sectors;
		}

		if (location?.points?.length) {
			finalElements = location.points;
		}

	return finalElements.some(el => el?.description?.trim());
};