import {queryClient} from '@src/api/QueryClient';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {findMutation} from './findMutation';
import IError from '@tehzor/tools/interfaces/IError';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {IAddCheckParams} from '@src/api/cache/checks/defaults/useChecksMutationDefaults';
import {checksQueryKeys} from '@src/api/cache/checks/keys';

export const transferCheck = (entity: IOfflineJournalEntity['entity']) => {
	const {entityId} = entity;
	const mutationCache = queryClient.getMutationCache();
	const [[, cachedData]] = queryClient.getQueriesData<ICheck>({
		queryKey: checksQueryKeys.detail(entityId)
	});

	if (cachedData) {
		const {objectId, stage, links, id} = cachedData;
		const currentMutation = findMutation(id);
		if (currentMutation) {
			void currentMutation.execute({objectId, stage, links, key: id});
			return;
		}
		const mutation = mutationCache.build<unknown, IError, IAddCheckParams, unknown>(
			queryClient,
			{
				mutationKey: checksQueryKeys.add()
			}
		);
		void mutation.execute({objectId, stage, links, key: id});
	}
};
