import {fieldsSettingsSetsAction} from '@/entities/FieldSettingsSets';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {IPaginateSettings} from '@/shared/types/IPaginate';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

interface ISelectedProps {
	preparedFieldSettingsSets: IPreparedFieldSettingsSet[];
	settings: IPaginateSettings;
}

export const useSelected = ({preparedFieldSettingsSets, settings}: ISelectedProps) => {
	const dispatch = useAppDispatch();
	const selectedEntities = preparedFieldSettingsSets.filter(field =>
		settings.selectedRows.includes(field.id)
	);

	const handleSelectedRowsChange = (value: string[]) =>
		dispatch(fieldsSettingsSetsAction.changeSelectedRows(value));

	const clearSelectedRowsChange = () => handleSelectedRowsChange([]);

	return {selectedEntities, handleSelectedRowsChange, clearSelectedRowsChange};
};
