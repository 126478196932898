import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

/**
 * Возвращает общие настройки страницы задач
 */
export const extractTasksDisplayMode = createSelector(
	(state: IState) => state.settings.pages.tasks.general,
	data => data.displayMode
);

/**
 * Возвращает метод отображения в графике задач
 */
export const extractTasksCollectMode = createSelector(
	(state: IState) => state.settings.pages.tasks.schedule,
	data => data.collectBy
);
/**
 * Возвращает настройки страницы реестра задача
 */
export const extractTasksListSettings = createSelector(
	(state: IState) => state.settings.pages.tasks.list,
	data => data
);

/**
 * Возвращает настройки страницы графика задач
 */
export const extractTasksScheduleSettings = createSelector(
	(state: IState) => state.settings.pages.tasks.schedule,
	data => data
);