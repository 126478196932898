import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';
import {useDeleteLegal} from './useDeleteLegal';

export const useDeleteLegals = () => async (legals: IPreparedLegal[]) => {
	const deleteLegal = useDeleteLegal();

	for (const legal of legals) {
		await deleteLegal(legal.id);
	}
};
