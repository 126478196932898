import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {Column} from 'react-table';
import {IPreparedSpaceStatusesSet} from '../../interfaces/IPreparedSpaceStatusesSet';
import {CompanyCell} from './cells/CompanyCell';
import {DeleteCell} from './cells/DeleteCell';
import {ModifiedCell} from './cells/ModifiedCell';
import {NameCell} from './cells/NameCell';
import {ObjectsCell} from './cells/ObjectsCell';
import {UserCell} from './cells/UserCell';

export const desktopColumns: Array<Column<IPreparedSpaceStatusesSet>> = [
	{
		id: 'name',
		Header: 'Название набора',
		accessor: 'name',
		Cell: NameCell,
		width: 80
	},
	{
		id: 'company',
		Header: 'Компания',
		accessor: 'companyId',
		Cell: CompanyCell,
		width: 80
	},
	{
		id: 'objects',
		Header: 'Объекты',
		accessor: 'objects',
		Cell: ObjectsCell,
		width: 90
	},
	{
		id: 'createdBy.displayName',
		Header: 'Автор',
		accessor: 'createdBy',
		Cell: UserCell,
		width: 80
	},
	{
		id: 'createdAt',
		Header: 'Создано',
		accessor: row => (row.createdAt
			? format(row.createdAt, dateTimeCommaSeparatedFormat)
			: ''),
		width: 80
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		width: 80,
		accessor: 'modifiedAt',
		Cell: ModifiedCell
	},
	{
		id: 'delete',
		Cell: DeleteCell,
		Header: '',
		width: 70,
		disableResizing: true,
		isNonClickable: true
	}
];
