import {Dispatch, memo, useCallback} from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {
	useOwnerAcceptancesResolutionsArray,
	useOwnerAcceptancesResolutionsMap
} from '@src/core/hooks/queries/ownerAcceptancesResolutions/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';

interface IResolutionProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: OwnerAcceptanceResolutionId;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const Resolution = <S extends {resolution?: OwnerAcceptanceResolutionId}, E>(
	props: IResolutionProps<S, E>
) => {
	const {
		className,
		style,
		label = 'Решение (опционально)',
		value,
		editingDispatch,
		required,
		disabled,
		hasError,
		...restProps
	} = props;

	const {data: resolutions} = useOwnerAcceptancesResolutionsArray();
	const {data: resolutionsMap} = useOwnerAcceptancesResolutionsMap();
	const translatedResolutions = useTranslatedDictionaryArray(
		dictionaryKeys.ownerAcceptanceResolutions,
		resolutions
	);
	const translatedResolutionsMap = useTranslatedDictionaryMap(
		dictionaryKeys.ownerAcceptanceResolutions,
		resolutionsMap
	);

	const handleChange = useCallback(
		(v: OwnerAcceptanceResolutionId) => {
			editingDispatch({type: 'update', field: 'resolution', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'resolution'});
			}
		},
		[required]
	);

	if (!translatedResolutions || !translatedResolutionsMap) return null;

	return (
		<div
			className={className}
			style={style}
			{...restProps}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<SelectPopup
				trigger={
					<TextFieldWithForwardedRef
						value={value ? translatedResolutionsMap[value]?.name : ''}
						elementType="div"
						// elementProps={textAreaProps}
						error={required && hasError ? 'Выберите решение' : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{translatedResolutions.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};

export default memo(Resolution) as typeof Resolution;
