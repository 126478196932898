export type IGetProblemsDocumentsStatsQueryKeyList = [] & {
	0: string; // problems-documents-stats
	1: string; // list
	2: string[]; // problem ids
}

export const problemsDocumentsStatsQueryKeys = {
	all: () => ['problems-documents-stats'],
	lists: () => [...problemsDocumentsStatsQueryKeys.all(), 'list'],
	list: (ids: string[]) => [...problemsDocumentsStatsQueryKeys.lists(), ids],
	localList: () => [...problemsDocumentsStatsQueryKeys.all(), 'local-list'],
};