import {CellProps} from 'react-table';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';

const CompanyNameCell = ({row}: CellProps<IObject>) => {
	const object = row.original;
	const {data: companies} = useCompaniesAsArray();

	const comp = companies?.find(el => el.id === object.companyId);
	return comp?.name ? <div>{comp.name}</div> : null;
};

export default CompanyNameCell;
