import {CellProps} from 'react-table';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

const SpaceTypeCell = ({row}: CellProps<IConvertedSpace>) => {
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const spaceTypeId = spaceTypes?.[row.original.type]?.id;

	const spaceTypeName = useTranslatedDictionaryById(dictionaryKeys.spaceTypes, spaceTypeId);

	return <>{spaceTypeName}</>;
};

export default SpaceTypeCell;
