export type IAuthorizedPersonsListQueryKey = [] & {
	0: string;
};

export const authorizedPersonsQueryKeys = {
	all: () => ['authorizedPersons'],
	one: () => ['authorizedPersons'],
	list: () => [...authorizedPersonsQueryKeys.all(), 'list'],
	localList: () => [...authorizedPersonsQueryKeys.all(), 'local-list'],
	details: () => [...authorizedPersonsQueryKeys.one(), 'detail'],
	savingData: (key: string) => [...authorizedPersonsQueryKeys.one(), 'saving-data', key],
	detail: (authorizedPersonId: string) => [...authorizedPersonsQueryKeys.one(), 'detail', authorizedPersonId],
	add: () => [...authorizedPersonsQueryKeys.one(), 'add'],
	edit: () => [...authorizedPersonsQueryKeys.one(), 'edit']
};