import { useCallback } from 'react';
import * as React from 'react';
import classNames from 'classnames';

interface ITableCellProps<D extends unknown> {
	className?: string;
	style?: React.CSSProperties;
	role?: string;
	children?: React.ReactNode;
	data?: D;
	header?: React.ReactNode;
	nonClickable?: boolean;

	onClick?: (data: D) => void;
}

const TableMobileCell = <D extends unknown>(props: ITableCellProps<D>) => {
	const {className, style, role, children, data, header, nonClickable, onClick, ...restProps} = props;

	const handleClick = useCallback(() => {
		if (onClick && data) {
			onClick(data);
		}
	}, [data, onClick]);

	return (
		<div
			className={classNames(
				'me-table__cell',
				{'me-table__cell_non-clickable': nonClickable},
				className
			)}
			style={style}
			role={role}
			onClick={nonClickable ? undefined : handleClick}
			{...restProps}
		>
			{!!header && (
				<div className="me-table__cell-header">
					{header}
				</div>
			)}
			{children}
		</div>
	);
};

export default TableMobileCell;