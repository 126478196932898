import {UserAvatar} from '../../../various';
import {format} from 'date-fns';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

interface IUserActionInfoProps {
	createdBy?: IBriefUser;
	createdAt?: number;
	labelTitle?: string;
	labelIn?: string;
	dateFormat?: string;
	dateOptions?: Parameters<typeof format>[2];
}

export const UserActionInfo = (props: IUserActionInfoProps) => {
	const {
		createdBy,
		createdAt,
		labelTitle = 'назначил(а) ответственного',
		labelIn = 'в',
		dateFormat = 'HH:mm',
		dateOptions
	} = props;

	return (
		<div className="delegation-history-item__info">
			<UserAvatar
				className="delegation-history-item__info-avatar"
				user={createdBy}
				size="34"
				coloring="text"
			/>
			<div className="delegation-history-item__info-text">
				<div className="delegation-history-item__info-author">{createdBy?.displayName}</div>
				<div className="delegation-history-item__info-action">
					{labelTitle}
					{createdAt !== undefined ? (
						<>
							{' '}
							{labelIn}
							{' '}
							{format(createdAt, dateFormat, dateOptions)}
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};
