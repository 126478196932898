import {useCallback, useState} from 'react';
import {addSuccessToast} from '@src/utils/toasts';
import {ActionButtons, Button, Plate} from '@tehzor/ui-components';
import './EditingSpaceTypeDecoration.less';
import {useEditableSpaceTypeDecoration} from '../EditableSpaceTypeDecoration/hooks/useEditableSpaceTypeDecoration';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {useSpaceTypeDecorationSet} from '@src/core/hooks/queries/SpaceTypeDecorationSet';
import {useEditSpaceTypeDecorationSet} from '@src/core/hooks/mutations/spaceTypeDecorationSets/useEditSpaceTypeDecorationSet';
import {useEditSpaceTypeDecoration} from '@src/core/hooks/mutations/spaceTypeDecorations/useEditSpaceTypeDecoration';

interface IEditingSpaceTypeDecorationProps {
	typeDecorationSetId: string;
	typeDecoration: ISpaceTypeDecoration[] | undefined;
}

export const EditingSpaceTypeDecoration = ({
	typeDecorationSetId,
	typeDecoration
}: IEditingSpaceTypeDecorationProps) => {
	const [saving, setSaving] = useState(false);
	const {mutateAsync: editSpaceTypeDecoration} = useEditSpaceTypeDecoration();
	const {mutateAsync: editSpaceTypeDecorationSet} = useEditSpaceTypeDecorationSet();
	const {data: typeDecorationSet} = useSpaceTypeDecorationSet(typeDecorationSetId);

	const [typeDecorationSetFields, typeDecorationFields, getSavingData] =
		useEditableSpaceTypeDecoration({
			typeDecorationSet,
			typeDecoration,
			saving
		});

	const handleSave = useCallback(async () => {
		setSaving(true);
		const savingData = getSavingData();
		try {
			if (savingData && savingData.typeDecorationSet && savingData.typeDecoration) {
				const editSpaceTypeDecorationParams = {
					typeDecorationSetId,
					items: savingData.typeDecoration
				};
				await Promise.all([
					editSpaceTypeDecorationSet({
						id: typeDecorationSetId,
						...savingData.typeDecorationSet
					} as ISpaceTypeDecorationSet),
					editSpaceTypeDecoration(editSpaceTypeDecorationParams)
				]);

				addSuccessToast('Успешно', 'Набор типов отделки помещений был обновлен');
			}
		} finally {
			setSaving(false);
		}
	}, [getSavingData, typeDecorationSetId]);

	return (
		<div className="editing-space-type-decoration__container">
			<Plate className="editing-space-type-decoration__items-grid">
				{typeDecorationSetFields}
				{typeDecorationFields}
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			</Plate>
		</div>
	);
};
