import {useMutation} from "@tanstack/react-query";
import {inspectionsQueryKeys} from "@src/api/cache/inspections/keys";
import {makeInspectionsExportRequest} from "@src/api/backend/inspections";
import {IExportResponse} from "@tehzor/tools/interfaces/IExportResponse";
import {addExportToast} from "@src/components/ExportToast/addExportToast";
import {addErrorToast, addWarningToast} from "@src/utils/toasts";

interface IExportInspectionsParams {
	templateId: string;
	objectId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	filters: Record<string, unknown> | undefined;
	sort: Record<string, boolean> | undefined;
	selected?: string[] | undefined;
}

export const useExportInspections = () => (
	useMutation<IExportResponse, Error, IExportInspectionsParams>({
		mutationKey: inspectionsQueryKeys.export(),
		mutationFn: params => {
			const {templateId, objectId, createDocument, email, filters, sort, selected} = params;
			return makeInspectionsExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected
			)
		},
		onSuccess: (response, params) => {
			const sending = !!params.email;
			if (response.link) {
				addExportToast(
					sending ? 'Документ отправлен' : 'Экспорт документа завершен',
					response.link,
					response.file.name,
					'',
					response.document,
				);
			}
			if (response.warnings?.itemsLimit !== undefined) {
				addWarningToast(
					'Внимание',
					`превышен лимит записей (${String(response.warnings.itemsLimit)})`
				);
			}
		},
		onError: () => {
			addErrorToast('Ошибка', 'при экспорте');
		}
	})
)