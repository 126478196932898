import {IGetExportTemplatesDestinationsResponse} from '@src/api/backend/exportTemplatesDestinations';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractExportTemplatesDestinationsAsArray,
	extractExportTemplatesDestinationsMap
} from './selectors';

export const useExportTemplatesDestinations = <T = IGetExportTemplatesDestinationsResponse>(
	select?: (data: IGetExportTemplatesDestinationsResponse) => T
) =>
	useQuery<IGetExportTemplatesDestinationsResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.exportTemplatesDestination(),
		meta: {
			error: 'при загрузке типов шаблонов экспорта'
		},
		select
	});

export const useExportTemplatesDestinationAsArray = () =>
	useExportTemplatesDestinations(extractExportTemplatesDestinationsAsArray);

export const useExportTemplatesDestinationMap = () =>
	useExportTemplatesDestinations(extractExportTemplatesDestinationsMap);
