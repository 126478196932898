import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import {ICommentVariants} from '@tehzor/tools/interfaces/comments/ICommentType';

export interface ICommentsState {
	visibility: ICommentVariants[];
}

export const getInitialState = (): ICommentsState => ({
	visibility: [ICommentVariants.REPLIES, ICommentVariants.COMMENTS]
});

export default createReducer<ICommentsState>(getInitialState(), {
	[types.CHANGE_COMMENTS_VISIBILITY]: (
		state,
		{payload}: {payload: ICommentVariants[]}
	) => {
		state.visibility = payload;
	}
});
