import { Dispatch, useCallback, useMemo, useState } from 'react';
import {IEditableSpaceStatusesSetAction, IEditableSpaceStatusesSetOriginal, IEditableSpaceStatusesSetState, isCompanyEdited, isObjectsEdited} from '@src/core/hooks/states/useSpaceStatusesSetState';
import {NameField} from './components/fields/NameField';
import {CompanyField} from './components/fields/CompanyField';
import {ObjectsField} from './components/fields/ObjectsField';
import {ActionButtons, Button, EntityGridItem} from '@tehzor/ui-components';
import './EditableSpaceStatusesSet.less';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {DefaultStatusField} from './components/fields/DefaultStatusField';
import {IDefaultSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/IDefaultSpaceStatus';

interface IEditableSpaceStatusesSetProps {
	defaultData?: IEditableSpaceStatusesSetOriginal;
	canModify?: boolean;
	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;
	editingState: IEditableSpaceStatusesSetState;
	updateSet: () => Promise<ISpaceStatusesSet | undefined>;
}

const warningIcon = <i className="tz-warning-20"/>;

const reset = (
	dispatch: Dispatch<IEditableSpaceStatusesSetAction>,
	data?: ISavingSpaceStatusesSet
) => {
	const {name, companyId, objects, defaultStatus} = data || {};

	dispatch({field: 'name', type: 'update', value: name || ''});
	dispatch({field: 'companyId', type: 'update', value: companyId});
	dispatch({field: 'objects', type: 'update', value: objects || []});
	dispatch({field: 'defaultStatus', type: 'update', value: undefined});
	dispatch({field: 'isLocal', type: 'update', value: !data});

	if (defaultStatus) {
		dispatch({field: 'statuses', type: 'update', value: [defaultStatus]});
	}
};

export const EditableSpaceStatusesSet = ({
	defaultData, canModify, editingDispatch, editingState, updateSet
}: IEditableSpaceStatusesSetProps) => {
	const [isEdited, setEdited] = useState(false);

	const set = defaultData?.spaceStatusesSet;

	const handleSave = useCallback(async () => {
		const res = await updateSet();

		if (res) {
			setEdited(false);
			reset(editingDispatch, res);
		}
	}, [updateSet]);

	const handleCancel = useCallback(() => {
		reset(editingDispatch, set);
	}, [set]);

	const handleSetEdit = useCallback(() => {
		if (!isEdited) {
			setEdited(true);
		}
	}, [isEdited]);

	const handleChangeName = useCallback((value: string) => {
		editingDispatch({type: 'update', field: 'name', value});
		handleSetEdit();
	}, [handleSetEdit]);

	const handleUpdateNameError = useCallback(() => {
		editingDispatch({type: 'update-error', field: 'name'});
	}, []);

	const handleChangeCompany = useCallback((value: string) => {
		editingDispatch({type: 'update', field: 'companyId', value});

		if (editingState.objects?.length && value) {
			editingDispatch({type: 'update', field: 'objects', value: null});
		}
		handleSetEdit();
	}, [handleSetEdit, editingState.objects]);

	const handleUpdateCompanyError = useCallback(() => {
		editingDispatch({type: 'update-error', field: 'companyId'});
		editingDispatch({type: 'update-error', field: 'objects'});
	}, []);

	const handleChangeObjects = useCallback((value: string[]) => {
		editingDispatch({type: 'update', field: 'objects', value});
		handleSetEdit();
	}, [handleSetEdit]);

	const handleUpdateObjectsError = useCallback(() => {
		editingDispatch({type: 'update-error', field: 'companyId'});
		editingDispatch({type: 'update-error', field: 'objects'});
	}, []);

	const handleChangeDefaultStatus = useCallback((value?: IDefaultSpaceStatus) => {
		editingDispatch({type: 'update', field: 'defaultStatus', value});
		handleSetEdit();
	}, [handleSetEdit]);

	const handleUpdateDefaultStatusError = useCallback(() => {
		editingDispatch({type: 'update-error', field: 'defaultStatus'});
	}, []);

	const companyEdited = useMemo(() =>
		isCompanyEdited(editingState, defaultData), [editingState, defaultData]);

	const objectsEdited = useMemo(() =>
		isObjectsEdited(editingState, defaultData), [editingState, defaultData]);

	const warning = (
		<div className="editable-space-statuses-set__warning">
			<div className="editable-space-statuses-set__warning-icon">{warningIcon}</div>
			<div className="editable-space-statuses-set__warnning-text">
				Изменения могут привести к сбросу статусов помещений у объектов
			</div>
		</div>
	);

	const isLargeTablet = useIsLargeTablet();

	return (
		<>
			<EntityGridItem
				className={{
					content: 'editable-space-statuses-set__item',
					header: 'editable-space-statuses-set__item-header'
				}}
				label="Название набора"
				fullRow
			>
				<NameField
					disabled={!canModify || editingState.default}
					value={editingState.name}
					hasError={editingState.errors.name}
					onChange={handleChangeName}
					onError={handleUpdateNameError}
					required
				/>
			</EntityGridItem>

			<EntityGridItem
				className={{
					content: 'editable-space-statuses-set__item',
					header: 'editable-space-statuses-set__item-header'
				}}
				label="Компания"
				fullRow
			>
				<CompanyField
					disabled={!canModify || editingState.default}
					value={editingState.companyId}
					hasError={editingState.errors.companyId}
					onConfirm={handleChangeCompany}
					onError={handleUpdateCompanyError}
					isMobile={!isLargeTablet}
					required
				/>
				{companyEdited && warning}
			</EntityGridItem>

			<EntityGridItem
				className={{
					content: 'editable-space-statuses-set__item',
					header: 'editable-space-statuses-set__item-header'
				}}
				label="Объекты"
				fullRow
			>
				<ObjectsField
					disabled={!canModify || editingState.default}
					value={editingState.objects}
					companyId={editingState.companyId}
					hasError={editingState.errors.objects}
					onConfirm={handleChangeObjects}
					onError={handleUpdateObjectsError}
					isMobile={!isLargeTablet}
					required
				/>
				{objectsEdited && warning}
			</EntityGridItem>

			{editingState.isLocal && (
				<EntityGridItem
					className={{
						content: 'editable-space-statuses-set__item',
						header: 'editable-space-statuses-set__item-header'
					}}
					label="Статус по умолчанию"
					fullRow
				>
					<DefaultStatusField
						value={editingState.defaultStatus}
						hasError={editingState.errors.defaultStatus}
						onChange={handleChangeDefaultStatus}
						onError={handleUpdateDefaultStatusError}
					/>
				</EntityGridItem>
			)}

			<EntityGridItem
				fullRow
			>
				{canModify && isEdited && (
					<ActionButtons className="editable-space-statuses-set__buttons">
						<Button
							className="editable-space-statuses-set__buttons-cancel"
							onClick={handleCancel}
							type="cancel"
							label="Отменить"
						/>
						<Button
							className="editable-space-statuses-set__buttons-save"
							onClick={handleSave}
							type="accent-blue"
							label="Сохранить"
						/>
					</ActionButtons>
				)}
			</EntityGridItem>
		</>
	);
};