import {requestListProblems} from '@src/api/backend/problems';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import * as types from '../../constants';

export type IGetWorkAcceptanceProblemsPayload = INormalizedData<IListProblem>;

const request = () => ({type: types.GET_PROBLEMS_REQUEST});

const success = (response: IGetWorkAcceptanceProblemsPayload) => ({
	type: types.GET_PROBLEMS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке нарушений');
	return {
		type: types.GET_PROBLEMS_FAILURE,
		payload: error
	};
};

/**
 * Получает список нарушений для конкретной приёмки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приёмки работ
 */
export const getWorkAcceptanceProblems = (objectId: string, workAcceptanceId: string) =>
	createApiAction<IGetWorkAcceptanceProblemsPayload>(request, success, failure, () =>
		requestListProblems(
			{
				objects: [objectId],
				workAcceptanceIds: [workAcceptanceId]
			},
			{createdAt: false}
		).then(({byId, allIds}) => ({
			byId,
			allIds
		}))
	);
