import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';

export enum ITreeUserGroupsItemType {
	GROUP = 'group',
	LEADER = 'leader',
	PERFORMER = 'performer'
}

export interface ITreeUserGroupsItem extends ITreeDataItem {
	additionalContent?: string;
	name: string;
	type: ITreeUserGroupsItemType;
}

export const makeUserGroupsTreeData = (
	groups: IWorkingGroup[], usersMap: Record<string, IBriefUser>
) => {
	const treeData: ITreeUserGroupsItem[] = [];

	for (const group of groups) {
		const leader = usersMap[group.leader];

		treeData.push({
			id: group.id,
			parentId: group.parentId,
			name: group.name,
			content: group.name,
			additionalContent: leader?.fullName,
			type: ITreeUserGroupsItemType.GROUP,
			onlySemiCheckable: true
		});

		if (leader && !group.performers?.includes(leader.id)) {
			treeData.push({
				id: group.leader,
				parentId: group.id,
				name: leader.fullName,
				content: leader.fullName,
				type: ITreeUserGroupsItemType.LEADER
			});
		}

		if (group.performers?.length) {
			const groupUsers: ITreeUserGroupsItem[] = group.performers?.map(id => {
				const user = usersMap[id];

				return {
					id,
					parentId: group.id,
					name: user?.fullName,
					content: user?.fullName,
					type: id === leader?.id
						? ITreeUserGroupsItemType.LEADER
						: ITreeUserGroupsItemType.PERFORMER
				};
			});
			treeData.push(...groupUsers);
		}
	}

	return treeData;
};