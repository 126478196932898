/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {IObjectManager} from '@tehzor/tools/interfaces/objects/IObject';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import classNames from 'classnames';
import {CSSProperties, Dispatch, ReactNode, useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {useTranslation} from 'react-i18next';

interface IManagerFields<S, E> {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				title?: string;
				fullName?: string;
				phone?: string;
		  };
	style?: CSSProperties;
	title?: ReactNode;
	field: keyof S;
	value?: IObjectManager;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: {
		fullName: string;
		phone: string;
	};
	onNameChange?: (fullName: string) => void;
	onPhoneChange?: (phone: string) => void;
}

const regPhone = /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/;

export const ManagerFields = <S, E>({
	className,
	style,
	title,
	field,
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	errorMessage,
	onNameChange,
	onPhoneChange
}: IManagerFields<S, E>) => {
	const classes = convertClassNames(className);

	const {t} = useTranslation();
	const [fullName, setFullName] = useState<string | undefined>(value?.fullName);
	const [phone, setPhone] = useState<string | undefined>(
		value?.phone ? String(value.phone) : undefined
	);

	const [phoneError, setPhoneError] = useState<boolean>(false);

	useUpdateEffect(() => {
		editingDispatch({type: 'update', field, value: {fullName, phone}});
		if (required) {
			editingDispatch({type: 'update-error', field});
		}
	}, [fullName, phone]);

	const handleNameChange = useCallback(
		(item: string) => {
			setFullName(item);

			if (onNameChange) {
				onNameChange(item);
			}
		},
		[onNameChange]
	);

	const handlePhoneChange = useCallback(
		(item: string) => {
			setPhone(item);

			setPhoneError(!regPhone.test(item));
			if (onPhoneChange) {
				onPhoneChange(item);
			}
		},
		[onPhoneChange]
	);

	return (
		<div
			className={classNames(classes.root, 'editable-object__manager-field')}
			style={style}
		>
			<div className={classNames(classes?.title, 'editable-object__manager-field-title')}>
				{title}
			</div>
			<div className={classNames(classes?.wrap, 'editable-object__manager-field-wrap')}>
				<div
					className={classNames(
						classes?.fullName,
						'editable-object__manager-field-wrap__fullName'
					)}
				>
					<EditableFieldLabel>
						{t('editableObject.managerFields.editableFieldLabel.fullName')}
					</EditableFieldLabel>

					<TextField
						value={fullName}
						elementType="input"
						onChange={handleNameChange}
						error={required && hasError ? errorMessage?.fullName : undefined}
						disabled={disabled}
					/>
				</div>
				<div
					className={classNames(
						classes?.phone,
						'editable-object__manager-field-wrap__phone'
					)}
				>
					<EditableFieldLabel>
						{t('editableObject.managerFields.editableFieldLabel.phone')}
					</EditableFieldLabel>

					<TextField
						value={phone}
						elementType="input"
						onChange={handlePhoneChange}
						error={
							(required && hasError) || (phoneError && phone?.length)
								? errorMessage?.phone
								: undefined
						}
						disabled={disabled}
					/>
				</div>
			</div>
		</div>
	);
};
