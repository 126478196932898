import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {IGetCheckResponse} from '@src/api/backend/check';
import IError from '@tehzor/tools/interfaces/IError';
import {checksQueryKeys} from '@src/api/cache/checks/keys';

export const useFetchLatestUserCheck = (
	checkId: string,
	objectId: string,
	options?: Partial<UseQueryOptions<IGetCheckResponse, IError>>
) =>
	useQuery<IGetCheckResponse, IError>({
		queryKey: [...checksQueryKeys.detail(checkId), objectId],
		staleTime: Infinity,
		meta: {
			error: 'при загрузке последней проверки'
		},
		...options
	});
