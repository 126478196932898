import {EntityInfo, EntityInfoDateWithCountFiles} from '@tehzor/ui-components';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface ICheckInfoProps {
	data: IListCheck;
}

const checkIcon = <i className="tz-check-28" />;

const CheckInfo = ({data}: ICheckInfoProps) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();
	return (
		<EntityInfo
			title={`${t('spacePage.desktopTable.checkInfo.title')} №${
				data.number ?? data.localNumber
			}`}
			subTitle={
				<EntityInfoDateWithCountFiles
					labelFrom={t('components.tableCell.info.entityInfoDateWithCountFiles.labelFrom')}
					locale={dateFnsLocale}
					data={data}
				/>
			}
			icon={checkIcon}
			iconColor="#57A4FF"
			iconSize={44}
		/>
	);
};

export default CheckInfo;
