import {useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton, Select2, SelectOption} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {
	ICustomFiltersData,
	ICustomFilterSelectValue
} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';
import {ICustomField, ValueTypeId} from '@tehzor/tools/interfaces/fields';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {isEmpty} from 'lodash';

interface ICustomFilterSelectPageProps {
	objectId: string;
	entity: string;
	fieldData: ICustomField;
	filterValue: ICustomFilterSelectValue;
	onChange: (data: ICustomFiltersData | Record<string, undefined>) => void;
	onClear: (key: string) => void;
}

export const CustomFilterSelectPage = ({
	objectId,
	entity,
	fieldData,
	filterValue,
	onChange,
	onClear
}: ICustomFilterSelectPageProps) => {
	const {key, name, values, valueType} = fieldData;
	const {t} = useTranslation();
	const {goBack} = useChangePath();

	const [search, setSearch] = useState('');
	const [selected, setSelected] = useState(filterValue);

	const filteredData = useMemo(() => {
		if (!values) return [];
		const filterItems = values.map(value => ({id: value, name: value}));
		if (search === '') return filterItems;
		return filterItems.filter(value => value.id.toUpperCase().includes(search.toUpperCase()));
	}, [values, search]);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = () => {
		if (isEmpty(selected)) {
			onClear(key);
		} else {
			onChange({
				[key]: {
					valueTypeId: valueType.id as ValueTypeId.SELECT,
					value: selected
				}
			});
		}
		goBack();
	};

	const handleClear = () => {
		setSelected(undefined);
	};

	useAppHeader(
		{
			title: name,
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label={t('entitiesFilters.cancelButton.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(filterValue, selected)}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
			onApplyClick={handleApply}
		>
			<Select2
				multiple
				value={selected}
				onChange={setSelected}
			>
				{filteredData.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</TranslatedFilterPage>
	);
};
