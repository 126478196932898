import {requestCheck} from '@src/api/backend/check';
import {requestChecks} from '@src/api/backend/checks';
import {requestListProblems} from '@src/api/backend/problems';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {checksActions} from '@src/store/modules/settings/pages/checks/slice';
import {Query, QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {getDateDifference} from '@tehzor/tools/utils/dates/getDateDifference';
import {endOfDay} from 'date-fns';
import {
	ICheckDetailQueryKey,
	ICheckInspectionsQueryKey,
	ICheckProblemsQueryKey,
	IChecksListQueryKey,
	checksQueryKeys
} from '../keys';
import {requestListInspections} from '@src/api/backend/inspections';

export const useChecksQueryDefaults = () => {
	const queryClient = useQueryClient();
	const {changeInitialStateByObjectId} = checksActions;
	const dispatch = useAppDispatch();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	queryClient.setQueryDefaults(checksQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IChecksListQueryKey>) => {
			const [, , filters, sort, offset, pageSize, objectId] = queryKey;
			dispatch(changeInitialStateByObjectId({objectId}));
			return requestChecks(filters, sort, offset, pageSize);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(checksQueryKeys.latest(), {
		queryFn: () => {
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			return requestChecks({createdAtFrom: date}, {createdAt: false}, 0, 100);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: getDateDifference(new Date(), endOfDay(new Date())) // до конца дня
	});
	queryClient.setQueryDefaults(checksQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ICheckDetailQueryKey>) => {
			const [, , checkId, objectId] = queryKey;
			return requestCheck(objectId, checkId);
		},
		meta: {
			persist: isOfflineModeAvailable
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(checksQueryKeys.localList(), {
		queryFn: ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localChecks = queryClient.getQueriesData<IListCheck>({
				queryKey: checksQueryKeys.details(),
				predicate: (query: Query<IListCheck>) => {
					const {data} = query.state;
					if (data?.transferStatus) {
						if (!objectId || objectId === 'all') {
							return true;
						}
						if (objectId === data.objectId) {
							return true;
						}
					}
					return false;
				}
			});
			return localChecks.map(([, check]) => check);
		},
		staleTime: 0,
		gcTime: 0,
		networkMode: 'offlineFirst',
		enabled: isOfflineModeAvailable
	});

	queryClient.setQueryDefaults(checksQueryKeys.inspections(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ICheckInspectionsQueryKey>) => {
			const [, , objectId, checkId] = queryKey;
			return requestListInspections(
				{
					objects: [objectId],
					checks: [checkId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});

	queryClient.setQueryDefaults(checksQueryKeys.problems(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ICheckProblemsQueryKey>) => {
			const [, , objectId, checkId] = queryKey;
			return requestListProblems(
				{
					objects: [objectId],
					checks: [checkId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});
};
