import { useRef, memo } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {formDocumentLink} from '@tehzor/tools/utils/links';
import {LinkButton} from '@tehzor/ui-components';
import {useVirtualizer} from '@tanstack/react-virtual';
import {useTranslation} from 'react-i18next';

interface IEntityDocsItemsProps {
	documents: IDocument[];
}

const documentIcon = <i className="tz-document-16" />;

export const DOCUMENTS_COUNT = 4;

export const EntityDocsVirtualizeItems = memo((props: IEntityDocsItemsProps) => {
	const {t} = useTranslation();

	const {pushPath} = useChangePath();
	const {documents} = props;
	const parentRef = useRef<HTMLDivElement>(null);

	const virtualizer = useVirtualizer({
		count: documents.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 100,
		overscan: 2
	});

	const items = virtualizer.getVirtualItems();

	const renderedDocuments = items.map(virtualRow => {
		const document = documents[virtualRow.index];
		const title = document?.number ? `${t('entityDocs.pluralName')} №${document.number.toString()}` : t('entityDocs.emptyName');

		const handleClick = () => {
			pushPath(formDocumentLink(document.id));
		};

		return (
			<div
				key={virtualRow.key}
				data-index={virtualRow.index}
				ref={virtualizer.measureElement}
			>
				<LinkButton
					onClick={handleClick}
					type="filled"
					leftIcon={documentIcon}
					label={title}
				/>
			</div>
		);
	});

	return (
		<div
			ref={parentRef}
			className="entity-docs__virtualize-list"
			style={{
				height: virtualizer.getTotalSize()
			}}
		>
			<div
				style={{
					height: virtualizer.getTotalSize()
				}}
				className="entity-docs__virtualize-list-wrapper"
			>
				<div
					style={{
						transform: `translateY(${items[0]?.start}px)`
					}}
					className="entity-docs__virtualize-list-items"
				>
					{renderedDocuments}
				</div>
			</div>
		</div>
	);
});
