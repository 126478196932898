import {queryClient} from '@src/api/QueryClient';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IDeleteCheckListResponse = ICheckList;

/**
 * Удаляет чек-лист
 *
 * @param id id чек-листа
 */
export const makeCheckListDeleteRequest = async (id: string) => {
	void queryClient.invalidateQueries({queryKey: checkListsQueryKeys.list()});
	const response = await httpRequests.withToken.delete<IDeleteCheckListResponse>(
		`check-lists/${id}`
	);
	return response.data;
};
