import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton, Select2, SelectOption} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useTranslation} from 'react-i18next';
import {useSpaceOwnersAsArray} from '@src/core/hooks/queries/spaceOwners/hooks';

export const SpaceOwnersFilterPage = () => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{spaceOwners?: string[]}>();
	const [search, setSearch] = useState('');
	const [selected, setSelected] = useState(state.spaceOwners);

	const {data: spaceOwners} = useSpaceOwnersAsArray();
	const filteredData = useMemo(
		() => flatFilter(spaceOwners || [], 'name', search),
		[spaceOwners, search]
	);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({spaceOwners: selected});
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: t('entitiesFilters.spaceOwnersFilter.label'),
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label={t('entitiesFilters.cancelButton.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(state.spaceOwners, selected)}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
			onApplyClick={handleApply}
		>
			<Select2
				multiple
				value={selected}
				onChange={setSelected}
			>
				{filteredData.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</TranslatedFilterPage>
	);
};
