import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

export const useTranslatedDictionaryById = (key: dictionaryKeys, id?: string) => {
	const {t} = useTranslation();

	if (!id) return '';

	return t(`${key}.${id}`);
};
