import {IGetListProblemsResponse} from '@src/api/backend/problems';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckVisibility} from '@src/store/modules/settings/pages/check/selectors';
import {useQuery} from '@tanstack/react-query';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {useCallback} from 'react';
import {useLocalProblems} from '../../problems/hooks';
import {
	extractCheckLocalProblemsEntities,
	extractCheckProblemsArray,
	extractCheckProblemsEntities
} from './selectors';

export const useCheckProblems = <T = IGetListProblemsResponse>(
	objectId: string,
	checkId: string,
	select?: (data: IGetListProblemsResponse) => T
) => useQuery({queryKey: checksQueryKeys.checkProblems(objectId, checkId), select});

export const useCheckProblemsArray = (objectId: string, checkId: string) =>
	useCheckProblems(objectId, checkId, extractCheckProblemsArray);

export const useCheckProblemsEntities = (objectId: string, checkId: string) => {
	const visibility = useAppSelector(extractCheckVisibility);
	const selector = useCallback(
		(data: IGetListProblemsResponse) => extractCheckProblemsEntities(data, visibility),
		[visibility]
	);
	return useCheckProblems(objectId, checkId, selector);
};

export const useCheckLocalProblemsEntities = (objectId: string, checkId: string) => {
	const visibility = useAppSelector(extractCheckVisibility);
	const selector = useCallback(
		(data: IListProblem[]) => extractCheckLocalProblemsEntities(data, checkId, visibility),
		[checkId, visibility]
	);
	return useLocalProblems(objectId, selector);
};
