import {IState} from '@src/store/modules';
import * as types from '../constants/problemsData';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {requestInternalAcceptancesProblemsData} from '@src/api/backend/internalAcceptances/problemsData';
import {IInternalAcceptancesStatsDataRequest} from '@src/interfaces/IInternalAcceptancesStatsData';

const request = (requestData: IInternalAcceptancesStatsDataRequest) => ({
	type: types.GET_REQUEST,
	payload: requestData
});

const success = (response: Record<string, IEntityProblemsData>) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке нарушений внутренних приемок');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает статстику нарушений для внутренних приемок
 *
 * @param requestData данные запроса
 */
export const getInternalAcceptancesProblemsData = (
	requestData: IInternalAcceptancesStatsDataRequest
) => (
		checkLoaded<IState, Record<string, IEntityProblemsData>, ApiAction>(
		s => s.pages.internalAcceptances.problemsData,
		createApiAction(
			() => request(requestData),
			result => success(result),
			error => failure(error),
			() => requestInternalAcceptancesProblemsData(requestData)
		)
	)
	);
