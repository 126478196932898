import { useCallback } from 'react';
import {Column} from 'react-table';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import {extractCheckProblemCommentsByProblem} from '@src/store/modules/entities/check/selectors';
import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';
import {ProblemInfoMobile} from '@src/components/tableCells/ProblemInfoMobileCell/ProblemInfoMobileCell';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import {EntityInfoLink} from '@tehzor/ui-components';
import {useWorkAcceptanceById} from '@src/core/hooks/queries/workAcceptances';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';

const Wrap = (props: IProblemCellProps<{data: IProblem}>) => {
	const {row, replies} = props;
	const {data} = row.original;
	const {objectId, links} = data;
	const {pushPath} = useChangePath();

	const {data: workAcceptance} = useWorkAcceptanceById(objectId, links?.workAcceptanceId);
	const navigate = useCallback(() => {
		if (links?.workAcceptanceId) {
			pushPath(formWorkAcceptanceLink(objectId, links.workAcceptanceId));
		}
	}, [links?.workAcceptanceId, objectId, pushPath]);

	return (
		<ProblemInfoMobile
			problem={data}
			depth={row.depth}
			subTitle={
				<EntityInfoLink
					label={links?.workAcceptanceId && `Приёмка работ № ${workAcceptance?.number}`}
					onClick={navigate}
				/>
			}
			replies={replies}
			getToggleRowSelectedProps={row.getToggleRowSelectedProps}
		/>
	);
};

export const columns: Array<Column<{data: IProblem}>> = [
	{
		id: 'info',
		Cell: ProblemInfoCellWrap(Wrap, extractCheckProblemCommentsByProblem)
	}
];
