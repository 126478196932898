import {IState} from '@src/store/modules';
import {createSelector} from 'reselect';

const getPerformersAllIds = (state: IState) =>
	state.entities.inspection.performersDelegationHistories.allIds || [];
const getPerformersById = (state: IState) =>
	state.entities.inspection.performersDelegationHistories.byId || {};

/**
 * Возвращает записи истории делегирования исполнителей в виде массива
 */
export const extractInspectionPerformersDelegationHistoriesAsArray = createSelector(
	[getPerformersAllIds, getPerformersById],
	(allIds, byId) => allIds.map(id => byId[id])
);
