import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {deleteInternalAcceptanceActions} from '@src/store/modules/entities/internalAcceptance/actions';
import {makeInternalAcceptanceDeleteRequest} from '@src/api/backend/internalAcceptance';

interface IDeleteInternalAcceptanceParams {
	objectId: string;
	internalAcceptanceId: string;
}

export const useDeleteInternalAcceptance = () => {
	const dispatch = useAppDispatch();
	return useMutation({
		mutationKey: internalAcceptancesQueryKeys.delete(),
		mutationFn: (params: IDeleteInternalAcceptanceParams) => {
			const {objectId, internalAcceptanceId} = params;
			return makeInternalAcceptanceDeleteRequest(objectId, internalAcceptanceId);
		},
		onSuccess: (_, params: IDeleteInternalAcceptanceParams) => {
			const {objectId, internalAcceptanceId} = params;
			void Promise.all([
				queryClient.invalidateQueries({
					queryKey: internalAcceptancesQueryKeys.lists()
				}),
				queryClient.invalidateQueries({
					queryKey: internalAcceptancesQueryKeys.latest()
				}),
				queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()}),
				queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.list()}),
				queryClient.invalidateQueries({
					queryKey: [...spacesQueryKeys.internalAcceptances(), objectId]
				})
			]);
			dispatch(deleteInternalAcceptanceActions.success(objectId, internalAcceptanceId, true));
			addInfoToast('Удалено', 'Внутренняя приёмка успешно удалена');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при удалении внутренней приёмки');
		}
	});
};
