import classNames from 'classnames';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {LoadingPanel} from '@tehzor/ui-components';
import {OwnerAcceptancesFilters} from './components/OwnerAcceptancesFilters';
import {OwnerAcceptancesPageCounter} from './components/OwnerAcceptancesPageCounter';
import {OwnerAcceptancesTable} from './components/table/OwnerAcceptancesTable';
import {useParams} from 'react-router-dom';
import {useOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances/hooks';
import {useOwnerAcceptancesPageBreadcrumbs} from '@src/pages/OwnerAcceptancesPage/hooks/useOwnerAcceptancesPageBreadcrumbs';
import './OwnerAcceptancesPage.less';

export const OwnerAcceptancesPage = () => {
	useScrollRestoration();
	const {objectId} = useParams<{objectId?: string}>();
	const {data: ownerAcceptancesMap, isFetching: ownerAcceptancesLoading} =
		useOwnerAcceptances(objectId);
	const breadcrumbs = useOwnerAcceptancesPageBreadcrumbs(objectId);

	return (
		<LoadingPanel
			className="owner-acceptances-page__loading-panel"
			active={ownerAcceptancesLoading}
		>
			<div className={classNames('page-cont', 'owner-acceptances-page')}>
				{breadcrumbs}
				<div className="owner-acceptances-page__filters">
					<div className="owner-acceptances-page__filters-bar">
						<OwnerAcceptancesFilters objectId={objectId} />
					</div>
					<OwnerAcceptancesPageCounter
						objectId={objectId}
						count={ownerAcceptancesMap?.total}
					/>
				</div>

				<OwnerAcceptancesTable objectId={objectId} />
			</div>
		</LoadingPanel>
	);
};
