import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {spacesQueryKeys} from '../keys';
import {requestSpacesTypesCount} from '@src/api/backend/spaces/spacesTypesCount';
import {ISpacesTypesCountQueryKey} from '../keys/interfaces';

export const useSpacesTypesCountQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(spacesQueryKeys.typesCounts(), {
		queryFn: ({queryKey}: QueryFunctionContext<ISpacesTypesCountQueryKey>) => {
			const [, , objectIds] = queryKey;
			return requestSpacesTypesCount(objectIds);
		},
		staleTime: 1000 * 60 * 60,
		gcTime: 1000 * 60 * 60 * 24
	});
};
