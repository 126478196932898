import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditProblemResponse = ILinkedProblem;

/**
 * Изменяет нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param fields данные нарушения
 */
export const makeProblemEditRequest = async (
	objectId: string,
	problemId: string,
	fields: ISavingProblem
) => {
	const response = await httpRequests.withToken.put<IEditProblemResponse>(
		`/problems/${problemId}`,
		{
			objectId,
			...fields
		}
	);
	return response.data;
};
