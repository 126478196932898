import {memo, useCallback} from 'react';
import './WorkAcceptanceFrontItem.less';
import {LinkButton} from '@tehzor/ui-components';
import {formSpaceLink, formStructureLink} from '@tehzor/tools/utils/links';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {TranslatedLocationSelect} from '@src/components/TranslatedLocationSelect';

const structureIcon = <i className="tz-structure" />;
const spaceIcon = <i className="tz-space-16" />;

interface IWorkAcceptanceFrontItem {
	item: {
		id: string;
		name: string;
		type: string;
		planId?: string;
		location?: ILocation;
	};
	objectId: string;
	frontType?: WorkAcceptanceFrontTypeId;
}

export const WorkAcceptanceFrontItem = memo((props: IWorkAcceptanceFrontItem) => {
	const {pushPath} = useChangePath();
	const {item, objectId, frontType} = props;
	const {data: plans} = usePlansAsArray(objectId);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const translatedSpaceTypes = useTranslatedDictionaryMap(dictionaryKeys.spaceTypes, spaceTypes);

	const navigate = useCallback(() => {
		if (frontType === WorkAcceptanceFrontTypeId.SPACES) {
			pushPath(formSpaceLink(objectId, item.id, 'work-acceptances'));
		}
		if (frontType === WorkAcceptanceFrontTypeId.STRUCTURES) {
			pushPath(formStructureLink(objectId, item.id));
		}
	}, [frontType, item, objectId]);

	const workAcceptanceFrontIcon =
		frontType === WorkAcceptanceFrontTypeId.STRUCTURES
			? structureIcon
			: frontType === WorkAcceptanceFrontTypeId.SPACES
			? spaceIcon
			: null;

	return (
		<div
			key={item.id}
			className="semi-bold work-acceptance-page__structure"
		>
			<div className="work-acceptance-page__structure_name">
				<div
					role="presentation"
					onClick={navigate}
					className="work-acceptance-page__selected-structure "
				>
					<div className="work-acceptance-page__selected-structure_icon">
						{workAcceptanceFrontIcon}
					</div>
					{frontType === WorkAcceptanceFrontTypeId.SPACES && spaceTypes
						? formSpaceTitle(
								item.name,
								undefined,
								translatedSpaceTypes[item.type],
								undefined,
								true
						  )
						: item.name}
				</div>
			</div>
			{item.location && item.planId && (
				<div className="work-acceptance-page__structure_markers">
					<TranslatedLocationSelect
						mode="view"
						planId={item.planId}
						location={item.location}
						plans={plans}
						path="entity"
						multiplePoints
					>
						{(displayLocation: string, open: () => void) => (
							<div className="problem-page__d-entities-grid-display-place">
								<LinkButton
									label={displayLocation}
									onClick={open}
								/>
							</div>
						)}
					</TranslatedLocationSelect>
				</div>
			)}
			<br />
		</div>
	);
});
