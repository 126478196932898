import {Dispatch, useCallback} from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {useTranslation} from 'react-i18next';

const arrowIcon = <i className="tz-simple-arrow-20" />;

interface IPlanSelectProps<S, E> {
	field?: keyof S;
	value?: string[];
	data: IPlan[];
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const PlanSelect = <
	S extends {
		planIds?: string[];
	},
	E
>({
	className,
	style,
	field = 'planIds',
	label,
	data,
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: IPlanSelectProps<S, E>) => {
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.planSelect.label');
	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});

			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	const handleClear = useCallback(() => {
		editingDispatch({type: 'update', field, value: null});

		if (required) {
			editingDispatch({type: 'update-error', field});
		}
	}, [required]);

	const planNames = data.filter(plan => value?.includes(plan.id)).map(plan => plan.name);
	const triggerValue =
		value && planNames?.length > 0
			? planNames.length > 1
				? `${planNames[0]} + ${planNames.length - 1}`
				: `${planNames[0]}`
			: undefined;
	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={triggerValue}
						icon={arrowIcon}
						error={
							required && hasError
								? t('components.editableFields.planSelect.error')
								: undefined
						}
						disabled={disabled}
						cleanable
						onClearClick={handleClear}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
					multiple
				>
					{data.map(plan => (
						<SelectOption
							key={plan.id}
							itemKey={plan.id}
							content={plan.name}
							inputType="checkbox"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
