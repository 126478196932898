import useAppSelector from '@src/core/hooks/useAppSelector';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {extractCheckRecordsAsArray} from '@src/store/modules/pages/checkLists/selectors/records';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {useMemo} from 'react';
import {
	useExtractCheckItemsAsTree,
	useExtractCheckItems
} from '@src/core/hooks/queries/checkItems/hooks';

function filterRecords(
	checkListId: string,
	records: ICheckRecord[],
	items?: ICheckItem[]
): [ICheckRecord[], string[]] {
	const itemsIds = items?.map(({id}) => id);
	const filteredRecords: ICheckRecord[] = [];
	const acceptedItemsIds: string[] = [];

	for (const record of records) {
		if (!record.links.checkItemId) {
			// нас интересуют только записи по категориям
			continue;
		}

		if (
			record.links.checkListId === checkListId &&
			itemsIds?.includes(record.links.checkItemId)
		) {
			filteredRecords.push(record);

			if (record.status === CheckRecordStatusId.ACCEPTED) {
				acceptedItemsIds.push(record.links.checkItemId);
			}
		}
	}

	return [filteredRecords, acceptedItemsIds];
}

/**
 * Разбивает список категорий по трём составляющим: принятые, текущие, будущие
 *
 * @param checkListId id чек-листа
 * @param groupAccepted группировать ли принятые категории
 */
export function useSeparatedCheckItems(checkListId: string, groupAccepted?: boolean) {
	const {data: items} = useExtractCheckItemsAsTree(checkListId);
	const {data: itemsArray} = useExtractCheckItems(checkListId);
	const records = useAppSelector(extractCheckRecordsAsArray);

	const [filteredRecords, acceptedItemsIds] = filterRecords(checkListId, records, itemsArray);
	const accepted: ICheckItem[] = [];
	const current: ICheckItem[] = [];
	const next: ICheckItem[] = [];

	return useMemo(() => {
		for (const item of items ?? []) {
			const record = filteredRecords.find(({links}) => links.checkItemId === item.id);

			if (
				// еще не была создана запись или категория не принята
				(!record || record.status !== CheckRecordStatusId.ACCEPTED) &&
				// у категории нет зависимостей или все зависимости удовлетворены
				(item.dependencies?.length === 0 ||
					item.dependencies?.every(depId => acceptedItemsIds.includes(depId)))
			) {
				current.push(item);
				continue;
			}
			if (groupAccepted) {
				if (
					// категория принята полностью
					record &&
					record.status === CheckRecordStatusId.ACCEPTED &&
					items?.indexOf(item) === accepted.length
				) {
					accepted.push(item);
					continue;
				}
			}
			if (record && record.status === CheckRecordStatusId.ACCEPTED) {
				current.push(item);
			} else {
				// сюда попадает любая категория, которую нельзя принять на текущий момент
				next.push(item);
			}
		}
		return {accepted, current, next, acceptedItemsIds};
	}, [items, filteredRecords, acceptedItemsIds]);
}
