import {useEffect, useState} from 'react';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {useUpdateEffect} from 'react-use';
import {formSpaceLocationForEntities} from '@src/utils/formSpaceLocationForEntities';
import {transformLocations} from '@src/pages/CheckListPage/utils/transformLocations';
import {ISpaceLocation} from '@tehzor/tools/interfaces/spaces/ISpaceLocation';
import {useShape} from '@src/core/hooks/queries/plans/hooks';
import {useSpace, useSpaceIsLoading} from '@src/core/hooks/queries/space';
import {useParams} from 'react-router';
import {useFetchLatestInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/latest/hooks';

export function useSpaceLocation(
	objectId: string,
	selectedInternalAcceptanceId: string | undefined,
	defaultProblemData?: ISavingProblem,
	defaultInspectionData?: ISavingInspection
): [ISavingProblem | undefined, ISavingInspection | undefined, boolean] {
	const {spaceId} = useParams<{spaceId: string}>();

	const {data: latestInternalAcceptances} = useFetchLatestInternalAcceptances();
	const [locations, setLocations] = useState<ISpaceLocation[] | undefined>();

	const [planId, sectorId] = transformLocations(locations);
	const {data: sector} = useShape(objectId, planId, sectorId);

	const [problemData, setProblemData] = useState<ISavingProblem | undefined>(defaultProblemData);
	const [inspectionData, setInspectionData] = useState<ISavingInspection | undefined>(
		defaultInspectionData
	);

	const {data: space} = useSpace(spaceId, objectId);
	const spaceIsLoading = useSpaceIsLoading();

	useUpdateEffect(() => {
		if (defaultProblemData) {
			setProblemData(defaultProblemData);
		}
	}, [defaultProblemData]);

	useEffect(() => {
		if (space) {
			setLocations(space?.locations);
			const data = formSpaceLocationForEntities(space, sector);
			setProblemData({...defaultProblemData, ...data});
			setInspectionData({...defaultInspectionData, ...data});
			return;
		}
		setProblemData(defaultProblemData);
		setInspectionData(defaultInspectionData);
	}, [selectedInternalAcceptanceId, latestInternalAcceptances, sector, space]);
	return [problemData, inspectionData, spaceIsLoading];
}
