import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditSpaceIndicatorsResponse = ISpace;

/**
 * Изменяет индикаторы помещения
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param indicators индикаторы
 */
export const makeSpaceIndicatorsEditRequest = async (
	objectId: string,
	spaceId: string,
	indicators: string[]
) => {
	const response = await httpRequests.withToken.patch<IEditSpaceIndicatorsResponse>(
		'spaces/indicators/edit',
		{
			objectId,
			spaceId,
			indicators
		}
	);
	return response.data;
};
