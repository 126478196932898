import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, checkId: string, quietly?: boolean) => {
	if (!quietly) {
		addInfoToast('Удалено', 'Проверка успешно удалена');
	}

	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			checkId
		}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении проверки');
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

export const deleteCheckActions = {request, success, failure};
