import classNames from 'classnames';

interface IProgressProps {
	inProgress?: boolean;
}

const moreIcon = <i className="tz-more-16"/>;
const markIcon = <i className="tz-mark-2-16"/>;

export const Progress = ({inProgress}: IProgressProps) => (
	<div className="responsibles-view-field__progress">
		<div className="responsibles-view-field__progress-start" />
		<div className="responsibles-view-field__progress-line" />
		<div
			className={classNames(
				'responsibles-view-field__progress-icon',
				inProgress
					? 'responsibles-view-field__progress-icon_blue'
					: 'responsibles-view-field__progress-icon_green'
			)}
		>
			{inProgress ? moreIcon : markIcon}
		</div>
	</div>
);
