import {CellProps} from 'react-table';
import {Tag} from '@tehzor/ui-components';
import {IPreparedSpaceTypeDecoration} from '../../interfaces/IPreparedSpaceTypeDecoration';
import {useObjectsMap} from '@src/core/hooks/queries/objects/hooks';

export const ObjectsCell = ({row}: CellProps<IPreparedSpaceTypeDecoration>) => {
	const rowObjects = row.original.objects || [];
	const objectsById = useObjectsMap();

	if (rowObjects.length < 1) {
		return null;
	}

	return (
		<>
			{rowObjects.map((object, index, arr) => {
				if (index < 2) {
					return (
						<Tag
							className="objectTag"
							key={objectsById?.[object]?.id}
							label={objectsById?.[object]?.name || ''}
						/>
					);
				}
				if (index === 2) {
					return (
						<Tag
							className="objectTag"
							key={objectsById?.[object]?.id}
							label={`+${arr.length - 2}`}
						/>
					);
				}
				return null;
			})}
		</>
	);
};
