import { memo, useCallback } from 'react';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import ItemButton from './ItemButton';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IChangeCheckRecordStatusParams} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import {useExtractTreeCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {useTranslation} from "react-i18next";

interface IItemFooterProps {
	active?: boolean;
	record?: ICheckRecord;
	item: ICheckItem;
	stage: ObjectStageIds;
	changeCheckItemRecord: (params: IChangeCheckRecordStatusParams) => void;
	disabled?: boolean;
	availableStatuses?: string[];
}

const getStatus = (record: ICheckRecord | undefined, nextStatus: CheckRecordStatusId) =>
	record?.status === nextStatus ? CheckRecordStatusId.NOT_CHECKED : nextStatus;

const closeIcon = <i className="tz-close-16" />;
const percentIcon = <i className="tz-percent" />;
const markIcon = <i className="tz-mark-16" />;

export const ItemActions = memo(
	({
		active,
		record,
		item,
		stage,
		changeCheckItemRecord,
		disabled,
		availableStatuses
	}: IItemFooterProps) => {
		const {t} = useTranslation();
		const {objectId, spaceId, workAcceptanceId} = useStrictParams<{
			objectId: string;
			spaceId?: string;
			workAcceptanceId?: string;
		}>();
		const {data: treeItem} = useExtractTreeCheckItemById(item.checkListId, item.id);

		const buttonProps = {record, item};

		const [closingDialog, getClosingConfirmation] = useConfirmDialog(
			t('checkListPage.checkListItems.itemActions.confirmDialog.title'),
			t('checkListPage.checkListItems.itemActions.confirmDialog.message')
		);

		const accept = useCallback(async () => {
			let confirmationResult = true;

			if (record?.status !== CheckRecordStatusId.ACCEPTED && treeItem?.children?.length) {
				confirmationResult = await getClosingConfirmation();
			}

			if (!confirmationResult) {
				return;
			}

			if (record) {
				changeCheckItemRecord({
					item,
					objectId,
					spaceId,
					workAcceptanceId,
					stage,
					status: getStatus(record, CheckRecordStatusId.ACCEPTED),
					record
				});
			}
		}, [
			record,
			treeItem?.children?.length,
			getClosingConfirmation,
			changeCheckItemRecord,
			item,
			objectId,
			spaceId,
			workAcceptanceId,
			stage
		]);

		const reject = useCallback(() => {
			if (record) {
				changeCheckItemRecord({
					item,
					objectId,
					spaceId,
					workAcceptanceId,
					stage,
					status: getStatus(record, CheckRecordStatusId.REJECTED),
					record
				});
			}
		}, [record, changeCheckItemRecord, item, objectId, spaceId, workAcceptanceId, stage]);

		const partiallyAccept = useCallback(() => {
			if (record) {
				changeCheckItemRecord({
					item,
					objectId,
					spaceId,
					workAcceptanceId,
					stage,
					status: getStatus(record, CheckRecordStatusId.PARTIALLY_ACCEPTED),
					record
				});
			}
		}, [record, changeCheckItemRecord, item, objectId, spaceId, workAcceptanceId, stage]);

		return active || record?.status !== CheckRecordStatusId.ACCEPTED ? (
			<ClickPreventWrap className="check-list-items__item-actions">
				{/* TODO: переверстать кнопку */}
				{(!availableStatuses ||
					availableStatuses.includes(CheckRecordStatusId.REJECTED)) && (
					<ItemButton
						onClick={reject}
						{...buttonProps}
						status={CheckRecordStatusId.REJECTED}
						icon={closeIcon}
						disabled={disabled}
					/>
				)}

				{(!availableStatuses ||
					availableStatuses.includes(CheckRecordStatusId.PARTIALLY_ACCEPTED)) && (
					<ItemButton
						onClick={partiallyAccept}
						{...buttonProps}
						status={CheckRecordStatusId.PARTIALLY_ACCEPTED}
						icon={percentIcon}
						disabled={disabled}
					/>
				)}

				{(!availableStatuses ||
					availableStatuses.includes(CheckRecordStatusId.ACCEPTED)) && (
					<ItemButton
						onClick={accept}
						{...buttonProps}
						status={CheckRecordStatusId.ACCEPTED}
						icon={markIcon}
						disabled={disabled}
					/>
				)}

				{closingDialog}
			</ClickPreventWrap>
		) : null;
	}
);
