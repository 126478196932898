interface ICustomCursorProps {
	className?: string;
	height?: number;
	points?: Array<{x: number, y: number}>;
}

const CustomCursor = (props: ICustomCursorProps) => {
	const {className, height = 0, points} = props;

	let x = 0;
	let y = 0;
	if (points?.length) {
		[{x, y}] = points;
	}

	return (
		<rect
			className={className}
			width="52"
			height={height + 80}
			x={x - 26}
			y={y - 30}
			rx="26"
			fill="#C4D1E3"
			fillOpacity={0.2}
		/>
);
};

export default CustomCursor;
