import {MouseEvent, useCallback} from 'react';
import './TreeSelectTagOption.less';
import SelectOption, {ISelectOptionProps} from '../SelectOption';
import classNames from 'classnames';
import IconButton from '../../../buttons/IconButton';
import {AnimatePresence, motion} from 'framer-motion';
import {ITwoWayTreeItem} from '@tehzor/tools/interfaces/ITwoWayTreeItem';
import {useTreeCtx} from '../../../Tree/utils/TreeCtx';
import {ITreeProblemTagsSetDataItem} from '../../../ProblemTagsSelect';
import {Tag} from '../../../tags';

export interface ITreeSelectTagOptionProps extends ISelectOptionProps {
	data: ITwoWayTreeItem<ITreeProblemTagsSetDataItem>;
	level: number;
	multiple?: boolean;
	disabled?: boolean;
}

const animationVariants = {
	opened: {opacity: 1, height: 'auto'},
	collapsed: {
		opacity: 0,
		height: 0,
		transition: {
			duration: 0.25,
			// Анимация до своих потомков необходима для избежания бага с резким
			// сворачиванием всех потомков при сворачивании родителя
			when: 'beforeChildren',
			staggerChildren: 0.5
		}
	}
};

const animationTransition = {type: 'tween', duration: 0.25};

export const TreeSelectTagOption = ({
	data,
	level,
	multiple,
	disabled: treeDisabled
}: ITreeSelectTagOptionProps) => {
	const {id, content, color, children, disabled, nonCheckable} = data;

	const {isExpanded, isChecked, isSemiChecked, changeExpanded, changeChecked} = useTreeCtx();
	const expanded = isExpanded(id);
	const checked = isChecked(id);
	const semiChecked = isSemiChecked(id);
	const inputType = !nonCheckable ? (multiple ? 'checkbox' : 'radio') : null;
	const handleExpand = useCallback(
		(event: MouseEvent) => {
			event.stopPropagation();
			changeExpanded(id, !expanded);
		},
		[id, expanded, changeExpanded]
	);

	const handleCheck = useCallback(() => {
		changeChecked(data, !checked);
	}, [data, checked, changeChecked]);

	return (
		<li className="tree-select-tag-option-wrap">
			<SelectOption
				dataTestId={`TreeItem_${level}`}
				className={{
					root: classNames(
						'tree-select-tag-option',
						`tree-select-tag-option_level_${level}`
					),
					content: classNames('tree-select-tag-option__content')
				}}
				content={
					<>
						{children && (
							<IconButton
								dataTestId="TreeItemExpandBtn"
								className="tree-select-tag-option__expand-btn"
								type="transparent"
								onClick={handleExpand}
							>
								<i
									className={classNames(
										'tz-simple-arrow-20',
										'tree-select-tag-option__expand-btn-icon',
										{
											'tree-select-tag-option__expand-btn-icon_expanded':
												expanded
										}
									)}
								/>
							</IconButton>
						)}
						<Tag
							label={content as string}
							dotColor={color}
							multiline
							className={children ? {label: 'tree-select-tag-option__label'} : ''}
						/>
					</>
				}
				inputType={inputType}
				checked={checked}
				semiChecked={semiChecked}
				disabled={treeDisabled || disabled}
				onClick={handleCheck}
			/>

			{children !== undefined && (
				<AnimatePresence initial={false}>
					{expanded && (
						<motion.ul
							className="tree-select-tag-option__children"
							initial="collapsed"
							animate="opened"
							exit="collapsed"
							variants={animationVariants}
							transition={animationTransition}
						>
							{children.map(item => (
								<TreeSelectTagOption
									key={item.id}
									data={item}
									multiple={multiple}
									disabled={treeDisabled}
									level={level + 1}
								/>
							))}
						</motion.ul>
					)}
				</AnimatePresence>
			)}
		</li>
	);
};

TreeSelectTagOption.displayName = 'TreeSelectTagOption';
