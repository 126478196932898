import {localAuthStorage} from './localAuthStorage';
import {httpRequests} from './httpRequests';
import {MissingTokenError} from '../errors/MissingTokenError';
import {MissingDeviceError} from '../errors/MissingDeviceError';
import {IDevice} from '../interfaces/IDevice';

export interface IUpdateTokensResponse {
	accessToken: string;
	refreshToken: string;
	device: IDevice;
	expires: number;
}
export class TokenUpdater {
	private static instance: TokenUpdater;

	appId: string;

	private refreshTokenPromise: Promise<IUpdateTokensResponse> | null = null;

	constructor() {
		if (!TokenUpdater.instance) {
			TokenUpdater.instance = this;
		}
		return TokenUpdater.instance;
	}

	initialize = (appId: string) => {
		this.appId = appId;
	};

	updateToken(): Promise<IUpdateTokensResponse> {
		if (!this.refreshTokenPromise) {
			this.refreshTokenPromise = this.updateTokens();
		}
		return this.refreshTokenPromise;
	}

	private updateTokens = async (): Promise<IUpdateTokensResponse> => {
		const refreshToken = await localAuthStorage.getRefreshToken();
		const deviceId = await localAuthStorage.getDeviceId();
		return new Promise((resolve, reject) => {
			if (!refreshToken) {
				throw new MissingTokenError();
			}
			if (!deviceId) {
				throw new MissingDeviceError();
			}
			void (async () => {
				try {
					const response = await httpRequests.default.post<IUpdateTokensResponse>('/auth/update-tokens', {
						appId: this.appId,
						refreshToken,
						deviceId
					});
					this.refreshTokenPromise = null;
					void localAuthStorage.setTokens(response.data.accessToken, response.data.refreshToken);
					resolve(response.data);
				} catch (error) {
					this.refreshTokenPromise = null;
					void localAuthStorage.clear();
					reject(error);
				}
			})();
		});
	};
}

export const tokenUpdater = new TokenUpdater();
