import './SpacesChange.less';
import classNames from 'classnames';
import {IChangeComponentProps} from '../interfaces/IChangeComponent';

interface ISpacesChangeProps extends IChangeComponentProps {
	value?: Record<string, string[]>;
}

export const SpacesChange = ({className, style, value}: ISpacesChangeProps) => (
	<div
		className={classNames('history-spaces-change', className)}
		style={style}
	>
		{value
			&& Object.keys(value).map(key => (
				<div
					key={key}
					className="history-spaces-change__line"
				>
					<div className="history-spaces-change__type">
						{key}
						:
					</div>
					<div>{value[key]?.join(', ')}</div>
				</div>
			))}
	</div>
);

SpacesChange.displayName = 'SpacesChange';
