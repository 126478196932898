import {IDndItem, IElementData} from '../interfaces';

/**
 * Получает данные элемента над которым находится курсор
 * 
 * @param items массив элементов
 * @param overItem элемент над которым находится курсор
 * @param overContainer контейнер над которым находится курсор
 */
export const getOverData = <S extends IDndItem>(
	items: S[], overItem?: S, overContainer?: string
): IElementData<S> | undefined => {
	if (!overItem) { return undefined; }

	const id = overItem.id;
	const index = items.findIndex(val => val.id === id);

	return {id, container: overContainer, item: overItem, index};
};