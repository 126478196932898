import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditProblemInspectorsResponse = ILinkedProblem;

/**
 * Изменяет ответственных нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param inspectors пользователи
 * @param inspectorsActiveGroup текущая группа
 */
export const makeProblemInspectorsEditRequest = async (
	objectId: string,
	problemId: string,
	inspectors?: string[],
	inspectorsActiveGroup?: string
) => {
	const response = await httpRequests.withToken.patch<IEditProblemInspectorsResponse>(
		`problems/edit-inspectors/${problemId}`,
		{
			objectId,
			inspectors,
			inspectorsActiveGroup
		}
	);

	return response.data;
};
