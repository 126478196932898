import {IWorkAcceptancesStatsDataRequest} from '@src/interfaces/IWorkAcceptancesStatsData';

export type IWorkAcceptancesTasksDataQueryKey = [] & {
	0: string; // work-acceptances-tasks-data
	1: IWorkAcceptancesStatsDataRequest; // params
};

export const workAcceptancesTasksDataQueryKeys = {
	all: () => ['work-acceptances-tasks-data'],
	data: (params?: IWorkAcceptancesStatsDataRequest) => [
		...workAcceptancesTasksDataQueryKeys.all(),
		params
	]
};
