import {useMemo} from 'react';
import {
	extractProblemsAndSubProblemsByItemIdAsArray,
	extractProblemsByCheckListIdAsArray
} from '@src/store/modules/pages/checkLists/selectors/problems';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {useExtractTreeCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';

/**
 * Вычисляет количество нарушений по каждому статусу для чек-листа или категории
 *
 * @param listId id чек-листа
 * @param itemId id категории
 */
export function useProblemsCountByStatus(
	listId: string,
	itemId?: string
): Array<{status: ProblemStatusId; count: number}> {
	const {data: treeCheckItem} = useExtractTreeCheckItemById(listId, itemId);

	const problems = useAppSelector(state =>
		itemId
			? extractProblemsAndSubProblemsByItemIdAsArray(state, treeCheckItem)
			: extractProblemsByCheckListIdAsArray(state, listId)
	);

	return useMemo(() => {
		const countByStatus = problems.reduce<Record<string, number>>((prev, problem) => {
			if (!prev[problem.status]) {
				prev[problem.status] = 0;
			}
			prev[problem.status] += 1;
			return prev;
		}, {});

		return Object.values(ProblemStatusId)
			.filter(status => status !== ProblemStatusId.EXPIRED)
			.map(status => ({
				status,
				count: countByStatus[status] ?? 0
			}));
	}, [listId, itemId, problems]);
}
