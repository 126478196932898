const domain = 'spaces';

export const GET_SCHEMA_REQUEST = `${domain}/schema/get/request`;
export const GET_SCHEMA_SUCCESS = `${domain}/schema/get/success`;
export const GET_SCHEMA_FAILURE = `${domain}/schema/get/failure`;

export const GET_LIST_REQUEST = `${domain}/list/get/request`;
export const GET_LIST_SUCCESS = `${domain}/list/get/success`;
export const GET_LIST_FAILURE = `${domain}/list/get/failure`;

export const CHANGE_OFFSET = `${domain}/offset/change`;
export const CHANGE_SELECTED = `${domain}/selected/change`;
export const CHANGE_CURRENT_OBJECT = `${domain}/current-object/change`;

export const EXPORT_REQUEST = `${domain}/export/request`;
export const EXPORT_SUCCESS = `${domain}/export/success`;
export const EXPORT_FAILURE = `${domain}/export/failure`;

export const SEND_REQUEST = `${domain}/send/request`;
export const SEND_SUCCESS = `${domain}/send/success`;
export const SEND_FAILURE = `${domain}/send/failure`;

export const GENERATE_REQUEST = `${domain}/generate/request`;
export const GENERATE_SUCCESS = `${domain}/generate/success`;
export const GENERATE_FAILURE = `${domain}/generate/failure`;
