import {CSSProperties, Dispatch, memo, useCallback, useEffect, useMemo} from 'react';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useExtractCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {CategorySelect, EditableFieldLabel} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {makeTreeData} from '@src/core/hooks/queries/categorySets/utils/makeTreeData';

interface ICategoriesSetProps<S, E> {
	className?: string;
	style?: CSSProperties;
	label?: string;
	value?: string | null;
	objectId: string;
	stage: ObjectStageIds;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	exception?: boolean;
	treeCheckOnlyChildren?: boolean;
}

const CategoryMemo = <S extends {categoryId?: string | null}, E>({
	className,
	style,
	label = 'Вид работ',
	value,
	objectId,
	stage,
	editingDispatch,
	required,
	disabled,
	hasError,
	exception,
	treeCheckOnlyChildren = false,
	...restProps
}: ICategoriesSetProps<S, E>) => {
	const {data: sets} = useExtractCategoriesSetsAsArray(objectId, stage);
	const {data: categories} = useExtractAllCategoriesAsArray();
	const {t} = useTranslation();

	const treeData = useMemo(() => makeTreeData(sets, categories, true), [sets, categories]);

	const category = useMemo(() => (value ? [value] : undefined), [value]);

	// обязательное требование заполнения снимается если нет категорий на выбор
	useEffect(() => {
		if (!required || exception) {
			return;
		}

		const isException = !treeData || treeData.length === 0;

		editingDispatch({type: 'update-exception', field: 'categoryId', value: isException});
	}, [exception, required, treeData]);

	const handleChange = useCallback(
		(v: string[]) => {
			editingDispatch({type: 'update', field: 'categoryId', value: v[0]});

			if (required) {
				editingDispatch({type: 'update-error', field: 'categoryId'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
			{...restProps}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<CategorySelect
				dialogTitle={t('components.editableFields.category.dialog.title')}
				treeData={treeData}
				value={category}
				error={
					required && hasError ? t('components.editableFields.category.error') : undefined
				}
				searchPlaceholder={t('components.editableFields.category.searchPlaceholder')}
				dialogAcceptBtnLabel={t('components.editableFields.category.dialog.accept.label')}
				dialogRejectBtnLabel={t('components.editableFields.category.dialog.reject.label')}
				disabled={disabled}
				onChange={handleChange}
				treeCheckOnlyChildren={treeCheckOnlyChildren}
			/>
		</div>
	);
};
export const Category = memo(CategoryMemo) as typeof CategoryMemo;
