import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useTranslation} from 'react-i18next';
import {PageCounter} from '@/shared/ui/PageCounter/PageCounter';
import {useEntitiesFiltersCtx} from '@/shared/lib/EntitiesFilters/hooks/useEntitiesFiltersCtx';
import {IStructuresFiltersState, useStructuresList} from '@/entities/Structures';
import {usePreparedPaginateStructures} from '@/features/Structures/useEnrichedStructures';

interface IStructuresPageHeaderProps {
	objectId: string;
	spaceId: string | undefined;
}

export const StructuresCounter = ({objectId, spaceId}: IStructuresPageHeaderProps) => {
	const {t} = useTranslation();
	const {total} = usePreparedPaginateStructures({objectId, spaceId});
	const {data: structures} = useStructuresList(objectId);
	const {state} = useEntitiesFiltersCtx<IStructuresFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);
	const text = `${
		isFiltersActive
			? t('structuresPage.structuresPageCounter.find')
			: t('structuresPage.structuresPageCounter.total')
	}: ${isFiltersActive ? total : structures ? structures.allIds.length : 0}`;
	return <PageCounter content={text} />;
};
