import {addErrorToast} from '@src/utils/toasts';
import {fieldSettingsSetsQueryKeys} from '@/entities/FieldSettingsSets/model/cache/keys';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import IError from '@tehzor/tools/interfaces/IError';
import {deleteFieldsSettingsSetRequest} from '@/shared/api/fieldsSettingsSets/deleteFieldsSettingsSet';

export const useDeleteFieldSettingsSet = () =>
	useMutation<void, IError, string>({
		mutationFn: async (fieldSettingsSet: string) => {
			await deleteFieldsSettingsSetRequest(fieldSettingsSet);
		},
		mutationKey: fieldSettingsSetsQueryKeys.delete(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsSetsQueryKeys.lists()});
		},
		onError: () => {
			addErrorToast('Набор пользовательских полей', 'не удалось удалить сущность');
		}
	});
