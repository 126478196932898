import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IMarkersListQueryKey, markersQueryKeys} from '../keys';
import {requestMarkers} from '@src/api/backend/markers';

export const useMarkersQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(markersQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IMarkersListQueryKey>) => {
			const [, , planId, problemIds, inspectionIds, ownerAcceptanceIds, taskIds] = queryKey;

			const result = await requestMarkers(
				planId,
				problemIds,
				inspectionIds,
				ownerAcceptanceIds,
				taskIds
			);
			return result;
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
