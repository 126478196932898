import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {DesktopItemDetail} from './detail';
import {useCheckItemsIsLoading} from '@src/core/hooks/queries/checkItems/hooks';
import {EmptyPlate} from '@src/pages/CheckListPage/components/detail/EmptyPlate/EmptyPlate';
import {useTranslation} from "react-i18next";

const inspectionIcon = <i className="tz-inspection" />;

export const DesktopDetailColumn = () => {
	const {t} = useTranslation();
	const itemsLoading = useCheckItemsIsLoading();
	const {itemId} = useStrictParams<{itemId?: string}>();
	return (
		<Plate
			withoutPadding
			className={{root: 'check-item-detail', content: 'check-item-detail__content'}}
		>
			{itemId && (
				<LoadingPanel
					active={itemsLoading}
					style={{height: '100%'}}
				>
					<DesktopItemDetail />
				</LoadingPanel>
			)}

			{!itemId && (
				<div className="check-item-detail__no-content">
					<EmptyPlate
						icon={inspectionIcon}
						text={t('structurePage.desktopDetailColumn.emptyPlate.text')}
					/>
				</div>
			)}
		</Plate>
	);
};
