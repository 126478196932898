import { useCallback } from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import SelectionActions from '../selection/SelectionActions';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {useTranslation} from "react-i18next";

interface ICustomSelectionRowProps extends ISelectionRowProps<ISpaceEntity> {
	objectId: string;
	spaceId: string;
}

const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {t} = useTranslation();
	const {objectId, spaceId, selectedFlatRows, toggleAllRowsSelected} = props;

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				objectId={objectId}
				spaceId={spaceId}
				selectedEntities={selectedFlatRows.map(item => item.original)}
			/>
			<Button
				label={t('spacePage.table.selectionRow.buttons.reset')}
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};

export default SelectionRow;
