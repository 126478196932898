import {memo, useState} from 'react';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useUpdateEffect} from 'react-use';
import {FilterButton, Select2, SelectOption} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useOwnerAcceptancesResolutionsArray} from '@src/core/hooks/queries/ownerAcceptancesResolutions/hooks';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

interface IResolutionFilterProps {
	resolutions?: string[];
}

export const ResolutionsFilter = memo(({resolutions}: IResolutionFilterProps) => {
	const [selectedResolutions, setSelectedResolutions] = useState(resolutions);
	const {data: allResolutions} = useOwnerAcceptancesResolutionsArray();
	const {dispatch} = useEntitiesFiltersCtx();
	const {t} = useTranslation();

	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.ownerAcceptanceResolutions,
		allResolutions
	);

	const handleApply = () => {
		dispatch({resolutions: selectedResolutions});
	};

	const handleClear = () => {
		setSelectedResolutions([]);
	};

	const handleFullClear = () => {
		setSelectedResolutions([]);
		dispatch({resolutions: undefined});
	};

	const handleCancel = () => {
		setSelectedResolutions(resolutions);
	};

	useUpdateEffect(() => {
		setSelectedResolutions(resolutions);
	}, [resolutions]);

	if (!translatedOptions) return null;

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedResolutions?.length}
			count={selectedResolutions?.length}
			footer
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel(
						t('ownerAcceptanceInfoCell.resolutionStatusSelect.label'),
						resolutions,
						translatedOptions
					)}
					active={!!resolutions?.length}
					onClear={handleFullClear}
				/>
			}
		>
			<Select2
				multiple
				value={selectedResolutions}
				onChange={setSelectedResolutions}
			>
				{translatedOptions.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</TranslatedSelectPopup>
	);
});
