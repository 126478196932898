import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IGetWorkAcceptanceStoriesQueryKeyList, workAcceptanceStoriesQueryKeys} from '../keys';
import {requestWorkAcceptanceStories} from '@src/api/backend/workAcceptanceStories';

export const useWorkAcceptanceStoriesQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(workAcceptanceStoriesQueryKeys.lists(), {
		queryFn: async ({
			queryKey
		}: QueryFunctionContext<IGetWorkAcceptanceStoriesQueryKeyList>) => {
			const [, , objectId, workAcceptanceId] = queryKey;
			return requestWorkAcceptanceStories(objectId, workAcceptanceId);
		},
		meta: {
			error: 'при загрузке истории изменений приёмки работ'
		},
		staleTime: 0, // 0 минут
		gcTime: 1000 * 60 * 5 // 5 Минут
	});
};
