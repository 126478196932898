import {memo, useMemo} from 'react';
import {HistoryList, IHistoryListProps, TranslationsHeader} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface TranslatedHistoryList extends Omit<IHistoryListProps, 'translations'> {
	translations?: TranslationsHeader;
}

export const TranslatedHistoryList = memo((props: TranslatedHistoryList) => {
	const {t} = useTranslation();
	const locale = useDateFnsLocale();

	const translations = useMemo<TranslationsHeader>(
		() => ({
			create: t('historyDialog.create'),
			modify: t('historyDialog.modify'),
			delete: t('historyDialog.delete'),
			prefix: t('historyDialog.prefix'),
			defaultParam: t('historyDialog.defaultParam'),
			defaultUser: t('historyDialog.defaultUser')
		}),
		[t]
	);

	const dateOptions = useMemo(() => ({locale}), [locale]);

	return (
		<HistoryList
			{...props}
			translations={translations}
			dateOptions={dateOptions}
		/>
	);
});
