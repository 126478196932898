import {memo, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUsersAsArray} from '@src/core/hooks/queries/users/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

interface IResponsiblesFilterMobileProps {
	respUsers?: string[];
	label?: string;
	onChange: (respUsers?: string[]) => void;
	onExpand?: () => void;
}

export const ResponsiblesFilterMobile = memo(
	({respUsers, label, onChange, onExpand}: IResponsiblesFilterMobileProps) => {
		const {t} = useTranslation();

		const [selectedUsers, setSelectedUsers] = useState(respUsers);
		const [search, setSearch] = useState('');

		const {data: users} = useUsersAsArray();
		const elements = useMemo(
			() => users?.map(user => ({id: user.id, name: user.fullName})),
			[users]
		);
		const filteredElements = useMemo(
			() => (elements ? flatFilter(elements, 'name', search) : []),
			[search, elements]
		);

		useUpdateEffect(() => {
			setSelectedUsers(respUsers);
		}, [respUsers]);

		return (
			<MobileFilter
				label={label ?? t('entitiesFilters.responsiblesFilter.label')}
				elements={filteredElements}
				selected={selectedUsers}
				linkBtnLabel={t('entitiesFilters.allBtn.label')}
				onChange={onChange}
				onExpand={onExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
			/>
		);
	}
);
