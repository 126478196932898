import {onlineManager} from '@tanstack/react-query';
import {useEffect, useState} from 'react';

export const useOnlineManager = () => {
	const [online, setNetworkStatus] = useState(onlineManager.isOnline());
	const unsubscribe = onlineManager.subscribe(isOnline => {
		setNetworkStatus(isOnline);
	});
	useEffect(() => unsubscribe());
	return online;
};
