import {memo, useCallback, useEffect, useRef, useState} from 'react';
import './AddingWarrantyClaimDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IAddingWarrantyClaimRefProps} from './components/AddingWarrantyClaim';
import {IAddingProblemRefProps} from '../AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {useClaimMaps} from './hooks/useClaimMaps';
import {saveClaim} from './utils/saveClaim';
import {useLocalWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/hooks';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';
import {useQueryClient} from '@tanstack/react-query';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {useTranslation} from 'react-i18next';

export type AddingWarrantyClaimView = 'claim' | 'problem' | undefined;

interface IAddingWarrantyClaimDialogProps {
	objectId: string;
	spaceId?: string;
	claimId?: string;
	links?: IWarrantyClaim['links'];
	defaultProblemData?: ISavingProblem;
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const addingWarrantyClaimDialogClassNames = {
	root: 'adding-warranty-claim-dialog',
	header: 'adding-warranty-claim-dialog__header',
	body: 'adding-warranty-claim-dialog__body'
};

const AddingWarrantyClaimDialog = (props: IAddingWarrantyClaimDialogProps) => {
	const {t} = useTranslation();

	const {objectId, spaceId, claimId, links, defaultProblemData, isOpen, onClose, onSuccess} =
		props;

	const [selectedWarrantyClaimId, setSelectedWarrantyClaimId] = useState<string | undefined>(
		claimId
	);

	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingWarrantyClaimRefProps>(null);

	const [view, setView] = useState<AddingWarrantyClaimView>();
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const {data: localWarrantyClaims} = useLocalWarrantyClaims();
	const localWarrantyClaimsIds = localWarrantyClaims?.map(claim => claim.id);

	useEffect(() => {
		setView(!selectedWarrantyClaimId ? 'claim' : 'problem');
	}, [selectedWarrantyClaimId]);

	const stage = ObjectStageIds.WARRANTY;
	const {data: stages} = useObjectStagesAsArray();
	const [contentMap, entitiesSelectMapProps] = useClaimMaps({
		objectId,
		claimId,
		spaceId,
		stage,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedWarrantyClaimId,
		setSelectedWarrantyClaimId,
		defaultProblemData,
		onClose
	});

	const queryClient = useQueryClient();

	const handleSave = useCallback(async () => {
		const updateLatest = async () => {
			if (selectedWarrantyClaimId) {
				await queryClient.invalidateQueries({
					queryKey: [...warrantyClaimsQueryKeys.detail(selectedWarrantyClaimId), objectId]
				});
			}
		};
		await saveClaim({
			addingEntityRef,
			localWarrantyClaimsIds,
			view,
			online,
			updateLatest,
			selectedWarrantyClaimId,
			success,
			onClose,
			setSaving
		});
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [
		localWarrantyClaimsIds,
		objectId,
		onClose,
		onSuccess,
		online,
		queryClient,
		selectedWarrantyClaimId,
		view
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const getEntitySelectContainerSubTitle = useCallback(
		(objectStageName: string): string =>
			t('globalAddingEntityDialog.entitySelectContainer.getEntitySelectContainerSubTitle', {
				name: objectStageName
			}),
		[t]
	);

	return (
		<Dialog
			className={addingWarrantyClaimDialogClassNames}
			isOpen={isOpen}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					selectedStage={stage}
					stages={stages}
					getEntitySelectContainerSubTitle={getEntitySelectContainerSubTitle}
				/>
			)}
		</Dialog>
	);
};

export default memo(AddingWarrantyClaimDialog);
