import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {LinkButton} from '@tehzor/ui-components';
import { useCallback, useMemo } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckListLink} from '@tehzor/tools/utils/links';
import {getCheckList} from '@src/store/modules/app/links/actions/getCheckList';
import { useTranslation } from 'react-i18next';

const externalLinkIcon = <i className="tz-external-link-16"/>;

export const CheckListEntityLink = ({
	checkListId, objectId, spaceId, workAcceptanceId	
}: Record<string, string>) => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();
	const checkList = useAppSelector(s => s.app.links.checkListId?.[checkListId]);
	const {t} = useTranslation();

	const checkListLink = useMemo(() => (checkList ? formCheckListLink({
		listId: checkListId,
		objectId,
		spaceId,
		stage: checkList?.stage,
		workAcceptanceId
	}) : undefined),
	[
		checkListId,
		objectId,
		checkList?.stage,
		workAcceptanceId
	]);

	useAsync(async () => {
		await Promise.all([dispatch(getCheckList(checkListId))]);
	}, [checkListId]);

	const handleClick = useCallback(() => {
		if (checkListLink) {
			pushPath(checkListLink);
		}
	}, [checkListLink]);

	if (!checkList) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${t('components.entityLinks.checkListEntityLink.label')} "${checkList.name}"`}
			leftIcon={externalLinkIcon}
			onClick={handleClick}
			disabled={!checkListLink}
			type="filled"
		/>
	);
};
