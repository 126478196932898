import {useRef, useState} from 'react';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {Outlet, Route, Routes} from 'react-router-dom';
import {Table} from '../table/Table.desktop';
import {useWorkAcceptanceDesktopColumns} from '../table/columns.desktop';
import {MainActionsDesktop} from '../actions/MainActions.desktop';
import {DesktopDescription} from '../description/Description.desktop';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {AdaptiveHeightPanel, Plate} from '@tehzor/ui-components';
import {useEntityAndCommentsViewerDesktop} from '@src/core/hooks/imagesViewers/desktop/useEntityAndCommentsViewerDesktop';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useCommentsCtx} from '@src/components/Comments/utils/CommentsCtx';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import DesktopComments from '@src/components/Comments/components/Comments.desktop';

interface IDesktopRoutesProps {
	workAcceptance: IWorkAcceptance;
	objectId: string;
	checkLists: ICheckList[];
	settings?: IEntitySettings;
}

export const DesktopRoutes = ({
	workAcceptance,
	objectId,
	checkLists,
	settings
}: IDesktopRoutesProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const workAcceptanceDesktopColumns = useWorkAcceptanceDesktopColumns();
	const pageRef = useRef<HTMLDivElement>(null);
	const {comments, entityPermissions} = useCommentsCtx();
	const [imagesViewer, , openCommentImage] = useEntityAndCommentsViewerDesktop(
		objectId,
		workAcceptance.id,
		workAcceptance,
		AttachmentFileDestination.WORK_ACCEPTANCE,
		AttachmentFileDestination.WORK_ACCEPTANCE_COMMENT_OR_REPLY,
		comments,
		'work-acceptance',
		'Фото приемки работ'
	);
	const problemsRoute = (
		<Route
			path="entities"
			element={
				<div className="work-acceptance-page__d-column work-acceptance-page__d-column_entities">
					<div className="work-acceptance-page__p-controls">
						<MainActionsDesktop
							objectId={objectId}
							workAcceptance={workAcceptance}
						/>
					</div>

					<Plate withoutPadding>
						<Table
							objectId={objectId}
							columns={workAcceptanceDesktopColumns}
							selectedRows={selectedRows}
							onSelectedRowsChange={setSelectedRows}
							workAcceptanceId={workAcceptance.id}
							checkLists={checkLists}
						/>
					</Plate>
				</div>
			}
		/>
	);

	const descriptionRoute = (
		<Route
			path=""
			element={
				<div className="work-acceptance-page__d-column work-acceptance-page__d-column_entities">
					<div className="work-acceptance-page__d-column_description">
						<DesktopDescription
							objectId={objectId}
							workAcceptance={workAcceptance}
							settings={settings}
						/>
						{(entityPermissions.canViewComments ||
							entityPermissions.canViewReplies) && (
							<div className="work-acceptance-page__d-column work-acceptance-page__d-column_comments">
								<AdaptiveHeightPanel pageRef={pageRef.current}>
									<Plate
										className={{
											root: 'work-acceptance-page__d-comments-plate',
											content:
												'work-acceptance-page__d-comments-plate-content'
										}}
									>
										<DesktopComments
											objectId={objectId}
											commentTypeId={ICommentTypeId.WORK_ACCEPTANCE_COMMENT}
											onImageClick={openCommentImage}
										/>
									</Plate>
								</AdaptiveHeightPanel>
							</div>
						)}
					</div>
					{imagesViewer}
				</div>
			}
		/>
	);

	return (
		<>
			<Routes>
				{problemsRoute}
				{descriptionRoute}
			</Routes>
			<Outlet />
		</>
	);
};
