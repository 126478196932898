import createReducer from '@tehzor/tools/utils/createReducer';
import {
	ADD_LOCAL_OWNER_ACCEPTANCE,
	CHANGE_LOCAL_OWNER_ACCEPTANCE_MODIFIED_AT,
	DELETE_LOCAL_OWNER_ACCEPTANCE
} from '@src/constants/local-entities/ownerAcceptance';
import {ILocalOwnerAcceptance} from '@src/interfaces/ILocalOwnerAcceptance';

type ILocalOwnerAcceptancesState = ILocalOwnerAcceptance[];

const ownerAcceptances = createReducer<ILocalOwnerAcceptancesState>([], {
	[ADD_LOCAL_OWNER_ACCEPTANCE]: (state, payload: {ownerAcceptance: ILocalOwnerAcceptance}) => [
		...state,
		payload.ownerAcceptance
	],
	[CHANGE_LOCAL_OWNER_ACCEPTANCE_MODIFIED_AT]: (
		state,
		payload: {key: string, modifiedAt: number}
	) =>
		state.map(item => {
			if (item.key === payload.key) {
				item.modifiedAt = payload.modifiedAt;
				return item;
			}
			return item;
		}),
	[DELETE_LOCAL_OWNER_ACCEPTANCE]: (state, payload: {key: string}) =>
		state.filter(item => item.key !== payload.key)
});

export {ILocalOwnerAcceptancesState};
export default ownerAcceptances;
