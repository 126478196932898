import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {
	useExtractCategoriesSetsAsArray,
	useExtractFullCategoriesSetsData
} from '@src/core/hooks/queries/categorySets/hook';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

interface ICategoriesSetFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	onChange: (changes: IChanges) => void;
}

export const CategoriesSetFilterMobile = memo(
	({objectId, value, entity, onChange, label}: ICategoriesSetFilterMobileProps) => {
		const [selected, setSelected] = useState<string[] | undefined>([]);
		const [search, setSearch] = useState('');
		const {data: categoriesSets} = useExtractCategoriesSetsAsArray(objectId || 'all');
		const {data: allCategories} = useExtractAllCategoriesAsArray();
		const {t} = useTranslation();
		const translatedLabel = label ?? t('entitiesFilters.CategoriesSetFilter.label');

		const elements = useMemo(() => {
			if (!categoriesSets?.length || !allCategories) {
				return [];
			}
			if (categoriesSets?.length > 1) {
				return categoriesSets.map(val => ({id: val.id, name: val.name}));
			}

			return allCategories[categoriesSets[0].id].map(val => ({id: val.id, name: val.name}));
		}, [categoriesSets, allCategories]);
		const filteredElements = useMemo(
			() => flatFilter(elements, 'name', search),
			[elements, search]
		);

		const {data: fullCategoriesSetsData} = useExtractFullCategoriesSetsData();
		const fullCategoriesSetsMap = fullCategoriesSetsData?.byId;

		useEffect(() => {
			if (categoriesSets && categoriesSets.length > 1) {
				const selectedSets = categoriesSets.reduce((prev, current) => {
					if (
						!prev.includes(current.id) &&
						current.categories.some(category => value?.includes(category.id))
					) {
						return [...prev, current.id];
					}

					return prev;
				}, [] as string[]);

				setSelected(selectedSets);
				return;
			}

			setSelected(value);
		}, [categoriesSets, value]);

		const handleChange = useCallback(
			(v: string[] | undefined) => {
				const categories: string[] | undefined = v ? [] : undefined;

				if (v && categories && categoriesSets?.length && fullCategoriesSetsMap) {
					if (categoriesSets.length > 1) {
						v.forEach(id =>
							categories.push(
								...fullCategoriesSetsMap[id].categories.map(category => category.id)
							)
						);
					}
					if (categoriesSets.length === 1) {
						categories.push(...v);
					}
				}

				setSelected(categories);
				onChange({categories});
			},
			[fullCategoriesSetsMap, categoriesSets?.length, onChange]
		);

		const handleExpand = useFilterPageTransition(objectId, entity, 'categories');

		return (
			<MobileFilter
				label={translatedLabel}
				elements={filteredElements}
				selected={selected}
				linkBtnLabel={t('problemsFiltersPage.allBtn.label')}
				onChange={handleChange}
				onExpand={handleExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
			/>
		);
	}
);
