import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {memo, useRef} from 'react';
import PageBreadcrumbs from './PageBreadcrumbs';
import DesktopAttachments from './attachments/Attachments.desktop';
import {DesktopControls} from './Controls.desktop';
import Info from './info/Info.desktop';
import DesktopComments from '@src/components/Comments/components/Comments.desktop';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useEntityAndCommentsViewerDesktop} from '@src/core/hooks/imagesViewers/desktop/useEntityAndCommentsViewerDesktop';
import {EntityLinks} from '@src/components/EntityLinks/EntityLinks';
import {extractHasLocationComments} from '@src/store/modules/pages/problem/selectors/problem';
import {AdaptiveHeightPanel, Plate} from '@tehzor/ui-components';
import {IPermissions} from '../hooks/usePermissions';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {useCommentsCtx} from '@src/components/Comments/utils/CommentsCtx';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';
import {ContractLink} from '@src/components/EntityLinks/components/ContractLink';
import {EntityProblemTasks} from './tasks/EntityProblemTasks';
import {DesktopEntityDocs} from '@src/components/EntityDocs';

interface IDesktopProps {
	objectId: string;
	problemId: string;
	problem?: IPreparedProblem;
	permissions: IPermissions;
	settings?: IEntitySettings;
}

const Desktop = memo(({objectId, problemId, problem, permissions, settings}: IDesktopProps) => {
	const pageRef = useRef<HTMLDivElement>(null);
	const hasLocationComments = useAppSelector(extractHasLocationComments);
	const {comments, entityPermissions} = useCommentsCtx();
	const [imagesViewer, openEntityImage, openCommentImage] =
		useEntityAndCommentsViewerDesktop<ILinkedProblem>(
			objectId,
			problemId,
			problem,
			AttachmentFileDestination.PROBLEM,
			AttachmentFileDestination.COMMENT_OR_REPLY,
			comments,
			'problem',
			'Фото нарушения'
		);

	if (!problem) {
		return null;
	}

	return (
		<div
			className="page-cont problem-page"
			ref={pageRef}
		>
			<PageBreadcrumbs objectId={objectId}/>

			<div className="problem-page__d-columns">
				<div className="problem-page__d-column problem-page__d-column_attachments">
					{settings?.builtin.attachments && (
						<DesktopAttachments
							problem={problem}
							onImageClick={openEntityImage}
						/>
					)}

					<DesktopEntityDocs id={problemId}/>

					<EntityProblemTasks
						objectId={objectId}
						problem={problem}
						isDesktop
					/>

					<Plate withoutPadding>
						<EntityLinks links={problem.links ? {objectId, ...problem.links} : {}}>
							{problem.contractId && permissions.canViewContract && (
								<ContractLink contractId={problem.contractId} />
							)}
						</EntityLinks>
					</Plate>
				</div>

				<div className="problem-page__d-column problem-page__d-column_info">
					<DesktopControls
						objectId={objectId}
						problem={problem}
						permissions={permissions}
					/>

					<Plate>
						<Info
							objectId={objectId}
							problem={problem}
							hasLocationComments={hasLocationComments}
							permissions={permissions}
							settings={settings}
						/>
					</Plate>
				</div>

				<div className="problem-page__d-column problem-page__d-column_comments">
					{(entityPermissions.canViewComments || entityPermissions.canViewReplies) && (
						<AdaptiveHeightPanel pageRef={pageRef.current}>
							<Plate
								className={{
									root: 'problem-page__d-comments-plate',
									content: 'problem-page__d-comments-plate-content'
								}}
							>
								<DesktopComments
									objectId={objectId}
									commentTypeId={ICommentTypeId.PROBLEM_COMMENT}
									onImageClick={openCommentImage}
								/>
							</Plate>
						</AdaptiveHeightPanel>
					)}
				</div>

				{imagesViewer}
			</div>
		</div>
	);
});

export default Desktop;
