import './WorkAcceptanceHistoryDialog.less';
import {ModalDialog} from '@tehzor/ui-components';
import {useEnrichedHistories} from './hooks/useEnrichedHistories';
import {useTranslation} from 'react-i18next';
import {TranslatedHistoryList} from '@src/components/TranslatedHistoryList';

interface IWorkAcceptanceHistoryDialogProps {
	objectId: string;
	workAcceptanceId: string;
	isOpen: boolean;
	onClose: () => void;
}
const workAcceptanceHistoryDialogClassName = {
	root: 'work-acceptance-history-dialog',
	content: 'work-acceptance-history-dialog__content'
};
export const WorkAcceptanceHistoryDialog = (props: IWorkAcceptanceHistoryDialogProps) => {
	const {t} = useTranslation();
	const {objectId, workAcceptanceId, isOpen, onClose} = props;
	const stories = useEnrichedHistories(objectId, workAcceptanceId);

	return (
		<ModalDialog
			className={workAcceptanceHistoryDialogClassName}
			open={isOpen}
			title={t('workAcceptancePage.workAcceptanceHistoryDialog.title')}
			fullScreenOnMobile
			onRequestClose={onClose}
		>
			<TranslatedHistoryList
				entityName={t('workAcceptancePage.historyList.entityName')}
				data={stories}
			/>
		</ModalDialog>
	);
};
