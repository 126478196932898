import { memo } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import {DesktopActions} from '@src/pages/WorkAcceptancesPage/components/actions/Actions.desktop';

interface IPageBreadcrumbsProps {
	objectId: string;
	isDesktop?: boolean;
}

export const ObjectPageBreadcrumbs = memo(({objectId, isDesktop}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useObjectBreadcrumbs(objectId);

	return (
		<AppBreadcrumbs
			className="inspections-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && <DesktopActions objectId={objectId} />}
		</AppBreadcrumbs>
	);
});
