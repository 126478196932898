export enum OfflineModeStatus {
	UNKNOWN = 'unknown',
	SAVING = 'saving',
	SAVING_COMPLETE = 'saving-complete',
	SAVING_ERROR = 'saving-error',
	CACHING = 'caching',
	CACHING_ERROR = 'caching-error',
	ONLINE_READY = 'online-ready',
	ONLINE_CACHE_MISSING = 'online-cache-missing',
	OFFLINE_READY = 'offline-ready',
	OFFLINE_ERROR = 'offline-error',
	OFFLINE_CACHE_MISSING = 'offline-cache-missing'
}
