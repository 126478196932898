import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import decomposeApartmentNumber from '@tehzor/tools/utils/decomposeApartmentNumber';

type GroupSpacesByFloorType = [string[], { [floor: string]: IConvertedSpace[] }];

function compareSpaces(a: IConvertedSpace, b: IConvertedSpace): number {
	const [aNum = 0, aStr = ''] = decomposeApartmentNumber(a.sortBase ? a.sortBase : a.name);
	const [bNum = 0, bStr = ''] = decomposeApartmentNumber(b.sortBase ? b.sortBase : b.name);
	return aNum === bNum ? (aStr > bStr ? 1 : -1) : aNum - bNum;
}

const compareFloors = (a: string, b: string, reverseSort?: boolean): number => (reverseSort ? +b - +a : +a - +b);

/**
 * Группирует квартиры по этажам
 *
 * @param {IConvertedSpace[]} data список квартир
 * @param reverseSort
 */
export function groupSpacesByFloor(data: IConvertedSpace[], reverseSort?: boolean): GroupSpacesByFloorType {
	const groups: Record<string, IConvertedSpace[]> = {};

	for (const space of data) {
		if (space.floor !== undefined) {
			if (!groups[space.floor]) {
				groups[space.floor] = [];
			}
			groups[space.floor].push(space);
		}
	}

	for (const floor in groups) {
		if (groups.hasOwnProperty(floor)) {
			groups[floor] = groups[floor].sort(compareSpaces);
		}
	}

	const floors = Object.keys(groups).sort((a, b) => compareFloors(a, b, reverseSort));
	return [floors, groups];
}