import {CellProps} from 'react-table';
import { useMemo } from 'react';
import {Tag} from '@tehzor/ui-components';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';
import {useObjectsAsArray} from '@src/core/hooks/queries/objects/hooks';

export const CheckListObjectsCell = ({row}: CellProps<IPreparedCheckList>) => {
	const rowObjects = row.original.objects || [];
	const objects = useObjectsAsArray();

	const selectedObjects = useMemo(() => {
		if (!objects) return [];
		return objects.filter(object => rowObjects.includes(object.id));
	}, [objects, row.original.objects]);

	return (
		<>
			{selectedObjects.length < 4 ? (
				selectedObjects.map(selectedObject => (
					<Tag
						className="listTag"
						key={selectedObject.id}
						label={selectedObject.name}
					/>
				))
			) : (
				<>
					{selectedObjects.slice(0, 3).map(selectedObject => (
						<Tag
							className="listTag"
							key={selectedObject.id}
							label={selectedObject.name}
						/>
					))}
					<Tag
						color="#EEF5FB"
						label={`+${selectedObjects.length - 3}`}
					/>
				</>
			)}
		</>
	);
};
