import { useCallback, useState } from 'react';
import * as React from 'react';
import AddingOwnerAcptDialog from '../AddingOwnerAcptDialog';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';

interface IAddingOwnerAcptDialogProps {
	objectId: string;
	spaceId?: string;
	acceptanceId?: string;
	links?: IOwnerAcceptance['links'];
	defaultProblemData?: ISavingProblem;
	onSuccess?: () => void | Promise<void>;
	title?: string;
}

export function useAddingOwnerAcptDialog({
	objectId,
	spaceId,
	acceptanceId,
	links,
	defaultProblemData,
	onSuccess,
	title = 'Добавить'
}: IAddingOwnerAcptDialogProps): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = isOpen ? (
		<AddingOwnerAcptDialog
			title={title}
			objectId={objectId}
			spaceId={spaceId}
			acceptanceId={acceptanceId}
			links={links}
			defaultProblemData={defaultProblemData}
			isOpen={isOpen}
			onClose={close}
			onSuccess={onSuccess}
		/>
	) : null;

	return [dialog, open];
}
