import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {TFunction} from 'i18next';

export const createContractorDifference = (
	prev: string | undefined,
	next: string | undefined,
	contractors: INormalizedData<ICompany> | undefined,
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev: prev ? contractors?.byId[prev]?.name : undefined,
	next: next ? contractors?.byId[next]?.name : undefined,
	type: HistoryTypeId.DESCRIPTION,
	name: t('createDifference.createContractorDifference')
});
