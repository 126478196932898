import {combineReducers} from 'redux';
import section from './section';
import building from '../building/reducers';
import acceptance from '../acceptance/reducers';
import warranty from '../warranty/reducers';

export default combineReducers({
	section,
	building,
	acceptance,
	warranty
});
