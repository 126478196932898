import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {Column} from 'react-table';
import RelationsCell from './cells/RelationsCell';
import DeleteCell from './cells/DeleteCell';
import NameCell from './cells/NameCell';
import UserCell from './cells/UserCell';
import StagesCell from './cells/StagesCell';
import {TFunction} from "i18next";
export const getDesktopColumns = (tFunc: TFunction): Array<Column<IFullCategoriesSet>> => (
	[
		{
			id: 'name',
			Header: tFunc('categoriesSetsPage.table.columns.name.header'),
			accessor: 'name',
			Cell: NameCell,
			width: 80
		},
		{
			id: 'companies',
			Header: tFunc('categoriesSetsPage.table.columns.companies.header'),
			accessor: 'companies',
			Cell: RelationsCell,
			width: 90
		},
		{
			id: 'createdBy.displayName',
			Header: tFunc('categoriesSetsPage.table.columns.createdBy.header'),
			accessor: 'createdBy',
			Cell: UserCell,
			width: 90
		},
		{
			id: 'stages',
			Header: tFunc('categoriesSetsPage.table.columns.stages.header'),
			Cell: StagesCell,
			width: 60
		},
		{
			id: 'createdAt',
			Header: tFunc('categoriesSetsPage.table.columns.createdAt.header'),
			accessor: row => (row.createdAt ? format(row.createdAt, dateTimeCommaSeparatedFormat) : ''),
			width: 40
		},
		{
			id: 'modifiedAt',
			Header: tFunc('categoriesSetsPage.table.columns.modifiedAt.header'),
			accessor: row =>
				(row.modifiedAt ? format(row.modifiedAt, dateTimeCommaSeparatedFormat) : ''),
			width: 40
		},
		{
			id: '_delete',
			Cell: DeleteCell,
			width: 80,
			minWidth: 68,
			disableResizing: true,
			isNonClickable: true
		}
	]
)

export const getDesktopColumnsWithoutObjects = (tFunc: TFunction) => getDesktopColumns(tFunc).filter(col => col.id !== 'companies');
