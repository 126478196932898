import {IGetWorkingGroupsResponse} from '@src/api/backend/workingGroups';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {extractWorkingGroupsPageFilters} from '@src/store/modules/settings/pages/manage/workingGroups/selectors';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useCallback} from 'react';
import useAppSelector from '../../useAppSelector';
import {useObject} from '../objects/hooks';
import {
	extractAcceptorsGroupsAsArray,
	extractFilteredWorkingGroupsAsArray,
	extractInspectorsGroupsAsArray,
	extractPerformersGroupsAsArray,
	extractWorkingGroupById,
	extractWorkingGroupsAsArray,
	extractWorkingGroupsAsMap,
	extractWorkingGroupsByParentIdAsArray
} from './selectors';

export const useWorkingGroups = <T = IGetWorkingGroupsResponse>(
	select?: (data: IGetWorkingGroupsResponse) => T
) =>
	useQuery<IGetWorkingGroupsResponse, IError, T>({
		queryKey: workingGroupsQueryKeys.list(),
		meta: {
			error: 'при загрузке  рабочих групп'
		},
		select
	});

export const useWorkingGroupsAsArray = () => useWorkingGroups(extractWorkingGroupsAsArray);

export const useWorkingGroupsAsMap = () => useWorkingGroups(extractWorkingGroupsAsMap);

export const useWorkingGroupsByParentIdAsArray = (parentId: string | undefined) => {
	const selector = useCallback(
		(data: IGetWorkingGroupsResponse) => extractWorkingGroupsByParentIdAsArray(data, parentId),
		[parentId]
	);
	return useWorkingGroups(selector);
};

export const useWorkingGroupById = (groupId: string | undefined) => {
	const selector = useCallback(
		(data: IGetWorkingGroupsResponse) => extractWorkingGroupById(data, groupId),
		[groupId]
	);
	return useWorkingGroups(selector);
};
export const useFilteredWorkingGroupsAsArray = () => {
	const filters = useAppSelector(extractWorkingGroupsPageFilters);
	const selector = useCallback(
		(data: IGetWorkingGroupsResponse) => extractFilteredWorkingGroupsAsArray(data, filters),
		[filters]
	);
	return useWorkingGroups(selector);
};

export const useInspectorsGroupsAsArray = (objectId?: string, stage?: ObjectStageIds) => {
	const {data: object} = useObject(objectId);

	const selector = useCallback(
		(data: IGetWorkingGroupsResponse) => extractInspectorsGroupsAsArray(data, object, stage),
		[object, stage]
	);
	return useWorkingGroups(selector);
};

export const usePerformersGroupsAsArray = (objectId?: string, stage?: ObjectStageIds) => {
	const {data: object} = useObject(objectId);

	const selector = useCallback(
		(data: IGetWorkingGroupsResponse) => extractPerformersGroupsAsArray(data, object, stage),
		[object, stage]
	);
	return useWorkingGroups(selector);
};

export const useAcceptorsGroupsAsArray = (objectId?: string, stage?: ObjectStageIds) => {
	const {data: object} = useObject(objectId);

	const selector = useCallback(
		(data: IGetWorkingGroupsResponse) =>
			extractAcceptorsGroupsAsArray(data, object, stage),
		[object, stage]
	);
	return useWorkingGroups(selector);
};
