import {useCallback, useState} from 'react';
import {
	ActionButtons,
	Button,
	EntityGridItem,
	InlineButton,
	ReadMore,
	TextField
} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useEditCheck} from '@src/core/hooks/mutations/checks/useEditCheck';

interface CheckDescriptionProps {
	objectId: string;
	checkId: string;
	value: string | undefined;
	canEdit?: boolean;
}

const descriptionIcon = <i className="tz-description-20" />;
const editIcon = <i className="tz-edit-16" />;

const DescriptionDesktop = ({objectId, checkId, value, canEdit}: CheckDescriptionProps) => {
	const {t} = useTranslation();

	const [isEditable, setIsEditable] = useState(false);
	const [text, setText] = useState(value);
	const {mutateAsync: editCheck, isPending: saving} = useEditCheck();

	const handleChange = useCallback((v: string) => {
		setText(v);
	}, []);

	const handleSave = useCallback(async () => {
		try {
			await editCheck({objectId, checkId, fields: {description: text}});
		} finally {
			setIsEditable(false);
		}
	}, [text, checkId, objectId, editCheck]);

	return (
		<EntityGridItem
			label={t('checksPage.description.title')}
			icon={descriptionIcon}
			fullRow
			buttons={
				canEdit && !isEditable ? (
					<InlineButton
						className="check-page__documents-edit-icon"
						type="accent"
						leftIcon={editIcon}
						onClick={() => setIsEditable(true)}
					/>
				) : null
			}
		>
			{isEditable ? (
				<TextField
					value={text}
					cleanable
					elementType="textarea"
					onChange={handleChange}
					className={{element: 'check-page__documents-description'}}
				/>
			) : value ? (
				<ReadMore
					className={{
						root: 'check-page__documents-description',
						button: 'check-page__documents-description-button'
					}}
					text={value}
					maxLength={100}
				/>
			) : null}
			{isEditable && (
				<ActionButtons className="check-page__info-buttons">
					<Button
						type="accent-blue"
						label={t('checksPage.description.save')}
						disabled={saving}
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label={t('checksPage.description.cancel')}
						disabled={saving}
						onClick={() => {
							setText(value);
							setIsEditable(false);
						}}
					/>
				</ActionButtons>
			)}
		</EntityGridItem>
	);
};
export default DescriptionDesktop;
