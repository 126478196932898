import {IWorkAcceptancesFiltersState, IWorkAcceptancesSortState} from './reducers/byPage';
import * as types from './constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {WorkAcceptanceDisplayMode} from '@src/interfaces/WorkAcceptanceDisplayMode';
import {IGetWorkAcceptancesResponse} from '@src/api/backend/workAcceptances';

export interface IChangeWorkAcceptanceDisplayModePayload {
	objectId: string;
	displayMode: WorkAcceptanceDisplayMode;
}

export interface IGetWorkAcceptancesPayload extends IGetWorkAcceptancesResponse {
	objectId: string;
}

export const workAcceptancesGetRequest = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

export const workAcceptancesGetSuccess = (
	objectId: string,
	response: IGetWorkAcceptancesResponse
) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	}
});

/**
 * Изменяет способ отображения страницы списка помещений
 *
 * @param objectId id объекта
 * @param displayMode способ отображения
 */
export const changeWorkAcceptanceDisplayMode = (
	objectId: string,
	displayMode: WorkAcceptanceDisplayMode
) => ({
	type: types.CHANGE_DISPLAY_MODE,
	payload: {
		objectId,
		displayMode
	} as IChangeWorkAcceptanceDisplayModePayload
});

/**
 * Изменяет фильтр
 *
 * @param objectId id объекта
 * @param filters свойства фильтра
 */
export const changeFilters = (objectId = 'all', filters: IWorkAcceptancesFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: {
		objectId,
		filters
	} as IChangeFiltersPayload<IWorkAcceptancesFiltersState>
});

/**
 * Очищает фильтр
 *
 * @param objectId id объекта
 */
export const clearFilters = (objectId = 'all') => ({
	type: types.CLEAR_FILTERS,
	payload: {objectId} as IClearFiltersPayload
});

/**
 * Изменяет сортировку
 *
 * @param objectId id объекта
 * @param sort параметры сортировки
 */
export const changeSort = (objectId = 'all', sort: IWorkAcceptancesSortState) => ({
	type: types.CHANGE_SORT,
	payload: {
		objectId,
		sort
	} as IChangeSortPayload<IWorkAcceptancesSortState>
});

/**
 * Изменяет количество элементов на странице
 *
 * @param objectId id объекта
 * @param pageSize количество элементов на странице
 */
export const changePageSize = (objectId = 'all', pageSize: number) => ({
	type: types.CHANGE_PAGE_SIZE,
	payload: {
		objectId,
		pageSize
	} as IChangePageSizePayload
});

/**
 * Создает initial state для конкретного объекта
 *
 * @param objectId id объекта
 */
export const setInitialState = (objectId = 'all') => ({
	type: types.SET_INITIAL_STATE,
	payload: {
		objectId
	} as const
});

/**
 * Изменяет смещение списка
 *
 * @param objectId id объекта
 * @param offset смещение
 */
export const changeOffset = (objectId = 'all', offset: number) =>
	({
		type: types.CHANGE_OFFSET,
		payload: {
			objectId,
			offset
		}
	}) as const;

/**
 * Изменяет выбранные строки в таблице
 *
 * @param objectId id объекта
 * @param selectedRows массив id выбранных строк
 */
export const changeSelectedRows = (objectId = 'all', selectedRows: string[]) =>
	({
		type: types.CHANGE_SELECTED_ROWS,
		payload: {
			objectId,
			selectedRows
		}
	}) as const;

/**
 * Переключает флаг выбора всех строк в таблице
 *
 * @param objectId id объекта
 * @param isAllRowsSelected true/false - включить/выключить, undefined - поменять на противоположное значение
 */
export const toggleAllRowsSelected = (objectId = 'all', isAllRowsSelected?: boolean) =>
	({
		type: types.TOGGLE_ALL_ROWS_SELECTED,
		payload: {
			objectId,
			isAllRowsSelected
		}
	}) as const;
