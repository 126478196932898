const num = /\d+/;

/**
 * Разбирает номер квартиры на число и строковую часть
 *
 * @param {string} fullNumber номер квартиры в формате строки
 */
export default function decomposeApartmentNumber(fullNumber: string): [number | undefined, string | undefined] {
	const nResult = num.exec(fullNumber);
	const s = fullNumber.replace(num, '').trim();

	return [
		nResult && nResult.length ? +nResult[0] : undefined,
		s || undefined
	];
}
