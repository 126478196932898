import {CSSProperties, Dispatch, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useContractsList, useContractsListArray} from '@src/core/hooks/queries/contracts/hooks';
import {IEditableProblemAction} from '@src/core/hooks/states/useEditableProblemState';
import {IContractsFiltersState} from '@src/store/modules/settings/pages/contracts/reducers';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import SelectSearch, {
	flatFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useTranslation} from 'react-i18next';

interface IContractsProps {
	className?: string;
	style?: CSSProperties;
	label?: string;
	value?: string | null;
	objectId?: string;
	stage?: ObjectStageIds;
	planId?: string;
	categoryId?: string | null;
	editingDispatch: Dispatch<IEditableProblemAction>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	exception?: boolean;
}

const arrowIcon = <i className="tz-simple-arrow-20" />;

export const Contracts = memo(
	({
		className,
		style,
		label,
		value,
		objectId,
		stage,
		planId,
		categoryId,
		editingDispatch,
		required,
		disabled,
		hasError,
		exception,
		...restProps
	}: IContractsProps) => {
		const {t} = useTranslation();

		const filters: IContractsFiltersState = useMemo(
			() => ({
				categoryIds: categoryId ? [categoryId] : undefined,
				objectIds: objectId ? [objectId] : undefined,
				stageIds: stage ? [stage] : undefined,
				planIds: planId ? [planId] : undefined
			}),
			[objectId, stage, planId, categoryId]
		);

		const {data: contractsData} = useContractsList(filters);
		const contract = value ? contractsData?.byId[value] : undefined;
		const {data: contracts} = useContractsListArray(filters);

		const [search, setSearch] = useState('');
		const clearSearch = () => setSearch('');
		const isSearchShown = (contracts?.length || 0) >= 8;

		const filteredContracts = useMemo(
			() => (contracts ? flatFilter(contracts, 'name', search) : undefined),
			[contracts, search]
		);

		// Убираем обязательность поля договоров при отсутствии вариантов для выбора
		useEffect(() => {
			if (!required || exception) {
				return;
			}

			const isException = !contracts || contracts.length === 0;
			editingDispatch({
				type: 'update-exception',
				field: 'contractId',
				value: isException
			});
		}, [contracts, editingDispatch, exception, required]);

		const handleChange = useCallback(
			(value: string) => {
				editingDispatch({type: 'update', field: 'contractId', value});
				if (required) {
					editingDispatch({type: 'update-error', field: 'contractId'});
				}
			},
			[required]
		);

		const handleClear = useCallback(() => {
			editingDispatch({type: 'update', field: 'contractId', value: null});
			if (required) {
				editingDispatch({type: 'update-error', field: 'contractId'});
			}
		}, [required]);

		if (!contracts) {
			return null;
		}

		const selectSearch = isSearchShown ? (
			<SelectSearch
				value={search}
				onChange={setSearch}
				onClear={clearSearch}
			/>
		) : null;

		return (
			<div
				className={className}
				style={style}
				{...restProps}
			>
				<EditableFieldLabel>
					{label ?? t('editableProblem.contracts.label')}
				</EditableFieldLabel>
				<SelectPopup
					noHeader={!isSearchShown}
					search={selectSearch}
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={contract?.name}
							icon={arrowIcon}
							error={
								required && hasError
									? t('components.editableFields.contracts.error')
									: undefined
							}
							onClearClick={handleClear}
							disabled={disabled}
							cleanable={!!value}
						/>
					}
				>
					<Select2
						value={value || undefined}
						onChange={handleChange}
					>
						{filteredContracts?.map(contract => (
							<SelectOption
								dataTestId="MenuItem"
								key={contract.id}
								itemKey={contract.id}
								content={contract.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			</div>
		);
	}
);
