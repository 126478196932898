import classNames from 'classnames';
import styles from './SortChanger.module.less';

interface ISortChangerProps {
	isRevers?: boolean;
	onChange: (value: boolean) => void;
}

export const SortChanger = ({isRevers, onChange}: ISortChangerProps) => (
	<i
		className={classNames('tz-table-sort-20', styles.wrapper, isRevers && styles.revers)}
		onClick={() => onChange(!isRevers)}
	/>
);
