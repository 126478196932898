import {StatsTreePanel, VerticalTabContent} from '@tehzor/ui-components';
import {SubObjectStats} from './SubObjectStats';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {ProblemsStatsChart} from './ProblemsStatsChart';
import {sumStatsValues} from '../../../utils/sumStatsValues';
import {StatsByStatus, StatsMap} from '@src/utils/statsAggregators/problemsByStatuses';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import {Tree} from 'array-to-tree';
import {useNavigateProblems} from '../../../hooks/useNavigateProblems';

interface IUserStatsProps {
	user: IBriefUser;
	userStats: StatsByStatus;
	objects: Array<Tree<IStatsObject>>;
	objectsStats: StatsMap;
}

export const UserStats = ({user, userStats, objects, objectsStats}: IUserStatsProps) => {
	const sourcesLoading = useAppSelector(extractSourcesLoading);
	const totalUserCount = userStats?.expired
		? sumStatsValues(userStats) - userStats.expired
		: sumStatsValues(userStats);

	const navigate = useNavigateProblems(
		{
			navProps: [
				{
					type: 'createdBy',
					prop: user.id
				}
			],

			page: 'building'
		},
		undefined
	);

	const handleClick = (status: string) => {
		void navigate(status);
	};

	return (
		<VerticalTabContent className="statistics-page__tabs-content-panel">
			<StatsTreePanel
				id={user.id}
				title={user.fullName}
				subTitle={user.position}
				loading={sourcesLoading}
				showExpandBtnPlaceholder
				permanentContent={(
					<ProblemsStatsChart
						onClick={handleClick}
						stats={userStats}
						totalCount={totalUserCount}
					/>
				)}
				hiddenContent={objects.map(object => (
					<SubObjectStats
						key={object.id}
						data={object}
						stats={objectsStats}
						level={1}
						navigateProps={[{type: 'createdBy', prop: user.id}]}
					/>
				))}
			/>
		</VerticalTabContent>
	);
};
