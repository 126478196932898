import { useCallback } from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {OwnerAcceptancesPage} from './OwnerAcceptancesPage';
import {extractOwnerAcceptancesPageSettings} from '@src/store/modules/settings/pages/ownerAcceptances/selectors';
import {ActionsMobile} from './components/actions/Actions.mobile';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/interfaces';
import {ownerAcceptancesPageSettingsActions} from '@src/store/modules/settings/pages/ownerAcceptances/slice';
import {useTranslation} from "react-i18next";

export const AllOwnerAcceptancesPageWrap = (props: Record<string, unknown>) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	useAppHeader({title: t('ownerAcceptancesPage.title'), mobileRightButtons: <ActionsMobile />});

	const {filters} = useAppSelector(s => extractOwnerAcceptancesPageSettings(s, 'all'));
	const {changeFilters, changeOffset, clearFilters} = ownerAcceptancesPageSettingsActions;

	const applyFilters = useCallback(
		(filters: IOwnerAcceptancesFiltersState) => {
			dispatch(changeFilters({objectId: 'all', filters}));
			dispatch(changeOffset({objectId: 'all', offset: 0}));
		},
		[dispatch, changeFilters, changeOffset]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: 'all'}));
	}, [dispatch, clearFilters]);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			onApply={applyFilters}
			filters={filters}
			onClear={onClear}
		>
			<OwnerAcceptancesPage
				key="all-ownerAcceptances"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};
