import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';

export type ICopyPlanResponse = INormalizedData<IPlan>;

/**
 * Копирует план
 *
 * @param objectId id объекта
 * @param planId id плана
 * @param objectDestinationId id объекта куда копировать
 */
export const makePlanCopyRequest = (
	objectId: string,
	planId: string,
	objectDestinationId: string,
	copies: number
) =>
	wsConnector.sendAuthorizedRequest<ICopyPlanResponse>('copyPlan', {
		objectId,
		planId,
		objectDestinationId,
		copies
	});
