import {useUsersAsMap} from "@src/core/hooks/queries/users/hooks";
import {useObjectsMap} from "@src/core/hooks/queries/objects/hooks";
import {useTaskStatusesMap} from "@src/core/hooks/queries/taskStatuses/hook";
import {useTaskTypesMap} from "@src/core/hooks/queries/taskTypes";
import {useTaskPrioritiesMap} from "@src/core/hooks/queries/task-priorities/hooks";
import {useCallback} from "react";
import {IGetTaskResponse} from "@src/api/backend/task";
import {IEnrichedTask} from "@tehzor/tools/interfaces/tasks/IEnrichedTask";
import {enrichTask} from "@src/utils/tasks/enrichTask";
import {useTask} from "@src/core/hooks/queries/task/hooks";

export const useEnrichedTask = (taskId: string) => {
	const {data: usersMap} = useUsersAsMap();
	const objectsMap = useObjectsMap();
	const {data: taskStatusesMap} = useTaskStatusesMap();
	const {data: taskTypesMap} = useTaskTypesMap();
	const {data: taskPrioritiesMap} = useTaskPrioritiesMap();

	const select = useCallback(
		(task: IGetTaskResponse): IEnrichedTask | undefined =>
			task
				? {
					...enrichTask({
						task,
						objectsMap,
						taskStatusesMap,
						taskPrioritiesMap,
						taskTypesMap,
						usersMap
					}),
					createdAtNumber: task.createdAt,
					modifiedAtNumber: task.modifiedAt
				}
				: undefined,
		[taskId, usersMap, objectsMap, taskStatusesMap, taskTypesMap, taskPrioritiesMap]
	);

	return useTask(taskId, select);
};
