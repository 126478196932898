import {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useManageTaskBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageTaskBreadcrumbs';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {ActionsDesktop} from './actions/Actions.desktop';

interface IDesktopPageBreadcrumbsProps {
	task?: IEnrichedTask;
}

const PageBreadcrumbs = ({task}: IDesktopPageBreadcrumbsProps) => {
	const breadcrumbs = useManageTaskBreadcrumbs(task);

	return (
		<AppBreadcrumbs items={breadcrumbs}>
			{task ? <ActionsDesktop task={task} /> : null}
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
