import {createReducer} from '@reduxjs/toolkit';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	IGetCheckRecordsPayload,
	IChangeStateCheckRecordPayload,
	IEditCheckRecordPayload
} from '../actions';
import * as types from '../constants/records';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';

import {RESET} from '@src/store/modules/pages/checkLists/constants/page';
import {ICheckChangedRecordsPayload} from '@src/store/modules/pages/checkLists/actions/records/checkChangedRecords';

export interface ICheckRecordsState {
	byId: {
		[id: string]: ICheckRecord;
	};
	allIds: string[];
	loading: boolean;
	isChanging: boolean;
}

export const getInitialState = (): ICheckRecordsState => ({
	byId: {},
	allIds: [],
	loading: false,
	isChanging: false

});

export default createReducer<ICheckRecordsState>(getInitialState(), {
	[types.GET_REQUEST]: state => {
		state.loading = true;
	},
	[types.GET_FAILURE]: state => {
		state.loading = false;
	},
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetCheckRecordsPayload}) => {
		state.byId = payload.byId;
		state.allIds = payload.allIds;
		state.loading = false;
	},
	[types.CHECK_SUCCESS]: (state, {payload}: {payload: ICheckChangedRecordsPayload}) => {
		for (const [key, value] of Object.entries(payload.byId)) {
			state.byId[key] = value;

			if (!state.allIds.includes(key)) {
				state.allIds.push(key);
			}
		}
	},
	[types.CHANGE_STATUS_REQUEST]: state => {
		state.isChanging = true;
	},
	[types.CHANGE_STATUS_FAILURE]: state => {
		state.isChanging = false;
	},
	[types.CHANGE_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IChangeStateCheckRecordPayload}
	) => {
		for (const record of payload) {
			state.byId[record.id] = record;

			if (!state.allIds.includes(record.id)) {
				state.allIds.push(record.id);
			}
		}
		state.isChanging = false;
	},
	[types.CHANGE_PERCENT_SUCCESS]: (state, {payload}: {payload: IEditCheckRecordPayload}) => {
		state.byId[payload.id] = payload;

		if (!state.allIds.includes(payload.id)) {
			state.allIds.push(payload.id);
		}
	},
	[types.ADD_SUCCESS]: (state, {payload}: {payload: IEditCheckRecordPayload}) => {
		state.byId[payload.id] = payload;
		state.allIds.push(payload.id);
	},
	[RESET]: getInitialState,
	[CLEAR_STORE]: getInitialState
});
