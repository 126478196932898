import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditTaskStatusResponse = ITask;

/**
 * ОТправляет запрос изменения статуса задачи
 *
 * @param taskId id задачи
 * @param statusId id статуса
 */

export interface IMakeTaskStatusEditRequestData {
	taskId: string;
	statusId: TaskStatusId
}
export const makeTaskStatusEditRequest = async (data: IMakeTaskStatusEditRequestData) => {
	const response = await httpRequests.withToken.patch<IEditTaskStatusResponse>(
		'tasks/status',
		data
	);
	return response.data;
};
