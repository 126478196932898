import {createContext, useContext} from 'react';
import {ITreeDataItem} from '../Tree';
import {ITwoWayTreeItem} from '@tehzor/tools/interfaces/ITwoWayTreeItem';

export interface TreeCtxType {
	isExpanded: (id: string) => boolean;
	isChecked: (id: string) => boolean;
	isSemiChecked: (id: string) => boolean;
	changeExpanded: (id: string, value: boolean) => void;
	changeChecked: (item: ITwoWayTreeItem<ITreeDataItem>, value: boolean) => void;
}

export const TreeCtx = createContext<TreeCtxType>({
	isExpanded: () => false,
	isChecked: () => false,
	isSemiChecked: () => false,
	changeExpanded: () => null,
	changeChecked: () => null
});

export const useTreeCtx = () => useContext(TreeCtx);
