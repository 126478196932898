import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

export type IGetCheckListResponse = ICheckList;

/**
 * Возвращает чек-лист
 *
 * @param id id чек-листа
 */
export const requestCheckList = async (id: string) => {
	const response = await httpRequests.withToken.get<IGetCheckListResponse>(`check-lists/${id}`);
	return response.data;
};
