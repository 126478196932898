import {formExportResponse} from '@src/utils/formExportResponse';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export const requestGetContractAttachment = async (
	contractId: string, fileKey: string
): Promise<{
	fileName: string,
	fileContent: Blob;
	warnings?: Record<string, unknown>
}> => {
	const response = await httpRequests.fileWithToken.get<Blob>(
		'contracts/attachment', {params: {contractId, fileKey}}
	);
	return formExportResponse(response);
}