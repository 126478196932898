import {OutputFileType} from '@tehzor/tools/interfaces/IExportTemplate';

const getFileExtension = (fileName: string) => fileName.split('.').pop();

/**
 * Проверяет корректность имени файла шаблонеа
 *
 * @param fileName название файла
 */
export const fileNameError = (fileName?: string, oldFileName?: string) => {
	if (!oldFileName) {
		return fileName
			? getFileExtension(fileName) !== OutputFileType.DOCX
					&& getFileExtension(fileName) !== OutputFileType.XLSX
			: false;
	}
	if (fileName) {
		return getFileExtension(fileName) !== getFileExtension(oldFileName);
	}
	return false;
};
