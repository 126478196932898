import {EditableCheckItemDialog} from '@src/components/EditableCheckList/components/EditableCheckItemDialog';
import {ICheckItemsPermissions} from '@src/core/hooks/permissions/useCheckItemsPermissions';
import {
	ISavingCheckItem,
	ISavingCheckItemDraftType
} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {createRecursiveIndex} from '@tehzor/tools/utils/createRecursiveIndex';
import {ActionButtons, Button, IconButton, TextField} from '@tehzor/ui-components';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

export const generateId = () => Math.random().toString(36).substr(2, 5);

export const isGeneratedId = (id: string) => {
	const expectedLength = 5;
	return typeof id === 'string' && id.length === expectedLength;
};

const deleteIcon = <i className="tz-delete" />;
const copyIcon = <i className="tz-copy" />;
const addIcon = <i className="tz-plus-20" />;

const classes = {
	root: 'editable-check-items__item-input',
	wrap: 'editable-check-items__item-input__view-wrap',
	element: 'editable-check-items__item-input__element'
};

interface ICheckItemProps {
	items: ISavingCheckItem[];
	item: ISavingCheckItem;
	permissions: ICheckItemsPermissions;
	hasDraft: boolean;
	handleAddSubCategory: (mainItemId: string) => void;
	handleCopyCategory: (item: ISavingCheckItem) => void;
	handleDraft: (item: ISavingCheckItem) => void;
	handleSoftDelete: (item: ISavingCheckItem) => void;
	handleHardDelete: (item: ISavingCheckItem) => void;
	handleRestore: (item: ISavingCheckItem) => void;
	onChange: (index: number, itemData: ISavingCheckItem) => void;
	onReset: () => void;
	dragItem?: ISavingCheckItem;
	archivedVisible: boolean;
}

export const CheckItem = ({
	items,
	item,
	permissions,
	hasDraft,
	handleAddSubCategory,
	handleCopyCategory,
	handleDraft,
	handleSoftDelete,
	handleHardDelete,
	handleRestore,
	onChange,
	onReset,
	dragItem,
	archivedVisible
}: ICheckItemProps) => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const itemIndex = createRecursiveIndex(item, items);
	const isDraggable = dragItem?.id === item.id;
	const canBeRestored = item.parentId
		? items.some(el => el.id === item.parentId && !el.deleted)
		: true;

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const handleAdd = useCallback(() => {
		handleAddSubCategory(item.id);
	}, [handleAddSubCategory, item]);

	const handleSoftDeleting = useCallback(() => {
		handleSoftDelete(item);
	}, [handleSoftDelete, item]);

	const handleHardDeleting = useCallback(() => {
		handleHardDelete(item);
	}, [handleHardDelete, item]);

	const handleRestoring = useCallback(() => {
		handleRestore(item);
	}, [handleRestore, item]);

	const handleCopy = useCallback(() => {
		handleCopyCategory(item);
	}, [handleCopyCategory, item]);

	const handleCancel = useCallback(() => {
		onReset();
	}, [onReset]);

	return (
		<div
			className={classNames('editable-check-items__item', {
				'editable-check-items__item_disabled': hasDraft && !item.isDraft,
				'editable-check-items__item_disabled_visible':
					hasDraft && item.isDraft && !item.draftType,
				'editable-check-items__item_archived': item.deleted
			})}
		>
			{!isDraggable && items && (
				<p className="editable-check-items__item-index">{itemIndex}</p>
			)}
			<TextField
				className={classes}
				value={item.name}
				elementType="div"
				onClick={open}
				disabled={
					archivedVisible ||
					(hasDraft && !item.isDraft) ||
					(item.isDraft &&
						item.draftType !== ISavingCheckItemDraftType.ADDING_DRAFT &&
						item.draftType !== ISavingCheckItemDraftType.EDITING_DRAFT)
				}
			/>

			<div className="editable-check-items__item-options">
				{item.isDraft && item.draftType ? (
					<ActionButtons className="editable-check-items__item-buttons">
						<Button
							type="accent-blue"
							label="Сохранить"
							disabled={!item.name}
							onClick={() => handleDraft(item)}
						/>
						<Button
							type="cancel"
							label="Отменить"
							onClick={handleCancel}
						/>
					</ActionButtons>
				) : item.deleted ? (
					<ActionButtons className="editable-check-items__item-buttons">
						{canBeRestored && permissions.canRestore ? (
							<Button
								type="accent-blue"
								label="Восстановить"
								onClick={handleRestoring}
							/>
						) : null}
						{permissions.canHardDelete && (
							<Button
								type="cancel"
								label="Удалить"
								onClick={handleHardDeleting}
							/>
						)}
					</ActionButtons>
				) : (
					<div className="editable-check-items__item-options-view">
						{permissions.canAdd && (
							<>
								<IconButton
									className="editable-check-items__item-button"
									type="inline-blue-accent"
									onClick={handleAdd}
									disabled={hasDraft || archivedVisible}
								>
									{addIcon}
								</IconButton>
								<IconButton
									className="editable-check-items__item-button"
									type="inline"
									onClick={handleCopy}
									disabled={hasDraft || archivedVisible}
								>
									{copyIcon}
								</IconButton>
							</>
						)}

						{permissions.canSoftDelete && (
							<IconButton
								className="editable-check-items__item-button"
								type="inline-red"
								onClick={handleSoftDeleting}
								disabled={hasDraft || archivedVisible}
							>
								{deleteIcon}
							</IconButton>
						)}
					</div>
				)}
			</div>

			<EditableCheckItemDialog
				isOpen={isOpen}
				close={close}
				itemsLeaf={item}
				items={items}
				onChange={onChange}
			/>
		</div>
	);
};
