import {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {IMeterTariff, MeterTariffId} from '@tehzor/tools/interfaces/meterConsumptions/IMeterTariff';
import {
	useExtractMeterTariffsAsArray,
	useExtractMeterTariffsById
} from '@src/core/hooks/queries/meterTariffs/hooks';
import {useTranslation} from "react-i18next";
import {useTranslatedDictionaryArray} from "@src/core/hooks/translations/useTranslatedDictionaryArray";
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";
import {useTranslatedDictionaryMap} from "@src/core/hooks/translations/useTranslatedDictionaryMap";

const arrowIcon = <i className="tz-simple-arrow-20" />;

interface IMeterTariffSelectProps<S, E> {
	meterConsumptions: Array<{tariffId?: MeterTariffId | null}>;
	index: number;
	className?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	disabled?: boolean;
}

const getInputLabel = (value: string | undefined, tariffsMap: Record<string, IMeterTariff>) => {
	if (!value) {
		return undefined;
	}

	if (tariffsMap[value]) {
		return tariffsMap[value].name;
	}

	return undefined;
};

export const MeterAddingMeterTariffSelect = <
	S extends {meterConsumptions?: Array<{tariffId?: MeterTariffId | null}> | null},
	E
>({
	meterConsumptions,
	index,
	className,
	editingDispatch,
	disabled
}: IMeterTariffSelectProps<S, E>) => {
	const {t} = useTranslation();
	const {data: tariffs} = useExtractMeterTariffsAsArray();
	const translatedTariffs = useTranslatedDictionaryArray(dictionaryKeys.meterTariffs, tariffs);
	const {data: tariffsMap} = useExtractMeterTariffsById();
	const translatedTariffsMap = useTranslatedDictionaryMap(dictionaryKeys.meterTariffs, tariffsMap);
	const handleChange = useCallback(
		(v: MeterTariffId) => {
			const value = [...meterConsumptions];
			value[index].tariffId = v;
			editingDispatch({
				type: 'update',
				field: 'meterConsumptions',
				value
			});
		},
		[editingDispatch, index, meterConsumptions]
	);

	const handleClear = useCallback(() => {
		const value = [...meterConsumptions];
		value[index].tariffId = null;
		editingDispatch({
			type: 'update',
			field: 'meterConsumptions',
			value
		});
	}, [editingDispatch, index, meterConsumptions]);

	return (
		<div className={className}>
			<EditableFieldLabel>
				{t('components.editableFields.meterAddingMeterTariffSelect.EditableFieldLabel')}
			</EditableFieldLabel>
			{translatedTariffs && translatedTariffsMap && (
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={getInputLabel(
								meterConsumptions[index].tariffId || undefined,
								translatedTariffsMap
							)}
							icon={arrowIcon}
							disabled={disabled}
							cleanable
							onClearClick={handleClear}
						/>
					}
				>
					<Select2
						value={meterConsumptions[index].tariffId || undefined}
						onChange={handleChange}
					>
						{translatedTariffs.map(tariff => (
							<SelectOption
								key={tariff.id}
								itemKey={tariff.id}
								content={tariff.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			)}
		</div>
	);
};
