import {CSSProperties, ReactElement} from 'react';
import IconButton from '../../buttons/IconButton';
import MenuLayer from '../MenuLayer';
import './IconMenu.less';
import {IMenuComponentProps, IMenuItemProps} from '../MenuItem';
import classNames from 'classnames';
import {IPopupBasicProps} from '../../containers/Popup';
import {ButtonType} from '../../buttons/IconButton/IconButton';

const defaultIcon = <i className="tz-menu" />;

interface IIconMenuProps<V, M extends IMenuComponentProps<V>> {
	className?: string;
	type?: ButtonType;
	style?: CSSProperties;
	icon?: ReactElement;
	children?: ReactElement<M> | Array<ReactElement<IMenuItemProps>>;
	popupArrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
	disabled?: boolean;
}

const IconMenu = <V, M extends IMenuComponentProps<V>>(props: IIconMenuProps<V, M>) => {
	const {
		className,
		type,
		style,
		icon = defaultIcon,
		children,
		popupArrowVisible = true,
		popupProps,
		disabled
	} = props;

	return (
		<MenuLayer
			trigger={
				<IconButton
					className={classNames('icon-menu__button', className)}
					style={style}
					type={type}
					disabled={disabled}
				>
					{icon}
				</IconButton>
			}
			popupArrowVisible={popupArrowVisible}
			popupProps={{placement: 'bottom-end', preferX: 'left', ...popupProps}}
		>
			{children}
		</MenuLayer>
	);
};

IconMenu.displayName = 'IconMenu';

export default IconMenu;
