import {StructurePage} from './StructurePage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

/**
 * Обёртка над страницей структуры,
 * необходимо для того чтобы при переходе от одной структуры к другой отрабатывали все методы жизненного цикла
 *
 * @param props свойства компонента
 * @constructor
 */
export const StructurePageWrap = () => {
	const {structureId} = useStrictParams<{structureId: string}>();
	return <StructurePage key={structureId}/>;
};
