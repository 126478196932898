import {io} from 'socket.io-client';
import {localAuthStorage} from './localAuthStorage';
import detectSocketTimeout from '../utils/detectSocketTimeout';
import {EventEmitter} from 'events';
import {MissingTokenError} from '../errors/MissingTokenError';
import {debounce} from 'lodash';
import {MissingDeviceError} from '../errors/MissingDeviceError';
import {NoActiveConnectionError} from '../errors/NoActiveConnectionError';
import IError from '../interfaces/IError';
import {Socket} from 'socket.io-client/build/esm/socket';
import {tokenUpdater} from './updateTokens';

type EventType = 'connect' | 'disconnect';

type Listener = (...args: unknown[]) => void;

export interface IWsRequestParams {
	// eslint-disable-next-line @typescript-eslint/ban-types
	[key: string]: string | number | boolean | object | Blob | null | undefined;
}

export interface IWsRequestOptions {
	timeout?: number;
}

/**
 * Базовый класс для взаимодействия с backend'ом по протоколу websocket
 */
export class BaseWsConnector {
	logout?: () => Promise<void>;

	appId: string;

	tokenUpdateInProgress: boolean;

	private eventEmitter = new EventEmitter();

	// eslint-disable-next-line no-undef
	private socket?: Socket;

	/**
	 * Создает подключение к api
	 *
	 * @param url адрес сервера
	 * @param path путь до endpoint
	 * @param transports используемые транспорты
	 */
	connect = (url: string, path = '/api/ws', transports: string[] = ['websocket'], logout?: () => Promise<void>) => {
		this.logout = logout;
		this.socket = io(url, {path, transports});
		this.socket.on('connect', this.handleConnect);
		this.socket.on('disconnect', this.handleDisconnect);

		if (typeof window !== 'undefined') {
			window.addEventListener('online', this.handleOnline);
			window.addEventListener('offline', this.handleOffline);
		}

		if (!this.isOnline()) {
			this.socket.disconnect();
		}
	};

	/**
	 * Разрывает websocket-соединение
	 */
	disconnect = () => {
		if (this.socket) {
			this.socket.disconnect();
		}
	};

	/**
	 * Подписывает на обновления об изменении статуса соединения
	 *
	 * @param event тип события
	 * @param listener функция-обработчик
	 */
	addEventListener = (event: EventType, listener: Listener) => {
		this.eventEmitter.addListener(event, listener);
	};

	/**
	 * Отписывает от обновлений об изменении статуса соединения
	 *
	 * @param event тип события
	 * @param listener функция-обработчик
	 */
	removeEventListener = (event: EventType, listener: Listener) => {
		this.eventEmitter.removeListener(event, listener);
	};

	/**
	 * Отправляет запрос через WebSocket и ожидает данные в ответ
	 *
	 * @param methodName название метода
	 * @param params параметры запроса
	 * @param options опции запроса
	 */
	sendRequest = <R>(methodName: string, params: IWsRequestParams = {}, options?: IWsRequestOptions): Promise<R> =>
		new Promise((resolve, reject) => {
			if (!this.socket) {
				reject(new NoActiveConnectionError('No active connection'));
			} else {
				this.socket.emit(
					methodName,
					params,
					detectSocketTimeout(data => {
						if (data && data.error) {
							reject(data);
						}
						resolve(data);
					}, options?.timeout)
				);
			}
		});

	/**
	 * Отправляет запрос, прикрепляя к нему токен авторизации
	 *
	 * @param methodName название метода
	 * @param params параметры запроса
	 * @param options опции запроса
	 */
	sendAuthorizedRequest = async <R>(
		methodName: string,
		params: IWsRequestParams = {},
		options?: IWsRequestOptions
	): Promise<R> => {
		const deviceId = await localAuthStorage.getDeviceId();

		if (!deviceId) {
			throw new MissingDeviceError();
		}
		const request = (accessToken?: string) =>
			this.sendRequest<R>(methodName, {...params, accessToken, deviceId}, options);
		return this.retryWithUpdateToken(request);
	};

	/**
	 * Отправляет запрос прикрепляя к нему токен авторизации и не прикрепляя DeviceId.
	 * Используется для удаления сессий пользователя в админ приложении.
	 *
	 * @param methodName название метода
	 * @param params параметры запроса
	 * @param options опции запроса
	 */
	sendAuthorizedRequestWithoutDeviceId = async <R>(
		methodName: string,
		params: IWsRequestParams = {},
		options?: IWsRequestOptions
	): Promise<R> => {
		const request = (accessToken?: string) =>
			this.sendRequest<R>(methodName, {...params, accessToken}, options);
		return this.retryWithUpdateToken(request);
	};

	/**
	 * Отправляет простой запрос через WebSocket без ожидания данных в ответ
	 *
	 * @param methodName название метода
	 * @param params параметры запроса
	 */
	sendSimpleRequest = async (methodName: string, params: IWsRequestParams = {}): Promise<void> => {
		if (!this.socket) {
			return Promise.reject(new NoActiveConnectionError('No active connection'));
		}
		this.socket.emit(methodName, params);
	};

	/**
	 * Отправляет простой запрос, прикрепляя к нему токен авторизации
	 *
	 * @param methodName название метода
	 * @param params параметры запроса
	 */
	sendAuthorizedSimpleRequest = async (methodName: string, params: IWsRequestParams = {}): Promise<void> => {
		const request = (accessToken?: string) => this.sendSimpleRequest(methodName, {...params, accessToken});
		return this.retryWithUpdateToken(request);
	};

	/**
	 * Обновление токена и повтор запроса
	 */
	retryWithUpdateToken = async <R>(request: (token?: string) => Promise<R>) => {
		try {
			await this.waitTokenUpdate();
			const accessToken = await localAuthStorage.getAccessToken();
			if (!accessToken) {
				throw new MissingTokenError();
			}
			return await request(accessToken);
		} catch (e) {
			if ((e as IError).statusCode == 401) {
				if ((e as IError).error === 'WrongRefreshToken' && this.logout) {
					await this.logout();
				}
				this.tokenUpdateInProgress = true;
				try {
					await tokenUpdater.updateToken();
					const accessToken = await localAuthStorage.getAccessToken();
					this.tokenUpdateInProgress = false;
					return await request(accessToken);
				} catch (error: unknown) {
					this.tokenUpdateInProgress = false;
					console.log(error);
					throw error;
				}
			}
			throw e;
		}
	};

	waitTokenUpdate = () =>
		new Promise(resolve => {
			if (this.tokenUpdateInProgress) {
				const checkToken = () => {
					if (!this.tokenUpdateInProgress) {
						resolve(true);
					} else {
						setTimeout(checkToken, 500);
					}
				};
				setTimeout(checkToken, 500);
			} else {
				resolve(true);
			}
		});

	/**
	 * Подписывается на WebSocket событие
	 *
	 * @param eventName название события
	 * @param callback callback
	 * @param unique добавлять ли функцию только один раз
	 */
	subscribe = (eventName: string, callback: Listener, unique = false) => {
		if (this.socket && (!unique || (unique && !this.socket.hasListeners(eventName)))) {
			this.socket.on(eventName, callback);
		}
	};

	/**
	 * Отписывается от WebSocket события
	 *
	 * @param eventName название события
	 * @param callback callback
	 */
	unsubscribe = (eventName: string, callback: Listener) => {
		if (this.socket) {
			this.socket.off(eventName, callback);
		}
	};

	/**
	 * Имеется ли подключение к сети
	 */
	isOnline = () => (typeof window !== 'undefined' ? window.navigator.onLine : true);

	/**
	 * Установлено ли соединение по websocket
	 */
	isConnected = () => this.socket !== undefined && this.socket.connected;

	/**
	 * Обрабатывает событие соединения с api
	 */
	private handleConnect = () => {
		this.eventEmitter.emit('connect');
	};

	/**
	 * Обрабатывает событие отключения от api
	 *
	 * @param reason причина отключения
	 */
	private handleDisconnect = (reason: string) => {
		this.eventEmitter.emit('disconnect');

		if (reason === 'io server disconnect' && this.socket) {
			this.socket.connect();
		}
	};

	/**
	 * Обрабатывает событие подключение к сети интернет
	 */
	// eslint-disable-next-line @typescript-eslint/member-ordering
	private handleOnline = debounce(() => {
		if (this.socket) {
			this.socket.connect();
		}
	}, 3000);

	/**
	 * Обрабатывает событие отключения от сети интернет
	 */
	private handleOffline = () => {
		if (this.socket) {
			this.socket.disconnect();
		}
	};
}
