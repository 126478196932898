import {CommentsVisibilityFilter} from '@src/components/CommentsVisibilityFilter';
import {useIsLargeDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {makeCommentTitle} from '../utils/makeCommentTitle';
import {useCommentsCtx} from '../utils/CommentsCtx';
import {useTranslation} from 'react-i18next';

const DesktopCommentsHeader = () => {
	const {entityPermissions, visibility, changeCommentsVisibility} = useCommentsCtx();
	const isLargeDesktop = useIsLargeDesktop();
	const {t} = useTranslation();
	return (
		<div className="comments__d-comments-header">
			<div className="comments__d-comments-title">
				{makeCommentTitle(entityPermissions.canViewComments, entityPermissions.canViewReplies,t)}
			</div>

			{entityPermissions.canViewComments && entityPermissions.canViewReplies && (
				<CommentsVisibilityFilter
					visibility={visibility}
					changeCommentsVisibility={changeCommentsVisibility}
					className="comments__d-comments-v-filter"
					popupProps={
						isLargeDesktop
							? {placement: 'bottom-end', preferX: 'right'}
							: {placement: 'bottom-start', preferX: 'left'}
					}
				/>
			)}
		</div>
	);
};

export default DesktopCommentsHeader;
