import { useCallback } from 'react';
import * as React from 'react';
import './Tag.less';
import classNames from 'classnames';
import IconButton from '../../buttons/IconButton';
import {isBrightColor} from '../../../utils/isBrightColor';
import {convertClassNames} from '../../../utils/convertClassNames';

export interface ITagProps<T> {
	className?: string | {
		root?: string;
		label?: string;
		icon?: string;
		closeBtn?: string;
	};
	styles?: React.CSSProperties;
	label: string;
	icon?: React.ReactNode;
	color?: string;
	dotColor?: string;
	data?: T;
	size?: 'small' | 'medium' | 'large';
	multiline?: boolean;
	threshold?: number;

	onDelete?: (data?: T) => void;
	onHover?: (isActive: boolean, data?: T) => void;
}

export const Tag = <T extends unknown>(props: ITagProps<T>) => {
	const {
		className,
		styles,
		label,
		icon,
		color,
		dotColor,
		data,
		size = 'medium',
		multiline,
		threshold = 0.7,
		onDelete,
		onHover
	} = props;

	const handleDelete = useCallback(() => {
		if (onDelete) {
			onDelete(data);
		}
	}, [data, onDelete]);

	const handleMouseEnter = useCallback(() => {
		if (onHover) {
			onHover(true, data);
		}
	}, [data, onHover]);

	const handleMouseLeave = useCallback(() => {
		if (onHover) {
			onHover(false, data);
		}
	}, [data, onHover]);

	const classes = convertClassNames(className);

	return (
		<div
			data-testid="Tag"
			className={classNames(
				'tag',
				`tag_${size}`,
				{tag_dark: color && isBrightColor(color, threshold)},
				{'tag_with-icon': !!icon},
				{'tag_with-dot': !!dotColor},
				{tag_removable: !!onDelete},
				{'tag_single-line': !multiline},
				classes.root
			)}
			style={{...styles, backgroundColor: dotColor ? 'transparent' : color, borderColor: dotColor}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{icon && <div className={classNames('tag__icon', classes.icon)}>{icon}</div>}
			{dotColor && (
				<div
					className="tag__dot"
					style={{backgroundColor: dotColor}}
				/>
			)}
			<div
				className={classNames('tag__label', classes.label)}
				title={label}
			>
				{label}
			</div>

			{onDelete !== undefined && (
				<IconButton
					className={classNames('tag__close', classes.closeBtn)}
					onClick={handleDelete}
				>
					<i className="tz-close-16 tag__close-icon"/>
				</IconButton>
			)}
		</div>
	);
};

Tag.displayName = 'Tag';