import React, {useMemo} from 'react';
import {usePermissions} from './usePermissions';
import {formSpaceLink, formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useSpace} from '@src/core/hooks/queries/space';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from "react-i18next";

interface IEntityLink {
	key: string;
	icon: React.ReactNode;
	label: string;
	url?: string;
	title: string;
}

export function useEntitiesLinks(spaceId: string | undefined, objectId: string): IEntityLink[] {
	const {t} = useTranslation();
	const {workAcceptanceId} = useStrictParams<{workAcceptanceId: string}>();
	const permissions = usePermissions(objectId);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const {data: space} = useSpace(spaceId, objectId);
	const {data: workAcceptance} = useWorkAcceptance(workAcceptanceId, objectId);

	return useMemo((): IEntityLink[] => {
		const result: IEntityLink[] = [];
		if (space && permissions.canViewSpace) {
			result.push({
				key: 'space',
				icon: null,
				label: formSpaceTitle(
					space.name,
					space.altName,
					spaceTypes?.[space.type],
					altNamesVisible
				),
				url: formSpaceLink(objectId, space.id),
				title: t('checkListPage.hooks.useEntitiesLinks.entityLink.space.title')
			});
		}
		if (workAcceptance && permissions.canViewWorkAcceptances) {
			result.push({
				key: 'work-acceptance',
				icon: null,
				label: `${t('checkListPage.hooks.useEntitiesLinks.entityLink.work-acceptance.label')} "${workAcceptance.number.toString()}"`,
				url: formWorkAcceptanceLink(objectId, workAcceptance.id),
				title: t('checkListPage.hooks.useEntitiesLinks.entityLink.work-acceptance.title')
			});
		}
		return result;
	}, [objectId, permissions, spaceTypes, altNamesVisible, space, workAcceptance]);
}
