import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {UserInfo, WorkingGroupInfo} from '../../../various';

interface IUsersOrGroupProps {
	users?: IBriefUser[];
	group?: {
		id: string;
		name: string;
		leader?: IBriefUser;
	};
}

export const UsersOrGroup = (props: IUsersOrGroupProps) => {
	const {group, users} = props;

	if (users?.length) {
		return (
			<div className="delegation-history-item__transition-users">
				{users.map(user => (
					<UserInfo
						key={user.id}
						className="delegation-history-item__transition-user"
						user={user}
						avatarSize="40"
						avatarColoring="text"
					/>
				))}
			</div>
		);
	}
	if (group) {
		return (
			<WorkingGroupInfo
				className="delegation-history-item__transition-group"
				name={group.name}
				leader={group.leader}
			/>
		);
	}
	return null;
};
