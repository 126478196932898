import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetDelegationHistoriesByInspectionResponse = INormalizedData<IDelegationHistory>;

/**
 * Получает записи из истории делегирования осмотра
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 */
export const requestDelegationHistoriesByInspection = async (
	objectId: string,
	inspectionId: string
) => {
	const response = await httpRequests.withToken.get<IGetDelegationHistoriesByInspectionResponse>(
		'delegation-histories',
		{
			params: {objectId, inspectionId, workingGroupType: WorkingGroupTypeId.PERFORMERS}
		}
	);

	return response.data;
};
