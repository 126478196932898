import {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useWorkingGroupById} from '../queries/workingGroups/hooks';

export const useManageWorkingGroupBreadcrumbs = () => {
	const {workingGroupId} = useParams<{workingGroupId?: string}>();
	const {data: workingGroup} = useWorkingGroupById(workingGroupId);
	return useMemo(() => {
		const items = [
			{
				label: 'Рабочие группы',
				url: '/manage/working-groups',
				nonClickable: !workingGroup
			}
		];

		if (workingGroupId && workingGroup) {
			items.push({
				label: `Группа "${workingGroup.name}"`,
				url: `/manage/working-groups/${workingGroup.id}`,
				nonClickable: false
			});
		}

		return items;
	}, [workingGroup]);
};
