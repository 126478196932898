import {IMutationErrorsMap} from '@src/interfaces/IMutationErrorsMap';
import {Mutation, useMutationState} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useMemo} from 'react';

interface IMutationErrorsProps {
	mutationKey: string[];
}

export const useMutationErrors = ({mutationKey}: IMutationErrorsProps): IMutationErrorsMap => {
	const errors = useMutationState({
		filters: {mutationKey, status: 'error'},
		select: (mutation: Mutation<unknown, Error>) => {
			if (mutation.state.variables) {
				return {
					[(mutation.state.variables as {key: string}).key]: mutation.state
						.error as unknown as IError
				};
			}
			return undefined;
		}
	});
	return useMemo(
		() =>
			errors.reduce<{[key: string]: IError}>((acc, item) => {
				if (item) {
					acc = {...acc, ...item};
				}
				return acc;
			}, {}),
		[errors]
	);
};
