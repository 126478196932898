var placements = {
	bottomLeft: {
		points: ['tl', 'bl'],
		offset: [0, 4],
		overflow: {
			adjustX: 0,
			adjustY: 1
		}
	},
	topLeft: {
		points: ['bl', 'tl'],
		offset: [0, -4],
		overflow: {
			adjustX: 0,
			adjustY: 1
		}
	}
};
export default placements;
