import { memo } from 'react';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {TableRowSelect} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {CellProps} from 'react-table';
import {CompanyCell} from './CompanyCell';
import {NameCell} from './NameCell';
import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';

export const MobileCell = memo((props: CellProps<IPreparedLegal>) => {
	const {row} = props;

	const {createdAt, createdBy, modifiedAt, modifiedBy} = row.original;
	const dates: Array<{name: string; at?: number; by?: IBriefUser}> = [];
	if (createdAt || createdBy) {
		dates.push({name: 'Добавлено:', at: createdAt, by: createdBy});
	}
	if (modifiedAt || modifiedBy) {
		dates.push({name: 'Изменено:', at: modifiedAt, by: modifiedBy});
	}

	return (
		<div className="legals__cell-mobile">
			<ClickPreventWrap className="legals__cell-mobile-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()} />
			</ClickPreventWrap>
			<div className="legals__cell-mobile-container">
				<NameCell {...props} />

				<CompanyCell {...props} />

				{dates.map(({at, by, name}) => (
					<div
						key={name}
						className="legals__cell-mobile__date"
					>
						<div className="legals__cell-mobile__date-name">{name}</div>
						<div className="legals__cell-mobile__date-info">
							{at && <div>{format(at, dateTimeCommaSeparatedFormat)}</div>}
							{by && <div>{by.displayName}</div>}
						</div>
					</div>
				))}
			</div>
		</div>
	);
});
