import {InlineButtonMenu, Menu, MenuItem} from '../../menu';
import {IPopupBasicProps} from '../../containers/Popup';
import './LanguageSelect.less';

const defaultPopupProps: IPopupBasicProps = {preferX: 'left', placement: 'bottom-end'};

interface ILanguageSelectProps {
	languages: Array<{key: string; name: string}>;
	value: string;
	popupProps?: IPopupBasicProps;

	onChange: (value: string) => void;
}

export const LanguageSelect = ({
	languages,
	value,
	popupProps = defaultPopupProps,
	onChange
}: ILanguageSelectProps) => {
	const selected = languages.find(l => l.key === value);

	return (
		<InlineButtonMenu
			type="accent"
			labelClassName="language-select-btn"
			label={selected?.name}
			popupArrowVisible
			popupProps={popupProps}
		>
			<Menu value={value} onChange={onChange}>
				{languages.map(lng => (
					<MenuItem key={lng.key} itemKey={lng.key}>
						{lng.name}
					</MenuItem>
				))}
			</Menu>
		</InlineButtonMenu>
	);
};
