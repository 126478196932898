import { memo } from 'react';
import * as React from 'react';
import {useYearsGrid} from '../hooks/useYearsGrid';
import {makeElement} from '../../../../utils/makeElement';
import Year, {IYearProps} from './Year';

interface IYearsGridProps {
	year?: number;
	decade: number;
	component?: React.ReactElement<IYearProps> | ((props: IYearProps) => JSX.Element);

	changeYear(value: number): void;
}

const YearsGrid = (props: IYearsGridProps) => {
	const {year, decade, component = Year, changeYear} = props;

	const years = useYearsGrid(decade);

	return (
		<div
			key={decade}
			className="calendar__years-grid"
		>
			{years.map((item, i) => {
				const yearProps = {
					...item,
					key: i,
					active: item.value === year,
					onClick: changeYear
				};
				return makeElement(component, yearProps);
			})}
		</div>
	);
};

export default memo(YearsGrid);
