import {ISavingLegalEntity} from '@tehzor/tools/interfaces/legals-entity/ISavingLegalEntity';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export type IEditLegalResponse = ILegalEntity;

/**
 * Изменяет юр лицо
 *
 * @param legalId id объекта
 * @param fields данные нарушения
 */
export const makeLegalEntityEditRequest = async (legalId: string, fields: ISavingLegalEntity) => {
	const response = await httpRequests.withToken.post<IEditLegalResponse>(
		`legals-entity/${legalId}`,
		{
			legalId,
			...fields
		}
	);
	return response.data;
};
