import * as types from '../constants';

/**
 * Изменяет статус подключения к сети
 *
 * @param value есть/нет подключения
 */
export const changeNetworkStatus = (value: boolean) => ({
	type: types.CHANGE_NETWORK_STATUS,
	payload: value
});
