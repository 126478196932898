import {Variants} from 'framer-motion';

export const makeAnimations = (
	useContentOpenAnimation?: boolean,
	useContentCloseAnimation?: boolean,
	useFullScreenAnimation?: boolean
): { [key: string]: Variants } => {
	const result: ReturnType<typeof makeAnimations> = {};
	if (!useFullScreenAnimation) {
		result.overlay = {
			initial: {
				opacity: 0
			},
			visible: {
				opacity: 1,
				transition: {
					ease: [0.34, 0.18, 0.61, 0.81],
					duration: 0.2,
					delayChildren: 0
				}
			},
			hidden: {
				opacity: 0,
				transition: {
					ease: [0.34, 0.18, 0.61, 0.81],
					duration: 0.2,
					delay: useContentCloseAnimation ? 0.2 : 0.1,
					when: false
				}
			}
		};
	}
	result.dialog = {
		initial: {
			scale: useFullScreenAnimation ? 0.9 : 0.75,
			opacity: 0
		},
		visible: {
			scale: 1,
			opacity: 1,
			transition: {
				type: 'tween',
				ease: [0.34, 0.18, 0.61, 0.81],
				duration: 0.2,
				delay: 0.1,
				delayChildren: 0.05,
				when: 'beforeChildren'
			}
		},
		hidden: {
			scale: useFullScreenAnimation ? 0.9 : 0.8,
			opacity: 0,
			transition: {
				type: 'tween',
				ease: [0.34, 0.18, 0.61, 0.81],
				duration: 0.25,
				delay: useContentCloseAnimation ? 0.05 : undefined,
				when: 'afterChildren'
			}
		}
	};
	if (useContentOpenAnimation || useContentCloseAnimation) {
		result.content = {
			visible: {
				opacity: 1,
				visibility: 'visible',
				transition: {
					ease: [0.34, 0.18, 0.61, 0.81],
					duration: 0.1
				}
			}
		};
		if (useContentOpenAnimation) {
			result.content.initial = {
				opacity: 0,
				visibility: 'hidden'
			};
		}
		if (useContentCloseAnimation) {
			result.content.hidden = {
				opacity: 0,
				transition: {
					ease: [0.34, 0.18, 0.61, 0.81],
					duration: 0.1
				},
				transitionEnd: {
					visibility: 'hidden'
				}
			};
		}
	}
	return result;
};
