const domain = 'pages/check-lists/problems';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const CHECK_REQUEST = `${domain}/check/request`;
export const CHECK_SUCCESS = `${domain}/check/success`;
export const CHECK_FAILURE = `${domain}/check/failure`;

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;
