import { memo } from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';

interface IFixDateProps {
	plannedFixDate: number;
}

const fixDateIcon = <i className="tz-calendar-20" />;

export const FixDate = memo(({plannedFixDate}: IFixDateProps) => {
	const formatted = format(plannedFixDate, dateFormat, {locale: ru});
	const actualFixDate = new Date().getTime();

	return (
		<EntityGridItem
			className={{
				root: 'contract__cells-item',
				header: 'contract__cells-item-header',
				content: 'contract__cells-item-content'
			}}
			icon={fixDateIcon}
			label="Окончание работ"
			inline
		>
			<div className="contract__cells-item-fix-date-title">{formatted}</div>

			{actualFixDate && actualFixDate > plannedFixDate && (
				<FixDateTag
					plannedFixDate={plannedFixDate}
					actualFixDate={actualFixDate}
				/>
			)}
		</EntityGridItem>
	);
});
