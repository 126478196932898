import {wsConnector} from '@src/api/backend/wsConnector';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

export type IGetObjectStatsResponse = IEntityProblemsData;

/**
 * Получает stats объекта
 */
export const requestObjectStats = (
	objectsIds: string[]
) =>
	wsConnector.sendAuthorizedRequest<IGetObjectStatsResponse>('getObjectStats', {objectsIds});
