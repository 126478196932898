import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import {Tree} from 'array-to-tree';
import {isFiltersIncludeObject} from './isFiltersIncludeObject';

/**
 * Фильтрует список объектов, оставляя только те, по которым есть статистика, либо которые фигурируют в фильтре.
 * Рекурсивно фильтрует дочерние объекты.
 *
 * @param objects список объектов
 * @param statsByObjects статистика, сгруппированная по объектам
 * @param filters фильтры
 */
export const filterObjects = (
	objects: Array<Tree<IStatsObject>> | undefined,
	statsByObjects: {[id: string]: unknown},
	filters: {
		companies?: string[];
		objects?: string[];
	}
) =>
	objects?.reduce<Array<Tree<IStatsObject>>>((prev, object) => {
		const children = filterObjects(object.children, statsByObjects, filters);
		if (
			statsByObjects[object.id]
			|| isFiltersIncludeObject(object, filters)
			|| children?.length
		) {
			prev.push({
				...object,
				children
			});
		}
		return prev;
	}, []);
