import jschardet from 'jschardet';

/**
 * Нормализует текстовую строку к кодировке UTF-8
 *
 * Input: 'Ð¿ÑÐ¸Ð¼ÐµÑ_Ð¾ÑÑÐµÑÐ°.pdf'
 * Output: 'пример_отчета.pdf'
 *
 * https://stackoverflow.com/questions/2696481/encoding-conversation-utf-8-to-1251-in-javascript
 * https://stackoverflow.com/questions/6965107/converting-between-strings-and-arraybuffers
 *
 * @param value
 */
export function normalizeTextEncoding(value = '') {
	if (!value) return value;
	let result = '';

	try {
		// Проверка кодировки для случая, когда на входе исправная строка, например 'пример отчета.docx',
		// иначе без этой проверки метод наоборот ломает строку в кракозяблы
		const isNormalizeIsNeeded = jschardet.detect(value).encoding === 'UTF-8';
		if (!isNormalizeIsNeeded) return value;

		const buffer = new Uint8Array(new ArrayBuffer(value.length));

		for (let i = 0; i < value.length; i++) {
			buffer[i] = value.charCodeAt(i);
		}

		result = new TextDecoder('utf-8').decode(buffer);
	} catch (e) {
		console.error('normalizeTextEncoding error', value);
		result = value;
	}

	return result;
}
