import {formWorkAcceptanceCheckListLink} from './formWorkAcceptanceCheckListLink';

interface Args {
	objectId: string;
	listId: string;
	itemId: string;
	workAcceptanceId: string;
}

/**
 * Формирует ссылку на категорию чек-листа
 *
 * @param itemId id категории
 * @param params
 */
export const formWorkAcceptanceCheckItemLink = ({itemId, ...params}: Args) => `${formWorkAcceptanceCheckListLink(params)}/items/${itemId}`;
