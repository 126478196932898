import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';

export const filterEmptyTypeDecorationSets = (
	typeDecorations: ISpaceTypeDecoration[] | undefined,
	typeDecorationSets: ISpaceTypeDecorationSet[]
) =>
	typeDecorationSets.filter(
		set =>
			typeDecorations && typeDecorations.some(decor => decor.typeDecorationSetId === set.id)
	);

export const mapSpaceTypeDecorationToTreeData = (
	data: Array<ISpaceTypeDecoration | ISpaceTypeDecorationSet>,
	multipleSelect = false
) => {
	const result = data.map(el => {
		if ('typeDecorationSetId' in el) {
			return {
				id: el.id,
				content: el.name,
				parentId: el?.typeDecorationSetId
			};
		}
		return {
			id: el.id,
			content: el.name,
			onlySemiCheckable: true,
			nonCheckable: !multipleSelect
		};
	});
	return result as ITreeDataItem[];
};

export const makeTreeDataSpaceTypeDecoration = (
	typeDecorations: ISpaceTypeDecoration[],
	typeDecorationSets: ISpaceTypeDecorationSet[],
	multipleSelect = false
) => {
	const filteredSets = filterEmptyTypeDecorationSets(typeDecorations, typeDecorationSets);
	const arr = [] as Array<ISpaceTypeDecoration | ISpaceTypeDecorationSet>;
	const dataForTree = arr.concat(typeDecorations, filteredSets);

	return mapSpaceTypeDecorationToTreeData(dataForTree, multipleSelect);
};
