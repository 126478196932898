import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export type IGetListInspectionsResponse = INormalizedData<IListInspection>;

/**
 * Возвращает список осмотров
 */
export const requestListInspections = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>
) => {
	const response = await httpRequests.withToken.post<IGetListInspectionsResponse>(
		'inspections/get-list-inspections',
		{
			filters,
			sort: convertSortParam(sort)
		}
	);

	return response.data;
};
