import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import {useMemo} from 'react';
import {useWorkAcceptancesBreadcrumbs} from './useWorkAcceptancesBreadcrumbs';
import {useTranslation} from 'react-i18next';

/**
 * Хук для хлебных крошек от "всех объектов" до приёмки работ
 *
 * @param objectId id конечного объекта
 * @param workAcceptanceId id приёмки работ
 * @param number номер приёмки работ
 */
export function useWorkAcceptanceBreadcrumbs(
	objectId: string,
	workAcceptanceId: string,
	number: number | undefined
) {
	const res = useWorkAcceptancesBreadcrumbs(objectId);
	const {t} = useTranslation();
	return useMemo(
		() =>
			res?.concat({
				label: number
					? `${t('workAcceptancePage.title')} №${number}`
					: t('workAcceptancePage.title'),
				url: formWorkAcceptanceLink(objectId, workAcceptanceId),
				inactive: true
			}),
		[objectId, workAcceptanceId, res, t]
	);
}
