import {Column} from 'react-table';
import {IPreparedWorkingGroup} from '../../interfaces/IPreparedWorkingGroup';

export const desktopGroupsColumns: Array<Column<IPreparedWorkingGroup>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Название группы',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},
	{
		id: 'count',
		accessor: 'count',
		Header: 'Количество правил',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Header: '',
		defaultCanSort: false,
		disableResizing: true
	}
];
