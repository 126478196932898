import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

interface IDelegationHistoriesPermissions {
	canView?: boolean;
}

export const useDelegationHistoriesPermissions = (): IDelegationHistoriesPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canView: hasPermission(user.roles, 'delegation-histories-view', UserRoleScopes.OBJECT)
		};
	}, [user]);
};
