import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAddingSpaceTypeDecorationDialog} from '@src/components/AddingSpaceTypeDecorationDialog';

export const AddButton = () => {
	const [dialog, open] = useAddingSpaceTypeDecorationDialog();

	return (
		<>
			<Desktop>
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-plus-20"/>}
					label="Добавить набор"
					onClick={open}
				/>
			</Desktop>

			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16"/>}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={open}
				/>
			</BeforeDesktop>
			{dialog}
		</>
	);
};
