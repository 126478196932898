import {LinkButton} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formTaskLink} from '@tehzor/tools/utils/links';
import {useTask} from "@src/core/hooks/queries/task/hooks";
import { useTranslation } from 'react-i18next';

export const TaskLink = ({taskId}: Record<string, string>) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {data: task} = useTask(taskId)

	const handleClick = useCallback(() => {
		pushPath(formTaskLink(taskId));
	}, [pushPath, taskId]);

	if (!task) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${t('components.entityLinks.taskLink.label')} ${task?.name}`}
			leftIcon={<i className="tz-external-link-16"/>}
			onClick={handleClick}
			type="filled"
		/>
	);
};
