import {Column} from 'react-table';
import {IPreparedPerformer} from '../../interfaces/IPreparedPerformer';
import {MenuCell} from './MenuCell';

export const desktopColumns: Array<Column<IPreparedPerformer>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'ФИО',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},
	{
		id: 'count',
		accessor: 'count',
		Header: 'Количество правил',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];
