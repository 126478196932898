import React, {memo} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IMenuItemProps} from '@tehzor/ui-components/src/components/menu/MenuItem';
import {useTranslation} from 'react-i18next';
import {useCommentsCtx} from '../utils/CommentsCtx';

interface ICommentMenuProps {
	comment: IComment;
	onExport: (id: string) => void;
	onEdit: (comment: IComment, official?: boolean) => void;
	onDelete: (comment: IComment) => void;
}

const CommentMenu = (props: ICommentMenuProps) => {
	const {t} = useTranslation();
	const {comment, onExport, onEdit, onDelete} = props;
	const {commentPermissions} = useCommentsCtx();
	const permissions = commentPermissions(comment);
	const items: Array<React.ReactElement<IMenuItemProps>> = [];

	if (permissions.canExport) {
		items.unshift(
			<MenuItem
				key="export"
				icon={<i className="tz-export-24" />}
				onClick={() => onExport(comment.id)}
			>
				{t('components.problemComments.commentMenu.export')}
			</MenuItem>
		);
	}
	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit" />}
				onClick={() => onEdit(comment, comment.official)}
			>
				{t('components.problemComments.commentMenu.edit')}
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={() => onDelete(comment)}
			>
				{t('components.problemComments.commentMenu.delete')}
			</MenuItem>
		);
	}

	return items.length > 0 ? <IconMenu>{items}</IconMenu> : null;
};

export default memo(CommentMenu);
