import {memo, useState, CSSProperties} from 'react';
import {useUpdateEffect} from 'react-use';

interface IContentProps {
	className?: string;
	style?: CSSProperties;
	url: string;
	suspend?: boolean;
	title?: string;
}

const Content = ({className, style, url, title, suspend}: IContentProps) => {
	const [visible, setVisible] = useState(!suspend);

	useUpdateEffect(() => {
		setVisible(s => s || !suspend);
	}, [suspend]);

	return (
		<>
			<div
				className={className}
				style={{...style, backgroundImage: visible ? `url(${url})` : undefined}}
			/>
			{title && (
				<p
					className={className}
					style={{...style, backgroundImage: visible ? `url(${url})` : undefined}}
				>
					{title}
				</p>
			)}
		</>
	);
};

export default memo(Content);
