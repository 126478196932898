import {CellProps} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {useExtractStructureTypesById} from '@src/core/hooks/queries/structureTypes/hooks';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';

export const StructureTypeCell = ({row}: CellProps<IEnrichedStructure>) => {
	const structure = row.original;
	const {data: structureTypes} = useExtractStructureTypesById();
	const translatedStructureTypes = useTranslatedDictionaryMap(
		dictionaryKeys.structureTypes,
		structureTypes
	);

	if (!translatedStructureTypes) {
		return null;
	}

	return <div>{translatedStructureTypes[structure.type]?.name ?? ''}</div>;
};
