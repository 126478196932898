import {IGetListProblemsResponse} from '@src/api/backend/problems';
import {CheckEntitiesVisibility} from '@src/store/modules/settings/pages/check/reducers/entitiesVisibility';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';

/**
 * Извлекает нарушения внутренней приемки
 */
export const extractInternalAcceptanceProblemsArray = (
	data: IGetListProblemsResponse
) => data.allIds.map(id => data.byId[id]);

/**
 * Извлекает локальные нарушения приемки
 */
export const extractInternalAcceptanceLocalProblemsArray = (data: IListProblem[], acpId: string) =>
	data.filter(problem => problem.links?.internalAcceptanceId === acpId);

export const extractInternalAcceptanceProblemsEntities = (
	data: IGetListProblemsResponse,
	visibility: CheckEntitiesVisibility[]
) => {
	const problems = extractInternalAcceptanceProblemsArray(data);
	if (visibility.includes('problems') && problems?.length) {
		return problems.map(item => ({
			id: `p_${item.id}`,
			type: 'problem',
			data: item
		}));
	}
	return [];
};

export const extractInternalAcceptanceLocalProblemsEntities = (
	data: IListProblem[],
	acpId: string,
	visibility: CheckEntitiesVisibility[]
) => {
	const problems = extractInternalAcceptanceLocalProblemsArray(data, acpId);
	if (visibility.includes('problems') && problems?.length) {
		return problems.map(item => ({
			id: `p_${item.id}`,
			type: 'problem',
			data: item
		}));
	}
	return [];
};
