import {IGetRegulatoryStandardsResponse} from '@src/api/backend/regulatoryStandards';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractRegulatoryStandardsAsArray, extractRegulatoryStandardsMap} from './selectors';
import {regulatoryStandardsQueryKeys} from '@src/api/cache/regulatoryStandards/keys';

export const useRegulatoryStandards = <T = IGetRegulatoryStandardsResponse>(
	select?: (data: IGetRegulatoryStandardsResponse) => T
) =>
	useQuery<IGetRegulatoryStandardsResponse, IError, T>({
		queryKey: regulatoryStandardsQueryKeys.list(),
		meta: {
			error: 'ошибка в получении стандартов'
		},
		select
	});

export const useRegulatoryStandardsAsArray = () =>
	useRegulatoryStandards(extractRegulatoryStandardsAsArray);

export const useRegulatoryStandardsMap = () =>
	useRegulatoryStandards(extractRegulatoryStandardsMap);
