import {restDictionariesQueryKeys} from '../../../../api/cache/restDictionaries/keys/index';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetSpaceIndicatorsSetsResponse} from '@src/api/backend/spaceIndicatorsSets';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useCallback} from 'react';
import {extractSpaceIndicatorsAsArray, extractSpaceIndicatorsAsMap} from './selectors';

export const useGetSpaceIndicatorsSets = <T = IGetSpaceIndicatorsSetsResponse>(
	select?: (data: IGetSpaceIndicatorsSetsResponse) => T
) =>
	useQuery<IGetSpaceIndicatorsSetsResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.spaceIndicatorsSets(),
		meta: {
			error: 'при загрузке индикаторов помещения'
		},
		select
	});

export const useSpaceIndicatorsAsArray = (object?: IObject) => {
	const selector = useCallback(
		(data: IGetSpaceIndicatorsSetsResponse) => extractSpaceIndicatorsAsArray(data, object),
		[object]
	);
	return useGetSpaceIndicatorsSets(selector);
};

export const useSpaceIndicatorsAsMap = (object?: IObject) => {
	const selector = useCallback(
		(data: IGetSpaceIndicatorsSetsResponse) => extractSpaceIndicatorsAsMap(data, object),
		[object]
	);
	return useGetSpaceIndicatorsSets(selector);
};
