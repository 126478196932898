import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetInspectionResponse} from '@src/api/backend/inspection';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {queryClient} from '@src/api/QueryClient';

export interface IGetInspectionRequestPayload {
	inspectionId: string;
}

export type IGetInspectionPayload = IGetInspectionResponse;

const request = (inspectionId: string) => ({type: types.GET_REQUEST, payload: {inspectionId}});

const success = (response: IGetInspectionResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке осмотра');
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает осмотр по id
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 */
export const getInspection = (objectId: string, inspectionId: string) =>
	createApiAction<IGetInspectionResponse>(
		() => request(inspectionId),
		success,
		failure,
		() =>
			queryClient.fetchQuery<IGetInspectionResponse>({
				queryKey: [...inspectionsQueryKeys.detail(inspectionId), objectId],
				staleTime: Infinity
			})
	);
