export enum ISavingCheckItemDraftType {
	ADDING_DRAFT = 'adding-draft',
	EDITING_DRAFT = 'editing-draft',
	COPYING_DRAFT = 'copying-draft',
	MOVING_DRAFT = 'moving-draft'
}
export interface ISavingCheckItem {
	id: string;
	checkListId: string;
	name?: string;
	description?: string;
	parentId?: string;
	dependencies?: string[];
	order: number;
	isDraft?: boolean;
	draftType?: ISavingCheckItemDraftType;
	sourceItemId?: string;
	sourceSubItemsIds?: string[];
	deleted?: boolean;
}
