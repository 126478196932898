import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetOwnerAcceptancesResponse extends INormalizedData<IListOwnerAcceptance> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список приёмок собственниками
 */
export const requestOwnerAcceptances = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const response = await httpRequests.withToken.post<IGetOwnerAcceptancesResponse>(
		'owner-acceptances/get',
		{
			filters,
			sort: convertSortParam(sort),
			offset,
			limit
		}
	);
	return response.data;
};
