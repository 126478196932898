import {IconButton} from '@tehzor/ui-components';
import {useCallback, useContext} from 'react';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from '../../../DispatchActionCtx';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import useAppSelector from "@src/core/hooks/useAppSelector";
import {extractTasksListSettings} from "@src/store/modules/settings/pages/tasks/selectors";

const deleteIcon = <i className="tz-delete" />;

export const MenuCell = ({row}: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'row'>) => {
	const menuActions = [];
	const perms = useTasksPermissions({
		objectId: row.original?.objectId?.id,
		activeGroupLeader: row.original?.activeGroupLeader?.id,
		respUsers: row.original?.respUsers?.map(user => (user ? user.id : '')),
		createdBy: row.original?.createdBy?.id,
		watchers: row.original?.watchers
	});

	const dispatchAction = useContext(DispatchActionCtx);
	const pageSettings = useAppSelector(extractTasksListSettings);

	const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [dispatchAction, row.original]);

	if (pageSettings.selectedRows.length > 0) {
		return null;
	}

	if (perms.canDelete) {
		menuActions.push(
			<IconButton
				key="delete"
				type="inline-blue-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
		);
	}

	return menuActions.length ? (
		<ClickPreventWrap className="tasks-page__d-table-menu-wrap">{menuActions}</ClickPreventWrap>
	) : null;
};
