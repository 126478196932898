import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {
	IInternalAcceptancesFiltersState,
	IInternalAcceptancesPageSettingsState,
	IInternalAcceptancesPagesSettingsState,
	IInternalAcceptancesSortState
} from './interfaces';
import {IChangeOffsetPayload} from '@src/store/interfaces/IChangeOffsetPayload';
import {IChangeSelectedRowsPayload} from '@src/store/interfaces/IChangeSelectedRowsPayload';

const initialState: IInternalAcceptancesPagesSettingsState = {};

export const getInitialStateForPage = (
	objectId: string
): IInternalAcceptancesPageSettingsState => ({
	filters: {
		objects: objectId !== 'all' ? [objectId] : undefined
	},
	sort: {
		modifiedAt: false
	},
	pageSize: 20,
	offset: 0,
	selectedRows: undefined
});

export const internalAcceptancesSlice = createSlice({
	name: 'internalAcceptances',
	initialState,
	reducers: {
		changeInitialStateByObjectId(state, action: PayloadAction<{objectId: string}>) {
			const {objectId} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
		},
		changeFilters(
			state,
			action: PayloadAction<IChangeFiltersPayload<IInternalAcceptancesFiltersState>>
		) {
			const {objectId, filters} = action.payload;

			if (objectId !== 'all') {
				filters.objects = [objectId];
			}

			state[objectId].filters = filters;
		},
		clearFilters(state, action: PayloadAction<IClearFiltersPayload>) {
			const {objectId} = action.payload;

			state[objectId].filters = {};

			if (objectId !== 'all') {
				state[objectId].filters = {objects: [objectId]};
			}
		},
		changeSort(
			state,
			action: PayloadAction<IChangeSortPayload<IInternalAcceptancesSortState>>
		) {
			const {objectId, sort} = action.payload;

			state[objectId].sort = sort;
		},
		changePageSize(state, action: PayloadAction<IChangePageSizePayload>) {
			const {objectId, pageSize} = action.payload;

			state[objectId].pageSize = pageSize;
		},
		changeOffset(state, action: PayloadAction<IChangeOffsetPayload>) {
			const {objectId, offset} = action.payload;

			state[objectId].offset = offset;
		},
		changeSelectedRows(state, action: PayloadAction<IChangeSelectedRowsPayload>) {
			const {objectId, selectedRows} = action.payload;

			state[objectId].selectedRows = selectedRows;
		}
	}
});

export const {actions: internalAcceptancesActions, reducer: internalAcceptancesReducer} =
	internalAcceptancesSlice;
