import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetMeterConsumptionsResponse = INormalizedData<IMeterConsumption>;

/**
 * Возвращает список показаний приборов учёта по id прибора учёта
 *
 * @param meterId id прибора учёта
 */
export const requestMeterConsumptions = async (meterId: string) => {
	const params = {
		meterId
	};
	const response = await httpRequests.withToken.get<IGetMeterConsumptionsResponse>(
		'meter-consumptions',
		{
			params
		}
	);
	return response.data;
};
