import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export const useTranslatedDictionary = <T extends {id: string}>(
	tKey: string,
	dic?: INormalizedData<T>
) => {
	const {t} = useTranslation();

	return useMemo<INormalizedData<T> | undefined>(() => {
		if (!dic) {
			return undefined;
		}

		const translatedDic: Record<string, T> = {};

		for (const [key, value] of Object.entries(dic.byId)) {
			translatedDic[key] = {...value, name: t(`${tKey}.${value.id}`)};
		}

		return {
			...dic,
			byId: translatedDic
		};
	}, [t, tKey, dic]);
};
