import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {formCheckListLink, formCheckListsLink, formSpaceLink} from '@tehzor/tools/utils/links';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useSpace} from '../queries/space';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from 'react-i18next';

export function useCheckListBreadcrumbs() {
	const {t} = useTranslation();

	const {objectId, spaceId, stage, listId} = useStrictParams<{
		objectId: string;
		spaceId: string;
		listId: string;
		stage: ObjectStageIds | 'all';
		itemId: string;
	}>();

	const objects = useObjectsData();
	const {data: space} = useSpace(spaceId, objectId);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const {data: checkList} = useExtractCheckListById(listId);

	return useMemo(() => {
		const items: IBreadcrumb[] = objects ? formObjectsChain(objects.byId, objectId, 'problems', t) : [];
		const checkListLink = formCheckListLink({objectId, spaceId, stage, listId});
		const checkListsLink = formCheckListsLink({objectId, spaceId, stage});
		const spaceLink = formSpaceLink(objectId, space?.id);

		if (space) {
			items.push({
				label: t('space.breadcrumbs'),
				url: `/objects/${objectId}/spaces`,
				inactive: false
			});

			const spaceTitle = formSpaceTitle(
				space.name,
				space.altName,
				spaceTypes?.[space.type],
				altNamesVisible
			);

			items.push({
				label: spaceTitle,
				url: spaceLink,
				inactive: false
			});
		}

		items.push({
			label: t('checkLists.breadcrumbs'),
			url: checkListsLink ?? '',
			inactive: !checkListsLink
		});

		if (listId) {
			items.push({
				label: checkList?.name ?? '',
				url: checkListLink ?? '',
				inactive: !checkListLink
			});
		}

		return items;
	}, [
		objects,
		objectId,
		spaceId,
		stage,
		listId,
		space,
		spaceTypes,
		altNamesVisible,
		checkList?.name,
		t
	]);
}
