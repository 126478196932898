import {Dispatch, useCallback, useEffect, useReducer, useRef} from 'react';
import {BehaviorSubject} from 'rxjs';
import {init, IUploadingFilesAction, IUploadingFilesState, reducer} from './state';
import {filter} from 'rxjs/operators';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';

/**
 * Добавляет локальный state для загружаемых файлов, а также метод для ожидания выгрузки всех файлов
 */
export function useUploadingFilesState(): [
	IUploadingFilesState,
	Dispatch<IUploadingFilesAction>,
	() => Promise<IUploadingFile[]>
] {
	const [state, dispatch] = useReducer(reducer, undefined, init);
	const subject = useRef(new BehaviorSubject(state.value));

	useEffect(() => {
		subject.current.next(state.value);
	}, [state.value]);

	// Функция ожидания выгрузки всех файлов
	const waitUploading = useCallback(
		() =>
			new Promise<IUploadingFile[]>(resolve => {
				// Завершение активных Observable (для случая, когда новые Observable были созданы, но старые еще не удалены)
				subject.current.observers.forEach(o => o.complete());
				subject.current
					.pipe(
						// В subscribe поступит только набор с файлами, не загружаемых или не ожидающих загрузку
						filter(items =>
							items.every(
								item =>
									item.status !== UploadingFileStatus.WAITING
									&& item.status !== UploadingFileStatus.STARTED
							))
					)
					.subscribe(value => {
						// Завершение активных Observable (в том числе текущего)
						subject.current.observers.forEach(o => o.complete());
						resolve(value);
					});
			}),
		[]
	);

	return [state, dispatch, waitUploading];
}
