import {CellProps} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {TableRowSelect, Tag, TagsContainer} from '@tehzor/ui-components';
import {useExtractStructureTypesById} from '@src/core/hooks/queries/structureTypes/hooks';
import {useExtractCategoriesAsMap} from '@src/core/hooks/queries/categories/hook';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import styles from './StructureInfoMobileCell.module.less';

const checkListIcon = <i className="tz-check-list-16 " />;

export const StructureInfoMobileCell = ({row}: CellProps<IEnrichedStructure>) => {
	const data = row.original;
	const {data: structureTypes} = useExtractStructureTypesById();
	const {data: categoriesMap} = useExtractCategoriesAsMap();

	const translatedStructureTypes = useTranslatedDictionaryMap(
		dictionaryKeys.structureTypes,
		structureTypes
	);
	const categories = data.categories ? Object.values(data.categories) : undefined;

	return (
		<div className={styles.wrapper}>
			<ClickPreventWrap>
				<TableRowSelect
					className={styles.rowSelect}
					{...row.getToggleRowSelectedProps()}
				/>
			</ClickPreventWrap>

			<div className={styles.container}>
				<div className={styles.text}>
					<div className={styles.title}>{data.name}</div>
					<div className={styles.type}>
						{(translatedStructureTypes && translatedStructureTypes[data.type]?.name) ??
							''}
					</div>

					{data.workAcceptancesStats && (
						<TagsContainer className={styles.stats}>
							{data.workAcceptancesStats.map(item => (
								<Tag
									key={item.key}
									icon={
										<i
											className="tz-work-acceptance-16"
											style={{color: item.color}}
										/>
									}
									label={item.value.toString()}
								/>
							))}
						</TagsContainer>
					)}

					{categoriesMap && categories && categories.length > 0 && (
						<div className={styles.category}>
							{categoriesMap[categories[0]]?.name}{' '}
							{categories.length > 1 && <>+{categories.length - 1}</>}
						</div>
					)}

					{data.checkLists && (
						<TagsContainer className={styles.checkLists}>
							{data.checkLists.map(checkList => (
								<Tag
									key={checkList.key}
									color={checkList.status.color}
									icon={checkListIcon}
									label={checkList.name.toString()}
									size="large"
								/>
							))}
						</TagsContainer>
					)}

					{data.problemsStats && data.problemsStats?.length > 0 && (
						<TagsContainer className={styles.tagContainer}>
							{data.problemsStats.map(item => (
								<Tag
									key={item.key}
									icon={
										<i
											className="tz-problem-16"
											style={{color: item.color}}
										/>
									}
									label={item.value.toString()}
								/>
							))}
						</TagsContainer>
					)}
				</div>
			</div>
		</div>
	);
};
