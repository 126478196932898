export const restDictionariesQueryKeys = {
	all: () => ['dictionary'],
	users: () => [...restDictionariesQueryKeys.all(), 'users'],
	objectStages: () => [...restDictionariesQueryKeys.all(), 'object-stages'],
	ownerAcceptanceResolutions: () => [
		...restDictionariesQueryKeys.all(),
		'owner-acceptance-resolutions'
	],
	ownerAcceptanceStatuses: () => [
		...restDictionariesQueryKeys.all(),
		'owner-acceptance-statuses'
	],
	problemStatuses: () => [...restDictionariesQueryKeys.all(), 'problem-statuses'],
	problemTemplatesSets: () => [...restDictionariesQueryKeys.all(), 'problem-templates-sets'],
	spaceIndicatorsSets: () => [...restDictionariesQueryKeys.all(), 'space-indicators-sets'],
	spaceTypes: () => [...restDictionariesQueryKeys.all(), 'space-types'],
	warrantyClaimStatuses: () => [...restDictionariesQueryKeys.all(), 'warranty-claim-statuses'],
	checkRecordStatuses: () => [...restDictionariesQueryKeys.all(), 'check-record-statuses'],
	taskStatuses: () => [...restDictionariesQueryKeys.all(), 'task-statuses'],
	taskTypes: () => [...restDictionariesQueryKeys.all(), 'task-types'],
	exportTemplatesDestination: () => [
		...restDictionariesQueryKeys.all(),
		'export-templates-destination'
	],
	taskPriorities: () => [...restDictionariesQueryKeys.all(), 'task-priorities'],
	structureTypes: () => [...restDictionariesQueryKeys.all(), 'structure-types'],
	workingGroupsTypes: () => [...restDictionariesQueryKeys.all(), 'working-groups-types'],
	workAcceptanceTypes: () => [...restDictionariesQueryKeys.all(), 'work-acceptance-types'],
	workAcceptanceStatuses: () => [...restDictionariesQueryKeys.all(), 'work-acceptance-statuses'],
	workAcceptanceFrontTypes: () => [
		...restDictionariesQueryKeys.all(),
		'work-acceptance-front-types'
	],
	meterTypes: () => [...restDictionariesQueryKeys.all(), 'meter-types'],
	meterTariffs: () => [...restDictionariesQueryKeys.all(), 'meter-tariffs']
};
