import {IDeleteSpaceStatusResponse} from '@src/api/backend/spaceStatuses/delete';
import {
	IEditableSpaceStatusesSetAction,
	IEditableSpaceStatusesSetState,
	IEditableStatus
} from '@src/core/hooks/states/useSpaceStatusesSetState';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISpaceStatusOrder} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatusOrder';
import generateKey from '@tehzor/tools/utils/generateKey';
import {getNewItemOrder} from '@tehzor/tools/utils/getNewItemOrder';
import {DndList, IDndEvent, IDndRenderItemProps, InlineButton} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import { Dispatch, useCallback, useMemo, useState } from 'react';
import {useAddingSpaceStatusDialog} from '../hooks/useAddingSpaceStatusDialog';
import {IExtendedSpaceStatus} from '../interfaces/IExtendedSpaceStatus';
import {getExtendedStatusesByStage} from '../utils/getExtendedStatuses';
import {DesktopEditableSpaceStatus} from './desktop/DesktopEditableSpaceStatus';
import {MobileEditableSpaceStatus} from './mobile/MobileEditableSpaceStatus';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';

interface IStageStatusesProps {
	statuses?: ISpaceStatus[];
	stage: IObjectStage;
	disabled?: boolean;
	spaceStatusesSetId: string;

	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;
	editingState: IEditableSpaceStatusesSetState;

	updateStatus: (index: number) => Promise<ISpaceStatus | undefined>;
	updateStatusesOrder: (
		order: ISpaceStatusOrder[]
	) => Promise<INormalizedData<ISpaceStatus> | undefined>;
	removeStatus: (id: string) => Promise<IDeleteSpaceStatusResponse | undefined>;
}

const plusIcon = <i className="tz-plus-20" />;

const classes = {
	item: 'editable-space-statuses__status-dnd',
	handle: 'editable-space-statuses__status-dnd-handle',
	itemWithoutHandle: 'editable-space-statuses__status-dnd-without-handle'
};

export const StageStatuses = ({
	statuses,
	stage,
	disabled,
	spaceStatusesSetId,
	editingDispatch,
	editingState,
	updateStatus,
	updateStatusesOrder,
	removeStatus
}: IStageStatusesProps) => {
	const title = useMemo(() => `Стадия: ${stage.name}`, [stage]);
	const {data: stagesMap} = useObjectStagesMap();
	const [editedStatuses, setEditedStatuses] = useState<string[]>([]);
	const {statusesByStage, draggable} = useMemo(
		() =>
			getExtendedStatusesByStage(
				editingState.statuses,
				stagesMap || {},
				stage,
				editedStatuses
			),
		[editingState.statuses, stagesMap, stage, editedStatuses]
	);

	const handleCreateStatus = useCallback(() => {
		if (spaceStatusesSetId === 'empty') {
			return undefined;
		}

		const id = generateKey();
		const order = getNewItemOrder(editingState.statuses);
		const savingSpaceStatus: IEditableStatus = {
			id,
			spaceStatusesSetId,
			stage: stage.id,
			color: '#FFFFFF',
			order,
			isLocal: true,
		};

		editingDispatch({
			type: 'add-in-array',
			field: 'statuses',
			value: [savingSpaceStatus]
		});
		setEditedStatuses(s => [...s, id]);

		return savingSpaceStatus;
	}, [editingState.statuses, spaceStatusesSetId, stage]);

	const handleDragEnd = useCallback(
		async ({items, container}: IDndEvent<IExtendedSpaceStatus>) => {
			if (!container) {
				return;
			}

			const newOrder: ISpaceStatusOrder[] = [];
			const newItems: IExtendedSpaceStatus[] = [];
			for (const item of items) {
				if (editedStatuses.includes(item.id) || item.isLocal) {
					continue;
				}

				const newItem: IExtendedSpaceStatus = {
					...item,
					stage: container as ObjectStageIds,
					order: item.order
				};

				newOrder.push({id: newItem.id, stage: newItem.stage, order: newItem.order});
				newItems.push(newItem);
			}

			const res = await updateStatusesOrder(newOrder);
			if (res) {
				for (const value of newItems) {
					editingDispatch({
						type: 'update-array-item',
						field: 'statuses',
						index: value.index,
						value
					});
				}
			} else {
				editingDispatch({type: 'update', field: 'statuses', value: editingState.statuses});
			}
		},
		[editingState.statuses, updateStatusesOrder, editedStatuses]
	);

	const isLargeTablet = useIsLargeTablet();

	const [open, addingDialog] = useAddingSpaceStatusDialog({
		createStatus: handleCreateStatus,
		updateStatus,
		editingDispatch,
		setEdited: setEditedStatuses,
		statuses,
		statusesByStage
	});

	const renderItem = useCallback(
		({item}: IDndRenderItemProps<IExtendedSpaceStatus>) => (
			<>
				{isLargeTablet ? (
					<DesktopEditableSpaceStatus
						disabled={disabled}
						status={item}
						statuses={statuses}
						isEdited={editedStatuses.includes(item.id)}
						setEdited={setEditedStatuses}
						editingDispatch={editingDispatch}
						updateStatus={updateStatus}
						removeStatus={removeStatus}
					/>
				) : (
					<MobileEditableSpaceStatus
						disabled={disabled}
						status={item}
						statuses={statuses}
						setEdited={setEditedStatuses}
						editingDispatch={editingDispatch}
						updateStatus={updateStatus}
						removeStatus={removeStatus}
					/>
				)}
			</>
		),
		[disabled, statuses, editedStatuses, editingDispatch, updateStatus, removeStatus]
	);

	return (
		<div className="editable-space-statuses__stage">
			<div className="editable-space-statuses__stage-title">{title}</div>
			<DndList
				className={classes}
				items={statusesByStage}
				renderItem={renderItem}
				draggableIds={draggable}
				container={stage.id}
				emptyMessage="Нет статусов для стадии"
				onDragEnd={handleDragEnd}
			/>

			<InlineButton
				className="editable-space-statuses__stage-add"
				disabled={disabled}
				leftIcon={plusIcon}
				label="Добавить статус"
				type="accent"
				onClick={isLargeTablet ? handleCreateStatus : open}
			/>
			{!isLargeTablet && !disabled && addingDialog}
		</div>
	);
};
