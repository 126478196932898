import ContentLoader from 'react-content-loader';

export const TypesCountLoader = () => (
	<ContentLoader
		speed={2}
		width={1000}
		height={56}
		viewBox="0 0 1000 56"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="0" y="22" rx="10" ry="10" width="500" height="34" />
	</ContentLoader>
);
