import {IDevice} from '../interfaces/IDevice';
import UAParser from 'ua-parser-js';
import {UniqueIdUtil} from './generateUniqueId';

export const platformsMap: Record<string, string> = {
	console: 'Консоль',
  	mobile: 'Телефон',
  	tablet: 'Планшет',
  	smarttv: 'ТВ',
  	wearable: 'Носимое устройство',
	emdedded: 'Встроенное устройство'
};

export const getDevice = async (): Promise<IDevice | undefined> => {
	const userAgent = window.navigator.userAgent;
	if (!userAgent) { return; }

	const {browser: browserObj, device: deviceObj, os: osObj} = UAParser(userAgent);
	const idUtil = new UniqueIdUtil();

	const device: IDevice = {
		deviceId: await idUtil.generateLongId(),
		browser: browserObj.name
			? `${browserObj.name} ${browserObj.version || ''}`
			: 'Неизвестный браузер',
		os: osObj.name
			? `${osObj.name} ${osObj.version || ''}`
			: 'Неизвестная ОС',
		platform: deviceObj.type && platformsMap[deviceObj.type]
			? platformsMap[deviceObj.type]
			: 'Компьютер'
	};

	return device;
};