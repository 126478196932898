import {Dispatch, useCallback} from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {IStructureType} from '@tehzor/tools/interfaces/structures/IStructureType';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {
	useExtractStructureTypesAsArray,
	useExtractStructureTypesById
} from '@src/core/hooks/queries/structureTypes/hooks';
import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";
import {useTranslatedDictionaryArray} from "@src/core/hooks/translations/useTranslatedDictionaryArray";
import {useTranslatedDictionaryMap} from "@src/core/hooks/translations/useTranslatedDictionaryMap";

interface IStructureTypeSelectProps<S, E> {
	field?: keyof S;
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	translationDictionaryKey?: dictionaryKeys
}

const getInputLabel = (
	value: string | string[] | undefined,
	types: IStructureType[],
	typesMap: Record<string, IStructureType>
) => {
	if (!value) {
		return undefined;
	}

	if (Array.isArray(value)) {
		return value.length ? makeFilterLabel('Выбрано', value, types) : undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

export const StructureTypeSelect = <
	S extends {type?: string; structureType?: string; structureTypes?: string[]},
	E
>({
	className,
	style,
	field = 'type',
	label,
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	translationDictionaryKey = dictionaryKeys.structureTypes
}: IStructureTypeSelectProps<S, E>) => {
	const {data: types} = useExtractStructureTypesAsArray();
	const {data: typesMap} = useExtractStructureTypesById();
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.structureTypeSelect.label');
	const translatedTypes = useTranslatedDictionaryArray(translationDictionaryKey, types);
	const translatedTypesMap = useTranslatedDictionaryMap(translationDictionaryKey, typesMap);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required, editingDispatch, field]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>
			{translatedTypes && translatedTypesMap && (
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={getInputLabel(value, translatedTypes, translatedTypesMap)}
							icon={<i className="tz-simple-arrow-20" />}
							error={
								required && hasError
									? t('components.editableFields.structureTypeSelect.error')
									: undefined
							}
							disabled={disabled}
						/>
					}
				>
					<Select2
						value={value}
						onChange={handleChange}
					>
						{translatedTypes.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			)}
		</div>
	);
};
