import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useQuery} from '@tanstack/react-query';
import {extractCheckInspectionsArray, extractCheckInspectionsEntity} from './selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCallback} from 'react';
import {extractCheckVisibility} from '@src/store/modules/settings/pages/check/selectors';
import {IGetListInspectionsResponse} from '@src/api/backend/inspections';

export const useCheckInspections = <T = IGetListInspectionsResponse>(
	objectId: string,
	checkId: string,
	select?: (data: IGetListInspectionsResponse) => T
) => useQuery({queryKey: checksQueryKeys.checkInspections(objectId, checkId), select});

export const useCheckInspectionsEntities = (objectId: string, checkId: string) => {
	const visibility = useAppSelector(extractCheckVisibility);
	const selector = useCallback(
		(data: IGetListInspectionsResponse) => extractCheckInspectionsEntity(data, visibility),
		[visibility]
	);
	return useCheckInspections(objectId, checkId, selector);
};

export const useCheckInspectionsArray = (objectId: string, checkId: string) =>
	useCheckInspections(objectId, checkId, extractCheckInspectionsArray);
