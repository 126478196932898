import {ExpandableMobileInfo} from '@src/components/ExpandableMobileInfo';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {EditorInfo, EntityGrid, Plate} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import { ReactNode } from 'react';

interface ISpaceStatusesSetInfoProps {
	setEl: ReactNode;
	spaceStatusesSet?: ISpaceStatusesSet;
}

export const SpaceStatusesSetInfo = ({setEl, spaceStatusesSet}: ISpaceStatusesSetInfoProps) => {
	const isLargeTablet = useIsLargeTablet();
	const addInfo = (
		<>
			{spaceStatusesSet?.createdAt && (
				<EditorInfo
					className="space-statuses-set__wrap-info__add-item"
					user={spaceStatusesSet?.createdBy}
					date={spaceStatusesSet?.createdAt}
					label="Создано"
					fullRow
				/>
			)}
			{spaceStatusesSet?.modifiedAt && (
				<EditorInfo
					className="space-statuses-set__wrap-info__add-item"
					user={spaceStatusesSet?.modifiedBy}
					date={spaceStatusesSet?.modifiedAt}
					label="Изменено"
					fullRow
				/>
			)}
		</>
	);

	return (
		<Plate className="space-statuses-set__wrap-info">
			<EntityGrid>
				{setEl}

				{isLargeTablet && <>{addInfo}</>}
			</EntityGrid>

			{!isLargeTablet && (
				<ExpandableMobileInfo noBorder>
					<EntityGrid withBorders>{addInfo}</EntityGrid>
				</ExpandableMobileInfo>
			)}
		</Plate>
	);
};
