import {localAuthStorage} from '@tehzor/tools/api/localAuthStorage';
import {useEffect, useState} from 'react';
import {useAsync} from 'react-use';

export const useGetDeviceId = () => {
	const [deviceId, setDeviceId] = useState<string | undefined>(undefined);

	const state = useAsync(() => localAuthStorage.getDeviceId(), []);

	useEffect(() => {
		if (state.value) {
			setDeviceId(state.value);
		}
	}, [state]);

	return deviceId;
};
