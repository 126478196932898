import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IRestoreCheckItemResponse = INormalizedData<ICheckItem>;

/**
 * Восстанавливает удаленную категорию чеклиста вместе со всеми вложениями
 *
 * @param checkListId id чек-листа
 * @param id  идентификатор категориии которую восстанавливаем
 */
export const makeCheckItemRestoreRequest = async (checkListId: string, id: string) => {
	const response = await httpRequests.withToken.put<IRestoreCheckItemResponse>(
		'/check-items/restore',
		{checkListId, id}
	);
	return response.data;
};
