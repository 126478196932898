import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {requestComments} from '@src/api/backend/comments';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

export type IGetInternalAcceptanceProblemRepliesPayload = INormalizedData<IComment>;

const request = () => ({type: types.GET_PROBLEM_REPLIES_REQUEST});

const success = (response: IGetInternalAcceptanceProblemRepliesPayload) => ({
	type: types.GET_PROBLEM_REPLIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке ответов на нарушения');
	return {
		type: types.GET_PROBLEM_REPLIES_FAILURE,
		payload: error
	};
};

/**
 * Получает список ответов на нарушения для конкретной внутренней приёмки
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 */
export const getInternalAcceptanceProblemReplies = (
	objectId: string,
	internalAcceptanceId: string
) => checkExpiration<IState, IGetInternalAcceptanceProblemRepliesPayload, ApiAction>(
		s => s.entities.internalAcceptance.problemReplies,
		createApiAction<IGetInternalAcceptanceProblemRepliesPayload>(
			request,
			success,
			failure,
			() =>
			requestComments(
					{
						commentTypeId: ICommentTypeId.PROBLEM_COMMENT,
						objects: [objectId],
						internalAcceptances: [internalAcceptanceId],
						official: true
					},
					{createdAt: false},
					0,
					1000
				).then(({byId, allIds}) => ({
					byId,
					allIds
				}))
		)
	);
