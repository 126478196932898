import IAttachment from "@tehzor/tools/interfaces/IAttachment";

export function isAttachmentsEqual(arr1: IAttachment[], arr2: IAttachment[]) {
	let flag = true;
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i].id !== arr2[i].id) {
			flag = false;
		}
	}
	return flag;
}