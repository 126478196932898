import {useQueryClient} from '@tanstack/react-query';
import {bankAccountsQueryKeys} from '../keys';
import {requestBankAccounts} from '@src/api/backend/bankAccounts';

export const useBankAccountsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(bankAccountsQueryKeys.list(), {
		queryFn: requestBankAccounts,
		meta: {
			error: 'при загрузке реквизитов'
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
