import {IEntity} from '@tehzor/ui-components/src/components/photos/EntitiesMobilePhotoViewer/EntitiesMobilePhotoViewer';
import {useCallback, useMemo} from 'react';
import * as React from 'react';
import {useEntitiesViewer} from './useEntitiesViewer';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import ICanvasData from '@tehzor/tools/interfaces/ICanvasData';
import IAttachmentsImageData from '@tehzor/tools/interfaces/IAttachmentsImageData';
import IEditImage from '@tehzor/tools/interfaces/IEditImage';
import {useTranslation} from 'react-i18next';

/**
 * Хук для создания просмотрщика изображений в редактировании сущности
 *
 * @param attachmentsImages массив ссылок на аттачменты
 * @param uploadsImages массив ссылок на вновь прикрепленные изображения
 * @param attachmentsImagesData данные аттачментов
 * @param uploadsImagesData данные вновь прикрепленных изображений
 * @param entityId id сущности
 * @param attachmentsType тип изображений, для какой сущности предназначены
 * @param attachmentsTitle заголовок в просмотрщике
 * @param canDraw можно ли рисовать
 * @param updateUploads функция обновления прикрепленных изображений
 * @param moveToUploads функция перемещения изображений в temp
 */
export function useAttachmentsAndUploadsViewer(
	attachmentsImages: string[],
	uploadsImages: string[],
	attachmentsImagesData: IAttachmentsImageData[],
	uploadsImagesData: Array<{
		id: string;
		key: string;
		url: string;
		canvas?: ICanvasData;
		original?: string;
	}>,
	entityId: string | undefined,
	attachmentsType: AttachmentFileDestination | undefined,
	attachmentsTitle: string,
	canDraw?: boolean,
	updateUploads?: (value: IEditImage) => Promise<void>,
	moveToUploads?: (value: IEditImage) => Promise<void>
): [React.ReactNode, (id: string) => void, (id: string) => void] {
	const {t} = useTranslation();
	// Формирование общего списка изображений
	const data = useMemo(() => {
		const result: IEntity[] = [];
		if (attachmentsImages.length) {
			if (entityId) {
				result.push({
					id: entityId,
					title: attachmentsTitle,
					files: attachmentsImages,
					type: attachmentsType,
					attachments: attachmentsImagesData
				});
			}
		}
		if (uploadsImages.length) {
			result.push({
				id: 'uploads',
				title: t('imagesViewer.uploadsImages.title'),
				files: uploadsImages,
				type: AttachmentFileDestination.TEMP,
				attachments: uploadsImagesData
			});
		}
		return result;
	}, [attachmentsImages, uploadsImages, entityId]);

	const [viewer, openImage] = useEntitiesViewer(
		data,
		canDraw || false,
		updateUploads,
		moveToUploads,
		true
	);

	const openAttachmentImage = useCallback(
		(id: string) => {
			const activeImage = attachmentsImagesData.filter(img => img.id === id)[0];
			const index = attachmentsImages.indexOf(activeImage.url);
			if (entityId) {
				openImage(entityId, index);
			}
		},
		[openImage, entityId, attachmentsImages]
	);
	const openUploadImage = useCallback(
		(id: string) => {
			const activeUpload = uploadsImagesData.filter(img => img.key === id)[0];
			const index = uploadsImages.indexOf(activeUpload.url);
			openImage('uploads', index);
		},
		[openImage, uploadsImages]
	);

	return [viewer, openAttachmentImage, openUploadImage];
}
