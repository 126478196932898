import * as types from '../constants';
import {togglePlanBusy} from '@src/actions/ui/planUIActions';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IEditPlanResponse, makePlanEditRequest} from '@src/api/backend/plan';
import {addErrorToast} from '@src/utils/toasts';
import ISavingPlan from '@tehzor/tools/interfaces/plans/ISavingPlan';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {queryClient} from '@src/api/QueryClient';

export type IEditPlanPayload = IEditPlanResponse;

const request = () => {
	togglePlanBusy(true);
	return {type: types.EDIT_REQUEST};
};

const success = (response: IEditPlanResponse) => {
	togglePlanBusy(false);
	void queryClient.invalidateQueries({queryKey: plansQueryKeys.lists()});
	return {
		type: types.EDIT_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при изменении плана');
	}
	togglePlanBusy(false);
	return {
		type: types.EDIT_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет план
 *
 * @param objectId id оъекта
 * @param plan данные плана
 */
export const editPlan = (objectId: string, plan: ISavingPlan) =>
	createApiAction<IEditPlanResponse>(
		request,
		res => success(res),
		failure,
		() => makePlanEditRequest(objectId, plan)
	);
