import {matchPath, useLocation} from 'react-router-dom';

/**
 * Возвращает ключ текущей страницы
 */
export const usePageKey = () => {
	const location = useLocation();
	const path = location.pathname;
	if (matchPath({path: '/'}, path)) {
		return 'objects';
	}
	if (matchPath({path: '/objects'}, path)) {
		return 'objects';
	}
	if (matchPath({path: '/checks'}, path)) {
		return 'checks';
	}
	if (matchPath({path: '/work-acceptances'}, path)) {
		return 'workAcceptances';
	}
	if (matchPath({path: '/internal-acceptances'}, path)) {
		return 'internalAcceptances';
	}
	if (matchPath({path: '/problems'}, path)) {
		return 'problems';
	}
	if (matchPath({path: '/inspections'}, path)) {
		return 'inspections';
	}
	if (matchPath({path: '/warranty-claims'}, path)) {
		return 'warrantyClaims';
	}
	if (matchPath({path: '/statistics'}, path)) {
		return 'statistics';
	}
	return undefined;
};
