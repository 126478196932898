import {cloneElement, ReactElement, useMemo, MouseEvent} from 'react';
import {IScrollbarsProps} from '../components/containers/Scrollbar/Scrollbar';
import {IMenuComponentProps, IMenuItemProps} from '../components/menu/MenuItem';

/**
 * Клонирует MenuItem, заменяя в нём onClick обработчик
 *
 * @param element оригинальный элемент
 * @param index индекс в массиве элементов
 * @param close функция закрытия меню
 * @param timeout время ожидания после закрытия
 */
function cloneMenuItem(element: ReactElement<IMenuItemProps>, index: number, close: () => void, timeout: number) {
	const {onClick} = element.props;
	let newHandler;
	if (onClick) {
		newHandler = (event: MouseEvent<HTMLDivElement>) => {
			close();
			// Отложенный вызов необходим для плавной анимации закрытия Popup/Dialog
			setTimeout(() => onClick(event), timeout);
		};
	}
	return cloneElement(element, {
		key: element.props.itemKey || index,
		onClick: newHandler
	});
}

/**
 * Клонирует Menu, заменяя в нём onChange обработчик, и клонируя все дочерние MenuItem
 *
 * @param element оригинальный элемент
 * @param close функция закрытия меню
 * @param timeout время ожидания после закрытия
 */
function cloneMenu<V>(element: ReactElement<IMenuComponentProps<V>>, close: () => void, timeout: number) {
	const {onChange} = element.props;
	let newHandler;
	if (onChange) {
		newHandler = (key: V) => {
			close();
			// Отложенный вызов необходим для плавной анимации закрытия Popup'а
			setTimeout(() => onChange(key), 150);
		};
	}
	let items;
	if (element.props.children) {
		if (Array.isArray(element.props.children)) {
			items = element.props.children.map((item, i) => cloneMenuItem(item, i, close, timeout));
		} else {
			items = cloneMenuItem(element.props.children, 0, close, timeout);
		}
	}
	return cloneElement(element, {onChange: newHandler}, items);
}

function cloneScrollbar<V, M extends IMenuComponentProps<V>>(
	element: ReactElement<IScrollbarsProps>, close: () => void, timeout: number
) {
	const menu = cloneMenu(element.props.children as ReactElement<M>, close, timeout);
	return cloneElement(element, {}, menu);
}

/**
 * Клонирует children для отложенного запуска обработчиков onClick и onChange у MenuItem и Menu
 *
 * @param children дочерние элементы
 * @param close функция закрытия меню
 * @param timeout время ожидания после закрытия
 */
export const useClonedMenu = <V, M extends IMenuComponentProps<V>>(
	children: ReactElement<M> | Array<ReactElement<IMenuItemProps>> | undefined,
	close: () => void,
	timeout: number
) =>
	useMemo(
		() => {
			if (children) {
				if (Array.isArray(children)) {
					return children.map((item, i) => cloneMenuItem(item, i, close, timeout));
				}
				if (typeof children.type === 'function') {
					const displayName = (children.type as unknown as { displayName: string | undefined }).displayName;
					switch (displayName) {
						case 'Menu':
							return cloneMenu(children, close, timeout);
						case 'MultipleMenu':
							return children;
						case 'MenuItem':
							return cloneMenuItem(children, 0, close, timeout);
					}
				}

				const isScrollbar = (
					children.props.children
					&& typeof children.props.children === 'object'
					&& 'type' in children.props.children
					&& typeof children.props.children.type === 'function'
				);
				if (isScrollbar) {
					return cloneScrollbar(children, close, timeout);
				}
			}
			return children;
		},
		[children, close, timeout]
	);