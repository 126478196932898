import {useSpacesMutationDefaults} from './useSpacesMutationDefaults';
import {useSpacesQueryDefaults} from './useSpacesQueryDefaults';
import {useSpacesIndicatorsChangesQueryDefaults} from './useSpacesIndicatorsChangesQueryDefaults';
import {useSpacesStatusChangesQueryDefaults} from './useSpacesStatusChangesQueryDefaults';
import {useSpacesTypesCountQueryDefaults} from './useSpacesTypesCountQueryDefaults';

export const useSpacesCacheDefaults = () => {
	useSpacesMutationDefaults();
	useSpacesQueryDefaults();
	useSpacesIndicatorsChangesQueryDefaults();
	useSpacesStatusChangesQueryDefaults();
	useSpacesTypesCountQueryDefaults();
};
