import {Tree} from 'array-to-tree';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import {statsObjectsSortFn} from './statsObjectsSortFn';

export const convertToStatsObject = (item: Tree<IObject>): Tree<IStatsObject> => ({
	id: item.id,
	name: item.name,
	ancestors: item.ancestors,
	parentId: item.parentId,
	companyId: item.companyId,
	image: item.previewImage,
	city: item.city,
	order: item.order,
	children: item.children?.map(convertToStatsObject).sort(statsObjectsSortFn)
});
