import { forwardRef, Ref } from 'react';
import * as React from 'react';
import ButtonBase from '../ButtonBase';
import './MobileFilterButton.less';
import classNames from 'classnames';

export interface IMobileFilterButtonProps {
	className?: string;
	style?: React.CSSProperties;
	label?: React.ReactNode;
	disabled?: boolean;
	active?: boolean;

	onClick: () => void;
}

const MobileFilterButton = (
	props: IMobileFilterButtonProps,
	ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>
) => {
	const {className, style, label, disabled, onClick, active} = props;

	return (
		<ButtonBase
			className={classNames(active ? 'mobile-filter-button_active' : 'mobile-filter-button_inactive', className)}
			style={style}
			label={label}
			rightIcon={
				active ? (
					<i className={classNames('tz-close-16', 'mobile-filter-button__close-icon')}/>
				) : null
			}
			disabled={disabled}
			classNamePrefix="mobile-filter-button"
			outerTagType="button"
			onClick={onClick}
			ref={ref}
		/>
	);
};

MobileFilterButton.displayName = 'MobileFilterButton';

export default forwardRef(MobileFilterButton);
