import {Dispatch, useCallback} from 'react';
import * as React from 'react';
import {EditableFieldLabel, ElementType, TextField} from '@tehzor/ui-components';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {ElementPropsType} from '@tehzor/ui-components/src/components/inputs/TextField/TextField';
import {useTranslation} from 'react-i18next';

interface ITextProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	field: keyof S;
	label?: React.ReactNode;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: string;
	elementType?: ElementType;
	textAreaProps?: ElementPropsType;
	icon?: React.ReactNode;
}

const defaultTextAreaProps = {
	minRows: 2,
	maxRows: 6
};

const Text = <S extends Record<string, unknown>, E>({
	className,
	style,
	field,
	label,
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	errorMessage,
	elementType = 'input',
	textAreaProps = defaultTextAreaProps,
	icon
}: ITextProps<S, E>) => {
	const {t} = useTranslation();
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			{label ? <EditableFieldLabel>{label}</EditableFieldLabel> : null}

			<TextField
				value={value}
				elementType={elementType}
				elementProps={textAreaProps}
				icon={icon}
				error={
					required && hasError
						? errorMessage || t('components.editableFields.text.error')
						: undefined
				}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default Text;
