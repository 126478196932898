import {memo, useMemo, useState} from 'react';
import * as React from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface IBaseListFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	options: Array<{id: string; name: string}>;
	label: string;
	filterName: string;
	resetOnApply?: string[];
	search?: React.ReactNode;
	translationDictionaryKey?: dictionaryKeys;

	onChange: (changes: IChanges) => void;
}

export const BaseListFilterMobile = memo(
	({
		objectId,
		value,
		entity,
		options,
		label,
		filterName,
		resetOnApply,
		search,
		translationDictionaryKey,
		onChange
	}: IBaseListFilterMobileProps) => {
		const {t} = useTranslation();

		const [selected, setSelected] = useState(value);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, filterName);

		const translatedOptions = useTranslatedDictionaryArray(translationDictionaryKey, options);
		const finalOptions = useMemo(
			() => (translationDictionaryKey ? translatedOptions : options),
			[translationDictionaryKey, translatedOptions, options]
		);

		const handleChange = (v: string[] | undefined) => {
			const changes = {[filterName]: v?.length ? v : undefined};
			resetOnApply?.forEach(el => {
				changes[el] = undefined;
			});
			onChange(changes);
		};

		return (
			<MobileFilter
				label={label}
				elements={finalOptions}
				selected={selected}
				onChange={handleChange}
				// onExpandButtonLabel={t('baseListFilterMobile.mobileFilter.onExpandButtonLabel')}
				onExpand={handleExpand}
				linkBtnLabel={t('problemsFiltersPage.allBtn.label')}
				search={search}
			/>
		);
	}
);
