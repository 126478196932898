import {ReactNode} from 'react';
import styles from './BottomBar.module.less';

interface IBottomBarProps {
	children: ReactNode;
}

export const BottomBar = ({children}: IBottomBarProps) => (
	<div className={styles.wrapper}>{children}</div>
);
