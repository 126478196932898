import {IGetListProblemsResponse, requestListProblems} from '@src/api/backend/problems';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../../constants/problems';

export type ICheckChangedProblemsPayload = IGetListProblemsResponse;

const request = () => ({
	type: types.CHECK_REQUEST
});

const success = (response: IGetListProblemsResponse) => ({
	type: types.CHECK_SUCCESS,
	payload: {
		...response
	} as ICheckChangedProblemsPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка нарушений');
	}
	return {
		type: types.CHECK_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список нарушений
 */
export const checkChangedProblems
	= (
		checkListIds: string[],
		spaceId: string | undefined,
		workAcceptanceId: string | undefined

	): AppThunkAction<Promise<IGetListProblemsResponse>> =>
		dispatch =>
			dispatch(
				createApiAction<IGetListProblemsResponse>(request, success, failure, () => {
					const spaces = [];
					const workAcceptanceIds = [];

					if (spaceId) {
						spaces.push(spaceId);
					}

					if (workAcceptanceId) {
						workAcceptanceIds.push(workAcceptanceId);
					}

					return requestListProblems(
						{
							checkListIds,
							spaces,
							workAcceptanceIds
						},
						{createdAt: false}
					);
				})
			);
