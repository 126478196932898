import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '../StatusesFilterPage';
import {useMainAndSubCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';

const MainCompaniesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const {data} = useMainAndSubCompaniesAsArray();
	const companies = data?.mainCompanies;

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={companies}
			label={t('entitiesFilters.companiesFilter.label')}
			filterName="companies"
		/>
	);
};

export default MainCompaniesFilterPage;
