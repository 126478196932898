import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISavingSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISavingSpaceStatus';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddSpaceStatusResponse = ISpaceStatus;

/**
 * Добавляет статус помещения
 *
 * @param spaceStatus статус помещения
 */
export const requestAddSpaceStatus = async (spaceStatus: ISavingSpaceStatus) => {
	const response = await httpRequests.withToken.post<IAddSpaceStatusResponse>('space-statuses', {
		...spaceStatus
	});
	return response.data;
};
