import {IconButton} from '@tehzor/ui-components';
import {useInspectionsExport} from '@src/core/hooks/export/useInspectionsExport';

interface IMobileChecksExportProps {
	objectId?: string;
}

const MobileChecksExport = ({objectId}: IMobileChecksExportProps) => {
	const [exportDialog, openExportDialog] = useInspectionsExport(objectId);

	return (
		<>
			<IconButton onClick={openExportDialog}>
				<i className="tz-export-24"/>
			</IconButton>
			{exportDialog}
		</>
	);
};

export default MobileChecksExport;
