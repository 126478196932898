import './NoData.less';
import {VerticalTabContent} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface INoDataProps {
	asTabContent?: boolean;
}

const NoData = ({asTabContent}: INoDataProps) => {
	const {t} = useTranslation();
	if (asTabContent) {
		return (
			<VerticalTabContent className="statistics-page__tabs-content-panel stats-no-data__tab-content">
				<div className="stats-no-data">{t('statisticsPage.component.noData')}</div>
			</VerticalTabContent>
		);
	}
	return <div className="stats-no-data">{t('statisticsPage.component.noData')}</div>;
};

export default NoData;
