import {memo, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {reopenedTimesItems} from '@src/constants/filters/reopenedTimesFilter';

interface IReopenedTimesFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;
	label?: string;

	onChange: (changes: IChanges) => void;
}

export const ReopenedTimesFilterMobile = memo(
	({objectId, value, entity, label, onChange}: IReopenedTimesFilterMobileProps) => {
		const {t} = useTranslation();

		const [selected, setSelected] = useState(value);
		const [search, setSearch] = useState('');

		const filteredData = useMemo(
			() => flatFilter(reopenedTimesItems, 'name', search),
			[search]
		);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, 'reopenedTimes');

		const handleChange = (v: string[] | undefined) => {
			onChange({reopenedTimes: v});
		};

		return (
			<MobileFilter
				label={label}
				elements={filteredData}
				linkBtnLabel={t('problemsFiltersPage.allBtn.label')}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
			/>
		);
	}
);
