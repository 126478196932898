import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDocumentsStatisticsData} from '@tehzor/tools/interfaces/documentsStatistics/IDocumentsStatisticsData';

export type IGetChecksDocumentsStatsResponse = INormalizedData<IDocumentsStatisticsData>;

/**
 * Возвращает количество документов у списка проверок
 */
export const requestChecksDocumentsStats = async (checkIds: string[]) => {
	const params = {
		ids: checkIds
	};
	const response = await httpRequests.withToken.get<IGetChecksDocumentsStatsResponse>('documents-statistics/checks', {params});
	return response.data;
};

