import {IDocumentsFiltersState, IDocumentsSortState} from '@src/store/modules/settings/pages/documents/reducers';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export type IGetListDocumentsResponse = INormalizedData<IDocument>;

/**
 * Возвращает список документов
 */
export const requestGetListDocuments = async (
	filters?: IDocumentsFiltersState,
	sort?: IDocumentsSortState
): Promise<IGetListDocumentsResponse> => {
	const params = {filters, sort: convertSortParam(sort)};
	const response = await httpRequests.withToken.post<IGetListDocumentsResponse>(
		'documents/get-list', params
	);

	return response.data;
};
