import {
	useObjectStagesAsArray,
	useObjectStagesMap
} from '@src/core/hooks/queries/objectStages/hooks';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IDefaultSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/IDefaultSpaceStatus';
import {
	ActionButtons,
	Button,
	Dialog,
	PopupColorPicker,
	Select2 as Select,
	SelectOption,
	SelectPopup,
	TextField,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import { useCallback, useState } from 'react';

interface IEditableDefaultStatusFieldProps {
	value?: IDefaultSpaceStatus;
	hasError?: boolean;

	onChange: (value?: IDefaultSpaceStatus) => void;
	onError: () => void;
}

const statusIcon = <i className="tz-status-20" />;

export const DefaultStatusField = ({
	value,
	hasError,
	onChange,
	onError
}: IEditableDefaultStatusFieldProps) => {
	const [isOpen, setOpen] = useState(false);

	const [hasNameError, setHasNameError] = useState(false);
	const [hasStageError, setHasStageError] = useState(false);

	const [name, setName] = useState(value?.name);
	const [color, setColor] = useState(value?.color || '#fff');
	const [stage, setStage] = useState(value?.stage);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const {data: stages} = useObjectStagesAsArray();
	const {data: stagesMap} = useObjectStagesMap();

	const handleChangeName = useCallback((val: string) => {
		setName(val);
		setHasNameError(!val);
	}, []);

	const handleChangeStage = useCallback((val: string) => {
		setStage(val as ObjectStageIds);
		setHasStageError(!val);
	}, []);

	const handleChangeColor = useCallback((val: string) => {
		setColor(val);
	}, []);

	const handleCancel = useCallback(() => {
		setName(value?.name);
		setColor(value?.color || '#fff');
		setStage(value?.stage);
		setHasNameError(false);
		setHasStageError(false);
		close();
	}, [onChange, value]);

	const handleConfirm = useCallback(() => {
		let hasErrors = false;
		if (!name) {
			setHasNameError(true);
			hasErrors = true;
		}

		if (!stage) {
			setHasStageError(true);
			hasErrors = true;
		}

		if (hasErrors || !name || !stage) {
			return;
		}

		onChange({name, stage, color});
		onError();
		close();
	}, [onChange, onError, close, name, stage, color]);

	const trigger = (
		<TextFieldWithForwardedRef
			elementType="div"
			value={value?.name}
			placeholder="Статус по умолчанию"
			icon={statusIcon}
			onClick={open}
			error={hasError ? 'Создайте статус по умолчанию' : undefined}
		/>
	);

	const footer = (
		<div className="editable-space-statuses-set__fields-default__dialog-footer">
			<ActionButtons className="editable-space-statuses-set__fields-default__dialog-footer__btns">
				<Button
					type="cancel"
					label="Отменить"
					onClick={handleCancel}
				/>
				<Button
					type="accent-blue"
					label="Сохранить"
					disabled={hasNameError || hasStageError}
					onClick={handleConfirm}
				/>
			</ActionButtons>
		</div>
	);

	const nameField = (
		<div className="editable-space-statuses-set__fields-default__name">
			<div className="editable-space-statuses-set__fields-default__name-title">
				Название статуса
			</div>
			<TextField
				className="editable-space-statuses-set__fields-default__name-input"
				elementType="input"
				value={name}
				onChange={handleChangeName}
				error={hasNameError ? 'Укажите название статуса' : undefined}
			/>
		</div>
	);

	const stagesField = (
		<div className="editable-space-statuses-set__fields-default__stages">
			<div className="editable-space-statuses-set__fields-default__stages-title">
				Стадия статуса
			</div>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						className="editable-space-statuses-set__fields-default__stages-input"
						elementType="div"
						value={stage && stagesMap ? stagesMap[stage].name : ''}
						error={hasStageError ? 'Укажите стадию статуса' : undefined}
					/>
				}
			>
				<Select
					value={stage}
					onChange={handleChangeStage}
				>
					{stages &&
						stages.map(stage => (
							<SelectOption
								key={stage.id}
								itemKey={stage.id}
								content={stage.name}
								inputType="radio"
							/>
						))}
				</Select>
			</SelectPopup>
		</div>
	);

	const colorField = (
		<div className="editable-space-statuses-set__fields-default__color">
			<PopupColorPicker
				className="editable-space-statuses-set__fields-default__color-picker"
				value={color}
				onChange={handleChangeColor}
				presetColors
			/>
		</div>
	);

	const content = (
		<div className="editable-space-statuses-set__fields-default__content">
			<div className="editable-space-statuses-set__fields-default__content-name">
				{nameField}
			</div>
			<div className="editable-space-statuses-set__fields-default__content-values">
				{stagesField}
				{colorField}
			</div>
		</div>
	);

	const dialog = (
		<Dialog
			className="editable-space-statuses-set__fields-default__dialog"
			isOpen={isOpen}
			title="Статус по умолчанию"
			onRequestClose={close}
			footer={footer}
		>
			{content}
		</Dialog>
	);

	return (
		<div className="editable-space-statuses-set__fields-default">
			{trigger}
			{dialog}
		</div>
	);
};
