import {Column} from 'react-table';

/**
 * Определяет какой использовать список столбцов для таблицы
 *
 * @param isAllObjects является ли текущая страница для всех объектов
 * @param isDesktop подходит ли текущее разрешение под десктоп
 * @param mobileColumns столбцы для мобильной версии
 * @param mobileColumnsWithoutObject столбцы для мобильной версии без объекта
 * @param desktopColumns столбцы для десктоп версии
 * @param desktopColumnsWithoutObject столбцы для десктоп версии без объекта
 */
export function determineTableColumns<E extends object>(
	isAllObjects: boolean,
	isDesktop: boolean,
	mobileColumns: Array<Column<E>>,
	mobileColumnsWithoutObject: Array<Column<E>>,
	desktopColumns: Array<Column<E>>,
	desktopColumnsWithoutObject: Array<Column<E>>
) {
	if (isAllObjects) {
		return isDesktop ? desktopColumns : mobileColumns;
	}
	return isDesktop ? desktopColumnsWithoutObject : mobileColumnsWithoutObject;
}
