import {IGetResponsibilityRulesResponse} from '@src/api/backend/responsibilityRules';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';

export const extractResponsibilityRulesAsArray = (
	data?: IGetResponsibilityRulesResponse
): IResponsibilityRule[] | undefined => {
	if (!data) return undefined;
	return data.allIds.map(id => data.byId[id]);
};

export const extractResponsibilityRulesForWorkingGroupAsArray = (
	data?: IGetResponsibilityRulesResponse,
	workingGroupId?: string
): IResponsibilityRule[] | undefined => {
	const rules = extractResponsibilityRulesAsArray(data);
	return rules?.filter(rule => rule.groupId === workingGroupId);
};
