import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export const useTranslatedDictionaryArray = <T extends {id: string}>(
	tKey?: string,
	items?: T[]
) => {
	const {t} = useTranslation();

	return useMemo<T[] | undefined>(() => {
		if (!tKey || !items) {
			return undefined;
		}

		return items.map(item => ({...item, name: t(`${tKey}.${item.id}`)}));
	}, [tKey, items, t]);
};
