export enum OfflineDataCachingStatus {
	/**
	 * Ожидание обновления кеша
	 */
	WAITING = 'waiting',
	/**
	 * Началось обновление кеша
	 */
	LOADING = 'loading',
	/**
	 * Кеш успешно обновлён
	 */
	LOADED = 'loaded',
	/**
	 * Срок жизни кеша не истёк, запрос не выполнялся
	 */
	SKIPPED = 'skipped',
	/**
	 * Ошибка при обновлении кеша
	 */
	ERROR = 'error'
}

export interface IOfflineDataCachingStatuses {
	dictionaries: {[entity: string]: OfflineDataCachingStatus};
}
