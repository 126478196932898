import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteContractResponse {
	success: boolean;
}

/**
 * Удаляет договор
 * 
 * @param contractId id договора
 */
export const requestDeleteContract = async (contractId: string) => {
	const res = await httpRequests.withToken.delete<IDeleteContractResponse>(
		`/contracts/${contractId}`
	);

	return res.data;
};
