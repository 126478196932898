import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetCategoriesResponse = INormalizedData<ICategory>;

/**
 * Возвращает список видов работ
 */
export const requestGetCategories = async () => {
	const response = await httpRequests.withToken.get<IGetCategoriesResponse>('categories/get');

	return response.data;
};
