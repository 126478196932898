import { ReactElement } from 'react';
import {SelectOption} from '@tehzor/ui-components';
import ReasonSelectLink from '@tehzor/ui-components/src/components/ReasonSelect/ReasonSelectLink';
import {IReasonEnhancedRegStandard} from '../components/SearchableProblemReason';

export const reasonRenderItem = <T extends IReasonEnhancedRegStandard>(item: T): ReactElement => (
	<SelectOption
		key={item.id}
		itemKey={item.id}
		inputType="radio"
		content={
			item.fullName !== undefined ? (
				<div className="reason-select__reason-render-item">
					{item.fullName}
					<ReasonSelectLink
						externalLink={item.externalLink}
						link={item.link}
						shortName={item.shortName}
					/>
				</div>
			) : null
		}
	/>
);