import {useTasksSchedulePopup} from "@src/components/TasksSchedulePopup/hooks/useTasksSchedulePopup";
import {IGroupTask} from "@src/pages/TasksPage/utils/groupTasksByStatusAndType";

interface IScheduleMonthVisibleItemProps {
	task: IGroupTask
	objectId?: string;
	startTime?: number;
	endTime?: number;
	popupTitle?: string;
}
export const ScheduleMonthVisibleItem = ({task, objectId, startTime, endTime, popupTitle}: IScheduleMonthVisibleItemProps) => {

	const [TasksSchedulePopup, openTasksSchedulePopup] = useTasksSchedulePopup(
		task.tasks,
		popupTitle,
		objectId,
		startTime,
		endTime
	);

	return (
		<>
			{TasksSchedulePopup}
			<div
				style={{
					backgroundColor: task.scheduleTableColor,
					borderLeft: `4px solid ${task.taskTypeColor}`
				}}
				className="manage-task-schedule-page__grouped-tasks_item"
				onClick={() => openTasksSchedulePopup()}
			>
				{task.count}
			</div>
		</>

	)
}