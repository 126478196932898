import {useMemo, useState, CSSProperties} from 'react';
import './DelegationTree.less';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {Tree} from 'array-to-tree';
import {DelegationTreeGroup} from '../DelegationTreeGroup';
import {DelegationTreeGroupChildren} from '../DelegationTreeGroupChildren';
import {TreeCtx, TreeCtxType} from './utils/TreeCtx';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {formInitialExpandedGroups} from './utils/formInitialExpandedGroups';
import classNames from 'classnames';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {DelegationTreeUser} from '../DelegationTreeUser';

interface IDelegationTreeProps {
	className?: string;
	style?: CSSProperties;
	groups: Array<Tree<IEnrichedWorkingGroup>>;
	author?: IBriefUser;
	activeGroup?: string;
	selectedUsers: string[];
	selectedGroup?: string;
	expandedGroups?: string[];
	onlyInitialLevelSelectable?: boolean;
	hideChildrenLabel?: string;
	showChildrenLabel?: string;
	readMoreHideLabel?: string;
	readMoreShowLabel?: string;
	onUsersSelect: (keys: string[]) => void;
	onGroupSelect: (key: string | undefined) => void;
	onGroupsExpand?: (keys: string[]) => void;
}

export const DelegationTree = (props: IDelegationTreeProps) => {
	const {
		className,
		style,
		groups,
		author,
		activeGroup,
		selectedUsers,
		selectedGroup,
		expandedGroups,
		hideChildrenLabel,
		showChildrenLabel,
		readMoreHideLabel,
		readMoreShowLabel,
		onlyInitialLevelSelectable,
		onUsersSelect,
		onGroupSelect,
		onGroupsExpand
	} = props;

	const [expanded, setExpanded] = useState(() =>
		formInitialExpandedGroups(groups, selectedUsers, selectedGroup, expandedGroups)
	);
	useUpdateEffect(() => {
		setExpanded(expandedGroups ?? []);
	}, [expandedGroups]);

	const ctxValue = useMemo(
		(): TreeCtxType => ({
			isUserSelected: (userId: string, groupId: string | undefined) =>
				selectedGroup === groupId && selectedUsers.includes(userId),
			isGroupSelected: (groupId: string) =>
				selectedGroup === groupId && selectedUsers.length === 0,
			isGroupExpanded: (groupId: string) => expanded.includes(groupId),
			changeUserSelected: (userId: string, groupId: string | undefined, value: boolean) => {
				if (value) {
					if (selectedGroup !== groupId) {
						onUsersSelect([userId]);
						onGroupSelect(groupId);
					} else {
						onUsersSelect([...selectedUsers, userId]);
					}
				} else {
					const newValue = selectedUsers.filter(id => id !== userId);
					onUsersSelect(newValue);
					if (newValue.length === 0) {
						onGroupSelect(undefined);
					}
				}
			},
			changeGroupSelected: (groupId: string, value: boolean) => {
				onGroupSelect(value ? groupId : undefined);
				onUsersSelect([]);
			},
			changeGroupExpanded: (groupId: string, value: boolean) => {
				const newExpanded = value
					? expanded.concat([groupId])
					: expanded.filter(key => key !== groupId);
				if (onGroupsExpand) {
					onGroupsExpand(newExpanded);
				} else {
					setExpanded(newExpanded);
				}
			}
		}),
		[selectedUsers, selectedGroup, expanded, onUsersSelect, onGroupSelect, onGroupsExpand]
	);
	
	return (
		<div className={classNames('delegation-tree', className)} style={style}>
			<TreeCtx.Provider value={ctxValue}>
				{!!author && (
					<>
						<DelegationTreeUser
							key={author.id}
							user={author}
							level={0}
							onlyFirstLevelSelectable={onlyInitialLevelSelectable}
							readMoreHideLabel={readMoreHideLabel}
							readMoreShowLabel={readMoreShowLabel}
						/>
						<div className={classNames('delegation-tree__author-separator')} />
					</>
				)}
				{groups.length ? (
					activeGroup === undefined ? (
						groups.map(group => (
							<DelegationTreeGroup
								key={group.id}
								data={group}
								level={0}
								onlyFirstLevelSelectable={onlyInitialLevelSelectable}
								hideChildrenLabel={hideChildrenLabel}
								showChildrenLabel={showChildrenLabel}
								readMoreHideLabel={readMoreHideLabel}
								readMoreShowLabel={readMoreShowLabel}
							/>
						))
					) : (
						<DelegationTreeGroupChildren
							key={groups[0].id}
							data={groups[0]}
							level={0}
							onlyFirstLevelSelectable={onlyInitialLevelSelectable}
							hideChildrenLabel={hideChildrenLabel}
							showChildrenLabel={showChildrenLabel}
							readMoreHideLabel={readMoreHideLabel}
							readMoreShowLabel={readMoreShowLabel}
						/>
					)
				) : null}
			</TreeCtx.Provider>
		</div>
	);
};

DelegationTree.displayName = 'DelegationTree';
