import { useCallback } from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {extractObjectsPageSettings} from '@src/store/modules/settings/pages/objects/selectors';
import {IObjectsFiltersState} from '@src/store/modules/settings/pages/objects/reducers';
import {changeFilters} from '@src/store/modules/settings/pages/objects/actions';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';

export const ObjectsFiltersRoutingPage = () => {
	const {filters} = useAppSelector(extractObjectsPageSettings) || {};
	const dispatch = useAppDispatch();

	useAsync(async () => {
		const promises: Array<Promise<unknown>> = [];
		promises.push(queryClient.refetchQueries({queryKey: objectsQueryKeys.list()}));
		await Promise.all(promises);
	}, []);

	const applyFilters = useCallback((value: IObjectsFiltersState) => {
		dispatch(changeFilters('all', value));
	}, []);

	return (
		<MobileEntitiesFilters
			objectId="all"
			filters={filters}
			onApply={applyFilters}
			fullClear
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};
