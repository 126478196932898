import {useCallback, useMemo, useState} from 'react';
import {IAnyEntity} from '@tehzor/tools/interfaces/IAnyEntity';
import {ITextSearchProvider} from '../interfaces';

/**
 * Общие обработчики для множественного селекта с поиском
 *
 * @param provider провайдер поиска
 * @param onSelect callback при совершении выбора
 */
export const useMultipleSearchSelectHandlers = <T extends IAnyEntity>(
	provider: ITextSearchProvider<T>,
	onSelect: (value: T[] | undefined) => void
): [string[] | undefined, (value: string[]) => void] => {
	const [selected, setSelected] = useState<string[]>();

	const handleSelection = useCallback(
		(value: string[]) => {
			setSelected(value);
			onSelect(provider.filteredList.filter(({id}) => value.includes(id)));
		},
		[provider.filteredList]
	);

	return useMemo(() => [selected, handleSelection], [selected, handleSelection]);
};
