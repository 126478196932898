import { useMemo } from 'react';
import {CellProps} from 'react-table';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';

const StagesCell = ({row}: CellProps<IEnrichedProblemTagsSet>) => {
	const stages = row.original.stages || [];
	const {data: stagesMap} = useObjectStagesMap();
	const data = useMemo(
		() =>
			stages.map(id => {
				const label = (stagesMap && stagesMap[id]?.name) || '';

				return {id, label};
			}),
		[stagesMap, stages]
	);
	const text = data.map(i => i.label).join(', ');
	return <div>{text}</div>;
};

export default StagesCell;
