import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IEntityType} from '@tehzor/tools/interfaces/fields';

export type IGetFieldEntityTypesResponse = INormalizedData<IEntityType>;

/**
 * Возвращает список типов сущностей для полей
 */
export const requestGetFieldEntityTypes = async () => {
	const res = await httpRequests.withToken.get<IGetFieldEntityTypesResponse>(
		'field-types/get-entity'
	);

	return res.data;
};
