import {useWarrantyClaimsMutationDefaults} from './useWarrantyClaimsMutationDefaults';
import {useWarrantyClaimsQueryDefaults} from './useWarrantyClaimsQueryDefaults';

/**
 * Хук для передачи дефолтных функций в QueryClient
 */
export function useWarrantyClaimsCacheDefaults() {
	useWarrantyClaimsMutationDefaults();
	useWarrantyClaimsQueryDefaults();
}
