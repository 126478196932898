import { Dispatch, useCallback } from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {useExportTemplatesDestinationAsArray} from '@src/core/hooks/queries/templatesDestination/hooks';

interface ICompanySelectProps<S, E> {
	field: keyof S;
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const DestinationSelect = <S extends {destination: string}, E>({
	className,
	style,
	field,
	label = 'Страница для экспорта',
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: ICompanySelectProps<S, E>) => {
	const {data: destinations} = useExportTemplatesDestinationAsArray();
	const text = destinations?.find(dest => dest.id === value)?.name;
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required, editingDispatch, field]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={text}
						icon={<i className="tz-simple-arrow-20" />}
						error={required && hasError ? 'Выберите страницу' : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{destinations?.map(destination => (
						<SelectOption
							key={destination.id}
							itemKey={destination.id}
							content={`${destination.name}(${destination.id})`}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
