import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useDeleteCheckConfirmDialog} from '../../hooks/useDeleteCheckConfirmDialog';
import {useMovingCheckDialog} from '@src/components/MovingCheckDialog/hooks/useMovingCheckDialog';
import {IPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import {useTranslation} from 'react-i18next';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';

interface IDesktopMenuProps {
	objectId: string;
	check: ILinkedCheck;
	permissions: IPermissions;
}

const arrowIcon = <i className="tz-long-arrow-24" />;
const deleteIcon = <i className="tz-delete" />;

const DesktopMenu = ({objectId, check, permissions}: IDesktopMenuProps) => {
	const {t} = useTranslation();

	const [deleteDialog, handleDeleteClick] = useDeleteCheckConfirmDialog(objectId, check);
	// Диалог переноса проверки
	const [moveDialog, openMoveDialog] = useMovingCheckDialog(check.objectId, check);

	const items = [];
	if (permissions.canMove && permissions.canProblemsMove) {
		items.push(
			<MenuItem
				key="move"
				icon={arrowIcon}
				onClick={openMoveDialog}
			>
				{t('checksPage.actions.menu.move')}
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				{t('checksPage.actions.menu.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
			{permissions.canMove && permissions.canProblemsMove && moveDialog}
		</>
	) : null;
};

export default DesktopMenu;
