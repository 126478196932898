import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {
	IGetProblemCommentsStoriesResponse,
	requestProblemCommentsStories
} from '@src/api/backend/commentsStories';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {IState} from '@src/store/modules';

export type IGetProblemCommentsStoriesPayload = IGetProblemCommentsStoriesResponse;

const request = () => ({type: types.GET_COMMENTS_STORIES_REQUEST});

const success = (response: IGetProblemCommentsStoriesResponse) => ({
	type: types.GET_COMMENTS_STORIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке истории комментариев');
	return {
		type: types.GET_COMMENTS_STORIES_FAILURE,
		payload: error
	};
};

/**
 * Получает записи истории изменения комментариев
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const getProblemCommentsStories = (objectId: string, problemId: string) =>
	checkLoaded<IState, IGetProblemCommentsStoriesResponse, ApiAction>(
		s => s.pages.problem.commentsStories,
		createApiAction<IGetProblemCommentsStoriesResponse>(request, success, failure, () =>
			requestProblemCommentsStories(objectId, problemId))
	);
