import { Dispatch } from 'react';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import Text from '@src/components/editableFields/Text';
import './EditableSpaceTypeDecoration.less';
import {
	IEditableTypeDecorationAction,
	IEditableTypeDecorationSetState
} from '@src/core/hooks/states/useEditableSpaceTypeDecorationState/state';
import CompanySelect from '../editableFields/CompanySelect';
import {ObjectsSelect} from '../editableFields/ObjectsSelect';
import {useUpdateEffect} from 'react-use';

interface IEditableSpaceTypeDecorationProps {
	editingState: IEditableTypeDecorationSetState;
	editingDispatch: Dispatch<IEditableTypeDecorationAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving?: boolean;
}

export const EditableSpaceTypeDecoration = (props: IEditableSpaceTypeDecorationProps) => {
	const {editingState, editingDispatch, fieldsSettings, saving} = props;

		// Сброс типа отделки если изменились поля
		useUpdateEffect(() => {
			editingDispatch({
				type: 'update',
				field: 'objects',
				value: undefined
			});
		}, [editingState.companyId]);

	return (
		<div className="editable-space-type-decoration">
			{fieldsSettings.name !== undefined && (
				<Text
					field="name"
					label="Название"
					value={editingState.name}
					editingDispatch={editingDispatch}
					required={fieldsSettings.name.isRequired}
					disabled={saving}
					hasError={editingState.errors.name}
				/>
			)}

			<CompanySelect
				field="companyId"
				label="Компания"
				value={editingState.companyId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.companyId.isRequired}
				hasError={editingState.errors.companyId}
			/>

			<ObjectsSelect
				field="objects"
				label="Объекты"
				value={editingState.objects}
				companyId={editingState.companyId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.objects.isRequired}
				hasError={editingState.errors.objects}
			/>

		</div>
	);
};
