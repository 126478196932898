import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getInternalAcceptance} from '@src/store/modules/app/links/actions/getInternalAcceptance';
import {LinkButton} from '@tehzor/ui-components';
import { useCallback, useEffect } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formInternalAcceptanceLink} from '@tehzor/tools/utils/links';
import {extractInternalAcceptanceId} from '@src/store/modules/app/links/selectors';

const leftIco = <i className="tz-external-link-16" />;

export const InternalAcceptanceEntityLink = ({
	internalAcceptanceId,
	objectId
}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const internalAcceptance = useAppSelector(state =>
		extractInternalAcceptanceId(state, internalAcceptanceId)
	);

	useEffect(() => {
		void dispatch(getInternalAcceptance(objectId, internalAcceptanceId))
	}, [objectId, internalAcceptanceId])

	const handleClick = useCallback(() => {
		pushPath(formInternalAcceptanceLink(objectId, internalAcceptanceId));
	}, [internalAcceptanceId, objectId]);

	if (!internalAcceptance) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`Внутренняя приёмка №${internalAcceptance.number}`}
			leftIcon={leftIco}
			onClick={handleClick}
			type="filled"
		/>
	);
};
