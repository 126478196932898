import {useReducer} from 'react';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {errorsFns, init} from './state';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export const useEditableSpaceTypeDecorationState = (data: {
	typeDecorationSet?: ISpaceTypeDecorationSet;
	typeDecoration?: ISpaceTypeDecoration[];
}) => useReducer(createReducer(init, errorsFns), data, init);
