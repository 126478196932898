import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useQuery} from '@tanstack/react-query';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {extractOwnerAcceptanceEntities, extractOwnerAcceptanceProblemsArray} from './selectors';

export const useOwnerAcceptancesProblems = <T = INormalizedData<IListProblem>>(
	objectId: string,
	acceptanceId: string,
	select?: (data: INormalizedData<IListProblem>) => T
) =>
	useQuery({
		queryKey: ownerAcceptancesQueryKeys.acceptanceProblems(objectId, acceptanceId),
		select
	});

export const useOwnerAcceptanceProblemsArray = (objectId: string, acceptanceId: string) =>
	useOwnerAcceptancesProblems(objectId, acceptanceId, extractOwnerAcceptanceProblemsArray);

export const useOwnerAcceptanceEntities = (objectId: string, acceptanceId: string) =>
	useOwnerAcceptancesProblems(objectId, acceptanceId, extractOwnerAcceptanceEntities);
