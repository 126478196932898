import { useCallback, useEffect, useState } from 'react';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {IBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {
	useEditableBankAccountState,
	makeDefaultData,
	isEdited
} from '@src/core/hooks/states/useEditableBankAccountState';
import {useUpdateContent} from './useUpdateContent';
import {reset} from '../utils/reset';
import {EditableBankAccount} from '../EditableBankAccount';

interface IUseEditableBankAccountProps {
	bankAccount?: IBankAccount;
	legalEntityId?: string;
	companyId?: string;
}

export const useEditableBankAccount = (props: IUseEditableBankAccountProps) => {
	const {bankAccount, legalEntityId, companyId} = props;

	const defaultData = makeDefaultData(bankAccount);
	const [editingState, editingDispatch] = useEditableBankAccountState(defaultData);

	const {updateBankAccount} = useUpdateContent({
		editingState,
		editingDispatch,
		bankAccount,
		legalEntityId,
		companyId
	});

	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, bankAccount));
	}, [editingState, bankAccount]);

	const fields = (
		<EditableBankAccount editingState={editingState} editingDispatch={editingDispatch} />
	);

	const handleReset = useCallback(
		() => reset(editingDispatch, defaultData),
		[editingDispatch, defaultData]
	);

	const getSavingData = async () => updateBankAccount();

	return {fields, reset: handleReset, getSavingData, isBlocking};
};
