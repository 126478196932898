import {IAddProblemParams} from '@src/api/cache/problems/defaults/useProblemsMutationDefaults';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {useAddProblemMutation} from '@src/api/cache/problems/mutations';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {addProblemActions} from '@src/store/modules/pages/problem/actions';
import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useCallback} from 'react';
import {useLocalProblems} from '../../queries/problems/hooks';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import {toListProblem, toProblem} from './utils/convertToLocalSave';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {usePlans} from '@src/core/hooks/queries/plans/hooks';

export interface ISavingProblemQuery {
	object: IObject;
	links: IProblem['links'];
	stage: ObjectStageIds | undefined;
	fields: ISavingProblem;
	transferStatus: OfflineDataTransferStatus;
	number: number;
	key: string;
}

export const useAddNewProblem = (object?: IObject) => {
	const queryClient = useQueryClient();
	const user = useAppSelector(extractUserProfile);
	const {data: localProblems} = useLocalProblems();
	const nextNumber = useNextLocalNumber<IListProblem>(localProblems, 'localCreatedAt');
	const mutation = useAddProblemMutation();
	const dispatch = useAppDispatch();
	const {data: plans} = usePlans(object?.id);
	const createNewProblemCache = useCallback(
		({objectId, links, stage, fields}: Omit<IAddProblemParams, 'key'>): Promise<IProblem> => {
			const key = Date.now().toString(10);
			if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
				links.checkRecordId = undefined;
			}
			return new Promise(resolve => {
				if (!object) return;
				const converted = toProblem(
					object,
					links,
					stage,
					fields,
					key,
					nextNumber,
					user,
					plans
				);
				const convertedToList = toListProblem(
					object,
					links,
					stage,
					fields,
					key,
					nextNumber,
					user,
					plans
				);
				// Формируем кэш нарушения с данными для сохранения, для удобства
				// в дальнейшем мы используем его при сохранении локальных родительских сущностей

				queryClient.setQueryData<ISavingProblemQuery>(problemsQueryKeys.savingData(key), {
					object,
					links,
					stage,
					fields,
					key,
					transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
					number: nextNumber
				});
				// Складывем новое нарушение в кэш как отдельную сущность, чтоб можно было в неё перейти из списка
				// для локального редактирования и т.п.
				queryClient.setQueryData<IProblem>(
					[...problemsQueryKeys.detail(key), objectId],
					convertedToList
				);
				if (!onlineManager.isOnline()) {
					addWarningToast('Локальное сохранение', 'нарушение добавлено локально');
					void queryClient.refetchQueries({queryKey: problemsQueryKeys.localList()});
					dispatch(addProblemActions.success(converted));
				}
				resolve(converted);
			});
		},
		[nextNumber, object, user, plans]
	);

	const saveProblem = ({objectId, links, stage, fields, key}: IAddProblemParams) => {
		mutation.mutate({
			objectId,
			links,
			stage,
			fields,
			key
		});
	};
	return {createNewProblemCache, saveProblem};
};
