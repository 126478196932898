import classNames from 'classnames';
import {getMonth, isToday} from 'date-fns';
import {IDayData} from '../hooks/useDaysGrid';
import CalendarButton from '../../../buttons/CalendarButton';

export interface IDayProps extends IDayData {
	activeMonth: number;
	activeYear: number;
}

const Day = (props: IDayProps) => {
	const {date, day, activeMonth} = props;

	return (
		<CalendarButton
			key={day}
			className={classNames(
				'calendar__day',
				// eslint-disable-next-line @typescript-eslint/camelcase
				{calendar__day_today: isToday(date)}
			)}
			// active={active}
			inactive={getMonth(date) !== activeMonth}
			// onClick={handleClick}
		>
			{day}
		</CalendarButton>
	);
};

export default Day;
