import classNames from 'classnames';
import {CellProps} from 'react-table';
import {IReportsTableRow} from '../../hooks/useReportsTable';

export const ExpanderCell = ({row}: CellProps<IReportsTableRow>) => (
	<div>
		{row.canExpand ? (
			<span {...row.getToggleRowExpandedProps()}>
				<i
					className={classNames(
						{isExpanded: row.isExpanded},
						"tz-simple-arrow-24"
					)}/>
			</span>
		) : null}
	</div>
);