import {queryClient} from '@src/api/QueryClient';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import getTimezone from '@tehzor/tools/utils/getTimezone';

/**
 * Отправляет запрос на экспорт внутренних приемок
 */
export const makeInternalAcceptancesExportRequest = async (
	templateId: string,
	email: string | undefined,
	objectId?: string,
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	selected?: string[],
	createDocument?: boolean
): Promise<IExportResponse> => {
	const params = {
		templateId,
		objectId,
		filters: {
			...filters,
			selected
		},
		sort: convertSortParam(sort),
		timezone: getTimezone(),
		createDocument,
		email
	};

	const response = await httpRequests.exportWithToken.post<IExportResponse>(
		'internal-acceptances/export',
		params
	);
	await queryClient.invalidateQueries({queryKey: documentsQueryKeys.lists()});
	return response.data;
};
