import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {TFunction} from "i18next";

export const offlineModeTransferTitles: Record<OfflineDataTransferStatus, string> = {
	[OfflineDataTransferStatus.SAVED_LOCAL]: 'Сохранено локально',
	[OfflineDataTransferStatus.TRANSFER]: 'Передача данных...',
	[OfflineDataTransferStatus.TRANSFER_COMPLETE]: 'Передача данных завершена',
	[OfflineDataTransferStatus.TRANSFER_ERROR]: 'Ошибка при передаче данных'
};

export const getOfflineModeTransferTitle = (status: string | undefined, tFunc: TFunction): string => {
	if(!status) return ""
	return tFunc(`offlineDataTransferStatus.${status}`)
}