import {wsConnector} from '@src/api/backend/wsConnector';

export type IDeletePlanResponse = void;

/**
 * Удаляет план
 *
 * @param objectId id объекта
 * @param planId id плана
 */
export const makePlanDeleteRequest = (objectId: string, planId: string) =>
	wsConnector.sendAuthorizedRequest<IDeletePlanResponse>('deletePlan', {
		objectId,
		planId
	});
