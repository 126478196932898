import {useCallback, useState} from 'react';
import {ActionButtons, Button, Dialog, TreeSelect} from '@tehzor/ui-components';
import {makeTreeDataStructure} from '@src/components/editableFields/StructureTreeSelect/utils/makeTreeData';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractStructureTypesAsArray} from '@src/core/hooks/queries/structureTypes/hooks';

interface IStructureSelectDialogProps {
	structures: Array<{
		id: string;
		name: string;
		type: string;
	}>;
	isOpen: boolean;
	value?: string;
	onClose: () => void;
	onSuccess: (selectedStructure: string) => void | Promise<void>;
}

export const StructureSelectDialog = (props: IStructureSelectDialogProps) => {
	const match = useStrictParams<{
		objectId: string;
	}>();

	const {isOpen, structures, value, onClose, onSuccess} = props;
	const [selectedObject, setSelectedObject] = useState<string>(value ?? '');
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const {data: structureTypes} = useExtractStructureTypesAsArray();

	const treeData =
		structureTypes &&
		makeTreeDataStructure(
			structures.map(el => ({...el, objectId: match.objectId || ''})),
			structureTypes
		);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	const handleSuccess = () => {
		if (selectedObject) {
			void onSuccess(selectedObject);
		}
	};

	return (
		<Dialog
			isOpen={isOpen}
			title="Выберите структуру"
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={onClose}
					/>
					<Button
						type="accent-blue"
						label="Выбрать"
						onClick={handleSuccess}
						disabled={!selectedObject}
					/>
				</ActionButtons>
			}
			onRequestClose={onClose}
		>
			{treeData && (
				<TreeSelect
					data={treeData}
					multiple={false}
					value={selectedObject}
					onChange={setSelectedObject}
					expandedValue={expandedObjects}
					onExpand={handleExpand}
				/>
			)}
		</Dialog>
	);
};
