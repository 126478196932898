import {Dialog} from '@tehzor/ui-components';
import {Settings} from './Settings';
import {useTranslation} from 'react-i18next';

interface ISettingsDialogProps {
	isOpen: boolean;

	onClose?: () => void;
}

export const SettingsDialog = ({isOpen, onClose}: ISettingsDialogProps) => {
	const {t} = useTranslation();

	return (
		<Dialog
			className="spaces-page__settings-dialog"
			title={t('spacesPage.mobileActions.settingsDialog.dialog.title')}
			fullScreenOnMobile
			isOpen={isOpen}
			onRequestClose={onClose}
		>
			<Settings />
		</Dialog>
	);
};
