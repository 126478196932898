import {useMemo} from 'react';
import {useSpacesBreadcrumbs} from './useSpacesBreadcrumbs';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {useSpace} from '../queries/space';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';

/**
 * Хук для хлебных крошек от "всех объектов" до помещения
 *
 * @param objectId id конечного объекта
 * @param spaceId id помещения
 */
export function useSpaceBreadcrumbs(objectId: string, spaceId: string) {
	const res = useSpacesBreadcrumbs(objectId);
	const {data: space} = useSpace(spaceId, objectId);
	const spaceTitle = useSpaceTitle(space);

	return useMemo<IBreadcrumb[]>(
		() =>
			res.concat({
				label: spaceTitle,
				url: formSpaceLink(objectId, spaceId),
				inactive: false
			}),
		[objectId, res, spaceTitle]
	);
}
