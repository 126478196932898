import chroma from 'chroma-js';

/**
 * Определяет является ли цвет ярким или светлым
 *
 * @param color цвет
 * @param threshold порог, разделяющий яркие и светлые цвета
 */
export const isBrightColor = (color: string, threshold = 0.7) => {
	const c = chroma(color);
	if (c.alpha() < 1) {
		return (1 - c.luminance()) * c.alpha() > threshold * 0.5;
	}
	return c.luminance() < threshold;
};
