import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IChangeCheckRecordStatusResponse = ICheckRecord[];

/**
 * Изменяет статус записи по категории
 *
 * @param objectId id объекта
 * @param links ссылки на сущности
 * @param stage стадия
 * @param status новый статус записи
 */
export const makeCheckRecordEditStatusRequest = async (
	objectId: string,
	links: ICheckRecord['links'],
	stage: ObjectStageIds,
	status: CheckRecordStatusId
) => {
	const res = await httpRequests.withToken.put<IChangeCheckRecordStatusResponse>(
		'check-records-statuses/edit',
		{
			objectId,
			links,
			stage,
			status
		}
	);
	return res.data;
};
