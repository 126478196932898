import InternalAcceptancePage from './InternalAcceptancePage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

/**
 * Обёртка над страницей внутренней приёмки,
 * необходимо для того чтобы при переходе от одной внутренней приёмки к другой отрабатывали все методы жизненного цикла
 *
 * @param props свойства компонента
 * @constructor
 */
const InternalAcceptancePageWrap = () => {
	const {internalAcceptanceId} = useStrictParams<{internalAcceptanceId: string}>();
	return <InternalAcceptancePage key={internalAcceptanceId}/>;
};

export default InternalAcceptancePageWrap;
