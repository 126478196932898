import EntitiesFilters, {CreatedByFilter, CreationDateFilter, ObjectsFilter} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ISpaceStatusesSetsFiltersState} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/reducers';
import {SpaceStatusesSetsSearch} from './filters/SpaceStatusesSetsSearch';

export const SpaceStatusesSetsFilters = () => {
	const {state} = useEntitiesFiltersCtx<ISpaceStatusesSetsFiltersState>();

	return (
		<div className="space-statuses-sets__filters">
			<SpaceStatusesSetsSearch/>

			<EntitiesFilters
				objectId="all"
				entity="space-statuses-sets"
			>
				<ObjectsFilter value={state.objects}/>

				<CreatedByFilter
					objectId="all"
					createdBy={state.createdBy}
				/>

				<CreationDateFilter
					from={state.createdAtFrom}
					to={state.createdAtTo}
				/>
			</EntitiesFilters>
		</div>
	);
};