import {OfflineDataItem} from '@tehzor/ui-components';
import {useCacheStates} from '../hooks/useCacheStates';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {useTranslation} from "react-i18next";

export const ProblemTemplatesCacheStatus = () => {
	const {t} = useTranslation();
	const [isFetching, isError, isStale, isPaused, dataUpdatedAt] = useCacheStates(
		restDictionariesQueryKeys.problemTemplatesSets()
	);
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t('components.offlineModeDialog.problemTemplatesCacheStatus.offlineDataItem.title')}
			icon={<i className="tz-internal-acceptance-24" />}
			date={dataUpdatedAt}
			error={isError ? t('cacheStatusErrors.error') : isStale ? t('cacheStatusErrors.stale') : undefined}
			inProgress={isPaused || isFetching}
			progressTitle={t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default')}
		/>
	);
};
