import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {CellProps} from 'react-table';
import './CategoryCell.less';
import {useExtractCategoryById} from '@src/core/hooks/queries/categories/hook';

export const CategoryCell = ({row}: CellProps<{data: IProblem | IInspection}>) => {
	const {data: category} = useExtractCategoryById(row.original.data.categoryId);

	if (!category) {
		return null;
	}
	return <div>{category.name}</div>;
};
