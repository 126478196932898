import React, {useCallback, useState} from 'react';
import AddingCheckDialog from '../AddingCheckDialog';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {useTranslation} from 'react-i18next';

interface IAddingCheckDialogProps {
	objectId: string;
	checkId?: string;
	links?: ICheck['links'];
	stage?: ObjectStageIds;
	types?: ICheckAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	onSuccess?: () => void | Promise<void>;
	problemToCopyId?: string;
	createdBy?: string;
	title?: string;
}

export function useAddingCheckDialog({
	objectId,
	checkId,
	links,
	stage,
	types,
	defaultProblemData,
	defaultInspectionData,
	onSuccess,
	problemToCopyId,
	createdBy,
	title
}: IAddingCheckDialogProps): [React.ReactNode, () => void] {
	const {t} = useTranslation();
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = stage ? (
		<AddingCheckDialog
			title={title ?? t('useAddingCheckDialog.title')}
			objectId={objectId}
			checkId={checkId}
			links={links}
			types={types}
			defaultProblemData={defaultProblemData}
			defaultInspectionData={defaultInspectionData}
			isOpen={isOpen}
			onClose={close}
			onSuccess={onSuccess}
			problemToCopyId={problemToCopyId}
			createdBy={createdBy}
		/>
	) : null;

	return [dialog, open];
}
