import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import {useEditableRespUserState} from '@src/core/hooks/states/useEditableRespUserState/hook';
import {
	convertRespUserToSave,
	errorsFns,
	isEdited
} from '@src/core/hooks/states/useEditableRespUserState/state';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {EditableRespUser} from '../EditableRespUser';
import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';

const fieldsSettings = {
	fullName: {fieldId: 'fullName', isRequired: true},
	email: {fieldId: 'email', isRequired: true},
	// area: {fieldId: 'area', isRequired: false},
	// location: {fieldId: 'location', isRequired: false},
	// stage: {fieldId: 'stageId', isRequired: false},
	categories: {fieldId: 'categories', isRequired: true}
	// planId: {fieldId: 'planId', isRequired: false}
};

interface IHookArgs {
	defaultData: IAddingRespUser | undefined;
	saving?: boolean;
	objectId: string;
}

export const useEditableRespUser = ({
	defaultData,
	saving,
	objectId
}: IHookArgs): [React.ReactNode, () => IAddingRespUser | undefined, () => void, boolean] => {
	const [editingState, editingDispatch] = useEditableRespUserState(defaultData);
	const [isBlocking, setIsBlocking] = useState(false);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, defaultData));
	}, [editingState, defaultData]);

	const getSavingData = useCallback(() => {
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}

		if (!isEdited(editingState, defaultData)) {
			return undefined;
		}

		return convertRespUserToSave(editingState, defaultData, true);
	}, [editingState, defaultData]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: defaultData
		});
	}, [defaultData]);

	const respUserFields = (
		<EditableRespUser
			objectId={objectId}
			editingState={editingState}
			editingDispatch={editingDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving}
		/>
	);

	return [respUserFields, getSavingData, reset, isBlocking];
};
