import { Dispatch, useCallback } from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {CheckListTypeId, ICheckListType} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {
	useCheckListTypesAsArray,
	useCheckListTypesMap
} from '@src/core/hooks/queries/checkListTypes/hooks';

const arrowIcon = <i className="tz-simple-arrow-20" />;

interface ICheckListTypeSelectProps<S, E> {
	field?: keyof S;
	value?: CheckListTypeId;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: CheckListTypeId | undefined,
	types: ICheckListType[],
	typesMap: Record<string, ICheckListType>
) => {
	if (!value) {
		return undefined;
	}

	if (Array.isArray(value)) {
		return value.length ? makeFilterLabel('Выбрано', value, types) : undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

const CheckListTypeSelect = <
	S extends {
		type?: CheckListTypeId;
		spaceTypes?: SpaceTypeId[];
	},
	E
>({
	className,
	style,
	field = 'type',
	label = 'Тип чеклиста',
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: ICheckListTypeSelectProps<S, E>) => {
	const {data: types} = useCheckListTypesAsArray();
	const {data: typesMap} = useCheckListTypesMap();
	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});
			if (v === CheckListTypeId.OBJECTS) {
				editingDispatch({type: 'update', field: 'spaceTypes', value: null});
			}
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	const isError = required && hasError ? 'Выберите тип чеклиста' : undefined;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			{types && typesMap && (
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={getInputLabel(value, types, typesMap)}
							icon={arrowIcon}
							error={isError}
							disabled={disabled}
						/>
					}
				>
					<Select2
						value={value}
						onChange={handleChange}
					>
						{types.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			)}
		</div>
	);
};

export default CheckListTypeSelect;
