import {Attributes, createElement} from 'react';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {isImage} from '@tehzor/tools/utils/mimeTypes';
import {UploadingFile, UploadingImage} from '../../../files';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import {IUploadingImageProps} from '../../../files/UploadingImage/UploadingImage';
import {IUploadingFileProps} from '../../../files/UploadingFile/UploadingFile';
import classNames from 'classnames';

export const splitSavedAttachments = (
	items?: IAttachment[],
	disabled?: boolean,
	imageClassName?: string,
	fileClassName?: string,
	onDelete?: (id: string) => void,
	onImageClick?: (id: string) => void
) => {
	const images = [];
	const files = [];
	if (items) {
		for (const item of items) {
			if (item.full) {
				const props: Attributes & (IUploadingImageProps<string> | IUploadingFileProps<string>) = {
					key: item.id,
					name: item.full.name,
					url: item.full.url,
					type: item.full.type,
					size: item.full.size,
					progress: 100,
					status: UploadingFileStatus.FINISHED,
					disabled,
					data: item.id,
					onDelete
				};

				if (isImage(item.full.type)) {
					props.className = classNames('editable-attachments__image', imageClassName);
					props.url = item.preview?.url ?? props.url;
					(props as IUploadingImageProps<string>).onClick = onImageClick;
					images.push(createElement(UploadingImage, props));
				} else {
					props.className = classNames('editable-attachments__file', fileClassName);
					files.push(createElement(UploadingFile, props));
				}
			}
		}
	}
	return [images, files];
};