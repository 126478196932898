import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISavingSpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecoration';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IEditSpaceTypeDecorationResponse {
	created: ISpaceTypeDecoration[];
	edited: ISpaceTypeDecoration[];
	deleted: ISpaceTypeDecoration[];
}

/**
 * Массово обновляет типы отделки в наборе.
 * В метод должен передаваться массив типов отделки после редактирования.
 * Все не переданные в массиве типы отделки считаются удаленными.
 *
 * @param typeDecorationSetId id набора типов отделки помещения
 * @param items массив типов отделки для добавления
 */
export const makeSpaceTypeDecorationEditRequest = async (
	typeDecorationSetId: string,
	items: ISavingSpaceTypeDecoration[]
) => {
	const response = await httpRequests.withToken.post<IEditSpaceTypeDecorationResponse>(
		`space-type-decoration/edit`,
		{typeDecorationSetId, items}
	);
	return response.data;
};
