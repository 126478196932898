import {LanguageSelect} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {languages} from '@src/core/i18n';

export const AppLanguageSelect = () => {
	const {i18n} = useTranslation();

	const changeLanguage = async (lng: string) => {
		await i18n.changeLanguage(lng);
	};

	return (
		<LanguageSelect
			languages={languages}
			value={i18n.language}
			onChange={changeLanguage}
		/>
	);
};
