import {useMemo} from 'react';
import SpaceInfoMobileCell from '@src/components/tableCells/SpaceInfoMobileCell';
import {Column} from 'react-table';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';

export const useMobileColumns = () =>
	useMemo<Array<Column<IConvertedSpace>>>(
		() => [
			{
				id: 'info',
				Cell: SpaceInfoMobileCell,
				className: 'spaces-page__mobile-cell'
			}
		],
		[]
	);
