import {IGetTasksResponse} from '@src/api/backend/tasks';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery} from '@tanstack/react-query';
import {extractTasksAsArray} from './selectors';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';

export const useSpaceTasks = <T = IGetTasksResponse>(
	spaceId: string,
	permission?: boolean,
	select?: (data: IGetTasksResponse) => T
) => {
	const {canView} = useTasksPermissions();

	return useQuery({
		queryKey: spacesQueryKeys.spaceTasks(spaceId),
		meta: {
			error: 'при загрузке задач'
		},
		select,
		enabled: permission || canView
	});
};

export const useSpaceTasksAsArray = (spaceId: string, permission?: boolean) =>
	useSpaceTasks(spaceId, permission, extractTasksAsArray);
