import {IGetCategoriesResponse} from '@src/api/backend/categories';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';

export const extractCategoriesAsArray = (data: IGetCategoriesResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractCategoriesAsMap = (data: IGetCategoriesResponse) => data.byId;

/**
 * Извлекает отсортированные виды работ по id наборов
 */
export const extractAllCategories = (data: IGetCategoriesResponse) => {
	const allCategories: {[setId: string]: IGetCategoriesResponse} = {};
	data.allIds?.forEach(categoryId => {
		const category = data.byId[categoryId];
		if (!allCategories[category.categoriesSetId]?.allIds.length) {
			allCategories[category.categoriesSetId] = {
				allIds: [],
				byId: {}
			};
		}

		allCategories[category.categoriesSetId].allIds.push(category.id);
		allCategories[category.categoriesSetId].byId[category.id] = category;
	});

	return allCategories;
};

/**
 * Извлекает отсортированные виды работ по id наборов в виде массив
 */
export const extractAllCategoriesAsArray = (data: IGetCategoriesResponse) => {
	const categories = extractCategoriesAsArray(data);
	const allCategories: {[setId: string]: ICategory[]} = {};

	categories?.forEach(category => {
		if (!allCategories[category.categoriesSetId]?.length) {
			allCategories[category.categoriesSetId] = [];
		}

		allCategories[category.categoriesSetId].push(category);
	});

	return allCategories;
};

/**
 * Извлекает виды работ для конкретного набора
 */
export const extractCategoriesBySetId = (
	data: IGetCategoriesResponse,
	categoriesSetId?: string
) => {
	const allCategories = extractAllCategories(data);
	let allIds: string[] = [];
	let byId: {[id: string]: ICategory} = {};
	if (!categoriesSetId) {
		return undefined;
	}
	if (allCategories[categoriesSetId]) {
		allIds = allCategories[categoriesSetId].allIds || [];
		byId = allCategories[categoriesSetId].byId || [];
	}

	const total = allIds.length;

	return {byId, allIds, total};
};

/**
 * Извлекает виды работ для конкретного набора в виде массива
 */
export const extractCategoriesAsArrayBySetId = (
	data: IGetCategoriesResponse,
	categoriesSetId?: string
) => {
	const categories = extractCategoriesBySetId(data, categoriesSetId);
	return categories?.allIds?.map((id: string) => categories.byId[id]);
};

/**
 * Извлекает вид работ по Id
 */
export const extractCategoryById = (data: IGetCategoriesResponse, categoryId?: string) => {
	if (!categoryId) {
		return undefined;
	}
	return data?.byId[categoryId];
};
