import {httpRequests} from '@tehzor/tools/api/httpRequests';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';

export type IAddCategoryResponse = ICategory;

/**
 * Добавляет вид работ
 *
 * @param category данные вида работ
 */
export const requestAddCategories = async (category: ISavingCategory) => {
	const response = await httpRequests.withToken.post<IAddCategoryResponse>('categories/add', category);

	return response.data;
};
