import { Dispatch } from 'react';
import './EditableSpaceOwner.less';
import Text from '../editableFields/Text';
import {
	IEditableSpaceOwnerAction,
	IEditableSpaceOwnerState
} from '@src/core/hooks/states/useEditableSpaceOwnerState';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {useTranslation} from 'react-i18next';

interface IEditableOwnerProps {
	editingState: IEditableSpaceOwnerState;
	editingDispatch: Dispatch<IEditableSpaceOwnerAction>;
	saving: boolean;
	fieldsSettings: Record<string, IObjectFieldSetting>;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableSpaceOwner = (props: IEditableOwnerProps) => {
	const {editingState, editingDispatch, saving, fieldsSettings} = props;
	const {t} = useTranslation();

	return (
		<div className="editable-space-owner">
			{fieldsSettings.name !== undefined && (
				<Text
					field="name"
					label={t('spacePage.info.spaceOwnersDialog.fullNameField.label')}
					value={editingState.name}
					editingDispatch={editingDispatch}
					disabled={saving}
					required={fieldsSettings.name.isRequired}
					hasError={editingState.errors.name}
					className="editable-space-owner__field"
				/>
			)}
			<div className="editable-space-owner__phone-wrapper">
				{fieldsSettings.phone !== undefined && (
					<Text
						field="phone"
						label={t('spacePage.info.spaceOwnersDialog.phoneNumberField.label')}
						value={editingState.phone}
						editingDispatch={editingDispatch}
						disabled={saving}
						required={fieldsSettings.phone.isRequired}
						hasError={editingState.errors.phone}
						className="editable-space-owner__field"
						errorMessage={t('spacePage.info.spaceOwnersDialog.phoneNumberField.error')}
					/>
				)}
				{fieldsSettings.additionalPhone !== undefined && (
					<Text
						field="additionalPhone"
						label={t('spacePage.info.spaceOwnersDialog.secondPhoneNumberField.label')}
						value={editingState.additionalPhone}
						editingDispatch={editingDispatch}
						disabled={saving}
						required={fieldsSettings.additionalPhone.isRequired}
						hasError={editingState.errors.additionalPhone}
						className="editable-space-owner__field"
						errorMessage={t(
							'spacePage.info.spaceOwnersDialog.secondPhoneNumberField.error'
						)}
					/>
				)}
			</div>
			{fieldsSettings.email !== undefined && (
				<Text
					field="email"
					label={t('spacePage.info.spaceOwnersDialog.emailField.label')}
					value={editingState.email}
					editingDispatch={editingDispatch}
					disabled={saving}
					required={fieldsSettings.email.isRequired}
					hasError={editingState.errors.email}
					className="editable-space-owner__field"
					errorMessage={t('spacePage.info.spaceOwnersDialog.emailField.error')}
				/>
			)}
			{fieldsSettings.additionalEmail !== undefined && (
				<Text
					field="additionalEmail"
					label={t('spacePage.info.spaceOwnersDialog.secondEmailField.label')}
					value={editingState.additionalEmail}
					editingDispatch={editingDispatch}
					disabled={saving}
					required={fieldsSettings.additionalEmail.isRequired}
					hasError={editingState.errors.additionalEmail}
					className="editable-space-owner__field"
					errorMessage={t('spacePage.info.spaceOwnersDialog.secondEmailField.error')}
				/>
			)}
			{fieldsSettings.comment !== undefined && (
				<Text
					field="comment"
					label={t('spacePage.info.spaceOwnersDialog.commentField.label')}
					value={editingState.comment}
					editingDispatch={editingDispatch}
					disabled={saving}
					required={fieldsSettings.comment.isRequired}
					hasError={editingState.errors.comment}
					className="editable-space-owner__field"
					errorMessage={t('spacePage.info.spaceOwnersDialog.commentField.error')}
				/>
			)}
		</div>
	);
};

export default EditableSpaceOwner;
