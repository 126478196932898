import {IWorkAcceptanceFrontType} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetWorkAcceptanceFrontTypesResponse = INormalizedData<IWorkAcceptanceFrontType>;

/**
 * Возвращает список типов фронта приёмок работ
 */
export const requestWorkAcceptanceFrontTypes = async () => {
	const response = await httpRequests.withToken.get<IGetWorkAcceptanceFrontTypesResponse>(
		'work-acceptance-front-types'
	);
	return response.data;
};
