import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

interface ISpaceTypeDecorationPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
}

export const useSpaceTypeDecorationPermissions = (): ISpaceTypeDecorationPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		return {
			canView: hasPermission(
				user.roles,
				'space-type-decoration-sets-view',
				UserRoleScopes.ALL
			),
			canAdd: hasPermission(user.roles, 'space-type-decoration-sets-add', UserRoleScopes.ALL),
			canEdit: hasPermission(
				user.roles,
				'space-type-decoration-sets-edit',
				UserRoleScopes.ALL
			),
			canDelete: hasPermission(
				user.roles,
				'space-type-decoration-sets-delete',
				UserRoleScopes.ALL
			)
		};
	}, [user]);
};
