import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import declination from '@tehzor/tools/utils/declination';
import { useMemo } from 'react';
import {CellProps} from 'react-table';
import {useTranslation} from "react-i18next";

const NameCell = ({row}: CellProps<IFullCategoriesSet>) => {
	const {t} = useTranslation();
	const total = useMemo(() => row.original.categories?.length ?? 0, [row.original.categories]);
	const amountOfCategories = useMemo(() => `${total} ${declination(total, t('categoriesSetsPage.table.cells.nameCell.declination', {returnObjects: true}))}`, [total]);

	const name = row.original.name || 'Имя набора не указано';

	return (
		<div className="categories-sets__cell-name">
			<p className="categories-sets__cell-name__header">{name}</p>
			<p className="categories-sets__cell-name__subtext">{amountOfCategories}</p>
		</div>
	);
};

export default NameCell;
