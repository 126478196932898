import {useMemo} from 'react';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';

/**
 * Извлекает из вложений ссылки на изображения
 *
 * @param uploads вложения загруженные в temp
 */
export const useUploadsImages = (uploads: IUploadingFile[]) =>
	useMemo(
		() =>
			uploads.reduce<string[]>((prev, item) => {
				if (item) {
					prev.push(item.url);
				}
				return prev;
			}, []),
		[uploads]
	);
