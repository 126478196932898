import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '../StatusesFilterPage';
import {useExtractWorkAcceptanceStatusesAsArray} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

export const WorkAcceptanceStatusesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity?: string}>();

	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatusesAsArray();
	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.workAcceptanceStatuses,
		workAcceptanceStatuses
	);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={translatedOptions}
			label={t('entitiesFilters.statusesFilter.workAcceptanceStatuses.label')}
			filterName="statuses"
		/>
	);
};
