import { useCallback } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';
import {useOwnerAcceptanceCombinedExport} from '../../hooks/useOwnerAcceptanceCombinedExport';

interface IExportSelectedProps {
	objectId: string;
	ownerAcceptanceId: string;
	selectedEntities: IOwnerAcceptanceEntity[];
}

const SelectedEntitiesExport = ({
	objectId,
	ownerAcceptanceId,
	selectedEntities
}: IExportSelectedProps) => {
	const [exportDialog, openExportDialog] = useOwnerAcceptanceCombinedExport(
		objectId,
		selectedEntities
	);

	const handleClick = useCallback(() => {
		openExportDialog(ownerAcceptanceId);
	}, [ownerAcceptanceId, openExportDialog]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-export-24"/>
			</IconButton>

			{exportDialog}
		</>
	);
};

export default SelectedEntitiesExport;
