import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {createMutation} from 'react-query-kit';
import {
	IAddInspectionParams,
	IEditInspectionParams
} from '../defaults/useInspectionsMutationDefaults';
import {inspectionsQueryKeys} from '../keys';

export const useAddInspectionMutation = createMutation<IInspection, IAddInspectionParams>({
	mutationKey: inspectionsQueryKeys.add()
});

export const useEditInspectionMutation = createMutation<IInspection, IEditInspectionParams>({
	mutationKey: inspectionsQueryKeys.edit()
});
