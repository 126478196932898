import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

export interface ICheckItemsPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canSoftDelete?: boolean;
	canHardDelete?: boolean;
	canRestore?: boolean;
}

export const useCheckItemsPermissions = (): ICheckItemsPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		return {
			canView: hasPermission(user.roles, 'check-items-view'),
			canAdd: hasPermission(user.roles, 'check-items-add'),
			canEdit: hasPermission(user.roles, 'check-items-edit'),
			canSoftDelete: hasPermission(user.roles, 'check-items-soft-delete'),
			canHardDelete: hasPermission(user.roles, 'check-items-hard-delete'),
			canRestore: hasPermission(user.roles, 'check-items-restore')
		};
	}, [user]);
};
