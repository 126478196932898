import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {useObjectsSpacesFilteredByType} from '@src/core/hooks/queries/spaces/typesCount/hooks';

/**
 * Возвращает id объектов, которые должны отображаться на шахматке,
 * исходя из фильтра по объектам и наличия в них помещений выбранного типа
 *
 * @param pageObjectId id объекта, на странице которого находится пользователь
 */
export function useVisibleObjects(pageObjectId: string, targetObjects: string[]) {
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, pageObjectId));
	const {data: objectsByType} = useObjectsSpacesFilteredByType(targetObjects, pageSettings.type);
	return useMemo(() => {
		const visibleObjects = pageSettings.filters.objects?.length
			? targetObjects?.filter(id => pageSettings.filters.objects?.includes(id)) || []
			: targetObjects || [];
		if (!objectsByType) return [];
		return visibleObjects?.filter(objId => objectsByType.includes(objId)) || [];
	}, [pageSettings.filters.objects, objectsByType, targetObjects]);
}
