import { useCallback, useMemo } from 'react';
import {
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	InlineButton,
	Plate,
	StatusSelect
} from '@tehzor/ui-components';
import {useAsyncFn} from 'react-use';
import Documents from './Documents';
import {ExpandableMobileInfo} from '@src/components/ExpandableMobileInfo';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {useEditableOwnerAcceptanceAttachmentsDialog} from '@src/components/EditableOwnerAcceptanceAttachmentsDialog/hooks/useEditableOwnerAcceptanceAttachmentsDialog';
import SpaceOwners from './SpaceOwners';
import Resolution from './Resolution';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useEditOwnerAcceptanceStatus} from '@src/core/hooks/mutations/ownerAcceptances/useEditOwnerAcceptanceStatus';
import {useOwnerAcceptancesResolutionsArray} from '@src/core/hooks/queries/ownerAcceptancesResolutions/hooks';
import {useOwnerAcceptancesStatuses} from '@src/core/hooks/queries/ownerAcceptancesStatuses/hooks';
import {useTranslation} from "react-i18next";
import {useTranslatedDictionary} from "@src/core/hooks/translations/useTranslatedDictionary";
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";
import {useTranslatedDictionaryArray} from "@src/core/hooks/translations/useTranslatedDictionaryArray";

interface IMobileInfoProps {
	objectId: string;
	ownerAcceptance: ILinkedOwnerAcceptance;
}

const spaceIcon = <i className="tz-space-24" />;
const calendarIcon = <i className="tz-calendar-20" />;
const editIcon = <i className="tz-edit-16" />;
const statusIcon = <i className="tz-status-20" />;
const documentIcon = <i className="tz-document-20" />;

const MobileInfo = ({objectId, ownerAcceptance}: IMobileInfoProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const spaceTitle = useSpaceTitle(ownerAcceptance?.space);
	const {data: statuses} = useOwnerAcceptancesStatuses();
	const translatedStatuses = useTranslatedDictionary(dictionaryKeys.ownerAcceptanceStatuses, statuses)
	const {data: resolutions} = useOwnerAcceptancesResolutionsArray();
	const translatedResolutions = useTranslatedDictionaryArray(dictionaryKeys.ownerAcceptanceResolutions, resolutions)
	const permissions = useOwnerAcceptancesPermissions(objectId);

	const isStatusHidden = useMemo(
		() => ownerAcceptance.resolution === OwnerAcceptanceResolutionId.ACCEPTED,
		[ownerAcceptance.resolution]
	);

	const [editingAttachmentsDialog, openEditAttachmentsDialog] =
		useEditableOwnerAcceptanceAttachmentsDialog(objectId, ownerAcceptance.id, ownerAcceptance);

	const editOwnerAcceptanceStatus = useEditOwnerAcceptanceStatus();

	const [, handleChange] = useAsyncFn(
		async (v: OwnerAcceptanceStatusId) => {
			if (ownerAcceptance) {
				await editOwnerAcceptanceStatus({
					objectId,
					acceptanceId: ownerAcceptance.id,
					statusId: v
				});
			}
		},
		[objectId, ownerAcceptance]
	);

	const handleClick = useCallback(() => {
		if (ownerAcceptance) {
			if (ownerAcceptance.objectId && ownerAcceptance.links?.spaceId) {
				pushPath(
					`/objects/${ownerAcceptance.objectId}/spaces/${ownerAcceptance.links.spaceId}`
				);
			}
		}
	}, [ownerAcceptance]);

	return (
		<Plate>
			<EntityGrid withBorders>
				{!isStatusHidden && (
					<EntityGridItem
						icon={statusIcon}
						label={t('ownerAcceptancePage.info.status.label')}
						inline
						fullRow
					>
						<StatusSelect
							className="owner-acceptance-page__d-entities-status"
							statuses={translatedStatuses}
							disabled={!permissions.canEditStatus}
							value={ownerAcceptance?.status}
							onChange={handleChange}
						/>
					</EntityGridItem>
				)}

				{ownerAcceptance?.resolution ? (
					<Resolution
						objectId={objectId}
						ownerAcceptanceId={ownerAcceptance.id}
						value={ownerAcceptance.resolution}
						resolutions={translatedResolutions}
						canEditResolution={permissions.canEdit}
					/>
				) : null}
				{ownerAcceptance?.spaceOwner ? (
					<SpaceOwners
						name={ownerAcceptance.spaceOwner.name}
						phone={ownerAcceptance?.spaceOwner.phone}
						email={ownerAcceptance?.spaceOwner.email}
					/>
				) : null}

				<EntityGridItem
					label={t('ownerAcceptancePage.info.formalNote.label')}
					icon={documentIcon}
					fullRow
					buttons={
						<InlineButton
							className="owner-acceptance-page__documents-edit-icon"
							type="accent"
							leftIcon={editIcon}
							onClick={openEditAttachmentsDialog}
						/>
					}
				>
					<Documents ownerAcceptance={ownerAcceptance} />
				</EntityGridItem>
			</EntityGrid>

			<ExpandableMobileInfo noBorder>
				<EntityGrid withBorders>
					<EditorInfo
						icon={calendarIcon}
						label={t('ownerAcceptancePage.info.created.label')}
						date={ownerAcceptance?.createdAt}
						user={ownerAcceptance?.createdBy}
						fullRow
					/>

					{ownerAcceptance?.createdAt !== ownerAcceptance?.modifiedAt && (
						<EditorInfo
							icon={calendarIcon}
							label={t('ownerAcceptancePage.info.updated.label')}
							date={ownerAcceptance?.modifiedAt}
							user={ownerAcceptance?.modifiedBy}
							fullRow
						/>
					)}
					<EntityGridItem className={{content: 'owner-acceptance-page__space-btn'}}>
						<InlineButton
							label={spaceTitle}
							type="accent"
							onClick={handleClick}
							leftIcon={spaceIcon}
						/>
					</EntityGridItem>
				</EntityGrid>
			</ExpandableMobileInfo>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default MobileInfo;
