import {queryClient} from '@src/api/QueryClient';
import {
	IGetMeterConsumptionsResponse,
	makeMeterConsumptionAddRequest
} from '@src/api/backend/meterConsumptions';
import {meterConsumptionsQueryKeys} from '@src/api/cache/meterConsumptions/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';
import {ISavingMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/ISavingMeterConsumption';
import {useCallback} from 'react';

export const useAddMeterConsumption = () => {
	const mutation = useMutation<IMeterConsumption, IError, ISavingMeterConsumption>({
		mutationFn: (params: ISavingMeterConsumption) => makeMeterConsumptionAddRequest(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetMeterConsumptionsResponse>(
				meterConsumptionsQueryKeys.list(data.meterId),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return {
						byId: {[data.id]: data},
						allIds: [data.id]
					};
				}
			);
			addSuccessToast('Успех', 'Показание прибора учёта добавлено');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении показания прибора учёта');
		}
	});
	/**
	 * Возвращает функцию для создания показания прибора учёта
	 *
	 * @param item данные нового показания прибора учёта
	 */
	return useCallback(
		(item: ISavingMeterConsumption) => {
			mutation.mutate(item);
		},
		[mutation]
	);
};
