import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddCheckItemResponse = ICheckItem;

/**
 * Добавляет категорию
 *
 * @param item данные для добавления
 */
export const makeCheckItemAddRequest = async (item: ISavingCheckItem) => {
	const {checkListId, name, description, dependencies, parentId, order} = item;
	const response = await httpRequests.withToken.post<IAddCheckItemResponse>('/check-items/add', {
		checkListId,
		name,
		description,
		dependencies,
		parentId,
		order
	});
	return response.data;
};
