import { useCallback } from 'react';
import {EntityInnerTable} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractStructureEntities} from '@src/store/modules/entities/structure/selectors';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {SelectionRow} from './SelectionRow';

interface IDesktopTableProps {
	objectId: string;
	columns: Array<Column<IStructureEntity>>;
	hideHead?: boolean;
	structureId: string;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

export const Table = ({
	objectId,
	columns,
	hideHead,
	selectedRows,
	onSelectedRowsChange
}: IDesktopTableProps) => {
	const {pushPath} = useChangePath();
	const entities = useAppSelector(extractStructureEntities);

	const handleRowClick = useCallback(
		(entity: IStructureEntity) => {
			switch (entity.type) {
				case 'problem':
					return pushPath(formProblemLink(objectId, entity.data.id));
				case 'inspection':
					return pushPath(formInspectionLink(objectId, entity.data.id));
				default:
					return undefined;
			}
		},
		[objectId]
	);

	return (
		<EntityInnerTable
			columns={columns}
			data={entities}
			hideHead={hideHead}
			selectable
			onRowClick={handleRowClick}
			selectedRows={selectedRows}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={props => (
				<SelectionRow
					{...props}
					objectId={objectId}
				/>
			)}
		/>
	);
};
