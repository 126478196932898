import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IDefaultSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/IDefaultSpaceStatus';
import {ISavingSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISavingSpaceStatus';
import {ISpaceStatus, ISpaceStatusNotificationMessage} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';

export interface IEditableSpaceStatusesSetOriginal {
	spaceStatusesSet: ISavingSpaceStatusesSet;
	statuses: ISavingSpaceStatus[];
}

export interface IEditableStatus {
	id: string;
	spaceStatusesSetId: string;
	stage: ObjectStageIds;
	name?: string;
	transitions?: string[] | null;
	autoTransitionTo?: string | null;
	color: string;
	default?: boolean;
	unavailableWithUnfixedProblems?: boolean;
	unavailableWithUnacceptedCheckItem?: boolean;
	isLocal?: boolean;
	order: number;
	notificationMessage?: ISpaceStatusNotificationMessage
}

export type IEditableSpaceStatusesSetState = IEditableEntityState<{
	name: string;
	companyId?: string | null;
	objects?: string[] | null;
	statuses: IEditableStatus[];
	default?: boolean;
	defaultStatus?: IDefaultSpaceStatus;
	isLocal?: boolean;
}>;

export type IEditableSpaceStatusesSetAction = IEditableEntityAction<
	IEditableSpaceStatusesSetState,
	IEditableSpaceStatusesSetOriginal
>;

export const makeEmptyState = (): IEditableSpaceStatusesSetState => ({
	name: '',
	companyId: undefined,
	objects: [],
	statuses: [],
	default: false,
	isLocal: true,
	errors: {
		name: false,
		companyId: false,
		objects: false,
		statuses: false,
		default: false
	}
});

export const makeDefaultData = (
	spaceStatusesSet?: ISpaceStatusesSet,
	statuses: ISpaceStatus[] = []
): IEditableSpaceStatusesSetOriginal | undefined => {
	if (!spaceStatusesSet) {
		return undefined;
	}
	return {spaceStatusesSet, statuses};
};

export const init = (
	original?: IEditableSpaceStatusesSetOriginal
): IEditableSpaceStatusesSetState => {
	const empty = makeEmptyState();
	if (!original) {
		return empty;
	}

	const {spaceStatusesSet, statuses} = original;
	const state: IEditableSpaceStatusesSetState = {
		name: spaceStatusesSet.name ?? '',
		companyId: spaceStatusesSet.companyId ?? '',
		objects: spaceStatusesSet.objects ?? [],
		default: spaceStatusesSet.default ?? false,
		statuses: statuses ?? [],
		errors: empty.errors
	};

	return state;
};

const isNameEdited = (
	state: IEditableSpaceStatusesSetState,
	original?: IEditableSpaceStatusesSetOriginal
): boolean =>
	original?.spaceStatusesSet.name ? original?.spaceStatusesSet.name !== state.name : !!state.name;

export const isCompanyEdited = (
	state: IEditableSpaceStatusesSetState,
	original?: IEditableSpaceStatusesSetOriginal
): boolean =>
	original?.spaceStatusesSet.companyId
		? original?.spaceStatusesSet.companyId !== state.companyId
		: !!state.companyId;

export const isObjectsEdited = (
	state: IEditableSpaceStatusesSetState,
	original?: IEditableSpaceStatusesSetOriginal
): boolean => {
	if (!original) {
		return !!state.objects?.length;
	}

	const {spaceStatusesSet} = original;

	const isLengthEqual = spaceStatusesSet.objects?.length !== state.objects?.length;
	const isObjectsEqual = !!spaceStatusesSet.objects?.some(id => !state.objects?.includes(id));

	return isLengthEqual || isObjectsEqual;
};

const isDefaultStatusEdited = (
	state: IEditableSpaceStatusesSetState,
	original?: IEditableSpaceStatusesSetOriginal
) => (!original ? !!state.defaultStatus : false);

const isStatusesEdited = (
	state: IEditableSpaceStatusesSetState,
	original?: IEditableSpaceStatusesSetOriginal
): boolean => {
	if (!original) {
		return !!state.statuses?.length;
	}

	const {statuses} = original;

	const isLengthEqual = statuses.length !== state.statuses.length;
	const isStatusesEqual = !!statuses.some(status =>
		state.statuses.find(item => item.name === status.name)
	);

	return isLengthEqual || isStatusesEqual;
};

export const isEdited = (
	state: IEditableSpaceStatusesSetState,
	original?: IEditableSpaceStatusesSetOriginal
): boolean =>
	isEntityEdited(
		state,
		original,
		isNameEdited,
		isCompanyEdited,
		isObjectsEdited,
		isDefaultStatusEdited,
		isStatusesEdited
	);

export const errorFns = {
	name: (state: IEditableSpaceStatusesSetState) => !state.name,
	statuses: (state: IEditableSpaceStatusesSetState) => !state.statuses?.length,
	companyId: (state: IEditableSpaceStatusesSetState) =>
		!state.objects?.length && !state.companyId,
	objects: (state: IEditableSpaceStatusesSetState) => !state.objects?.length && !state.companyId,
	defaultStatus: (state: IEditableSpaceStatusesSetState) => !state.defaultStatus
};

export const convertToSave = (
	state: IEditableSpaceStatusesSetState,
	original?: IEditableSpaceStatusesSetOriginal
): [ISavingSpaceStatusesSet, ISavingSpaceStatus[]] => {
	const spaceStatusesSet: ISavingSpaceStatusesSet = {};
	const statuses: ISavingSpaceStatus[] = [];

	if (isNameEdited(state, original)) {
		spaceStatusesSet.name = state.name;
	}
	if (isCompanyEdited(state, original)) {
		spaceStatusesSet.companyId = state.companyId;
	}
	if (isObjectsEdited(state, original)) {
		spaceStatusesSet.objects = state.objects;
	}
	if (isStatusesEdited(state, original)) {
		statuses.push(...state.statuses);
	}
	if (isDefaultStatusEdited(state, original)) {
		spaceStatusesSet.defaultStatus = state.defaultStatus;
	}
	return [spaceStatusesSet, statuses];
};
