import {useLegalsMap} from '@src/core/hooks/queries/legals';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {
	ActionButtons,
	Button,
	Dialog,
	EditableFieldLabel,
	Select2 as Select,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import SelectSearch, {
	flatFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import classNames from 'classnames';
import {Dispatch, ReactNode, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';

interface ISingleSelectContractFieldProps<S, E> {
	field: keyof S;
	fieldName?: string;
	className?: string;
	label?: string;
	legalEntities: ILegalEntity[];
	dialogTitle?: string;
	errorMessage?: string;
	hasError?: boolean;
	required?: boolean;
	isMobile?: boolean;
	disabled?: boolean;
	value?: string | null;

	icon?: ReactNode;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	onError: () => void;
}

const getTitle = (
	fieldName: string,
	value?: string | null,
	itemsMap?: Record<string, ILegalEntity>
): string => {
	if (!value || !itemsMap || !itemsMap[value] || !(fieldName in itemsMap[value])) {
		return '';
	}

	const title = itemsMap[value][fieldName] as unknown;
	return typeof title === 'string' ? title : '';
};

const arrow = <i className="tz-simple-arrow-20" />;

export const SingleSelectContractField = <S extends {contractorLegalEntityId?: string}, E>({
	field = 'contractorLegalEntityId',
	fieldName = 'name',
	className,
	label,
	legalEntities,
	dialogTitle,
	errorMessage,
	hasError,
	required,
	isMobile,
	disabled,
	value,
	icon = arrow,
	editingDispatch,
	onError
}: ISingleSelectContractFieldProps<S, E>) => {
	const legalEntitiesMap = useLegalsMap();
	const [checked, setChecked] = useState(value);
	const [isOpen, setOpen] = useState(false);

	const [search, setSearch] = useState('');

	const clearSearch = () => setSearch('');

	useUpdateEffect(() => setChecked(value), [value]);

	const open = () => setOpen(true);
	const close = () => setOpen(false);

	const isSearchShown = (legalEntities?.length || 0) >= 8;

	const filteredItems = useMemo(
		() => flatFilter(legalEntities || [], fieldName, search),
		[fieldName, legalEntities, search]
	);

	const handleCancel = () => {
		setChecked(value);
		if (isMobile) {
			close();
		}
		clearSearch();
	};

	const handleChange = useCallback(
		(v: string | null | undefined) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[editingDispatch, field, required]
	);

	const handleConfirm = useCallback(() => {
		handleChange(checked);
		if (required) {
			onError();
		}
		if (isMobile) {
			close();
		}

		clearSearch();
	}, [handleChange, checked, required, isMobile, onError]);

	const handleClearButton = useCallback(() => {
		handleChange(null);
		if (required) {
			onError();
		}
		clearSearch();
	}, [handleChange, required, onError]);

	const trigger = (
		<TextFieldWithForwardedRef
			className="editable-contract__fields-single-select__text-field"
			elementType="div"
			value={getTitle(fieldName, value, legalEntitiesMap)}
			icon={icon}
			onClearClick={handleClearButton}
			onClick={isMobile ? open : undefined}
			cleanable={!!value}
			error={hasError ? errorMessage : undefined}
			disabled={disabled}
		/>
	);

	const content = (
		<Select
			value={checked || undefined}
			onChange={setChecked}
		>
			{filteredItems?.map(item => (
				<SelectOption
					className={{
						content: 'editable-contract__fields-single-select__content'
					}}
					key={item.id}
					itemKey={item.id}
					content={typeof item[fieldName] === 'string' ? (item[fieldName] as string) : ''}
					inputType="radio"
				/>
			))}
		</Select>
	);

	const selectSearch = isSearchShown ? (
		<SelectSearch
			value={search}
			onChange={setSearch}
			onClear={clearSearch}
		/>
	) : null;

	const footer = (
		<div className="editable-contract__fields-single-select__dialog-footer">
			<ActionButtons className="editable-contract__fields-single-select__dialog-footer-btns">
				<Button
					type="cancel"
					label="Отменить"
					onClick={handleCancel}
				/>
				<Button
					type="accent-blue"
					label="Сохранить"
					onClick={handleConfirm}
				/>
			</ActionButtons>
		</div>
	);

	return (
		<div className={classNames('editable-contract__fields-single-select', className)}>
			<EditableFieldLabel className="editable-contract__fields-single-select__label">
				{required ? `${label} *` : label}
			</EditableFieldLabel>
			{!isMobile ? (
				<SelectPopup
					noHeader={!isSearchShown}
					search={selectSearch}
					footer
					onCancel={handleCancel}
					onApply={handleConfirm}
					trigger={trigger}
				>
					{content}
				</SelectPopup>
			) : (
				trigger
			)}
			{isMobile && (
				<Dialog
					isOpen={isOpen}
					className="editable-contract__fields-single-select__dialog"
					title={required ? `${dialogTitle} *` : dialogTitle}
					onRequestClose={close}
					footer={footer}
				>
					<div className="editable-contract__fields-single-select__dialog-search">
						{selectSearch}
					</div>
					{content}
				</Dialog>
			)}
		</div>
	);
};
