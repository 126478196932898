import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {
	formCheckLink,
	formInspectionLink,
	formInternalAcceptanceLink,
	formSpaceLink
} from '@tehzor/tools/utils/links';
import {extractInspection} from '@src/store/modules/entities/inspection/selectors/inspection';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from 'react-i18next';

/**
 * Хук для хлебных крошек от "всех объектов" до осмотра
 *
 * @param objectId id конечного объекта
 */
export function useInspectionBreadcrumbs(objectId: string) {
	const {t} = useTranslation();

	const objects = useObjectsData();
	const inspection = useAppSelector(extractInspection);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(() => {
		const items: IBreadcrumb[] = objects
			? formObjectsChain(objects.byId, objectId, 'inspections', t)
			: [];

		if (inspection) {
			if (inspection.space) {
				items.push({
					label: formSpaceTitle(
						inspection.space.name,
						inspection.space.altName,
						spaceTypes?.[inspection.space.type],
						altNamesVisible
					),
					url: formSpaceLink(objectId, inspection.space.id),
					inactive: false
				});
			}
			if (inspection.check) {
				items.push({
					label: `${t('inspectionPage.pageBreadcrumbs.check.label')} №${
						inspection.check.number
					}`,
					url: formCheckLink(objectId, inspection.check.id),
					inactive: false
				});
			}
			if (inspection.internalAcceptance) {
				items.push({
					label: `${t('inspectionPage.pageBreadcrumbs.internalAcceptance.label')} №${
						inspection.internalAcceptance.number
					}`,
					url: formInternalAcceptanceLink(objectId, inspection.internalAcceptance.id),
					inactive: false
				});
			}
			items.push({
				label: `${t('inspectionPage.header')} №${
					inspection.number || inspection.localNumber
				}`,
				url: formInspectionLink(objectId, inspection.id),
				inactive: false
			});
		}
		return items;
	}, [objectId, inspection, objects, spaceTypes]);
}
