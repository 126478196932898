import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetCheckListTypesResponse} from '@src/api/backend/checkListTypes';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {extractCheckListTypesAsArray, extractCheckListTypesMap} from './selectors';

export const useCheckListTypes = <T = IGetCheckListTypesResponse>(
	select?: (data: IGetCheckListTypesResponse) => T
) =>
	useQuery<IGetCheckListTypesResponse, IError, T>({
		queryKey: checkListsQueryKeys.types(),
		meta: {
			error: 'ошибка при получении типов чек-листов'
		},
		select
	});

export const useCheckListTypesAsArray = () => useCheckListTypes(extractCheckListTypesAsArray);
export const useCheckListTypesMap = () => useCheckListTypes(extractCheckListTypesMap);
