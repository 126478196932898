import {requestGetSpaceStatusesSets} from '@src/api/backend/spaceStatusesSets';
import {useQueryClient} from '@tanstack/react-query';
import {spaceStatusesSetsQueryKeys} from '../keys';

export const useSpaceStatusesSetsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(spaceStatusesSetsQueryKeys.list(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetSpaceStatusesSets,
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
