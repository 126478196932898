import {
	TOGGLE_WEB_PUSH_ENABLED,
	TOGGLE_NOTIFICATIONS_VISIBLE
} from '@src/constants/settings/notifications';

/**
 * Переключает видимость окна уведомлений
 *
 * @param {any | undefined} value true/false - для показа или скрытия, undefined - для переключения
 * @returns {{type, payload}}
 */
export const toggleNotificationsVisible = (value?: any) => ({
	type: TOGGLE_NOTIFICATIONS_VISIBLE,
	payload: value
});

/**
 * Переключает состояние подписик на пуш уведомления
 *
 * @param {any | undefined} value true/false - для включения или выключения, undefined - для переключения
 * @returns {{type, payload}}
 */
export const toggleWebPushEnabled = (value?: any) => ({
	type: TOGGLE_WEB_PUSH_ENABLED,
	payload: value
});
