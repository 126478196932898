import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import ICompanyEmployee from '@tehzor/tools/interfaces/companies/ICompanyEmployee';

export const convertEmployeesToTreeItems = (
	users: INormalizedData<IBriefUser>,
	employees?: ICompanyEmployee[],
	parentId?: string
): ITreeDataItem[] =>
	(employees
		? employees
				.reduce<ITreeDataItem[]>((prev, inspector) => {
					if (users.byId[inspector.userId]) {
						prev.push({
							id: inspector.userId,
							content: users.byId[inspector.userId].fullName,
							parentId
						});
					}
					return prev;
				}, [])
				.sort((a, b) => (a.content > b.content ? 1 : -1))
		: []);
