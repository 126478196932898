import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState} from '@src/store/modules/app/links/reducers';

/**
 * Возвращает настройки страницы для конкретного объекта
 */
export const extractDocumentsPageSettings = createSelector(
	(state: IState) => state.settings.pages.documents,
	data => data || getInitialState()
);
