import {
	memo,
	useRef,
	useState,
	CSSProperties,
	ReactNode,
	ReactElement,
	MouseEvent,
	SyntheticEvent,
	KeyboardEvent,
	cloneElement
} from 'react';
import './ModalDialog.less';
import classNames from 'classnames';
import IconButton from '../../buttons/IconButton';
import {convertClassNames} from '../../../utils/convertClassNames';
import {ScrollBlockContext} from '../../../context/ScrollBlockContext';
import {Modal} from '../../Modal';

export interface IDialogProps {
	className?:
		| string
		| {
				root?: string;
				portal?: string;
				overlay?: string;
				layer?: string;
				content?: string;
				header?: string;
				title?: string;
				body?: string;
				footer?: string;
		  };
	style?: CSSProperties;
	children?: ReactNode;
	open: boolean;
	title?: ReactNode;
	footer?: ReactNode;
	closeButton?: ReactElement<{
		className?: string;
		onClick?: (event: MouseEvent) => void;
	}>;
	hideCloseButton?: boolean;
	useContentOpenAnimation?: boolean;
	useContentCloseAnimation?: boolean;
	fullScreen?: boolean;
	fullScreenOnMobile?: boolean;
	fullScreenOnTablet?: boolean;
	disableScrollBlock?: boolean;

	onRequestClose?: (event?: SyntheticEvent) => void;
	onBeforeOpen?: () => void;
	onAfterOpen?: () => void;
	onBeforeClose?: () => void;
	onAfterClose?: () => void;
	onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
	dataTestId?: string;
}

export const ModalDialog = memo((props: IDialogProps) => {
	const {
		className,
		children,
		title,
		footer,
		closeButton,
		hideCloseButton,
		useContentOpenAnimation,
		useContentCloseAnimation,
		fullScreen,
		fullScreenOnMobile,
		fullScreenOnTablet,
		disableScrollBlock,
		onRequestClose,
		onAfterClose,
		dataTestId,
		open,
		...rest
	} = props;

	const bodyElement = useRef<HTMLDivElement>(null);
	const btnVisible = onRequestClose && !hideCloseButton;
	const classes = convertClassNames(className);

	const [isScrollBlocked, setScrollBlocked] = useState(false);

	let closeBtn;
	if (btnVisible) {
		closeBtn = closeButton ? (
			cloneElement(closeButton, {
				className: classNames(closeButton.props.className, 'dialog__close-btn'),
				onClick: onRequestClose
			})
		) : (
			<IconButton
				className="dialog__close-btn"
				onClick={onRequestClose}
				dataTestId="CloseButton"
			>
				<i className="tz-close-24" />
			</IconButton>
		);
	}

	const isFinalScrollBlocked = disableScrollBlock ? false : isScrollBlocked;

	return (
		<Modal
			{...rest}
			// disableBodyScrollLock={disableScrollBlock}
			className={classNames(classes.root)}
			contentClassName={classNames(classes.content)}
			open={open}
			useContentCloseAnimation={useContentCloseAnimation}
			useContentOpenAnimation={useContentOpenAnimation}
			onClose={onRequestClose}
			fullScreen={fullScreen}
			fullScreenOnTablet={fullScreenOnTablet}
			fullScreenOnMobile={fullScreenOnMobile}
			qa={dataTestId}
			onAfterClose={onAfterClose}
		>
			{closeBtn}

			{title !== undefined && (
				<div
					className={classNames(
						'dialog__header',
						{'dialog__header_margin-right': btnVisible},
						classes.header
					)}
				>
					<div className={classNames('dialog__header-title', classes.title)}>{title}</div>
				</div>
			)}
			<ScrollBlockContext.Provider value={setScrollBlocked}>
				{children !== undefined && (
					<div
						className={classNames('dialog__body', classes.body, {
							'dialog__body_scroll-blocked': isFinalScrollBlocked
						})}
						ref={bodyElement}
					>
						{children}
					</div>
				)}
			</ScrollBlockContext.Provider>
			{footer !== undefined && (
				<div className={classNames('dialog__footer', classes.footer)}>{footer}</div>
			)}
		</Modal>
	);
});
