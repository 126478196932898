import {memo, useCallback} from 'react';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {ResponsiblesFilterMobile} from './ResponsiblesFilterMobile';

interface IPerformersFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;
	label?: string;
	onChange: (changes: IChanges) => void;
}

export const PerformersFilterMobile = memo(
	({objectId, value, entity, label, onChange}: IPerformersFilterMobileProps) => {
		const handleChange = useCallback((v: string[] | undefined) => {
			onChange({performers: v});
		}, []);

		const handleExpand = useFilterPageTransition(objectId, entity, 'performers');

		return (
			<ResponsiblesFilterMobile
				respUsers={value}
				label={label}
				onChange={handleChange}
				onExpand={handleExpand}
			/>
		);
	}
);
