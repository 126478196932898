import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialStateForPage, ISpacesSchemaPagesState} from '../reducers/schemas';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';

const getSpacesPages = (state: IState): ISpacesSchemaPagesState => state.entities.spaces.schemas;

export const enrichSpaces = (
	spaces: IListSpace[],
	spaceStatuses: Record<string, ISpaceStatus>,
	spaceIndicators: Record<string, ISpaceIndicator>
): IEnrichedSpace[] =>
	spaces.map(space => {
		const {status, indicators, ...restData} = space;
		return {
			...restData,
			status: spaceStatuses[status],
			indicators: indicators?.map(id => spaceIndicators[id]).filter(item => item)
		};
	});

/**
 * Извлекает данные для шахматки помещений конкретного объекта
 */
export const extractSpacesSchemaData = createSelector(
	getSpacesPages,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(rootData, objectId) => rootData[objectId] || getInitialStateForPage()
);
