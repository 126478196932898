import {makeChecksExportRequest} from '@src/api/backend/checks';
import {addExportToast} from '@src/components/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {useCallback} from 'react';

interface IExportCheckParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId?: string;
	filters?: Record<string, unknown>;
	sort?: Record<string, boolean>;
	selected?: string[];
}

export const useExportChecks = () => {
	const mutation = useMutation({
		mutationFn: (params: IExportCheckParams) => {
			const {sort, email, objectId, selected, templateId, filters, createDocument} = params;
			return makeChecksExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected
			);
		},
		onSuccess: (data, params) => {
			if (data.link) {
				addExportToast(
					params.email ? 'Документ отправлен' : 'Экспорт документа завершен',
					data.link,
					data.file.name,
					'',
					data.document,
				);
			}
			if (data.warnings?.itemsLimit !== undefined) {
				addWarningToast(
					'Внимание',
					`превышен лимит записей (${String(data.warnings.itemsLimit)})`
				);
			}
		},
		onError: () => {
			addErrorToast('Ошибка', 'при экспорте');
		}
	});

	/**
	 * Возвращает функцию для экспорта списка проверок
	 *
	 * @param templateId id шаблона экспорта
	 * @param createDocument флаг создания документа
	 * @param email email для отправки
	 * @param objectId id объекта
	 * @param filters фильтры
	 * @param sort сортировка
	 * @param selected выбранные элементы
	 */

	return useCallback(
		(
			templateId: string,
			createDocument: boolean | undefined,
			email: string | undefined,
			objectId?: string,
			filters?: Record<string, unknown>,
			sort?: Record<string, boolean>,
			selected?: string[]
		) => {
			mutation.mutate({
				templateId,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected
			});
		},
		[mutation]
	);
};
