import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useStructureBreadcrumbs} from '@src/core/hooks/breadcrumbs/useStructureBreadcrumbs';
import {MainActionsDesktop} from './actions/MainActions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useLocation} from 'react-router-dom';

interface IPageBreadcrumbsProps {
	objectId: string;
	structureId: string;
}

export const PageBreadcrumbs = ({objectId, structureId}: IPageBreadcrumbsProps) => {
	const location = useLocation();
	const breadcrumbs = useStructureBreadcrumbs(objectId, structureId);
	const isDesktop = useIsDesktop();
	const isActionVisible = location.pathname === `/objects/${objectId}/structures/${structureId}`;

	return (
		<AppBreadcrumbs
			className="structure-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && isActionVisible && (
				<MainActionsDesktop
					objectId={objectId}
					structureId={structureId}
				/>
			)}
		</AppBreadcrumbs>
	);
};
