import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetCheckListStoriesResponse} from '@src/api/backend/checkListStories';
import {checkListStoriesQueryKeys} from '@src/api/cache/checkListStories/keys';
import {useCheckListPermissions} from '../../permissions/useCheckListPermissions';
import {ICheckListHistoryLinks} from '@src/components/CheckListHistoryDialog/hooks/useEnrichedHistories';
import {ICheckRecordStory} from '@tehzor/tools/interfaces/checkRecordStories/ICheckRecordStory';
import {extractStoriesAsArray} from './selectors';

export const useCheckListStoriesHook = <T = IGetCheckListStoriesResponse>(
	objectId: string,
	stage: string,
	links: ICheckListHistoryLinks,
	select?: (data: IGetCheckListStoriesResponse) => T
) => {
	const permissions = useCheckListPermissions(objectId);
	return useQuery<IGetCheckListStoriesResponse, IError, T>({
		queryKey: [...checkListStoriesQueryKeys.list(objectId, stage, links)],
		meta: {
			error: 'при загрузке истории изменений по чек-листу'
		},
		select,
		enabled: permissions.canViewHistory && !!(links.spaceId || links.workAcceptanceId)
	});
};

export const useCheckListStoriesAsArray = (
	objectId: string,
	stage: string,
	links: ICheckListHistoryLinks
) => useCheckListStoriesHook<ICheckRecordStory[]>(objectId, stage, links, extractStoriesAsArray);
