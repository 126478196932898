import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {
	requestSpaceTypeDecorationSet,
	requestSpaceTypeDecorationSets
} from '@src/api/backend/spaceTypeDecorationSets';
import {ISpaceTypesDecorationsSetsQueryKey, spaceTypeDecorationSetsQueryKeys} from '../keys';

export const useSpaceTypeDecorationsSetsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(spaceTypeDecorationSetsQueryKeys.lists(), {
		networkMode: 'offlineFirst',
		queryFn: requestSpaceTypeDecorationSets,
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24 * 7
	});
	queryClient.setQueryDefaults(spaceTypeDecorationSetsQueryKeys.one(), {
		networkMode: 'offlineFirst',
		queryFn: ({queryKey}: QueryFunctionContext<ISpaceTypesDecorationsSetsQueryKey>) => {
			const [, id] = queryKey;

			return requestSpaceTypeDecorationSet(id);
		},
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24 * 7
	});
};
