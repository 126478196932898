import {IGetProblemTemplatesSetsResponse} from '@src/api/backend/problemTemplatesSets';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

/**
 * Возвращает наборы шаблонов нарушений в виде массива
 */
export const extractProblemTemplatesSetsAsArray = (data: IGetProblemTemplatesSetsResponse) =>
	data.allIds.map(id => data.byId[id]);

/**
 * Возвращает шаблоны нарушений для конкретного объекта в виде массива
 */
export const extractProblemTemplatesAsArray = (
	data: IGetProblemTemplatesSetsResponse,
	object?: IObject
) => {
	const sets = extractProblemTemplatesSetsAsArray(data);

	const set = sets.find(set => set.id === object?.problemTemplatesSet);
	return set?.data;
};
