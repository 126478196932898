import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IMeterType} from '@tehzor/tools/interfaces/meters/IMeterType';

export type IGetMeterTypesResponse = INormalizedData<IMeterType>;

/**
 * Возвращает список типов приборов учёта
 */
export const requestMeterTypes = async () => {
	const response = await httpRequests.withToken.get<IGetMeterTypesResponse>('meter-types');
	return response.data;
};
