import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {MenuActionCtxProvider} from '@src/pages/PlansPage/components/DispatchActionCtx';
import {useIsFetching} from '@tanstack/react-query';
import {Row} from 'react-table';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {DndContext, DndTable, IDndEvent, LoadingPanel, Plate} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {useEditOrderPlans} from '@src/core/hooks/mutations/plans/useEditOrderPlans';
import {renderPreview} from '../utils/renderPreview';
import {useColumns} from '@src/pages/PlansPage/hooks/useColumns';

const classes = {emptyRow: 'plans-page__plate-empty'};

export const PlansTable = () => {
	const {pushPath} = useChangePath();
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {data: plans} = usePlansAsArray(objectId);
	const isFetching = !!useIsFetching({queryKey: plansQueryKeys.list()});

	const editPlanOrder = useEditOrderPlans();

	const handleRowClick = useCallback(
		(row: Row<IPlan>) => {
			pushPath(`/objects/${objectId}/plans/${row.original.id}`);
		},
		[objectId]
	);

	const handleDragEnd = useCallback(
		({items}: IDndEvent<IPlan>) => {
			const newOrder = items.map(item => ({
				id: item.id,
				order: item.order
			}));
			if (!objectId) {
				return;
			}

			editPlanOrder({objectId, order: newOrder});
		},
		[objectId]
	);

	const columns = useColumns();

	return plans ? (
		<Plate
			withoutPadding
			className="plans-page__plate"
		>
			<MenuActionCtxProvider>
				<LoadingPanel active={isFetching}>
					<DndContext renderPreview={renderPreview}>
						<DndTable<IPlan>
							className={classes}
							columns={columns}
							items={plans}
							onRowClick={handleRowClick}
							onDragEnd={handleDragEnd}
							container="plans-table"
						/>
					</DndContext>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	) : null;
};
