import {PrivateRoute} from '@src/containers/PrivateRoute';
import ProblemPage from '@src/pages/ProblemPage';
import {ProblemsPage} from '@src/pages/ProblemsPage';
import ProblemsFiltersRoutingPage from '@src/pages/filters/ProblemsFiltersRoutingPage';
import {problemsFilterRoutes} from './problemsFilterRoutes';
import ProblemCommentsPageWrap from '@src/pages/ProblemCommentsPage/ProblemCommentsPageWrap';

export const objectProblemsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<ProblemsPage />} />
	},
	{
		path: ':problemId',
		element: <PrivateRoute element={<ProblemPage />} />
	},
	{
		path: ':problemId/comments',
		element: <PrivateRoute element={<ProblemCommentsPageWrap />}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<ProblemsFiltersRoutingPage />} />,
		children: problemsFilterRoutes
	}
];
