import {useMemo} from 'react';
import '../CheckListHistoryDialog.less';
import {IHistoryDifference} from '@tehzor/tools/interfaces/history/IHistoryDifference';
import {useCheckListStoriesAsArray} from '@src/core/hooks/queries/checkListStories/hook';
import {createCheckRecordStatusDifference} from '@src/utils/createHistoryData';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {ICheckRecordStory} from '@tehzor/tools/interfaces/checkRecordStories/ICheckRecordStory';
import {IGetCheckRecordStatusesResponse} from '@src/api/backend/checkRecordStatuses';
import {useIndexedCheckItems} from '@src/core/hooks/queries/checkItems/hooks';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useCheckRecordsStatuses} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useUsers} from '@src/core/hooks/queries/users/hooks';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';

interface IIndexedCheckItem extends ICheckItem {
	currentIndex: string | undefined;
}

function compare(
	next: ICheckRecordStory,
	prev: ICheckRecordStory | undefined,
	users: INormalizedData<IBriefUser> | undefined,
	checkRecordStatusesStatuses: IGetCheckRecordStatusesResponse | undefined,
	t: TFunction<'translation', undefined>,
	checkList?: ICheckList,
	indexedItems?: IIndexedCheckItem[]
): IHistoryDifference {
	const data: IHistoryData[] = [];
	if ((!prev || next.data.status !== prev?.data.status) && checkRecordStatusesStatuses) {
		let label;
		if (next.data.links.checkItemId) {
			const checkItem = indexedItems?.find(item => item.id === next.data.links.checkItemId);
			if (checkItem) {
				label = `категории "${checkItem.currentIndex} ${checkItem.name}"`;
			}
		} else if (next.data.links.checkListId && checkList) {
			label = `чек-листа "${checkList.name}"`;
		}

		data.push(
			createCheckRecordStatusDifference(
				prev?.data.status,
				next.data.status,
				label,
				checkRecordStatusesStatuses,
				t
			)
		);
	}

	return {
		data,
		createdAt: next.createdAt,
		createdBy: next.createdBy && users ? users.byId[next.createdBy] : undefined
	};
}

export interface ICheckListHistoryLinks {
	checkListId: string;
	spaceId?: string;
	workAcceptanceId?: string;
}

export function useEnrichedHistories(
	objectId: string,
	stage: string,
	links: ICheckListHistoryLinks
): IHistoryDifference[] {
	const {t} = useTranslation();
	const {data: histories} = useCheckListStoriesAsArray(objectId, stage, links);
	const {data: checkList} = useExtractCheckListById(links.checkListId);
	const {data: checkRecordStatuses} = useCheckRecordsStatuses();
	const {data: users} = useUsers();
	const {data: indexedItems} = useIndexedCheckItems(links.checkListId);

	const preparedHistories = histories?.reduce(
		(prev, current) => {
			const itemId = current.data.links.checkItemId;
			if (!itemId) {
				prev.listStories.push(current);
			} else if (!prev.itemsStories[itemId]) {
				prev.itemsStories[itemId] = [current];
			} else {
				prev.itemsStories[itemId].push(current);
			}
			return prev;
		},
		{
			itemsStories: {} as Record<string, ICheckRecordStory[]>,
			listStories: [] as ICheckRecordStory[]
		}
	);

	return useMemo(() => {
		const result: IHistoryDifference[] = [];
		if (preparedHistories) {
			const handleStories = (stories: ICheckRecordStory[] | undefined) => {
				if (!stories || stories.length === 0) return;

				// у checkRecords нет записи в истории о создании
				// также история началась вестить не сразу
				// поэтому нигде в истории не хранятся данные о предыдущем или самом первом значении статуса
				// чтобы корректно отображалось первое изменение статуса в истории сравниваем первую историю с undefined
				// тем самым как бы добавляем принудительно искусственную начальную точку в историю
				result.push(
					compare(
						stories[0],
						undefined,
						users,
						checkRecordStatuses,
						t,
						checkList,
						indexedItems
					)
				);

				for (let i = 0; i < stories.length - 1; i++) {
					result.push(
						compare(
							stories[i + 1],
							stories[i],
							users,
							checkRecordStatuses,
							t,
							checkList,
							indexedItems
						)
					);
				}
			};

			for (const itemId in preparedHistories.itemsStories) {
				if (preparedHistories.itemsStories.hasOwnProperty(itemId)) {
					handleStories(preparedHistories.itemsStories[itemId]);
				}
			}

			handleStories(preparedHistories.listStories);
		}
		// убираем из истории изменений пустые записи появившиеся из-за изменений нарушений
		const filteredStories = result.filter(item => item.data && item.data?.length > 0);
		return filteredStories;
	}, [preparedHistories, users, checkRecordStatuses, checkList, indexedItems]);
}
