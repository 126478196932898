import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {IStructureType} from '@tehzor/tools/interfaces/structures/IStructureType';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';

export const filterEmptyStructureTypes = (
	sructures: IStructure[],
	structureTypes: IStructureType[]
) => structureTypes.filter(type =>
	sructures.some(sructure => sructure.type === type.id));

export const mapStructureToTreeData = (
	data: Array<IStructure | IStructureType>

) => {
	const result = data.map(el => {
		if ('type' in el) {
			return {
				id: el.id,
				content: el.name,
				parentId: el?.type
			};
		}
		return {
			id: el.id,
			content: el.name,
			onlySemiCheckable: true,
			nonCheckable: true
		};
	});
	return result as ITreeDataItem[];
};

export const makeTreeDataStructure = (
	sructures: IStructure[],
	structureTypes: IStructureType[]
) => {
	const filteredSets = filterEmptyStructureTypes(sructures, structureTypes);
	const arr = [] as Array<IStructure | IStructureType>;
	const dataForTree = arr.concat(sructures, filteredSets);

	return mapStructureToTreeData(dataForTree);
};