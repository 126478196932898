import {PrivateRoute} from '@src/containers/PrivateRoute';
import {FieldSettingsSetsPage} from '@/pages/FieldsSettingsSetsPage';
import {Navigate} from 'react-router';

export const manageFieldsRoutes = [
	{
		index: true,
		element: (
			<Navigate
				to="settings-sets"
				replace
			/>
		)
	},
	{
		path: 'settings-sets',
		element: <PrivateRoute element={<FieldSettingsSetsPage />} />
	}
];
