import {useCallback, useEffect, useState, ReactNode} from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {EditableMeter} from '../EditableMeter';
import {
	convertToSave,
	errorsFns,
	isEdited,
	useEditableMeterState
} from '@src/core/hooks/states/useEditableMeterState';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {ISavingMeter} from '@tehzor/tools/interfaces/meters/ISavingMeter';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';

const fieldsSettings = {
	typeId: {fieldId: 'typeId', isRequired: true},
	serialNumber: {fieldId: 'serialNumber', isRequired: false},
	description: {fieldId: 'description', isRequired: false}
};

/**
 * Логика редактирования и сохранения прибора учёта (нового или существующего)
 */
export const useEditableMeter = (
	meter: IMeter | undefined,
	meterConsumptions: IMeterConsumption[] | undefined,
	saving: boolean
): [ReactNode, () => ISavingMeter | undefined, () => void, boolean] => {
	const editedMeter = meter ? {...meter, meterConsumptions} : undefined;
	const [editingState, editingDispatch] = useEditableMeterState(editedMeter);
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, editedMeter));
	}, [editingState, meter]);

	const getSavingData = useCallback(() => {
		// Проверка наличия ошибок в состоянии
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (!isEdited(editingState, editedMeter)) {
			return undefined;
		}
		return convertToSave(editingState, meter, true);
	}, [editingState, meter]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: meter
		});
	}, [meter]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [meter]);

	const fields = (
		<EditableMeter
			editingState={editingState}
			editingDispatch={editingDispatch}
			saving={saving}
			fieldsSettings={fieldsSettings}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
