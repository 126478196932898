import {Checkbox} from '@tehzor/ui-components';
import { ChangeEvent } from 'react';

interface IDefaultFieldProps {
	value?: boolean;
	disabled?: boolean;

	onChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

export const DefaultField = ({
	value, disabled, onChange
}: IDefaultFieldProps) => (
	<div className="editable-space-statuses__status-fields__default">
		<Checkbox
			checked={value}
			onChange={onChange}
			disabled={disabled}
		>
			По умолчанию
		</Checkbox>
	</div>
);