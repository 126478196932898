import {PhotoPreview} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {IEnrichedTaskForScheduler} from "@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler";

export const TaskDescription = ({row}: Pick<CellProps<IEnrichedTaskForScheduler>, 'row'>) => {
	const task = row.original;

	return (
		<span className="tasks-schedule-popup__list-content_task">
			<div className="tasks-schedule-popup__list-content_task-name">
				{task.name}
			</div>
			{task?.taskType !== undefined && (
				<div className="tasks-schedule-popup__list-content_task-description">
					{task.taskType.name}
				</div>
			)}
			{task?.attachment?.preview && (
				<div className="tasks-schedule-popup__list-content_task-preview">
					<PhotoPreview
						url={task?.attachment.preview.url}
						data={0}
					/>
				</div>
			)}
		</span>
	);
};
