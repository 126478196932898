import {findPermission} from '@tehzor/tools/utils/findPermission';
import {checkRestrictions} from '@tehzor/tools/utils/permissions/restrictions/checkRestrictions';
import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IWorkAcceptanceResponsiblePermissions {
	canEditAcceptors?: boolean;
	onlyActiveGroup?: boolean;
}

export const useWorkAcceptanceResponsiblePermissions = (objectId: string): IWorkAcceptanceResponsiblePermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		let onlyActiveGroup = false;

		const permission = findPermission(user.roles, 'work-acceptances-acceptors-edit', UserRoleScopes.OBJECT, objectId);
		if (!permission) {
			return {canEditAcceptors: false, onlyActiveGroup};
		}

		const restrictions = permission.restrictions;
		if (!restrictions) {
			return {canEditAcceptors: true, onlyActiveGroup};
		}

		if (restrictions?.onlyActiveGroup !== undefined) {
			onlyActiveGroup = typeof restrictions.onlyActiveGroup === 'boolean';
		}

		const canEditAcceptors = checkRestrictions(restrictions, {userId: user.id});

		return {canEditAcceptors, onlyActiveGroup}
	}, [objectId, user]);
}