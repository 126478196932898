import {useContractsPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import {useContract} from '@src/core/hooks/queries/contracts/hooks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IContract} from '@tehzor/tools/interfaces/contracts';
import {formContractLink} from '@tehzor/tools/utils/links';
import {LinkButton} from '@tehzor/ui-components';
import { memo, useCallback } from 'react';

const contractIcon = <i className="tz-contract-16" />;

interface IContractLinkProps {
	contractId: string;
}

export const ContractLink = memo(({contractId}: IContractLinkProps) => {
	const {pushPath} = useChangePath();
	const {data: contract} = useContract(contractId);
	const {canAdministrate} = useContractsPermissions();

	const handleClick = useCallback(
		(contract: IContract) => {
			if (canAdministrate) {
				pushPath(formContractLink(contract.id));
			}
		},
		[pushPath, canAdministrate]
	);

	const label = `${contract?.name}${contract?.number ? ` №${contract?.number}` : ''}`;

	return contract ? (
		<LinkButton
			disabled={!canAdministrate}
			className="entity-links__link"
			onClick={() => handleClick(contract)}
			label={label}
			leftIcon={contractIcon}
			key={contract.id}
			type="filled"
		/>
	) : null;
});
