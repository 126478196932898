import {CellProps} from 'react-table';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {SpaceIndicatorTag, TagsContainer} from '@tehzor/ui-components';

const SpaceIndicatorsCell = ({row}: CellProps<IConvertedSpace>) => {
	const space = row.original;
	return space.indicators?.length ? (
		<TagsContainer>
			{space.indicators.map(item => (
				<SpaceIndicatorTag
					key={item.id}
					color={item.color}
					label={item.name}
				/>
			))}
		</TagsContainer>
	) : null;
};

export default SpaceIndicatorsCell;
