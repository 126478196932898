import {makeDefaultData} from '@src/core/hooks/states/useEditableObjectState';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useCallback, useState} from 'react';
import {useEditableObject} from '../EditableObject/hooks/useEditableObject';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {useTitle} from './hooks/useTitle';
import './EditableObjectDialog.less';
import {useEditObject} from '@src/core/hooks/mutations/object/useEditObject';

interface IEditableObjectDialogProps {
	object: IObject;
	isOpen: boolean;

	onClose: () => void;
}

export const EditableObjectDialog = ({object, isOpen, onClose}: IEditableObjectDialogProps) => {
	const {t} = useTranslation();
	const [saving, setSaving] = useState(false);
	const title = useTitle(!!object);
	const {mutateAsync: editObject} = useEditObject();

	const defaultData = makeDefaultData(object);
	const [fields, getSavingData, reset, isBlocking] = useEditableObject(
		object.id,
		object.companyId,
		defaultData,
		saving,
		false
	);

	const save = useCallback(async () => {
		if (!object) {
			return;
		}

		setSaving(true);

		const savingData = await getSavingData();

		if (savingData) {
			await editObject({objectId: object.id, object: savingData});
			onClose();
		}

		setSaving(false);
	}, [object, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-object-dialog"
			isOpen={isOpen}
			title={title}
			footer={
				<ActionButtons className="editable-object-dialog-action-buttons">
					<Button
						type="cancel"
						label={t('cancelBtn.label')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('applyBtn.label')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};
