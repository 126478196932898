import chroma from 'chroma-js';

export function determineColors(iconColor?: string, backgroundColor?: string) {
	if (iconColor && backgroundColor) {
		return [iconColor, backgroundColor];
	}
	if (iconColor) {
		return [iconColor, chroma(iconColor).alpha(0.2).css()];
	}
	if (backgroundColor) {
		return [chroma(backgroundColor).set('hsl.s', '-0.02').set('hsl.l', '-0.35').css(), backgroundColor];
	}
	return ['#8695A6', chroma('#8695A6').alpha(0.2).css()];
}