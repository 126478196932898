import {IGetMetersResponse} from '@src/api/backend/meters';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractMetersAsArrayForSpace} from '@src/core/hooks/queries/meters/selectors';
import {metersQueryKeys} from '@src/api/cache/meters/keys';
import {useCallback} from 'react';

export const useMeters = <T = IGetMetersResponse>(
	spaceId: string,
	select?: (data: IGetMetersResponse) => T
) =>
	useQuery<IGetMetersResponse, IError, T>({
		queryKey: metersQueryKeys.list(spaceId),
		meta: {
			error: 'Ошибка получения приборов учёта'
		},
		select,
		enabled: !!spaceId
	});

export const useMetersAsArrayForSpace = (spaceId: string) => {
	const select = useCallback(
		(data: IGetMetersResponse) => extractMetersAsArrayForSpace(data, spaceId),
		[spaceId]
	);

	return useMeters(spaceId, select);
};
