import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import { useState } from 'react';
import MobileSelectionClearing from '../selection/SelectionClearing.mobile';
import MobileRightButtons from '../actions/RightButtons.mobile';
import {Plate} from '@tehzor/ui-components';
import {Table} from './Table';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {mobileColumns} from './columns.mobile';
import {useSpaceSectionsMenu} from '@src/core/hooks/useSpaceSectionsMenu';
import {useSpaceStage} from '@src/core/hooks/spaces/useSpaceStage';
import {useTranslation} from "react-i18next";

interface MobileTableProps {
	objectId: string;
	spaceId: string;
	space?: ISpace;
}

export const MobileWorkAcceptanceTable = (props: MobileTableProps) => {
	const {t} = useTranslation();
	const {objectId, spaceId, space} = props;
	const spaceTitle = useSpaceTitle(space);
	const stage = useSpaceStage(objectId, space);
	const sections = useSpaceSectionsMenu(objectId, spaceId, stage);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const mobileLeftButton =
		selectedRows.length > 0 ? (
			<MobileSelectionClearing onSelectedRowsChange={setSelectedRows} />
		) : undefined;
	const mobileRightButtons = space ? (
		<MobileRightButtons
			objectId={objectId}
			spaceId={spaceId}
			space={space}
			selectedRows={selectedRows}
		/>
	) : null;

	useAppHeader(
		{
			title: spaceTitle,
			subTitle: t('header.workAcceptances'),
			showBackBtn: true,
			sectionsMenu: sections,
			mobileLeftButton,
			mobileRightButtons
		},
		[space, selectedRows, sections, mobileLeftButton, mobileRightButtons]
	);

	return (
		<Plate withoutPadding>
			<Table
				objectId={objectId}
				columns={mobileColumns}
				hideHead
				spaceId={spaceId}
				selectedRows={selectedRows}
				onSelectedRowsChange={setSelectedRows}
			/>
		</Plate>
	);
};
