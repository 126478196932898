interface IHatch {
	id: string;
}

export const Hatch = ({id}: IHatch) => (
	<defs>
		<linearGradient
			id={id}
			gradientUnits="userSpaceOnUse"
			x1="0"
			y1="0"
			x2="10"
			y2="10"
			spreadMethod="repeat"
		>
			<stop
				offset="0%"
				stopColor="#CCE4FF"
			/>
			<stop
				offset="70%"
				stopColor="#CCE4FF"
			/>
			<stop
				offset="70%"
				stopColor="#FF5E71"
			/>
			<stop
				offset="100%"
				stopColor="#FF5E71"
			/>
		</linearGradient>
	</defs>
);
