import { useCallback } from 'react';
import ChecksPage from './ChecksPage';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import ActionsMobile from './components/actions/Actions.mobile';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/interfaces';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {checksActions} from '@src/store/modules/settings/pages/checks/slice';

const ChecksPageWrap = () => {
	const dispatch = useAppDispatch();
	const {clearFilters, changeFilters, changeOffset} = checksActions;
	const {objectId} = useStrictParams<{objectId: string}>();

	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {
		sectionsMenu,
		mobileRightButtons: <ActionsMobile objectId={objectId} />
	});

	const {filters} = useAppSelector(s => extractChecksPageSettings(s, objectId));

	const applyFilters = useCallback(
		(value: IChecksFiltersState) => {
			dispatch(changeFilters({objectId, filters: value}));
			dispatch(changeOffset({objectId: objectId || 'all', offset: 0}));
		},
		[changeFilters, changeOffset, dispatch, objectId]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId}));
	}, [clearFilters, dispatch, objectId]);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			objectId={objectId}
			onClear={onClear}
		>
			<ChecksPage
				key={objectId}
				className={sectionsMenu.length ? 'page-cont_extra-top-margin' : undefined}
			/>
		</EntitiesFiltersProvider>
	);
};

export default ChecksPageWrap;
