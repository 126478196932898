/* eslint-disable no-undef */
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Dispatch, useCallback, useMemo, useState} from 'react';
import {EntitiesTable} from '@tehzor/ui-components';
import {extractCheckListsPageSettings} from '@src/store/modules/settings/pages/manage/checkLists/selectors';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {convertGroups} from '../../utils/convertGroups';
import {useEditableResponsibilityRulesDialog} from '@src/components/EditableResponsibilityRulesDialog/hooks/useEditableResponsibilityRulesDialog';
import {desktopGroupsColumns} from './groupsColumns.desktop';
import {IPreparedWorkingGroup} from '../../interfaces/IPreparedWorkingGroup';
import {useResponsibilityRulesForWorkingGroupAsArray} from '@src/core/hooks/queries/responsibilityRules/hooks';
import {useWorkingGroupsByParentIdAsArray} from '@src/core/hooks/queries/workingGroups/hooks';

interface ITableProps {
	editingState: IEditableWorkingGroupState;
	editingDispatch: Dispatch<IEditableWorkingGroupAction>;
	workingGroupId?: string;
}

export const GroupsTable = ({editingState, editingDispatch, workingGroupId}: ITableProps) => {
	const [editDialog, openEditDialog] = useEditableResponsibilityRulesDialog(
		editingState,
		workingGroupId
	);
	const pageSettings = useAppSelector(extractCheckListsPageSettings);
	const {data: groups} = useWorkingGroupsByParentIdAsArray(workingGroupId);
	const {data: responsibilityRules} =
		useResponsibilityRulesForWorkingGroupAsArray(workingGroupId);
	const preparedData = useMemo(
		() => convertGroups(groups, responsibilityRules),
		[groups, editingState, responsibilityRules]
	);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const handleRowClick = useCallback(
		(item: IPreparedWorkingGroup) => {
			openEditDialog({userId: undefined, groupId: item.id});
		},
		[openEditDialog]
	);

	return (
		<>
			<MenuActionCtxProvider
				editingState={editingState}
				editingDispatch={editingDispatch}
			>
				<EntitiesTable
					columns={desktopGroupsColumns}
					data={preparedData}
					selectedRows={selectedRows}
					sort={pageSettings.sort}
					selectable
					onRowClick={handleRowClick}
					onSelectedRowsChange={setSelectedRows}
				/>
			</MenuActionCtxProvider>
			{editDialog}
		</>
	);
};
