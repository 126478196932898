import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {IntervalEndFilterPage} from '@src/pages/filters/IntervalEndFilterPage';
import {IntervalStartFilterPage} from '@src/pages/filters/IntervalStartFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';
import {TasksResponsiblesFilterPage} from '@src/pages/filters/TasksResponsiblesFilterPage';
import {TasksFiltersPage} from '@src/pages/filters/TasksFiltersPage';
import {TasksPrioritiesFilterPage} from '@src/pages/filters/TasksPrioritiesFilterPage';
import {TasksStatusesFilterPage} from '@src/pages/filters/TasksStatusesFilterPage';
import {TasksTypesFilterPage} from '@src/pages/filters/TasksTypesFilterPage';

export const tasksFilterRoutes = [
	{
		path: 'objects',
		element: <FiltersPageObjects/>
	},

	{
		path: 'statuses',
		element: <TasksStatusesFilterPage/>
	},

	{
		path: 'types',
		element: <TasksTypesFilterPage/>
	},
	{
		path: 'priorities',
		element: <TasksPrioritiesFilterPage/>
	},

	{
		path: 'intervalstart',
		element: <IntervalStartFilterPage/>
	},

	{
		path: 'intervalend',
		element: <IntervalEndFilterPage/>
	},

	{
		path: 'creationdate',
		element: <CreationDateFilterPage/>
	},

	{
		path: 'respusers',
		element: <TasksResponsiblesFilterPage/>
	},

	{
		path: 'createdBy',
		element: <CreatedByFilterPage/>
	},

	{
		path: '',
		element: <TasksFiltersPage/>
	}
];
