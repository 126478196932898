import {createMutation} from 'react-query-kit';
import {authorizedPersonsQueryKeys} from '../keys';
import {IAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import {
	IAddAuthorizedPersonParams,
	IEditAuthorizedPersonParams
} from '@src/api/cache/authorizedPersons/defaults/useAuthorizedPersonsMutationDefaults';

export const useAddAuthorizedPersonMutation = createMutation<
	IAuthorizedPerson,
	IAddAuthorizedPersonParams
>({
	mutationKey: authorizedPersonsQueryKeys.add(),
	meta: {
		entity: 'authorizedPerson',
		type: 'creation'
	}
});

export const useEditAuthorizedPersonMutation = createMutation<
	IAuthorizedPerson,
	IEditAuthorizedPersonParams
>({
	mutationKey: authorizedPersonsQueryKeys.edit(),
	meta: {
		entity: 'authorizedPerson',
		type: 'editing'
	}
});
