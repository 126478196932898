import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {WorkAcceptanceTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';

export interface IEditableResponsibilityRule {
	id: string;
	target?:
		| {
				userId: string;
				groupId: undefined;
		  }
		| {
				userId: undefined;
				groupId: string;
		  };
	objects?: string;
	scope?: string;
	autoSelect?: boolean;
	categories?: string[];
	plans?: string[];
	stage?: ObjectStageIds;
}

export type IEditableWorkingGroupState = IEditableEntityState<{
	name?: string;
	parentId?: string;
	departmentId?: string;
	companyId?: string;
	stages?: string[];
	leader?: string;
	performers?: string[];
	objects?: string[];
	type?: WorkingGroupTypeId;
	workAcceptanceType?: WorkAcceptanceTypeId[];
	workAcceptanceFrontType?: WorkAcceptanceFrontTypeId;
}>;

export type IEditableWorkingGroupAction = IEditableEntityAction<
	IEditableWorkingGroupState,
	{
		workingGroup?: IWorkingGroup;
	}
>;

const makeEmptyState = (): IEditableWorkingGroupState => ({
	name: undefined,
	parentId: undefined,
	departmentId: undefined,
	companyId: undefined,
	stages: [
		ObjectStageIds.ACCEPTANCE,
		ObjectStageIds.BUILDING,
		ObjectStageIds.TRANSFER,
		ObjectStageIds.WARRANTY
	],
	leader: undefined,
	objects: [],
	performers: [],
	type: undefined,
	workAcceptanceType: undefined,
	workAcceptanceFrontType: undefined,

	errors: {
		name: false,
		parentId: false,
		departmentId: false,
		companyId: false,
		objects: false,
		stages: false,
		leader: false,
		performers: false,
		type: false,
		workAcceptanceType: false,
		workAcceptanceFrontType: false
	}
});

export const init = ({
	workingGroup
}: {
	workingGroup?: IWorkingGroup;
}): IEditableWorkingGroupState => {
	const empty = makeEmptyState();

	return workingGroup
		? {
				name: workingGroup.name,
				objects: workingGroup.objects,
				parentId: workingGroup.parentId,
				departmentId: workingGroup.departmentId,
				companyId: workingGroup.companyId,
				stages: workingGroup.stages,
				leader: workingGroup.leader,
				performers: workingGroup.performers,
				type: workingGroup.type,
				workAcceptanceType: workingGroup.workAcceptanceType,
				workAcceptanceFrontType: workingGroup.workAcceptanceFrontType,
				errors: empty.errors
		  }
		: empty;
};
export const isEdited = (state: IEditableWorkingGroupState, original?: IWorkingGroup): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		original?.parentId ? isPropEdited.bind(null, 'parentId') : () => !!state.parentId,
		isPropEdited.bind(null, 'companyId'),
		original?.departmentId ? isPropEdited.bind(null, 'parentId') : () => null,
		isPropEdited.bind(null, 'leader'),
		isPropEdited.bind(null, 'objects'),
		isPropEdited.bind(null, 'stages'),
		isPropEdited.bind(null, 'type'),
		original?.workAcceptanceType && original?.workAcceptanceType?.length > 0
			? isPropEdited.bind(null, 'workAcceptanceType')
			: () => null,
		isPropEdited.bind(null, 'workAcceptanceFrontType')
	);

export const errorsFns = {
	name: (state: IEditableWorkingGroupState) => !state.name,
	companyId: (state: IEditableWorkingGroupState) => !state.companyId,
	parentId: (state: IEditableWorkingGroupState) => !state.parentId,
	departmentId: (state: IEditableWorkingGroupState) => !state.departmentId,
	leader: (state: IEditableWorkingGroupState) => !state.leader,
	performers: (state: IEditableWorkingGroupState) => !state.performers,
	objects: (state: IEditableWorkingGroupState) => !state.objects,
	stages: (state: IEditableWorkingGroupState) => !state.stages?.length,
	type: (state: IEditableWorkingGroupState) => !state.type,
	workAcceptanceType: (state: IEditableWorkingGroupState) => !state.workAcceptanceType,
	workAcceptanceFrontType: (state: IEditableWorkingGroupState) => !state.workAcceptanceFrontType
};

export const convertWorkingGroupToSave = (
	state: IEditableWorkingGroupState,
	original?: IWorkingGroup,
	onlyEdited?: boolean
): ISavingWorkingGroup => {
	if (!onlyEdited) {
		return state;
	}

	const workingGroup: ISavingWorkingGroup = {};
	// в зависимости от исполнителей на бэке удаляются правила, поэтому нужно всегда отправлять
	workingGroup.performers = state.performers;

	if (isPropEdited('name', state, original)) {
		workingGroup.name = state.name;
	}
	if (isPropEdited('parentId', state, original)) {
		workingGroup.parentId = state.parentId;
	}
	if (isPropEdited('companyId', state, original)) {
		workingGroup.companyId = state.companyId;
	}
	if (isPropEdited('departmentId', state, original)) {
		workingGroup.departmentId = state.departmentId;
	}
	if (isPropEdited('leader', state, original)) {
		workingGroup.leader = state.leader;
	}
	if (isPropEdited('objects', state, original)) {
		workingGroup.objects = state.objects;
	}
	if (isPropEdited('stages', state, original)) {
		workingGroup.stages = state.stages;
	}
	if (isPropEdited('type', state, original)) {
		workingGroup.type = state.type;
	}
	if (isPropEdited('workAcceptanceType', state, original)) {
		workingGroup.workAcceptanceType = state.workAcceptanceType;
	}
	if (isPropEdited('workAcceptanceFrontType', state, original)) {
		workingGroup.workAcceptanceFrontType = state.workAcceptanceFrontType;
	}

	return workingGroup;
};
