import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoryOrder} from '@tehzor/tools/interfaces/categories/ICategoryOrder';

export type IEditCategoriesOrderResponse = INormalizedData<ICategory>;

/**
 * Изменяет порядок видов работ по id набора
 *
 * @param categoriesSetId id набора
 * @param order порядок видов работ
 */
export const requestEditOrderCategories = async (
	categoriesSetId: string,
	order: ICategoryOrder[]
) => {
	const response = await httpRequests.withToken.post<IEditCategoriesOrderResponse>(
		`categories/editOrder/${categoriesSetId}`, {order}
	);

	return response.data;
};
