import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

export const getCategoriesFromWorkAcceptances = (
	workAcceptances: IListWorkAcceptance[] | undefined
): string[] => {
	if (!workAcceptances) {
		return [];
	}
	const result = new Set<string>();
	for (const workAcceptance of workAcceptances) {
		if (workAcceptance && workAcceptance.categoryId) {
			result.add(workAcceptance.categoryId);
		}
	}
	return Array.from(result);
};
