import {queryClient} from '@src/api/QueryClient';
import {makeCheckListEditRequest} from '@src/api/backend/checkList';
import {IGetCheckListsResponse} from '@src/api/backend/checkLists';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';
import {useCallback} from 'react';

interface ICheckListEditParams {
	id: string;
	checkListData: ISavingCheckList;
}

export const useEditCheckList = () => {
	const mutation = useMutation<ICheckList, IError, ICheckListEditParams>({
		mutationFn: (params: ICheckListEditParams) =>
			makeCheckListEditRequest(params.id, params.checkListData),

		onSuccess: data => {
			queryClient.setQueryData<IGetCheckListsResponse>(
				checkListsQueryKeys.list(),
				oldData => {
					if (oldData && oldData.allIds.includes(data.id) && oldData.byId[data.id]) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds]
						};
					}
					return oldData;
				}
			);
			addSuccessToast('Успех', 'Чек-лист обновлен');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при обновлении чек-листа');
		}
	});
	/**
	 * Возвращает функцию для редактирования чек-листа
	 *
	 * @param item данные чек-листа
	 */
	return useCallback(
		(item: ICheckListEditParams) => {
			mutation.mutate(item);
		},
		[mutation]
	);
};
