import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetChecksResponse extends INormalizedData<IListCheck> {
	offset: number;
	limit: number;
	total: number;
}

export const requestChecks = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const response = await httpRequests.withToken.post<IGetChecksResponse>('checks/get', {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	});

	return response.data;
};
