import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export type IAddWorkingGroupResponse = IWorkingGroup;

/**
 * Добавляет рабочую группу
 *
 * @param fields данные рабочей группы
 */
export const requestAddWorkingGroup = async (fields: ISavingWorkingGroup) => {
	const response = await httpRequests.withToken.post<IAddWorkingGroupResponse>('working-groups', {
		...fields
	});
	return response.data;
};
