import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBankAccount} from '@tehzor/tools/interfaces/bankAccounts';

export type IGetBankAccountsResponse = INormalizedData<IBankAccount>;

/**
 * Возвращает реквизиты
 */
export const requestBankAccounts = async () => {
	const response = await httpRequests.withToken.get<IGetBankAccountsResponse>('bank-accounts');
	return response.data;
}