import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import Menu from '../Menu';
import {MobileCreateCheck} from './CreateCheck.mobile';

interface IMobileActionsProps {
	taskId: string;
	objectId?: string;
	taskType?: TaskTypeId;
}

const MobileActions = ({taskId, objectId, taskType}: IMobileActionsProps) => {
	const permissions = useChecksPermissions(objectId);
	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	if (taskId === undefined || taskType === undefined) {
		return null;
	}

	return (
		<>
			{taskType === TaskTypeId.AUDIT &&
				permissions.canAdd &&
				(problemsPermissions.canAdd || inspectionsPermissions.canAdd) &&
				objectId && (
					<MobileCreateCheck
						taskId={taskId}
						objectId={objectId}
					/>
				)}
			<Menu taskId={taskId} />
		</>
	);
};

export default MobileActions;
