import {PrivateRoute} from '@src/containers/PrivateRoute';
import {WorkAcceptancesPage} from '@src/pages/WorkAcceptancesPage';
import {WorkAcceptancePageWrap} from '@src/pages/WorkAcceptancePage';
import {WorkAcceptanceCommentsPageWrap} from '@src/pages/WorkAcceptanceCommentsPage';
import {WorkAcceptancesFiltersRoutingPage} from '@src/pages/filters/WorkAcceptancesFiltersRoutingPage';
import {workAcceptancesFilterRoutes} from './workAcceptancesFilterRoutes';
import {CheckListsDesktopRoutes} from '@src/pages/WorkAcceptancePage/components/routes/CheckLists.desktop.routes';

export const objectWorkAcceptancesRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<WorkAcceptancesPage />} />
	},
	{
		path: ':workAcceptanceId/*',
		element: <PrivateRoute element={<WorkAcceptancePageWrap />} />,
		children: [
					{
						path: 'check-lists/:listId?/:pathParam?/:itemId?',
						element: <CheckListsDesktopRoutes />
					}
				]
	},
	{
		path: ':workAcceptanceId/comments',
		element: <PrivateRoute element={<WorkAcceptanceCommentsPageWrap />} />
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<WorkAcceptancesFiltersRoutingPage />} />,
		children: workAcceptancesFilterRoutes
	}
];
