import {addWarningToast} from '@src/utils/toasts';
import {useQueryClient} from '@tanstack/react-query';
import {toSpaceOwner} from './utils/convertToLocalSave';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {useAddSpaceOwnerMutation} from '@src/api/cache/spaceOwners/mutations';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {useCallback} from 'react';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {useOnlineManager} from '../../useOnlineManager';
import useAppSelector from '../../useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';

export interface ISavingSpaceOwnerQuery {
	companyId: string;
	savingData: ISavingSpaceOwner;
	key: string;
}

export const useAddNewSpaceOwner = () => {
	const queryClient = useQueryClient();
	const online = useOnlineManager();
	const offlineModeIsAvailable = useAppSelector(extractOfflineModeAvailability);
	const mutation = useAddSpaceOwnerMutation();
	const createNewSpaceOwnerCache = useCallback(
		(companyId: string, fields: ISavingSpaceOwner): ISpaceOwner => {
			const key = Date.now().toString(10);
			const converted = toSpaceOwner(fields, companyId, key);

			// Формируем кэш собственника с данными для сохранения, для удобства
			// в дальнейшем мы используем его при сохранении локальных родительских сущностей
			queryClient.setQueryData<ISavingSpaceOwnerQuery>(spaceOwnersQueryKeys.detail(key), {
				savingData: fields,
				key,
				...converted
			});
			if (!online && offlineModeIsAvailable) {
				addWarningToast('Локальное сохранение', 'собственник помещения добавлен локально');
				void queryClient.invalidateQueries({queryKey: spaceOwnersQueryKeys.localList()});
			}
			mutation.mutate({
				companyId,
				fields,
				key
			});

			return converted;
		},
		[mutation, offlineModeIsAvailable, online, queryClient]
	);

	return createNewSpaceOwnerCache;
};
