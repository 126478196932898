import {combineReducers} from '@reduxjs/toolkit';
import * as types from '../../building/constants';
import {createAsyncUIReducer} from '@src/utils/createAsyncUIReducer';

export default combineReducers({
	problemsByStatusesLoading: createAsyncUIReducer(
		types.GET_PROBLEMS_BY_STATUSES_REQUEST,
		types.GET_PROBLEMS_BY_STATUSES_SUCCESS,
		types.GET_PROBLEMS_BY_STATUSES_FAILURE
	),
	problemsByCategoriesLoading: createAsyncUIReducer(
		types.GET_PROBLEMS_BY_CATEGORIES_REQUEST,
		types.GET_PROBLEMS_BY_CATEGORIES_SUCCESS,
		types.GET_PROBLEMS_BY_CATEGORIES_FAILURE
	),
	checksCreationLoading: createAsyncUIReducer(
		types.GET_CHECKS_CREATION_REQUEST,
		types.GET_CHECKS_CREATION_SUCCESS,
		types.GET_CHECKS_CREATION_FAILURE
	),
	problemsCreationLoading: createAsyncUIReducer(
		types.GET_PROBLEMS_CREATION_REQUEST,
		types.GET_PROBLEMS_CREATION_SUCCESS,
		types.GET_PROBLEMS_CREATION_FAILURE
	),
	problemRepliesCreationLoading: createAsyncUIReducer(
		types.GET_PROBLEM_REPLIES_CREATION_REQUEST,
		types.GET_PROBLEM_REPLIES_CREATION_SUCCESS,
		types.GET_PROBLEM_REPLIES_CREATION_FAILURE
	),
	inspectionsCreationLoading: createAsyncUIReducer(
		types.GET_INSPECTIONS_CREATION_REQUEST,
		types.GET_INSPECTIONS_CREATION_SUCCESS,
		types.GET_INSPECTIONS_CREATION_FAILURE
	)
});
