import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ItemBullet} from '@src/pages/CheckListPage/components/list/CheckListItems/components/ItemBullet';
import {
	DesktopItemHeaderActions,
	MobileItemHeaderActions
} from '@src/pages/WorkAcceptancePage/components/actions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {format} from 'date-fns';
import {DescriptionPopUp} from './DescriptionPopUp';
import {DescriptionDialog} from './DescriptionDialog';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IItemHeaderProps {
	mode: 'main' | 'detail';
	stage: ObjectStageIds;
	item: ICheckItem;
	record?: ICheckRecord;
}

export const ItemHeader = ({mode, item, record, stage}: IItemHeaderProps) => {
	const isDesktop = useIsDesktop();
	return (
		<div className="work-acceptance-page-check-list-items__item-header">
			<ItemBullet
				item={item}
				record={record}
			/>

			{item.description ? (
				isDesktop ? (
					<DescriptionPopUp item={item} />
				) : (
					<DescriptionDialog item={item} />
				)
			) : (
				<div className="work-acceptance-page-check-list-items__item-header-title">
					{item.name}
				</div>
			)}

			{record && record.modifiedAt ? (
				<div className="work-acceptance-page-check-list-items__item-header-subtitle">
					<div className="work-acceptance-page-check-list-items__item-header-subtitle_accepted">
						{record.status === CheckRecordStatusId.ACCEPTED ? 'Принято' : 'Изменено'}{' '}
						&nbsp;
						<div>{format(record.modifiedAt, dateTimeLetterSeparatedFormat)}</div>
						&nbsp;
						<div className="work-acceptance-page-check-list-items__item-header-subtitle-name">
							{record?.modifiedBy?.displayName}
						</div>
					</div>
				</div>
			) : null}

			<div className="work-acceptance-page-check-list-items__item-header-actions">
				{isDesktop && mode === 'main' && (
					<DesktopItemHeaderActions
						stage={stage}
						listId={item.checkListId}
						itemId={item.id}
					/>
				)}
				{!isDesktop && mode === 'detail' && (
					<MobileItemHeaderActions
						listId={item.checkListId}
						itemId={item.id}
					/>
				)}
			</div>
		</div>
	);
};
