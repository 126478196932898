import {Button} from '@tehzor/ui-components';
import {useAddingInternalAcceptanceDialog} from '@src/components/AddingInternalAcceptanceDialog/hooks/useAddingInternalAcceptanceDialog';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

interface IDesktopEntityAddingProps {
	objectId: string;
}

const DesktopEntityAdding = ({objectId}: IDesktopEntityAddingProps) => {
	const {data: object} = useObject(objectId);
	const {t} = useTranslation();
	const [dialog, open] = useAddingInternalAcceptanceDialog({
		objectId,
		stage: object?.stage
	});

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-plus-20" />}
				label={t('addButton.label')}
				onClick={open}
			/>
			{dialog}
		</>
	);
};

export default DesktopEntityAdding;
