import getRandomColor from '../../utils/getRandomColor';
import AbstractHandler from './AbstractHandler';

const shapeStyles = {
	opacity: 0.5
};

/**
 * Класс для работы с сгенерированными фигурами
 */
class GenShapesHandler extends AbstractHandler {
	// Доступные режимы рисования
	_availableModes = ['select'];

	// Доступные фигуры для выделения
	_availableShapes = ['polygon'];

	/**
	 * Добавляет массив фигур, перед этим очистив слой
	 * @param {Array} shapes фигуры
	 */
	addShapes(shapes) {
		this._group.clear();
		this._shapes = [];

		for (let i = 0, polygon; i < shapes.length; i++) {
			polygon = this._group
				.polygon()
				.attr(shapeStyles)
				.fill(getRandomColor())
				.plot(shapes[i].points);

			let name;
			if (shapes[i].lines.length === 4) {
				const lines = shapes[i].lines.sort();
				name = `${lines[2]}-${lines[3]} / ${lines[0]}-${lines[1]}`;
			} else {
				name = `Сектор ${i + 1}`;
			}

			this._shapes.push({
				element: polygon,
				name,
				selected: false
			});
		}
		this._emit();
	}

	/**
	 * Очищает ресурсы, удаляет слой
	 */
	destroy() {
		super.destroy();
	}

	/**
	 * Добавляет необходимые атрибуты фигуре
	 * @param {Object} shape SVG.js фигура
	 * @return {Object}SVG.js фигура
	 * @private
	 */
	_enhanceShape(shape) {
		return shape
			.fill(getRandomColor())
			.attr(shapeStyles);
	}
}

export default GenShapesHandler;
