import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '../StatusesFilterPage';
import {useObjectsCitiesAsArray} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

const CitiesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const cities = useObjectsCitiesAsArray();

	if (!cities) return null;

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={cities}
			label={t('objectsPage.filters.city')}
			filterName="cities"
		/>
	);
};

export default CitiesFilterPage;
