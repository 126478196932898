import {memo, useMemo} from 'react';
import {MobileFilter, TextField} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useFilterPageTransition} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {IConvertCustomFieldDate} from '@tehzor/tools/interfaces/fields';
import {ICustomFilterDateValue} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';

interface IFixDateFilterMobileProps {
	objectId?: string;
	entity: string;
	fieldData: IConvertCustomFieldDate;
	filterValue: ICustomFilterDateValue;
}

const calendarIcon = <i className="tz-calendar-20" />;

export const CustomFilterDateMobile = memo(
	({
		objectId,
		filterValue = {from: undefined, to: undefined},
		fieldData,
		entity
	}: IFixDateFilterMobileProps) => {
		const handleExpand = useFilterPageTransition(objectId, entity, `custom/${fieldData.key}`);

		const valueAsDate = useMemo(() => {
			const {from, to} = filterValue;
			return {
				from: from ? new Date(from) : undefined,
				to: to ? new Date(to) : undefined
			};
		}, [filterValue]);

		return (
			<MobileFilter label={fieldData.name}>
				<TextField
					className="mobile-entities-filters__textfield"
					elementType="div"
					value={makeDateFilterLabel('', valueAsDate.from, valueAsDate.to)}
					icon={calendarIcon}
					onClick={handleExpand}
				/>
			</MobileFilter>
		);
	}
);
