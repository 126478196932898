/**
 * Возвращает отмеченные точки в виде строки
 *
 * @param points точки
 */
export const getPointsDisplayValue = (points?: Array<{name: string}>): string[] => {
	if (!points || !Array.isArray(points) || !points.length) {
		return [];
	}
	return points.map(item => item.name || '');
};
