import {CellProps} from 'react-table';
import {IPreparedClaim} from '../../interfaces/IPreparedClaim';

const OwnerCell = ({row}: CellProps<IPreparedClaim>) => {
	const {spaceOwner, replyEmail, replyPhone, claimerName} = row.original;
	return (
		<>
			{spaceOwner && (claimerName === undefined || claimerName === '') ? (
				<div>{spaceOwner.name}</div>
			) : null}
			{claimerName && <div>{claimerName}</div>}
			{replyEmail && <div>{replyEmail}</div>}
			{replyPhone && <div>{replyPhone}</div>}
		</>
	);
};

export default OwnerCell;
