import './ScheduleTable.less';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {useBodyColumns, useInfoColumns, useInfoColumnsForGroupMode} from './columns';
import {MenuActionCtxProvider} from '../../../DispatchActionCtx';
import {TasksScheduleTable} from '@src/components/TasksScheduleTable/TasksScheduleTable';
import {convertTasksToScheduleFormat} from '@src/pages/TasksPage/utils/convertTasksToScheduleFormat';
import {useTasksScheduleCollectMode} from '@src/core/hooks/queries/tasks/hooks';
import {
	ITasksFiltersState,

	TasksScheduleCollectMode
} from '@src/store/modules/settings/pages/tasks/interfaces';
import {useIsFetching} from '@tanstack/react-query';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractTasksScheduleSettings} from '@src/store/modules/settings/pages/tasks/selectors';
import {useEnrichedTasksForScheduler} from "@src/core/hooks/queries/tasks/enrichedTasks/hooks";
import {useEntitiesFiltersCtx} from "@src/components/EntitiesFilters/utils/entitiesFiltersCtx";

export const ScheduleTable = () => {
	const {state} = useEntitiesFiltersCtx<ITasksFiltersState>();
	const collectMode = useTasksScheduleCollectMode();
	const {settings} = useAppSelector(extractTasksScheduleSettings);
	const isFetching = useIsFetching({queryKey: tasksQueryKeys.schedule()});

	const {data: enrichedTasks} = useEnrichedTasksForScheduler(state);
	const preparedData = convertTasksToScheduleFormat(settings, collectMode, enrichedTasks);

	const infoColumns = useInfoColumns();
	const infoColumnsForGroupMode = useInfoColumnsForGroupMode();
	const bodyColumns = useBodyColumns(settings);

	const finalInfoColumns =
		collectMode === TasksScheduleCollectMode.GROUP ? infoColumnsForGroupMode : infoColumns;

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider>
				<LoadingPanel active={!!isFetching}>
					<TasksScheduleTable
						data={preparedData}
						columns={finalInfoColumns}
						bodyColumns={bodyColumns}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
