import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetDelegationHistoriesByProblemResponse = INormalizedData<IDelegationHistory>;

/**
 * Получает записи из истории делегирования нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const requestDelegationHistoriesByProblem = async (
	objectId: string,
	problemId: string,
	workingGroupType: WorkingGroupTypeId
) => {
	const response = await httpRequests.withToken.get<IGetDelegationHistoriesByProblemResponse>(
		'delegation-histories',
		{
			params: {objectId, problemId, workingGroupType}
		}
	);

	return response.data;
};
