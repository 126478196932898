import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditProblemTagsSetResponse = IProblemTagsSet;

/**
 * Изменяет набор меток нарушений
 *
 * @param id id набора меток нарушений
 * @param fields данные набора меток
 */
export const makeProblemTagsSetEditRequest = async (id: string, fields: ISavingProblemTagsSet) => {
	const response = await httpRequests.withToken.put<IEditProblemTagsSetResponse>(
		`problem-tags-sets/${id}`,
		fields
	);
	return response.data;
};
