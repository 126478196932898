import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {ICategoriesSetsFilters} from '../selectors';

export const handleFilter = (set: ICategoriesSet, filters: ICategoriesSetsFilters = {}) => {
	const filtersArray = Object.keys(filters || {});
	let result = true;

	for (const filterKey of filtersArray) {
		const {createdAtFrom, createdAtTo, createdBy, stages} = filters;
		const objects = filters.objects || [];

		switch (filterKey) {
			case 'objects': {
				if (!objects?.length) {
					break;
				}

				result = set.objects?.some(item => objects.includes(item)) || !!set.shared;
				break;
			}
			case 'createdBy': {
				const id = set.createdBy?.id || '';
				if (!createdBy?.length) {
					break;
				}

				result = createdBy.includes(id);
				break;
			}
			case 'createdAtTo':
			case 'createdAtFrom': {
				const createdAt = new Date(set.createdAt || 0);
				createdAt.setHours(0, 0, 0, 0);

				if (!createdAtFrom || !createdAtTo) {
					break;
				}

				result = createdAtFrom <= createdAt && createdAt <= createdAtTo;
				break;
			}
			case 'stages': {
				if (!stages || !set.stages?.length) {
					break;
				}

				result = set.stages?.some(item => stages.includes(item));
				break;
			}
		}

		if (!result) {
			break;
		}
	}

	return result;
};
