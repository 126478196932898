import {useQuery} from '@tanstack/react-query';
import {IGetUnitsResponse} from '@src/api/backend/units';
import IError from '@tehzor/tools/interfaces/IError';
import {unitsQueryKeys} from '@src/api/cache/units/keys';
import {IUnit} from '@tehzor/tools/interfaces/units/IUnit';
import {extractUnitsAsArray} from './selectors';

export interface IListUnit extends IUnit {
	fullListName: string;
}

export const useUnits = <T = IGetUnitsResponse>(select?: (data: IGetUnitsResponse) => T) =>
	useQuery<IGetUnitsResponse, IError, T>({
		queryKey: [...unitsQueryKeys.list()],
		meta: {
			error: 'при загрузке единиц измерений'
		},
		select
	});

export const useUnitsAsArray = () => useUnits<IListUnit[]>(extractUnitsAsArray);

/**
 * Функция создающая строку с единицами измерения
 * Пример использования: makeUnitStr([IUnit, IUnit], 10)
 * @param  unitsArr массив IUnit[]
 * @param  unitValue строка значения IUnit
 * @param  unitId id нужного IUnit
 */
export const makeUnitStr = (
	unitsArr?: IUnit[],
	unitValue?: string,
	unitId?: string
): string | undefined => {
	if (!unitValue) return undefined;
	const unit = unitsArr?.find(item => item.id === unitId);

	let finalStr;

	if (unit) {
		finalStr = `${unitValue} (${unit.name})`;
	} else {
		finalStr = `${unitValue}`;
	}

	return finalStr.toLowerCase();
};
