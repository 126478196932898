import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IContract} from '@tehzor/tools/interfaces/contracts';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export interface IContractsFiltersState extends Record<string, unknown> {
	companyIds?: string[];
	contractorIds?: string[];
	objectIds?: string[];
	stageIds?: ObjectStageIds[];
	planIds?: string[];
	categoryIds?: string[];
	signingDateFrom?: Date;
	signingDateTo?: Date;
	createdAtFrom?: Date;
	createdAtTo?: Date;
	createdBy?: string[];
}

export type IContractsSortState = Partial<Record<keyof IContract, boolean>>;

export interface IContractsPageSettingState {
	filters: IContractsFiltersState;
	sort: IContractsSortState;
	pageSize: number;
	offset: number;
	selectedRows: string[];
}

export type IContractsPageSettingsState = Record<string, IContractsPageSettingState>;

export const getInitialState = (): IContractsPageSettingState => ({
	filters: {},
	sort: {
		modifiedAt: false
	},
	pageSize: 20,
	offset: 0,
	selectedRows: []
});

const contractsSlice = createSlice({
	name: 'contracts',
	initialState: getInitialState,
	reducers: {
		changeFilters: (
			state,
			{payload}: PayloadAction<IContractsFiltersState>
		) => {
			state.filters = payload;
		},
		clearFilters: (state) => {
			state.filters = {};
		},
		changeSort: (
			state,
			{payload}: PayloadAction<IContractsSortState>
		) => {
			state.sort = payload;
		},
		changePageSize: (
			state,
			{payload}: PayloadAction<IContractsPageSettingState['pageSize']>
		) => {
			state.pageSize = payload;
		},
		changeOffset: (
			state,
			{payload}: PayloadAction<IContractsPageSettingState['offset']>
		) => {
			state.offset = payload;
		},
		changeSelectedRows: (
			state,
			{payload}: PayloadAction<IContractsPageSettingState['selectedRows']>
		) => {
			state.selectedRows = payload;
		}
	}
});

export const contractsActions = contractsSlice.actions;
export const contracts = contractsSlice.reducer;