import {IconButton} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import { useContext } from 'react';
import {CellProps} from 'react-table';
import {useAsyncFn} from 'react-use';
import {DispatchActionCtx} from '../ContractsTable';
import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';

const deleteIcon = <i className="tz-delete" />;

export const DeleteCell = ({row}: CellProps<IPreparedContract>) => {
	const canDelete = row.original.canDelete;

	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить договор',
		'Вы уверены, что хотите удалить договор?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			dispatchAction({type: 'delete', payload: row.original});
		}
	}, [dispatchAction, row.original]);

	return canDelete ? (
		<>
			<IconButton
				className="contracts__cell-delete"
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</>
	) : null;
};
