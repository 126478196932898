import './SpaceIndicatorTag.less';
import {Tag, ITagProps} from '../Tag';
import classNames from 'classnames';
import {getTagColor} from './utils/getTagColor';

type ISpaceIndicatorTag<T> = Omit<ITagProps<T>, 'icon'>;

export const SpaceIndicatorTag = <T extends unknown>({
	className,
	color,
	size = 'small',
	...rest
}: ISpaceIndicatorTag<T>) => (
	<Tag
		{...rest}
		className={classNames('space-indicator-tag', className)}
		color={getTagColor(color)}
		icon={(
			<div
				className="space-indicator-tag__icon"
				style={{backgroundColor: color}}
			/>
		)}
		size={size}
	/>
);

SpaceIndicatorTag.displayName = 'SpaceIndicatorTag';