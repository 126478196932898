/**
 * Определяет индекс вкладки по текущему пути
 *
 * @param location текущий путь страницы
 * @param path текущий путь раздела
 * @param paths массив всех путей вкладок (относительно раздела)
 */
export function getTabIndexByPath(location: string, path: string, paths: string[]) {
	const subPath = location.replace(path, '');
	const index = paths.indexOf(subPath);
	return index === -1 ? 0 : index;
}
