import * as React from 'react';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';
import debounce from 'lodash/debounce';

interface IAdaptiveHeightPanelProps {
	children?: React.ReactNode;
	pageRef?: HTMLDivElement | null;
	scrollContainerId: string;
}

/**
 * Компонент-контейнер, занимающий всю свободную высоту от своего начала до низа экрана
 */
class AdaptiveHeightPanel extends React.PureComponent<IAdaptiveHeightPanelProps> {
	static defaultProps = {
		scrollContainerId: 'scroll-container'
	};

	/**
	 * Элемент, внутри которого происходит скролл
	 */
	private scrollCont: HTMLElement | null;

	/**
	 * Ссылка на элемент-контейнер комментариев
	 */
	private ref?: HTMLDivElement | null;

	/**
	 * Отступ страницы снизу
	 */
	private pageMargin = 20;

	/**
	 * Обрабатывает событие scroll
	 */
	private handleScroll = rafThrottle(() => {
		this.setHeight();
	});

	/**
	 * Обрабатывает событие resize
	 */
	private handleResize = debounce(() => {
		this.savePageMargin();
		this.setHeight();
	}, 300);

	componentDidMount() {
		this.scrollCont = document.getElementById(this.props.scrollContainerId);
		if (this.scrollCont) {
			this.scrollCont.addEventListener('scroll', this.handleScroll);
		}
		window.addEventListener('resize', this.handleResize);
	}

	componentDidUpdate(prevProps: IAdaptiveHeightPanelProps) {
		if (prevProps.pageRef !== this.props.pageRef) {
			this.savePageMargin();
			this.setHeight();
		}
	}

	componentWillUnmount() {
		if (this.scrollCont) {
			this.scrollCont.removeEventListener('scroll', this.handleScroll);
		}
		window.removeEventListener('resize', this.handleResize);
	}

	render() {
		const {children} = this.props;

		return (
			<div
				ref={this.saveRef}
			>
				{children}
			</div>
		);
	}

	/**
	 * Устанавливает высоту контейнера
	 */
	private setHeight = () => {
		if (this.ref) {
			const height = Math.floor(
				window.innerHeight - this.ref.getBoundingClientRect().top - this.pageMargin
			);
			this.ref.style.height = `${height}px`;
		}
	};

	/**
	 * Локально сохраняет отступ страницы снизу для избежания повторных вычислений
	 */
	private savePageMargin = () => {
		this.pageMargin = this.props.pageRef
			? parseFloat(window.getComputedStyle(this.props.pageRef).paddingBottom)
			: 20;
	};

	/**
	 * Сохраняет ссылку на контейнер комментариев
	 *
	 * @param element элемент
	 */
	private saveRef = (element: HTMLDivElement | null) => {
		this.ref = element;
		this.setHeight();
	};
}

export default AdaptiveHeightPanel;
