import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {MutableRefObject, RefObject} from 'react';
import {AddingWarrantyClaimView} from '../AddingWarrantyClaimDialog';
import {IAddingWarrantyClaimRefProps} from '../components/AddingWarrantyClaim';

interface ISaveClaimProps {
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingWarrantyClaimRefProps>;
	localWarrantyClaimsIds?: string[];
	view: AddingWarrantyClaimView;
	online: boolean;
	updateLatest: () => Promise<void>;
	selectedWarrantyClaimId?: string;

	success: MutableRefObject<boolean>;
	onClose: () => void;
	setSaving?: (value: boolean) => void;
}

export const saveClaim = async ({
	addingEntityRef,
	localWarrantyClaimsIds,
	view,
	online,
	updateLatest,
	selectedWarrantyClaimId,
	success,
	onClose,
	setSaving
}: ISaveClaimProps) => {
	if (addingEntityRef.current) {
		let warrantyClaimId = selectedWarrantyClaimId;

		const extraLinks: IProblem['links'] = {warrantyClaimId};

		const savingData = await addingEntityRef.current.getSavingData(true);
		if (!savingData) {
			return;
		}

		if (view === 'claim') {
			warrantyClaimId = (await addingEntityRef.current.save(savingData)) as string;
		}
		if (view === 'problem') {
			if (addingEntityRef.current.saveCache && savingData) {
				if (warrantyClaimId && localWarrantyClaimsIds?.includes(warrantyClaimId)) {
					await addingEntityRef.current.saveCache(savingData, extraLinks);
				} else {
					const cachedData = await addingEntityRef.current.saveCache(
						savingData,
						extraLinks
					);
					if (cachedData) {
						await addingEntityRef.current.save(
							savingData,
							extraLinks,
							cachedData.cacheKey
						);
					}
				}
			}
		}
		if (warrantyClaimId && online) {
			void updateLatest();
		}
	}
	if (!addingEntityRef.current || view === 'problem') {
		onClose();
	}
	success.current = true;
	if (success.current && view === 'problem') {
		setSaving?.(false);
	}
};
