import {useAddBankAccountMutation} from '@src/api/cache/bankAccounts/mutations';
import {IAddBankAccountParams} from '@src/api/cache/bankAccounts/defaults/useBankAccountsMutationDefaults';
import {IAddBankAccountResponse} from '@src/api/backend/bankAccount/add';

export const useAddBankAccount = (): ((
	params: IAddBankAccountParams
) => Promise<IAddBankAccountResponse | undefined>) => {
	const mutation = useAddBankAccountMutation();
	return (params: IAddBankAccountParams) => mutation.mutateAsync(params);
};
