import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetCheckItemsResponse = INormalizedData<ICheckItem>;

/**
 * Возвращает список категорий доступных чек-листов
 */
export const requestCheckItems = async () => {
	const response = await httpRequests.withToken.get<IGetCheckItemsResponse>('check-items');
	return response.data;
};
