import * as types from './constants';

/**
 * Переключает вариант отображения бокового меню (расширенный/узкий)
 *
 * @param value true/false - расширенный/узкий, undefined - поменять на противоположное значение
 */
export const toggleSidebarExpanded = (value?: boolean) => ({
	type: types.TOGGLE_EXPANDED,
	payload: value
});
