import {useEffect} from 'react';
import './App.less';
import AppHeader from '../AppHeader';
import {AppSidebar} from '../AppSidebar';
import AppBottomMenu from '../AppBottomMenu';
import AppNotifications from '../AppNotifications';
import AppContent from '../AppContent';
import {ToastContainer} from 'react-toastify';
import '@tehzor/ui-components/src/styles/toastify.less';
import 'react-toastify/dist/ReactToastify.css';
import {AppUpdateDialog} from '../AppUpdateDialog';
import {ResourcesLoader} from '../ResourcesLoader';
import ScrollRestoration from '@tehzor/tools/components/ScrollRestoration';
import {BeforeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {PageLeavingBlock} from '@tehzor/ui-components';
import {useOfflineMode} from '@src/core/offlineMode/hooks/useOfflineMode';
import {isMobileDevice} from '@src/utils/isMobileDevice';
import {OfflineModeDialog} from '@src/components/OfflineModeDialog';
import {OnlyActivated} from '@src/components/OnlyActivated';
import {useCacheDefaults} from '@src/api/cache/default';
import {EmailNotificationsToast} from '../EmailNotificationsToast/EmailNotificationsToast';
import history from '@src/core/history';
import {onlineManager} from '@tanstack/react-query';
import {SaveDataToast} from '@src/components/SaveDataToast/SavingDataToast';
import {useAddingSuccessToast} from '@src/components/AddingSuccessToast';

/**
 * Корневой компонент приложения
 */
export const App = () => {
	useCacheDefaults();
	useEffect(() => {
		// Добавление корневого класса в зависимости от устройства
		document.body.classList.add(isMobileDevice() ? 'mobile' : 'desktop');
	}, []);

	useEffect(() => {
		if (
			typeof navigator.onLine !== 'undefined' &&
			navigator.onLine !== onlineManager.isOnline()
		) {
			onlineManager.setOnline(navigator.onLine);
		}
	}, []);

	useOfflineMode();

	const {addingSuccessToastContainer} = useAddingSuccessToast();

	return (
		// Оборачивание в AppHeader необходимо для доступности контекста шапки в компонентах страницы
		<>
			{addingSuccessToastContainer}
			<AppHeader>
				<div
					id="scroll-container"
					className="app__wrap"
				>
					<ScrollRestoration
						history={history}
						scrollContainerId="scroll-container"
					>
						<PageLeavingBlock history={history}>
							<ResourcesLoader>
								<AppContent />
							</ResourcesLoader>
						</PageLeavingBlock>
					</ScrollRestoration>

					<BeforeTablet>
						<AppBottomMenu />
					</BeforeTablet>

					<ToastContainer
						position="bottom-right"
						containerId="AppNotificationsContainer"
						autoClose={5000}
					/>

					<AppNotifications />
				</div>
			</AppHeader>

			<AppUpdateDialog />

			<OnlyActivated>
				<AppSidebar />

				<OfflineModeDialog />

				<ToastContainer
					position="bottom-right"
					containerId="EmailNotificationsToastContainer"
					autoClose={5000}
				/>

				<EmailNotificationsToast />
				<SaveDataToast />
			</OnlyActivated>
		</>
	);
};
