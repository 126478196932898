export const checkIsCurrent = (from?: number, to?: number): boolean => {
	if (!from && !to) { return false; }

	const currentDate = new Date().setHours(0);
	const fromDate = new Date(from || 0).setHours(0);
	const toDate = new Date(to || 0).setHours(0);

	if (from && !to) {
		return fromDate < currentDate;
	}
	if (!from && to) {
		return toDate > currentDate;
	}
	return fromDate < currentDate && currentDate < toDate;
};