import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import { useCallback, useState } from 'react';
import * as React from 'react';
import {EditableWorkAcceptanceDialog} from '../EditableWorkAcceptanceDialog';
import {useQueryClient} from '@tanstack/react-query';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';

export function useEditableWorkAcceptanceDialog(
	objectId: string,
	stage: ObjectStageIds,
	workAcceptance?: IWorkAcceptance
): [React.ReactNode, () => void] {
	const queryClient = useQueryClient();
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const reloadWorkAcceptancesList = useCallback(() => {
		void queryClient.invalidateQueries({
			queryKey: workAcceptancesQueryKeys.listWithObjectId(objectId)
		});
	}, [objectId, queryClient]);

	const dialog = (
		<EditableWorkAcceptanceDialog
			objectId={objectId}
			stage={stage}
			workAcceptance={workAcceptance}
			isOpen={isOpen}
			onClose={close}
			onSuccess={reloadWorkAcceptancesList}
		/>
	);

	return [dialog, open];
}
