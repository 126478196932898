import {Reducer, useReducer} from 'react';
import {
	errorsFns,
	IEditableMeterConsumptionAction,
	IEditableMeterConsumptionState,
	init
} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';

export const useEditableMeterConsumptionState = (meterConsumption?: IMeterConsumption) =>
	useReducer<
		Reducer<IEditableMeterConsumptionState, IEditableMeterConsumptionAction>,
		IMeterConsumption | undefined
	>(createReducer(init, errorsFns), meterConsumption, init);
