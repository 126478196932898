import {Plate} from '@tehzor/ui-components';
import {DesktopInfo as Info} from './info/Info.desktop';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {IPermissions} from '@src/pages/ObjectsPage/utils/usePermissionsForObject';
import {DesktopControls as Controls} from './Controls.desktop';
import {Image} from './Image';

interface IDesktopProps {
	object: IObject;
	stages: IObjectStage[];
	permissions: IPermissions;
}

export const Desktop = ({object, stages, permissions}: IDesktopProps) => (
	<div className="page-cont object-page">
		<div className="object-page__d-options">
			<PageBreadcrumbs objectId={object.id}/>
			<Controls
				object={object}
				permissions={permissions}
			/>
		</div>

		<div className="object-page__d-columns">
			<Plate>
				<Image
					title={object.name}
					fullImage={object.fullImage?.url}
					previewImage={object.previewImage?.url}
				/>
			</Plate>

			<Plate className="object-page__d-column">
				<Info
					object={object}
					stages={stages}
				/>
			</Plate>
		</div>
	</div>
);
