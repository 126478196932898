import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IProblemsAddingPermissions {
	canAdd?: boolean;
}

export interface IParamAggregateEntityPermissions {
	createdBy?: string;
}

/**
 * Возвращает полномочие с учётом ограничений на создание добавление сущности в аггрегирующаю сущность
 *
 * @param objectId id объекта
 * @param permissionId id полномочия
 * @param param параметры необходимые для проверки ограничений
 * 
 */
export const useAggregateEntityPermissions = (
	objectId?: string,
	permissionId?: string,
	param?: IParamAggregateEntityPermissions
	): IProblemsAddingPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles || !objectId || !permissionId) {
			return {};
		}
		const permission = findPermission(user.roles, permissionId, UserRoleScopes.OBJECT, objectId);
		if (!permission) {
			return {};
		}
		let canAdd = true;

		if (permission.restrictions && param) {
			Object.keys(permission.restrictions).forEach(
				restrictionKey => {
				if (restrictionKey.indexOf('CreatedByUser') !== -1) {
					if (permission.restrictions![restrictionKey]
						&& param.createdBy
						&& user.id.toString() !== param.createdBy.toString()) {
						canAdd = false;
					}
				}
			}
);
		}

		return {
			canAdd
		};
	}, [objectId, user]);
};