import {memo} from 'react';
import {Plate} from '@tehzor/ui-components';
import './EditingWorkingGroup.less';
import {useEditableWorkingGroup} from '../EditableWorkingGroup/hooks/useEditableWorkingGroup';
import {useWorkingGroupById} from '@src/core/hooks/queries/workingGroups/hooks';

interface IEditingWorkingGroupProps {
	workingGroupId?: string;
}

export const EditingWorkingGroup = memo(({workingGroupId}: IEditingWorkingGroupProps) => {
	const {data: workingGroup} = useWorkingGroupById(workingGroupId);
	const [workingGroupFields, workingGroupPerformersFields, workingGroupSubGroupsFields] =
		useEditableWorkingGroup({
			workingGroup
		});
	return (
		<div className="editing-working-group__container">
			<Plate className="editing-working-group__info-grid">{workingGroupFields}</Plate>
			<Plate className="editing-working-group__items-grid">
				{workingGroupPerformersFields}
				{workingGroupSubGroupsFields}
			</Plate>
		</div>
	);
});
