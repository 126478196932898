import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTaskStatusesAsArray} from '@src/core/hooks/queries/taskStatuses/hook';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {useTranslation} from 'react-i18next';

export const TasksStatusesFilterPage = () => {
	const {t} = useTranslation();
	const {entity} = useStrictParams<{entity: string}>();
	const {data: statuses} = useTaskStatusesAsArray();

	return (
		<StatusesFilterPage
			entity={entity}
			statuses={statuses}
			label={t('entitiesFilters.tasksStatusesFilter.label')}
			translationDictionaryKey={dictionaryKeys.taskStatuses}
		/>
	);
};
