import {memo} from 'react';
import * as React from 'react';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import isEqual from 'lodash/isEqual';
import {useUpdateEffect} from 'react-use';
import {useWorkingGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';

interface IWorkingGroupSelectProps {
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	editingStages?: string[];
	originalStages?: string[];
	label?: string;
	onChange?: (value?: string | null, required?: boolean) => void;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	excludedGroup?: string;
	workingGroupType: WorkingGroupTypeId;
}

const simpleArrowIcon = <i className="tz-simple-arrow-20" />;

export const WorkingGroupSelect = memo((props: IWorkingGroupSelectProps) => {
	const {
		className,
		style,
		label = 'Родительская группа',
		value,
		required,
		originalStages,
		editingStages,
		onChange,
		disabled,
		hasError,
		excludedGroup,
		workingGroupType
	} = props;
	const {data: groups} = useWorkingGroupsAsArray();
	const filteredGroups = groups
		? groups.filter(
				group =>
					group.type === workingGroupType &&
					(!excludedGroup ||
						(group.id !== excludedGroup && group.parentId !== excludedGroup)) &&
					group?.stages?.some(stage => editingStages?.includes(stage))
		  )
		: [];

	useUpdateEffect(() => {
		if (!originalStages || (originalStages && !isEqual(originalStages, editingStages))) {
			onChange?.(null, required);
		}
	}, [editingStages, originalStages]);

	const handleClear = () => {
		onChange?.(null, required);
	};

	const handleSelectChange = (value: string) => {
		onChange?.(value, required);
	};

	if (!groups) {
		return null;
	}
	const groupName = value && groups.find(group => group.id === value)?.name;
	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader={!value}
				onClear={handleClear}
				clearButton={!!value}
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={groupName}
						icon={simpleArrowIcon}
						error={required && hasError ? 'Выберите родительскую группу' : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleSelectChange}
				>
					{filteredGroups.map(group => (
						<SelectOption
							key={group.id}
							itemKey={group.id}
							content={group.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
});
