import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';

export type IEditableSpaceOwnerState = IEditableEntityState<{
	name?: string;
	phone?: string;
	additionalPhone?: string;
	comment?: string;
	email?: string;
	additionalEmail?: string;
}>;

export type IEditableSpaceOwnerAction = IEditableEntityAction<
	IEditableSpaceOwnerState,
	ISpaceOwner
>;

const makeEmptyState = (): IEditableSpaceOwnerState => ({
	name: '',
	phone: '',
	additionalPhone: '',
	comment: '',
	email: '',
	additionalEmail: '',
	errors: {
		name: false,
		phone: false,
		additionalPhone: false,
		comment: false,
		email: false,
		additionalEmail: false
	}
});

export const init = (owner?: ISpaceOwner): IEditableSpaceOwnerState => {
	const empty = makeEmptyState();
	return owner
		? {
				name: owner.name || '',
				email: owner.email || '',
				additionalEmail: owner.additionalEmail || '',
				phone: owner.phone || '',
				additionalPhone: owner.additionalPhone || '',
				comment: owner.comment || '',
				errors: empty.errors
		  }
		: empty;
};

const isNameEdited = (state: IEditableSpaceOwnerState, original?: ISpaceOwner) =>
	(original?.name ? original.name !== state.name : !!state.name);

const isPhoneEdited = (state: IEditableSpaceOwnerState, original?: ISpaceOwner) =>
	(original?.phone ? original.phone !== state.phone : !!state.phone);

const isAdditionalPhoneEdited = (state: IEditableSpaceOwnerState, original?: ISpaceOwner) =>
	(original?.additionalPhone ? original.additionalPhone !== state.additionalPhone : !!state.additionalPhone);

const isCommentEdited = (state: IEditableSpaceOwnerState, original?: ISpaceOwner) =>
	(original?.comment ? original.comment !== state.comment : !!state.comment);

const isEmailEdited = (state: IEditableSpaceOwnerState, original?: ISpaceOwner) =>
	(original?.email ? original.email !== state.email : !!state.email);

	const isAdditionalEmailEdited = (state: IEditableSpaceOwnerState, original?: ISpaceOwner) =>
	(original?.additionalEmail ? original.additionalEmail !== state.additionalEmail : !!state.additionalEmail);

/**
 * Возвращает значение, показывающее были ли отредактированы поля нарушения
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableSpaceOwnerState, original?: ISpaceOwner): boolean =>
	isEntityEdited(
		state,
		original,
		isNameEdited,
		isPhoneEdited,
		isEmailEdited,
		isAdditionalPhoneEdited,
		isCommentEdited,
		isAdditionalEmailEdited
	);

/**
 * Функции проверки полей на ошибки
 */
const regEmail = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
const regPhone = /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/;

export const errorsFns = {
	name: (state: IEditableSpaceOwnerState) => !state.name,
	phone: (state: IEditableSpaceOwnerState) => !!state.phone && !(state.phone && regPhone.test(state.phone)),
	additionalPhone: (state: IEditableSpaceOwnerState) =>
	!!state.additionalPhone && !(state.additionalPhone && regPhone.test(state.additionalPhone)),
	comment: (state: IEditableSpaceOwnerState) => !state.comment,
	email: (state: IEditableSpaceOwnerState) =>
		!!state.email && !(state.email && regEmail.test(state.email)),
	additionalEmail: (state: IEditableSpaceOwnerState) =>
		!!state.additionalEmail && !(state.additionalEmail && regEmail.test(state.additionalEmail))
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableSpaceOwnerState,
	original?: ISpaceOwner,
	onlyEdited?: boolean
): ISavingSpaceOwner => {
	if (!onlyEdited) {
		return {
			name: state.name,
			phone: state.phone,
			additionalPhone: state.additionalPhone,
			comment: state.comment,
			email: state.email,
			additionalEmail: state.additionalEmail
		};
	}
	const owner: ISavingSpaceOwner = {};

	if (isNameEdited(state, original)) {
		owner.name = state.name;
	}
	if (isPhoneEdited(state, original)) {
		owner.phone = state.phone ? state.phone : null;
	}
	if (isAdditionalPhoneEdited(state, original)) {
		owner.additionalPhone = state.additionalPhone ? state.additionalPhone : null;
	}
	if (isCommentEdited(state, original)) {
		owner.comment = state.comment ? state.comment : null;
	}
	if (isEmailEdited(state, original)) {
		owner.email = state.email ? state.email : null;
	}
	if (isAdditionalEmailEdited(state, original)) {
		owner.additionalEmail = state.additionalEmail ? state.additionalEmail : null;
	}
	return owner;
};
