import {EntityGridItem, PhotoFeed} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IMobileImagesProps {
	images: string[];

	onClick?: (index: number) => void;
}

const MobileImages = ({images, onClick}: IMobileImagesProps) => {
	const {t} = useTranslation();

	return (
		<EntityGridItem
			className={{content: 'inspection-page__m-images-content'}}
			icon={<i className="tz-photo-20" />}
			label={t('taskViewPage.info.mobileImages')}
			fullRow
		>
			<PhotoFeed
				className={{root: 'inspection-page__m-images', photo: 'inspection-page__m-image'}}
				data={images}
				onClick={onClick}
			/>
		</EntityGridItem>
	);
};

export default MobileImages;
