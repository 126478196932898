import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {useTaskTypesAsArray} from '@src/core/hooks/queries/taskTypes';
import {useTranslation} from 'react-i18next';

export const TasksTypesFilterPage = () => {
	const {t} = useTranslation();
	const {entity} = useStrictParams<{entity: string}>();
	const {data: types} = useTaskTypesAsArray();

	if (!types) return null;

	return (
		<StatusesFilterPage
			entity={entity}
			statuses={types}
			filterName="types"
			label={t('entitiesFilters.tasksTypesFilter.label')}
		/>
	);
};
