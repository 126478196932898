import { ErrorInfo, ReactNode } from 'react';

export const formErrorMessage = (error: Error, errorInfo?: ErrorInfo): ReactNode => (
	<>
		{error?.name && (
			<div className="error-message__dialog-container">
				<div className="error-message__dialog-container-title">Название:</div>
				<div className="error-message__dialog-container-text">{error?.name}</div>
			</div>
		)}

		{error?.message && (
			<div className="error-message__dialog-container">
				<div className="error-message__dialog-container-title">Сообщение:</div>
				<div className="error-message__dialog-container-text">{error.message}</div>
			</div>
		)}

		{error?.cause && (
			<div className="error-message__dialog-container">
				<div className="error-message__dialog-container-title">Причина:</div>
				<div className="error-message__dialog-container-text">{error?.cause as string}</div>
			</div>
		)}

		{error?.stack && (
			<div className="error-message__dialog-container">
				<div className="error-message__dialog-container-title">Описание:</div>
				<div className="error-message__dialog-container-text">{error?.stack}</div>
			</div>
		)}

		{errorInfo?.componentStack && (
			<div className="error-message__dialog-container">
				<div className="error-message__dialog-container-title">Описание компонента:</div>
				<div className="error-message__dialog-container-text">{errorInfo.componentStack}</div>
			</div>
		)}
	</>
);
