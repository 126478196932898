import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import {deleteInspection} from '@src/store/modules/entities/inspection/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useTranslation} from 'react-i18next';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: ICheckEntity[];
	clearSelection?: () => void;
}

const deleteIcon = <i className="tz-delete" />;

const SelectedEntitiesDeletion = ({
	selectedEntities,
	clearSelection
}: ISelectedEntitiesDeletionProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		t('useConfirmDialog.selectedEntities.deleteTitle'),
		t('useConfirmDialog.selectedEntities.deleteMessage'),
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('useConfirmDialog.rejectBtnLabel')
		}
	);

	const [, handleClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			const objectsIds: string[] = [];
			for (const {type, data} of selectedEntities) {
				switch (type) {
					case 'problem': {
						const {objectId, id} = data;
						await dispatch(deleteProblem(objectId, id));
						objectsIds.push(data.objectId);
						break;
					}
					case 'inspection': {
						const {objectId, id} = data;
						await dispatch(deleteInspection(objectId, id));
						break;
					}
				}
			}
			if (objectsIds.length > 0) {
				await queryClient.refetchQueries({
					queryKey: [...objectsQueryKeys.stats(), objectsIds]
				});
			}
			if (clearSelection) {
				clearSelection();
			}
		}
	}, [selectedEntities, clearSelection]);

	return (
		<>
			<IconButton onClick={handleClick}>
				{deleteIcon}
			</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
