import {memo} from 'react';
import {IConvertedCheckListData} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';
import {Hatch} from './Hatch';
import {isHatchVisible} from '../utils/isHatchVisible';
import {getBorderColor} from '../utils/getBorderColor';
import {getSegments} from '../utils/getSegments';
import {StatsBorderCircle} from '../../components/StatsBorderCircle';

interface ICheckListsDiagramProps {
	width: number;
	height: number;
	radius: number;
	spaceId?: string;
	checkLists: IConvertedCheckListData[] | undefined;
}

export const CheckListsDiagram = memo(
	({checkLists, width, height, radius, spaceId}: ICheckListsDiagramProps) => {
		const segments = getSegments(checkLists);
		const segmentsCount = segments.length === 0 ? 1 : segments.length;
		// длина окружности
		const L = Math.PI * 2 * radius;
		// длина границы одного сегмента
		const x = L / segmentsCount;
		// остаток от сегмента
		const y = L - x;
		// координаты центра
		const cx = width - 10 - radius;
		const cy = height - 10 - radius;

		return (
			<>
				{/* цвет штриховки */}
				<Hatch id="hatch" />

				<circle
					cx={cx}
					cy={cy}
					r={radius}
					fill="#E8EAEC"
					stroke="#E8EAEC"
					strokeWidth="3"
				/>

				{segments.map((item, index) => (
					<circle
						// eslint-disable-next-line react/no-array-index-key
						key={`${spaceId}${index}`}
						cx={cx}
						cy={cy}
						r={radius / 2}
						fill="transparent"
						stroke={isHatchVisible(item) ? 'url(#hatch)' : item.color}
						strokeWidth={radius}
						strokeDasharray={`${(x / 2).toString()} ${(y / 2).toString()}`}
						strokeDashoffset={(L - index * x + L / 4) / 2}
					/>
				))}

				{checkLists &&
					checkLists.length > 1 &&
					checkLists?.map((item, index) => (
						<line
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							x1={cx}
							y1={cy}
							x2={
								cx +
								radius *
									Math.cos(
										(Math.PI * 2 * -(item.startPoint ?? 0)) / 100 - Math.PI / 2
									)
							}
							y2={
								cy +
								radius *
									Math.sin(
										(Math.PI * 2 * -(item.startPoint ?? 0)) / 100 - Math.PI / 2
									)
							}
							stroke="#c4d1e3"
							strokeWidth="2"
						/>
					))}

				{checkLists?.map(item => (
					<StatsBorderCircle
						key={item.key}
						width={width}
						height={height}
						radius={radius}
						item={item}
						color={getBorderColor(item.status)}
					/>
				))}
			</>
		);
	}
);
