export enum TaskStatusId {
	OPENED = 'opened',
	ASSIGNED = 'assigned',
	WIP = 'work-in-progress',
	REJECTED = 'rejected',
	ACCEPTED = 'accepted'
}

export interface ITaskStatus {
	id: TaskStatusId;
	name: string;
	color?: string;
	textColor?: string;
	scheduleTableColor: string;
	order?: number;
}
