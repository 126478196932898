import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import {format} from 'date-fns';
import { memo } from 'react';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {isBlockVisible} from '../../utils/isBlockVisible';
import classNames from 'classnames';
import {FixDate} from '../FixDate';

interface IDatesBlockProps {
	contract: IPreparedContract;
}

const signingDateIcon = <i className="tz-calendar-done-20" />;
const startDateIcon = <i className="tz-calendar-20" />;

const visibilityFields: Array<keyof IPreparedContract> = ['signingDate', 'startDate', 'endDate'];

export const DatesBlock = memo(({contract}: IDatesBlockProps) =>
	isBlockVisible(contract, visibilityFields) ? (
		<div className="contract__blocks-dates">
			<div className="contract__blocks-dates__header">Даты</div>

			<EntityGrid
				className={{
					wrap: classNames('contract__blocks-dates__container', 'contract__cells')
				}}
				withBorders
			>
				{contract.signingDate && (
					<EntityGridItem
						className={{
							root: 'contract__cells-item',
							header: 'contract__cells-item-header',
							content: 'contract__cells-item-content'
						}}
						label="Заключение договора"
						icon={signingDateIcon}
						inline
					>
						{format(contract.signingDate, dateFormat)}
					</EntityGridItem>
				)}

				{contract.startDate && (
					<EntityGridItem
						className={{
							root: 'contract__cells-item',
							header: 'contract__cells-item-header',
							content: 'contract__cells-item-content'
						}}
						label="Начало работ"
						icon={startDateIcon}
						inline
					>
						{format(contract.startDate, dateFormat)}
					</EntityGridItem>
				)}

				{contract.endDate && <FixDate plannedFixDate={contract.endDate} />}
			</EntityGrid>
		</div>
	) : null
);
