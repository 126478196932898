import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import { memo, useState } from 'react';
import MobileSelectionClearing from '../selection/SelectionClearing.mobile';
import MobileRightButtons from '../actions/RightButtons.mobile';
import {Plate} from '@tehzor/ui-components';
import {EntitiesTabs} from '../EntitiesTabs';
import {Table} from './Table';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {useMobileColumns} from './columns.mobile';
import {useSpaceSectionsMenu} from '@src/core/hooks/useSpaceSectionsMenu';
import {useSpaceStage} from '@src/core/hooks/spaces/useSpaceStage';

interface MobileTableProps {
	objectId: string;
	spaceId: string;
	space?: ISpace;
}

export const MobileTable = memo((props: MobileTableProps) => {
	const {objectId, spaceId, space} = props;
	const spaceTitle = useSpaceTitle(space);
	const stage = useSpaceStage(objectId, space);
	const sections = useSpaceSectionsMenu(objectId, spaceId, stage);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const mobileColumns = useMobileColumns(objectId, spaceId);

	useAppHeader(
		{
			title: spaceTitle,
			showBackBtn: true,
			sectionsMenu: sections,
			mobileLeftButton:
				selectedRows.length > 0 ? (
					<MobileSelectionClearing onSelectedRowsChange={setSelectedRows} />
				) : undefined,
			mobileRightButtons: space ? (
				<MobileRightButtons
					objectId={objectId}
					spaceId={spaceId}
					space={space}
					selectedRows={selectedRows}
				/>
			) : null
		},
		[space, selectedRows, sections]
	);

	return (
		<Plate withoutPadding>
			<EntitiesTabs
				objectId={objectId}
				spaceId={spaceId}
			/>

			<Table
				objectId={objectId}
				columns={mobileColumns}
				hideHead
				spaceId={spaceId}
				selectedRows={selectedRows}
				onSelectedRowsChange={setSelectedRows}
			/>
		</Plate>
	);
});
