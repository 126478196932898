import { useCallback, useState } from 'react';
import * as React from 'react';
import {StructureSelectDialog} from './StructureSelectDialog';

export const useStructureSelectDialog = (
	structures: Array<{
		id: string;
		name: string;
		type: string;
	}>,
	onSuccess: ({
		structureId,
		spaceId
	}: {
		structureId?: string;
		spaceId?: string;
	}) => void | Promise<void>,
	entityAddingDialog?: React.ReactNode | Record<string, never> | null,
	value?: string
): [JSX.Element, () => void] => {
	const [structureSelectDialogIsOpen, setStructureSelectDialogOpen] = useState<boolean>(false);

	const openStructureSelectDialog: () => void = useCallback(() => {
		setStructureSelectDialogOpen(true);
	}, []);

	const closeStructureSelectDialog = useCallback(() => {
		setStructureSelectDialogOpen(false);
	}, []);

	const handleSuccess = (val: string) => {
		closeStructureSelectDialog();
		void onSuccess({structureId: val});
	};

	const structureSelectDialog = (
		<>
			{structureSelectDialogIsOpen && (
				<StructureSelectDialog
					structures={structures}
					isOpen={structureSelectDialogIsOpen}
					value={value}
					onClose={closeStructureSelectDialog}
					onSuccess={handleSuccess}
				/>
			)}
			{entityAddingDialog}
		</>
	);

	return [structureSelectDialog, openStructureSelectDialog];
};
