import {PageHeader} from '@tehzor/ui-components';
import {useChecksQuery} from '@src/core/hooks/queries/checks/hooks';
import {useTranslation} from 'react-i18next';

interface IProblemsPageHeaderProps {
	objectId?: string;
}

const ChecksPageCounter = ({objectId = 'all'}: IProblemsPageHeaderProps) => {
	const {t} = useTranslation();
	const {data: checksData} = useChecksQuery(objectId);

	return (
		<PageHeader
			className="checks-page__header"
			title=""
			subTitle={t('checksPage.checksPageCounter.subTitle', {count: checksData?.total ?? 0})}
			wrapClassName="checks-page__header-wrap"
			leftSideClassName="checks-page__header-left"
			rightSideClassName="checks-page__header-right"
		/>
	);
};

export default ChecksPageCounter;
