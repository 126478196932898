import {format} from 'date-fns';

interface IAutomaticActionInfoProps {
	createdAt?: number;
	labelTitle?: string;
	labelIn?: string;
	dateFormat?: string;
	dateOptions?: Parameters<typeof format>[2];
}

export const AutomaticActionInfo = (props: IAutomaticActionInfoProps) => {
	const {
		createdAt,
		labelTitle = 'Автоматически назначен ответственный',
		labelIn = 'в',
		dateFormat = 'HH:mm',
		dateOptions
	} = props;

	return (
		<div className="delegation-history-item__info">
			<div className="delegation-history-item__info-automatic-action-icon">
				<i className="tz-reload-20" />
			</div>
			<div className="delegation-history-item__info-action">
				{labelTitle}
				{createdAt !== undefined ? (
					<>
						{' '}
						{labelIn}
						{' '}
						{format(createdAt, dateFormat, dateOptions)}
					</>
				) : null}
			</div>
		</div>
	);
};
