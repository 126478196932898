import {makeInternalAcceptanceAddRequest} from '@src/api/backend/internalAcceptance';
import {
	addInternalAcceptanceActions,
	deleteInternalAcceptanceActions
} from '@src/store/modules/entities/internalAcceptance/actions';
import {useQueryClient} from '@tanstack/react-query';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {useAddChildren} from '../../hooks/useAddChildren';
import {internalAcceptancesQueryKeys} from '../keys';
import {updateCachedState} from '@src/utils/updateCachedState';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {spacesQueryKeys} from '../../spaces/keys';

export interface IAddInternalAcceptanceParams {
	key: string;
	objectId: string;
	links: IInternalAcceptance['links'] | undefined;
	stage?: ObjectStageIds;
}
/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useInternalAcceptancesMutationDefaults = () => {
	const queryClient = useQueryClient();
	const [addProblems, addInspections] = useAddChildren();
	const {updateEntity} = useUpdateEntity(internalAcceptancesQueryKeys);
	queryClient.setMutationDefaults(internalAcceptancesQueryKeys.add(), {
		mutationFn: async (params: IAddInternalAcceptanceParams) => {
			const {objectId, links, stage, key} = params;
			await updateCachedState<IInternalAcceptance>(
				internalAcceptancesQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			return makeInternalAcceptanceAddRequest(objectId, links, stage, key);
		},
		onSuccess: async (
			newInternalAcceptance: IInternalAcceptance,
			{key}: IAddInternalAcceptanceParams
		) => {
			await updateCachedState<IInternalAcceptance>(
				internalAcceptancesQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			addProblems(key, newInternalAcceptance.id, 'internalAcceptanceId');
			addInspections(key, newInternalAcceptance.id, 'internalAcceptanceId');
			await queryClient.invalidateQueries({queryKey: internalAcceptancesQueryKeys.lists()});
			/**
			 * TODO
			 * Переделать реестры с redux на cache
			 * убрать обновление списков
			 * добавить локальные списки
			 */
			updateEntity<IInternalAcceptance>(
				newInternalAcceptance,
				deleteInternalAcceptanceActions.success,
				addInternalAcceptanceActions.success,
				key
			);
			await queryClient.refetchQueries({
				queryKey: internalAcceptancesQueryKeys.localList()
			});
			await queryClient.invalidateQueries({
				queryKey: internalAcceptancesQueryKeys.latest()
			});
			await queryClient.invalidateQueries({queryKey: spacesQueryKeys.internalAcceptances()});
		},
		onError: async (_, {key}: IAddInternalAcceptanceParams) => {
			await updateCachedState<IInternalAcceptance>(
				internalAcceptancesQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
		}
	});
};
