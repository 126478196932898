import {IGetListInspectionsResponse, requestListInspections} from '@src/api/backend/inspections';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {HttpStatus} from '@tehzor/tools/enums/HttpStatus';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../../constants/inspections';

export type IGetInspectionsPayload = IGetListInspectionsResponse;

const request = () => ({
	type: types.GET_REQUEST
});

const success = (response: IGetListInspectionsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response
	} as IGetInspectionsPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== HttpStatus.UNAUTHORIZED) {
		addErrorToast('Ошибка', 'при загрузке списка осмотров');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список осмотров для чек-листов
 */
export const getInspections =
	(
		checkListIds: string[],
		spaceId?: string,
		workAcceptanceIds?: string[]
	): AppThunkAction<Promise<IGetListInspectionsResponse>> =>
		dispatch =>
			dispatch(
				createApiAction<IGetListInspectionsResponse>(request, success, failure, () => {
					const filters: Record<string, unknown> = {checkListIds};
					if (spaceId) {
						filters.spaces = [spaceId];
					}
					if (workAcceptanceIds?.length) {
						filters.workAcceptanceIds = workAcceptanceIds;
					}
					return requestListInspections(filters, {createdAt: false});
				})
			);
