import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';

export const categoriesMigrationBreadcrumbs: IBreadcrumb[] = [
	{
		label: 'Наборы видов работ',
		url: '/manage/categories-sets'
	},
	{
		label: 'Миграция видов работ',
		url: '/manage/categories-sets/migration'
	}
];
