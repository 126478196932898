import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Удаляет задачу
 *
 * @param taskId id задачи
 */
export const makeTaskDeleteRequest = async (taskId: string) => {
	const response = await httpRequests.withToken.delete<void>(`tasks/${taskId}`);
	return response.data;
};
