import {useMemo} from 'react';
import './CheckListsSpace.less';
import classNames from 'classnames';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {SpaceDiagram} from './components/SpaceDiagram';
import {SpaceBlock} from './components/SpaceBlock';
import {ISpaceComponent} from '../interfaces/ISpaceComponent';
import {Indicators} from '../Indicators/Indicators';
import {CheckListPopupContent} from '../CheckListPopupContent/CheckListPopupContent';
import {HoverPopup} from '../../containers';
import {useIsTablet} from '../../../utils/mediaQueries';

const getPropsByType = (type: SpaceTypeId) => {
	switch (type) {
		case SpaceTypeId.COMMERCIAL_SPACES:
		case SpaceTypeId.UNDERGROUND_PARKING:
		case SpaceTypeId.GROUND_PARKING:
		case SpaceTypeId.STOREROOMS:
			return {
				width: 74 + 12 + 12,
				height: 46 + 12 + 12,
				radius: 20
			};
		case SpaceTypeId.COMMON_AREAS:
		case SpaceTypeId.TECHNICAL_SPACES:
		case SpaceTypeId.TECHNICAL_UNDERGROUND:
		case SpaceTypeId.SYSTEMS:
		case SpaceTypeId.REST:
			return {
				width: 298 + 12 + 12,
				height: 46 + 12 + 12,
				radius: 20
			};
		case SpaceTypeId.APARTMENTS:
		case SpaceTypeId.FLATS:
		default:
			return {
				width: 52 + 10 + 10,
				height: 52 + 10 + 10,
				radius: 50
			};
	}
};

const declaredTypes = Object.values(SpaceTypeId);

export const CheckListsSpace = ({
	className,
	space,
	disabled,
	altNamesVisible,
	checkListPopupContentSpaceNameTitle,
	checkListPopupContentTranslationArr,
	onClick
}: ISpaceComponent) => {
	const isTablet = useIsTablet();
	const classes = classNames(className, 'check-lists-space', `check-lists-space_${space.type}`, {
		'check-lists-space_disabled': disabled,
		'check-lists-space_default': !declaredTypes.includes(space.type)
	});

	const props = getPropsByType(space.type);

	const name = useMemo(
		() => (altNamesVisible ? space.altName ?? '' : space.name),
		[space.altName, space.name, altNamesVisible]
	);

	const SpaceComponent =
		space.type === SpaceTypeId.APARTMENTS || space.type === SpaceTypeId.FLATS ? (
			<SpaceDiagram key={space.id} {...props} space={space} name={name} onClick={onClick} />
		) : (
			<SpaceBlock
				key={space.id}
				{...props}
				space={space}
				name={name}
				onClick={onClick}
			/>
		);

	return (
		<div className={classes}>
			{space && space.checkLists && isTablet ? (
				<HoverPopup
					trigger={({ref}, hoverProps) => (
						<div
							ref={ref}
							{...hoverProps}
						>
							{SpaceComponent}
						</div>
					)}
					arrowVisible
				>
					<CheckListPopupContent
						space={space}
						spaceNameTitle={checkListPopupContentSpaceNameTitle}
						checkListTranslationArr={checkListPopupContentTranslationArr}
					/>
				</HoverPopup>
			) : (
				SpaceComponent
			)}

			<div className="space__indicators">
				{space.indicators?.length ? <Indicators indicators={space.indicators} /> : null}
			</div>
		</div>
	);
};
