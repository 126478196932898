export enum CheckRecordStatusId {
	// не осмотрено - записи CheckRecord не существует
	NOT_CHECKED = 'not-checked',
	WAITING = 'waiting',
	WIP = 'work-in-progress',
	PARTIALLY_ACCEPTED = 'partially-accepted',
	REJECTED = 'rejected',
	ACCEPTED = 'accepted'
}

export interface ICheckRecordStatus {
	id: CheckRecordStatusId;
	name: string;
	for: Array<'check-list' | 'check-item'>;
	transitions?: CheckRecordStatusId[];
	color: string;
	textColor: string;
}
