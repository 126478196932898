import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import isEqual from 'lodash/isEqual';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';

export type IEditableInspectionState = IEditableEntityState<{
	planId?: string;
	location?: ILocation;
	floor: string;
	description: string;
	categoryId?: string;
	attachments: IAttachment[];
	performers: string[];
	watchers: string[];
	performersActiveGroup?: string;
	initialRuleParams?: IRuleParams;
}>;

export type IEditableInspectionAction = IEditableEntityAction<
	IEditableInspectionState,
	ISavingInspection
>;

const makeEmptyState = (): IEditableInspectionState => ({
	planId: undefined,
	location: undefined,
	floor: '',
	description: '',
	categoryId: undefined,
	attachments: [],
	customFields: {},
	performers: [],
	watchers: [],
	performersActiveGroup: undefined,
	initialRuleParams: undefined,
	errors: {
		planId: false,
		location: false,
		floor: false,
		description: false,
		attachments: false,
		customFields: {},
		performers: false,
		watchers: false,
		performersActiveGroup: false,
		initialRuleParams: false
	},
	exceptions: {}
});

export const makeDefaultData = (inspection?: IInspection): ISavingInspection | undefined => {
	if (!inspection) {
		return undefined;
	}

	return {
		planId: inspection.plan?.id,
		location: inspection.location,
		floor: inspection.floor,
		description: inspection.description,
		categoryId: inspection.categoryId,
		attachments: inspection.attachments,
		performers: inspection.performers,
		performersActiveGroup: inspection.performersActiveGroup,
		customFields: inspection.customFields,
		watchers: inspection.watchers
	};
};

export const init = (original?: ISavingInspection): IEditableInspectionState => {
	const empty = makeEmptyState();
	if (!original) {
		return empty;
	}
	return {
		planId: original.planId ?? undefined,
		location: original.location ?? undefined,
		floor: original.floor ?? '',
		description: original.description ?? '',
		categoryId: original.categoryId ?? undefined,
		attachments: original.attachments ?? [],
		performers: original.performers ?? [],
		performersActiveGroup: original.performersActiveGroup ?? undefined,
		watchers: original.watchers ?? [],
		customFields: original.customFields,
		initialRuleParams: {
			planId: original.planId ?? undefined,
			categoryId: original.categoryId ?? undefined
		},
		errors: empty.errors,
		exceptions: empty.exceptions
	};
};

const isPlanEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	original?.planId ? original.planId !== state.planId : !!state.planId;

const isLocationEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	original?.location ? !isEqual(original.location, state.location) : !!state.location;

const areFloorEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	original?.floor ? original.floor !== state.floor : !!state.floor;

const isDescriptionEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	original?.description ? original.description !== state.description : !!state.description;

const isCategoryIdEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	original?.categoryId ? original.categoryId !== state.categoryId : !!state.categoryId;

const areAttachmentsEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	original?.attachments ? original.attachments.length !== state.attachments.length : false;

const arePerformersEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	original?.performers
		? original.performers.length !== state.performers.length ||
		  original.performers.some(id => !state.performers.includes(id))
		: !!state.performers.length;

const isPerformersActiveGroupEdited = (
	state: IEditableInspectionState,
	original?: ISavingInspection
) =>
	original?.performersActiveGroup
		? original.performersActiveGroup !== state.performersActiveGroup
		: !!state.performersActiveGroup;

const areWatchersEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	original?.watchers
		? original.watchers.length !== state.watchers.length ||
		  original.watchers.some(id => !state.watchers.includes(id))
		: !!state.watchers.length;

const areCustomFieldsEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	!isEqual(state.customFields, original?.customFields);
/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableInspectionState, original?: ISavingInspection): boolean =>
	isEntityEdited(
		state,
		original,
		isPlanEdited,
		isCategoryIdEdited,
		isLocationEdited,
		areFloorEdited,
		isDescriptionEdited,
		areAttachmentsEdited,
		arePerformersEdited,
		isPerformersActiveGroupEdited,
		areWatchersEdited,
		areCustomFieldsEdited
	);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	planId: (state: IEditableInspectionState) => !state.planId,
	location: (state: IEditableInspectionState) => !state.location,
	floor: (state: IEditableInspectionState) => !state.floor,
	description: (state: IEditableInspectionState) => !state.description,
	categoryId: (state: IEditableInspectionState) => !state.categoryId,
	attachments: (state: IEditableInspectionState) => !state.attachments.length,
	performers: (state: IEditableInspectionState) =>
		state.exceptions?.performers
			? false
			: !state.performers.length && !state.performersActiveGroup,
	performersActiveGroup: (state: IEditableInspectionState) =>
		state.exceptions?.performers ? false : !state.performersActiveGroup,
	watchers: (state: IEditableInspectionState) => !state.watchers.length,
	customFields: (state: IEditableInspectionState, key?: string) => {
		if (!key) {
			return false;
		}
		const value = state.customFields?.[key];
		if (Array.isArray(value)) {
			return !value.length;
		}
		return !value;
	}
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableInspectionState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableInspectionState,
	original?: ISavingInspection,
	onlyEdited?: boolean
): ISavingInspection => {
	if (!onlyEdited) {
		return {
			planId: state.planId,
			location: state.location || null,
			floor: state.floor,
			description: state.description,
			categoryId: state.categoryId,
			attachments: state.attachments,
			performers: state.performers,
			performersActiveGroup: state.performersActiveGroup,
			watchers: state.watchers,
			customFields: state.customFields
		};
	}
	const inspection: ISavingInspection = {};
	if (isPlanEdited(state, original)) {
		inspection.planId = state.planId || null;
	}
	if (isLocationEdited(state, original)) {
		inspection.location = state.location || null;
	}
	if (areFloorEdited(state, original)) {
		inspection.floor = state.floor || undefined;
	}
	if (isDescriptionEdited(state, original)) {
		inspection.description = state.description;
	}
	if (isCategoryIdEdited(state, original)) {
		inspection.categoryId = state.categoryId || null;
	}
	if (areAttachmentsEdited(state, original)) {
		inspection.attachments = state.attachments.map(item => ({id: item.id}));
	}
	if (areCustomFieldsEdited(state, original)) {
		inspection.customFields = state.customFields;
	}
	if (arePerformersEdited(state, original) || isPerformersActiveGroupEdited(state, original)) {
		inspection.performers = state.performers;
		inspection.performersActiveGroup = state.performersActiveGroup;
	}
	if (areWatchersEdited(state, original)) {
		inspection.watchers = state.watchers;
	}
	return inspection;
};
