import {ITwoWayTreeItem} from '../../interfaces/ITwoWayTreeItem';

/**
 * Формирует список ключей всех элементов дерева, которые могут участвовать в выборе
 *
 * @param data данные
 */
export const getAllTreeKeys = <T extends {id: string, nonCheckable?: boolean}>(
	data: Array<ITwoWayTreeItem<T>>
): string[] =>
	data.reduce((prev, item) => {
		if (!item.nonCheckable) {
			prev.push(item.id);
		}
		if (item.children) {
			prev = prev.concat(getAllTreeKeys(item.children));
		}
		return prev;
	}, [] as string[]);
