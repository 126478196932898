/* eslint-disable @typescript-eslint/ban-types */
import { useCallback } from 'react';

import * as React from 'react';
import './MobileFiltersLinkBtn.less';
import {IconButton} from '@tehzor/ui-components';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IMobileFiltersLinkBtnProps<F> {
	className?: string;
	style?: React.CSSProperties;
	objectId?: string;
	entity: string;
	state: F;
}

export const MobileFiltersLinkBtn = <F extends object>(props: IMobileFiltersLinkBtnProps<F>) => {
	const {className, style, objectId = 'all', entity, state} = props;
	const isFiltersActive = useFiltersActive<F>(objectId, state);
	const {search} = useLocation();
	const {pushPath} = useChangePath();

	const goToFilters = useCallback(() => {
		pushPath(
			objectId !== 'all'
				? `/objects/${objectId}/${entity}/filters${search}`
				: `/${entity}/filters${search}`,
			{state: {canGoBack: true}}
		);
	}, [objectId, entity, search]);

	return (
		<IconButton
			className={classNames('mobile-filters-link-btn', className)}
			style={style}
			type="common"
			onClick={goToFilters}
		>
			<i className="tz-filters-24"/>
			{isFiltersActive && <div className="mobile-filters-link-btn__mark"/>}
		</IconButton>
	);
};
