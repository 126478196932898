import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import getEmailsArray from "@src/utils/getEmailsArray";

/**
 * Отправляет запрос на экспорт осмотра
 */
export const makeInspectionExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	inspectionId: string,
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId,
		inspectionId
	};
	const response = await httpRequests.exportWithToken.post<IExportResponse>(
		`inspections/export/${inspectionId}`,
		params
	);
	return response.data;
};
