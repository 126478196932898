import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IProblemViewsListQueryKey, problemViewsQueryKeys} from '../keys';
import {requestProblemViews} from '@src/api/backend/problemViews';

export const useProblemViewsQueryDefault = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(problemViewsQueryKeys.list(), {
		queryFn: ({queryKey}: QueryFunctionContext<IProblemViewsListQueryKey>) => {
			const [, , objectId, problemId] = queryKey;
			return requestProblemViews(objectId, problemId);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 день
	});
};
