import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {fieldSettingsQueryKeys} from '@src/api/cache/fieldSettings/keys';
import {fieldSettingsSetsQueryKeys} from '@src/api/cache/fieldSettingsSets/keys';
import {fieldsQueryKeys} from '@src/api/cache/fields/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {regulatoryStandardsQueryKeys} from '@src/api/cache/regulatoryStandards/keys';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';

export const keysPermissionMapping = (roles?: IUserRole[]) => {
	if (!roles) {
		return [];
	}
	const {
		checkRecordStatuses,
		objectStages,
		ownerAcceptanceResolutions,
		ownerAcceptanceStatuses,
		problemStatuses,
		spaceIndicatorsSets,
		spaceTypes,
		users,
		warrantyClaimStatuses,
		problemTemplatesSets
	} = restDictionariesQueryKeys;
	const fullMap = [
		hasPermission(roles, 'categories-view') && categoriesQueryKeys.list(),
		hasPermission(roles, 'categories-view') && categoriesSetsQueryKeys.list(),
		hasPermission(roles, 'check-lists-view') && checkListsQueryKeys.list(),
		hasPermission(roles, 'check-items-view') && checkItemsQueryKeys.list(),
		checkListsQueryKeys.types(),
		checkRecordStatuses(),
		checksQueryKeys.latest(),
		internalAcceptancesQueryKeys.latest(),
		warrantyClaimsQueryKeys.latest(),
		ownerAcceptancesQueryKeys.latest(),
		objectStages(),
		ownerAcceptanceResolutions(),
		ownerAcceptanceStatuses(),
		hasPermission(roles, 'plansView') && plansQueryKeys.lists(),
		problemStatuses(),
		hasPermission(roles, 'problem-templates-sets-view') && problemTemplatesSets(),
		regulatoryStandardsQueryKeys.list(),
		hasPermission(roles, 'responsibility-rules-view') && responsibilityRulesQueryKeys.list(),
		hasPermission(roles, 'spaceIndicatorsSetsView') && spaceIndicatorsSets(),
		hasPermission(roles, 'space-owners-view') && spaceOwnersQueryKeys.list(),
		hasPermission(roles, 'spaceStatusesSetsView') && spaceStatusesSetsQueryKeys.list(),
		hasPermission(roles, 'spaceStatusesSetsView') && spaceStatusesQueryKeys.list(),
		spaceTypes(),
		users(),
		warrantyClaimStatuses(),
		hasPermission(roles, 'working-groups-view') && workingGroupsQueryKeys.list(),
		hasPermission(roles, 'fields-view') && fieldsQueryKeys.builtinLists(),
		hasPermission(roles, 'fields-view') && fieldsQueryKeys.customLists(),
		hasPermission(roles, 'fields-view') && fieldsQueryKeys.types.entityTypes(),
		hasPermission(roles, 'fields-view') && fieldsQueryKeys.types.valueTypes(),
		hasPermission(roles, 'fields-settings-view') && fieldSettingsQueryKeys.lists(),
		hasPermission(roles, 'fields-settings-sets-view') && fieldSettingsSetsQueryKeys.lists()
	];
	return fullMap.filter(item => !!item) as string[][];
};
