import {queryClient} from '@src/api/QueryClient';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {IListOwnerAcceptanceSavingData} from '@src/core/hooks/mutations/ownerAcceptances/utils/convertToLocalSave';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';

export const findParentCache = (links?: IProblem['links'] | IInspection['links']) => {
	let cache;
	if (links?.checkId) {
		cache = queryClient.getQueriesData<ICheck>({
			queryKey: checksQueryKeys.detail(links.checkId)
		});
	}
	if (links && 'ownerAcceptanceId' in links && links.ownerAcceptanceId) {
		cache = queryClient.getQueriesData<IListOwnerAcceptanceSavingData>({
			queryKey: ownerAcceptancesQueryKeys.savingData(links.ownerAcceptanceId)
		});
	}
	if (links && 'warrantyClaimId' in links && links.warrantyClaimId) {
		cache = queryClient.getQueriesData<IListWarrantyClaim>({
			queryKey: warrantyClaimsQueryKeys.detail(links.warrantyClaimId)
		});
	}
	if (links?.internalAcceptanceId) {
		cache = queryClient.getQueriesData<IListInternalAcceptance>({
			queryKey: internalAcceptancesQueryKeys.detail(links.internalAcceptanceId)
		});
	}
	if (cache && cache.length) {
		return !!cache[0][1];
	}
	return false;
};
