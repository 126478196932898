import { HTMLAttributes, memo } from 'react';
import {EntityInfoDate} from '../EntityInfoDate/EntityInfoDate';
import {FilesCount} from './FilesCount';
import {Locale} from 'date-fns';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';

interface IEntityInfoDateWithCountFilesProps<T> extends HTMLAttributes<HTMLDivElement> {
	data?: T;
	labelFrom?: string;
	depth?: number;
	count?: number;
	createdAt?: number;
	withoutCount?: boolean;
	locale?: Locale;
}

export const EntityInfoDateWithCountFiles = memo(
	<T extends {attachments?: IAttachment[], createdAt?: number}>(
		props: IEntityInfoDateWithCountFilesProps<T>
	) => {
		const {
			data,
			labelFrom = 'от',
			depth = 0,
			count,
			createdAt,
			withoutCount = false,
			locale,
			...restProps
		} = props;

		if (!data && !count && !createdAt) return null;

		return (
			<div {...restProps}>
				{depth === 0 && (
					<EntityInfoDate
						labelFrom={labelFrom}
						locale={locale}
						value={data?.createdAt ?? createdAt}
					/>
				)}
				{!withoutCount && <FilesCount count={data?.attachments?.length ?? count}/>}
			</div>
		);
	}
);
