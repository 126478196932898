import { memo } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {DesktopMainActions} from './actions/DesktopMainActions';
import {LegalActions} from './actions/LegalActions';
import {useLegalsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useLegalsBreadcrumbs';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

interface IPageBreadcrumbsProps {
	legal: ILegalEntity;
}

export const PageBreadcrumbs = memo(({legal}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useLegalsBreadcrumbs(legal);
	const isDesktop = useIsDesktop();

	return (
		<AppBreadcrumbs
			className="legal-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && <DesktopMainActions legal={legal} />}
			{isDesktop && <LegalActions legal={legal} />}
		</AppBreadcrumbs>
	);
});
