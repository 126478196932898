import {formStructureCheckListLink} from './formStructureCheckListLink';

interface Args {
	objectId: string;
	listId: string;
	itemId: string;
	structureId: string;
}

/**
 * Формирует ссылку на категорию чек-листа
 *
 * @param itemId id категории
 * @param params
 */
export const formStructureCheckItemLink = ({itemId, ...params}: Args) =>
	`${formStructureCheckListLink(params)}/items/${itemId}`;
