import {useMutation} from '@tanstack/react-query';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {makeExportTemplateAddRequest} from '@src/api/backend/exportTemplates/add';
import {ISavingExportTemplate} from '@src/interfaces/saving/ISavingExportTemplate';
import {queryClient} from '@src/api/QueryClient';
import {exportTemplatesQueryKeys} from '@src/api/cache/exportTemplates/keys';

export const useAddExportTemplate = () =>
	useMutation({
		mutationKey: exportTemplatesQueryKeys.add(),
		mutationFn: (fields: ISavingExportTemplate) => makeExportTemplateAddRequest(fields),
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении шаблона');
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: exportTemplatesQueryKeys.list()});
			addSuccessToast('Успешно', 'шаблон создан');
		}
	});
