import {memo, useMemo, useState} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useExtractSpaceTypesAsArray} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

interface ISpaceTypesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpaceTypesFilterMobile = memo((props: ISpaceTypesFilterMobileProps) => {
	const {t} = useTranslation('');

	const [search, setSearch] = useState('');
	const {data: spaceTypes} = useExtractSpaceTypesAsArray();

	const translatedOptions = useTranslatedDictionaryArray(dictionaryKeys.spaceTypes, spaceTypes);
	const filteredData = useMemo(() => {
		if (!translatedOptions) {
			return null;
		}
		return flatFilter(translatedOptions, 'name', search);
	}, [translatedOptions, search]);

	if (!filteredData) {
		return null;
	}
	return (
		<BaseListFilterMobile
			{...props}
			options={filteredData}
			label={t('spacesFiltersPage.spaceTypesFilterMobile.baseListFilterMobile.title')}
			filterName="types"
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
		/>
	);
});
