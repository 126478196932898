import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import getEmailsArray from "@src/utils/getEmailsArray";

/**
 * Отправляет запрос на экспорт помещения
 */
export const makeSpaceExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	spaceId: string,
	problems?: string[],
	inspections?: string[],
	warrantyClaims?: string[],
	checks?: string[],
	ownerAcceptances?: string[]
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId,
		spaceId,
		problems,
		inspections,
		warrantyClaims,
		checks,
		ownerAcceptances
	};
	const response = await httpRequests.exportWithToken.post<IExportResponse>(
		`spaces/export/${spaceId}`, params
	);
	return response.data;
};
