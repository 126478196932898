import { useCallback, useState } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureCheckItemLink} from '@tehzor/tools/utils/links';
import {LoadingPanel, MobilePagination, Plate} from '@tehzor/ui-components';
import {CheckListItem} from '../list/CheckListItems/components/CheckListItem';
import {ProblemsTable} from '@src/pages/CheckListPage/components/detail/ProblemsTable';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {
	useCheckItemsIsLoading,
	useExtractCheckItemById,
	useGetSiblings
} from '@src/core/hooks/queries/checkItems/hooks';

export const MobileItemDetail = () => {
	const {replacePath} = useChangePath();
	const params = useStrictParams<{
		listId: string;
		itemId: string;
		objectId: string;
		stage: string;
		structureId: string;
	}>();
	const {data: item} = useExtractCheckItemById(params.itemId);
	const {data: siblings} = useGetSiblings(item);
	const itemsLoading = useCheckItemsIsLoading();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const handlePageChange = useCallback(
		(nextIndex: number) => {
			if (siblings) {
				const next = siblings[nextIndex];
				replacePath(formStructureCheckItemLink({...params, itemId: next.id}));
			}
		},
		[params, replacePath, siblings]
	);

	if (!item) {
		return null;
	}

	const currentIndex = siblings?.findIndex(sibling => sibling.id === item?.id) ?? 0;

	return (
		<div className="structure-page__check-list__m-item-detail">
			<Plate
				withoutPadding
				className="structure-page__check-list__m-nav"
			>
				<MobilePagination
					className="spaces-page__m-pagination"
					page={currentIndex}
					pageCount={siblings?.length ?? 0}
					renderTitle={({page}) => `${page + 1} категория`}
					onPageChange={handlePageChange}
				/>
			</Plate>

			<LoadingPanel active={itemsLoading}>
				<Plate className="check-item-detail">
					<CheckListItem
						mode="detail"
						item={item}
					/>
					<hr
						className="structure-page__check-list__divider"
						style={{margin: '20px 0'}}
					/>
					<ProblemsTable
						item={item}
						selectedRows={selectedRows}
						onSelectedRowsChange={setSelectedRows}
					/>
				</Plate>
			</LoadingPanel>
		</div>
	);
};
