import { memo } from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useTranslation} from 'react-i18next';

interface IAdditionalNameProps {
	space: ISpace;
}

const nameIcon = <i className="tz-name-20" />;

export const AdditionalName = memo(({space}: IAdditionalNameProps) => {
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const {t} = useTranslation();

	return (
		<EntityGridItem
			label={
				altNamesVisible
					? t('spacePage.info.additionalName.main')
					: t('spacePage.info.additionalName.additional')
			}
			icon={nameIcon}
			fullRow
		>
			<span className="semi-bold">{altNamesVisible ? space.name : space.altName}</span>
		</EntityGridItem>
	);
});
