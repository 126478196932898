import {queryClient} from '@src/api/QueryClient';

export const deleteMutation = (itemKey: string) => {
	const mutationCache = queryClient.getMutationCache();
	const currentMutation = mutationCache.find<
		unknown,
		unknown,
		Record<string, unknown> & {key: string},
		unknown
	>({
		predicate: mut => {
			const key = (mut.state.variables as Record<string, unknown> & {key: string}).key;
			return itemKey === key;
		}
	});
	if (currentMutation) {
		void mutationCache.remove(currentMutation);
	}
};
