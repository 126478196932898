import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {CellProps} from 'react-table';
import {useTranslation} from 'react-i18next';
import {usePlan} from '@src/core/hooks/queries/plans/hooks';

const LocationCell = ({row}: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const {planId, floor, objectId} = row.original;
	const {data: plan} = usePlan(objectId?.id, planId, {enabled: !!objectId?.id});
	const {t} = useTranslation();

	return (
		<div className="tasks-page__d-table-location">
			{!!objectId && (
				<div className="tasks-page__d-table-location-object">{objectId.name}</div>
			)}

			{(planId || floor || location) && (
				<div className="tasks-page__d-table-location-block">
					{plan && (
						<div className="tasks-page__d-table-location-line">
							<span className="tasks-page__d-table-location-title">
								{t('tasksTablePage.locationCell.plan.title')}
							</span>
							<span className="tasks-page__d-table-location-subtitle">
								{plan.name}
							</span>
						</div>
					)}
					{floor && (
						<div className="tasks-page__d-table-location-line">
							<span className="tasks-page__d-table-location-title">
								{t('tasksTablePage.locationCell.floor.title')}
							</span>
							<span className="tasks-page__d-table-location-subtitle">{floor}</span>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default LocationCell;
