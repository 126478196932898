import {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useTranslation} from 'react-i18next';
import {useExtractSpaceStatusesAsArrayByObjectId} from '@src/core/hooks/queries/spaceStatusesSets/hooks';

interface ISpacesStatusesFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpacesStatusesFilterMobile = memo((props: ISpacesStatusesFilterMobileProps) => {
	const {t} = useTranslation();

	const {objectId} = props;
	const {data: statuses} = useExtractSpaceStatusesAsArrayByObjectId(objectId);

	if (!statuses) return null;

	return (
		<BaseListFilterMobile
			{...props}
			options={statuses}
			label={t('spacesFiltersPage.spacesStatusesFilterMobile.baseListFilterMobile.label')}
			filterName="statuses"
		/>
	);
});
