import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {IStructureDiagramData} from '@/widgets/StructuresSchema/model/interfaces';
import {convertDiagramMapToSortArray} from '@/widgets/StructuresSchema/model/utils/convertDiagramMapToSortArray';
import {useEnrichedFilteredSchemaStructures} from '@/features/Structures/useEnrichedStructures';
import {convertStatsToDiagramSectors} from '@tehzor/ui-components';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';

export const useSchemaItemsByWorkAcceptancesAndProblems = (objectId: string) => {
	const {data: structures, isLoading} = useEnrichedFilteredSchemaStructures({objectId});

	const {schemaStructureTypeId, schemaSortRevers} = useAppSelector(state =>
		extractStructuresSchemaPageSettings(state, objectId)
	);

	const schemaData = useMemo(() => {
		if (!structures) return undefined;

		const filteredByTypeStructures = structures.filter(
			({type}) => type === schemaStructureTypeId
		);

		const diagramDataMap: Map<string | undefined, IStructureDiagramData[]> = new Map();
		filteredByTypeStructures.forEach(structure => {
			const data: IStructureDiagramData = {
				id: structure.id,
				name: structure.name,
				filtered: structure.filtered,
				innerSectors: convertStatsToDiagramSectors(structure.workAcceptancesStats),
				outerSectors: convertStatsToDiagramSectors(structure.problemsStats)
			};
			const currentElement = diagramDataMap.get(structure.floor);
			if (currentElement) {
				diagramDataMap.set(structure.floor, [...currentElement, data]);
			} else {
				diagramDataMap.set(structure.floor, [data]);
			}
		});

		return convertDiagramMapToSortArray(diagramDataMap);
	}, [structures, schemaStructureTypeId]);

	const data = useMemo(() => {
		if (schemaSortRevers) return schemaData?.reverse();
		return schemaData;
	}, [schemaData, schemaSortRevers]);

	return {
		data,
		isLoading,
		total: structures?.length
	};
};
