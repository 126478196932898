import {makeProblemViewAddRequest} from '@src/api/backend/problemViews';
import {problemViewsQueryKeys} from '@src/api/cache/problemViews/keys';
import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {IProblemView} from '@tehzor/tools/interfaces/problemView/IProblemView';

export const useAddProblemView = () =>
	useMutation<IProblemView, Error, {objectId: string; problemId: string}>({
		mutationKey: problemViewsQueryKeys.add(),
		mutationFn: ({objectId, problemId}) => makeProblemViewAddRequest(objectId, problemId),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: problemViewsQueryKeys.list()});
		}
	});
