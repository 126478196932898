import {TFunction} from 'i18next';
import {measureUnitMap} from '@src/constants/translations/measureUnitMap';

export const getTranslatedMeasureUnit = (measureUnit: string, tFunc: TFunction) => {
	const translatedKey = measureUnitMap.get(measureUnit);
	if (translatedKey) {
		return tFunc(translatedKey);
	}
	return measureUnit;
};
