import {useQuery} from '@tanstack/react-query';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {IGetWorkAcceptanceResponse} from '@src/api/backend/workAcceptance';
import {IGetWorkAcceptancesResponse} from '@src/api/backend/workAcceptances';

export const useWorkAcceptance = <TResult = IGetWorkAcceptanceResponse>(
	workAcceptanceId?: string,
	objectId?: string,
	select?: (data: IGetWorkAcceptancesResponse) => TResult
) =>
	useQuery({
		queryKey: [...workAcceptancesQueryKeys.detail(workAcceptanceId), objectId],
		meta: {
			error: 'при загрузке приёмки работ'
		},
		select,
		enabled: !!workAcceptanceId && !!objectId
	});
