export const spaceOwnersQueryKeys = {
	all: () => ['space-owners'],
	one: () => ['spaceOwner'],
	list: () => [...spaceOwnersQueryKeys.all(), 'list'],
	localList: () => [...spaceOwnersQueryKeys.all(), 'local-list'],
	add: () => [...spaceOwnersQueryKeys.one(), 'add'],
	details: () => [...spaceOwnersQueryKeys.one(), 'detail'],
	detail: (spaceOwnerId: string) => [...spaceOwnersQueryKeys.one(), 'detail', spaceOwnerId],
	edit: () => [...spaceOwnersQueryKeys.one(), 'edit'],
	bind: () => [...spaceOwnersQueryKeys.one(), 'bind'],
	unbind: () => [...spaceOwnersQueryKeys.one(), 'unbind']
};
