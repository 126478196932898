import './PopupPlate.less';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import Popup, {IPopupProps} from '../Popup';

export const PopupPlate = ({className, arrowOffset = 28, ...props}: IPopupProps) => {
	const classes = convertClassNames(className);

	return (
		<Popup
			{...props}
			arrowOffset={arrowOffset}
			className={{
				root: classNames('popup-plate', classes.root),
				content: classNames('popup-plate__content', classes.content)
			}}
		/>
	);
};

PopupPlate.displayName = 'PopupPlate';
