import {useMemo} from 'react';
import {Column} from 'react-table';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import SpaceNameCell from '@src/components/tableCells/SpaceNameCell';
import SpaceStatusCell from '@src/components/tableCells/SpaceStatusCell';
import SpaceTypeCell from '@src/components/tableCells/SpaceTypeCell';
import SpaceStageCell from '@src/components/tableCells/SpaceStageCell';
import SpaceIndicatorsCell from '@src/components/tableCells/SpaceIndicatorsCell';
import ProblemsStatsCell from '@src/components/tableCells/ProblemsStatsCell';
import {useTranslation} from 'react-i18next';

export const useDesktopColumns = () => {
	const {t} = useTranslation();

	return useMemo<Array<Column<IConvertedSpace>>>(
		() => [
			{
				id: 'name',
				accessor: 'name',
				Header: t('spacesPage.table.columns.name'),
				Cell: SpaceNameCell,
				width: 110,
				minWidth: 80
			},
			{
				id: 'status',
				accessor: 'status',
				Header: t('spacesPage.table.columns.status'),
				Cell: SpaceStatusCell,
				width: 110,
				minWidth: 80
			},
			{
				id: 'type',
				accessor: 'type',
				Header: t('spacesPage.table.columns.type'),
				Cell: SpaceTypeCell,
				width: 80,
				minWidth: 80
			},
			{
				id: 'stage',
				Header: t('spacesPage.table.columns.stage'),
				Cell: SpaceStageCell,
				width: 70,
				minWidth: 70
			},
			{
				id: 'object',
				accessor: s => s.object?.name,
				Header: t('spacesPage.table.columns.object'),
				width: 110,
				minWidth: 80
			},
			{
				id: 'indicators',
				Header: t('spacesPage.table.columns.indicators'),
				Cell: SpaceIndicatorsCell,
				width: 100,
				minWidth: 80
			},
			{
				id: 'problems',
				Header: t('spacesPage.table.columns.problems'),
				Cell: ProblemsStatsCell,
				width: 100,
				minWidth: 80
			}
		],
		[t]
	);
};
