import {ILegalsFiltersState, ILegalsSortState} from '@src/store/modules/settings/pages/legals/reducers';

export type ILegalsListQueryKey = [] & {
	0: string;
	1: string;
	2: ILegalsFiltersState;
	3: ILegalsSortState;
	4: string[];
	5: number;
	6: number;
};

export const legalsQueryKeys = {
	all: () => ['legals'],
	one: () => ['legal'],
	list: () => [...legalsQueryKeys.all(), 'list'],
	localList: () => [...legalsQueryKeys.all(), 'local-list'],
	details: () => [...legalsQueryKeys.one(), 'detail'],
	savingData: (key: string) => [...legalsQueryKeys.one(), 'saving-data', key],
	detail: (legalId: string) => [...legalsQueryKeys.one(), 'detail', legalId],
	add: () => [...legalsQueryKeys.one(), 'add'],
	edit: () => [...legalsQueryKeys.one(), 'edit']
};