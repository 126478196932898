import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {
	IChecksFiltersState,
	IChecksPageSettingsState,
	IChecksPagesSettingsState,
	IChecksSortState
} from './interfaces';

const initialState: IChecksPagesSettingsState = {};

export const getInitialStateForPage = (objectId: string): IChecksPageSettingsState => ({
	tabIndex: 0,
	filters: {
		objects: objectId !== 'all' ? [objectId] : undefined
	},
	sort: {
		modifiedAt: false
	},
	pageSize: 20,
	offset: 0
});

export const checksSlice = createSlice({
	name: 'checks',
	initialState,
	reducers: {
		changeInitialStateByObjectId(state, action: PayloadAction<{objectId: string}>) {
			const {objectId} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
		},
		changeFilters(state, action: PayloadAction<IChangeFiltersPayload<IChecksFiltersState>>) {
			const {objectId, filters} = action.payload;

			if (objectId !== 'all') {
				filters.objects = [objectId];
			}

			state[objectId].filters = filters;
		},
		clearFilters(state, action: PayloadAction<IClearFiltersPayload>) {
			const {objectId} = action.payload;

			state[objectId].filters = {};

			if (objectId !== 'all') {
				state[objectId].filters = {objects: [objectId]};
			}
		},
		changeSort(state, action: PayloadAction<IChangeSortPayload<IChecksSortState>>) {
			const {objectId, sort} = action.payload;

			state[objectId].sort = sort;
		},
		changePageSize(state, action: PayloadAction<IChangePageSizePayload>) {
			const {objectId, pageSize} = action.payload;

			state[objectId].pageSize = pageSize;
		},
		changeOffset(state, action: PayloadAction<{objectId: string; offset: number}>) {
			const {objectId, offset} = action.payload;

			state[objectId].offset = offset;
		},
		changeSelectedRows(
			state,
			action: PayloadAction<{objectId: string; selectedRows: string[]}>
		) {
			const {objectId, selectedRows} = action.payload;

			state[objectId].selectedRows = selectedRows;
		}
	}
});

export const {actions: checksActions, reducer: checksReducer} = checksSlice;
