import { useMemo } from 'react';
import './AttachmentsChange.less';
import classNames from 'classnames';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import splitAttachments from '@tehzor/tools/utils/splitAttachments';
import PhotoPreview from '../../photos/PhotoPreview';
import {IChangeComponentProps} from '../interfaces/IChangeComponent';
import {DownloadableFile} from '../../files';

interface IAttachmentsChangeProps extends IChangeComponentProps {
	value?: IAttachment[];
}

export const AttachmentsChange = ({className, style, value}: IAttachmentsChangeProps) => {
	const [images, files] = useMemo(() => {
		if (value) {
			return splitAttachments(value);
		}
		return [[], []];
	}, [value]);

	const previews = useMemo(
		() =>
			images.reduce<string[]>((prev, item) => {
				if (item.preview) {
					prev.push(item.preview.url);
				}
				return prev;
			}, []),
		[images]
	);

	return (
		<div
			className={classNames('history-attachments-change', className)}
			style={style}
		>
			<div className="history-attachments-change__images">
				{previews.map((url, index) => (
					<PhotoPreview
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className="history-attachments-change__image"
						url={url}
						data={index}
					/>
				))}
			</div>
			{files.length > 0 && (
				<div className="history-attachments-change__files">
					{files.map(file => (
						<div
							key={file.id}
							className="history-attachments-change__file-wrap"
						>
							<DownloadableFile
								className="history-attachments-change__file"
								{...file}
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

AttachmentsChange.displayName = 'AttachmentsChange';
