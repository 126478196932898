import PageBreadcrumbs from './PageBreadcrumbs';
import DesktopAttachments from './attachments/Attachments.desktop';
import DesktopControls from './Controls.desktop';
import Info from './info/Info.desktop';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useInspectionViewer} from '@src/core/hooks/imagesViewers/desktop/useInspectionViewer';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import {EntityLinks} from '@src/components/EntityLinks/EntityLinks';

interface IDesktopProps {
	task?: IEnrichedTask;
	loading?: boolean;
}

const Desktop = ({task, loading}: IDesktopProps) => {
	const [imagesAttachments] = useSplitAttachments(task);
	const images = useAttachmentsImages(imagesAttachments);
	const imagesData = useAttachmentsImagesObjects(imagesAttachments);

	const [imagesViewer, openInspectionImage] = useInspectionViewer(
		images,
		task ? task.id : '',
		imagesData
	);

	return (
		<LoadingPanel active={loading}>
			<div className="page-cont task-view-page">
				<PageBreadcrumbs task={task} />

				<div className="task-view-page__d-columns">
					{task !== undefined && (
						<div className="task-view-page__d-column task-view-page__d-column_attachments">
							<DesktopAttachments
								task={task}
								onImageClick={openInspectionImage}
							/>

							{task?.links && Object.keys(task.links).length !== 0 && (
								<Plate
									withoutPadding
									className="task-view-page__d-plate"
								>
									<EntityLinks
										links={{...task?.links, objectId: task.objectId?.id}}
									/>
								</Plate>
							)}
						</div>
					)}

					<div className="task-view-page__d-column task-view-page__d-column_info">
						{task?.id !== undefined && <DesktopControls taskId={task.id} />}

						{task !== undefined && (
							<Plate>
								<Info task={task} />
							</Plate>
						)}
					</div>
					{imagesViewer}
				</div>
			</div>
		</LoadingPanel>
	);
};

export default Desktop;
