/**
 * Меняет первую букву на строчную
 *
 * @param value строка
 */
export function makeFirstLetterLowercase(value?: string) {
	if (value && value.length > 0) {
		return value[0].toLowerCase() + value.substring(1);
	}
	return value;
}

/**
 * Меняет первую букву на заглавную
 *
 * @param value строка
 */
export function makeFirstLetterUppercase(value?: string) {
	if (value && value.length > 0) {
		return value[0].toUpperCase() + value.substring(1);
	}
	return value;
}
