import { useRef, memo } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useVirtualizer} from '@tanstack/react-virtual';
import {LinkButton} from '@tehzor/ui-components';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

interface EntityTasksVirtualizeItemsProps {
	tasks?: ITask[];
}

const taskIcon = <i className="tz-task-16" />;

const itemHeight = 44;

export const EntityTasksVirtualizeItems = memo((props: EntityTasksVirtualizeItemsProps) => {
	const {pushPath} = useChangePath();
	const {tasks} = props;
	const parentRef = useRef<HTMLDivElement>(null);

	const virtualizer = useVirtualizer({
		count: tasks?.length || 0,
		getScrollElement: () => parentRef.current,
		estimateSize: () => itemHeight,
		overscan: 4
	});

	const items = virtualizer.getVirtualItems();

	if (!tasks?.length) return null;

	const renderedTasks = items.map(virtualRow => {
		const task = tasks[virtualRow.index];

		return (
			<div
				key={virtualRow.key}
				data-index={virtualRow.index}
				ref={virtualizer.measureElement}
				className="entity-tasks__virtualize-list-item"
			>
				<LinkButton
					type="filled"
					leftIcon={taskIcon}
					onClick={() => pushPath(`/tasks/${task.id}`)}
					label={task.name}
				/>
			</div>
		);
	});

	return (
		<div
			ref={parentRef}
			className="entity-tasks__virtualize-list"
			style={{
				height: items.length * itemHeight
			}}
		>
			<div
				style={{
					height: virtualizer.getTotalSize()
				}}
				className="entity-tasks__virtualize-list-wrapper"
			>
				<div
					style={{
						transform: `translateY(${items[0]?.start}px)`
					}}
					className="entity-tasks__virtualize-list-items"
				>
					{renderedTasks}
				</div>
			</div>
		</div>
	);
});
