import {CellProps} from 'react-table';
import {Tag, TagsContainer} from '@tehzor/ui-components';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import styles from './WorkAcceptancesStatsCell.module.less';

export const WorkAcceptancesStatsCell = ({row}: CellProps<IEnrichedStructure>) => {
	const data = row.original;

	return data.workAcceptancesStats?.length ? (
		<TagsContainer>
			{data.workAcceptancesStats.map(item => (
				<Tag
					key={item.key}
					className={{
						label: styles.cellLabel,
						icon: styles.cellIcon
					}}
					icon={
						<i
							className="tz-work-acceptance-16"
							style={{color: item.color}}
						/>
					}
					label={item.value.toString()}
					size="large"
				/>
			))}
		</TagsContainer>
	) : null;
};
