import {OwnerAcceptancePage} from './OwnerAcceptancePage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

/**
 * Обёртка над страницей передачи собственнику
 * необходимо для того чтобы при переходе от одной передачи к другой отрабатывали все методы жизненного цикла
 *
 * @param props свойства компонента
 * @constructor
 */
export const OwnerAcceptancePageWrap = () => {
	const {ownerAcceptanceId} = useStrictParams<{ownerAcceptanceId: string}>();
	return <OwnerAcceptancePage key={ownerAcceptanceId}/>;
};
