import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IEditCommentResponse,
	makeCommentEditRequest
} from '@src/api/backend/comment';
import {addErrorToast} from '@src/utils/toasts';
import ISavingComment from '@src/interfaces/ISavingComment';

export type IEditWorkAcceptanceCommentPayload = IEditCommentResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditCommentResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении комментария');
	return {
		type: types.EDIT_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет комментарий приемки работ
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const editWorkAcceptanceComment = (
	objectId: string,
	commentId: string,
	fields: ISavingComment
) =>
	createApiAction<IEditCommentResponse>(request, success, failure, () =>
		makeCommentEditRequest(objectId, commentId, fields));
