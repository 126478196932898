import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISavingSpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecoration';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddSpaceTypeDecorationResponse = ISpaceTypeDecoration[];

/**
 * Создает массово типы отделки помещения в наборе
 *
 * @param typeDecorationSetId id набора типов отделки помещения
 * @param items массив типов отделки для добавления
 */
export const makeSpaceTypeDecorationAddRequest = async (
	typeDecorationSetId: string,
	items: ISavingSpaceTypeDecoration[]
) => {
	const response = await httpRequests.withToken.post<IAddSpaceTypeDecorationResponse>(
		'space-type-decoration',
		{typeDecorationSetId, items}
	);
	return response.data;
};
