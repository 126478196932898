import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {EntityGrid, EntityGridItem, Tag} from '@tehzor/ui-components';
import {checkIsCurrent} from '../../utils/checkIsCurrent';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IObjectStagesProps {
	object: IObject;
	stages: IObjectStage[];
	isDesktop?: boolean;
}

const calendarIcon = <i className="tz-calendar-20" />;

export const ObjectStages = ({object, stages, isDesktop}: IObjectStagesProps) => {
	const {t} = useTranslation();
	const locale = useDateFnsLocale();
	return (
		<EntityGrid
			withBorders
			className={{
				wrap: 'object-page__d-entities-grid',
				root: 'object-page__d-entities-grid-stages'
			}}
		>
			<EntityGridItem className={{content: 'object-page__d-entities-grid-title'}}>
				{t('objectPage.info.stages.title')}
			</EntityGridItem>
			<EntityGridItem
				className="object-page__d-entities-grid-stages__wrap"
				label={t('objectPage.info.stages.stageCompletionDates.label')}
				icon={calendarIcon}
				inline={isDesktop}
			>
				{stages.map(stage => {
					const from = object.stages?.[stage.id]?.from;
					const to = object.stages?.[stage.id]?.to;

					return (
						<div
							className="object-page__d-entities-grid-stages__wrap-stage"
							key={stage.id}
						>
							{from || to ? (
								<>
									<div className="object-page__d-entities-grid-stages__wrap-stage-name">
										{stage.name}
										{checkIsCurrent(from, to) && (
											<Tag
												label={t('objectPage.info.currentStage.label')}
												color="#C2DEFF"
											/>
										)}
									</div>
									<div>
										{from ? format(from, 'dd.MM.yyyy', {locale}) : '?'} -{' '}
										{to ? format(to, 'dd.MM.yyyy', {locale}) : '?'}
									</div>
								</>
							) : (
								<>
									<div className="object-page__d-entities-grid-stages__wrap-stage-name">
										{stage.name}
									</div>
									<div>—</div>
								</>
							)}
						</div>
					);
				})}
			</EntityGridItem>
		</EntityGrid>
	);
};
