import { memo, useState } from 'react';
import {Plate} from '@tehzor/ui-components';
import {Table} from './Table';
import {getDesktopColumns} from './columns.desktop';
import {useTranslation} from "react-i18next";

interface IDesktopProps {
	objectId: string;
	spaceId: string;
}

export const DesktopWorkAcceptanceTable = memo((props: IDesktopProps) => {
	const {t} = useTranslation();
	const {objectId, spaceId} = props;
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	return (
		<div className="space-page__d-column space-page__d-column_entities">
			<Plate withoutPadding>
				<Table
					objectId={objectId}
					columns={getDesktopColumns(t)}
					spaceId={spaceId}
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
				/>
			</Plate>
		</div>
	);
});
