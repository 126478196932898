import {Action, Middleware} from 'redux';
import {IState} from '@src/store/modules';
import {AppThunkDispatch} from '@src/store/interfaces/thunk';
import {ApiAction, ApiDispatch} from './api';

const API_ACTION_TYPE = 'call-api';

type ApiMiddleware = Middleware<ApiDispatch, IState, AppThunkDispatch>;

/**
 * Middleware для обработки вызовов к backend api
 */
export default (): ApiMiddleware =>
	({getState}) => next =>
		(action: Action | ApiAction) => {
			if (action.type !== API_ACTION_TYPE) {
				const {type} = action as ApiAction;
				const online = getState().offlineMode.networkStatus;
				const exclude = [
					'space/get/request',
					'space/get/success',
					'space/get/failure',
					'spaces/schema/get/request',
					'spaces/schema/get/success',
					'spaces/schema/get/failure',
					'spaces/list/get/request',
					'spaces/list/get/success',
					'spaces/list/get/failure',
					'persist/REHYDRATE',
					'persist/PERSIST',
					'CLEAR_NOTIFICATION_MESSAGES',
					'TOGGLE_OBJECTS_GROUP_VISIBLE',
					'CHANGE_OBJECTS_FILTER',
					'offline-mode/cache-status/change',
					'offline-mode/network-status/change'
				];
				if (!online && !exclude.includes(type)
					&& !type.includes('_LOCAL_')
					&& !type.endsWith('/change')
					&& !type.startsWith('app/')) {
					// return undefined;
				}
			}
			return next(action);
		};
