import { useCallback, useState } from 'react';
import * as React from 'react';
import AddingInternalAcceptanceDialog from '../AddingInternalAcceptanceDialog';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';

interface IAddingInternalAcceptanceDialogProps {
	objectId: string;
	internalAcceptanceId?: string;
	links?: IInternalAcceptance['links'];
	stage?: ObjectStageIds;
	types?: IInternalAcceptanceAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	onSuccess?: () => void | Promise<void>;
	problemToCopyId?: string;
	createdBy?: string;
}

export function useAddingInternalAcceptanceDialog({
	objectId,
	internalAcceptanceId,
	links,
	stage,
	types,
	defaultProblemData,
	defaultInspectionData,
	onSuccess,
	problemToCopyId,
	createdBy
}: IAddingInternalAcceptanceDialogProps): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog =
		stage && isOpen ? (
			<AddingInternalAcceptanceDialog
				objectId={objectId}
				internalAcceptanceId={internalAcceptanceId}
				links={links}
				types={types}
				defaultProblemData={defaultProblemData}
				defaultInspectionData={defaultInspectionData}
				isOpen={isOpen}
				onClose={close}
				onSuccess={onSuccess}
				problemToCopyId={problemToCopyId}
				createdBy={createdBy}
			/>
		) : null;

	return [dialog, open];
}
