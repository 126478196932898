import {IconButton} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import { memo } from 'react';
import {IPreparedLegal} from '../../interfaces/IPreparedLegal';
import {useDeleteLegals} from '@src/core/hooks/mutations/legals/useDeleteLegals';

interface ISelectionActionsProps {
	selectedEntities: IPreparedLegal[];
	onClear: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const LegalsRowActions = memo(({selectedEntities, onClear}: ISelectionActionsProps) => {
	const canDelete = selectedEntities.every(item => item.canDelete);
	const deleteLegals = useDeleteLegals();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить юрлица',
		'Вы действительно хотите удалить выбранные юрлица?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleDelete = async () => {
		if (await getDeleteConfirmation()) {
			await deleteLegals(selectedEntities);
		}
		onClear();
	};

	if (!canDelete) return null;

	return (
		<>
			<IconButton onClick={handleDelete}>{deleteIcon}</IconButton>

			{deleteDialog}
		</>
	);
});
