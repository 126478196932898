import {
	IEditableEntityState,
	IEditableEntityAction,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {
	IAuthorizedPerson,
	ISavingAuthorizedPerson
} from '@tehzor/tools/interfaces/authorizedPersons';

export type IEditableAuthorizedPersonState = IEditableEntityState<{
	name?: string;
	position?: string;
	authorityBasis?: string;
	authorityExpiry?: number;
	comment?: string;
	isLocal?: boolean;
}>;

export type IEditableAuthorizedPersonAction = IEditableEntityAction<
	IEditableAuthorizedPersonState,
	ISavingAuthorizedPerson
>;

const makeEmptyState = (): IEditableAuthorizedPersonState => ({
	name: '',
	position: '',
	authorityBasis: '',
	authorityExpiry: undefined,
	comment: '',
	isLocal: false,
	errors: {
		name: false,
		position: false,
		authorityBasis: false,
		authorityExpiry: false,
		isLocal: false
	}
});

export const makeDefaultData = (
	authorizedPerson?: IAuthorizedPerson
): ISavingAuthorizedPerson | undefined => {
	if (!authorizedPerson) {
		return undefined;
	}

	return {
		legalEntityId: authorizedPerson.legalEntityId,
		companyId: authorizedPerson.companyId,
		name: authorizedPerson.name,
		position: authorizedPerson.position,
		authorityBasis: authorizedPerson.authorityBasis,
		authorityExpiry: authorizedPerson.authorityExpiry,
		comment: authorizedPerson.comment
	};
};

export const init = (original?: ISavingAuthorizedPerson): IEditableAuthorizedPersonState => {
	const empty = makeEmptyState();
	if (!original) {
		return empty;
	}

	const state: IEditableAuthorizedPersonState = {
		...original,
		errors: empty.errors
	};
	return state;
};

export const isEdited = (
	state: IEditableAuthorizedPersonState,
	original?: IAuthorizedPerson
): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'position'),
		isPropEdited.bind(null, 'authorityBasis'),
		isPropEdited.bind(null, 'authorityExpiry'),
		isPropEdited.bind(null, 'comment')
	);

export const errorsFns = {
	name: (state: IEditableAuthorizedPersonState) => !state.name,
	position: (state: IEditableAuthorizedPersonState) => !state.position,
	authorityBasis: (state: IEditableAuthorizedPersonState) => !state.authorityBasis,
	authorityExpiry: (state: IEditableAuthorizedPersonState) => !state.authorityExpiry,
	comment: (state: IEditableAuthorizedPersonState) => !state.comment
};

export const convertToSave = (
	state: IEditableAuthorizedPersonState,
	original?: IAuthorizedPerson
): ISavingAuthorizedPerson => {
	const defaultData = makeDefaultData(original);
	const bankAccount: ISavingAuthorizedPerson = {};

	bankAccount.name = state.name;

	if (isPropEdited('position', state, defaultData)) {
		bankAccount.position = state.position;
	}
	if (isPropEdited('authorityBasis', state, defaultData)) {
		bankAccount.authorityBasis = state.authorityBasis;
	}
	if (isPropEdited('authorityExpiry', state, defaultData)) {
		bankAccount.authorityExpiry = state.authorityExpiry;
	}
	if (isPropEdited('comment', state, defaultData)) {
		bankAccount.comment = state.comment;
	}

	return bankAccount;
};
