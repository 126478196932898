import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetCheckResponse = ILinkedCheck;

/**
 * Возвращает проверку
 *
 * @param objectId id объекта
 * @param checkId id проверки
 */
export const requestCheck = async (objectId: string, checkId: string) => {
	const response = await httpRequests.withToken.get<IGetCheckResponse>(`checks/${checkId}`, {
		params: {objectId}
	});

	return response.data;
};
