import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';

export const useTranslatedObjectStagesArray = () => {
	const {t} = useTranslation();
	const {data: stages} = useObjectStagesAsArray();

	return useMemo(
		() =>
			stages ? stages.map(stage => ({...stage, name: t(`objectStages.${stage.id}`)})) : [],
		[stages]
	);
};
