import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {getPermissions} from './getPermissions';

export const convertFieldsSettingsSets = (
	fieldsData: IFieldSettingsSet[],
	roles: IUserRole[]
): IPreparedFieldSettingsSet[] =>
	fieldsData.map(fields => ({
		...fields,
		...getPermissions(roles)
	}));
