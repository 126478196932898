import {queryClient} from '@src/api/QueryClient';
import {IGetCheckItemsResponse} from '@src/api/backend/checkItems';
import {makeCheckItemMoveRequest} from '@src/api/backend/checkItems/move';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useCallback} from 'react';

interface ICheckItemMoveParams {
	checkListId: string;
	id: string;
	order: number;
	parentId?: string;
}

export const useMoveCheckItem = () => {
	const mutation = useMutation<IGetCheckItemsResponse, IError, ICheckItemMoveParams>({
		mutationFn: (params: ICheckItemMoveParams) =>
			makeCheckItemMoveRequest(params.checkListId, params.id, params.order, params.parentId),

		onSuccess: data => {
			queryClient.setQueryData<IGetCheckItemsResponse>(
				checkItemsQueryKeys.list(),
				oldData => {
					if (oldData) {
						const newById = oldData.allIds.reduce((prev, current) => {
							prev[current] =
								data.allIds.includes(current) && data.byId[current]
									? data.byId[current]
									: oldData.byId[current];
							return prev;
						}, {});

						return {
							allIds: oldData.allIds,
							byId: newById
						};
					}
					return oldData;
				}
			);
			addSuccessToast('Успех', 'Категория перемещена');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при перемещении категории');
		}
	});
	/**
	 * Возвращает функцию для перемещения категории внутри чек-листа
	 *
	 * @param item данные новой категории
	 */
	return useCallback(
		(params: ICheckItemMoveParams) => {
			mutation.mutate(params);
		},
		[mutation]
	);
};
