import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState, IInternalAcceptanceState} from './reducers';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

const extractInternalAcceptanceData = (state: IState): IInternalAcceptanceState =>
	state.entities.internalAcceptance || getInitialState();

/**
 * Извлекает текущую внутреннюю приёмку
 */
export const extractInternalAcceptance = createSelector(
	[extractInternalAcceptanceData],
	internalAcceptance => internalAcceptance.data
);

/**
 * Извлекает ответы на нарушения внутренней приёмки
 */
export const extractInternalAcceptanceProblemReplies = createSelector(
	[extractInternalAcceptanceData],
	data => data.problemReplies.allIds.map(id => data.problemReplies.byId[id])
);

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractInternalAcceptanceProblemCommentsByProblem = createSelector(
	[extractInternalAcceptanceProblemReplies],
	replies =>
		replies.reduce<Record<string, IComment>>((prev, item) => {
			if (item.links?.problemId && !prev[item.links.problemId]) {
				prev[item.links.problemId] = item;
			}
			return prev;
		}, {})
);
