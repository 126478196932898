import {useCallback, useMemo, useState} from 'react';
import {FilterButton, Select2, SelectOption} from '@tehzor/ui-components';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useTranslation} from 'react-i18next';
import {useUpdateEffect} from 'react-use';
import {makeCustomFilterLabel} from '@src/components/CustomFilters/helpers/makeCustomFilterLabel';
import {
	ICustomFilterSelectValue,
	ICustomFiltersData
} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';
import {IConvertCustomFieldSelect} from '@tehzor/tools/interfaces/fields';

interface ICustomFilterSelectProps {
	fieldData: IConvertCustomFieldSelect;
	filterValue: ICustomFilterSelectValue;
	onChange: (data: ICustomFiltersData) => void;
	onClear: (key: string) => void;
}

export const CustomFilterSelect = ({
	fieldData,
	filterValue,
	onChange,
	onClear
}: ICustomFilterSelectProps) => {
	const {t} = useTranslation();
	const {values, name, key, valueTypeId} = fieldData;

	const [selectedValues, setSelectedValues] = useState(filterValue);

	const [search, setSearch] = useState('');
	const clearSearch = useCallback(() => setSearch(''), []);

	const isSearchShown = useMemo(() => (values ? values.length >= 8 : false), [values]);

	const handleCancel = useCallback(() => {
		setSelectedValues(filterValue);
		clearSearch();
	}, [setSelectedValues, clearSearch, filterValue]);

	const handleApply = useCallback(() => {
		onChange({
			[key]: {
				valueTypeId,
				value: selectedValues
			}
		});
		clearSearch();
	}, [onChange, selectedValues, clearSearch, key, valueTypeId]);

	const handleClear = useCallback(() => {
		setSelectedValues(undefined);
		clearSearch();
	}, [setSelectedValues, clearSearch]);

	const handleFullClear = useCallback(() => {
		onClear(key);
		setSelectedValues([]);
		clearSearch();
	}, [onClear, clearSearch, key]);

	const handleSelectAll = useCallback(() => {
		setSelectedValues(values);
		clearSearch();
	}, [clearSearch, values]);

	const handleChange = useCallback(
		(v?: string[]) => {
			if (!v || v.length === 0) {
				setSelectedValues(undefined);
			} else {
				setSelectedValues(v);
			}
		},
		[setSelectedValues]
	);

	const filteredData = useMemo(() => {
		if (!values) return [];
		if (search === '') return values;
		return values.filter(value => value.toUpperCase().includes(search.toUpperCase()));
	}, [values, search]);

	useUpdateEffect(() => {
		setSelectedValues(filterValue);
	}, [filterValue]);

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedValues?.length}
			footer
			count={selectedValues?.length}
			noHeader={!isSearchShown}
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeCustomFilterLabel(name, filterValue)}
					active={!!(filterValue && filterValue.length != 0)}
					onClear={handleFullClear}
				/>
			}
			search={
				isSearchShown && (
					<TranslatedSelectSearch
						value={search}
						placeholder={t('selectSearch.placeholder')}
						onChange={setSearch}
					/>
				)
			}
			onSelectAll={handleSelectAll}
		>
			<Select2
				multiple
				value={selectedValues}
				onChange={handleChange}
			>
				{filteredData.map(item => (
					<SelectOption
						key={item}
						itemKey={item}
						content={item}
					/>
				))}
			</Select2>
		</TranslatedSelectPopup>
	);
};
