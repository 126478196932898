import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	makeWorkAcceptanceStatusEditRequest,
	IEditWorkAcceptanceStatusResponse
} from '@src/api/backend/workAcceptance';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {queryClient} from '@src/api/QueryClient';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';

export type IEditWorkAcceptanceStatusPayload = IEditWorkAcceptanceStatusResponse;

const request = () => ({
	type: types.EDIT_STATUS_REQUEST
});

const success = (response: IEditWorkAcceptanceStatusResponse) => {
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.lists()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.paginate()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.details()});

	return {
		type: types.EDIT_STATUS_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении статуса приёмки работ');
	return {
		type: types.EDIT_STATUS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет статус приёмки работ
 */
export const editWorkAcceptanceStatus = (
	objectId: string,
	workAcceptanceId: string,
	statusId: WorkAcceptanceStatusIds
) =>
	createApiAction<IEditWorkAcceptanceStatusResponse>(request, success, failure, () =>
		makeWorkAcceptanceStatusEditRequest(objectId, workAcceptanceId, statusId)
	);
