import * as React from 'react';
import './FieldError.less';
import classNames from 'classnames';

interface IFieldErrorProps {
	className?: string;
	style?: React.CSSProperties;
	text: string;
}

const FieldError = ({className, style, text}: IFieldErrorProps) => (
	<div
		className={classNames('field-error', className)}
		style={style}
	>
		{text}
	</div>
);

FieldError.displayName = FieldError;

export default FieldError;
