import {useCallback, useTransition} from 'react';
import {Location, useNavigate} from 'react-router-dom';

export const useChangePath = () => {
	const navigate = useNavigate();
	const [isPending, startTransition] = useTransition();
	const pushPath = useCallback(
		(path: string | undefined, state?: Location['state']) => {
			if (!path) return;
			startTransition(() => navigate(path, state));
		},
		[navigate]
	);

	const replacePath = useCallback(
		(path: string, state?: Location['state']) => {
			startTransition(() => navigate(path, {state, replace: true}));
		},
		[navigate]
	);

	const goBack = useCallback(() => {
		startTransition(() => navigate(-1));
	}, [navigate]);

	return {pushPath, replacePath, goBack, isPending};
};
