import {ReactElement} from 'react';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {groupBy} from 'lodash';
import {ISpaceStatus} from './ISpaceStatus';
import {getSpaceStageTitle} from './getSpaceStageTitle';
import {isDisabled} from '../../BaseStatusSelect/utils/isDisabled';
import {getSpaceMenuItem} from './getSpaceMenuItem';

interface IGetSpaceMenuItems <S extends ISpaceStatus> {
	statuses?: S[];
	selectedStatus?: S;
	availableStatuses?: string[];
	stagesMap: Record<ObjectStageIds, IObjectStage>;
}

export const getSpaceMenuItems = <S extends ISpaceStatus> (
	{statuses, selectedStatus, availableStatuses, stagesMap}: IGetSpaceMenuItems<S>
) => {
	if (!statuses) {
		return;
	}

	const group = groupBy(statuses, 'stage');

	let disabledCount = 0;
	const menuItems: ReactElement[] = [];
	for (const stage of Object.keys(group)) {
		const groupedStatuses = group[stage as ObjectStageIds];

		menuItems.push(
			getSpaceStageTitle(stage as ObjectStageIds, stagesMap)
		);

		let groupDisabledCount = 0;
		menuItems.push(
			...groupedStatuses.map(item => {
				const disabled = isDisabled(item, selectedStatus, availableStatuses);

				if (disabled) {
					groupDisabledCount++;
				}

				return (
					getSpaceMenuItem(item, selectedStatus, disabled)
				);
			})
		);
		disabledCount += groupDisabledCount;
	}

	if (menuItems.length === disabledCount) {
		return;
	}
	return menuItems;
};