import {IGetContractFormsResponse} from '@src/api/backend/contractForms';
import {contractFormsQueryKeys} from '@src/api/cache/сontractForms/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractContractFormsAsArray, extractContractFormsById} from './selectors';

export const useContractForms = <T = IGetContractFormsResponse>(
	select?: (data: IGetContractFormsResponse) => T
) =>
	useQuery<IGetContractFormsResponse, IError, T>({
		queryKey: contractFormsQueryKeys.lists(),
		meta: {
			error: 'при загрузке форм договоров'
		},
		select
	});

export const useContractFormsAsArray = () => useContractForms(extractContractFormsAsArray);

export const useWorkingGroupsTypesById = () => useContractForms(extractContractFormsById);
