import { useCallback } from 'react';
import * as React from 'react';

interface IClickPreventWrapProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
}

const ClickPreventWrap = ({className, style, children}: IClickPreventWrapProps) => {
	const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	}, []);

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div
			className={className}
			style={style}
			role="presentation"
			tabIndex={-1}
			onClick={handleClick}
		>
			{children}
		</div>
	);
};

export default ClickPreventWrap;
