import * as types from '../constants';
import {CheckEntitiesVisibility} from '../reducers/entitiesVisibility';

/**
 * Изменяет фильтр отображения нарушений и осмотров
 *
 * @param value значение фильтра
 */
export const changeEntitiesVisibility = (value: CheckEntitiesVisibility[]) => ({
	type: types.CHANGE_ENTITIES_VISIBILITY,
	payload: value
});
