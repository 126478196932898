import {StatsTreePanel, VerticalTabContent} from '@tehzor/ui-components';
import SubObjectStats from './SubObjectStats';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import CommonStatsCards from './CommonStatsCards';
import {StatsByFields, StatsMap} from '@src/utils/statsAggregators/creation';
import {Tree} from 'array-to-tree';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';

interface IUserStatsProps {
	user: IBriefUser;
	userStats: StatsByFields;
	objects: Array<Tree<IStatsObject>>;
	objectsStats: StatsMap;
}

const UserStats = ({user, userStats, objects, objectsStats}: IUserStatsProps) => {
	const sourcesLoading = useAppSelector(extractSourcesLoading);

	return (
		<VerticalTabContent className="statistics-page__tabs-content-panel">
			<StatsTreePanel
				id={user.id}
				title={user.fullName}
				subTitle={user.position}
				loading={sourcesLoading}
				showExpandBtnPlaceholder
				permanentContent={(
					<CommonStatsCards
						stats={userStats}
						hideChecks
						hideInspections
						hideSpaces
					/>
				)}
				hiddenContent={objects.map(object => (
					<SubObjectStats
						key={object.id}
						data={object}
						stats={objectsStats}
						level={1}
					/>
				))}
			/>
		</VerticalTabContent>
	);
};

export default UserStats;
