/**
 * Проверяет находиятся ли координаты в конкретных рамках
 *
 * @param xmin: number,
 * @param xmax: number,
 * @param ymin: number,
 * @param ymax: number,
 * @param x: number,
 * @param y: number
 */
const isInside = (
	xmin: number,
	xmax: number,
	ymin: number,
	ymax: number,
	x: number,
	y: number
): boolean => x >= xmin && x <= xmax && y >= ymin && y <= ymax;

/**
 * Вычисляет контейнер в который перемещается элемент
 *
 * @param containers данные о всех контейнерах
 * @param x x координата события
 * @param y y координата события
 */
export const getDropContainer = (
	containers: Record<string, HTMLElement> | undefined,
	x: number,
	y: number
): string | undefined => {
	if (!containers) {
		return undefined;
	}

	for (const key in containers) {
		if (containers.hasOwnProperty(key)) {
			const element = containers[key];
			const rect = element.getBoundingClientRect();

			const containerXMin = rect.left;
			const containerXMax = rect.right;
			const containerYMin = rect.top;
			const containerYMax = rect.bottom;

			if (isInside(containerXMin, containerXMax, containerYMin, containerYMax, x, y)) {
				return key;
			}
		}
	}

	return undefined;
};
