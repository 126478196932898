import * as React from 'react';
import './HistoryItem.less';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';

export interface IHistoryItemProps {
	className?:
		| string
		| {
		root?: string;
		transition?: string;
		block?: string;
		arrow?: string;
	};
	style?: React.CSSProperties;
	prev?: React.ReactNode;
	next?: React.ReactNode;
}

export const HistoryItem = ({className, style, prev, next}: IHistoryItemProps) => {
	const classes = convertClassNames(className);

	return (
		<div
			className={classNames('history-item', classes.root)}
			style={style}
		>
			{(prev || next) && (
				<div className={classNames('history-item__transition', classes.transition)}>
					<div className={classNames('history-item__block', classes.block)}>{prev}</div>
					<i className={classNames('tz-arrow-16', 'history-item__transition-arrow', classes.arrow)}/>
					<div className={classNames('history-item__block', classes.block)}>{next}</div>
				</div>
			)}
		</div>
	);
};

HistoryItem.displayName = 'HistoryItem';
