import {Attributes, createElement} from 'react';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import {isImage} from '@tehzor/tools/utils/mimeTypes';
import {UploadingFile, UploadingImage} from '../../../files';
import {IUploadingImageProps} from '../../../files/UploadingImage/UploadingImage';
import {IUploadingFileProps} from '../../../files/UploadingFile/UploadingFile';
import classNames from 'classnames';

export const splitUploadingFiles = (
	items?: IUploadingFile[],
	disabled?: boolean,
	imageClassName?: string,
	fileClassName?: string,
	onDelete?: (key: string) => void,
	onReload?: (key: string) => void,
	onImageClick?: (id: string, url: string) => void
) => {
	const images = [];
	const files = [];
	if (items) {
		for (const file of items) {
			const props: Attributes & (IUploadingImageProps<string> | IUploadingFileProps<string>) = {
				...file,
				disabled,
				data: file.key,
				onDelete,
				onReload
			};

			if (isImage(file.type)) {
				props.className = classNames('editable-attachments__image', imageClassName);
				(props as IUploadingImageProps<string>).onClick = onImageClick;
				images.push(createElement(UploadingImage, props));
			} else {
				props.className = classNames('editable-attachments__file', fileClassName);
				files.push(createElement(UploadingFile, props));
			}
		}
	}
	return [images, files];
};