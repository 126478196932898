import {useCallback} from 'react';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {EntitiesTable} from '@tehzor/ui-components';
import {getColumns} from './columns';
import TotalTasks from './TotalTasks';
import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IEnrichedTaskForScheduler} from '@tehzor/tools/interfaces/tasks/IEnrichedTaskForScheduler';
import {useTranslation} from 'react-i18next';

const plusIcon = <i className="tz-plus-24" />;

export const TasksListPopup = ({
	data,
	objectId,
	startTime,
	endTime
}: {
	data: IEnrichedTaskForScheduler[];
	objectId?: string;
	startTime?: number;
	endTime?: number;
}) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const permissions = useTasksPermissions();
	const [taskDialog, handleOpenTaskDialog] = useAddingTaskDialog({
		objectId,
		startTime: startTime ? startTime.toString() : undefined,
		endTime: endTime ? endTime.toString() : undefined
	});

	const handleRowClick = useCallback((task: IEnrichedTaskForScheduler) => {
		pushPath(`/tasks/${task.id}`);
	}, []);

	return (
		<div className="tasks-schedule-popup__list-content">
			<TotalTasks number={data.length} />
			<EntitiesTable
				// selectable
				columns={getColumns(t)}
				data={data}
				onRowClick={handleRowClick}
			/>

			{permissions.canAdd && (
				<button
					className="tasks-schedule-popup__button-add-task"
					onClick={handleOpenTaskDialog}
					type="button"
				>
					{plusIcon}
					<span>{t('components.tasksSchedulePopup.tasksListPopup.buttons.add')}</span>
				</button>
			)}

			{permissions.canAdd && taskDialog}
		</div>
	);
};
