import {IGetListInspectionsResponse} from '@src/api/backend/inspections';
import {CheckEntitiesVisibility} from '@src/store/modules/settings/pages/check/reducers/entitiesVisibility';

/**
 * Извлекает осмотры внутренней приемки
 */
export const extractInternalAcceptanceInspectionsArray = (
	data: IGetListInspectionsResponse
) => data.allIds.map(id => data.byId[id]);

export const extractInternalAcceptanceInspectionsEntities = (
	data: IGetListInspectionsResponse,
	visibility: CheckEntitiesVisibility[]
) => {
	const inspections = extractInternalAcceptanceInspectionsArray(data);
	if (visibility.includes('inspections') && inspections?.length) {
		return inspections.map(item => ({
			id: `i_${item.id}`,
			type: 'inspection',
			data: item
		}));
	}
	return [];
};
