import MobileInfo from './info/Info.mobile';
import {Plate} from '@tehzor/ui-components';
import MobileLinks from './links/Links.mobile';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import Documents from './attachments/Documents';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {useInspectionViewer} from '@src/core/hooks/imagesViewers/mobile/useInspectionViewer';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import PageBreadcrumbs from './PageBreadcrumbs';
import {useTranslation} from 'react-i18next';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';

interface IMobileProps {
	objectId: string;
	inspection?: ILinkedInspection;
	settings?: IEntitySettings;
}

const Mobile = ({objectId, inspection, settings}: IMobileProps) => {
	const [imagesAttachments, docs] = useSplitAttachments(inspection);
	const images = useAttachmentsImages(imagesAttachments);
	const imagesData = useAttachmentsImagesObjects(imagesAttachments);
	const [imagesViewer, openInspectionImage] = useInspectionViewer(
		images,
		inspection ? inspection?.id : '',
		imagesData
	);
	const {t} = useTranslation();

	return (
		<div className="page-cont inspection-page">
			{inspection !== undefined && (
				<PageBreadcrumbs
					objectId={objectId}
					inspectionId={inspection.id}
				/>
			)}
			{inspection !== undefined && (
				<MobileLinks
					objectId={objectId}
					inspection={inspection}
				/>
			)}

			{inspection !== undefined && (
				<Plate className="inspection-page__m-plate inspection-page__m-info">
					<MobileInfo
						objectId={objectId}
						inspection={inspection}
						images={images}
						onImageClick={openInspectionImage}
						settings={settings}
					/>
				</Plate>
			)}

			{docs.length > 0 && (
				<Plate
					className="inspection-page__m-plate"
					title={t('inspectionPage.info.attachments.label')}
				>
					<Documents files={docs} />
				</Plate>
			)}

			{imagesViewer}
		</div>
	);
};

export default Mobile;
