import { useCallback } from 'react';
import {SwitchMenuButton} from '@tehzor/ui-components';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {changeDisplayMode} from '@src/store/modules/settings/pages/spaces/actions';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

const options = [
	{key: SpacesDisplayMode.SCHEMA, content: <i className="tz-schema-24"/>},
	{key: SpacesDisplayMode.LIST, content: <i className="tz-list-24"/>}
];

interface IMobileDisplayModeSwitchProps {
	objectId: string;
}

export const MobileDisplayModeSwitch = ({objectId}: IMobileDisplayModeSwitchProps) => {
	const {displayMode} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const handleChange = useCallback(
		(value: SpacesDisplayMode) => {
			dispatch(changeDisplayMode(objectId, value));
		},
		[objectId]
	);

	return (
		<SwitchMenuButton
			className="spaces-page__m-display-mode-switch"
			options={options}
			value={displayMode}
			onChange={handleChange}
		/>
	);
};
