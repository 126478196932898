import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState, ICheckState} from './reducers';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

const extractCheckData = (state: IState): ICheckState => state.entities.check || getInitialState();

/**
 * Извлекает ответы на нарушения проверки
 */
export const extractCheckProblemReplies = createSelector([extractCheckData], data =>
	data.problemReplies.allIds.map(id => data.problemReplies.byId[id])
);

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractCheckProblemCommentsByProblem = createSelector(
	[extractCheckProblemReplies],
	replies =>
		replies.reduce<Record<string, IComment>>((prev, item) => {
			if (item.links?.problemId && !prev[item.links.problemId]) {
				prev[item.links.problemId] = item;
			}
			return prev;
		}, {})
);
