import {IWorkAcceptancesStatsDataRequest} from '@src/interfaces/IWorkAcceptancesStatsData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IEntityTasksData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityTasksData';

export interface IGetWorkAcceptanceTasksResponse {
	[key: string]: IEntityTasksData;
}

/**
 * Возвращает статистику задач по приемкам работ
 */
export const requestWorkAcceptancesTasksData = async (
	requestData: IWorkAcceptancesStatsDataRequest
) => {
	const response = await httpRequests.withToken.post<IGetWorkAcceptanceTasksResponse>(
		'/work-acceptances/tasks-stats',
		{...requestData}
	);
	return response.data;
};
