import {ButtonContainer, IconButton, IconMenu, MenuItem} from '@tehzor/ui-components';
import { memo } from 'react';
import {useAddingWorkAcceptanceCheckListProblemDialog} from '@src/pages/WorkAcceptancePage/hooks/useAddingWorkAcceptanceCheckListProblemDialog';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useAddingCheckListTaskDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListTaskDialog';
import {useEntityAddingPermission} from '@src/pages/CheckListPage/hooks/useEntityAddingPermission';
import {extractCheckListsPageSettings} from '@src/store/modules/settings/pages/checkLists/selectors';
import {useAddingWorkAcceptanceCheckListInspectionDialog} from '../../hooks/useAddingWorkAcceptanceCheckListInspectionDialog';
import {LastEntityAddedFromCheckList} from '@src/interfaces/LastEntityAddedFromCheckList';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IDesktopItemHeaderActions {
	stage: ObjectStageIds;
	listId: string;
	itemId: string;
}

const inspectionAddIcon = <i className="tz-inspection-add-24" />;
const taskAddIcon = <i className="tz-task-add-24" />;
const problemAddIcon = <i className="tz-problem-add-24" />;
const expandIcon = (
	<i className="tz-expand-arrow-heavy work-acceptance-page-check-list-items__item-header-actions-expand-menu-icon" />
);
export const DesktopItemHeaderActions = memo((props: IDesktopItemHeaderActions) => {
	const {listId, itemId, stage} = props;
	const {lastAddedEntity} = useAppSelector(extractCheckListsPageSettings);
	const [problemDialog, handleOpenAddProblemDialog] =
		useAddingWorkAcceptanceCheckListProblemDialog(listId, itemId);
	const hideInspectionAdding =
		stage === ObjectStageIds.WARRANTY || stage === ObjectStageIds.TRANSFER;
	const [inspectionDialog, handleOpenAddInspectionDialog] =
		useAddingWorkAcceptanceCheckListInspectionDialog(listId, itemId);
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId: string;
	}>();
	const canAddEntity = useEntityAddingPermission(objectId, listId);

	const [taskDialog, handleOpenAddTaskDialog] = useAddingCheckListTaskDialog({
		objectId,
		workAcceptanceId,
		checkItemId: itemId,
		checkListId: listId
	});

	const roles = useAppSelector(extractUserRoles);

	const menuItems = [];

	const permissions = {
		canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
		canAddProblem: canAddEntity && hasPermission(roles, 'problems-add'),
		canAddInspection: canAddEntity && hasPermission(roles, 'inspections-add'),
		canAddEntity
	};
	const icons = {
		[LastEntityAddedFromCheckList.PROBLEM]: {
			icon: problemAddIcon,
			handler: handleOpenAddProblemDialog
		},
		[LastEntityAddedFromCheckList.INSPECTION]: {
			icon: hideInspectionAdding ? problemAddIcon : inspectionAddIcon,
			handler: hideInspectionAdding
				? handleOpenAddProblemDialog
				: handleOpenAddInspectionDialog
		},
		[LastEntityAddedFromCheckList.TASK]: {
			icon: taskAddIcon,
			handler: handleOpenAddTaskDialog
		}
	};
	if (permissions.canAddProblem) {
		menuItems.push(
			<MenuItem
				icon={problemAddIcon}
				onClick={handleOpenAddProblemDialog}
				key={`problem-${itemId}`}
			>
				Добавить нарушение
			</MenuItem>
		);
	}
	if (permissions.canAddInspection && !hideInspectionAdding) {
		menuItems.push(
			<MenuItem
				icon={inspectionAddIcon}
				onClick={handleOpenAddInspectionDialog}
				key={`inspection-${itemId}`}
			>
				Добавить осмотр
			</MenuItem>
		);
	}

	if (permissions.canAddTask) {
		menuItems.push(
			<MenuItem
				icon={taskAddIcon}
				onClick={handleOpenAddTaskDialog}
				key={`task-${itemId}`}
			>
				Добавить задачу
			</MenuItem>
		);
	}

	return menuItems.length ? (
		<div>
			<ButtonContainer className="work-acceptance-page-check-list-items__item-header-actions-container">
				<IconButton
					className="work-acceptance-page-check-list-items__item-header-actions-menu-button"
					onClick={icons[lastAddedEntity].handler}
				>
					{icons[lastAddedEntity].icon}
				</IconButton>

				<IconMenu
					className="work-acceptance-page-check-list-items__item-header-actions-menu-button work-acceptance-page-check-list-items__item-header-actions-menu-button_small"
					icon={expandIcon}
				>
					{menuItems}
				</IconMenu>
			</ButtonContainer>
			{permissions.canAddProblem && problemDialog}
			{permissions.canAddInspection && !hideInspectionAdding && inspectionDialog}
			{permissions.canAddTask && taskDialog}
		</div>
	) : null;
});
