import {EntityGridItem} from '@tehzor/ui-components';
import classNames from 'classnames';
import { memo } from 'react';

interface IPriceCellProps {
	priceNumber?: number;
	priceText?: string;
}

const priceIcon = <i className="tz-price-20"/>;

export const PriceCell = memo(({priceNumber, priceText}: IPriceCellProps) => (
	<EntityGridItem
		className={{
			content: classNames(
				'contract__cells-price', 'contract__cells-item-content'
			),
			root: 'contract__cells-item',
			header: 'contract__cells-item-header'
		}}
		label="Стоимость договора"
		icon={priceIcon}
		inline
	>
		<div>
			{priceNumber && priceNumber}
			{(priceNumber && priceText) && (
				','
			)}
		</div>
		<div>
			{priceText && priceText}
		</div>
	</EntityGridItem>
));