import { useCallback, useMemo, useState } from 'react';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {
	ActionButtons,
	Button,
	Dialog,
	SelectPopup,
	SelectSearch,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useUpdateEffect} from 'react-use';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useObjectIdsForCompanyOrAllIds, useObjectsMap} from '@src/core/hooks/queries/objects/hooks';

interface IObjectsFieldProps {
	value?: string[] | null;
	companyId?: string | null;

	disabled?: boolean;
	required?: boolean;
	hasError?: boolean;
	isMobile?: boolean;

	onConfirm: (value?: string[] | null) => void;
	onError: () => void;
}

const getTitle = (objectsMap?: Record<string, IObject>, value?: string[] | null) => {
	if (!value?.length) {
		return '';
	}

	if (!objectsMap) return '';

	const objects = value.map(id => objectsMap[id]?.name);
	return objects.join(', ');
};

const arrow = <i className="tz-simple-arrow-20" />;

export const ObjectsField = ({
	value,
	companyId,
	disabled,
	required,
	hasError,
	isMobile,
	onConfirm,
	onError
}: IObjectsFieldProps) => {
	const [checked, setChecked] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	useUpdateEffect(() => setChecked(value), [value]);

	const objectsMap = useObjectsMap();
	const objectIds = useObjectIdsForCompanyOrAllIds(companyId?.toString()) || [];

	const {filteredData, expanded} = useMemo(() => {
		const treeData = makeObjectsTreeData(objectsMap ? objectIds.map(id => objectsMap[id]) : []);
		return treeFilter(treeData, 'content', search);
	}, [objectsMap, objectIds, search]);

	useUpdateEffect(() => {
		setExpandedObjects(expanded?.map(item => item.id));
	}, [expanded]);

	const isSearchShown = useMemo(() => (objectIds?.length || 0) >= 8, [filteredData]);

	const handleCancel = useCallback(() => {
		setChecked(value);
		clearSearch();
	}, [value, clearSearch]);

	const handleConfirm = useCallback(() => {
		onConfirm(checked);
		if (required) {
			onError();
		}
		clearSearch();
	}, [onConfirm, checked, required, onError, clearSearch]);

	const handleClearButton = useCallback(() => {
		onConfirm(null);
		if (required) {
			onError();
		}
		clearSearch();
	}, [onConfirm, required, onError, clearSearch]);

	const handleClear = useCallback(() => {
		setChecked(null);
		clearSearch();
	}, [clearSearch]);

	const trigger = (
		<TextFieldWithForwardedRef
			elementType="div"
			value={getTitle(objectsMap, value)}
			icon={arrow}
			error={required && hasError ? 'Выберите объект' : undefined}
			disabled={disabled}
			cleanable={!!value?.length}
			onClearClick={handleClearButton}
			onClick={isMobile ? open : undefined}
		/>
	);

	const content = (
		<TreeSelect
			data={filteredData}
			multiple
			value={checked || undefined}
			onChange={setChecked}
			expandedValue={expandedObjects}
			onExpand={setExpandedObjects}
		/>
	);

	const selectSearch = isSearchShown ? (
		<SelectSearch
			value={search}
			onChange={setSearch}
			onClear={clearSearch}
		/>
	) : null;

	return (
		<div className="editable-space-statuses-set__objects">
			{!isMobile ? (
				<SelectPopup
					search={selectSearch}
					trigger={trigger}
					clearButton={!!checked?.length}
					footer
					count={checked?.length}
					onApply={handleConfirm}
					onCancel={handleCancel}
					onClear={handleClear}
				>
					{content}
				</SelectPopup>
			) : (
				trigger
			)}
			{isMobile && (
				<Dialog
					isOpen={isOpen}
					className="editable-space-statuses-set__fields-objects__dialog"
					title="Автоматическая смена статуса на:"
					onRequestClose={close}
					footer={
						<div className="editable-space-statuses-set__fields-objects__dialog">
							<ActionButtons className="editable-space-statuses-set__fields-objects__dialog-btns">
								<Button
									type="cancel"
									label="Отменить"
									onClick={handleCancel}
								/>
								<Button
									type="accent-blue"
									label="Сохранить"
									onClick={handleConfirm}
								/>
							</ActionButtons>
						</div>
					}
				>
					{selectSearch}
					{content}
				</Dialog>
			)}
		</div>
	);
};
