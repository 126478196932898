import {IGetUsersResponse} from '@src/api/backend/users';

export const extractUsersAsArray = (data: IGetUsersResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

export const extractUsersAsMap = (data: IGetUsersResponse) => data.byId;

export const extractUser = (data: IGetUsersResponse, userId?: string) => {
	if (!userId) {
		return undefined;
	}
	return extractUsersAsMap(data)[userId];
};
