import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {convertEmployeesToTreeItems} from './convertEmployeesToTreeItems';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

type IContractorsTreeData = Array<{
	key: string;
	data: ITreeDataItem[];
}>;

const makeContactorTreeData = (
	contractor: ICompany,
	users: INormalizedData<IBriefUser>,
	parentId?: string
): ITreeDataItem[] =>
	[
		{
			id: contractor.id,
			content: contractor.name,
			parentId
		} as ITreeDataItem
	].concat(convertEmployeesToTreeItems(users, contractor.employees, contractor.id));

/**
 * Формирует дерево подрядчиков
 *
 * @param mainCompanies главные компании
 * @param subCompanies компании подрядчики
 * @param users пользователи
 */
export const makeContractorsTreeData = (
	mainCompanies: ICompany[],
	subCompanies: ICompany[],
	users: INormalizedData<IBriefUser>
): IContractorsTreeData => {
	if (mainCompanies.length === 1) {
		if (mainCompanies[0].contractors) {
			return mainCompanies[0].contractors.map(contractor => {
				const contractorCompany = subCompanies.find(
					item => item.id === contractor.subCompanyId
				);
				if (contractorCompany) {
					const data = makeContactorTreeData(contractorCompany, users);
					return {key: contractor.subCompanyId, data};
				}
					return {key: contractor.subCompanyId, data: [] as ITreeDataItem[]};
			});
		}
		return [];
	}
	return mainCompanies.reduce<IContractorsTreeData>((prev, company) => {
		if (company.contractors) {
			const contractorsIds = company.contractors.map(item => item.subCompanyId);
			const contractorCompanies = subCompanies.filter(item =>
				contractorsIds.includes(item.id));
			contractorCompanies.forEach(contractor => {
				prev.push({
					key: contractor.id,
					data: makeContactorTreeData(contractor, users)
				});
			});
		}
		return prev;
	}, []);
};
