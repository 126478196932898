import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton} from '@tehzor/ui-components';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/interfaces';
import {
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	ObjectsFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';

export const InternalAcceptancesFiltersPage = () => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();
	const canGoBack = (useLocation().state as {canGoBack?: boolean} | null)?.canGoBack;
	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IInternalAcceptancesFiltersState>();

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			goBack();
		}
	}, [canGoBack, apply, goBack]);

	useAppHeader(
		{
			title: t('filtersPage.title'),
			showBackBtn: true,
			mobileRightButtons: isCleanable<IInternalAcceptancesFiltersState>(state, objectId) ? (
				<LinkButton
					label={t('filterPage.resetFiltersBtn.label')}
					onClick={mobileClear}
				/>
			) : null
		},
		[state, objectId]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			{objectId === undefined && (
				<ObjectsFilterMobile
					entity={entity}
					value={state?.objects}
				/>
			)}

			<CreatedByFilterMobile
				objectId={objectId}
				value={state?.createdBy}
				entity={entity}
				onChange={change}
			/>

			<CreationDateFilterMobile
				objectId={objectId}
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity}
			/>
		</TranslatedFilterPage>
	);
};
