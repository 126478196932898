import { useCallback, useState } from 'react';
import * as React from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IExportTemplate, {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import {exportSpace} from "@src/store/modules/entities/spaces/actions/export";

const availableDestinations = [ExportTemplatesDestinationId.OBJECT];

export function useSpacesExport(objectId: string): [React.ReactNode, (objectId: string) => void] {
	const [isOpen, setOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const openDialog = useCallback(() => {
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await dispatch(exportSpace(template.id, createDocument, email, objectId));

			setOpen(false);
		},
		[objectId]
	);

	const dialog = (
		<ExportDialog
			pageKey="objects"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
