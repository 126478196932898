import * as React from 'react';
import './MobilePhotoEditorFooter.less';
import classNames from 'classnames';
import {ColorSelectButton, Button, InlineButton} from '../../../../buttons';
import Navigation from '../Navigation';
import {IEntity} from '../../EntitiesMobilePhotoViewer';

interface IPhotoEditorFooterProps {
	className?: string;
	style?: React.CSSProperties;
	type: 'edit' | 'view';
	editable?: boolean;
	hEdit(): void;
	hClear(): void;
	title?: string;
	onChange: (index: number) => void;
	hChangeColor(color: string): void;
	hCancel(): void;
	hSave: (data: any) => void;
	colors: string[];
	selectedColor?: string;
	entities: IEntity[];
	value: number;

}

const MobilePhotoEditorFooter = (props: IPhotoEditorFooterProps) => {
	const {
		className,
		editable,
		type,
		hEdit,
		hClear,
		hChangeColor,
		hCancel,
		hSave,
		colors,
		selectedColor,
		title,
		entities,
		value,
		onChange
	} = props;
	const editIcon = <i className="tz-brush-24"/>;
	const eraseIcon = <i className="tz-eraser-24"/>;
	return (
		<div className={classNames('mobile-photo-viewer__footer', className)}>
			{(editable && type === 'edit')
				&& (
					<div className="mobile-photo-viewer__footer_edit">
						<InlineButton
							leftIcon={eraseIcon}
							onClick={hClear}
						/>
						<div className="mobile-photo-viewer__footer_edit-color">
							{colors.map((color, index) => (
								<ColorSelectButton
									key={index}
									onClick={() => hChangeColor(color)}
									color={`${color}`}
									selected={color === selectedColor}
								/>
							))}
						</div>
					</div>
				)}
			{type === 'view' && (
				<Navigation
					entities={entities}
					value={value}
					onChange={onChange}
				/>
			)}
			<div className="mobile-photo-viewer__footer-info">
				{type === 'view' && <div className="mobile-photo-viewer__footer-info-title">{title}</div>}
				{(editable && type === 'edit')
					&& (
						<div className="mobile-photo-viewer__footer_edit-actions">
							<Button
								type="accent-blue"
								label="Готово"
								onClick={hSave}
							/>
							<Button
								type="cancel"
								label="Отменить"
								onClick={hCancel}
							/>
						</div>
					)}
				{(editable && type === 'view') && (
					<InlineButton
						leftIcon={editIcon}
						onClick={hEdit}
						labelClassName="mobile-photo-viewer__footer_edit-label"
					/>
				)}
			</div>
		</div>
	);
};

export default MobilePhotoEditorFooter;