import { useCallback, useEffect } from 'react';
import {EntitiesTable} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {SelectionRow} from './SelectionRow';
import {useEnrichedList} from '../../hooks/useEnrichedList';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeSelected, getSpacesList} from '@src/store/modules/entities/spaces/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {extractSpacesListData} from '@src/store/modules/entities/spaces/selectors/lists';
import {changeSort} from '@src/store/modules/settings/pages/spaces/actions';
import {convertProblemsData} from '../../utils/convertProblems';
import {convertCheckListsData} from '../../utils/convertCheckListsData.';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {useObjects, useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useExtractCheckListsAsArray} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractCheckItemsGroupedByLists} from '@src/core/hooks/queries/checkItems/hooks';
import {useCheckRecordsStatuses} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {convertWorkAcceptancesData} from '../../utils/convertWorkAcceptances';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';

interface IDesktopTableProps {
	objectId: string;
	columns: Array<Column<IConvertedSpace>>;
	hideHead?: boolean;
	noRowBorder?: boolean;
	selectable?: boolean;
}

const Table = (props: IDesktopTableProps) => {
	const {objectId, columns, hideHead, noRowBorder, selectable} = props;
	const {pushPath} = useChangePath();

	const {isFetched: objectsLoaded} = useObjects();
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const {data: targetObjects} = useTargetObjects(objectId);
	const {offset, selected} = useAppSelector(s => extractSpacesListData(s, objectId));
	const {filters, sort, pageSize} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const {data: problemStatuses} = useProblemStatuses();
	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatuses();
	const {data: checkListsAsArray} = useExtractCheckListsAsArray();
	const {data: checkRecordStatuses} = useCheckRecordsStatuses();
	const {data: checkItemsByLists} = useExtractCheckItemsGroupedByLists();

	const spaces = useEnrichedList(objectId);

	const convertedSpaces: IConvertedSpace[] = spaces?.map(space => ({
		...space,
		problems: convertProblemsData(space.problems, problemStatuses),
		workAcceptances: convertWorkAcceptancesData(space.workAcceptances, workAcceptanceStatuses),
		checkLists: convertCheckListsData(
			checkListsAsArray,
			checkItemsByLists,
			checkRecordStatuses,
			undefined,
			space.checkLists
		)
	}));

	const [, loadSpaces] = useAsyncFn(
		() => dispatch(getSpacesList(objectId, {objects: targetObjects})),
		[objectId, targetObjects, online]
	);

	useEffect(() => {
		if (objectsLoaded) {
			void loadSpaces();
		}
	}, [objectsLoaded]);

	useUpdateEffect(() => {
		void loadSpaces();
	}, [offset, filters, sort, pageSize]);

	const handleRowClick = useCallback(
		(space: IConvertedSpace) => pushPath(formSpaceLink(space.objectId, space.id)),
		[objectId]
	);

	const handleSelection = useCallback(
		(value: string[]) => dispatch(changeSelected(objectId, value)),
		[objectId]
	);

	const handleSortChange = useCallback(
		(value: Record<string, boolean>) => dispatch(changeSort(objectId, value)),
		[objectId]
	);

	return (
		<EntitiesTable
			columns={columns}
			data={convertedSpaces}
			sort={sort}
			selectedRows={selected}
			selectable={selectable}
			noRowBorder={noRowBorder}
			headVisible={!hideHead}
			onRowClick={handleRowClick}
			onSelectedRowsChange={handleSelection}
			onSortChange={handleSortChange}
			renderSelectionRow={SelectionRow}
		/>
	);
};

export default Table;
