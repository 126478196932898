import {createReducer} from '@reduxjs/toolkit';
import {IFullSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/IFullSpaceStatusesSet';
import * as types from './constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeOffsetPayload} from '@src/store/interfaces/IChangeOffsetPayload';
import {IChangeSelectedRowsPayload} from '@src/store/interfaces/IChangeSelectedRowsPayload';

export interface ISpaceStatusesSetsFiltersState extends Record<string, unknown> {
	search?: string;
	objects?: string[];
	companies?: string[];
	createdBy?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
}

export type ISpaceStatusesSetsSortState = Partial<Record<keyof IFullSpaceStatusesSet, boolean>>;

export interface ISpaceStatusesSetsPageSettingsState {
	filters: ISpaceStatusesSetsFiltersState;
	sort: ISpaceStatusesSetsSortState;
	selectedRows: string[];
	pageSize: number;
	offset: number;
}

export const getInitialStateForPage = (): ISpaceStatusesSetsPageSettingsState => ({
	filters: {
		objects: undefined
	},
	sort: {
		modifiedAt: false
	},
	selectedRows: [],
	pageSize: 20,
	offset: 0
});

export const spaceStatusesSets = createReducer<ISpaceStatusesSetsPageSettingsState>(
	getInitialStateForPage,
	{
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<ISpaceStatusesSetsFiltersState>}
		) => {
			const {filters} = payload;

			state.filters = filters;
		},
		[types.CLEAR_FILTERS]: state => {
			const filters = {} as ISpaceStatusesSetsFiltersState;

			state.filters = filters;
		},
		[types.CHANGE_SORT]: (
			state,
			{payload}: {payload: IChangeSortPayload<ISpaceStatusesSetsSortState>}
		) => {
			const {sort} = payload;

			state.sort = sort;
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: IChangeOffsetPayload}
		) => {
			const {offset} = payload;

			state.offset = offset;
		},
		[types.CHANGE_PAGE_SIZE]: (
			state,
			{payload}: {payload: IChangePageSizePayload}
		) => {
			const {pageSize} = payload;

			state.pageSize = pageSize;
		},
		[types.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: {payload: IChangeSelectedRowsPayload}
		) => {
			const {selectedRows} = payload;

			state.selectedRows = selectedRows;
		}
	}
);