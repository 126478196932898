import {useExtractCategoriesAsMap} from '@src/core/hooks/queries/categories/hook';
import {EntityGridItem, InlineButton} from '@tehzor/ui-components';
import classNames from 'classnames';
import {memo, useState} from 'react';

interface ICategoriesCellProps {
	categoryIds?: string[];
}

const NUMBER_OF_SHOWN = 2;

const categoriesIcon = <i className="tz-category-20" />;

export const CategoriesCell = memo(({categoryIds}: ICategoriesCellProps) => {
	const [isShown, setShown] = useState(false);
	const handleClick = () => setShown(s => !s);

	const {data: categoriesMap} = useExtractCategoriesAsMap();

	if (!categoryIds?.length) {
		return null;
	}
	const categories = categoriesMap && categoryIds?.map(id => categoriesMap[id]?.name);

	const shownCategories = categories
		?.filter((_, i) => (!isShown ? i + 1 <= NUMBER_OF_SHOWN : true))
		.join(', ');

	const numberOfRemainingCategories =
		categories && categories.length > NUMBER_OF_SHOWN ? categories.length : 0;

	return (
		<EntityGridItem
			className={{
				root: 'contract__cells-item',
				header: 'contract__cells-item-header',
				content: classNames('contract__cells-item-content', 'contract__cells-categories')
			}}
			label="Виды работ"
			icon={categoriesIcon}
			inline
		>
			<div>{shownCategories}</div>
			{!!numberOfRemainingCategories && (
				<InlineButton
					label={!isShown ? `Показать все (${numberOfRemainingCategories})` : 'Скрыть'}
					type="accent"
					onClick={handleClick}
				/>
			)}
		</EntityGridItem>
	);
});
