import SelectPopup, {
	ISelectPopupProps
} from '@tehzor/ui-components/src/components/inputs/select/SelectPopup/SelectPopup';
import {useTranslation} from 'react-i18next';

type TranslatedSelectPopupProps = ISelectPopupProps;

export const TranslatedSelectPopup = (props: TranslatedSelectPopupProps) => {
	const {t} = useTranslation();
	const translations = {
		selectSelectedCountLabel: t('selectPopup.selectedCount.label'),
		selectSelectAllBtnLabel: t('selectPopup.selectSelectAllBtn.label'),
		selectApplyBtnLabel: t('applyBtn.label'),
		selectCancelBtnLabel: t('cancelBtn.label'),
		selectClearAllBtnLabel: t('selectPopup.selectClearAllBtn.label'),
		selectClearBtnLabel: t('selectPopup.selectClearBtn.label')
	};
	return (
		<SelectPopup
			translationsProps={translations}
			{...props}
		/>
	);
};
