import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

/**
 * Возвращает полномочия для конкретной приёмки работ
 *
 * @param workAcceptance приёмка работ
 * @param networkStatus статус соединения
 * @param objectId id объекта
 * @param user текущий пользователь
 */
export const getPermissionsForWorkAcceptance = (
	workAcceptance: IWorkAcceptance,
	networkStatus: boolean,
	objectId?: string,
	user?: IUser
) => {
	let canDelete = false;
	if (networkStatus && user) {
		const deletePermission = findPermission(
			user.roles || [],
			'work-acceptances-delete',
			UserRoleScopes.OBJECT,
			objectId
		);
		canDelete = !!deletePermission;
	}
	return {canDelete};
};
