import {StatsTreePanel, VerticalTabContent} from '@tehzor/ui-components';
import ProblemsStatsChart from './ProblemsStatsChart';
import {ContractorsStatsMap} from '@src/utils/statsAggregators/problemsByStatuses';
import {WorkerStats} from './WorkerStats';
import {sumStatsValues} from '../../../utils/sumStatsValues';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';
import {useNavigateProblems} from '@src/pages/StatisticsPage/hooks/useNavigateProblems';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';

interface IContractorStatsProps {
	contractor: ICompany;
	contractorStats?: ContractorsStatsMap['id'];
	users: string[];
	deletedUsers: string[];
}

export const ContractorStats = ({
	contractor,
	contractorStats,
	users,
	deletedUsers
}: IContractorStatsProps) => {
	const sourcesLoading = useAppSelector(extractSourcesLoading);
	const totalContractorCount = contractorStats
		? contractorStats.stats?.expired
			? sumStatsValues(contractorStats.stats) - contractorStats.stats?.expired
			: sumStatsValues(contractorStats.stats)
		: 0;

	const navigate = useNavigateProblems(
		{
			navProps: [
				{
					type: 'respUsers',
					prop: [...users, ...deletedUsers]
				}
			],
			page: 'warranty'
		},
		undefined
	);

	const handleClick = (status: string) => {
		void navigate(status);
	};

	return (
		<VerticalTabContent className="statistics-page__tabs-content-panel">
			<StatsTreePanel
				id={contractor.id}
				title={contractor.name}
				loading={sourcesLoading}
				showExpandBtnPlaceholder
				permanentContent={(
					<ProblemsStatsChart
						onClick={handleClick}
						stats={contractorStats?.stats}
						totalCount={totalContractorCount}
					/>
				)}
				hiddenContent={users.map(userId => (
					<WorkerStats
						key={userId}
						userId={userId}
						stats={contractorStats?.usersStats[userId]}
						level={1}
					/>
				))}
			/>
		</VerticalTabContent>
	);
};
