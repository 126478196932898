import { memo } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {DesktopMainActions} from './actions/MainActions.desktop';
import {useWarrantyClaimBreadcrumbs} from '@src/core/hooks/breadcrumbs/useWarantyClaimBreadcrumbs';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IPageBreadcrumbsProps {
	objectId: string;
	warrantyClaim: IWarrantyClaim;
}

export const PageBreadcrumbs = memo(({objectId, warrantyClaim}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useWarrantyClaimBreadcrumbs(
		objectId,
		warrantyClaim.id,
		warrantyClaim.number
	);
	const isDesktop = useIsDesktop();

	if (!breadcrumbs) return null;

	return (
		<AppBreadcrumbs
			className="warranty-claim-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && (
				<DesktopMainActions
					objectId={objectId}
					warrantyClaim={warrantyClaim}
				/>
			)}
		</AppBreadcrumbs>
	);
});
