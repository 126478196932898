import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import useAppSelector from '../useAppSelector';
import {useMemo} from 'react';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {checkRestrictions} from '@tehzor/tools/utils/permissions/restrictions/checkRestrictions';

export interface IInspectionResponsiblePermissions {
	canEditPerformers?: boolean;
	onlyActiveGroup?: boolean;
}

export const useInspectionResponsiblePermissions = (
	objectId: string,
	inspection: ILinkedInspection
): IInspectionResponsiblePermissions => {
	const user = useAppSelector(extractUserProfile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		let onlyActiveGroup = false;

		const permission = findPermission(
			user.roles,
			'inspections-performers-edit',
			UserRoleScopes.OBJECT,
			objectId
		);
		if (!permission) {
			return {canEditPerformers: false, onlyActiveGroup};
		}

		const restrictions = permission.restrictions;
		if (!restrictions) {
			return {canEditPerformers: true, onlyActiveGroup};
		}

		if (restrictions.onlyActiveGroup !== undefined) {
			onlyActiveGroup = typeof restrictions.onlyActiveGroup === 'boolean';
		}

		const canEditPerformers = checkRestrictions(restrictions, {userId: user.id, inspection});
		return {canEditPerformers, onlyActiveGroup};
	}, [objectId, inspection, user]);
};
