import {Button} from '@tehzor/ui-components';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

interface IDesktopEntityAddingProps {
	objectId: string;
}

const plusIcon = <i className="tz-plus-20" />;

const DesktopEntityAdding = ({objectId}: IDesktopEntityAddingProps) => {
	const {t} = useTranslation();
	const {data: object} = useObject(objectId);

	const [dialog, open] = useAddingCheckDialog({
		objectId,
		stage: object?.stage
	});

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={plusIcon}
				label={t('checksPage.entityAdding.addButton')}
				onClick={open}
			/>
			{dialog}
		</>
	);
};

export default DesktopEntityAdding;
