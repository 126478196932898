import * as React from 'react';
import {EntityInfo, IEntityInfoProps} from '../EntityInfo';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

interface IWorkingGroupInfoProps extends Omit<IEntityInfoProps, 'title' | 'subTitle' | 'icon'> {
	name: string;
	leader?: IBriefUser;
	icon?: React.ReactNode;
}

export const WorkingGroupInfo = (props: IWorkingGroupInfoProps) => {
	const {
		name,
		leader,
		icon = <i className="tz-users-24"/>,
		iconColor = '#718198',
		iconSize = 40,
		backgroundColor = '#FFDEB8',
		...rest
	} = props;

	return (
		<EntityInfo
			{...rest}
			title={name}
			subTitle={leader?.displayName}
			icon={icon}
			iconColor={iconColor}
			iconSize={iconSize}
			backgroundColor={backgroundColor}
		/>
	);
};