import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IconButton} from '@tehzor/ui-components';
import {useDeleteFieldsSettingsSets} from '../../../hooks/useDeleteFieldsSettingsSets/useDeleteFieldsSettingsSets';
import {useCallback} from "react";

interface ISelectedEntitiesDeletionFieldsSettingsSets {
	selectedEntities: IPreparedFieldSettingsSet[];
	selectedClearing: () => void;
}

export const SelectedEntitiesDeletionFieldsSettingsSets = ({
	selectedEntities,
	selectedClearing
}: ISelectedEntitiesDeletionFieldsSettingsSets) => {
	const deleteFieldsSettingsSets = useDeleteFieldsSettingsSets();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить набор',
		'Вы действительно хотите удалить выбранные наборы?',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			deleteFieldsSettingsSets(selectedEntities);
		}
		selectedClearing();
	}, [deleteFieldsSettingsSets, getDeleteConfirmation, selectedClearing, selectedEntities]);

	return (
		<>
			<IconButton onClick={handleDelete}>
				<i className="tz-delete" />
			</IconButton>

			{deleteDialog}
		</>
	);
};
