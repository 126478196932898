import {MutableRefObject, useCallback, useEffect, useRef} from 'react';
import IUploadingFile from '../../interfaces/IUploadingFile';
import {UploadingFileStatus} from '../../enums/UploadingFileStatus';
import {QueueObject} from 'async';

/**
 * Хук. Нужен для отслеживания состояния сети.
 * Online - Ставит файлы обратно в очередь.
 * Offline - Очищает очередь и помечает их как локальные.
 * @param {boolean | undefined} online
 * @param {IUploadingFile[]} uploadingFiles
 * @param {() => void} abortFilesUploading
 * @param {(value: IUploadingFile[]) => void} onUploadingFilesChange
 * @param {MutableRefObject<QueueObject<string>>} queueHandler
 * @param {MutableRefObject<string[]>} processingKeys
 */
export const useConnectionHandler = (
	online: boolean | undefined,
	uploadingFiles: IUploadingFile[],
	abortFilesUploading: () => void,
	onUploadingFilesChange: (value: IUploadingFile[]) => void,
	queueHandler: MutableRefObject<QueueObject<string>>,
	processingKeys: MutableRefObject<string[]>
) => {
	const networkStatusRef = useRef<boolean>();

	const handleConnected = useCallback(() => {
		networkStatusRef.current = true;
		onUploadingFilesChange(
			uploadingFiles.map(file => ({
				...file,
				status: file.tempFile ? UploadingFileStatus.FINISHED : UploadingFileStatus.WAITING
			}))
		);
		queueHandler.current.resume();
	}, [uploadingFiles, onUploadingFilesChange]);

	const handleDisconnected = useCallback(() => {
		networkStatusRef.current = false;
		queueHandler.current.pause();
		queueHandler.current.remove(() => true);
		abortFilesUploading();
		onUploadingFilesChange(
			uploadingFiles.map(file => {
				processingKeys.current.splice(processingKeys.current.indexOf(file.key), 1);
				return {
					...file,
					status: UploadingFileStatus.LOCAL
				};
			})
		);
	}, [abortFilesUploading, onUploadingFilesChange, uploadingFiles]);

	useEffect(() => {
		// Первый рендер, не вызываем хандлеры если статус online
		if (networkStatusRef.current === undefined && online) {
			networkStatusRef.current = true;
			return;
		}
		// Последующие ререндеры
		if (networkStatusRef.current !== undefined) {
			if (online) {
				// Если ref true, то либо хандлер уже вызывался, либо ререндер при неизменном online
				if (networkStatusRef.current) return;
				handleConnected();
			} else {
				handleDisconnected();
			}
		}
	}, [online, handleConnected, handleDisconnected]);
};
