import {ISavingNewAttachment} from '@src/interfaces/attachments/ISavingAttachment';
import {TempFileDestination} from '@tehzor/tools/enums/TempFileDestination';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';

export const convertRawToUploading = (
	raw: IRawFile,
	destination: TempFileDestination,
	status = UploadingFileStatus.WAITING,
	sizeError: boolean
): IUploadingFile => ({
	...raw,
	status,
	sizeError,
	destination,
	progress: 0
});

export const convertUploadingToSaving = (item: IUploadingFile): ISavingNewAttachment => ({
	id: item.tempFile?.id
});

export const convertUploadingToLocalSaving = (item: IUploadingFile): ISavingNewAttachment => ({
	id: item.tempFile?.id,
	file: item.original,
	originalFile: item.canvas?.originalFile,
	drawData: item.canvas?.drawedData,
	key: item.key
});
