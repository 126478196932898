import {IDeleteContractParams} from '@src/api/cache/contracts/defaults/mutations/delete';
import {contractsQueryKeys} from '@src/api/cache/contracts/keys';
import {useDeleteContractMutation} from '@src/api/cache/contracts/mutations';
import {useQueryClient} from '@tanstack/react-query';

export const useDeleteContract = () => {
	const queryClient = useQueryClient();
	const mutation = useDeleteContractMutation();

	return async (params: IDeleteContractParams) => {
		await mutation.mutateAsync(params);
		await queryClient.invalidateQueries({queryKey: contractsQueryKeys.all()});
	};
};
