import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useAddingWorkAcceptanceCheckListProblemDialog} from '@src/pages/WorkAcceptancePage/hooks/useAddingWorkAcceptanceCheckListProblemDialog';

interface IMobileItemHeaderActionsProps {
	listId: string;
	itemId: string;
}

export const MobileItemHeaderActions = (props: IMobileItemHeaderActionsProps) => {
	const {listId, itemId} = props;
	const [dialog, handleOpen] = useAddingWorkAcceptanceCheckListProblemDialog(listId, itemId);

	return (
		<div>
			<IconMenu>
				<MenuItem
					icon={<i className="tz-plus-24" />}
					onClick={handleOpen}
				>
					Добавить нарушение
				</MenuItem>
			</IconMenu>
			{dialog}
		</div>
	);
};
