import {Column} from 'react-table';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemStatusCell} from '@src/components/tableCells/ProblemStatusCell';
import {ProblemFixCell} from '@src/components/tableCells/ProblemFixCell';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import {useMemo} from 'react';
import {useWarrantyClaimProblemCommentsByProblem} from '@src/core/hooks/queries/warrantyClaim/problemsReplies/hooks';
import {useTranslation} from 'react-i18next';

export const useDesktopColumns = (
	claimId?: string,
	objectId?: string
): Array<Column<IWarrantyClaimEntity>> => {
	const {t} = useTranslation();
	const {data: replies} = useWarrantyClaimProblemCommentsByProblem(claimId, objectId);

	return useMemo(
		() => [
			{
				id: 'number',
				Header: HeaderCellWithRowSelectWrap(t('warrantyClaimPage.table.columns.number.header')),
				Cell: CellWrap({
					problem: ProblemInfoCellWrap(ProblemInfoDesktopCell, undefined, replies)
				}),
				width: 200,
				minWidth: 200
			},
			{
				id: 'status',
				Header: t('warrantyClaimPage.table.columns.status.header'),
				Cell: CellWrap({
					problem: ProblemStatusCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'category',
				Header: t('warrantyClaimPage.table.columns.category.header'),
				Cell: CellWrap({
					problem: CategoryCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'fix',
				Header: t('warrantyClaimPage.table.columns.fix.header'),
				Cell: CellWrap({problem: ProblemFixCell}),
				width: 80,
				minWidth: 80
			}
		],
		[replies]
	);
};
