import { useCallback } from 'react';
import {Button} from '@tehzor/ui-components';
import {useCheckExport} from '@src/core/hooks/export/useCheckExport';
import {useTranslation} from 'react-i18next';

interface ICheckExportProps {
	objectId: string;
	checkId: string;
}

const exportIcon = <i className="tz-export-20"/>;

const DesktopCheckExportBtn = ({objectId, checkId}: ICheckExportProps) => {
	const {t} = useTranslation();

	const [exportDialog, openExportDialog] = useCheckExport(objectId);

	const handleExport = useCallback(() => {
		openExportDialog(checkId);
	}, [checkId, openExportDialog]);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={exportIcon}
				label={t('checksPage.actions.export')}
				onClick={handleExport}
			/>
			{exportDialog}
		</>
	);
};

export default DesktopCheckExportBtn;
