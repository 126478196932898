import {ObjectImage} from '@src/pages/ObjectsPage/components/item/components/ObjectImage';
import {DesktopPhotoViewer, MobilePhotoViewer} from '@tehzor/ui-components';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useCallback, useMemo, useState} from 'react';
import {IDesktopPhotoViewerPropsTranslations} from '@tehzor/ui-components/src/components/photos/DesktopPhotoViewer';
import {useTranslation} from 'react-i18next';
import '../ObjectPage.less';

interface IImageProps {
	title: string;
	fullImage?: string;
	previewImage?: string;
}

export const Image = ({title, fullImage, previewImage}: IImageProps) => {
	const {t} = useTranslation();

	const [isImgOpen, setImgOpen] = useState(false);

	const handleImgOpen = useCallback(() => setImgOpen(true), []);
	const handleImgClose = useCallback(() => setImgOpen(false), []);

	const isTablet = useIsTablet();

	const translations = useMemo<IDesktopPhotoViewerPropsTranslations>(
		() => ({
			desktopPhotoEditorFooter: {
				applyBtn: t('readyBtn.label'),
				cancelBtn: t('cancelBtn.label'),
				editBtn: t('editBtn.label')
			}
		}),
		[t]
	);

	return (
		<>
			<ObjectImage
				image={previewImage}
				className="object-page__image"
				onClick={fullImage ? handleImgOpen : undefined}
			/>
			{fullImage &&
				(isTablet ? (
					<DesktopPhotoViewer
						title={title}
						isOpen={isImgOpen}
						onClose={handleImgClose}
						data={fullImage ? [fullImage] : undefined}
						translations={translations}
					/>
				) : (
					<MobilePhotoViewer
						isOpen={isImgOpen}
						onClose={handleImgClose}
						data={fullImage ? [fullImage] : undefined}
					/>
				))}
		</>
	);
};
