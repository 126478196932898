import {useMemo} from 'react';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

/**
 * Хук для хлебных крошек от "всех объектов" до приемок собственником
 *
 * @param objectId id конечного объекта
 */
export function useOwnerAcceptancesBreadcrumbs(objectId: string) {
	const {t} = useTranslation();
	const objects = useObjectsData();
	return useMemo(() => {
		if (!objects) return [];
		const items = formObjectsChain(objects.byId, objectId, 'problems', t);
		items.push({
			label: t('ownerAcceptancesPage.useOwnerAcceptancesBreadcrumbs.item.label'),
			url: `/objects/${objectId}/owner-acceptances`,
			inactive: false
		});
		return items;
	}, [objectId, objects]);
}
