import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';

export type IGetProblemResponse = ILinkedProblem;

/**
 * Получает нарушение по id
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const requestProblem = async (objectId: string, problemId: string) => {
	const response = await httpRequests.withToken.get<IGetProblemResponse>(
		`problems/${problemId}`,
		{params: {objectId}}
	);

	return response.data;
};
