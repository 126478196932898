import ContentLoader from 'react-content-loader';

export const MobileTypesFilterLoader = () => (
	<ContentLoader
		speed={2}
		width={160}
		height={40}
		viewBox="0 0 160 40"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="0" y="0" rx="10" ry="10" width="157" height="40" />
	</ContentLoader>
);
