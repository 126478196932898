import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {OfflineJournalItem} from './components/OfflineJournalItem/OfflineJournalItem';
import {OfflineJournalFilters} from './components/OfflineJournalFilters/OfflineJournalFilters';
import {OfflineJournalPageCounter} from './components/OfflineJournalPageCounter/OfflineJournalPageCounter';
import {OfflineJournalSort} from './components/OfflineJournalSort/OfflineJournalSort';
import {MobileOfflineJournalFiltersBtn} from './components/MobileOfflineJournalFiltersBtn/MobileOfflineJournalFiltersBtn';
import './OfflineJournalPage.less';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';
import {useJournalData} from './hooks/useJournalData';
import {OfflineJournalPageBreadcrumbs} from '@src/pages/OfflineJournalPage/components/OfflineJournalPageBreadcrumbs';

export const OfflineJournalPage = () => {
	const isDesktop = useIsDesktop();
	const journalData = useJournalData();
	const {state} = useEntitiesFiltersCtx<IOfflineJournalFiltersState>();
	const isFiltersActive = useFiltersActive('all', state);
	return (
		<div className="page-cont offline-journal-page">
			<div className="offline-journal-page__header">
				<OfflineJournalPageBreadcrumbs />
				{isDesktop && (
					<OfflineJournalFilters
						className="offline-journal-page__header-filters"
						isFiltersActive={isFiltersActive}
					/>
				)}
				<div className="offline-journal-page__header-display">
					<OfflineJournalPageCounter length={journalData.length} />
					<div className="offline-journal-page__header-display-filters">
						<OfflineJournalSort />
						{!isDesktop && (
							<MobileOfflineJournalFiltersBtn isFiltersActive={isFiltersActive} />
						)}
					</div>
				</div>
			</div>
			<div className="offline-journal-page__list">
				{journalData.map(entity => (
					<OfflineJournalItem
						offlineJournalEntity={entity}
						key={entity.entity.entityId}
					/>
				))}
			</div>
		</div>
	);
};
