import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import { memo } from 'react';
import {IPreparedLegal} from '../../interfaces/IPreparedLegal';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useDeleteLegals} from '@src/core/hooks/mutations/legals/useDeleteLegals';

interface ISelectionActionsProps {
	selectedEntities: IPreparedLegal[];
	onClear: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const LegalsActions = memo(({selectedEntities = [], onClear}: ISelectionActionsProps) => {
	const canDelete = selectedEntities.every(item => item.canDelete);
	const deleteLegals = useDeleteLegals();
	const updateAppData = useAppUpdateMenuItem();

	const items = [];

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить юрлица',
		'Вы действительно хотите удалить выбранные юрлица?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleDelete = async () => {
		if (await getDeleteConfirmation()) {
			await deleteLegals(selectedEntities);
		}
		onClear();
	};

	items.push(updateAppData);

	if (canDelete && selectedEntities.length) {
		items.push(
			<>
				<MenuItem
					key="delete"
					icon={deleteIcon}
					onClick={handleDelete}
				>
					Удалить
				</MenuItem>
				{deleteDialog}
			</>
		);
	}

	return <IconMenu>{items}</IconMenu>;
});
