import {CSSProperties, ReactNode, useCallback} from 'react';
import './SwitchMenu.less';
import classNames from 'classnames';
import {SwitchMenuContext} from './SwitchMenuContext';

interface IToggleButtonGroupProps {
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
	value: string;
	onChange?: (value: string) => void;
}

export const SwitchMenu = (props: IToggleButtonGroupProps) => {
	const {className, style, children, value, onChange} = props;

	const isChecked = useCallback((itemValue: string) => value === itemValue, [value]);

	return (
		<div className={classNames(className, 'switch-menu')} style={style}>
			<div className="switch-menu__wrap">
				<SwitchMenuContext.Provider value={{value, isChecked, onChange}}>
					{children}
				</SwitchMenuContext.Provider>
			</div>
		</div>
	);
};
