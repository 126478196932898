import {useMemo} from 'react';
import {IDatesOptions} from '../Calendar';
import {getMonthName} from '../utils/getMonthName';

export interface IMonthData {
	value: number;
	name: string;
}

export const useMonthsGrid = (datesOptions?: IDatesOptions) => useMemo(() => {
		const months: IMonthData[] = [];
		for (let i = 0; i < 12; i++) {
			months.push({
				value: i,
				name: getMonthName(i, datesOptions?.locale)
			});
		}
		return months;
	}, [datesOptions]);
