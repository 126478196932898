import {PageHeader} from '@tehzor/ui-components';
import {useInspections} from '@src/core/hooks/queries/inspections/hooks';
import {useTranslation} from 'react-i18next';

interface IProblemsPageHeaderProps {
	objectId?: string;
}

const InspectionsPageCounter = ({objectId = 'all'}: IProblemsPageHeaderProps) => {
	const {t} = useTranslation();
	const {data: inspectionsData} = useInspections(objectId);

	return (
		<PageHeader
			className="inspections-page__header"
			title=""
			subTitle={t('inspectionsPage.inspectionsPageCounter.subTitle', {
				count: inspectionsData?.total ?? 0
			})}
			wrapClassName="inspections-page__header-wrap"
			leftSideClassName="inspections-page__header-left"
			rightSideClassName="inspections-page__header-right"
		/>
	);
};

export default InspectionsPageCounter;
