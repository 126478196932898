import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {Tree} from 'array-to-tree';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {getInitialState} from '../reducers/inspections';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

const getTreeItemIds = (item: Tree<ICheckItem>): string[] => {
	const subIds = [];

	if (item.children) {
		subIds.push(...item.children.map(getTreeItemIds).flat(Infinity));
	}

	return Array.from(new Set([item.id, ...subIds])) as string[];
};

/**
 * Возвращает осмотры для конкретного объекта
 */
export const extractInspectionsData = createSelector(
	(state: IState) => state.pages.checkLists.inspections,
	data => data || getInitialState()
);

/**
 * Возвращает локальные осмотры
 */
export const extractLocalInspectionsData = createSelector(
	(state: IState) => state.localEntities.inspections,
	data => data || getInitialState()
);

/**
 * Возвращает осмотры всех вложенных подкатегорий в виде массива
 */
export const extractInspectionsAndSubInspectionsByItemIdAsArray = createSelector(
	[extractInspectionsData, (state: IState, treeCheckItem?: Tree<ICheckItem>) => treeCheckItem],
	(data, treeCheckItem) => {
		const array = treeCheckItem ? getTreeItemIds(treeCheckItem) : [];
		return data.allIds.reduce((acc: IInspection[], id) => {
			const inspection = data.byId[id];

			if (inspection?.links?.checkItemId && array.includes(inspection?.links?.checkItemId)) {
				acc.push(inspection);
			}

			return acc;
		}, []);
	}
);

/**
 * Возвращает локальные осмотры всех вложенных подкатегорий в виде массива
 */
export const extractLocalInspectionsAndSubInspectionsByItemIdAsArray = createSelector(
	[
		extractLocalInspectionsData,
		(state: IState, treeCheckItem?: Tree<ICheckItem>) => treeCheckItem,
		(state: IState, treeCheckItem?: Tree<ICheckItem>, spaceId?: string) => spaceId
	],
	(data, treeCheckItem, spaceId) => {
		const array = treeCheckItem ? getTreeItemIds(treeCheckItem) : [];
		return data
			.filter(inspection => !!(inspection?.links?.checkItemId &&
					array.includes(inspection?.links?.checkItemId) &&
					inspection?.links?.spaceId === spaceId))
			.map(local => ({id: local.key, ...local}));
	}
);

/**
 * Возвращает осмотры для чек-листа в виде массива
 */
export const extractInspectionsByCheckListIdAsArray = createSelector(
	[extractInspectionsData, (state: IState, checkListId: string) => checkListId],
	(data, checkListId) =>
		data.allIds.reduce((acc: IInspection[], id) => {
			const inspection = data.byId[id];

			if (inspection?.links?.checkListId === checkListId) {
				acc.push(inspection);
			}

			return acc;
		}, [])
);

export const extractInspectionsEntitiesByList = createSelector(
	[extractInspectionsByCheckListIdAsArray],
	inspections => inspections.map(inspection => ({id: inspection.id, type: 'inspection', data: inspection}))
);

export const extractInspectionsEntitiesByItem = createSelector(
	[
		extractInspectionsAndSubInspectionsByItemIdAsArray,
		extractLocalInspectionsAndSubInspectionsByItemIdAsArray
	],
	(inspections, localInspections) => [
		...inspections.map(inspection => ({id: inspection.id, type: 'inspection', data: inspection})),
		...localInspections.map(linspection => ({
			id: linspection.key,
			type: 'local_inspection',
			data: linspection
		}))
	]
);