import declination from '@tehzor/tools/utils/declination';
import {CellProps} from 'react-table';
import {IPreparedCheck} from '../../interfaces/IPreparedCheck';

const StatsCell = ({row}: CellProps<IPreparedCheck>) => {
	const {stats} = row.original;
	if (!stats) {
		return null;
	}

	const {inspections} = stats;
	// const problemsCount = (problems?.waiting || 0)
	// 	+ (problems?.handling || 0)
	// 	+ (problems?.verification || 0)
	// 	+ (problems?.fixed || 0);
	const problemsCount = 0;

	return (
		<>
			{!!problemsCount && (
				<div>
					{problemsCount}
					{' '}
					{declination(problemsCount, ['нарушение', 'нарушения', 'нарушений'])}
				</div>
			)}
			{!!inspections && (
				<div>
					{inspections}
					{' '}
					{declination(inspections, ['осмотр', 'осмотра', 'осмотров'])}
				</div>
			)}
		</>
	);
};

export default StatsCell;
