import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as replyTypes from '@src/store/modules/entities/problemReply/constants';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IGetLastProblemReplyPayload} from './actions';
import {
	IAddProblemCommentPayload,
	IDeleteProblemCommentPayload,
	IEditProblemCommentPayload
} from '@src/store/modules/entities/problemComment/actions';

export interface IProblemsLastRepliesState {
	[problemId: string]: IComment | undefined;
}

export default createReducer<IProblemsLastRepliesState>(
	{},
	{
		[types.GET_SUCCESS]: (state, {payload}: {payload: IGetLastProblemReplyPayload}) => {
			state[payload.problemId] = payload.reply || undefined;
		},
		[replyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemCommentPayload}) => {
			if (payload.links?.problemId && state.hasOwnProperty(payload.links?.problemId)) {
				state[payload.links.problemId] = payload;
			}
		},
		[replyTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditProblemCommentPayload}) => {
			if (
				payload.links?.problemId &&
				state.hasOwnProperty(payload.links.problemId)
				&& state[payload.links.problemId]?.id === payload.id
			) {
				state[payload.links.problemId] = payload;
			}
		},
		[replyTypes.DELETE_SUCCESS]: (
			state,
			{payload}: {payload: IDeleteProblemCommentPayload}
		) => {
			if (
				state.hasOwnProperty(payload.problemId)
				&& state[payload.problemId]?.id === payload.commentId
			) {
				delete state[payload.problemId];
			}
		}
	}
);
