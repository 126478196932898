import {CacheStatus} from '@tehzor/tools/enums/CacheStatus';

export const getIconClass = (cachingStatus?: CacheStatus, syncActive?: boolean, notSupported?: boolean) => {
	if (syncActive) {
		return 'tz-cloud-sync';
	}
	if (notSupported) {
		return 'tz-cloud-caching-error';
	}
	if (cachingStatus) {
		if (cachingStatus === CacheStatus.CACHING) {
			return 'tz-cloud-caching-active';
		}
		// if (cachingStatus === CacheStatus.DONE) {
		// 	return 'tz-cloud-caching-done';
		// }
		if (cachingStatus === CacheStatus.ERROR) {
			return 'tz-cloud-caching-error';
		}
	}
	return 'tz-cloud';
};
