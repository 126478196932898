import {useMemo} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Desktop} from './components/Desktop';
import {Mobile} from './components/Mobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import './SpacePage.less';
import {useSpaceStage} from '@src/core/hooks/spaces/useSpaceStage';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {getCheckRecords} from '@src/store/modules/pages/checkLists/actions';
import {useHasUnfinished} from './hooks/useHasUnfinished';
import {useSpace} from '@src/core/hooks/queries/space';
import {LoadingPanel} from '@tehzor/ui-components';
import {useSpacePageIsLoading} from './hooks/useSpaceIsLoading';
import {SpacesPageVariants} from '@src/interfaces/SpacesPageVariants';

export const SpacePage = ({spacesPageVariants}: {spacesPageVariants: SpacesPageVariants}) => {
	useScrollRestoration();

	const {objectId, spaceId} = useStrictParams<{objectId: string; spaceId: string}>();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const {data: space} = useSpace(spaceId, objectId);
	const stage = useSpaceStage(objectId, space);
	const checkLists = useAvailableCheckLists(objectId, spaceId, 'all');
	const checkListsIds = useMemo(() => checkLists?.map(cl => cl.id), [checkLists]);
	const [hasUnfixedProblems, hasUnacceptedCheckItem] = useHasUnfinished(
		checkListsIds,
		objectId,
		spaceId
	);

	useAsync(async () => {
		await dispatch(getCheckRecords(checkListsIds, [objectId], spaceId));
	}, [checkListsIds, objectId, spaceId]);

	const loading = useSpacePageIsLoading();

	if (!space) {
		return (
			<LoadingPanel
				className="space-page__loading-panel"
				active={!space}
			/>
		);
	}

	return (
		<LoadingPanel
			className="space-page__loading-panel"
			active={loading}
		>
			{isDesktop ? (
				<Desktop
					objectId={objectId}
					spaceId={spaceId}
					space={space}
					stage={stage}
					hasUnfixedProblems={hasUnfixedProblems}
					hasUnacceptedCheckItem={hasUnacceptedCheckItem}
					spacesPageVariants={spacesPageVariants}
				/>
			) : (
				<Mobile
					objectId={objectId}
					spaceId={spaceId}
					space={space}
					hasUnfixedProblems={hasUnfixedProblems}
					hasUnacceptedCheckItem={hasUnacceptedCheckItem}
					spacesPageVariants={spacesPageVariants}
				/>
			)}
		</LoadingPanel>
	);
};
