import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

export type IEditSpaceStatusResponse = ISpace;

/**
 * Изменяет статус помещения
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param status статус
 */
export const makeSpaceStatusEditRequest = async (
	objectId: string,
	spaceId: string,
	status: string
) => {
	const response = await httpRequests.withToken.patch<IEditSpaceStatusResponse>(
		'spaces/status/edit',
		{
			objectId,
			spaceId,
			status
		}
	);
	return response.data;
};
