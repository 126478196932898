import {memo, useMemo, useState} from 'react';
import {FilterButton, Select2, SelectOption, SelectSearch} from '@tehzor/ui-components';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {useTranslation} from 'react-i18next';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {reopenedTimesItems} from '@src/constants/filters/reopenedTimesFilter';

interface IReopenedTimesFilterProps {
	label?: string;
	reopenedTimes?: string[];
}

export const ReopenedTimesFilter = memo((props: IReopenedTimesFilterProps) => {
	const {t} = useTranslation();
	const {label = 'Выдано повторно', reopenedTimes} = props;
	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedReopenedTimes, setSelectedReopenedTimes] = useState(reopenedTimes);
	const [search, setSearch] = useState('');

	const filteredData = useMemo(() => flatFilter(reopenedTimesItems, 'name', search), [search]);

	const handleApply = () => {
		dispatch({reopenedTimes: selectedReopenedTimes});
		setSearch('');
	};

	const handleClear = () => {
		setSelectedReopenedTimes([]);
		setSearch('');
	};

	const handleFullClear = () => {
		setSelectedReopenedTimes([]);
		dispatch({reopenedTimes: undefined});
		setSearch('');
	};

	const handleCancel = () => {
		setSelectedReopenedTimes(reopenedTimes);
		setSearch('');
	};

	const handleSelectAll = () => {
		setSearch('');
		setSelectedReopenedTimes(reopenedTimesItems.map(item => item.id));
	};

	return (
		<div>
			<TranslatedSelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedReopenedTimes?.length}
				onSelectAll={handleSelectAll}
				count={selectedReopenedTimes?.length}
				footer
				trigger={
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel(label, reopenedTimes, reopenedTimesItems)}
						active={!!(reopenedTimes && reopenedTimes.length !== 0)}
						onClear={handleFullClear}
					/>
				}
				search={
					<SelectSearch
						type="popup"
						value={search}
						placeholder={t('selectSearch.placeholder')}
						onChange={setSearch}
					/>
				}
			>
				<Select2
					multiple
					value={selectedReopenedTimes}
					onChange={setSelectedReopenedTimes}
				>
					{filteredData.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.name}
						/>
					))}
				</Select2>
			</TranslatedSelectPopup>
		</div>
	);
});
