import {getAllTreeKeys} from './getAllTreeKeys';
import {ITwoWayTreeItem} from '../../interfaces/ITwoWayTreeItem';

/**
 * Формирует массив из всех выбранных элементов, в случае если выбирались только родительские элементы
 *
 * @param data данные в виде дерева
 * @param checked отмеченные элементы
 */
export function formAllChecked<T extends {
	id: string;
	onlySemiCheckable?: boolean;
}>(data: Array<ITwoWayTreeItem<T>>, checked: string[]): string[] {
	if (!checked.length) {
		return [];
	}
	return data.reduce<string[]>((prev, treeItem) => {
		if (treeItem.children) {
			return formAllChecked(treeItem.children, prev);
		}
		if (checked.includes(treeItem.id) && treeItem.children) {
			return prev.concat(getAllTreeKeys(treeItem.children));
		}
		return prev;
	}, checked);
}
