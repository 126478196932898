import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialStateForPage} from './settings';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';

/**
 * Возвращает настройки страницы для конкретного объекта
 */
export const extractStructuresPageSettings = createSelector(
	(state: IState) => state.settings.pages.structures,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(data, objectId) => data[objectId] || getInitialStateForPage()
);

export const extractCurrentStructuresPageSettings = createSelector(
	(state: IState) => state.settings.pages.structures,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(data, objectId) => {
		const settings = data[objectId] || getInitialStateForPage();
		return settings[settings.displayMode];
	}
);

export const extractStructuresListPageSettings = createSelector(
	(state: IState) => state.settings.pages.structures,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(data, objectId) => {
		const settings = data[objectId] || getInitialStateForPage();
		return settings[StructuresDisplayMode.LIST];
	}
);

export const extractStructuresSchemaPageSettings = createSelector(
	(state: IState) => state.settings.pages.structures,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(data, objectId) => {
		const settings = data[objectId] || getInitialStateForPage();
		return settings[StructuresDisplayMode.SCHEMA];
	}
);
