import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {queryClient} from '@src/api/QueryClient';
import {deleteMutation} from '@src/cache/utils/deleteMutation';

export const deleteLocalProblem = async (problemId: string) => {
	queryClient.removeQueries({queryKey: [...problemsQueryKeys.details(), problemId]});
	queryClient.removeQueries({queryKey: problemsQueryKeys.savingData(problemId)});
	await queryClient.refetchQueries({
		queryKey: problemsQueryKeys.localList()
	});
	deleteMutation(problemId);
};
