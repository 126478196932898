import {IAddContractResponse, requestAddContract} from '@src/api/backend/contract';
import {addErrorToast} from '@src/utils/toasts';
import {updateCachedState} from '@src/utils/updateCachedState';
import {QueryClient} from '@tanstack/react-query';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {IContract} from '@tehzor/tools/interfaces/contracts';
import {contractsQueryKeys} from '../../keys';
import {invalidateQueries} from './invalidateQueries';
import {ISavingContract} from '@src/interfaces/saving/ISavingContract';

export interface IAddContractParams {
	fields: ISavingContract;
}

export const useAddContractsMutationDefaults = (queryClient: QueryClient) => {
	const key = Date.now().toString(10);

	queryClient.setMutationDefaults(contractsQueryKeys.add(), {
		onMutate: (params: IAddContractParams) => ({variables: params}),
		mutationFn: async (params: IAddContractParams) => {
			await updateCachedState<IContract>(
				contractsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);

			const {fields} = params;
			return requestAddContract(fields);
		},
		onSuccess: async (data: IAddContractResponse) => {
			await updateCachedState<IContract>(
				contractsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);

			queryClient.removeQueries({queryKey: contractsQueryKeys.savingData(key)});
			await invalidateQueries(queryClient);

			return data;
		},
		onError: async () => {
			addErrorToast('Ошибка', 'не удалось добавить договор');
			await updateCachedState<IContract>(
				contractsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
			return undefined;
		},
		retry: false
	});
};
