import {requestDocument} from '@src/api/backend/document';
import {requestGetListDocuments, requestGetPaginateDocuments} from '@src/api/backend/documents';
import {useQueryClient, QueryFunctionContext} from '@tanstack/react-query';
import {
	IGetDocumentQueryKey,
	IGetListDocumentsQueryKey,
	IGetPaginateDocumentsQueryKey,
	documentsQueryKeys
} from '../keys';
import {requestDocumentTypes} from '@src/api/backend/documentTypes/get';

export const useDocumentsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(documentsQueryKeys.paginates(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetPaginateDocumentsQueryKey>) => {
			const [, , filters, sort, offset, pageSize] = queryKey;
			return requestGetPaginateDocuments(filters, sort, offset, pageSize);
		}
	});

	queryClient.setQueryDefaults(documentsQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetListDocumentsQueryKey>) => {
			const [, , filters, sort] = queryKey;
			return requestGetListDocuments(filters, sort);
		}
	});

	queryClient.setQueryDefaults(documentsQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetDocumentQueryKey>) => {
			const [, , documentId] = queryKey;
			return requestDocument(documentId);
		}
	});

	queryClient.setQueryDefaults(documentsQueryKeys.types(), {
		queryFn: requestDocumentTypes
	});
};
