import {getStoredState} from 'redux-persist';
import {formPersistConfig} from '@src/store/persistentStorage/formPersistConfig';
import {REHYDRATE} from 'redux-persist/es/constants';
import {AppDispatch} from '@src/store/appStore';

/**
 * Берёт данные справочников из IndexedDb и помещает в store приложения
 *
 * @param dispatch dispatch-функция хранилища
 */
export async function rehydrateState(dispatch: AppDispatch) {
	const storageDictionaryState = await getStoredState(formPersistConfig('dictionaries'));
	dispatch({
		key: 'dictionaries',
		type: REHYDRATE,
		payload: storageDictionaryState
	});
}
