import {IDocumentsFiltersState, IDocumentsSortState} from '@src/store/modules/settings/pages/documents/reducers';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetPaginateDocumentsResponse extends INormalizedData<IDocument> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает пагинированный список документов
 */
export const requestGetPaginateDocuments = async (
	filters?: IDocumentsFiltersState,
	sort?: IDocumentsSortState,
	offset?: number,
	limit?: number
): Promise<IGetPaginateDocumentsResponse> => {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};
	const response = await httpRequests.withToken.post<IGetPaginateDocumentsResponse>(
		'documents/get-paginate', params
	);

	return response.data;
}