import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableStructureAction, IEditableStructureState, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export const useEditableStructureState = (structure?: IStructure) =>
	useReducer<Reducer<IEditableStructureState, IEditableStructureAction>, IStructure | undefined>(
		createReducer(init, errorsFns),
		structure,
		init
	);
