import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {extractProblem} from '@src/store/modules/pages/problem/selectors/problem';
import {
	formCheckLink,
	formInternalAcceptanceLink,
	formOwnerAcceptanceLink,
	formProblemLink,
	formSpaceLink,
	formWarrantyClaimLink
} from '@tehzor/tools/utils/links';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';

/**
 * Хук для хлебных крошек от "всех объектов" до нарушения
 *
 * @param objectId id конечного объекта
 */
export function useProblemBreadcrumbs(objectId: string) {
	const {t} = useTranslation();

	const objects = useObjectsData();
	const problem = useAppSelector(extractProblem);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const translatedSpaceTypes = useTranslatedDictionaryMap(dictionaryKeys.spaceTypes, spaceTypes);

	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(() => {
		const items: IBreadcrumb[] = objects
			? formObjectsChain(objects.byId, objectId, 'problems', t)
			: [];

		if (problem) {
			if (problem.space) {
				items.push({
					label: formSpaceTitle(
						problem.space.name,
						problem.space.altName,
						translatedSpaceTypes?.[problem.space.type],
						altNamesVisible,
						true
					),
					url: formSpaceLink(objectId, problem.space.id),
					inactive: false
				});
			}
			if (problem.check) {
				items.push({
					label: t('pageBreadcrumbs.check', {id: problem.check.number}),
					url: formCheckLink(objectId, problem.check.id),
					inactive: false
				});
			}
			if (problem.warrantyClaim && problem.space) {
				items.push({
					label: t('pageBreadcrumbs.warrantyClaim', {id: problem.warrantyClaim.number}),
					url: formWarrantyClaimLink(objectId, problem.warrantyClaim.id),
					inactive: false
				});
			}
			if (problem.ownerAcceptance && problem.space) {
				items.push({
					label: t('pageBreadcrumbs.ownerAcceptance', {
						id: problem.ownerAcceptance.number
					}),
					url: formOwnerAcceptanceLink(objectId, problem.ownerAcceptance.id),
					inactive: false
				});
			}
			if (problem.internalAcceptance) {
				items.push({
					label: t('pageBreadcrumbs.internalAcceptance', {
						id: problem.internalAcceptance.number
					}),
					url: formInternalAcceptanceLink(objectId, problem.internalAcceptance.id),
					inactive: false
				});
			}
			items.push({
				label: t('pageBreadcrumbs.problem', {id: problem.number || problem.localNumber}),
				url: formProblemLink(objectId, problem.id),
				inactive: false
			});
		}
		return items;
	}, [objectId, problem, objects, spaceTypes]);
}
