import {useMemo} from 'react';
import {useParams} from 'react-router';
import {useExtractExportTemplateById} from '../queries/exportTemplates/hooks';

export const useManageExportTemplateBreadcrumbs = () => {
	const {exportTemplateId} = useParams<{exportTemplateId?: string}>();
	const {data: exportTemplate} = useExtractExportTemplateById(
		exportTemplateId,
		!!exportTemplateId
	);

	return useMemo(() => {
		const items = [
			{
				label: 'Шаблоны экспорта',
				url: '/manage/export-templates',
				nonClickable: !exportTemplate
			}
		];

		if (exportTemplateId && exportTemplate) {
			items.push({
				label: `Шаблон "${exportTemplate.name}"`,
				url: `/manage/export-templates/${exportTemplate.id}`,
				nonClickable: false
			});
		}

		return items;
	}, [exportTemplate, exportTemplateId]);
};
