import {IGetSpacesTypesCountResponse} from '@src/api/backend/spaces/spacesTypesCount';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {queryClient} from '@src/api/QueryClient';
import {store} from '@src/store/appStore';

export const getSpacesTypesCount = (objectIds?: string[]) => {
	const state = store.getState();
	const selectedObjects = state.settings.offlineMode.cache.objects;
	if (selectedObjects) {
		const spacesTypesCount = queryClient.getQueryData<IGetSpacesTypesCountResponse>(
			spacesQueryKeys.typesCount(selectedObjects)
		);
		if (spacesTypesCount) {
			const asArray = Object.entries(spacesTypesCount.byId);

			// Фильтруем по объектам
			const filteredByObjects = objectIds
				? asArray.filter(([, value]) => objectIds.includes(value.id))
				: asArray;
			if (filteredByObjects.length) {
				const byId = Object.fromEntries(filteredByObjects);
				const allIds = spacesTypesCount.allIds.filter(id => Object.keys(byId).includes(id));
				return {byId, allIds} as IGetSpacesTypesCountResponse;
			}
			return undefined;
		}
	}
	return undefined;
};
