import {Toggle} from '@tehzor/ui-components';
import {memo, useCallback, useMemo} from 'react';
import './ColumnsCustomizer.less';
import IconButton from '@tehzor/ui-components/src/components/buttons/IconButton';
import classNames from 'classnames';
import MenuLayer from '@tehzor/ui-components/src/components/menu/MenuLayer';
import {useTranslation} from 'react-i18next';
import {IColumnSettings, ITableSettings} from '@src/interfaces/ITableSettings';

export interface ToggleSettings extends IColumnSettings {
	id: string;
}

export const getToggleData = (tableSettings: ITableSettings) =>
	Object.entries(tableSettings).reduce((toggleSettings: ToggleSettings[], item) => {
		const [id, setting] = item;
		if (setting.showToggle) {
			toggleSettings.push({
				id,
				...setting
			});
		}
		return toggleSettings;
	}, []);

interface IColumnsCustomizerProps {
	tableSettings: ITableSettings;
	onChangeColumnsVisible: (columnId: string) => void;
	onShowAllColumns: () => void;
}

export const ColumnsCustomizer = memo(
	({tableSettings, onChangeColumnsVisible, onShowAllColumns}: IColumnsCustomizerProps) => {
		const {t} = useTranslation();

		const allIsVisible = useMemo(() => {
			for (const key in tableSettings) {
				if (!tableSettings[key].isVisible) {
					return false;
				}
			}
			return true;
		}, [tableSettings]);

		const changeColumnActiveHandler = (columnId: string) => {
			onChangeColumnsVisible(columnId);
		};

		const changeAllColumnsActiveHandler = useCallback(() => {
			if (allIsVisible) return;
			onShowAllColumns();
		}, [onShowAllColumns, allIsVisible]);

		return (
			<MenuLayer
				trigger={
					<IconButton
						className={classNames('icon-menu__button')}
						type="common"
					>
						<i className="tz-settings-24" />
					</IconButton>
				}
				popupProps={{placement: 'bottom-end', preferX: 'left'}}
				popupArrowVisible
				className="columns-customizer__menu-layer"
			>
				<div className="columns-customizer">
					<div className="columns-customizer__header">
						<h2 className="columns-customizer__title">
							{t('components.columnsCustomizer.title')}
						</h2>
					</div>
					<div className="columns-customizer__bar">
						<Toggle
							checked={allIsVisible}
							onChange={changeAllColumnsActiveHandler}
							className={{
								root: 'columns-customizer__toggle'
							}}
						>
							{t('components.columnsCustomizer.toggleAll.text')}
						</Toggle>
					</div>
					<div className="columns-customizer__list">
						{getToggleData(tableSettings).map(setting => (
							<Toggle
								checked={setting.isVisible}
								onChange={() => changeColumnActiveHandler(setting.id)}
								className={{
									root: 'columns-customizer__toggle'
								}}
								key={setting.id}
							>
								{setting.header}
							</Toggle>
						))}
					</div>
				</div>
			</MenuLayer>
		);
	}
);
