import {IGetProblemTagsResponse} from '@src/api/backend/problemTags';

export const extractProblemTagsById = (data: IGetProblemTagsResponse) => data.byId;

export const extractProblemTagsAsArray = (data: IGetProblemTagsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractProblemTagsForSetAsArray = (
	data: IGetProblemTagsResponse,
	problemTagsSetId?: string
) => extractProblemTagsAsArray(data).filter(item => item.problemTagsSetId === problemTagsSetId);

export const extractProblemTagsForSetsAsArray = (
	data: IGetProblemTagsResponse,
	problemTagsSetIds: string[]
) =>
	extractProblemTagsAsArray(data).filter(item =>
		problemTagsSetIds.some(problemTagsSetId => item.problemTagsSetId === problemTagsSetId)
	);
