import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import MobileRightButtons from '@src/pages/SpacePage/components/actions/RightButtons.mobile';
import {useSpace} from '@src/core/hooks/queries/space';
import {useSpaceSectionsMenu} from '@src/core/hooks/useSpaceSectionsMenu';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from "react-i18next";

export const useSpaceCheckListsHeader = (
	spaceId: string,
	objectId: string,
	stage: ObjectStageIds | 'all',
	listId?: string
) => {
	const {t} = useTranslation();
	const translatedTitle = t('checkListPage.hooks.useSpaceCheckListsHeader.title');
	const {data: space} = useSpace(spaceId, objectId);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const sections = useSpaceSectionsMenu(objectId, spaceId, stage);
	const isDesktop = useIsDesktop();

	const spaceTitle = useSpaceTitle(space);
	const title = isDesktop
		? space
			? `${translatedTitle} ${spaceTypes?.[space.type]?.name ?? ''} №${space.name}`
			: translatedTitle
		: spaceTitle;

	useAppHeader(
		{
			title,
			subTitle: isDesktop ? undefined : translatedTitle,
			showBackBtn: true,
			sectionsMenu: !listId ? sections : undefined,
			mobileRightButtons:
				!isDesktop && space ? (
					<MobileRightButtons
						objectId={objectId}
						spaceId={space.id}
						space={space}
					/>
				) : null
		},
		[isDesktop, listId, objectId, sections, space, title]
	);
};
