import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export type IDeleteSpaceOwnerResponse = ISpaceOwner;

/**
 * Удаляет собственника помещения
 *
 * @param ownerId id собственника
 */
export const makeSpaceOwnerDeleteRequest = async (ownerId: string) => {
	const res = await httpRequests.withToken.delete<IDeleteSpaceOwnerResponse>(
		`space-owners/${ownerId}`
	);
	return res.data;
};
