import {requestMeters} from '@src/api/backend/meters';
import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IMetersListQueryKey, metersQueryKeys} from '../keys';

export const useMetersQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(metersQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IMetersListQueryKey>) => {
			const [, , spaceId] = queryKey;
			const result = await requestMeters(spaceId);
			return result;
		},
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
