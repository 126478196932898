import {useState, useEffect} from 'react';

export const useNoOptionsState = (initialOptions: string[] | null | undefined) => {
	const [selectedOptions, setSelectedOptions] = useState<string[] | undefined | null>(
		initialOptions
	);
	const [noOptions, setNoOptions] = useState(false);

	useEffect(() => {
		if (selectedOptions === undefined || Array.isArray(selectedOptions)) {
			setNoOptions(false);
		} else if (selectedOptions === null) {
			setNoOptions(true);
		}
	}, [selectedOptions]);

	const toggleNoOptions = (onToggle?: (newNoOptions: boolean) => void) => {
		setNoOptions(prevNoOptions => {
			const newNoOptions = !prevNoOptions;
			setSelectedOptions(newNoOptions ? null : undefined);
			if (typeof onToggle === 'function') {
				onToggle(newNoOptions);
			}
			return newNoOptions;
		});
	};
	return {
		selectedOptions,
		setSelectedOptions,
		noOptions,
		setNoOptions,
		toggleNoOptions
	};
};
