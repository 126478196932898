import {memo} from 'react';
import {CellProps} from 'react-table';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {useIsTablet} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import styles from './FieldCompanyCell.module.less';

export const FieldCompanyCell = memo(({row}: CellProps<IPreparedFieldSettingsSet>) => {
	const isTablet = useIsTablet();

	return (
		<div className={styles.companyCell}>
			{!isTablet && 'Компания: '}
			<span className={styles.title}>{row.original.company?.name}</span>
		</div>
	);
});
