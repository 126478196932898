import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import {ProblemInfo} from '@src/components/tableCells/ProblemInfo/ProblemInfo';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import {useResponsibleDisplayValue} from '@src/core/hooks/useResponsibleDisplayValue';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {FilesCount, PhotoPreview, TableRowSelect} from '@tehzor/ui-components';
import classNames from 'classnames';
import * as React from 'react';
import {useMemo} from 'react';
import {TableToggleRowsSelectedProps} from 'react-table';
import ClickPreventWrap from '../ClickPreventWrap';
import ProblemReply from '../ProblemReply';
import {ProblemStatus} from '../ProblemStatusCell';
import {FixDateTag} from '@src/components/FixDateTag';
import {format} from 'date-fns';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {ru} from 'date-fns/locale';
import {CriticalMarker} from '@src/components/CriticalMarker';
import './ChecklistProblemInfoMobileCell.less';
import {useTranslation} from "react-i18next";
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {useEntityAndReplyViewerMobile} from '@src/core/hooks/imagesViewers/mobile/useEntityAndReplyViewerMobile';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useCommentsCtx} from '@src/components/Comments/utils/CommentsCtx';

interface IProblemInfoMobileProps {
	problem: IListProblem;
	depth: number;
	subTitle?: React.ReactNode;
	replies?: Record<string, IComment>;
	allProblemTags?: Record<string, IProblemTag>;
	getToggleRowSelectedProps: (
		props?: Partial<TableToggleRowsSelectedProps>
	) => TableToggleRowsSelectedProps;
	positionFilesCount?: 'subtitle' | 'after';
}

export const ChecklistProblemInfoMobileCell = ({
	problem,
	depth,
	subTitle,
	replies,
	allProblemTags,
	getToggleRowSelectedProps,
	positionFilesCount = 'subtitle'
}: IProblemInfoMobileProps) => {
	const {t} = useTranslation();
	const {
		plannedFixDate,
		performers,
		performersActiveGroup,
		inspectors,
		inspectorsActiveGroup,
		description,
		problemTags,
		actualFixDate,
		critical
	} = problem;

	const reply = replies?.[problem.id];
	const {lastReply} = useCommentsCtx();
	const [problemImagesAttachments] = useSplitAttachments(problem);
	const problemImages = useAttachmentsImages(problemImagesAttachments);
	const problemImagesData = useAttachmentsImagesObjects(problemImagesAttachments);
	const [replyImagesAttachments] = useSplitAttachments(reply);
	const replyImages = useAttachmentsImages(replyImagesAttachments);
	const replyImagesData = useAttachmentsImagesObjects(replyImagesAttachments);


	const [imagesViewer, openProblemImage, openReplyImage] = useEntityAndReplyViewerMobile(
		problemImages,
		replyImages,
		AttachmentFileDestination.PROBLEM,
		AttachmentFileDestination.COMMENT_OR_REPLY,
		problemImagesData,
		replyImagesData,
		'problem',
		'Фото нарушения',
		lastReply?.number,
		problem.id,
		lastReply?.id
	)

	const colors = allProblemTags ? problemTags?.map(id => allProblemTags[id]?.color) : undefined;
	const performersDisplayValue = useResponsibleDisplayValue(performers, performersActiveGroup);
	const inspectorsDisplayValue = useResponsibleDisplayValue(inspectors, inspectorsActiveGroup);

	const problemInfoSubTitle = useMemo(
		() => (
			<div>
				{subTitle}
				{positionFilesCount === 'subtitle' && (
					<FilesCount count={problem?.attachments?.length} />
				)}
			</div>
		),
		[subTitle, problem?.attachments?.length, positionFilesCount]
	);

	const markers = (
		<>
			{critical && <CriticalMarker />}
			<div className="m-checklist-problem-info-cell__markers">
				{colors && <ProblemTagsMarker colors={colors} />}
			</div>
		</>
	);
	return (
		<div className="m-checklist-problem-info-cell-wrapper">
			<div className="m-checklist-problem-info-cell-inner">
				<ClickPreventWrap className="m-checklist-problem-info-cell-inner__row-select">
					<TableRowSelect {...getToggleRowSelectedProps()} />
				</ClickPreventWrap>
				<div className="m-checklist-problem-info-cell">
					<div
						className={classNames('m-checklist-problem-info-cell__problem', {
							'm-checklist-problem-info-cell__problem_with-padding': depth > 0
						})}
					>
						<div className="m-checklist-problem-info-cell__problem-header">
							<ProblemInfo
								className={{
									title: 'm-checklist-problem-info-cell__problem-header-title'
								}}
								data={problem}
								depth={depth}
								subTitle={problemInfoSubTitle}
								title="№"
								withoutStatusIcon
								markers={markers}
							/>
						</div>

						{description !== undefined && (
							<div className="m-checklist-problem-info-cell__problem-desc">
								{description}
							</div>
						)}
						{plannedFixDate !== undefined && (
							<div className="m-checklist-problem-info-cell__extra-info m-checklist-problem-info-cell--fix-date">
								<span className="m-checklist-problem-info-cell__extra-info-fix-date">
									{t(
										'components.tableCells.checklistProblemInfoMobileCell.plannedFixDate.text'
									)}
									: {format(plannedFixDate, dateTimeFormat, {locale: ru})}
								</span>
								<FixDateTag
									plannedFixDate={plannedFixDate}
									actualFixDate={actualFixDate}
								/>
							</div>
						)}
						{performersDisplayValue ? (
							<div className="m-checklist-problem-info-cell__extra-info">
								<div className="m-checklist-problem-info-cell__extra-info-value">
									{t(
										'components.tableCells.checklistProblemInfoMobileCell.responsible.text'
									)}
									: {performersDisplayValue}
								</div>
							</div>
						) : null}
						{inspectorsDisplayValue ? (
							<div className="m-checklist-problem-info-cell__extra-info">
								<div className="m-checklist-problem-info-cell__extra-info-value">
									{t(
										'components.tableCells.checklistProblemInfoMobileCell.responsible.text'
									)}
									: {inspectorsDisplayValue}
								</div>
							</div>
						) : null}
						<div className="m-checklist-problem-info-cell__problem-status-row">
							<ProblemStatus problem={problem} />
						</div>
					</div>
					{reply !== undefined && (
						<ProblemReply
							className="m-checklist-problem-info-cell__reply"
							reply={reply}
							images={replyImages}
							openImage={openReplyImage}
						/>
					)}
					{problemImages.length || replyImages.length ? (
						<ClickPreventWrap>{imagesViewer}</ClickPreventWrap>
					) : null}
					{positionFilesCount === 'after' && (
						<FilesCount count={problem?.attachments?.length} />
					)}
				</div>
			</div>
			{problemImages.length > 0 ? (
				<ClickPreventWrap>
					<PhotoPreview
						className="m-checklist-problem-info-cell__problem-image"
						url={problemImages[0]}
						data={0}
						onClick={openProblemImage}
					/>
				</ClickPreventWrap>
			) : null}
		</div>
	);
};
