import {isEqual} from 'lodash';
import IObjectFieldSetting from '../../interfaces/objects/IObjectFieldSetting';

/**
 * Тип состояния с ошибками и исключениями
 */
export type IEditableEntityState<S> = S & {
	customFields?: Record<string, unknown>;
} & {
	errors: {
		[k in keyof S]?: boolean;
	} & {
		customFields?: Record<string, boolean | undefined>;
	};
	exceptions?: {
		[k in keyof S]?: boolean;
	} & {
		customFields?: Record<string, boolean | undefined>;
	};
};

/**
 * Тип действия для reducer'а редактирования сущности
 */
export type IEditableEntityAction<S, E> =
	| {
			type: 'update';
			field: keyof S;
			value: unknown;
	  }
	| {
			type: 'add-in-array';
			field: keyof S;
			value: unknown[];
	  }
	| {
			type: 'update-array-item';
			field: keyof S;
			value: unknown;
			index: number;
	  }
	| {
			type: 'delete-array-item';
			field: keyof S;
			index: number[];
	  }
	| {
			type: 'reset';
			entity?: E;
	  }
	| {
			type: 'update-error';
			field: keyof S;
			key?: string;
	  }
	| {
			type: 'create-custom-error';
			field: string;
		}
	| {
			type: 'update-custom-error';
			field: string;
	  }
	| {
			type: 'update-errors';
	  }
	| {
			type: 'update-exception';
			field: keyof S;
			value: boolean;
	  }
	| {
			type: 'update-custom-exception';
			field: string;
			value: boolean;
	  };

export type IEditableEntityErrorFns<S> = {
	customFields?: (s: IEditableEntityState<S>, key?: string) => boolean;
} & {[K in keyof S]?: (s: IEditableEntityState<S>) => boolean};

const hasBuiltinError = <S>(
	state: IEditableEntityState<S>,
	errorFns: IEditableEntityErrorFns<S>,
	field: string
): boolean => {
	if (!state.exceptions?.[field as keyof S]) {
		return !!errorFns[field as keyof S]?.(state);
	}
	return false;
};

const hasCustomError = <S>(state: IEditableEntityState<S>, errorFns: IEditableEntityErrorFns<S>, field: string) => {
	if (!state.exceptions?.customFields?.[field]) {
		return !!errorFns?.customFields?.(state, field);
	}
	return false;
};

/**
 * Функция проверки ошибок всех полей в state
 */
function updateErrors<S>(
	state: IEditableEntityState<S>,
	errorFns: IEditableEntityErrorFns<S>
): IEditableEntityState<S> {
	let errors = state.errors;

	for (const key in errorFns) {
		if (!errorFns.hasOwnProperty(key)) {
			continue;
		}
		if (key !== 'customFields') {
			errors = {
				...errors,
				[key as keyof S]: hasBuiltinError(state, errorFns, key)
			};
			continue;
		}

		for (const nestedKey in errors.customFields) {
			if (!errors.customFields?.hasOwnProperty(nestedKey)) {
				continue;
			}
			errors.customFields = {
				...errors.customFields,
				[nestedKey]: hasCustomError(state, errorFns, nestedKey)
			};
		}
	}

	return {...state, errors};
}

/**
 * Создаёт reducer для редактирования сущности
 */
export const createReducer =
	<S, E>(init: (entity?: E) => IEditableEntityState<S>, errorFns: IEditableEntityErrorFns<S>) =>
	(state: IEditableEntityState<S>, action: IEditableEntityAction<S, E>): IEditableEntityState<S> => {
		switch (action.type) {
			case 'update':
				return {
					...state,
					[action.field]: action.value
				};
			case 'add-in-array':
				return {
					...state,
					[action.field]: (state[action.field] as unknown as unknown[]).concat(action.value)
				};
			case 'update-array-item':
				return {
					...state,
					[action.field]: (state[action.field] as unknown as unknown[]).map((item, i) =>
						i === action.index ? action.value : item
					)
				};
			case 'delete-array-item':
				return {
					...state,
					[action.field]: (state[action.field] as unknown as unknown[]).filter(
						(item, i) => !action.index.includes(i)
					)
				};
			case 'reset':
				return init(action.entity);
			case 'create-custom-error':
				return {
					...state,
					errors: {
						...state.errors,
						customFields: {
							...state.errors.customFields,
							[action.field]: false
						}
					}
				};
			case 'update-error':
				return {
					...state,
					errors: {
						...state.errors,
						[action.field]: hasBuiltinError(state, errorFns, String(action.field))
					}
				};
			case 'update-custom-error':
				return {
					...state,
					errors: {
						...state.errors,
						customFields: {
							...state.errors.customFields,
							[action.field]: hasCustomError(state, errorFns, String(action.field))
						}
					}
				};
			case 'update-errors':
				return updateErrors(state, errorFns);
			case 'update-exception':
				return state.exceptions
					? {...state, exceptions: {...state.exceptions, [action.field]: action.value}}
					: state;
			case 'update-custom-exception':
				return state.exceptions
					? {
							...state,
							exceptions: {
								...state.exceptions,
								customFields: {
									...state.exceptions.customFields,
									[action.field]: action.value
								}
							}
					  }
					: state;
			default:
				return state;
		}
	};

/**
 * Возвращает значение, показывающее были ли отредактированы поля сущности
 *
 * @param state состояние
 * @param original изначальные данные сущности
 * @param fns функции для проверки изменения в отдельных полях
 */
export const isEntityEdited = <S, E>(
	state: IEditableEntityState<S>,
	original?: E,
	...fns: Array<(s: IEditableEntityState<S>, o?: E) => boolean>
): boolean => fns.some(fn => fn(state, original));

/**
 * Проверяет, есть ли ошибки в полях сущности
 *
 * @param state состояние
 * @param errorFns функции проверок полей
 * @param settings настройки полей
 */
export const hasErrors = <S>(
	state: IEditableEntityState<S>,
	errorFns: IEditableEntityErrorFns<S>,
	// !TODO Доработать после полного выпиливания полей из объектов
	settings: Record<string, IObjectFieldSetting>,
	isCustomFields?: boolean
) => {
	for (const [key, setting] of Object.entries(settings)) {
		if (
			settings.hasOwnProperty(key) &&
			// !TODO Доработать после полного выпиливания полей из объектов
			(setting?.isRequired || setting?.required) &&
			(isCustomFields ? hasCustomError(state, errorFns, key) : hasBuiltinError(state, errorFns, key))
		) {
			return true;
		}
	}
	return false;
};

/**
 * Проверяет был-ли изменен ключ в state, сравнивая его с оригиналом
 *
 * @param prop ключ
 * @param state текущий state
 * @param original оригинальное значение
 */
export const isPropEdited = <
	State extends IEditableEntityState<unknown>,
	Key extends keyof State,
	Entity extends Partial<Record<Key, State[Key]>>
>(
	prop: Key,
	state: State,
	original?: Entity
) => {
	const currentVal: unknown = state[prop];

	if (!original) {
		if (currentVal === false) {
			return true;
		}
		return !!currentVal;
	}

	let result = true;
	const originalVal: unknown = original[prop];

	if (Array.isArray(currentVal) && Array.isArray(originalVal)) {
		result =
			result &&
			(currentVal.length !== originalVal.length || JSON.stringify(currentVal) !== JSON.stringify(originalVal));
	} else if (typeof currentVal === 'object') {
		result = result && !isEqual(currentVal, originalVal);
	} else {
		result = result && currentVal != originalVal;
	}

	return result;
};
