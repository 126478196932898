import EntitiesFilters, {
	CreatedByFilter,
	CreationDateFilter,
	ObjectsFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ICategoriesSetsFiltersState} from '@src/store/modules/settings/pages/manage/categoriesSets/reducers';
import {StagesFilter} from '@src/pages/ProblemsPage/components/filters/StagesFilters';
import {useResolvedPath} from 'react-router-dom';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';
import {useTranslation} from 'react-i18next';

const CategoriesSetsFilters = ({objectId = 'all'}) => {
	const {t} = useTranslation();

	const {state} = useEntitiesFiltersCtx<ICategoriesSetsFiltersState>();
	const {data: allStages} = useObjectStagesAsArray();
	const manage = useResolvedPath('').pathname.split('/').includes('manage');

	return (
		<div className="categories-sets__filters">
			<EntitiesFilters
				objectId={objectId}
				entity={manage ? 'manage/categories-sets' : 'categories-sets'}
			>
				{objectId === 'all' && (
					<ObjectsFilter
						value={state.objects}
						label={t('entitiesFilters.objectsFilter.label')}
					/>
				)}

				<CreatedByFilter
					objectId={objectId}
					createdBy={state.createdBy}
					label={t('entitiesFilters.CreatedByFilter.label')}
				/>

				{allStages && (
					<StagesFilter
						allStages={allStages}
						stages={state.stages}
						label={t('entitiesFilters.stagesFilter.label')}
					/>
				)}

				<CreationDateFilter
					from={state.createdAtFrom}
					to={state.createdAtTo}
					label={t('entitiesFilters.CreationDateFilter.label')}
				/>
			</EntitiesFilters>
		</div>
	);
};

export default CategoriesSetsFilters;
