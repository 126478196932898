import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {useReducer, Reducer} from 'react';
import {
	IEditableSpaceStatusesSetOriginal,
	IEditableSpaceStatusesSetState,
	IEditableSpaceStatusesSetAction,
	init,
	errorFns
} from './state';

export const useEditableSpaceStatusesSetState = (
	defaultData?: IEditableSpaceStatusesSetOriginal
) => useReducer<
	Reducer<IEditableSpaceStatusesSetState, IEditableSpaceStatusesSetAction>,
	IEditableSpaceStatusesSetOriginal | undefined
>(createReducer(init, errorFns), defaultData, init);