import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {enrichSpaces} from '@src/store/modules/entities/spaces/selectors/schemas';
import {extractSpacesListAsArray} from '@src/store/modules/entities/spaces/selectors/lists';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useSpaceIndicatorsAsMap} from '@src/core/hooks/queries/spaceIndicatorsSets/hooks';
import {useSpaceStatuses} from '@src/core/hooks/queries/spaceStatuses/hooks';

export function useEnrichedList(objectId: string) {
	const spaces = useAppSelector(s => extractSpacesListAsArray(s, objectId));
	const {data: spaceStatuses} = useSpaceStatuses();
	const {data: object} = useObject(objectId);
	const {data: spaceIndicators} = useSpaceIndicatorsAsMap(object);

	return useMemo(
		() =>
			spaceIndicators && spaceStatuses
				? enrichSpaces(spaces, spaceStatuses.byId, spaceIndicators.byId)
				: [],
		[spaces, spaceStatuses?.byId, spaceIndicators?.byId]
	);
}
