import {queryClient} from '@src/api/QueryClient';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';

export const findEntityLinks = <R extends {links: IProblem['links'] | IInspection['links']}>(
	queryKey: (key: string) => string[],
	entityId: string
): IProblem['links'] | IInspection['links'] => {
	const savingData = queryClient.getQueryData<R>(queryKey(entityId));
	return savingData?.links;
};
