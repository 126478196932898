import { memo, useMemo } from 'react';
import {ErrorBoundary, ErrorMessage, LinkButton} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useObjectsAsArray} from '@src/core/hooks/queries/objects/hooks';
import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';

interface IObjectsFilterMobileProps {
	entity: string;
	value?: string[];
	pageObjectId?: string;
	targetObjects?: string[];
	filterName?: string;
}

export const ObjectsFilterMobile = memo(
	({
		entity,
		value,
		pageObjectId,
		targetObjects,
		filterName = 'objects'
	}: IObjectsFilterMobileProps) => {
		const {t} = useTranslation();

		const objects = useObjectsAsArray();
		const {data: companies} = useCompaniesAsArray();

		const treeData = useMemo(() => {
			if (!objects) return [];
			if (targetObjects) {
				return makeObjectsTreeData(objects.filter(item => targetObjects.includes(item.id)));
			}
			return makeObjectsTreeData(objects, companies);
		}, [targetObjects, objects, companies]);

		const handleExpand = useFilterPageTransition(pageObjectId, entity, filterName);

		const label = makeFilterLabel(t('problemsPage.objectsFilter.label'), value, treeData, true);

		return (
			<ErrorBoundary
				label="ObjectsFilterMobile"
				component={
					<div className="mobile-entities-filters__objects-filter">
						<ErrorMessage />
					</div>
				}
			>
				<div className="mobile-entities-filters__objects-filter">
					<div className="mobile-entities-filters__objects-filter-header">
						<div className="mobile-entities-filters__objects-filter-title">
							{t('problemsPage.objectsFilter.label')}
						</div>
						<LinkButton
							label={t('problemsFiltersPage.allBtn.label')}
							onClick={handleExpand}
						/>
					</div>
					<div className="mobile-entities-filters__objects-filter-label">{label}</div>
				</div>
			</ErrorBoundary>
		);
	}
);
