import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IWorkAcceptanceState, getInitialState} from '../reducers';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';

const extractWorkAcceptanceData = (state: IState): IWorkAcceptanceState =>
	state.entities.workAcceptance || getInitialState();

/**
 * Извлекает нарушения приёмки работ
 */
export const extractWorkAcceptanceProblems = createSelector([extractWorkAcceptanceData], data =>
	data.problems.allIds.map(id => data.problems.byId[id])
);

export const extractWorkAcceptanceProblemsData = createSelector(
	[extractWorkAcceptanceData],
	data => data.problems
);

/**
 * Извлекает осмотры приёмки работ
 */
export const extractWorkAcceptanceInspectionsData = createSelector(
	[extractWorkAcceptanceData],
	data => data.inspections
);

/**
 * Извлекает ответы на нарушения приёмки работ
 */
export const extractWorkAcceptanceProblemReplies = createSelector(
	[extractWorkAcceptanceData],
	data => data.problemReplies.allIds.map(id => data.problemReplies.byId[id])
);

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractWorkAcceptanceProblemCommentsByProblem = createSelector(
	[extractWorkAcceptanceProblemReplies],
	replies =>
		replies.reduce<Record<string, IComment>>((prev, item) => {
			if (item.links?.problemId && !prev[item.links.problemId]) {
				prev[item.links.problemId] = item;
			}
			return prev;
		}, {})
);

export const extractProblemsAndSubProblemsByWorkAcceptanceIdAsArray = createSelector(
	[
		extractWorkAcceptanceProblemsData,
		(state: IState, workAcceptanceId: string) => workAcceptanceId
	],
	(data, workAcceptanceId) =>
		data.allIds.reduce((acc: IProblem[], id) => {
			const problem = data.byId[id];

			if (problem?.links?.workAcceptanceId === workAcceptanceId) {
				acc.push(problem);
			}

			return acc;
		}, [])
);

export const extractInspectionsByWorkAcceptanceIdAsArray = createSelector(
	[
		extractWorkAcceptanceInspectionsData,
		(state: IState, workAcceptanceId: string) => workAcceptanceId
	],
	(data, workAcceptanceId) =>
		data.allIds.reduce((acc: IInspection[], id) => {
			const inspection = data.byId[id];

			if (inspection?.links?.workAcceptanceId === workAcceptanceId) {
				acc.push(inspection);
			}

			return acc;
		}, [])
);

/**
 * Извлекает сущности приёмки работ
 */
export const extractWorkAcceptanceEntities = createSelector(
	[extractWorkAcceptanceProblemsData, extractWorkAcceptanceInspectionsData],
	(problemsData, inspectionsData) => {
		const problems = problemsData.allIds
			.map(id => problemsData.byId[id])
			.map<IWorkAcceptanceEntity>(item => ({
				id: `p_${item.id}`,
				type: 'problem',
				data: item
			}));

		const inspections = inspectionsData.allIds
			.map(id => inspectionsData.byId[id])
			.map<IWorkAcceptanceEntity>(item => ({
				id: `i_${item.id}`,
				type: 'inspection',
				data: item
			}));
		return [...problems, ...inspections].sort(
			(a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0)
		);
	}
);

/**
 * Возвращает записи истории делегирования в виде массива
 */
export const extractWorkAcceptanceDelegationHistoriesAsArray = createSelector(
	[extractWorkAcceptanceData],
	workAcceptance =>
		workAcceptance.delegationHistories.allIds.map(
			id => workAcceptance.delegationHistories.byId[id]
		)
);
