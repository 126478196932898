import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';

const typicalColors = {
	ACCEPTED: '#90D857',
	IN_PROGRESS: '#1C85FD',
	NOT_INSPECTED: '#C4D1E3'
};

/**
 * Возвращает цвет рамки для диаграммы
 *
 * @param checkLists массив чеклистов
 */
export function getBorderColor(status: CheckRecordStatusId | undefined) {
	if (status) {
		if (status === CheckRecordStatusId.ACCEPTED) {
			// зеленый
			return typicalColors.ACCEPTED;
		}
		if (status === CheckRecordStatusId.NOT_CHECKED) {
			// серый
			return typicalColors.NOT_INSPECTED;
		}
		// синий
		return typicalColors.IN_PROGRESS;
	}

	// серый
	return typicalColors.NOT_INSPECTED;
}