import {Dispatch, useCallback} from 'react';
import * as React from 'react';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {useTaskTypesAsArray, useTaskTypesMap} from '@src/core/hooks/queries/taskTypes';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';

interface ISpaceStatusProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const TaskTypeSelect = <S extends {taskType?: string}, E>({
	className,
	style,
	label,
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: ISpaceStatusProps<S, E>) => {
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.taskTypeSelect.label');
	const {data: types} = useTaskTypesAsArray();
	const {data: typesMap} = useTaskTypesMap();

	const translatedTasksTypesMap = useTranslatedDictionaryMap(dictionaryKeys.taskTypes, typesMap);
	const translatedTaskTypesArray = useTranslatedDictionaryArray(dictionaryKeys.taskTypes, types);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'taskType', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'taskType'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={value ? translatedTasksTypesMap?.[value]?.name : ''}
						icon={<i className="tz-task-type-20" />}
						error={
							required && hasError
								? t('components.editableFields.taskTypeSelect.error')
								: undefined
						}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{translatedTaskTypesArray?.map(type => (
						<SelectOption
							key={type.id}
							itemKey={type.id}
							content={type.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
