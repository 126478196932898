import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddSpaceOwnerResponse = ISpaceOwner;

/**
 * Добавляет собственника помещения
 *
 * @param companyId id компании
 * @param data данные собственника
 */
export const makeSpaceOwnerAddRequest = async (companyId: string, data: ISavingSpaceOwner) => {
	const response = await httpRequests.withToken.post<IAddSpaceOwnerResponse>('space-owners', {
		companyId,
		...data
	});
	return response.data;
};
