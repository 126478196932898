import {useFilteredLegalsArray, useLegalsMap} from '@src/core/hooks/queries/legals';
import {IEditableContractState} from '@src/core/hooks/states/useEditableContractState';
import {memo, useCallback} from 'react';
import {SingleSelectContractField} from '../fields/SingleSelectContractField';
import {
	useAuthorizedPersonsMap,
	useFilteredAuthorizedPersonsArray
} from '@src/core/hooks/queries/authorizedPersons';
import {useBankAccountsMap, useFilteredBankAccounts} from '@src/core/hooks/queries/bankAccounts';
import {useCompaniesById, useMainCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';

interface ICompanyBlockProps {
	editingState: IEditableContractState;

	isMobile?: boolean;

	onChange: (
		value: string | number | string[] | null | undefined,
		field: keyof IEditableContractState
	) => void;
	onError: (field: keyof IEditableContractState) => void;
}

export const CompanyBlock = memo(
	({editingState, isMobile, onChange, onError}: ICompanyBlockProps) => {
		const {companyId, companyLegalEntityId: legalEntityId} = editingState;

		const {data: companies} = useMainCompaniesAsArray();
		const {data: companiesMap} = useCompaniesById();

		const company = companyId && companiesMap ? companiesMap[companyId] : undefined;

		const handleChangeCompany = useCallback(
			(value: string | null | undefined) => {
				onChange(value, 'companyId');

				// Сброс связанных сущностей
				onChange(null, 'companyAuthorizedPersonId');
				onChange(null, 'companyBankAccountId');
				onChange(null, 'companyLegalEntityId');
				onChange(null, 'objectIds');
				onChange(null, 'categoryIds');
			},
			[onChange]
		);
		const legalEntities = useFilteredLegalsArray(company?.uuid ? [company?.uuid] : undefined);
		const legalEntitiesMap = useLegalsMap();

		const authorizedPersons = useFilteredAuthorizedPersonsArray(company?.uuid, legalEntityId);
		const authorizedPersonsMap = useAuthorizedPersonsMap();

		const bankAccounts = useFilteredBankAccounts(company?.uuid, legalEntityId);
		const bankAccountsMap = useBankAccountsMap();

		return (
			<div className="editable-contract__blocks-company">
				<div className="editable-contract__blocks-company-title">Заказчик</div>
				<div className="editable-contract__blocks-company-container">
					<SingleSelectContractField
						items={companies}
						itemsMap={companiesMap}
						label="Заказчик"
						dialogTitle="Заказчик"
						errorMessage="Укажите заказчика"
						hasError={editingState.errors.companyId}
						required
						value={editingState.companyId}
						isMobile={isMobile}
						onConfirm={handleChangeCompany}
						onError={() => onError('companyId')}
					/>

					<SingleSelectContractField
						disabled={!companyId}
						items={legalEntities}
						itemsMap={legalEntitiesMap}
						label="Юридическое лицо"
						dialogTitle="Юридическое лицо"
						errorMessage="Укажите юридическое лицо"
						hasError={editingState.errors.companyLegalEntityId}
						required
						value={editingState.companyLegalEntityId}
						isMobile={isMobile}
						onConfirm={value => onChange(value, 'companyLegalEntityId')}
						onError={() => onError('companyLegalEntityId')}
					/>

					<SingleSelectContractField
						disabled={!companyId || !legalEntityId}
						fieldName="accountNumber"
						items={bankAccounts}
						itemsMap={bankAccountsMap}
						label="Реквизиты"
						dialogTitle="Реквизиты"
						errorMessage="Укажите реквизиты"
						hasError={editingState.errors.companyBankAccountId}
						value={editingState.companyBankAccountId}
						isMobile={isMobile}
						onConfirm={value => onChange(value, 'companyBankAccountId')}
						onError={() => onError('companyBankAccountId')}
					/>

					<SingleSelectContractField
						disabled={!companyId || !legalEntityId}
						items={authorizedPersons}
						itemsMap={authorizedPersonsMap}
						label="Уполномоченное лицо"
						dialogTitle="Уполномоченное лицо"
						errorMessage="Укажите уполномоченное лицо"
						hasError={editingState.errors.companyAuthorizedPersonId}
						value={editingState.companyAuthorizedPersonId}
						isMobile={isMobile}
						onConfirm={value => onChange(value, 'companyAuthorizedPersonId')}
						onError={() => onError('companyAuthorizedPersonId')}
					/>
				</div>
			</div>
		);
	}
);
