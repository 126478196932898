import {DndAttribute} from '../interfaces';

const handle = DndAttribute.HANDLE;
const empty = DndAttribute.EMPTY;
const id = DndAttribute.ID;

const getIdElement = (element: HTMLElement | undefined): HTMLElement | undefined => {
	if (element?.hasAttribute(id) || element?.hasAttribute(empty)) {
		return element;
	}
	const parent = element?.parentElement;
	if (!parent) {
		return undefined;
	}

	if (parent?.hasAttribute(id) || parent?.hasAttribute(empty)) {
		return parent;
	}
	return getIdElement(parent);
};

/**
 * Получает dom элемент по координатам и наличию id аттрибута
 *
 * @param x позиция x
 * @param y позиция y
 * @param isHandle имеет ли элемент аттрибут handle
 */
export const getOverElementByIdAttribute = (
	x: number,
	y: number,
	isHandle?: boolean
): HTMLElement | undefined => {
	const overElement = document.elementFromPoint(x, y) as HTMLElement;
	if (isHandle && !overElement.hasAttribute(handle)) {
		return undefined;
	}

	return getIdElement(overElement);
};
