import {localAuthStorage} from '@tehzor/tools/api/localAuthStorage';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import config from '@src/core/config';

const {appId} = config;

export async function multipleLogout() {
	try {
		await httpRequests.withToken.post('/auth/multiple-logout', {appId});
	} finally {
		await localAuthStorage.clear();
	}
}
