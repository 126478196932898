import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {ICustomFiltersData} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';
import {useCallback} from 'react';

export const useCustomFiltersHandlers = (
	change: (changes: IChanges<{custom?: ICustomFiltersData}>) => void,
	filtersData: ICustomFiltersData | undefined
): [(data: ICustomFiltersData | Record<string, undefined>) => void, (key: string) => void] => {
	const onChange = useCallback(
		(data: ICustomFiltersData | Record<string, undefined>) => {
			change({custom: {...filtersData, ...data}});
		},
		[change, filtersData]
	);

	const onClear = useCallback(
		(key: string) => {
			if (filtersData?.hasOwnProperty(key)) {
				const {[key]: removedValue, ...rest} = filtersData;
				change({custom: rest});
			}
		},
		[change, filtersData]
	);

	return [onChange, onClear];
};
