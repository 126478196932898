import {ReactNode, useCallback, useMemo, useState} from 'react';
import {debounce} from 'lodash';
import {
	formAllExpandedGroups,
	formInitialExpandedGroups
} from '@tehzor/ui-components/src/components/delegation/DelegationTree/utils/formInitialExpandedGroups';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {filterBySearch} from '../utils/filterBySearch';
import {useUpdateEffect} from 'react-use';

interface IUseDelegationSearchProps {
	workingGroups: IEnrichedWorkingGroup[];
	selectedUsers?: string[];
	selectedGroup?: string;

	placeholder: string;

	changeExpandedGroups: (groups: string[]) => void;
}

export const useDelegationSearch = ({
	workingGroups,
	selectedUsers,
	selectedGroup,
	placeholder,
	changeExpandedGroups
}: IUseDelegationSearchProps): [IEnrichedWorkingGroup[], ReactNode, () => void] => {
	const [searchValue, setSearchValue] = useState('');
	const [filteredGroups, setFilteredGroups] = useState(workingGroups);

	useUpdateEffect(() => setFilteredGroups(workingGroups), [workingGroups]);

	const changeSearchValue = useCallback(
		(value: string) => {
			setSearchValue(value);

			const newFilteredGroups = filterBySearch(workingGroups, value);
			setFilteredGroups(newFilteredGroups);

			const newExpandedGroups = value
				? formAllExpandedGroups(newFilteredGroups)
				: formInitialExpandedGroups(newFilteredGroups, selectedUsers, selectedGroup);

			changeExpandedGroups(newExpandedGroups);
		},
		[workingGroups, selectedUsers, selectedGroup]
	);

	const handleChangeSearch = useMemo(() => debounce(changeSearchValue, 300), [changeSearchValue]);

	const clearSearchValue = () => changeSearchValue('');

	const component = (
		<TranslatedSelectSearch
			value={searchValue}
			onChange={handleChangeSearch}
			placeholder={placeholder}
		/>
	);

	return [filteredGroups, component, clearSearchValue];
};
