import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {LoadingPanel} from '@tehzor/ui-components';
import './CategoriesSetsPage.less';
import CategoriesSetsTable from './components/table/CategoriesSetsTable';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCategoriesSetBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCategoriesSetBreadcrumbs';
import CategoriesSetsPageHeader from './components/CategoriesSetsPageHeader';
import classNames from 'classnames';
import CategoriesSetsFilters from './components/CategoriesSetsFilters';
import {ActionsDesktop} from './actions/Actions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useCategoriesSetPermissions} from '@src/core/hooks/permissions/useCategoriesSetPermissions';
import useAddingCategoriesSet from '@src/components/AddingCategoriesSet/hooks/useAddingCategoriesSet';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useParams} from 'react-router';
import {useExtractCategoriesSetsForPage} from '@src/core/hooks/queries/categorySets/hook';
import {extractCategoriesSetsPageSettings} from '@src/store/modules/settings/pages/manage/categoriesSets/selectors';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useTranslation} from 'react-i18next';

export const CategoriesSetsPage = ({className}: {className?: string}) => {
	const {t} = useTranslation();
	useScrollRestoration();

	const {objectId} = useParams<{objectId?: string}>();

	const isDesktop = useIsDesktop();
	const {canAdd} = useCategoriesSetPermissions();

	const breadcrumb: IBreadcrumb[] = useCategoriesSetBreadcrumbs(objectId);

	useAppHeader({title: t('categoriesSetsPage.title')}, []);

	const addButton = useAddingCategoriesSet(canAdd);
	const settings = useAppSelector(state =>
		extractCategoriesSetsPageSettings(state, objectId || 'all')
	);
	const {data: allCategories} = useExtractAllCategoriesAsArray();
	const {data: setsData, isLoading} = useExtractCategoriesSetsForPage(
		objectId || 'all',
		settings,
		allCategories
	);

	const actions = <ActionsDesktop />;

	return (
		<LoadingPanel
			className={classNames('categories-sets__loading-panel')}
			active={isLoading}
		>
			<div className={classNames('categories-sets', 'page-cont', className)}>
				<AppBreadcrumbs
					className="categories-sets__breadcrumbs"
					items={breadcrumb}
				>
					{isDesktop && actions}
				</AppBreadcrumbs>
				<CategoriesSetsPageHeader total={setsData?.total || 0} />
				<CategoriesSetsFilters objectId={objectId} />
				{setsData && (
					<CategoriesSetsTable
						objectId={objectId}
						{...setsData}
					/>
				)}
				{!isDesktop && addButton}
			</div>
		</LoadingPanel>
	);
};
