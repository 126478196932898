import {useMemo} from 'react';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import ICanvasData from '@tehzor/tools/interfaces/ICanvasData';

/**
 * Извлекает из вложений ссылки на изображения
 *
 * @param attachments вложения
 */
export const useAttachmentsImagesObjects = (attachments: IAttachment[]) =>
	useMemo(
		() =>
			attachments.reduce<
				Array<{id: string, url: string, canvas?: ICanvasData, original?: string}>
			>((prev, item) => {
				if (item.full) {
					prev.push({
						id: item.id,
						url: item.full.url,
						canvas: item.canvas,
						original: item.original?.url
					});
				}
				return prev;
			}, []),
		[attachments]
	);
