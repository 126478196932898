import {wsConnector} from '../wsConnector';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';

export type IGetOwnerAcceptanceResponse = ILinkedOwnerAcceptance;

/**
 * Возвращает передачу собственнику
 *
 * @param objectId id объекта
 * @param acceptanceId id передачи
 */
export const requestOwnerAcceptance = (objectId: string, acceptanceId: string) =>
	wsConnector.sendAuthorizedRequest<IGetOwnerAcceptanceResponse>('getOwnerAcceptance', {
		objectId,
		acceptanceId
	});
