import AppNotifications from './AppNotifications';
import useAppSelector from '@src/core/hooks/useAppSelector';

const AppNotificationsWrap = () => {
	const user = useAppSelector(s => s.auth.profile);

	return user.activated ? <AppNotifications/> : null;
};

export default AppNotificationsWrap;
