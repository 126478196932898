import {useMemo} from 'react';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from 'react-i18next';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';

export const useTranslatedObjectStagesMap = () => {
	const {t} = useTranslation();
	const {data: stagesMap} = useObjectStagesMap();

	return useMemo(() => {
		if (!stagesMap) {
			return {};
		}
		const translated: Record<string, IObjectStage> = {};
		for (const [key, value] of Object.entries(stagesMap)) {
			translated[key] = {
				...value,
				name: t(`objectStages.${value.id}`)
			};
		}
		return translated;
	}, [stagesMap, t]);
};
