import {testEntityRestrictions} from '@src/utils/testRestrictions';
import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export interface IEntityCommentsPermissions {
	canViewComments?: boolean;
	canViewReplies?: boolean;
	canAddComments?: boolean;
	canAddReplies?: boolean;
}

interface ICommentsPermissionsParams {
	objectId: string;
	createdBy?: IBriefUser;
	performers?: string[];
	inspectors?: string[];
	watchers?: string[];
}

/**
 * Возвращает полномочия для списка комментариев
 * @param {Object} params объект параметров хука
 * @param params.objectId id объекта
 * @param params.createdBy кем создана сущность
 * @param params.performers исполнители или принимающие
 * @param params.inspectors проверяющие
 * @param params.watchers наблюдатели
 */
export const useCommentsPermissions = ({
	objectId,
	createdBy,
	performers,
	inspectors,
	watchers
}: ICommentsPermissionsParams): IEntityCommentsPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canViewComments: testEntityRestrictions(
				user.id,
				user.roles,
				'commentsView',
				objectId,
				createdBy,
				performers,
				inspectors,
				watchers
			),
			canViewReplies: testEntityRestrictions(
				user.id,
				user.roles,
				'repliesView',
				objectId,
				createdBy,
				performers,
				inspectors,
				watchers
			),
			canAddComments: testEntityRestrictions(
				user.id,
				user.roles,
				'commentsAdd',
				objectId,
				createdBy,
				performers,
				inspectors,
				watchers
			),
			canAddReplies: testEntityRestrictions(
				user.id,
				user.roles,
				'repliesAdd',
				objectId,
				createdBy,
				performers,
				inspectors,
				watchers
			)
		};
	}, [objectId, createdBy, performers, inspectors, watchers, user]);
};
