import {Dispatch, useCallback, useMemo, useState} from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import {useUpdateEffect} from 'react-use';
import {makeTreeDataSpace} from './utils/makeTreeData';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import './SpaceTreeSelect.less';
import {SpaceLocationSelect} from './SpaceLocationSelect';
import {useExtractSpaceTypesAsArray, useSpaceTypes} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useSpacesAsArray, useSpacesData} from '@src/core/hooks/queries/spaces/hooks';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useTranslation} from 'react-i18next';

interface ISpaceTreeSelectProps<S, E> {
	objectId: string;
	className?: string;
	style?: React.CSSProperties;
	field: keyof S;
	label?: string;
	value?: string[];
	locations?: Record<string, {planId: string; location: ILocation}>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const SpaceTreeSelect = <S extends {spaceIds?: string[]; locations?: ILocation | null}, E>({
	objectId,
	className,
	style,
	field = 'spaceIds',
	label = 'Помещения',
	value,
	locations,
	editingDispatch,
	required,
	disabled,
	hasError
}: ISpaceTreeSelectProps<S, E>) => {
	const {t} = useTranslation();
	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const {data: spaces} = useSpacesAsArray(objectId);
	const {data: spaceTypes} = useSpaceTypes();

	const {data: spaceTypesArray} = useExtractSpaceTypesAsArray();
	const {data: spacesById} = useSpacesData(objectId);
	const treeData = useMemo(
		() => makeTreeDataSpace(spaces, spaceTypesArray),
		[spaces, spaceTypesArray]
	);

	const locationBySpace = useMemo(
		() =>
			locations
				? value?.map(id => ({spaceId: id, location: locations ? locations[id] : undefined}))
				: undefined,
		[locations, value]
	);

	const handleChange = useCallback(() => {
		editingDispatch({type: 'update', field, value: selectedObjects});
		if (required) {
			editingDispatch({type: 'update-error', field});
		}
	}, [required, selectedObjects, field, editingDispatch]);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
	}, []);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
	}, [value]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	useUpdateEffect(() => {
		setSelectedObjects(value);
	}, [value]);

	const title =
		value && spaceTypes && value?.length > 0
			? value.length > 1
				? `${formSpaceTitle(
						spacesById[value[0]]?.name,
						undefined,
						spaceTypes.byId[spacesById[value[0]]?.type]
				  )} + ${value.length - 1}`
				: `${formSpaceTitle(
						spacesById[value[0]]?.name,
						undefined,
						spaceTypes.byId[spacesById[value[0]]?.type]
				  )}`
			: undefined;

	const trigger = (
		<TextFieldWithForwardedRef
			elementType="div"
			value={title}
			icon={<i className="tz-simple-arrow-20" />}
			error={required && hasError ? 'Выберите помещения' : undefined}
			disabled={disabled}
		/>
	);

	return (
		<div
			className={`${className} space-tree-select_front-select-area`}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			{spacesById && (
				<SelectPopup
					trigger={trigger}
					clearButton={!!selectedObjects?.length}
					footer
					noHeader
					count={selectedObjects?.length}
					onApply={handleChange}
					onCancel={handleCancel}
					onClear={handleClear}
				>
					<TreeSelect
						data={treeData}
						multiple
						value={selectedObjects}
						onChange={setSelectedObjects}
						expandedValue={expandedObjects}
						onExpand={handleExpand}
					/>
				</SelectPopup>
			)}
			{locations && !!locationBySpace?.length && (
				<div className="space-tree-select_locations">
					<EditableFieldLabel className="space-tree-select_locations_label">
						{t('components.editableFields.locationsTreeSelect.label')}
					</EditableFieldLabel>
					<div className="space-tree-select space-tree-select_location-select-area space-tree-select__info-grid">
						{locationBySpace &&
							locationBySpace.map(spaceEl => (
								<SpaceLocationSelect
									locations={locations}
									objectId={objectId}
									spaceEl={spaceEl}
									editingDispatch={editingDispatch}
								/>
							))}
					</div>
				</div>
			)}
		</div>
	);
};
