import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	CategoriesSetFilterMobile,
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	CriticalFilterMobile,
	PlannedFixDateFilterMobile,
	ObjectsFilterMobile,
	PlansFilterMobile,
	ProblemStatusesFilterMobile,
	ActualFixDateFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {ExpiredFilterMobile} from '@src/components/MobileEntitiesFilters/components/ExpiredFilterMobile';
import {InspectorsFilterMobile} from '@src/components/MobileEntitiesFilters/components/InspectorsFilterMobile';
import {ProblemTagsSetFilterMobile} from '@src/components/MobileEntitiesFilters/components/ProblemTagsSetFilterMobile';
import {StagesFilterMobile} from '@src/components/MobileEntitiesFilters/components/StagesFilterMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useProblemSettings} from '@src/core/hooks/settings/useProblemSettings';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {LinkButton} from '@tehzor/ui-components';
import {stringify} from 'query-string';
import {useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {PerformersFilterMobile} from '@src/components/MobileEntitiesFilters/components/PerformersFilterMobile';
import {CustomFiltersMobile} from '@src/components/CustomFilters/CustomFiltersMobile';
import {ReopenedTimesFilterMobile} from "@src/components/MobileEntitiesFilters/components/ReopenedTimesFilterMobile";

const ProblemsFilterPage = () => {
	const {t} = useTranslation();

	const {pushPath} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();
	const settings = useProblemSettings(objectId || 'all');
	const canGoBack = (useLocation().state as {canGoBack?: boolean | null})?.canGoBack;
	const {state, isLocal, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IProblemsFiltersState>();

	const [isCleared, setIsCleared] = useState(false);

	const search = stringify(state, {arrayFormat: 'index'});

	const handleApply = useCallback(() => {
		if (canGoBack) {
			if (isLocal) {
				apply();
				pushPath(
					objectId ? `/objects/${objectId}/problems/?${search}` : `/problems/?${search}`
				);
			} else {
				apply();
				pushPath(objectId ? `/objects/${objectId}/problems` : '/problems');
			}
		}
	}, [canGoBack, isLocal, apply, pushPath, objectId, search]);

	useAppHeader(
		{
			title: t('problemsFiltersPage.title'),
			showBackBtn: true,
			mobileRightButtons: isCleanable<IProblemsFiltersState>(state, objectId) ? (
				<LinkButton
					label={t('problemsFiltersPage.resetFiltersBtn.label')}
					onClick={() => {
						mobileClear();
						setIsCleared(true);
					}}
				/>
			) : null
		},
		[state, objectId, t]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
			acceptBtnLabel={t('filterPage.acceptBtn.label')}
		>
			{objectId === undefined && (
				<ObjectsFilterMobile
					entity={entity}
					value={state?.objects}
				/>
			)}
			<ProblemStatusesFilterMobile
				objectId={objectId}
				value={state?.statuses}
				entity={entity}
				onChange={change}
			/>

			{settings.builtin.plannedFixDate && (
				<PlannedFixDateFilterMobile
					objectId={objectId}
					from={state.plannedFixDateFrom}
					to={state.plannedFixDateTo}
					entity={entity}
				/>
			)}
			<ActualFixDateFilterMobile
				objectId={objectId}
				from={state.actualFixDateFrom}
				to={state.actualFixDateTo}
				entity={entity}
			/>
			{settings.builtin.critical && (
				<CriticalFilterMobile
					value={state.critical}
					label={t('problemsPage.booleanFilter.label')}
					onChange={change}
				/>
			)}

			<ExpiredFilterMobile
				value={state.expired}
				label={t('problemsPage.expiredFilter.label')}
				onChange={change}
			/>

			<ProblemTagsSetFilterMobile
				objectId={objectId}
				label={t('problemsPage.problemTagsSetFilter.label')}
				value={state?.problemTags}
				entity={entity}
				onChange={change}
				isCleared={isCleared}
				setIsCleared={setIsCleared}
			/>

			<CreatedByFilterMobile
				objectId={objectId}
				label={t('problemsPage.createdByFilter.label')}
				value={state?.createdBy}
				entity={entity}
				onChange={change}
			/>

			<CreationDateFilterMobile
				objectId={objectId}
				label={t('problemsPage.creationDateFilter.label')}
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity}
			/>

			{settings.builtin.categoryId && (
				<CategoriesSetFilterMobile
					objectId={objectId}
					label={t('problemsPage.categoriesSetFilter.label')}
					value={state?.categories}
					entity={entity}
					onChange={change}
				/>
			)}

			{objectId !== undefined && settings.builtin.location && (
				<PlansFilterMobile
					objectId={objectId}
					label={t('problemsPage.plansFilter.label')}
					value={state?.plans}
					entity={entity}
					onChange={change}
				/>
			)}

			{settings.builtin.performers && (
				<PerformersFilterMobile
					objectId={objectId ?? 'all'}
					label={t('entitiesFilters.performers.label')}
					value={state?.performers}
					entity={entity}
					onChange={change}
				/>
			)}

			{settings.builtin.inspectors && (
				<InspectorsFilterMobile
					objectId={objectId ?? 'all'}
					label={t('entitiesFilters.inspectors.label')}
					value={state?.inspectors}
					entity={entity}
					onChange={change}
				/>
			)}

			<StagesFilterMobile
				objectId={objectId}
				label={t('problemsPage.stagesFilter.label')}
				value={state?.stages}
				entity={entity}
				onChange={change}
			/>

			<ReopenedTimesFilterMobile
				objectId={objectId ?? 'all'}
				label={t('entitiesFilters.reopenedTimes.label')}
				entity={entity}
				onChange={change}
				value={state?.reopenedTimes}
			/>


			{settings.custom && (
				<CustomFiltersMobile
					objectId={objectId ?? 'all'}
					settings={settings.custom}
					filtersData={state.custom}
					entity={entity}
				/>
			)}
		</TranslatedFilterPage>
	);
};

export default ProblemsFilterPage;
