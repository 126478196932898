import {memo} from 'react';
import {CellProps} from 'react-table';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {FieldDescriptionCell} from '../FieldDescriptionCell/FieldDescriptionCell';
import {FieldCompanyCell} from '../FieldCompanyCell/FieldCompanyCell';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import styles from './MobileCell.module.less';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {TableRowSelect} from '@tehzor/ui-components';

export const MobileCell = memo((props: CellProps<IPreparedFieldSettingsSet>) => {
	const {row} = props;

	const {createdAt, createdBy, modifiedAt, modifiedBy} = row.original;
	const dates: Array<{name: string; at?: number; by?: IBriefUser}> = [];
	if (createdAt || createdBy) {
		dates.push({name: 'Добавлено:', at: createdAt, by: createdBy});
	}
	if (modifiedAt || modifiedBy) {
		dates.push({name: 'Изменено:', at: modifiedAt, by: modifiedBy});
	}

	return (
		<div className={styles.mobileCell}>
			<ClickPreventWrap className={styles.mobileSelect}>
				<TableRowSelect {...row.getRowProps()} />
			</ClickPreventWrap>
			<div className={styles.container}>
				<FieldDescriptionCell {...props} />
				<FieldCompanyCell {...props} />

				{dates.map(({at, by, name}) => (
					<div
						key={name}
						className={styles.date}
					>
						<div className={styles.name}>{name}</div>
						<div className={styles.info}>
							{at && <div>{format(at, dateTimeCommaSeparatedFormat)}</div>}
							{by && <div>{by.displayName}</div>}
						</div>
					</div>
				))}
			</div>
		</div>
	);
});
