import {PrivateRoute} from '@src/containers/PrivateRoute';
import {ProblemTagsSetsPage} from '@src/pages/manage/ProblemTagsSetsPage/ProblemTagsSetsPage';
import {ProblemTagsSetEditingPage} from '@src/pages/manage/ProblemTagsSetEditingPage/ProblemTagsSetEditingPage';

export const manageProblemTagsSetsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<ProblemTagsSetsPage/>}/>
	},
	{
		path: 'add',
		element: <PrivateRoute element={<ProblemTagsSetEditingPage/>}/>
	},
	{
		path: ':problemTagsSetId',
		element: <PrivateRoute element={<ProblemTagsSetEditingPage/>}/>
	}
];
