import * as React from 'react';
import './PhotoArrowButton.less';
import classNames from 'classnames';
import ButtonBase from '../../buttons/ButtonBase';

interface IPhotoArrowButtonProps {
	className?: string;
	style?: React.CSSProperties;
	type: 'left' | 'right';
	onClick?: (event: React.MouseEvent) => void;
}

const PhotoArrowButton = (props: IPhotoArrowButtonProps) => {
	const {className, style, type, onClick} = props;

	return (
		<ButtonBase
			className={classNames(`photo-arrow-button_${type}`, className)}
			style={style}
			leftIcon={<i className="tz-simple-arrow-24"/>}
			classNamePrefix="photo-arrow-button"
			outerTagType="button"
			onClick={onClick}
		/>
	);
};

export default PhotoArrowButton;