import { useCallback } from 'react';
import * as React from 'react';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import useToggle from 'react-use/lib/useToggle';
import {useExportChecks} from '@src/core/hooks/mutations/checks/useExportChecks';

const availableDestinations = [
	ExportTemplatesDestinationId.CHECKS,
	ExportTemplatesDestinationId.CHECKS_PROBLEMS
];

export function useChecksExport(objectId = 'all'): [React.ReactNode, () => void] {
	const {filters, sort, selectedRows} = useAppSelector(s => extractChecksPageSettings(s, objectId));
	const [isOpen, toggleOpen] = useToggle(false);
	const exportChecks = useExportChecks();

	const handleExport = useCallback(
		(template: IExportTemplate, email?: string, createDocument?: boolean) => {
			exportChecks(
				template.id,
				createDocument,
				email,
				objectId !== 'all' ? objectId : undefined,
				filters,
				sort,
				selectedRows
			);

			toggleOpen(false);
		},
		[exportChecks, objectId, filters, sort, selectedRows, toggleOpen]
	);

	const dialog = (
		<ExportDialog
			pageKey="checks"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={toggleOpen}
		/>
	);

	return [dialog, toggleOpen];
}
