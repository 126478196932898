import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {IChangedStatus} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback} from 'react';
import {extractLocalSpaceStatusChangesJournal} from './selector';

export const useLocalSpaceStatusChangesJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: spacesQueryKeys.status.edit()});
	const selector = useCallback(
		(records: IChangedStatus[]) => extractLocalSpaceStatusChangesJournal(records, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IChangedStatus[], unknown, IOfflineJournalEntity[]>({
		queryKey: spacesQueryKeys.status.localList(),
		select: selector
	});
	return data || [];
};
