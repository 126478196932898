import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingProblemComment} from '@src/interfaces/saving/ISavingProblemComment';
import {IEditCommentResponse} from '@src/api/backend/comment';
import {makeReplyEditRequest} from '@src/api/backend/reply';

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditCommentResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении ответа');
	return {
		type: types.EDIT_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет ответ на нарушение
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const editProblemReply = (
	objectId: string,
	commentId: string,
	fields: ISavingProblemComment
) =>
	createApiAction<IEditCommentResponse>(request, success, failure, () =>
		makeReplyEditRequest(objectId, commentId, fields)
	);
