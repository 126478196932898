import {StatsTreePanel, VerticalTabContent} from '@tehzor/ui-components';
import {Tree} from 'array-to-tree';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import CommonStatsCards from './CommonStatsCards';
import SubObjectStats from './SubObjectStats';
import {StatsMap} from '@src/utils/statsAggregators/creation';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';

interface IObjectStatsProps {
	data: Tree<IStatsObject>;
	objectsStats: StatsMap;
}

const ObjectStats = ({data, objectsStats}: IObjectStatsProps) => {
	const sourcesLoading = useAppSelector(extractSourcesLoading);
	const objectStats = objectsStats[data.id];

	return (
		<VerticalTabContent className="statistics-page__tabs-content-panel">
			<StatsTreePanel
				id={data.id}
				title={data.name}
				image={data.image?.url}
				loading={sourcesLoading}
				showExpandBtnPlaceholder={!!data.children?.length}
				permanentContent={(
					<CommonStatsCards
						stats={objectStats}
						hideChecks
						hideInspections
						hideSpaces
     />
				)}
				hiddenContent={data.children?.map(item => (
					<SubObjectStats
						key={item.id}
						data={item}
						stats={objectsStats}
						level={1}
					/>
				))}
			/>
		</VerticalTabContent>
	);
};

export default ObjectStats;
