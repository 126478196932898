import { CSSProperties, Dispatch, ReactNode, useCallback } from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {IUploadingFilesAction} from '@src/core/hooks/states/useUploadingFilesState';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import classNames from 'classnames';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertRawFiles} from './utils/convertRawFile';
import {addTempFile, deleteTempFile} from '@src/store/modules/entities/tempFile/actions';
import {useUploadsImages} from '@src/core/hooks/useUploadsImages';
import {useUploadsImagesObjects} from '@src/core/hooks/useUploadsImagesObject';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAttachmentsAndUploadsViewer} from '@src/core/hooks/imagesViewers/desktop/useAttachmentsAndUploadsViewer';
import {useAttachmentsAndUploadsViewer as useMobileAttachmentsAndUploadsViewer} from '@src/core/hooks/imagesViewers/mobile/useAttachmentsAndUploadsViewer';
import {EditableAttachments, FilesDropArea, Scrollbar} from '@tehzor/ui-components';
import IFile from '@tehzor/tools/interfaces/IFile';
import {useFileImage} from './hooks/useFileImage';
import {useFileImageObject} from './hooks/useFileImageObject';
import {useSavedImage} from './hooks/useSavedImage';

interface IImageFieldProps<S, E> {
	className?:
		| string
		| {
				root?: string;
				attachBtn?: string;
				scrollArea?: string;
				images?: string;
				files?: string;
				image?: string;
				file?: string;
		  };
	style?: CSSProperties;
	image?: IFile;
	uploadingFile?: IUploadingFile;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	label?: string;
	imageDestination?: AttachmentFileDestination;
	imageTitle?: string;
	entityId?: string;
	required?: boolean;
	disabled?: boolean;
	showAttachBtn?: boolean;
	icon?: ReactNode;
	canDraw?: boolean;
	hasError?: boolean;
	errorMessage?: string;
	sizeErrorMessage?: string;
}

export const ImageField = <S extends {image: IAttachment}, E>({
	className,
	style,
	image,
	uploadingFile,
	editingDispatch,
	uploadingFilesDispatch,
	label,
	imageDestination,
	imageTitle,
	entityId,
	required,
	disabled,
	showAttachBtn,
	icon,
	canDraw = false,
	hasError,
	errorMessage = 'Прикрепите изображение',
	sizeErrorMessage = 'Размер файла не должен привышать 50 мб'
}: IImageFieldProps<S, E>) => {
	const dispatch = useAppDispatch();
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const classes = convertClassNames(className);

	const updateErrors = useCallback(() => {
		if (required) {
			editingDispatch({type: 'update-error', field: 'image'});
			uploadingFilesDispatch({type: 'update-error'});
		}
	}, [required]);

	const handlePickImage = useCallback(
		(file: IRawFile[]) => {
			uploadingFilesDispatch({
				type: 'update',
				value: [convertRawFiles(file[0], online)]
			});
			updateErrors();
		},
		[online, updateErrors]
	);

	const addTemporaryFile = useCallback(
		(...args: Parameters<typeof addTempFile>) => dispatch(addTempFile(...args)),
		[]
	);

	const deleteTemporaryFile = useCallback(async (fileId: string) => {
		await dispatch(deleteTempFile(fileId));
	}, []);

	const handleUploadingFileChange = useCallback(
		(value: IUploadingFile[]) => {
			uploadingFilesDispatch({type: 'update', value});
			updateErrors();
		},
		[updateErrors]
	);

	const handleImageChange = useCallback(
		(value: IAttachment[]) => {
			const image = value[0];

			if (image) {
				editingDispatch({type: 'update', field: 'image', value: image});
			} else {
				editingDispatch({type: 'update', field: 'image', value: null});
			}
			updateErrors();
		},
		[updateErrors]
	);

	const attachmentImage = useFileImage(image);
	const attachmentImageObject = useFileImageObject(image);
	const uploadsImage = useUploadsImages(uploadingFile ? [uploadingFile] : []);
	const uploadsImageObject = useUploadsImagesObjects(uploadingFile ? [uploadingFile] : []);

	const savedImage = useSavedImage(image, uploadingFile);

	const isDesktop = useIsDesktop();

	const [desktopImagesViewer, openAttachmentImage, openUploadsImage] =
		useAttachmentsAndUploadsViewer(
			attachmentImage,
			uploadsImage,
			attachmentImageObject,
			uploadsImageObject,
			entityId,
			imageDestination,
			imageTitle || '',
			canDraw
		);

	const [mobileImagesViewer, openMobileAttachmentImage, openMobileUploadsImage] =
		useMobileAttachmentsAndUploadsViewer(
			attachmentImage,
			uploadsImage,
			attachmentImageObject,
			uploadsImageObject,
			entityId,
			imageDestination,
			imageTitle || '',
			canDraw
		);

	return (
		<div className={classNames('editable-image-field', classes.root)} style={style}>
			<Scrollbar
				className={classNames(
					'editable-attachments-field__scroll-area',
					classes.scrollArea
				)}
				simpleBarProps={{autoHide: false}}
			>
				<EditableAttachments
					className={{
						images: classes.images,
						files: classes.files,
						image: classes.image,
						file: classes.file
					}}
					online={online}
					onSavedAttachmentImageClick={
						isDesktop ? openAttachmentImage : openMobileAttachmentImage
					}
					onUploadingImageClick={isDesktop ? openUploadsImage : openMobileUploadsImage}
					uploadingFiles={uploadingFile ? [uploadingFile] : []}
					savedAttachments={savedImage}
					disabled={disabled}
					onUploadingFilesChange={handleUploadingFileChange}
					onSavedAttachmentsChange={handleImageChange}
					onAddTempFile={addTemporaryFile}
					onDeleteTempFile={deleteTemporaryFile}
				/>
				{isDesktop ? desktopImagesViewer : mobileImagesViewer}
			</Scrollbar>

			{uploadingFile?.sizeError && (
				<div className="editable-attachments-field__error">
					{sizeErrorMessage}
				</div>
			)}

			{showAttachBtn && (
				<FilesDropArea
					className={classNames(
						'editable-attachments-field__attach-btn',
						classes.attachBtn
					)}
					accept="image/jpeg,image/png,image/gif"
					label={label}
					icon={icon}
					disabled={disabled}
					onPick={handlePickImage}
				/>
			)}

			{required && hasError && (
				<div className="editable-attachments-field__error">{errorMessage}</div>
			)}
		</div>
	);
};
