import React, {useCallback, useState} from 'react';
import {EditableReply} from '@src/components/EditableReply';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

export function useReplyDialog(
	objectId: string,
	links?: IComment['links']
): [React.ReactNode, () => void, (comment: IComment) => void] {
	const [isOpen, setOpen] = useState<boolean>(false);
	const [comment, setComment] = useState<IComment | undefined>();
	const add = useCallback(() => {
		setComment(undefined);
		setOpen(true);
	}, []);

	const edit = useCallback((comment: IComment) => {
		setComment(comment);
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const clear = useCallback(() => {
		setComment(undefined);
	}, []);

	const dialog = (
		<EditableReply
			objectId={objectId}
			comment={comment}
			links={links}
			isOpen={isOpen}
			onClose={close}
			onAfterClose={clear}
		/>
	);

	return [dialog, add, edit];
}
