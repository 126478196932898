import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {
	IAuthorizedPerson,
	ISavingAuthorizedPerson
} from '@tehzor/tools/interfaces/authorizedPersons';

export type IAddAuthorizedPersonResponse = IAuthorizedPerson;

/**
 * Добавляет уполномоченное лицо
 */
export const makeAuthorizedPersonAddRequest = async (fields: ISavingAuthorizedPerson) => {
	const response = await httpRequests.withToken.post<IAddAuthorizedPersonResponse>(
		'authorized-persons',
		fields
	);
	return response.data;
};
