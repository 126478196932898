import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import * as spaceTypes from '@src/store/modules/entities/space/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import * as settingsTypes from '@src/store/modules/settings/pages/spaces/constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ILoadedEntityState} from '@tehzor/tools/utils/reducersHandlers';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {IGetSpacesSchemaPayload} from '../actions';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {CLEAR_BEFORE_UPDATE} from '@src/store/modules/app/updateData/constants';
import _ from 'lodash';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export interface ISpacesListPageState extends ILoadedEntityState<IListSpace> {
	offset: number;
	total: number;
	selected: string[];
}

export type ISpacesListPagesState = Record<string, ISpacesListPageState>;

export const getInitialStateForPage = (): ISpacesListPageState => ({
	byId: {},
	allIds: [],
	loaded: false,
	offset: 0,
	total: 0,
	selected: []
});

const clearPage = (state: ISpacesListPagesState, {payload}: {payload: {objectId: string}}) => {
	if (state[payload.objectId]) {
		state[payload.objectId].loaded = false;
		state[payload.objectId].offset = 0;
		state[payload.objectId].total = 0;
	}
	if (state.all) {
		state.all.loaded = false;
		state.all.offset = 0;
		state.all.total = 0;
	}
};

const applySpaceUpdate = (
	state: ISpacesListPagesState,
	{payload}: {payload: ISpace & {pageObjectId: string}}
) => {
	const page = state[payload.pageObjectId];
	if (page && page.allIds.includes(payload.id)) {
		page.byId[payload.id] = {...page.byId[payload.id], ...payload};
	}
};

export default createReducer<ISpacesListPagesState>(
	{},
	{
		[types.GET_LIST_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_LIST_SUCCESS]: (state, {payload}: {payload: IGetSpacesSchemaPayload}) => {
			const page = state[payload.objectId];
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.offset = payload.offset;
			page.total = payload.total;
			page.loaded = true;
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string; offset: number}}
		) => {
			const page = state[payload.objectId];
			if (page) {
				page.offset = payload.offset;
				page.loaded = false;
			}
		},
		[types.CHANGE_SELECTED]: (
			state,
			{payload}: {payload: {objectId: string; value: string[]}}
		) => {
			if (state[payload.objectId]) {
				state[payload.objectId].selected = payload.value;
			}
		},
		// При манипуляциях с фильтрами, сортировкой, пагинацией необходимо сбросить loaded для игнорирования кеша
		[settingsTypes.CHANGE_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].loaded = false;
				state[payload.objectId].offset = 0;
				state[payload.objectId].total = 0;
			}
		},
		[settingsTypes.CLEAR_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].loaded = false;
				state[payload.objectId].offset = 0;
				state[payload.objectId].total = 0;
			}
		},
		[settingsTypes.CHANGE_SORT]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].loaded = false;
			}
		},
		[settingsTypes.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].loaded = false;
			}
		},
		[spaceTypes.ADD_SUCCESS]: clearPage,
		[spaceTypes.EDIT_STATUS_SUCCESS]: applySpaceUpdate,
		[spaceTypes.EDIT_INDICATORS_SUCCESS]: applySpaceUpdate,
		[spaceTypes.EDIT_SUCCESS]: applySpaceUpdate,
		[problemTypes.ADD_SUCCESS]: clearPage,
		[problemTypes.EDIT_SUCCESS]: clearPage,
		[problemTypes.EDIT_STATUS_SUCCESS]: clearPage,
		[problemTypes.DELETE_SUCCESS]: clearPage,
		[CLEAR_STORE]: () => ({}),
		[CLEAR_BEFORE_UPDATE]: state => {
			_.forIn(state, (_value, key) => {
				state[key].loaded = false;
			});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
		}
	}
);
