import {useExtractWorkAcceptanceStatusesAsArray} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useTranslatedProblemStatuses} from '@src/core/hooks/translations/useProblemStatuses';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import styles from './StructuresLegend.module.less';
import {extractStructuresPageSettings} from '@/entities/Structures';
import {DiagramIcon, getLegendGroupItems, LegendGroup, LegendPopup} from '@tehzor/ui-components';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {useTranslation} from 'react-i18next';

interface ILegendProps {
	objectId: string;
}

export const StructuresLegend = ({objectId}: ILegendProps) => {
	const {t} = useTranslation();
	const isDesktop = useIsDesktop();
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const problemStatuses = useTranslatedProblemStatuses();
	const problemsLegendGroupItems = getLegendGroupItems(problemStatuses, color =>
		displayMode === StructuresDisplayMode.LIST ? (
			<i
				className="tz-problem-16"
				style={{color}}
			/>
		) : (
			<DiagramIcon
				color={color}
				isExternal
			/>
		)
	);

	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatusesAsArray();
	const workAcceptancesLegendGroupItems = getLegendGroupItems(workAcceptanceStatuses, color =>
		displayMode === StructuresDisplayMode.LIST ? (
			<i
				className="tz-work-acceptance-16"
				style={{color}}
			/>
		) : (
			<DiagramIcon
				color={color}
				className={styles.internalDiagramIcon}
			/>
		)
	);

	return (
		<LegendPopup isDesktop={isDesktop}>
			<LegendGroup
				title={t('structuresPage.legend.problemsGroup.title')}
				items={problemsLegendGroupItems}
			/>
			<LegendGroup
				title={t('structuresPage.legend.workAcceptancesGroup.title')}
				items={workAcceptancesLegendGroupItems}
			/>
		</LegendPopup>
	);
};
