import { forwardRef, Ref } from 'react';
import './DottedLinkButton.less';
import classNames from 'classnames';
import LinkButton, {ILinkButtonProps} from '../LinkButton';

type IDottedLinkButtonProps = Omit<
	ILinkButtonProps,
	'leftIcon' | 'leftIconClassName' | 'rightIcon' | 'rightIconClassName'
>;

const DottedLinkButton = (props: IDottedLinkButtonProps, ref?: Ref<HTMLAnchorElement>) => {
	const {className, wrapClassName, labelClassName, ...rest} = props;

	return (
		<LinkButton
			{...rest}
			className={classNames('dotted-link-button', className)}
			wrapClassName={classNames('dotted-link-button__wrap', wrapClassName)}
			labelClassName={classNames('dotted-link-button__label', labelClassName)}
			ref={ref}
		/>
	);
};

DottedLinkButton.displayName = 'DottedLinkButton';

export default forwardRef(DottedLinkButton);
