import {ReactElement, ReactNode} from "react";
import {useIsDesktop} from '../../../utils/mediaQueries';
import {IPopupBasicProps} from '../../containers/Popup';
import PopupMenu, {IPopupMenuTriggerFnProps, IPopupMenuTriggerElProps} from '../PopupMenu';
import {IMenuComponentProps, IMenuItemProps} from '../MenuItem';
import DialogMenu, {IDialogMenuProps, IDialogMenuTriggerElProps, IDialogMenuTriggerFnProps} from '../DialogMenu';


interface IMenuLayerProps<V, M extends IMenuComponentProps<V>> {
	className?: string;
	children?: ReactElement<M> | Array<ReactElement<IMenuItemProps>>;
	trigger:
		| ReactElement<IDialogMenuTriggerElProps | IPopupMenuTriggerElProps>
		| ((props: IDialogMenuTriggerFnProps | IPopupMenuTriggerFnProps) => ReactNode);
	dialogProps?: IDialogMenuProps<V, M>['dialogProps'];
	popupArrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
}

const MenuLayer = <V, M extends IMenuComponentProps<V>>(props: IMenuLayerProps<V, M>) => {
	const {className, children, trigger, dialogProps, popupArrowVisible, popupProps} = props;

	const isDesktop = useIsDesktop();

	return isDesktop ? (
		<PopupMenu
			className={className}
			arrowVisible={popupArrowVisible}
			popupProps={popupProps}
			trigger={trigger}
		>
			{children}
		</PopupMenu>
	) : (
		<DialogMenu
			className={className}
			dialogProps={dialogProps}
			trigger={trigger}
		>
			{children}
		</DialogMenu>
	);
};

MenuLayer.displayName = 'MenuLayer';

export default MenuLayer;
