import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';

export type IGetAuthorizedPersonsResponse = INormalizedData<IAuthorizedPerson>;

/**
 * Возвращает уполномоченные лицо
 */
export const requestAuthorizedPersons = async () => {
	const response = await httpRequests.withToken.get<IGetAuthorizedPersonsResponse>('authorized-persons');
	return response.data;
};