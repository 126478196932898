import {useMemo} from 'react';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

/**
 * Хук для хлебных крошек от "всех объектов" до гарантийных обращений
 *
 * @param objectId id конечного объекта
 */
export function useWarrantyClaimsBreadcrumbs(objectId: string) {
	const {t} = useTranslation();
	const objects = useObjectsData();
	return useMemo(() => {
		if (!objects) return undefined;
		const items = formObjectsChain(objects.byId, objectId, 'problems', t);
		items.push({
			label: t('warrantyClaimsPage.title'),
			url: `/objects/${objectId}/warranty-claims`,
			inactive: false
		});
		return items;
	}, [objectId, objects]);
}
