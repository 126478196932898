import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {IGetWorkAcceptanceTypesResponse} from '@src/api/backend/workAcceptanceTypes';
import {
	extractWorkAcceptanceTypesAllIds,
	extractWorkAcceptanceTypesAsArray,
	extractWorkAcceptanceTypesById
} from './selectors';

export const useWorkAcceptanceTypes = <T = IGetWorkAcceptanceTypesResponse>(
	select?: (data: IGetWorkAcceptanceTypesResponse) => T
) => useQuery<IGetWorkAcceptanceTypesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.workAcceptanceTypes(),
		meta: {
			error: 'при загрузке типов приёмок работ'
		},
		select
	});

export const useExtractWorkAcceptanceTypesAsArray = () =>
	useWorkAcceptanceTypes(extractWorkAcceptanceTypesAsArray);
export const useExtractWorkAcceptanceTypesById = () =>
	useWorkAcceptanceTypes(extractWorkAcceptanceTypesById);
export const useExtractWorkAcceptanceTypesAllIds = () =>
	useWorkAcceptanceTypes(extractWorkAcceptanceTypesAllIds);
