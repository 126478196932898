import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';

const formatDate = (date: Date, customFormat?: string) =>
	format(date, customFormat || dateFormat);

type DateType = Date | string | number;

export const makeDateRangeLabel = (
	label?: string,
	from?: DateType,
	to?: DateType,
	customFormat?: string
) => {
	if (from !== undefined && to !== undefined) {
		if (!label) {
			return `${formatDate(new Date(from), customFormat)} - ${formatDate(new Date(to), customFormat)}`;
		}
		return `${label}: ${formatDate(new Date(from), customFormat)} - ${formatDate(new Date(to), customFormat)}`;
	}
	return label;
};