import useAppSelector from '@src/core/hooks/useAppSelector';
import { useCallback, useState } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckItemLink} from '@tehzor/tools/utils/links';
import {FloatingActionButton, LoadingPanel, MobilePagination, Plate} from '@tehzor/ui-components';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import MobileRightButtons from '../../../actions/RightButtons.mobile';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {
	useCheckItemsIsLoading,
	useExtractCheckItemById,
	useGetSiblings
} from '@src/core/hooks/queries/checkItems/hooks';
import {useAddingCheckListProblemDialog} from '../../../../hooks/useAddingCheckListProblemDialog';
import {useAddingCheckListTaskDialog} from '../../../../hooks/useAddingCheckListTaskDialog';
import {useEntityAddingPermission} from '../../../../hooks/useEntityAddingPermission';
import {CheckDetailTabs, ITabItem} from '../../Tabs/CheckDetailTabs';
import {CheckListItems} from '../../../list';
import {ProblemsTable} from '../../ProblemsTable';
import {TasksTable} from '../../TasksTable';
import TitleButtons from '../../../TitleButtons.mobile';
import TitleObjectButtons from '../../../TitleObjectButtons.mobile';
import {CheckListItem} from '../../../list/CheckListItems/components/CheckListItem';
import {MobileLinks} from '../../../links/Links.mobile';
import './ItemDetail.mobile.less';
import {InspectionsTable} from '../../InspectionsTable';
import {useAddingCheckListInspectionDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListInspectionDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {useTranslation} from "react-i18next";

const iconPlus = <i className="tz-plus-16" />;

const tabsClassNames = {
	links: 'check-item-detail__tabs-links',
	link: 'check-item-detail__tabs-link'
};

export const MobileItemDetail = ({type}: {type: CheckListTypeId}) => {
	const {t} = useTranslation();
	const {replacePath} = useChangePath();
	const params = useStrictParams<{
		listId: string;
		itemId: string;
		objectId: string;
		stage: ObjectStageIds;
		spaceId?: string;
	}>();
	const {spaceId, objectId, listId, stage, itemId} = params;
	const hideInspectionAdding =
		stage === ObjectStageIds.WARRANTY || stage === ObjectStageIds.TRANSFER;
	const tabs = {
		subcategories: 0,
		problems: 1,
		inspections: hideInspectionAdding ? undefined : 2,
		tasks: hideInspectionAdding ? 2 : 3
	};
	const [activeTab, setActiveTab] = useState(tabs.subcategories);
	const {data: item} = useExtractCheckItemById(itemId);
	const {data: siblings} = useGetSiblings(item);
	const itemsLoading = useCheckItemsIsLoading();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [problemDialog, handleOpenProblemDialog] = useAddingCheckListProblemDialog(itemId);
	const [inspectionDialog, handleOpenInspectionDialog] =
		useAddingCheckListInspectionDialog(itemId);
	const [taskDialog, handleOpenTaskDialog] = useAddingCheckListTaskDialog();
	const handlePageChange = useCallback(
		(nextIndex: number) => {
			if (siblings) {
				const next = siblings[nextIndex];
				const checkItemLink = formCheckItemLink({...params, itemId: next?.id});

				if (!checkItemLink) {
					return;
				}
				replacePath(checkItemLink);
			}
		},
		[siblings, params, replacePath]
	);
	const roles = useAppSelector(extractUserRoles);

	const canAddEntity = useEntityAddingPermission(objectId, listId);
	const permissions = {
		canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
		canAddProblem: canAddEntity && hasPermission(roles, 'problems-add'),
		canAddInspection: canAddEntity && hasPermission(roles, 'inspections-add')
	};

	const tabsContent: ITabItem[] = [
		{
			value: t('checkListPage.detail.mobileItemDetail.tabsContent.subcategory.value'),
			content: (
				<CheckListItems
					mode="detail"
					withoutHeader
				/>
			)
		},
		{
			value: t('checkListPage.detail.mobileItemDetail.tabsContent.problems.value'),
			content: (
				<ProblemsTable
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
					item={item}
				/>
			)
		}
	];
	if (!hideInspectionAdding) {
		tabsContent.push({
			value: t('checkListPage.detail.mobileItemDetail.tabsContent.inspections.value'),
			content: (
				<InspectionsTable
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
					item={item}
				/>
			)
		});
	}
	tabsContent.push({
		value: t('checkListPage.detail.mobileItemDetail.tabsContent.tasks.value'),
		content: (
			<TasksTable
				selectedRows={selectedRows}
				onSelectedRowsChange={setSelectedRows}
				item={item}
			/>
		)
	});

	const handleTabChange = (tabIndex: number) => {
		setActiveTab(tabIndex);
		setSelectedRows([]);
	};

	useAppHeader(
		{
			title: item?.name ?? '',
			showBackBtn: true,
			titleButtons: spaceId ? (
				<TitleButtons
					objectId={objectId}
					spaceId={spaceId}
				/>
			) : (
				<TitleObjectButtons objectId={objectId} />
			),
			mobileRightButtons: spaceId && (
				<MobileRightButtons
					objectId={objectId}
					spaceId={spaceId}
					selectedRows={selectedRows}
					listId={listId}
				/>
			)
		},
		[item?.name, selectedRows, objectId, spaceId]
	);

	const currentIndex = siblings?.findIndex(sibling => sibling.id === item?.id) ?? 0;

	const showDetailTaskAction = permissions.canAddTask && activeTab === tabs.tasks;
	const showDetailProblemAction = permissions.canAddProblem && activeTab === tabs.problems;
	const showDetailInspectionAction =
		permissions.canAddInspection && activeTab === tabs.inspections && !hideInspectionAdding;

	if (!item) {
		return null;
	}

	return (
		<LoadingPanel active={itemsLoading}>
			<div className="check-item-mobile-detail">
				<Plate withoutPadding>
					<MobilePagination
						className="spaces-page__m-pagination"
						page={currentIndex}
						pageCount={siblings?.length ?? 0}
						renderTitle={({page}) => `${page + 1} ${t('checkListPage.detail.mobileItemDetail.mobilePagination.renderTitle')}`}
						onPageChange={handlePageChange}
					/>
					<div className="check-item-mobile-detail__info">
						<CheckListItem
							type={type}
							mode="detail"
							item={item}
							stage={stage}
							canAddEntity={canAddEntity}
						/>
						<MobileLinks
							objectId={objectId}
							spaceId={spaceId}
						/>
					</div>
				</Plate>
				<Plate withoutPadding>
					<CheckDetailTabs
						tabs={tabsContent}
						tabsClassNames={tabsClassNames}
						onTabClick={handleTabChange}
						activeTab={activeTab}
					>
						{showDetailTaskAction && (
							<FloatingActionButton
								icon={iconPlus}
								label={t('checkListPage.detail.mobileItemDetail.floatingActionButton.task.label')}
								minifyOnScroll
								scrollContainer="scroll-container"
								onClick={handleOpenTaskDialog}
							/>
						)}

						{showDetailProblemAction && (
							<FloatingActionButton
								icon={iconPlus}
								label={t('checkListPage.detail.mobileItemDetail.floatingActionButton.problem.label')}
								minifyOnScroll
								scrollContainer="scroll-container"
								onClick={handleOpenProblemDialog}
							/>
						)}
						{showDetailInspectionAction && (
							<FloatingActionButton
								icon={iconPlus}
								label={t('checkListPage.detail.mobileItemDetail.floatingActionButton.inspection.label')}
								minifyOnScroll
								scrollContainer="scroll-container"
								onClick={handleOpenInspectionDialog}
							/>
						)}
					</CheckDetailTabs>
				</Plate>
				{permissions.canAddProblem && problemDialog}
				{permissions.canAddInspection && inspectionDialog && !hideInspectionAdding}
				{permissions.canAddTask && taskDialog}
			</div>
		</LoadingPanel>
	);
};
