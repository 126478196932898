import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {IMeterTariff} from '@tehzor/tools/interfaces/meterConsumptions/IMeterTariff';
import {
	useExtractMeterTariffsAsArray,
	useExtractMeterTariffsById
} from '@src/core/hooks/queries/meterTariffs/hooks';
import {useTranslatedDictionaryArray} from "@src/core/hooks/translations/useTranslatedDictionaryArray";
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";
import {useTranslatedDictionaryMap} from "@src/core/hooks/translations/useTranslatedDictionaryMap";
import {useTranslation} from "react-i18next";

const arrowIcon = <i className="tz-simple-arrow-20" />;

interface IMeterTariffSelectProps<S, E> {
	field?: keyof S;
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (value: string | undefined, tariffsMap: Record<string, IMeterTariff>) => {
	if (!value) {
		return undefined;
	}

	if (tariffsMap[value]) {
		return tariffsMap[value].name;
	}

	return undefined;
};

export const MeterTariffSelect = <
	S extends {tariffId?: string; meterTariff?: string; meterTariffs?: string[]},
	E
>({
	className,
	style,
	field = 'tariffId',
	label,
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: IMeterTariffSelectProps<S, E>) => {
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.meterTariffSelect.label')
	const {data: tariffs} = useExtractMeterTariffsAsArray();
	const translatedTariffs = useTranslatedDictionaryArray(dictionaryKeys.meterTariffs, tariffs);
	const {data: tariffsMap} = useExtractMeterTariffsById();
	const translatedTariffsMap = useTranslatedDictionaryMap(dictionaryKeys.meterTariffs, tariffsMap);
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required, editingDispatch, field]
	);

	const handleClear = useCallback(() => {
		editingDispatch({type: 'update', field, value: null});
		if (required) {
			editingDispatch({type: 'update-error', field});
		}
	}, [required, editingDispatch, field]);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>
			{translatedTariffs && translatedTariffsMap && (
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={getInputLabel(value, translatedTariffsMap)}
							icon={arrowIcon}
							error={required && hasError ? t('components.editableFields.meterTariffSelect.error') : undefined}
							disabled={disabled}
							cleanable
							onClearClick={handleClear}
						/>
					}
				>
					<Select2
						value={value}
						onChange={handleChange}
					>
						{translatedTariffs.map(tariff => (
							<SelectOption
								key={tariff.id}
								itemKey={tariff.id}
								content={tariff.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			)}
		</div>
	);
};
