import {IGetWorkAcceptancesResponse} from '@src/api/backend/workAcceptances';
import {extractWorkAcceptancesPageSettings} from '@src/store/modules/settings/pages/workAcceptances/selectors';
import {useIsFetching, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {extractWorkAcceptanceById, extractWorkAcceptancesAsArray} from '../selectors';
import {useCallback} from 'react';
import IError from '@tehzor/tools/interfaces/IError';
import useAppSelector from '@src/core/hooks/useAppSelector';

export interface IUseWorkAcceptancesParams<T> {
	objectId?: string;
	argsFilters?: Record<string, unknown>;
	select?: (data: IGetWorkAcceptancesResponse) => T;
}

export const useWorkAcceptances = <TResult = IGetWorkAcceptancesResponse>({
	objectId = 'all',
	argsFilters,
	select,
	options
}: IUseWorkAcceptancesParams<TResult> & {
	options?: Partial<UseQueryOptions<IGetWorkAcceptancesResponse, IError, TResult>>;
}) => {
	const {filters, sort, offset, pageSize} = useAppSelector(s =>
		extractWorkAcceptancesPageSettings(s, objectId)
	);

	return useQuery<IGetWorkAcceptancesResponse, IError, TResult>({
		queryKey: workAcceptancesQueryKeys.paginateList(
			objectId,
			argsFilters || filters,
			sort,
			offset,
			pageSize
		),
		meta: {
			error: 'при загрузке списка приёмок'
		},
		select,
		...options
	});
};

export const useWorkAcceptancesIsLoading = () =>
	!!useIsFetching({queryKey: workAcceptancesQueryKeys.lists()});

export const useWorkAcceptancesAsArray = (objectId?: string) =>
	useWorkAcceptances({
		objectId,
		select: extractWorkAcceptancesAsArray
	});

export const useWorkAcceptanceById = (objectId?: string, workAcceptanceId?: string) => {
	const select = useCallback(
		(data: IGetWorkAcceptancesResponse) => extractWorkAcceptanceById(data, workAcceptanceId),
		[workAcceptanceId]
	);

	return useWorkAcceptances({objectId, select});
};
