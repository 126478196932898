import { FunctionComponent } from 'react';
import * as React from 'react';
import chroma from 'chroma-js';

export type IconType =
	| 'space-status'
	| 'problem-status'
	| 'work-acceptance-status'
	| 'indicator'
	| 'check-list-status'
	| 'check-item-status';

const getColorStyle = (type?: IconType, color?: string) => {
	if (!type || !color) {
		return {};
	}
	if (type === 'problem-status' || type === 'work-acceptance-status') {
		return {borderColor: color};
	}
	if (type === 'check-list-status') {
		const colorForBorder = chroma(color).set('hsl.s', '-0.02').set('hsl.l', '-0.35').css();
		return {borderColor: colorForBorder};
	}
	return {background: color};
	// return type === 'problem-status'|| type === 'check-list-status' ? {borderColor: color} : {background: color};
};

interface ILegendItemProps {
	title?: string;
	type?: IconType;
	color?: string;
	Icon?: React.ReactNode;
	onlyBorder?: boolean;
	children?: React.ReactNode;
}

export const LegendItem: FunctionComponent<ILegendItemProps> = ({
	children,
	title,
	type,
	color,
	Icon,
	onlyBorder
}) => (
	<div className="spaces-page__legend-item">
		{children || (
			<>
				<div className="spaces-page__legend-item-icon">
					{Icon || (
						<div
							className={`spaces-page__legend-${type}`}
							style={
								onlyBorder
									? {border: '1px solid #D1D4D9', background: '#FFF'}
									: getColorStyle(type, color)
							}
						/>
					)}
				</div>
				<div className="spaces-page__legend-item-title">{title}</div>
			</>
		)}
	</div>
);
