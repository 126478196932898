import {combineReducers} from 'redux';
import tabIndex from './tabIndex';
import filters from './filters';
import filtersDialogTabIndex from './filtersDialogTabIndex';
import grouping from './grouping';
import problems from '../problems/reducers';
import categories from '../categories/reducers';

export default combineReducers({
	tabIndex,
	filters,
	filtersDialogTabIndex,
	grouping,
	problems,
	categories
});
