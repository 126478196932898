import IFile from '@tehzor/tools/interfaces/IFile';
import {useMemo} from 'react';

export const useFileImageObject = (file?: IFile) =>
	useMemo(() => (file ? [
		{
			id: file.id,
			url: file.url,
			canvas: undefined,
			original: file.url
		}
	] : []), [file]);