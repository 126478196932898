import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт гарантийного обращения
 */
export const makeWarrantyClaimExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	claimId: string,
	problems?: string[]
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId,
		problems
	};
	const response = await httpRequests.exportWithToken.get<IExportResponse>(
		`warranty-claims/${claimId}/export`,
		{
			params
		}
	);
	return response.data;
};
