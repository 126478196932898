import {makeTempFilesAddRequest} from '@src/api/backend/tempFile';
import {ISavingNewAttachment} from '@src/interfaces/attachments/ISavingAttachment';
import {TempFileDestination} from '@tehzor/tools/enums/TempFileDestination';
import ITempFile from '@tehzor/tools/interfaces/ITempFile';

export const addTempFiles = async (newAttachments?: ISavingNewAttachment[]) => {
	if (!newAttachments || !newAttachments?.length) {
		return undefined;
	}
	const filesArr = newAttachments
		? (newAttachments
				.filter(att => !att.id)
				.map(item => ({
					file: item.file,
					originalFile: item.originalFile,
					drawData: item.drawData
				}))
				.filter(file => file !== undefined) as Array<{
				file: File;
				originalFile?: File;
				drawData?: string;
		  }>)
		: undefined;
	const tempFiles: ITempFile[] = [];

	if (filesArr) {
		for (const obj of filesArr) {
			const tempFile = await makeTempFilesAddRequest(
				obj.file,
				TempFileDestination.ATTACHMENT,
				undefined,
				undefined,
				obj.originalFile,
				JSON.stringify(obj.drawData)
			);
			tempFiles.push(tempFile);
		}
	}
	return [...tempFiles, ...newAttachments.filter(att => !!att.id)];
};
