import StatusesFilterPage from '../StatusesFilterPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractSpaceTypesAsArray} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

const SpaceTypesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const {data: types} = useExtractSpaceTypesAsArray();

	const translatedOptions = useTranslatedDictionaryArray(dictionaryKeys.spaceTypes, types);
	if (!translatedOptions) return null;

	return (
		<div>
			<StatusesFilterPage
				objectId={objectId}
				entity={entity}
				statuses={translatedOptions}
				label={t('spacesFiltersPage.spaceTypesFilterMobile.baseListFilterMobile.title')}
				filterName="types"
			/>
		</div>
	);
};

export default SpaceTypesFilterPage;
