import {useQuery} from '@tanstack/react-query';
import {IChangedRecord} from '@src/api/cache/checkRecords/defaults/useCheckRecordsMutationDefaults';
import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback, useMemo} from 'react';
import {exportLocalCheckRecordsChangesJournal} from './selector';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';

export const useLocalCheckRecordsChangesJournal = () => {
	const errorsListMap = useMutationErrors({mutationKey: checkRecordsQueryKeys.changeList()});
	const errorsItemMap = useMutationErrors({mutationKey: checkRecordsQueryKeys.changeItem()});
	const errorsMap = useMemo(
		() => ({...errorsListMap, ...errorsItemMap}),
		[errorsItemMap, errorsListMap]
	);
	const selector = useCallback(
		(records: IChangedRecord[]) => exportLocalCheckRecordsChangesJournal(records, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IChangedRecord[], unknown, IOfflineJournalEntity[]>({
		queryKey: checkRecordsQueryKeys.localList(),
		select: selector
	});
	return data || [];
};
