import {memo, useCallback, useMemo} from 'react';
import {EntityInnerTable} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {convertWorkAcceptances} from '@src/pages/WorkAcceptancesPage/utils/convertWorkAcceptances';
import {useCompanies} from '@src/core/hooks/queries/companies/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useExtractWorkAcceptanceTypesById} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';
import {useWorkAcceptancesForSpaceAsArray} from '@src/core/hooks/queries/workAcceptances';

interface IDesktopTableProps {
	objectId: string;
	columns: Array<Column<IPreparedWorkAcceptance>>;
	hideHead?: boolean;
	spaceId: string;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

export const Table = memo(
	({
		objectId,
		columns,
		hideHead,
		spaceId,
		selectedRows,
		onSelectedRowsChange
	}: IDesktopTableProps) => {
		const {pushPath} = useChangePath();

		const {data: workAcceptances} = useWorkAcceptancesForSpaceAsArray(objectId, spaceId);

		const {data: companies} = useCompanies();
		const {data: usersMap} = useUsersAsMap();
		const {data: groupsMap} = useWorkingGroupsAsMap();
		const {data: typesMap} = useExtractWorkAcceptanceTypesById();
		const translatedTypesMap = useTranslatedDictionaryMap(
			dictionaryKeys.workAcceptanceTypes,
			typesMap
		);
		const user = useAppSelector(s => s.auth.profile);
		const networkStatus = useAppSelector(extractNetworkStatus);

		const preparedWorkAcceptances = useMemo(() => {
			if (!workAcceptances || !usersMap || !groupsMap) return [];
			return convertWorkAcceptances(
				workAcceptances,
				translatedTypesMap,
				undefined,
				networkStatus,
				usersMap,
				groupsMap,
				companies?.byId,
				undefined,
				undefined,
				user
			);
		}, [
			workAcceptances,
			networkStatus,
			usersMap,
			groupsMap,
			companies,
			translatedTypesMap,
			user
		]);

		const handleRowClick = useCallback(
			(workAcceptance: IPreparedWorkAcceptance) =>
				pushPath(formWorkAcceptanceLink(objectId, workAcceptance.id)),
			[objectId]
		);

		return (
			<EntityInnerTable
				columns={columns}
				data={preparedWorkAcceptances}
				hideHead={hideHead}
				selectable
				onRowClick={handleRowClick}
				selectedRows={selectedRows}
				onSelectedRowsChange={onSelectedRowsChange}
			/>
		);
	}
);
