import {IGetListFieldSettingsResponse} from '@src/api/backend/fieldSettings';

export const extractFieldSettingsListMap = (data: IGetListFieldSettingsResponse) => data.byId;

export const extractFieldSettingsListArray = (data: IGetListFieldSettingsResponse) =>
	data.allIds?.map(id => data.byId[id]);

export const extractFieldSettingsListBySetIds = (
	data: IGetListFieldSettingsResponse,
	setIds?: string[]
) => {
	if (!setIds?.length) {
		return [];
	}

	const array = extractFieldSettingsListArray(data);
	return array?.filter(setting => setIds.includes(setting.fieldsSettingsSetId));
};

export const extractFieldSettingsListById = (data: IGetListFieldSettingsResponse, id: string) =>
	data.byId[id];
