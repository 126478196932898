import {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useSpaceIndicatorsAsArray} from '@src/core/hooks/queries/spaceIndicatorsSets/hooks';
import {useTranslation} from 'react-i18next';

interface ISpacesIndicatorsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpacesIndicatorsFilterMobile = memo((props: ISpacesIndicatorsFilterMobileProps) => {
	const {t} = useTranslation();

	const {data: object} = useObject(props.objectId);
	const {data: indicators} = useSpaceIndicatorsAsArray(object);

	if (!indicators) {
		return null;
	}
	return (
		<BaseListFilterMobile
			{...props}
			options={indicators}
			label={t('spacesFiltersPage.spacesIndicatorsFilterMobile.baseListFilterMobile.label')}
			filterName="indicators"
		/>
	);
});
