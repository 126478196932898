import {cloneElement, ReactElement, ReactNode, useCallback} from 'react';
import './SelectPopup.less';
import Popup, {IPopupBasicProps} from '../../../containers/Popup';
import ShadowBox from '../../../Shadowbox';
import classNames from 'classnames';
import {RefCallback} from 'react-laag/dist/types.d';
import useToggle from 'react-use/lib/useToggle';
import {ISelectProps} from '../Select';
import {ITreeSelectProps} from '../TreeSelect';
import {ActionButtons, LinkButton} from '../../../buttons';
import Button from '../../../buttons/Button';
import {ITreeDataItem} from '../../../Tree';
import SelectOption from '../SelectOption';

export interface IPopupSelectTriggerElProps {
	isOpen?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	ref?: RefCallback;
}

export interface IPopupSelectTriggerFnProps {
	isOpen?: boolean;
	disabled?: boolean;
	open?: () => void;
	close?: () => void;
	toggle?: () => void;
	ref?: RefCallback;
}

interface ItranslationsProps {
	selectApplyBtnLabel: string;
	selectClearBtnLabel: string;
	selectClearAllBtnLabel: string;
	selectCancelBtnLabel: string;
	selectSelectAllBtnLabel: string;
	selectSelectedCountLabel: string;
}

export interface ISelectPopupProps<T extends ITreeDataItem = ITreeDataItem> {
	className?: string;
	trigger:
		| ReactElement<IPopupSelectTriggerElProps>
		| ((props: IPopupSelectTriggerFnProps) => ReactNode);
	children?: ReactElement<ISelectProps> | ReactElement<ITreeSelectProps<T>>;
	popupProps?: IPopupBasicProps;
	clearButton?: boolean;
	footer?: boolean;
	noHeader?: boolean;
	count?: number;
	onClear?: () => void;
	onSelectAll?: () => void;
	onCancel?: () => void;
	onApply?: () => void;
	search?: ReactNode;
	tabs?: ReactNode;
	translationsProps?: ItranslationsProps;
	dataTestId?: string;
	noOptions?: boolean;
	noOptionsOnChange?: () => void;
	noOptionsChecked?: boolean;
	noOptionsContent?: ReactNode;
}

const SelectPopup = (props: ISelectPopupProps) => {
	const {
		className,
		trigger,
		children,
		popupProps,
		onClear,
		onCancel,
		onApply,
		onSelectAll,
		clearButton,
		footer,
		noHeader,
		count,
		search,
		tabs,
		translationsProps = {
			selectClearAllBtnLabel: 'Сбросить все',
			selectApplyBtnLabel: 'Применить',
			selectCancelBtnLabel: 'Отменить',
			selectClearBtnLabel: 'Сбросить',
			selectSelectAllBtnLabel: 'Выбрать все',
			selectSelectedCountLabel: 'Выбрано'
		},
		dataTestId,
		noOptions,
		noOptionsOnChange,
		noOptionsChecked,
		noOptionsContent
	} = props;
	const {
		selectSelectedCountLabel,
		selectSelectAllBtnLabel,
		selectClearBtnLabel,
		selectClearAllBtnLabel,
		selectCancelBtnLabel,
		selectApplyBtnLabel
	} = translationsProps;

	const [isOpen, toggleOpen] = useToggle(false);

	const open = useCallback(() => {
		toggleOpen(true);
	}, [toggleOpen]);

	const close = useCallback(() => {
		toggleOpen(false);
	}, [toggleOpen]);

	const onClose = () => {
		close();
		if (onCancel) {
			onCancel();
		}
	};

	const apply = () => {
		close();
		if (onApply) {
			onApply();
		}
	};

	return children ? (
		<Popup
			{...popupProps}
			className={classNames('select-popup', className)}
			trigger={triggerProps =>
				typeof trigger === 'function'
					? trigger({
							...triggerProps,
							isOpen,
							open,
							close,
							toggle: toggleOpen
					  })
					: cloneElement(trigger, {
							isOpen,
							onClick: toggleOpen,
							ref: triggerProps.ref
					  })
			}
			isOpen={isOpen}
			onOutsideClick={onClose}
			onDisappear={onClose}
			dataTestId={dataTestId}
		>
			{!noHeader ? (
				<div className="select-popup__header">
					{tabs && <div className="select-popup__header-tabs">{tabs}</div>}
					{search && <div className="select-popup__header-search">{search}</div>}
					{(onSelectAll || (clearButton && !footer)) && (
						<div className="select-popup__header-actions">
							{onSelectAll ? (
								<LinkButton label={selectSelectAllBtnLabel} onClick={onSelectAll} />
							) : null}
							{clearButton && !footer ? (
								<LinkButton label={selectClearBtnLabel} onClick={onClear} />
							) : null}
						</div>
					)}
				</div>
			) : null}
			<ShadowBox className="select-popup__scrollable">
				{noOptions ? (
					<SelectOption
						onChange={noOptionsOnChange}
						checked={noOptionsChecked}
						content={noOptionsContent}
						className={{root: 'select-popup__no-option-select'}}
					/>
				) : null}
				{children}
			</ShadowBox>
			{footer ? (
				<div className="select-popup__footer">
					{clearButton ? (
						<div className="select-popup__info">
							<div className="d-regular-12">
								{`${selectSelectedCountLabel} ${count || 0}`}
							</div>
							<div>
								<LinkButton
									label={selectClearAllBtnLabel}
									className="select-popup__clear"
									onClick={onClear}
									rightIcon={
										<i
											className={classNames(
												'tz-close-16',
												'select-popup__close'
											)}
										/>
									}
								/>
							</div>
						</div>
					) : null}

					<ActionButtons className="select-popup__actions">
						{onCancel ? (
							<Button type="cancel" label={selectCancelBtnLabel} onClick={onClose} />
						) : null}
						{onApply ? (
							<Button
								type="accent-blue"
								label={selectApplyBtnLabel}
								onClick={apply}
							/>
						) : null}
					</ActionButtons>
				</div>
			) : null}
		</Popup>
	) : (
		<>
			{typeof trigger === 'function'
				? trigger({disabled: true})
				: cloneElement(trigger, {disabled: true})}
		</>
	);
};

SelectPopup.displayName = 'SelectPopup';

export default SelectPopup;
