import {requestGetSpaceStatuses} from '@src/api/backend/spaceStatuses';
import {useQueryClient} from '@tanstack/react-query';
import {spaceStatusesQueryKeys} from '../keys';

export const useSpaceStatusesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(spaceStatusesQueryKeys.list(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetSpaceStatuses,
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24 * 7
	});
};
