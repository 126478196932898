import ICanvasData, {ICanvasLine} from '@tehzor/tools/interfaces/ICanvasData';
import {useCallback, useEffect, useRef} from 'react';
import {ICanvasRefObject} from './Canvas';
import {getTouchPosition} from './utils/getTouchPosition';

export const useCanvasDrawing = (
	canvas: HTMLCanvasElement | null,
	canvasWidth: number,
	canvasHeight: number,
	brushColor = 'red',
	brushRadius: number,
	imageUrl: string,
	savedDrawData = {} as ICanvasData,
	onChange?: (data: ICanvasRefObject | undefined) => void
) => {
	const isDrawing = useRef(false);
	const drawData = useRef<ICanvasData>({} as ICanvasData);
	const currentLine = useRef<ICanvasLine | null>(null);
	const initializedRef = useRef(false);
	const canvasRefObject = useRef<ICanvasRefObject>();
	const initialize = useCallback(() => {
		const {lines = [], width, height} = savedDrawData;
		const scaleX = canvasWidth / width;
		const scaleY = canvasHeight / height;
		const scaledLines = lines.map(line => {
			const scaledLine = {
				...line,
				points: line.points.map(({x, y}) => ({x: x * scaleX, y: y * scaleY})),
				brushRadius: line.brushRadius * scaleX
			};
			return scaledLine;
		});
		drawData.current.lines = scaledLines;
		drawData.current.width = width;
		drawData.current.height = height;
		initializedRef.current = true;
	}, [canvasWidth, canvasHeight, savedDrawData]);

	// Рисуем линию и складываем её в массив линий
	const startDrawing = useCallback(
		(e: MouseEvent) => {
			isDrawing.current = true;
			const {offsetX: x, offsetY: y} = e;
			currentLine.current = {
				points: [{x, y}],
				brushColor,
				brushRadius
			};
			if (!drawData.current.lines) {
				drawData.current.lines = [];
			}
			drawData.current.lines.push(currentLine.current);
		},
		[brushColor]
	);
	const touchStartDrawing = useCallback(
		(e: TouchEvent) => {
			isDrawing.current = true;
			const {x, y} = getTouchPosition(canvas, e);
			currentLine.current = {
				points: [{x, y}],
				brushColor,
				brushRadius
			};
			if (!drawData.current.lines) {
				drawData.current.lines = [];
			}
			drawData.current.lines.push(currentLine.current);
		},
		[brushColor, canvas]
	);

	const drawLines = useCallback(
		(canvas: HTMLCanvasElement | null) => {
			const context = canvas?.getContext('2d');
			if (!canvas || !context) return;
			context.clearRect(0, 0, canvasWidth, canvasHeight);
			drawData.current.lines?.forEach(({points, brushColor}) => {
				context.strokeStyle = brushColor;
				context.lineJoin = 'round';
				context.lineCap = 'round';
				context.beginPath();
				context.moveTo(points[0].x, points[0].y);
				for (let i = 1; i < points.length; i++) {
					context.lineTo(points[i].x, points[i].y);
				}
				context.stroke();
			});
		},
		[canvasWidth, canvasHeight]
	);

	const draw = useCallback(
		(e: MouseEvent) => {
			if (!isDrawing.current) return;

			const {offsetX: x, offsetY: y} = e;

			if (currentLine.current) {
				currentLine.current.points.push({x, y});
			}
			drawLines(canvas);
		},
		[canvas]
	);

	const touchDraw = useCallback(
		(e: TouchEvent) => {
			if (!isDrawing.current) return;

			const {x, y} = getTouchPosition(canvas, e);

			if (currentLine.current) {
				currentLine.current.points.push({x, y});
			}
			drawLines(canvas);
		},
		[canvas]
	);

	// Перестаем рисовать, сообщаем об изменениях
	const stopDrawing = useCallback(() => {
		isDrawing.current = false;
		currentLine.current = null;
		if (onChange && initializedRef.current) {
			onChange(canvasRefObject.current);
		}
	}, []);

	// хандлер очистки канвы
	const handleClear = useCallback(() => {
		drawData.current.lines = [];
		drawLines(canvas);
		if (onChange && canvasRefObject.current) {
			onChange({...canvasRefObject.current, drawed: {} as ICanvasData});
		}
	}, [onChange, canvas]);

	// Подгружаем текущее изображение с сервера, чтобы получить его blob и заняться склейкой с нарисованным
	const loadImage = useCallback(
		async (url: string) =>
			new Promise<HTMLImageElement>((resolve, reject) => {
				const image = new Image();
				image.onload = () => resolve(image);
				image.onerror = err => reject(err);
				image.crossOrigin = 'anonymous';
				image.src = `${url}`;
			}),
		[]
	);

	// Получаем blob из нарисованного
	const getBlobFromCanvas = useCallback(
		async (canvas: HTMLCanvasElement): Promise<Blob> =>
			new Promise(resolve => {
				canvas.toBlob(blob => {
					if (blob) {
						resolve(blob);
					}
				}, 'image/jpeg');
			}),
		[]
	);

	// По настоящему мы кладем картинку на canvas только сейчас,
	// В связи с этим полностью перерисовываем поле, получаем blob оригинала и склееного изображения
	const handleGetBlobs = useCallback(
		async (canvas?: HTMLCanvasElement | null) => {
			const context = canvas?.getContext('2d');
			drawData.current.width = canvasWidth;
			drawData.current.height = canvasHeight;
			if (context && canvas) {
				const image = await loadImage(imageUrl);
				const scaleWFull = image.naturalWidth / canvas.width;
				const scaleHFull = image.naturalHeight / canvas.height;
				canvas.width = image.naturalWidth;
				canvas.height = image.naturalHeight;
				context.drawImage(image, 0, 0);
				const originalBlob = await getBlobFromCanvas(canvas);
				drawData.current.lines.forEach(({points, brushColor}) => {
					context.strokeStyle = brushColor;
					context.lineWidth = brushRadius * scaleWFull * 1.3;
					context.lineJoin = 'round';
					context.lineCap = 'round';
					context.beginPath();
					context.moveTo(points[0].x * scaleWFull, points[0].y * scaleHFull);
					for (let i = 1; i < points.length; i++) {
						context.lineTo(points[i].x * scaleWFull, points[i].y * scaleHFull);
					}
					context.stroke();
				});
				const editedBlob = await getBlobFromCanvas(canvas);
				return {editedBlob, originalBlob};
			}
			return undefined;
		},
		[imageUrl, canvas]
	);

	useEffect(() => {
		const context = canvas?.getContext('2d');
		if (!canvas || !context) return;
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;

		context.lineWidth = brushRadius;
		context.lineCap = 'round';

		context.imageSmoothingEnabled = true;
		context.imageSmoothingQuality = 'medium';

		if (!initializedRef.current && canvasWidth != 0) {
			initialize();
		}

		canvas.addEventListener('mousedown', startDrawing);
		canvas.addEventListener('mousemove', draw);
		canvas.addEventListener('mouseup', stopDrawing);
		canvas.addEventListener('mouseout', stopDrawing);

		canvas.addEventListener('touchstart', touchStartDrawing);
		canvas.addEventListener('touchmove', touchDraw);
		canvas.addEventListener('touchend', stopDrawing);
		canvas.addEventListener('touchcancel', stopDrawing);

		canvasRefObject.current = {
			canvas,
			clear: handleClear,
			drawed: drawData.current,
			getBlobUrl: handleGetBlobs
		};
		if (onChange) {
			onChange(canvasRefObject.current);
		}
		return () => {
			canvas.removeEventListener('mousedown', startDrawing);
			canvas.removeEventListener('mousemove', draw);
			canvas.removeEventListener('mouseup', stopDrawing);
			canvas.removeEventListener('mouseout', stopDrawing);

			canvas.removeEventListener('touchstart', touchStartDrawing);
			canvas.removeEventListener('touchmove', touchDraw);
			canvas.removeEventListener('touchend', stopDrawing);
			canvas.removeEventListener('touchcancel', stopDrawing);
		};
	}, [canvasWidth, canvasHeight, brushColor, canvas]);

	return {
		canvasRefObject: canvasRefObject.current,
		handleClear,
		drawLines
	};
};
