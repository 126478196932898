import {FixDateDisplayValue} from '@src/components/FixDateDisplayValue/FixDateDisplayValue';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {EntityInfo, UserInfo} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {CellProps} from 'react-table';
import './ProblemFixCell.less';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';

const userIcon = <i className="tz-users-24" />;

export const ProblemFixCell = ({row}: CellProps<{data: IProblem}>) => {
	const {status, plannedFixDate, performers, performersActiveGroup, critical, actualFixDate} =
		row.original.data;
	const {data: usersMap} = useUsersAsMap();
	const {data: groupsMap} = useWorkingGroupsAsMap();
	const isDesktop = useIsDesktop();

	return (
		<>
			{plannedFixDate ? (
				<FixDateDisplayValue
					className="problem-fix-cell-date"
					plannedFixDate={plannedFixDate}
					status={status}
					critical={critical}
					actualFixDate={actualFixDate}
				/>
			) : null}

			{performers?.length && usersMap ? (
				performers.map(userId => (
					<UserInfo
						key={userId}
						className="problem-fix-cell-user"
						user={usersMap[userId]}
						avatarSize={isDesktop ? '40' : '34'}
						avatarColoring="text"
					/>
				))
			) : performersActiveGroup && groupsMap ? (
				<EntityInfo
					className="problem-fix-cell-user"
					title={groupsMap[performersActiveGroup]?.name}
					icon={userIcon}
					iconColor="#718198"
					backgroundColor="#FFDEB8"
				/>
			) : null}
		</>
	);
};
