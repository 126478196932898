import {queryClient} from '@src/api/QueryClient';
import {meterConsumptionsQueryKeys} from '@src/api/cache/meterConsumptions/keys';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {ISavingMeter} from '@tehzor/tools/interfaces/meters/ISavingMeter';

export type IEditMeterResponse = IMeter;

/**
 * Редактирование прибора учёта
 *
 * @param item данные для редактирования
 */
export const makeMeterEditRequest = async (item: ISavingMeter) => {
	const {id, typeId, serialNumber, description, meterConsumptions} = item;
	const response = await httpRequests.withToken.post<IEditMeterResponse>(`/meters/edit/${id}`, {
		typeId,
		serialNumber,
		description,
		meterConsumptions
	});
	if (id) {
		void queryClient.invalidateQueries({queryKey: meterConsumptionsQueryKeys.list(id)});
	}
	return response.data;
};
