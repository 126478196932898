import { useMemo } from 'react';
import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {CellProps} from 'react-table';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';

const getStageColor = (id: ObjectStageIds) => {
	switch (id) {
		case ObjectStageIds.BUILDING: {
			return '#B3ECFF';
		}
		case ObjectStageIds.ACCEPTANCE: {
			return '#FFDEB8';
		}
		case ObjectStageIds.TRANSFER: {
			return '#C1FBE5';
		}
		case ObjectStageIds.WARRANTY: {
			return '#BEC2F9';
		}
		default: {
			return 'hsla(73, 80%, 78%, 1)';
		}
	}
};

const StagesCell = ({row}: CellProps<IFullCategoriesSet>) => {
	const stages = row.original.stages || [];
	const {data: stagesMap} = useObjectStagesMap();
	const data = useMemo(
		() =>
			stages.map(id => {
				const label = (stagesMap && stagesMap[id]?.name) || '';

				return {id, label, color: getStageColor(id)};
			}),
		[stagesMap, stages]
	);
	const text = data.map(i => i.label).join(', ');
	return <div className="categories-sets__cell-stages">{text}</div>;
};

export default StagesCell;
