import useAppSelector from '@src/core/hooks/useAppSelector';
import {useReportsTable} from '@src/pages/SpacesPage/hooks/useReportsTable';
import {Plate} from '@tehzor/ui-components';
import {ReportsTable} from './ReportsTable';
import {buildColumns} from './buildColumns';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {FieldButtons} from './FieldButtons';
import {useExtractSpaceStatusesAsArrayByObjectId} from '@src/core/hooks/queries/spaceStatusesSets/hooks';

interface ITableProps {
	objectId: string;
}

export const Table = ({objectId}: ITableProps) => {
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const {data: spaceStatuses} = useExtractSpaceStatusesAsArrayByObjectId(objectId);
	const spaceStatusesMap: Record<string, ISpaceStatus> = {};
	const spaceStatusesIds: string[] = [];

	const filtersStatuses = pageSettings.filters?.statuses;
	const filtersStage = pageSettings.stage;
	if (spaceStatuses) {
		for (const status of spaceStatuses) {
			const statusId = status.id;

			if (
				(!filtersStatuses?.length && !filtersStage) ||
				(filtersStatuses?.length && filtersStatuses.includes(statusId)) ||
				(filtersStage === status.stage && !filtersStatuses?.length)
			) {
				spaceStatusesMap[statusId] = status;
			}
			spaceStatusesIds.push(statusId);
		}
	}

	const data = useReportsTable(objectId, 'status', spaceStatusesIds, spaceStatusesMap);
	const columns = buildColumns(spaceStatusesIds, spaceStatusesMap);

	return (
		<Plate
			className="spaces-page__reports-table-plate"
			withoutPadding
		>
			<FieldButtons />
			<ReportsTable
				columns={columns}
				data={data}
			/>
		</Plate>
	);
};
