import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {CellProps} from 'react-table';

export const DeadLineCell = ({row}: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const task = row.original;
	const {t} = useTranslation();

	if (!task?.taskIntervalStart || !task?.taskIntervalEnd) {
		return null;
	}

	return (
		<div className="tasks-page__deadline">
			<div className="tasks-page__deadline-grid">
				<div className="tasks-page__deadline-grid-preposition">
					{t('tasksPage.table.deadline.from')}
				</div>
				<div>{format(task.taskIntervalStart, dateTimeCommaSeparatedFormat)}</div>
				<div className="tasks-page__deadline-grid-preposition">
					{t('tasksPage.table.deadline.to')}
				</div>
				<div>{format(task.taskIntervalEnd, dateTimeCommaSeparatedFormat)}</div>
			</div>

			{task.status !== undefined &&
				task.status?.id !== TaskStatusId.ACCEPTED &&
				task.status?.id !== TaskStatusId.REJECTED && (
					<FixDateTag
						className="tasks-page__deadline-tag"
						plannedFixDate={task.taskIntervalEnd}
						size="small"
					/>
				)}
		</div>
	);
};
