import { memo } from 'react';
import * as React from 'react';
import {changeEntitiesVisibility} from '@src/store/modules/settings/pages/space/actions';
import VisibilityFilter from '@src/components/VisibilityFilter';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useTranslation} from 'react-i18next';

interface IEntitiesVisibilityProps {
	className?: string;
	style?: React.CSSProperties;
}

export const EntitiesVisibility = memo(({className, style}: IEntitiesVisibilityProps) => {
	const {t} = useTranslation();

	const visibilityTitles = {
		problems: t('spacePage.visibilityFilter.label.problems'),
		inspections: t('spacePage.visibilityFilter.label.inspections')
	};

	const visibility = useAppSelector(s => s.settings.pages.space.entitiesVisibility);

	return (
		<VisibilityFilter
			className={className}
			style={style}
			value={visibility}
			titlesMap={visibilityTitles}
			onChange={changeEntitiesVisibility}
		/>
	);
});
