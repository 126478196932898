import { useCallback } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useSpaceExport} from '@src/core/hooks/export/useSpaceExport';
// import {useCheckExport} from '@src/core/hooks/export/useCheckExport';

interface ISpaceExportProps {
	objectId: string;
	spaceId: string;
}

const MobileSpaceExport = ({objectId, spaceId}: ISpaceExportProps) => {
	const [exportDialog, openExportDialog] = useSpaceExport(objectId, spaceId);

	const handleExport = useCallback(() => {
		openExportDialog(objectId, spaceId);
	}, [spaceId, openExportDialog]);

	return (
		<>
			<IconButton onClick={handleExport}>
				<i className="tz-export-24"/>
			</IconButton>
			{exportDialog}
		</>
	);
};

export default MobileSpaceExport;
