import * as types from '../../constants/records';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IAddCheckRecordResponse, makeCheckRecordAddRequest} from '@src/api/backend/checkRecord';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingCheckRecord} from '@tehzor/tools/interfaces/checkRecords/ISavingCheckRecord';
import {AppThunkDispatch} from '@src/store/interfaces/thunk';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IAddCheckRecordPayload = IAddCheckRecordResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddCheckRecordResponse) => (dispatch: AppThunkDispatch) => {
	dispatch({
		type: types.ADD_SUCCESS,
		payload: response
	});
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении записи по категории');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет запись по категории
 *
 * @param objectId id объекта
 * @param links связанные сущности
 * @param stage стадия
 * @param fields данные для добавления
 */
export const addRecord = (
	objectId: string,
	links: ICheckRecord['links'],
	stage: ObjectStageIds,
	fields: ISavingCheckRecord
) =>
	createApiAction<IAddCheckRecordResponse>(request, success, failure, () =>
		makeCheckRecordAddRequest(objectId, links, stage, fields));
