import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {SelectionActions} from './SelectionActions';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';

export const SelectionRow = (props: ISelectionRowProps<IPreparedWorkAcceptance>) => {
	const {selectedFlatRows, toggleAllRowsSelected, objectId} = props;

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<span>{selectedFlatRows.length}</span>
			<SelectionActions
				selectedEntities={selectedFlatRows.map(item => item.original)}
				selectedClearing={clearSelection}
				objectId={objectId || 'all'}
			/>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};
