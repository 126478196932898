import { memo } from 'react';
import './FixDateTag.less';
import {Tag} from '@tehzor/ui-components';
import {differenceInDays, formatDistanceStrict, isBefore} from 'date-fns';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IFixDateTagProps {
	className?: string;
	plannedFixDate: number;
	size?: 'small' | 'medium' | 'large';
	actualFixDate?: number;
}

export const FixDateTag = memo(
	({className, plannedFixDate, size, actualFixDate}: IFixDateTagProps) => {
		const nowDate = actualFixDate ?? new Date();
		const {t, i18n} = useTranslation();
		const dateFnsLocale = useDateFnsLocale();

		if (differenceInDays(plannedFixDate, nowDate) > 5) {
			return null;
		}

		const distance = formatDistanceStrict(plannedFixDate, nowDate, {
			addSuffix: false,
			locale: dateFnsLocale
		});
		const isExpired = isBefore(plannedFixDate, nowDate);
		const prefix = isExpired ? t('fixDateTag.overdue') : t('fixDateTag.untilExpiry');

		const shouldSwapPrefix = i18n.language !== 'en';
		const label = shouldSwapPrefix
			? isExpired
				? `${prefix} ${distance}`
				: `${distance} ${prefix}`
			: `${distance} ${prefix}`;

		return (
			<Tag
				className={classNames(
					'fix-date-tag',
					isExpired
						? actualFixDate
							? ''
							: 'fix-date-tag_expired'
						: 'fix-date-tag_warning',
					className
				)}
				label={label}
				icon={
					<i
						className={
							isExpired
								? actualFixDate
									? 'tz-alarm-clock'
									: 'tz-alarm-clock fix-date-tag__icon_expired'
								: 'tz-clock fix-date-tag__icon_warning'
						}
					/>
				}
				color={isExpired ? (actualFixDate ? '#EEEFF3' : '#FFF1F1') : '#FFF5EE'}
				size={size || 'large'}
				multiline
			/>
		);
	}
);
