import './MarkerCommentChange.less';
import classNames from 'classnames';
import {IChangeComponentProps} from '../interfaces/IChangeComponent';

interface IMarkerCommentChangeProps extends IChangeComponentProps {
	value?: {
		name?: string;
		description?: string;
		planName?: string;
	};
}

export const MarkerCommentChange = ({className, style, value}: IMarkerCommentChangeProps) => (
	<div
		className={classNames('history-marker-comment-change', className)}
		style={style}
	>
		<div>
			<span className="history-marker-comment-change__text">Местоположение</span>
			:&nbsp;
			{value?.name ? value?.name : null}
		</div>
		<div>
			<span className="history-marker-comment-change__text">Комментарий</span>
			:&nbsp;
			{value?.description ? `"${value.description}"` : null}
		</div>
	</div>
);

MarkerCommentChange.displayName = 'MarkerCommentChange';
