import {IDictionaryCacheState} from '@src/store/modules/offlineMode/reducers/dictionariesCacheState';
import {OfflineDataCachingStatus} from '@tehzor/tools/contracts/dataCachingWebWorker/interfaces/IOfflineDataCachingStatuses';

export const someInProgress = (states: Array<IDictionaryCacheState | undefined>) =>
	states.some(
		state =>
			state
			&& (state.status === OfflineDataCachingStatus.WAITING
				|| state.status === OfflineDataCachingStatus.LOADING)
	);
