import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {wsConnector} from '../wsConnector';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

export type IGetLastReplyResponse = IComment | false | undefined;

/**
 * Возвращает последний ответ
 *
 * @param objectId id объекта
 * @param entityId id сущности
 * @param commentTypeId id типа комментария
 */
export const requestLastReply = (objectId: string, entityId: string, commentTypeId: ICommentTypeId) =>
	wsConnector.sendAuthorizedRequest<IGetLastReplyResponse>('getLastReply', {
		objectId,
		entityId,
		commentTypeId
	});
