import {CellProps} from 'react-table';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {WorkAcceptanceFrontItem} from '@src/components/WorkAcceptanceFrontItem/WorkAcceptanceFrontItem';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';

export const WorkAcceptanceScopeMobileCell = ({row}: CellProps<IPreparedWorkAcceptance>) => {
	const {structures, object, frontType, spaces} = row.original;

	const objectId = object?.id;
	if (!objectId) {
		return null;
	}
	const workAcceptanceFront =
		frontType?.id === WorkAcceptanceFrontTypeId.STRUCTURES
			? structures
			: frontType?.id === WorkAcceptanceFrontTypeId.SPACES
			? spaces
			: undefined;

	return (
		<div className="work-acceptances-page__m-table__cell-scope">
			<div className="work-acceptances-page__m-table__cell-scope__structures">
				{workAcceptanceFront &&
					workAcceptanceFront.slice(0, 2).map(
						item =>
							item && (
								<WorkAcceptanceFrontItem
									item={item}
									objectId={objectId}
									frontType={frontType?.id}
								/>
							)
					)}
				{!!structures?.length && structures?.length > 3 && (
					<div className="work-acceptances-page__m-table__cell-rest">
						+{structures.length - 3}
					</div>
				)}
			</div>
		</div>
	);
};
