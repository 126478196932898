import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants/entitiesVisibility';
import {RESET} from '@src/store/modules/pages/checkLists/constants/page';

export type CheckItemEntitiesVisibility = 'inspections' | 'problems';

export const getInitialState = (): CheckItemEntitiesVisibility[] => ['problems'];

export default createReducer<CheckItemEntitiesVisibility[]>(getInitialState(), {
	[types.CHANGE_ENTITIES_VISIBILITY]: (
		state,
		{payload}: {payload: CheckItemEntitiesVisibility[]}
	) => payload,
	[RESET]: getInitialState
});
