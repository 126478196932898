import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetCategoriesSetsResponse = INormalizedData<ICategoriesSet>;

/**
 * Возвращает список наборов видов работ
 */
export const requestGetCategoriesSets = async () => {
	const response = await httpRequests.withToken.get<IGetCategoriesSetsResponse>(
		'categories-sets/get'
	);

	return response.data;
};
