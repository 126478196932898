import _ from 'lodash';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';

export const extractAllLatestWarrantyClaimsAsArray = (data: IGetWarrantyClaimsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractLatestWarrantyClaimsAsArray = (
	data: IGetWarrantyClaimsResponse,
	userId: string,
	objectId: string,
	spaceId?: string
) => {
	const arr = extractAllLatestWarrantyClaimsAsArray(data);
	return arr.filter(
		item =>
			item.objectId === objectId &&
			item.createdBy?.id === userId &&
			(!spaceId || spaceId === item.links?.spaceId)
	);
};

export const extractLatestWarrantyClaimId = (
	data: IGetWarrantyClaimsResponse,
	userId: string,
	objectId: string,
	spaceId?: string,
	localWarrantyClaim?: IListWarrantyClaim[]
) => {
	const latestInternalAcceptance = extractLatestWarrantyClaimsAsArray(
		data,
		userId,
		objectId,
		spaceId
	);
	const filteredLocalWarrantyClaims = localWarrantyClaim?.filter(
		item => spaceId === item.links?.spaceId
	);
	if (filteredLocalWarrantyClaims?.length) {
		return _.maxBy(filteredLocalWarrantyClaims, 'modifiedAt')?.id;
	}
	if (latestInternalAcceptance.length) {
		return _.maxBy(latestInternalAcceptance, 'modifiedAt')?.id;
	}
	return undefined;
};
