import declination from '@tehzor/tools/utils/declination';
import { memo } from 'react';

interface IDocumentsPageHeaderProps {
	total?: number;
}

const words = ['документ', 'документа', 'документов'];

export const DocumentsPageHeader = memo(({
	total = 0
}: IDocumentsPageHeaderProps) => (
	<div className="documents__page-header">
		<p className="documents__page-header-amount">
			{`Всего: ${total} ${declination(total, words)}`}
		</p>
	</div>
));