import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {getCheck} from '@src/store/modules/app/links/actions/getCheck';
import {LinkButton} from '@tehzor/ui-components';
import { useCallback } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckLink} from '@tehzor/tools/utils/links';
import { useTranslation } from 'react-i18next';

export const CheckEntityLink = ({checkId, objectId}: Record<string, string>) => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();
	const check = useAppSelector(s => s.app.links.checkId?.[checkId]);
	const {t} = useTranslation();

	useAsync(async () => {
		await Promise.all([dispatch(getCheck(objectId, checkId))]);
	}, [checkId, objectId]);

	const handleClick = useCallback(() => {
		pushPath(formCheckLink(objectId, checkId));
	}, [checkId, objectId]);

	if (!check) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${t('components.entityLinks.checkEntityLink.label')} №${check.number}`}
			leftIcon={<i className="tz-external-link-16"/>}
			onClick={handleClick}
			type="filled"
		/>
	);
};
