import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '../StatusesFilterPage';
import {useWarrantyClaimStatusesArray} from '@src/core/hooks/queries/warrantyClaimsStatuses/hooks';

const WClaimStatusesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();
	const {data: statuses} = useWarrantyClaimStatusesArray();
	if (!statuses) return null;

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={statuses}
		/>
	);
};

export default WClaimStatusesFilterPage;
