import {useMemo} from 'react';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useTranslation} from 'react-i18next';

/**
 * Хук для хлебных крошек от "всех объектов" до помещений
 *
 * @param objectId id конечного объекта
 */
export function useSpacesBreadcrumbs(objectId: string): IBreadcrumb[] {
	const {t} = useTranslation();
	const objects = useObjectsData();
	return useMemo(() => {
		if (!objects) return [];
		const items = formObjectsChain(objects.byId, objectId, 'problems', t);
		items.push({
			label: t('space.breadcrumbs'),
			url: `/objects/${objectId}/spaces`,
			inactive: false
		});
		return items;
	}, [objectId, objects]);
}
