/* eslint-disable @typescript-eslint/ban-types */
import { useCallback } from 'react';

import * as React from 'react';
import './TableCell.less';
import classNames from 'classnames';

interface ITableCellProps<D extends object> {
	className?: string;
	style?: React.CSSProperties;
	role?: string;
	children?: React.ReactNode;
	data?: D;
	nonClickable?: boolean;
	showBorders?: boolean;

	onClick?: (data: D) => void;
}

const TableCell = <D extends object>(props: ITableCellProps<D>) => {
	const {className, style, role, children, data, nonClickable, showBorders, onClick, ...restProps} = props;

	const handleClick = useCallback(() => {
		if (onClick && data) {
			onClick(data);
		}
	}, [data, onClick]);

	return (
		<div
			className={classNames(
				'table-cell',
				{'table-cell_non-clickable': nonClickable},
				{'table-cell_with-border': showBorders},
				className
			)}
			style={style}
			role={role}
			onClick={nonClickable ? undefined : handleClick}
			{...restProps}
		>
			{children}
		</div>
	);
};

export default TableCell;