import {DependencyList, ReactNode} from 'react';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {
	EntitiesFiltersProvider,
	IEntitiesFiltersProviderProps
} from '@/shared/lib/EntitiesFilters/context/EntitiesFiltersProvider';
import {AppHeaderCtxOptions} from '@src/components/AppHeader/utils/AppHeaderCtx';

interface IEntitiesPageProps<F extends Record<string, unknown> = Record<string, unknown>> {
	objectId: string;
	appHeaderOptions?: AppHeaderCtxOptions;
	appHeaderDeps?: DependencyList;
	filtersProviderProps: Omit<IEntitiesFiltersProviderProps<F>, 'objectId'>;
	children: ReactNode;
}

export const EntitiesPageWrapper = ({
	objectId,
	filtersProviderProps,
	appHeaderOptions,
	appHeaderDeps,
	children
}: IEntitiesPageProps) => {
	useScrollRestoration();
	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {sectionsMenu, ...appHeaderOptions}, appHeaderDeps);

	return (
		<EntitiesFiltersProvider
			objectId={objectId}
			{...filtersProviderProps}
		>
			{children}
		</EntitiesFiltersProvider>
	);
};
