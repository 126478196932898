import { memo } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import DesktopSpacesExportBtn from './actions/SpacesExportBtn.desktop';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {DesktopMenu} from './actions/Menu.desktop';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';

interface IPageBreadcrumbsProps {
	objectId: string;
}

const PageBreadcrumbs = ({objectId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useObjectBreadcrumbs(objectId);
	const permissions = useSpacesPermissions(objectId);
	const object = useCurrentTreeObject(objectId);
	const lastObject = object ? !object.children?.length : false;
	const isDesktop = useIsDesktop();

	return (
		<AppBreadcrumbs
			className="spaces-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && (
				<>
					{permissions.canExport && <DesktopSpacesExportBtn objectId={objectId} />}
					<DesktopMenu
						objectId={objectId}
						canAddSpace={permissions.canAdd && lastObject}
					/>
				</>
			)}
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
