import {
	ADD_LOCAL_OWNER_ACCEPTANCE,
	DELETE_LOCAL_OWNER_ACCEPTANCE,
	CHANGE_LOCAL_OWNER_ACCEPTANCE_MODIFIED_AT
} from '@src/constants/local-entities/ownerAcceptance';
import {ILocalOwnerAcceptance} from '@src/interfaces/ILocalOwnerAcceptance';

/**
 * Добавляет сущность в локальное хранилище
 *
 * @param {ILocalOwnerAcceptance} ownerAcceptance передача собственнику
 * @returns {{type, payload}} action
 */
export const addLocalOwnerAcceptance = (ownerAcceptance: ILocalOwnerAcceptance) => ({
	type: ADD_LOCAL_OWNER_ACCEPTANCE,
	payload: {ownerAcceptance}
});

export const changeLocalOwnerAcceptanceModifiedAt = (key: string, modifiedAt: number) => ({
	type: CHANGE_LOCAL_OWNER_ACCEPTANCE_MODIFIED_AT,
	payload: {key, modifiedAt}
});

/**
 * Удаляет сущность из локального хранилища
 *
 * @param {string} key id локальной сущности
 * @returns {{type, payload}} action
 */
export const deleteLocalOwnerAcceptance = (key: string) => ({
	type: DELETE_LOCAL_OWNER_ACCEPTANCE,
	payload: {key}
});
