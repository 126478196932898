import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import { memo, useCallback } from 'react';
import {StatsBorderCircle} from '../../components/StatsBorderCircle';

interface ISpaceCircleProps {
	space: IConvertedSpace;
	width: number;
	height: number;
	name: string;
	onClick?: (space: IConvertedSpace) => void;
}

const COLOR = '#EEF5FB';

export const SpaceCircle = memo(({space, width, height, name, onClick}: ISpaceCircleProps) => {
	const {workAcceptances} = space;
	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(space);
		}
	}, [onClick, space]);

	return (
		<svg
			width={width}
			height={height}
			className="work-acceptances-space__status"
			onClick={handleClick}
		>
			{/* тень */}
			<circle
				cx={width / 2}
				cy={height / 2}
				r={(width - 20) / 2}
				className="work-acceptances-space__status-shadow"
			/>

			{/* заливка круга */}
			<circle
				cx={width / 2}
				cy={height / 2}
				r={(width - 20) / 2}
				fill={COLOR}
			/>

			{/* бордер */}
			{workAcceptances?.map(item => (
				<StatsBorderCircle
					key={item.key}
					width={width}
					height={height}
					radius={(width - 20) / 2}
					item={item}
				/>
			))}
			<text
				className="work-acceptances-space__number"
				x={width / 2}
				y={height / 2 + 1}
				dominantBaseline="middle"
				textAnchor="middle"
			>
				{name}
			</text>
		</svg>
	);
});
