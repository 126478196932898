import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import useAppSelector from "@src/core/hooks/useAppSelector";

export interface IPermissions {
	canViewFields?: boolean;
	canViewSettings?: boolean;
	canViewSettingsSets?: boolean;
}

export const useFieldsPermissions = (): IPermissions => {
	const roles = useAppSelector(extractUserRoles);

	return useMemo(() => {
		if (!roles.length) {
			return {};
		}

		return {
			canViewFields: hasPermission(roles, 'fields-view'),
			canViewSettings: hasPermission(roles, 'fields-settings-view'),
			canViewSettingsSets: hasPermission(roles, 'fields-settings-sets-view'),
		}
	}, [roles])
}