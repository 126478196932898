import {IChartDataItem} from '../ProblemsPieChart';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

export function prepareData(
	statuses: IProblemStatus[],
	counts: Record<ProblemStatusId, number | undefined>,
	totalCount?: number
) {
	// Сумма отображаемых элементов
	let visibleTotal = 0;
	const result = statuses.reduce<IChartDataItem[]>((prev, status) => {
		const value = counts[status.id];
		if (value !== undefined && value > 0) {
			prev.push({...status, value});
			if (counts[status.id] !== undefined) {
				visibleTotal += value;
			}
		}
		return prev;
	}, []);

	// Сумма всех элементов
	const total = totalCount ?? Object.values(counts).reduce<number>((sum, num) => sum + (num ?? 0), 0);

	if (total > visibleTotal) {
		const value = total - visibleTotal;
		result.push({
			id: 'total',
			name: '',
			value,
			color: '#E9EFF4'
		});
	}
	return result;
}
