import {useQueryClient} from '@tanstack/react-query';
import {exportTemplatesQueryKeys} from '../keys';
import {requestExportTemplates} from '@src/api/backend/exportTemplates/get';

export const useExportTemplatesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(exportTemplatesQueryKeys.list(), {
		queryFn: requestExportTemplates,
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
