import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import {useMemo} from 'react';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';
import {queryClient} from '@src/api/QueryClient';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';

export const useAddingProblemTaskDialog = (problem: IPreparedProblem) => {
	const onSuccess = () => {
		void queryClient.refetchQueries({queryKey: tasksQueryKeys.lists()});
	};

	const taskDialogArgs = useMemo(
		() => ({
			objectId: problem.objectId,
			taskType: TaskTypeId.TASK,
			links: {problemId: problem.id},
			onSuccess
		}),
		[problem]
	);

	return useAddingTaskDialog(taskDialogArgs);
};
