import {makeExportedOwnerAcptSendRequest} from '@src/api/backend/ownerAcceptance';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';

export const useSendExportedOwnerAcceptance =
	() =>
	async (
		email: string,
		templateId: string,
		objectId: string,
		acceptanceId: string,
		problems?: string[],
		createDocument?: boolean
	) => {
		try {
			await makeExportedOwnerAcptSendRequest(
				email,
				templateId,
				objectId,
				acceptanceId,
				problems,
				createDocument
			);

			addInfoToast('Отправлено', 'Документ успешно отправлен');
		} catch (e) {
			addErrorToast('Ошибка', 'при отправке на email');
		}
	};
