import {useChangePath} from '@src/core/hooks/useChangePath';
import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useCallback} from 'react';
import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const useAddingCategoriesSet = (canAdd = false): React.ReactNode | null => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {pathname} = useLocation();
	const isDesktop = useIsDesktop();
	const handleClick = useCallback(() => {
		pushPath(`${pathname}/add`, {canGoBack: true});
	}, []);

	return canAdd ? (
		isDesktop ? (
			<Button
				className="categories-sets__controls-button"
				type="accent-blue"
				leftIcon={<i className="tz-plus-20" />}
				label={t('components.useAddingCategoriesSet.buttons.addSet.label')}
				onClick={handleClick}
			/>
		) : (
			<FloatingActionButton
				icon={<i className="tz-plus-16" />}
				label={t('components.useAddingCategoriesSet.buttons.add.label')}
				minifyOnScroll
				scrollContainer="scroll-container"
				onClick={handleClick}
			/>
		)
	) : null;
};

export default useAddingCategoriesSet;
