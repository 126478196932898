import {IPreparedClaim} from '@src/pages/WarrantyClaimsPage/interfaces/IPreparedClaim';
import {SelectionActions} from '../selection/SelectionActions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

interface IMobileRightButtonsProps {
	selectedEntities?: IPreparedClaim[];
	selectedClearing: () => void;
}

export const MobileRightButtons = (props: IMobileRightButtonsProps) => {
	const {selectedEntities, selectedClearing} = props;
	const showSelectionActions = selectedEntities && selectedEntities.length > 0;

	return (
		<div>
			{showSelectionActions ? (
				<SelectionActions
					selectedEntities={selectedEntities}
					selectedClearing={selectedClearing}
				/>
			) : null}
			<AppUpdateMenu />
		</div>
	);
};
