import {ReactNode} from 'react';
import styles from './Wrapper.module.less';

interface IWrapperProps {
	children: ReactNode;
}

export const Wrapper = ({children}: IWrapperProps) => (
	<div className={styles.wrapper}>{children}</div>
);
