import {forwardRef, Ref} from 'react';
import * as React from 'react';
import ButtonBase, {OuterTagProps, OuterTagType} from '../ButtonBase';
import './Button.less';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import {createButtonTestId} from './utils/createButtonTestId';

export type ButtonType =
	| 'common'
	| 'common-bordered'
	| 'accent-blue'
	| 'accent-red'
	| 'cancel'
	| 'secondary';

export interface IButtonProps {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				label?: string;
				leftIcon?: string;
				rightIcon?: string;
		  };
	style?: React.CSSProperties;
	type?: ButtonType;
	leftIcon?: React.ReactNode;
	rightIcon?: React.ReactNode;
	label?: React.ReactNode;
	disabled?: boolean;
	outerTagType?: OuterTagType;
	outerTagProps?: OuterTagProps;
	dataTestId?: string;

	onClick?: (event: React.MouseEvent) => void;
}

const Button = (
	props: IButtonProps,
	ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>
) => {
	const {
		className,
		style,
		type = 'common',
		leftIcon,
		rightIcon,
		label,
		disabled,
		outerTagType,
		outerTagProps,
		dataTestId,
		onClick
	} = props;
	const typedClass = type && (disabled ? `button_${type}-disabled` : `button_${type}`);
	const classes = convertClassNames(className);

	return (
		<ButtonBase
			dataTestId={createButtonTestId(type, dataTestId)}
			className={classNames(typedClass, classes.root)}
			style={style}
			wrapClassName={classes.wrap}
			labelClassName={classes.label}
			leftIconClassName={classes.leftIcon}
			rightIconClassName={classes.rightIcon}
			leftIcon={leftIcon}
			rightIcon={rightIcon}
			label={label}
			disabled={disabled}
			classNamePrefix="button"
			outerTagType={outerTagType}
			outerTagProps={outerTagProps}
			onClick={onClick}
			ref={ref}
		/>
	);
};

Button.displayName = 'Button';

export default forwardRef(Button);
