import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const extractInspectionData = (state: IState) => state.entities.inspection || {};

/**
 * Извлекает текущий осмотр
 */
export const extractInspection = createSelector(
	[extractInspectionData],
	inspection => inspection.data
);
