import {IContract} from '@tehzor/tools/interfaces/contracts';
import {createMutation} from 'react-query-kit';
import {contractsQueryKeys} from '../keys';
import {IDeleteContractParams} from '../defaults/mutations/delete';
import {IEditContractParams} from '../defaults/mutations/edit';
import {IAddContractParams} from '../defaults/mutations/add';

export const useAddContractMutation = createMutation<
	IContract, IAddContractParams
>({
	mutationKey: contractsQueryKeys.add(),
	meta: {
		entity: 'contract',
		type: 'creation'
	}
})

export const useEditContractMutation = createMutation<
	IContract, IEditContractParams
>({
	mutationKey: contractsQueryKeys.edit(),
	meta: {
		entity: 'contract',
		type: 'editing'
	}
})

export const useDeleteContractMutation = createMutation<
	IContract, IDeleteContractParams
>({
	mutationKey: contractsQueryKeys.delete(),
	meta: {
		entity: 'contract',
		type: 'removing'
	}
})