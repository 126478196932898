import {formCheckListsLink} from './formCheckListsLink';

interface Args {
	objectId: string;
	stage?: string;
	listId: string;
	spaceId?: string;
	workAcceptanceId?: string;
}
/**
 * Формирует ссылку на чек-лист
 *
 * @param params
 * @param listId id чек-листа
 */
export const formCheckListLink = ({listId, ...params}: Args): string | undefined => {
	const checkListsLink = formCheckListsLink(params);

	return checkListsLink ? `${checkListsLink}/${listId}` : undefined;
};
