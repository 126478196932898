import {useCallback, useState} from 'react';
import './EditableResultWorkAcceptanceDialog.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {
	addWorkAcceptance,
	editWorkAcceptanceResult
} from '@src/store/modules/entities/workAcceptance/actions';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import {useEditableResultWorkAcceptance} from '../EditableResultWorkAcceptance/hooks/useEditableResultWorkAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from 'react-i18next';

interface IEditableResultWorkAcceptanceDialogProps {
	objectId: string;
	stage: ObjectStageIds;
	workAcceptance: IWorkAcceptance;
	isOpen: boolean;

	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

/**
 * Окно редактирования приёмки работ
 */
export const EditableResultWorkAcceptanceDialog = ({
	objectId,
	stage,
	workAcceptance,
	isOpen,
	onClose,
	onSuccess
}: IEditableResultWorkAcceptanceDialogProps) => {
	const {t} = useTranslation();
	const workAcceptanceId = workAcceptance?.id;
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, isBlocking] = useEditableResultWorkAcceptance(
		objectId,
		stage,
		workAcceptance || undefined,
		saving
	);
	const save = useCallback(async () => {
		setSaving(true);
		const savingData = await getSavingData();
		if (savingData && workAcceptanceId) {
			try {
				await dispatch(editWorkAcceptanceResult(objectId, workAcceptanceId, savingData));
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		if (savingData && !workAcceptanceId) {
			try {
				await dispatch(addWorkAcceptance(objectId, savingData));
				if (onSuccess) {
					void onSuccess();
				}
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		setSaving(false);
	}, [objectId, workAcceptanceId, getSavingData, dispatch, onClose, onSuccess]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		t('useConfirmDialog.title'),
		t('useConfirmDialog.message'),
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('useConfirmDialog.rejectBtnLabel')
		}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose, getClosingConfirmation]);

	return (
		<ModalDialog
			className={{root: 'editable-result-work-acceptance-dialog'}}
			open={isOpen}
			title={t('editableResultWorkAcceptanceDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
		>
			{fields}
			{closingDialog}
		</ModalDialog>
	);
};
