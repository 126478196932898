import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeReplyAddRequest} from '@src/api/backend/reply';
import {IAddCommentResponse} from '@src/api/backend/comment';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import ISavingComment from '@src/interfaces/ISavingComment';

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddCommentResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => ({
	type: types.ADD_FAILURE,
	payload: error
});

/**
 * Добавляет ответ на приемку работ
 *
 * @param objectId id объекта
 * @param commentTypeId тип комментрия
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const addWorkAcceptanceReply = (
	objectId: string,
	commentTypeId: ICommentTypeId,
	links: IComment['links'] | undefined,
	fields: ISavingComment
) =>
	createApiAction<IAddCommentResponse>(request, success, failure, () =>
		makeReplyAddRequest(objectId, commentTypeId, links, fields)
	);
