import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {Dispatch, MutableRefObject, RefObject, SetStateAction} from 'react';

interface ISaveInternalAcceptanceProps {
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	selectedInternalAcceptanceId?: string;
	online: boolean;
	localInternalAcceptancesIds: string[];

	addNewInternalAcceptance: () => IInternalAcceptance | undefined;
	updateLatest: () => Promise<void>;
	setSaving: Dispatch<SetStateAction<boolean>>;

	success: MutableRefObject<boolean>;
	onClose: () => void;
}

export const saveInternalAcceptance = async ({
	addingEntityRef,
	selectedInternalAcceptanceId,
	online,
	localInternalAcceptancesIds,
	updateLatest,
	addNewInternalAcceptance,
	setSaving,
	success,
	onClose
}: ISaveInternalAcceptanceProps) => {
	if (addingEntityRef.current) {
		let internalAcceptanceId = selectedInternalAcceptanceId;
		const extraLinks: IProblem['links'] | IInspection['links'] = {internalAcceptanceId};
		const savingData = await addingEntityRef.current.getSavingData(true);
		if (!savingData) {
			return;
		}
		setSaving(true);
		if (addingEntityRef.current.saveCache && savingData) {
			if (internalAcceptanceId === undefined) {
				const internalAcceptance = addNewInternalAcceptance();
				internalAcceptanceId = internalAcceptance?.id;
				if (!internalAcceptance || !internalAcceptanceId) {
					return;
				}
				extraLinks.internalAcceptanceId = internalAcceptanceId;
				await addingEntityRef.current.saveCache(savingData, extraLinks);
			} else if (
				internalAcceptanceId
				&& localInternalAcceptancesIds.includes(internalAcceptanceId)
			) {
				extraLinks.internalAcceptanceId = internalAcceptanceId;
				await addingEntityRef.current.saveCache(savingData, extraLinks);
			} else {
				const cachedData = await addingEntityRef.current.saveCache(savingData, extraLinks);
				if (cachedData) {
					await addingEntityRef.current.save(savingData, extraLinks, cachedData.cacheKey);
				}
			}
		}
		if (internalAcceptanceId && online) {
			void updateLatest();
		}
	} else {
		onClose();
	}
	success.current = true;
	if (success.current) {
		onClose()
		setSaving(false)
	}
};
