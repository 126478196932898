import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IValueType} from '@tehzor/tools/interfaces/fields';

export type IGetFieldValueTypesResponse = INormalizedData<IValueType>;

/**
 * Получение списка типов значений для полей
 */
export const requestGetFieldValueTypes = async () => {
	const res = await httpRequests.withToken.get<IGetFieldValueTypesResponse>(
		'field-types/get-value'
	);

	return res.data;
};
