import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ICheckRecordStatus} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {convertProblemsData} from './convertProblemsData';
import {convertWorkAcceptancesData} from './convertWorkAcceptancesData';
import {getCategoriesFromWorkAcceptances} from './getCategoriesFromWorkAcceptances';
import {convertCheckLists} from './convertCheckLists';

export const enrichStructures = (
	structures: IListStructure[],
	problemStatuses: INormalizedData<IProblemStatus> | undefined,
	workAcceptanceStatuses: INormalizedData<IWorkAcceptanceStatus> | undefined,
	checkLists: Record<string, ICheckList[]>,
	checkListsRecords: ICheckRecord[],
	checkRecordStatuses: Record<string, ICheckRecordStatus>
): IEnrichedStructure[] =>
	structures.map(structure => ({
		...structure,
		problemsStats:
			problemStatuses && convertProblemsData(structure.problems?.problems, problemStatuses),
		workAcceptancesStats:
			workAcceptanceStatuses &&
			convertWorkAcceptancesData(structure.workAcceptances, workAcceptanceStatuses),
		categories: getCategoriesFromWorkAcceptances(structure.workAcceptances),
		checkLists: convertCheckLists(structure, checkLists, checkListsRecords, checkRecordStatuses)
	}));
