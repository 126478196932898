import {useEffect, useMemo} from 'react';
import * as React from 'react';
import {formCheckLink, formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {usePermissions} from './usePermissions';
import {useChecksQuery} from '@src/core/hooks/queries/checks/hooks';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getProblem} from '@src/store/modules/pages/problem/actions';
import {extractProblem} from '@src/store/modules/pages/problem/selectors/problem';
import {useTranslation} from 'react-i18next';
import {useProblems} from '@src/core/hooks/queries/problems/hooks';
import {useInspections} from '@src/core/hooks/queries/inspections/hooks';

export interface ITaskLink {
	id: string;
	type: 'problem' | 'inspection' | 'check';
	icon: React.ReactNode;
	url?: string;
	title: string;
	label: string;
}

const checkIcon = <i className="tz-check-24" />;

export function useTaskLinks(task: IEnrichedTask): ITaskLink[] {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const objectId = task?.objectId?.id || 'all';
	const realObjectId = task?.objectId?.id;
	const problemId = task?.links?.problemId;
	const permissions = usePermissions(objectId);
	const {data: problems} = useProblems(objectId);
	const {data: inspections} = useInspections(objectId);
	const {data: checks} = useChecksQuery(objectId);
	const problem = useAppSelector(extractProblem);

	useEffect(() => {
		if (realObjectId && problemId) {
			void getProblem(realObjectId, problemId);
		}
	}, [realObjectId, problemId, dispatch]);

	return useMemo((): ITaskLink[] => {
		const result: ITaskLink[] = [];

		if (problemId && problem) {
			result.push({
				id: problemId,
				type: 'problem',
				icon: checkIcon,
				url: formProblemLink(problem.objectId, problemId),
				title: t('taskViewPage.useTaskLinks.problem.title'),
				label: t('taskViewPage.useTaskLinks.problem.label', {id: problem.number})
			});
		}

		if (problems?.byId) {
			const problemsObj = problems.byId;
			const keys = Object.keys(problems.byId);

			keys.forEach(key => {
				const problem = problemsObj?.[key];

				if (
					problem?.links?.taskId !== undefined &&
					problem?.links?.taskId === task.id &&
					permissions.canViewProblem
				) {
					result.push({
						id: problem.id,
						type: 'problem',
						icon: checkIcon,
						url: formProblemLink(problem.objectId, problem.id),
						title: t('taskViewPage.useTaskLinks.problem.title'),
						label: t('taskViewPage.useTaskLinks.problem.label', {id: problem.number})
					});
				}
			});
		}

		if (inspections?.byId) {
			const inspectionsObj = inspections.byId;
			const keys = Object.keys(inspections.byId);

			keys.forEach(key => {
				const inspection = inspectionsObj?.[key];

				if (
					inspection?.links?.taskId !== undefined &&
					inspection?.links?.taskId === task.id &&
					permissions.canViewInspection
				) {
					result.push({
						id: inspection.id,
						type: 'inspection',
						icon: checkIcon,
						url: formInspectionLink(inspection.objectId, inspection.id),
						label: t('taskViewPage.useTaskLinks.inspection.label', {
							id: inspection.number
						}),
						title: t('taskViewPage.useTaskLinks.inspection.title')
					});
				}
			});
		}

		if (checks?.byId) {
			const checksObj = checks.byId;
			const keys = Object.keys(checks.byId);

			keys.forEach(key => {
				const check = checksObj?.[key];

				if (
					check?.links?.taskId !== undefined &&
					check?.links?.taskId === task.id &&
					permissions.canViewCheck
				) {
					result.push({
						id: check.id,
						type: 'check',
						icon: checkIcon,
						url: formCheckLink(check.objectId, check.id),
						label: t('taskViewPage.useTaskLinks.check.label', {id: check.number}),
						title: t('taskViewPage.useTaskLinks.check.title')
					});
				}
			});
		}

		return result;
	}, [task, problems, inspections, checks, permissions, problemId, problem, t]);
}
