import {
	IInternalAcceptancesFiltersState,
	IInternalAcceptancesSortState
} from '@src/store/modules/settings/pages/internalAcceptances/interfaces';

export type IInternalAcceptancesListQueryKey = [] & {
	0: string;
	1: string;
	2: IInternalAcceptancesFiltersState;
	3: IInternalAcceptancesSortState;
	4: number;
	5: number;
	6: string;
};

export type IInternalAcceptanceInspectionsQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: string;
};

export type IInternalAcceptanceProblemsQueryKey = [] & {
	0: string; // internal-acceptance
	1: string; // problems
	2: string; // objectId
	3: string; // internalAcceptanceId
};

export type IInternalAcceptanceDetailQueryKey = [] & {
	0: string;
	1: string;
	2: string; // internalAcceptanceId
	3: string; // objectId
};

export const internalAcceptancesQueryKeys = {
	all: () => ['internal-acceptances'],
	one: () => ['internal-acceptance'],
	latest: () => ['latest-internal-acceptances'],
	lists: () => [...internalAcceptancesQueryKeys.all(), 'list'],
	delete: () => [...internalAcceptancesQueryKeys.one(), 'delete'] as const,
	list: (
		filters?: IInternalAcceptancesFiltersState,
		sort?: IInternalAcceptancesSortState,
		offset?: number,
		pageSize?: number,
		objectId?: string
	) => [...internalAcceptancesQueryKeys.lists(), filters, sort, offset, pageSize, objectId],
	localList: () => [...internalAcceptancesQueryKeys.all(), 'local-list'],
	add: () => [...internalAcceptancesQueryKeys.one(), 'add'],
	details: () => [...internalAcceptancesQueryKeys.one(), 'detail'],
	detail: (internalAcceptanceId: string) => [
		...internalAcceptancesQueryKeys.details(),
		internalAcceptanceId
	],
	inspections: () => [...internalAcceptancesQueryKeys.one(), 'inspections'],
	internalAcceptanceInspections: (objectId: string, internalAcceptanceId: string) => [
		...internalAcceptancesQueryKeys.inspections(),
		objectId,
		internalAcceptanceId
	],
	problems: () => [...internalAcceptancesQueryKeys.one(), 'problems'],
	internalAcceptanceProblems: (objectId: string, internalAcceptanceId: string) => [
		...internalAcceptancesQueryKeys.problems(),
		objectId,
		internalAcceptanceId
	]
};
