import {IStructuresFiltersState, IStructuresSortState} from '../../slice/interfaces';

export type IStructuresListQueryKey = [] & {
	0: string;
	1: string;
	2: IStructuresFiltersState;
	3: IStructuresSortState;
	4: number;
	5: number;
};

export type IStructuresPaginateQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: IStructuresFiltersState;
	4: IStructuresSortState;
	5: number;
	6: number;
};

export type IStructuresProblemsStatsQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: string; // objectId
	4: string[]; // structuresIds
};
export const structuresQueryKeys = {
	all: () => ['structures'],
	one: () => ['structure'],
	list: () => [...structuresQueryKeys.all(), 'list'],
	paginate: () => [...structuresQueryKeys.list(), 'paginate'],
	localList: () => [...structuresQueryKeys.all(), 'local-list'],
	stats: () => [...structuresQueryKeys.all(), 'stats'],
	problemsStatsAll: () => [...structuresQueryKeys.stats(), 'problems'],
	problemsStats: (objectId: string, structuresIds?: string[]) => [
		...structuresQueryKeys.problemsStatsAll(),
		objectId,
		structuresIds
	]
};
