export enum OwnerAcceptanceResolutionId {
	ACCEPTED = 'accepted',
	ACCEPTED_WITH_PROBLEMS = 'accepted-with-problems',
	REJECTED = 'rejected',
	UNDEFINED = 'undefined'
}

export interface IOwnerAcceptanceResolution {
	id: OwnerAcceptanceResolutionId;
	name: string;
	color?: string;
}
