import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Удаляет помещение
 *
 * @param objectId id объекта
 * @param spaceId id нарушения
 */

export const makeSpaceDeleteRequest = async (objectId: string, spaceId: string) => {
	await httpRequests.withToken.delete(`spaces/${spaceId}`, {data: {objectId}});
};
