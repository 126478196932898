import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface ICheckListPermissions {
	canView?: boolean;
	canViewHistory?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
}

export const useCheckListPermissions = (objectId?: string): ICheckListPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		return {
			canView: hasPermission(user.roles, 'check-lists-view', UserRoleScopes.OBJECT, objectId),
			canViewHistory: hasPermission(user.roles, 'check-lists-history-view', UserRoleScopes.OBJECT, objectId),
			canAdd: hasPermission(user.roles, 'check-lists-add', UserRoleScopes.OBJECT, objectId),
			canEdit: hasPermission(user.roles, 'check-lists-edit', UserRoleScopes.OBJECT, objectId),
			canDelete: hasPermission(
				user.roles,
				'check-lists-delete',
				UserRoleScopes.OBJECT,
				objectId
			),
			canExport: hasPermission(
				user.roles,
				'check-lists-export',
				UserRoleScopes.OBJECT,
				objectId
			)
		};
	}, [user, objectId]);
};
