import {queryClient} from '@src/api/QueryClient';
import {requestAddWorkingGroup} from '@src/api/backend/workingGroup';
import { responsibilityRulesQueryKeys } from '@src/api/cache/responsibilityRules/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';

export const useAddWorkingGroup = () =>
	useMutation({
		mutationFn: (params: ISavingWorkingGroup) => requestAddWorkingGroup(params),
		mutationKey: workingGroupsQueryKeys.add(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: workingGroupsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
			addSuccessToast('Успешно', 'Группа создана');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при добавлении рабочей группы');
		}
	});
