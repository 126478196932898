import {CellProps} from 'react-table';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';

export const ObjectStageCell = ({row}: CellProps<IPreparedCheckList>) => {
	const {data: stages} = useObjectStagesAsArray();
	if (!stages) {
		return null;
	}
	const stage = stages.find(({id}) => id === row.original.stage);

	if (!stage) {
		return null;
	}

	return <div>{stage.name}</div>;
};
