import {EntityGridItem} from '@tehzor/ui-components';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import './IntervalView.less';

interface IDescriptionProps {
	className?: string;
	start?: number;
	stop?: number;
}

const clockIcon = <i className="tz-clock" />;

export const IntervalView = ({className, start, stop}: IDescriptionProps) => {
	const {t} = useTranslation();

	return (
		<EntityGridItem
			className={className}
			icon={clockIcon}
			label={t('taskViewPage.intervalView.title')}
			fullRow
		>
			<div className="interval-view-field__content">
				<span>{t('taskViewPage.intervalView.from')}</span>
				<span>{start && format(start, dateTimeCommaSeparatedFormat)}</span>
				<span>{t('taskViewPage.intervalView.to')}</span>
				<span>{stop && format(stop, dateTimeCommaSeparatedFormat)}</span>
			</div>
		</EntityGridItem>
	);
};
