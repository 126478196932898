import {
	ADD_LOCAL_WARRANTY_CLAIM,
	CHANGE_LOCAL_WARRANTY_CLAIM_MODIFIED_AT,
	DELETE_LOCAL_WARRANTY_CLAIM
} from '@src/constants/local-entities/warrantyClaim';
import {ILocalWarrantyClaim} from '@src/interfaces/ILocalWarrantyClaim';

/**
 * Добавляет сущность в локальное хранилище
 *
 * @param {ILocalWarrantyClaim} warrantyClaim гарантия
 * @returns {{type, payload}} action
 */
export const addLocalWarrantyClaim = (warrantyClaim: ILocalWarrantyClaim) => ({
	type: ADD_LOCAL_WARRANTY_CLAIM,
	payload: {warrantyClaim}
});

export const changeLocalWarrantyClaimModifiedAt = (key: string, modifiedAt: number) => ({
	type: CHANGE_LOCAL_WARRANTY_CLAIM_MODIFIED_AT,
	payload: {key, modifiedAt}
});

/**
 * Удаляет сущность из локального хранилища
 *
 * @param {string} key id локальной сущности
 * @returns {{type, payload}} action
 */
export const deleteLocalWarrantyClaim = (key: string) => ({
	type: DELETE_LOCAL_WARRANTY_CLAIM,
	payload: {key}
});
