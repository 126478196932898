import { ChangeEvent, ForwardedRef, HTMLProps } from 'react';
import * as React from 'react';
import './TextField.less';
import classNames from 'classnames';
import Textarea, {TextareaAutosizeProps} from 'react-textarea-autosize';
import FieldError from '../FieldError';
import {convertClassNames} from '../../../utils/convertClassNames';

export type ElementType = 'div' | 'input' | 'textarea';

export type ElementPropsType = HTMLProps<HTMLDivElement>
| HTMLProps<HTMLInputElement>
| TextareaAutosizeProps;

export interface ITextFieldProps {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				element?: string;
				icon?: string;
				placeholder?: string;
				clearbutton?: string;
				hiddenIcon?: string;
		  };
	style?: React.CSSProperties;
	elementType?: ElementType;
	elementProps?: ElementPropsType;
	elementRef?: ForwardedRef<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>;
	value?: string;
	defaultValue?: string;
	disabled?: boolean;
	placeholder?: string;
	error?: string;
	valid?: boolean;
	icon?: React.ReactNode;
	hiddenIcon?: React.ReactNode;
	cleanable?: boolean;
	hideable?: boolean;
	isHidden?: boolean;
	isHandleEnterDisabled?: boolean;
	onClearClick?: () => void;
	onChange?: (value: string) => void;
	onClick?: (event: React.MouseEvent) => void;
	onIconClick?: (event?: React.MouseEvent) => void;
}

interface ITextFieldState {
	value?: string;
	currentValue: string;
	isHidden: boolean;
	focused: boolean;
	isIconPressed?: boolean;
}

class TextField extends React.PureComponent<ITextFieldProps, ITextFieldState> {
	static displayName = 'TextField';

	static defaultProps: Partial<ITextFieldProps> = {
		elementType: 'div'
	};

	private _element?: HTMLDivElement | HTMLInputElement | HTMLTextAreaElement;

	constructor(props: ITextFieldProps) {
		super(props);

		const currentValue = props.defaultValue ?? '';
		const isHidden = props.isHidden || false;
		this.state = {
			currentValue,
			isHidden,
			focused: false,
			isIconPressed: false
		};
	}

	static getDerivedStateFromProps(nextProps: ITextFieldProps, prevState: ITextFieldState) {
		if (
			(nextProps.value !== undefined && nextProps.value !== prevState.value)
			|| (nextProps.value === undefined && prevState.value !== undefined)
		) {
			return {
				value: nextProps.value,
				currentValue: nextProps.value || ''
			};
		}
		return null;
	}

	/**
	 * Возвращает текущее значение
	 */
	getValue = (): string => this.state.currentValue;

	/**
	 * Переводит фокус на элемент
	 */
	focus = () => {
		if (this._element) {
			this._element.focus();
		}
	};

	/**
	 * Снимает фокус с элемента
	 */
	blur = () => {
		if (this._element) {
			this._element.blur();
		}
	};

	render() {
		const {
			className,
			style,
			disabled,
			placeholder,
			error,
			valid,
			icon,
			hiddenIcon,
			cleanable,
			hideable,
			onClick,
			onClearClick,
			onIconClick
		} = this.props;
		const {currentValue, isHidden, focused, isIconPressed} = this.state;
		const classes = convertClassNames(className);

		return (
			<div
				className={classNames('text-field', classes.root)}
				style={style}
			>
				<div
					className={classNames(
						'text-field__wrap',
						{'text-field__wrap_focused': focused},
						{'text-field__wrap_disabled': disabled},
						{'text-field__wrap_error': error !== undefined},
						{'text-field__wrap_valid': valid},
						{'text-field__wrap_clickable': onClick !== undefined},
						classes.wrap
					)}
					onClick={onClick}
				>
					{this._renderElement(classes.element)}

					{cleanable && (
						<div
							className={classNames(
								'text-field__clear-btn',
								'clickable',
								classes.clearbutton
							)}
							onClick={event => {
								this._clearValue(event);
								if (onClearClick) {
									onClearClick();
								}
							}}
						>
							<i className="tz-close-20"/>
						</div>
					)}

					{icon !== undefined && (
						<div
							className={classNames(
								'text-field__icon',
								isHidden ? classes.hiddenIcon : classes.icon,
								{
									isPressed: isIconPressed,
									clickable: onIconClick || hideable
								}
							)}
							onClick={hideable ? this._handleChangeVisibility : onIconClick}
						>
							{isHidden ? hiddenIcon : icon}
						</div>
					)}

					{placeholder !== undefined && !currentValue && !focused && (
						<div className={classNames('text-field__placeholder', classes.placeholder)}>{placeholder}</div>
					)}
				</div>

				{!!error && <FieldError text={error}/>}
			</div>
		);
	}

	componentDidMount() {
		const {isHidden} = this.state;
		this._updateVisibility(isHidden);
	}

	componentDidUpdate(prevProps: ITextFieldProps, prevState: ITextFieldState) {
		const {isHidden} = this.state;

		if (prevState.isHidden === isHidden) { return; }
		this._updateVisibility(isHidden);
	}

	private _updateVisibility = (isHidden: boolean) => {
		const element = this._element;

		const input = element instanceof HTMLInputElement
			? element
			: undefined;

		if (!input || !element) { return; }

		input.type = isHidden ? 'password' : 'text';
	};

	private _renderElement = (className?: string): React.ReactNode => {
		const {elementType = 'div', elementProps = {}, disabled} = this.props;
		const {currentValue} = this.state;

		const props = {
			...elementProps,
			className: classNames(elementProps.className, `text-field__${elementType}`, className),
			onFocus: this._handleFocus,
			onBlur: this._handleBlur,
			ref: this._saveElementRef
		} as TextareaAutosizeProps & React.RefAttributes<HTMLTextAreaElement>;

		if (elementType === 'div') {
			props.tabIndex = disabled ? undefined : 0;
			props.children = currentValue;
		} else {
			props.value = currentValue || '';
			props.disabled = disabled;
			props.onChange = this._handleChange;
			props.onKeyDown = this._handleKeyDown;
			props.onKeyUp = this._handleKeyUp;
		}
		if (props.defaultValue) {
			delete props.defaultValue;
		}
		return React.createElement(elementType === 'textarea' ? Textarea : elementType, props);
	};

	private _handleFocus = () => {
		this.setState({focused: true});
	};

	private _handleBlur = () => {
		this.setState({focused: false});
	};

	private _handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
		const newValue = event.target.value;
		const {value, onChange} = this.props;

		if (onChange) {
			onChange(newValue);
		}
		if (value === undefined) {
			this.setState({currentValue: newValue});
		}
	};

	private _handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement & HTMLInputElement & HTMLDivElement>) => {
		const {onIconClick, isHandleEnterDisabled, elementProps} = this.props;

		if (elementProps?.onKeyDown) {
			elementProps.onKeyDown(event);
		}

		if (!onIconClick || isHandleEnterDisabled) { return; }

		if (event.key === 'Enter') {
			this.setState({isIconPressed: true});
		}
	};

	private _handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement & HTMLInputElement & HTMLDivElement>) => {
		const {onIconClick, isHandleEnterDisabled, elementProps} = this.props;

		if (elementProps?.onKeyUp) {
			elementProps.onKeyUp(event);
		}

		if (!onIconClick || isHandleEnterDisabled) { return; }

		if (event.key === 'Enter' && onIconClick) {
			onIconClick();
			this.setState({isIconPressed: false});
		}
	};

	private _clearValue = (event: React.MouseEvent) => {
		event.stopPropagation();
		const {value, onChange} = this.props;
		if (value === undefined) {
			this.setState({currentValue: ''});
		} else if (onChange) {
			onChange('');
		}
	};

	private _handleChangeVisibility = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.setState(s => ({isHidden: !s.isHidden}));
	};

	private _saveElementRef = (element: HTMLDivElement | HTMLInputElement | HTMLTextAreaElement) => {
		this._element = element;
		if (this.props.elementRef) {
			if (typeof this.props.elementRef === 'function') {
				this.props.elementRef(element);
			} else {
				this.props.elementRef.current = element;
			}
		}
	};
}

export default TextField;
