import {PrivateRoute} from '@src/containers/PrivateRoute';
import {AllWarrantyClaimsPage} from '@src/pages/WarrantyClaimsPage';
import WarrantyClaimsFiltersRoutingPage from '@src/pages/filters/WarrantyClaimsFiltersRoutingPage';
import {warrantyClaimsFilterRoutes} from './warrantyClaimsFilterRoutes';

export const warrantyClaimsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllWarrantyClaimsPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<WarrantyClaimsFiltersRoutingPage/>}/>,
		children: warrantyClaimsFilterRoutes
	}
];
