import StatusesFilterPage from '../StatusesFilterPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useSpaceIndicatorsAsArray} from '@src/core/hooks/queries/spaceIndicatorsSets/hooks';
import {useTranslation} from 'react-i18next';

const SpacesStatusesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const {data: object} = useObject(objectId);
	const {data: indicators} = useSpaceIndicatorsAsArray(object);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={indicators}
			label={t('spacesFiltersPage.spacesIndicatorsFilterMobile.baseListFilterMobile.label')}
			filterName="indicators"
		/>
	);
};

export default SpacesStatusesFilterPage;
