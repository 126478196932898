import {
	IEditableCategoriesSetAction,
	IEditableCategoriesSetState
} from '@src/core/hooks/states/useCategoriesSetState';
import {TextField} from '@tehzor/ui-components';
import { Dispatch, useCallback, useMemo } from 'react';
import * as React from 'react';

interface IEditableNameFieldProps {
	disabled?: boolean;

	setEdited: (value: React.SetStateAction<boolean>) => void;
	editingDispatch: Dispatch<IEditableCategoriesSetAction>;
	editingState: IEditableCategoriesSetState;
}

const EditableNameField = ({
	disabled,
	setEdited,
	editingDispatch,
	editingState
}: IEditableNameFieldProps) => {
	const hasError = useMemo(() => !!editingState.errors?.name, [editingState]);

	const handleChange = useCallback((value: string) => {
		setEdited(true);
		editingDispatch({type: 'update', field: 'name', value});
	}, []);

	return (
		<div className="editable-namefield">
			<TextField
				placeholder="Название набора видов работ"
				className="editable-namefield__input"
				elementType={!disabled ? 'input' : 'div'}
				value={editingState.name}
				onChange={handleChange}
				error={hasError ? 'Укажите наименование' : undefined}
			/>
		</div>
	);
};

export default EditableNameField;
