import {IEditPlansOrderResponse} from '@src/api/backend/plans';
import {createMutation} from 'react-query-kit';
import {plansQueryKeys} from '../keys';
import {IEditOrderPlansParams} from '../defaults/mutations/editOrder';

export const useEditOrderPlansMutation = createMutation<
	IEditPlansOrderResponse, IEditOrderPlansParams
>({
	mutationKey: plansQueryKeys.editOrder(),
	meta: {
		entity: 'plan',
		type: 'orderEditing'
	}
});