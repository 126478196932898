import {DBSchema, openDB, IDBPDatabase} from 'idb';

const dbKey = 'auth-tokens' as const;
const storeKey = 'tokens' as const;

interface TehzorDB extends DBSchema {
	[storeKey]: {
		key: string;
		value: string;
	};
}

const keys = {
	accessToken: 'access-token',
	refreshToken: 'refresh-token',
	deviceId: 'deviceId'
};

/**
 * Класс для сохранения данных для авторизации (id пользователя, токены) в локальном хранилище
 */
class LocalAuthStorage {
	private db: Promise<IDBPDatabase<TehzorDB>>;

	constructor() {
		this.db = openDB<TehzorDB>(dbKey, 1, {
			upgrade(db) {
				if (!db.objectStoreNames.contains(storeKey)) {
					db.createObjectStore(storeKey);
				}
			}
		});
	}

	/**
	 * Возвращает токен доступа
	*/
	getAccessToken = async (): Promise<string | undefined> => this.get(keys.accessToken);

	/**
	 * Возвращает токен обновления
	 */
	getRefreshToken = async (): Promise<string | undefined> => this.get(keys.refreshToken);

	/**
	 * Сохраняет токен доступа
	 * @param accessToken Токен доступа
	 */
	setAccessToken = async (accessToken: string): Promise<void> => {
		await this.set(keys.accessToken, accessToken);
	};

	/**
	 * Сохраняет токен обновления
	 * @param refreshToken Токен обновления
	 */
	setRefreshToken = async (refreshToken: string): Promise<void> => {
		await this.set(keys.refreshToken, refreshToken);
	};

	/**
	 * Сохраняет токены
	 * @param accessToken Токен доступа
	 * @param refreshToken Токен обновления
	 */
	setTokens = async (
		accessToken: string,
		refreshToken: string
	): Promise<void> => {
		await this.setAccessToken(accessToken);
		await this.setRefreshToken(refreshToken);
	};

	/**
	 * Возращает id устройства
	 */
	getDeviceId = async (): Promise<string | undefined> => this.get(keys.deviceId);

	/**
	 * Сохраняет id устройства
	 * @param deviceId Идентификатор устройства
	 */
	setDeviceId = async (deviceId: string): Promise<void> => {
		await this.set(keys.deviceId, deviceId);
	};

	/**
	 * Очищает indexedDB
	 */
	clear = async (): Promise<void> => {
		const db = await this.db;
		await db.clear(storeKey);
	};

	/**
	 * Возвращает значение по ключу из indexedDB
	 *
	 * @param key ключ
	 */
	private get = async (key: string): Promise<string | undefined> => (await this.db).get(storeKey, key);

	/**
	 * Сохраняет значение в indexedDB
	 *
	 * @param key ключ
	 * @param value значение
	 */
	private set = async (key: string, value: string): Promise<string> => (await this.db).put(storeKey, value, key);
}

export const localAuthStorage = new LocalAuthStorage();
