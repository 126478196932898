import { memo } from 'react';
import {DownloadableFile, EntityGridItem, InlineButton} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {useTranslation} from 'react-i18next';

interface IMobileDocumentsProps {
	space: ISpace;
	openDialog: () => void;
	canEdit?: boolean;
}

const photoIcon = <i className="tz-photo-20" />;
const editIcon = <i className="tz-edit-20" />;

export const MobileDocuments = memo(({space, openDialog, canEdit}: IMobileDocumentsProps) => {
	const {t} = useTranslation();
	return (
		<EntityGridItem
			label={t('spacePage.documents.title')}
			icon={photoIcon}
			fullRow
			buttons={
				canEdit ? (
					<InlineButton
						type="accent"
						leftIcon={editIcon}
						onClick={openDialog}
						className="space-page__info-edit-icon"
					/>
				) : null
			}
		>
			{space.attachments?.length
				? space.attachments.map(item =>
						item.full ? (
							<div
								key={item.id}
								className="space-page__documents-file"
							>
								<DownloadableFile
									name={item.full.name}
									url={item.full.url}
									type={item.full.type}
									size={item.full.size}
								/>
							</div>
						) : null
				  )
				: null}
		</EntityGridItem>
	);
});
