import { memo, useCallback } from 'react';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import classNames from 'classnames';
import {formCheckListLink} from '@tehzor/tools/utils/links';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {CheckListDesktop} from '../CheckListDesktop/CheckListDesktop';
import {CheckListMobile} from '@src/pages/CheckListPage/components/CheckListMobile/CheckListMobile';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';

interface IStageGroupProps {
	stage: IObjectStage;
	checkLists: ICheckList[];
	addDivider?: boolean;
	mobileView?: boolean;
}

export const StageGroup = memo((props: IStageGroupProps) => {
	const {stage, checkLists, addDivider, mobileView} = props;
	const {pushPath} = useChangePath();
	const params = useStrictParams<{
		objectId: string;
		stage: string;
		listId: string;
		spaceId?: string;
		workAcceptanceId?: string;
	}>();

	const redirectToCheckList = useCallback(
		(checkListId: string) => {
			const checkListLink = formCheckListLink({...params, listId: checkListId});
			if (params.listId !== checkListId && checkListLink) {
				pushPath(checkListLink);
			}
		},
		[params, pushPath]
	);

	const stageGroupClass = classNames({
		'check-list-page-list__stage-group': !mobileView,
		'check-list-page__m-list__stage-group': mobileView
	});

	const stageGroupHeaderClass = classNames({
		'check-list-page-list__stage-group__header': !mobileView,
		'check-list-page__m-list__stage-group__header': mobileView
	});

	return (
		<>
			<div className={stageGroupClass}>
				<h2 className={stageGroupHeaderClass}>{stage.name}</h2>
				{checkLists?.map(checkList =>
					!mobileView ? (
						<CheckListDesktop
							key={checkList.id}
							checkList={checkList}
							onClick={redirectToCheckList}
						/>
					) : (
						<CheckListMobile
							type={CheckListTypeId.OPERATIONS}
							className="check-list-page__m-list__stage-group__check-list"
							key={checkList.id}
							checkList={checkList}
							onClick={redirectToCheckList}
						/>
					)
				)}
			</div>
			{addDivider && <hr className="check-list-page-list__divider" />}
		</>
	);
});
