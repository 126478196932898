import {memo, useCallback} from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {deleteWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IPreparedWorkAcceptance[];
	selectedClearing: () => void;
}

export const SelectedEntitiesDeletion = memo(
	({selectedEntities, selectedClearing}: ISelectedEntitiesDeletionProps) => {
		const dispatch = useAppDispatch();
		const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
			'Удалить приемки работ?',
			'Вы действительно хотите удалить выбранные приемки работ?',
			{acceptBtnProps: {type: 'accent-red'}}
		);

		const handleClick = useCallback(async () => {
			if (await getDeleteConfirmation()) {
				for (const workAcceptance of selectedEntities) {
					await dispatch(
						deleteWorkAcceptance(workAcceptance?.object?.id || 'all', workAcceptance.id)
					);
				}
				selectedClearing();
			}
		}, [selectedEntities, selectedClearing, getDeleteConfirmation, dispatch]);

		return (
			<>
				<IconButton onClick={handleClick}>
					<i className="tz-delete" />
				</IconButton>

				{deleteDialog}
			</>
		);
	}
);
