import classNames from 'classnames';
import { ReactNode, createRef, useMemo, useRef, useState } from 'react';
import {useLatest} from 'react-use';
import {convertClassNames} from '../../../utils/convertClassNames';
import {IDndDragState, IDndItem, IDragPositionHandler} from '../interfaces';
import './DndContext.less';
import {DndCtx, defaultHandlers, defaultState} from './components/DndCtx';
import {DndPreview} from './components/DndPreview';
import {useDndPreview} from './hooks/useDndPreview';
import {useSetEventHandlers} from './hooks/useSetEventHandlers';
import {IDndCtx, IDragHandlers} from './interfaces/IDndCtx';
import {IDndRenderPreviewProps} from './interfaces/IDndRenderPreviewProps';
import {IInitPosition} from './interfaces/IInitPositionRef';

interface IDndContextProps<S> {
	className?:
		| string
		| {
				root?: string;
				preview?: string;
		  };

	renderPreview?: (props: IDndRenderPreviewProps<S>) => ReactNode;
	previewOffset?: number;
	children: ReactNode;
	treeOffset?: number;
	selector?: string;
}

export const DndContextRef = createRef<HTMLDivElement>();

export const DndContext = <S extends IDndItem>({
	className,
	renderPreview,
	children,
	previewOffset,
	treeOffset,
	selector
}: IDndContextProps<S>) => {
	const initPositionRef = useRef<IInitPosition>({});
	const [dragState, setDragState] = useState(defaultState as IDndDragState<S>);
	const [handlers, setHandlers] = useState(defaultHandlers);

	const latestHandlers = useLatest(handlers);

	const contextValue: IDndCtx<S> = useMemo(
		() => ({
			treeOffset,
			dragState,
			changeDragState: (newState: IDndDragState<S>) => {
				setDragState(s => ({...s, ...newState}));
			},
			resetDragState: () => {
				setDragState(s => (!s.isDragging ? s : {isDragging: false, containers: s.containers}));
			},
			changeInitPosition: (x: number, y: number) => {
				initPositionRef.current = {x, y};
			},
			changeDragContainers: (container: string, elementRef: HTMLElement) => {
				setDragState(s => ({
					...s,
					containers: {
						...s.containers,
						[container]: elementRef
					}
				}));
			},
			handlers,
			addHandler: (
				handler: IDragPositionHandler,
				key: keyof IDragHandlers,
				container: string
			) => {
				setHandlers(s => {
					const currentContainer = container;

					const newState = s;
					if (!newState[key]) {
						newState[key] = {};
					}
					newState[key][currentContainer] = handler;
					return newState;
				});
			}
		}),
		[dragState, handlers, treeOffset]
	);

	useSetEventHandlers(latestHandlers, selector);
	const previewRef = useDndPreview(
		contextValue.addHandler,
		initPositionRef,
		dragState.isDragging,
		previewOffset
	);

	const classes = useMemo(() => convertClassNames(className), [className]);

	return (
		<div
			className={classNames(classes.root, 'dnd-context')}
			ref={DndContextRef}
		>
			<DndCtx.Provider value={contextValue}>
				{children}

				<DndPreview
					className={classes.preview}
					state={dragState}
					ref={previewRef}
					renderPreview={renderPreview}
				/>
			</DndCtx.Provider>
		</div>
	);
};