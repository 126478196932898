import ILayer from '@tehzor/tools/interfaces/plans/ILayer';

const extractPoints = (polygonString: string): number[][] => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(polygonString, 'image/svg+xml');
	const polygon = doc.querySelector('polygon');

	if (!polygon) {
		return [];
	}

	const points = polygon.getAttribute('points');

	if (!points) {
		return [];
	}

	const pointsArray = points
		.split(/[ ,]+/)
		.map(Number)
		.filter(val => !isNaN(val));

	const result: number[][] = [];
	for (let i = 0; i < pointsArray.length; i += 2) {
		result.push([pointsArray[i], pointsArray[i + 1]]);
	}
	return result;
};

const isPointInPolygon = (x: number, y: number, polygon: number[][]): boolean => {
	let inside = false;
	for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
		const xi = polygon[i][0],
			yi = polygon[i][1];
		const xj = polygon[j][0],
			yj = polygon[j][1];

		const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
		if (intersect) inside = !inside;
	}
	return inside;
};

export const getPointAttributes = (x: number, y: number, layers?: ILayer[]) => {
	if (layers) {
		for (const layer of layers) {
			for (const shape of layer.shapes || []) {
				const polygon = extractPoints(shape.svg);
				if (isPointInPolygon(x, y, polygon)) {
					return {sector: shape.id, name: shape.name};
				}
			}
		}
	}
	return {sector: undefined, name: 'Точка'};
};
