import { Dispatch, useCallback } from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {usePlans, usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';

interface IObjectPlansSelectProps<S, E> {
	field?: keyof S;
	value?: string;
	objectId: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: string | undefined,
	types: IPlan[],
	typesMap: Record<string, IPlan>
) => {
	if (!value) {
		return undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

export const ObjectPlansSelect = <
	S extends {
		planId?: string;
	},
	E
>({
	className,
	style,
	field = 'planId',
	label = 'План',
	value,
	objectId,
	editingDispatch,
	required,
	disabled,
	hasError
}: IObjectPlansSelectProps<S, E>) => {
	const {data: plans} = usePlansAsArray(objectId);
	const {data: plansMap} = usePlans(objectId);

	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	return plansMap && plans ? (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, plans, plansMap.byId)}
						icon={<i className="tz-simple-arrow-20" />}
						error={required && hasError ? 'Выберите план' : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{plans.map(plan => (
						<SelectOption
							key={plan.id}
							itemKey={plan.id}
							content={plan.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	) : null;
};
