import {useStrictParams} from '@src/core/hooks/useStrictParams';
import StatusesFilterPage from '../StatusesFilterPage';
import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';

const CompaniesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const {data: companies} = useCompaniesAsArray();

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={companies}
			label={t('entitiesFilters.companiesFilter.label')}
			filterName="companies"
		/>
	);
};

export default CompaniesFilterPage;
