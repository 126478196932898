import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {ISavingProblemQuery} from '@src/core/hooks/mutations/problem/useAddProblem';
import {toListProblem} from '@src/core/hooks/mutations/problem/utils/convertToLocalSave';
import {store} from '@src/store/appStore';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';

export const getCachedProblem = (key: string) => {
	const state = store.getState();
	const user = extractUserProfile(state);
	const cachedProblem = queryClient.getQueryData<ISavingProblemQuery>(
		problemsQueryKeys.savingData(key)
	);
	if (cachedProblem) {
		const {object, links, stage, fields, number} = cachedProblem;
		const convertedProblem = toListProblem(object, links, stage, fields, key, number, user);
		return convertedProblem;
	}
	return undefined;
};
