import {httpRequests} from '@tehzor/tools/api/httpRequests';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';

/**
 * Генерирует слои осей для плана
 *
 * @param objectId id объекта
 * @param planId id плана
 */
export const getAxisMarkupRequest = async (objectId: string, planId: string) => {
	const params = {objectId, planId};

	const response = await httpRequests.withToken.post<{layers: ILayer[]}>(
		'plans/axis-markup', params
	);
	return response.data;
};
