import {useChecksMutationDefaults} from './useChecksMutationDefaults';
import {useChecksQueryDefaults} from './useChecksQueryDefaults';

/**
 * Хук для передачи дефолтных функций в QueryClient
 */
export function useChecksCacheDefaults() {
	useChecksMutationDefaults();
	useChecksQueryDefaults();
}
