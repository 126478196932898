import * as types from './constants';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ObjectsDisplayMode} from '@src/interfaces/ObjectsDisplayMode';
import {createReducer} from '@reduxjs/toolkit';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangeDisplayModePayload, IChangeGroupVisiblePayload} from './actions';

export interface IObjectsFiltersState extends Record<string, unknown> {
	object?: [];
	stages?: ObjectStageIds[];
	cities?: string[];
	companies?: string[];
}

export type IObjectsSortState = Record<string, boolean>;

interface IObjectsPageSettingsState {
	filters: IObjectsFiltersState;
	sort: IObjectsSortState;
	displayMode: ObjectsDisplayMode;
	visibleGroups: {
		[companyId: string]: boolean;
	};
}

const getInitialState = (): IObjectsPageSettingsState => ({
	filters: {},
	sort: {name: true},
	displayMode: ObjectsDisplayMode.SCHEMA,
	visibleGroups: {}
});

export const objects = createReducer<IObjectsPageSettingsState>(getInitialState(), {
	[types.CHANGE_FILTERS]: (
		state,
		{payload}: {payload: IChangeFiltersPayload<IObjectsFiltersState>}
	) => {
		state.filters = payload.filters;
	},
	[types.CLEAR_FILTERS]: state => {
		state.filters = {};
	},
	[types.CHANGE_SORT]: (state, {payload}: {payload: IChangeSortPayload<IObjectsSortState>}) => {
		state.sort = payload.sort;
	},
	[types.CHANGE_DISPLAY_MODE]: (state, {payload}: {payload: IChangeDisplayModePayload}) => {
		state.displayMode = payload.displayMode;
	},
	[types.CHANGE_GROUP_VISIBLE]: (state, {payload}: {payload: IChangeGroupVisiblePayload}) => {
		const {companyId, value} = payload;
		state.visibleGroups[companyId] = value || false;
	}
});
