import { ReactElement } from 'react';
import {TextField} from '../../inputs';
import {IAnyEntity} from '@tehzor/tools/interfaces/IAnyEntity';
import {ITextSearchProvider} from '../interfaces';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import {MultipleSearchSelect} from './components/MultipleSearchSelect';
import {SingleSearchSelect} from './components/SingleSearchSelect';
import './SearchSelect.less';

export type ISearchSelectProps<T extends IAnyEntity> = {
	className?:
		| string
		| {
				root?: string;
				textField?: string;
				select?: string;
		  };
	provider: ITextSearchProvider<T>;
	renderItem: (item: T) => ReactElement;
} & (
	| {
			multiple: true;
			onSelect: (value: T[] | undefined) => void;
	  }
	| {
			multiple?: false;
			onSelect: (value: T | undefined) => void;
	  }
);

export const SearchSelect = <T extends IAnyEntity>(props: ISearchSelectProps<T>) => {
	const {className, multiple, provider, onSelect, renderItem} = props;
	const classes = convertClassNames(className);

	return (
		<div className={classNames('search-select', classes.root)}>
			<TextField
				className={classNames('search-select__input', classes.textField)}
				elementType="input"
				value={provider.text}
				icon={<i className="tz-search-20"/>}
				onChange={provider.handleSearch}
			/>
			{multiple ? (
				<MultipleSearchSelect
					className={classes.select}
					provider={provider}
					onSelect={onSelect as (value: T[] | undefined) => void}
					renderItem={renderItem}
				/>
			) : (
				<SingleSearchSelect
					className={classes.select}
					provider={provider}
					onSelect={onSelect as (value: T | undefined) => void}
					renderItem={renderItem}
				/>
			)}
		</div>
	);
};
