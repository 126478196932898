import {useMemo} from 'react';
import {useCompaniesContractorsAsArray } from '@src/core/hooks/queries/companies/hooks';

/**
 * Фильтрует подрядчиков исходя из установленных фильтров и наличия статистики.
 * Также возвращает сотрудников и статистику, для избежания повторных вычислений в других местах.
 */
export function useFilteredContractors<CT, UT>(
	statsByContractors: {
		[id: string]: {stats: CT; usersStats: UT};
	},
	filters: {contractors?: Record<string, true | string[]>},
	isFiltersSetUp: boolean
) {
	const {data: contractors} = useCompaniesContractorsAsArray();

	return useMemo(() => {
		const result = [];
		if (contractors) {
			for (const contractor of contractors) {
				const contractorStats = statsByContractors[contractor.id];
				const allContractorUsersFromStats = Object.keys(contractorStats?.usersStats || {});
				const users = contractor.employees
					? contractor.employees.reduce<string[]>((prev, {userId}) => {
						if (isFiltersSetUp) {
							const filter = filters.contractors?.[contractor.id];
							if (filter) {
								if (
									(Array.isArray(filter) && filter.includes(userId)) ||
									(typeof filter === 'boolean' && filter)
								) {
									prev.push(userId);
								}
							} else if (contractorStats?.usersStats[userId]) {
								prev.push(userId);
							}
						} else {
							prev.push(userId);
						}
						return prev;
					}, [])
					: [];

				if (
					!isFiltersSetUp ||
					(filters.contractors && Object.keys(filters.contractors).length
						? filters.contractors[contractor.id]
						: contractorStats || users.length)
				) {
					result.push({
						contractor,
						users,
						deletedUsers: allContractorUsersFromStats.filter(id => !users.includes(id)),
						stats: contractorStats
					});
				}
			}
		}

		return result;
	}, [contractors, statsByContractors, isFiltersSetUp, filters.contractors]);
}
