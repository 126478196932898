import classNames from 'classnames';
import * as React from 'react';
import './AppInfo.less';

interface IAppInfoProps {
	className?: string;
	style?: React.CSSProperties;
	name?: string;
	version?: string;
	company?: string;
	startYear?: number;
}

const AppInfo = (props: IAppInfoProps) => {
	const {className, style, name = '', version = '', company = '', startYear} = props;

	const about = `${name} ${version}`;
	const currentYear = new Date().getFullYear();
	const copyright = `© ${company}, ${startYear && startYear !== currentYear ? `${startYear}–` : ''}${currentYear}`;

	return (
		<div
			className={classNames('app-info', className)}
			style={style}
		>
			<div className="app-info__about">{about}</div>
			<div className="app-info__copyright">{copyright}</div>
		</div>
	);
};

export default AppInfo;
