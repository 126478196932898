import {useCompaniesById} from '@src/core/hooks/queries/companies/hooks';
import {IPreparedSpaceStatusesSet} from '@src/pages/manage/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {CellProps} from 'react-table';

export const CompanyCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) => {
	const isTablet = useIsTablet();

	const {data: companiesMap} = useCompaniesById();
	const company = row.original?.companyId;

	return company && companiesMap ? (
		<div className="space-statuses-sets__cell-company">
			{!isTablet && <div className="space-statuses-sets__cell-company-title">Компания:</div>}

			<div className="space-statuses-sets__cell-company-name">
				{companiesMap[company]?.name}
			</div>
		</div>
	) : null;
};
