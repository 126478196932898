import {queryClient} from '@src/api/QueryClient';
import {requestDeleteManySpaceStatuses} from '@src/api/backend/spaceStatuses';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useMutation} from '@tanstack/react-query';

interface IDeleteManySpaceStatuses {
	spaceStatusesSetId: string;
}

export const useDeleteManySpaceStatuses = () =>
	useMutation({
		mutationFn: (params: IDeleteManySpaceStatuses) => {
			const {spaceStatusesSetId} = params;
			return requestDeleteManySpaceStatuses(spaceStatusesSetId);
		},
		mutationKey: spaceStatusesQueryKeys.deleteMany(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
		}
	});
