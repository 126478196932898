import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddProblemTagResponse = IProblemTag;

/**
 * Добавляет метку
 *
 * @param problemTagsSetId id набора
 * @param tag данные для добавления
 */
export const makeProblemTagAddRequest = async (tag: ISavingProblemTag) => {
	const response = await httpRequests.withToken.post<IAddProblemTagResponse>(
		'/problem-tags/add',
		tag
	);
	return response.data;
};
