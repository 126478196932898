import './Dates.less';
import {EntityGridItem} from '@tehzor/ui-components';
import {IDateProps, SingleDate} from './components/SingleDate';
import {useTranslation} from 'react-i18next';

interface IDatesProps {
	dates?: IDateProps[];
}

const calendarIcon = <i className="tz-calendar-20" />;

export const Dates = ({dates}: IDatesProps) => {
	const {t} = useTranslation();
	return dates ? (
		<EntityGridItem
			className="fix-date-view-field"
			icon={calendarIcon}
			label={t('spacePage.additionalInfo.dates.label')}
			fullRow
		>
			{dates.map((date, index) => (
				<SingleDate
					key={index}
					value={date.value}
					label={date.label}
				/>
			))}
		</EntityGridItem>
	) : null;
};
