import { Dispatch, useCallback, useMemo, useState } from 'react';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	Select2,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components/src/components/inputs';
import {Button, Dialog} from '@tehzor/ui-components';
import './EditableStages.less';
import classNames from 'classnames';
import {
	IEditableProblemTagsSetAction,
	IEditableProblemTagsSetState
} from '@src/core/hooks/states/useEditableProblemTagsSetState';
import {
	useObjectStagesAsArray,
	useObjectStagesMap
} from '@src/core/hooks/queries/objectStages/hooks';

interface IEditableStagesProps {
	required?: boolean;
	disabled?: boolean;
	editingState: IEditableProblemTagsSetState;
	editingDispatch: Dispatch<IEditableProblemTagsSetAction>;
}

const editIcon = <i className="tz-edit-20" />;

const getTitle = (map: {[id: string]: IObjectStage}, value: ObjectStageIds[] | undefined) =>
	value && value.length > 0 ? value.map(id => map[id].name).join(', ') : '';

const EditableStages = ({
	editingState,
	editingDispatch,
	required,
	disabled
}: IEditableStagesProps) => {
	const {data: allStages} = useObjectStagesAsArray();
	const {data: stagesMap} = useObjectStagesMap();
	const [stages, setStages] = useState<ObjectStageIds[] | undefined>(editingState.stages);
	const [isOpen, setOpen] = useState(false);

	const hasError = useMemo(() => editingState.errors.stages, [editingState.errors.stages]);

	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => setOpen(false), []);

	const handleChange = useCallback((v: ObjectStageIds[]) => {
		setStages(v);
	}, []);

	const handleConfirm = useCallback(() => {
		editingDispatch({type: 'update', field: 'stages', value: stages});

		if (required) {
			editingDispatch({type: 'update-error', field: 'stages'});
		}
		handleClose();
	}, [editingDispatch, handleClose, required, stages]);

	const handleCancel = useCallback(() => {
		setStages(editingState.stages);
		handleClose();
	}, [editingState.stages, handleClose]);
	if (!stagesMap) return null;
	return (
		<>
			<TextFieldWithForwardedRef
				className={classNames('editable-stages__text-field', {disabled})}
				onClick={handleOpen}
				elementType="div"
				value={getTitle(stagesMap, stages)}
				icon={editIcon}
				error={required && hasError ? 'Выберите стадию объекта' : undefined}
				disabled={disabled}
			/>
			<Dialog
				shouldCloseOnEsc
				isOpen={isOpen}
				fullScreenOnMobile
				onRequestClose={handleCancel}
				title="Стадии"
				footer={
					<div className="editable-stages__actions">
						<Button
							label="Применить"
							type="accent-blue"
							onClick={handleConfirm}
						/>
						<Button
							label="Сбросить"
							type="cancel"
							onClick={handleCancel}
						/>
					</div>
				}
			>
				<Select2
					value={stages}
					onChange={handleChange}
					multiple
				>
					{allStages &&
						allStages.map(stage => (
							<SelectOption
								key={stage.id}
								itemKey={stage.id}
								content={stage.name}
								inputType="checkbox"
							/>
						))}
				</Select2>
			</Dialog>
		</>
	);
};

export default EditableStages;
