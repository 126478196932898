import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {UserInfo, WorkingGroupInfo} from '@tehzor/ui-components';

interface IActiveDelegationInfoProps {
	respUsersIds?: string[];
	activeGroup?: IWorkingGroup;
}

export const ActiveDelegationInfo = ({respUsersIds, activeGroup}: IActiveDelegationInfoProps) => {
	const {data: usersMap} = useUsersAsMap();
	const activeGroupLeader = activeGroup && usersMap ? usersMap[activeGroup.leader] : undefined;

	const respUsers: IBriefUser[] = [];
	if (usersMap && respUsersIds?.length) {
		for (const userId of respUsersIds) {
			const user = usersMap[userId];
			if (!user) {
				continue;
			}
			respUsers.push(user);
		}
	}

	if (respUsers?.length) {
		return (
			<>
				{respUsers.map(user => (
					<UserInfo
						key={user.id}
						className="responsibles-view-field__user"
						user={user}
						avatarSize="40"
						avatarColoring="text"
						showCompany
					/>
				))}
			</>
		);
	}

	if (activeGroup) {
		return (
			<WorkingGroupInfo
				className="responsibles-view-field__group"
				name={activeGroup.name}
				leader={activeGroupLeader}
			/>
		);
	}

	return null;
};
