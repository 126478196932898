import {IGetMarkersResponse} from '@src/api/backend/markers';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractMarkersAsArray} from '@src/core/hooks/queries/markers/selectors';
import {markersQueryKeys} from '@src/api/cache/markers/keys';
import {useCallback, useMemo} from 'react';

export const useMarkers = <T = IGetMarkersResponse>(
	planId?: string,
	problemIds?: string[],
	inspectionIds?: string[],
	ownerAcceptanceIds?: string[],
	taskIds?: string[],
	select?: (data: IGetMarkersResponse) => T
) =>
	useQuery<IGetMarkersResponse, IError, T>({
		queryKey: markersQueryKeys.list(
			planId,
			problemIds,
			inspectionIds,
			ownerAcceptanceIds,
			taskIds
		),
		meta: {
			error: 'Ошибка получения маркеров'
		},
		select
	});

interface IMarkersAsArrayProps {
	planId?: string;
	problemIds?: string[];
	inspectionIds?: string[];
	ownerAcceptanceIds?: string[];
	taskIds?: string[];
}

export const useMarkersAsArray = ({
	planId,
	problemIds,
	inspectionIds,
	ownerAcceptanceIds,
	taskIds
}: IMarkersAsArrayProps) => {
	const entityIds: string[] = useMemo(() => {
		if (planId) {
			return [planId];
		}
		if (problemIds) {
			return [...problemIds];
		}
		if (inspectionIds) {
			return [...inspectionIds];
		}
		if (ownerAcceptanceIds) {
			return [...ownerAcceptanceIds];
		}
		if (taskIds) {
			return [...taskIds];
		}
		return [];
	}, [inspectionIds, ownerAcceptanceIds, planId, problemIds, taskIds]);

	const select = useCallback(
		(data: IGetMarkersResponse) => extractMarkersAsArray(data, entityIds),
		[entityIds]
	);

	return useMarkers(planId, problemIds, inspectionIds, ownerAcceptanceIds, taskIds, select);
};
