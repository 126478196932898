import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';

export const useModeData = () => {
	const {t} = useTranslation();

	return useMemo(
		() => [
			{id: 'year', name: t('tasksPage.mode.year')},
			{id: 'month', name: t('tasksPage.mode.month')},
			// {id: 'month', name: t('tasksPage.mode.week')},
			// {id: 'month', name: t('tasksPage.mode.day')},
		],
		[t]
	);
};
