import {wsConnector} from '@src/api/backend/wsConnector';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {ISendResponse} from '@src/interfaces/ISendResponse';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на отправку экспортированной передачи собственнику
 */
export const makeExportedOwnerAcptSendRequest = (
	email: string,
	templateId: string,
	objectId: string,
	acceptanceId: string,
	problems?: string[],
	createDocument?: boolean
) => {
	const params = {
		email: getEmailsArray(email),
		templateId,
		objectId,
		acceptanceId,
		problems,
		timezone: getTimezone(),
		createDocument
	};
	return wsConnector.sendAuthorizedRequest<ISendResponse>('sendExportedOwnerAcceptance', params);
};
