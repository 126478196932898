import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IEditProfileResponse, makeProfileEditRequest} from '@src/api/backend/profile';
import ISavingUserProfile from '@tehzor/tools/interfaces/users/ISavingUserProfile';

export type IEditProfilePayload = IEditProfileResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditProfileResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => ({
	type: types.EDIT_FAILURE,
	payload: error
});

/**
 * Изменяет данные текущего пользователя
 *
 * @param fields измененные поля
 */
export const editProfile = (fields: ISavingUserProfile) =>
	createApiAction<IEditProfileResponse>(request, success, failure, () =>
		makeProfileEditRequest(fields));
