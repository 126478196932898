import {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import {useExtractCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';

interface IUseTemplateSelectHandlerOptions {
	objectId: string;
	stage: ObjectStageIds;
	editingDispatch: Dispatch<IEditableEntityAction<any, any>>;
}

export const useTemplateSelectHandler = ({
	objectId,
	stage,
	editingDispatch
}: IUseTemplateSelectHandlerOptions) => {
	const {data: allCategories} = useExtractAllCategoriesAsArray();
	const {data: categoriesSets} = useExtractCategoriesSetsAsArray(objectId, stage, allCategories);
	const categories = categoriesSets?.map(set => set.categories).flat();
	return useCallback(
		(selected?: IProblemTemplate) => {
			if (!selected) {
				return;
			}

			editingDispatch({type: 'update', field: 'templateId', value: selected.id});
			editingDispatch({type: 'update', field: 'description', value: selected.description});

			if (selected.critical) {
				editingDispatch({
					type: 'update',
					field: 'critical',
					value: true
				});
			}

			if (selected.fixDate || selected.critical) {
				// Фиксируем "Срок устранения" в 24 часа для случаев,
				// когда в шаблоне установлена галочка "Критично" без поля fixDate(plannedFixDate)
				const offset = selected.fixDate ? selected.fixDate : 1;

				editingDispatch({
					type: 'update',
					field: 'plannedFixDate',
					value: new Date().getTime() + offset * 24 * 60 * 60 * 1000
				});
			}

			if (selected.prescription) {
				editingDispatch({
					type: 'update',
					field: 'prescription',
					value: selected.prescription
				});
			}

			if (selected.reason) {
				editingDispatch({
					type: 'update',
					field: 'reason',
					value: {value: selected.reason.value}
				});
			}

			if (selected.categoryId) {
				const found = categories?.find(
					category => category.name.trim() === selected.categoryId?.trim()
				);

				if (found) {
					editingDispatch({
						type: 'update',
						field: 'categoryId',
						value: found.id
					});
				}
			}
		},
		[categories, editingDispatch]
	);
};
