import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import MobileRightButtons from './components/actions/RightButtons.mobile';
import {useTranslation} from 'react-i18next';
import './TaskViewPage.less';
import {useEnrichedTask} from "@src/core/hooks/queries/task/enrichedTask/hooks";

const TaskViewPage = () => {
	const {t} = useTranslation();
	const {taskId} = useStrictParams<{taskId: string}>();
	useScrollRestoration();

	const {data: enrichedTask, isLoading} = useEnrichedTask(taskId);

	useAppHeader(
		{
			title: enrichedTask && !isLoading ? t('taskViewPage.title', {name: enrichedTask.name}) : '',
			showBackBtn: true,
			mobileRightButtons:
				enrichedTask && !isLoading ? (
					<MobileRightButtons
						taskId={taskId}
						objectId={enrichedTask.objectId?.id}
						taskType={enrichedTask.taskType?.id}
					/>
				) : null
		},
		[enrichedTask, isLoading]
	);

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				task={enrichedTask}
				loading={isLoading}
			/>
		);
	}

	return (
		<Mobile
			task={enrichedTask}
			loading={isLoading}
		/>
	);
};

export default TaskViewPage;
