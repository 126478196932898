import { Dispatch } from 'react';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import Text from '@src/components/editableFields/Text';
import CompanySelect from '@src/components/editableFields/CompanySelect';
import './EditableProblemTagsSet.less';
import {ObjectsSelect} from '../editableFields/ObjectsSelect';
import {isPropEdited} from '@tehzor/tools/core/states/editableEntityState';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {
	IEditableProblemTagsSetState,
	IEditableProblemTagsSetAction
} from '@src/core/hooks/states/useEditableProblemTagsSetState';
import EditableStages from './components/EditableStages/EditableStages';

interface IEditableProblemTagsSetProps {
	editingState: IEditableProblemTagsSetState;
	editingDispatch: Dispatch<IEditableProblemTagsSetAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	disabled?: boolean;
	problemTagsSet?: IProblemTagsSet;
}

export const EditableProblemTagsSet = (props: IEditableProblemTagsSetProps) => {
	const {
		editingState,
		editingDispatch,
		fieldsSettings,
		disabled,
		problemTagsSet
	} = props;

	return (
		<div className="editable-problem-tags-set">
			{fieldsSettings.name !== undefined && (
				<Text
					field="name"
					label="Название набора"
					value={editingState.name}
					editingDispatch={editingDispatch}
					required={fieldsSettings.name.isRequired}
					disabled={disabled}
					hasError={editingState.errors.name}
				/>
			)}

			{fieldsSettings.companyId !== undefined && (
				<div>
					<CompanySelect
						field="companyId"
						label="Компания"
						value={editingState.companyId}
						editingDispatch={editingDispatch}
						required={fieldsSettings.companyId.isRequired}
						disabled={disabled}
						hasError={editingState.errors.companyId}
					/>
					{problemTagsSet && isPropEdited('companyId', editingState, problemTagsSet) && (
						<div className="editable-problem-tags-set__warning">
							Изменения приведут к удалению связанных с объектами компании меток
						</div>
					)}
				</div>
			)}

			{fieldsSettings.objects !== undefined && (
				<div>
					<ObjectsSelect
						field="objects"
						label="Объекты"
						value={editingState.objects}
						companyId={editingState.companyId}
						editingDispatch={editingDispatch}
						required={fieldsSettings.objects.isRequired}
						disabled={disabled}
						hasError={editingState.errors.objects}
					/>
					{problemTagsSet && isPropEdited('objects', editingState, problemTagsSet) && (
						<div className="editable-problem-tags-set__warning">
							Изменения приведут к удалению меток связанных с удаленными объектами
						</div>
					)}
				</div>
			)}
			{
				fieldsSettings.stages !== undefined && (
					<EditableStages
						editingDispatch={editingDispatch}
						editingState={editingState}
						disabled={disabled}
					/>
)
			}
		</div>
	);
};
