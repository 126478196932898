import {Column} from 'react-table';
import {CompanyCell} from './CompanyCell';
import {IPreparedSpaceTypeDecoration} from '../../interfaces/IPreparedSpaceTypeDecoration';
import {MenuCell} from './MenuCell';
import {ObjectsCell} from './ObjectsCell';

export const desktopColumns: Array<Column<IPreparedSpaceTypeDecoration>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Наименование',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},
	{
		id: 'companies',
		Header: 'Компания',
		accessor: 'companyId',
		Cell: CompanyCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'objects',
		Header: 'Объекты',
		accessor: 'objects',
		Cell: ObjectsCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'createdBy',
		Header: 'Автор',
		accessor: 'createdBy',
		width: 80,
		minWidth: 80
	},
	{
		id: 'createdAt',
		accessor: 'createdAt',
		Header: 'Создание',
		width: 80,
		minWidth: 80
	},
	{
		id: 'modifiedAt',
		accessor: 'modifiedAt',
		Header: 'Изменение',
		width: 80,
		minWidth: 80
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		className: 'checks-page__d-table-menu'
	}
];
