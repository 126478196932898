import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useMemo} from 'react';

export interface IPermissions {
	canAddCheck?: boolean;
	canAddInspection?: boolean;
	canExport?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param user текущий пользователь
 * @param objectId id объекта
 */
const getPermissions = (user?: IUser, objectId?: string): IPermissions => {
	if (!user || !user.roles) {
		return {};
	}
	return objectId
		? {
				canAddCheck: hasPermission(
					user.roles,
					'checksAdd',
					UserRoleScopes.OBJECT,
					objectId
				),
				canAddInspection: hasPermission(
					user.roles,
					'checkInspectionsAdd',
					UserRoleScopes.OBJECT,
					objectId
				),
				canExport: hasPermission(
					user.roles,
					'inspectionsExport',
					UserRoleScopes.OBJECT,
					objectId
				)
		  }
		: {
				canAddCheck: false,
				canAddInspection: false,
				canExport: hasPermission(user.roles, 'inspectionsExport') // canExport: false
		  };
};

/**
 * Хук для возврата кешированных полномочий текущего пользователя
 *
 * @param user текущий пользователь
 * @param objectId id объекта
 */
export const usePermissions = (user?: IUser, objectId?: string): IPermissions =>
	useMemo(() => getPermissions(user, objectId), [user, objectId]);
