import {Column} from 'react-table';
import {StatsCell} from './StatsCell';
import {MenuCell} from './MenuCell';
import {IPreparedInternalAcceptance} from '../../interfaces/IPreparedInternalAcceptance';
import {CreatedCell} from './CreatedCell';
import {ModifiedCell} from './ModifiedCell';
import {TFunction} from "i18next";

export const getDesktopColumns = (tFunc: TFunction): Array<Column<IPreparedInternalAcceptance>> => (
	[
		{
			Header: '№',
			accessor: row => row.number ?? row.localNumber,
			width: 60
		},
		{
			id: 'object',
			Header: tFunc('internalAcceptancesPage.table.columns.object.header'),
			accessor: row => row.object?.name,
			width: 100,
			disableSortBy: true,
			defaultCanSort: false
		},
		{
			id: 'description',
			Header: tFunc('internalAcceptancesPage.table.columns.description.header'),
			accessor: row => row.description,
			width: 200,
			disableSortBy: true,
			defaultCanSort: false
		},
		{
			id: 'createdAt',
			Header: tFunc('internalAcceptancesPage.table.columns.createdAt.header'),
			accessor: row => row.createdAt,
			Cell: CreatedCell,
			width: 100,
			sortDescFirst: true
		},
		{
			id: 'modifiedAt',
			Header: tFunc('internalAcceptancesPage.table.columns.modifiedAt.header'),
			accessor: row => row.modifiedAt,
			Cell: ModifiedCell,
			width: 100,
			sortDescFirst: true
		},
		{
			id: 'stats',
			Header: tFunc('internalAcceptancesPage.table.columns.stats.header'),
			Cell: StatsCell,
			width: 120
		},
		{
			id: '_menu',
			Cell: MenuCell,
			className: 'internalAcceptances-page__d-table-menu',
			width: 68,
			minWidth: 68,
			defaultCanSort: false,
			disableResizing: true,
			isNonClickable: true
		}
	]
);

export const getDesktopColumnsWithoutObject = (tFunc: TFunction) => getDesktopColumns(tFunc).filter(item => item.id !== 'object');
