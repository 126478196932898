import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import {CircleLoader} from '../../../loaders';
import {CircleButton} from '../../../buttons';

interface ICenterProps {
	progress: number;
	status: UploadingFileStatus;
	disabled?: boolean;

	onReloadClick?: () => void;
}

const Icon = (props: ICenterProps) => {
	const {progress, status, disabled, onReloadClick} = props;

	if ((status === UploadingFileStatus.WAITING || status === UploadingFileStatus.STARTED)) {
		return (
			<CircleLoader
				className="uploading-image__center uploading-image__loader"
				percent={status === UploadingFileStatus.STARTED ? progress : undefined}
				trackColor="rgba(255, 255, 255, 0.45)"
				progressColor="#FFFFFF"
			/>
		);
	}
	if (status === UploadingFileStatus.ERROR) {
		return (
			<CircleButton
				className="uploading-image__reload-btn uploading-image__center"
				type="common"
				disabled={disabled}
				onClick={onReloadClick}
			>
				<i className="tz-reload-24"/>
			</CircleButton>
		);
	}
	if (status === UploadingFileStatus.LOCAL) {
		return (
			<div className="uploading-image__center uploading-image__warning-icon">
				<i className="tz-circle-warning-32 uploading-image__center"/>
			</div>
		);
	}
	return null;
};

export default Icon;