import { useCallback } from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {inspectionsActions} from "@src/store/modules/settings/pages/inspections/slice";
import {IInspectionsFiltersState} from "@src/store/modules/settings/pages/inspections/interfaces";

const InspectionsFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const {changeFilters, changeOffset, clearFilters} = inspectionsActions;

	const applyFilters = useCallback(
		(value: IInspectionsFiltersState) => {
			dispatch(changeFilters({objectId: objectId || 'all', filters: value}));
			dispatch(changeOffset({ objectId: objectId || 'all', offset: 0}));
		},
		[objectId, changeFilters]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: objectId || 'all'}));
	}, [clearFilters, dispatch, objectId]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};

export default InspectionsFiltersRoutingPage;
