import {formInitialExpanded} from '@tehzor/tools/utils/tree/formInitialExpanded';
import {Tree} from 'array-to-tree';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';

export function formInitialExpandedGroups(
	groups: Array<Tree<IEnrichedWorkingGroup>>,
	selectedUsers?: string[],
	selectedGroup?: string,
	expandedGroups?: string[]
) {
	const expanded = formInitialExpanded(
		groups,
		expandedGroups,
		undefined,
		selectedGroup ? [selectedGroup] : undefined,
		undefined,
		true
	);
	if (selectedGroup && selectedUsers?.length) {
		expanded.push(selectedGroup);
	}
	return expanded;
}

/**
 * Возвращает список всех групп ответственных, которые в последующем будут раскрыты (expanded) в интерфейсе
 *
 * @param groups рабочие группы ответственных, обогащенные данные
 */
export const formAllExpandedGroups = (
	groups: Array<Tree<IEnrichedWorkingGroup>>
): string[] => {
	let result: string[] = [];
	for (const groupItem of groups) {
		if (groupItem.id) {
			result = result.concat(groupItem.id);
		}

		if (groupItem.children?.length) {
			result = result.concat(formAllExpandedGroups(groupItem.children));
		}
	}
	return result;
};
