import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useContractsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useContractsBreadcrumbs';
import {useContractsPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import {useContractsPaginate} from '@src/core/hooks/queries/contracts/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {extractContractsPageSettings} from '@src/store/modules/settings/pages/contracts/selectors';
import {addErrorToast} from '@src/utils/toasts';
import {LoadingPanel} from '@tehzor/ui-components';
import { useEffect } from 'react';
import './ContractsPage.less';
import {ContractsFilters} from './components/ContractsFilters';
import {ContractsPageHeader} from './components/ContractsPageHeader';
import {AddButton} from './components/actions/AddButton';
import {ContractsTable} from './components/table/ContractsTable';

export const ContractsPage = () => {
	useScrollRestoration();

	const {canAdministrate, canAdd} = useContractsPermissions();

	useEffect(() => {
		if (!canAdministrate) {
			addErrorToast('Отсутствие доступа', 'к просмотру страницы');
		}
	}, [canAdministrate]);

	const breadcrumbs = useContractsBreadcrumbs();
	const {data, isFetching} = useContractsPaginate();
	const {selectedRows, sort} = useAppSelector(extractContractsPageSettings);

	if (!canAdministrate) {
		return null;
	}
	return (
		<LoadingPanel active={isFetching}>
			<div className="contracts">
				<AppBreadcrumbs
					className="contracts__breadcrumbs"
					items={breadcrumbs}
				>
					<AddButton canAdd={canAdd} />
				</AppBreadcrumbs>
				{data && (
					<>
						<div className="contracts__actions">
							<ContractsFilters />
							<ContractsPageHeader total={data.total} />
						</div>
						<ContractsTable
							offset={data.offset}
							total={data.total}
							selectedRows={selectedRows}
							sort={sort}
						/>
					</>
				)}
			</div>
		</LoadingPanel>
	);
};
