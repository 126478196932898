import {useCallback, useContext} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';
import {DispatchActionCtx} from '../OwnerAcceptancesTable';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';

const deleteIcon = <i className="tz-delete" />;

export const MenuCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => {
	const {canDelete} = row.original;

	const dispatchAction = useContext(DispatchActionCtx);

	const deleteOwnerAcceptance = useCallback(() => {
		dispatchAction({type: 'delete', payload: row.original});
	}, [row.original, dispatchAction]);

	const menuActions = [];

	if (canDelete) {
		menuActions.push(
			<IconButton
				key="delete"
				type="inline-blue-accent"
				onClick={deleteOwnerAcceptance}
			>
				{deleteIcon}
			</IconButton>
		);
	}

	return menuActions.length ? (
		<ClickPreventWrap className="owner-acceptances-page__d-table-menu-wrap">
			{menuActions}
		</ClickPreventWrap>
	) : null;
};
