import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {usePermissions} from '@src/pages/InspectionPage/hooks/usePermissions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteInspection} from '@src/store/modules/entities/inspection/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {useEditableInspectionDialog} from '@src/components/EditableInspectionDialog/hooks/useEditableInspectionDialog';
import {useInspectionHistoryDialog} from '@src/components/InspectionHistoryDialog/hooks/useProblemHistoryDialog';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IMenuProps {
	objectId: string;
	inspection: ILinkedInspection;
}

const historyIcon = <i className="tz-history-24" />;
const editIcon = <i className="tz-edit" />;
const deleteIcon = <i className="tz-delete" />;

const Menu = ({objectId, inspection}: IMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const permissions = usePermissions(objectId, inspection);
	const isDesktop = useIsDesktop();
	const {t} = useTranslation();

	// Диалог редактирования осмотра
	const [inspectionDialog, openEditDialog] = useEditableInspectionDialog(objectId, inspection);
	// Диалог истории изменений
	const [historyDialog, openHistoryDialog] = useInspectionHistoryDialog(objectId, inspection.id);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.inspection.deleteTitle'),
		message: t('useConfirmDialog.inspection.deleteMessage', {number: inspection?.number})
	});

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteInspection(objectId, inspection.id));
			goBack();
		}
	}, [objectId, inspection.id]);

	const items = [];
	if (!isDesktop) {
		items.push(updateData);
	}

	if (permissions.canViewHistory) {
		items.push(
			<MenuItem
				key="history"
				icon={historyIcon}
				onClick={openHistoryDialog}
			>
				{t('inspectionPage.menu.button.history')}
			</MenuItem>
		);
	}

	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={openEditDialog}
			>
				{t('inspectionPage.menu.button.edit')}
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				{t('inspectionPage.menu.button.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{permissions.canEdit && inspectionDialog}
			{permissions.canDelete && deleteDialog}
			{permissions.canViewHistory && historyDialog}
		</>
	) : null;
};

export default Menu;
