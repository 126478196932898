import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {PhotoPreview} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {IInspectionEntity} from "@src/interfaces/IInspectionsEntity";

interface IDescriptionCellProps extends CellProps<IInspectionEntity> {
	settings?: IEntitySettings;
}

const DescriptionCell = ({row, settings}: IDescriptionCellProps) => {

	const numberEl = (
		<div className="inspections-page__d-table-description-number">
			№{row.original.data.number || row.original.data.localNumber?.toUpperCase()}
		</div>
	);

	const descriptionEl = (
		<div className="inspections-page__d-table-description-text">
			 {row.original.data.description}
		</div>
	);

	const attachmentsEl = (
		<div className="inspections-page__d-table-description-image">
			{row.original.data.attachment?.preview && (
				<PhotoPreview
					url={row.original.data.attachment.preview.url}
					data={0}
				/>
			)}
		</div>
	);

	return (
		<div className="inspections-page__d-table-description">
			<div>
				<div className="inspections-page__d-table-description-header">
					{numberEl}
				</div>
				{settings?.builtin.description && descriptionEl}
			</div>
			{settings?.builtin.attachments && attachmentsEl}
		</div>
	);
};

export default DescriptionCell;
