import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceType} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetSpaceTypesResponse = INormalizedData<ISpaceType>;

/**
 * Возвращает список типов помещений
 */
export const requestSpaceTypes = async () => {
	const response = await httpRequests.withToken.get<IGetSpaceTypesResponse>('space-types');
	return response.data;
};
