import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/ISavingMeterConsumption';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';

export type IAddMeterConsumptionResponse = IMeterConsumption;

/**
 * Добавляет показание прибора учёта
 *
 * @param meterConsumption данные для добавления
 */
export const makeMeterConsumptionAddRequest = async (meterConsumption: ISavingMeterConsumption) => {
	const response = await httpRequests.withToken.post<IAddMeterConsumptionResponse>(
		'/meter-consumptions/add',
		{
			...meterConsumption
		}
	);
	return response.data;
};
