import {queryClient} from '@src/api/QueryClient';
import {requestDeleteSpaceStatus} from '@src/api/backend/spaceStatuses';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useMutation} from '@tanstack/react-query';

interface IDeleteSpaceStatusParams {
	id: string;
	spaceStatusesSetId: string;
}

export const useDeleteSpaceStatus = () =>
	useMutation({
		mutationFn: (params: IDeleteSpaceStatusParams) => {
			const {id, spaceStatusesSetId} = params;
			return requestDeleteSpaceStatus(id, spaceStatusesSetId);
		},
		mutationKey: spaceStatusesQueryKeys.delete(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
		}
	});
