import React, {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {MenuItem, MultipleMenu} from '@tehzor/ui-components';
import {IMultipleMenuProps} from '@tehzor/ui-components/src/components/menu/MultipleMenu';
import {ICommentVariants} from '@tehzor/tools/interfaces/comments/ICommentType';

const menuItems = {
	[ICommentVariants.REPLIES]: 'Ответы',
	[ICommentVariants.COMMENTS]: 'Комментарии'
};

const sortFn = (a: string, b: string) => (a > b ? -1 : 1);

/**
 * Формирует меню и отображаемое значение для фильтра видимости комментариев или ответов сущности
 */
export function useVisibilityFilterMenu(
	visibility: ICommentVariants[],
	changeCommentsVisibility: (value: ICommentVariants[]) => {
		type: string;
		payload: ICommentVariants[];
	}
): [React.ReactElement<IMultipleMenuProps>, string] {
	const dispatch = useAppDispatch();

	const handleChange = useCallback((keys: ICommentVariants[]) => {
		dispatch(changeCommentsVisibility(keys.sort(sortFn)));
	}, [changeCommentsVisibility, dispatch]);

	const label = visibility.length
		? visibility.map((key: string | number) => menuItems[key]).join(', ')
		: 'Выберите, что показать';

	const menu = (
		<MultipleMenu
			value={visibility}
			onChange={handleChange}
		>
			<MenuItem itemKey={ICommentVariants.REPLIES}>
				{menuItems[ICommentVariants.REPLIES]}
			</MenuItem>
			<MenuItem itemKey={ICommentVariants.COMMENTS}>
				{menuItems[ICommentVariants.COMMENTS]}
			</MenuItem>
		</MultipleMenu>
	);

	return [menu, label];
}
