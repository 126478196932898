import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {useMemo} from 'react';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {useCompaniesById} from '@src/core/hooks/queries/companies/hooks';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useWorkingGroupsTypesById} from '@src/core/hooks/queries/workingGroupTypes/hooks';

/**
 * Преобразовывает рабочую группу в необходимый для вывода формат
 *
 * @param data массив групп
 */
export const useEnrichedGroups = (data: IWorkingGroup[] | undefined): IEnrichedWorkingGroup[] => {
	const {data: usersMap} = useUsersAsMap();
	const {data: companiesMap} = useCompaniesById();
	const {data: stagesMap} = useObjectStagesMap();
	const {data: typesMap} = useWorkingGroupsTypesById();
	return useMemo(
		() =>
			companiesMap && data
				? data.map(item => {
						const stages = item?.stages?.map(id => (stagesMap || {})[id]);
						return {
							...item,
							company: companiesMap[item.companyId] || undefined,
							stage: stages,
							type: item.type !== undefined ? typesMap?.[item.type] : undefined,
							leader: usersMap ? usersMap[item.leader] : undefined,
							performers: [],
							createdBy:
								item.createdBy && usersMap ? usersMap[item.createdBy] : undefined,
							createdAt: item.createdAt
								? format(item.createdAt, dateTimeCommaSeparatedFormat)
								: '',
							modifiedBy:
								item.modifiedBy && usersMap ? usersMap[item.modifiedBy] : undefined,
							modifiedAt: item.modifiedAt
								? format(item.modifiedAt, dateTimeCommaSeparatedFormat)
								: ''
						};
				  })
				: [],
		[data, usersMap, companiesMap, stagesMap]
	);
};
