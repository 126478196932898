import {useMemo} from 'react';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import {extractInternalAcceptanceProblemCommentsByProblem} from '@src/store/modules/entities/internalAcceptance/selectors';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell';
import {Column} from 'react-table';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';

export const useMobileColumns = () =>
	useMemo<Array<Column<IInternalAcceptanceEntity>>>(
		() => [
			{
				id: 'info',
				Cell: CellWrap({
					problem: ProblemInfoCellWrap(
						ProblemInfoMobileCell,
						extractInternalAcceptanceProblemCommentsByProblem
					),
					inspection: InspectionInfoMobileCell
				})
			}
		],
		[]
	);
