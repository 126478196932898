import {useEffect, useMemo, useState} from 'react';
import {add, getDecade, getMonth, getYear, set, sub} from 'date-fns';
import {getCentury} from '../utils/getCentury';

export enum CalendarView {
	MONTH = 'month',
	YEAR = 'year',
	DECADE = 'decade',
	CENTURY = 'century'
}

export function useCalendarView(initialDate: Date) {
	const [date, setDate] = useState(initialDate);

	const [view, changeView] = useState<CalendarView>(CalendarView.MONTH);

	const [month, setCurrentMonth] = useState<number | undefined>(getMonth(initialDate));
	const [year, setCurrentYear] = useState<number | undefined>(getYear(initialDate));
	const [decade, setCurrentDecade] = useState<number | undefined>(getDecade(initialDate));
	const [century, setCurrentCentury] = useState<number>(getCentury(initialDate));

	useEffect(() => {
		setCurrentMonth(getMonth(date));
		setCurrentYear(getYear(date));
		setCurrentDecade(getDecade(date));
		setCurrentCentury(getCentury(date));
	}, [date]);

	return useMemo(() => {
		const changeMonth = (value: number) => {
			setDate(set(date, {month: value}));
			changeView(CalendarView.MONTH);
		};

		const prevMonth = () => {
			setDate(sub(date, {months: 1}));
		};

		const nextMonth = () => {
			setDate(add(date, {months: 1}));
		};

		const changeYear = (value: number) => {
			setDate(set(date, {year: value}));
			changeView(CalendarView.YEAR);
		};

		const prevYear = () => {
			setDate(sub(date, {years: 1}));
		};

		const nextYear = () => {
			setDate(add(date, {years: 1}));
		};

		const changeDecade = (value: number) => {
			setDate(set(date, {year: value}));
			changeView(CalendarView.DECADE);
		};

		const prevDecade = () => {
			setDate(sub(date, {years: 10}));
		};

		const nextDecade = () => {
			setDate(add(date, {years: 10}));
		};

		const changeCentury = (value: number) => {
			setDate(set(date, {year: value}));
			changeView(CalendarView.YEAR);
		};

		const prevCentury = () => {
			setDate(sub(date, {years: 100}));
		};

		const nextCentury = () => {
			setDate(add(date, {years: 100}));
		};

		return {
			view,
			month,
			year,
			decade,
			century,
			changeView,
			changeMonth,
			prevMonth,
			nextMonth,
			changeYear,
			prevYear,
			nextYear,
			changeDecade,
			prevDecade,
			nextDecade,
			changeCentury,
			prevCentury,
			nextCentury
		};
	}, [month, year, decade, century, view]);
}

export type CalendarViewResult = ReturnType<typeof useCalendarView>;
