import {TFunction} from 'i18next';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';
import LocationCell from '@src/pages/InspectionsPage/components/table/LocationCell';
import CellWrap from '@src/components/tableCells/CellWrap';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import TransferStatusCell from '@src/pages/InspectionsPage/components/table/TransferStatusCell';
import MenuCell from '@src/pages/InspectionsPage/components/table/MenuCell';
import {ColumnWithSettings} from '@src/core/hooks/settings/useColumnsWithSettings/interfaces';
import {InspectionsPerformersCell} from '../components/table/InspectionsPerformersCell';

export const getMobileColumns = (
	objectId: string,
	t: TFunction
): Array<ColumnWithSettings<IInspectionEntity>> => [
	{
		id: 'number',
		Header: '',
		accessor: row => row.data.number ?? row.data.localNumber,
		width: 65,
		className: 'inspections-page__m-table-number',
		customizeSettings: {
			controlled: false
		}
	},
	{
		id: 'object',
		Header: t('columns.object'),
		accessor: row => row.data.object?.name,
		width: 100,
		customizeSettings: {
			controlled: false
		}
	},
	{
		id: 'location',
		Header: t('columns.location'),
		Cell: LocationCell,
		width: 100,
		defaultCanSort: false,
		customizeSettings: {
			controlled: false
		}
	},
	{
		id: 'createdBy',
		Header: t('columns.createdBy'),
		accessor: row => row.data.createdBy,
		width: 100,
		customizeSettings: {
			controlled: false
		}
	},
	{
		id: 'categoryId',
		Header: t('columns.category'),
		Cell: CellWrap({
			inspection: CategoryCell
		}),
		width: 80,
		minWidth: 80,
		customizeSettings: {
			controlled: false
		}
	},
	{
		id: 'performers',
		Header: t('inspectionsPage.table.performersCell.label'),
		Cell: CellWrap({inspection: InspectionsPerformersCell}),
		width: 100,
		minWidth: 100,
		customizeSettings: {
			controlled: false
		}
	},
	{
		id: 'createdAt',
		Header: t('columns.createdAt'),
		accessor: row => row.data.createdAt,
		width: 100,
		sortDescFirst: true,
		customizeSettings: {
			controlled: false
		}
	},
	{
		id: 'modifiedAt',
		Header: t('columns.modifiedAt'),
		accessor: row => row.data.modifiedAt,
		width: 100,
		sortDescFirst: true,
		customizeSettings: {
			controlled: false
		}
	},
	{
		id: '_transferStatus',
		accessor: row => row.data.transferStatus,
		width: 260,
		Cell: TransferStatusCell,
		customizeSettings: {
			controlled: false
		}
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		className: 'inspections-page__m-table-menu',
		customizeSettings: {
			controlled: false
		}
	}
];
