import {IEditableCategory} from '@src/core/hooks/states/useCategoriesSetState';
import {createRecursiveIndex} from '@tehzor/tools/utils/createRecursiveIndex';
import {useIsBeforeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import { Dispatch, SetStateAction, memo, useMemo } from 'react';
import {CategoryDesktopOptions} from './CategoryDesktopOptions';
import {CategoryMobileOptions} from './CategoryMobileOptions';
import {CategoryName} from './CategoryName';
import classNames from 'classnames';

export interface ICategoryProps {
	item: IEditableCategory;
	dragItem?: IEditableCategory;
	items: IEditableCategory[];
	edited: string[];
	current?: string;

	setCurrent: Dispatch<SetStateAction<string | undefined>>;
	openCategoryDialog: (item: IEditableCategory) => void;
	onCreate: (parent?: IEditableCategory) => void;
	onRemove: (id: string) => void;
	onChangeEdit: (id: string) => void;
	onConfirm: (id: string) => void;
	onCancel: (id: string) => void;
	saveDrop?: () => void;
	moveRow?: (dragIndex: number, hoverIndex: number) => void;

	isEditable?: boolean;
}

export const Category = memo(
	({
		item,
		dragItem,
		items,
		edited,
		current,
		openCategoryDialog,
		onCreate,
		onRemove,
		onConfirm,
		onChangeEdit,
		onCancel,

		isEditable
	}: ICategoryProps) => {
		const isEdited = useMemo(() => edited?.includes(item.id), [edited, item.id]);
		const isCurrent = useMemo(() => current === item.id, [current, item.id]);
		const isMobile = useIsBeforeTablet();

		const optionsProps = {
			item,
			onCreate,
			onRemove,
			onConfirm,
			onCancel,
			isEditable,
			isEdited,
			isCurrent
		};

		const isDraggable = dragItem?.id === item.id;

		return (
			<div className={classNames('editable-categories__item', {isDraggable})}>
				{!isDraggable && (
					<p className="editable-categories__item-index">
						{createRecursiveIndex(item, items)}
					</p>
				)}
				<CategoryName
					item={item}
					isEdited={isEdited}
					onChangeEdit={onChangeEdit}
					openCategoryDialog={openCategoryDialog}
				/>
				{!isMobile ? (
					<CategoryDesktopOptions {...optionsProps} />
				) : (
					<CategoryMobileOptions {...optionsProps} />
				)}
			</div>
		);
	}
);
