import { memo } from 'react';
import DesktopLinks from './links/Links.desktop';
import Menu from './Menu';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';

interface IControlsProps {
	objectId: string;
	inspection: ILinkedInspection;
}

const DesktopControls = ({objectId, inspection}: IControlsProps) => (
	<div className="inspection-page__d-controls">
		<div className="inspection-page__d-controls-left">
			<DesktopLinks
				objectId={objectId}
				inspection={inspection}
			/>
		</div>

		<div className="inspection-page__d-controls-right">
			<Menu
				objectId={objectId}
				inspection={inspection}
			/>
		</div>
	</div>
);

export default memo(DesktopControls);
