import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {memo, useCallback} from 'react';
import {Main} from './Main/Main';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {IStructuresFiltersState, structuresActions} from '@/entities/Structures';
import {EntitiesPageWrapper} from '@/widgets/EntitiesPageWrapper/EntitiesPageWrapper';
import {Filters} from '../components/Filters/Filters';
import {StructuresTopBar} from './StructuresTopBar/StructuresTopBar';
import {StructuresCentralBarDesktop} from './StructuresCentralBarDesktop/StructuresCentralBarDesktop';
import {StructuresCentralBarMobile} from './StructuresCentralBarMobile/StructuresCentalBarMobile';
import {StructuresBottomBar} from './StructuresBottomBar/StructuresBootomBar';
import {Wrapper} from '@/shared/ui/EntitiesPage/Wrapper/Wrapper';
import {Controls} from '@/shared/ui/EntitiesPage/Controls/Controls';
import {ActionsMenu} from '../components/ActionsMenu/ActionsMenu';
import {extractCurrentStructuresPageSettings} from '@/entities/Structures/model/slice/selectors';

export const StructuresPage = memo(() => {
	const isDesktop = useIsDesktop();
	const {objectId, spaceId} = useStrictParams<{objectId: string; spaceId: string}>();
	const {filters} = useAppSelector(s => extractCurrentStructuresPageSettings(s, objectId));
	const {changeFilters, clearFilters, changeOffset} = structuresActions;
	const dispatch = useAppDispatch();

	const onApply = useCallback(
		(value: IStructuresFiltersState) => {
			dispatch(changeFilters({objectId, filters: value}));
			dispatch(changeOffset({objectId, offset: 0}));
		},
		[objectId, dispatch, changeFilters, changeOffset]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId}));
	}, [objectId, dispatch, clearFilters]);

	return (
		<EntitiesPageWrapper
			objectId={objectId}
			appHeaderOptions={{
				mobileRightButtons: !isDesktop && <ActionsMenu objectId={objectId} />
			}}
			filtersProviderProps={{
				filters,
				onApply,
				onClear
			}}
		>
			<Wrapper>
				<Controls>
					<StructuresTopBar objectId={objectId} />
					{isDesktop ? (
						<StructuresCentralBarDesktop
							objectId={objectId}
							spaceId={spaceId}
						/>
					) : (
						<StructuresCentralBarMobile objectId={objectId} />
					)}
					{isDesktop && <Filters objectId={objectId} />}
					<StructuresBottomBar objectId={objectId} />
				</Controls>
				<Main
					objectId={objectId}
					spaceId={spaceId}
				/>
			</Wrapper>
		</EntitiesPageWrapper>
	);
});
