import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useTranslation} from "react-i18next";

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IOwnerAcceptanceEntity[];
}

const SelectedEntitiesDeletion = ({selectedEntities}: ISelectedEntitiesDeletionProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		t('ownerAcceptancePage.selectedEntitiesDeletion.useConfirmDialog.title'),
		t('ownerAcceptancePage.selectedEntitiesDeletion.useConfirmDialog.message'),
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('ownerAcceptancePage.selectedEntitiesDeletion.useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('ownerAcceptancePage.selectedEntitiesDeletion.useConfirmDialog.rejectBtnLabel')
		}
	);

	const [, handleClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			const objectsIds: string[] = [];
			for (const {type, data} of selectedEntities) {
				switch (type) {
					case 'problem':
						await dispatch(deleteProblem(data.objectId, data.id));
				}
			}
			if (objectsIds.length > 0) {
				await queryClient.refetchQueries({
					queryKey: [...objectsQueryKeys.stats(), objectsIds]
				});
			}
		}
	}, [selectedEntities]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete" />
			</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
