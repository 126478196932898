import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {Plate} from '@tehzor/ui-components';
import { memo } from 'react';
import {useEntityAddingPermission} from '../hooks/useEntityAddingPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {DesktopItemDetail} from './detail/ItemDetail/ItemDetail.desktop';
import {DesktopListDetail} from './detail/ListDetail/ListDetail.desktop';
import {EmptyPlate} from '@src/pages/CheckListPage/components/detail/EmptyPlate/EmptyPlate';
import {useTranslation} from "react-i18next";

const plateClassNames = {root: 'check-item-detail', content: 'check-item-detail__content'};
const inspectionIcons = <i className="tz-inspection" />;

export interface IDesktopItemDetailPermissions {
	canAddTask?: boolean;
	canAddProblem?: boolean;
	canAddInspection?: boolean;
}

export const DesktopDetailColumn = memo(() => {
	const {t} = useTranslation();
	const roles = useAppSelector(extractUserRoles);

	const {
		objectId = 'all',
		listId,
		itemId
	} = useStrictParams<{
		objectId?: string;
		listId: string;
		itemId?: string;
	}>();

	const canAddEntity = useEntityAddingPermission(objectId, listId);

	const permissions: IDesktopItemDetailPermissions = {
		canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
		canAddProblem: canAddEntity && hasPermission(roles, 'problems-add'),
		canAddInspection: canAddEntity && hasPermission(roles, 'inspections-add'),
	};
	return (
		<Plate
			withoutPadding
			className={plateClassNames}
		>
			{itemId && (
				<DesktopItemDetail
					permissions={permissions}
				/>
			)}

			{!itemId && listId && <DesktopListDetail />}
			{!itemId && !listId && (
				<div className="check-item-detail__no-content">
					<EmptyPlate
						icon={inspectionIcons}
						text={t('checkListPage.desktopDetailColumn.emptyPlate.title')}
					/>
				</div>
			)}
		</Plate>
	);
});
