import {makeDateFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useFilterPageTransition} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {MobileFilter, TextField} from '@tehzor/ui-components';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';

interface ISigningDateFilterMobileProps {
	from?: Date;
	to?: Date;
}

export const calendarIcon = <i className="tz-calendar-20" />;

export const SigningDateFilterMobile = memo(({from, to}: ISigningDateFilterMobileProps) => {
	const {t} = useTranslation();
	const handleExpand = useFilterPageTransition(undefined, 'contracts', 'signingdate');

	return (
		<MobileFilter label={t('entitiesFilters.signingDateFilterMobile.label')}>
			<TextField
				className="mobile-entities-filters__textfield"
				elementType="div"
				value={from && to ? makeDateFilterLabel('', from, to) : undefined}
				icon={calendarIcon}
				onClick={handleExpand}
			/>
		</MobileFilter>
	);
});
