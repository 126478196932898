export type IGetChecksDocumentsStatsQueryKeyList = [] & {
	0: string; // problems-documents-stats
	1: string; // list
	2: string[]; // checks ids
}
export const checksDocumentsStatsQueryKeys = {
	all: () => ['checks-documents-stats'],
	lists: () => [...checksDocumentsStatsQueryKeys.all(), 'list'],
	list: (ids: string[]) => [...checksDocumentsStatsQueryKeys.lists(), ids],
	localList: () => [...checksDocumentsStatsQueryKeys.all(), 'local-list'],
};