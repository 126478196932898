import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';
import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useTranslation} from 'react-i18next';

export interface IPermissions {
	canViewProblems?: boolean;
	canViewInspections?: boolean;
	canViewReports?: boolean;
	canViewSpaces?: boolean;
	canViewWarrantyClaims?: boolean;
	canViewCategories?: boolean;
	canViewPlans?: boolean;
	canViewCheckLists?: boolean;
	canViewInternalAcceptances?: boolean;
	canViewOwnerAcceptances?: boolean;
	canViewStructures?: boolean;
	canViewWorkAcceptances?: boolean;
}

const getPermissions = (objectId: string, hasChildren: boolean, user?: IUser): IPermissions => {
	if (!user || !user.roles) {
		return {};
	}
	const result = {
		canViewSpaces: hasPermission(user.roles, 'spaces-view', UserRoleScopes.OBJECT, objectId)
	} as IPermissions;
	result.canViewProblems = hasPermission(
		user.roles,
		'problemsView',
		UserRoleScopes.OBJECT,
		objectId
	);
	result.canViewInspections = hasPermission(
		user.roles,
		'inspectionsView',
		UserRoleScopes.OBJECT,
		objectId
	);

	result.canViewPlans = hasPermission(user.roles, 'plansView', UserRoleScopes.OBJECT, objectId);

	result.canViewStructures = hasPermission(
		user.roles,
		'structures-view',
		UserRoleScopes.OBJECT,
		objectId
	);
	result.canViewWorkAcceptances = hasPermission(
		user.roles,
		'work-acceptances-view',
		UserRoleScopes.OBJECT,
		objectId
	);
	result.canViewReports = hasPermission(
		user.roles,
		'checksView',
		UserRoleScopes.OBJECT,
		objectId
	);
	if (!hasChildren) {
		result.canViewWarrantyClaims = hasPermission(
			user.roles,
			'warranty-claims-view',
			UserRoleScopes.OBJECT,
			objectId
		);

		result.canViewOwnerAcceptances = hasPermission(
			user.roles,
			'owner-acceptances-view',
			UserRoleScopes.OBJECT,
			objectId
		);

		result.canViewCategories = hasPermission(
			user.roles,
			'categories-view',
			UserRoleScopes.OBJECT,
			objectId
		);

		result.canViewCheckLists = hasPermission(
			user.roles,
			'check-lists-view',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewInternalAcceptances = hasPermission(
			user.roles,
			'internal-acceptances-view',
			UserRoleScopes.OBJECT,
			objectId
		);
	}
	return result;
};

export function useObjectSectionsMenu(objectId: string, flag?: boolean): IHeaderSectionsMenuItem[] {
	const user = useAppSelector(s => s.auth.profile);
	const {t} = useTranslation();
	const currentObject = useCurrentTreeObject(objectId);
	const hasSpaces = currentObject?.hasSpaces;
	const isDesktop = useIsDesktop();

	return useMemo(() => {
		const hasChildrenObjects = !!currentObject?.children?.length;
		const permissions = getPermissions(objectId, hasChildrenObjects, user);

		const result = [];
		if (hasChildrenObjects) {
			result.push({
				id: 'Objects',
				url: `/objects/${objectId}`,
				label: t('header.objects')
			});
		}
		if (permissions.canViewProblems) {
			result.push({
				id: 'Problems',
				url: `/objects/${objectId}/problems`,
				label: t('header.defects'),
				serveSubPath: flag
			});
		}
		if (permissions.canViewInspections) {
			result.push({
				id: 'Inspections',
				url: `/objects/${objectId}/inspections`,
				label: t('header.inspections')
			});
		}
		if (permissions.canViewReports) {
			result.push({
				id: 'Checks',
				url: `/objects/${objectId}/checks`,
				label: t('header.checks')
			});
		}
		if (permissions.canViewSpaces) {
			result.push({
				id: 'Spaces',
				url: `/objects/${objectId}/spaces`,
				label: t('header.spaces')
			});
		}
		if (permissions.canViewStructures) {
			result.push({
				id: 'Structures',
				url: `/objects/${objectId}/structures`,
				label: t('header.structures')
			});
		}
		if (permissions.canViewInternalAcceptances) {
			result.push({
				id: 'InternalAcceptances',
				url: `/objects/${objectId}/internal-acceptances`,
				label: t('header.internalAcceptances')
			});
		}
		if (permissions.canViewWorkAcceptances) {
			result.push({
				id: 'WorkAcceptances',
				url: `/objects/${objectId}/work-acceptances`,
				label: t('header.workAcceptances')
			});
		}
		if (permissions.canViewWorkAcceptances && !hasChildrenObjects) {
			result.push({
				id: 'OwnerAcceptances',
				url: `/objects/${objectId}/owner-acceptances`,
				label: t('header.ownerAcceptances')
			});
		}
		if (permissions.canViewWarrantyClaims) {
			result.push({
				id: 'WarrantyClaims',
				url: `/objects/${objectId}/warranty-claims`,
				label: t('header.warrantyClaims')
			});
		}
		if (permissions.canViewCategories) {
			result.push({
				id: 'CategoriesSets',
				url: `/objects/${objectId}/categories-sets`,
				label: t('header.categoriesSets'),
				hidden: true
			});
		}
		if (permissions.canViewPlans) {
			result.push({
				id: 'Plans',
				url: `/objects/${objectId}/plans`,
				label: t('header.plans'),
				hidden: true
			});
		}
		return result;
	}, [objectId, currentObject, user, hasSpaces, isDesktop]);
}
