import { Dispatch, memo, useCallback, useEffect } from 'react';
import * as React from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';

interface IClaimerNameProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value: string;
	defaultValue?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const ClaimerName = <S extends {claimerName: string}, E>({
	className,
	style,
	label = 'Заявитель',
	value,
	defaultValue,
	editingDispatch,
	required,
	disabled,
	hasError,
	...restProps
}: IClaimerNameProps<S, E>) => {
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'claimerName', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'claimerName'});
			}
		},
		[required]
	);

	useEffect(() => {
		if (defaultValue) {
			editingDispatch({type: 'update', field: 'claimerName', value: defaultValue});
		}
	}, [defaultValue]);

	return (
		<div
			className={className}
			style={style}
			{...restProps}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<TextField
				value={value || defaultValue}
				elementType="input"
				error={required && hasError ? 'Введите имя заявителя' : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default memo(ClaimerName) as typeof ClaimerName;
