
export type IMarkersListQueryKey = [] & {
	0: string; // markers
	1: string; // list
	2: string; // planId
	3: string[]; // problemIds
	4: string[]; // inspectionIds
	5: string[]; // ownerAcceptanceIds
	6: string[]; // taskIds

};

export const markersQueryKeys = {
	all: () => ['markers'],
	one: () => ['marker'],
	lists: () => [...markersQueryKeys.all(), 'list'],
	list: (
		planId?: string,
		problemIds?: string[],
		inspectionIds?: string[],
		ownerAcceptanceIds?: string[],
		taskIds?: string[]
		) => [...markersQueryKeys.lists(), planId, problemIds, inspectionIds, ownerAcceptanceIds, taskIds]
};
