import {MouseEvent, CSSProperties} from 'react';
import ButtonBase from '../../buttons/ButtonBase';

interface IPhotoArrowButtonProps {
	className?: string;
	style?: CSSProperties;
	onClick?: (event: MouseEvent) => void;
}

const arrowIcon = <i className="tz-simple-arrow-24" />;

export const ContentArrowButton = (props: IPhotoArrowButtonProps) => {
	const {className, style, onClick} = props;

	return (
		<ButtonBase
			className={className}
			style={style}
			leftIcon={arrowIcon}
			classNamePrefix="plan__arrow-button"
			outerTagType="button"
			onClick={onClick}
		/>
	);
};
