import { useCallback } from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import SelectionActions from '../selection/SelectionActions';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {useTranslation} from 'react-i18next';

const SelectionRow = (props: ISelectionRowProps<IProblemEntity>) => {
	const {selectedFlatRows, toggleAllRowsSelected} = props;
	const {t} = useTranslation();

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				selectedEntities={selectedFlatRows.map(item => item.original)}
				selectedClearing={clearSelection}
			/>
			<Button
				label={t('problemsPage.selectionRow.cancelButton.label')}
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};

export default SelectionRow;
