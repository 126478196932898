import { memo } from 'react';
import * as React from 'react';
import './UserInfo.less';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import classNames from 'classnames';
import UserAvatar, {UserAvatarSize, UserAvatarColoring} from '../UserAvatar';
import {convertClassNames} from '../../../utils/convertClassNames';

interface IUserInfoProps {
	className?:
		| string
		| {
				root?: string;
				avatar?: string;
				name?: string;
				position?: string;
		  };
	style?: React.CSSProperties;
	user?: IBriefUser;
	avatarSize?: UserAvatarSize;
	avatarColoring?: UserAvatarColoring;
	showCompany?: boolean;
}

const UserInfo = ({
	className,
	style,
	user,
	avatarSize,
	avatarColoring,
	showCompany
}: IUserInfoProps) => {
	const classes = convertClassNames(className);

	return (
		<div
			className={classNames('user-info', classes.root)}
			style={style}
		>
			<UserAvatar
				className={classNames('user-info__avatar', classes.avatar)}
				user={user}
				size={avatarSize}
				coloring={avatarColoring}
			/>

			<div className="user-info__name-block">
				<div className={classNames('user-info__name', classes.name)}>
					{user?.displayName}
				</div>
				<div className="user-info__info-block">
					{user?.position && (
						<div className={classNames('user-info__position', classes.position)}>
							{user.position}
						</div>
					)}
					{showCompany && (
						<div className="user-info__company">{user?.companies?.[0]?.name}</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(UserInfo);
