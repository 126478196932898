import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Удаляет правило ответственности *
 * @param ruleId id правила
 */
export const makeResponsibilityRuleDeleteRequest = async (ruleId: string) => {
	const response = await httpRequests.withToken.delete<void>(`responsibility-rules/${ruleId}`);
	return response.data;
};
