import {TFunction} from "i18next";

export function makeCommentTitle(comments: boolean | undefined, replies: boolean | undefined, t:TFunction) {
	if (comments && replies) {
		return t('problemPage.commentTitle.replyAndComments');
	}
	if (comments) {
		return t('problemPage.commentTitle.comments');
	}
	if (replies) {
		return t('problemPage.commentTitle.reply');
	}
	return undefined;
}
