import {useFetchLatestInternalAcceptance} from '@src/core/hooks/queries/internalAcceptance/latest/hooks';
import {
	useInternalAcceptancesAsArray,
	useLocalInternalAcceptances
} from '@src/core/hooks/queries/internalAcceptances/hooks';
import {
	useExtractAllLatestInternalAcceptancesAsArray,
	useExtractLatestInternalAcceptanceId
} from '@src/core/hooks/queries/internalAcceptances/latest/hooks';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {Dispatch, SetStateAction, useEffect} from 'react';

export const useLatestInternalAcceptances = (
	objectId: string,
	setSelectedInternalAcceptanceId: Dispatch<SetStateAction<string | undefined>>,
	selectedInternalAcceptanceId?: string,
	links?: IInternalAcceptance['links']
) => {
	const {data: allInternalAcceptances} = useInternalAcceptancesAsArray(objectId);
	const {data: latestInternalAcceptanceId} = useExtractLatestInternalAcceptanceId(
		objectId,
		links?.spaceId
	);

	const {data: allLatestInternalAcceptances} = useExtractAllLatestInternalAcceptancesAsArray();
	const {data: localInternalAcceptances} = useLocalInternalAcceptances();

	const isInternalAcp = !!allInternalAcceptances?.find(
		item => item.id === selectedInternalAcceptanceId
	);
	const isLatestInternalAcp = !!allLatestInternalAcceptances?.find(
		item => item.id === selectedInternalAcceptanceId
	);
	const isLocalInternalAcp = !!localInternalAcceptances?.find(
		item => item.id === selectedInternalAcceptanceId
	);

	const {data: latestInternalAcceptance} = useFetchLatestInternalAcceptance(
		selectedInternalAcceptanceId as string,
		objectId,
		{
			enabled: !!(
				selectedInternalAcceptanceId &&
				objectId &&
				isInternalAcp &&
				!isLatestInternalAcp &&
				!isLocalInternalAcp
			)
		}
	);

	// "Синхронизация состояния" - это антипаттерн, стоит переписать.
	useEffect(() => {
		if (latestInternalAcceptanceId) {
			setSelectedInternalAcceptanceId(latestInternalAcceptanceId);
		}
	}, [latestInternalAcceptanceId]);

	useEffect(() => {
		if (latestInternalAcceptance) {
			setSelectedInternalAcceptanceId(latestInternalAcceptance.id);
		}
	}, [latestInternalAcceptance]);
};
