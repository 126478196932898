import {IGetWarrantyClaimResponse} from '@src/api/backend/warrantyClaim';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

export const useWarrantyClaim = <T = IGetWarrantyClaimResponse>(
	claimId: string,
	objectId: string,
	select?: (data: IGetWarrantyClaimResponse) => T,
	options?: Partial<UseQueryOptions<IGetWarrantyClaimResponse, IError, T>>
) =>
	useQuery({
		queryKey: [...warrantyClaimsQueryKeys.detail(claimId), objectId],
		meta: {
			error: 'при загрузке гарантийных обращений'
		},
		select,
		...options
	});
