import {memo, useCallback, useMemo, useRef} from 'react';
import {IReasonProps, Reason} from '@src/components/editableFields/Reason/Reason';
import config from '@src/core/config';
import IReason from '@tehzor/tools/interfaces/IReason';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ReasonSelect, useLocalSearchProvider, useSearchSelectPopup} from '@tehzor/ui-components';
import {IEnhancedRegStandard} from '@tehzor/ui-components/src/components/ReasonSelect/ReasonSelect';
import {reasonRenderItem} from '../utils/reasonRenderItem';
import {useRegulatoryStandardsAsArray} from '@src/core/hooks/queries/regulatoryStandards/hooks';
import {useTranslation} from 'react-i18next';

export interface IReasonEnhancedRegStandard extends IEnhancedRegStandard {
	externalLink?: string;
}

const externalLink = config.regStandardExternalLink;

type ISearchableProblemReasonProps = Omit<
	IReasonProps<{reason: IReason}, IProblem>,
	'onSelect' | 'addon'
>;

export const SearchableProblemReason = memo(
	({
		editingDispatch,
		value,
		required,
		disabled,
		hasError,
		...props
	}: ISearchableProblemReasonProps) => {
		const {t} = useTranslation();

		const inputRef = useRef<HTMLTextAreaElement>(null);
		const {data: regStandards} = useRegulatoryStandardsAsArray();
		const filteredData = useMemo<IReasonEnhancedRegStandard[]>(
			() =>
				regStandards
					? regStandards.map(item => ({
							...item,
							fullName: `«${item.name}»`,
							externalLink
					  }))
					: [],
			[regStandards]
		);
		const provider = useLocalSearchProvider<IReasonEnhancedRegStandard>({
			initialList: filteredData,
			keys: ['fullName']
		});

		const onSelect = useCallback(
			(selected: IReasonEnhancedRegStandard) => {
				if (selected.fullName) {
					editingDispatch({
						type: 'update',
						field: 'reason',
						value: {regulatoryStandard: selected.id, value: selected.fullName}
					});
				}
			},
			[editingDispatch]
		);

		const [popup, handleSearchChange] = useSearchSelectPopup<IReasonEnhancedRegStandard>({
			inputRef,
			onSelect,
			provider,
			renderItem: reasonRenderItem,
			noCheckbox: true
		});

		const handleChange = useCallback(
			(v: IReason | null) => {
				editingDispatch({type: 'update', field: 'reason', value: v});
				if (required) {
					editingDispatch({type: 'update-error', field: 'reason'});
				}
			},
			[required, editingDispatch]
		);

		return (
			<Reason
				ref={inputRef}
				editingDispatch={editingDispatch}
				value={value}
				required={required}
				disabled={disabled}
				hasError={hasError}
				onChange={handleSearchChange}
				select={
					<ReasonSelect
						data={regStandards}
						externalLink={externalLink}
						value={value}
						dialogTitle={t('components.editableFields.SearchableProblemReason.dialogTitle')}
						searchPlaceholder={t('components.editableFields.SearchableProblemReason.searchPlaceholder')}
						dialogAcceptBtnLabel={t('components.editableFields.SearchableProblemReason.dialog.accept.label')}
						dialogRejectBtnLabel={t('components.editableFields.SearchableProblemReason.dialog.reject.label')}
						btnLabel={t('components.editableFields.SearchableProblemReason.button.label')}
						noRowsMessage={t('components.editableFields.SearchableProblemReason.noRowsMessage')}
						error={required && hasError ? t('components.editableFields.SearchableProblemReason.error') : undefined}
						disabled={disabled}
						onChange={handleChange}
					/>
				}
				search={popup}
				{...props}
			/>
		);
	}
);
