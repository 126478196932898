import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IProblemsLastRepliesState} from '@src/store/modules/entities/problemsLastReplies/reducers';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

/**
 * Извлекает последний ответ на приемку работ
 */
export const extractWorkAcceptanceLastReply = createSelector(
	(state: IState) => state.entities.workAcceptancesLastReplies,
	(state: IState, workAcceptanceId: string) => workAcceptanceId,
	(data: IProblemsLastRepliesState, workAcceptanceId: string): IComment | undefined => data[workAcceptanceId]
);
