import classNames from 'classnames';
import * as React from 'react';
import './TagPlaceholder.less';

export interface ITagPlaceholderProps {
	className?: string;
	style?: React.CSSProperties;
	width?: string | number;
	size?: 'small' | 'medium' | 'large';
}

const TagPlaceholder = ({className, style, width, size = 'medium'}: ITagPlaceholderProps) => {
	const styles: React.CSSProperties = {...style};
	if (width !== undefined) {
		styles.width = width;
	}

	return (
		<div
			className={classNames('tag-placeholder', `tag-placeholder_${size}`, className)}
			style={styles}
		/>
);
};

export default TagPlaceholder;
