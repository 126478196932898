import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetProblemTagsSetResponse = IProblemTagsSet;

/**
 * Получает набор меток нарушений по id
 *
 * @param setId id набора
 */
export const requestProblemTagsSet = async (setId: string) => {
	const response = await httpRequests.withToken.get<IGetProblemTagsSetResponse>(
		`problem-tags-sets/${setId}`
	);
	return response.data;
};
