import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddSpaceTypeDecorationSetResponse = ISpaceTypeDecorationSet;

/**
 * Создает набор типов отделки помещения
 *
 * @param fields данные для добавления
 */
export const makeSpaceTypeDecorationSetRequest = async (fields: ISavingSpaceTypeDecorationSet) => {
	const response = await httpRequests.withToken.post<IAddSpaceTypeDecorationSetResponse>(
		'space-type-decoration-sets',
		{...fields}
	);
	return response.data;
};
