import {IGetSpaceTypeDecorationSetsResponse} from '@src/api/backend/spaceTypeDecorationSets';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export const extractSpaceTypeDecorationSetsAsArray = (data: IGetSpaceTypeDecorationSetsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractSpaceTypeDecorationSetsForObjectAsArray = (
	data: IGetSpaceTypeDecorationSetsResponse,
	objectId?: string
) => {
	if (!objectId) return undefined;
	const spaceTypeDecorationSetsArr = extractSpaceTypeDecorationSetsAsArray(data);
	return spaceTypeDecorationSetsArr.filter((set: ISpaceTypeDecorationSet) =>
		set.objects.includes(objectId)
	);
};
