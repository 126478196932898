import './CheckListsEditPage.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {LoadingPanel} from '@tehzor/ui-components';
import {EditingCheckList} from '@src/components/EditingCheckList/EditingCheckList';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {useCheckItemsIsLoading} from '@src/core/hooks/queries/checkItems/hooks';
import {useCheckListTypesAsArray} from '@src/core/hooks/queries/checkListTypes/hooks';
import {useIsFetching} from '@tanstack/react-query';
import {spaceTypesDecorationsQueryKeys} from '@src/api/cache/spaceTypeDecorations/keys';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';

export const CheckListsEditPage = () => {
	const {checkListId} = useStrictParams<{checkListId: string}>();
	const checkListsIsLoading = useCheckListsIsLoading();
	const checkItemsIsLoading = useCheckItemsIsLoading();
	const {isLoading} = useCheckListTypesAsArray();

	useAppHeader({
		title: 'Редактирование чек-листа',
		mobileRightButtons: <AppUpdateMenu />,
		showBackBtn: true
	});

	const loadingSpaceTypes = !!useIsFetching({queryKey: spaceTypesDecorationsQueryKeys.list()});
	const loadingSpaceTypesSets = !!useIsFetching({
		queryKey: spaceTypeDecorationSetsQueryKeys.lists()
	});

	const isActive =
		loadingSpaceTypes ||
		loadingSpaceTypesSets ||
		checkListsIsLoading ||
		checkItemsIsLoading ||
		isLoading;

	return (
		<div className="page-cont manage-check-lists-edit-page">
			{checkListId && <PageBreadcrumbs checkListId={checkListId} />}

			<LoadingPanel active={isActive}>
				<>
					{!loadingSpaceTypes && !loadingSpaceTypesSets && checkListId && (
						<EditingCheckList checkListId={checkListId} />
					)}
				</>
			</LoadingPanel>
		</div>
	);
};
