import {
	IWarrantyClaimsFiltersState,
	IWarrantyClaimsSortState
} from '@src/store/modules/settings/pages/warrantyClaims/reducers';
import {IWarrantyClaimsProblemsDataRequest} from '@src/interfaces/warrantyClaimsStatsData';

export type IWarrantyClaimsListQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
	3: IWarrantyClaimsFiltersState;
	4: IWarrantyClaimsSortState;
	5: number;
	6: number;
};

export type IWarrantyClaimQueryKey = [] & {
	0: string;
	1: string;
	2: string; // warrantyClaimId
	3: string; // objectId
};

export type IWarrantyClaimProblemsQueryKey = [] & {
	0: string; // warranty-claim
	1: string; // problems
	2: string; // objectId
	3: string; // claimId
};

export type IWarrantyClaimProblemsRepliesQueryKey = [] & {
	0: string; // warranty-claim
	1: string; // problems-replies
	2: string; // claimId
	3: string; // objectId
};

/**
 * @param [0]: warranty-claims
 * @param [1]: list
 * @param [2]: problems-stats
 * @param [rest]: IWarrantyClaimsProblemsDataRequest[]
 */
export type IWarrantyClaimsProblemsStats = [
	string,
	string,
	string,
	...IWarrantyClaimsProblemsDataRequest[]
];

export const warrantyClaimsQueryKeys = {
	all: () => ['warranty-claims'] as const,
	one: () => ['warranty-claim'],
	lists: () => [...warrantyClaimsQueryKeys.all(), 'list'],
	list: (
		objectId = 'all',
		filters?: IWarrantyClaimsFiltersState,
		sort?: IWarrantyClaimsSortState,
		offset?: number,
		pageSize?: number
	) => [...warrantyClaimsQueryKeys.lists(), objectId, filters, sort, offset, pageSize] as const,
	delete: () => [...warrantyClaimsQueryKeys.one(), 'delete'] as const,
	listWithObjectId: (objectId = 'all') => [...warrantyClaimsQueryKeys.lists(), objectId],
	latest: () => [...warrantyClaimsQueryKeys.all(), 'latest-warranty-claims'],
	localList: () => [...warrantyClaimsQueryKeys.all(), 'local-list'],
	add: () => [...warrantyClaimsQueryKeys.one(), 'add'],
	details: () => [...warrantyClaimsQueryKeys.one(), 'detail'],
	detail: (warrantyClaimId: string) => [...warrantyClaimsQueryKeys.details(), warrantyClaimId],
	problems: () => [...warrantyClaimsQueryKeys.one(), 'problems'],
	claimProblems: (objectId: string, claimId: string) => [
		...warrantyClaimsQueryKeys.problems(),
		objectId,
		claimId
	],
	problemsReplies: () => [...warrantyClaimsQueryKeys.one(), 'problems-replies'],
	claimProblemsReplies: (warrantyClaimId: string, objectId: string) => [
		...warrantyClaimsQueryKeys.problemsReplies(),
		warrantyClaimId,
		objectId
	],
	problemsStats: () => [...warrantyClaimsQueryKeys.lists(), 'problems-stats'],
	warrantyProblemsStats: (queries: IWarrantyClaimsProblemsDataRequest[]) => [
		...warrantyClaimsQueryKeys.problemsStats(),
		...queries
	]
};
