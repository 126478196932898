import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getStructure} from '@src/store/modules/app/links/actions/getStructure';
import {LinkButton} from '@tehzor/ui-components';
import { useCallback, useEffect } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureLink} from '@tehzor/tools/utils/links';
import {useExtractStructureTypesById} from '@src/core/hooks/queries/structureTypes/hooks';
import {extractLinksStructureId} from '@src/store/modules/app/links/selectors';

const leftIco = <i className="tz-external-link-16" />;

export const StructureEntityLink = ({structureId, objectId}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const structure = useAppSelector(state => extractLinksStructureId(state, structureId));
	const {data: structureTypes} = useExtractStructureTypesById();

	const handleClick = useCallback(() => {
		pushPath(formStructureLink(objectId, structureId));
	}, [objectId, structureId]);

	useEffect(() => {
		void dispatch(getStructure(objectId, structureId))
	}, [structureId, objectId]);

	return structure && structureTypes && structureTypes[structure.type] ? (
		<LinkButton
			className="entity-links__link"
			label={`Структура: ${structure.name}`}
			leftIcon={leftIco}
			onClick={handleClick}
			type="filled"
		/>
	) : null;
};
