import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {
	extractWorkAcceptanceFrontTypesAllIds,
	extractWorkAcceptanceFrontTypesAsArray,
	extractWorkAcceptanceFrontTypesById
} from './selectors';
import {IGetWorkAcceptanceFrontTypesResponse} from '@src/api/backend/workAcceptanceFrontTypes';

export const useWorkAcceptanceFrontTypes = <T = IGetWorkAcceptanceFrontTypesResponse>(
	select?: (data: IGetWorkAcceptanceFrontTypesResponse) => T
) => useQuery<IGetWorkAcceptanceFrontTypesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.workAcceptanceFrontTypes(),
		meta: {
			error: 'при загрузке типов фронта приёмок работ'
		},
		select
	});

export const useExtractWorkAcceptanceFrontTypesAsArray = () =>
	useWorkAcceptanceFrontTypes(extractWorkAcceptanceFrontTypesAsArray);
export const useExtractWorkAcceptanceFrontTypesById = () =>
	useWorkAcceptanceFrontTypes(extractWorkAcceptanceFrontTypesById);
export const useExtractWorkAcceptanceFrontTypesAllIds = () =>
	useWorkAcceptanceFrontTypes(extractWorkAcceptanceFrontTypesAllIds);
