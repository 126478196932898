import {useCallback} from 'react';
import {MobilePagination, Plate} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import Table from './Table';
import {extractSpacesListData} from '@src/store/modules/entities/spaces/selectors/lists';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeOffset} from '@src/store/modules/entities/spaces/actions';
import {useMobileColumns} from '@src/pages/SpacesPage/hooks/useMobileColumns';

interface IMobileTableProps {
	objectId: string;
}

const MobileTable = ({objectId}: IMobileTableProps) => {
	const {total, offset} = useAppSelector(s => extractSpacesListData(s, objectId));
	const {pageSize} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const handlePageChange = useCallback(
		(value: number) => {
			dispatch(changeOffset(objectId, value * pageSize));
		},
		[objectId, pageSize]
	);

	const pageCount = Math.ceil(total / pageSize);
	const currentPage = Math.floor(offset / pageSize);

	const columns = useMobileColumns();

	return (
		<Plate
			withoutPadding
			className="spaces-page__plate"
		>
			<MobilePagination
				className="spaces-page__m-pagination"
				page={currentPage}
				pageCount={pageCount}
				onPageChange={handlePageChange}
			/>
			<Table
				objectId={objectId}
				columns={columns}
				hideHead
				noRowBorder
			/>
		</Plate>
	);
};

export default MobileTable;
