import {useInternalAcceptancesMutationDefaults} from './useInternalAcceptancesMutationDefaults';
import {useInternalAcceptancesQueryDefaults} from './useInternalAcceptancesQueryDefaults';

/**
 * Хук для передачи дефолтных функций в QueryClient
 */
export function useInternalAcceptancesCacheDefaults() {
	useInternalAcceptancesMutationDefaults();
	useInternalAcceptancesQueryDefaults();
}
