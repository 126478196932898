import {TFunction} from 'i18next';
import {CellProps} from 'react-table';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';
import LocationCell from '@src/pages/InspectionsPage/components/table/LocationCell';
import DescriptionCell from '@src/pages/InspectionsPage/components/table/DescriptionCell';
import CellWrap from '@src/components/tableCells/CellWrap';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import MenuCell from '@src/pages/InspectionsPage/components/table/MenuCell';
import {ColumnWithSettings} from '@src/core/hooks/settings/useColumnsWithSettings/interfaces';
import {InspectionsPerformersCell} from '../components/table/InspectionsPerformersCell';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {getCustomColumns} from '@src/utils/getCustomColumns';

export const getDesktopColumns = (
	objectId: string,
	t: TFunction,
	settings?: IEntitySettings
): Array<ColumnWithSettings<IInspectionEntity>> => {
	let columns: Array<ColumnWithSettings<IInspectionEntity>> = [
		{
			id: 'number',
			Header: '№',
			accessor: row => row.data.number || row.data.localNumber,
			Cell: (props: CellProps<IInspectionEntity>) => DescriptionCell({...props, settings}),
			width: 160,
			minWidth: 160,
			className: 'inspections-page__d-table-description-cell',
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'object',
			Header: t('columns.object'),
			accessor: row => row.data.object?.name,
			width: 130,
			minWidth: 130,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'location',
			Header: t('columns.location'),
			Cell: LocationCell,
			width: 130,
			minWidth: 130,
			defaultCanSort: false,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'createdBy',
			Header: t('columns.createdBy'),
			accessor: row => row.data.createdBy,
			width: 130,
			minWidth: 130,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'categoryId',
			Header: t('columns.category'),
			Cell: CellWrap({
				inspection: CategoryCell
			}),
			width: 130,
			minWidth: 130,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'performers',
			Header: t('inspectionsPage.table.performersCell.label'),
			Cell: CellWrap({inspection: InspectionsPerformersCell}),
			width: 130,
			minWidth: 130,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'createdAt',
			Header: t('columns.createdAt'),
			accessor: row => row.data.createdAt,
			width: 130,
			minWidth: 130,
			sortDescFirst: true,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'modifiedAt',
			Header: t('columns.modifiedAt'),
			accessor: row => row.data.modifiedAt,
			width: 130,
			minWidth: 130,
			sortDescFirst: true,
			customizeSettings: {
				controlled: true
			}
		}
	];

	if (settings?.custom) {
		const customColumns = getCustomColumns<IInspectionEntity>(settings.custom);
		columns = [...columns, ...customColumns];
	}

	columns.push({
		id: '_menu',
		Cell: MenuCell,
		className: 'inspections-page__d-table-menu',
		width: 130,
		minWidth: 130,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		customizeSettings: {
			controlled: false
		}
	});

	return columns;
};
