import {useCallback, useState} from 'react';
import './EditableSpaceOwnerDialog.less';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, InlineButton, ModalDialog} from '@tehzor/ui-components';
import {useEditableSpaceOwner} from '../EditableSpaceOwner/hooks/useEditableSpaceOwner';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {useEditSpaceOwner} from '@src/core/hooks/mutations/spaceOwners/useEditSpaceOwner';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useUnbindSpaceOwner} from '@src/core/hooks/mutations/spaceOwners/useUnbindSpaceOwner';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';

interface IEditableSpaceOwnerDialogProps {
	spaceId: string;
	owner: ISpaceOwner | undefined;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования собственника помещения
 */

const deleteIcon = <i className="tz-delete" />;

const EditableSpaceOwnerDialog = ({
	spaceId,
	owner,
	isOpen,
	onClose
}: IEditableSpaceOwnerDialogProps) => {
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableSpaceOwner(owner, saving);
	const {t} = useTranslation();
	const online = useOnlineManager();
	const {mutateAsync: unbindSpaceFromOwner} = useUnbindSpaceOwner();

	const editSpaceOwner = useEditSpaceOwner();
	const save = useCallback(() => {
		if (!owner) {
			return;
		}
		setSaving(true);
		const savingData = getSavingData();
		if (savingData) {
			try {
				editSpaceOwner({
					spaceOwnerId: owner.id,
					companyId: owner.companyId,
					fields: savingData
				});
				onClose();
			} catch (error) {
				setSaving(false);
				throw error;
			}
		}
		setSaving(false);
	}, [owner, getSavingData, editSpaceOwner, onClose]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose, getClosingConfirmation]);

	const [deletingDialog, getDeletingConfirmation] = useConfirmDialog(
		t('spacePage.info.spaceOwnersDialog.deletingDialogTitle'),
		t('spacePage.info.spaceOwnersDialog.deletingDialogMessage'),
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleDelete = useCallback(async () => {
		if (!owner) {
			return;
		}
		if (await getDeletingConfirmation()) {
			setSaving(true);
			try {
				await unbindSpaceFromOwner({ownerId: owner.id, spaceId, removeIfLast: false});
				onClose();
			} finally {
				setSaving(false);
			}
		}
	}, [owner, getDeletingConfirmation, unbindSpaceFromOwner, spaceId, onClose]);

	return (
		<ModalDialog
			className={{root: 'editable-space-owner-dialog'}}
			open={isOpen}
			title={t('spacePage.info.spaceOwnersDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('spacePage.info.spaceOwnersDialog.cancelButton')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('spacePage.info.spaceOwnersDialog.saveButton')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{online && (
				<InlineButton
					label={t('spacePage.info.spaceOwnersDialog.deleteButton')}
					className="editable-space-owner-dialog__delete-button"
					onClick={handleDelete}
					type="cancel"
					leftIcon={deleteIcon}
					disabled={saving}
				/>
			)}
			{closingDialog}
			{deletingDialog}
		</ModalDialog>
	);
};

export default EditableSpaceOwnerDialog;
