import {wsConnector} from '../wsConnector';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {ISavingOwnerAcceptance} from '@src/interfaces/saving/ISavingOwnerAcceptance';

export type IAddOwnerAcptResponse = IOwnerAcceptance;

/**
 * Добавляет передачу собственнику
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные передачи
 */
export const makeOwnerAcptAddRequest = (
	objectId: string,
	links: IOwnerAcceptance['links'] | undefined,
	fields: ISavingOwnerAcceptance,
	localId?: string
) =>
	wsConnector.sendAuthorizedRequest<IAddOwnerAcptResponse>('addOwnerAcceptance', {
		objectId,
		links,
		localId,
		...fields
	});
