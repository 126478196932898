import {IDndItem} from '../interfaces';

/**
 * Фильтрует массив для отображения убирая из него детей перемещаемого элемента
 * 
 * @param items массив элементов
 * @param dragItem перемещаемый элемент
 * @param dragChildren дети перемещаемого элемента
 */
export const filterVisibleItems = <S extends IDndItem>(
	items: S[], dragItem?: S, dragChildren?: S[]
): S[] => {
	if (!dragItem) { return items; }

	const dragChildIds = dragChildren?.map(({id}) => id);
	return items.filter(item => !dragChildIds?.includes(item.id));
};

/**
 * Фильтрует массив элементов убирая из него перемещаемый элемент и его детей
 * 
 * @param items массив элементов
 * @param dragItem перемещаемый элемент
 * @param dragChildren дети перемещаемого элемента
 */
export const filterDragItems = <S extends IDndItem>(
	items: S[], dragItem?: S, dragChildren?: S[]
): S[] => {
	if (!dragItem) { return items; }

	const dragChildIds = dragChildren?.map(({id}) => id);
	return items.filter(item =>
		dragItem.id !== item.id && !dragChildIds?.includes(item.id));
};