import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDocumentsStatisticsData} from '@tehzor/tools/interfaces/documentsStatistics/IDocumentsStatisticsData';

export type IGetProblemsDocumentsStatsResponse = INormalizedData<IDocumentsStatisticsData>;

/**
 * Возвращает количество документов у списка нарушений
 */
export const requestProblemsDocumentsStats = async (problemIds: string[]) => {
	const params = {
		ids: problemIds
	};
	const response = await httpRequests.withToken.get<IGetProblemsDocumentsStatsResponse>('documents-statistics/problems', {params});
	return response.data;
};

