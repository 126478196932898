import {ReactNode} from 'react';
import {createPortal} from 'react-dom';

interface IPortalProps {
	children: ReactNode;
	root?: HTMLElement;
	className?: string;
}

export const Portal = (props: IPortalProps) => {
	const {children, root = document.body, className} = props;
	if (className) root.classList.add(className);
	return createPortal(children, root);
};
