import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {findPermission, hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IChecksPermissions {
	canAdd?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
}

/**
 * Возвращает полномочия для проверок
 *
 * @param objectId id объекта
 * @param targetComponent таргет
 */
export const useChecksPermissions = (
	objectId?: string,
	targetComponent?: 'problems' | 'spaces' | 'space' | 'check-list' | 'work-acceptance'
): IChecksPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		const addPermission = findPermission(
			user.roles,
			'checksAdd',
			UserRoleScopes.OBJECT,
			objectId
		);
		return objectId
			? {
					canAdd:
						addPermission !== undefined
						&& !(
							targetComponent === 'problems'
							&& addPermission.restrictions
							&& addPermission.restrictions['checks-add-from-problems']
						)
						&& !(
							targetComponent === 'space'
							&& addPermission.restrictions
							&& addPermission.restrictions['checks-add-from-space']
						)
						&& !(
							targetComponent === 'check-list'
							&& addPermission.restrictions
							&& addPermission.restrictions['checks-add-from-check-list']
						)
						&& !(
							targetComponent === 'work-acceptance'
							&& addPermission.restrictions
							&& addPermission.restrictions['checks-add-from-work-acceptance']
						),
					canDelete: hasPermission(
						user.roles,
						'checksDelete',
						UserRoleScopes.OBJECT,
						objectId
					),
					canExport: hasPermission(
						user.roles,
						'checksExport',
						UserRoleScopes.OBJECT,
						objectId
					)
			  }
			: {
					canAdd: false,
					canDelete: false,
					canExport: hasPermission(user.roles, 'checksExport')
			  };
	}, [objectId, user]);
};
