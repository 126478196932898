import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectStats} from './ObjectStats';
import {aggregateByObjects} from '@src/store/modules/statistics/warranty/selectors/problemsByStatuses';
import {useFilteredObjects} from '@src/pages/StatisticsPage/hooks/useFilteredObjects';
import StatsTreePlaceholder from '../../StatsTreePlaceholder';
import ProblemsStatsChart from './ProblemsStatsChart';
import {areProblemsByStatusesStatsLoading} from '@src/store/modules/statistics/ui/selectors/warranty';
import {isWarrantyFiltersSetUp} from '@src/store/modules/settings/pages/statistics/warranty/selectors/filters';

const ByObjectsStats = () => {
	const statsByObjects = useAppSelector(aggregateByObjects);
	const filters = useAppSelector(s => s.settings.pages.statistics.warranty.filters);
	const isFiltersSetUp = useAppSelector(isWarrantyFiltersSetUp);
	const objects = useFilteredObjects(statsByObjects, filters, isFiltersSetUp);
	const loading = useAppSelector(areProblemsByStatusesStatsLoading);

	return (
		<>
			{objects?.map(object => (
				<ObjectStats
					key={object.id}
					data={object}
					objectsStats={statsByObjects}
				/>
			))}

			{loading && !objects?.length && (
				<StatsTreePlaceholder
					includeImage
					includeChildren
					permanentContent={<ProblemsStatsChart/>}
				/>
			)}
		</>
	);
};

export default ByObjectsStats;
