import {useMutation} from '@tanstack/react-query';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';
import {addErrorToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {requestEditSpaceStatusesSet} from '@src/api/backend/spaceStatusesSets/edit';

interface IUseEditSpaceStatusesSetsMutationParams {
	spaceStatusesSetId: string;
	fields: ISavingSpaceStatusesSet;
}

export const useEditSpaceStatusesSets = () =>
	useMutation({
		mutationKey: spaceStatusesSetsQueryKeys.edit(),
		mutationFn: (params: IUseEditSpaceStatusesSetsMutationParams) => {
			const {spaceStatusesSetId, fields} = params;
			return requestEditSpaceStatusesSet(spaceStatusesSetId, fields);
		},
		onError: () => {
			addErrorToast(
				'Наборы статусов помещений',
				'Не удалось изменить набор статусов помещений'
			);
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesSetsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
		}
	});
