import {IEditableCategory} from '@src/core/hooks/states/useCategoriesSetState';
import {Button, IconMenu, MenuItem} from '@tehzor/ui-components';
import { memo } from 'react';

interface ICategoryMobileOptionsProps {
	item: IEditableCategory;
	isCurrent?: boolean;
	onCreate: (parentId?: IEditableCategory) => void;
	onRemove: (id: string) => void;
	onConfirm: (id: string) => void;
	onCancel: (id: string) => void;
}

const addIcon = <i className="tz-plus-20"/>;
const removeIcon = <i className="tz-delete"/>;

export const CategoryMobileOptions = memo(({
	item,
	isCurrent,
	onCreate,
	onRemove,
	onConfirm,
	onCancel
}: ICategoryMobileOptionsProps) => (
	<div className="editable-categories__item-options">
		{isCurrent && (
			<div className="editable-categories__item-options__edit">
				<Button
					label="Сохранить"
					type="accent-blue"
					onClick={() => onConfirm(item.id)}
				/>
				<Button
					label="Отменить"
					type="cancel"
					onClick={() => onCancel(item.id)}
				/>
			</div>
		)}

		<IconMenu className="editable-categories__item-options__view">
			<MenuItem
				onClick={() => onCreate(item)}
				icon={addIcon}
			>
				Вложенный элемент
			</MenuItem>
			<MenuItem
				onClick={() => onRemove(item.id)}
				icon={removeIcon}
			>
				Удалить
			</MenuItem>
		</IconMenu>

	</div>
));
