import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IEditWarrantyClaimResponse,
	makeWarrantyClaimEditRequest
} from '@src/api/backend/warrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {queryClient} from '@src/api/QueryClient';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';

export type IEditWarrantyClaimPayload = IEditWarrantyClaimResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditWarrantyClaimResponse) => {
	void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.lists()});
	void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.details()});

	return {
		type: types.EDIT_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении статуса обращения');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет гарантийное обращение
 */
export const editWarrantyClaim = (objectId: string, claimId: string, data: ISavingWarrantyClaim) =>
	createApiAction<IEditWarrantyClaimResponse>(request, success, failure, () =>
		makeWarrantyClaimEditRequest(objectId, claimId, data)
	);
