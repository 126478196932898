import {useCallback, useState, Suspense, ReactNode} from 'react';
import {WorkAcceptanceHistoryDialog} from '../WorkAcceptanceHistoryDialog';

export function useWorkAcceptanceHistoryDialog(
	objectId: string,
	workAcceptanceId: string
): [ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<Suspense>
			<WorkAcceptanceHistoryDialog
				objectId={objectId}
				workAcceptanceId={workAcceptanceId}
				isOpen={isOpen}
				onClose={close}
			/>
		</Suspense>
	);

	return [dialog, open];
}
