import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import './CheckListType.less';
import {useCheckListTypesMap} from '@src/core/hooks/queries/checkListTypes/hooks';

interface ICheckListTypeProps {
	typeId: CheckListTypeId;
}

export const CheckListType = (props: ICheckListTypeProps) => {
	const {typeId} = props;
	const {data: CheckListTypesMap} = useCheckListTypesMap();

	if (!CheckListTypesMap) return null;

	const typeName = CheckListTypesMap[typeId]?.name;

	return <div className="check-list-type">{typeName}</div>;
};
