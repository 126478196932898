import {SelectSearch} from '@tehzor/ui-components';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ISelectSearchProps} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

type ITranslatedSelectSearch = ISelectSearchProps;

export const TranslatedSelectSearch = memo((props: ITranslatedSelectSearch) => {
	const {t} = useTranslation();

	return (
		<SelectSearch
			placeholder={t('selectSearch.placeholder')}
			{...props}
		/>
	);
});
