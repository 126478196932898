interface Args {
	objectId: string;
	structureId: string;
}
/**
 * Формирует ссылку на список чек-листов
 *
 * @param objectId id объекта
 * @param structureId id структуры
 */
export const formStructureCheckListsLink = ({objectId, structureId}: Args) =>
	`/objects/${objectId}/structures/${structureId}/check-lists`;
