import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';

export interface IGetWarrantyClaimsPayload extends IGetWarrantyClaimsResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetWarrantyClaimsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка обращений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

export const getWarrantyClaimsActions = {request, success, failure};
