import * as types from './constants';

/**
 * Переключает видимость мобильного sidebar'а
 *
 * @param value true/false - показать/скрыть, undefined - поменять на противоположное значение
 */
export const toggleTabletSidebarVisibility = (value?: boolean) => ({
	type: types.TOGGLE_TABLET_SIDEBAR_VISIBILITY,
	payload: value
});

/**
 * Переключает видимость мобильного sidebar'а
 *
 * @param value true/false - показать/скрыть, undefined - поменять на противоположное значение
 */
export const toggleMobileSidebarVisibility = (value?: boolean) => ({
	type: types.TOGGLE_MOBILE_SIDEBAR_VISIBILITY,
	payload: value
});
