import {Plate} from '@tehzor/ui-components';
import './EmptyTableMessage.less';
import {useTranslation} from 'react-i18next';

export const EmptyTableMessage = () => {
	const {t} = useTranslation();

	return (
		<Plate className="empty-table-message">
			<i className="tz-empty-table empty-table-message__icon" />
			<p className="empty-table-message__text">{t('components.emptyTableMessage.text')}</p>
		</Plate>
	);
};
