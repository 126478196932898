import { useCallback } from 'react';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {ActionsMobile} from './components/actions/Actions.mobile';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {extractOwnerAcceptancesPageSettings} from '@src/store/modules/settings/pages/ownerAcceptances/selectors';
import {OwnerAcceptancesPage} from '@src/pages/OwnerAcceptancesPage/OwnerAcceptancesPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ownerAcceptancesPageSettingsActions} from '@src/store/modules/settings/pages/ownerAcceptances/slice';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/interfaces';

export const OwnerAcceptancesPageWrap = () => {
	const dispatch = useAppDispatch();
	const {objectId} = useStrictParams<{objectId: string}>();
	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {
		sectionsMenu,
		mobileRightButtons: <ActionsMobile objectId={objectId} />
	});

	const {filters} = useAppSelector(s => extractOwnerAcceptancesPageSettings(s, objectId));
	const {clearFilters, changeFilters, changeOffset} = ownerAcceptancesPageSettingsActions;

	const applyFilters = useCallback(
		(filters: IOwnerAcceptancesFiltersState) => {
			dispatch(changeFilters({objectId, filters}));
			dispatch(changeOffset({objectId, offset: 0}));
		},
		[objectId, dispatch, changeFilters, changeOffset]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId}));
	}, [objectId, dispatch, clearFilters]);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			objectId={objectId}
			onClear={onClear}
		>
			<OwnerAcceptancesPage key={objectId} />
		</EntitiesFiltersProvider>
	);
};
