import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';
import {requestExportTemplatesDestinations} from '@src/api/backend/exportTemplatesDestinations';

export const useExportTemplatesDestinationsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.exportTemplatesDestination(), {
		queryFn: requestExportTemplatesDestinations,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
