import * as React from 'react';
import {Dispatch, memo, useCallback, useEffect, useMemo} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, ProblemTagsSelect} from '@tehzor/ui-components';
import {makeTreeData} from '@src/utils/makeTreeData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useExtractProblemTagsAsArray} from '@src/core/hooks/queries/problemTags/hooks';
import {useTranslation} from 'react-i18next';
import {useExtractProblemTagsSetsAsFilteredArray} from '@src/core/hooks/queries/problemTagsSets/hooks';

interface IProblemTagsProps<S, E> {
	objectId: string;
	stage: ObjectStageIds;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	tags?: string[];

	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	exception?: boolean;
}

const ProblemTagsMemo = <S extends {problemTags: string[]}, E>({
	objectId,
	stage,
	className,
	style,
	label = 'Метки',
	tags,
	editingDispatch,
	required,
	disabled,
	hasError,
	exception,
	...restProps
}: IProblemTagsProps<S, E>) => {
	const {t} = useTranslation();
	const {data: sets} = useExtractProblemTagsSetsAsFilteredArray(objectId, stage);
	const {data: tagsArray} = useExtractProblemTagsAsArray();
	const treeData = useMemo(() => makeTreeData(sets, tagsArray, true), [sets, tagsArray]);

	// обязательное требование заполнения снимается если нет меток на выбор
	useEffect(() => {
		if (!required || exception) {
			return;
		}

		const isException = !treeData || treeData.length === 0;

		editingDispatch({type: 'update-exception', field: 'problemTags', value: isException});
	}, [editingDispatch, exception, required, treeData]);

	const handleChange = useCallback(
		(item: string[]) => {
			editingDispatch({type: 'update', field: 'problemTags', value: item});
			if (required) {
				editingDispatch({type: 'update-error', field: 'problemTags'});
			}
		},
		[required, editingDispatch]
	);

	return (
		<div
			className={className}
			style={style}
			{...restProps}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<ProblemTagsSelect
				dialogTitle={t('components.editableFields.problemTags.dialog.title')}
				treeData={treeData}
				value={tags}
				searchPlaceholder={t('components.editableFields.problemTags.searchPlaceholder')}
				dialogAcceptBtnLabel={t(
					'components.editableFields.problemTags.dialog.accept.label'
				)}
				dialogRejectBtnLabel={t(
					'components.editableFields.problemTags.dialog.reject.label'
				)}
				error={
					required && hasError
						? t('components.editableFields.problemTags.error')
						: undefined
				}
				disabled={disabled}
				onChange={handleChange}
				multiple
			/>
		</div>
	);
};
export const ProblemTags = memo(ProblemTagsMemo) as typeof ProblemTagsMemo;
