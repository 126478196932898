import {
	IDocumentsFiltersState,
	IDocumentsSortState
} from '@src/store/modules/settings/pages/documents/reducers';

export type IGetPaginateDocumentsQueryKey = [] & {
	0: string; // documents
	1: string; // paginate
	2?: IDocumentsFiltersState; // filters
	3?: IDocumentsSortState; // sort
	4?: number; // offset
	5?: number; // pageSize
};

export type IGetListDocumentsQueryKey = [] & {
	0: string; // documents
	1: string; // list
	2?: IDocumentsFiltersState; // filters
	3?: IDocumentsSortState; // sort
};

export type IGetDocumentQueryKey = [] & {
	0: string; // document
	1: string; // detail
	2: string; // documentId
};

export const documentsQueryKeys = {
	all: () => ['documents'],
	one: () => ['document'],
	lists: () => [...documentsQueryKeys.all(), 'list'],
	list: (filters?: IDocumentsFiltersState, sort?: IDocumentsSortState) => [
		...documentsQueryKeys.lists(),
		filters,
		sort
	],
	paginates: () => [...documentsQueryKeys.all(), 'paginate'],
	paginate: (
		filters?: IDocumentsFiltersState,
		sort?: IDocumentsSortState,
		offset?: number,
		pageSize?: number
	) => [...documentsQueryKeys.paginates(), filters, sort, offset, pageSize],
	details: () => [...documentsQueryKeys.one(), 'detail'],
	detail: (documentId: string) => [...documentsQueryKeys.details(), documentId],
	types: () => [...documentsQueryKeys.all(), 'types']
};
