import {useMemo} from 'react';
import {IEntity} from '../EntitiesMobilePhotoViewer';

export const getCanvasData = (entities: IEntity[], activeId?: string, activeIndex?: number) =>
	useMemo(() => {
		let data;
		if (activeId && (activeIndex || activeIndex === 0)) {
			const current_entity = entities.find(ent => ent.id === activeId);
			if (current_entity && current_entity.attachments && current_entity.attachments[activeIndex]) {
				if (current_entity.attachments[activeIndex].canvas) {
					data = {
						canvas: JSON.stringify(current_entity.attachments[activeIndex].canvas),
						original: current_entity.attachments[activeIndex].original
					};
				}
			}
		}

		return data;
	}, [entities, activeId, activeIndex]);
