import CheckPage from './CheckPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

/**
 * Обёртка над страницей проверки,
 * необходимо для того чтобы при переходе от одной проверки к другой отрабатывали все методы жизненного цикла
 *
 * @param props свойства компонента
 * @constructor
 */
const CheckPageWrap = () => {
	const {checkId} = useStrictParams<{checkId: string}>();
	return <CheckPage key={checkId}/>;
};

export default CheckPageWrap;
