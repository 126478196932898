import {useCallback} from 'react';
import {Button} from '@tehzor/ui-components';
import {useInspectionExport} from '@src/core/hooks/export/useInspectionExport';
import {useTranslation} from 'react-i18next';

interface IInspectionExportProps {
	objectId: string;
	inspectionId: string;
}

const DesktopInspectionExportBtn = ({objectId, inspectionId}: IInspectionExportProps) => {
	const [exportDialog, openExportDialog] = useInspectionExport(objectId, inspectionId);
	const {t} = useTranslation();

	const handleExport = useCallback(() => {
		openExportDialog(objectId, inspectionId);
	}, [objectId, inspectionId, openExportDialog]);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-export-20" />}
				label={t('exportButton.label')}
				onClick={handleExport}
			/>
			{exportDialog}
		</>
	);
};

export default DesktopInspectionExportBtn;
