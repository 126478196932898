import {memo} from 'react';
import {BaseListFilterMobile} from './BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useExtractWorkAcceptanceStatusesAsArray} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

interface IWorkAcceptanceStatusesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	filterName?: string;
	onChange: (changes: IChanges) => void;
}

export const WorkAcceptanceStatusesFilterMobile = memo(
	(props: IWorkAcceptanceStatusesFilterMobileProps) => {
		const {t} = useTranslation();
		const {data: statuses} = useExtractWorkAcceptanceStatusesAsArray();
		const translatedOptions = useTranslatedDictionaryArray(
			dictionaryKeys.workAcceptanceStatuses,
			statuses
		);

		if (!translatedOptions) return null;

		return statuses ? (
			<BaseListFilterMobile
				{...props}
				options={translatedOptions}
				label={
					props.label ?? t('entitiesFilters.statusesFilter.workAcceptanceStatuses.label')
				}
				filterName={props.filterName ?? 'workAcceptanceStatuses'}
			/>
		) : null;
	}
);
