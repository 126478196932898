import { Dispatch, memo, useCallback } from 'react';
import * as React from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import { useTranslation } from 'react-i18next';

interface IFloorsProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const Floors = <S extends {floor: string}, E>({
	className,
	style,
	label,
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	...restProps
}: IFloorsProps<S, E>) => {
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.floors.label')
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'floor', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'floor'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
			{...restProps}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>

			<TextField
				value={value}
				elementType="input"
				error={required && hasError ? t('components.editableFields.floor.error') : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default memo(Floors) as typeof Floors;
