import {ReactNode} from 'react';
import styles from './TopBar.module.less';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface ITopBarProps {
	breadcrumbs: IBreadcrumb[];
	children: ReactNode;
}

export const TopBar = ({breadcrumbs, children}: ITopBarProps) => {
	const isDesktop = useIsDesktop();

	return (
		<div className={styles.wrapper}>
			<AppBreadcrumbs
				items={breadcrumbs}
				className={styles.breadcrumbs}
			/>
			{isDesktop && <div className={styles.controls}>{children}</div>}
		</div>
	);
};
