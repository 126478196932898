import useAppSelector from '@src/core/hooks/useAppSelector';
import { memo, useCallback } from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formInspectionLink} from '@tehzor/tools/utils/links';
import {EntityInnerTable} from '@tehzor/ui-components';
import {mobileColumns} from '@src/pages/CheckListPage/components/columns.mobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckDetailsEntity} from '@src/interfaces/ICheckDetailsEntity';
import {EmptyPlate} from '@src/pages/CheckListPage/components/detail/EmptyPlate/EmptyPlate';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useExtractTreeCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {
	extractInspectionsEntitiesByItem,
	extractInspectionsEntitiesByList
} from '@src/store/modules/pages/checkLists/selectors/inspections';
import {useTranslation} from "react-i18next";

interface IItem extends ICheckItem {
	children?: any;
}

interface ITableProps {
	item?: IItem;
	list?: ICheckList;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

const inspectionIcon = <i className="tz-inspection" />;

export const InspectionsTable = memo(
	({item, list, selectedRows, onSelectedRowsChange}: ITableProps) => {
		const {t} = useTranslation();
		const {pushPath} = useChangePath();
		const params = useStrictParams<{itemId: string; objectId: string; spaceId?: string}>();
		const {data: treeCheckItem} = useExtractTreeCheckItemById(item?.checkListId, item?.id);
		const inspections = useAppSelector(s => {
			if (item) {
				return extractInspectionsEntitiesByItem(s, treeCheckItem, params.spaceId);
			}
			if (list) {
				return extractInspectionsEntitiesByList(s, list.id);
			}
			return null;
		}) as ICheckDetailsEntity[];
		const handleRowClick = useCallback(
			(entity: ICheckDetailsEntity) => {
				if (entity.type === 'local_inspection') {
					return;
				}
				pushPath(formInspectionLink(params.objectId, entity.id));
			},
			[params.objectId]
		);

		return (
			<div className="check-item-detail__list">
				{inspections?.length > 0 ? (
					<div className="check-item-detail__list-body">
						<EntityInnerTable
							columns={mobileColumns}
							hideHead
							selectedRows={selectedRows}
							onSelectedRowsChange={onSelectedRowsChange}
							data={inspections}
							selectable
							onRowClick={handleRowClick}
						/>
					</div>
				) : (
					<EmptyPlate
						text={t('checkListPage.inspectionsTable.emptyPlate.text')}
						icon={inspectionIcon}
					/>
				)}
			</div>
		);
	}
);
