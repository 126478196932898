import {IDeleteCategoryResponse} from '@src/api/backend/categories';
import {
	IEditableCategoriesSetAction,
	IEditableCategoriesSetState,
	IEditableCategory
} from '@src/core/hooks/states/useCategoriesSetState';
import {addErrorToast} from '@src/utils/toasts';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoryOrder} from '@tehzor/tools/interfaces/categories/ICategoryOrder';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';
import {DndList, DndContext, IDndEvent, IDndRenderItemProps} from '@tehzor/ui-components';
import classNames from 'classnames';
import {orderBy} from 'lodash';
import { Dispatch, SetStateAction, memo, useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import {useUpdateEffect} from 'react-use';
import './EditableCategories.less';
import {Category} from './components/Category';
import {renderPreview} from './utils/renderPreview';

interface IEditableCategoriesProps {
	isEditable?: boolean;
	edited: string[];
	setEdited: Dispatch<SetStateAction<string[]>>;
	editingState: IEditableCategoriesSetState;
	editingDispatch: Dispatch<IEditableCategoriesSetAction>;
	allCategoriesBySet?: ICategory[];
	inDialog?: boolean;
	categoryDialog: React.ReactNode;
	openCategoryDialog: (item: IEditableCategory) => void;
	handleCreate: (parent?: ISavingCategory) => void;
	update: (id: string, index: number) => Promise<ICategory | undefined>;
	updateOrder: (order: ICategoryOrder[]) => Promise<INormalizedData<ICategory> | undefined>;
	remove: (id: string) => Promise<IDeleteCategoryResponse | undefined>;
}

/**
 * Возвращает массив видов работ без удаленных видов,
 * связанных с идентификатором и массив удаленных видов работ
 *
 * @param id Идентификатор вида работ
 * @param categories массив видов работ
 * @returns Возвращает filteredArr и removedArr
 */
const getFilteredArrays = (
	id: string,
	categories: IEditableCategory[]
): [IEditableCategory[], IEditableCategory[]] => {
	const removedArr = categories.filter(val => val?.parentId === id || val.id === id);
	let filteredArr = categories.filter(val => val?.parentId !== id && val.id !== id);

	for (const removedItem of removedArr) {
		filteredArr = filteredArr.filter(val => {
			if (val?.parentId === removedItem.id) {
				removedArr.push(val);
				return false;
			}
			return true;
		});
	}

	return [filteredArr, removedArr];
};

const classes = {
	item: 'editable-categories__item-dnd',
	handle: 'editable-categories__item-dnd-handle',
	itemWithoutHandle: 'editable-categories__item-dnd-without-handle'
};

export const EditableCategories = memo((props: IEditableCategoriesProps) => {
	const {
		edited,
		setEdited,
		editingState,
		editingDispatch,
		allCategoriesBySet,
		isEditable,
		inDialog,
		openCategoryDialog,
		categoryDialog,
		handleCreate,
		update,
		updateOrder,
		remove
	} = props;

	const selector = inDialog ? '.Portal' : undefined;

	const {categories, sortedCategories, draggable} = useMemo(() => {
		const categories = editingState.categories;
		const sortedCategories = orderBy(editingState.categories, ['order'], ['asc']);

		const draggable: string[] = [];
		for (const category of categories) {
			if (category.isLocal || edited.includes(category.id)) {
				continue;
			}
			draggable.push(category.id);
		}

		return {categories, sortedCategories, draggable};
	}, [editingState.categories, edited]);

	const [current, setCurrent] = useState<string | undefined>(undefined);

	useUpdateEffect(() => {
		const length = categories.length;

		if (length) {
			const lastCategory = categories[length - 1];
			setCurrent(lastCategory.id);
		}
	}, [categories.length]);

	const handleChangeEdit = useCallback(
		(id: string) => {
			const index = categories.findIndex(item => item.id === id);
			const category = categories[index];

			if (!edited || !category) {
				return;
			}
			setEdited(s => [...s, id]);
			setCurrent(id);
		},
		[edited, categories]
	);

	const handleConfirm = useCallback(
		async (id: string) => {
			const index = categories.findIndex(item => item.id === id);
			const category = categories[index];

			if (!edited || !category) {
				return;
			}
			if (!category.name) {
				addErrorToast('Ошибка', 'Необходимо указать наименование вида работ');
				return;
			}

			setEdited([...edited.filter(editId => editId !== id)]);
			const value = await update(id, index);

			if (!value) {
				return;
			}

			setCurrent(undefined);
			editingDispatch({type: 'update-array-item', field: 'categories', index, value});
		},
		[edited, categories]
	);

	const handleCancel = useCallback(
		(id: string) => {
			setCurrent(undefined);

			const index = categories.findIndex(item => item.id === id);
			const value = allCategoriesBySet?.find(item => item.id === id);

			if (index === -1) {
				return;
			}
			if (!value) {
				editingDispatch({type: 'delete-array-item', field: 'categories', index: [index]});
				setEdited([...edited.filter(editId => editId !== id)]);
				return;
			}

			editingDispatch({type: 'update-array-item', field: 'categories', index, value});
			setEdited([...edited.filter(editId => editId !== id)]);
		},
		[categories, allCategoriesBySet, edited]
	);

	const handleRemove = useCallback(
		async (id: string) => {
			const [filteredArr, removedArr] = getFilteredArrays(id, categories);

			const promises: Array<Promise<IDeleteCategoryResponse | undefined>> = [];
			removedArr.forEach(item => {
				promises.push(remove(item.id));
			});

			await Promise.all(promises);

			editingDispatch({type: 'update', field: 'categories', value: filteredArr});
			if (categories.length <= 1) {
				handleCreate();
			}
		},
		[categories]
	);

	const handleDragEnd = useCallback(
		async ({items}: IDndEvent<ISavingCategory>) => {
			const newOrder: ICategoryOrder[] = [];
			for (const item of items) {
				if (edited.includes(item.id) || item.isLocal) {
					continue;
				}

				newOrder.push({
					id: item.id,
					parentId: item.parentId,
					order: item.order
				});
			}

			const res = await updateOrder(newOrder);
			if (res) {
				editingDispatch({type: 'update', field: 'categories', value: items});
			} else {
				editingDispatch({
					type: 'update',
					field: 'categories',
					value: editingState.categories
				});
			}
		},
		[updateOrder, edited, editingState.categories]
	);

	const renderItem = useCallback(
		({item, dragState: {item: dragItem}}: IDndRenderItemProps<IEditableCategory>) => (
			<Category
				item={item}
				dragItem={dragItem}
				items={categories}
				edited={edited}
				onCreate={handleCreate}
				onRemove={handleRemove}
				onConfirm={handleConfirm}
				onChangeEdit={handleChangeEdit}
				onCancel={handleCancel}
				isEditable={isEditable}
				current={current}
				setCurrent={setCurrent}
				openCategoryDialog={openCategoryDialog}
			/>
		),
		[
			categories,
			edited,
			current,
			isEditable,
			handleCreate,
			handleRemove,
			handleConfirm,
			handleCancel,
			openCategoryDialog,
			handleChangeEdit
		]
	);

	return (
		<div
			className={classNames('editable-categories', {
				'editable-categories__with-offset': current
			})}
		>
			{sortedCategories?.length ? (
				<DndContext
					renderPreview={renderPreview}
					selector={selector}
					treeOffset={50}
				>
					<DndList
						className={classes}
						items={sortedCategories}
						renderItem={renderItem}
						draggableIds={draggable}
						container="categories-list"
						emptyMessage="Нет видов работ в наборе"
						onDragEnd={handleDragEnd}
					/>
				</DndContext>
			) : (
				<p className="editable-categories__text">Нет видов работ в наборе</p>
			)}
			{categoryDialog}
		</div>
	);
});
