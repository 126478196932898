import {createReducer} from '@reduxjs/toolkit';
import config from '@src/core/config';
import * as types from './constants';
import * as acceptanceSettingsTypes from '@src/store/modules/settings/pages/statistics/acceptance/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import * as problemRepliesTypes from '@src/store/modules/entities/problemReply/constants';
import * as inspectionTypes from '@src/store/modules/entities/inspection/constants';
import * as checkTypes from '@src/store/modules/entities/check/constants';
import IProblemsByCategoriesStatsItem from '@tehzor/tools/interfaces/statistics/IProblemsByCategoriesStatsItem';
import ICreationStatsItem from '@tehzor/tools/interfaces/statistics/ICreationStatsItem';
import {
	IGetAcceptanceProblemsByStatusesStatsPayload,
	IGetAcceptanceProblemsByCategoriesStatsPayload
} from './actions';
import IProblemsByStatusesStatsItem from '@tehzor/tools/interfaces/statistics/IProblemsByStatusesStatsItem';
import {
	IGetInspectionsCreationStatsResponse,
	IGetProblemRepliesCreationStatsResponse,
	IGetProblemsCreationStatsResponse,
	IGetSpacesInspectionStatsResponse
} from '@src/api/backend/statistics/creation';

const {entitiesCacheTime} = config;

export interface IAcceptanceStatisticsState {
	problemsByStatuses: {
		data: IProblemsByStatusesStatsItem[];
		expires?: number;
	};
	problemsByCategories: {
		data: IProblemsByCategoriesStatsItem[];
		expires?: number;
	};
	spacesInspection: {
		data: ICreationStatsItem[];
		expires?: number;
	};
	checksCreation: {
		data: ICreationStatsItem[];
		expires?: number;
	};
	problemsCreation: {
		data: ICreationStatsItem[];
		expires?: number;
	};
	repliesCreation: {
		data: ICreationStatsItem[];
		expires?: number;
	};
	inspectionsCreation: {
		data: ICreationStatsItem[];
		expires?: number;
	};
}

export const getInitialState = (): IAcceptanceStatisticsState => ({
	problemsByStatuses: {data: []},
	problemsByCategories: {data: []},
	spacesInspection: {data: []},
	checksCreation: {data: []},
	problemsCreation: {data: []},
	repliesCreation: {data: []},
	inspectionsCreation: {data: []}
});

export default createReducer<IAcceptanceStatisticsState>(getInitialState(), {
	[types.GET_PROBLEMS_BY_STATUSES_SUCCESS]: (
		state,
		{payload}: {payload: IGetAcceptanceProblemsByStatusesStatsPayload}
	) => {
		state.problemsByStatuses.data = payload;
		state.problemsByStatuses.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_PROBLEMS_BY_CATEGORIES_SUCCESS]: (
		state,
		{payload}: {payload: IGetAcceptanceProblemsByCategoriesStatsPayload}
	) => {
		state.problemsByCategories.data = payload;
		state.problemsByCategories.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_SPACES_INSPECTION_SUCCESS]: (
		state,
		{payload}: {payload: IGetSpacesInspectionStatsResponse}
	) => {
		state.spacesInspection.data = payload;
		state.spacesInspection.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_CHECKS_CREATION_SUCCESS]: (
		state,
		{payload}: {payload: IGetSpacesInspectionStatsResponse}
	) => {
		state.checksCreation.data = payload;
		state.checksCreation.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_PROBLEMS_CREATION_SUCCESS]: (
		state,
		{payload}: {payload: IGetProblemsCreationStatsResponse}
	) => {
		state.problemsCreation.data = payload;
		state.problemsCreation.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_PROBLEM_REPLIES_CREATION_SUCCESS]: (
		state,
		{payload}: {payload: IGetProblemRepliesCreationStatsResponse}
	) => {
		state.repliesCreation.data = payload;
		state.repliesCreation.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_INSPECTIONS_CREATION_SUCCESS]: (
		state,
		{payload}: {payload: IGetInspectionsCreationStatsResponse}
	) => {
		state.inspectionsCreation.data = payload;
		state.inspectionsCreation.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[acceptanceSettingsTypes.CHANGE_FILTERS]: getInitialState,
	[acceptanceSettingsTypes.CLEAR_FILTERS]: getInitialState,
	[acceptanceSettingsTypes.DELETE_FILTERS_ITEM]: getInitialState,
	[acceptanceSettingsTypes.DELETE_FILTERS_ITEMS]: getInitialState,
	[problemTypes.EDIT_STATUS_REQUEST]: state => {
		state.problemsByStatuses.expires = undefined;
	},
	[problemTypes.ADD_SUCCESS]: state => {
		state.problemsCreation.expires = undefined;
		state.problemsByStatuses.expires = undefined;
	},
	[problemTypes.DELETE_SUCCESS]: state => {
		state.repliesCreation.expires = undefined;
		state.problemsCreation.expires = undefined;
		state.problemsByStatuses.expires = undefined;
	},
	[inspectionTypes.ADD_SUCCESS]: state => {
		state.inspectionsCreation.expires = undefined;
	},
	[inspectionTypes.DELETE_SUCCESS]: state => {
		state.inspectionsCreation.expires = undefined;
	},
	[checkTypes.ADD_SUCCESS]: state => {
		state.checksCreation.expires = undefined;
	},
	[checkTypes.DELETE_SUCCESS]: state => {
		state.checksCreation.expires = undefined;
	},
	[problemRepliesTypes.ADD_SUCCESS]: state => {
		state.repliesCreation.expires = undefined;
	},
	[problemRepliesTypes.DELETE_SUCCESS]: state => {
		state.repliesCreation.expires = undefined;
	}
});
