import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import IFile from '@tehzor/tools/interfaces/IFile';
import {IEntity} from '@tehzor/ui-components/src/components/photos/EntitiesDesktopPhotoViewer';
import React, {useCallback, useMemo} from 'react';
import {useEntitiesViewer} from './useEntitiesViewer';

/**
 * Хук для создания просмотрщика изображений общего плана и плана помещений
 *
 * @param images изображения планов
 * @param spaceId id помещения
 * @param imagesData данные изображений
 */
export const useSpacePlanViewer = (
	images: string[],
	spaceId: string,
	imagesData: IFile[]
): [React.ReactNode, (i: number) => void, (id: string, i: number) => void] => {
	// Формирование общего списка изображений
	const data = useMemo(() => {
		const result: IEntity[] = [];
		if (images.length) {
			result.push({
				id: spaceId,
				title: 'План помещения',
				files: images,
				type: AttachmentFileDestination.PLAN,
				attachments: imagesData
			});
		}
		return result;
	}, [images, spaceId, imagesData]);

	const [viewer, openImage] = useEntitiesViewer(data, false);

	const openPlanImage = useCallback(
		(index: number) => {
			openImage(spaceId, index);
		},
		[openImage, spaceId]
	);

	return [viewer, openPlanImage, openImage];
};
