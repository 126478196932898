import {orderBy} from 'lodash';
import {calculateDepth} from './calculateDepth';
import {IDndItem} from '../interfaces';

/**
 * Сортировка массива элементов по order с добавлением depth
 * 
 * @param items массив элементов
 */
export const sortItems = <S extends IDndItem>(items: S[]): S[] => {
	const sortedItems = orderBy(items, ['order'], ['asc']);
	return calculateDepth(sortedItems);
};