import {useMemo} from 'react';
import ProblemPage from './ProblemPage';
import CommentsProvider from '@src/components/Comments/utils/CommentsProvider';
import {
	getCommentsForProblem,
	resetProblemComments
} from '@src/store/modules/pages/problem/actions/comments';
import {
	addProblemReply,
	deleteProblemReply,
	editProblemReply
} from '@src/store/modules/entities/problemReply/actions';
import {
	addProblemComment,
	deleteProblemComment,
	editProblemComment
} from '@src/store/modules/entities/problemComment/actions';
import {getProblem} from '@src/store/modules/pages/problem/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblem} from '@src/store/modules/pages/problem/selectors/problem';
import {changeCommentsVisibility} from '@src/store/modules/settings/pages/problem/actions';
import {extractProblemCommentsAsTree} from '@src/store/modules/pages/problem/selectors/comments';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {getLastProblemReply} from '@src/store/modules/entities/problemsLastReplies/actions';
import {extractProblemLastReply} from '@src/store/modules/entities/problemsLastReplies/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useCommentsPermissions} from '@src/core/hooks/permissions/useCommentsPermissions';
import {getCommentPermissions} from '@src/components/Comments/utils/getCommentPermissions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {convertProblem} from './utils/convertProblem';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import ISavingComment from '@src/interfaces/ISavingComment';

/**
 * Обёртка над страницей нарушения,
 * необходимо для того чтобы при переходе от одного нарушения к другому отрабатывали все методы жизненного цикла
 */
const ProblemPageWrap = () => {
	const {problemId, objectId} = useStrictParams<{objectId: string; problemId: string}>();
	const user = useAppSelector(s => s.auth.profile);
	const extractedProblem = useAppSelector(extractProblem);
	const {data: usersMap} = useUsersAsMap();
	const problem = useMemo(
		() => convertProblem(extractedProblem, usersMap),
		[extractedProblem, usersMap]
	);
	const dispatch = useAppDispatch();
	const comments = useAppSelector(extractProblemCommentsAsTree);

	const getComments = () => {
		void dispatch(getCommentsForProblem(objectId, ICommentTypeId.PROBLEM_COMMENT, problemId));
	};

	const addReply = (savingData: ISavingComment) =>
		dispatch(
			addProblemReply(
				objectId,
				ICommentTypeId.PROBLEM_COMMENT,
				{...problem?.links, problemId},
				savingData
			)
		);

	const problemCommentsPermissions = useCommentsPermissions({
		objectId,
		createdBy: problem?.createdBy,
		performers: problem?.performers,
		inspectors: problem?.inspectors,
		watchers: problem?.watchers
	});
	const commentPermissions = (comment: IComment) =>
		getCommentPermissions(objectId, user, comment);

	const commentDeletion = (commentId: string) =>
		dispatch(deleteProblemComment(objectId, problemId, commentId));

	const replyDeletion = (commentId: string) =>
		dispatch(deleteProblemReply(objectId, problemId, commentId));

	const getEntity = () => dispatch(getProblem(objectId, problemId));
	const hasMore = useAppSelector(s => s.pages.problem.comments.hasMore);
	const visibility = useAppSelector(s => s.settings.pages.problem.comments.visibility);
	const lastReply = useAppSelector(s => extractProblemLastReply(s, problemId));
	const getLastReply = () => {
		void dispatch(getLastProblemReply(objectId, problemId, ICommentTypeId.PROBLEM_COMMENT));
	};
	const reset = () => dispatch(resetProblemComments());
	const addComment = (savingData: ISavingComment) =>
		dispatch(
			addProblemComment(
				objectId,
				ICommentTypeId.PROBLEM_COMMENT,
				{...problem?.links, problemId},
				savingData
			)
		);
	const editComment = (commentId: string, savingData: ISavingComment) =>
		dispatch(editProblemComment(objectId, commentId, savingData));

	const editReply = (commentId: string, savingData: ISavingComment) =>
		dispatch(editProblemReply(objectId, commentId, savingData));

	return (
		<CommentsProvider
			hasMore={hasMore}
			visibility={visibility}
			changeCommentsVisibility={changeCommentsVisibility}
			transferStatus={problem?.transferStatus}
			entityPermissions={problemCommentsPermissions}
			commentPermissions={commentPermissions}
			links={{...problem?.links, problemId}}
			comments={comments}
			getComments={getComments}
			addReply={addReply}
			editReply={editReply}
			addComment={addComment}
			editComment={editComment}
			getEntity={getEntity}
			resetComments={reset}
			deleteComment={commentDeletion}
			deleteReply={replyDeletion}
			lastReply={lastReply}
			getLastReply={getLastReply}
		>
			<ProblemPage />
		</CommentsProvider>
	);
};

export default ProblemPageWrap;
