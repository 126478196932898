import { useCallback } from 'react';
import {VerticalTabs} from '@tehzor/ui-components';
import StatsFiltersTags from './FiltersTags';
import Controls from './Controls';
import Grouping from './Grouping';
import ProblemsStats from './problems/ProblemsByStatusesStats';
import CreationStats from './common/CreationStats';
import {Navigate, Route, Routes, useLocation, useResolvedPath} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeTabIndex} from '@src/store/modules/settings/pages/statistics/acceptance/actions';
import useMount from 'react-use/lib/useMount';
import {getTabIndexByPath} from '../../utils/getTabIndexByPath';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useTranslation} from 'react-i18next';

const tabsPaths = ['/problems', /* '/categories', */ '/common'];

const AcceptanceStats = () => {
	const {replacePath} = useChangePath();
	const {t} = useTranslation();
	const url = useResolvedPath('').pathname;
	const location = useLocation();
	const path = location.pathname;
	const tabIndex = useAppSelector(s => s.settings.pages.statistics.acceptance.tabIndex);
	const dispatch = useAppDispatch();

	const tabsLabels = [
		t('statisticsPage.tabsLabels.byProblems'),
		/* 'По видам работ', */ 
		t('statisticsPage.tabsLabels.common')
	];

	useMount(() => {
		if (location.pathname !== path) {
			const index = getTabIndexByPath(location.pathname, path, tabsPaths);
			if (index !== tabIndex) {
				dispatch(changeTabIndex(index));
			}
		}
	});

	const handleTabChange = useCallback((index: number) => {
		dispatch(changeTabIndex(index));
		replacePath(url + tabsPaths[index]);
	}, []);

	return (
		<div className="statistics-page__section">
			<StatsFiltersTags />

			<Controls />

			<VerticalTabs
				className="statistics-page__tabs"
				sideColumnClassName="statistics-page__tabs-side-column"
				beforeLinks={<Grouping />}
				links={tabsLabels}
				manualMode
				activeTab={tabIndex}
				onActiveTabChange={handleTabChange}
			>
				<Routes>
					<Route
						path={tabsPaths[0]}
						element={<ProblemsStats />}
					/>
					{/* <Route */}
					{/*	path={path + tabsPaths[1]} */}
					{/*	component={CategoriesStats} */}
					{/* /> */}
					<Route
						path={tabsPaths[1]}
						element={<CreationStats />}
					/>
					<Route
						path=""
						element={<Navigate to={url + tabsPaths[tabIndex]} />}
					/>
				</Routes>
			</VerticalTabs>
		</div>
	);
};

export default AcceptanceStats;
