import {useCallback, useState, ReactNode} from 'react';
import {EditableMeterDialog} from '../EditableMeterDialog';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';

export function useEditableMeterDialog(): [
	ReactNode,
	(meter?: IMeter, meterConsumptions?: IMeterConsumption[]) => void
] {
	const [isOpen, setOpen] = useState<boolean>(false);
	const [activeMeter, setActiveMeter] = useState<IMeter | undefined>();
	const [activeMeterConsumptions, setActiveMeterConsumptions] = useState<
		IMeterConsumption[] | undefined
	>();

	const open = useCallback(
		(meter: IMeter | undefined, meterConsumptions: IMeterConsumption[] | undefined) => {
			setActiveMeter(meter);
			setActiveMeterConsumptions(meterConsumptions);
			setOpen(true);
		},
		[]
	);

	const close = useCallback(() => {
		setOpen(false);
		setActiveMeter(undefined);
		setActiveMeterConsumptions(undefined);
	}, []);

	const dialog =
		activeMeter && isOpen ? (
			<EditableMeterDialog
				meter={activeMeter}
				meterConsumptions={activeMeterConsumptions}
				isOpen={isOpen}
				onClose={close}
			/>
		) : null;

	return [dialog, open];
}
