import _ from 'lodash';

export const useNextLocalNumber = <T extends {localNumber?: string}>(
	localEntities?: T[],
	field?: string
) => {
	if (!localEntities) return 1;
	const lastLocalEntity = _.maxBy(localEntities, `${field}`);
	return Number(lastLocalEntity?.localNumber?.split('-')[1] || 0) + 1;
};
