import {useQueryClient} from '@tanstack/react-query';
import {companiesQueryKeys} from '../keys';
import {requestCompanies} from '@src/api/backend/companies';

export const useCompaniesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(companiesQueryKeys.list(), {
		queryFn: requestCompanies,
		staleTime: 1000 * 60 * 60 * 24, // 1 день
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
