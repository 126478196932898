import {requestWorkAcceptanceFrontTypes} from '@src/api/backend/workAcceptanceFrontTypes';
import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';

export const useWorkAcceptanceFrontTypesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.workAcceptanceFrontTypes(), {
		queryFn: requestWorkAcceptanceFrontTypes,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
