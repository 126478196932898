import {IPreparedSpaceStatusesSet} from '@src/pages/manage/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {CellProps} from 'react-table';

export const ModifiedCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) => {
	const {modifiedAt, modifiedBy} = row.original;

	return (
		<div className="space-statuses-sets__cell-modified">
			{modifiedAt && <div>{format(modifiedAt, dateTimeCommaSeparatedFormat)}</div>}
			{modifiedBy && <div>{modifiedBy.displayName}</div>}
		</div>
	);
};
