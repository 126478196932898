import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useCheckListHistoryDialog} from '@src/components/CheckListHistoryDialog/hooks/useCheckListHistoryDialog';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import CheckListExportDialog from '@src/pages/CheckListPage/components/actions/CheckListExportDialog';
import {ICheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useTranslation} from "react-i18next";

interface IListDetailHeaderMenu {
	checkListPermissions: ICheckListPermissions;
	objectId: string;
	checkList?: ICheckList;
	spaceId?: string;
	workAcceptanceId?: string;
}
const historyIcon = <i className="tz-history-24" />;

export const ListDetailHeaderMenu = (props: IListDetailHeaderMenu) => {
	const {checkListPermissions, checkList, spaceId, objectId, workAcceptanceId} = props;
	const {t} = useTranslation();

	const [historyDialog, openHistoryDialog] = useCheckListHistoryDialog(
		objectId,
		checkList?.stage,
		checkList?.id,
		spaceId,
		workAcceptanceId
	);
	const updateData = useAppUpdateMenuItem();

	const items = [updateData];

	const showHistory = !!(
		checkListPermissions?.canViewHistory &&
		(spaceId || workAcceptanceId) &&
		checkList?.stage
	);

	if (showHistory) {
		items.push(
			<MenuItem
				key="history"
				icon={historyIcon}
				onClick={openHistoryDialog}
			>
				{t('checkListPage.menuItem.history.text')}
			</MenuItem>
		);
	}

	const canExportCheckList = spaceId && checkList?.id && checkListPermissions?.canExport;

	return (
		<>
			{canExportCheckList && (
				<CheckListExportDialog
					objectId={objectId}
					spaceId={spaceId}
					checkListId={checkList?.id}
				/>
			)}
			<IconMenu>{items}</IconMenu>
			{showHistory && historyDialog}
		</>
	);
};
