import {EntityCountIndicator} from '@tehzor/ui-components';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {useProblems} from '@src/core/hooks/queries/problems/hooks';
import {useTranslation} from 'react-i18next';
import {useIsFetching} from '@tanstack/react-query';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

interface IProblemsPageHeaderProps {
	objectId?: string;
}

const ProblemsPageCounter = ({objectId = 'all'}: IProblemsPageHeaderProps) => {
	const {state} = useEntitiesFiltersCtx<IProblemsFiltersState>();
	const {data: problems, refetch: refetchProblems} = useProblems(objectId);
	const isFetching = !!useIsFetching({queryKey: problemsQueryKeys.list()});

	const {t} = useTranslation();
	const isFiltersActive = useFiltersActive(objectId, state);
	const text = `${
		isFiltersActive
			? t('problemsPage.counter.prefix.found')
			: t('problemsPage.counter.prefix.total')
	}: `;

	return (
		<EntityCountIndicator
			text={text}
			totalEntities={problems?.total || 0}
			onClick={refetchProblems}
			isFetching={isFetching}
		/>
	);
};

export default ProblemsPageCounter;
