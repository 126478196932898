import {Ref, ReactNode, Dispatch, SetStateAction, useEffect} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import DialogTitle from '../components/DialogTitle';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import AddingAcceptance from '../components/AddingAcceptance';
import {AddingOwnerAcceptanceView} from '../AddingOwnerAcptDialog';
import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import {useOwnerAcceptance} from '@src/core/hooks/queries/ownerAcceptance/hooks';
import {
	useLatestOwnerAcceptanceId,
	useLatestOwnerAcceptancesAsArray
} from '@src/core/hooks/queries/ownerAcceptances';
import {useTranslation} from 'react-i18next';

interface IUseAcceptanceMapsProps {
	objectId: string;
	acceptanceId?: string;
	spaceId?: string;
	stage: ObjectStageIds;
	links?: IOwnerAcceptance['links'];
	view: AddingOwnerAcceptanceView;
	addingEntityRef: Ref<IAddingProblemRefProps | IAddingInspectionRefProps>;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedOwnerAcceptanceId?: string;
	setSelectedOwnerAcceptanceId: Dispatch<SetStateAction<string | undefined>>;

	defaultProblemData?: ISavingProblem;

	onClose: () => void;
}

export const useOwnerAcceptanceMaps = (
	props: IUseAcceptanceMapsProps
): [Record<ObjectStageIds, ReactNode>, Record<ObjectStageIds, IEntitySelectProps>] => {
	const {
		objectId,
		acceptanceId,
		spaceId,
		stage,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedOwnerAcceptanceId,
		setSelectedOwnerAcceptanceId,
		defaultProblemData,
		onClose
	} = props;

	const {t} = useTranslation();

	const contentMap = {} as Record<ObjectStageIds, ReactNode>;
	const entitiesSelectMapProps = {} as Record<ObjectStageIds, IEntitySelectProps>;

	const {data: latestOwnerAcceptanceId} = useLatestOwnerAcceptanceId(objectId, links?.spaceId);
	const {data: latestOwnerAcceptances} = useLatestOwnerAcceptancesAsArray(
		objectId,
		links?.spaceId
	);
	const {data: ownerAcceptance} = useOwnerAcceptance(objectId, acceptanceId);

	useEffect(() => {
		if (latestOwnerAcceptanceId) {
			setSelectedOwnerAcceptanceId(latestOwnerAcceptanceId);
		}
	}, [latestOwnerAcceptanceId, setSelectedOwnerAcceptanceId]);

	contentMap[stage] = (
		<>
			<div className="adding-owner-acpt-dialog__title">
				<DialogTitle view={view} />
			</div>
			{view === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={{...links, ownerAcceptanceId: selectedOwnerAcceptanceId}}
					stage={stage}
					defaultData={defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : view === 'acceptance' ? (
				<AddingAcceptance
					objectId={objectId}
					spaceId={spaceId}
					links={links}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : null}
		</>
	);

	entitiesSelectMapProps[stage] = {
		name: t('globalAddingEntityDialog.useEntitiesMaps.transfer.name'),
		newName: t('globalAddingEntityDialog.useEntitiesMaps.transfer.newName'),
		selected: selectedOwnerAcceptanceId,
		onEntityChange: setSelectedOwnerAcceptanceId,
		withoutNew: !!ownerAcceptance,
		entities: ownerAcceptance ? [ownerAcceptance] : latestOwnerAcceptances,
		localEntities: []
	};

	return [contentMap, entitiesSelectMapProps];
};
