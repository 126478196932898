import {useMemo} from 'react';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

/**
 * Хук для хлебных крошек от "всех объектов" до приёмок работ
 *
 * @param objectId id конечного объекта
 */
export function useWorkAcceptancesBreadcrumbs(objectId: string) {
	const {t} = useTranslation();
	const objects = useObjectsData();
	return useMemo(() => {
		if (!objects) return undefined;
		const items = formObjectsChain(objects?.byId, objectId, 'problems', t);
		items.push({
			label: t('workAcceptancePage.breadcrumbs.label'),
			url: `/objects/${objectId}/work-acceptances`,
			inactive: false
		});
		return items;
	}, [objectId, objects]);
}
