import {useReducer} from 'react';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {errorsFns, init} from '@src/core/hooks/states/useEditableWorkingGroupState/state';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';

export const useEditableWorkingGroupState = (data: {
	workingGroup?: IWorkingGroup;
	responsibilityRules?: IResponsibilityRule[];
}) => useReducer(createReducer(init, errorsFns), data, init);
