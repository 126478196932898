import {wsConnector} from '@src/api/backend/wsConnector';

export type IGenerateSpacesResponse = void;

/**
 * Генерирует помещения из планов
 *
 * @param objectId id объекта
 * @param plans массив выбранных планов, по которым будут генерироваться помещения
 * @param withSpacesPlans флаг для определения необходимости генерации планов помещений
 */
export const makeGenerateSpacesRequest = (
	objectId: string,
	withSpacesPlans: boolean,
	plans?: string[]
) =>
	wsConnector.sendAuthorizedRequest<IGenerateSpacesResponse>('generateSpaces', {
		objectId,
		withSpacesPlans,
		plans
	});
