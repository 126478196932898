import {IMutationErrorsMap} from '@src/interfaces/IMutationErrorsMap';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {offlineJournalActionType} from '../../enums/actionsTypes';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';

export const extractLocalInternalAcceptancesJournal = (
	internalAcceptances: IListInternalAcceptance[],
	errorsMap: IMutationErrorsMap
) =>
	internalAcceptances.map(
		acceptance =>
			({
				entity: {
					entityId: acceptance.id,
					entityLocalNumber: acceptance.localNumber,
					entityType: offlineJournalActionType.INTERNAL_ACCEPTANCE_ADD
				},
				object: {
					objectId: acceptance.object?.id,
					objectName: acceptance.object?.name
				},
				transfer: {
					status: acceptance.transferStatus,
					statusMessage: acceptance.transferStatus
						? offlineModeTransferTitles[acceptance.transferStatus]
						: ''
				},
				createdAt: acceptance.createdAt,
				error: errorsMap[acceptance.id]
			}) as IOfflineJournalEntity
	);
