import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery} from '@tanstack/react-query';
import {IGetSpaceInspectionsResponse} from '../interfaces';
import {extractInspectionsAsArray} from './selectors';

export const useSpaceInspections = <T = IGetSpaceInspectionsResponse>(
	objectId: string,
	spaceId: string,
	permission = true,
	select?: (data: IGetSpaceInspectionsResponse) => T
) =>
	useQuery({
		queryKey: spacesQueryKeys.spaceInspections(objectId, spaceId),
		meta: {
			error: 'при загрузке осмотров'
		},
		select,
		enabled: permission
	});

export const useSpaceInspectionsAsArray = (objectId: string, spaceId: string, permission = true) =>
	useSpaceInspections(objectId, spaceId, permission, extractInspectionsAsArray);
