import {memo} from 'react';
import {CellProps} from 'react-table';
import {IPreparedClaim} from '@src/pages/WarrantyClaimsPage/interfaces/IPreparedClaim';
import {StatusSelect} from '@tehzor/ui-components';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useWarrantyClaimsStatuses} from '@src/core/hooks/queries/warrantyClaimsStatuses/hooks';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import {editWarrantyClaimStatus} from '@src/store/modules/entities/warrantyClaim/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';

export const StatusCell = memo(({row}: CellProps<IPreparedClaim>) => {
	const claim = row.original;
	const objectId = claim.object?.id ?? 'all';
	const dispatch = useAppDispatch();

	const permissions = useWarrantyClaimsPermissions(objectId);

	const {data: statuses} = useWarrantyClaimsStatuses();
	const translatedStatuses = useTranslatedDictionary(
		dictionaryKeys.warrantyClaimStatuses,
		statuses
	);

	const [, handleChange] = useAsyncFn(
		async (v: WarrantyClaimStatusId) => {
			if (claim) {
				await dispatch(editWarrantyClaimStatus(objectId, claim.id, v));
			}
		},
		[objectId, claim]
	);

	return (
		<ClickPreventWrap className="warranty-claims-table__status-cell">
			<StatusSelect
				statuses={translatedStatuses}
				disabled={!permissions.canEditStatus}
				value={claim?.statusId}
				onChange={handleChange}
			/>
		</ClickPreventWrap>
	);
});
