import {useCallback, useMemo, useState} from 'react';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import {IPreparedSpaceTypeDecoration} from '../../interfaces/IPreparedSpaceTypeDecoration';
import {mobileColumns} from './columns.mobile';
import {convertSpaceTypeDecorationSet} from '../../utils/convertSpaceTypeDecoration';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useExtractSpaceTypeDecorationSetsAsArray} from '@src/core/hooks/queries/SpaceTypeDecorationSets/hooks';

export const MobileTable = () => {
	const {pushPath} = useChangePath();
	const {data: spaceTypeDecorationSets, isLoading} = useExtractSpaceTypeDecorationSetsAsArray();

	const preparedData = useMemo(() => {
		if (spaceTypeDecorationSets && spaceTypeDecorationSets.length > 0) {
			return convertSpaceTypeDecorationSet(spaceTypeDecorationSets);
		}
		return [];
	}, [spaceTypeDecorationSets]);

	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const handleRowClick = useCallback(
		(item: IPreparedSpaceTypeDecoration) => {
			pushPath(`/manage/space-type-decoration/${item.id}`);
		},
		[pushPath]
	);

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider>
				<LoadingPanel active={isLoading}>
					<EntitiesTable
						columns={mobileColumns}
						data={preparedData}
						selectedRows={selectedRows}
						onRowClick={handleRowClick}
						onSelectedRowsChange={setSelectedRows}
						headVisible={false}
						noRowBorder
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
