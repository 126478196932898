import {IGetUsersResponse} from '@src/api/backend/users';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractUser, extractUsersAsArray, extractUsersAsMap} from './selectors';
import {useCallback} from 'react';

export const useUsers = <T = IGetUsersResponse>(select?: (data: IGetUsersResponse) => T) =>
	useQuery<IGetUsersResponse, IError, T>({
		networkMode: 'offlineFirst',
		queryKey: restDictionariesQueryKeys.users(),
		meta: {
			error: 'при загрузке пользователей'
		},
		select
	});

export const useUsersAsArray = () => useUsers(extractUsersAsArray);
export const useUsersAsMap = () => useUsers(extractUsersAsMap);

export const useUser = (userId?: string) => {
	const selector = useCallback((data: IGetUsersResponse) => extractUser(data, userId), [userId]);
	return useUsers(selector);
};
