import './SpaceTypeDecorationMobileCell.less';
import {CellProps} from 'react-table';
import {EntityInfo, Tag} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import {MenuCell} from '@src/pages/manage/SpaceTypeDecorationPage/components/table/MenuCell';
import {IPreparedSpaceTypeDecoration} from '@src/pages/manage/SpaceTypeDecorationPage/interfaces/IPreparedSpaceTypeDecoration';
import {useObjectsMap} from '@src/core/hooks/queries/objects/hooks';
import {useCompaniesAsMap} from '@src/core/hooks/queries/companies/hooks';

export const SpaceTypeDecorationMobileCell = (props: CellProps<IPreparedSpaceTypeDecoration>) => {
	const {row} = props;
	const spaceTypeDecoration = row.original;
	const {data: companiesMap} = useCompaniesAsMap();
	const objectsById = useObjectsMap();
	return (
		<div className="m-space-type-decoration-cell">
			<div className="m-space-type-decoration-cell__wrapper">
				<div className="m-space-type-decoration-cell__view">
					<EntityInfo
						backgroundColor="#E8EAEC"
						icon={<i className="tz-space-type-20" />}
					/>
				</div>
				<div className="m-space-type-decoration-cell__text">
					<div className="m-space-type-decoration-cell__title">
						{spaceTypeDecoration.name}
					</div>
					<div className="m-space-type-decoration-cell__company">
						Компания:
						{spaceTypeDecoration?.companyId ? (
							<span>{companiesMap?.get(spaceTypeDecoration.companyId)?.name}</span>
						) : null}
					</div>
					<div className="m-space-type-decoration-cell__objects">
						Объекты:
						{spaceTypeDecoration?.objects
							? spaceTypeDecoration?.objects?.map((objId, i, arr) => {
									if (i < 2) {
										return (
											<Tag
												className="objectTag"
												size="small"
												key={objectsById?.[objId]?.id}
												label={objectsById?.[objId]?.name || ''}
											/>
										);
									}
									if (i === 2) {
										return (
											<Tag
												className="objectTag"
												size="small"
												key={objectsById?.[objId]?.id}
												label={`+${arr?.length - 2}`}
											/>
										);
									}
									return null;
							  })
							: null}
					</div>
					<div className="m-space-type-decoration-cell__date">
						{spaceTypeDecoration.modifiedAt}
						<div className="m-space-type-decoration-cell__name">
							{spaceTypeDecoration.modifiedBy}
						</div>
					</div>
				</div>
			</div>

			<ClickPreventWrap className="m-space-type-decoration-cell__menu-cell">
				<MenuCell {...props} />
			</ClickPreventWrap>
		</div>
	);
};
