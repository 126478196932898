import classNames from 'classnames';
import { ReactNode, memo } from 'react';
import {useDndCtx} from '../../DndContext';
import {useDndHandle} from '../../hooks/useDndHandle';
import {useDndItem} from '../../hooks/useDndItem';
import {IDndItem, OverStatus} from '../../interfaces';
import {IDndRenderItemProps} from '../interfaces/IDndRenderItemProps';

export interface IDndListItemProps<S> {
	className?: {
		item?: string;
		itemContainer?: string;
		itemWithoutHandle?: string;
		handle?: string;
	};
	item: S;
	index: number;
	isOverItem?: boolean;
	overStatus?: OverStatus;

	draggableIds?: string[];
	container?: string;

	renderItem: (props: IDndRenderItemProps<S>) => ReactNode;
}

export const DndListItem = memo(<S extends IDndItem>({
	className,
	item,
	index,
	isOverItem,
	overStatus,
	draggableIds,
	container,
	renderItem
}: IDndListItemProps<S>) => {
	const {dragState, treeOffset = 0} = useDndCtx<S>();
	const {item: dragItem, children: dragChildren, isDragging} = dragState;
	const {depth, id} = item;

	const isChild = !!dragChildren?.find(val => val.id === item.id);
	const isDraggable = draggableIds ? draggableIds.includes(id) : true;
	const marginLeft = depth ? `${depth * treeOffset}px` : undefined;

	const itemRef = useDndItem<HTMLDivElement>(id, container, isDragging);
	const handleRef = useDndHandle(isDragging, isDraggable);

	return !isChild ? (
		<div
			className={classNames(
				{
					isOver: isOverItem,
					top: isOverItem && overStatus === OverStatus.TOP,
					center: isOverItem && overStatus === OverStatus.CENTER,
					bottom: isOverItem && overStatus === OverStatus.BOTTOM
				},
				className?.itemContainer,
				'dnd-list__item-container'
			)}
			style={{marginLeft}}
		>
			<div
				className={classNames(
					{isDragging: isDragging && dragItem?.id === id},
					'dnd-list__item',
					className?.item,
					!isDraggable && className?.itemWithoutHandle
				)}
				ref={itemRef}
			>
				{isDraggable && (
					<i
						className={classNames(
							'tz-drag-and-drop-24',
							'dnd-list__item-handle',
							className?.handle
						)}
						ref={handleRef}
					/>
				)}
				{renderItem({item, dragState, container, index})}
			</div>
		</div>
	) : null;
});