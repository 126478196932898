import {forwardRef, Ref} from 'react';
import './StatusButton.less';
import ButtonBase, {IButtonBaseProps} from '../ButtonBase';
import classNames from 'classnames';
import {isBrightColor} from '../../../utils/isBrightColor';
import {useIsTablet} from '../../../utils/mediaQueries';
import {CircleLoader} from '../../loaders';

interface IProblemStatusButtonProps
	extends Pick<
		IButtonBaseProps,
		| 'className'
		| 'style'
		| 'label'
		| 'disabled'
		| 'outerTagProps'
		| 'onClick'
		| 'onMouseEnter'
		| 'onMouseLeave'
	> {
	color?: string;
	arrowDirection?: boolean;
	actionInProgress?: boolean;
	multiline?: boolean;
}

const StatusButton = (props: IProblemStatusButtonProps, ref?: Ref<HTMLButtonElement>) => {
	const {className, multiline, style, color = '#C4D1E3', arrowDirection, actionInProgress, ...rest} = props;

	const isTablet = useIsTablet();
	const arrowVisible =
		isTablet && arrowDirection !== undefined && !rest.disabled && !actionInProgress;
	const progressIndicator = <CircleLoader className="status-button__loader" />;

	return (
		<ButtonBase
			{...rest}
			className={classNames(
				isBrightColor(color, 0.8) ? 'status-button_dark' : 'status-button_light',
				{'status-button--multiline': multiline},
				className
			)}
			style={{
				...style,
				backgroundColor: color
			}}
			classNamePrefix="status-button"
			rightIconClassName={
				arrowVisible
					? classNames('status-button__arrow-icon', {
							'status-button__arrow-icon_reversed': arrowDirection
					  })
					: undefined
			}
			rightIcon={
				actionInProgress ? (
					progressIndicator
				) : arrowVisible ? (
					<i className="tz-simple-arrow-16" />
				) : undefined
			}
			disabled={actionInProgress}
			outerTagType="button"
			ref={ref}
			labelClassName={multiline ? 'status-button__label--multiline' : undefined}
		/>
	);
};

StatusButton.displayName = 'StatusButton';

export default forwardRef(StatusButton);
