import {Dispatch} from 'react';
import './EditableMeterConsumption.less';
import Text from '../editableFields/Text';
import {
	IEditableMeterConsumptionAction,
	IEditableMeterConsumptionState
} from '@src/core/hooks/states/useEditableMeterConsumptionState';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {MeterTariffSelect} from '../editableFields/MeterTariffSelect';
import {useTranslation} from "react-i18next";

interface IEditableMeterConsumptionProps {
	editingState: IEditableMeterConsumptionState;
	editingDispatch: Dispatch<IEditableMeterConsumptionAction>;
	saving: boolean;
	fieldsSettings: Record<string, IObjectFieldSetting>;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableMeterConsumption = (props: IEditableMeterConsumptionProps) => {
	const {t} = useTranslation();
	const {editingState, editingDispatch, saving, fieldsSettings} = props;

	return (
		<div className="editable-meter-consumption">
			<Text
				field="value"
				label={t('components.editableMeterConsumption.indicationField.label')}
				value={editingState.value}
				editingDispatch={editingDispatch}
				disabled={saving}
				required={fieldsSettings.value.isRequired}
				hasError={editingState.errors.value}
				className="editable-meter-consumption__field"
				errorMessage={t('components.editableMeterConsumption.indicationField.error')}
			/>
			<MeterTariffSelect
				value={editingState.tariffId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.tariffId.isRequired}
				disabled={saving}
				hasError={editingState.errors.tariffId}
				className="editable-meter-consumption__field"
			/>
		</div>
	);
};
