import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {WorkingGroupInfo} from '@tehzor/ui-components';

interface IInitialDelegationInfoProps {
	respUsers?: string[];
	activeGroup?: IWorkingGroup;
	initialGroup?: IWorkingGroup;
}

export const InitialDelegationInfo = ({
	respUsers,
	activeGroup,
	initialGroup
}: IInitialDelegationInfoProps) => {
	const {data: usersMap} = useUsersAsMap();
	const initialGroupLeader =
		initialGroup && usersMap ? usersMap[initialGroup?.leader] : undefined;

	if (initialGroup && (activeGroup?.id !== initialGroup.id || respUsers?.length)) {
		return (
			<WorkingGroupInfo
				className="responsibles-view-field__group"
				name={initialGroup.name}
				leader={initialGroupLeader}
			/>
		);
	}

	return null;
};
