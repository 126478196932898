import {combineReducers} from '@reduxjs/toolkit';
import {createAsyncUIReducer} from '@src/utils/createAsyncUIReducer';
import * as types from '../../sources/constants';

export default combineReducers({
	companiesLoading: createAsyncUIReducer(
		types.GET_COMPANIES_REQUEST,
		types.GET_COMPANIES_SUCCESS,
		types.GET_COMPANIES_FAILURE
	),
	objectsLoading: createAsyncUIReducer(
		types.GET_OBJECTS_REQUEST,
		types.GET_OBJECTS_SUCCESS,
		types.GET_OBJECTS_FAILURE
	),
	// usersLoading: createAsyncUIReducer(
	// 	types.GET_USERS_REQUEST,
	// 	types.GET_USERS_SUCCESS,
	// 	types.GET_USERS_FAILURE
	// ),
	// inspectorsLoading: createAsyncUIReducer(
	// 	types.GET_INSPECTORS_REQUEST,
	// 	types.GET_INSPECTORS_SUCCESS,
	// 	types.GET_INSPECTORS_FAILURE
	// ),
	// contractorsLoading: createAsyncUIReducer(
	// 	types.GET_CONTRACTORS_REQUEST,
	// 	types.GET_CONTRACTORS_SUCCESS,
	// 	types.GET_CONTRACTORS_FAILURE
	// ),
	categoriesLoading: createAsyncUIReducer(
		types.GET_CATEGORIES_REQUEST,
		types.GET_CATEGORIES_SUCCESS,
		types.GET_CATEGORIES_FAILURE
	)
});
