import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IAddCommentResponse,
	makeCommentAddRequest
} from '@src/api/backend/comment';
import {addErrorToast} from '@src/utils/toasts';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import ISavingComment from '@src/interfaces/ISavingComment';

export type IAddWorkAcceptanceCommentPayload = IAddCommentResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddCommentResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении комментария');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет комментарий к приемке работ
 *
 * @param objectId id объекта
 * @param commentTypeId тип комментария
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const addWorkAcceptanceComment = (
	objectId: string,
	commentTypeId: ICommentTypeId,
	links: IComment['links'] | undefined,
	fields: ISavingComment
) =>
	createApiAction<IAddCommentResponse>(request, success, failure, () =>
		makeCommentAddRequest(objectId, commentTypeId,/* problemId,*/ links, fields));
