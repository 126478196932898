import EntitiesFilters, {ObjectsFilter} from '@src/components/EntitiesFilters';
import {StagesFilter} from '@src/pages/ProblemsPage/components/filters/StagesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IWorkingGroupsFiltersState} from '@src/store/modules/settings/pages/manage/workingGroups/slice';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

export const WorkingGroupsFilters = () => {
	const {state, dispatch} = useEntitiesFiltersCtx<IWorkingGroupsFiltersState>();

	const handleSearchClear = () => {
		dispatch({name: undefined});
	};

	const handleChange = (val: string) => {
		dispatch({name: val});
	};

	const {data: stages} = useObjectStagesAsArray();
	return (
		<EntitiesFilters
			entity="manage/working-groups"
			desktopOnly
		>
			<TranslatedSelectSearch
				className="manage-working-groups-page__filters-search"
				type="filter"
				value={state.name}
				onSearch={handleChange}
				onClear={handleSearchClear}
			/>

			<ObjectsFilter value={state.objects} />

			{stages && (
				<StagesFilter
					allStages={stages}
					stages={state.stages}
				/>
			)}
		</EntitiesFilters>
	);
};
