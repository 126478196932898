import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {MeterTariffId} from '@tehzor/tools/interfaces/meterConsumptions/IMeterTariff';
import {MeterTypeId} from '@tehzor/tools/interfaces/meters/IMeterType';
import {ISavingMeter} from '@tehzor/tools/interfaces/meters/ISavingMeter';
import {IMeterState} from './hook';

export type IEditableMeterState = IEditableEntityState<{
	typeId?: MeterTypeId;
	serialNumber?: string;
	description?: string;
	meterConsumptions?: Array<{
		id?: string;
		value?: string;
		tariffId?: MeterTariffId | null;
	}>;
}>;

export type IEditableMeterAction = IEditableEntityAction<IEditableMeterState, IMeterState>;

const makeEmptyState = (): IEditableMeterState => ({
	typeId: undefined,
	serialNumber: '',
	description: '',
	meterConsumptions: undefined,
	errors: {
		typeId: false,
		serialNumber: false,
		description: false,
		meterConsumptions: false
	}
});

export const init = (meter?: IMeterState): IEditableMeterState => {
	const empty = makeEmptyState();
	return meter
		? {
				typeId: meter.typeId || undefined,
				serialNumber: meter.serialNumber || '',
				description: meter.description || '',
				meterConsumptions: meter.meterConsumptions?.map(el => ({
					id: el.id,
					tariffId: el.tariffId,
					value: el.value
				})),
				errors: empty.errors
		  }
		: empty;
};

const isTypeIdEdited = (state: IEditableMeterState, original?: IMeterState) =>
	original?.typeId ? original.typeId !== state.typeId : !!state.typeId;

const isSerialNumberEdited = (state: IEditableMeterState, original?: IMeterState) =>
	original?.serialNumber ? original.serialNumber !== state.serialNumber : !!state.serialNumber;

const isDescriptionEdited = (state: IEditableMeterState, original?: IMeterState) =>
	original?.description ? original.description !== state.description : !!state.description;

const areMeterConsumptionsEdited = (state: IEditableMeterState, original?: IMeterState) =>
	// Если длина массивов отличается, значит есть изменения
	state.meterConsumptions?.length !== original?.meterConsumptions?.length ||
	!!state.meterConsumptions?.some(mc => {
		if (!original?.meterConsumptions) {
			// Если если в оригинале вообще не было показаний, значит есть изменения
			return true;
		}
		const originalMc = original?.meterConsumptions.find(item => item.id === mc.id);
		if (!originalMc) {
			// Если хотя бы для одного из нового id нет существующего, значит есть изменения
			return true;
		}
		// Если хотя бы для одного из нового значение или тариф не соответствует оригиналу, значит есть изменения, иначе нет изменений
		const edit = originalMc.tariffId !== mc.tariffId || originalMc.value !== mc.value;
		return edit;
	});
/**
 * Возвращает значение, показывающее были ли отредактированы поля прибора учёта
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableMeterState, original?: IMeterState): boolean =>
	isEntityEdited(
		state,
		original,
		isTypeIdEdited,
		isSerialNumberEdited,
		isDescriptionEdited,
		areMeterConsumptionsEdited
	);

export const errorsFns = {
	typeId: (state: IEditableMeterState) => !state.typeId,
	serialNumber: (state: IEditableMeterState) => !state.serialNumber,
	description: (state: IEditableMeterState) => !state.description
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableMeterState,
	original?: IMeterState,
	onlyEdited?: boolean
): ISavingMeter => {
	if (!onlyEdited) {
		return {
			typeId: state.typeId,
			serialNumber: state.serialNumber,
			description: state.description
		};
	}
	const meter: ISavingMeter = {};

	if (isTypeIdEdited(state, original)) {
		meter.typeId = state.typeId;
	}
	if (isSerialNumberEdited(state, original)) {
		meter.serialNumber = state.serialNumber;
	}
	if (isDescriptionEdited(state, original)) {
		meter.description = state.description;
	}
	if (areMeterConsumptionsEdited(state)) {
		meter.meterConsumptions = state.meterConsumptions;
	}

	return meter;
};
