import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {IGetSpaceTypesResponse} from '@src/api/backend/spaceTypes';
import {TFunction} from 'i18next';

const getData = (
	spacesMap: Record<string, IListSpace>,
	spaceTypes: IGetSpaceTypesResponse | undefined,
	items: string[] | undefined
): Record<string, string[]> | undefined =>
	items?.reduce(
		(prev, current) => {
			const type = spaceTypes?.byId[spacesMap[current]?.type].name;
			const name = spacesMap[current]?.name;
			if (type && name) {
				if (prev[type]) {
					prev[type].push(name);
				} else {
					prev[type] = [name];
				}
			}
			return prev;
		},
		{} as Record<string, string[]>
	);

export const createSpacesDifference = (
	prev: string[] | undefined,
	next: string[] | undefined,
	spacesMap: Record<string, IListSpace>,
	spaceTypes: IGetSpaceTypesResponse | undefined,
	t: TFunction<'translation', undefined>
): IHistoryData => ({
	prev: getData(spacesMap, spaceTypes, prev),
	next: getData(spacesMap, spaceTypes, next),
	type: HistoryTypeId.SPACES,
	name: t('createDifference.createSpacesDifference')
});
