import {WorkAcceptancePage} from './WorkAcceptancePage';
import CommentsProvider from '@src/components/Comments/utils/CommentsProvider';
import useAppSelector from '@src/core/hooks/useAppSelector';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	getCommentsForWorkAcceptance,
	resetWorkAcceptanceComments
} from '@src/store/modules/entities/workAcceptance/actions/comments';
import {extractWorkAcceptanceCommentsAsTree} from '@src/store/modules/entities/workAcceptance/selectors/comments';
import {
	addWorkAcceptanceReply,
	deleteWorkAcceptanceReply,
	editWorkAcceptanceReply
} from '@src/store/modules/entities/workAcceptanceReply/actions';
import {getCommentPermissions} from '@src/components/Comments/utils/getCommentPermissions';
import {
	addWorkAcceptanceComment,
	deleteWorkAcceptanceComment,
	editWorkAcceptanceComment
} from '@src/store/modules/entities/workAcceptanceComment/actions';
import {extractWorkAcceptanceLastReply} from '@src/store/modules/entities/workAcceptancesLastReplies/selectors';
import {getLastWorkAcceptanceReply} from '@src/store/modules/entities/workAcceptancesLastReplies/actions';
import {changeCommentsVisibility} from '@src/store/modules/settings/pages/workAcceptance/actions';
import {useCommentsPermissions} from '@src/core/hooks/permissions/useCommentsPermissions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import ISavingComment from '@src/interfaces/ISavingComment';

/**
 * Обёртка над страницей приемки работ
 *
 * @param props свойства компонента
 * @constructor
 */
export const WorkAcceptancePageWrap = () => {
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId: string;
	}>();
	const user = useAppSelector(s => s.auth.profile);
	const {data: workAcceptance} = useWorkAcceptance(workAcceptanceId, objectId);
	const dispatch = useAppDispatch();
	const comments = useAppSelector(extractWorkAcceptanceCommentsAsTree);

	const getComments = () => {
		void dispatch(
			getCommentsForWorkAcceptance(
				objectId,
				ICommentTypeId.WORK_ACCEPTANCE_COMMENT,
				workAcceptanceId
			)
		);
	};

	const addReply = (savingData: ISavingComment) =>
		dispatch(
			addWorkAcceptanceReply(
				objectId,
				ICommentTypeId.WORK_ACCEPTANCE_COMMENT,
				{workAcceptanceId},
				savingData
			)
		);

	const workAcceptanceCommentsPermissions = useCommentsPermissions({
		objectId,
		createdBy: workAcceptance?.createdBy,
		performers: workAcceptance?.acceptors,
		watchers: workAcceptance?.watchers
	});
	const commentPermissions = (comment: IComment) =>
		getCommentPermissions(objectId, user, comment);

	const commentDeletion = (commentId: string) =>
		dispatch(deleteWorkAcceptanceComment(objectId, workAcceptanceId, commentId));

	const replyDeletion = (commentId: string) =>
		dispatch(deleteWorkAcceptanceReply(objectId, workAcceptanceId, commentId));

	const hasMore = useAppSelector(s => s.entities.workAcceptance.comments.hasMore);
	const visibility = useAppSelector(s => s.settings.pages.workAcceptance.comments.visibility);
	const lastReply = useAppSelector(s => extractWorkAcceptanceLastReply(s, workAcceptanceId));
	const getLastReply = () => {
		void dispatch(getLastWorkAcceptanceReply(objectId, workAcceptanceId));
	};
	const reset = () => dispatch(resetWorkAcceptanceComments());
	const addComment = (savingData: ISavingComment) =>
		dispatch(
			addWorkAcceptanceComment(
				objectId,
				ICommentTypeId.WORK_ACCEPTANCE_COMMENT,
				{workAcceptanceId},
				savingData
			)
		);
	const editComment = (commentId: string, savingData: ISavingComment) =>
		dispatch(editWorkAcceptanceComment(objectId, commentId, savingData));

	const editReply = (commentId: string, savingData: ISavingComment) =>
		dispatch(editWorkAcceptanceReply(objectId, commentId, savingData));
	return (
		<CommentsProvider
			hasMore={hasMore}
			visibility={visibility}
			changeCommentsVisibility={changeCommentsVisibility}
			entityPermissions={workAcceptanceCommentsPermissions}
			commentPermissions={commentPermissions}
			links={{workAcceptanceId}}
			comments={comments}
			getComments={getComments}
			addComment={addComment}
			editComment={editComment}
			addReply={addReply}
			editReply={editReply}
			resetComments={reset}
			deleteComment={commentDeletion}
			deleteReply={replyDeletion}
			lastReply={lastReply}
			getLastReply={getLastReply}
		>
			<WorkAcceptancePage />
		</CommentsProvider>
	);
};
