function debounce(f: (...a: any) => any, ms: number) {
	let timer: number | undefined;

	return function(...args: any) {
		const onComplete = () => {
			// @ts-ignore
			f.apply(this, args);
			timer = undefined;
		};
		if (timer) {
			clearTimeout(timer);
		}
		timer = window.setTimeout(onComplete, ms);
	};
}

export default debounce;
