import {
	IEditableEntityAction,
	IEditableEntityState
} from '@tehzor/tools/core/states/editableEntityState';
import {ICustomField, IFieldSetting} from '@tehzor/tools/interfaces/fields';
import {DatePicker, EditableFieldLabel, TextFieldWithForwardedRef} from '@tehzor/ui-components';

import {Dispatch, memo, useCallback, useEffect} from 'react';
import {ICustomState} from '../CustomFields';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';
import {format} from 'date-fns';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from 'react-i18next';

interface ICustomDateFieldProps<S, E> {
	field: ICustomField;
	setting: IFieldSetting;
	hasError?: boolean;
	exception?: boolean;
	disabled?: boolean;

	editingState: IEditableEntityState<S>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
}

const getValue = (value: unknown): number | undefined => {
	if (typeof value === 'number') {
		return value;
	}
	return undefined;
};

const getIcon = (icon?: string) =>
	icon ? <i className={icon} /> : <i className="tz-simple-arrow-20" />;

const CustomDateFieldComponent = <S extends ICustomState, E>({
	field,
	setting,
	hasError,
	exception,
	disabled,
	editingState,
	editingDispatch
}: ICustomDateFieldProps<S, E>) => {
	const value = getValue(editingState.customFields?.[field.key]);
	const locale = useDateFnsLocale();
	const {t} = useTranslation();

	useEffect(() => {
		editingDispatch({type: 'create-custom-error', field: field.key});
	}, [field.key]);

	// убираем обязательность поля при отсутствии вариантов для выбора
	useEffect(() => {
		if (setting.required && !exception) {
			const isException = !field.values || field.values.length === 0;

			editingDispatch({
				type: 'update-custom-exception',
				field: field.key,
				value: isException
			});
		}
	}, [field, exception, setting.required]);

	const handleChange = useCallback(
		(val?: Date | null) => {
			editingDispatch({
				field: 'customFields',
				type: 'update',
				value: {...editingState.customFields, [field.key]: val ? val.getTime() : null}
			});

			if (setting.required) {
				editingDispatch({type: 'update-custom-error', field: field.key});
			}
		},
		[editingState.customFields, field, setting.required]
	);

	const handleClearButton = useCallback(() => {
		handleChange(null);

		if (setting.required) {
			editingDispatch({type: 'update-custom-error', field: field.key});
		}
	}, [handleChange, setting.required, field.key]);

	const trigger = useCallback(
		(props: IDatePickerTriggerProps) => (
			<TextFieldWithForwardedRef
				elementType="div"
				value={props?.value !== undefined ? format(props.value, dateFormat) : ''}
				icon={getIcon(field.icon)}
				onClearClick={handleClearButton}
				onClick={props?.toggle}
				disabled={disabled}
				ref={props?.ref}
				cleanable={!!props?.value}
				error={
					setting.required && hasError
						? t('components.editableFields.text.error')
						: undefined
				}
			/>
		),
		[hasError, setting.required, field.icon, handleClearButton]
	);

	const date = value ? new Date(value) : undefined;
	const dialogProps = {title: t('tasksPage.dateTimeSelect.dialogProps')};

	return (
		<div>
			<EditableFieldLabel>
				{setting.required ? `${field.name} *` : field.name}
			</EditableFieldLabel>

			<DatePicker
				trigger={trigger}
				value={date}
				datesOptions={{locale}}
				dialogProps={dialogProps}
				selectCancelBtnLabel={t('cancelBtn.label')}
				selectApplyBtnLabel={t('applyBtn.label')}
				InlineDatePickerLabel={t('inlineDatePicker.label')}
				useApplyButton
				onChange={handleChange}
			/>
		</div>
	);
};

export const CustomDateField = memo(CustomDateFieldComponent) as typeof CustomDateFieldComponent;
