import { memo, useCallback } from 'react';
import classNames from 'classnames';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {longDateFormat} from '@tehzor/tools/utils/dateFormats';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useDateFnsLocale} from "@src/core/hooks/translations/useDateFnsLocales";
import {useTranslation} from "react-i18next";

interface ICreationDateFilterProps {
	from?: Date;
	to?: Date;
	label?: string;
	className?: string;
}

export const CreationDateFilter = memo((props: ICreationDateFilterProps) => {
	const {label = 'Дата добавления', className, from, to} = props;
	const {t: translate} = useTranslation();
	const dateFrom = from ? new Date(from) : undefined;
	const dateTo = to ? new Date(to) : undefined;

	const {dispatch} = useEntitiesFiltersCtx();
	const handleChange = useCallback(
		(f: Date | null, t: Date | null) => {
			const createdAtFrom = f
				? new Date(f.getFullYear(), f.getMonth(), f.getDate())
				: undefined;
			const createdAtTo = t
				? new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999)
				: undefined;
			dispatch({createdAtFrom, createdAtTo});
		},
		[dispatch]
	);

	const handleClear = useCallback(() => {
		dispatch({createdAtFrom: undefined, createdAtTo: undefined});
	}, [dispatch]);

	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	return (
		<DateRangePicker
			trigger={triggerProps => (
				<FilterButton
					className={classNames('entities-filters__item', className)}
					label={makeDateFilterLabel(label, dateFrom, dateTo)}
					active={dateFrom !== undefined || dateTo !== undefined}
					onClick={triggerProps.toggle}
					onClear={handleClear}
					ref={triggerProps.ref}
				/>
			)}
			datesOptions={datesOptions}
			valueFrom={dateFrom}
			valueTo={dateTo}
			dateFormat={longDateFormat}
			useApplyButton
			onChange={handleChange}
			selectApplyBtnLabel={translate('applyBtn.label')}
			selectCancelBtnLabel={translate('cancelBtn.label')}
		/>
	);
});
