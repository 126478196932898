import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

export type IEditTaskResponsibleResponse = ITask;
/**
 * Изменяет ответственных задачи
 *
 * @param objectId id объекта
 * @param taskId id задачи
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */

export interface IMakeTaskResponsibleRequestData {
	objectId: string,
	taskId: string,
	respUsers?: string[],
	activeGroup?: string
}
export const makeTaskResponsible = async (data: IMakeTaskResponsibleRequestData) => {
	const response = await httpRequests.withToken.patch<IEditTaskResponsibleResponse>(
		'tasks/responsible',
		data
	);
	return response.data;
};
