import {useCallback} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/interfaces';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {useChecksQuery} from '@src/core/hooks/queries/checks/hooks';
import {checksActions} from '@src/store/modules/settings/pages/checks/slice';

const ChecksFiltersRoutingPage = () => {
	const {objectId = 'all'} = useParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractChecksPageSettings(s, objectId));
	useChecksQuery(objectId || 'all');
	const {clearFilters, changeFilters, changeOffset} = checksActions;
	const dispatch = useAppDispatch();

	const applyFilters = useCallback(
		(value: IChecksFiltersState) => {
			dispatch(changeFilters({objectId: objectId || 'all', filters: value}));
			dispatch(changeOffset({objectId: objectId || 'all', offset: 0}));
		},
		[changeFilters, changeOffset, dispatch, objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: 'all'}));
	}, [clearFilters, dispatch]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};

export default ChecksFiltersRoutingPage;
