import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback} from 'react';
import {extractLocalOwnerAcceptancesJournal} from './selector';

export const useLocalOwnerAcceptancesJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: ownerAcceptancesQueryKeys.add()});
	const selector = useCallback(
		(ownerAcceptances: IListOwnerAcceptance[]) =>
			extractLocalOwnerAcceptancesJournal(ownerAcceptances, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IListOwnerAcceptance[], unknown, IOfflineJournalEntity[]>({
		queryKey: ownerAcceptancesQueryKeys.localList(),
		select: selector
	});
	return data || [];
};
