import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {deleteWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions';
import {useAsyncFn} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useEditableWorkAcceptanceDialog} from '@src/components/EditableWorkAcceptanceDialog/hooks/useEditableWorkAcceptanceDialog';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useCheckListHistoryDialog} from '@src/components/CheckListHistoryDialog/hooks/useCheckListHistoryDialog';
import {useWorkAcceptanceHistoryDialog} from '@src/components/WorkAcceptanceHistoryDialog/hooks/useWorkAcceptanceHistoryDialog';
import {useTranslation} from 'react-i18next';

interface IMobileMenuProps {
	workAcceptance: IWorkAcceptance;
	permissions?: IPermissions;
}

const historyIcon = <i className="tz-history-24" />;
const editIcon = <i className="tz-edit" />;
const deleteIcon = <i className="tz-delete" />;

export const MobileMenu = ({workAcceptance, permissions}: IMobileMenuProps) => {
	const {t} = useTranslation();

	const params = useStrictParams<{
		objectId: string;
		listId?: string;
	}>();
	const {objectId, listId} = params;

	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const checkListPermissions = useCheckListPermissions(objectId);
	const {data: checkList} = useExtractCheckListById(listId);
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		t('useConfirmDialog.workAcceptance.deleteTitle'),
		t('useConfirmDialog.workAcceptance.deleteMessage', {id: workAcceptance.number}),
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('useConfirmDialog.rejectBtnLabel')
		}
	);

	const [workAcceptanceHistoryDialog, openWorkAcceptanceHistoryDialog] =
		useWorkAcceptanceHistoryDialog(objectId, workAcceptance.id);

	const [historyDialog, openHistoryDialog] = useCheckListHistoryDialog(
		objectId,
		checkList?.stage,
		checkList?.id,
		undefined,
		workAcceptance.id
	);
	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteWorkAcceptance(workAcceptance.objectId, workAcceptance.id));
			goBack();
		}
	}, [workAcceptance.objectId, workAcceptance.id]);

	// Диалог редактирования приёмки работ
	const [workAcceptanceDialog, openEditDialog] = useEditableWorkAcceptanceDialog(
		workAcceptance.objectId,
		workAcceptance.stage,
		workAcceptance
	);

	const items = [];

	items.push(updateData);

	if (workAcceptance && permissions?.canViewHistory) {
		items.push(
			<MenuItem
				key="work-acceptance-history"
				icon={historyIcon}
				onClick={openWorkAcceptanceHistoryDialog}
			>
				{t('workAcceptancePage.menu.workAcceptanceUpdateHistory')}
			</MenuItem>
		);
	}

	if (checkListPermissions.canViewHistory && checkList) {
		items.push(
			<MenuItem
				key="checklist-history"
				icon={historyIcon}
				onClick={openHistoryDialog}
			>
				{t('workAcceptancePage.menu.workAcceptanceUpdateHistory')}
			</MenuItem>
		);
	}
	if (permissions?.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={openEditDialog}
			>
				{t('workAcceptancePage.menu.edit')}
			</MenuItem>
		);
	}

	if (permissions?.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				{t('workAcceptancePage.menu.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions?.canDelete && deleteDialog}
			{permissions?.canEdit && workAcceptanceDialog}
			{checkListPermissions.canViewHistory && checkList && historyDialog}
			{permissions?.canViewHistory && workAcceptanceHistoryDialog}
		</>
	) : null;
};
