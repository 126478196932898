import { useCallback } from 'react';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch'
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IDocumentsFiltersState, documentsActions} from '@src/store/modules/settings/pages/documents/reducers';
import {extractDocumentsPageSettings} from '@src/store/modules/settings/pages/documents/selectors';
import {DocumentsPage} from '../DocumentsPage';

export const DocumentsPageWrap = () => {
	const dispatch = useAppDispatch();

	const {filters} = useAppSelector(extractDocumentsPageSettings);
	const {changeFilters, clearFilters, changeOffset} = documentsActions;

	const applyFilters = useCallback((value: IDocumentsFiltersState) => {
		dispatch(changeFilters(value));
		dispatch(changeOffset(0));
	}, []);

	const onClear = useCallback(() => {
		dispatch(clearFilters());
	}, []);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<DocumentsPage/>
		</EntitiesFiltersProvider>
	);
}