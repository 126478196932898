import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTaskStatuses} from '@src/core/hooks/queries/taskStatuses/hook';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {getSelectedStatus} from '@tehzor/ui-components/src/components/inputs/statuses/BaseStatusSelect/utils/getSelectedStatus';
import {IStatus} from '@tehzor/ui-components/src/components/inputs/statuses/StatusSelect/utils/IStatus';
import {CellProps} from 'react-table';

export const TaskStatusMobileCell = (
	props: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'value'>
) => {
	const {value} = props;
	const task = value;

	const {data: statuses} = useTaskStatuses();
	const translatedStatuses = useTranslatedDictionary(dictionaryKeys.taskStatuses, statuses);

	const selectedStatus = getSelectedStatus<IStatus>(task?.status?.id, translatedStatuses);

	return (
		<div
			className="tasks-page__m-table-cell-status"
			style={{color: selectedStatus?.color || '#000'}}
		>
			{selectedStatus?.name}
		</div>
	);
};
