/**
 * Извлекает массив файлов из события
 *
 * @param {Event} event событие
 * @copyright react-dropzone
 * @return {Array} файлы
 */
function getDataTransferFiles(event: React.ChangeEvent<HTMLInputElement>): File[] {
	// let dataTransferItemsList = [];
	// if (event.dataTransfer) {
	// 	const dt = event.dataTransfer;
	// 	if (dt.files && dt.files.length) {
	// 		dataTransferItemsList = dt.files;
	// 	} else if (dt.items && dt.items.length) {
	// 		// During the drag even the dataTransfer.files is null
	// 		// but Chrome implements some drag store, which is accesible via dataTransfer.items
	// 		dataTransferItemsList = dt.items;
	// 	}
	// } else
	if (event.target && event.target.files) {
		return Array.prototype.slice.call(event.target.files);
	}
	// Convert from DataTransferItemsList to the native Array
	// return Array.prototype.slice.call(dataTransferItemsList);
	return [];
}

export default getDataTransferFiles;
