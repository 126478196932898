import {ReactNode, useCallback, useMemo, useState} from 'react';
import './LocationSelect.less';
import classNames from 'classnames';
import {BeforeLargeTablet, LargeTablet, useIsLargeTablet} from '../../utils/mediaQueries';
import Controls from './components/Controls';
import {PlanViewer} from '../PlanViewer';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import useToggle from 'react-use/lib/useToggle';
import MapViewer from './components/MapViewer';
import ActionButtons from '../buttons/ActionButtons';
import Button from '../buttons/Button';
import {TabContent, TabLink, Tabs} from '../containers/Tabs';
import {TextField} from '../inputs/TextField';
import {useLocSelectState} from './hooks/useLocSelectState';
import {usePlanState} from './hooks/usePlanState';
import {useVisibleLayersState} from './hooks/useVisibleLayersState';
import {useDisplayValueState} from './hooks/useDisplayValueState';
import {getViewerTabTitle} from './utils/getViewerTabTitle';
import {PlanChooseDialog} from './components/PlanChooseDialog';
import {MarkerCommentDialog} from '../MarkerCommentDialog/MarkerCommentDialog';
import {Path} from '../PlanViewer/PlanViewer';
import {ModalDialog} from '../dialogs';

export type Mode = 'view' | 'edit';

export type ViewerType = 'plan' | 'map';

export type InputType = 'sectors' | 'points';

export interface ILocationSelectTranslationProps {
	dialogAcceptBtnLabel?: string;
	dialogRejectBtnLabel?: string;
	dialogCloseBtnLabel?: string;
	addCommentPrompt?: string;
	paramsBtn?: string;
	result: string;
	markedPoints: string;
	selectedSectors: string;
	display: string;
	planMode: string;
	mapMode: string;
	selectionMethod: string;
	pointsT: string;
	sectors: string;
	layersT: string;
	layersMap?: Record<string, string>;
}

export interface ILocationSelectProps {
	className?: string;
	children: (displayValue: string, open: () => void) => ReactNode;
	mode?: Mode;
	defaultViewerType?: ViewerType;
	defaultInputType?: InputType;
	availableViewerTypes?: ViewerType[];
	availableInputTypes?: InputType[];
	planId?: string;
	location?: ILocation;
	entityLocation?: ILocation;
	plans?: IPlan[];
	multiplePoints?: boolean;
	pointsColor?: string;
	path?: Path;
	translations?: ILocationSelectTranslationProps;
	onLocationChange?: (location: ILocation, planId?: string) => void;
	onViewerTypeChange?: (value: ViewerType) => void;
	onInputTypeChange?: (value: InputType) => void;
	onPlanChange?: (value?: string) => void;
}

/**
 * Компонент для выбора местоположения
 */

const LocationSelect = (props: ILocationSelectProps) => {
	const {
		className,
		children,
		mode = 'view',
		defaultViewerType,
		defaultInputType,
		availableViewerTypes = ['plan'],
		availableInputTypes = ['sectors', 'points'],
		planId: controlledPlanId,
		location,
		entityLocation,
		plans = [],
		multiplePoints = false,
		pointsColor,
		path = 'problem',
		onLocationChange,
		translations = {
			paramsBtn: 'Параметры',
			addCommentPrompt: 'Добавьте комментарий',
			dialogAcceptBtnLabel: 'Сохранить',
			dialogRejectBtnLabel: 'Отменить',
			dialogCloseBtnLabel: 'Закрыть',
			result: 'Результат',
			markedPoints: 'Отмеченные точки',
			selectedSectors: 'Выбранные секторы',
			display: 'Отображение',
			planMode: 'План',
			mapMode: 'Карта',
			selectionMethod: 'Способ выбора',
			pointsT: 'Точки',
			sectors: 'Секторы',
			layersT: 'Слои',
			layersMap: {}
		},
		onViewerTypeChange,
		onInputTypeChange,
		onPlanChange
	} = props;
	const isDesktop = useIsLargeTablet();
	const [isOpen, toggleOpen] = useToggle(false);
	const [
		planSectors,
		planPoints,
		mapPoints,
		setPlanSectors,
		setPlanPoints,
		setMapPoints,
		viewerType,
		setViewerType,
		inputType,
		setInputType,
		clearLocation,
		resetLocation,
		entitySectors,
		entityPoints
	] = useLocSelectState(location, defaultViewerType, defaultInputType, entityLocation);

	const {
		dialogAcceptBtnLabel,
		dialogRejectBtnLabel,
		dialogCloseBtnLabel,
		addCommentPrompt,
		paramsBtn,
		layersT,
		display,
		selectionMethod,
		selectedSectors,
		sectors,
		result,
		markedPoints,
		pointsT,
		mapMode,
		planMode,
		layersMap
	} = translations;

	const [currentPlanId, image, layers, setPlanId] = usePlanState(plans, controlledPlanId);

	const translatedLayers = useMemo(() => {
		if (!layersMap) return layers;
		return layers.map(layer => {
			if (!layer.type) return layer;
			return {...layer, name: layersMap[layer.type] || layer.name};
		});
	}, [layers, layersMap]);

	const [visibleLayers, setVisibleLayers] = useVisibleLayersState(translatedLayers);
	const displayValue = useDisplayValueState(
		viewerType,
		inputType,
		planSectors,
		planPoints,
		mapPoints,
		translatedLayers
	);
	const [activeTab, setActiveTab] = useState(currentPlanId === undefined ? 1 : 0);
	const [showCommentPopup, setShowCommentPopup] = useState(false);
	const [singleSelectedPoint, setSingleSelectedPoint] = useState<number>();
	const [singleSelectedSector, setSingleSelectedSector] = useState<number>();

	const handleInputTypeChange = useCallback(
		(value: InputType) => {
			setInputType(value);
			if (onInputTypeChange) {
				onInputTypeChange(value);
			}
		},
		[onInputTypeChange, setInputType]
	);

	const handleViewerTypeChange = useCallback(
		(value: ViewerType) => {
			setViewerType(value);
			if (onViewerTypeChange) {
				onViewerTypeChange(value);
			}
			// TODO Убрать после добавления секторов на карту
			handleInputTypeChange('points');
		},
		[handleInputTypeChange, onViewerTypeChange, setViewerType]
	);

	const handlePlanChange = useCallback(
		(value: string) => {
			// Очистка текущего места при изменении плана
			clearLocation();
			setPlanId(value);
			if (currentPlanId === undefined) {
				setActiveTab(0);
			}
		},
		[clearLocation, currentPlanId, setPlanId]
	);

	const handleOkClick = useCallback(() => {
		if (onLocationChange) {
			const result = {} as ILocation;
			if (viewerType === 'plan') {
				if (inputType === 'sectors') {
					result.sectors = planSectors;
				} else if (inputType === 'points') {
					result.points = planPoints;
				}
				if (onPlanChange) {
					onPlanChange(currentPlanId);
				}
			} else if (viewerType === 'map') {
				if (inputType === 'points') {
					result.mapPoints = mapPoints;
				}
			}
			onLocationChange(result, currentPlanId);
		}
		toggleOpen(false);
	}, [
		viewerType,
		inputType,
		planSectors,
		planPoints,
		mapPoints,
		currentPlanId,
		onLocationChange,
		onPlanChange,
		toggleOpen
	]);

	const handleCancelClick = useCallback(() => {
		toggleOpen(false);
		// Сброс текущего места при отмене
		resetLocation();
	}, [resetLocation, toggleOpen]);

	const onSelectSinglePoint = (pointIndex: number) => {
		setShowCommentPopup(true);
		setSingleSelectedPoint(pointIndex);
	};

	const onSelectSingleSector = (sectorIndex: number) => {
		setShowCommentPopup(true);
		setSingleSelectedSector(sectorIndex);
	};

	const onClosePointCommentInputModal = () => {
		setShowCommentPopup(false);
		setSingleSelectedPoint(undefined);
	};

	const onCloseSectorCommentInputModal = () => {
		setShowCommentPopup(false);
	};

	/**
	 * Возвращает отмеченные точки в виде строки
	 *
	 * @param points точки
	 */
	const actions =
		mode === 'edit' ? (
			<ActionButtons className="loc-select__action-buttons">
				<Button type="accent-blue" label={dialogAcceptBtnLabel} onClick={handleOkClick} />
				<Button type="cancel" label={dialogRejectBtnLabel} onClick={handleCancelClick} />
			</ActionButtons>
		) : (
			<ActionButtons className="loc-select__action-buttons">
				<Button label={dialogCloseBtnLabel} onClick={toggleOpen} />
			</ActionButtons>
		);

	const deletePointComment = () => {
		setPlanPoints(planPoints.filter((e, i) => i !== singleSelectedPoint));
		onClosePointCommentInputModal();
	};

	const deleteSectorComment = () => {
		setPlanSectors(
			planSectors
				.filter((el, i) => i !== singleSelectedSector)
				.map(el => ({...el, selected: false}))
		);
		onClosePointCommentInputModal();
	};

	const pointCommentInputOnApply = (pointDescription?: string) => {
		setPlanPoints(s =>
			s.map((el, i) => {
				if (i === singleSelectedPoint) {
					return {...el, description: pointDescription};
				}
				return el;
			})
		);
		onClosePointCommentInputModal();
	};

	const sectorCommentInputOnApply = (sectorDescription?: string) => {
		setPlanSectors(s =>
			s.map((el, i) => {
				if (i === singleSelectedSector) {
					return {...el, description: sectorDescription, selected: false};
				}
				return {...el, selected: false};
			})
		);
		onCloseSectorCommentInputModal();
	};

	const closeSectorCommentInputModal = () => {
		setPlanSectors(planSectors.map(el => ({...el, selected: false})));
		onCloseSectorCommentInputModal();
	};

	const pointCommentInputModal = singleSelectedPoint !== undefined &&
		planPoints[singleSelectedPoint] && (
			<MarkerCommentDialog
				translation={{
					reject: dialogRejectBtnLabel,
					addCommentPrompt,
					save: dialogAcceptBtnLabel
				}}
				show={showCommentPopup}
				onClose={onClosePointCommentInputModal}
				onApply={pointCommentInputOnApply}
				title={planPoints[singleSelectedPoint].name}
				deleteComment={deletePointComment}
				description={planPoints[singleSelectedPoint].description || ''}
				canEdit={mode === 'edit'}
			/>
		);

	const sectorCommentInputModal = singleSelectedSector !== undefined &&
		planSectors[singleSelectedSector] && (
			<MarkerCommentDialog
				show={showCommentPopup}
				translation={{
					reject: dialogRejectBtnLabel,
					addCommentPrompt,
					save: dialogAcceptBtnLabel
				}}
				onClose={closeSectorCommentInputModal}
				onApply={sectorCommentInputOnApply}
				title={planSectors[singleSelectedSector].name}
				deleteComment={deleteSectorComment}
				description={planSectors[singleSelectedSector].description || ''}
				canEdit={mode === 'edit'}
			/>
		);

	const viewer =
		viewerType === 'plan' ? (
			<div className="loc-select__viewer">
				<PlanViewer
					mode={mode}
					inputType={inputType}
					image={image}
					layers={translatedLayers}
					visibleLayers={visibleLayers}
					sectors={planSectors}
					points={planPoints}
					multiplePoints={multiplePoints}
					onSectorsChange={setPlanSectors}
					onPointsChange={setPlanPoints}
					selectPoint={onSelectSinglePoint}
					singleSelectedPoint={singleSelectedPoint}
					selectSector={onSelectSingleSector}
					pointsColor={pointsColor}
					entitySectors={entitySectors}
					entityPoints={entityPoints}
					path={path}
				/>
			</div>
		) : viewerType === 'map' ? (
			<MapViewer
				className="loc-select__viewer"
				mode={mode}
				points={mapPoints}
				onPointsChange={setMapPoints}
			/>
		) : null;

	const isCombined = planSectors.length !== displayValue.length;

	const ControlsDisplayValue =
		inputType === 'sectors'
			? // Если тип - Секторы, тогда
			  // если количество секторов отличается от количество отображаемых названий,
			  // значит сработало объединение секторов, и отображаем только названия объединенных секторов,
			  // но если длина одинаковая то отображаем сами секторы с комментариями
			  isCombined
				? displayValue.map((el: string) => ({name: el}))
				: planSectors.map(item => ({
						name: item.name || '',
						description: item.description || ''
				  }))
			: planPoints.map(item => ({
					name: item.name || '',
					description: item.description || ''
			  }));

	const onValueSelect = (index: number) => {
		if (inputType === 'sectors' && !isCombined) {
			onSelectSingleSector(index);
		}
		if (inputType === 'points') {
			onSelectSinglePoint(index);
		}
	};

	const controls = (
		<Controls
			viewerType={viewerType}
			inputType={inputType}
			planId={currentPlanId}
			plans={plans}
			layers={translatedLayers}
			visibleLayers={visibleLayers}
			displayValue={ControlsDisplayValue}
			availableViewerTypes={availableViewerTypes}
			availableInputTypes={availableInputTypes}
			onViewerTypeChange={handleViewerTypeChange}
			onInputTypeChange={handleInputTypeChange}
			onVisibleLayersChange={setVisibleLayers}
			onPlanChange={handlePlanChange}
			onValueSelect={onValueSelect}
			translated={{
				layersT,
				display,
				selectionMethod,
				selectedSectors,
				pointsT,
				map: mapMode,
				plan: planMode,
				markedPoints,
				result,
				sectors
			}}
		/>
	);

	return (
		<div className={classNames('loc-select', className)}>
			{children(displayValue.join(', '), toggleOpen)}

			<ModalDialog
				dataTestId="LocationSelectDialog"
				className={{
					layer: 'loc-select__dialog-layer',
					content: 'loc-select__dialog-content',
					body: 'loc-select__dialog-body'
				}}
				open={isOpen}
				footer={actions}
				hideCloseButton
				useContentOpenAnimation
				useContentCloseAnimation
				fullScreen
				onRequestClose={toggleOpen}
				// onAfterOpen={onAfterOpen}
				fullScreenOnMobile
			>
				<div className="loc-select__wrap">
					{mode === 'view' && (
						<>
							{viewer}
							{pointCommentInputModal}
							{sectorCommentInputModal}
						</>
					)}

					{mode === 'edit' && (
						<>
							<BeforeLargeTablet>
								<Tabs
									className={{
										root: 'loc-select__tabs',
										links: 'loc-select__tabs-links',
										content: 'loc-select__tabs-content'
									}}
									activeTab={activeTab}
									links={[
										<TabLink
											key="viewer"
											label={getViewerTabTitle({
												viewerType,
												map: mapMode,
												plan: planMode
											})}
										/>,
										<TabLink key="params" label={paramsBtn} />
									]}
									onActiveTabChange={setActiveTab}
								>
									<TabContent className="loc-select__tab-content">
										<TextField
											className="loc-select__controls-result loc-select__controls-result_margin"
											elementType="input"
											disabled
											value={displayValue.join(', ')}
										/>
										{viewer}
										{pointCommentInputModal}
										{sectorCommentInputModal}
									</TabContent>

									<TabContent className="loc-select__tab-content">
										{controls}
										{pointCommentInputModal}
										{sectorCommentInputModal}
									</TabContent>
								</Tabs>
							</BeforeLargeTablet>

							<LargeTablet>
								{controls}
								{viewer}
								{pointCommentInputModal}
								{sectorCommentInputModal}

								{isOpen && isDesktop && currentPlanId === undefined && (
									<PlanChooseDialog
										plans={plans}
										onPlanChange={handlePlanChange}
									/>
								)}
							</LargeTablet>
						</>
					)}
				</div>
			</ModalDialog>
		</div>
	);
};

export default LocationSelect;
