import {OfflineDataItem} from '@tehzor/ui-components';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {useCacheStates} from '../hooks/useCacheStates';
import {useTranslation} from "react-i18next";

export const GroupsAndRulesCacheStatus = () => {
	const {t} = useTranslation();
	const [isGroupsFetching, isGroupsError, isGroupsStale, isGroupsPaused, groupsDataUpdatedAt]
		= useCacheStates(workingGroupsQueryKeys.list());
	const [isRulesFetching, isRulesError, isRulesStale, isRulesPaused, rulesDataUpdatedAt]
		= useCacheStates(responsibilityRulesQueryKeys.list());
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t('components.offlineModeDialog.groupsAndRulesCacheStatus.offlineDataItem.title')}
			icon={<i className="tz-internal-acceptance-24"/>}
			date={Math.max(groupsDataUpdatedAt ?? 0, rulesDataUpdatedAt ?? 0)}
			error={
				isGroupsError || isRulesError
					? t('cacheStatusErrors.error')
					: isGroupsStale || isRulesStale
					? t('cacheStatusErrors.stale')
					: undefined
			}
			inProgress={isGroupsPaused || isGroupsFetching || isRulesPaused || isRulesFetching}
			progressTitle={t('components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default')}
		/>
	);
};
