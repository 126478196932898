import { memo, useState } from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {DesktopActions} from '../actions/Actions.desktop';
import {EntitiesTabs} from '../EntitiesTabs';
import {Plate} from '@tehzor/ui-components';
import {Table} from './Table';
import {useDesktopColumns} from './columns.desktop';

interface IDesktopProps {
	objectId: string;
	spaceId: string;
	space: ISpace;
}

export const DesktopTable = memo((props: IDesktopProps) => {
	const {space, objectId, spaceId} = props;
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const desktopColumns = useDesktopColumns(objectId, spaceId);

	return (
		<div className="space-page__d-column space-page__d-column_entities">
			<DesktopActions space={space} />

			<Plate withoutPadding>
				<EntitiesTabs
					objectId={objectId}
					spaceId={spaceId}
				/>

				<Table
					objectId={objectId}
					columns={desktopColumns}
					spaceId={spaceId}
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
				/>
			</Plate>
		</div>
	);
});
