import {OfflineModeStatus} from '@tehzor/tools/enums/OfflineModeStatus';

export const offlineModeIcons: Record<OfflineModeStatus, string> = {
	[OfflineModeStatus.UNKNOWN]: 'tz-cloud-caching-error',
	[OfflineModeStatus.SAVING]: 'cloud-sync',
	[OfflineModeStatus.SAVING_COMPLETE]: 'cloud-sync',
	[OfflineModeStatus.SAVING_ERROR]: 'tz-cloud-caching-error',
	[OfflineModeStatus.CACHING]: 'tz-cloud-caching-active',
	[OfflineModeStatus.CACHING_ERROR]: 'tz-cloud-caching-error',
	[OfflineModeStatus.ONLINE_READY]: 'tz-cloud-caching-done',
	[OfflineModeStatus.ONLINE_CACHE_MISSING]: 'tz-cloud-caching-error',
	[OfflineModeStatus.OFFLINE_READY]: 'tz-cloud-caching-done',
	[OfflineModeStatus.OFFLINE_ERROR]: 'tz-cloud-caching-error',
	[OfflineModeStatus.OFFLINE_CACHE_MISSING]: 'tz-cloud-caching-error'
};
