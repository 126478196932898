import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IEntityWorkAcceptancesData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityWorkAcceptancesData';
import {IConvertedWorkAcceptancesSpaceData} from '@tehzor/tools/interfaces/spaces/IConvertedWorkAcceptancesData';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';

export const convertWorkAcceptancesData = (
	workAcceptances: IEntityWorkAcceptancesData | undefined,
	workAcceptanceStatuses: INormalizedData<IWorkAcceptanceStatus> | undefined
): IConvertedWorkAcceptancesSpaceData[] => {
	if (!workAcceptances || !workAcceptanceStatuses) {
		return [];
	}

	let total = 0;
	const result: IConvertedWorkAcceptancesSpaceData[] = [];

	Object.keys(workAcceptances).forEach(statusId => {
		result.push({
			key: statusId,
			value: workAcceptances[statusId]?.count,
			percent: 0,
			color: workAcceptanceStatuses.byId[statusId]?.color || '#00000000',
			startPoint: 0
		});
		total += workAcceptances[statusId].count;
	});

	let offset = 0;
	for (const stat of result) {
		stat.percent = (stat.value / total) * 100;
		stat.startPoint = -offset;
		offset += stat.percent;
	}

	return result;
};
