import {memo, ReactNode} from "react";
import {ActionButtons, Dialog, InlineButton} from "@tehzor/ui-components";
import Button from "@tehzor/ui-components/src/components/buttons/Button";

interface INotificationMessageDialogProps {
	isOpen: boolean;
	rejectHandler: () => void;
	handleDeleteNotificationMessage: () => void;
	deleteIsDisable: boolean;
	handleSaveNotificationMessage: () => void
	children: ReactNode;
}
export const NotificationMessageDialog = memo (({
											  isOpen,
											  rejectHandler,
											  handleDeleteNotificationMessage,
											  deleteIsDisable,
											  handleSaveNotificationMessage,
											  children
										  }: INotificationMessageDialogProps) => (
	<Dialog
		title="Ведите текст оповещения при установке статуса"
		isOpen={isOpen}
		onRequestClose={rejectHandler}
		className={{
			root: "editable-space-statuses__status-fields__notification-message__dialog",
			body: "editable-space-statuses__status-fields__notification-message__dialog-body",
			title: "editable-space-statuses__status-fields__notification-message__dialog-title"
		}}
		footer={
			<div className="editable-space-statuses__status-fields__notification-message__dialog-footer">
				<InlineButton
					leftIcon={<i className="tz-delete"/>}
					label="Удалить оповещение"
					type="cancel"
					onClick={handleDeleteNotificationMessage}
					disabled={deleteIsDisable}
				/>
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleSaveNotificationMessage}
					/>
					<Button
						type="cancel"
						label="Отменить"
						onClick={rejectHandler}
					/>
				</ActionButtons>
			</div>

		}
	>
		{children}
	</Dialog>
))