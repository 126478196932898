import {memo, useMemo, useState} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useExtractStructureTypesAsArray} from '@src/core/hooks/queries/structureTypes/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';

interface IStructureTypesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const StructureTypesFilterMobile = memo((props: IStructureTypesFilterMobileProps) => {
	const {t} = useTranslation();
	const [search, setSearch] = useState('');
	const {data: structureTypes} = useExtractStructureTypesAsArray();
	const translatedStructureTypes = useTranslatedDictionaryArray(
		dictionaryKeys.structureTypes,
		structureTypes
	);

	const filteredData = useMemo(
		() => translatedStructureTypes && flatFilter(translatedStructureTypes, 'name', search),
		[translatedStructureTypes, search]
	);

	return filteredData ? (
		<BaseListFilterMobile
			{...props}
			options={filteredData}
			label={t('entitiesFilters.statusesFilter.structureTypes.label')}
			filterName="types"
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
		/>
	) : null;
});
