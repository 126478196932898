import * as React from 'react';
import classNames from 'classnames';

interface ITableRowWrapProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
}

const TableRowWrap = ({className, style, children, ...restProps}: ITableRowWrapProps) => (
	<div
		className={classNames(
			'table2__row-wrap',
			className
		)}
		style={style}
		{...restProps}
	>
		{children}
	</div>
);

export default TableRowWrap;