import {memo} from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {MobileInfo} from '@src/pages/SpacePage/components/info/Info.mobile';
import {AdditionalInfo} from '@src/pages/SpacePage/components/info/AdditionalInfo';
import {MetersBlock} from './MetersBlock';

interface IMobileInfoBlockProps {
	space: ISpace;
	objectId: string;
	hasUnfixedProblems: boolean;
	hasUnacceptedCheckItem: boolean;
}

export const MobileInfoBlock = memo((props: IMobileInfoBlockProps) => {
	const {objectId, space, hasUnfixedProblems, hasUnacceptedCheckItem} = props;
	const showAdditionalInfo =
		space.areaBTI ||
		space.floorBTI ||
		space.numberBTI ||
		space.contractForm ||
		space.markupForRegistration;
	return (
		<>
			<MobileInfo
				objectId={objectId}
				space={space}
				hasUnfixedProblems={hasUnfixedProblems}
				hasUnacceptedCheckItem={hasUnacceptedCheckItem}
			/>
			<MetersBlock
				objectId={objectId}
				spaceId={space.id}
			/>

			{showAdditionalInfo && <AdditionalInfo space={space} />}
		</>
	);
});
