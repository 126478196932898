import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IGetChecksDocumentsStatsQueryKeyList, checksDocumentsStatsQueryKeys} from '../keys';
import {requestChecksDocumentsStats} from '@src/api/backend/checksDocumentsStats';

export const useChecksDocumentsStatsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(checksDocumentsStatsQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetChecksDocumentsStatsQueryKeyList>) => {
			const [, , checkIds] = queryKey;
			return requestChecksDocumentsStats(checkIds);
		},
		meta: {
			error: 'при загрузке статистики по документам y проверок'
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
