import {wsConnector} from '../wsConnector';
import {ISavingProblemComment} from '@src/interfaces/saving/ISavingProblemComment';
import {IAddCommentResponse} from '../comment';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

/**
 * Добавляет ответ на нарушение
 *
 * @param objectId id объекта
 * @param commentTypeId тип комментрия
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const makeReplyAddRequest = (
	objectId: string,
	commentTypeId: ICommentTypeId,
	links: IComment['links'] | undefined,
	fields: ISavingProblemComment
) =>
	wsConnector.sendAuthorizedRequest<IAddCommentResponse>('addReply', {
		objectId,
		commentTypeId,
		links,
		...fields
	});
