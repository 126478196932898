import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import {
	convertToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import EditableWarrantyClaim from '../EditableWarrantyClaim';
import {
	useEditableWarrantyClaimState,
	convertToSave,
	hasAttachmentsError,
	isEdited,
	errorsFns
} from '@src/core/hooks/states/useEditableWarrantyClaimState';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {useIsFetching} from '@tanstack/react-query';

/**
 * Логика редактирования и сохранения передачи собственнику (нового или существующего)
 */
export const useEditableWarrantyClaim = (
	spaceId: string | undefined,
	claim: IWarrantyClaim | undefined,
	saving: boolean
): [
	React.ReactNode,
	(useLocalFiles?: boolean) => Promise<ISavingWarrantyClaim | undefined>,
	() => void,
	boolean
] => {
	// TODO Определение типа настроек
	// const fieldsSettings = useFieldsSettings(objectId, 'buildingProblem');
	const fieldsSettings = {};

	const [editingState, editingDispatch] = useEditableWarrantyClaimState(claim);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, claim) || isFilesExist(uploadingFilesState.value));
	}, [editingState, uploadingFilesState.value, claim]);

	const getSavingData = useCallback(
		async (useLocalFiles?: boolean) => {
			const files = await waitUploading();
			// Проверка наличия ошибок в состояниях
			if (
				hasErrors(editingState, errorsFns, fieldsSettings) ||
				(hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
			) {
				editingDispatch({type: 'update-errors'});
				uploadingFilesDispatch({type: 'update-error'});
				return undefined;
			}
			// Проверка, были ли отредактированы поля
			if (
				!isEdited(editingState, claim) &&
				(!isFilesExist(files) || someFilesHaveError(files))
			) {
				return undefined;
			}
			const savingData = convertToSave(editingState, claim, true);
			savingData.newAttachments = useLocalFiles
				? convertToLocalSave(files)
				: convertFilesToSave(files);
			return savingData;
		},
		[editingState, claim, fieldsSettings]
	);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: claim
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [claim]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [claim]);

	// TO-DO: Избавиться от этого безобразия, после переработки форм
	const isAllLoading = !!useIsFetching({});

	const fields = (
		<EditableWarrantyClaim
			spaceId={spaceId}
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving || isAllLoading}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
