import {useCheckPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import DesktopCheckExportBtn from './CheckExport.desktop';
import EntityAdding from './EntityAdding';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {useCheckProblemsArray} from '@src/core/hooks/queries/check/problems/hooks';

interface IDesktopMainActionsProps {
	objectId: string;
	check: ICheck;
}

const DesktopMainActions = ({objectId, check}: IDesktopMainActionsProps) => {
	const {data: problems} = useCheckProblemsArray(objectId, check.id);
	const permissions = useCheckPermissions(objectId, check, problems);

	return (
		<>
			{permissions.canExport && (
				<DesktopCheckExportBtn
					objectId={objectId}
					checkId={check.id}
				/>
			)}
			<EntityAdding
				objectId={objectId}
				check={check}
			/>
		</>
	);
};

export default DesktopMainActions;
