import {useContext, useLayoutEffect} from 'react';
import {Location} from 'history';
import {ScrollRestorationCtx} from '../ScrollRestoration';

export const createScrollRestorationHook = (useLocation: () => Location) => (mountPosition?: number) => {
	const location = useLocation();
	const actions = useContext(ScrollRestorationCtx);

	useLayoutEffect(() => {
		actions.scrollTo(mountPosition);

		return () => {
			if (location.key) {
				actions.addPosition(location.key);
			}
		};
	}, []);

	return actions;
};
