import {useCallback, useState} from 'react';
import * as React from 'react';
import EditableSpaceOwnerDialog from '../EditableSpaceOwnerDialog';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export function useEditableSpaceOwnerDialog(
	spaceId: string,
	setActiveOwner:(owner:ISpaceOwner | undefined)=> void,
	activeOwner: ISpaceOwner | undefined,
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
		 setActiveOwner(undefined);
	}, [setActiveOwner]);

	const dialog = (
		<EditableSpaceOwnerDialog
			spaceId={spaceId}
			owner={activeOwner}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
}
