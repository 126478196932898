import {useMemo} from 'react';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {useTasksByLinks} from '@src/core/hooks/queries/tasks/hooks';

/**
 * Вычисляет количество задач по каждому статусу для чек-листа или категории
 *
 * @param listId id чек-листа
 * @param itemId id категории
 * @param spaceId id помещения
 * @param workAcceptanceId id приёмки работ
 */

interface IUseTasksCountByStatusProps {
	listId?: string;
	itemId?: string;
	spaceId?: string;
	workAcceptanceId?: string;
}
export function useTasksCountByStatus({
	listId,
	itemId,
	spaceId,
	workAcceptanceId
}: IUseTasksCountByStatusProps): Array<{status: TaskStatusId; count: number}> {
	const {data: tasks} = useTasksByLinks(
		{
			checkListIds: listId ? [listId] : undefined,
			checkItemIds: itemId ? [itemId] : undefined
		},
		{
			spaceId,
			workAcceptanceId
		}
	);

	return useMemo(() => {
		if (!tasks) return [];
		const countByStatus = tasks.reduce<Record<string, number>>((prev, task) => {
			if (!prev[task.status]) {
				prev[task.status] = 0;
			}
			prev[task.status] += 1;
			return prev;
		}, {});

		return Object.values(TaskStatusId).map(status => ({
			status,
			count: countByStatus[status] ?? 0
		}));
	}, [tasks]);
}
