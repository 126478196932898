import {useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '../../useAppDispatch';
import {useEditSpaceIndicatorsMutation} from '@src/api/cache/spaces/mutations';
import {IEditSpaceIndicatorsParams} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {IGetSpaceResponse} from '@src/api/backend/space';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import IError from '@tehzor/tools/interfaces/IError';
import {editSpaceIndicatorsActions} from '@src/store/modules/entities/space/actions';
import {addWarningToast} from '@src/utils/toasts';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useSpaceIndicatorsAsMap} from '../../queries/spaceIndicatorsSets/hooks';

export interface IEditSpaceIndicatorsJournalData {
	objectName: string;
	locationName?: string;
	newIndicators: string[];
}

export const useEditSpaceIndicators = (objectId: string) => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const mutation = useEditSpaceIndicatorsMutation();
	const {data: object} = useObject(objectId);
	const {data: objectIndicators} = useSpaceIndicatorsAsMap(object);

	return (params: Omit<IEditSpaceIndicatorsParams, 'key'>) => {
		const {objectId, pageObjectId, spaceId, indicators} = params;

		const spaceDetailQueryKey = [...spacesQueryKeys.detail(spaceId), objectId];
		const key = Date.now().toString(10);

		const space = queryClient.getQueryData<IGetSpaceResponse>(spaceDetailQueryKey);
		const mutationCache = queryClient.getMutationCache();

		const journalData = {
			objectName: object ? object.name : '',
			locationName: space?.name,
			newIndicators:
				indicators.length && objectIndicators
					? indicators.map(
							indicator =>
								objectIndicators.byId[indicator]?.name || 'Неизвестный индикатор'
					  )
					: ['Все индикаторы удалены']
		};

		const currentMutation = mutationCache.find<
			ISpace,
			IError,
			Partial<IEditSpaceIndicatorsParams>,
			unknown
		>({
			predicate: mut => {
				const mutStatus = mut.state.status;
				const mutSpaceId = (mut.state.variables as IEditSpaceIndicatorsParams).spaceId;
				const newIndicators = (mut.state.variables as IEditSpaceIndicatorsParams)
					?.indicators;

				return (
					!!newIndicators &&
					mutSpaceId === spaceId &&
					mutStatus !== ('success' || 'error')
				);
			}
		});

		// Если нашли существующую мутацию (запрос), обновляем ее данные
		if (currentMutation) {
			currentMutation.state.isPaused = true;
			currentMutation.state.variables = {
				...currentMutation.state.variables,
				...params
			};
		}

		// Если не нашли делаем запрос
		if (!currentMutation) {
			mutation.mutate({...params, key, journalData});
		}

		if (!params.online) {
			// Обновляем помещение в кеше, что бы отправить его в редакс
			const cashedSpace = queryClient.setQueryData(spaceDetailQueryKey, () => ({
				...space,
				indicators
			}));
			dispatch(editSpaceIndicatorsActions.success(cashedSpace as ISpace, pageObjectId));
			addWarningToast('Успех', 'индикаторы помещения сохранены локально');
		}
	};
};
