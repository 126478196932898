export enum TaskTypeId {
	AUDIT = 'audit',
	CHECK = 'check',
	ACCEPTANCE_CONTROL = 'acceptance-control',
	ACCEPTANCE_CALL = 'acceptance-call',
	TASK = 'task'
}

export interface ITaskType {
	id: TaskTypeId;
	name: string;
	color: string;
}
