import {ICheckListHistoryLinks} from "@src/components/CheckListHistoryDialog/hooks/useEnrichedHistories";

export type IGetCheckListStoriesQueryKeyList = [] & {
	0: string; // check-list-stories
	1: string; // list
	2: string; // objectId
	3: string; // stage
	4: ICheckListHistoryLinks;
};

export const checkListStoriesQueryKeys = {
	all: () => ['check-list-stories'],
	lists: () => [...checkListStoriesQueryKeys.all(), 'list'],
	list: (
		objectId: string,
		stage: string,
		links: ICheckListHistoryLinks
	) => [
		...checkListStoriesQueryKeys.lists(),
		objectId,
		stage,
		links
	],
};
