import StatusesFilterPage from '../StatusesFilterPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractCheckRecordStatusesByEntityTypeAsArray} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';

export const CheckListStatusesFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();
	const {data: checkListsRecordStatuses} =
		useExtractCheckRecordStatusesByEntityTypeAsArray('check-list');

	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.checkListStatuses,
		checkListsRecordStatuses
	);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={translatedOptions}
			label={t('spacesFiltersPage.checkListStatusesFilterMobile.label')}
			filterName="checkListStatuses"
		/>
	);
};
