import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {useOwnerAcceptanceStatusesAsArray} from '@src/core/hooks/queries/ownerAcceptanceStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface IOwnerAcceptancesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const OwnerAcceptancesStatusesFilterMobile = memo(
	(props: IOwnerAcceptancesFilterMobileProps) => {
		const {t} = useTranslation();
		const {data: statuses} = useOwnerAcceptanceStatusesAsArray();

		const translatedOptions = useTranslatedDictionaryArray(
			dictionaryKeys.ownerAcceptanceStatuses,
			statuses
		);

		if (!translatedOptions) return null;

		return (
			<BaseListFilterMobile
				{...props}
				options={translatedOptions}
				label={t('entitiesFilters.statusesFilter.ownerAcceptanceStatuses.label')}
				filterName="statuses"
			/>
		);
	}
);
