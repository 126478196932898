import {requestInternalAcceptances} from '@src/api/backend/internalAcceptances';
import {Query, QueryFunctionContext, QueryKey, useQueryClient} from '@tanstack/react-query';
import {endOfDay} from 'date-fns';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {requestInternalAcceptance} from '@src/api/backend/internalAcceptance';
import {
	IInternalAcceptanceDetailQueryKey,
	IInternalAcceptanceInspectionsQueryKey,
	IInternalAcceptanceProblemsQueryKey,
	IInternalAcceptancesListQueryKey,
	internalAcceptancesQueryKeys
} from '../keys';
import {internalAcceptancesActions} from '@src/store/modules/settings/pages/internalAcceptances/slice';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {getDateDifference} from '@tehzor/tools/utils/dates/getDateDifference';
import {requestListInspections} from '@src/api/backend/inspections';
import {requestListProblems} from '@src/api/backend/problems';

export const useInternalAcceptancesQueryDefaults = () => {
	const queryClient = useQueryClient();
	const {changeInitialStateByObjectId} = internalAcceptancesActions;
	const dispatch = useAppDispatch();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	queryClient.setQueryDefaults(internalAcceptancesQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IInternalAcceptancesListQueryKey>) => {
			const [, , filters, sort, offset, pageSize, objectId] = queryKey;
			dispatch(changeInitialStateByObjectId({objectId}));
			return requestInternalAcceptances(filters, sort, offset, pageSize);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(internalAcceptancesQueryKeys.latest(), {
		queryFn: () => {
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			return requestInternalAcceptances({createdAtFrom: date}, {createdAt: false}, 0, 100);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: getDateDifference(new Date(), endOfDay(new Date())) // до конца дня
	});
	queryClient.setQueryDefaults(internalAcceptancesQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IInternalAcceptanceDetailQueryKey>) => {
			const [, , internalAcceptanceId, objectId] = queryKey;
			return requestInternalAcceptance(objectId, internalAcceptanceId);
		},
		meta: {
			persist: isOfflineModeAvailable
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(internalAcceptancesQueryKeys.localList(), {
		queryFn: async ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localAcpts = await new Promise<
				Array<[QueryKey, IListInternalAcceptance | undefined]>
			>(resolve => {
				const local = queryClient.getQueriesData<IListInternalAcceptance>({
					queryKey: internalAcceptancesQueryKeys.details(),
					predicate: (query: Query<IListInternalAcceptance>) => {
						const {data} = query.state;
						if (data?.transferStatus) {
							if (!objectId || objectId === 'all') {
								return true;
							}
							if (objectId === data.objectId) {
								return true;
							}
						}
						return false;
					}
				});
				resolve(local);
			});
			return localAcpts.map(([, acpt]) => acpt) as IListInternalAcceptance[];
		},
		staleTime: 0,
		gcTime: 0,
		networkMode: 'offlineFirst'
	});

	queryClient.setQueryDefaults(internalAcceptancesQueryKeys.inspections(), {
		queryFn: async ({
			queryKey
		}: QueryFunctionContext<IInternalAcceptanceInspectionsQueryKey>) => {
			const [, , objectId, internalAcceptanceId] = queryKey;
			return requestListInspections(
				{
					objects: [objectId],
					internalAcceptances: [internalAcceptanceId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});

	queryClient.setQueryDefaults(internalAcceptancesQueryKeys.problems(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IInternalAcceptanceProblemsQueryKey>) => {
			const [, , objectId, internalAcceptanceId] = queryKey;
			return requestListProblems(
				{
					objects: [objectId],
					internalAcceptances: [internalAcceptanceId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});
};
