import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';
import {IUserRole, UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {checkRestrictions} from '@tehzor/tools/utils/permissions/restrictions/checkRestrictions';
import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';

export interface IProblemResponsiblePermissions {
	canEditPerformers?: boolean;
	onlyPerformersActiveGroup?: boolean;
	canEditInspectors?: boolean;
	onlyInspectorsActiveGroup?: boolean;
}

const checkResponsiblePermissions = (
	objectId: string,
	userId: string,
	roles: IUserRole[],
	problem: IPreparedProblem,
	permissionId: string
): {onlyActiveGroup: boolean; canEditResponsibles: boolean} => {
	let onlyActiveGroup = false;

	const permission = findPermission(roles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!permission) {
		return {canEditResponsibles: false, onlyActiveGroup};
	}

	const restrictions = permission.restrictions;
	if (!restrictions) {
		return {canEditResponsibles: true, onlyActiveGroup};
	}

	if (restrictions?.onlyActiveGroup !== undefined) {
		onlyActiveGroup = typeof restrictions.onlyActiveGroup === 'boolean';
	}

	const canEditResponsibles = checkRestrictions(restrictions, {userId, problem});
	return {canEditResponsibles, onlyActiveGroup};
};

export const useProblemResponsiblePermissions = (
	objectId: string,
	problem: IPreparedProblem
): IProblemResponsiblePermissions => {
	const user = useAppSelector(extractUserProfile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		const userId = user.id;
		const roles = user.roles;

		const {canEditResponsibles: canEditPerformers, onlyActiveGroup: onlyPerformersActiveGroup} =
			checkResponsiblePermissions(objectId, userId, roles, problem, 'problems-performers-edit');
		const {canEditResponsibles: canEditInspectors, onlyActiveGroup: onlyInspectorsActiveGroup} =
			checkResponsiblePermissions(objectId, userId, roles, problem, 'problems-inspectors-edit');

		return {
			canEditPerformers,
			onlyPerformersActiveGroup,
			canEditInspectors,
			onlyInspectorsActiveGroup
		};
	}, [objectId, problem, user]);
};
