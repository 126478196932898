export const flatFilter = <T extends {id: string}>(data: T[], field: string, search: string): T[] =>
	data.filter(item => {
		if (!item[field]) {
			return false;
		}

		const content = item[field] as string;

		const upperContent = content.toUpperCase();
		const upperSearch = search.toUpperCase();

		if (search === '' || upperContent.indexOf(upperSearch) > -1) {
			return true;
		}
		return false;
	});

/**
 * Улучшенная версия метода flatFilter, позволяет искать сразу по нескольким полям
 *
 * @param data массив обьектов, по которым будет вестись поиск
 * @param field поля, по которым будет вестись поиск
 * @param search поисковой запрос
 */
export const flatFilterMultiple = <T extends {id: string}>(
	data: T[],
	field: string | string[],
	search: string
): T[] => {
	const upperSearch = search.toUpperCase();

	return data.filter(item => {
		const fieldList = typeof field === 'string' ? [field] : field;

		if (search === '') {
			return true;
		}

		const filteredFieldList = fieldList.map(fieldItem => {
			if (!item[fieldItem] || typeof item[fieldItem] !== 'string') {
				return false;
			}

			const content = item[fieldItem] as string;
			const upperContent = content.toUpperCase();

			return upperContent.includes(upperSearch);
		});
		return filteredFieldList.includes(true);
	});
};
