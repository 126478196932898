import _ from 'lodash';

export const handleSort = <I extends Record<string, unknown>, S extends Record<string, boolean>>(
	array: I[],
	sort?: S
): I[] => {
	if (!sort) {
		return array;
	}

	const sortKeys = Object.keys(sort || {});
	const sortItem = sort[sortKeys[0]];

	const order: Array<'asc' | 'desc'> = sortItem ? ['asc'] : ['desc'];

	if (sortKeys[0] === 'companies') {
		sortKeys.push('objects', 'shared');
		order.push(...order, ...order);
	}

	return _.orderBy(array, sortKeys, order);
};
