import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteMeterResponse {
	id: string;
	spaceId: string;
}

/**
 * Удаляет прибор учёта
 *
 * @param id  идентификатор прибора учёта
 */
export const makeMeterDeleteRequest = async (id: string) => {
	const response = await httpRequests.withToken.delete<IDeleteMeterResponse>(
		`/meters/delete/${id}`
	);
	return response.data;
};
