import {requestOwnerAcceptance} from '@src/api/backend/ownerAcceptance';
import {requestOwnerAcceptances} from '@src/api/backend/ownerAcceptances';
import {requestListProblems} from '@src/api/backend/problems';
import {IListOwnerAcceptanceSavingData} from '@src/core/hooks/mutations/ownerAcceptances/utils/convertToLocalSave';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {ownerAcceptancesPageSettingsActions} from '@src/store/modules/settings/pages/ownerAcceptances/slice';
import {Query, QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {getDateDifference} from '@tehzor/tools/utils/dates/getDateDifference';
import {endOfDay} from 'date-fns';
import {
	IOwnerAcceptanceProblemsQueryKey,
	IOwnerAcceptancesDetailsQueryKey,
	IOwnerAcceptancesListQueryKey,
	ownerAcceptancesQueryKeys
} from '../keys';

export const useOwnerAcceptancesQueryDefaults = () => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	const {initializeByObjectId} = ownerAcceptancesPageSettingsActions;

	queryClient.setQueryDefaults(ownerAcceptancesQueryKeys.list(), {
		queryFn: ({queryKey}: QueryFunctionContext<IOwnerAcceptancesListQueryKey>) => {
			const [, , objectId, filters, sort, offset, limit] = queryKey;
			dispatch(initializeByObjectId({objectId}));
			return requestOwnerAcceptances(filters, sort, offset, limit);
		}
	});

	queryClient.setQueryDefaults(ownerAcceptancesQueryKeys.details(), {
		queryFn: ({queryKey}: QueryFunctionContext<IOwnerAcceptancesDetailsQueryKey>) => {
			const [, , acceptanceId, objectId] = queryKey;
			if (!acceptanceId || !objectId || objectId === 'all') return undefined;
			return requestOwnerAcceptance(objectId, acceptanceId);
		},
		meta: {
			persist: isOfflineModeAvailable
		}
	});

	queryClient.setQueryDefaults(ownerAcceptancesQueryKeys.latest(), {
		queryFn: () => {
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			return requestOwnerAcceptances({createdAtFrom: date}, {createdAt: false}, 0, 100);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: getDateDifference(new Date(), endOfDay(new Date())) // до конца дня
	});

	queryClient.setQueryDefaults(ownerAcceptancesQueryKeys.localList(), {
		queryFn: ({queryKey}) => {
			const [, , objectId] = queryKey;
			const queryMatch = queryClient.getQueriesData<IListOwnerAcceptanceSavingData>({
				queryKey: ownerAcceptancesQueryKeys.savingDatas(),
				predicate: (query: Query<IListOwnerAcceptanceSavingData>) => {
					const {data} = query.state;
					if (objectId === data?.object?.id || !objectId || objectId === 'all') {
						return true;
					}
					return false;
				}
			});
			return queryMatch.map(([, acp]) => acp) as IListOwnerAcceptance[];
		},
		staleTime: 0,
		gcTime: 0,
		networkMode: 'offlineFirst'
	});

	queryClient.setQueryDefaults(ownerAcceptancesQueryKeys.problems(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IOwnerAcceptanceProblemsQueryKey>) => {
			const [, , objectId, acceptanceId] = queryKey;
			return requestListProblems(
				{
					objects: [objectId],
					ownerAcceptances: [acceptanceId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});
};
