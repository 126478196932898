import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import {queryClient} from '@src/api/QueryClient';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {IWorkAcceptancesStatsDataRequest} from '@src/interfaces/IWorkAcceptancesStatsData';
import {workAcceptancesTasksDataQueryKeys} from '@src/api/cache/workAcceptancesTasksData/keys';

export const useAddWorkAcceptanceTaskDialog = (workAcceptance: IWorkAcceptance) => {
	const onSuccess = () => {
		const params: IWorkAcceptancesStatsDataRequest = {
			workAcceptances: [
				{
					objectId: workAcceptance.objectId,
					workAcceptanceId: workAcceptance.id
				}
			]
		};
		void queryClient.refetchQueries({
			queryKey: workAcceptancesTasksDataQueryKeys.data(params)
		});
		void queryClient.refetchQueries({queryKey: tasksQueryKeys.lists()});
	};

	const taskDialogArgs = {
		objectId: workAcceptance.objectId,
		taskType: TaskTypeId.ACCEPTANCE_CALL,
		links: {workAcceptanceId: workAcceptance.id},
		onSuccess
	};

	return useAddingTaskDialog(taskDialogArgs);
};
