import {PersistConfig} from 'redux-persist/es/types';
import {createStorage} from './storage';

// Преобразование версии из строки в число, где вместо точек - 0
// eslint-disable-next-line no-undef
const versionString = APP_VERSION || '1.1.1';
export const appVersion = Number(versionString.replace(/\./g, '0'));

export const formPersistConfig = <S = unknown>(
	key: string,
	extra?: Omit<Partial<PersistConfig<S>>, 'key'>
): PersistConfig<S> => ({
	key,
	keyPrefix: '',
	storage: createStorage(),
	version: appVersion,
	serialize: false,
	// Без указания данного недокументированного свойства возникает ошибка при парсинге объекта
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	deserialize: false,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	timeout: null,
	debug: process.env.NODE_ENV === 'development',
	...extra
});
