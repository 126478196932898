import classNames from 'classnames';
import './DocumentsCount.less';

interface IDocumentsCountProps {
	count: number;
	className?: string;
}

const documentIcon = <i className="tz-document-16" />;

export const DocumentsCount = ({count, className}: IDocumentsCountProps) => (
		<div className={classNames('documents-count', className)}>
			<div className="documents-count__icon">{documentIcon}</div>
			<div className="documents-count__number">{count}</div>
		</div>
	);
