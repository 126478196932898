import {RefObject, useCallback, useEffect, useRef} from 'react';
import {IDragPositionEvent} from '../../interfaces';
import {IAddHandler} from '../interfaces/IDndCtx';
import {IInitPositionRef} from '../interfaces/IInitPositionRef';

export const useDndPreview = (
	addHandler: IAddHandler,
	initPositionRef: IInitPositionRef,
	isDragging?: boolean,
	offset: number | undefined = 10
): RefObject<HTMLDivElement> => {
	const ref = useRef<HTMLDivElement>(null);

	const handleMove = useCallback(({x, y}: IDragPositionEvent) => {
		if (!ref.current || !x || !y) { return; }

		const element = ref.current;
		element.style.top = `${y + offset}px`;
		element.style.left = `${x + offset}px`;
	}, [offset]);

	// Добавляет обработчик событий на перемещение preview
	useEffect(() => {
		addHandler(handleMove, 'dragPreviewMoveHandlers', 'preview');
	}, [handleMove, addHandler]);

	// Запускает функцию перемещения preview когда происходит dragStart, но еще не происходит dragMove
	useEffect(() => {
		if (!isDragging || !initPositionRef?.current) { return; }

		const positions = initPositionRef.current;
		if (positions.x && positions.y) {
			handleMove({x: positions.x, y: positions.y});
		}
	}, [isDragging, offset]);

	return ref;
};