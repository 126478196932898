import {RefObject, useEffect, useRef} from 'react';
import {getScrollContainer} from '@tehzor/tools/utils/getScrollContainer';
import useRafState from 'react-use/lib/useRafState';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';
import ResizeObserver from 'resize-observer-polyfill';

interface Options {
	scrollContainerId?: string;
	threshold?: number;
}

export function useScrollingReveal<T extends HTMLElement>({scrollContainerId, threshold = 50}: Options = {}): [
	{offset: number, hidden: boolean},
	RefObject<T>
] {
	const ref = useRef<T>(null);
	const scrollContainer = useRef<Window | HTMLElement>();
	const height = useRef<number>(0);
	const prevPos = useRef<number>(0);
	const prevOffset = useRef<number>(0);
	const prevHidden = useRef<boolean>(false);

	const [state, setState] = useRafState({
		offset: 0,
		hidden: false
	});

	useEffect(() => {
		const observer = new ResizeObserver(() => {
			height.current = ref.current?.offsetHeight ?? 0;
		});

		const handleScroll = rafThrottle(() => {
			const cont = scrollContainer.current;

			if (cont) {
				const pos = cont === window ? cont.pageYOffset : (cont as HTMLElement).scrollTop;
				const offset = Math.max(Math.min(pos - prevPos.current + prevOffset.current, height.current), 0);
				let hidden;
				if (pos > prevPos.current) {
					hidden = offset >= threshold;
				} else {
					hidden = height.current - offset <= threshold;
				}
				if (offset !== prevOffset.current || hidden !== prevHidden.current) {
					setState({offset, hidden});
				}
				prevPos.current = pos;
				prevOffset.current = offset;
				prevHidden.current = hidden;
			}
		});

		if (ref.current) {
			observer.observe(ref.current);
			scrollContainer.current = getScrollContainer(scrollContainerId ?? ref.current);
			scrollContainer.current.addEventListener('scroll', handleScroll);
		}

		return () => {
			observer.disconnect();
			if (scrollContainer.current) {
				scrollContainer.current.removeEventListener('scroll', handleScroll);
				scrollContainer.current = undefined;
			}
		};
	}, []);

	return [state, ref];
}
