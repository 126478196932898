import {useCategoriesSetPermissions} from '@src/core/hooks/permissions/useCategoriesSetPermissions';
import {
	IEditableCategoriesSetState,
	makeDefaultData,
	useEditableCategoriesSetState
} from '@src/core/hooks/states/useCategoriesSetState';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import generateKey from '@tehzor/tools/utils/generateKey';
import {Button} from '@tehzor/ui-components';
import {useCallback, useState} from 'react';
import * as React from 'react';
import {useUpdateEffect} from 'react-use';
import {EditableCategories} from '../components/EditableCategories/EditableCategories';
import EditableNameField from '../components/EditableNameField/EditableNameField';
import EditableRelations from '../components/EditableRelations/EditableRelations';
import EditableStages from '../components/EditableStages/EditableStages';
import useUpdateContent from './useUpdateContent';
import {getNewItemOrder} from '@tehzor/tools/utils/getNewItemOrder';
import EditableExternalId from '../components/EditableExternalId/EditableExternalId';
import {useEditableCategoriesDialog} from './useEditableCategoriesDialog';

const addIcon = <i className="tz-plus-20" />;

export interface IEditableCategoriesSet {
	nameEl: React.ReactNode;
	relationsEl: React.ReactNode;
	categoriesEl: React.ReactNode;
	stagesEl: React.ReactNode;
	externalIdEl: React.ReactNode;
	addButtonEl: React.ReactNode | null;
	saveButtonsEl: React.ReactNode | null;

	state: IEditableCategoriesSetState;
}

const useEditableCategoriesSet = (
	categoriesSet?: ICategoriesSet,
	categories?: ICategory[],
	inDialog?: boolean
): IEditableCategoriesSet => {
	const defaultData = makeDefaultData(categoriesSet, categories);
	const [editingState, editingDispatch] = useEditableCategoriesSetState(defaultData);
	const [categoryDialog, openCategoryDialog] = useEditableCategoriesDialog(
		editingState,
		editingDispatch
	);
	const {categoriesSetId} = useStrictParams<{categoriesSetId: string}>();
	const {updateCategoriesSet, removeCategory, updateCategory, updateCategoriesOrder} =
		useUpdateContent(editingState, editingDispatch, categoriesSet, categories);
	const {canEdit, canShare} = useCategoriesSetPermissions();
	const [editedCategories, setEditedCategories] = useState<string[]>([]);
	const [isEdited, setEdited] = useState(false);

	useUpdateEffect(() => {
		editingDispatch({type: 'update', field: 'id', value: categoriesSetId});
	}, [categoriesSetId]);

	useUpdateEffect(() => {
		const {categories: editingCategories} = editingState;

		editingCategories.forEach(item => {
			if (item && !item?.name) {
				setEditedCategories(s => [...s, item.id]);
			}
		});
	}, [editingState.categories]);

	const handleCreateCategory = useCallback(
		(parent?: ISavingCategory) => {
			if (!categoriesSet?.id || (parent && parent.isLocal)) {
				return;
			}

			const id = generateKey();
			const order = getNewItemOrder(editingState.categories, parent?.id);
			const savingCategory: ISavingCategory = {
				id,
				externalId: '',
				name: '',
				parentId: parent?.id,
				categoriesSetId: categoriesSet.id,
				order,
				isLocal: true
			};

			editingDispatch({
				type: 'add-in-array',
				field: 'categories',
				value: [savingCategory]
			});
		},
		[editingState.categories, categoriesSet]
	);

	const handleConfirm = useCallback(async () => {
		await updateCategoriesSet(true);
		setEdited(false);
	}, [editingState]);

	const handleCancel = useCallback(() => {
		editingDispatch({field: 'name', type: 'update', value: categoriesSet?.name || ''});
		editingDispatch({
			field: 'companies',
			type: 'update',
			value: categoriesSet?.companies || []
		});
		editingDispatch({field: 'objects', type: 'update', value: categoriesSet?.objects || []});
		editingDispatch({field: 'shared', type: 'update', value: categoriesSet?.shared || false});
		editingDispatch({field: 'stages', type: 'update', value: categoriesSet?.stages || []});
		editingDispatch({
			field: 'externalId',
			type: 'update',
			value: categoriesSet?.externalId || ''
		});
	}, []);

	const nameEl = (
		<EditableNameField
			setEdited={setEdited}
			editingDispatch={editingDispatch}
			editingState={editingState}
			disabled={!canEdit}
		/>
	);

	const relationsEl = (
		<EditableRelations
			disabled={!canEdit}
			required
			isSharable={canShare}
			setEdited={setEdited}
			editingDispatch={editingDispatch}
			editingState={editingState}
		/>
	);

	const stagesEl = (
		<EditableStages
			setEdited={setEdited}
			editingDispatch={editingDispatch}
			editingState={editingState}
			disabled={!canEdit}
		/>
	);

	const externalIdEl = (
		<EditableExternalId
			setEdited={setEdited}
			editingDispatch={editingDispatch}
			editingState={editingState}
			disabled={!canEdit}
		/>
	);

	const saveButtonsEl = canEdit && isEdited && (
		<>
			<Button
				label="Сохранить"
				type="accent-blue"
				onClick={handleConfirm}
			/>
			<Button
				label="Отменить"
				type="cancel"
				onClick={handleCancel}
			/>
		</>
	);

	const categoriesEl = (
		<EditableCategories
			allCategoriesBySet={categories}
			edited={editedCategories}
			setEdited={setEditedCategories}
			editingState={editingState}
			editingDispatch={editingDispatch}
			isEditable={canEdit}
			handleCreate={handleCreateCategory}
			updateOrder={updateCategoriesOrder}
			remove={removeCategory}
			update={updateCategory}
			inDialog={inDialog}
			categoryDialog={categoryDialog}
			openCategoryDialog={openCategoryDialog}
		/>
	);

	const addButtonEl =
		canEdit && categoriesSetId ? (
			<Button
				leftIcon={addIcon}
				type="accent-blue"
				label="Добавить вид"
				onClick={() => handleCreateCategory()}
			/>
		) : null;

	return {
		nameEl,
		relationsEl,
		categoriesEl,
		stagesEl,
		externalIdEl,
		addButtonEl,
		saveButtonsEl,
		state: editingState
	};
};

export default useEditableCategoriesSet;
