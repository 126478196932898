import { useCallback } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import {useMovingProblemsDialog} from '@src/components/MovingProblemsDialog/hooks/useMovingProblemsDialog';

interface IMoveSelectedProps {
	objectId: string;
	checkId: string;
	selectedEntities: ICheckEntity[];
	restrictions?: {[key: string]: boolean};
	clearSelection?: () => void;
}

const SelectedProblemsMove = ({
	objectId,
	checkId,
	selectedEntities,
	restrictions,
	clearSelection
}: IMoveSelectedProps) => {
	const [movingDialog, openMoveDialog] = useMovingProblemsDialog(
		objectId,
		{checkId},
		selectedEntities,
		restrictions,
		clearSelection,
	);

	const handleClick = useCallback(() => {
		openMoveDialog();
	}, [checkId, openMoveDialog]);

	return (
		<>
			<IconButton
				disabled={selectedEntities.some(i => i.type === 'inspection')}
				onClick={handleClick}
			>
				<i className="tz-long-arrow-24"/>
			</IconButton>

			{movingDialog}
		</>
	);
};

export default SelectedProblemsMove;
