import {CellProps} from 'react-table';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {StatusSelect} from '@tehzor/ui-components';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {dictionaryKeys} from "@src/constants/translations/dictionaryKeys";
import {useTranslatedDictionary} from "@src/core/hooks/translations/useTranslatedDictionary";

export const WorkAcceptanceStatusCell = ({row}: CellProps<IListWorkAcceptance>) => {
	const workAcceptance = row.original;
	const {data: statuses} = useExtractWorkAcceptanceStatuses();
	const translatedStatuses = useTranslatedDictionary(dictionaryKeys.workAcceptanceStatuses, statuses);

	return (
		<ClickPreventWrap>
			<StatusSelect
				className="work-acceptance-page__d-entities-status"
				statuses={translatedStatuses}
				disabled
				hideArrow
				value={workAcceptance?.status}
			/>
		</ClickPreventWrap>
	);
};
