import { useCallback, useRef } from 'react';
import * as React from 'react';
import './Overlay.less';
import {motion, Variants, Transition} from 'framer-motion';

import classNames from 'classnames';

interface IOverlayProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	variants?: Variants;
	transition?: Transition;

	onClick?(e?: React.SyntheticEvent): void;

	onAnimationStart?(): void;

	onAnimationComplete?(): void;
}

const Overlay = (props: IOverlayProps) => {
	const {
		className,
		style,
		children,
		variants,
		transition,
		onClick,
		onAnimationStart,
		onAnimationComplete
	} = props;
	const ref = useRef<HTMLDivElement | null>(null);
	const isPressedOnOverlay = useRef<boolean>();

	const handleMouseDown = useCallback((event: React.MouseEvent) => {
		event.stopPropagation();
		// Запоминаем, было ли нажатие на оверлее
		isPressedOnOverlay.current = event.target === ref.current;
	}, []);

	const handleMouseUp = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			// Клик считается только в случае если было нажатие и отжатие на оверлее
			if (onClick && isPressedOnOverlay.current && event.target === ref.current) {
				onClick(event);
			}
		},
		[onClick]
	);

	const handleOnClick = useCallback((event: React.MouseEvent) => {
		event.stopPropagation();
	}, []);

	return (
		<motion.div
			key="overlay"
			className={classNames('overlay', className)}
			style={style}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onClick={handleOnClick}
			initial="initial"
			animate="visible"
			exit="hidden"
			variants={variants}
			transition={transition}
			onAnimationStart={onAnimationStart}
			onAnimationComplete={onAnimationComplete}
			ref={ref}
		>
			{children}
		</motion.div>
	);
};

export default Overlay;
