import {useEditableObjectDialog} from '@src/components/EditableObjectDialog/hooks/useEditableObjectDialog';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IPermissions} from '@src/pages/ObjectsPage/utils/usePermissionsForObject';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAsyncFn} from 'react-use';
import {useGenerateSpacesDialog} from '../hooks/useGenerateSpacesDialog';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import { useDeleteObject } from '@src/core/hooks/mutations/object/useDeleteObject';

interface IMenuProps {
	object?: IObject;
	permissions: IPermissions;
}

const spaceIcon = <i className="tz-space-24" />;
const deleteIcon = <i className="tz-delete" />;
const editIcon = <i className="tz-edit" />;

export const Menu = ({object, permissions}: IMenuProps) => {
	const {t} = useTranslation();
	const {mutateAsync: deleteObject} = useDeleteObject();
	const {goBack} = useChangePath();
	const currentTreeObject = useCurrentTreeObject(object?.id || 'all');
	const hasNoChildObjects = !currentTreeObject?.children?.length;
	const canGenerateSpaces = permissions.canGenerate && hasNoChildObjects;

	const items = [];

	const isDesktop = useIsDesktop();

	const [objectEditDialog, openObjectEditDialog] = useEditableObjectDialog(object);

	const [generateSpacesDialog, openGenerateSpacesDialog] = useGenerateSpacesDialog(
		object?.id || 'all'
	);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.objects.deleteTitle'),
		message: ''
	});

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await deleteObject(object?.id || 'all');
			goBack();
		}
	}, [object?.id]);

	if (permissions.canEditObject && !isDesktop) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={openObjectEditDialog}
			>
				{t('objectPage.controls.editButton.label')}
			</MenuItem>
		);
	}

	if (canGenerateSpaces) {
		items.push(
			<MenuItem
				key="settings"
				icon={spaceIcon}
				onClick={openGenerateSpacesDialog}
			>
				{t('objectPage.controls.settingsButton.label')}
			</MenuItem>
		);
	}

	if (permissions.canDeleteObject) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				{t('objectPage.controls.deleteButton.label')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{permissions.canDeleteObject && deleteDialog}
			{permissions.canEditObject && objectEditDialog}
			{canGenerateSpaces && generateSpacesDialog}
		</>
	) : null;
};
