import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';

export type ISoftDeleteCheckItemResponse = INormalizedData<ICheckItem>;

/**
 * Удаляет (soft) категорию чеклиста вместе со всеми вложениями
 *
 * @param checkListId id чек-листа
 * @param id  идентификатор категориии которую удаляем
 */
export const makeCheckItemSoftDeleteRequest = async (checkListId: string, id: string) => {
	const response = await httpRequests.withToken.put<ISoftDeleteCheckItemResponse>(
		'/check-items/soft-delete',
		{checkListId, id}
	);
	return response.data;
};