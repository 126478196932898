import {formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';

export function getRouteForNotificationAction(action: string, fields: Record<string, string>): string|null {
	if (!fields?.objectId) {
		return null;
	}

	switch (action) {
		case 'openProblem': {
			if (fields.problemId) {
				return formProblemLink(fields.objectId, fields.problemId);
			}
			break;
		}
		case 'openInspection': {
			if (fields.inspectionId) {
				return formInspectionLink(fields.objectId, fields.inspectionId);
			}
			break;
		}
		case 'openReport': {
			if (fields.reportId) {
				return `/objects/${fields.objectId}/checks/${fields.reportId}`;
			}
			break;
		}
		case 'openSpace': {
			if (fields.spaceId) {
				return `/objects/${fields.objectId}/spaces/${fields.spaceId}`;
			}
			break;
		}
	}
	return null;
}