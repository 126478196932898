import {useMutation} from '@tanstack/react-query';
import {addExportToast} from '@src/components/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';
import {makeCheckListsExportRequest} from '@src/api/backend/checkLists';

interface ISelected {
	spaceId?: string;
	workAcceptanceId?: string;
	objectId: string;
	checkListsIds: string[];
}

interface IExportCheckParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	selected: ISelected[];
}

export const useExportCheckListsMutation = () =>
	useMutation({
		mutationFn: (params: IExportCheckParams) => {
			const {selected, templateId, email, createDocument} = params;
			return makeCheckListsExportRequest(templateId, createDocument, email, selected);
		},
		onSuccess: (data, params) => {
			if (data.link) {
				addExportToast(
					params.email ? 'Документ отправлен' : 'Экспорт документа завершен',
					data.link,
					data.file.name,
					'',
					data.document,
					undefined,
					undefined
				);
			}
			if (data.warnings?.itemsLimit !== undefined) {
				addWarningToast(
					'Внимание',
					`превышен лимит записей (${String(data.warnings.itemsLimit)})`
				);
			}
		},
		onError: (_, params) => {
			const errorMessage = params.email ? 'при отправке' : 'при экспорте';
			addErrorToast('Ошибка', errorMessage);
		}
	});
