/**
 * Конвертирует параметр sort для методов по запросу списков из формата веб-клиента в формат сервера
 *
 * @param sort объект сортировки
 */
export function convertSortParam(sort?: Record<string, boolean>): Record<string, number> | undefined {
	if (!sort) {
		return undefined;
	}
	const result = {};
	for (const key in sort) {
		if (sort.hasOwnProperty(key)) {
			result[key] = sort[key] ? 1 : -1;
		}
	}
	return result;
}
