import {CSSProperties, ReactNode} from 'react';
import MobileFilterButton from '../../buttons/MobileFilterButton';
import './MobileFilter.less';
import classNames from 'classnames';
import {LinkButton} from '../../buttons';
import {useSortedElements} from './utils/useSortedElements';
import {isActive} from './utils/isActive';
import {ErrorBoundary, ErrorMessage} from '../../containers';

export interface IMobileFilterItem {
	id: string;
	name: string;
}

export interface IMobileFilterProps {
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
	label?: ReactNode;
	elements?: IMobileFilterItem[];
	selected?: string[];
	fullSize?: boolean;
	search?: ReactNode;
	linkBtnLabel?: string;
	noOptions?: boolean;
	noOptionsOnChange?: () => void;
	noOptionsChecked?: boolean;
	noOptionsContent?: ReactNode;

	onChange?: (value: string[] | undefined) => void;
	onExpand?: () => void;
}

const MobileFilter = ({
	className,
	style,
	label,
	elements,
	selected = [],
	children,
	fullSize,
	search,
	linkBtnLabel = 'Все',
	noOptions,
	noOptionsOnChange,
	noOptionsChecked,
	noOptionsContent,
	onChange,
	onExpand
}: IMobileFilterProps) => {
	const sortedElements = useSortedElements(elements, selected);
	const handleClick = (item: IMobileFilterItem) => {
		if (onChange) {
			if (isActive(item, selected)) {
				onChange(selected.filter(id => id !== item.id));
			} else {
				onChange([...selected, item.id]);
			}
		}
	};

	const handleNoOptionClick = () => {
		if (!noOptionsOnChange) return;
		else {
			noOptionsOnChange();
		}
	};
	return (
		<ErrorBoundary
			label="MobileFilter"
			component={
				<div className="mobile-filter">
					<ErrorMessage />
				</div>
			}
		>
			<div className={classNames('mobile-filter', className)} style={style}>
				<div className="mobile-filter__info">
					<div className="mobile-filter__label">{label}</div>
					{onExpand ? <LinkButton label={linkBtnLabel} onClick={onExpand} /> : null}
				</div>
				{search && <div className="mobile-filter__search">{search}</div>}
				{children ?? (
					<div
						className={classNames('mobile-filter__tabs', {
							'mobile-filter__tabs_fullsize': fullSize
						})}
					>
						{noOptions ? (
							<MobileFilterButton
								className="mobile-filter__item"
								label={noOptionsContent}
								active={noOptionsChecked}
								onClick={handleNoOptionClick}
							/>
						) : null}
						{sortedElements.map(item => (
							<MobileFilterButton
								key={item.id}
								className="mobile-filter__item"
								label={item.name}
								active={isActive(item, selected)}
								onClick={() => handleClick(item)}
							/>
						))}
					</div>
				)}
			</div>
		</ErrorBoundary>
	);
};

MobileFilter.displayName = 'MobileFilter';

export default MobileFilter;
