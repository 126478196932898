import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IMoveCheckResponse = ICheck;

/**
 * Перемещает проверку
 */
export const makeCheckMoveRequest = async (
	objectId: string,
	checkId: string,
	toObjectId: string,
	toSpaceId?: string
) => {
	const response = await httpRequests.withToken.patch<IMoveCheckResponse>(
		`checks/move-check/${checkId}`,
		{objectId, toObjectId, toSpaceId}
	);

	return response.data;
};
