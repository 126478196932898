import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IFile from '@tehzor/tools/interfaces/IFile';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import {useMemo} from 'react';

export const useSavedImage = (
	image?: IFile, uploadingFile?: IUploadingFile
): IAttachment[] | undefined =>
	useMemo(() => (image && !uploadingFile ? [
		{
			id: image.id,
			full: image
		}
	] : undefined), [image, uploadingFile]);