import {memo} from "react";
import {useTaskStatuses} from "@src/core/hooks/queries/taskStatuses/hook";
import {useTasksCountByStatus} from "@src/core/hooks/tasks/useTasksCountByStatus";
import {TaskIndicator} from "@tehzor/ui-components";

interface TaskIndicatorsProps {
	checkListId?: string;
	checkItemId?: string;
	spaceId?: string;
	workAcceptanceId?: string;
}

export const TaskIndicators = memo(
	({checkListId, checkItemId, spaceId, workAcceptanceId}: TaskIndicatorsProps) => {
		const {data: taskStatuses} = useTaskStatuses();
		const taskCounts = useTasksCountByStatus({
			listId: checkListId,
			itemId: checkItemId,
			spaceId,
			workAcceptanceId
		});
		return (
			<div className="check-list-page__indicators">
				{taskStatuses &&
					taskCounts.map(({status, count}) =>
						count > 0 ? (
							<TaskIndicator
								key={status}
								statuses={taskStatuses}
								status={status}
								count={count}
							/>
						) : null
					)}
			</div>
		);
	}
);