import {wsConnector} from '@src/api/backend/wsConnector';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {ISendResponse} from '@src/interfaces/ISendResponse';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на отправку экспортированных проверок
 */
export const makeExportedInternalAcceptancesSendRequest = async (
	email: string,
	templateId: string,
	objectId?: string,
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	selected?: string[],
	createDocument?: boolean
) => {
	const params = {
		email: getEmailsArray(email),
		templateId,
		objectId,
		filters: {
			...filters,
			selected
		},
		sort: convertSortParam(sort),
		timezone: getTimezone(),
		createDocument
	};
	return wsConnector.sendAuthorizedRequest<ISendResponse>('sendExportedInternalAcceptances', params);
};
