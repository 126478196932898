import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import { Suspense, useCallback, useState } from 'react';
import * as React from 'react';
import {MovingWarrantyClaimDialog} from '../MovingWarrantyClaimDialog';

export function useMovingWarrantyClaimDialog(
	objectId: string,
	warrantyClaim: IWarrantyClaim
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<Suspense>
			<MovingWarrantyClaimDialog
				objectId={objectId}
				claim={warrantyClaim}
				isOpen={isOpen}
				onClose={close}
			/>
		</Suspense>
	);

	return [dialog, open];
}
