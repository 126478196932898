import {createReducer} from '@reduxjs/toolkit';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	IDeleteInspectionPayload,
	IEditInspectionPayload
} from '@src/store/modules/entities/inspection/actions';
import {IAddInspectionPayload} from '@src/store/modules/entities/inspection/actions/add';
import * as inspectionTypes from '@src/store/modules/entities/inspection/constants';
import {
	IAddProblemPayload,
	IDeleteProblemPayload,
	IEditProblemPayload,
	IEditProblemStatusPayload
} from '@src/store/modules/pages/problem/actions';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {
	IEditStructurePayload,
	IGetStructureInspectionsPayload,
	IGetStructurePayload,
	IGetStructureProblemsPayload
} from './actions';
import * as types from './constants';

export type StructureEntitiesTab = 'all';

const {entitiesCacheTime} = config;

export interface IStructureState {
	data?: IStructure;
	loaded?: boolean;
	problems: {
		byId: Record<string, IListProblem>;
		allIds: string[];
		expires?: number;
	};
	inspections: {
		byId: Record<string, IListInspection>;
		allIds: string[];
		expires?: number;
	};
}

export const getInitialState = (): IStructureState => ({
	problems: {
		byId: {},
		allIds: []
	},
	inspections: {
		byId: {},
		allIds: []
	}
});

export const structure = createReducer<IStructureState>(getInitialState(), {
	[types.GET_REQUEST]: (state, {payload}: {payload: {structureId: string}}) =>
		state.data?.id !== payload.structureId ? getInitialState() : state,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetStructurePayload}) => {
		state.data = payload;
		state.loaded = true;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditStructurePayload}) => {
		state.data = payload;
	},
	[types.GET_PROBLEMS_SUCCESS]: (state, {payload}: {payload: IGetStructureProblemsPayload}) => {
		state.problems.byId = payload.byId;
		state.problems.allIds = payload.allIds;
		state.problems.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_INSPECTIONS_SUCCESS]: (
		state,
		{payload}: {payload: IGetStructureInspectionsPayload}
	) => {
		state.inspections.byId = payload.byId;
		state.inspections.allIds = payload.allIds;
		state.inspections.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Нарушения
	[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
		if (state.data?.id === payload.links?.structureId) {
			state.problems.byId[payload.id] = payload;
			state.problems.allIds.unshift(payload.id);
		}
	},
	[problemTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditProblemPayload}) => {
		if (state.problems.allIds.includes(payload.id)) {
			state.problems.byId[payload.id] = payload;
		}
	},
	[problemTypes.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditProblemStatusPayload}
	) => {
		if (state.problems.allIds.includes(payload.id)) {
			state.problems.byId[payload.id] = payload;
		}
	},
	[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (state.problems.allIds.includes(payload.problemId)) {
			state.problems.allIds = state.problems.allIds.filter(id => id !== payload.problemId);
			delete state.problems.byId[payload.problemId];
		}
	},
	// Осмотры
	[inspectionTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddInspectionPayload}) => {
		if (state.data?.id === payload.links?.structureId) {
			state.inspections.byId[payload.id] = payload;
			state.inspections.allIds.unshift(payload.id);
		}
	},
	[inspectionTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditInspectionPayload}) => {
		if (state.inspections.allIds.includes(payload.id)) {
			state.inspections.byId[payload.id] = payload;
		}
	},
	[inspectionTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteInspectionPayload}) => {
		if (state.inspections.allIds.includes(payload.inspectionId)) {
			state.inspections.allIds = state.inspections.allIds.filter(
				id => id !== payload.inspectionId
			);
			delete state.inspections.byId[payload.inspectionId];
		}
	},

	[CLEAR_STORE]: getInitialState
});
