import {IStructuresStatsDataRequest} from '@src/interfaces/IStructuresStatsData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

export type IGetStructuresProblemsResponse = Record<string, IEntityProblemsData>;

/**
 * Возвращает статистику нарушений по структурам
 */
export const getStructuresProblemsDataRequest = async (
	requestData: IStructuresStatsDataRequest
) => {
	const response = await httpRequests.withToken.post<IGetStructuresProblemsResponse>(
		'structures/get-problems-stats',
		{
			objectId: requestData.objectId,
			structuresIds: requestData.structuresIds
		}
	);
	return response.data;
};
