import { memo } from 'react';
import {useLegalMainContent} from '../hooks/useLegalMainContent';
import {Plate} from '@tehzor/ui-components';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

interface IMainContentProps {
	legal: ILegalEntity;
}

export const MainContent = memo(({legal}: IMainContentProps) => {
	const items = useLegalMainContent(legal);

	return (
		<div className="legal-page__main-content-wrapper">
			<Plate>
				<div className="legal-page__main-content">
					<div className="legal-page__main-content-section">{items}</div>
				</div>
			</Plate>
		</div>
	);
});
