import {orderBy} from 'lodash';
import {ILegalsSortState} from '@src/store/modules/settings/pages/legals/reducers';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export const handleSort = (array: ILegalEntity[], sort?: ILegalsSortState) => {
	if (!sort) {
		return array;
	}

	const sortKeys = Object.keys(sort || {}) as Array<keyof ILegalsSortState>;
	const sortItem = sort[sortKeys[0]];
	const order: Array<'asc' | 'desc'> = sortItem ? ['asc'] : ['desc'];

	if (sortKeys[0] === 'company') {
		return array.sort((a, b) => {
			const aName = a.company?.name;
			const bName = b.company?.name;

			if (!aName || !bName) return -1;

			if (order[0] === 'asc') {
				return aName.localeCompare(bName);
			}
			return bName.localeCompare(aName);
		});
	}

	const orderedArr = orderBy(array, sortKeys, order);

	return orderedArr;
};
