import {IGetTasksResponse} from '@src/api/backend/tasks';
import {ITask} from "@tehzor/tools/interfaces/tasks/ITask";

export const extractTasksAsArray = (data: IGetTasksResponse) =>
	data.allIds.map(id => data.byId[id]);

export const filterByLinks = (tasks: ITask[], spaceId?: string, workAcceptanceId?: string): ITask[] => (
	tasks.filter(task => {
		if(!task.links) {
			return false;
		}
		const hasMatchingSpaceId = spaceId ? task.links.spaceId === spaceId : false;
		const hasMatchingWorkAcceptanceId =
			workAcceptanceId ? task.links?.workAcceptanceId === workAcceptanceId : false;
		return hasMatchingSpaceId || hasMatchingWorkAcceptanceId;
	})
)