import {IGetInspectionStoriesResponse} from '@src/api/backend/inspectionStories';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractInspectionStoriesAsArray} from './selectors';
import {IInspectionStory} from '@tehzor/tools/interfaces/inspectionStories/IInspectionStory';

export const useInspectionStories = <T = IGetInspectionStoriesResponse>(
	objectId: string,
	inspectionId: string,
	select?: (data: IGetInspectionStoriesResponse) => T,
	options?: Partial<UseQueryOptions<IGetInspectionStoriesResponse, IError, T>>
) =>
	useQuery<IGetInspectionStoriesResponse, IError, T>({
		queryKey: inspectionsQueryKeys.story(objectId, inspectionId),
		meta: {
			error: 'при загрузке истории осмотра'
		},
		...options,
		select
	});
export const useInspectionStoriesAsArray = (
	objectId: string,
	inspectionId: string,
	options?: Partial<UseQueryOptions<IGetInspectionStoriesResponse, IError, IInspectionStory[]>>
) => useInspectionStories(objectId, inspectionId, extractInspectionStoriesAsArray, options);
