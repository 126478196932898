import { ReactElement } from 'react';
import * as React from 'react';
import './DraggableMenu.less';
import classNames from 'classnames';
import anime, {AnimeInstance} from 'animejs';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';
import ResizeObserver from 'resize-observer-polyfill';
import TouchablePanel from '../../containers/TouchablePanel';
import {findMenuItemIndex} from './utils/findMenuItemIndex';

export interface IDraggableMenuItemProps {
	active?: boolean;
}

interface IDraggableMenuProps {
	className?: string;
	style?: React.CSSProperties;
	itemClassName?: string;
	itemStyle?: React.CSSProperties;
	fakeItemClassName?: string;
	fakeItemStyle?: React.CSSProperties;
	value?: number;
	defaultValue?: number;
	items?: ReactElement<IDraggableMenuItemProps> | Array<ReactElement<IDraggableMenuItemProps>>;
	disableFakeItem?: boolean;

	onSelect?(index: number): void;
}

interface IDraggableMenuState {
	value: number;
}

class DraggableMenu extends React.PureComponent<IDraggableMenuProps, IDraggableMenuState> {
	static displayName = 'DraggableMenu';

	/**
	 * Элемент TouchablePanel
	 */
	private panel?: TouchablePanel | null;

	/**
	 * Текущий активный пункт меню
	 */
	private _item?: HTMLDivElement | null;

	/**
	 * Псевдо пункт меню
	 */
	private _fakeItem?: HTMLDivElement | null;

	/**
	 * Предыдущее значение top активного пункта меню
	 */
	private _prevItemTop = 0;

	/**
	 * Предыдущее значение left активного пункта меню
	 */
	private _prevItemLeft = 0;

	/**
	 * Объект анимации псевдо пункта меню
	 */
	private _fakeItemAnimation?: AnimeInstance;

	/**
	 * Функции для отписки от событий
	 */
	private _unsubs: { [key: string]: Array<() => void> } = {
		item: []
	};

	/**
	 * Обработчик изменения размера активного пункта меню
	 */
	private _handleItemResize = rafThrottle(() => {
		this._updateFakeItemSize();
	});

	constructor(props: IDraggableMenuProps) {
		super(props);

		this.state = {value: props.defaultValue || 0};
	}

	static getDerivedStateFromProps(nextProps: IDraggableMenuProps) {
		if (nextProps.value !== undefined) {
			return {value: nextProps.value};
		}
		return null;
	}

	componentDidMount() {
		const fontsSet: EventTarget = (document as any).fonts;
		if (fontsSet?.addEventListener) {
			fontsSet.addEventListener('loadingdone', () => {
				this._updateFakeItemSize();
				this._updateFakeItemPosition(true);
			});
		} else {
			this._updateFakeItemSize();
			this._updateFakeItemPosition(true);
		}
	}

	componentDidUpdate(prevProps: IDraggableMenuProps, prevState: IDraggableMenuState) {
		if (this.state.value !== prevState.value) {
			this.updatePanelPosition();
			this._moveFakeItem();
		}
	}

	render() {
		const {className, style, fakeItemClassName, fakeItemStyle, items, disableFakeItem} = this.props;

		return items ? (
			<TouchablePanel
				className={classNames('draggable-menu', className)}
				style={style}
				onClick={this._handleClick}
				ref={this.savePanelRef}
			>
				<div className="draggable-menu__items">{this._cloneItems()}</div>
				{!disableFakeItem && (
					<div
						className={classNames('draggable-menu__fake-item', fakeItemClassName)}
						style={fakeItemStyle}
						ref={this._saveFakeItemRef}
					/>
				)}
			</TouchablePanel>
		) : null;
	}

	/**
	 * Клонирует children, оборачивая в div и добавляя selected в свойства
	 */
	private _cloneItems = () => {
		const {items, itemClassName, itemStyle} = this.props;
		if (items === undefined) {
			return null;
		}
		const {value} = this.state;

		if (Array.isArray(items)) {
			return items.map((item, index) =>
				React.createElement(
					'div',
					{
						// eslint-disable-next-line react/no-array-index-key
						key: index,
						className: classNames('draggable-menu__item', itemClassName),
						style: itemStyle,
						'data-index': index,
						ref: value === index ? this._saveItemRef : undefined
					},
					React.cloneElement(item, {active: value === index})
				));
		}
		return React.createElement(
			'div',
			{
				key: 0,
				className: classNames('draggable-menu__item', itemClassName),
				style: itemStyle,
				'data-index': 0,
				ref: value === 0 ? this._saveItemRef : undefined
			},
			React.cloneElement(items, {active: value === 0})
		);
	};

	/**
	 * Определяет новый активный элемент
	 *
	 * @param event событие
	 */
	private _handleClick = (event: MouseEvent | TouchEvent) => {
		const {value} = this.state;
		if (event.target) {
			const index = findMenuItemIndex(event.target as HTMLElement);
			if (index === undefined || index === value) {
				return;
			}
			const {value: propsValue, onSelect} = this.props;
			if (propsValue === undefined) {
				this.setState({value: index});
			}
			if (onSelect) {
				onSelect(index);
			}
		}
	};

	/**
	 * Анимированно свдигает wrapper для попадания активного пункта в область просмотра
	 */
	private updatePanelPosition = () => {
		if (this.panel && this._item) {
			const {offsetLeft, offsetWidth} = this._item;
			const {offset, width} = this.panel;
			// Проверяем входит ли элемент в видимую область слева
			if (offsetLeft + offset < 0) {
				this.panel.move(-offsetLeft);
			}
			// Проверяем входит ли элемент в видимую область справа
			if (offsetLeft + offsetWidth + offset - width > 0) {
				// Дополнительно проверяем входит ли левый край элемента в видимую область
				if (width - offsetWidth < 0) {
					this.panel.move(-offsetLeft);
				} else {
					this.panel.move(width - offsetLeft - offsetWidth);
				}
			}
		}
	};

	/**
	 * Обновляет расположение фона псевдо элемента
	 *
	 * @param initial не учитывать ли предыдущее положение
	 */
	private _updateFakeItemPosition = (initial?: boolean) => {
		if (this._item && this._fakeItem) {
			this._stopFakeItemAnimation();

			if (this._prevItemTop !== this._item.offsetTop || initial) {
				this._fakeItem.style.top = `${this._item.offsetTop}px`;
				this._prevItemTop = this._item.offsetTop;
			}
			if (this._prevItemLeft !== this._item.offsetLeft || initial) {
				this._fakeItem.style.left = `${this._item.offsetLeft}px`;
				this._prevItemLeft = this._item.offsetLeft;
			}
		}
	};

	/**
	 * Обновляет размеры фона псевдо элемента
	 */
	private _updateFakeItemSize = () => {
		if (this._item && this._fakeItem) {
			this._fakeItem.style.width = `${this._item.offsetWidth}px`;
			this._fakeItem.style.height = `${this._item.offsetHeight}px`;
		}
	};

	/**
	 * Останавливает анимацию перемещения фона псевдо элемента
	 */
	private _stopFakeItemAnimation = () => {
		if (this._fakeItemAnimation) {
			this._fakeItemAnimation.pause();
			this._fakeItemAnimation = undefined;
		}
	};

	/**
	 * Перемещает псевдо элемент с анимацией
	 */
	private _moveFakeItem = () => {
		if (this._item) {
			this._stopFakeItemAnimation();

			const top = this._item.offsetTop;
			const left = this._item.offsetLeft;
			const width = this._item.offsetWidth;
			const height = this._item.offsetHeight;

			if (this._prevItemTop !== top || this._prevItemLeft !== left) {
				setTimeout(() => {
					if (this._fakeItem) {
						const distance
							= left !== this._prevItemLeft
							? Math.abs(left - this._prevItemLeft)
							: Math.abs(top - this._prevItemTop);
						const duration = distance <= 300 ? 300 : Math.floor(5 * distance ** 0.5 + 300);

						this._fakeItemAnimation = anime({
							targets: this._fakeItem,
							top,
							left,
							width: {
								value: width,
								duration: duration - 100,
								delay: 50
							},
							height: {
								value: height,
								duration: duration - 100,
								delay: 50
							},
							easing: 'easeOutQuart',
							duration,
							update: anim => {
								this._prevItemTop
									= top + Math.floor(((this._prevItemTop - top) * (100 - anim.progress)) / 100);
								this._prevItemLeft
									= left + Math.floor(((this._prevItemLeft - left) * (100 - anim.progress)) / 100);
							}
						});
					}
				}, 0);
			}
		}
	};

	private _addResizeEventListener = (element: HTMLElement, handler: () => void, unsubsKey: string) => {
		const observer = new ResizeObserver(handler);
		observer.observe(element);
		this._unsubs[unsubsKey].push(() => observer.disconnect());
	};

	private _removeEventListeners = (unsubsKey: string) => {
		this._unsubs[unsubsKey].forEach(fn => fn());
		this._unsubs[unsubsKey] = [];
	};

	/**
	 * Сохраняет ссылку на TouchablePanel
	 *
	 * @param element элемент
	 */
	private savePanelRef = (element: TouchablePanel | null) => {
		this.panel = element;
	};

	/**
	 * Сохраняет ссылку на активный пункт меню
	 *
	 * @param element html-элемент
	 */
	private _saveItemRef = (element: HTMLDivElement | null) => {
		this._item = element;
		this._removeEventListeners('item');
		if (this._item) {
			this._addResizeEventListener(this._item, this._handleItemResize, 'item');
		}
	};

	/**
	 * Сохраняет ссылку на псевдо пункт меню
	 *
	 * @param element html-элемент
	 */
	private _saveFakeItemRef = (element: HTMLDivElement | null) => {
		this._fakeItem = element;
		this._updateFakeItemSize();
		this._updateFakeItemPosition(true);
	};
}

export default DraggableMenu;
