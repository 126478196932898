import {memo, useCallback} from 'react';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import BaseStatusSelect, {IBaseStatusSelectProps} from '../BaseStatusSelect/BaseStatusSelect';
import {getSelectedStatus} from '../BaseStatusSelect/utils/getSelectedStatus';
import {useSpaceStatusMenu} from './hooks/useSpaceStatusMenu';
import {getSpaceStatusColor} from './utils/getSpaceStatusColor';
import './SpaceStatusSelect.less';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {convertClassNames} from '../../../../utils/convertClassNames';
import classNames from 'classnames';
import useConfirmDialog from '../../../../hooks/useConfirmDialog';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';

interface IStatusSelectProps extends Omit<IBaseStatusSelectProps, 'menu' | 'selectedStatus'> {
	statuses?: INormalizedData<ISpaceStatus>;
	availableStatuses?: string[];
	value?: string;

	actionInProgress?: boolean;
	stagesMap: Record<ObjectStageIds, IObjectStage>;
	onChange?: (value: string) => void;
}

export const SpaceStatusSelect = memo((props: IStatusSelectProps) => {
	const {
		statuses,
		availableStatuses,
		value,
		stagesMap,
		onChange,
		className,
		actionInProgress,
		...baseStatusSelectProps
	} = props;

	const classes = convertClassNames(className);

	const [dialog, getConfirmation, changeDialogData] = useConfirmDialog('', '', {
		acceptBtnProps: {type: 'accent-red'}
	});

	const handleChange = useCallback(
		async (statusId: string) => {
			if (!onChange) return;
			if (statuses) {
				const status = statuses.byId[statusId];
				if (status && status.notificationMessage) {
					const {title, body} = status.notificationMessage;
					changeDialogData({title, message: body});
					if (!(await getConfirmation())) {
						return;
					}
				}
			}
			onChange(statusId);
		},
		[changeDialogData, getConfirmation, onChange, statuses]
	);

	const selectedStatus = getSelectedStatus<ISpaceStatus>(value, statuses);
	const menu = useSpaceStatusMenu<ISpaceStatus>({
		selectedStatus,
		statuses,
		availableStatuses,
		stagesMap,
		onChange: handleChange
	});

	return (
		<>
			{dialog}
			<BaseStatusSelect
				{...baseStatusSelectProps}
				className={{
					root: classNames('space-status-select', classes?.root),
					btn: classNames('space-status-select__btn', classes?.btn)
				}}
				actionInProgress={actionInProgress}
				selectedStatus={selectedStatus}
				menu={menu}
				color={getSpaceStatusColor(selectedStatus)}
				multiline
			/>
		</>
	);
});
