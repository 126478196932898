import {queryClient} from '@src/api/QueryClient';
import {makeResponsibilityRuleEditRequest} from '@src/api/backend/responsibilityRules';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {ISavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/ISavingResponsibilityRule';

export interface IEditResponsibilityRulesParams {
	ruleId: string;
	groupId: string;
	rule: ISavingResponsibilityRule;
}

export const useEditResponsibilityRules = () =>
	useMutation({
		mutationFn: async (params: IEditResponsibilityRulesParams) => {
			const {ruleId, groupId, rule} = params;
			return makeResponsibilityRuleEditRequest(ruleId, groupId, rule);
		},
		mutationKey: responsibilityRulesQueryKeys.edit(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
			addSuccessToast('Успешно', 'Правило обновлено');
		},
		onError: () => {
			addErrorToast('Ошибка', 'при изменении правила');
		}
	});
