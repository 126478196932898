import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton, Select2, SelectOption} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useTranslation} from 'react-i18next';

export interface IStatusesFilterPageProps {
	objectId?: string;
	entity?: string;
	filterName?: string;
	label?: string;
	statuses?: Array<{id: string; name: string}>;
	resetOnApply?: string[];
	translationDictionaryKey?: dictionaryKeys;
}

const StatusesFilterPage = ({
	objectId,
	entity,
	filterName = 'statuses',
	label,
	statuses,
	resetOnApply,
	translationDictionaryKey
}: IStatusesFilterPageProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {state, change} = useEntitiesFiltersCtx<{statuses?: string[]}>();
	const [selected, setSelected] = useState<string[] | undefined>(state[filterName] as string[]);
	const [search, setSearch] = useState('');

	const isSearchShown = useMemo(() => statuses && statuses.length >= 8, [statuses]);

	const translatedOptions = useTranslatedDictionaryArray(translationDictionaryKey, statuses);
	const translatedLabel = label ?? t('entitiesFilters.statusesFilter.label');

	const filteredData = useMemo(() => {
		if (!translatedOptions && statuses) {
			return flatFilter(statuses, 'name', search);
		}
		if (translatedOptions) {
			return flatFilter(translatedOptions, 'name', search);
		}
		return undefined;
	}, [translatedOptions, search, statuses]);

	const location = useLocation();
	const {canGoBack} = location.state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		const changes = {[filterName]: selected && selected?.length > 0 ? selected : undefined};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		change(changes);
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: translatedLabel,
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label={t('entitiesFilters.cancelButton.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(state[filterName], selected)}
			onApplyClick={handleApply}
			search={
				isSearchShown && (
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				)
			}
		>
			<Select2
				multiple
				value={selected}
				onChange={setSelected}
			>
				{filteredData?.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</TranslatedFilterPage>
	);
};

export default StatusesFilterPage;
