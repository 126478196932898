import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {WorkAcceptanceTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IWorkingGroupsFilters} from '../types/IWorkingGroupsFilters';

const checkObjects = (group: IWorkingGroup, objectId: string) =>
	!group.objects?.length || group.objects.includes(objectId);

const checkType = (group: IWorkingGroup, type?: WorkingGroupTypeId) => !type || group.type === type;

const checkStage = (group: IWorkingGroup, stage?: ObjectStageIds) =>
	!group.stages?.length || (stage && group.stages.includes(stage));

const checkScope = (group: IWorkingGroup, scope?: string) =>
	!group.scope || !scope || group.scope === scope;

const checkWorkAcceptanceType = (group: IWorkingGroup, type?: WorkAcceptanceTypeId) =>
	!type || group.workAcceptanceType?.includes(type);

const checkWorkAcceptanceFrontType = (
	group: IWorkingGroup,
	frontType?: WorkAcceptanceFrontTypeId
) => !frontType || group.workAcceptanceFrontType?.includes(frontType);

export const filterWorkingGroups = (
	workingGroups: IWorkingGroup[],
	{
		objectId,
		scope,
		stage,
		type,
		workAcceptanceFrontType,
		workAcceptanceType
	}: IWorkingGroupsFilters
) => {
	if (stage === undefined && scope === undefined) {
		return workingGroups.filter(
			group =>
				checkObjects(group, objectId) &&
				checkType(group, type) &&
				checkWorkAcceptanceType(group, workAcceptanceType) &&
				checkWorkAcceptanceFrontType(group, workAcceptanceFrontType)
		);
	}

	return workingGroups.filter(
		group =>
			checkStage(group, stage) &&
			checkScope(group, scope) &&
			checkObjects(group, objectId) &&
			checkType(group, type) &&
			checkWorkAcceptanceType(group, workAcceptanceType) &&
			checkWorkAcceptanceFrontType(group, workAcceptanceFrontType)
	);
};
