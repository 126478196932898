import {IGetTaskPrioritiesResponse} from '@src/api/backend/taskPriorities';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractTaskPrioritiesAsArray, extractTaskPrioritiesMap} from './selectors';

export const useTaskPriorities = <T = IGetTaskPrioritiesResponse>(
	select?: (data: IGetTaskPrioritiesResponse) => T
) =>
	useQuery<IGetTaskPrioritiesResponse, IError, T>({
		queryKey: restDictionariesQueryKeys.taskPriorities(),
		meta: {
			error: 'при получение приоритетов задач'
		},
		select
	});

export const useTaskPrioritiesAsArray = () => useTaskPriorities(extractTaskPrioritiesAsArray);
export const useTaskPrioritiesMap = () => useTaskPriorities(extractTaskPrioritiesMap);
