import {IContractsFiltersState, IContractsSortState} from '@src/store/modules/settings/pages/contracts/reducers';

export type IGetPaginateContractsQueryKey = [] & {
	0: string; // contracts
	1: string; // paginate
	2?: IContractsFiltersState; // filters
	3?: IContractsSortState; // sort
	4?: number; // offset
	5?: number; // pageSize
};

export type IGetListContractsQueryKey = [] & {
	0: string; // contracts
	1: string; // list
	2?: IContractsFiltersState; // filters
	3?: IContractsSortState; // sort
}

export type IGetContractQueryKey = [] & {
	0: string; // contract
	1: string; // detail
	2: string; // contractId
};

export const contractsQueryKeys = {
	all: () => ['contracts'],
	one: () => ['contract'],
	lists: () => [...contractsQueryKeys.all(), 'list'],
	list: (
		filters?: IContractsFiltersState,
		sort?: IContractsSortState
	) => [
		...contractsQueryKeys.lists(), filters, sort
	],
	localList: () => [...contractsQueryKeys.all(), 'local-list'],
	paginates: () => [...contractsQueryKeys.all(), 'paginate'],
	paginate: (
		filters?: IContractsFiltersState,
		sort?: IContractsSortState,
		offset?: number,
		pageSize?: number
	) => [
		...contractsQueryKeys.paginates(), filters, sort, offset, pageSize
	],
	savingData: (key: string) =>
		[...contractsQueryKeys.one(), 'saving-data', key],
	add: () => [...contractsQueryKeys.one(), 'add'],
	edit: () => [...contractsQueryKeys.one(), 'edit'],
	delete: () => [...contractsQueryKeys.one(), 'delete'],
	details: () => [...contractsQueryKeys.one(), 'detail'],
	detail: (contractId: string) =>
		[...contractsQueryKeys.details(), contractId],
};