import {useMemo} from 'react';
import * as React from 'react';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {Tree} from 'array-to-tree';
import {Comment, ICommentProps,IProblemCommentTranslationsProps} from '../Comment';
import {flattenDescendants} from '../utils/flattenDescendants';
import {format} from 'date-fns';

interface IChildrenProps {
	className?: ICommentProps['className'];
	style?: React.CSSProperties;
	data: Tree<IComment>;
	level: number;
	isSelected?: (id: string) => boolean;
	controls?: (data: IComment) => React.ReactNode;
	dateOptions?: Parameters<typeof format>[2];

	onSubCommentAdd?: (comment: IComment) => void;
	onImageClick?: (id: string, index: number) => void;
	translationsProps?: IProblemCommentTranslationsProps;
}

const Children = (props: IChildrenProps) => {
	const {
		className,
		style,
		data,
		level,
		isSelected,
		controls,
		dateOptions,
		onSubCommentAdd,
		onImageClick,
		translationsProps
	} = props;

	const comments = useMemo(
		() =>
			flattenDescendants(data).sort(
				(a, b) => (a.item.createdAt ?? 0) - (b.item.createdAt ?? 0)
			),
		[data]
	);

	return (
		<div className="problem-comment__children">
			{comments.map(({item, parent, showAppeal}) => (
				<Comment
					key={item.id}
					className={className}
					style={style}
					data={item}
					parent={parent}
					controls={controls}
					level={level + 1}
					showAppeal={showAppeal}
					isSelected={isSelected}
					dateOptions={dateOptions}
					onSubCommentAdd={onSubCommentAdd}
					onImageClick={onImageClick}
					translationsProps={translationsProps}
				/>
			))}
		</div>
	);
};

export default Children;
