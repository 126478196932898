import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {DatePickerResult, InlineDateRangePicker, LinkButton} from '@tehzor/ui-components';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {ICustomField, ValueTypeId} from '@tehzor/tools/interfaces/fields';
import {
	ICustomFilterDateValue,
	ICustomFiltersData
} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';
import isEqual from 'lodash/isEqual';

interface ICustomFilterDatePageProps {
	objectId: string;
	entity: string;
	fieldData: ICustomField;
	filterValue: ICustomFilterDateValue;
	onChange: (data: ICustomFiltersData | Record<string, undefined>) => void;
	onClear: (key: string) => void;
}

export const CustomFilterDatePage = ({
	objectId,
	entity,
	fieldData,
	filterValue,
	onChange,
	onClear
}: ICustomFilterDatePageProps) => {
	const {key, valueType, name} = fieldData;
	const {t} = useTranslation();
	const locale = useDateFnsLocale();
	const {goBack} = useChangePath();

	const [dateValues, setDateValues] = useState(filterValue);
	const valuesAsDate = useMemo(() => {
		if (dateValues && dateValues.from && dateValues.to) {
			return {
				from: new Date(dateValues.from),
				to: new Date(dateValues.to)
			};
		}
		return {
			from: undefined,
			to: undefined
		};
	}, [dateValues]);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = () => {
		if (!dateValues) {
			onClear(key);
		} else {
			onChange({
				[key]: {
					valueTypeId: valueType.id as ValueTypeId.DATE,
					value: dateValues
				}
			});
		}
		goBack();
	};

	const handleChange = useCallback(
		(fromValue: Date | null, toValue: Date | null) => {
			if (fromValue && toValue) {
				setDateValues({from: fromValue.getTime(), to: toValue.getTime()});
			}
		},
		[setDateValues]
	);

	const handleClear = useCallback(() => {
		setDateValues(undefined);
	}, [setDateValues]);

	useAppHeader(
		{
			title: name,
			showBackBtn: true,
			mobileRightButtons: dateValues ? (
				<LinkButton
					label={t('entitiesFilters.cancelButton.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[dateValues]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={
				isEqual(filterValue?.from, dateValues?.from) &&
				isEqual(filterValue?.to, dateValues?.to)
			}
			onApplyClick={handleApply}
		>
			<DatePickerResult
				style={{marginTop: '20px'}}
				value1={valuesAsDate.from}
				value2={valuesAsDate.to}
				placeholder1={t('entitiesFilters.dateFilter.startDate.label')}
				placeholder2={t('entitiesFilters.dateFilter.completionDate.label')}
				isRange
				dateFormat="dd.MM.yyyy"
			/>
			<InlineDateRangePicker
				style={{marginTop: '18px'}}
				valueFrom={valuesAsDate.from}
				valueTo={valuesAsDate.to}
				onChange={handleChange}
				datesOptions={{locale}}
			/>
		</TranslatedFilterPage>
	);
};
