import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IBankAccount, ISavingBankAccount} from '@tehzor/tools/interfaces/bankAccounts';

export type IAddBankAccountResponse = IBankAccount;

/**
 * Добавляет юрлицо
 */
export const makeBankAccountAddRequest = async (fields: ISavingBankAccount) => {
	const response = await httpRequests.withToken.post<IAddBankAccountResponse>('bank-accounts', fields);
	return response.data;
};