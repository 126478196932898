import IAttachment from '../interfaces/IAttachment';
import IFile from '../interfaces/IFile';
import {isImage} from './mimeTypes';

/**
 * Разделяет массив вложений на массивы изображений и остальных файлов
 *
 * @param attachments вложения
 */
export default function splitAttachments(attachments: IAttachment[]): [IAttachment[], IFile[]] {
	const images: IAttachment[] = [];
	const docs: IFile[] = [];
	for (const attachment of attachments) {
		if (attachment.full) {
			if (isImage(attachment.full.type)) {
				images.push(attachment);
			} else {
				docs.push(attachment.full);
			}
		}
	}
	return [images, docs];
}
