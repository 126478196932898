import { memo, useCallback } from 'react';
import classNames from 'classnames';
import {StatusesFilter} from './filters/StatusesFilter';
import {CreationDateFilter} from './filters/CreationDateFilter';
import {Button} from '@tehzor/ui-components';
import {ActionsFilter} from './filters/ActionsFilter';
import {getEntityStatuses} from '@src/pages/filters/OfflineJournalFilterPage';
import './OfflineJournalFilters.less';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';
import {clearFilters} from '@src/store/modules/settings/pages/offlineJournal/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useTranslation} from "react-i18next";
import {getEntityActions} from "@src/pages/OfflineJournalPage/utils/getEntityActions";

interface IOfflineJournalFilters {
	isFiltersActive?: boolean;
	className?: string;
}

export const OfflineJournalFilters = memo((props: IOfflineJournalFilters) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {state} = useEntitiesFiltersCtx<IOfflineJournalFiltersState>();
	const {isFiltersActive, className} = props;

	const handleClear = useCallback(() => {
		dispatch(clearFilters());
	}, [dispatch]);

	return (
		<div className={classNames('offline-journal-filters', className)}>
			<ActionsFilter
				allActions={getEntityActions(t)}
				actions={state.actions}
			/>
			<CreationDateFilter
				label={t('offlineJournalPage.offlineJournalFilters.creationDateFilter.label')}
				from={state.createdAtFrom}
				to={state.createdAtTo}
			/>
			<StatusesFilter
				statuses={state.statuses}
				allStatuses={getEntityStatuses(t)}
			/>
			{isFiltersActive && (
				<Button
					type="cancel"
					label={t('offlineJournalPage.offlineJournalFilters.button.cancel.label')}
					onClick={handleClear}
				/>
)}
		</div>
	);
});
