import {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {useObject} from '@src/core/hooks/queries/objects/hooks';

interface ICreateCheckProps {
	taskId: string;
	objectId: string;
}

export const MobileCreateCheck = ({taskId, objectId}: ICreateCheckProps) => {
	const {data: object} = useObject(objectId);

	const [dialog, openDialog] = useAddingCheckDialog({
		objectId,
		stage: object?.stage,
		links: {taskId}
	});

	const handleClick = useCallback(() => {
		openDialog();
	}, [openDialog]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-check-24" />
			</IconButton>
			{dialog}
		</>
	);
};
