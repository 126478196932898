import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {Column} from 'react-table';
import DeleteCell from './cells/DeleteCell';
import RelationsCell from './cells/RelationsCell';
import NameCell from './cells/NameCell';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import StagesCell from './cells/StagesCell';
import {TFunction} from "i18next";

export const getMobileColumns = (tFunc: TFunction): Array<Column<IFullCategoriesSet>> => (
	[
		{
			id: 'name',
			Header: tFunc('categoriesSetsPage.table.columns.name.header'),
			accessor: 'name',
			Cell: NameCell,
			width: 100
		},
		{
			id: 'companies',
			Header: tFunc('categoriesSetsPage.table.columns.companies.header'),
			accessor: 'companies',
			Cell: RelationsCell,
			width: 100
		},
		{
			id: 'stages',
			Header: tFunc('categoriesSetsPage.table.columns.stages.header'),
			Cell: StagesCell,
			width: 100,
			sortDescFirst: true
		},
		{
			id: 'createdAt',
			Header: tFunc('categoriesSetsPage.table.columns.createdAt.header'),
			accessor: row => (row.createdAt ? format(row.createdAt, dateTimeCommaSeparatedFormat) : ''),
			width: 100
		},
		{
			id: '_delete',
			Cell: DeleteCell,
			defaultCanSort: false,
			disableResizing: true,
			isNonClickable: true
		}
	]
)

export const getMobileColumnsWithoutObjects = (tFunc: TFunction): Array<Column<IFullCategoriesSet>> => getMobileColumns(tFunc).filter(
	col => col.id !== 'companies'
);
