import {useMutation, useQueryClient} from '@tanstack/react-query';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {makeCheckAddRequest} from '@src/api/backend/check';
import {addErrorToast} from '@src/utils/toasts';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

interface IAddCheckOnlineParams {
	objectId: string;
	links: ICheck['links'] | undefined;
	stage?: ObjectStageIds;
}

// Добавление проверки, без учёта офлайн режима
export const useAddCheckOnline = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: checksQueryKeys.add(),
		mutationFn: ({objectId, links, stage}: IAddCheckOnlineParams) =>
			makeCheckAddRequest(objectId, links, stage),
		onError: () => addErrorToast('Ошибка', 'при добавлении проверки'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.latest()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.checks()});
		}
	});
};
