import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddCheckResponse = ICheck;

/**
 * Добавляет проверку
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param localId localId для синхронизации данных оффлайн сущностей и онлайн
 * @param stage стадия объекта
 */
export const makeCheckAddRequest = async (
	objectId: string,
	links: ICheck['links'] | undefined,
	localId?: string,
	stage: ObjectStageIds = ObjectStageIds.BUILDING
) => {
	const response = await httpRequests.withToken.post<IAddCheckResponse>('/checks', {
		objectId,
		links,
		stage,
		localId
	});

	return response.data;
};
