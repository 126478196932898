import SelectedEntitiesExport from './SelectedEntitiesExport';
import SelectedEntitiesDeletion from './SelectedEntitiesDeletion';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';

interface ISelectionsActionsProps {
	objectId: string;
	warrantyClaimId: string;
	selectedEntities: IWarrantyClaimEntity[];
}

const SelectionActions = ({
	objectId,
	warrantyClaimId,
	selectedEntities
}: ISelectionsActionsProps) => {
	const permissions = useWarrantyClaimsPermissions(objectId);

	return (
		<>
			{permissions.canExport && (
				<SelectedEntitiesExport
					objectId={objectId}
					warrantyClaimId={warrantyClaimId}
					selectedEntities={selectedEntities}
				/>
			)}
			{permissions.canDelete && (
				<SelectedEntitiesDeletion selectedEntities={selectedEntities}/>
			)}
		</>
	);
};

export default SelectionActions;
