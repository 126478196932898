import {Tree} from 'array-to-tree';

/**
 * Формирует список элементов у которых есть дочерние элементы
 *
 * @param data данные в виде дерева
 */
export const getAllParents = <T extends {id: string}>(data: Array<Tree<T>>): string[] =>
	data.reduce((acc, item) => {
		if (item.children) {
			acc.push(item.id, ...getAllParents(item.children));
		}
		return acc;
	}, [] as string[]);

/**
 * Формирует список всех предков у конкретного элемента
 *
 * @param items элементы дерева
 * @param ancestors массив предков для текущих элементов
 * @param checked выбранные элементы
 */
function getAllAncestors<T extends {id: string, parentId?: string}>(
	items: Array<Tree<T>>,
	ancestors: string[],
	checked: string[]
) {
	let result: string[] = [];
	for (const item of items) {
		if (checked.includes(item.id)) {
			result = result.concat(ancestors);
		}
		if (item.children?.length) {
			result = result.concat(getAllAncestors(item.children, ancestors.concat([item.id]), checked));
		}
	}
	return result;
}

/**
 * Формирует список изначально развёрнутых элементов
 *
 * @param treeData данные в виде дерева
 * @param controlled controlled элементы
 * @param uncontrolled uncontrolled элементы
 * @param checked выбранные элементы
 * @param isAllExpanded развёрнуты ли все элементы
 * @param isAllCheckedExpanded развёрнуты ли выбранные элементы
 */
export function formInitialExpanded<T extends {id: string, parentId?: string}>(
	treeData: Array<Tree<T>>,
	controlled?: string[],
	uncontrolled?: string[],
	checked?: string[],
	isAllExpanded?: boolean,
	isAllCheckedExpanded?: boolean
) {
	if (controlled) {
		return controlled;
	}
	if (uncontrolled) {
		return uncontrolled;
	}
	if (isAllExpanded) {
		return getAllParents(treeData);
	}
	if (isAllCheckedExpanded && checked?.length) {
		const keys = getAllAncestors(treeData, [], checked);
		return Array.from(new Set(keys).values());
	}
	return [];
}
