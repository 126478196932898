import { Dispatch } from 'react';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import Text from '@src/components/editableFields/Text';
import './EditableExportTemplate.less';
import CompanySelect from '../editableFields/CompanySelect';
import {ObjectsSelect} from '../editableFields/ObjectsSelect';
import {
	IEditableExportTemplateAction,
	IEditableExportTemplateState
} from '@src/core/hooks/states/useEditableExportTemplateState';
import Attachments from '../editableFields/Attachments';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {DestinationSelect} from '../editableFields/DestinationSelect';
import {DocumentTypeSelect} from '../editableFields/DocumentTypeSelect';

interface IEditableExportTemplateProps {
	editingState: IEditableExportTemplateState;
	editingDispatch: Dispatch<IEditableExportTemplateAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving?: boolean;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	fileNameError?: boolean;
	isEditing?: boolean;
}

export const EditableExportTemplate = (props: IEditableExportTemplateProps) => {
	const {
		editingState,
		editingDispatch,
		fieldsSettings,
		saving,
		uploadingFilesState,
		uploadingFilesDispatch,
		fileNameError,
		isEditing
	} = props;

	return (
		<div className="editable-export-template">
			{fieldsSettings.name !== undefined && (
				<Text
					field="name"
					label="Название"
					value={editingState.name}
					editingDispatch={editingDispatch}
					required={fieldsSettings.name.isRequired}
					disabled={saving}
					hasError={editingState.errors.name}
				/>
			)}
			{fieldsSettings.destination !== undefined && (
				<DestinationSelect
					field="destination"
					label="Страница для экспорта"
					value={editingState.destination}
					editingDispatch={editingDispatch}
					required={fieldsSettings.destination.isRequired}
					hasError={editingState.errors.destination}
				/>
			)}

			{fieldsSettings.name !== undefined && (
				<Text
					field="fileNameTemplate"
					label="Название файла на выходе"
					value={editingState.fileNameTemplate}
					editingDispatch={editingDispatch}
					required={fieldsSettings.fileNameTemplate.isRequired}
					disabled={saving}
					hasError={editingState.errors.fileNameTemplate}
				/>
			)}

			<CompanySelect
				field="companyId"
				label="Компания"
				disabled={isEditing}
				value={editingState.companyId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.companyId.isRequired}
				hasError={editingState.errors.companyId}
			/>

			<DocumentTypeSelect
				field="documentTypeId"
				label="Тип документа"
				disabled={saving}
				value={editingState.documentTypeId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.documentTypeId.isRequired}
				hasError={editingState.errors.documentTypeId}
			/>

			<ObjectsSelect
				field="objects"
				label="Объекты"
				value={editingState.objects}
				companyId={editingState.companyId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.objects.isRequired}
				hasError={editingState.errors.objects}
			/>

			<div>
				{fieldsSettings.attachments !== undefined && (
					<Attachments
						className={{
							root: 'editable-export-template__attachments',
							scrollArea: 'editable-export-template__attachments-scroll-area',
							files: 'editable-export-template__attachments-files',
							file: 'editable-export-template__attachments-file'
						}}
						attachments={editingState.attachments || []}
						uploadingFiles={uploadingFilesState.value}
						editingDispatch={editingDispatch}
						uploadingFilesDispatch={uploadingFilesDispatch}
						required={fieldsSettings.attachments.isRequired}
						hasError={fileNameError || uploadingFilesState.value.length > 1}
						showAttachBtn={uploadingFilesState.value.length < 1}
						errorText={
							uploadingFilesState.value.length > 1
								? 'файл должен быть один'
								: 'недопустимое расширение файла'
						}
					/>
				)}
			</div>
		</div>
	);
};
