import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState, IAcceptanceStatisticsFiltersState} from '../reducers/filters';

export const extractFilters = (state: IState): IAcceptanceStatisticsFiltersState =>
	state.settings.pages.statistics.acceptance.filters || getInitialState();

export const isAcceptanceFiltersSetUp = createSelector(
	[extractFilters],
	filters =>
		!!(
			filters.companies?.length
			|| filters.objects?.length
			|| filters.users?.length
			|| (filters.contractors && Object.keys(filters.contractors).length)
		)
);
