import {useContext} from 'react';
import {CellProps} from 'react-table';
import {IconButton} from '@tehzor/ui-components';
import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {useAsyncFn} from 'react-use';
import {useCategoriesSetPermissions} from '@src/core/hooks/permissions/useCategoriesSetPermissions';
import {DispatchActionCtx} from '../CategoriesSetsTable';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

const deleteIcon = <i className="tz-delete" />;

const DeleteCell = ({row}: CellProps<IFullCategoriesSet>) => {
	const {t} = useTranslation();
	const {canDelete} = useCategoriesSetPermissions();
	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.categorySet.deleteTitle'),
		message: t('useConfirmDialog.categorySet.deleteMessage', {id: row.original.name})
	});

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			dispatchAction({type: 'delete', payload: row.original});
		}
	}, [row.original, dispatchAction]);

	return canDelete ? (
		<>
			<IconButton
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</>
	) : null;
};

export default DeleteCell;
