import {InlineButton} from '@tehzor/ui-components';
import {useFiltersDialogDispatch} from '../utils/FiltersDialogDispatchCtx';
import { useTranslation } from 'react-i18next';

interface IControlsProps {
	checkedField: string;
}

const Controls = ({checkedField}: IControlsProps) => {
	const dispatch = useFiltersDialogDispatch();
	const {t} = useTranslation();

	const clearFilters = () => {
		dispatch({type: 'update', field: checkedField, value: []});
	};

	return (
		<div className="stats-filters-dialog__controls">
			<InlineButton
				label={t('component.statsFilters.clearFilters')}
				onClick={clearFilters}
			/>
		</div>
	);
};

export default Controls;
