export enum OwnerAcceptanceStatusId {
	WAITING = 'waiting',
	IN_PROGRESS = 'in-progress',
	DONE = 'done'
}

export interface IOwnerAcceptanceStatus {
	id: OwnerAcceptanceStatusId;
	name: string;
	transitions?: OwnerAcceptanceStatusId[];
	color: string;
	textColor?: string;
}
